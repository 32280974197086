import TableStatusEnum from '@/api/Enums/TableStatusEnum'

export default {
  computed: {
    isTableAvailable () {
      return this.tableIsAvailable(this.table)
    },
    isTableOccupied () {
      return this.tableIsOccupied(this.table)
    },
    isTablePaid () {
      return this.tableIsPaid(this.table)
    },
    isTablePartiallyPaid () {
      return this.tableIsPartiallyPaid(this.table)
    },
    isTableAwaitingPayment () {
      return this.tableIsAwaitingPayment(this.table)
    },
    isTableInOccupiedStatuses () {
      return this.tableIsInOccupiedStatuses(this.table)
    },
    hasTableItemsEditable () {
      return this.tableHasItemsEditable(this.table)
    },
    hasTableOpenShift () {
      return this.tableHasOpenShift(this.table)
    },
    TableStatusEnum () {
      return TableStatusEnum
    }
  },

  methods: {
    tableIsAvailable (table) {
      return table?.StatusId === TableStatusEnum.Available && !this.tableHasOpenShift(table)
    },
    tableIsOccupied (table) {
      return table?.StatusId === TableStatusEnum.Occupied && this.tableHasOpenShift(table)
    },
    tableIsPaid (table) {
      return table?.StatusId === TableStatusEnum.Paid && this.tableHasOpenShift(table)
    },
    tableIsPartiallyPaid (table) {
      return table?.StatusId === TableStatusEnum.PartiallyPaid && this.tableHasOpenShift(table)
    },
    tableIsAwaitingPayment (table) {
      return table?.StatusId === TableStatusEnum.AwaitingPayment && this.tableHasOpenShift(table)
    },
    tableIsInOccupiedStatuses (table) {
      return this.tableIsOccupied(table) || this.tableIsPaid(table) || this.tableIsPartiallyPaid(table) || this.tableIsAwaitingPayment(table)
    },
    tableHasOpenShift (table) {
      return !!table?.ShiftStartedAt
    },
    tableHasItemsEditable (table) {
      return !!table?.HasItemsEditable
    },
    getTableStatusLang (table) {
      if (!table) return ''

      return this.$te('Restaurant.Tables.TableStatus') ? Object.values(this.$t('Restaurant.Tables.TableStatus'))?.find(o => o.Id === table.StatusId)?.Name || table.StatusName : table.StatusName
    },
    getTableStatusColor (table, dark = false) {
      if (parseInt(table.StatusId) === 22) return this.isDarkMode ? 'grey darken-3' : 'grey lighten-5'
      if (table?.StatusColor) return `${ table.StatusColor || 'grey' }${ !dark ? ' lighten-5' : '' }`
      return 'grey'
    },
    getTableStatusIcon (table) {
      if (!table) return ''
      if (parseInt(table.StatusId) === 22) return 'playlist_add'
      if (parseInt(table.StatusId) === 23) return 'playlist_add_check'
      if (parseInt(table.StatusId) === 24) return 'playlist_remove'
      if (parseInt(table.StatusId) === 25) return 'queue_music'
      if (parseInt(table.StatusId) === 30) return 'credit_score'
      if (parseInt(table.StatusId) === 31) return 'credit_card'
      if (parseInt(table.StatusId) === 32) return 'add_card'
    }
  }
}
