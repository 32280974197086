export default {
  MenuOptionsDialog: {
    Field: {
      Comment: {
        Label: 'Comments',
        Hint : 'eg. Without Onions, Well Cooked, etc.'
      },
      Checkbox: {
        Error: {
          Message: 'Please select',
          Choice : 'choices | choice | choices',
          Min    : '@:Pos.MenuOptionsDialog.Field.Checkbox.Error.Message at least ',
          Max    : '@:Pos.MenuOptionsDialog.Field.Checkbox.Error.Message up to ',
          From   : '@:Pos.MenuOptionsDialog.Field.Checkbox.Error.Message between ',
          To     : ' and '
        }
      }
    },
    SelectedOptions: 'Your Selections'
  },

  Pos: {
    Toolbar: {
      Title: {
        New : '@:Order.OrdersAll.Toolbar.Button.Title',
        Edit: '@:Order.OrdersAll.ListView.Button.Edit.Title'
      },
      Customer: {
        Select: {
          Title: 'Select Customer',
          Icon : 'account_circle'
        },
        Search: {
          Title: 'Search Customer',
          Icon : 'search'
        },
        Add: {
          Title: 'New Customer',
          Icon : 'person_add'
        },
        Selected: {
          Floor       : '@:Customer.CustomerAddEditAddressDialog.Field.Floor.Label:',
          DoorBell    : '@:Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label:',
          Instructions: '@:Customer.CustomerAddEditAddressDialog.Field.Instructions.Label:'
        }
      },
      Source: 'Other source'
    },
    Cart: {
      Title : 'Cart',
      Icon  : 'shopping_cart',
      NoData: {
        Title   : 'Empty Cart',
        Subtitle: 'Add products'
      },
      Error: {
        NoStock : 'There is no stock available for product',
        MinStock: {
          Title     : 'The stock for product',
          Message   : 'is less than the minimum order quantity (',
          MessageEnd: ').'
        }
      }
    },
    ListView: {
      Categories: {
        All   : 'All',
        NoData: {
          Title   : 'Empty category',
          Subtitle: 'There are no available products',
          Icon    : 'shopping_cart'
        }
      },
      Field: {
        Search: {
          Label: 'Search product',
          Icon : 'search'
        }
      }
    },
    Footer: {
      Time: {
        Delivery  : 'Delivery time',
        Collection: 'Collection time'
      },
      Button: {
        Comment: {
          Title: '@:Pos.MenuOptionsDialog.Field.Comment.Label',
          Field: {
            Label: 'Order\'s comments',
            Hint : 'eg. Call Customer, etc.'
          }
        },
        Payment : 'Payment',
        AddOrder: 'Add Order'
      }
    },
    Dialog: {
      Save: {
        Title       : 'Save Order?',
        Content     : 'Do you want to save the order?',
        Notification: 'Otherwise the order will be permanently canceled!'
      },
      SaveRefund: {
        Title       : 'Save Order?',
        Content     : '<strong>The order has card transactions!</strong><br>Do you want to save the order?',
        Notification: 'Otherwise the order and card transactions will be permanently canceled and a refund will be issued!'
      },
      Cancel: {
        Title       : 'Cancel Order?',
        Content     : 'Do you want to cancel the order permanently?',
        Notification: 'The order will be permanently canceled!'
      },
      CancelRefund: {
        Title       : 'Cancel Order?',
        Content     : '<strong>The order has card transactions which will be canceled and refunded.</strong><br><br>Do you want to cancel the order and the card transactions permanently?',
        Notification: 'The order and card transactions will be permanently canceled and a refund will be issued!'
      }
    },
    Notification: {
      Error                              : 'There was an error. Please try again!',
      Complete                           : 'Order Completed!',
      Cancel                             : 'Order Not Canceled!',
      NoClient                           : 'Please Select Customer and Address!',
      NoProduct                          : 'Please Add Products to Cart!',
      CouponOverOrderTotal               : 'The discount was not added. The total of the discounts exceeds the total of the order.',
      CouponOverMenuItemTotal            : 'The discount was not added. The discount exceeds the product price.',
      FullDiscountWithPaymentTransactions: 'The 100% discount was not added. You must first remove all payment transactions.'
    }
  },

  SelectCouponDialog: {
    Toolbar: {
      Subtitle: 'Select Discount'
    },
    Field: {
      DiscountPercent: {
        Label: '@:Common.Misc.Discount Percentage',
        Hint : '@:Common.Misc.Discount Percentage . eg. 10, 5.3'
      },
      DiscountAmount: {
        Label: '@:Common.Misc.Discount Amount',
        Hint : '@:Common.Misc.Discount Amount. eg 10, 5.3'
      }
    }
  },

  SelectTipAmount: {
    Toolbar: {
      Title   : 'Tip Amount',
      Subtitle: 'Select Tip Amount',
      Icon    : 'redeem'
    },
    Field: {
      TipAmount: {
        Label: 'Tip Amount',
        Hint : 'Tip Amount. eg. 0.5, 1, 2, 5, 10'
      }
    },
    Amounts: [{ Amount: 0.25 }, { Amount: 0.5 }, { Amount: 1.0 }, { Amount: 1.5 }, { Amount: 2.0 }, { Amount: 2.5 }, { Amount: 3.0 }, { Amount: 3.5 }, { Amount: 4.0 }, { Amount: 4.5 }, { Amount: 5.0 }, { Amount: 10.0 }]
  },

  SelectExtraCharge: {
    Toolbar: {
      Title   : 'Extra Charge',
      Subtitle: 'Select Extra Charge',
      Icon    : 'mdi-credit-card-plus'
    }
  },

  OrderPaymentDialog: {
    Toolbar: {
      Title   : 'Order Payment',
      Subtitle: 'Add Payment Transactions',
      Icon    : 'mdi-account-credit-card'
    },
    Notification: {
      Notification: {
        Refund: {
          Success: 'The transaction was successfully refunded.',
          Error  : 'Transaction refund failed! Try again.'
        },
        Sale: {
          Success: 'The transaction at the selected EFT/POS payment terminal was completed successfully.',
          Error  : 'The transaction at the selected EFT/POS payment terminal failed! Try again.'
        }
      }
    }
  },

  SelectOrderSourceTypeDialog: {
    Toolbar: {
      Title   : 'Order\'s Source',
      Subtitle: 'Select Order\'s Source'
    }
  },

  SelectPlatformCatalogDialog: {
    Button: {
      ChangeCatalog: 'Change Catalog'
    },
    Toolbar: {
      Title   : 'Products\'s Catalog',
      Subtitle: 'Select Products\'s Catalog'
    }
  },

  SelectOrderTypeTimeDialog: {
    Toolbar: {
      Title: {
        Delivery  : 'Order\'s @:Pos.Pos.Footer.Time.Delivery',
        Collection: 'Order\'s @:Pos.Pos.Footer.Time.Collection'
      },
      Subtitle: {
        Delivery  : 'Select @:Pos.SelectOrderTypeTimeDialog.Toolbar.Title.Delivery',
        Collection: 'Select @:Pos.SelectOrderTypeTimeDialog.Toolbar.Title.Collection'
      }
    },
    SetAsDefault: {
      Delivery  : 'Set as default delivery order time',
      Collection: 'Set as default collection order time'
    }
  }
}
