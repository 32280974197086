<template>
  <v-expansion-panels
    v-model="panel"
    accordion
    focusable
    tile
    flat
  >
    <template v-for="(table, tableIdx) in data.PaymentsByTable">
      <v-expansion-panel :key="`table-accordion-${tableIdx}`">
        <v-expansion-panel-header class="text-h6 grey--text text--darken-2 pl-4">
          {{ table.TableAreaName }} #{{ table.TableName }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-list
            class="mb-2 mt-2 pb-0 pt-0"
            three-line
          >
            <v-list-item
              v-for="(payment, paymentIdx) in table.Payments"
              :key="`list-tile-table-${tableIdx}-payment-${paymentIdx}`"
              class="grey lighten-3 my-2"
              ripple
              @click="onListTileClick(payment, table)"
            >
              <div
                class="text-caption pr-2"
                style="display: grid !important;"
              >
                <v-chip
                  class="white--text px-2"
                  color="green"
                  label
                  style="display: inline-block; text-align: center; min-width: 105px;"
                >
                  <strong>{{ payment.Time }}</strong>
                </v-chip>

                <div>
                  <v-chip
                    color="lighten-1 blue-grey--text text--lighten-3 blue-grey"
                    class="px-2 mr-2 mt-2"
                    label
                    style="display: inline-block; text-align: center; min-width:40px;"
                  >
                    <v-icon size="20">
                      tab_unselected
                    </v-icon>
                  </v-chip>

                  <v-chip
                    color="light-blue light-blue--text text--lighten-4"
                    class="px-2 mt-2"
                    label
                    style="display: inline-block; text-align: center; min-width: 40px; width: 56px; max-width: 88px;"
                  >
                    <v-icon size="20">
                      shopping_cart
                    </v-icon>
                    <span class="pl-2">
                      <strong>{{ payment.ItemsCount }}</strong>
                    </span>
                  </v-chip>
                </div>
              </div>

              <v-list-item-content class="grey--text text--darken-1">
                <slot
                  :payment="payment"
                  name="content"
                >
                  <v-list-item-subtitle style="white-space: nowrap;">
                    <v-icon
                      color="blue"
                      size="16"
                      style="width: 22px;"
                    >
                      today
                    </v-icon>
                    <span class="">{{ payment.Date }}</span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <span>
                      <v-icon
                        color="amber darken-3"
                        size="16"
                        style="width: 22px;"
                      >
                        euro_symbol
                      </v-icon>
                      <span class="blue-grey--text text--darken-2">
                        {{ payment.CodTotal | currency }}
                      </span>
                    </span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <span>
                      <v-icon
                        color="blue-grey"
                        size="16"
                        style="width: 22px;"
                      >
                        far fa-credit-card
                      </v-icon>
                      <span class="blue-grey--text text--darken-2">
                        {{ payment.CardTotal | currency }}
                      </span>
                    </span>
                  </v-list-item-subtitle>
                </slot>
              </v-list-item-content>

              <v-list-item-action>
                <slot
                  :order="payment"
                  name="actions"
                >
                  <v-list-item-action-text class="text-subtitle-1">
                    {{ payment.Total | currency }}
                  </v-list-item-action-text>

                  <v-btn
                    v-if="listTileAction && posUserHasComponentPermission('WaiterStaff', 'MANAGE')"
                    :class="`${listTileActionIconColor}--text ma-1`"
                    icon
                    ripple
                    @click.native.stop="onListTileActionClick(payment, table)"
                  >
                    <v-icon>
                      {{ listTileActionIcon }}
                    </v-icon>
                  </v-btn>
                </slot>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>

        <v-divider />
      </v-expansion-panel>
    </template>
  </v-expansion-panels>
</template>

<script>
import AppData     from '@/mixins/appdata'
import Auth        from '@/mixins/auth'
import paymentType from '@/mixins/orders/paymentType'

export default {
  name      : 'PaymentsList',
  components: {},
  directives: {},
  mixins    : [AppData, Auth, paymentType],
  props     : {
    data: {
      type    : [Object, Array],
      default : () => { return {} },
      required: true
    },

    listTileClickEvent: {
      type   : String,
      default: 'list-tile-click'
    },

    listTileActionClickEvent: {
      type   : String,
      default: 'list-tile-action-click'
    },

    listTileAction: {
      type   : Boolean,
      default: false
    },

    listTileActionIcon: {
      type   : String,
      default: 'close'
    },

    listTileActionIconColor: {
      type   : String,
      default: 'red'
    }
  },
  data () {
    return {
      panel: 0
    }
  },
  computed: {},
  watch   : {
    data () {
      this.panel = 0
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  activated () {},
  deactivated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onListTileClick (payment, table) {
      this.$emit(this.listTileClickEvent, payment, table)
    },

    onListTileActionClick (payment, table) {
      this.$emit(this.listTileActionClickEvent, payment, table)
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile {
  padding : 0 8px;
}

/deep/ .v-chip__content {
  padding : 0;
}

/deep/ .v-expansion-panel-content__wrap {
  padding : 4px;
}
</style>
