export default class BaseResource {
  /**
   *
   * @param {Object} [rawData={}] the object to resource.
   * @return {BaseResource} A BaseResource object.
   */
  // eslint-disable-next-line no-useless-constructor
  constructor (rawData = {}) {

  }

  /* METHODS */

  /**
   * Gets a serialized version of the resource.
   *
   * @returns {JSON | Object} The serialized resource
   */
  clone () {
    return JSON.parse(JSON.stringify(this))
  }

  /**
   * stringifies the resource using JSON.stringify API.
   *
   * @return {string} The stringified resource.
   */
  toString () {
    return JSON.stringify(this.clone())
  }
}
