export default {
  Title: 'Πελάτες | Πελάτης | Πελάτες',

  CustomerStatus: {
    Label: 'Status'
  },
  CustomerType: {
    Visitor   : 'Επισκέπτης',
    Registered: 'Εγγεγραμμένος Πελάτης',
    Through   : 'μέσω'
  },

  Table: {
    Button: {
      NewCustomer: {
        Title: 'Νέος πελάτης',
        Icon : 'add_box',
        Color: 'primary'
      },
      Export: {
        Title         : 'Εξαγωγή',
        Icon          : 'file_download',
        Color         : 'primary',
        SuccessMessage: 'Σύντομα θα λάβετε αρχείο με την εξαγωγή των πελατών στο email: {email}'
      },
      Import: {
        Title         : 'Εισαγωγή',
        Subtitle      : 'Εισαγωγή πελατών από',
        Icon          : 'mdi-import',
        Color         : 'primary',
        SuccessMessage: 'Η εισαγωγή πελατών ολοκληρώθηκε με επιτυχία.'
      },
      NewOrder: {
        Title: 'Νέα Παραγγελία',
        Icon : 'add_shopping_cart',
        Color: 'green'
      },
      NewReservation: {
        Title: 'Νέα Κράτηση',
        Icon : 'mdi mdi-book-open-variant',
        Color: 'indigo'
      },
      ViewCustomer: {
        Title: 'Προβολή Πελάτη',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      },
      DeleteCustomer: {
        Title: 'Διαγραφή Πελάτη',
        Icon : 'delete',
        Color: 'red'
      }
    },

    Headers: {
      CustomerType: '',
      Id          : '#',
      Firstname   : 'Όνομα',
      Lastname    : 'Επίθετο',
      Address     : 'Διεύθυνση',
      Groups      : 'Ομάδες',
      Phone       : 'Τηλέφωνο',
      CreatedAt   : 'Εγγραφή',
      Actions     : ''
    },

    Filter: {
      filterCustomerStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Κατάσταση Πελάτη',
        PlaceHolder: 'Όλοι οι πελάτες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλοι'
          },
          {
            Value: 'true',
            Name : 'Ενεργός',
            Icon : 'check_circle',
            Color: 'light-green darken-1',
            Class: 'white--text'
          },
          {
            Value: 'false',
            Name : 'Ανενεργός',
            Icon : 'check_circle',
            Color: 'orange darken-1',
            Class: 'white--text'
          }
        ]
      },
      filterCustomerSourceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Πηγή Πελάτη',
        PlaceHolder: 'Όλες οι πηγές πελατών',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterCustomerGroup: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Ομάδες Πελατών',
        PlaceHolder: 'Όλες οι ομάδες πελατών',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterCustomerSocial: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Τύπος Εγγραφής',
        PlaceHolder: 'Όλοι οι τύποι εγγραφής',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        ItemDesc   : 'Description',
        Items      : [
          {
            Value : 'Guest',
            Name  : 'Εγγεγραμμένοι',
            Avatar: {
              Icon : 'verified_user',
              Color: 'amber'
            },
            Class: 'white--text'
          },
          {
            Value : 'Email',
            Name  : 'Εγγεγραμμένοι μέσω Email',
            Avatar: {
              Icon : 'email',
              Color: 'blue-grey'
            },
            Class: 'white--text'
          },
          {
            Value : 'Google',
            Name  : 'Εγγεγραμμένοι μέσω Google',
            Avatar: {
              Icon : 'fab fa-google-plus-square',
              Color: 'red'
            },
            Class: 'white--text'
          },
          {
            Value : 'Facebook',
            Name  : 'Εγγεγραμμένοι μέσω Facebook',
            Avatar: {
              Icon : 'fab fa-facebook-square',
              Color: 'blue darken-1'
            },
            Class: 'white--text'
          }
        ]
      },
      filterDate: {
        Type       : 'v-date-picker',
        Label      : 'Ημερομηνία Εγγραφής',
        PlaceHolder: 'Επιλέξτε Ημερομηνία',
        Icon       : 'event'
      }
    }
  },

  CustomersDeleteDialog: {
    Title       : 'Διαγραφή Πελάτη {customer};',
    Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον πελάτη "{customer}";',
    Notification: 'Η διαγραφή είναι οριστική και η διαδικασία είναι μη αναστρέψιμη'
  },

  CustomerSelectEmailDialog: {
    Toolbar: {
      Title   : 'Email Πελάτη',
      Subtitle: 'Εισάγετε διευθύνσεις email πελάτη',
      Icon    : 'mdi-email',
      Color   : 'primary'
    }
  },

  CustomerSelectCompanyDialog: {
    Toolbar: {
      Title        : 'Εταιρεία Πελάτη',
      Subtitle     : 'Επιλέξτε Εταιρεία ή Εισαγάγετε Στοιχεία Εταιρείας',
      SelectCompany: 'Επιλέξτε Εταιρεία',
      Icon         : 'mdi-domain',
      Color        : 'indigo'
    }
  },

  CustomersExportDialog: {
    Toolbar: {
      Title   : 'Εξαγωγή Πελατών',
      Subtitle: 'Εξαγωγή Πελατών σε Excel',
      Icon    : 'file_download',
      Color   : 'primary'
    },
    Field: {
      Period: {
        Label: 'Επιλέξτε Περίοδο Εγγραφής Πελάτη'
      },
      Group: {
        Label: 'Ομάδες Πελατών',
        Hint : 'Επιλέξτε Ομάδες Πελατών',
        Icon : 'group'
      },
      LastOrder: {
        Label            : 'Τελευταία Παραγγελία Πελάτη',
        Hint             : 'Επιλέξτε περίοδο τελευταίας παραγγελίας πελάτη',
        HintWithOrders   : 'Περίοδος που ο πελάτης έχει κάνει παραγγελίες',
        HintWithoutOrders: 'Περίοδος που ο πελάτης δεν έχει κάνει παραγγελίες',
        Icon             : 'add_shopping_cart',
        Items            : [
          {
            Name : 'Τελευταία εβδομάδα',
            Value: 'week'
          },
          {
            Name : 'Τελευταίος μήνας',
            Value: 'month'
          },
          {
            Name : 'Τελευταίο τρίμηνο',
            Value: 'months3'
          },
          {
            Name : 'Τελευταίο εξάμηνο',
            Value: 'months6'
          },
          {
            Name : 'Τελευταίο έτος',
            Value: 'year'
          },
          {
            Name : 'Πάνω από το τελευταίο έτος',
            Value: 'yearMore'
          }
        ]
      },
      LastWithOrders: {
        Label: 'Με Παραγγελίες'
      },
      LastWithoutOrders: {
        Label: 'Χωρίς Παραγγελίες'
      },
      Registered: {
        Label: 'Εγγεγραμμένοι'
      },
      Guest: {
        Label: 'Επισκέπτες'
      },
      WithAddress: {
        Label: 'Διευθύνσεις'
      },
      ForSms: {
        Label: 'Excel για SMS'
      }
    },
    Button: {
      Export: 'Εξαγωγή'
    }
  },

  CustomerAddEditDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Πελάτη',
        Subtitle: 'Δημιουργία Νέου Πελάτη'
      },
      Edit: {
        Title   : 'Επεξεργασία Πελάτη',
        Subtitle: 'Τροποποίηση Στοιχείων Πελάτη'
      },
      Icon : 'account_circle',
      Color: 'indigo'
    },
    Field: {
      Info: {
        Title    : 'Στοιχεία',
        Firstname: {
          Label: 'Όνομα',
          Hint : 'Όνομα πελάτη. πχ. Βαγγέλης, Μαρία, κλπ.',
          Icon : 'account_circle',
          Error: {
            Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
          }
        },
        Lastname: {
          Label: 'Επίθετο',
          Hint : 'Επίθετο πελάτη. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
          Icon : 'account_circle',
          Error: {
            Between: 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
          }
        },
        Telephone: {
          Label: 'Τηλέφωνο',
          Hint : 'Τηλέφωνο. πχ.',
          Icon : 'phone',
          Error: {
            Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
          }
        },
        Email: {
          Label: 'Email',
          Hint : 'Διεύθυνση Email πελάτη. πχ. support@getfoodpro.gr',
          Icon : 'email',
          Error: {
            Between: 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
            Valid  : 'Το E-mail πρέπει να είναι έγκυρο'
          }
        },
        Group: {
          Label: 'Ομάδες',
          Hint : 'Επιλέξτε Ομάδες Πελάτη',
          Icon : 'group',
          Error: {
            Min: 'Παρακαλώ επιλέξτε τουλάχιστον μία ομάδα πελάτη'
          }
        }
      },
      Notifications: {
        Title   : 'Ενημερώσεις',
        Subtitle: 'Ενημερώσεις για προσφορές',
        Email   : {
          Label: 'Email'
        },
        Sms: {
          Label: 'SMS'
        },
        Push: {
          Label: 'Push'
        }
      }
    },
    Button: {
      SaveWithoutAddress: '@:Common.Button.Save χωρίς διεύθυνση',
      AddAddress        : 'Προσθήκη Διεύθυνσης',
      CustomerDetails   : 'Στοιχεία πελάτη'
    }
  },

  CustomerAddCompanyDialog: {
    Toolbar: {
      Icon : 'mdi-domain',
      Color: 'indigo',
      Add  : {
        Title   : 'Προσθήκη Εταιρείας',
        Subtitle: 'Δημιουργία Νέας Εταιρείας'
      },
      Edit: {
        Title   : 'Επεξεργασία Εταιρείας',
        Subtitle: 'Τροποποίηση Στοιχείων Εταιρείας'
      }
    },
    Card: {
      NoData: {
        Icon    : 'mdi-domain',
        Title   : 'Δεν Υπάρχουν Εταιρίες',
        Subtitle: 'Προσθέστε μια νέα εταιρεία'
      }
    },
    Company: {
      Title: 'Στοιχεία Εταιρείας'
    },
    Address: {
      Title: 'Στοιχεία Διεύθυνσης'
    },
    Field: {
      BrandName: {
        Label : 'Επωνυμία',
        Hint  : 'Επωνυμία εταιρείας',
        Icon  : 'account_balance',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      DistinctiveTitle: {
        Label : 'Διακριτικός Τίτλος',
        Hint  : 'Διακριτικός τίτλος εταιρείας',
        Icon  : 'account_balance',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Profession: {
        Label : 'Επάγγελμα',
        Hint  : 'Επάγγελμα εταιρείας',
        Icon  : 'account_balance',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Phone: {
        Label : 'Τηλέφωνο',
        Hint  : 'Τηλέφωνο εταιρείας',
        Icon  : 'phone',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      VatNumber: {
        Label : 'ΑΦΜ',
        Hint  : 'ΑΦΜ εταιρείας',
        Icon  : 'toll',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες',
          ApiError : 'Μη υπαρκτό / ενεργό ΑΦΜ'
        }
      },
      Irs: {
        Label : 'ΔΟΥ',
        Hint  : 'ΔΟΥ εταιρείας',
        Icon  : 'toll',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Notes: {
        Label : 'Σημειώσεις',
        Hint  : '',
        Icon  : 'mdi-text-box-edit',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 1024 χαρακτήρες'
        }
      },
      IsDefault: {
        Label : 'Προεπιλεγμένη εταιρεία',
        Hint  : '',
        Icon  : '',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο'
        }
      },
      County: {
        Label : 'Νομός',
        Hint  : 'Νομός έδρας εταιρείας',
        Icon  : 'map',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      City: {
        Label : 'Πόλη',
        Hint  : 'Πόλη έδρας εταιρείας',
        Icon  : 'place',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Street: {
        Label : 'Διεύθυνση',
        Hint  : 'Διεύθυνση έδρας εταιρείας',
        Icon  : 'my_location',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      StreetNumber: {
        Label : 'Αριθμός',
        Hint  : 'Αριθμός έδρας εταιρείας',
        Icon  : 'my_location',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Postcode: {
        Label : 'ΤΚ',
        Hint  : 'ΤΚ έδρας εταιρείας',
        Icon  : 'navigation',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Country: {
        Label : 'Χώρα',
        Hint  : 'Χώρα έδρας εταιρείας',
        Icon  : 'place',
        Errors: {
          Mandatory: 'Υποχρεωτικό πεδίο',
          Valid    : 'Πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      }
    }
  },

  CustomerAddEditAddressDialog: {
    Toolbar: {
      Icon : 'my_location',
      Color: 'indigo',
      Add  : {
        Title   : 'Προσθήκη Διεύθυνσης',
        Subtitle: 'Δημιουργία Νέας Διεύθυνσης Πελάτη'
      },
      Edit: {
        Title   : 'Επεξεργασία Διεύθυνσης',
        Subtitle: 'Τροποποίηση Στοιχείων Διεύθυνσης Πελάτη'
      }
    },
    UseCustom: 'Χωρίς επιβεβαίωση με χάρτη',
    Field    : {
      AutoComplete: {
        Label : 'Εισάγετε την διεύθυνση σας',
        Hint  : 'Εισάγετε την διεύθυνση σας.',
        Errors: {
          Mandatory: 'Παρακαλώ συμπληρώστε την διεύθυνση σας',
          Valid    : 'Η διεύθυνση πρέπει να είναι μέσα στα όρια διανομής του καταστήματος'
        }
      },
      Address: {
        PlaceHolder: 'Εισάγετε την διεύθυνση σας',
        Hint       : 'Εισάγετε την διεύθυνση σας',
        Icon       : 'location_on',
        Error      : {
          Range: {
            Subtitle: 'Η διεύθυνση δεν είναι μέσα στα όρια παράδοσης του καταστήματος.'
          },
          Custom: {
            Subtitle: 'Μη επιβεβαιωμένη διεύθυνση. Ενδέχεται να είναι εκτός ορίων παράδοσης.'
          }
        }
      },
      Instructions: {
        Label: 'Οδηγίες',
        Hint : 'Επιπλέον οδηγίες για την διεύθυνση',
        Icon : 'help'
      },
      DefaultAddress: {
        Label: 'Προεπιλεγμένη Διεύθυνση'
      },
      FriendlyName: {
        Label: 'Φιλική Ονομασία',
        Hint : 'πχ. Σπίτι, Γραφείο, κλπ.',
        Icon : 'label'
      },
      Region: {
        PlaceHolder: 'Περιοχή',
        Hint       : 'Περιοχή',
        Icon       : 'location_on',
        Error      : {
          Between: 'Η περιοχή πρέπει να είναι από 2 έως 120 χαρακτήρες'
        }
      },
      Street: {
        PlaceHolder: 'Οδός',
        Hint       : 'Οδός',
        Icon       : 'location_on',
        Error      : {
          Between: 'Η οδος πρέπει να είναι από 2 έως 120 χαρακτήρες'
        }
      },
      Number: {
        Label: 'Αριθμός',
        Error: {
          Between: 'Ο αριθμός πρέπει να είναι από 1 έως 10 χαρακτήρες'
        }
      },
      Postcode: {
        Label: 'Τ.Κ.',
        Hint : 'πχ. 153 54',
        Error: {
          Valid: 'Το Τ.Κ. πρέπει να είναι έγκυρο'
        }
      },
      Floor: {
        Label: 'Όροφος',
        Hint : 'πχ. 1ος, Ισόγειο, Μεζονέτα, Μονοκατοικια, κλπ.',
        Icon : 'domain'
      },
      DoorBell: {
        Label: 'Κουδούνι',
        Hint : 'Όνομα που αναγράφεται στο κουδούνι',
        Icon : 'notifications'
      }
    }
  },

  CustomerDetailsDialog: {
    Toolbar: {
      Title   : 'Καρτέλα Πελάτη',
      Subtitle: 'Στοιχεία και Στατιστικά πελάτη'
    },
    Card: {
      Statistic: {
        Week: {
          FooterText: 'Προηγούμενη @:Common.Date.Week:'
        },
        Month: {
          FooterText: 'Προηγούμενος @:Common.Date.Month:'
        },
        Average: {
          Subtext: 'Μέση τιμή'
        },
        LoyaltyPoints: {
          Title     : 'Πόντοι Επιβράβευσης',
          Subtext   : 'Loyalty',
          FooterText: 'Συνολικοί Πόντοι:'
        }
      },
      Details: {
        Toolbar: {
          Title: '@:Common.Misc.Details',
          Icon : 'account_circle',
          Color: 'blue darken-4',
          Tab  : {
            Customer : 'Πελάτης',
            Companies: 'Εταιρίες'
          }
        },
        Content: {
          DateAdded    : 'Πελάτης από',
          CustomerGroup: 'Ομάδες Πελάτη',
          Preferences  : 'Top 10 Προτιμήσεις Πελάτη | Προτίμηση Πελάτη | Top {count} Προτιμήσεις Πελάτη'
        }
      },
      Companies: {
        Delete: {
          Title  : 'Διαγραφή Εταιρείας;',
          Content: 'Θέλετε να διαγράψετε οριστικά αυτή την εταιρεία;'
        }
      },
      Address: {
        Toolbar: {
          Title: 'Διευθύνσεις',
          Icon : 'location_on',
          Color: 'green darken-4'
        },
        Content: {
          ListView: {
            Floor       : '@:Customer.CustomerAddEditAddressDialog.Field.Floor.Label:',
            DoorBell    : '@:Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label:',
            Instructions: '@:Customer.CustomerAddEditAddressDialog.Field.Instructions.Label:'
          },
          NoData: {
            Title   : 'Δεν Υπάρχουν Διευθύνσεις',
            Subtitle: 'Προσθέστε μια νέα διεύθυνση',
            Icon    : 'location_off',
            Color   : 'grey lighten-2'
          }
        },
        Delete: {
          Title  : 'Διαγραφή Διεύθυνσης;',
          Content: 'Θέλετε να διαγράψετε οριστικά αυτή την διεύθυνση;'
        }
      },
      Order: {
        Toolbar: {
          Title: 'Τελευταίες Παραγγελίες | Τελευταία Παραγγελία | Τελευταίες {count} Παραγγελίες',
          Icon : 'shopping_cart',
          Color: 'deep-purple darken-3'
        },
        Content: {
          NoData: {
            Title   : 'Δεν Υπάρχουν Παραγγελίες',
            Subtitle: 'Προσθέστε μια νέα παραγγελία',
            Icon    : 'shopping_cart',
            Color   : 'grey lighten-2'
          }
        }
      }
    },
    Button: {
      Color         : 'green',
      SelectCustomer: {
        Title: 'Επιλογή Πελάτη',
        Icon : 'person_add'
      }
    }
  },

  CustomerGroupAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Ομάδας Πελατών',
        Subtitle: 'Δημιουργία Νέας Ομάδας Πελατών'
      },
      Edit: {
        Title   : 'Επεξεργασία Ομάδας Πελατών',
        Subtitle: 'Τροποποίηση Στοιχείων Ομάδας Πελατών'
      },
      Icon : 'account_circle',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: 'Όνομα',
        Hint : 'Όνομα Ομάδας Πελατών. πχ. Εταιρεία, Ιδιώτης, E-Food, κλπ.',
        Icon : 'title',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : 'Περιγραφή Ομάδας Πελατών.',
        Icon : 'comment',
        Error: {
          Between: 'Η περιγραφή πρέπει να είναι από 2 έως 200 χαρακτήρες'
        }
      }
    }
  },

  CustomerGroups: {
    Table: {
      Button: {
        New: {
          Title: 'Νέα Ομάδα Πελατών',
          Icon : 'add_box',
          Color: 'primary'
        },
        Edit: {
          Title: 'Επεξεργασία Ομάδας Πελατών',
          Icon : 'edit',
          Color: 'cyan'
        }
      },

      Headers: {
        Id         : '#',
        Name       : 'Όνομα',
        Description: 'Περιγραφή',
        Actions    : ''
      }
    },

    Error: 'Η κατηγορία δεν ενημερώθηκε. @:Common.Misc.Retry'
  },

  CustomerSearchDialog: {
    Toolbar: {
      Title   : 'Αναζήτηση Πελάτη',
      Subtitle: 'Αναζήτηση με όνομα, επίθετο, τηλέφωνο ή E-mail',
      Icon    : 'account_circle',
      Color   : 'blue-grey'
    }
  },

  CustomerSelectAddressDialog: {
    Toolbar: {
      Title   : 'Επιλογή Διεύθυνσης',
      Subtitle: 'Επιλογή Διεύθυνσης Πελάτη',
      Icon    : 'my_location',
      Color   : 'indigo'
    },
    Header: {
      Name        : 'Όνομα:',
      Telephone   : '@:Customer.CustomerAddEditDialog.Field.Info.Telephone.Label:',
      Email       : 'Email:',
      Floor       : '@:Customer.CustomerAddEditAddressDialog.Field.Floor.Label:',
      DoorBell    : '@:Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label:',
      Instructions: '@:Customer.CustomerAddEditAddressDialog.Field.Instructions.Label:'
    },
    Button: {
      NewAddress: {
        Title: 'Νέα Διεύθυνση',
        Color: 'green'
      }
    }
  }
}
