<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Catalog.Copy.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Catalog.Copy.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Catalog.Copy.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Catalog.Copy.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="fill-height pa-3"
        >
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              fluid
              class="pa-0"
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :hint="$t('Settings.Catalog.Copy.Field.Source.Hint')"
                    :label="$t('Settings.Catalog.Copy.Field.Source.Label')"
                    :prepend-icon="$t('Settings.Catalog.Copy.Field.Source.Icon')"
                    :value="sourceDomainName"
                    persistent-hint
                    readonly
                    required
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col cols="12">
                  <v-autocomplete
                    v-model="targetDomains"
                    :hint="$t('Settings.Catalog.Copy.Field.Target.Hint')"
                    :item-text="(d) => getDomainName(d)"
                    :items="domainsList"
                    :label="$t('Settings.Catalog.Copy.Field.Target.Label')"
                    :prepend-icon="$t('Settings.Catalog.Copy.Field.Target.Icon')"
                    :rules="[(v) => !!v && v.length || $t('Common.Error.Mandatory')]"
                    item-value="SourceId"
                    multiple
                    persistent-hint
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Copy').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData from '../../../mixins/appdata'
import Auth    from '../../../mixins/auth'

export default {
  name      : 'CatalogCopyDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogFormValid: false,
      targetDomains  : []
    }
  },
  computed: {
    domainsList () {
      return this.multipleLocations.filter(location => location.SourceId !== this.appConfig.LOCATION_DATA.SourceId)
    },
    sourceDomainName () {
      return this.getDomainName(this.appConfig.LOCATION_DATA)
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Catalog.Copy, this.onResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Catalog.Copy, this.onResult)
  },
  methods: {
    getDomainName (d) {
      const name = d.Name || ''
      const url = d.SiteUrl || ''

      return `${ name } - ${ url }`
    },
    onResult (response) {
      this.onCancelButtonClick()

      if (response && response.status) {
        this.$bus.$emit('app-show-notification', {
          body   : response.type,
          type   : 'success',
          icon   : 'check',
          timeout: 8000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Settings.Domain.Copy.Notification'),
          type   : 'error',
          icon   : 'warning',
          timeout: 8000
        })
      }
    },
    onSaveButtonClick () {
      if (this.$refs.dialogForm) this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()

        const data = { TargetDomains: this.targetDomains }
        window.callAS(window.SocketCommand.Settings.Catalog.Copy, data)
      }
    },
    onCancelButtonClick () {
      this.targetDomains = []
      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
    }
  }
}
</script>
