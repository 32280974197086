<template>
  <v-dialog
    v-model="isVisible"
    :overlay="false"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card v-if="receipt">
      <v-toolbar
        flat
        extended
        height="60"
        extension-height="40"
        max-height="100"
        color="secondary"
        class="rounded-0"
      >
        <v-avatar color="blue-grey lighten-1">
          <v-icon
            dark
            medium
          >
            mdi-receipt
          </v-icon>
        </v-avatar>

        <v-toolbar-title class="pl-3">
          <div class="body-3 grey--text text--lighten-3">
            {{ $tc('Receipt.Title', 1) }} {{ receipt.InternalId }}
          </div>
          <div class="text-caption grey--text  text--lighten-1">
            {{ receipt.InvoiceTypeCode }} {{ receipt.InvoiceTypeName }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          dark
          icon
          @click="isVisible = false"
        >
          <v-icon>close</v-icon>
        </v-btn>

        <template #extension>
          <v-tabs
            v-model="tab"
            dark
            grow
            show-arrows
            background-color="secondary"
            slider-color="primary"
            class="rounded-0"
          >
            <v-tab
              v-if="receipt.DocumentUrl"
              value="tab-details"
            >
              ΠΑΡΑΣΤΑΤΙΚΟ
            </v-tab>

            <v-tab
              v-if="userInAdminGroup"
              value="tab-payload"
            >
              PAYLOAD
            </v-tab>

            <v-tab
              v-if="userInAdminGroup"
              value="tab-response"
            >
              RESPONSE
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text class="pa-0 fill-height">
        <v-tabs-items
          v-model="tab"
          class="fill-height"
        >
          <v-tab-item
            v-if="receipt.DocumentUrl"
            key="tab-details"
          >
            <iframe
              v-if="isVisible"
              scrolling="auto"
              style="border:none; width: 100%; height: calc(100vh - 108px);"
              :src="receipt.DocumentUrl"
            />
          </v-tab-item>

          <v-tab-item
            v-if="userInAdminGroup"
            key="tab-payload"
            style="background-color: #1E1F22; color: #B7BBC1; overflow-y: auto; height: 100vh;"
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <pre
                    style="user-select: text;"
                    v-html="syntaxHighlightReceiptPayload"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item
            v-if="userInAdminGroup"
            key="tab-response"
            style="background-color: #1E1F22; color: #B7BBC1; overflow-y: auto; height: 100vh;"
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <pre
                    style="user-select: text;"
                    v-html="syntaxHighlightReceiptResponse"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'ReceiptDetailsDialog',
  components: {},
  mixins    : [Auth, AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    receipt: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      tab: 'tab-main'
    }
  },
  computed: {
    syntaxHighlightReceiptPayload () {
      return this.syntaxHighlight(this.receipt?.Data?.Payload)
    },
    syntaxHighlightReceiptResponse () {
      return this.syntaxHighlight(this.receipt?.Data?.Response)
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible () {
      this.tab = 'tab-main'
    }
  },
  updated () {},
  methods: {
    syntaxHighlight (json) {
      json = JSON.stringify(json, undefined, 4) || ''
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
        let cls = 'number'
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key'
          } else {
            cls = 'string'
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean'
        } else if (/null/.test(match)) {
          cls = 'null'
        }
        return '<span class="' + cls + '">' + match + '</span>'
      })

      return json
    }
  }
}
</script>

<style scoped>
/deep/ pre {
  outline : 1px solid #B7BBC1;
  padding : 5px;
  margin  : 5px;
}

/deep/ .string {
  color : #69AA6F;
}

/deep/ .number {
  color : #24A5A2;
}

/deep/ .boolean {
  color : #CF8E6D;
}

/deep/ .null {
  color : #C76738;
}

/deep/ .key {
  color : #C37AB6;
}
</style>
