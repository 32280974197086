<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="960"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Charge.AddEditDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Charge.AddEditDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!itemToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Charge.AddEditDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Charge.AddEditDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Charge.AddEditDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Charge.AddEditDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="chargeForm"
            v-model="chargeFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="selectedLanguages.length > 1">
                    <v-tabs
                      v-model="languageTab"
                      :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                      grow
                      height="40"
                      slider-color="blue"
                    >
                      <template v-for="lang in selectedLanguages">
                        <v-tab
                          :key="lang.Code"
                          :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                        >
                          <v-avatar
                            v-if="lang.ImageUrl"
                            class="mr-2"
                            size="20"
                          >
                            <v-img :src="lang.ImageUrl" />
                          </v-avatar>
                          {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                        </v-tab>
                      </template>
                    </v-tabs>
                  </template>

                  <v-tabs-items v-model="languageTab">
                    <template v-for="lang in selectedLanguages">
                      <v-tab-item
                        :key="lang.Code"
                        eager
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editCharge.Lang[lang.Code].Name"
                              :counter="255"
                              :maxlength="255"
                              :hint="$t('Charge.AddEditDialog.Field.Name.Hint')"
                              :label="$t('Charge.AddEditDialog.Field.Name.Label')"
                              :prepend-icon="$t('Charge.AddEditDialog.Field.Name.Icon')"
                              :rules="validationRules.chargeForm.Name"
                              class="pr-2"
                              required
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editCharge"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Name"
                                />
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </template>
                  </v-tabs-items>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editCharge.Priority"
                    :hint="$t('Charge.AddEditDialog.Field.Priority.Hint')"
                    :label="$t('Charge.AddEditDialog.Field.Priority.Label')"
                    :prepend-icon="$t('Charge.AddEditDialog.Field.Priority.Icon')"
                    :rules="validationRules.chargeForm.Priority"
                    type="number"
                    step="1"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editCharge.Quantity"
                    :hint="$t('Charge.AddEditDialog.Field.Quantity.Hint')"
                    :label="$t('Charge.AddEditDialog.Field.Quantity.Label')"
                    :prepend-icon="$t('Charge.AddEditDialog.Field.Quantity.Icon')"
                    :rules="validationRules.chargeForm.Quantity"
                    class="pr-2"
                    type="number"
                    min="1"
                    step="1"
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editCharge.Price"
                    :hint="$t('Charge.AddEditDialog.Field.Price.Hint')"
                    :label="$t('Charge.AddEditDialog.Field.Price.Label')"
                    :prepend-icon="$t('Charge.AddEditDialog.Field.Price.Icon')"
                    :rules="validationRules.chargeForm.Price"
                    class="pr-2"
                    type="number"
                    min="0"
                    step="1"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-col cols="12">
                  <v-icon>{{ $t('Restaurant.CatalogAddDialog.Field.SourceTypes.Icon') }}</v-icon>

                  <div class="grey--text text--darken-1 ma-0 pb-1">
                    {{ $t('Marketing.Coupon.Dialog.Field.OrderRestriction.Title') }}
                  </div>

                  <v-radio-group
                    v-model="editCharge.OrderRestriction"
                    class="pa-0"
                    row
                  >
                    <v-radio
                      :label="$t('Marketing.Coupon.Dialog.Field.OrderRestriction.RadioOption.All')"
                      value="0"
                    />
                    <v-radio
                      :label="$t('Marketing.Coupon.Dialog.Field.OrderRestriction.RadioOption.Delivery')"
                      value="1"
                    />
                    <v-radio
                      :label="$t('Marketing.Coupon.Dialog.Field.OrderRestriction.RadioOption.TakeAway')"
                      value="2"
                    />
                  </v-radio-group>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editCharge.SourceType"
                    :hint="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Hint')"
                    :items="sourceTypes"
                    :label="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Label')"
                    :prepend-icon="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Icon')"
                    :rules="validationRules.chargeForm.SourceType"
                    item-text="Name"
                    item-value="Id"
                    multiple
                  >
                    <template #selection="{item}">
                      <v-chip
                        :color="item.Status ? 'green' : ''"
                        :dark="item.Status"
                        class="pl-0"
                        label
                        small
                      >
                        <v-avatar
                          :color="item.Avatar.Color"
                          class="mr-2"
                          tile
                        >
                          <v-icon
                            v-if="item.Avatar.Icon"
                            class="white--text"
                          >
                            {{ item.Avatar.Icon }}
                          </v-icon>

                          <img
                            v-else
                            :src="item.Avatar.Img"
                          >
                        </v-avatar>
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :color="data.item.Avatar.Color"
                        :size="28"
                      >
                        <v-icon
                          v-if="data.item.Avatar.Icon"
                          class="white--text"
                        >
                          {{ data.item.Avatar.Icon }}
                        </v-icon>

                        <img
                          v-else
                          :src="data.item.Avatar.Img"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold mt-2">
                    {{ $t('Restaurant.CategoryAddDialog.Field.Tax.Label') }}
                  </h5>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editCharge.TaxId"
                    :hint="$t('Charge.AddEditDialog.Field.Tax.Hint')"
                    :items="taxesList"
                    :label="$t('Charge.AddEditDialog.Field.Tax.Label')"
                    :prepend-icon="$t('Charge.AddEditDialog.Field.Tax.Icon')"
                    :rules="validationRules.chargeForm.TaxId"
                    class="mt-2"
                    item-text="Name"
                    item-value="Id"
                    persistent-hint
                    clearable
                    required
                  >
                    <template #selection="{item}">
                      {{ getTranslatedField(item, 'Name') }}
                    </template>
                  </v-select>
                </v-col>

                <template v-if="appSupportIncomeClassifications">
                  <v-col cols="12">
                    <h5 class="text-body-2 font-weight-bold mt-2">
                      {{ $t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Title') }}
                    </h5>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="editCharge.FeeCategoryCode"
                      :disabled="feeDisabled"
                      :hint="$t('Charge.AddEditDialog.Field.FeeCategoryCode.Hint')"
                      :items="feeCategoryCodeList"
                      :label="$t('Charge.AddEditDialog.Field.FeeCategoryCode.Label')"
                      :prepend-icon="$t('Charge.AddEditDialog.Field.FeeCategoryCode.Icon')"
                      :rules="validationRules.chargeForm.FeeCategoryCode"
                      class="mt-2"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>

                    <h5 class="text-body-2 font-weight-bold mt-4">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Receipt') }}
                    </h5>

                    <v-select
                      v-model="editCharge.ClassificationCategoryCode"
                      :disabled="classificationDisabled"
                      :hint="$t('Charge.AddEditDialog.Field.ClassificationCategoryCode.Hint')"
                      :items="incomeClassificationCategoryCodeList"
                      :label="$t('Charge.AddEditDialog.Field.ClassificationCategoryCode.Label')"
                      :prepend-icon="$t('Charge.AddEditDialog.Field.ClassificationCategoryCode.Icon')"
                      :rules="validationRules.chargeForm.ClassificationCategoryCode"
                      class="mt-2"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Code }}</v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>

                    <v-select
                      v-model="editCharge.ClassificationTypeCode"
                      :disabled="classificationDisabled"
                      :hint="$t('Charge.AddEditDialog.Field.ClassificationTypeCode.Hint')"
                      :items="getReceiptIncomeClassificationTypesByCategoryCode(editCharge.ClassificationCategoryCode)"
                      :label="$t('Charge.AddEditDialog.Field.ClassificationTypeCode.Label')"
                      :prepend-icon="$t('Charge.AddEditDialog.Field.ClassificationTypeCode.Icon')"
                      :rules="validationRules.chargeForm.ClassificationTypeCode"
                      class="mt-4"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.Code }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <h5 class="text-body-2 font-weight-bold mt-4">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Invoice') }}
                    </h5>

                    <v-select
                      v-model="editCharge.ClassificationCategoryCodeInvoice"
                      :disabled="classificationDisabled"
                      :hint="$t('Charge.AddEditDialog.Field.ClassificationCategoryCode.Hint')"
                      :items="incomeClassificationCategoryCodeList"
                      :label="$t('Charge.AddEditDialog.Field.ClassificationCategoryCode.Label')"
                      :prepend-icon="$t('Charge.AddEditDialog.Field.ClassificationCategoryCode.Icon')"
                      :rules="validationRules.chargeForm.ClassificationCategoryCode"
                      class="mt-2"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Code }}</v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>

                    <v-select
                      v-model="editCharge.ClassificationTypeCodeInvoice"
                      :disabled="classificationDisabled"
                      :hint="$t('Charge.AddEditDialog.Field.ClassificationTypeCode.Hint')"
                      :items="getInvoiceIncomeClassificationTypesByCategoryCode(editCharge.ClassificationCategoryCodeInvoice)"
                      :label="$t('Charge.AddEditDialog.Field.ClassificationTypeCode.Label')"
                      :prepend-icon="$t('Charge.AddEditDialog.Field.ClassificationTypeCode.Icon')"
                      :rules="validationRules.chargeForm.ClassificationTypeCode"
                      class="mt-4"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.Code }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </template>
              </v-row>

              <v-row dense>
                <v-col cols="auto">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>
                  <v-switch
                    v-model="editCharge.Active"
                    inset
                    :label="editCharge.Active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0 mr-4"
                    color="success"
                  />
                </v-col>

                <v-col
                  v-if="userIsSuperAdmin"
                  cols="auto"
                >
                  <v-subheader class="pl-0 mt-2">
                    System
                  </v-subheader>
                  <v-switch
                    v-model="editCharge.IsSystem"
                    inset
                    :label="editCharge.IsSystem ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="pa-0 ma-0 mr-4"
                    color="orange"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <translate-all-fields-button
            v-if="selectedLanguages.length > 1"
            :disabled="loading"
            :item="editCharge"
            :locale-from="defaultLanguage.Code"
            :field="['Name']"
          />

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData                  from '@/mixins/appdata'
import Auth                     from '@/mixins/auth'
import Translatable             from '@/mixins/translatable'
import TaxesCommon              from '@/mixins/taxes/taxesCommon'
import SourceType               from '@/mixins/orders/sourceType'
import { clone }                from '@/lib/helper/helper'
import TranslateFieldButton     from '@/components/common/translation/translateFieldButton.vue'
import TranslateAllFieldsButton from '@/components/common/translation/translateAllFieldsButton.vue'

export default {
  name      : 'ChargeAddDialog',
  components: {
    TranslateAllFieldsButton,
    TranslateFieldButton
  },
  mixins: [AppData, Auth, TaxesCommon, Translatable, SourceType],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    },
    taxes: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      languageTab    : 0,
      loading        : false,
      chargeFormValid: false,
      newCharge      : null,
      tmpCharge      : {
        Id                               : null,
        Name                             : '',
        Lang                             : {},
        Price                            : '0.0',
        Quantity                         : 1,
        Priority                         : 1,
        IsSystem                         : false,
        Active                           : true,
        TaxId                            : null,
        TaxRate                          : null,
        TaxLang                          : null,
        FeeCategoryCode                  : null,
        ClassificationTypeCode           : null,
        ClassificationCategoryCode       : null,
        ClassificationTypeCodeInvoice    : null,
        ClassificationCategoryCodeInvoice: null,
        OrderRestriction                 : '0',
        SourceType                       : [0]
      },
      validationRules: {
        chargeForm: {
          Name: [
            (v) => (this.selectedLanguages.length > 1 ? this.selectedLanguages.some(lang => this.editCharge.Lang[lang.Code].Name) : true) || this.$t('Charge.AddEditDialog.Field.Name.Error.AtLeastOneLang'),
            (v) => (this.selectedLanguages.length > 1 ? !v || (v.length >= 2 && v.length <= 255) : v && (v.length >= 2 && v.length <= 255)) || this.$t('Charge.AddEditDialog.Field.Name.Error.Between')
          ],
          Priority: [
            (v) => v && String(v).length >= 1 && !isNaN(v) || this.$t('Charge.AddEditDialog.Field.Priority.Error.Valid')
          ],
          Price: [
            (v) => v && String(v).length >= 1 && parseFloat(v) > 0 && !isNaN(v) || this.$t('Charge.AddEditDialog.Field.Price.Error.Valid')
          ],
          Quantity: [
            (v) => v && String(v).length >= 1 && parseInt(v) > 0 && !isNaN(v) || this.$t('Charge.AddEditDialog.Field.Quantity.Error.Valid')
          ],
          SourceType: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Error.Required')
          ],
          TaxId: [
            (v) => !!v || this.$t('Charge.AddEditDialog.Field.Tax.Error.Required')
          ],
          FeeCategoryCode: [
            // (v) => !this.appSupportIncomeClassifications || (this.userCanViewReceipt && !!v) || this.$t('Charge.AddEditDialog.Field.FeeCategoryCode.Error.Required')
          ],
          ClassificationTypeCode: [
            // (v) => !this.appSupportIncomeClassifications || (this.userCanViewReceipt && !!v) || this.$t('Charge.AddEditDialog.Field.ClassificationTypeCode.Error.Required')
          ],
          ClassificationCategoryCode: [
            // (v) => !this.appSupportIncomeClassifications || (this.userCanViewReceipt && !!v) || this.$t('Charge.AddEditDialog.Field.ClassificationCategoryCode.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    sourceTypes () {
      const sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT) || []

      return sourceTypesDefault.filter(sourceType => sourceType.active || sourceType.source_type_id === 0).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })
    },

    feeDisabled () {
      if (!!this.editCharge?.ClassificationTypeCode ||
        !!this.editCharge?.ClassificationCategoryCode ||
        !!this.editCharge?.ClassificationTypeCodeInvoice ||
        !!this.editCharge?.ClassificationCategoryCodeInvoice) {
        return true
      }

      return false
    },
    classificationDisabled () {
      return !!this.editCharge?.FeeCategoryCode
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editCharge: {
      get () {
        if (this.itemToEdit) {
          this.newCharge = clone(this.itemToEdit)
        } else {
          this.newCharge = clone(this.tmpCharge)
        }

        const langObj = {}
        for (const lang of this.selectedLanguages) {
          langObj[lang.Code] = {
            Name  : '',
            Locale: lang.Code
          }
        }
        this.newCharge.Lang = Object.assign({}, langObj, this.newCharge.Lang)

        // this.newCharge.TaxId = this.newCharge.TaxId ? this.newCharge.TaxId : this.defaultTax.TaxId
        // this.newCharge.ClassificationTypeCode = this.newCharge.ClassificationTypeCode ? this.newCharge.ClassificationTypeCode : this.defaultClassification.ClassificationTypeCode
        // this.newCharge.ClassificationCategoryCode = this.newCharge.ClassificationCategoryCode ? this.newCharge.ClassificationCategoryCode : this.defaultClassification.ClassificationCategoryCode

        return this.newCharge
      }
    }
  },
  watch: {
    'editCharge.TaxId': function (newVal) {
      const tax = this.taxesList.find(t => t.Id === newVal)
      this.editCharge.TaxLang = tax?.Lang || null
      this.editCharge.TaxRate = tax?.Rate || null
    },
    'editCharge.FeeCategoryCode': function (newVal) {
      if (newVal) {
        this.editCharge.ClassificationTypeCode = null
        this.editCharge.ClassificationCategoryCode = null
        this.editCharge.ClassificationTypeCodeInvoice = null
        this.editCharge.ClassificationCategoryCodeInvoice = null
      }
    },
    'editCharge.ClassificationTypeCode': function (newVal) {
      if (newVal) this.editCharge.FeeCategoryCode = null
    },
    'editCharge.ClassificationCategoryCode': function (newVal) {
      if (newVal) this.editCharge.FeeCategoryCode = null
    },
    'editCharge.ClassificationTypeCodeInvoice': function (newVal) {
      if (newVal) this.editCharge.FeeCategoryCode = null
    },
    'editCharge.ClassificationCategoryCodeInvoice': function (newVal) {
      if (newVal) this.editCharge.FeeCategoryCode = null
    },

    'editCharge.SourceType': function (newVal, oldVal) {
      if (newVal.length > 1) {
        if (newVal.includes(0) && !oldVal.includes(0)) {
          this.editCharge.SourceType = [0]
        } else if (newVal.includes(0)) {
          this.editCharge.SourceType = newVal.filter(sourceType => sourceType !== 0)
        }
      }
    },

    visible (newVal) {
      this.languageTab = 0
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.chargeForm) this.$refs.chargeForm.resetValidation()
        })

        setTimeout(function () {
          const evt = document.createEvent('UIEvents')
          evt.initUIEvent('resize', true, false, window, 0)
          window.dispatchEvent(evt)
        }, 250)
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.ExtraCharge.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.ExtraCharge.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.chargeForm.validate()

      this.$nextTick(() => {
        if (this.chargeFormValid) {
          this.loading = true

          this.editCharge.Name = this.editCharge.Lang[this.defaultLanguage.Code].Name

          window.callAS(window.SocketCommand.ExtraCharge.Save, this.editCharge)
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.FieldError'),
            type: 'error',
            icon: 'warning'
          })
        }
      })
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Error.Generic'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.chargeForm) this.$refs.chargeForm.resetValidation()
      this.newCharge = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
.vc-swatches {
  min-width  : 472px;
  height     : auto;
  overflow-y : auto;
  zoom       : 75%;
}

/deep/ .vc-swatches .vc-swatches-box div.vc-swatches-color-group:last-child {
  display : none;
}

/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

</style>
