<template>
  <v-card>
    <v-toolbar
      class="datatable-object settings-window-toolbar"
      flat
      height="80"
    >
      <v-avatar
        :color="$t('Settings.Domain.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Domain.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $tc('Settings.Domain.Title', 0) }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Domain.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      class="pa-1"
    >
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar
            class="filter-toolbar"
            flat
          >
            <template #default>
              <v-btn
                v-if="userCanAdd"
                :color="$t('Settings.Domain.Button.Add.Color')"
                :icon="$vuetify.breakpoint.xsOnly"
                class="elevation-0 ma-1"
                text
                @click="onAddDomainClick"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">
                  {{ $t('Settings.Domain.Button.Add.Icon') }}
                </v-icon>&nbsp;
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                  $t('Settings.Domain.Button.Add.Title').toLocaleUpperCase($i18n.locale)
                }}</span>
              </v-btn>

              <v-spacer />

              <filter-search v-model="dataTable.search.terms" />

              <v-menu
                v-model="filtersMenu"
                :close-on-content-click="false"
                max-width="800"
                :min-width="$vuetify.breakpoint.mdAndUp ? '800' : '250'"
                offset-y
              >
                <template #activator="{on: menu}">
                  <v-badge
                    :value="activeFiltersCount"
                    color="purple"
                    overlap
                  >
                    <template #badge>
                      <span>{{ activeFiltersCount }}</span>
                    </template>

                    <v-btn
                      :height="$vuetify.breakpoint.xsOnly ? 36 : 48"
                      :width="$vuetify.breakpoint.xsOnly ? 36 : 48"
                      class="pa-0 mr-0 elevation-0"
                      :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
                      min-height="36"
                      min-width="36"
                      v-on="{...menu}"
                    >
                      <v-icon
                        size="24"
                        color="primary"
                        v-text="'filter_alt'"
                      />
                    </v-btn>
                  </v-badge>
                </template>

                <v-card
                  max-width="800"
                  min-width="320"
                >
                  <v-card-text class="pa-0">
                    <v-container
                      class="pa-3"
                      fluid
                    >
                      <v-row dense>
                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Domain Active
                          </p>
                          <v-btn-toggle
                            v-model="filters.Active"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Allow IFrame
                          </p>
                          <v-btn-toggle
                            v-model="filters.AllowIFrame"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            CName Active
                          </p>
                          <v-btn-toggle
                            v-model="filters.CNameActive"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Voip Active
                          </p>
                          <v-btn-toggle
                            v-model="filters.VoipDomainActive"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has Landing Page
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasLandingPage"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Is Demo
                          </p>
                          <v-btn-toggle
                            v-model="filters.IsDemo"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Qr Only
                          </p>
                          <v-btn-toggle
                            v-model="filters.QrOnly"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has Trial
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasTrial"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Trial Expired
                          </p>
                          <v-btn-toggle
                            v-model="filters.TrialExpired"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has Subscription
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasSubscription"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Subscription Expired
                          </p>
                          <v-btn-toggle
                            v-model="filters.SubscriptionExpired"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Is Lead
                          </p>
                          <v-btn-toggle
                            v-model="filters.IsLead"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Is Customer
                          </p>
                          <v-btn-toggle
                            v-model="filters.IsCustomer"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            ENV
                          </p>
                          <v-btn-toggle
                            v-model="filters.Env"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              height="40"
                              text
                              value="TRIAL"
                            >
                              TRIAL
                            </v-btn>
                            <v-btn
                              height="40"
                              text
                              value="DEV"
                            >
                              DEV
                            </v-btn>
                            <v-btn
                              height="40"
                              text
                              value="LIVE"
                            >
                              LIVE
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has Receipts
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasReceipts"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has External Platform
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasPlatform"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has Mobile App
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasMobileApp"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has Marketing
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasMarketing"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Has Locked
                          </p>
                          <v-btn-toggle
                            v-model="filters.HasLocked"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <p class="pb-0 mb-1">
                            Extended Period
                          </p>
                          <v-btn-toggle
                            v-model="filters.ExtendedPeriod"
                            class="elevation-0"
                            mandatory
                            style="min-width: 250px;"
                          >
                            <v-btn
                              :value="null"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.All') }}
                            </v-btn>
                            <v-btn
                              :value="true"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Active') }}
                            </v-btn>
                            <v-btn
                              :value="false"
                              height="40"
                              text
                            >
                              {{ $t('Common.Button.Toggle.Inactive') }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />

                    <v-btn
                      color="primary"
                      text
                      @click="filtersMenu = false"
                    >
                      Ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>

            <template #extension>
              <v-spacer />

              <v-autocomplete
                v-model="filters.ResellerId"
                :item-text="(item) => `${item.FullName} - ${item.CompanyName} / ${item.Area}`"
                :items="resellersList"
                :label="$t('Settings.Domain.Filters.Reseller.Label')"
                cache-items
                class="pl-2"
                clearable
                filled
                flat
                hide-details
                item-value="Id"
                single-line
                style="max-width: 220px;"
              >
                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.FullName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.CompanyName }} / {{ item.Area }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>

              <v-autocomplete
                v-model="filters.PackageId"
                :item-text="(p) => `#${p.Id} - ${p.Name}`"
                :items="settings ? settings.Packages : []"
                :label="$t('Settings.Domain.Filters.Package.Label')"
                cache-items
                class="pl-2"
                clearable
                filled
                flat
                hide-details
                item-value="Id"
                single-line
                style="max-width: 220px;"
              />

              <v-autocomplete
                v-model="filters.StoreGroup"
                :item-text="(data) => data.Lang && data.Lang[$i18n.locale] && data.Lang[$i18n.locale].Title || data.Title"
                :items="typesList"
                :label="$t('Settings.Domain.Filters.StoreGroup.Label')"
                cache-items
                class="pl-2"
                clearable
                filled
                flat
                hide-details
                item-value="GroupId"
                single-line
                style="max-width: 220px;"
              />

              <v-autocomplete
                v-model="filters.CountryId"
                :items="countryList"
                :label="$t('Settings.Domain.Edit.Field.Country.Label')"
                cache-items
                class="pl-2"
                clearable
                filled
                flat
                hide-details
                item-text="name"
                item-value="id"
                single-line
                style="max-width: 220px;"
              >
                <template #prepend-inner>
                  <v-avatar
                    v-if="locale"
                    size="22"
                    tile
                  >
                    <v-img :src="`/assets/flags/1x1/${String(locale).toLowerCase()}.svg`" />
                  </v-avatar>
                </template>

                <template #item="data">
                  <v-list-item-avatar size="34">
                    <v-img :src="`/assets/flags/1x1/${String(data.item.code).toLowerCase()}.svg`" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name" />
                    <v-list-item-subtitle
                      class="text-caption"
                      v-html="data.item.desc"
                    />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <v-data-table
                  :expanded.sync="expand"
                  :footer-props="dataTable.footerProps"
                  :headers="dataTableHeaders"
                  :height="dataTable.resize.bodyHeight"
                  :items="dataTable.data"
                  :loading="dataTable.loading"
                  :loading-text="dataTable.text.loadingText"
                  :no-data-text="dataTable.text.noDataText"
                  :no-results-text="dataTable.text.noResultsText"
                  :options.sync="dataTable.options"
                  :search="dataTable.search.terms"
                  :server-items-length="dataTable.total"
                  fixed-header
                  item-key="Id"
                  show-expand
                  single-expand
                >
                  <template #[`item.id`]="{item}">
                    <div
                      class="d-block"
                      style="vertical-align: middle;"
                    >
                      <img
                        v-if="item.CountryId"
                        :src="`/assets/flags/4x3/${String(getLocaleByCountryId(item.CountryId)).toLowerCase()}.svg`"
                        :title="getCountryNameByCountryId(item.CountryId)"
                        height="auto"
                        width="25"
                      >
                    </div>

                    {{ item.Id }}
                  </template>

                  <template #[`item.domain`]="{item}">
                    <div class="text-no-wrap">
                      <v-icon
                        :color="item.Active ? item.Locked ? 'warning' : 'light-green' : 'grey lighten-3'"
                        class="mr-1"
                        style="vertical-align: middle;"
                        small
                      >
                        {{ item.Locked ? 'mdi-lock' : 'mdi-lock-open-variant-outline' }}
                      </v-icon>

                      <v-icon
                        :color="item.Active ? item.IsDemo ? 'orange' : 'light-green' : 'grey lighten-3'"
                        class="mr-1"
                        style="vertical-align: middle;"
                        small
                      >
                        check_circle
                      </v-icon>

                      <a
                        :href="`https://${getDomainName(item)}`"
                        target="_blank"
                      >{{ getDomainName(item) | truncate(35) }}</a>
                    </div>

                    <div
                      v-if="item.CName"
                      class="text-no-wrap"
                    >
                      <v-icon
                        :color="item.CNameActive ? 'light-blue' : 'grey lighten-3'"
                        class="ml-5 mr-1"
                        style="vertical-align: middle;"
                        small
                      >
                        check_circle
                      </v-icon>

                      <a
                        :href="`https://${item.CName}`"
                        target="_blank"
                      >{{ item.CName }}</a>
                    </div>

                    <div
                      v-if="item.LandingPageUrl"
                      class="text-no-wrap"
                    >
                      <v-icon
                        :color="item.HasLandingPage ? 'purple' : 'grey lighten-3'"
                        class="ml-5 mr-1"
                        small
                        style="vertical-align: middle;"
                      >
                        check_circle
                      </v-icon>

                      <a
                        :href="`${item.LandingPageUrl}`"
                        target="_blank"
                      >{{ item.LandingPageUrl.replace('https://', '') }}</a>
                    </div>
                  </template>

                  <template #[`item.db_host`]="{item}">
                    <v-chip
                      v-if="item.Env"
                      :color="item.Env === 'TRIAL' ? 'orange' : item.Env === 'LIVE' ? 'green' : item.Env === 'DEV' ? 'grey darken-2' : ''"
                      class="text-center justify-center align-center"
                      style="min-width: 60px;"
                      dark
                      label
                      small
                    >
                      {{ item.Env }}
                    </v-chip>
                  </template>

                  <template #[`item.customer`]="{item}">
                    <div v-if="item.CustomerId">
                      <strong>CUST:</strong> <a
                        :href="`https://crm.getfoodpro.gr/admin/clients/client/${item.CustomerId}`"
                        target="_blank"
                      >#{{ item.CustomerId }}</a>
                    </div>

                    <template v-if="item.LeadId">
                      <div>
                        <strong>LEAD:</strong> <a
                          :href="`https://crm.getfoodpro.gr/admin/leads/index/${item.LeadId}`"
                          target="_blank"
                        >#{{ item.LeadId }}</a>
                      </div>

                      <div>
                        <a
                          :href="`https://dapi.getfoodpro.gr/v2/raw/customer/${item.Id}/${authorizationToken}`"
                          target="_blank"
                        >RAW DATA</a>
                      </div>
                    </template>
                  </template>

                  <template #[`item.reseller`]="{item}">
                    <a
                      v-if="item.ResellerCrmId"
                      :href="`https://crm.getfoodpro.gr/admin/clients/client/${item.ResellerCrmId}`"
                      target="_blank"
                    >
                      <div>{{ item.ResellerName }}</div>
                      <div>{{ item.ResellerCompanyName }} / {{ item.ResellerArea }}</div>
                    </a>
                  </template>

                  <template #[`item.updated_at`]="{item}">
                    <div class="text-no-wrap">
                      <strong v-if="item.DateCreated">Created:</strong> {{ item.DateCreated }}
                    </div>
                    <div class="text-no-wrap">
                      <strong v-if="item.DateUpdated">Updated:</strong> {{ item.DateUpdated }}
                    </div>
                    <div class="text-no-wrap">
                      <strong v-if="item.TrialExpired">Expires:</strong> {{ item.TrialExpired }}
                    </div>
                  </template>

                  <template #[`item.season_ended_at`]="{item}">
                    <template v-if="item.Season">
                      <div class="text-no-wrap">
                        <strong>Start</strong>: {{ item.SeasonStartedAt }}
                      </div>
                      <div class="text-no-wrap">
                        <strong>End</strong>: {{ item.SeasonEndedAt }}
                      </div>
                      <div class="text-no-wrap">
                        <strong>Months</strong>: {{ item.Season }}
                      </div>
                    </template>
                  </template>

                  <template #[`item.extended_period_at`]="{item}">
                    <div
                      v-if="item.Locked && item.ExtendedPeriodAt"
                      class="text-no-wrap"
                    >
                      {{ item.ExtendedPeriodAt }} (<span class="red--text">{{ item.ExtendedPeriodDays }} Days</span>)
                    </div>
                  </template>

                  <template #[`item.subscription_expired_at`]="{item}">
                    <div><strong>#{{ item.PackageId }} - {{ item.PackageName }}</strong></div>

                    <div v-if="item.TrialExpiredAt">
                      <strong>Trial </strong> {{ item.TrialExpiredAt }} (<span
                        :class="[{'red--text': item.TrialExpiredDaysIn <= 3 && !item.SubscriptionExpiredAt}]"
                      >{{
                        item.TrialExpiredDaysIn
                      }} Days</span>)
                    </div>

                    <div v-if="item.SubscriptionExpiredAt">
                      <strong>Subscription </strong> {{ item.SubscriptionExpiredAt }} (<span
                        :class="[{'red--text': item.SubscriptionExpiredDaysIn <= 7}]"
                      >{{
                        item.SubscriptionExpiredDaysIn
                      }} Days</span>)
                    </div>
                  </template>

                  <template #[`item.iframe`]="{item}">
                    <v-icon
                      :color="item.AllowIFrame ? 'light-green' : 'grey lighten-3'"
                      class="mr-1"
                      size="21"
                      style="vertical-align: middle;"
                    >
                      check_circle
                    </v-icon>
                  </template>

                  <template #[`item.voip`]="{item}">
                    <v-icon
                      :color="item.VoipDomainActive ? 'light-green' : 'grey lighten-3'"
                      class="mr-1"
                      size="21"
                      style="vertical-align: middle;"
                    >
                      dialer_sip
                    </v-icon>

                    <span v-if="item.VoipDomain">{{ item.VoipDomain }}</span>
                    <span v-if="item.VoipDomain && item.VoipDomainDescription"> - </span>
                    <span v-if="item.VoipDomainDescription">{{ item.VoipDomainDescription }}</span>
                  </template>

                  <template #[`item.log`]="{item}">
                    <div class="text-no-wrap">
                      <v-icon
                        :color="item.Logging.ENABLED && item.Logging.BUGSNAG.DESKTOP ? 'light-green' : 'grey lighten-3'"
                        class="mr-1"
                        size="21"
                        style="vertical-align: middle;"
                      >
                        desktop_windows
                      </v-icon>
                      <v-icon
                        :color="item.Logging.ENABLED && item.Logging.BUGSNAG.WEB ? 'light-green' : 'grey lighten-3'"
                        class="mr-1"
                        quick-a
                        size="21"
                        style="vertical-align: middle;"
                      >
                        language
                      </v-icon>

                      <span>BUGSNAG</span>
                    </div>

                    <div class="text-no-wrap">
                      <v-icon
                        :color="item.Logging.ENABLED && item.Logging.LOGROCKET.DESKTOP ? 'light-green' : 'grey lighten-3'"
                        class="mr-1"
                        size="21"
                        style="vertical-align: middle;"
                      >
                        desktop_windows
                      </v-icon>
                      <v-icon
                        :color="item.Logging.ENABLED && item.Logging.LOGROCKET.WEB ? 'light-green' : 'grey lighten-3'"
                        class="mr-1"
                        size="21"
                        style="vertical-align: middle;"
                      >
                        language
                      </v-icon>

                      <span>LOGROCKET</span>
                    </div>
                  </template>

                  <template #item.data-table-expand="props">
                    <v-speed-dial
                      v-model="props.item.fab"
                      class="d-inline-block"
                      direction="left"
                      transition="slide-x-reverse-transition"
                    >
                      <template #activator>
                        <v-btn
                          v-model="props.item.fab"
                          class="blue darken-2 elevation-2 ma-0 mr-1"
                          dark
                          fab
                          icon
                          style="width: 32px; height: 32px;"
                        >
                          <v-icon v-if="!props.item.fab">
                            menu
                          </v-icon>
                          <v-icon v-else>
                            close
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-tooltip
                        color="blue"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage && userIsSuperAdmin && props.item.HasDummyData"
                            class="blue elevation-2 ma-0 mr-1"
                            dark
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onBackupDomainClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>backup</v-icon>
                          </v-btn>
                        </template>
                        <span>Backup Domain</span>
                      </v-tooltip>

                      <v-tooltip
                        color="light-green"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage && userIsSuperAdmin"
                            class="light-green elevation-2 ma-0 mr-1 white--text"
                            disabled
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onManageDomainUsersClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>people</v-icon>
                          </v-btn>
                        </template>

                        <span>Manage Domain Users</span>
                      </v-tooltip>

                      <v-tooltip
                        color="green"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage"
                            class="green elevation-2 ma-0 mr-1 white--text"
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onDomainClearCache(props.item)"
                            v-on="on"
                          >
                            <v-icon>cached</v-icon>
                          </v-btn>
                        </template>

                        <span>Clear Cache</span>
                      </v-tooltip>

                      <v-tooltip
                        color="light-blue"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage"
                            class="light-blue elevation-2 ma-0 mr-1 white--text"
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onManageDomainCustomerClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>person</v-icon>
                          </v-btn>
                        </template>

                        <span>Customer</span>
                      </v-tooltip>

                      <v-tooltip
                        color="teal"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage && userIsSuperAdmin"
                            class="teal elevation-2 ma-0 mr-1 white--text"
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onManageDomainPaymentFeeClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>payment</v-icon>
                          </v-btn>
                        </template>

                        <span>Payment Fee</span>
                      </v-tooltip>

                      <v-tooltip
                        color="orange"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage && userIsSuperAdmin"
                            class="orange elevation-2 ma-0 mr-1"
                            dark
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onCopyDomainDataClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>content_copy</v-icon>
                          </v-btn>
                        </template>

                        <span>Copy Domain</span>
                      </v-tooltip>

                      <v-tooltip
                        color="blue-grey"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage && userIsSuperAdmin"
                            class="blue-grey elevation-2 ma-0 mr-1 white--text"
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onDomainDatabaseMoveClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>storage</v-icon>
                          </v-btn>
                        </template>

                        <span>DB Move</span>
                      </v-tooltip>

                      <v-tooltip
                        color="purple"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage && userIsSuperAdmin"
                            class="purple elevation-2 ma-0 mr-1"
                            dark
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onImportForDomainClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>import_export</v-icon>
                          </v-btn>
                        </template>

                        <span>Import External Catalog</span>
                      </v-tooltip>

                      <v-tooltip
                        color="blue"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage"
                            class="blue elevation-2 ma-0 mr-1"
                            dark
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onCustomerEmail(props.item)"
                            v-on="on"
                          >
                            <v-icon>email</v-icon>
                          </v-btn>
                        </template>

                        <span>Customer Welcome Email</span>
                      </v-tooltip>

                      <v-tooltip
                        color="red"
                        open-delay="1000"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            v-if="userCanManage && userCanDelete && userIsSuperAdmin"
                            class="red elevation-2 ma-0 mr-1"
                            dark
                            icon
                            style="width: 32px; height: 32px;"
                            @click="onDeleteDomainClick(props.item)"
                            v-on="on"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>

                        <span>Delete Domain</span>
                      </v-tooltip>
                    </v-speed-dial>

                    <v-tooltip
                      color="blue-grey"
                      open-delay="1000"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          v-if="userCanManage && userIsSuperAdmin"
                          class="blue-grey elevation-2 ma-0 mr-1"
                          dark
                          icon
                          style="width: 32px; height: 32px;"
                          @click="onChangeStaffDomain(props.item)"
                          v-on="on"
                        >
                          <v-icon>mdi-web-refresh</v-icon>
                        </v-btn>
                      </template>

                      <span>Login to Domain</span>
                    </v-tooltip>

                    <v-tooltip
                      color="cyan"
                      open-delay="1000"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          v-if="userCanManage && userIsSuperAdmin"
                          class="cyan elevation-2 ma-0 mr-1"
                          dark
                          icon
                          style="width: 32px; height: 32px;"
                          @click="onEditDomainClick(props.item)"
                          v-on="on"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>

                      <span>Edit Domain</span>
                    </v-tooltip>

                    <v-tooltip
                      color="grey"
                      open-delay="1000"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          v-if="userCanManage && userIsSuperAdmin"
                          :class="domainSocketClients(props.item) ? 'blue' : 'grey'"
                          class="elevation-2 ma-0"
                          dark
                          icon
                          style="width: 32px; height: 32px;"
                          @click="props.expand(!props.isExpanded)"
                          v-on="on"
                        >
                          <v-icon>{{ !props.isExpanded ? 'expand_more' : 'expand_less' }}</v-icon>
                        </v-btn>
                      </template>

                      <span>Expand</span>
                    </v-tooltip>
                  </template>

                  <template #expanded-item="{headers, item}">
                    <td :colspan="headers.length">
                      <div class="divTable my-2">
                        <div class="divTableHeading">
                          <div class="divTableRow">
                            <div class="divTableHead text-center">
                              ID
                            </div>

                            <div class="divTableHead text-right">
                              UUID
                            </div>

                            <div class="divTableHead text-center">
                              VOIP AGENT
                            </div>

                            <div class="divTableHead text-center">
                              VOIP DOMAIN
                            </div>

                            <div class="divTableHead text-center">
                              SOCKET STATUS
                            </div>

                            <div class="divTableHead text-center">
                              ACTIVE
                            </div>

                            <div class="divTableHead text-center">
                              SINGLE LOGIN
                            </div>

                            <div class="divTableHead text-center">
                              LAST LOGIN
                            </div>

                            <div class="divTableHead text-center">
                              CREATED
                            </div>

                            <div class="divTableHead text-right" />
                          </div>
                        </div>

                        <div class="divTableBody">
                          <template v-for="user in domainUsers(item)">
                            <div
                              :key="`domain-${item.Id}-user-${user.id}`"
                              class="divTableRow"
                            >
                              <div class="divTableCell text-center">
                                {{ user.id }}
                              </div>

                              <div class="divTableCell text-right">
                                {{ user.uid }}
                              </div>

                              <div class="divTableCell text-center">
                                {{ user.voip_agent }}
                              </div>

                              <div class="divTableCell text-center">
                                {{ user.voip_domain }}
                              </div>

                              <div class="divTableCell text-center">
                                <v-icon
                                  :color="user.socket_status ? 'blue' : 'grey lighten-3'"
                                  size="24"
                                >
                                  sensors
                                </v-icon>
                              </div>

                              <div class="divTableCell text-center">
                                <v-icon
                                  :color="user.active ? 'light-green' : 'grey lighten-3'"
                                  size="21"
                                >
                                  check_circle
                                </v-icon>
                              </div>

                              <div class="divTableCell text-center">
                                <v-icon
                                  :color="user.single_login ? 'orange' : 'grey lighten-3'"
                                  size="21"
                                >
                                  how_to_reg
                                </v-icon>
                              </div>

                              <div class="divTableCell text-center">
                                {{ user.last_login }}
                              </div>

                              <div class="divTableCell text-center">
                                {{ user.created_at }}
                              </div>

                              <div class="divTableCell text-right text-no-wrap" />
                            </div>
                          </template>
                        </div>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 datatable-object settings-window-actions">
      <v-btn
        v-if="userIsSuperAdmin"
        class="primary"
        text
        @click="onBackupAllDomainClick"
      >
        {{ $t('Settings.Domain.Button.BackupAll').toLocaleUpperCase($i18n.locale) }}
      </v-btn>

      <v-btn
        v-if="userIsSuperAdmin"
        class="green"
        dark
        text
        @click="onDomainClearCache"
      >
        CLEAR CACHE
      </v-btn>

      <v-btn
        v-if="userIsSuperAdmin"
        class="orange"
        dark
        text
        @click="onRunSchedules"
      >
        RUN SCHEDULES
      </v-btn>

      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>

    <confirm-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :html-content="$t('Settings.Domain.Dialog.Backup.Content', {domain: getDomainName(selectedDomain)})"
      :html-title="$t('Settings.Domain.Dialog.Backup.Title')"
      :visible.sync="confirmDialogBackupVisible"
      no-button-class="v-btn--outline grey--text"
      title-class="info white--text lighten-0"
      yes-button-class="info white--text"
      yes-button-event="on-confirm-backup"
      @on-confirm-backup="onConfirmBackup"
    />

    <confirm-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :html-content="$t('Settings.Domain.Dialog.BackupAll.Content', {domain: getDomainName(selectedDomain)})"
      :html-title="$t('Settings.Domain.Dialog.BackupAll.Title')"
      :visible.sync="confirmDialogBackupAllVisible"
      no-button-class="v-btn--outline grey--text"
      title-class="info white--text lighten-0"
      yes-button-class="info white--text"
      yes-button-event="on-confirm-backup"
      @on-confirm-backup="onConfirmBackupAll"
    />

    <domain-delete-dialog
      v-if="userCanManage && userCanDelete && userIsSuperAdmin"
      :visible.sync="confirmDialogDeleteVisible"
      :selected-domain="selectedDomain"
      @on-confirm-delete="onConfirmDelete"
    />

    <domain-edit-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :countries="settings ? settings.Countries : []"
      :databases="databasesList"
      :domain="selectedDomain"
      :domains="settings ? settings.Domains : []"
      :resellers="resellersList"
      :types="settings ? typesList : []"
      :visible.sync="editDialogVisible"
      @domain-save="onSaveDomain"
    />

    <domain-copy-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :domain="selectedDomain"
      :domains="settings ? settings.Domains : []"
      :visible.sync="copyDialogVisible"
    />

    <aggregator-import-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :domain="selectedDomain"
      :visible.sync="importDialogVisible"
    />

    <customer-email-dialog
      :domain="selectedDomain"
      :visible.sync="customerEmailDialogVisible"
    />

    <payment-fee-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :domain="selectedDomain"
      :visible.sync="paymentFeeDialogVisible"
    />

    <domain-customer-dialog
      :countries="settings ? settings.Countries : []"
      :domain="selectedDomain"
      :resellers="resellersList"
      :visible.sync="customerDialogVisible"
    />

    <domain-database-move
      :databases="databasesList"
      :domain="selectedDomain"
      :visible.sync="databaseMoveDialogVisible"
    />
  </v-card>
</template>

<script>
import AppData                from '@/mixins/appdata'
import Auth                   from '@/mixins/auth'
import Watchable              from '@/mixins/watchable'
import DomainModel            from '@/api/Models/settings/DomainModel'
import DomainEditDialog       from './domains/DomainEditDialog'
import AggregatorImportDialog from './domains/AggregatorImportDialog'
import DomainCopyDialog       from './domains/DomainCopyDialog'
import ConfirmDialog          from '../common/ConfirmDialog'
import CustomerEmailDialog    from '@/components/settings/domains/CustomerEmailDialog'
import PaymentFeeDialog       from '@/components/settings/domains/PaymentFeeDialog'
import DomainCustomerDialog   from '@/components/settings/domains/DomainCustomerDialog'
import DomainDatabaseMove     from '@/components/settings/domains/DomainDatabaseMove.vue'
import SocketCommand          from '@/api/SocketCommand'
import FilterSearch           from '@/components/common/filter/FilterSearch.vue'
import DataTable              from '@/mixins/data-table/dataTable'
import DomainDeleteDialog     from '@/components/settings/domains/DomainDeleteDialog.vue'

export default {
  name      : 'SystemSettingsDomains',
  components: {
    DomainDeleteDialog,
    FilterSearch,
    DomainDatabaseMove,
    DomainCustomerDialog,
    PaymentFeeDialog,
    'confirm-dialog': ConfirmDialog,
    CustomerEmailDialog,
    AggregatorImportDialog,
    DomainCopyDialog,
    DomainEditDialog
  },
  mixins: [AppData, Auth, Watchable, DataTable],
  data () {
    return {
      expand       : [false],
      socketClients: [],
      search       : '',
      filtersMenu  : false,
      filters      : {
        Active             : null,
        PackageId          : null,
        ResellerId         : null,
        StoreGroup         : null,
        CountryId          : null,
        AllowIFrame        : null,
        CNameActive        : null,
        VoipDomainActive   : null,
        IsDemo             : null,
        QrOnly             : null,
        HasTrial           : null,
        TrialExpired       : null,
        HasSubscription    : null,
        SubscriptionExpired: null,
        IsLead             : null,
        IsCustomer         : null,
        Env                : null,
        HasLandingPage     : null,
        HasPlatform        : null,
        HasMobileApp       : null,
        HasReceipts        : null,
        HasMarketing       : null,
        HasLocked          : null,
        ExtendedPeriod     : null
      },
      dataTable: {
        socketCommand: {
          list: window.SocketCommand.Settings.Domains.All
        },
        resize: {
          offset: 42
        },
        options: {
          defaultSortBy: 'id',
          sortBy       : ['id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : ['description'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : '#',
            value    : 'id',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'DOMAIN',
            value    : 'domain',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'ENV',
            value    : 'db_host',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : 'CUSTOMER',
            value    : 'customer',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : 'RESELLER',
            value    : 'reseller',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : 'DATE',
            value    : 'updated_at',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'SEASON',
            value    : 'season_ended_at',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'EXTENDED',
            value    : 'extended_period_at',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'PACKAGE',
            value    : 'subscription_expired_at',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'IFRAME',
            value    : 'iframe',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : 'VOIP',
            value    : 'voip',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : 'LOG',
            value    : 'log',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            value    : 'data-table-expand',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption text-no-wrap px-2'
          }
        ]
      },
      debounceSearchTimeout        : null,
      settings                     : null,
      selectedDomain               : null,
      editDialogVisible            : false,
      importDialogVisible          : false,
      copyDialogVisible            : false,
      paymentFeeDialogVisible      : false,
      confirmDialogBackupVisible   : false,
      confirmDialogBackupAllVisible: false,
      confirmDialogDeleteVisible   : false,
      customerEmailDialogVisible   : false,
      customerDialogVisible        : false,
      databaseMoveDialogVisible    : false
    }
  },
  computed: {
    activeFiltersCount () {
      return Object.values(this.filters).filter(v => v !== undefined && v !== null && v !== 'null').length
    },
    typesList () {
      return this.settings ? [
        ...[{ header: this.$t('Settings.Domain.Edit.Field.StoreGroup.Group.FoodIndustry') }, { divider: true }],
        ...this.settings.Types.filter(t => t.FoodIndustry),
        ...[{ header: this.$t('Settings.Domain.Edit.Field.StoreGroup.Group.Other') }, { divider: true }],
        ...this.settings.Types.filter(t => !t.FoodIndustry)
      ] : []
    },
    locale () {
      return this.getLocaleByCountryId(this.filters.CountryId)
    },
    countryList () {
      return this.settings?.Countries || []
    },
    databasesList () {
      return this.settings?.Databases || []
    },
    resellersList () {
      return this.settings?.Resellers || []
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(SocketCommand.System.Socket.Clients, this.onSocketClientsResult)
    this.$bus.$on(window.SocketCommand.Settings.Domains.Settings, this.onDomainSettingsResult)
    this.$bus.$on(window.SocketCommand.Settings.Domains.Save, this.onSaveDomainResult)
    this.$bus.$on(window.SocketCommand.Settings.Domains.Backup, this.onBackupDomainResult)
    this.$bus.$on(window.SocketCommand.Settings.Domains.CacheClear, this.onDomainClearCacheResult)
    this.$bus.$on(window.SocketCommand.Settings.Domains.PaymentFee, this.getData)
    this.$bus.$on(window.SocketCommand.Settings.Domains.Customer.Save, this.getData)
    this.$bus.$on(window.SocketCommand.Settings.Domains.RunSchedules, this.onRunSchedulesResult)

    this.getSettings()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(SocketCommand.System.Socket.Clients, this.onSocketClientsResult)
    this.$bus.$off(window.SocketCommand.Settings.Domains.Settings, this.onDomainSettingsResult)
    this.$bus.$off(window.SocketCommand.Settings.Domains.Save, this.onSaveDomainResult)
    this.$bus.$off(window.SocketCommand.Settings.Domains.Backup, this.onBackupDomainResult)
    this.$bus.$off(window.SocketCommand.Settings.Domains.CacheClear, this.onDomainClearCacheResult)
    this.$bus.$off(window.SocketCommand.Settings.Domains.PaymentFee, this.getData)
    this.$bus.$off(window.SocketCommand.Settings.Domains.Customer.Save, this.getData)
    this.$bus.$off(window.SocketCommand.Settings.Domains.RunSchedules, this.onRunSchedulesResult)
  },
  methods: {
    domainUsers (domain) {
      return domain?.Users || []
    },

    getLocaleByCountryId (countryId) {
      const country = this.countryList.find(country => country.id === countryId)
      return country ? (country.code === 'GR' ? 'EL' : country.code === 'GB' ? 'UK' : country.code) : ''
    },

    getCountryNameByCountryId (countryId) {
      const country = this.countryList.find(country => country.id === countryId)
      return country ? country.name : ''
    },

    getDomainName (d) {
      if (!d) return ''
      const Domain = d.Domain || ''
      const SubDomain = Domain.split('.').shift()
      const BaseDomain = Domain.split('.').slice(1).join('.').slice(0)
      return d ? `${ SubDomain }.${ d.BaseDomain || BaseDomain || '' }` : ''
    },

    onAddDomainClick () {
      this.selectedDomain = new DomainModel()
      this.editDialogVisible = true
    },

    onEditDomainClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.editDialogVisible = true
    },

    onDeleteDomainClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete (delay = 30) {
      window.callAS(window.SocketCommand.Settings.Domains.Delete, {
        Id          : this.selectedDomain.Id,
        DatabaseName: this.selectedDomain.DatabaseName,
        Delay       : parseInt(delay ?? 30)
      })
    },

    onCustomerEmail (d) {
      this.selectedDomain = new DomainModel(d)
      this.customerEmailDialogVisible = true
    },

    onBackupDomainClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.confirmDialogBackupVisible = true
    },

    onConfirmBackup () {
      window.callAS(window.SocketCommand.Settings.Domains.Backup, { Id: this.selectedDomain && this.selectedDomain.Id })
    },

    onBackupAllDomainClick () {
      this.selectedDomain = null
      this.confirmDialogBackupAllVisible = true
    },

    onConfirmBackupAll () {
      window.callAS(window.SocketCommand.Settings.Domains.Backup, { Id: null })
    },

    onBackupDomainResult (data) {
      if (data.status) {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Domain.Notification.Success'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Domain.Notification.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onManageDomainUsersClick (d) {
    },

    onRunSchedules () {
      window.callAS(window.SocketCommand.Settings.Domains.RunSchedules)
    },
    onRunSchedulesResult (data) {
      if (data.status) {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Domain.Notification.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onDomainClearCache (d) {
      this.selectedDomain = d ? new DomainModel(d) : null
      window.callAS(window.SocketCommand.Settings.Domains.CacheClear, { DomainId: this.selectedDomain && this.selectedDomain.Id })
    },
    onDomainClearCacheResult (data) {
      if (data.status) {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Domain.Notification.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onDomainDatabaseMoveClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.databaseMoveDialogVisible = true
    },

    onManageDomainCustomerClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.customerDialogVisible = true
    },

    onManageDomainPaymentFeeClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.paymentFeeDialogVisible = true
    },

    onCopyDomainDataClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.copyDialogVisible = true
    },

    onImportForDomainClick (d) {
      this.selectedDomain = new DomainModel(d)
      this.importDialogVisible = true
    },

    onDomainSettingsResult (data) {
      this.settings = data
    },

    onSocketClientsResult (data) {
      const items = JSON.parse(JSON.stringify(this.viewData?.items))
      this.socketClients = data || []

      for (let d = 0; d < items.length; d++) {
        const domain = items[d]

        for (let u = 0; u < domain?.Users?.length; u++) {
          const user = domain.Users[u]

          user.socket_status = data.includes(user.id)
        }
      }

      this.$set(this.viewData, 'items', items)
      this.$nextTick(() => {
        this.dataTable.data = this.viewData?.items || []
      })
    },

    domainSocketClients (domain) {
      if (!domain) return 0
      return domain?.Users?.find(user => this.socketClients.includes(user.id)) || 0
    },

    setData (data) {
      this.setDataInternal(data?.Domains)

      this.$nextTick(() => {
        this.watch('filters', this.onFiltersChange, true)
      })
    },

    onFiltersChange () {
      if (this.dataTable.options.page > 1) {
        this.dataTable.options.page = 1
      } else {
        this.getData()
      }
    },

    onChangeStaffDomain (item) {
      const domain = `${ item?.Domain }.${ item?.BaseDomain }`
      if (!item || domain === this.posUser.Domain || !this.userInAdminGroup) return

      this.$bus.$emit('show-app-loading-modal', true)
      window.callAS(window.SocketCommand.Staff.ChangeDomain, { Domain: domain })
    },

    onSaveDomain (domain, permissionsObj) {
      domain.save({
        Domain    : domain,
        StaffGroup: permissionsObj
      })
    },

    onSaveDomainResult (data) {
      if (data.status) {
        this.editDialogVisible = false
        this.getData()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Domain.Notification.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    getData () {
      if (this.filters.PackageId === 1) this.filters.PackageId = null
      this.getDataInternal()
    },

    getSettings () {
      window.callAS(window.SocketCommand.Settings.Domains.Settings, {})
    }
  }
}
</script>

<style scoped>
/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 75px !important;*/
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}

/deep/ .v-chip .v-chip__content {
  text-align : center;
}

/deep/ .v-toolbar__extension {
  padding : 0 6px !important;
}

/deep/ tr.v-data-table__expanded__content {
  box-shadow : none !important;
}

.divTableCell, .divTableHead {
  padding : 12px 6px;
}
</style>
