<template>
  <div class="d-inline-block print-receipt-button">
    <v-badge
      :color="canIssueDocument ? 'blue' : 'purple'"
      :offset-x="badgeOffsetX"
      :offset-y="badgeOffsetY"
      overlap
      right
    >
      <template #badge>
        <v-icon
          dark
          size="16"
          v-text="canIssueDocument ? 'mdi-plus' : 'mdi-eye'"
        />
      </template>

      <v-tooltip
        :bottom="tooltipPosition === 'bottom'"
        :top="tooltipPosition === 'top'"
        :left="tooltipPosition === 'left'"
        :right="tooltipPosition === 'right'"
        :disabled="disabled || disableTooltip"
        :open-delay="tooltipOpenDelay"
        :close-delay="tooltipCloseDelay"
        :color="canIssueDocument ? 'blue' : 'purple'"
      >
        <template #activator="{on: onTooltip}">
          <v-btn
            :class="[btnClass, icon ? 'orange' : '', {'grey lighten-2': disabled && icon}]"
            :color="icon ? '' : 'orange'"
            :style="btnStyle ? btnStyle : small ? icon ? `width: ${smallSize}px; height: ${smallSize}px;` : `height: ${smallSize}px;` : ''"
            :dark="dark"
            :icon="icon"
            :small="small"
            :x-small="xSmall"
            :large="large"
            :outlined="outline"
            :text="flat"
            :depressed="depressed"
            :absolute="absolute"
            :disabled="disabled"
            :block="block"
            :fixed="fixed"
            :fab="fab"
            v-on="onTooltip"
            @click="canIssueDocument ? onIssueDocumentClick() : onManageDocumentsClick()"
          >
            <v-icon
              v-if="!hideIcon"
              :small="small && smallIcon"
              :left="!!labelText && !hideLabel && !icon"
            >
              {{ canIssueDocument ? 'mdi-receipt-text-plus-outline' : 'mdi-receipt-text-check-outline' }}
            </v-icon>
            <span v-if="labelText && !hideLabel && !icon">{{ labelText }}</span>
          </v-btn>
        </template>

        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </v-badge>

    <table-order-fiscal-documents-dialog
      :visible.sync="tableOrderFiscalDocumentsDialogVisible"
      :table="table"
      :order="order"
      :receipts="receipts"
    />
  </div>
</template>

<script>
import Auth                            from '@/mixins/auth'
import AppData                         from '@/mixins/appdata'
import ReceiptTypeEnum                 from '@/api/Enums/ReceiptTypeEnum'
import NotifyTypeEnum                  from '@/api/Enums/NotifyTypeEnum'
import TableOrderFiscalDocumentsDialog from '@/components/restaurant/tables/tables/dialogs/TableOrderFiscalDocumentsDialog.vue'

export default {
  name      : 'ManageFiscalDocumentsButton',
  components: {
    TableOrderFiscalDocumentsDialog
  },
  directives: {},
  mixins    : [AppData, Auth],
  props     : {
    hideLabel: {
      type   : Boolean,
      default: false
    },
    btnClass: {
      type   : String,
      default: 'white--text'
    },
    btnStyle: {
      type   : String,
      default: ''
    },
    icon: {
      type   : Boolean,
      default: false
    },
    hideIcon: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    },
    xSmall: {
      type   : Boolean,
      default: false
    },
    smallIcon: {
      type   : Boolean,
      default: false
    },
    smallSize: {
      type   : [Number, String],
      default: 32
    },
    large: {
      type   : Boolean,
      default: false
    },
    outline: {
      type   : Boolean,
      default: false
    },
    flat: {
      type   : Boolean,
      default: false
    },
    depressed: {
      type   : Boolean,
      default: false
    },
    absolute: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    block: {
      type   : Boolean,
      default: false
    },
    fixed: {
      type   : Boolean,
      default: false
    },
    fab: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    tooltipPosition: {
      type   : String,
      default: 'top'
    },
    tooltipOpenDelay: {
      type   : Number,
      default: 150
    },
    tooltipCloseDelay: {
      type   : Number,
      default: 150
    },
    disableTooltip: {
      type   : Boolean,
      default: false
    },
    badgeOffsetX: {
      type   : [Number, String],
      default: undefined
    },
    badgeOffsetY: {
      type   : [Number, String],
      default: undefined
    },
    receipts: {
      type   : Array,
      default: () => []
    },
    table: {
      type   : Object,
      default: undefined
    },
    order: {
      type   : Object,
      default: undefined
    },
    canIssueDocument: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      menuActive                            : false,
      tableOrderFiscalDocumentsDialogVisible: false
    }
  },
  computed: {
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },

    tooltipText () {
      return this.canIssueDocument ? this.$t('Common.Button.IssueFnb') : this.$t('Common.Button.ManageDocuments')
    },

    labelText () {
      return this.canIssueDocument ? this.$t('Common.Button.IssueFnb').toLocaleUpperCase(this.$i18n.locale) : this.$t('Common.Button.ManageDocuments')
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onIssueDocumentClick () {
      this.$emit('issue-document', {
        DocId         : null,
        DocType       : ReceiptTypeEnum.FNB,
        Print         : true,
        Email         : false,
        EmailAddresses: [],
        Company       : null
      })
    },

    onManageDocumentsClick () {
      this.tableOrderFiscalDocumentsDialogVisible = true
      this.$emit('manage-documents')
    }
  }
}
</script>

<style scoped>

</style>
