<template>
  <v-dialog
    v-if="ticket"
    v-model="isVisible"
    :fullscreen="isFullScreen"
    max-width="850"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        height="80"
        max-height="80"
      >
        <v-avatar
          :color="ticket.status.color ? ticket.status.color : 'indigo'"
          dark
        >
          <h2 class="white--text">
            {{ $parent.getTicketAvatarStatus(ticket) }}
          </h2>
        </v-avatar>

        <v-toolbar-title
          class="pl-3"
        >
          <div class="body-3">
            {{ ticket.subject }}
          </div>

          <div class="text-caption">
            {{ ticket.service.name }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-title
          class="px-4"
        >
          <div class="text-caption text-right">
            {{ ticket.date }} {{ ticket.time }}
          </div>

          <div>
            <v-chip
              dark
              label
              outlined
              small
              class="ml-1"
            >
              {{ ticket.department.name }}
            </v-chip>

            <v-chip
              :color="ticket.status.color"
              dark
              label
              outlined
              small
              class="ml-1"
            >
              {{ ticket.status.name }}
            </v-chip>

            <v-chip
              :color="$parent.getPriorityColor(ticket.priority.priorityid)"
              class="ml-1"
              dark
              label
              outlined
              small
            >
              {{ ticket.priority.name }}
            </v-chip>
          </div>
        </v-toolbar-title>

        <v-btn
          class="ml-0"
          icon
          @click="onCancelButtonClick"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider />

      <v-card-text
        v-dynamic-router-link
        class="fill-height pa-3"
      >
        <v-timeline
          v-if="ticket.replies"
          align-top
          dense
        >
          <v-timeline-item
            class="white--text mb-12"
            color="grey"
            fill-dot
            large
          >
            <template #icon>
              <span>YOU</span>
            </template>

            <v-text-field
              v-model="$parent.replyMessage"
              hide-details
              label="Reply to this ticket..."
              solo
              @keydown.enter="$parent.onReplyMessage"
            >
              <template #append>
                <v-btn
                  class="mx-0"
                  depressed
                  @click="$parent.onReplyMessage"
                >
                  REPLY
                </v-btn>
              </template>
            </v-text-field>
          </v-timeline-item>

          <v-slide-x-transition group>
            <v-timeline-item
              v-for="reply in ticket.replies"
              :key="reply.id"
              :color="parseInt(reply.admin) > 0 ? 'info' : 'green'"
              class="white--text"
              large
            >
              <template #icon>
                <span>{{ parseInt(reply.admin) > 0 ? 'GFP' : 'YOU' }}</span>
              </template>

              <v-card class="elevation-2">
                <v-card-title class="text-h6">
                  {{ parseInt(reply.admin) > 0 ? 'GETFOOD Pro' : reply.submitter }}
                  <v-spacer />
                  <span class="text-caption font-weight-bold">
                    {{ reply.date }} {{ reply.time }}
                  </span>
                </v-card-title>
                <v-card-text v-html="reply.message" />
              </v-card>
            </v-timeline-item>
          </v-slide-x-transition>

          <v-timeline-item
            class="white--text"
            color="green"
            large
          >
            <template #icon>
              <span>YOU</span>
            </template>

            <v-card class="elevation-2">
              <v-card-title class="text-h6">
                {{ ticket.name }}
                <v-spacer />
                <span class="text-caption font-weight-bold">
                  {{ ticket.date }} {{ ticket.time }}
                </span>
              </v-card-title>
              <v-card-text v-html="ticket.message" />
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer />

        <v-btn
          class="light-blue--text text--darken-1"
          outlined
          @click="onCancelButtonClick"
        >
          {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Auth from '../../mixins/auth'

export default {
  name      : 'TicketDetailsDialog',
  components: {},
  mixins    : [Auth],
  props     : {
    visible: {
      type    : Boolean,
      default : false,
      required: true
    },
    ticket: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      isFullScreen: true
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    onCancelButtonClick () {
      this.isVisible = false
    }

  }
}
</script>
