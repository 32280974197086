<template>
  <v-card flat>
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Customer.CustomerGroups.Table.Button.New.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showCategoryAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Customer.CustomerGroups.Table.Button.New.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Customer.CustomerGroups.Table.Button.New.Title').toLocaleUpperCase($i18n.locale) }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.customer_group_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.group_name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showCategoryEditDialog(item)"
                >
                  {{ item.Name }}
                </a>
              </template>
              <template v-else>
                {{ item.Name }}
              </template>
            </template>

            <template #[`item.order_source`]="{item}">
              <v-chip
                v-if="item.SourceTypeId"
                class="pl-0"
                :color="isDarkMode ? 'grey darken-3' : 'grey lighten-2'"
                small
                label
              >
                <v-avatar
                  tile
                  :color="item.SourceTypeId < 100 ? 'blue-grey' : 'white'"
                  class="mr-2"
                >
                  <v-icon
                    v-if="item.SourceTypeId < 100"
                    class="white--text"
                    v-text="getOrderSourceTypeById(item.SourceTypeId).logo"
                  />

                  <img
                    v-else
                    :src="getOrderSourceTypeById(item.SourceTypeId).logo"
                  >
                </v-avatar>
                <span>{{ item.SourceTypeId >= 100 ? getOrderSourceTypeById(item.SourceTypeId).name : getOrderSourceTypeLangById(item.SourceTypeId).Title }} </span>
              </v-chip>
            </template>

            <template #[`item.description`]="{item}">
              {{ item.Description }}
            </template>

            <template #[`item.actions`]="{item}">
              <v-tooltip
                :color="$t('Customer.CustomerGroups.Table.Button.Edit.Color')"
                open-delay="1000"
                left
              >
                <template #activator="{on}">
                  <v-btn
                    v-if="userCanManage"
                    class="elevation-2 ml-1"
                    :class="$t('Customer.CustomerGroups.Table.Button.Edit.Color')"
                    dark
                    icon
                    :small="$vuetify.breakpoint.xsOnly"
                    v-on="on"
                    @click="showCategoryEditDialog(item)"
                  >
                    <v-icon :small="$vuetify.breakpoint.xsOnly">
                      {{ $t('Customer.CustomerGroups.Table.Button.Edit.Icon') }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Customer.CustomerGroups.Table.Button.Edit.Title') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <customer-group-add-dialog
      :category-to-edit="selectedItem"
      :visible.sync="addEditCategoryDialog"
    />
  </v-card>
</template>

<script>
import AppData                from '@/mixins/appdata'
import Auth                   from '@/mixins/auth'
import DataTable              from '@/mixins/data-table/dataTable'
import FilterSearch           from '@/components/common/filter/FilterSearch.vue'
import CustomerGroupAddDialog from './CustomerGroupAddDialog.vue'
import SourceType             from '@/mixins/orders/sourceType'

export default {
  components: {
    FilterSearch,
    'customer-group-add-dialog': CustomerGroupAddDialog
  },
  directives: {},
  mixins    : [AppData, Auth, DataTable, SourceType],
  data () {
    return {
      selectedItem         : null,
      addEditCategoryDialog: false,

      dataTable: {
        dataStore    : 'customerGroups',
        socketCommand: {
          list: window.SocketCommand.CustomerGroup.All,
          save: window.SocketCommand.CustomerGroup.Save
        },
        options: {
          defaultSortBy: 'customer_group_id',
          sortBy       : ['customer_group_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : ['description'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Customer.CustomerGroups.Table.Headers.Id'),
            value    : 'customer_group_id',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.CustomerGroups.Table.Headers.Name'),
            value    : 'group_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.OrderSource'),
            value    : 'order_source',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.CustomerGroups.Table.Headers.Description'),
            value    : 'description',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.CustomerGroups.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2'
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    showCategoryAddDialog () {
      this.selectedItem = null
      this.addEditCategoryDialog = true
    },

    showCategoryEditDialog (category) {
      this.selectedItem = category
      this.addEditCategoryDialog = true
    },

    onSaveResult (data) {
      if (data.status === 'success') {
        this.onSaveResultInternal(data)
        this.addEditCategoryDialog = false
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Customer.CustomerGroups.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}
</style>
