import Appdata                 from '@/mixins/appdata'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import Auth                    from '@/mixins/auth'
import ReceiptStatusEnum       from '@/api/Enums/ReceiptStatusEnum'
import ReceiptTypeCodeEnum     from '@/api/Enums/ReceiptTypeCodeEnum'

export default {
  mixins  : [Auth, Appdata],
  computed: {},
  methods : {
    canRetryReceipt (item) {
      return this.userCanViewReceipt && this.appHasReceiptsProvider && this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD && !item.CanceledAt && !item.Mark
    },

    canCancelReceipt (item) {
      const providerTypeConditions = (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD && item.Mark) || (this.appReceiptsProviderType === ReceiptProviderTypeEnum.LOCAL && !item.Mark)

      // const canCancelFnb = !item?.Related?.Receipt?.InternalId && (item.CanCancelFnb || this.$route.name === 'Receipts')
      // return this.cancel && this.userCanViewReceipt && this.appHasReceiptsProvider && !item.IsCredit && !item.CanceledAt && providerTypeConditions && canCancelFnb

      return this.cancel && this.userCanViewReceipt && this.appHasReceiptsProvider && !!providerTypeConditions && !!item.CanCancel
    },

    tableReceiptOrderNumber (receipt, orders) {
      const orderNumber = orders?.find(order => order.Timestamp === receipt?.Timestamp)?.OrderNumber || ''
      return orderNumber ? `#${ orderNumber }` : ''
    },

    receiptTypeColor (receipt, text = false) {
      let retval = 'grey'
      const typeCode = receipt?.InvoiceTypeCodeInternal || receipt?.InvoiceTypeCode || receipt?.TypeCodeInternal || receipt?.TypeCode || ''

      if (typeCode === ReceiptTypeCodeEnum.GREEK_INVOICE) {
        retval = {
          base: 'lime darken-3',
          text: 'lime--text text--darken-3'
        }
      }
      if (typeCode === ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_1) {
        retval = {
          base: 'red',
          text: 'red--text'
        }
      }
      if (typeCode === ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_2) {
        retval = {
          base: 'red',
          text: 'red--text'
        }
      }

      if (typeCode === ReceiptTypeCodeEnum.GREEK_RECEIPT) {
        retval = {
          base: 'teal',
          text: 'teal--text'
        }
      }
      if (typeCode === ReceiptTypeCodeEnum.GREEK_RECEIPT_CREDIT) {
        retval = {
          base: 'red',
          text: 'red--text'
        }
      }

      if (typeCode === ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER) {
        retval = {
          base: 'blue-grey',
          text: 'blue-grey--text'
        }
      }
      if (typeCode === ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER_CANCEL) {
        retval = {
          base: 'red',
          text: 'red--text'
        }
      }
      if (typeCode === ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER_RETURN) {
        retval = {
          base: 'orange',
          text: 'orange--text'
        }
      }

      return text ? retval.text : retval.base
    },

    receiptStatus (receipt) {
      if (receipt?.CanceledAt) return this.$t('Receipt.Status.Canceled')
      const status = receipt?.DocumentStatus || ''

      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        if (status === ReceiptStatusEnum.OPEN) return this.$t('Receipt.Status.Open')

        if (status === ReceiptStatusEnum.CLOSED) return this.$t('Receipt.Status.Closed')

        if (status === ReceiptStatusEnum.AWAITING) return this.$t('Receipt.Status.Closed')

        if (status === ReceiptStatusEnum.TRANSMITTED || receipt?.Mark) return this.$t('Receipt.Status.Transmitted')

        if (status === ReceiptStatusEnum.TRANSMISSION_FAILURE) return this.$t('Receipt.Status.Closed')

        if (status === ReceiptStatusEnum.CANCELED) return this.$t('Receipt.Status.Canceled')
      }

      if (receipt?.Id && this.appHasReceiptsProvider && this.appReceiptsProviderType === ReceiptProviderTypeEnum.LOCAL) return this.$t('Receipt.Status.Issued')

      return this.$t('Receipt.Status.Waiting')
    }
  }
}
