export default {
  Title: 'Extra Charges | Extra Charge | Extra Charges',

  Toolbar: {
    Button: {
      New: {
        Title: 'Add Charge',
        Icon : 'add_box',
        Color: 'primary'
      }
    }
  },

  Table: {
    Headers: {
      Id      : '#',
      Name    : 'Name',
      Quantity: 'Quantity',
      Price   : 'Price',
      Tax     : 'Tax',
      Priority: 'Priority',
      Active  : 'Status',
      Actions : ''
    }
  },

  Dialog: {
    Delete: {
      Title       : 'Delete Extra Charge?',
      Content     : 'Are you sure you want to delete the Extra Charge?',
      Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
    }
  },

  AddEditDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Extra Charge',
        Subtitle: 'Create a New Extra Charge'
      },
      Edit: {
        Title   : 'Edit Extra',
        Subtitle: 'Modification of Extra Charge Details'
      },
      Icon : 'mdi-credit-card-plus',
      Color: 'indigo'
    },

    Field: {
      Name: {
        Label: 'Name',
        Hint : 'Extra charge Name',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'You must enter the name for at least one language',
          Between       : 'Name must be between 2 and 255 characters'
        }
      },
      Priority: {
        Label: 'Priority',
        Hint : 'Προτεραιότητα επιπλέον χρέωσης',
        Icon : 'sort_by_alpha',
        Error: {
          Valid: 'Priority must be an integer'
        }
      },
      Quantity: {
        Label: 'Quantity',
        Hint : 'Extra charge quantity',
        Icon : 'mdi-counter',
        Error: {
          Valid: 'Quantity must be an integer greater than 0'
        }
      },
      Price: {
        Label: 'Price',
        Hint : 'Τιμή επιπλέον χρέωσης',
        Icon : 'mdi-currency-eur',
        Error: {
          Valid: 'Price must be a number greater than 0'
        }
      },
      Tax: {
        Label: 'VAT Category',
        Hint : 'Select VAT Category',
        Icon : 'percent',
        Error: {
          Required: 'You must select VAT category.'
        }
      },
      FeeCategoryCode: {
        Label: 'Fee Category Code',
        Hint : 'Select fee category code',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'You must select fee category code.'
        }
      },
      ClassificationCategoryCode: {
        Label: 'Income Classification Category Code',
        Hint : 'Select income classification category code',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'You must select income classification category code.'
        }
      },
      ClassificationTypeCode: {
        Label: 'Income Classification Type Code ',
        Hint : 'Select a income classification type code',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'You must select income classification type code.'
        }
      },
      Active: {
        Label: 'Status'
      }
    }
  }
}
