import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class CustomerEmailDomainValidator extends BaseValidator {
  rules = {
    Id: {
      required: true,
      type    : 'number'
    },
    Email: {
      required: true,
      type    : 'email'
    },
    To: {
      required: false,
      type    : 'email'
    },
    Pin: {
      required: true,
      type    : 'numeric',
      length  : 4
    },
    Locale: {
      required: true,
      type    : 'string'
    },
    Firstname: {
      required: false,
      type    : 'string'
    },
    Lastname: {
      required: false,
      type    : 'string'
    },
    Subdomain: {
      required: false,
      type    : 'string'
    }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
