export default {
  Coupon: {
    Title   : 'Κουπόνια | Κουπόνι | Κουπόνια',
    ListView: {
      Button: {
        Add: {
          Title: 'Νέο Κουπόνι',
          Color: 'primary',
          Icon : 'add_box'
        },
        Edit: {
          Title: 'Επεξεργασία Κουπονιού',
          Color: 'cyan',
          Icon : 'edit'
        }
      },
      Table: {
        Headers: {
          Id              : '#',
          Name            : 'Όνομα',
          Code            : 'Κωδικός',
          Type            : 'Τύπος',
          Discount        : 'Έκπτωση',
          UsedCount       : 'Εξαργυρώσεις',
          Customers       : 'Πελάτες',
          CustomerGroups  : 'Ομάδες Πελατών',
          OrderRestriction: 'Τύπος Παραγγελίας',
          SourceType      : 'Πηγή Παραγγελίας',
          Validity        : 'Εγκυρότητα',
          AutoApply       : 'Αυτόματη Εφαρμογή',
          Status          : 'Κατάσταση',
          Actions         : ''
        }
      }
    },
    Dialog: {
      Toolbar: {
        Add: {
          Title   : 'Προσθήκη Κουπονιού',
          SubTitle: 'Δημιουργία Νέου Κουπονιού'
        },
        Edit: {
          Title   : 'Επεξεργασία Κουπονιού',
          SubTitle: 'Τροποποίηση Στοιχείων Κουπονιού'
        },
        Tab: {
          General     : 'Γενικά',
          Restrictions: 'Περιορισμοί',
          History     : 'Ιστορικό'
        }
      },

      History: {
        Table: {
          Headers: {
            Id        : '#',
            Customer  : 'Πελάτης',
            Order     : 'Παραγγελία',
            OrderTotal: 'Σύνολο',
            Amount    : 'Έκπτωση',
            Date      : 'Ημερομηνία'
          }
        }
      },

      Field: {

        Name: {
          Label: 'Όνομα',
          Hint : 'Όνομα Κουπονιού. πχ. Έκπτωση 10%',
          Error: {
            Between: 'Το όνομα πρέπει να είναι από 2 έως 128 χαρακτήρες'
          }
        },
        Code: {
          Label: 'Κωδικός',
          Hint : 'Κωδικός Κουπονιού. πχ. OFFER10, κλπ.',
          Error: {
            Between: 'Ο κωδικός πρέπει να είναι από 2 έως 15 χαρακτήρες'
          }
        },

        Type: {
          Title      : 'Τύπος Έκπτωσης',
          RadioOption: {
            DiscountPercent: 'Ποσοστό Έκπτωσης',
            FixedPrice     : 'Καθορισμένη Τιμή'
          }

        },

        Discount: {
          Label: {
            DiscountPercent: '@:Marketing.Coupon.Dialog.Field.Type.RadioOption.DiscountPercent',
            FixedPrice     : '@:Marketing.Coupon.Dialog.Field.Type.RadioOption.FixedPrice'
          },
          Hint : '@:Marketing.Coupon.Dialog.Field.Discount.Label.DiscountPercent ή @:Marketing.Coupon.Dialog.Field.Discount.Label.FixedPrice έκπτωσης',
          Error: {
            Number: 'Πρέπει να είναι αριθμός'
          }
        },

        OrderRestriction: {
          Title      : 'Τύπος Παραγγελίας',
          RadioOption: {
            All     : 'Όλες',
            Delivery: 'Παράδοση',
            TakeAway: 'Παραλαβή'
          }
        },

        Redemptions: {
          Label: 'Εξαργυρώσεις',
          Hint : 'Ο συνολικός αριθμός των φορών που αυτό το κουπόνι μπορεί να εξαργυρωθεί. Εισάγετε 0 για απεριόριστες εξαργυρώσεις.',
          Error: {
            Number: '@:Marketing.Coupon.Dialog.Field.Discount.Error.Number'
          }
        },

        CustomerRedemptions: {
          Label: 'Εξαργυρώσεις Πελάτη',
          Hint : 'Ο συνολικός αριθμός των φορών που αυτό το κουπόνι μπορεί να εξαργυρωθεί ανά πελάτη. Εισάγετε 0 για απεριόριστες εξαργυρώσεις.',
          Error: {
            Number: '@:Marketing.Coupon.Dialog.Field.Discount.Error.Number'
          }
        },

        MinTotal: {
          Label: 'Ελάχιστο σύνολο',
          Hint : 'Ελάχιστο σύνολο παραγγελίας για να μπορεί να χρησιμοποιηθεί το κουπόνι',
          Error: {
            Number: '@:Marketing.Coupon.Dialog.Field.Discount.Error.Number'
          }
        },

        Status: {
          Title: 'Κατάσταση'
        },

        AutoApply: {
          Title: 'Αυτόματη Εφαρμογή'
        },

        Validity: {
          Title : 'Εγκυρότητα',
          Toggle: {
            Always: {
              Title: 'Πάντα'
            },
            DateTime: {
              Title  : 'Ημερομηνία / Ώρα',
              Options: {
                TwentyFourHours: '24 Ώρες',
                Custom         : 'Προσαρμοσμενο'
              }
            },
            Period: {
              Title: 'Περίοδος'
            },
            Repeat: {
              Title  : 'Επαναληψη',
              Options: {
                TwentyFourHours: '@:Marketing.Coupon.Dialog.Field.Validity.Toggle.DateTime.Options.TwentyFourHours',
                Custom         : '@:Marketing.Coupon.Dialog.Field.Validity.Toggle.DateTime.Options.Custom'
              }
            }
          }
        }

      },

      Message: {
        Save: {
          Success: 'Το κουπόνι αποθηκεύτηκε επιτυχώς.'
        },
        Update: {
          Success: 'Το κουπόνι ενημερώθηκε επιτυχώς.'
        }
      }
    }
  }
}
