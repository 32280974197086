export default class BarcodeScanListener {
  constructor (configs = {}) {
    this.configs = {}
    this.boundEventHandler = this.keyUp.bind(this)
    this.initialize(configs)
  }

  initialize (configs) {
    this.configs = this.extendConfig({

      debug           : false,
      eventSuffix     : 'keyboardSequence',
      maxKeyboardDelay: 75,
      allowedChars    : '[0-9]',
      barcodeRegex    : '',
      ignoreInputs    : false

    }, configs)

    this.validateConfigs()

    this.sequence = ''
    this.lastCharRecordedTime = null
    this.targetElement = null
    this.watcher = null

    this.start()
  }

  start () {
    window.addEventListener('keyup', this.boundEventHandler)
  }

  stop () {
    window.removeEventListener('keyup', this.boundEventHandler)
  }

  /**
   * @method validateConfigs
   */
  validateConfigs () {
    if (this.configs.maxKeyboardDelay < 10 || this.configs.maxKeyboardDelay > 2000) {
      // eslint-disable-next-line no-throw-literal
      throw '"maxKeyboardDelay" need to be between 10 and 2000'
    }
  }

  /**
   * @method extendConfig
   * @param {Object} defaultConfigs
   * @param {Object} configs
   * @returns {Object}
   */
  extendConfig (defaultConfigs, configs) {
    for (const key in configs) {
      if (configs.hasOwnProperty(key)) {
        defaultConfigs[key] = configs[key]
      }
    }

    return defaultConfigs
  }

  /**
   * @method keyUp
   * @param {Object} keyEvent
   */
  keyUp (keyEvent) {
    this.recordKey(keyEvent || window.event)
  }

  /**
   * @method recordKey
   * @param {Object} keyEvent
   */
  recordKey (keyEvent) {
    if (this.configs.ignoreInputs) {
      if (this.isInputTarget(keyEvent.target)) {
        this.clean(true)
        this.clearWatcher()

        return
      }
    }

    const charCode = keyEvent.charCode || keyEvent.keyCode
    const character = keyEvent.key || String.fromCharCode(charCode)

    if (character.length > 1 || !this.checkValidChar(character)) {
      return
    }

    this.clean(false)
    this.clearWatcher()

    this.lastCharRecordedTime = new Date().getTime()
    this.sequence += character

    if (this.sequence.length === 1) {
      this.targetElement = keyEvent.target
    }

    if (this.configs.debug) {
      // eslint-disable-next-line no-console
      console.log(this.sequence)
    }

    this.setWatcher()
  }

  /**
   * @method isInputTarget
   * @param {Object} event
   * @returns {boolean}
   */
  isInputTarget (target) {
    if (!target) return false
    switch (target.tagName.toLowerCase()) {
    case 'input':
    case 'textarea':
      return true

    default:
      return false
    }
  }

  /**
   * @method checkValidChar
   * @param {string} input
   * @returns {boolean
   */
  checkValidChar (input) {
    return input.match(new RegExp(this.configs.allowedChars)) !== null
  }

  /**
   * @method clean
   * @param {boolean} force
   */
  clean (force) {
    if (force === undefined) {
      force = false
    }

    if (force || !this.checkDelay()) {
      if (this.configs.debug) {
        // eslint-disable-next-line no-console
        console.log('-- CLEARED --')
      }

      this.sequence = ''
    }
  }

  /**
   * @method checkDelay
   * @returns {boolean}
   */
  checkDelay () {
    return this.lastCharRecordedTime === null ||
      (new Date().getTime() - this.lastCharRecordedTime) <= this.configs.maxKeyboardDelay
  }

  /**
   * @method setWatcher
   */
  setWatcher () {
    this.watcher = setTimeout(function () {
      this.watcher = null
      this.checkCompleted()
    }.bind(this), this.configs.maxKeyboardDelay)
  }

  clearWatcher () {
    if (this.watcher !== null) {
      clearTimeout(this.watcher)
      this.watcher = null
    }
  }

  /**
   * @method isRecordedInputValid
   * @param {string} input
   * @returns {boolean}
   */
  isRecordedInputValid (input) {
    const regex = new RegExp(this.configs.barcodeRegex, 'gi')
    const result = regex.exec(input)

    return result
  }

  /**
   * @method checkCompleted
   */
  checkCompleted () {
    const sequence = this.sequence
    const isValidBarcodeResult = this.isRecordedInputValid(sequence)

    if (isValidBarcodeResult !== null) {
      this.dispatchEvent(isValidBarcodeResult)
    } else {
      this.clean(false)
    }
  }

  /**
   * @method newInputDetected
   * @param {string} sequence
   */
  dispatchEvent (validBarcodeResult) {
    if (this.configs.debug) {
      // eslint-disable-next-line no-console
      console.log('=========================')
      // eslint-disable-next-line no-console
      console.log('Barcode: ', validBarcodeResult)
      // eslint-disable-next-line no-console
      console.log('=========================')
    }

    if (this.isInputTarget(this.targetElement)) {
      this.targetElement.value = this.targetElement.value.replace(new RegExp(validBarcodeResult.input, 'gi'), '')
      this.targetElement.dispatchEvent(new Event('input', { bubbles: true }))
    }

    const eventName = this.configs.eventSuffix ? `onBarcodeScan-${ this.configs.eventSuffix }` : 'onBarcodeScan-keyboardSequence'

    window.dispatchEvent(new CustomEvent(eventName, {
      detail: {
        match  : validBarcodeResult,
        barcode: validBarcodeResult[0],
        type   : validBarcodeResult[1],
        value  : validBarcodeResult[2]
      }
    }
    ))
  }
}
