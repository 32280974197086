h
<template>
  <v-card flat>
    <v-card-title class="py-0 px-0">
      <v-toolbar
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        class="filter-toolbar"
        flat
      >
        <v-btn
          v-if="posUserHasComponentPermission('Orders', 'ADD') && posUserHasComponentPermission('Pos', 'ACCESS')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'Pos', exact: true}"
          class="primary--text elevation-0 ma-1"
          text
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Order.OrdersAll.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Order.OrdersAll.Toolbar.Button.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <print-button
          v-if="posUserHasComponentPermission('Orders', 'MANAGE') && userCanViewAllOrders"
          :hide-label="$vuetify.breakpoint.xsOnly"
          :disabled="!viewHasData"
          btn-class="ma-1"
          color="primary"
          flat
          menu-offset-y
          @printer-selected="printer => printOrderTotalsZ(null, printer)"
        />

        <print-receipt-totals-z-button
          v-if="userCanViewReceipt"
          :fab="$vuetify.breakpoint.xsOnly"
          :hide-label="$vuetify.breakpoint.xsOnly"
          :disabled="!viewHasData"
          btn-class="ma-1"
          flat
          menu-offset-y
          @printer-selected="printer => printReceiptTotalsZ(null, printer)"
        />

        <v-btn
          v-if="posUserHasComponentPermission('Orders', 'MANAGE') && userCanViewAllOrders"
          :icon="$vuetify.breakpoint.xsOnly"
          :disabled="!viewHasData"
          class="primary--text elevation-0 ma-1"
          text
          @click="showOrderMapDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Order.OrdersToday.Toolbar.Button.Map.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Order.OrdersToday.Toolbar.Button.Map.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <v-spacer />

        <table-actions
          v-if="userCanDeleteOrders"
          v-model="dataTable.tableActions"
          :items.sync="selectedOrders"
          :disabled="!viewHasData"
        >
          <v-btn
            v-if="userCanDeleteOrders"
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            :disabled="deleteOrderLoading || !selectedOrders.length"
            :loading="deleteOrderLoading"
            :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
            class="pa-0 ma-1 elevation-0"
            @click="onDeleteOrdersClick(selectedOrders)"
          >
            <v-icon
              color="red"
              v-text="'delete'"
            />
          </v-btn>
        </table-actions>

        <filter-search v-model="dataTable.search.terms" />

        <filter-options
          v-model="filtersModel"
          :filters="filterData"
        />
      </v-toolbar>
    </v-card-title>

    <!--TODAY ORDERS TABLE-->
    <v-data-table
      v-model="selectedOrders"
      :custom-filter="customSearch"
      :footer-props="dataTable.footerProps"
      :headers="dataTableHeaders"
      :height="dataTable.resize.bodyHeight"
      :items="filteredItems"
      :loading="dataTable.loading"
      :loading-text="dataTable.text.loadingText"
      :no-data-text="dataTable.text.noDataText"
      :no-results-text="dataTable.text.noResultsText"
      :options.sync="dataTable.options"
      :search="dataTable.search.terms"
      :show-select="dataTable.tableActions"
      :single-select="false"
      fixed-header
      item-key="Id"
    >
      <template #[`item.source_type`]="{item}">
        <div v-if="getOrderSourceTypeById(item.SourceTypeId)">
          <v-tooltip
            right
            color="indigo lighten-2"
          >
            <template #activator="{on}">
              <v-avatar
                size="28"
                v-on="on"
              >
                <v-icon
                  v-if="item.SourceTypeId<100"
                  color="indigo lighten-2"
                >
                  {{ getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo }}
                </v-icon>

                <img
                  v-else
                  :src="getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo"
                >
              </v-avatar>
            </template>

            <span v-if="item.SourceTypeId < 100">
              {{ getOrderSourceTypeLangById(item.SourceTypeId) && getOrderSourceTypeLangById(item.SourceTypeId).Desc }}
            </span>
            <span v-else>
              {{ getOrderSourceTypeById(item.SourceTypeId).desc }}
            </span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.daily_check`]="{item}">
        <v-chip
          v-if="item.DailyCheck && appConfig.LOCATION_DATA.ShowDailyCheck"
          label
          x-small
          class="px-1"
        >
          {{ item.DailyCheck }}
        </v-chip>
      </template>

      <template #[`item.order_id`]="{item}">
        <div class="d-flex align-center">
          <template v-if="item.ExternalPlatform && item.ExternalCatalogId && getExternalOrderRawUrl(item) && userIsSuperAdmin">
            <div class="d-inline-flex mr-2">
              <v-tooltip
                color="light-green"
                top
              >
                <template #activator="{on}">
                  <v-btn
                    class="light-green"
                    small
                    :href="getExternalOrderRawUrl(item)"
                    target="_blank"
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-code-block-braces
                    </v-icon>
                  </v-btn>
                </template>

                <span>View External Order</span>
              </v-tooltip>
            </div>
          </template>

          <div class="d-inline-flex">
            <div>
              <a
                href="#"
                @click.prevent="getOrderDetails(item)"
              >
                {{ item.Id }}
              </a>

              <div
                v-if="item.ExternalPlatform"
                class="text-caption text-uppercase"
              >
                #{{ item.ExternalOrderId }}
              </div>

              <div
                v-if="item.MarketplaceOrderCode"
                class="text-caption text-uppercase"
              >
                #{{ item.MarketplaceOrderCode }}
              </div>

              <div
                v-if="item.QrData"
                class="text-caption"
              >
                {{ item.QrData }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #[`item.icons`]="{item}">
        <v-tooltip
          :color="parseInt(item.CustomerId) === 0 ? 'grey darken-3' : 'amber darken-3'"
          right
        >
          <template #activator="{on}">
            <v-icon
              :color="parseInt(item.CustomerId) === 0 ? 'grey' : 'amber'"
              class="text--lighten-2"
              small
              v-on="on"
            >
              verified_user
            </v-icon>
          </template>
          <span>{{
            parseInt(item.CustomerId) === 0 ? $t('Customer.CustomerType.Visitor') : $t('Customer.CustomerType.Registered')
          }}</span>
        </v-tooltip>

        <div v-if="parseInt(item.AddressId) > -1 && item.Address && parseInt(item.OrderTypeId) === 1">
          <v-icon
            color="light-green lighten-1"
            small
          >
            location_on
          </v-icon>
        </div>

        <div v-if="item.Telephone && $options.filters.phone(item.Telephone)">
          <v-icon
            color="blue lighten-2"
            small
          >
            phone
          </v-icon>
        </div>

        <div v-if="item.Telephone2 && $options.filters.phone(item.Telephone2)">
          <v-icon
            color="blue lighten-2"
            small
          >
            phone
          </v-icon>
        </div>

        <div v-if="item.Telephone3 && $options.filters.phone(item.Telephone3)">
          <v-icon
            color="blue lighten-2"
            small
          >
            phone
          </v-icon>
        </div>
      </template>

      <template #[`item.customer`]="{item}">
        <div
          v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && parseInt(item.CustomerId) !== 0"
          :style="customerColumnMaxWidth"
          :class="isDarkMode ? 'white--text' : 'grey--text text--darken-3'"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; cursor: pointer;"
          @click="$bus.$emit('show-customer-details-dialog', item.CustomerId)"
        >
          {{ item.FullName }}
        </div>

        <template v-else>
          <div
            :style="customerColumnMaxWidth"
            :class="isDarkMode ? 'white--text' : 'grey--text text--darken-3'"
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          >
            {{
              parseInt(item.CustomerId) !== 0 || item.FullName.trim() ? item.FullName : $t('Customer.CustomerType.Visitor')
            }}
          </div>
        </template>

        <div
          v-if="!isQrOrder(item) && parseInt(item.AddressId) > -1 && item.Address && parseInt(item.OrderTypeId) === 1"
          :style="customerColumnMaxWidth"
          class="grey--text"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        >
          {{ getAddressFormatted(item.Address) }}
        </div>

        <div
          v-else-if="isQrOrder(item) && parseInt(item.OrderTypeId) === 1"
          :style="customerColumnMaxWidth"
          class="grey--text"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        >
          `
          {{ item.Comment }}
        </div>

        <div
          v-if="item.Telephone || item.Telephone2 || item.Telephone3"
          :style="customerColumnMaxWidth"
          class="grey--text"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        >
          <template v-if="item.Telephone && $options.filters.phone(item.Telephone)">
            {{ item.Telephone | phone }}<br>
          </template>

          <template v-if="item.Telephone2 && $options.filters.phone(item.Telephone2)">
            {{ item.Telephone2 | phone }}<br>
          </template>

          <template v-if="item.Telephone3 && $options.filters.phone(item.Telephone3)">
            {{ item.Telephone3 | phone }}<br>
          </template>
        </div>
      </template>

      <template #[`item.staff_id`]="{item}">
        <template v-if="item.StaffId > 0">
          <v-tooltip
            :color="item.StaffColor ? `#${item.StaffColor}` : 'grey'"
            :content-class="`${getContrastColor(item.StaffColor ? `#${item.StaffColor}` : '#666666', true, 135)}--text`"
            right
          >
            <template #activator="{on}">
              <v-avatar
                :color="item.StaffColor ? `#${item.StaffColor}` : 'grey'"
                size="32"
                tile
                class="font-weight-bold rounded"
                :class="`${getContrastColor(item.StaffColor ? `#${item.StaffColor}` : '#666666', true, 135)}--text`"
                v-on="on"
              >
                {{ item.StaffShortname }}
              </v-avatar>
            </template>

            <span>{{ item.StaffFirstname }} {{ item.StaffLastname }}</span>
          </v-tooltip>
        </template>
      </template>

      <template #[`item.status`]="{item}">
        <div class="my-1">
          <order-status-chip
            :order="item"
            dense
          />
        </div>

        <div class="my-1">
          <v-btn
            v-if="parseInt(item.StatusId) !== 19 && parseInt(item.StatusId) !== 15 && posUserHasComponentPermission('Orders', 'MANAGE')"
            :class="getOrderStatusButtonColorClass(item)"
            :disabled="false"
            :loading="item.loading"
            :small="$vuetify.breakpoint.xsOnly"
            class="white--text elevation-2 mx-1 px-0"
            :style="(parseInt(item.StatusId) === 0 || parseInt(item.StatusId) === 11) && !item.loading ? 'min-width: 100px;' : 'min-width: 170px;'"
            @click.native.stop="parseInt(item.StatusId) === 0 ? null : $set(item, 'loading', true)"
            @click.exact="parseInt(item.StatusId) === 0 ? showAcceptOrderDialog(item) : updateOrderStatus(item)"
          >
            {{ getOrderStatusNextLang(item).toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            v-if="(parseInt(item.StatusId) === 0 || parseInt(item.StatusId) === 11) && !item.loading"
            :class="getOrderStatusButtonColorClass(item)"
            :small="$vuetify.breakpoint.xsOnly"
            class="white--text elevation-2 ml-0 mr-1 px-1"
            color="orange"
            style="min-width: 25px;"
            @click="showSelectOrderTypeTimeDialog(item)"
          >
            <v-icon>timer</v-icon>
            <span class="mx-1 text-subtitle-1 text-lowercase">
              {{ convertMinutesToDisplayString(item.OrderTypeTime) }}
            </span>
          </v-btn>
        </div>
      </template>

      <template #[`item.order_type`]="{item}">
        <order-type-chip
          :order="item"
          dense
        />
      </template>

      <template #[`item.order_total`]="{item}">
        <v-badge
          :color="item.ReceiptType === ReceiptTypeEnum.RECEIPT ? 'green' : item.ReceiptType === ReceiptTypeEnum.INVOICE ? 'blue' : 'red'"
          :value="item.SourceTypeId !== SourceTypeEnum.TABLE && appHasReceiptsEnabled && appHasReceiptsProvider && (orderHasReceipt(item) || (item.CanGenerateReceipt && orderIsRefundable(item)))"
          offset-x="2"
          offset-y="-2"
          right
          dot
        >
          <v-tooltip
            v-if="appHasReceiptsProvider"
            :color="item.ReceiptType === ReceiptTypeEnum.RECEIPT ? 'green' : item.ReceiptType === ReceiptTypeEnum.INVOICE ? 'blue' : 'red'"
            content-class="text-center"
            right
          >
            <template #activator="{on}">
              <div
                class="d-inline-block"
                v-on="on"
              >
                <template v-if="orderHasUnpaidBalance(item) && parseInt(item.StatusId) !== OrderStatusEnum.Canceled">
                  <span class="red--text">{{ orderTransactionsTotal(item) | currency }}</span> <span>/</span> <span class="blue--text">{{ item.Price | currency }}</span>
                </template>
                <template v-else>
                  {{ item.Price | currency }}
                </template>
              </div>
            </template>

            <span v-if="orderHasUnpaidBalance(item) && parseInt(item.StatusId) !== OrderStatusEnum.Canceled">
              {{ $t('Order.Total.PartiallyPaid') }}
            </span>
            <span v-else-if="item.ReceiptType === ReceiptTypeEnum.RECEIPT">
              {{ $t('Order.Total.Receipt') }}
            </span>
            <span v-else-if="item.ReceiptType === ReceiptTypeEnum.INVOICE">
              {{ $t('Order.Total.Invoice') }}
            </span>
            <span v-else>
              {{ $t('Order.Total.NoFiscalDoc') }}
            </span>
          </v-tooltip>

          <div
            v-else
            class="d-inline-block"
          >
            <template v-if="orderHasUnpaidBalance(item) && parseInt(item.StatusId) !== OrderStatusEnum.Canceled">
              <span class="red--text">{{ orderTransactionsTotal(item) | currency }}</span> <span>/</span> <span class="blue--text">{{ item.Price | currency }}</span>
            </template>
            <template v-else>
              {{ item.Price | currency }}
            </template>
          </div>
        </v-badge>
      </template>

      <template #[`item.order_payment`]="{item}">
        <order-payments-icon
          :item="item"
          chip
          dense
        />
      </template>

      <template #[`item.date_added`]="{item}">
        <span>{{ item.DateAdded }}</span>

        <div class="grey--text">
          {{ item.TimeAdded }}
        </div>
      </template>

      <template #[`item.actions`]="{item}">
        <v-speed-dial
          v-model="item.fab"
          direction="left"
          transition="slide-x-reverse-transition"
        >
          <template #activator>
            <v-btn
              :small="$vuetify.breakpoint.xsOnly"
              class="blue-grey lighten-2 elevation-2 ma-0"
              dark
              icon
            >
              <v-icon
                v-if="item.fab"
                :small="$vuetify.breakpoint.xsOnly"
              >
                close
              </v-icon>
              <v-icon
                v-else
                :small="$vuetify.breakpoint.xsOnly"
              >
                menu
              </v-icon>
            </v-btn>
          </template>

          <template v-if="orderPaymentDialogAvailable(item)">
            <v-tooltip
              color="indigo"
              content-class="text-center"
              top
            >
              <template #activator="{on}">
                <v-btn
                  :class="$t(`Order.OrdersToday.ListView.Button.OrderPayment.Color`)"
                  :small="$vuetify.breakpoint.xsOnly"
                  dark
                  icon
                  @click="showOrderPaymentDialog(item)"
                  v-on="on"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    {{ $t(`Order.OrdersToday.ListView.Button.OrderPayment.Icon`) }}
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t(`Order.OrdersToday.ListView.Button.OrderPayment.Title`) }}</span>
            </v-tooltip>
          </template>

          <issue-receipt-document-button
            v-if="issueReceiptDocumentVisible(item)"
            :badge-offset-x="20"
            :badge-offset-y="20"
            :can-issue-document="item.CanGenerateReceipt"
            :can-issue-receipt="item.CanGenerateReceipt"
            :document-id="item.ReceiptId"
            :document-type="item.ReceiptType"
            :document-url="item.DocumentUrl"
            :icon-name="item.ReceiptId ? 'mdi-receipt-text-edit-outline' : 'mdi-receipt-text-plus-outline'"
            :invoice="appReceiptsProviderDocument.includes(ReceiptTypeEnum.INVOICE) && appHasInvoiceDocEnabled"
            :receipt="appReceiptsProviderDocument.includes(ReceiptTypeEnum.RECEIPT) && appHasReceiptDocEnabled"
            :label="item.ReceiptId ? $t('Common.Button.ManageDocument').toLocaleUpperCase($i18n.locale) : $t('Common.Button.IssueDocument').toLocaleUpperCase($i18n.locale)"
            :small="$vuetify.breakpoint.xsOnly"
            :tooltip="item.ReceiptId ? item.ReceiptType === ReceiptTypeEnum.RECEIPT ? $t('Common.Button.ManageReceipt') : item.ReceiptType === ReceiptTypeEnum.INVOICE ? $t('Common.Button.ManageInvoice') : $t('Common.Button.ManageFnb') : $t('Common.Button.IssueDocument')"
            :tooltip-open-delay="150"
            hide-label
            icon
            tooltip-position="top"
            @view-document="data => $bus.$emit('fiscal-document:view', data.DocUrl)"
            @issue-document="data => $bus.$emit('fiscal-document:create', item, data.DocType)"
            @cancel-document="data => $bus.$emit('fiscal-document:cancel', item, data)"
            @print-document="data => $bus.$emit('fiscal-document:print', data.DocId)"
            @email-document="data => $bus.$emit('fiscal-document:email', data.DocId, item, data)"
          />

          <print-button
            v-if="printOrderDocumentVisible(item)"
            :small="$vuetify.breakpoint.xsOnly"
            :tooltip-open-delay="150"
            hide-label
            icon
            menu-offset-y
            open-on-hover
            tooltip-position="top"
            @printer-selected="printer => printOrder(item, printer)"
          />

          <print-preparation-button
            v-if="printOrderPreparationVisible(item)"
            :order-print-groups="item.PrintGroups || []"
            :small="$vuetify.breakpoint.xsOnly"
            :tooltip-open-delay="150"
            hide-label
            icon
            menu-offset-y
            open-on-hover
            tooltip-position="top"
            @printer-group-selected="printerGroup => printOrderPreparation(item, printerGroup)"
          />

          <template v-if="parseInt(item.SourceTypeId) !== 4">
            <v-tooltip
              :color="$t('Order.OrdersToday.ListView.Button.AddTo.Color')"
              top
            >
              <template #activator="{on}">
                <v-btn
                  v-if="parseInt(item.DeliveryStaffId) === -1 && parseInt(item.StatusId) !== OrderStatusEnum.NotComplete && posUserHasComponentPermission('Orders', 'MANAGE') && posUserHasComponentPermission('DeliveryStaff', 'MANAGE')"
                  :class="$t('Order.OrdersToday.ListView.Button.AddTo.Color')"
                  :small="$vuetify.breakpoint.xsOnly"
                  dark
                  icon
                  @click="showAddOrderToDeliveryStaff(item)"
                  v-on="on"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    {{ $t('Order.OrdersToday.ListView.Button.AddTo.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t('Order.OrdersToday.ListView.Button.AddTo.Title') }}</span>
            </v-tooltip>

            <v-tooltip
              :color="$t('Order.OrdersToday.ListView.Button.RemoveFrom.Color')"
              content-class="text-center"
              top
            >
              <template #activator="{on}">
                <v-btn
                  v-if="parseInt(item.DeliveryStaffId) > -1 && deliveryStaffHasActiveShift(item) && parseInt(item.StatusId) !== OrderStatusEnum.NotComplete && posUserHasComponentPermission('Orders', 'MANAGE') && posUserHasComponentPermission('DeliveryStaff', 'MANAGE')"
                  :class="$t('Order.OrdersToday.ListView.Button.RemoveFrom.Color')"
                  :small="$vuetify.breakpoint.xsOnly"
                  dark
                  icon
                  @click="showRemoveOrderFromDeliveryStaffDialog(item)"
                  v-on="on"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    {{ $t('Order.OrdersToday.ListView.Button.RemoveFrom.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <span>{{
                $t('Order.OrdersToday.ListView.Button.RemoveFrom.Title')
              }}<br>{{
                item.DeliveryStaffFirstname + ' ' + item.DeliveryStaffLastname | sanitizeDiacritics | uppercase
              }}</span>
            </v-tooltip>

            <v-tooltip
              :color="$t('Order.OrdersAll.ListView.Button.Edit.Color')"
              top
            >
              <template #activator="{on}">
                <v-btn
                  v-if="canEditOrder(item)"
                  :class="$t('Order.OrdersAll.ListView.Button.Edit.Color')"
                  :small="$vuetify.breakpoint.xsOnly"
                  dark
                  icon
                  @click="showOrderEditDialog(item)"
                  v-on="on"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    {{ $t('Order.OrdersAll.ListView.Button.Edit.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t('Order.OrdersAll.ListView.Button.Edit.Title') }}</span>
            </v-tooltip>
          </template>

          <v-tooltip
            :color="$t('Order.OrdersAll.ListView.Button.View.Color')"
            top
          >
            <template #activator="{on}">
              <v-btn
                :class="$t('Order.OrdersAll.ListView.Button.View.Color')"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="getOrderDetails(item)"
                v-on="on"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  {{ $t('Order.OrdersAll.ListView.Button.View.Icon') }}
                </v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Order.OrdersAll.ListView.Button.View.Title') }}</span>
          </v-tooltip>

          <v-tooltip
            :color="$t('Order.OrdersAll.ListView.Button.Cancel.Color')"
            top
          >
            <template #activator="{on}">
              <v-btn
                v-if="parseInt(item.StatusId) !== OrderStatusEnum.Canceled && posUserHasComponentPermission('Orders', 'DELETE')"
                :class="$t('Order.OrdersAll.ListView.Button.Cancel.Color')"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                v-on="on"
                @click="onCancelOrderClick(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  {{ $t('Order.OrdersAll.ListView.Button.Cancel.Icon') }}
                </v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Order.OrdersAll.ListView.Button.Cancel.Title') }}</span>
          </v-tooltip>

          <v-tooltip
            :color="$t('Order.OrdersAll.ListView.Button.Delete.Color')"
            top
          >
            <template #activator="{on}">
              <v-btn
                v-if="(parseInt(item.StatusId) === OrderStatusEnum.Completed || parseInt(item.StatusId) === OrderStatusEnum.Canceled) && appConfig.LOCATION_DATA.HasForceDeleteOrders && posUserHasComponentPermission('Orders', 'DELETE')"
                :class="$t('Order.OrdersAll.ListView.Button.Delete.Color')"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="onDeleteOrderClick(item)"
                v-on="on"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  {{ $t('Order.OrdersAll.ListView.Button.Delete.Icon') }}
                </v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Order.OrdersAll.ListView.Button.Delete.Title') }}</span>
          </v-tooltip>
        </v-speed-dial>
      </template>
    </v-data-table>

    <order-change-status-dialog
      :cancel-order-confirm-dialog-visible.sync="cancelOrderConfirmDialogVisible"
      :selected-order="!Array.isArray(selectedOrder) ? selectedOrder : undefined"
    />

    <order-delete-dialog
      v-if="appConfig.LOCATION_DATA.HasForceDeleteOrders && posUserHasComponentPermission('Orders', 'DELETE')"
      :selected-order="selectedOrder"
      :is-delete-and-copy-order="isDeleteAndCopyOrder"
      :visible.sync="deleteOrderConfirmDialogVisible"
      @order-deleted="onOrdersDelete"
    />

    <order-payment-dialog
      v-if="selectedOrder"
      :order-id="selectedOrder.Id"
      :customer-id="selectedOrder.CustomerId"
      :order-total="selectedOrder.OrderTotal"
      :transactions="selectedOrder.PaymentTransactions"
      :visible.sync="orderPaymentDialogVisible"
    />

    <select-delivery-staff-dialog
      :selected-delivery-staff.sync="selectedDeliveryStaff"
      :visible.sync="selectedDeliveryStaffDialogVisible"
    />

    <confirm-dialog
      :buttons-block="$vuetify.breakpoint.xsOnly"
      :cancel-button-text="$t('Common.Button.Close')"
      :card-actions-class="$vuetify.breakpoint.xsOnly ? 'd-inline' : ''"
      :html-content="confirmDialogEditOrderBody"
      :html-title="confirmDialogEditOrderTitle"
      :info-bar-body="$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Notification')"
      :no-button-text="$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Button.Delete')"
      :visible.sync="confirmDialogEditOrderVisible"
      :width="700"
      :yes-button="selectedOrder ? parseInt(selectedOrder.SourceTypeId) !== 1 : false"
      :yes-button-text="$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Button.Copy')"
      cancel-button
      info-bar
      no-button-class="v-btn--outline darken-1 red--text caption ma-0 my-1 mr-1"
      no-button-event="edit-delete"
      title-class="cyan lighten-2 white--text lighten-0"
      title-icon="edit"
      yes-button-class="outline green darken-1 white--text caption ma-0 my-1 mr-1"
      yes-button-event="edit-copy"
      @edit-copy="onEditCopy"
      @edit-delete="onEditDelete"
    />

    <confirm-dialog
      :html-content="`${$t('Order.OrdersToday.ListView.Button.RemoveFrom.PopUp.Content')} <strong>${selectedOrder ? selectedOrder.DeliveryStaffFirstname : ''} ${selectedOrder ? selectedOrder.DeliveryStaffLastname : ''}</strong>;`"
      :html-title="$t('Order.OrdersToday.ListView.Button.RemoveFrom.PopUp.Title')"
      :visible.sync="confirmDialogRemoveOrderFromDeliveryStaffVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="delete-order-on-yes-btn"
      @delete-order-on-yes-btn="removeOrderFromDeliveryStaff"
    />

    <orders-map-dialog :orders-map-dialog-visible.sync="ordersMapDialogVisible" />
  </v-card>
</template>

<script>
import OrdersCommon               from '../../mixins/orders/common'
import SelectDeliveryStaffDialog  from '../../components/delivery/SelectDeliveryStaffDialog.vue'
import OrdersMapDialog            from '../../components/orders/OrdersMapDialog'
import PrintButton                from '@/components/common/print/PrintButton.vue'
import PrintReceiptTotalsZButton  from '@/components/common/print/PrintReceiptTotalsZButton.vue'
import Translatable               from '@/mixins/translatable'
import FilterCommon               from '@/mixins/filter/filterCommon'
import FilterOptions              from '@/components/common/filter/FilterOptions.vue'
import PrintPreparationButton     from '@/components/common/print/PrintPreparationButton.vue'
import DataTable                  from '@/mixins/data-table/dataTable'
import FilterSearch               from '@/components/common/filter/FilterSearch.vue'
import greekUtils                 from '@/lib/greek-utils'
import OrderStatusEnum            from '@/api/Enums/OrderStatusEnum'
import TableActions               from '@/components/common/filter/TableActions.vue'
import IssueReceiptDocumentButton from '@/components/common/receipt/IssueReceiptDocumentButton.vue'
import ReceiptTypeEnum            from '@/api/Enums/ReceiptTypeEnum'
import OrderPaymentDialog         from '@/components/payments/orders/OrderPaymentDialog.vue'
import OrderPaymentsIcon          from '@/components/orders/common/OrderPaymentsIcon.vue'
import SourceTypeEnum             from '@/api/Enums/SourceTypeEnum'
import PinManagerEnum             from '@/api/Enums/PinManagerEnum'
import PaymentTypeEnum            from '@/api/Enums/PaymentTypeEnum'
import ReceiptProviderTypeEnum    from '@/api/Enums/ReceiptProviderTypeEnum'
import { getContrastColor }       from '@/lib/color'
import OrderStatusChip            from '@/components/orders/common/OrderStatusChip.vue'
import OrderTypeChip              from '@/components/orders/common/OrderTypeChip.vue'

export default {
  components: {
    OrderTypeChip,
    OrderStatusChip,
    OrderPaymentsIcon,
    OrderPaymentDialog,
    IssueReceiptDocumentButton,
    TableActions,
    FilterSearch,
    PrintPreparationButton,
    FilterOptions,
    PrintReceiptTotalsZButton,
    PrintButton,
    'select-delivery-staff-dialog': SelectDeliveryStaffDialog,
    'orders-map-dialog'           : OrdersMapDialog
  },
  directives: {},
  mixins    : [OrdersCommon, Translatable, FilterCommon, DataTable],
  data () {
    return {
      dataTable: {
        dataStore    : 'ordersDataToday',
        socketCommand: {
          list: window.SocketCommand.Order.Daily
        },
        options: {
          serverSideEnabled: false,
          defaultSortBy    : 'order_id',
          sortBy           : ['order_id'],
          sortDesc         : [true]
        },
        rejectHeaders: {
          xsOnly   : ['icons'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Order.Headers.SourceType'),
            value    : 'source_type',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : '',
            value    : 'daily_check',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1',
            width    : 0
          },
          {
            text     : this.$t('Order.Headers.OrderId'),
            value    : 'order_id',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : '',
            value    : 'icons',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.Customer'),
            value    : 'customer',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.User'),
            value    : 'staff_id',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.Status'),
            value    : 'status',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.OrderType'),
            value    : 'order_type',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.Total'),
            value    : 'order_total',
            align    : 'right',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : '',
            value    : 'order_payment',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1',
            width    : 25
          },
          {
            text     : this.$t('Order.Headers.Date'),
            value    : 'date_added',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : '',
            value    : 'actions',
            align    : 'right',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1',
            width    : 40
          }
        ]
      },

      filtersModel: {
        lang           : 'Order.OrdersAll',
        filterOrderType: {
          value: 0,
          items: this.$t('Order.OrdersAll.Filter.filterOrderType.Items')
        },
        filterOrderReceipt: {
          value   : 0,
          items   : this.$t('Order.OrdersAll.Filter.filterOrderReceipt.Items'),
          disabled: true
        },
        filterHasDeliveryStaff: {
          value: 0,
          items: this.$t('Order.OrdersAll.Filter.filterHasDeliveryStaff.Items')
        },
        filterOrderStatus: {
          value: [],
          items: this.$t('Order.OrdersAll.Filter.filterOrderStatus.Items').filter(o => o.Id > 0)
        },
        filterSourceType: {
          value: null,
          items: []
        },
        filterPaymentType: {
          value: null,
          items: []
        },
        filterStaff: {
          value: null,
          items: []
        }
      },
      selectedDeliveryStaff                           : null,
      selectedDeliveryStaffDialogVisible              : false,
      confirmDialogRemoveOrderFromDeliveryStaffVisible: false,
      ordersMapDialogVisible                          : false
    }
  },
  computed: {
    ReceiptProviderTypeEnum () {
      return ReceiptProviderTypeEnum
    },
    SourceTypeEnum () {
      return SourceTypeEnum
    },
    PaymentTypeEnum () {
      return PaymentTypeEnum
    },
    OrderStatusEnum () {
      return OrderStatusEnum
    },
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },
    customerColumnMaxWidth () {
      return this.$vuetify.breakpoint.lgAndDown ? 'max-width: 200px; width: 200px;' : 'max-width: 310px;  width: 310px;'
    },
    filteredItems () {
      const items = this.dataTable?.data || []
      const filterOrderType = this.filtersModel.filterOrderType.value
      const filterHasDeliveryStaff = this.filtersModel.filterHasDeliveryStaff.value
      const filterOrderReceipt = this.filtersModel.filterOrderReceipt.value
      const filterSourceType = this.filtersModel.filterSourceType.value
      const filterPaymentType = this.filtersModel.filterPaymentType.value
      const filterOrderStatus = this.filtersModel.filterOrderStatus.value
      const filterStaff = this.filtersModel.filterStaff.value

      return items
        .map(o => {
          o.isSelectable = (parseInt(o.StatusId) === OrderStatusEnum.Completed || parseInt(o.StatusId) === OrderStatusEnum.Canceled) && this.userCanDeleteOrders
          return o
        })
        .filter(o => this.dailyOrdersVisibleStatuses.includes(o.StatusId))
        .filter(o => filterOrderType ? o.OrderTypeId === filterOrderType && parseInt(o.SourceTypeId) !== 4 : true)
        .filter(o => filterStaff ? o.StaffId === filterStaff : true)
        .filter(o => filterHasDeliveryStaff ? filterHasDeliveryStaff === 1 ? o.DeliveryStaffId > -1 : o.DeliveryStaffId === -1 : true)
        .filter(o => filterOrderReceipt ? filterOrderReceipt === 1 ? !!o.ReceiptId : !o.ReceiptId : true)
        .filter(o => filterSourceType ? o.SourceTypeId === filterSourceType : true)
        .filter(o => filterPaymentType ? filterPaymentType === 'cod' ? o.Payment === 'cod' || o.Payments?.cod : o.Payment !== 'cod' || o.Payments?.card : true)
        .filter(o => filterOrderStatus?.length > 0 ? filterOrderStatus?.includes(o.StatusId) && !o.DeletedAt : true)
    },
    staffList () {
      const data = this.dataTable?.data || []
      const list = data.map(item => {
        return {
          StaffId       : item.StaffId,
          StaffFullname : `${ item.StaffFirstname } ${ item.StaffLastname }`,
          StaffFirstname: item.StaffFirstname,
          StaffLastname : item.StaffLastname
        }
      })

      return [...new Set(list)]
    },
    deliveryStaffList () {
      return window.Vue?.deliveryStaffItems || []
    },
    dailyOrdersVisibleStatuses () {
      return this.posUser?.Settings?.Orders?.VisibleStatuses || [OrderStatusEnum.Received, OrderStatusEnum.Accepted, OrderStatusEnum.Preparation, OrderStatusEnum.Delivery, OrderStatusEnum.Completed, OrderStatusEnum.Canceled]
    }
  },
  watch: {
    selectedDeliveryStaff (newVal) {
      if (newVal) {
        window.callAS(window.SocketCommand.Staff.Delivery.Shift.AddOrder, {
          Id     : newVal.Id,
          OrderId: this.selectedOrder.Id
        }, window.SocketCommand.Staff.Delivery.All)
        this.selectedOrder = null
        this.selectedDeliveryStaff = null
      }
    }
  },
  created () {
    // console.log('1. created');
    this.$bus.$on('show-add-order-to-delivery-staff-dialog', this.showAddOrderToDeliveryStaff)
    this.$bus.$on('show-remove-order-from-delivery-staff-dialog', this.showRemoveOrderFromDeliveryStaffDialog)
    this.$bus.$on(window.SocketCommand.Staff.Delivery.All, this.getData)
    this.$bus.$on(window.SocketCommand.Receipt.Update, this.getData)
    this.$bus.$on(window.SocketCommand.Order.Payment.Transaction.OrderResult, this.onPaymentTransactionResult)
    this.$bus.$on(window.SocketCommand.Order.Daily, this.onGetAllOrders)

    // Init Custom Filter Values
    this.filtersModel.filterSourceType.items = this.sourceTypesList
    this.filtersModel.filterPaymentType.items = this.paymentTypesLang
    this.filtersModel.filterOrderReceipt.disabled = !this.appHasReceiptsProvider
    this.filtersModel.filterStaff.items = this.staffList
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off('show-add-order-to-delivery-staff-dialog', this.showAddOrderToDeliveryStaff)
    this.$bus.$off('show-remove-order-from-delivery-staff-dialog', this.showRemoveOrderFromDeliveryStaffDialog)
    this.$bus.$off(window.SocketCommand.Staff.Delivery.All, this.getData)
    this.$bus.$off(window.SocketCommand.Receipt.Update, this.getData)
    this.$bus.$off(window.SocketCommand.Order.Payment.Transaction.OrderResult, this.onPaymentTransactionResult)
    this.$bus.$off(window.SocketCommand.Order.Daily, this.onGetAllOrders)
  },
  methods: {
    getContrastColor,

    customSearch (value, search, item) {
      if (!search) return

      const searchString = greekUtils.toGreeklish(String(search).toLocaleLowerCase(this.$i18n.locale))

      const itemString = [
        item.Id && String(item.Id),
        item.Email && String(item.Email),
        item.Address && greekUtils.toGreeklish(JSON.stringify(item.Address).toLocaleLowerCase(this.$i18n.locale)),
        item.FirstName && greekUtils.toGreeklish(String(item.FirstName).toLocaleLowerCase(this.$i18n.locale)),
        item.LastName && greekUtils.toGreeklish(String(item.LastName).toLocaleLowerCase(this.$i18n.locale)),
        item.StaffFirstname && greekUtils.toGreeklish(String(item.StaffFirstname).toLocaleLowerCase(this.$i18n.locale)),
        item.StaffLastname && greekUtils.toGreeklish(String(item.StaffLastname).toLocaleLowerCase(this.$i18n.locale)),
        item.Telephone && String(item.Telephone),
        item.Telephone2 && String(item.Telephone2),
        item.Telephone3 && String(item.Telephone3)
      ].filter(Boolean).join(' ')

      return itemString.includes(searchString)
    },

    getDeliveryStaffById (deliveryStaffId) {
      return this.deliveryStaffList.find(staff => parseInt(staff.Id) === parseInt(deliveryStaffId))
    },
    deliveryStaffHasActiveShift (order) {
      return order && order.DeliveryStaffHasActiveShift
    },
    showRemoveOrderFromDeliveryStaffDialog (order) {
      if (order && this.deliveryStaffHasActiveShift(order)) {
        this.selectedOrder = order
        this.confirmDialogRemoveOrderFromDeliveryStaffVisible = true
      }
    },
    removeOrderFromDeliveryStaff () {
      const selectedDeliveryStaff = this.getDeliveryStaffById(this.selectedOrder.DeliveryStaffId)

      if (selectedDeliveryStaff && this.selectedOrder) {
        const selectedOrder = selectedDeliveryStaff.ShiftOrders.find(order => parseInt(order.Id) === parseInt(this.selectedOrder.Id))

        if (selectedOrder) {
          window.callAS(window.SocketCommand.Staff.Delivery.Shift.RemoveOrder, {
            DeliveryStaffShiftOrderId: selectedOrder.DeliveryStaffShiftOrderId,
            DeliveryStaffShiftId     : selectedDeliveryStaff.DeliveryStaffShiftId,
            OrderId                  : selectedOrder.Id
          }, window.SocketCommand.Staff.Delivery.All)
        }
      }
      this.selectedOrder = null
    },
    showSelectOrderTypeTimeDialog (order) {
      if (parseInt(order.StatusId) === OrderStatusEnum.Accepted) {
        this.executeWithPinPermissionManager(PinManagerEnum.PosChangeOrderTimeAfterAccept, () => {
          this.$bus.$emit('order-type-time-dialog', order)
        })
      } else {
        this.executeWithPinPermissionManager(PinManagerEnum.PosChangeOrderTimeDuringAccept, () => {
          this.$bus.$emit('order-type-time-dialog', order)
        })
      }
    },
    showAddOrderToDeliveryStaff (order) {
      this.selectedOrder = order
      this.selectedDeliveryStaffDialogVisible = true
    },
    showAcceptOrderDialog (order) {
      this.$bus.$emit('show-order-accept-dialog', order)
    },
    showOrderMapDialog () {
      this.ordersMapDialogVisible = true
    },

    orderPaymentDialogAvailable (order) {
      if (
        SourceTypeEnum.ONLINE_SOURCES.includes(parseInt(order.SourceTypeId)) ||
        SourceTypeEnum.TABLE === parseInt(order.SourceTypeId) ||
        order.StatusId === OrderStatusEnum.Canceled ||
        (order.DeletedAt && order.StatusId === OrderStatusEnum.Canceled) ||
        !!order.ExternalSourceTypeId ||
        !!order.ReceiptId
      ) {
        return false
      }

      return true
    },

    showOrderPaymentDialog (order) {
      this.selectedOrder = order

      this.executeWithPinPermissionManager(PinManagerEnum.PosPayments, () => {
        this.orderPaymentDialogVisible = true
      })
    },

    onPaymentTransactionResult (data) {
      this.getData({ orderId: data?.order_id || null })
    },

    getData (payload = {}) {
      this.getDataInternal(payload)
      window.callAS(window.SocketCommand.Order.Counts, {})
      // console.log((new Error()).stack)
    },

    onGetAllOrders (data, payload) {
      if (!payload?.orderId) return

      const order = data?.find(order => parseInt(order.Id) === parseInt(payload.orderId))
      if (this.selectedOrder?.Id === order?.Id) this.selectedOrder = order
    }
  }
}
</script>

<style lang="css" scoped>

</style>
