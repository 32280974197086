import { AES }                   from '@/lib/crypto/crypto'
import PinManagerEnum            from '@/api/Enums/PinManagerEnum'
import { isBoolean, isFunction } from '@/lib/data/Validations/utils/Utils'

export default {
  data () {
    return {}
  },

  mixins: [],

  methods: {
    userNeedsPinPermission (permission) {
      if (this.posUser?.Settings?.ExcludeFromPin) return false

      const validPermissions = Object.values(PinManagerEnum)
      if (!validPermissions.includes(permission)) {
        // eslint-disable-next-line no-console
        console.warn(`PIN Action Permission "${ permission }" does not exist!`)
        return false
      }

      return this.pinActionsManagerEnabled && this.pinActionsManagerPermissions[permission] || false
    },

    executeWithPinPermissionManager (permission, callback, action, payload) {
      const condition = isBoolean(permission) ? permission : this.userNeedsPinPermission(permission)

      if (condition) {
        this.$bus.$emit('show-manager-pin-dialog', {
          action  : action || null,
          payload : payload || null,
          callback: callback || null
        })
      } else {
        if (isFunction(callback)) callback()
      }
    }
  },

  computed: {
    pinActionsManager () {
      const data = this.appConfig?.LOCATION_DATA?.PinActionsManager || []
      const staffGroupId = this.posUser?.staff_group_id ?? 0
      const userPinActionsManager = data.find(group => parseInt(group.Id) === parseInt(staffGroupId))

      return userPinActionsManager
    },
    pinActionsManagerEnabled () {
      return !!this.pinActionsManager?.Enabled
    },
    pinActionsManagerPin () {
      if (this.pinActionsManagerEnabled) return AES.decrypt(this.pinActionsManager.Pin)

      return ''
    },
    pinActionsManagerPermissions () {
      return this.pinActionsManager?.Permissions || {}
    }
  }
}
