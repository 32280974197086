export default {

  Settings: {
    Toolbar: {
      Title   : 'Ρυθμίσεις',
      Subtitle: 'Ρυθμίσεις Συστήματος',
      Icon    : 'settings',
      Color   : 'indigo'
    },
    ListView: {
      Title   : 'Επιλέξτε Κατηγορία Ρυθμίσεων',
      Subtitle: '@:Settings.Settings.ListView.Title από την λίστα'
    },
    Header: {
      Store: {
        Title   : 'Κατάστημα',
        Icon    : 'storefront',
        Keywords: ['κατάστημα', 'στοιχεία', 'διεύθυνση', 'επιχείρηση', 'επωνυμία', 'λογότυπο']
      },
      WorkHour: {
        Title   : 'Ωράριο',
        Icon    : 'timer',
        Keywords: ['ωράριο', 'λειτουργία', 'χρόνοι']
      },
      Delivery: {
        Title   : 'Παράδοση',
        Icon    : 'directions_bike',
        Keywords: ['παράδοση', 'διανομή', 'χάρτη', 'χάρτης', 'χάρτες', 'όρια', 'διευθύνσεις', 'lat', 'lng', 'συντεταγμένες', 'qr', 'qrcode', 'χώρα', 'χρέωση']
      },
      Order: {
        Title   : 'Παραγγελίες',
        Icon    : 'shopping_cart',
        Keywords: ['παραγγελίες', 'tips', 'κατάσταση', 'αυτόματη', 'αποδοχή', 'ολοκλήρωση', 'μέθοδος', 'πληρωμή', 'τύπος', 'παράδοση', 'παραλαβή', 'προεπιλογή', 'ποσό', 'ελάχιστο', 'σημερινές', 'pos', 'ωράριο', 'πηγές', 'σχόλια', 'λίστα', 'εμφάνιση', 'κατηγορίες', 'οριζόντια', 'κάθετα', 'υπολογιστής', 'κινητό', 'προβολή', 'προσθήκη', 'click', 'χρώμα', 'πλάτος', 'αναζήτηση', 'εικόνες', 'εικόνα', 'θέση', 'τιμή', 'προσαρμοσμένη', 'barcode', 'prefix', 'πρόθεμα', 'βάρος', 'αφαίρεση', 'απόθεμα', 'εξωτερικές', 'πλατφόρμες', 'efood', 'fagi', 'foody', 'getfood', 'platform']
      },
      Tables: {
        Title   : 'Τραπέζια',
        Icon    : 'tab_unselected',
        Keywords: ['τραπέζια', 'άτομα', 'καταχώρηση', 'διαχειριστής', 'λίστα', 'επεκτάσιμη', 'κινητό', 'υπολογιστής', 'παραγγελίες', 'θέση', 'προϊόν', 'σειρά', 'σερβίρισμα', 'σερβιτόροι', 'μεταφορά', 'διαχείριση']
      },
      PinManager: {
        Title   : 'PIN Ενεργειών',
        Icon    : 'mdi-form-textbox-password',
        Keywords: ['pin', 'actions', 'manager']
      },
      Reservation: {
        Title   : 'Κρατήσεις',
        Icon    : 'mdi mdi-book-open-variant',
        Keywords: ['κρατήσεις', 'διάρκεια', 'ώρα', 'κράτηση', 'άφιξη', 'χρόνος', 'άτομα', 'όριο', 'ελάχιστο', 'μέγιστο', 'χωρητικότητα']
      },
      Payment: {
        Title   : 'Πληρωμές',
        Icon    : 'payment',
        Keywords: ['πληρωμές', 'tips', 'μέθοδοι', 'μέθοδος', 'πληρωμή', 'viva', 'everypay', 'stripe', 'pos', 'card', 'cash', 'μετρητά', 'κάρτα', 'πύλες', 'τερματικά', 'nft', 'mellon']
      },
      Website: {
        Title   : 'Website',
        Icon    : 'language',
        Keywords: ['website', 'web', 'site', 'qr', 'qrcode', 'σελίδες', 'γκαλερί', 'gallery', 'social', 'analytics', 'theme', 'αρχική', 'κατάλογος', 'επικοινωνία', 'σύνδεση', 'χρήστες', 'άλλες', 'προσφορές', 'προτεινόμενα', 'προϊόντα', 'κατηγορίες', 'κατάστημα', 'εικόνες', 'μέγεθος', 'θέση', 'email', 'φόρμα', 'περιοχές', 'διανομής', 'σύνδεση', 'google', 'facebook', 'recatcha', 'guest', 'επισκέπτες', 'όροι', 'χρήση', 'απόρρητο', 'πολιτική προστασίας', 'cookies', 'τρόποι', 'πληρωμής', 'επιστροφή', 'χρημάτων', 'pixel', 'ga', 'ga4', 'επιλογές', 'χρωμάτων']
      },
      Regional: {
        Title   : 'Τοπικές Ρυθμίσεις',
        Icon    : 'translate',
        Keywords: ['τοπικές', 'ρυθμίσεις', 'χώρα', 'ζώνη', 'ώρα', 'γλώσσα', 'website', 'pos', 'φπα', 'έσοδα', 'απόδειξη', 'παραστατικά', 'κατηγορία', 'τιμολόγιο', 'κουπόνι', 'χρέωση', 'διανομή', 'tips']
      },
      Documents: {
        Title   : 'Παραστατικά',
        Icon    : 'receipt_long',
        Keywords: ['παραστατικά', 'φπα', 'κεφαλαία', 'πλάτος', 'χάρτη', 'ωράριο', 'barcode', 'αριθμός', 'προϊόν', 'τιμολόγιο', 'απόδειξη', 'δελτίο', 'παραγγελία', 'αναφορά', 'βάρδια', 'διανομέα', 'φορολογικά', 'σειρά', 'αριθμός', 'εκτύπωση', 'πιστωτικό', 'δελτίο', 'χάρτης', 'κατηγορίες', 'qr', 'qrcode', 'ωράριο', 'έκδοση', 'μέγεθος', 'γραμματοσειρά', 'font', 'πλάτος', 'εκτύπωση']
      },
      Notification: {
        Title   : 'Ειδοποιήσεις',
        Icon    : 'notifications_active',
        Keywords: ['ειδοποιήσεις', 'ήχος', 'νέα', 'παραγγελία', 'ειδοποιήσεις', 'πελάτης', 'κατάστημα', 'email', 'επιβεβαίωση']
      },
      Printer: {
        Title   : 'Εκτυπωτές',
        Icon    : 'print',
        Keywords: ['εκτυπωτές', 'ομάδες', 'κουζίνα', 'πάσο', 'bar', 'μπάρ', 'δελτίο', 'παραγγελίες', 'προετοιμασία', 'παραστατικά', 'φορολογικά', 'απόδειξη', 'τιμολόγιο', 'πιστωτικό', 'αναφορές']
      },
      Display: {
        Title   : 'Οθόνες',
        Icon    : 'mdi-monitor-screenshot',
        Keywords: ['οθόνες', 'οθόνη', 'display', 'monitor', 'customer', 'screen', 'cds']
      },
      Email: {
        Title   : 'Email',
        Icon    : 'email',
        Keywords: ['email', 'smtp', 'port', 'κρυπτογράφηση', 'tls', 'ssl', 'αποστολέας']
      },
      Permission: {
        Title   : 'Δικαιώματα',
        Icon    : 'group',
        Keywords: ['δικαιώματα', 'πρόσβαση', 'απαιτήσεις', 'api']
      },
      Domain: {
        Title   : 'Domains',
        Icon    : 'domain',
        Keywords: ['domains', 'env', 'reseller', 'package', 'voip', 'συνδρομή', 'mobile', 'app', 'platforms', 'bug', 'snag', 'busnag', 'log', 'logrocket', 'εξωτερικές', 'efood', 'fagi', 'foody', 'getfood', 'wolt', 'debug', 'logging', 'remote', 'permissions', 'marketing', 'customer', 'πελάτης', 'application', 'demo', 'landing', 'fb', 'iframe', 'παραγγελία', 'διαγραφή', 'οριστική', 'προεπιλεγμένος τύπος', 'παράδοση', 'διανομή', 'παραλαβή', 'qr', 'qronly', 'φορολογικά', 'παραστατικά', 'αποδείξεις', 'τιμολόγια', 'πάροχοι', 'ηλεκτρονική', 'τιμολόγηση', 'live', 'staging', 'impact', 'rbs', 'mat', 'primer', 'generic', 'crm', 'app', 'mobile', 'ios', 'android', 'onesignal', 'push', 'notifications']
      },
      OnlineUsers: {
        Title   : 'Online Users',
        Icon    : 'mdi-account-voice',
        Keywords: ['domains', 'online', 'users', 'socket', 'production', 'development']
      },
      SystemUsers: {
        Title   : 'System Users',
        Icon    : 'mdi-account-group',
        Keywords: ['domains', 'online', 'all', 'system', 'users', 'socket', 'production', 'development']
      }
    }
  },

  Delivery: {
    Toolbar: {
      Title   : 'Παράδοση Παραγγελιών',
      Subtitle: '@:Settings.Settings.Toolbar.Title @:Settings.Delivery.Toolbar.Title',
      Icon    : 'directions_bike',
      Color   : 'blue'
    },
    Tab: {
      Settings    : 'Ρυθμίσεις Παράδοσης',
      Maps        : 'Ρυθμίσεις Χαρτών',
      DeliveryArea: 'Όρια παράδοσης'
    },
    ListView: {
      OutOfRange: {
        Title   : 'Διευθύνσεις Εκτός Ορίων Καταστήματος',
        Subtitle: 'Επιτρέπονται διευθύνσεις πελατών εκτός ορίων καταστήματος για καταχωρήσεις από το POS.',
        Warning : 'Εμφανίζεται προειδοποίηση κατά την καταχώρηση. Δεν επηρεάζει την λειτουργία του online καταστήματος.'
      },
      AcceptCustomAddresses: {
        Title   : 'Διευθύνσεις Χωρίς Επιβεβαίωση μέσω χάρτη',
        Subtitle: 'Επιτρέπονται διευθύνσεις πελατών χωρίς επιβεβαίωση μέσω χάρτη.',
        Warning : 'Προσοχή! Ενδέχεται οι διευθύνσεις να είναι εκτός ορίων καταστήματος.'
      },
      AcceptLatLngAddresses: {
        Title   : 'Διευθύνσεις Μόνο με Χρήση Συντεταγμένων χάρτη (Lat / Lng)',
        Subtitle: 'Επιτρέπονται διευθύνσεις πελατών χωρίς οδό, αριθμό, περιοχή ή τ.κ. αλλά μόνο με συντεταγμένες',
        Warning : 'Προσοχή! Ενδέχεται οι διευθύνσεις να μην έχουν άλλα στοιχεία εκτός από τις συντεταγμένες στο χάρτη.'
      },
      DeliveryLimit: {
        Title   : 'Όρια Παράδοσης Παραγγελιών Καταστήματος',
        Subtitle: 'Δημιουργία ορίων παράδοσης παραγγελιών καταστήματος με την χρήση χάρτη.'
      },
      Country: {
        Title      : 'Χώρα/Χώρες Παράδοσης Παραγγελιών Καταστήματος',
        Subtitle   : 'Προαιρετικά επιλέξτε χώρα ή χώρες για περιορίσετε τις διευθύνσεις παράδοσης παραγγελιών και τις διευθύνσεις χαρτών.',
        PlaceHolder: 'Χώρα/Χώρες Παράδοσης',
        Error      : {
          Max: 'Επιλέξτε έως 5 χώρες'
        }
      },
      Maps: {
        Info: {
          Google: 'Όλα τα Google Api Keys θα πρέπει να επιτρέπουν χρήση στα εξής domains:',
          MapBox: 'Όλα τα MapBox Api Keys θα πρέπει να επιτρέπουν χρήση στα εξής domains:'
        },
        Pos: {
          Title: 'Σύστημα Εσωτερικής Παραγγελιοληψίας (POS)',
          Icon : 'mdi-network-pos'
        },
        Web: {
          Title: 'Website & Mobile App',
          Icon : 'mdi-web'
        },
        ApiKey: {
          Map    : 'Api Key',
          Geocode: 'Api Key'
        },
        Provider: {
          DefaultMap: {
            Title   : 'System Default Πάροχος Διαδραστικών Χαρτών',
            Subtitle: ''
          },
          Map: {
            Title   : 'Πάροχος Διαδραστικών Χαρτών',
            Subtitle: 'Interactive Maps Provider'
          },
          MapStatic: {
            Title   : 'Πάροχος Στατικών Χαρτών',
            Subtitle: 'Static Maps Provider'
          },
          MapPrint: {
            Title   : 'Πάροχος Εκτύπωσης Χαρτών',
            Subtitle: 'Static Maps Provider'
          },
          Geocode: {
            Title   : 'Πάροχος Γεωκώδικα',
            Subtitle: 'Geocode Provider'
          }
        },
        Providers: [
          {
            Name: 'Google',
            Key : 'google'
          },
          {
            Name: 'MapBox',
            Key : 'mapbox'
          }
        ]
      },
      Map: {
        Button: {
          Add    : 'Προσθήκη Περιοχής',
          Polygon: 'Πολύγωνο',
          Circle : 'Κύκλος'
        },
        Region: 'Περιοχή',
        Field : {
          Title          : { Label: 'Τίτλος Περιοχής' },
          Move           : { Label: 'Μετακίνηση Πολυγώνου' },
          ChargeCondition: {
            Title   : 'Συνθήκες χρέωσης παράδοσης παραγγελιών',
            Subtitle: 'Χρησιμοποιήστε τον παρακάτω πίνακα για να ορίσετε διαφορετικές συνθήκες χρέωσης παράδοσης. Πχ. xx έξοδα παράδοσης στην παραγγελία συνολικά πάνω από xx. Ορίστε 0 έξοδα παράδοσης για δωρεάν παράδοση ή να εφαρμόζονται σε όλες τις παραγγελίες.',
            Label   : {
              Charge      : 'Χρέωση',
              Condition   : 'Συνθήκη',
              Total       : '@:Common.Misc.Total',
              NewCondition: {
                All   : 'Όλες οι παραγγελίες',
                Above : 'Για παραγγελία πάνω από',
                Bellow: 'Για παραγγελία κάτω από'
              }
            },
            Notification: 'Οι ρυθμίσεις παράδοσης δεν ενημερώθηκαν. @:Common.Misc.Retry'
          }
        }
      }
    }
  },

  Domain: {
    Title  : 'Domains | Domain | Domains',
    Toolbar: {
      Subtitle: '@:Settings.Settings.Toolbar.Title Domains',
      Icon    : 'domain',
      Color   : 'blue'
    },
    Button: {
      BackupAll: 'Backup Domains',
      Add      : {
        Title: 'Νέο Domain',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Filters: {
      Package   : { Label: 'Package' },
      Reseller  : { Label: 'Reseller' },
      StoreGroup: { Label: 'Είδος Επιχείρησης' }
    },
    Dialog: {
      Backup: {
        Title  : 'Backup Domain;',
        Content: 'Είστε σίγουροι ότι θέλετε να κάνετε backup το domain <strong>{domain}</strong>;'
      },
      BackupAll: {
        Title  : 'Backup All Domains?',
        Content: 'Είστε σίγουροι ότι θέλετε να κάνετε backup όλα τα domains?'
      },
      Delete: {
        Title  : 'Διαγραφή Domain?',
        Content: 'Είστε σίγουροι ότι θέλετε να διαγράψετε οριστικά το domain <strong>{domain}</strong>;',
        Delay  : 'Καθυστέρηση διαγραφής',
        Warning: 'Η οριστική διαγραφή domain είναι μη αναστρέψιμη!'
      }
    },
    Notification: {
      Success: 'Το backup του domain ολοκληρώθηκε.',
      Error  : 'Το Domain δεν ενημερώθηκε. @:Common.Misc.Retry',
      Generic: {
        Success: 'Το Domain ενημερώθηκε.',
        Error  : 'Το Domain δεν ενημερώθηκε. @:Common.Misc.Retry'
      }
    },
    Edit: {
      Toolbar: {
        Add: {
          Title   : 'Δημιουργία Domain',
          Subtitle: 'Δημιουργία Νέου Domain'
        },
        Edit: {
          Title   : 'Επεξεργασία Domain',
          Subtitle: 'Τροποποίηση Στοιχείων Domain'
        },
        Icon : 'domain',
        Color: 'blue'
      },
      Tab: {
        Domain       : 'Domain',
        Settings     : 'Settings',
        Documents    : 'Documents',
        Customer     : 'Customer',
        Permissions  : 'Permissions',
        Mobile       : 'Mobile App',
        Sms          : 'SMS',
        Marketing    : 'Marketing',
        Platforms    : 'Platforms',
        WebHooks     : 'WebHooks',
        RemoteLogging: 'Remote Logging',
        Application  : 'Application'
      },
      Field: {
        WebHooks: {
          Header: {
            Title: 'Webhook Headers',
            Name : 'Όνομα',
            Value: 'Τιμή'
          },
          Button: {
            Add   : 'Προσθήκη WebHook',
            Delete: 'Διαγραφή Webhook'
          },
          Order: {
            Title   : 'Παραγγελίες',
            Subtitle: 'Webhooks Παραγγελιών',
            Field   : {
              Name: {
                Label: 'Όνομα',
                Hint : ''
              },
              Url: {
                Label: 'URL',
                Hint : ''
              },
              Status: {
                Label: 'Κατάσταση Παραγγελίας',
                Hint : 'Ενεργοποίηση του webhook μόνο σε αυτές τις καταστάσεις παραγγελίας'
              },
              Production: {
                Label: 'Production Περιβάλλον',
                Hint : 'Ενεργοποίηση ή απενεργοποίηση του production περιβάλλοντος'
              }
            }
          }
        },
        Platform: {
          Update: {
            Title : 'Αυτόματη Ενημέρωση Καταλόγου',
            Dialog: {
              Title   : 'Αυτόματη Ενημέρωση Καταλόγου',
              Subtitle: 'Επιλέξτε Ώρα',
              Field   : {
                Time: {
                  Label      : 'Ώρα',
                  Placeholder: 'HH:MM',
                  Hint       : 'Ώρα αυτόματης ενημέρωσης',
                  Icon       : 'mdi-calendar-clock',
                  Error      : {
                    Required  : 'Το πεδίο είναι υποχρεωτικό',
                    TimeString: 'Πρέπει να είναι έγκυρη ώρα της μορφής HH:MM'
                  }
                }
              }
            }
          },
          Efood: {
            Active: {
              Title   : 'E-Food Integration',
              SubTitle: 'E-Food Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          Foody: {
            Active: {
              Title   : 'Foody Integration',
              SubTitle: 'Foody Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          Fagi: {
            Active: {
              Title   : 'Fagi Integration',
              SubTitle: 'Fagi Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          Wolt: {
            Active: {
              Title   : 'Wolt Integration',
              SubTitle: 'Wolt Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          GetFood: {
            Active: {
              Title   : 'GetFood Integration',
              SubTitle: 'GetFood Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          }
        },
        TrialExpiredAt: {
          Label: 'Λήξη Δοκιμαστικής Περιόδου',
          Hint : 'Ημερομηνία Λήξης Δοκιμαστικής Περιόδου',
          Icon : 'today'
        },
        SubscriptionExpiredAt: {
          Label: 'Λήξη Συνδρομής',
          Hint : 'Ημερομηνία Λήξης Συνδρομής',
          Icon : 'today'
        },
        SeasonStartedAt: {
          Label: 'Έναρξη Σεζόν',
          Hint : 'Ημερομηνία Έναρξη Σεζόν',
          Icon : 'today'
        },
        SeasonEndedAt: {
          Label: 'Λήξη Σεζόν',
          Hint : 'Ημερομηνία Λήξης Σεζόν',
          Icon : 'today'
        },
        ExtendedPeriodAt: {
          Label: 'Έναρξη Περιόδου Χάριτος',
          Hint : 'Ημερομηνία Έναρξης Περιόδου Χάριτος',
          Icon : 'today'
        },
        CreateLead: {
          Label: 'Δημιουργία Δυνητικού Πελάτη',
          Hint : 'Δημιουργία Δυνητικού Πελάτη',
          Icon : 'engineering'
        },
        Company: {
          Label: 'Εταιρεία',
          Hint : 'Επωνυμία Εταιρείας',
          Icon : 'store'
        },
        Firstname: {
          Label: 'Όνομα',
          Hint : 'Όνομα Δυνητικού Πελάτη',
          Icon : 'person'
        },
        Lastname: {
          Label: 'Επώνυμο',
          Hint : 'Επώνυμο Δυνητικού Πελάτη',
          Icon : 'person'
        },
        Email: {
          Label: 'Email',
          Hint : 'Email Δυνητικού Πελάτη',
          Icon : 'email'
        },
        Telephone: {
          Label: 'Τηλέφωνο',
          Hint : 'Τηλέφωνο Δυνητικού Πελάτη',
          Icon : 'phone'
        },
        CustomerId: {
          Label: 'ID Πελάτη',
          Hint : 'Το ID του πελάτη από το CRM',
          Icon : 'person'
        },
        LeadId: {
          Label: 'ID Δυνητικού Πελάτη',
          Hint : 'Το ID του δυνητικού πελάτη από το CRM',
          Icon : 'engineering'
        },
        ResellerId: {
          Label: 'Reseller',
          Hint : 'Reseller from CRM',
          Icon : 'mdi-face-agent'
        },
        Country: {
          Label: 'Χώρα',
          Hint : 'Χώρα εταιρείας. πχ. Ελλάδα',
          Icon : 'map'
        },
        BaseDomain: {
          Label: 'Base Domain',
          Hint : '',
          Icon : 'public'
        },
        SubDomain: {
          Label: 'Sub Domain',
          Hint : 'Το subdomain πχ. demo, dev, κλπ.',
          Icon : 'domain'
        },
        CName: {
          Label: 'C Name',
          Hint : 'Το cname πχ. getfood.gr',
          Icon : 'http'
        },
        VoipDomain: {
          Label: 'Voip ID',
          Hint : 'Το Voip ID πχ. 66',
          Icon : 'dialer_sip'
        },
        VoipDomainDesc: {
          Label: 'Αριθμός Voip',
          Hint : 'Ο αριθμός Voip πχ. 2152152324',
          Icon : 'perm_phone_msg'
        },
        DatabaseName: {
          Label: 'Όνομα Βάσης Δεδομένων',
          Hint : 'Το όνομα για το σχήμα της βάσης δεδομένων πχ. demo_getfood',
          Icon : 'fas fa-database'
        },
        DatabaseAutoGenerate: {
          Active  : 'Αυτόματα',
          Inactive: 'Χειροκίνητα'
        },
        StoreGroup: {
          Label: 'Επιλέξτε Είδος Επιχείρησης',
          Hint : 'Το είδος της επιχείρησή σας',
          Group: {
            FoodIndustry: 'Εστίαση και Τρόφιμα',
            Other       : 'Άλλα'
          }
        },
        ImportDummyData: {
          Title   : 'Import Dummy Data',
          Subtitle: 'Εισαγωγή dummy/demo καταλόγου καταστήματος με βάση τον τύπο επιχείρησης'
        },
        ImportFromThirdParty: {
          Title   : 'Εισαγωγή από άλλη πλατφόρμα;',
          Subtitle: 'Εισαγωγή καταλόγου καταστήματος από άλλη πλατφόρμα;',
          Label   : 'Διευθυνση (URL) του καταστήματος'
        },
        ImportFromThirdPartyForce: {
          Title   : 'Forced εισαγωγή;',
          Subtitle: 'Εισαγωγή Live ή από την Cache (ανενεργό)'
        },
        ImportFromThirdPartyWithOptions: {
          Title   : 'Εισαγωγή με επιλογές;',
          Subtitle: 'Εισαγωγή από άλλη πλατφόρμα με επιλογές'
        },
        ImportFromThirdPartyAllLocales: {
          Title   : 'Εισαγωγή όλων των Locales;',
          Subtitle: 'Εισαγωγή όλων των Locales από άλλη πλατφόρμα'
        },
        ImportFromThirdPartyDefaultLocale: {
          Title   : 'Προεπιλεγμένο Locale;',
          Subtitle: 'Διαλέξτε το προεπιλεγμένο Locale'
        },
        HasReceipts: {
          Title   : 'Φορολογικά Παραστατικά',
          Subtitle: 'Φορολογικά παραστατικά ενεργά για το domain.'
        },
        DemoInstallation: {
          Title   : 'Demo Εγκατάσταση',
          Subtitle: 'Ορισμός domain ως Demo κατάστημα / site. Κάποιες λειτουργίες είναι περιορισμένες.'
        },
        SSLCertificate: {
          Title   : 'SSL Certificate',
          Subtitle: 'Δημιουργία ή ενημέρωση SSL Certificate για το C Name'
        },
        UploadDefaultAsset: {
          Title   : 'Upload Default Assets',
          Subtitle: 'Upload default assets για το επιλεγμένο domain στο CDN'
        },
        PurgeCDNCache: {
          Title   : 'Purge CDN Cache',
          Subtitle: 'Purges CDN cache for domain assets'
        },
        AllowFbIFrame: {
          Title   : 'Allow FB IFrame',
          Subtitle: 'Να επιτρέπεται η χρήση iframe για facebook application'
        },
        OrderWithoutAddress: {
          Title   : 'Παραγγελίες παράδοσης χωρίς διεύθυνση (QrOnly)',
          Subtitle: 'Να επιτρέπονται παραγγελίες παράδοσης μόνο χωρίς διεύθυνση.'
        },
        ForceDeleteOrders: {
          Title   : 'Οριστική Διαγραφή Παραγγελιών',
          Subtitle: 'Το κατάστημα έχει ενεργή την Οριστική Διαγραφή Παραγγελιών'
        },
        LandingPage: {
          Title   : 'Landing Page',
          Subtitle: 'Το κατάστημα έχει ενεργό landing page',
          Label   : 'URL Landing Page'
        },
        RedirectToLandingPage: {
          Title   : 'Redirect στο Landing Page',
          Subtitle: 'Redirect πίσω στο Landing Page στην απευθείας πρόσβαση (όχι μέσω landing page) στα websites'
        },
        OrderType: {
          Title   : 'Τύποι παραγγελίας',
          Subtitle: 'Εμφάνιση επιλογής τύπου παραγγελίας website'
        },
        DefaultOrderType: {
          Title: {
            Site: 'Προεπιλεγμένος τύπος παραγγελίας website',
            Pos : 'Προεπιλεγμένος τύπος παραγγελίας POS'
          },
          Delivery: {
            Title: '@:Order.Type.Delivery.Title',
            Icon : '@:Order.Type.Delivery.Icon'
          },
          Collection: {
            Title: '@:Order.Type.Collection.Title',
            Icon : '@:Order.Type.Collection.Icon'
          }
        }, // Permissions
        SelectPackage: 'Επιλέξτε Πακέτο', // Application
        AppName      : {
          Label: 'Όνομα',
          Hint : 'Το όνομα του application πχ. demo.getfood.gr, κλπ.',
          Icon : 'label'
        },
        AppId: {
          Label: 'Id',
          Icon : 'fas fa-key'
        },
        AppKey: {
          Label: 'Key',
          Icon : 'vpn_key'
        },
        AppSecret: {
          Label: 'Secret',
          Icon : 'security'
        },
        Permission: 'Δικαίωμα',
        Access    : 'Πρόσβαση'
      }
    },
    Import: {
      Toolbar: {
        Title   : 'Εισαγωγή από άλλη πλατφόρμα',
        Subtitle: 'Εισαγωγή καταλόγου καταστήματος από άλλη πλατφόρμα',
        Icon    : 'domain',
        Color   : 'blue'
      },
      Field: {
        StoreId: {
          Label: 'ID ή URL Καταστήματος',
          Hint : 'Το ID ή το URL του καταστήματος από την άλλη πλατφόρμα',
          Icon : 'fas fa-store',
          Error: {
            Required: 'Το πεδίο απαιτείται',
            Between : 'Πρέπει να είναι τουλάχιστον 3 χαρακτήρες'
          }
        },
        CatalogOnly: {
          Label: 'Εισαγωγή μόνο καταλόγου',
          Hint : 'Εισαγωγή μόνο καταλόγου προϊόντων'
        },
        Catalog: {
          Label: 'Επιλογή καταλόγου',
          Hint : 'Επιλογή καταλόγου προϊόντων για εισαγωγή'
        },
        CatalogId: {
          Label: 'Κατάλογος (id)',
          Hint : 'Επιλέξτε κατάλογο (id) προϊόντων',
          Icon : 'menu_book'
        }
      }
    },
    Copy: {
      Notification: 'Υπήρξε πρόβλημα με την αντιγραφή! Ελέγξτε τα επιλεγμένα καταστήματα και προσπαθήστε ξανά.',
      Toolbar     : {
        Title   : 'Αντιγραφή Domain',
        Subtitle: 'Αντιγραφή δεδομένων ενός domain σε ένα άλλο domain',
        Icon    : 'domain',
        Color   : 'blue'
      },
      Field: {
        Source: {
          Label: 'Αντιγραφή από Domain',
          Hint : 'Domain από το οποίο θα αντιγραφούν τα δεδομένα',
          Icon : 'copy_all'
        },
        Target: {
          Label: 'Αντιγραφή σε Domain',
          Hint : 'Domain στο οποίο θα αντιγραφούν τα δεδομένα',
          Icon : 'adjust'
        },
        WithActivity: {
          Title   : 'Activity Log',
          Subtitle: 'Αντιγραφή με δεδομένα Activity Log',
          Icon    : 'view_list'
        },
        WithMenu: {
          Title   : 'Δεδομένα Καταλόγου',
          Subtitle: 'Αντιγραφή με δεδομένα Καταλόγου',
          Icon    : 'view_list'
        },
        WithLocation: {
          Title   : 'Δεδομένα Location',
          Subtitle: 'Αντιγραφή με δεδομένα Location',
          Icon    : 'map'
        },
        WithCustomers: {
          Title   : 'Δεδομένα Πελατών',
          Subtitle: 'Αντιγραφή με δεδομένα Πελατών',
          Icon    : 'account_circle'
        },
        WithOrders: {
          Title   : 'Δεδομένα Παραγγελιών',
          Subtitle: 'Αντιγραφή με δεδομένα Παραγγελιών',
          Icon    : 'shopping_cart'
        },
        WithCoupons: {
          Title   : 'Δεδομένα Κουπονιών',
          Subtitle: 'Αντιγραφή με δεδομένα Κουπονιών',
          Icon    : 'local_offer'
        },
        WithStaff: {
          Title   : 'Δεδομένα Προσωπικού',
          Subtitle: 'Αντιγραφή με δεδομένα Προσωπικού',
          Icon    : 'supervisor_account'
        },
        WithStaffGroups: {
          Title   : 'Δεδομένα Ομάδων Προσωπικού',
          Subtitle: 'Αντιγραφή με δεδομένα Ομάδων Προσωπικού',
          Icon    : 'supervisor_account'
        },
        WithDeliveryStaff: {
          Title   : 'Δεδομένα Διανομέων',
          Subtitle: 'Αντιγραφή με δεδομένα Διανομέων',
          Icon    : 'directions_bike'
        },
        WithCdr: {
          Title   : 'Δεδομένα Τηλεφωνικών Κλήσεων',
          Subtitle: 'Αντιγραφή με δεδομένα Τηλεφωνικών Κλήσεων',
          Icon    : 'phone_in_talk'
        },
        WithAssets: {
          Title   : 'Δεδομένα Φωτογραφιών',
          Subtitle: 'Αντιγραφή με δεδομένα Φωτογραφιών',
          Icon    : 'photo'
        }
      }
    },
    PaymentFee: {
      Toolbar: {
        Title   : 'Payment Fee',
        Subtitle: 'Επεξεργσία Payment Fee',
        Icon    : 'payment',
        Color   : 'teal'
      },
      Field: {
        Active: {
          Label: 'Active',
          Hint : 'Payment Fee Active',
          Icon : 'toggle_on'
        },
        Factor: {
          Label: 'Factor',
          Hint : 'Payment Factor',
          Icon : 'percent'
        },
        Fee: {
          Label: 'Fee',
          Hint : 'Payment Fee',
          Icon : 'euro'
        }
      }
    },
    Email: {
      Toolbar: {
        Title   : 'Αποστολή Welcome Email',
        Subtitle: 'Αποστολή Welcome Email Στον Πελάτη',
        Icon    : 'email',
        Color   : 'blue'
      },
      Field: {
        Email: {
          Label: 'Username',
          Hint : 'Customer Username',
          Icon : 'email'
        },
        To: {
          Label: 'To',
          Hint : 'To Email',
          Icon : 'email'
        },
        Pin: {
          Label: 'PIN',
          Hint : 'Customer PIN',
          Icon : 'adjust'
        },
        Locale: {
          Label: 'Locale',
          Hint : 'Customer Locale',
          Icon : 'map'
        },
        Firstname: {
          Label: 'Όνομα',
          Hint : 'Όνομα Πελάτη',
          Icon : 'person'
        },
        Lastname: {
          Label: 'Επώνυμο',
          Hint : 'Επώνυμο Πελάτη',
          Icon : 'person'
        },
        Subdomain: {
          Label: 'Subdomain',
          Hint : 'Customer Subdomain',
          Icon : 'language'
        }
      }
    },
    Customer: {
      Toolbar: {
        Title   : 'Επεξεργασία Πελάτη',
        Subtitle: 'Τροποποίηση Στοιχείων Πελάτη',
        Icon    : 'person',
        Color   : 'light-blue'
      }
    },
    DatabaseMove: {
      Notification: 'There was a problem with the domain move! Check the selected target database and try again.',
      Toolbar     : {
        Title   : 'Μεταφορά Domain',
        Subtitle: 'Μεταφορά domain σε άλλη βάση δεδομένων',
        Icon    : 'storage',
        Color   : 'blue-grey'
      },
      Field: {
        Domain: {
          Label: 'Domain',
          Icon : 'language'
        },
        SourceDatabase: {
          Label: 'Απο Βάση Δεδομένων',
          Hint : 'Βάση δεδομένων από όπου θα μεταφερθεί το domain',
          Icon : 'logout'
        },
        TargetDatabase: {
          Label: 'Σε Βάση Δεδομένων',
          Hint : 'Βάση δεδομένων που θα μεταφερθεί το domain',
          Icon : 'login'
        },
        WithDelete: {
          Title   : 'Διαγραφή Βάσης',
          Subtitle: 'Διαγραφή αρχικής βάσης',
          Icon    : 'view_list'
        }
      }
    }
  },

  Catalog: {
    Copy: {
      Button: {
        Title: 'Αντιγραφή Καταλόγου'
      },
      Toolbar: {
        Title   : 'Αντιγραφή Καταλόγου Προϊόντων',
        Subtitle: 'Αντιγράψτε τον κατάλογο προϊόντων σε ένα ή περισσότερα καταστήματά σας',
        Icon    : 'domain',
        Color   : 'blue'
      },
      Field: {
        Source: {
          Label: 'Αντιγραφή από κατάστημα',
          Hint : 'Κατάστημα από το οποίο θα αντιγραφεί ο κατάλογος προϊόντων',
          Icon : 'adjust'
        },
        Target: {
          Label: 'Αντιγραφή σε καταστήματα',
          Hint : 'Καταστήματα στα οποία θα αντιγραφεί ο κατάλογος προϊόντων',
          Icon : 'copy_all'
        }
      }
    }
  },

  Email: {
    Toolbar: {
      Title   : 'Email',
      Subtitle: '@:Settings.Settings.Toolbar.Title Email',
      Icon    : 'email',
      Color   : 'blue'
    },
    Field: {
      SMTPAddress: {
        Label: 'Διεύθυνση SMTP',
        Hint : 'Διεύθυνση SMTP. πχ. mail.getfoodpro.gr',
        Icon : 'fas fa-server',
        Error: {
          Between: 'Η διεύθυνση smtp πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      SMTPPort: {
        Label: 'Πόρτά SMTP',
        Hint : 'Πόρτά SMTP. πχ. 25',
        Icon : 'fas fa-door-open',
        Error: {
          Between: 'Η πόρτα smtp πρέπει να είναι από 2 έως 6 αριθμοί'
        }
      },
      Encryption: {
        Label: 'Κρυπτογράφηση',
        Hint : 'Κρυπτογράφηση SMTP Server',
        Icon : 'lock',
        List : {
          NoEncryption : 'Χωρίς Κρυπτογράφηση',
          SSLEncryption: 'SSL',
          TSLEncryption: 'TSL'
        }
      },
      Username: {
        Label: 'Όνομα Χρήστη',
        Hint : 'Όνομα Χρήστη SMTP. πχ. noreply@getfoodpro.gr',
        Icon : 'account_circle',
        Error: {
          Between: 'Το όνομα χρήστη πρέπει να είναι από 2 έως 96 χαρακτήρες'
        }
      },
      Password: {
        Label: 'Κωδικός Χρήστη',
        Hint : 'Κωδικός Χρήστη SMTP',
        Icon : 'fas fa-shield-alt',
        Error: {
          Between: 'Ο κωδικός χρήστη πρέπει να είναι από 5 έως 32 χαρακτήρες'
        }
      },
      SenderAddress: {
        Label: 'Διεύθυνση Αποστολέα',
        Hint : 'Διεύθυνση αποστολέα πχ. info@getfoodpro.gr',
        Icon : 'email'
      },
      SenderName: {
        Label: 'Όνομα Αποστολέα',
        Hint : 'Όνομα αποστολέα πχ. GETFOOD Pro',
        Icon : 'account_circle'
      },
      TestEmail: {
        Title: 'Αποστολή Δοκιμαστικού Email',
        Email: {
          Label: 'Email παραλήπτη',
          Hint : 'Το email στο οποίο θέλετε να λάβετε το δοκιμαστικό μήνυμα. πχ. info@getfoodpro.gr',
          Icon : 'email',
          Error: {
            Between: 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
            Valid  : 'Το E-mail πρέπει να είναι έγκυρο'
          }
        }
      }
    },
    Notification: {
      Update: 'Οι ρυθμίσεις email δεν ενημερώθηκαν. @:Common.Misc.Retry',
      Send  : 'Το δοκιμαστικό email δεν στάλθηκε. @:Common.Misc.Retry'
    }
  },

  Notification: {
    Toolbar: {
      Title   : 'Ειδοποιήσεις',
      Subtitle: '@:Settings.Settings.Toolbar.Title Ειδοποιήσεων',
      Icon    : 'notifications_active',
      Color   : 'blue'
    },
    Data: {
      Sound: {
        Title: 'Επιλογή ήχου ειδοποίησης νέας παραγγελίας',
        Icon : 'music_video',
        Field: {
          Sound: 'Ήχος',
          Label: 'Ήχος ειδοποίησης νέας παραγγελίας'
        }
      },
      StoreEmail: {
        Title   : 'Email Ειδοποιήσεων Καταστήματος',
        Subtitle: ''
      },
      Registration: {
        Title   : 'Email εγγραφής',
        Subtitle: 'Αυτόματη αποστολή email επιβεβαίωσης στον πελάτη ή/και το κατάστημα μετά την επιτυχή εγγραφή λογαριασμού'
      },
      Reservation: {
        Title   : 'Ειδοποιήσεις Κρατήσεων',
        Subtitle: 'Αυτόματη αποστολή ειδοποιήσεων στον πελάτη ή/και το κατάστημα'
      },
      Order: {
        Title   : 'Ειδοποιήσεις Παραγγελιών',
        Subtitle: 'Αυτόματη αποστολή ειδοποιήσεων στον πελάτη ή/και το κατάστημα, μέσω email ή/και sms για εσωτερικές (POS) ή/και online (website, mobile app) παραγγελίες.'
      },
      InternalOrder: {
        Title   : 'Ειδοποιήσεις Καθυστέρησης Ενέργειας Παραγγελιών',
        Subtitle: 'Εσωτερική ειδοποίηση καθυστέρησης ενέργειας παραγγελιών.',
        Accept  : {
          Icon  : 'mdi-clock',
          Suffix: 'Λεπτά',
          Label : 'Καθυστέρηση Αποδοχής παραγγελίας. Ειδοποίηση <span class="text-body-1">{minutes}</span> λεπτά μετά τη λήψη.'
        },
        CompletedDelivery: {
          Icon  : 'mdi-clock',
          Suffix: 'Λεπτά',
          Label : 'Καθυστέρηση Ολοκλήρωσης παραγγελίας. Ειδοποίηση στα <span class="text-body-1">{minutes}</span> λεπτά. <span class="text-body-1">{minutesBefore}</span> λεπτά πριν από τον χρόνο παράδοσης.'
        },
        CompletedCollection: {
          Icon  : 'mdi-clock',
          Suffix: 'Λεπτά',
          Label : 'Καθυστέρηση Ολοκλήρωσης παραγγελίας. Ειδοποίηση στα <span class="text-body-1">{minutes}</span> λεπτά. <span class="text-body-1">{minutesBefore}</span> λεπτά πριν από τη λήξη του χρόνου παραλαβής.'
        },
        Snooze: {
          Icon  : 'mdi-clock',
          Suffix: 'Λεπτά',
          Title : 'Υπενθύμιση',
          Label : 'Υπενθύμιση σε <span class="text-body-1">{minutes}</span> λεπτά.'
        }
      },

      Button: {
        To: {
          Customer: 'Πελάτης',
          Admin   : 'Διαχειριστής',
          Store   : 'Κατάστημα'
        },
        Channel: {
          Email: 'Email',
          Sms  : 'Sms',
          Push : 'Push'
        },
        Source: {
          Pos   : 'Pos',
          Online: 'Online'
        }
      }
    },
    Notification  : 'Οι ρυθμίσεις ειδοποιήσεων δεν ενημερώθηκαν. @:Common.Misc.Retry',
    SupportRequest: {
      Contact: 'Ενεργοποίηση',
      Sms    : {
        Subject: 'Αίτηση Ενεργοποίησης SMS Notifications',
        Message: 'Θέλω να ενεργοποιήσω την δυνατότητα αποστολής SMS Notifications.'
      },
      Push: {
        Subject: 'Αίτηση Ενεργοποίησης Push Notifications',
        Message: 'Θέλω να ενεργοποιήσω την δυνατότητα αποστολής Push Notifications.'
      }
    }
  },

  Order: {
    Toolbar: {
      Title   : 'Παραγγελίες',
      Subtitle: '@:Settings.Settings.Toolbar.Title Παραγγελιών',
      Icon    : 'shopping_cart',
      Color   : 'blue'
    },
    Field: {
      SubtractStockThreshold: {
        Title   : 'Όρια Αφαίρεσης Αποθέματος',
        Subtitle: 'Ορίστε το όριο αφαίρεσης αποθέματος ανά ένδειξη',
        Values  : {
          Default: {
            Color: 'green',
            Value: ''
          },
          Tier1: {
            Color: 'blue',
            Value: 25
          },
          Tier2: {
            Color: 'orange',
            Value: 10
          },
          Tier3: {
            Color: 'red',
            Value: 5
          }
        }
      },
      GeneralOption: {
        Title    : 'Γενικές Επιλογές',
        Icon     : 'settings',
        OrderTips: {
          Title   : 'Εμφάνιση επιλογής προσθήκης "Tip"',
          Subtitle: 'Ενεργοποίηση της επιλογής προσθήκης "Tip" παραγγελίας για τις ενεργές μεθόδους πληρωμής.'
        },
        OrderStatus: {
          Title   : 'Αρχική Κατάσταση Παραγγελιών',
          Subtitle: 'Επιλέξτε την αρχική κατάσταση των Online & POS παραγγελιών.'
        },
        OrderStatusPerPaymentMethod: {
          Title   : 'Αρχική Κατάσταση Παραγγελιών ανά Μέθοδο Πληρωμής',
          Subtitle: 'Επιλέξτε την αρχική κατάσταση των Online & POS παραγγελιών ανά Μέθοδο Πληρωμής.'
        },
        OrderTypes: {
          Title        : 'Τύποι παραγγελίας',
          ShowOrderType: 'Εμφάνιση επιλογής τύπου παραγγελίας website',
          Label        : {
            DefaultTypeWebsite: 'Προεπιλεγμένος τύπος παραγγελίας website',
            DefaultTypePos    : 'Προεπιλεγμένος τύπος παραγγελίας POS'
          },
          Button: {
            Delivery: {
              Title: 'Παράδοση',
              Icon : 'directions_bike'
            },
            Collection: {
              Title: 'Παραλαβή',
              Icon : 'directions_run'
            }
          },
          OrderTotalMin: {
            Title                  : 'Ελάχιστο ποσό παραγγελίας',
            OrderTotalDeliveryMin  : 'Ελάχιστο Ποσό για Παραγγελίες Παράδοσης',
            OrderTotalCollectionMin: 'Ελάχιστο Ποσό για Παραγγελίες Παραλαβής'
          }
        },
        DailyOrders: {
          Title   : 'Εμφάνιση σημερινών παραγγελιών',
          Subtitle: 'Εμφάνιση σημερινών παραγγελιών επιπλέον του ωραρίου καταστήματος',
          From    : 'Ώρα Από',
          To      : 'Ώρα Έως',
          Icon    : 'access_time'
        }
      },
      PrintOption: {
        Title         : 'Επιλογές Εκτύπωσης',
        Icon          : 'print',
        ShowCategories: {
          Title   : 'Εμφάνιση κατηγοριών',
          Subtitle: 'Εμφάνιση κατηγοριών στην εκτύπωση παραγγελίας'
        },
        ShowMap: {
          Title   : 'Εκτύπωση χάρτη',
          Subtitle: 'Εμφάνιση χάρτη με την διεύθυνση του πελάτη στην εκτύπωση παραγγελίας',
          Hint    : 'Για ρύθμιση πλάτους χάρτη πατήστε'
        },
        ShowQRCode: {
          Title   : 'Εκτύπωση QR Code χάρτη',
          Subtitle: 'Εκτύπωση QR Code για προβολή της διεύθυνσης του πελάτη σε χάρτη',
          Hint    : 'Για ρύθμιση πλάτους QR Code χάρτη πατήστε'
        },
        ShowPrintNamesUppercase: {
          Title   : 'Εκτύπωση με κεφαλαία',
          Subtitle: 'Εκτύπωση με κεφαλαία στην εκτύπωση παραγγελίας'
        }
      },
      PosOption: {
        Title             : 'Επιλογές POS',
        Icon              : 'add_shopping_cart',
        VoipAddOrderDialog: {
          Title   : 'Εμφάνιση Διαλόγου Εισαγωγής Παραγγελίας από Τηλεφωνική Κλήση',
          Subtitle: 'Εμφάνιση διαλόγου εισαγωγής παραγγελίας κατά την απάντηση τηλεφωνικής κλήσης στον χρήστη που απάντησε, σε όλους τους χρήστες ή σε κανένα.',
          Item    : {
            Agent   : 'Χρήστης',
            Everyone: 'Όλοι',
            None    : 'Κανένας'
          }
        },
        ShowMenuImages: {
          Title   : 'Εμφάνιση Εικόνων Προϊόντων',
          Subtitle: 'Εμφάνιση των εικόνων προϊόντων στο Pos.'
        },
        ShowSelectedMenuOptionsPos: {
          Title   : 'Προβολή Επιλεγμένων Επιλογών Προϊόντος',
          Subtitle: 'Προβολή επιλεγμένων επιλογών προϊόντος σε κείμενο'
        },
        MenuItemQuickAdd: {
          Title   : 'Προσθήκη Προϊόντων με ένα Click',
          Subtitle: 'Προσθήκη προϊόντων στο Pos με ένα Click.'
        },
        MenuListItemImageLeftPos: {
          Title   : 'Θέση Εικόνας',
          Subtitle: 'Εμφάνιση εικόνας προϊόντος δεξιά ή αριστερά.'
        },
        MenuCategoriesTabsPosDesktop: {
          Title   : 'Εμφάνιση Κατηγοριών Προϊόντων σε Υπολογιστές',
          Subtitle: 'Εμφάνιση κατηγοριών προϊόντων σε οριζόντια ή κάθετη λίστα σε Υπολογιστές.'
        },
        MenuCategoriesTabsPosMobile: {
          Title   : 'Εμφάνιση Κατηγοριών Προϊόντων σε Κινητά',
          Subtitle: 'Εμφάνιση κατηγοριών προϊόντων σε οριζόντια ή κάθετη λίστα σε Κινητά.'
        },
        MenuCategoriesColorWidthPos: {
          Title   : 'Πλάτος Χρώματος Κατηγοριών',
          Subtitle: 'Αλλαγή του πλάτους χρώματος των κατηγοριών',
          Items   : [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
        },
        MenuSearchEnabledPos: {
          Title   : 'Χρήση Αναζήτησης Προϊόντων',
          Subtitle: 'Δυνατότητα αναζήτησης προϊόντων.'
        },
        MenuItemCustomPrice: {
          Title   : 'Χρήση Προσαρμοσμένης Τιμής Προϊόντων',
          Subtitle: 'Δυνατότητα αλλαγής της τιμής προϊόντων στο pos.'
        },
        BarcodeDelayBetweenScans: {
          Title      : 'Χρόνος Ανάμεσα Barcode Scans',
          Subtitle   : 'Χρόνος σε Milliseconds Ανάμεσα Barcode Scans.',
          PlaceHolder: 'Εισαγωγή Χρόνου σε Milliseconds',
          Suffix     : 'ms'
        },
        BarcodePrefixProductUnitQuantity: {
          Title      : 'Prefix για Barcode Βάρους',
          Subtitle   : 'Προσθήκη prefix barcode βάρους ζυγιζόμενων προϊόντων.',
          PlaceHolder: 'Εισαγωγή Prefix Barcode Βάρους'
        },
        BarcodePrefixProductUnitPrice: {
          Title      : 'Prefix για Barcode Τιμής',
          Subtitle   : 'Προσθήκη prefix barcode τιμής ζυγιζόμενων προϊόντων.',
          PlaceHolder: 'Εισαγωγή Prefix Barcode Τιμής'
        },
        MenuOptionsListExpandable: {
          Enabled: {
            Title   : 'Επεκτάσιμη Προβολή Λίστας Επιλογών Προϊόντος',
            Subtitle: 'Δυνατότητα προβολής της λίστας επιλογών προϊόντος με επεκτάσιμη λίστα.'
          },
          Mobile: {
            Title   : 'Επεκτάσιμη Λίστα σε Κινητά',
            Subtitle: 'Χρήση της επεκτάσιμης λίστας σε κινητά',
            Multiple: {
              Title   : 'Επέκταση Πολλαπλών Παράλληλα',
              Subtitle: ''
            }
          },
          Desktop: {
            Title   : 'Επεκτάσιμη Λίστα σε Υπολογιστές',
            Subtitle: 'Χρήση της επεκτάσιμης λίστας σε υπολογιστές',
            Multiple: {
              Title   : 'Επέκταση Πολλαπλών Παράλληλα',
              Subtitle: ''
            }
          }
        },
        SourceTypes: {
          Title   : 'Πηγές Παραγγελίας',
          Subtitle: 'Διαχείριση πηγών παραγγελιών.'
        },
        OrderComments: {
          Title   : 'Σχόλια Παραγγελίας',
          Subtitle: 'Διαχείριση σχολίων παραγγελιών.',
          Table   : {
            Header: {
              Comment: 'Σχόλιο'
            }
          },
          Button: {
            Add: {
              Title: 'Προσθήκη Σχολίου',
              Icon : 'add_box'
            }
          }
        }
      },
      ExternalPlatforms: {
        Title   : 'Εξωτερικές Πλατφόρμες',
        Icon    : 'mdi-open-in-new',
        Platform: {
          DeliveryHero: 'Delivery Hero (E-Food / Foody)',
          Fagi        : 'Fagi',
          GetFood     : 'GetFood'
        },
        AutoCreateCustomer: {
          Title   : 'Αυτόματη Δημιουργία Πελάτη',
          Subtitle: 'Αυτόματη δημιουργία πελάτη εάν δεν βρεθεί.'
        },
        AutoCreateAddress: {
          Title   : 'Αυτόματη Δημιουργία Διεύθυνσης',
          Subtitle: 'Αυτόματη δημιουργία διεύθυνσης πελάτη εάν δεν βρεθεί.'
        },
        AutoPrintOrder: {
          Title   : 'Αυτόματη Εκτύπωση Δελτίου Σύνοψης Παραγγελίας',
          Subtitle: 'Αυτόματη εκτύπωση δελτίου σύνοψης παραγγελίας παραγγελίας όταν αυτές εισάγονται στο σύστημα.'
        },
        AutoPrintPreparationOrder: {
          Title   : 'Αυτόματη Εκτύπωση Δελτίου Προετοιμασίας',
          Subtitle: 'Αυτόματη εκτύπωση δελτίου προετοιμασίας παραγγελίας όταν αυτές εισάγονται στο σύστημα.'
        },
        AutoPrintReceipt: {
          Title   : 'Αυτόματη Εκτύπωση Απόδειξης Λιανικής',
          Subtitle: 'Αυτόματη εκτύπωση απόδειξης λιανικής παραγγελίας όταν αυτές εισάγονται στο σύστημα.'
        },
        AddDelivery: {
          Title   : 'Προσθήκη Κόστους Διανομής στην Παραγγελία',
          Subtitle: 'Προσθήκη κόστους διανομής στις παραγγελίες όταν αυτές εισάγονται στο σύστημα.'
        },
        CatalogImportUseItemPrintedName: {
          Title   : 'Όνομα Προϊόντος Εκτύπωσης ως Όνομα Προϊόντος',
          Subtitle: 'Χρησιμοποιήστε το όνομα προϊόντος εκτύπωσης ως όνομα προϊόντος'
        },
        AddTip: {
          Title   : 'Προσθήκη Tip στην Παραγγελία',
          Subtitle: 'Προσθήκη Tip στις παραγγελίες όταν αυτές εισάγονται στο σύστημα.'
        },
        InitOrderStatus: {
          Title   : 'Αρχική Κατάσταση Παραγγελιών',
          Subtitle: 'Επιλέξτε την αρχική κατάσταση των παραγγελιών όταν αυτές εισάγονται στο σύστημα.'
        },
        OrderUpdateStatusInSource: {
          Title   : 'Αλλαγή Κατάστασης Παραγγελιών',
          Subtitle: 'Δυνατότητα αλλαγής της κατάστασης των παραγγελιών.'
        },
        OrderDeleteInSource: {
          Title   : 'Διαγραφή Παραγγελιών',
          Subtitle: 'Δυνατότητα διαγραφής παραγγελιών.'
        }
      }
    },
    Notification: 'Οι ρυθμίσεις παραγγελιών δεν ενημερώθηκαν. @:Common.Misc.Retry'
  },

  Payment: {
    Toolbar: {
      Title   : 'Πληρωμές',
      Subtitle: '@:Settings.Settings.Toolbar.Title Πληρωμών',
      Icon    : 'payment',
      Color   : 'blue',
      Tab     : {
        Gateway : 'ΠΥΛΕΣ ΠΛΗΡΩΜΩΝ',
        Terminal: 'ΤΕΡΜΑΤΙΚΑ ΚΑΡΤΩΝ'
      }
    },
    ListView: {
      Title      : 'Μέθοδοι πληρωμών',
      Subtitle   : 'Επιλέξτε τις διαθέσιμες μεθόδους πληρωμών για το website και το pos.',
      UsedPos    : 'Χρήση στο Pos',
      UsedWebsite: 'Χρήση στο Website',
      Tips       : 'Αποδοχή Tips',
      Extra      : 'Extra'
    },
    Notification: {
      VivaWallet: 'Δεν ήταν δυνατή η επαλήθευση των διαπιστευτηρίων σας από το Viva Wallet.',
      Update    : 'Οι ρυθμίσεις πληρωμής δεν ενημερώθηκαν. @:Common.Misc.Retry'
    },
    Edit: {
      Toolbar: {
        Title   : 'Επεξεργασία',
        Subtitle: 'Τροποποίηση Στοιχείων Μεθόδου Πληρωμής'
      },
      Field: {
        OrderStatus: {
          Label: 'Αρχική Κατάσταση Online Παραγγελίας',
          Hint : 'Επιλέξτε την αρχική κατάσταση των Online παραγγελιών.',
          Items: [
            {
              Id  : 11,
              Name: 'Ληφθείσα (Χειροκίνητη αποδοχή)'
            },
            {
              Id  : 21,
              Name: 'Αποδεκτή (Αυτόματη αποδοχή)'
            },
            {
              Id  : 15,
              Name: 'Ολοκληρωμένη (Αυτόματη Ολοκλήρωση)'
            }
          ]
        },
        OrderStatusPos: {
          Label: 'Αρχική Κατάσταση POS Παραγγελίας',
          Hint : 'Επιλέξτε την αρχική κατάσταση των POS παραγγελιών.',
          Items: [
            {
              Id  : 11,
              Name: 'Ληφθείσα (Χειροκίνητη αποδοχή)'
            },
            {
              Id  : 21,
              Name: 'Αποδεκτή (Αυτόματη αποδοχή)'
            },
            {
              Id  : 15,
              Name: 'Ολοκληρωμένη (Αυτόματη Ολοκλήρωση)'
            }
          ]
        },
        SiteTitle: {
          Label: 'Τίτλος Site',
          Hint : 'Τίτλος που εμφανίζεται στο Site πχ. Κάρτα, Μετρητά, κλπ.'
        },
        PosTitle: {
          Label: 'Τίτλος POS',
          Hint : 'Τίτλος που εμφανίζεται στο POS πχ. Κάρτα, Μετρητά, κλπ.'
        },
        MinTotal: {
          Label: 'Ελάχιστο @:Common.Misc.Total',
          Hint : 'Το σύνολο της παραγγελίας πρέπει να είναι μεγαλύτερο από την ελάχιστη τιμή για να ενεργοποιηθεί η πληρωμή.'
        },
        Priority: {
          Label: 'Προτεραιότητα',
          Hint : 'Προτεραιότητα που εμφανίζεται πχ. 1, 5, κλπ.'
        },
        Label: {
          Icon          : 'Εικονίδιο',
          PrimaryColor  : 'Κύριο Χρώμα',
          SecondaryColor: 'Δευτερεύων Χρώμα',
          Method        : 'Μέθοδος'
        }
      }
    }
  },

  Permission: {
    Toolbar: {
      Title   : 'Δικαιώματα',
      Subtitle: 'Ρυθμίσεις Δικαιωμάτων',
      Icon    : 'group',
      Color   : 'blue',
      Button  : {
        Add: {
          Title: 'Δημιουργία νέου δικαιώματος',
          Icon : 'add',
          Color: 'light-green'
        },
        Edit: {
          Title: 'Επεξεργασία δικαιώματος',
          Icon : 'edit',
          Color: 'grey'
        },
        Delete: {
          Title: 'Διαγραφή δικαιώματος',
          Icon : 'close',
          Color: 'red'
        }
      }
    },
    Dialog: {
      Delete: {
        Title  : 'Διαγραφή Δικαιώματος;',
        Content: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το δικαίωμα:'
      }
    },
    Header: {
      Permission  : 'Δικαίωμα',
      Access      : 'Πρόσβαση',
      Requirements: 'Απαιτήσεις'
    },
    Edit: {
      Toolbar: {
        Permission: 'Δικαιώματος',
        Icon      : 'group',
        Color     : 'blue',
        Add       : {
          Title   : 'Δημιουργία @:Settings.Permission.Edit.Toolbar.Permission',
          Subtitle: 'Δημιουργία Νέου @:Settings.Permission.Edit.Toolbar.Permission'
        },
        Edit: {
          Title   : 'Επεξεργασία @:Settings.Permission.Edit.Toolbar.Permission',
          Subtitle: 'Επεξεργασία Στοιχείων @:Settings.Permission.Edit.Toolbar.Permission'
        }
      },
      Field: {
        Id: {
          Error: {
            Required: 'Το πεδίο Id απαιτείται.'
          }
        },
        Name: {
          Label: '@:Common.Misc.Name',
          Error: {
            Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες.'
          }
        },
        Description: {
          Label: 'Περιγραφή',
          Error: {
            Required: 'Το πεδίο περιγραφή απαιτείται.'
          }
        },
        Parent: {
          Label: 'Γονικό δικαίωμα'
        },
        AccessType: {
          Label: 'Τύπος Πρόσβασης',
          Error: {
            Required: 'Το πεδίο τύπος πρόσβασης απαιτείται.'
          }
        },
        RequiredPermissions: {
          Label: 'Απαιτούμενα δικαιώματα'
        }
      }
    },
    Notification: {
      Update: 'Το δικαίωμα δεν ενημερώθηκε. @:Common.Misc.Retry',
      Delete: {
        Success: 'Το δικαίωμα διαγράφηκε επιτυχώς!',
        Error  : 'Το δικαίωμα δεν διαγράφηκε. @:Common.Misc.Retry'
      }
    }
  },

  Display: {
    Title  : 'Οθόνες | Οθόνη | Οθόνες',
    Toolbar: {
      Subtitle: '@:Settings.Settings.Toolbar.Title Οθονών',
      Icon    : 'mdi-monitor-screenshot',
      Color   : 'blue',
      Button  : {
        Refresh: 'Ανανέωση Οθονών'
      }
    },
    Header: {
      Id       : '#',
      Name     : 'Όνομα<br>Οθόνης',
      Bounds   : 'Ανάλυση<br>Οθόνης',
      Primary  : 'Κύρια<br>Οθόνη',
      AutoOpen : 'Αυτόματο<br>Άνοιγμα',
      CdsStatus: 'Οθόνη<br>Πελάτη',
      Actions  : ''
    },
    Notification: {
      NoDisplays: 'Δεν Υπάρχουν Διαθέσιμες Οθόνες',
      Success   : 'Οι οθόνες ενημερώθηκαν επιτυχώς.',
      Error     : 'Οι οθόνες δεν ενημερώθηκαν. @:Common.Misc.Retry'
    }
  },

  Printer: {
    Title  : 'Εκτυπωτές | Εκτυπωτής | Εκτυπωτές',
    Toolbar: {
      Subtitle: '@:Settings.Settings.Toolbar.Title Εκτυπωτών',
      Icon    : 'print',
      Color   : 'blue',
      Button  : {
        Refresh: 'Ανανέωση Εκτυπωτών'
      }
    },
    Tab: {
      Groups     : 'ΟΜΑΔΕΣ ΕΚΤΥΠΩΤΩΝ',
      Default    : 'ΔΕΛΤΙΟ ΣΥΝΟΨΗΣ ΠΑΡΑΓΓΕΛΙΑΣ',
      Preparation: 'ΔΕΛΤΙΟ ΠΡΟΕΤΟΙΜΑΣΙΑΣ',
      Receipt    : 'ΦΟΡΟΛΟΓΙΚΑ ΠΑΡΑΣΤΑΤΙΚΑ',
      Total      : 'ΑΝΑΦΟΡΕΣ'
    },
    Header: {
      Copy       : 'Αντίγραφα',
      AutoPrint  : 'Αυτόματη Εκτύπωση',
      WaiterPrint: 'Εκτύπωση<br>Σερβιτόρου',
      Auto       : 'Αυτόματη',
      Group      : 'Ομάδα',
      Print      : 'Εκτύπωση',
      MaxWidth   : 'Max Width',
      FontSize   : 'Font Size',
      Status     : '@:Common.Misc.Status'
    },
    Field: {
      Name: {
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 128 χαρακτήρες'
        }
      },
      Email: {
        Error: {
          Between: 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
          Valid  : 'Το E-mail πρέπει να είναι έγκυρο'
        }
      }
    },
    Notification: {
      NoPrinters: 'Δεν Υπάρχουν Διαθέσιμοι Εκτυπωτές',
      Success   : 'Οι εκτυπωτές ενημερώθηκαν επιτυχώς.',
      Error     : 'Οι εκτυπωτές δεν ενημερώθηκαν. @:Common.Misc.Retry'
    },

    PrinterGroupAddDialog: {
      Toolbar: {
        Add: {
          Title   : 'Προσθήκη Ομάδας Εκτυπωτών',
          Subtitle: 'Δημιουργία Νέας Ομάδας Εκτυπωτών'
        },
        Edit: {
          Title   : 'Επεξεργασία Ομάδας Εκτυπωτών',
          Subtitle: 'Τροποποίηση Στοιχείων Ομάδας Εκτυπωτών'
        },
        Icon : 'print',
        Color: 'primary'
      },
      Field: {
        Name: {
          Label: 'Όνομα',
          Hint : 'Όνομα ομάδας εκτυπωτών. πχ. Κουζίνα, Μπαρ, κλπ.',
          Icon : 'title',
          Error: {
            Between: 'Το όνομα πρέπει να είναι από 2 έως 255 χαρακτήρες'
          }
        },
        Description: {
          Label: 'Περιγραφή',
          Hint : 'Περιγραφή ομάδας εκτυπωτών',
          Icon : 'description'
        }
      },
      Notification: {
        Update : 'Η ομάδα εκτυπωτών δεν αποθηκεύτηκε. @:Common.Misc.Retry',
        Success: 'Η ομάδα εκτυπωτών αποθηκεύτηκε επιτυχώς.'
      }
    }
  },

  Reservation: {
    Toolbar: {
      Title   : 'Κρατήσεις',
      Subtitle: '@:Settings.Settings.Toolbar.Title Κρατήσεων',
      Icon    : 'mdi mdi-book-open-variant',
      Color   : 'blue'
    },
    Section: {
      Duration: {
        Title: 'Διάρκεια & Ώρα',
        Icon : 'mdi mdi-calendar-clock',
        Field: {
          TimeIntervalOnline: {
            Title   : 'Χρόνος μεταξύ διαθέσιμων ωρών',
            Subtitle: 'Ο χρόνος μεταξύ των διαθέσιμων ωρών που εμφανίζεται στους επισκέπτες κατά την online κράτηση. π.χ. εάν το διάστημα είναι 0:30, οι επισκέπτες μπορούν να κάνουν κράτηση στις 10:00, 10:30, 11:00 κλπ.'
          },
          TimeSlotBetween: {
            Title   : 'Χρόνος μεταξύ δύο κρατήσεων',
            Subtitle: 'Κενός χρόνος που μεσολαβεί μεταξύ δύο κρατήσεων'
          },
          AllowedMinutesStep: {
            Title   : 'Βήμα διαθέσιμων λεπτών προς επιλογή',
            Subtitle: 'πχ. Βήμα 15 μας δίνει επιλογές λεπτών από 0, 15, 30, 45 λεπτά.'
          },
          DurationDefault: {
            Title   : 'Προεπιλεγμένη διάρκεια κράτησης',
            Subtitle: 'Η προεπιλεγμένη διάρκεια των online κρατήσεων και η προεπιλεγμένη διάρκεια κρατήσεων που δημιουργήθηκαν από το προσωπικό — συμπεριλαμβανομένου του χρόνου διεκπεραίωσης.'
          },
          MaxDaysInAdvance: {
            Title   : 'Ο μέγιστος χρόνος που μπορεί να πραγματοποιηθεί μια κράτηση πριν από την ημερομηνία άφιξης.',
            Subtitle: 'πχ. εάν οριστεί σε "1 εβδομάδα", μόνο οι επόμενες 7 ημέρες θα είναι διαθέσιμες κατά την πραγματοποίηση μιας κράτησης.'
          },
          MinTimeInAdvance: {
            Title   : 'Ελάχιστη ώρα που μπορεί να πραγματοποιηθεί μια κράτηση πριν από την ώρα άφιξης',
            Subtitle: 'πχ. εάν έχει οριστεί σε 1 ώρα και η ώρα είναι 13:00, η πρώτη διαθέσιμη ώρα που μπορεί να κάνει κράτηση ένας επισκέπτης θα είναι στις 14:00.'
          },
          UseDurationByPeople: {
            Title   : 'Ορίστε τη διάρκεια με βάση τον αριθμό των ατόμων',
            Subtitle: 'Εάν είναι ενεργό, μπορείτε να ορίσετε κανόνες που καθορίζουν τις διάρκειες για online κρατήσεις με βάση τον αριθμό των ατόμων. πχ. Κρατήσεις με 1-3 άτομα να έχουν διάρκεια 2 ώρες και οι κρατήσεις με 4 ή περισσότερα άτομα να έχουν διάρκεια 3 ώρες. Εάν δεν ταιριάζουν κανόνες, θα χρησιμοποιηθεί η προεπιλεγμένη διάρκεια που ορίζεται στις ρυθμίσεις κράτησης.'
          },
          DurationByPeople: {
            MinPeople: 'Ελάχιστα Άτομα',
            MaxPeople: 'Μέγιστα Άτομα',
            Duration : 'Διάρκεια',
            Button   : {
              Add: {
                Title: 'Προσθήκη κανόνα διάρκειας',
                Icon : 'mdi mdi-plus'
              }
            }
          }
        }
      },
      People: {
        Title: 'Άτομα',
        Icon : 'people',
        Field: {
          MinPeopleOnline: {
            Title   : 'Ελάχιστος αριθμός ατόμων σε μια online κράτηση',
            Subtitle: 'Ο ελάχιστος αριθμός ατόμων σε μία μεμονωμένη online κράτηση.'
          },
          MaxPeopleOnline: {
            Title   : 'Μέγιστος αριθμός ατόμων σε μια online κράτηση',
            Subtitle: 'Ο μέγιστος αριθμός ατόμων σε μια μεμονωμένη online κράτηση.'
          },
          PersonsThreshold: {
            Title   : 'Όριο ατόμων για χωρητικότητα τραπεζιού',
            Subtitle: 'πχ. +2 & -2 άτομα κατά την αναζήτηση τραπεζιών για κράτηση'
          }
        }
      },
      Manage: {
        Title: 'Διαχείριση',
        Icon : 'settings',
        Field: {
          AutoUpdateTables: {
            Title   : 'Αυτόματη ενημέρωση τραπεζιών',
            Subtitle: 'Αυτόματη ενημέρωση των επιλεγμένων τραπεζιών κατά την αλλαγή του αριθμού των ατόμων σε μια κράτηση.'
          },
          TableOverwrite: {
            Title   : 'Αυτόματη αλλαγή τραπεζιών',
            Subtitle: 'Αυτόματη αλλαγή των επιλεγμένων τραπεζιών όταν επεξεργάζεστε μια κράτηση και επιλέγετε έναν νέο τραπέζι που ταιριάζει στον αριθμό των ατόμων.'
          },
          OnlineBooking: {
            Title   : 'Υπηρεσία online κρατήσεων',
            Subtitle: 'Ενεργοποιήστε ή απενεργοποιήστε την υπηρεσία online κρατήσεων.'
          }
        }
      }
    }
  },

  Restaurant: {
    Toolbar: {
      Title   : 'Κατάστημα',
      Subtitle: '@:Settings.Settings.Toolbar.Title Καταστήματος',
      Icon    : 'storefront',
      Color   : 'blue'
    },
    Tab: {
      Details: '@:Common.Misc.Details',
      Address: 'Διεύθυνση'
    },
    Field: {
      CompanyDetails: {
        Title: 'Στοιχεία Επιχείρησης',
        Icon : 'far fa-building',
        Name : {
          Label: 'Επωνυμία Επιχείρησης',
          Hint : 'Επωνυμία Επιχείρησης. πχ. Τα Σουβλάκια του Βαγγέλη',
          Icon : 'storefront'
        },
        Description: {
          Label: 'Περιγραφή Επιχείρησης',
          Hint : 'Περιγραφή Επιχείρησης. πχ. Ψήνουμε, μαγειρεύουμε & δημιουργούμε τα καλύτερα για εσάς!',
          Icon : 'comment'
        },
        Email: {
          Label: 'Email',
          Hint : 'Email Επιχείρησης. πχ. info@getfoodpro.gr',
          Icon : 'email'
        },
        Phone: {
          Label: 'Τηλέφωνο',
          Hint : 'Τηλέφωνο Επιχείρησης. πχ. 2106105200',
          Icon : 'phone'
        },
        Logo: {
          Title     : 'Λογότυπο Επιχείρησης (340x150)',
          TitleLight: 'Ανοιχτό Λογότυπο (340x150)',
          TitleDark : 'Σκούρο Λογότυπο (340x150)',
          Icon      : 'far fa-object-group',
          Button    : { Title: 'Καταργηση φωτογραφιας' },
          Hint      : {
            Drag   : 'Κάντε drag & drop μια εικόνα ή',
            Click  : 'κάντε κλικ για να επιλέξετε ένα αρχείο',
            Tap    : 'Πατήστε εδώ για να επιλέξετε μια',
            Gallery: 'φωτογραφία από την γκαλερί σας',
            Aspect : 'Τοπιο/Πορτρετο'
          },
          Control: {
            Change: 'Αλλαγη φωτογραφιας',
            Remove: '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
            Select: 'Επιλεξτε φωτογραφια'
          },
          Success: 'Η φωτογραφία επιλέχθηκε με επιτυχία!',
          Error  : {
            Upload: 'Η συσκευή σας δεν υποστηρίζει τη μεταφόρτωση αρχείων.',
            Size  : 'Το μέγεθος του αρχείου υπερβαίνει το όριο',
            Type  : 'Αυτός ο τύπος αρχείου δεν υποστηρίζεται.'
          }
        }
      },
      CompanyAddress: {
        Title  : 'Διεύθυνση Επιχείρησης',
        Icon   : 'place',
        Address: {
          PlaceHolder: 'Εισάγετε την διεύθυνση σας',
          Hint       : '@:Settings.Restaurant.Field.CompanyAddress.Address.PlaceHolder',
          Icon       : 'location_on'
        },
        CustomAddress: {
          Label : 'Χρήση προσαρμοσμένης διεύθυνσης',
          Region: {
            PlaceHolder: 'Περιοχή',
            Hint       : '@:Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.PlaceHolder',
            Icon       : 'location_on'
          },
          Street: {
            PlaceHolder: 'Οδός',
            Hint       : '@:Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.PlaceHolder',
            Icon       : 'location_on'
          },
          Number  : { Label: 'Αριθμός' },
          Postcode: {
            Label: 'Τ.Κ.',
            Hint : 'πχ. 153 54'
          }
        }
      }
    },
    Notification: 'Το κατάστημα δεν ενημερώθηκε. @:Common.Misc.Retry'
  },

  Website: {
    Toolbar: {
      Title   : 'Website',
      Subtitle: '@:Settings.Settings.Toolbar.Title Website',
      Icon    : 'language',
      Color   : 'blue'
    },
    Tabs: {
      QRCode   : 'QR-Code',
      Pages    : 'Σελίδες',
      Gallery  : 'Gallery',
      Social   : 'Social',
      Analytics: 'Analytics',
      Theme    : 'Theme'
    },
    Field: {
      Settings: {
        Icon     : 'photo_size_select_large',
        Title    : 'Image Settings',
        MinHeight: {
          Label: 'Minimum Height'
        }
      },
      Upload: {
        Icon : 'cloud_upload',
        Title: 'Image Management'
      },
      QRCode: {
        Title: {
          Home   : 'Αρχική Σελίδα',
          Catalog: 'Κατάλογος Προϊόντων'
        },
        Color   : 'Χρώμα',
        Logo    : 'Λογότυπο',
        DotScale: 'Μέγεθος Κουκίδων',
        Button  : {
          DownloadQR: 'Download QR Code'
        }
      },
      Page: {
        Title: 'Σελίδα',
        Home : {
          Title    : 'Αρχική @:Settings.Website.Field.Page.Title',
          Icon     : 'home',
          StoreInfo: {
            Title   : 'Εμφάνιση στοιχείων καταστήματος',
            Subtitle: 'Εμφάνιση ή απόκρυψη των στοιχείων του καταστήματος στην Αρχική Σελίδα.'
          },
          Offer: {
            Title   : 'Εμφάνιση προσφορών',
            Subtitle: 'Ενεργοποίηση ή απενεργοποίηση των προσφορών στην Αρχική Σελίδα.'
          },
          FeaturedProduct: {
            Title   : 'Εμφάνιση προτεινόμενων προϊόντων',
            Subtitle: 'Ενεργοποίηση ή απενεργοποίηση των προτεινόμενων προϊόντων στην Αρχική Σελίδα.'
          },
          FeaturedCategory: {
            Title   : 'Εμφάνιση δημοφιλών κατηγοριών',
            Subtitle: 'Ενεργοποίηση ή απενεργοποίηση των δημοφιλών κατηγοριών στην Αρχική Σελίδα.'
          },
          AllCategories: {
            Title   : 'Εμφάνιση όλων των κατηγοριών',
            Subtitle: 'Ενεργοποίηση ή απενεργοποίηση εμφάνισης όλων των κατηγοριών στην Αρχική Σελίδα.'
          },
          MaxCategoryNum: {
            Title: 'Μέγιστος αριθμός εμφάνισης κατηγοριών',
            Label: 'Όριο Κατηγοριών'
          },
          RandomCategory: {
            Title   : 'Εμφάνιση κατηγοριών με τυχαία σειρά και επιλογή',
            Subtitle: 'Ενεργοποίηση ή απενεργοποίηση εμφάνισης κατηγοριών με τυχαία σειρά και επιλογή στην Αρχική Σελίδα.'
          }
        },
        Catalog: {
          Title    : '@:Settings.Website.Field.Page.Title Καταλόγου',
          Icon     : 'fas fa-th-list',
          StoreInfo: {
            Title   : '@:Settings.Website.Field.Page.Home.StoreInfo.Title',
            Subtitle: 'Εμφάνιση ή απόκρυψη των στοιχείων του καταστήματος στην Σελίδα Καταλόγου.'
          },
          ProductImage: {
            Title   : 'Εμφάνιση εικόνων προϊόντων',
            Subtitle: 'Εμφάνιση των εικόνων των προϊόντων στην Σελίδα Καταλόγου.'
          },
          ProductImageSize: {
            Title   : 'Μέγεθος εικόνων προϊόντων',
            Subtitle: 'Χρήση μεγάλων εικόνων προϊόντων στη σελίδα ή το popup προϊόντος.'
          },
          ShowSelectedMenuOptions: {
            Title   : 'Προβολή Επιλεγμένων Επιλογών Προϊόντος',
            Subtitle: 'Προβολή επιλεγμένων επιλογών προϊόντος σε κείμενο'
          },
          QuickAddMenuItem: {
            Title   : 'Γρήγορη Προσθήκη Προϊόντων με ένα Click',
            Subtitle: 'Γρήγορη προσθήκη προϊόντων στο Pos με ένα Click.'
          },
          MenuListItemImageLeft: {
            Title   : 'Θέση Εικόνας',
            Subtitle: 'Εμφάνιση εικόνας προϊόντος δεξιά ή αριστερά.'
          },
          ProductPage: {
            Title   : 'Σελίδα / Popup προϊόντος',
            Subtitle: 'Χρήση σελίδας προϊόντος αντί για το popup προϊόντος.'
          },
          AllCategories: {
            Title   : 'Εμφάνιση όλων των κατηγοριών',
            Subtitle: {
              ThereAre      : 'Υπάρχουν',
              ActiveProducts: 'ενεργά προϊόντα.'
            },
            ProductLimit: {
              Title: 'Ορίστε μέχρι πιο όριο προϊόντων θα εμφανίζονται όλες οι κατηγορίες προϊόντων',
              Label: 'Όριο Προϊόντων'
            }
          }
        },
        Contact: {
          Title    : '@:Settings.Website.Field.Page.Title Επικοινωνίας',
          Icon     : 'email',
          FormEmail: {
            Title   : 'Email φόρμας επικοινωνίας',
            Subtitle: 'Το email θα χρησιμοποιηθεί για την λήψη των στοιχείων της φόρμας επικοινωνίας.',
            Label   : 'Email επικοινωνίας'
          },
          ShowDeliveryAreas: {
            Title   : 'Εμφάνιση Περιοχών Διανομής',
            Subtitle: 'Προβολή των περιοχών διανομής του καταστήματος.'
          },
          ShowDeliveryAreasCharges: {
            Title   : 'Εμφάνιση Χρεώσεων Περιοχών Διανομής',
            Subtitle: 'Προβολή των χρεώσεων ανά περιοχή διανομής.'
          }
        },
        User: {
          Title: 'Σύνδεση χρηστών',
          Icon : 'lock',
          Guest: {
            Title   : 'Παραγγελίες επισκεπτών (Guest)',
            Subtitle: 'Επιτρέψτε στους επισκέπτες του website να παραγγείλουν χωρίς τη δημιουργία λογαριασμού'
          },
          QrOnlineWithCustomer: {
            Title   : 'QR Παραγγελίες με Στοιχεία Πελάτη',
            Subtitle: 'QR Online Παραγγελίες με Στοιχεία Πελάτη'
          },
          QrOnlineWithCustomerRequired: {
            Title   : 'Στοιχεία Πελάτη Υποχρεωτικά',
            Subtitle: 'QR Online Παραγγελίες με Υποχρεωτικά Στοιχεία Πελάτη'
          },
          Email: {
            Title   : 'Σύνδεση με Email',
            Subtitle: 'Να επιτρέπεται η σύνδεση χρηστών στο site με email.'
          },
          GoogleLogin: {
            Title         : 'Σύνδεση με Google',
            Subtitle      : 'Να επιτρέπεται η σύνδεση χρηστών στο site με Google.',
            GoogleID      : 'Google Login Client ID',
            GoogleSecret  : 'Google Login Client Secret',
            GoogleRedirect: {
              Title: 'Google Redirect URI:',
              Icon : 'far fa-copy'
            }
          },
          FacebookLogin: {
            Title           : 'Σύνδεση με Facebook',
            Subtitle        : 'Να επιτρέπεται η σύνδεση χρηστών στο site με Facebook.',
            FacebookID      : 'Facebook Login App ID',
            FacebookSecret  : 'Facebook Login App Secret',
            FacebookRedirect: {
              Title: 'Facebook Redirect URI:',
              Icon : 'far fa-copy'
            }
          },
          GoogleMaps: {
            Title: 'Google Maps',
            Label: 'Google Maps Api Key'
          },
          GoogleRecaptcha: {
            Title: 'Google Recaptcha',
            Label: {
              Public: 'Google Recaptcha Key',
              Secret: 'Google Recaptcha Secret'
            }
          }
        },
        OtherPage: {
          Title     : 'Άλλες Σελίδες',
          Icon      : 'description',
          CustomPage: {
            Title   : 'Προσωποποίηση περιεχομένου σελίδων',
            Subtitle: 'Ενεργοποίηση αλλαγής περιεχομένου σελίδων ή χρήση υπάρχοντος περιεχομένου.'
          },
          ListView: {
            Edit: {
              Title: 'Επεξεργασία σελίδας',
              Icon : 'far fa-edit'
            },
            View: {
              Title: 'Προβολή σελίδας',
              Icon : 'far fa-eye'
            },
            EditManager: {
              Title: 'Επεξεργασία σελίδας manager',
              Icon : 'fas fa-user-edit'
            }
          }
        }
      },
      Icons: {
        Title      : 'Εικονίδια',
        SubTitle   : 'Επιλέξτε Εικονίδιο',
        Search     : 'Αναζήτηση',
        PlaceHolder: 'Αναζήτηση σε {count} εικονίδια',
        NoIcons    : {
          Title   : 'Δεν βρέθηκαν εικονίδια',
          SubTitle: 'Δοκιμάστε να κάνετε διαφορετική αναζήτηση'
        }
      },
      Gallery: {
        Title   : 'Gallery',
        SubTitle: 'Επιλέξτε Φωτογραφία',
        Tabs    : {
          Category: 'Κατηγορίες',
          Product : 'Προϊόντα',
          Other   : 'Άλλες Φωτογραφίες'
        },
        Settings: {
          Icon     : 'photo_size_select_large',
          Title    : 'Ρυθμίσεις Εικόνων',
          MinHeight: {
            Label: 'Ελάχιστο Ύψος'
          }
        },
        Upload: {
          Icon : 'cloud_upload',
          Title: 'Διαχείριση Εικόνων'
        },
        Image: {
          Button: { Title: 'Καταργηση φωτογραφιας' },
          Hint  : {
            Drag   : 'Κάντε drag & drop ή',
            Click  : 'επιλέξτε μια φωτογραφία',
            Tap    : 'Πατήστε εδώ για να επιλέξετε μια',
            Gallery: 'φωτογραφία από την γκαλερί σας',
            Aspect : 'Τοπιο/Πορτρετο'
          },
          Control: {
            Change : 'Επιλεξτε απο συσκευη',
            Remove : '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
            Select : 'Επιλεξτε απο συσκευη',
            Gallery: 'Επιλεξτε απο Gallery'
          },
          Success: 'Η φωτογραφία επιλέχθηκε με επιτυχία!',
          Error  : {
            Upload: 'Η συσκευή σας δεν υποστηρίζει τη μεταφόρτωση αρχείων.',
            Size  : 'Το μέγεθος του αρχείου υπερβαίνει το όριο των 10MB',
            Type  : 'Αυτός ο τύπος αρχείου δεν υποστηρίζεται.'
          },
          DeleteConfirmDialog: {
            Title       : 'Διαγραφή {count} Φωτογραφιών; | Διαγραφή Φωτογραφίας; | Διαγραφή {count} Φωτογραφιών;',
            Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε τις {count} επιλεγμένες φωτογραφίες; | Είστε σίγουροι ότι θέλετε να διαγράψετε την επιλεγμένη φωτογραφία; | Είστε σίγουροι ότι θέλετε να διαγράψετε τις {count} επιλεγμένες φωτογραφίες;',
            Notification: 'Η διαγραφή είναι οριστική και η διαδικασία είναι μη αναστρέψιμη'
          }
        }
      },
      Social: {
        Title: 'Social Σελίδες',
        Icon : 'share'
      },
      Analytics: {
        Google: {
          Title: 'Google Analytics',
          Icon : 'fab fa-google',
          Gfp  : {
            Label: 'GFP GA Property ID',
            Hint : 'Εισάγετε το GFP GA Property ID',
            Icon : 'analytics'
          },
          Client: {
            Label: 'GA Property ID',
            Hint : 'Εισάγετε το GA Property ID',
            Icon : 'analytics'
          }
        },
        Facebook: {
          Title: 'Facebook Pixel',
          Icon : 'fab fa-facebook',
          AppId: {
            Label: 'Application ID',
            Hint : 'Εισάγετε το Facebook Pixel ID',
            Icon : 'analytics'
          }
        }
      },
      Theme: {
        Title  : 'Επιλογές Χρωμάτων',
        Site   : '@:Settings.Website.Field.Theme.Title Site',
        Control: '@:Settings.Website.Field.Theme.Title Controls',
        Icon   : 'fas fa-palette'
      }
    },
    Notification  : 'Οι ρυθμίσεις website δεν ενημερώθηκαν. @:Common.Misc.Retry',
    PageEditDialog: {
      Toolbar: {
        Title: 'Επεξεργασία Σελίδας',
        Color: 'indigo',
        Icon : 'description'
      },
      Field: {
        Title: {
          Label      : 'Τίτλος',
          PlaceHolder: 'Τίτλος Σελίδας',
          Error      : {
            Mandatory: 'Το πεδίο είναι υποχρεωτικό',
            Between  : 'Ο τίτλος πρέπει να είναι από 2 έως 255 χαρακτήρες'
          }
        },
        Status: {
          Active  : '@:Common.Button.Toggle.ActiveF',
          Inactive: '@:Common.Button.Toggle.InactiveF'

        }
      },
      Notification: {
        CheckFields: 'Ελέγξτε ότι συμπληρώστε όλα τα στοιχεία σωστά!',
        Success    : 'Η σελίδα αποθηκεύτηκε επιτυχώς.',
        TryAgain   : 'Η σελίδα δεν ενημερώθηκε. Παρακαλώ προσπαθήστε ξανά.'
      }

    }
  },

  Regional: {
    Toolbar: {
      Title   : 'Τοπικές Ρυθμίσεις',
      Subtitle: '@:Settings.Settings.Toolbar.Title',
      Icon    : 'translate',
      Color   : 'blue'
    },
    Region: {
      Title   : 'Επιλογή Χώρας & Ζώνη Ώρας',
      Label   : 'Διαθέσιμες Χώρες και Ζώνες Ώρας',
      Subtitle: 'Επιλέξτε την χώρα και την ζώνη ώρας του καταστήματος',
      Icon    : 'translate',
      Country : { Label: 'Χώρες' },
      Zone    : { Label: 'Ζώνες Ώρας' }
    },
    Website: {
      Title    : 'Ρυθμίσεις Γλώσσας Website',
      Icon     : 'translate',
      Available: {
        Title   : 'Διαθέσιμες Γλώσσες Website',
        Subtitle: 'Επιλέξτε τις γλώσσες που θα είναι διαθέσιμες στο website.',
        Label   : 'Διαθέσιμες Γλώσσες'
      },
      Default: {
        Title   : 'Προεπιλεγμένη Γλώσσα Website',
        Subtitle: 'Επιλέξτε την προεπιλεγμένη γλώσσα του website.',
        Label   : 'Προεπιλεγμένη Γλώσσα'
      }
    },
    Pos: {
      Title  : 'Ρυθμίσεις Γλώσσας POS',
      Icon   : 'translate',
      Default: {
        Title   : 'Γλώσσα POS',
        Subtitle: 'Επιλέξτε την γλώσσα του POS.',
        Label   : 'Γλώσσα'
      }
    },
    Tax: {
      Title  : 'Ρυθμίσεις ΦΠΑ',
      Icon   : 'percent',
      Default: {
        Vat: {
          Title   : 'Προεπιλογή ΦΠΑ',
          Subtitle: 'Επιλέξτε προεπιλεγμένη κατηγορία ΦΠΑ.'
        },
        Classification: {
          Title   : 'Προεπιλογή Κωδικού Κατηγορίας και Τύπου Χαρακτηρισμού Εσόδων',
          Subtitle: 'Επιλέξτε προεπιλεγμένο κωδικού κατηγορίας και τύπου χαρακτηρισμού εσόδων.'
        }
      },
      Extension: {
        Title   : 'Επιλογές ΦΠΑ',
        Subtitle: 'Επιλέξτε κατηγορία ΦΠΑ.',
        Coupon  : {
          Title: 'Κουπόνι',
          Label: 'Κατηγορία ΦΠΑ'
        },
        Delivery: {
          Title: 'Χρέωση Διανομής',
          Label: 'Κατηγορία ΦΠΑ'
        },
        TipAmount: {
          Title: 'Tip',
          Label: 'Κατηγορία ΦΠΑ'
        }
      },
      Reset: {
        Title     : 'Επαναφορά Κατηγορίας ΦΠΑ',
        Subtitle  : 'Αλλάξτε κατηγορία ΦΠΑ στις επιλεγμένες κατηγορίες και τα προϊόντα τους.',
        Categories: {
          Label: 'Κατηγορίες Προϊόντων'
        },
        TaxId: {
          Label: 'Κατηγορία ΦΠΑ'
        },
        Tax2Id: {
          Label: 'Κατηγορία ΦΠΑ Καθήμενων'
        }
      }
    },
    Classifications: {
      Reset: {
        Title     : 'Επαναφορά Κωδικού Κατηγορίας και Τύπου Χαρακτηρισμού Εσόδων',
        Subtitle  : 'Αλλάξτε κωδικό κατηγορίας και τύπου χαρακτηρισμού εσόδων στις επιλεγμένες κατηγορίες και τα προϊόντα τους.',
        Categories: {
          Label: 'Κατηγορίες Προϊόντων'
        },
        ClassificationCategoryCode: {
          Label: 'Κωδικός Κατηγορίας Χαρακτηρισμού Εσόδων'
        },
        ClassificationTypeCode: {
          Label: 'Κωδικός Τύπου Χαρακτηρισμού Εσόδων'
        }
      }
    },
    Notification: 'Οι τοπικές ρυθμίσεις δεν ενημερώθηκαν. @:Common.Misc.Retry',
    Warning     : 'Θα πρέπει να κάνετε έξοδο και ξανά είσοδο για να εφαρμοστούν οι αλλαγές της γλώσσας.'
  },

  Documents: {
    Toolbar: {
      Title   : 'Παραστατικά',
      Subtitle: '@:Settings.Settings.Toolbar.Title',
      Icon    : 'receipt_long',
      Color   : 'blue'
    },
    PreparationDocument: {
      Title        : 'Δελτίο Προετοιμασίας',
      Icon         : 'summarize',
      ShowItemPrice: {
        Title   : 'Εμφάνιση τιμών προϊόντων',
        Subtitle: 'Εμφάνιση τιμών προϊόντων στην εκτύπωση παραγγελίας'
      },
      ShowCategories: {
        Title   : 'Εμφάνιση κατηγοριών',
        Subtitle: 'Εμφάνιση κατηγοριών στην εκτύπωση παραγγελίας'
      },
      ShowPrintNamesUppercase: {
        Title   : 'Εκτύπωση με κεφαλαία',
        Subtitle: 'Εκτύπωση με κεφαλαία στην εκτύπωση παραγγελίας'
      },
      ShowProductNumber: {
        Title   : 'Εκτύπωση αριθμού προϊόντος',
        Subtitle: 'Εκτύπωση αριθμού προϊόντος στην εκτύπωση παραγγελίας'
      },
      MaxWidth: {
        Title   : 'Πλάτος Εκτύπωσης',
        Subtitle: 'Πλάτος εκτύπωσης δελτίου προετοιμασίας (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      },
      FontSize: {
        Title   : 'Μέγεθος Γραμματοσειράς',
        Subtitle: 'Μέγεθος γραμματοσειράς στην εκτύπωση δελτίου προετοιμασίας (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      }
    },
    OrderDocument: {
      Title         : 'Δελτίο Σύνοψης Παραγγελίας',
      Icon          : 'receipt',
      ShowCategories: {
        Title   : 'Εμφάνιση κατηγοριών',
        Subtitle: 'Εμφάνιση κατηγοριών στην εκτύπωση παραγγελίας'
      },
      ShowMap: {
        Title   : 'Εκτύπωση χάρτη',
        Subtitle: 'Εκτύπωση χάρτη με την διεύθυνση του πελάτη στην εκτύπωση παραγγελίας',
        Hint    : 'Για εκτύπωση χάρτη με χρήση συντεταγμένων χάρτη (Lat / Lng) πατήστε'
      },
      MapWidth: {
        Title   : 'Πλάτος χάρτη',
        Subtitle: 'Πλάτος χάρτη με την διεύθυνση του πελάτη στην εκτύπωση παραγγελίας'
      },
      ShowQRCode: {
        Title   : 'Εκτύπωση QR Code χάρτη',
        Subtitle: 'Εκτύπωση QR Code για προβολή της διεύθυνσης του πελάτη σε χάρτη',
        Hint    : 'Για εκτύπωση QR Code χάρτη με χρήση συντεταγμένων χάρτη (Lat / Lng) πατήστε'
      },
      QrWidth: {
        Title   : 'Πλάτος QR Code χάρτη',
        Subtitle: 'Πλάτος QR Code για προβολή της διεύθυνσης του πελάτη σε χάρτη'
      },
      ShowBarcode: {
        Title   : 'Εκτύπωση Barcode',
        Subtitle: 'Εκτύπωση Barcode για χρήση με barcode scanner'
      },
      ShowPrintSourceType: {
        Title   : 'Εκτύπωση Πηγής Παραγγελίας',
        Subtitle: 'Εκτύπωση πηγής παραγγελίας στην εκτύπωση δελτίου σύνοψης παραγγελίας'
      },
      ShowPrintOrderDailyNumber: {
        Title   : 'Εκτύπωση ημερήσιου αύξοντα αριθμού παραγγελίας',
        Subtitle: 'Εκτύπωση ημερήσιου αύξοντα αριθμού παραγγελίας στην εκτύπωση δελτίου σύνοψης παραγγελίας'
      },
      ShowPrintNamesUppercase: {
        Title   : 'Εκτύπωση με κεφαλαία',
        Subtitle: 'Εκτύπωση με κεφαλαία στην εκτύπωση δελτίου σύνοψης παραγγελίας'
      },
      ShowProductNumber: {
        Title   : 'Εκτύπωση αριθμού προϊόντος',
        Subtitle: 'Εκτύπωση αριθμού προϊόντος στην εκτύπωση δελτίου σύνοψης παραγγελίας'
      },
      MaxWidth: {
        Title   : 'Πλάτος Εκτύπωσης',
        Subtitle: 'Πλάτος εκτύπωσης δελτίου σύνοψης παραγγελίας (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      },
      FontSize: {
        Title   : 'Μέγεθος Γραμματοσειράς',
        Subtitle: 'Μέγεθος γραμματοσειράς στην εκτύπωση δελτίου σύνοψης παραγγελίας (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      },
      RespectWorkingHours: {
        Title   : 'Έκδοση αναφορών βάση ωραρίου',
        Subtitle: 'Έκδοση αναφορών βάση ωραρίου σημερινών παραγγελιών'
      },
      ShowTaxes: {
        Title   : 'Εκτύπωση ανάλυσης ΦΠΑ',
        Subtitle: 'Εκτύπωση ανάλυσης ΦΠΑ της παραγγελίας'
      }
    },
    OrderReceipt: {
      Title   : 'Φορολογικά Παραστατικά',
      Icon    : 'receipt_long',
      Document: {
        Receipt      : 'Απόδειξη Λιανικής Πώλησης',
        ReceiptCredit: 'Πιστωτική Απόδειξη Λιανικής Πώλησης',
        Invoice      : 'Τιμολόγιο Πώλησης',
        InvoiceCredit: 'Πιστωτικό Τιμολόγιο Πώλησης',
        Fnb          : 'Δελτίο Παραγγελίας',
        FnbCancel    : 'Δελτίο Παραγγελίας Ακύρωσης',
        FnbReturn    : 'Δελτίο Παραγγελίας Επιστροφής',
        Misc         : 'Επιπλέον Ρυθμίσεις'
      },
      AutoGenerate: {
        Title   : 'Αυτόματη έκδοση απόδειξης',
        Subtitle: 'Δυνατότητα αυτόματης έκδοσης απόδειξης'
      },
      FnbAutoGenerate: {
        Title   : 'Αυτόματη έκδοση δελτίου παραγγελίας',
        Subtitle: 'Δυνατότητα αυτόματης έκδοσης δελτίου παραγγελίας'
      },
      Notify: {
        Title   : 'Προεπιλογή για Εκτύπωση ή/και αποστολή με Email',
        Subtitle: 'Δυνατότητα εκτύπωσης απόδειξης ή/και αποστολής με email από προεπιλογή',
        Print   : 'Εκτύπωση',
        Email   : 'Email'
      },
      InvoiceNotify: {
        Title   : 'Προεπιλογή για Εκτύπωση ή/και αποστολή με Email',
        Subtitle: 'Δυνατότητα εκτύπωσης τιμολογίου ή/και αποστολής με email από προεπιλογή',
        Print   : 'Εκτύπωση',
        Email   : 'Email'
      },
      ShowCategories: {
        Title   : 'Εμφάνιση κατηγοριών',
        Subtitle: 'Εμφάνιση κατηγοριών στην εκτύπωση απόδειξης'
      },
      ShowMap: {
        Title   : 'Εκτύπωση χάρτη',
        Subtitle: 'Εμφάνιση χάρτη με την διεύθυνση του πελάτη στην εκτύπωση παραγγελίας',
        Hint    : 'Για εκτύπωση χάρτη με χρήση συντεταγμένων χάρτη (Lat / Lng) πατήστε'
      },
      MapWidth: {
        Title   : 'Πλάτος χάρτη',
        Subtitle: 'Πλάτος χάρτη με την διεύθυνση του πελάτη στην εκτύπωση παραγγελίας'
      },
      ShowQRCode: {
        Title   : 'Εκτύπωση QR Code χάρτη',
        Subtitle: 'Εκτύπωση QR Code για προβολή της διεύθυνσης του πελάτη σε χάρτη',
        Hint    : 'Για QR Code χάρτη πατήστε με χρήση συντεταγμένων χάρτη (Lat / Lng) πατήστε'
      },
      QrWidth: {
        Title   : 'Πλάτος QR Code χάρτη',
        Subtitle: 'Πλάτος QR Code για προβολή της διεύθυνσης του πελάτη σε χάρτη'
      },
      ShowCustomer: {
        Title   : 'Εμφάνιση πελάτη',
        Subtitle: 'Εμφάνιση των στοιχείων του πελάτη στην εκτύπωση απόδειξης'
      },
      ShowOrderType: {
        Title   : 'Εμφάνιση τύπου παραγγελίας',
        Subtitle: 'Εμφάνιση του τύπου παραγγελίας στην εκτύπωση απόδειξης'
      },
      ShowPayment: {
        Title   : 'Εμφάνιση τρόπου πληρωμής',
        Subtitle: 'Εμφάνιση του τρόπου πληρωμής στην εκτύπωση απόδειξης'
      },
      RespectWorkingHours: {
        Title   : 'Έκδοση αποδείξεων βάση ωραρίου',
        Subtitle: 'Έκδοση αποδείξεων βάση ωραρίου σημερινών παραγγελιών'
      },
      Series: {
        Title   : 'Σειρά απόδειξης',
        Subtitle: 'Σειρά απόδειξης, πχ. ΑΛΠ, ΑΛΠ-Α, ΑΛΠ-Β κλπ. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί αποδείξεις.'
      },
      SeriesCredit: {
        Title   : 'Σειρά πιστωτικής απόδειξης',
        Subtitle: 'Σειρά πιστωτικής απόδειξης, πχ. ΠΛΠ, ΠΛΠ-Α, ΠΛΠ-Β κλπ. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί πιστωτικές αποδείξει.'
      },
      TotalCheckInit: {
        Title   : 'Αρχικός αριθμός απόδειξης',
        Subtitle: 'Αρχικοποίηση αριθμού προόδου απόδειξης. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί αποδείξεις.',
        Error   : {
          InvalidNumber: 'Πρέπει να είναι ακέραιος αριθμός'
        }
      },
      TotalCheckInitCredit: {
        Title   : 'Αρχικός αριθμός πιστωτικής απόδειξης',
        Subtitle: 'Αρχικοποίηση αριθμού προόδου πιστωτικής απόδειξης. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί πιστωτικές αποδείξεις.',
        Error   : {
          InvalidNumber: 'Πρέπει να είναι ακέραιος αριθμός'
        }
      },
      InvoiceSeries: {
        Title   : 'Σειρά τιμολογίου',
        Subtitle: 'Σειρά τιμολογίου, πχ. ΤΠ, ΤΠ-Α, ΤΠ-Β κλπ. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί τιμολόγια.'
      },
      InvoiceSeriesCredit: {
        Title   : 'Σειρά πιστωτικού τιμολογίου',
        Subtitle: 'Σειρά πιστωτικού τιμολογίου, πχ. ΠΤΠ, ΠΤΠ-Α, ΠΤΠ-Β κλπ. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί πιστωτικά τιμολόγια.'
      },
      InvoiceTotalCheckInit: {
        Title   : 'Αρχικός αριθμός τιμολογίου',
        Subtitle: 'Αρχικοποίηση αριθμού προόδου τιμολογίου. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί τιμολόγια.',
        Error   : {
          InvalidNumber: 'Πρέπει να είναι ακέραιος αριθμός'
        }
      },
      InvoiceTotalCheckInitCredit: {
        Title   : 'Αρχικός αριθμός πιστωτικού τιμολογίου',
        Subtitle: 'Αρχικοποίηση αριθμού προόδου πιστωτικού τιμολογίου. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί πιστωτικά τιμολόγια.',
        Error   : {
          InvalidNumber: 'Πρέπει να είναι ακέραιος αριθμός'
        }
      },
      FnbSeries: {
        Title   : 'Δελτίο Παραγγελίας',
        Subtitle: 'Σειρά δελτίου παραγγελίας, πχ. ΔΠ, ΔΠ-Α, ΔΠ-Β κλπ. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί δελτία παραγγελίας.'
      },
      FnbSeriesCancel: {
        Title   : 'Δελτίο Παραγγελίας Ακύρωσης',
        Subtitle: 'Σειρά δελτίου παραγγελίας ακύρωσης, πχ. ΔΠΑ, ΔΠΑ-Α, ΔΠΑ-Β κλπ. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί δελτία παραγγελίας ακύρωσης.'
      },
      FnbSeriesReturn: {
        Title   : 'Δελτίο Παραγγελίας Επιστροφής',
        Subtitle: 'Σειρά δελτίου παραγγελίας επιστροφής, πχ. ΔΠΕ, ΔΠΕ-Α, ΔΠΕ-Β κλπ. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί δελτία παραγγελίας επιστροφής.'
      },
      FnbTotalCheckInit: {
        Title   : 'Αρχικός αριθμός δελτίου παραγγελίας',
        Subtitle: 'Αρχικοποίηση αριθμού προόδου δελτίου παραγγελίας. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί δελτία παραγγελίας.',
        Error   : {
          InvalidNumber: 'Πρέπει να είναι ακέραιος αριθμός'
        }
      },
      FnbTotalCheckInitCancel: {
        Title   : 'Αρχικός αριθμός δελτίου παραγγελίας ακύρωσης',
        Subtitle: 'Αρχικοποίηση αριθμού προόδου δελτίου παραγγελίας ακύρωσης. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί δελτία παραγγελίας ακύρωσης.',
        Error   : {
          InvalidNumber: 'Πρέπει να είναι ακέραιος αριθμός'
        }
      },
      FnbTotalCheckInitReturn: {
        Title   : 'Αρχικός αριθμός δελτίου παραγγελίας επιστροφής',
        Subtitle: 'Αρχικοποίηση αριθμού προόδου δελτίου παραγγελίας επιστροφής. Δεν μπορεί να τροποποιηθεί εφόσον έχουν εκδοθεί δελτία παραγγελίας επιστροφής.',
        Error   : {
          InvalidNumber: 'Πρέπει να είναι ακέραιος αριθμός'
        }
      },
      MaxWidth: {
        Title   : 'Πλάτος Εκτύπωσης',
        Subtitle: 'Πλάτος εκτύπωσης απόδειξης (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      },
      FontSize: {
        Title   : 'Μέγεθος Γραμματοσειράς',
        Subtitle: 'Μέγεθος γραμματοσειράς στην εκτύπωση απόδειξης (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      }
    },
    TotalsDocument: {
      Title   : 'Αναφορά Ζ',
      Icon    : 'inventory',
      MaxWidth: {
        Title   : 'Πλάτος Εκτύπωσης',
        Subtitle: 'Πλάτος εκτύπωσης αναφοράς Ζ (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      },
      FontSize: {
        Title   : 'Μέγεθος Γραμματοσειράς',
        Subtitle: 'Μέγεθος γραμματοσειράς στην εκτύπωση αναφοράς Ζ (μόνο για εκτύπωση από browser)',
        Hint    : 'Για ρύθμιση εκτύπωσης απο εφαρμογή Desktop πατήστε'
      }
    },
    DeliveryStaffDocument: {
      Title                            : 'Αναφορά Βάρδιας Διανομέα',
      Icon                             : 'directions_bike',
      PrintDeliveryStaffShiftWithOrders: {
        Title   : 'Εκτύπωση βάρδιας διανομέων',
        Subtitle: 'Εμφάνιση παραγγελιών στην εκτύπωση βάρδιας διανομέων'
      }
    },
    Notification: 'Οι ρυθμίσεις παραστατικών δεν ενημερώθηκαν. @:Common.Misc.Retry'
  },

  WorkingHour: {
    Toolbar: {
      Title   : 'Ωράριο & Χρόνοι',
      Subtitle: 'Ωράριο Λειτουργίας & Χρόνοι Παραγγελίας',
      Icon    : 'timer',
      Color   : 'blue'
    },
    Tab: {
      WorkingHours : 'Ωράριο Λειτουργίας',
      OrderingTimes: 'Διαθέσιμοι Χρόνοι Παραγγελίας'
    },
    Field: {
      WorkingHours: {
        Title               : 'Ρυθμίσεις Ωραρίου Καταστήματος',
        OnlineOrderingStatus: {
          Title   : 'Online Κατάστημα',
          Subtitle: 'Λειτουργία του online καταστήματος βάση ωραρίου (Άνοιγμα / Κλείσιμο)',
          Open    : 'Ανοιχτό',
          Closed  : 'Κλειστό'
        },
        AlwaysOnlineOrdering: {
          Title   : 'Παραγγελίες Εκτός Ωραρίου',
          Subtitle: 'Το online κατάστημα μπορεί να δέχεται παραγγελίες εκτός ωραρίου καταστήματος'
        },
        OpeningType: {
          Title        : 'Ωράριο Καταστήματος',
          AlwaysOpen   : 'Ανοιχτά 24/7',
          FlexibleHours: 'Ευέλικτο ωράριο'
        },
        OpeningTime: {
          Label: 'Ώρα Έναρξης',
          Icon : 'access_time',
          Error: {
            Required     : 'Το πεδίο ώρα έναρξης απαιτείται',
            RequiredClose: 'Συμπληρώστε και το πεδίο ώρα λήξης'
          }
        },
        ClosingTime: {
          Label: 'Ώρα Λήξης',
          Icon : 'access_time',
          Error: {
            Required    : 'Το πεδίο ώρα λήξης απαιτείται',
            RequiredOpen: 'Συμπληρώστε και το πεδίο ώρα έναρξης'
          }
        },
        ExtendedHour: 'Διευρυμένο ωράριο',
        Button      : {
          Store: {
            Title: 'Καταστήματος',
            Icon : 'storefront'
          },
          FlexibleHours: {
            Title: '@:Settings.WorkingHour.Field.WorkingHours.OpeningType.FlexibleHours',
            Icon : 'access_time'
          }
        },
        DeliveryHour: {
          Title              : 'Ωράριο Παράδοσης',
          Icon               : 'directions_bike',
          DeliveryService    : 'Υπηρεσία Παράδοσης',
          DefaultDeliveryHour: 'Προεπιλεγμένος Χρόνος Παράδοσης Παραγγελίας'
        },
        CollectionHour: {
          Title                : 'Ωράριο Παραλαβής',
          Icon                 : 'directions_run',
          CollectionService    : 'Υπηρεσία Παραλαβής',
          DefaultCollectionHour: 'Προεπιλεγμένος Χρόνος Παραλαβής Παραγγελίας'
        },
        ReservationHour: {
          Title             : 'Ωράριο Κρατήσεων',
          Icon              : 'mdi mdi-book-open-variant',
          ReservationService: 'Υπηρεσία Κρατήσεων'
        }
      },
      OrderingTimes: {
        Title         : 'Ρυθμίσεις Διαθέσιμων Χρόνων Παραγγελίας',
        AddTime       : 'Προσθήκη Χρόνου',
        AvailableTimes: 'Διαθέσιμοι Χρόνοι',
        DeliveryTime  : {
          Title              : 'Διαθέσιμοι Χρόνοι Παράδοσης Παραγγελίας',
          Icon               : 'directions_bike',
          CopyFromCollection : 'Αντιγραφή από παραλαβή',
          DefaultDeliveryTime: 'Προεπιλεγμένος Χρόνος Παράδοσης Παραγγελίας'
        },
        CollectionTime: {
          Title                : 'Διαθέσιμοι Χρόνοι Παραλαβής Παραγγελίας',
          Icon                 : 'directions_run',
          CopyFromCollection   : 'Αντιγραφή από παράδοση',
          DefaultCollectionTime: 'Προεπιλεγμένος Χρόνος Παραλαβής Παραγγελίας'
        }
      },
      Error: {
        Required    : 'Το πεδίο απαιτείται',
        RequiredBoth: 'Παρακαλώ συμπληρώστε και τα 2 πεδία'
      }
    },
    Notification: 'Το ωράριο του καταστήματος δεν ενημερώθηκε. @:Common.Misc.Retry'
  },

  PinManager: {
    Toolbar: {
      Title   : '@:Settings.Settings.Header.PinManager.Title',
      Subtitle: '@:Settings.Settings.Toolbar.Title PIN ενεργειών ανά ομάδα χρηστών',
      Icon    : '@:Settings.Settings.Header.PinManager.Icon',
      Color   : 'blue'
    },
    Enabled: {
      Title   : 'PIN Διαχειριστή',
      Subtitle: 'Χρήση PIN Διαχειριστή για ξεκλείδωμα λειτουργιών'
    },
    Pin: {
      Title   : 'Κωδικός PIN',
      Subtitle: 'Κωδικός PIN για ξεκλείδωμα λειτουργιών',
      Error   : 'Ο Κωδικός PIN πρέπει να είναι 4 αριθμοί'
    },
    Permissions: {
      Title: 'Απαιτείται PIN για'
    },
    PermissionGroups: [
      {
        Title      : 'POS',
        Icon       : 'add_shopping_cart',
        Description: '',
        Permissions: {
          PosDiscount: {
            Title   : 'Έκπτωση σε παραγγελία',
            Subtitle: ''
          },
          PosCancel: {
            Title   : 'Ακύρωση παραγγελίας',
            Subtitle: ''
          },
          PosDelete: {
            Title   : 'Διαγραφή παραγγελίας',
            Subtitle: ''
          },
          PosEdit: {
            Title   : 'Επεξεργασία παραγγελίας',
            Subtitle: ''
          },
          PosPayments: {
            Title   : 'Διαχείριση συναλλαγών πληρωμών παραγγελίας',
            Subtitle: ''
          },
          PosSetCustomPrice: {
            Title   : 'Προσαρμοσμένη τιμή προϊόντος σε παραγγελία',
            Subtitle: ''
          },
          PosChangeOrderTimeDuringAccept: {
            Title   : 'Αλλαγή χρόνου διανομής/παραλαβής σε παραγγελία κατά την αποδοχή',
            Subtitle: ''
          },
          PosChangeOrderTimeAfterAccept: {
            Title   : 'Αλλαγή χρόνου διανομής/παραλαβής σε παραγγελία μετά την αποδοχή',
            Subtitle: ''
          }
        }
      },
      {
        Title      : 'Τραπέζια',
        Icon       : 'tab_unselected',
        Description: '',
        Permissions: {
          TableDiscount: {
            Title   : 'Έκπτωση σε παραγγελία',
            Subtitle: ''
          },
          TableCancel: {
            Title   : 'Ακύρωση παραγγελίας',
            Subtitle: ''
          },
          TableSetCustomPrice: {
            Title   : 'Προσαρμοσμένη τιμή προϊόντος σε παραγγελία',
            Subtitle: ''
          }
        }
      },
      {
        Title      : 'Σερβιτόροι',
        Icon       : 'room_service',
        Description: '',
        Permissions: {
          OpenWaiterShift: {
            Title   : 'Άνοιγμα βάρδιας σερβιτόρου',
            Subtitle: ''
          },
          CloseWaiterShift: {
            Title   : 'Κλείσιμο βάρδιας σερβιτόρου',
            Subtitle: ''
          },
          MovePayment: {
            Title   : 'Μεταφορά πληρωμής σε άλλο σερβιτόρο',
            Subtitle: ''
          },
          ManageAllTables: {
            Title   : 'Διαχείριση τραπεζιών άλλων σερβιτόρων',
            Subtitle: ''
          },
          ChangeTableWaiter: {
            Title   : 'Μεταφορά τραπεζιού σε άλλο σερβιτόρο',
            Subtitle: ''
          }
        }
      }
    ]
  },

  Tables: {
    Toolbar: {
      Title   : '@:Settings.Settings.Header.Tables.Title',
      Subtitle: '@:Settings.Settings.Toolbar.Title Τραπεζιών',
      Icon    : '@:Settings.Settings.Header.Tables.Icon',
      Color   : 'blue'
    },
    Tables: {
      Title                      : 'Τραπέζια',
      Icon                       : 'tab_unselected',
      OpenTableWithDefaultPersons: {
        Title   : 'Καταχώρηση ατόμων',
        Subtitle: 'Αυτόματη καταχώρηση ατόμων που κάθονται στο τραπέζι κατά το άνοιγμα τραπεζιού'
      },
      TableListExpandable: {
        Enabled: {
          Title   : 'Επεκτάσιμη Προβολή Λίστας Τραπεζιών',
          Subtitle: 'Δυνατότητα προβολής της λίστας περιοχών και Τραπεζιών με επεκτάσιμη λίστα.'
        },
        Mobile: {
          Title   : 'Επεκτάσιμη Λίστα σε Κινητά',
          Subtitle: 'Χρήση της επεκτάσιμης λίστας σε κινητά',
          Multiple: {
            Title   : 'Επέκταση Πολλαπλών Παράλληλα',
            Subtitle: ''
          }
        },
        Desktop: {
          Title   : 'Επεκτάσιμη Λίστα σε Υπολογιστές',
          Subtitle: 'Χρήση της επεκτάσιμης λίστας σε υπολογιστές',
          Multiple: {
            Title   : 'Επέκταση Πολλαπλών Παράλληλα',
            Subtitle: ''
          }
        }
      }
    },
    Orders: {
      Title                     : 'Παραγγελίες',
      Icon                      : 'shopping_cart',
      MenuOptionsDialogTableSeat: {
        Title   : 'Επιλογή Θέσης Προϊόντος',
        Subtitle: 'Δυνατότητα επιλογής της θέσης που αντιστοιχεί το προϊόν στα άτομα που κάθονται στο τραπέζι. πχ. Μέση (Τραπέζι), 1, 2, 3, κλπ. '
      },
      MenuOptionsDialogTableOrder: {
        Title   : 'Επιλογή Σειράς Σερβιρίσματος Προϊόντος',
        Subtitle: 'Δυνατότητα επιλογής της σειράς που θα σερβιριστεί το προϊόν στο τραπέζι. πχ. 1ο, 2ο, 3ο, κλπ.'
      },
      Permission: {
        Daily: {
          Title   : 'Σημερινές Παραγγελίες',
          Subtitle: 'Πρόσβαση στις σημερινές παραγγελίες'
        },
        Saved: {
          Title   : 'Αποθηκευμένες Παραγγελίες',
          Subtitle: 'Πρόσβαση στις αποθηκευμένες παραγγελίες'
        },
        All: {
          Title   : 'Όλες οι Παραγγελίες',
          Subtitle: 'Πρόσβαση σε όλες τις παραγγελίες'
        },
        ExcludeFromPin: {
          Title   : 'Εξαίρεση απο PIN Διαχειριστή',
          Subtitle: 'Εξαίρεση απο PIN Διαχειριστή για ξεκλείδωμα λειτουργιών'
        }
      },
      VisibleStatuses: {
        Title   : 'Εμφάνιση Παραγγελιών με Κατάσταση',
        Subtitle: 'Εμφάνιση παραγγελιών μόνο με αυτές τις καταστάσεις'
      }
    },
    Waiters: {
      Title             : 'Σερβιτόροι',
      Icon              : 'room_service',
      WaiterCanManagePos: {
        Title   : 'Παραγγελίες POS',
        Subtitle: 'Πρόσβαση και προσθήκη παραγγελιών POS'
      },
      WaiterCanManageAllTables: {
        Title   : 'Διαχείριση τραπεζιών άλλων σερβιτόρων',
        Subtitle: 'Πρόσβαση και διαχείριση τραπεζιών όλων των σερβιτόρων'
      },
      WaiterCanChangeTableWaiter: {
        Title   : 'Μεταφορά τραπεζιού σε άλλο σερβιτόρο',
        Subtitle: 'Δυνατότητα αλλαγής σερβιτόρου τραπεζιού'
      },
      WaiterCanSetCustomPrice: {
        Title   : 'Προσαρμοσμένη τιμή προϊόντος',
        Subtitle: 'Δυνατότητα προσθήκης προϊόντος με προσαρμοσμένη τιμή σε παραγγελία'
      },
      WaiterCanChangeExternalReceipt: {
        Title   : 'Αλλαγή εξωτερικής απόδειξης',
        Subtitle: 'Δυνατότητα αλλαγής εξωτερικής απόδειξης'
      }
    }
  },

  OnlineUsers: {
    Title  : 'Online Χρήστες | Online Χρήστης | Online Χρήστες',
    Toolbar: {
      Subtitle: '@:Settings.Settings.Toolbar.Title Online Χρηστών',
      Icon    : 'mdi-account-voice',
      Color   : 'blue'
    }
  },

  SystemUsers: {
    Title  : 'Χρήστες Συστήματος',
    Toolbar: {
      Subtitle: '@:Settings.Settings.Toolbar.Title Χρηστών Συστήματος',
      Icon    : 'mdi-account-group',
      Color   : 'blue'
    }
  },

  Wizard: {
    Dialog: {
      InstallationGuide     : 'Οδηγός Εγκατάστασης',
      QuickInstallationGuide: 'Οδηγός Γρήγορης Εγκατάστασης',
      WorkingHours          : 'Ωράριο',
      WorkingHoursSettings  : 'Ρυθμίσεις Ωραρίου',
      Success               : 'Επιτυχής Ολοκλήρωση',
      DeliveryArea          : 'Περιοχή Παράδοσης Παραγγελιών',
      DeliverySettings      : 'Ρυθμίσεις Παράδοσης Παραγγελιών'
    },
    Intro: {
      Select : 'Επιλέξτε "',
      ToBegin: '" για να ξεκινήσετε'
    },
    Outro: {
      FaqInstructions     : 'Κέντρο Βοηθείας',
      MoreSettings        : 'Περισσότερες ρυθμίσεις',
      CategoriesManagement: 'Διαχείριση κατηγοριών',
      ProductsManagement  : 'Διαχείριση προϊόντων'
    }
  }

}
