import ReceiptTypeCodeEnum from '@/api/Enums/ReceiptTypeCodeEnum'

export default {
  data () {
    return {}
  },

  props: {
    asc: {
      type   : Boolean,
      default: false
    },
    desc: {
      type   : Boolean,
      default: false
    }
  },

  computed: {
    tableTotals () {
      return this.viewData?.TableTotals || []
    },

    tableTotalsUsed () {
      return this.viewData?.TableTotalsUsed || []
    },

    tableCouponsDiscountTotal () {
      return parseFloat((this.tableCoupons.reduce((value, coupon) => value + coupon.Amount, 0) || 0).toFixed(2))
    },

    tableCouponsUsedDiscountTotal () {
      return parseFloat((this.tableCouponsUsed.reduce((value, coupon) => value + coupon.Amount, 0) || 0).toFixed(2))
    },

    tableCoupons () {
      return this.tableTotals.filter(total => total.Name === 'coupon') || []
    },

    tableCouponsUsed () {
      return this.tableTotalsUsed.filter(total => total.Name === 'coupon') || []
    },

    completedData () {
      const data = JSON.parse(JSON.stringify(this.viewData?.Completed || []))

      if (this.desc) return data.sort((a, b) => parseInt(b.Timestamp) - parseInt(a.Timestamp))

      return data
    }
  },

  methods: {
    cartItemsCanChangeUnitQuantity (cartTimestamp, orderReceipts) {
      const cartOrderReceipts = orderReceipts?.filter(receipt => receipt.InvoiceTypeCodeInternal === ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER && receipt.Timestamp === cartTimestamp) || []
      return cartOrderReceipts.every(receipt => !!receipt.CanceledAt)
    },

    getCartItems (cart, ungrouped = false, singleQuantity = false, filterItemsBy) {
      const items = (cart?.Items || [])
        .filter(item => filterItemsBy !== 'CancelQuantity' ? item.Quantity : true)
        .filter(item => filterItemsBy && item.hasOwnProperty(filterItemsBy) ? item[filterItemsBy] : true)

      if (ungrouped) return items

      const itemsByTableSeat = this.groupCartItemsByTableSeat(items)
      itemsByTableSeat.forEach(tableSeat => {
        tableSeat.Items = this.groupCartItemsByCategory(tableSeat.Items, singleQuantity)
      })

      return itemsByTableSeat
    },

    getMenuSelectedOptionsString (menu) {
      let menuArr = menu.Options?.filter(element => element.Selected && element.DisplayType !== 'unit' && element.DisplayType !== 'extra_charges')?.map(element => this.getTranslatedField(element, 'Name'))
      if (menu.Comment) menuArr.push(menu.Comment)
      const menuString = menuArr.join(', ')
      menuArr = null
      return menuString
    },

    groupCartItemsByTableSeat (cartItems) {
      const items = cartItems?.reduce(function (groups, item) {
        const val = item?.Custom?.Seat || 0
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {}) || []

      let prop
      const arr = []
      for (prop in items) {
        if (items.hasOwnProperty(prop)) {
          arr.push({
            Seat : parseInt(prop),
            Name : parseInt(prop) ? `${ this.$tc('Restaurant.Tables.Seat.Title', 1) } ${ prop }` : this.$tc('Restaurant.Tables.Title', 1),
            Items: items[prop]
          })
        }
      }
      arr.sort((a, b) => { return a.Seat - b.Seat })

      return arr
    },

    groupCartItemsByCategory (cartItems, singleQuantity = false) {
      const items = cartItems?.reduce(function (groups, item) {
        const val = item.CategoryId
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {}) || []

      let prop
      const arr = []
      for (prop in items) {
        if (items.hasOwnProperty(prop)) {
          arr.push({
            CategoryId      : parseInt(prop),
            CategoryPriority: parseInt(items[prop][0].CategoryPriority),
            CategoryName    : items[prop][0].CategoryName,
            Items           : items[prop]
          })
        }
      }
      arr.sort((a, b) => { return a.CategoryPriority - b.CategoryPriority })

      if (singleQuantity) {
        arr.forEach(category => {
          const categoryItems = []
          category.Items.forEach(item => {
            if (item.UnitId > 1) item.PaymentQuantity = 1

            if (this.menuItemHasCoupon(item) || item.PaymentQuantity === 1) {
              const itemObj = JSON.parse(JSON.stringify(item))
              itemObj.Quantity = item.PaymentQuantity
              // itemObj.Quantity = item.CancelQuantity ? itemObj.Quantity : item.PaymentQuantity
              itemObj.HashId = itemObj.RowId
              categoryItems.push(itemObj)
            } else {
              for (let i = 0; i < item.PaymentQuantity; i++) {
                const itemObj = JSON.parse(JSON.stringify(item))
                itemObj.Quantity = itemObj.PaymentQuantity = 1
                // itemObj.Quantity = item.CancelQuantity ? itemObj.Quantity : 1
                // itemObj.PaymentQuantity = item.CancelQuantity ? itemObj.Quantity : 1
                itemObj.HashId = itemObj.RowId + '-' + i
                categoryItems.push(itemObj)
              }
            }
          })
          category.Items = categoryItems
        })
      }

      return arr
    },

    menuItemPriceTotal (menuItem, calculateCoupons = false) {
      const couponAmount = calculateCoupons ? (this.getMenuItemCoupon(menuItem)?.Amount || 0) : 0
      let quantity = menuItem.Quantity
      if (this.unpaid || (this.unpaid && this.selectable)) quantity = menuItem.PaymentQuantity
      if (this.canceled || (this.canceled && this.selectable)) quantity = menuItem.CancelQuantity
      if (menuItem.UnitId > 1) quantity = 1

      const menuItemPrice = (parseFloat(menuItem.Price) * quantity) - couponAmount
      return menuItemPrice
    },

    cartHasItemsWithCoupon (cart) {
      const items = this.getOrderMenuItems(cart)
      return items?.some(item => !!item?.Coupon) ?? false
    },

    cartTotals (cart) {
      return cart?.Totals || []
    },

    cartCoupons (cart) {
      const totals = this.cartTotals(cart) || []
      return totals.filter(total => total.Name === 'coupon') || []
    },

    cartHasCoupons (cart) {
      return !!this.cartCoupons(cart)?.length
    },

    cartOrderPriceTotal (cart, calculateCoupons = false) {
      const items = this.getOrderMenuItems(cart)
      const priceTotal = items?.reduce((value, menuItem) => value + this.menuItemPriceTotal(menuItem, calculateCoupons), 0) || 0
      let totalDiscount = 0

      if (calculateCoupons) {
        const totals = this.cartCoupons(cart)
        totalDiscount = calculateCoupons ? totals.reduce((value, coupon) => value + coupon.Amount, 0) || 0 : 0
      }

      return priceTotal - totalDiscount
    },

    canceledPriceTotal () {
      let total = 0
      this.completedData.forEach(cart => {
        total = total + this.cartOrderPriceTotal(cart, true)
      })

      return parseFloat(total.toFixed(2))
    },

    cartsPriceTotal (calculateTableCoupons = false) {
      let total = 0
      this.completedData.forEach(cart => {
        total = total + this.cartOrderPriceTotal(cart, true)
      })

      total -= calculateTableCoupons ? this.tableCouponsDiscountTotal : 0

      return parseFloat(total.toFixed(2))
    },

    menuItemHasCoupon (item) {
      return !!this.getMenuItemCoupon(item)
    },

    getMenuItemCoupon (item) {
      return item?.Coupon || null
    }

  }
}
