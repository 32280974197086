<template>
  <v-card>
    <v-toolbar
      class="settings-window-toolbar"
      extension-height="40"
      flat
      height="80"
    >
      <v-avatar
        :color="$t('Settings.Payment.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Payment.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>
      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Payment.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Payment.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <template #extension>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          height="40"
          slider-color="blue"
        >
          <v-tab key="gateway">
            {{ $t('Settings.Payment.Toolbar.Tab.Gateway').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            v-if="appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD"
            key="terminal"
          >
            {{ $t('Settings.Payment.Toolbar.Tab.Terminal').toLocaleUpperCase($i18n.locale) }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-tabs-items v-model="tab">
          <v-tab-item key="gateway">
            <v-container
              class="pa-0"
              fluid
            >
              <v-row
                align="center"
                dense
              >
                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Payment.ListView.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Payment.ListView.Subtitle') }}</span>
                </v-col>

                <v-col cols="12">
                  <v-list three-line>
                    <template v-for="(paymentMethod, i) in viewData.Payments">
                      <template v-if="userIsSuperExtraAdmin || paymentMethod.Status">
                        <v-list-item
                          :key="'list_tile_' + i"
                          :disabled="!userCanManage || paymentMethod.Marketplace || paymentMethod.Code === PaymentTypeEnum.VIVA_MARKETPLACE"
                        >
                          <div
                            v-if="userIsSuperAdmin"
                            :class="[paymentMethod.Status ? 'light-green' : 'grey lighten-1']"
                            class="ma-0 pa-0"
                            style="width: 5px; height: 100%; position: absolute; left: 0"
                          />

                          <v-list-item-avatar
                            :color="paymentMethod.Color1"
                            class="mr-3"
                          >
                            <v-icon dark>
                              {{ paymentMethod.Icon }}
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{ paymentMethod.ModuleTitle }}</v-list-item-title>

                            <v-list-item-subtitle>{{ getTranslatedField(paymentMethod, 'Name', 'Lang', true, 'Title') }}</v-list-item-subtitle>

                            <v-list-item-subtitle>
                              <template v-if="paymentMethod.Pos">
                                <span class="pr-1 text-caption">
                                  <v-icon
                                    color="orange"
                                    size="16"
                                  >
                                    far fa-check-circle
                                  </v-icon>
                                  {{ $t('Settings.Payment.ListView.UsedPos') }}
                                </span>
                              </template>
                              <template v-if="paymentMethod.Site">
                                <span class="pr-1 text-caption">
                                  <v-icon
                                    color="blue"
                                    size="16"
                                  >
                                    far fa-check-circle
                                  </v-icon>
                                  {{ $t('Settings.Payment.ListView.UsedWebsite') }}
                                </span>
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action v-if="userCanManage && !paymentMethod.Marketplace && paymentMethod.Code !== PaymentTypeEnum.VIVA_MARKETPLACE">
                            <v-btn
                              color="grey darken-2"
                              icon
                              text
                              @click.prevent="onEditPaymentMethodClick(paymentMethod)"
                            >
                              <v-icon>edit</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider :key="'list_tile_divider' + i" />
                      </template>
                    </template>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item
            v-if="appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD"
            key="terminal"
          >
            <v-expansion-panels
              v-model="expansionPanel"
              flat
              focusable
            >
              <!-- Vendors -->
              <template v-for="vendor in viewData.Vendors">
                <v-expansion-panel :key="`vendor-${vendor.Id}`">
                  <v-expansion-panel-header>
                    <div class="d-flex justify-start align-end">
                      <v-icon class="pr-3">
                        mdi-credit-card-wireless
                      </v-icon>

                      <h4 class="text-body-2 font-weight-bold">
                        {{ vendor.Name }}
                      </h4>
                    </div>
                  </v-expansion-panel-header>

                  <v-divider />

                  <v-expansion-panel-content>
                    <v-toolbar flat>
                      <v-spacer />

                      <v-btn
                        color="primary"
                        depressed
                        @click="onAddVendorTerminal(vendor.Id)"
                      >
                        <v-icon left>
                          mdi-plus
                        </v-icon>
                        {{ $t('Common.Button.Add') }}
                      </v-btn>
                    </v-toolbar>

                    <v-divider />

                    <v-container
                      class="pa-0 py-2"
                      fluid
                    >
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col cols="12">
                          <div class="divTable">
                            <div class="divTableHeading">
                              <div class="divTableRow">
                                <div class="divTableHead text-left">
                                  Name
                                </div>

                                <div class="divTableHead text-left">
                                  Type
                                </div>

                                <div class="divTableHead text-left">
                                  Endpoint
                                </div>

                                <div class="divTableHead text-left">
                                  Model
                                </div>

                                <div class="divTableHead text-left">
                                  TerminalId
                                </div>

                                <div class="divTableHead text-left">
                                  VirtualTerminalId
                                </div>

                                <div class="divTableHead text-left">
                                  SerialNumber
                                </div>

                                <div
                                  class="divTableHead text-right"
                                  style="max-width: 20px; width: 20px;"
                                />
                              </div>
                            </div>

                            <div
                              v-for="terminal in vendor.Terminals"
                              :key="`vendor-${vendor.Id}terminal-${terminal.Id}`"
                              class="divTableRow"
                            >
                              <div class="divTableCell text-left">
                                {{ terminal.Nickname }}
                              </div>

                              <div class="divTableCell text-left">
                                {{ terminal.Type }}
                              </div>

                              <div class="divTableCell text-left">
                                <template v-if="terminal.Protocol && terminal.IpAddress && terminal.Port">
                                  {{ terminal.Protocol }}://{{ terminal.IpAddress }}:{{ terminal.Port }}
                                </template>
                              </div>

                              <div class="divTableCell text-left">
                                {{ terminal.Manufacturer }} {{ terminal.Model }}
                              </div>

                              <div class="divTableCell text-left">
                                {{ terminal.TerminalId }}
                              </div>

                              <div class="divTableCell text-left">
                                {{ terminal.VirtualTerminalId }}
                              </div>

                              <div class="divTableCell text-left">
                                {{ terminal.SerialNumber }}
                              </div>

                              <div class="divTableCell text-right">
                                <v-speed-dial
                                  v-model="terminal.fab"
                                  direction="left"
                                  transition="slide-x-reverse-transition"
                                >
                                  <template #activator>
                                    <v-btn
                                      icon
                                      small
                                    >
                                      <v-icon>mdi mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-tooltip
                                    color="grey"
                                    top
                                  >
                                    <template #activator="{on}">
                                      <v-btn
                                        class="grey ma-0 ml-2"
                                        dark
                                        icon
                                        small
                                        @click="onEditVendorTerminal(terminal)"
                                        v-on="on"
                                      >
                                        <v-icon small>
                                          mdi-pencil
                                        </v-icon>
                                      </v-btn>
                                    </template>

                                    <span>{{ $t('Common.Button.Edit') }}</span>
                                  </v-tooltip>

                                  <v-tooltip
                                    color="red"
                                    top
                                  >
                                    <template #activator="{on}">
                                      <v-btn
                                        class="red ma-0 ml-2"
                                        dark
                                        icon
                                        small
                                        @click="onDeleteVendorTerminal(terminal)"
                                        v-on="on"
                                      >
                                        <v-icon small>
                                          mdi-close
                                        </v-icon>
                                      </v-btn>
                                    </template>

                                    <span>{{ $t('Common.Button.Delete') }}</span>
                                  </v-tooltip>
                                </v-speed-dial>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>

    <terminal-edit-dialog
      :payment-terminal="selectedPaymentTerminal"
      :visible.sync="terminalEditDialogVisible"
      @payment-terminal-save="onPaymentTerminalSave"
    />

    <payment-edit-dialog
      v-if="userCanManage"
      :payment-method="selectedPaymentMethod"
      :visible.sync="editDialogVisible"
      @payment-method-save="onPaymentMethodChanged"
    />
  </v-card>
</template>

<script>
import PaymentEditDialog       from './payments/PaymentEditDialog'
import AppData                 from '@/mixins/appdata'
import Auth                    from '@/mixins/auth'
import Translatable            from '@/mixins/translatable'
import PaymentTerminalModel    from '@/api/Models/settings/PaymentTerminalModel'
import TerminalEditDialog      from '@/components/settings/payments/TerminalEditDialog.vue'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import PaymentTypeEnum         from '@/api/Enums/PaymentTypeEnum'

export default {
  components: {
    TerminalEditDialog,
    PaymentEditDialog
  },
  mixins: [AppData, Auth, Translatable],
  data () {
    return {
      tab                      : null,
      expansionPanel           : null,
      editDialogVisible        : false,
      terminalEditDialogVisible: false,
      selectedPaymentMethod    : null,
      selectedPaymentTerminal  : null,
      viewData                 : null
    }
  },
  computed: {
    PaymentTypeEnum () {
      return PaymentTypeEnum
    },
    ReceiptProviderTypeEnum () {
      return ReceiptProviderTypeEnum
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Payments.Get, this.onGetPaymentsResult)
    this.$bus.$on(window.SocketCommand.Settings.Payments.Save, this.onSavePaymentResult)
    this.$bus.$on(window.SocketCommand.Settings.Payments.Terminal.Save, this.onSavePaymentTerminalResult)
    this.$bus.$on(window.SocketCommand.Settings.Payments.Terminal.Delete, this.onDeleteVendorTerminalResult)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Payments.Get, this.onGetPaymentsResult)
    this.$bus.$off(window.SocketCommand.Settings.Payments.Save, this.onSavePaymentResult)
    this.$bus.$off(window.SocketCommand.Settings.Payments.Terminal.Save, this.onSavePaymentTerminalResult)
    this.$bus.$off(window.SocketCommand.Settings.Payments.Terminal.Delete, this.onDeleteVendorTerminalResult)
    // console.log('4. destroyed');
  },
  methods: {
    onAddVendorTerminal (vendorId) {
      this.selectedPaymentTerminal = new PaymentTerminalModel({ PosVendorId: vendorId })
      this.terminalEditDialogVisible = true
    },
    onEditVendorTerminal (terminal) {
      this.selectedPaymentTerminal = new PaymentTerminalModel(terminal)
      this.terminalEditDialogVisible = true
    },

    onDeleteVendorTerminal (data) {
      window.callAS(window.SocketCommand.Settings.Payments.Terminal.Delete, { Id: data?.Id })
    },
    onDeleteVendorTerminalResult (result) {
      if (result.status === 'success') this.getData()
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.Payments.Get, {})
    },
    onGetPaymentsResult (result) {
      if (!result) return

      const payments = result?.Payments || []

      payments.forEach(payment => {
        payment.OrderStatus = parseInt(payment.OrderStatus)
        if (payment.OrderStatus === 0) payment.OrderStatus = 11
      })

      result.Payments = payments

      this.viewData = result
    },

    onEditPaymentMethodClick (paymentMethod) {
      this.selectedPaymentMethod = JSON.parse(JSON.stringify(paymentMethod))
      this.editDialogVisible = true
    },

    onPaymentTerminalSave (data) {
      window.callAS(window.SocketCommand.Settings.Payments.Terminal.Save, data || {})
    },
    onSavePaymentTerminalResult (result) {
      if (result.status === 'success') {
        this.terminalEditDialogVisible = false
        this.getData()
      }
    },

    onPaymentMethodChanged (data) {
      window.callAS(window.SocketCommand.Settings.Payments.Save, { Payment: data })
    },
    onSavePaymentResult (result) {
      if (result.status === 'success') {
        this.editDialogVisible = false
        this.getData()
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        if (result.hasOwnProperty('gateway') && result.gateway === 'viva_card_3ds') {
          this.$bus.$emit('app-show-notification', {
            body   : this.$t('Settings.Payment.Notification.VivaWallet'),
            type   : 'error',
            icon   : 'warning',
            timeout: 3000
          })
          return
        }

        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Settings.Payment.Notification.Update'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    }
  }
}
</script>

<style scoped>

.divTableCell, .divTableHead {
  padding : 3px 4px;
}
</style>
