<template>
  <v-dialog
    v-model="isVisible"
    max-width="350"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        height="80"
        max-height="80"
      >
        <v-avatar color="primary">
          <v-icon dark>
            tag
          </v-icon>
        </v-avatar>

        <v-toolbar-title class="pl-3">
          <div class="body-3">
            {{ $t('Common.NumPadDialog.Title') }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          @click="onCancelButtonClick"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider />

      <v-card-text class="pa-3">
        <v-container
          class="pa-0"
          fluid
        >
          <v-row dense>
            <v-col cols="12">
              <num-pad
                v-model="val"
                :initial-value="val"
                :currency="currency"
                :number="number"
                :unit="unit"
                :suffix="suffix"
                :use-decimals="useDecimals"
                :max-num-length="maxNumLength"
                :max-num-value="maxNumValue"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          class="light-blue--text text--darken-1"
          text
          outlined
          @click="onCancelButtonClick"
        >
          {{ $t('Common.Button.Cancel') }}
        </v-btn>

        <v-btn
          ref="savebtn"
          :disabled="!dataFormValid"
          class="green white--text elevation-0"
          @click="onSaveButtonClick"
        >
          {{ $t('Common.Button.Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumPad from '@/components/common/NumPad.vue'

export default {
  name      : 'NumPadDialog',
  components: { NumPad },
  mixins    : [],
  props     : {
    initialValue: {
      type   : Number,
      default: 0
    },
    useDecimals: {
      type   : Boolean,
      default: true
    },
    minNumValue: {
      type   : Number,
      default: undefined
    },
    maxNumValue: {
      type   : Number,
      default: undefined
    },
    maxNumLength: {
      type   : Number,
      default: 8
    },
    currency: {
      type   : Boolean,
      default: false
    },
    number: {
      type   : Boolean,
      default: false
    },
    unit: {
      type   : String,
      default: undefined
    },
    suffix: {
      type   : String,
      default: ''
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      val: 0
    }
  },
  computed: {
    dataFormValid () {
      return !isNaN(this.val) && parseFloat(this.val) > 0
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.val = this.initialValue || 0
        window && window.addEventListener('keyup', this.onKeyUp)
      } else {
        window && window.removeEventListener('keyup', this.onKeyUp)
      }
    }
  },
  methods: {
    onSaveButtonClick () {
      if (!this.dataFormValid) return

      if (this.minNumValue !== undefined && this.minNumValue !== null) {
        const newVal = parseFloat(this.val)
        const minVal = parseFloat(this.minNumValue.toFixed(2))
        if (this.minNumValue >= 0 && newVal < minVal) {
          this.val = minVal
          return
        }
      }

      this.onCancelButtonClick()
      this.$emit('save', parseFloat(parseFloat(this.val).toFixed(2)))
    },

    onCancelButtonClick () {
      this.isVisible = false
      window && window.removeEventListener('keyup', this.onKeyUp)
    },

    onKeyUp (keyCode) {
      switch (keyCode.key) {
        case 'Enter':
          this.onSaveButtonClick()
          break
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-text-field__slot {
  min-height  : 120px;
  display     : flex !important;
  align-items : center !important;
}

/deep/ .v-text-field input {
  font-size  : 70px;
  min-height : 70px;
  text-align : center;
}

/deep/ .v-text-field .v-label {
  font-size   : 30px;
  min-height  : 30px;
  line-height : 30px;
}

.v-text-field.error--text >>> .v-input__slot::after {
  border-color : #ff9800 !important;
}

.v-text-field.error--text >>> .v-input__slot::before {
  border-color : #ff9800 !important;
}

.v-text-field.error--text >>> .error--text {
  color : #ff9800 !important;
}

.v-text-field.error--text >>> input {
  caret-color : #ff9800 !important;
}
</style>
