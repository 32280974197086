<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.MealTimeAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.MealTimeAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!categoryToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.MealTimeAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.MealTimeAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.MealTimeAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.MealTimeAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="categoryForm"
            v-model="categoryFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="selectedLanguages.length > 1">
                    <v-tabs
                      v-model="languageTab"
                      :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                      grow
                      height="40"
                      slider-color="blue"
                    >
                      <template v-for="lang in selectedLanguages">
                        <v-tab
                          :key="lang.Code"
                          :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                        >
                          <v-avatar
                            v-if="lang.ImageUrl"
                            class="mr-2"
                            size="20"
                          >
                            <v-img :src="lang.ImageUrl" />
                          </v-avatar>
                          {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                        </v-tab>
                      </template>
                    </v-tabs>
                  </template>
                  <v-tabs-items v-model="languageTab">
                    <template v-for="lang in selectedLanguages">
                      <v-tab-item
                        :key="lang.Code"
                        eager
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editCategory.Lang[lang.Code].Name"
                              :counter="32"
                              :maxlength="32"
                              :hint="$t('Restaurant.MealTimeAddDialog.Field.Name.Hint')"
                              :label="$t('Restaurant.MealTimeAddDialog.Field.Name.Label')"
                              :prepend-icon="$t('Restaurant.MealTimeAddDialog.Field.Name.Icon')"
                              :rules="validationRules.categoryForm.Name"
                              class="pr-2"
                              required
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editCategory"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Name"
                                />
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </template>
                  </v-tabs-items>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-menu
                    ref="menuStartTime"
                    v-model="menuStartTimeVisible"
                    :close-on-content-click="false"
                    :return-value.sync="editCategory.StartTime"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="editCategory.StartTime"
                        :label="$t('Restaurant.MealTimeAddDialog.Field.StartTime.Label')"
                        :prepend-icon="$t('Restaurant.MealTimeAddDialog.Field.StartTime.Icon')"
                        :rules="validationRules.categoryForm.StartTime"
                        clearable
                        readonly
                        required
                        v-on="on"
                      />
                    </template>

                    <v-time-picker
                      v-if="menuStartTimeVisible"
                      v-model="editCategory.StartTime"
                      :allowed-hours="allowedStartHours"
                      :allowed-minutes="allowedStartMinutes"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      first-day-of-week="1"
                      format="24hr"
                      scrollable
                      @change="$refs.menuStartTime.save(editCategory.StartTime)"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-menu
                    ref="menuEndTime"
                    v-model="menuEndTimeVisible"
                    :close-on-content-click="false"
                    :disabled="!editCategory.StartTime"
                    :return-value.sync="editCategory.EndTime"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="editCategory.EndTime"
                        :disabled="!editCategory.StartTime"
                        :label="$t('Restaurant.MealTimeAddDialog.Field.EndTime.Label')"
                        :prepend-icon="$t('Restaurant.MealTimeAddDialog.Field.EndTime.Icon')"
                        :rules="validationRules.categoryForm.EndTime"
                        clearable
                        readonly
                        required
                        v-on="on"
                      />
                    </template>

                    <v-time-picker
                      v-if="menuEndTimeVisible"
                      v-model="editCategory.EndTime"
                      :allowed-hours="allowedEndHours"
                      :allowed-minutes="allowedEndMinutes"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      first-day-of-week="1"
                      format="24hr"
                      scrollable
                      @change="$refs.menuEndTime.save(editCategory.EndTime)"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-switch
                    :id="editCategory.Id + '_status'"
                    v-model="editCategory.Status"
                    inset
                    :label="editCategory.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="mt-6"
                    color="success"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <translate-all-fields-button
            v-if="selectedLanguages.length > 1"
            :disabled="loading"
            :item="editCategory"
            :locale-from="defaultLanguage.Code"
            :field="['Name']"
          />

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import AppData                  from '../../../mixins/appdata'
import Auth                     from '@/mixins/auth'
import TranslateAllFieldsButton from '@/components/common/translation/translateAllFieldsButton.vue'
import TranslateFieldButton     from '@/components/common/translation/translateFieldButton.vue'

export default {
  components: {
    TranslateFieldButton,
    TranslateAllFieldsButton
  },
  mixins: [AppData, Auth],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    categoryToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      languageTab         : 0,
      loading             : false,
      menuStartTimeVisible: false,
      menuEndTimeVisible  : false,
      categoryFormValid   : false,
      newCategory         : null,
      tmpCategory         : {
        Id       : null,
        Name     : '',
        StartTime: null,
        EndTime  : null,
        Status   : true,
        Lang     : {}
      },
      validationRules: {
        categoryForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Restaurant.MealTimeAddDialog.Field.Name.Error.Between')
          ],
          StartTime: [
            (v) => v && v.length === 5 || this.$t('Restaurant.MealTimeAddDialog.Field.StartTime.Error.Required')
          ],
          EndTime: [
            (v) => v && v.length === 5 || this.$t('Restaurant.MealTimeAddDialog.Field.EndTime.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editCategory: {
      get () {
        if (this.categoryToEdit) {
          this.newCategory = JSON.parse(JSON.stringify(this.categoryToEdit))
        } else {
          this.newCategory = JSON.parse(JSON.stringify(this.tmpCategory))
        }

        const langObj = {}
        for (const lang of this.selectedLanguages) {
          langObj[lang.Code] = {
            Name  : '',
            Locale: lang.Code
          }
        }
        this.newCategory.Lang = Object.assign({}, langObj, this.newCategory.Lang)

        return this.newCategory
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.languageTab = 0
        this.$nextTick(() => {
          if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.MealTimes.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.MealTimes.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.categoryForm.validate()

      if (this.categoryFormValid) {
        this.loading = true
        const tmp = JSON.parse(JSON.stringify(this.editCategory))
        tmp.Name = tmp.Lang[this.defaultLanguage.Code].Name

        window.callAS(window.SocketCommand.MealTimes.Save, { mealtime: tmp })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    onSaveResult () {
      this.loading = false
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
      this.isVisible = false
      this.newCategory = null
    },

    allowedStartHours (value) {
      // eslint-disable-next-line
      const [endHour, endMinute] = this.editCategory.EndTime ? this.editCategory.EndTime.split(':') : '00:00'
      return this.editCategory.EndTime ? value <= parseInt(endHour) : true
    },
    allowedStartMinutes (value) {
      // eslint-disable-next-line
      const [startHour, startMinute] = this.editCategory.StartTime ? this.editCategory.StartTime.split(':') : '00:00'
      const [endHour, endMinute] = this.editCategory.EndTime ? this.editCategory.EndTime.split(':') : '00:00'
      return this.editCategory.StartTime && this.editCategory.EndTime ? parseInt(startHour) === parseInt(endHour) ? value > parseInt(endMinute) : true : true
    },
    allowedEndHours (value) {
      // eslint-disable-next-line
      const [startHour, startMinute] = this.editCategory.StartTime ? this.editCategory.StartTime.split(':') : '00:00'
      return this.editCategory.StartTime ? value >= parseInt(startHour) : true
    },
    allowedEndMinutes (value) {
      const [startHour, startMinute] = this.editCategory.StartTime ? this.editCategory.StartTime.split(':') : '00:00'
      // eslint-disable-next-line
      const [endHour, endMinute] = this.editCategory.EndTime ? this.editCategory.EndTime.split(':') : '00:00'
      return this.editCategory.StartTime && this.editCategory.EndTime ? parseInt(startHour) === parseInt(endHour) ? value > parseInt(startMinute) : true : true
    }

  }
}
</script>
