import * as Utils from './Utils'
import moment     from 'moment'

export const types = {
  alpha (value) {
    return new RegExp(/^[a-zA-Z]*$/).test(value)
  },

  alphaNum (value) {
    return new RegExp(/^[a-zA-Z0-9]*$/).test(value)
  },

  numeric (value) {
    return new RegExp(/^[0-9]*$/).test(value)
  },

  array (value) {
    return Utils.isArray(value)
  },

  object (value) {
    return Utils.isObject(value)
  },

  function (value) {
    return Utils.isFunction(value)
  },

  promise (value) {
    return Utils.isPromise(value)
  },

  boolean (value) {
    return Utils.isBoolean(value)
  },

  number (value) {
    return Utils.isNumber(value)
  },

  integer (value) {
    return Utils.isNumber(value) && parseInt(value, 10) === value
  },

  float (value) {
    return Utils.isNumber(value) && parseInt(value, 10) !== value
  },

  string (value) {
    return Utils.isString(value)
  },

  url (value) {
    return (new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i')).test(value)
  },

  domain (value) {
    if (typeof value !== 'string') return false
    const opts = {}

    const parts = value.split('.')
    if (parts.length <= 1) return false

    const tld = parts.pop()
    const tldRegex = /^(?:xn--)?[a-zA-Z0-9]+$/gi

    if (!tldRegex.test(tld)) return false
    if (opts.subdomain === false && parts.length > 1) return false

    const isValid = parts.every(function (host, index) {
      if (opts.wildcard && index === 0 && host === '*' && parts.length > 1) return true

      const hostRegex = /^(?!:\/\/)([a-zA-Z0-9]+|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/gi

      return hostRegex.test(host)
    })

    return isValid
  },

  email (value) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
  },

  date (value) {
    return typeof value.getTime === 'function' && typeof value.getMonth === 'function' && typeof value.getYear === 'function'
  },

  mysqlDateTime (value) {
    return dateFormat('YYYY-MM-DD HH:mm:ss', value)
  },

  mysqlDateOrDateTime (value) {
    return types.mysqlDate(value) || types.mysqlDateTime(value)
  },

  mysqlDate (value) {
    return dateFormat('YYYY-MM-DD', value)
  },

  mysqlTime (value) {
    return timeFormat('HH:mm:ss', value)
  },

  timeString (value) {
    return timeFormat('HH:mm', value)
  },

  dateStringDash (value) {
    return dateFormat('DD-MM-YYYY', value)
  },

  dateNoYearStringDash (value) {
    return dateFormat('DD-MM', value)
  },

  dateStringSlash (value) {
    return dateFormat('DD/MM/YYYY', value)
  }
}

export function type (rule, value) {
  if (Utils.isString(rule)) {
    return types[rule](value)
  } else {
    return value instanceof rule
  }
}

export function required (rule, value) {
  if (rule === false) {
    return true
  }

  if (value === undefined || value === null || value === '') {
    return false
  }

  return true
}

export function requiredIf (rule, value, data) {
  const [ruleProperty, rulePropertyValue] = rule
  const propertyValue = ruleProperty.includes('.') ? Utils.pathToValue(ruleProperty, data) : data[ruleProperty]
  return rulePropertyValue === propertyValue
}

export function regexp (rule, value) {
  if ((new RegExp(rule)).test(value) === false) {
    return false
  }

  return true
}

export function min (rule, value) {
  if (typeof value !== 'number') {
    return false
  }

  if (value < rule) {
    return false
  }

  return true
}

export function max (rule, value) {
  if (typeof value !== 'number') {
    return false
  }

  if (value > rule) {
    return false
  }

  return true
}

export function between (rule, value) {
  return min(rule[0], value) && max(rule[1], value)
}

export function minLen (rule, value) {
  if (Utils.isNumber(value)) {
    value = value.toString()
  } else if (!Utils.isString(value) && !Utils.isArray(value)) {
    return false
  }

  if (value.length < rule) {
    return false
  }

  return true
}

export function maxLen (rule, value) {
  if (Utils.isNumber(value)) {
    value = value.toString()
  } else if (!Utils.isString(value) && !Utils.isArray(value)) {
    return false
  }

  if (value.length > rule) {
    return false
  }

  return true
}

export function betweenLen (rule, value) {
  return minLen(rule[0], value) && maxLen(rule[1], value)
}

export function length (rule, value) {
  if (Utils.isNumber(value)) {
    value = value.toString()
  } else if (!Utils.isString(value) && !Utils.isArray(value)) {
    return false
  }

  if (value.length !== rule) {
    return false
  }

  return true
}

export function equals (rule, value, data) {
  if (rule.includes('|')) rule = rule.split('|')[0]
  // eslint-disable-next-line no-invalid-this
  const value2 = Utils.pathToValue(rule, data)

  if (JSON.stringify(Utils.sortObjectAttributes(value)) !== JSON.stringify(Utils.sortObjectAttributes(value2))) {
    return false
  }

  return true
}

export function is (rule, value) {
  // eslint-disable-next-line eqeqeq
  if (rule !== value) {
    return false
  }

  return true
}

export function isNot (rule, value) {
  // eslint-disable-next-line eqeqeq
  if (rule === value) {
    return false
  }

  return true
}

export function isIn (rule, value) {
  if (rule.indexOf(value) === -1) {
    return false
  }

  return true
}

export function isNotIn (rule, value) {
  if (rule.indexOf(value) > -1) {
    return false
  }

  return true
}

export function dateFormat (rule, value) {
  return moment(value, rule, true).isValid()
}

export function timeFormat (rule, value) {
  return moment(value, rule, true).isValid()
}
