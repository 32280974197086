export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [],
  directives: {},
  watch     : {},
  methods   : {
    getOrderStatusLang (order) {
      if (!order) return ''
      if (parseInt(order.StatusId) === 0) return this.$te('Order.Statuses.NotComplete') ? this.$t('Order.Statuses.NotComplete') : order.OrderStatusName
      if (parseInt(order.StatusId) === 11) return this.$te('Order.Statuses.Received') ? this.$t('Order.Statuses.Received') : order.OrderStatusName
      if (parseInt(order.StatusId) === 12) return this.$te('Order.Statuses.Pending') ? this.$t('Order.Statuses.Pending') : order.OrderStatusName
      if (parseInt(order.StatusId) === 13) return this.$te('Order.Statuses.Preparation') ? this.$t('Order.Statuses.Preparation') : order.OrderStatusName
      if (parseInt(order.StatusId) === 14) return this.$te('Order.Statuses.Delivery') ? this.$t('Order.Statuses.Delivery') : order.OrderStatusName
      if (parseInt(order.StatusId) === 15) return this.$te('Order.Statuses.Completed') ? this.$t('Order.Statuses.Completed') : order.OrderStatusName
      if (parseInt(order.StatusId) === 19) return this.$te('Order.Statuses.Canceled') ? this.$t('Order.Statuses.Canceled') : order.OrderStatusName
      if (parseInt(order.StatusId) === 19 && !order.DeletedAt) return this.$te('Order.Statuses.Canceled') ? this.$t('Order.Statuses.Canceled') : order.OrderStatusName
      if (parseInt(order.StatusId) === 19 && order.DeletedAt) return this.$te('Order.Statuses.Deleted') ? this.$t('Order.Statuses.Deleted') : order.OrderStatusName
      if (parseInt(order.StatusId) === 20) return this.$te('Order.Statuses.Printed') ? this.$t('Order.Statuses.Printed') : order.OrderStatusName
      if (parseInt(order.StatusId) === 21) return this.$te('Order.Statuses.Accepted') ? this.$t('Order.Statuses.Accepted') : order.OrderStatusName
      return order.OrderStatusName || ''
    },
    getOrderStatusNextLang (order) {
      if (!order) return ''
      if (parseInt(order.NextStatusId) === 21) return this.$te('Order.Statuses.Next.Accept') ? this.$t('Order.Statuses.Next.Accept') : order.OrderStatusNextName
      if (parseInt(order.NextStatusId) === 13) return this.$te('Order.Statuses.Next.Prepare') ? this.$t('Order.Statuses.Next.Prepare') : order.OrderStatusNextName
      if (parseInt(order.NextStatusId) === 14) return this.$te('Order.Statuses.Next.Deliver') ? this.$t('Order.Statuses.Next.Deliver') : order.OrderStatusNextName
      if (parseInt(order.NextStatusId) === 15) return this.$te('Order.Statuses.Next.Complete') ? this.$t('Order.Statuses.Next.Complete') : order.OrderStatusNextName
      return order.OrderStatusNextName || ''
    },
    getOrderStatusColorClass (order) {
      if (order) {
        if (parseInt(order.StatusId) === 0) return 'deep-orange'
        if (parseInt(order.StatusId) === 11) return 'grey'
        if (parseInt(order.StatusId) === 12) return 'grey'
        if (parseInt(order.StatusId) === 13) return 'purple'
        if (parseInt(order.StatusId) === 14) return 'brown'
        if (parseInt(order.StatusId) === 15) return 'light-green'
        if (parseInt(order.StatusId) === 19 && !order.DeletedAt) return 'pink'
        if (parseInt(order.StatusId) === 19 && order.DeletedAt) return 'red'
        if (parseInt(order.StatusId) === 21) return 'green'
      }
      return ''
    },
    getOrderStatusButtonColorClass (order) {
      if (order) {
        if (parseInt(order.NextStatusId) === 0) return 'light-blue'
        if (parseInt(order.NextStatusId) === 11) return 'light-blue'
        if (parseInt(order.NextStatusId) === 12) return 'light-blue'
        if (parseInt(order.NextStatusId) === 13) return 'purple'
        if (parseInt(order.NextStatusId) === 14) return 'brown'
        if (parseInt(order.NextStatusId) === 15) return 'light-green'
        if (parseInt(order.NextStatusId) === 19 && !order.DeletedAt) return 'pink'
        if (parseInt(order.NextStatusId) === 19 && order.DeletedAt) return 'red'
        if (parseInt(order.NextStatusId) === 21) return 'light-blue'
      }

      /* if (order) {
        if (parseInt(order.StatusId) === 0) return 'light-blue'
        if (parseInt(order.StatusId) === 11) return 'light-blue'
        if (parseInt(order.StatusId) === 12) return 'light-blue'
        if (parseInt(order.StatusId) === 13) return 'purple'
        if (parseInt(order.StatusId) === 14) return 'brown'
        if (parseInt(order.StatusId) === 15) return 'light-green'
        if (parseInt(order.StatusId) === 19 && !order.DeletedAt) return 'pink'
        if (parseInt(order.StatusId) === 19 && order.DeletedAt) return 'red'
        if (parseInt(order.StatusId) === 21) return 'light-green'
      } */
      return ''
    },
    getOrderStatusIcon (order) {
      if (order) {
        if (parseInt(order.StatusId) === 0) return 'priority_high'
        if (parseInt(order.StatusId) === 11) return 'call_received'
        if (parseInt(order.StatusId) === 12) return 'swap_vertical_circle'
        if (parseInt(order.StatusId) === 13) return 'room_service'
        if (parseInt(order.StatusId) === 14) return 'directions_run'
        if (parseInt(order.StatusId) === 15) return 'done_all'
        if (parseInt(order.StatusId) === 19 && !order.DeletedAt) return 'close'
        if (parseInt(order.StatusId) === 19 && order.DeletedAt) return 'delete_forever'
        if (parseInt(order.StatusId) === 21) return 'done'
      }
      return ''
    }
  },
  computed: {},
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  }

}
