import { render, staticRenderFns } from "./TablePaymentSelectMenuItemDialog.vue?vue&type=template&id=edd0bf2a&scoped=true"
import script from "./TablePaymentSelectMenuItemDialog.vue?vue&type=script&lang=js"
export * from "./TablePaymentSelectMenuItemDialog.vue?vue&type=script&lang=js"
import style0 from "./TablePaymentSelectMenuItemDialog.vue?vue&type=style&index=0&id=edd0bf2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edd0bf2a",
  null
  
)

export default component.exports