export default {
  Title: 'Κέντρο Υποστήριξης',

  Toolbar: {
    Subtitle: 'Δελτία Υποστήριξης',
    Icon    : 'help_outline',
    Color   : 'indigo',
    Button  : {
      New   : { Title: 'Νέο Δελτίο Υποστήριξης', Icon: 'add_box' },
      Filter: { Title: 'Φιλτράρισμα με', Icon: 'filter_list' },
      Group : { Title: 'Ομαδοποίηση με', Icon: 'more_vert' }
    }
  },

  ListView: {
    Select: {
      Title   : 'Επιλέξτε Δελτίο Υποστήριξης',
      Subtitle: 'Επιλέξτε ένα δελτίο υποστήριξης από τη λίστα',
      Icon    : '@:Support.Toolbar.Icon'
    },
    NoData: {
      Subtitle: 'Δεν υπάρχουν δελτία υποστήριξης ακόμη',
      Icon    : '@:Support.Toolbar.Icon'
    }
  },

  TicketAddDialog: {
    Toolbar: {
      Title   : 'Νέο Δελτίο Υποστήριξης',
      Subtitle: 'Δημιουργήστε ένα νέο δελτίο υποστήριξης'
    },
    Field: {
      Department: { Label: 'Επιλέξτε Τμήμα' },
      Service   : { Label: 'Επιλέξτε Υπηρεσία' },
      Priority  : { Label: 'Επιλέξτε Προτεραιότητα' },
      Subject   : { Label: 'Θέμα', PlaceHolder: 'Θέμα Δελτίου Υποστήριξης' }
    }
  },

  GroupBy: {
    Date      : 'Ημερομηνία',
    Department: 'Τμήμα',
    Read      : 'Αναγνωσμένα',
    Unread    : 'Μη Αναγνωσμένα',
    Status    : 'Κατάσταση',
    Service   : 'Υπηρεσία',
    Priority  : 'Προτεραιότητα'
  }
}
