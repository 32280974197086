<template>
  <vue-drag-resize
    :aspect-ratio="tableItem.type === 'circle'"
    :axis="'both'"
    :grid-x="50"
    :grid-y="50"
    :h="tableItem.state.height"
    :is-active="false"
    :is-draggable="true"
    :is-resizable="true"
    :minh="50"
    :minw="50"
    :parent-h="0"
    :parent-limitation="true"
    :parent-scale-x="zoom"
    :parent-scale-y="zoom"
    :parent-w="0"
    :prevent-active-behavior="false"
    :snap-to-grid="true"
    :sticks="sticks"
    :w="tableItem.state.width"
    :x="tableItem.state.x"
    :y="tableItem.state.y"
    :z="'auto'"
    @dragging="onTableResize"
    @resizing="onTableResize"
  >
    <div :style="styles">
      <floor-plan-chair
        v-for="(seat, index) in seats.top"
        :key="`seats-top-${index}`"
        :color="statusColor"
        :x="index"
        top
      />
      <floor-plan-chair
        v-for="(seat, index) in seats.left"
        :key="`seats-left-${index}`"
        :color="statusColor"
        :y="index"
        left
      />
      <floor-plan-chair
        v-for="(seat, index) in seats.right"
        :key="`seats-right-${index}`"
        :color="statusColor"
        :y="index"
        right
      />
      <floor-plan-chair
        v-for="(seat, index) in seats.bottom"
        :key="`seats-bottom-${index}`"
        :color="statusColor"
        :x="index"
        bottom
      />
      <floor-plan-chair
        v-for="(seat, index) in seats.circle"
        :key="`seats-bottom-${index}`"
        :color="statusColor"
        :radius="tableItem.state.width"
        :total="seats.circle"
        :x="index"
        circle
      />
      <div
        :style="`line-height: ${tableItem.state.height}px !important;`"
        class="text-center white--text text-h4"
      >
        {{ tableItem.number }}
      </div>
    </div>
  </vue-drag-resize>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import VueDragResize  from '../../../../common/VueDragResize'
import FloorPlanChair from './FloorPlanChair'
import colors         from 'vuetify/es5/util/colors'

export default {
  name      : 'FloorPlanTable',
  components: {
    'vue-drag-resize' : VueDragResize,
    'floor-plan-chair': FloorPlanChair
  },
  directives: {},
  mixins    : [],
  props     : {
    item: {
      type: Object
    },
    zoom: {
      type   : Number,
      default: 1.0
    }
  },
  data () {
    return {
      seats: {
        top   : 0,
        left  : 0,
        right : 0,
        bottom: 0,
        circle: 0
      }
    }
  },
  computed: {
    sticks () {
      if (this.tableItem.type === 'circle') {
        return ['tm', 'mr', 'bm', 'ml']
      } else {
        return ['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml']
      }
    },
    styles () {
      return {
        'background-color': this.statusColor.base,
        'border-radius'   : this.tableItem.type === 'circle' ? '50%' : 'none',
        width             : (this.tableItem.state.width) + 'px',
        height            : (this.tableItem.state.height) + 'px',
        top               : (this.tableItem.state.y) + 'px',
        left              : (this.tableItem.state.x) + 'px'
      }
    },
    statusColor () {
      if (this.tableItem.status === 'available' && this.tableItem.enabled) {
        return colors.lightGreen
      } else if (this.tableItem.status === 'occupied' && this.tableItem.enabled) {
        return colors.red
      } else if (this.tableItem.status === 'reserved' && this.tableItem.enabled) {
        return colors.orange
      } else {
        return colors.grey
      }
    },
    tableItem: {
      get () {
        return this.item ? this.item : null
      },
      set (val) {
        this.$emit('update:item', val)
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.calculateSeats()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    calculateSeats () {
      if (this.tableItem.type === 'circle') {
        this.seats.circle = parseInt((this.tableItem.state.width * 4) / 50)
      } else {
        this.seats.top = parseInt(this.tableItem.state.width / 50)
        this.seats.left = parseInt(this.tableItem.state.height / 50)
        this.seats.right = parseInt(this.tableItem.state.height / 50)
        this.seats.bottom = parseInt(this.tableItem.state.width / 50)
      }
    },
    setTable (rect) {
      this.tableItem.state.x = parseInt(rect.left)
      this.tableItem.state.y = parseInt(rect.top)
      this.tableItem.state.width = parseInt(rect.width)
      this.tableItem.state.height = parseInt(rect.height)
    },
    onTableResize (rect) {
      this.setTable(rect)
      this.calculateSeats()
    }
  }
}
</script>

<style scoped>

</style>
