/**
 * Enum for order source types.
 * @readonly
 * @enum {Number} SourceTypeEnum
 */

const SourceTypeEnum = {
  ALL           : 0,
  ONLINE        : 1,
  VOIP          : 2,
  TELEPHONY     : 3,
  TABLE         : 4,
  POS           : 5,
  ONLINE_TABLE  : 6,
  ONLINE_BOOTH  : 7,
  ONLINE_ROOM   : 8,
  ONLINE_SUNBED : 9,
  MOBILE        : 10,
  MARKETPLACE   : 11,
  EFOOD         : 100,
  BOX           : 103,
  WOLT          : 104,
  FOODY         : 105,
  FAGI          : 110,
  GETFOOD       : 120,
  POS_GROUP     : -1,
  ONLINE_GROUP  : -2,
  EXTERNAL_GROUP: -3,

  /** @type {Array} */
  get EXTERNAL_PLATFORMS () {
    return [this.EFOOD, this.BOX, this.WOLT, this.FOODY, this.FAGI, this.GETFOOD]
  },

  /** @type {Array} */
  get ONLINE_SOURCES () {
    return [this.ONLINE, this.ONLINE_TABLE, this.ONLINE_BOOTH, this.ONLINE_ROOM, this.ONLINE_SUNBED, this.MOBILE, this.MARKETPLACE]
  },

  /** @type {Array} */
  get POS_SOURCES () {
    return [this.POS, this.VOIP, this.TELEPHONY, this.TABLE]
  },

  /** @type {Array} */
  get EXCLUDE_MULTIPLE_CATALOGS () {
    return [this.ALL, ...this.ONLINE_SOURCES]
  }
}

export default SourceTypeEnum
