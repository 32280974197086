import { AES, HmacMD5 } from '@/lib/crypto/crypto'
import AppLogging       from './appLogging'
import StaffGroupEnum   from '@/api/Enums/StaffGroupEnum'
import PinManager       from '@/mixins/pinManager'
import ReceiptTypeEnum  from '@/api/Enums/ReceiptTypeEnum'

export default {
  data () {
    return {}
  },

  mixins: [AppLogging, PinManager],

  methods: {
    posUserHasRoutePermission (to) {
      const route = to ? this.$router.resolve(to).route : this.$router.currentRoute
      if (!this.posRouteExists(route.name)) return false
      let hasPermission = false

      if (route.meta.requiresAuth) {
        if (!this.posUser) {
          this.$router.replace({ name: 'Login' })
        } else {
          hasPermission = this.posUserCan(route.name, 'ACCESS')
        }
      } else {
        hasPermission = true
      }

      const permissionsOverride = this.posUser?.PermissionsMap || []
      const overrideComponent = permissionsOverride?.find(p => p.Name.map(n => n.toUpperCase()).includes(route.name.toUpperCase()))
      if (overrideComponent) hasPermission = this.posUserCan(overrideComponent.Permission, 'ACCESS')

      return hasPermission
    },

    posUserHasComponentPermission (component, permission) {
      if (!component || !permission) return false
      if (!this.posUser) {
        this.$router.replace({ name: 'Login' })
        return false
      }
      if (this.userIsSuperAdmin) return true

      component = component.toUpperCase()
      permission = permission.toLowerCase()

      let permissionsArr = this.posUser?.Permissions[component]
      const permissionsOverride = this.posUser?.PermissionsMap || []

      const overrideComponent = permissionsOverride?.find(p => p.Name.map(n => n.toUpperCase()).includes(component))
      if (overrideComponent && !permissionsArr) {
        component = overrideComponent.Permission.toUpperCase()
        permissionsArr = this.posUser?.Permissions[component]
      }

      return !!permissionsArr?.includes(permission)
    },

    posRouteExists (component) {
      const routes = this?.$router?.getRoutes() || []
      return !!routes.find(route => route.name.toLowerCase() === component.toLowerCase())
    },

    posUserCan (component, permission) {
      return this.posUserHasComponentPermission(component, permission)
    }
  },

  computed: {
    appDomainIsLocked () {
      return !this.userInAdminGroup && (this.appConfig?.LOCATION_DATA?.LockedFull ?? false)
    },

    appDomainInExtendedPeriod () {
      return !(this.appConfig?.LOCATION_DATA?.LockedFull ?? false) && (this.appConfig?.LOCATION_DATA?.Locked ?? false)
    },

    authorizationTokenBearer () {
      const storageKey = HmacMD5.hash('authorizationToken')
      return this.$sessionStorage.get(storageKey) ? `Bearer ${ this.$sessionStorage.get(storageKey) }` : ''
    },

    authorizationToken: {
      get () {
        const storageKey = HmacMD5.hash('authorizationToken')
        return this.$sessionStorage.get(storageKey)
      },
      set (val) {
        const storageKey = HmacMD5.hash('authorizationToken')
        this.$sessionStorage.set(storageKey, val)
        window.authorizationToken = val || ''
        window.authorizationTokenBearer = this.$sessionStorage.get(storageKey) ? `Bearer ${ this.$sessionStorage.get(storageKey) }` : ''
      }
    },

    posUser: {
      get () {
        const storageKey = HmacMD5.hash('posUser')
        if (!this.$sessionStorage.get(storageKey)) window.Vue.PosUser = null
        if (!window.Vue.PosUser && this.$sessionStorage.get(storageKey)) window.Vue.PosUser = this.$sessionStorage.get(storageKey)
        return AES.decrypt(window.Vue.PosUser)
      },
      set (val) {
        if (val && val.hasOwnProperty('staff_group_id')) {
          val.staff_group_id = String(val.staff_group_id)
        }

        this.$nextTick(() => {
          if (val) {
            this.initRemoteLogging()
            this.setRemoteLoggingUser()
          } else {
            this.unsetRemoteLoggingUser()
          }
        })

        const storageKey = HmacMD5.hash('posUser')
        window.Vue.PosUser = AES.encrypt(val)
        window.staffName = val?.staff_name || ''
        this.$sessionStorage.set(storageKey, AES.encrypt(val))
      }
    },

    UID () {
      return window.UID
    },

    PIN: {
      get () {
        const storageKey = HmacMD5.hash('PIN')
        return AES.decrypt(this.$sessionStorage.get(storageKey))
      },
      set (val) {
        const storageKey = HmacMD5.hash('PIN')
        this.$sessionStorage.set(storageKey, AES.encrypt(val))
      }
    },

    adminGroups () {
      return [11, 12, 13]
    },

    userInAdminGroup () {
      return this.userIsSuperExtraAdmin || this.userIsSuperAdmin || this.userIsGFPEditor || this.userIsCRMEditor
    },

    userIsSuperExtraAdmin () {
      return this.userIsSuperAdmin && [
        'v.gerogiannis@gmail.com',
        'v.gerogiannis@otodev.gr',
        'dao@ddais.com',
        'd.dais@otodev.gr'
      ].includes(this.posUser.staff_email)
    },

    userIsSuperAdmin () {
      return this.posUser && this.posUser.IsAdmin && parseInt(this.posUser.staff_group_id) === StaffGroupEnum.ADMIN
    },

    userIsGFPEditor () {
      return this.posUser && parseInt(this.posUser.staff_group_id) === StaffGroupEnum.GFP_EDITOR
    },

    userIsCRMEditor () {
      return this.posUser && parseInt(this.posUser.staff_group_id) === StaffGroupEnum.CRM_EDITOR
    },

    userIsOwner () {
      return this.posUser && parseInt(this.posUser.staff_group_id) === StaffGroupEnum.OWNER
    },

    userIsCashier () {
      return this.posUser && parseInt(this.posUser.staff_group_id) === StaffGroupEnum.CASHIER
    },

    userIsWaiter () {
      return this.posUser && parseInt(this.posUser.staff_group_id) === StaffGroupEnum.WAITER
    },

    userIsUser () {
      return this.posUser && parseInt(this.posUser.staff_group_id) === StaffGroupEnum.USER
    },

    userCanAccess () {
      const routeName = this.$router.currentRoute.name
      return this.posUserCan(routeName, 'ACCESS')
    },

    userCanAdd () {
      const routeName = this.$router.currentRoute.name
      return this.posUserCan(routeName, 'ADD')
    },

    userCanDelete () {
      const routeName = this.$router.currentRoute.name
      return this.posUserCan(routeName, 'DELETE')
    },

    userCanManage () {
      const routeName = this.$router.currentRoute.name
      return this.posUserCan(routeName, 'MANAGE')
    },

    userCanPrintReceiptTotalsZ () {
      return this.appMachineHasReceiptTotalsZPrinters && this.userCanViewReceipt
    },

    userCanPrintReceipt () {
      return this.appMachineHasReceiptPrinters && this.userCanViewReceipt
    },

    userCanViewReceipt () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsEnabled && this.posUserCan('RECEIPTS', 'MANAGE') && (this.appReceiptsProviderDocument.includes(ReceiptTypeEnum.FNB) || this.appReceiptsProviderDocument.includes(ReceiptTypeEnum.RECEIPT) || this.appReceiptsProviderDocument.includes(ReceiptTypeEnum.INVOICE))
    },

    userCanViewDailyOrders () {
      return this.posUser?.Settings?.Orders?.Permissions?.Daily ?? true
    },

    userCanViewSavedOrders () {
      return this.posUser?.Settings?.Orders?.Permissions?.Saved ?? true
    },

    userCanViewAllOrders () {
      return this.posUser?.Settings?.Orders?.Permissions?.All ?? true
    }
  }
}
