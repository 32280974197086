<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        class="overflow-auto"
      >
        <div
          class="divTable text-caption"
          :class="{'GroupAlternateColors': alternateGroupColors, 'RowAlternateColors': alternateRowColors}"
        >
          <div class="divTableHeading">
            <div class="divTableRow">
              <div class="divTableHead text-left">
                {{ $t('Order.OrderDetailsDialog.Receipt.Type') }}
              </div>

              <div
                v-if="!!orders.length"
                class="divTableHead text-center"
              >
                {{ $t('Order.OrderDetailsDialog.Receipt.OrderNumber') }}
              </div>

              <div class="divTableHead text-right">
                {{ $t('Order.OrderDetailsDialog.Receipt.TotalCheck') }}
              </div>

              <div class="divTableHead text-right">
                {{ $t('Order.OrderDetailsDialog.Receipt.Amount') }}
              </div>

              <div
                v-if="appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD"
                class="divTableHead text-center"
              >
                {{ $t('Order.OrderDetailsDialog.Receipt.Aade') }}
              </div>

              <div class="divTableHead text-center">
                {{ $t('Order.OrderDetailsDialog.Receipt.Status') }}
              </div>

              <div class="divTableHead text-right">
                {{ $t('Order.OrderDetailsDialog.Receipt.RelatedDocs') }}
              </div>

              <div class="divTableHead text-right">
                {{ $t('Order.OrderDetailsDialog.Receipt.CreatedAt') }}
              </div>

              <div class="divTableHead text-right" />
            </div>
          </div>

          <template v-if="receiptsCount">
            <template v-for="(receipt, receiptIndex) in documentsList">
              <v-hover
                v-slot="{hover}"
                :key="`receipt-${receipt.Id}-${receiptIndex}-hover`"
              >
                <receipts-table-row
                  :key="`receipt-${receipt.Id}-${receiptIndex}`"
                  :receipt="receipt"
                  :receipt-index="receiptIndex"
                  :print="print"
                  :email="email"
                  :cancel="cancel"
                  :table="table"
                  :orders="orders"
                  :class="{'grey darken-3': hover && $vuetify.theme.dark, 'grey lighten-2': hover && !$vuetify.theme.dark}"
                />
              </v-hover>
            </template>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                    from '@/mixins/auth'
import AppData                 from '@/mixins/appdata'
import PaymentType             from '@/mixins/orders/paymentType'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import ReceiptTypeEnum         from '@/api/Enums/ReceiptTypeEnum'
import ReceiptsCommon          from '@/mixins/receipts/receiptsCommon'
import ReceiptsTableRow        from '@/components/receipts/ReceiptsTableRow.vue'

export default {
  name      : 'ReceiptsTable',
  components: {
    ReceiptsTableRow
  },
  directives: {},
  mixins    : [Auth, AppData, PaymentType, ReceiptsCommon],
  props     : {
    receipts: {
      type   : Array,
      default: () => []
    },
    orders: {
      type   : Array,
      default: () => []
    },
    print: {
      type   : Boolean,
      default: false
    },
    email: {
      type   : Boolean,
      default: false
    },
    cancel: {
      type   : Boolean,
      default: false
    },
    table: {
      type   : Object,
      default: undefined
    },
    alternateGroupColors: {
      type   : Boolean,
      default: false
    },
    alternateRowColors: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },
    ReceiptProviderTypeEnum () {
      return ReceiptProviderTypeEnum
    },
    documentsList () {
      const data = this.receipts || []
      data.forEach(item => {
        item.fab = false
        const relatedReceipts = item?.Related?.Receipts || []
        const relatedFnbs = item?.Related?.Fnbs || []
        const relatedCanceled = item?.Related?.Canceled?.Mark ? [item?.Related?.Canceled] : []
        const relatedCanceledByMark = item?.Related?.CanceledByMark?.Mark ? [item?.Related?.CanceledByMark] : []
        item.relatedItems = [...relatedReceipts, ...relatedFnbs, ...relatedCanceled, ...relatedCanceledByMark]
      })
      return data
    },

    receiptsCount () {
      return this.receipts?.length || 0
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
.theme--dark .divTable.GroupAlternateColors .divTableGroup:nth-child(odd) {
  background : transparent;
}

.theme--dark .divTable.GroupAlternateColors .divTableGroup:nth-child(even) {
  background : rgba(255, 255, 255, 0.05);
}

.theme--light .divTable.GroupAlternateColors .divTableGroup:nth-child(even) {
  background : transparent;
}

.theme--light .divTable.GroupAlternateColors .divTableGroup:nth-child(odd) {
  background : rgba(0, 0, 0, 0.05);
}

.theme--dark .divTable.RowAlternateColors .divTableRow:nth-child(odd) {
  background : transparent;
}

.theme--dark .divTable.RowAlternateColors .divTableRow:nth-child(even) {
  background : rgba(255, 255, 255, 0.05);
}

.theme--light .divTable.RowAlternateColors .divTableRow:nth-child(even) {
  background : transparent;
}

.theme--light .divTable.RowAlternateColors .divTableRow:nth-child(odd) {
  background : rgba(0, 0, 0, 0.05);
}
</style>
