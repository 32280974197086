import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class AccountOverviewValidator extends BaseValidator {
  rules = {
    Id       : { required: true, type: 'number' },
    Firstname: { required: true, type: 'string', betweenLen: [2, 32] },
    Lastname : { required: true, type: 'string', betweenLen: [2, 32] },
    FullName : { required: true, type: 'string' },
    Email    : { required: true, type: 'email', maxLen: 96 },
    Telephone: { required: true, type: 'string', betweenLen: [8, 16] },
    Locale   : { required: true, type: 'string', length: 2 },
    Pin      : { required: true, type: 'string', length: 4 },
    GroupName: { required: false, type: 'string' },
    GroupId  : { required: false, type: 'string' },
    CreatedAt: { required: false, type: 'string' }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
