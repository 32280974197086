<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card v-if="domain">
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Domain.Copy.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Domain.Copy.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Domain.Copy.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Domain.Copy.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="sourceDomain"
                    :hint="$t('Settings.Domain.Copy.Field.Source.Hint')"
                    :item-text="(d) => getDomainName(d)"
                    :items="domainsList"
                    :label="$t('Settings.Domain.Copy.Field.Source.Label')"
                    :prepend-icon="$t('Settings.Domain.Copy.Field.Source.Icon')"
                    :rules="[(v) => !!v || $t('Common.Error.Mandatory')]"
                    item-value="Id"
                    persistent-hint
                    required
                    return-object
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col cols="12">
                  <v-text-field
                    v-model="targetDomainName"
                    :hint="$t('Settings.Domain.Copy.Field.Target.Hint')"
                    :label="$t('Settings.Domain.Copy.Field.Target.Label')"
                    :prepend-icon="$t('Settings.Domain.Copy.Field.Target.Icon')"
                    persistent-hint
                    readonly
                    required
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-switch
                    v-model="toggleAll"
                    inset
                    :label="toggleAll ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithMenu.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithMenu.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithMenu.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withMenu"
                    inset
                    :label="withMenu ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithActivity.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithActivity.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithActivity.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withActivity"
                    inset
                    :label="withActivity ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithLocation.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithLocation.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithLocation.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withLocation"
                    inset
                    :label="withLocation ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithCustomers.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithCustomers.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithCustomers.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withCustomers"
                    inset
                    :label="withCustomers ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithOrders.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithOrders.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithOrders.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withOrders"
                    inset
                    :label="withOrders ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithCoupons.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithCoupons.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithCoupons.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withCoupons"
                    inset
                    :label="withCoupons ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithStaff.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithStaff.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithStaff.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withStaff"
                    inset
                    :label="withStaff ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithStaffGroups.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithStaffGroups.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithStaffGroups.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withStaffGroups"
                    inset
                    :label="withStaffGroups ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithDeliveryStaff.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithDeliveryStaff.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithDeliveryStaff.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withDeliveryStaff"
                    inset
                    :label="withDeliveryStaff ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithCdr.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithCdr.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithCdr.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withCdr"
                    inset
                    :label="withCdr ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pt-4"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.Copy.Field.WithAssets.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.Copy.Field.WithAssets.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.Copy.Field.WithAssets.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withAssets"
                    inset
                    :label="withAssets ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Copy').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '../../../mixins/appdata'
import Auth    from '../../../mixins/auth'

export default {
  name      : 'DomainCopyDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    domain: {
      type   : Object,
      default: undefined
    },
    domains: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      dialogFormValid  : false,
      toggleAll        : false,
      withMenu         : true,
      withActivity     : false,
      withLocation     : false,
      withCustomers    : false,
      withOrders       : false,
      withCoupons      : false,
      withStaff        : false,
      withStaffGroups  : false,
      withDeliveryStaff: false,
      withCdr          : false,
      withAssets       : false,
      sourceDomain     : null
    }
  },
  computed: {
    domainsList () {
      const domains = this.domains || []
      return domains.filter(d => d.Id !== this.targetDomain.Id)
    },
    targetDomain () {
      return this.domain
    },
    targetDomainName () {
      return this.getDomainName(this.targetDomain)
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    toggleAll (newVal) {
      this.withMenu = newVal
      this.withActivity = newVal
      this.withLocation = newVal
      this.withCustomers = newVal
      this.withOrders = newVal
      this.withCoupons = newVal
      this.withStaff = newVal
      this.withStaffGroups = newVal
      this.withDeliveryStaff = newVal
      this.withCdr = newVal
      this.withAssets = newVal
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Domains.Copy, this.onResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Domains.Copy, this.onResult)
  },
  methods: {
    getDomainName (d) {
      const Domain = d.Domain || ''
      const SubDomain = Domain.split('.').shift()
      const BaseDomain = Domain.split('.').slice(1).join('.').slice(0)
      return d ? `${ SubDomain }.${ d.BaseDomain || BaseDomain || '' }` : ''
    },
    onResult (result) {
      this.onCancelButtonClick()
    },
    onSaveButtonClick () {
      this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()

        const data = {
          SourceDomain     : this.sourceDomain.Id,
          SourceAppId      : this.sourceDomain.AppId,
          TargetDomain     : this.targetDomain.Id,
          TargetAppId      : this.targetDomain.Application.id,
          WithMenu         : this.withMenu,
          WithActivity     : this.withActivity,
          WithLocation     : this.withLocation,
          WithCustomers    : this.withCustomers,
          WithOrders       : this.withOrders,
          WithCoupons      : this.withCoupons,
          WithStaff        : this.withStaff,
          WithStaffGroups  : this.withStaffGroups,
          WithDeliveryStaff: this.withDeliveryStaff,
          WithCdr          : this.withCdr,
          WithAssets       : this.withAssets
        }

        window.callAS(window.SocketCommand.Settings.Domains.Copy, data)
      }
    },
    onCancelButtonClick () {
      this.sourceDomain = null
      this.withMenu = true
      this.withActivity = false
      this.withLocation = false
      this.withCustomers = false
      this.withOrders = false
      this.withCoupons = false
      this.withStaff = false
      this.withStaffGroups = false
      this.withDeliveryStaff = false
      this.withCdr = false
      this.withAssets = false
      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
    }
  }
}
</script>
