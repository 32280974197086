export default {
  Title : 'Delivery Staff | Delivery Staff | Delivery Staff',
  Button: {
    DeliveryStaff: {
      Add: {
        Title: 'Add Delivery Staff',
        Color: 'primary',
        Icon : 'add_box'
      },
      Edit: {
        Color: 'grey lighten-0',
        Icon : 'edit'
      },
      Delete: {
        PopUp: {
          Title  : 'Delete delivery staff?',
          Content: 'Are you sure you want to permanently delete this delivery staff?'
        }
      }
    },
    RemoveOrder: {
      PopUp: {
        Title  : 'Remove order?',
        Content: 'Are you sure you want to remove this order from the delivery staff\'s shift?'
      }
    },
    Shift: {
      Open: {
        Title: 'Start shift',
        Color: 'green',
        Icon : 'fas fa-plus-circle',
        PopUp: {
          Title : 'Shift Start Amount',
          Hint  : 'The amount with which the shift starts.',
          Button: 'Start'
        }
      },
      Close: {
        Title: 'End shift',
        Color: 'red',
        Icon : 'fas fa-times-circle',
        PopUp: {
          Title  : 'End shift?',
          Content: 'Are you sure you want to end this shift?'
        }
      }
    }
  },

  ListView: {
    NoData: {
      Title   : 'Create Delivery Staff',
      Subtitle: 'Add New Delivery Staff',
      Icon    : 'account_circle'
    },
    SelectDeliveryStaff: {
      Title   : 'Select delivery staff',
      Subtitle: 'Select delivery staff from the list',
      Icon    : 'directions_bike'
    },
    Table: {
      Headers: {
        DateStarted: 'Date',
        Swifts     : 'Swifts',
        Orders     : 'Orders',
        Total      : 'Total',
        Actions    : ''
      }
    }
  },

  Common: {
    Orders      : 'Orders:',
    ShiftsOrders: 'Shift\'s Orders: ',
    ShiftStart  : 'Start of shift',
    ShiftTotal  : 'Total amount of shift',
    InfoLabels  : {
      Icon     : 'account_circle',
      Telephone: 'Tel.:',
      Email    : 'Email:'
    }
  },

  DeliveryStaffInfo: {
    Tab: {
      Details: '@:Common.Misc.Details',
      History: 'History'
    },
    Button: {
      AddOrder: {
        Title: 'Add order',
        Color: 'light-green'
      },
      ViewDailyShift: {
        Title: 'View daily shifts',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      }
    },
    Result: {
      NoOrders     : 'There are no shift orders yet',
      InactiveShift: {
        Icon        : 'account_circle',
        Title       : 'Inactive shift',
        SubtitleLink: 'Start',
        SubtitleText: 'delivery staff\'s shift'
      }
    }
  },

  PrintDeliveryShift: {
    PrintReceipt: {
      StartShift  : 'Start of Shift',
      Delivery    : 'Delivery',
      Collection  : 'Collection',
      Canceled    : 'Canceled',
      Cash        : 'Cash',
      Card        : 'Card',
      WithoutStart: 'Without Start:',
      NotIncluded : 'Not Included',
      ByCard      : ' by Card ('
    },
    Notification: 'The shift was sent for printing!'
  },

  DeliveryStaffAddDialog: {
    Toolbar: {
      Color: 'indigo',
      Icon : 'directions_bike',
      Add  : {
        Title   : 'Add Delivery Staff',
        Subtitle: 'Create New Delivery Staff'
      },
      Edit: {
        Title   : 'Edit Delivery Staff',
        Subtitle: 'Edit Delivery Staff Details'
      }
    },
    Field: {
      Firstname: {
        Label: 'First Name',
        Hint : 'Delivery Staff\'s First Name. eg. George, Jane, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'First Name must be between 2 and 32 characters'
        }
      },
      Lastname: {
        Label: 'Last Name',
        Hint : 'Delivery Staff\'s Last Name. eg. Smith, Doe, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'Last Name must be between 2 and 32 characters'
        }
      },
      Telephone: {
        Label: 'Telephone',
        Hint : 'Delivery Staff\'s telephone number eg. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Telephone number must be between 8 and 16 digits'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Delivery Staff\'s email address eg. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'E-mail  must be up to 96 characters long',
          Valid  : 'E-mail must be valid'
        }
      },
      Notes: {
        Label: 'Notes',
        Hint : 'Delivery Staff\'s additional info',
        Icon : 'speaker_notes'
      }
    }
  },

  DeliveryStaffDayShiftsDialog: {
    Header: {
      Icon : 'directions_bike',
      Title: 'Delivery Staff\'s Daily Shifts',
      Shift: 'Shift'
    },
    Result: {
      NoOrders: {
        Icon    : 'shopping_cart',
        Color   : 'grey lighten-2',
        Title   : 'There are no orders',
        Subtitle: 'There are no orders in the delivery staff\'s shift'
      }
    }
  },

  SelectDeliveryStaffDialog: {
    Toolbar: {
      Icon    : 'directions_bike',
      Subtitle: 'Delivery Staff Selection'
    },
    Result: {
      NoDeliveryStaff: {
        Title   : 'There are no Delivery Staff',
        Subtitle: {
          Please       : 'Please',
          Open         : 'open',
          ShiftOr      : 'shift or',
          Create       : 'create',
          DeliveryStaff: 'delivery staff'
        }
      }
    }
  },

  DeliveryStaffBarcode: {
    OnBarcodeScan: {
      Error: {
        Title: 'Order Barcode #',
        Body : 'The order was not added! Please scan the delivery staff barcode first!'
      },
      SetActiveDeliveryStaff: {
        Add: {
          Body: 'Start receiving orders for delivery staff. Please scan order barcode.'
        },
        ClosedShift: {
          Title: 'Delivery Staff Barcode',
          Body : 'Delivery Staff {firstname} {lastname} does not have an open shift. Please open shift first.'
        },
        NotFound: {
          Title: 'Delivery Staff Barcode',
          Body : 'The barcode does not correspond to delivery staff!'
        }
      },
      ClearActiveDeliveryStaff: {
        Body: 'End receiving orders for delivery staff.'
      }
    }
  }
}
