<template>
  <v-row justify="center">
    <v-dialog
      v-if="selectedDeliveryStaff && selectedShiftsDay"
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ $t('Delivery.DeliveryStaffDayShiftsDialog.Header.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Delivery.DeliveryStaffDayShiftsDialog.Header.Title') }}
            </div>

            <div class="text-caption grey--text">
              {{ selectedDeliveryStaff.Firstname }} {{ selectedDeliveryStaff.Lastname }}:
              <span class="grey--text text--darken-1">
                {{ selectedShiftsDay.ShiftsDate }}
              </span>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-tabs
          v-model="activeTab"
          :grow="selectedShiftsDay.Shifts.length < 7"
          background-color="indigo"
          slider-color="light-green"
          show-arrows
          dark
        >
          <v-tab
            v-for="(shift, key) in selectedShiftsDay.Shifts"
            :key="'tab' + key"
            :href="'#tab' + key"
            active-class="v-tabs__item--active indigo darken-2"
            ripple
            @click="shiftToggle(shift)"
          >
            <template v-if="!shift.TimeEndedFmt">
              {{ $t('Delivery.DeliveryStaffDayShiftsDialog.Header.Shift') }}<br>{{ shift.ShowDates ? shift.DateStartedFmt + ' ' + shift.TimeStartedFmt : shift.TimeStartedFmt }} - {{ $t('Common.Date.Now') }}
            </template>

            <template v-else>
              {{ $t('Delivery.DeliveryStaffDayShiftsDialog.Header.Shift') }}<br>{{ shift.ShowDates ? shift.DateStartedFmt + ' ' + shift.TimeStartedFmt : shift.TimeStartedFmt }} - {{ shift.ShowDates ? shift.DateEndedFmt + ' ' + shift.TimeEndedFmt : shift.TimeEndedFmt }}
            </template>
          </v-tab>
        </v-tabs>

        <v-container
          fluid
          class="pa-4"
        >
          <v-row>
            <v-col
              class="text-left"
              sm="3"
              cols="6"
            >
              <div
                v-if="selectedShift"
                class="text-center"
              >
                <div class="text-caption pl-1 pt-0 blue--text">
                  <strong>{{ $t('Delivery.Common.ShiftStart') }}</strong>
                </div>
                <div class="text-h6 grey--text text--darken-1">
                  {{ selectedShift.ShiftStartMoney | currency }}
                </div>
                <div
                  class="text-caption pl-1 pt-1 grey--text"
                  style="white-space: nowrap;"
                >
                  {{ selectedShiftsDay.ShiftsDate }}
                </div>
                <div class="text-caption">
                  {{ selectedShift.TimeStartedFmt }}
                </div>
              </div>
            </v-col>

            <v-col
              class="text-left"
              sm="3"
              cols="6"
            >
              <div
                v-if="selectedShift"
                class="text-center"
              >
                <div class="text-caption pl-1 pt-0 orange--text">
                  <strong>{{ $t('Delivery.Common.ShiftTotal') }}</strong>
                </div>

                <div class="text-h6 grey--text text--darken-1">
                  {{ (selectedShift.ShiftStartMoney + selectedShift.ShiftOrdersTotal) | currency }}
                </div>

                <div
                  class="text-caption pl-1 pt-1"
                  style="white-space: nowrap;"
                >
                  <span class="grey--text">
                    {{ $t('Delivery.Common.Orders') }}
                  </span>
                  <div>{{ selectedShift.ShiftOrdersTotal | currency }}</div>
                </div>
              </div>
            </v-col>

            <v-col
              class="text-right"
              sm="6"
              cols="12"
            >
              <barcode
                :style="$vuetify.breakpoint.xsOnly ? 'zoom: 0.85' : ''"
                :text="(String(selectedDeliveryStaff.Firstname + ' ' + selectedDeliveryStaff.Lastname).toLocaleUpperCase($i18n.locale))"
                :value="getBarcode(selectedDeliveryStaff).barcode"
                class="print-item"
                element-tag="svg"
                font-size="12"
                format="CODE128"
                height="35"
                text-margin="2"
                text-position="bottom"
                width="1"
              />

              <div style="display: none;">
                <div
                  class="barcode-generator-print-element"
                  style="text-align: center;"
                >
                  <barcode
                    ref="barcodeCanvas"
                    :margin="0"
                    :value="getBarcode(selectedDeliveryStaff).barcode"
                    class="print-item"
                    element-tag="canvas"
                    font-size="12"
                    format="CODE128"
                    height="40"
                    text=" "
                    text-margin="2"
                    text-position="bottom"
                    width="1"
                  />
                </div>
              </div>

              <v-menu
                v-if="appMachinePrintersActive.length > 1 && appHasPrinters && userCanManage"
                left
                offset-y
                open-on-hover
              >
                <template #activator="{on}">
                  <v-btn
                    absolute
                    class="grey"
                    dark
                    icon
                    right
                    style="margin-top: 220px; top: -14px;"
                    top
                    v-on="on"
                  >
                    <v-icon>print</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(printer, index) in appMachinePrintersActive"
                    :key="index"
                    @click="onPrintDeliveryShift(printer)"
                  >
                    <v-list-item-action>
                      <v-icon>print</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ printer.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                v-if="(appMachinePrintersActive.length === 1 && appHasPrinters) && userCanManage"
                absolute
                class="grey"
                dark
                icon
                right
                style="margin-top: 220px; top: -14px;"
                top
                @click.native="onPrintDeliveryShift(appMachinePrintersActive[0])"
              >
                <v-icon>print</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-divider />

        <v-subheader class="pl-0 pt-4 grey--text text--darken-2">
          <v-spacer />

          <template v-if="selectedShift">
            {{ selectedShift.Orders.length > 0 ? $t('Delivery.Common.ShiftsOrders') + selectedShift.Orders.length : '' }}
          </template>
        </v-subheader>

        <v-card-text class="px-2">
          <v-tabs-items
            v-if="selectedShift"
            v-model="activeTab"
          >
            <v-tab-item
              :key="activeTab"
              :value="activeTab"
            >
              <v-container
                class="pa-0"
                fluid
              >
                <v-row>
                  <v-col
                    v-if="selectedShift.Orders.length <= 0"
                    cols="12"
                  >
                    <div class="text-center grey--text pa-6">
                      <v-icon
                        :color="$t('Delivery.DeliveryStaffDayShiftsDialog.Result.NoOrders.Color')"
                        class="mb-4"
                        size="128"
                      >
                        {{ $t('Delivery.DeliveryStaffDayShiftsDialog.Result.NoOrders.Icon') }}
                      </v-icon>

                      <div
                        class="text-h6 mb-2"
                        style="height: auto;"
                      >
                        {{ $t('Delivery.DeliveryStaffDayShiftsDialog.Result.NoOrders.Title') }}
                      </div>

                      <div
                        class="text-subtitle-1"
                        style="height: auto;"
                      >
                        {{ $t('Delivery.DeliveryStaffDayShiftsDialog.Result.NoOrders.Subtitle') }}
                      </div>
                    </div>
                  </v-col>

                  <v-col
                    v-else
                    cols="12"
                  >
                    <orders-v-list
                      :orders="selectedShift.Orders"
                      @list-tile-click="getOrderDetails"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData           from '../../mixins/appdata'
import Auth              from '../../mixins/auth'
import BarcodeGenerator  from '../../lib/barcode/BarcodeGenerator'
import Printer           from '@/lib/helper/printer'
import OrdersVList       from '../orders/common/OrdersVList'
import BarcodePrefixEnum from '@/api/Enums/BarcodePrefixEnum'

export default {
  components: {
    OrdersVList,
    barcode: BarcodeGenerator
  },
  mixins: [AppData, Auth],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    tmpOrder: {
      type   : Object,
      default: undefined
    },
    selectedDeliveryStaff: {
      type   : Object,
      default: undefined
    },
    selectedShiftsDay: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      activeTab    : 'tab0',
      selectedShift: null
    }
  },
  computed: {
    tempOrder: {
      get () {
        return this.tmpOrder
      },
      set (val) {
        this.$emit('update:tmpOrder', val)
      }
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        if (this.selectedShiftsDay.Shifts.length > 0) {
          this.activeTab = 'tab'
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.shiftToggle(this.selectedShiftsDay.Shifts[0])
              this.activeTab = 'tab0'
            })
          })
        }
      }
    }
  },
  methods: {
    shiftToggle (shift) {
      this.selectedShift = shift
    },
    getOrderListItemColor (order) {
      let colorClass = ''
      colorClass = order.Payment === 'cod' ? 'green lighten-5' : 'blue-grey lighten-5'
      colorClass = parseInt(order.StatusId) !== 19 ? colorClass : 'red lighten-5'
      return colorClass
    },
    getOrderDetails (orderItem) {
      this.tempOrder = orderItem
      window.callAS(window.SocketCommand.Order.Get, {
        order_id : orderItem.Id,
        status_id: orderItem.StatusId
      })
    },
    getBarcode (staff) {
      const barcode = {
        barcode: BarcodePrefixEnum.DeliveryStaff + staff.Id,
        value  : staff.Id
      }

      return barcode
    },
    onCancelButtonClick () {
      this.selectedShift = null
      this.isVisible = false
    },
    onPrintDeliveryShift (printer) {
      const printWithOrders = this.appConfig.LOCATION_DATA.hasOwnProperty('PrintDeliveryStaffShiftWithOrders') ? this.appConfig.LOCATION_DATA.PrintDeliveryStaffShiftWithOrders : true
      let printContents = ''
      let totalOrdersExcludingCanceled = 0
      let totalCanceledOrders = 0
      let totalOrdersMoneyCanceled = 0
      let totalCardOrders = 0
      let totalOrdersMoneyCard = 0

      printContents += '<div style="width: 100%; text-align: center; margin-top: 12px;"><img style="" width="' + this.$refs.barcodeCanvas.$el.children[0].width + 'px" height="' + this.$refs.barcodeCanvas.$el.children[0].height + 'px" src="' + this.$refs.barcodeCanvas.$el.children[0].toDataURL('image/jpeg') + '" /></div>'
      printContents += '<div style="width: 100%; text-align: center;">'
      printContents += '<span style="position:relative; top: -10px; font-family: Arial; font-size: 12px; text-align: center;">' + (String(this.selectedDeliveryStaff.Firstname + ' ' + this.selectedDeliveryStaff.Lastname).toLocaleUpperCase(this.$i18n.locale)) + '</span>'
      printContents += '</div>'

      if (this.selectedDeliveryStaff && this.selectedShift) {
        printContents += '' +
          '<div style="margin-bottom: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.StartShift') + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(this.selectedShift.ShiftStartMoney) + '</div>' +
          '<div>' + this.selectedShiftsDay.ShiftsDate + ' ' + this.selectedShift.TimeStartedFmt + '</div>' +
          '</div>'

        for (let i = 0; i < this.selectedShift.Orders.length; i++) {
          const isNotCalculated = parseInt(this.selectedShift.Orders[i].StatusId) === 19 || this.selectedShift.Orders[i].Payment !== 'cod'
          const isCanceled = parseInt(this.selectedShift.Orders[i].StatusId) === 19
          const isCash = this.selectedShift.Orders[i].Payment === 'cod'

          if (isCanceled) {
            totalOrdersMoneyCanceled += parseFloat(this.selectedShift.Orders[i].OrderTotal)
            totalCanceledOrders++
          } else {
            totalOrdersExcludingCanceled++
          }
          if (!isCash && !isCanceled) {
            totalOrdersMoneyCard += parseFloat(this.selectedShift.Orders[i].OrderTotal)
            totalCardOrders++
          }

          if (printWithOrders) {
            printContents += '' +
              '<div style="width: 100%; margin-top: 10px; font-family: Arial; font-size: 10px; text-align: center; text-decoration: ' + (isNotCalculated ? 'line-through' : 'none') + ';">' +
              '<span><strong>#' + this.selectedShift.Orders[i].Id + '</strong></span>' +
              '<span>&nbsp;' + (parseInt(this.selectedShift.Orders[i].OrderTypeId) === 1 ? this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Delivery').toLocaleUpperCase(this.$i18n.locale) : this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Collection').toLocaleUpperCase(this.$i18n.locale)) + '</span>' +
              '<span>&nbsp;' + (this.selectedShift.Orders[i].Payment === 'cod' ? this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Cash').toLocaleUpperCase(this.$i18n.locale) : this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Card').toLocaleUpperCase(this.$i18n.locale)) + '&nbsp;' + (parseInt(this.selectedShift.Orders[i].StatusId) === 19 ? this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Canceled').toLocaleUpperCase(this.$i18n.locale) : '') + '</span>' +
              '<div>' +
              '<span>&nbsp;' + this.selectedShift.Orders[i].DateAdded + ' - ' + this.selectedShift.Orders[i].TimeAdded + '</span>' +
              '<span><strong>&nbsp;' + this.$options.filters.currency(this.selectedShift.Orders[i].OrderTotal) + '</strong></span>' +
              '</div>' +
              '</div>'
          }
        }

        totalOrdersMoneyCanceled = this.$options.filters.currency(totalOrdersMoneyCanceled)
        totalOrdersMoneyCard = this.$options.filters.currency(totalOrdersMoneyCard)

        printContents += '' +
          '<div style="width: 100%; margin-top: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Delivery.Common.ShiftTotal') + '</strong></div>' +
          '<div style="font-size: 14px;"><strong>' + (totalOrdersExcludingCanceled - totalCardOrders) + ' ' + this.$tc('Order.Title', (totalOrdersExcludingCanceled - totalCardOrders)) + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(this.selectedShift.ShiftStartMoney + this.selectedShift.ShiftOrdersTotal) + '</div>' +
          '<div><strong>' + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.WithoutStart') + '</strong> ' + this.$options.filters.currency(this.selectedShift.ShiftOrdersTotal) + '</div>'

        if (totalCardOrders > 0 || totalCanceledOrders > 0) {
          printContents += '<br><div style="font-size: 12px;"><strong>' + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.NotIncluded') + '</strong></div>'
        }

        if (totalCardOrders > 0) {
          printContents += '<div style="font-size: 12px;">' + totalCardOrders + ' ' + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.ByCard') + totalOrdersMoneyCard + ')</div>'
        }

        if (totalCanceledOrders > 0) {
          printContents += '<div style="font-size: 12px;">' + totalCanceledOrders + ' ' + this.$tc('Order.Status.Canceled', totalCanceledOrders) + ' (' + totalOrdersMoneyCanceled + ')' + '</div>'
        }
        printContents += '</div>'
      }

      const html = '<html>' +
        '<head>' +
        '<style>' +
        ' @page {' +
        '            width: 100%;' +
        '            margin-left   : 0px;' +
        '            margin-right  : 0px;' +
        '            margin-top    : 0px;' +
        '            margin-bottom : 0px;' +
        '        }' +
        'body { width: 100%; border: 0; margin: 0; overflow : hidden; }' +
        '@media print { body { width: 100%; overflow : hidden; border: 0; margin: 0; } }' +
        '</style>' +
        '</head>' +
        '<body>' + printContents + '</body>' +
        '</html>'

      this.$bus.$emit('app-show-notification', {
        body: this.$t('Delivery.PrintDeliveryShift.Notification'),
        type: 'info',
        icon: 'print'
      })

      Printer.printStaffShift(html, printer.name)
    }
  }
}
</script>
