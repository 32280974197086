export default {
  Call: {
    Title: 'Calls | Call | Calls',

    ListView: {
      CallerId: {
        Unknown: {
          Title: 'Unknown No.',
          Color: 'red lighten-2',
          Icon : 'phone'
        },
        Phone: {
          Color: 'blue lighten-2',
          Icon : 'phone'
        }
      },
      CustomerAdd: {
        Tooltip: {
          Title: 'Add New Customer',
          Color: 'green darken-1'
        },
        Button: {
          Color: 'green darken-1',
          Icon : 'person_add_alt_1'
        }
      },
      SearchCustomer: {
        Tooltip: {
          Title: 'Search Customers',
          Color: 'blue darken-3'
        },
        Button: {
          Color: 'blue darken-3',
          Icon : 'person_search'
        }
      },
      CustomerDetails: {
        Tooltip: {
          Title: 'Search Customers',
          Color: 'blue darken-3'
        },
        Button: {
          Color: 'blue darken-3',
          Icon : 'person_search'
        }
      }
    },

    Table: {
      Headers: {
        Status    : 'Status',
        Customer  : 'Customer',
        User      : 'User',
        CallerId  : 'Phone',
        Address   : 'Address',
        IncomingAt: 'Date / Time',
        Actions   : ''
      },
      Filter: {
        filterStatus: {
          Type       : 'v-btn-toggle',
          Label      : 'Τύπος Παραγγελίας',
          PlaceHolder: 'Όλοι οι τύποι παραγγελίας',
          ItemText   : 'Name',
          ItemValue  : 'Value',
          Items      : [
            {
              Value: 0,
              Name : 'Όλες',
              Color: 'light-blue',
              Icon : 'phone_in_talk',
              Class: 'white--text'
            },
            {
              Value: 1,
              Name : 'Απαντημένες',
              Color: 'green',
              Icon : 'phone_in_talk',
              Class: 'white--text'
            },
            {
              Value: 2,
              Name : 'Αναπάντητες',
              Color: 'red',
              Icon : 'phone_missed',
              Class: 'white--text'
            }
          ]
        },
        filterDate: {
          Type       : 'v-date-picker',
          Label      : 'Date',
          PlaceHolder: 'Select Date',
          Icon       : 'event'
        }
      }
    }
  }
}
