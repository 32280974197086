import BaseModel              from '../../../lib/data/Models/BaseModel'
import SupportTicketValidator from '@/api/Validations/support-center/SupportTicketValidator'
import SocketCommand          from '@/api/SocketCommand'

export default class SupportTicketAddModel extends BaseModel {
  constructor (rawData = {}, validator = SupportTicketValidator) {
    super(rawData, validator)

    this.saveCommand = SocketCommand.SupportCenter.Ticket.Add
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.subject = rawData.subject || ''
    this.message = rawData.message || ''
    this.department = parseInt(rawData.department) || 0
    this.priority = parseInt(rawData.priority) || 0
    this.service = parseInt(rawData.service) || 0
  }

  boot () {

  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */

  onSave (data) {
    super.onSave(data)
    window.Vue.$DataStore.support.tickets.fetch()
  }
}
