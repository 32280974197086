import BaseModel       from '@/lib/data/Models/BaseModel'
import PaymentTypeEnum from '@/api/Enums/PaymentTypeEnum'

export default class CartModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  /* PROPERTIES */
  get Customer () {
    return this.SCustomer
  }

  set Customer (val) {
    this.SCustomer = val && parseInt(val.Id) && val || null
    this.CustomerId = val && parseInt(val.Id) || 0
    if (this.SCustomer) {
      const infoObj = {
        Id        : this.CustomerId,
        Firstname : this.SCustomer.Firstname || '',
        Lastname  : this.SCustomer.Lastname || '',
        Telephone : this.SCustomer.Telephone || '',
        Telephone2: this.SCustomer.Telephone2 || '',
        Telephone3: this.SCustomer.Telephone3 || '',
        Newsletter: this.SCustomer.Newsletter ?? true,
        Email     : this.SCustomer.Email || '',
        Status    : this.SCustomer.Status ?? true,
        GroupId   : this.SCustomer.GroupId || 0,
        Groups    : this.SCustomer.Groups || null
      }
      this.SCustomer.Info = infoObj
    }
  }

  get Address () {
    return this.SAddress
  }

  set Address (val) {
    this.SAddress = val || null
    this.AddressId = val && parseInt(val.Id) || -1
  }

  get OrderType () {
    return this.SOrderType
  }

  set OrderType (val) {
    this.SOrderType = parseInt(val) || 1
    const defaultOrderTypeTime = this.SOrderType === 1 ? window.Vue.OrderTypeDefaultTimes.delivery_time : window.Vue.OrderTypeDefaultTimes.collection_time
    this.OrderTypeTime = defaultOrderTypeTime
    this.RespectDeliveryAreaCharges = this.SOrderType === 1
  }

  get OrderChange () {
    const receivedTotal = this.PaymentTransactions.reduce((a, b) => a + parseFloat(b.Received), 0) || 0
    const transactionsReceivedTotal = parseFloat(receivedTotal.toFixed(2))
    const orderTotal = parseFloat(this.SOrderTotal.toFixed(2))

    return Math.abs(transactionsReceivedTotal > orderTotal ? orderTotal - transactionsReceivedTotal : 0)
  }

  get OrderReceivedTotal () {
    const receivedTotal = this.PaymentTransactions.reduce((a, b) => a + parseFloat(b.Received), 0) || 0

    return parseFloat(receivedTotal.toFixed(2))
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = parseInt(rawData.Id) || null
    this.LocationId = parseInt(rawData.LocationId) || 1
    this.CustomerId = parseInt(rawData.CustomerId) || 0
    this.SCustomer = rawData.SCustomer || null
    this.AddressId = parseInt(rawData.AddressId) || -1
    this.SAddress = rawData.Address || null
    this.Items = rawData.Items || []
    this.ItemsFlat = rawData.ItemsFlat || []
    this.ItemsByTableSeat = rawData.ItemsByTableSeat || []
    this.SCartTotal = parseFloat(rawData.SCartTotal) || 0
    this.TotalItems = parseInt(rawData.TotalItems) || 0
    this.SOrderTotal = parseFloat(rawData.SOrderTotal) || 0.00
    this.Totals = rawData.Totals || []
    this.SOrderType = parseInt(rawData.SOrderType) || 1
    this.OrderTypeTime = parseInt(rawData.OrderTypeTime) || 30
    this.SourceTypeId = parseInt(rawData.SourceTypeId) || 5
    this.PaymentType = rawData.PaymentType || PaymentTypeEnum.CASH
    this.PaymentTransactions = rawData.PaymentTransactions || []
    this.Comment = rawData.Comment || ''
    this.DateAdded = rawData.DateAdded || ''
    this.Messages = rawData.Messages || []
    this.TableShiftId = rawData.TableShiftId || null
    this.TableShift = rawData.TableShift || null
    this.RespectDeliveryAreaCharges = rawData.RespectDeliveryAreaCharges ?? true

    if (rawData.SCustomer) this.Customer = rawData.SCustomer
    if (rawData.SAddress) this.Address = rawData.SAddress
  }

  boot () {
    this.guarded = ['CallerId', 'ItemsByTableSeat']
  }

  /* METHODS */

  /* API METHODS */
}
