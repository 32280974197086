<template>
  <div
    :style="`
        background-image : linear-gradient(rgba(0, 0, 0, .1) 1px, transparent 1px), linear-gradient(90deg, rgba(0, 0, 0, .1) 1px, transparent 1px);
        background-size  : ${50*zoom}px ${50*zoom}px;
        background-repeat : repeat;
    `"
    style="overflow: auto; position: absolute; width: 100%; height: calc(100% - 80px);"
  >
    <div
      :style="`
         width: calc(${200 * (1/zoom)}% - ${(100*zoom) * (1/zoom)}px);
         height: calc(${200 * (1/zoom)}% - ${(80 + (100*zoom)) * (1/zoom)}px);
         margin: ${50*zoom}px; transform: scale(${zoom});
         transform-origin: 0 0;
         position: absolute;`"
      class="greenXX"
    >
      <template v-for="(table, index) in tables">
        <floor-plan-table
          :key="`floor-plan-table-${index}`"
          :item="table"
          :zoom="zoom"
        />
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
/* eslint-disable vue/require-prop-types */
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'
import FloorPlanTable from './FloorPlanTable'

export default {
  name      : 'TablesGrid',
  components: {
    'floor-plan-table': FloorPlanTable
  },
  directives: {},
  mixins    : [AppData, Auth],
  props     : ['zoom'],
  data () {
    return {
      tables: [
        {
          number  : '1',
          minSeats: 2,
          maxSeats: 4,
          type    : 'circle',
          status  : 'available',
          enabled : true,
          state   : {
            width : 100,
            height: 100,
            x     : 0,
            y     : 0
          }
        },
        {
          number  : '2',
          minSeats: 2,
          maxSeats: 4,
          type    : 'rect',
          status  : 'available',
          enabled : true,
          state   : {
            width : 150,
            height: 100,
            x     : 200,
            y     : 0
          }
        },
        {
          number  : '3',
          minSeats: 2,
          maxSeats: 4,
          type    : 'rect',
          status  : 'occupied',
          enabled : true,
          state   : {
            width : 150,
            height: 50,
            x     : 200,
            y     : 200
          }
        },
        {
          number  : '4',
          minSeats: 2,
          maxSeats: 4,
          type    : 'rect',
          status  : 'reserved',
          enabled : true,
          state   : {
            width : 100,
            height: 250,
            x     : 500,
            y     : 0
          }
        },
        {
          number  : '5',
          minSeats: 2,
          maxSeats: 4,
          type    : 'rect',
          status  : 'available',
          enabled : true,
          state   : {
            width : 100,
            height: 50,
            x     : 0,
            y     : 200
          }
        },
        {
          number  : '6',
          minSeats: 2,
          maxSeats: 4,
          type    : 'rect',
          status  : 'available',
          enabled : false,
          state   : {
            width : 100,
            height: 250,
            x     : 750,
            y     : 0
          }
        }
      ]
    }
  },
  computed: {},
  watch   : {
    zoom: {
      handler: function () {
        // console.log('Zoom: ', newVal, oldVal)
      },
      deep: true
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
