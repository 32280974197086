<template>
  <v-app light>
    <v-main class="grey darken-3">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name      : 'LoginLayout',
  components: {},
  mixins    : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {}
}

</script>

<style scoped>

</style>
