<template>
  <pre
    v-if="source"
    ref="source"
    class="source-code-viewer"
    contenteditable="true"
    @blur="onSourceChange"
  >
    {{ sourcePrettified }}
  </pre>
</template>

<script>

export default {
  name    : 'SourceCodeViewer',
  // eslint-disable-next-line vue/require-prop-types
  props   : ['source'],
  computed: {
    sourcePrettified () {
      return (this.source || '')
    }
  },
  methods: {
    onSourceChange () {
      this.$emit('change', this.$refs.source.textContent)
    }
  }
}
</script>

<style scoped>
.source-code-viewer {
  overflow    : auto;
  font-family : monospace;
  word-break  : break-all;
  white-space : pre-wrap;
  border      : 1px solid;
  padding     : 12px;
}
</style>
