import axios from 'axios'

export const MapBoxGeocoderProvider = (options = {}) => {
  const defaultOptions = {
    autocomplete: true,
    serviceUrl  : 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
    accessToken : '',
    language    : 'el',
    forwardTypes: ['address'],
    reverseTypes: ['country', 'region', 'postcode', 'district', 'place', 'locality', 'neighborhood', 'address'],
    country     : 'gr'
  }
  options = { ...defaultOptions, ...options }

  const validTypes = ['geocode', 'address', 'city', 'postcode']
  const mapBoxValidTypes = ['address', 'address', 'place', 'postcode']
  options.forwardTypes = options.forwardTypes.map(type => {
    const index = validTypes.findIndex(item => item === type)
    return mapBoxValidTypes[index] || type
  })

  // eslint-disable-next-line no-unused-vars
  const geocodeSearch = async (query) => {
    try {
      const result = await axios.get(`${ options.serviceUrl }${ encodeURIComponent(query) }.json`, {
        withCredentials: false,
        params         : {
          access_token: options.accessToken,
          autocomplete: options.autocomplete,
          country     : options.country,
          language    : options.language,
          types       : options.forwardTypes.join(',')
        }
      })

      const places = result?.data?.features || []

      if (!options.autocomplete) return places.length ? getProperties(places[0]) : null

      return places.map(place => getProperties(place))
    } catch (e) {
      if (!options.autocomplete) return null
      return []
    }
  }

  const placeDetails = async (address) => {
    return null
  }

  const addressDetails = async (address) => {
    try {
      const result = await axios.get(`${ options.serviceUrl }${ encodeURIComponent(address) }.json`, {
        withCredentials: false,
        params         : {
          access_token: options.accessToken,
          autocomplete: options.autocomplete,
          country     : options.country,
          language    : options.language,
          types       : options.forwardTypes.join(',')
        }
      })

      const places = result?.data?.features || []
      return places.length ? getProperties(places[0]) : null
    } catch (e) {
      return null
    }
  }

  // eslint-disable-next-line no-unused-vars
  const geocodeReverse = async (location) => {
    try {
      const result = await axios.get(`${ options.serviceUrl }${ location.lng },${ location.lat }.json`, {
        withCredentials: false,
        params         : {
          access_token: options.accessToken,
          country     : options.country,
          language    : options.language,
          types       : options.reverseTypes.join(',')
        }
      })

      const places = result?.data?.features || []
      return places.length ? getProperties(places[0]) : getProperties(null, location)
    } catch (e) {
      return null
    }
  }

  const getProperties = (place, latLng) => {
    return {
      Provider: 'mapbox',
      Id      : place?.id || '',
      Name    : place?.place_name || '',
      Center  : place?.center || latLng ? [place?.center[0] || latLng?.lng || -1, place?.center[1] || latLng?.lat || -1] : [] || [],
      LatLng  : {
        lng: place?.center[0] || latLng?.lng || -1,
        lat: place?.center[1] || latLng?.lat || -1
      },
      Properties: {
        Number     : place?.address || '',
        Postcode   : place?.context?.find(item => item.id.startsWith('postcode'))?.text || '',
        City       : place?.context?.find(item => item.id.startsWith('place'))?.text || '',
        Street     : place?.text || '',
        Country    : place?.context?.find(item => item.id.startsWith('country'))?.text || '',
        CountryCode: place?.context?.find(item => item.id.startsWith('country'))?.short_code || ''
      },
      Raw: place
    }
  }

  return {
    geocodeSearch,
    geocodeReverse,
    placeDetails,
    addressDetails
  }
}
