<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Documents.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Documents.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Documents.Toolbar.Title') }}
        </div>
        <div class="text-caption">
          {{ $t('Settings.Documents.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-expansion-panels
          v-model="expansionPanel"
          focusable
          flat
        >
          <!-- Order Document -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex justify-start align-center">
                <v-icon class="pr-3">
                  {{ $t('Settings.Documents.OrderDocument.Icon') }}
                </v-icon>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Documents.OrderDocument.Title') }}
                </h5>
              </div>
            </v-expansion-panel-header>

            <v-divider />

            <v-expansion-panel-content>
              <v-container
                fluid
                class="pa-0 py-2"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowCategories.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowCategories.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.ShowCategories"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.ShowCategories ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowMap.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowMap.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderReceipt.ShowMap.Hint') }}
                      <router-link :to="{name: 'SystemSettingsDelivery'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.PrintMap"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.PrintMap ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.MapWidth.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.MapWidth.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderDocument.MapWidth"
                      :disabled="!userCanManage"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="mm"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowQRCode.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowQRCode.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderDocument.ShowQRCode.Hint') }}
                      <router-link :to="{name: 'SystemSettingsDelivery'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.PrintQR"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.PrintQR ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.QrWidth.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.QrWidth.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderDocument.QrWidth"
                      :disabled="!userCanManage"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="mm"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowBarcode.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowBarcode.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.PrintBarcode"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.PrintBarcode ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowPrintSourceType.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowPrintSourceType.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.ShowPrintSourceType"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.ShowPrintSourceType ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.RespectWorkingHours.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.RespectWorkingHours.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.RespectWorkingHours"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.RespectWorkingHours ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowTaxes.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowTaxes.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.ShowTaxes"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.ShowTaxes ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowPrintOrderDailyNumber.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowPrintOrderDailyNumber.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.ShowPrintDailyCheck"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.ShowPrintDailyCheck ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowPrintNamesUppercase.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowPrintNamesUppercase.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.ShowPrintNamesUppercase"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.ShowPrintNamesUppercase ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.ShowProductNumber.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.ShowProductNumber.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderDocument.ShowProductNumber"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderDocument.ShowProductNumber ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.MaxWidth.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.MaxWidth.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderDocument.MaxWidth.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderDocument.MaxWidth"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.MaxWidth')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="mm"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderDocument.FontSize.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderDocument.FontSize.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderDocument.FontSize.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderDocument.FontSize"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.FontSize')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="pt"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Preparation Document -->
          <v-expansion-panel :readonly="!viewData.PreparationDocument.Enabled">
            <v-expansion-panel-header :hide-actions="!viewData.PreparationDocument.Enabled">
              <div class="d-flex justify-start align-center">
                <v-container
                  fluid
                  class="pa-0"
                >
                  <v-row
                    dense
                    align="center"
                  >
                    <v-col>
                      <v-icon class="pr-3">
                        {{ $t('Settings.Documents.PreparationDocument.Icon') }}
                      </v-icon>
                      <h5 class="d-inline-block text-body-2 font-weight-bold">
                        {{ $t('Settings.Documents.PreparationDocument.Title') }}
                      </h5>
                    </v-col>

                    <v-col class="shrink text-no-wrap">
                      <v-switch
                        v-model="viewData.PreparationDocument.Enabled"
                        inset
                        :disabled="!userCanManage"
                        :label="viewData.PreparationDocument.Enabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        color="success"
                        class="ma-0 mr-3 text-no-wrap"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-expansion-panel-header>

            <v-divider />

            <v-expansion-panel-content>
              <v-container
                fluid
                class="pa-0 py-2"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.PreparationDocument.ShowItemPrice.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.PreparationDocument.ShowItemPrice.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.PreparationDocument.ShowItemPrice"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.PreparationDocument.ShowItemPrice ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.PreparationDocument.ShowCategories.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.PreparationDocument.ShowCategories.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.PreparationDocument.ShowCategories"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.PreparationDocument.ShowCategories ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.PreparationDocument.ShowPrintNamesUppercase.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.PreparationDocument.ShowPrintNamesUppercase.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.PreparationDocument.ShowPrintNamesUppercase"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.PreparationDocument.ShowPrintNamesUppercase ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.PreparationDocument.ShowProductNumber.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.PreparationDocument.ShowProductNumber.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.PreparationDocument.ShowProductNumber"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.PreparationDocument.ShowProductNumber ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.PreparationDocument.MaxWidth.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.PreparationDocument.MaxWidth.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.PreparationDocument.MaxWidth.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.PreparationDocument.MaxWidth"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.MaxWidth')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="mm"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.PreparationDocument.FontSize.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.PreparationDocument.FontSize.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.PreparationDocument.FontSize.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.PreparationDocument.FontSize"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.FontSize')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="pt"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Order Receipt -->
          <v-expansion-panel v-if="receiptsActive">
            <v-expansion-panel-header>
              <div class="d-flex justify-start align-center">
                <v-container
                  fluid
                  class="pa-0"
                >
                  <v-row
                    align="center"
                  >
                    <v-col>
                      <v-icon class="pr-3">
                        {{ $t('Settings.Documents.OrderReceipt.Icon') }}
                      </v-icon>
                      <h5 class="d-inline-block text-body-2 font-weight-bold">
                        {{ $t('Settings.Documents.OrderReceipt.Title') }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-expansion-panel-header>

            <v-divider />

            <v-expansion-panel-content>
              <v-container
                fluid
                class="pa-0 py-2"
              >
                <!-- Receipt -->
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Receipt') }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.Enabled"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.Enabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0 text-no-wrap"
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.Series.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.Series.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.Series"
                      :disabled="viewData.OrderReceipt.HasReceipts"
                      :hide-details="viewData.OrderReceipt.HasReceipts"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.TotalCheckInit.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.TotalCheckInit.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.TotalCheckInit"
                      :disabled="viewData.OrderReceipt.HasReceipts"
                      :hide-details="viewData.OrderReceipt.HasReceipts"
                      :rules="[(v) => String(v).length > 0 && parseInt(v) >= 0 && String(v).match(/^\d+$/i) !== null || $t('Settings.Documents.OrderReceipt.TotalCheckInit.Error.InvalidNumber')]"
                      type="number"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                      @focusout="onTotalReceiptCheckInitFocusOut"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.Notify.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.Notify.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn-toggle
                      v-model="viewData.OrderReceipt.Notify"
                      color="success"
                      multiple
                    >
                      <v-btn
                        text
                        value="print"
                      >
                        {{ $t('Settings.Documents.OrderReceipt.Notify.Print').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>

                      <v-btn
                        text
                        value="email"
                        :disabled="!appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
                      >
                        {{ $t('Settings.Documents.OrderReceipt.Notify.Email').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.AutoGenerate.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.AutoGenerate.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.AutoGenerate"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.AutoGenerate ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0 text-no-wrap"
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />

                  <template v-if="viewData.OrderReceipt.AutoGenerate">
                    <v-col cols="12">
                      <v-simple-table>
                        <template #default>
                          <thead>
                            <tr>
                              <th
                                class="text-left"
                                style="width: 200px;"
                              >
                                {{ $t('Order.SourceType.Title') }}
                              </th>

                              <th
                                class="text-left"
                                style="width: 280px;"
                              >
                                {{ $t('Order.Type.Title') }}
                              </th>

                              <th class="text-left">
                                {{ $t('Common.Button.Toggle.ActiveF') }}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <template v-for="(sourceType, sourceTypeIdx) in viewData.OrderReceipt.SourcesAutoGenerate">
                              <tr
                                v-if="(sourceType.id === 0 && sourceType.active) || (!viewData.OrderReceipt.SourcesAutoGenerate[0].active)"
                                :key="`source-type-${sourceTypeIdx}`"
                              >
                                <td class="pa-2">
                                  <v-chip
                                    label
                                    :color="getSourceTypeById(sourceType.id).Status ? 'green' : ''"
                                    :dark="getSourceTypeById(sourceType.id).Status"
                                    class="pl-0"
                                    style="width: 180px; height: 40px;"
                                  >
                                    <v-avatar
                                      tile
                                      :color="getSourceTypeById(sourceType.id).Avatar.Color"
                                      style="width: 40px !important; height: 40px !important;"
                                      class="mr-2"
                                    >
                                      <v-icon
                                        v-if="getSourceTypeById(sourceType.id).Avatar.Icon"
                                        class="white--text"
                                      >
                                        {{ getSourceTypeById(sourceType.id).Avatar.Icon }}
                                      </v-icon>

                                      <img
                                        v-else
                                        :src="getSourceTypeById(sourceType.id).Avatar.Img"
                                      >
                                    </v-avatar>
                                    <span>{{ getSourceTypeById(sourceType.id).Name }}</span>
                                  </v-chip>
                                </td>

                                <td class="pa-2">
                                  <v-select
                                    v-model="sourceType.orderType"
                                    :disabled="sourceType.id === 0"
                                    :items="orderTypesListFiltered"
                                    :label="$t('Restaurant.CatalogAddDialog.Field.OrderType.Label')"
                                    style="max-width: 260px;"
                                    class="pt-0"
                                    item-text="Name"
                                    item-value="Id"
                                    single-line
                                    hide-details
                                    outlined
                                    dense
                                  >
                                    <template #selection="{item}">
                                      <v-chip
                                        small
                                        label
                                        :color="item.Status ? 'green' : ''"
                                        :dark="item.Status"
                                        class="pl-0"
                                        style="width: 100%;"
                                      >
                                        <v-avatar
                                          tile
                                          :color="item.Color"
                                          class="mr-2"
                                        >
                                          <v-icon class="white--text">
                                            {{ item.Icon }}
                                          </v-icon>
                                        </v-avatar>
                                        <span>{{ item.Name }}</span>
                                      </v-chip>
                                    </template>

                                    <template #item="data">
                                      <v-list-item-avatar :color="data.item.Color">
                                        <v-icon dark>
                                          {{ data.item.Icon }}
                                        </v-icon>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </td>

                                <td class="pa-2">
                                  <v-switch
                                    v-model="sourceType.active"
                                    :disabled="!userCanManage"
                                    color="success"
                                    class="ma-0 pa-0 d-inline-flex"
                                    hide-details
                                    inset
                                    @change="onSourceOrderTypeChange(sourceType)"
                                  />
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </template>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <!-- Receipt Credit -->
                <v-row
                  dense
                  align="center"
                >
                  <v-col cols="12">
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.ReceiptCredit') }}
                    </h3>
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.SeriesCredit.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.SeriesCredit.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.CreditSeries"
                      :disabled="viewData.OrderReceipt.HasReceiptsCredit"
                      :hide-details="viewData.OrderReceipt.HasReceiptsCredit"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.TotalCheckInitCredit.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.TotalCheckInitCredit.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.TotalCreditCheckInit"
                      :disabled="viewData.OrderReceipt.HasReceiptsCredit"
                      :hide-details="viewData.OrderReceipt.HasReceiptsCredit"
                      :rules="[(v) => String(v).length > 0 && parseInt(v) >= 0 && String(v).match(/^\d+$/i) !== null || $t('Settings.Documents.OrderReceipt.TotalCheckInitCredit.Error.InvalidNumber')]"
                      type="number"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                      @focusout="onTotalCreditReceiptCheckInitFocusOut"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <!-- Invoice -->
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Invoice') }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.InvoiceEnabled"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.InvoiceEnabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0 text-no-wrap"
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceSeries.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceSeries.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.InvoiceSeries"
                      :disabled="viewData.OrderReceipt.HasInvoices"
                      :hide-details="viewData.OrderReceipt.HasInvoices"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceTotalCheckInit.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceTotalCheckInit.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.InvoiceTotalCheckInit"
                      :disabled="viewData.OrderReceipt.HasInvoices"
                      :hide-details="viewData.OrderReceipt.HasInvoices"
                      :rules="[(v) => String(v).length > 0 && parseInt(v) >= 0 && String(v).match(/^\d+$/i) !== null || $t('Settings.Documents.OrderReceipt.InvoiceTotalCheckInit.Error.InvalidNumber')]"
                      type="number"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                      @focusout="onTotalInvoiceCheckInitFocusOut"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceNotify.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceNotify.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn-toggle
                      v-model="viewData.OrderReceipt.InvoiceNotify"
                      color="success"
                      multiple
                    >
                      <v-btn
                        text
                        value="print"
                      >
                        {{ $t('Settings.Documents.OrderReceipt.InvoiceNotify.Print').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>

                      <v-btn
                        text
                        value="email"
                        :disabled="!appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
                      >
                        {{ $t('Settings.Documents.OrderReceipt.InvoiceNotify.Email').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <!-- Invoice Credit -->
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.InvoiceCredit') }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.InvoiceEnabled"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.InvoiceEnabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0 text-no-wrap"
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceSeriesCredit.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceSeriesCredit.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.InvoiceCreditSeries"
                      :disabled="viewData.OrderReceipt.HasInvoicesCredit"
                      :hide-details="viewData.OrderReceipt.HasInvoicesCredit"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceTotalCheckInitCredit.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.InvoiceTotalCheckInitCredit.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.InvoiceCreditTotalCheckInit"
                      :disabled="viewData.OrderReceipt.HasInvoicesCredit"
                      :hide-details="viewData.OrderReceipt.HasInvoicesCredit"
                      :rules="[(v) => String(v).length > 0 && parseInt(v) >= 0 && String(v).match(/^\d+$/i) !== null || $t('Settings.Documents.OrderReceipt.InvoiceTotalCheckInitCredit.Error.InvalidNumber')]"
                      type="number"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                      @focusout="onTotalInvoiceCreditCheckInitFocusOut"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <!-- FnB -->
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Fnb') }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.FnbEnabled"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.FnbEnabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0 text-no-wrap"
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FnbSeries.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FnbSeries.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.FnbSeries"
                      :disabled="viewData.OrderReceipt.HasFnb"
                      :hide-details="viewData.OrderReceipt.HasFnb"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FnbTotalCheckInit.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FnbTotalCheckInit.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.FnbTotalCheckInit"
                      :disabled="viewData.OrderReceipt.HasFnb"
                      :hide-details="viewData.OrderReceipt.HasFnb"
                      :rules="[(v) => String(v).length > 0 && parseInt(v) >= 0 && String(v).match(/^\d+$/i) !== null || $t('Settings.Documents.OrderReceipt.FnbTotalCheckInit.Error.InvalidNumber')]"
                      type="number"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                      @focusout="onTotalFnbCheckInitFocusOut"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FnbAutoGenerate.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FnbAutoGenerate.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.FnbAutoGenerate"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.FnbAutoGenerate ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0 text-no-wrap"
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0"
                  />
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <!-- FnB Cancel -->
                <v-row
                  dense
                  align="center"
                >
                  <v-col cols="12">
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.FnbCancel') }}
                    </h3>
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FnbSeriesCancel.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FnbSeriesCancel.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.FnbCancelSeries"
                      :disabled="viewData.OrderReceipt.HasFnbCancel"
                      :hide-details="viewData.OrderReceipt.HasFnbCancel"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FnbTotalCheckInitCancel.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FnbTotalCheckInitCancel.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.FnbCancelTotalCheckInit"
                      :disabled="viewData.OrderReceipt.HasFnbCancel"
                      :hide-details="viewData.OrderReceipt.HasFnbCancel"
                      :rules="[(v) => String(v).length > 0 && parseInt(v) >= 0 && String(v).match(/^\d+$/i) !== null || $t('Settings.Documents.OrderReceipt.FnbTotalCheckInitCancel.Error.InvalidNumber')]"
                      type="number"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                      @focusout="onTotalFnbCreditCheckInitFocusOut"
                    />
                  </v-col>
                </v-row>

                <!-- FnB Return -->
                <v-row
                  dense
                  align="center"
                >
                  <v-col cols="12">
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.FnbReturn') }}
                    </h3>
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FnbSeriesReturn.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FnbSeriesReturn.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.FnbReturnSeries"
                      :disabled="viewData.OrderReceipt.HasFnbReturn"
                      :hide-details="viewData.OrderReceipt.HasFnbReturn"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FnbTotalCheckInitReturn.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FnbTotalCheckInitReturn.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.FnbReturnTotalCheckInit"
                      :disabled="viewData.OrderReceipt.HasFnbReturn"
                      :hide-details="viewData.OrderReceipt.HasFnbReturn"
                      :rules="[(v) => String(v).length > 0 && parseInt(v) >= 0 && String(v).match(/^\d+$/i) !== null || $t('Settings.Documents.OrderReceipt.FnbTotalCheckInitReturn.Error.InvalidNumber')]"
                      type="number"
                      outlined
                      required
                      single-line
                      dense
                      style="min-width: 100px; max-width: 100px;"
                      @focusout="onTotalFnbReturnCheckInitFocusOut"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col cols="12">
                    <h3 class="grey--text text--darken-1 my-2">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Misc') }}
                    </h3>
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.ShowCategories.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.ShowCategories.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.ShowCategories"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.ShowCategories ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.ShowMap.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.ShowMap.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderReceipt.ShowMap.Hint') }}
                      <router-link :to="{name: 'SystemSettingsDelivery'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.ShowMap"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.ShowMap ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <template v-if="viewData.OrderReceipt.ShowMap">
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                    />

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Documents.OrderReceipt.MapWidth.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Documents.OrderReceipt.MapWidth.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-text-field
                        v-model="viewData.OrderReceipt.MapWidth"
                        :disabled="!userCanManage"
                        class="elevation-0 d-inline-block ml-2"
                        hide-details
                        required
                        outlined
                        single-line
                        style="min-width: 100px; max-width: 100px;"
                        suffix="mm"
                        type="number"
                        min="0"
                        step="1"
                        dense
                      />
                    </v-col>
                  </template>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.ShowQRCode.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.ShowQRCode.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderReceipt.ShowQRCode.Hint') }}
                      <router-link :to="{name: 'SystemSettingsDelivery'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.ShowMapQr"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.ShowMapQr ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <template v-if="viewData.OrderReceipt.ShowMapQr">
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                    />

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Documents.OrderReceipt.QrWidth.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Documents.OrderReceipt.QrWidth.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-text-field
                        v-model="viewData.OrderReceipt.QrWidth"
                        :disabled="!userCanManage"
                        class="elevation-0 d-inline-block ml-2"
                        hide-details
                        required
                        outlined
                        single-line
                        style="min-width: 100px; max-width: 100px;"
                        suffix="mm"
                        type="number"
                        min="0"
                        step="1"
                        dense
                      />
                    </v-col>
                  </template>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.ShowCustomer.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.ShowCustomer.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.ShowCustomer"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.ShowCustomer ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.ShowOrderType.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.ShowOrderType.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.ShowOrderType"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.ShowOrderType ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.ShowPayment.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.ShowPayment.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.ShowPayment"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.ShowPayment ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.RespectWorkingHours.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.RespectWorkingHours.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderReceipt.RespectWorkingHours"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderReceipt.RespectWorkingHours ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.MaxWidth.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.MaxWidth.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderReceipt.MaxWidth.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.MaxWidth"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.MaxWidth')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="mm"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.OrderReceipt.FontSize.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.OrderReceipt.FontSize.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.OrderReceipt.FontSize.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.OrderReceipt.FontSize"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.FontSize')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="pt"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Totals Document -->
          <v-expansion-panel v-if="posUserHasComponentPermission('RECEIPTS', 'MANAGE')">
            <v-expansion-panel-header>
              <div class="d-flex justify-start align-center">
                <v-icon class="pr-3">
                  {{ $t('Settings.Documents.TotalsDocument.Icon') }}
                </v-icon>
                <h5 class="d-inline-block text-body-2 font-weight-bold">
                  {{ $t('Settings.Documents.TotalsDocument.Title') }}
                </h5>
              </div>
            </v-expansion-panel-header>

            <v-divider />

            <v-expansion-panel-content>
              <v-container
                fluid
                class="pa-0 py-2"
              >
                <v-row
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.TotalsDocument.MaxWidth.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.TotalsDocument.MaxWidth.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.TotalsDocument.MaxWidth.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.TotalsDocument.MaxWidth"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.MaxWidth')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="mm"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.TotalsDocument.FontSize.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.TotalsDocument.FontSize.Subtitle') }}
                    </div>
                    <div class="text-caption grey--text">
                      {{ $t('Settings.Documents.TotalsDocument.FontSize.Hint') }}
                      <router-link :to="{name: 'SystemSettingsPrinters'}">
                        {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                      </router-link>
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.TotalsDocument.FontSize"
                      :disabled="!userCanManage"
                      :label="$t('Settings.Printer.Header.FontSize')"
                      class="elevation-0 d-inline-block ml-2"
                      hide-details
                      required
                      outlined
                      single-line
                      style="min-width: 100px; max-width: 100px;"
                      suffix="pt"
                      type="number"
                      min="0"
                      step="1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Delivery Staff Document -->
          <v-expansion-panel v-if="posUserHasComponentPermission('DeliveryStaff', 'MANAGE')">
            <v-expansion-panel-header>
              <div class="d-flex justify-start align-center">
                <v-icon class="pr-3">
                  {{ $t('Settings.Documents.DeliveryStaffDocument.Icon') }}
                </v-icon>
                <h5 class="d-inline-block text-body-2 font-weight-bold">
                  {{ $t('Settings.Documents.DeliveryStaffDocument.Title') }}
                </h5>
              </div>
            </v-expansion-panel-header>

            <v-divider />

            <v-expansion-panel-content>
              <v-container
                fluid
                class="pa-0 py-2"
              >
                <v-row
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Documents.DeliveryStaffDocument.PrintDeliveryStaffShiftWithOrders.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Documents.DeliveryStaffDocument.PrintDeliveryStaffShiftWithOrders.Subtitle') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.DeliveryStaffDocument.PrintDeliveryStaffShiftWithOrders"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.DeliveryStaffDocument.PrintDeliveryStaffShiftWithOrders ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'
import Translatable   from '@/mixins/translatable'
import NotifyTypeEnum from '@/api/Enums/NotifyTypeEnum'
import SourceType     from '@/mixins/orders/sourceType'
import { clone }      from '@/lib/helper/helper'

export default {
  name      : 'SystemSettingsDocuments',
  components: {},
  mixins    : [AppData, Auth, Translatable, SourceType],
  data () {
    return {
      menuOrdersDailyOpenVisible : false,
      menuOrdersDailyCloseVisible: false,
      isSaving                   : false,
      viewData                   : null,
      expansionPanel             : null
    }
  },
  computed: {
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },

    receiptsActive () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsDomainEnabled &&
        this.posUserHasComponentPermission('RECEIPTS', 'MANAGE')
    },

    activePaymentMethods () {
      return this.orderBy(this.viewData?.Payments || [], 'Site')
    },

    sourceTypesListFiltered () {
      let sourceTypesAll = this.appConfig?.SOURCE_TYPES?.DEFAULT?.ALL || {}
      let sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})
      let sourceTypesOther = this.appConfig?.SOURCE_TYPES?.OTHER || []

      sourceTypesAll = [{
        Id         : sourceTypesAll.source_type_id,
        Name       : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Title || sourceTypesAll.title,
        Description: this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Desc || sourceTypesAll.desc,
        Avatar     : {
          Icon : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Icon || sourceTypesAll.icon,
          Color: 'green'
        }
      }]

      sourceTypesDefault = sourceTypesDefault.filter(sourceType => !sourceType.deleted_at && sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })

      sourceTypesOther = sourceTypesOther.filter(sourceType => !sourceType.deleted_at && sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : o.title,
          Description: o.desc,
          Avatar     : {
            Img  : o.logo,
            Color: 'blue-grey'
          }
        }
      })

      return [...sourceTypesAll, ...sourceTypesDefault, ...sourceTypesOther]
    },

    orderTypesListFiltered () {
      return this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Items')
    }
  },
  watch: {
    'viewData.PreparationDocument.Enabled': function (newVal) {
      if (!newVal) this.expansionPanel = null
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Documents.Get, this.onGetDataResponse)
    this.$bus.$on(window.SocketCommand.Settings.Documents.Save, this.onSaveDataResponse)
    this.getData()
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Documents.Get, this.onGetDataResponse)
    this.$bus.$off(window.SocketCommand.Settings.Documents.Save, this.onSaveDataResponse)
  },
  methods: {
    onTotalReceiptCheckInitFocusOut () {
      this.viewData.OrderReceipt.TotalCheckInit = parseInt(this.viewData.OrderReceipt.TotalCheckInit).toString()
    },
    onTotalCreditReceiptCheckInitFocusOut () {
      this.viewData.OrderReceipt.TotalCreditCheckInit = parseInt(this.viewData.OrderReceipt.TotalCreditCheckInit).toString()
    },
    onTotalInvoiceCheckInitFocusOut () {
      this.viewData.OrderReceipt.InvoiceTotalCheckInit = parseInt(this.viewData.OrderReceipt.InvoiceTotalCheckInit).toString()
    },
    onTotalInvoiceCreditCheckInitFocusOut () {
      this.viewData.OrderReceipt.InvoiceCreditTotalCheckInit = parseInt(this.viewData.OrderReceipt.InvoiceCreditTotalCheckInit).toString()
    },
    onTotalFnbCheckInitFocusOut () {
      this.viewData.OrderReceipt.FnbTotalCheckInit = parseInt(this.viewData.OrderReceipt.FnbTotalCheckInit).toString()
    },
    onTotalFnbCreditCheckInitFocusOut () {
      this.viewData.OrderReceipt.FnbCreditTotalCheckInit = parseInt(this.viewData.OrderReceipt.FnbCreditTotalCheckInit).toString()
    },
    onTotalFnbReturnCheckInitFocusOut () {
      this.viewData.OrderReceipt.FnbReturnTotalCheckInit = parseInt(this.viewData.OrderReceipt.FnbReturnTotalCheckInit).toString()
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.Documents.Get, {})
    },

    onSourceOrderTypeChange (sourceType) {
      if (sourceType.id === 0 && !sourceType.active) {
        const sources = clone(this.viewData.OrderReceipt.SourcesAutoGenerate)
        for (let i = 0; i < sources.length; i++) {
          if (sources[i].id > 0) sources[i].active = false
        }

        this.viewData.OrderReceipt.SourcesAutoGenerate = sources
      }
    },

    onGetDataResponse (result) {
      const sourcesAutoGenerate = result?.OrderReceipt?.SourcesAutoGenerate || []

      result.OrderReceipt.SourcesAutoGenerate = this.sourceTypesListFiltered.map(defaultSourceType => {
        const sourceType = sourcesAutoGenerate.find(sourceType => sourceType.id === defaultSourceType.Id)
        return {
          id       : defaultSourceType.Id,
          orderType: defaultSourceType.Id === 0 ? 0 : sourceType?.orderType ?? 0,
          active   : sourceType?.active ?? false
        }
      })
      this.viewData = result
    },

    getSourceTypeById (id) {
      return this.sourceTypesListFiltered.find(sourceType => parseInt(sourceType.Id) === parseInt(id))
    },

    onSaveClick () {
      this.isSaving = true
      this.onTotalReceiptCheckInitFocusOut()
      this.onTotalInvoiceCheckInitFocusOut()
      this.onTotalFnbCheckInitFocusOut()
      window.callAS(window.SocketCommand.Settings.Documents.Save, this.viewData)
    },

    onSaveDataResponse (result) {
      this.isSaving = false

      if (result.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Documents.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
