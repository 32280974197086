import ReceiptTypeEnum  from '@/api/Enums/ReceiptTypeEnum'
import Printer          from '@/lib/helper/printer'
import AppData          from '@/mixins/appdata'
import { emailIsGuest } from '@/lib/helper/helper'
import isElectron       from '@/electron/isElectron'
import Auth             from '@/mixins/auth'

export default {
  mixins: [Auth, AppData],

  data () {
    return {
      selectedReceiptItem                : null,
      confirmCancelFiscalDocDialogVisible: false,
      customerSelectEmailDialogVisible   : false,
      customerSelectEmailDialogData      : null,
      customerSelectCompanyDialogVisible : false,
      customerSelectCompanyDialogData    : null
    }
  },

  created () {
    this.$bus.$on('fiscal-document:view', this.viewFiscalDocument)
    this.$bus.$on('fiscal-document:create', this.createFiscalDocument)
    this.$bus.$on('fiscal-document:cancel', this.confirmCancelFiscalDocument)
    this.$bus.$on('fiscal-document:print', this.printFiscalDocument)
    this.$bus.$on('fiscal-document:email', this.emailFiscalDocument)
    this.$bus.$on('fiscal-document:retry', this.retryReceipt)

    this.$bus.$on(window.SocketCommand.Receipt.Generate, this.issueFiscalDocumentResult)
    this.$bus.$on(window.SocketCommand.Receipt.NotifyPrint, this.printFiscalDocumentResult)
    this.$bus.$on(window.SocketCommand.Receipt.NotifyEmail, this.emailFiscalDocumentResult)
    this.$bus.$on(window.SocketCommand.Receipt.Cancel, this.cancelFiscalDocumentResult)
    this.$bus.$on(window.SocketCommand.Receipt.Retry, this.onRetryReceiptResponse)
    this.$bus.$on(window.SocketCommand.Customer.Companies, this.getCustomerCompaniesResult)
    this.$bus.$on(window.SocketCommand.Settings.Misc.Countries, this.getCountriesResult)
  },

  beforeDestroy () {
    this.$bus.$off('fiscal-document:view', this.viewFiscalDocument)
    this.$bus.$off('fiscal-document:create', this.createFiscalDocument)
    this.$bus.$off('fiscal-document:cancel', this.confirmCancelFiscalDocument)
    this.$bus.$off('fiscal-document:print', this.printFiscalDocument)
    this.$bus.$off('fiscal-document:email', this.emailFiscalDocument)
    this.$bus.$off('fiscal-document:retry', this.retryReceipt)

    this.$bus.$off(window.SocketCommand.Receipt.Generate, this.issueFiscalDocumentResult)
    this.$bus.$off(window.SocketCommand.Receipt.NotifyPrint, this.printFiscalDocumentResult)
    this.$bus.$off(window.SocketCommand.Receipt.NotifyEmail, this.emailFiscalDocumentResult)
    this.$bus.$off(window.SocketCommand.Receipt.Cancel, this.cancelFiscalDocumentResult)
    this.$bus.$off(window.SocketCommand.Receipt.Retry, this.onRetryReceiptResponse)
    this.$bus.$off(window.SocketCommand.Customer.Companies, this.getCustomerCompaniesResult)
    this.$bus.$off(window.SocketCommand.Settings.Misc.Countries, this.getCountriesResult)
  },

  computed: {
    selectedOrderHasRefundableTransactions () {
      return this.orderHasRefundableTransactions(this.selectedReceiptItem)
    },
    selectedOrderRefundableTransactionsTotal () {
      return this.orderRefundableTransactionsTotal(this.selectedReceiptItem)
    }
  },

  methods: {
    viewFiscalDocument (docUrl) {
      if (!docUrl) return

      window.open(docUrl, '_blank')
    },

    createFiscalDocument (order, docType, data = null, command = window.SocketCommand.Receipt.Generate) {
      if (docType === ReceiptTypeEnum.INVOICE) {
        this.customerSelectCompanyDialogData = {
          companies: [],
          countries: [],
          order    : order,
          docType  : docType,
          data     : data,
          command  : command
        }

        window.callAS(window.SocketCommand.Settings.Misc.Countries, {})

        if (order?.CustomerId) {
          window.callAS(window.SocketCommand.Customer.Companies, { id: order.CustomerId })
        } else {
          this.customerSelectCompanyDialogVisible = true
        }
      } else {
        this.issueFiscalDocument(order, docType, data)
      }
    },
    getCountriesResult (result) {
      if (!Array.isArray(result)) return

      this.customerSelectCompanyDialogData.countries = result || []
    },
    getCustomerCompaniesResult (result) {
      this.customerSelectCompanyDialogData.companies = Array.isArray(result) ? result : []
      this.customerSelectCompanyDialogVisible = true
    },
    createFiscalDocumentFromInvoice (company, payload) {
      this.$bus.$emit('invoice-company:selected', company)

      if (!payload) payload = {}
      if (!payload?.data) payload.data = {}
      payload.data.Company = company

      this.issueFiscalDocument(payload.order, payload.docType, payload.data, payload.command)
    },
    issueFiscalDocument (order, docType, data = null, command = window.SocketCommand.Receipt.Generate) {
      if ((!order?.Id && (!order?.TableId || !order?.CartId || !order?.Timestamp)) || !docType || (docType === ReceiptTypeEnum.INVOICE && !data?.Company)) return

      const emailAddresses = data?.EmailAddresses ?? []
      if (order?.Email && !emailIsGuest(order?.Email) && !emailAddresses.includes(order.Email)) emailAddresses.push(order.Email)
      const emailReceipt = data?.Email ?? this.ReceiptsEmailNotify ? !!emailAddresses.length : false
      const printReceipt = data?.Print ?? this.ReceiptsPrintNotify

      if (order?.TableId && order?.CartId && order?.Timestamp) {
        // Issue Doc from Table Cart
        window.callAS(window.SocketCommand.Receipt.Generate, {
          TableId       : order.TableId,
          CartId        : order.CartId,
          Timestamp     : order.Timestamp,
          Print         : printReceipt,
          Email         : emailReceipt,
          EmailAddresses: emailReceipt ? emailAddresses : [],
          Type          : docType,
          Company       : data?.Company || null
        }, command, 20000)
      } else {
        // Issue Doc from Order
        window.callAS(window.SocketCommand.Receipt.Generate, {
          Id            : order.Id,
          Print         : printReceipt,
          Email         : emailReceipt,
          EmailAddresses: emailReceipt ? emailAddresses : [],
          Type          : docType,
          Company       : data?.Company || null
        }, command, 20000)
      }
    },
    issueFiscalDocumentResult (result, payload) {
      if (result?.status === 'success') {
        this.$bus.$emit(window.SocketCommand.Receipt.Update, payload)

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.IssueReceipt.Success'),
          type: 'success',
          icon: 'check'
        })

        this.customerSelectCompanyDialogData = null
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Misc.Notification.IssueReceipt.Error'),
          type   : 'error',
          icon   : 'warning',
          timeout: 6000
        })
      }
    },

    printFiscalDocument (receiptId) {
      if (!receiptId) return

      if (isElectron() || this.userIsWaiter) {
        window.callAS(window.SocketCommand.Receipt.Notify, {
          Id            : receiptId,
          Print         : true,
          Email         : false,
          EmailAddresses: []
        }, window.SocketCommand.Receipt.NotifyPrint, 20000)
      } else {
        Printer.printReceipt(receiptId)
      }
    },
    printFiscalDocumentResult (result, payload) {
      if (result?.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.PrintReceipt.Success'),
          type: 'info',
          icon: 'print'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Misc.Notification.PrintReceipt.Error'),
          type   : 'error',
          icon   : 'warning',
          timeout: 6000
        })
      }
    },

    emailFiscalDocument (receiptId, order = null, data = null) {
      if (!receiptId) return

      this.customerSelectEmailDialogData = {
        receiptId: receiptId,
        order    : order,
        data     : data
      }

      this.customerSelectEmailDialogVisible = true
    },
    emailFiscalDocumentConfirm () {
      const {
        receiptId,
        order,
        data
      } = this.customerSelectEmailDialogData

      const emailAddresses = data?.EmailAddresses ?? []
      if (order?.Email && !emailIsGuest(order?.Email) && !emailAddresses.includes(order.Email)) emailAddresses.push(order.Email)
      const emailReceipt = data?.Email ?? this.ReceiptsEmailNotify ? !!emailAddresses.length : false
      if (!emailReceipt) return

      window.callAS(window.SocketCommand.Receipt.Notify, {
        Id            : receiptId,
        Print         : false,
        Email         : emailReceipt,
        EmailAddresses: emailReceipt ? emailAddresses : []
      }, window.SocketCommand.Receipt.NotifyEmail, 20000)

      this.customerSelectEmailDialogData = null
    },
    emailFiscalDocumentResult (response, payload) {
      if (response?.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.EmailReceipt.Success'),
          type: 'info',
          icon: 'email'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Misc.Notification.EmailReceipt.Error'),
          type   : 'error',
          icon   : 'warning',
          timeout: 6000
        })
      }
    },

    confirmCancelFiscalDocument (order, data = null) {
      if (!order) return

      this.selectedReceiptItem = order
      this.confirmCancelFiscalDocDialogVisible = true
    },
    cancelFiscalDocument (order, refund = false) {
      if (!order?.ReceiptId) return

      window.callAS(window.SocketCommand.Receipt.Cancel, {
        Id           : order.ReceiptId,
        TableId      : order?.TableId || null,
        BroadcastList: order?.BroadcastList ?? false,
        Refund       : refund ?? false
      })
    },
    cancelFiscalDocumentResult (response, payload) {
      if (response?.status === 'success') {
        this.$bus.$emit(window.SocketCommand.Receipt.Update, payload)

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.CancelReceipt.Success'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Misc.Notification.CancelReceipt.Error'),
          type   : 'error',
          icon   : 'warning',
          timeout: 6000
        })
      }
    },

    retryReceipt (receiptId) {
      window.callAS(window.SocketCommand.Receipt.Retry, { Id: receiptId })
    },
    onRetryReceiptResponse (response) {
      if (response?.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.RetryReceipt.Success'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Misc.Notification.RetryReceipt.Error'),
          type   : 'error',
          icon   : 'warning',
          timeout: 6000
        })
      }
    },

    orderHasRefundableTransactions (order) {
      if (!order) return false

      return order?.PaymentMethods?.some(payment => payment.Refundable)
    },

    orderRefundableTransactionsTotal (order) {
      if (!order) return 0

      return order?.PaymentMethods?.filter(payment => payment.Refundable)?.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0
    }
  }
}
