<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card v-if="domain">
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Domain.Import.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Domain.Import.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Domain.Import.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Domain.Import.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="domainForm"
            v-model="domainFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :value="`${domain.Domain}.${domain.BaseDomain}`"
                    label="Domain"
                    prepend-icon="domain"
                    readonly
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="data.ThirdPartyUrl"
                    :hint="$t('Settings.Domain.Import.Field.StoreId.Hint')"
                    :label="$t('Settings.Domain.Import.Field.StoreId.Label')"
                    :prepend-icon="$t('Settings.Domain.Import.Field.StoreId.Icon')"
                    :rules="[
                      (v) => !!v || $t('Settings.Domain.Import.Field.StoreId.Error.Required'),
                      (v) => v && v.length > 3 || $t('Settings.Domain.Import.Field.StoreId.Error.Between'),
                    ]"
                    required
                  />
                </v-col>

                <v-col
                  class="pa-2"
                  cols="12"
                />

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Domain.Import.Field.CatalogOnly.Label') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Domain.Import.Field.CatalogOnly.Hint') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="data.CatalogOnly"
                    inset
                    :disabled="!userCanManage"
                    :label="data.CatalogOnly ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Domain.Import.Field.Catalog.Label') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Domain.Import.Field.Catalog.Hint') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="catalog"
                    inset
                    :disabled="!userCanManage"
                    :label="catalog ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col
                  v-if="catalog"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.CatalogId"
                    v-mask="'#####'"
                    :hint="$t('Settings.Domain.Import.Field.CatalogId.Hint')"
                    :label="$t('Settings.Domain.Import.Field.CatalogId.Label')"
                    :prepend-icon="$t('Settings.Domain.Import.Field.CatalogId.Icon')"
                    clearable
                  />
                  <!--
                  <v-select
                    v-model="data.CatalogId"
                    :hint="$t('Settings.Domain.Import.Field.CatalogId.Hint')"
                    :items="catalogsList"
                    :label="$t('Settings.Domain.Import.Field.CatalogId.Label')"
                    :prepend-icon="$t('Settings.Domain.Import.Field.CatalogId.Icon')"
                    persistent-hint
                    item-text="Name"
                    item-value="Id"
                    clearable
                  >
                    <template #selection="{item}">
                      <v-chip
                        small
                        label
                        :color="`#${item.Color}`"
                        dark
                      >
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-tile-avatar
                        :color="`#${data.item.Color}`"
                        :size="28"
                      />

                      <v-list-tile-content>
                        <v-list-tile-title>{{ data.item.Name }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ data.item.Description | truncate }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </template>
                  </v-select>
                  -->
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyForce.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyForce.Subtitle') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="data.ImportFromThirdPartyForce"
                    inset
                    :disabled="!userCanManage"
                    :label="data.ImportFromThirdPartyForce ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyWithOptions.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyWithOptions.Subtitle') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="data.ImportFromThirdPartyWithOptions"
                    inset
                    :disabled="!userCanManage"
                    :label="data.ImportFromThirdPartyWithOptions ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyAllLocales.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyAllLocales.Subtitle') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="data.ImportFromThirdPartyAllLocales"
                    inset
                    :disabled="!userCanManage"
                    :label="data.ImportFromThirdPartyAllLocales ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyDefaultLocale.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyDefaultLocale.Subtitle') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-select
                    v-model="data.ImportFromThirdPartyDefaultLocale"
                    :disabled="!userCanManage"
                    :items="$i18n.languages"
                    class="ma-0 d-inline-flex ml-2"
                    item-text="name"
                    item-value="locale"
                    required
                    single-line
                    solo
                    style="min-width: 150px; max-width: 150px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Import.Title').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '../../../mixins/appdata'
import Auth    from '../../../mixins/auth'

export default {
  name      : 'AggregatorImportDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    domain: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      domainFormValid: false,
      catalog        : false,
      data           : {
        ThirdPartyUrl                    : '',
        CatalogId                        : null,
        CatalogOnly                      : true,
        ImportFromThirdPartyForce        : false,
        ImportFromThirdPartyWithOptions  : true,
        ImportFromThirdPartyAllLocales   : false,
        ImportFromThirdPartyDefaultLocale: 'el'
      }
    }
  },
  computed: {
    catalogsList () {
      const allCatalogs = this.domain?.Catalogs || []
      return allCatalogs
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      this.catalog = false
      this.data = {
        ThirdPartyUrl                    : '',
        CatalogId                        : null,
        CatalogOnly                      : true,
        ImportFromThirdPartyForce        : false,
        ImportFromThirdPartyWithOptions  : true,
        ImportFromThirdPartyAllLocales   : false,
        ImportFromThirdPartyDefaultLocale: 'el'
      }
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Domains.Import, this.onImportResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Domains.Import, this.onImportResult)
  },
  methods: {
    onImportResult (result) {
      this.isVisible = false
    },
    onSaveButtonClick () {
      this.$refs.domainForm.validate()

      if (this.domainFormValid) {
        if (this.$refs.domainForm) this.$refs.domainForm.resetValidation()

        const data = {
          AppId                            : this.domain.Application.id,
          Database                         : this.domain.DatabaseName,
          ThirdPartyUrl                    : this.data.ThirdPartyUrl,
          ImportFromThirdParty             : true,
          ImportFromThirdPartyForce        : this.data.ImportFromThirdPartyForce,
          ImportFromThirdPartyAllLocales   : this.data.ImportFromThirdPartyAllLocales,
          ImportFromThirdPartyWithOptions  : this.data.ImportFromThirdPartyWithOptions,
          ImportFromThirdPartyDefaultLocale: this.data.ImportFromThirdPartyDefaultLocale,
          CatalogOnly                      : this.data.CatalogOnly,
          CatalogId                        : this.catalog ? this.data.CatalogId || null : null
        }

        window.callAS(window.SocketCommand.Settings.Domains.Import, data)
      }
    },
    onCancelButtonClick () {
      if (this.$refs.domainForm) this.$refs.domainForm.resetValidation()
      this.isVisible = false
    }
  }
}
</script>
