<template>
  <v-list
    :three-line="!small && $vuetify.breakpoint.smAndUp"
    :two-line="$vuetify.breakpoint.xsOnly"
    class="pa-0"
    :light="parseInt(table.StatusId) !== 22"
  >
    <v-list-item
      :class="[disabled ? 'grey lighten-3' : getTableStatusColor(table), {'px-3': small}]"
      :disabled="disabled"
      :inactive="disabled"
      :style="small ? 'height: 38px !important; min-height: 38px !important;' : 'height: 126px !important;  min-height: 126px !important;'"
      class="fill-height"
      dense
      ripple
      v-on="hasClickListener ? {click: onTableClick} : {}"
    >
      <div
        :class="disabled ? 'grey' : getTableStatusColor(table, true)"
        style="position: absolute; width: 5px;"
        :style="small ? 'height: 39px; margin-left: -12px;' : 'height: 100%; margin-left: -16px;'"
      />

      <v-list-item-content class="align-content-start py-0">
        <v-list-item-title
          class="my-1"
          :class="[isDarkMode && parseInt(table.StatusId) === 22 ? 'white--text' : 'grey--text text--darken-3']"
        >
          <span class="font-weight-bold text-h5">
            {{ table.Name }}
          </span>

          <span class="text-caption">
            {{ childrenTablesNames }}
          </span>
        </v-list-item-title>

        <template v-if="!small">
          <v-list-item-subtitle
            class="text-caption"
            style="margin-top: 6px;"
          >
            <span class="font-weight-bold">{{ $tc('Restaurant.Tables.Seat.Title', 0) }}</span>:
            {{ tableTotalMinCapacity }}/{{ tableTotalMaxCapacity }}
          </v-list-item-subtitle>

          <v-list-item-subtitle
            class="text-caption"
            style="margin-top: 2px;"
          >
            <template v-if="tableIsInOccupiedStatuses(table)">
              <span class="font-weight-bold">{{ $t('Restaurant.Tables.Products') }}</span>:
              {{ table.TotalItems > 0 ? table.TotalItems : 0 }}
            </template>
          </v-list-item-subtitle>

          <v-list-item-subtitle
            class="text-caption"
            style="margin-top: 2px;"
          >
            <template v-if="tableIsInOccupiedStatuses(table)">
              <span class="font-weight-bold">{{ $t('Restaurant.Tables.Time') }}</span>:
              <time-ago-moment
                :auto-update="1"
                :since="getTableSwiftTimeSince(table)"
              />
            </template>
          </v-list-item-subtitle>

          <v-list-item-subtitle
            class="text-caption"
            style="margin-top: 2px;"
          >
            <template v-if="tableIsInOccupiedStatuses(table) && table.StaffName">
              <span
                :style="getTableStaffColor(table, true)"
                class="font-weight-bold"
              >
                {{ table.StaffName.toLocaleUpperCase($i18n.locale) }}
              </span>
            </template>
          </v-list-item-subtitle>
        </template>
      </v-list-item-content>

      <v-list-item-action
        v-if="tableIsInOccupiedStatuses(table)"
        class="pt-2 my-0 fill-height"
      >
        <v-list-item-action-text class="pt-1">
          <div class="text-right justify-end align-end align-content-end justify-self-end">
            <v-badge
              v-if="tableIsInOccupiedStatuses(table)"
              :color="`${getTableStatusColor(table, true)} lighten-2`"
              class="d-inline-flex"
              overlap
            >
              <template #badge>
                <span>{{ table.Persons }}</span>
              </template>

              <v-btn
                :style="!hasClickPersonsListener ? 'pointer-events: none;' : ''"
                class="ml-2 d-inline-flex"
                icon
                style="width: 32px; height: 32px;"
                v-on="hasClickPersonsListener ? {click: onPersonsClick} : {}"
              >
                <v-icon
                  :color="`${getTableStatusColor(table, true)} lighten-1`"
                  size="24"
                >
                  group
                </v-icon>
              </v-btn>
            </v-badge>

            <template v-if="!small">
              <div class="ml-4 d-inline-flex">
                <v-icon :color="`${getTableStatusColor(table, true)} lighten-1`">
                  {{ getTableStatusIcon(table) }}
                </v-icon>
              </div>
            </template>
          </div>

          <template v-if="!small">
            <div
              v-if="!isTableAvailable"
              :class="`${getTableStatusColor(table, true)}--text`"
              class="text-caption"
            >
              <span class="font-weight-bold">
                {{ getTableStatusLang(table).toLocaleUpperCase($i18n.locale) }}
              </span>
            </div>
          </template>
        </v-list-item-action-text>

        <template v-if="!small">
          <v-list-item-action-text class="text-subtitle-1 grey--text text--darken-3">
            <template v-if="tableIsInOccupiedStatuses(table)">
              <template v-if="table.HasDiscounts">
                <span class="old-price">
                  {{ table.CartTotal | currency }}
                </span>
                {{ table.OrderTotal | currency }}
              </template>
              <template v-else>
                {{ table.OrderTotal | currency }}
              </template>
            </template>
          </v-list-item-action-text>
        </template>
      </v-list-item-action>
    </v-list-item>

    <table-list-tile-actions-toolbar
      v-if="toolbar && !small"
      :area="area"
      :disabled="disabled"
      :table="table"
    />

    <v-divider />
  </v-list>
</template>

<script>
import TableStatus                 from '@/mixins/tables/tableStatus'
import TableCommon                 from '@/mixins/tables/tableCommon'
import TimeAgoMoment               from '@/components/common/TimeAgoMoment'
import Auth                        from '@/mixins/auth'
import Appdata                     from '@/mixins/appdata'
import TableListTileActionsToolbar from '@/components/restaurant/tables/tables/TableListTileActionsToolbar'

export default {
  name      : 'TableListTile',
  components: {
    TableListTileActionsToolbar,
    TimeAgoMoment
  },
  directives: {},
  mixins    : [Auth, Appdata, TableStatus, TableCommon],
  props     : {
    toolbar: {
      type   : Boolean,
      default: false
    },
    table: {
      type    : Object,
      required: true
    },
    area: {
      type   : Object,
      default: undefined
    },
    childrenTables: {
      type   : Array,
      default: undefined
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    childrenTablesNames () {
      return this.childrenTables?.map(table => table.Name)?.join(' / ') || ''
    },
    tableTotalMaxCapacity () {
      return this.childrenTables?.reduce((a, b) => a + b.MaxCapacity, this.table.MaxCapacity) || this.table.MaxCapacity || 0
    },
    tableTotalMinCapacity () {
      return this.childrenTables?.reduce((a, b) => a + b.MinCapacity, this.table.MinCapacity) || this.table.MinCapacity || 0
    },
    hasClickListener () {
      return this.$listeners && this.$listeners.hasOwnProperty('click')
    },
    hasClickPersonsListener () {
      return this.$listeners && this.$listeners.hasOwnProperty('click-persons')
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onTableClick (e) {
      e.stopPropagation()
      this.$emit('click', this.table, this.area)
    },

    onPersonsClick (e) {
      e.stopPropagation()
      this.$emit('click-persons', this.table, this.area)
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile {
  height : 100%;
}

/deep/ .v-list__tile__title {
  height      : 26px;
  line-height : 26px;
}

/deep/ .v-list__tile__content {
  justify-content : start !important;
}

/deep/ .v-badge__badge {
  top            : -11px;
  right          : -11px;
  pointer-events : none;
}
</style>
