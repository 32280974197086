<template>
  <v-combobox
    ref="googleAutocompleteServiceComponent"
    v-model.trim="vModel"
    :items="locationFoundItems"
    :rules="rules"
    :search-input.sync="locationSearchText"
    :hint="hint"
    :placeholder="placeholder"
    :loading="loading"
    :autofocus="autofocus"
    :readonly="readonly"
    :disabled="disabled"
    :error-messages="errorMessages"
    item-text="description"
    item-value="place_id"
    prepend-icon="location_on"
    hide-no-data
    no-filter
    clearable
    @blur="onBlur"
    @input="onInput"
    @focus="onFocus"
    @change="onChange"
    @click:clear="clearIconCb"
  />
</template>

<script>

import vModel from '@/mixins/vModel'
import util   from '@/filters/util'

const ADDRESS_COMPONENTS = {
  subpremise                 : 'short_name',
  street_number              : 'short_name',
  route                      : 'long_name',
  locality                   : 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  administrative_area_level_3: 'long_name',
  country                    : 'long_name',
  postal_code                : 'short_name'
}

// eslint-disable-next-line no-unused-vars
const CITIES_TYPE = ['locality', 'administrative_area_level_3']
// eslint-disable-next-line no-unused-vars
const REGIONS_TYPE = ['locality', 'sublocality', 'postal_code', 'country', 'administrative_area_level_1', 'administrative_area_level_2']

/*
  By default, we're only including basic place data because requesting these
  fields place data is not additionally charged by Google. Refer to:

  https://developers.google.com/maps/billing/understanding-cost-of-use#basic-data
*/
const BASIC_DATA_FIELDS = ['address_components', 'adr_address', 'alt_id',
                           'formatted_address', 'geometry', 'icon', 'id', 'name',
                           'business_status', 'photo', 'place_id', 'scope', 'type', 'url',
                           'utc_offset_minutes', 'vicinity']

export default {
  name  : 'VGoogleAutocompleteService',
  mixins: [vModel],
  props : {
    autofocus: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    readonly: {
      type   : Boolean,
      default: false
    },
    lazy: {
      type   : Boolean,
      default: false
    },
    clearIconCb: {
      type   : Function,
      default: () => {}
    },
    placeholder: {
      type   : String,
      default: ''
    },
    hint: {
      type   : String,
      default: ''
    },
    errorMessages: {
      type   : [String, Array],
      default: () => []
    },
    rules: {
      type   : Array,
      default: () => []
    },
    country: {
      type   : Array,
      default: () => []
    },
    types: {
      type   : Array,
      default: () => ['geocode']
    },
    fields: {
      type   : Array,
      default: () => BASIC_DATA_FIELDS
    },
    debounceTimeout: {
      type   : Number,
      default: 500
    },
    debounceMinChars: {
      type   : Number,
      default: 3
    }
  },
  data () {
    return {
      autocompleteDebounceTimeout: null,
      locationSearchText         : null,
      googleSessionToken         : null,
      locationEntries            : [],
      loading                    : false
    }
  },
  computed: {
    locationFoundItems () {
      return Array.isArray(this.locationEntries) ? this.locationEntries : []
    }
  },
  watch: {
    /*
    vModel (newVal) {
      const address = newVal?.description || newVal || ''
      if (address !== this.locationSearchText) this.locationSearchText = newVal
    },
    */

    locationSearchText (newVal, oldVal) {
      const newSearchStr = newVal && newVal.trim() || ''
      const oldSearchStr = oldVal && oldVal.trim() || ''
      const modelStr = this.vModel?.description || this.vModel || ''

      if ((this.lazy && modelStr === newSearchStr) || this.disabled) return

      if (newSearchStr !== oldSearchStr && (newSearchStr.length >= this.debounceMinChars || newSearchStr.length === 0)) {
        clearTimeout(this.autocompleteDebounceTimeout)
        this.autocompleteDebounceTimeout = setTimeout(() => {
          this.locationSearchTextDebounced(newSearchStr)
        }, this.debounceTimeout)
      }
    }
  },
  created () {
    this.generateNewSessionToken()
  },
  methods: {
    onBlur (param) {
      this.$emit('blur', param)
    },

    onFocus (param) {
      this.$emit('focus', param)
    },

    onInput (param) {
      this.$emit('input', param)
    },

    onChange (val) {
      this.$emit('change', val)

      const htmlInputElement = this.$refs?.googleAutocompleteServiceComponent?.$refs?.input
      if (!val || !util.isObject(val) || !htmlInputElement) return

      const placesService = new window.google.maps.places.PlacesService(htmlInputElement)
      placesService.getDetails(
        {
          placeId: val.place_id,
          fields : this.fields
        },
        (place) => {
          this.$emit('place-changed', place, this.formatResult(place))
        })
    },

    /**
     * Format result from Geo google APIs
     * @param place
     * @returns {Object} formatted_output
     */
    formatResult (place) {
      const returnData = {}

      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0]

        if (ADDRESS_COMPONENTS[addressType]) {
          returnData[addressType] = place.address_components[i][ADDRESS_COMPONENTS[addressType]]
        }
      }

      returnData.place_id = place.place_id
      returnData.name = place.name
      returnData.formatted_address = place.formatted_address
      returnData.lat = place.geometry.location.lat()
      returnData.lng = place.geometry.location.lng()

      return returnData
    },

    generateNewSessionToken () {
      this.googleSessionToken = new window.google.maps.places.AutocompleteSessionToken()
    },

    locationSearchTextDebounced (searchStr) {
      if (!searchStr || this.disabled) return

      this.loading = true
      const _vue = this

      this.getSuggestions(searchStr)
        .then(result => {
          _vue.locationEntries = result
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(() => {
          this.loading = false
          this.generateNewSessionToken()
        })
    },

    async getSuggestions (searchText) {
      try {
        return await this.searchLocation(searchText)
      } catch (err) {
        return err
      }
    },

    async searchLocation (val) {
      return await new Promise((resolve, reject) => {
        const displaySuggestions = (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) reject(status)

          resolve(predictions)
        }

        const service = new window.google.maps.places.AutocompleteService()
        service.getPlacePredictions(
          {
            input                : val,
            sessionToken         : this.googleSessionToken,
            componentRestrictions: { country: this.country || [] },
            types                : this.types || ['geocode'],
            fields               : this.fields
          },
          displaySuggestions
        )
      }).catch(err => {
        throw err
      })
    }
  }
}
</script>

<style scoped>

</style>
