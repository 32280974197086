<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="350"
      persistent
      scrollable
    >
      <v-card v-if="table && area">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            v-if="isTableAvailable"
            :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'"
          >
            <v-icon dark>
              {{ getTableStatusIcon(table) }}
            </v-icon>
          </v-avatar>

          <v-badge
            v-else
            :color="`${getTableStatusColor(table, true)} lighten-2`"
            overlap
          >
            <template #badge>
              <span>{{ table.Persons }}</span>
            </template>

            <v-avatar :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'">
              <v-icon dark>
                group
              </v-icon>
            </v-avatar>
          </v-badge>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ area.Name }} #{{ table.Name }}
            </div>

            <div
              v-if="isTableAvailable"
              class="text-caption grey--text"
            >
              {{ $tc('Restaurant.Tables.Title', 1) }} {{ getTableStatusLang(table) }}
            </div>

            <div
              v-else-if="table.StaffId && table.StaffName"
              class="text-caption grey--text"
            >
              {{ table.StaffName }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="fill-height pa-3">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <!-- Open -->
              <v-col
                v-if="canOpenTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  dark
                  color="green"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onOpenShiftButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.Open.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.Open.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Order -->
              <v-col
                v-if="canOrderTable && !canEditTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  :dark="!canEditTable"
                  color="primary"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onNewOrderButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.NewOrder.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.NewOrder.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Edit -->
              <v-col
                v-if="canEditTable"
                cols="12"
              >
                <v-btn
                  :disabled="!canEditTable"
                  depressed
                  large
                  block
                  class="white--text"
                  color="grey darken-2"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onEditTableButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.Edit.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.Edit.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- View -->
              <v-col
                v-if="canViewTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  class="white--text"
                  color="light-blue"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onViewTableButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.History.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.History.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Pay -->
              <v-col
                v-if="canPayTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  class="white--text"
                  color="light-green"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onPayButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.PayOrder.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.PayOrder.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Change Waiter -->
              <v-col
                v-if="canChangeWaiter"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  class="white--text"
                  color="brown"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onChangeWaiterButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.Waiter.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.Waiter.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Move -->
              <v-col
                v-if="canMoveTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  class="white--text"
                  color="indigo"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onMoveButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.Move.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.Move.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Merge -->
              <v-col
                v-if="canMergeTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  class="white--text"
                  color="indigo"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onMergeTableButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.Merge.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.Merge.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Split -->
              <v-col
                v-if="canSplitTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  class="white--text"
                  color="indigo"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onSplitTableButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.Split.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.Split.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>

              <!-- Close -->
              <v-col
                v-if="canCloseTable"
                cols="12"
              >
                <v-btn
                  depressed
                  large
                  block
                  class="white--text"
                  color="red"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onCloseTableButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.OptionMenu.Close.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.OptionMenu.Close.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TableStatus  from '@/mixins/tables/tableStatus'
import TableCommon  from '@/mixins/tables/tableCommon'
import TableActions from '@/mixins/tables/tableActions'

export default {
  name      : 'TableOptionsMenuDialog',
  components: {},
  mixins    : [TableStatus, TableCommon, TableActions],
  props     : {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch  : {},
  methods: {
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
