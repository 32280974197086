export default {
  Title: 'Dashboard',

  OrdersVsTurnover: {
    Title   : '@:Dashboard.OrdersVsTurnover.Orders vs. @:Dashboard.OrdersVsTurnover.Turnover',
    SubTitle: {
      Today : 'Daily statistics per hour',
      Week  : 'Weekly statistics per day',
      Month : 'Monthly statistics per day',
      Year  : 'Yearly statistics per month',
      Custom: 'Period statistics'
    },
    Orders     : 'Orders',
    Turnover   : 'Turnover',
    NumOfOrders: 'Num of Orders'
  },

  Top10: {
    Products: {
      Title   : 'Best Products | Best Product | Best Products',
      SubTitle: 'By Total Orders',
      NoData  : {
        Title   : 'No Products Found',
        SubTitle: 'Select different period'
      }
    },
    Customers: {
      Orders: {
        Title   : 'Best Customers | Best Customer | Best Customers',
        SubTitle: 'By Total Orders',
        NoData  : {
          Title   : 'No Customers Found',
          SubTitle: 'Select different period'
        }
      },
      Turnover: {
        Title   : 'Best Customers | Best Customer | Best Customers',
        SubTitle: 'By Total Turnover',
        NoData  : {
          Title   : 'No Customers Found',
          SubTitle: 'Select different period'
        }
      }
    }
  },

  Help: {
    SupportEmail: {
      Name   : 'Name',
      Store  : 'Store',
      Url    : 'E-Shop',
      Email  : 'Email',
      Message: 'Your Message'
    },
    SupportCenter: 'Support Center',
    Faq          : 'Help Center',
    Wizard       : 'Quick Setup Wizard',
    WhatsNew     : 'What\'s New?'
  }
}
