<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :icon="$vuetify.breakpoint.xsOnly"
          class="primary--text elevation-0 ma-1"
          text
          @click="showAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Faq.FaqCategories.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Faq.FaqCategories.Button.Title').toLocaleUpperCase($i18n.locale) }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('FAQS', 'ACCESS')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'Faqs'}"
          class="primary--text elevation-0 ma-0"
          text
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Faq.Toolbar.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $tc('Faq.Title', 1) }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.title`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showEditDialog(item)"
                >
                  {{ getTranslatedField(item, 'Title') }}
                </a>
              </template>
              <template v-else>
                {{ getTranslatedField(item, 'Title') }}
              </template>
            </template>

            <template #[`item.slug`]="{item}">
              {{ item.Slug }}
            </template>

            <template #[`item.languages`]="{item}">
              <template v-for="lang in faqLanguages">
                <div
                  :key="lang.locale"
                  class="mx-1 d-inline-block"
                >
                  <v-badge
                    :value="!item.Lang[lang.locale] || !item.Lang[lang.locale].Title"
                    color="orange"
                    overlap
                    right
                  >
                    <template #badge>
                      <v-icon
                        dark
                        small
                      >
                        priority_high
                      </v-icon>
                    </template>
                    <v-avatar size="20">
                      <img
                        :class="[{'grayscale opacity': !item.Lang[lang.locale] || !item.Lang[lang.locale].Title}]"
                        :src="`/assets/flags/1x1/${lang.locale}.svg`"
                      >
                    </v-avatar>
                  </v-badge>
                </div>
              </template>
            </template>

            <template #[`item.priority`]="{item}">
              {{ item.Priority }}
            </template>

            <template #[`item.color`]="{item}">
              <v-chip
                :color="item.Color"
                label
                text-color="white"
              />
            </template>

            <template #[`item.active`]="{item}">
              <v-switch
                v-model="item.Active"
                inset
                :disabled="!userCanManage"
                :label="item.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                class="ma-0"
                color="success"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.created_at`]="{item}">
              {{ item.DateUpdated ? item.DateUpdated : item.DateCreated }}
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanDelete"
                class="red elevation-2 ma-0 mr-2"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showDeleteDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  close
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanManage"
                class="cyan elevation-2 ma-0"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <faq-category-add-dialog
      :category-to-edit="selectedItem"
      :visible.sync="addEditDialog"
    />

    <confirm-dialog
      :html-content="$t('Faq.FaqCategories.Delete.Content')"
      :html-title="$t('Faq.FaqCategories.Delete.Title')"
      :visible.sync="confirmDialogDeleteFaqCategoryVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="delete-faq-category-on-yes-btn"
      @delete-faq-category-on-yes-btn="deleteFaqCategory"
    />
  </v-card>
</template>

<script>
import Auth                 from '@/mixins/auth'
import AppData              from '@/mixins/appdata'
import Translatable         from '@/mixins/translatable'
import DataTable            from '@/mixins/data-table/dataTable'
import ConfirmDialog        from '@/components/common/ConfirmDialog'
import FilterSearch         from '@/components/common/filter/FilterSearch.vue'
import FaqCategoryAddDialog from './FaqCategoryAddDialog'

export default {
  name      : 'FaqCategories',
  components: {
    FilterSearch,
    FaqCategoryAddDialog,
    ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, DataTable],
  props     : [],
  data () {
    return {
      selectedItem                         : null,
      addEditDialog                        : false,
      confirmDialogDeleteFaqCategoryVisible: false,
      dataTable                            : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Faq.Category.All,
          save  : window.SocketCommand.Faq.Category.Save,
          delete: window.SocketCommand.Faq.Category.Delete
        },
        options: {
          defaultSortBy: 'id',
          sortBy       : ['id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Id'),
            value    : 'id',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Title'),
            value    : 'title',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Slug'),
            value    : 'slug',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Languages'),
            value    : 'languages',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Priority'),
            value    : 'priority',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Color'),
            value    : 'color',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Status'),
            value    : 'active',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Date'),
            value    : 'created_at',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.FaqCategories.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 140
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    showAddDialog () {
      this.selectedItem = null
      this.addEditDialog = true
    },

    showEditDialog (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.addEditDialog = true
    },

    showDeleteDialog (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.confirmDialogDeleteFaqCategoryVisible = true
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.Faq.Category.Save, { ...item })
    },

    deleteFaqCategory () {
      window.callAS(window.SocketCommand.Faq.Category.Delete, { Id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data && data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Faq.FaqCategories.Delete.Result.Success'),
          type: 'success',
          icon: 'info'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Faq.FaqCategories.Delete.Result.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--switch .v-input--selection-controls__input {
  margin-right : 0;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 65px !important;*/
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch label {
  font-size     : 11px !important;
  padding-right : 0;
  margin        : 0;
}

/deep/ .chip:focus:not(.chip--disabled):after, .chip--active:not(.chip--disabled):after, .chip--selected:not(.chip--disabled):after {
  top  : 0;
  left : 0;
}
</style>
