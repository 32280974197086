<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card v-if="editData">
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Domain.PaymentFee.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Domain.PaymentFee.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Domain.PaymentFee.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Domain.PaymentFee.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="pa-3"
        >
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <template v-for="(paymentMethod, index) in editData">
                  <v-col
                    :key="`title-${index}`"
                    cols="12"
                  >
                    <h3>{{ paymentMethod.title }}</h3>
                  </v-col>

                  <v-col :key="`active-${index}`">
                    <v-icon
                      :color="paymentMethod.active ? 'light-green' : ''"
                      class="mr-2"
                    >
                      {{ $t('Settings.Domain.PaymentFee.Field.Active.Icon') }}
                    </v-icon>
                    <div class="d-inline-block">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.PaymentFee.Field.Active.Label') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.PaymentFee.Field.Active.Hint') }}
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    :key="`active-shrink-${index}`"
                    class="shrink"
                  >
                    <v-switch
                      v-model="paymentMethod.active"
                      inset
                      :disabled="!userCanManage"
                      :label="paymentMethod.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      :rules="paymentMethod.validator.vuetifyFormFieldRules('active')"
                      class="ma-0 d-inline-flex ml-2"
                      color="blue"
                      hide-details
                    />
                  </v-col>

                  <v-col
                    :key="`active-separator-${index}`"
                    class="pt-0"
                    cols="12"
                  />

                  <v-col
                    :key="`factor-${index}`"
                    md="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model.number="paymentMethod.factor"
                      :disabled="!userCanManage"
                      :hint="$t('Settings.Domain.PaymentFee.Field.Factor.Hint')"
                      :label="$t('Settings.Domain.PaymentFee.Field.Factor.Label')"
                      :prepend-icon="$t('Settings.Domain.PaymentFee.Field.Factor.Icon')"
                      :rules="paymentMethod.validator.vuetifyFormFieldRules('factor')"
                    />
                  </v-col>
                  <v-col
                    :key="`fee-${index}`"
                    md="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model.number="paymentMethod.fee"
                      :disabled="!userCanManage"
                      :hint="$t('Settings.Domain.PaymentFee.Field.Fee.Hint')"
                      :label="$t('Settings.Domain.PaymentFee.Field.Fee.Label')"
                      :prepend-icon="$t('Settings.Domain.PaymentFee.Field.Fee.Icon')"
                      :rules="paymentMethod.validator.vuetifyFormFieldRules('fee')"
                    />
                  </v-col>

                  <v-col
                    v-if="index + 1 < editData.length"
                    :key="`payment-method-separator-${index}`"
                    cols="12"
                  >
                    <v-divider class="my-2" />
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData               from '../../../mixins/appdata'
import Auth                  from '../../../mixins/auth'
import PaymentFeeDomainModel from '@/api/Models/settings/PaymentFeeDomainModel'

export default {
  name      : 'PaymentFeeDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    domain: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      dialogFormValid: false,
      editData       : null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.editData = Object.values(JSON.parse(JSON.stringify(this.domain.PaymentFee))).map(obj => new PaymentFeeDomainModel(obj))
      } else {
        this.editData = null
      }
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Domains.PaymentFee, this.onResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Domains.PaymentFee, this.onResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()

        const paymentData = {}
        this.editData.forEach(paymentMethod => {
          paymentData[paymentMethod.id] = paymentMethod
        })

        const data = {
          Id        : this.domain.Id,
          PaymentFee: paymentData
        }
        window.callAS(window.SocketCommand.Settings.Domains.PaymentFee, data)
      }
    },
    onResult (response) {
      this.onCancelButtonClick()

      if (response && response.status) {
        this.$bus.$emit('app-show-notification', {
          body   : response.type,
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Settings.Domain.Notification.Generic.Error'),
          type   : 'error',
          icon   : 'warning',
          timeout: 5000
        })
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
    }
  }
}
</script>
