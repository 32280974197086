/**
 * Enum for table reservation status.
 * @readonly
 * @enum {Number} ReservationStatusEnum
 */

const ReservationStatusEnum = {
  WaitingList     : 100,
  Late            : 101,
  NotConfirmed    : 102,
  Confirmed       : 103,
  NoAnswer        : 104,
  Arrived         : 105,
  PartiallyArrived: 106,
  Finished        : 107,
  Denied          : 108,
  Canceled        : 109,
  NoShow          : 110
}

export default ReservationStatusEnum
