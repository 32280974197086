<template>
  <div style="height: 100%; display: table;">
    <div
      style="display: table-cell; vertical-align: top;"
      :style="userInAdminGroup ? 'cursor: pointer;' : ''"
      @click="onViewUsers"
    >
      <v-tooltip
        color="green darken-1"
        :disabled="userInAdminGroup ? !appOnlineAdminUsers.count : !appOnlineTotalUsers.count"
        bottom
      >
        <template #activator="{on}">
          <div
            :class="[isOnline ? 'light-green lighten-1' : 'red lighten-3']"
            class="rounded"
            style="height: 100%; width: 60px; display: table; overflow: hidden;"
            v-on="on"
          >
            <span
              class="white--text"
              style="display: table-cell; text-align: center; vertical-align: middle; line-height: 10px;"
            >
              <v-icon :class="[isOnline ? 'green--text text--darken-3' : 'red--text']">
                {{ isOnline ? 'wifi' : 'signal_wifi_off' }}
              </v-icon>
              <div
                :class="[isOnline ? 'green--text text--darken-3' : 'red--text']"
                style="font-size: 10px; font-weight: 900;"
              >
                <div style="font-size: 10px; font-weight: 900;">
                  POS
                </div>
                <div style="font-size: 10px; font-weight: 900;">
                  {{ isOnline ? 'ONLINE' : 'OFFLINE' }}
                </div>
              </div>
            </span>

            <span
              v-if="!isOnline"
              class="red"
              style="display: table-cell; height: 100%; width: 7px;"
            />
            <span
              v-else
              class="green darken-3"
              style="display: table-cell; height: 100%; width: 7px;"
            />

            <v-badge
              style="top: -10px; right: 8px;"
              color="green darken-3"
              overlap
              left
            >
              <template #badge>
                <span>{{ appOnlineTotalUsers.count }}</span>
              </template>
            </v-badge>

            <v-badge
              v-if="userInAdminGroup && appOnlineAdminUsers.count"
              style="top: 12px; right: 8px;"
              color="purple"
              overlap
              left
            >
              <template #badge>
                <span>{{ appOnlineAdminUsers.count }}</span>
              </template>
            </v-badge>
          </div>
        </template>

        <div>
          <v-subheader class="pa-0 font-weight-bold white--text">
            {{ $t('Common.Misc.UsersOnline') }} ({{ userInAdminGroup && appOnlineAdminUsers.count ? (appOnlineAdminUsers.count + appOnlineTotalUsers.count) : appOnlineTotalUsers.count }})
          </v-subheader>

          <v-divider />

          <v-list
            dense
            color="transparent"
            class="pa-0"
          >
            <template v-for="(user, index) in appOnlineNormalUsers.users">
              <v-list-item
                :key="`normal-user-${index}`"
                dense
                class="pl-0"
              >
                <v-list-item-avatar
                  color="green darken-4"
                  size="28"
                >
                  <v-icon size="20">
                    <template v-if="user.group === StaffGroupEnum.OWNER">
                      mdi-account-tie
                    </template>
                    <template v-else-if="user.group === StaffGroupEnum.CASHIER">
                      mdi-account-cash
                    </template>
                    <template v-else-if="user.group === StaffGroupEnum.WAITER">
                      room_service
                    </template>
                    <template v-else-if="user.group === StaffGroupEnum.USER">
                      mdi-account
                    </template>
                    <template v-else>
                      mdi-account-check
                    </template>
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.name }}
                    <span class="text-caption green--text text--lighten-4 ml-1">({{ $te(`Authentication.StaffGroup.${ user.group }`) ? $t(`Authentication.StaffGroup.${ user.group }`) : user.group }})</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ user.uid }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-for="(user, index) in appOnlineGuestUsers.users">
              <v-list-item
                :key="`guest-user-${index}`"
                dense
                class="pl-0"
              >
                <v-list-item-avatar
                  color="orange darken-1"
                  size="28"
                >
                  <v-icon size="20">
                    <template v-if="user.group === StaffGroupEnum.OWNER">
                      mdi-account-tie
                    </template>
                    <template v-else-if="user.group === StaffGroupEnum.CASHIER">
                      mdi-account-cash
                    </template>
                    <template v-else-if="user.group === StaffGroupEnum.WAITER">
                      room_service
                    </template>
                    <template v-else-if="user.group === StaffGroupEnum.USER">
                      mdi-account
                    </template>
                    <template v-else>
                      mdi-account-lock
                    </template>
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.name }}
                    <span class="text-caption green--text text--lighten-4 ml-1">({{ $te(`Authentication.StaffGroup.${ user.group }`) ? $t(`Authentication.StaffGroup.${ user.group }`) : user.group }})</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ user.uid }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-if="userInAdminGroup && appOnlineAdminUsers.count">
              <template v-for="(user, index) in appOnlineAdminUsers.users">
                <v-list-item
                  :key="`admin-user-${index}`"
                  dense
                  class="pl-0"
                >
                  <v-list-item-avatar
                    color="purple"
                    size="28"
                  >
                    <v-icon size="20">
                      mdi-shield-account
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                      <span class="text-caption green--text text--lighten-4 ml-1">({{ $te(`Authentication.StaffGroup.${ user.group }`) ? $t(`Authentication.StaffGroup.${ user.group }`) : user.group }})</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ user.uid }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list>
        </div>
      </v-tooltip>
    </div>

    <div
      v-if="posUserHasComponentPermission('Online', 'ACCESS')"
      class="pl-1"
      style="display: table-cell; vertical-align: top;"
    >
      <div
        :class="[isClosed ? 'light-green lighten-1' : 'red lighten-3']"
        class="rounded"
        style="height: 100%; width: 60px; display: table;"
      >
        <div
          class="white--text"
          style="display: table-cell; text-align: center; vertical-align: middle; line-height: 10px;"
        >
          <v-icon :class="[isClosed ? 'green--text text--darken-3' : 'red--text']">
            {{ isClosed ? 'cloud_done' : 'cloud_off' }}
          </v-icon>
          <div :class="[isClosed ? 'green--text text--darken-3' : 'red--text']">
            <div style="font-size: 10px; font-weight: 900;">
              WEB
            </div>
            <div style="font-size: 10px; font-weight: 900;">
              {{ isClosed ? $t('Common.OnlineStatus.Title.Open').toLocaleUpperCase($i18n.locale) : $t('Common.OnlineStatus.Title.Closed').toLocaleUpperCase($i18n.locale) }}
            </div>
          </div>
        </div>
        <span
          v-if="!isClosed"
          class="red rounded"
          style="display: table-cell; height: 100%; width: 7px;"
        />
        <span
          v-else
          class="green darken-3 rounded"
          style="display: table-cell; height: 100%; width: 7px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'
import StaffGroupEnum from '@/api/Enums/StaffGroupEnum'

export default {
  components: {},
  mixins    : [AppData, Auth],
  props     : [],
  data () {
    return {}
  },
  computed: {
    StaffGroupEnum () {
      return StaffGroupEnum
    },
    isOnline: {
      get () {
        return this.appOnlineStatus
      }
    },
    isClosed: {
      get () {
        return this.onlineOrderingStatus && this.isOnline
      }
    }
  },
  methods: {
    onViewUsers () {
      if (!this.posUserHasComponentPermission('SystemSettingsDomains', 'ACCESS') || !this.userInAdminGroup) return

      this.$router.push({ name: 'SystemSettingsOnlineUsers' })
    }
  }
}
</script>
