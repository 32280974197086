<template>
  <div
    v-if="machineHasPrinters"
    class="d-inline-block"
  >
    <template v-if="machineHasMultiplePrinters">
      <v-menu
        v-model="menuActive"
        :bottom="menuPosition === 'bottom'"
        :top="menuPosition === 'top'"
        :left="menuPosition === 'left'"
        :right="menuPosition === 'right'"
        :offset-x="menuOffsetX"
        :offset-y="menuOffsetY"
        :open-on-hover="openOnHover"
      >
        <template #activator="{on: onMenu}">
          <v-tooltip
            :bottom="tooltipPosition === 'bottom'"
            :top="tooltipPosition === 'top'"
            :left="tooltipPosition === 'left'"
            :right="tooltipPosition === 'right'"
            :disabled="disabled || disableTooltip || openOnHover"
            :open-delay="tooltipOpenDelay"
            :close-delay="tooltipCloseDelay"
            :color="color"
          >
            <template #activator="{on: onTooltip}">
              <v-btn
                :class="[btnClass]"
                :color="color"
                :style="btnStyle"
                :dark="dark"
                :icon="icon"
                :small="small"
                :large="large"
                :outlined="outline"
                :text="flat"
                :depressed="depressed"
                :absolute="absolute"
                :disabled="disabled"
                :block="block"
                :fixed="fixed"
                :fab="fab"
                v-on="{...onMenu, ...onTooltip}"
              >
                <v-icon
                  v-if="!hideIcon"
                  :small="small"
                  :left="!!label && !hideLabel && !icon"
                  :size="iconSize"
                >
                  {{ iconName }}
                </v-icon>
                <span v-if="label && !hideLabel && !icon">{{ label }}</span>
              </v-btn>
            </template>
            <span>{{ tooltip }}</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item
            v-for="(printer, index) in appMachineReceiptTotalsZPrintersActive"
            :key="`printer-${index}`"
            @click="onPrintClick(printer)"
          >
            <v-list-item-action>
              <v-icon>print</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ printer.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-else>
      <v-tooltip
        :bottom="tooltipPosition === 'bottom'"
        :top="tooltipPosition === 'top'"
        :left="tooltipPosition === 'left'"
        :right="tooltipPosition === 'right'"
        :disabled="disabled || disableTooltip"
        :open-delay="tooltipOpenDelay"
        :close-delay="tooltipCloseDelay"
        :color="color"
      >
        <template #activator="{on}">
          <v-btn
            :class="[btnClass]"
            :color="color"
            :style="btnStyle"
            :dark="dark"
            :icon="icon"
            :small="small"
            :large="large"
            :outlined="outline"
            :text="flat"
            :depressed="depressed"
            :absolute="absolute"
            :disabled="disabled"
            :block="block"
            :fixed="fixed"
            :fab="fab"
            v-on="on"
            @click="onPrintClick(appMachineReceiptTotalsZPrintersActive[0])"
          >
            <v-icon
              v-if="!hideIcon"
              :small="small"
              :left="!!label && !hideLabel && !icon"
              :size="iconSize"
            >
              {{ iconName }}
            </v-icon>
            <span v-if="label && !hideLabel && !icon">{{ label }}</span>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
import Auth    from '@/mixins/auth'
import AppData from '@/mixins/appdata'

export default {
  name      : 'PrintReceiptTotalsZButton',
  components: {},
  directives: {},
  mixins    : [Auth, AppData],
  props     : {
    tooltip: {
      type   : String,
      default: function () { return this.$t('Order.Print.ButtonReportZ.Title') }
    },
    label: {
      type   : String,
      default: function () { return this.$t('Order.Print.ButtonReportZ.Title').toLocaleUpperCase(this.$i18n.locale) }
    },
    hideLabel: {
      type   : Boolean,
      default: false
    },
    btnClass: {
      type   : String,
      default: 'white--text'
    },
    btnStyle: {
      type   : String,
      default: ''
    },
    color: {
      type   : String,
      default: 'orange'
    },
    icon: {
      type   : Boolean,
      default: false
    },
    iconName: {
      type   : String,
      default: function () { return this.$t('Order.Print.ButtonReportZ.Icon') }
    },
    iconSize: {
      type   : Number,
      default: 18
    },
    hideIcon: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    },
    large: {
      type   : Boolean,
      default: false
    },
    outline: {
      type   : Boolean,
      default: false
    },
    flat: {
      type   : Boolean,
      default: false
    },
    depressed: {
      type   : Boolean,
      default: false
    },
    absolute: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    block: {
      type   : Boolean,
      default: false
    },
    fixed: {
      type   : Boolean,
      default: false
    },
    fab: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    menuPosition: {
      type   : String,
      default: 'bottom'
    },
    menuOffsetY: {
      type   : Boolean,
      default: false
    },
    menuOffsetX: {
      type   : Boolean,
      default: false
    },
    tooltipPosition: {
      type   : String,
      default: 'bottom'
    },
    tooltipOpenDelay: {
      type   : Number,
      default: 1200
    },
    tooltipCloseDelay: {
      type   : Number,
      default: 200
    },
    disableTooltip: {
      type   : Boolean,
      default: false
    },
    openOnHover: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      menuActive: false
    }
  },
  computed: {
    machineHasPrinters () {
      return this.appMachineReceiptTotalsZPrintersActive.length && this.appHasReceiptTotalsZPrinters
    },
    machineHasMultiplePrinters () {
      return this.appMachineHasReceiptTotalsZPrinters && this.appMachineReceiptTotalsZPrintersActive.length > 1
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onPrintClick (printer) {
      if (!printer) return

      this.$emit('printer-selected', printer)
    }
  }
}
</script>

<style scoped>

</style>
