/* eslint-disable no-console */
import { isArray, isFunction } from '@/lib/data/Validations/utils/Utils'
import { EventBus }            from '@/events/eventBus'

/**
 * Usage
 *
 * const promiseQueue = new PromiseQueue({ concurrency: 2, cooldown: 1000 });
 *
 * promiseQueue.push(() => axios.get('https://twitter.com/').then((response, body) => {
 *    // The eventual response of Twitter
 * }));
 *
 * // This request will not start until 1000 ms after the above.
 * promiseQueue.push(() => axios.get('https://some.com/').then((response, body) => {
 *    // ...
 * }));
 *
 * // This will not run until of the above have terminated.
 * promiseQueue.push(() => axios.get('https://test.com/').then((response, body) => {
 *    // ...
 * }));
 *
 *  Make sure you wrap the function. Otherwise, it will run immediately, and defeat the purpose of the queue.
 *
 * // WILL NOT BE CORRECTLY ENQUEUED
 * promiseQueue.push(axios.get('https://twitter.com/').then((response, body) => {
 *    // The queue will not control the start of execution here.
 * }));
 *
 * Events
 * Whenever the queue is empty after handling tasks, it will emit an 'idle' event.
 *
 * promiseQueue.$on('promise-queue:idle', function(noTasksCompleted, noTasksWithError) {
 *    // Do something
 * });
 *
 * Errors
 * The queue continues on any error, but the error will be outputted to stdout.
 */

export default class PromiseQueue {
  constructor ({ concurrency = 1, cooldown = 0 } = {}) {
    this.concurrency = concurrency
    this.cooldown = cooldown
    this.noCompleted = 0
    this.noErrors = 0
    this._running = []
    this._staging = []
    this._enqueued = []
    this.earliestExecution = Date.now()
  }

  clear () {
    this.noCompleted = 0
    this.noErrors = 0
    this._running = []
    this._staging = []
    this._enqueued = []
    this.earliestExecution = Date.now()
    this.onComplete()
  }

  onComplete () {
    EventBus.$emit('promise-queue:idle', this.noCompleted, this.noErrors)
  }

  executionDelay () {
    const now = Date.now()
    if (this.earliestExecution < now) this.earliestExecution = now
    this.earliestExecution += this.cooldown
    return this.earliestExecution - now
  }

  pending () {
    return this._enqueued.length
  }

  running () {
    return this._running.length
  }

  staging () {
    return this._staging.length
  }

  readyToPop () {
    return (this._running.length + this._staging.length) < this.concurrency && this._enqueued.length > 0
  }

  allDone () {
    return (this._running.length === 0 && this._staging.length === 0 && this._enqueued.length === 0)
  }

  delay (ms) { // eslint-disable-line class-methods-use-this
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  pop () {
    if (this.readyToPop()) {
      const fx = this._enqueued.shift()
      this._staging.push(fx)

      this.delay(this.executionDelay())
        .then(() => {
          this._staging.splice(this._staging.indexOf(fx), 1)
          this._running.push(fx)
          console.log(`${ this.running() } running, ${ this.staging() } staging and ${ this.pending() } pending.`)
        })
        .then(() => {
          let result

          try {
            result = fx()
          } catch (e) {
            console.error(e)
            this.noErrors += 1
          }

          return Promise.resolve(result)
        })
        .catch((err) => {
          console.error(err)
          this.noErrors += 1
          return Promise.resolve()
        })
        .then(() => {
          this._running.splice(this._running.indexOf(fx), 1)
          this.noCompleted += 1
        })
        .then(this.pop.bind(this))
    } else if (this.allDone()) {
      this.onComplete()
    }
  }

  push (fx) {
    const tasks = isArray(fx) ? fx : [fx]

    tasks.forEach((task) => {
      if (!isFunction(task)) throw new Error('Push only functions that returns promises to the queue.')
      this._enqueued.push(task)
      this.pop()
    })

    EventBus.$emit('promise-queue:push', this.noCompleted, this.noErrors)
  }
}
