import axios               from 'axios'
import isElectron          from '@/electron/isElectron'
import i18n                from '@/lang/lang'
import ipcRendererElectron from '@/electron/ipcRendererElectron'
import ipcCommandsEnum     from '@/electron/ipcCommandsEnum'

const IS_ELECTRON = isElectron()
const ipcRenderer = ipcRendererElectron()

/**
 *
 * @param url {String}
 * @param printers {Array}
 * @param auto {Boolean}
 */
function printURLtoPrinter (url, printers = [], auto = false) {
  if (IS_ELECTRON) {
    ipcRenderer.send(ipcCommandsEnum.PrintUrlToPrinter, url, printers, auto, window.authorizationTokenBearer)
  } else {
    if (auto) return
    API.get(url, {})
      .then(response => {
        printRawHTMLtoPrinter(response.data, printers)
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }
}

/**
 *
 * @param htmlString {String}
 * @param printers {Array}
 */
function printRawHTMLtoPrinter (htmlString, printers = []) {
  if (IS_ELECTRON) {
    ipcRenderer.send(ipcCommandsEnum.PrintRawHtmlToPrinter, htmlString, printers)
  } else {
    const win = window.open('', 'Print', 'height=600,width=800')
    setTimeout(() => {
      win.document.write('<html><head><title>Print</title>')
      win.document.write('</head><body>')
      win.document.write(htmlString)
      win.document.write('</body></html>')
      win.document.close()
    }, 450)

    setTimeout(() => {
      win.focus()
      win.print()
    }, 650)

    setTimeout(() => {
      if (isMobileDevice()) {
        win.onfocus = () => { win.close() }
      } else {
        win.close()
      }
    }, 700)
  }
}

/**
 *
 * @returns {Boolean}
 */
function isMobileDevice () {
  return (/android/i.test(navigator.userAgent) || /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
}

/**
 *
 * @type {{printTableReceiptAuto: Printer.printTableReceiptAuto, printOrder: Printer.printOrder, printTableOrderAuto: Printer.printTableOrderAuto, printOrderPreparation: Printer.printOrderPreparation, printPreparationAuto: Printer.printPreparationAuto, printStaffShift: Printer.printStaffShift, printStatsProducts: Printer.printStatsProducts, printOrderAuto: Printer.printOrderAuto, printTableReceiptFromOrder: Printer.printTableReceiptFromOrder, printTableOrderPreparationAuto: Printer.printTableOrderPreparationAuto, printReceiptTotalsZ: Printer.printReceiptTotalsZ, printReceipt: Printer.printReceipt, printTableCanceled: Printer.printTableCanceled, printTableOrderPreparation: Printer.printTableOrderPreparation, printTableOrder: Printer.printTableOrder, getSystemPrinters: (function(): *), printTableReceipt: Printer.printTableReceipt, printTableCanceledAuto: Printer.printTableCanceledAuto, printReceiptAuto: Printer.printReceiptAuto, printOrderTotalsZ: Printer.printOrderTotalsZ}}
 */
export const Printer = {
  /**
   *
   * @returns {Array}
   */
  getSystemPrinters: () => {
    return ipcRenderer.sendSync(ipcCommandsEnum.GetSystemPrinters) || []
  },

  /**
   *
   * @param orderId {Number}
   */
  printOrderAuto: (orderId) => {
    // console.log('>>>> printOrderAuto::: ', orderId)
    const printersArray = window.Vue.AppMachinePrinters.filter(printer => printer.status === '1' && parseInt(printer.copies) > 0) || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    if (printers.length > 0) printURLtoPrinter(`${ process.env.VUE_APP_API_URL }order/print/${ orderId }`, printers, true)
  },

  /**
   *
   * @param orderId {Number}
   * @param printerGroupId {Number}
   */
  printPreparationAuto: (orderId, printerGroupId) => {
    // console.log('>>>> printPreparationAuto::: ', orderId, printerGroupId)
    const printerGroup = window.Vue.appPrinterGroups?.find(group => parseInt(group.Id) === parseInt(printerGroupId))
    if (!printerGroup || !window?.Vue?.AppConfig?.LOCATION_DATA?.PreparationDocumentEnabled) return

    const printersArray = window.Vue.AppMachinePreparationPrinters.filter(printer => printerGroup?.Status && printer?.groups?.includes(printerGroupId) && printer.status === '1' && parseInt(printer.copies) > 0) || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    if (printers.length > 0) printURLtoPrinter(`${ process.env.VUE_APP_API_URL }order/preparation/print/${ orderId }/${ printerGroupId }`, printers, true)
  },

  /**
   *
   * @param receiptId {Number | String}
   */
  printReceiptAuto: (receiptId) => {
    if (!window?.Vue?.userCanViewReceipt) return

    // console.log('>>>> printReceiptAuto::: ', orderId)
    const printersArray = window.Vue.AppMachineReceiptPrinters.filter(printer => printer.status === '1') || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    if (printers.length > 0) printURLtoPrinter(`${ process.env.VUE_APP_API_URL }receipt/print/${ receiptId }`, printers, true)
  },

  /**
   *
   * @param cartId {Number | String}
   * @param timestamp {String}
   */
  printTableOrderAuto: (cartId, timestamp) => {
    // console.log('>>>> printOrderAuto::: ', cartId, timestamp)
    const printersArray = window.Vue.AppMachinePrinters.filter(printer => printer.status === '1' && parseInt(printer.copies) > 0) || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    if (printers.length > 0) {
      if (timestamp) {
        printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/print/${ cartId }/${ timestamp }`, printers, true)
      } else {
        printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/print/${ cartId }/0`, printers, true)
      }
    }
  },

  /**
   *
   * @param cartId {Number | String}
   * @param printerGroupId {Number | String}
   * @param timestamp {String}
   */
  printTableOrderPreparationAuto: (cartId, printerGroupId, timestamp) => {
    // console.log('>>>> printTableOrderPreparationAuto::: ', cartId, printerGroupId, timestamp)
    const printerGroup = window.Vue.appPrinterGroups?.find(group => parseInt(group.Id) === parseInt(printerGroupId))
    if (!printerGroup || !window?.Vue?.AppConfig?.LOCATION_DATA?.PreparationDocumentEnabled) return

    const printersArray = window.Vue.AppMachinePreparationPrinters.filter(printer => printerGroup?.Status && printer?.groups?.includes(printerGroupId) && printer.status === '1' && parseInt(printer.copies) > 0) || []
    const printers = JSON.parse(JSON.stringify(printersArray))

    if (printers.length > 0) printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/preparation/print/${ cartId }/${ printerGroupId }/${ timestamp }`, printers, true)
  },

  /**
   *
   * @param cartId {Number | String}
   * @param timestamp {String}
   */
  printTableReceiptAuto: (cartId, timestamp) => {
    // console.log('>>>> printTableReceiptAuto::: ', cartId, timestamp)
    const printersArray = window.Vue.AppMachineReceiptPrinters.filter(printer => printer.status === '1' && parseInt(printer.copies) > 0) || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    if (printers.length > 0) printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/receipt/print/${ cartId }/${ timestamp }`, printers, true)
  },

  /**
   *
   * @param cartId {Number | String}
   * @param items {Array}
   */
  printTableCanceledAuto: (cartId, items) => {
    // console.log('>>>> printTableCanceledAuto::: ', cartId, items)
    const printersArray = window.Vue.AppMachinePrinters.filter(printer => printer.status === '1' && parseInt(printer.copies) > 0) || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    if (items && Array.isArray(items) && items?.length) {
      const itemsString = JSON.stringify(items)
      if (printers.length > 0) printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/cancel/print/${ cartId }/${ itemsString }`, printers, true)
    }
  },

  /**
   *
   * @param orderId {Number | String}
   * @param printer {Object}
   * @param copies {Number}
   */
  printOrder: (orderId, printer = { name: '' }, copies = 1) => {
    // console.log('>>>>>>>> printOrder::: ', orderId, printer.name, copies)
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    printURLtoPrinter(`${ process.env.VUE_APP_API_URL }order/print/${ orderId }`, [printer])

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.Notification'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param orderId {Number | String}
   * @param printerGroupId {Number | String}
   * @param copies {Number}
   */
  printOrderPreparation: (orderId, printerGroupId, copies = 1) => {
    // console.log('>>>>>>>> printOrderPreparation::: ', orderId, printerGroupId, copies)
    const printerGroup = window.Vue.appPrinterGroups?.find(group => parseInt(group.Id) === parseInt(printerGroupId))
    if (!printerGroup || !window?.Vue?.AppConfig?.LOCATION_DATA?.PreparationDocumentEnabled) return

    const printersArray = window.Vue.AppMachinePreparationPrinters.filter(printer => printerGroup?.Status && printer?.groups?.includes(printerGroupId) && printer.status === '1') || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    let printer = IS_ELECTRON ? printers : [{
      name  : '',
      copies: copies
    }]
    if (IS_ELECTRON && !printer.length) return

    printer = printer.map(p => {
      p.copies = parseInt(copies)
      return p
    })

    printURLtoPrinter(`${ process.env.VUE_APP_API_URL }order/preparation/print/${ orderId }/${ printerGroupId }`, printer)

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.Notification'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param receiptId {Number | String}
   */
  printReceipt: (receiptId) => {
    if (!window?.Vue?.userCanViewReceipt) return

    // console.log('>>>>>>>> printReceipt::: ', orderId, printer.name, copies)
    const printersArray = window.Vue.AppMachineReceiptPrinters.filter(printer => printer.status === '1') || []
    const printers = JSON.parse(JSON.stringify(printersArray))

    printURLtoPrinter(`${ process.env.VUE_APP_API_URL }receipt/print/${ receiptId }`, printers)

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.NotificationReceipt'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param range {String}
   * @param printer {Object}
   * @param copies {Number}
   */
  printReceiptTotalsZ: (range, printer = { name: '' }, copies = 1) => {
    if (!window?.Vue?.userCanViewReceipt) return

    // console.log('>>>>>>>> printReceiptTotalsZ::: ', range, printer.name, copies)
    range = range || '-'
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    printURLtoPrinter(`${ process.env.VUE_APP_API_URL }receipt/totals/print/${ range }`, [printer])

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.NotificationTotals'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param range {String}
   * @param printer {Object}
   * @param copies {Number}
   */
  printOrderTotalsZ: (range, printer = { name: '' }, copies = 1) => {
    // console.log('>>>>>>>> printOrderTotalsZ::: ', range, printer.name, copies)
    range = range || '-'
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    printURLtoPrinter(`${ process.env.VUE_APP_API_URL }order/totals/print/${ range }`, [printer])

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.NotificationTotals'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param cartId {Number | String}
   * @param timestamp {String}
   * @param printer {Object}
   * @param copies {Number}
   */
  printTableOrder: (cartId, timestamp, printer = { name: '' }, copies = 1, showNotification = true) => {
    // console.log('>>>>>>>> printTableOrder::: ', cartId, timestamp, printer.name, copies)
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    if (timestamp) {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/print/${ cartId }/${ timestamp }`, [printer])
    } else {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/print/${ cartId }/0`, [printer])
    }

    if (!showNotification) return

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.Notification'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param cartId {Number | String}
   * @param printerGroupId {Number | String}
   * @param timestamp {String}
   * @param copies {Number}
   */
  printTableOrderPreparation: (cartId, printerGroupId, timestamp, copies = 1) => {
    // console.log('>>>>>>>> printTableOrderPreparation::: ', cartId, printerGroupId, timestamp, copies)
    const printerGroup = window.Vue.appPrinterGroups?.find(group => parseInt(group.Id) === parseInt(printerGroupId))
    if (!printerGroup || !window?.Vue?.AppConfig?.LOCATION_DATA?.PreparationDocumentEnabled) return

    const printersArray = window.Vue.AppMachinePreparationPrinters.filter(printer => printerGroup?.Status && printer?.groups?.includes(printerGroupId) && printer.status === '1') || []
    const printers = JSON.parse(JSON.stringify(printersArray))
    const printer = IS_ELECTRON ? printers : [{ name: '' }]
    if (IS_ELECTRON && !printer.length) return

    printer.copies = parseInt(copies)

    if (timestamp) {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/preparation/print/${ cartId }/${ printerGroupId }/${ timestamp }`, [printer])
    } else {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/preparation/print/${ cartId }/${ printerGroupId }/0`, [printer])
    }

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.Notification'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param receiptType {ReceiptTypeEnum}
   * @param cartId {Number | String}
   * @param timestamp {String}
   * @param printer {Object}
   * @param copies {Number}
   */
  printTableReceipt: (receiptType, cartId, timestamp, printer = { name: '' }, copies = 1) => {
    // console.log('>>>>>>>> printTableReceipt::: ', receiptType, cartId, timestamp, printer.name, copies)
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    if (timestamp) {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/receipt/print/${ cartId }/${ receiptType }/${ timestamp }`, [printer])
    } else {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/receipt/print/${ cartId }/${ receiptType }/0`, [printer])
    }

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.NotificationReceipt'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param orderId {Number | String}
   * @param timestamp {String}
   * @param printer {Object}
   * @param copies {Number}
   */
  printTableReceiptFromOrder: (orderId, timestamp, printer = { name: '' }, copies = 1) => {
    // console.log('>>>>>>>> printTableReceiptFromOrder::: ', orderId, timestamp, printer.name, copies)
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    if (timestamp) {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/receipt/print/${ orderId }/${ timestamp }`, [printer])
    } else {
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/receipt/print/${ orderId }/0`, [printer])
    }

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.NotificationReceipt'),
      type: 'info',
      icon: 'print'
    })
  },

  /**
   *
   * @param cartId {Number | String}
   * @param items {Array}
   * @param printer {Object}
   * @param copies {Number}
   */
  printTableCanceled: (cartId, items, printer = { name: '' }, copies = 1) => {
    // console.log('>>>>>>>> printTableCanceled::: ', cartId, items, printer.name, copies)
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    if (items && Array.isArray(items) && items?.length) {
      const itemsString = JSON.stringify(items)
      printURLtoPrinter(`${ process.env.VUE_APP_API_URL }table/order/cancel/print/${ cartId }/${ itemsString }`, [printer])

      window.Vue && window.Vue.$bus.$emit('app-show-notification', {
        body: window.Vue.$t('Order.Print.Notification'),
        type: 'info',
        icon: 'print'
      })
    }
  },

  /**
   *
   * @param htmlString {String}
   * @param printerName {String}
   * @param copies {Number}
   */
  printStaffShift: (htmlString, printerName = '', copies = 1) => {
    if (IS_ELECTRON && printerName === '') return
    printRawHTMLtoPrinter(htmlString, [{
      name  : printerName,
      copies: parseInt(copies)
    }])
  },

  /**
   *
   * @param range {String}
   * @param sortBy {String}
   * @param orderBy {String}
   * @param printer {Object}
   * @param copies {Number}
   */
  printStatsProducts: (range, sortBy, orderBy, printer = { name: '' }, copies = 1) => {
    // console.log('>>>>>>>> printOrderTotalsZ::: ', range, printer.name, copies)
    range = range || '-'
    sortBy = sortBy || 'Id'
    orderBy = orderBy || 'DESC'
    printer = JSON.parse(JSON.stringify(printer))
    if (IS_ELECTRON && printer.name === '') return
    printer.copies = parseInt(copies)
    printURLtoPrinter(`${ process.env.VUE_APP_API_URL }stats/products/print/${ range }/${ sortBy }/${ orderBy }`, [printer])

    window.Vue && window.Vue.$bus.$emit('app-show-notification', {
      body: window.Vue.$t('Order.Print.NotificationTotals'),
      type: 'info',
      icon: 'print'
    })
  }

}

const API = axios.create({
  baseURL        : '',
  timeout        : 30000,
  responseType   : 'text',
  crossDomain    : true,
  withCredentials: true,
  headers        : {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Referer'       : process.env.VUE_APP_API_X_REFERER || ''
  }
})

API.interceptors.request.use((request) => {
  request.headers.Authorization = window.authorizationTokenBearer
  request.headers['Accept-Language'] = i18n.locale
  return request
}, function (error) {
  return Promise.reject(error)
})

export default Printer
