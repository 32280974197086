<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :icon="$vuetify.breakpoint.xsOnly"
          class="primary--text elevation-0 ma-1"
          text
          @click="showAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Faq.Faqs.Toolbar.Button.Add.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Faq.Faqs.Toolbar.Button.Add.Title').toLocaleUpperCase($i18n.locale) }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('FAQCATEGORIES', 'ACCESS')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'FaqCategories'}"
          class="primary--text elevation-0 ma-0"
          text
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            support
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $tc('Faq.FaqCategoryAddDialog.Title', 0).toLocaleUpperCase($i18n.locale) }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />

        <filter-options
          v-model="filtersModel"
          :filters="filterData"
        />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.title`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showEditDialog(item)"
                >
                  {{ getTranslatedField(item, 'Title') }}
                </a>
              </template>
              <template v-else>
                {{ getTranslatedField(item, 'Title') }}
              </template>
            </template>

            <template #[`item.slug`]="{item}">
              {{ getTranslatedField(item, 'Slug') }}
            </template>

            <template #[`item.languages`]="{item}">
              <template v-for="lang in faqLanguages">
                <div
                  :key="lang.locale"
                  class="mx-1 d-inline-block"
                >
                  <v-badge
                    :value="!item.Lang[lang.locale] || !item.Lang[lang.locale].Title"
                    color="orange"
                    overlap
                    right
                  >
                    <template #badge>
                      <v-icon
                        dark
                        small
                      >
                        priority_high
                      </v-icon>
                    </template>

                    <v-avatar size="20">
                      <img
                        :class="[{'grayscale opacity': !item.Lang[lang.locale] || !item.Lang[lang.locale].Title}]"
                        :src="`/assets/flags/1x1/${lang.locale}.svg`"
                      >
                    </v-avatar>
                  </v-badge>
                </div>
              </template>
            </template>

            <template #[`item.content`]="{item}">
              <v-icon
                :color="item.Content.length > 0 ? 'orange': 'grey'"
                class="mb-2"
              >
                format_list_bulleted
              </v-icon>
              <span class="text-h5 grey--text pl-1">{{ item.Content.length }}</span>
            </template>

            <template #[`item.category_id`]="{item}">
              <v-chip
                :color="item.CategoryColor"
                label
                text-color="white"
                @click="onCategoryChipClick(item.CategoryId)"
              >
                {{ getTranslatedField(item, 'Title', 'CategoryLang', true, 'CategoryTitle') }}
              </v-chip>
            </template>

            <template #[`item.active`]="{item}">
              <v-switch
                v-model="item.Active"
                inset
                :disabled="!userCanManage"
                :label="item.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                class="ma-0"
                color="success"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.created_at`]="{item}">
              {{ item.DateUpdated ? item.DateUpdated : item.DateCreated }}
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanDelete"
                class="red elevation-2 ma-0 mr-2"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showDeleteDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  close
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanAccess"
                class="elevation-2 ma-0 mr-2"
                :class="item.Active ? 'light-green' : 'purple'"
                :small="$vuetify.breakpoint.xsOnly"
                :href="`https://help.getfoodpro.com/el/article/${getLangField(item, 'Slug').replaceAll('_', '-')}-${item.CategoryId}-${item.Id}${!item.Active ? '?draft=preview' : ''}`"
                target="_blank"
                dark
                icon
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  mdi-open-in-new
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanManage"
                class="cyan elevation-2 ma-0"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <faqs-add-dialog
      :categories="categories"
      :item-to-edit="selectedItem"
      :visible.sync="addEditDialog"
    />

    <confirm-dialog
      :html-content="$t('Faq.Faqs.Dialog.Content')"
      :html-title="$t('Faq.Faqs.Dialog.Title')"
      :visible.sync="confirmDialogDeleteFaqVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="delete-faq-on-yes-btn"
      @delete-faq-on-yes-btn="deleteFaq"
    />
  </v-card>
</template>

<script>
import Auth          from '@/mixins/auth'
import AppData       from '@/mixins/appdata'
import Watchable     from '@/mixins/watchable'
import Translatable  from '@/mixins/translatable'
import FilterCommon  from '@/mixins/filter/filterCommon'
import DataTable     from '@/mixins/data-table/dataTable'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import FilterSearch  from '@/components/common/filter/FilterSearch.vue'
import FilterOptions from '@/components/common/filter/FilterOptions.vue'
import FaqsAddDialog from './FaqsAddDialog'

export default {
  name      : 'Faqs',
  components: {
    FilterOptions,
    FilterSearch,
    FaqsAddDialog,
    ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, Watchable, FilterCommon, DataTable],
  props     : [],
  data () {
    return {
      filtersModel: {
        lang          : 'Faq.Faqs',
        filterCategory: {
          key  : 'filter_category',
          value: null,
          items: []
        }
      },
      categories                   : [],
      selectedItem                 : null,
      addEditDialog                : false,
      confirmDialogDeleteFaqVisible: false,
      dataTable                    : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Faq.Manage.All,
          save  : window.SocketCommand.Faq.Manage.Save,
          delete: window.SocketCommand.Faq.Manage.Delete
        },
        options: {
          defaultSortBy: 'id',
          sortBy       : ['id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Id'),
            value    : 'id',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Title'),
            value    : 'title',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Slug'),
            value    : 'slug',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Languages'),
            value    : 'languages',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Content'),
            value    : 'content',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Category'),
            value    : 'category_id',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Status'),
            value    : 'active',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Date'),
            value    : 'created_at',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Faq.Faqs.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 140
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {
    search (newVal, oldVal) {
      if (newVal.trim() !== oldVal.trim()) {
        this.viewData.table.config.pagination.page = 1
        clearTimeout(this.debounceSearchTimeout)
        this.debounceSearchTimeout = setTimeout(() => {
          this.getData()
        }, 500)
      }
    }
  },
  beforeCreate () {
  },
  created () {
    // Init Custom Filter Values
    this.filtersModel.filterCategory.items = this.categories
  },
  beforeMount () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Faq.Category.Combo, this.onCategoriesResult)
    this.getCategories()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Faq.Category.Combo, this.onCategoriesResult)
  },
  destroyed () {
  },
  methods: {
    getLangField (item, field = 'Title', langObj = 'Lang') {
      return this.$i18n.locale && item[langObj] && item[langObj][this.$i18n.locale] && item[langObj][this.$i18n.locale][field] || ''
    },

    showAddDialog () {
      this.selectedItem = null
      this.addEditDialog = true
    },

    showEditDialog (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.addEditDialog = true
    },

    showDeleteDialog (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.confirmDialogDeleteFaqVisible = true
    },

    updateItemStatus (item) {
      this.saveFaq(item)
    },

    saveFaq (item) {
      this.loading = true
      window.callAS(window.SocketCommand.Faq.Manage.Save, { ...item })
    },

    deleteFaq () {
      window.callAS(window.SocketCommand.Faq.Manage.Delete, { Id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data && data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Faq.Faqs.Notification.Success.Delete'),
          type: 'success',
          icon: 'info'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Faq.Faqs.Notification.Error.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onCategoryChipClick (category) {
      this.filtersModel.filterCategory.value = category
    },

    getCategories () {
      window.callAS(window.SocketCommand.Faq.Category.Combo, {})
    },

    onCategoriesResult (data) {
      if (data) {
        data = (data?.Categories || []).map(o => {
          o.Avatar = {
            Icon : 'support',
            Color: 'grey'
          }
          return o
        })
        this.categories = data
        this.filtersModel.filterCategory.items = this.categories
      }
    },

    setData (data) {
      for (let i = 0; i < data.items.length; i++) {
        const item = data.items[i]
        for (let ii = 0; ii < item.Content.length; ii++) {
          const itemContent = item.Content[ii]
          if (!itemContent.hasOwnProperty('Lang')) {
            itemContent.Lang = {
              el: {
                Description: itemContent.Description,
                Image      : itemContent.Image,
                Note       : itemContent.Note,
                Tip        : itemContent.Tip,
                Title      : itemContent.Title
              }
            }
          }
        }
      }

      this.setDataInternal(data)
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--switch .v-input--selection-controls__input {
  margin-right : 0;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch label {
  font-size     : 11px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
