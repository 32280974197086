import { render, staticRenderFns } from "./SystemSettingsDomains.vue?vue&type=template&id=5ee99915&scoped=true"
import script from "./SystemSettingsDomains.vue?vue&type=script&lang=js"
export * from "./SystemSettingsDomains.vue?vue&type=script&lang=js"
import style0 from "./SystemSettingsDomains.vue?vue&type=style&index=0&id=5ee99915&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ee99915",
  null
  
)

export default component.exports