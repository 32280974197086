import CryptoJS from 'crypto-js'

const encKey = '6bdf5e16-fe42-55b7-bea4-d251a732b6fc'

export const AES = {
  encrypt: (obj) => {
    return obj ? CryptoJS.AES.encrypt(JSON.stringify(obj), encKey).toString() : ''
  },

  decrypt: (encryptedText) => {
    const decryptedStr = encryptedText ? CryptoJS.AES.decrypt(encryptedText, encKey).toString(CryptoJS.enc.Utf8) : null
    try {
      return JSON.parse(decryptedStr)
    } catch (error) {
      return null
    }
  }
}

export const MD5 = {
  hash: (val) => {
    return CryptoJS.MD5(val).toString(CryptoJS.enc.Base64)
  }
}
export const HmacMD5 = {
  hash: (val) => {
    return CryptoJS.HmacMD5(val, encKey).toString(CryptoJS.enc.Base64)
  }
}

export const SHA1 = {
  hash: (val) => {
    return CryptoJS.SHA1(val).toString(CryptoJS.enc.Base64)
  }
}
export const HmacSHA1 = {
  hash: (val) => {
    return CryptoJS.HmacSHA1(val, encKey).toString(CryptoJS.enc.Base64)
  }
}

export const SHA256 = {
  hash: (val) => {
    return CryptoJS.SHA256(val).toString(CryptoJS.enc.Base64)
  }
}
export const HmacSHA256 = {
  hash: (val) => {
    return CryptoJS.HmacSHA256(val, encKey).toString(CryptoJS.enc.Base64)
  }
}

export const SHA512 = {
  hash: (val) => {
    return CryptoJS.SHA512(val).toString(CryptoJS.enc.Base64)
  }
}
export const HmacSHA512 = {
  hash: (val) => {
    return CryptoJS.HmacSHA512(val, encKey).toString(CryptoJS.enc.Base64)
  }
}

export default {
  AES,
  MD5,
  HmacMD5,
  SHA1,
  HmacSHA1,
  SHA256,
  HmacSHA256,
  SHA512,
  HmacSHA512
}
