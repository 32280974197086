<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            color="blue"
            size="46"
          >
            <v-icon
              color="white"
              size="30"
            >
              mdi-credit-card-wireless
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Restaurant.Tables.Dialog.SelectTerminal.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Restaurant.Tables.Dialog.SelectTerminal.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <template v-for="vendor in vendors">
                <template v-for="terminal in vendor.Terminals">
                  <v-col
                    :key="`vendor-${vendor.Id}-terminal-${terminal.Id}`"
                    cols="12"
                  >
                    <v-list class="pa-0">
                      <v-list-item
                        class="rounded"
                        :class="isDarkMode ? 'grey darken-3' : 'grey lighten-3'"
                        ripple
                        @click="onSelectTerminal(terminal)"
                      >
                        <v-list-item-avatar size="32">
                          <v-icon
                            color="blue"
                            size="32"
                          >
                            mdi-credit-card-wireless
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content :class="isDarkMode ? 'white--text' : 'grey--text text--darken-2'">
                          <v-list-item-title class="text-left">
                            {{ terminal.Nickname }}
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            <span class="grey--text">{{ vendor.Name }} {{ terminal.Manufactor }} {{ terminal.Model }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            text
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData from '@/mixins/appdata'

export default {
  name      : 'SelectPaymentTerminalDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    vendors () {
      return this.appConfig?.POS_VENDORS || []
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onSelectTerminal (terminal) {
      this.isVisible = false
      this.$emit('terminal:selected', terminal)
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>
