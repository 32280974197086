/* eslint-disable no-multiple-empty-lines */
export const MapsProviderEnum = {

  Google: 'google',


  MapBox: 'mapbox'
}

export default {
  data () {
    return {}
  },
  methods : {},
  computed: {
    defaultMapsProvider () {
      return this.appConfig?.LOCATION_DATA?.Maps?.Pos?.DefaultMapsProvider || null
    },
    interactiveMapsProvider () {
      return this.appConfig?.LOCATION_DATA?.Maps?.Pos?.InteractiveMapsProvider || null
    },
    staticMapsProvider () {
      return this.appConfig?.LOCATION_DATA?.Maps?.Pos?.StaticMapsProvider || null
    },
    geocodeProvider () {
      return this.appConfig?.LOCATION_DATA?.Maps?.Pos?.GeocodeProvider || this.interactiveMapsProvider || this.staticMapsProvider || null
    }
  }
}
