import BaseModel               from '../../../lib/data/Models/BaseModel'
import CustomerDomainValidator from '../../Validations/settings/CustomerDomainValidator'
import SocketCommand           from '../../SocketCommand'

export default class CustomerDomainModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = rawData.Id || null
    this.Active = rawData.Active ?? false
    this.CustomerId = rawData.CustomerId || ''
    this.LeadId = rawData.LeadId || ''
    this.ResellerId = rawData.ResellerId || ''
    this.TrialExpiredAt = rawData.TrialExpiredAt || ''
    this.SubscriptionExpiredAt = rawData.SubscriptionExpiredAt || ''
    this.SeasonStartedAt = rawData.SeasonStartedAt || ''
    this.SeasonEndedAt = rawData.SeasonEndedAt || ''
    this.ExtendedPeriodAt = rawData.ExtendedPeriodAt || ''
    this.Locked = rawData.Locked ?? false
    this.CountryId = rawData.CountryId || 86

    this.validator = new CustomerDomainValidator(this)
  }

  boot () {
    super.boot()

    this.guarded = ['CountryId']
    this.saveCommand = SocketCommand.Settings.Domains.Customer.Save
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */
}
