<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card v-if="selectedCustomer && company">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="$t('Customer.CustomerAddCompanyDialog.Toolbar.Color')">
            <v-icon dark>
              {{ $t('Customer.CustomerAddCompanyDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!editCompanyId"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Customer.CustomerAddCompanyDialog.Toolbar.Add.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerAddCompanyDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>
          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Customer.CustomerAddCompanyDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerAddCompanyDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="companyForm"
            v-model="companyFormValid"
            @submit.prevent
          >
            <v-container
              class="mt-0 pt-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-subheader class="white--text primary text-subtitle-1 ma-0">
                    <v-icon
                      class="pr-2"
                      dark
                    >
                      mdi-domain
                    </v-icon>
                    {{ $t('Customer.CustomerAddCompanyDialog.Company.Title') }}
                  </v-subheader>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.BrandName"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.BrandName.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.BrandName.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.BrandName.Icon')"
                    :rules="validationRules.companyForm.BrandName"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.DistinctiveTitle"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.DistinctiveTitle.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.DistinctiveTitle.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.DistinctiveTitle.Icon')"
                    :rules="validationRules.companyForm.DistinctiveTitle"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="company.Profession"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Profession.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Profession.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Profession.Icon')"
                    :rules="validationRules.companyForm.Profession"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.Phone"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Phone.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Phone.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Phone.Icon')"
                    :rules="validationRules.companyForm.Phone"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.Email"
                    :counter="96"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Hint')"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Label')"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Icon')"
                    :rules="validationRules.companyForm.Email"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.VatNumber"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.VatNumber.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.VatNumber.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.VatNumber.Icon')"
                    :rules="validationRules.companyForm.VatNumber"
                    :prefix="vatCountryCode"
                    :error-messages="vatNumberError"
                    required
                  >
                    <template #append>
                      <v-btn
                        icon
                        small
                        :loading="fetchingVatData"
                        :disabled="!company.VatNumber || fetchingVatData"
                        @click="fetchDataByVatNumber"
                      >
                        <v-icon>mdi-database-arrow-down</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.Irs"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Irs.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Irs.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Irs.Icon')"
                    :rules="validationRules.companyForm.Irs"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-subheader class="white--text primary text-subtitle-1 ma-0">
                    <v-icon
                      class="pr-2"
                      dark
                    >
                      my_location
                    </v-icon>
                    {{ $t('Customer.CustomerAddCompanyDialog.Address.Title') }}
                  </v-subheader>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.Street"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Street.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Street.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Street.Icon')"
                    :rules="validationRules.companyForm.Street"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="company.StreetNumber"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.StreetNumber.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.StreetNumber.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.StreetNumber.Icon')"
                    :rules="validationRules.companyForm.StreetNumber"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="company.Postcode"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Postcode.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Postcode.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Postcode.Icon')"
                    :rules="validationRules.companyForm.Postcode"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="company.City"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.City.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.City.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.City.Icon')"
                    :rules="validationRules.companyForm.City"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="company.CountryId"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Country.Hint')"
                    :items="countryList"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Country.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Country.Icon')"
                    :rules="validationRules.companyForm.CountryId"
                    cache-items
                    class="pr-2"
                    item-text="name"
                    item-value="id"
                    required
                  >
                    <template #prepend>
                      <v-avatar
                        size="22"
                        tile
                      >
                        <template v-if="vatCountryCode">
                          <v-img :src="`/assets/flags/1x1/${String(vatCountryCode).toLowerCase()}.svg`" />
                        </template>
                        <template v-else>
                          <v-icon>map</v-icon>
                        </template>
                      </v-avatar>
                    </template>

                    <template #item="{item}">
                      <v-list-item-avatar size="34">
                        <v-img :src="`/assets/flags/1x1/${String(item.code).toLowerCase()}.svg`" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="item.name" />
                        <v-list-item-subtitle
                          class="text-caption"
                          v-html="item.desc"
                        />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="company.Notes"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Icon')"
                    :rules="validationRules.companyForm.Notes"
                    hide-details="auto"
                    rows="3"
                  />
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    v-model="company.IsDefault"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.IsDefault.Label')"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import AppData          from '@/mixins/appdata'
import { API, APICall } from '@/api/Api'

const clearCompanyData = {
  Id              : null,
  BrandName       : '',
  DistinctiveTitle: '',
  Profession      : '',
  Phone           : '',
  Email           : '',
  VatNumber       : '',
  Irs             : '',
  Notes           : '',
  IsDefault       : true,
  County          : '',
  City            : '',
  Street          : '',
  StreetNumber    : '',
  Postcode        : '',
  Country         : '',
  CountryId       : 86,
  CountryCode     : 'GR'
}

export default {
  name      : 'CustomerCompanyAddDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    editCompanyId: {
      type   : [Number, String],
      default: undefined
    },
    selectedCustomer: {
      type   : Object,
      default: undefined
    },
    selectedCompany: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      loading         : false,
      companyFormValid: false,
      fetchingVatData : false,
      vatNumberError  : '',
      company         : this.defaultEmptyCompany(),
      validationRules : {
        companyForm: {
          BrandName: [
            (v) => !!v || this.$t('Customer.CustomerAddCompanyDialog.Field.BrandName.Errors.Mandatory'),
            (v) => v && v.length >= 2 && v.length <= 512 || this.$t('Customer.CustomerAddCompanyDialog.Field.BrandName.Errors.Valid')
          ],
          DistinctiveTitle: [
            (v) => !v || (v && v.length >= 2 && v.length <= 512) || this.$t('Customer.CustomerAddCompanyDialog.Field.DistinctiveTitle.Errors.Valid')
          ],
          Profession: [
            (v) => !v || (v.length >= 2 && v.length <= 512) || this.$t('Customer.CustomerAddCompanyDialog.Field.Profession.Errors.Valid')
          ],
          Phone: [
            (v) => !v || (v && v.length >= 8 && v.length <= 16) || this.$t('Customer.CustomerAddCompanyDialog.Field.Phone.Errors.Valid')
          ],
          Email: [
            (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Between'),
            (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Valid')
          ],
          VatNumber: [
            (v) => !!v || this.$t('Customer.CustomerAddCompanyDialog.Field.VatNumber.Errors.Mandatory'),
            (v) => v && v.length >= 9 && v.length <= 14 || this.$t('Customer.CustomerAddCompanyDialog.Field.VatNumber.Errors.Valid')
          ],
          Irs: [
            (v) => !!v || this.$t('Customer.CustomerAddCompanyDialog.Field.Irs.Errors.Mandatory'),
            (v) => v && v.length >= 2 || this.$t('Customer.CustomerAddCompanyDialog.Field.Irs.Errors.Valid')
          ],
          Notes: [
            (v) => !v || (v && v.length >= 2 && v.length <= 1024) || this.$t('Customer.CustomerAddCompanyDialog.Field.Notes.Errors.Valid')
          ],
          County: [
            (v) => !v || (v && v.length >= 2 && v.length <= 128) || this.$t('Customer.CustomerAddCompanyDialog.Field.County.Errors.Valid')
          ],
          City: [
            (v) => !!v || this.$t('Customer.CustomerAddCompanyDialog.Field.City.Errors.Mandatory'),
            (v) => v && v.length >= 2 && v.length <= 128 || this.$t('Customer.CustomerAddCompanyDialog.Field.City.Errors.Valid')
          ],
          Street: [
            (v) => !!v || this.$t('Customer.CustomerAddCompanyDialog.Field.Street.Errors.Mandatory'),
            (v) => v && v.length >= 2 && v.length <= 128 || this.$t('Customer.CustomerAddCompanyDialog.Field.Street.Errors.Valid')
          ],
          StreetNumber: [
            (v) => !!v || this.$t('Customer.CustomerAddCompanyDialog.Field.StreetNumber.Errors.Mandatory'),
            (v) => v && v.length >= 1 && v.length <= 10 || this.$t('Customer.CustomerAddCompanyDialog.Field.StreetNumber.Errors.Valid')
          ],
          Postcode: [
            (v) => !v || (v && v.length >= 2) || this.$t('Customer.CustomerAddCompanyDialog.Field.Postcode.Errors.Valid')
          ],
          CountryId: [
            (v) => !!v || this.$t('Customer.CustomerAddCompanyDialog.Field.StreetNumber.Errors.Mandatory')
          ]
        }
      }
    }
  },
  computed: {
    vatCountryCode () {
      const country = this.countryList.find(country => country.id === this.company.CountryId)
      return country ? (country.code === 'GR' ? 'EL' : country.code === 'GB' ? 'UK' : country.code) : ''
    },

    countryCode () {
      const country = this.countryList.find(country => country.id === this.company.CountryId)
      return country?.code || ''
    },

    countryList () {
      return this.selectedCustomer?.Countries || []
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    countryCode (newVal) {
      this.company.CountryCode = newVal
    },

    isVisible (newVal) {
      if (newVal) {
        this.$bus.$on(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        if (this.selectedCompany && this.editCompanyId) {
          this.company = JSON.parse(JSON.stringify(this.selectedCompany))
        } else {
          this.company = this.defaultEmptyCompany()
        }
      } else {
        this.$bus.$off(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        this.vatNumberError = ''
      }
    }
  },
  mounted () {},
  created () {
    clearCompanyData.CountryId = this.appCountryId
    clearCompanyData.CountryCode = this.appCountryCode
  },
  beforeDestroy () {},
  methods: {
    defaultEmptyCompany () {
      return JSON.parse(JSON.stringify(clearCompanyData))
    },

    async fetchDataByVatNumber () {
      if (!this.company.VatNumber || !this.vatCountryCode) return

      this.vatNumberError = ''
      this.fetchingVatData = true

      const payload = {
        Vat           : this.company.VatNumber,
        VatCountryCode: this.vatCountryCode || 'EL'
      }

      try {
        const response = await API.post(APICall.vatCheck, payload)
        const data = response?.data || null
        const status = data?.status || ''

        if (status === 'success') {
          this.setCompanyDataFromApi(data)
        } else {
          this.vatNumberError = this.$t('Customer.CustomerAddCompanyDialog.Field.VatNumber.Errors.ApiError')
        }
      } catch (e) {
        this.vatNumberError = this.$t('Common.Error.Generic')
      }

      this.fetchingVatData = false
    },

    setCompanyDataFromApi (data) {
      this.company.Irs = data.IrsDescription || ''
      this.company.BrandName = data.BrandName || ''
      this.company.DistinctiveTitle = data.DistinctiveTitle || ''
      this.company.Profession = data.Profession || ''
      this.company.Street = data.Street || ''
      this.company.StreetNumber = data.StreetNumber || ''
      this.company.Postcode = data.Postcode || ''
      this.company.City = data.City || ''
    },

    onCustomerSaveResult (result) {
      if (result.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'info',
          icon: 'info'
        })

        this.$bus.$emit(window.SocketCommand.Customer.Get, result.customer)
        this.isVisible = false
      } else {
        this.$bus.$emit('app-show-notification', {
          body: result.type || result.message,
          type: 'error',
          icon: 'warning'
        })
      }

      this.loading = false
    },

    onSaveButtonClick () {
      const company = JSON.parse(JSON.stringify(this.company))
      const customer = JSON.parse(JSON.stringify(this.selectedCustomer))
      let i = 0

      this.$refs.companyForm.validate()

      if (this.companyFormValid) {
        if (!customer?.Companies) customer.Companies = []

        if (this.editCompanyId) {
          for (i = 0; i < customer.Companies.length; i++) {
            if (customer.Companies[i].Id.toString() === this.editCompanyId.toString()) {
              customer.Companies[i] = company
            } else {
              if (company.IsDefault) {
                customer.Companies[i].IsDefault = false
              }
            }
          }
        } else {
          if (company.IsDefault) {
            for (i = 0; i < customer.Companies.length; i++) {
              customer.Companies[i].IsDefault = false
            }
          }
          customer.Companies.push(company)
        }

        this.saveCustomer(customer)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    saveCustomer (customer) {
      this.loading = true
      const tmpCustomer = JSON.parse(JSON.stringify(customer))
      const groups = tmpCustomer?.Info?.Groups?.map(group => group?.Id || group || null)?.filter(group => group !== null)
      tmpCustomer.Info.Groups = groups?.length ? groups : [11]

      const payload = {
        ...JSON.parse(JSON.stringify(tmpCustomer.Info)),
        Addresses: JSON.parse(JSON.stringify(tmpCustomer.Addresses || [])),
        Companies: JSON.parse(JSON.stringify(tmpCustomer.Companies || []))
      }

      window.callAS(window.SocketCommand.Customer.Save, payload)
    },

    onCancelButtonClick () {
      if (this.$refs.companyForm) this.$refs.companyForm.resetValidation()
      this.isVisible = false
    }
  }
}
</script>
