<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ appCurrency.Icon }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Common.Misc.Discount') }}
            </div>

            <div class="text-caption">
              {{ $t('Pos.SelectCouponDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <v-col
                v-for="(item, index) in coupons"
                :key="index"
                cols="3"
              >
                <v-list
                  class="pb-0 pt-0"
                  two-line
                >
                  <v-list-item
                    :class="[item.Type === 'P' ? (isDarkMode ? 'green darken-4' : 'green lighten-5') : (isDarkMode ? 'orange darken-4' : 'orange lighten-5'), {'pa-1': !$vuetify.breakpoint.xsOnly}, 'rounded']"
                    class="pa-1"
                    ripple
                    @click="setCoupon(item)"
                  >
                    <v-list-item-avatar v-if="!$vuetify.breakpoint.xsOnly">
                      <v-icon
                        :class="[item.Type === 'P' ? 'green--text' : 'orange--text']"
                        size="36"
                      >
                        {{ item.Type === 'P' ? 'local_offer' : appCurrency.Icon }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content :class="[isDarkMode ? 'grey--text text--lighten-2' : 'grey--text text--darken-2']">
                      <v-list-item-title :class="[$vuetify.breakpoint.xsOnly ? 'text-center' : 'text-left']">
                        -{{ item.Type === 'P' ? item.Discount.toFixed(0) + '%' : $options.filters.currency(item.Discount) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </v-list>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col
                sm="5"
                cols="10"
              >
                <v-text-field
                  v-model="discountPercent.Discount"
                  :error="discountPercent.Error"
                  :hint="$t('Pos.SelectCouponDialog.Field.DiscountPercent.Hint')"
                  :label="$t('Pos.SelectCouponDialog.Field.DiscountPercent.Label')"
                  clearable
                  hi
                  max="100"
                  min="0"
                  step="1"
                  solo
                  suffix="%"
                  type="number"
                />
              </v-col>

              <v-col
                sm="1"
                cols="2"
              >
                <v-btn
                  class="green mt-2"
                  dark
                  icon
                  @click="setCoupon(discountPercent)"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col
                sm="5"
                cols="10"
              >
                <v-text-field
                  v-model="discountAmount.Discount"
                  :error="discountAmount.Error"
                  :hint="$t('Pos.SelectCouponDialog.Field.DiscountAmount.Hint')"
                  :label="$t('Pos.SelectCouponDialog.Field.DiscountAmount.Label')"
                  :suffix="appCurrency.Symbol"
                  clearable
                  min="0"
                  solo
                  type="number"
                />
              </v-col>

              <v-col
                sm="1"
                cols="2"
              >
                <v-btn
                  class="orange mt-2"
                  dark
                  icon
                  @click="setCoupon(discountAmount)"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData   from '../../mixins/appdata'
import { clone } from '@/lib/helper/helper'

export default {
  name      : 'SelectCouponDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    sourceType: {
      type   : [String, Number],
      default: 0
    },
    menuItem: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      isDiscountSelected: false,
      discountAmount    : {
        Type    : 'F',
        Name    : 'Coupon',
        Code    : '',
        Discount: '',
        Error   : false
      },
      discountPercent: {
        Type    : 'P',
        Name    : 'Coupon',
        Code    : '',
        Discount: '',
        Error   : false
      },
      coupons: [
        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 5,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 10,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 0.5,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 1,
          RowId   : '',
          Custom  : ''
        },

        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 15,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 20,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 1.5,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 2,
          RowId   : '',
          Custom  : ''
        },

        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 25,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 30,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 2.5,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 3,
          RowId   : '',
          Custom  : ''
        },

        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 40,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 50,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 5,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 10,
          RowId   : '',
          Custom  : ''
        },

        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 75,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: 100,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 15,
          RowId   : '',
          Custom  : ''
        },
        {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: 20,
          RowId   : '',
          Custom  : ''
        }
      ]
    }
  },
  computed: {
    cartCoupons () {
      const totals = this.posCart?.Totals || []
      return totals.filter(total => total.Name === 'coupon') || []
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.discountAmount = {
          Type    : 'F',
          Name    : 'Coupon',
          Code    : '',
          Discount: '',
          Error   : false
        }
        this.discountPercent = {
          Type    : 'P',
          Name    : 'Coupon',
          Code    : '',
          Discount: '',
          Error   : false
        }
      } else {
        this.isDiscountSelected = false
      }
    }
  },
  methods: {
    setCoupon (coupon) {
      if (this.isDiscountSelected) return
      coupon = clone(coupon)

      if (coupon.Discount === '' || isNaN(coupon.Discount) || coupon.Discount <= 0 || (coupon.Type === 'P' && coupon.Discount > 100)) {
        coupon.Error = true
        return
      } else {
        coupon.Error = false
      }

      this.isDiscountSelected = true

      const discountFormatted = parseFloat(coupon.Discount).toLocaleString(this.$i18n.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })

      if (this.menuItem) {
        coupon.RowId = this.menuItem?.RowId || ''
        if (coupon.Type === 'P') {
          coupon.Type = 'F'
          coupon.Custom = `${ discountFormatted }%`
          coupon.Discount = parseFloat((((parseFloat(this.menuItem.Price) * parseInt(this.menuItem.Quantity)) / 100) * coupon.Discount).toFixed(2))
        } else {
          coupon.Custom = this.$options.filters.currency(parseFloat(coupon.Discount))
        }
      } else {
        if (coupon.Type === 'P') {
          if (this.posCart.TableShift) {
            const cartOrderTotal = parseFloat(this.posCart.SCartTotal) || 0
            const cartCouponsTotal = parseFloat((this.cartCoupons?.reduce((a, b) => parseFloat(a) + parseFloat(b.Discount), 0) || 0))
            const cartSpecialAndExcludedTotal = parseFloat(this.posCart?.ItemsFlat?.filter(item => item.Excluded || (item.SpecialStatus && item.IsSpecial))?.reduce((a, b) => parseFloat(a) + (parseFloat(b.Price) * parseInt(b.Quantity)), 0) || 0)
            const cartOrderTotalWithDiscounts = parseFloat((cartOrderTotal - cartCouponsTotal - cartSpecialAndExcludedTotal).toFixed(2))

            coupon.Type = 'F'
            coupon.Custom = `${ discountFormatted }%`
            coupon.Discount = parseFloat(((cartOrderTotalWithDiscounts / 100) * coupon.Discount).toFixed(2))
          } else {
            coupon.Custom = `${ discountFormatted }%`
          }
        } else {
          coupon.Custom = this.$options.filters.currency(parseFloat(coupon.Discount))
        }
      }

      coupon.Discount = parseFloat(parseFloat(coupon.Discount).toFixed(2))
      coupon.Code = coupon.Custom ? this.menuItem ? this.menuItem.Name + ' ' : this.$t('Common.Misc.Discount').toLocaleUpperCase(this.$i18n.locale) + ' ' : this.$t('Common.Misc.Discount').toLocaleUpperCase(this.$i18n.locale) + ' '
      coupon.Code += coupon.Custom ? `( ${ coupon.Custom } )` : `( ${ (coupon.Type === 'P' ? `${ parseFloat(coupon.Discount).toFixed(2) }%` : this.$options.filters.currency(coupon.Discount)) } )`

      this.$emit('cart:coupon-selected', coupon, this.menuItem)
      this.isVisible = false

      // eslint-disable-next-line no-console
      console.log('setCoupon', coupon, this.menuItem)
    },
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile--link {
  padding : 0 !important;
}
</style>
