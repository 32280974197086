<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        class="d-inline-flex"
      >
        <template v-if="!isTableOrder">
          <!-- Order Type Toggle -->
          <v-btn-toggle
            v-if="appConfig"
            v-model="posCart.OrderType"
            :disabled="disabled"
            mandatory
            borderless
            dense
            style="width: 100%;"
            class="lighten-2 elevation-0 pa-0 ma-0 mr-1"
          >
            <template v-for="item in orderTypes">
              <v-btn
                :key="item.Id"
                :class="[item.Icon === 'directions_bike' ? 'purple white--text' : 'blue darken-3 white--text']"
                :style="posCart.OrderType === item.Id ? 'opacity: 1' : 'opacity: 0.35'"
                :disabled="disabled || (posCart.OrderType !== item.Id && (selectedOrderSourceType.source_type_id === 1 || selectedOrderSourceType.source_type_id === 10))"
                :value="item.Id"
                class="elevation-0 white--text pa-0 flex-grow-1"
                min-width="36"
              >
                <span
                  v-if="posCart.OrderType === item.Id && $vuetify.breakpoint.mdAndUp"
                  class="mr-2"
                >
                  {{ item.Title }}
                </span>

                <v-icon
                  v-if="item.Icon"
                  color="white"
                  size="22"
                >
                  {{ item.Icon }}
                </v-icon>
              </v-btn>
            </template>
          </v-btn-toggle>

          <!-- Order Payment Toggle -->
          <v-btn-toggle
            v-if="appConfig"
            v-model="posCart.PaymentType"
            :disabled="disabled"
            mandatory
            borderless
            dense
            style="width: 100%;"
            class="lighten-2 elevation-0 ma-0 mr-1"
          >
            <template v-for="item in activePaymentTypes">
              <v-btn
                v-if="item.active"
                :key="item.code"
                :disabled="disabled"
                :color="item.color1"
                :value="item.code"
                :style="posCart.PaymentType === item.code ? 'opacity: 1' : 'opacity: 0.35'"
                class="elevation-0 white--text pa-0 flex-grow-1"
                min-width="36"
              >
                <span
                  v-if="posCart.PaymentType === item.code && $vuetify.breakpoint.mdAndUp"
                  class="mr-2"
                >
                  {{ item.code === 'cod' ? $t('Order.PaymentMethod.Cash').Title : item.code === 'pos_card' ? $t('Order.PaymentMethod.Card').Title : item.name }}
                </span>

                <v-icon
                  v-if="item.icon"
                  color="white"
                  size="22"
                >
                  {{ item.code === 'cod' ? appCurrency.Icon : item.icon }}
                </v-icon>

                <v-icon
                  v-if="item.icon2"
                  color="white"
                  size="22"
                >
                  {{ item.icon2 }}
                </v-icon>
              </v-btn>
            </template>
          </v-btn-toggle>

          <!-- Platform Type Toggle -->
          <v-btn-toggle
            v-model="platformType"
            :disabled="disabled"
            mandatory
            borderless
            dense
            style="width: 100%;"
            class="lighten-2 elevation-0 ma-0"
          >
            <v-btn
              :disabled="disabled || selectedOrderSourceType.source_type_id === 4"
              :style="platformType === 'getfoodpro' ? 'opacity: 1' : 'opacity: 0.35'"
              class="red darken-1 pa-0 elevation-0 white--text flex-grow-1"
              value="getfoodpro"
              min-width="36"
              @click="showSelectOrderSourceTypeDialog"
            >
              <span
                v-if="platformType === 'getfoodpro' && $vuetify.breakpoint.mdAndUp"
                class="mr-2"
              >
                <template v-if="parseInt(selectedOrderSourceType.source_type_id) < 100">
                  {{ getOrderSourceTypeLangById(selectedOrderSourceType.source_type_id).Title }}
                </template>
                <template v-else>
                  GETFOOD Pro
                </template>
              </span>

              <svg-logo
                v-if="selectedOrderSourceType.source_type_id > 99"
                :pro-active="false"
                class=""
                one-color="#fff"
                size="32px"
              />

              <v-avatar
                v-if="selectedOrderSourceType.source_type_id < 100"
                size="22px"
              >
                <v-icon
                  color="white"
                  size="22"
                >
                  {{ selectedOrderSourceType.logo }}
                </v-icon>
              </v-avatar>
            </v-btn>

            <v-btn
              v-if="otherSourceTypesActive"
              :disabled="disabled || (selectedOrderSourceType.source_type_id === 1 || selectedOrderSourceType.source_type_id === 4 || selectedOrderSourceType.source_type_id === 10)"
              :style="platformType === 'other' ? 'opacity: 1' : 'opacity: 0.35'"
              class="cyan elevation-0 pa-0 white--text flex-grow-1"
              value="other"
              min-width="36"
              @click="showSelectOrderSourceTypeDialog"
            >
              <span
                v-if="platformType === 'other' && $vuetify.breakpoint.mdAndUp"
                class="mr-2"
              >
                <template v-if="selectedOrderSourceType.source_type_id > 99">
                  {{ selectedOrderSourceType.title }}
                </template>
                <template v-else>
                  {{ $t('Pos.Pos.Toolbar.Source').toLocaleUpperCase($i18n.locale) }}
                </template>
              </span>

              <v-icon
                v-if="selectedOrderSourceType.source_type_id < 100"
                color="white"
                size="22"
              >
                arrow_back
              </v-icon>

              <v-avatar
                v-if="selectedOrderSourceType.source_type_id > 99"
                color="white"
                size="22px"
              >
                <v-icon
                  v-if="!selectedOrderSourceType.logo"
                  color="white"
                  size="22"
                >
                  arrow_back
                </v-icon>
                <img
                  v-if="selectedOrderSourceType.logo"
                  :src="selectedOrderSourceType.logo"
                  height="22"
                  width="auto"
                >
              </v-avatar>
            </v-btn>
          </v-btn-toggle>
        </template>
        <template v-else-if="sourceTypeHasMultipleCatalogs">
          <v-btn
            block
            class="primary darken-1 pa-1 elevation-0 white--text"
            @click="$bus.$emit('platform-catalog-dialog:show', posCart.SourceTypeId)"
          >
            {{ $t('Pos.SelectPlatformCatalogDialog.Button.ChangeCatalog') }}
          </v-btn>
        </template>

        <!-- Cart Drawer Toggle-->
        <template v-if="$vuetify.breakpoint.xsOnly">
          <v-bottom-navigation
            color="green"
            horizontal
            fixed
            height="36"
            dark
          >
            <v-btn
              color="green"
              block
              @click="toggleCartDrawer"
            >
              <span class="text-subtitle-1 font-weight-bold white--text">{{ cartOrderTotal | currency }}</span>

              <v-icon
                color="white"
                small
              >
                add_shopping_cart
              </v-icon>
            </v-btn>
          </v-bottom-navigation>
        </template>
      </v-col>

      <template v-if="!isTableOrder || sourceTypeHasMultipleCatalogs">
        <v-col
          cols="12"
          class="pt-1"
        >
          <v-divider class="" />
        </v-col>
      </template>

      <select-order-source-type-dialog
        :platform-type.sync="platformType"
        :tmp-platform-type="tmpPlatformType"
        :visible.sync="selectOrderSourceTypeDialogVisible"
      />
    </v-row>
  </v-container>
</template>

<script>
import Auth                        from '@/mixins/auth'
import OrderType                   from '@/mixins/orders/orderType'
import SourceType                  from '@/mixins/orders/sourceType'
import SelectOrderSourceTypeDialog from '@/components/pos/SelectOrderSourceTypeDialog'
import SvgLogo                     from '@/components/common/SvgLogo'
import PosOrder                    from '@/mixins/pos/posOrder'
import AppData                     from '@/mixins/appdata'
import PosCartQueue                from '@/mixins/pos/posCartQueue'
import SourceTypeEnum              from '@/api/Enums/SourceTypeEnum'
import { clone }                   from '@/lib/helper/helper'
import PaymentTypeEnum             from '@/api/Enums/PaymentTypeEnum'
import ReceiptProviderTypeEnum     from '@/api/Enums/ReceiptProviderTypeEnum'

export default {
  name      : 'PosToolbar',
  components: {
    SelectOrderSourceTypeDialog,
    SvgLogo
  },
  directives: {},
  mixins    : [Auth, AppData, OrderType, PosCartQueue, PosOrder, SourceType],
  props     : {
    drawer: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      platformType                      : 'getfoodpro',
      tmpPlatformType                   : '',
      selectOrderSourceTypeDialogVisible: false
    }
  },
  computed: {
    activePaymentTypes () {
      const paymentTypes = clone(this.appConfig.PAYMENT_TYPES)
      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) return paymentTypes

      paymentTypes.push({
        name: 'MULTIPLE',
        code: PaymentTypeEnum.MULTIPLE,
        lang: {
          en: {
            Locale: 'en',
            Name  : 'Multiple Transactions'
          },
          el: {
            Locale: 'el',
            Name  : 'Πολλαπλές Συναλλαγές'
          }
        },
        icon          : 'euro_symbol',
        icon2         : 'credit_card',
        active        : true,
        color1        : 'purple darken-3',
        color2        : 'purple darken-4',
        priority      : 3,
        minOrderTotal : 0,
        orderStatus   : 11,
        orderStatusPos: 11
      })

      return paymentTypes
    },
    sourceTypeHasMultipleCatalogs () {
      const activeCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => catalog.Status) || []
      const catalogs = activeCatalogs.filter(catalog => catalog.SourceTypes.includes(parseInt(this.posCart.SourceTypeId))) || []
      return catalogs.length > 1
    },

    otherSourceTypesActive () {
      return this?.appConfig?.SOURCE_TYPES?.OTHER?.filter(item => item.active > 0)?.length || false
    },

    sourceTypes () {
      if (this.platformType === 'getfoodpro') {
        return this.appConfig.SOURCE_TYPES.DEFAULT
      } else {
        return this.appConfig.SOURCE_TYPES.OTHER
      }
    },

    sourceTypeCount () {
      return Object.values(this.sourceTypes)?.filter(sourceType => this.isSourceTypeEnabled(sourceType))?.length || 0
    },

    showSourceTypesDialog () {
      let count = 0

      if (this.platformType === 'getfoodpro') {
        count = this.posCart.SourceTypeId < 100 ? 1 : 0
      } else {
        count = this.posCart.SourceTypeId >= 100 ? 1 : 0
      }

      return this.sourceTypeCount > count
    },

    cartOrderTotal () {
      return this.posCart?.Totals?.find(total => total.Name === 'order_total')?.AmountFormatted || 0
    },

    selectedOrderSourceType () {
      return this.getOrderSourceTypeById(this.posCart.SourceTypeId)
    },

    cartDrawer: {
      get () {
        return this.drawer
      },
      set (val) {
        this.$emit('update:drawer', val)
      }
    },

    showCartItemsBadge () {
      return this.posCart.TotalItems > 0
    }
  },

  watch: {
    'posCart.SourceTypeId': {
      immediate: true,
      handler () {
        this.platformType = this.posCart.SourceTypeId < 100 ? 'getfoodpro' : 'other'
      }
    }
  },

  created () {
  },

  methods: {
    toggleCartDrawer () {
      this.cartDrawer = !this.cartDrawer
    },

    showSelectOrderSourceTypeDialog () {
      if (this.posCart.SourceTypeId === SourceTypeEnum.ONLINE || this.posCart.SourceTypeId === SourceTypeEnum.MOBILE) return
      this.tmpPlatformType = this.platformType.toString()

      this.$nextTick(() => {
        if (this.showSourceTypesDialog) this.selectOrderSourceTypeDialogVisible = true
      })
    },

    isSourceTypeEnabled (item) {
      return parseInt(item.source_type_id) !== SourceTypeEnum.ONLINE && parseInt(item.source_type_id) !== SourceTypeEnum.TABLE && parseInt(item.source_type_id) !== SourceTypeEnum.MOBILE && parseInt(item.active) === 1 && (parseInt(item.source_type_id) > 99 || (this.posUserHasComponentPermission(item.name, 'ACCESS')))
    }
  }
}
</script>

<style scoped>

</style>
