<template>
  <div
    class="ordersQueue v-btn v-btn--icon v-btn--round v-btn--router v-size--default"
    style="width: 36px; height: 36px;"
  >
    <template v-if="todayOrdersCount <= 0">
      <v-btn
        :class="[{'v-btn--active': $route && $route.matched[0] && $route.matched[0].name==='Orders'}]"
        :exact="menuItem.route.exact"
        :to="menuItem.route"
        class="grey--text text--lighten-1"
        height="36"
        icon
        width="36"
      >
        <v-badge
          v-model="ordersQueueCount"
          :class="[ordersQueueCount > 0 ? 'pulse-badge' : '']"
          color="red"
          overlap
          right
        >
          <span slot="badge">
            {{ ordersQueueCount }}
          </span>
          <div class="grey--text text--lighten-1">
            <v-icon class="">
              {{ menuItem.icon }}
            </v-icon>
          </div>
        </v-badge>
      </v-btn>
    </template>

    <v-menu
      v-else
      v-model="showMenu"
      :close-on-click="true"
      :close-on-content-click="false"
      :nudge-bottom="0"
      :nudge-width="650"
      bottom
      left
      max-width="750"
      offset-y
    >
      <template #activator="{on, attrs}">
        <v-btn
          :class="[$route.name==='Orders' ? 'v-btn--active' : '']"
          class="grey--text text--lighten-1"
          height="36"
          icon
          v-bind="attrs"
          width="36"
          v-on="on"
        >
          <v-badge
            v-model="ordersQueueCount"
            :class="[ordersQueueCount > 0 ? 'pulse-badge' : '']"
            color="red"
            overlap
            right
          >
            <span slot="badge">
              {{ ordersQueueCount }}
            </span>
            <div class="grey--text text--lighten-1">
              <v-icon class="">
                {{ menuItem.icon }}
              </v-icon>
            </div>
          </v-badge>
        </v-btn>
      </template>

      <v-card dark>
        <v-card-title class="pr-2">
          <div class="text-h5 grey--text text--lighten-3">
            {{ $tc('Order.Title', 0) }}
          </div>

          <v-spacer />

          <v-btn
            :exact="menuItem.route.exact"
            :to="menuItem.route"
            class="blue white--text"
            @click.native="showMenu=false;"
          >
            {{ $t('Common.OrdersQueue.Button.Title').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-title>

        <v-divider dark />

        <v-list
          id="ordersQueueWaitingOrdersList"
          dark
          subheader
          three-line
        >
          <v-subheader
            v-if="ordersQueueReceived.length > 0"
            class="light-green--text"
          >
            {{ ordersQueueReceived.length }} {{ $tc('Order.Title', ordersQueueReceived.length) }}
            {{ $t('Common.OrdersQueue.ListView.Title.ToAccept') }}
          </v-subheader>

          <div
            v-if="ordersQueueReceived.length > 0"
            :style="'min-height:88px; max-height: ' + (maxContainerHeight/2) + 'px; ' + 'overflow-y: auto;'"
          >
            <template v-for="(order, index) in ordersQueueReceived">
              <v-list-item :key="index">
                <div
                  class="text-caption pr-4"
                  style="display: grid !important;"
                >
                  <v-chip
                    :class="isOrderDelayed(order) ? 'red darken-1' : 'green'"
                    class="white--text mb-1"
                    label
                    style="display: inline-block; text-align: center;"
                  >
                    <strong>{{ '#' + order.Id }}</strong>
                  </v-chip>

                  <v-chip
                    v-if="parseInt(order.OrderTypeId) === 2"
                    class="light-blue light-blue--text text--lighten-4 mb-1"
                    label
                    style="display: inline-block; text-align: center;"
                  >
                    <v-icon>directions_run</v-icon>
                    <span class="pl-2 text-subtitle-1">
                      {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
                    </span>
                  </v-chip>

                  <v-chip
                    v-if="parseInt(order.OrderTypeId) === 1"
                    class="purple purple--text text--lighten-3 mb-1"
                    label
                    style="display: inline-block; text-align: center;"
                  >
                    <v-icon>directions_bike</v-icon>
                    <span class="pl-2 text-subtitle-1">
                      {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
                    </span>
                  </v-chip>
                </div>
                <v-list-item-content>
                  <v-list-item-title>
                    <!--{{ order.FullName }}-->
                    <v-icon
                      :class="[order.CustomerId=='0' ? 'grey--text' : 'amber--text']"
                      class="text--lighten-2 pb-1 pr-1"
                      size="16"
                    >
                      verified_user
                    </v-icon>
                    <span
                      v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && order.CustomerId!='0'"
                      style="cursor: pointer;"
                      @click="$bus.$emit('show-customer-details-dialog', order.CustomerId)"
                    >
                      {{ order.FullName }}
                    </span>
                    <template v-else>
                      <span class="">
                        {{
                          parseInt(order.CustomerId) !== 0 || order.FullName.trim() ? order.FullName : $t('Common.OrdersQueue.ListView.ListItem.Visitor')
                        }}
                      </span>
                    </template>
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ order.DateAdded }} - {{ order.TimeAdded }}
                    <span
                      :class="isOrderDelayed(order) ? 'red--text' : 'green--text'"
                      class="pl-2 text-caption"
                    >
                      <!--{{ getOrderTimeElapsedHumanized(order) }}-->
                      <timeago
                        :auto-update="1"
                        :locale="$i18n.locale"
                        :max-time="86400 * 365"
                        :since="getOrderTimeSince(order)"
                      />
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span
                      v-if="order.Telephone && $options.filters.phone(order.Telephone)"
                      style="white-space: nowrap;"
                    >
                      {{ $t('Common.OrdersQueue.ListView.ListItem.Telephone') }} {{ order.Telephone | phone }}&nbsp;
                    </span>
                    <span
                      v-if="order.Telephone2 && $options.filters.phone(order.Telephone2)"
                      style="white-space: nowrap;"
                    >
                      {{ $t('Common.OrdersQueue.ListView.ListItem.Telephone') }} {{ order.Telephone2 | phone }}&nbsp;
                    </span>
                    <span
                      v-if="order.Telephone3 && $options.filters.phone(order.Telephone3)"
                      style="white-space: nowrap;"
                    >
                      {{ $t('Common.OrdersQueue.ListView.ListItem.Telephone') }} {{ order.Telephone3 | phone }}&nbsp;
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="text-subtitle-1 white--text">
                    <div style="display: flex;">
                      <span>{{ order.Price | currency }}</span>
                      <v-icon
                        :color="getOrderPaymentType(order).color1"
                        class="pl-2"
                      >
                        {{ getOrderPaymentType(order).icon }}
                      </v-icon>
                    </div>
                  </v-list-item-action-text>

                  <div style="padding-bottom: 6px;">
                    <v-btn
                      v-if="order.StatusId!='19' && order.StatusId!='15' && posUserHasComponentPermission('Orders', 'MANAGE')"
                      :class="order.StatusId==0 ? getOrderStatusColorClass(order) : getOrderStatusButtonColorClass(order)"
                      :disabled="false"
                      :icon="$vuetify.breakpoint.xsOnly"
                      :loading="order.loading"
                      class="white--text elevation-0 mr-1 px-1"
                      small
                      style="max-width: 130px;"
                      @click.exact="order.StatusId==0 ? onShowAcceptOrderDialog(order) : orderUpdateStatus(order)"
                      @click.native.stop="order.StatusId==0 ? null : $set(order, 'loading', true)"
                    >
                      <v-icon dark>
                        {{ getOrderStatusIcon(order) }}
                      </v-icon>
                      <span v-if="!$vuetify.breakpoint.xsOnly">{{ getOrderStatusNextLang(order) }}</span>
                    </v-btn>
                    <v-btn
                      v-if="(order.StatusId==0 || order.StatusId==11) && !order.loading && posUserHasComponentPermission('Orders', 'MANAGE')"
                      :class="getOrderStatusButtonColorClass(order)"
                      class="elevation-0 px-1"
                      color="orange"
                      dark
                      small
                      style="min-width: 65px;"
                      @click="showSelectOrderTypeTimeDialog(order)"
                    >
                      <v-icon>timer</v-icon>
                      <span class="ml-1 text-subtitle-1 text-lowercase">
                        {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
                      </span>
                    </v-btn>
                    <v-btn
                      :icon="$vuetify.breakpoint.xsOnly"
                      class="cyan white--text elevation-0 ml-1"
                      small
                      @click="showOrderDetailsDialog(order)"
                    >
                      <span v-if="!$vuetify.breakpoint.xsOnly">{{
                        $t('Common.Button.View').toLocaleUpperCase($i18n.locale)
                      }}</span>
                      <v-icon v-if="$vuetify.breakpoint.xsOnly">
                        remove_red_eye
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < ordersQueueReceived.length-1"
                :key="index + Math.random()"
              />
            </template>
          </div>
        </v-list>

        <v-list
          id="ordersQueueActiveOrdersList"
          dark
          subheader
          three-line
        >
          <v-subheader
            v-if="ordersQueueAccepted.length > 0"
            class="light-green--text"
          >
            {{ ordersQueueAccepted.length }} {{ $tc('Order.Title', ordersQueueAccepted.length) }}
            {{ $t('Common.OrdersQueue.ListView.Title.ToComplete') }}
          </v-subheader>

          <div
            v-if="ordersQueueAccepted.length > 0"
            :style="'min-height:88px; max-height: ' + (maxContainerHeight/2) + 'px; ' + 'overflow-y: auto;'"
          >
            <template v-for="(order, index) in ordersQueueAccepted">
              <v-list-item
                :key="index"
                class="mt-1 mb-1"
                style="max-height: 118px;"
              >
                <div
                  class="text-caption pr-4"
                  style="display: grid !important;"
                >
                  <v-chip
                    :class="isOrderDelayed(order) ? 'red darken-1' : 'green'"
                    class="white--text mb-1"
                    label
                    style="display: inline-block; text-align: center;"
                  >
                    <strong>{{ '#' + order.Id }}</strong>
                  </v-chip>

                  <v-chip
                    v-if="order.OrderTypeId=='2'"
                    class="light-blue light-blue--text text--lighten-4  mb-1"
                    label
                    style="display: inline-block; text-align: center;"
                  >
                    <v-icon>directions_run</v-icon>
                    <span class="pl-2 text-subtitle-1">
                      {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
                    </span>
                  </v-chip>

                  <template v-if="order.DeliveryStaffId>-1">
                    <v-badge
                      color="light-green"
                      overlap
                      right
                      style="z-index: 1; top: 8px; right: 4px;"
                    >
                      <v-icon
                        slot="badge"
                        dark
                        small
                      >
                        done
                      </v-icon>
                    </v-badge>

                    <v-chip
                      v-if="order.OrderTypeId=='1'"
                      class="purple purple--text text--lighten-3 mb-1"
                      label
                      style="display: inline-block; text-align: center;"
                    >
                      <v-icon>directions_bike</v-icon>
                      <span class="pl-2 text-subtitle-1">
                        {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
                      </span>
                    </v-chip>
                  </template>

                  <template v-else>
                    <v-chip
                      v-if="order.OrderTypeId=='1'"
                      class="purple purple--text text--lighten-3 mb-1"
                      label
                      style="display: inline-block; text-align: center;"
                    >
                      <v-icon>directions_bike</v-icon>
                      <span class="pl-2 text-subtitle-1">
                        {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
                      </span>
                    </v-chip>
                  </template>
                </div>
                <v-list-item-content>
                  <v-list-item-title>
                    <!--{{ order.FullName }}-->
                    <v-icon
                      :class="[order.CustomerId=='0' ? 'grey--text' : 'amber--text']"
                      class="text--lighten-2 pb-1 pr-1"
                      size="16"
                    >
                      verified_user
                    </v-icon>
                    <span
                      v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && order.CustomerId!='0'"
                      style="cursor: pointer;"
                      @click="$bus.$emit('show-customer-details-dialog', order.CustomerId)"
                    >
                      {{ order.FullName }}
                    </span>
                    <template v-else>
                      <span class="">
                        {{
                          parseInt(order.CustomerId) !== 0 || order.FullName.trim() ? order.FullName : $t('Common.OrdersQueue.ListView.ListItem.Visitor')
                        }}
                      </span>
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="order.AddressFormatted && order.OrderTypeId=='1'"
                    class=""
                  >
                    {{ order.Address.Street }} {{ order.Address.Number }}, {{ order.Address.City }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ order.DateAdded }} - {{ order.TimeAdded }}
                    <span
                      :class="isOrderDelayed(order) ? 'red--text' : 'green--text'"
                      class="pl-2 text-caption"
                    >
                      <!--{{ getOrderTimeElapsedHumanized(order) }}-->
                      <timeago
                        :auto-update="1"
                        :locale="$i18n.locale"
                        :max-time="86400 * 365"
                        :since="getOrderTimeSince(order)"
                      />
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span
                      v-if="order.Telephone && $options.filters.phone(order.Telephone)"
                      style="white-space: nowrap;"
                    >
                      {{ $t('Common.OrdersQueue.ListView.ListItem.Telephone') }} {{ order.Telephone | phone }}&nbsp;
                    </span>
                    <span
                      v-if="order.Telephone2 && $options.filters.phone(order.Telephone2)"
                      style="white-space: nowrap;"
                    >
                      {{ $t('Common.OrdersQueue.ListView.ListItem.Telephone') }} {{ order.Telephone2 | phone }}&nbsp;
                    </span>
                    <span
                      v-if="order.Telephone3 && $options.filters.phone(order.Telephone3)"
                      style="white-space: nowrap;"
                    >
                      {{ $t('Common.OrdersQueue.ListView.ListItem.Telephone') }} {{ order.Telephone3 | phone }}&nbsp;
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="text-subtitle-1 white--text">
                    <div style="display: flex;">
                      <span>{{ order.Price | currency }}</span>
                      <v-icon
                        :color="getOrderPaymentType(order).color1"
                        class="pl-2"
                      >
                        {{ getOrderPaymentType(order).icon }}
                      </v-icon>
                    </div>
                  </v-list-item-action-text>
                  <div style="padding-bottom: 6px;">
                    <v-btn
                      v-if="order.StatusId!='19' && order.StatusId!='15' && posUserHasComponentPermission('Orders', 'MANAGE')"
                      :class="getOrderStatusButtonColorClass(order)"
                      :disabled="false"
                      :icon="$vuetify.breakpoint.xsOnly"
                      :loading="order.loading"
                      class="white--text elevation-0 mr-1"
                      small
                      style="max-width: 130px;"
                      @click="orderUpdateStatus(order)"
                    >
                      <v-icon dark>
                        {{ getOrderStatusIcon(order) }}
                      </v-icon>
                      <span v-if="!$vuetify.breakpoint.xsOnly">{{ getOrderStatusNextLang(order) }}</span>
                    </v-btn>
                    <v-btn
                      :icon="$vuetify.breakpoint.xsOnly"
                      class="cyan white--text elevation-0 ml-1"
                      small
                      @click="showOrderDetailsDialog(order)"
                    >
                      <span v-if="!$vuetify.breakpoint.xsOnly">{{
                        $t('Common.Button.View').toLocaleUpperCase($i18n.locale)
                      }}</span>
                      <v-icon v-if="$vuetify.breakpoint.xsOnly">
                        remove_red_eye
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < ordersQueueAccepted.length-1"
                :key="index + Math.random()"
              />
            </template>
          </div>
        </v-list>
      </v-card>
    </v-menu>

    <confirm-dialog
      :html-content="confirmDialogAcceptOrderHtmlContent"
      :html-title="`${$t('Common.OrdersQueue.AcceptOrder.Title')} #  ${selectedOrder ? selectedOrder.Id : ''};`"
      :title-class="'red white--text lighten-0'"
      :visible.sync="confirmDialogAcceptOrderVisible"
      :width="700"
      no-button-event="cancel-confirm-change-order-status"
      title-icon="check"
      yes-button-event="accept-order"
      @accept-order="onAcceptOrder"
      @cancel-confirm-change-order-status="onCancelConfirmChangeOrderStatus"
    />

    <order-change-status-dialog
      :cancel-order-confirm-dialog-visible.sync="cancelOrderConfirmDialogVisible"
      :selected-order="selectedOrder"
    />

    <select-order-type-time-dialog
      v-if="selectedOrder"
      :order-type.sync="selectedOrder.OrderTypeId"
      :order-status="selectedOrder.StatusId"
      :selected-time.sync="selectedOrder.OrderTypeTime"
      :update-status.sync="selectedOrder.updateStatus"
      :visible.sync="selectOrderTypeTimeDialogVisible"
      @update-order-time="orderOrderTime"
    />
  </div>
</template>

<script>
import moment                    from 'moment'
import AppData                   from '../../mixins/appdata'
import Auth                      from '../../mixins/auth'
import OrderStatusColor          from '../../mixins/orders/orderStatus'
import OrderPaymentType          from '../../mixins/orders/paymentType'
import OrderTypeTime             from '../../mixins/orders/orderTypeTime'
import Resizable                 from '../../mixins/resizable'
import OrderSoundNotification    from '../../mixins/orders/orderSoundNotification'
import OrderChangeStatusDialog   from '../../components/orders/OrderChangeStatusDialog'
import ConfirmDialog             from '../../components/common/ConfirmDialog'
import SelectOrderTypeTimeDialog from '../../components/pos/SelectOrderTypeTimeDialog'
import OrderStatusEnum           from '@/api/Enums/OrderStatusEnum'
import OrderTypeEnum             from '@/api/Enums/OrderTypeEnum'

export default {
  name      : 'OrderQueue',
  components: {
    'order-change-status-dialog'   : OrderChangeStatusDialog,
    'select-order-type-time-dialog': SelectOrderTypeTimeDialog,
    'confirm-dialog'               : ConfirmDialog
  },
  mixins: [AppData, Auth, OrderStatusColor, OrderPaymentType, OrderTypeTime, OrderSoundNotification, Resizable],
  props : {
    menuItem: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      showMenu                        : false,
      maxContainerHeight              : 88,
      selectedOrder                   : null,
      ordersNotification              : [],
      monitorIntervalId               : null,
      confirmDialogAcceptOrderVisible : false,
      cancelOrderConfirmDialogVisible : false,
      selectOrderTypeTimeDialogVisible: false
    }
  },
  computed: {
    confirmDialogAcceptOrderHtmlContent () {
      return `<div class="text-center"><br>
                <span class="red--text">
                  <strong>${ this.$t('Common.Misc.Attention').toLocaleUpperCase(this.$i18n.locale) }:</strong> ${ this.$t('Common.OrdersQueue.AcceptOrder.Content.Payment') }
                </span><br><br> ${ this.$t('Common.OrdersQueue.AcceptOrder.Content.Choose') }
                <span class="green--text text--darken-1">${ this.$t('Common.OrdersQueue.AcceptOrder.Content.Accept') }</span> ${ this.$t('Common.OrdersQueue.AcceptOrder.Content.Order') }<br><br>
            </div>`
    },

    ordersQueueReceived: {
      get () {
        const orders = this.ordersQueue.filter(function (order) {
          return parseInt(order.StatusId) === OrderStatusEnum.Received || parseInt(order.StatusId) === OrderStatusEnum.Printed || parseInt(order.StatusId) === OrderStatusEnum.NotComplete
        })

        return orders
      },
      set () {

      }
    },
    ordersQueueAccepted: {
      get () {
        const orders = this.ordersQueue.filter(function (order) {
          return parseInt(order.StatusId) === OrderStatusEnum.Accepted || parseInt(order.StatusId) === OrderStatusEnum.Preparation || parseInt(order.StatusId) === OrderStatusEnum.Delivery
        })

        return orders
      },
      set () {

      }
    },
    ordersQueue: {
      get () {
        return this.$DataStore?.ordersDataToday?.items || []
      },
      set () {

      }
    },
    ordersQueueCount: {
      get () {
        if (this.posUserHasComponentPermission('Orders', 'ACCESS') || this.posUserHasComponentPermission('Pos', 'ACCESS')) {
          const today = this.posUserHasRoutePermission('/orders/today') ? window.Vue.ordersCounts.daily_count : 0
          // const future = this.posUserHasRoutePermission('/orders/future') ? window.Vue.ordersCounts.future_count : 0
          // const saved = this.posUserHasRoutePermission('/orders/saved') ? window.Vue.ordersCounts.saved_count : 0
          return today // + future + saved
        }
        return 0
      },
      set () {

      }
    },
    todayOrdersCount: {
      get () {
        if (this.posUserHasComponentPermission('Orders', 'ACCESS') || this.posUserHasComponentPermission('Pos', 'ACCESS')) {
          return this.posUserHasRoutePermission('/orders/today') ? window.Vue.ordersCounts.daily_count : 0
        }
        return 0
      },
      set () {

      }
    }
  },
  watch: {},
  created () {
    // console.log('---------created')
    this.$bus.$on(window.SocketCommand.Order.Get, this.setOrderDetails)
    this.$bus.$on(window.SocketCommand.Order.UpdateStatus, this.orderUpdateStatus)
    this.$bus.$on(window.SocketCommand.Order.StatusUpdated, this.onOrderStatusUpdated)
    this.$bus.$on(window.SocketCommand.Order.UpdateTime, this.onOrderStatusUpdated)
    this.$bus.$on('show-order-accept-dialog', this.onShowAcceptOrderDialog)
    this.$bus.$on('order-canceled', this.onCancelOrder)
    this.$bus.$on('order-type-time-dialog', this.showSelectOrderTypeTimeDialog)
    this.monitorIntervalId = setInterval(this.monitorOrders, 1000 * 30)
  },
  mounted () {
    // console.log('---------mounted')
  },
  updated () {
    // console.log('---------updated')
  },
  destroyed () {
    // console.log('---------destroyed')
  },
  beforeDestroy () {
    // console.log('---------beforeDestroy')
    this.$bus.$off(window.SocketCommand.Order.Get, this.setOrderDetails)
    this.$bus.$off(window.SocketCommand.Order.UpdateStatus, this.orderUpdateStatus)
    this.$bus.$off(window.SocketCommand.Order.StatusUpdated, this.onOrderStatusUpdated)
    this.$bus.$off(window.SocketCommand.Order.UpdateTime, this.onOrderStatusUpdated)
    this.$bus.$off('show-order-accept-dialog', this.onShowAcceptOrderDialog)
    this.$bus.$off('order-canceled', this.onCancelOrder)
    this.$bus.$off('order-type-time-dialog', this.showSelectOrderTypeTimeDialog)
    if (this.monitorIntervalId) clearInterval(this.monitorIntervalId)
  },
  methods: {
    showSelectOrderTypeTimeDialog (order) {
      this.selectedOrder = order
      this.selectOrderTypeTimeDialogVisible = true
    },
    isOrderDelayed (order) {
      const orderNotify = this.appConfig.LOCATION_DATA?.OrderNotify || {
        active                              : true,
        notAcceptedMinsAfterReceived        : 2,
        notCompletedMinsBeforeDeliveryTime  : 10,
        notCompletedMinsBeforeCollectionTime: 5,
        snoozeForMins                       : 5
      }

      if (!orderNotify.active) return false

      const timeElapsed = this.getOrderTimeElapsed(order)
      const deliveryTime = parseInt(order.OrderTypeTime) > 0 ? parseInt(order.OrderTypeTime) : parseInt(this.orderTypeDefaultTimes.delivery_time)
      const collectionTime = parseInt(order.OrderTypeTime) > 0 ? parseInt(order.OrderTypeTime) : parseInt(this.orderTypeDefaultTimes.collection_time)

      const orderStatusId = parseInt(order.StatusId)
      const orderTypeId = parseInt(order.OrderTypeId)

      if (orderStatusId === OrderStatusEnum.Accepted && orderTypeId === OrderTypeEnum.DELIVERY) return timeElapsed >= (deliveryTime - orderNotify.notCompletedMinsBeforeDeliveryTime)
      if (orderStatusId === OrderStatusEnum.Accepted && orderTypeId === OrderTypeEnum.COLLECTION) return timeElapsed >= (collectionTime - orderNotify.notCompletedMinsBeforeCollectionTime)
      if (orderStatusId === OrderStatusEnum.Received || orderStatusId === OrderStatusEnum.NotComplete) return timeElapsed >= orderNotify.notAcceptedMinsAfterReceived

      return false
    },
    monitorOrders () {
      if (!this.posUserHasComponentPermission('Orders', 'MANAGE')) return

      let i = 0
      let order = null

      for (i = 0; i < this.ordersQueueReceived.length; i++) {
        order = this.ordersQueueReceived[i]
        if (this.isOrderDelayed(order)) this.notifyUserForOrder(order)
      }

      for (i = 0; i < this.ordersQueueAccepted.length; i++) {
        order = this.ordersQueueAccepted[i]
        if (this.isOrderDelayed(order)) this.notifyUserForOrder(order)
      }

      this.$bus.$emit('on-monitor-orders')
    },
    orderNotificationRemove (order) {
      const index = this.ordersNotification.findIndex(o => (parseInt(o.Id) === parseInt(order.Id)))
      if (index > -1) this.ordersNotification.splice(index, 1)
    },
    orderNotificationSnooze (order) {
      const index = this.ordersNotification.findIndex(o => (parseInt(o.Id) === parseInt(order.Id)))
      const orderNotification = index < 0 ? null : this.ordersNotification[index]

      if (orderNotification) {
        orderNotification.notification.showLater = true
      }
    },
    notifyUserForOrder (order) {
      const snoozeForMins = this.appConfig.LOCATION_DATA?.OrderNotify?.snoozeForMins || 5
      const index = this.ordersNotification.findIndex(o => (parseInt(o.Id) === parseInt(order.Id)))
      const orderNotification = index < 0 ? null : this.ordersNotification[index]
      let notifyUser = false

      if (!orderNotification) {
        order.notification = {
          datetime : new Date().getTime(),
          showLater: false
        }
        this.ordersNotification.push(order)
        notifyUser = true
      } else {
        if (orderNotification.notification.showLater) {
          const timeNow = new Date().getTime()
          const timeDiff = (timeNow / 1000 / 60) - (orderNotification.notification.datetime / 1000 / 60)
          if (timeDiff >= snoozeForMins) {
            orderNotification.notification.datetime = new Date().getTime()
            orderNotification.notification.showLater = false
            notifyUser = true
          }
        }
      }

      if (notifyUser) {
        const orderStatusId = parseInt(order.StatusId)
        const snotifyId = this.showNotification({
          title       : orderStatusId === 0 ? `${ this.$t('Common.OrdersQueue.Notification.Title') } # ${ order.Id }` : `${ this.$tc('Order.Title', 1) } # ${ order.Id }`,
          body        : orderStatusId === 0 ? `${ this.$t('Common.OrdersQueue.Notification.Content.Delay') + ' ' + this.getOrderTimeElapsedHumanized(order) + ' ' + this.$t('Common.OrdersQueue.Notification.Content.Doing') + ' ' + this.getOrderStatusNextLang(order).toLocaleUpperCase(this.$i18n.locale) + ' ' + this.$t('Common.OrdersQueue.Notification.Content.Cancel') } # ${ order.Id }` : `${ this.$t('Common.OrdersQueue.Notification.Content.Delay') + ' ' + this.getOrderTimeElapsedHumanized(order) + ' ' + this.$t('Common.OrdersQueue.Notification.Content.Doing') + ' ' + this.getOrderStatusNextLang(order).toLocaleUpperCase(this.$i18n.locale) + ' ' + this.$t('Common.OrdersQueue.Notification.Content.Order') } # ${ order.Id }`,
          timeout     : -1,
          closeOnClick: false,
          type        : orderStatusId === 0 ? 'error' : 'warning',
          icon        : 'warning',
          buttons     : [
            {
              text  : this.$t('Common.OrdersQueue.Notification.Button.Close').toLocaleUpperCase(this.$i18n.locale),
              bold  : false,
              class : 'grey caption',
              action: (toastId) => {
                this.$snotify.remove(toastId)
              }
            },
            {
              text  : this.$t('Common.OrdersQueue.Notification.Button.Remind').toLocaleUpperCase(this.$i18n.locale),
              bold  : false,
              class : 'blue caption',
              action: (toastId) => {
                this.orderNotificationSnooze(order)
                this.$snotify.remove(toastId)
              }
            },
            {
              text  : this.$t('Common.OrdersQueue.Notification.Button.View').toLocaleUpperCase(this.$i18n.locale),
              bold  : false,
              class : 'cyan caption',
              action: () => {
                this.showOrderDetailsDialog(order)
              }
            },
            {
              text  : this.getOrderStatusNextLang(order).toLocaleUpperCase(this.$i18n.locale) + ' ' + this.$t('Common.OrdersQueue.Notification.Order').toLocaleUpperCase(this.$i18n.locale),
              bold  : false,
              class : parseInt(order.StatusId) === 11 ? 'light-blue caption' : parseInt(order.StatusId) === 21 ? 'light-green caption' : 'green caption',
              action: () => {
                this.orderUpdateStatus(order)
              }
            },
            {
              text  : this.$t('Common.OrdersQueue.Notification.Button.Cancel').toLocaleUpperCase(this.$i18n.locale),
              bold  : false,
              class : 'deep-orange caption',
              action: () => {
                this.selectedOrder = order
                this.cancelOrderConfirmDialogVisible = true
              }
            }
          ]
        })

        this.$set(order, 'snotifyId', snotifyId)
      }
    },
    getOrderTimeSince (order) {
      return moment(order.DateAdded + ' ' + order.TimeAdded, 'DD-MM-YYYY HH:mm:ss')
    },
    getOrderTimeElapsed (order) {
      return moment.duration(moment(order.DateAdded + ' ' + order.TimeAdded, 'DD-MM-YYYY HH:mm:ss').diff(moment())).asMinutes() * -1
    },
    getOrderTimeElapsedHumanized (order) {
      return moment.duration(moment(order.DateAdded + ' ' + order.TimeAdded, 'DD-MM-YYYY HH:mm:ss').diff(moment())).humanize(false)
    },
    setOrderDetails (data) {
      if (this.selectedOrder) {
        this.selectedOrder.Details = data
        this.showMenu = false
        this.$bus.$emit('show-order-details-dialog', this.selectedOrder)
      }
    },
    onShowAcceptOrderDialog (order) {
      this.selectedOrder = order
      this.confirmDialogAcceptOrderVisible = true
    },
    onCancelConfirmChangeOrderStatus () {
      this.$set(this.selectedOrder, 'loading', false)
      this.confirmDialogAcceptOrderVisible = false
      this.selectedOrder = null
    },
    onAcceptOrder () {
      if (this.selectedOrder.hasOwnProperty('snotifyId')) {
        if (this.selectedOrder.snotifyId) this.$snotify.remove(this.selectedOrder.snotifyId)
      }
      this.orderNotificationRemove(this.selectedOrder)
      this.$set(this.selectedOrder, 'loading', true)

      window.callAS(window.SocketCommand.Order.UpdateStatus, {
        order_id       : this.selectedOrder.Id,
        status_id      : this.selectedOrder.StatusId,
        order_type_time: this.selectedOrder.OrderTypeTime
      }, window.SocketCommand.Order.StatusUpdated)
    },
    getOrderDetails (data) {
      window.callAS(window.SocketCommand.Order.Get, data)
    },
    onCancelOrder (order) {
      this.selectedOrder = order
      if (this.selectedOrder.hasOwnProperty('snotifyId')) {
        if (this.selectedOrder.snotifyId) this.$snotify.remove(this.selectedOrder.snotifyId)
      }
      this.orderNotificationRemove(this.selectedOrder)
    },
    orderOrderTime (data) {
      if (!this.selectedOrder) return

      if (parseInt(this.selectedOrder.StatusId) === OrderStatusEnum.Accepted) {
        window.callAS(window.SocketCommand.Order.UpdateTime, {
          order_id       : this.selectedOrder.Id,
          order_type_time: data?.time || this.selectedOrder.OrderTypeTime,
          set_as_default : data?.setAsDefault ?? false
        })
      } else {
        this.orderUpdateStatus(this.selectedOrder)
      }
    },
    orderUpdateStatus (order) {
      if (parseInt(order.StatusId) === 0) {
        this.onShowAcceptOrderDialog(order)
      } else {
        if (order.snotifyId) this.$snotify.remove(order.snotifyId)
        this.orderNotificationRemove(order)
        this.$set(order, 'loading', true)
        window.callAS(window.SocketCommand.Order.UpdateStatus, {
          order_id       : order.Id,
          status_id      : order.StatusId,
          order_type_time: order.OrderTypeTime
        }, window.SocketCommand.Order.StatusUpdated)
      }
    },
    onOrderStatusUpdated (data) {
      if (data) {
        window.callAS(window.SocketCommand.Order.Daily, { config: 0 })
        window.callAS(window.SocketCommand.Order.Counts, {})
      }
    },
    showOrderDetailsDialog (orderItem) {
      this.selectedOrder = orderItem
      this.getOrderDetails({
        order_id : orderItem.Id,
        status_id: orderItem.StatusId
      })
    },
    onResize () {
      const headerHeight = document.getElementById('appToolbar') && document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter') && document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0

      if (headerHeight && footerHeight) this.maxContainerHeight = (innerHeight - headerHeight - footerHeight - (95 * 2))
    },
    showNotification (data) {
      if (this.$route.name === 'Login') return

      const snotifyId = this.$snotify[data.type](data.body, data.title, {
        timeout        : data.hasOwnProperty('timeout') ? data.timeout : 2000,
        showProgressBar: data.hasOwnProperty('showProgressBar') ? data.showProgressBar : true,
        closeOnClick   : data.hasOwnProperty('closeOnClick') ? data.closeOnClick : true,
        pauseOnHover   : data.hasOwnProperty('pauseOnHover') ? data.pauseOnHover : true,
        position       : data.position ? data.position : 'centerTop',
        buttons        : data.buttons ? data.buttons : [],
        icon           : data.icon ? data.icon : null
      })

      return snotifyId
    }
  }
}
</script>
