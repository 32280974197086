import BaseModel              from '../../../lib/data/Models/BaseModel'
import MessageGroupCollection from '../../Collections/messages/MessageGroupCollection'
import MessageCollection      from '../../Collections/messages/MessageCollection'
import { EventBus }           from '../../../events/eventBus'
import SocketCommand          from '../../SocketCommand'

export default class MessagesModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.count = 0
    this.hasImportant = false
    this.groups = new MessageGroupCollection()
    this.inbox = new MessageCollection()
  }

  boot () {
    EventBus.$on(SocketCommand.Message.Counts, resultData => this.onFetchCounts(resultData))
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */

  fetchCounts () {
    window.callAS(SocketCommand.Message.Counts)
  }

  onFetchCounts (data) {
    if (data.hasOwnProperty('Count')) {
      if (this, this.count !== data.Count && data.Count > 0) {
        this.fetchMessages()
      }
      this.count = data.Count
    }
    if (data.hasOwnProperty('Important')) this.hasImportant = data.Important
  }

  fetchMessages () {
    this.inbox.fetch()
  }
}
