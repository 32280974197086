<template>
  <v-card
    flat
    height="100%"
  >
    <account-toolbar />
    <router-view />
  </v-card>
</template>

<script>
import AccountToolbar from '@/components/account/AccountToolbar'
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'

export default {
  name      : 'Account',
  components: { AccountToolbar },
  directives: {},
  mixins    : [AppData, Auth],
  props     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
