export default {
  Title: 'Επιπλέον Χρεώσεις | Επιπλέον Χρέωση | Επιπλέον Χρεώσεις',

  Toolbar: {
    Button: {
      New: {
        Title: 'Νέα Χρέωση',
        Icon : 'add_box',
        Color: 'primary'
      }
    }
  },

  Table: {
    Headers: {
      Id      : '#',
      Name    : 'Όνομα',
      Quantity: 'Ποσότητα',
      Price   : 'Τιμή',
      Tax     : 'ΦΠΑ',
      Priority: 'Προτεραιότητα',
      Active  : 'Κατάσταση',
      Actions : ''
    }
  },

  Dialog: {
    Delete: {
      Title       : 'Διαγραφή Επιπλέον Χρέωσης;',
      Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε την επιπλέον χρέωση;',
      Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
    }
  },

  AddEditDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Επιπλέον Χρέωσης',
        Subtitle: 'Δημιουργία Νέας Επιπλέον Χρέωσης'
      },
      Edit: {
        Title   : 'Επεξεργασία Επιπλέον Χρέωσης',
        Subtitle: 'Τροποποίηση Στοιχείων Επιπλέον Χρέωσης'
      },
      Icon : 'mdi-credit-card-plus',
      Color: 'indigo'
    },

    Field: {
      Name: {
        Label: 'Όνομα',
        Hint : 'Το όνομα για την επιπλέον χρέωση',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'Πρέπει να συμπληρώσετε όνομα σε τουλάχιστον μια γλώσσα',
          Between       : 'Το όνομα πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Priority: {
        Label: 'Προτεραιότητα',
        Hint : 'Προτεραιότητα επιπλέον χρέωσης',
        Icon : 'sort_by_alpha',
        Error: {
          Valid: 'Η προτεραιότητα πρέπει να είναι ακέραιος αριθμός'
        }
      },
      Quantity: {
        Label: 'Ποσότητα',
        Hint : 'Ποσότητα επιπλέον χρέωσης',
        Icon : 'mdi-counter',
        Error: {
          Valid: 'Η ποσότητα πρέπει να είναι ακέραιος αριθμός μεγαλύτερος του 0'
        }
      },
      Price: {
        Label: 'Τιμή',
        Hint : 'Τιμή επιπλέον χρέωσης',
        Icon : 'mdi-currency-eur',
        Error: {
          Valid: 'Η τιμή πρέπει να είναι αριθμός μεγαλύτερος του 0'
        }
      },
      Tax: {
        Label: 'Κατηγορία ΦΠΑ',
        Hint : 'Επιλέξτε κατηγορία ΦΠΑ',
        Icon : 'percent',
        Error: {
          Required: 'Πρέπει να επιλέξετε κατηγορία ΦΠΑ.'
        }
      },
      FeeCategoryCode: {
        Label: 'Κωδικός Κατηγορίας Τελών',
        Hint : 'Επιλέξτε κωδικό κατηγορίας τελών',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'Πρέπει να επιλέξετε κωδικό κατηγορίας τελών.'
        }
      },
      ClassificationCategoryCode: {
        Label: 'Κωδικός Κατηγορίας Χαρακτηρισμού Εσόδων',
        Hint : 'Επιλέξτε κωδικό  κατηγορίας χαρακτηρισμού εσόδων',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'Πρέπει να επιλέξετε κωδικό κατηγορίας χαρακτηρισμού εσόδων.'
        }
      },
      ClassificationTypeCode: {
        Label: 'Τύπος Κατηγορίας Χαρακτηρισμού Εσόδων',
        Hint : 'Επιλέξτε τύπο κατηγορίας χαρακτηρισμού εσόδων',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'Πρέπει να επιλέξετε τύπο κατηγορίας χαρακτηρισμού εσόδων.'
        }
      },
      Active: {
        Label: 'Κατάσταση'
      }
    }
  }
}
