<template>
  <v-container
    class="pa-1"
    fluid
  >
    <v-row dense>
      <v-col
        class="order-2 order-sm-1"
        cols="12"
        sm="5"
      >
        <v-card class="fill-height pa-1">
          <num-pad
            v-model="numPadModel"
            :disabled="numPadDisabled"
            :initial-value="initValue"
            :max-num-value="maxTransactionAmount"
            :small="$vuetify.breakpoint.xsOnly"
            :button-height="appHasReceiptsProvider && appHasReceiptsEnabled ? 55 : 70"
            currency
            class="fill-height align-content-space-between"
            @numpad:enter="onNumPadEnter"
          >
            <div class="px-1">
              <div class="mb-1 d-flex justify-space-between">
                <v-btn
                  :disabled="selectedPaymentType === PaymentTypeEnum.CARD"
                  dark
                  class="flex-grow-1 mr-1"
                  color="orange"
                  style="min-width: 24px"
                  @click="setNumPadValue(5)"
                >
                  5€
                </v-btn>

                <v-btn
                  :disabled="selectedPaymentType === PaymentTypeEnum.CARD"
                  dark
                  class="flex-grow-1 mr-1"
                  color="orange"
                  style="min-width: 24px"
                  @click="setNumPadValue(10)"
                >
                  10€
                </v-btn>

                <v-btn
                  :disabled="selectedPaymentType === PaymentTypeEnum.CARD"
                  dark
                  class="flex-grow-1 mr-1"
                  color="orange"
                  style="min-width: 24px"
                  @click="setNumPadValue(20)"
                >
                  20€
                </v-btn>

                <v-btn
                  :disabled="selectedPaymentType === PaymentTypeEnum.CARD"
                  dark
                  class="flex-grow-1 mr-1"
                  color="orange"
                  style="min-width: 24px"
                  @click="setNumPadValue(50)"
                >
                  50€
                </v-btn>

                <v-btn
                  :disabled="selectedPaymentType === PaymentTypeEnum.CARD"
                  dark
                  class="flex-grow-1"
                  color="orange"
                  style="min-width: 24px"
                  @click="setNumPadValue(100)"
                >
                  100€
                </v-btn>
              </div>

              <template v-if="appHasReceiptsEnabled && receipt && (appReceiptsProviderDocument.includes(ReceiptTypeEnum.RECEIPT) || appReceiptsProviderDocument.includes(ReceiptTypeEnum.INVOICE))">
                <div>
                  <v-btn-toggle
                    v-model="selectedReceiptType"
                    class="lighten-2 elevation-0 ma-0 mb-1 d-flex"
                    mandatory
                  >
                    <v-btn
                      v-if="appReceiptsProviderDocument.includes(ReceiptTypeEnum.RECEIPT)"
                      :disabled="(hasCardPaymentsThatRequireReceipt && selectedReceiptType !== ReceiptTypeEnum.RECEIPT) || !appReceiptsProviderDocument.includes(ReceiptTypeEnum.RECEIPT)"
                      :style="selectedReceiptType === ReceiptTypeEnum.RECEIPT ? 'opacity: 1;' : 'opacity: 0.35;'"
                      :class="selectedReceiptType === ReceiptTypeEnum.RECEIPT ? 'flex-grow-1' : 'flex-grow-0'"
                      :value="ReceiptTypeEnum.RECEIPT"
                      class="elevation-0 white--text"
                      color="green"
                      style="height: 40px; border: 0;"
                      @click="onSelectedPaymentTypeChange(ReceiptTypeEnum.RECEIPT)"
                    >
                      <v-icon
                        color="white"
                        size="18"
                      >
                        mdi-receipt-text-plus-outline
                      </v-icon>

                      <span
                        v-if="selectedReceiptType === ReceiptTypeEnum.RECEIPT"
                        class="ml-2"
                      >
                        {{ $t('Account.Billing.Field.IsReceiptToggle.Receipt') }}
                      </span>
                    </v-btn>

                    <v-btn
                      v-if="appReceiptsProviderDocument.includes(ReceiptTypeEnum.INVOICE)"
                      :disabled="(hasCardPaymentsThatRequireReceipt && selectedReceiptType !== ReceiptTypeEnum.INVOICE) || !appReceiptsProviderDocument.includes(ReceiptTypeEnum.INVOICE)"
                      :style="selectedReceiptType === ReceiptTypeEnum.INVOICE ? 'opacity: 1;' : 'opacity: 0.35;'"
                      :class="selectedReceiptType === ReceiptTypeEnum.INVOICE ? 'flex-grow-1' : 'flex-grow-0'"
                      :value="ReceiptTypeEnum.INVOICE"
                      class="elevation-0 white--text pl-1 justify-start"
                      color="blue"
                      style="height: 40px; border: 0;"
                      @click="onSelectedPaymentTypeChange(ReceiptTypeEnum.INVOICE)"
                    >
                      <div>
                        <v-badge
                          :color="selectedReceiptData ? 'green darken-1' : 'red'"
                          :value="selectedReceiptType === ReceiptTypeEnum.INVOICE"
                          :icon="selectedReceiptData ? 'mdi-check-bold' : 'mdi-exclamation-thick'"
                          inline
                        />
                      </div>

                      <div class="flex-grow-1">
                        <v-icon
                          color="white"
                          size="18"
                        >
                          mdi-invoice-text-plus-outline
                        </v-icon>

                        <span
                          v-if="selectedReceiptType === ReceiptTypeEnum.INVOICE"
                          class="ml-2"
                        >
                          {{ $t('Account.Billing.Field.IsReceiptToggle.Invoice') }}
                        </span>
                      </div>
                    </v-btn>

                    <v-btn
                      :disabled="noFiscalDocumentDisabled"
                      :style="selectedReceiptType === ReceiptTypeEnum.NONE ? 'opacity: 1;' : 'opacity: 0.35;'"
                      :class="selectedReceiptType === ReceiptTypeEnum.NONE ? 'flex-grow-1' : 'flex-grow-0'"
                      :value="ReceiptTypeEnum.NONE"
                      class="elevation-0 white--text"
                      color="purple"
                      style="height: 40px; border: 0;"
                      @click="onSelectedPaymentTypeChange(ReceiptTypeEnum.NONE)"
                    >
                      <v-icon
                        color="white"
                        size="18"
                      >
                        mdi-receipt-text-remove
                      </v-icon>

                      <span
                        v-if="selectedReceiptType === ReceiptTypeEnum.NONE"
                        class="ml-2"
                      >
                        {{ $t('Account.Billing.Field.IsReceiptToggle.None') }}
                      </span>
                    </v-btn>
                  </v-btn-toggle>
                </div>

                <div>
                  <v-btn-toggle
                    v-model="selectedReceiptNotify"
                    :disabled="selectedReceiptNotifyDisabled"
                    color="success"
                    class="elevation-0 ma-0-f d-flex"
                    dense
                    multiple
                  >
                    <v-btn
                      text
                      value="print"
                      class="flex-grow-1"
                      :disabled="selectedReceiptNotifyDisabled"
                      :color="selectedReceiptNotify.includes(NotifyTypeEnum.PRINT) ? 'success' : ''"
                    >
                      <v-icon
                        left
                        size="22"
                        color="success"
                      >
                        mdi-printer-pos-outline
                      </v-icon>

                      {{ $t('Settings.Documents.OrderReceipt.Notify.Print').toLocaleUpperCase($i18n.locale) }}
                    </v-btn>

                    <v-btn
                      text
                      value="email"
                      class="flex-grow-1 justify-start"
                      :disabled="selectedReceiptNotifyDisabled || !appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
                      :color="selectedReceiptNotify.includes(NotifyTypeEnum.EMAIL) ? 'primary' : ''"
                    >
                      <div class="flex-grow-1 mt-1">
                        <v-icon
                          left
                          size="18"
                          color="primary"
                          class="mb-1"
                        >
                          mdi-email-arrow-right-outline
                        </v-icon>

                        {{ $t('Settings.Documents.OrderReceipt.Notify.Email').toLocaleUpperCase($i18n.locale) }}
                      </div>

                      <div class="mt-auto">
                        <v-btn
                          icon
                          small
                          :disabled="!selectedReceiptNotify.includes(NotifyTypeEnum.EMAIL)"
                          @click.stop="showEmailAddressesDialog"
                        >
                          <v-icon>
                            mdi-plus-box
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </template>
            </div>
          </num-pad>
        </v-card>
      </v-col>

      <v-col
        class="order-1 order-sm-2"
        cols="12"
        sm="7"
      >
        <v-card class="fill-height">
          <v-toolbar
            color="light-blue"
            dark
            flat
            height="50"
          >
            <v-toolbar-title>{{ $t('Restaurant.Tables.Dialog.Payment.Transactions.Title') }}</v-toolbar-title>

            <v-spacer />
          </v-toolbar>

          <v-card-text
            class="pa-0 px-2"
            :style="receipt ? 'min-height: 215px;' : 'min-height: 135px;'"
            style="max-height: 300px; overflow: auto;"
          >
            <v-list
              class="pb-0"
              dense
            >
              <template v-for="(transaction, index) in transactions">
                <template v-if="transaction.PaymentType === PaymentTypeEnum.CARD && appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD">
                  <div :key="`transaction-info-${index}`">
                    <v-alert
                      class="text-caption ma-0 py-0 px-2 rounded-t"
                      border="left"
                      :color="getTransactionInfo(transaction).Color"
                      :icon="getTransactionInfo(transaction).Icon"
                      dense
                      text
                      tile
                    >
                      <template
                        v-if="transaction?.WaitingPos"
                        #append
                      >
                        <v-progress-circular
                          size="18"
                          width="2"
                          color="blue"
                          indeterminate
                        />
                      </template>

                      {{ getTransactionInfo(transaction).Message }}
                    </v-alert>
                    <v-divider :class="[getTransactionInfo(transaction).Color, isDarkMode ? 'darken-2' : 'lighten-4']" />
                  </div>
                </template>

                <v-list-item
                  :key="`transaction-amount-${index}`"
                  :class="[getTransactionInfo(transaction).Color, isDarkMode ? 'darken-4' : 'lighten-5', 'ma-0', transaction.PaymentType === PaymentTypeEnum.CASH ? 'rounded' : 'rounded-b']"
                  style="min-height: 56px;"
                  ripple
                  @click.stop="executeDefaultAction(transaction)"
                >
                  <v-badge
                    :color="transaction.Paid === 'success' || transaction.PaymentType === PaymentTypeEnum.CASH || (appReceiptsProviderType === ReceiptProviderTypeEnum.NONE || appReceiptsProviderType === ReceiptProviderTypeEnum.LOCAL) ? 'success' : transaction.Paid === 'fail' ? 'error' : 'blue'"
                    :offset-x="26"
                    :offset-y="20"
                    overlap
                    right
                  >
                    <v-list-item-avatar
                      :color="getPaymentTypeByCode(transaction.PaymentType).color1"
                      class="ml-0"
                      size="30"
                    >
                      <v-icon
                        dark
                        small
                      >
                        {{ getPaymentTypeByCode(transaction.PaymentType).icon }}
                      </v-icon>
                    </v-list-item-avatar>

                    <template #badge>
                      <v-icon
                        v-if="transaction.Paid === 'success' || transaction.PaymentType !== PaymentTypeEnum.CARD || (appReceiptsProviderType === ReceiptProviderTypeEnum.NONE || appReceiptsProviderType === ReceiptProviderTypeEnum.LOCAL)"
                      >
                        mdi-check
                      </v-icon>

                      <v-icon v-else-if="transaction.Paid === 'fail'">
                        mdi-exclamation-thick
                      </v-icon>

                      <v-icon v-else>
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-badge>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getPaymentTypeByCode(transaction.PaymentType).name }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="transaction.PaymentType === PaymentTypeEnum.CARD && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD && transaction.Nickname">
                      {{ transaction.Nickname }}
                      <span class="text-caption grey--text text-capitalize">({{ transaction.TerminalVendorCode }} #{{ transaction.TerminalId }})</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <template v-if="transaction.PaymentType === PaymentTypeEnum.CARD && appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD">
                      <template v-if="!transaction.Paid">
                        <v-tooltip
                          bottom
                          color="blue"
                        >
                          <template #activator="{on}">
                            <v-btn
                              class="blue--text"
                              :disabled="transaction.WaitingPos"
                              icon
                              ripple
                              small
                              v-on="on"
                              @click.stop="showSelectPaymentTerminalDialog(transaction)"
                            >
                              <v-icon size="20">
                                mdi-credit-card-plus
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>{{ $t('Restaurant.Tables.Dialog.Payment.Transactions.Button.Terminal.Add') }}</span>
                        </v-tooltip>
                      </template>

                      <template v-if="transaction.Paid === 'fail'">
                        <v-tooltip
                          bottom
                          color="orange"
                        >
                          <template #activator="{on}">
                            <v-btn
                              :disabled="transaction.WaitingPos"
                              class="orange--text"
                              icon
                              ripple
                              small
                              v-on="on"
                              @click.stop="onRetryTransaction(transaction)"
                            >
                              <v-icon size="20">
                                mdi-credit-card-sync
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>{{ $t('Restaurant.Tables.Dialog.Payment.Transactions.Button.Terminal.Retry') }}</span>
                        </v-tooltip>
                      </template>

                      <template v-if="transaction.Paid === 'success'">
                        <v-tooltip
                          bottom
                          color="purple"
                        >
                          <template #activator="{on}">
                            <v-btn
                              class="purple--text"
                              :disabled="transaction.WaitingPos"
                              icon
                              ripple
                              small
                              v-on="on"
                              @click.stop="showConfirmRefundTransactionDialog(transaction)"
                            >
                              <v-icon size="20">
                                mdi-credit-card-remove
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>{{ $t('Restaurant.Tables.Dialog.Payment.Transactions.Button.Terminal.Refund') }}</span>
                        </v-tooltip>
                      </template>
                    </template>

                    <template v-if="canRemoveTransaction(transaction)">
                      <v-tooltip
                        bottom
                        color="red"
                      >
                        <template #activator="{on}">
                          <v-btn
                            :class="isDarkMode ? 'red--text text--lighten-2' : 'red--text'"
                            icon
                            ripple
                            small
                            v-on="on"
                            @click.stop="onRemoveTransaction(transaction)"
                          >
                            <v-icon size="20">
                              close
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>{{ $t('Restaurant.Tables.Dialog.Payment.Transactions.Button.Remove') }}</span>
                      </v-tooltip>
                    </template>
                  </v-list-item-action>

                  <v-list-item-action>
                    <v-list-item-action-text
                      class="text-end"
                      style="min-width: 50px;"
                    >
                      <template v-if="transaction.PaymentType === PaymentTypeEnum.CASH">
                        -{{ transaction.Received | currency }}
                      </template>

                      <template v-else>
                        -{{ transaction.Amount | currency }}
                      </template>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>

                <v-divider
                  v-if="index < transactions.length-1"
                  :key="`transaction-divider-${index}`"
                  class="my-1"
                />
              </template>
            </v-list>
          </v-card-text>

          <v-card-actions class="grey--text text--darken-2 pt-0 pb-2 px-2">
            <div style="width: 100%;">
              <v-divider class="my-1" />

              <div class="mb-2">
                <div class="pr-1">
                  <span class="font-weight-medium">{{ $t('Restaurant.Tables.Dialog.Payment.Total') }}:</span>
                  <span class="float-right">{{ orderTotal | currency }}</span>
                </div>

                <div class="pr-1">
                  <span class="font-weight-medium">{{ $t('Restaurant.Tables.Dialog.Payment.Change') }}:</span>
                  <span class="float-right">{{ orderChange | currency }}</span>
                </div>

                <div class="mt-1 pr-1 font-weight-bold">
                  <span class="">{{ $t('Restaurant.Tables.Dialog.Payment.Balance') }}:</span>
                  <span class="float-right">{{ tablePaidBalance | currency }}</span>
                </div>
              </div>

              <div>
                <v-btn-toggle
                  v-if="appConfig"
                  v-model="selectedPaymentType"
                  class="lighten-2 elevation-0 ma-0 d-flex"
                  mandatory
                >
                  <template v-for="item in appConfig.PAYMENT_TYPES">
                    <v-btn
                      v-if="item.active"
                      :key="item.code"
                      :color="item.color1"
                      :style="selectedPaymentType === item.code ? 'opacity: 1;' : 'opacity: 0.35;'"
                      :class="selectedPaymentType === item.code ? 'flex-grow-1' : 'flex-grow-0'"
                      :value="item.code"
                      class="elevation-0 white--text"
                      style="height: 40px; border: 0;"
                    >
                      <span
                        v-if="selectedPaymentType === item.code"
                        class="mr-2"
                      >
                        {{ item.code === PaymentTypeEnum.CASH ? $t('Order.PaymentMethod.Cash').Title : item.code === PaymentTypeEnum.CARD ? $t('Order.PaymentMethod.Card').Title : item.name }}
                      </span>

                      <v-icon
                        v-if="item.icon"
                        color="white"
                        size="22"
                      >
                        {{ item.code === PaymentTypeEnum.CASH ? appCurrency.Icon : item.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-btn-toggle>
              </div>

              <div>
                <v-btn
                  :disabled="!canAddTransaction"
                  block
                  class="green darken-1 white--text mt-1 font-weight-bold elevation-0"
                  style="height: 40px;"
                  @click="onAddTransaction"
                >
                  {{ $t('Common.Button.TransactionAdd') }}
                  <v-spacer />
                  {{ numPadModel | currency }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <customer-email-select-dialog
      :visible.sync="customerSelectEmailDialogVisible"
      :data="customerSelectEmailDialogData"
      @save:emails="onSaveEmailAddresses"
      @cancel:emails="onCancelEmailAddresses"
    />

    <select-payment-terminal-dialog
      :visible.sync="selectPaymentTerminalDialogVisible"
      @terminal:selected="onPaymentTerminalSelected"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Tables.Dialog.Payment.Transactions.Dialog.ConfirmRefund.Content')"
      :html-title="$t('Restaurant.Tables.Dialog.Payment.Transactions.Dialog.ConfirmRefund.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Tables.Dialog.Payment.Transactions.Dialog.ConfirmRefund.Notification')"
      :visible.sync="confirmDialogRefundVisible"
      :width="560"
      info-bar-class="purple lighten-4"
      no-button-class="v-btn--outline grey--text"
      title-class="purple white--text lighten-0"
      yes-button-class="purple white--text"
      yes-button-event="on-confirm-refund"
      @on-confirm-refund="onRefundTransaction"
    />
  </v-container>
</template>

<script>
import vModel                      from '@/mixins/vModel'
import Auth                        from '@/mixins/auth'
import AppData                     from '@/mixins/appdata'
import OrderPaymentType            from '@/mixins/orders/paymentType'
import NumPad                      from '@/components/common/NumPad'
import Translatable                from '@/mixins/translatable'
import SelectPaymentTerminalDialog from '@/components/payments/orders/SelectPaymentTerminalDialog'
import ReceiptTypeEnum             from '@/api/Enums/ReceiptTypeEnum'
import ReceiptProviderTypeEnum     from '@/api/Enums/ReceiptProviderTypeEnum'
import ConfirmDialog               from '@/components/common/ConfirmDialog'
import PaymentTypeEnum             from '@/api/Enums/PaymentTypeEnum'
import NotifyTypeEnum              from '@/api/Enums/NotifyTypeEnum'
import { clone, emailIsGuest }     from '@/lib/helper/helper'
import CustomerEmailSelectDialog   from '@/components/customers/CustomerEmailSelectDialog'

export default {
  name: 'OrderPayment',

  components: {
    CustomerEmailSelectDialog,
    ConfirmDialog,
    SelectPaymentTerminalDialog,
    NumPad
  },
  mixins: [Auth, AppData, vModel, OrderPaymentType, Translatable],
  props : {
    orderId: {
      type   : [Number, String],
      default: null
    },
    cartId: {
      type   : [Number, String],
      default: null
    },
    customerId: {
      type   : [Number, String],
      default: null
    },
    orderTotal: {
      type   : Number,
      default: 0
    },
    receipt: {
      type   : Boolean,
      default: false
    },
    receiptType: {
      type   : String,
      default: ReceiptTypeEnum.RECEIPT
    },
    receiptData: {
      type   : Object,
      default: null
    },
    receiptNotify: {
      type   : Array,
      default: () => []
    },
    receiptNotifyEmails: {
      type   : Array,
      default: () => []
    },
    paymentType: {
      type   : String,
      default: PaymentTypeEnum.CASH
    },
    items: {
      type   : Array,
      default: () => []
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      numPadModel                       : 0,
      confirmDialogRefundVisible        : false,
      selectedPaymentType               : PaymentTypeEnum.CASH,
      selectedTransaction               : null,
      selectedTerminal                  : null,
      numPadDisabled                    : false,
      selectedReceiptNotifyDisabled     : false,
      selectPaymentTerminalDialogVisible: false,
      customerSelectEmailDialogVisible  : false,
      customerSelectEmailDialogData     : null

    }
  },
  computed: {
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },
    PaymentTypeEnum () {
      return PaymentTypeEnum
    },
    ReceiptProviderTypeEnum () {
      return ReceiptProviderTypeEnum
    },
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },

    transactions () {
      return this.vModel || []
    },

    hasCustomerWithEmail () {
      return this.customerEmail && !emailIsGuest(this.customerEmail)
    },

    customerEmail () {
      return this.posCart?.SCustomer?.Email || ''
    },

    selectedReceiptType: {
      get () {
        return this.receiptType || ''
      },
      set (val) {
        this.$emit('update:receiptType', val)
      }
    },

    selectedReceiptData: {
      get () {
        return this.receiptData || null
      },
      set (val) {
        this.$emit('update:receiptData', val)
      }
    },

    selectedReceiptNotify: {
      get () {
        return this.receiptNotify || []
      },
      set (val) {
        this.$emit('update:receiptNotify', val)
      }
    },

    selectedReceiptNotifyEmails: {
      get () {
        return this.receiptNotifyEmails || []
      },
      set (val) {
        this.$emit('update:receiptNotifyEmails', val)
      }
    },

    canAddTransaction () {
      const orderBalance = parseFloat(this.orderBalance.toFixed(2))
      const numPadModel = parseFloat(this.numPadModel.toFixed(2))

      return orderBalance > 0 && numPadModel > 0 && (this.maxTransactionAmount > 0 ? (numPadModel <= orderBalance && orderBalance - numPadModel >= 0) : true)
    },

    transactionsTotal () {
      const val = this.transactions.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0
      return parseFloat(val.toFixed(2))
    },

    transactionsReceivedTotal () {
      const val = this.transactions.reduce((a, b) => a + parseFloat(b.Received), 0) || 0
      return parseFloat(val.toFixed(2))
    },

    transactionsPaidTotal () {
      let val = this.transactions.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0

      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        val = this.transactions.filter(transaction => transaction.Paid === 'success' || transaction.PaymentType !== PaymentTypeEnum.CARD).reduce((a, b) => a + parseFloat(b.Amount), 0) || 0
      }

      return parseFloat(val.toFixed(2))
    },

    hasCardPaymentsThatRequireReceipt () {
      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        return !!this.transactions?.filter(transaction => transaction.PaymentType === PaymentTypeEnum.CARD && transaction.Paid === 'success')?.length
      }

      return false
    },

    noFiscalDocumentDisabled () {
      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        return !!this.transactions?.filter(transaction => transaction.PaymentType === PaymentTypeEnum.CARD)?.length || this.selectedPaymentType === PaymentTypeEnum.CARD
      }

      return false
    },

    initValue () {
      return this.orderBalance > 0 ? this.orderBalance : 0
    },

    orderBalance () {
      const orderTotal = parseFloat(this.orderTotal.toFixed(2))
      return orderTotal - this.transactionsTotal
    },

    orderChange () {
      const orderTotal = parseFloat(this.orderTotal.toFixed(2))
      return Math.abs(this.transactionsReceivedTotal > orderTotal ? orderTotal - this.transactionsReceivedTotal : 0)
    },

    maxTransactionAmount () {
      return this.selectedPaymentType === PaymentTypeEnum.CASH ? undefined : this.orderBalance
    },

    tablePaidBalance () {
      const orderTotal = parseFloat(this.orderTotal.toFixed(2))
      return orderTotal - this.transactionsPaidTotal
    }
  },
  watch: {
    selectedReceiptType: {
      immediate: true,
      handler (val) {
        if (val === ReceiptTypeEnum.NONE) {
          this.selectedPaymentType = PaymentTypeEnum.CASH
          this.selectedReceiptNotifyDisabled = true
          this.selectedReceiptNotify = []
        }

        if (val === ReceiptTypeEnum.RECEIPT) {
          this.selectedReceiptNotify = clone(this.ReceiptsNotify)?.filter(item => this.hasCustomerWithEmail ? true : item !== NotifyTypeEnum.EMAIL) || []
          this.selectedReceiptNotifyDisabled = false
        }

        if (val === ReceiptTypeEnum.INVOICE) {
          this.selectedReceiptNotify = clone(this.ReceiptsInvoiceNotify)?.filter(item => this.hasCustomerWithEmail ? true : item !== NotifyTypeEnum.EMAIL) || []
          this.selectedReceiptNotifyDisabled = false
        }
      }
    },

    selectedPaymentType (val) {
      if (val === PaymentTypeEnum.CARD) this.selectedReceiptType = ReceiptTypeEnum.RECEIPT
    },

    selectedReceiptNotify (newVal) {
      if (newVal.includes(NotifyTypeEnum.EMAIL) && !this.selectedReceiptNotifyEmails?.length && !this.hasCustomerWithEmail) {
        this.showEmailAddressesDialog()
      }
    },

    selectedReceiptNotifyEmails (newVal) {
      if (!newVal?.length && !this.hasCustomerWithEmail) {
        this.onCancelEmailAddresses()
      }
    }
  },
  created () {
    this.selectedPaymentType = this.paymentType || PaymentTypeEnum.CASH

    this.$bus.$on(window.SocketCommand.Order.Payment.Transaction.Add, this.onAddTransactionResponse)
    this.$bus.$on(window.SocketCommand.Order.Payment.Transaction.Remove, this.onRemoveTransactionResponse)
    this.$bus.$on(window.SocketCommand.Order.Payment.Transaction.Sale, this.onTerminalSaleResponse)
    this.$bus.$on(window.SocketCommand.Order.Payment.Transaction.Refund, this.onTerminalRefundResponse)
    this.$bus.$on('customer-company-select-dialog:visible', this.onCustomerCompanySelectDialogVisible)
    this.$bus.$on('invoice-company:selected', this.onInvoiceCompanySelected)
  },
  beforeCreate () {
    this.$bus.$off(window.SocketCommand.Order.Payment.Transaction.Add, this.onAddTransactionResponse)
    this.$bus.$off(window.SocketCommand.Order.Payment.Transaction.Remove, this.onRemoveTransactionResponse)
    this.$bus.$off(window.SocketCommand.Order.Payment.Transaction.Sale, this.onTerminalSaleResponse)
    this.$bus.$off(window.SocketCommand.Order.Payment.Transaction.Refund, this.onTerminalRefundResponse)
    this.$bus.$off('customer-company-select-dialog:visible', this.onCustomerCompanySelectDialogVisible)
    this.$bus.$off('invoice-company:selected', this.onInvoiceCompanySelected)
  },
  methods: {
    showEmailAddressesDialog () {
      this.customerSelectEmailDialogData = {
        receiptId: null,
        order    : {
          Email: this.customerEmail
        },
        data: {
          EmailAddresses: [...this.selectedReceiptNotifyEmails]
        }
      }
      this.customerSelectEmailDialogVisible = true
    },

    onSaveEmailAddresses (data) {
      this.selectedReceiptNotifyEmails = data?.data?.EmailAddresses || []
    },

    onCancelEmailAddresses () {
      const index = this.selectedReceiptNotify?.findIndex(item => item === NotifyTypeEnum.EMAIL)
      if (index > -1) this.selectedReceiptNotify.splice(index, 1)
    },

    setNumPadValue (val) {
      this.numPadModel = val
    },

    onNumPadEnter () {
      this.onAddTransaction()
    },

    onSelectedPaymentTypeChange (val) {
      if (val === ReceiptTypeEnum.INVOICE) {
        this.$bus.$emit('fiscal-document:create', { CustomerId: this.customerId }, ReceiptTypeEnum.INVOICE, this.selectedReceiptData)
      } else {
        this.selectedReceiptData = null
        this.numPadDisabled = false
      }
    },

    onCustomerCompanySelectDialogVisible (visible) {
      this.numPadDisabled = visible
    },

    onInvoiceCompanySelected (company) {
      this.selectedReceiptData = company
    },

    getTransactionInfo (transaction) {
      if (transaction?.WaitingPos) {
        return {
          Type   : 'info',
          Icon   : 'mdi-information',
          Color  : 'blue',
          Message: this.$t('Restaurant.Tables.Dialog.Payment.Transactions.Info.SaleWaitingResult')
        }
      }

      if (!transaction?.Paid) {
        return {
          Type   : 'info',
          Icon   : 'mdi-information',
          Color  : 'blue-grey',
          Message: this.$t('Restaurant.Tables.Dialog.Payment.Transactions.Info.Sale')
        }
      }

      if (transaction?.Paid === 'fail') {
        return {
          Type   : 'error',
          Icon   : 'mdi-alert-circle',
          Color  : 'red',
          Message: this.$t('Restaurant.Tables.Dialog.Payment.Transactions.Info.SaleError')
        }
      }

      if (transaction?.Paid === 'success') {
        return {
          Type   : 'success',
          Icon   : 'mdi-information',
          Color  : 'green',
          Message: this.$t('Restaurant.Tables.Dialog.Payment.Transactions.Info.SaleSuccess')
        }
      }

      if (!transaction?.Refund) {
        return {
          Type   : 'info',
          Icon   : 'mdi-information',
          Color  : 'blue',
          Message: this.$t('Restaurant.Tables.Dialog.Payment.Transactions.Info.RefundError')
        }
      }

      if (transaction?.Refund === 'fail') {
        return {
          Type   : 'error',
          Icon   : 'mdi-alert-circle',
          Color  : 'red',
          Message: this.$t('Restaurant.Tables.Dialog.Payment.Transactions.Info.RefundError')
        }
      }

      if (transaction?.Refund === 'success') {
        return {
          Type   : 'success',
          Icon   : 'mdi-information',
          Color  : 'green',
          Message: this.$t('Restaurant.Tables.Dialog.Payment.Transactions.Info.RefundSuccess')
        }
      }

      return {
        Type   : 'info',
        Icon   : 'mdi-information',
        Color  : 'blue',
        Message: ''
      }
    },

    executeDefaultAction (transaction) {
      if (transaction.WaitingPos) return

      if (!transaction.Paid) this.showSelectPaymentTerminalDialog(transaction)

      if (transaction.Paid === 'fail') this.onRetryTransaction(transaction)

      if (transaction.Paid === 'success') this.showConfirmRefundTransactionDialog(transaction)
    },

    onAddTransaction () {
      if (!this.canAddTransaction) return

      const amount = parseFloat(this.numPadModel.toFixed(2))
      const paymentType = this.selectedPaymentType
      const payload = {
        OrderId      : this.orderId || null,
        CartId       : this.cartId || null,
        TransactionId: Date.now().toString() + (this.transactions.length + 1),
        PaymentType  : paymentType,
        Received     : amount,
        Amount       : amount > this.orderBalance ? this.orderBalance : amount
      }

      this.transactions.push(payload)
      this.numPadModel = this.orderBalance

      window.callAS(window.SocketCommand.Order.Payment.Transaction.Add, payload)
    },
    onAddTransactionResponse (data, payload) {
      const transactionIndex = this.transactions?.findIndex(t => t.TransactionId === payload.TransactionId)

      if (data.status === 'fail' && transactionIndex > -1) {
        this.transactions.splice(transactionIndex, 1)
        return
      }

      if (data.TransactionId && transactionIndex > -1) {
        this.transactions[transactionIndex].TransactionId = data.TransactionId
      }

      this.$bus.$emit('cds:send-message', {
        Action : 'cds:payment-change',
        Payload: {
          Change  : this.orderChange || 0,
          Received: this.transactionsReceivedTotal || 0
        }
      })
    },

    canRemoveTransaction (transaction) {
      if (!transaction || PaymentTypeEnum.ONLINE_CARDS.includes(transaction.PaymentType)) return false

      if (transaction.PaymentType === PaymentTypeEnum.CARD && this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        return !transaction.Paid || transaction.Paid === 'fail'
      }

      return true
    },

    onRemoveTransaction (transaction) {
      this.numPadModel = this.orderBalance

      const payload = {
        OrderId      : this.orderId || null,
        CartId       : this.cartId || null,
        TransactionId: transaction?.TransactionId
      }

      window.callAS(window.SocketCommand.Order.Payment.Transaction.Remove, payload)
    },
    onRemoveTransactionResponse (data, payload) {
      if (data.status === 'fail') return

      const transactionIndex = this.transactions?.findIndex(t => t.TransactionId === payload.TransactionId)
      this.transactions.splice(transactionIndex, 1)

      this.$bus.$emit('cds:send-message', {
        Action : 'cds:payment-change',
        Payload: {
          Change  : this.orderChange || 0,
          Received: this.transactionsReceivedTotal || 0
        }
      })
    },

    onRetryTransaction (transaction) {
      transaction.WaitingPos = true

      const payload = {
        OrderId      : this.orderId || null,
        CartId       : this.cartId || null,
        TerminalId   : transaction?.TerminalId,
        TransactionId: transaction?.TransactionId,
        ReceiptType  : transaction?.Data?.ReceiptType,
        ReceiptData  : transaction?.Data?.ReceiptData,
        Items        : transaction?.Data?.Items || []
      }

      window.callAS(window.SocketCommand.Order.Payment.Transaction.Sale, payload)
    },

    showConfirmRefundTransactionDialog (transaction) {
      this.selectedTransaction = transaction
      this.confirmDialogRefundVisible = true
    },

    onRefundTransaction () {
      if (!this.selectedTransaction) return
      this.selectedTransaction.WaitingPos = true

      const payload = {
        OrderId      : this.orderId || null,
        CartId       : this.cartId || null,
        TransactionId: this.selectedTransaction?.TransactionId
      }

      window.callAS(window.SocketCommand.Order.Payment.Transaction.Refund, payload)
    },

    showSelectPaymentTerminalDialog (transaction) {
      this.selectedTransaction = transaction
      this.selectPaymentTerminalDialogVisible = true
    },
    onPaymentTerminalSelected (terminal) {
      this.selectedTerminal = terminal

      const transactionTerminalInUse = this.transactions?.some(t => t.TerminalId === terminal.TerminalId && t.Status !== 'APPROVED')
      this.$set(this.selectedTransaction, 'TerminalId', terminal.TerminalId)

      if (transactionTerminalInUse) {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Restaurant.Tables.Dialog.SelectTerminal.Notification.Error'),
          type   : 'warning',
          icon   : 'warning',
          timeout: 4000
        })
      } else {
        this.selectedTransaction.WaitingPos = true

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Tables.Dialog.SelectTerminal.Notification.Success'),
          type: 'info',
          icon: 'info'
        })

        const items = this.items.map(item => {
          return {
            RowId   : item.RowId,
            Quantity: item.Quantity
          }
        })

        const payload = {
          OrderId      : this.orderId || null,
          CartId       : this.cartId || null,
          TerminalId   : this.selectedTerminal?.TerminalId,
          TransactionId: this.selectedTransaction?.TransactionId,
          ReceiptType  : this.selectedReceiptType || ReceiptTypeEnum.RECEIPT,
          ReceiptData  : this.selectedReceiptData || null,
          Items        : items
        }

        window.callAS(window.SocketCommand.Order.Payment.Transaction.Sale, payload)
      }
    },

    async onTerminalRefundResponse (response, payload) {
      this.$bus.$emit('cart:cart-update', window.SocketCommand.Order.Payment.Transaction.Refund)
    },

    async onTerminalSaleResponse (response, payload) {
      this.$bus.$emit('cart:cart-update', window.SocketCommand.Order.Payment.Transaction.Sale)
    }
  }
}
</script>

<style scoped>
/deep/ .v-list-item__action {
  margin : 0;
}

/deep/ .v-alert__icon.v-icon {
  font-size : 18px;
}

/deep/ .v-alert__icon {
  margin-right : 6px;
}
</style>
