<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="$t('Customer.CustomersExportDialog.Toolbar.Color')">
            <v-icon dark>
              {{ $t('Customer.CustomersExportDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Customer.CustomersExportDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomersExportDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="dataForm"
            v-model="dataFormValid"
            @submit.prevent
          >
            <v-container
              fluid
            >
              <v-row>
                <v-col
                  cols="12"
                  class="py-0 my-0"
                >
                  <h5 class="mb-1">
                    {{ $t('Customer.CustomersExportDialog.Field.Period.Label') }}
                  </h5>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="pt-0"
                >
                  <v-menu
                    v-model="startDateVisible"
                    :close-on-content-click="false"
                    left
                    max-width="290px"
                    min-width="140px"
                    offset-y
                    style="width: 100%"
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="startDateFmt"
                        :rules="validationRules.dataForm.startDate"
                        :label="$t('Common.Date.PeriodFrom')"
                        clearable
                        height="36px"
                        hide-details
                        prepend-inner-icon="event"
                        readonly
                        filled
                        v-on="on"
                        @blur="onStartDateBlur"
                      />
                    </template>

                    <v-date-picker
                      v-model="startDate"
                      :allowed-dates="allowedStartDates"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      first-day-of-week="1"
                      scrollable
                      @input="onStartDateInput"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="pt-0"
                >
                  <v-menu
                    v-model="endDateVisible"
                    :close-on-content-click="false"
                    left
                    max-width="290px"
                    min-width="140px"
                    offset-y
                    style="width: 100%"
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="endDateFmt"
                        :rules="validationRules.dataForm.endDate"
                        :label="$t('Common.Date.PeriodTo')"
                        clearable
                        height="36px"
                        hide-details
                        prepend-inner-icon="event"
                        readonly
                        filled
                        v-on="on"
                        @blur="onEndDateBlur"
                      />
                    </template>

                    <v-date-picker
                      v-model="endDate"
                      :allowed-dates="allowedEndDates"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      first-day-of-week="1"
                      scrollable
                      @input="onEndDateInput"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-col cols="12">
                  <h5 class="mb-1">
                    {{ $t('Customer.CustomersExportDialog.Field.Group.Hint') }}
                  </h5>
                  <v-select
                    v-model="model.selectedGroups"
                    :rules="validationRules.dataForm.selectedGroups"
                    :hint="$t('Customer.CustomersExportDialog.Field.Group.Hint')"
                    :items="customerGroups"
                    :label="$t('Customer.CustomersExportDialog.Field.Group.Label')"
                    :prepend-inner-icon="$t('Customer.CustomersExportDialog.Field.Group.Icon')"
                    item-text="Name"
                    item-value="Id"
                    multiple
                    required
                    small-chips
                    hide-details
                    clearable
                    chips
                    filled
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-col
                  cols="12"
                  class="py-0 my-0"
                >
                  <div>
                    <div class="d-inline-block">
                      <h5 class="mb-1">
                        {{ $t('Customer.CustomersExportDialog.Field.LastOrder.Hint') }}
                      </h5>
                    </div>

                    <div class="d-inline-block float-right">
                      <v-switch
                        v-model="model.withLastOrders"
                        inset
                        :label="model.withLastOrders ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                        color="blue"
                        class="mt-0 pt-0"
                        hide-details
                      />
                    </div>
                  </div>
                </v-col>

                <template v-if="model.withLastOrders">
                  <v-col cols="12">
                    <v-select
                      v-model="model.lastOrder"
                      :rules="validationRules.dataForm.lastOrder"
                      :items="$t('Customer.CustomersExportDialog.Field.LastOrder.Items')"
                      :label="model.lastWithOrders ? $t('Customer.CustomersExportDialog.Field.LastOrder.HintWithOrders') : $t('Customer.CustomersExportDialog.Field.LastOrder.HintWithoutOrders')"
                      :prepend-inner-icon="$t('Customer.CustomersExportDialog.Field.LastOrder.Icon')"
                      item-text="Name"
                      item-value="Value"
                      multiple
                      required
                      small-chips
                      hide-details
                      clearable
                      chips
                      filled
                    />
                  </v-col>

                  <v-col cols="3">
                    <h5 class="mb-1">
                      {{ $t('Customer.CustomersExportDialog.Field.LastWithOrders.Label') }}
                    </h5>
                    <v-switch
                      v-model="model.lastWithOrders"
                      inset
                      :label="model.lastWithOrders ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                      color="success"
                      class="mt-0 pt-0"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="3">
                    <h5 class="mb-1">
                      {{ $t('Customer.CustomersExportDialog.Field.LastWithoutOrders.Label') }}
                    </h5>
                    <v-switch
                      v-model="model.lastWithoutOrders"
                      inset
                      :label="model.lastWithoutOrders ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                      color="success"
                      class="mt-0 pt-0"
                      hide-details
                    />
                  </v-col>
                </template>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-col cols="3">
                  <h5 class="mb-1">
                    {{ $t('Customer.CustomersExportDialog.Field.Registered.Label') }}
                  </h5>
                  <v-switch
                    v-model="model.registered"
                    inset
                    :label="model.registered ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    color="success"
                    class="mt-0 pt-0"
                    hide-details
                  />
                </v-col>

                <v-col cols="3">
                  <h5 class="mb-1">
                    {{ $t('Customer.CustomersExportDialog.Field.Guest.Label') }}
                  </h5>
                  <v-switch
                    v-model="model.guest"
                    inset
                    :label="model.guest ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    color="success"
                    class="mt-0 pt-0"
                    hide-details
                  />
                </v-col>

                <v-col cols="3">
                  <h5 class="mb-1">
                    {{ $t('Customer.CustomersExportDialog.Field.WithAddress.Label') }}
                  </h5>
                  <v-switch
                    v-model="model.withAddress"
                    inset
                    :label="model.withAddress ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    color="success"
                    class="mt-0 pt-0"
                    hide-details
                  />
                </v-col>

                <v-col cols="3">
                  <h5 class="mb-1">
                    {{ $t('Customer.CustomersExportDialog.Field.ForSms.Label') }}
                  </h5>
                  <v-switch
                    v-model="model.forSms"
                    inset
                    :label="model.forSms ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    color="success"
                    class="mt-0 pt-0"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Customer.CustomersExportDialog.Button.Export') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name      : 'CustomersExportDialog',
  components: {},
  mixins    : [],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    customerGroups: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      isLoading       : false,
      dataFormValid   : false,
      startDateVisible: false,
      endDateVisible  : false,
      datePeriod      : '',
      startDate       : '',
      startDateFmt    : '',
      endDate         : '',
      endDateFmt      : '',
      model           : {
        selectedGroups   : [],
        lastOrder        : [],
        registered       : true,
        guest            : true,
        withAddress      : false,
        forSms           : false,
        withLastOrders   : false,
        lastWithOrders   : false,
        lastWithoutOrders: true
      },
      validationRules: {
        dataForm: {
          selectedGroups: [],
          lastOrder     : [],
          startDate     : [],
          endDate       : []
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  watch: {
    'model.withAddress': function (newVal) {
      if (newVal) this.model.forSms = false
    },
    'model.forSms': function (newVal) {
      if (newVal) this.model.withAddress = false
    },

    'model.lastWithOrders': function (newVal) {
      this.model.lastWithoutOrders = !newVal
    },
    'model.lastWithoutOrders': function (newVal) {
      this.model.lastWithOrders = !newVal
    },

    startDate (newVal) {
      if (this.endDate && newVal) {
        this.datePeriod = newVal + ' 00:00_' + this.endDate + ' 00:00'
      } else {
        this.datePeriod = ''
      }
    },

    endDate (newVal) {
      if (this.startDate && newVal) {
        this.datePeriod = this.startDate + ' 00:00_' + newVal + ' 00:00'
      } else {
        this.datePeriod = ''
      }
    },

    isVisible (newVal) {
      if (newVal) {
        this.initData()

        this.$nextTick(() => {
          if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
        })
      }
    }
  },

  created () {
    // console.log('1. created');
  },

  mounted () {
    // console.log('2. mounted');
  },

  updated () {
    // console.log('3. updated');
  },

  beforeDestroy () {
    // console.log('4. destroyed');
  },

  methods: {
    initData (dateStartStr, dateEndStr) {
      this.datePeriod = ''
      this.startDateFmt = dateStartStr ? this.formatDate(dateStartStr) ?? '' : ''
      this.endDateFmt = dateEndStr ? this.formatDate(dateEndStr) ?? '' : ''
      this.startDate = dateStartStr ? this.parseDate(this.startDateFmt) : ''
      this.endDate = dateEndStr ? this.parseDate(this.endDateFmt) : ''
      this.model = {
        selectedGroups   : [],
        lastOrder        : [],
        registered       : true,
        guest            : true,
        withAddress      : false,
        forSms           : false,
        withLastOrders   : false,
        lastWithOrders   : false,
        lastWithoutOrders: true
      }
    },

    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },

    parseDate (date) {
      if (!date) {
        return null
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    },

    allowedStartDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.endDate ? new Date(this.endDate).getTime() : null
      return maxDate ? (currDate <= maxDate) : true
    },

    allowedEndDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.startDate ? new Date(this.startDate).getTime() : null
      return maxDate ? (currDate >= maxDate) : true
    },

    todayDateStr () {
      return this.getLocalISOString(new Date()).slice(0, 10)
    },

    getLocalISOString (date) {
      const offset = date.getTimezoneOffset()
      const offsetAbs = Math.abs(offset)
      const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString()
      return `${ isoString.slice(0, -1) }${ offset > 0 ? '-' : '+' }${ String(Math.floor(offsetAbs / 60)).padStart(2, '0') }:${ String(offsetAbs % 60).padStart(2, '0') }`
    },

    onStartDateBlur () {
      this.startDate = this.parseDate(this.startDateFmt)
    },

    onEndDateBlur () {
      this.endDate = this.parseDate(this.endDateFmt)
    },

    onStartDateInput (event) {
      this.startDateFmt = this.formatDate(event)
      this.startDateVisible = false
    },

    onEndDateInput (event) {
      this.endDateFmt = this.formatDate(event)
      this.endDateVisible = false
    },

    onSaveButtonClick () {
      this.$refs.dataForm.validate()

      if (this.dataFormValid) {
        this.isLoading = true

        this.$emit('export:data', {
          Period           : this.datePeriod,
          Groups           : this.model.selectedGroups,
          Registered       : this.model.registered,
          Guest            : this.model.guest,
          WithAddress      : this.model.withAddress,
          ForSms           : this.model.forSms,
          LastWithOrders   : this.model.lastWithOrders,
          LastWithoutOrders: this.model.lastWithoutOrders,
          LastOrder        : this.model.lastOrder || [],
          WithLastOrders   : this.model.withLastOrders
        })

        this.onCancelButtonClick()
      }
    },

    onCancelButtonClick () {
      if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      this.initData()
      this.isVisible = false
      this.isLoading = false
    }

  }
}
</script>
