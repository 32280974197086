<template>
  <v-app>
    <vue-snotify />

    <app-navigation-drawer
      :items="appConfig.navigationDrawerItems"
      :navigation-drawer.sync="navigationDrawer"
      @app:exit="onAppExit"
    />

    <app-toolbar
      :toolbar-title="toolbarTitle"
      :items="appConfig.toolbarNavigationItems"
      :navigation-drawer.sync="navigationDrawer"
      @app:logout="showLogOutDialog"
    />

    <v-main>
      <slot :navigation-drawer="navigationDrawer" />

      <customer-add-dialog
        v-if="posUserHasComponentPermission('Customers', 'MANAGE')"
        :preset-data="customerAddPresetData"
        :visible.sync="customerAddDialogVisible"
      />

      <customer-details-dialog
        v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
        :customer-id="selectedCustomerId"
        :visible.sync="customerDetailsDialogVisible"
      />

      <order-details-dialog
        :order-details-dialog-visible.sync="orderDetailsDialogVisible"
        :display-order="selectedOrder"
      />

      <confirm-dialog
        :html-content="`${$t('Common.Misc.LogoutDialog.Body')}<br>`"
        :html-title="`${$t('Common.Misc.LogoutDialog.Title')}`"
        :info-bar-body="$t('Common.Misc.LogoutDialog.Warning')"
        :title-class="'red white--text lighten-0'"
        :visible.sync="confirmDialogLogOutVisible"
        info-bar
        yes-button-event="logout-yes"
        @logout-yes="onLogOutYes"
      />

      <confirm-dialog
        :html-content="`${$t('Common.Misc.ExitAppDialog.Body')}<br>`"
        :html-title="`${$t('Common.Misc.ExitAppDialog.Title')}`"
        :info-bar-body="$t('Common.Misc.ExitAppDialog.Warning')"
        :title-class="'red white--text lighten-0'"
        :visible.sync="confirmDialogExitAppVisible"
        info-bar
        title-icon="exit_to_app"
        yes-button-event="exit-yes"
        @exit-yes="onExitAppYes"
      />

      <manager-pin-dialog
        :visible.sync="confirmManagerPinDialogVisible"
        :data="managerPinDialogData"
      />

      <reservation-add-dialog
        :visible.sync="reservationAddDialogVisible"
        :reservation-to-edit="reservationToEdit"
        :caller-id="reservationCallerID"
        :customer="reservationCustomer"
        :table="reservationTable"
        :area="reservationTableArea"
      />

      <customer-email-select-dialog
        :visible.sync="customerSelectEmailDialogVisible"
        :data="customerSelectEmailDialogData"
        @save:emails="emailFiscalDocumentConfirm"
      />

      <customer-company-select-dialog
        :visible.sync="customerSelectCompanyDialogVisible"
        :data="customerSelectCompanyDialogData"
        @save:company="createFiscalDocumentFromInvoice"
      />

      <confirm-dialog
        :html-content="selectedOrderHasRefundableTransactions ? $t('Order.OrderDetailsDialog.Receipt.ConfirmDialog.CancelWithRefund.Content', {amount: $options.filters.currency(selectedOrderRefundableTransactionsTotal)}) : $t('Order.OrderDetailsDialog.Receipt.ConfirmDialog.Cancel.Content')"
        :html-title="$t('Order.OrderDetailsDialog.Receipt.ConfirmDialog.Cancel.Title')"
        :info-bar="true"
        :info-bar-body="selectedOrderHasRefundableTransactions ? $t('Order.OrderDetailsDialog.Receipt.ConfirmDialog.CancelWithRefund.Notification') : $t('Order.OrderDetailsDialog.Receipt.ConfirmDialog.Cancel.Notification')"
        info-bar-class="red lighten-4"
        :visible.sync="confirmCancelFiscalDocDialogVisible"
        :width="560"
        title-class="red white--text lighten-0"
        :cancel-button="selectedOrderHasRefundableTransactions"
        :cancel-button-text="$t('Common.Button.Close').toLocaleUpperCase($i18n.locale)"
        no-button-class="v-btn--outline grey--text"
        :no-button-event="selectedOrderHasRefundableTransactions ? 'on-confirm-cancel-receipt' : 'confirm-dialog-no'"
        :no-button-text="selectedOrderHasRefundableTransactions ? $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) : $t('Common.Button.No').toLocaleUpperCase($i18n.locale)"
        yes-button-class="red white--text"
        :yes-button-text="selectedOrderHasRefundableTransactions ? $t('Common.Button.CancelWithRefund').toLocaleUpperCase($i18n.locale) : $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale)"
        :yes-button-event="selectedOrderHasRefundableTransactions ? 'on-confirm-cancel-receipt-refund' : 'on-confirm-cancel-receipt'"
        @on-confirm-cancel-receipt-refund="cancelFiscalDocument(selectedReceiptItem, true)"
        @on-confirm-cancel-receipt="cancelFiscalDocument(selectedReceiptItem, false)"
      />
    </v-main>

    <v-footer
      id="appFooter"
      class="secondary white--text gfp-footer px-1"
      color="grey"
      fixed
      app
    >
      <div
        class="gfp-footer text-center"
        style="width: 100%;"
      >
        © {{ new Date().getFullYear() }} <a
          href="https://www.otodev.gr/"
          target="_blank"
          class="gfp-footer"
        ><strong>OTO Dev</strong></a> - Powered By <a
          href="https://getfoodpro.gr/"
          target="_blank"
          class="gfp-footer"
        ><strong>GETFOOD Pro</strong></a>

        <div
          v-if="posUserCan('AccountOverview', 'ACCESS')"
          class="float-right mx-1"
        >
          <v-menu
            v-model="themeMenu"
            :close-on-content-click="false"
            min-width="300"
          >
            <template #activator="{on, attrs}">
              <v-btn
                depressed
                icon
                dark
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi mdi-invert-colors</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-body-2 font-weight-bold pa-3 pb-1">
                {{ $t('Account.Overview.Field.Theme.Title') }}

                <v-spacer />

                <v-btn
                  icon
                  small
                  @click="themeMenu = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-divider />

              <v-item-group
                v-if="posUser"
                v-model="posUser.theme_mode"
                mandatory
                @change="onSelectThemeClick"
              >
                <v-container fluid>
                  <v-row>
                    <v-col
                      v-for="theme in $t('Account.Overview.Field.Theme.Items')"
                      :key="theme.Value"
                      cols="4"
                    >
                      <v-item
                        v-slot="{active, toggle}"
                        :value="theme.Value"
                      >
                        <v-card
                          :dark="theme.Value === 'auto' ? isSystemThemeDark() : theme.Value !== 'light'"
                          :light="theme.Value === 'auto' ? !isSystemThemeDark() : theme.Value === 'light'"
                          :style="active ? 'border: #59ae4a solid 2px;' : 'border: #3b98f6 solid 2px;'"
                          :value="theme.Value"
                          width="85"
                          max-width="85"
                          @click="toggle"
                        >
                          <div class="pa-2">
                            <v-img :src="`/assets/img/${theme.Value}-mode.svg`" />

                            <v-avatar
                              v-if="active"
                              color="light-green lighten-4"
                              style="position: absolute; left: -12px; top: -12px;"
                              size="25"
                            >
                              <v-icon
                                color="success"
                              >
                                mdi-check-circle
                              </v-icon>
                            </v-avatar>
                          </div>

                          <v-divider />

                          <div class="align-start d-flex px-3 py-1">
                            <div class="d-inline-flex">
                              <div>
                                <div class="text-body-2">
                                  {{ theme.Label }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-footer>

    <loading-modal :visible="showLoader" />
  </v-app>
</template>

<script>
import AppData                         from '@/mixins/appdata'
import Auth                            from '@/mixins/auth'
import CustomerAddDialog               from '@/components/customers/CustomerAddDialog.vue'
import OrderDetailsDialog              from '@/components/orders/OrderDetailsDialog.vue'
import ConfirmDialog                   from '@/components/common/ConfirmDialog.vue'
import CustomerDetailsDialog           from '@/components/customers/CustomerDetailsDialog'
import ManagerPinDialog                from '@/components/common/ManagerPinDialog.vue'
import AppNavigationDrawer             from '@/components/common/AppNavigationDrawer.vue'
import AppToolbar                      from '@/components/common/AppToolbar.vue'
import isElectron                      from '@/electron/isElectron'
import ipcRendererElectron             from '@/electron/ipcRendererElectron'
import ipcCommandsEnum                 from '@/electron/ipcCommandsEnum'
import { stopCloudUpdatePollingCheck } from '@/api/ApiUpdate'
import LoadingModal                    from '@/components/common/LoadingModal.vue'
import ReservationAddDialog            from '@/components/restaurant/reservations/ReservationAddDialog.vue'
import ReceiptsGlobal                  from '@/mixins/receipts/receiptsGlobal'
import CustomerEmailSelectDialog       from '@/components/customers/CustomerEmailSelectDialog.vue'
import CustomerCompanySelectDialog     from '@/components/customers/CustomerCompanySelectDialog.vue'

export default {
  name      : 'DefaultLayout',
  components: {
    CustomerCompanySelectDialog,
    CustomerEmailSelectDialog,
    ReservationAddDialog,
    LoadingModal,
    AppToolbar,
    AppNavigationDrawer,
    ManagerPinDialog,
    CustomerDetailsDialog,
    'customer-add-dialog' : CustomerAddDialog,
    'order-details-dialog': OrderDetailsDialog,
    'confirm-dialog'      : ConfirmDialog
  },
  mixins: [AppData, Auth, ReceiptsGlobal],
  data () {
    return {
      customerDetailsDialogVisible  : false,
      customerAddDialogVisible      : false,
      customerAddPresetData         : {},
      orderDetailsDialogVisible     : false,
      confirmDialogLogOutVisible    : false,
      confirmDialogExitAppVisible   : false,
      confirmManagerPinDialogVisible: false,
      managerPinDialogData          : null,
      selectedCustomerId            : null,
      selectedOrder                 : null,
      reservationAddDialogVisible   : false,
      reservationTableArea          : null,
      reservationCustomer           : null,
      reservationCallerID           : null,
      reservationTable              : null,
      reservationToEdit             : null,
      navigationDrawer              : false,
      navigationDrawerItems         : [],
      toolbarNavigationItems        : [],
      toolbarTitle                  : '',
      showLoader                    : false,
      themeMenu                     : false
    }
  },
  computed: {},
  watch   : {},
  created () {
    // eslint-disable-next-line no-console
    console.log('appConfig::', this.appConfig)
    // eslint-disable-next-line no-console
    console.log(`%c${ '-'.repeat(15) } APP CREATED ${ this.appVersion } ${ this.appConfig?.LOCATION_DATA.SiteUrl } ${ '-'.repeat(15) }`, 'background-color: #388E3C; color: #A5D6A7; padding: 10px 10px;')
    this.$bus.$on('show-app-loading-modal', this.showLoadingModal)
    this.$bus.$on('toolbar-update-title', this.updateTitle)
    this.$bus.$on('app-show-notification', this.showNotification)
    this.$bus.$on('show-customer-add-dialog', this.showCustomerAddDialog)
    this.$bus.$on('show-customer-details-dialog', this.showCustomerDetailsDialog)
    this.$bus.$on('hide-customer-details-dialog', this.hideCustomerDetailsDialog)
    this.$bus.$on('show-order-details-dialog', this.showOrderDetailsDialog)
    this.$bus.$on('hide-order-details-dialog', this.hideOrderDetailsDialog)
    this.$bus.$on('show-manager-pin-dialog', this.showManagerPinDialog)
    this.$bus.$on('hide-manager-pin-dialog', this.hideManagerPinDialog)
    this.$bus.$on('show-table-reservation-dialog', this.showTableReservationDialog)
    this.$bus.$on('app-logout', this.appLogout)
    if (this.$route.name !== 'Login' && this.$route.matched.length > 0) {
      this.$bus.$emit('toolbar-update-title', this.$route.matched[0].meta.title)
    }

    if (isElectron()) {
      const ipcRenderer = ipcRendererElectron()
      ipcRenderer.on(ipcCommandsEnum.AppExit, () => {
        this.onAppExit()
      })
    }
  },
  mounted () {
  },
  updated () {
    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },
  beforeDestroy () {
    // eslint-disable-next-line no-console
    console.log('%c--------------- APP DESTROYED ---------------', 'background-color: #E53935; color: #EF9A9A; padding: 10px 10px;')
    this.$bus.$off('show-app-loading-modal', this.showLoadingModal)
    this.$bus.$off('toolbar-update-title', this.updateTitle)
    this.$bus.$off('app-show-notification', this.showNotification)
    this.$bus.$off('show-customer-add-dialog', this.showCustomerAddDialog)
    this.$bus.$off('show-customer-details-dialog', this.showCustomerDetailsDialog)
    this.$bus.$off('hide-customer-details-dialog', this.hideCustomerDetailsDialog)
    this.$bus.$off('show-order-details-dialog', this.showOrderDetailsDialog)
    this.$bus.$off('hide-order-details-dialog', this.hideOrderDetailsDialog)
    this.$bus.$off('show-manager-pin-dialog', this.showManagerPinDialog)
    this.$bus.$off('hide-manager-pin-dialog', this.hideManagerPinDialog)
    this.$bus.$off('show-table-reservation-dialog', this.showTableReservationDialog)
    this.$bus.$off('app-logout', this.appLogout)
  },
  methods: {
    onAppExit () {
      this.navigationDrawer = false
      this.confirmDialogExitAppVisible = true
    },
    onExitAppYes () {
      this.$bus.$emit('app-logout')
      if (isElectron()) {
        const ipcRenderer = ipcRendererElectron()
        ipcRenderer.send(ipcCommandsEnum.AppExit)
      }
    },
    showLoadingModal (showModal = true) {
      this.showLoader = showModal
    },
    updateTitle (val) {
      this.toolbarTitle = val
    },
    showNotification (data) {
      if (this.$route.name === 'Login') return

      this.$snotify[data.type](data.body, data.title, {
        timeout        : data.hasOwnProperty('timeout') ? data.timeout : 2500,
        showProgressBar: data.hasOwnProperty('showProgressBar') ? data.showProgressBar : true,
        closeOnClick   : data.hasOwnProperty('closeOnClick') ? data.closeOnClick : true,
        pauseOnHover   : data.hasOwnProperty('pauseOnHover') ? data.pauseOnHover : true,
        position       : data.position ? data.position : 'centerTop',
        buttons        : data.buttons ? data.buttons : [],
        icon           : data.icon ? data.icon : null
      })
    },
    showCustomerDetailsDialog (customerId) {
      this.selectedCustomerId = customerId
      if (this.customerDetailsDialogVisible) {
        this.customerDetailsDialogVisible = false
      }
      this.$nextTick(() => {
        this.customerDetailsDialogVisible = true
      })
    },
    hideCustomerDetailsDialog () {
      this.customerDetailsDialogVisible = false
    },
    showCustomerAddDialog (presetData = {}) {
      this.customerAddPresetData = presetData
      this.customerAddDialogVisible = true
    },
    hideOrderDetailsDialog () {
      this.orderDetailsDialogVisible = false
    },
    showOrderDetailsDialog (data) {
      this.selectedOrder = data
      if (this.orderDetailsDialogVisible) {
        this.orderDetailsDialogVisible = false
      }
      this.$nextTick(() => {
        this.orderDetailsDialogVisible = true
      })
    },

    showManagerPinDialog (data) {
      this.managerPinDialogData = data
      this.confirmManagerPinDialogVisible = true
    },
    hideManagerPinDialog () {
      this.confirmManagerPinDialogVisible = false
    },

    showTableReservationDialog (data) {
      this.reservationTable = data?.Table || null
      this.reservationTableArea = data?.Area || null
      this.reservationCustomer = data?.Customer || null
      this.reservationCallerID = data?.CallerID || null
      this.reservationToEdit = data?.Reservation || null
      this.reservationAddDialogVisible = true
    },

    showLogOutDialog () {
      this.confirmDialogLogOutVisible = true
    },
    onLogOutYes () {
      this.$bus.$emit('app-logout')
    },
    appLogout (resetApp = false) {
      if (this.$router.currentRoute.name === 'Pos') {
        this.$bus.$emit('app-logout-from-pos-screen')
        setTimeout(() => {
          if (resetApp) {
            this.resetApp()
          } else {
            this.logout()
          }
        }, 350)
        return
      }
      if (resetApp) {
        this.resetApp()
      } else {
        this.logout()
      }
    },
    logout () {
      window.API.post(window.APICall.logout, {})
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
        .finally(() => {
          this.resetApp()
          // eslint-disable-next-line no-console
          console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>> DefaultLayout ----- onAppLogout')
        })
    },

    onSelectThemeClick (val) {
      this.setThemeMode(val)
      this.$nextTick(() => {
        window.callAS(window.SocketCommand.Account.Save, { ThemeMode: this.posUser.theme_mode })
        this.themeMenu = false
      })
    },

    resetApp () {
      this.$snotify.clear()
      this.PIN = ''
      this.pinCode = []
      this.posUser = null
      this.authorizationToken = ''
      window.staffName = ''
      window.authorizationToken = ''
      window.appConfig = null
      window.socketId = null
      this.$sessionStorage && this.$sessionStorage.clear()
      window.Socket && window.Socket.disconnect()
      stopCloudUpdatePollingCheck()
    }
  }

}

</script>

<style>
.v-data-table__wrapper > table > tbody > tr > td {
  padding    : 0 8px;
  min-height : 48px;
}

.v-application--is-ltr .v-data-footer__pagination {
  margin : 0 8px 0 2px;
}

.filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

@media only screen and (max-width : 599px) {
  .v-data-table__wrapper > table > tbody > tr > td {
    padding    : 0 8px;
    min-height : 22px !important;
  }

  .v-data-table__mobile-row__header, .v-data-table__mobile-row__cell {
    font-size   : 0.75rem !important;
    line-height : 1.25rem;
    padding     : 2px;
  }

  .v-slide-group__next, .v-slide-group__prev {
    flex      : 0 1 28px;
    min-width : 28px;
  }
}

.gfp-footer {
  font-size : 14px;
  color     : #FFFFFF;
}

.gfp-footer > a,
.gfp-footer > a:link,
.gfp-footer > a:visited,
.gfp-footer > a:active {
  text-decoration : none !important;
  color           : #FFFFFF !important;
}

.gfp-footer > a:hover {
  text-decoration : underline !important;
  color           : #FFFFFF !important;
}

.divTable {
  display        : table;
  width          : 100%;
  border-spacing : 0;
}

.divTableRow {
  display : table-row;
}

.v-application.theme--dark .divTableBody .divTableRow:nth-child(even) {
  background-color : #212121;
}

.v-application.theme--light .divTableBody .divTableRow:nth-child(even) {
  background-color : #F7F7F7;
}

.v-application.theme--dark .divTableBody .divTableRow:nth-child(odd) {
  background-color : #2c2c2c;
}

.v-application.theme--light .divTableBody .divTableRow:nth-child(odd) {
  background-color : #FFF;
}

.divTableCell, .divTableHead {
  border-collapse : collapse;
  display         : table-cell;
  padding         : 6px 6px 6px 6px;
  vertical-align  : middle;
}

.divTableGroup {
  display : table-row-group;
}

.v-application.theme--dark .divTableCell, .v-application.theme--dark .divTableHead {
  border-bottom : 1px solid rgba(255, 255, 255, 0.12);
}

.v-application.theme--light .divTableCell, .v-application.theme--light .divTableHead {
  border-bottom : 1px solid rgba(0, 0, 0, 0.12);
}

.v-application.theme--dark .divTableCell {
  color : white;
}

.v-application.theme--light .divTableCell {
  color : black;
}

.divTableHead {
  color          : grey;
  padding        : 16px 10px;
  vertical-align : middle;
  white-space    : nowrap;
}

.divTableHeading {
  display     : table-header-group;
  font-weight : bold;
}

.v-application.theme--dark .divTableHeading, .v-application.theme--dark .divTableFoot {
  background-color : #191919;
}

.v-application.theme--light .divTableHeading, .v-application.theme--dark .divTableFoot {
  background-color : #EEE;
}

.divTableFoot {
  display     : table-footer-group;
  font-weight : bold;
}

.divTableBody {
  display : table-row-group;
}

</style>
