<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        class="filter-toolbar"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Qr.Toolbar.Button.New.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showQrAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Qr.Toolbar.Button.New.Icon') }}
          </v-icon>&nbsp;

          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Qr.Toolbar.Button.New.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="userCanManage"
          :color="$t('Qr.Toolbar.Button.Settings.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showQrSettingsDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Qr.Toolbar.Button.Settings.Icon') }}
          </v-icon>&nbsp;

          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Qr.Toolbar.Button.Settings.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="userCanManage"
          :color="$t('Qr.Toolbar.Button.Export.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :loading="loadingExport"
          :disabled="dataTable.total <= 0"
          class="elevation-0 ma-1"
          text
          @click="onExportButtonClick"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Qr.Toolbar.Button.Export.Icon') }}
          </v-icon>&nbsp;

          <span v-if="$vuetify.breakpoint.smAndUp">{{ $tc('Qr.Title', 0) }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.code`]="{item}">
              <v-btn
                class="ma-0 mr-1"
                icon
                small
                @click="copyToClipboard(item.Url)"
              >
                <v-icon small>
                  far fa-copy
                </v-icon>
              </v-btn>

              <a
                :href="item.Url"
                target="_blank"
              >
                {{ item.Code }}
              </a>
            </template>

            <template #[`item.data`]="{item}">
              {{ item.Data }}
            </template>

            <template #[`item.type`]="{item}">
              <v-chip
                label
                small
              >
                {{ $t(`Qr.Type.${ item.Type }.Title`) }}
              </v-chip>
            </template>

            <template #[`item.created_at`]="{item}">
              {{ item.CreatedAt }}
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanManage && !buttonsDisabled(item)"
                :disabled="buttonsDisabled(item)"
                :small="$vuetify.breakpoint.xsOnly"
                class="cyan elevation-2 ma-0"
                dark
                icon
                @click="showQrEditDialog(item)"
              >
                <v-icon>edit</v-icon>
              </v-btn>

              <v-btn
                v-if="userCanDelete && !buttonsDisabled(item)"
                :disabled="buttonsDisabled(item)"
                :small="$vuetify.breakpoint.xsOnly"
                class="red elevation-2 ma-1"
                dark
                icon
                @click="deleteQr(item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <qr-settings-dialog
      :item-to-edit="settingsItem"
      :visible.sync="settingsQrDialog"
    />

    <qr-add-dialog
      :item-to-edit="selectedItem"
      :visible.sync="addEditQrDialog"
    />

    <confirm-dialog
      :html-content="$t('Qr.Dialog.Delete.Content')"
      :html-title="$t('Qr.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Qr.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>
import AppData          from '@/mixins/appdata'
import Auth             from '@/mixins/auth'
import Translatable     from '@/mixins/translatable'
import DataTable        from '@/mixins/data-table/dataTable'
import ConfirmDialog    from '@/components/common/ConfirmDialog'
import FilterSearch     from '@/components/common/filter/FilterSearch.vue'
import QrAddDialog      from '@/components/qr/QrAddDialog.vue'
import QrSettingsDialog from '@/components/qr/QrSettingsDialog.vue'

export default {
  name      : 'QrManagement',
  components: {
    QrSettingsDialog,
    QrAddDialog,
    FilterSearch,
    'qr-add-dialog' : QrAddDialog,
    'confirm-dialog': ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, DataTable],
  data () {
    return {
      loadingExport             : false,
      confirmDialogDeleteVisible: false,
      selectedItem              : null,
      settingsItem              : null,
      addEditQrDialog           : false,
      settingsQrDialog          : false,
      dataTable                 : {
        socketCommand: {
          list  : window.SocketCommand.QRCode.All,
          save  : window.SocketCommand.QRCode.Save,
          delete: window.SocketCommand.QRCode.Delete
        },
        options: {
          defaultSortBy: 'id',
          sortBy       : ['id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Qr.Table.Headers.Id'),
            value    : 'id',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Qr.Table.Headers.Code'),
            value    : 'code',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Qr.Table.Headers.Data'),
            value    : 'data',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Qr.Table.Headers.Type'),
            value    : 'type',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Qr.Table.Headers.CreatedAt'),
            value    : 'created_at',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Qr.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 100
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.QRCode.Settings.Save, this.getData)
    this.$bus.$on(window.SocketCommand.QRCode.Export, this.onExportResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.QRCode.Settings.Save, this.getData)
    this.$bus.$off(window.SocketCommand.QRCode.Export, this.onExportResult)
  },
  methods: {
    onExportButtonClick () {
      this.loadingExport = true
      window.callAS(window.SocketCommand.QRCode.Export, {})
    },

    onExportResult (response) {
      this.loadingExport = false

      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Qr.Toolbar.Button.Export.SuccessMessage', { email: this.posUser?.staff_email || '' }),
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    buttonsDisabled (item) {
      return !!item.ObjectId || item.Type === 'catalog' || item.Type === 'home'
    },

    showQrSettingsDialog () {
      this.selectedItem = null
      this.settingsQrDialog = true
    },

    showQrAddDialog () {
      this.selectedItem = null
      this.addEditQrDialog = true
    },

    showQrEditDialog (item) {
      this.selectedItem = item
      this.addEditQrDialog = true
    },

    setData (data) {
      this.setDataInternal(data)
      this.settingsItem = data?.options || null
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.QRCode.Save, item)
    },

    deleteQr (item) {
      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.QRCode.Delete, { Id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Qr.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  width    : 75px !important;
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
