<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="$t('Customer.CustomerSelectEmailDialog.Toolbar.Color')">
            <v-icon dark>
              {{ $t('Customer.CustomerSelectEmailDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Customer.CustomerSelectEmailDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerSelectEmailDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="dataForm"
            v-model="dataFormValid"
            @submit.prevent
          >
            <v-container
              class="mt-0 pt-0"
              fluid
            >
              <v-row>
                <v-col
                  cols="12"
                  class="text-end"
                >
                  <v-btn
                    depressed
                    color="primary"
                    class="my-2"
                    @click="addItem()"
                  >
                    <v-icon left>
                      mdi-plus
                    </v-icon>

                    {{ $t('Common.Button.Add').toLocaleUpperCase($i18n.locale) }} EMAIL
                  </v-btn>
                </v-col>
              </v-row>

              <template v-for="(email, idx) in emails">
                <v-row
                  :key="idx"
                  dense
                >
                  <v-col>
                    <v-text-field
                      v-model="emails[idx]"
                      :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Hint')"
                      :label="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Label')"
                      :placeholder="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Label')"
                      :rules="validationRules.dataForm.Email"
                      prepend-inner-icon="mdi-email"
                      append-icon=""
                      clearable
                      outlined
                      flat
                    />
                  </v-col>

                  <v-col class="shrink">
                    <v-btn
                      icon
                      color="red"
                      class="mt-2"
                      @click="removeItem(idx)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Send').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import AppData          from '@/mixins/appdata'
import { emailIsGuest } from '@/lib/helper/helper'

export default {
  name      : 'CustomerEmailSelectDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    data: {
      type   : Object,
      default: undefined
    },

    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      emails         : [],
      dataFormValid  : false,
      validationRules: {
        dataForm: {
          Email: [
            (v) => v && (v.length >= 0 && v.length <= 96) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Between'),
            (v) => v && (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Valid')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        const order = this.data?.order
        const data = this.data?.data

        this.emails = data?.EmailAddresses ?? []
        if (order?.Email && !emailIsGuest(order?.Email) && !this.emails.includes(order.Email)) this.emails.push(order.Email)
        if (this.emails.length === 0) this.emails.push('')
      } else {
        this.emails = []
      }
    }
  },
  mounted () {},
  created () {},
  beforeDestroy () {},
  methods: {

    addItem () {
      this.emails.push('')
    },

    removeItem (idx) {
      this.emails.splice(idx, 1)
    },

    onSaveButtonClick () {
      this.$refs.dataForm.validate()

      if (this.dataFormValid) {
        if (!this.data?.data) this.data.data = {}
        this.data.data = { ...this.data.data, ...{ EmailAddresses: this.emails } }
        this.$emit('save:emails', this.data)
        this.closeDialog()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    closeDialog () {
      if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      this.isVisible = false
    },

    onCancelButtonClick () {
      this.$emit('cancel:emails')
      this.closeDialog()
    }
  }
}
</script>
