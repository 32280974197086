<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card v-if="selectedOrder">
        <v-toolbar
          flat
          class="red"
          height="80"
          max-height="80"
        >
          <v-avatar
            color="red darken-3"
          >
            <template v-if="isDeleteMultiple">
              <v-icon
                color="white"
                size="28"
              >
                shopping_cart
              </v-icon>
            </template>

            <template v-else>
              <v-icon
                v-if="selectedOrder.SourceTypeId < 100"
                color="white"
                size="28"
              >
                {{ getOrderSourceTypeById(selectedOrder.SourceTypeId).logo }}
              </v-icon>

              <img
                v-else
                :src="getOrderSourceTypeById(selectedOrder.SourceTypeId).logo"
                style="width: 32px; height: auto;"
              >
            </template>
          </v-avatar>

          <v-toolbar-title class="pl-3 white--text">
            <div class="body-3">
              {{ isDeleteMultiple ? $t('Order.OrderDeleteDialog.DeleteOrders.Title') : $t('Order.OrderDeleteDialog.DeleteOrder.Title') }}
            </div>

            <div class="text-caption red--text text--lighten-4">
              <template v-if="isDeleteMultiple">
                {{ $tc('Order.OrderDeleteDialog.DeleteOrders.Subtitle', selectedOrder.length, {orders: selectedOrder.length}) }}
              </template>
              <template v-else>
                {{ parseInt(selectedOrder.SourceTypeId) >= 100 ? getOrderSourceTypeById(selectedOrder.SourceTypeId).desc : getOrderSourceTypeLangById(selectedOrder.SourceTypeId).Desc }} #{{ selectedOrder.Id }}
              </template>
            </div>
          </v-toolbar-title>

          <v-spacer />
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-container
            class="pa-1"
            fluid
          >
            <v-row dense>
              <v-col
                class="mb-1"
                cols="12"
              >
                <template v-if="isDeleteMultiple">
                  <span class="text-subtitle-1">
                    {{ $tc('Order.OrderDeleteDialog.DeleteOrders.Body', selectedOrder.length, {orders: selectedOrder.length}) }}
                  </span>
                </template>

                <template v-else>
                  <span class="text-subtitle-1">
                    {{ $t('Order.OrderDeleteDialog.DeleteOrder.Subtitle.Order') }}
                    <strong>#{{ selectedOrder.Id }}</strong> {{ $t('Order.OrderDeleteDialog.DeleteOrder.Subtitle.CancelPermanently') }}
                  </span>
                </template>
              </v-col>
            </v-row>

            <v-row
              v-if="!isDeleteMultiple"
              dense
            >
              <v-col
                sm="8"
                cols="12"
              >
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row no-gutters>
                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderDeleteDialog.Field.Name').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      {{ (parseInt(selectedOrder.CustomerId) !== 0 || selectedOrder.FullName !== 'null null') ? selectedOrder.FullName : $t('Customer.CustomerType.Visitor') }}
                    </v-col>

                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderDeleteDialog.Field.Telephone').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      {{ selectedOrder.Telephone }}
                    </v-col>

                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderDeleteDialog.Field.Time').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      {{ selectedOrder.TimeAdded }}
                    </v-col>

                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderDeleteDialog.Field.Total').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      <span
                        class="text-subtitle-1 font-weight-bold"
                        :class="isDarkMode ? 'white--text' : 'black--text'"
                      >{{ selectedOrder.Price | currency }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col
                sm="4"
                cols="12"
              >
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <order-status-chip
                        :order="selectedOrder"
                        min-width="170"
                        dense
                      />
                    </v-col>

                    <v-col cols="12">
                      <order-type-chip
                        :order="selectedOrder"
                        min-width="170"
                        dense
                        label
                      />
                    </v-col>

                    <v-col cols="12">
                      <order-payments-chip
                        :item="selectedOrder"
                        min-width="170"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <v-col
                :class="isDarkMode ? 'red lighten-2' : 'red lighten-4'"
                cols="12"
              >
                <div
                  class="py-2 px-4 text-caption"
                  style="display: table;"
                >
                  <div style="display: table-row">
                    <div
                      class="font-weight-bold pr-2"
                      style="display: table-cell; vertical-align: top;"
                    >
                      {{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:
                    </div>

                    <div
                      v-if="isDeleteMultiple"
                      style="display: table-cell; vertical-align: middle;"
                    >
                      <div v-html="`&#8226; ${$t('Order.OrderDeleteDialog.Notification.Title')}`" />

                      <div
                        class="mt-1"
                        v-html="`&#8226; ${$t('Order.OrderDeleteDialog.Notification.RefundMultiple')}`"
                      />

                      <div
                        v-if="appHasReceiptsProvider"
                        class="mt-1"
                        v-html="`&#8226; ${$t('Order.OrderDeleteDialog.Notification.ReceiptOrInvoiceMultiple')}`"
                      />

                      <div
                        class="mt-1 font-italic font-weight-medium"
                        v-html="`&nbsp;&nbsp;${$t('Order.OrderDeleteDialog.Notification.Tip')}`"
                      />
                    </div>

                    <div
                      v-else
                      style="display: table-cell; vertical-align: middle;"
                    >
                      <div v-html="`&#8226; ${$t('Order.OrderDeleteDialog.Notification.Title')}`" />

                      <div
                        v-if="selectedOrderIsRefundable"
                        class="mt-1"
                        v-html="`&#8226; ${$t('Order.OrderDeleteDialog.Notification.Amount')} ${$options.filters.currency(selectedOrderRefundableAmount) } ${$t('Order.OrderDeleteDialog.Notification.Refund')}`"
                      />

                      <div
                        v-if="selectedOrderHasReceipt"
                        class="mt-1"
                        v-html="`&#8226; ${selectedOrder.ReceiptType === 'receipt' ? $t('Order.OrderDeleteDialog.Notification.Receipt') : $t('Order.OrderDeleteDialog.Notification.Invoice')}`"
                      />

                      <div
                        v-if="selectedOrderIsRefundable || selectedOrderHasReceipt"
                        class="mt-1 font-italic font-weight-medium"
                        v-html="`&nbsp;&nbsp;${$t('Order.OrderDeleteDialog.Notification.Tip')}`"
                      />
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="green--text text--darken-1"
            text
            outlined
            @click.native="isVisible = false"
          >
            {{ $t('Common.Button.No').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            class="red darken-1 white--text"
            text
            @click.exact="deleteOrder()"
            @click.native="isVisible = false"
          >
            {{ $t('Common.Button.Yes').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData           from '../../mixins/appdata'
import OrderStatusColor  from '../../mixins/orders/orderStatus'
import OrderType         from '../../mixins/orders/orderType'
import OrderTypeTime     from '../../mixins/orders/orderTypeTime'
import SourceType        from '../../mixins/orders/sourceType'
import OrderPaymentsChip from '@/components/orders/common/OrderPaymentsChip.vue'
import PaymentTypeEnum   from '@/api/Enums/PaymentTypeEnum'
import Auth              from '@/mixins/auth'
import OrderStatusChip   from '@/components/orders/common/OrderStatusChip.vue'
import OrderTypeChip     from '@/components/orders/common/OrderTypeChip.vue'

export default {
  components: {
    OrderTypeChip,
    OrderStatusChip,
    OrderPaymentsChip
  },
  mixins: [AppData, Auth, OrderStatusColor, OrderType, SourceType, OrderTypeTime],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    isDeleteAndCopyOrder: {
      type   : Boolean,
      default: false
    },
    selectedOrder: {
      type   : [Object, Array],
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    orderHasOnlineCardPayments () {
      return this.selectedOrder?.Payment === PaymentTypeEnum.VIVA_3DS || this.selectedOrder?.Payment === PaymentTypeEnum.VIVA_SMART || this.selectedOrder?.Payment === PaymentTypeEnum.EVERYPAY || this.selectedOrder?.Payment === PaymentTypeEnum.STRIPE
    },

    orderRefundableTransactions () {
      return this.selectedOrder?.PaymentTransactions?.filter(payment => payment.Status === 'APPROVED' && payment.PaymentType === PaymentTypeEnum.CARD) || []
    },

    orderHasPaymentMethodRefundable () {
      return this.orderRefundableTransactions.length > 0
    },

    selectedOrderIsRefundable () {
      return this.orderHasPaymentMethodRefundable || this.orderHasOnlineCardPayments
    },

    selectedOrderRefundableAmount () {
      if (this.orderHasOnlineCardPayments) return this.selectedOrder?.Price ?? 0

      if (this.orderHasPaymentMethodRefundable) return this.orderRefundableTransactions.reduce((a, b) => a + parseFloat(b?.Amount ?? 0), 0) || 0

      return 0
    },

    selectedOrderHasReceipt () {
      return !!this.selectedOrder?.ReceiptId
    },

    isDeleteMultiple () {
      return Array.isArray(this.selectedOrder)
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    deleteOrder () {
      const orderIdsArray = this.isDeleteMultiple ? this.selectedOrder : [this.selectedOrder.Id]
      if (this.$route.name === 'OrdersSaved') {
        window.callAS(window.SocketCommand.Cart.Destroy, { CartId: orderIdsArray })
      } else {
        if (this.isDeleteAndCopyOrder) {
          window.callAS(window.SocketCommand.Order.Edit, {
            order_id  : this.selectedOrder.Id,
            catalog_id: this.selectedOrder?.ExternalCatalogId || null,
            cancel    : true,
            Reserved  : parseInt(this.posUser.staff_id)
          })
        } else {
          window.callAS(window.SocketCommand.Order.Delete, { Id: orderIdsArray }, window.SocketCommand.Order.StatusUpdated)
        }
      }

      this.$bus.$emit('order-deleted', this.selectedOrder)
      this.$emit('order-deleted', this.selectedOrder)
    }
  }
}
</script>
