<template>
  <v-card
    :color="iconBackgroundColor"
    :flat="flat"
    :to="to"
    min-width="170px"
  >
    <v-card-text class="pa-1">
      <v-container
        fluid
        class="pa-1"
      >
        <v-row
          class="align-center"
          dense
        >
          <v-col
            style="min-width: 80px;"
            class="text-center shrink"
          >
            <v-icon
              v-if="icon"
              :color="colorClass"
              :size="iconSize"
            >
              {{ icon }}
            </v-icon>

            <v-avatar
              v-if="img"
              :size="iconSize"
              class="ma-0 pa-0"
              color="white"
            >
              <img
                :height="iconSize"
                :src="img"
                width="auto"
              >
            </v-avatar>

            <div
              v-if="iconTitle"
              class="text-no-wrap font-weight-bold"
              style="font-size: 9px;"
            >
              {{ iconTitle }}
            </div>
          </v-col>

          <v-col
            :class="[colorClass]"
            class="white--text text-center rounded"
          >
            <div
              v-if="title"
              class="text-h6 text-no-wrap"
            >
              {{ title }}
            </div>

            <div
              v-if="subTitle"
              class="text-no-wrap"
              style="font-size: 11px"
            >
              {{ subTitle }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name      : 'MiniStatsIconCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    flat: {
      type   : Boolean,
      default: false
    },
    title: {
      type   : String,
      default: ''
    },
    subTitle: {
      type   : String,
      default: ''
    },
    iconTitle: {
      type   : String,
      default: ''
    },
    colorClass: {
      type   : String,
      default: 'blue-grey'
    },
    icon: {
      type   : String,
      default: ''
    },
    iconBackgroundColor: {
      type   : String,
      default: 'grey lighten-4'
    },
    img: {
      type   : String,
      default: ''
    },
    iconSize: {
      type   : String,
      default: '32'
    },
    to: {
      type   : [String, Object],
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
