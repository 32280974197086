<template>
  <v-container
    class="pa-1 pa-sm-2 fill-height align-start"
    fluid
  >
    <v-row
      :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
      class=""
      no-gutters
    >
      <v-col class="shrink pr-2">
        <v-card flat>
          <v-toolbar
            ref="deliveryStaffToolbar"
            :color="isDarkMode ? 'grey darken-4' : 'grey lighten-5'"
            flat
          >
            <v-toolbar-title class="grey--text text--darken-1 pr-2">
              {{ $tc('Delivery.Title', 0) }}
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              v-if="userCanAdd"
              :color="$t('Delivery.Button.DeliveryStaff.Add.Color')"
              :icon="$vuetify.breakpoint.xsOnly"
              class="ma-0 px-2"
              text
              @click="showDeliveryStaffAddDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">
                {{ $t('Delivery.Button.DeliveryStaff.Add.Icon') }}
              </v-icon>&nbsp;

              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Delivery.Button.DeliveryStaff.Add.Title') }}</span>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-text class="pa-0 mt-1">
            <div
              ref="deliveryStaffList"
              style="overflow-y: auto;"
            >
              <div
                v-if="deliveryStaff.length <= 0"
                class="fill-height"
                style="position: relative;"
              >
                <div
                  class="text-center grey--text pa-6"
                  style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                >
                  <v-icon
                    class="mb-4"
                    color="grey lighten-2"
                    size="128"
                  >
                    {{ $t('Delivery.ListView.NoData.Icon') }}
                  </v-icon>
                  <div
                    class="text-h6 mb-2"
                    style="height: auto;"
                  >
                    {{ $t('Delivery.ListView.NoData.Title') }}
                  </div>
                  <div
                    class="text-subtitle-1"
                    style="height: auto;"
                  >
                    {{ $t('Delivery.ListView.NoData.Subtitle') }}
                  </div>
                </div>
              </div>

              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-list
                  class="ma-0 pa-0"
                  three-line
                >
                  <template v-for="staff in deliveryStaff">
                    <v-list-item
                      :key="`staff-list-item-${staff.Id}`"
                      :class="[selectedDeliveryStaff && parseInt(staff.Id) === parseInt(selectedDeliveryStaff.Id) ? (isDarkMode ? 'green darken-4' : 'green lighten-4') : (isDarkMode ? 'grey darken-4' : 'grey lighten-4')]"
                      style="height: 88px;"
                      ripple
                      @click="deliveryStaffToggle(staff)"
                    >
                      <div
                        :class="[staff.HasActiveShift ? 'light-green' : 'grey lighten-1']"
                        class="ma-0 pa-0"
                        style="width: 5px; height: 100%; position: absolute; left: 0"
                      />

                      <v-list-item-avatar>
                        <v-icon
                          :class="[staff.HasActiveShift ? 'light-green--text' : 'grey--text text--lighten-1']"
                          x-large
                        >
                          account_circle
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="pl-3">
                        <v-list-item-title class="">
                          {{ staff.Firstname + ' ' + staff.Lastname }}
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="staff.Phone">
                          {{ $t('Delivery.Common.InfoLabels.Telephone') }} {{ staff.Phone }}&nbsp;
                        </v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-if="staff.HasActiveShift"
                          class="text-caption"
                        >
                          {{ $t('Delivery.Common.ShiftsOrders') }} {{ staff.ShiftOrders.length }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action class="">
                        <v-btn
                          v-if="userCanManage"
                          icon
                          ripple
                          small
                          @click.native.stop="showDeliveryStaffEditDialog(staff)"
                        >
                          <v-icon
                            :color="$t('Delivery.Button.DeliveryStaff.Edit.Color')"
                          >
                            {{ $t('Delivery.Button.DeliveryStaff.Edit.Icon') }}
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!staff.HasActiveShift && userCanDelete"
                          class="red--text"
                          icon
                          ripple
                          small
                          @click.native.stop="showDeleteDeliveryStaffDialog(staff)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      :key="`staff-list-divider-${staff.Id}`"
                      class="mb-1"
                    />
                  </template>
                </v-list>
              </template>

              <v-menu
                v-else
                bottom
                max-height="400"
                transition="slide-y-transition"
              >
                <template #activator="{on}">
                  <v-btn
                    block
                    color="primary ma-0"
                    dark
                    v-on="on"
                  >
                    {{ $t('Delivery.ListView.SelectDeliveryStaff.Title') }}
                  </v-btn>
                </template>

                <v-list
                  class="mb-1 mt-0 pb-0 pt-0"
                  three-line
                >
                  <template v-for="staff in deliveryStaff">
                    <v-list-item
                      :key="`list-item-${staff.Id}`"
                      :class="[selectedDeliveryStaff && parseInt(staff.Id) === parseInt(selectedDeliveryStaff.Id) ? (isDarkMode ? 'green darken-4' : 'green lighten-4') : (isDarkMode ? 'grey darken-4' : 'grey lighten-4')]"
                      ripple
                      @click="deliveryStaffToggle(staff)"
                    >
                      <div
                        :class="[staff.HasActiveShift ? 'light-green' : 'grey lighten-1']"
                        class="ma-0 pa-0"
                        style="width: 5px; height: 100%; position: absolute; left: 0"
                      />

                      <v-list-item-avatar>
                        <v-icon
                          :class="[staff.HasActiveShift ? 'light-green--text' : 'grey--text text--lighten-1']"
                          x-large
                        >
                          {{ $t('Delivery.Common.InfoLabels.Icon') }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="pl-3">
                        <v-list-item-title>
                          {{ staff.Firstname + ' ' + staff.Lastname }}
                        </v-list-item-title>

                        <v-list-item-subtitle>{{ $t('Delivery.Common.InfoLabels.Telephone') }} {{ staff.Phone }}&nbsp;</v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-if="staff.HasActiveShift"
                          class="text-caption"
                        >
                          {{ $t('Delivery.Common.ShiftsOrders') }} {{ staff.ShiftOrders.length }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          v-if="userCanManage"
                          icon
                          ripple
                          small
                          @click.native.stop="showDeliveryStaffEditDialog(staff)"
                        >
                          <v-icon color="grey lighten-0">
                            edit
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!staff.HasActiveShift && userCanDelete"
                          class="red--text"
                          icon
                          ripple
                          small
                          @click.native.stop="showDeleteDeliveryStaffDialog(staff)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      :key="`list-item-divider-${staff.Id}`"
                      class="mb-1"
                    />
                  </template>
                </v-list>
              </v-menu>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="selectedDeliveryStaff">
        <v-divider
          v-if="$vuetify.breakpoint.smAndDown"
          class="mt-1"
        />

        <v-card
          v-if="selectedDeliveryStaff"
          :class="{'mt-2 pa-1': $vuetify.breakpoint.smAndDown}"
          flat
        >
          <v-toolbar
            ref="deliveryStaffToolbar"
            class="datatable-object"
            flat
          >
            <div
              :class="[selectedDeliveryStaff.HasActiveShift ? 'light-green' : 'grey lighten-1']"
              class="ma-0 pa-0"
              style="width: 5px; height: 100%; position: absolute; left: 0"
            />

            <v-avatar>
              <v-icon
                :class="[selectedDeliveryStaff.HasActiveShift ? 'light-green--text' : 'grey--text text--lighten-1']"
                x-large
              >
                {{ $t('Delivery.Common.InfoLabels.Icon') }}
              </v-icon>
            </v-avatar>

            <v-toolbar-title class="grey--text text--darken-1 ma-1 text-body-2">
              {{ selectedDeliveryStaff.Firstname + ' ' + selectedDeliveryStaff.Lastname }}
              <div class="grey--text text-caption">
                <span class="grey--text text--darken-1">
                  {{ $t('Delivery.Common.InfoLabels.Telephone') }}
                </span> {{ selectedDeliveryStaff.Phone }}

                <span
                  v-if="selectedDeliveryStaff.Email"
                  class="pl-2"
                >
                  <span class="grey--text text--darken-1">
                    {{ $t('Delivery.Common.InfoLabels.Email') }}
                  </span> {{ selectedDeliveryStaff.Email }}
                </span>
              </div>
            </v-toolbar-title>

            <v-spacer />

            <v-menu
              v-if="!selectedDeliveryStaff.HasActiveShift && userCanManage"
              v-model="startShiftPopOverVisible"
              :close-on-content-click="false"
              :nudge-width="85"
              :offset-y="true"
              bottom
              class="ma-0 pa-0"
            >
              <template #activator="{on}">
                <v-btn
                  :color="$t('Delivery.Button.Shift.Open.Color')"
                  :icon="$vuetify.breakpoint.xsOnly"
                  class="ma-0"
                  text
                  v-on="on"
                >
                  <v-icon :left="$vuetify.breakpoint.smAndUp">
                    {{ $t('Delivery.Button.Shift.Open.Icon') }}
                  </v-icon>&nbsp;

                  <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Delivery.Button.Shift.Open.Title') }}</span>
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <v-text-field
                    v-model="selectedDeliveryStaff.ShiftStartMoney"
                    :append-icon="appCurrency.Icon"
                    :hint="$t('Delivery.Button.Shift.Open.PopUp.Hint')"
                    :label="$t('Delivery.Button.Shift.Open.PopUp.Title')"
                    autofocus
                    name="input-order-comments"
                    persistent-hint
                  />
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    class="primary--text"
                    text
                    @click="startDeliveryStaffShift()"
                  >
                    {{ $t('Delivery.Button.Shift.Open.PopUp.Button') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-btn
              v-else-if="selectedDeliveryStaff.HasActiveShift && userCanManage"
              :color="$t('Delivery.Button.Shift.Close.Color')"
              :icon="$vuetify.breakpoint.xsOnly"
              class="ma-0"
              text
              @click="showCloseDeliveryStaffShiftDialog()"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">
                {{ $t('Delivery.Button.Shift.Close.Icon') }}
              </v-icon>&nbsp;
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Delivery.Button.Shift.Close.Title') }}</span>
            </v-btn>
          </v-toolbar>

          <v-divider class="datatable-object" />

          <v-tabs
            v-model="activeTab"
            class="mt-1 datatable-object"
            background-color="indigo"
            slider-color="light-green"
            dark
            grow
          >
            <v-tab
              active-class="indigo darken-2 v-tabs__item--active"
              href="#tab1"
              ripple
            >
              {{ $t('Delivery.DeliveryStaffInfo.Tab.Details') }}
            </v-tab>

            <v-tab
              active-class="indigo darken-2 v-tabs__item--active"
              href="#tab2"
              ripple
            >
              {{ $t('Delivery.DeliveryStaffInfo.Tab.History') }}
            </v-tab>
          </v-tabs>

          <v-card-text class="pa-0 pt-2">
            <v-tabs-items v-model="activeTab">
              <!--ACTIVE SHIFT TAB-->
              <v-tab-item value="tab1">
                <v-card>
                  <v-card-title class="pa-0">
                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row dense>
                        <v-col
                          class="text-left"
                          sm="3"
                          cols="6"
                        >
                          <div
                            v-if="selectedDeliveryStaff.HasActiveShift"
                            class="text-center"
                          >
                            <div class="text-caption pl-1 pt-0 blue--text">
                              <strong>{{ $t('Delivery.Common.ShiftStart') }}</strong>
                            </div>

                            <div class="text-h6 grey--text text--darken-1">
                              {{ selectedDeliveryStaff.ShiftStartMoney | currency }}
                            </div>

                            <div
                              class="text-caption pl-1 pt-1 grey--text"
                              style="white-space: nowrap;"
                            >
                              {{ selectedDeliveryStaff.ShiftStartDateTime.split(' ')[0] }}
                            </div>

                            <div class="text-caption">
                              {{ selectedDeliveryStaff.ShiftStartDateTime.split(' ')[1] }}
                            </div>
                          </div>
                        </v-col>

                        <v-col
                          class="text-left"
                          sm="3"
                          cols="6"
                        >
                          <div
                            v-if="selectedDeliveryStaff.HasActiveShift"
                            class="text-center"
                          >
                            <div class="text-caption pl-1 pt-0 orange--text">
                              <strong>{{ $t('Delivery.Common.ShiftTotal') }}</strong>
                            </div>

                            <div class="text-h6 grey--text text--darken-1">
                              {{ getDeliveryStaffShiftTotals(true) | currency }}
                            </div>

                            <div
                              class="text-caption pl-1 pt-1"
                              style="white-space: nowrap;"
                            >
                              <span class="grey--text">
                                {{ $t('Delivery.Common.Orders') }}
                              </span>
                              <div>{{ getDeliveryStaffShiftTotals(false) | currency }}</div>
                            </div>
                          </div>
                        </v-col>

                        <v-col
                          class="text-right"
                          sm="6"
                          cols="12"
                        >
                          <barcode
                            :id="getBarcode(selectedDeliveryStaff).barcode"
                            :style="$vuetify.breakpoint.xsOnly ? 'zoom: 0.85' : ''"
                            :text="(String(selectedDeliveryStaff.Firstname + ' ' + selectedDeliveryStaff.Lastname).toLocaleUpperCase($i18n.locale))"
                            :value="getBarcode(selectedDeliveryStaff).barcode"
                            class="print-item"
                            element-tag="svg"
                            font-size="12"
                            format="CODE128"
                            height="45"
                            text-margin="2"
                            text-position="bottom"
                            width="2"
                          />

                          <v-menu
                            v-if="appMachinePrintersActive.length > 1 && appHasPrinters && userCanManage"
                            left
                            nudge-bottom="30"
                            open-on-hover
                          >
                            <template #activator>
                              <v-btn
                                class="grey"
                                dark
                                icon
                              >
                                <v-icon>print</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <template v-for="(printer, index) in appMachinePrintersActive">
                                <v-list-item
                                  :key="index"
                                  @click="onPrintDeliveryShift(printer)"
                                >
                                  <v-list-item-action>
                                    <v-icon>print</v-icon>
                                  </v-list-item-action>
                                  <v-list-item-title>{{ printer.name }}</v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-menu>

                          <v-btn
                            v-if="(appMachinePrintersActive.length === 1 && appHasPrinters) && userCanManage"
                            class="grey"
                            dark
                            icon
                            @click.native="onPrintDeliveryShift(appMachinePrintersActive[0])"
                          >
                            <v-icon>print</v-icon>
                          </v-btn>

                          <div style="display: none;">
                            <div
                              class="barcode-generator-print-element"
                              style="text-align: center;"
                            >
                              <barcode
                                :id="getBarcode(selectedDeliveryStaff).barcode + '-PRINT'"
                                ref="barcodeCanvas"
                                :margin="0"
                                :value="getBarcode(selectedDeliveryStaff).barcode"
                                class="print-item"
                                element-tag="canvas"
                                font-size="12"
                                format="CODE128"
                                height="55"
                                text=" "
                                text-margin="2"
                                text-position="bottom"
                                width="2"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row no-gutters>
                        <v-col cols="12">
                          <template v-if="selectedDeliveryStaff.HasActiveShift">
                            <v-subheader
                              class="grey--text text--darken-2 px-0"
                              style="height: 50px"
                            >
                              <v-btn
                                v-if="userCanManage && posUserHasComponentPermission('Orders', 'MANAGE')"
                                :color="$t('Delivery.DeliveryStaffInfo.Button.AddOrder.Color')"
                                class="elevation-0 ml-0"
                                dark
                                small
                                @click.native="showAddOrderToDeliveryStaff"
                              >
                                {{ $t('Delivery.DeliveryStaffInfo.Button.AddOrder.Title') }}
                              </v-btn>

                              <v-spacer />

                              <span class="text-caption text-right">{{ selectedDeliveryStaff.ShiftOrders.length > 0 ? $t('Delivery.Common.ShiftsOrders') + selectedDeliveryStaff.ShiftOrders.length : $t('Delivery.DeliveryStaffInfo.Result.NoOrders') }}</span>
                            </v-subheader>

                            <div
                              ref="ordersList"
                              style="overflow-y: auto;"
                            >
                              <orders-v-list
                                :orders="selectedDeliveryStaff.ShiftOrders"
                                :remove-action="true"
                                @remove-action-click="showDeleteOrderDialog"
                                @list-tile-click="getOrderDetails"
                              />
                            </div>
                          </template>

                          <template v-else>
                            <div
                              ref="ordersList"
                              class="pr-2 mt-6"
                              style="overflow-y: auto;"
                            >
                              <div
                                class="fill-height"
                                style="position: relative;"
                              >
                                <div
                                  class="text-center grey--text pa-2"
                                  style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                                >
                                  <v-icon
                                    class="mb-4"
                                    color="grey lighten-2"
                                    size="128"
                                  >
                                    {{ $t('Delivery.DeliveryStaffInfo.Result.InactiveShift.Icon') }}
                                  </v-icon>
                                  <div
                                    class="text-h6 mb-2"
                                    style="height: auto;"
                                  >
                                    {{ $t('Delivery.DeliveryStaffInfo.Result.InactiveShift.Title') }}
                                  </div>
                                  <div
                                    class="text-subtitle-1"
                                    style="height: auto;"
                                  >
                                    <a @click="startShiftPopOverVisible=true">{{ $t('Delivery.DeliveryStaffInfo.Result.InactiveShift.SubtitleLink') }}</a> {{ $t('Delivery.DeliveryStaffInfo.Result.InactiveShift.SubtitleText') }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!--HISTORY SHIFTS TAB-->
              <v-tab-item value="tab2">
                <!--HISTORY SHIFTS TABLE-->
                <v-card
                  v-if="viewData"
                  flat
                >
                  <v-data-table
                    :footer-props="dataTable.footerProps"
                    :headers="dataTable.headers"
                    :height="dataTable.resize.bodyHeight"
                    :items="dataTable.data"
                    :loading="dataTable.loading"
                    :loading-text="dataTable.text.loadingText"
                    :no-data-text="dataTable.text.noDataText"
                    :no-results-text="dataTable.text.noResultsText"
                    :options.sync="dataTable.options"
                    :server-items-length="dataTable.total"
                    fixed-header
                    item-key="Id"
                  >
                    <template #[`item.date_started`]="{item}">
                      {{ item.ShiftsDate }}
                    </template>

                    <template #[`item.swifts`]="{item}">
                      {{ item.ShiftsCount }}
                    </template>

                    <template #[`item.orders`]="{item}">
                      {{ item.OrdersCount }}
                    </template>

                    <template #[`item.total`]="{item}">
                      {{ item.OrdersTotal | currency }}
                    </template>

                    <template #[`item.actions`]="{item}">
                      <v-tooltip
                        :color="$t('Delivery.DeliveryStaffInfo.Button.ViewDailyShift.Color')"
                        open-delay="1000"
                        left
                      >
                        <template #activator="{on}">
                          <v-btn
                            :class="$t('Delivery.DeliveryStaffInfo.Button.ViewDailyShift.Color')"
                            class="elevation-2 ml-1"
                            dark
                            icon
                            :small="$vuetify.breakpoint.xsOnly"
                            v-on="on"
                            @click.native.stop="showDeliveryShifts(item)"
                          >
                            <v-icon :small="$vuetify.breakpoint.xsOnly">
                              {{ $t('Delivery.DeliveryStaffInfo.Button.ViewDailyShift.Icon') }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('Delivery.DeliveryStaffInfo.Button.ViewDailyShift.Title') }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="!selectedDeliveryStaff">
        <v-container
          class="pa-0 fill-height"
          fluid
        >
          <v-row class="fill-height align-center justify-center">
            <v-col
              class="text-center grey--text"
              cols="12"
            >
              <v-icon
                class="mb-4"
                color="grey lighten-2"
                size="128"
              >
                {{ $t('Delivery.ListView.SelectDeliveryStaff.Icon') }}
              </v-icon>
              <div
                class="text-h6 mb-2"
                style="height: auto;"
              >
                {{ $t('Delivery.ListView.SelectDeliveryStaff.Title') }}
              </div>
              <div
                class="text-subtitle-1"
                style="height: auto;"
              >
                {{ $t('Delivery.ListView.SelectDeliveryStaff.Subtitle') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <confirm-dialog
      :html-content="$t('Delivery.Button.Shift.Close.PopUp.Content')"
      :html-title="$t('Delivery.Button.Shift.Close.Title')"
      :visible.sync="confirmDialogOpenShiftVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="close-shift-on-yes-btn"
      @close-shift-on-yes-btn="closeDeliveryStaffShift"
    />

    <confirm-dialog
      :html-content="$t('Delivery.Button.DeliveryStaff.Delete.PopUp.Content')"
      :html-title="$t('Delivery.Button.DeliveryStaff.Delete.PopUp.Title')"
      :visible.sync="confirmDialogDeleteDeliveryStaffVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="delete-delivery-staff-on-yes-btn"
      @delete-delivery-staff-on-yes-btn="deleteDeliveryStaff"
    />

    <confirm-dialog
      :html-content="$t('Delivery.Button.RemoveOrder.PopUp.Content')"
      :html-title="$t('Delivery.Button.RemoveOrder.PopUp.Title')"
      :visible.sync="confirmDialogDeleteOrderVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="delete-order-on-yes-btn"
      @delete-order-on-yes-btn="deleteOrder"
    />

    <delivery-staff-add-dialog
      :delivery-staff-to-edit="tmpDeliveryStaff"
      :visible.sync="addEditDeliveryStaffDialog"
    />

    <delivery-staff-day-shifts-dialog
      :selected-delivery-staff="selectedDeliveryStaff"
      :selected-shifts-day="selectedShiftsDay"
      :tmp-order.sync="tmpOrder"
      :visible.sync="selectedShiftsDayDialogVisible"
    />

    <select-order-dialog
      :selected-order.sync="selectedOrder"
      :visible.sync="selectedOrderDialogVisible"
    />
  </v-container>
</template>

<script>
import moment                       from 'moment'
import AppData                      from '@/mixins/appdata'
import Auth                         from '@/mixins/auth'
import Resizable                    from '@/mixins/resizable'
import BarcodeGenerator             from '@/lib/barcode/BarcodeGenerator'
import Printer                      from '@/lib/helper/printer'
import SelectOrderDialog            from '@/components/orders/SelectOrderDialog'
import ConfirmDialog                from '@/components/common/ConfirmDialog'
import OrdersVList                  from '@/components/orders/common/OrdersVList'
import DeliveryStaffAddDialog       from '@/components/delivery/DeliveryStaffAddDialog'
import DeliveryStaffDayShiftsDialog from '@/components/delivery/DeliveryStaffDayShiftsDialog'
import BarcodePrefixEnum            from '@/api/Enums/BarcodePrefixEnum'
import DataTable                    from '@/mixins/data-table/dataTable'

export default {
  components: {
    'orders-v-list'                   : OrdersVList,
    'delivery-staff-add-dialog'       : DeliveryStaffAddDialog,
    'delivery-staff-day-shifts-dialog': DeliveryStaffDayShiftsDialog,
    'confirm-dialog'                  : ConfirmDialog,
    barcode                           : BarcodeGenerator,
    'select-order-dialog'             : SelectOrderDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable, DataTable],
  props     : [],

  data () {
    return {
      activeTab: 'tab1',
      dataTable: {
        dataStore: null,
        options  : {
          defaultSortBy: 'date_started',
          sortBy       : ['date_started'],
          sortDesc     : [true]
        },
        resize: {
          offset: 20
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Delivery.ListView.Table.Headers.DateStarted'),
            value    : 'date_started',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Delivery.ListView.Table.Headers.Swifts'),
            value    : 'swifts',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Delivery.ListView.Table.Headers.Orders'),
            value    : 'orders',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Delivery.ListView.Table.Headers.Total'),
            value    : 'total',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Delivery.ListView.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2'
          }
        ]
      },
      selectedDeliveryStaff                  : null,
      tmpDeliveryStaff                       : null,
      tmpOrder                               : null,
      selectedOrder                          : null,
      selectedShiftsDay                      : null,
      selectedShiftsDayDialogVisible         : null,
      startShiftPopOverVisible               : false,
      confirmDialogOpenShiftVisible          : false,
      confirmDialogDeleteDeliveryStaffVisible: false,
      confirmDialogDeleteOrderVisible        : false,
      addEditDeliveryStaffDialog             : false,
      selectedOrderDialogVisible             : false
    }
  },

  computed: {
    deliveryStaff () {
      return window.Vue.deliveryStaffItems
    }
  },

  watch: {
    selectedOrder (newVal) {
      if (newVal) {
        window.callAS(window.SocketCommand.Staff.Delivery.Shift.AddOrder, {
          Id     : this.selectedDeliveryStaff.Id,
          OrderId: newVal.Id
        }, window.SocketCommand.Staff.Delivery.All)
        this.selectedOrder = null
      }
    },

    activeTab (newVal) {
      if (newVal === 'tab2') {
        this.getData()
      }
    }
  },

  created () {
  },

  mounted () {
    this.$bus.$on(window.SocketCommand.Staff.Delivery.All, this.onDeliveryStaffList)
    this.$bus.$on(window.SocketCommand.Order.Get, this.onOrderDetailsResponse)
    this.$bus.$on(window.SocketCommand.Staff.Delivery.ShiftsHistory, this.setData)
    window.callAS(window.SocketCommand.Staff.Delivery.All, {})
  },

  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Staff.Delivery.All, this.onDeliveryStaffList)
    this.$bus.$off(window.SocketCommand.Order.Get, this.onOrderDetailsResponse)
    this.$bus.$off(window.SocketCommand.Staff.Delivery.ShiftsHistory, this.setData)
  },
  methods: {
    showDeliveryShifts (shiftsDay) {
      this.selectedShiftsDay = shiftsDay
      this.selectedShiftsDayDialogVisible = true
    },

    showAddOrderToDeliveryStaff () {
      this.selectedOrderDialogVisible = true
    },

    showDeliveryStaffAddDialog () {
      this.tmpDeliveryStaff = null
      this.addEditDeliveryStaffDialog = true
    },

    showDeliveryStaffEditDialog (staff) {
      this.tmpDeliveryStaff = staff
      this.addEditDeliveryStaffDialog = true
    },

    showCloseDeliveryStaffShiftDialog () {
      this.confirmDialogOpenShiftVisible = true
    },

    showDeleteDeliveryStaffDialog (staff) {
      this.tmpDeliveryStaff = staff
      this.confirmDialogDeleteDeliveryStaffVisible = true
    },

    showDeleteOrderDialog (order) {
      this.tmpOrder = order
      this.confirmDialogDeleteOrderVisible = true
    },

    getDateTimeNow () {
      return moment().format('DD/MM/YYYY HH:mm')
    },

    getDeliveryStaffShiftTotals (withShiftStartMoney) {
      if (!this.selectedDeliveryStaff) return 0

      let i = 0
      let total = 0.00
      const orders = this.selectedDeliveryStaff?.ShiftOrders || []

      for (i = 0; i < orders.length; i++) {
        if (orders[i].Payment === 'cod' && parseInt(orders[i].StatusId) !== 19) {
          total += parseFloat(orders[i].OrderTotal)
        }
      }

      if (withShiftStartMoney) total += parseFloat(this.selectedDeliveryStaff.ShiftStartMoney)

      return total
    },

    deliveryStaffToggle (staff) {
      this.activeTab = 'tab1'
      this.dataTable.data = []

      if (this.selectedDeliveryStaff === staff) {
        this.selectedDeliveryStaff = null
      } else {
        this.selectedDeliveryStaff = staff
      }

      this.$nextTick(function () {
        this.onResize()
      })
    },

    startDeliveryStaffShift () {
      this.startShiftPopOverVisible = false

      if (this.selectedDeliveryStaff) {
        window.callAS(window.SocketCommand.Staff.Delivery.Shift.Open, {
          Id   : this.selectedDeliveryStaff.Id,
          Money: this.selectedDeliveryStaff?.ShiftStartMoney || 0
        }, window.SocketCommand.Staff.Delivery.All)
      }
    },

    closeDeliveryStaffShift () {
      if (this.selectedDeliveryStaff) {
        window.callAS(window.SocketCommand.Staff.Delivery.Shift.Close, { Id: this.selectedDeliveryStaff.Id }, window.SocketCommand.Staff.Delivery.All)
      }
    },

    deleteDeliveryStaff () {
      if (this.tmpDeliveryStaff) {
        window.callAS(window.SocketCommand.Staff.Delivery.Delete, { Id: this.tmpDeliveryStaff.Id }, window.SocketCommand.Staff.Delivery.All)
      }
      this.tmpDeliveryStaff = null
    },

    deleteOrder () {
      if (this.selectedDeliveryStaff && this.tmpOrder) {
        window.callAS(window.SocketCommand.Staff.Delivery.Shift.RemoveOrder, {
          DeliveryStaffShiftOrderId: this.tmpOrder.DeliveryStaffShiftOrderId,
          DeliveryStaffShiftId     : this.selectedDeliveryStaff.DeliveryStaffShiftId,
          OrderId                  : this.tmpOrder.Id
        }, window.SocketCommand.Staff.Delivery.All)
      }
      this.tmpOrder = null
    },

    getOrderDetails (orderItem) {
      this.tmpOrder = orderItem
      window.callAS(window.SocketCommand.Order.Get, {
        order_id : orderItem.Id,
        status_id: orderItem.StatusId
      })
    },

    getBarcode (staff) {
      const barcode = {
        barcode: BarcodePrefixEnum.DeliveryStaff + staff.Id,
        value  : staff.Id
      }

      return barcode
    },

    getOrderListItemColor (order) {
      let colorClass = ''
      colorClass = order.Payment === 'cod' ? 'green lighten-5' : 'blue-grey lighten-5'
      colorClass = parseInt(order.StatusId) !== 19 ? colorClass : 'red lighten-5'
      return colorClass
    },

    onOrderDetailsResponse (data) {
      if (this.tmpOrder) {
        this.tmpOrder.Details = data
        this.$bus.$emit('show-order-details-dialog', this.tmpOrder)
      }
    },

    onDeliveryStaffList (data) {
      if (data && data.status === 'success') {
        if (this.selectedDeliveryStaff) {
          this.$nextTick(() => {
            this.selectedDeliveryStaff = this.deliveryStaff.find(staff => staff.Id.toString() === this.selectedDeliveryStaff.Id.toString())
          })
        }

        this.$nextTick(() => {
          this.onResize()
        })

        if (data.message) {
          this.$bus.$emit('app-show-notification', {
            body: data.message,
            type: 'success',
            icon: 'info'
          })
        }
      } else if (data && data.status === 'fail' && data.message) {
        this.$bus.$emit('app-show-notification', {
          body: data.message,
          type: 'error',
          icon: 'warning'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.GenericError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    getData () {
      if (this.selectedDeliveryStaff) {
        this.dataTable.loading = true
        window.callAS(window.SocketCommand.Staff.Delivery.ShiftsHistory, {
          id    : this.selectedDeliveryStaff.Id,
          ...this.dataTableRequestData(),
          config: 0
        })
      }
    },
    setData (data) {
      this.setDataInternal(data)
    },

    onPrintDeliveryShift (printer) {
      const printWithOrders = this.appConfig.LOCATION_DATA.hasOwnProperty('PrintDeliveryStaffShiftWithOrders') ? this.appConfig.LOCATION_DATA.PrintDeliveryStaffShiftWithOrders : true
      let printContents = ''
      let totalOrdersExcludingCanceled = 0
      let totalCanceledOrders = 0
      let totalOrdersMoneyCanceled = 0
      let totalCardOrders = 0
      let totalOrdersMoneyCard = 0

      printContents += '<div style="width: 100%; text-align: center; margin-top: 12px;"><img style="" width="' + this.$refs.barcodeCanvas.$el.children[0].width + 'px" height="' + this.$refs.barcodeCanvas.$el.children[0].height + 'px" src="' + this.$refs.barcodeCanvas.$el.children[0].toDataURL('image/jpeg') + '" /></div>'
      printContents += '<div style="width: 100%; text-align: center;">'
      printContents += '<span style="position:relative; top: -10px; font-family: Arial; font-size: 12px; text-align: center;">' + (String(this.selectedDeliveryStaff.Firstname + ' ' + this.selectedDeliveryStaff.Lastname).toLocaleUpperCase(this.$i18n.locale)) + '</span>'
      printContents += '</div>'

      if (this.selectedDeliveryStaff && this.selectedDeliveryStaff.HasActiveShift) {
        printContents += '' +
          '<div style="margin-bottom: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.StartShift') + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(this.selectedDeliveryStaff.ShiftStartMoney) + '</div>' +
          '<div>' + this.selectedDeliveryStaff.ShiftStartDateTime + '</div>' +
          '</div>'

        for (let i = 0; i < this.selectedDeliveryStaff.ShiftOrders.length; i++) {
          const isNotCalculated = parseInt(this.selectedDeliveryStaff.ShiftOrders[i].StatusId) === 19 || this.selectedDeliveryStaff.ShiftOrders[i].Payment !== 'cod'
          const isCanceled = parseInt(this.selectedDeliveryStaff.ShiftOrders[i].StatusId) === 19
          const isCash = this.selectedDeliveryStaff.ShiftOrders[i].Payment === 'cod'

          if (isCanceled) {
            totalOrdersMoneyCanceled += parseFloat(this.selectedDeliveryStaff.ShiftOrders[i].OrderTotal)
            totalCanceledOrders++
          } else {
            totalOrdersExcludingCanceled++
          }
          if (!isCash && !isCanceled) {
            totalOrdersMoneyCard += parseFloat(this.selectedDeliveryStaff.ShiftOrders[i].OrderTotal)
            totalCardOrders++
          }

          if (printWithOrders) {
            printContents += '' +
              '<div style="width: 100%; margin-top: 10px; font-family: Arial; font-size: 10px; text-align: center; text-decoration: ' + (isNotCalculated ? 'line-through' : 'none') + ';">' +
              '<span><strong>#' + this.selectedDeliveryStaff.ShiftOrders[i].Id + '</strong></span>' +
              '<span>&nbsp;' + (parseInt(this.selectedDeliveryStaff.ShiftOrders[i].OrderTypeId) === 1 ? this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Delivery').toLocaleUpperCase(this.$i18n.locale) : this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Collection').toLocaleUpperCase(this.$i18n.locale)) + '</span>' +
              '<span>&nbsp;' + (this.selectedDeliveryStaff.ShiftOrders[i].Payment === 'cod' ? this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Cash').toLocaleUpperCase(this.$i18n.locale) : this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Card').toLocaleUpperCase(this.$i18n.locale)) + '&nbsp;' + (parseInt(this.selectedDeliveryStaff.ShiftOrders[i].StatusId) === 19 ? this.$t('Delivery.PrintDeliveryShift.PrintReceipt.Canceled').toLocaleUpperCase(this.$i18n.locale) : '') + '</span>' +
              '<div>' +
              '<span>&nbsp;' + this.selectedDeliveryStaff.ShiftOrders[i].DateAdded + ' - ' + this.selectedDeliveryStaff.ShiftOrders[i].TimeAdded + '</span>' +
              '<span><strong>&nbsp;' + this.$options.filters.currency(this.selectedDeliveryStaff.ShiftOrders[i].OrderTotal) + '</strong></span>' +
              '</div>' +
              '</div>'
          }
        }

        totalOrdersMoneyCanceled = this.$options.filters.currency(totalOrdersMoneyCanceled)
        totalOrdersMoneyCard = this.$options.filters.currency(totalOrdersMoneyCard)

        printContents += '' +
          '<div style="width: 100%; margin-top: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Delivery.Common.ShiftTotal') + '</strong></div>' +
          '<div style="font-size: 14px;"><strong>' + (totalOrdersExcludingCanceled - totalCardOrders) + ' ' + this.$tc('Order.Title', (totalOrdersExcludingCanceled - totalCardOrders)) + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(this.getDeliveryStaffShiftTotals(true)) + '</div>' +
          '<div><strong>' + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.WithoutStart') + '</strong> ' + this.$options.filters.currency(this.getDeliveryStaffShiftTotals(false)) + '</div>'

        if (totalCardOrders > 0 || totalCanceledOrders > 0) {
          printContents += '<br><div style="font-size: 12px;"><strong>' + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.NotIncluded') + '</strong></div>'
        }

        if (totalCardOrders > 0) {
          printContents += '<div style="font-size: 12px;">' + totalCardOrders + this.$t('Delivery.PrintDeliveryShift.PrintReceipt.ByCard') + totalOrdersMoneyCard + ')</div>'
        }

        if (totalCanceledOrders > 0) {
          printContents += '<div style="font-size: 12px;">' + totalCanceledOrders + ' ' + this.$tc('Order.Status.Canceled', totalCanceledOrders) + ' (' + totalOrdersMoneyCanceled + ')' + '</div>'
        }
        printContents += '</div>'
      }

      const html = '<html>' +
        '<head>' +
        '<style>' +
        ' @page {' +
        '            width: 100%;' +
        '            margin-left   : 0px;' +
        '            margin-right  : 0px;' +
        '            margin-top    : 0px;' +
        '            margin-bottom : 0px;' +
        '        }' +
        'body { width: 100%; border: 0; margin: 0; overflow : hidden; }' +
        '@media print { body { width: 100%; overflow : hidden; border: 0; margin: 0; } }' +
        '</style>' +
        '</head>' +
        '<body>' + printContents + '</body>' +
        '</html>'

      // console.log(html);

      this.$bus.$emit('app-show-notification', {
        body: this.$t('Delivery.PrintDeliveryShift.Notification'),
        type: 'info',
        icon: 'print'
      })

      Printer.printStaffShift(html, printer.name)
    },

    onResize () {
      const deliveryStaffList = this.$refs.deliveryStaffList
      const ordersList = this.$refs.ordersList

      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const deliveryStaffToolbarHeight = this.$refs.deliveryStaffToolbar ? this.$refs.deliveryStaffToolbar.$el.clientHeight : 0

      if (deliveryStaffList) deliveryStaffList.style.height = this.$vuetify.breakpoint.smAndDown ? '45px' : (innerHeight - headerHeight - footerHeight - deliveryStaffToolbarHeight - 24) + 'px'
      if (ordersList) ordersList.style.height = (innerHeight - headerHeight - footerHeight - deliveryStaffToolbarHeight - 256 - 8) + 'px'
    }
  }
}
</script>
