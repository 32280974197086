<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.CatalogAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.CatalogAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!catalogToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.CatalogAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.CatalogAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.CatalogAddDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Restaurant.CatalogAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="catalogForm"
            v-model="catalogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editCatalog.Name"
                    :counter="255"
                    :maxlength="255"
                    :hint="$t('Restaurant.CatalogAddDialog.Field.Name.Hint')"
                    :label="$t('Restaurant.CatalogAddDialog.Field.Name.Label')"
                    :prepend-icon="$t('Restaurant.CatalogAddDialog.Field.Name.Icon')"
                    :rules="validationRules.catalogForm.Name"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editCatalog.Description"
                    :hint="$t('Restaurant.CatalogAddDialog.Field.Description.Hint')"
                    :label="$t('Restaurant.CatalogAddDialog.Field.Description.Label')"
                    :prepend-icon="$t('Restaurant.CatalogAddDialog.Field.Description.Icon')"
                    :rules="validationRules.catalogForm.Description"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editCatalog.SourceTypes"
                    :hint="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Hint')"
                    :items="sourceTypesListFiltered"
                    :label="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Label')"
                    :prepend-icon="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Icon')"
                    :rules="validationRules.catalogForm.SourceTypes"
                    persistent-hint
                    item-text="Name"
                    item-value="Id"
                    multiple
                  >
                    <template #selection="{item}">
                      <v-chip
                        small
                        label
                        :color="item.Status ? 'green' : ''"
                        :dark="item.Status"
                        class="pl-0"
                      >
                        <v-avatar
                          tile
                          :color="item.Avatar.Color"
                          class="mr-2"
                        >
                          <v-icon
                            v-if="item.Avatar.Icon"
                            class="white--text"
                          >
                            {{ item.Avatar.Icon }}
                          </v-icon>

                          <img
                            v-else
                            :src="item.Avatar.Img"
                          >
                        </v-avatar>
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :size="28"
                        :color="data.item.Avatar.Color"
                      >
                        <v-icon
                          v-if="data.item.Avatar.Icon"
                          class="white--text"
                        >
                          {{ data.item.Avatar.Icon }}
                        </v-icon>

                        <img
                          v-else
                          :src="data.item.Avatar.Img"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editCatalog.OrderType"
                    :hint="$t('Restaurant.CatalogAddDialog.Field.OrderType.Hint')"
                    :items="orderTypesListFiltered"
                    :label="$t('Restaurant.CatalogAddDialog.Field.OrderType.Label')"
                    :prepend-icon="$t('Restaurant.CatalogAddDialog.Field.OrderType.Icon')"
                    :rules="validationRules.catalogForm.OrderType"
                    persistent-hint
                    item-text="Name"
                    item-value="Id"
                  >
                    <template #selection="{item}">
                      <v-chip
                        small
                        label
                        :color="item.Status ? 'green' : ''"
                        :dark="item.Status"
                        class="pl-0"
                      >
                        <v-avatar
                          tile
                          :color="item.Color"
                          class="mr-2"
                        >
                          <v-icon class="white--text">
                            {{ item.Icon }}
                          </v-icon>
                        </v-avatar>
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar :color="data.item.Color">
                        <v-icon dark>
                          {{ data.item.Icon }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <template v-if="userIsSuperAdmin">
                  <v-subheader>Platforms</v-subheader>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editCatalog.ShopId"
                      prepend-icon="mdi-identifier"
                      label="Shop Id"
                      class="pr-2"
                      required
                      readonly
                    >
                      <template #append-outer>
                        <v-btn
                          class="ma-0 mr-1"
                          icon
                          small
                          @click="copyToClipboard(editCatalog.ShopId)"
                        >
                          <v-icon small>
                            far fa-copy
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editCatalog.InternalId"
                      prepend-icon="mdi-identifier"
                      label="Internal Id"
                      class="pr-2"
                      required
                      readonly
                    >
                      <template #append-outer>
                        <v-btn
                          class="ma-0 mr-1"
                          icon
                          small
                          @click="copyToClipboard(editCatalog.InternalId)"
                        >
                          <v-icon small>
                            far fa-copy
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </template>
              </v-row>

              <v-row dense>
                <v-col
                  v-if="userIsSuperAdmin && editCatalog.Id && editCatalog.Api"
                  cols="4"
                >
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.CatalogAddDialog.Field.CanCreateOrder.Label') }}
                  </v-subheader>
                  <v-switch
                    v-model="editCatalog.CanCreateOrder"
                    inset
                    :label="editCatalog.CanCreateOrder ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>

                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>
                  <v-switch
                    v-model="editCatalog.Status"
                    inset
                    :disabled="(catalogToEdit && catalogToEdit.Default)"
                    :label="editCatalog.Status ? $t('Common.Button.Toggle.ActiveM') : $t('Common.Button.Toggle.InactiveM')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>

                <v-col
                  v-if="editCatalog.Default"
                  cols="4"
                >
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.CatalogAddDialog.Field.Default.Label') }}
                  </v-subheader>

                  <v-icon color="green">
                    check_circle_outline
                  </v-icon>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <div class="grey--text pt-2">
                    {{ $t('Restaurant.CatalogAddDialog.Field.Color.Label') }}
                  </div>
                  <color-picker
                    v-model="editCatalog.TmpColor"
                    style="width: 100%"
                    :style="isDarkMode ? 'background-color: #191919;' : 'background-color: white;'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'
import Translatable   from '@/mixins/translatable'
import SourceType     from '@/mixins/orders/sourceType'
import { Swatches }   from 'vue-color'
import SourceTypeEnum from '@/api/Enums/SourceTypeEnum'
import { clone }      from '@/lib/helper/helper'

export default {
  name      : 'CatalogAddDialog',
  components: {
    'color-picker': Swatches
  },
  mixins: [AppData, Auth, Translatable, SourceType],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    catalogToEdit: {
      type   : Object,
      default: undefined
    },
    taxes: {
      type   : Array,
      default: () => []
    },
    catalogs: {
      type   : Array,
      default: () => []
    },
    sourceTypesList: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      loading         : false,
      catalogFormValid: false,
      categories      : [],
      newCatalog      : null,
      tmpCatalog      : {
        Id         : null,
        LocationId : 1,
        Name       : '',
        Description: '',
        Color      : 'B71C1C',
        TmpColor   : {
          hex   : '',
          source: ''
        },
        SourceTypes   : [],
        OrderType     : null,
        Default       : false,
        Status        : false,
        CanCreateOrder: true
      },
      validationRules: {
        catalogForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 255 || this.$t('Restaurant.CatalogAddDialog.Field.Name.Error.Between')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 255) || this.$t('Restaurant.CatalogAddDialog.Field.Description.Error.Between')
          ],
          SourceTypes: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Error.Required')
          ],
          OrderType: [
            (v) => v !== null && v >= 0 || this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    sourceTypesListFiltered () {
      return this.sourceTypesList
    },

    orderTypesListFiltered () {
      const allCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs || []
      const orderTypes = this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Items')
      const retVal = orderTypes.filter(orderType => !allCatalogs.find(catalog => this.editCatalog.SourceTypes.some(sourceType => catalog.SourceTypes.includes(sourceType) && SourceTypeEnum.EXCLUDE_MULTIPLE_CATALOGS.includes(sourceType)) && catalog.OrderType === orderType.Id && catalog.Id !== this.editCatalog.Id))

      return retVal
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editCatalog: {
      get () {
        if (this.catalogToEdit) {
          const c = clone(this.catalogToEdit).Color
          this.newCatalog = clone(this.catalogToEdit)
          this.newCatalog.TmpColor = {
            hex   : c ? '#' + c : '',
            source: 'hex'
          }
        } else {
          const c = clone(this.tmpCatalog).Color
          this.newCatalog = clone(this.tmpCatalog)
          this.newCatalog.TmpColor = {
            hex   : c ? '#' + c : '',
            source: 'hex'
          }
        }

        return this.newCatalog
      }
    }
  },
  watch: {
    'editCatalog.SourceTypes': function (newVal, oldVal) {
      if (newVal.length > 1) {
        if (newVal.includes(0) && !oldVal.includes(0)) {
          this.editCatalog.SourceTypes = [0]
        } else if (newVal.includes(0)) {
          this.editCatalog.SourceTypes = newVal.filter(sourceType => sourceType !== 0)
        }
      }

      if (!this.orderTypesListFiltered.find(orderType => orderType.Id === this.editCatalog.OrderType) && !this.userIsSuperAdmin) {
        this.editCatalog.OrderType = null
      }

      if (newVal === SourceTypeEnum.FAGI) this.editCatalog.CanCreateOrder = false
    },

    visible (newVal) {
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.catalogForm) this.$refs.catalogForm.resetValidation()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Catalog.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Catalog.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.catalogForm.validate()

      if (this.catalogFormValid) {
        this.editCatalog.Color = JSON.parse(JSON.stringify(this.editCatalog)).TmpColor.hex.replace('#', '')
        this.loading = true

        window.callAS(window.SocketCommand.Catalog.Save, this.editCatalog)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()

        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.CatalogAddDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }

      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.catalogForm) this.$refs.catalogForm.resetValidation()
      this.newCatalog = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
.vc-swatches {
  min-width  : 472px;
  height     : auto;
  overflow-y : auto;
  zoom       : 75%;
}

/deep/ .vc-swatches .vc-swatches-box div.vc-swatches-color-group:last-child {
  display : none;
}

/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

</style>
