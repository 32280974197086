export const advancedDotNotationItemsDefaults = {
  'global.background.color'                                                           : '#fafafa',
  'global.text.color'                                                                 : '#424242',
  'global.button.background.color'                                                    : '#d50000',
  'global.button.text.color'                                                          : '#ffffff',
  'common.toolbar.background.color'                                                   : '#212121',
  'common.toolbar.text.color'                                                         : '#ffffff',
  'common.toolbar.item.text.color'                                                    : '#ffffff',
  'common.toolbar.item.icon.color'                                                    : '#ffffff',
  'common.toolbar.item.active.color'                                                  : '#d50000',
  'common.footer.mobile.background.color'                                             : '#ffffff',
  'common.footer.mobile.text.background.color'                                        : '#ffffff',
  'common.footer.mobile.text.title.color'                                             : '#212121',
  'common.footer.mobile.text.description.color'                                       : '#757575',
  'common.footer.social.background.color'                                             : '#424242',
  'common.footer.social.text.color'                                                   : '#ffffff',
  'common.footer.social.button.color'                                                 : '#ffffff',
  'common.footer.links.background.color'                                              : '#eeeeee',
  'common.footer.links.text.color'                                                    : '#424242',
  'common.footer.copyright.background.color'                                          : '#212121',
  'common.footer.copyright.text.color'                                                : '#ffffff',
  'common.cart.background.color'                                                      : '#ffffff',
  'common.cart.text.color'                                                            : '#424242',
  'common.cart.toolbar.background.color'                                              : '#f5f5f5',
  'common.cart.toolbar.title.color'                                                   : '#616161',
  'common.cart.toolbar.icon.color'                                                    : '#616161',
  'common.cart.toolbar.icon.badge.background.color'                                   : '#d50000',
  'common.cart.toolbar.icon.badge.text.color'                                         : '#ffffff',
  'common.cart.button.buyNow.background.color'                                        : '#B40A0A',
  'common.cart.button.buyNow.text.color'                                              : '#ffffff',
  'common.cart.button.orderType.delivery.background.color'                            : '#9c27b0',
  'common.cart.button.orderType.delivery.text.color'                                  : '#ffffff',
  'common.cart.button.orderType.collection.background.color'                          : '#1565c0',
  'common.cart.button.orderType.collection.text.color'                                : '#ffffff',
  'common.cart.button.remove.color'                                                   : '#f44336',
  'common.cart.coupon.text.color'                                                     : '#757575',
  'common.cart.coupon.icon.color'                                                     : '#757575',
  'common.cart.coupon.button.background.color'                                        : '#9e9e9e',
  'common.cart.coupon.button.text.color'                                              : '#ffffff',
  'common.cart.tip.text.color'                                                        : '#757575',
  'common.cart.tip.icon.color'                                                        : '#757575',
  'common.cart.tip.button.background.color'                                           : '#e0e0e0',
  'common.cart.tip.button.background.active.color'                                    : '#4caf50',
  'common.cart.tip.button.text.color'                                                 : '#000000',
  'common.cart.tip.button.text.active.color'                                          : '#ffffff',
  'common.cart.tip.button.icon.color'                                                 : '#757575',
  'common.cart.tip.button.icon.active.color'                                          : '#e0e0e0',
  'common.cart.items.category.background.color'                                       : '#e1e1e1',
  'common.cart.items.category.text.color'                                             : '#757575',
  'common.cart.items.total.background.color'                                          : '#e1e1e1',
  'common.cart.items.total.text.color'                                                : '#212121',
  'common.cart.items.product.text.price.color'                                        : '#757575',
  'common.cart.items.product.text.description.color'                                  : '#9e9e9e',
  'common.cart.items.product.text.title.color'                                        : '#424242',
  'common.cart.items.product.text.title.quantity.color'                               : '#ff9800',
  'common.cart.items.product.background.color'                                        : '#f5f5f5',
  'common.productDialog.toolbar.background.color'                                     : '#ffffff',
  'common.productDialog.toolbar.title.color'                                          : '#616161',
  'common.productDialog.toolbar.quantity.color'                                       : '#ff9800',
  'common.productDialog.toolbar.price.color'                                          : '#212121',
  'common.productDialog.body.background.color'                                        : '#eeeeee',
  'common.productDialog.body.description.color'                                       : '#212121',
  'common.productDialog.body.options.title.color'                                     : '#212121',
  'common.productDialog.body.options.option.background.color'                         : '#ffffff',
  'common.productDialog.body.options.option.text.color'                               : '#757575',
  'common.productDialog.body.options.option.control.color'                            : '#B40A0A',
  'common.productDialog.body.comments.color'                                          : '#8C8C8C',
  'common.productDialog.actions.background.color'                                     : '#ffffff',
  'common.productDialog.actions.text.color'                                           : '#616161',
  'common.productDialog.actions.button.background.color'                              : '#B40A0A',
  'common.productDialog.actions.button.title.color'                                   : '#ffffff',
  'common.productDialog.actions.quantity.title.color'                                 : '#757575',
  'common.productDialog.actions.quantity.button.color'                                : '#9e9e9e',
  'common.login.toolbar.background.color'                                             : '#ffffff',
  'common.login.toolbar.icon.color'                                                   : '#8C8C8C',
  'common.login.toolbar.title.color'                                                  : '#212121',
  'common.login.toolbar.description.color'                                            : '#424242',
  'common.login.body.background.color'                                                : '#eeeeee',
  'common.login.body.title.color'                                                     : '#616161',
  'common.login.body.text.color'                                                      : '#424242',
  'common.login.body.icon.color'                                                      : '#bdbdbd',
  'common.login.body.link.color'                                                      : '#757575',
  'common.login.body.control.color'                                                   : '#8C8C8C',
  'common.login.body.button.login.background.color'                                   : '#d50000',
  'common.login.body.button.login.text.color'                                         : '#ffffff',
  'common.login.body.button.register.background.color'                                : '#ffa726',
  'common.login.body.button.register.text.color'                                      : '#ffffff',
  'common.register.toolbar.background.color'                                          : '#ffffff',
  'common.register.toolbar.icon.color'                                                : '#8C8C8C',
  'common.register.toolbar.title.color'                                               : '#212121',
  'common.register.toolbar.description.color'                                         : '#424242',
  'common.register.body.background.color'                                             : '#eeeeee',
  'common.register.body.title.color'                                                  : '#616161',
  'common.register.body.text.color'                                                   : '#424242',
  'common.register.body.link.color'                                                   : '#d50000',
  'common.register.body.control.color'                                                : '#8C8C8C',
  'common.register.body.button.register.background.color'                             : '#ffa726',
  'common.register.body.button.register.text.color'                                   : '#ffffff',
  'common.forgotPassword.toolbar.background.color'                                    : '#ffffff',
  'common.forgotPassword.toolbar.icon.color'                                          : '#8C8C8C',
  'common.forgotPassword.toolbar.title.color'                                         : '#212121',
  'common.forgotPassword.toolbar.description.color'                                   : '#424242',
  'common.forgotPassword.body.background.color'                                       : '#eeeeee',
  'common.forgotPassword.body.text.color'                                             : '#757575',
  'common.forgotPassword.body.link.color'                                             : '#d50000',
  'common.forgotPassword.body.control.color'                                          : '#8C8C8C',
  'common.forgotPassword.body.button.reset.background.color'                          : '#ffa726',
  'common.forgotPassword.body.button.reset.text.color'                                : '#ffffff',
  'common.gdpr.toolbar.background.color'                                              : '#ffffff',
  'common.gdpr.toolbar.icon.color'                                                    : '#8C8C8C',
  'common.gdpr.toolbar.title.color'                                                   : '#212121',
  'common.gdpr.toolbar.description.color'                                             : '#424242',
  'common.gdpr.body.background.color'                                                 : '#eeeeee',
  'common.gdpr.body.text.color'                                                       : '#757575',
  'common.gdpr.body.control.color'                                                    : '#8C8C8C',
  'common.gdpr.body.button.check.background.color'                                    : '#ffa726',
  'common.gdpr.body.button.check.text.color'                                          : '#ffffff',
  'common.confirm.toolbar.background.color'                                           : '#f44336',
  'common.confirm.toolbar.icon.color'                                                 : '#ffffff',
  'common.confirm.toolbar.title.color'                                                : '#ffffff',
  'common.confirm.toolbar.description.color'                                          : '#ffffff',
  'common.confirm.body.background.color'                                              : '#ffffff',
  'common.confirm.body.text.color'                                                    : '#757575',
  'common.confirm.body.link.color'                                                    : '#d50000',
  'common.confirm.actions.background.color'                                           : '#ffffff',
  'common.confirm.actions.button.cancel.background.color'                             : '#757575',
  'common.confirm.actions.button.cancel.text.color'                                   : '#ffffff',
  'common.confirm.actions.button.no.background.color'                                 : '#B40A0A',
  'common.confirm.actions.button.no.text.color'                                       : '#ffffff',
  'common.confirm.actions.button.yes.background.color'                                : '#e53935',
  'common.confirm.actions.button.yes.text.color'                                      : '#ffffff',
  'common.sidebar.needHelp.toolbar.background.color'                                  : '#f5f5f5',
  'common.sidebar.needHelp.toolbar.title.color'                                       : '#616161',
  'common.sidebar.needHelp.toolbar.icon.color'                                        : '#616161',
  'common.sidebar.needHelp.body.background.color'                                     : '#ffffff',
  'common.sidebar.needHelp.body.title.color'                                          : '#616161',
  'common.sidebar.needHelp.body.icon.color'                                           : '#616161',
  'common.sidebar.needHelp.body.label.color'                                          : '#e0e0e0',
  'common.sidebar.needHelp.body.text.color'                                           : '#9e9e9e',
  'common.sidebar.deliveryAreas.toolbar.background.color'                             : '#f5f5f5',
  'common.sidebar.deliveryAreas.toolbar.title.color'                                  : '#616161',
  'common.sidebar.deliveryAreas.toolbar.icon.color'                                   : '#616161',
  'common.sidebar.deliveryAreas.body.background.color'                                : '#ffffff',
  'common.sidebar.deliveryAreas.body.list.area.title.color'                           : '#212121',
  'common.sidebar.deliveryAreas.body.list.area.selected.color'                        : '#f5f5f5',
  'common.sidebar.deliveryAreas.body.list.area.text.color'                            : '#424242',
  'common.sidebar.workingHours.toolbar.background.color'                              : '#f5f5f5',
  'common.sidebar.workingHours.toolbar.title.color'                                   : '#616161',
  'common.sidebar.workingHours.toolbar.icon.color'                                    : '#616161',
  'common.sidebar.workingHours.body.background.color'                                 : '#ffffff',
  'common.sidebar.workingHours.body.text.color'                                       : '#424242',
  'common.sidebar.workingHours.body.todayOpen.background.color'                       : '#8C8C8C',
  'common.sidebar.workingHours.body.todayOpen.text.color'                             : '#ffffff',
  'common.sidebar.workingHours.body.todayClosed.background.color'                     : '#f44336',
  'common.sidebar.workingHours.body.todayClosed.text.color'                           : '#ffffff',
  'common.cookies.text.color'                                                         : '#ffffff',
  'common.cookies.link.color'                                                         : '#4caf50',
  'common.cookies.background.color'                                                   : '#212121',
  'common.cookies.button.background.color'                                            : '#4caf50',
  'common.cookies.button.hover.color'                                                 : '#278d2b',
  'common.cookies.button.text.color'                                                  : '#ffffff',
  'pages.home.background.color'                                                       : '#fafafa',
  'pages.home.text.color'                                                             : '#424242',
  'pages.home.sections.categories.background.color'                                   : '#d50000',
  'pages.home.sections.categories.title.color'                                        : '#ffffff',
  'pages.home.sections.categories.text.color'                                         : '#ffffff',
  'pages.home.sections.categories.button.color'                                       : '#ffffff',
  'pages.home.sections.featuredProducts.background.color'                             : '#212121',
  'pages.home.sections.featuredProducts.title.color'                                  : '#ffffff',
  'pages.home.sections.featuredProducts.product.image.background.color'               : '#ffffff',
  'pages.home.sections.featuredProducts.product.text.title.color'                     : '#ffffff',
  'pages.home.sections.featuredProducts.product.text.description.color'               : '#ffffff',
  'pages.home.sections.featuredProducts.product.text.button.color'                    : '#ffffff',
  'pages.home.sections.featuredProducts.product.text.background.color'                : '#d50000',
  'pages.home.sections.featuredCategories.background.color'                           : '#eeeeee',
  'pages.home.sections.featuredCategories.title.color'                                : '#212121',
  'pages.home.sections.featuredCategories.category.image.background.color'            : '#ffffff',
  'pages.home.sections.featuredCategories.category.text.title.color'                  : '#212121',
  'pages.home.sections.featuredCategories.category.text.button.color'                 : '#212121',
  'pages.home.sections.featuredCategories.category.text.background.color'             : '#ffffff',
  'pages.home.sections.offers.background.color'                                       : '#d50000',
  'pages.home.sections.offers.title.color'                                            : '#ffffff',
  'pages.home.sections.offers.description.color'                                      : '#ffffff',
  'pages.home.sections.offers.offer.image.background.color'                           : '#ffffff',
  'pages.home.sections.offers.offer.ribbon.background.color'                          : '#d50000',
  'pages.home.sections.offers.offer.ribbon.text.color'                                : '#ffffff',
  'pages.home.sections.offers.offer.text.title.color'                                 : '#212121',
  'pages.home.sections.offers.offer.text.background.color'                            : '#ffffff',
  'pages.home.sections.partners.background.color'                                     : '#ffffff',
  'pages.home.sections.partners.title.color'                                          : '#212121',
  'pages.catalog.background.color'                                                    : '#fafafa',
  'pages.catalog.text.color'                                                          : '#424242',
  'pages.catalog.sections.categories.background.color'                                : '#ffffff',
  'pages.catalog.sections.categories.active.color'                                    : '#eeeeee',
  'pages.catalog.sections.categories.text.color'                                      : '#757575',
  'pages.catalog.sections.categories.tabs.background.color'                           : '#424242',
  'pages.catalog.sections.categories.tabs.active.color'                               : '#d50000',
  'pages.catalog.sections.categories.tabs.text.color'                                 : '#ffffff',
  'pages.catalog.sections.catalog.category.background.color'                          : '#000000',
  'pages.catalog.sections.catalog.category.title.color'                               : '#ffffff',
  'pages.catalog.sections.catalog.category.title.background.color'                    : '#bdbdbd',
  'pages.catalog.sections.catalog.product.background.color'                           : '#f5f5f5',
  'pages.catalog.sections.catalog.product.number.background.color'                    : '#e0e0e0',
  'pages.catalog.sections.catalog.product.number.text.color'                          : '#616161',
  'pages.catalog.sections.catalog.product.quantity.color'                             : '#ff9800',
  'pages.catalog.sections.catalog.product.title.color'                                : '#424242',
  'pages.catalog.sections.catalog.product.description.color'                          : '#757575',
  'pages.catalog.sections.catalog.product.price.color'                                : '#B40A0A',
  'pages.catalog.sections.catalog.product.quickAddButton.text.color'                  : '#ffffff',
  'pages.catalog.sections.catalog.product.quickAddButton.background.color'            : '#B40A0A',
  'pages.catalog.sections.search.color'                                               : '#B40A0A',
  'pages.catalog.sections.search.background.color'                                    : '#ffffff',
  'pages.contact.background.color'                                                    : '#fafafa',
  'pages.contact.text.color'                                                          : '#424242',
  'pages.contact.form.section.background.color'                                       : '#8C8C8C',
  'pages.contact.form.section.icon.color'                                             : '#ffffff',
  'pages.contact.form.section.title.color'                                            : '#ffffff',
  'pages.contact.form.button.background.color'                                        : '#B40A0A',
  'pages.contact.form.button.text.color'                                              : '#ffffff',
  'pages.contact.form.fields.color'                                                   : '#8C8C8C',
  'pages.contact.form.text.color'                                                     : '#424242',
  'pages.account.background.color'                                                    : '#fafafa',
  'pages.account.text.color'                                                          : '#424242',
  'pages.account.tabs.background.color'                                               : '#424242',
  'pages.account.tabs.text.color'                                                     : '#ffffff',
  'pages.account.tabs.active.color'                                                   : '#d50000',
  'pages.account.profile.controls.color'                                              : '#8C8C8C',
  'pages.account.profile.button.edit.color'                                           : '#8bc34a',
  'pages.account.profile.button.edit.text.color'                                      : '#ffffff',
  'pages.account.profile.button.cancel.color'                                         : '#9e9e9e',
  'pages.account.profile.button.cancel.text.color'                                    : '#9e9e9e',
  'pages.account.profile.button.save.color'                                           : '#B40A0A',
  'pages.account.profile.button.save.text.color'                                      : '#ffffff',
  'pages.account.profile.button.manage.color'                                         : '#9e9e9e',
  'pages.account.profile.button.manage.text.color'                                    : '#ffffff',
  'pages.account.profile.button.delete.color'                                         : '#f44336',
  'pages.account.profile.button.delete.text.color'                                    : '#ffffff',
  'pages.account.profile.button.disable.color'                                        : '#ff9800',
  'pages.account.profile.button.disable.text.color'                                   : '#ffffff',
  'pages.account.profile.button.enable.color'                                         : '#4caf50',
  'pages.account.profile.button.enable.text.color'                                    : '#ffffff',
  'pages.account.profile.button.export.color'                                         : '#2196f3',
  'pages.account.profile.button.export.text.color'                                    : '#ffffff',
  'pages.account.addresses.card.background.color'                                     : '#f5f5f5',
  'pages.account.addresses.card.text.color'                                           : '#424242',
  'pages.account.addresses.button.add.color'                                          : '#B40A0A',
  'pages.account.addresses.button.add.text.color'                                     : '#ffffff',
  'pages.account.addresses.button.edit.color'                                         : '#9e9e9e',
  'pages.account.addresses.button.delete.color'                                       : '#f44336',
  'pages.account.addresses.addressDialog.toolbar.background.color'                    : '#ffffff',
  'pages.account.addresses.addressDialog.toolbar.title.color'                         : '#616161',
  'pages.account.addresses.addressDialog.toolbar.description.color'                   : '#9e9e9e',
  'pages.account.addresses.addressDialog.body.background.color'                       : '#eeeeee',
  'pages.account.addresses.addressDialog.body.text.color'                             : '#212121',
  'pages.account.addresses.addressDialog.body.controls.color'                         : '#8C8C8C',
  'pages.account.addresses.addressDialog.actions.background.color'                    : '#ffffff',
  'pages.account.addresses.addressDialog.actions.text.color'                          : '#616161',
  'pages.account.addresses.addressDialog.actions.button.cancel.background.color'      : '#757575',
  'pages.account.addresses.addressDialog.actions.button.cancel.text.color'            : '#ffffff',
  'pages.account.addresses.addressDialog.actions.button.save.background.color'        : '#B40A0A',
  'pages.account.addresses.addressDialog.actions.button.save.text.color'              : '#ffffff',
  'pages.account.addresses.selectAddressDialog.toolbar.background.color'              : '#ffffff',
  'pages.account.addresses.selectAddressDialog.toolbar.title.color'                   : '#616161',
  'pages.account.addresses.selectAddressDialog.toolbar.description.color'             : '#9e9e9e',
  'pages.account.addresses.selectAddressDialog.body.background.color'                 : '#eeeeee',
  'pages.account.addresses.selectAddressDialog.body.text.color'                       : '#212121',
  'pages.account.addresses.selectAddressDialog.body.list.item.background.color'       : '#ffffff',
  'pages.account.addresses.selectAddressDialog.body.list.item.title.color'            : '#757575',
  'pages.account.addresses.selectAddressDialog.body.list.item.address.title.color'    : '#424242',
  'pages.account.addresses.selectAddressDialog.body.list.item.address.text.color'     : '#757575',
  'pages.account.addresses.selectAddressDialog.actions.background.color'              : '#ffffff',
  'pages.account.addresses.selectAddressDialog.actions.text.color'                    : '#616161',
  'pages.account.addresses.selectAddressDialog.actions.button.cancel.background.color': '#757575',
  'pages.account.addresses.selectAddressDialog.actions.button.cancel.text.color'      : '#ffffff',
  'pages.account.orders.list.item.background.color'                                   : '#eeeeee',
  'pages.account.orders.list.item.title.color'                                        : '#757575',
  'pages.account.orders.list.item.subtitle.color'                                     : '#9e9e9e',
  'pages.account.orders.list.item.price.color'                                        : '#455a64',
  'pages.account.orders.list.item.button.view.color'                                  : '#B40A0A',
  'pages.account.orders.list.item.button.reorder.color'                               : '#2196f3',
  'pages.checkout.background.color'                                                   : '#fafafa',
  'pages.checkout.text.color'                                                         : '#424242',
  'pages.checkout.steps.background.color'                                             : '#f5f5f5',
  'pages.checkout.details.user.color'                                                 : '#8C8C8C',
  'pages.checkout.details.user.text.color'                                            : '#d50000',
  'pages.checkout.details.user.link.color'                                            : '#d50000',
  'pages.checkout.details.header.background.color'                                    : '#8C8C8C',
  'pages.checkout.details.header.title.color'                                         : '#ffffff',
  'pages.checkout.details.header.icon.color'                                          : '#ffffff',
  'pages.checkout.details.address.card.background.color'                              : '#ffffff',
  'pages.checkout.details.address.card.text.color'                                    : '#424242',
  'pages.checkout.details.address.card.button.add.color'                              : '#B40A0A',
  'pages.checkout.details.address.card.button.add.text.color'                         : '#ffffff',
  'pages.checkout.details.address.card.button.change.color'                           : '#607d8b',
  'pages.checkout.details.address.card.button.change.text.color'                      : '#ffffff',
  'pages.informational.background.color'                                              : '#fafafa',
  'pages.informational.text.color'                                                    : '#424242',
  'pages.informational.content.background.color'                                      : '#ffffff',
  'pages.informational.content.list.background.color'                                 : '#ffffff',
  'pages.informational.content.list.text.color'                                       : '#424242',
  'pages.informational.content.list.active.color'                                     : '#9e9e9e',
  'pages.informational.content.tabs.background.color'                                 : '#424242',
  'pages.informational.content.tabs.text.color'                                       : '#ffffff',
  'pages.informational.content.tabs.active.color'                                     : '#d50000'
}
