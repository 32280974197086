<template>
  <div>
    <v-combobox
      v-if="autocomplete"
      ref="autocompleteServiceComponent"
      v-model.trim="modelStr"
      :items="locationFoundItems"
      :rules="rules"
      :search-input.sync="locationSearchText"
      :hint="hint"
      :placeholder="placeholder"
      :loading="loading"
      :autofocus="autofocus"
      :readonly="readonly"
      :dark="dark"
      :color="color"
      :disabled="disabled"
      :error-messages="errorMessages"
      item-text="Name"
      prepend-icon="location_on"
      hide-no-data
      no-filter
      clearable
      @blur="onBlur"
      @input="onInput"
      @focus="onFocus"
      @change="onChange"
      @click:clear="clearIconCb"
    />

    <v-text-field
      v-else
      ref="autocompleteServiceComponent"
      v-model.trim="locationSearchText"
      :rules="rules"
      :hint="hint"
      :placeholder="placeholder"
      :loading="loading"
      :autofocus="autofocus"
      :readonly="readonly"
      :dark="dark"
      :color="color"
      :disabled="disabled"
      :error-messages="errorMessages"
      prepend-icon="location_on"
      clearable
      @blur="onBlur"
      @input="onInput"
      @focus="onFocus"
      @change="onChange"
      @click:clear="clearIconCb"
    />

    <v-map
      v-if="map && !mapRef"
      :map.sync="mapObj"
      :map-center="markerLatLng"
      :address-marker="markerLatLng"
      :store-marker="storeMarker"
      :width="mapWidth"
      :height="mapHeight"
      :map-styles="mapStyles"
      show-store-marker
      show-address-marker
      draggable
      @dragend="onMarkerDragEnd"
    />
  </div>
</template>

<script>

import vModel                           from '@/mixins/vModel'
import mapBoxGl                         from 'mapbox-gl'
import GeocoderProviderFactory          from '@/lib/maps/geocoder/GeocoderProviderFactory'
import VMap                             from '@/components/common/maps/VMap.vue'
import MapsCommon, { MapsProviderEnum } from '@/mixins/maps/mapsCommon'
import AppData                          from '@/mixins/appdata'
import { isObject, pathToValue }        from '@/lib/data/Validations/utils/Utils'

export default {
  name      : 'VMapAutocompleteService',
  components: { VMap },
  mixins    : [AppData, MapsCommon, vModel],
  props     : {
    map: {
      type   : Boolean,
      default: false
    },
    addressMarker: {
      type   : Object,
      default: () => {
        return {
          lat: window.appConfig?.LOCATION_DATA?.Lat || -1,
          lng: window.appConfig?.LOCATION_DATA?.Lng || -1
        }
      }
    },
    storeMarker: {
      type   : Object,
      default: () => {
        return {
          lat: window.appConfig?.LOCATION_DATA?.Lat || -1,
          lng: window.appConfig?.LOCATION_DATA?.Lng || -1
        }
      }
    },
    mapZoom: {
      type   : Number,
      default: 17
    },
    mapStyles: {
      type   : Boolean,
      default: true
    },
    mapWidth: {
      type   : String,
      default: '100%'
    },
    mapHeight: {
      type   : String,
      default: '355px'
    },
    mapRef: {
      type   : Object,
      default: undefined
    },
    autocomplete: {
      type   : Boolean,
      default: true
    },
    autofocus: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    readonly: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    lazy: {
      type   : Boolean,
      default: true
    },
    clearIconCb: {
      type   : Function,
      default: () => {
      }
    },
    placeholder: {
      type   : String,
      default: 'Αναζήτηση Διεύθυνσης'
    },
    color: {
      type   : String,
      default: 'primary'
    },
    hint: {
      type   : String,
      default: ''
    },
    errorMessages: {
      type   : [String, Array],
      default: () => []
    },
    rules: {
      type   : Array,
      default: () => []
    },
    country: {
      type   : String,
      default: 'gr'
    },
    language: {
      type   : String,
      default: 'el'
    },
    types: {
      type   : Array,
      default: () => [],
      validator (value) {
        const validValues = ['geocode', 'address', 'city', 'postcode']
        return value?.some(v => validValues.includes(v))
      }
    },
    displayField: {
      type   : String,
      default: 'Name'
    },
    debounceTimeout: {
      type   : Number,
      default: 1000
    },
    debounceMinChars: {
      type   : Number,
      default: 3
    }
  },
  data () {
    return {
      modelStr                   : null,
      mapObj                     : null,
      markerLatLng               : null,
      autocompleteDebounceTimeout: null,
      locationSearchText         : null,
      loading                    : false,
      locationEntries            : []
    }
  },
  computed: {
    locationFoundItems () {
      return this.locationEntries || []
    }
  },
  watch: {
    addressMarker (newVal) {
      this.markerLatLng = newVal
    },
    vModel (newVal) {
      if (newVal.Provider === MapsProviderEnum.Google && newVal.LatLng.lat === -1 && newVal.LatLng.lng === -1) {
        this.getPlaceDetails(newVal)
      } else {
        this.modelStr = pathToValue(this.displayField, newVal) || ''
        this.$emit('place-changed', newVal || null)
        this.gotoMarker(newVal)
      }
    },

    locationSearchText (newVal, oldVal) {
      const newSearchStr = newVal && newVal.trim() || ''
      const oldSearchStr = oldVal && oldVal.trim() || ''

      if ((this.lazy && this.modelStr === newSearchStr) || this.disabled) return

      if (newSearchStr !== oldSearchStr && (newSearchStr.length >= this.debounceMinChars || newSearchStr.length === 0)) {
        clearTimeout(this.autocompleteDebounceTimeout)
        this.autocompleteDebounceTimeout = setTimeout(() => {
          this.locationSearchTextDebounced(newSearchStr)
        }, this.debounceTimeout)
      }
    }
  },
  created () {
    mapBoxGl.accessToken = this.interactiveMapsProvider.Key
    this.markerLatLng = this.addressMarker || this.storeMarker
  },
  mounted () {},
  beforeDestroy () {},
  methods: {
    onBlur (val) {
      this.$emit('blur', val)
    },

    onFocus (val) {
      this.$emit('focus', val)
    },

    onInput (val) {
      if (isObject(val) || val === null) {
        this.$emit('input', val)
      }
    },

    onChange (val) {
      if (isObject(val) || val === null) {
        this.vModel = val
        this.$emit('change', val || null)
      }
    },

    gotoMarker (place) {
      if (!this.map || !this.mapObj?.Instance) return

      this.markerLatLng = place ? place.LatLng : this.storeMarker
    },

    async onMarkerDragEnd (e, latLng, market, provider) {
      this.loading = true

      const result = await GeocoderProviderFactory(this.geocodeProvider.Name, {
        accessToken : this.geocodeProvider.Key,
        autoComplete: this.autocomplete,
        country     : this.country,
        language    : this.language,
        forwardTypes: this.types
      }).geocodeReverse(latLng)

      if (result) {
        result.LatLng = latLng
        result.Center = [latLng.lng, latLng.lat]
      }

      this.vModel = result

      this.loading = false
    },

    async locationSearchTextDebounced (searchStr) {
      if (!searchStr || this.disabled) return

      this.loading = true

      const result = await GeocoderProviderFactory(this.geocodeProvider.Name, {
        accessToken : this.geocodeProvider.Key,
        autoComplete: this.autocomplete,
        country     : this.country,
        language    : this.language,
        forwardTypes: this.types
      }).geocodeSearch(searchStr)

      if (this.autocomplete) {
        this.locationEntries = result
      } else {
        this.vModel = result
      }

      this.loading = false
    },

    async getPlaceDetails (place) {
      if (!place || this.disabled) return

      this.loading = true

      const result = await GeocoderProviderFactory(this.geocodeProvider.Name, {
        input      : this.$refs?.autocompleteServiceComponent?.$refs?.input,
        accessToken: this.geocodeProvider.Key,
        language   : this.language
      }).placeDetails(place.Id)

      this.vModel = result

      this.loading = false
    },

    async getAddressDetails (place) {
      if (!place || this.disabled) return

      this.loading = true

      const result = await GeocoderProviderFactory(this.geocodeProvider.Name, {
        accessToken: this.geocodeProvider.Key,
        language   : this.language
      }).addressDetails(place.Name)

      this.vModel = result

      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
