<template>
  <v-container
    fluid
  >
    <v-row dense>
      <current-plan-warning ref="currentPlan" />

      <v-col
        ref="planBillingCycle"
        class="text-center"
        cols="12"
      >
        <v-btn-toggle
          v-model="billingPeriod"
          class="elevation-0"
          mandatory
        >
          <v-btn
            class="pa-4"
            color="blue"
            text
            large
            outlined
            value="monthly"
          >
            {{ $t('Account.Plan.BillingCycle.Monthly') }}
          </v-btn>

          <v-btn
            class="pa-4"
            color="blue"
            text
            large
            outlined
            value="yearly"
          >
            {{ $t('Account.Plan.BillingCycle.Yearly.Title') }}
            <v-chip
              class="ml-2 hidden-xs-only"
              color="info"
              dark
              label
              small
            >
              {{ $t('Account.Plan.BillingCycle.Yearly.Discount') }}
            </v-chip>
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-if="$vuetify.breakpoint.mdAndDown"
          ref="planTabs"
          v-model="responsivePlanSelected"
          class="elevation-1 mb-2 stickyTabs"
          grow
          show-arrows
        >
          <v-tabs-slider color="red darken-4" />

          <v-tab>
            <img
              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-contactless.png`"
              class="py-2"
              height="32"
              style="margin-top: 8px"
              width="auto"
            >
          </v-tab>
          <v-tab>
            <img
              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-online.png`"
              class="py-2"
              height="32"
              style="margin-top: 8px"
              width="auto"
            >
          </v-tab>
          <v-tab>
            <img
              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-pos.png`"
              class="py-2"
              height="32"
              style="margin-top: 8px"
              width="auto"
            >
          </v-tab>
          <v-tab>
            <img
              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-smart.png`"
              class="py-2"
              height="32"
              style="margin-top: 8px"
              width="auto"
            >
          </v-tab>
          <v-tab>
            <img
              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-enterprise.png`"
              class="py-2"
              height="32"
              style="margin-top: 8px"
              width="auto"
            >
          </v-tab>
        </v-tabs>

        <v-card>
          <v-card-text
            ref="cardBody"
            class="pt-0"
            style="overflow: auto;"
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <div
                    v-if="PricingPlans"
                    :class="[{'pt-0': $vuetify.breakpoint.mdAndDown}]"
                  >
                    <table
                      class="hoverTable"
                      style="width: 100%;"
                    >
                      <thead v-if="PricingPlans.Plans">
                        <tr>
                          <th />
                          <th v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 0">
                            <img
                              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-contactless.png`"
                              class="py-2"
                              height="40"
                              width="auto"
                            >
                            <h2
                              class="text-h6 py-2"
                              v-html="$t(`Account.Plan.Package.${PlansEnum.GetContactless}.Price`)"
                            />
                            <v-btn
                              :color="isCurrentPlan(PlansEnum.GetContactless) ? 'info' : 'red accent-4'"
                              block
                              dark
                              depressed
                              @click="onRequestDialogShow({RequestPlanId: PlansEnum.GetContactless})"
                            >
                              {{ isCurrentPlan(PlansEnum.GetContactless) ? $t('Common.Button.CurrentPlan') : $t('Common.Button.ChangePlan') | sanitizeDiacritics }}
                            </v-btn>
                          </th>
                          <th v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 1">
                            <img
                              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-online.png`"
                              class="py-2"
                              height="40"
                              width="auto"
                            >
                            <h2 class="text-h6 py-2">
                              <span class="text-h6 text-caption" /> {{ billingPeriod === 'yearly' ? formatPlanCurrency(PricingPlans.Plans[1].Yearly) : formatPlanCurrency(PricingPlans.Plans[1].Monthly) }}<span class="text-h6 text-caption"> / {{ $t('Account.Plan.BillingCycle.Month') }}</span>
                            </h2>
                            <v-btn
                              :color="isCurrentPlan(PlansEnum.GetOnline) ? 'info' : 'red accent-4'"
                              block
                              dark
                              depressed
                              @click="onRequestDialogShow({RequestPlanId: PlansEnum.GetOnline})"
                            >
                              {{ isCurrentPlan(PlansEnum.GetOnline) ? $t('Common.Button.RenewPlan') : $t('Common.Button.ChangePlan') | sanitizeDiacritics }}
                            </v-btn>
                          </th>
                          <th v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 2">
                            <img
                              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-pos.png`"
                              class="py-2"
                              height="40"
                              width="auto"
                            >
                            <h2 class="text-h6 py-2">
                              <span class="text-h6 text-caption" /> {{ billingPeriod === 'yearly' ? formatPlanCurrency(PricingPlans.Plans[2].Yearly) : formatPlanCurrency(PricingPlans.Plans[2].Monthly) }}<span class="text-h6 text-caption"> / {{ $t('Account.Plan.BillingCycle.Month') }}</span>
                            </h2>
                            <v-btn
                              :color="isCurrentPlan(PlansEnum.GetPos) ? 'info' : 'red accent-4'"
                              block
                              dark
                              depressed
                              @click="onRequestDialogShow({RequestPlanId: PlansEnum.GetPos})"
                            >
                              {{ isCurrentPlan(PlansEnum.GetPos) ? $t('Common.Button.RenewPlan') : $t('Common.Button.ChangePlan') | sanitizeDiacritics }}
                            </v-btn>
                          </th>
                          <th v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 3">
                            <img
                              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-smart.png`"
                              class="py-2"
                              height="40"
                              width="auto"
                            >
                            <h2 class="text-h6 py-2">
                              <span class="text-h6 text-caption" /> {{ billingPeriod === 'yearly' ? formatPlanCurrency(PricingPlans.Plans[3].Yearly) : formatPlanCurrency(PricingPlans.Plans[3].Monthly) }}<span class="text-h6 text-caption"> / {{ $t('Account.Plan.BillingCycle.Month') }}</span>
                            </h2>
                            <v-btn
                              :color="isCurrentPlan(PlansEnum.GetSmart) ? 'info' : 'red accent-4'"
                              block
                              dark
                              depressed
                              @click="onRequestDialogShow({RequestPlanId: PlansEnum.GetSmart})"
                            >
                              {{ isCurrentPlan(PlansEnum.GetSmart) ? $t('Common.Button.RenewPlan') : $t('Common.Button.ChangePlan') | sanitizeDiacritics }}
                            </v-btn>
                          </th>
                          <th v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 4">
                            <img
                              :src="`${appConfig.LOCATION_DATA.CdnUrl}assets/package/logo-get-enterprise.png`"
                              class="py-2"
                              height="40"
                              width="auto"
                            >
                            <h2
                              class="text-h6 py-2"
                              v-html="'&nbsp'"
                            />
                            <v-btn
                              :color="isCurrentPlan(PlansEnum.GetEnterprise) ? 'info' : 'red accent-4'"
                              block
                              dark
                              depressed
                              @click="onRequestDialogShow({RequestPlanId: PlansEnum.GetEnterprise})"
                            >
                              {{ isCurrentPlan(PlansEnum.GetEnterprise) ? $t('Common.Button.RenewPlan') : $t('Common.Button.ChangePlan') | sanitizeDiacritics }}
                            </v-btn>
                          </th>
                        </tr>
                      </thead>

                      <tbody v-if="PricingPlans.Features">
                        <template v-for="(category, categoryIndex) in PricingPlans.Features">
                          <tr
                            :key="`${categoryIndex}`"
                            class="no-hover section-title"
                          >
                            <td
                              :class="[{'no-wrap': false}]"
                              class="section-title"
                            >
                              {{ category.Lang[$i18n.locale].Title }}
                              <template v-if="category.Lang[$i18n.locale].Help">
                                <v-tooltip
                                  max-width="250"
                                  top
                                >
                                  <template #activator="{on}">
                                    <span
                                      class="float-right"
                                      style="cursor: pointer;"
                                      v-on="on"
                                    >
                                      <v-icon color="grey darken-1">help_outline</v-icon>
                                    </span>
                                  </template>
                                  <span>{{ category.Lang[$i18n.locale].Help }}</span>
                                </v-tooltip>
                              </template>
                            </td>
                            <td v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 0" />
                            <td v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 1" />
                            <td v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 2" />
                            <td v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 3" />
                            <td v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === 4" />
                          </tr>

                          <tr
                            v-for="(item, itemIdx) in category.Items"
                            :key="`${categoryIndex}-${itemIdx}`"
                          >
                            <td
                              :class="[{'no-wrap': $vuetify.breakpoint.smAndUp}]"
                              style="width: 280px"
                            >
                              <span>{{ item.Lang[$i18n.locale].Title }}</span>
                              <template v-if="item.Lang[$i18n.locale].Help">
                                <v-tooltip
                                  max-width="250"
                                  top
                                >
                                  <template #activator="{on}">
                                    <span
                                      class="float-right"
                                      style="cursor: pointer;"
                                      v-on="on"
                                    >
                                      <v-icon color="grey darken-1">help_outline</v-icon>
                                    </span>
                                  </template>
                                  <span>{{ item.Lang[$i18n.locale].Help }}</span>
                                </v-tooltip>
                              </template>
                            </td>

                            <template v-for="(plan, planIdx) in item.Plans">
                              <td
                                v-if="$vuetify.breakpoint.lgAndUp || responsivePlanSelected === planIdx"
                                :key="`${categoryIndex}-${itemIdx}-${planIdx}`"
                              >
                                <template v-if="plan.Active">
                                  <template v-if="plan.HasPrice">
                                    +{{ billingPeriod === 'yearly' ? formatPlanCurrency(plan.Yearly) : formatPlanCurrency(plan.Monthly) }}

                                    <span
                                      v-if="plan.Lang[$i18n.locale].Period"
                                      class="text-h6 text-caption"
                                    >
                                      / {{ plan.Lang[$i18n.locale].Period }}
                                    </span>

                                    <span
                                      v-if="plan.Fixed"
                                      class="d-block grey--text text-caption"
                                    >
                                      {{ $t('Account.Plan.BillingCycle.Fixed') }} {{ plan.Fixed | currency }}
                                    </span>

                                    <span
                                      v-else-if="plan.Lang[$i18n.locale].Help"
                                      class="d-block grey--text text-caption"
                                    >
                                      {{ plan.Lang[$i18n.locale].Help }}
                                    </span>
                                  </template>

                                  <template v-else>
                                    <v-icon color="#2ebc4f">
                                      done
                                    </v-icon>
                                    <span
                                      v-if="plan.Lang[$i18n.locale].Help"
                                      class="d-block grey--text text-caption"
                                    >
                                      {{ plan.Lang[$i18n.locale].Help }}
                                    </span>
                                  </template>
                                </template>
                                <template v-else>
                                  <v-icon color="grey lighten-2">
                                    close
                                  </v-icon>
                                </template>
                              </td>
                            </template>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ticket-request-dialog
      :message="ticketMessage"
      :ticket-model="ticketModel"
      :visible.sync="requestDialogVisible"
    />
  </v-container>
</template>

<script>
import AppData               from '@/mixins/appdata'
import Auth                  from '@/mixins/auth'
import CurrentPlanWarning    from '@/components/account/CurrentPlanWarning'
import TicketRequestDialog   from '@/components/support-center/TicketRequestDialog.vue'
import Resizable             from '@/mixins/resizable'
import PlansEnum             from '@/mixins/plans/plansEnum'
import SupportTicketAddModel from '@/api/Models/support-center/SupportTicketAddModel'
import formatCurrency        from '@/lib/currency/currency'

export default {
  name      : 'AccountPlans',
  components: {
    CurrentPlanWarning,
    TicketRequestDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable, PlansEnum],
  props     : {},
  data () {
    return {
      viewData              : null,
      billingPeriod         : 'yearly',
      responsivePlanSelected: 0,
      ticketMessage         : '',
      requestDialogVisible  : false,
      ticketModel           : new SupportTicketAddModel()
    }
  },
  computed: {
    PricingPlans () {
      return this.viewData || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.$bus.$on(window.SocketCommand.Account.Plans.List, this.setData)
    this.getData()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Account.Plans.List, this.setData)
  },
  destroyed () {},
  methods: {

    formatPlanCurrency (value) {
      const locale = 'el-GR'
      const currency = 'EUR'
      return formatCurrency(locale, currency, String(value).replaceAll(',', ''))
    },

    isCurrentPlan (planId) {
      return this.appConfig.LOCATION_DATA.PackageId === planId
    },

    onRequestDialogShow (data) {
      const RequestPlanId = data.RequestPlanId
      const CurrentPlanId = this.appConfig.LOCATION_DATA.PackageId
      const IsCurrentPlan = this.isCurrentPlan(RequestPlanId)
      // eslint-disable-next-line no-unused-vars
      const IsCatalogOnly = CurrentPlanId === this.PlansEnum.GetContactless && this.appConfig.LOCATION_DATA.CatalogOnly
      const ChangeToContactless = RequestPlanId === this.PlansEnum.GetContactless && !this.appConfig.LOCATION_DATA.CatalogOnly
      const TrialExpiredAt = CurrentPlanId === this.PlansEnum.GetSmart && this.appConfig.LOCATION_DATA.TrialExpiredAt
      const SubscriptionExpiredAt = this.appConfig.LOCATION_DATA.SubscriptionExpiredAt
      const BillingPeriod = this.billingPeriod

      if (!RequestPlanId) return

      let Subject = ''
      let Message = ''
      const Department = 1
      const Priority = 2
      const Service = this.$t(`Account.Plan.Package.${ RequestPlanId }.ServiceId`)

      if (IsCurrentPlan) {
        if (SubscriptionExpiredAt) {
          Subject = this.$t('Account.Ticket.RenewSubscriptionRequest.Subject')
          Message = this.$t('Account.Ticket.RenewSubscriptionRequest.Message', {
            SubscriptionExpiredAt: this.appConfig.LOCATION_DATA.SubscriptionExpiredAt,
            RequestPlanName      : this.$t(`Account.Plan.Package.${ RequestPlanId }.Name`),
            BillingPeriod        : BillingPeriod === 'yearly' ? this.$t('Account.Ticket.BillingPeriod.Yearly') : this.$t('Account.Ticket.BillingPeriod.Monthly')
          })
        } else if (TrialExpiredAt) {
          Subject = this.$t('Account.Ticket.TrialUpgradeRequest.Subject')
          Message = this.$t('Account.Ticket.TrialUpgradeRequest.Subject', {
            TrialExpiredAt : this.appConfig.LOCATION_DATA.TrialExpiredAt,
            RequestPlanName: this.$t(`Account.Plan.Package.${ RequestPlanId }.Name`),
            BillingPeriod  : BillingPeriod === 'yearly' ? this.$t('Account.Ticket.BillingPeriod.Yearly') : this.$t('Account.Ticket.BillingPeriod.Monthly')
          })
        } else {
          return
        }
      } else {
        Subject = this.$t('Account.Ticket.ChangeSubscriptionRequest.Subject')
        if (ChangeToContactless) {
          Message = this.$t('Account.Ticket.ChangeSubscriptionRequest.MessageContactless', {
            SubscriptionExpiredAt: this.appConfig.LOCATION_DATA.SubscriptionExpiredAt || this.appConfig.LOCATION_DATA.TrialExpiredAt,
            CurrentPlanName      : this.$t(`Account.Plan.Package.${ CurrentPlanId }.Name`),
            RequestPlanName      : this.$t(`Account.Plan.Package.${ RequestPlanId }.Name`)
          })
        } else {
          Message = this.$t('Account.Ticket.ChangeSubscriptionRequest.Message', {
            SubscriptionExpiredAt: this.appConfig.LOCATION_DATA.SubscriptionExpiredAt || this.appConfig.LOCATION_DATA.TrialExpiredAt,
            CurrentPlanName      : this.$t(`Account.Plan.Package.${ CurrentPlanId }.Name`),
            RequestPlanName      : this.$t(`Account.Plan.Package.${ RequestPlanId }.Name`),
            BillingPeriod        : BillingPeriod === 'yearly' ? this.$t('Account.Ticket.BillingPeriod.Yearly') : this.$t('Account.Ticket.BillingPeriod.Monthly')
          })
        }
      }

      this.ticketMessage = Message
      this.ticketModel = new SupportTicketAddModel({
        subject   : Subject,
        department: Department,
        priority  : Priority,
        service   : Service
      })

      this.requestDialogVisible = true
    },

    getData () {
      this.loading = true
      window.callAS(window.SocketCommand.Account.Plans.List, {})
    },

    setData (data) {
      if (data && data.status === 'success') {
        this.viewData = data || []
      }
      this.loading = false
    },

    onResize () {
      const cardBody = this.$refs.cardBody
      const currentPlan = this.$refs.currentPlan
      const planTabs = this.$refs.planTabs
      const planBillingCycle = this.$refs.planBillingCycle
      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const tabsHeight = document.getElementsByClassName('v-tabs')[0] ? document.getElementsByClassName('v-tabs')[0].offsetHeight + 16 : 48
      const currentPlanHeight = currentPlan ? currentPlan.$el.offsetHeight : 96
      const planBillingCycleHeight = planBillingCycle ? planBillingCycle.offsetHeight : 60
      const planTabsHeight = planTabs ? planTabs.$el.offsetHeight : 0

      if (cardBody) cardBody.style.height = (innerHeight - headerHeight - footerHeight - tabsHeight - currentPlanHeight - planBillingCycleHeight - planTabsHeight - 10) + 'px'
    }
  }
}
</script>

<style scoped>
.hoverTable {
  border-collapse       : collapse;
  border-radius         : 5px;
  -moz-border-radius    : 5px;
  -webkit-border-radius : 5px;
}

/deep/ .theme--light .hoverTable td:not(:first-child):not(:last-child), /deep/ .theme--light .hoverTable th:not(:first-child):not(:last-child) {
  border-right : #d2d2d2 1px solid;
}

/deep/ .theme--dark .hoverTable td:not(:first-child):not(:last-child), /deep/ .theme--dark .hoverTable th:not(:first-child):not(:last-child) {
  border-right : #1e1e1e 1px solid;
}

/deep/ .theme--light .hoverTable th:first-child {
  background : #fafafa;
}

/deep/ .theme--dark .hoverTable th:first-child {
  background : #161616
}

/deep/ .theme--light .hoverTable td:not(:first-child) {
  background : rgba(255, 255, 255, 0.85);
}

/deep/ .theme--dark .hoverTable td:not(:first-child) {
  background : rgba(0, 0, 0, 0.25);
}

/deep/ .theme--light .hoverTable th:not(:first-child) {
  background : #FFFFFF;
}

/deep/ .theme--dark .hoverTable th:not(:first-child) {
  background : #161616
}

/deep/ .hoverTable td:first-child, /deep/ .hoverTable th:first-child {
  color       : #586069;
  font-size   : 14px;
  font-weight : 500;
  text-align  : left;
}

/deep/ .hoverTable .section-title td:first-child, /deep/ .hoverTable .section-title th:first-child {
  color       : #434a4f;
  font-size   : 18px;
  font-weight : 600;
  text-align  : left;
  padding     : 12px 16px 0 0;
}

/deep/ .hoverTable td {
  width   : 16%;
  height  : 45px;
  padding : 8px 16px;
}

/deep/ .hoverTable th {
  width          : 16%;
  height         : 150px;
  vertical-align : bottom;
  padding        : 4px 12px;
  white-space    : nowrap;

}

/deep/ .theme--light .hoverTable tbody tr:hover:not(.no-hover) {
  background-color : #b4b4b4;
}

/deep/ .theme--dark .hoverTable tbody tr:hover:not(.no-hover) {
  background-color : #121212;
}

/deep/ .hoverTable tr td, .hoverTable tr th {
  -moz-border-radius-topleft        : 5px;
  -moz-border-radius-bottomleft     : 5px;
  -webkit-border-top-left-radius    : 5px;
  -webkit-border-bottom-left-radius : 5px;
}

thead tr th {
  position : -webkit-sticky;
  position : sticky;
  top      : 0px;
  z-index  : 1;
}

.stickyTabs {
  position : -webkit-sticky;
  position : sticky;
  top      : 0px;
}
</style>
