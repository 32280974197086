/**
 * Enum for staff groups.
 * @readonly
 * @enum {Number} StaffGroupEnum
 */

const StaffGroupEnum = {
  ADMIN     : 11,
  GFP_EDITOR: 12,
  CRM_EDITOR: 13,
  OWNER     : 100,
  CASHIER   : 101,
  WAITER    : 102,
  USER      : 103
}

export default StaffGroupEnum
