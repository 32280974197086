<template>
  <v-row justify="center">
    <v-dialog
      v-if="viewData"
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class=""
          extension-height="44"
          height="80"
          :max-height="viewData.length ? 124 : 80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ $t('Order.SelectOrderDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Order.SelectOrderDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Order.SelectOrderDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template
            v-if="viewData.length"
            #extension
          >
            <v-btn-toggle
              v-if="appConfig"
              v-model="orderType"
              dense
              class="elevation-0 ma-2 flex-grow-1"
              mandatory
            >
              <v-btn
                :value="0"
                class="elevation-0 grey darken-3 white--text flex-grow-1"
                :style="orderType === 0 ? 'opacity: 1' : 'opacity: 0.35'"
              >
                <span>
                  {{ $t('Order.SelectOrderDialog.Result.Button.All.Title') }}
                </span>

                <v-icon
                  color="white"
                  size="22"
                  right
                >
                  {{ $t('Order.SelectOrderDialog.Result.Button.All.Icon') }}
                </v-icon>
              </v-btn>

              <template v-for="item in orderTypes">
                <v-btn
                  :key="item.Id"
                  :class="[item.Icon === 'directions_bike' ? 'purple white--text' : 'blue darken-3 white--text']"
                  :style="orderType === item.Id ? 'opacity: 1' : 'opacity: 0.35'"
                  :value="item.Id"
                  class="elevation-0 flex-grow-1"
                >
                  <span>
                    {{ item.Title }}
                  </span>

                  <v-icon
                    v-if="item.Icon"
                    color="white"
                    size="22"
                    right
                  >
                    {{ item.Icon }}
                  </v-icon>
                </v-btn>
              </template>
            </v-btn-toggle>
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-2">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <div
                  v-if="filteredOrders.length <= 0 || viewData.length <= 0"
                  class="fill-height"
                  style="position: relative; height: 250px;"
                >
                  <div
                    class="text-center grey--text pa-6"
                    style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                  >
                    <v-icon
                      :color="$t('Order.SelectOrderDialog.Result.NoOrder.Color')"
                      class="mb-4"
                      size="128"
                    >
                      {{ $t('Order.SelectOrderDialog.Result.NoOrder.Icon') }}
                    </v-icon>
                    <div class="text-h6 mb-2">
                      {{ $t('Order.SelectOrderDialog.Result.NoOrder.Title') }}
                    </div>
                    <div class="text-subtitle-1">
                      {{ $t('Order.SelectOrderDialog.Result.NoOrder.Subtitle') }}
                    </div>
                  </div>
                </div>

                <orders-v-list
                  :orders="filteredOrders"
                  @list-tile-click="setOrder"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-2">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData     from '../../mixins/appdata'
import OrderType   from '../../mixins/orders/orderType'
import OrdersVList from './common/OrdersVList'

export default {
  components: { OrdersVList },
  mixins    : [AppData, OrderType],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    selectedOrder: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      viewData : null,
      orderType: 0
    }
  },
  computed: {
    filteredOrders () {
      const viewData = this.viewData || []
      return this.orderType === 0 ? viewData : viewData.filter(o => o.OrderTypeId === this.orderType)
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    currentOrder: {
      get () {
        return this.selectedOrder
      },
      set (val) {
        this.$emit('update:selectedOrder', val)
      }
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.orderType = 0
        window.callAS(window.SocketCommand.Order.Delivery, {})
      }
    }
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Order.Delivery, this.onSetData)
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Order.Delivery, this.onSetData)
  },
  methods: {
    onSetData (data) {
      this.viewData = data
    },
    setOrder (order) {
      this.currentOrder = order
      this.viewData = null
      this.isVisible = false
    },
    onCancelButtonClick () {
      this.viewData = null
      this.currentOrder = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__extension {
  padding : 0;
}
</style>
