<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="$t('Customer.CustomerAddEditDialog.Toolbar.Color')">
            <v-icon dark>
              {{ $t('Customer.CustomerAddEditDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Customer.CustomerAddEditDialog.Toolbar.Add.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerAddEditDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-stepper
            v-model="stepperStatus"
            class="elevation-0"
          >
            <v-stepper-items>
              <v-stepper-content
                class="ma-0 pa-0"
                step="1"
              >
                <v-form
                  ref="customerForm"
                  v-model="customerFormValid"
                  @submit.prevent
                >
                  <v-container fluid>
                    <v-row dense>
                      <v-col cols="12">
                        <v-subheader class="white--text primary text-subtitle-1 ma-0">
                          <v-icon
                            class="pr-2"
                            dark
                          >
                            account_circle
                          </v-icon>
                          {{ $t('Customer.CustomerAddEditDialog.Field.Info.Title') }}
                        </v-subheader>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editCustomer.Info.Firstname"
                          :counter="32"
                          :maxlength="32"
                          :error-messages="errorMessages.customerForm.first_name"
                          :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Hint')"
                          :label="$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Icon')"
                          :rules="validationRules.customerForm.Firstname"
                          required
                          tabindex="1"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editCustomer.Info.Telephone"
                          v-mask="'################'"
                          :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Hint') + ' 6937XXXXXX'"
                          :label="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Label') + ' 1'"
                          :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Icon')"
                          :rules="validationRules.customerForm.Telephone"
                          required
                          tabindex="4"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editCustomer.Info.Lastname"
                          :counter="32"
                          :maxlength="32"
                          :error-messages="errorMessages.customerForm.last_name"
                          :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Hint')"
                          :label="$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Icon')"
                          :rules="validationRules.customerForm.Lastname"
                          class=""
                          required
                          tabindex="2"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editCustomer.Info.Telephone2"
                          v-mask="'################'"
                          :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Hint') + ' 2106105200'"
                          :label="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Label') + ' 2'"
                          :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Icon')"
                          :rules="validationRules.customerForm.Telephone2"
                          tabindex="5"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editCustomer.Info.Email"
                          :counter="96"
                          :error-messages="errorMessages.customerForm.email"
                          :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Hint')"
                          :label="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Icon')"
                          :rules="validationRules.customerForm.Email"
                          class=""
                          tabindex="3"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editCustomer.Info.Telephone3"
                          v-mask="'################'"
                          :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Hint') + ' 2106105200'"
                          :label="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Label') + ' 3'"
                          :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Icon')"
                          :rules="validationRules.customerForm.Telephone3"
                          tabindex="6"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-select
                          v-model="editCustomer.Info.Groups"
                          :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Group.Hint')"
                          :items="editCustomer.CustomerGroups"
                          :label="$t('Customer.CustomerAddEditDialog.Field.Info.Group.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Group.Icon')"
                          :rules="validationRules.customerForm.Groups"
                          chips
                          class=""
                          item-text="Name"
                          item-value="Id"
                          multiple
                          persistent-hint
                          required
                        >
                          <template
                            slot="selection"
                            slot-scope="data"
                          >
                            <v-chip
                              :key="data.item.Id"
                              class="chip--select-multi"
                              color="indigo"
                              label
                              text-color="white"
                              @input="data.parent.selectItem(data.item)"
                            >
                              {{ data.item.Name }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="editCustomer.Info.Notes"
                          :hint="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Hint')"
                          :label="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Label')"
                          :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Icon')"
                          hide-details="auto"
                          rows="3"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-subheader class="white--text primary text-subtitle-1 ma-0">
                          <v-icon
                            class="pr-2"
                            dark
                          >
                            notifications
                          </v-icon>
                          {{ $t('Customer.CustomerAddEditDialog.Field.Notifications.Title') }}
                        </v-subheader>
                      </v-col>

                      <v-col cols="12">
                        <v-subheader
                          class="pl-1"
                          style="height: 24px;"
                        >
                          {{ $t('Customer.CustomerAddEditDialog.Field.Notifications.Subtitle') }}
                        </v-subheader>

                        <div class="d-inline-flex">
                          <v-checkbox
                            v-model="editCustomer.Info.Newsletter"
                            :label="$t('Customer.CustomerAddEditDialog.Field.Notifications.Email.Label')"
                            class="mr-3"
                            color="success"
                            hide-details
                          />

                          <v-checkbox
                            v-model="editCustomer.Info.SMS"
                            :label="$t('Customer.CustomerAddEditDialog.Field.Notifications.Sms.Label')"
                            class="mr-3"
                            color="success"
                            hide-details
                          />

                          <v-checkbox
                            v-model="editCustomer.Info.Push"
                            :label="$t('Customer.CustomerAddEditDialog.Field.Notifications.Push.Label')"
                            class="mr-3"
                            color="success"
                            hide-details
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-subheader class="white--text primary text-subtitle-1">
                          <v-icon
                            class="pr-2"
                            dark
                          >
                            toggle_on
                          </v-icon>
                          {{ $t('Common.Misc.Status') }}
                        </v-subheader>
                      </v-col>

                      <v-col cols="12">
                        <span class="text-caption grey--text">
                          <v-switch
                            v-model="editCustomer.Info.Status"
                            :label="editCustomer.Info.Status ? $t('Common.Button.Toggle.ActiveM') : $t('Common.Button.Toggle.InactiveM')"
                            class="ma-0"
                            hide-details
                            inset
                          />
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>

              <v-stepper-content
                class="ma-0 pa-0"
                step="2"
              >
                <v-form
                  ref="addressForm"
                  v-model="addressFormValid"
                  @submit.prevent
                >
                  <v-container
                    class="mt-0 pt-0"
                    fluid
                  >
                    <v-alert
                      :value="addressIsOutsideStoreLimits"
                      class="pa-1"
                      color="error"
                      icon="warning"
                      outlined
                    >
                      <template v-if="isCustomAddress(address)">
                        <strong>{{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:</strong> {{ $t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Custom.Subtitle') }}
                      </template>
                      <template v-else>
                        <strong>{{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:</strong> {{ $t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Range.Subtitle') }}
                      </template>
                    </v-alert>

                    <v-row dense>
                      <v-col
                        sm="6"
                        cols="12"
                      >
                        <v-container
                          class=""
                          fluid
                        >
                          <v-row dense>
                            <v-col cols="12">
                              <v-map-autocomplete-service
                                v-model="selectedLocation"
                                :rules="useCustomAddress ? [] : validationRules.addressForm.AutoComplete"
                                :maps-provider="interactiveMapsProvider.Name"
                                :maps-access-token="interactiveMapsProvider.Key"
                                :geocode-provider="geocodeProvider.Name"
                                :geocode-access-token="geocodeProvider.Key"
                                :map-center="mapCenter"
                                :store-marker="markerStore"
                                :address-marker="markerAddress"
                                :country="locationDeliveryCountriesStr"
                                :disabled="useCustomAddress || !isVisible"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Address.Hint')"
                                :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Address.PlaceHolder')"
                                :types="['geocode']"
                                color="gfp-adv-pages-account-address-dialog-body-controls"
                                display-field="Name"
                                :map-height="acceptCustomAddresses ? '320px' : '380px'"
                                :map="isVisible && mapVisible && (!isCustomAddress(address) && !useCustomAddress)"
                                @place-changed="(placeObj) => onPlaceChanged(placeObj)"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-img
                                v-if="isCustomAddress(address) || useCustomAddress"
                                :height="acceptCustomAddresses ? 320 : 350"
                                position="center center"
                                src="/assets/map/map-placeholder.png"
                                style="border: 1px solid #e2e2e2;"
                                width="100%"
                              />
                            </v-col>

                            <v-col
                              v-if="acceptCustomAddresses"
                              cols="12"
                            >
                              <v-switch
                                v-model="useCustomAddress"
                                inset
                                :label="$t('Customer.CustomerAddEditAddressDialog.UseCustom')"
                                class="pt-0 mt-0"
                                color="success"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>

                      <v-col
                        sm="6"
                        cols="12"
                      >
                        <v-container
                          class=""
                          fluid
                        >
                          <v-row
                            v-if="!useCustomAddress && !acceptLatLngAddresses"
                            dense
                          >
                            <v-col cols="12">
                              <v-text-field
                                v-model="address.Nickname"
                                :counter="35"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Icon')"
                              />
                            </v-col>

                            <v-col
                              sm="9"
                              cols="12"
                            >
                              <v-map-autocomplete-service
                                v-model="selectedCity"
                                :geocode-provider="geocodeProvider.Name"
                                :geocode-access-token="geocodeProvider.Key"
                                :country="locationDeliveryCountriesStr"
                                :disabled="!isVisible"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Region.Hint')"
                                :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Region.PlaceHolder')"
                                :rules="validationRules.addressForm.City"
                                :types="['city']"
                                display-field="Properties.City"
                                color="gfp-adv-pages-account-address-dialog-body-controls"
                                @place-changed="(placeObj) => onPlaceChanged(placeObj)"
                              />
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.Postcode"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Label')"
                                :rules="validationRules.addressForm.Postcode"
                                required
                              />
                            </v-col>

                            <v-col
                              sm="9"
                              cols="12"
                            >
                              <v-map-autocomplete-service
                                v-model="selectedStreet"
                                :geocode-provider="geocodeProvider.Name"
                                :geocode-access-token="geocodeProvider.Key"
                                :country="locationDeliveryCountriesStr"
                                :disabled="!isVisible"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Street.Hint')"
                                :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Street.PlaceHolder')"
                                :rules="validationRules.addressForm.Street"
                                :types="['address']"
                                display-field="Properties.Street"
                                color="gfp-adv-pages-account-address-dialog-body-controls"
                                @place-changed="(placeObj) => onPlaceChanged(placeObj)"
                              />
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.Number"
                                :counter="8"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Number.Label')"
                                :rules="validationRules.addressForm.Number"
                                hint=""
                                required
                                @blur="getAddress"
                              />
                            </v-col>

                            <v-col
                              sm="5"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.Floor"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Icon')"
                                class="pr-2"
                              />
                            </v-col>
                            <v-col
                              sm="7"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.DoorBell"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Icon')"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-text-field
                                v-model="address.Comment"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Icon')"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-checkbox
                                v-model="address.IsDefault"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.DefaultAddress.Label')"
                                hide-details
                              />
                            </v-col>
                          </v-row>

                          <v-row
                            v-else
                            dense
                          >
                            <v-col cols="12">
                              <v-text-field
                                v-model="address.Nickname"
                                :counter="35"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Icon')"
                              />
                            </v-col>
                            <v-col cols="12">
                              <div class="input-group input-group--prepend-icon input-group--text-field">
                                <div class="input-group__input">
                                  <v-text-field
                                    ref="formatted"
                                    v-model="address.City"
                                    :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Region.Hint')"
                                    :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Region.PlaceHolder')"
                                    :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Region.Icon')"
                                    :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.City"
                                  />
                                </div>
                                <div
                                  class="input-group__details"
                                  style="min-height:0;"
                                />
                              </div>
                            </v-col>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <div class="input-group input-group--prepend-icon input-group--text-field">
                                <div class="input-group__input">
                                  <v-text-field
                                    ref="formatted"
                                    v-model="address.Street"
                                    :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Street.Hint')"
                                    :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Street.PlaceHolder')"
                                    :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Street.Icon')"
                                    :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Street"
                                    required
                                  />
                                </div>
                                <div
                                  class="input-group__details"
                                  style="min-height:0;"
                                />
                              </div>
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.Number"
                                :counter="8"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Number.Label')"
                                :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Number"
                                hint=""
                                required
                              />
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.Postcode"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Label')"
                                :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Postcode"
                                required
                              />
                            </v-col>

                            <v-col
                              sm="5"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.Floor"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Icon')"
                                class="pr-6"
                              />
                            </v-col>
                            <v-col
                              sm="7"
                              cols="12"
                            >
                              <v-text-field
                                v-model="address.DoorBell"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Icon')"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-text-field
                                v-model="address.Comment"
                                :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Hint')"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Label')"
                                :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Icon')"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-checkbox
                                v-model="address.IsDefault"
                                :label="$t('Customer.CustomerAddEditAddressDialog.Field.DefaultAddress.Label')"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-divider />

        <v-card-actions
          :class="[{'d-block text-center': $vuetify.breakpoint.xsOnly}]"
          class="pa-4"
        >
          <v-spacer class="hidden-xs-only" />

          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :small="$vuetify.breakpoint.xsOnly"
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            v-if="stepperStatus === 1"
            :block="$vuetify.breakpoint.xsOnly"
            :small="$vuetify.breakpoint.xsOnly"
            class="green--text elevation-0"
            outlined
            @click="onSaveButtonClick(true)"
          >
            {{ $t('Customer.CustomerAddEditDialog.Button.SaveWithoutAddress').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            v-if="stepperStatus === 1"
            :block="$vuetify.breakpoint.xsOnly"
            :small="$vuetify.breakpoint.xsOnly"
            class="green white--text elevation-0"
            @click="onNextButtonClick"
          >
            {{ $t('Customer.CustomerAddEditDialog.Button.AddAddress').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            v-if="stepperStatus === 2"
            class="green--text elevation-0"
            outlined
            @click="onPrevButtonClick"
          >
            {{ $t('Customer.CustomerAddEditDialog.Button.CustomerDetails').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            v-if="stepperStatus === 2"
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import AppData                 from '../../mixins/appdata'
import VMapAutocompleteService from '@/components/common/maps/VMapAutocompleteService.vue'
import MapsCommon              from '@/mixins/maps/mapsCommon'
import AddressCommon           from '@/mixins/maps/addressCommon'

export default {
  components: {
    VMapAutocompleteService
  },
  mixins: [AppData, MapsCommon, AddressCommon],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    presetData: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading          : false,
      customerFormValid: false,
      addressFormValid : false,
      useCustomAddress : false,
      mapVisible       : false,
      stepperStatus    : 1,
      newCustomer      : null,
      tmpCustomer      : {
        Addresses     : [],
        Companies     : [],
        CustomerGroups: [],
        Info          : {
          Id        : null,
          GroupId   : 11,
          Groups    : [11],
          Firstname : '',
          Lastname  : '',
          Telephone : '',
          Telephone2: '',
          Telephone3: '',
          Email     : '',
          Notes     : '',
          Newsletter: true,
          SMS       : true,
          Push      : true,
          Status    : true
        }
      },
      address      : this.defaultEmptyAddress(),
      errorMessages: {
        customerForm: {
          first_name: [],
          last_name : [],
          email     : []
        }
      },
      validationRules: {
        customerForm: {
          Firstname: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Error.Between')
          ],
          Lastname: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Error.Between')
          ],
          Telephone: [
            (v) => v && v.length >= 8 && v.length <= 16 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Error.Between')
          ],
          Telephone2: [
            (v) => (v.length === 0 || (v.length >= 8 && v.length <= 16)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Error.Between')
          ],
          Telephone3: [
            (v) => (v.length === 0 || (v.length >= 8 && v.length <= 16)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Error.Between')
          ],
          Email: [
            (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Between'),
            (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Valid')
          ],
          Groups: [
            (v) => v.length > 0 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Group.Error.Min')
          ]
        },

        addressForm: {
          AutoComplete: this.useCustomAddress ? [] : [
            (v) => !!v || this.$t('Customer.CustomerAddEditAddressDialog.Field.AutoComplete.Errors.Mandatory'),
            (v) => !!v && !!this.address.Lat && !!this.address.Lng || this.$t('Customer.CustomerAddEditAddressDialog.Field.AutoComplete.Errors.Mandatory'),
            (v) => v && !this.addressIsOutsideStoreLimits || this.$t('Customer.CustomerAddEditAddressDialog.Field.AutoComplete.Errors.Valid')
          ],
          City: [
            (v) => v && v.length >= 2 && v.length <= 120 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Region.Error.Between')
          ],
          Postcode: [
            (v) => v && v.length >= 2 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Error.Valid')
          ],
          Street: [
            (v) => v && v.length >= 2 && v.length <= 120 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Street.Error.Between')
          ],
          Number: [
            (v) => v && v.length >= 1 && v.length <= 10 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Number.Error.Between')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editCustomer: {
      get () {
        const customer = JSON.parse(JSON.stringify(this.tmpCustomer))
        customer.Info = { ...customer.Info, ...this.presetData }
        this.newCustomer = customer
        return this.newCustomer
      }
    }
  },
  watch: {
    isVisible (newVal) {
      this.autoCompleteModel = ''

      if (newVal) {
        this.$bus.$on(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        window.callAS(window.SocketCommand.CustomerGroup.All, {
          page_limit: -1,
          config    : false
        })

        this.$nextTick(() => {
          if (this.$route && this.$route.params.hasOwnProperty('callerID')) {
            if (!isNaN(this.$route.params.callerID)) {
              this.editCustomer.Info.Telephone = this.$route.params.callerID || ''
            }
          }
          this.setAddress()
        })
      } else {
        this.$bus.$off(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        this.mapVisible = false
      }
    },
    useCustomAddress (newVal, oldVal) {
      if (newVal) {
        this.address.Lng = this.address.Lat = this.address.PlaceId = this.address.Formatted = ''
      }
    }
  },
  created () {
    // console.log('1. created');
    this.$gmapApiPromiseLazy().then(() => {
      this.initMapCenter()
    })
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.CustomerGroup.All, this.onCustomerGroupsUpdateDataItems)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.CustomerGroup.All, this.onCustomerGroupsUpdateDataItems)
  },
  methods: {
    async setAddress () {
      this.clearSelectedAddress()
      this.initMapCenter()
      this.useCustomAddress = this.isCustomAddress(this.address)
    },

    async checkAddress (address) {
      if (!address) return false

      const result = await this.isPlaceInsideDeliveryAreas(this.addressToPlaceObject(address))
      this.addressIsOutsideStoreLimits = !result

      return result
    },

    async onPlaceChanged (place) {
      this.setAddressFromPlace(place, true)
      await this.checkAddress(this.placeToAddressObject(place))
    },

    onNextButtonClick () {
      this.stepperStatus = 2
      this.mapVisible = true
    },
    onPrevButtonClick () {
      this.stepperStatus = 1
    },
    onSaveButtonClick (saveWithoutAddress) {
      this.$refs.customerForm.validate()

      if (saveWithoutAddress === true) {
        if (this.customerFormValid) {
          this.saveCustomer(this.newCustomer)
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.FieldError'),
            type: 'error',
            icon: 'warning'
          })
        }
      } else {
        this.$refs.addressForm.validate()

        if (this.customerFormValid && this.addressFormValid) {
          const address = JSON.parse(JSON.stringify(this.address))
          address.Formatted = this.getAddressFormatted(address)
          if (this.useCustomAddress) address.Lat = address.Lng = 0

          this.newCustomer.Addresses = []
          this.newCustomer.Addresses.push(address)
          this.saveCustomer(this.newCustomer)
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.FieldError'),
            type: 'error',
            icon: 'warning'
          })
        }
      }
    },
    saveCustomer (customer) {
      this.loading = true

      const payload = {
        ...JSON.parse(JSON.stringify(customer.Info)),
        Addresses: JSON.parse(JSON.stringify(customer.Addresses))
      }

      window.callAS(window.SocketCommand.Customer.Save, payload)
    },
    onCancelButtonClick () {
      if (this.$refs.customerForm) this.$refs.customerForm.resetValidation()
      if (this.$refs.addressForm) this.$refs.addressForm.resetValidation()
      this.initMapCenter()
      this.isVisible = false
      this.stepperStatus = 1
      this.$nextTick(() => {
        this.newCustomer = null
        this.clearSelectedAddress()
      })
    },
    onCustomerGroupsUpdateDataItems (data) {
      this.$set(this.editCustomer, 'CustomerGroups', data?.items || data?.table?.items?.items || [])
    },
    onCustomerSaveResult (result) {
      if (result.status === 'success' && result.result) {
        if (result.result.customer_id && this.newCustomer) this.newCustomer.Id = result.result.customer_id
        if (result.result.address_id && this.newAddress) this.newAddress.Id = result.result.address_id
        this.$bus.$emit('new-customer-created', {
          Customer: result.result.customer_id && this.newCustomer ? JSON.parse(JSON.stringify(this.newCustomer)) : null,
          Address : result.result.address_id && this.newAddress ? JSON.parse(JSON.stringify(this.newAddress)) : null
        })

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'info',
          icon: 'info'
        })

        if (this.$refs.customerForm) this.$refs.customerForm.resetValidation()
        if (this.$refs.addressForm) this.$refs.addressForm.resetValidation()
        this.markerVisible = false
        this.initMapCenter()
        this.isVisible = false
        this.stepperStatus = 1
        this.$nextTick(() => {
          this.newCustomer = null
          this.newAddress = null
        })
        this.autoCompleteModel = ''
      } else if (result.message) {
        this.$bus.$emit('app-show-notification', {
          body: result.message,
          type: 'error',
          icon: 'warning'
        })
      } else {
        // Set Error Messages from API to Fields
        let key
        if (result?.result?.address_id) {
          for (key in result.result.address_id) {
            if (result.result.address_id.hasOwnProperty(key) && this.errorMessages.customerForm.hasOwnProperty(key)) {
              this.errorMessages.customerForm[key].push(result.result.address_id[key])
            }
          }
        }

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'

        })
      }
      this.loading = false
    },
    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      let key
      for (key in this.errorMessages.customerForm) {
        if (this.errorMessages.customerForm.hasOwnProperty(key)) {
          this.errorMessages.customerForm[key] = []
        }
      }
    }
  }
}
</script>

<style scoped>
.hideMap {
  display : none;
}
</style>
