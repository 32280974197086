<template>
  <v-card
    v-if="domainReseller"
    flat
  >
    <v-toolbar
      flat
      class="pl-0"
      height="70"
    >
      <v-avatar class="purple">
        <v-icon dark>
          mdi-handshake
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Account.Billing.Misc.Reseller.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Account.Billing.Misc.Reseller.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text>
      <v-container
        class="pa-1"
        fluid
      >
        <v-row dense>
          <v-col
            v-if="domainReseller.Name"
            sm="6"
            cols="12"
          >
            <v-text-field
              filled
              dense
              :label="$t('Account.Billing.Field.Company.Label')"
              :prepend-icon="$t('Account.Billing.Field.Company.Icon')"
              readonly
              :value="`${domainReseller.Name}`"
            />
          </v-col>

          <v-col
            v-if="domainReseller.Address"
            sm="6"
            cols="12"
          >
            <v-text-field
              filled
              dense
              :label="$t('Account.Billing.Field.Address.Label')"
              :prepend-icon="$t('Account.Billing.Field.Address.Icon')"
              readonly
              :value="`${domainReseller.Address}, ${domainReseller.Area}`"
            />
          </v-col>

          <v-col
            v-if="domainReseller.Email"
            sm="6"
            cols="12"
          >
            <v-text-field
              filled
              dense
              :label="$t('Account.Billing.Field.Email.Label')"
              :prepend-icon="$t('Account.Billing.Field.Email.Icon')"
              readonly
              :value="domainReseller.Email"
            />
          </v-col>

          <v-col
            v-if="domainReseller.Phone"
            sm="6"
            cols="12"
          >
            <v-text-field
              filled
              dense
              :label="$t('Account.Billing.Field.Telephone.Label')"
              :prepend-icon="$t('Account.Billing.Field.Telephone.Icon')"
              readonly
              :value="domainReseller.Phone"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

import AppData from '@/mixins/appdata'

export default {
  name      : 'ResellerContactInfoCard',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
