<template>
  <v-app-bar
    id="appToolbar"
    class="appToolbar"
    color="grey darken-3"
    app
    dark
    dense
    fixed
    flat
  >
    <template v-if="!appDomainIsLocked">
      <v-app-bar-nav-icon @click.stop="navigationDrawerVisible = !navigationDrawerVisible" />

      <v-toolbar-title
        class="text-body-2 font-weight-medium pl-0"
        style="padding-top: 1px;"
      >
        {{ toolbarTitle }}
      </v-toolbar-title>
    </template>

    <template v-else>
      <v-avatar
        size="48"
        style="min-width: 48px;"
        tile
      >
        <svg-logo
          size="45px"
          :fork-color="isDarkMode ? '#fff' : '#414042'"
          :pro-color="isDarkMode ? '#fff' : '#414042'"
          :signal-color1="isDarkMode ? '#fff' : '#58595b'"
          :signal-color2="isDarkMode ? '#fff' : '#414042'"
        />
      </v-avatar>

      <v-divider
        class="ml-2"
        style="margin-left: 1px;"
        vertical
      />
    </template>

    <v-spacer />

    <template v-if="!appDomainIsLocked">
      <template v-if="$DataStore.cloudUpdate.HasUpdate">
        <v-badge
          class="pulse-badge"
          color="red"
          right
          overlap
          :offset-x="20"
          :offset-y="15"
        >
          <v-btn
            :class="toolbarButtonsClass"
            class="orange--text pulse-badge"
            icon
            height="36"
            width="36"
            @click="EventBus.$emit('app-cloud-update-notify-user', $DataStore.cloudUpdate)"
          >
            <v-icon>mdi-update</v-icon>
          </v-btn>

          <template #badge>
            <v-icon v-text="'mdi-exclamation-thick'" />
          </template>
        </v-badge>
      </template>

      <current-plan-warning
        :show-before-expire="false"
        show-in-app-toolbar
      />

      <template v-if="appHasOrphanedTax && !userIsWaiter && posUserCan('KitchenMenuCategories', 'MANAGE') && posUserCan('KitchenMenu', 'MANAGE')">
        <v-tooltip
          color="red"
          bottom
        >
          <template #activator="{on}">
            <div v-on="on">
              <v-btn
                :class="toolbarButtonsClass"
                class="red--text px-2"
                style="min-width: 36px"
                height="36"
                depressed
                :to="{name: appTaxes.Orphaned.Category.Count > 0 ? 'KitchenMenuCategories' : 'KitchenMenu'}"
              >
                <v-icon>mdi-percent-box</v-icon>

                <span class="font-weight-bold ml-1">ΦΠΑ</span>
              </v-btn>

              <v-badge
                v-if="appTaxes.Orphaned.Category.Count"
                color="red"
                left
                overlap
                :offset-x="-70"
                :offset-y="17"
              >
                <template #badge>
                  <span>{{ appTaxes.Orphaned.Category.Count }}</span>
                </template>
              </v-badge>

              <v-badge
                v-if="appTaxes.Orphaned.Menu.Count"
                color="red"
                left
                overlap
                :offset-x="-70"
                :offset-y="-6"
              >
                <template #badge>
                  <span>{{ appTaxes.Orphaned.Menu.Count }}</span>
                </template>
              </v-badge>
            </div>
          </template>

          <div>
            <span
              v-if="appTaxes.Orphaned.Category.Count && appTaxes.Orphaned.Menu.Count"
              v-html="$t('Common.Misc.Notification.TaxesOrphaned.Both', {categories: appTaxes.Orphaned.Category.Count, products: appTaxes.Orphaned.Menu.Count})"
            />

            <span
              v-else-if="appTaxes.Orphaned.Category.Count"
              v-html="$tc('Common.Misc.Notification.TaxesOrphaned.Category', appTaxes.Orphaned.Category.Count, {categories: appTaxes.Orphaned.Category.Count})"
            />

            <span
              v-else-if="appTaxes.Orphaned.Menu.Count"
              v-html="$tc('Common.Misc.Notification.TaxesOrphaned.Product', appTaxes.Orphaned.Menu.Count, {products: appTaxes.Orphaned.Menu.Count})"
            />
          </div>
        </v-tooltip>
      </template>
    </template>

    <v-menu
      v-model="staffMenu"
      :close-on-content-click="false"
    >
      <template #activator="{on}">
        <v-btn
          :class="[$vuetify.breakpoint.smAndDown ? 'mx-0 px-0' : 'px-2', {'mr-3': appDomainIsLocked}]"
          :icon="$vuetify.breakpoint.smAndDown"
          height="36"
          width="auto"
          color="#494949"
          dark
          depressed
          v-on="on"
        >
          <v-icon
            color="grey lighten-1"
            size="22"
          >
            lock_outline
          </v-icon>

          <span
            v-if="posUser && $vuetify.breakpoint.mdAndUp"
            class="pl-2 grey--text text--lighten-1"
          >
            {{ posUser ? posUser.staff_short_name : '' }}
          </span>
        </v-btn>
      </template>

      <v-card dark>
        <v-list dark>
          <v-list-item>
            <v-list-item-avatar color="indigo">
              <v-icon dark>
                account_circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content v-if="posUser">
              <v-list-item-title>{{ posUser.staff_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ posUser.staff_email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-card-actions v-if="userInAdminGroup">
          <v-autocomplete
            v-model="staffDomain"
            :items="domains"
            :filter="filterDomain"
            :label="$t('Staff.StaffAddDialog.Field.Domain.Label')"
            :prepend-icon="$t('Staff.StaffAddDialog.Field.Domain.Icon')"
            :placeholder="$t('Staff.StaffAddDialog.Field.Domain.Label')"
            item-text="Domain"
            item-value="Domain"
            solo-inverted
            hide-details
            required
            flat
            @blur="onStaffDomainReset"
            @change="onStaffDomainChange"
          >
            <template #selection="data">
              <div class="text-caption">
                <div>{{ data.item.Domain }}</div>
                <div
                  class="text-caption"
                  :class="data.item.CNameActive ? 'green--text' : 'grey--text'"
                >
                  {{ data.item.CName }}
                </div>
              </div>
            </template>

            <template #item="data">
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.Domain }}
                </v-list-item-title>

                <v-list-item-subtitle
                  class="text-caption"
                  :class="data.item.CNameActive ? 'green--text' : 'grey--text'"
                >
                  {{ data.item.CName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-actions>

        <v-card-actions>
          <div
            style="width: 100%;"
            class="d-inline-flex"
          >
            <v-btn
              v-if="posUserHasComponentPermission('AccountOverview', 'ACCESS') && !appDomainIsLocked"
              :to="{name: 'AccountOverview'}"
              class="flex-grow-1 mr-2"
              text
              small
              @click="staffMenu = false"
            >
              <v-icon left>
                account_circle
              </v-icon>

              {{ $t('Account.Title').toLocaleUpperCase($i18n.locale) }}
            </v-btn>

            <v-btn
              color="red"
              class="flex-grow-1"
              dark
              depressed
              small
              @click="showLogOutDialog"
            >
              <v-icon left>
                exit_to_app
              </v-icon>
              {{ $t('Common.Misc.LogoutDialog.Title').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-menu>

    <template v-if="!appDomainIsLocked">
      <template :slot="activeSlot">
        <!-- HELP MENU -->
        <v-menu>
          <template #activator="{on}">
            <v-btn
              :class="toolbarButtonsClass"
              class="grey--text text--lighten-1"
              icon
              height="36"
              width="36"
              v-on="on"
            >
              <v-icon>help_outline</v-icon>
            </v-btn>
          </template>

          <v-card dark>
            <v-list dark>
              <v-list-item>
                <v-list-item-avatar>
                  <svg-logo
                    fork-color="#FFF"
                    pro-color="#FFF"
                    signal-color1="#FFF"
                    signal-color2="#FFF"
                    size="46px"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>GETFOOD Pro</v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text-caption">
                      {{ appVersion }}
                    </span>

                    <span
                      v-if="appVersionDesktop"
                      style="font-size: 10px;"
                    >
                      ({{ appVersionDesktop }})
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider />

            <v-list dense>
              <v-list-item style="max-height: 30px;">
                <v-list-item-action>
                  <v-icon color="light-green">
                    access_time
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>10:00 - 18:00</v-list-item-title>
              </v-list-item>

              <v-list-item style="max-height: 30px;">
                <v-list-item-action>
                  <v-icon color="orange darken-1">
                    phone
                  </v-icon>
                </v-list-item-action>

                <v-list-item-title v-if="domainReseller">
                  {{ domainReseller.Phone || '+30 215 215 23 24' }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  +30 215 215 23 24
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :href="`mailto: ${domainReseller && domainReseller.Email ? domainReseller.Email : 'support@getfoodpro.gr'}?cc=${domainReseller && domainReseller.Email ? 'support@getfoodpro.gr' : ''}&subject=GFP SUPPORT REQUEST&body=
${$t('Dashboard.Help.SupportEmail.Name')}: ${posUser && posUser.staff_name} %0D%0A
${$t('Dashboard.Help.SupportEmail.Store')}: ${appConfig && appConfig.LOCATION_DATA.Name} %0D%0A
${$t('Dashboard.Help.SupportEmail.Url')}: ${appConfig && appConfig.LOCATION_DATA.SiteUrl} %0D%0A
${$t('Dashboard.Help.SupportEmail.Email')}: ${posUser && posUser.staff_email} %0D%0A %0D%0A
${$t('Dashboard.Help.SupportEmail.Message')} %0D%0A %0D%0A
                `"
                class="mb-2"
                style="max-height: 30px;"
                target="_blank"
              >
                <v-list-item-action>
                  <v-icon color="blue">
                    email
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title v-if="domainReseller">
                  {{ domainReseller.Email || 'support@getfoodpro.gr' }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  support@getfoodpro.gr
                </v-list-item-title>
              </v-list-item>
            </v-list>

            <template v-if="posUserHasComponentPermission('Faq', 'ACCESS') || posUserHasComponentPermission('SupportCenter', 'ACCESS') || posUser && posUser.staff_group_id === StaffGroupEnum.OWNER || posUser && posUser.staff_group_id === StaffGroupEnum.ADMIN">
              <v-divider />

              <v-list class="pb-0">
                <v-list-item
                  v-if="posUserHasComponentPermission('AccountOverview', 'ACCESS')"
                  :to="{name: 'AccountOverview'}"
                >
                  <v-list-item-action>
                    <v-icon>
                      account_circle
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                    {{ $t('Account.Title') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="posUserHasComponentPermission('SupportCenter', 'ACCESS')"
                  :to="{name: 'SupportCenter'}"
                >
                  <v-list-item-action>
                    <v-icon color>
                      help_outline
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                    {{ $t('Dashboard.Help.SupportCenter') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="posUserHasComponentPermission('Faq', 'ACCESS')"
                  :href="`https://help.getfoodpro.com/${$i18n.locale}`"
                  target="_blank"
                >
                  <v-list-item-action>
                    <v-icon>
                      support
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                    {{ $t('Dashboard.Help.Faq') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="$route.name === 'Dashboard' && (posUser && posUser.staff_group_id === StaffGroupEnum.OWNER || posUser && posUser.staff_group_id === StaffGroupEnum.ADMIN)"
                  @click="$bus.$emit('show-settings-wizard')"
                >
                  <v-list-item-action>
                    <v-icon>
                      settings
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                    {{ $t('Dashboard.Help.Wizard') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  :href="`https://help.getfoodpro.com/${$i18n.locale}/category/whats-new-in-getfood-pro-19`"
                  target="_blank"
                  class="info"
                >
                  <v-list-item-action>
                    <v-icon>
                      mdi-new-box
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                    {{ $t('Dashboard.Help.WhatsNew') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </template>
          </v-card>
        </v-menu>

        <template v-if="!appDomainIsLocked">
          <template v-for="(item, i) in items">
            <v-spacer
              v-if="$vuetify.breakpoint.xsOnly && activeSlot === 'extension'"
              :key="'spacer1_' + i"
            />

            <v-tooltip
              :key="i + '_tooltip'"
              bottom
              open-delay="1000"
            >
              <template #activator="{on}">
                <template v-if="item.route.path=='/orders/today'">
                  <orders-queue
                    :key="i"
                    :menu-item="item"
                    :class="toolbarButtonsClass"
                    v-on="on"
                  />
                </template>

                <v-btn
                  v-else-if="item.route.path=='/messages-inbox'"
                  :key="i + '_btn'"
                  :class="toolbarButtonsClass"
                  :exact="item.route.exact"
                  :to="item.route"
                  class="grey--text text--lighten-1"
                  icon
                  width="36"
                  height="36"
                  v-on="on"
                >
                  <v-badge
                    :class="[messagesInboxCount > 0 ? 'pulse-badge' : '']"
                    :color="messagesInboxHasImportant ? 'red' : 'green'"
                    :value="messagesInboxCount > 0"
                    overlap
                    right
                  >
                    <template #badge>
                      <span>{{ messagesInboxCount }}</span>
                    </template>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-badge>
                </v-btn>

                <v-btn
                  v-else
                  :key="i + '_btn'"
                  :class="toolbarButtonsClass"
                  :exact="item.route.exact"
                  :to="item.route"
                  class="grey--text text--lighten-1"
                  icon
                  width="36"
                  height="36"
                  v-on="on"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </template>
        </template>
      </template>

      <calls-queue
        v-if="posUserHasComponentPermission('Voip', 'ACCESS')"
        class="ml-1 mr-0"
      />

      <online-status class="ml-1 mr-0" />
    </template>
  </v-app-bar>
</template>

<script>
import AppData            from '@/mixins/appdata'
import Auth               from '@/mixins/auth'
import SvgLogo            from '@/components/common/SvgLogo.vue'
import OrdersQueue        from '@/components/common/OrdersQueue.vue'
import CallsQueue         from '@/components/common/CallsQueue.vue'
import OnlineStatus       from '@/components/common/OnlineStatus.vue'
import { EventBus }       from '@/events/eventBus'
import StaffGroupEnum     from '@/api/Enums/StaffGroupEnum'
import ipcCommandsEnum    from '@/electron/ipcCommandsEnum'
import CurrentPlanWarning from '@/components/account/CurrentPlanWarning.vue'

export default {
  name      : 'AppToolbar',
  components: {
    CurrentPlanWarning,
    'svg-logo'     : SvgLogo,
    'orders-queue' : OrdersQueue,
    'calls-queue'  : CallsQueue,
    'online-status': OnlineStatus
  },
  directives: {},
  mixins    : [AppData, Auth],
  props     : {
    navigationDrawer: {
      type   : Boolean,
      default: false
    },
    toolbarTitle: {
      type   : String,
      default: ''
    },
    items: {
      type    : Array,
      required: true
    }
  },
  data () {
    return {
      staffDomain: null,
      staffMenu  : false
    }
  },
  computed: {
    ipcCommandsEnum () {
      return ipcCommandsEnum
    },
    StaffGroupEnum () {
      return StaffGroupEnum
    },
    domains () {
      return this.$DataStore?.appDomains || []
    },
    EventBus () {
      return EventBus
    },
    activeSlot () {
      if (this.userIsWaiter) return 'default'
      return this.$vuetify.breakpoint.xsOnly ? 'extension' : 'default'
    },

    toolbarButtonsClass () {
      return this.$vuetify.breakpoint.smAndDown ? 'mx-0' : 'mx-1'
    },

    messagesInboxCount () {
      return this.$DataStore.messages.count
    },

    messagesInboxHasImportant () {
      return this.$DataStore.messages.hasImportant
    },

    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.addStaffDomainEvents()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.removeStaffDomainEvents()
  },
  destroyed () {
  },
  methods: {
    addStaffDomainEvents () {
      if (!this.userInAdminGroup) return

      this.$bus.$on(window.SocketCommand.Settings.Domains.ComboList, this.setDomainsCombo)
      this.$bus.$on(window.SocketCommand.Staff.ChangeDomain, this.onChangeStaffDomainResult)

      this.staffDomain = this.posUser?.Domain || null
      window.callAS(window.SocketCommand.Settings.Domains.ComboList, {})
    },
    removeStaffDomainEvents () {
      if (!this.userInAdminGroup) return

      this.$bus.$off(window.SocketCommand.Settings.Domains.ComboList, this.setDomainsCombo)
      this.$bus.$off(window.SocketCommand.Staff.ChangeDomain, this.onChangeStaffDomainResult)
    },

    changeStaffDomain () {
      if (!this.staffDomain || !this.userInAdminGroup) return

      this.staffMenu = false
      this.$bus.$emit('show-app-loading-modal', true)
      window.callAS(window.SocketCommand.Staff.ChangeDomain, { Domain: this.staffDomain })
    },

    onChangeStaffDomainResult (response) {
      if (response) {
        location && location.reload()
      } else {
        this.$bus.$emit('show-app-loading-modal', false)
      }
    },

    onStaffDomainChange (newVal) {
      if (newVal && this.staffDomain !== this.posUser?.Domain) this.changeStaffDomain()
    },

    onStaffDomainReset () {
      if (!this.staffDomain) this.staffDomain = this.posUser?.Domain || null
    },

    setDomainsCombo (response) {
      if (response) this.$DataStore.appDomains = response
    },

    filterDomain (item, queryText, itemText) {
      const domain = item.Domain?.toLowerCase() || ''
      const cname = item.CName?.toLowerCase() || ''
      const searchText = queryText.toLowerCase()

      return domain.includes(searchText) || cname.includes(searchText)
    },

    showLogOutDialog () {
      this.$emit('app:logout')
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width : 599px) {
  /deep/ .v-toolbar__extension {
    padding : 4px;
  }
}
</style>
