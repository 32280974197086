/* eslint-disable camelcase,no-unused-vars */
import { API, APICall } from '@/api/Api'

export default {
  data () {
    return {
      place                      : {},
      autoCompleteModel          : '',
      mapCenter                  : { lat: window.appConfig.LOCATION_DATA.Lat, lng: window.appConfig.LOCATION_DATA.Lng },
      markerStore                : { lat: window.appConfig.LOCATION_DATA.Lat, lng: window.appConfig.LOCATION_DATA.Lng },
      markerAddress              : { lat: window.appConfig.LOCATION_DATA.Lat, lng: window.appConfig.LOCATION_DATA.Lng },
      markerVisible              : false,
      mapVisible                 : true,
      mapZoom                    : 18,
      polys                      : null,
      circs                      : null,
      rt                         : 0,
      s_number                   : '',
      s_street                   : '',
      s_city                     : '',
      s_country                  : '',
      s_country_code             : '',
      googleMapState             : false,
      addressIsOutsideStoreLimits: false
    }
  },

  methods: {
    initMapCenter (initLatLng, useOffset = true) {
      this.autoCompleteModel = ''
      this.markerVisible = false
      this.mapCenter = this.markerAddress = this.markerStore
      this.addressIsOutsideStoreLimits = false
      const lat = parseFloat(initLatLng?.Lat || initLatLng?.lat || this.markerAddress.lat)
      const lng = parseFloat(initLatLng?.Lng || initLatLng?.lng || this.markerAddress.lng)

      this.markerAddress = useOffset ? this.offsetLatLng(18, 0, { lat: lat, lng: lng }) : { lat: lat, lng: lng }
      this.geocodeLatLng(lat, lng, 'setAddressFields')
      this.markerVisible = true
    },

    fillInAddress (place) {
      this.addressIsOutsideStoreLimits = false
      this.place = place
      if (place && place.geometry && place.geometry.location) {
        this.checkAddress(place.geometry.location.lat(), place.geometry.location.lng())
      }
    },

    fillInAddressNoLimitis (place) {
      this.addressIsOutsideStoreLimits = false
      this.place = place
      if (place && place.geometry && place.geometry.location) {
        this.setGeocodeAddress()
      }
    },

    async checkAddress (lat, lng) {
      // eslint-disable-next-line
      let latLng = new google.maps.LatLng(lat, lng)
      const result = await this.isPointInside(latLng)

      if (result) {
        this.addressIsOutsideStoreLimits = false
        if (this.place === undefined) {
          this.geocodeLatLng(lat, lng, 'setGeocodeAddress')
        } else {
          this.setGeocodeAddress()
        }
      } else {
        this.addressIsOutsideStoreLimits = true

        if (this.onlyWarningForAddresses) {
          if (this.place === undefined) {
            this.geocodeLatLng(lat, lng, 'setGeocodeAddress')
          } else {
            this.setGeocodeAddress()
          }
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Range.Subtitle'),
            type: 'error',
            icon: 'warning'
          })

          if (this.$refs.addressForm) this.$refs.addressForm.reset()
          this.autoCompleteModel = ''
        }
      }
    },

    async isLatLngInDeliveryCountries (lat, lng) {
      try {
        const payload = APICall.CreatePayload(window.SocketCommand.Customer.AddressValidate, { Lat: lat || 0, Lng: lng || 0 })
        const response = await API.post(APICall.api, payload)
        return response?.data?.status === 'success'
      } catch (e) {
        return false
      }
    },

    async isPointInside (position, countryCode) {
      // Check if point belongs to custom address
      if (position.lat() === 0 && position.lng() === 0) return false

      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas) {
        try {
          return await this.isLatLngInDeliveryCountries(position.lat(), position.lng())
        } catch (e) {
          return false
        }
      }

      let i = 0
      for (i = 0; i < this.circles.length; i++) {
        // eslint-disable-next-line
        if (google.maps.geometry.spherical.computeDistanceBetween(position, this.circles[i].getCenter()) <= this.circles[i].getRadius()) return true
      }
      for (i = 0; i < this.restaurantDeliveryAreaPolygons.length; i++) {
        // eslint-disable-next-line
        if (google.maps.geometry.poly.containsLocation(position, this.restaurantDeliveryAreaPolygons[i])) return true
      }
      return false
    },
    getCountryCodeFromLatLng (lat, lng) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              if (Array.isArray(results[0].address_components)) {
                results[0].address_components.forEach((item) => {
                  if (item.types[0] === 'country') return resolve(item.short_name)
                })
              }
              // eslint-disable-next-line prefer-promise-reject-errors
              reject()
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            reject()
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        })
      })
    },

    geocodeLatLng (lat, lng, callback, usePlaceId = true) {
      const self = this
      const placeId = this.address?.PlaceId || this.place?.place_id || ''
      this.geocoder.geocode({
        ...((usePlaceId && placeId) && { placeId: placeId }),
        ...((lat && lng && !(usePlaceId && placeId)) && { location: { lat: lat, lng: lng } })
      }, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            self.place = results[0]
            self.place.geometry.location.lat = lat
            self.place.geometry.location.lng = lng
            if (callback === 'setAddressFields') {
              self.setAddressFields()
            } else if (callback === 'setGeocodeAddress') {
              self.setGeocodeAddress()
            }
          }
        }
      })
    },

    setGeocodeAddress () {
      this.mapCenter = this.place && this.place.geometry && this.place.geometry.location || { lat: 0, lng: 0 }
      this.setAddressFields()
      this.markerVisible = false
      this.markerAddress = this.getLatLngFromPlace()
      this.markerVisible = true
    },

    onMarkerDragEndNoLimits (e) {
      this.markerAddress = e.latLng
      this.mapCenter = e.latLng
      this.geocodeLatLng(this.markerAddress.lat(), this.markerAddress.lng(), 'setAddressFields', false)
    },

    async onMarkerDragEnd (e) {
      this.markerAddress = e.latLng
      this.mapCenter = e.latLng
      const $result = await this.isPointInside(this.markerAddress)

      if ($result) {
        this.addressIsOutsideStoreLimits = false
        this.geocodeLatLng(this.markerAddress.lat(), this.markerAddress.lng(), 'setAddressFields', false)
      } else {
        this.addressIsOutsideStoreLimits = true

        if (this.onlyWarningForAddresses) {
          this.geocodeLatLng(this.markerAddress.lat(), this.markerAddress.lng(), 'setAddressFields', false)
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Range.Subtitle'),
            type: 'error',
            icon: 'warning'
          })

          if (this.$refs.addressForm) this.$refs.addressForm.reset()
          this.autoCompleteModel = this.newAddress.Formatted = ''
        }
      }

      if (this.$refs.addressForm) this.$refs.addressForm.validate()
    },

    setAddressFields () {
      const obj = {
        street_number              : '',
        route                      : '',
        locality                   : '',
        postal_town                : '',
        administrative_area_level_1: '',
        administrative_area_level_2: '',
        country                    : '',
        country_code               : '',
        postal_code                : ''
      }
      const componentForm = {
        street_number              : 'short_name',
        route                      : 'long_name',
        locality                   : 'long_name',
        postal_town                : 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'short_name',
        country                    : 'long_name',
        postal_code                : 'short_name'
      }

      for (let i = 0; i < this.place.address_components.length; i++) {
        const addressType = this.place.address_components[i].types[0]
        if (this.place.address_components[i].types[0] === 'country') obj.country_code = this.place.address_components[i].short_name
        if (componentForm[addressType]) {
          obj[addressType] = this.place.address_components[i][componentForm[addressType]]
        }
      }

      if (!this.newAddress) this.newAddress = {}

      let formatted_address = ''
      this.newAddress.Street = obj.route

      this.s_street = obj.route
      if (obj.route) {
        formatted_address = obj.route
      }
      if (obj.street_number) {
        this.newAddress.Number = obj.street_number
      }

      const number = ''
      let street_number = this.newAddress.Number
      if ((this.rt === 0) && number && (number !== this.s_number)) {
        this.rt = 1
        this.s_number = number
        this.newAddress.Number = number
        this.getAddress()
        return
      }

      this.s_number = street_number
      street_number = this.newAddress.Number

      if (street_number) {
        formatted_address += (obj.route ? ' ' : '') + street_number + ', '
      } else {
        formatted_address += obj.route ? ', ' : ''
      }

      this.newAddress.City = obj.locality || obj.postal_town || obj.administrative_area_level_2
      this.s_city = obj.locality || obj.postal_town || obj.administrative_area_level_2
      if (this.s_city) {
        formatted_address += this.s_city + (obj.postal_code ? ' ' : ', ')
      }

      // $('.administrative_area_level_1').val(obj.administrative_area_level_1);
      this.newAddress.Postcode = obj.postal_code
      if (obj.postal_code) {
        formatted_address += obj.postal_code + ', '
      }

      this.newAddress.Country = obj.country
      this.newAddress.CountryCode = obj.country_code || ''
      if (obj.country) {
        formatted_address += obj.country
      }

      const latLng = this.getLatLngFromPlace()
      this.newAddress.Lat = latLng.lat
      this.newAddress.Lng = latLng.lng
      this.newAddress.PlaceId = this.place.place_id
      this.newAddress.Formatted = formatted_address

      this.autoCompleteModel = formatted_address
    },

    getAddressNoLimits () {
      const country = this.newAddress.Country
      const s_country = this.s_country
      const country_code = this.newAddress.CountryCode
      const s_country_code = this.s_country_code
      const city = this.newAddress.City
      const s_city = this.s_city
      const street = this.newAddress.Street
      const s_street = this.s_street
      const number = this.newAddress.Number
      const s_number = this.s_number

      if (city !== s_city || street !== s_street || number !== s_number) {
        const address = city + ',' + street
        const self = this
        this.geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK') {
            self.place = results[0]
            const latLng = self.getLatLngFromPlace()
            if (this.place === undefined) {
              this.geocodeLatLng(latLng.lat, latLng.lng, 'setGeocodeAddress')
            } else {
              this.setGeocodeAddress()
            }
          } else {
            // -- NOT FOUND
            // console.log(json.status);
          }
        })
      }
    },

    getAddress () {
      const country = this.newAddress.Country
      const s_country = this.s_country
      const country_code = this.newAddress.CountryCode
      const s_country_code = this.s_country_code
      const city = this.newAddress.City
      const s_city = this.s_city
      const street = this.newAddress.Street
      const s_street = this.s_street
      const number = this.newAddress.Number
      const s_number = this.s_number

      if (city !== s_city || street !== s_street || number !== s_number) {
        const address = city + ',' + street + ' ' + number
        const self = this
        this.geocoder.geocode({ address: address }, function (results, status) {
          if (status === 'OK') {
            self.place = results[0]
            const latLng = self.getLatLngFromPlace()
            self.checkAddress(latLng.lat, latLng.lng)
          } else {
            // -- NOT FOUND
            // console.log(json.status);
          }
        })
      }
    },

    getLatLngFromPlace () {
      let lat = 0
      let lng = 0
      if (typeof this.place.geometry.location.lat === 'function') {
        lat = this.place.geometry.location.lat()
      } else {
        lat = this.place.geometry.location.lat
      }
      if (typeof this.place.geometry.location.lng === 'function') {
        lng = this.place.geometry.location.lng()
      } else {
        lng = this.place.geometry.location.lng
      }
      return { lat: lat, lng: lng }
    },

    isCustomAddress (address) {
      return address && parseFloat(address.Lng) === 0 && parseFloat(address.Lat) === 0
    },

    offsetLatLng (offsetLatInMeters, offsetLngInMeters, locationLatLng) {
      const earth = 6378.137 // radius of the earth in kilometer
      const m = (1 / ((2 * Math.PI / 360) * earth)) / 1000 // 1 meter in degree

      const newLat = locationLatLng.lat + (offsetLatInMeters * m)
      const newLng = locationLatLng.lng + (offsetLngInMeters * m) / Math.cos(locationLatLng.lng * (Math.PI / 180))

      return { lat: newLat, lng: newLng }
    }

  },

  computed: {
    onlyWarningForAddresses () {
      return this.appConfig?.LOCATION_DATA?.HasOnlyWarningForAddresses ?? false
    },
    acceptCustomAddresses () {
      return this.appConfig?.LOCATION_DATA?.AcceptCustomAddresses ?? false
    },
    acceptLatLngAddresses () {
      return this.appConfig?.LOCATION_DATA?.AcceptLatLngAddresses ?? false
    },
    restaurantDeliveryAreaPolygons: {
      get () {
        const $location_polygons = this.appConfig.LOCATION_DATA.DeliveryAreas

        if (!this.polys) {
          this.polys = []
          for (const key in $location_polygons) {
            if ($location_polygons.hasOwnProperty(key)) {
              const poly = $location_polygons[key]
              if (poly.type === 'shape') {
                // eslint-disable-next-line
                this.polys.push(new google.maps.Polygon({ paths: poly.vertices }))
              }
            }
          }
        }

        return this.polys
      }
    },
    circles: {
      get () {
        const $location_polygons = this.appConfig.LOCATION_DATA.DeliveryAreas

        if (!this.circs) {
          this.circs = []
          for (const key in $location_polygons) {
            if ($location_polygons.hasOwnProperty(key)) {
              const poly = $location_polygons[key]
              if (poly.type === 'circle') {
                // eslint-disable-next-line
                this.circs.push(new google.maps.Circle(poly.circle))
              }
            }
          }
        }
        return this.circs
      }
    },
    geocoder: {
      get () {
        // eslint-disable-next-line
        return new google.maps.Geocoder()
      }
    }

  },
  created () {
    this.$gmapApiPromiseLazy().then(() => {
      this.googleMapState = true
    })
  },
  mounted () {

  }

}
