import SourceTypeEnum from '@/api/Enums/SourceTypeEnum'
import { clone }      from '@/lib/helper/helper'

export default {
  data () {
    return {
      selectedCatalog   : null,
      activeCatalog     : null,
      activeCategory    : null,
      activeTags        : [],
      lastPosMenuUpdate : new Date().getTime(),
      posMenuItemsActive: []
    }
  },
  mixins : [],
  methods: {
    getMenuItemTags (menuItem) {
      const AppTags = this.appConfig?.TAGS || []
      const tags = []

      menuItem.Tags.forEach(tagId => {
        const tag = AppTags.find(appTag => appTag.Id === tagId && appTag.Active)
        if (tag) tags.push(clone(tag))
      })

      return tags
    },

    getMenuPriceWithExtraCharges (menu, specialPrice = false) {
      if (!menu) return 0

      let extraCharges = 0
      const options = menu?.DetailsData?.Options || []
      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'extra_charges' && option.Selected) {
            extraCharges = extraCharges + parseFloat(option.Price)
          }
        }
      }

      return parseFloat(parseFloat(parseFloat(specialPrice ? menu.SpecialPrice : menu.Price) + parseFloat(extraCharges)).toFixed(2))
    },

    getMenuUnitBasePriceWithOptions (menu) {
      if (!menu || menu.UnitId <= 1) return 0

      let optionsPrice = 0
      const options = menu?.DetailsData?.Options || []
      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]

          if (item.DisplayType === 'unit_value') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              optionsPrice = optionsPrice + parseFloat(option.Price)
            }
          }
        }
      }

      return parseFloat(parseFloat(parseFloat(menu.Price) + parseFloat(optionsPrice)).toFixed(2))
    },

    menuItemQuantityInCart (menu) {
      let quantity = 0
      this.posCart.Items.forEach(category => {
        category.Items.forEach(item => {
          if (parseInt(item.Id) === parseInt(menu.Id)) {
            const itemQuantity = item.UnitId > 1 ? item.UnitQuantity : item.Quantity
            quantity = quantity + parseInt(itemQuantity)
          }
        })
      })

      return quantity
    },

    onCategoryClick (category, posMenuItems) {
      this.activeTags = []
      this.getCategoryDetails(category, posMenuItems)
    },

    getCategoryDetails (category, posMenuItems) {
      if (parseInt(this.activeCategory?.Id) === parseInt(category?.Id) && !posMenuItems) return

      const categoryDetailsList = document.getElementById('categoryDetailsList')
      if (categoryDetailsList) categoryDetailsList.scrollTo(0, 0)

      this.searchProduct = ''
      this.activeCategory = category || null

      const data = posMenuItems || this.posMenuItems
      let categoryData = []

      if (category) {
        categoryData = data?.items?.find(obj => parseInt(obj.Category.Id) === parseInt(category?.Id)) || []
      } else {
        categoryData = data?.items?.length ? data.items[0] : []
      }

      this.$set(this, 'posMenuItemsActive', [categoryData])
    },

    getCategoryColorByCategory (category) {
      if (!category) return 'grey'

      return category.Color || 'grey'
    },

    getCategoryColor (id) {
      if (!id) return 'grey'

      const category = window?.posMenu?.categories?.find(category => parseInt(category.Id) === parseInt(id))
      return category ? category.Color : 'grey'
    },

    getCategory (id) {
      if (!id) return null

      return window?.posMenu?.categories?.find(category => parseInt(category.Id) === parseInt(id))
    },

    getMenuItemsAllById (menuItemId) {
      const resultArray = []
      if (!this.posMenuItems || !menuItemId) return resultArray

      const categories = this.posMenuItems?.items || []

      categories.forEach(category => {
        const items = category?.Items || []
        items.forEach(menu => {
          if (parseInt(menu.Id) === parseInt(menuItemId)) {
            resultArray.push(menu)
          }
        })
      })

      return resultArray
    },

    getMenuItemsActiveById (menuItemId) {
      const resultArray = []
      if (!this.posMenuItemsActive || !menuItemId) return resultArray

      const categories = this.posMenuItemsActive || []

      categories.forEach(category => {
        const items = category?.Items || []
        items.forEach(menu => {
          if (parseInt(menu.Id) === parseInt(menuItemId)) {
            resultArray.push(menu)
          }
        })
      })

      return resultArray
    },

    getCatalogMenuItems (catalog) {
      if (!catalog) return null

      const menuItems = JSON.parse(JSON.stringify(window.posMenu))
      menuItems.items = menuItems.items
        .map(item => {
          item.Items = item.Items.filter(menu => menu.ActivePos && menu?.Catalogs?.includes(catalog.Id))
          return item
        })
        .filter(item => item.Category.Status && item.Category?.Catalogs?.includes(catalog.Id) && item.Items.length)

      menuItems.categories = menuItems.categories
        .filter(category => category.Status && category?.Catalogs?.includes(catalog.Id) && menuItems.items.find(menu => menu.Category.Id === category.Id))

      if (this.activeCatalog?.Id !== catalog.Id) {
        const activeCategory = menuItems.categories[0] || null
        this.getCategoryDetails(activeCategory, menuItems)
        this.activeCatalog = catalog
      }

      return menuItems
    }
  },
  computed: {
    activeMenuItemsTags () {
      const AppTags = this.appConfig?.TAGS || []
      const tags = []

      this.posMenuItemsActive.forEach(category => {
        const items = category?.Items || []
        items.forEach(menu => {
          const menuItemTags = menu?.DetailsData?.Tags || []

          menuItemTags.forEach(tagId => {
            const tag = AppTags.find(appTag => appTag.Id === tagId && appTag.Active && appTag.Visible && !appTag.System)
            if (tag && !tags.find(tag => tag.Id === tagId)) tags.push(clone(tag))
          })
        })
      })

      return tags
    },

    posMenuItems () {
      if (!this.lastPosMenuUpdate || !this.posCart || !window?.posMenu?.items || !window?.posMenu?.categories) return null

      const activeCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => catalog.Status) || []
      const selectedSourceType = parseInt(this.posCart?.SourceTypeId || this.appConfig?.SOURCE_TYPES?.DEFAULT?.POS?.source_type_id || 5)
      const selectedOrderType = parseInt(this.posCart?.OrderType || 1)
      let selectedCatalog = null

      if (this.selectedCatalog && !SourceTypeEnum.EXCLUDE_MULTIPLE_CATALOGS.includes(selectedSourceType)) {
        selectedCatalog = this.selectedCatalog
      } else {
        selectedCatalog =
          activeCatalogs.find(catalog => (catalog.SourceTypes.includes(selectedSourceType) || catalog.SourceTypes.includes(0)) && parseInt(catalog.OrderType) === parseInt(selectedOrderType) && !catalog.Default) ||
          activeCatalogs.find(catalog => (catalog.SourceTypes.includes(selectedSourceType) || catalog.SourceTypes.includes(0)) && parseInt(catalog.OrderType) === 0 && !catalog.Default) ||
          activeCatalogs.find(catalog => catalog.Default)
      }

      return this.getCatalogMenuItems(selectedCatalog)
    }
  }
}
