import moment from 'moment'

/**
 *
 * @param s             Date or Time or DateTime String
 * @param fromFormat    Convert from format eg. 'DD-MM-YYYY HH:mm:ss'
 * @param toFormat      Convert to format eg. 'HH:mm:ss'
 * @returns {string}
 */
function dateTime (s, toFormat = 'HH:mm:ss', fromFormat = 'DD-MM-YYYY HH:mm:ss') {
  return moment(s, fromFormat).format(toFormat)
}

export default dateTime
