export default {
  data () {
    return {
      PlansEnum: {
        GetOnline     : 10,
        GetContactless: 11,
        GetPos        : 30,
        GetSmart      : 60,
        GetEnterprise : 1
      }
    }
  },
  computed: {
    PlanIds () {
      return Object.values(this.PlansEnum)
    }
  }
}
