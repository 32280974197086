<template>
  <v-row
    v-if="paymentMethod"
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar :color="paymentMethod.Color1">
            <v-icon dark>
              {{ paymentMethod.Icon }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Payment.Edit.Toolbar.Title') }} {{ paymentMethod.ModuleTitle }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Payment.Edit.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
              height="40"
              slider-color="blue"
            >
              <v-tab key="basic">
                {{ $t('Common.Misc.Details').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab
                key="extra"
                :disabled="!paymentMethod.ExtraFields.length"
              >
                {{ $t('Settings.Payment.ListView.Extra').toLocaleUpperCase($i18n.locale) }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="paymentMethodForm"
            v-model="paymentMethodFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="tab">
              <v-tab-item key="basic">
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <template v-if="selectedLanguages.length > 1">
                        <v-tabs
                          v-model="languageTab"
                          :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                          grow
                          height="40"
                          slider-color="blue"
                        >
                          <template v-for="lang in selectedLanguages">
                            <v-tab
                              :key="lang.Code"
                              :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                            >
                              <v-avatar
                                v-if="lang.ImageUrl"
                                class="mr-2"
                                size="20"
                              >
                                <v-img :src="lang.ImageUrl" />
                              </v-avatar>
                              {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                            </v-tab>
                          </template>
                        </v-tabs>
                      </template>
                      <v-tabs-items v-model="languageTab">
                        <template v-for="lang in selectedLanguages">
                          <v-tab-item
                            :key="lang.Code"
                            eager
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="paymentMethod.Lang[lang.Code].Name"
                                  :hint="$t('Settings.Payment.Edit.Field.SiteTitle.Hint')"
                                  :label="$t('Settings.Payment.Edit.Field.SiteTitle.Label')"
                                  required
                                />
                              </v-col>
                            </v-row>
                          </v-tab-item>
                        </template>
                      </v-tabs-items>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col
                      sm="3"
                      cols="12"
                    >
                      <v-text-field
                        v-model="paymentMethod.MinOrderTotal"
                        :hint="$t('Settings.Payment.Edit.Field.MinTotal.Hint')"
                        :label="$t('Settings.Payment.Edit.Field.MinTotal.Label')"
                        required
                      />
                    </v-col>
                    <v-col
                      sm="3"
                      cols="12"
                    >
                      <v-text-field
                        v-model="paymentMethod.Priority"
                        :hint="$t('Settings.Payment.Edit.Field.Priority.Hint')"
                        :label="$t('Settings.Payment.Edit.Field.Priority.Label')"
                        type="number"
                        step="1"
                        required
                      />
                    </v-col>
                    <v-col
                      sm="6"
                      cols="12"
                    >
                      <div class="d-inline-block mx-4">
                        <p class="grey--text text-caption ma-0">
                          {{ $t('Settings.Payment.ListView.UsedPos') }}
                        </p>
                        <v-switch
                          v-model="paymentMethod.Pos"
                          inset
                          :disabled="!paymentMethod.CanPos"
                          :label="paymentMethod.Pos ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                          class="ma-0"
                          color="orange"
                        />
                      </div>

                      <div class="d-inline-block mx-4">
                        <p class="grey--text text-caption ma-0">
                          {{ $t('Settings.Payment.ListView.UsedWebsite') }}
                        </p>
                        <v-switch
                          v-model="paymentMethod.Site"
                          inset
                          :disabled="!paymentMethod.CanSite"
                          :label="paymentMethod.Site ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                          class="ma-0"
                          color="blue"
                        />
                      </div>

                      <div
                        v-if="paymentMethod.SupportsTip"
                        class="d-inline-block mx-4"
                      >
                        <p class="grey--text text-caption ma-0">
                          {{ $t('Settings.Payment.ListView.Tips') }}
                        </p>
                        <v-switch
                          v-model="paymentMethod.Tip"
                          inset
                          :label="paymentMethod.Tip ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                          class="ma-0"
                          color="purple"
                        />
                      </div>
                    </v-col>

                    <template v-if="userIsSuperAdmin">
                      <v-col
                        sm="6"
                        cols="12"
                      >
                        <v-select
                          v-model="paymentMethod.OrderStatus"
                          :items="$te('Settings.Payment.Edit.Field.OrderStatus.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatus.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatus.Items') : []"
                          :label="$t('Settings.Payment.Edit.Field.OrderStatus.Label')"
                          item-value="Id"
                          item-text="Name"
                          clearable
                        />
                      </v-col>
                      <v-col
                        sm="6"
                        cols="12"
                      >
                        <v-select
                          v-model="paymentMethod.OrderStatusPos"
                          :items="$te('Settings.Payment.Edit.Field.OrderStatusPos.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatusPos.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatusPos.Items') : []"
                          :label="$t('Settings.Payment.Edit.Field.OrderStatusPos.Label')"
                          item-value="Id"
                          item-text="Name"
                          clearable
                        />
                      </v-col>

                      <v-col
                        sm="4"
                        cols="12"
                      >
                        <select-icon-input
                          v-model="paymentMethod.Icon"
                          editable
                        />
                      </v-col>
                      <v-col
                        sm="4"
                        cols="12"
                      >
                        <v-text-field
                          v-model="paymentMethod.Color1"
                          :label="$t('Settings.Payment.Edit.Field.Label.PrimaryColor')"
                          required
                        />
                      </v-col>
                      <v-col
                        sm="4"
                        cols="12"
                      >
                        <v-text-field
                          v-model="paymentMethod.Color2"
                          :label="$t('Settings.Payment.Edit.Field.Label.SecondaryColor')"
                          required
                        />
                      </v-col>
                    </template>

                    <v-col
                      v-if="userIsSuperAdmin"
                      cols="12"
                    >
                      <div
                        class="d-inline-block"
                      >
                        <p class="grey--text text-caption ma-0">
                          {{ $t('Settings.Payment.Edit.Field.Label.Method') }}
                        </p>
                        <v-switch
                          v-model="paymentMethod.Status"
                          inset
                          :label="paymentMethod.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                          class="ma-0 mr-4"
                          color="success"
                        />
                      </div>

                      <div
                        class="d-inline-block"
                      >
                        <p class="grey--text text-caption ma-0">
                          Pos
                        </p>
                        <v-switch
                          v-model="paymentMethod.CanPos"
                          inset
                          :label="paymentMethod.CanPos ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          class="ma-0 mr-4"
                          color="orange"
                          @change="onCanPosChange"
                        />
                      </div>

                      <div
                        class="d-inline-block"
                      >
                        <p class="grey--text text-caption ma-0">
                          WebSite
                        </p>
                        <v-switch
                          v-model="paymentMethod.CanSite"
                          inset
                          :label="paymentMethod.CanSite ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          class="ma-0"
                          color="blue"
                          @change="onCanSiteChange"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="paymentMethod.ExtraFields.length > 0"
                key="extra"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <template v-for="(field, i) in paymentMethod.ExtraFields">
                      <v-col
                        v-if="paymentMethod.Code !== 'viva_card_3ds' || (paymentMethod.Code === 'viva_card_3ds' && (userIsSuperAdmin || (!field.id.includes('sandbox_') && !field.id.includes('api_mode'))))"
                        :key="i"
                        sm="6"
                        cols="12"
                      >
                        <v-text-field
                          v-if="field.type === 'text'"
                          v-model="field.value"
                          :label="field.label"
                          :type="field.type"
                        />

                        <v-text-field
                          v-if="field.type === 'password'"
                          v-model="field.value"
                          :label="field.label"
                          :type="field.type"
                          autocomplete="new-password"
                        />

                        <v-radio-group
                          v-if="field.type === 'radio' && field.id !== 'api_mode'"
                          v-model="field.value"
                          row
                        >
                          <template v-for="(radio, r) in field.radios">
                            <v-radio
                              :key="r"
                              :label="radio.label"
                              :value="radio.value"
                            />
                          </template>
                        </v-radio-group>
                      </v-col>
                    </template>

                    <template v-for="(field, i) in paymentMethod.ExtraFields">
                      <v-col
                        v-if="field.id === 'api_mode'"
                        :key="`webhooks-${i}`"
                        cols="12"
                      >
                        <v-radio-group
                          v-if="field.type === 'radio'"
                          v-model="field.value"
                          row
                        >
                          <template v-for="(radio, r) in field.radios">
                            <v-radio
                              :key="r"
                              :label="radio.label"
                              :value="radio.value"
                            />
                          </template>
                        </v-radio-group>

                        <template v-if="paymentMethod.WebHooks[field.value === '1' ? 'sandbox' : 'live'] && paymentMethod.WebHooks[field.value === '1' ? 'sandbox' : 'live'].length">
                          <div class="text-caption font-weight-bold">
                            Web Hooks
                          </div>
                          <v-divider />
                          <template v-for="(webhook, index) in paymentMethod.WebHooks[field.value === '1' ? 'sandbox' : 'live']">
                            <div
                              :key="`webhook-${index}`"
                              class="mt-2"
                            >
                              <div
                                class="text-caption font-weight-bold"
                                :class="`${webhook.Color}--text`"
                              >
                                {{ webhook.Label }}
                              </div>
                              <div class="text-caption">
                                <v-btn
                                  class="ma-0 text-lowercase"
                                  :color="webhook.Color"
                                  text
                                  small
                                  @click="copyToClipboard(webhook.Url)"
                                >
                                  <v-icon
                                    color="primary"
                                    small
                                    left
                                  >
                                    far fa-copy
                                  </v-icon>
                                  <span class="grey--text text--darken-2">{{ webhook.Url }}</span>
                                </v-btn>
                              </div>
                            </div>
                          </template>
                        </template>

                        <template v-if="paymentMethod.WebApps[field.value === '1' ? 'sandbox' : 'live'] && paymentMethod.WebApps[field.value === '1' ? 'sandbox' : 'live'].length">
                          <div class="text-caption font-weight-bold mt-3">
                            Web Apps
                          </div>
                          <v-divider />
                          <template v-for="(webapp, index) in paymentMethod.WebApps[field.value === '1' ? 'sandbox' : 'live']">
                            <div
                              :key="`webapp-${index}`"
                              class="mt-2"
                            >
                              <div
                                class="text-caption font-weight-bold"
                                :class="`${webapp.Color}--text`"
                              >
                                {{ webapp.Label }}
                              </div>
                              <div class="text-caption">
                                <v-btn
                                  class="ma-0 text-lowercase"
                                  :color="webapp.Color"
                                  text
                                  small
                                  @click="copyToClipboard(webapp.Url)"
                                >
                                  <v-icon
                                    color="primary"
                                    small
                                    left
                                  >
                                    far fa-copy
                                  </v-icon>
                                  <span class="grey--text text--darken-2">{{ webapp.Url }}</span>
                                </v-btn>
                              </div>
                            </div>
                          </template>
                        </template>
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData         from '@/mixins/appdata'
import Auth            from '@/mixins/auth'
import SelectIconInput from '@/components/common/icon/SelectIconInput.vue'

export default {
  name      : 'PaymentEditDialog',
  components: { SelectIconInput },
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    paymentMethod: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      tab                    : 'basic',
      languageTab            : 0,
      paymentMethodFormValid : false,
      selectIconDialogVisible: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        const langObj = {}
        for (const lang of this.selectedLanguages) {
          langObj[lang.Code] = {
            Name       : '',
            Description: '',
            Locale     : lang.Code
          }
        }
        this.paymentMethod.Lang = Object.assign({}, langObj, this.paymentMethod.Lang)
      }
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    onCanPosChange (val) {
      if (!val) this.paymentMethod.Pos = false
    },

    onCanSiteChange (val) {
      if (!val) this.paymentMethod.Site = false
    },

    onSaveButtonClick () {
      if (this.paymentMethod.Code === 'viva_card_3ds' && !this.userIsSuperAdmin) {
        const index = this.paymentMethod.ExtraFields.findIndex(field => field.id === 'api_mode')
        if (index > -1) this.paymentMethod.ExtraFields[index].value = '2'
      }
      this.paymentMethod.Title = this.paymentMethod.Lang[this.defaultLanguage.Code].Name
      this.$emit('payment-method-save', this.paymentMethod)
    },

    onCancelButtonClick () {
      if (this.$refs.paymentMethodForm) this.$refs.paymentMethodForm.resetValidation()
      this.isVisible = false
    }
  }
}
</script>
