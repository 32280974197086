<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Regional.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Regional.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Regional.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Regional.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-container
          fluid
          class="pa-0"
        >
          <v-row dense>
            <v-col cols="12">
              <v-card flat>
                <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                  <v-icon class="pr-3">
                    {{ $t('Settings.Regional.Region.Icon') }}
                  </v-icon>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Regional.Region.Title') }}
                  </h5>
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row
                    align="center"
                  >
                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Region.Label') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Region.Subtitle') }}</span>
                    </v-col>

                    <v-col
                      sm="6"
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="viewData.Regional.SelectedCountry"
                        :disabled="!userCanManage"
                        :items="viewData.Regional.Countries"
                        :label="$t('Settings.Regional.Region.Country.Label')"
                        cache-items
                        chips
                        hide-details
                        item-text="name"
                        item-value="code"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <img :src="data.item.image">
                            </v-avatar>
                            {{ data.item.name }}
                            <template v-if="data.item.currency">
                              <span class="mx-2">/</span> <span class="text-caption grey--text text--darken-1">{{ data.item.currency.name }} {{ data.item.currency.symbol }}</span>
                            </template>
                          </v-chip>
                        </template>

                        <template #item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.image">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.name" />
                            <template v-if="data.item.currency">
                              <v-list-item-subtitle class="text-caption grey--text">
                                {{ data.item.currency.name }} {{ data.item.currency.symbol }}
                              </v-list-item-subtitle>
                            </template>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col
                      sm="6"
                      cols="12"
                    >
                      <v-select
                        v-model="viewData.Regional.SelectedTimezone"
                        :disabled="!userCanManage"
                        :items="timeZonesList"
                        :label="$t('Settings.Regional.Region.Zone.Label')"
                        chips
                        hide-details
                        item-text="label"
                        item-value="value"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar
                              class="font-weight-bold mr-2"
                              color="blue white--text"
                              style="font-size: 12px;"
                            >
                              {{ data.item.avatar }}
                            </v-avatar>
                            {{ data.item.label }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <v-list-item-avatar
                            class="font-weight-bold"
                            color="blue white--text"
                          >
                            {{ data.item.avatar }}
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.label" />
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              lg="6"
              cols="12"
            >
              <v-card flat>
                <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                  <v-icon class="pr-3">
                    {{ $t('Settings.Regional.Website.Icon') }}
                  </v-icon>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Regional.Website.Title') }}
                  </h5>
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row
                    align="center"
                  >
                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Website.Available.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Website.Available.Subtitle') }}</span>

                      <v-select
                        v-model="viewData.Website.SelectedLanguages"
                        :disabled="!userCanManage"
                        :items="viewData.Website.LanguageList"
                        :label="$t('Settings.Regional.Website.Available.Label')"
                        :menu-props="{closeOnClick: false, closeOnContentClick: true, openOnClick: false, maxHeight: 300}"
                        chips
                        hide-details
                        hide-selected
                        item-text="Name"
                        item-value="Id"
                        multiple
                      >
                        <template #selection="data">
                          <v-chip
                            :close="viewData.Website.SelectedLanguages.length > 1"
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                            @input="remove(data.item)"
                          >
                            <v-avatar class="mr-2">
                              <img :src="data.item.ImageUrl">
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.ImageUrl">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.Name" />
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Website.Default.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Website.Default.Subtitle') }}</span>
                      <v-select
                        v-model="viewData.Website.DefaultLanguage"
                        :disabled="!userCanManage"
                        :items="viewData.Website.LanguageList"
                        :label="$t('Settings.Regional.Website.Default.Label')"
                        chips
                        hide-details
                        item-text="Name"
                        item-value="Id"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <img :src="data.item.ImageUrl">
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.ImageUrl">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.Name" />
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              lg="6"
              cols="12"
            >
              <v-card flat>
                <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                  <v-icon class="pr-3">
                    {{ $t('Settings.Regional.Pos.Icon') }}
                  </v-icon>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Regional.Pos.Title') }}
                  </h5>
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row
                    align="center"
                  >
                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Pos.Default.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Pos.Default.Subtitle') }}</span>
                      <v-select
                        v-model="viewData.Pos.DefaultLanguage"
                        :disabled="!userCanManage"
                        :items="viewData.Pos.LanguageList"
                        :label="$t('Settings.Regional.Pos.Default.Label')"
                        chips
                        hide-details
                        item-text="Name"
                        item-value="Id"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <img :src="data.item.ImageUrl">
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.ImageUrl">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.Name" />
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card flat>
                <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                  <v-icon class="pr-3">
                    {{ $t('Settings.Regional.Tax.Icon') }}
                  </v-icon>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Regional.Tax.Title') }}
                  </h5>
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Tax.Default.Vat.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Tax.Default.Vat.Subtitle') }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="viewData.Taxes.Default.TaxId"
                        :disabled="!userCanManage"
                        :items="viewData.TaxRates"
                        :label="$t('Settings.Regional.Tax.Reset.TaxId.Label')"
                        chips
                        hide-details
                        item-text="Name"
                        item-value="Id"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <v-icon>percent</v-icon>
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-if="appSupportSecondTax"
                        v-model="viewData.Taxes.Default.Tax2Id"
                        :disabled="!userCanManage"
                        :items="viewData.TaxRates"
                        :label="$t('Settings.Regional.Tax.Reset.Tax2Id.Label')"
                        chips
                        hide-details
                        item-text="Name"
                        item-value="Id"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <v-icon>percent</v-icon>
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-divider class="mb-3" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Tax.Default.Classification.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Tax.Default.Classification.Subtitle') }}</span>
                    </v-col>

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Documents.OrderReceipt.Document.Receipt') }}
                      </h5>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="viewData.Classifications.Default.ClassificationCategoryCode"
                        :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                        :items="incomeClassificationCategoryCodeList"
                        :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                        item-text="Name"
                        item-value="Code"
                        persistent-hint
                        required
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            label
                            color="grey darken-1"
                            dark
                          >
                            <span>{{ item.Code }}</span>
                          </v-chip>

                          <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                        </template>

                        <template #item="{item}">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.Code }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="viewData.Classifications.Default.ClassificationTypeCode"
                        :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                        :items="getReceiptIncomeClassificationTypesByCategoryCode(viewData.Classifications.Default.ClassificationCategoryCode)"
                        :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                        item-text="Name"
                        item-value="Code"
                        persistent-hint
                        required
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            label
                            color="grey darken-1"
                            dark
                          >
                            <span>{{ item.Code }}</span>
                          </v-chip>

                          <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                        </template>

                        <template #item="{item}">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.Code }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold mt-2">
                        {{ $t('Settings.Documents.OrderReceipt.Document.Invoice') }}
                      </h5>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="viewData.Classifications.Default.ClassificationCategoryCodeInvoice"
                        :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                        :items="incomeClassificationCategoryCodeList"
                        :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                        item-text="Name"
                        item-value="Code"
                        persistent-hint
                        required
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            label
                            color="grey darken-1"
                            dark
                          >
                            <span>{{ item.Code }}</span>
                          </v-chip>

                          <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                        </template>

                        <template #item="{item}">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.Code }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="viewData.Classifications.Default.ClassificationTypeCodeInvoice"
                        :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                        :items="getInvoiceIncomeClassificationTypesByCategoryCode(viewData.Classifications.Default.ClassificationCategoryCodeInvoice)"
                        :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                        item-text="Name"
                        item-value="Code"
                        persistent-hint
                        required
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            label
                            color="grey darken-1"
                            dark
                          >
                            <span>{{ item.Code }}</span>
                          </v-chip>

                          <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                        </template>

                        <template #item="{item}">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.Code }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-divider class="mb-3" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Tax.Extension.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Tax.Extension.Subtitle') }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Tax.Extension.Coupon.Title') }}
                      </h5>
                      <v-select
                        v-model="viewData.Taxes.Coupon.TaxId"
                        :disabled="!userCanManage"
                        :items="viewData.TaxRates"
                        :label="$t('Settings.Regional.Tax.Extension.Coupon.Label')"
                        chips
                        hide-details
                        item-text="Name"
                        item-value="Id"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <v-icon>percent</v-icon>
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Tax.Extension.Delivery.Title') }}
                      </h5>
                      <v-select
                        v-model="viewData.Taxes.Delivery.TaxId"
                        :disabled="!userCanManage"
                        :items="viewData.TaxRates"
                        :label="$t('Settings.Regional.Tax.Extension.Delivery.Label')"
                        chips
                        hide-details
                        item-text="Name"
                        item-value="Id"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <v-icon>percent</v-icon>
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Tax.Extension.TipAmount.Title') }}
                      </h5>
                      <v-select
                        v-model="viewData.Taxes.TipAmount.TaxId"
                        :disabled="!userCanManage"
                        :items="viewData.TaxRates"
                        :label="$t('Settings.Regional.Tax.Extension.TipAmount.Label')"
                        chips
                        hide-details
                        item-text="Name"
                        item-value="Id"
                      >
                        <template #selection="data">
                          <v-chip
                            :value="data.selected"
                            class="chip--select-multi pl-0"
                            small
                          >
                            <v-avatar class="mr-2">
                              <v-icon>percent</v-icon>
                            </v-avatar>
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Regional.Tax.Reset.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.Regional.Tax.Reset.Subtitle') }}</span>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="viewData.Taxes.Reset.ForceUpdate"
                        inset
                        :disabled="!userCanManage"
                        :label="viewData.Taxes.Reset.ForceUpdate ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                        color="red"
                        class="mt-0 pt-0"
                        hide-details
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                    />

                    <template v-if="viewData.Taxes.Reset.ForceUpdate">
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-select
                          v-model="viewData.Taxes.Reset.TaxId"
                          :disabled="!userCanManage"
                          :items="viewData.TaxRates"
                          :label="$t('Settings.Regional.Tax.Reset.TaxId.Label')"
                          chips
                          hide-details
                          item-text="Name"
                          item-value="Id"
                          clearable
                        >
                          <template #selection="data">
                            <v-chip
                              :value="data.selected"
                              class="chip--select-multi pl-0"
                              small
                            >
                              <v-avatar class="mr-2">
                                <v-icon>percent</v-icon>
                              </v-avatar>
                              {{ data.item.Name }}
                            </v-chip>
                          </template>
                        </v-select>

                        <v-select
                          v-if="appSupportSecondTax"
                          v-model="viewData.Taxes.Reset.Tax2Id"
                          :disabled="!userCanManage"
                          :items="viewData.TaxRates"
                          :label="$t('Settings.Regional.Tax.Reset.Tax2Id.Label')"
                          class="mt-4"
                          chips
                          hide-details
                          item-text="Name"
                          item-value="Id"
                          clearable
                        >
                          <template #selection="data">
                            <v-chip
                              :value="data.selected"
                              class="chip--select-multi pl-0"
                              small
                            >
                              <v-avatar class="mr-2">
                                <v-icon>percent</v-icon>
                              </v-avatar>
                              {{ data.item.Name }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="8"
                      >
                        <v-select
                          v-model="viewData.Taxes.Reset.Categories"
                          :item-text="(data) => getTranslatedField(data, 'Name')"
                          :items="viewData.Categories"
                          :label="$t('Settings.Regional.Tax.Reset.Categories.Label')"
                          item-value="Id"
                          chips
                          clearable
                          multiple
                          required
                          hide-details
                        >
                          <template #prepend-item>
                            <v-list-item
                              ripple
                              @click="toggleCategories"
                            >
                              <v-list-item-action>
                                <v-icon>
                                  {{ selectAllIcon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-title>Select All</v-list-item-title>
                            </v-list-item>
                            <v-divider />
                          </template>

                          <template #selection="data">
                            <v-chip
                              :key="data.item.Id"
                              :style="'background-color:#' + (data.item.Color ? data.item.Color : 'e0e0e0') + '; color: ' + (invertColor(data.item.Color ? data.item.Color : 'e0e0e0', true)) + ';'"
                              class="chip--select-multi"
                              small
                              label
                              @input="data.parent.selectItem(data.item)"
                            >
                              {{ getTranslatedField(data.item, 'Name') }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>

                    <template v-if="appSupportIncomeClassifications">
                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ $t('Settings.Regional.Classifications.Reset.Title') }}
                        </h5>
                        <span class="text-caption">{{ $t('Settings.Regional.Classifications.Reset.Subtitle') }}</span>
                      </v-col>

                      <v-col class="shrink">
                        <v-switch
                          v-model="viewData.Classifications.Reset.ForceUpdate"
                          inset
                          :disabled="!userCanManage"
                          :label="viewData.Classifications.Reset.ForceUpdate ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                          color="red"
                          class="mt-0 pt-0"
                          hide-details
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        class="pa-0 ma-0"
                      />

                      <template v-if="viewData.Classifications.Reset.ForceUpdate">
                        <v-col cols="12">
                          <v-select
                            v-model="viewData.Classifications.Reset.Categories"
                            :item-text="(data) => getTranslatedField(data, 'Name')"
                            :items="viewData.Categories"
                            :label="$t('Settings.Regional.Tax.Reset.Categories.Label')"
                            item-value="Id"
                            chips
                            clearable
                            multiple
                            required
                            hide-details
                          >
                            <template #prepend-item>
                              <v-list-item
                                ripple
                                @click="toggleClassificationCategories"
                              >
                                <v-list-item-action>
                                  <v-icon>
                                    {{ selectAllClassificationsIcon }}
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-title>Select All</v-list-item-title>
                              </v-list-item>
                              <v-divider />
                            </template>

                            <template #selection="data">
                              <v-chip
                                :key="data.item.Id"
                                :style="'background-color:#' + (data.item.Color ? data.item.Color : 'e0e0e0') + '; color: ' + (invertColor(data.item.Color ? data.item.Color : 'e0e0e0', true)) + ';'"
                                class="chip--select-multi"
                                small
                                label
                                @input="data.parent.selectItem(data.item)"
                              >
                                {{ getTranslatedField(data.item, 'Name') }}
                              </v-chip>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          class="pr-sm-3"
                        >
                          <h5 class="text-body-2 font-weight-bold mt-4">
                            {{ $t('Settings.Documents.OrderReceipt.Document.Receipt') }}
                          </h5>

                          <v-select
                            v-model="viewData.Classifications.Reset.ClassificationCategoryCodeInvoice"
                            :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                            :items="incomeClassificationCategoryCodeList"
                            :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                            class="mt-2"
                            item-text="Name"
                            item-value="Code"
                            persistent-hint
                            clearable
                            required
                          >
                            <template #selection="{item}">
                              <v-chip
                                small
                                label
                                color="grey darken-1"
                                dark
                              >
                                <span>{{ item.Code }}</span>
                              </v-chip>

                              <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                            </template>

                            <template #item="{item}">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.Code }}</v-list-item-title>
                                <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>

                          <v-select
                            v-model="viewData.Classifications.Reset.ClassificationTypeCode"
                            :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                            :items="getReceiptIncomeClassificationTypesByCategoryCode(viewData.Classifications.Reset.ClassificationCategoryCode)"
                            :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                            class="mt-4"
                            item-text="Name"
                            item-value="Code"
                            persistent-hint
                            clearable
                            required
                          >
                            <template #selection="{item}">
                              <v-chip
                                small
                                label
                                color="grey darken-1"
                                dark
                              >
                                <span>{{ item.Code }}</span>
                              </v-chip>

                              <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                            </template>

                            <template #item="{item}">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.Code }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          class="pl-sm-3"
                        >
                          <h5 class="text-body-2 font-weight-bold mt-4">
                            {{ $t('Settings.Documents.OrderReceipt.Document.Invoice') }}
                          </h5>

                          <v-select
                            v-model="viewData.Classifications.Reset.ClassificationCategoryCode"
                            :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                            :items="incomeClassificationCategoryCodeList"
                            :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                            class="mt-2"
                            item-text="Name"
                            item-value="Code"
                            persistent-hint
                            clearable
                            required
                          >
                            <template #selection="{item}">
                              <v-chip
                                small
                                label
                                color="grey darken-1"
                                dark
                              >
                                <span>{{ item.Code }}</span>
                              </v-chip>

                              <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                            </template>

                            <template #item="{item}">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.Code }}</v-list-item-title>
                                <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>

                          <v-select
                            v-model="viewData.Classifications.Reset.ClassificationTypeCodeInvoice"
                            :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                            :items="getInvoiceIncomeClassificationTypesByCategoryCode(viewData.Classifications.Reset.ClassificationCategoryCodeInvoice)"
                            :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                            class="mt-4"
                            item-text="Name"
                            item-value="Code"
                            persistent-hint
                            clearable
                            required
                          >
                            <template #selection="{item}">
                              <v-chip
                                small
                                label
                                color="grey darken-1"
                                dark
                              >
                                <span>{{ item.Code }}</span>
                              </v-chip>

                              <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                            </template>

                            <template #item="{item}">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.Code }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </v-col>
                      </template>

                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-alert
                :value="isLangDirty"
                class="pa-2 mx-4"
                color="error"
                icon="warning"
                outlined
              >
                {{ $t('Settings.Regional.Warning') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="saveData"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData       from '@/mixins/appdata'
import Auth          from '@/mixins/auth'
import Translatable  from '@/mixins/translatable'
import TaxesCommon   from '@/mixins/taxes/taxesCommon'
import { mergeDeep } from '@/lib/helper/helper'

export default {
  name      : 'SystemSettingsRegional',
  components: {},
  directives: {},
  mixins    : [AppData, Auth, TaxesCommon, Translatable],
  props     : {},
  data () {
    return {
      isLangDirty                     : false,
      isSaving                        : false,
      viewData                        : null,
      PosDefaultLanguageOriginal      : null,
      WebsiteDefaultLanguageOriginal  : null,
      WebsiteSelectedLanguagesOriginal: null
    }
  },
  computed: {
    timeZonesList () {
      return this?.viewData?.Regional?.Countries?.find(country => country.code === this.viewData.Regional.SelectedCountry)?.timezones || []
    },
    allCategoriesSelected () {
      return this.viewData?.Taxes?.Reset?.Categories?.length === this.viewData.Categories.length
    },
    someCategoriesSelected () {
      return this.viewData?.Taxes?.Reset?.Categories?.length > 0 && !this.allCategoriesSelected
    },
    allClassificationCategoriesSelected () {
      return this.viewData?.Classifications?.Reset?.Categories?.length === this.viewData.Categories.length
    },
    someClassificationCategoriesSelected () {
      return this.viewData?.Classifications?.Reset?.Categories?.length > 0 && !this.allClassificationCategoriesSelected
    },
    selectAllIcon () {
      if (this.allCategoriesSelected) return 'check_box'
      if (this.someCategoriesSelected) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    },
    selectAllClassificationsIcon () {
      if (this.allClassificationCategoriesSelected) return 'check_box'
      if (this.someClassificationCategoriesSelected) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    }
  },
  watch: {
    'viewData.Regional.SelectedCountry': function (newVal) {
      if (this.timeZonesList.length && newVal) {
        this.viewData.Regional.SelectedTimezone = this.timeZonesList[0].value
      } else {
        this.viewData.Regional.SelectedTimezone = ''
      }
    },
    'viewData.Pos.DefaultLanguage': function (newVal) {
      this.isLangDirty = newVal !== this.PosDefaultLanguageOriginal || this.viewData.Website.DefaultLanguage !== this.WebsiteDefaultLanguageOriginal || !this.arrayEquals(this.viewData.Website.SelectedLanguages, this.WebsiteSelectedLanguagesOriginal)
    },
    'viewData.Website.DefaultLanguage': function (newVal) {
      this.isLangDirty = newVal !== this.WebsiteDefaultLanguageOriginal || this.viewData.Pos.DefaultLanguage !== this.PosDefaultLanguageOriginal || !this.arrayEquals(this.viewData.Website.SelectedLanguages, this.WebsiteSelectedLanguagesOriginal)
    },
    'viewData.Website.SelectedLanguages': function (newVal) {
      this.isLangDirty = !this.arrayEquals(newVal, this.WebsiteSelectedLanguagesOriginal) || this.viewData.Pos.DefaultLanguage !== this.PosDefaultLanguageOriginal || this.viewData.Website.DefaultLanguage !== this.WebsiteDefaultLanguageOriginal

      if (newVal.length === 1) {
        this.viewData.Website.DefaultLanguage = newVal[0]
      } else if (newVal.length === 0) {
        this.viewData.Website.DefaultLanguage = null
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Regional.Get, this.setData)
    this.$bus.$on(window.SocketCommand.Settings.Regional.Save, this.onSaveResult)
    this.getData()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Regional.Get, this.setData)
    this.$bus.$off(window.SocketCommand.Settings.Regional.Save, this.onSaveResult)
  },
  destroyed () {
  },
  methods: {
    toggleCategories () {
      this.$nextTick(() => {
        if (this.allCategoriesSelected) {
          this.viewData.Taxes.Reset.Categories = []
        } else {
          this.viewData.Taxes.Reset.Categories = this.viewData.Categories.map(c => c.Id)
        }
      })
    },
    toggleClassificationCategories () {
      this.$nextTick(() => {
        if (this.allClassificationCategoriesSelected) {
          this.viewData.Classifications.Reset.Categories = []
        } else {
          this.viewData.Classifications.Reset.Categories = this.viewData.Categories.map(c => c.Id)
        }
      })
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.Regional.Get, {})
    },

    setData (data) {
      if (data) {
        this.PosDefaultLanguageOriginal = data?.Pos?.DefaultLanguage
        this.WebsiteDefaultLanguageOriginal = data?.Website?.DefaultLanguage
        this.WebsiteSelectedLanguagesOriginal = data?.Website?.SelectedLanguages

        const ClassificationsDefault = {
          Default: {
            ClassificationCategoryCode: null,
            ClassificationTypeCode    : null
          }
        }

        const TaxesDefault = {
          Default: {
            TaxId : null,
            Tax2Id: null
          }
        }

        data.Classifications = mergeDeep(ClassificationsDefault, data.Classifications || {})
        data.Taxes = mergeDeep(TaxesDefault, data.Taxes || {})

        this.viewData = JSON.parse(JSON.stringify(data))
      }
    },

    saveData () {
      this.isSaving = true
      const saveData = JSON.parse(JSON.stringify(this.viewData))
      delete saveData.Categories
      delete saveData.TaxRates
      window.callAS(window.SocketCommand.Settings.Regional.Save, { Regional: saveData })
    },

    onSaveResult (data) {
      this.isSaving = false
      if (data && data.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Regional.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    remove (item) {
      if (!this.viewData) return
      const index = this.viewData.Website.SelectedLanguages.indexOf(item.Id)
      if (index >= 0) this.viewData.Website.SelectedLanguages.splice(index, 1)
    },

    arrayEquals (a, b) {
      return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
    },

    invertColor (hex, bw) {
      if (!hex) return '#FFFFFF'

      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      if (hex.length !== 6) {
        // eslint-disable-next-line no-console
        console.warn('Invalid HEX color.')
        return '#FFFFFF'
      }
      let r = parseInt(hex.slice(0, 2), 16)
      let g = parseInt(hex.slice(2, 4), 16)
      let b = parseInt(hex.slice(4, 6), 16)
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#323232'
          : '#FFFFFF'
      }
      // invert color components
      r = (255 - r).toString(16)
      g = (255 - g).toString(16)
      b = (255 - b).toString(16)
      // pad each with zeros and return
      return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
    }
  }
}
</script>

<style scoped>

</style>
