import Vue                            from 'vue'
import vuetify                        from '@/plugins/vuetify'
import { i18n, setI18nLanguage }      from './lang/lang'
import { EventBus }                   from './events/eventBus'
import greekUtils                     from './lib/greek-utils'
import router                         from './router/router'
import moment                         from 'moment'
import * as VueGoogleMaps             from 'vue2-google-maps'
import VueTimeago                     from './components/common/TimeAgo'
import Snotify                        from './components/common/vue-snotify/index'
import './components/common/vue-snotify/styles/material.css'
import AppData                        from './mixins/appdata'
import Auth                           from './mixins/auth'
import VueFilters                     from './filters/index'
import { API, APICall, APIEndPoints } from './api/Api'
import Storage                        from 'vue-web-storage'
import DataStorePlugin                from './lib/data/DataStore/Plugin/DataStorePlugin'
import Socket                         from './api/Socket'
import SocketData                     from './api/SocketData'
import SocketCommand                  from './api/SocketCommand'
import { AES, HmacMD5 }               from './lib/crypto/crypto'
import Meta                           from 'vue-meta'
import App                            from './components/App'
import isElectron                     from '@/electron/isElectron'
import ipcRendererElectron            from '@/electron/ipcRendererElectron'
import DataStore                      from './api/DataStore/DataStore'
import semver                         from 'semver'
import DefaultLayout                  from './layouts/DefaultLayout'
import LoginLayout                    from './layouts/LoginLayout'
import EmptyLayout                    from './layouts/EmptyLayout'
import LockedLayout                   from './layouts/LockedLayout'
import VueClipboard                   from 'vue-clipboard2'
import VueYouTubeEmbed                from 'vue-youtube-embed'
import VueLazyload                    from 'vue-lazyload'
import Croppa                         from 'vue-croppa'
import VueMask                        from 'v-mask'
import ipcCommandsEnum                from '@/electron/ipcCommandsEnum'
import { checkForCloudUpdate }        from '@/api/ApiUpdate'
import BarcodeScanListener            from '@/lib/barcode/BarcodeScanListener'
import BarcodePrefixEnum              from '@/api/Enums/BarcodePrefixEnum'
import MessagesModel                  from '@/api/Models/messages/MessagesModel'
import CartModel                      from '@/api/Models/pos/CartModel'
import SupportTicketCollection        from '@/api/Collections/support-tickets/SupportTicketCollection'
import SupportDepartmentsCollection   from '@/api/Collections/support-tickets/SupportDepartmentsCollection'
import SupportServicesCollection      from '@/api/Collections/support-tickets/SupportServicesCollection'
import SupportPrioritiesCollection    from '@/api/Collections/support-tickets/SupportPrioritiesCollection'
import SupportStatusesCollection      from '@/api/Collections/support-tickets/SupportStatusesCollection'
import 'vue-croppa/dist/vue-croppa.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'v-mapbox/dist/v-mapbox.css'

require('@openfonts/roboto_greek')

// eslint-disable-next-line no-console
console.log('isElectron: ', isElectron())

if (isElectron()) {
  const ipcRenderer = ipcRendererElectron()

  if (ipcRenderer) {
    /*
    TODO: Uncomment When new EXE version is Deployed to production.
    const data = ipcRenderer.sendSync(ipcCommandsEnum.AppSync)
    if (data) {
      DataStore.app.guid = data.guid
      DataStore.app.guid_old = data.guid_old
      DataStore.app.versionDesktop = 'v' + semver.valid(semver.coerce(data.version.version))
      DataStore.app.versionWeb = 'v' + semver.valid(semver.coerce(process.env.VUE_APP_VERSION))
      DataStore.app.computer = data.computer
    }
     */

    ipcRenderer.on(ipcCommandsEnum.App, (event, data) => {
      DataStore.app.guid = data.guid
      DataStore.app.guid_old = data.guid_old
      DataStore.app.versionDesktop = 'v' + semver.valid(semver.coerce(data.version.version))
      DataStore.app.versionWeb = 'v' + semver.valid(semver.coerce(process.env.VUE_APP_VERSION))
      DataStore.app.computer = data.computer
    })
    ipcRenderer.on(ipcCommandsEnum.AutoUpdater.CheckingForUpdate, (event, data) => {
      // eslint-disable-next-line no-console
      console.log(`>>>>> ${ ipcCommandsEnum.AutoUpdater.CheckingForUpdate }: `, event, data)
    })
    ipcRenderer.on(ipcCommandsEnum.AutoUpdater.UpdateAvailable, (event, data) => {
      // eslint-disable-next-line no-console
      console.log(`>>>>> ${ ipcCommandsEnum.AutoUpdater.UpdateAvailable }: `, event, data)
    })
    ipcRenderer.on(ipcCommandsEnum.AutoUpdater.UpdateNotAvailable, (event, data) => {
      // eslint-disable-next-line no-console
      console.log(`>>>>> ${ ipcCommandsEnum.AutoUpdater.UpdateNotAvailable }: `, event, data)

      Socket.connect()
    })
    ipcRenderer.on(ipcCommandsEnum.AutoUpdater.Error, (event, data) => {
      // eslint-disable-next-line no-console
      console.log(`>>>>> ${ ipcCommandsEnum.AutoUpdater.Error }: `, event, data)

      Socket.connect()
    })
    ipcRenderer.on(ipcCommandsEnum.AutoUpdater.Progress, (event, data) => {
      // eslint-disable-next-line no-console
      console.log(`>>>>> ${ ipcCommandsEnum.AutoUpdater.Progress }: `, event, data)
    })
    ipcRenderer.on(ipcCommandsEnum.AutoUpdater.UpdateDownloaded, (event, data) => {
      // eslint-disable-next-line no-console
      console.log(`>>>>> ${ ipcCommandsEnum.AutoUpdater.UpdateDownloaded }: `, event, data)
    })
  }
} else {
  DataStore.app.versionDesktop = 'v' + semver.valid(semver.coerce(process.env.VUE_APP_VERSION))
  DataStore.app.versionWeb = 'v' + semver.valid(semver.coerce(process.env.VUE_APP_VERSION))
}
// eslint-disable-next-line no-console
console.log('>>>>>APP::::: ', DataStore.app)

window.staffName = ''
window.authorizationToken = null
window.authorizationTokenBearer = null
window.UID = ''
window.Vue = null
window.API = API
window.APICall = APICall
window.APIEndPoints = APIEndPoints
window.API.defaults.baseURL = window.APIEndPoints.API
window.Socket = Socket
window.SocketData = SocketData
window.SocketCommand = SocketCommand
window.$bus = EventBus
window.isVueRoutesInited = false

let vm = null
// eslint-disable-next-line
let barcodeListener = null

window.callAS = (event, data = {}, resultEvent = '', timeout = parseInt(process.env.VUE_APP_API_TIMEOUT) || 60000, headers = {}, multipart = false) => {
  // SocketData.get(event, data, resultEvent)

  const payload = APICall.CreatePayload(event, data, resultEvent, multipart)

  if (!SocketData.apiCallIsAllowed(payload)) return

  const result = API.post(`${ APICall.api }/${ event }`, multipart ? payload.multipartData : payload, {
    timeout: timeout,
    headers: {
      ...headers,
      ...(multipart && { 'Content-Type': 'multipart/form-data' })
    }
  })

  result
    .then(response => {
      SocketData.set(payload.result_command, response.data, payload)
    })
    .catch(error => {
      const retObj = {
        error  : true,
        status : 0,
        message: '',
        payload: payload
      }

      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        retObj.status = error.response?.status
        retObj.message = error.response?.data?.message || ''
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in
        // the browser and an instance of http.ClientRequest in node.js
        retObj.status = 490 // Custom Error Code For Network Error
      }
      if (error.message) retObj.message = error.message
      vm.$bus.$emit(SocketCommand.commandToClientFormat(payload.result_command), retObj, payload)

      // eslint-disable-next-line no-console
      console.error(retObj)
    })

  return result
}

window.initSocket = async () => {
  const cloudUpdateExists = await checkForCloudUpdate()
  if (cloudUpdateExists) return

  if (isElectron()) {
    const ipcRenderer = ipcRendererElectron()
    setTimeout(() => {
      ipcRenderer && ipcRenderer.send(ipcCommandsEnum.CheckForUpdates)
    }, 1000)
  } else {
    Socket.connect()
  }
}

const initVUE = () => {
  Vue.component('DefaultLayout', DefaultLayout)
  Vue.component('LoginLayout', LoginLayout)
  Vue.component('EmptyLayout', EmptyLayout)
  Vue.component('LockedLayout', LockedLayout)

  Vue.use(DataStorePlugin)

  Vue.use(VueYouTubeEmbed, { global: false })

  Vue.use(Meta)

  Vue.use(Storage, {
    prefix : 'gfp_',
    drivers: ['session', 'local'] // default 'local'
  })

  VueClipboard.config.autoSetContainer = true
  Vue.use(VueClipboard)

  Vue.use(VueLazyload, {
    observer     : true,
    lazyComponent: true
  })

  Vue.use(Croppa)

  Vue.use(VueMask)

  Vue.mixin({
    methods: {
      copyToClipboard (textToCopy = '') {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(textToCopy).then(function () {
            EventBus.$bus.$emit('app-show-notification', {
              title  : 'Clipboard',
              body   : vm && vm.$t('Common.Misc.Copy'),
              type   : 'info',
              icon   : 'far fa-copy',
              timeout: 1000
            })
          }, function (e) {
          })
        } else {
          this.$copyText(textToCopy).then(function () {
            EventBus.$bus.$emit('app-show-notification', {
              title  : 'Clipboard',
              body   : vm && vm.$t('Common.Misc.Copy'),
              type   : 'info',
              icon   : 'far fa-copy',
              timeout: 1000
            })
          }, function (e) {
          })
        }
      }
    }
  })

  Vue.use(VueFilters)

  Vue.use(Snotify, {
    config: {
      timeout        : 3000,
      showProgressBar: true,
      pauseOnHover   : true,
      titleMaxLength : 28,
      bodyMaxLength  : 150,
      backdrop       : -1
    },
    options: {
      maxOnScreen  : 8,
      maxAtPosition: 8,
      maxHeight    : 500,
      newOnTop     : true,
      position     : 'centerTop'
    }
  })

  Vue.use(VueTimeago, { locale: process.env.VUE_APP_I18N_LOCALE })

  Vue.use(greekUtils)

  Vue.use(VueGoogleMaps)

  vm = new Vue({
    el    : '#app',
    i18n,
    mixins: [AppData, Auth],
    data () {
      return {
        AppOnlineStatus                 : false,
        OnlineOrderingStatus            : false,
        OrderTypeDefaultTimes           : null,
        AppMachinePrinters              : [],
        AppMachinePreparationPrinters   : [],
        AppMachineReceiptPrinters       : [],
        AppMachineReceiptTotalsZPrinters: [],
        AppPrinterGroups                : [],
        AppConfig                       : null,
        PosUser                         : null,

        callsQueueActive : [],
        callsQueueWaiting: [],
        callsQueueMissed : [],
        dashboardData    : null,

        ordersCounts: {
          daily_count : 0,
          future_count: 0,
          saved_count : 0
        },

        deliveryStaffItems: [],

        waiterStaffItems: []
      }
    },
    created () {
      window.Vue = this
      this.$bus.$on(window.SocketCommand.Pusher.IsOnline, this.onAppOnlineStatusChange)
      this.$bus.$on('app-reset-data', this.resetAppData)
      this.resetAppData()
    },
    async mounted () {
      this.$bus.$on(window.SocketCommand.Staff.Delivery.Shift.AddOrder, this.onAddOrderToDeliveryStaff)
    },
    beforeMount () {
      setI18nLanguage(process.env.VUE_APP_I18N_LOCALE)
      moment.suppressDeprecationWarnings = true

      if (this.$route?.meta?.requiresAuth) this.$router.replace({ name: 'Login' })

      const storageKey = HmacMD5.hash('ROUTE')

      router.afterEach((to, from, next) => {
        if (to.name !== 'Login' && to.name !== 'Cds' && to.name !== 'DomainLocked') {
          this.$sessionStorage.set(storageKey, AES.encrypt({
            name    : to.name,
            fullPath: to.fullPath,
            hash    : to.hash,
            meta    : to.meta,
            params  : to.params,
            path    : to.path,
            query   : to.query
          }))

          if (to.matched.length > 0) {
            this.$bus.$emit('toolbar-update-title', to.matched.map(route => route.meta.title).join(' - '))
          }
        }
      })

      router.beforeEach((to, from, next) => {
        const authUser = this.posUser

        if (from?.name === 'DomainLocked' && !from?.meta?.allowNavigation && to.name !== 'Login') {
          next(false)
          return
        }

        if (to.meta.requiresAuth) {
          if (!authUser) {
            next({ name: 'Login' })
          } else {
            next(this.posUserHasComponentPermission(to.name, 'ACCESS'))
          }
        } else {
          next()
        }
      })
    },
    beforeDestroy () {
      this.$bus.$off(window.SocketCommand.Staff.Delivery.Shift.AddOrder, this.onAddOrderToDeliveryStaff)
      this.$bus.$off(window.SocketCommand.Pusher.IsOnline, this.onAppOnlineStatusChange)
      this.$bus.$off('app-reset-data', this.resetAppData)
    },
    methods: {
      resetAppData () {
        window.appConfig = null
        window.posMenu = []

        this.AppOnlineStatus = false
        this.OnlineOrderingStatus = false
        this.OrderTypeDefaultTimes = null
        this.AppMachinePrinters = []
        this.AppMachinePreparationPrinters = []
        this.AppMachineReceiptPrinters = []
        this.AppMachineReceiptTotalsZPrinters = []
        this.AppPrinterGroups = []
        this.AppConfig = null
        this.PosUser = null
        this.callsQueueActive = []
        this.callsQueueWaiting = []
        this.callsQueueMissed = []
        this.dashboardData = null
        this.ordersCounts = {
          daily_count : 0,
          future_count: 0,
          saved_count : 0
        }

        this.deliveryStaffItems = []
        this.waiterStaffItems = []

        this.$DataStore.messages = new MessagesModel()

        this.$DataStore.cart = new CartModel()

        this.$DataStore.support = {
          tickets    : new SupportTicketCollection(),
          departments: new SupportDepartmentsCollection(),
          services   : new SupportServicesCollection(),
          priorities : new SupportPrioritiesCollection(),
          statuses   : new SupportStatusesCollection()
        }

        this.$DataStore.tables = []

        this.$DataStore.customers = {
          count: 0,
          items: []
        }
        this.$DataStore.customerGroups = {
          count: 0,
          items: []
        }

        this.$DataStore.ordersDataToday = {
          count: 0,
          items: []
        }
        this.$DataStore.ordersDataSaved = {
          count: 0,
          items: []
        }
        this.$DataStore.ordersDataAll = {
          count: 0,
          items: []
        }
      },

      onAppOnlineStatusChange (state) {
        this.appOnlineStatus = state.status
      },

      onAddOrderToDeliveryStaff (data) {
        if (data && data.status === 'success' && data.message) {
          this.$bus.$emit('app-show-notification', {
            body: data.message,
            type: 'success',
            icon: 'info'
          })
        } else if (data && data.status === 'fail' && data.message) {
          this.$bus.$emit('app-show-notification', {
            body: data.message,
            type: 'error',
            icon: 'warning'
          })
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.GenericError'),
            type: 'error',
            icon: 'warning'
          })
        }
      }
    },
    router,
    vuetify,
    render: h => h(App)
  })
}

// setupEpsonSDK()
initVUE()

window.initBarcodeScanner = () => {
  window.removeEventListener('onBarcodeScan-DeliveryOrder', onBarcodeScan)
  let activeDeliveryStaffId = null
  let setTimeOutId = null
  const barcodePrefixes = Object.values(BarcodePrefixEnum).join('|')

  barcodeListener = new BarcodeScanListener({
    debug           : false,
    eventSuffix     : 'DeliveryOrder',
    maxKeyboardDelay: vm?.appConfig?.LOCATION_DATA?.BarcodeDelayBetweenScans || 50,
    allowedChars    : '[0-9]',
    barcodeRegex    : `(${ barcodePrefixes })([0-9]{1,})`,
    ignoreInputs    : false
  })

  function onBarcodeScan (e) {
    // eslint-disable-next-line no-console
    console.log('>>>> main barcode:', e)

    const barcodeType = e?.detail?.type || ''
    const barcodeValue = e?.detail?.value || ''

    if (vm) {
      if (!vm.posUserHasRoutePermission('/delivery-staff')) return
      vm.$bus.$emit('barcode-scanned', e?.detail)
    }

    if (barcodeType === BarcodePrefixEnum.DeliveryStaff) {
      if (!activeDeliveryStaffId) { // Open Session For Delivery Staff
        setActiveDeliveryStaff(barcodeValue)
      } else {
        if (parseInt(activeDeliveryStaffId) === parseInt(e.detail.value)) { // Close Session For Current Delivery Staff
          clearActiveDeliveryStaff()
        } else { // Close any existing session and Open new Session For Delivery Staff
          setActiveDeliveryStaff(barcodeValue)
        }
      }
    } else if (barcodeType === BarcodePrefixEnum.Order) {
      if (activeDeliveryStaffId) {
        window.callAS(window.SocketCommand.Staff.Delivery.Shift.AddOrder, {
          Id     : activeDeliveryStaffId,
          OrderId: barcodeValue
        }, window.SocketCommand.Staff.Delivery.Shift.AddOrder)
      } else {
        if (vm) {
          vm.$bus.$emit('app-show-notification', {
            title: vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.Error.Title') + barcodeValue,
            body : vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.Error.Body'),
            type : 'error',
            icon : 'warning'
          })
        }
      }
    }

    function setActiveDeliveryStaff (id) {
      const deliveryStaff = getDeliveryStaff(id)
      if (deliveryStaff) {
        if (deliveryStaff.HasActiveShift) {
          clearActiveDeliveryStaff()
          activeDeliveryStaffId = parseInt(id)
          setTimeOutId = setTimeout(clearActiveDeliveryStaff, 1000 * 30) // Auto close session after 30 sec
          if (vm) {
            vm.$bus.$emit('app-show-notification', {
              title: deliveryStaff.Firstname + ' ' + deliveryStaff.Lastname,
              body : vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.SetActiveDeliveryStaff.Add.Body'),
              type : 'success',
              icon : 'info'
            })
          }
        } else {
          if (vm) {
            vm.$bus.$emit('app-show-notification', {
              title: vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.SetActiveDeliveryStaff.ClosedShift.Title'),
              body : vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.SetActiveDeliveryStaff.ClosedShift.Body', {
                firstname: deliveryStaff.Firstname,
                lastname : deliveryStaff.Lastname
              }),
              type: 'error',
              icon: 'warning'
            })
          }
        }
      } else {
        if (vm) {
          vm.$bus.$emit('app-show-notification', {
            title: vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.SetActiveDeliveryStaff.NotFound.Title'),
            body : vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.SetActiveDeliveryStaff.NotFound.Body'),
            type : 'error',
            icon : 'warning'
          })
        }
      }
    }

    function clearActiveDeliveryStaff () {
      if (activeDeliveryStaffId) {
        const deliveryStaff = getDeliveryStaff(activeDeliveryStaffId)
        if (vm) {
          vm.$bus.$emit('app-show-notification', {
            title: deliveryStaff.Firstname + ' ' + deliveryStaff.Lastname,
            body : vm.$t('Delivery.DeliveryStaffBarcode.OnBarcodeScan.ClearActiveDeliveryStaff.Body'),
            type : 'info',
            icon : 'info'
          })
        }
      }
      activeDeliveryStaffId = null
      if (setTimeOutId) {
        clearTimeout(setTimeOutId)
        setTimeOutId = null
      }
    }

    function getDeliveryStaff (id) {
      if (!vm) return false
      const index = vm.deliveryStaffItems.findIndex(p => (parseInt(p.Id) === parseInt(id)))
      return index < 0 ? null : vm.deliveryStaffItems[index]
    }
  }

  window.addEventListener('onBarcodeScan-DeliveryOrder', onBarcodeScan)
}

window.addEventListener('dragenter', function (e) {
  e = e || event
  e.preventDefault()
  e.dataTransfer.effectAllowed = 'none'
  e.dataTransfer.dropEffect = 'none'
}, false)
window.addEventListener('dragover', function (e) {
  e = e || event
  e.preventDefault()
  e.dataTransfer.effectAllowed = 'none'
  e.dataTransfer.dropEffect = 'none'
}, false)
window.addEventListener('drop', function (e) {
  e = e || event
  e.preventDefault()
  e.dataTransfer.effectAllowed = 'none'
  e.dataTransfer.dropEffect = 'none'
}, false)

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
}
