/* eslint-disable no-console */
import * as VueGoogleMaps                                                                                                          from 'vue2-google-maps'
import Socket                                                                                                                      from './Socket'
import SocketCommand                                                                                                               from './SocketCommand'
import router                                                                                                                      from '@/router/router'
import { AES, HmacMD5 }                                                                                                            from '@/lib/crypto/crypto'
import Printer                                                                                                                     from '@/lib/helper/printer'
import isElectron                                                                                                                  from '@/electron/isElectron'
import i18n                                                                                                                        from '@/lang/lang'
import Bugsnag                                                                                                                     from '@bugsnag/js'
import ipcRendererElectron                                                                                                         from '@/electron/ipcRendererElectron'
import ipcCommandsEnum                                                                                                             from '@/electron/ipcCommandsEnum'
import { MapsProviderEnum }                                                                                                        from '@/mixins/maps/mapsCommon'
import { decrementPosMenuItemsQuantityById, deletePosMenuItems, incrementPosMenuItemsQuantityById, setPosMenu, updatePosMenuItem } from '@/api/SocketMenuPosHelper'

export const SocketData = {
  /**
   *
   * @param command {String}}
   * @param data {Object}
   * @param resultCommand {String}
   */
  get: (command, data = {}, resultCommand = '') => {
    resultCommand = resultCommand === '' ? command : resultCommand
    const payload = Object.assign({}, data, {
      command       : command,
      result_command: resultCommand,
      location_id   : window.appConfig ? window.appConfig.LOCATION_DATA.Id : 1
    })

    console.groupCollapsed('%cGET.SocketData:: %c' + command, 'color: #1E88E5', 'color: ' + (command.startsWith('get-') || SocketData.commandExists(SocketCommand, command) ? '#4c4c4c' : '#E53935'))
    console.log(JSON.parse(JSON.stringify(payload)))
    console.groupEnd()

    payload.command = SocketCommand.commandToSocketFormat(command)
    payload.result_command = SocketCommand.commandToSocketFormat(resultCommand)
    if (Socket.pusher()) Socket.pusher().channel(Socket.clientChannel()).trigger('client-' + Socket.clientEvent(), payload)
  },

  /**
   *
   * @param command {String}
   * @param responseData {Object}
   * @param requestPayload {Object}
   */
  set: (command, responseData, requestPayload) => {
    const vm = window.Vue
    const ipcRenderer = ipcRendererElectron()
    const data = responseData ? JSON.parse(JSON.stringify(responseData)) : null
    // eslint-disable-next-line no-unused-vars
    const payload = requestPayload ? JSON.parse(JSON.stringify(requestPayload)) : null
    const storageKey = HmacMD5.hash('ROUTE')
    const storedRoute = AES.decrypt(vm.$sessionStorage.get(storageKey))
    command = SocketCommand.commandToClientFormat(command)

    console.groupCollapsed('%cSET.SocketData:: %c' + command, 'color: #388E3C', 'color: #4c4c4c')
    console.log(data)
    console.groupEnd()

    if (vm) vm.$bus.$emit(command, data, payload)

    switch (command) {
    case SocketCommand.Pusher.Pong:
      if (window.isVueRoutesInited) {
        window.callAS(SocketCommand.System.Ping, {
          commands: [{
            command     : SocketCommand.commandToSocketFormat(SocketCommand.Location.OnlineOrderingStatus),
            request_data: {}
          }, {
            command     : SocketCommand.commandToSocketFormat(SocketCommand.Order.Counts),
            request_data: {}
          }, {
            command     : SocketCommand.commandToSocketFormat(SocketCommand.Message.Counts),
            request_data: {}
          }]
        })
      }
      break

    case SocketCommand.System.Ping:
      Object.keys(data.data).forEach(command => {
        SocketData.set(command, data.data[command])
      })
      break

    case SocketCommand.getClientId:
      window.socketId = data.socketId || null
      window.callAS(SocketCommand.System.Init, {
        commands: [{
          command     : SocketCommand.commandToSocketFormat(SocketCommand.System.Printers.Get),
          request_data: {}
        }, {
          command     : SocketCommand.commandToSocketFormat(SocketCommand.WorkingHours.All),
          request_data: {}
        }, {
          command     : SocketCommand.commandToSocketFormat(SocketCommand.Staff.Delivery.All),
          request_data: {}
        }, {
          command     : SocketCommand.commandToSocketFormat(SocketCommand.Staff.Waiter.All),
          request_data: {}
        }, {
          command     : SocketCommand.commandToSocketFormat(SocketCommand.Order.Daily),
          request_data: { config: 0 }
        }, {
          command     : SocketCommand.commandToSocketFormat(SocketCommand.Order.Counts),
          request_data: {}
        }],
        guid    : vm.$DataStore.app.guid,
        guid_old: vm.$DataStore.app.guid_old,
        ver     : vm.$DataStore.app.versionDesktop,
        verWeb  : vm.$DataStore.app.versionWeb,
        computer: vm.$DataStore.app.computer
      })
      break

    case SocketCommand.System.Init:
      if (!window.isVueRoutesInited) {
        let component = null
        for (let r = 0; r < window.appConfig.routerItems.length; r++) {
          component = window.appConfig.routerItems[r].component
          window.appConfig.routerItems[r].component = window.routerComponents[component]
          if (window.appConfig.routerItems[r].children) {
            for (let c = 0; c < window.appConfig.routerItems[r].children.length; c++) {
              component = window.appConfig.routerItems[r].children[c].component
              window.appConfig.routerItems[r].children[c].component = window.routerComponents[component]
            }
          }
        }

        router.addRoutes(window.appConfig.routerItems)
        if (!VueGoogleMaps.gmapApi()) {
          const providers = window.AppConfig?.LOCATION_DATA?.Maps?.Pos || {}
          const providersArray = Object.values(providers)
          const googleProvider = providersArray?.find(provider => provider.Name === MapsProviderEnum.Google && provider.Key)
          const defaultMapsProvider = window.appConfig?.LOCATION_DATA?.Maps?.Pos?.DefaultMapsProvider
          const googleMapsKey = googleProvider?.Key || defaultMapsProvider?.Key

          VueGoogleMaps.loadGmapApi({
            key      : googleMapsKey,
            libraries: 'places,geometry,visualization',
            language : i18n.locale || process.env.VUE_APP_I18N_LOCALE
            // v        : 3.38
          })
        }

        if (vm.posUserHasComponentPermission('DeliveryStaff', 'ACCESS')) window.initBarcodeScanner()
        window.isVueRoutesInited = true
      }

      Object.keys(data.data).forEach(command => {
        SocketData.set(command, data.data[command])
      })

      window.Vue.$bus.$emit(SocketCommand.Pusher.IsOnline, { status: true })
      setTimeout(() => {
        window.callAS(SocketCommand.Location.OnlineOrderingStatus, {})
      }, 3000)

      if (vm.posUserHasComponentPermission('POS', 'ACCESS')) window.callAS(SocketCommand.Menu.Pos, {})
      if (vm.posUserHasComponentPermission('Tables', 'ACCESS')) window.callAS(SocketCommand.Table.AllByArea, {})
      if (vm.posUserHasComponentPermission('Dashboard', 'ACCESS')) window.callAS(window.SocketCommand.Dashboard.Get, { stats_range: 'week' })
      if (vm.posUserHasComponentPermission('MessagesInbox', 'ACCESS')) window.callAS(SocketCommand.Message.Counts, {})
      if (vm.posUserHasComponentPermission('SupportCenter', 'ACCESS')) window.Vue.$DataStore.support.tickets.fetch()

      if (vm.appConfig.LOCATION_DATA.LockedFull && !vm.userInAdminGroup) {
        vm.$router.replace({ name: 'DomainLocked' })
        return
      }

      if (storedRoute && vm.posUserHasComponentPermission(storedRoute.name, 'ACCESS')) {
        vm.$router.replace(storedRoute)
      } else {
        if (vm.userIsWaiter) {
          const waiter = vm.waiterStaffItems?.find(staff => staff.Id.toString() === vm.posUser.staff_id.toString())
          if (waiter?.HasActiveShift && vm.posUserHasComponentPermission('Tables', 'ACCESS')) {
            vm.$router.replace({ name: 'Tables' })
          } else if (vm.posUserHasComponentPermission('WaiterStaff', 'ACCESS')) {
            vm.$router.replace({ name: 'WaiterStaff' })
          } else {
            if (window.appConfig.routerItems.length > 0) {
              vm.$router.replace({ name: window.appConfig.routerItems[0].name })
            } else {
              throw new Error('No available routes')
            }
          }
        } else {
          if (vm.posUser?.ShowSettingsWizard && vm.posUserHasComponentPermission('Dashboard', 'ACCESS')) {
            vm.$router.replace({ name: 'Dashboard' })
          } else {
            if (vm.posUserHasComponentPermission('Orders', 'ACCESS')) {
              vm.$router.replace({ name: 'OrdersToday' })
            } else if (vm.posUserHasComponentPermission('Dashboard', 'ACCESS')) {
              vm.$router.replace({ name: 'Dashboard' })
            } else if (vm.posUserHasComponentPermission('Tables', 'ACCESS')) {
              vm.$router.replace({ name: 'Tables' })
            } else {
              if (window.appConfig.routerItems.length > 0) {
                vm.$router.replace({ name: window.appConfig.routerItems[0].name })
              } else {
                throw new Error('No available routes')
              }
            }
          }
        }
      }

      // INIT CUSTOMER DISPLAY SYSTEM (CDS)
      if (vm.posUserHasComponentPermission('Pos', 'ACCESS') && isElectron()) {
        const cdsDisplays = window?.appConfig?.CDS_DISPLAYS || []
        const display = cdsDisplays.find(cdsDisplay => cdsDisplay.guid === vm.$DataStore.app.guid)
        const cdsUrl = `${ process.env.VUE_APP_POS_URL }cds?lang=${ vm.$i18n?.locale || 'el' }`
        if (display && !!parseInt(display.auto_open)) {
          ipcRenderer.send(ipcCommandsEnum.OpenCds, {
            ...display,
            cds_url: cdsUrl
          })
        }
      }
      break

      // CONFIG
    case SocketCommand.System.Config:
      // --------------------------------------------------------------------------
      // TODO: Remove for loop when data from API respects floats
      console.log('>>>>>>> ', data)
      data.LOCATION_DATA.DeliveryAreas.forEach((area, a) => {
        data.LOCATION_DATA.DeliveryAreas[a].areaId = parseFloat(area.areaId)
        data.LOCATION_DATA.DeliveryAreas[a].circle.center.lat = parseFloat(area.circle.center.lat)
        data.LOCATION_DATA.DeliveryAreas[a].circle.center.lng = parseFloat(area.circle.center.lng)
        data.LOCATION_DATA.DeliveryAreas[a].circle.radius = parseFloat(area.circle.radius)

        area.vertices.forEach((vert, v) => {
          data.LOCATION_DATA.DeliveryAreas[a].vertices[v].lat = parseFloat(vert.lat)
          data.LOCATION_DATA.DeliveryAreas[a].vertices[v].lng = parseFloat(vert.lng)
          if (!data.LOCATION_DATA.DeliveryAreas[a].vertices[v].hasOwnProperty('latlng')) {
            data.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng = {
              lat: parseFloat(vert.lat),
              lng: parseFloat(vert.lng)
            }
          }
          data.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng.lat = parseFloat(vert.latlng.lat)
          data.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng.lng = parseFloat(vert.latlng.lng)
        })
      })

      window.appConfig.LOCATION_DATA = data.LOCATION_DATA
      window.appConfig.ORDER_TYPES = data.ORDER_TYPES
      window.appConfig.PAYMENT_TYPES = data.PAYMENT_TYPES
      window.appConfig.SOURCE_TYPES = data.SOURCE_TYPES
      window.appConfig.EXTRA_CHARGES = data.EXTRA_CHARGES
      window.appConfig.APP_UPDATE = data.APP_UPDATE
      window.appConfig.APP_LOGGING = data.APP_LOGGING
      window.appConfig.POS_VENDORS = data.POS_VENDORS
      window.appConfig.TAX = data.TAX

      vm.$set(vm.AppConfig, 'LOCATION_DATA', data.LOCATION_DATA)
      vm.$set(vm.AppConfig, 'ORDER_TYPES', data.ORDER_TYPES)
      vm.$set(vm.AppConfig, 'PAYMENT_TYPES', data.PAYMENT_TYPES)
      vm.$set(vm.AppConfig, 'SOURCE_TYPES', data.SOURCE_TYPES)
      vm.$set(vm.AppConfig, 'EXTRA_CHARGES', data.EXTRA_CHARGES)
      vm.$set(vm.AppConfig, 'APP_UPDATE', data.APP_UPDATE)
      vm.$set(vm.AppConfig, 'APP_LOGGING', data.APP_LOGGING)
      vm.$set(vm.AppConfig, 'POS_VENDORS', data.POS_VENDORS)
      vm.$set(vm.AppConfig, 'TAX', data.TAX)
      break

      // AJAX
    case SocketCommand.Ajax.Get:
      if (data && data.command && SocketData.commandExists(SocketCommand, SocketCommand.commandToClientFormat(data.command))) {
        const command = SocketCommand.commandToClientFormat(data.command)
        const excludedCommands = [SocketCommand.Table.GetById, SocketCommand.Order.Get, SocketCommand.Catalog.All, SocketCommand.Receipt.All]

        if (!excludedCommands.includes(command)) {
          if (command === window.SocketCommand.Menu.Pos && data?.action) {
            if (data?.action === 'update') {
              window.callAS(command, {
                action: data?.action || 'update',
                menus : data?.menus || []
              }, window.SocketCommand.Menu.PosItem)
            } else if (data?.action === 'delete') {
              deletePosMenuItems(data)
            } else if (data?.action === 'stock_increment') {
              incrementPosMenuItemsQuantityById(data)
            } else if (data?.action === 'stock_decrement') {
              decrementPosMenuItemsQuantityById(data)
            }
          } else {
            window.callAS(command, {})
          }
        }
      }
      break

      // ELECTRON
    case SocketCommand.ElectronApp.HardReload:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.HardReload)
      }
      break
    case SocketCommand.ElectronApp.Reload:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.Reload)
      }
      break
    case SocketCommand.ElectronApp.ClearHttpCache:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.ClearHttpCache)
      }
      break
    case SocketCommand.ElectronApp.ClearStorageCache:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.ClearStorageCache)
      }
      break
    case SocketCommand.ElectronApp.ClearAllCache:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.ClearAllCache)
      }
      break
    case SocketCommand.ElectronApp.ClearAllAndRelaunch:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.ClearAllAndRelaunch)
      }
      break
    case SocketCommand.ElectronApp.Relaunch:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.Relaunch)
      }
      break
    case SocketCommand.ElectronApp.RetryLoadMainApp:
      if (isElectron()) {
        ipcRenderer.send(ipcCommandsEnum.RetryLoadMainApp)
      }
      break

      // SOCKET
    case SocketCommand.System.Socket.ChannelConnections.Ws:
      vm.$DataStore.channelConnections = data || {
        guest: {
          users: [],
          count: 0
        },
        normal: {
          users: [],
          count: 0
        }
      }
      break

      // DASHBOARD
    case SocketCommand.Dashboard.Get:
      vm.$set(vm, 'dashboardData', data)
      break

      // TABLES
    case SocketCommand.Table.AllByArea:
      vm.$DataStore.tables = data || []
      break

      // ORDERS
    case SocketCommand.Order.Print:
      if (data.order_id) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)
        }
        Printer.printOrderAuto(data.order_id)
      }
      break
    case SocketCommand.Receipt.Print:
      if (data.receipt_id) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)
        }
        Printer.printReceiptAuto(data.receipt_id)
      }
      break
    case SocketCommand.Receipt.PrintManual:
      if (data.receipt_id) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)
        }
        Printer.printReceipt(data.receipt_id)
      }
      break
    case SocketCommand.Order.PrintPreparation:
      if (data.order_id && data.group) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)
        }
        Printer.printPreparationAuto(data.order_id, data.group)
      }
      break

      // TABLES
    case SocketCommand.Table.SendOrderForPrint:
      if (data.cart_id) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)

          if (data?.waiter) {
            const appMachinePrinters = window.Vue.AppMachinePrinters ? window.Vue.AppMachinePrinters : []
            const appMachineWaiterPrintersActive = appMachinePrinters.filter(printer => printer?.status === '1' && printer?.waiter === '1')
            Printer.printTableOrder(data.cart_id, data?.timestamp || null, appMachineWaiterPrintersActive, 1, false)
          }
        }

        if (!data?.waiter) {
          Printer.printTableOrderAuto(data.cart_id, data?.timestamp || null)
        }
      }
      break
    case SocketCommand.Table.SendOrderPreparationForPrint:
      if (data.cart_id && data.timestamp && data.group) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)
        }
        Printer.printTableOrderPreparationAuto(data.cart_id, data.group, data.timestamp)
      }
      break
    case SocketCommand.Table.SendReceiptForPrint:
      if (data.cart_id && data.timestamp) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)
        }
        Printer.printTableReceiptAuto(data.cart_id, data.timestamp)
      }
      break
    case SocketCommand.Table.SendCanceledForPrint:
      if (data.cart_id && data.items) {
        if (isElectron()) {
          ipcRenderer.send(ipcCommandsEnum.AppFocus)
        }
        Printer.printTableCanceledAuto(data.cart_id, data.items)
      }
      break

    case SocketCommand.Order.Counts:
      vm.$set(vm, 'ordersCounts', data)
      break

    case SocketCommand.Order.Daily:
      if (data?.items) {
        vm.$set(vm.$DataStore.ordersDataToday, 'count', data?.count || 0)
        vm.$set(vm.$DataStore.ordersDataToday, 'items', data?.items || [])
      } else if (data?.table) {
        vm.$set(vm.$DataStore.ordersDataToday, 'count', data?.table?.items?.count || 0)
        vm.$set(vm.$DataStore.ordersDataToday, 'items', data?.table?.items?.items || [])
      } else {
        vm.$set(vm.$DataStore.ordersDataToday, 'items', data || [])
      }
      break

    case SocketCommand.Order.Saved:
      if (data?.items) {
        vm.$set(vm.$DataStore.ordersDataSaved, 'count', data?.count || 0)
        vm.$set(vm.$DataStore.ordersDataSaved, 'items', data?.items || [])
      } else if (data?.table) {
        vm.$set(vm.$DataStore.ordersDataSaved, 'count', data?.table?.items?.count || 0)
        vm.$set(vm.$DataStore.ordersDataSaved, 'items', data?.table?.items?.items || [])
      } else {
        vm.$set(vm.$DataStore.ordersDataSaved, 'items', data || [])
      }
      break

      // Online Ordering Status
    case SocketCommand.Location.OnlineOrderingStatus:
      vm.$set(vm, 'onlineOrderingStatus', data.status)
      break

      // WORKING HOURS
    case SocketCommand.WorkingHours.All:
      vm.$set(vm, 'orderTypeDefaultTimes', data.settings)
      break

      /* DELIVERY STAFF */
    case SocketCommand.Staff.Delivery.All:
      if (data && Array.isArray(data) && data.length) vm.$set(vm, 'deliveryStaffItems', data || [])
      if (data && data.result && Array.isArray(data.result) && data.result.length) vm.$set(vm, 'deliveryStaffItems', data.result || [])
      break

      /* WAITER STAFF */
    case SocketCommand.Staff.Waiter.All:
      if (data && Array.isArray(data) && data.length) {
        vm.$set(vm, 'waiterStaffItems', data.sort((a, b) => b.HasActiveShift - a.HasActiveShift) || [])
      } else if (data && data.result && Array.isArray(data.result) && data.result.length) {
        vm.$set(vm, 'waiterStaffItems', data.result.sort((a, b) => b.HasActiveShift - a.HasActiveShift) || [])
      } else {
        vm.$set(vm, 'waiterStaffItems', [])
      }

      // eslint-disable-next-line no-case-declarations
      const posUserWaiter = vm.waiterStaffItems.find(waiter => parseInt(waiter.Id) === parseInt(vm.posUser.staff_id))
      if (posUserWaiter) {
        const posUser = vm.posUser
        posUser.Settings = posUserWaiter.Settings
        vm.posUser = posUser
      }
      break

      /* PRINTERS */
    case SocketCommand.System.Printers.Get:
      if (data) {
        const groups = data?.groups
        vm.$set(vm, 'AppPrinterGroups', groups || [])

        const printers = data?.printers?.find(machine => machine.guid === vm.$DataStore.app.guid)

        vm.$set(vm, 'AppMachinePrinters', printers?.printers_default || [])
        vm.$set(vm, 'AppMachinePreparationPrinters', printers?.printers_preparation || [])
        vm.$set(vm, 'AppMachineReceiptPrinters', printers?.printers_receipts || [])
        vm.$set(vm, 'AppMachineReceiptTotalsZPrinters', printers?.printers_totals || [])
      }
      break

      /* MENU */
    case SocketCommand.Menu.Pos:
      if (data?.categories && data?.items && !data?.action) setPosMenu(data)
      break
    case window.SocketCommand.Menu.PosItem:
      if (data) updatePosMenuItem(data)
      break

    case SocketCommand.Catalog.TaxesOrphaned:
      if (data?.Orphaned) {
        window.appConfig.TAX.Orphaned = data.Orphaned
        window.Vue.AppConfig.TAX.Orphaned = data.Orphaned
      }
      break
    }
  },

  /**
   *
   * @param commandsObj {Object}
   * @param value {String}
   * @returns {Boolean}
   */
  commandExists: (commandsObj, value) => {
    for (const key in commandsObj) {
      if (commandsObj[key] === value) return true
      if (typeof commandsObj[key] === 'object') {
        const found = SocketData.commandExists(commandsObj[key], value)
        if (found) return true
      }
    }
  },

  /**
   *
   * @param payload {Object}
   * @returns {Boolean}
   */
  apiCallIsAllowed: (payload) => {
    const excludedCommands = [SocketCommand.Settings.Misc.Countries]
    const validateCommandsEnabled = window?.appConfig?.LOCATION_DATA?.HasValidateCommands || false
    const commands = window?.Vue?.posUser?.Commands || []
    const commandAllowed = !validateCommandsEnabled || commands.includes(payload.command) || excludedCommands.map(permission => SocketCommand.commandToSocketFormat(permission)).includes(payload.command)

    if (commandAllowed) return true

    if (!commandAllowed) {
      console.groupCollapsed('%cAPI::COMMAND.NOT-ALLOWED:: %c' + SocketCommand.commandToClientFormat(payload.command), 'font-weight: bold; color: #b22828', 'color: #4c4c4c')
      console.log(JSON.parse(JSON.stringify(payload)))
      console.groupEnd()

      Bugsnag.notify(new Error('API::COMMAND.NOT-ALLOWED:: ' + SocketCommand.commandToClientFormat(payload.command)))
    }

    return false
  }
}

export default SocketData
