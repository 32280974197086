import Vue              from 'vue'
import Bugsnag          from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import LogRocket        from 'logrocket'
import isElectron       from '@/electron/isElectron'
import AppData          from './appdata'

export default {
  name: 'AppLogging',
  data () {
    return {}
  },
  mixins : [AppData],
  methods: {
    initRemoteLogging () {
      if (!this.isLoggingActive) return
      this.initLogRocket()
      this.initBugSnag()
    },
    setRemoteLoggingUser () {
      if (!this.isLoggingActive) return
      this.setLogRocketUser()
      this.setBugSnagUser()
      /* setTimeout(() => {
        Bugsnag.notify(new Error('Test error'))
      }, 3000) */
    },
    unsetRemoteLoggingUser () {
      if (!this.isLoggingActive) return
      this.unsetLogRocketUser()
      this.unsetBugSnagUser()
    },

    initLogRocket () {
      if (!this.isLoggingActive || !this.isLogRocketActive || this.logRocketInitialized) return

      LogRocket.init(this.APP_LOGGING.LOGROCKET.KEY, {
        release: process.env.VUE_APP_VERSION,
        dom    : {
          baseHref: process.env.VUE_APP_POS_URL
        }
      })

      this.logRocketInitialized = true
    },
    setLogRocketUser () {
      if (!this.isLoggingActive || !this.isLogRocketActive || !this.logRocketInitialized) return

      if (!this.posUser) return

      // LogRocket.startNewSession()
      const domain = String(window.appConfig.LOCATION_DATA.SiteUrl).replace('https://', '').replace('/', '')
      LogRocket.identify(`${ this.posUser.staff_id }@${ domain }`, {
        IsAdmin           : this.userIsSuperAdmin,
        Permissions       : this.posUser.Permissions,
        ShowSettingsWizard: this.posUser.ShowSettingsWizard,
        is_guest          : this.posUser.is_guest,
        staff_id          : this.posUser.staff_id,
        staff_group_id    : this.posUser.staff_group_id,
        staff_firstname   : this.posUser.staff_firstname,
        staff_lastname    : this.posUser.staff_lastname,
        staff_name        : this.posUser.staff_name,
        staff_email       : this.posUser.staff_email,
        staff_phone       : this.posUser.staff_phone,
        staff_locale      : this.posUser.staff_locale,
        ws_app_key        : window.appConfig.WS.key,
        location_name     : window.appConfig.LOCATION_DATA.Name,
        location_site_url : window.appConfig.LOCATION_DATA.SiteUrl,
        using_desktop_app : isElectron()
      })
    },
    unsetLogRocketUser () {
      if (!this.isLoggingActive || !this.isLogRocketActive || !this.logRocketInitialized) return
      LogRocket.identify(false)
    },

    initBugSnag () {
      if (!this.isLoggingActive || !this.isBugSnagActive || this.bugSnagInitialized) return
      Bugsnag.start({
        apiKey             : this.APP_LOGGING.BUGSNAG.KEY,
        plugins            : [new BugsnagPluginVue(Vue)],
        appVersion         : process.env.VUE_APP_VERSION,
        appType            : isElectron() ? 'DESKTOP' : 'WEB',
        autoNotify         : true,
        autoCaptureSessions: true,
        collectUserIp      : true,
        releaseStage       : process.env.VUE_APP_ENV,
        notifyReleaseStages: ['production', 'staging'], // development
        filters            : [
          'token', // exact match: "token"
          /^password$/i // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
        ]
      })
      Bugsnag.clearMetadata('app')
      Bugsnag.addMetadata('app', this.$DataStore.app || {})

      this.bugSnagInitialized = true
    },
    setBugSnagUser () {
      if (!this.isLoggingActive || !this.isBugSnagActive || !this.bugSnagInitialized) return

      this.unsetBugSnagUser()
      if (!this.posUser) return

      const domain = String(window.appConfig.LOCATION_DATA.SiteUrl).replace('https://', '').replace('/', '')
      Bugsnag.setUser(`${ this.posUser.staff_id }@${ domain }`, this.posUser.staff_email, this.posUser.staff_name)

      const user = JSON.parse(JSON.stringify(this.posUser))
      delete user.Commands
      delete user.Permissions

      Bugsnag.addMetadata('user', {
        ...user,
        ...{
          IsAdmin          : this.userIsSuperAdmin,
          ws_app_key       : window.appConfig.WS.key,
          location_name    : window.appConfig.LOCATION_DATA.Name,
          location_site_url: window.appConfig.LOCATION_DATA.SiteUrl,
          using_desktop_app: isElectron()
        }
      })
      Bugsnag.addMetadata('commands', this.posUser.Commands)
      Bugsnag.addMetadata('permissions', this.posUser.Permissions)
    },
    unsetBugSnagUser () {
      if (!this.isLoggingActive && !this.isBugSnagActive || !this.bugSnagInitialized) return
      Bugsnag.setUser('', '', '')
      Bugsnag.clearMetadata('user')
    }
  },
  computed: {
    APP_LOGGING () {
      return this.appConfig ? this.appConfig.APP_LOGGING : {}
    },
    isLoggingActive () {
      return this.APP_LOGGING.ENABLED && process.env.VUE_APP_ENV !== 'development'
    },
    isLogRocketActive () {
      return isElectron() ? this.APP_LOGGING.LOGROCKET.DESKTOP : this.APP_LOGGING.LOGROCKET.WEB
    },
    isBugSnagActive () {
      return isElectron() ? this.APP_LOGGING.BUGSNAG.DESKTOP : this.APP_LOGGING.BUGSNAG.WEB
    },
    logRocketInitialized: {
      set (v) {
        this.APP_LOGGING.LOGROCKET.INIT = v
      },
      get () {
        return this.APP_LOGGING.LOGROCKET.INIT
      }
    },
    bugSnagInitialized: {
      set (v) {
        this.APP_LOGGING.BUGSNAG.INIT = v
      },
      get () {
        return this.APP_LOGGING.BUGSNAG.INIT
      }
    }
  }
}
