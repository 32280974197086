<template>
  <v-dialog
    v-if="message"
    v-model="isVisible"
    :fullscreen="isFullScreen"
    max-width="850"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        class="pl-1"
        height="80"
        max-height="80"
      >
        <div class="mr-3">
          <v-badge
            :value="message.Important"
            color="red"
            overlap
            right
          >
            <v-icon
              slot="badge"
              dark
              small
            >
              fas fa-exclamation
            </v-icon>
            <v-avatar :color="message.Type.Color ? message.Type.Color : 'indigo'">
              <v-icon :color="message.Type.Color ? (message.Type.Color + ' lighten-5') : 'indigo lighten-5'">
                {{ message.Type.Icon }}
              </v-icon>
            </v-avatar>
          </v-badge>
        </div>

        <v-toolbar-title
          class="pl-1"
        >
          <div class="body-3">
            {{ message.Type.Name }}
          </div>
          <div class="text-caption">
            {{ message.Type.Subject }}
          </div>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-title
          class="px-4"
        >
          <div class="text-caption">
            {{ message.DateAdded }} {{ message.TimeAdded }}
          </div>
        </v-toolbar-title>
        <v-btn
          class="ml-0"
          icon
          @click="onCancelButtonClick"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider />

      <v-card-text
        v-dynamic-router-link
        class="fill-height pa-4"
        :style="userIsSuperAdmin ? 'user-select: text;' : ''"
        v-html="message.Body.replace(new RegExp('{username}', 'g'), posUser.staff_name).replace(new RegExp('{{\\$user_name}}', 'g'), posUser.staff_name)"
      />

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          class="light-blue--text text--darken-1"
          outlined
          @click="onCancelButtonClick"
        >
          {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Auth from '../../mixins/auth'

export default {
  name      : 'MessageDetailsDialog',
  components: {},
  mixins    : [Auth],
  props     : {
    visible: {
      type    : Boolean,
      default : false,
      required: true
    },
    message: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      isFullScreen: true
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    onCancelButtonClick () {
      this.isVisible = false
    }

  }
}
</script>
