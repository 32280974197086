import TableMergeSplitModeEnum from '@/api/Enums/TableMergeSplitModeEnum'
import moment                  from 'moment'
import Auth                    from '@/mixins/auth'
import TableStatus             from '@/mixins/tables/tableStatus'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'

export default {
  mixins: [Auth, TableStatus],

  computed: {
    canManageTable () {
      return this.tableCanManage(this.table)
    },
    isOwnTable () {
      return this.tableIsOwn(this.table)
    },
    hasTablePaidItems () {
      return this.tableHasPaidItems(this.table)
    },
    hasTableUnPaidItems () {
      return this.tableHasUnPaidItems(this.table)
    },
    hasTableChildren () {
      return this.tableHasChildren(this.table)
    },
    hasTableParent () {
      return this.tableHasParent(this.table)
    },
    canSplitTable () {
      return this.tableCanSplit(this.table)
    },
    canMoveTable () {
      return this.tableCanMove(this.table)
    },
    canMergeTable () {
      return this.tableCanMerge(this.table)
    },
    canChangeWaiter () {
      return this.tableCanChangeWaiter(this.table)
    },
    canCloseTable () {
      return this.tableCanClose(this.table)
    },
    canPayTable () {
      return this.tableCanPay(this.table)
    },
    canEditTable () {
      return this.tableCanEdit(this.table)
    },
    canViewTable () {
      return this.tableCanView(this.table)
    },
    canOrderTable () {
      return this.tableCanOrder(this.table)
    },
    canOpenTable () {
      return this.tableCanOpen(this.table)
    },
    canBookTable () {
      return this.tableCanBeBooked(this.table)
    },
    TableMergeSplitModeEnum () {
      return TableMergeSplitModeEnum
    },
    receiptsProviderRule () {
      return this.tableReceiptsProviderRule(this.table)
    }
  },

  methods: {
    tableStaffColor (staffId) {
      if (staffId !== this.table?.StaffId) return 'orange'
      return 'green'
    },

    tableCanManage (table) {
      const waiter = window.Vue.waiterStaffItems?.find(staff => parseInt(staff.Id) === parseInt(this.posUser.staff_id))
      const waiterTableAreas = waiter?.TableAreas || []

      if (
        this.userIsWaiter &&
        this.appConfig?.LOCATION_DATA?.WaiterCanManageAllTables &&
        this.posUser?.Settings?.Tables?.Waiter?.Permissions?.WaiterCanManageAllTables
      ) { return true }

      const canAccessTableArea = (waiterTableAreas.length > 0 && waiterTableAreas[0] > 0 ? waiterTableAreas.includes(table.AreaId) : true)

      return ((this.tableIsAvailable(table) || this.tableIsOwn(table)) && canAccessTableArea && this.userIsWaiter) || (this.posUserHasComponentPermission('TablesWaiterManagement', 'ACCESS') || this.userIsOwner || this.userIsSuperAdmin || this.userIsSuperExtraAdmin)
    },

    tableHasPaidItems (table) {
      return table?.HasItemsPaid
    },

    tableHasUnPaidItems (table) {
      return table?.HasItemsUnpaid
    },

    tableHasChildren (table) {
      return table?.Children && table?.Children?.length
    },

    tableHasParent (table) {
      return !!table?.Parent
    },

    tableIsOwn (table) {
      return parseInt(table?.StaffId) === parseInt(this.posUser?.staff_id)
    },

    tableReceiptsProviderRule (table) {
      // Do not allow split, move & merge when with cloud receipts provider, and we have added orders to the table.
      return this.appHasReceiptsProvider && this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD && table?.CartsCount === 0 && !(this.tableHasPaidItems(table) || this.tableHasUnPaidItems(table))
    },

    tableCanSplit (table) {
      const generalRule = this.tableHasChildren(table) && ((this.tableIsInOccupiedStatuses(table) && this.canManageTable) || this.tableIsAvailable(table))
      const receiptsProviderRule = this.tableReceiptsProviderRule(table) && generalRule

      return this.appHasReceiptsProvider ? receiptsProviderRule && generalRule : generalRule
    },
    tableCanMove (table) {
      const generalRule = this.tableIsInOccupiedStatuses(table) && this.tableHasUnPaidItems(table) && this.canManageTable
      const receiptsProviderRule = this.tableReceiptsProviderRule(table) && generalRule

      return this.appHasReceiptsProvider ? receiptsProviderRule && generalRule : generalRule
    },
    tableCanMerge (table) {
      const generalRule = !this.tableHasParent(table) && ((this.tableIsInOccupiedStatuses(table) && this.canManageTable) || this.tableIsAvailable(table))
      const receiptsProviderRule = this.tableReceiptsProviderRule(table) && generalRule

      return this.appHasReceiptsProvider ? receiptsProviderRule && generalRule : generalRule
    },
    tableCanChangeWaiter (table) {
      if (this.tableIsInOccupiedStatuses(table) && this.canManageTable) {
        if (
          this.userIsWaiter &&
          this.appConfig?.LOCATION_DATA?.WaiterCanChangeTableWaiter &&
          this.posUser?.Settings?.Tables?.Waiter?.Permissions?.WaiterCanChangeTableWaiter
        ) {
          return true
        }

        return (!this.userIsWaiter && this.canManageTable)
      }

      return false
    },
    tableCanClose (table) {
      return this.tableIsInOccupiedStatuses(table) && this.canManageTable
    },
    tableCanPay (table) {
      return this.tableIsInOccupiedStatuses(table) && this.tableHasUnPaidItems(table) && this.canManageTable
    },
    tableCanEdit (table) {
      return this.tableIsInOccupiedStatuses(table) && this.tableHasItemsEditable(table) && table?.CartId && this.canManageTable
    },
    tableCanView (table) {
      return this.tableIsInOccupiedStatuses(table) && table?.CartId && table?.HasItems
    },
    tableCanOrder (table) {
      return this.tableIsInOccupiedStatuses(table) && this.canManageTable
    },
    tableCanOpen (table) {
      return this.tableIsAvailable(table) && this.canManageTable
    },
    tableCanBeBooked (table) {
      return table?.Bookable && this.posUserHasComponentPermission('TableReservation', 'MANAGE')
    },

    getTableStaffColor (table, style = false) {
      const waiters = window.Vue?.waiterStaffItems || []
      const waiter = waiters.find(waiter => parseInt(waiter.Id) === parseInt(table.StaffId)) || null
      const color = this.tableIsOwn(table) ? '#616161' : waiter.Color ? `#${ waiter.Color }` : '#e65100'
      return style ? `color: ${ color };` : color
    },

    getTableChildrenTables (table) {
      const retVal = []
      if (!table) return retVal
      const data = JSON.parse(JSON.stringify(this.viewData || []))
      const selectedArea = data.find(originalArea => originalArea.Id === table.AreaId)
      if (!table?.Children?.length || !selectedArea) return retVal

      table.Children.forEach(tableId => {
        const table = selectedArea?.Tables?.find(tbl => tbl.Id === tableId)
        if (table) retVal.push(table)
      })

      return retVal
    },

    getTableTotalMaxCapacity (table) {
      if (!table) return 0
      return this.getTableChildrenTables(table)?.reduce((a, b) => a + b.MaxCapacity, table.MaxCapacity) || table?.MaxCapacity || 0
    },

    getTableTotalMinCapacity (table) {
      if (!table) return 0
      return this.getTableChildrenTables(table)?.reduce((a, b) => a + b.MinCapacity, table.MinCapacity) || table?.MinCapacity || 0
    },

    getTableSwiftTimeElapsed (table) {
      return moment.duration(moment.unix(String(table.ShiftStartedAt)).diff(moment())).asMinutes() * -1
    },

    getTableSwiftTimeElapsedHumanized (table, mini = false) {
      moment.relativeTimeThreshold('ss', 60)
      moment.updateLocale('en', { relativeTime: { s: '%d sec' } })
      moment.updateLocale('el', { relativeTime: { s: '%d δευτ.' } })
      moment.locale(this.$i18n.locale)

      let h = moment.duration(moment.unix(String(table.ShiftStartedAt)).diff(moment())).humanize(false)

      if (this.mini) {
        const s = h.split(' ')
        const retVal = s.length >= 1 ? s[0] + s[1].charAt(0) : ''

        h = retVal
      }

      return h
    },

    getTableSwiftTimeSince (table) {
      return moment(moment.unix(String(table.ShiftStartedAt)), 'DD-MM-YYYY HH:mm:ss')
    }
  }
}
