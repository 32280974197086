export default {
  Title: 'Messages | Message | Messages',

  Toolbar: {
    Subtitle: 'Messages List',
    Icon    : 'email',
    Color   : 'indigo',
    Button  : {
      New   : { Title: 'New message', Icon: 'add_box' },
      Filter: { Title: 'Filter by', Icon: 'filter_list' },
      Group : { Title: 'Group by', Icon: 'more_vert' }
    },
    Label: {
      Dear   : 'Dear',
      Sender : 'Sender',
      Reading: {
        Read  : 'Read',
        Unread: 'Unread'
      },
      Importance: {
        Important: 'Important',
        Regular  : 'Regular'
      },
      Type: 'Type'
    }
  },
  ListView: {
    Select: {
      Title   : 'Select Message',
      Subtitle: 'Select message from the list',
      Icon    : 'email'
    },
    NoData: {
      Subtitle: 'There are no messages yet.',
      Icon    : 'email'
    }
  },

  MessageSendDialog: {
    Toolbar: {
      Title   : '@:Message.Toolbar.Button.New.Title',
      Subtitle: 'Send new message',
      Icon    : 'email',
      Color   : 'indigo'
    },
    Field: {
      MessageType : { Label: 'Message Type' },
      Sender      : { Label: 'Sender' },
      Important   : { Label: 'Important', IconOn: 'fas fa-exclamation-circle', IconOff: 'far fa-circle' },
      DateSent    : { Label: 'Date to be sent', Icon: 'event' },
      HourSent    : { Label: 'Hour to be sent', Icon: 'access_time' },
      SentTo      : { Label: 'To', Placeholder: 'All' },
      MessageTitle: { Label: 'Title', Placeholder: 'Message Title' }
    }
  }
}
