<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar color="indigo">
            <v-icon dark>
              {{ $t(`Restaurant.Tables.Dialog.Payment.Items.Icon`) }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ area && area.Name }} #{{ table && table.Name }}
            </div>

            <div class="text-caption grey--text">
              {{ $t(`Restaurant.Tables.Dialog.Payment.Items.Subtitle`) }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-progress-linear
          v-if="loading"
          class="pa-0 ma-0"
          color="secondary"
          height="2"
          indeterminate
        />

        <v-divider />

        <div>
          <v-stepper
            v-model="paymentStep"
            :alt-labels="true"
            class="elevation-0"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="paymentStep > 1"
                step="1"
              >
                {{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Unpaid.Step.Orders') }}
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                :complete="paymentStep > 2"
                step="2"
              >
                {{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Unpaid.Step.Payment') }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>

        <v-divider />

        <v-card-text
          v-if="table && area"
          class="fill-height pa-3"
          style="min-height: 310px;"
        >
          <v-container
            v-if="viewData"
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-stepper
                  v-model="paymentStep"
                  class="elevation-0"
                >
                  <v-stepper-items>
                    <v-stepper-content
                      class="pa-0"
                      step="1"
                    >
                      <v-container
                        class="pa-0"
                        fluid
                      >
                        <v-row>
                          <!-- CART ORDERS -->
                          <v-col cols="12">
                            <select-table-completed-orders
                              v-model="selectedUnpaidItems"
                              :selectable-menu-items="true"
                              :selectable-orders="true"
                              :selected-total.sync="selectedUnpaidItemsTotal"
                              :view-data="viewData"
                              desc
                              unpaid
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-stepper-content>

                    <v-stepper-content
                      class="pa-0"
                      step="2"
                    >
                      <table-payment
                        v-model="transactions"
                        :customer-id="viewData?.SCustomer?.Id"
                        :items="selectedUnpaidItems"
                        :payment-totals.sync="totals"
                        :receipt-data.sync="selectedReceiptData"
                        :receipt-notify-emails.sync="selectedReceiptNotifyEmails"
                        :receipt-notify.sync="selectedReceiptNotify"
                        :receipt-type.sync="selectedReceiptType"
                        :table="table"
                        :table-total="tableUnpaidTotalForPayment"
                        receipt
                        @transaction:add:loading="(data) => { loading = true }"
                        @transaction:remove:loading="(data) => { loading = true }"
                      />
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <template v-if="viewData && paymentStep === 1">
          <v-divider />

          <v-card-actions class="pa-4 py-1">
            <table-carts-totals
              :can-add-coupon="canManageTable && paymentStep === 1"
              :can-remove-coupon="canManageTable"
              :view-data="viewData"
              class="pr-1"
            />
          </v-card-actions>
        </template>

        <v-card-actions
          v-if="!!tableCoupons.length"
          class="pa-0"
        >
          <v-alert
            border="left"
            class="py-1 ma-0 text-caption"
            dense
            icon="warning"
            tile
            type="warning"
            width="100%"
          >
            {{ $t('Restaurant.Tables.Notification.HasTableCouponPay') }}
          </v-alert>
        </v-card-actions>

        <v-divider />

        <v-card-actions class="pa-2">
          <v-spacer />

          <template v-if="paymentStep === 1">
            <v-btn
              class="light-blue--text text--darken-1"
              outlined
              text
              @click="onCancelButtonClick"
            >
              {{ $t('Common.Button.Close') }}
            </v-btn>

            <v-btn
              :disabled="loading || !!tableCoupons.length || !selectedUnpaidItems.length"
              :loading="loading"
              class="blue white--text elevation-0 ml-1 px-2"
              @click="onPaymentButtonClick('selected')"
            >
              {{ $t('Common.Button.Payment') }}
            </v-btn>

            <v-btn
              :disabled="loading"
              :loading="loading"
              class="green white--text elevation-0 ml-1 px-2"
              @click="onPaymentButtonClick('all')"
            >
              {{ $t('Common.Button.PaymentAll') }}
            </v-btn>
          </template>

          <template v-if="paymentStep === 2">
            <v-btn
              class="light-blue--text text--darken-1"
              outlined
              text
              @click="onBackButtonClick"
            >
              {{ $t('Common.Button.Back') }}
            </v-btn>

            <v-btn
              :disabled="loading || tableUnpaidBalance > 0 || tableUnpaidBalance < 0 || (appHasReceiptsEnabled && selectedReceiptType === ReceiptTypeEnum.INVOICE && !selectedReceiptData)"
              :loading="loading"
              class="green white--text elevation-0 ml-1"
              style="min-width: 150px;"
              @click="onPayButtonClick(selectedReceiptType !== ReceiptTypeEnum.NONE)"
            >
              <span class="mr-2">{{ $t('Common.Button.Payment') }}</span>
              <v-spacer />
              {{ tableUnpaidTotalForPayment | currency }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SelectTableCompletedOrders from '@/components/restaurant/tables/orders/SelectTableCompletedOrders'
import TablePaymentCommon         from '@/mixins/tables/tablePaymentCommon'
import TablePayment               from '@/components/restaurant/tables/payments/TablePayment'
import TableCartsTotals           from '@/components/restaurant/tables/orders/TableCartsTotals.vue'
import TableCommon                from '@/mixins/tables/tableCommon'
import ReceiptTypeEnum            from '@/api/Enums/ReceiptTypeEnum'
import PinManagerEnum             from '@/api/Enums/PinManagerEnum'
import { emailIsGuest }           from '@/lib/helper/helper'

export default {
  name      : 'TablePaymentSelectMenuItemDialog',
  components: {
    TableCartsTotals,
    TablePayment,
    SelectTableCompletedOrders
  },
  mixins: [TableCommon, TablePaymentCommon],
  props : {},
  data () {
    return {}
  },
  computed: {
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.loading = false
        this.selectedReceiptType = ReceiptTypeEnum.RECEIPT
        this.selectedReceiptData = null
        this.selectedReceiptNotify = []
        this.selectedReceiptNotifyEmails = []
      }
    }
  },
  created () {
    this.$bus.$on(window.SocketCommand.Ajax.Get, this.getData)
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Ajax.Get, this.getData)
  },
  methods: {
    getData (payload) {
      if (!this.isVisible) return

      const command = window.SocketCommand.Table.GetById
      const payloadCommand = window.SocketCommand.commandToClientFormat(payload?.command || '')
      const payloadTableIdsArray = payload?.TableId
      const payloadTableClose = payload?.TableClose

      if (!payload || (payloadCommand === command && payloadTableIdsArray.includes(this.table.Id))) {
        if (payloadTableClose) {
          this.onCancelButtonClick()
        } else {
          this.loading = true
          window.callAS(window.SocketCommand.Table.Show, { TableId: this.table.Id })
        }
      }
    },
    onPaymentButtonClick (type = 'selected') {
      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.ManageAllTables) && this.canManageTable && !this.isOwnTable, () => {
        this.gotoPaymentStep2(type)
      }, 'table-cancel-items')
    },

    gotoPaymentStep2 (type) {
      if (type === 'all') {
        this.$bus.$emit('table-order-select-all-carts-items')
      }

      this.$nextTick(() => {
        if (this.tableUnpaidBalance <= 0 && (this.selectedUnpaidItemsHaveFullDiscount || !!this.tableTotals.length) && !this.transactions.length) {
          this.transactions = [this.getZeroTransaction()]
          this.onPayButtonClick(false, true)
        } else {
          this.paymentStep = 2
        }
      })
    },

    onBackButtonClick () {
      this.paymentStep = 1
    },

    onPayButtonClick (autoPrint = true, isDiscountFull = false) {
      if (!this.table || !this.selectedUnpaidItems.length) return
      this.loading = true

      const items = this.selectedUnpaidItems.map(item => {
        return {
          RowId   : item.RowId,
          Quantity: item.UnitId > 1 ? item.UnitQuantity : item.Quantity
        }
      })

      const autoPrintReceipt = this.tableHasCardPaymentsThatRequireReceipt || autoPrint || isDiscountFull
      const docType = this.selectedReceiptType || ReceiptTypeEnum.RECEIPT
      const company = this.selectedReceiptData || null
      const printNotify = this.selectedReceiptNotify?.includes('print') || null
      const emailNotify = this.selectedReceiptNotify?.includes('email') || null
      const customerEmail = emailNotify && this.viewData?.SCustomer?.Email ? [this.viewData.SCustomer.Email] : []
      const dataEmails = this.selectedReceiptNotifyEmails || []
      const emailAddresses = [...new Set([...customerEmail, ...dataEmails])].filter(email => !emailIsGuest(email))
      const transactions = JSON.parse(JSON.stringify(this.transactions))
      const totals = JSON.parse(JSON.stringify(this.tableTotals))

      const requestData = {
        TableId       : this.table.Id,
        CartId        : this.table.CartId,
        Items         : items,
        Transactions  : transactions,
        Totals        : totals,
        Print         : printNotify,
        Email         : emailNotify,
        EmailAddresses: emailAddresses,
        Company       : company,
        ReceiptType   : docType,
        Receipt       : autoPrintReceipt,
        DiscountFull  : isDiscountFull
      }

      window.callAS(window.SocketCommand.Table.Pay, requestData)
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width : 959px) {
  /deep/ .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display : flex !important;
  }

  /deep/ .v-stepper__label {
    font-size : 12px;
  }
}

/deep/ .v-stepper__step {
  padding : 6px 24px;
}

/deep/ .v-stepper__header > hr {
  margin-top : 19px !important
}
</style>
