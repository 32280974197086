<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-0">
        <toolbar-period-selector
          v-model="dataTable.filter.filter_date"
          default-period="today"
        >
          <print-button
            :hide-label="$vuetify.breakpoint.xsOnly"
            :fab="$vuetify.breakpoint.xsOnly"
            btn-class="ma-1"
            color="primary"
            flat
            menu-offset-y
            @printer-selected="printer => printStatsProducts(printer)"
          />

          <v-btn
            v-if="userCanManage && (userIsOwner || userIsSuperAdmin)"
            :disabled="!dataTable.filter.filter_date"
            :color="$t('Customer.Table.Button.Export.Color')"
            :icon="$vuetify.breakpoint.xsOnly"
            class="elevation-0 ma-1"
            text
            @click="onExportButtonClick"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $t('Customer.Table.Button.Export.Icon') }}
            </v-icon>&nbsp;
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Customer.Table.Button.Export.Title').toLocaleUpperCase($i18n.locale) }}</span>
          </v-btn>

          <v-spacer />

          <filter-search v-model="dataTable.search.terms" />
        </toolbar-period-selector>
      </v-card-title>

      <v-divider />

      <v-container
        class="pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :footer-props="dataTable.footerProps"
              :headers="dataTableHeaders"
              :height="dataTable.resize.bodyHeight"
              :items="dataTable.data"
              :loading="dataTable.loading"
              :loading-text="dataTable.text.loadingText"
              :no-data-text="dataTable.text.noDataText"
              :no-results-text="dataTable.text.noResultsText"
              :options.sync="dataTable.options"
              :search="dataTable.search.terms"
              :server-items-length="dataTable.total"
              fixed-header
              item-key="Id"
            >
              <template #[`item.CategoryName`]="{item}">
                {{ item.CategoryName }}
              </template>

              <template #[`item.Name`]="{item}">
                {{ item.Name }}
              </template>

              <template #[`item.Quantity`]="{item}">
                {{ item.Quantity }}
              </template>

              <template #[`item.Sales`]="{item}">
                {{ item.Sales | currency }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import FilterSearch          from '@/components/common/filter/FilterSearch'
import FilterCommon          from '@/mixins/filter/filterCommon'
import DataTable             from '@/mixins/data-table/dataTable'
import AppData               from '@/mixins/appdata'
import Auth                  from '@/mixins/auth'
import ToolbarPeriodSelector from '@/components/common/ToolbarPeriodSelector.vue'
import PrintButton           from '@/components/common/print/PrintButton.vue'
import Printer               from '@/lib/helper/printer'

export default {
  name      : 'ProductStatistics',
  components: {
    PrintButton,
    ToolbarPeriodSelector,
    FilterSearch
  },
  directives: {},
  mixins    : [AppData, Auth, FilterCommon, DataTable],
  data () {
    return {
      selectDateRangeDialogVisible: false,

      dataTable: {
        socketCommand: {
          list: window.SocketCommand.Statistics.Products.All
        },
        filter: {
          filter_date: 'today'
        },
        options: {
          defaultSortBy: 'Quantity',
          sortBy       : ['Quantity'],
          sortDesc     : [true]
        },
        headers: [
          {
            text     : this.$t('Statistics.Product.Table.Headers.CategoryName'),
            value    : 'CategoryName',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Statistics.Product.Table.Headers.Name'),
            value    : 'Name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Statistics.Product.Table.Headers.Quantity'),
            value    : 'Quantity',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Statistics.Product.Table.Headers.UnitQuantity'),
            value    : 'UnitQuantity',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Statistics.Product.Table.Headers.Sales'),
            value    : 'Sales',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    this.$bus.$on(window.SocketCommand.Statistics.Products.Export, this.onExportResult)
  },
  mounted () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Statistics.Products.Export, this.onExportResult)
  },
  methods: {
    printStatsProducts (printer) {
      if (!printer) return
      const dataTablePayload = this.dataTableRequestData()
      const payload = {
        Period : this.dataTable.filter.filter_date,
        SortBy : dataTablePayload.sort_by,
        OrderBy: dataTablePayload.order_by
      }

      Printer.printStatsProducts(payload.Period, payload.SortBy, payload.OrderBy, printer)
    },

    onExportButtonClick () {
      if (!this.dataTable?.filter?.filter_date) return

      const dataTablePayload = this.dataTableRequestData()
      const payload = {
        Period : this.dataTable.filter.filter_date,
        SortBy : dataTablePayload.sort_by,
        OrderBy: dataTablePayload.order_by
      }

      window.callAS(window.SocketCommand.Statistics.Products.Export, payload)
    },

    onExportResult (response) {
      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Statistics.Product.Export.SuccessMessage', { email: this.posUser?.staff_email || '' }),
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
