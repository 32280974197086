<template>
  <v-container
    v-if="viewData"
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-expansion-panels
          accordion
          tile
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              class="px-2 py-0"
              :class="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
              style="min-height: 24px;"
              hide-actions
            >
              <template #default="{open}">
                <div class="divTable">
                  <div class="divTableRow grey--text text--darken-1 text-subtitle-2">
                    <div
                      class="divTableCell text-left"
                      style="width: 33%;"
                    >
                      {{ $t('Common.Misc.Subtotal').toLocaleUpperCase($i18n.locale) }}
                    </div>

                    <div
                      class="divTableCell text-center"
                      style="width: 33%;"
                    >
                      <v-icon
                        v-if="open"
                        color="orange"
                        size="32"
                      >
                        expand_more
                      </v-icon>
                      <v-icon
                        v-else
                        color="orange"
                        size="32"
                      >
                        expand_less
                      </v-icon>
                    </div>

                    <div
                      class="divTableCell text-right text-no-wrap"
                      style="min-width: 60px; width: 33%;"
                    >
                      {{ subtotal | currency }}
                    </div>
                  </div>
                </div>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-container
                class="pa-0"
                fluid
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <div
                      v-if="showTableTotals && !tableTotals.length"
                      style="float: none; clear: both; line-height: 22px;"
                      class="pa-0 py-2 px-2 text-subtitle-2"
                    >
                      <div class="">
                        <span class="grey--text text--darken-1">
                          {{ $t('Common.Misc.Discount').toLocaleUpperCase($i18n.locale) }}
                        </span>
                        <span
                          class="grey--text text--darken-1 float-right"
                        >
                          <v-btn
                            :disabled="loadingAddCoupon || !canAddCoupon || unpaidTotal <= 0"
                            :loading="loadingAddCoupon"
                            class="pa-0 ma-0 mb-1 green lighten-5 green--text"
                            width="22"
                            height="22"
                            icon
                            ripple
                            small
                            @click.native.stop="showSelectCouponDialog()"
                          >
                            <v-icon size="22">add</v-icon>
                          </v-btn>
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="showTableTotals && tableCoupons.length"
                      class="divTable text-body-2"
                    >
                      <template v-for="(tblTotal, index) in tableCoupons">
                        <div
                          :key="index"
                          class="divTableRow"
                        >
                          <div
                            class="divTableCell text-left pl-1 pr-1"
                            style="width: 28px;"
                          >
                            <v-btn
                              :disabled="tblTotal.Loading || !canRemoveCoupon"
                              :loading="tblTotal.Loading"
                              class="pa-0 ma-0 red--text"
                              width="20"
                              height="20"
                              icon
                              ripple
                              small
                              @click.native.stop="removeCoupon(tblTotal)"
                            >
                              <v-icon size="20">
                                close
                              </v-icon>
                            </v-btn>
                          </div>

                          <div
                            class="divTableCell text-left grey--text text--darken-1"
                            style="width: 100%;"
                          >
                            {{ tblTotal.Title }}
                          </div>

                          <div
                            class="divTableCell text-right text-no-wrap"
                            style="min-width: 60px;"
                          >
                            <span class="grey--text text--darken-1 pr-2">
                              -{{ tblTotal.Amount | currency }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </div>

                    <v-divider
                      class="my-1"
                      :style="isDarkMode ? '': 'border-color: rgba(0, 0, 0, 0.05);'"
                    />
                  </v-col>

                  <v-col
                    v-if="tableCouponsUsedDiscountTotal > 0"
                    cols="12"
                  >
                    <div class="divTable text-body-2 px-2">
                      <div class="divTableRow purple--text text--darken-1">
                        <div
                          class="divTableCell text-left"
                          style="width: 100%;"
                        >
                          {{ $t('Common.Misc.Discounts').toLocaleUpperCase($i18n.locale) }}
                        </div>

                        <div
                          class="divTableCell text-right text-no-wrap"
                          style="min-width: 60px;"
                        >
                          <span v-if="tableCouponsUsedDiscountTotal > 0">-</span>{{ tableCouponsUsedDiscountTotal | currency }}
                        </div>
                      </div>
                    </div>

                    <v-divider
                      class="my-1"
                      :style="isDarkMode ? '': 'border-color: rgba(0, 0, 0, 0.05);'"
                    />
                  </v-col>

                  <v-col cols="12">
                    <div class="divTable px-2 text-subtitle-2">
                      <div class="divTableRow blue--text text--darken-1">
                        <div
                          class="divTableCell text-left"
                          style="width: 100%;"
                        >
                          {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                        </div>

                        <div
                          class="divTableCell text-right text-no-wrap"
                          style="min-width: 60px;"
                        >
                          {{ tableTotal | currency }}
                        </div>
                      </div>
                    </div>

                    <v-divider
                      class="my-1"
                      :style="isDarkMode ? '': 'border-color: rgba(0, 0, 0, 0.05);'"
                    />
                  </v-col>

                  <v-col
                    v-if="paidTotal > 0"
                    cols="12"
                  >
                    <div class="divTable text-body-2 px-2">
                      <div class="divTableRow green--text text--darken-1">
                        <div
                          class="divTableCell text-left"
                          style="width: 100%;"
                        >
                          {{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Paid.Title').toLocaleUpperCase($i18n.locale) }}
                        </div>

                        <div
                          class="divTableCell text-right text-no-wrap"
                          style="min-width: 60px;"
                        >
                          <span v-if="paidTotal > 0">-</span>{{ paidTotal | currency }}
                        </div>
                      </div>
                    </div>

                    <v-divider
                      class="my-1"
                      :style="isDarkMode ? '': 'border-color: rgba(0, 0, 0, 0.05);'"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <div class="divTable text-subtitle-1 font-weight-bold px-2">
          <div class="divTableRow orange--text text--darken-1">
            <div
              class="divTableCell text-left"
              style="width: 100%;"
            >
              {{ $t('Common.Misc.Balance').toLocaleUpperCase($i18n.locale) }}
            </div>

            <div
              class="divTableCell text-right text-no-wrap"
              style="min-width: 60px;"
            >
              {{ unpaidTotal | currency }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <select-coupon-dialog
      :visible.sync="selectCouponDialogVisible"
      :menu-item="{Name: $t('Common.Misc.Discount').toLocaleUpperCase($i18n.locale), Price: unpaidTotal, Quantity: 1}"
      @cart:coupon-selected="onCouponSelected"
    />
  </v-container>
</template>

<script>
import Auth               from '@/mixins/auth'
import AppData            from '@/mixins/appdata'
import CompletedData      from '@/mixins/tables/completedData'
import PaymentData        from '@/mixins/tables/paymentData'
import SelectCouponDialog from '@/components/pos/SelectCouponDialog.vue'
import PinManagerEnum     from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'TableCartsTotals',
  components: { SelectCouponDialog },
  directives: {},
  mixins    : [Auth, AppData, PaymentData, CompletedData],
  props     : {
    viewData: {
      type   : Object,
      default: undefined
    },
    canAddCoupon: {
      type   : Boolean,
      default: true
    },
    canRemoveCoupon: {
      type   : Boolean,
      default: true
    },
    loading: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      loadingAddCoupon         : false,
      selectCouponDialogVisible: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.loading
      },
      set (val) {
        this.$emit('update:loading', val)
      }
    },

    showTableTotals () {
      return !this.paid && !this.canceled
    },

    subtotal () {
      return this.cartsPriceTotal()
    },

    paidTotal () {
      return this.sumPaymentsTransactions()
    },

    unpaidTotal () {
      return parseFloat((this.tableTotal - this.paidTotal).toFixed(2))
    },

    canceledTotal () {
      return this.canceledPriceTotal()
    },

    tableTotal () {
      return parseFloat((this.cartsPriceTotal(true) - this.tableCouponsUsedDiscountTotal).toFixed(2))
    },

    total () {
      if (this.paid) return this.sumPaymentsTransactions()
      if (this.canceled) return this.canceledPriceTotal()

      return this.cartsPriceTotal(true)
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.$bus.$on(window.SocketCommand.Table.Coupon.Add, this.onAddCoupon)
    this.$bus.$on(window.SocketCommand.Table.Coupon.Remove, this.onRemoveCoupon)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Table.Coupon.Add, this.onAddCoupon)
    this.$bus.$off(window.SocketCommand.Table.Coupon.Remove, this.onRemoveCoupon)
  },
  destroyed () {},
  methods: {
    showSelectCouponDialog (item) {
      this.executeWithPinPermissionManager(PinManagerEnum.TableDiscount, () => {
        this.selectCouponDialogVisible = true
      }, 'select-coupon')
    },

    onCouponSelected (coupon) {
      if (!coupon) return

      if (coupon.Type === 'F') {
        const total = this.total - parseFloat(coupon.Discount)

        if (total === 0 && this.tableTransactions.length > 0) {
          this.$bus.$emit('app-show-notification', {
            body   : this.$t('Pos.Pos.Notification.FullDiscountWithPaymentTransactions'),
            timeout: 3000,
            type   : 'error',
            icon   : 'warning'
          })

          return
        }

        if (total < 0) {
          this.$bus.$emit('app-show-notification', {
            body   : this.$t('Pos.Pos.Notification.CouponOverOrderTotal'),
            timeout: 3000,
            type   : 'error',
            icon   : 'warning'
          })

          return
        }
      }

      this.$set(this, 'loadingAddCoupon', true)
      this.isLoading = true

      this.addCoupon(coupon)
    },

    addCoupon (coupon) {
      if (!coupon) return

      window.callAS(window.SocketCommand.Table.Coupon.Add, {
        CartId : this.viewData.Id,
        TableId: this.viewData.TableId,
        Coupon : coupon
      })
    },
    onAddCoupon (data, payload) {
      this.viewData.TableTotals = Array.isArray(data) ? data : []

      this.$set(this, 'loadingAddCoupon', false)
      this.isLoading = false
    },

    removeCoupon (coupon) {
      if (!coupon) return

      this.$set(coupon, 'Loading', true)
      this.isLoading = true

      window.callAS(window.SocketCommand.Table.Coupon.Remove, {
        CartId : this.viewData.Id,
        TableId: this.viewData.TableId,
        Coupon : coupon
      })
    },
    onRemoveCoupon (data, payload) {
      const hash = payload?.Coupon?.Hash
      const couponItem = this.tableCoupons.find(total => total.Hash === hash)

      this.viewData.TableTotals = Array.isArray(data) ? data : []

      if (couponItem) {
        this.$set(couponItem, 'Loading', false)
      }
      this.isLoading = false
    },

    orderHasMenuItems (cart) {
      return !!cart?.Items?.filter(item => this.canceled ? true : item.Quantity)?.length
    },

    getOrderMenuItems (cart) {
      const items = []
      const seats = this.getCartItems(cart, false, false)

      seats.forEach(seat => {
        seat.Items.forEach(category => {
          category.Items.forEach(menuItem => {
            items.push(menuItem)
          })
        })
      })

      return items
    }
  }
}
</script>

<style scoped>
.divTable {
  display : table;
  width   : 100%;
}

.divTableRow {
  display : table-row;
}

.divTableCell {
  border-collapse : collapse;
  display         : table-cell;
  padding-top     : 0;
  padding-bottom  : 0;
}

.divTableCell, .divTableHead {
  border-bottom : none;
}

/deep/ .v-expansion-panel-content__wrap {
  padding : 4px;
}
</style>
