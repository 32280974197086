<template>
  <div class="d-inline-block print-receipt-button">
    <v-badge
      :value="!canIssueDocument"
      :color="'green'"
      :offset-x="badgeOffsetX"
      :offset-y="badgeOffsetY"
      overlap
      right
    >
      <template #badge>
        <v-icon
          dark
          v-text="'check'"
        />
      </template>

      <v-menu
        v-model="menuActive"
        :bottom="menuPosition === 'bottom'"
        :top="menuPosition === 'top'"
        :left="menuPosition === 'left'"
        :right="menuPosition === 'right'"
        :offset-x="menuOffsetX"
        :offset-y="menuOffsetY"
        :open-on-hover="openOnHover"
        :min-width="300"
        :nudge-width="300"
        auto
      >
        <template #activator="{on: onMenu}">
          <v-tooltip
            :bottom="tooltipPosition === 'bottom'"
            :top="tooltipPosition === 'top'"
            :left="tooltipPosition === 'left'"
            :right="tooltipPosition === 'right'"
            :disabled="disabled || disableTooltip"
            :open-delay="tooltipOpenDelay"
            :close-delay="tooltipCloseDelay"
            :color="color"
          >
            <template #activator="{on: onTooltip}">
              <v-btn
                :class="[btnClass, icon ? color : '', {'grey lighten-2': disabled && icon}]"
                :color="icon ? '' : color"
                :style="btnStyle ? btnStyle : small ? icon ? `width: ${smallSize}px; height: ${smallSize}px;` : `height: ${smallSize}px;` : ''"
                :dark="dark"
                :icon="icon"
                :small="small"
                :x-small="xSmall"
                :large="large"
                :outlined="outline"
                :text="flat"
                :depressed="depressed"
                :absolute="absolute"
                :disabled="disabled"
                :block="block"
                :fixed="fixed"
                :fab="fab"
                v-on="{...onMenu, ...onTooltip}"
              >
                <v-icon
                  v-if="!hideIcon"
                  :small="small && smallIcon"
                  :left="!!labelText && !hideLabel && !icon"
                >
                  {{ iconName }}
                </v-icon>
                <span v-if="labelText && !hideLabel && !icon">{{ labelText }}</span>
              </v-btn>
            </template>

            <span>{{ tooltipText }}</span>
          </v-tooltip>
        </template>

        <!-- ISSUE DOCUMENT -->
        <v-list
          v-if="canIssueDocument"
          class="py-0"
        >
          <v-list-item
            v-if="fnb"
            :disabled="!fnb"
            @click="onIssueDocumentClick (ReceiptTypeEnum.FNB)"
          >
            <v-list-item-action>
              <v-icon :color="fnb ? 'teal' : 'grey'">
                mdi-receipt-text-plus-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.IssueFnb') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="canIssueReceipt"
            :disabled="!receipt"
            @click="onIssueDocumentClick (ReceiptTypeEnum.RECEIPT)"
          >
            <v-list-item-action>
              <v-icon :color="receipt ? 'green' : 'grey'">
                mdi-receipt-text-plus-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.IssueReceipt') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="canIssueReceipt"
            :disabled="!invoice"
            @click="onIssueDocumentClick (ReceiptTypeEnum.INVOICE)"
          >
            <v-list-item-action>
              <v-icon :color="invoice ? 'blue' : 'grey'">
                mdi-invoice-text-plus-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.IssueInvoice') }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <!-- MANAGE ISSUED DOCUMENT -->
        <v-list
          v-else
          class="py-0"
        >
          <!-- PRINT DOCUMENT -->
          <v-list-item
            v-if="documentType === ReceiptTypeEnum.RECEIPT"
            :disabled="!userCanPrintReceipt"
            @click="onPrintDocumentClick(documentType)"
          >
            <v-list-item-action>
              <v-icon color="blue-grey">
                mdi-printer
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.PrintReceipt') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="documentType === ReceiptTypeEnum.INVOICE"
            :disabled="!userCanPrintReceipt"
            @click="onPrintDocumentClick(documentType)"
          >
            <v-list-item-action>
              <v-icon color="blue-grey">
                mdi-printer
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.PrintInvoice') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="documentType === ReceiptTypeEnum.FNB"
            :disabled="!userCanPrintReceipt"
            @click="onPrintDocumentClick(documentType)"
          >
            <v-list-item-action>
              <v-icon color="blue-grey">
                mdi-printer
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.PrintFnb') }}</v-list-item-title>
          </v-list-item>

          <!-- EMAIL DOCUMENT -->
          <template v-if="appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)">
            <v-list-item
              v-if="documentType === ReceiptTypeEnum.RECEIPT"
              :disabled="!appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
              @click="onEmailDocumentClick(documentType)"
            >
              <v-list-item-action>
                <v-icon :color="appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL) ? 'blue' : 'grey'">
                  mdi-email-arrow-right
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('Common.Button.EmailReceipt') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="documentType === ReceiptTypeEnum.INVOICE"
              :disabled="!appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
              @click="onEmailDocumentClick(documentType)"
            >
              <v-list-item-action>
                <v-icon :color="appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL) ? 'blue' : 'grey'">
                  mdi-email-arrow-right
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('Common.Button.EmailInvoice') }}</v-list-item-title>
            </v-list-item>
          </template>

          <!-- VIEW DOCUMENT -->
          <template v-if="documentUrl">
            <v-list-item
              v-if="documentType === ReceiptTypeEnum.RECEIPT"
              @click="onViewDocumentClick(documentUrl)"
            >
              <v-list-item-action>
                <v-icon color="light-green">
                  mdi-open-in-new
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('Common.Button.ViewReceipt') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="documentType === ReceiptTypeEnum.INVOICE"
              @click="onViewDocumentClick(documentUrl)"
            >
              <v-list-item-action>
                <v-icon color="light-green">
                  mdi-open-in-new
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('Common.Button.ViewInvoice') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="documentType === ReceiptTypeEnum.FNB"
              @click="onViewDocumentClick(documentUrl)"
            >
              <v-list-item-action>
                <v-icon color="light-green">
                  mdi-open-in-new
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('Common.Button.ViewFnb') }}</v-list-item-title>
            </v-list-item>
          </template>

          <v-divider />

          <!-- CANCEL DOCUMENT -->
          <v-list-item
            v-if="documentType === ReceiptTypeEnum.RECEIPT"
            @click="onCancelDocumentClick(documentId)"
          >
            <v-list-item-action>
              <v-icon color="red">
                mdi-close-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.CancelReceipt') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="documentType === ReceiptTypeEnum.INVOICE"
            @click="onCancelDocumentClick(documentId)"
          >
            <v-list-item-action>
              <v-icon color="red">
                mdi-close-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.CancelInvoice') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="documentType === ReceiptTypeEnum.FNB && canCancelFnb"
            @click="onCancelDocumentClick(documentId)"
          >
            <v-list-item-action>
              <v-icon color="red">
                mdi-close-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Common.Button.CancelFnb') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-badge>
  </div>
</template>

<script>
import Auth            from '@/mixins/auth'
import AppData         from '@/mixins/appdata'
import ReceiptTypeEnum from '@/api/Enums/ReceiptTypeEnum'
import NotifyTypeEnum  from '@/api/Enums/NotifyTypeEnum'

export default {
  name      : 'IssueReceiptDocumentButton',
  components: {},
  directives: {},
  mixins    : [AppData, Auth],
  props     : {
    tooltip: {
      type   : String,
      default: ''
    },
    label: {
      type   : String,
      default: ''
    },
    hideLabel: {
      type   : Boolean,
      default: false
    },
    btnClass: {
      type   : String,
      default: 'white--text'
    },
    btnStyle: {
      type   : String,
      default: ''
    },
    color: {
      type   : String,
      default: 'orange'
    },
    icon: {
      type   : Boolean,
      default: false
    },
    iconName: {
      type   : String,
      default: 'mdi-receipt-text-plus-outline'
    },
    hideIcon: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    },
    xSmall: {
      type   : Boolean,
      default: false
    },
    smallIcon: {
      type   : Boolean,
      default: false
    },
    smallSize: {
      type   : [Number, String],
      default: 32
    },
    large: {
      type   : Boolean,
      default: false
    },
    outline: {
      type   : Boolean,
      default: false
    },
    flat: {
      type   : Boolean,
      default: false
    },
    depressed: {
      type   : Boolean,
      default: false
    },
    absolute: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    block: {
      type   : Boolean,
      default: false
    },
    fixed: {
      type   : Boolean,
      default: false
    },
    fab: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    tooltipPosition: {
      type   : String,
      default: 'top'
    },
    tooltipOpenDelay: {
      type   : Number,
      default: 150
    },
    tooltipCloseDelay: {
      type   : Number,
      default: 150
    },
    disableTooltip: {
      type   : Boolean,
      default: false
    },
    canIssueDocument: {
      type   : Boolean,
      default: false
    },
    canIssueReceipt: {
      type   : Boolean,
      default: false
    },
    canCancelFnb: {
      type   : Boolean,
      default: false
    },
    documentUrl: {
      type   : String,
      default: ''
    },
    documentType: {
      type   : String,
      default: ''
    },
    documentId: {
      type   : [String, Number],
      default: undefined
    },
    badgeOffsetX: {
      type   : [Number, String],
      default: undefined
    },
    badgeOffsetY: {
      type   : [Number, String],
      default: undefined
    },
    menuPosition: {
      type   : String,
      default: 'bottom'
    },
    menuOffsetY: {
      type   : Boolean,
      default: false
    },
    menuOffsetX: {
      type   : Boolean,
      default: false
    },
    openOnHover: {
      type   : Boolean,
      default: false
    },
    receipt: {
      type   : Boolean,
      default: false
    },
    invoice: {
      type   : Boolean,
      default: false
    },
    fnb: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      menuActive: false
    }
  },
  computed: {
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },

    tooltipText () {
      if (this.tooltip) return this.tooltip

      return !this.canIssueDocument ? this.documentType === ReceiptTypeEnum.RECEIPT ? this.$t('Common.Button.ManageReceipt') : this.$t('Common.Button.ManageInvoice') : this.$t('Common.Button.IssueDocument')
    },

    labelText () {
      if (this.label) return this.label.toLocaleUpperCase(this.$i18n.locale)

      return (!this.canIssueDocument ? this.documentType === ReceiptTypeEnum.RECEIPT ? this.$t('Common.Button.ManageReceipt') : this.$t('Common.Button.ManageInvoice') : this.$t('Common.Button.IssueDocument')).toLocaleUpperCase(this.$i18n.locale)
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onIssueDocumentClick (docType = ReceiptTypeEnum.RECEIPT) {
      this.$emit('issue-document', {
        DocId         : null,
        DocType       : docType,
        Print         : docType === ReceiptTypeEnum.RECEIPT ? this.ReceiptsPrintNotify : this.ReceiptsInvoicePrintNotify,
        Email         : docType === ReceiptTypeEnum.RECEIPT ? this.ReceiptsEmailNotify : this.ReceiptsInvoiceEmailNotify,
        EmailAddresses: [],
        Company       : null
      })
    },

    onPrintDocumentClick (docType = ReceiptTypeEnum.RECEIPT) {
      this.$emit('print-document', {
        DocId         : this.documentId || null,
        DocType       : docType,
        Print         : true,
        Email         : false,
        EmailAddresses: [],
        Company       : null
      })
    },

    onEmailDocumentClick (docType = ReceiptTypeEnum.RECEIPT) {
      this.$emit('email-document', {
        DocId         : this.documentId || null,
        DocType       : docType,
        Print         : false,
        Email         : true,
        EmailAddresses: [],
        Company       : null
      })
    },

    onCancelDocumentClick (docId) {
      if (!docId) return

      this.$emit('cancel-document', { DocId: docId })
    },

    onViewDocumentClick (docUrl) {
      if (!docUrl) return

      this.$emit('view-document', {
        DocUrl: docUrl,
        DocId : this.documentId || null
      })
    }
  }
}
</script>

<style scoped>

</style>
