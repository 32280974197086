<template>
  <v-toolbar
    color="grey darken-2"
    dark
    dense
    flat
    max-height="48"
  >
    <div class="d-inline-flex align-center mr-2">
      <v-toolbar-title class="white--text text-body-1 mr-4">
        {{ $tc('Restaurant.Tables.ItemOrder.Select', 1) }}
      </v-toolbar-title>

      <v-btn
        class="pa-2 ma-1"
        color="grey darken-4"
        style="min-width: 0px;"
        @click="onMinusClick"
      >
        <v-icon>remove</v-icon>
      </v-btn>

      <v-btn
        class="pa-2 ma-1"
        color="grey darken-4"
        style="min-width: 0px;"
        @click="onPlusClick"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>

    <div
      class="grey text-center"
      style="width: 40px; height: 36px; line-height: 36px;"
    >
      {{ vModel }}
    </div>
  </v-toolbar>
</template>

<script>
import vModel from '@/mixins/vModel'

export default {
  name      : 'SelectMenuItemOrder',
  components: {},
  directives: {},
  mixins    : [vModel],
  props     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    onPlusClick () {
      if (parseInt(this.vModel) >= 10) return
      this.vModel = String(parseInt(this.vModel) + 1)
    },

    onMinusClick () {
      if (parseInt(this.vModel) <= 0) return
      this.vModel = String(parseInt(this.vModel) - 1)
    }
  }
}
</script>

<style scoped>

</style>
