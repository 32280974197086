<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          class="pl-1"
          color="red"
          height="80"
          max-height="80"
          flat
          dark
        >
          <v-avatar color="red darken-2">
            <v-icon dark>
              live_help
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Waiter.Button.Shift.Close.Title') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-progress-linear
          v-if="loading"
          color="secondary"
          height="2"
          indeterminate
          class="pa-0 ma-0"
        />

        <v-divider />

        <v-card-text class="pa-3">
          <v-container
            class="pa-1"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <div class="pa-2 mb-6">
                  {{ $t('Waiter.Button.Shift.Close.PopUp.ContentChangeWaiter') }}
                </div>
              </v-col>

              <!-- Source Waiter -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid
                  :class="{'pl-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.SourceWaiter`) }}
                      </h5>
                    </v-col>
                    <v-col cols="12">
                      <v-container
                        class="ma-0 pa-0"
                        fluid
                      >
                        <v-row style="min-height: 85px;">
                          <v-col
                            v-if="sourceWaiter"
                            cols="12"
                          >
                            <v-list-item
                              style="height: 110px;"
                              class="grey lighten-3"
                              ripple
                            >
                              <v-list-item-avatar size="54">
                                <v-icon
                                  color="light-green"
                                  size="54"
                                >
                                  room_service
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content class="grey--text text--darken-2">
                                <v-list-item-title class="text-left">
                                  {{ sourceWaiter.Firstname }} {{ sourceWaiter.Lastname }}
                                </v-list-item-title>

                                <v-list-item-subtitle v-if="sourceWaiter.Phone">
                                  <span class="grey--text">
                                    {{ sourceWaiter.Phone }}
                                  </span>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle v-if="sourceWaiter.Email">
                                  <span class="grey--text">
                                    {{ sourceWaiter.Email }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <!-- Target Waiter -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid
                  :class="{'pl-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.TargetWaiter`) }}
                      </h5>
                    </v-col>
                    <v-col cols="12">
                      <v-container
                        class="ma-0 pa-0"
                        fluid
                      >
                        <v-row

                          style="min-height: 85px;"
                        >
                          <v-col
                            v-if="targetWaiter"
                            cols="12"
                          >
                            <v-list-item
                              style="height: 110px;"
                              class="grey lighten-3"
                              ripple
                              @click="onSelectTargetWaiterStaff"
                            >
                              <v-list-item-avatar size="54">
                                <v-icon
                                  color="light-green"
                                  size="54"
                                >
                                  room_service
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content class="grey--text text--darken-2">
                                <v-list-item-title class="text-left">
                                  {{ targetWaiter.Firstname }} {{ targetWaiter.Lastname }}
                                </v-list-item-title>

                                <v-list-item-subtitle v-if="targetWaiter.Phone">
                                  <span class="grey--text">
                                    {{ targetWaiter.Phone }}
                                  </span>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle v-if="targetWaiter.Email">
                                  <span class="grey--text">
                                    {{ targetWaiter.Email }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col
                            v-else
                            cols="12"
                          >
                            <v-btn
                              outlined
                              color="grey"
                              block
                              class="ma-0"
                              style="height: 110px;"
                              @click="onSelectTargetWaiterStaff"
                            >
                              {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.SelectWaiter`).toLocaleUpperCase($i18n.locale) }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col cols="12">
                <div class="pa-1">
                  <v-checkbox
                    v-model="withPayments"
                    :label="$t(`Restaurant.Tables.Dialog.ChangeWaiter.WithPayments`)"
                    color="success"
                    hide-details
                    @change="onCheckboxClick"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="green--text"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.No') }}
          </v-btn>

          <v-btn
            :disabled="loading || !targetWaiter"
            :loading="loading"
            class="red white--text elevation-0"
            @click="onMoveSelectedButtonClick"
          >
            {{ $t('Common.Button.Yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <select-waiter-staff-dialog
      :visible.sync="selectWaiterStaffDialogVisible"
      :selected-waiter-staff.sync="targetWaiter"
      :exclude-waiter-staff-ids="excludeWaiterStaffIds"
    />
  </div>
</template>

<script>
import SelectWaiterStaffDialog from '@/components/waiters/SelectWaiterStaffDialog.vue'
import Auth                    from '@/mixins/auth'
import PinManagerEnum          from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'WaiterStaffShiftCloseDialog',
  components: { SelectWaiterStaffDialog },
  mixins    : [Auth],
  props     : {
    sourceWaiter: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      loading                       : false,
      selectWaiterStaffDialogVisible: false,
      withPayments                  : false,
      targetWaiter                  : null
    }
  },
  computed: {
    excludeWaiterStaffIds () {
      return this.sourceWaiter ? [this.sourceWaiter.Id] : []
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$bus.$on(window.SocketCommand.Table.ChangeWaiter, this.onChangeWaiterResponse)
      } else {
        this.$bus.$off(window.SocketCommand.Table.ChangeWaiter, this.onChangeWaiterResponse)
        this.targetWaiter = null
      }
    }
  },
  methods: {
    onCheckboxClick (withPayments) {
      this.$nextTick(() => {
        if (this.userNeedsPinPermission(PinManagerEnum.MovePayment) && withPayments) {
          this.withPayments = false

          this.$bus.$emit('show-manager-pin-dialog', {
            action  : 'waiter-close-shift-move-payments',
            payload : null,
            callback: () => {
              this.withPayments = true
            }
          })
        }
      })
    },

    onSelectTargetWaiterStaff () {
      this.selectWaiterStaffDialogVisible = true
    },

    onMoveSelectedButtonClick () {
      if (!this.sourceWaiter || !this.targetWaiter) return
      this.loading = true

      const payload = {
        CurrentStaffId: this.sourceWaiter.Id,
        StaffId       : this.targetWaiter.Id,
        WithPayments  : this.withPayments
      }
      window.callAS(window.SocketCommand.Table.ChangeWaiter, payload)
    },

    onChangeWaiterResponse (data) {
      if (data.status === 'success') {
        this.onCancelButtonClick()
        this.$emit('save-success')
      } else {
        this.loading = false
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.targetWaiter = null
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile {
  height : 110px;
}
</style>
