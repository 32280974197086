<template>
  <div>
    <template v-if="editable">
      <v-text-field
        v-model="vModel"
        :rules="rules"
        :readonly="readonly"
        :disabled="disabled"
        :clearable="clearable"
        :label="$t('Settings.Payment.Edit.Field.Label.Icon')"
        required
        @click="readonly ? showSelectIconDialog() : false"
      >
        <template #prepend>
          <v-icon
            v-if="vModel"
            class="pr-2"
          >
            {{ vModel }}
          </v-icon>

          <v-icon
            v-else
            class="pr-2"
          >
            mdi-select-drag
          </v-icon>
        </template>

        <template #append>
          <v-btn
            icon
            small
            @click="showSelectIconDialog"
          >
            <v-icon>
              mdi-image-search
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>

    <template v-else>
      <v-chip
        class="px-4 rounded-r-0 align-center justify-center"
        label
        style="height: 36px; width: calc(100% - 36px)"
      >
        <v-icon v-if="vModel">
          {{ vModel }}
        </v-icon>
      </v-chip>

      <v-btn
        class="px-2 rounded-l-0"
        color="grey darken-2"
        dark
        depressed
        style="min-width: 0"
        @click="showSelectIconDialog"
      >
        <v-icon size="20">
          mdi-image-search
        </v-icon>
      </v-btn>
    </template>

    <select-icon-dialog
      :visible.sync="selectIconDialogVisible"
      @icon:selected="onIconSelected"
    />
  </div>
</template>

<script>

import SelectIconDialog from '@/components/common/icon/SelectIconDialog.vue'

export default {
  name      : 'SelectIconInput',
  components: { SelectIconDialog },
  mixins    : [],
  props     : {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: ''
    },
    editable: {
      type   : Boolean,
      default: false
    },
    readonly: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    clearable: {
      type   : Boolean,
      default: false
    },
    rules: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      selectIconDialogVisible: false
    }
  },
  computed: {
    vModel: {
      get () {
        return this.value || ''
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {},
  created () {
  },
  beforeDestroy () {
  },
  methods: {
    onIconSelected (icon) {
      this.vModel = icon || ''
    },

    showSelectIconDialog () {
      if (this.disabled) return

      this.selectIconDialogVisible = true
    }
  }
}
</script>

<style scoped>

</style>
