<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-1">
        <v-toolbar
          class="filter-toolbar"
          :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
          flat
        >
          <v-spacer />

          <filter-search v-model="dataTable.search.terms" />

          <filter-options
            v-model="filtersModel"
            :filters="filterData"
          />
        </v-toolbar>
      </v-card-title>

      <v-container
        class="ma-0 pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :footer-props="dataTable.footerProps"
              :headers="dataTableHeaders"
              :height="dataTable.resize.bodyHeight"
              :items="dataTable.data"
              :loading="dataTable.loading"
              :loading-text="dataTable.text.loadingText"
              :no-data-text="dataTable.text.noDataText"
              :no-results-text="dataTable.text.noResultsText"
              :options.sync="dataTable.options"
              :search="dataTable.search.terms"
              :server-items-length="dataTable.total"
              fixed-header
              item-key="Id"
            >
              <template #[`item.status`]="{item}">
                <v-tooltip
                  :color="item.Status === 'ANSWERED' ? 'green' : item.Status === 'INCOMING' ? 'blue' : 'red'"
                  right
                >
                  <template #activator="{on}">
                    <v-icon
                      :color="item.Status === 'ANSWERED' ? 'green' : item.Status === 'INCOMING' ? 'blue' : 'red'"
                      v-on="on"
                    >
                      {{ item.Status === 'ANSWERED' ? 'phone_in_talk' : item.Status === 'INCOMING' ? 'phone_callback' : 'phone_missed' }}
                    </v-icon>
                  </template>

                  <span>
                    {{ item.StatusName }}
                  </span>
                </v-tooltip>
              </template>

              <template #[`item.customer`]="{item}">
                <span
                  v-if="parseInt(item.CustomerId) > 0 && posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
                  class="font-weight-medium"
                  style="cursor: pointer;"
                  @click="$bus.$emit('show-customer-details-dialog', item.CustomerId)"
                >
                  {{ item.CustomerName }}
                </span>

                <span
                  v-else-if="parseInt(item.CustomerId) === 0"
                  class="grey--text"
                >
                  {{ item.CustomerName }}
                </span>

                <span v-else-if="parseInt(item.CustomerId) < -1">
                  {{ Math.abs(parseInt(item.CustomerId)) }} {{ item.CustomerName }}
                </span>

                <span
                  v-else
                  class="font-weight-medium"
                >
                  {{ item.CustomerName }}
                </span>
              </template>

              <template #[`item.user`]="{item}">
                {{ item.Agent }}
              </template>

              <template #[`item.caller_id_number`]="{item}">
                <div v-if="item.CallerId === 'unknown' || item.CallerId === 'anonymous'">
                  <v-icon
                    :color="$t('Voip.Call.ListView.CallerId.Unknown.Color')"
                    small
                  >
                    {{ $t('Voip.Call.ListView.CallerId.Unknown.Icon') }}
                  </v-icon>

                  <span>
                    {{ $t('Voip.Call.ListView.CallerId.Unknown.Title').toLocaleUpperCase($i18n.locale) }}
                  </span>
                </div>

                <div v-else>
                  <v-icon
                    :color="$t('Voip.Call.ListView.CallerId.Phone.Color')"
                    small
                  >
                    {{ $t('Voip.Call.ListView.CallerId.Phone.Icon') }}
                  </v-icon>

                  <span>
                    {{ item.CallerId | phone }}
                  </span>
                </div>
              </template>

              <template #[`item.address`]="{item}">
                <template v-for="(add, index) in item.Addresses">
                  <div :key="index">
                    {{ add }}
                  </div>
                </template>
              </template>

              <template #[`item.incoming_at`]="{item}">
                {{ item.CreatedAt }}
              </template>

              <template #[`item.actions`]="{item}">
                <v-tooltip
                  v-if="posUserHasComponentPermission('Customers', 'ADD') && parseInt(item.CustomerId) === 0 && item.CallerId !== 'unknown' && item.CallerId !== 'anonymous'"
                  :color="$t('Voip.Call.ListView.CustomerAdd.Tooltip.Color')"
                  left
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="posUserHasComponentPermission('Customers', 'ADD') && parseInt(item.CustomerId) === 0 && item.CallerId !== 'unknown' && item.CallerId !== 'anonymous'"
                      :class="$t('Voip.Call.ListView.CustomerAdd.Button.Color')"
                      class="elevation-2 ma-0 ml-1"
                      dark
                      icon
                      v-on="on"
                      @click="showCustomerAddDialog(item)"
                    >
                      <v-icon>{{ $t('Voip.Call.ListView.CustomerAdd.Button.Icon') }}</v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('Voip.Call.ListView.CustomerAdd.Tooltip.Title') }}</span>
                </v-tooltip>

                <v-tooltip
                  v-if="posUserHasComponentPermission('Customers', 'ACCESS') && parseInt(item.CustomerId) < 0"
                  :color="$t('Voip.Call.ListView.SearchCustomer.Tooltip.Color')"
                  open-delay="1000"
                  left
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="posUserHasComponentPermission('Customers', 'ACCESS') && parseInt(item.CustomerId) < 0"
                      :class="$t('Voip.Call.ListView.SearchCustomer.Button.Color')"
                      :small="$vuetify.breakpoint.xsOnly"
                      class="elevation-2 ma-0 ml-1"
                      dark
                      icon
                      v-on="on"
                      @click="showSearchCustomerDialog(item)"
                    >
                      <v-icon :small="$vuetify.breakpoint.xsOnly">
                        {{ $t('Voip.Call.ListView.SearchCustomer.Button.Icon') }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('Voip.Call.ListView.SearchCustomer.Tooltip.Title') }}</span>
                </v-tooltip>

                <v-tooltip
                  v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && parseInt(item.CustomerId) > 0"
                  :color="$t('Voip.Call.ListView.CustomerDetails.Tooltip.Color')"
                  open-delay="1000"
                  left
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && parseInt(item.CustomerId) > 0"
                      :class="$t('Voip.Call.ListView.CustomerDetails.Button.Color')"
                      class="elevation-2 ma-0 ml-1"
                      :small="$vuetify.breakpoint.xsOnly"
                      dark
                      icon
                      v-on="on"
                      @click="$bus.$emit('show-customer-details-dialog', item.CustomerId)"
                    >
                      <v-icon :small="$vuetify.breakpoint.xsOnly">
                        {{ $t('Voip.Call.ListView.CustomerDetails.Button.Icon') }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('Voip.Call.ListView.CustomerDetails.Tooltip.Title') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppData       from '@/mixins/appdata'
import Auth          from '@/mixins/auth'
import DataTable     from '@/mixins/data-table/dataTable'
import FilterCommon  from '@/mixins/filter/filterCommon'
import FilterOptions from '@/components/common/filter/FilterOptions'
import FilterSearch  from '@/components/common/filter/FilterSearch'

export default {
  name      : 'CallHistory',
  components: {
    FilterOptions,
    FilterSearch
  },
  directives: {},
  mixins    : [AppData, Auth, FilterCommon, DataTable],
  data () {
    return {
      dataTable: {
        // dataStore    : 'customers',
        socketCommand: {
          list: window.SocketCommand.Voip.History
        },
        options: {
          defaultSortBy: 'incoming_at',
          sortBy       : ['incoming_at'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : ['user', 'address', 'incoming_at'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Voip.Call.Table.Headers.Status'),
            value    : 'status',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Voip.Call.Table.Headers.Customer'),
            value    : 'customer',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Voip.Call.Table.Headers.User'),
            value    : 'user',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Voip.Call.Table.Headers.CallerId'),
            value    : 'caller_id_number',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Voip.Call.Table.Headers.Address'),
            value    : 'address',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Voip.Call.Table.Headers.IncomingAt'),
            value    : 'incoming_at',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : '',
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption xs-2'
          }
        ]
      },

      filtersModel: {
        lang        : 'Voip.Call.Table',
        filterStatus: {
          key  : 'filter_status',
          value: this.$t('Voip.Call.Table.Filter.filterStatus.Items')[0].Value,
          items: this.$t('Voip.Call.Table.Filter.filterStatus.Items')
        },
        filterDate: {
          key    : 'filter_date',
          value  : null,
          visible: false,
          date   : null
        }
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Voip.Answer, this.getData)
    this.$bus.$on(window.SocketCommand.Voip.Incoming, this.getData)
    this.$bus.$on(window.SocketCommand.Voip.Hangup, this.getData)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Voip.Answer, this.getData)
    this.$bus.$off(window.SocketCommand.Voip.Incoming, this.getData)
    this.$bus.$off(window.SocketCommand.Voip.Hangup, this.getData)
  },
  methods: {
    showCustomerAddDialog (call) {
      this.$bus.$emit('show-customer-add-dialog', { Telephone: call.CallerId })
    },

    showSearchCustomerDialog (call) {
      this.$router.push({
        name  : 'Customers',
        params: { searchParam: call.CallerId }
      })
    },

    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },

    parseDate (date) {
      if (!date) {
        return null
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .FilterToolbar {
  margin-top    : 8px !important;
  margin-bottom : 0 !important;
}

/deep/ .FilterToolbar .v-toolbar__content,
/deep/ .FilterToolbar .v-toolbar__extension,
/deep/ .dateTextField .v-input__slot {
  padding : 0 !important;
}

/deep/ .dateTextField {
  max-width : 100%;
}

/deep/ .dateTextField .v-input__prepend-outer {
  margin-top : 8px;
}

/deep/ .dateTextField .v-input__control {
  line-height : 36px !important;
  height      : 36px !important;
  min-height  : 36px !important;
}
</style>
