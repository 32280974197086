import AppData from '../appdata'

export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [AppData],
  directives: {},
  watch     : {
    receivedOrderCount: {
      handler (newVal) {
        // console.log('receivedOrderCount::::::::', newVal)
        if (newVal > 0) {
          try {
            this.play()
            // eslint-disable-next-line no-empty
          } catch (e) {}
        } else {
          try {
            this.stop()
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
      },
      deep: true
    }
  },
  methods: {
    play () {
      try {
        this.orderSound.play()
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    stop () {
      try {
        this.orderSound.pause()
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  },
  computed: {
    defaultSoundName () {
      return this.appConfig.LOCATION_DATA.Sound || 'sound_4.mp3'
    },
    orderSound () {
      return this.appConfig && this.appConfig.LOCATION_DATA ? new Audio(this.appConfig.LOCATION_DATA.CdnUrl + 'assets/sound/' + this.defaultSoundName) : new Audio()
    },
    receivedOrderCount () {
      return this.orders.filter(order => parseInt(order.StatusId) === 11 || parseInt(order.StatusId) === 20 || parseInt(order.StatusId) === 0).length
    },
    orders () {
      return this.$DataStore?.ordersDataToday?.items || []
    }
  },
  created () {
    this.orderSound.loop = true
  },
  mounted () {},
  updated () {},
  beforeDestroy () {
    this.stop()
  }

}
