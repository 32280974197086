<template>
  <div
    :class="classes"
    :style="styles"
  >
    <svg
      viewBox="0 0 61.21 46.85"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          :style="`fill: ${color.darken2}`"
          d="M61.21,36.61H54.13V17.91A10.84,10.84,0,0,0,43.3,7.09H17.91A10.83,10.83,0,0,0,7.09,17.91v18.7H0V17.91A17.92,17.92,0,0,1,17.91,0H43.3A17.92,17.92,0,0,1,61.21,17.91Z"
        />
        <rect
          :style="`fill: ${color.lighten2}`"
          height="34.25"
          rx="2.31"
          width="34.25"
          x="13.48"
          y="12.6"
        />
      </g>
    </svg>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
export default {
  name      : 'FloorPlanChair',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    color : Object,
    top   : Boolean,
    left  : Boolean,
    right : Boolean,
    bottom: Boolean,
    circle: Boolean,
    radius: Number,
    total : Number,
    x     : Number,
    y     : Number
  },
  data () {
    return {}
  },
  computed: {
    classes () {
      return {
        chair                  : true,
        'chair-position-top'   : this.top,
        'chair-position-left'  : this.left,
        'chair-position-right' : this.right,
        'chair-position-bottom': this.bottom
      }
    },
    styles () {
      if (this.top || this.bottom) {
        return {
          left: (this.x * 50) + 'px'
        }
      }
      if (this.left || this.right) {
        return {
          top: (this.y * 50) + 'px'
        }
      }
      if (this.circle) {
        const radius = (this.radius + 50) / 2
        const offsetAngle = 360 / this.total
        const rotateAngle = offsetAngle * this.x
        return {
          transform: `rotate(${ rotateAngle }deg) translate(${ radius }px)  rotate(90deg)`,
          top      : 'calc(50% - 25px)',
          left     : 'calc(50% - 25px)'
        }
      }
      return {}
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
.chair {
  max-width : 40px;
  width     : 40px;
  margin    : 5px;
  height    : auto;
  position  : absolute;
}

.chair-position-top {
  bottom : 100%;
}

.chair-position-left {
  right     : 100%;
  transform : rotate(-90deg);
}

.chair-position-right {
  left      : 100%;
  transform : rotate(90deg);
}

.chair-position-bottom {
  top       : 100%;
  transform : rotate(180deg);
}
</style>
