<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Settings.Printer.PrinterGroupAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Printer.PrinterGroupAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!itemToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Settings.Printer.PrinterGroupAddDialog.Toolbar.Add.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Settings.Printer.PrinterGroupAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-1"
          >
            <div class="body-3">
              {{ $t('Settings.Printer.PrinterGroupAddDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Settings.Printer.PrinterGroupAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="printerGroupForm"
            v-model="printerGroupFormValid"
            @submit.prevent
          >
            <v-container
              fluid
              class="pa-0"
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editPrinterGroup.Name"
                    :counter="255"
                    :hint="$t('Settings.Printer.PrinterGroupAddDialog.Field.Name.Hint')"
                    :label="$t('Settings.Printer.PrinterGroupAddDialog.Field.Name.Label')"
                    :prepend-icon="$t('Settings.Printer.PrinterGroupAddDialog.Field.Name.Icon')"
                    :rules="validationRules.printerGroupForm.Name"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editPrinterGroup.Description"
                    :counter="255"
                    :hint="$t('Settings.Printer.PrinterGroupAddDialog.Field.Description.Hint')"
                    :label="$t('Settings.Printer.PrinterGroupAddDialog.Field.Description.Label')"
                    :prepend-icon="$t('Settings.Printer.PrinterGroupAddDialog.Field.Description.Icon')"
                    :rules="validationRules.printerGroupForm.Description"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>
                  <v-switch
                    v-model="editPrinterGroup.Status"
                    inset
                    :label="editPrinterGroup.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'PrinterGroupAddDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading              : false,
      printerGroupFormValid: false,
      newPrinterGroup      : null,
      tmpPrinterGroup      : {
        Id           : null,
        Name         : '',
        Description  : '',
        Status       : true,
        PrintersCount: 0
      },
      validationRules: {
        printerGroupForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 255 || this.$t('Settings.Printer.PrinterGroupAddDialog.Field.Name.Error.Between')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editPrinterGroup: {
      get () {
        if (this.itemToEdit) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.newPrinterGroup = JSON.parse(JSON.stringify(this.itemToEdit))
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.newPrinterGroup = JSON.parse(JSON.stringify(this.tmpPrinterGroup))
        }
        return this.newPrinterGroup
      }
    }
  },
  watch: {
    visible (newVal) {
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.printerGroupForm) this.$refs.printerGroupForm.resetValidation()
        })
      }
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Printer.Group.Save, this.onSaveResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Printer.Group.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      if (this.$refs.printerGroupForm) this.$refs.printerGroupForm.validate()

      if (this.printerGroupFormValid) {
        this.loading = true
        window.callAS(window.SocketCommand.Settings.Printer.Group.Save, this.editPrinterGroup)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Printer.PrinterGroupAddDialog.Notification.Success'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Printer.PrinterGroupAddDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.printerGroupForm) this.$refs.printerGroupForm.resetValidation()
      this.newPrinterGroup = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
