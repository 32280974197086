<template>
  <v-btn
    :disabled="isDisabled"
    :loading="isLoading"
    class="light-blue white--text elevation-0"
    @click="translateAllFields()"
  >
    <v-icon left>
      mdi-translate
    </v-icon>
    {{ $t('Common.Button.TranslateAll').toLocaleUpperCase($i18n.locale) }}
  </v-btn>
</template>

<script>

import AppData             from '@/mixins/appdata'
import TranslateFieldMixin from '@/components/common/translation/translateFieldMixin'

export default {
  name      : 'TranslateAllFieldsButton',
  components: {},
  directives: {},
  mixins    : [AppData, TranslateFieldMixin],
  props     : {
    item: {
      type   : [Object, Array],
      default: undefined
    },

    field: {
      type   : Array,
      default: undefined
    },

    localeFrom: {
      type   : String,
      default: ''
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    isDisabled () {
      return this.isLoading ||
        this.disabled ||
        !this.field ||
        (Array.isArray(this.field) && !this.field?.length)
    },
    isLoading () {
      return !!this.item?.loadingTranslation
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async translateAllFields () {
      const fields = this.field
      const item = this.item
      const localeFrom = this.localeFrom
      const langs = this.selectedLanguages.filter(lang => lang.Code !== localeFrom) || []

      if (Array.isArray(item)) {
        for (let lng = 0; lng < langs.length; lng++) {
          const localeTo = langs[lng].Code
          for (let opt = 0; opt < item.length; opt++) {
            await this.translateField(fields, item[opt], localeTo, localeFrom)
          }
        }
      } else {
        for (let lng = 0; lng < langs.length; lng++) {
          const localeTo = langs[lng].Code
          await this.translateField(fields, item, localeTo, localeFrom)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
