<template>
  <v-app>
    <app-toolbar
      v-if="appConfig"
      :items="appConfig.toolbarNavigationItems"
      @app:logout="showLogOutDialog"
    />

    <v-main>
      <v-container
        class="pa-0 fill-height"
        fluid
      >
        <v-row
          class="fill-height align-center"
          no-gutters
        >
          <slot />
        </v-row>
      </v-container>

      <confirm-dialog
        :html-content="`${$t('Common.Misc.LogoutDialog.Body')}<br>`"
        :html-title="`${$t('Common.Misc.LogoutDialog.Title')}`"
        :info-bar-body="$t('Common.Misc.LogoutDialog.Warning')"
        :title-class="'red white--text lighten-0'"
        :visible.sync="confirmDialogLogOutVisible"
        info-bar
        yes-button-event="logout-yes"
        @logout-yes="onLogOutYes"
      />

      <confirm-dialog
        :html-content="`${$t('Common.Misc.ExitAppDialog.Body')}<br>`"
        :html-title="`${$t('Common.Misc.ExitAppDialog.Title')}`"
        :info-bar-body="$t('Common.Misc.ExitAppDialog.Warning')"
        :title-class="'red white--text lighten-0'"
        :visible.sync="confirmDialogExitAppVisible"
        info-bar
        title-icon="exit_to_app"
        yes-button-event="exit-yes"
        @exit-yes="onExitAppYes"
      />
    </v-main>

    <v-footer
      id="appFooter"
      class="secondary white--text gfp-footer px-1"
      color="grey"
      fixed
      app
    >
      <div
        class="gfp-footer text-center"
        style="width: 100%;"
      >
        © {{ new Date().getFullYear() }} <a
          href="https://www.otodev.gr/"
          target="_blank"
          class="gfp-footer"
        ><strong>OTO Dev</strong></a> - Powered By <a
          href="https://getfoodpro.gr/"
          target="_blank"
          class="gfp-footer"
        ><strong>GETFOOD Pro</strong></a>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import AppData                         from '@/mixins/appdata'
import Auth                            from '@/mixins/auth'
import AppToolbar                      from '@/components/common/AppToolbar.vue'
import isElectron                      from '@/electron/isElectron'
import ipcRendererElectron             from '@/electron/ipcRendererElectron'
import ipcCommandsEnum                 from '@/electron/ipcCommandsEnum'
import { stopCloudUpdatePollingCheck } from '@/api/ApiUpdate'
import ConfirmDialog                   from '@/components/common/ConfirmDialog.vue'

export default {
  name      : 'LockedLayout',
  components: {
    AppToolbar,
    'confirm-dialog': ConfirmDialog
  },
  mixins: [AppData, Auth],
  beforeRouteLeave (to, from, next) {
    next({ to: 'DomainLocked' })
  },
  data () {
    return {
      confirmDialogLogOutVisible : false,
      confirmDialogExitAppVisible: false
    }
  },
  computed: {},
  watch   : {},
  created () {
    this.$vuetify.theme.dark = true

    // eslint-disable-next-line no-console
    console.log('appConfig::', this.appConfig)
    // eslint-disable-next-line no-console
    console.log(`%c${ '-'.repeat(15) } APP CREATED ${ this.appVersion } ${ this.appConfig?.LOCATION_DATA.SiteUrl } ${ '-'.repeat(15) }`, 'background-color: #388E3C; color: #A5D6A7; padding: 10px 10px;')
    this.$bus.$on('app-logout', this.appLogout)

    if (isElectron()) {
      const ipcRenderer = ipcRendererElectron()
      ipcRenderer.on(ipcCommandsEnum.AppExit, () => {
        this.onAppExit()
      })
    }
  },
  mounted () {
  },
  updated () {
    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },
  beforeDestroy () {
    // eslint-disable-next-line no-console
    console.log('%c--------------- APP DESTROYED ---------------', 'background-color: #E53935; color: #EF9A9A; padding: 10px 10px;')
    this.$bus.$off('app-logout', this.appLogout)
  },
  methods: {
    onAppExit () {
      this.navigationDrawer = false
      this.confirmDialogExitAppVisible = true
    },
    onExitAppYes () {
      this.$bus.$emit('app-logout')
      if (isElectron()) {
        const ipcRenderer = ipcRendererElectron()
        ipcRenderer.send(ipcCommandsEnum.AppExit)
      }
    },

    showLogOutDialog () {
      this.confirmDialogLogOutVisible = true
    },
    onLogOutYes () {
      this.$bus.$emit('app-logout')
    },
    appLogout (resetApp = false) {
      if (this.$router.currentRoute.name === 'Pos') {
        this.$bus.$emit('app-logout-from-pos-screen')
        setTimeout(() => {
          if (resetApp) {
            this.resetApp()
          } else {
            this.logout()
          }
        }, 350)
        return
      }
      if (resetApp) {
        this.resetApp()
      } else {
        this.logout()
      }
    },
    logout () {
      window.API.post(window.APICall.logout, {})
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
        .finally(() => {
          this.resetApp()
          // eslint-disable-next-line no-console
          console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>> DefaultLayout ----- onAppLogout')
        })
    },

    resetApp () {
      this.$snotify.clear()
      this.PIN = ''
      this.pinCode = []
      this.posUser = null
      this.authorizationToken = ''
      window.staffName = ''
      window.authorizationToken = ''
      window.appConfig = null
      window.socketId = null
      this.$sessionStorage && this.$sessionStorage.clear()
      window.Socket && window.Socket.disconnect()
      stopCloudUpdatePollingCheck()
    }
  }

}

</script>

<style>
.v-data-table__wrapper > table > tbody > tr > td {
  padding    : 0 8px;
  min-height : 48px;
}

.v-application--is-ltr .v-data-footer__pagination {
  margin : 0 8px 0 2px;
}

.filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

@media only screen and (max-width : 599px) {
  .v-data-table__wrapper > table > tbody > tr > td {
    padding    : 0 8px;
    min-height : 22px !important;
  }

  .v-data-table__mobile-row__header, .v-data-table__mobile-row__cell {
    font-size   : 0.75rem !important;
    line-height : 1.25rem;
    padding     : 2px;
  }

  .v-slide-group__next, .v-slide-group__prev {
    flex      : 0 1 28px;
    min-width : 28px;
  }
}

.gfp-footer {
  font-size : 14px;
  color     : #FFFFFF;
}

.gfp-footer > a,
.gfp-footer > a:link,
.gfp-footer > a:visited,
.gfp-footer > a:active {
  text-decoration : none !important;
  color           : #FFFFFF !important;
}

.gfp-footer > a:hover {
  text-decoration : underline !important;
  color           : #FFFFFF !important;
}

.divTable {
  display        : table;
  width          : 100%;
  border-spacing : 0;
}

.divTableRow {
  display : table-row;
}

.v-application.theme--dark .divTableBody .divTableRow:nth-child(even) {
  background-color : #212121;
}

.v-application.theme--light .divTableBody .divTableRow:nth-child(even) {
  background-color : #F7F7F7;
}

.v-application.theme--dark .divTableBody .divTableRow:nth-child(odd) {
  background-color : #2c2c2c;
}

.v-application.theme--light .divTableBody .divTableRow:nth-child(odd) {
  background-color : #FFF;
}

.divTableCell, .divTableHead {
  border-collapse : collapse;
  display         : table-cell;
  padding         : 6px 6px 6px 6px;
  vertical-align  : middle;
}

.divTableGroup {
  display : table-row-group;
}

.v-application.theme--dark .divTableCell, .v-application.theme--dark .divTableHead {
  border-bottom : 1px solid rgba(255, 255, 255, 0.12);
}

.v-application.theme--light .divTableCell, .v-application.theme--light .divTableHead {
  border-bottom : 1px solid rgba(0, 0, 0, 0.12);
}

.v-application.theme--dark .divTableCell {
  color : white;
}

.v-application.theme--light .divTableCell {
  color : black;
}

.divTableHead {
  color          : grey;
  padding        : 16px 10px;
  vertical-align : middle;
  white-space    : nowrap;
}

.divTableHeading {
  display     : table-header-group;
  font-weight : bold;
}

.v-application.theme--dark .divTableHeading, .v-application.theme--dark .divTableFoot {
  background-color : #191919;
}

.v-application.theme--light .divTableHeading, .v-application.theme--dark .divTableFoot {
  background-color : #EEE;
}

.divTableFoot {
  display     : table-footer-group;
  font-weight : bold;
}

.divTableBody {
  display : table-row-group;
}

</style>
