import MessageModel   from '../../Models/messages/MessageModel'
import BaseCollection from '../../../lib/data/Collections/BaseCollection'
import SocketCommand  from '../../SocketCommand'

export default class MessageCollection extends BaseCollection {
  constructor (items = []) {
    super(items, MessageModel)
  }

  boot () {
    super.boot()

    this.fetchCommand = SocketCommand.Message.All
  }

  /* PROPERTIES */

  /* METHODS */

  filteredAndGrouped (filterByProperty, groupByProperty) {
    const filterResults = filterByProperty === '' ? this : this.filter(message => message.Type.GroupId.toString() === filterByProperty.toString() && message.Active)
    const unsortedObj = filterResults.reduce(
      (objectsByKeyValue, obj) => ({
        ...objectsByKeyValue,
        [groupByProperty.split('.').reduce((o, i) => o[i], obj)]: (objectsByKeyValue[groupByProperty.split('.').reduce((o, i) => o[i], obj)] || []).concat(obj)
      }),
      {}
    )

    const sortedKeys = Object.keys(unsortedObj).sort().reverse()
    const sortedObj = {}
    sortedKeys.forEach(key => {
      sortedObj[key] = unsortedObj[key]
    })

    return sortedObj
  }

  /* API METHODS */

  onFetch (data) {
    if (data.Messages) super.onFetch(data.Messages)
    if (data.hasOwnProperty('Groups')) window.Vue.$DataStore.messages.groups.clear().add(data.Groups)
  }
}
