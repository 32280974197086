export default {

  Language: {
    el: {
      active: true,
      name  : 'Ελληνικά',
      locale: 'el',
      code  : 'el-GR',
      code2 : 'el_GR'
    },
    en: {
      active: true,
      name  : 'Αγγλικά',
      locale: 'en',
      code  : 'en-US',
      code2 : 'en_US'
    },
    bg: {
      active: false,
      name  : 'Βουλγάρικα',
      locale: 'bg',
      code  : 'bg-BG',
      code2 : 'bg_BG'
    }
  },

  Date: {
    Title     : 'Ημερομηνία',
    DateShort : 'Ημ/νία',
    Time      : 'Ώρα',
    Minute    : 'Λεπτά | Λεπτό | Λεπτά',
    Today     : 'Σήμερα',
    Day       : 'Ημέρα',
    Week      : 'Εβδομάδα',
    Month     : 'Μήνας',
    Year      : 'Έτος',
    Period    : 'Περίοδος',
    PeriodFrom: 'Από',
    PeriodTo  : 'Έως',
    WeekDays  : {
      Short: ['Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ', 'Κυρ'],
      Long : ['Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο', 'Κυριακή']
    },
    Now: 'Τώρα'
  },

  Update: {
    Cloud: {
      Title          : 'Nέα έκδοση διαθέσιμη',
      BodyWithVersion: 'Υπάρχει διαθέσιμη ενημέρωση του συστήματος. Πατήστε "ΕΝΗΜΕΡΩΣΗ ΤΩΡΑ" για να ολοκληρωθεί και να μεταβείτε από την έκδοση v{CurrentVersion} στην νέα έκδοση v{ServerVersion}.',
      BodyNoVersion  : 'Υπάρχει διαθέσιμη ενημέρωση του συστήματος. Πατήστε "ΕΝΗΜΕΡΩΣΗ ΤΩΡΑ" για να ολοκληρωθεί και να μεταβείτε στην νέα έκδοση.'
    }
  },

  Button: {
    All             : 'Όλα',
    PaymentAll      : 'Πληρωμή Όλων',
    Backup          : 'Backup',
    Upgrade         : 'Αναβάθμιση',
    Downgrade       : 'Υποβάθμιση',
    ChangePlan      : 'Αλλαγή Πλάνου',
    RenewPlan       : 'Ανανέωση Συνδρομής',
    CurrentPlan     : 'Τρέχον Πλάνο',
    More            : 'Περισσότερα',
    Save            : 'Αποθήκευση',
    Cancel          : 'Ακύρωση',
    CancelWithRefund: 'Ακύρωση & Επιστροφή',
    Close           : 'Κλείσιμο',
    Duplicate       : 'Αντιγραφή',
    Delete          : 'Διαγραφή',
    Update          : 'Ενημέρωση',
    Send            : 'Αποστολή',
    Here            : 'Εδώ',
    Translate       : 'Μετάφραση',
    TranslateAll    : 'Μετάφραση Όλων',
    Yes             : 'Ναι',
    No              : 'Όχι',
    Ok              : 'Οκ',
    Next            : 'Επόμενο',
    Previous        : 'Προηγούμενο',
    Back            : 'Πίσω',
    Dismiss         : 'Καθαρισμός',
    View            : 'Προβολή',
    Add             : 'Προσθήκη',
    Remove          : 'Αφαίρεση',
    Reset           : 'Επαναφορά',
    End             : 'Τέλος',
    Finish          : '@:Common.Button.End',
    Preview         : 'Προεπισκόπηση',
    Renew           : 'Ανανέωση',
    Copy            : 'Αντιγραφή',
    Edit            : 'Επεξεργασία',
    Move            : 'Μεταφορά',
    MoveSelected    : 'Μεταφορά Επιλεγμένων',
    MoveAll         : 'Μεταφορά Όλων',
    Print           : 'Εκτύπωση',
    PrintOrder      : 'Εκτύπωση Δελτίου Σύνοψης Παραγγελίας',
    PrintPreparation: 'Εκτύπωση Δελτίου Προετοιμασίας',
    PrintReceipt    : 'Εκτύπωση Απόδειξης',
    PrintInvoice    : 'Εκτύπωση Τιμολογίου',
    PrintFnb        : 'Εκτύπωση Δελτίου Παραγγελίας',
    ViewDocument    : 'Προβολή Παραστατικού',
    PrintDocument   : 'Εκτύπωση Παραστατικού',
    RetryDocument   : 'Διαβίβαση Παραστατικού',
    EmailDocument   : 'Αποστολή Παραστατικού με Email',
    CancelReceipt   : 'Ακύρωση Απόδειξης',
    CancelInvoice   : 'Ακύρωση Τιμολογίου',
    CancelFnb       : 'Ακύρωση Δελτίου Παραγγελίας',
    ManageDocuments : 'Διαχείριση Παραστατικών',
    ManageDocument  : 'Διαχείριση Παραστατικού',
    ManageReceipt   : 'Διαχείριση Απόδειξης',
    ManageInvoice   : 'Διαχείριση Τιμολογίου',
    ManageFnb       : 'Διαχείριση Δελτίου Παραγγελίας',
    EmailReceipt    : 'Αποστολή Απόδειξης με Email',
    EmailInvoice    : 'Αποστολή Τιμολογίου με Email',
    EmailFnb        : 'Αποστολή Δελτίου Παραγγελίας με Email',
    ViewReceipt     : 'Προβολή Απόδειξης',
    ViewInvoice     : 'Προβολή Τιμολογίου',
    ViewFnb         : 'Προβολή Δελτίου Παραγγελίας',
    IssueDocument   : 'Έκδοση Παραστατικού',
    IssueReceipt    : 'Έκδοση Απόδειξης',
    IssueInvoice    : 'Έκδοση Τιμολογίου',
    IssueFnb        : 'Έκδοση Δελτίου Παραγγελίας',
    ExternalReceipt : 'Εξωτερική Απόδειξη',
    Payment         : 'Πληρωμή',
    TransactionAdd  : 'Προσθήκη Συναλλαγής',
    Left            : 'Αριστερά',
    Right           : 'Δεξιά',
    Toggle          : {
      Active    : 'Ενεργό',
      ActiveF   : 'Ενεργή',
      ActiveM   : 'Ενεργός',
      Inactive  : 'Ανενεργό',
      InactiveF : 'Ανενεργή',
      InactiveM : 'Ανενεργός',
      Horizontal: 'Οριζόντια',
      Vertical  : 'Κάθετα',
      Locked    : 'Κλειδωμένο',
      Unlocked  : 'Ξεκλείδωτο'
    },
    Import: {
      Icon : 'file_upload',
      Title: 'Εισαγωγή'
    },
    Export: {
      Icon : 'file_download',
      Title: 'Εξαγωγή'
    }
  },

  Misc: {
    Desc         : 'Περιγραφή',
    Printer      : 'Εκτυπωτές | Εκτυπωτής | Εκτυπωτές',
    Total        : 'Σύνολο',
    Change       : 'Ρέστα',
    Payment      : 'Πληρωμή',
    Subtotal     : 'Υποσύνολο',
    Name         : 'Όνομα',
    Discount     : 'Έκπτωση',
    Discounts    : 'Εκπτώσεις',
    ExtraCharges : 'Επιπλέον Χρέωση',
    Balance      : 'Υπόλοιπο',
    Tip          : 'Tip',
    Details      : 'Στοιχεία',
    Quantity     : 'Ποσότητα',
    Status       : 'Κατάσταση',
    Welcome      : 'Καλώς Ήρθατε',
    All          : 'Όλα',
    And          : 'και',
    In           : 'σε',
    RequiredField: '* Υποδηλώνει υποχρεωτικό πεδίο',
    UsersOnline  : 'Χρήστες Online',
    Notification : {
      ImportPlatformInProgress: 'Παρακαλώ περιμένετε μέχρι να<br>ολοκληρωθεί η εισαγωγή του καταλόγου',
      ImportEFoodSuccess      : 'Η εισαγωγή απο το E-Food ξεκίνησε με επιτυχία',
      ImportFoodySuccess      : 'Η εισαγωγή απο το Foody ξεκίνησε με επιτυχία',
      ImportFagiSuccess       : 'Η εισαγωγή απο το Fagi ξεκίνησε με επιτυχία',
      ImportGetFoodSuccess    : 'Η εισαγωγή απο το Get Food ξεκίνησε με επιτυχία',
      FieldError              : 'Ελέγξτε ότι συμπληρώσατε όλα τα στοιχεία σωστά!',
      GenericError            : 'Υπήρξε κάποιο πρόβλημα. Προσπαθήστε ξανά.',
      IssueReceipt            : {
        Success: 'Το παραστατικό έχει εκδοθεί επιτυχώς',
        Error  : 'Υπήρξε πρόβλημα κατά την έκδοση του παραστατικού. Παρακαλώ δοκιμάστε ξανά!'
      },
      CancelReceipt: {
        Success: 'Το παραστατικό έχει ακυρωθεί επιτυχώς',
        Error  : 'Υπήρξε πρόβλημα κατά την ακύρωση του παραστατικού. Παρακαλώ δοκιμάστε ξανά!'
      },
      RetryReceipt: {
        Success: 'Το παραστατικό έχει σταλεί προς διαβίβαση',
        Error  : 'Υπήρξε πρόβλημα κατά την αποστολή του παραστατικού προς διαβίβαση. Παρακαλώ δοκιμάστε ξανά!'
      },
      PrintReceipt: {
        Success: 'Το παραστατικό έχει σταλεί για εκτύπωση',
        Error  : 'Υπήρξε πρόβλημα κατά την εκτύπωση του παραστατικού. Παρακαλώ δοκιμάστε ξανά!'
      },
      EmailReceipt: {
        Success: 'Το παραστατικό έχει σταλεί με Email επιτυχώς',
        Error  : 'Υπήρξε πρόβλημα κατά την αποστολή του παραστατικού με Email. Παρακαλώ δοκιμάστε ξανά!'
      },
      TaxesOrphaned: {
        Both    : 'Υπάρχουν <strong>{categories} κατηγορίες</strong> και <strong>{products} προϊόντα</strong> που δεν έχει εφαρμοστεί κατηγορία ΦΠΑ!',
        Category: 'Υπάρχουν <strong>{categories} κατηγορίες</strong> που δεν έχει εφαρμοστεί κατηγορία ΦΠΑ! | Υπάρχει <strong>{categories} κατηγορία</strong> που δεν έχει εφαρμοστεί κατηγορία ΦΠΑ! | Υπάρχουν <strong>{categories} κατηγορίες</strong> που δεν έχει εφαρμοστεί κατηγορία ΦΠΑ!',
        Product : 'Υπάρχουν <strong>{products} προϊόντα</strong> που δεν έχει εφαρμοστεί κατηγορία ΦΠΑ! | Υπάρχει <strong>{products} προϊόν</strong> που δεν έχει εφαρμοστεί κατηγορία ΦΠΑ! | Υπάρχουν <strong>{products} προϊόντα</strong> που δεν έχει εφαρμοστεί κατηγορία ΦΠΑ!'
      }
    },
    WaitProgress: {
      Title: 'Παρακαλώ περιμένετε'
    },
    Retry       : 'Παρακαλώ προσπαθήστε ξανά.',
    Attention   : 'Προσοχή',
    Copy        : 'Αντιγράφηκε επιτυχώς',
    Exit        : 'Έξοδος',
    ExitApp     : 'Έξοδος από την εφαρμογή',
    LogoutDialog: {
      Title  : 'Αποσύνδεση',
      Body   : 'Θέλετε να αποσυνδεθείτε;',
      Warning: 'Το Online κατάστημα θα σταματήσει να δέχεται παραγγελίες αν δεν υπάρχει άλλος σταθμός εργασίας ενεργός.'
    },
    ExitAppDialog: {
      Title  : '@:Common.Misc.Exit',
      Body   : 'Θέλετε να κάνετε έξοδο από την εφαρμογή;',
      Warning: '@:Common.Misc.LogoutDialog.Warning'
    }
  },

  Table: {
    Text: {
      LoadingText  : '',
      NoDataText   : 'Δεν υπάρχουν εγγραφές',
      NoResultsText: 'Δεν υπάρχουν αποτελέσματα'
    },
    Pagination: {
      From: 'από',
      To  : 'έως'
    },

    Result: {
      NoDataText      : 'Δεν υπάρχουν διαθέσιμες εγγραφές',
      NoResultsText   : 'Δεν βρέθηκαν εγγραφές',
      RowsPerPageItems: [10, 15, 25],
      RowsPerPageText : 'Εγγραφές ανά σελίδα'
    }
  },

  Search: {
    Label      : 'Αναζήτηση',
    Icon       : 'search',
    Description: 'Αποτελέσματα Αναζήτησης'
  },

  CallsQueue: {
    Toolbar: {
      Title : 'Τηλ. Κλήσεις',
      Button: {
        Title: '@:Common.Button.View όλων'
      }
    },
    ListView: {
      Title     : 'Κλήσεις | Κλήση | Κλήσεις',
      NoCall    : 'Δεν Υπάρχουν',
      InProgress: {
        Title: 'Κλήσεις σε Εξέλιξη',
        Icon : 'phone_in_talk'
      },
      OnHold: {
        Title: 'Κλήσεις σε Αναμονή',
        Icon : 'phone_paused'
      },
      Missed: {
        Title: 'Αναπάντητες Κλήσεις | Αναπάντητη Κλήση | Αναπάντητες Κλήσεις',
        Icon : 'phone_missed'
      },
      Common: {
        PotentialCustomer: 'Πιθανοί Πελάτες',
        UnknownCustomer  : 'Άγνωστος Πελάτης',
        HiddenCallerID   : 'Απόκρυψη Αριθμού',
        NewOrder         : 'Νέα Παραγγελία',
        NewReservation   : 'Νέα Κράτηση',
        CustomerTab      : 'Καρτέλα Πελάτη'
      },
      Button: {
        Dismiss: {
          Title: '@:Common.Button.Dismiss Όλων',
          Icon : 'delete_forever',
          Color: 'red'
        },
        DismissAndNewOrder: {
          Title: '@:Common.Button.Dismiss & Νέα Παραγγελία',
          Icon : 'add_shopping_cart'
        },
        DismissAndNewReservation: {
          Title: '@:Common.Button.Dismiss & Νέα Κράτηση',
          Icon : 'mdi mdi-book-open-variant'
        }
      }
    }
  },

  ConfirmDialog: {
    Title: 'Είστε Σίγουροι;'
  },

  NumPadDialog: {
    Title: 'Εισάγετε Τιμή'
  },

  OnlineStatus: {
    Title: {
      Open  : 'Ανοιχτά',
      Closed: 'Κλειστά'
    }
  },

  OrdersQueue: {
    Button: {
      Title: '@:Common.Button.View όλων'
    },
    ListView: {
      Title: {
        ToAccept  : 'προς Αποδοχή',
        ToComplete: 'προς Ολοκλήρωση'
      },
      ListItem: {
        Visitor  : 'Επισκέπτης',
        Telephone: 'Τηλ:'
      }
    },
    Notification: {
      Title  : 'Εκκρεμής Παραγγελία',
      Order  : 'παραγγελίας',
      Content: {
        Delay : 'Έχετε καθυστερήσει',
        Doing : 'να κάνετε',
        Cancel: 'ή ΑΚΥΡΩΣΗ την εκκρεμής παραγγελία',
        Order : 'τη παραγγελία'
      },
      Button: {
        Close : 'Κλείσιμο χωρίς υπενθύμιση',
        Remind: 'Υπενθύμιση σε 5"',
        View  : 'Προβολή παραγγελίας',
        Cancel: '@:Common.Button.Cancel @:Common.OrdersQueue.Notification.Order'
      }
    },
    AcceptOrder: {
      Title  : 'Αποδοχή Εκκρεμούς Παραγγελίας',
      Content: {
        Payment: 'Οι εκκρεμείς παραγγελίες δεν έχουν ακόμη επιβεβαιωμένη πληρωμή.',
        Choose : 'Επιλέξτε αν θέλετε να',
        Accept : 'αποδεχτείτε',
        Edit   : 'επεξεργαστείτε',
        Order  : 'αυτή την εκκρεμής παραγγελία ή όχι.'
      }
    }
  },

  SelectDateRangeDialog: {
    Toolbar: {
      Title   : 'Επιλογή Περιόδου',
      Subtitle: 'Επιλέξτε ημερομηνία από - έως',
      Color   : 'primary',
      Icon    : 'date_range'
    }
  },

  Map: {
    Title    : 'Χάρτης',
    Satellite: 'Δορυφόρος'
  },

  ColorPicker: {
    Error: {
      InvalidColor: 'Δεν είναι έγκυρος κωδικός χρώματος!'
    }
  },

  Error: {
    Mandatory: 'Το πεδίο είναι υποχρεωτικό',
    Generic  : 'Υπήρξε κάποιο πρόβλημα! Παρακαλώ δοκιμάστε ξανά αργότερα.'
  }
}
