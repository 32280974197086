<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.MenuOptionValueGroupAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.MenuOptionValueGroupAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!categoryToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.MenuOptionValueGroupAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.MenuOptionValueGroupAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.MenuOptionValueGroupAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.MenuOptionValueGroupAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="categoryForm"
            v-model="categoryFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="selectedLanguages.length > 1">
                    <v-tabs
                      v-model="languageTab"
                      :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                      grow
                      height="40"
                      slider-color="blue"
                    >
                      <template v-for="lang in selectedLanguages">
                        <v-tab
                          :key="lang.Code"
                          :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                        >
                          <v-avatar
                            v-if="lang.ImageUrl"
                            class="mr-2"
                            size="20"
                          >
                            <v-img :src="lang.ImageUrl" />
                          </v-avatar>
                          {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                        </v-tab>
                      </template>
                    </v-tabs>
                  </template>
                  <v-tabs-items v-model="languageTab">
                    <template v-for="lang in selectedLanguages">
                      <v-tab-item
                        :key="lang.Code"
                        eager
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editCategory.Lang[lang.Code].Name"
                              :counter="32"
                              :maxlength="32"
                              :hint="$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Name.Hint')"
                              :label="$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Name.Label')"
                              :prepend-icon="$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Name.Icon')"
                              :rules="validationRules.categoryForm.Name"
                              class="pr-2"
                              required
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editCategory"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Name"
                                />
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editCategory.Lang[lang.Code].Description"
                              :counter="200"
                              :maxlength="200"
                              :hint="$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Description.Hint')"
                              :label="$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Description.Label')"
                              :prepend-icon="$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Description.Icon')"
                              :rules="validationRules.categoryForm.Description"
                              class="pr-2"
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editCategory"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Description"
                                />
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </template>
                  </v-tabs-items>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>
                  <v-switch
                    v-model="editCategory.Status"
                    inset
                    :label="editCategory.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>
                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.MenuOptionValueGroupAddDialog.Field.ShowOptionValues.Label') }}
                  </v-subheader>
                  <v-switch
                    v-model="editCategory.ShowOptionValues"
                    inset
                    :label="editCategory.ShowOptionValues ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <translate-all-fields-button
            v-if="selectedLanguages.length > 1"
            :disabled="loading"
            :item="editCategory"
            :locale-from="defaultLanguage.Code"
            :field="['Name', 'Description']"
          />

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import AppData                  from '@/mixins/appdata'
import Auth                     from '@/mixins/auth'
import TranslateAllFieldsButton from '@/components/common/translation/translateAllFieldsButton.vue'
import TranslateFieldButton     from '@/components/common/translation/translateFieldButton.vue'

export default {
  components: {
    TranslateFieldButton,
    TranslateAllFieldsButton
  },
  mixins: [AppData, Auth],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    categoryToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      languageTab      : 0,
      categoryFormValid: false,
      loading          : false,
      newCategory      : null,
      tmpCategory      : {
        Id              : null,
        Name            : '',
        ShowOptionValues: false,
        Status          : true,
        Description     : '',
        Lang            : {}
      },
      validationRules: {
        categoryForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Name.Error.Between')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 200) || this.$t('Restaurant.MenuOptionValueGroupAddDialog.Field.Description.Error.Between')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editCategory: {
      get () {
        if (this.categoryToEdit) {
          this.newCategory = JSON.parse(JSON.stringify(this.categoryToEdit))
        } else {
          this.newCategory = JSON.parse(JSON.stringify(this.tmpCategory))
        }

        const langObj = {}
        for (const lang of this.selectedLanguages) {
          langObj[lang.Code] = {
            Name       : '',
            Description: '',
            Locale     : lang.Code
          }
        }
        if (!this.newCategory.Lang) this.newCategory.Lang = {}
        this.newCategory.Lang = Object.assign({}, langObj, this.newCategory.Lang)

        return this.newCategory
      }
    }
  },
  watch: {
    isVisible (newVal) {
      this.languageTab = 0
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Menu.OptionValueGroup.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Menu.OptionValueGroup.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.categoryForm.validate()

      if (this.categoryFormValid) {
        this.loading = true
        const saveObj = JSON.parse(JSON.stringify(this.editCategory))

        saveObj.Name = saveObj.Lang[this.defaultLanguage.Code].Name
        saveObj.Description = saveObj.Lang[this.defaultLanguage.Code].Description

        window.callAS(window.SocketCommand.Menu.OptionValueGroup.Save, { group: saveObj })
        if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult () {
      this.loading = false
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
      this.isVisible = false
      this.newCategory = null
    }
  }
}
</script>
