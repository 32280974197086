/**
 * Enum for notify types.
 * @readonly
 * @enum {String} NotifyTypeEnum
 */

const NotifyTypeEnum = {
  PRINT: 'print',
  EMAIL: 'email',
  SMS  : 'sms'
}

export default NotifyTypeEnum
