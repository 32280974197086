/* eslint-disable no-console */
import { EventBus } from '../../../events/eventBus'
import BaseResource from '../Resources/BaseResource'

export default class BaseModel {
  #validator = null
  #collection = null
  #guarded = []
  #fetchCommand = null
  #saveCommand = null
  #deleteCommand = null

  /**
   *
   * @param {Object | BaseResource} [rawData={}] the object or resource to model.
   * @param {BaseValidator} [validator=null] the validator to validate model data.
   * @return {BaseModel} A BaseModel object.
   */
  constructor (rawData = {}, validator = null) {
    this.guarded = []
    this.setData(rawData)
    // eslint-disable-next-line new-cap
    if (validator) this.#validator = new validator(this)
    this.boot()
  }

  /* PROPERTIES */
  /**
   * Sets the collection that the model belongs.
   *
   * @property {collection}
   * @param val
   */
  set collection (val) {
    this.#collection = val
  }

  get validator () {
    return this.#validator
  }

  /**
   * Sets the validator to be used to validate properties.
   *
   * @property {validator}
   * @param val
   */
  set validator (val) {
    this.#validator = val
  }

  get validationMessages () {
    let validatorsFound = 0
    let errors = {}

    Object.keys(this).forEach((key) => {
      if (this[key] instanceof BaseModel) {
        if (this[key].validator) {
          validatorsFound++
          const tmp = {}
          tmp[key] = this[key].validationMessages
          if (Object.keys(tmp[key]).length > 0) errors = Object.assign({}, errors, tmp)
        }
      }
    })
    if (this.validator) {
      validatorsFound++
      if (Object.keys(this.validator.validationMessages).length > 0) errors = Object.assign({}, errors, this.validator.validationMessages)
    }

    if (!validatorsFound) {
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }

    return errors
  }

  /**
   * The guarded property should contain an array of attributes that you do not want to be
   * serialized during {clone} or {toString}. All other attributes not in the array will be
   * serialized.
   *
   * @property {guarded}
   * @param {Array} guardedPropertiesArray
   */
  set guarded (guardedPropertiesArray) {
    this.#guarded = guardedPropertiesArray
  }

  set fetchCommand (command) {
    this.#fetchCommand = command
  }

  set saveCommand (command) {
    this.#saveCommand = command
  }

  set deleteCommand (command) {
    this.#deleteCommand = command
  }

  /* METHODS */
  /**
   * Called after construction, this hook allows you to add some extra setup
   * logic without having to override the constructor.
   */
  boot () {

  }

  /**
   * Sets the data to the model
   *
   * @param {Object | BaseResource} [rawData={}] the object or resource to model.
   */
  setData (rawData = {}) {
    if (this.constructor.name === BaseModel.prototype.constructor.name || rawData instanceof BaseResource) {
      Object.assign(this, rawData)
    }
  }

  reset () {
    if (this.constructor.name === BaseModel.prototype.constructor.name) {
      Object.keys(this).forEach(key => delete this[key])
    }
    this.setData()
  }

  validate () {
    let isValid = 0
    let validatorsFound = 0

    Object.keys(this).forEach((key) => {
      if (this[key] instanceof BaseModel) {
        if (this[key].validator) {
          validatorsFound++
          if (!this[key].validate()) isValid++
        }
      }
    })
    if (this.validator) {
      validatorsFound++
      if (!this.validator.validate()) isValid++
    }

    if (!validatorsFound) {
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }

    return isValid <= 0
  }

  validateField (property) {
    if (this.validator) {
      return this.validator.validateField(property)
    } else {
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }
  }

  vuetifyFormFieldRules (field) {
    let validatorsFound = 0
    let rules = []
    let model = this

    if (field.includes('.') && this[field.split('.')[0]] instanceof BaseModel) {
      field = field.split('.')
      model = this[field.shift()]
      field = field.join('.')
      validatorsFound++
      const modelFieldRules = model.vuetifyFormFieldRules(field)
      if (modelFieldRules.length > 0) rules = modelFieldRules
      return rules
    }

    if (model.validator) {
      validatorsFound++
      const modelFieldRules = model.validator.vuetifyFormFieldRules(field)
      if (modelFieldRules.length > 0) rules = modelFieldRules
    }

    if (!validatorsFound) {
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }

    return rules
  }

  /**
   * Gets a serialized version of the model.
   *
   * @param {BaseResource} [resourceClass=null] Optional resource to manipulate the data
   * @returns {JSON | Object} The serialized model
   */
  clone (resourceClass = null) {
    let retVal = null

    Object.keys(this).forEach((key) => {
      if (this[key] instanceof BaseModel) {
        this[key] = this[key].clone()
      }
    })

    if (resourceClass) {
      // eslint-disable-next-line new-cap
      retVal = JSON.parse(JSON.stringify(new resourceClass(this)))
    } else {
      retVal = JSON.parse(JSON.stringify(this))
    }

    Object.keys(retVal).filter(key => this.#guarded.includes(key)).forEach(key => delete retVal[key])

    return retVal
  }

  /**
   * stringifies the model using JSON.stringify API.
   *
   * @param {BaseResource} [resourceClass=null] Optional resource to manipulate the data
   * @return {string} The stringified model.
   */
  toString (resourceClass = null) {
    return JSON.stringify(this.clone(resourceClass))
  }

  /* API METHODS */

  fetch (data = null, command = null, resultCommand = '') {
    if (!command && !this.#fetchCommand) return
    if (!command && this.#fetchCommand) command = this.#fetchCommand
    if (!data) data = {}
    EventBus.$once(command, resultData => this.onFetch(resultData))
    window.callAS(command, data, resultCommand)
  }

  onFetch (data) {
    if (this.constructor.name === BaseModel.prototype.constructor.name || data instanceof BaseResource) {
      Object.assign(this, data)
    }
  }

  save (data = null, command = null, resultCommand = '') {
    if (!command && !this.#saveCommand) return
    if (!command && this.#saveCommand) command = this.#saveCommand
    if (!data) data = this.clone()
    EventBus.$once(command, resultData => this.onSave(resultData))
    window.callAS(command, data, resultCommand)
  }

  onSave (data) {

  }

  delete (data = null, command = null, resultCommand = '') {
    if (!command && !this.#deleteCommand) return
    if (!command && this.#deleteCommand) command = this.#deleteCommand
    if (!data) data = this.clone()
    EventBus.$once(command, resultData => this.onDelete(resultData))
    window.callAS(command, data, resultCommand)
  }

  onDelete (data) {

  }
}
