/**
 * Enum for order status.
 * @readonly
 * @enum {Number} OrderStatusEnum
 */

const OrderStatusEnum = {
  NotComplete: 0,
  Pending    : 12,
  Received   : 11,
  Preparation: 13,
  Delivery   : 14,
  Completed  : 15,
  Canceled   : 19,
  Printed    : 20,
  Accepted   : 21
}

export default OrderStatusEnum
