import AppData from '../../mixins/appdata'

export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [AppData],
  directives: {},
  watch     : {},
  methods   : {
    getOrderPaymentType (order) {
      return order?.Payment ? this.getPaymentTypeByCode(order.Payment) : null
    },

    getPaymentTypeByCode (code) {
      for (const payment in this.appConfig.PAYMENT_TYPES) {
        if (this.appConfig.PAYMENT_TYPES.hasOwnProperty(payment) && this.appConfig.PAYMENT_TYPES[payment].code === code) {
          const val = this.appConfig.PAYMENT_TYPES[payment]
          val.icon = this.appConfig.PAYMENT_TYPES[payment].code === 'cod' ? this.appCurrency.Icon : val.icon
          return val
        }
      }
      return null
    },

    getPaymentTypeLangByCode (code) {
      return this.paymentTypesLang?.find(p => p.Code === code) || {}
    }
  },
  computed: {
    paymentTypes () {
      return this.appConfig?.PAYMENT_TYPES || []
    },
    paymentTypesLang () {
      return [this.$t('Order.PaymentMethod.Cash'), this.$t('Order.PaymentMethod.Card')].map(o => {
        o.Avatar = {
          Icon : o.Icon,
          Color: o.Color
        }
        return o
      })
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {}
}
