<template>
  <div
    v-if="isActive && showInAppToolbar"
    class="pa-0 ma-0"
  >
    <v-menu
      nudge-width="450"
      max-width="650"
      nudge-left="225"
      open-on-hover
      offset-y
      bottom
    >
      <template #activator="{on}">
        <v-btn
          :class="[$vuetify.breakpoint.smAndDown ? 'mx-0' : 'mx-1', `${alertData.Color}--text`]"
          class="px-1"
          color="transparent"
          style="min-width: 36px"
          height="36"
          depressed
          v-on="on"
        >
          <v-icon size="26">
            {{ alertData.Icon }}
          </v-icon>

          <v-chip
            :color="alertData.Color"
            class="font-weight-medium ml-2 px-1 text-capitalize"
            style="cursor: pointer"
            label
            small
          >
            {{ alertData.ToolbarTitle }}
          </v-chip>
        </v-btn>
      </template>

      <v-card flat>
        <div>
          <current-plan-warning-alert
            :alert-data="alertData"
            :hide-actions="hideActions"
          />
        </div>
      </v-card>
    </v-menu>
  </div>

  <v-container
    v-else-if="isActive && !showInAppToolbar"
    fluid
    class="pa-1"
  >
    <v-row
      dense
      no-gutters
    >
      <v-col cols="12">
        <current-plan-warning-alert
          :alert-data="alertData"
          :hide-actions="hideActions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppData                 from '@/mixins/appdata'
import Auth                    from '@/mixins/auth'
import PlansEnum               from '@/mixins/plans/plansEnum'
import CurrentPlanWarningAlert from '@/components/account/CurrentPlanWarningAlert.vue'

export default {
  name      : 'CurrentPlanWarning',
  components: { CurrentPlanWarningAlert },
  directives: {},
  mixins    : [AppData, Auth, PlansEnum],
  props     : {
    showBeforeExpire: {
      type   : Boolean,
      default: true
    },
    showInAppToolbar: {
      type   : Boolean,
      default: false
    },
    hideActions: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    domainIsLocked () {
      return this.appConfig?.LOCATION_DATA?.LockedFull ?? false
    },

    domainInExtendedPeriod () {
      return !this.domainIsLocked && (this.appConfig?.LOCATION_DATA?.Locked ?? false)
    },

    isActive () {
      return this.alertData && (
        (this.appConfig.LOCATION_DATA.CatalogOnly && this.appConfig.LOCATION_DATA.PackageId === this.PlansEnum.GetContactless) ||
        (this.appConfig.LOCATION_DATA.TrialExpiredAt && this.appConfig.LOCATION_DATA.PackageId === this.PlansEnum.GetSmart) ||
        (this.appConfig.LOCATION_DATA.SubscriptionExpiredAt && this.PlanIds.includes(this.appConfig.LOCATION_DATA.PackageId)) ||
        (this.PlanIds.includes(this.appConfig.LOCATION_DATA.PackageId) && this.showBeforeExpire && !this.showInAppToolbar)
      )
    },

    alertData () {
      // CONTACTLESS CATALOG UPGRADE REMINDER
      if (this.appConfig.LOCATION_DATA.CatalogOnly && this.appConfig.LOCATION_DATA.PackageId === this.PlansEnum.GetContactless) {
        return {
          Title       : this.getTitle('CONTACTLESS'),
          SubTitle    : this.getSubTitle('CONTACTLESS'),
          ToolbarTitle: this.$t('Account.Plan.ExpireNotice.Contactless.Free'),
          Days        : 0,
          Color       : 'orange',
          Class       : '',
          Icon        : 'warning',
          Button      : {
            Active: this.posUser.billing && (this.userIsOwner || this.userIsSuperAdmin) && this.$route.name !== 'AccountPlans' && this.posUserCan('AccountPlans', 'ACCESS'),
            To    : { name: 'AccountPlans' },
            Title : this.$t('Common.Button.Upgrade'),
            Color : 'orange lighten-1',
            Class : ''
          }
        }

        // SUBSCRIPTION EXPIRE REMINDER
      } else if (
        (this.appConfig.LOCATION_DATA.SubscriptionExpiredAt && this.PlanIds.includes(this.appConfig.LOCATION_DATA.PackageId) &&
          (((this.$route.name.includes('Dashboard') || this.showInAppToolbar) && this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn < 15) ||
            (this.$route.name.includes('Account') && !this.showInAppToolbar)))
      ) {
        if (this.domainInExtendedPeriod) {
          return {
            Title       : this.getTitle('EXTENDED'),
            SubTitle    : this.getSubTitle('EXTENDED'),
            ToolbarTitle: this.$tc('Account.Plan.ExpireNotice.Days', this.appConfig.LOCATION_DATA.ExtendedPeriodDays, { days: this.appConfig.LOCATION_DATA.ExtendedPeriodDays }),
            Days        : this.appConfig.LOCATION_DATA.ExtendedPeriodDays,
            Color       : 'red',
            Class       : '',
            Icon        : 'warning',
            Type        : 'warning',
            Button      : {
              Active: false,
              To    : { name: 'AccountPlans' },
              Title : this.$t('Common.Button.More'),
              Color : 'red lighten-1',
              Class : ''
            }
          }
        } else if (this.domainIsLocked) {
          return {
            Title       : this.getTitle('SUBSCRIPTION'),
            SubTitle    : this.getSubTitle('SUBSCRIPTION'),
            ToolbarTitle: 'LOCKED',
            Days        : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn,
            Color       : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn <= 3 ? 'red' : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn >= 15 ? 'info' : 'orange',
            Class       : '',
            Icon        : 'warning',
            Type        : 'warning',
            Button      : {
              Active: false,
              To    : { name: 'AccountPlans' },
              Title : this.$t('Common.Button.More'),
              Color : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn <= 3 ? 'red lighten-1' : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn >= 15 ? 'info lighten-1' : 'orange lighten-1',
              Class : ''
            }
          }
        } else {
          return {
            Title       : this.getTitle('SUBSCRIPTION'),
            SubTitle    : this.getSubTitle('SUBSCRIPTION'),
            ToolbarTitle: this.$tc('Account.Plan.ExpireNotice.Days', this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn, { days: this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn }),
            Days        : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn,
            Color       : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn <= 3 ? 'red' : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn >= 15 ? 'info' : 'orange',
            Class       : '',
            Icon        : 'warning',
            Type        : 'warning',
            Button      : {
              Active: this.posUser.billing && (this.userIsOwner || this.userIsSuperAdmin) && this.$route.name !== 'AccountPlans' && this.posUserCan('AccountPlans', 'ACCESS'),
              To    : { name: 'AccountPlans' },
              Title : this.$t('Common.Button.More'),
              Color : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn <= 3 ? 'red lighten-1' : this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn >= 15 ? 'info lighten-1' : 'orange lighten-1',
              Class : ''
            }
          }
        }

        // TRIAL EXPIRE REMINDER
      } else if (!this.appConfig.LOCATION_DATA.SubscriptionExpiredAt && this.appConfig.LOCATION_DATA.PackageId === this.PlansEnum.GetSmart && this.appConfig.LOCATION_DATA.TrialExpiredAt) {
        return {
          Title       : this.getTitle('TRIAL'),
          SubTitle    : this.getSubTitle('TRIAL'),
          ToolbarTitle: this.$tc('Account.Plan.ExpireNotice.Days', this.appConfig.LOCATION_DATA.TrialExpiredDaysIn, { days: this.appConfig.LOCATION_DATA.TrialExpiredDaysIn }),
          Days        : this.appConfig.LOCATION_DATA.TrialExpiredDaysIn,
          Color       : this.appConfig.LOCATION_DATA.TrialExpiredDaysIn <= 3 ? 'red' : 'orange',
          Class       : '',
          Icon        : 'warning',
          Button      : {
            Active: this.posUser.billing && (this.userIsOwner || this.userIsSuperAdmin) && this.$route.name !== 'AccountPlans' && this.posUserCan('AccountPlans', 'ACCESS'),
            To    : { name: 'AccountPlans' },
            Title : this.$t('Common.Button.More'),
            Color : this.appConfig.LOCATION_DATA.TrialExpiredDaysIn <= 3 ? 'red lighten-1' : 'orange lighten-1',
            Class : ''
          }
        }

        // NORMAL PLAN UPGRADE
      } else if (this.PlanIds.includes(this.appConfig.LOCATION_DATA.PackageId) && this.showBeforeExpire && !this.showInAppToolbar) {
        return {
          Title       : this.getTitle('NORMAL'),
          SubTitle    : this.getSubTitle('NORMAL'),
          ToolbarTitle: '',
          Days        : 0,
          Color       : 'info',
          Class       : '',
          Icon        : 'warning',
          Type        : 'warning',
          Button      : {
            Active: this.posUser.billing && (this.userIsOwner || this.userIsSuperAdmin) && this.$route.name !== 'AccountPlans' && this.posUserCan('AccountPlans', 'ACCESS'),
            To    : { name: 'AccountPlans' },
            Title : this.$t('Common.Button.More'),
            Color : 'info lighten-1',
            Class : ''
          }
        }
      }

      return null
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getTitle (type) {
      if (type === 'CONTACTLESS') {
        return this.$t('Account.Plan.ExpireNotice.Contactless.Title')
      }

      if (type === 'EXTENDED') {
        return this.$t('Account.Plan.ExpireNotice.Extended.Title', { planName: this.$t(`Account.Plan.Package.${ this.appConfig.LOCATION_DATA.PackageId }.Name`) })
      }

      if (type === 'SUBSCRIPTION') {
        return this.$t('Account.Plan.ExpireNotice.Current.Title', { planName: this.$t(`Account.Plan.Package.${ this.appConfig.LOCATION_DATA.PackageId }.Name`) })
      }

      if (type === 'TRIAL') {
        return this.$t('Account.Plan.ExpireNotice.Trial.Title')
      }

      if (type === 'NORMAL') {
        return this.$t('Account.Plan.CurrentPlan', { planName: this.$t(`Account.Plan.Package.${ this.appConfig.LOCATION_DATA.PackageId }.Name`) })
      }
    },

    getSubTitle (type) {
      if (type === 'CONTACTLESS') {
        return this.$t('Account.Plan.ExpireNotice.Contactless.SubTitle')
      }

      if (type === 'EXTENDED') {
        if (this.appConfig.LOCATION_DATA.ExtendedPeriodDays > 0) {
          return this.$tc('Account.Plan.ExpireNotice.Extended.ExpiringIn', this.appConfig.LOCATION_DATA.ExtendedPeriodDays, {
            hours: this.appConfig.LOCATION_DATA.ExtendedPeriodHours,
            days : this.appConfig.LOCATION_DATA.ExtendedPeriodDays
          })
        } else if (this.appConfig.LOCATION_DATA.ExtendedPeriodDays === 0) {
          return this.$tc('Account.Plan.ExpireNotice.Extended.ExpiringIn', this.appConfig.LOCATION_DATA.ExtendedPeriodDays, {
            hours: this.appConfig.LOCATION_DATA.ExtendedPeriodHours,
            days : this.appConfig.LOCATION_DATA.ExtendedPeriodDays
          })
        }
      }

      if (type === 'SUBSCRIPTION') {
        if (this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn > 30) {
          return this.$t('Account.Plan.ExpireNotice.Current.ExpiringOn', { date: this.appConfig.LOCATION_DATA.SubscriptionExpiredAt })
        } else {
          if (this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn > 0) {
            return this.$tc('Account.Plan.ExpireNotice.Current.ExpiringIn', this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn, { days: this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn })
          } else if (this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn === 0) {
            return this.$tc('Account.Plan.ExpireNotice.Current.ExpiringIn', this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn, { days: this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn })
          } else {
            return this.$t('Account.Plan.ExpireNotice.Current.ExpiredOn', {
              days: Math.abs(this.appConfig.LOCATION_DATA.SubscriptionExpiredDaysIn),
              date: this.appConfig.LOCATION_DATA.SubscriptionExpiredAt
            })
          }
        }
      }

      if (type === 'TRIAL') {
        if (this.appConfig.LOCATION_DATA.TrialExpiredDaysIn > 30) {
          return this.$t('Account.Plan.ExpireNotice.Trial.ExpiringOn', { date: this.appConfig.LOCATION_DATA.TrialExpiredAt })
        } else {
          if (this.appConfig.LOCATION_DATA.TrialExpiredDaysIn > 0) {
            return this.$tc('Account.Plan.ExpireNotice.Trial.ExpiringIn', this.appConfig.LOCATION_DATA.TrialExpiredDaysIn, { days: this.appConfig.LOCATION_DATA.TrialExpiredDaysIn })
          } else if (this.appConfig.LOCATION_DATA.TrialExpiredDaysIn === 0) {
            return this.$tc('Account.Plan.ExpireNotice.Trial.ExpiringIn', this.appConfig.LOCATION_DATA.TrialExpiredDaysIn, { days: this.appConfig.LOCATION_DATA.TrialExpiredDaysIn })
          } else {
            return this.$t('Account.Plan.ExpireNotice.Trial.ExpiredOn', {
              days: Math.abs(this.appConfig.LOCATION_DATA.TrialExpiredDaysIn),
              date: this.appConfig.LOCATION_DATA.TrialExpiredAt
            })
          }
        }
      }

      if (type === 'NORMAL') {
        return this.$t('Account.Plan.UpgradePlan')
      }
    }
  }
}
</script>

<style scoped>
/deep/ .top10Toolbar .v-toolbar__content, /deep/ .top10Toolbar .v-toolbar__extension {
  padding : 0 !important;
}

.v-menu__content {
  overflow : initial;
}
</style>
