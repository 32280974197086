import PinManagerEnum from '@/api/Enums/PinManagerEnum'

export default {
  methods: {
    $emitAction (action, askPermission = true) {
      const table = this.table || this.selectedTable || null
      const area = this.area || this.selectedArea || null
      if (!table || !area) return

      this.executeWithPinPermissionManager(askPermission && this.userNeedsPinPermission(PinManagerEnum.ManageAllTables) && this.canManageTable && !this.isOwnTable, () => {
        this.$bus.$emit(action, table, area)
      }, action)
    },

    // Table Options Menu Actions

    onReservationButtonClick () {
      this.$emitAction('click:table-reservation-button', false)
    },

    onOpenShiftButtonClick () {
      this.$emitAction('click:table-open-button', false)
    },

    onNewOrderButtonClick () {
      this.$emitAction('click:table-new-order-button')
    },

    onEditTableButtonClick () {
      this.$emitAction('click:table-edit-button')
    },

    onViewTableButtonClick () {
      this.$emitAction('click:table-view-button', false)
    },

    onPayButtonClick () {
      this.$emitAction('click:table-pay-button', false)
    },

    onMoveButtonClick () {
      this.$emitAction('click:table-order-items-move-button')
    },

    onChangeWaiterButtonClick () {
      this.$emitAction('click:table-change-waiter-button')
    },

    onMergeTableButtonClick () {
      this.$emitAction('click:table-merge-button')
    },

    onSplitTableButtonClick () {
      this.$emitAction('click:table-split-button')
    },

    onCloseTableButtonClick () {
      if (this.table?.DisableActions) return

      this.table.DisableActions = true
      this.$emitAction('click:table-close-button')
    },

    // Table Payment Menu Actions

    onPayTableButtonClick () {
      this.$emitAction('click:payment-pay-table-button', false)
    },

    onPayOrderButtonClick () {
      this.$emitAction('click:payment-pay-table-order-button', false)
    },

    onPayMenuItemsButtonClick () {
      this.$emitAction('click:payment-pay-table-menu-item-button', false)
    },

    onSetAwaitingPaymentStatusButtonClick () {
      this.$emitAction('click:set-awaiting-payment-table-status-button', false)
    },

    onUnsetAwaitingPaymentStatusButtonClick () {
      this.$emitAction('click:unset-awaiting-payment-table-status-button', false)
    }
  }
}
