<template>
  <v-container
    ref="tables"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar
            flat
            height="80"
          >
            <v-avatar
              :color="$t('Restaurant.Tables.Toolbar.Color')"
            >
              <v-icon dark>
                {{ $t('Restaurant.Tables.Toolbar.Icon') }}
              </v-icon>
            </v-avatar>
            <v-toolbar-title>
              <div class="body-3">
                {{ $t('Restaurant.Tables.Toolbar.Title') }}
              </div>
              <div class="text-caption">
                {{ $t('Restaurant.Tables.Toolbar.Subtitle') }}
              </div>
            </v-toolbar-title>
            <v-spacer />

            Zoom {{ zoom.toPrecision(1) * 100 }}%
            <v-btn
              class="mr-0"
              icon
              @click="setZoom(0.1)"
            >
              <v-icon>add</v-icon>
            </v-btn>
            <v-btn
              class="ml-0"
              icon
              @click="setZoom(-0.1)"
            >
              <v-icon>remove</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-text
            ref="tablesContainer"
            class="pa-0 ma-0"
            style="height: calc(100vh - 216px);"
          >
            <tables-grid :zoom="zoom" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppData    from '@/mixins/appdata'
import Auth       from '@/mixins/auth'
import Resizable  from '@/mixins/resizable'
import TablesGrid from './common/TablesGrid'

export default {
  name      : 'TableFloorPlan',
  components: { TablesGrid },
  directives: {},
  mixins    : [AppData, Auth, Resizable],
  props     : [],
  data () {
    return {
      zoom   : 0.5,
      maxZoom: 1.0,
      minZoom: 0.5
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    // this.$bus.$emit('toolbar-update-title', this.$t('Restaurant.Tables.Toolbar.Title'))
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    setZoom (val) {
      const newZoom = this.zoom + val
      if (newZoom < this.minZoom || newZoom > this.maxZoom) return
      this.zoom = newZoom
    },
    onResize () {
      /* const tablesContainer = this.$refs.tablesContainer

        const settingsContainerPadding = this.$refs.tables ? parseInt(window.getComputedStyle(this.$refs.tables, null).getPropertyValue('padding-top')) * 2 : 0
        const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
        const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0

        if (tablesContainer) tablesContainer.style.height = (innerHeight - headerHeight - footerHeight - settingsContainerPadding - 80) + 'px' */
    }
  }
}
</script>

<style scoped>

</style>
