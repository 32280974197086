export default {
  Title: 'Fiscal Documents | Fiscal Document | Fiscal Documents',

  Status: {
    Transmitted: {
      Id  : 1,
      Name: 'Transmitted'
    },
    Canceled: {
      Id  : 2,
      Name: 'Cancelled'
    },
    Issued: {
      Id  : 3,
      Name: 'Issued'
    },
    Closed: {
      Id  : 4,
      Name: 'Closed'
    },
    Open: {
      Id  : 5,
      Name: 'Open'
    },
    Waiting: {
      Id  : 10,
      Name: 'Waiting'
    }
  },

  Table: {
    Button: {
      View: {
        Title: 'View Customer',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      },
      Delete: {
        Title: 'Delete Customer',
        Icon : 'delete',
        Color: 'red'
      }
    },

    Headers: {
      Id      : '#',
      Type    : 'Type',
      Number  : 'Total Num.',
      Order   : 'Order',
      Total   : 'Total',
      Aade    : 'AADE',
      Status  : 'Status',
      ClosedBy: 'Closed By',
      Related : 'Related Docs',
      Date    : 'Date',
      Actions : ''
    },

    Filter: {
      filterFailure: {
        Type       : 'v-btn-toggle',
        Label      : 'Connection Loss',
        PlaceHolder: 'All Documents',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All',
            Icon : 'mdi-wifi',
            Color: 'blue darken-1',
            Class: 'white--text'
          },
          {
            Value: 'true',
            Name : 'With Loss',
            Icon : 'mdi-wifi-remove',
            Color: 'red darken-1',
            Class: 'white--text'
          },
          {
            Value: 'false',
            Name : 'Without Loss',
            Icon : 'mdi-wifi-check',
            Color: 'light-green darken-1',
            Class: 'white--text'
          }
        ]
      },
      filterStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Document Status',
        PlaceHolder: 'All Documents',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        Items      : []
      },
      filterInvoiceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Document Type',
        PlaceHolder: 'All types',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        Items      : []
      },
      filterDate: {
        Type       : 'v-date-picker',
        Label      : 'Issue Date',
        PlaceHolder: 'Select Date',
        Icon       : 'event'
      }
    }
  }

}
