<template>
  <v-dialog
    v-model="isVisible"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="1024"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        class="pl-1"
        height="80"
        max-height="80"
      >
        <v-avatar :color="$t('Support.Toolbar.Color')">
          <v-icon color="indigo lighten-5">
            {{ $t('Support.Toolbar.Icon') }}
          </v-icon>
        </v-avatar>

        <v-toolbar-title
          class="pl-3"
        >
          <div class="body-3">
            {{ editTicket.subject }}
          </div>

          <div class="text-caption">
            {{ $t('Support.TicketAddDialog.Toolbar.Subtitle') }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          class="ml-0"
          icon
          @click="onCancelButtonClick"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider />

      <v-card-text class="pa-3">
        <v-form
          ref="ticketForm"
          v-model="ticketFormValid"
          @submit.prevent
        >
          <v-container
            class="pa-1"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <v-alert
                  :value="!!message"
                  class="pa-4 font-weight-regular"
                  color="info"
                  outlined
                  style="font-size: 18px;"
                >
                  {{ message }}
                </v-alert>
              </v-col>

              <v-col cols="12">
                <vue-editor
                  v-model="editTicket.message"
                  :editor-options="editorSettings"
                  :editor-toolbar="editorToolbar"
                  :rules="editTicket.vuetifyFormFieldRules('message')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          class="light-blue--text text--darken-1"
          outlined
          @click="onCancelButtonClick"
        >
          {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        <v-btn
          class="elevation-0"
          color="light-green"
          dark
          @click="onSendButtonClick"
        >
          {{ $t('Common.Button.Send').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Quill, VueEditor } from 'vue2-editor'
import MagicUrl             from 'quill-magic-url'
import SocketCommand        from '@/api/SocketCommand'

const AlignStyle = Quill.import('attributors/style/align')
const BackgroundStyle = Quill.import('attributors/style/background')
const DirectionStyle = Quill.import('attributors/style/direction')
const ColorStyle = Quill.import('attributors/style/color')
const SizeStyle = Quill.import('attributors/style/size')

Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(ColorStyle, true)
Quill.register(SizeStyle, true)
Quill.register('modules/magicUrl', MagicUrl)

export default {
  name      : 'TicketRequestDialog',
  components: { VueEditor },
  mixins    : [],
  props     : {
    message: {
      type   : String,
      default: ''
    },
    ticketModel: {
      type    : Object,
      required: true
    },
    visible: {
      type    : Boolean,
      default : false,
      required: true
    }
  },
  DataStore: {
    departments: 'support.departments',
    services   : 'support.services',
    priorities : 'support.priorities'
  },
  data () {
    return {
      ticketFormValid: false,
      editorToolbar  : [
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link']
      ],
      editorSettings: {
        debug  : 'error',
        modules: {
          magicUrl: true
        }
      }
    }
  },
  computed: {
    editTicket () {
      return this.ticketModel
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.$bus.$on(SocketCommand.SupportCenter.Ticket.Add, this.onTicketAdded)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(SocketCommand.SupportCenter.Ticket.Add, this.onTicketAdded)
  },
  methods: {
    onSendButtonClick () {
      this.$refs.ticketForm.validate()
      const data = this.editTicket.clone()
      data.message = `<p>${ this.message }</p><br>${ data.message }`

      if (this.ticketFormValid) {
        this.editTicket.save(data)
        this.onCancelButtonClick()
      }
    },

    onTicketAdded () {
      this.$router.push({ name: 'SupportCenter' })
    },

    onCancelButtonClick () {
      if (this.$refs.ticketForm) this.$refs.ticketForm.resetValidation()
      this.isVisible = false
    }
  }
}
</script>
