<template>
  <div
    v-if="isVisible"
    :style="`background-color: ${backgroundColorRGBA}; width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 2147483647;`"
  >
    <v-container class="fill-height">
      <v-row
        align="center"
        justify="center"
      >
        <v-progress-circular
          :color="progressColorClass"
          :size="progressSize"
          :width="progressWidth"
          indeterminate
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name      : 'LoadingModal',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    backgroundColorRGBA: {
      type   : String,
      default: 'rgba(0,0,0,0.75)'
    },
    progressColorClass: {
      type   : String,
      default: 'red accent-4'
    },
    progressSize: {
      type   : Number,
      default: 64
    },
    progressWidth: {
      type   : Number,
      default: 5
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible () {
      return this.visible
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  activated () {},
  deactivated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
