import { GoogleGeocoderProvider } from '@/lib/maps/geocoder/GoogleGeocoderProvider'
import { MapBoxGeocoderProvider } from '@/lib/maps/geocoder/MapBoxGeocoderProvider'

const GeocoderProviderFactory = (provider, options = {}) => {
  if (provider === 'google') return GoogleGeocoderProvider(options)
  if (provider === 'mapbox') return MapBoxGeocoderProvider(options)
  return null
}

export default GeocoderProviderFactory
