<script>
import { Pie }         from 'vue-chartjs'
import * as PieceLabel from 'chart.piecelabel.js'

export default {
  name   : 'TopProductsChart',
  extends: Pie,
  props  : {
    chartData: {
      type   : Object,
      default: undefined
    },

    chartOptions: {
      type   : Object,
      default: undefined
    }
  },
  mounted () {
    this.handleDataChange()
    this.$bus.$on(window.SocketCommand.Customer.Get, this.handleDataChange)
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Customer.Get, this.handleDataChange)
  },
  methods: {
    handleDataChange () {
      const chart = this.$data._chart
      if (chart) chart.destroy()

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.addPlugin(PieceLabel)
          if (this.$refs.canvas) this.renderChart(this.chartData, this.chartOptions)
        })
      })
    }
  }
}
</script>
