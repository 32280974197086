<template>
  <v-container fluid>
    <v-row
      v-if="viewData"
      dense
    >
      <current-plan-warning ref="currentPlan" />

      <v-col cols="12">
        <v-card>
          <v-toolbar
            flat
            class="pl-1"
            height="70"
          >
            <v-avatar class="indigo">
              <v-icon dark>
                supervisor_account
              </v-icon>
            </v-avatar>

            <v-toolbar-title class="pl-3">
              <div class="body-3">
                {{ $t('Account.Overview.Title') }}
              </div>

              <div class="text-caption">
                {{ $t('Account.Overview.Subtitle', {date: viewData.CreatedAt}) }}
              </div>
            </v-toolbar-title>

            <v-spacer />
          </v-toolbar>

          <v-divider />

          <v-card-text
            ref="cardBody"
            style="overflow: auto;"
          >
            <v-form
              ref="staffForm"
              v-model="staffFormValid"
              autocomplete="off"
              @submit.prevent
            >
              <v-container
                fluid
              >
                <v-row dense>
                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model="viewData.Firstname"
                      :counter="32"
                      :hint="$t('Account.Overview.Field.Firstname.Hint')"
                      :label="$t('Account.Overview.Field.Firstname.Label')"
                      :prepend-icon="$t('Account.Overview.Field.Firstname.Icon')"
                      :rules="viewData.validator.vuetifyFormFieldRules('Firstname')"
                      class="pr-2"
                      required
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model="viewData.Lastname"
                      :counter="32"
                      :hint="$t('Account.Overview.Field.Lastname.Hint')"
                      :label="$t('Account.Overview.Field.Lastname.Label')"
                      :prepend-icon="$t('Account.Overview.Field.Lastname.Icon')"
                      :rules="viewData.validator.vuetifyFormFieldRules('Lastname')"
                      class="pr-2"
                      required
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model="viewData.Telephone"
                      v-mask="'################'"
                      :hint="$t('Account.Overview.Field.Telephone.Hint')"
                      :label="$t('Account.Overview.Field.Telephone.Label')"
                      :prepend-icon="$t('Account.Overview.Field.Telephone.Icon')"
                      :rules="viewData.validator.vuetifyFormFieldRules('Telephone')"
                      class="pr-2"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model="viewData.Email"
                      :counter="96"
                      :hint="$t('Account.Overview.Field.Email.Hint')"
                      :label="$t('Account.Overview.Field.Email.Label')"
                      :prepend-icon="$t('Account.Overview.Field.Email.Icon')"
                      :rules="viewData.validator.vuetifyFormFieldRules('Email')"
                      class="pr-2"
                      disabled
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model="viewData.GroupName"
                      :hint="$t('Account.Overview.Field.GroupName.Hint')"
                      :label="$t('Account.Overview.Field.GroupName.Label')"
                      :prepend-icon="$t('Account.Overview.Field.GroupName.Icon')"
                      :rules="viewData.validator.vuetifyFormFieldRules('GroupName')"
                      class="pr-2"
                      disabled
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-text-field
                      v-model="viewData.Pin"
                      v-mask="'####'"
                      :append-icon="!pinVisible ? 'visibility' : 'visibility_off'"
                      :counter="4"
                      :disabled="parseInt(posUser.staff_id) !== parseInt(viewData.Id)"
                      :hint="$t('Account.Overview.Field.Pin.Hint')"
                      :label="$t('Account.Overview.Field.Pin.Label')"
                      :prepend-icon="$t('Account.Overview.Field.Pin.Icon')"
                      :rules="viewData.validator.vuetifyFormFieldRules('Pin')"
                      :type="!pinVisible ? 'password' : 'text'"
                      autocomplete="new-password"
                      class="pr-2"
                      maxlength="4"
                      required
                      @click:append="(pinVisible = !pinVisible)"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                  >
                    <v-select
                      v-model="viewData.Locale"
                      :hint="$t('Account.Overview.Field.Locale.Hint')"
                      :items="$i18n.languages"
                      :label="$t('Account.Overview.Field.Locale.Label')"
                      :prepend-icon="$t('Account.Overview.Field.Locale.Icon')"
                      :rules="viewData.validator.vuetifyFormFieldRules('Locale')"
                      class="pr-2"
                      item-text="name"
                      item-value="locale"
                      required
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>{{ $t('Account.Overview.Field.Theme.Title') }}</h3>
                  </v-col>

                  <v-col cols="12">
                    <v-item-group
                      v-model="viewData.ThemeMode"
                      mandatory
                    >
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row>
                          <v-col
                            v-for="theme in $t('Account.Overview.Field.Theme.Items')"
                            :key="theme.Value"
                            cols="auto"
                          >
                            <v-item
                              v-slot="{active, toggle}"
                              :value="theme.Value"
                            >
                              <v-card
                                :dark="theme.Value === 'auto' ? isSystemThemeDark() : theme.Value !== 'light'"
                                :light="theme.Value === 'auto' ? !isSystemThemeDark() : theme.Value === 'light'"
                                :style="active ? 'border: #59ae4a solid 2px;' : 'border: #3b98f6 solid 2px;'"
                                :value="theme.Value"
                                width="200"
                                max-width="200"
                                @click="toggle"
                              >
                                <div class="pa-4">
                                  <v-img :src="`/assets/img/${theme.Value}-mode.svg`" />

                                  <v-avatar
                                    v-if="active"
                                    color="light-green lighten-4"
                                    style="position: absolute; left: -12px; top: -12px;"
                                    size="25"
                                  >
                                    <v-icon
                                      color="success"
                                    >
                                      mdi-check-circle
                                    </v-icon>
                                  </v-avatar>
                                </div>

                                <v-divider />

                                <div class="align-start d-flex px-3 py-1">
                                  <div class="d-inline-flex">
                                    <div>
                                      <div class="text-h6">
                                        {{ theme.Label }}
                                      </div>
                                      <div class="grey--text text-caption">
                                        {{ theme.Hint }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-item-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider />

          <v-card-actions
            ref="cardActions"
            class="pa-4"
          >
            <v-spacer class="hidden-xs-only" />
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              :disabled="loading"
              :loading="loading"
              class="green white--text elevation-0"
              @click="onSaveButtonClick()"
            >
              {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
        v-if="userIsOwner || userIsSuperAdmin"
        ref="closeAccount"
        cols="12"
      >
        <v-card>
          <v-toolbar
            flat
            class="top10Toolbar"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-3'"
            height="60"
          >
            <v-avatar
              :style="isDarkMode ? 'background-color: #191919;' : 'background-color: white;'"
              size="60"
              tile
            >
              <v-icon
                color="red"
                dark
                size="28"
              >
                cancel
              </v-icon>
            </v-avatar>
            <v-toolbar-title
              class="px-0 mx-4"
              style="width: 100%;"
            >
              <div
                class="text-subtitle-1 font-weight-bold"
                style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
              >
                {{ $t('Account.Overview.DeleteAccount.Toolbar.Title') }}
              </div>
              <div class="text-caption">
                {{ $t('Account.Overview.DeleteAccount.Toolbar.Subtitle') }}
              </div>
            </v-toolbar-title>

            <template v-if="$vuetify.breakpoint.smAndUp">
              <v-btn
                class="mx-2"
                color="red"
                dark
                depressed
                @click="onRequestDialogShow()"
              >
                {{ $t('Account.Overview.DeleteAccount.Delete') }}
              </v-btn>
            </template>
          </v-toolbar>

          <template v-if="$vuetify.breakpoint.xsOnly">
            <v-divider />

            <v-card-actions>
              <v-btn
                block
                class="ma-2"
                color="red"
                dark
                depressed
              >
                {{ $t('Account.Overview.DeleteAccount.Delete').toLocaleUpperCase($i18n.locale) }}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <ticket-request-dialog
      :message="ticketMessage"
      :ticket-model="ticketModel"
      :visible.sync="requestDialogVisible"
    />
  </v-container>
</template>

<script>
import Auth                  from '@/mixins/auth'
import AppData               from '@/mixins/appdata'
import Resizable             from '@/mixins/resizable'
import CurrentPlanWarning    from '@/components/account/CurrentPlanWarning'
import AccountOverviewModel  from '@/api/Models/account/AccountOverviewModel'
import SupportTicketAddModel from '@/api/Models/support-center/SupportTicketAddModel'
import TicketRequestDialog   from '@/components/support-center/TicketRequestDialog.vue'

export default {
  name      : 'AccountOverview',
  components: {
    CurrentPlanWarning,
    TicketRequestDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable],
  props     : {},
  data () {
    return {
      viewData            : null,
      staffFormValid      : true,
      loading             : true,
      pinVisible          : false,
      ticketMessage       : '',
      requestDialogVisible: false,
      ticketModel         : new SupportTicketAddModel(),
      themes              : ['auto', 'light', 'dark']
    }
  },
  computed: {},
  watch   : {
    'viewData.ThemeMode': function (newVal, oldVal) {
      this.setThemeMode(newVal)
      this.$nextTick(() => {
        if (oldVal) this.onSaveButtonClick()
      })
    }
  },
  beforeCreate () {
  },
  created () {
    this.getProfileData()
  },
  beforeMount () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Account.Get, this.setProfileData)
    this.$bus.$on(window.SocketCommand.Account.Save, this.onSaveResult)
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Account.Get, this.setProfileData)
    this.$bus.$off(window.SocketCommand.Account.Save, this.onSaveResult)
  },
  destroyed () {
  },
  methods: {
    onRequestDialogShow () {
      const CurrentPlanId = this.appConfig.LOCATION_DATA.PackageId

      const Subject = this.$t('Account.Ticket.AccountCloseRequest.Subject')
      const Message = this.$t('Account.Ticket.AccountCloseRequest.Message')
      const Department = 1
      const Priority = 2
      const Service = this.$t(`Account.Plan.Package.${ CurrentPlanId }.ServiceId`)

      this.ticketMessage = Message
      this.ticketModel = new SupportTicketAddModel({
        subject   : Subject,
        department: Department,
        priority  : Priority,
        service   : Service
      })

      this.requestDialogVisible = true
    },

    onSaveButtonClick () {
      this.$refs.staffForm.validate()
      if (this.staffFormValid) {
        this.loading = true
        window.callAS(window.SocketCommand.Account.Save, { Account: this.viewData.clone() })
      }
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Account.Misc.ProfileSaved'),
          type: 'success',
          icon: 'info'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: data.error || this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    getProfileData () {
      this.loading = true
      window.callAS(window.SocketCommand.Account.Get, { Id: this.posUser.staff_id })
    },

    setProfileData (data) {
      if (data && data.status === 'success') {
        this.viewData = new AccountOverviewModel(data.data || {})
      }
      this.loading = false
    },

    onResize () {
      const cardBody = this.$refs.cardBody
      const cardActions = this.$refs.cardActions
      const currentPlan = this.$refs.currentPlan
      const closeAccount = this.$refs.closeAccount
      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const tabsHeight = document.getElementsByClassName('v-tabs')[0] ? document.getElementsByClassName('v-tabs')[0].offsetHeight + 16 : 48
      const cardActionsHeight = cardActions ? cardActions.offsetHeight + 10 : 96
      const currentPlanHeight = currentPlan ? currentPlan.$el.offsetHeight : 96
      const closeAccountHeight = closeAccount ? closeAccount.offsetHeight : 96
      const cardToolbarHeight = 70

      if (cardBody) cardBody.style.height = (innerHeight - headerHeight - footerHeight - tabsHeight - cardActionsHeight - closeAccountHeight - currentPlanHeight - cardToolbarHeight) + 'px'
    }
  }
}
</script>

<style scoped>
/deep/ .top10Toolbar .v-toolbar__content, /deep/ .top10Toolbar .v-toolbar__extension {
  padding : 0 !important;
}
</style>
