/**
 * Enum for income Classification relations.
 * @readonly
 * @enum {Object} IncomeClassificationRelationsEnum
 */

const IncomeClassificationRelationsEnum = {

  /*
|--------------------------------------------------------------------------
| ΤΥΠΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ: 11.1 - ΑΛΠ (Απόδειξη Λιανικής Πώλησης)
|--------------------------------------------------------------------------
*/
  11.1: {
    CATEGORY1_1: [
      'E3_561_003',
      'E3_561_004',
      'E3_561_005',
      'E3_561_006',
      'E3_561_007'
    ],
    CATEGORY1_2: [
      'E3_561_003',
      'E3_561_004',
      'E3_561_005',
      'E3_561_006',
      'E3_561_007'
    ],
    CATEGORY1_3: [
      'E3_561_003',
      'E3_561_004',
      'E3_561_005',
      'E3_561_006',
      'E3_561_007',
      'E3_563'
    ],
    CATEGORY1_4: [
      'E3_880_002',
      'E3_880_003',
      'E3_880_004',
      'E3_561_007'
    ],
    CATEGORY1_5: [
      'E3_561_007',
      'E3_562',
      'E3_563',
      'E3_570'
    ],
    CATEGORY1_6: [
      'E3_595'
    ],
    CATEGORY1_7: [
      'E3_881_002',
      'E3_881_003',
      'E3_881_004'
    ],
    CATEGORY1_8: [
      'E3_561_003',
      'E3_561_004',
      'E3_561_005',
      'E3_561_006',
      'E3_561_007',
      'E3_562',
      'E3_563',
      'E3_595',
      'E3_570',
      'E3_880_002',
      'E3_880_003',
      'E3_880_004',
      'E3_881_002',
      'E3_881_003',
      'E3_881_004'
    ],
    CATEGORY1_9: [
      'E3_561_003',
      'E3_561_004',
      'E3_561_005',
      'E3_561_006',
      'E3_561_007',
      'E3_562',
      'E3_563',
      'E3_595',
      'E3_570',
      'E3_880_002',
      'E3_880_003',
      'E3_880_004',
      'E3_881_002',
      'E3_881_003',
      'E3_881_004'
    ],
    CATEGORY1_10: [],
    CATEGORY1_95: []
  },

  /*
  |--------------------------------------------------------------------------
  | ΤΥΠΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ: 1.1 - ΤΙΜΟΛΟΓΙΟ ΠΩΛΗΣΗΣ
  |--------------------------------------------------------------------------
  */
  1.1: {
    CATEGORY1_1: [
      'E3_561_001',
      'E3_561_002',
      'E3_561_007'
    ],
    CATEGORY1_2: [
      'E3_561_001',
      'E3_561_002',
      'E3_561_007'
    ],
    CATEGORY1_3: [
      'E3_561_001',
      'E3_561_002',
      'E3_561_007',
      'E3_563'
    ],
    CATEGORY1_4: [
      'E3_880_001'
    ],
    CATEGORY1_5: [
      'E3_561_002',
      'E3_561_007',
      'E3_562',
      'E3_563',
      'E3_564',
      'E3_565',
      'E3_566',
      'E3_567',
      'E3_568',
      'E3_570'
    ],
    CATEGORY1_6: [],
    CATEGORY1_7: [
      'E3_881_001',
      'E3_881_003',
      'E3_881_004'
    ],
    CATEGORY1_8: [
      'E3_561_001',
      'E3_561_002',
      'E3_561_007',
      'E3_562',
      'E3_563',
      'E3_564',
      'E3_565',
      'E3_566',
      'E3_567',
      'E3_568',
      'E3_570',
      'E3_881_001',
      'E3_881_003',
      'E3_881_004'
    ],
    CATEGORY1_9: [
      'E3_561_001',
      'E3_561_002',
      'E3_561_007',
      'E3_562',
      'E3_563',
      'E3_564',
      'E3_565',
      'E3_566',
      'E3_567',
      'E3_568',
      'E3_570',
      'E3_881_001',
      'E3_881_003',
      'E3_881_004'
    ],
    CATEGORY1_10: [],
    CATEGORY1_95: [
      'E3_596',
      'E3_597'
    ]
  }
}

export default IncomeClassificationRelationsEnum
