<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        class="filter-toolbar"
        flat
      >
        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ADD')"
          :color="$t('Restaurant.Catalogs.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showCatalogAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.Catalogs.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Catalogs.Toolbar.Button.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ACCESS')"
          :color="$t('Restaurant.Categories.Toolbar.Button.Products.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'KitchenMenu'}"
          class="elevation-0 ma-1"
          text
        >
          <v-icon
            v-if="$t('Restaurant.Categories.Toolbar.Button.Products.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Categories.Toolbar.Button.Products.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Restaurant.Categories.Toolbar.Button.Products.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenuCategories', 'ACCESS')"
          :color="$t('Restaurant.Menu.Toolbar.Button.Categories.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'KitchenMenuCategories'}"
          class="elevation-0 ma-1"
          text
        >
          <v-icon
            v-if="$t('Restaurant.Menu.Toolbar.Button.Categories.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Menu.Toolbar.Button.Categories.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Restaurant.Menu.Toolbar.Button.Categories.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <v-spacer />

        <template v-if="userIsSuperAdmin">
          <v-menu
            v-if="canImportFromFoody"
            bottom
            offset-y
            transition="slide-y-transition"
          >
            <template #activator="{on, attrs}">
              <v-btn
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'pa-0 px-3'"
                :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'height: 48px;'"
                class="pa-0 ma-1 elevation-0"
                color="orange"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">
                  mdi-import
                </v-icon>&nbsp;
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                  $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale)
                }} FOODY</span>
              </v-btn>
            </template>

            <v-list
              color="orange"
              dark
              dense
            >
              <template v-for="(shop, idx) in platforms.foody.shops">
                <v-list-item
                  v-if="shop.active"
                  :key="`foody-shop-${idx}`"
                  @click="onImportShopFromFoody(shop)"
                >
                  <v-list-item-title>{{ shop.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <v-menu
            v-if="canImportFromEFood"
            bottom
            offset-y
            transition="slide-y-transition"
          >
            <template #activator="{on, attrs}">
              <v-btn
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'pa-0 px-3'"
                :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'height: 48px;'"
                class="pa-0 ma-1 elevation-0"
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">
                  mdi-import
                </v-icon>&nbsp;
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                  $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale)
                }} E-FOOD</span>
              </v-btn>
            </template>

            <v-list
              color="red"
              dark
              dense
            >
              <template v-for="(shop, idx) in platforms.efood.shops">
                <v-list-item
                  v-if="shop.active"
                  :key="`efood-shop-${idx}`"
                  @click="onImportShopFromEFood(shop)"
                >
                  <v-list-item-title>{{ shop.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <v-menu
            v-if="canImportFromFagi"
            bottom
            offset-y
            transition="slide-y-transition"
          >
            <template #activator="{on, attrs}">
              <v-btn
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'pa-0 px-3'"
                :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'height: 48px;'"
                class="pa-0 ma-1 elevation-0"
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">
                  mdi-import
                </v-icon>&nbsp;
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                  $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale)
                }} Fagi</span>
              </v-btn>
            </template>

            <v-list
              color="red"
              dark
              dense
            >
              <template v-for="(shop, idx) in platforms.fagi.shops">
                <v-list-item
                  v-if="shop.active"
                  :key="`fagi-shop-${idx}`"
                  @click="onImportShopFromFagi(shop)"
                >
                  <v-list-item-title>{{ shop.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <v-menu
            v-if="canImportFromGetFood"
            :disabled="importPlatformCatalogLoading"
            bottom
            offset-y
            transition="slide-y-transition"
          >
            <template #activator="{on, attrs}">
              <v-btn
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'pa-0 px-3'"
                :disabled="importPlatformCatalogLoading"
                :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'height: 48px;'"
                class="pa-0 ma-1 elevation-0"
                color="grey darken-3"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">
                  mdi-import
                </v-icon>&nbsp;
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                  $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale)
                }} GETFOOD</span>
              </v-btn>
            </template>

            <v-list
              color="grey darken-3"
              dark
              dense
            >
              <template v-for="(shop, idx) in platforms.getfood.shops">
                <v-list-item
                  v-if="shop.active"
                  :key="`getfood-shop-${idx}`"
                  @click="onImportShopFromGetFood(shop)"
                >
                  <v-list-item-title>{{ shop.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.color`]="{item}">
              <div
                class="d-inline-flex align-center"
                style="height: 100%; min-height: 53px; text-align: left;"
              >
                <div
                  v-if="item.Color"
                  :style="'background-color: #' + item.Color + ';width:5px; max-width:5px; height: 100%'"
                  class="pa-0 ma-0"
                />
              </div>
            </template>

            <template #[`item.catalog_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.name`]="{item}">
              <template
                v-if="(posUserCan('KitchenMenu', 'ADD') && !item.Default && !isExternalPlatformCatalog(item)) || userIsSuperAdmin"
              >
                <a
                  href="#"
                  @click.prevent="showCatalogEditDialog(item)"
                >
                  {{ item.Name }}
                </a>
              </template>
              <template v-else>
                {{ item.Name }}
              </template>

              <div class="text-caption grey--text">
                {{ item.Description | truncate }}
              </div>
            </template>

            <template #[`item.products_count`]="{item}">
              <v-chip
                label
                small
                @click="onProductsChipClick(item.Id)"
              >
                {{ item.ProductsCount || 0 }}
              </v-chip>
            </template>

            <template #[`item.table_areas_count`]="{item}">
              <v-chip
                label
                small
              >
                {{ item.TableAreasCount || 0 }}
              </v-chip>
            </template>

            <template #[`item.order_source`]="{item}">
              <template
                v-for="(sourceType, index) in getItemSourceTypes(item.SourceTypes)"
              >
                <div
                  :key="`source-type-${index}`"
                  class="mr-1 my-1"
                >
                  <v-chip
                    :color="sourceType.Status ? 'green' : ''"
                    :dark="sourceType.Status"
                    class="pl-0"
                    label
                    small
                  >
                    <v-avatar
                      :color="sourceType.Avatar.Color"
                      class="mr-2"
                      tile
                    >
                      <v-icon
                        v-if="sourceType.Avatar.Icon"
                        class="white--text"
                      >
                        {{ sourceType.Avatar.Icon }}
                      </v-icon>

                      <img
                        v-else
                        :src="sourceType.Avatar.Img"
                      >
                    </v-avatar>
                    <span>{{ sourceType.Name }}</span>
                  </v-chip>
                </div>
              </template>
            </template>

            <template #[`item.order_type`]="{item}">
              <v-chip
                :color="getCatalogOrderType(item.OrderType).Status ? 'green' : ''"
                :dark="getCatalogOrderType(item.OrderType).Status"
                class="pl-0 mr-1 my-1"
                label
                small
              >
                <v-avatar
                  :color="getCatalogOrderType(item.OrderType).Color"
                  class="mr-2"
                  tile
                >
                  <v-icon class="white--text">
                    {{ getCatalogOrderType(item.OrderType).Icon }}
                  </v-icon>
                </v-avatar>
                <span>{{ getCatalogOrderType(item.OrderType).Name }}</span>
              </v-chip>
            </template>

            <template #[`item.is_default`]="{item}">
              <v-icon
                v-if="item.Default"
                color="green"
              >
                check_circle_outline
              </v-icon>
            </template>

            <template #[`item.status`]="{item}">
              <v-icon
                v-if="item.Default || (isExternalPlatformCatalog(item) && !userIsSuperAdmin)"
                color="green"
              >
                check_circle_outline
              </v-icon>

              <v-switch
                v-else
                v-model="item.Status"
                :disabled="!posUserCan('KitchenMenu', 'MANAGE') || item.Default"
                :label="item.Status ? $t('Common.Button.Toggle.ActiveM') : $t('Common.Button.Toggle.InactiveM')"
                class="ma-2"
                color="success"
                hide-details
                inset
                @change="updateItem(item)"
              />
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="(posUserCan('KitchenMenu', 'ADD') && !isExternalPlatformCatalog(item))"
                :loading="item.DuplicateIsLoading"
                :small="$vuetify.breakpoint.xsOnly"
                class="orange elevation-2 ma-0 mr-1"
                dark
                icon
                @click="duplicateCatalog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  difference
                </v-icon>
              </v-btn>

              <v-menu
                v-if="posUserCan('KitchenMenu', 'MANAGE')"
                bottom
                transition="slide-y-transition"
              >
                <template #activator="{on, attrs}">
                  <v-btn
                    v-if="posUserCan('KitchenMenu', 'MANAGE')"
                    :class="$t('Restaurant.Menu.Toolbar.Button.Import.Color')"
                    :small="$vuetify.breakpoint.xsOnly"
                    class="elevation-2 ma-0 mr-1"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :small="$vuetify.breakpoint.xsOnly">
                      mdi-swap-vertical
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <template v-if="!isExternalPlatformCatalog(item) && userInAdminGroup">
                    <v-list-item @click="onImportButtonClick(item)">
                      <v-list-item-icon>
                        <v-icon>{{ $t('Restaurant.Menu.Toolbar.Button.Import.Icon') }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="py-1">
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale) }} EXCEL
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Subtitle') }} Excel
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-else-if="item.Api === 'efood'">
                    <v-list-item
                      :disabled="!isExternalPlatformCatalogActive(item)"
                      @click="onImportCatalogFromEFood(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ $t('Restaurant.Menu.Toolbar.Button.Import.Icon') }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="py-1">
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale) }} E-FOOD
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Subtitle') }} e-Food
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-else-if="item.Api === 'foody'">
                    <v-list-item
                      :disabled="!isExternalPlatformCatalogActive(item)"
                      @click="onImportCatalogFromFoody(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ $t('Restaurant.Menu.Toolbar.Button.Import.Icon') }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="py-1">
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale) }} FOODY
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Subtitle') }} Foody
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-else-if="item.Api === 'fagi'">
                    <v-list-item
                      :disabled="!isExternalPlatformCatalogActive(item)"
                      @click="onImportCatalogFromFagi(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ $t('Restaurant.Menu.Toolbar.Button.Import.Icon') }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="py-1">
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale) }} FAGI
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Subtitle') }} Fagi
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-else-if="item.Api === 'getfood'">
                    <v-list-item
                      :disabled="!isExternalPlatformCatalogActive(item)"
                      @click="onImportCatalogFromGetFood(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ $t('Restaurant.Menu.Toolbar.Button.Import.Icon') }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="py-1">
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Title').toLocaleUpperCase($i18n.locale) }}
                          GETFOOD
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t('Restaurant.Menu.Toolbar.Button.Import.Subtitle') }} GETFOOD
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <v-divider />

                  <v-list-item
                    :disabled="parseInt(item.ProductsCount) <= 0"
                    @click="onExportButtonClick(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ $t('Restaurant.Menu.Toolbar.Button.Export.Icon') }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="py-1">
                        {{ $t('Restaurant.Menu.Toolbar.Button.Export.Title').toLocaleUpperCase($i18n.locale) }} EXCEL
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          $t('Restaurant.Menu.Toolbar.Button.Export.Subtitle')
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                v-if="(posUserCan('KitchenMenu', 'ADD') && !isExternalPlatformCatalog(item)) || userIsSuperAdmin"
                :class="[item.Default && !userIsSuperAdmin ? 'grey' : 'cyan']"
                :disabled="item.Default && !userIsSuperAdmin"
                :small="$vuetify.breakpoint.xsOnly"
                class="elevation-2 ma-0 mr-1"
                dark
                icon
                @click="showCatalogEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>

              <v-btn
                v-if="(posUserCan('KitchenMenu', 'DELETE') && !isExternalPlatformCatalog(item)) || userIsSuperAdmin"
                :class="[item.Default && !userIsSuperAdmin ? 'grey' : 'red']"
                :disabled="item.Default && !userIsSuperAdmin"
                :small="$vuetify.breakpoint.xsOnly"
                class="elevation-2 ma-0"
                dark
                icon
                @click="deleteCatalog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-overlay
        :value="importPlatformCatalogLoading"
        absolute
      >
        <div class="text-center">
          <v-progress-circular
            indeterminate
            size="64"
          />

          <h4
            class="mt-6 grey--text text--lighten-4"
            v-html="$t('Common.Misc.Notification.ImportPlatformInProgress')"
          />
        </div>
      </v-overlay>
    </v-container>

    <menu-import-dialog :visible.sync="importDialogVisible" />

    <menu-export-dialog
      :catalog="selectedItem"
      :visible.sync="exportDialogVisible"
    />

    <catalog-add-dialog
      :catalog-to-edit="selectedItem"
      :source-types-list="sourceTypesList"
      :visible.sync="addEditDialogVisible"
    />

    <catalog-duplicate-dialog
      :catalog-to-edit="selectedItem"
      :source-types-list="sourceTypesList"
      :visible.sync="duplicateCatalogDialogVisible"
      @click:save="onDuplicateDialogSave"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Catalogs.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.Catalogs.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Catalogs.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDeleteWithProducts"
    />
  </v-card>
</template>

<script>
import AppData                from '@/mixins/appdata'
import Auth                   from '@/mixins/auth'
import SourceType             from '@/mixins/orders/sourceType'
import CatalogDuplicateDialog from '@/components/restaurant/kitchen/CatalogDuplicateDialog.vue'
import ConfirmDialog          from '@/components/common/ConfirmDialog'
import FilterSearch           from '@/components/common/filter/FilterSearch'
import CatalogAddDialog       from './CatalogAddDialog'
import DataTable              from '@/mixins/data-table/dataTable'
import MenuExportDialog       from '@/components/restaurant/kitchen/MenuExportDialog.vue'
import MenuImportDialog       from '@/components/restaurant/kitchen/MenuImportDialog.vue'

export default {
  name      : 'Catalogs',
  components: {
    MenuImportDialog,
    MenuExportDialog,
    FilterSearch,
    'catalog-add-dialog'      : CatalogAddDialog,
    'catalog-duplicate-dialog': CatalogDuplicateDialog,
    'confirm-dialog'          : ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, SourceType, DataTable],
  data () {
    return {
      importPlatformCatalogLoading : false,
      confirmDialogDeleteVisible   : false,
      selectedItem                 : null,
      addEditDialogVisible         : false,
      importDialogVisible          : false,
      exportDialogVisible          : false,
      duplicateCatalogDialogVisible: false,
      dataTable                    : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Catalog.All,
          save  : window.SocketCommand.Catalog.Save,
          delete: window.SocketCommand.Catalog.Delete
        },
        options: {
          defaultSortBy: 'catalog_id',
          sortBy       : ['catalog_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.Image'),
            value    : 'color',
            align    : 'left',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption px-1',
            width    : 5
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.Id'),
            value    : 'catalog_id',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.Name'),
            value    : 'name',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.ProductsCount'),
            value    : 'products_count',
            align    : 'center',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.TableAreasCount'),
            value    : 'table_areas_count',
            align    : 'center',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.OrderSource'),
            value    : 'order_source',
            align    : 'left',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.OrderType'),
            value    : 'order_type',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.IsDefault'),
            value    : 'is_default',
            align    : 'center',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.Status'),
            value    : 'status',
            align    : 'center',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Catalogs.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption text-no-wrap px-2',
            width    : 140
          }
        ]
      }
    }
  },
  computed: {
    platforms () {
      return JSON.parse(JSON.stringify(this.appConfig?.LOCATION_DATA?.Platforms || {}))
    },

    canImportFromGetFood () {
      return this.posUserHasComponentPermission('KitchenMenu', 'ADD') &&
        this.platforms?.getfood?.active &&
        this.platforms?.getfood?.shops?.filter(shop => shop.active)?.length
    },

    canImportFromEFood () {
      return this.posUserHasComponentPermission('KitchenMenu', 'ADD') &&
        this.platforms?.efood?.active &&
        this.platforms?.efood?.shops?.filter(shop => shop.active)?.length
    },

    canImportFromFagi () {
      return this.posUserHasComponentPermission('KitchenMenu', 'ADD') &&
        this.platforms?.fagi?.active &&
        this.platforms?.fagi?.shops?.filter(shop => shop.active)?.length
    },

    canImportFromFoody () {
      return this.posUserHasComponentPermission('KitchenMenu', 'ADD') &&
        this.platforms?.foody?.active &&
        this.platforms?.foody?.shops?.filter(shop => shop.active)?.length
    },

    sourceTypesList () {
      let sourceTypesAll = this.appConfig?.SOURCE_TYPES?.DEFAULT?.ALL || {}
      let sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})
      let sourceTypesOther = this.appConfig?.SOURCE_TYPES?.OTHER || []

      sourceTypesAll = [{
        Id         : sourceTypesAll.source_type_id,
        Name       : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Title || sourceTypesAll.title,
        Description: this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Desc || sourceTypesAll.desc,
        Avatar     : {
          Icon : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Icon || sourceTypesAll.icon,
          Color: 'green'
        }
      }]

      sourceTypesDefault = sourceTypesDefault.filter(sourceType => sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })

      sourceTypesOther = sourceTypesOther.filter(sourceType => sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : o.title,
          Description: o.desc,
          Avatar     : {
            Img  : o.logo,
            Color: 'blue-grey'
          }
        }
      })

      return [...sourceTypesAll, ...sourceTypesDefault, ...sourceTypesOther]
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Catalog.Duplicate, this.onDuplicateCatalogResult)
    this.$bus.$on(window.SocketCommand.Catalog.ImportEFood, this.onImportFromEFoodResult)
    this.$bus.$on(window.SocketCommand.Catalog.ImportFoody, this.onImportFromFoodyResult)
    this.$bus.$on(window.SocketCommand.Catalog.ImportFagi, this.onImportFromFagiResult)
    this.$bus.$on(window.SocketCommand.Catalog.ImportGetFood, this.onImportFromGetFoodResult)
    this.$bus.$on(window.SocketCommand.Ajax.Get, this.updateData)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Catalog.Duplicate, this.onDuplicateCatalogResult)
    this.$bus.$off(window.SocketCommand.Catalog.ImportEFood, this.onImportFromEFoodResult)
    this.$bus.$off(window.SocketCommand.Catalog.ImportFoody, this.onImportFromFoodyResult)
    this.$bus.$off(window.SocketCommand.Catalog.ImportFagi, this.onImportFromFagiResult)
    this.$bus.$off(window.SocketCommand.Catalog.ImportGetFood, this.onImportFromGetFoodResult)
    this.$bus.$off(window.SocketCommand.Ajax.Get, this.updateData)
  },
  methods: {
    isExternalPlatformCatalogActive (catalog) {
      if (!catalog?.Api) return true

      return !!this.platforms[catalog.Api]?.active && !!this.platforms[catalog.Api]?.shops?.find(c => c.shopId === catalog.ShopId) && !this.importPlatformCatalogLoading
    },

    isExternalPlatformCatalog (catalog) {
      return !!catalog.Api && !!catalog.ShopId
    },

    onImportShopFromEFood (shop) {
      if (!shop?.shopId || !shop?.active) return

      this.onImportFromEFood(shop?.shopId)
    },
    onImportCatalogFromEFood (catalog) {
      if (!catalog?.ShopId) return
      this.onImportFromEFood(catalog?.ShopId)
    },
    onImportFromEFood (shopId) {
      if (!shopId) return

      this.importPlatformCatalogLoading = true

      window.callAS(window.SocketCommand.Catalog.ImportEFood, { ShopId: shopId })
    },
    onImportFromEFoodResult (data) {
      if (data?.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.ImportEFoodSuccess'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Error.Generic'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onImportShopFromFoody (shop) {
      if (!shop?.shopId || !shop?.active) return

      this.onImportFromFoody(shop?.shopId)
    },
    onImportCatalogFromFoody (catalog) {
      if (!catalog?.ShopId) return
      this.onImportFromFoody(catalog?.ShopId)
    },
    onImportFromFoody (shopId) {
      if (!shopId) return

      this.importPlatformCatalogLoading = true

      window.callAS(window.SocketCommand.Catalog.ImportFoody, { ShopId: shopId })
    },
    onImportFromFoodyResult (data) {
      if (data?.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.ImportFoodySuccess'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Error.Generic'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onImportShopFromFagi (shop) {
      if (!shop?.shopId || !shop?.active) return

      this.onImportFromFagi(shop?.shopId)
    },
    onImportCatalogFromFagi (catalog) {
      if (!catalog?.ShopId) return
      this.onImportFromFagi(catalog?.ShopId)
    },
    onImportFromFagi (shopId) {
      if (!shopId) return

      this.importPlatformCatalogLoading = true

      window.callAS(window.SocketCommand.Catalog.ImportFagi, { ShopId: shopId })
    },
    onImportFromFagiResult (data) {
      if (data?.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.ImportFagiSuccess'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Error.Generic'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onImportShopFromGetFood (shop) {
      if (!shop?.shopId || !shop?.active) return

      this.onImportFromGetFood(shop?.mainId, shop?.shopId)
    },
    onImportCatalogFromGetFood (catalog) {
      if (!catalog?.ShopId) return

      const platform = this.platforms[catalog.Api]?.shops?.find(c => c.shopId === catalog.ShopId)
      if (platform) this.onImportFromGetFood(platform.mainId, catalog?.ShopId)
    },
    onImportFromGetFood (shopId, internalId) {
      if (!shopId || !internalId) return

      this.importPlatformCatalogLoading = true

      window.callAS(window.SocketCommand.Catalog.ImportGetFood, {
        ShopId    : shopId,
        InternalId: internalId
      })
    },
    onImportFromGetFoodResult (data) {
      if (data?.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.ImportGetFoodSuccess'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Error.Generic'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    updateData (payload) {
      const command = window.SocketCommand.Catalog.All
      const payloadCommand = window.SocketCommand.commandToClientFormat(payload?.command || '')
      if (command === payloadCommand) {
        this.importPlatformCatalogLoading = false
        this.getData()
      }
    },

    onImportButtonClick (item) {
      this.selectedItem = item
      this.importDialogVisible = true
    },

    onExportButtonClick (item) {
      this.selectedItem = item
      this.exportDialogVisible = true
    },

    getItemSourceTypes (sourceTypes) {
      return this.sourceTypesList.filter(sourceType => sourceTypes.includes(sourceType.Id))
    },

    getCatalogOrderType (orderTypeId) {
      const orderTypes = this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Items')

      return orderTypes.find(orderType => orderType.Id === orderTypeId)
    },

    showCatalogAddDialog () {
      this.selectedItem = null
      this.addEditDialogVisible = true
    },

    showCatalogEditDialog (catalog) {
      if (catalog?.Default && !this.userIsSuperAdmin) return

      this.selectedItem = catalog
      this.addEditDialogVisible = true
    },

    duplicateCatalog (catalog) {
      this.selectedItem = catalog
      this.duplicateCatalogDialogVisible = true
    },

    onDuplicateDialogSave () {
      if (this.selectedItem) this.$set(this.selectedItem, 'DuplicateIsLoading', true)
    },

    onDuplicateCatalogResult (data) {
      const viewDataItems = this.viewData?.items || this.viewData?.table?.items?.items
      const item = viewDataItems?.find(catalog => parseInt(catalog?.Id) === parseInt(data?.result?.old))
      if (item) this.$set(item, 'DuplicateIsLoading', false)

      if (data.status === 'success') {
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.CatalogAddDialog.Notification.Duplicate', { id: data?.result?.new || '' }),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.CatalogAddDialog.Notification.DuplicateError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    updateItem (item) {
      window.callAS(window.SocketCommand.Catalog.Save, item)
    },

    deleteCatalog (item) {
      if (item?.Default && !this.userIsSuperAdmin) return

      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Catalog.Delete, {
        Id          : this.selectedItem.Id,
        WithProducts: false
      })
    },

    onConfirmDeleteWithProducts () {
      window.callAS(window.SocketCommand.Catalog.Delete, {
        Id          : this.selectedItem.Id,
        WithProducts: true
      })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Catalogs.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onProductsChipClick (catalogId) {
      this.$router.push({
        name  : 'KitchenMenu',
        params: { catalogId: catalogId }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--switch .v-input--selection-controls__input {
  margin-right : 0;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 65px !important;*/
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}

/deep/ .chip:focus:not(.chip--disabled):after, .chip--active:not(.chip--disabled):after, .chip--selected:not(.chip--disabled):after {
  top  : 0;
  left : 0;
}
</style>
