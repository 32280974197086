/**
 * Enum for table merge split mode.
 * @readonly
 * @enum {String} TableMergeSplitModeEnum
 */

const TableMergeSplitModeEnum = {
  Merge: 'Merge',
  Split: 'Split'
}

export default TableMergeSplitModeEnum
