export default {
  Title: 'Παραγγελίες | Παραγγελία | Παραγγελίες',

  Headers: {
    SourceType : '',
    OrderId    : '#',
    Customer   : 'ΠΕΛΑΤΗΣ',
    User       : 'ΧΡΗΣΤΗΣ',
    Status     : 'ΚΑΤΑΣΤΑΣΗ',
    DailyCheck : 'ΑΡ. ΗΜΕΡ.',
    OrderNumber: 'ΑΡ. ΠΑΡΑΓ.',
    OrderType  : 'ΤΥΠΟΣ',
    Payment    : 'ΠΛΗΡΩΜΗ',
    Total      : 'ΣΥΝΟΛΟ',
    Date       : 'ΗΜΕΡΟΜΗΝΙΑ'
  },

  Total: {
    PartiallyPaid: 'Μερικώς Πληρωμένο',
    NoFiscalDoc  : 'Χωρίς Παραστατικό',
    Receipt      : 'Απόδειξη',
    Invoice      : 'Τιμολόγιο'
  },

  Type: {
    Title   : 'Τύπος Παραγγελίας',
    Delivery: {
      Id   : 1,
      Title: 'Παράδοση',
      Icon : 'directions_bike',
      Color: 'purple'
    },
    Collection: {
      Id   : 2,
      Title: 'Παραλαβή',
      Icon : 'directions_run',
      Color: 'blue darken-3'
    },
    Time: {
      Day: {
        Title : 'Ημέρα | Ημέρες',
        Suffix: 'ημ'
      },
      Hour: {
        Title : 'Ώρα | Ώρες',
        Suffix: 'ώ'
      },
      Minute: {
        Title : 'Λεπτό | Λεπτά',
        Suffix: 'λ'
      },
      Second: {
        Title : 'Δευτερόλεπτο | Δευτερόλεπτα',
        Suffix: 'δ'
      }
    }
  },

  PaymentMethod: {
    Title: 'Μέθοδος πληρωμής | Μέθοδοι πληρωμών',
    Card : {
      Code : 'pos_card',
      Title: 'Κάρτα',
      Icon : 'fa-credit-card',
      Color: 'blue-grey darken-2'
    },
    Cash: {
      Code : 'cod',
      Title: 'Μετρητά',
      Icon : 'euro_symbol',
      Color: 'amber darken-4'
    }
  },

  FilterStatuses: {
    Title: 'Κατάσταση Παραγγελίας',
    Items: {
      Received: {
        Id      : 11,
        StatusId: 11,
        Title   : 'Ληφθείσα'
      },
      Accepted: {
        Id      : 21,
        StatusId: 21,
        Title   : 'Αποδεκτή'
      },
      Completed: {
        Id      : 15,
        StatusId: 15,
        Title   : 'Ολοκληρωμένη'
      },
      Canceled: {
        Id       : 19,
        StatusId : 19,
        DeletedAt: false,
        Title    : 'Ακυρωμένη'
      },
      Deleted: {
        Id       : -19,
        StatusId : 19,
        DeletedAt: true,
        Title    : 'Διαγραμμένη'
      }
    }
  },

  Statuses: {
    NotComplete: 'Εκκρεμής',
    Pending    : 'Εκκρεμής',
    Received   : 'Ληφθείσα',
    Preparation: 'Προετοιμάζεται',
    Delivery   : 'Παραδίδεται',
    Completed  : 'Ολοκληρωμένη',
    Canceled   : 'Ακυρωμένη',
    Deleted    : 'Διαγραμμένη',
    Printed    : 'Εκτυπωμένη',
    Accepted   : 'Αποδεκτή',

    Next: {
      Receive : 'Receive',
      Accept  : 'Αποδοχή',
      Prepare : 'Προετοίμασια',
      Deliver : 'Παράδοση',
      Complete: 'Ολοκλήρωση'
    }
  },

  Status: {
    Canceled: 'Ακυρωμένες | Ακυρωμένη | Ακυρωμένες',
    Delete  : 'Διαγράφηκε'
  },

  SourceType: {
    Title  : 'Πηγή Παραγγελίας',
    Default: {
      ALL: {
        Id   : 0,
        Order: 0,
        Name : 'ALL',
        Title: 'ΟΛΕΣ',
        Desc : 'Όλες οι πηγές παραγγελίας',
        Icon : 'swipe_right_alt'
      },
      ONLINE: {
        Id   : 1,
        Order: 1,
        Name : 'ONLINE',
        Title: 'WEBSITE',
        Desc : 'Online Παραγγελία - Website',
        Icon : 'language'
      },
      MOBILE: {
        Id   : 10,
        Order: 2,
        Name : 'MOBILE',
        Title: 'MOBILE APP',
        Desc : 'Online Παραγγελία - Mobile App',
        Icon : 'smartphone'
      },
      VOIP: {
        Id   : 2,
        Order: 3,
        Name : 'VOIP',
        Title: 'ΤΗΛΕΦΩΝΟ',
        Desc : 'Τηλεφωνική Παραγγελία',
        Icon : 'phone_in_talk'
      },
      TELEPHONY: {
        Id   : 3,
        Order: 4,
        Name : 'TELEPHONY',
        Title: 'ΤΗΛΕΦΩΝΟ',
        Desc : 'Τηλεφωνική Παραγγελία',
        Icon : 'local_phone'
      },
      TABLE: {
        Id   : 4,
        Order: 5,
        Name : 'TABLE',
        Title: 'ΤΡΑΠΕΖΙ',
        Desc : 'Παραγγελία Τραπεζιού',
        Icon : 'tab_unselected'
      },
      POS: {
        Id   : 5,
        Order: 6,
        Name : 'POS',
        Title: 'POS',
        Desc : 'Εσωτερική Παραγγελία',
        Icon : 'shopping_cart'
      },
      MARKETPLACE: {
        Id   : 11,
        Order: 7,
        Name : 'MARKETPLACE',
        Title: 'MARKETPLACE',
        Desc : 'Παραγγελία Marketplace',
        Icon : 'mdi-store-marker'
      },
      ONLINE_INTERNAL_TABLE: {
        Id   : 6,
        Order: 8,
        Name : 'ONLINE_INTERNAL_TABLE',
        Title: 'ONLINE ΤΡΑΠΕΖΙ',
        Desc : 'Online Παραγγελία - Τραπέζι',
        Icon : 'fas fa-border-none'
      },
      ONLINE_INTERNAL_BOOTH: {
        Id   : 7,
        Order: 9,
        Name : 'ONLINE_INTERNAL_BOOTH',
        Title: 'ONLINE BOOTH',
        Desc : 'Online Παραγγελία - Περίπτερο',
        Icon : 'fas fa-store'
      },
      ONLINE_INTERNAL_ROOM: {
        Id   : 8,
        Order: 10,
        Name : 'ONLINE_INTERNAL_ROOM',
        Title: 'ONLINE ROOM',
        Desc : 'Online Παραγγελία - Δωμάτιο',
        Icon : 'fas fa-h-square'
      },
      ONLINE_INTERNAL_SUNBED: {
        Id   : 9,
        Order: 11,
        Name : 'ONLINE_INTERNAL_SUNBED',
        Title: 'ONLINE SUNBED',
        Desc : 'Online Παραγγελία - Ξαπλώστρα',
        Icon : 'fas fa-umbrella-beach'
      }
    }
  },

  Print: {
    Button: {
      Title: 'Εκτύπωση παραγγελίας',
      Icon : 'print'
    },
    ButtonReportZ: {
      Title: 'ΑΝΑΦΟΡΑ Ζ',
      Icon : 'fa-solid fa-print'
    },
    Content: {
      DayTotal   : 'Σύνολο Ημέρας',
      Included   : 'Συμπεριλαμβάνονται',
      NotIncluded: 'Δεν Συμπεριλαμβάνονται',
      ByCard     : 'με @:Order.PaymentMethod.Card.Title ('
    },
    Notification       : 'Η παραγγελία στάλθηκε προς εκτύπωση!',
    NotificationReceipt: 'Η απόδειξη στάλθηκε προς εκτύπωση!',
    NotificationTotals : 'Η αναφορά στάλθηκε προς εκτύπωση!'
  },

  OrdersVList: {
    ReOrder: {
      Title: 'Επαναπαραγγελία',
      Icon : 'add_shopping_cart'
    }
  },

  OrderDetailsDialog: {
    Toolbar: {
      Subtitle: 'Στοιχεία Παραγγελίας #'
    },
    Footer: {
      DeliveryCost: 'Χρέωση παράδοσης'
    },
    Customer: {
      Name    : 'ΟΝ/ΜΟ:',
      Phone   : 'ΤΗΛ:',
      Comments: 'ΣΧΟΛΙΑ:',
      Email   : 'EMAIL',
      Address : {
        Title   : 'ΔΙΕΥΘΥΝΣΗ:',
        Street  : 'ΟΔΟΣ:',
        City    : 'ΠΟΛΗ:',
        PostCode: 'Τ.Κ.:',
        Floor   : 'ΟΡΟΦΟΣ:',
        Bell    : 'ΚΟΥΔΟΥΝΙ:',
        Comment : 'ΟΔΗΓΙΕΣ:'
      }
    },
    Transaction: {
      Title      : 'Συναλλαγές',
      Amount     : 'Ποσό',
      PaymentType: 'Τύπος',
      Terminal   : 'EFT/POS',
      Action     : 'Ενέργεια',
      Status     : 'Κατάσταση',
      Date       : 'Ημ/νία',
      Vendor     : 'Vendor',
      Total      : 'Σύνολο',
      Type       : {
        Sale  : 'Χρέωση',
        Refund: 'Επιστροφή'
      }
    },
    Receipt: {
      Title        : 'Παραστατικά',
      Cancel       : 'Ακύρωση Παραστατικού',
      CancelSuccess: 'Το παραστατικό ακυρώθηκε επιτυχώς',
      ConfirmDialog: {
        Cancel: {
          Title       : 'Ακύρωση Παραστατικού;',
          Content     : 'Είστε σίγουροι ότι θέλετε ακυρώσετε το παραστατικό;',
          Notification: 'Η ακύρωση παραστατικού είναι μη αναστρέψιμη!'
        },
        CancelWithRefund: {
          Title       : 'Ακύρωση Παραστατικού;',
          Content     : 'Είστε σίγουροι ότι θέλετε ακυρώσετε το παραστατικό και να κάνετε επιστροφή {amount} στην κάρτα του πελάτη;',
          Notification: 'Η ακύρωση παραστατικού και η επιστροφή χρημάτων είναι μη αναστρέψιμη!'
        }
      },
      Order      : 'Παραγγελία',
      Type       : 'Τύπος',
      DailyCheck : 'Αρ. Ημέρ.',
      OrderNumber: 'Αρ. Παραγ.',
      TotalCheck : 'Αρ. Προόδ.',
      Amount     : 'Πληρ. Ποσό',
      RelatedDocs: 'Σχ. Παραστατ.',
      PrintCount : 'Αρ. Εκτυπώσεων',
      CreatedAt  : 'Ημ/νία',
      Aade       : 'ΑΑΔΕ',
      Status     : 'Κατάσταση',
      Mark       : 'Μ.Αρ.Κ',
      Uid        : 'Αναγνωριστικό',
      AuthCode   : 'Υπογραφή',
      Provider   : 'Πάροχος',
      Submitted  : 'Το παραστατικό έχει υποβληθεί στην ΑΑΔΕ'
    }
  },

  OrdersAll: {
    Toolbar: {
      Button: {
        Title: 'Νέα παραγγελία',
        Icon : 'add_box'
      }
    },
    Filter: {
      filterOrderType: {
        Type       : 'v-btn-toggle',
        Label      : 'Τύπος Παραγγελίας',
        PlaceHolder: 'Όλοι οι τύποι παραγγελίας',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Παράδοση',
            Icon : 'directions_bike',
            Color: 'purple',
            Class: 'white--text'
          },
          {
            Value: 2,
            Name : 'Παραλαβή',
            Icon : 'directions_run',
            Color: 'blue darken-3',
            Class: 'white--text'
          }
        ]
      },
      filterOrderReceipt: {
        Type       : 'v-btn-toggle',
        Label      : 'Παραστατικό',
        PlaceHolder: 'Όλα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'Όλα'
          },
          {
            Value: 1,
            Name : 'Ναι',
            Icon : 'mdi-receipt-text-check-outline',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: 2,
            Name : 'Όχι',
            Icon : 'mdi-receipt-text-remove',
            Color: 'red',
            Class: 'white--text'
          }
        ]
      },
      filterHasDeliveryStaff: {
        Type       : 'v-btn-toggle',
        Label      : 'Ανάθεση σε Διανομέα',
        PlaceHolder: 'Όλες οι παραγγελίες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Ναι',
            Icon : 'directions_bike',
            Color: 'purple',
            Class: 'white--text'
          },
          {
            Value: -1,
            Name : 'Όχι',
            Icon : 'directions_bike',
            Color: 'blue darken-3',
            Class: 'white--text'
          }
        ]
      },
      filterOrderStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Κατάσταση Παραγγελίας',
        PlaceHolder: 'Όλες οι καταστάσεις παραγγελίας',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        ItemAll    : {
          Id      : 0,
          StatusId: 0,
          Name    : 'Όλες οι καταστάσεις παραγγελίας',
          Avatar  : {
            Color: 'green',
            Icon : 'mdi-check'
          }
        },
        Items: [
          {
            Id      : 11,
            StatusId: 11,
            Name    : 'Ληφθείσα',
            Avatar  : {
              Color: 'grey',
              Icon : 'call_received'
            }
          },
          {
            Id      : 21,
            StatusId: 21,
            Name    : 'Αποδεκτή',
            Avatar  : {
              Color: 'green',
              Icon : 'done'
            }
          },
          {
            Id      : 13,
            StatusId: 21,
            Name    : 'Προετοιμάζεται',
            Avatar  : {
              Color: 'purple',
              Icon : 'room_service'
            }
          },
          {
            Id      : 14,
            StatusId: 21,
            Name    : 'Παραδίδεται',
            Avatar  : {
              Color: 'brown',
              Icon : 'directions_run'
            }
          },
          {
            Id      : 15,
            StatusId: 15,
            Name    : 'Ολοκληρωμένη',
            Avatar  : {
              Color: 'light-green',
              Icon : 'done_all'
            }
          },
          {
            Id       : 19,
            StatusId : 19,
            DeletedAt: false,
            Name     : 'Ακυρωμένη',
            Avatar   : {
              Color: 'pink',
              Icon : 'close'
            }
          },
          {
            Id       : -19,
            StatusId : 19,
            DeletedAt: true,
            Name     : 'Διαγραμμένη',
            Avatar   : {
              Color: 'red',
              Icon : 'delete_forever'
            }
          }
        ]
      },
      filterSourceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Πηγή Παραγγελίας',
        PlaceHolder: 'Όλες οι πηγές παραγγελίας',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterPaymentType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Μέθοδος Πληρωμής',
        PlaceHolder: 'Όλες οι μέθοδοι πληρωμής',
        ItemText   : 'Title',
        ItemValue  : 'Code',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterStaff: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Χρήστης',
        PlaceHolder: 'Όλοι οι χρήστες',
        ItemText   : 'StaffFullname',
        ItemValue  : 'StaffId',
        Items      : []
      },
      filterDate: {
        Type       : 'v-date-picker',
        Label      : 'Ημερομηνία Παραγγελίας',
        PlaceHolder: 'Επιλέξτε Ημερομηνία',
        Icon       : 'event'
      }
    },
    ListView: {
      Type: {
        Collection: '@:Order.Type.Collection.Title σε',
        Delivery  : '@:Order.Type.Delivery.Title σε'
      },
      Status: {
        DeletedAt: '@:Order.Status.Delete στις'
      },
      Button: {
        Edit: {
          Title: 'Επεξεργασία παραγγελίας',
          Icon : 'edit',
          Color: 'cyan lighten-2',
          PopUp: {
            Content: {
              Choose: 'Επιλέξτε αν θέλετε να',
              Delete: 'διαγράψετε',
              Or    : 'ή να',
              Copy  : 'αντιγράψετε',
              Order : 'την παραγγελία και να δημιουργήσετε μια νέα παραγγελία με τα στοιχεία αυτής προς επεξεργασία.'
            },
            Button: {
              Copy  : 'Αντιγραφη & Δημιουργια Νεας',
              Delete: 'Διαγραφη & Δημιουργια Νεας'
            },
            Notification: 'Οι εκπτώσεις και τα tips δεν θα συμπεριληφθούν στην δημιουργία της παραγγελίας.'
          }
        },
        View: {
          Title: 'Προβολή στοιχείων παραγγελίας',
          Icon : 'remove_red_eye',
          Color: 'cyan'
        },
        Cancel: {
          Title: 'Ακύρωση παραγγελίας',
          Icon : 'close',
          Color: 'pink accent-3'
        },
        Delete: {
          Title: 'Οριστική Διαγραφή Παραγγελίας',
          Icon : 'delete',
          Color: 'red'
        }
      }
    }
  },

  OrderChangeStatusDialog: {
    CancelOrder: {
      Title   : '@:Order.OrdersAll.ListView.Button.Cancel.Title;',
      Subtitle: {
        Order            : 'Η παραγγελία',
        CancelPermanently: 'θα ακυρωθεί οριστικά.'
      }
    },
    Field: {
      Name     : '@:Common.Misc.Name:',
      Telephone: 'Τηλέφωνο:',
      Time     : 'Ώρα:',
      Total    : '@:Common.Misc.Total:'
    },
    Notification: {
      Title  : 'Η ακύρωση παραγγελίας είναι μη αναστρέψιμη!',
      Amount : 'Το ποσό των',
      Refund : 'έχει πληρωθεί με κάρτα και θα επιστραφεί αυτόματα.',
      Receipt: 'Για την παραγγελία έχει εκδοθεί απόδειξη και θα ακυρωθεί αυτόματα.',
      Invoice: 'Για την παραγγελία έχει εκδοθεί τιμολόγιο και θα ακυρωθεί αυτόματα.'
    }
  },

  OrderDeleteDialog: {
    DeleteOrder: {
      Title   : 'Οριστική Διαγραφή Παραγγελίας;',
      Subtitle: {
        Order            : 'Η παραγγελία',
        CancelPermanently: 'θα διαγραφεί οριστικά.'
      }
    },
    DeleteOrders: {
      Title   : 'Οριστική Διαγραφή Παραγγελιών;',
      Subtitle: 'Έχετε επιλέξει {orders} παραγγελίες | Έχετε επιλέξει {orders} παραγγελία | Έχετε επιλέξει {orders} παραγγελίες',
      Body    : 'Έχετε επιλέξει {orders} παραγγελίες για οριστική διαγραφή. | Έχετε επιλέξει {orders} παραγγελία για οριστική διαγραφή. | Έχετε επιλέξει {orders} παραγγελίες για οριστική διαγραφή.'
    },
    DeleteAllOrders: {
      Title   : 'Οριστική Διαγραφή Παραγγελιών;',
      Subtitle: '',
      Body    : 'Επιλέξτε αν θέλετε να κάνετε <strong>οριστική διαγραφή όλων</strong> ή <strong>μόνο των δικών</strong> σας παραγγελιών.',
      Button  : {
        YesAll : 'Διαγραφή Όλων',
        YesMine: 'Διαγραφή Δικών μου'
      },
      Notification: {
        Title: 'Η οριστική διαγραφή παραγγελιών είναι μη αναστρέψιμη!'
      }
    },
    Field: {
      Name     : '@:Common.Misc.Name:',
      Telephone: 'Τηλέφωνο:',
      Time     : 'Ώρα:',
      Total    : '@:Common.Misc.Total:'
    },
    Notification: {
      Title                   : 'Η οριστική διαγραφή παραγγελίας είναι μη αναστρέψιμη!',
      Amount                  : 'Το ποσό των',
      Refund                  : 'έχει πληρωθεί με κάρτα και <strong>ΔΕΝ θα επιστραφεί</strong> αυτόματα.',
      Receipt                 : 'Για την παραγγελία έχει εκδοθεί απόδειξη και <strong>ΔΕΝ θα ακυρωθεί</strong> αυτόματα.',
      Invoice                 : 'Για την παραγγελία έχει εκδοθεί τιμολόγιο και <strong>ΔΕΝ θα ακυρωθεί</strong> αυτόματα.',
      Tip                     : 'Διαφορετικά κάντε πρώτα "ΑΚΥΡΩΣΗ" και μετά "ΔΙΑΓΡΑΦΗ" παραγγελίας.',
      RefundMultiple          : 'Εαν υπάρχουν πληρωμές με κάρτα <strong>ΔΕΝ θα γίνει επιστροφή</strong> αυτόματα.',
      ReceiptOrInvoiceMultiple: 'Εάν έχουν εκδοθεί αποδείξεις ή τιμολόγια <strong>ΔΕΝ θα ακυρωθούν</strong> αυτόματα.'
    }
  },

  SelectOrderDialog: {
    Toolbar: {
      Title   : 'Παραγγελίες',
      Subtitle: 'Επιλογή Παραγγελίας',
      Icon    : 'shopping_cart',
      Color   : 'indigo'
    },
    Result: {
      Button: {
        All: {
          Title: 'Όλες',
          Icon : 'shopping_cart'
        }
      },
      NoOrder: {
        Title   : 'Δεν Υπάρχουν Παραγγελίες',
        Subtitle: 'ή έχουν προστεθεί όλες σε διανομείς',
        Icon    : 'shopping_cart',
        Color   : 'grey lighten-2'
      }
    }
  },

  OrdersMapDialog: {
    Toolbar: {
      Title   : 'Χάρτης Παραγγελιών',
      Subtitle: 'προς παράδοση',
      Icon    : 'map',
      Color   : 'light-green',
      Button  : {
        ResetMap: {
          Title: 'Επαναφορά Χάρτη',
          Icon : 'my_location'
        },
        MapLimit: {
          Title: 'Όρια Διανομής Καταστήματος',
          Icon : 'map'
        },
        Delivery: {
          Icon: 'directions_bike'
        },
        ReceivedOrder: {
          Title: 'Ληφθείσες Παραγγελίες',
          Icon : 'shopping_cart'
        },
        AcceptedOrder: {
          Title: 'Αποδεκτές Παραγγελίες',
          Icon : 'shopping_cart'
        },
        CompletedOrder: {
          Title: 'Ολοκληρωμένες Παραγγελίες',
          Icon : 'shopping_cart'
        }
      }
    }
  },

  OrdersSaved: {
    ListView: {
      EditedBy : 'Την επεξεργάζεται ο χρήστης',
      BelongsTo: 'Ανήκει στον χρήστη'
    }
  },

  OrdersToday: {
    Toolbar: {
      Button: {
        Print: {
          Title       : 'Εκτύπωση',
          Icon        : 'print',
          Notification: 'Οι παραγγελίες στάλθηκαν προς εκτύπωση!'
        },
        Map: {
          Title: 'Χάρτης',
          Icon : 'map'
        },
        All: {
          Title: 'Όλες',
          Icon : 'shopping_cart'
        }
      }
    },
    ListView: {
      Button: {
        OrderPayment: {
          Title: 'Συναλλαγές Πληρωμών',
          Icon : 'mdi-account-credit-card',
          Color: 'indigo'
        },
        AddTo: {
          Title: 'Προσθήκη παραγγελίας σε βάρδια διανομέα',
          Icon : 'directions_bike',
          Color: 'light-green darken-1'
        },
        RemoveFrom: {
          Title: 'Αφαίρεση παραγγελίας από βάρδια διανομέα',
          Icon : 'directions_bike',
          Color: 'red accent-4',
          PopUp: {
            Title  : 'Αφαίρεση Παραγγελίας;',
            Content: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε την παραγγελία από τη βάρδια του διανομέα:'
          }
        }
      }
    }
  },

  OrdersToolbar: {
    All  : 'Όλες',
    Today: 'Σημερινές',
    Saved: 'Αποθηκευμένες'
  }
}
