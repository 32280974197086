import Vue                          from 'vue'
import Router                       from 'vue-router'
// Route Templates
import Login                        from '@/components/authentication/Login'
import Dashboard                    from '@/components/dashboard/Dashboard'
import Orders                       from '@/components/orders/Orders'
import OrdersToday                  from '@/components/orders/OrdersToday'
import OrdersSaved                  from '@/components/orders/OrdersSaved'
import OrdersAll                    from '@/components/orders/OrdersAll'
import Customers                    from '@/components/customers/Customers'
import CustomerGroups               from '@/components/customers/CustomerGroups'
import DeliveryStaff                from '@/components/delivery/DeliveryStaff'
import WaiterStaff                  from '@/components/waiters/WaiterStaff'
import Staff                        from '@/components/staff/Staff'
import StaffGroups                  from '@/components/staff/StaffGroups'
import Pos                          from '@/components/pos/Pos'
import Tags                         from '@/components/restaurant/kitchen/Tags'
import KitchenMenu                  from '@/components/restaurant/kitchen/Menu'
import KitchenCatalogs              from '@/components/restaurant/kitchen/Catalogs'
import KitchenMenuCategories        from '@/components/restaurant/kitchen/Categories'
import KitchenMealTimes             from '@/components/restaurant/kitchen/MealTimes'
import KitchenMenuOptions           from '@/components/restaurant/kitchen/Options'
import KitchenMenuOptionValueGroups from '@/components/restaurant/kitchen/MenuOptionValueGroups'
import MarketingCoupons             from '@/components/marketing/Coupons'
import MessagesInbox                from '@/components/messages/MessagesInbox'
import SystemSettings               from '@/components/settings/SystemSettings'
import SystemSettingsRestaurant     from '@/components/settings/SystemSettingsRestaurant'
import SystemSettingsWorkingHours   from '@/components/settings/SystemSettingsWorkingHours'
import SystemSettingsOrders         from '@/components/settings/SystemSettingsOrders'
import SystemSettingsReservations   from '@/components/settings/SystemSettingsReservations'
import SystemSettingsNotifications  from '@/components/settings/SystemSettingsNotifications'
import SystemSettingsPrinters       from '@/components/settings/SystemSettingsPrinters'
import SystemSettingsDisplays       from '@/components/settings/SystemSettingsDisplays'
import SystemSettingsDelivery       from '@/components/settings/SystemSettingsDelivery'
import SystemSettingsEmail          from '@/components/settings/SystemSettingsEmail'
import SystemSettingsTables         from '@/components/settings/SystemSettingsTables'
import SystemSettingsPinManager     from '@/components/settings/SystemSettingsPinManager'
import SystemSettingsPayments       from '@/components/settings/SystemSettingsPayments'
import SystemSettingsWebSite        from '@/components/settings/SystemSettingsWebSite'
import SystemSettingsRegional       from '@/components/settings/SystemSettingsRegional'
import SystemSettingsDocuments      from '@/components/settings/SystemSettingsDocuments'
import SystemSettingsPermissions    from '@/components/settings/SystemSettingsPermissions'
import SystemSettingsDomains        from '@/components/settings/SystemSettingsDomains'
import SystemSettingsOnlineUsers    from '@/components/settings/SystemSettingsOnlineUsers'
import SystemSettingsSystemUsers    from '@/components/settings/SystemSettingsSystemUsers'
import Faqs                         from '@/components/faq/Faqs'
import FaqCategories                from '@/components/faq/FaqCategories'
import VoipCallHistory              from '@/components/voip/VoipCallHistory'
import Account                      from '@/components/account/Account'
import AccountOverview              from '@/components/account/AccountOverview'
import AccountBilling               from '@/components/account/AccountBilling'
import AccountPlans                 from '@/components/account/AccountPlans'
import AccountInvoices              from '@/components/account/AccountInvoices'
import SupportCenter                from '@/components/support-center/SupportCenter'
import TableFloorPlan               from '@/components/restaurant/tables/floor-plan/TableFloorPlan'
import TableAreasManagement         from '@/components/restaurant/tables/management/TableAreasManagement'
import TableManagement              from '@/components/restaurant/tables/management/TableManagement'
import Tables                       from '@/components/restaurant/tables/tables/Tables'
import QrManagement                 from '@/components/qr/QrManagement'
import Charges                      from '@/components/charges/Charges'
import Statistics                   from '@/components/statistics/Statistics'
import ProductStatistics            from '@/components/statistics/ProductStatistics'
import SalesReportZ                 from '@/components/statistics/SalesReportZ'
import OrdersHeatmap                from '@/components/statistics/OrdersHeatmap'
import TableReservation             from '@/components/restaurant/reservations/TableReservation'
import Receipts                     from '@/components/receipts/Receipts'
import Cds                          from '@/components/cds/Cds'
import DomainLocked                 from '@/components/account/DomainLocked'

/*
const Login = () => import(/!* webpackChunkName: "authentication" *!/ '../components/authentication/Login')
const Dashboard = () => import(/!* webpackChunkName: "dashboard" *!/ '../components/dashboard/Dashboard')
const Orders = () => import(/!* webpackChunkName: "orders" *!/ '../components/orders/Orders')
const OrdersToday = () => import(/!* webpackChunkName: "orders-today" *!/ '../components/orders/OrdersToday')
const OrdersSaved = () => import(/!* webpackChunkName: "orders-saved" *!/ '../components/orders/OrdersSaved')
const OrdersAll = () => import(/!* webpackChunkName: "orders-all" *!/ '../components/orders/OrdersAll')
const Customers = () => import(/!* webpackChunkName: "customers" *!/ '../components/customers/Customers')
const CustomerGroups = () => import(/!* webpackChunkName: "customer-groups" *!/ '../components/customers/CustomerGroups')
const DeliveryStaff = () => import(/!* webpackChunkName: "delivery-staff" *!/ '../components/delivery/DeliveryStaff')
const Staff = () => import(/!* webpackChunkName: "staff" *!/ '../components/staff/Staff')
const StaffGroups = () => import(/!* webpackChunkName: "staff-groups" *!/ '../components/staff/StaffGroups')
const Pos = () => import(/!* webpackChunkName: "pos" *!/ '../components/pos/Pos')
const KitchenMenu = () => import(/!* webpackChunkName: "kitchen-menu" *!/ '../components/restaurant/kitchen/Menu')
const KitchenMenuCategories = () => import(/!* webpackChunkName: "kitchen-menu-categories" *!/ '../components/restaurant/kitchen/Categories')
const KitchenMealTimes = () => import(/!* webpackChunkName: "kitchen-meal-times" *!/ '../components/restaurant/kitchen/MealTimes')
const KitchenMenuOptions = () => import(/!* webpackChunkName: "kitchen-menu-options" *!/ '../components/restaurant/kitchen/Options')
const KitchenMenuOptionValueGroups = () => import(/!* webpackChunkName: "kitchen-menu-option-values" *!/ '../components/restaurant/kitchen/MenuOptionValueGroups')
const MarketingCoupons = () => import(/!* webpackChunkName: "marketing" *!/ '../components/marketing/Coupons')
const MessagesInbox = () => import(/!* webpackChunkName: "messages" *!/ '../components/messages/MessagesInbox')
const SystemSettings = () => import(/!* webpackChunkName: "system-settings" *!/ '../components/settings/SystemSettings')
const SystemSettingsRestaurant = () => import(/!* webpackChunkName: "system-settings-restaurant" *!/ '../components/settings/SystemSettingsRestaurant')
const SystemSettingsWorkingHours = () => import(/!* webpackChunkName: "system-settings-working-hours" *!/ '../components/settings/SystemSettingsWorkingHours')
const SystemSettingsOrders = () => import(/!* webpackChunkName: "system-settings-orders" *!/ '../components/settings/SystemSettingsOrders')
const SystemSettingsReservations = () => import(/!* webpackChunkName: "system-settings-reservations" *!/ '../components/settings/SystemSettingsReservations')
const SystemSettingsNotifications = () => import(/!* webpackChunkName: "system-settings-notifications" *!/ '../components/settings/SystemSettingsNotifications')
const SystemSettingsPrinters = () => import(/!* webpackChunkName: "system-settings-printers" *!/ '../components/settings/SystemSettingsPrinters')
const SystemSettingsDelivery = () => import(/!* webpackChunkName: "system-settings-delivery" *!/ '../components/settings/SystemSettingsDelivery')
const SystemSettingsEmail = () => import(/!* webpackChunkName: "system-settings-email" *!/ '../components/settings/SystemSettingsEmail')
const SystemSettingsPayments = () => import(/!* webpackChunkName: "system-settings-payments" *!/ '../components/settings/SystemSettingsPayments')
const SystemSettingsWebSite = () => import(/!* webpackChunkName: "system-settings-website" *!/ '../components/settings/SystemSettingsWebSite')
const SystemSettingsPermissions = () => import(/!* webpackChunkName: "system-settings-permissions" *!/ '../components/settings/SystemSettingsPermissions')
*/

Vue.use(Router)

window.routerComponents = {
  Login                       : Login,
  Dashboard                   : Dashboard,
  Orders                      : Orders,
  OrdersToday                 : OrdersToday,
  OrdersSaved                 : OrdersSaved,
  OrdersAll                   : OrdersAll,
  Customers                   : Customers,
  CustomerGroups              : CustomerGroups,
  DeliveryStaff               : DeliveryStaff,
  WaiterStaff                 : WaiterStaff,
  Staff                       : Staff,
  StaffGroups                 : StaffGroups,
  Pos                         : Pos,
  Tags                        : Tags,
  KitchenMenu                 : KitchenMenu,
  KitchenCatalogs             : KitchenCatalogs,
  KitchenMealTimes            : KitchenMealTimes,
  KitchenMenuCategories       : KitchenMenuCategories,
  KitchenMenuOptions          : KitchenMenuOptions,
  KitchenMenuOptionValueGroups: KitchenMenuOptionValueGroups,
  MarketingCoupons            : MarketingCoupons,
  MessagesInbox               : MessagesInbox,
  SystemSettings              : SystemSettings,
  SystemSettingsRestaurant    : SystemSettingsRestaurant,
  SystemSettingsWorkingHours  : SystemSettingsWorkingHours,
  SystemSettingsDelivery      : SystemSettingsDelivery,
  SystemSettingsEmail         : SystemSettingsEmail,
  SystemSettingsTables        : SystemSettingsTables,
  SystemSettingsPinManager    : SystemSettingsPinManager,
  SystemSettingsPayments      : SystemSettingsPayments,
  SystemSettingsWebSite       : SystemSettingsWebSite,
  SystemSettingsRegional      : SystemSettingsRegional,
  SystemSettingsDocuments     : SystemSettingsDocuments,
  SystemSettingsOrders        : SystemSettingsOrders,
  SystemSettingsReservations  : SystemSettingsReservations,
  SystemSettingsNotifications : SystemSettingsNotifications,
  SystemSettingsPrinters      : SystemSettingsPrinters,
  SystemSettingsDisplays      : SystemSettingsDisplays,
  SystemSettingsPermissions   : SystemSettingsPermissions,
  SystemSettingsDomains       : SystemSettingsDomains,
  SystemSettingsOnlineUsers   : SystemSettingsOnlineUsers,
  SystemSettingsSystemUsers   : SystemSettingsSystemUsers,
  Faqs                        : Faqs,
  FaqCategories               : FaqCategories,
  VoipCallHistory             : VoipCallHistory,
  Account                     : Account,
  AccountOverview             : AccountOverview,
  AccountBilling              : AccountBilling,
  AccountPlans                : AccountPlans,
  AccountInvoices             : AccountInvoices,
  SupportCenter               : SupportCenter,
  TableAreasManagement        : TableAreasManagement,
  TableManagement             : TableManagement,
  Tables                      : Tables,
  TableFloorPlan              : TableFloorPlan,
  QrManagement                : QrManagement,
  Charges                     : Charges,
  Statistics                  : Statistics,
  ProductStatistics           : ProductStatistics,
  SalesReportZ                : SalesReportZ,
  OrdersHeatmap               : OrdersHeatmap,
  TableReservation            : TableReservation,
  Receipts                    : Receipts,
  Cds                         : Cds,
  DomainLocked                : DomainLocked
}

const routes = [
  {
    name     : 'Login',
    path     : '/login',
    component: Login,
    meta     : {
      requiresAuth: false,
      layout      : 'login'
    }
  },
  {
    name     : 'Cds',
    path     : '/cds',
    component: Cds,
    meta     : {
      requiresAuth: false,
      layout      : 'empty'
    }
  },
  {
    name     : 'DomainLocked',
    path     : '/locked',
    component: DomainLocked,
    meta     : {
      requiresAuth: false,
      layout      : 'locked'
    }
  },
  /*
  {
    name     : 'TableFloorPlan',
    path     : '/table-management/floor-plan',
    component: TableFloorPlan,
    meta     : {
      title       : 'FLOOR PLAN',
      subTitle    : 'Διαχείριση Τραπεζιών',
      requiresAuth: true
    }
  },
   */
  {
    name    : 'Default',
    path    : '*',
    redirect: '/login'
  }
]

const createRouter = () => new Router({
  mode  : 'history',
  base  : process.env.VUE_APP_PUBLIC_PATH,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

const router = createRouter()

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
Router.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch(error => error)
}

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  window.isVueRoutesInited = false
}

export default router
