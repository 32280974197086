export default {
  Title: 'Λογαριασμός',

  Toolbar: {
    Tab: {
      Overview: 'Επισκόπηση',
      Billing : 'Στοιχεία Χρέωσης',
      Plan    : 'Πακέτα & Πρόσθετα',
      Invoice : 'Πληρωμές & Τιμολόγια'
    }
  },

  Overview: {
    Title   : 'Το Προφίλ Μου',
    Subtitle: 'Μέλος από {date}',

    Field: {
      Firstname: {
        Label: 'Όνομα',
        Hint : 'Το όνομα σας. πχ. Βαγγέλης, Μαρία, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Lastname: {
        Label: 'Επίθετο',
        Hint : 'Το επίθετο σας. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Telephone: {
        Label: 'Τηλέφωνο',
        Hint : 'Το κινητό ή σταθερό τηλέφωνο σας. πχ. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Η διεύθυνση Email σας. πχ. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
          Valid  : 'Το E-mail πρέπει να είναι έγκυρο'
        }
      },
      GroupName: {
        Label: 'Ομάδα Χρηστών',
        Hint : 'Η Ομάδα χρηστών. πχ. Διαχειριστής, Χρήστης',
        Icon : 'group'
      },
      Pin: {
        Label: 'PIN',
        Hint : 'Το PIN σας πχ. 1234',
        Icon : 'fiber_pin',
        Error: {
          FixedNumber: 'Το PIN πρέπει να είναι 4 αριθμοί'
        }
      },
      Locale: {
        Label: 'Γλώσσα Συστήματος',
        Hint : 'Επιλέξτε τη γλώσσα που θέλετε να εμφανίζεται το σύστημα',
        Icon : 'language'
      },

      Theme: {
        Title: 'Επιλογή Θέματος',
        Items: [
          {
            Value: 'auto',
            Label: 'Auto',
            Hint : 'Χρήση θέματος συστήματος'
          },
          {
            Value: 'dark',
            Label: 'Dark',
            Hint : 'Χρήση θέματος Dark'
          },
          {
            Value: 'light',
            Label: 'Light',
            Hint : 'Χρήση θέματος Light'
          }
        ]
      }
    },

    DeleteAccount: {
      Toolbar: {
        Title   : 'Κλείσιμο  Λογαριασμού',
        Subtitle: 'Κλείστε τον GETFOOD Pro λογαριασμό σας και διαγράψτε όλα τα δεδομένα του. Αυτή η ενέργεια είναι μη αναστρέψιμη!'

      },
      Delete: 'Αίτηση Κλεισίματος Λογαριασμού'
    }
  },

  Billing: {
    Misc: {
      Reseller: {
        Title   : 'Στοιχεία Συνεργάτη',
        Subtitle: 'Στοιχεία Επικοινωνίας Συνεργάτη'
      },
      ContactInfo: 'Στοιχεία Επικοινωνίας',
      BillingInfo: 'Στοιχεία Χρέωσης',
      InvoiceInfo: 'Στοιχεία Τιμολόγησης'
    },
    Field: {
      Firstname: {
        Label: 'Όνομα',
        Hint : 'Όνομα επαφής. πχ. Βαγγέλης, Μαρία, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 128 χαρακτήρες'
        }
      },
      Lastname: {
        Label: 'Επίθετο',
        Hint : 'Επίθετο επαφής. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το επίθετο πρέπει να είναι από 2 έως 128 χαρακτήρες'
        }
      },
      Telephone: {
        Label: 'Τηλέφωνο',
        Hint : 'Κινητό ή σταθερό τηλέφωνο επαφής. πχ. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Διεύθυνση Email επαφής. πχ. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
          Valid  : 'Το E-mail πρέπει να είναι έγκυρο'
        }
      },
      IsReceiptToggle: {
        Invoice: 'Τιμολόγιο',
        Receipt: 'Απόδειξη',
        Fnb    : 'Δελτίο Παραγγελίας',
        None   : 'Χωρίς Παραστατικό'
      },
      Company: {
        Label: 'Επωνυμία',
        Hint : 'Επωνυμία εταιρείας. πχ. OTO DEV O.E.',
        Icon : 'account_balance',
        Error: {
          Between: 'Η Επωνυμία εταιρείας πρέπει να είναι από 2 έως 100 χαρακτήρες'
        }
      },
      Address: {
        Label: 'Διεύθυνση',
        Icon : 'mdi-map-search'
      },
      Profession: {
        Label: 'Είδος εταιρείας',
        Hint : 'Είδος εταιρείας. πχ. Πιτσαρία, Εστιατόριο, κλπ.',
        Icon : 'account_balance',
        Error: {
          Between: 'Το Είδος εταιρείας πρέπει να είναι από 2 έως 128 χαρακτήρες'
        }
      },
      DistinctiveTitle: {
        Label: 'Διακριτικός τίτλος',
        Hint : 'Διακριτικός τίτλος εταιρείας. πχ. Το Κουτούκι, κλπ.',
        Icon : 'account_balance',
        Error: {
          Between: 'Ο διακριτικός τίτλος της εταιρείας πρέπει να είναι από 2 έως 128 χαρακτήρες'
        }
      },
      Irs: {
        Label: 'ΔΟΥ',
        Hint : 'ΔΟΥ Εταιρείας. πχ. ΦΑΕΕ Αθηνών',
        Icon : 'toll',
        Error: {
          Between: 'H ΔΟΥ εταιρείας πρέπει να είναι από 2 έως 128 χαρακτήρες'
        }
      },
      Vat: {
        Label: 'ΑΦΜ',
        Hint : 'ΑΦΜ Εταιρείας. πχ. EL888223344',
        Icon : 'toll',
        Error: {
          Between: 'O ΑΦΜ της εταιρείας πρέπει να είναι 7 έως 12 χαρακτήρες'
        }
      },
      Gemi: {
        Label: 'Γ.Ε.ΜΗ',
        Hint : 'Αριθμός Γ.Ε.ΜΗ',
        Icon : 'toll',
        Error: {
          Between: 'O Αριθμός Γ.Ε.ΜΗ της εταιρείας πρέπει να είναι 12 χαρακτήρες'
        }
      },
      CompanyPhone: {
        Label: 'Τηλέφωνο',
        Hint : 'Τηλέφωνο εταιρείας. πχ. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },

      Country: {
        Label: 'Χώρα',
        Hint : 'Χώρα εταιρείας. πχ. Ελλάδα',
        Icon : 'map'
      },
      City: {
        Label: 'Πόλη',
        Hint : 'Πόλη Εταιρείας. πχ. Αθήνα',
        Icon : 'place',
        Error: {
          Between: 'Η Πόλη εταιρείας πρέπει να είναι από 2 έως 100 χαρακτήρες'
        }
      },
      State: {
        Label: 'Νομός',
        Hint : 'Νομός Εταιρείας. πχ. Αττικής',
        Icon : 'place',
        Error: {
          Between: 'Ο Νομός εταιρείας πρέπει να είναι από 2 έως 100 χαρακτήρες'
        }
      },
      Street: {
        Label: 'Οδός και Αριθμός',
        Hint : 'Οδός εταιρείας. πχ. Αγ. Κωνσταντίνου 40',
        Icon : 'my_location',
        Error: {
          Between: 'Η Οδός εταιρείας πρέπει να είναι από 2 έως 100 χαρακτήρες'
        }
      },
      PostCode: {
        Label: 'TK',
        Hint : 'Ταχυδρομικός κώδικας Εταιρείας. πχ. 15124',
        Icon : 'navigation',
        Error: {
          Between: 'Ο TK εταιρείας πρέπει να είναι από 2 έως 15 χαρακτήρες'
        }
      }

    },
    CreditCard: {
      Title   : 'Πιστωτικές ή χρεωστικές κάρτες',
      Subtitle: 'Οι κάρτες θα χρεωθούν είτε στο τέλος του μήνα είτε όποτε το υπόλοιπό σας υπερβεί το όριο χρήσης. Όλες οι μεγάλες πιστωτικές / χρεωστικές κάρτες γίνονται δεκτές.',
      Body    : 'Δεν υπάρχουν κάρτες',
      Button  : {
        Add: 'Καταχώρηση Κάρτας'
      }

    }
  },

  Invoice: {
    InvoiceList: {
      Toolbar: {
        Title   : 'Τιμολόγια',
        SubTitle: 'Λίστα τιμολογίων'
      }
    },
    InvoiceDetails: {
      Toolbar: {
        Title   : 'Λεπτομέρειες',
        SubTitle: 'Λεπτομέρειες τιμολογίου'
      },
      NoDataWarning: {
        Title   : 'Δεν έχει επιλεγεί τιμολόγιο',
        SubTitle: 'Επιλέξτε τιμολόγιο απο την λίστα'
      },
      Table: {
        Service : 'Υπηρεσία',
        Quantity: 'Ποσότητα',
        Price   : 'Τιμή',
        Total   : 'Σύνολο'
      }
    },
    NoDataWarning: {
      Title   : 'Δεν υπάρχουν διαθέσιμα τιμολόγια',
      SubTitle: 'Αναβαθμίστε την συνδρομή σας'
    },
    Status: {
      1: {
        Title: 'ΑΝΕΞΟΦΛΗΤΟ',
        Color: 'red'
      },
      2: {
        Title: 'ΕΞΟΦΛΗΜΕΝΟ',
        Color: 'light-green'
      },
      3: {
        Title: 'ΜΕΡΙΚΩΣ ΕΞΟΦΛΗΜΕΝΟ',
        Color: 'orange'
      },
      4: {
        Title: 'ΕΚΠΡΟΘΕΣΜΟ',
        Color: 'red'
      },
      5: {
        Title: 'ΑΚΥΡΟΜΕΝΟ',
        Color: 'grey darken-2'
      },
      6: {
        Title: 'ΠΡΟΣΧΕΔΙΟ',
        Color: 'grey darken-2'
      }
    }
  },

  Plan: {
    CurrentPlan : 'Το Πλάνο σας: {planName}',
    UpgradePlan : 'Αναβαθμίστε τη συνδρομή σας σήμερα και αυξήστε την παραγωγικότητά σας!',
    BillingCycle: {
      Fixed  : 'εφάπαξ χρέωση',
      Month  : 'μήνα',
      Monthly: 'Μηνιαία Τιμολόγηση',
      Yearly : {
        Title   : 'Ετήσια Τιμολόγηση',
        Discount: 'Κέρδος 20%'
      }
    },
    Package: {
      11: {
        Name     : 'getCONTACTLESS',
        Price    : 'ΔΩΡΕΑΝ',
        ServiceId: '12'
      },
      10: {
        Name     : 'getONLINE',
        ServiceId: '9'
      },
      30: {
        Name     : 'getPOS',
        ServiceId: '10'
      },
      60: {
        Name     : 'getSMART',
        ServiceId: '11'
      },
      1: {
        Name     : 'getEnterprise',
        ServiceId: '13'
      }
    },
    ExpireNotice: {
      Days: 'Σήμερα | {days} Ημ. | {days} Ημ.',

      Extended: {
        Title     : 'Το Πλάνο σας: {planName}',
        ExpiringIn: '<strong>Η περίοδος χάριτος {hours} ωρών λήγει σήμερα!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Η περίοδος χάριτος {hours} ωρών λήγει σε {days} ημέρα!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Η περίοδος χάριτος {hours} ωρών λήγει σε {days} ημέρες!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip'
      },
      Current: {
        Title      : 'Το Πλάνο σας: {planName}',
        ExpiringOn : 'Η συνδρομή σας λήγει στις {date}!',
        ExpiringIn : '<strong>Η συνδρομή σας λήγει σήμερα!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Η συνδρομή σας λήγει σε {days} ημέρα!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Η συνδρομή σας λήγει σε {days} ημέρες!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip',
        ExpiredOn  : '<strong>Η συνδρομή σας έληξε πριν από {days} ημέρες, στις {date}!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip',
        ExpiringTip: 'Επικοινωνήστε άμεσα μαζί μας, ώστε να αποφύγετε πιθανή διακοπή υπηρεσιών.'
      },
      Trial: {
        Title      : 'Δοκιμαστική Περίοδος Υπηρεσίας',
        ExpiringOn : 'Η δωρεάν δοκιμαστική περίοδος λήγει στις {date}!',
        ExpiringIn : '<strong>Η δωρεάν δοκιμαστική περίοδος λήγει σήμερα!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip | <strong>Η δωρεάν δοκιμαστική περίοδος λήγει σε {days} ημέρα!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip | <strong>Η δωρεάν δοκιμαστική περίοδος λήγει σε {days} ημέρες!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip',
        ExpiredOn  : '<strong>Η δωρεάν δοκιμαστική περίοδος έληξε πριν από {days} ημέρες, στις {date}!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip',
        ExpiringTip: 'Επικοινωνήστε μαζί μας, για την αναβάθμιση της συνδρομής σας.'
      },
      Contactless: {
        Title   : 'Δωρεάν ανέπαφος ψηφιακός κατάλογος προϊόντων με QR-Code',
        SubTitle: 'Αναβαθμίστε τη συνδρομή σας, συνδυάζοντας το με Online Παραγγελιοληψία προσφέροντας στους πελάτες σας έναν νέο και ασφαλή τρόπο παραγγελίας.',
        Free    : 'Δωρεάν'
      }
    }
  },

  Ticket: {
    BillingPeriod: {
      Monthly: 'μηνιαία',
      Yearly : 'ετήσια'
    },
    RenewSubscriptionRequest: {
      Subject: 'Αίτηση Ανανέωσης Συνδρομής',
      Message: 'Θα ήθελα να ανανεώσω τη συνδρομή μου που λήγει στις {SubscriptionExpiredAt} για το πλάνο {RequestPlanName} και να πληρώνω {BillingPeriod}.'
    },
    ChangeSubscriptionRequest: {
      Subject           : 'Αίτηση Αλλαγής Συνδρομής',
      Message           : 'Θα ήθελα να αλλάξω τη συνδρομή μου που λήγει στις {SubscriptionExpiredAt}, από το πλάνο {CurrentPlanName} στο πλάνο {RequestPlanName} και να πληρώνω {BillingPeriod}.',
      MessageContactless: 'Θα ήθελα να αλλάξω τη συνδρομή μου που λήγει στις {SubscriptionExpiredAt}, από το πλάνο {CurrentPlanName} στο δωρεάν πλάνο {RequestPlanName}.'
    },
    TrialUpgradeRequest: {
      Subject: 'Αίτηση Αναβάθμισης Δοκιμαστικής συνδρομής',
      Message: 'Θα ήθελα να αναβαθμίσω τη δοκιμαστική συνδρομή μου που λήγει στις {TrialExpiredAt} σε συνδρομή επί πληρωμή για το πλάνο {RequestPlanName} και να πληρώνω {BillingPeriod}.'
    },
    AccountCloseRequest: {
      Subject: 'Αίτηση Κλεισίματος Λογαριασμού',
      Message: 'Θα ήθελα να κλείσω οριστικά τον GETFOOD Pro λογαριασμό μου και να διαγραφούν όλα τα σχετικά δεδομένα. Καταλαβαίνω ότι η ενέργεια αυτή είναι μη αναστρέψιμη και ότι όλα τα δεδομένα μου θα χαθούν οριστικά.'
    }
  },

  DomainLocked: {
    Title       : 'Κλειδωμένος Λογαριασμός',
    SubTitle    : 'Επικοινωνήστε μαζί μας το συντομότερο δυνατό.',
    Subscription: 'Η συνδρομή σας έληξε πριν από {days} ημέρες, στις {date}!',
    Extend      : 'Επέκταση συνδρομής για {hours} ώρες',
    ExtendUsed  : 'Έχετε ήδη χρησιμοποιήσει την επέκταση συνδρομής {hours} ωρών!',
    Icon        : 'mdi-account-lock-outline'
  },

  Misc: {
    ProfileSaved: 'Το προφίλ αποθηκεύτηκε επιτυχώς!'
  }
}
