<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-card flat>
          <v-card-title
            v-if="viewData && viewData.length"
            ref="filtersToolbar"
            class="pa-0"
          >
            <v-toolbar
              :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
              class="filter-toolbar"
              flat
            >
              <v-btn
                :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
                :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
                class="pa-0 ma-1 elevation-0"
                @click="toggleTableSize"
              >
                <v-icon
                  color="primary"
                  v-text="small ? 'unfold_more' : 'unfold_less'"
                />
              </v-btn>

              <v-spacer />

              <filter-search
                v-model="search"
              />

              <filter-options
                v-model="filtersModel"
                :filters="filterData"
              />
            </v-toolbar>
          </v-card-title>

          <v-card-text class="pa-0">
            <div
              ref="cardContainer"
              style="overflow-y: auto;"
            >
              <template v-if="viewData && viewData.length">
                <!-- Tables Accordion -->
                <v-expansion-panels
                  v-if="tableListExpandableEnabled"
                  v-model="panel"
                  :multiple="tableListExpandableMultiple"
                  accordion
                  tile
                  flat
                  class="transparent"
                >
                  <template v-for="(area, index) in viewDataFiltered">
                    <v-expansion-panel :key="`area-${index}-${area.Id}`">
                      <v-expansion-panel-header
                        class="spx-2 py-0"
                        style="max-height: 48px;min-height: 48px;height: 48px;"
                      >
                        <v-subheader class="pl-0">
                          <div
                            :style="`background-color: #${area.Color || '000000'};`"
                            class="d-inline-block"
                            style="width:5px; height: 22px; margin-right: 6px;"
                          />

                          <span class="grey--text text--darken-2 font-weight-bold text-subtitle-1 pr-1">
                            {{ area.Name && area.Name.toLocaleUpperCase($i18n.locale) }}
                          </span>&nbsp;
                          <span class="grey--text font-weight-regular mr-2">
                            {{ area.Tables.length }} {{ $tc('Restaurant.Tables.Title', area.Tables.length) }}
                          </span>

                          <template v-if="$vuetify.breakpoint.mdAndUp">
                            <table-area-tags
                              :tags="area.Tags"
                              :icon-color="`#${area.Color || '000000'}`"
                              label
                              x-small
                            />
                          </template>
                        </v-subheader>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <template v-if="$vuetify.breakpoint.smAndDown">
                          <table-area-tags
                            :tags="area.Tags"
                            :icon-color="`#${area.Color || '000000'}`"
                            label
                            x-small
                            class="mb-2"
                          />
                        </template>

                        <v-card
                          color="transparent"
                          flat
                        >
                          <v-card-text class="pa-0">
                            <v-container
                              class="pa-0"
                              fluid
                            >
                              <v-row dense>
                                <template v-for="table in area.Tables">
                                  <v-col
                                    :key="`area-${index}-${area.Id}-table-${table.Id}`"
                                    :xl="small ? 1 : 2"
                                    :lg="small ? 2 : 3"
                                    :md="small ? 2 : 4"
                                    :sm="small ? 3 : 6"
                                    :cols="small ? 6 : 12"
                                  >
                                    <v-lazy
                                      :options="{threshold: 0.5}"
                                      transition="fade-transition"
                                    >
                                      <table-list-tile
                                        :area="area"
                                        :children-tables="getTableChildrenTables(table)"
                                        :small="small"
                                        :table="table"
                                        :toolbar="true"
                                        @click="onTableClick(table, area)"
                                        @click-persons="onPersonsClick(table, area)"
                                      />
                                    </v-lazy>
                                  </v-col>
                                </template>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>

                      <v-divider />
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>

                <!-- Tables List -->
                <v-container
                  v-else
                  class="pa-2"
                  fluid
                >
                  <v-row dense>
                    <template v-for="(area, index) in viewDataFiltered">
                      <v-col
                        v-if="area.Tables && area.Tables.length > 0"
                        :key="`area-${area.Id}`"
                        :class="{'pt-4': index > 0}"
                        cols="12"
                      >
                        <v-subheader class="pl-0">
                          <div
                            :style="`background-color: #${area.Color || '000000'};`"
                            class="d-inline-block"
                            style="width:5px; height: 22px; margin-right: 6px;"
                          />

                          <span class="grey--text text--darken-2 font-weight-bold text-subtitle-1 pr-1">
                            {{ area.Name && area.Name.toLocaleUpperCase($i18n.locale) }}
                          </span>&nbsp;

                          <span class="grey--text font-weight-regular text-no-wrap mr-2">
                            {{ area.Tables.length }} {{ $tc('Restaurant.Tables.Title', area.Tables.length) }}
                          </span>

                          <template v-if="$vuetify.breakpoint.mdAndUp">
                            <table-area-tags
                              :tags="area.Tags"
                              :icon-color="`#${area.Color || '000000'}`"
                              label
                              x-small
                            />
                          </template>
                        </v-subheader>

                        <template v-if="$vuetify.breakpoint.smAndDown">
                          <table-area-tags
                            :tags="area.Tags"
                            :icon-color="`#${area.Color || '000000'}`"
                            label
                            x-small
                          />
                        </template>
                      </v-col>

                      <template v-for="table in area.Tables">
                        <v-col
                          :key="`area-${area.Id}-table-${table.Id}`"
                          :xl="small ? 1 : 2"
                          :lg="small ? 2 : 3"
                          :md="small ? 2 : 4"
                          :sm="small ? 3 : 6"
                          :cols="small ? 6 : 12"
                        >
                          <v-lazy
                            :options="{threshold: 0.25}"
                            transition="fade-transition"
                            min-height="38"
                            max-height="175"
                            :height="small ? 38 : 175"
                          >
                            <table-list-tile
                              :area="area"
                              :children-tables="getTableChildrenTables(table)"
                              :small="small"
                              :table="table"
                              :toolbar="true"
                              @click="onTableClick(table, area)"
                              @click-persons="onPersonsClick(table, area)"
                            />
                          </v-lazy>
                        </v-col>
                      </template>
                    </template>
                  </v-row>
                </v-container>
              </template>

              <!-- No Waiter Active Shift -->
              <template v-if="userIsWaiter && viewData && viewData.error">
                <v-container
                  class="fill-height pa-2"
                  fluid
                >
                  <v-row
                    class="align-center fill-height ma-0 pa-0"
                  >
                    <v-col
                      class="text-center"
                      cols="12"
                    >
                      <div class="grey--text pa-1">
                        <v-icon
                          class="mb-2"
                          color="grey lighten-2"
                          size="128"
                        >
                          room_service
                        </v-icon>

                        <div class="text-h6">
                          {{ $t('Restaurant.Tables.NoData.Title') }}
                        </div>

                        <div
                          v-if="posUserHasComponentPermission('WaiterStaff', 'MANAGE')"
                          class="mt-4"
                        >
                          <v-btn
                            :to="{name: 'WaiterStaff'}"
                            class="elevation-0"
                            color="primary"
                          >
                            {{ $t('Restaurant.Tables.NoData.Button.ManageWaiters') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <!-- Tables Loading Anim -->
              <template v-else-if="viewData && !viewData.length && loading">
                <v-container
                  class="fill-height pa-2"
                  fluid
                >
                  <v-row
                    class="align-center fill-height ma-0 pa-0"
                  >
                    <v-col
                      class="text-center"
                      cols="12"
                    >
                      <div
                        class="grey--text pa-1"
                      >
                        <v-icon
                          class="mb-2"
                          color="grey lighten-2"
                          size="128"
                        >
                          tab_unselected
                        </v-icon>

                        <div class="text-h6">
                          {{ $t('Common.Misc.WaitProgress.Title') }}
                        </div>

                        <div class="mt-4">
                          <v-progress-circular
                            :size="48"
                            :width="3"
                            color="green"
                            indeterminate
                          />
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <!-- No Tables Found -->
              <template v-else-if="viewData && !viewData.length">
                <v-container
                  class="fill-height pa-2"
                  fluid
                >
                  <v-row
                    class="align-center fill-height ma-0 pa-0"
                  >
                    <v-col
                      class="text-center"
                      cols="12"
                    >
                      <div class="grey--text pa-1">
                        <v-icon
                          class="mb-2"
                          color="grey lighten-2"
                          size="128"
                        >
                          tab_unselected
                        </v-icon>

                        <div class="text-h6">
                          {{ $t('Restaurant.Tables.NoTables.Title') }}
                        </div>

                        <div
                          v-if="posUserHasComponentPermission('TableManagement', 'MANAGE')"
                          class="mt-4"
                        >
                          <v-btn
                            :to="{name: 'TableManagement'}"
                            class="elevation-0"
                            color="primary"
                          >
                            {{ $t('Restaurant.Tables.NoTables.Button.ManageWaiters') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </div>
          </v-card-text>
        </v-card>

        <template v-if="viewData && viewData.length">
          <table-options-menu-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tableOptionsMenuDialogVisible"
          />

          <table-payment-menu-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tablePaymentMenuDialogVisible"
          />

          <table-merge-split-dialog
            :area="selectedArea"
            :mode="tableMode"
            :table="selectedTable"
            :visible.sync="tableMergeSplitDialogVisible"
            @save-success="onTableMergeSplitSuccess"
          />

          <table-view-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tableViewDialogVisible"
            @payment-success="getData"
          />

          <table-edit-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tableEditDialogVisible"
            @save-success="getData"
          />

          <table-order-items-move-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tableOrderItemsMoveDialogVisible"
            @save-success="onOrderItemsMoveSuccess"
          />

          <table-change-waiter-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tableChangeWaiterDialogVisible"
            @save-success="onTableChangeWaiterSuccess"
          />

          <table-payment-select-order-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tablePaymentSelectOrderDialogVisible"
            @payment-success="onPaymentTableSuccess"
          />

          <table-payment-select-menu-item-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tablePaymentSelectMenuItemDialogVisible"
            @payment-success="onPaymentTableSuccess"
          />

          <table-payment-preview-order-dialog
            :area="selectedArea"
            :table="selectedTable"
            :visible.sync="tablePaymentPreviewOrderDialogVisible"
            @payment-success="onPaymentTableSuccess"
          />

          <select-table-dialog
            :exclude="[selectedTable]"
            :tables="viewData"
            :visible.sync="selectTableDialogVisible"
          />

          <select-waiter-staff-dialog
            :visible.sync="selectWaiterDialogVisible"
            @on:waiter-staff-selected="onSelectWaiter"
            @waiter-staff-selected="onSelectWaiter"
          />

          <set-table-persons-dialog
            :area="selectedArea"
            :table="selectedTable"
            :merged-min-capacity="getTableTotalMinCapacity(selectedTable)"
            :merged-max-capacity="getTableTotalMaxCapacity(selectedTable)"
            :visible.sync="setTablePersonsDialogVisible"
            @on:persons-set="onSetTablePersons"
          />

          <confirm-dialog
            :html-content="confirmCloseTableSwiftDialog.Body"
            :html-title="confirmCloseTableSwiftDialog.Title"
            :info-bar="true"
            :info-bar-body="confirmCloseTableSwiftDialog.Info"
            :visible.sync="confirmCloseTableSwiftDialogVisible"
            :width="560"
            title-class="red white--text"
            cancel-button
            :cancel-button-text="$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrderPartialPaid.Button.Cancel')"
            no-button-class="green white--text"
            :no-button-text="$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrderPartialPaid.Button.No')"
            yes-button-class="red white--text"
            :yes-button-text="$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrderPartialPaid.Button.Yes')"
            @confirm-dialog-no="onConfirmNoCloseAndDiscardSwift"
            @confirm-dialog-yes="onConfirmYesCloseAndDiscardSwift"
            @confirm-dialog-cancel="onConfirmCancel"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppData                          from '@/mixins/appdata'
import Auth                             from '@/mixins/auth'
import TableOptionsMenuDialog           from '@/components/restaurant/tables/tables/dialogs/menu/TableOptionsMenuDialog'
import TableListTile                    from '@/components/restaurant/tables/tables/TableListTile'
import TableMergeSplitDialog            from '@/components/restaurant/tables/tables/dialogs/TableMergeSplitDialog'
import TableStatus                      from '@/mixins/tables/tableStatus'
import TableCommon                      from '@/mixins/tables/tableCommon'
import Resizable                        from '@/mixins/resizable'
import TableMergeSplitModeEnum          from '@/api/Enums/TableMergeSplitModeEnum'
import TableViewDialog                  from '@/components/restaurant/tables/tables/dialogs/TableViewDialog'
import TableOrderItemsMoveDialog        from '@/components/restaurant/tables/tables/dialogs/TableOrderItemsMoveDialog'
import SelectTableDialog                from '@/components/restaurant/tables/tables/dialogs/SelectTableDialog'
import ConfirmDialog                    from '@/components/common/ConfirmDialog'
import greekUtils                       from '@/lib/greek-utils'
import TablePaymentMenuDialog           from '@/components/restaurant/tables/tables/dialogs/menu/TablePaymentMenuDialog'
import TablePaymentSelectOrderDialog    from '@/components/restaurant/tables/payments/dialogs/TablePaymentSelectOrderDialog'
import TablePaymentSelectMenuItemDialog from '@/components/restaurant/tables/payments/dialogs/TablePaymentSelectMenuItemDialog'
import TablePaymentPreviewOrderDialog   from '@/components/restaurant/tables/payments/dialogs/TablePaymentPreviewOrderDialog'
import TableEditDialog                  from '@/components/restaurant/tables/tables/dialogs/TableEditDialog'
import SetTablePersonsDialog            from '@/components/restaurant/tables/tables/dialogs/SetTablePersonsDialog.vue'
import TableChangeWaiterDialog          from '@/components/restaurant/tables/tables/dialogs/TableChangeWaiterDialog.vue'
import FilterSearch                     from '@/components/common/filter/FilterSearch.vue'
import FilterOptions                    from '@/components/common/filter/FilterOptions.vue'
import FilterCommon                     from '@/mixins/filter/filterCommon'
import SelectWaiterStaffDialog          from '@/components/waiters/SelectWaiterStaffDialog.vue'
import TableActions                     from '@/mixins/tables/tableActions'
import TableStatusEnum                  from '@/api/Enums/TableStatusEnum'
import TableAreaTags                    from '@/components/restaurant/tables/common/TableAreaTags.vue'
import { clone }                        from '@/lib/helper/helper'
import PinManagerEnum                   from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'Tables',
  components: {
    TableAreaTags,
    SelectWaiterStaffDialog,
    FilterOptions,
    FilterSearch,
    TableChangeWaiterDialog,
    SetTablePersonsDialog,
    TableEditDialog,
    TablePaymentSelectMenuItemDialog,
    TablePaymentPreviewOrderDialog,
    TablePaymentSelectOrderDialog,
    TablePaymentMenuDialog,
    ConfirmDialog,
    SelectTableDialog,
    TableOrderItemsMoveDialog,
    TableViewDialog,
    TableMergeSplitDialog,
    TableListTile,
    TableOptionsMenuDialog
  },
  directives: {},
  mixins    : [AppData, Auth, TableStatus, TableCommon, TableActions, Resizable, FilterCommon],
  props     : {},
  DataStore : { tables: 'tables' },
  data () {
    return {
      filtersModel: {
        lang           : 'Restaurant.Tables',
        filterTableArea: {
          value: [],
          items: []
        },
        filterTableStatus: {
          value: [],
          items: []
        },
        filterWaiterStaff: {
          value: null,
          items: []
        }
      },
      search                                 : '',
      panel                                  : 0,
      viewData                               : null,
      small                                  : false,
      loading                                : false,
      selectedWaiter                         : null,
      selectedTable                          : null,
      selectedArea                           : null,
      tableAreaCombo                         : [],
      tableOptionsMenuDialogVisible          : false,
      tablePaymentMenuDialogVisible          : false,
      tablePaymentSelectOrderDialogVisible   : false,
      tablePaymentSelectMenuItemDialogVisible: false,
      tablePaymentPreviewOrderDialogVisible  : false,
      tableMergeSplitDialogVisible           : false,
      tableViewDialogVisible                 : false,
      tableEditDialogVisible                 : false,
      tableOrderItemsMoveDialogVisible       : false,
      tableChangeWaiterDialogVisible         : false,
      setTablePersonsDialogVisible           : false,
      selectTableDialogVisible               : false,
      selectWaiterDialogVisible              : false,
      confirmCloseTableSwiftDialogVisible    : false,
      confirmCloseTableSwiftDialog           : {
        Title: '',
        Body : '',
        Info : ''
      },
      tableMode: TableMergeSplitModeEnum.Merge
    }
  },
  computed: {
    tableListExpandable () {
      return this.appConfig?.LOCATION_DATA?.TableListExpandable || {
        Enabled        : true,
        Desktop        : false,
        MultipleDesktop: false,
        Mobile         : true,
        MultipleMobile : false
      }
    },

    tableListExpandableEnabled () {
      if (!this.tableListExpandable.Enabled) return false

      if (this.tableListExpandable.Mobile && this.tableListExpandable.Desktop) return true
      if (this.$vuetify.breakpoint.xsOnly && this.tableListExpandable.Mobile) return true
      if (this.$vuetify.breakpoint.smAndUp && this.tableListExpandable.Desktop) return true

      return false
    },

    tableListExpandableMultiple () {
      if (this.tableListExpandable.Mobile && this.tableListExpandable.MultipleMobile &&
        this.tableListExpandable.Desktop && this.tableListExpandable.MultipleDesktop) {
        return true
      }

      if (this.$vuetify.breakpoint.xsOnly && this.tableListExpandable.Mobile && this.tableListExpandable.MultipleMobile) return true
      if (this.$vuetify.breakpoint.smAndUp && this.tableListExpandable.Desktop && this.tableListExpandable.MultipleDesktop) return true

      return false
    },

    tableAreaComboFiltered () {
      return this.tableAreaCombo.filter(area => area.Active).map(o => {
        o.Avatar = { Color: `#${ o.Color }` }
        return o
      })
    },

    tableStatusComboFiltered () {
      return Object.values(this.$t('Restaurant.Tables.TableStatus')).filter(status => status.Active).map(o => {
        o.Avatar = { Color: o.Color }
        return o
      })
    },

    waiterStaffFiltered () {
      const staff = window.Vue.waiterStaffItems?.filter(waiter => waiter.Status && waiter.HasActiveShift) || []
      return staff.map(o => {
        o.Name = `${ o.Firstname } ${ o.Lastname }`
        o.Avatar = {
          Color: o.Color ? `#${ o.Color }` : 'grey darken-1',
          Icon : 'account_circle'
        }
        return o
      })
    },

    viewDataFiltered () {
      const filterArea = this.filtersModel.filterTableArea.value || []
      const filterStatus = this.filtersModel.filterTableStatus.value || []
      const filterWaiter = this.filtersModel.filterWaiterStaff.value || ''
      const filterSearch = this.search ? greekUtils.toGreeklish(this.search.toLocaleLowerCase(this.$i18n.locale).trim()) : ''

      let data = JSON.parse(JSON.stringify(this.viewData || []))
      data = data.filter(area => filterArea.length ? filterArea.includes(area.Id) : true)

      data.forEach(area => {
        area.Tables = area.Tables
          .filter(table => !table.Parent)
          .filter(table => filterStatus.length ? filterStatus.includes(parseInt(table.StatusId)) : true)
          .filter(table => filterSearch ? greekUtils.toGreeklish(table.Name.toLocaleLowerCase(this.$i18n.locale).trim()).includes(filterSearch) : true)
          .filter(table => filterWaiter ? !table?.StaffId || table?.StaffId === parseInt(filterWaiter) : true)
      })

      return data
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.small = this.$localStorage.get('tbl', false)

    this.viewData = this.tables

    // Init Custom Filter Values
    this.filtersModel.filterTableArea.items = this.tableAreaComboFiltered
    this.filtersModel.filterTableStatus.items = this.tableStatusComboFiltered
    this.filtersModel.filterWaiterStaff.items = this.waiterStaffFiltered
  },
  beforeMount () {},
  mounted () {
    this.$bus.$on(window.SocketCommand.Table.AllByArea, this.setData)
    this.$bus.$on(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
    this.$bus.$on(window.SocketCommand.Table.Close, this.onCloseTableResponse)
    this.$bus.$on(window.SocketCommand.Table.Open, this.onOpenTableResponse)
    this.$bus.$on(window.SocketCommand.Staff.Waiter.Shift.Changed, this.onWaiterStaffShiftChanged)
    this.$bus.$on(window.SocketCommand.Table.SetAwaitingPaymentStatus, this.onPaymentTableSuccess)
    this.$bus.$on(window.SocketCommand.Table.UnsetAwaitingPaymentStatus, this.onPaymentTableSuccess)
    this.$bus.$on(window.SocketCommand.Table.ChangePersons, this.onSetTablePersonsResponse)
    this.$bus.$on('click:table-new-order-button', this.onNewOrderButtonClick)
    this.$bus.$on('click:table-merge-button', this.onTableMergeButtonClick)
    this.$bus.$on('click:table-split-button', this.onTableSplitButtonClick)
    this.$bus.$on('click:table-view-button', this.onTableViewButtonClick)
    this.$bus.$on('click:table-edit-button', this.onTableEditButtonClick)
    this.$bus.$on('click:table-order-items-move-button', this.onTableMoveButtonClick)
    this.$bus.$on('click:table-change-waiter-button', this.onTableChangeWaiterButtonClick)
    this.$bus.$on('click:table-select-button', this.onSelectTableButtonClick)
    this.$bus.$on('click:table-close-button', this.onCloseTableButtonClick)
    this.$bus.$on('click:table-open-button', this.onOpenTableButtonClick)
    this.$bus.$on('click:table-reservation-button', this.onReserveTableButtonClick)
    this.$bus.$on('click:table-pay-button', this.onPayTableButtonClick)
    this.$bus.$on('click:payment-pay-table-button', this.onPaymentTableButtonClick)
    this.$bus.$on('click:payment-pay-table-order-button', this.onPaymentTableOrderButtonClick)
    this.$bus.$on('click:payment-pay-table-menu-item-button', this.onPaymentTableMenuItemButtonClick)
    this.$bus.$on('click:set-awaiting-payment-table-status-button', this.onSetAwaitingPaymentStatusButtonClick)
    this.$bus.$on('click:unset-awaiting-payment-table-status-button', this.onUnsetAwaitingPaymentStatusButtonClick)

    this.getData()
    this.getTableAreaCombo()
    this.initWaiterFilters()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Table.AllByArea, this.setData)
    this.$bus.$off(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
    this.$bus.$off(window.SocketCommand.Table.Close, this.onCloseTableResponse)
    this.$bus.$off(window.SocketCommand.Table.Open, this.onOpenTableResponse)
    this.$bus.$off(window.SocketCommand.Staff.Waiter.Shift.Changed, this.onWaiterStaffShiftChanged)
    this.$bus.$off(window.SocketCommand.Table.SetAwaitingPaymentStatus, this.onPaymentTableSuccess)
    this.$bus.$off(window.SocketCommand.Table.UnsetAwaitingPaymentStatus, this.onPaymentTableSuccess)
    this.$bus.$off(window.SocketCommand.Table.ChangePersons, this.onSetTablePersonsResponse)
    this.$bus.$off('click:table-new-order-button', this.onNewOrderButtonClick)
    this.$bus.$off('click:table-merge-button', this.onTableMergeButtonClick)
    this.$bus.$off('click:table-split-button', this.onTableSplitButtonClick)
    this.$bus.$off('click:table-view-button', this.onTableViewButtonClick)
    this.$bus.$off('click:table-edit-button', this.onTableEditButtonClick)
    this.$bus.$off('click:table-order-items-move-button', this.onTableMoveButtonClick)
    this.$bus.$off('click:table-change-waiter-button', this.onTableChangeWaiterButtonClick)
    this.$bus.$off('click:table-select-button', this.onSelectTableButtonClick)
    this.$bus.$off('click:table-close-button', this.onCloseTableButtonClick)
    this.$bus.$off('click:table-open-button', this.onOpenTableButtonClick)
    this.$bus.$off('click:table-reservation-button', this.onReserveTableButtonClick)
    this.$bus.$off('click:table-pay-button', this.onPayTableButtonClick)
    this.$bus.$off('click:payment-pay-table-button', this.onPaymentTableButtonClick)
    this.$bus.$off('click:payment-pay-table-order-button', this.onPaymentTableOrderButtonClick)
    this.$bus.$off('click:payment-pay-table-menu-item-button', this.onPaymentTableMenuItemButtonClick)
    this.$bus.$off('click:set-awaiting-payment-table-status-button', this.onSetAwaitingPaymentStatusButtonClick)
    this.$bus.$off('click:unset-awaiting-payment-table-status-button', this.onUnsetAwaitingPaymentStatusButtonClick)
  },
  destroyed () {
  },
  methods: {
    toggleTableSize () {
      this.small = !this.small
      this.$localStorage.set('tbl', this.small)
    },

    initWaiterFilters () {
      if (!this.tables.length) return

      const waiter = this.waiterStaffFiltered?.find(waiter => parseInt(waiter.Id) === parseInt(this.posUser.staff_id))
      const currentUserHasActiveWaiterShift = !!waiter
      if (currentUserHasActiveWaiterShift && !this.appConfig?.LOCATION_DATA?.WaiterCanManageAllTables) {
        this.filtersModel.filterWaiterStaff.value = String(this.posUser.staff_id)
      }

      if (currentUserHasActiveWaiterShift) {
        const waiterTableAreas = waiter?.TableAreas || []
        if (waiterTableAreas.length > 0 && waiterTableAreas[0] > 0) {
          this.filtersModel.filterTableArea.value = waiterTableAreas
        }
      }
    },

    getData () {
      this.loading = true
      window.callAS(window.SocketCommand.Table.AllByArea, {})
    },
    setData (data) {
      if (!this.tables.length) {
        setTimeout(() => {
          this.initWaiterFilters()
        }, 100)
      }

      this.viewData = this.tables = data || []
      this.loading = false
      this.updateSelectedTable()
    },

    updateSelectedTable () {
      if (this.selectedArea) {
        const area = this.viewData?.find(area => area.Id === this.selectedArea)
        if (area) this.selectedArea = clone(area)
      }

      if (this.selectedTable) {
        this.viewData.forEach(area => {
          const table = area?.Tables?.find(tbl => tbl.Id === this.selectedTable.Id)
          if (table) {
            this.selectedTable = clone(table)
          }
        })
      }
    },

    getTableAreaCombo () {
      window.callAS(window.SocketCommand.Table.Area.List)
    },
    setTableAreaCombo (data) {
      this.tableAreaCombo = data || []
      this.filtersModel.filterTableArea.items = this.tableAreaComboFiltered
    },

    onTableClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area
      this.tableOptionsMenuDialogVisible = true
    },

    onPersonsClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area
      this.setTablePersonsDialogVisible = true
    },

    onNewOrderButtonClick (table, area) {
      if (!table || !area) return
      this.$router.push({
        name  : 'Pos',
        params: {
          tableId: table.Id,
          table  : table,
          area   : area
        }
      })
    },

    onTableMergeButtonClick (table, area) {
      if (!table || !area) return
      const data = JSON.parse(JSON.stringify(this.viewData))
      const selectedArea = data.find(originalArea => originalArea.Id === area.Id)
      this.selectedTable = clone(table)
      this.selectedArea = selectedArea
      this.tableMode = TableMergeSplitModeEnum.Merge
      this.tableMergeSplitDialogVisible = true
    },
    onTableSplitButtonClick (table, area) {
      if (!table || !area) return
      const data = JSON.parse(JSON.stringify(this.viewData))
      const selectedArea = data.find(originalArea => originalArea.Id === area.Id)
      this.selectedTable = clone(table)
      this.selectedArea = selectedArea
      this.tableMode = TableMergeSplitModeEnum.Split
      this.tableMergeSplitDialogVisible = true
    },
    onTableMergeSplitSuccess () {
      this.tableOptionsMenuDialogVisible = false
      this.tableMergeSplitDialogVisible = false
      this.getData()
    },

    onTableViewButtonClick (table, area) {
      if (!table || !area) return
      const data = JSON.parse(JSON.stringify(this.viewData))
      const selectedArea = data.find(originalArea => originalArea.Id === area.Id)
      this.selectedTable = clone(table)
      this.selectedArea = selectedArea
      this.tableViewDialogVisible = true
    },

    onTableEditButtonClick (table, area) {
      if (!table || !area) return
      const data = JSON.parse(JSON.stringify(this.viewData))
      const selectedArea = data.find(originalArea => originalArea.Id === area.Id)
      this.selectedTable = clone(table)
      this.selectedArea = selectedArea
      this.tableEditDialogVisible = true
    },

    onTableMoveButtonClick (table, area) {
      if (!table || !area) return
      const data = JSON.parse(JSON.stringify(this.viewData))
      const selectedArea = data.find(originalArea => originalArea.Id === area.Id)
      this.selectedTable = clone(table)
      this.selectedTable.ChildrenTables = this.getTableChildrenTables(table)
      this.selectedArea = selectedArea
      this.tableOrderItemsMoveDialogVisible = true
    },
    onOrderItemsMoveSuccess () {
      this.tableOptionsMenuDialogVisible = false
      this.tableOrderItemsMoveDialogVisible = false
      this.getData()
    },

    onTableChangeWaiterButtonClick (table, area) {
      if (!table || !area) return
      const data = JSON.parse(JSON.stringify(this.viewData))
      const selectedArea = data.find(originalArea => originalArea.Id === area.Id)
      this.selectedTable = clone(table)
      this.selectedTable.ChildrenTables = this.getTableChildrenTables(table)
      this.selectedArea = selectedArea

      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.ChangeTableWaiter) && this.tableIsOwn(table), () => {
        this.tableChangeWaiterDialogVisible = true
      }, 'change-table-waiter')
    },
    onTableChangeWaiterSuccess () {
      this.tableOptionsMenuDialogVisible = false
      this.tableChangeWaiterDialogVisible = false
      this.getData()
    },

    onSelectTableButtonClick (table) {
      if (!table) return
      this.selectedTable = clone(table)
      this.selectedTable.ChildrenTables = this.getTableChildrenTables(table)
      this.selectTableDialogVisible = true
    },

    onCloseTableButtonClick (table, area) {
      if (!table || !area) return
      this.selectedTable = table
      this.selectedArea = clone(area)

      if (this.tableHasUnPaidItems(table)) {
        if (this.tableHasPaidItems(table)) {
          this.confirmCloseTableSwiftDialog = {
            Title: this.$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrderPartialPaid.Title'),
            Body : this.$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrderPartialPaid.Content'),
            Info : this.$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrderPartialPaid.Notification')
          }
        } else {
          this.confirmCloseTableSwiftDialog = {
            Title: this.$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrder.Title'),
            Body : this.$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrder.Content'),
            Info : this.$t('Restaurant.Tables.Dialog.ConfirmCloseSwiftAndDiscardOrder.Notification')
          }
        }
        this.confirmCloseTableSwiftDialogVisible = true
      } else {
        this.onConfirmYesCloseAndDiscardSwift()
      }
    },

    onConfirmYesCloseAndDiscardSwift () {
      window.callAS(window.SocketCommand.Table.Close, {
        TableId: this.selectedTable.Id,
        CartId : this.selectedTable?.CartId || null
      })
    },

    onConfirmCancel () {
      this.selectedTable.DisableActions = false
    },

    onConfirmNoCloseAndDiscardSwift () {
      this.selectedTable.DisableActions = false
      this.$emitAction('click:table-pay-button')
    },

    onCloseTableResponse (data) {
      if (data.status === 'success') {
        this.tableOptionsMenuDialogVisible = false
        this.getData()
      } else {
        this.selectedTable.DisableActions = false
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onSelectWaiter (waiter) {
      if (!waiter) return

      this.selectedWaiter = waiter

      const openTableWithDefaultPersons = this.appConfig?.LOCATION_DATA?.OpenTableWithDefaultPersons || false
      if (openTableWithDefaultPersons) {
        this.openTable(this.selectedTable, this.selectedArea, null, waiter)
      } else {
        this.setTablePersonsDialogVisible = true
      }
    },

    onReserveTableButtonClick (table, area) {
      if (!table) return

      this.selectedTable = clone(table)
      this.selectedArea = clone(area)

      this.$bus.$emit('show-table-reservation-dialog', {
        Table: this.selectedTable,
        Area : this.selectedArea
      })
    },

    onOpenTableButtonClick (table, area) {
      if (!table) return

      const openTableWithDefaultPersons = this.appConfig?.LOCATION_DATA?.OpenTableWithDefaultPersons || false
      this.selectedTable = clone(table)
      this.selectedArea = clone(area)

      if (this.userIsWaiter) {
        if (openTableWithDefaultPersons) {
          this.openTable(this.selectedTable, this.selectedArea)
        } else {
          this.setTablePersonsDialogVisible = true
        }
      } else {
        this.selectWaiterDialogVisible = true
      }
    },

    onSetTablePersons (table, area, persons) {
      if (!table) return

      if (table.StatusId === TableStatusEnum.Available) {
        this.onOpenTableWithPersons(table, area, persons)
      } else {
        const payload = {
          TableId: table.Id,
          ShiftId: table.ShiftId,
          Persons: persons
        }
        window.callAS(window.SocketCommand.Table.ChangePersons, payload)
      }
    },
    onSetTablePersonsResponse (data) {
      if (data.status === 'success') {
        this.getData()
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onOpenTableWithPersons (table, area, persons) {
      if (!table) return

      if (this.selectedWaiter) {
        this.openTable(this.selectedTable, this.selectedArea, persons, this.selectedWaiter)
      } else {
        this.openTable(table, area, persons)
      }
    },

    openTable (table, area, persons, waiter) {
      if (!table) return

      const openTableWithDefaultPersons = this.appConfig?.LOCATION_DATA?.OpenTableWithDefaultPersons || false
      const minCapacity = openTableWithDefaultPersons ? table.MinCapacity || 1 : 1
      const maxCapacity = openTableWithDefaultPersons ? table.MaxCapacity || 1 : 10000

      persons = parseInt(persons) || 0

      if (persons < minCapacity) persons = minCapacity
      if (persons > maxCapacity) persons = maxCapacity

      const data = {
        TableId: table.Id,
        Persons: persons,
        StaffId: waiter?.Id || null
      }
      window.callAS(window.SocketCommand.Table.Open, data)

      this.selectedWaiter = null
    },
    onOpenTableResponse (data) {
      if (data.status === 'success') {
        this.tableOptionsMenuDialogVisible = false
        this.getData()
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onWaiterStaffShiftChanged (data) {
      if (parseInt(this.posUser.staff_id) === parseInt(data.id)) {
        this.getData()

        if (data.open && !this.appConfig?.LOCATION_DATA?.WaiterCanManageAllTables) {
          this.filtersModel.filterWaiterStaff.value = String(this.posUser.staff_id)
        } else {
          this.filtersModel.filterWaiterStaff.value = null
        }
      }
    },

    onPayTableButtonClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area
      this.tablePaymentMenuDialogVisible = true
      this.tableOptionsMenuDialogVisible = false
    },

    onPaymentTableButtonClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area
      this.tablePaymentPreviewOrderDialogVisible = true
    },

    onPaymentTableOrderButtonClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area
      this.tablePaymentSelectOrderDialogVisible = true
    },

    onPaymentTableMenuItemButtonClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area
      this.tablePaymentSelectMenuItemDialogVisible = true
    },

    onSetAwaitingPaymentStatusButtonClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area

      window.callAS(window.SocketCommand.Table.SetAwaitingPaymentStatus, { TableId: this.selectedTable.Id })
    },

    onUnsetAwaitingPaymentStatusButtonClick (table, area) {
      if (!table || !area) return
      this.selectedTable = clone(table)
      this.selectedArea = area

      window.callAS(window.SocketCommand.Table.UnsetAwaitingPaymentStatus, {
        CartId : this.selectedTable.CartId,
        TableId: this.selectedTable.Id
      })
    },

    onPaymentTableSuccess () {
      this.tablePaymentMenuDialogVisible = false
      this.tablePaymentSelectOrderDialogVisible = false
      this.getData()
    },

    onResize () {
      const container = this.$refs.cardContainer || null
      const filtersToolbar = this.$refs.filtersToolbar || null

      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const toolbarHeight = filtersToolbar?.offsetHeight || 48

      if (container) container.style.height = (innerHeight - headerHeight - footerHeight - toolbarHeight) + 'px'
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-expansion-panel-content__wrap {
  padding : 4px 8px;
}

/deep/ .v-expansion-panel__header {
  padding    : 8px 8px;
  min-height : 48px;
  height     : 48px;
}
</style>
