import BaseModel                from '@/lib/data/Models/BaseModel'
import SocketCommand            from '@/api/SocketCommand'
import PaymentTerminalValidator from '@/api/Validations/settings/PaymentTerminalValidator'

export default class PaymentTerminalModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = rawData.Id || null
    this.PosVendorId = rawData.PosVendorId || null

    this.TerminalId = rawData.TerminalId || null
    this.TId = rawData.TId || null
    this.VirtualTerminalId = rawData.VirtualTerminalId || null
    this.SerialNumber = rawData.SerialNumber || null

    this.Nickname = rawData.Nickname || ''

    this.Type = rawData.Type || 'cloud'
    this.Protocol = rawData.Protocol || 'https'
    this.IpAddress = rawData.IpAddress || ''
    this.Port = rawData.Port || '9564'

    this.Model = rawData.Model || ''
    this.Manufactor = rawData.Manufactor || ''
    this.Connection = rawData.Connection || ''
    this.OperationSystem = rawData.OperationSystem || ''

    this.validator = new PaymentTerminalValidator(this)
  }

  boot () {
    super.boot()

    this.guarded = []
    this.saveCommand = SocketCommand.Settings.Payments.Terminal.Save
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */
}
