<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ $t('Pos.SelectTipAmount.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Pos.SelectTipAmount.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Pos.SelectTipAmount.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <v-col
                v-for="(item, index) in $t('Pos.SelectTipAmount.Amounts')"
                :key="index"
                cols="3"
              >
                <v-list
                  class="pb-0 pt-0"
                  two-line
                >
                  <v-list-item
                    :class="[isDarkMode ? 'green darken-4' : 'green lighten-5', {'pa-1': !$vuetify.breakpoint.xsOnly},'rounded']"
                    class="px-1"
                    ripple
                    @click="setTip(item)"
                  >
                    <v-list-item-avatar v-if="!$vuetify.breakpoint.xsOnly">
                      <v-icon
                        class="green--text"
                        size="36"
                      >
                        {{ $t('Pos.SelectTipAmount.Toolbar.Icon') }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content :class="[isDarkMode ? 'grey--text text--lighten-2' : 'grey--text text--darken-2']">
                      <v-list-item-title :class="[$vuetify.breakpoint.xsOnly ? 'text-center' : 'text-left']">
                        +{{ $options.filters.currency(item.Amount) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </v-list>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                  v-model.number="tip.Amount"
                  :error="tip.Error"
                  :hint="$t('Pos.SelectTipAmount.Field.TipAmount.Hint')"
                  :label="$t('Pos.SelectTipAmount.Field.TipAmount.Label')"
                  :suffix="appCurrency.Symbol"
                  clearable
                  hide-details
                  solo
                  type="number"
                  min="0"
                  step="any"
                />
              </v-col>

              <v-col class="shrink">
                <v-btn
                  class="green mt-2"
                  dark
                  icon
                  @click="setTip(tip)"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData from '../../mixins/appdata'

export default {
  name      : 'SelectTipDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      isTipSelected: false,
      tip          : {
        Amount: '',
        Error : false
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.tip = {
          Amount: '',
          Error : false
        }
      } else {
        this.isTipSelected = false
      }
    }
  },
  methods: {
    setTip (tip) {
      if (this.isTipSelected) return
      tip.Error = false

      if (tip.Amount === '' || isNaN(tip.Amount) || tip.Amount <= 0 || tip.Amount > 10000) {
        tip.Error = true
        return
      }

      this.isTipSelected = true

      tip.Discount = parseFloat(tip.Amount)
      this.$emit('cart:tip-selected', tip)
      this.isVisible = false
    },
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile--link {
  padding : 0 !important;
}
</style>
