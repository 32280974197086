<template>
  <div ref="colorpicker">
    <v-text-field
      v-model="colorValue"
      :disabled="disabled"
      :hide-details="false"
      :rules="[(v) => v && v.length === 7 && v.match(/^#[a-f0-9]{6}$/i) !== null || $t('Common.ColorPicker.Error.InvalidColor')]"
      style="min-width: 160px"
      max-length="7"
      min-length="7"
      outlined
      required
      single-line
      size="7"
      @focus="togglePicker()"
    >
      <template
        v-if="label"
        #prepend
      >
        <h5 class="pt-2 grey--text text--darken-1 text-no-wrap">
          {{ label }}
        </h5>
      </template>

      <template #prepend-inner>
        <span class="input-group-addon color-picker-container">
          <span
            :style="'background-color: ' + colorValue"
            class="current-color"
          />

          <chrome-picker
            v-if="displayPicker"
            :disable-alpha="true"
            :disable-fields="true"
            :value="colors"
            @input="updateFromPicker"
          />
        </span>
      </template>

      <template #append>
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import Auth       from '../../../mixins/auth'

export default {
  name      : 'ColorPicker',
  components: {
    'chrome-picker': Chrome
  },
  directives: {},
  mixins    : [Auth],
  props     : {
    disabled: {
      type   : Boolean,
      default: false
    },
    color: {
      type   : String,
      default: '#FF0000CC'
    },
    label: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {
      colors: {
        hex: '#000000'
      },
      colorValue   : '',
      displayPicker: false
    }
  },
  computed: {},
  watch   : {
    colorValue (val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.setColor(this.color || '#000000')
  },
  beforeUpdate () {},
  updated () {},
  activated () {},
  deactivated () {},
  beforeDestroy () {
    document.removeEventListener('click', this.documentClick)
  },
  destroyed () {},
  methods: {
    setColor (color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors (color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        }
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        const hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)
        this.colors = {
          hex: hex,
          a  : rgba[3]
        }
      }
    },
    showPicker () {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker () {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker () {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    updateFromInput () {
      this.updateColors(this.colorValue)
    },
    updateFromPicker (color) {
      this.colors = color
      if (parseInt(color.rgba.a) === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
      }
    },
    documentClick (e) {
      const el = this.$refs.colorpicker
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  }
}
</script>

<style scoped>
.vc-chrome {
  position   : absolute;
  top        : 55px;
  left       : 0;
  z-index    : 9;
  max-height : 250px;
}

/deep/ .current-color {
  position         : relative;
  top              : -2px;
  display          : inline-block;
  width            : 24px;
  height           : 24px;
  background-color : #000;
  cursor           : pointer;
  border           : solid 1px #424242;
}
</style>
