<template>
  <v-container
    fluid
  >
    <v-row dense>
      <current-plan-warning ref="currentPlan" />

      <v-col cols="12">
        <v-card v-if="viewData">
          <v-card-text :style="`min-height: ${mainCardHeight}px`">
            <v-container
              class="pa-1"
              fluid
            >
              <v-row
                dense
                :class="!viewData.length ? 'align-center justify-center text-center' : ''"
                class="fill-height"
              >
                <template v-if="viewData.length">
                  <v-col
                    lg="4"
                    md="5"
                    cols="12"
                  >
                    <v-card flat>
                      <v-toolbar
                        flat
                        class="pl-0"
                        height="70"
                      >
                        <v-avatar class="info">
                          <v-icon dark>
                            receipt
                          </v-icon>
                        </v-avatar>

                        <v-toolbar-title class="pl-3">
                          <div class="body-3">
                            {{ $t('Account.Invoice.InvoiceList.Toolbar.Title') }}
                          </div>

                          <div class="text-caption">
                            {{ $t('Account.Invoice.InvoiceList.Toolbar.SubTitle') }}
                          </div>
                        </v-toolbar-title>

                        <v-spacer />
                      </v-toolbar>

                      <v-divider />

                      <v-card-text
                        :style="internalCardHeight ? `height: ${internalCardHeight}px` : ''"
                        class="pa-0"
                        style="overflow: auto;"
                      >
                        <v-list two-line>
                          <template v-for="invoice in viewData">
                            <v-list-item
                              :key="`invoice-${invoice.InvoiceId}`"
                              ripple
                              @click="toggleInvoice(invoice)"
                            >
                              <div
                                class="text-caption pr-2"
                                style="display: grid !important;"
                              >
                                <v-chip
                                  class="white--text font-weight-bold align-center justify-center mb-1"
                                  color="light-blue"
                                  label
                                  small
                                  style="min-width: 100px;"
                                >
                                  {{ invoice.Date }}
                                </v-chip>

                                <v-chip
                                  :color="$t(`Account.Invoice.Status.${ invoice.Status }.Color`)"
                                  class="white--text font-weight-bold align-center justify-center"
                                  label
                                  small
                                  style="min-width: 100px;"
                                >
                                  {{ $t(`Account.Invoice.Status.${ invoice.Status }.Title`).toLocaleUpperCase($i18n.locale) }}
                                </v-chip>
                              </div>

                              <v-list-item-content class="grey--text text--darken-1">
                                <v-list-item-title class="">
                                  {{ invoice.InvoiceNumber }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-caption">
                                  {{ invoice.Company }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle style="white-space: nowrap;">
                                  <span class="text-caption">
                                    {{ invoice.Total | currency }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-btn
                                  class="info--text"
                                  icon
                                  ripple
                                  small
                                >
                                  <v-icon>remove_red_eye</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="`divider-${invoice.InvoiceId}`" />
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    lg="8"
                    md="7"
                    cols="12"
                  >
                    <v-card flat>
                      <v-toolbar
                        flat
                        class="pl-0"
                        height="70"
                      >
                        <v-avatar class="orange">
                          <v-icon dark>
                            view_list
                          </v-icon>
                        </v-avatar>
                        <v-toolbar-title class="pl-3">
                          <div class="body-3">
                            {{ selectedInvoice ? selectedInvoice.InvoiceNumber : $t('Account.Invoice.InvoiceDetails.Toolbar.Title') }}
                          </div>
                          <div class="text-caption">
                            {{ $t('Account.Invoice.InvoiceDetails.Toolbar.SubTitle') }}
                          </div>
                        </v-toolbar-title>

                        <v-spacer />

                        <v-btn
                          v-if="selectedInvoice"
                          :href="selectedInvoice.InvoiceUrl"
                          color="blue"
                          dark
                          icon
                          target="_blank"
                        >
                          <v-icon size="22">
                            picture_as_pdf
                          </v-icon>
                        </v-btn>
                      </v-toolbar>

                      <v-divider />

                      <v-card-text
                        v-if="selectedInvoice"
                        :style="internalCardHeight ? `height: ${internalCardHeight}px` : ''"
                        class="px-0"
                        style="overflow: auto;"
                      >
                        <v-container
                          class="pa-1"
                          fluid
                        >
                          <v-row
                            dense
                            class="mx-0"
                          >
                            <v-col
                              class="pa-0"
                              cols="12"
                            >
                              <div class="divTable">
                                <div class="divTableHeading">
                                  <div class="divTableRow">
                                    <div class="divTableHead text-center">
                                      #
                                    </div>
                                    <div class="divTableHead text-left">
                                      {{ $t('Account.Invoice.InvoiceDetails.Table.Service') }}
                                    </div>
                                    <div class="divTableHead text-left">
                                      {{ $t('Account.Invoice.InvoiceDetails.Table.Quantity') }}
                                    </div>
                                    <div class="divTableHead text-right">
                                      {{ $t('Account.Invoice.InvoiceDetails.Table.Price') }}
                                    </div>
                                    <div class="divTableHead text-right">
                                      {{ $t('Account.Invoice.InvoiceDetails.Table.Total') }}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  v-for="(item, index) in selectedInvoice.Items"
                                  :key="index"
                                  class="divTableRow"
                                >
                                  <div
                                    class="divTableCell text-center"
                                    v-text="index+1"
                                  />
                                  <div
                                    class="divTableCell text-left"
                                    v-html="item.LongDescription"
                                  />
                                  <div class="divTableCell text-left">
                                    {{ parseInt(item.Quantity) }} {{ item.Unit }}
                                  </div>
                                  <div class="divTableCell text-right">
                                    {{ item.Rate }}
                                  </div>
                                  <div class="divTableCell text-right">
                                    {{ item.Total }}
                                  </div>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-text
                        v-else
                        class="text-center"
                      >
                        <v-icon size="96">
                          receipt
                        </v-icon>
                        <h2 class="grey--text">
                          {{ $t('Account.Invoice.InvoiceDetails.NoDataWarning.Title') }}
                        </h2>
                        <h3 class="grey--text text--lighten-1">
                          {{ $t('Account.Invoice.InvoiceDetails.NoDataWarning.SubTitle') }}
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>

                <v-col v-else>
                  <v-icon size="128">
                    receipt
                  </v-icon>
                  <h2 class="grey--text">
                    {{ $t('Account.Invoice.NoDataWarning.Title') }}
                  </h2>
                  <h3 class="grey--text text--lighten-1">
                    {{ $t('Account.Invoice.NoDataWarning.SubTitle') }}
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Resizable          from '@/mixins/resizable'
import AppData            from '@/mixins/appdata'
import Auth               from '@/mixins/auth'
import CurrentPlanWarning from '@/components/account/CurrentPlanWarning'

export default {
  name      : 'AccountInvoices',
  components: { CurrentPlanWarning },
  directives: {},
  mixins    : [AppData, Auth, Resizable],
  props     : {},
  data () {
    return {
      viewData          : null,
      selectedInvoice   : null,
      mainCardHeight    : null,
      internalCardHeight: null
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Account.Invoices.List, this.setData)
    this.getInvoiceData()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Account.Invoices.List, this.setData)
  },
  destroyed () {
  },
  methods: {
    toggleInvoice (invoice) {
      this.selectedInvoice = this.selectedInvoice === invoice ? null : invoice
    },

    getInvoiceData () {
      this.loading = true
      window.callAS(window.SocketCommand.Account.Invoices.List, { Id: this.posUser.staff_id })
    },

    setData (data) {
      if (data && data.status === 'success') {
        this.viewData = data.data || []
      }
      this.loading = false
    },

    onResize () {
      const currentPlan = this.$refs.currentPlan
      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const currentPlanHeight = currentPlan ? currentPlan.$el.offsetHeight : 96
      this.mainCardHeight = innerHeight - headerHeight - footerHeight - currentPlanHeight - 96
      this.internalCardHeight = this.$vuetify.breakpoint.mdAndUp ? innerHeight - headerHeight - footerHeight - currentPlanHeight - 180 : null
    }
  }
}
</script>

<style scoped>

</style>
