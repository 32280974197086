<template>
  <v-container
    class="pa-0 overflow-hidden"
    fluid
    style="border-right: 1px solid rgba(255, 255, 255, 0.08);"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-container
          class="pa-0"
          fluid
        >
          <v-row no-gutters>
            <!-- Cart -->
            <v-col
              id="cart"
              class="px-1"
              style="overflow-y: auto;"
              cols="12"
            >
              <div
                v-if="!posCart.ItemsFlat.length"
                class="fill-height"
                style="position: relative;"
              >
                <div
                  class="text-center grey--text pa-6"
                  style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                >
                  <v-icon
                    class="mb-4"
                    color="grey lighten-2"
                    size="128"
                  >
                    {{ $t('Pos.Pos.Cart.Icon') }}
                  </v-icon>
                  <div class="text-h6 mb-2">
                    {{ $t('Pos.Pos.Cart.NoData.Title') }}
                  </div>
                </div>
              </div>

              <template v-else>
                <template v-for="(menu) in posCart.ItemsFlat">
                  <v-list
                    :key="menu.RowId"
                    class="mb-1 pb-0 pt-1"
                    dense
                    light
                    dark
                  >
                    <v-list-item
                      :disabled="menu.Loading"
                      dense
                      style="height: 56px;"
                      class="pr-2"
                      ripple
                    >
                      <v-list-item-content>
                        <span
                          :style="'background-color: #' + (getCategoryColor(menu) || '333333') + ';'"
                          style="position: absolute; width:5px; height: 100%; margin-left: -16px; padding: 0; margin-right: 16px;"
                        />

                        <v-list-item-title class="text-subtitle-1 grey--text text--lighten-2 font-weight-regular mb-2">
                          <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                            <strong class="orange--text">{{ formatUnitQuantity($i18n.locale, menu.UnitQuantity, getMeasurementUnitById(menu.UnitId)) }}</strong>
                          </template>
                          <template v-else>
                            <strong class="orange--text">
                              {{ menu.Quantity }} x
                            </strong>
                          </template>
                          {{ getTranslatedField(menu, 'Name') }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="font-weight-regular">
                          <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                            <span class="old-price">
                              {{ menu.MenuPrice | currency }}
                            </span> {{ menu.SpecialPrice | currency }}
                          </template>
                          <template v-else>
                            <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                              {{ getMenuItemUnitPriceWithOptions(menu) | currency }}
                              <span class="grey--text text-caption">/ {{ getMeasurementUnitById(menu.UnitId).BaseUnit }}</span>
                            </template>
                            <template v-else>
                              {{ menu.Price | currency }}
                            </template>
                          </template>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action class="ma-0">
                        <v-list-item-action-text class="text-subtitle-1">
                          <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                            <span class="old-price">
                              {{ parseFloat(parseFloat(menu.MenuPrice) * menu.Quantity) | currency }}
                            </span>
                            {{ parseFloat(parseFloat(menu.SpecialPrice) * menu.Quantity) | currency }}
                          </template>
                          <template v-else>
                            {{ parseFloat(parseFloat(menu.Price) * menu.Quantity) | currency }}
                          </template>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider dark />
                  </v-list>
                </template>
              </template>
            </v-col>

            <!-- Totals -->
            <v-col
              id="cartTotals"
              class=""
              cols="12"
            >
              <v-container
                fluid
                class="pa-1 pb-0 text-body-2"
              >
                <v-row no-gutters>
                  <!-- Sub Total -->
                  <v-col
                    class="grey--text text--darken-1"
                    cols="12"
                  >
                    <v-divider dark />

                    <div class="pa-1 pt-2">
                      <span class="grey--text text--darken-1">
                        {{ $t('Common.Misc.Subtotal').toLocaleUpperCase($i18n.locale) }}
                      </span>
                      <span class="grey--text text--darken-1 float-right pr-2">
                        {{ posCart.SCartTotal | currency }}
                      </span>
                    </div>

                    <v-divider dark />
                  </v-col>

                  <!-- Extra Charges -->
                  <v-col
                    v-if="cartHasExtraCharge"
                    class="grey--text text--darken-1"
                    cols="12"
                  >
                    <div class="divTable">
                      <template v-for="(total, index) in posCart.Totals">
                        <div
                          v-if="total.Name === 'extra_charge'"
                          :key="index"
                          class="divTableRow"
                        >
                          <div
                            class="divTableCell text-left pa-1"
                            style="width: 100%;"
                          >
                            <span class="grey--text text--darken-1">
                              {{ total.Title }}
                            </span>
                          </div>

                          <div
                            class="divTableCell text-right text-no-wrap pa-1"
                            style="min-width: 60px;"
                          >
                            <span class="grey--text text--darken-1 pr-2">
                              +{{ total.AmountFormatted | currency }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </div>
                    <v-divider dark />
                  </v-col>

                  <!-- Tips -->
                  <v-col
                    v-if="cartHasTip"
                    class="grey--text text--darken-1"
                    cols="12"
                  >
                    <template v-for="(tip, index) in posCart.Totals">
                      <div
                        v-if="tip.Name === 'tip_amount'"
                        :key="index"
                        class="pa-0 px-1"
                        style="float: none; clear: both; line-height: 28px;"
                      >
                        <span class="grey--text text--darken-1">
                          {{ tip.Title }}
                        </span>
                        <span class="grey--text text--darken-1 float-right pr-2">
                          +{{ tip.AmountFormatted | currency }}
                        </span>
                      </div>
                    </template>
                    <v-divider dark />
                  </v-col>

                  <!-- Coupons -->
                  <v-col
                    v-if="cartHasCoupon"
                    class="grey--text text--darken-1"
                    cols="12"
                  >
                    <div class="divTable">
                      <template v-for="(total, index) in posCart.Totals">
                        <div
                          v-if="total.Name === 'coupon'"
                          :key="index"
                          class="divTableRow"
                        >
                          <div
                            class="divTableCell text-left pa-1"
                            style="width: 100%;"
                          >
                            <span class="grey--text text--darken-1">
                              {{ total.Title }}
                            </span>
                          </div>

                          <div
                            class="divTableCell text-right text-no-wrap pa-1"
                            style="min-width: 60px;"
                          >
                            <span class="grey--text text--darken-1 pr-2">
                              -{{ total.AmountFormatted | currency }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </div>
                    <v-divider dark />
                  </v-col>

                  <!-- Delivery -->
                  <template v-for="(total, index) in posCart.Totals">
                    <v-col
                      v-if="total.Name === 'delivery'"
                      :key="`totals-delivery-${index}`"
                      class="grey--text text--darken-1"
                      cols="12"
                    >
                      <div class="pa-1 pb-0">
                        <span class="grey--text text--darken-1">
                          {{ $t('Order.Type.Delivery.Title').toLocaleUpperCase($i18n.locale) }}
                        </span>
                        <span class="grey--text text--darken-1 float-right pr-2">
                          {{ total.AmountFormatted | currency }}
                        </span>
                      </div>
                      <v-divider dark />
                    </v-col>
                  </template>

                  <!-- Order Payments Received -->
                  <v-col
                    v-if="!!receivedTotal"
                    class="grey--text text--darken-1"
                    cols="12"
                  >
                    <div class="pa-1 pb-0">
                      <span class="grey--text text--darken-1">
                        {{ $t('Common.Misc.Payment').toLocaleUpperCase($i18n.locale) }}
                      </span>
                      <span class="grey--text text--darken-1 float-right pr-2">
                        {{ receivedTotal | currency }}
                      </span>
                    </div>

                    <v-divider dark />
                  </v-col>

                  <!-- Order Change -->
                  <v-col
                    v-if="!!receivedTotal"
                    class="grey--text text--darken-1"
                    cols="12"
                  >
                    <div class="pa-1 pb-0">
                      <span class="grey--text text--darken-1">
                        {{ $t('Common.Misc.Change').toLocaleUpperCase($i18n.locale) }}
                      </span>
                      <span class="grey--text text--darken-1 float-right pr-2">
                        {{ orderChange | currency }}
                      </span>
                    </div>

                    <v-divider dark />
                  </v-col>

                  <!-- Order Totals -->
                  <template v-if="posCart.Totals.length === 0">
                    <v-col
                      class="grey--text"
                      cols="12"
                    >
                      <div class="pa-1 pt-2">
                        <strong>
                          <span class="grey--text">
                            {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                          </span>
                          <span class="grey--text float-right pr-2">
                            {{ 0 | currency }}
                          </span>
                        </strong>
                      </div>
                    </v-col>
                  </template>

                  <template v-else>
                    <template v-for="(total, index) in posCart.Totals">
                      <v-col
                        v-if="total.Name === 'order_total'"
                        :key="`totals-ordertotal-${index}`"
                        class="grey--text text--darken-"
                        cols="12"
                      >
                        <div class="pa-1 pt-2">
                          <strong>
                            <span class="grey--text">
                              {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                            </span>
                            <span class="grey--text float-right pr-2">
                              {{ total.AmountFormatted | currency }}
                            </span>
                          </strong>
                        </div>
                      </v-col>
                    </template>
                  </template>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Translatable           from '@/mixins/translatable'
import Resizable              from '@/mixins/resizable'
import { formatUnitQuantity } from '@/lib/currency/currency'
import CartModel              from '@/api/Models/pos/CartModel'
import { setI18nLanguage }    from '@/lang/lang'

export default {
  name: 'CdsCart',

  components: {},

  directives: {},

  mixins: [Resizable, Translatable],

  props: {},

  data () {
    return {
      cdsChannel   : new BroadcastChannel('cds'),
      posCart      : new CartModel(),
      orderChange  : 0,
      receivedTotal: 0
    }
  },

  computed: {
    cartHasExtraCharge () {
      return this.posCart ? !!this.posCart.Totals.find(total => total.Name === 'extra_charge') : false
    },

    cartHasCoupon () {
      return this.posCart ? !!this.posCart.Totals.find(total => total.Name === 'coupon') : false
    },

    cartHasTip () {
      return this.posCart ? !!this.posCart.Totals.find(total => total.Name === 'tip_amount') : false
    }
  },

  watch: {},

  created () {
    this.cdsChannel.onmessage = this.cdsChannelMessageReceived
    if (this.$route.query.lang) setI18nLanguage(this.$route.query.lang)
  },

  mounted () {
    this.onResize()

    this.$nextTick(() => {
      this.cdsChannelMessageSend('cart:get-data')
    })
  },

  beforeDestroy () {
    this.cdsChannel.close()
  },

  methods: {
    formatUnitQuantity,

    cdsChannelMessageSend (action, payload) {
      if (!this.cdsChannel) return

      this.cdsChannel.postMessage({
        Action : action,
        Payload: payload
      })
    },

    cdsChannelMessageReceived (event) {
      const action = event?.data?.Action || null
      const payload = event?.data?.Payload || new CartModel()

      const handleAction = (action) => {
        const actions = {
          'cds:payment-change': () => {
            this.orderChange = parseFloat(payload?.Change)
            this.receivedTotal = parseFloat(payload?.Received)
          },

          'cart:data': () => {
            this.posCart = new CartModel(payload)
            this.orderChange = this.posCart.OrderChange
            this.receivedTotal = this.posCart.OrderReceivedTotal
          },

          'default:action': () => {
            this.posCart = new CartModel(payload)
            this.orderChange = this.posCart.OrderChange
            this.receivedTotal = this.posCart.OrderReceivedTotal
          }
        }
        const execute = actions[action] || actions['default:action']
        execute()
      }

      handleAction(action)

      const cart = document.getElementById('cart')
      this.$nextTick(() => {
        cart && cart.scrollTo(0, innerHeight)
      })
    },

    getCategoryColor (item) {
      return item?.Category?.Color || 'grey'
    },

    getMenuItemUnitPriceWithOptions (menu) {
      const unitValueOptions = menu?.Options?.filter(option => option.DisplayType === 'unit_value' && option.Selected) || []

      let price = parseFloat(menu.MenuPrice || 0)
      unitValueOptions.forEach(option => {
        price = price + parseFloat(option.Price || 0)
      })
      return parseFloat(price.toFixed(2))
    },

    onResize () {
      const cart = document.getElementById('cart')
      const totalsHeight = document.getElementById('cartTotals') ? document.getElementById('cartTotals').offsetHeight : 0
      if (cart) cart.style.height = (innerHeight - totalsHeight) + 'px'
    }
  }
}
</script>

<style scoped>
.divTable {
  display : table;
  width   : 100%;
}

.divTableRow {
  display : table-row;
}

.divTableCell {
  border-collapse : collapse;
  display         : table-cell;
  padding-top     : 0;
  padding-bottom  : 0;
  align-content   : center;
}
</style>
