<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Qr.SettingsDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Qr.SettingsDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Qr.SettingsDialog.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Qr.SettingsDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="qrForm"
            v-model="qrFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row
                dense
                align="center"
              >
                <v-col
                  class="text-center justify-center align-center"
                  cols="12"
                >
                  <div
                    ref="qrCode"
                    class="qr-image"
                  />
                </v-col>

                <v-col
                  class="text-center"
                  cols="12"
                >
                  <v-row
                    align="center"
                    class="mt-6"
                  >
                    <v-col cols="6">
                      <color-picker
                        v-model="editQr.ColorDark"
                        :color="editQr.ColorDark || '#000000'"
                        :disabled="!userCanManage"
                        :label="$t('Settings.Website.Field.QRCode.Color') + ' #1'"
                      />
                    </v-col>

                    <v-col cols="6">
                      <color-picker
                        v-model="editQr.ColorLight"
                        :color="editQr.ColorLight || '#FFFFFF'"
                        :disabled="!userCanManage"
                        :label="$t('Settings.Website.Field.QRCode.Color') + ' #2'"
                      />
                    </v-col>

                    <v-col cols="6">
                      <color-picker
                        v-model="editQr.ColorControlOuter"
                        :color="editQr.ColorControlOuter || editQr.ColorDark || '#000000'"
                        :disabled="!userCanManage"
                        :label="$t('Settings.Website.Field.QRCode.Color') + ' #3'"
                      />
                    </v-col>

                    <v-col cols="6">
                      <color-picker
                        v-model="editQr.ColorControlInner"
                        :color="editQr.ColorControlInner || editQr.ColorDark || '#000000'"
                        :disabled="!userCanManage"
                        :label="$t('Settings.Website.Field.QRCode.Color') + ' #4'"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-switch
                        v-model="editQr.Logo"
                        inset
                        :disabled="!userCanManage"
                        :label="editQr.Logo ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0"
                        color="success"
                        hide-details
                      >
                        <h5
                          slot="prepend"
                          class="pt-1 grey--text text--darken-1 text-no-wrap"
                        >
                          {{ $t('Settings.Website.Field.QRCode.Logo') }}
                        </h5>
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData     from '@/mixins/appdata'
import Auth        from '@/mixins/auth'
import ColorPicker from '@/components/common/color/ColorPicker.vue'
import * as QRCode from 'easyqrcodejs'

export default {
  name      : 'QrSettingsDialog',
  components: { ColorPicker },
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading    : false,
      qrFormValid: false,
      qrCode     : null,
      newQr      : null,
      tmpQr      : {
        Logo             : true,
        DotScale         : 1.0,
        ColorDark        : '#000000',
        ColorLight       : '#FFFFFF',
        ColorControlInner: '#000000',
        ColorControlOuter: '#000000'
      },
      validationRules: {
        qrForm: {
          Data: [
            (v) => v && v.length >= 2 && v.length <= 100 || this.$t('Qr.AddEditDialog.Field.Data.Error.Between')
          ],
          Type: [
            (v) => v && v.length > 0 || this.$t('Qr.AddEditDialog.Field.Type.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    qrTypes () {
      return Object.values(this.$t('Qr.Type')).filter(type => parseInt(type.Id) > 0)
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editQr: {
      get () {
        if (this.itemToEdit) {
          this.newQr = JSON.parse(JSON.stringify(this.itemToEdit))
        } else {
          this.newQr = JSON.parse(JSON.stringify(this.tmpQr))
        }

        return this.newQr
      }
    }
  },
  watch: {
    editQr: {
      handler: function () {
        this.initQrCode()
      },
      deep: true
    },

    visible (newVal) {
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.qrForm) this.$refs.qrForm.resetValidation()
          this.initQrCode()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.QRCode.Settings.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.QRCode.Settings.Save, this.onSaveResult)
  },
  methods: {
    initQrCode () {
      if (this.qrCode) this.qrCode.clear()
      if (this.$refs.qrCode) {
        this.qrCode = new QRCode(this.$refs.qrCode, {
          text               : 'https://qr.getfood.pro/1/12345678',
          colorDark          : this.editQr.ColorDark,
          colorLight         : this.editQr.ColorLight,
          PO                 : this.editQr.ColorControlOuter,
          PI                 : this.editQr.ColorControlInner,
          // AO                 : this.editQr.ColorControlOuter,
          // AI                 : this.editQr.ColorControlInner,
          dotScale           : this.editQr.DotScale,
          logo               : this.editQr.Logo && this.getContrastLogo(this.LOGO_TYPE.QRCODE, this.editQr.ColorLight) ? this.getContrastLogo(this.LOGO_TYPE.QRCODE, this.editQr.ColorLight).encoded : undefined,
          logoBackgroundColor: this.editQr.ColorLight,
          correctLevel       : QRCode.CorrectLevel.H, // L, M, Q, H
          quietZoneColor     : 'transparent',
          quietZone          : 20,
          width              : 1024,
          height             : 1024
        })
      }
    },

    onSaveButtonClick () {
      this.$refs.qrForm.validate()

      if (this.qrFormValid) {
        this.loading = true
        window.callAS(window.SocketCommand.QRCode.Settings.Save, { Options: this.editQr })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data && data.status !== 'fail') {
        this.onCancelButtonClick()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Qr.AddEditDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.qrForm) this.$refs.qrForm.resetValidation()
      this.newQr = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

/deep/ .qr-image img {
  max-width : 100%;
  width     : 300px;
  height    : 300px;
}

/deep/ .qr-image canvas {
  max-width : 100%;
  width     : 300px;
  height    : 300px;
}

/deep/ .croppa-container canvas {
  width  : 100% !important;
  height : auto !important;
}
</style>
