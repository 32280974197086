<template>
  <v-dialog
    v-if="selectedMenuItem"
    ref="menuOptionsDialog"
    v-model="isVisible"
    v-resize="onResize"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="1024"
    persistent
    scrollable
    @keydown.enter="onSaveButtonClick"
  >
    <v-card
      v-if="selectedMenuItem && selectedMenuItem.Details"
      class=""
    >
      <v-toolbar
        :height="toolbarHeight"
        :max-height="toolbarHeight"
        flat
        class="menu-image"
      >
        <div
          v-if="menuItemImage && $vuetify.breakpoint.smAndUp"
          style="width: 180px; height: 100px;"
          class="mr-3"
        >
          <img
            v-lazy="appConfig.LOCATION_DATA.CdnImagesUrl + menuItemImage"
            style="width: 180px; height: 100px; border-radius: 5px; object-fit: cover;"
          >
        </div>

        <v-toolbar-title ref="toolbarTitle">
          <div
            class="text-subtitle-1"
            :class="isDarkMode ? 'grey--text text--lighten-2' : 'grey--text text--darken-2'"
          >
            <strong>{{ getTranslatedField(selectedMenuItem, 'Name') }}</strong>
          </div>

          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="text-caption"
            :class="isDarkMode ? 'grey--text' : 'grey--text text--darken-1'"
          >
            {{ getTranslatedField(selectedMenuItem.Details, 'Description') }}
          </div>

          <div class="text-h6 pt-2">
            <strong
              v-if="selectedMenuItem.Quantity > 1"
              class="orange--text"
            >
              {{ selectedMenuItem.Quantity }} x
            </strong>
            <template v-if="selectedMenuItem.SpecialStatus && selectedMenuItem.IsSpecial && !selectedMenuItem.HasCustomPrice">
              <span class="old-price">
                {{ selectedMenuItem.Details.Price | currency }}
              </span> {{ selectedMenuItem.SpecialPrice | currency }}
            </template>
            <template v-else>
              <template v-if="userCanSetUnitQuantity">
                {{ selectedMenuItem.Details.Price | currency }}
                <span
                  v-if="userCanSetUnitQuantity"
                  class="grey--text text-caption"
                >/ {{ getMeasurementUnitById(selectedMenuItem.UnitId).BaseUnit }}</span>
              </template>
              <template v-else>
                {{ selectedMenuItem.Price | currency }}
              </template>
            </template>
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          @click="onCancelButtonClick"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider />

      <template v-if="isTableOrder && (tableSeatEnabled || tableOrderEnabled)">
        <select-table-seat
          v-if="tableSeatEnabled"
          v-model="selectedMenuItem.Details.Custom.Seat"
          :persons.sync="posCart.TableShift.Persons"
        />

        <select-menu-item-order
          v-if="tableOrderEnabled"
          v-model="selectedMenuItem.Details.Custom.Order"
        />

        <v-divider />
      </template>

      <v-card-text
        ref="dialogOptionsContent"
        class="pa-2"
        :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-3']"
        style="min-height: 200px;"
      >
        <v-form @submit.prevent>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <v-col cols="12">
                <div class="pa-1">
                  <template v-for="tag in getMenuItemTags(selectedMenuItem.Details)">
                    <v-tooltip
                      v-if="tag.Visible && !tag.System"
                      :key="`menu-tag-tooltip-${tag.Id}`"
                      :color="tag.Color || '#666666'"
                      bottom
                    >
                      <template #activator="{on}">
                        <v-chip
                          v-if="tag.Visible && !tag.System"
                          :key="`menu-tag-${tag.Id}`"
                          :color="tag.Color || '#666666'"
                          small
                          label
                          class="menu-tag"
                          :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`"
                          v-on="on"
                        >
                          <v-icon
                            v-if="tag.Icon && !tag.LabelOnly"
                            small
                          >
                            {{ tag.Icon }}
                          </v-icon>

                          <span
                            v-if="!tag.Icon || tag.LabelOnly"
                            class="text-caption"
                            :class="{'ml-1': !!tag.Icon && !tag.LabelOnly}"
                          >
                            {{ tag.Label }}
                          </span>
                        </v-chip>
                      </template>

                      <div :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`">
                        <div class="font-weight-bold text-center">
                          {{ tag.Lang[$i18n.locale].Label }}
                        </div>
                        <div class="text-caption text-left">
                          {{ tag.Lang[$i18n.locale].Description }}
                        </div>
                      </div>
                    </v-tooltip>
                  </template>
                </div>
              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.xsOnly"
                cols="12"
              >
                <div class="text-subtitle-1 grey--text text--darken-1">
                  {{ getTranslatedField(selectedMenuItem.Details, 'Description') }}
                </div>
              </v-col>

              <v-expansion-panels
                v-if="menuOptionsListExpandableEnabled"
                v-model="panel"
                :multiple="menuOptionsListExpandableMultiple"
                accordion
                flat
              >
                <template v-for="(item, index) in selectedMenuItemOptions">
                  <template v-if="item.DisplayType === 'separator'">
                    <div
                      :key="`expansion-panel-seperator-${index}`"
                      class="my-4"
                      style="width: 100%;"
                    >
                      <div
                        v-if="item.DisplayName"
                        class="text-center separator-title font-weight-bold pl-1"
                      >
                        {{ getTranslatedField(item, 'Name', 'Lang', true, 'OptionName') }}
                      </div>
                      <div v-else>
                        <v-divider />
                      </div>
                    </div>
                  </template>

                  <v-expansion-panel
                    v-else
                    v-show="item.Visible !== false"
                    :key="`expansion-panel-${index}`"
                    :class="[{'transparent': item.DisplayType === 'label'}]"
                    :readonly="item.DisplayType === 'label'"
                    class="transparent"
                  >
                    <v-expansion-panel-header
                      class="pa-2"
                      :hide-actions="item.DisplayType === 'label'"
                    >
                      <div class="font-weight-bold text-subtitle-1 pl-1">
                        {{ getTranslatedField(item, 'Name', 'Lang', true, 'OptionName') }}
                      </div>
                    </v-expansion-panel-header>

                    <v-divider />

                    <v-expansion-panel-content
                      color="transparent"
                      class="pa-0"
                    >
                      <v-card
                        color="transparent"
                        flat
                      >
                        <v-card-text class="pa-0">
                          <v-container
                            class="pa-0"
                            fluid
                          >
                            <v-row no-gutters>
                              <v-alert
                                v-if="item.Error"
                                :value="true"
                                class="ma-1 mt-2 pa-1 text-caption flex-grow-1"
                                color="error"
                                icon="warning"
                                outlined
                              >
                                {{ item.Error.Message }}
                              </v-alert>

                              <template v-if="item.DisplayType === 'checkbox'">
                                <template v-for="(option, optionIndex) in item.OptionValues">
                                  <v-col
                                    v-show="option.Visible !== false"
                                    :key="optionIndex"
                                    :cols="item.OptionValues.length === 1 || $vuetify.breakpoint.xsOnly ? '12' : '4'"
                                  >
                                    <v-card
                                      class="ma-1"
                                      flat
                                      style="cursor: pointer;"
                                      @click="onCheckBoxClick(option)"
                                    >
                                      <v-card-text class="pa-2">
                                        <div class="option-value-row">
                                          <div class="option-value-row-control">
                                            <v-checkbox
                                              v-model="option.Selected"
                                              :disabled="(option.Mandatory && option.Selected) || option.Disabled"
                                              :required="option.Mandatory"
                                              class="mt-0 pt-0"
                                              color="green"
                                              hide-details
                                              readonly
                                            >
                                              <span
                                                slot="label"
                                                :class="{'font-weight-bold': option.Selected}"
                                                v-html="getTranslatedField(option, 'Name')"
                                              />
                                            </v-checkbox>
                                          </div>

                                          <div class="option-value-row-price">
                                            <div
                                              v-show="option.ShowPrice"
                                              :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                              class="text-caption float-right pr-1"
                                              style="min-height: 24px; line-height: 22px;"
                                            >
                                              {{ option.Price | currency }}
                                            </div>
                                          </div>
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </template>
                              </template>

                              <template v-if="item.DisplayType === 'extra_charges'">
                                <template v-for="(option, optionIndex) in item.OptionValues">
                                  <v-col
                                    v-show="option.Visible !== false"
                                    :key="optionIndex"
                                    :cols="item.OptionValues.length === 1 || $vuetify.breakpoint.xsOnly ? '12' : '4'"
                                  >
                                    <v-card
                                      class="ma-1"
                                      flat
                                    >
                                      <v-card-text class="pa-2">
                                        <div class="option-value-row">
                                          <div class="option-value-row-control">
                                            <span
                                              :class="{'font-weight-bold': option.Selected}"
                                              v-html="getTranslatedField(option, 'Name')"
                                            />
                                          </div>

                                          <div class="option-value-row-price">
                                            <div
                                              v-show="option.ShowPrice"
                                              :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                              class="text-caption float-right pr-1"
                                              style="min-height: 24px; line-height: 22px;"
                                            >
                                              {{ option.Price | currency }}
                                            </div>
                                          </div>
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </template>
                              </template>

                              <template v-if="item.DisplayType==='radio' || item.DisplayType==='unit_value'">
                                <v-radio-group
                                  v-model="item.DefaultValueId"
                                  :mandatory="item.Required"
                                  class="pa-0 ma-0 flex-grow-1"
                                  hide-details
                                  row
                                  @change="onRadioChange"
                                >
                                  <v-container
                                    class="pa-0"
                                    fluid
                                  >
                                    <v-row no-gutters>
                                      <template v-for="option in item.OptionValues">
                                        <v-col
                                          :key="item.Id + '-radio-' + option.Id"
                                          cols="12"
                                          :sm="item.OptionValues.length === 1 ? 12 : 6"
                                          :md="item.OptionValues.length === 1 ? 12 : 4"
                                        >
                                          <v-card
                                            class="ma-1"
                                            flat
                                            style="cursor: pointer;"
                                            @click="onRadioClick(item, option)"
                                          >
                                            <v-card-text class="pa-2">
                                              <div class="option-value-row">
                                                <div class="option-value-row-control">
                                                  <v-radio
                                                    :value="option.Id"
                                                    class="mt-0 pt-0"
                                                    color="green"
                                                    hide-details1
                                                    placeholder=""
                                                  >
                                                    <span
                                                      slot="label"
                                                      :class="{'font-weight-bold': option.Selected}"
                                                      v-html="getTranslatedField(option, 'Name')"
                                                    />
                                                  </v-radio>
                                                </div>

                                                <div class="option-value-row-price">
                                                  <span
                                                    v-show="option.ShowPrice"
                                                    :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                                    class="text-caption float-right pt-2 pr-1"
                                                    style="min-height: 24px; line-height: 10px;"
                                                  >
                                                    {{ option.Price | currency }}
                                                  </span>
                                                </div>
                                              </div>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </template>
                                    </v-row>
                                  </v-container>
                                </v-radio-group>
                              </template>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>

              <template v-else>
                <template v-for="item in selectedMenuItemOptions">
                  <v-col
                    v-show="item.Visible !== false"
                    :key="item.Id"
                    cols="12"
                  >
                    <v-container
                      class="pa-0"
                      fluid
                    >
                      <v-row no-gutters>
                        <v-col
                          class="text-subtitle-1"
                          cols="12"
                        >
                          <template v-if="item.DisplayType === 'separator'">
                            <div
                              v-if="item.DisplayName"
                              class="text-center separator-title font-weight-bold mt-2 pl-1"
                            >
                              {{ getTranslatedField(item, 'Name', 'Lang', true, 'OptionName') }}
                            </div>
                            <v-divider v-else />
                          </template>

                          <div
                            v-else
                            class="font-weight-bold mt-2 pl-1"
                          >
                            {{ getTranslatedField(item, 'Name', 'Lang', true, 'OptionName') }}
                          </div>

                          <v-alert
                            v-if="item.Error"
                            :value="true"
                            class="ma-1 mb-2 pa-1 text-caption flex-grow-1"
                            color="error"
                            icon="warning"
                            outlined
                          >
                            {{ item.Error.Message }}
                          </v-alert>
                        </v-col>

                        <v-col cols="12">
                          <v-container
                            class="pa-0 pb-3"
                            fluid
                          >
                            <v-row dense>
                              <template v-if="item.DisplayType==='checkbox'">
                                <template v-for="(option, optionIndex) in item.OptionValues">
                                  <v-col
                                    v-show="option.Visible!==false"
                                    :key="optionIndex"
                                    :cols="item.OptionValues.length === 1 || $vuetify.breakpoint.xsOnly ? 12 : 4"
                                  >
                                    <v-card
                                      class="ma-1"
                                      :ripple="true"
                                      flat
                                      style="cursor: pointer;"
                                      @click="onCheckBoxClick(option)"
                                    >
                                      <v-card-text class="pa-2">
                                        <div class="option-value-row">
                                          <div class="option-value-row-control">
                                            <v-checkbox
                                              v-model="option.Selected"
                                              :disabled="(option.Mandatory && option.Selected) || option.Disabled"
                                              :required="option.Mandatory"
                                              class="mt-0 pt-0"
                                              color="green"
                                              hide-details
                                              readonly
                                            >
                                              <span
                                                slot="label"
                                                :class="{'font-weight-bold': option.Selected}"
                                                v-html="getTranslatedField(option, 'Name')"
                                              />
                                            </v-checkbox>
                                          </div>

                                          <div class="option-value-row-price">
                                            <div
                                              v-show="option.ShowPrice"
                                              :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                              class="text-caption float-right pr-1"
                                              style="min-height: 24px; line-height: 22px;"
                                            >
                                              {{ option.Price | currency }}
                                            </div>
                                          </div>
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </template>
                              </template>

                              <template v-if="item.DisplayType === 'extra_charges'">
                                <template v-for="(option, optionIndex) in item.OptionValues">
                                  <v-col
                                    v-show="option.Visible!==false"
                                    :key="optionIndex"
                                    :cols="item.OptionValues.length === 1 || $vuetify.breakpoint.xsOnly ? 12 : 4"
                                  >
                                    <v-card
                                      class="ma-1"
                                      flat
                                    >
                                      <v-card-text class="pa-2">
                                        <div class="option-value-row">
                                          <div class="option-value-row-control">
                                            <span
                                              :class="{'font-weight-bold': option.Selected}"
                                              v-html="getTranslatedField(option, 'Name')"
                                            />
                                          </div>

                                          <div class="option-value-row-price">
                                            <div
                                              v-show="option.ShowPrice"
                                              :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                              class="text-caption float-right pr-1"
                                              style="min-height: 24px; line-height: 22px;"
                                            >
                                              {{ option.Price | currency }}
                                            </div>
                                          </div>
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </template>
                              </template>

                              <v-col cols="12">
                                <v-radio-group
                                  v-if="item.DisplayType==='radio' || item.DisplayType==='unit_value'"
                                  v-model="item.DefaultValueId"
                                  :mandatory="item.Required"
                                  class="pa-0 ma-0"
                                  hide-details
                                  row
                                  @change="onRadioChange"
                                >
                                  <v-container
                                    class="pa-0"
                                    fluid
                                  >
                                    <v-row dense>
                                      <v-col
                                        v-for="option in item.OptionValues"
                                        :key="item.Id + '-radio-' + option.Id"
                                        cols="12"
                                        :sm="item.OptionValues.length === 1 ? 12 : 6"
                                        :md="item.OptionValues.length === 1 ? 12 : 4"
                                      >
                                        <v-card
                                          class="ma-1"
                                          flat
                                          style="cursor: pointer;"
                                          @click="onRadioClick(item, option)"
                                        >
                                          <v-card-text class="pa-2">
                                            <div class="option-value-row">
                                              <div class="option-value-row-control">
                                                <v-radio
                                                  :value="option.Id"
                                                  class="mt-0 pt-0"
                                                  color="green"
                                                  hide-details1
                                                  placeholder=""
                                                >
                                                  <span
                                                    slot="label"
                                                    :class="{'font-weight-bold': option.Selected}"
                                                    v-html="getTranslatedField(option, 'Name')"
                                                  />
                                                </v-radio>
                                              </div>

                                              <div class="option-value-row-price">
                                                <span
                                                  v-show="option.ShowPrice"
                                                  :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                                  class="text-caption float-right pt-2 pr-1"
                                                  style="min-height: 24px; line-height: 10px;"
                                                >
                                                  {{ option.Price | currency }}
                                                </span>
                                              </div>
                                            </div>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </template>
              </template>

              <v-col cols="12">
                <v-textarea
                  v-model="selectedMenuItem.Details.Comment"
                  :hint="$t('Pos.MenuOptionsDialog.Field.Comment.Hint')"
                  :label="$t('Pos.MenuOptionsDialog.Field.Comment.Label')"
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-2">
        <v-container
          class="pa-0"
          fluid
        >
          <v-row dense>
            <v-col
              v-if="!!menuItemSelectedOptionsText"
              cols="12"
            >
              <div class="text-caption px-1 pt-1 pb-2">
                <strong>{{ $t('Pos.MenuOptionsDialog.SelectedOptions') }}:</strong> {{ menuItemSelectedOptionsText }}
              </div>
            </v-col>

            <v-col
              v-if="$vuetify.breakpoint.xsOnly && !userCanSetUnitQuantity"
              cols="12"
            >
              <v-btn-toggle
                v-if="userCanSetCustomPrice"
                :disabled="submitButton"
                class="ma-0 pa-0"
                background-color="blue"
                active-class=""
                color="blue"
                dense
                style="width: 100%;"
              >
                <v-btn
                  :disabled="submitButton"
                  class="white--text"
                  color="blue"
                  depressed
                  dark
                  style="min-width: 145px; width: calc(100% - 48px);"
                  @click="onShowCustomPriceDialog"
                >
                  <template v-if="selectedMenuItem.HasCustomPrice">
                    {{ $t('Restaurant.MenuAddDialog.Button.CustomPrice.Update').toLocaleUpperCase($i18n.locale) }}
                  </template>
                  <template v-else>
                    {{ $t('Restaurant.MenuAddDialog.Button.CustomPrice.Set').toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>

                <v-btn
                  :disabled="submitButton || !selectedMenuItem.HasCustomPrice"
                  class="white--text"
                  color="blue"
                  depressed
                  dark
                  style="max-width: 48px;"
                  @click="onResetCustomPrice"
                >
                  <v-icon color="white">
                    mdi-arrow-u-left-top
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12">
              <div class="d-inline-block text-no-wrap">
                <span class="pr-1 hidden-xs-only text-body-2">
                  {{ $t('Common.Misc.Quantity').toLocaleUpperCase($i18n.locale) }}
                </span>

                <template v-if="userCanSetUnitQuantity">
                  <v-btn
                    outlined
                    color="grey darken-1"
                    class="px-1"
                    style="text-transform: none; min-width: 100px;"
                    @click="onShowUnitQuantityDialog"
                  >
                    <v-icon class="mr-2">
                      mdi-keyboard-outline
                    </v-icon>

                    <v-spacer />

                    {{ formatUnitQuantity($i18n.locale, selectedMenuItem.UnitQuantity, getMeasurementUnitById(selectedMenuItem.UnitId)) }}

                    <span
                      v-if="selectedMenuItem.SubtractStock"
                      class="ml-1 text-caption"
                    >
                      / {{ formatUnitQuantity($i18n.locale, maxQuantity, getMeasurementUnitById(selectedMenuItem.UnitId)) }}
                    </span>
                  </v-btn>
                </template>

                <template v-else>
                  <v-btn
                    :disabled="quantity <= (selectedMenuItem.MinimumQuantity || 1)"
                    class="grey--text elevation-0"
                    outlined
                    style="min-width: 40px; max-width: 40px;"
                    @click="decrementQuantity"
                  >
                    -
                  </v-btn>

                  <v-btn
                    class="grey--text elevation-0 ml-1"
                    :style="isDarkMode ? 'background-color: #212121;' : 'background-color: white;'"
                    disabled
                    outlined
                    style="min-width: 40px; max-width: 120px;"
                  >
                    {{ selectedMenuItem.Quantity + ((selectedMenuItem.SubtractStock || selectedMenuItem.Details.SubtractStock) ? ' / ' + maxQuantity : '') }}
                  </v-btn>

                  <v-btn
                    :disabled="quantity >= maxQuantity && (selectedMenuItem.SubtractStock || selectedMenuItem.Details.SubtractStock)"
                    class="grey--text elevation-0 ml-1"
                    outlined
                    style="min-width: 40px; max-width: 40px;"
                    @click="incrementQuantity"
                  >
                    +
                  </v-btn>
                </template>
              </div>

              <div class="d-inline-block float-end">
                <v-btn-toggle
                  v-if="userCanSetCustomPrice && !userCanSetUnitQuantity && $vuetify.breakpoint.smAndUp"
                  :disabled="submitButton"
                  class="ma-0 pa-0 mr-2"
                  background-color="blue"
                  active-class=""
                  color="blue"
                  dense
                >
                  <v-btn
                    :disabled="submitButton"
                    class="white--text"
                    color="blue"
                    depressed
                    dark
                    style="min-width: 145px;"
                    @click="onShowCustomPriceDialog"
                  >
                    <template v-if="selectedMenuItem.HasCustomPrice">
                      {{ $t('Restaurant.MenuAddDialog.Button.CustomPrice.Update').toLocaleUpperCase($i18n.locale) }}
                    </template>
                    <template v-else>
                      {{ $t('Restaurant.MenuAddDialog.Button.CustomPrice.Set').toLocaleUpperCase($i18n.locale) }}
                    </template>
                  </v-btn>

                  <v-btn
                    :disabled="submitButton || !selectedMenuItem.HasCustomPrice"
                    class="white--text"
                    color="blue"
                    depressed
                    dark
                    style="max-width: 48px;"
                    @click="onResetCustomPrice"
                  >
                    <v-icon color="white">
                      mdi-arrow-u-left-top
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>

                <v-btn
                  :disabled="submitButton"
                  :loading="submitButton"
                  class="white--text pa-2"
                  color="green darken-1"
                  depressed
                  style="min-width: 145px; margin-bottom: 1px;"
                  @click="onSaveButtonClick"
                >
                  <div class="mr-2 flex text-left">
                    {{ menuOptionsDialogEditMode ? $t('Common.Button.Update').toLocaleUpperCase($i18n.locale) : $t('Common.Button.Add').toLocaleUpperCase($i18n.locale) }}
                  </div>
                  <div class="flex text-right">
                    {{ menuItemTotal | currency }}
                  </div>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <num-pad-dialog
            v-if="selectedMenuItem && selectedMenuItem.MenuCustomPrice"
            :initial-value="selectedMenuItem.HasCustomPrice ? parseFloat(parseFloat(selectedMenuItem.Price).toFixed(2)) : 0"
            :visible.sync="customPriceDialogVisible"
            currency
            @save="onSetCustomPrice"
          />

          <num-pad-dialog
            v-if="userCanSetUnitQuantity"
            :visible.sync="unitQuantityDialogVisible"
            :use-decimals="false"
            :suffix="getMeasurementUnitById(selectedMenuItem.UnitId).RateUnit"
            :max-num-value="selectedMenuItem.SubtractStock ? maxQuantity : undefined"
            :min-num-value="selectedMenuItem.MinimumQuantity ? parseInt(selectedMenuItem.MinimumQuantity) : undefined"
            number
            @save="onSetUnitQuantity"
          />
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppData                from '@/mixins/appdata'
import Translatable           from '@/mixins/translatable'
import PosOrder               from '@/mixins/pos/posOrder'
import PosCatalog             from '@/mixins/pos/posCatalog'
import SelectTableSeat        from '@/components/restaurant/tables/common/SelectTableSeat'
import SelectMenuItemOrder    from '@/components/restaurant/tables/common/SelectMenuItemOrder'
import PosMenuItemCommon      from '@/mixins/pos/posMenuItemCommon'
import NumPadDialog           from '@/components/common/NumPadDialog'
import Auth                   from '@/mixins/auth'
import { formatUnitQuantity } from '@/lib/currency/currency'
import PinManagerEnum         from '@/api/Enums/PinManagerEnum'
import { getContrastColor }   from '@/lib/color'

export default {
  components: {
    NumPadDialog,
    SelectMenuItemOrder,
    SelectTableSeat
  },
  mixins: [AppData, Auth, Translatable, PosOrder, PosCatalog, PosMenuItemCommon],
  props : {
    menuOptionsDialogVisible: {
      type   : Boolean,
      default: false
    },
    selectedMenuItem: {
      type   : Object,
      default: undefined
    },
    menuOptionsDialogEditMode: {
      type   : Boolean,
      default: false
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      panel                    : 0,
      submitButton             : false,
      toolbarHeight            : 120,
      selectedOption           : null,
      customPriceDialogVisible : false,
      unitQuantityDialogVisible: false
    }
  },
  computed: {
    maxQuantity () {
      if (!this.selectedMenuItem) return 0

      const menuItemStockQuantity = parseInt(this.selectedMenuItem?.Details?.StockQuantity)
      const menuItemUnitQuantity = parseInt(this.selectedMenuItem?.Details?.UnitQuantity)
      const menuItemQuantity = parseInt(this.selectedMenuItem?.Details?.Quantity)

      const availableStockQuantity = menuItemStockQuantity - this.menuItemQuantityInCart(this.selectedMenuItem)
      const menuQuantity = this.userCanSetUnitQuantity ? menuItemUnitQuantity : menuItemQuantity

      if (this.menuOptionsDialogEditMode) return menuQuantity + availableStockQuantity

      return availableStockQuantity
    },

    userCanSetUnitQuantity () {
      return this.selectedMenuItem?.MenuCustomPrice && this.selectedMenuItem?.UnitId > 1
    },

    userCanSetCustomPrice () {
      const retVal = this.selectedMenuItem?.MenuCustomPrice || false

      if (this.userIsWaiter) {
        return retVal && this.posUser?.Settings?.Tables?.Waiter?.Permissions?.WaiterCanSetCustomPrice
      }

      return retVal && this.posUserHasComponentPermission('KitchenMenu', 'MANAGE')
    },

    isVisible: {
      get () {
        return this.menuOptionsDialogVisible
      },
      set (val) {
        this.$emit('update:menuOptionsDialogVisible', val)
      }
    },

    menuItemImage () {
      return this.selectedMenuItem?.Image || this.selectedMenuItem?.Details?.Image || null
    },

    menuItemTotal () {
      return this.quantity * this.selectedMenuItem.Price
    },

    menuItemSelectedOptionsText () {
      return this.selectedMenuItemOptions
        ?.filter(option => option.DisplayType !== 'extra_charges')
        ?.map(option => option.OptionValues
          ?.filter(optionValue => optionValue.Selected)
          ?.map(optionValue => optionValue?.Lang[this.$i18n.locale]?.Name || optionValue?.Name)?.join(', '))
        ?.filter(item => item.length)?.join(', ')
    },

    menuOptionsListExpandable () {
      return this.appConfig?.LOCATION_DATA?.MenuOptionsListExpandable || {
        Enabled        : true,
        Desktop        : false,
        MultipleDesktop: false,
        Mobile         : true,
        MultipleMobile : false
      }
    },

    menuOptionsListExpandableEnabled () {
      if (!this.menuOptionsListExpandable.Enabled) return false

      if (this.menuOptionsListExpandable.Mobile && this.menuOptionsListExpandable.Desktop) return true
      if (this.$vuetify.breakpoint.xsOnly && this.menuOptionsListExpandable.Mobile) return true
      if (this.$vuetify.breakpoint.smAndUp && this.menuOptionsListExpandable.Desktop) return true

      return false
    },

    menuOptionsListExpandableMultiple () {
      if (this.menuOptionsListExpandable.Mobile && this.menuOptionsListExpandable.MultipleMobile &&
        this.menuOptionsListExpandable.Desktop && this.menuOptionsListExpandable.MultipleDesktop) {
        return true
      }

      if (this.$vuetify.breakpoint.xsOnly && this.menuOptionsListExpandable.Mobile && this.menuOptionsListExpandable.MultipleMobile) return true
      if (this.$vuetify.breakpoint.smAndUp && this.menuOptionsListExpandable.Desktop && this.menuOptionsListExpandable.MultipleDesktop) return true

      return false
    },

    tableSeatEnabled () {
      return this.appConfig?.LOCATION_DATA?.MenuOptionsDialog?.TableSeat || false
    },

    tableOrderEnabled () {
      return this.appConfig?.LOCATION_DATA?.MenuOptionsDialog?.TableOrder || false
    },

    showOptionsDefaultClearButtons () {
      let hasCheckboxes = 0
      this.selectedMenuItemOptions.forEach(item => {
        if (item.DisplayType === 'checkbox' || item.DisplayType === 'extra_charges') hasCheckboxes++
      })

      return hasCheckboxes > 0
    }

  },
  watch: {
    maxQuantity (newVal) {
      if (!this.selectedMenuItem) return

      if (newVal < this.selectedMenuItem.UnitQuantity) {
        this.selectedMenuItem.UnitQuantity = newVal
      } else {
        this.selectedMenuItem.UnitQuantity = this.selectedMenuItem?.DetailsData?.UnitQuantity || this.selectedMenuItem?.Details?.UnitQuantity
      }
    },

    customPriceDialogVisible (newVal) {
      if (!newVal) this.setFocusToDialog()
    },
    unitQuantityDialogVisible (newVal) {
      if (!newVal) this.setFocusToDialog()
    },

    isVisible (newVal) {
      if (newVal) {
        this.panel = 0
        this.submitButton = false
        this.initMenuItem()
        this.dispatchResizeEvent()
        this.calculateToolbarHeight()
      } else {
        if (this.$refs.dialogOptionsContent) this.$refs.dialogOptionsContent.scrollTop = 0
      }
    }
  },
  methods: {
    getContrastColor,
    formatUnitQuantity,

    setFocusToDialog () {
      this.$nextTick(() => {
        if (this.$refs?.menuOptionsDialog?.$refs?.dialog) this.$refs.menuOptionsDialog.$refs.dialog.focus()
      })
    },

    menuItemQuantityInCart (menu) {
      let quantity = 0
      this.posCart.Items.forEach(category => {
        category.Items.forEach(item => {
          if (parseInt(item.Id) === parseInt(menu.Id)) {
            const itemQuantity = item.UnitId > 1 ? item.UnitQuantity : item.Quantity
            quantity = quantity + parseInt(itemQuantity)
          }
        })
      })
      return quantity
    },

    menuItemCountInCart (menu) {
      let count = 0
      this.posCart.Items.forEach(category => {
        category.Items.forEach(item => {
          if (parseInt(item.Id) === parseInt(menu.Id)) count++
        })
      })
      return count
    },

    onShowUnitQuantityDialog () {
      if (!this.userCanSetUnitQuantity) return

      this.unitQuantityDialogVisible = true
    },

    onShowCustomPriceDialog () {
      if (!this.userCanSetCustomPrice) return

      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.PosSetCustomPrice) || this.userNeedsPinPermission(PinManagerEnum.TableSetCustomPrice), () => {
        this.customPriceDialogVisible = true
      }, 'set-custom-price')
    },

    onResetCustomPrice () {
      this.selectedMenuItem.HasCustomPrice = false
      this.selectedMenuItem.Price = this.selectedMenuItem.Details.Price

      this.calculateModifiers()
      this.isRequiredOptionsValid()
      this.calculateOptionsPrices()
    },

    onSetUnitQuantity (val) {
      this.selectedMenuItem.UnitQuantity = val || 1
      this.setUnitQuantityCustomPrice()
    },

    onSaveButtonClick () {
      if (!this.isVisible) return
      if (!this.isRequiredOptionsValid()) return
      this.submitButton = true

      const saveData = JSON.parse(JSON.stringify(this.selectedMenuItem))
      if (saveData?.Details?.OptionsCopy) delete saveData.Details.OptionsCopy

      this.$emit('save:menuOptionsItem', saveData)
    },

    onCancelButtonClick () {
      this.isVisible = false
    },

    calculateToolbarHeight () {
      this.$nextTick(() => {
        const toolbarTitle = this.$refs.toolbarTitle
        if (toolbarTitle) {
          this.toolbarHeight = toolbarTitle.clientHeight < 120 && this.menuItemImage && this.$vuetify.breakpoint.smAndUp ? 120 : (toolbarTitle.clientHeight < 90 ? 90 : toolbarTitle.clientHeight)
        }
      })
    },

    onResize () {
      this.calculateToolbarHeight()
    },

    onResetToDefaultOptions () {
      if (!this.selectedMenuItem.Details) return

      const options = this.selectedMenuItemOptions
      const defaultOptions = this.selectedMenuItemOptionsCopy
      options.forEach((option, i) => {
        if (option.DisplayType === 'checkbox' || option.DisplayType === 'extra_charges') {
          option.OptionValues.forEach((optionValue, ii) => {
            option.OptionValues[ii].Selected = defaultOptions[i].OptionValues[ii].Selected
          })
        }
      })

      this.onRadioChange()
      this.onCheckBoxChange()
    },

    onClearDefaultOptions () {
      if (!this.selectedMenuItem.Details) return

      const options = this.selectedMenuItemOptions
      options.forEach((o) => {
        if (o.DisplayType === 'checkbox' || o.DisplayType === 'extra_charges') {
          const selectedItems = o.OptionValues.filter((option) => {
            return option.Selected && !option.Disabled
          })
          selectedItems.forEach((optionValue) => {
            optionValue.Selected = false
          })
        }
      })

      this.onRadioChange()
      this.onCheckBoxChange()
    },

    dispatchResizeEvent () {
      if (typeof window === 'undefined') return
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    }
  }
}
</script>

<style scoped>
/deep/ .unit-quantity.v-text-field input {
  text-align : right;
  cursor     : pointer;
}

/deep/ .v-card--link:focus:before {
  opacity : 0;
}

/deep/ .v-expansion-panel__header {
  padding    : 8px 6px;
  min-height : 48px;
}

/deep/ .v-expansion-panel-content__wrap {
  padding : 0;
}

/deep/ .v-toolbar__title {
  white-space : normal !important;
}

/deep/ .menu-image > .v-toolbar__content {
  padding-left : 12px !important;
}

/deep/ .v-input--radio-group--row .v-input__control {
  width : 100%;
}

/deep/ .v-tabs__icon--next, /deep/ .v-tabs__icon--prev {
  line-height : 48px;
}

.separator-title {
  position   : relative;
  z-index    : 1;
  overflow   : hidden;
  text-align : center;
}

.separator-title:before, .separator-title:after {
  position         : absolute;
  top              : 51%;
  overflow         : hidden;
  width            : 48%;
  height           : 1px;
  content          : '\a0';
  background-color : #cccccc;
  margin-left      : 2%;
}

.separator-title:before {
  margin-left : -50%;
  text-align  : right;
}

.option-value-row {
  display         : flex;
  width           : 100%;
  justify-content : space-between;
  align-items     : center;
}

.option-value-row-control {
  overflow      : hidden;
  min-width     : 0;
  text-overflow : ellipsis;
  margin-right  : 6px;
}

.option-value-row-price {
  text-align : right;
}

.menu-tag {
  margin       : 0;
  margin-right : 4px;
  height       : 18px !important;
  padding      : 2px;
}
</style>
