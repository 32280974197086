import BaseModel from '../../../lib/data/Models/BaseModel'

export default class SupportPriorityModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.priorityid = parseInt(rawData.priorityid) || 0
    this.name = rawData.name || ''
  }

  boot () {

  }

  /* PROPERTIES */

  /* METHODS */
}
