<template>
  <div :class="containerClass">
    <v-text-field
      v-model="model"
      :disabled="disabled"
      :class="{'closed': searchClosed && !model && !maximized, 'disabled': disabled}"
      :placeholder="$t('Common.Search.Label')"
      :append-icon="model ? 'close' : ''"
      class="expanding-search"
      flat
      hide-details
      prepend-inner-icon="search"
      single-line
      solo
      @focusin="searchClosed = false"
      @focusout="searchClosed = true"
      @input="(data) => $emit('input', data)"
      @click:append="model=''"
    />
  </div>
</template>

<script>

export default {
  name      : 'FilterSearch',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : String,
      default: ''
    },

    containerClass: {
      type   : String,
      default: 'ma-1'
    },

    maximized: {
      type   : Boolean,
      default: false
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  enums: {},
  data () {
    return {
      searchClosed: true
    }
  },
  computed: {
    model: {
      get () {
        return this.value || ''
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
.expanding-search {
  width      : 100%;
  max-width  : 350px;
  transition : max-width 0.3s;
}

.expanding-search.closed {
  max-width : 48px;
}

/deep/ .theme--dark.v-input--is-disabled .v-icon {
  color : rgba(255, 255, 255, 0.3) !important;
}

/deep/ .theme--light.v-input--is-disabled .v-icon {
  color : rgba(0, 0, 0, 0.26) !important;
}

/deep/ .theme--dark.v-input--is-disabled > .v-input__control > .v-input__slot {
  background-color : rgba(255, 255, 255, 0.12) !important;
}

/deep/ .theme--light.v-input--is-disabled > .v-input__control > .v-input__slot {
  background-color : rgba(0, 0, 0, 0.12) !important;
}

/deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background : rgba(0, 0, 0, 0.06);
}

/deep/ .expanding-search.closed .v-input__control .v-input__slot {
  cursor : pointer !important;
}

/deep/ .v-icon {
  color : #1976d2;
}

@media only screen and (max-width : 599px) {
  .expanding-search {
    max-width : 240px;
  }

  .expanding-search.closed {
    max-width : 36px;
  }

  /deep/ .v-text-field.v-text-field--solo .v-input__control {
    min-height : 36px;
  }

  /deep/ .v-text-field.v-text-field--enclosed .v-text-field__details,
  /deep/ .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    padding : 0 6px;
  }
}
</style>
