<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-1">
        <v-toolbar
          :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
          class="filter-toolbar"
          flat
        >
          <v-spacer />

          <filter-search v-model="dataTable.search.terms" />

          <filter-options
            v-model="filtersModel"
            :filters="filterData"
          />
        </v-toolbar>
      </v-card-title>

      <v-container
        class="pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :footer-props="dataTable.footerProps"
              :headers="dataTableHeaders"
              :height="dataTable.resize.bodyHeight"
              :items="dataTable.data"
              :loading="dataTable.loading"
              :loading-text="dataTable.text.loadingText"
              :no-data-text="dataTable.text.noDataText"
              :no-results-text="dataTable.text.noResultsText"
              :options.sync="dataTable.options"
              :search="dataTable.search.terms"
              :server-items-length="dataTable.total"
              fixed-header
              item-key="Id"
            >
              <template #[`item.id`]="{item}">
                <span>{{ item.Id }}</span>
              </template>

              <template #[`item.type`]="{item}">
                <span :class="receiptTypeColor(item, true)">{{ item.InvoiceTypeCode }} {{ item.InvoiceTypeName }}</span>
              </template>

              <template #[`item.number`]="{item}">
                <div class="text-no-wrap">
                  <v-chip
                    label
                    dark
                    x-small
                    style="min-width: 100px;"
                    class="align-center justify-center font-weight-bold"
                    @click="showReceiptDetailsDialog(item.Id)"
                  >
                    {{ item.InternalId }}
                  </v-chip>

                  <v-btn
                    v-if="item.DocumentUrl"
                    icon
                    x-small
                    target="_blank"
                    :href="item.DocumentUrl"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </template>

              <template #[`item.total`]="{item}">
                <v-tooltip
                  :disabled="!item.PaymentMethods || (item.PaymentMethods && !item.PaymentMethods.length)"
                  bottom
                  :color="isDarkMode ? '#191919' : 'grey lighten-5'"
                >
                  <template #activator="{on}">
                    <div v-on="on">
                      <v-badge color="transparent">
                        <span>{{ item.AmountFmt }}</span>

                        <template
                          v-if="item.PaymentMethods && item.PaymentMethods.length"
                          #badge
                        >
                          <v-icon
                            color="info"
                            x-small
                          >
                            mdi-information-variant-circle-outline
                          </v-icon>
                        </template>
                      </v-badge>
                    </div>
                  </template>

                  <v-card
                    flat
                    color="transparent"
                  >
                    <v-card-title class="justify-center font-weight-bold text-body-1">
                      {{ $tc('Restaurant.Tables.Dialog.Payment.Title', 0) }}
                    </v-card-title>

                    <v-list
                      flat
                      dense
                      color="transparent"
                    >
                      <v-list-item
                        v-for="(payment, paymentIdx) in item.PaymentMethods"
                        :key="paymentIdx"
                        class="text-left"
                      >
                        <v-list-item-avatar :color="getPaymentTypeLangByCode(payment.Code).Color">
                          <v-icon
                            dark
                            v-text="getPaymentTypeLangByCode(payment.Code).Icon"
                          />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ getPaymentTypeLangByCode(payment.Code).Title }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ payment.Amount }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-tooltip>
              </template>

              <template #[`item.aade`]="{item}">
                <template v-if="item.Mark && appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD">
                  <v-tooltip
                    bottom
                    :color="isDarkMode ? '#191919' : 'grey lighten-5'"
                    class="pa-0"
                  >
                    <template #activator="{on}">
                      <div v-on="on">
                        <v-badge
                          color="transparent"
                          offset-x="6"
                          offset-y="16"
                          overlap
                        >
                          <my-data-logo
                            width="45"
                            img-class="mt-3"
                          />

                          <template #badge>
                            <v-icon
                              color="info"
                              x-small
                            >
                              mdi-information-variant-circle-outline
                            </v-icon>
                          </template>
                        </v-badge>
                      </div>
                    </template>

                    <div
                      class="text-caption pa-2 text-center"
                      :class="isDarkMode ? 'white--text' : 'black--text'"
                    >
                      <my-data-logo
                        width="125"
                        img-class="mb-2"
                      />

                      <div class="font-weight-medium">
                        {{ $t('Order.OrderDetailsDialog.Receipt.Submitted') }}
                      </div>

                      <div class="my-4" />

                      <div class="text-left">
                        <div>{{ $t('Order.OrderDetailsDialog.Receipt.Mark') }}</div>
                        <div class="grey--text">
                          {{ item.Mark }}
                        </div>

                        <div class="my-2" />

                        <div>{{ $t('Order.OrderDetailsDialog.Receipt.Uid') }}</div>
                        <div class="grey--text">
                          {{ item.Uid }}
                        </div>

                        <div class="my-2" />

                        <div>{{ $t('Order.OrderDetailsDialog.Receipt.AuthCode') }}</div>
                        <div class="grey--text">
                          {{ item.AuthenticationCode }}
                        </div>

                        <div class="my-2" />

                        <div>{{ $t('Order.OrderDetailsDialog.Receipt.Provider') }}</div>
                        <div class="grey--text">
                          {{ item.Provider }}
                        </div>
                      </div>
                    </div>
                  </v-tooltip>
                </template>
              </template>

              <template #[`item.status`]="{item}">
                <div>
                  <v-chip
                    label
                    dark
                    x-small
                    style="min-width: 100px;"
                    class="align-center justify-center font-weight-bold mr-5"
                    :color="receiptStatus(item).Color"
                  >
                    {{ receiptStatus(item).Name.toLocaleUpperCase($i18n.locale) }}
                  </v-chip>
                </div>

                <div
                  v-if="item.InvoiceTypeCode === ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER && item.Related && item.Related.Receipt && item.Related.Receipt.InternalId"
                  class="text-no-wrap"
                >
                  <v-chip
                    label
                    dark
                    x-small
                    style="min-width: 100px;"
                    class="align-center justify-center font-weight-bold"
                    color="grey"
                    @click="showReceiptDetailsDialog(item.Related.Receipt.Id)"
                  >
                    {{ item.Related.Receipt.InternalId }}
                  </v-chip>

                  <v-btn
                    v-if="item.Related.Receipt.DocumentUrl"
                    icon
                    x-small
                    color="grey"
                    target="_blank"
                    :href="item.Related.Receipt.DocumentUrl"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </template>

              <template #[`item.related`]="{item}">
                <div
                  v-if="item.Related && item.Related.Canceled && item.Related.Canceled.InternalId"
                  class="text-no-wrap my-1"
                >
                  <v-chip
                    label
                    dark
                    x-small
                    style="min-width: 100px;"
                    class="align-center justify-center font-weight-bold"
                    :color="receiptTypeColor(item.Related.Canceled)"
                    @click="showReceiptDetailsDialog(item.Related.Canceled.Id)"
                  >
                    {{ item.Related.Canceled.InternalId }}
                  </v-chip>

                  <v-btn
                    v-if="item.Related.Canceled.DocumentUrl"
                    icon
                    x-small
                    :color="receiptTypeColor(item.Related.Canceled)"
                    target="_blank"
                    :href="item.Related.Canceled.DocumentUrl"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>

                <div
                  v-if="item.Related && item.Related.CanceledByMark && item.Related.CanceledByMark.InternalId"
                  class="text-no-wrap my-1"
                >
                  <v-chip
                    label
                    dark
                    x-small
                    style="min-width: 100px;"
                    class="align-center justify-center font-weight-bold"
                    :color="receiptTypeColor(item.Related.CanceledByMark)"
                    @click="showReceiptDetailsDialog(item.Related.CanceledByMark.Id)"
                  >
                    {{ item.Related.CanceledByMark.InternalId }}
                  </v-chip>

                  <v-btn
                    v-if="item.Related.CanceledByMark.DocumentUrl"
                    icon
                    x-small
                    :color="receiptTypeColor(item.Related.CanceledByMark)"
                    target="_blank"
                    :href="item.Related.CanceledByMark.DocumentUrl"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>

                <template v-if="item.Related && item.Related.Fnbs && Array.isArray(item.Related.Fnbs)">
                  <template v-for="(fnb, index) in item.Related.Fnbs">
                    <div
                      :key="`fnb-${fnb.Id}-${index}`"
                      class="text-no-wrap my-1"
                    >
                      <v-chip
                        label
                        dark
                        x-small
                        style="min-width: 100px;"
                        class="align-center justify-center font-weight-bold"
                        :color="receiptTypeColor(fnb)"
                        @click="showReceiptDetailsDialog(fnb.Id)"
                      >
                        {{ fnb.InternalId }}
                      </v-chip>

                      <v-btn
                        v-if="fnb.DocumentUrl"
                        icon
                        x-small
                        :color="receiptTypeColor(fnb)"
                        target="_blank"
                        :href="fnb.DocumentUrl"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </template>

                <template v-if="item.Related && item.Related.Receipts && Array.isArray(item.Related.Receipts)">
                  <template v-for="(receipt, index) in item.Related.Receipts">
                    <div
                      :key="`receipt-${receipt.Id}-${index}`"
                      class="text-no-wrap my-1"
                    >
                      <v-chip
                        label
                        dark
                        x-small
                        style="min-width: 100px;"
                        class="align-center justify-center font-weight-bold"
                        :color="receiptTypeColor(receipt)"
                        @click="showReceiptDetailsDialog(receipt.Id)"
                      >
                        {{ receipt.InternalId }}
                      </v-chip>

                      <v-btn
                        v-if="receipt.DocumentUrl"
                        icon
                        x-small
                        :color="receiptTypeColor(receipt)"
                        target="_blank"
                        :href="receipt.DocumentUrl"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </template>
              </template>

              <template #[`item.date`]="{item}">
                <div class="text-no-wrap">
                  <span>{{ item.CreatedAt }}</span>
                </div>
              </template>

              <template #[`item.actions`]="{item}">
                <v-speed-dial
                  v-model="item.fab"
                  direction="left"
                  transition="slide-x-reverse-transition"
                >
                  <template #activator>
                    <v-btn
                      class="blue-grey lighten-2 elevation-2 ma-0"
                      dark
                      icon
                      :small="$vuetify.breakpoint.xsOnly"
                    >
                      <v-icon
                        v-if="item.fab"
                        :small="$vuetify.breakpoint.xsOnly"
                      >
                        close
                      </v-icon>
                      <v-icon
                        v-else
                        :small="$vuetify.breakpoint.xsOnly"
                      >
                        menu
                      </v-icon>
                    </v-btn>
                  </template>

                  <print-receipt-button
                    v-if="userCanPrintReceipt && appMachineHasReceiptPrinters"
                    class="ml-1"
                    :small-size="28"
                    hide-label
                    icon
                    :small="$vuetify.breakpoint.xsOnly"
                    :small-icon="$vuetify.breakpoint.xsOnly"
                    @fiscal-document:print="$bus.$emit('fiscal-document:print', item.Id)"
                  />

                  <email-receipt-button
                    v-if="userCanViewReceipt && appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
                    class="ml-1"
                    :small-size="28"
                    hide-label
                    icon
                    :small="$vuetify.breakpoint.xsOnly"
                    :small-icon="$vuetify.breakpoint.xsOnly"
                    @fiscal-document:email="$bus.$emit('fiscal-document:email', item.Id)"
                  />

                  <template v-if="userInAdminGroup && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD">
                    <v-tooltip
                      :color="$t('Customer.Table.Button.ViewCustomer.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :class="$t('Customer.Table.Button.ViewCustomer.Color')"
                          :small="$vuetify.breakpoint.xsOnly"
                          class="ma-0 ml-1"
                          dark
                          icon
                          @click="showReceiptDetailsDialog(item.Id)"
                          v-on="on"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Customer.Table.Button.ViewCustomer.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('Common.Button.ViewDocument') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="item.DocumentUrl">
                    <v-tooltip
                      color="light-green"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          class="light-green ma-0 ml-1"
                          dark
                          icon
                          target="_blank"
                          :href="item.DocumentUrl"
                          v-on="on"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            mdi-open-in-new
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('Common.Button.ViewDocument') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="canRetryReceipt(item)">
                    <v-tooltip
                      color="orange"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          class="orange ma-0 ml-1"
                          :small="$vuetify.breakpoint.xsOnly"
                          dark
                          icon
                          v-on="on"
                          @click="retryReceipt(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            mdi-cloud-upload-outline
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Common.Button.RetryDocument') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="canCancelReceipt(item)">
                    <v-tooltip
                      :color="$t('Order.OrdersAll.ListView.Button.Delete.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :class="$t('Order.OrdersAll.ListView.Button.Delete.Color')"
                          class="ma-0 ml-1"
                          :small="$vuetify.breakpoint.xsOnly"
                          dark
                          icon
                          v-on="on"
                          @click="cancelReceipt(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Order.OrdersAll.ListView.Button.Cancel.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Order.OrderDetailsDialog.Receipt.Cancel') }}</span>
                    </v-tooltip>
                  </template>
                </v-speed-dial>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <receipt-details-dialog
      v-if="selectedDocument"
      :visible.sync="receiptDetailsDialogVisible"
      :receipt="selectedDocument"
    />
  </div>
</template>

<script>
import Auth                    from '@/mixins/auth'
import AppData                 from '@/mixins/appdata'
import DataTable               from '@/mixins/data-table/dataTable'
import FilterCommon            from '@/mixins/filter/filterCommon'
import FilterSearch            from '@/components/common/filter/FilterSearch'
import FilterOptions           from '@/components/common/filter/FilterOptions'
import PaymentType             from '@/mixins/orders/paymentType'
import EmailReceiptButton      from '@/components/common/receipt/EmailReceiptButton'
import PrintReceiptButton      from '@/components/common/receipt/PrintReceiptButton'
import ReceiptDetailsDialog    from '@/components/receipts/ReceiptDetailsDialog'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import NotifyTypeEnum          from '@/api/Enums/NotifyTypeEnum'
import MyDataLogo              from '@/components/receipts/MyDataLogo.vue'
import ReceiptsCommon          from '@/mixins/receipts/receiptsCommon'
import ReceiptTypeEnum         from '@/api/Enums/ReceiptTypeEnum'
import ReceiptTypeCodeEnum     from '@/api/Enums/ReceiptTypeCodeEnum'

export default {
  name      : 'Receipts',
  components: {
    MyDataLogo,
    ReceiptDetailsDialog,
    PrintReceiptButton,
    EmailReceiptButton,
    FilterSearch,
    FilterOptions
  },
  directives: {},
  mixins    : [AppData, Auth, DataTable, FilterCommon, PaymentType, ReceiptsCommon],
  props     : {
    searchParam: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {
      cancel                     : true,
      selectedDocument           : null,
      receiptDetailsDialogVisible: false,
      filtersModel               : {
        lang         : 'Receipt.Table',
        filterFailure: {
          key  : 'filter_failure',
          value: null,
          items: this.$t('Receipt.Table.Filter.filterFailure.Items')
        },
        filterStatus: {
          key  : 'filter_status',
          value: [],
          items: []
        },
        filterInvoiceType: {
          key  : 'filter_invoice_type',
          value: [],
          items: []
        },
        filterDate: {
          key    : 'filter_date',
          value  : null,
          visible: false,
          date   : null
        }
      },
      dataTable: {
        dataStore    : null,
        socketCommand: {
          list: window.SocketCommand.Receipt.All
        },
        options: {
          defaultSortBy: 'order_receipt_id',
          sortBy       : ['order_receipt_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Receipt.Table.Headers.Id'),
            value    : 'id',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Receipt.Table.Headers.Type'),
            value    : 'type',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Receipt.Table.Headers.Number'),
            value    : 'number',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Receipt.Table.Headers.Total'),
            value    : 'total',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption'
          },
          ...(window.appConfig?.LOCATION_DATA?.ReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD ? [{
            text     : this.$t('Receipt.Table.Headers.Aade'),
            value    : 'aade',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          }] : []),
          {
            text     : this.$t('Receipt.Table.Headers.Status'),
            value    : 'status',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Receipt.Table.Headers.Related'),
            value    : 'related',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Receipt.Table.Headers.Date'),
            value    : 'date',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Receipt.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : '50px'
          }
        ]
      }
    }
  },
  computed: {
    ReceiptTypeCodeEnum () {
      return ReceiptTypeCodeEnum
    },
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },
    ReceiptProviderTypeEnum () {
      return ReceiptProviderTypeEnum
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
    this.$bus.$on(window.SocketCommand.Receipt.Get, this.onReceiptDetailsResponse)
    this.$bus.$on(window.SocketCommand.Receipt.Filters, this.onFiltersDataResponse)
    this.$bus.$on(window.SocketCommand.Ajax.Get, this.updateData)

    this.getFiltersData()

    // Init Custom Filter Values
    this.filtersModel.filterStatus.items = Object.values(this.$t('Receipt.Status'))
    this.filtersModel.filterInvoiceType.items = []
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Receipt.Get, this.onReceiptDetailsResponse)
    this.$bus.$off(window.SocketCommand.Receipt.Filters, this.onFiltersDataResponse)
    this.$bus.$off(window.SocketCommand.Ajax.Get, this.updateData)
  },
  methods: {
    cancelReceipt (item) {
      this.$bus.$emit('fiscal-document:cancel', {
        ...item,
        ReceiptId    : item.Id,
        TableId      : null,
        BroadcastList: true
      }, { refund: false })
    },

    retryReceipt (item) {
      this.$bus.$emit('fiscal-document:retry', item.Id)
    },

    showReceiptDetailsDialog (itemId) {
      if (!this.userInAdminGroup || this.appReceiptsProviderType !== ReceiptProviderTypeEnum.CLOUD) return
      window.callAS(window.SocketCommand.Receipt.Get, { Id: itemId })
    },
    onReceiptDetailsResponse (item) {
      this.selectedDocument = item
      this.receiptDetailsDialogVisible = true
    },

    showOrderDetailsDialog (item) {
      window.callAS(window.SocketCommand.Order.Get, {
        order_id: item.OrderId
      })
    },

    getFiltersData () {
      window.callAS(window.SocketCommand.Receipt.Filters, {})
    },
    onFiltersDataResponse (data) {
      this.filtersModel.filterInvoiceType.items = data?.InvoiceTypes || {}
    },

    updateData (payload) {
      const command = window.SocketCommand.Receipt.All
      const payloadCommand = window.SocketCommand.commandToClientFormat(payload?.command || '')
      if (command === payloadCommand) this.getData()
    }
  }
}
</script>

<style scoped>
.v-tooltip__content.menuable__content__active {
  opacity : 1;
}
</style>
