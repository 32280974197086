<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.TableAreas.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showTableAreaAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.TableAreas.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.TableAreas.Toolbar.Button.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.Tables.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          :to="{name: 'TableManagement'}"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.TableAddDialog.Toolbar.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $tc('Restaurant.Tables.Title', 0) }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.table_area_color`]="{item}">
              <div style="height: 100%; width:5px; max-width:5px; text-align: left;">
                <div
                  v-if="item.Color"
                  :style="'background-color: #' + (item.Color || '4c4c4c') + '; height: 100%'"
                  class="pa-0 ma-0"
                />
              </div>
            </template>

            <template #[`item.table_area_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.table_area_name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showTableAreaEditDialog(item)"
                >
                  {{ item.Name }}
                </a>
              </template>
              <template v-else>
                {{ item.Name }}
              </template>
            </template>

            <template #[`item.tables_count`]="{item}">
              {{ item.TablesCount }}
            </template>

            <template #[`item.table_area_priority`]="{item}">
              {{ item.Priority }}
            </template>

            <template #[`item.table_area_bookable`]="{item}">
              <v-switch
                v-model="item.Bookable"
                inset
                :disabled="!userCanManage"
                :label="item.Bookable ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-0"
                color="indigo"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.table_area_bookable_online`]="{item}">
              <v-switch
                v-model="item.BookableOnline"
                inset
                :disabled="!userCanManage"
                :label="item.BookableOnline ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-0"
                color="blue"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.table_area_status`]="{item}">
              <v-switch
                v-model="item.Status"
                inset
                :disabled="!userCanManage || item.Id === 1"
                :label="item.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-0"
                color="success"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanManage && item.Id > 1"
                class="cyan elevation-2 ma-0"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showTableAreaEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanDelete && item.Id > 1"
                class="red elevation-2 ma-1"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="deleteTableArea(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <table-area-add-dialog
      :item-to-edit="selectedItem"
      :visible.sync="addEditTableAreaDialog"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.TableAreas.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.TableAreas.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.TableAreas.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>
import AppData            from '@/mixins/appdata'
import Auth               from '@/mixins/auth'
import Watchable          from '@/mixins/watchable'
import Translatable       from '@/mixins/translatable'
import ConfirmDialog      from '@/components/common/ConfirmDialog'
import FilterSearch       from '@/components/common/filter/FilterSearch.vue'
import TableAreaAddDialog from './TableAreaAddDialog'
import DataTable          from '@/mixins/data-table/dataTable'

export default {
  name      : 'TableAreasManagement',
  components: {
    FilterSearch,
    'table-area-add-dialog': TableAreaAddDialog,
    'confirm-dialog'       : ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, Watchable, DataTable],
  data () {
    return {
      confirmDialogDeleteVisible: false,
      selectedItem              : null,
      addEditTableAreaDialog    : false,
      dataTable                 : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Table.Area.All,
          save  : window.SocketCommand.Table.Area.Save,
          delete: window.SocketCommand.Table.Area.Delete
        },
        options: {
          defaultSortBy: 'table_area_id',
          sortBy       : ['table_area_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },

        headers: [
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.Color'),
            value    : 'table_area_color',
            align    : 'left',
            cellClass: 'text-caption',
            width    : 5
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.Id'),
            value    : 'table_area_id',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.Name'),
            value    : 'table_area_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.TablesCount'),
            value    : 'tables_count',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.Priority'),
            value    : 'table_area_priority',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.Bookable'),
            value    : 'table_area_bookable',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.BookableOnline'),
            value    : 'table_area_bookable_online',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.Status'),
            value    : 'table_area_status',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableAreas.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 100
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    showTableAreaAddDialog () {
      this.selectedItem = null
      this.addEditTableAreaDialog = true
    },

    showTableAreaEditDialog (item) {
      this.selectedItem = item
      this.addEditTableAreaDialog = true
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.Table.Area.Save, item)
    },

    deleteTableArea (item) {
      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Table.Area.Delete, {
        Id        : this.selectedItem.Id,
        MoveAreaId: 1
      })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.TableAreas.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  width    : 75px !important;
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
