<template>
  <div>
    <v-dialog
      v-if="messageConfig"
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1060"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-badge
            :value="editMessage.Important"
            color="red"
            overlap
            right
          >
            <v-icon
              slot="badge"
              dark
              small
            >
              fas fa-exclamation
            </v-icon>
            <v-avatar
              :color="$t('Message.MessageSendDialog.Toolbar.Color')"
            >
              <v-icon color="indigo lighten-5">
                {{ $t('Message.MessageSendDialog.Toolbar.Icon') }}
              </v-icon>
            </v-avatar>
          </v-badge>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Message.MessageSendDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Message.MessageSendDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="ml-0"
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="messageForm"
            v-model="messageFormValid"
            @submit.prevent
          >
            <v-container
              class="fill-height pa-1"
              fluid
            >
              <v-row dense>
                <v-col
                  sm="5"
                  cols="12"
                >
                  <v-select
                    v-model="editMessage.TempType"
                    :items="groupedTypes"
                    :label="$t('Message.MessageSendDialog.Field.MessageType.Label')"
                    :rules="editMessage.vuetifyFormFieldRules('Type')"
                    filled
                    item-text="GroupName"
                    item-value="Id"
                    return-object
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      <div>
                        <v-avatar
                          :color="data.item.Color"
                          class="mr-1"
                          size="18"
                        >
                          <v-icon
                            :color="data.item.Color + ' lighten-5'"
                            size="10"
                          >
                            {{ data.item.Icon }}
                          </v-icon>
                        </v-avatar>
                        <span class="">{{ data.item.GroupName }} {{ data.item.Subject ? '- ' + data.item.Subject : '' }}</span>
                      </div>
                    </template>

                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <v-list-item-avatar>
                        <v-avatar
                          :color="data.item.Color"
                          size="36"
                        >
                          <v-icon
                            :color="data.item.Color + ' lighten-5'"
                            small
                          >
                            {{ data.item.Icon }}
                          </v-icon>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Subject }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.GroupName }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  sm="5"
                  cols="12"
                >
                  <v-text-field
                    v-model="editMessage.Type.Name"
                    :counter="255"
                    :label="$t('Message.MessageSendDialog.Field.Sender.Label')"
                    :rules="editMessage.vuetifyFormFieldRules('Type.Name')"
                    filled
                  />
                </v-col>

                <v-col
                  sm="2"
                  cols="12"
                >
                  <v-checkbox
                    v-model="editMessage.Important"
                    :label="$t('Message.MessageSendDialog.Field.Important.Label')"
                    :off-icon="$t('Message.MessageSendDialog.Field.Important.IconOff')"
                    :on-icon="$t('Message.MessageSendDialog.Field.Important.IconOn')"
                    class="pa-0 ma-1"
                    color="red"
                    hide-details
                  />
                  <v-checkbox
                    v-model="editMessage.Active"
                    :label="$t('Common.Button.Toggle.Active')"
                    class="pa-0 ma-1"
                    color="success"
                    hide-details
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-menu
                    v-model="scheduledDateDialog"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="computedDateFormattedMomentjs"
                        :label="$t('Message.MessageSendDialog.Field.DateSent.Label')"
                        :prepend-inner-icon="$t('Message.MessageSendDialog.Field.DateSent.Icon')"
                        filled
                        clearable
                        hide-details
                        readonly
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="scheduledDate"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      :min="new Date().toISOString().substr(0, 10)"
                      @input="scheduledDateDialog = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-menu
                    ref="scheduledTimeDialog"
                    v-model="scheduledTimeDialog"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="scheduledTime"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="scheduledTime"
                        :label="$t('Message.MessageSendDialog.Field.HourSent.Label')"
                        :prepend-inner-icon="$t('Message.MessageSendDialog.Field.HourSent.Icon')"
                        filled
                        clearable
                        hide-details
                        readonly
                        v-on="on"
                      />
                    </template>

                    <v-time-picker
                      v-if="scheduledTimeDialog"
                      v-model="scheduledTime"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      :min="scheduledDate === new Date().toISOString().substr(0, 10) ? new Date().toISOString().substr(11, 5) : undefined"
                      format="24hr"
                      @click:minute="$refs.scheduledTimeDialog.save(scheduledTime)"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="editMessage.To"
                    :items="messageConfig.Apps.all()"
                    :label="$t('Message.MessageSendDialog.Field.SentTo.Label')"
                    :placeholder="$t('Message.MessageSendDialog.Field.SentTo.Placeholder')"
                    filled
                    chips
                    clearable
                    hide-details
                    item-text="Name"
                    item-value="Key"
                    multiple
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      <v-chip
                        :value="data.selected"
                        class="chip--select-multi"
                        close
                        @click:close="removeRecipient(data.item)"
                        @input="removeRecipient(data.item)"
                      >
                        <v-avatar
                          color="blue-grey"
                          dark
                        >
                          <span
                            v-if="data.item.Name"
                            class="white--text text-h5"
                          >{{ data.item.Name.charAt(0).toLocaleUpperCase($i18n.locale) }}</span>
                        </v-avatar>
                        {{ data.item.Name }}
                      </v-chip>
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <v-list-item-avatar>
                        <v-avatar
                          color="blue-grey"
                          dark
                          size="32"
                        >
                          <span
                            v-if="data.item.Name"
                            class="white--text text-h5"
                          >{{ data.item.Name.charAt(0).toLocaleUpperCase($i18n.locale) }}</span>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editMessage.Type.Subject"
                    :counter="255"
                    :label="$t('Message.MessageSendDialog.Field.MessageTitle.Label')"
                    :placeholder="$t('Message.MessageSendDialog.Field.MessageTitle.Placeholder')"
                    :rules="editMessage.vuetifyFormFieldRules('Type.Subject')"
                    filled
                  />
                </v-col>

                <v-col cols="12">
                  <vue-editor
                    v-if="editMessage.Type.Editable"
                    ref="editor"
                    v-model="editMessage.Body"
                    :editor-options="editorSettings"
                    :editor-toolbar="editorToolbar"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <source-code-viewer
                    v-if="editMessage.Type.Editable && htmlCodeView"
                    :source="editMessage.Body"
                    @change="onHtmlChange"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-btn
            depressed
            @click="onPreviewButtonClick"
          >
            {{ $t('Common.Button.Preview').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="elevation-0"
            color="light-green"
            dark
            @click="onSendButtonClick"
          >
            {{ $t('Common.Button.Send').toLocaleUpperCase($i18n.locale) }}
            <v-icon right>
              send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <message-details-dialog
      :message="previewMessage"
      :visible.sync="showPreviewMessageDialog"
    />
  </div>
</template>

<script>
import { Quill, VueEditor } from 'vue2-editor'
import { ImageDrop }        from 'quill-image-drop-module'
import ImageResize          from 'quill-image-resize-fix-module'
import MagicUrl             from 'quill-magic-url'
import MessageDetailsDialog from './MessageDetailsDialog'
import MessageModel         from '../../api/Models/messages/MessageModel'
import MessageConfigModel   from '../../api/Models/messages/MessageConfigModel'
import SourceCodeViewer     from '@/components/common/SourceCodeViewer'
import moment               from 'moment'

/* var FontAttributor = Quill.import('attributors/class/font')
  FontAttributor.whitelist = ['roboto', 'sans-serif', 'serif']
  Quill.register(FontAttributor, true) */

const AlignStyle = Quill.import('attributors/style/align')
const BackgroundStyle = Quill.import('attributors/style/background')
const DirectionStyle = Quill.import('attributors/style/direction')
const ColorStyle = Quill.import('attributors/style/color')
const SizeStyle = Quill.import('attributors/style/size')
const BaseImageFormat = Quill.import('formats/image')
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style']

class ImageFormat extends BaseImageFormat {
  static formats (domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      return formats
    }, {})
  }

  format (name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

Quill.register(ImageFormat, true)
Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(ColorStyle, true)
Quill.register(SizeStyle, true)
Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

export default {
  name      : 'MessageSendDialog',
  components: {
    SourceCodeViewer,
    MessageDetailsDialog,
    VueEditor
  },
  mixins: [],
  props : {
    visible: {
      type    : Boolean,
      default : false,
      required: true
    },

    message: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      scheduledDateDialog     : false,
      scheduledTimeDialog     : false,
      scheduledDate           : '',
      scheduledTime           : '',
      previewMessage          : null,
      showPreviewMessageDialog: false,
      messageFormValid        : false,
      htmlCodeView            : false,
      messageConfig           : new MessageConfigModel(),
      editMessage             : new MessageModel(),
      editorToolbar           : [
        // [{ 'font': [] }, { 'size': [] }, { 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ header: '1' }, { header: '2' }, 'blockquote'],
        [{ color: [] }, { background: [] }],
        [{ script: 'super' }, { script: 'sub' }],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }, { indent: '-1' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['omega']
      ],
      editorSettings: {
        debug  : 'error',
        modules: {
          magicUrl   : true,
          imageDrop  : true,
          imageResize: {}
        }
      }
    }
  },
  computed: {
    groupedTypes () {
      return this.messageConfig.Types.groupedTypesForSelect()
    },
    computedDateFormattedMomentjs: {
      get () {
        return this.scheduledDate ? moment(this.scheduledDate).format('DD/MM/YYYY') : ''
      },
      set (val) {
        this.scheduledDate = val || ''
      }
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.scheduledDateDialog = false
        this.scheduledTimeDialog = false
        this.scheduledDate = ''
        this.scheduledTime = ''
        this.previewMessage = null
        this.showPreviewMessageDialog = false
        this.messageFormValid = false
        this.htmlCodeView = false
        this.htmlCodeView = false

        if (this.message) {
          this.editMessage = new MessageModel(this.message)
        } else {
          this.editMessage = new MessageModel()
        }
      }
    },
    'editMessage.Type.Editable': function (val) {
      this.$nextTick(() => {
        const customButton = document.querySelector('.ql-omega')
        if (!customButton) return

        if (val) {
          customButton.removeEventListener('click', this.toggleHtmlCodeView)
          customButton.addEventListener('click', this.toggleHtmlCodeView)
        } else {
          customButton.removeEventListener('click', this.toggleHtmlCodeView)
        }
      })
    }
  },
  created () {
    this.messageConfig.fetch()
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    onSendButtonClick () {
      const d = new Date()
      const dateNowStr = `${ d.getFullYear() }-${ d.getMonth() + 1 }-${ d.getDate() }`
      const timeNowStr = `${ d.getHours() }:${ d.getMinutes() }`
      const payload = this.editMessage.clone()

      if (this.scheduledDate) {
        payload.Scheduled = `${ this.scheduledDate } ${ this.scheduledTime || timeNowStr }:00`
      }

      if (this.scheduledTime) {
        payload.Scheduled = `${ this.scheduledDate || dateNowStr } ${ this.scheduledTime }:00`
      }

      if (this.$refs.messageForm.validate()) {
        this.editMessage.save({ Message: payload })

        this.onCancelButtonClick()
      }
    },
    onPreviewButtonClick () {
      this.previewMessage = this.editMessage.clone()
      this.showPreviewMessageDialog = true
    },
    onCancelButtonClick () {
      if (this.$refs.messageForm) this.$refs.messageForm.resetValidation()
      this.editMessage = new MessageModel()
      this.scheduledDate = ''
      this.scheduledTime = ''
      this.isVisible = false
    },
    removeRecipient (item) {
      const index = this.editMessage.To.indexOf(item.Key)
      if (index >= 0) this.editMessage.To.splice(index, 1)
    },
    toggleHtmlCodeView () {
      this.htmlCodeView = !this.htmlCodeView
      // this.$refs.editor.handleInitialContent()
    },
    onHtmlChange (html) {
      this.editMessage.Body = html
    }
  }
}
</script>

<style scoped>
/deep/ .ql-omega:after {
  content : "<>";
}
</style>
