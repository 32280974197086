import { clone }    from '@/lib/helper/helper'
import { EventBus } from '@/events/eventBus'

export const updatePosMenuItem = (item) => {
  const menu = getPosMenu()
  const items = menu?.items || []

  items.forEach(category => {
    const index = category.Items.findIndex(menuItem => menuItem.Id === item.Id)
    if (index > -1) category.Items[index] = item
  })

  setPosMenu(menu)
}

export const deletePosMenuItem = (id) => {
  const menu = getPosMenu()
  const items = menu?.items || []

  items.forEach(category => {
    const index = category.Items.findIndex(menuItem => menuItem.Id === id)
    if (index > -1) category.Items.splice(index, 1)
  })

  setPosMenu(menu)
}

export const deletePosMenuItems = (data) => {
  if (Array.isArray(data?.menus) && data?.menus?.length) {
    data.menus.forEach(menu => {
      deletePosMenuItem(menu.id)
    })
  }
}

export const decrementPosMenuItemQuantityById = (id, value = 1) => {
  const menu = getPosMenu()
  const items = menu?.items || []
  items.forEach(category => {
    const index = category.Items.findIndex(menuItem => menuItem.Id === id)
    if (index > -1) {
      category.Items[index].Quantity -= value
      category.Items[index].DetailsData.StockQuantity -= value
    }
  })

  setPosMenu(menu)
}
export const decrementPosMenuItemsQuantityById = (data) => {
  const menus = data?.menus || []

  if (Array.isArray(menus) && menus?.length) {
    menus.forEach(menu => {
      decrementPosMenuItemQuantityById(menu.id, menu.value)
    })
  }
}

export const incrementPosMenuItemQuantityById = (id, value = 1) => {
  const menu = getPosMenu()
  const items = menu?.items || []

  items.forEach(category => {
    const index = category.Items.findIndex(menuItem => menuItem.Id === id)
    if (index > -1) {
      category.Items[index].Quantity += value
      category.Items[index].DetailsData.StockQuantity += value
    }
  })

  setPosMenu(menu)
}
export const incrementPosMenuItemsQuantityById = (data) => {
  const menus = data?.menus || []

  if (Array.isArray(menus) && menus?.length) {
    menus.forEach(menu => {
      incrementPosMenuItemQuantityById(menu.id, menu.value)
    })
  }
}

export const getPosMenu = () => {
  return clone(window.posMenu)
}

export const setPosMenu = (menu) => {
  window.posMenu = menu
  EventBus.$emit(window.SocketCommand.Menu.PosUpdate)
}
