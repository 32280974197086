<template>
  <v-tabs
    dark
    grow
    show-arrows
    background-color="secondary"
    slider-color="light-green"
    class="rounded-0"
  >
    <v-tab
      v-if="posUserHasRoutePermission('/orders/today') && posUserHasComponentPermission('Orders', 'ACCESS') && userCanViewDailyOrders"
      active-class="secondary darken-1 v-tabs__item--active"
      ripple
      to="/orders/today"
    >
      <v-badge
        v-model="todayOrdersVisible"
        color="red"
        right
      >
        <span slot="badge">
          {{ todayOrdersCount }}
        </span>
        <span>{{ $t('Order.OrdersToolbar.Today').toLocaleUpperCase($i18n.locale) }}</span>
      </v-badge>
    </v-tab>

    <v-tab
      v-if="posUserHasRoutePermission('/orders/saved') && posUserHasComponentPermission('Orders', 'ACCESS') && posUserHasComponentPermission('Pos', 'ACCESS') && userCanViewSavedOrders"
      active-class="secondary darken-1 v-tabs__item--active"
      ripple
      to="/orders/saved"
    >
      <span>{{ $t('Order.OrdersToolbar.Saved').toLocaleUpperCase($i18n.locale) }}</span>
    </v-tab>

    <v-tab
      v-if="posUserHasRoutePermission('/orders/all') && posUserHasComponentPermission('Orders', 'ACCESS') && userCanViewAllOrders"
      active-class="secondary darken-1 v-tabs__item--active"
      ripple
      to="/orders/all"
    >
      <span>{{ $t('Order.OrdersToolbar.All').toLocaleUpperCase($i18n.locale) }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
import AppData from '../../mixins/appdata'
import Auth    from '../../mixins/auth'

export default {
  mixins: [AppData, Auth],
  data () {
    return {}
  },
  computed: {
    todayOrdersCount () {
      return window.Vue.ordersCounts.daily_count
    },
    todayOrdersVisible: {
      get () {
        return this.todayOrdersCount > 0
      },
      set () {

      }
    },
    savedOrdersCount () {
      return window.Vue.ordersCounts.saved_count
    },
    savedOrdersVisible: {
      get () {
        return this.savedOrdersCount > 0
      },
      set () {

      }
    },
    futureOrdersCount () {
      return window.Vue.ordersCounts.future_count
    },
    futureOrdersVisible: {
      get () {
        return this.futureOrdersCount > 0
      },
      set () {

      }
    }
  }
}
</script>
