<template>
  <div class="fill-height">
    <component
      :is="viewComponent"
      :view.sync="toggleView"
    />

    <confirm-dialog
      :html-content="$t('Reservation.Table.ConfirmDialog.Delete.Content', {code: selectedItem ? selectedItem.ReserveCode : ''})"
      :html-title="$t('Reservation.Table.ConfirmDialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Reservation.Table.ConfirmDialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </div>
</template>

<script>
import AppData                      from '@/mixins/appdata'
import Auth                         from '@/mixins/auth'
import TableReservationTableView    from '@/components/restaurant/reservations/TableReservationTableView'
import TableReservationCalendarView from '@/components/restaurant/reservations/TableReservationCalendarView'
import { AES }                      from '@/lib/crypto/crypto'
import ReservationStatusEnum        from '@/api/Enums/ReservationStatusEnum'
import ConfirmDialog                from '@/components/common/ConfirmDialog.vue'

const storageKey = 'reservationView'

export default {
  name      : 'TableReservation',
  components: {
    ConfirmDialog,
    TableReservationTableView,
    TableReservationCalendarView
  },
  directives: {},
  mixins    : [AppData, Auth],
  props     : {},
  data () {
    return {
      confirmDialogDeleteVisible: false,
      selectedItem              : null,
      toggleView                : 'Table'
    }
  },
  computed: {
    viewComponent () {
      return `TableReservation${ this.toggleView }View`
    }
  },
  watch: {
    toggleView (newVal) {
      this.$localStorage.set(storageKey, AES.encrypt(newVal))
    }
  },
  beforeCreate () {},
  created () {
    if (this.$localStorage.hasKey(storageKey)) {
      this.toggleView = AES.decrypt(this.$localStorage.get(storageKey))
    } else {
      this.$localStorage.set(storageKey, AES.encrypt(this.toggleView))
    }

    this.$bus.$on('click:table-reservation-finished', this.handleTableReservationFinishedClick)
    this.$bus.$on('click:table-reservation-arrived', this.handleTableReservationArrivedClick)
    this.$bus.$on('click:table-reservation-partially-arrived', this.handleTableReservationPartiallyArrivedClick)
    this.$bus.$on('click:table-reservation-edit', this.handleTableReservationEditClick)
    this.$bus.$on('click:table-reservation-delete', this.handleTableReservationDeleteClick)
    this.$bus.$on('click:table-reservation-cancel', this.handleTableReservationCancelClick)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('click:table-reservation-finished', this.handleTableReservationFinishedClick)
    this.$bus.$off('click:table-reservation-arrived', this.handleTableReservationArrivedClick)
    this.$bus.$off('click:table-reservation-partially-arrived', this.handleTableReservationPartiallyArrivedClick)
    this.$bus.$off('click:table-reservation-edit', this.handleTableReservationEditClick)
    this.$bus.$off('click:table-reservation-delete', this.handleTableReservationDeleteClick)
    this.$bus.$off('click:table-reservation-cancel', this.handleTableReservationCancelClick)
  },
  destroyed () {},
  methods: {
    handleTableReservationFinishedClick (reservation) {
      if (!reservation) return

      window.callAS(window.SocketCommand.Table.Reservation.Status, {
        Id      : reservation.Id,
        StatusId: ReservationStatusEnum.Finished
      })
    },

    handleTableReservationArrivedClick (reservation) {
      if (!reservation) return

      window.callAS(window.SocketCommand.Table.Reservation.Status, {
        Id      : reservation.Id,
        StatusId: ReservationStatusEnum.Arrived
      })
    },

    handleTableReservationPartiallyArrivedClick (reservation) {
      if (!reservation) return

      window.callAS(window.SocketCommand.Table.Reservation.Status, {
        Id      : reservation.Id,
        StatusId: ReservationStatusEnum.PartiallyArrived
      })
    },

    handleTableReservationEditClick (reservation) {
      if (!reservation) return
      this.$bus.$emit('show-table-reservation-dialog', { Reservation: reservation })
    },

    handleTableReservationCancelClick (reservation) {
      if (!reservation) return

      window.callAS(window.SocketCommand.Table.Reservation.Cancel, { Id: reservation.Id })
    },

    handleTableReservationDeleteClick (reservation) {
      if (!reservation) return

      this.selectedItem = reservation
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      if (!this.selectedItem) return

      window.callAS(window.SocketCommand.Table.Reservation.Delete, { Id: this.selectedItem.Id })
    }
  }
}
</script>

<style scoped>

</style>
