<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Staff.StaffAddDialog.Add.Color')"
          >
            <v-icon dark>
              {{ $t('Staff.StaffAddDialog.Add.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!staffToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Staff.StaffAddDialog.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Staff.StaffAddDialog.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Staff.StaffAddDialog.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Staff.StaffAddDialog.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-tabs
          v-if="staffToEdit && userIsSuperAdmin"
          v-model="activeTab"
          :scrollable="false"
          background-color="indigo"
          dark
          slider-color="light-green"
        >
          <v-tab
            key="tab1"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab1"
          >
            {{ $t('Staff.StaffAddDialog.Tab.Info').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="tab2"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab2"
          >
            {{ $t('Staff.StaffAddDialog.Tab.Permissions').toLocaleUpperCase($i18n.locale) }}
          </v-tab>
        </v-tabs>

        <v-card-text class="pa-0">
          <v-form
            ref="staffForm"
            v-model="staffFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="activeTab">
              <v-tab-item
                key="tab1"
                value="tab1"
              >
                <v-container
                  fluid
                >
                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editStaff.Firstname"
                        :counter="32"
                        :hint="$t('Staff.StaffAddDialog.Field.Firstname.Hint')"
                        :label="$t('Staff.StaffAddDialog.Field.Firstname.Label')"
                        :maxlength="32"
                        :prepend-icon="$t('Staff.StaffAddDialog.Field.Firstname.Icon')"
                        :rules="validationRules.staffForm.Firstname"
                        class="pr-2"
                        required
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editStaff.Lastname"
                        :counter="32"
                        :hint="$t('Staff.StaffAddDialog.Field.Lastname.Hint')"
                        :label="$t('Staff.StaffAddDialog.Field.Lastname.Label')"
                        :maxlength="32"
                        :prepend-icon="$t('Staff.StaffAddDialog.Field.Lastname.Icon')"
                        :rules="validationRules.staffForm.Lastname"
                        class="pr-2"
                        required
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editStaff.Telephone"
                        v-mask="'################'"
                        :hint="$t('Staff.StaffAddDialog.Field.Telephone.Hint')"
                        :label="$t('Staff.StaffAddDialog.Field.Telephone.Label')"
                        :prepend-icon="$t('Staff.StaffAddDialog.Field.Telephone.Icon')"
                        :rules="validationRules.staffForm.Telephone"
                        class="pr-2"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editStaff.Email"
                        :counter="96"
                        :hint="$t('Staff.StaffAddDialog.Field.Email.Hint')"
                        :label="$t('Staff.StaffAddDialog.Field.Email.Label')"
                        :prepend-icon="$t('Staff.StaffAddDialog.Field.Email.Icon')"
                        :rules="editStaff.GroupId === '102' ? validationRules.staffForm.EmailWaiter : validationRules.staffForm.Email"
                        class="pr-2 d-inline-flex"
                        style="width: 92%;"
                      />

                      <v-btn
                        :disabled="!editStaff.Email"
                        class="ma-0"
                        icon
                        small
                        @click="copyToClipboard(editStaff.Email)"
                      >
                        <v-icon small>
                          far fa-copy
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="editStaff.GroupId"
                        :disabled="!userCanManage || (!userIsSuperAdmin && (parseInt(editStaff.GroupId) === 17) ? parseInt(posUser.staff_id) === parseInt(editStaff.Id) : false)"
                        :hint="$t('Staff.StaffAddDialog.Field.GroupId.Hint')"
                        :items="groupsList"
                        :label="$t('Staff.StaffAddDialog.Field.GroupId.Label')"
                        :prepend-icon="$t('Staff.StaffAddDialog.Field.GroupId.Icon')"
                        class="pr-2"
                        item-text="Name"
                        item-value="Id"
                        required
                        single-line
                        @change="resetPermissions"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editStaff.Pin"
                        v-mask="'####'"
                        :append-icon="!pinVisible ? 'visibility' : 'visibility_off'"
                        :counter="4"
                        :disabled="!userCanManage"
                        :hint="$t('Staff.StaffAddDialog.Field.Pin.Hint')"
                        :label="$t('Staff.StaffAddDialog.Field.Pin.Label')"
                        :prepend-icon="$t('Staff.StaffAddDialog.Field.Pin.Icon')"
                        :rules="validationRules.staffForm.Pin"
                        :type="!pinVisible ? 'password' : 'text'"
                        class="pr-2"
                        maxlength="4"
                        required
                        @click:append="(pinVisible = !pinVisible)"
                      >
                        <template #append-outer>
                          <v-btn
                            :disabled="!editStaff.Pin"
                            class="ma-0"
                            icon
                            small
                            @click="copyToClipboard(editStaff.Pin)"
                          >
                            <v-icon small>
                              far fa-copy
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="editStaff.Locale"
                        :hint="$t('Staff.StaffAddDialog.Field.Locale.Hint')"
                        :items="$i18n.languages"
                        :label="$t('Staff.StaffAddDialog.Field.Locale.Label')"
                        :prepend-icon="$t('Staff.StaffAddDialog.Field.Locale.Icon')"
                        class="pr-2"
                        item-text="name"
                        item-value="locale"
                        required
                        single-line
                      />
                    </v-col>

                    <template v-if="userIsSuperAdmin">
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-autocomplete
                          v-model="editStaff.Domain"
                          :filter="filterDomain"
                          :items="domains"
                          :label="$t('Staff.StaffAddDialog.Field.Domain.Label')"
                          :prepend-icon="$t('Staff.StaffAddDialog.Field.Domain.Icon')"
                          class="pr-2"
                          hide-details
                          item-text="Domain"
                          item-value="Domain"
                          required
                        >
                          <template #selection="data">
                            <div>
                              <div>{{ data.item.Domain }}</div>
                              <div
                                :class="data.item.CNameActive ? 'green--text' : 'grey--text'"
                                class="text-caption"
                              >
                                {{ data.item.CName }}
                              </div>
                            </div>
                          </template>

                          <template #item="data">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ data.item.Domain }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                :class="data.item.CNameActive ? 'green--text' : 'grey--text'"
                                class="text-caption"
                              >
                                {{ data.item.CName }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editStaff.Group"
                          :label="$t('Staff.StaffAddDialog.Field.Group.Label')"
                          :prepend-icon="$t('Staff.StaffAddDialog.Field.Group.Icon')"
                          class="pr-2"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editStaff.VoipDomain"
                          :label="$t('Staff.StaffAddDialog.Field.VoipDomain.Label')"
                          :prepend-icon="$t('Staff.StaffAddDialog.Field.VoipDomain.Icon')"
                          class="pr-2"
                          disabled
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editStaff.VoipAgent"
                          :label="$t('Staff.StaffAddDialog.Field.VoipAgent.Label')"
                          :prepend-icon="$t('Staff.StaffAddDialog.Field.VoipAgent.Icon')"
                          class="pr-2"
                          required
                        />
                      </v-col>
                    </template>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-switch
                        v-model="editStaff.Status"
                        :disabled="!userCanDelete || (!userIsSuperAdmin && (parseInt(editStaff.GroupId) === 17) ? parseInt(posUser.staff_id) === parseInt(editStaff.Id) : false)"
                        :label="editStaff.Status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="mt-1"
                        color="success"
                        inset
                      >
                        <span
                          slot="prepend"
                          class="mt-1 grey--text text-no-wrap"
                        >{{ $t('Staff.StaffAddDialog.Field.Status.Title') }}</span>
                      </v-switch>

                      <v-switch
                        v-model="editStaff.Guest"
                        :disabled="!userCanManage"
                        :label="editStaff.Guest ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="mt-0"
                        color="orange"
                        inset
                      >
                        <span
                          slot="prepend"
                          class="mt-1 grey--text text-no-wrap"
                        >{{ $t('Staff.StaffAddDialog.Field.Guest.Title') }}</span>
                      </v-switch>

                      <v-switch
                        v-model="editStaff.SingleLogin"
                        :disabled="!userCanManage"
                        :label="editStaff.SingleLogin ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="mt-0"
                        color="purple"
                        inset
                      >
                        <span
                          slot="prepend"
                          class="mt-1 grey--text text-no-wrap"
                        >
                          Single Login
                        </span>
                      </v-switch>
                    </v-col>

                    <v-col
                      v-if="userIsSuperAdmin"
                      cols="12"
                      sm="6"
                    >
                      <v-switch
                        v-model="updatePermissions"
                        :disabled="!userCanManage"
                        :label="updatePermissions ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="mt-0"
                        color="red"
                        inset
                      >
                        <span
                          slot="prepend"
                          class="mt-1 grey--text text-no-wrap"
                        >
                          Update Permissions
                        </span>
                      </v-switch>

                      <v-switch
                        v-model="clearPermissions"
                        :disabled="!userCanManage"
                        :label="clearPermissions ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="mt-0"
                        color="red"
                        inset
                      >
                        <span
                          slot="prepend"
                          class="mt-1 grey--text text-no-wrap"
                        >
                          Clear Permissions
                        </span>
                      </v-switch>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-card flat>
                        <v-card-title :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']">
                          <v-icon class="pr-3">
                            {{ $t('Settings.Tables.Orders.Icon') }}
                          </v-icon>
                          <h5 class="text-body-2 font-weight-bold">
                            {{ $t('Settings.Tables.Orders.Title') }}
                          </h5>
                        </v-card-title>

                        <v-divider />

                        <v-card-text>
                          <v-container
                            class="pa-0"
                            fluid
                          >
                            <v-row align="center">
                              <v-col>
                                <h5>{{ $t('Settings.Tables.Orders.Permission.Daily.Title') }}</h5>
                                <span class="text-caption">{{
                                  $t('Settings.Tables.Orders.Permission.Daily.Subtitle')
                                }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="editStaff.Settings.Orders.Permissions.Daily"
                                  :disabled="!userCanManage"
                                  :label="editStaff.Settings.Orders.Permissions.Daily ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="mt-0 pt-0"
                                  color="success"
                                  hide-details
                                  inset
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-select
                                  v-model="editStaff.Settings.Orders.VisibleStatuses"
                                  :hint="$t('Settings.Tables.Orders.VisibleStatuses.Subtitle')"
                                  :items="$t('Order.OrdersAll.Filter.filterOrderStatus.Items').filter(o => o.Id > 0)"
                                  :label="$t('Settings.Tables.Orders.VisibleStatuses.Title')"
                                  :rules="validationRules.staffForm.TableAreas"
                                  item-text="Name"
                                  item-value="Id"
                                  multiple
                                  persistent-hint
                                >
                                  <template #selection="{item}">
                                    <v-chip
                                      :color="`${item.Avatar.Color}`"
                                      class="px-1"
                                      dark
                                      label
                                      small
                                    >
                                      <v-icon
                                        class="mr-1"
                                        small
                                      >
                                        {{ item.Avatar.Icon }}
                                      </v-icon>
                                      <span>{{ item.Name }}</span>
                                    </v-chip>
                                  </template>

                                  <template #item="data">
                                    <v-list-item-avatar
                                      :color="`${data.item.Avatar.Color}`"
                                      :size="28"
                                      dark
                                    >
                                      <v-icon
                                        color="white"
                                        small
                                      >
                                        {{ data.item.Avatar.Icon }}
                                      </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          data.item.Description | truncate
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-select>
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="" />
                              </v-col>

                              <v-col>
                                <h5>{{ $t('Settings.Tables.Orders.Permission.Saved.Title') }}</h5>
                                <span class="text-caption">{{
                                  $t('Settings.Tables.Orders.Permission.Saved.Subtitle')
                                }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="editStaff.Settings.Orders.Permissions.Saved"
                                  :disabled="!userCanManage"
                                  :label="editStaff.Settings.Orders.Permissions.Saved ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="mt-0 pt-0"
                                  color="success"
                                  hide-details
                                  inset
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="" />
                              </v-col>

                              <v-col>
                                <h5>{{ $t('Settings.Tables.Orders.Permission.All.Title') }}</h5>
                                <span class="text-caption">{{
                                  $t('Settings.Tables.Orders.Permission.All.Subtitle')
                                }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="editStaff.Settings.Orders.Permissions.All"
                                  :disabled="!userCanManage"
                                  :label="editStaff.Settings.Orders.Permissions.All ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="mt-0 pt-0"
                                  color="success"
                                  hide-details
                                  inset
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="" />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card flat>
                        <v-card-title :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']">
                          <v-icon class="pr-3">
                            lock
                          </v-icon>
                          <h5 class="text-body-2 font-weight-bold">
                            {{ $t('Settings.PinManager.Enabled.Title') }}
                          </h5>
                        </v-card-title>

                        <v-divider />

                        <v-card-text>
                          <v-container
                            class="pa-0"
                            fluid
                          >
                            <v-row align="center">
                              <v-col>
                                <h5>{{ $t('Settings.Tables.Orders.Permission.ExcludeFromPin.Title') }}</h5>
                                <span class="text-caption">{{
                                  $t('Settings.Tables.Orders.Permission.ExcludeFromPin.Subtitle')
                                }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="editStaff.Settings.ExcludeFromPin"
                                  :disabled="!userCanManage"
                                  :label="editStaff.Settings.ExcludeFromPin ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="mt-0 pt-0"
                                  color="success"
                                  hide-details
                                  inset
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="" />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="staffToEdit && userIsSuperAdmin"
                key="tab2"
                value="tab2"
              >
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <v-data-table
                        :footer-props="dataTable.footerProps"
                        :headers="headers"
                        :height="dataTable.resize.bodyHeight"
                        :items="editStaff.Permissions"
                        :options.sync="dataTable.options"
                        class="elevation-0"
                        fixed-header
                        hide-default-footer
                      >
                        <template #[`item.Id`]="{item}">
                          <v-chip label>
                            {{ item.Id }}
                          </v-chip>
                        </template>

                        <template #[`item.Permissions`]="{item}">
                          <span :class="{'caption': $vuetify.breakpoint.xsOnly}">{{ item.Permission }}</span>
                        </template>

                        <template
                          v-if="userIsSuperAdmin"
                          #[`item.Api`]="{item}"
                        >
                          <v-checkbox
                            v-if="!!item.Action.find(p => (p === 'api'))"
                            v-model="item.UserPermissions"
                            :color="getPermissionColor(item, 'api')"
                            hide-details
                            primary
                            style="display: inline-block"
                            value="api"
                            @change="onCheckboxChange('api', item)"
                          />
                        </template>

                        <template #[`item.Access`]="{item}">
                          <v-checkbox
                            v-if="!!item.Action.find(p => (p === 'access'))"
                            v-model="item.UserPermissions"
                            :color="getPermissionColor(item, 'access')"
                            :disabled="!userIsSuperAdmin || !userCanManage"
                            hide-details
                            primary
                            style="display: inline-block"
                            value="access"
                            @change="onCheckboxChange('access', item)"
                          />
                        </template>

                        <template #[`item.Manage`]="{item}">
                          <v-checkbox
                            v-if="!!item.Action.find(p => (p === 'manage'))"
                            v-model="item.UserPermissions"
                            :color="getPermissionColor(item, 'manage')"
                            :disabled="!userIsSuperAdmin || !userCanManage"
                            hide-details
                            primary
                            style="display: inline-block"
                            value="manage"
                            @change="onCheckboxChange('manage', item)"
                          />
                        </template>

                        <template #[`item.Add`]="{item}">
                          <v-checkbox
                            v-if="!!item.Action.find(p => (p === 'add'))"
                            v-model="item.UserPermissions"
                            :color="getPermissionColor(item, 'add')"
                            :disabled="!userIsSuperAdmin || !userCanManage"
                            hide-details
                            primary
                            style="display: inline-block"
                            value="add"
                            @change="onCheckboxChange('add', item)"
                          />
                        </template>

                        <template #[`item.Delete`]="{item}">
                          <td class="text-center">
                            <v-checkbox
                              v-if="!!item.Action.find(p => (p === 'delete'))"
                              v-model="item.UserPermissions"
                              :color="getPermissionColor(item, 'delete')"
                              :disabled="!userIsSuperAdmin || !userCanManage"
                              hide-details
                              primary
                              style="display: inline-block"
                              value="delete"
                              @change="onCheckboxChange('delete', item)"
                            />
                          </td>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-btn
            v-if="activeTab === 'tab2'"
            class="light-blue--text text--darken-1"
            outlined
            @click="resetPermissions"
          >
            {{ $t('Common.Button.Reset').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData         from '@/mixins/appdata'
import Auth            from '@/mixins/auth'
import DataTable       from '@/mixins/data-table/dataTable'
import OrderStatusEnum from '@/api/Enums/OrderStatusEnum'

export default {
  components: {},
  mixins    : [AppData, Auth, DataTable],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    staffToEdit: {
      type   : Object,
      default: undefined
    },
    groups: {
      type   : Array,
      default: () => []
    },
    domains: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      activeTab        : 'tab1',
      loading          : false,
      pinVisible       : false,
      staffFormValid   : false,
      newStaff         : null,
      clearPermissions : false,
      updatePermissions: false,
      dataTable        : {
        resize: {
          offset: () => this.$vuetify.breakpoint.smAndUp ? 98 : 80 + 68
        },
        options: {
          itemsPerPage     : -1,
          mustSort         : false,
          serverSideEnabled: false
        }
      },
      tmpStaff: {
        Domain     : '',
        Email      : '',
        Firstname  : '',
        Group      : 'gfp',
        GroupId    : '15',
        GroupName  : '',
        Guest      : false,
        SingleLogin: true,
        Id         : null,
        Lastname   : '',
        Pin        : '',
        Status     : true,
        Telephone  : '',
        VoipAgent  : '',
        VoipDomain : '',
        Locale     : 'el',
        Permissions: [],
        Settings   : {
          ExcludeFromPin: false,
          Tables        : {
            Waiter: {
              Permissions: {
                WaiterCanManagePos            : false,
                WaiterCanChangeTableWaiter    : false,
                WaiterCanManageAllTables      : false,
                WaiterCanSetCustomPrice       : false,
                WaiterCanChangeExternalReceipt: false
              }
            }
          },
          Orders: {
            Permissions: {
              Daily: true,
              Saved: true,
              All  : true
            },
            VisibleStatuses: [OrderStatusEnum.Received, OrderStatusEnum.Accepted, OrderStatusEnum.Preparation, OrderStatusEnum.Delivery, OrderStatusEnum.Completed, OrderStatusEnum.Canceled]
          }
        }
      },
      validationRules: {
        staffForm: {
          Firstname: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Staff.StaffAddDialog.Field.Firstname.Error.Between')
          ],
          Lastname: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Staff.StaffAddDialog.Field.Lastname.Error.Between')
          ],
          Telephone: [
            (v) => !v || (v.length >= 8 && v.length <= 16 && !isNaN(v)) || this.$t('Staff.StaffAddDialog.Field.Telephone.Error.Between')
          ],
          Pin: [
            (v) => v && v.length === 4 && !isNaN(v) || this.$t('Staff.StaffAddDialog.Field.Pin.Error.FixedNumber')
          ],
          Email: [
            (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Staff.StaffAddDialog.Field.Email.Error.Between'),
            (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Staff.StaffAddDialog.Field.Email.Error.Valid')
          ],
          EmailWaiter: [
            (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Staff.StaffAddDialog.Field.Email.Error.Between')
          ]
        }
      }
    }
  },
  computed: {
    groupsList () {
      return this.groups?.filter(group => parseInt(group.Id) > 1) || []
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editStaff: {
      get () {
        if (this.staffToEdit) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.newStaff = JSON.parse(JSON.stringify(this.staffToEdit))
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.pinVisible = true
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.newStaff = JSON.parse(JSON.stringify(this.tmpStaff))
        }
        return this.newStaff
      }
    },

    headers () {
      if (this.userIsSuperAdmin) {
        return [
          {
            text    : '#',
            align   : 'center',
            value   : 'Id',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Right'),
            align   : 'left',
            value   : 'Permissions',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Api'),
            align   : 'center',
            value   : 'Api',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Access'),
            align   : 'center',
            value   : 'Access',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Edit'),
            align   : 'center',
            value   : 'Manage',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Add'),
            align   : 'center',
            value   : 'Add',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Delete'),
            align   : 'center',
            value   : 'Delete',
            sortable: false
          }
        ]
      }

      return [
        {
          text    : '#',
          align   : 'center',
          value   : 'Id',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Right'),
          align   : 'left',
          value   : 'Permissions',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Access'),
          align   : 'center',
          value   : 'Access',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Edit'),
          align   : 'center',
          value   : 'Manage',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Add'),
          align   : 'center',
          value   : 'Add',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Delete'),
          align   : 'center',
          value   : 'Delete',
          sortable: false
        }
      ]
    }
  },
  watch: {
    'editStaff.Domain': function (newVal) {
      const domain = this.domains?.find(d => d.Domain === newVal)
      if (domain && domain.VoipDomain) {
        this.editStaff.VoipDomain = domain.VoipDomain
      } else {
        this.editStaff.VoipDomain = ''
      }
    },

    isVisible (newVal) {
      if (newVal) {
        this.activeTab = 'tab1'
        this.pinVisible = false
        this.clearPermissions = false
        this.updatePermissions = false
        this.$bus.$on(window.SocketCommand.Staff.Save, this.onStaffSaveResult)
        this.$nextTick(() => {
          if (this.$refs.staffForm) this.$refs.staffForm.resetValidation()
        })
      } else {
        this.$bus.$off(window.SocketCommand.Staff.Save, this.onStaffSaveResult)
      }
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    resetPermissions () {
      const groupPermissions = this.groups.find(group => group.Id === this.editStaff.GroupId)?.Permissions?.map(group => {
        return {
          Action         : group.Action,
          UserPermissions: group.GroupPermissions,
          Id             : group.Id,
          Name           : group.Name,
          Permission     : group.Permission
        }
      })

      if (groupPermissions) {
        this.editStaff.Permissions = JSON.parse(JSON.stringify(groupPermissions))
      }
    },

    onCheckboxChange (type, permission) {
    },

    getPermissionColor (item, permission, isText = false) {
      permission = permission.toUpperCase()

      switch (permission) {
        case 'API':
          return 'purple' + (isText ? '--text' : '')
        case 'ACCESS':
          return 'green' + (isText ? '--text' : '')
        case 'MANAGE':
          return 'blue' + (isText ? '--text' : '')
        case 'ADD':
          return 'amber' + (isText ? '--text darken-3' : ' darken-3')
        case 'DELETE':
          return 'red' + (isText ? '--text' : '')
        default:
          return 'grey' + (isText ? '--text' : '')
      }
    },

    filterDomain (item, queryText, itemText) {
      const domain = item.Domain?.toLowerCase() || ''
      const cname = item.CName?.toLowerCase() || ''
      const searchText = queryText.toLowerCase()

      return domain.includes(searchText) || cname.includes(searchText)
    },

    onSaveButtonClick () {
      this.$refs.staffForm.validate()

      if (this.staffFormValid) {
        this.loading = true
        this.editStaff.Name = this.editStaff.Firstname + ' ' + this.editStaff.Lastname
        const payload = {
          ...JSON.parse(JSON.stringify(this.editStaff)),
          ...{
            ClearPermissions : this.clearPermissions,
            UpdatePermissions: this.updatePermissions
          }
        }

        window.callAS(window.SocketCommand.Staff.Save, { staff: payload })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onCancelButtonClick () {
      if (this.$refs.staffForm) this.$refs.staffForm.resetValidation()
      this.isVisible = false
      this.newStaff = null
    },

    onStaffSaveResult (result) {
      if (result.status) {
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'info',
          icon: 'info'
        })
        this.onCancelButtonClick()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    }

  }
}
</script>

<style scoped>

</style>
