import BaseCollection       from '../../../lib/data/Collections/BaseCollection'
import SupportPriorityModel from '../../Models/support-center/SupportPriorityModel'
import SocketCommand        from '@/api/SocketCommand'

export default class SupportPrioritiesCollection extends BaseCollection {
  constructor (items = []) {
    super(items, SupportPriorityModel)

    this.fetchCommand = SocketCommand.SupportCenter.Ticket.Lists
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */

  onFetch (data) {
    if (data.status) {
      super.onFetch(data.data.priorities)
      if (data.data.hasOwnProperty('services')) window.Vue.$DataStore.support.departments.clear().add(data.data.departments)
      if (data.data.hasOwnProperty('priorities')) window.Vue.$DataStore.support.services.clear().add(data.data.services)
      if (data.data.hasOwnProperty('priorities')) window.Vue.$DataStore.support.statuses.clear().add(data.data.statuses)
    }
  }
}
