<template>
  <div
    class="card elevation-2"
    :class="isDarkMode ? 'grey darken-4' : 'white'"
  >
    <slot name="avatar">
      <v-avatar
        :class="[avatarColor]"
        class="avatar elevation-2"
        name="avatar"
        size="75px"
        tile
      >
        <div>
          <div
            v-if="avatarText"
            class="white--text text-h4"
            v-text="avatarText"
          />
          <v-icon
            v-else-if="avatarIcon"
            dark
            large
            v-text="avatarIcon"
          />
          <div
            v-if="avatarSubText"
            class="white--text"
            style="font-size: 9px"
          >
            {{ avatarSubText }}
          </div>
        </div>
      </v-avatar>
    </slot>

    <div
      class="card-content"
      :class="isDarkMode ? 'grey darken-4' : 'white'"
    >
      <slot name="card-content">
        <slot name="title">
          <div
            v-if="title"
            class="category grey--text"
            style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
            v-html="title"
          />
        </slot>

        <slot name="subTitle">
          <div
            v-if="subTitle"
            class="subtext-h6 text-h6 grey--text text--darken-2"
            style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
            v-html="subTitle"
          />
        </slot>

        <slot name="mainContent">
          <div
            v-if="mainText"
            class="main-content text-left pt-1 pb-1 grey--text text--darken-1"
            v-html="mainText"
          />
        </slot>
      </slot>
    </div>

    <slot name="footer">
      <div
        v-if="iconFooter || footerText"
        class="card-footer"
        :class="isDarkMode ? 'grey darken-4' : 'white'"
        style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
      >
        <v-icon
          v-if="iconFooter"
          color="grey"
          v-text="iconFooter"
        />
        <span
          v-if="footerText"
          class="text-caption grey--text"
          v-html="footerText"
        />
      </div>
    </slot>
  </div>
</template>

<script>

import AppData from '@/mixins/appdata'

export default {
  name      : 'ElevatedCard',
  components: {},
  mixins    : [AppData],
  props     : {
    avatarColor: {
      type   : String,
      default: 'orange'
    },
    avatarIcon: {
      type   : String,
      default: 'account_circle'
    },
    avatarText: {
      type   : String,
      default: ''
    },
    avatarSubText: {
      type   : String,
      default: ''
    },
    title: {
      type   : String,
      default: ''
    },
    subTitle: {
      type   : String,
      default: ''
    },
    footerText: {
      type   : String,
      default: ''
    },
    iconFooter: {
      type   : String,
      default: ''
    },
    mainText: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {}
}
</script>

<style scoped>
.card {
  display       : inline-block;
  position      : relative;
  width         : 100%;
  margin-top    : 20px;
  margin-bottom : 0px;
}

.avatar {
  float         : left;
  text-align    : center;
  margin-top    : -20px;
  margin-left   : 15px;
  margin-right  : 15px;
  margin-bottom : 5px;
  border-radius : 3px;
}

.card .card-content {
  text-align : right;
  padding    : 10px 15px 5px;
  min-height : 65px;
}

.card .card-content .category {
  margin-bottom : 0;
}

.card .subtitle {
  margin : 0;
}

.card .card-footer {
  margin      : 0 15px 10px;
  padding-top : 10px;
  border-top  : 1px solid #dedede;
}

.card .card-footer div {
  display : inline-block;
}

</style>
