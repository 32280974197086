<script>
import { Bar } from 'vue-chartjs'

export default {
  name   : 'ChartIncome',
  extends: Bar,
  props  : {
    chartData: {
      type   : Object,
      default: undefined
    },
    chartOptions: {
      type   : Object,
      default: undefined
    }
  },
  watch: {},
  mounted () {
    this.handleDataChange()
    this.$bus.$on(window.SocketCommand.Dashboard.Get, this.handleDataChange)
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Dashboard.Get, this.handleDataChange)
  },
  methods: {
    handleDataChange () {
      const chart = this.$data._chart
      if (chart) chart.destroy()

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.renderChart(this.chartData, this.chartOptions)
        })
      })
    }
  }
}
</script>
