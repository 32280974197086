<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-1">
        <v-toolbar
          :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
          class="filter-toolbar"
          flat
        >
          <v-btn
            v-if="userCanAdd"
            :color="$t('Customer.Table.Button.NewCustomer.Color')"
            :icon="$vuetify.breakpoint.xsOnly"
            class="elevation-0 ma-1"
            text
            @click="showCustomerAddDialog"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $t('Customer.Table.Button.NewCustomer.Icon') }}
            </v-icon>&nbsp;
            <span v-if="$vuetify.breakpoint.smAndUp">{{
              $t('Customer.Table.Button.NewCustomer.Title').toLocaleUpperCase($i18n.locale)
            }}</span>
          </v-btn>

          <v-btn
            v-if="userCanAdd && userIsSuperAdmin"
            :color="$t('Customer.Table.Button.Import.Color')"
            :icon="$vuetify.breakpoint.xsOnly"
            class="elevation-0 ma-1"
            text
            @click="onImportButtonClick"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $t('Customer.Table.Button.Import.Icon') }}
            </v-icon>&nbsp;
            <span v-if="$vuetify.breakpoint.smAndUp">{{
              $t('Customer.Table.Button.Import.Title').toLocaleUpperCase($i18n.locale)
            }}</span>
          </v-btn>

          <v-btn
            v-if="userCanManage && (userIsOwner || userIsSuperAdmin)"
            :color="$t('Customer.Table.Button.Export.Color')"
            :icon="$vuetify.breakpoint.xsOnly"
            :disabled="!viewHasData"
            class="elevation-0 ma-1"
            text
            @click="onExportButtonClick"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $t('Customer.Table.Button.Export.Icon') }}
            </v-icon>&nbsp;
            <span v-if="$vuetify.breakpoint.smAndUp">{{
              $t('Customer.Table.Button.Export.Title').toLocaleUpperCase($i18n.locale)
            }}</span>
          </v-btn>

          <v-spacer />

          <filter-search v-model="dataTable.search.terms" />

          <filter-options
            v-model="filtersModel"
            :filters="filterData"
          />
        </v-toolbar>
      </v-card-title>

      <v-container
        class="pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :footer-props="dataTable.footerProps"
              :headers="dataTableHeaders"
              :height="dataTable.resize.bodyHeight"
              :items="dataTable.data"
              :loading="dataTable.loading"
              :loading-text="dataTable.text.loadingText"
              :no-data-text="dataTable.text.noDataText"
              :no-results-text="dataTable.text.noResultsText"
              :options.sync="dataTable.options"
              :search="dataTable.search.terms"
              :server-items-length="dataTable.total"
              fixed-header
              item-key="Key"
            >
              <template #[`item.customer_type`]="{item}">
                <div class="text-no-wrap">
                  <v-tooltip
                    :color="item.Status ? 'light-green darken-1' : 'grey darken-1'"
                    right
                  >
                    <template #activator="{on}">
                      <v-icon
                        :color="item.Status ? 'light-green' : 'grey'"
                        v-on="on"
                      >
                        check_circle
                      </v-icon>
                    </template>
                    <span>{{
                      item.Status ? $t('Common.Button.Toggle.ActiveM') : $t('Common.Button.Toggle.InactiveM')
                    }}</span>
                  </v-tooltip>

                  <template v-if="getOrderSourceTypeById(item.SourceTypeId)">
                    <v-tooltip
                      right
                      color="indigo lighten-2"
                    >
                      <template #activator="{on}">
                        <v-avatar
                          class="ml-1"
                          color="transparent"
                          size="24"
                          v-on="on"
                        >
                          <v-icon
                            v-if="item.SourceTypeId < 100"
                            color="indigo lighten-2"
                          >
                            {{
                              getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo
                            }}
                          </v-icon>

                          <img
                            v-else
                            :src="getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo"
                          >
                        </v-avatar>
                      </template>

                      <span v-if="item.SourceTypeId < 100">
                        {{
                          getOrderSourceTypeLangById(item.SourceTypeId) && getOrderSourceTypeLangById(item.SourceTypeId).Title
                        }}
                      </span>
                      <span v-else>
                        {{ getOrderSourceTypeById(item.SourceTypeId).title }}
                      </span>
                    </v-tooltip>
                  </template>

                  <v-tooltip
                    :color="getCustomerRegistrationType(item.SocialProvider).Color + ' darken-1'"
                    right
                  >
                    <template #activator="{on}">
                      <v-icon
                        :color="getCustomerRegistrationType(item.SocialProvider).Color"
                        class="ml-1"
                        v-on="on"
                      >
                        {{ getCustomerRegistrationType(item.SocialProvider).Icon }}
                      </v-icon>
                    </template>
                    <span>{{ getCustomerRegistrationType(item.SocialProvider).Name }}</span>
                  </v-tooltip>
                </div>
              </template>

              <template #[`item.customer_id`]="{item}">
                <template v-if="userCanManage">
                  <a
                    href="#"
                    @click.prevent="showCustomerDetailsDialog(item)"
                  >
                    {{ item.Id }}
                  </a>
                </template>
                <template v-else>
                  {{ item.Id }}
                </template>
              </template>

              <template #[`item.first_name`]="{item}">
                {{ item.Firstname }}
              </template>

              <template #[`item.last_name`]="{item}">
                {{ item.Lastname }}
              </template>

              <template #[`item.address`]="{item}">
                <template v-for="address in item.Addresses">
                  <div
                    :key="address.Id"
                    class="text-no-wrap"
                  >
                    <v-icon
                      :color="address.IsDefault ? 'light-green lighten-1' : 'grey'"
                      size="14"
                    >
                      location_on
                    </v-icon>
                    {{ getAddressFormatted(address) }}
                  </div>
                </template>
              </template>

              <template #[`item.groups`]="{item}">
                <div class="mt-2 mb-1">
                  <template v-for="group in item.Groups">
                    <div :key="group.Id">
                      <v-chip
                        class="text-caption ma-0 mb-1"
                        color="indigo lighten-3"
                        dark
                        label
                        small
                        @click="onCustomerGroupChipClick(group.Id)"
                      >
                        {{ group.Name }}
                      </v-chip>
                    </div>
                  </template>
                </div>
              </template>

              <template #[`item.telephone`]="{item}">
                <div
                  v-if="item.Telephone && $options.filters.phone(item.Telephone)"
                  class="text-no-wrap"
                >
                  <v-icon
                    color="blue lighten-2"
                    small
                  >
                    phone
                  </v-icon>
                  {{ item.Telephone | phone }}
                </div>
                <div
                  v-if="item.Telephone2 && $options.filters.phone(item.Telephone2)"
                  class="text-no-wrap"
                >
                  <v-icon
                    color="blue lighten-2"
                    small
                  >
                    phone
                  </v-icon>
                  {{ item.Telephone2 | phone }}<br>
                </div>
                <div
                  v-if="item.Telephone3 && $options.filters.phone(item.Telephone3)"
                  class="text-no-wrap"
                >
                  <v-icon
                    color="blue lighten-2"
                    small
                  >
                    phone
                  </v-icon>
                  {{ item.Telephone3 | phone }}<br>
                </div>
              </template>

              <template #[`item.date_added`]="{item}">
                <div class="text-no-wrap">
                  {{ item.DateAdded }}
                </div>
              </template>

              <template #[`item.actions`]="{item}">
                <v-tooltip
                  :color="$t('Customer.Table.Button.ViewCustomer.Color')"
                  left
                  open-delay="1000"
                >
                  <template #activator="{on}">
                    <v-btn
                      :class="$t('Customer.Table.Button.ViewCustomer.Color')"
                      :small="$vuetify.breakpoint.xsOnly"
                      class="elevation-2 ml-1"
                      dark
                      icon
                      @click="showCustomerDetailsDialog(item)"
                      v-on="on"
                    >
                      <v-icon :small="$vuetify.breakpoint.xsOnly">
                        {{ $t('Customer.Table.Button.ViewCustomer.Icon') }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Customer.Table.Button.ViewCustomer.Title') }}</span>
                </v-tooltip>

                <template v-if="userCanDelete">
                  <v-tooltip
                    :color="$t('Customer.Table.Button.DeleteCustomer.Color')"
                    left
                    open-delay="1000"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :class="$t('Customer.Table.Button.DeleteCustomer.Color')"
                        :small="$vuetify.breakpoint.xsOnly"
                        class="elevation-2 ml-1"
                        dark
                        icon
                        @click="deleteCustomer(item)"
                        v-on="on"
                      >
                        <v-icon :small="$vuetify.breakpoint.xsOnly">
                          delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Customer.Table.Button.DeleteCustomer.Title') }}</span>
                  </v-tooltip>
                </template>

                <template v-if="posUserHasComponentPermission('TableReservation', 'MANAGE')">
                  <v-tooltip
                    :color="$t('Customer.Table.Button.NewReservation.Color')"
                    left
                    open-delay="1000"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :class="$t('Customer.Table.Button.NewReservation.Color')"
                        :small="$vuetify.breakpoint.xsOnly"
                        class="elevation-2 ml-1"
                        dark
                        icon
                        @click="createNewCustomerReservation(item)"
                        v-on="on"
                      >
                        <v-icon :small="$vuetify.breakpoint.xsOnly">
                          {{ $t('Customer.Table.Button.NewReservation.Icon') }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Customer.Table.Button.NewReservation.Title') }}</span>
                  </v-tooltip>
                </template>

                <template
                  v-if="posUserHasComponentPermission('Pos', 'ACCESS') && posUserHasComponentPermission('Orders', 'MANAGE')"
                >
                  <v-tooltip
                    :color="$t('Customer.Table.Button.NewOrder.Color')"
                    left
                    open-delay="1000"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :class="$t('Customer.Table.Button.NewOrder.Color')"
                        :small="$vuetify.breakpoint.xsOnly"
                        class="elevation-2 ml-1"
                        dark
                        icon
                        @click="createNewCustomerOrder(item)"
                        v-on="on"
                      >
                        <v-icon :small="$vuetify.breakpoint.xsOnly">
                          {{ $t('Customer.Table.Button.NewOrder.Icon') }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Customer.Table.Button.NewOrder.Title') }}</span>
                  </v-tooltip>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <customers-import-dialog :visible.sync="customersImportDialogVisible" />

    <customers-export-dialog
      v-if="posUserHasComponentPermission('Customers', 'MANAGE') && posUserHasComponentPermission('CustomerGroups', 'ACCESS')"
      :customer-groups="customerGroups"
      :visible.sync="customersExportDialogVisible"
      @export:data="onExportDataSelected"
    />

    <confirm-dialog
      v-if="selectedItem"
      :html-content="$t('Customer.CustomersDeleteDialog.Content', {customer: `#${selectedItem.Id} ${selectedItem.Firstname} ${selectedItem.Lastname}`})"
      :html-title="$t('Customer.CustomersDeleteDialog.Title', {customer: `#${selectedItem.Id}`})"
      :info-bar="true"
      :info-bar-body="$t('Customer.CustomersDeleteDialog.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </div>
</template>

<script>
import Auth                  from '@/mixins/auth'
import AppData               from '@/mixins/appdata'
import SourceType            from '@/mixins/orders/sourceType'
import DataTable             from '@/mixins/data-table/dataTable'
import FilterSearch          from '@/components/common/filter/FilterSearch.vue'
import CustomersExportDialog from '@/components/customers/CustomersExportDialog.vue'
import FilterCommon          from '@/mixins/filter/filterCommon'
import CustomerCommon        from '@/mixins/customers/customerCommon'
import FilterOptions         from '@/components/common/filter/FilterOptions.vue'
import CustomersImportDialog from '@/components/customers/CustomersImportDialog.vue'
import ConfirmDialog         from '@/components/common/ConfirmDialog.vue'

export default {
  components: {
    ConfirmDialog,
    CustomersImportDialog,
    CustomersExportDialog,
    FilterSearch,
    FilterOptions
  },
  directives: {},
  mixins    : [AppData, Auth, DataTable, SourceType, FilterCommon, CustomerCommon],
  props     : {
    searchParam: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {
      selectedItem                : null,
      customerGroups              : [],
      confirmDialogDeleteVisible  : false,
      customersExportDialogVisible: false,
      customersImportDialogVisible: false,
      filtersModel                : {
        lang                : 'Customer.Table',
        filterCustomerStatus: {
          key  : 'filter_status',
          value: null,
          items: this.$t('Customer.Table.Filter.filterCustomerStatus.Items')
        },
        filterCustomerGroup: {
          key  : 'filter_group',
          value: [],
          items: []
        },
        filterCustomerSourceType: {
          key  : 'filter_source_type',
          value: null,
          items: []
        },
        filterCustomerSocial: {
          key  : 'filter_social',
          value: null,
          items: this.$t('Customer.Table.Filter.filterCustomerSocial.Items')
        },
        filterDate: {
          key    : 'filter_date',
          value  : null,
          visible: false,
          date   : null
        }
      },
      dataTable: {
        dataStore    : 'customers',
        socketCommand: {
          list  : window.SocketCommand.Customer.All,
          save  : window.SocketCommand.Customer.Save,
          delete: window.SocketCommand.Customer.Delete
        },
        options: {
          defaultSortBy: 'customer_id',
          sortBy       : ['customer_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : ['groups', 'date_added'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Customer.Table.Headers.CustomerType'),
            value    : 'customer_type',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.Id'),
            value    : 'customer_id',
            align    : 'left',
            sortable : true,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.Firstname'),
            value    : 'first_name',
            align    : 'left',
            sortable : true,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.Lastname'),
            value    : 'last_name',
            align    : 'left',
            sortable : true,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.Address'),
            value    : 'address',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.Groups'),
            value    : 'groups',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.Phone'),
            value    : 'telephone',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.CreatedAt'),
            value    : 'date_added',
            align    : 'center',
            sortable : true,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Customer.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          }
        ]
      }
    }
  },
  computed: {
    customerGroupsFiltered () {
      return this.customerGroups.map(o => {
        o.Avatar = {
          Color: 'indigo',
          Icon : 'people'
        }
        return o
      })
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
    if (this.$route.params.searchParam) this.dataTable.search.terms = this.$route.params.searchParam

    // Init Custom Filter Values
    this.filtersModel.filterCustomerGroup.items = this.customerGroupsFiltered
    this.filtersModel.filterCustomerSourceType.items = this.customerSourceTypeList
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.CustomerGroup.All, this.onCustomerGroupsResult)
    this.$bus.$on(window.SocketCommand.Customer.Export, this.onExportResult)

    if (this.posUserHasComponentPermission('Customers', 'MANAGE') && this.posUserHasComponentPermission('CustomerGroups', 'ACCESS')) {
      window.callAS(window.SocketCommand.CustomerGroup.All, {
        page_limit: -1,
        config    : false
      })
    }
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.CustomerGroup.All, this.onCustomerGroupsResult)
    this.$bus.$off(window.SocketCommand.Customer.Export, this.onExportResult)
  },
  methods: {
    onCustomerGroupChipClick (customerGroupId) {
      if (!this.filtersModel.filterCustomerGroup.value.includes(customerGroupId)) {
        this.filtersModel.filterCustomerGroup.value.push(customerGroupId)
      }
    },

    showCustomerDetailsDialog (customer) {
      this.$bus.$emit('show-customer-details-dialog', customer.Id)
    },

    showCustomerAddDialog () {
      this.$bus.$emit('show-customer-add-dialog')
    },

    createNewCustomerOrder (customer) {
      if (!customer) return

      this.$router.push({
        name  : 'Pos',
        params: {
          orderId   : '0',
          orderType : '1',
          customerId: customer.Id
        }
      })
    },

    createNewCustomerReservation (customer) {
      if (!customer) return

      this.$bus.$emit('show-table-reservation-dialog', {
        Customer: customer
      })
    },

    onExportButtonClick () {
      this.customersExportDialogVisible = true
    },

    onImportButtonClick () {
      this.customersImportDialogVisible = true
    },

    onExportDataSelected (data) {
      window.callAS(window.SocketCommand.Customer.Export, data)
    },

    onExportResult (response) {
      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Customer.Table.Button.Export.SuccessMessage', { email: this.posUser?.staff_email || '' }),
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onCustomerGroupsResult (result) {
      this.customerGroups = result?.items || []

      this.filtersModel.filterCustomerGroup.items = this.customerGroupsFiltered
    },

    getAddressFormatted (address) {
      const comma = address.Address || address.Number ? ',' : ''
      const retVal = `${ address.Address } ${ address.Number }${ comma } ${ address.City }`

      return retVal?.trim() || address.Formatted
    },

    deleteCustomer (item) {
      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      if (!this.selectedItem) return
      window.callAS(window.SocketCommand.Customer.Delete, { id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Error.Generic'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
