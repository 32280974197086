<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import DataTableFixed    from '../directives/DataTableFixed'
import DynamicRouterLink from '../directives/DynamicRouterLink'

export default {
  name: 'App',
  metaInfo () {
    return {
      title        : this.$route.meta.title ? this.$route.matched.map(route => route.meta.title).join(' - ') : this.$route.name,
      titleTemplate: '%s | GETFOOD Pro'
    }
  },
  directives: {
    DataTableFixed,
    DynamicRouterLink
  },
  components: {},
  mixins    : [],
  data () {
    return {}
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },
  watch  : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {}
}

</script>

<style>
html {
  /*overflow            : hidden !important;*/
  overflow-y          : auto;

  -webkit-user-select : none; /* Chrome all / Safari all */
  -moz-user-select    : none; /* Firefox all */
  -ms-user-select     : none; /* IE 10+ */
  user-select         : none; /* Likely future */
}

.listNoDivider:after, .listNoDivider:before {
  content : none !important;
}

.appToolbar .v-toolbar__content {
  padding-right : 0;
  padding-left  : 12px;
}

.chip:focus:not(.chip--disabled):after, .chip--active:not(.chip--disabled):after, .chip--selected:not(.chip--disabled):after {
  left : 0 !important;
}

.list--group:after {
  content : initial !important;
}

.imagecontainer {
  overflow : hidden;
}

.is-contain.imagecontainer .imagecontainer img {
  overflow        : hidden;
  object-fit      : contain;
  object-position : 0 0;
  font-family     : "object-fit: contain";
}

::-webkit-scrollbar {
  width  : 12px;
  height : 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow    : inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-border-radius : 1px;
  border-radius         : 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius : 1px;
  border-radius         : 1px;
  -webkit-box-shadow    : inset 0 0 1px rgba(0, 0, 0, 0.3);
}

.v-application.theme--light ::-webkit-scrollbar-thumb, .v-application.theme--light ::-webkit-scrollbar-thumb:window-inactive {
  background : rgb(220, 220, 220);
}

.v-application.theme--dark ::-webkit-scrollbar-thumb, .v-application.theme--dark ::-webkit-scrollbar-thumb:window-inactive {
  background : rgb(58, 58, 58);
}

::-webkit-scrollbar-track {
  /*margin : 20px 20px;*/
}

.dialog--fullscreen {
  overflow : hidden;
}

span.old-price {
  opacity      : 0.6;
  position     : relative;
  display      : inline-block;
  margin-right : 0px;
}

span.old-price:before {
  content    : '';
  position   : absolute;
  left       : 0;
  top        : 50%;
  right      : 0;
  border-top : 2px solid red;
  transform  : rotateZ(-15deg);
}

th.sortable {
  outline : none;
}

img.grayscale {
  filter         : url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
  filter         : gray; /* IE6-9 */
  -webkit-filter : grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

img.opacity {
  /* IE 8 */
  -ms-filter     : "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  /* IE 5-7 */
  filter         : alpha(opacity=50);
  /* Netscape */
  -moz-opacity   : 0.5;
  /* Safari 1.x */
  -khtml-opacity : 0.5;
  /* Good browsers */
  opacity        : 0.5;
}

.fill-width {
  width : 100%;
}

/*
*   PULSE ANIMATION
*/
.pulse-badge .v-badge__badge {
  overflow : initial;
}

.pulse-badge .v-badge__badge:before {
  content          : '';
  position         : absolute;
  top              : 0;
  left             : 0;
  width            : 100%;
  height           : 100%;
  background-color : inherit;
  border-radius    : inherit;
  transition       : opacity .3s;
  animation        : pulse-badge-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index          : -1;
}

.pulse {
  overflow : initial;
  position : relative;
}

.pulse:before {
  content          : '';
  position         : absolute;
  top              : 0;
  left             : 0;
  width            : 100%;
  height           : 100%;
  background-color : inherit;
  border-radius    : inherit;
  transition       : opacity .3s;
  animation        : pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index          : -1;
}

.pulse-1:before {
  animation-iteration-count : 1;
}

.pulse-2:before {
  animation-iteration-count : 2;
}

.pulse-3:before {
  animation-iteration-count : 3;
}

.pulse-4:before {
  animation-iteration-count : 4;
}

.pulse-5:before {
  animation-iteration-count : 5;
}

.pulse-10:before {
  animation-iteration-count : 10;
}

.pulse-stop:before, .pulse-hover:hover:before {
  animation : none;
}

@keyframes pulse-animation {
  0% {
    opacity   : 1;
    transform : scale(1)
  }
  50% {
    opacity   : 0;
    transform : scale(1.5)
  }
  100% {
    opacity   : 0;
    transform : scale(1.5)
  }
}

@keyframes pulse-badge-animation {
  0% {
    opacity   : 1;
    transform : scale(1)
  }
  50% {
    opacity   : 0;
    transform : scale(2)
  }
  100% {
    opacity   : 0;
    transform : scale(2)
  }
}

.custom-loader {
  animation : loader 1s infinite;
  display   : flex;
}

@-moz-keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}
</style>
