<template>
  <v-card flat>
    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-tabs-items v-model="tab">
          <v-tab-item key="settings">
            <v-row>
              <v-col>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Delivery.ListView.OutOfRange.Title') }}
                </h5>
                <div class="text-caption">
                  {{ $t('Settings.Delivery.ListView.OutOfRange.Subtitle') }}
                </div>
                <div class="text-caption orange--text text--darken-2">
                  {{ $t('Settings.Delivery.ListView.OutOfRange.Warning') }}
                </div>
              </v-col>
              <v-col class="shrink">
                <v-switch
                  v-model="viewData.HasOnlyWarningForAddresses"
                  inset
                  :label="viewData.HasOnlyWarningForAddresses ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                  color="success"
                  hide-details
                />
              </v-col>

              <v-col cols="12">
                <v-divider class="my-2" />
              </v-col>

              <v-col>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Delivery.ListView.AcceptCustomAddresses.Title') }}
                </h5>
                <div class="text-caption">
                  {{ $t('Settings.Delivery.ListView.AcceptCustomAddresses.Subtitle') }}
                </div>
                <div class="text-caption orange--text text--darken-2">
                  {{ $t('Settings.Delivery.ListView.AcceptCustomAddresses.Warning') }}
                </div>
              </v-col>
              <v-col class="shrink">
                <v-switch
                  v-model="viewData.AcceptCustomAddresses"
                  inset
                  :label="viewData.AcceptCustomAddresses ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                  color="success"
                  hide-details
                />
              </v-col>

              <v-col cols="12">
                <v-divider class="my-2" />
              </v-col>

              <v-col>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Delivery.ListView.DeliveryLimit.Title') }}
                </h5>
                <div class="text-caption">
                  {{ $t('Settings.Delivery.ListView.DeliveryLimit.Subtitle') }}
                </div>
              </v-col>
              <v-col class="shrink">
                <v-switch
                  v-model="viewData.HasDeliveryAreas"
                  inset
                  :label="viewData.HasDeliveryAreas ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                  color="success"
                  hide-details
                />
              </v-col>

              <v-col cols="12">
                <v-divider class="my-2" />
              </v-col>

              <v-col cols="12">
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Delivery.ListView.Country.Title') }}
                </h5>
                <span class="text-caption">{{ $t('Settings.Delivery.ListView.Country.Subtitle') }}</span>
                <v-autocomplete
                  v-if="viewData"
                  v-model="viewData.DeliveryCountries"
                  :items="viewData.Countries"
                  :placeholder="$t('Settings.Delivery.ListView.Country.PlaceHolder')"
                  clearable
                  hide-details
                  item-text="name"
                  item-value="code"
                  multiple
                  persistent-hint
                  required
                  single-line
                />
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item
            key="delivery-areas"
            :disabled="!viewData.HasDeliveryAreas"
          >
            <v-container
              class="ma-0 pa-0"
              fluid
            >
              <v-row>
                <v-col
                  lg="6"
                  cols="12"
                >
                  <gmap-map
                    ref="restaurantMap"
                    :center="markerStore"
                    :options="{styles: [{'featureType': 'poi', 'stylers': [{'visibility': 'off'}]}]}"
                    :zoom="mapZoom"
                    map-type-id="roadmap"
                    style="height: 580px"
                  >
                    <template v-if="viewData && viewData.HasDeliveryAreas && selectedDeliveryArea">
                      <gmap-circle
                        v-if="selectedDeliveryAreaType === 'circle'"
                        ref="circle"
                        :center="restaurantDeliveryAreaCircle.center || markerStore"
                        :editable="true"
                        :options="{strokeColor: selectedDeliveryAreaColor, strokeOpacity: 1.0, strokeWeight: 3, fillColor: selectedDeliveryAreaColor, fillOpacity: 0.15}"
                        :radius="restaurantDeliveryAreaCircle.radius || 1000"
                        @center_changed="updateEditedCircleCenter($event)"
                        @radius_changed="updateEditedCircleRadius($event)"
                      />

                      <gmap-polygon
                        v-if="selectedDeliveryAreaType === 'shape'"
                        ref="polyline"
                        :draggable="dragPolygon"
                        :editable="!dragPolygon"
                        :options="{strokeColor: selectedDeliveryAreaColor, strokeOpacity: 1.0, strokeWeight: 3, fillColor: selectedDeliveryAreaColor, fillOpacity: 0.15}"
                        :paths="restaurantDeliveryAreaPolygons"
                        @paths_changed="updateEdited($event)"
                        @rightclick="handleClickForDelete"
                      />
                    </template>

                    <template v-if="viewData && viewData.HasDeliveryAreas && !selectedDeliveryArea">
                      <template v-for="(deliveryArea, index) in viewData.DeliveryAreas">
                        <gmap-circle
                          v-if="deliveryArea.type === 'circle'"
                          :key="`map-circle-${index}`"
                          :center="deliveryArea.circle.center || markerStore"
                          :options="{strokeColor: deliveryArea.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: deliveryArea.color, fillOpacity: 0.15}"
                          :radius="deliveryArea.circle.radius || 1000"
                        />

                        <gmap-polygon
                          v-if="deliveryArea.type === 'shape'"
                          :key="`map-poly-${index}`"
                          :draggable="false"
                          :editable="false"
                          :options="{strokeColor: deliveryArea.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: deliveryArea.color, fillOpacity: 0.15}"
                          :paths="deliveryArea.vertices || []"
                        />
                      </template>
                    </template>

                    <gmap-marker
                      :icon="getGMapIcon(LOGO_COLOR.DARK)"
                      :position="markerStore"
                      label=""
                    />
                  </gmap-map>
                </v-col>

                <v-col
                  lg="6"
                  cols="12"
                >
                  <v-btn
                    block
                    class="blue white--text"
                    depressed
                    @click="onAddDeliveryArea()"
                  >
                    {{ $t('Settings.Delivery.ListView.Map.Button.Add') }}
                  </v-btn>

                  <v-expansion-panel v-model="xpanel">
                    <template v-for="(deliveryArea, index) in viewData.DeliveryAreas">
                      <v-expansion-panel-content :key="`delivery_area_${index}`">
                        <template #header>
                          <v-card-title class="pl-0">
                            <v-icon
                              :color="deliveryArea.color || getAreaColor(index)"
                              class="pr-4"
                            >
                              {{ deliveryArea.type === 'shape' ? 'fas fa-vector-square' : 'far fa-circle' }}
                            </v-icon>
                            <h3>{{ $t('Settings.Delivery.ListView.Map.Region') }} #{{ (index + 1) }}</h3>
                            <h5
                              v-if="deliveryArea.title"
                              class="grey--text text--darken-1"
                            >
                              &nbsp; - {{ deliveryArea.title }}
                            </h5>

                            <v-spacer />

                            <v-btn
                              v-if="viewData.DeliveryAreas.length > 1"
                              color="red"
                              dark
                              text
                              icon
                              small
                              @click.stop="onRemoveDeliveryArea(index)"
                            >
                              <v-icon>delete_forever</v-icon>
                            </v-btn>
                          </v-card-title>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-row
                              align="center"
                            >
                              <v-col cols="12">
                                <v-text-field
                                  v-model="deliveryArea.title"
                                  :label="$t('Settings.Delivery.ListView.Map.Field.Title.Label')"
                                  clearable
                                  hide-details
                                  required
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-btn-toggle
                                  v-model="deliveryArea.type"
                                  class="elevation-0"
                                  dark
                                  mandatory
                                  style="width: 100%;"
                                >
                                  <v-btn
                                    block
                                    class="elevation-0"
                                    color="red accent-4"
                                    value="shape"
                                  >
                                    <v-icon
                                      class="mr-2"
                                      color="white"
                                      size="22"
                                    >
                                      fas fa-vector-square
                                    </v-icon>
                                    <span>{{ $t('Settings.Delivery.ListView.Map.Button.Polygon').toLocaleUpperCase($i18n.locale) }}</span>
                                  </v-btn>
                                  <v-btn
                                    block
                                    class="elevation-0"
                                    color="blue darken-3"
                                    value="circle"
                                  >
                                    <v-icon
                                      class="mr-2"
                                      color="white"
                                      size="22"
                                    >
                                      far fa-circle
                                    </v-icon>
                                    <span>{{ $t('Settings.Delivery.ListView.Map.Button.Circle').toLocaleUpperCase($i18n.locale) }}</span>
                                  </v-btn>
                                </v-btn-toggle>
                              </v-col>

                              <v-col cols="12">
                                <v-switch
                                  v-if="deliveryArea.type==='shape'"
                                  v-model="dragPolygon"
                                  inset
                                  :label="$t('Settings.Delivery.ListView.Map.Field.Move.Label').toLocaleUpperCase($i18n.locale)"
                                  class="ma-0 ml-4 d-inline-flex"
                                  color="red"
                                  hide-details
                                />
                              </v-col>

                              <v-col cols="12">
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Subtitle') }}</span>
                              </v-col>
                              <v-col cols="12">
                                <div class="divTable">
                                  <div class="divTableHeading">
                                    <div class="divTableRow text-center">
                                      <div class="divTableHead">
                                        {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Charge') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Condition') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Total') }}
                                      </div>
                                      <div class="divTableHead">
                                        <v-menu
                                          auto
                                          offset-y
                                        >
                                          <v-btn
                                            slot="activator"
                                            class="blue my-0"
                                            dark
                                            icon
                                            small
                                          >
                                            <v-icon>add</v-icon>
                                          </v-btn>
                                          <v-list>
                                            <v-list-item
                                              v-for="item in chargeConditionsList"
                                              :key="item.Id"
                                              :disabled="deliveryArea.charge && !!deliveryArea.charge.find(charge => charge.condition === item.Id) && item.Id === 'all'"
                                              @click="onAddChargeCondition(deliveryArea, item)"
                                            >
                                              <v-list-item-title
                                                v-html="item.Name"
                                              />
                                            </v-list-item>
                                          </v-list>
                                        </v-menu>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="divTableBody">
                                    <div
                                      v-for="(charge, idx) in deliveryArea.charge || []"
                                      :key="`delivery_area_${index}_charge_${idx}`"
                                      class="divTableRow"
                                    >
                                      <div
                                        class="divTableCell"
                                        style="width: 100px;"
                                      >
                                        <v-text-field
                                          v-model="charge.amount"
                                          :append-icon="appCurrency.Icon"
                                          :placeholder="$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Charge')"
                                          hide-details
                                          required
                                          single-line
                                        />
                                      </div>
                                      <div class="divTableCell text-center">
                                        <v-select
                                          v-model="charge.condition"
                                          :items="chargeConditionsList"
                                          :label="$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Condition')"
                                          hide-details
                                          item-text="Name"
                                          item-value="Id"
                                          single-line
                                        />
                                      </div>
                                      <div
                                        class="divTableCell"
                                        style="width: 100px;"
                                      >
                                        <v-text-field
                                          v-model="charge.total"
                                          :append-icon="appCurrency.Icon"
                                          :disabled="charge.condition === 'all'"
                                          :placeholder="$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Total')"
                                          hide-details
                                          required
                                          single-line
                                        />
                                      </div>
                                      <div
                                        class="divTableCell text-right"
                                        style="white-space: nowrap;"
                                      >
                                        <v-btn
                                          :disabled="deliveryArea.charge && deliveryArea.charge.length === 1"
                                          class="orange"
                                          dark
                                          icon
                                          small
                                          @click="onRemoveChargeCondition(deliveryArea, idx)"
                                        >
                                          <v-icon>remove</v-icon>
                                        </v-btn>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </template>
                  </v-expansion-panel>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import AppData from '../../../mixins/appdata'
import Auth    from '../../../mixins/auth'

export default {
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    wizardVisible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      tab                 : 0,
      xpanel              : null,
      viewData            : null,
      countryList         : [],
      colorsList          : ['#F16745', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#404040', '#F16745', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#404040', '#F16745', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#404040', '#F16745', '#FFC65D'],
      chargeConditionsList: [
        {
          Id  : 'all',
          Name: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.NewCondition.All')
        },
        {
          Id  : 'above',
          Name: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.NewCondition.Above')
        },
        {
          Id  : 'below',
          Name: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.NewCondition.Bellow')
        }
      ],
      isSaving   : false,
      dragPolygon: false,
      newPath    : [],
      newCircle  : {
        radius: 0,
        center: {
          lat: 0,
          lng: 0
        }
      },
      mapZoom: 16
    }
  },
  computed: {
    tabs () {
      return this?.viewData?.HasDeliveryAreas ? ['settings', 'delivery-areas'] : ['settings']
    },
    defaultArea () {
      return {
        type  : 'circle',
        areaId: this.viewData ? this.viewData.DeliveryAreas.length + 1 : 1,
        charge: [
          {
            total    : 0,
            amount   : 0,
            condition: 'all'
          }
        ],
        circle: {
          center: {
            lat: this.markerStore.lat,
            lng: this.markerStore.lng
          },
          radius: 1200
        },
        color   : this.getAreaColor(this.viewData ? this.viewData.DeliveryAreas.length : 0),
        area_id : this.viewData ? this.viewData.DeliveryAreas.length + 1 : 1,
        vertices: this.newPath || []
      }
    },
    selectedDeliveryArea () {
      return this.viewData && this.xpanel > -1 ? this.viewData.DeliveryAreas[this.xpanel] : {}
    },
    selectedDeliveryAreaColor () {
      return this.selectedDeliveryArea.color || this.getAreaColor(parseInt(this.selectedDeliveryArea.areaId) - 1 || 0)
    },
    selectedDeliveryAreaType: {
      get () {
        return this.selectedDeliveryArea && this.selectedDeliveryArea.type
      },
      set (val) {
        this.selectedDeliveryArea.type = val
      }
    },
    markerStore () {
      return {
        lat: this.viewData ? this.viewData.Lat : 0,
        lng: this.viewData ? this.viewData.Lng : 0
      }
    },
    restaurantDeliveryAreaCircle () {
      return this.selectedDeliveryArea ? this.selectedDeliveryArea.circle : {}
    },
    restaurantDeliveryAreaPolygons () {
      return this.selectedDeliveryArea ? [this.selectedDeliveryArea.vertices] : []
    }
  },
  watch: {
    tab (newVal) {
      if (newVal === 1) {
        setTimeout(() => {
          this.centerZoomMap()
        }, 150)
      }
    },
    selectedDeliveryArea () {
      this.centerZoomMap()
    },
    selectedDeliveryAreaType () {
      this.centerZoomMap()
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Delivery.Get, this.onGetDeliveryResult)
    this.$bus.$on(window.SocketCommand.Settings.Delivery.Save, this.onSaveDeliveryResult)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Delivery.Get, this.onGetDeliveryResult)
    this.$bus.$off(window.SocketCommand.Settings.Delivery.Save, this.onSaveDeliveryResult)
  },
  methods: {
    init () {
      this.$gmapApiPromiseLazy().then(() => {
        this.googleMapState = true
        // eslint-disable-next-line no-undef
        const circleArea = new google.maps.Circle({
          center: this.markerStore,
          radius: 1000
        })
        const ne = circleArea.getBounds().getNorthEast()
        const sw = circleArea.getBounds().getSouthWest()
        const scale = 1
        const windowWidth = ne.lng() - sw.lng()
        const windowHeight = ne.lat() - sw.lat()
        const widthMargin = windowWidth * scale
        const heightMargin = windowHeight * scale
        const top = ne.lat() - heightMargin
        const bottom = sw.lat() + heightMargin
        const left = sw.lng() + widthMargin
        const right = ne.lng() - widthMargin
        this.newPath = [
          {
            lat   : top,
            lng   : right,
            latlng: {
              lat: top,
              lng: right
            }
          },
          {
            lat   : bottom,
            lng   : right,
            latlng: {
              lat: bottom,
              lng: right
            }
          },
          {
            lat   : bottom,
            lng   : left,
            latlng: {
              lat: bottom,
              lng: left
            }
          },
          {
            lat   : top,
            lng   : left,
            latlng: {
              lat: top,
              lng: left
            }
          }
        ]

        this.centerZoomMap()
      })
    },

    onAddDeliveryArea () {
      this.viewData.DeliveryAreas.push(this.defaultArea)
    },
    onRemoveDeliveryArea (index) {
      this.viewData.DeliveryAreas.splice(index, 1)
    },

    onAddChargeCondition (deliveryArea, condition) {
      if (!deliveryArea.charge) deliveryArea.charge = []
      deliveryArea.charge.push({
        amount   : 0,
        condition: condition.Id,
        total    : 0
      })
    },
    onRemoveChargeCondition (deliveryArea, index) {
      if (!deliveryArea.charge) deliveryArea.charge = []
      deliveryArea.charge.splice(index, 1)
    },

    updateEditedCircleCenter (center) {
      // console.log('Center::', center)
      if (this.selectedDeliveryArea) {
        this.selectedDeliveryArea.circle.center.lat = center.lat()
        this.selectedDeliveryArea.circle.center.lng = center.lng()
      }
    },
    updateEditedCircleRadius (radius) {
      // console.log('Radius::', radius)
      if (this.selectedDeliveryArea) this.selectedDeliveryArea.circle.radius = parseFloat(radius)
    },
    updateEdited (mvcArray) {
      const paths = []
      for (let i = 0; i < mvcArray.getLength(); i++) {
        const path = []
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          const point = mvcArray.getAt(i).getAt(j)
          path.push({
            lat   : point.lat(),
            lng   : point.lng(),
            latlng: {
              lat: point.lat(),
              lng: point.lng()
            }
          })
        }
        paths.push(path)
      }

      if (paths.length > 0 && this.selectedDeliveryArea && this.selectedDeliveryAreaType === 'shape') {
        this.selectedDeliveryArea.vertices = paths[0]
      }
    },
    handleClickForDelete ($event) {
      if ($event.vertex) {
        this.$refs.polyline.$polygonObject.getPaths()
          .getAt($event.path)
          .removeAt($event.vertex)
      }
    },
    getAreaColor (areaIndex) {
      return this.colorsList[areaIndex] ? this.colorsList[areaIndex] : this.colorsList[0]
    },
    centerZoomMap () {
      if (!this.googleMapState) return
      // eslint-disable-next-line no-undef
      const latlngbounds = new google.maps.LatLngBounds()

      if (this.selectedDeliveryArea) {
        if (this.selectedDeliveryAreaType === 'shape') {
          for (let i = 0; i < this.restaurantDeliveryAreaPolygons.length; i++) {
            for (let j = 0; j < this.restaurantDeliveryAreaPolygons[i].length; j++) {
              // eslint-disable-next-line no-undef
              this.restaurantDeliveryAreaPolygons[i][j].latlng = new google.maps.LatLng(parseFloat(this.restaurantDeliveryAreaPolygons[i][j].lat), parseFloat(this.restaurantDeliveryAreaPolygons[i][j].lng))
              latlngbounds.extend(this.restaurantDeliveryAreaPolygons[i][j].latlng)
            }
          }

          if (this.$refs.restaurantMap) {
            if (this.$refs.restaurantMap.$mapCreated) {
              this.$refs.restaurantMap.$mapCreated.then((map) => {
                map.fitBounds(latlngbounds)
                map.panToBounds(latlngbounds)
              })
            } else if (this.$refs.restaurantMap.$mapPromise) {
              this.$refs.restaurantMap.$mapPromise.then((map) => {
                map.fitBounds(latlngbounds)
                map.panToBounds(latlngbounds)
              })
            }
          }
        } else {
          if (this.$refs.restaurantMap) {
            if (this.$refs.restaurantMap.$mapCreated) {
              this.$refs.restaurantMap.$mapCreated.then((map) => {
                if (this.$refs.circle) {
                  this.$refs.circle.$circlePromise.then((circle) => {
                    map.fitBounds(circle.getBounds())
                    map.panToBounds(circle.getBounds())
                  })
                }
              })
            } else if (this.$refs.restaurantMap.$mapPromise) {
              this.$refs.restaurantMap.$mapPromise.then((map) => {
                if (this.$refs.circle) {
                  this.$refs.circle.$circlePromise.then((circle) => {
                    map.fitBounds(circle.getBounds())
                    map.panToBounds(circle.getBounds())
                  })
                }
              })
            }
          }
        }
      } else {
        if (Array.isArray(this.viewData.DeliveryAreas) && this.viewData.DeliveryAreas.length > 0) {
          for (let area = 0; area < this.viewData.DeliveryAreas.length; area++) {
            const deliveryArea = this.viewData.DeliveryAreas[area]
            if (deliveryArea.type === 'shape') {
              for (let i = 0; i < deliveryArea.vertices.length; i++) {
                // eslint-disable-next-line
                let latlng = new google.maps.LatLng(parseFloat(deliveryArea.vertices[i].lat), parseFloat(deliveryArea.vertices[i].lng))
                deliveryArea.vertices[i].latlng = latlng
                latlngbounds.extend(latlng)
              }
            } else {
              // eslint-disable-next-line
              let latlng = new google.maps.LatLng(parseFloat(deliveryArea.circle.center.lat), parseFloat(deliveryArea.circle.center.lng))
              latlngbounds.extend(latlng)
            }

            if (this.$refs.restaurantMap) {
              if (this.$refs.restaurantMap.$mapCreated) {
                this.$refs.restaurantMap.$mapCreated.then((map) => {
                  map.fitBounds(latlngbounds)
                  map.panToBounds(latlngbounds)
                })
              } else if (this.$refs.restaurantMap.$mapPromise) {
                this.$refs.restaurantMap.$mapPromise.then((map) => {
                  map.fitBounds(latlngbounds)
                  map.panToBounds(latlngbounds)
                })
              }
            }
          }
        } else {
          if (this.$refs.restaurantMap) {
            if (this.$refs.restaurantMap.$mapCreated) {
              this.$refs.restaurantMap.$mapCreated.then((map) => {
                // eslint-disable-next-line no-undef
                map.setCenter(new google.maps.LatLng(parseFloat(this.markerStore.lat), parseFloat(this.markerStore.lng)))
                map.setZoom(15)
              })
            } else if (this.$refs.restaurantMap.$mapPromise) {
              this.$refs.restaurantMap.$mapPromise.then((map) => {
                // eslint-disable-next-line no-undef
                map.setCenter(new google.maps.LatLng(parseFloat(this.markerStore.lat), parseFloat(this.markerStore.lng)))
                map.setZoom(15)
              })
            }
          }
        }
      }
    },

    onGetDeliveryResult (data) {
      data.DeliveryAreas.forEach((area, a) => {
        data.DeliveryAreas[a].areaId = parseFloat(area.areaId)
        data.DeliveryAreas[a].circle.center.lat = parseFloat(area.circle.center.lat)
        data.DeliveryAreas[a].circle.center.lng = parseFloat(area.circle.center.lng)
        data.DeliveryAreas[a].circle.radius = parseFloat(area.circle.radius)

        area.vertices.forEach((vert, v) => {
          data.DeliveryAreas[a].vertices[v].lat = parseFloat(vert.lat)
          data.DeliveryAreas[a].vertices[v].lng = parseFloat(vert.lng)
          if (!data.DeliveryAreas[a].vertices[v].hasOwnProperty('latlng')) {
            data.DeliveryAreas[a].vertices[v].latlng = {
              lat: parseFloat(vert.lat),
              lng: parseFloat(vert.lng)
            }
          }
          data.DeliveryAreas[a].vertices[v].latlng.lat = parseFloat(vert.latlng.lat)
          data.DeliveryAreas[a].vertices[v].latlng.lng = parseFloat(vert.latlng.lng)
        })
      })
      this.viewData = data
      this.init()
    },

    onSaveButtonClick () {
      this.saveStep()
    },

    saveStep () {
      if (!this.viewData) return
      this.$emit('wizard-component-loading', true)
      this.isSaving = true

      const saveData = JSON.parse(JSON.stringify(this.viewData))
      if (saveData.Countries) delete saveData.Countries

      saveData.DeliveryAreas.forEach((item, index) => {
        saveData.DeliveryAreas[index].areaId = saveData.DeliveryAreas[index].area_id = (index + 1)
        if (!saveData.DeliveryAreas[index].color) saveData.DeliveryAreas[index].color = this.getAreaColor(index)
        saveData.DeliveryAreas[index].vertices.forEach((vert, v) => {
          if (!saveData.DeliveryAreas[index].vertices[v].hasOwnProperty('latlng')) {
            saveData.DeliveryAreas[index].vertices[v].latlng = {
              lat: parseFloat(vert.lat),
              lng: parseFloat(vert.lng)
            }
          }
        })
      })

      window.callAS(window.SocketCommand.Settings.Delivery.Save, { Delivery: saveData })
    },

    onSaveDeliveryResult (result) {
      this.isSaving = false
      this.$emit('wizard-component-loading', false)

      if (result.status) {
        this.$emit('wizard-component-complete')
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    getData () {
      window.callAS(window.SocketCommand.Settings.Delivery.Get, {})
    }
  }
}
</script>

<style scoped>
.v-card__actions .v-btn-toggle .v-btn + .v-btn {
  margin-left : 0;
}

.v-btn-toggle .v-btn__content {
  padding : 0 4px;
}

.divTable {
  display     : table;
  width       : 100%;
  padding-top : 12px;
  font-size   : 14px;
  color       : rgba(0, 0, 0, 0.85);
}
</style>
