<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="datatable-object settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Permission.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Permission.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Permission.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Permission.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <filter-search v-model="dataTable.search.terms" />

      <v-tooltip
        color="primary"
        left
      >
        <template #activator="{on}">
          <v-btn
            v-if="userCanManage"
            class="pa-0 elevation-0"
            style="min-width: 48px; min-height: 48px;"
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            small
            depressed
            v-on="on"
            @click="onAddPermissionClick"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ $t('Settings.Permission.Toolbar.Button.Add.Icon') }}
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('Settings.Permission.Toolbar.Button.Add.Title') }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      class="pa-1"
    >
      <v-data-table
        :headers="headers"
        :items="dataTable.data"
        :search="dataTable.search.terms"
        :custom-filter="customSearch"
        :footer-props="dataTable.footerProps"
        :height="dataTable.resize.bodyHeight"
        :loading="dataTable.loading"
        :loading-text="dataTable.text.loadingText"
        :no-data-text="dataTable.text.noDataText"
        :no-results-text="dataTable.text.noResultsText"
        :options.sync="dataTable.options"
        class="elevation-0"
        fixed-header
        hide-default-footer
      >
        <template #[`item.Id`]="{item}">
          <v-chip
            class="justify-center"
            color="grey"
            label
            small
            text-color="white"
          >
            {{ item.Id }}
          </v-chip>
        </template>

        <template #[`item.Name`]="{item}">
          <div :class="{'caption': $vuetify.breakpoint.xsOnly}">
            {{ item.Name }}
          </div>

          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="text-caption grey--text"
          >
            {{ item.Description }}
          </div>
        </template>

        <template #[`item.Access`]="{item}">
          <template v-for="(p, i) in item.Action">
            <v-chip
              :key="i"
              :color="getPermissionColor(p)"
              class="justify-center mr-1"
              label
              small
              style="width: 80px;"
              text-color="white"
            >
              {{ p.toLocaleUpperCase($i18n.locale) }}
            </v-chip>
          </template>
        </template>

        <template #[`item.Requirements`]="{item}">
          <v-icon
            v-if="item.Requires.Parent"
            color="blue"
          >
            arrow_upward
          </v-icon>
          <v-icon
            v-if="item.Requires.Permissions && item.Requires.Permissions.length > 0"
            color="blue"
          >
            check_circle
          </v-icon>
        </template>

        <template #[`item.Active`]="{item}">
          <v-icon
            v-if="item.Status"
            color="green"
          >
            check_circle
          </v-icon>
          <v-icon
            v-else
            color="red"
          >
            remove_circle
          </v-icon>
        </template>

        <template #[`item.Actions`]="{item}">
          <v-tooltip
            color="grey"
            top
          >
            <template #activator="{on}">
              <v-btn
                v-if="userCanAdd && userCanManage"
                :class="$t('Settings.Permission.Toolbar.Button.Edit.Color')"
                class="elevation-2 ma-1"
                dark
                icon
                v-on="on"
                @click="onEditPermissionClick(item)"
              >
                <v-icon>{{ $t('Settings.Permission.Toolbar.Button.Edit.Icon') }}</v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Settings.Permission.Toolbar.Button.Edit.Title') }}</span>
          </v-tooltip>

          <v-tooltip
            color="red"
            top
          >
            <template #activator="{on}">
              <v-btn
                v-if="userCanDelete && userCanManage"
                :class="$t('Settings.Permission.Toolbar.Button.Delete.Color')"
                class="elevation-2 ma-1"
                dark
                icon
                v-on="on"
                @click="onDeletePermissionClick(item)"
              >
                <v-icon>{{ $t('Settings.Permission.Toolbar.Button.Delete.Icon') }}</v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Settings.Permission.Toolbar.Button.Delete.Title') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>

    <permission-edit-dialog
      :permission="selectedPermission"
      :permissions="dataTable.data"
      :visible.sync="editDialogVisible"
      @permission-save="onSavePermission"
    />

    <confirm-dialog
      :html-content="`${$t('Settings.Permission.Dialog.Delete.Content')} <strong>${selectedPermission ? selectedPermission.Name : ''}</strong>;`"
      :html-title="$t('Settings.Permission.Dialog.Delete.Title')"
      :visible.sync="confirmDialogVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="delete-permission-on-yes-btn"
      @delete-permission-on-yes-btn="onDeletePermission"
    />
  </v-card>
</template>

<script>
import AppData              from '@/mixins/appdata'
import Auth                 from '@/mixins/auth'
import DataTable            from '@/mixins/data-table/dataTable'
import PermissionEditDialog from './permissions/PermissionEditDialog'
import ConfirmDialog        from '../common/ConfirmDialog'
import FilterSearch         from '@/components/common/filter/FilterSearch.vue'
import greekUtils           from '@/lib/greek-utils'

export default {
  name      : 'SystemSettingsPermissions',
  components: {
    FilterSearch,
    ConfirmDialog,
    PermissionEditDialog
  },
  mixins: [AppData, Auth, DataTable],
  data () {
    return {
      selectedPermission  : null,
      editDialogVisible   : false,
      confirmDialogVisible: false,
      editObj             : {
        Action     : [],
        Description: '',
        Id         : null,
        Name       : '',
        Requires   : {
          Parent     : '',
          Permissions: ''
        },
        Status: true
      },
      dataTable: {
        socketCommand: {
          list: window.SocketCommand.Settings.Permissions.All
        },
        resize: {
          offset: 110
        },
        options: {
          serverSideEnabled: false,
          itemsPerPage     : -1
        }
      }
    }
  },
  computed: {
    headers () {
      return [
        {
          text    : '#',
          align   : 'center',
          value   : 'Id',
          sortable: false
        },
        {
          text    : this.$t('Settings.Permission.Header.Permission'),
          align   : 'left',
          value   : 'Name',
          sortable: false
        },
        {
          text    : this.$t('Settings.Permission.Header.Access'),
          align   : 'left',
          value   : 'Access',
          sortable: false
        },
        {
          text    : this.$t('Settings.Permission.Header.Requirements'),
          align   : 'center',
          value   : 'Requirements',
          sortable: false
        },
        {
          text    : this.$t('Common.Button.Toggle.Active'),
          align   : 'center',
          value   : 'Active',
          sortable: false
        },
        {
          text    : '',
          align   : 'right',
          value   : 'Actions',
          sortable: false
        }
      ]
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Permissions.Save, this.onSavePermissionResult)
    this.$bus.$on(window.SocketCommand.Settings.Permissions.Delete, this.onDeletePermissionResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Permissions.Save, this.onSavePermissionResult)
    this.$bus.$off(window.SocketCommand.Settings.Permissions.Delete, this.onDeletePermissionResult)
  },
  methods: {
    itemHasChildren (item) {
      return this.dataTable.data.filter(p => p.Requires.Parent === item.Id).length > 0
    },
    onAddPermissionClick () {
      this.selectedPermission = JSON.parse(JSON.stringify(this.editObj))
      this.editDialogVisible = true
    },
    onEditPermissionClick (p) {
      this.selectedPermission = JSON.parse(JSON.stringify(p))
      this.editDialogVisible = true
    },
    onDeletePermissionClick (p) {
      this.selectedPermission = JSON.parse(JSON.stringify(p))
      this.confirmDialogVisible = true
    },

    onSavePermission (data) {
      window.callAS(window.SocketCommand.Settings.Permissions.Save, { Permission: data })
    },
    onSavePermissionResult (data) {
      if (data.status) {
        this.editDialogVisible = false
        this.getData()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Permission.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onDeletePermission () {
      window.callAS(window.SocketCommand.Settings.Permissions.Delete, { Permission: this.selectedPermission })
    },
    onDeletePermissionResult (data) {
      if (data.status) {
        this.getData()
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Permission.Notification.Delete.Success'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Permission.Notification.Delete.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    getPermissionColor (p) {
      switch (p.toLocaleUpperCase(this.$i18n.locale)) {
        case 'API':
          return 'purple'
        case 'ACCESS':
          return 'green'
        case 'MANAGE':
          return 'blue'
        case 'ADD':
          return 'amber darken-3'
        case 'DELETE':
          return 'red'
        default:
          return 'grey'
      }
    },

    customSearch (value, search, item) {
      if (!search) return

      const searchString = greekUtils.toGreeklish(String(search).toLocaleLowerCase(this.$i18n.locale))

      const itemString = [
        item.Id && String(item.Id),
        item.Name && greekUtils.toGreeklish(String(item.Name).toLocaleLowerCase(this.$i18n.locale))

      ].filter(Boolean).join(' ')

      return itemString.includes(searchString)
    }
  }
}
</script>

<style scoped>

</style>
