export default {
  data () {
    return {}
  },
  mixins    : [],
  props     : {},
  directives: {},
  watch     : {},
  computed  : {
    customerSourceTypeList () {
      const sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})

      return sourceTypesDefault.filter(sourceType => sourceType.active && [1, 5, 10].includes(sourceType.source_type_id)).map(o => {
        return {
          Id    : o.source_type_id,
          Name  : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Avatar: {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })
    }
  },
  methods: {
    getCustomerRegistrationType (socialProvider) {
      if (socialProvider === 'Email') {
        return {
          Icon : 'email',
          Color: 'blue-grey',
          Type : 'Email',
          Name : `${ this.$t('Customer.CustomerType.Registered') } ${ this.$t('Customer.CustomerType.Through') } Email`
        }
      }

      if (socialProvider === 'Google') {
        return {
          Icon : 'fab fa-google-plus-square',
          Color: 'red lighten-2',
          Type : 'Google',
          Name : `${ this.$t('Customer.CustomerType.Registered') } ${ this.$t('Customer.CustomerType.Through') } Google`
        }
      }

      if (socialProvider === 'Facebook') {
        return {
          Icon : 'fab fa-facebook-square',
          Color: 'blue darken-1',
          Type : 'Facebook',
          Name : `${ this.$t('Customer.CustomerType.Registered') } ${ this.$t('Customer.CustomerType.Through') } Facebook`
        }
      }

      return {
        Icon : 'verified_user',
        Color: 'amber lighten-1',
        Type : 'Guest',
        Name : `${ this.$t('Customer.CustomerType.Registered') }`
      }
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {}
}
