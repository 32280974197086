import BaseModel               from '@/lib/data/Models/BaseModel'
import AccountBillingValidator from '@/api/Validations/account/AccountBillingValidator'

export default class AccountBillingModel extends BaseModel {
  constructor (rawData = {}, validator = AccountBillingValidator) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.LeadId = rawData.LeadId || null
    this.CustomerId = rawData.CustomerId || null
    this.Firstname = rawData.Firstname || ''
    this.Lastname = rawData.Lastname || ''
    this.Telephone = rawData.Telephone || ''
    this.Email = rawData.Email || ''
    this.IsReceipt = rawData.IsReceipt ?? false
    this.Company = rawData.Company || ''
    this.Profession = rawData.Profession || ''
    this.DistinctiveTitle = rawData.DistinctiveTitle || ''
    this.Irs = rawData.Irs || ''
    this.Vat = rawData.Vat || ''
    this.Gemi = rawData.Gemi || ''
    this.CountryId = rawData.CountryId || '86'
    this.City = rawData.City || ''
    this.Street = rawData.Street || ''
    this.State = rawData.State || ''
    this.PostCode = rawData.PostCode || ''
    this.CompanyPhone = rawData.CompanyPhone || ''
  }

  boot () {
    super.boot()

    this.guarded = ['LeadId', 'CustomerId']
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */
}
