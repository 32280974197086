<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-text class="ma-0 pa-0">
      <v-tabs-items v-model="tab">
        <v-tab-item key="details">
          <v-form
            ref="restaurantForm"
            v-model="restaurantFormValid"
            @submit.prevent
          >
            <v-card flat>
              <v-card-title>
                <v-icon class="pr-3">
                  {{ $t('Settings.Restaurant.Field.CompanyDetails.Icon') }}
                </v-icon>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Restaurant.Field.CompanyDetails.Title') }}
                </h5>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="viewData.Restaurant.Name"
                      :counter="60"
                      :hint="$t('Settings.Restaurant.Field.CompanyDetails.Name.Hint')"
                      :label="$t('Settings.Restaurant.Field.CompanyDetails.Name.Label')"
                      :prepend-icon="$t('Settings.Restaurant.Field.CompanyDetails.Name.Icon')"
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="viewData.Restaurant.Email"
                      :counter="128"
                      :hint="$t('Settings.Restaurant.Field.CompanyDetails.Email.Hint')"
                      :label="$t('Settings.Restaurant.Field.CompanyDetails.Email.Label')"
                      :prepend-icon="$t('Settings.Restaurant.Field.CompanyDetails.Email.Icon')"
                      required
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="viewData.Restaurant.Phone1"
                      :counter="32"
                      :hint="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Hint')"
                      :label="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Label')"
                      :max="32"
                      :min="5"
                      :prepend-icon="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Icon')"
                      required
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="viewData.Restaurant.Phone2"
                      :counter="32"
                      :hint="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Hint')"
                      :label="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Label')"
                      :max="32"
                      :min="5"
                      :prepend-icon="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Icon')"
                      required
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="viewData.Restaurant.Phone3"
                      :counter="32"
                      :hint="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Hint')"
                      :label="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Label')"
                      :max="32"
                      :min="5"
                      :prepend-icon="$t('Settings.Restaurant.Field.CompanyDetails.Phone.Icon')"
                      required
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat>
              <v-card-title>
                <v-icon class="pr-3">
                  {{ $t('Settings.Restaurant.Field.CompanyDetails.Logo.Icon') }}
                </v-icon>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Restaurant.Field.CompanyDetails.Logo.Title') }}
                </h5>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col
                    class="text-center"
                    sm="6"
                    cols="12"
                  >
                    <div>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Restaurant.Field.CompanyDetails.Logo.TitleLight') }}
                      </h5>
                    </div>
                    <image-crop-selector
                      v-model="croppaLight"
                      :container-class="`transparent`"
                      :height="150"
                      :initial-image="restaurantLogoLight"
                      :initial-image-class="`pa-2`"
                      :initial-image-style="`border: 1px solid #1c1c1c !important; background-color: #262626;`"
                      :placeholder-color="`#ffffff`"
                      :placeholder-font-size="15"
                      :prevent-white-space="false"
                      :quality="1"
                      :replace-drop="true"
                      :show-loading="true"
                      :width="340"
                      @new-image-drawn="onPhotoLightChange"
                      @image-remove="onPhotoLightRemoved"
                    />
                  </v-col>

                  <v-col
                    class="text-center"
                    sm="6"
                    cols="12"
                  >
                    <div>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Restaurant.Field.CompanyDetails.Logo.TitleDark') }}
                      </h5>
                    </div>
                    <image-crop-selector
                      v-model="croppaDark"
                      :container-class="`transparent`"
                      :height="150"
                      :initial-image="restaurantLogoDark"
                      :initial-image-class="`pa-2`"
                      :initial-image-style="`border: 1px solid #d7d7d7 !important; background-color: #fafafa;`"
                      :placeholder-color="`#000000`"
                      :placeholder-font-size="15"
                      :prevent-white-space="false"
                      :quality="1"
                      :replace-drop="true"
                      :show-loading="true"
                      :width="340"
                      @new-image-drawn="onPhotoDarkChange"
                      @image-remove="onPhotoDarkRemoved"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-tab-item>

        <v-tab-item key="address">
          <v-form
            ref="addressForm"
            v-model="addressFormValid"
            @submit.prevent
          >
            <v-card flat>
              <v-card-title>
                <v-icon class="pr-3">
                  {{ $t('Settings.Restaurant.Field.CompanyAddress.Icon') }}
                </v-icon>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Restaurant.Field.CompanyAddress.Title') }}
                </h5>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-google-autocomplete-service
                      v-model="autoCompleteModel"
                      :hint="$t('Settings.Restaurant.Field.CompanyAddress.Address.Hint')"
                      :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.Address.PlaceHolder')"
                      :types="['geocode']"
                      autofocus
                      lazy
                      @place-changed="fillInAddressNoLimitis"
                    />

                    <!--                    <div class="input-group input-group&#45;&#45;prepend-icon input-group&#45;&#45;text-field">
                                          <div class="input-group__input">
                                            <vuetify-google-autocomplete
                                              id="map_address"
                                              ref="formatted"
                                              v-model="autoCompleteModel"
                                              :hint="$t('Settings.Restaurant.Field.CompanyAddress.Address.Hint')"
                                              :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.Address.PlaceHolder')"
                                              :prepend-icon="$t('Settings.Restaurant.Field.CompanyAddress.Address.Icon')"
                                              autofocus
                                              classname="form-control"
                                              clearable
                                              required
                                              types="geocode"
                                              @placechanged="fillInAddressNoLimitis"
                                            />
                                          </div>
                                          <div
                                            class="input-group__details"
                                            style="min-height:0;"
                                          />
                                        </div>-->
                  </v-col>
                  <v-col cols="12">
                    <gmap-map
                      ref="dialogMap"
                      :center="mapCenter"
                      :options="{styles: [{'featureType': 'poi', 'stylers': [{'visibility': 'off'}]}]}"
                      :zoom="18"
                      map-type-id="roadmap"
                      style="width:100%; height: 225px"
                    >
                      <gmap-marker
                        ref="addressMarker"
                        :draggable="true"
                        :position="markerStore"
                        label="★"
                        @dragend="onMarkerDragEndNoLimits"
                      />
                    </gmap-map>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <v-switch
                          v-model="viewData.UseCustomAddress"
                          inset
                          :label="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Label')"
                          class=""
                          color="primary"
                          hide-details
                        />
                      </v-col>
                      <template v-if="!viewData.UseCustomAddress">
                        <v-col cols="12">
                          <v-google-autocomplete-service
                            v-model="viewData.Address.City"
                            :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.Hint')"
                            :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.PlaceHolder')"
                            :types="['(cities)']"
                            lazy
                            @blur="getAddressNoLimits"
                            @place-changed="fillInAddressNoLimitis"
                          />

                          <!--                          <div class="input-group input-group&#45;&#45;prepend-icon input-group&#45;&#45;text-field">
                                                      <div class="input-group__input">
                                                        <vuetify-google-autocomplete
                                                          id="map_area"
                                                          v-model="viewData.Address.City"
                                                          :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.Hint')"
                                                          :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.PlaceHolder')"
                                                          :prepend-icon="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.Icon')"
                                                          classname="form-control"
                                                          hide-details
                                                          required
                                                          types="(cities)"
                                                          @blur="getAddressNoLimits"
                                                          @placechanged="fillInAddressNoLimitis"
                                                        />
                                                      </div>
                                                      <div
                                                        class="input-group__details"
                                                        style="min-height:0;"
                                                      />
                                                    </div>-->
                        </v-col>
                        <v-col cols="6">
                          <v-google-autocomplete-service
                            v-model="viewData.Address.Street"
                            :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.Hint')"
                            :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.PlaceHolder')"
                            :types="['address']"
                            lazy
                            @blur="getAddressNoLimits"
                            @place-changed="fillInAddressNoLimitis"
                          />

                          <!--                          <div class="input-group input-group&#45;&#45;prepend-icon input-group&#45;&#45;text-field">
                                                      <div class="input-group__input">
                                                        <vuetify-google-autocomplete
                                                          id="map_street"
                                                          v-model="viewData.Address.Street"
                                                          :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.Hint')"
                                                          :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.PlaceHolder')"
                                                          :prepend-icon="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.Icon')"
                                                          classname="form-control"
                                                          required
                                                          types="address"
                                                          @blur="getAddressNoLimits"
                                                          @placechanged="fillInAddressNoLimitis"
                                                        />
                                                      </div>
                                                      <div
                                                        class="input-group__details"
                                                        style="min-height:0;"
                                                      />
                                                    </div>-->
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="viewData.Address.Number"
                            :label="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Number.Label')"
                            required
                            @blur="getAddressNoLimits"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="viewData.Address.Postcode"
                            :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Postcode.Hint')"
                            :label="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Postcode.Label')"
                            required
                          />
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="12">
                          <div class="input-group input-group--prepend-icon input-group--text-field">
                            <div class="input-group__input">
                              <v-text-field
                                id="map_area"
                                v-model="viewData.Address.City"
                                :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.Hint')"
                                :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.PlaceHolder')"
                                :prepend-icon="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.Icon')"
                                classname="form-control"
                                hide-details
                                required
                                types="(cities)"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="input-group input-group--prepend-icon input-group--text-field">
                            <div class="input-group__input">
                              <v-text-field
                                id="map_street"
                                v-model="viewData.Address.Street"
                                :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.Hint')"
                                :placeholder="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.PlaceHolder')"
                                :prepend-icon="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.Icon')"
                                classname="form-control"
                                hide-details
                                required
                                types="address"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="viewData.Address.Number"
                            :label="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Number.Label')"
                            required
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="viewData.Address.Postcode"
                            :hint="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Postcode.Hint')"
                            :label="$t('Settings.Restaurant.Field.CompanyAddress.CustomAddress.Postcode.Label')"
                            required
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import AppData                    from '../../../mixins/appdata'
import Auth                       from '../../../mixins/auth'
import GMapAddressValidate        from '../../../mixins/gmapaddressvalidate'
import ImageCropSelector          from '@/components/common/ImageCropSelector'
import VGoogleAutocompleteService from '@/components/common/google/VGoogleAutocompleteService.vue'

export default {
  name      : 'SettingsWizardRestaurant',
  components: {
    VGoogleAutocompleteService,
    'image-crop-selector': ImageCropSelector
  },
  mixins: [AppData, Auth, GMapAddressValidate],
  props : {
    wizardVisible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      isSaving           : false,
      tab                : 0,
      tabs               : ['details', 'address'],
      restaurantFormValid: false,
      addressFormValid   : false,
      tmpImageLight      : null,
      tmpImageDark       : null,
      tmpImageBase64Light: null,
      tmpImageBase64Dark : null,
      viewData           : null,
      croppaLight        : null,
      croppaDark         : null
    }
  },
  computed: {
    restaurantLogoLight () {
      return this.viewData.Restaurant.Image ? this.appConfig.LOCATION_DATA.CdnImagesUrl + this.viewData.Restaurant.Image : ''
    },
    restaurantLogoDark () {
      return this.viewData.Restaurant.ImageDark ? this.appConfig.LOCATION_DATA.CdnImagesUrl + this.viewData.Restaurant.ImageDark : ''
    },

    newAddress: {
      get () {
        return this.viewData.Address
      },
      set (data) {
        this.viewData.Address = data
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Restaurant.Get, this.onGetRestaurantResult)
    this.$bus.$on(window.SocketCommand.Settings.Restaurant.Save, this.onSaveRestaurantResult)
    this.getData()
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Restaurant.Get, this.onGetRestaurantResult)
    this.$bus.$off(window.SocketCommand.Settings.Restaurant.Save, this.onSaveRestaurantResult)
  },
  methods: {
    onGetRestaurantResult (result) {
      this.viewData = result
      this.autoCompleteModel = this?.viewData?.Address?.Formatted || ''
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.Restaurant.Get, {})
    },

    saveStep () {
      if (!this.viewData) return
      this.$emit('wizard-component-loading', true)

      this.isSaving = true
      this.onPhotoLightChange()
      this.onPhotoDarkChange()
      this.viewData.Restaurant.ImageBase64 = this.viewData.Restaurant.Image ? null : this.tmpImageBase64Light ? this.tmpImageBase64Light.img : ''
      this.viewData.Restaurant.ImageMime = this.tmpImageBase64Light ? this.tmpImageBase64Light.mime : ''
      this.viewData.Restaurant.ImageExt = this.tmpImageBase64Light ? this.tmpImageBase64Light.ext : ''

      this.viewData.Restaurant.ImageBase64Dark = this.viewData.Restaurant.ImageDark ? null : this.tmpImageBase64Dark ? this.tmpImageBase64Dark.img : ''
      this.viewData.Restaurant.ImageMimeDark = this.tmpImageBase64Dark ? this.tmpImageBase64Dark.mime : ''
      this.viewData.Restaurant.ImageExtDark = this.tmpImageBase64Dark ? this.tmpImageBase64Dark.ext : ''

      window.callAS(window.SocketCommand.Settings.Restaurant.Save, this.viewData, '', 300000)
    },

    onSaveRestaurantResult (data) {
      if (data.status) {
        this.$emit('wizard-component-complete')
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Restaurant.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.$emit('wizard-component-loading', false)
      this.isSaving = false
    },

    onPhotoLightChange () {
      if (this.croppaLight && this.croppaLight.getChosenFile() && !this.viewData.Restaurant.Image) {
        this.tmpImageLight = this.croppaLight.getChosenFile()
        this.tmpImageBase64Light = {
          img : this.croppaLight.generateDataUrl(),
          mime: this.tmpImageLight.type,
          ext : this.tmpImageLight.name.split('.')[1]
        }
      }
    },
    onPhotoLightRemoved () {
      this.viewData.Restaurant.Image = ''
      this.tmpImageLight = null
      this.tmpImageBase64Light = null
    },

    onPhotoDarkChange () {
      if (this.croppaDark && this.croppaDark.getChosenFile() && !this.viewData.Restaurant.ImageDark) {
        this.tmpImageDark = this.croppaDark.getChosenFile()
        this.tmpImageBase64Dark = {
          img : this.croppaDark.generateDataUrl(),
          mime: this.tmpImageDark.type,
          ext : this.tmpImageDark.name.split('.')[1]
        }
      }
    },
    onPhotoDarkRemoved () {
      this.viewData.Restaurant.ImageDark = ''
      this.tmpImageDark = null
      this.tmpImageBase64Dark = null
    }
  }
}
</script>

<style scoped>
/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}
</style>
