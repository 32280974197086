<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-1">
        <v-toolbar
          class="filter-toolbar"
          :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
          flat
        >
          <v-btn
            v-if="userCanAdd"
            :color="$t('Reservation.Table.Button.New.Color')"
            :icon="$vuetify.breakpoint.xsOnly"
            class="elevation-0 ma-1"
            text
            @click="onEditClick(undefined)"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $t('Reservation.Table.Button.New.Icon') }}
            </v-icon>&nbsp;
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Reservation.Table.Button.New.Title').toLocaleUpperCase($i18n.locale) }}</span>
          </v-btn>

          <v-spacer />

          <filter-search v-model="dataTable.search.terms" />

          <filter-options
            v-model="filtersModel"
            :filters="filterData"
          />

          <v-btn
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
            class="pa-0 ma-1 elevation-0"
            @click="onToggleView"
          >
            <v-icon
              color="purple"
              v-text="'mdi-calendar-clock'"
            />
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-container
        class="ma-0 pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :footer-props="dataTable.footerProps"
              :headers="dataTableHeaders"
              :height="dataTable.resize.bodyHeight"
              :items="dataTable.data"
              :loading="dataTable.loading"
              :loading-text="dataTable.text.loadingText"
              :no-data-text="dataTable.text.noDataText"
              :no-results-text="dataTable.text.noResultsText"
              :options.sync="dataTable.options"
              :search="dataTable.search.terms"
              :server-items-length="dataTable.total"
              fixed-header
              item-key="Id"
            >
              <template #[`item.reserve_code`]="{item}">
                <v-tooltip
                  :color="getSourceTypeById(item.SourceType).Avatar.Color + ' darken-2'"
                  top
                >
                  <template #activator="{on}">
                    <v-chip
                      :color="getSourceTypeById(item.SourceType).Avatar.Color"
                      class="darken-1 mr-1 px-1"
                      label
                      small
                      dark
                      v-on="on"
                    >
                      <v-icon
                        dark
                        small
                      >
                        {{ getSourceTypeById(item.SourceType).Avatar.Icon }}
                      </v-icon>
                    </v-chip>
                  </template>
                  <span>{{ getSourceTypeById(item.SourceType).Name }}</span>
                </v-tooltip>

                <v-chip
                  v-if="userCanManage"
                  small
                  label
                  class="justify-center px-2 font-weight-medium"
                  style="min-width: 90px;"
                  @click="onEditClick(item)"
                >
                  {{ item.ReserveCode }}
                </v-chip>

                <v-chip
                  v-else
                  small
                  label
                  class="justify-center px-2 font-weight-medium"
                  style="min-width: 90px;"
                >
                  {{ item.ReserveCode }}
                </v-chip>
              </template>

              <template #[`item.status_id`]="{item}">
                <v-chip
                  :class="getReservationStatusById(item.StatusId).Avatar.Color"
                  style="min-width: 165px;"
                  class="mx-1 pl-0"
                  small
                  label
                  dark
                >
                  <v-avatar
                    :color="getReservationStatusById(item.StatusId).Avatar.Color"
                    class="darken-2 mr-2"
                    tile
                  >
                    <v-icon
                      dark
                      small
                    >
                      {{ getReservationStatusById(item.StatusId).Avatar.Icon }}
                    </v-icon>
                  </v-avatar>

                  {{ getReservationStatusById(item.StatusId).Name.toLocaleUpperCase($i18n.locale) }}
                </v-chip>
              </template>

              <template #[`item.tags`]="{item}">
                <div
                  style="max-width: 84px;"
                  class="d-inline-block"
                >
                  <template v-for="tagId in item.Tags">
                    <v-tooltip
                      :key="`reservation-tag-${tagId}`"
                      :color="getReservationTagById(tagId).Avatar.Color + ' darken-2'"
                      top
                    >
                      <template #activator="{on}">
                        <v-chip
                          :color="getReservationTagById(tagId).Avatar.Color"
                          class="darken-1 mr-1 mb-1 px-1"
                          label
                          small
                          dark
                          v-on="on"
                        >
                          <v-icon
                            dark
                            small
                          >
                            {{ getReservationTagById(tagId).Avatar.Icon }}
                          </v-icon>
                        </v-chip>
                      </template>
                      <span>{{ getReservationTagById(tagId).Name }}</span>
                    </v-tooltip>
                  </template>
                </div>
              </template>

              <template #[`item.reserve_date`]="{item}">
                {{ item.ReserveDate }} - {{ item.ReserveTime }}
              </template>

              <template #[`item.arrival_time`]="{item}">
                {{ item.ArrivalTime }}
              </template>

              <template #[`item.departure_time`]="{item}">
                {{ item.DepartureTime }}
              </template>

              <template #[`item.persons`]="{item}">
                <v-chip
                  :color="reservationHasInvalidTablePersons(item.Persons, item.Tables) ? 'warning' : 'grey darken-2'"
                  class="mx-1 px-0"
                  small
                  label
                  dark
                >
                  <v-avatar
                    class="grey darken-3"
                    tile
                  >
                    <v-icon
                      dark
                      small
                    >
                      people
                    </v-icon>
                  </v-avatar>

                  <span
                    style="min-width: 30px;"
                    class="px-1 text-center"
                  >
                    {{ item.Persons }}
                  </span>
                </v-chip>
              </template>

              <template #[`item.duration`]="{item}">
                {{ convertMinutesToDisplayString(item.Duration, false, true) }}
              </template>

              <template #[`item.customer_id`]="{item}">
                <div class="text-no-wrap">
                  <v-icon
                    :color="item.CustomerId ? 'amber' : 'grey'"
                    class="text--lighten-2"
                    small
                  >
                    verified_user
                  </v-icon>

                  <span
                    v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && item.CustomerId"
                    class="text-no-wrap"
                    :class="isDarkMode ? 'grey--text text--lighten-1' : 'grey--text text--darken-3'"
                    style="cursor: pointer;"
                    @click="$bus.$emit('show-customer-details-dialog', item.CustomerId)"
                  >
                    {{ item.FirstName }} {{ item.LastName }}
                  </span>

                  <template v-else>
                    <span
                      v-if="item.FirstName || item.LastName"
                      class="text-no-wrap"
                      :class="isDarkMode ? 'grey--text text--lighten-1' : 'grey--text text--darken-3'"
                    >
                      {{ item.FirstName }} {{ item.LastName }}
                    </span>
                    <span
                      v-else
                      class="text-no-wrap"
                      :class="isDarkMode ? 'grey--text text--lighten-1' : 'grey--text text--darken-3'"
                    >
                      {{ $t('Staff.ListView.Table.Headers.Guest') }}
                    </span>
                  </template>
                </div>

                <div
                  v-if="item.Telephone"
                  class="grey--text text-no-wrap"
                >
                  <v-icon
                    color="blue lighten-2"
                    small
                  >
                    phone
                  </v-icon>

                  {{ item.Telephone | phone }}
                </div>

                <div
                  v-if="item.Email && !emailIsGuest(item.Email)"
                  class="grey--text text-no-wrap"
                >
                  <v-icon
                    color="indigo lighten-2"
                    small
                  >
                    email
                  </v-icon>

                  {{ item.Email }}
                </div>
              </template>

              <template #[`item.table_id`]="{item}">
                <template v-for="table in item.Tables">
                  <v-tooltip
                    :key="`table-tooltip-${table.Id}`"
                    :color="`#${table.Area.Color}`"
                    top
                  >
                    <template #activator="{on}">
                      <v-chip
                        :key="`table-${table.Id}`"
                        :color="`#${table.Area.Color}`"
                        :style="`color: ${(invertColor(table.Area.Color || 'e0e0e0', true))}`"
                        class="px-2 mr-1 mb-1 justify-center"
                        small
                        label
                        dark
                        v-on="on"
                      >
                        <span class="font-weight-bold mr-2">#{{ table.Name }}</span>
                        <v-chip
                          x-small
                          label
                          class="text-caption px-1"
                          :class="!reservationHasInvalidTablePersons(item.Persons, item.Tables) ? 'grey--text text--lighten-2' : 'warning white--text'"
                          :outlined="!reservationHasInvalidTablePersons(item.Persons, item.Tables)"
                        >
                          {{ table.MinCapacity }}-{{ table.MaxCapacity }}
                        </v-chip>
                      </v-chip>
                    </template>

                    <span class="mr-1">{{ table.Area.Name }}</span>
                    <span class="font-weight-bold">#{{ table.Name }}</span>
                  </v-tooltip>
                </template>
              </template>

              <template #[`item.comment`]="{item}">
                <v-tooltip
                  v-if="item.Comment"
                  max-width="350"
                  color="warning"
                  top
                >
                  <template #activator="{on}">
                    <span
                      style="cursor: pointer;"
                      v-on="on"
                    >
                      <v-icon color="warning">mdi mdi-comment-account-outline</v-icon>
                    </span>
                  </template>

                  <template #default>
                    <div class="font-weight-bold mb-1">
                      {{ $t('Reservation.AddEditDialog.Field.Comment.Label') }}
                    </div>
                    <span>{{ item.Comment }}</span>
                  </template>
                </v-tooltip>

                <v-tooltip
                  v-if="item.Notes"
                  max-width="350"
                  color="info darken-1"
                  top
                >
                  <template #activator="{on}">
                    <span
                      style="cursor: pointer;"
                      v-on="on"
                    >
                      <v-icon color="info">mdi mdi-text-box-edit-outline</v-icon>
                    </span>
                  </template>

                  <template #default>
                    <div class="font-weight-bold mb-1">
                      {{ $t('Reservation.AddEditDialog.Field.Notes.Label') }}
                    </div>
                    <span>{{ item.Notes }}</span>
                  </template>
                </v-tooltip>
              </template>

              <template #[`item.actions`]="{item}">
                <v-speed-dial
                  v-model="item.fab"
                  direction="left"
                  transition="slide-x-reverse-transition"
                >
                  <template #activator>
                    <v-btn
                      class="blue-grey lighten-2 elevation-2 ma-0"
                      dark
                      icon
                      :small="$vuetify.breakpoint.xsOnly"
                    >
                      <v-icon :small="$vuetify.breakpoint.xsOnly">
                        {{ item.fab ? 'close' : 'menu' }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <template v-if="userCanManage && [ReservationStatusEnum.Arrived, ReservationStatusEnum.PartiallyArrived].includes(item.StatusId)">
                    <v-tooltip
                      :color="$t('Reservation.Table.Button.Finished.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          :class="$t('Reservation.Table.Button.Finished.Color')"
                          dark
                          icon
                          v-on="on"
                          @click="onTableReservationFinishedClick(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Reservation.Table.Button.Finished.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Reservation.Table.Button.Finished.Tooltip') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="userCanManage && [ReservationStatusEnum.Confirmed, ReservationStatusEnum.PartiallyArrived].includes(item.StatusId)">
                    <v-tooltip
                      :color="$t('Reservation.Table.Button.Arrived.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          :class="$t('Reservation.Table.Button.Arrived.Color')"
                          dark
                          icon
                          v-on="on"
                          @click="onTableReservationArrivedClick(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Reservation.Table.Button.Arrived.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Reservation.Table.Button.Arrived.Tooltip') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="userCanManage && [ReservationStatusEnum.Confirmed].includes(item.StatusId)">
                    <v-tooltip
                      :color="$t('Reservation.Table.Button.PartiallyArrived.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          :class="$t('Reservation.Table.Button.PartiallyArrived.Color')"
                          dark
                          icon
                          v-on="on"
                          @click="onTableReservationPartiallyArrivedClick(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Reservation.Table.Button.PartiallyArrived.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Reservation.Table.Button.PartiallyArrived.Tooltip') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="userCanManage">
                    <v-tooltip
                      :color="$t('Reservation.Table.Button.Edit.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          :class="$t('Reservation.Table.Button.Edit.Color')"
                          dark
                          icon
                          v-on="on"
                          @click="onTableReservationEditClick(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Reservation.Table.Button.Edit.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Reservation.Table.Button.Edit.Tooltip') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="userCanDelete">
                    <v-tooltip
                      :color="$t('Reservation.Table.Button.Delete.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          :class="$t('Reservation.Table.Button.Delete.Color')"
                          dark
                          icon
                          v-on="on"
                          @click="onTableReservationDeleteClick(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Reservation.Table.Button.Delete.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Reservation.Table.Button.Delete.Tooltip') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="userCanDelete && ![ReservationStatusEnum.Canceled].includes(item.StatusId)">
                    <v-tooltip
                      :color="$t('Reservation.Table.Button.Cancel.Color')"
                      top
                    >
                      <template #activator="{on}">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          :class="$t('Reservation.Table.Button.Cancel.Color')"
                          dark
                          icon
                          v-on="on"
                          @click="onTableReservationCancelClick(item)"
                        >
                          <v-icon :small="$vuetify.breakpoint.xsOnly">
                            {{ $t('Reservation.Table.Button.Cancel.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('Reservation.Table.Button.Cancel.Tooltip') }}</span>
                    </v-tooltip>
                  </template>
                </v-speed-dial>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppData                       from '@/mixins/appdata'
import Auth                          from '@/mixins/auth'
import FilterCommon                  from '@/mixins/filter/filterCommon'
import DataTable                     from '@/mixins/data-table/dataTable'
import OrderTypeTime                 from '@/mixins/orders/orderTypeTime'
import FilterOptions                 from '@/components/common/filter/FilterOptions'
import FilterSearch                  from '@/components/common/filter/FilterSearch'
import ReservationTags               from '@/mixins/reservation/reservationTags'
import ReservationStatus             from '@/mixins/reservation/reservationStatus'
import { emailIsGuest, invertColor } from '@/lib/helper/helper'
import SourceType                    from '@/mixins/orders/sourceType'
import ReservationCommon             from '@/mixins/reservation/reservationCommon'
import SourceTypeEnum                from '@/api/Enums/SourceTypeEnum'
import ReservationStatusEnum         from '@/api/Enums/ReservationStatusEnum'
import ReservationActions            from '@/mixins/reservation/reservationActions'

export default {
  name      : 'TableReservationTableView',
  components: {
    FilterOptions,
    FilterSearch
  },
  directives: {},
  mixins    : [AppData, Auth, FilterCommon, DataTable, SourceType, OrderTypeTime, ReservationCommon, ReservationActions, ReservationStatus, ReservationTags],
  props     : {
    view: {
      type   : String,
      default: 'Table'
    }
  },
  data () {
    return {
      dataTable: {
        // dataStore    : 'customers',
        socketCommand: {
          list: window.SocketCommand.Table.Reservation.All,
          save: window.SocketCommand.Table.Reservation.Save
        },
        options: {
          defaultSortBy: 'reservation_id',
          sortBy       : ['reservation_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Reservation.Table.Headers.Code'),
            value    : 'reserve_code',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption text-no-wrap px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Status'),
            value    : 'status_id',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Reservation'),
            value    : 'reserve_date',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption font-weight-medium px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Arrival'),
            value    : 'arrival_time',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Departure'),
            value    : 'departure_time',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Persons'),
            value    : 'persons',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Duration'),
            value    : 'duration',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Customer'),
            value    : 'customer_id',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Table'),
            value    : 'table_id',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Tags'),
            value    : 'tags',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Reservation.Table.Headers.Comments'),
            value    : 'comment',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption text-no-wrap px-1'
          },
          {
            text     : '',
            value    : 'actions',
            align    : 'right',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption text-no-wrap px-1',
            width    : 40
          }
        ]
      },

      filtersModel: {
        lang           : 'Reservation.Table',
        filterWithTable: {
          key  : 'filter_with_table',
          value: null,
          items: this.$t('Reservation.Table.Filter.filterWithTable.Items')
        },
        filterStatus: {
          key  : 'filter_status',
          value: null,
          items: this.$t('Reservation.Status.Items')
        },
        filterTable: {
          key  : 'filter_table',
          value: [],
          items: []
        },
        filterDate: {
          key    : 'filter_date',
          value  : null,
          visible: false,
          date   : null
        },
        filterTimeStart: {
          key    : 'filter_time_start',
          value  : null,
          visible: false,
          time   : null
        },
        filterTimeEnd: {
          key    : 'filter_time_end',
          value  : null,
          visible: false,
          time   : null
        }
      }
    }
  },
  computed: {
    toggleView: {
      get () {
        return this.view || ''
      },
      set (val) {
        this.$emit('update:view', val)
      }
    },

    ReservationStatusEnum () {
      return ReservationStatusEnum
    },

    tables () {
      const areas = this.$DataStore.tables || []
      const tables = []

      areas.forEach(area => {
        area.Tables.forEach(table => {
          const tbl = {
            Id            : table.Id,
            Name          : `${ area.Name } #${ table.Name }`,
            Description   : '',
            Bookable      : table.Bookable,
            BookableOnline: table.BookableOnline,
            MinCapacity   : table.MinCapacity,
            MaxCapacity   : table.MaxCapacity,
            Area          : {
              Id   : area.Id,
              Name : area.Name,
              Color: area.Color
            },
            Avatar: {
              Color: area.Color ? `#${ area.Color }` : ''
            }
          }
          tables.push(tbl)
        })
      })

      return tables
    },
    sourceTypes () {
      const sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT) || []

      return sourceTypesDefault.map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: o.source_type_id === SourceTypeEnum.VOIP ? 'orange darken-1' : 'blue-grey'
          }
        }
      })
    }
  },
  watch: {
    '$DataStore.tables': function () {
      this.filtersModel.filterTable.items = this.tables
    }
  },
  beforeCreate () {},
  created () {
    // Init Custom Filter Values
    this.filtersModel.filterTable.items = this.tables
  },
  beforeMount () {},
  mounted () {
    this.$bus.$on(window.SocketCommand.Table.Reservation.Cancel, this.getData)
    this.$bus.$on(window.SocketCommand.Table.Reservation.Delete, this.getData)
    this.$bus.$on(window.SocketCommand.Table.Reservation.Status, this.getData)
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Table.Reservation.Cancel, this.getData)
    this.$bus.$off(window.SocketCommand.Table.Reservation.Delete, this.getData)
    this.$bus.$off(window.SocketCommand.Table.Reservation.Status, this.getData)
  },
  destroyed () {},
  methods: {
    emailIsGuest,
    invertColor,

    onEditClick (item) {
      this.$bus.$emit('show-table-reservation-dialog', { Reservation: item })
    },

    getSourceTypeById (id) {
      return this.sourceTypes.find(sourceType => parseInt(sourceType.Id) === parseInt(id))
    },

    onToggleView () {
      this.toggleView = this.toggleView === 'Table' ? 'Calendar' : 'Table'
    }
  }
}
</script>

<style scoped>

</style>
