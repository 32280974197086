<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1200"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1"
          extended
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Faq.FaqsAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Faq.FaqsAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!itemToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Faq.FaqsAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Faq.FaqsAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Faq.FaqsAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Faq.FaqsAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs
              v-model="tab"
              :slider-color="$t('Faq.FaqsAddDialog.Toolbar.Color')"
              :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
              grow
              height="40"
            >
              <v-tab :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'">
                FAQ
              </v-tab>

              <v-tab :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'">
                CONTENT
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="pa-3"
          style="overflow: hidden;"
        >
          <v-form
            ref="itemForm"
            v-model="formValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-container
                    class="pa-1"
                    fluid
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editItem.CategoryId"
                          :item-text="(data) => getTranslatedField(data, 'Title')"
                          :items="categories"
                          :label="$t('Faq.FaqsAddDialog.Field.Category.Label')"
                          :prepend-icon="$t('Faq.FaqsAddDialog.Field.Category.Icon')"
                          :rules="validationRules.itemForm.Category"
                          clearable
                          item-value="Id"
                          required
                          single-line
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editItem.Priority"
                          :hint="$t('Faq.FaqsAddDialog.Field.Priority.Hint')"
                          :label="$t('Faq.FaqsAddDialog.Field.Priority.Label')"
                          :prepend-icon="$t('Faq.FaqsAddDialog.Field.Priority.Icon')"
                          :rules="validationRules.itemForm.Priority"
                          type="number"
                          step="1"
                          class="pr-2"
                          required
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-switch
                          :id="editItem.Id + '_active'"
                          v-model="editItem.Active"
                          :label="editItem.Active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                          inset
                          color="success"
                        >
                          <template #prepend>
                            <label
                              class="v-label ml-2 text-no-wrap"
                              style="left: auto; right: 0px; position: relative;"
                            >{{ $t('Common.Misc.Status') }}</label>
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12">
                        <template v-if="faqLanguages.length > 1">
                          <v-tabs
                            v-model="languageTab"
                            :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                            grow
                            height="40"
                            slider-color="blue"
                          >
                            <template v-for="lang in faqLanguages">
                              <v-tab
                                :key="lang.locale"
                                :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                              >
                                <v-avatar
                                  class="mr-2"
                                  size="20"
                                >
                                  <v-img :src="`/assets/flags/1x1/${lang.locale}.svg`" />
                                </v-avatar>
                                {{ $te(`Common.Language.${ lang.locale }`) ? $t(`Common.Language.${ lang.locale }`).name.toLocaleUpperCase($i18n.locale) : lang.name.toLocaleUpperCase($i18n.locale) }}
                              </v-tab>
                            </template>
                          </v-tabs>
                        </template>

                        <v-tabs-items v-model="languageTab">
                          <template v-for="lang in faqLanguages">
                            <v-tab-item
                              :key="lang.locale"
                              style="height: calc(100vh - 500px); overflow: auto;"
                              eager
                            >
                              <v-container
                                class="pa-1"
                                fluid
                              >
                                <v-row dense>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="editItem.Lang[lang.locale].Title"
                                      :counter="255"
                                      :hint="$t('Faq.FaqsAddDialog.Field.Title.Hint')"
                                      :label="$t('Faq.FaqsAddDialog.Field.Title.Label')"
                                      :prepend-icon="$t('Faq.FaqsAddDialog.Field.Title.Icon')"
                                      :rules="validationRules.itemForm.Title"
                                      class="pr-2"
                                      required
                                    />
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="editItem.Lang[lang.locale].Description"
                                      :counter="512"
                                      :hint="$t('Faq.FaqsAddDialog.Field.Description.Hint')"
                                      :label="$t('Faq.FaqsAddDialog.Field.Description.Label')"
                                      :prepend-icon="$t('Faq.FaqsAddDialog.Field.Description.Icon')"
                                      :rules="validationRules.itemForm.Description"
                                      class="pr-2"
                                      required
                                    />
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="editItem.Lang[lang.locale].Slug"
                                      :counter="255"
                                      :hint="$t('Faq.FaqsAddDialog.Field.Slug.Hint')"
                                      :label="$t('Faq.FaqsAddDialog.Field.Slug.Label')"
                                      :prepend-icon="$t('Faq.FaqsAddDialog.Field.Slug.Icon')"
                                      :rules="validationRules.itemForm.Slug"
                                      class="pr-2"
                                      required
                                    />
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="editItem.Lang[lang.locale].Tip"
                                      :counter="255"
                                      :hint="$t('Faq.FaqsAddDialog.Field.Tip.Hint')"
                                      :label="$t('Faq.FaqsAddDialog.Field.Tip.Label')"
                                      :prepend-icon="$t('Faq.FaqsAddDialog.Field.Tip.Icon')"
                                      :rules="validationRules.itemForm.Tip"
                                      class="pr-2"
                                      required
                                    />
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="editItem.Lang[lang.locale].Note"
                                      :counter="255"
                                      :hint="$t('Faq.FaqsAddDialog.Field.Note.Hint')"
                                      :label="$t('Faq.FaqsAddDialog.Field.Note.Label')"
                                      :prepend-icon="$t('Faq.FaqsAddDialog.Field.Note.Icon')"
                                      :rules="validationRules.itemForm.Note"
                                      class="pr-2"
                                      required
                                    />
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="editItem.Lang[lang.locale].VideoId"
                                      :counter="255"
                                      :hint="$t('Faq.FaqsAddDialog.Field.VideoId.Hint')"
                                      :label="$t('Faq.FaqsAddDialog.Field.VideoId.Label')"
                                      :prepend-icon="$t('Faq.FaqsAddDialog.Field.VideoId.Icon')"
                                      :rules="validationRules.itemForm.VideoId"
                                      append-outer-icon="remove_red_eye"
                                      class="pr-2"
                                      required
                                      @click:append-outer="previewVideo(editItem.Lang[lang.locale].VideoId)"
                                    />
                                  </v-col>

                                  <v-col cols="4">
                                    <v-switch
                                      v-model="editItem.Lang[lang.locale].ContentActive"
                                      inset
                                      :label="editItem.Lang[lang.locale].ContentActive ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      color="success"
                                    >
                                      <template #prepend>
                                        <label
                                          class="v-label ml-2 text-no-wrap"
                                          style="left: auto; right: 0px; position: relative;"
                                        >{{ $t('Faq.FaqsAddDialog.Field.Content.Label') }}</label>
                                      </template>
                                    </v-switch>
                                  </v-col>

                                  <v-col cols="4">
                                    <v-switch
                                      v-model="editItem.Lang[lang.locale].ContentTitleActive"
                                      inset
                                      :label="editItem.Lang[lang.locale].ContentTitleActive ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      color="success"
                                    >
                                      <template #prepend>
                                        <label
                                          class="v-label ml-2 text-no-wrap"
                                          style="left: auto; right: 0px; position: relative;"
                                        >{{ $t('Faq.FaqsAddDialog.Field.ContentTitle.Label') }}</label>
                                      </template>
                                    </v-switch>
                                  </v-col>

                                  <v-col cols="4">
                                    <v-switch
                                      v-model="editItem.Lang[lang.locale].ContentMediaActive"
                                      inset
                                      :label="editItem.Lang[lang.locale].ContentMediaActive ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      color="success"
                                    >
                                      <template #prepend>
                                        <label
                                          class="v-label ml-2 text-no-wrap"
                                          style="left: auto; right: 0px; position: relative;"
                                        >{{ $t('Faq.FaqsAddDialog.Field.ContentMedia.Label') }}</label>
                                      </template>
                                    </v-switch>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-tab-item>
                          </template>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item>
                  <v-container
                    class="pa-1"
                    fluid
                  >
                    <v-row dense>
                      <v-col
                        class="mb-6"
                        cols="12"
                      >
                        <v-toolbar
                          flat
                          class="pa-0 ma-0"
                          height="40"
                        >
                          <v-spacer />
                          <v-btn
                            color="primary"
                            class="ma-0 pa-0"
                            dark
                            small
                            icon
                            @click="addContent"
                          >
                            <v-icon>add</v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-divider />
                      </v-col>

                      <v-col cols="12">
                        <template v-if="faqLanguages.length > 1">
                          <v-tabs
                            v-model="languageTabContent"
                            :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                            grow
                            height="40"
                            slider-color="blue"
                          >
                            <template v-for="lang in faqLanguages">
                              <v-tab
                                :key="lang.locale"
                                :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                              >
                                <v-avatar
                                  class="mr-2"
                                  size="20"
                                >
                                  <v-img :src="`/assets/flags/1x1/${lang.locale}.svg`" />
                                </v-avatar>
                                {{ $te(`Common.Language.${ lang.locale }`) ? $t(`Common.Language.${ lang.locale }`).name.toLocaleUpperCase($i18n.locale) : lang.name.toLocaleUpperCase($i18n.locale) }}
                              </v-tab>
                            </template>
                          </v-tabs>
                        </template>
                        <v-tabs-items v-model="languageTabContent">
                          <template v-for="lang in faqLanguages">
                            <v-tab-item
                              :key="lang.locale"
                              style="height: calc(100vh - 405px); overflow: scroll;"
                              eager
                            >
                              <template v-for="(item, index) in editItem.Content">
                                <v-col
                                  :key="`${lang.locale}-${index}`"
                                  cols="12"
                                >
                                  <v-card class="mb-6">
                                    <v-card-actions class="pa-4">
                                      <h1 class="grey--text">
                                        #{{ index + 1 }}
                                      </h1>
                                      <v-spacer />
                                      <v-btn
                                        color="red"
                                        dark
                                        icon
                                        @click="removeContent(index)"
                                      >
                                        <v-icon>close</v-icon>
                                      </v-btn>
                                    </v-card-actions>
                                    <v-divider />
                                    <v-card-text>
                                      <v-row>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="item.Lang[lang.locale].Title"
                                            :counter="255"
                                            :label="$t('Faq.FaqsAddDialog.Field.Title.Label')"
                                            :prepend-icon="$t('Faq.FaqsAddDialog.Field.Title.Icon')"
                                            :rules="validationRules.itemForm.Title"
                                            class="pr-2"
                                            required
                                          />
                                        </v-col>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="item.Lang[lang.locale].Description"
                                            :counter="512"
                                            :label="$t('Faq.FaqsAddDialog.Field.Description.Label')"
                                            :prepend-icon="$t('Faq.FaqsAddDialog.Field.Description.Icon')"
                                            :rules="validationRules.itemForm.Description"
                                            class="pr-2"
                                            required
                                          />
                                        </v-col>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="item.Lang[lang.locale].Tip"
                                            :counter="255"
                                            :label="$t('Faq.FaqsAddDialog.Field.Tip.Label')"
                                            :prepend-icon="$t('Faq.FaqsAddDialog.Field.Tip.Icon')"
                                            :rules="validationRules.itemForm.Tip"
                                            class="pr-2"
                                            required
                                          />
                                        </v-col>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="item.Lang[lang.locale].Note"
                                            :counter="255"
                                            :label="$t('Faq.FaqsAddDialog.Field.Note.Label')"
                                            :prepend-icon="$t('Faq.FaqsAddDialog.Field.Note.Icon')"
                                            :rules="validationRules.itemForm.Note"
                                            class="pr-2"
                                            required
                                          />
                                        </v-col>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="item.Priority"
                                            :hint="$t('Faq.FaqsAddDialog.Field.Priority.Hint')"
                                            :label="$t('Faq.FaqsAddDialog.Field.Priority.Label')"
                                            :prepend-icon="$t('Faq.FaqsAddDialog.Field.Priority.Icon')"
                                            :rules="validationRules.itemForm.Priority"
                                            type="number"
                                            step="1"
                                            class="pr-2"
                                            required
                                          />
                                        </v-col>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="item.Lang[lang.locale].Image"
                                            :counter="255"
                                            :hint="getImageUrl(item, lang.locale)"
                                            :label="$t('Faq.FaqsAddDialog.Field.Image.Label')"
                                            :rules="validationRules.itemForm.Image"
                                            append-outer-icon="remove_red_eye"
                                            class="pr-2"
                                            persistent-hint
                                            prepend-icon="photo"
                                            required
                                            @click:append-outer="previewImage(getImageUrl(item, lang.locale))"
                                          />
                                        </v-col>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="item.Lang[lang.locale].VideoId"
                                            :counter="255"
                                            :hint="$t('Faq.FaqsAddDialog.Field.VideoId.Hint')"
                                            :label="$t('Faq.FaqsAddDialog.Field.VideoId.Label')"
                                            :prepend-icon="$t('Faq.FaqsAddDialog.Field.VideoId.Icon')"
                                            :rules="validationRules.itemForm.VideoId"
                                            append-outer-icon="remove_red_eye"
                                            class="pr-2"
                                            required
                                            @click:append-outer="previewVideo(item.Lang[lang.locale].VideoId)"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </template>
                            </v-tab-item>
                          </template>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            v-if="faqLanguages.length > 1"
            :disabled="loading"
            class="purple white--text elevation-0"
            @click="translateFaq()"
          >
            {{ $t('Common.Button.Translate').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <image-preview
      :url="imagePreviewUrl"
      :visible.sync="imagePreviewDialog"
    />
  </div>
</template>

<script>
import AppData         from '../../mixins/appdata'
import ImagePreview    from './ImagePreview'
import Translatable    from '@/mixins/translatable'
import { clone }       from '@/lib/helper/helper'
import googleTranslate from '@/lib/google-translate/googleTranslate'

export default {
  name      : 'FaqsAddDialog',
  components: {
    ImagePreview
  },
  mixins: [AppData, Translatable],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    },
    categories: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      tab               : 0,
      languageTab       : 0,
      languageTabContent: 0,
      loading           : false,
      formValid         : false,
      newItem           : null,
      imagePreviewDialog: false,
      imagePreviewUrl   : '',
      tmpContent        : {
        Title      : '',
        Description: '',
        Tip        : '',
        Note       : '',
        Image      : '',
        Priority   : '1'
      },
      tmpItem: {
        Id           : null,
        Title        : '',
        Description  : '',
        Tip          : '',
        Note         : '',
        Slug         : '',
        Priority     : '1',
        Active       : true,
        VideoId      : '',
        Content      : [],
        CategoryId   : null,
        CategoryTitle: '',
        CategoryColor: ''
      },
      validationRules: {
        itemForm: {
          Title: [
            (v) => v && v.length >= 2 && v.length <= 255 || this.$t('Faq.FaqsAddDialog.Field.Title.Error.Between')
          ],
          Description: [
            (v) => !v || v.length >= 2 && v.length <= 512 || this.$t('Faq.FaqsAddDialog.Field.Description.Error.Between')
          ],
          Note: [
            (v) => !v || v.length >= 2 && v.length <= 255 || this.$t('Faq.FaqsAddDialog.Field.Note.Error.Between')
          ],
          Tip: [
            (v) => !v || v.length >= 2 && v.length <= 255 || this.$t('Faq.FaqsAddDialog.Field.Tip.Error.Between')
          ],
          Slug: [
            (v) => v && (v.length >= 2 && v.length <= 255) || this.$t('Faq.FaqsAddDialog.Field.Slug.Error.Between')
          ],
          Category: [
            (v) => v && !isNaN(v) || this.$t('Faq.FaqsAddDialog.Field.Category.Error.Required')
          ],
          Priority: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Faq.FaqsAddDialog.Field.Priority.Error.Min')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editItem: {
      get () {
        let retVal = null
        if (this.itemToEdit) {
          const tmp = clone(this.itemToEdit)
          tmp.Priority = tmp.Priority.toString()
          // eslint-disable-next-line no-return-assign
          tmp.Content.forEach((item, index) => tmp.Content[index].Priority = item.Priority.toString())
          retVal = clone(tmp)
        } else {
          retVal = clone(this.tmpItem)
        }

        const langObj = {}
        for (const lang of this.faqLanguages) {
          langObj[lang.locale] = {
            Title      : '',
            Tip        : '',
            Note       : '',
            Slug       : '',
            Description: '',
            VideoId    : ''
          }
        }
        retVal.Lang = Object.assign({}, langObj, retVal.Lang)

        const langContentObj = {}
        for (const lang of this.faqLanguages) {
          langContentObj[lang.locale] = {
            Title      : '',
            Description: '',
            Tip        : '',
            Note       : '',
            Image      : ''
          }
        }
        for (let i = 0; i < retVal.Content.length; i++) {
          langContentObj[this.defaultLanguage.Code] = {
            Title      : '',
            Description: '',
            Tip        : '',
            Note       : '',
            Image      : ''
          }
          langContentObj[this.defaultLanguage.Code].Title = retVal.Content[i].Title
          langContentObj[this.defaultLanguage.Code].Description = retVal.Content[i].Description
          langContentObj[this.defaultLanguage.Code].Tip = retVal.Content[i].Tip
          langContentObj[this.defaultLanguage.Code].Note = retVal.Content[i].Note
          langContentObj[this.defaultLanguage.Code].Image = retVal.Content[i].Image
          retVal.Content[i].Lang = Object.assign({}, langContentObj, clone(retVal.Content[i].Lang))
        }
        return clone(retVal)
      }
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Faq.Manage.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Faq.Manage.Save, this.onSaveResult)
  },
  methods: {
    getImageUrl (item, locale) {
      if (item && locale && this.editItem && this.editItem.CategoryLang && this.editItem.CategoryLang[locale] && this.editItem.CategoryLang[locale].Slug && this.editItem.Lang && this.editItem.Lang[locale] && this.editItem.Lang[locale].Slug && item.Lang && item.Lang[locale] && item.Lang[locale].Image) {
        return this.appConfig.LOCATION_DATA.FaqUrl + this.editItem.CategoryLang[locale].Slug + '/' + this.editItem.Lang[locale].Slug + '/' + item.Lang[locale].Image
      }
      return ''
    },

    previewVideo (videoId) {
      if (videoId) {
        window.open(`https://www.youtube.com/watch?v=${ videoId }`, '_blank')
      }
    },

    previewImage (url) {
      if (url) {
        this.imagePreviewUrl = url
        this.imagePreviewDialog = true
      }
    },

    addContent () {
      const langContentObj = {}
      for (const lang of this.faqLanguages) {
        langContentObj[lang.locale] = {
          Title      : '',
          Description: '',
          Tip        : '',
          Note       : '',
          Image      : ''
        }
      }
      const tmpContent = JSON.parse(JSON.stringify(this.tmpContent))
      tmpContent.Lang = Object.assign({}, langContentObj, JSON.parse(JSON.stringify(tmpContent.Lang || {})))
      this.editItem.Content.push(tmpContent)
      this.$forceUpdate()
    },

    removeContent (index) {
      this.editItem.Content.splice(index, 1)
      this.$forceUpdate()
    },

    async onSaveButtonClick () {
      this.$refs.itemForm.validate()

      if (this.formValid) {
        this.saveFaq(this.editItem)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    saveFaq (item) {
      this.loading = true

      /* Merge fields default locale to default fields */
      for (let i = 0; i < item.Content.length; i++) {
        item.Content[i] = Object.assign({}, item.Content[i], item.Content[i].Lang && item.Content[i].Lang[this.defaultLanguage.Code] || {})
      }

      /* Remove Empty locale from contents lang object */
      for (let i = 0; i < item.Content.length; i++) {
        for (const lang in item.Content[i].Lang) {
          if (item.Content[i].Lang.hasOwnProperty(lang)) {
            if (!item.Content[i].Lang[lang].Title && !item.Content[i].Lang[lang].Description && !item.Content[i].Lang[lang].Tip && !item.Content[i].Lang[lang].Note) delete item.Content[i].Lang[lang]
          }
        }
      }
      /* Remove Empty locale from item lang object */
      for (const lang in item.Lang) {
        if (item.Lang.hasOwnProperty(lang)) {
          if (!item.Lang[lang].Title && !item.Lang[lang].Description && !item.Lang[lang].Tip && !item.Lang[lang].Note) delete item.Lang[lang]
        }
      }

      item.Description = item.Lang[this.defaultLanguage.Code].Description || ''
      item.Note = item.Lang[this.defaultLanguage.Code].Note || ''
      item.Slug = item.Lang[this.defaultLanguage.Code].Slug || ''
      item.Tip = item.Lang[this.defaultLanguage.Code].Tip || ''
      item.Title = item.Lang[this.defaultLanguage.Code].Title || ''
      item.VideoId = item.Lang[this.defaultLanguage.Code].VideoId || ''

      window.callAS(window.SocketCommand.Faq.Manage.Save, item)
    },

    onSaveResult (result) {
      if (result && result.status === 'success') {
        this.onCancelButtonClick()
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.itemForm) this.$refs.itemForm.resetValidation()
      this.isVisible = false
      this.newItem = null
      this.languageTab = 0
      this.languageTabContent = 0
      this.tab = 0
    },

    async translateFaq (item = this.editItem, localeFrom = this.defaultLanguage.Code, localeTo) {
      if (this.faqLanguages.length <= 0) return
      const langs = localeTo ? [{ Code: localeTo }] : this.faqLanguages.filter(lang => lang.locale !== localeFrom) || []

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].locale

        let result = await googleTranslate([
          item.Lang[localeFrom].Title || '',
          item.Lang[localeFrom].Description || '',
          item.Lang[localeFrom].Tip || '',
          item.Lang[localeFrom].Note || ''
        ], localeFrom, localeTo)

        if (result) {
          const [Title, Description, Tip, Note] = result
          item.Lang[localeTo].Title = Title.translatedText
          item.Lang[localeTo].Description = Description.translatedText
          item.Lang[localeTo].Tip = Tip.translatedText
          item.Lang[localeTo].Note = Note.translatedText
          item.Lang[localeTo].Slug = `${ item.Lang[localeFrom].Slug }_${ localeTo }`
          this.$forceUpdate()
        }

        const contentItemsArr = []
        for (let i = 0; i < item.Content.length; i++) {
          contentItemsArr.push(item.Content[i].Lang[localeFrom].Title || '')
          contentItemsArr.push(item.Content[i].Lang[localeFrom].Description || '')
          contentItemsArr.push(item.Content[i].Lang[localeFrom].Tip || '')
          contentItemsArr.push(item.Content[i].Lang[localeFrom].Note || '')
        }
        result = await googleTranslate(contentItemsArr, localeTo, localeFrom)
        if (result) {
          const fieldsPerContent = 4
          for (let i = 0; i < item.Content.length; i++) {
            item.Content[i].Lang[localeTo].Title = result[(i * fieldsPerContent)].translatedText
            item.Content[i].Lang[localeTo].Description = result[(i * fieldsPerContent) + 1].translatedText
            item.Content[i].Lang[localeTo].Tip = result[(i * fieldsPerContent) + 2].translatedText
            item.Content[i].Lang[localeTo].Note = result[(i * fieldsPerContent) + 3].translatedText
          }
          this.$forceUpdate()
        }
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__extension {
  padding : 0;
}
</style>
