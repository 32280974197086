<template>
  <v-container
    class="pa-1 pa-sm-2 fill-height align-start"
    fluid
  >
    <v-row no-gutters>
      <!-- STAFF LIST -->
      <v-col
        v-if="!userIsWaiter"
        class="pr-2"
        :class="[{'shrink': $vuetify.breakpoint.smAndUp}]"
      >
        <v-card flat>
          <v-toolbar
            ref="staffToolbar"
            :color="isDarkMode ? 'grey darken-4' : 'grey lighten-5'"
            flat
          >
            <v-toolbar-title class="grey--text text--darken-1 pr-2">
              {{ $tc('Waiter.Title', 0) }}
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              v-if="userCanAdd"
              :color="$t('Waiter.Button.WaiterStaff.Add.Color')"
              :icon="$vuetify.breakpoint.xsOnly"
              class="ma-0 px-2"
              text
              @click="showWaiterStaffAddDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">
                {{ $t('Waiter.Button.WaiterStaff.Add.Icon') }}
              </v-icon>&nbsp;
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Waiter.Button.WaiterStaff.Add.Title') }}</span>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-text class="pa-0 mt-1">
            <div
              ref="staffList"
              style="overflow-y: auto;"
            >
              <div
                v-if="waiterStaff.length <= 0"
                class="fill-height"
                style="position: relative;"
              >
                <div
                  class="text-center grey--text pa-6"
                  :style="'position: absolute; width: 100%; top: 50%; transform: translateY(-50%);'"
                >
                  <v-icon
                    class="mb-4"
                    color="grey lighten-2"
                    size="128"
                  >
                    {{ $t('Waiter.ListView.NoData.Icon') }}
                  </v-icon>
                  <div
                    class="text-h6 mb-2"
                    style="height: auto;"
                  >
                    {{ $t('Waiter.ListView.NoData.Title') }}
                  </div>
                  <div
                    class="text-subtitle-1"
                    style="height: auto;"
                  >
                    {{ $t('Waiter.ListView.NoData.Subtitle') }}
                  </div>
                </div>
              </div>

              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-list
                  class="ma-0 pa-0"
                  three-line
                >
                  <template v-for="staff in waiterStaff">
                    <v-list-item
                      :key="`staff-list-item-${staff.Id}`"
                      :class="[selectedWaiterStaff && parseInt(staff.Id) === parseInt(selectedWaiterStaff.Id) ? (isDarkMode ? 'green darken-4' : 'green lighten-4') : (isDarkMode ? 'grey darken-4' : 'grey lighten-4')]"
                      style="height: 88px;"
                      ripple
                      @click="waiterStaffToggle(staff)"
                    >
                      <div
                        :class="[staff.HasActiveShift ? 'light-green' : 'grey lighten-1']"
                        class="ma-0 pa-0"
                        style="width: 5px; height: 100%; position: absolute; left: 0"
                      />

                      <v-list-item-avatar>
                        <v-icon
                          :class="[staff.HasActiveShift ? 'light-green--text' : 'grey--text text--lighten-1']"
                          x-large
                        >
                          account_circle
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="pl-3">
                        <v-list-item-title class="">
                          {{ staff.Firstname + ' ' + staff.Lastname }}
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="staff.Phone">
                          {{ $t('Waiter.Common.InfoLabels.Telephone') }} {{ staff.Phone }}&nbsp;
                        </v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-if="staff.HasActiveShift"
                          class="text-caption"
                        >
                          {{ $t('Waiter.Common.ShiftsOrders') }} {{ getWaiterStaffShiftTotals(staff) | currency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action class="">
                        <v-btn
                          v-if="userCanManage"
                          icon
                          ripple
                          small
                          @click.native.stop="showWaiterStaffEditDialog(staff)"
                        >
                          <v-icon
                            :color="$t('Waiter.Button.WaiterStaff.Edit.Color')"
                          >
                            {{ $t('Waiter.Button.WaiterStaff.Edit.Icon') }}
                          </v-icon>
                        </v-btn>

                        <v-btn
                          v-if="!staff.HasActiveShift && userCanDelete"
                          class="red--text"
                          icon
                          ripple
                          small
                          @click.native.stop="showDeleteWaiterStaffDialog(staff)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      :key="`staff-list-divider-${staff.Id}`"
                      class="mb-1"
                    />
                  </template>
                </v-list>
              </template>

              <v-menu
                v-else
                bottom
                max-height="400"
                transition="slide-y-transition"
              >
                <template #activator="{on}">
                  <v-btn
                    block
                    color="primary ma-0"
                    dark
                    v-on="on"
                  >
                    {{ $t('Waiter.ListView.SelectWaiterStaff.Title') }}
                  </v-btn>
                </template>

                <v-list
                  class="mb-1 mt-0 pb-0 pt-0"
                  three-line
                >
                  <template v-for="staff in waiterStaff">
                    <v-list-item
                      :key="`list-item-${staff.Id}`"
                      :class="[selectedWaiterStaff && parseInt(staff.Id) === parseInt(selectedWaiterStaff.Id) ? (isDarkMode ? 'green darken-4' : 'green lighten-4') : (isDarkMode ? 'grey darken-4' : 'grey lighten-4')]"
                      ripple
                      @click="waiterStaffToggle(staff)"
                    >
                      <div
                        :class="[staff.HasActiveShift ? 'light-green' : 'grey lighten-1']"
                        class="ma-0 pa-0"
                        style="width: 5px; height: 100%; position: absolute; left: 0"
                      />

                      <v-list-item-avatar>
                        <v-icon
                          :class="[staff.HasActiveShift ? 'light-green--text' : 'grey--text text--lighten-1']"
                          x-large
                        >
                          {{ $t('Waiter.Common.InfoLabels.Icon') }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="pl-3">
                        <v-list-item-title>
                          {{ staff.Firstname }} {{ staff.Lastname }}
                        </v-list-item-title>

                        <v-list-item-subtitle>{{ $t('Waiter.Common.InfoLabels.Telephone') }} {{ staff.Phone }}&nbsp;</v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-if="staff.HasActiveShift"
                          class="text-caption"
                        >
                          {{ $t('Waiter.Common.ShiftsOrders') }} {{ staff.ShiftPayments.length }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          v-if="userCanManage"
                          icon
                          ripple
                          small
                          @click.native.stop="showWaiterStaffEditDialog(staff)"
                        >
                          <v-icon
                            :color="$t('Waiter.Button.WaiterStaff.Edit.Color')"
                          >
                            {{ $t('Waiter.Button.WaiterStaff.Edit.Icon') }}
                          </v-icon>
                        </v-btn>

                        <v-btn
                          v-if="!staff.HasActiveShift && userCanDelete"
                          class="red--text"
                          icon
                          ripple
                          small
                          @click.native.stop="showDeleteWaiterStaffDialog(staff)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      :key="`list-item-divider-${staff.Id}`"
                      class="mb-1"
                    />
                  </template>
                </v-list>
              </v-menu>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="selectedWaiterStaff">
        <v-divider
          v-if="$vuetify.breakpoint.smAndDown"
          class="mt-1"
        />

        <v-card
          v-if="selectedWaiterStaff"
          :class="{'mt-2 pa-1': $vuetify.breakpoint.smAndDown}"
          flat
        >
          <v-toolbar
            ref="staffToolbar"
            class="waiter-toolbar"
            flat
          >
            <div
              :class="[selectedWaiterStaff.HasActiveShift ? 'light-green' : 'grey lighten-1']"
              class="ma-0 pa-0"
              style="width: 5px; height: 100%; position: absolute; left: 0"
            />

            <v-avatar>
              <v-icon
                :class="[selectedWaiterStaff.HasActiveShift ? 'light-green--text' : 'grey--text text--lighten-1']"
                x-large
              >
                {{ $t('Waiter.Common.InfoLabels.Icon') }}
              </v-icon>
            </v-avatar>

            <v-toolbar-title class="grey--text text--darken-1 ma-1 text-body-2">
              {{ selectedWaiterStaff.Firstname + ' ' + selectedWaiterStaff.Lastname }}
              <div class="grey--text text-caption">
                <div
                  v-if="selectedWaiterStaff.Phone"
                  class="pr-2"
                >
                  <span class="grey--text text--darken-1">
                    {{ $t('Waiter.Common.InfoLabels.Telephone') }}
                  </span>
                  {{ selectedWaiterStaff.Phone }}
                </div>

                <div
                  v-if="selectedWaiterStaff.Email"
                  class="pr-2"
                >
                  <span class="grey--text text--darken-1">{{ $t('Waiter.Common.InfoLabels.Email') }}</span>
                  <span
                    style="cursor: pointer;"
                    @click="copyToClipboard(selectedWaiterStaff.Email)"
                  >
                    {{ selectedWaiterStaff.Email }}
                  </span>
                </div>
              </div>
            </v-toolbar-title>

            <v-spacer />

            <v-menu
              v-if="!selectedWaiterStaff.HasActiveShift && userCanManage"
              v-model="startShiftPopOverVisible"
              :close-on-content-click="false"
              :nudge-width="85"
              :offset-y="true"
              bottom
              class="ma-0 pa-0"
            >
              <template #activator="{on}">
                <v-btn
                  :color="$t('Waiter.Button.Shift.Open.Color')"
                  :icon="$vuetify.breakpoint.xsOnly"
                  class="ma-0"
                  text
                  v-on="on"
                >
                  <v-icon :left="$vuetify.breakpoint.smAndUp">
                    {{ $t('Waiter.Button.Shift.Open.Icon') }}
                  </v-icon>&nbsp;

                  <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Waiter.Button.Shift.Open.Title') }}</span>
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <v-text-field
                    v-model="selectedWaiterStaff.ShiftStartMoney"
                    :append-icon="appCurrency.Icon"
                    :hint="$t('Waiter.Button.Shift.Open.PopUp.Hint')"
                    :label="$t('Waiter.Button.Shift.Open.PopUp.Title')"
                    autofocus
                    name="input-order-comments"
                    persistent-hint
                  />
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    class="primary--text"
                    text
                    @click="startShiftPopOverVisible = false; startWaiterStaffShift();"
                  >
                    {{ $t('Waiter.Button.Shift.Open.PopUp.Button') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-btn
              v-else-if="selectedWaiterStaff.HasActiveShift && userCanManage"
              :color="$t('Waiter.Button.Shift.Close.Color')"
              :icon="$vuetify.breakpoint.xsOnly"
              class="ma-0"
              text
              @click="showCloseWaiterStaffShiftDialog()"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">
                {{ $t('Waiter.Button.Shift.Close.Icon') }}
              </v-icon>&nbsp;
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Waiter.Button.Shift.Close.Title') }}</span>
            </v-btn>
          </v-toolbar>

          <v-divider class="datatable-object" />

          <v-tabs
            v-model="activeTab"
            class="mt-1 datatable-object"
            background-color="indigo"
            slider-color="light-green"
            dark
            grow
          >
            <v-tab
              key="tab1"
              active-class="indigo darken-2 v-tabs__item--active"
              href="#tab1"
              ripple
            >
              {{ $t('Waiter.WaiterStaffInfo.Tab.Details') }}
            </v-tab>

            <v-tab
              key="tab2"
              active-class="indigo darken-2 v-tabs__item--active"
              href="#tab2"
              ripple
            >
              {{ $t('Waiter.WaiterStaffInfo.Tab.History') }}
            </v-tab>
          </v-tabs>

          <v-card-text class="pa-0 pt-2">
            <v-tabs-items v-model="activeTab">
              <!--ACTIVE SHIFT TAB-->
              <v-tab-item value="tab1">
                <v-card>
                  <v-card-title class="pa-0">
                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row dense>
                        <v-col
                          class="text-left"
                          md="3"
                          cols="6"
                        >
                          <div
                            v-if="selectedWaiterStaff.HasActiveShift"
                            class="text-center"
                          >
                            <div class="text-caption pl-1 pt-0 blue--text">
                              <strong>{{ $t('Waiter.Common.ShiftStart') }}</strong>
                            </div>

                            <div class="text-h6 grey--text text--darken-1">
                              {{ selectedWaiterStaff.ShiftStartMoney | currency }}
                            </div>

                            <div
                              class="text-caption pl-1 pt-1 grey--text"
                              style="white-space: nowrap;"
                            >
                              {{ selectedWaiterStaff.ShiftDateStarted }}
                            </div>

                            <div class="text-caption">
                              {{ selectedWaiterStaff.ShiftTimeStarted }}
                            </div>
                          </div>
                        </v-col>

                        <v-col
                          class="text-left"
                          md="3"
                          cols="6"
                        >
                          <div
                            v-if="selectedWaiterStaff.HasActiveShift"
                            class="text-center"
                          >
                            <div class="text-caption pl-1 pt-0 orange--text">
                              <strong>{{ $t('Waiter.Common.ShiftTotal') }}</strong>
                            </div>

                            <div class="text-h6 grey--text text--darken-1">
                              {{ getWaiterStaffShiftTotals(selectedWaiterStaff) | currency }}
                            </div>

                            <div
                              class="text-caption pl-1 pt-1"
                              style="white-space: nowrap;"
                            >
                              <span class="grey--text">
                                {{ $t('Waiter.Common.Orders') }}
                              </span>
                              <div>
                                {{ getWaiterStaffShiftTotals(selectedWaiterStaff, false) | currency }}
                              </div>
                            </div>
                          </div>
                        </v-col>

                        <v-col
                          class="text-right"
                          cols="12"
                          md="6"
                        >
                          <template v-if="selectedWaiterStaff.HasActiveShift">
                            <v-menu
                              v-if="appMachinePrintersActive.length > 1 && appHasPrinters && userCanManage"
                              left
                              nudge-bottom="30"
                              open-on-hover
                            >
                              <template #activator="{on}">
                                <v-btn
                                  class="grey"
                                  dark
                                  icon
                                  style="right: 5px;"
                                  :style="$vuetify.breakpoint.xsOnly ? 'top: 0;' : 'top: 60px;'"
                                  v-on="on"
                                >
                                  <v-icon>print</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item
                                  v-for="(printer, index) in appMachinePrintersActive"
                                  :key="index"
                                  @click="onPrintDeliveryShift(printer)"
                                >
                                  <v-list-item-action>
                                    <v-icon>print</v-icon>
                                  </v-list-item-action>
                                  <v-list-item-title>{{ printer.name }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-btn
                              v-if="(appMachinePrintersActive.length === 1 && appHasPrinters) && userCanManage"
                              class="grey"
                              dark
                              icon
                              style="right: 5px;"
                              :style="$vuetify.breakpoint.xsOnly ? 'top: 0;' : 'top: 60px;'"
                              @click.native="onPrintDeliveryShift(appMachinePrintersActive[0])"
                            >
                              <v-icon>print</v-icon>
                            </v-btn>
                          </template>
                        </v-col>

                        <v-col
                          v-if="selectedWaiterStaff.HasActiveShift"
                          cols="12"
                        >
                          <v-divider />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <template v-if="selectedWaiterStaff.HasActiveShift">
                      <v-subheader
                        v-if="selectedWaiterStaff.ShiftPayments.Total <= 0"
                        class="grey--text text--darken-2 px-0"
                        style="height: 50px; position: absolute; right: 0;"
                      >
                        <v-spacer />
                        <span class="text-caption">
                          {{ $t('Waiter.WaiterStaffInfo.Result.NoOrders') }}
                        </span>
                      </v-subheader>

                      <div
                        ref="ordersList"
                        class="mt-2"
                        style="overflow-y: auto;"
                      >
                        <payments-list
                          :data="selectedWaiterStaff.ShiftPayments"
                          list-tile-action
                          list-tile-action-icon="transfer_within_a_station"
                          list-tile-action-icon-color="brown"
                          @list-tile-click="showPaymentDetails"
                          @list-tile-action-click="showPaymentChangeWaiterDialog"
                        />
                      </div>
                    </template>

                    <template v-if="!selectedWaiterStaff.HasActiveShift">
                      <div
                        ref="ordersList"
                        class="mt-2 ordersList"
                        style="overflow-y: auto; min-height: 300px; height: 300px;"
                      >
                        <div
                          class="fill-height"
                          style="position: relative;"
                        >
                          <div
                            class="text-center grey--text pa-2"
                            style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                          >
                            <v-icon
                              class="mb-4"
                              color="grey lighten-2"
                              size="128"
                            >
                              {{ $t('Waiter.WaiterStaffInfo.Result.InactiveShift.Icon') }}
                            </v-icon>
                            <div
                              class="text-h6 mb-2"
                              style="height: auto;"
                            >
                              {{ $t('Waiter.WaiterStaffInfo.Result.InactiveShift.Title') }}
                            </div>
                            <div
                              class="text-subtitle-1"
                              style="height: auto;"
                            >
                              <a @click="startShiftPopOverVisible=true">{{ $t('Waiter.WaiterStaffInfo.Result.InactiveShift.SubtitleLink') }}</a> {{ $t('Waiter.WaiterStaffInfo.Result.InactiveShift.SubtitleText') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!--HISTORY SHIFTS TAB-->
              <v-tab-item value="tab2">
                <!--HISTORY SHIFTS TABLE-->
                <v-card
                  v-if="viewData"
                  flat
                >
                  <v-data-table
                    :footer-props="dataTable.footerProps"
                    :headers="dataTable.headers"
                    :height="dataTable.resize.bodyHeight"
                    :items="dataTable.data"
                    :loading="dataTable.loading"
                    :loading-text="dataTable.text.loadingText"
                    :no-data-text="dataTable.text.noDataText"
                    :no-results-text="dataTable.text.noResultsText"
                    :options.sync="dataTable.options"
                    :server-items-length="dataTable.total"
                    fixed-header
                    item-key="Id"
                  >
                    <template #[`item.started_at`]="{item}">
                      {{ item.ShiftsDate }}
                    </template>

                    <template #[`item.swifts`]="{item}">
                      {{ item.ShiftsCount }}
                    </template>

                    <template #[`item.payments`]="{item}">
                      {{ item.PaymentsCount }}
                    </template>

                    <template #[`item.total`]="{item}">
                      {{ item.PaymentsTotal | currency }}
                    </template>

                    <template #[`item.actions`]="{item}">
                      <v-tooltip
                        :color="$t('Waiter.WaiterStaffInfo.Button.ViewDailyShift.Color')"
                        open-delay="1000"
                        left
                      >
                        <template #activator="{on}">
                          <v-btn
                            :class="$t('Waiter.WaiterStaffInfo.Button.ViewDailyShift.Color')"
                            class="elevation-2 ml-1"
                            dark
                            icon
                            :small="$vuetify.breakpoint.xsOnly"
                            v-on="on"
                            @click.native.stop="showDeliveryShifts(item)"
                          >
                            <v-icon :small="$vuetify.breakpoint.xsOnly">
                              {{ $t('Waiter.WaiterStaffInfo.Button.ViewDailyShift.Icon') }}
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>{{ $t('Waiter.WaiterStaffInfo.Button.ViewDailyShift.Title') }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="!selectedWaiterStaff">
        <v-container
          class="fill-height align-center justify-center"
          fluid
        >
          <v-row class="fill-height align-center justify-center">
            <div class="text-center grey--text">
              <v-icon
                class="mb-4"
                color="grey lighten-2"
                size="128"
              >
                {{ $t('Waiter.ListView.SelectWaiterStaff.Icon') }}
              </v-icon>
              <div
                class="text-h6 mb-2"
                style="height: auto;"
              >
                {{ $t('Waiter.ListView.SelectWaiterStaff.Title') }}
              </div>
              <div
                class="text-subtitle-1"
                style="height: auto;"
              >
                {{ $t('Waiter.ListView.SelectWaiterStaff.Subtitle') }}
              </div>
            </div>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <confirm-dialog
      :html-content="$t('Waiter.Button.Shift.Close.PopUp.Content')"
      :html-title="$t('Waiter.Button.Shift.Close.Title')"
      :visible.sync="confirmDialogCloseShiftVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="close-shift-on-yes-btn"
      @close-shift-on-yes-btn="closeWaiterStaffShift"
    />

    <confirm-dialog
      :html-content="$t('Waiter.Button.WaiterStaff.Delete.PopUp.Content')"
      :html-title="$t('Waiter.Button.WaiterStaff.Delete.PopUp.Title')"
      :visible.sync="confirmDialogDeleteWaiterStaffVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="delete-delivery-staff-on-yes-btn"
      @delete-delivery-staff-on-yes-btn="deleteWaiterStaff"
    />

    <waiter-staff-shift-close-dialog
      :source-waiter="selectedWaiterStaff"
      :visible.sync="waiterStaffShiftCloseDialogVisible"
      @save-success="closeWaiterStaffShift"
    />

    <payment-change-waiter-dialog
      :payment="selectedPaymentItem"
      :source-waiter="selectedWaiterStaff"
      :table="selectedTableItem"
      :visible.sync="paymentChangeWaiterDialogVisible"
      @save-success="onPaymentChangeWaiterSuccess"
    />

    <waiter-staff-add-dialog
      :staff-to-edit="tmpWaiterStaff"
      :table-area-combo="tableAreaCombo"
      :visible.sync="waiterStaffAddDialogVisible"
    />

    <waiter-staff-day-shifts-dialog
      :selected-shifts-day="selectedShiftsDay"
      :selected-waiter-staff="selectedWaiterStaff"
      :visible.sync="selectedShiftsDayDialogVisible"
      @waiter-staff:show-payment-details="showPaymentDetails"
    />

    <waiter-staff-payment-details-dialog
      :view-data="selectedPaymentItem"
      :visible.sync="paymentDetailsDialogVisible"
    />
  </v-container>
</template>

<script>
import moment                          from 'moment'
import AppData                         from '@/mixins/appdata'
import Auth                            from '@/mixins/auth'
import Resizable                       from '@/mixins/resizable'
import ConfirmDialog                   from '@/components/common/ConfirmDialog'
import PaymentsList                    from '@/components/waiters/PaymentsList.vue'
import WaiterStaffDayShiftsDialog      from '@/components/waiters/WaiterStaffDayShiftsDialog'
import WaiterStaffPaymentDetailsDialog from '@/components/waiters/WaiterStaffPaymentDetailsDialog.vue'
import WaiterStaffAddDialog            from '@/components/waiters/WaiterStaffAddDialog.vue'
import Printer                         from '@/lib/helper/printer'
import WaiterStaffShiftCloseDialog     from '@/components/waiters/WaiterStaffShiftCloseDialog.vue'
import PaymentChangeWaiterDialog       from '@/components/waiters/PaymentChangeWaiterDialog.vue'
import SocketData                      from '@/api/SocketData'
import DataTable                       from '@/mixins/data-table/dataTable'
import PinManagerEnum                  from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'WaiterStaff',
  components: {
    PaymentChangeWaiterDialog,
    WaiterStaffShiftCloseDialog,
    WaiterStaffAddDialog,
    WaiterStaffPaymentDetailsDialog,
    PaymentsList,
    ConfirmDialog,
    WaiterStaffDayShiftsDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable, DataTable],
  props     : [],

  data () {
    return {
      activeTab     : 'tab1',
      tableAreaCombo: [],
      dataTable     : {
        dataStore: null,
        options  : {
          defaultSortBy: 'started_at',
          sortBy       : ['started_at'],
          sortDesc     : [true]
        },
        resize: {
          offset: 85
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Waiter.ListView.Table.Headers.DateStarted'),
            value    : 'started_at',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Waiter.ListView.Table.Headers.Swifts'),
            value    : 'swifts',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Waiter.ListView.Table.Headers.Payments'),
            value    : 'payments',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Waiter.ListView.Table.Headers.Total'),
            value    : 'total',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Waiter.ListView.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2'
          }
        ]
      },
      tmpWaiterStaff                       : null,
      selectedWaiterStaff                  : null,
      selectedShiftsDay                    : null,
      selectedShiftsDayDialogVisible       : null,
      startShiftPopOverVisible             : false,
      confirmDialogCloseShiftVisible       : false,
      selectedPaymentItem                  : null,
      selectedTableItem                    : null,
      paymentDetailsDialogVisible          : false,
      waiterStaffAddDialogVisible          : false,
      paymentChangeWaiterDialogVisible     : false,
      waiterStaffShiftCloseDialogVisible   : false,
      confirmDialogDeleteWaiterStaffVisible: false
    }
  },

  computed: {
    waiterStaff () {
      return window.Vue.waiterStaffItems
    }
  },

  watch: {
    activeTab (newVal) {
      if (newVal === 'tab2') {
        this.getData()
      }
    }
  },

  created () {
    if (this.userIsWaiter) {
      this.selectedWaiterStaff = this.waiterStaff.find(staff => staff.Id.toString() === this.posUser.staff_id.toString())
    }
  },

  mounted () {
    this.$bus.$on(window.SocketCommand.Staff.Waiter.All, this.onStaffList)
    this.$bus.$on(window.SocketCommand.Staff.Waiter.ShiftsHistory, this.setData)
    this.$bus.$on(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
    this.getWaiterStaffList()
    this.getTableAreaCombo()
  },

  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Staff.Waiter.All, this.onStaffList)
    this.$bus.$off(window.SocketCommand.Staff.Waiter.ShiftsHistory, this.setData)
    this.$bus.$off(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
  },
  methods: {
    showDeliveryShifts (shiftsDay) {
      this.selectedShiftsDay = shiftsDay

      this.selectedShiftsDayDialogVisible = true
    },

    showCloseWaiterStaffShiftDialog () {
      if (!this.selectedWaiterStaff) return

      this.executeWithPinPermissionManager(PinManagerEnum.CloseWaiterShift, () => {
        if (this.selectedWaiterStaff?.HasActiveTableShifts) {
          this.waiterStaffShiftCloseDialogVisible = true
        } else {
          this.confirmDialogCloseShiftVisible = true
        }
      }, 'waiter-staff-shift-close')
    },

    getDateTimeNow () {
      return moment().format('DD/MM/YYYY HH:mm')
    },

    getWaiterStaffShiftTotals (staff, withShiftStartMoney = true) {
      if (!staff) return 0.00

      let total = staff?.ShiftPayments?.CodTotal || 0.00

      if (withShiftStartMoney) total += parseFloat(staff?.ShiftStartMoney || 0)

      return total
    },

    waiterStaffToggle (staff) {
      this.activeTab = 'tab1'
      this.dataTable.data = []

      if (this.selectedWaiterStaff === staff) {
        this.selectedWaiterStaff = null
      } else {
        this.selectedWaiterStaff = staff
      }

      this.$nextTick(() => {
        this.onResize()
      })
    },

    startWaiterStaffShift () {
      if (this.selectedWaiterStaff) {
        this.executeWithPinPermissionManager(PinManagerEnum.OpenWaiterShift, () => {
          window.callAS(window.SocketCommand.Staff.Waiter.Shift.Open, {
            Id   : this.selectedWaiterStaff.Id,
            Money: this.selectedWaiterStaff.ShiftStartMoney
          }, window.SocketCommand.Staff.Waiter.All)
        }, 'waiter-staff-shift-open')
      }
    },

    closeWaiterStaffShift () {
      if (this.selectedWaiterStaff) {
        window.callAS(window.SocketCommand.Staff.Waiter.Shift.Close, { Id: this.selectedWaiterStaff.Id }, window.SocketCommand.Staff.Waiter.All)
      }
    },

    onPaymentChangeWaiterSuccess (data) {
      if (Array.isArray(data.result) && data.result.length) {
        SocketData.set(window.SocketCommand.Staff.Waiter.All, data)
      }
    },

    showPaymentChangeWaiterDialog (payment, table) {
      this.selectedPaymentItem = payment
      this.selectedTableItem = table

      this.executeWithPinPermissionManager(PinManagerEnum.MovePayment, () => {
        this.paymentChangeWaiterDialogVisible = true
      }, 'change-payment-waiter')
    },

    showPaymentDetails (item) {
      if (!item) return

      this.selectedPaymentItem = item
      this.paymentDetailsDialogVisible = true
    },

    showWaiterStaffAddDialog () {
      this.tmpWaiterStaff = null
      this.waiterStaffAddDialogVisible = true
    },

    showWaiterStaffEditDialog (staff) {
      this.tmpWaiterStaff = staff
      this.waiterStaffAddDialogVisible = true
    },

    showDeleteWaiterStaffDialog (staff) {
      this.tmpWaiterStaff = staff
      this.confirmDialogDeleteWaiterStaffVisible = true
    },

    deleteWaiterStaff () {
      if (this.tmpWaiterStaff) {
        window.callAS(window.SocketCommand.Staff.Waiter.Delete, { Id: this.tmpWaiterStaff.Id }, window.SocketCommand.Staff.Waiter.All)
      }
      this.tmpWaiterStaff = null
    },

    onStaffList (data) {
      if (data && data.status === 'success') {
        if (this.selectedWaiterStaff) {
          this.$nextTick(() => {
            this.selectedWaiterStaff = this.waiterStaff.find(staff => staff.Id.toString() === this.selectedWaiterStaff.Id.toString())
          })
        } else {
          if (this.userIsWaiter) {
            this.selectedWaiterStaff = this.waiterStaff.find(staff => staff.Id.toString() === this.posUser.staff_id.toString())
          }
        }

        this.$nextTick(() => {
          this.onResize()
        })

        if (data.message) {
          this.$bus.$emit('app-show-notification', {
            body: data.message,
            type: 'success',
            icon: 'info'
          })
        }
      } else if (data && data.status === 'fail' && data.message) {
        this.$bus.$emit('app-show-notification', {
          body: data.message,
          type: 'error',
          icon: 'warning'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.GenericError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    getWaiterStaffList () {
      window.callAS(window.SocketCommand.Staff.Waiter.All, {})
    },

    getTableAreaCombo () {
      window.callAS(window.SocketCommand.Table.Area.List)
    },

    setTableAreaCombo (data) {
      const areas = (data || []).filter(area => area.Active).map(area => {
        area.Name = area.Name.toLocaleUpperCase(this.$i18n.locale)
        return area
      })
      areas.unshift({
        Id    : 0,
        Name  : this.$t('Waiter.StaffAddDialog.Field.TableAreas.All').toLocaleUpperCase(this.$i18n.locale),
        Color : '59ae4a',
        Active: true
      })
      this.tableAreaCombo = areas
    },

    getData () {
      if (this.selectedWaiterStaff) {
        this.dataTable.loading = true
        window.callAS(window.SocketCommand.Staff.Waiter.ShiftsHistory, {
          id    : this.selectedWaiterStaff.Id,
          ...this.dataTableRequestData(),
          config: 0
        })
      }
    },

    setData (data) {
      this.setDataInternal(data)
    },

    onPrintDeliveryShift (printer) {
      if (!this.selectedWaiterStaff || !this.selectedWaiterStaff?.HasActiveShift) return

      let printContents = ''
      const cardTotal = this.selectedWaiterStaff?.ShiftPayments?.CardTotal || 0
      const shiftStartMoney = this.selectedWaiterStaff?.ShiftStartMoney || 0
      const shiftStartDateTime = `${ this.selectedWaiterStaff?.ShiftDateStarted } ${ this.selectedWaiterStaff?.ShiftTimeStarted }`

      printContents += '<div style="font-family: Arial; width: 100%; text-align: center; padding-top: 12px; padding-bottom: 12px;">'
      printContents += '<div style="font-size: 12px; text-align: center; padding-bottom: 4px;"><strong>' + this.$tc('Waiter.Title', 1) + '</strong></div>'
      printContents += '<div style="font-size: 12px; text-align: center;">' + (String(this.selectedWaiterStaff.Firstname + ' ' + this.selectedWaiterStaff.Lastname).toLocaleUpperCase(this.$i18n.locale)) + '</div>'
      printContents += '</div>'

      if (this.selectedWaiterStaff && this.selectedWaiterStaff.HasActiveShift) {
        printContents += '' +
          '<div style="margin-bottom: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.StartShift') + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(shiftStartMoney) + '</div>' +
          '<div>' + shiftStartDateTime + '</div>' +
          '</div>'

        printContents += '' +
          '<div style="width: 100%; margin-top: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Waiter.Common.ShiftTotal') + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(this.getWaiterStaffShiftTotals(this.selectedWaiterStaff)) + '</div>' +
          '<div><strong>' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.Cash') + '</strong> ' + this.$options.filters.currency(this.getWaiterStaffShiftTotals(this.selectedWaiterStaff, false)) + '</div>'

        if (cardTotal > 0) {
          printContents += '<br><div style="font-size: 12px;"><strong>' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.NotIncluded') + '</strong></div>'
          printContents += '<div style="font-size: 12px;">' + this.$options.filters.currency(cardTotal) + ' ' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.ByCard') + '</div>'
        }

        printContents += '</div>'
      }

      const html = '<html>' +
        '<head>' +
        '<style>' +
        ' @page {' +
        '            width: 100%;' +
        '            margin-left   : 0px;' +
        '            margin-right  : 0px;' +
        '            margin-top    : 0px;' +
        '            margin-bottom : 0px;' +
        '        }' +
        'body { width: 100%; border: 0; margin: 0; overflow : hidden; }' +
        '@media print { body { width: 100%; overflow : hidden; border: 0; margin: 0; } }' +
        '</style>' +
        '</head>' +
        '<body>' + printContents + '</body>' +
        '</html>'

      this.$bus.$emit('app-show-notification', {
        body: this.$t('Waiter.PrintDeliveryShift.Notification'),
        type: 'info',
        icon: 'print'
      })

      Printer.printStaffShift(html, printer.name)
    },

    onResize () {
      const staffList = this.$refs.staffList
      const ordersList = this.$refs.ordersList

      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const staffToolbarHeight = this.$refs.staffToolbar ? this.$refs.staffToolbar.$el.clientHeight : 0

      if (staffList) staffList.style.height = this.$vuetify.breakpoint.smAndDown ? '45px' : (innerHeight - headerHeight - footerHeight - staffToolbarHeight - 18) + 'px'
      if (ordersList && this.$vuetify.breakpoint.smAndUp) ordersList.style.height = (innerHeight - headerHeight - footerHeight - staffToolbarHeight - (this.selectedWaiterStaff?.HasActiveShift ? 188 : 225)) + 'px'
    }
  }
}
</script>

<style scoped>

</style>
