import { render, staticRenderFns } from "./SettingsWizardIntro.vue?vue&type=template&id=806697c8&scoped=true"
import script from "./SettingsWizardIntro.vue?vue&type=script&lang=js"
export * from "./SettingsWizardIntro.vue?vue&type=script&lang=js"
import style0 from "./SettingsWizardIntro.vue?vue&type=style&index=0&id=806697c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "806697c8",
  null
  
)

export default component.exports