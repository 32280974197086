import axios            from 'axios'
import { API, APICall } from '@/api/Api'
import { trim }         from 'core-js/internals/string-trim'

const apiFree = axios.create({
  baseURL     : 'https://proxy.cors.sh/https://translate.googleapis.com/',
  timeout     : parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000,
  responseType: 'json',
  crossDomain : true
})

const apiFreeKey = axios.create({
  baseURL     : 'https://translate-pa.googleapis.com/',
  timeout     : parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000,
  responseType: 'json',
  crossDomain : true
})

/**
 *
 * @param translation {String || Array}
 * @param localeTo {String}
 * @param localeFrom {String}
 * @return {Promise<null|*>}
 */
export const googleTranslateFreeKey = async (translation, localeTo, localeFrom = 'auto') => {
  const freeKey = 'AIzaSyATBXajvzQLTDHEQbcpq0Ihe0vWDHmO520'
  const texts = convertToRequestArray(translation)

  try {
    const response = await apiFreeKey.post('v1/translateHtml', [[texts, localeFrom, localeTo], 'wt_lib'], {
      headers: {
        'X-Goog-API-Key': freeKey,
        'Content-Type'  : 'application/json+protobuf'
      }
    })

    if (response.status === 200 && response?.data) {
      const result = response?.data?.length > 0 ? response.data[0] : []
      const translation = convertToResultArray(result)
      return translation || null
    }
  } catch (e) {
    return null
  }
  return null
}

/**
 *
 * @param translation {String || Array}
 * @param localeTo {String}
 * @param localeFrom {String}
 * @return {Promise<null|*>}
 */
export const googleTranslateFree = async (translation, localeTo, localeFrom = 'auto') => {
  const texts = convertToRequestArray(translation)

  try {
    const response = await apiFree.get('translate_a/single', {
      params: {
        client: 'gtx',
        sl    : localeFrom || 'auto',
        tl    : localeTo || 'en',
        dt    : 't',
        q     : JSON.stringify(texts)
      }
    })

    if (response.status === 200 && response?.request?.response) {
      const result = response?.request?.response ? JSON.parse(response.request.response) : null
      const resultArr = result.length >= 1 ? result[0] : []
      const resultArrMap = convertToResultArray(resultArr)
      const translations = resultArrMap.length >= 1 ? resultArrMap[0] : []
      const translation = translations.length >= 1 ? convertToResultArray(JSON.parse(translations[0])) : null

      return translation || null
    }
  } catch (e) {
    return null
  }
  return null
}

/**
 *
 * @param translation {String || Array}
 * @param localeTo {String}
 * @param localeFrom {String}
 * @return {Promise<null|*>}
 */
export const googleTranslateGetfoodApi = async (translation, localeTo, localeFrom = 'auto') => {
  const texts = convertToRequestArray(translation)

  const payload = APICall.CreatePayload(window.SocketCommand.Translate.Get, {
    q     : texts,
    source: localeFrom,
    target: localeTo
  })
  try {
    const response = await API.post(APICall.api, payload)

    if (response.status === 200 && response.data && response.data.data && response.data.data.translations) {
      return response.data.data.translations.length > 0 ? response.data.data.translations.length === 1 ? response.data.data.translations[0] : response.data.data.translations : null
    }
  } catch (e) {
    return null
  }
  return null
}

const convertToResultArray = data => {
  const payload = Array.isArray(data) ? data : []

  return payload.map(item => { return { translatedText: item } })
}

const convertToRequestArray = data => {
  const retVal = []

  if (Array.isArray(data)) {
    data.forEach((item) => {
      retVal.push(trim(item) ? item : ' ')
    })
  } else {
    retVal.push(trim(data) ? data : ' ')
  }

  return retVal
}

const googleTranslate = async (translation, localeTo, localeFrom = 'auto') => {
  return await googleTranslateFreeKey(translation, localeTo, localeFrom)
}

export default googleTranslate
