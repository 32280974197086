<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="350"
      persistent
      scrollable
    >
      <v-card v-if="calendarEvent">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="getReservationStatusById(calendarEvent.reservation.StatusId).Avatar.Color">
            <v-icon dark>
              {{ getReservationStatusById(calendarEvent.reservation.StatusId).Avatar.Icon }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              <span>
                <v-icon size="22">people</v-icon>
                {{ calendarEvent.reservation.Persons }}
              </span>

              {{ calendarEvent.name }}
            </div>

            <div class="text-caption">
              <span class="mr-2">{{ calendarEvent.reservation.ReserveDate }}</span>
              <span class="grey--text">
                {{ formatEventTime(calendarEvent.start) }} - {{ formatEventTime(calendarEvent.end) }}
                <span class="ml-1">({{ convertMinutesToDisplayString(calendarEvent.reservation.Duration, false, true) }})</span>
              </span>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="fill-height pa-3">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <!-- Finished -->
              <template v-if="posUserCan('TableReservation', 'MANGE') && [ReservationStatusEnum.Arrived, ReservationStatusEnum.PartiallyArrived].includes(calendarEvent.reservation.StatusId)">
                <v-col cols="12">
                  <v-btn
                    depressed
                    large
                    block
                    class="white--text"
                    :color="$t('Reservation.Table.Button.Finished.Color')"
                    style="height: 55px; font-size: 16px; line-height: 24px;"
                    @click="onTableReservationFinishedClick(calendarEvent.reservation)"
                  >
                    <v-icon left>
                      {{ $t('Reservation.Table.Button.Finished.Icon') }}
                    </v-icon>
                    {{ $t('Reservation.Table.Button.Finished.Tooltip').toLocaleUpperCase($i18n.locale) }}
                  </v-btn>
                </v-col>
              </template>

              <!-- Arrived -->
              <template v-if="posUserCan('TableReservation', 'MANGE') && [ReservationStatusEnum.Confirmed, ReservationStatusEnum.PartiallyArrived].includes(calendarEvent.reservation.StatusId)">
                <v-col cols="12">
                  <v-btn
                    depressed
                    large
                    block
                    class="white--text"
                    :color="$t('Reservation.Table.Button.Arrived.Color')"
                    style="height: 55px; font-size: 16px; line-height: 24px;"
                    @click="onTableReservationArrivedClick(calendarEvent.reservation)"
                  >
                    <v-icon left>
                      {{ $t('Reservation.Table.Button.Arrived.Icon') }}
                    </v-icon>
                    {{ $t('Reservation.Table.Button.Arrived.Tooltip').toLocaleUpperCase($i18n.locale) }}
                  </v-btn>
                </v-col>
              </template>

              <!-- PartiallyArrived -->
              <template v-if="posUserCan('TableReservation', 'MANGE') && [ReservationStatusEnum.Confirmed].includes(calendarEvent.reservation.StatusId)">
                <v-col cols="12">
                  <v-btn
                    depressed
                    large
                    block
                    class="white--text"
                    :color="$t('Reservation.Table.Button.PartiallyArrived.Color')"
                    style="height: 55px; font-size: 16px; line-height: 24px;"
                    @click="onTableReservationPartiallyArrivedClick(calendarEvent.reservation)"
                  >
                    <v-icon left>
                      {{ $t('Reservation.Table.Button.PartiallyArrived.Icon') }}
                    </v-icon>
                    {{ $t('Reservation.Table.Button.PartiallyArrived.Tooltip').toLocaleUpperCase($i18n.locale) }}
                  </v-btn>
                </v-col>
              </template>

              <!-- Edit -->
              <template v-if="posUserCan('TableReservation', 'MANGE')">
                <v-col cols="12">
                  <v-btn
                    depressed
                    large
                    block
                    class="white--text"
                    :color="$t('Reservation.Table.Button.Edit.Color')"
                    style="height: 55px; font-size: 16px; line-height: 24px;"
                    @click="onTableReservationEditClick(calendarEvent.reservation)"
                  >
                    <v-icon left>
                      {{ $t('Reservation.Table.Button.Edit.Icon') }}
                    </v-icon>
                    {{ $t('Reservation.Table.Button.Edit.Tooltip').toLocaleUpperCase($i18n.locale) }}
                  </v-btn>
                </v-col>
              </template>

              <!-- Delete -->
              <template v-if="posUserCan('TableReservation', 'DELETE')">
                <v-col cols="12">
                  <v-btn
                    depressed
                    large
                    block
                    class="white--text"
                    :color="$t('Reservation.Table.Button.Delete.Color')"
                    style="height: 55px; font-size: 16px; line-height: 24px;"
                    @click="onTableReservationDeleteClick(calendarEvent.reservation)"
                  >
                    <v-icon left>
                      {{ $t('Reservation.Table.Button.Delete.Icon') }}
                    </v-icon>
                    {{ $t('Reservation.Table.Button.Delete.Tooltip').toLocaleUpperCase($i18n.locale) }}
                  </v-btn>
                </v-col>
              </template>

              <!-- Cancel -->
              <template v-if="posUserCan('TableReservation', 'DELETE') && ![ReservationStatusEnum.Canceled].includes(calendarEvent.reservation.StatusId)">
                <v-col cols="12">
                  <v-btn
                    depressed
                    large
                    block
                    class="white--text"
                    :color="$t('Reservation.Table.Button.Cancel.Color')"
                    style="height: 55px; font-size: 16px; line-height: 24px;"
                    @click="onTableReservationCancelClick(calendarEvent.reservation)"
                  >
                    <v-icon left>
                      {{ $t('Reservation.Table.Button.Cancel.Icon') }}
                    </v-icon>
                    {{ $t('Reservation.Table.Button.Cancel.Tooltip').toLocaleUpperCase($i18n.locale) }}
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import OrderTypeTime         from '@/mixins/orders/orderTypeTime'
import ReservationStatus     from '@/mixins/reservation/reservationStatus'
import Auth                  from '@/mixins/auth'
import AppData               from '@/mixins/appdata'
import ReservationStatusEnum from '@/api/Enums/ReservationStatusEnum'
import ReservationActions    from '@/mixins/reservation/reservationActions'

export default {
  name      : 'ReservationMenuDialog',
  components: {},
  mixins    : [Auth, AppData, ReservationActions, ReservationStatus, OrderTypeTime],
  props     : {
    calendarEvent: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ReservationStatusEnum () {
      return ReservationStatusEnum
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch  : {},
  methods: {
    formatEventTime (date) {
      return new Date(date).toLocaleTimeString(this.$i18n.locale, {
        hour  : '2-digit',
        minute: '2-digit',
        hour12: false
      })
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
