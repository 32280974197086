export default {
  Title: 'FAQs | FAQ | FAQs',

  Toolbar: {
    Subtitle     : 'Frequent questions',
    SearchResults: 'Search Results',
    Icon         : 'support',
    Color        : 'indigo',
    Button       : {
      Title: 'Select FAQ category',
      Color: 'primary ma-0'
    }
  },

  ListView: {
    ListItem: {
      Subtitle: 'Posts | Post | Posts',
      All     : 'All',
      Question: 'Q'
    },
    NoData: {
      Title   : 'Select category',
      Subtitle: '@:Faq.ListView.NoData.Title from the list',
      Icon    : 'support'
    }
  },

  FaqCategories: {
    Table: {
      Headers: {
        Id       : '#',
        Title    : 'Title',
        Slug     : 'Slug',
        Languages: 'Languages',
        Priority : 'Priority',
        Color    : 'Color',
        Status   : 'Status',
        Date     : 'Date',
        Actions  : ''
      }
    },
    Button: {
      Title: 'New category',
      Icon : 'add_box'
    },
    Save: {
      Result: {
        Success: 'Category saved successfully.',
        Error  : 'An error has occurred during the save operation. @:Common.Misc.Retry'
      }
    },
    Delete: {
      Title  : 'Delete Category?',
      Content: 'Are you sure you want to permanently delete the category?',
      Result : {
        Success: 'Category deleted successfully.',
        Error  : 'An error has occurred during the delete operation. @:Common.Misc.Retry'
      }
    }
  },

  FaqCategoryAddDialog: {
    Title  : 'Categories | Category | Categories',
    Toolbar: {
      Add: {
        Title   : 'Add Category',
        Subtitle: 'Create New Slug'
      },
      Edit: {
        Title   : 'Edit Category',
        Subtitle: 'Modify Category Details'
      },
      Icon : 'support',
      Color: 'indigo'
    },
    Field: {
      Title: {
        Label: 'Title',
        Hint : 'Category title',
        Icon : 'support',
        Error: {
          Between: 'The title must be between 2 and 255 characters'
        }
      },
      Slug: {
        Label: 'Slug',
        Hint : 'Category slug',
        Icon : 'subtitles',
        Error: {
          Between: 'The slug must be between 2 and 255 characters'
        }
      },
      Description: {
        Label: 'Description',
        Hint : 'Short description of the category',
        Icon : 'title',
        Error: {
          Between: 'The description must be between 2 and 512 characters'
        }
      },
      Priority: {
        Label: 'Priority',
        Hint : 'Priority number eg. 10, 100, 150',
        Icon : 'sort_by_alpha',
        Error: {
          Min: 'Priority must be at least one number'
        }
      },
      Status: { Label: 'Status' },
      Color : { Label: 'Category Color' }
    }
  },

  Faqs: {
    Toolbar: {
      Button: {
        Add: {
          Title: 'New FAQ',
          Icon : 'add_box'
        }
      },
      Select: {
        Label: 'Select category',
        Icon : 'support'
      }
    },
    Table: {
      Headers: {
        Id       : '#',
        Title    : 'Title',
        Slug     : 'Slug',
        Languages: 'Languages',
        Content  : 'Content',
        Category : 'Category',
        Status   : 'Status',
        Date     : 'Date',
        Actions  : ''
      }
    },
    Dialog: {
      Title  : 'Delete FAQ?',
      Content: 'Are you sure you want to permanently delete the FAQ?'
    },
    Notification: {
      Success: {
        Save  : 'The FAQ was saved successfully.',
        Delete: 'The FAQ was deleted successfully.'
      },
      Error: {
        Save  : 'There was a problem while saving! @:Common.Misc.Retry',
        Delete: 'There was a problem while deleting! @:Common.Misc.Retry'
      }
    },
    Filter: {
      filterCategory: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'FAQ Category',
        PlaceHolder: 'All FAQ Categories',
        ItemText   : 'Title',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    }
  },

  FaqsAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add FAQ',
        Subtitle: 'Create New FAQ'
      },
      Edit: {
        Title   : 'Edit FAQ',
        Subtitle: 'Modify FAQ Details'
      },
      Icon : 'support',
      Color: 'indigo'
    },
    Field: {
      Title: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Title.Label',
        Hint : 'FAQ Title',
        Icon : 'title',
        Error: {
          Between: '@:Faq.FaqCategoryAddDialog.Field.Title.Error.Between'
        }
      },
      Description: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Description.Label',
        Hint : 'FAQ Description',
        Icon : 'short_text',
        Error: {
          Between: '@:Faq.FaqCategoryAddDialog.Field.Description.Error.Between'
        }
      },
      Slug: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Slug.Label',
        Hint : 'Slugified FAQ title',
        Icon : 'subtitles',
        Error: {
          Between: '@:Faq.FaqCategoryAddDialog.Field.Slug.Error.Between'
        }
      },
      Category: {
        Label: '@:Faq.Faqs.Toolbar.Select.Label',
        Icon : '@:Faq.Faqs.Toolbar.Select.Icon',
        Error: {
          Required: 'You must select a category'
        }
      },
      Tip: {
        Label: 'Tip',
        Hint : 'FAQ Tip',
        Icon : 'help_outline',
        Error: {
          Between: 'The Tip must be between 2 and 255 characters'
        }
      },
      Note: {
        Label: 'Note',
        Hint : 'FAQ Note',
        Icon : 'note',
        Error: {
          Between: 'The Note must be between 2 and 255 characters'
        }
      },
      VideoId: {
        Label: 'YouTube Video',
        Hint : 'YouTube Video ID. Eg.reaRZQTPKCY',
        Icon : 'movie',
        Error: {
          Between: 'The Video ID must be between 2 and 255 characters'
        }
      },
      Priority: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Priority.Label',
        Hint : '@:Faq.FaqCategoryAddDialog.Field.Priority.Hint',
        Icon : '@:Faq.FaqCategoryAddDialog.Field.Priority.Icon',
        Error: {
          Min: '@:Faq.FaqCategoryAddDialog.Field.Priority.Error.Min'
        }
      },
      Content     : { Label: 'Content' },
      ContentTitle: { Label: 'Content Text' },
      ContentMedia: { Label: 'Content Media' },
      Image       : { Label: 'Image' }
    }
  },

  ImagePreview: {
    Toolbar: {
      Title: 'Image @:Common.Button.Preview',
      Icon : 'photo',
      Color: 'indigo'
    }
  }
}
