<template>
  <div
    v-if="order"
    class="d-inline-flex"
  >
    <v-tooltip
      v-if="parseInt(order.OrderTypeId) === 2 && parseInt(order.SourceTypeId) !== 4"
      :color="parseInt(order.DeliveryStaffId) === -1 ? 'light-blue' : deliveryStaffHasActiveShift(order) ? 'light-green darken-2' : 'grey darken-1'"
      content-class="text-center"
      right
    >
      <template #activator="{on}">
        <div
          class="d-inline-block"
          v-on="on"
        >
          <v-badge
            v-if="parseInt(order.DeliveryStaffId) > -1"
            :color="deliveryStaffHasActiveShift(order) ? 'light-green' : 'grey'"
            overlap
            right
          >
            <template #badge>
              <v-icon
                dark
                small
              >
                done
              </v-icon>
            </template>

            <v-chip
              :small="dense"
              label
              class="light-blue lighten-1 white--text elevation-0 mx-1 pl-0"
              :style="`min-width: ${chipMinWidth}px;`"
            >
              <v-avatar
                class="light-blue darken-1 mr-2"
                :style="avatarSize"
                tile
              >
                <v-icon
                  color="light-blue lighten-4"
                  :size="iconSize"
                >
                  directions_run
                </v-icon>
              </v-avatar>

              <div style="width: 100%;">
                <span
                  v-if="label"
                  class="mr-1"
                >
                  {{ getOrderTypeName(order) | uppercase | sanitizeDiacritics }}
                </span>
                {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
              </div>
            </v-chip>
          </v-badge>

          <v-chip
            v-else
            :small="dense"
            label
            class="light-blue lighten-1 white--text elevation-0 mx-1 pl-0"
            :style="`min-width: ${chipMinWidth}px;`"
          >
            <v-avatar
              class="light-blue darken-1 mr-2"
              :style="avatarSize"
              tile
            >
              <v-icon
                color="light-blue lighten-4"
                :size="iconSize"
              >
                directions_run
              </v-icon>
            </v-avatar>

            <div style="width: 100%;">
              <span
                v-if="label"
                class="mr-1"
              >
                {{ getOrderTypeName(order) | uppercase | sanitizeDiacritics }}
              </span>
              {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
            </div>
          </v-chip>
        </div>
      </template>

      <div v-if="parseInt(order.DeliveryStaffId) === -1">
        {{ $t('Order.OrdersAll.ListView.Type.Collection') }} {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
      </div>
      <div v-else>
        <strong>{{
          $tc('Delivery.Title', 1)
        }}</strong><br>{{
          order.DeliveryStaffFirstname + ' ' + order.DeliveryStaffLastname | sanitizeDiacritics | uppercase
        }}
      </div>
    </v-tooltip>

    <v-tooltip
      v-if="parseInt(order.OrderTypeId) === 1"
      :color="parseInt(order.DeliveryStaffId) === -1 ? 'purple' : deliveryStaffHasActiveShift(order) ? 'light-green darken-2' : 'grey darken-1'"
      content-class="text-center"
      right
    >
      <template #activator="{on}">
        <div
          class="d-inline-block"
          v-on="on"
        >
          <v-badge
            v-if="parseInt(order.DeliveryStaffId) > -1"
            :color="deliveryStaffHasActiveShift(order) ? 'light-green' : 'grey'"
            overlap
            right
          >
            <template #badge>
              <v-icon
                dark
                small
              >
                done
              </v-icon>
            </template>

            <v-chip
              :small="dense"
              label
              class="purple lighten-1 white--text elevation-0 mx-1 pl-0"
              :style="`min-width: ${chipMinWidth}px;`"
            >
              <v-avatar
                class="purple mr-2"
                :style="avatarSize"
                tile
              >
                <v-icon
                  class="purple--text text--lighten-3"
                  :size="iconSize"
                >
                  directions_bike
                </v-icon>
              </v-avatar>

              <div
                style="width: 100%;"
                class="text-center"
              >
                <span
                  v-if="label"
                  class="mr-1"
                >
                  {{ getOrderTypeName(order) | uppercase | sanitizeDiacritics }}
                </span>
                {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
              </div>
            </v-chip>
          </v-badge>

          <v-chip
            v-else
            :small="dense"
            label
            class="purple lighten-1 white--text elevation-0 mx-1 pl-0"
            :style="`min-width: ${chipMinWidth}px;`"
          >
            <v-avatar
              class="purple mr-2"
              :style="avatarSize"
              tile
            >
              <v-icon
                class="purple--text text--lighten-3"
                :size="iconSize"
              >
                directions_bike
              </v-icon>
            </v-avatar>

            <div
              style="width: 100%;"
              class="text-center"
            >
              <span
                v-if="label"
                class="mr-1"
              >
                {{ getOrderTypeName(order) | uppercase | sanitizeDiacritics }}
              </span>
              {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
            </div>
          </v-chip>
        </div>
      </template>

      <div v-if="parseInt(order.DeliveryStaffId) === -1">
        {{ $t('Order.OrdersAll.ListView.Type.Delivery') }} {{ convertMinutesToDisplayString(order.OrderTypeTime) }}
      </div>
      <div v-else>
        <strong>{{
          $tc('Delivery.Title', 1)
        }}</strong><br>{{
          order.DeliveryStaffFirstname + ' ' + order.DeliveryStaffLastname | sanitizeDiacritics | uppercase
        }}
      </div>
    </v-tooltip>

    <v-tooltip
      v-if="parseInt(order.SourceTypeId) === SourceTypeEnum.TABLE"
      color="brown"
      content-class="text-center"
      right
    >
      <template #activator="{on}">
        <div
          class="d-inline-block"
          v-on="on"
        >
          <v-chip
            :small="dense"
            label
            class="brown lighten-1 white--text elevation-0 mx-1 pl-0"
            :style="`min-width: ${chipMinWidth}px;`"
          >
            <v-avatar
              class="brown darken-1 mr-2"
              :style="avatarSize"
              tile
            >
              <v-icon
                class="brown--text text--lighten-4"
                :size="iconSize"
              >
                tab_unselected
              </v-icon>
            </v-avatar>

            <div
              style="width: 100%;"
              class="text-center"
            >
              <span
                v-if="label"
                class="mr-1"
              >
                {{ getOrderTypeName(order) | uppercase | sanitizeDiacritics }}
              </span>
              {{ convertMinutesToDisplayString(order.TableTime || 1) }}
            </div>
          </v-chip>
        </div>
      </template>

      <div>
        <div
          v-if="order.Table"
          class="text-body-2 mb-2"
        >
          {{ order.Table.TableAreaName }} #{{ order.Table.TableName }}
        </div>
        <strong>{{ $tc('Waiter.Title', 1) }}</strong><br><span v-if="order.StaffId">{{
          order.StaffFirstname + ' ' + order.StaffLastname | sanitizeDiacritics | uppercase
        }}</span>
      </div>
    </v-tooltip>
  </div>
</template>

<script>

import OrderTypeTime  from '@/mixins/orders/orderTypeTime'
import SourceTypeEnum from '@/api/Enums/SourceTypeEnum'
import OrderType      from '@/mixins/orders/orderType'

export default {
  name      : 'OrderTypeChip',
  components: {},
  directives: {},
  mixins    : [OrderType, OrderTypeTime],
  props     : {
    order: {
      type   : Object,
      default: undefined
    },
    minWidth: {
      type   : [Number, String],
      default: 105
    },
    label: {
      type   : Boolean,
      default: false
    },
    dense: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    SourceTypeEnum () {
      return SourceTypeEnum
    },
    chipMinWidth () {
      return this.minWidth.toString().replaceAll('px', '')
    },
    iconSize () {
      return this.dense ? 18 : 22
    },
    avatarSize () {
      return `width: ${ this.dense ? 32 : 46 }px !important; height: 32px !important;`
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    deliveryStaffHasActiveShift (order) {
      return order && order.DeliveryStaffHasActiveShift
    }
  }
}
</script>

<style scoped>
/deep/ .v-chip > .v-chip__content {
  width : 100%;
}
</style>
