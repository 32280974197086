<template>
  <v-container
    v-if="hasOrders"
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12">
        <template v-for="cart in completedDataFiltered">
          <v-container
            :key="`cart-${cart.Timestamp}`"
            class="pa-0"
            fluid
          >
            <v-row dense>
              <!-- CART HEADER -->
              <v-col cols="12">
                <div
                  :class="[`${tableStaffColor(cart.StaffId)} lighten-4`]"
                  class="pa-1 grey--text text--darken-3 text-subtitle-1 font-weight-bold rounded"
                >
                  <v-container
                    class="pa-0"
                    fluid
                  >
                    <v-row
                      dense
                      align="center"
                      justify="center"
                    >
                      <v-col cols="8">
                        <div>{{ $tc('Order.Title', 1) }} #{{ cart.OrderNumber }} -&nbsp;<span class="grey--text text--darken-1">{{ cart.Time }}</span></div>

                        <div
                          v-if="cart.StaffName"
                          class="text-caption"
                        >
                          <div class="grey--text text--darken-3">
                            <strong>{{ $tc('Waiter.Title', 1) }}:</strong> {{ cart.StaffName }}
                          </div>
                        </div>
                      </v-col>

                      <v-col
                        class="text-right pr-3"
                        cols="4"
                      >
                        <template v-if="print">
                          <template v-if="canceled">
                            <print-button
                              class="ml-1"
                              :menu-position="'left'"
                              hide-label
                              icon
                              menu-offset-x
                              small
                              @printer-selected="printer => printTableCanceled(cart, printer)"
                            />
                          </template>

                          <template v-if="!unpaid && !paid && !canceled">
                            <print-preparation-button
                              v-if="printOrderPreparation"
                              class="ml-1"
                              :menu-position="'left'"
                              :order-print-groups="cart.PrintGroups || []"
                              hide-label
                              icon
                              menu-offset-x
                              small
                              @printer-group-selected="printerGroup => printTableOrderPreparation(cart, printerGroup)"
                            />

                            <print-button
                              v-if="printOrder"
                              class="ml-1"
                              :menu-position="'left'"
                              hide-label
                              icon
                              menu-offset-x
                              small
                              @printer-selected="printer => printTableOrder(cart, printer)"
                            />

                            <external-receipt-toggle-button
                              v-if="externalReceipt && !issueReceipt"
                              icon
                              small
                              :timestamp="cart.Timestamp"
                              :cart-id="viewData.Id"
                              :table-id="table.Id"
                              :selected="!!cart.ExternalReceipt"
                              class="ml-1"
                            />

                            <manage-fiscal-documents-button
                              v-if="userCanViewReceipt && issueReceipt && appHasFnbDocEnabled && !orderId"
                              :receipts="viewData.OrderReceipts"
                              :table="table"
                              :order="cart"
                              :can-issue-document="cart.CanGenerateReceipt"
                              :tooltip-open-delay="1000"
                              menu-offset-y
                              hide-label
                              small-size="28"
                              small
                              icon
                              small-icon
                              tooltip-position="top"
                              class="ml-1"
                              @issue-document="data => $bus.$emit('fiscal-document:create', {TableId: table.Id, CartId: viewData.Id, Timestamp: cart.Timestamp}, data.DocType)"
                            />
                          </template>
                        </template>

                        <template v-if="selectable">
                          <v-spacer />

                          <div class="d-inline-block">
                            <v-checkbox
                              :indeterminate="someOrderMenuItemsSelected(cart) && !allOrderMenuItemsSelected(cart)"
                              :value="someOrderMenuItemsSelected(cart)"
                              class="ma-1"
                              color="grey darken-2"
                              hide-details
                              @change="toggleOrderMenuItems(cart)"
                            />
                          </div>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>

              <!-- CART ITEMS -->
              <v-col cols="12">
                <v-container
                  class="mb-4 pa-0"
                  fluid
                >
                  <v-row dense>
                    <!-- ITEMS -->
                    <v-col cols="12">
                      <template v-for="tableSeat in getCartItems(cart, false, selectable, filterItemsByField)">
                        <v-subheader
                          :key="`table-seat-${tableSeat.Seat}`"
                          :class="[`${tableStaffColor(cart.StaffId)}`, isDarkMode ? 'darken-3' : 'lighten-5']"
                          class="font-weight-bold pl-1 rounded"
                          style="height: 26px;"
                        >
                          {{ tableSeat.Name }}
                        </v-subheader>

                        <template v-for="category in tableSeat.Items">
                          <v-subheader
                            :key="`cart-${cart.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}`"
                            class="grey--text text--darken-2 text-subtitle-1 font-weight-bold pl-0"
                            style="height: 36px;"
                          >
                            {{ getTranslatedField(getCategory(category.CategoryId), 'Name') || category.CategoryName }}
                          </v-subheader>

                          <v-list
                            :key="`cart-${cart.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-list`"
                            class="pa-0"
                            dense
                            three-line
                          >
                            <template v-for="(menu) in category.Items">
                              <v-list-item
                                :key="`cart-${cart.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-item-${menu.RowId}-hash-${menu.HashId}`"
                                :disabled="menu.Loading"
                                :class="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                                ripple
                                v-on="selectable ? {click: () => toggleMenuItem(menu)} : editableItemUnits && menu.UnitId > 1 && menu.PaymentQuantity > 0 ? {click: () => showUnitQuantityDialog(menu, cart)} : {}"
                              >
                                <v-list-item-action
                                  v-if="selectable"
                                  class="my-auto"
                                >
                                  <v-checkbox
                                    readonly
                                    hide-details
                                    :ripple="false"
                                    :value="isMenuItemSelected(menu)"
                                    style="pointer-events: none;"
                                  />
                                </v-list-item-action>

                                <v-list-item-content>
                                  <span
                                    :style="`background-color: #${getCategoryColor(category.CategoryId) || '333333'};`"
                                    style="position: absolute; width:5px; height: 100%; margin-left: -16px; padding: 0; margin-right: 16px; top: 1px;"
                                  />

                                  <v-list-item-title class="text-subtitle-1 font-weight-regular">
                                    <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                      <v-icon
                                        v-if="editableItemUnits && menu.PaymentQuantity > 0 && cartItemsCanChangeUnitQuantity(cart.Timestamp, viewData.OrderReceipts)"
                                        class="mr-2"
                                      >
                                        mdi-keyboard-outline
                                      </v-icon>
                                      <strong class="orange--text">{{ formatUnitQuantity($i18n.locale, menu.UnitQuantity, getMeasurementUnitById(menu.UnitId)) }}</strong>
                                    </template>
                                    <template v-else>
                                      <strong class="orange--text">
                                        {{ getMenuQuantity(menu) }} x
                                      </strong>
                                    </template>
                                    {{ getTranslatedField(menu, 'Name') }}
                                  </v-list-item-title>

                                  <v-list-item-subtitle class="grey--text">
                                    <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                      <span class="old-price">
                                        {{ menu.MenuPrice | currency }}
                                      </span>
                                      {{ menu.SpecialPrice | currency }}
                                    </template>

                                    <template v-else>
                                      <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                        {{ menu.MenuPrice | currency }}
                                        <span class="grey--text text-caption">/ {{ getMeasurementUnitById(menu.UnitId).BaseUnit }}</span>
                                      </template>
                                      <template v-else>
                                        <span
                                          v-if="menuItemHasCoupon(menu)"
                                          class="old-price"
                                        >
                                          {{ parseFloat(menu.Price) | currency }}
                                        </span>
                                        {{ menuItemHasCoupon(menu) ? parseFloat(menu.PriceFmt) - (parseFloat(menu.Coupon.Amount) / getMenuQuantity(menu)) : menu.PriceFmt | currency }}
                                      </template>
                                    </template>
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle class="pb-1 text-caption grey--text">
                                    {{ getMenuSelectedOptionsString(menu) }}&nbsp;
                                  </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-list-item-action-text class="text-subtitle-1 grey--text">
                                    <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                      <span class="old-price">
                                        {{ parseFloat(parseFloat(menu.MenuPrice) * getMenuQuantity(menu)) | currency }}
                                      </span>
                                      {{ parseFloat(parseFloat(menu.SpecialPrice) * getMenuQuantity(menu)) | currency }}
                                    </template>

                                    <template v-else>
                                      <span
                                        v-if="menuItemHasCoupon(menu)"
                                        class="old-price"
                                      >
                                        {{ parseFloat(parseFloat(menu.Price) * getMenuQuantity(menu)) | currency }}
                                      </span>
                                      {{ menuItemPriceTotal(menu, menuItemHasCoupon(menu)) | currency }}
                                    </template>
                                  </v-list-item-action-text>

                                  <v-list-item-action-text>
                                    <v-btn
                                      v-if="menu.Custom && parseInt(menu.Custom.Order)"
                                      class="d-inline-block ml-1 px-1"
                                      color="pink lighten-1"
                                      text
                                      ripple
                                      small
                                      style="min-width: 0px;"
                                    >
                                      <v-icon>
                                        low_priority
                                      </v-icon>

                                      <span style="position: relative; top: -4px;">{{ menu.Custom.Order }}</span>
                                    </v-btn>

                                    <v-btn
                                      v-if="menu.Custom && parseInt(menu.Custom.Seat)"
                                      class="d-inline-block ml-1 px-1"
                                      color="brown lighten-1"
                                      text
                                      small
                                      style="min-width: 0px;"
                                    >
                                      <v-icon>
                                        event_seat
                                      </v-icon>

                                      <span style="position: relative; top: -4px;">{{ menu.Custom.Seat }}</span>
                                    </v-btn>

                                    <v-btn
                                      v-if="menuItemHasCoupon(menu)"
                                      class="d-inline-block ml-1 px-1"
                                      color="purple"
                                      text
                                      small
                                      style="min-width: 0px;"
                                    >
                                      <v-icon>
                                        redeem
                                      </v-icon>
                                    </v-btn>
                                  </v-list-item-action-text>
                                </v-list-item-action>
                              </v-list-item>

                              <v-divider
                                :key="`cart-${cart.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-item-divider-${menu.RowId}-hash-${menu.HashId}`"
                                class="mb-1"
                              />
                            </template>
                          </v-list>
                        </template>
                      </template>
                    </v-col>

                    <!-- SUBTOTALS -->
                    <v-col
                      v-if="subTotals"
                      class="grey--text text--darken-1"
                      cols="12"
                    >
                      <template v-if="cartHasCoupons(cart)">
                        <div class="pl-1 pr-2 pt-1 pb-1">
                          <strong>
                            <span class="grey--text text--darken-1">
                              {{ $t('Common.Misc.Subtotal').toLocaleUpperCase($i18n.locale) }}
                            </span>
                            <span class="grey--text text--darken-1 float-right pr-2">
                              {{ cartOrderPriceTotal(cart, false) | currency }}
                            </span>
                          </strong>
                        </div>

                        <template v-for="(total, index) in cartCoupons(cart)">
                          <div
                            :key="index"
                            class="pl-1 pr-2"
                          >
                            <span class="grey--text text--darken-1">
                              {{ total.Title }}
                            </span>
                            <span class="grey--text text--darken-1 float-right pr-2">
                              -{{ total.Amount | currency }}
                            </span>
                          </div>
                        </template>
                      </template>

                      <div class="pl-1 pr-2 pt-1 pb-1">
                        <strong>
                          <span class="grey--text text--darken-1">
                            {{ cartHasCoupons(cart) ? $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) : $t('Common.Misc.Subtotal').toLocaleUpperCase($i18n.locale) }}
                          </span>
                          <span class="grey--text text--darken-1 float-right pr-2">
                            {{ cartOrderPriceTotal(cart, true) | currency }}
                          </span>
                        </strong>
                      </div>
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>

      <!-- TOTAL -->
      <v-col
        v-if="totals"
        class="grey--text text--darken-1 white"
        cols="12"
      >
        <div class="pl-1 pr-2 pt-1 pb-1">
          <strong>
            <span class="grey--text text--darken-1">
              {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
            </span>
            <span class="grey--text text--darken-1 float-right pr-2">
              {{ cartsPriceTotal() | currency }}
            </span>
          </strong>
        </div>
        <v-divider />
      </v-col>

      <num-pad-dialog
        v-if="selectedItem"
        :visible.sync="unitQuantityDialogVisible"
        :use-decimals="false"
        :suffix="selectedItemRateUnit"
        :max-num-value="selectedItem.SubtractStock ? maxQuantity : undefined"
        :min-num-value="selectedItem.MinimumQuantity ? parseInt(selectedItem.MinimumQuantity) : undefined"
        number
        @save="onSetUnitQuantity"
      />
    </v-row>
  </v-container>
</template>

<script>
import Auth                        from '@/mixins/auth'
import AppData                     from '@/mixins/appdata'
import Translatable                from '@/mixins/translatable'
import TableStatus                 from '@/mixins/tables/tableStatus'
import PosCatalog                  from '@/mixins/pos/posCatalog'
import vModel                      from '@/mixins/vModel'
import CompletedData               from '@/mixins/tables/completedData'
import TableCommon                 from '@/mixins/tables/tableCommon'
import Printer                     from '@/lib/helper/printer'
import PrintButton                 from '@/components/common/print/PrintButton.vue'
import PrintPreparationButton      from '@/components/common/print/PrintPreparationButton.vue'
import { formatUnitQuantity }      from '@/lib/currency/currency'
import ExternalReceiptToggleButton from '@/components/common/print/ExternalReceiptToggleButton.vue'
import NumPadDialog                from '@/components/common/NumPadDialog.vue'
import ReceiptTypeEnum             from '@/api/Enums/ReceiptTypeEnum'
import IssueReceiptDocumentButton  from '@/components/common/receipt/IssueReceiptDocumentButton.vue'
import ManageFiscalDocumentsButton from '@/components/common/receipt/ManageFiscalDocumentsButton.vue'

export default {
  name      : 'TableCompletedOrdersList',
  components: {
    ManageFiscalDocumentsButton,
    IssueFnbDocumentButton: ManageFiscalDocumentsButton,
    IssueReceiptDocumentButton,
    NumPadDialog,
    ExternalReceiptToggleButton,
    PrintPreparationButton,
    PrintButton
  },
  directives: {},
  mixins    : [Auth, AppData, vModel, Translatable, TableStatus, TableCommon, CompletedData, PosCatalog],
  props     : {
    viewData: {
      type   : Object,
      default: undefined
    },
    table: {
      type   : Object,
      default: undefined
    },
    canceled: {
      type   : Boolean,
      default: false
    },
    unpaid: {
      type   : Boolean,
      default: false
    },
    paid: {
      type   : Boolean,
      default: false
    },
    selectable: {
      type   : Boolean,
      default: false
    },
    editableItemUnits: {
      type   : Boolean,
      default: false
    },
    totals: {
      type   : Boolean,
      default: false
    },
    subTotals: {
      type   : Boolean,
      default: true
    },
    print: {
      type   : Boolean,
      default: false
    },
    externalReceipt: {
      type   : Boolean,
      default: true
    },
    issueReceipt: {
      type   : Boolean,
      default: true
    },
    printOrder: {
      type   : Boolean,
      default: true
    },
    printOrderPreparation: {
      type   : Boolean,
      default: true
    },
    orderId: {
      type   : [Number, String],
      default: null
    },
    selectedTotal: {
      type   : Number,
      default: 0
    }
  },
  dataStore: {},
  data () {
    return {
      selectedItem             : null,
      unitQuantityDialogVisible: false
    }
  },
  computed: {
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },
    selectedItemRateUnit () {
      return this.getMeasurementUnitById(this.selectedItem?.UnitId || 2)?.RateUnit
    },

    maxQuantity () {
      if (!this.selectedItem) return 0

      const menuItemStockQuantity = parseInt(this.selectedItem?.StockQuantity)
      const menuItemUnitQuantity = parseInt(this.selectedItem?.UnitQuantity)
      const menuItemQuantity = parseInt(this.selectedItem?.Quantity)

      const availableStockQuantity = menuItemStockQuantity
      const menuQuantity = this.userCanSetUnitQuantity ? menuItemUnitQuantity : menuItemQuantity

      return menuQuantity + availableStockQuantity
    },

    userCanSetUnitQuantity () {
      return this.selectedItem?.MenuCustomPrice && this.selectedItem?.UnitId > 1
    },

    ordersToday () {
      return this.$DataStore?.ordersDataToday?.items || []
    },

    isSelectedOrderToday () {
      return !!this.ordersToday.find(order => parseInt(order.Id) === parseInt(this.orderId))
    },

    filterItemsByField () {
      if (this.unpaid) return 'PaymentQuantity'
      if (this.canceled) return 'CancelQuantity'

      return null
    },

    selectedItemsTotal: {
      get () {
        return this.selectedTotal || 0
      },
      set (val) {
        this.$emit('update:selected-total', val)
      }
    },

    completedDataFiltered () {
      return this.completedData
        .filter(cart => this.orderHasMenuItems(cart))
        .filter(cart => this.paid ? cart.Paid : this.unpaid ? !cart.Paid : true)
    },

    hasOrders () {
      if (this.canceled) return !!this.viewData?.CanceledCount
      if (this.unpaid) return !!this.viewData?.UnPaidCount
      if (this.paid) return !!this.viewData?.PaidCount
      return !!this.viewData?.OrdersCount
    }
  },
  watch: {
    vModel (items) {
      this.selectedItemsTotal = items?.reduce((value, menuItem) => value + this.menuItemPriceTotal(menuItem, true), 0) || 0
    }
  },
  beforeCreate () {},
  created () {
    this.$bus.$on('table-order-select-all-carts-items', this.selectAllCartOrderMenuItems)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('table-order-select-all-carts-items', this.selectAllCartOrderMenuItems)
  },
  destroyed () {},
  methods: {
    formatUnitQuantity,

    /**
     *
     * @param item {Object}
     * @param cart {Object}
     */
    showUnitQuantityDialog (item, cart) {
      if (!item || !cart || item?.UnitId === 1 || !this.cartItemsCanChangeUnitQuantity(cart.Timestamp, this.viewData.OrderReceipts)) return

      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.selectedItem.Cart = JSON.parse(JSON.stringify(cart))
      this.unitQuantityDialogVisible = true
    },

    /**
     *
     * @param val {Number}
     */
    onSetUnitQuantity (val) {
      if (!val || !this.selectedItem) return

      window.callAS(window.SocketCommand.Table.Cart.UpdateUnitItem, {
        CartId      : this.table.CartId,
        RowId       : this.selectedItem.RowId,
        TableId     : this.table.Id,
        Timestamp   : this.selectedItem.Cart.Timestamp,
        UnitQuantity: val
      })

      this.selectedItem = null
    },

    /**
     *
     * @param cart {Object}
     */
    cartHasReceipt (cart) {
      return !!this.cartReceiptByCart(cart)
    },

    /**
     *
     * @param cart {Object}
     */
    cartReceiptByCart (cart) {
      const receipts = Array.isArray(this.viewData?.Receipts) ? this.viewData?.Receipts : this.viewData?.Receipts ? [this.viewData?.Receipts] : null
      return receipts?.find(receipt => receipt.Timestamp === cart.Timestamp)
    },

    /**
     *
     * @param cart {Object}
     */
    orderReceiptByCart (cart) {
      const receipt = this.cartReceiptByCart(cart) || null
      const orderReceipt = this.viewData?.OrderReceipts?.find(o => o.OrderId === receipt?.OrderId)

      return orderReceipt || null
    },

    /**
     *
     * @param item {Object}
     */
    getMenuQuantity (item) {
      if (this.unpaid || (this.unpaid && this.selectable)) return parseInt(item.PaymentQuantity)
      if (this.canceled || (this.canceled && this.selectable)) return parseInt(item.CancelQuantity)

      return parseInt(item.Quantity)
    },

    /**
     *
     * @param item {Object}
     */
    isMenuItemSelected (item) {
      return !!this.vModel?.find(menu => menu.HashId === item.HashId)
    },

    /**
     *
     * @param item {Object}
     */
    toggleMenuItem (item) {
      if (!this.vModel) return

      const index = this.vModel?.findIndex(menu => menu.HashId === item.HashId)

      if (index > -1) {
        this.vModel.splice(index, 1)
      } else {
        this.vModel.push(item)
      }
    },

    /**
     *
     * @param cart {Object}
     */
    toggleOrderMenuItems (cart) {
      if (!this.vModel) return

      const seats = this.getCartItems(cart, false, this.selectable, this.filterItemsByField)
      const tmpModel = JSON.parse(JSON.stringify(this.vModel))

      if (this.someOrderMenuItemsSelected(cart) && !this.allOrderMenuItemsSelected(cart)) {
        seats.forEach(seat => {
          seat.Items.forEach(category => {
            category.Items.forEach(menuItem => {
              const index = tmpModel.findIndex(menu => menu.HashId === menuItem.HashId)
              tmpModel.splice(index, 1)
            })
          })
        })

        seats.forEach(seat => {
          seat.Items.forEach(category => {
            category.Items.forEach(menuItem => {
              tmpModel.push(menuItem)
            })
          })
        })
      } else {
        seats.forEach(seat => {
          seat.Items.forEach(category => {
            category.Items.forEach(menuItem => {
              const index = tmpModel.findIndex(menu => menu.HashId === menuItem.HashId)
              if (index > -1) {
                tmpModel.splice(index, 1)
              } else {
                tmpModel.push(menuItem)
              }
            })
          })
        })
      }

      this.vModel = tmpModel
    },

    selectAllCartOrderMenuItems () {
      const tmpModel = []

      this.completedDataFiltered.forEach(cart => {
        const seats = this.getCartItems(cart, false, this.selectable, this.filterItemsByField)

        seats.forEach(seat => {
          seat.Items.forEach(category => {
            category.Items.forEach(menuItem => {
              const index = tmpModel.findIndex(menu => menu.HashId === menuItem.HashId)
              if (index > -1) {
                tmpModel.splice(index, 1)
              } else {
                tmpModel.push(menuItem)
              }
            })
          })
        })
      })

      this.vModel = tmpModel
    },

    /**
     *
     * @param cart {Object}
     */
    allOrderMenuItemsSelected (cart) {
      const items = this.getOrderMenuItems(cart)

      return items.every(menuItem => !!this.vModel && this.vModel.find(menu => menu.HashId === menuItem.HashId))
    },

    /**
     *
     * @param cart {Object}
     */
    someOrderMenuItemsSelected (cart) {
      const items = this.getOrderMenuItems(cart)

      return items.some(menuItem => !!this.vModel && this.vModel.find(menu => menu.HashId === menuItem.HashId))
    },

    /**
     *
     * @param cart {Object}
     */
    getOrderMenuItems (cart) {
      const items = []
      const seats = this.getCartItems(cart, false, this.selectable, this.filterItemsByField)

      seats.forEach(seat => {
        seat.Items.forEach(category => {
          category.Items.forEach(menuItem => {
            items.push(menuItem)
          })
        })
      })

      return items
    },

    /**
     *
     * @param cart {Object}
     */
    orderHasMenuItems (cart) {
      return !!cart?.Items?.filter(item => this.canceled ? item.CancelQuantity : item.Quantity)?.length
    },

    /**
     *
     * @param cart {Object}
     * @param printer {Object}
     */
    printTableCanceled (cart, printer) {
      if (!cart || !printer) return
      const items = cart.Items.map(item => {
        return {
          RowId   : item.RowId,
          Quantity: item.CancelQuantity
        }
      })

      if (this.userIsWaiter) {
        window.callAS(window.SocketCommand.Table.SendCanceledForPrint, {
          cart_id: this.viewData.Id,
          items  : items
        })
      } else {
        Printer.printTableCanceled(this.viewData.Id, items, printer)
      }
    },

    /**
     *
     * @param cart {Object}
     * @param printer {Object}
     */
    printTableOrder (cart, printer) {
      if (!cart || !printer) return

      if (this.userIsWaiter) {
        window.callAS(window.SocketCommand.Table.SendOrderForPrint, {
          cart_id  : this.viewData.Id,
          timestamp: cart.Timestamp
        })
      } else {
        Printer.printTableOrder(this.viewData.Id, cart.Timestamp, printer)
      }
    },

    /**
     *
     * @param cart {Object}
     * @param printerGroup {Object}
     */
    printTableOrderPreparation (cart, printerGroup) {
      if (!cart || !printerGroup) return

      if (this.userIsWaiter) {
        window.callAS(window.SocketCommand.Table.SendOrderPreparationForPrint, {
          cart_id  : this.viewData.Id,
          timestamp: cart.Timestamp
        })
      } else {
        Printer.printTableOrderPreparation(this.viewData.Id, printerGroup.Id, cart.Timestamp)
      }
    }
  }
}
</script>

<style scoped>

</style>
