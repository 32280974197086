export default {
  Coupon: {
    Title   : 'Coupons | Coupon | Coupons',
    ListView: {
      Button: {
        Add: {
          Title: 'New Coupon',
          Color: 'primary',
          Icon : 'add_box'
        },
        Edit: {
          Title: 'Edit Coupon',
          Color: 'cyan',
          Icon : 'edit'
        }
      },
      Table: {
        Headers: {
          Id              : '#',
          Name            : 'Name',
          Code            : 'Code',
          Type            : 'Type',
          Discount        : 'Discount',
          UsedCount       : 'Redemptions',
          Customers       : 'Customers',
          CustomerGroups  : 'Customer Groups',
          OrderRestriction: 'Order Type',
          SourceType      : 'Order Source',
          Validity        : 'Validity',
          AutoApply       : 'Auto Apply',
          Status          : 'Status',
          Actions         : ''
        }
      }
    },
    Dialog: {
      Toolbar: {
        Add: {
          Title   : 'New Coupon',
          SubTitle: 'Create New Coupon'
        },
        Edit: {
          Title   : 'Edit Coupon',
          SubTitle: 'Edit Coupon Details'
        },
        Tab: {
          General     : 'General',
          Restrictions: 'Restrictions',
          History     : 'History'
        }
      },

      History: {
        Table: {
          Headers: {
            Id        : '#',
            Customer  : 'Customer',
            Order     : 'Order',
            OrderTotal: 'Total',
            Amount    : 'Discount',
            Date      : 'Date'
          }
        }
      },

      Field: {

        Name: {
          Label: 'Name',
          Hint : 'Coupon Name. eg. Sale 10%',
          Error: {
            Between: 'The name must be between 2 and 128 characters.'
          }
        },
        Code: {
          Label: 'Code',
          Hint : 'Coupon Code. eg. OFFER10, etc.',
          Error: {
            Between: 'The code must be between 2 and 15 characters'
          }
        },

        Type: {
          Title      : 'Discount Type',
          RadioOption: {
            DiscountPercent: 'Discount Percent',
            FixedPrice     : 'Fixed Price'
          }

        },

        Discount: {
          Label: {
            DiscountPercent: '@:Marketing.Coupon.Dialog.Field.Type.RadioOption.DiscountPercent',
            FixedPrice     : '@:Marketing.Coupon.Dialog.Field.Type.RadioOption.FixedPrice'
          },
          Hint : '@:Marketing.Coupon.Dialog.Field.Discount.Label.DiscountPercent or @:Marketing.Coupon.Dialog.Field.Discount.Label.FixedPrice discount',
          Error: {
            Number: 'Must be number'
          }
        },

        OrderRestriction: {
          Title      : 'Order Type',
          RadioOption: {
            All     : 'All',
            Delivery: 'Delivery',
            TakeAway: 'Take Away'
          }
        },

        Redemptions: {
          Label: 'Redemptions',
          Hint : 'The total number of times this coupon can be redeemed. Enter 0 for unlimited redemptions.',
          Error: {
            Number: '@:Marketing.Coupon.Dialog.Field.Discount.Error.Number'
          }
        },

        CustomerRedemptions: {
          Label: 'Customer Redemptions',
          Hint : 'The total number of times this coupon can be redeemed per customer. Enter 0 for unlimited redemptions.',
          Error: {
            Number: '@:Marketing.Coupon.Dialog.Field.Discount.Error.Number'
          }
        },

        MinTotal: {
          Label: 'Minimum total',
          Hint : 'Minimum order total so that the coupon can be used',
          Error: {
            Number: '@:Marketing.Coupon.Dialog.Field.Discount.Error.Number'
          }
        },

        Status: {
          Title: 'Status'
        },

        AutoApply: {
          Title: 'Auto Apply'
        },

        Validity: {
          Title : 'Validity',
          Toggle: {
            Always: {
              Title: 'Always'
            },
            DateTime: {
              Title  : 'Date / Time',
              Options: {
                TwentyFourHours: '24 Hours',
                Custom         : 'Custom'
              }
            },
            Period: {
              Title: 'Period'
            },
            Repeat: {
              Title  : 'Repeat',
              Options: {
                TwentyFourHours: '@:Marketing.Coupon.Dialog.Field.Validity.Toggle.DateTime.Options.TwentyFourHours',
                Custom         : '@:Marketing.Coupon.Dialog.Field.Validity.Toggle.DateTime.Options.Custom'
              }
            }
          }
        }

      },

      Message: {
        Save: {
          Success: 'Coupon was successfully saved.'
        },
        Update: {
          Success: 'Coupon was successfully updated.'
        }
      }
    }
  }
}
