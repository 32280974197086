import ReservationStatusEnum from '@/api/Enums/ReservationStatusEnum'

export default {
  Title: 'Table Reservations | Table Reservation | Table Reservations',

  Table: {
    Headers: {
      Id         : '#',
      SourceType : 'Source',
      Code       : 'Code',
      Status     : 'Status',
      Tags       : 'Tags',
      Reservation: 'Reservation',
      Date       : 'Date',
      Time       : 'Time',
      Arrival    : 'Arrival',
      Departure  : 'Departure',
      Persons    : 'Persons',
      Duration   : 'Duration',
      Comments   : 'Comments',
      Notes      : 'Notes',
      Customer   : 'Customer',
      Table      : 'Table',
      Actions    : ''
    },

    Button: {
      New: {
        Color: 'primary',
        Icon : 'add_box',
        Title: 'New Reservation'
      },
      Edit: {
        Color  : 'cyan',
        Icon   : 'edit',
        Tooltip: 'Edit Reservation'
      },
      Arrived: {
        Color  : 'light-green',
        Icon   : 'mdi mdi-account-multiple',
        Tooltip: 'Arrived'
      },
      PartiallyArrived: {
        Color  : 'amber darken-2',
        Icon   : 'mdi mdi-account-plus',
        Tooltip: 'Partially Arrived'
      },
      Finished: {
        Color  : 'grey',
        Icon   : 'mdi mdi-check',
        Tooltip: 'Finished'
      },
      Cancel: {
        Color  : 'pink',
        Icon   : 'close',
        Tooltip: 'Cancel Reservation'
      },
      Delete: {
        Color  : 'red',
        Icon   : 'delete',
        Tooltip: 'Διαγραφή Κράτησης'
      }
    },

    Filter: {
      filterWithTable: {
        Type       : 'v-btn-toggle',
        Label      : 'Reservations',
        PlaceHolder: 'All Reservations',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: 'true',
            Name : 'With Table',
            Icon : 'mdi mdi-check-circle-outline',
            Color: 'blue darken-1',
            Class: 'white--text'
          },
          {
            Value: 'false',
            Name : 'Without Table',
            Icon : 'mdi mdi-close-circle-outline',
            Color: 'orange darken-1',
            Class: 'white--text'
          }
        ]
      },
      filterStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Status',
        PlaceHolder: 'All statuses',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterTable: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Table',
        PlaceHolder: 'All tables',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterDate: {
        Type       : 'v-date-picker',
        Range      : false,
        Label      : 'Date',
        PlaceHolder: 'Select Date',
        Icon       : 'event'
      },
      filterTimeStart: {
        Type       : 'v-time-picker',
        Label      : 'From Time',
        PlaceHolder: 'Select Time',
        Cols       : 6,
        Icon       : 'mdi mdi-clock-time-eight-outline'
      },
      filterTimeEnd: {
        Type       : 'v-time-picker',
        Label      : 'To Time',
        PlaceHolder: 'Select Time',
        Cols       : 6,
        Icon       : 'mdi mdi-clock-time-eight-outline'
      }
    },

    ConfirmDialog: {
      Delete: {
        Title       : 'Delete Reservation?',
        Content     : 'Are you sure you want to permanently delete the reservation with code: <strong>{code}</strong> ?',
        Notification: 'The deletion is final and the process is irreversible'
      }
    }
  },

  AddEditDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Reservation',
        Subtitle: 'Create a New Reservation'
      },
      Edit: {
        Title   : 'Edit Reservation',
        Subtitle: 'Edit Reservation Details'
      },
      Icon : 'menu_book',
      Color: 'indigo'
    },

    Section: {
      Customer: {
        TitleCustomer: 'Customer Details',
        TitleGuest   : 'Guest Details',
        Button       : {
          Select: 'Select Customer',
          Change: 'Change Customer',
          Guest : 'Guest'
        }
      },
      Reservation: {
        Title: 'Reservation Details'
      }
    },

    Field: {
      FirstName: {
        Label: 'Firstname',
        Hint : 'Customer\'s name. eg Vangelis, Maria, etc.',
        Icon : 'account_circle',
        Error: {
          Required: 'You must enter a firstname',
          Between : 'The firstname must be between 2 and 32 characters'
        }
      },
      LastName: {
        Label: 'Lastname',
        Hint : 'Customer last name. eg Papadopoulos, Markoras, etc.',
        Icon : 'account_circle',
        Error: {
          Required: 'You must fill in a lastname',
          Between : 'Lastname must be between 2 and 32 characters'
        }
      },
      Telephone: {
        Label: 'Telephone',
        Hint : 'Customer phone number. eg 2152152324',
        Icon : 'phone',
        Error: {
          Required: 'You must enter a phone number',
          Between : 'The phone number must be 8 to 16 numbers'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Customer Email Address. eg support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Required: 'You must fill in E-mail',
          Between : 'E-mail must be up to 96 characters',
          Valid   : 'E-mail must be valid'
        }
      },
      ReserveDate: {
        Label: 'Date',
        Hint : '',
        Icon : 'event',
        Error: {
          Required: 'You must select reservation date',
          Invalid : 'Select valid date'
        }
      },
      ReserveTime: {
        Label: 'Time',
        Hint : '',
        Icon : 'access_time',
        Error: {
          Required: 'You must select reservation time',
          Invalid : 'Select valid time'
        }
      },
      Duration: {
        Label: 'Duration',
        Hint : '',
        Icon : 'mdi mdi-timer-refresh-outline',
        Error: {
          Required: 'You must enter a duration'
        }
      },
      Persons: {
        Label: 'Persons',
        Hint : '',
        Icon : 'mdi mdi-account-multiple-outline',
        Error: {
          Required: 'You must give reservation persons'
        }
      },
      SourceType: {
        Label: 'Order Source Type',
        Hint : '',
        Icon : 'swipe_right_alt',
        Error: {
          Required: 'You must select an order source type'
        }
      },
      Status: {
        Label: 'Status',
        Hint : 'Select Reservation Status',
        Icon : 'mdi mdi-list-status',
        Error: {
          Required: 'Please select a reservation status'
        }
      },
      Tables: {
        Label: 'Table',
        Hint : 'Select Table or Tables for the reservation',
        Icon : 'tab_unselected',
        Error: {}
      },
      Tags: {
        Label: 'Tags',
        Hint : 'Select Reservation Tags',
        Icon : 'mdi mdi-tag-plus',
        Error: {}
      },
      Comment: {
        Label: 'Customer Comments',
        Hint : 'Comments visible to the customer',
        Icon : 'mdi mdi-comment-account-outline'
      },
      Notes: {
        Label: 'Admin Notes',
        Hint : 'Admin notes are not visible to the customer',
        Icon : 'mdi mdi-text-box-edit-outline'
      },
      Notify: {
        Label   : 'Send Notification',
        Customer: { Label: 'Send Notification to customer' },
        Guest   : { Label: 'Send Notification to guest' }
      }
    },

    Notification: {
      Error: 'Υπήρξε πρόβλημα, παρακαλώ δοκιμάστε ξανά.'
    }
  },

  Status: {
    Title: 'Status',
    Items: [
      {
        Id    : ReservationStatusEnum.WaitingList,
        Name  : 'Waiting List',
        Avatar: {
          Color: 'purple',
          Icon : 'mdi mdi-clipboard-text-clock-outline'
        }
      },
      {
        Id    : ReservationStatusEnum.Late,
        Name  : 'Late',
        Avatar: {
          Color: 'orange',
          Icon : 'mdi mdi-clock-alert-outline'
        }
      },
      {
        Id    : ReservationStatusEnum.NotConfirmed,
        Name  : 'Not Confirmed',
        Avatar: {
          Color: 'grey darken-3',
          Icon : 'mdi mdi-cellphone-remove'
        }
      },
      {
        Id    : ReservationStatusEnum.Confirmed,
        Name  : 'Confirmed',
        Avatar: {
          Color: 'blue',
          Icon : 'mdi mdi-cellphone-check'
        }
      },
      {
        Id    : ReservationStatusEnum.NoAnswer,
        Name  : 'No Answer',
        Avatar: {
          Color: 'red darken-2',
          Icon : 'mdi mdi-cellphone-remove'
        }
      },
      {
        Id    : ReservationStatusEnum.Arrived,
        Name  : 'Arrived',
        Avatar: {
          Color: 'light-green',
          Icon : 'mdi mdi-account-multiple'
        }
      },
      {
        Id    : ReservationStatusEnum.PartiallyArrived,
        Name  : 'Partially Arrived',
        Avatar: {
          Color: 'amber darken-2',
          Icon : 'mdi mdi-account-plus'
        }
      },
      {
        Id    : ReservationStatusEnum.Finished,
        Name  : 'Finished',
        Avatar: {
          Color: 'grey',
          Icon : 'mdi mdi-check'
        }
      },
      {
        Id    : ReservationStatusEnum.Denied,
        Name  : 'Denied',
        Avatar: {
          Color: 'pink',
          Icon : 'mdi mdi-minus'
        }
      },
      {
        Id    : ReservationStatusEnum.Canceled,
        Name  : 'Canceled',
        Avatar: {
          Color: 'red',
          Icon : 'mdi mdi-close'
        }
      },
      {
        Id    : ReservationStatusEnum.NoShow,
        Name  : 'No Show',
        Avatar: {
          Color: 'blue-grey',
          Icon : 'mdi mdi-account-remove'
        }
      }
    ]
  },

  Tags: {
    Title: 'Tags',
    Items: [
      {
        Id    : 1,
        Name  : 'Anniversary',
        Avatar: {
          Icon : 'mdi mdi-heart',
          Color: 'amber'
        }
      },
      {
        Id    : 2,
        Name  : 'Birthday',
        Avatar: {
          Icon : 'mdi mdi-cake-variant',
          Color: 'amber'
        }
      },
      {
        Id    : 3,
        Name  : 'Regular',
        Avatar: {
          Icon : 'mdi mdi-account-clock',
          Color: 'pink'
        }
      },
      {
        Id    : 4,
        Name  : 'VIP',
        Avatar: {
          Icon : 'mdi mdi-star',
          Color: 'pink'
        }
      },
      {
        Id    : 5,
        Name  : 'Smoking Area',
        Avatar: {
          Icon : 'mdi mdi-smoking',
          Color: 'cyan'
        }
      },
      {
        Id    : 6,
        Name  : 'Window',
        Avatar: {
          Icon : 'mdi mdi-window-closed-variant',
          Color: 'cyan'
        }
      }
    ]
  }
}
