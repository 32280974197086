<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1200"
      persistent
      scrollable
    >
      <v-card v-if="domain">
        <v-toolbar
          class="pl-1"
          extension-height="40"
          flat
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Domain.Edit.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Domain.Edit.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{
                isAddNewMode ? $t('Settings.Domain.Edit.Toolbar.Add.Title') : $t('Settings.Domain.Edit.Toolbar.Edit.Title')
              }}
            </div>

            <div class="text-caption">
              {{
                isAddNewMode ? $t('Settings.Domain.Edit.Toolbar.Add.Subtitle') : $t('Settings.Domain.Edit.Toolbar.Edit.Subtitle')
              }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
              height="40"
              slider-color="blue"
            >
              <v-tab key="domain">
                {{ $t('Settings.Domain.Edit.Tab.Domain').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="settings">
                {{ $t('Settings.Domain.Edit.Tab.Settings').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="documents">
                {{ $t('Settings.Domain.Edit.Tab.Documents').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="customer">
                {{ $t('Settings.Domain.Edit.Tab.Customer').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="permissions">
                {{ $t('Settings.Domain.Edit.Tab.Permissions').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="mobile">
                {{ $t('Settings.Domain.Edit.Tab.Mobile').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="sms">
                {{ $t('Settings.Domain.Edit.Tab.Sms').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="marketing">
                {{ $t('Settings.Domain.Edit.Tab.Marketing').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="platforms">
                {{ $t('Settings.Domain.Edit.Tab.Platforms').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="webhooks">
                {{ $t('Settings.Domain.Edit.Tab.WebHooks').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab key="logging">
                {{ $t('Settings.Domain.Edit.Tab.RemoteLogging').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab
                v-if="!isAddNewMode"
                key="application"
              >
                {{ $t('Settings.Domain.Edit.Tab.Application').toLocaleUpperCase($i18n.locale) }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="domainForm"
            v-model="domainFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="tab">
              <v-tab-item key="domain">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col
                      cols="8"
                      sm="10"
                    >
                      <v-text-field
                        v-model="domain.Domain"
                        :hint="$t('Settings.Domain.Edit.Field.SubDomain.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.SubDomain.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.SubDomain.Icon')"
                        :rules="domain.vuetifyFormFieldRules('Domain')"
                        :suffix="`.${domain.BaseDomain}`"
                        class="d-inline-flex"
                        required
                        style="width: 60%;"
                        @input="onDomainChange"
                      >
                        <template
                          v-if="checkDomainStatus!==null"
                          slot="append-outer"
                        >
                          <v-icon :color="checkDomainStatus ? 'light-green' : 'grey lighten-1'">
                            {{ checkDomainStatus ? 'check_circle' : 'remove_circle' }}
                          </v-icon>
                        </template>
                      </v-text-field>

                      <v-autocomplete
                        v-model="domain.BaseDomain"
                        :hint="$t('Settings.Domain.Edit.Field.BaseDomain.Hint')"
                        :items="systemDomainsList"
                        :label="$t('Settings.Domain.Edit.Field.BaseDomain.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.BaseDomain.Icon')"
                        class="d-inline-flex pl-4"
                        required
                        style="width: 40%;"
                      />
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="4"
                      sm="2"
                    >
                      <v-switch
                        v-model="domain.Active"
                        :label="domain.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('Active')"
                        class="d-inline-flex ml-2"
                        color="blue"
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="8"
                      sm="10"
                    >
                      <v-text-field
                        v-model="domain.CName"
                        :hint="$t('Settings.Domain.Edit.Field.CName.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.CName.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.CName.Icon')"
                        :rules="domain.vuetifyFormFieldRules('CName')"
                        required
                      >
                        <template
                          v-if="checkCNameStatus!==null"
                          slot="append-outer"
                        >
                          <v-icon :color="checkCNameStatus ? 'light-green' : 'grey lighten-1'">
                            {{ checkCNameStatus ? 'check_circle' : 'remove_circle' }}
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="4"
                      sm="2"
                    >
                      <v-switch
                        v-model="domain.CNameActive"
                        :label="domain.CNameActive ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('CNameActive')"
                        class="d-inline-flex ml-2"
                        color="blue"
                        inset
                      />
                    </v-col>

                    <template v-if="domain.CNameActive">
                      <v-col>
                        <v-icon left>
                          {{ $t('Settings.Domain.Edit.Field.CName.Icon') }}
                        </v-icon>
                        <div class="d-inline-block">
                          <h5 class="text-body-2 font-weight-bold">
                            {{ $t('Settings.Domain.Edit.Field.SSLCertificate.Title') }}
                          </h5>
                          <div class="text-caption">
                            {{ $t('Settings.Domain.Edit.Field.SSLCertificate.Subtitle') }}
                          </div>
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.ForceSSL"
                          :disabled="!userCanManage || !domain.CNameActive"
                          :label="domain.ForceSSL ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          :rules="domain.vuetifyFormFieldRules('ForceSSL')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>
                    </template>

                    <v-col
                      cols="8"
                      sm="10"
                    >
                      <v-text-field
                        v-model="domain.VoipDomain"
                        :hint="$t('Settings.Domain.Edit.Field.VoipDomain.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.VoipDomain.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.VoipDomain.Icon')"
                        :rules="domain.vuetifyFormFieldRules('VoipDomain')"
                        class="d-inline-flex"
                        required
                        style="width: 50%"
                      />

                      <v-text-field
                        v-model="domain.VoipDomainDescription"
                        :hint="$t('Settings.Domain.Edit.Field.VoipDomainDesc.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.VoipDomainDesc.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.VoipDomainDesc.Icon')"
                        :rules="domain.vuetifyFormFieldRules('VoipDomainDescription')"
                        class="d-inline-flex"
                        required
                        style="width: 50%"
                      />
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="4"
                      sm="2"
                    >
                      <v-switch
                        v-model="domain.VoipDomainActive"
                        :label="domain.VoipDomainActive ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('VoipDomainActive')"
                        class="d-inline-flex ml-2"
                        color="blue"
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="8"
                      sm="10"
                    >
                      <v-text-field
                        v-model="domain.DatabaseName"
                        :disabled="!isAddNewMode || domain.DatabaseAutoGenerate"
                        :hint="$t('Settings.Domain.Edit.Field.DatabaseName.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.DatabaseName.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.DatabaseName.Icon')"
                        :readonly="!isAddNewMode"
                        :rules="domain.vuetifyFormFieldRules('DatabaseName')"
                        required
                      />
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="4"
                      sm="2"
                    >
                      <v-switch
                        v-model="domain.DatabaseAutoGenerate"
                        :disabled="!isAddNewMode"
                        :label="domain.DatabaseAutoGenerate ? $t('Settings.Domain.Edit.Field.DatabaseAutoGenerate.Active') : $t('Settings.Domain.Edit.Field.DatabaseAutoGenerate.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('DatabaseAutoGenerate')"
                        class="d-inline-flex ml-2"
                        color="blue"
                        inset
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-select
                        v-model="domain.DatabaseConnection"
                        :disabled="!isAddNewMode"
                        :hint="$t('Settings.Domain.DatabaseMove.Field.TargetDatabase.Hint')"
                        :items="databasesList"
                        :label="$t('Settings.Domain.DatabaseMove.Field.TargetDatabase.Label')"
                        :prepend-icon="$t('Settings.Domain.DatabaseMove.Field.TargetDatabase.Icon')"
                        :rules="[(v) => !!v || $t('Common.Error.Mandatory')]"
                        item-text="Name"
                        item-value="Id"
                        mandatory
                        required
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                        v-model="domain.StoreGroup"
                        :hint="$t('Settings.Domain.Edit.Field.StoreGroup.Hint')"
                        :item-text="(data) => data.Lang && data.Lang[$i18n.locale] && data.Lang[$i18n.locale].Title || data.Title"
                        :items="types"
                        :label="$t('Settings.Domain.Edit.Field.StoreGroup.Label')"
                        :rules="domain.vuetifyFormFieldRules('StoreGroup')"
                        item-value="GroupId"
                        prepend-icon="map"
                        required
                      />
                    </v-col>

                    <template v-if="isAddNewMode">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ $t('Settings.Domain.Edit.Field.ImportDummyData.Title') }}
                        </h5>
                        <div class="text-caption">
                          {{ $t('Settings.Domain.Edit.Field.ImportDummyData.Subtitle') }}
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.WithDummyData"
                          :disabled="!userCanManage"
                          :label="domain.WithDummyData ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          :rules="domain.vuetifyFormFieldRules('WithDummyData')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ $t('Settings.Domain.Edit.Field.ImportFromThirdParty.Title') }}
                        </h5>
                        <div class="text-caption">
                          {{ $t('Settings.Domain.Edit.Field.ImportFromThirdParty.Subtitle') }}
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.ImportFromThirdParty"
                          :disabled="!userCanManage"
                          :label="domain.ImportFromThirdParty ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          :rules="domain.vuetifyFormFieldRules('ImportFromThirdParty')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>

                      <template v-if="domain.ImportFromThirdParty">
                        <v-col
                          class="mb-4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="domain.ThirdPartyUrl"
                            :label="$t('Settings.Domain.Edit.Field.ImportFromThirdParty.Label')"
                            :rules="domain.vuetifyFormFieldRules('ThirdPartyUrl')"
                            hide-details
                            required
                          />
                        </v-col>

                        <v-col>
                          <h5 class="text-body-2 font-weight-bold">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyForce.Title') }}
                          </h5>
                          <div class="text-caption">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyForce.Subtitle') }}
                          </div>
                        </v-col>
                        <v-col class="shrink">
                          <v-switch
                            v-model="domain.ImportFromThirdPartyForce"
                            :disabled="!userCanManage"
                            :label="domain.ImportFromThirdPartyForce ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                            :rules="domain.vuetifyFormFieldRules('ImportFromThirdPartyForce')"
                            class="ma-0 d-inline-flex ml-2"
                            color="blue"
                            hide-details
                            inset
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-divider class="my-2" />
                        </v-col>

                        <v-col>
                          <h5 class="text-body-2 font-weight-bold">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyWithOptions.Title') }}
                          </h5>
                          <div class="text-caption">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyWithOptions.Subtitle') }}
                          </div>
                        </v-col>
                        <v-col class="shrink">
                          <v-switch
                            v-model="domain.ImportFromThirdPartyWithOptions"
                            :disabled="!userCanManage"
                            :label="domain.ImportFromThirdPartyWithOptions ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                            :rules="domain.vuetifyFormFieldRules('ImportFromThirdPartyWithOptions')"
                            class="ma-0 d-inline-flex ml-2"
                            color="blue"
                            hide-details
                            inset
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-divider class="my-2" />
                        </v-col>

                        <v-col>
                          <h5 class="text-body-2 font-weight-bold">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyAllLocales.Title') }}
                          </h5>
                          <div class="text-caption">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyAllLocales.Subtitle') }}
                          </div>
                        </v-col>
                        <v-col class="shrink">
                          <v-switch
                            v-model="domain.ImportFromThirdPartyAllLocales"
                            :disabled="!userCanManage"
                            :label="domain.ImportFromThirdPartyAllLocales ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                            :rules="domain.vuetifyFormFieldRules('ImportFromThirdPartyAllLocales')"
                            class="ma-0 d-inline-flex ml-2"
                            color="blue"
                            hide-details
                            inset
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-divider class="my-2" />
                        </v-col>

                        <v-col>
                          <h5 class="text-body-2 font-weight-bold">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyDefaultLocale.Title') }}
                          </h5>
                          <div class="text-caption">
                            {{ $t('Settings.Domain.Edit.Field.ImportFromThirdPartyDefaultLocale.Subtitle') }}
                          </div>
                        </v-col>
                        <v-col class="shrink">
                          <v-select
                            v-model="domain.ImportFromThirdPartyDefaultLocale"
                            :disabled="!userCanManage"
                            :items="$i18n.languages"
                            :rules="domain.vuetifyFormFieldRules('ImportFromThirdPartyDefaultLocale')"
                            class="ma-0 d-inline-flex ml-2"
                            item-text="name"
                            item-value="locale"
                            required
                            single-line
                            solo
                            style="max-width: 130px"
                          />
                        </v-col>
                      </template>
                    </template>

                    <template v-if="!isAddNewMode">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ $t('Settings.Domain.Edit.Field.UploadDefaultAsset.Title') }}
                        </h5>
                        <div class="text-caption">
                          {{ $t('Settings.Domain.Edit.Field.UploadDefaultAsset.Subtitle') }}
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.ForceAssets"
                          :disabled="!userCanManage"
                          :label="domain.ForceAssets ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          :rules="domain.vuetifyFormFieldRules('ForceAssets')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ $t('Settings.Domain.Edit.Field.PurgeCDNCache.Title') }}
                        </h5>
                        <div class="text-caption">
                          {{ $t('Settings.Domain.Edit.Field.PurgeCDNCache.Subtitle') }}
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.PurgeCache"
                          :disabled="!userCanManage"
                          :label="domain.PurgeCache ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          :rules="domain.vuetifyFormFieldRules('PurgeCache')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="settings">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.DemoInstallation.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.DemoInstallation.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.IsDemo"
                        :disabled="!userCanManage"
                        :label="domain.IsDemo ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('IsDemo')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.ForceDeleteOrders.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.ForceDeleteOrders.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.HasForceDeleteOrders"
                        :disabled="!userCanManage"
                        :label="domain.HasForceDeleteOrders ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('HasForceDeleteOrders')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.LandingPage.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.LandingPage.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.HasLandingPage"
                        :disabled="!userCanManage"
                        :label="domain.HasLandingPage ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('HasLandingPage')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.HasLandingPage">
                      <v-col
                        class="mt-4"
                        cols="12"
                      />

                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ $t('Settings.Domain.Edit.Field.RedirectToLandingPage.Title') }}
                        </h5>
                        <div class="text-caption">
                          {{ $t('Settings.Domain.Edit.Field.RedirectToLandingPage.Subtitle') }}
                        </div>
                      </v-col>
                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.RedirectToLandingPage"
                          :disabled="!userCanManage"
                          :label="domain.RedirectToLandingPage ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          :rules="domain.vuetifyFormFieldRules('RedirectToLandingPage')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>

                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="domain.LandingPageUrl"
                          :label="$t('Settings.Domain.Edit.Field.LandingPage.Label')"
                          :rules="domain.vuetifyFormFieldRules('LandingPageUrl')"
                          class="mt-2"
                          hide-details
                          required
                        />
                      </v-col>

                      <v-col
                        class="mt-2"
                        cols="12"
                      />

                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          Domain Is Primary
                        </h5>
                        <div class="text-caption" />
                      </v-col>
                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.IsPrimary"
                          :disabled="!userCanManage"
                          :label="domain.IsPrimary ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>

                      <v-col
                        v-if="domain.IsPrimary"
                        cols="12"
                      >
                        <v-autocomplete
                          v-model="domain.PrimaryChildren"
                          :items="domainsList"
                          chips
                          class="mt-"
                          deletable-chips
                          hide-details
                          item-text="name"
                          item-value="db_name"
                          label="Primary Children Domains"
                          multiple
                          outlined
                          required
                          return-object
                          small-chips
                        >
                          <template #selection="data">
                            <v-chip
                              :class="data.item.is_primary ? 'pl-0' : ''"
                              :input-value="data.selected"
                              close
                              label
                              small
                              v-bind="data.attrs"
                              @click.stop="toggleIsPrimary(data)"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              <v-chip
                                v-if="data.item.is_primary"
                                class="pa-0 ml-0 white--text"
                                color="blue"
                                label
                                small
                              >
                                <v-icon>
                                  check
                                </v-icon>
                              </v-chip>

                              {{ data.item.name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.AllowFbIFrame.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.AllowFbIFrame.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.AllowIFrame"
                        :disabled="!userCanManage"
                        :label="domain.AllowIFrame ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('AllowIFrame')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.OrderWithoutAddress.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.OrderWithoutAddress.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.QrOnly"
                        :disabled="!userCanManage"
                        :label="domain.QrOnly ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('QrOnly')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.OrderType.Title') }}
                      </h5>
                    </v-col>
                    <v-col>
                      <div class="text-caption py-2">
                        {{ $t('Settings.Domain.Edit.Field.OrderType.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.ShowOrderTypes"
                        :disabled="!userCanManage"
                        :label="domain.ShowOrderTypes ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('ShowOrderTypes')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>
                    <v-col
                      v-if="domain.ShowOrderTypes"
                      cols="12"
                    >
                      <h5 class="mt-2">
                        {{ $t('Settings.Domain.Edit.Field.DefaultOrderType.Title.Site') }}
                      </h5>
                      <v-btn-toggle
                        v-model="domain.DefaultOrderType"
                        class="lighten-2 elevation-0"
                        mandatory
                        style="width: 100%;"
                      >
                        <v-btn
                          :style="domain.DefaultOrderType === 1 ? 'opacity: 1' : 'opacity: 0.35'"
                          :value="1"
                          class="purple white--text elevation-0 mr-0"
                          large
                          style="width: 50%; font-size: 13px !important;"
                        >
                          <span>{{
                            $t('Settings.Domain.Edit.Field.DefaultOrderType.Delivery.Title').toLocaleUpperCase($i18n.locale)
                          }}</span>
                          <v-icon
                            class="white--text"
                            right
                          >
                            {{ $t('Settings.Domain.Edit.Field.DefaultOrderType.Delivery.Icon') }}
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :style="domain.DefaultOrderType === 2 ? 'opacity: 1' : 'opacity: 0.35'"
                          :value="2"
                          class="blue darken-3 white--text elevation-0 ml-0"
                          large
                          style="width: 50%; font-size: 13px !important;"
                        >
                          <span>{{
                            $t('Settings.Domain.Edit.Field.DefaultOrderType.Collection.Title').toLocaleUpperCase($i18n.locale)
                          }}</span>
                          <v-icon
                            class="white--text"
                            right
                          >
                            {{ $t('Settings.Domain.Edit.Field.DefaultOrderType.Collection.Icon') }}
                          </v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>

                    <v-col cols="12">
                      <h5 class="mt-2">
                        {{ $t('Settings.Domain.Edit.Field.DefaultOrderType.Title.Pos') }}
                      </h5>
                      <v-btn-toggle
                        v-model="domain.DefaultOrderTypePos"
                        class="lighten-2 elevation-0"
                        mandatory
                        style="width: 100%;"
                      >
                        <v-btn
                          :style="domain.DefaultOrderTypePos === 1 ? 'opacity: 1' : 'opacity: 0.35'"
                          :value="1"
                          class="purple white--text elevation-0 mr-0"
                          large
                          style="width: 50%; font-size: 13px !important;"
                        >
                          <span>{{
                            $t('Settings.Domain.Edit.Field.DefaultOrderType.Delivery.Title').toLocaleUpperCase($i18n.locale)
                          }}</span>
                          <v-icon
                            class="white--text"
                            right
                          >
                            {{ $t('Settings.Domain.Edit.Field.DefaultOrderType.Delivery.Icon') }}
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :style="domain.DefaultOrderTypePos === 2 ? 'opacity: 1' : 'opacity: 0.35'"
                          :value="2"
                          class="blue darken-3 white--text elevation-0 ml-0"
                          large
                          style="width: 50%; font-size: 13px !important;"
                        >
                          <span>{{
                            $t('Settings.Domain.Edit.Field.DefaultOrderType.Collection.Title').toLocaleUpperCase($i18n.locale)
                          }}</span>
                          <v-icon
                            class="white--text"
                            right
                          >
                            {{ $t('Settings.Domain.Edit.Field.DefaultOrderType.Collection.Icon') }}
                          </v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="documents">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col class="mt-2">
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.HasReceipts.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.HasReceipts.Subtitle') }}
                      </div>
                    </v-col>
                    <v-col class="shrink mt-2">
                      <v-switch
                        v-model="domain.HasReceipts"
                        :disabled="!userCanManage"
                        :label="domain.HasReceipts ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :rules="domain.vuetifyFormFieldRules('HasReceipts')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.HasReceipts">
                      <v-col cols="12">
                        <v-divider class="my-4" />
                      </v-col>

                      <v-col cols="6">
                        <v-select
                          v-model="domain.ReceiptsProvider"
                          :items="fiscalDocumentProviders"
                          class="mt-2"
                          hide-details
                          item-text="Name"
                          item-value="Id"
                          label="Receipts Provider"
                          outlined
                          required
                          @change="onProviderChange"
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-select
                          v-model="domain.ReceiptsProviderConfig.env"
                          :items="[{Name: 'Live Environment', Id: 'live'}, {Name: 'Staging Environment', Id: 'staging'}]"
                          class="mt-2"
                          hide-details
                          item-text="Name"
                          item-value="Id"
                          label="Receipts Provider Environment"
                          outlined
                          required
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col cols="12">
                        <v-container
                          class="pa-1"
                          fluid
                        >
                          <v-row
                            dense
                            class="align-center"
                          >
                            <v-col cols="6">
                              <h5 class="text-body-1 font-weight-bold">
                                Live Environment Settings
                              </h5>
                            </v-col>

                            <v-col
                              class="text-right"
                              cols="6"
                            >
                              <v-btn
                                color="green"
                                icon
                                small
                                @click="onAddReceiptProviderConfigLive"
                              >
                                <v-icon>mdi mdi-plus-circle</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>

                          <template v-for="(item, index) in domain.ReceiptsProviderConfig.live">
                            <v-row
                              :key="`receipts-provider-config-live-${index}`"
                              class="align-center"
                              dense
                            >
                              <v-col>
                                <v-text-field
                                  v-model="item.key"
                                  :readonly="!!item.required"
                                  label="Key"
                                />
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-if="item.type === 'string'"
                                  v-model="item.value"
                                  label="Value"
                                />

                                <v-select
                                  v-else-if="item.type === 'list'"
                                  v-model="item.value"
                                  :items="item.list"
                                  :multiple="!!item.multiple"
                                  item-text="Name"
                                  item-value="Id"
                                  label="Value"
                                  required
                                />

                                <v-switch
                                  v-else-if="item.type === 'boolean'"
                                  v-model="item.value"
                                  :label="item.value ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="ma-0 d-inline-flex"
                                  color="green"
                                  hide-details
                                  inset
                                />
                              </v-col>

                              <v-col class="shrink">
                                <v-btn
                                  v-if="!item.required"
                                  class="mt-3"
                                  color="red"
                                  icon
                                  small
                                  @click="onRemoveReceiptProviderConfigLive(index)"
                                >
                                  <v-icon>mdi mdi-minus-circle</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </template>
                        </v-container>
                      </v-col>

                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col cols="12">
                        <v-container
                          class="pa-1"
                          fluid
                        >
                          <v-row
                            dense
                            class="align-center"
                          >
                            <v-col cols="6">
                              <h5 class="text-body-1 font-weight-bold">
                                Staging Environment Settings
                              </h5>
                            </v-col>

                            <v-col
                              class="text-right"
                              cols="6"
                            >
                              <v-btn
                                color="green"
                                icon
                                small
                                @click="onAddReceiptProviderConfigStaging"
                              >
                                <v-icon>mdi mdi-plus-circle</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>

                          <template v-for="(item, index) in domain.ReceiptsProviderConfig.staging">
                            <v-row
                              :key="`receipts-provider-config-staging-${index}`"
                              class="align-center"
                              dense
                            >
                              <v-col>
                                <v-text-field
                                  v-model="item.key"
                                  :readonly="!!item.required"
                                  label="Key"
                                />
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-if="item.type === 'string'"
                                  v-model="item.value"
                                  label="Value"
                                />

                                <v-select
                                  v-else-if="item.type === 'list'"
                                  v-model="item.value"
                                  :items="item.list"
                                  :multiple="!!item.multiple"
                                  item-text="Name"
                                  item-value="Id"
                                  label="Value"
                                  required
                                />

                                <v-switch
                                  v-else-if="item.type === 'boolean'"
                                  v-model="item.value"
                                  :label="item.value ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="ma-0 d-inline-flex"
                                  color="green"
                                  hide-details
                                  inset
                                />
                              </v-col>

                              <v-col class="shrink">
                                <v-btn
                                  v-if="!item.required"
                                  class="mt-3"
                                  color="red"
                                  icon
                                  small
                                  @click="onRemoveReceiptProviderConfigStaging(index)"
                                >
                                  <v-icon>mdi mdi-minus-circle</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </template>
                        </v-container>
                      </v-col>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="customer">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col cols="12">
                      <v-switch
                        v-model="domain.CreateLead"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Domain.Edit.Field.CreateLead.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.CreateLead.Label')"
                        :rules="domain.vuetifyFormFieldRules('CreateLead')"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.CreateLead">
                      <v-col cols="12">
                        <v-text-field
                          v-model="domain.Company"
                          :disabled="!userCanManage"
                          :hint="$t('Settings.Domain.Edit.Field.Company.Hint')"
                          :label="$t('Settings.Domain.Edit.Field.Company.Label')"
                          :prepend-icon="$t('Settings.Domain.Edit.Field.Company.Icon')"
                          :rules="domain.vuetifyFormFieldRules('Company')"
                          clearable
                          required
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Firstname"
                          :disabled="!userCanManage"
                          :hint="$t('Settings.Domain.Edit.Field.Firstname.Hint')"
                          :label="$t('Settings.Domain.Edit.Field.Firstname.Label')"
                          :prepend-icon="$t('Settings.Domain.Edit.Field.Firstname.Icon')"
                          :rules="domain.vuetifyFormFieldRules('Firstname')"
                          clearable
                          required
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Lastname"
                          :disabled="!userCanManage"
                          :hint="$t('Settings.Domain.Edit.Field.Lastname.Hint')"
                          :label="$t('Settings.Domain.Edit.Field.Lastname.Label')"
                          :prepend-icon="$t('Settings.Domain.Edit.Field.Lastname.Icon')"
                          :rules="domain.vuetifyFormFieldRules('Lastname')"
                          clearable
                          required
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Email"
                          :disabled="!userCanManage"
                          :hint="$t('Settings.Domain.Edit.Field.Email.Hint')"
                          :label="$t('Settings.Domain.Edit.Field.Email.Label')"
                          :prepend-icon="$t('Settings.Domain.Edit.Field.Email.Icon')"
                          :rules="domain.vuetifyFormFieldRules('Email')"
                          clearable
                          required
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Telephone"
                          :disabled="!userCanManage"
                          :hint="$t('Settings.Domain.Edit.Field.Telephone.Hint')"
                          :label="$t('Settings.Domain.Edit.Field.Telephone.Label')"
                          :prepend-icon="$t('Settings.Domain.Edit.Field.Telephone.Icon')"
                          :rules="domain.vuetifyFormFieldRules('Telephone')"
                          clearable
                          required
                        />
                      </v-col>
                    </template>

                    <template v-else>
                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.CustomerId"
                          :disabled="!userCanManage"
                          :hint="$t('Settings.Domain.Edit.Field.CustomerId.Hint')"
                          :label="$t('Settings.Domain.Edit.Field.CustomerId.Label')"
                          :prepend-icon="$t('Settings.Domain.Edit.Field.CustomerId.Icon')"
                          :rules="domain.vuetifyFormFieldRules('CustomerId')"
                          clearable
                          required
                        >
                          <template #append-outer>
                            <v-btn
                              :disabled="!domain.CustomerId"
                              :href="`https://crm.getfoodpro.gr/admin/clients/client/${domain.CustomerId}`"
                              icon
                              small
                              target="_blank"
                            >
                              <v-icon>mdi-open-in-new</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.LeadId"
                          :disabled="!userCanManage"
                          :hint="$t('Settings.Domain.Edit.Field.LeadId.Hint')"
                          :label="$t('Settings.Domain.Edit.Field.LeadId.Label')"
                          :prepend-icon="$t('Settings.Domain.Edit.Field.LeadId.Icon')"
                          :rules="domain.vuetifyFormFieldRules('LeadId')"
                          clearable
                          required
                        >
                          <template #append-outer>
                            <v-btn
                              :disabled="!domain.LeadId"
                              :href="`https://crm.getfoodpro.gr/admin/leads/index/${domain.LeadId}`"
                              icon
                              small
                              target="_blank"
                            >
                              <v-icon>mdi-open-in-new</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </template>

                    <v-col cols="12">
                      <v-autocomplete
                        v-model="domain.ResellerId"
                        :hint="$t('Settings.Domain.Edit.Field.ResellerId.Hint')"
                        :items="resellersList"
                        :label="$t('Settings.Domain.Edit.Field.ResellerId.Label')"
                        :rules="domain.vuetifyFormFieldRules('ResellerId')"
                        cache-items
                        class=""
                        item-text="FullName"
                        item-value="Id"
                        prepend-icon="mdi-face-agent"
                        required
                      >
                        <template #selection="{item}">
                          <div>
                            <span class="mr-1">{{ item.FullName }}</span>
                            -
                            <span class="ml-1 grey--text">{{ item.CompanyName }} / {{ item.Area }}</span>
                          </div>
                        </template>

                        <template #item="{item}">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.FullName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.CompanyName }} / {{ item.Area }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>

                        <template #append-outer>
                          <v-btn
                            :disabled="!domain.ResellerId"
                            :href="`https://crm.getfoodpro.gr/admin/leads/index/${domain.ResellerId}`"
                            icon
                            small
                            target="_blank"
                          >
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="domain.TrialExpiredAt"
                        v-mask="'##-##-####'"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Domain.Edit.Field.TrialExpiredAt.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.TrialExpiredAt.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.TrialExpiredAt.Icon')"
                        :rules="domain.vuetifyFormFieldRules('TrialExpiredAt')"
                        clearable
                        required
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="domain.SubscriptionExpiredAt"
                        v-mask="'##-##-####'"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Domain.Edit.Field.SubscriptionExpiredAt.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.SubscriptionExpiredAt.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.SubscriptionExpiredAt.Icon')"
                        :rules="domain.vuetifyFormFieldRules('SubscriptionExpiredAt')"
                        clearable
                        required
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="domain.SeasonStartedAt"
                        v-mask="'##-##'"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Domain.Edit.Field.SeasonStartedAt.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.SeasonStartedAt.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.SeasonStartedAt.Icon')"
                        :rules="domain.vuetifyFormFieldRules('SeasonStartedAt')"
                        clearable
                        required
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="domain.SeasonEndedAt"
                        v-mask="'##-##'"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Domain.Edit.Field.SeasonEndedAt.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.SeasonEndedAt.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.SeasonEndedAt.Icon')"
                        :rules="domain.vuetifyFormFieldRules('SeasonEndedAt')"
                        clearable
                        required
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="domain.ExtendedPeriodAt"
                        v-mask="'##-##-####'"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Domain.Edit.Field.ExtendedPeriodAt.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.ExtendedPeriodAt.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.ExtendedPeriodAt.Icon')"
                        :rules="domain.vuetifyFormFieldRules('ExtendedPeriodAt')"
                        :suffix="`+${appConfig?.LOCATION_DATA?.ExtendedPeriodHours} ${$tc('Order.Type.Time.Hour.Title', appConfig?.LOCATION_DATA?.ExtendedPeriodHours || 0)}`"
                        clearable
                        required
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-switch
                        v-model="domain.Locked"
                        inset
                        :disabled="!userCanManage"
                        :label="domain.Locked ? $t('Common.Button.Toggle.Locked') : $t('Common.Button.Toggle.Unlocked')"
                        :rules="domain.validator.vuetifyFormFieldRules('Locked')"
                        :color="domain.Locked ? 'red' : 'green'"
                        class="ma-0 d-inline-flex ml-2"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                        v-model="domain.CountryId"
                        :hint="$t('Settings.Domain.Edit.Field.Country.Hint')"
                        :items="countryList"
                        :label="$t('Settings.Domain.Edit.Field.Country.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.Country.Icon')"
                        :rules="domain.vuetifyFormFieldRules('CountryId')"
                        cache-items
                        class="pr-2"
                        item-text="name"
                        item-value="id"
                        required
                      >
                        <template #prepend>
                          <v-avatar
                            size="22"
                            tile
                          >
                            <template v-if="locale">
                              <v-img :src="`/assets/flags/1x1/${String(locale).toLowerCase()}.svg`" />
                            </template>
                            <template v-else>
                              <v-icon>map</v-icon>
                            </template>
                          </v-avatar>
                        </template>

                        <template #item="data">
                          <v-list-item-avatar size="34">
                            <v-img :src="`/assets/flags/1x1/${String(data.item.code).toLowerCase()}.svg`" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.name" />
                            <v-list-item-subtitle
                              class="text-caption"
                              v-html="data.item.desc"
                            />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="permissions">
                <template v-if="editObj">
                  <v-select
                    v-model="editObj.ActivePackage"
                    :items="editObj.Packages"
                    :label="$t('Settings.Domain.Edit.Field.SelectPackage')"
                    filled
                    flat
                    full-width
                    hide-details
                    item-text="Name"
                    item-value="Id"
                    prepend-inner-icon="extension"
                    single-line
                    @change="onPackageSelection"
                  />

                  <v-data-table
                    :headers="dataTableHeaders"
                    :height="dataTable.resize.bodyHeight"
                    :items="dataTable.data"
                    :options.sync="dataTable.options"
                    fixed-header
                    hide-default-footer
                  >
                    <template #[`item.permission`]="{item}">
                      <span :class="{'caption': $vuetify.breakpoint.xsOnly}">{{ item.Permission }}</span>
                    </template>

                    <template #[`item.group_permissions`]="{item}">
                      <v-checkbox
                        v-if="item.Action.findIndex(p => (p === 'access')) > -1"
                        v-model="item.GroupPermissions"
                        :color="getPermissionColor(item, 'access')"
                        hide-details
                        primary
                        style="display: inline-block"
                        value="access"
                        @change="onCheckboxChange('access', item)"
                      />
                    </template>
                  </v-data-table>
                </template>
              </v-tab-item>

              <v-tab-item key="mobile">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col cols="12">
                      <h5 class="text-body-1 font-weight-bold">
                        Mobile Application
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Active
                      </h5>
                      <div class="text-caption">
                        Mobile App Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Mobile.Active"
                        :disabled="!userCanManage"
                        :label="domain.Mobile.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      class="my-2"
                      cols="12"
                    />

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        Android
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Active
                      </h5>
                      <div class="text-caption">
                        Android Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Mobile.Android.Active"
                        :disabled="!userCanManage"
                        :label="domain.Mobile.Android.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="domain.Mobile.Android.StoreUrl"
                        :disabled="!userCanManage"
                        hide-details
                        label="Android Store Url"
                      />
                    </v-col>

                    <v-col
                      class="my-2"
                      cols="12"
                    />

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        iOS
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Active
                      </h5>
                      <div class="text-caption">
                        iOS Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Mobile.Ios.Active"
                        :disabled="!userCanManage"
                        :label="domain.Mobile.Ios.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="domain.Mobile.Ios.StoreUrl"
                        :disabled="!userCanManage"
                        hide-details
                        label="iOS Store Url"
                      />
                    </v-col>

                    <v-col cols="12" />
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="sms">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col class="shrink">
                      <h5 class="text-body-1 text-no-wrap font-weight-bold">
                        SMS Credits
                      </h5>
                    </v-col>

                    <v-col class="text-end">
                      <v-text-field
                        v-model.number="domain.SmsCredits"
                        :disabled="!userCanManage"
                        hide-details
                        clearable
                        outlined
                        single-line
                        dense
                        type="number"
                        step="1"
                        min="0"
                        class="d-inline-flex"
                        label="Sms Credits"
                        style="max-width: 150px"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="text-body-1 font-weight-bold">
                        SMS Providers
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col cols="12">
                      <v-card
                        flat
                        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
                      >
                        <v-card-title>
                          <h5 class="text-body-1 font-weight-bold">
                            Routee
                          </h5>

                          <v-spacer />

                          <v-switch
                            v-model="domain.SmsProvider.routee.active"
                            :disabled="!userCanManage"
                            :label="domain.SmsProvider.routee.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                            class="ma-0 d-inline-flex ml-2"
                            color="blue"
                            hide-details
                            inset
                          />
                        </v-card-title>

                        <v-divider />

                        <v-card-text>
                          <v-container
                            class="pa-0"
                            fluid
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="domain.SmsProvider.routee.from"
                                  :disabled="!userCanManage"
                                  :rules="[v => v && v.length <= 11 || 'Το αναγνωριστικό αποστολέα μπορεί να είναι μια συμβολοσειρά με μέγιστο μήκος 11 χαρακτήρων']"
                                  hint="Το αναγνωριστικό αποστολέα μπορεί να είναι μια συμβολοσειρά με μέγιστο μήκος 11 χαρακτήρων που περιέχει λατινικά γράμματα, αριθμούς, λευκούς χώρους ή / και οποιονδήποτε από τους παρακάτω χαρακτήρες: !, &quot;, #, $, %, &, ‘, (, ), *, +, &quot;,&quot;, -, ., /, :, ;, <, =, >, ?, @, [, , ], ^, _, `, {, |, }, ~"
                                  counter
                                  persistent-hint
                                  clearable
                                  max="11"
                                  label="From Name"
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-text-field
                                  v-model="domain.SmsProvider.routee.endpoint"
                                  :disabled="!userCanManage"
                                  hide-details
                                  clearable
                                  label="Endpoint URL"
                                />
                              </v-col>

                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-text-field
                                  v-model="domain.SmsProvider.routee.credentials.username"
                                  :disabled="!userCanManage"
                                  hide-details
                                  clearable
                                  label="Username"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-text-field
                                  v-model="domain.SmsProvider.routee.credentials.password"
                                  :disabled="!userCanManage"
                                  hide-details
                                  :type="smsProviderRouteePasswordVisible ? 'text' : 'password'"
                                  label="Password"
                                  :append-icon="smsProviderRouteePasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="() => smsProviderRouteePasswordVisible = !smsProviderRouteePasswordVisible"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="marketing">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col cols="12">
                      <h5 class="text-body-1 font-weight-bold">
                        Marketing Automation
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        OneSignal
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Active
                      </h5>
                      <div class="text-caption">
                        OneSignal Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Marketing.OneSignal.Active"
                        :disabled="!userCanManage"
                        :label="domain.Marketing.OneSignal.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />

                    <v-col>
                      <v-text-field
                        v-model="domain.Marketing.OneSignal.AppId"
                        :disabled="!userCanManage"
                        hide-details
                        label="App Id"
                      />
                    </v-col>
                    <v-col class="shrink">
                      <v-text-field
                        v-model="domain.Marketing.OneSignal.ClientTimeout"
                        :disabled="!userCanManage"
                        hide-details
                        suffix="Sec"
                        type="number"
                        step="1"
                        style="min-width: 120px;"
                        class="ml-4"
                        label="Client Timeout"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="domain.Marketing.OneSignal.RestApiKey"
                        :disabled="!userCanManage"
                        hide-details
                        label="Rest Api Key"
                      />
                    </v-col>

                    <v-col
                      class="my-2"
                      cols="12"
                    />

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        Push
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Active
                      </h5>
                      <div class="text-caption">
                        Push Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Marketing.OneSignal.Push.Active"
                        :disabled="!userCanManage"
                        :label="domain.Marketing.OneSignal.Push.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Web Active
                      </h5>
                      <div class="text-caption">
                        Push Web Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Marketing.OneSignal.Push.Web.Active"
                        :disabled="!userCanManage"
                        :label="domain.Marketing.OneSignal.Push.Web.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Mobile Active
                      </h5>
                      <div class="text-caption">
                        Push Mobile Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Marketing.OneSignal.Push.Mobile.Active"
                        :disabled="!userCanManage"
                        :label="domain.Marketing.OneSignal.Push.Mobile.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      class="my-2"
                      cols="12"
                    />

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        Email
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Active
                      </h5>
                      <div class="text-caption">
                        Email Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Marketing.OneSignal.Email.Active"
                        :disabled="!userCanManage"
                        :label="domain.Marketing.OneSignal.Email.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      class="my-2"
                      cols="12"
                    />

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        Sms
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Active
                      </h5>
                      <div class="text-caption">
                        Sms Active
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Marketing.OneSignal.Sms.Active"
                        :disabled="!userCanManage"
                        :label="domain.Marketing.OneSignal.Sms.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="platforms">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    v-if="domain.ShopId"
                    class="align-center"
                    dense
                  >
                    <v-col>
                      <h5 class="text-body-1 font-weight-bold">
                        Shop ID
                      </h5>

                      <div class="font-weight-bold">
                        <v-chip
                          label
                          small
                        >
                          {{ domain.ShopId }}
                        </v-chip>
                      </div>
                    </v-col>

                    <v-col class="shrink">
                      <v-btn
                        class="pa-2 mt-2"
                        depressed
                        style="min-width: 0;"
                        @click="copyToClipboard(domain.ShopId)"
                      >
                        <v-icon color="primary">
                          far fa-copy
                        </v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col>
                      <h5 class="text-body-1 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Efood.Active.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Efood.Active.SubTitle') }}
                      </div>
                    </v-col>

                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Platforms.efood.active"
                        :disabled="!userCanManage"
                        :label="domain.Platforms.efood.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.Platforms.efood.active">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col
                        class="text-end"
                        cols="12"
                      >
                        <v-btn
                          color="primary"
                          depressed
                          @click="addShop('efood')"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          ADD SHOP
                        </v-btn>
                      </v-col>

                      <template v-for="(shop, shopIdx) in domainPlatformsEfoodShops">
                        <v-col
                          :key="`efood-shop-${shopIdx}`"
                          cols="12"
                        >
                          <v-card
                            :color="isDarkMode ? 'grey darken-3' : 'grey lighten-4'"
                            flat
                          >
                            <v-card-title>
                              E-Food Shop #{{ (parseInt(shopIdx) + 1) }}
                              <v-spacer />

                              <v-switch
                                v-model="shop.active"
                                :disabled="!userCanManage"
                                :label="shop.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="ma-0 d-inline-flex ml-2"
                                color="blue"
                                hide-details
                                inset
                              />
                            </v-card-title>

                            <v-divider />

                            <v-card-text>
                              <v-container
                                class="pa-1"
                                fluid
                              >
                                <v-row
                                  dense
                                  class="align-center"
                                >
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.name"
                                      label="Name"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.shopId"
                                      label="Shop Id"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <h5 class="text-body-2 font-weight-bold pb-1">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Update.Title') }}
                                    </h5>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-simple-table>
                                      <template #default>
                                        <thead>
                                          <tr>
                                            <th class="text-left">
                                              {{ $t('Common.Date.Day') }}
                                            </th>

                                            <th class="text-left">
                                              {{ $t('Common.Date.Time') }}
                                            </th>

                                            <th class="text-left">
                                              <v-btn
                                                icon
                                                depressed
                                                x-small
                                                class="primary white--text"
                                                @click="addPlatformUpdateWeekTime(shop)"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <template v-for="(day, dayIdx) in Weekdays">
                                            <tr :key="`day-${dayIdx}`">
                                              <td class="font-weight-bold">
                                                {{ day }}
                                              </td>

                                              <td style="width: 100%;">
                                                <template v-for="(time, timeIdx) in shop.update[dayIdx + 1]">
                                                  <v-chip
                                                    :key="`day-${dayIdx}-time-${timeIdx}`"
                                                    class="mr-1 mb-1"
                                                    color="primary"
                                                    small
                                                    close
                                                    label
                                                    @click.stop="editPlatformUpdateTime(shop.update[dayIdx + 1], timeIdx)"
                                                    @click:close="removePlatformUpdateDayTime(shop.update[dayIdx + 1], timeIdx)"
                                                  >
                                                    {{ time }}
                                                  </v-chip>
                                                </template>
                                              </td>

                                              <td
                                                class="text-right"
                                                style="width: 40px;"
                                              >
                                                <v-btn
                                                  icon
                                                  depressed
                                                  x-small
                                                  class="primary white--text"
                                                  @click="addPlatformUpdateDayTime(shop.update[dayIdx + 1])"
                                                >
                                                  <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                              </td>
                                            </tr>
                                          </template>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>

                                <v-row
                                  dense
                                  class="justify-center align-center"
                                >
                                  <v-col>
                                    <h5 class="text-body-2 font-weight-bold mt-3">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Efood.Production.Title') }}
                                    </h5>
                                    <div class="text-caption">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Efood.Production.SubTitle') }}
                                    </div>
                                  </v-col>
                                  <v-col class="shrink">
                                    <v-switch
                                      v-model="shop.production"
                                      :disabled="!userCanManage"
                                      :label="shop.production ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      class="ma-0 d-inline-flex ml-2"
                                      color="blue"
                                      hide-details
                                      inset
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="red"
                                dark
                                depressed
                                small
                                @click="removeShop('efood', shopIdx)"
                              >
                                <v-icon left>
                                  delete
                                </v-icon>
                                DELETE SHOP
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </template>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col>
                      <h5 class="text-body-1 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Foody.Active.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Foody.Active.SubTitle') }}
                      </div>
                    </v-col>

                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Platforms.foody.active"
                        :disabled="!userCanManage"
                        :label="domain.Platforms.foody.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.Platforms.foody.active">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col
                        class="text-end"
                        cols="12"
                      >
                        <v-btn
                          color="primary"
                          depressed
                          @click="addShop('foody')"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          ADD SHOP
                        </v-btn>
                      </v-col>

                      <template v-for="(shop, shopIdx) in domainPlatformsFoodyShops">
                        <v-col
                          :key="`foody-shop-${shopIdx}`"
                          cols="12"
                        >
                          <v-card
                            :color="isDarkMode ? 'grey darken-3' : 'grey lighten-4'"
                            flat
                          >
                            <v-card-title>
                              Foody Shop #{{ (parseInt(shopIdx) + 1) }}
                              <v-spacer />

                              <v-switch
                                v-model="shop.active"
                                :disabled="!userCanManage"
                                :label="shop.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="ma-0 d-inline-flex ml-2"
                                color="blue"
                                hide-details
                                inset
                              />
                            </v-card-title>

                            <v-divider />

                            <v-card-text>
                              <v-container
                                class="pa-1"
                                fluid
                              >
                                <v-row
                                  dense
                                  class="align-center"
                                >
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.name"
                                      label="Name"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.shopId"
                                      label="Shop Id"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <h5 class="text-body-2 font-weight-bold pb-1">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Update.Title') }}
                                    </h5>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-simple-table>
                                      <template #default>
                                        <thead>
                                          <tr>
                                            <th class="text-left">
                                              {{ $t('Common.Date.Day') }}
                                            </th>

                                            <th class="text-left">
                                              {{ $t('Common.Date.Time') }}
                                            </th>

                                            <th class="text-left">
                                              <v-btn
                                                icon
                                                depressed
                                                x-small
                                                class="primary white--text"
                                                @click="addPlatformUpdateWeekTime(shop)"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <template v-for="(day, dayIdx) in Weekdays">
                                            <tr :key="`day-${dayIdx}`">
                                              <td class="font-weight-bold">
                                                {{ day }}
                                              </td>

                                              <td style="width: 100%;">
                                                <template v-for="(time, timeIdx) in shop.update[dayIdx + 1]">
                                                  <v-chip
                                                    :key="`day-${dayIdx}-time-${timeIdx}`"
                                                    class="mr-1 mb-1"
                                                    color="primary"
                                                    small
                                                    close
                                                    label
                                                    @click.stop="editPlatformUpdateTime(shop.update[dayIdx + 1], timeIdx)"
                                                    @click:close="removePlatformUpdateDayTime(shop.update[dayIdx + 1], timeIdx)"
                                                  >
                                                    {{ time }}
                                                  </v-chip>
                                                </template>
                                              </td>

                                              <td
                                                class="text-right"
                                                style="width: 40px;"
                                              >
                                                <v-btn
                                                  icon
                                                  depressed
                                                  x-small
                                                  class="primary white--text"
                                                  @click="addPlatformUpdateDayTime(shop.update[dayIdx + 1])"
                                                >
                                                  <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                              </td>
                                            </tr>
                                          </template>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>

                                <v-row
                                  dense
                                  class="justify-center align-center"
                                >
                                  <v-col>
                                    <h5 class="text-body-2 font-weight-bold mt-3">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Foody.Production.Title') }}
                                    </h5>
                                    <div class="text-caption">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Foody.Production.SubTitle') }}
                                    </div>
                                  </v-col>
                                  <v-col class="shrink">
                                    <v-switch
                                      v-model="shop.production"
                                      :disabled="!userCanManage"
                                      :label="shop.production ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      class="ma-0 d-inline-flex ml-2"
                                      color="blue"
                                      hide-details
                                      inset
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="red"
                                dark
                                depressed
                                small
                                @click="removeShop('foody', shopIdx)"
                              >
                                <v-icon left>
                                  delete
                                </v-icon>
                                DELETE SHOP
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </template>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col>
                      <h5 class="text-body-1 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Fagi.Active.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Fagi.Active.SubTitle') }}
                      </div>
                    </v-col>

                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Platforms.fagi.active"
                        :disabled="!userCanManage"
                        :label="domain.Platforms.fagi.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.Platforms.fagi.active">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col
                        class="text-end"
                        cols="12"
                      >
                        <v-btn
                          color="primary"
                          depressed
                          @click="addShop('fagi')"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          ADD SHOP
                        </v-btn>
                      </v-col>

                      <template v-for="(shop, shopIdx) in domainPlatformsFagiShops">
                        <v-col
                          :key="`fagi-shop-${shopIdx}`"
                          cols="12"
                        >
                          <v-card
                            :color="isDarkMode ? 'grey darken-3' : 'grey lighten-4'"
                            flat
                          >
                            <v-card-title>
                              Fagi Shop #{{ (parseInt(shopIdx) + 1) }}
                              <v-spacer />

                              <v-switch
                                v-model="shop.active"
                                :disabled="!userCanManage"
                                :label="shop.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="ma-0 d-inline-flex ml-2"
                                color="blue"
                                hide-details
                                inset
                              />
                            </v-card-title>

                            <v-divider />

                            <v-card-text>
                              <v-container
                                class="pa-1"
                                fluid
                              >
                                <v-row
                                  dense
                                  class="align-center"
                                >
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.name"
                                      label="Name"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.shopId"
                                      label="Shop Id"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <h5 class="text-body-2 font-weight-bold pb-1">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Update.Title') }}
                                    </h5>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-simple-table>
                                      <template #default>
                                        <thead>
                                          <tr>
                                            <th class="text-left">
                                              {{ $t('Common.Date.Day') }}
                                            </th>

                                            <th class="text-left">
                                              {{ $t('Common.Date.Time') }}
                                            </th>

                                            <th class="text-left">
                                              <v-btn
                                                icon
                                                depressed
                                                x-small
                                                class="primary white--text"
                                                @click="addPlatformUpdateWeekTime(shop)"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <template v-for="(day, dayIdx) in Weekdays">
                                            <tr :key="`day-${dayIdx}`">
                                              <td class="font-weight-bold">
                                                {{ day }}
                                              </td>

                                              <td style="width: 100%;">
                                                <template v-for="(time, timeIdx) in shop.update[dayIdx + 1]">
                                                  <v-chip
                                                    :key="`day-${dayIdx}-time-${timeIdx}`"
                                                    class="mr-1 mb-1"
                                                    color="primary"
                                                    small
                                                    close
                                                    label
                                                    @click.stop="editPlatformUpdateTime(shop.update[dayIdx + 1], timeIdx)"
                                                    @click:close="removePlatformUpdateDayTime(shop.update[dayIdx + 1], timeIdx)"
                                                  >
                                                    {{ time }}
                                                  </v-chip>
                                                </template>
                                              </td>

                                              <td
                                                class="text-right"
                                                style="width: 40px;"
                                              >
                                                <v-btn
                                                  icon
                                                  depressed
                                                  x-small
                                                  class="primary white--text"
                                                  @click="addPlatformUpdateDayTime(shop.update[dayIdx + 1])"
                                                >
                                                  <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                              </td>
                                            </tr>
                                          </template>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>

                                <v-row
                                  dense
                                  class="justify-center align-center"
                                >
                                  <v-col>
                                    <h5 class="text-body-2 font-weight-bold mt-3">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Fagi.Production.Title') }}
                                    </h5>
                                    <div class="text-caption">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Fagi.Production.SubTitle') }}
                                    </div>
                                  </v-col>
                                  <v-col class="shrink">
                                    <v-switch
                                      v-model="shop.production"
                                      :disabled="!userCanManage"
                                      :label="shop.production ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      class="ma-0 d-inline-flex ml-2"
                                      color="blue"
                                      hide-details
                                      inset
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="red"
                                dark
                                depressed
                                small
                                @click="removeShop('fagi', shopIdx)"
                              >
                                <v-icon left>
                                  delete
                                </v-icon>
                                DELETE SHOP
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </template>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col>
                      <h5 class="text-body-1 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Wolt.Active.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.Platform.Wolt.Active.SubTitle') }}
                      </div>
                    </v-col>

                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Platforms.wolt.active"
                        :disabled="!userCanManage"
                        :label="domain.Platforms.wolt.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.Platforms.wolt.active">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ $t('Settings.Domain.Edit.Field.Platform.Wolt.Production.Title') }}
                        </h5>
                        <div class="text-caption">
                          {{ $t('Settings.Domain.Edit.Field.Platform.Wolt.Production.SubTitle') }}
                        </div>
                      </v-col>

                      <v-col class="shrink">
                        <v-switch
                          v-model="domain.Platforms.wolt.production"
                          :disabled="!userCanManage"
                          :label="domain.Platforms.wolt.production ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                          class="ma-0 d-inline-flex ml-2"
                          color="blue"
                          hide-details
                          inset
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Platforms.wolt.venueId"
                          label="Venue Id"
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Platforms.wolt.packageApi"
                          label="Package Api"
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Platforms.wolt.username"
                          label="Username"
                        />
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="domain.Platforms.wolt.password"
                          label="Password"
                        />
                      </v-col>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col>
                      <h5 class="text-body-1 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.Platform.GetFood.Active.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.Platform.GetFood.Active.SubTitle') }}
                      </div>
                    </v-col>

                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Platforms.getfood.active"
                        :disabled="!userCanManage"
                        :label="domain.Platforms.getfood.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domain.Platforms.getfood.active">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col
                        class="text-end"
                        cols="12"
                      >
                        <v-btn
                          color="primary"
                          depressed
                          @click="addShop('getfood')"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          ADD SHOP
                        </v-btn>
                      </v-col>

                      <template v-for="(shop, shopIdx) in domainPlatformsGetfoodShops">
                        <v-col
                          :key="`getfood-shop-${shopIdx}`"
                          cols="12"
                        >
                          <v-card
                            :color="isDarkMode ? 'grey darken-3' : 'grey lighten-4'"
                            flat
                          >
                            <v-card-title>
                              GetFood Shop #{{ (parseInt(shopIdx) + 1) }}
                              <v-spacer />

                              <v-switch
                                v-model="shop.active"
                                :disabled="!userCanManage"
                                :label="shop.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="ma-0 d-inline-flex ml-2"
                                color="blue"
                                hide-details
                                inset
                              />
                            </v-card-title>

                            <v-divider />

                            <v-card-text>
                              <v-container
                                class="pa-1"
                                fluid
                              >
                                <v-row
                                  dense
                                  class="align-center"
                                >
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.name"
                                      label="Name"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.shopId"
                                      label="Shop Id"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="shop.mainId"
                                      label="Main Id"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <h5 class="text-body-2 font-weight-bold pb-1">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.Update.Title') }}
                                    </h5>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-simple-table>
                                      <template #default>
                                        <thead>
                                          <tr>
                                            <th class="text-left">
                                              {{ $t('Common.Date.Day') }}
                                            </th>

                                            <th class="text-left">
                                              {{ $t('Common.Date.Time') }}
                                            </th>

                                            <th class="text-left">
                                              <v-btn
                                                icon
                                                depressed
                                                x-small
                                                class="primary white--text"
                                                @click="addPlatformUpdateWeekTime(shop)"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <template v-for="(day, dayIdx) in Weekdays">
                                            <tr :key="`day-${dayIdx}`">
                                              <td class="font-weight-bold">
                                                {{ day }}
                                              </td>

                                              <td style="width: 100%;">
                                                <template v-for="(time, timeIdx) in shop.update[dayIdx + 1]">
                                                  <v-chip
                                                    :key="`day-${dayIdx}-time-${timeIdx}`"
                                                    class="mr-1 mb-1"
                                                    color="primary"
                                                    small
                                                    close
                                                    label
                                                    @click.stop="editPlatformUpdateTime(shop.update[dayIdx + 1], timeIdx)"
                                                    @click:close="removePlatformUpdateDayTime(shop.update[dayIdx + 1], timeIdx)"
                                                  >
                                                    {{ time }}
                                                  </v-chip>
                                                </template>
                                              </td>

                                              <td
                                                class="text-right"
                                                style="width: 40px;"
                                              >
                                                <v-btn
                                                  icon
                                                  depressed
                                                  x-small
                                                  class="primary white--text"
                                                  @click="addPlatformUpdateDayTime(shop.update[dayIdx + 1])"
                                                >
                                                  <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                              </td>
                                            </tr>
                                          </template>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>

                                <v-row
                                  dense
                                  class="justify-center align-center"
                                >
                                  <v-col>
                                    <h5 class="text-body-2 font-weight-bold mt-3">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.GetFood.Production.Title') }}
                                    </h5>
                                    <div class="text-caption">
                                      {{ $t('Settings.Domain.Edit.Field.Platform.GetFood.Production.SubTitle') }}
                                    </div>
                                  </v-col>
                                  <v-col class="shrink">
                                    <v-switch
                                      v-model="shop.production"
                                      :disabled="!userCanManage"
                                      :label="shop.production ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      class="ma-0 d-inline-flex ml-2"
                                      color="blue"
                                      hide-details
                                      inset
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="red"
                                dark
                                depressed
                                small
                                @click="removeShop('getfood', shopIdx)"
                              >
                                <v-icon left>
                                  delete
                                </v-icon>
                                DELETE SHOP
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </template>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="webhooks">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col>
                      <h5 class="text-body-1 font-weight-bold">
                        {{ $t('Settings.Domain.Edit.Field.WebHooks.Order.Title') }}
                      </h5>
                      <div class="text-caption">
                        {{ $t('Settings.Domain.Edit.Field.WebHooks.Order.Subtitle') }}
                      </div>
                    </v-col>

                    <v-col class="shrink">
                      <v-switch
                        v-model="domainWebHooks.order.active"
                        :disabled="!userCanManage"
                        :label="domainWebHooks.order.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="domainWebHooks.order.active">
                      <v-col cols="12">
                        <v-divider class="my-2" />
                      </v-col>

                      <v-col
                        class="text-end"
                        cols="12"
                      >
                        <v-btn
                          color="primary"
                          depressed
                          @click="addWebhook('order')"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          {{ $t('Settings.Domain.Edit.Field.WebHooks.Button.Add') }}
                        </v-btn>
                      </v-col>

                      <template v-for="(webhook, webhookIdx) in domainWebHooks.order.hooks">
                        <v-col
                          :key="`order-webhook-${webhookIdx}`"
                          cols="12"
                        >
                          <v-card
                            :color="isDarkMode ? 'grey darken-3' : 'grey lighten-4'"
                            flat
                          >
                            <v-card-title>
                              Webhook #{{ (parseInt(webhookIdx) + 1) }}
                              <v-spacer />

                              <v-switch
                                v-model="webhook.active"
                                :disabled="!userCanManage"
                                :label="webhook.active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="ma-0 d-inline-flex ml-2"
                                color="blue"
                                hide-details
                                inset
                              />
                            </v-card-title>

                            <v-divider />

                            <v-card-text>
                              <v-container
                                class="pa-1"
                                fluid
                              >
                                <v-row
                                  dense
                                  class="align-center"
                                >
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="webhook.name"
                                      :label="$t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Name.Label')"
                                      :hint="$t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Name.Hint')"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="webhook.url"
                                      :label="$t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Url.Label')"
                                      :hint="$t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Url.Hint')"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-select
                                      v-model="webhook.status"
                                      :hint="$t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Status.Hint')"
                                      :items="webhookOrderStatuses"
                                      :label="$t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Status.Label')"
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      persistent-hint
                                    >
                                      <template #selection="{item}">
                                        <v-chip
                                          :color="`${item.Avatar.Color}`"
                                          class="px-1"
                                          dark
                                          label
                                          small
                                        >
                                          <v-icon
                                            class="mr-1"
                                            small
                                          >
                                            {{ item.Avatar.Icon }}
                                          </v-icon>
                                          <span>{{ item.Name }}</span>
                                        </v-chip>
                                      </template>

                                      <template #item="data">
                                        <v-list-item-avatar
                                          :color="`${data.item.Avatar.Color}`"
                                          :size="28"
                                          dark
                                        >
                                          <v-icon
                                            color="white"
                                            small
                                          >
                                            {{ data.item.Avatar.Icon }}
                                          </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                          <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                          <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-select
                                      v-model="webhook.sourceType"
                                      :hint="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Hint')"
                                      :items="sourceTypesListFiltered"
                                      :label="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Label')"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      @change="onWebhookSourceTypeChange(webhook)"
                                    >
                                      <template #selection="{item}">
                                        <v-chip
                                          small
                                          label
                                          :color="item.Status ? 'green' : ''"
                                          :dark="item.Status"
                                          class="pl-0"
                                        >
                                          <v-avatar
                                            tile
                                            :color="item.Avatar.Color"
                                            class="mr-2"
                                          >
                                            <v-icon
                                              v-if="item.Avatar.Icon"
                                              class="white--text"
                                            >
                                              {{ item.Avatar.Icon }}
                                            </v-icon>

                                            <img
                                              v-else
                                              :src="item.Avatar.Img"
                                            >
                                          </v-avatar>
                                          <span>{{ item.Name }}</span>
                                        </v-chip>
                                      </template>

                                      <template #item="data">
                                        <v-list-item-avatar
                                          :size="28"
                                          :color="data.item.Avatar.Color"
                                        >
                                          <v-icon
                                            v-if="data.item.Avatar.Icon"
                                            class="white--text"
                                          >
                                            {{ data.item.Avatar.Icon }}
                                          </v-icon>

                                          <img
                                            v-else
                                            :src="data.item.Avatar.Img"
                                          >
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                          <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                          <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-select
                                      v-model="webhook.orderType"
                                      :hint="$t('Restaurant.CatalogAddDialog.Field.OrderType.Hint')"
                                      :items="orderTypesListFiltered"
                                      :label="$t('Restaurant.CatalogAddDialog.Field.OrderType.Label')"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                    >
                                      <template #selection="{item}">
                                        <v-chip
                                          small
                                          label
                                          :color="item.Status ? 'green' : ''"
                                          :dark="item.Status"
                                          class="pl-0"
                                        >
                                          <v-avatar
                                            tile
                                            :color="item.Color"
                                            class="mr-2"
                                          >
                                            <v-icon class="white--text">
                                              {{ item.Icon }}
                                            </v-icon>
                                          </v-avatar>
                                          <span>{{ item.Name }}</span>
                                        </v-chip>
                                      </template>

                                      <template #item="data">
                                        <v-list-item-avatar :color="data.item.Color">
                                          <v-icon dark>
                                            {{ data.item.Icon }}
                                          </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                          <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>

                                <v-row
                                  dense
                                  class="justify-center align-center"
                                >
                                  <v-col>
                                    <h5 class="text-body-2 font-weight-bold mt-3">
                                      {{ $t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Production.Label') }}
                                    </h5>
                                    <div class="text-caption">
                                      {{ $t('Settings.Domain.Edit.Field.WebHooks.Order.Field.Production.Hint') }}
                                    </div>
                                  </v-col>
                                  <v-col class="shrink">
                                    <v-switch
                                      v-model="webhook.production"
                                      :disabled="!userCanManage"
                                      :label="webhook.production ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      class="ma-0 d-inline-flex ml-2"
                                      color="blue"
                                      hide-details
                                      inset
                                    />
                                  </v-col>
                                </v-row>

                                <v-row
                                  dense
                                  class="mt-4"
                                >
                                  <v-col cols="12">
                                    <h5 class="text-body-2 font-weight-bold pb-1">
                                      {{ $t('Settings.Domain.Edit.Field.WebHooks.Header.Title') }}
                                    </h5>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-simple-table>
                                      <template #default>
                                        <thead>
                                          <tr>
                                            <th class="text-left">
                                              {{ $t('Settings.Domain.Edit.Field.WebHooks.Header.Name') }}
                                            </th>

                                            <th class="text-left">
                                              {{ $t('Settings.Domain.Edit.Field.WebHooks.Header.Value') }}
                                            </th>

                                            <th class="text-right">
                                              <v-btn
                                                icon
                                                depressed
                                                x-small
                                                class="primary white--text"
                                                @click="addWebhookHeader(webhook)"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <template v-for="(header, headerIdx) in webhook.headers">
                                            <tr :key="`header-${headerIdx}`">
                                              <td class="pa-2">
                                                <v-text-field
                                                  v-model="header.name"
                                                  :placeholder="$t('Settings.Domain.Edit.Field.WebHooks.Header.Name')"
                                                  outlined
                                                  dense
                                                  single-line
                                                  hide-details
                                                  clearable
                                                />
                                              </td>

                                              <td class="pa-2">
                                                <v-text-field
                                                  v-model="header.value"
                                                  :placeholder="$t('Settings.Domain.Edit.Field.WebHooks.Header.Value')"
                                                  outlined
                                                  dense
                                                  single-line
                                                  hide-details
                                                  clearable
                                                />
                                              </td>

                                              <td
                                                class="text-right"
                                                style="width: 40px;"
                                              >
                                                <v-btn
                                                  icon
                                                  depressed
                                                  x-small
                                                  class="red white--text"
                                                  @click="removeWebhookHeader(webhook, headerIdx)"
                                                >
                                                  <v-icon small>
                                                    mdi-close
                                                  </v-icon>
                                                </v-btn>
                                              </td>
                                            </tr>
                                          </template>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="red"
                                dark
                                depressed
                                small
                                @click="removeWebhook('order', webhookIdx)"
                              >
                                <v-icon left>
                                  delete
                                </v-icon>
                                {{ $t('Settings.Domain.Edit.Field.WebHooks.Button.Delete') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </template>
                    </template>

                    <v-col cols="12">
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item key="logging">
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        APPLICATION LOGGING
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Remote Logging
                      </h5>
                      <div class="text-caption">
                        Remote Application Logging
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Logging.ENABLED"
                        :disabled="!userCanManage"
                        :label="domain.Logging.ENABLED ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      class="my-2"
                      cols="12"
                    />

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        BUG SNAG
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Desktop
                      </h5>
                      <div class="text-caption">
                        Desktop Logging
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Logging.BUGSNAG.DESKTOP"
                        :disabled="!userCanManage || !domain.Logging.ENABLED"
                        :label="domain.Logging.BUGSNAG.DESKTOP ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Web
                      </h5>
                      <div class="text-caption">
                        Web Logging
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Logging.BUGSNAG.WEB"
                        :disabled="!userCanManage || !domain.Logging.ENABLED"
                        :label="domain.Logging.BUGSNAG.WEB ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />

                    <v-col cols="12">
                      <v-text-field
                        v-model="domain.Logging.BUGSNAG.KEY"
                        :disabled="!userCanManage || !domain.Logging.ENABLED"
                        hide-details
                        label="Api Key"
                      />
                    </v-col>

                    <v-col
                      class="my-2"
                      cols="12"
                    />

                    <v-col cols="12">
                      <h5 class="text-body-2 font-weight-bold">
                        LOG ROCKET
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Desktop
                      </h5>
                      <div class="text-caption">
                        Desktop Logging
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Logging.LOGROCKET.DESKTOP"
                        :disabled="!userCanManage || !domain.Logging.ENABLED"
                        :label="domain.Logging.LOGROCKET.DESKTOP ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />

                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        Web
                      </h5>
                      <div class="text-caption">
                        Web Logging
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="domain.Logging.LOGROCKET.WEB"
                        :disabled="!userCanManage || !domain.Logging.ENABLED"
                        :label="domain.Logging.LOGROCKET.WEB ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="ma-0 d-inline-flex ml-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col cols="12" />

                    <v-col cols="12">
                      <v-text-field
                        v-model="domain.Logging.LOGROCKET.KEY"
                        :disabled="!userCanManage || !domain.Logging.ENABLED"
                        hide-details
                        label="Api Key"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="!isAddNewMode"
                key="application"
              >
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row
                    dense
                    class="align-center"
                  >
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="domain.Application.name"
                        :disabled="!isAddNewMode"
                        :hint="$t('Settings.Domain.Edit.Field.AppName.Hint')"
                        :label="$t('Settings.Domain.Edit.Field.AppName.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.AppName.Icon')"
                        :readonly="!isAddNewMode"
                        :rules="domain.vuetifyFormFieldRules('Application.name')"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="domain.Application.id"
                        :disabled="!isAddNewMode"
                        :label="$t('Settings.Domain.Edit.Field.AppId.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.AppId.Icon')"
                        :readonly="!isAddNewMode"
                        :rules="domain.vuetifyFormFieldRules('Application.id')"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="domain.Application.key"
                        :disabled="!isAddNewMode"
                        :label="$t('Settings.Domain.Edit.Field.AppKey.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.AppKey.Icon')"
                        :readonly="!isAddNewMode"
                        :rules="domain.vuetifyFormFieldRules('Application.key')"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="8"
                      sm="6"
                    >
                      <v-text-field
                        v-model="domain.Application.secret"
                        :disabled="!isAddNewMode"
                        :label="$t('Settings.Domain.Edit.Field.AppSecret.Label')"
                        :prepend-icon="$t('Settings.Domain.Edit.Field.AppSecret.Icon')"
                        :readonly="!isAddNewMode"
                        :rules="domain.vuetifyFormFieldRules('Application.secret')"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-form>

          <platform-update-dialog
            :visible.sync="platformUpdateDialogVisible"
            :data="selectedShopUpdate"
            @save:time="savePlatformUpdateTime"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-btn
            v-if="tab === 0"
            class="light-blue--text text--darken-1"
            outlined
            @click="onReCheck"
          >
            RE-CHECK
          </v-btn>
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData              from '@/mixins/appdata'
import Auth                 from '@/mixins/auth'
import DataTable            from '@/mixins/data-table/dataTable'
import DomainModel          from '@/api/Models/settings/DomainModel'
import ReceiptTypeEnum      from '@/api/Enums/ReceiptTypeEnum'
import NotifyTypeEnum       from '@/api/Enums/NotifyTypeEnum'
import { mergeDeep }        from '@/lib/helper/helper'
import PlatformUpdateDialog from '@/components/settings/domains/PlatformUpdateDialog.vue'
import OrderStatusEnum      from '@/api/Enums/OrderStatusEnum'
import SourceType           from '@/mixins/orders/sourceType'

export default {
  name      : 'DomainEditDialog',
  components: { PlatformUpdateDialog },
  mixins    : [AppData, Auth, DataTable, SourceType],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    domain: {
      type   : Object,
      default: undefined
    },
    domains: {
      type   : Array,
      default: () => []
    },
    databases: {
      type   : Array,
      default: () => []
    },
    types: {
      type   : Array,
      default: () => []
    },
    countries: {
      type   : Array,
      default: () => []
    },
    resellers: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      tab                             : null,
      domainFormValid                 : false,
      platformUpdateDialogVisible     : false,
      selectedShopUpdate              : null,
      Weekdays                        : this.$t('Common.Date.WeekDays.Long'),
      smsProviderRouteePasswordVisible: false,
      fiscalDocumentProviders         : [
        {
          Name: 'Generic',
          Id  : 'generic'
        },
        {
          Name: 'Impact',
          Id  : 'impact'
        },
        {
          Name: 'Mat',
          Id  : 'mat'
        },
        {
          Name: 'Primer',
          Id  : 'primer'
        }
      ],
      systemDomainsList   : ['on.getfood.gr', 'online.getfood.gr', 'dev.getfood.gr', 'getfood.gr', 'on.eorder.store', 'online.eorder.store', 'dev.eorder.store', 'eorder.store', 'on.e-order.store', 'online.e-order.store', 'dev.e-order.store', 'e-order.store'],
      checkDomainStatus   : null,
      checkCNameStatus    : null,
      editObj             : null,
      permissionsFormValid: false,
      dataTable           : {
        options: {
          serverSideEnabled: false,
          itemsPerPage     : -1,
          defaultSortBy    : 'permission',
          sortBy           : ['permission'],
          sortDesc         : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Settings.Domain.Edit.Field.Permission'),
            align    : 'left',
            value    : 'permission',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Settings.Domain.Edit.Field.Access'),
            align    : 'center',
            value    : 'group_permissions',
            sortable : false,
            cellClass: 'text-caption',
            width    : 40
          }
        ]
      }
    }
  },
  computed: {
    OrderStatusEnum () {
      return OrderStatusEnum
    },
    webhookOrderStatuses () {
      return this.$t('Order.OrdersAll.Filter.filterOrderStatus.Items').filter(status => [OrderStatusEnum.Accepted, OrderStatusEnum.Completed, OrderStatusEnum.Canceled].includes(status.Id))
    },

    domainWebHooks () {
      return this.domain?.Webhooks || null
    },

    domainPlatformsEfoodShops () {
      return this.shopsArrayMerged('efood')
    },
    domainPlatformsFoodyShops () {
      return this.shopsArrayMerged('foody')
    },
    domainPlatformsFagiShops () {
      return this.shopsArrayMerged('fagi')
    },
    domainPlatformsGetfoodShops () {
      return this.shopsArrayMerged('getfood')
    },

    isAddNewMode () {
      return this.domain.Id === null
    },
    locale () {
      const country = this.countryList.find(country => country.id === this.domain.CountryId)
      return country ? (country.code === 'GR' ? 'EL' : country.code === 'GB' ? 'UK' : country.code) : ''
    },
    databasesList () {
      return this.databases || []
    },
    countryList () {
      return this.countries || []
    },
    domainsList () {
      const domains = this.domains || []
      return domains.map(domain => {
        return {
          name       : `${ domain.Domain }.${ domain.BaseDomain }`,
          description: domain.DBName,
          db_name    : domain.DBName,
          is_primary : this.domain.PrimaryChildren?.find(d => d.db_name === domain.DBName)?.is_primary || false
        }
      })
    },
    resellersList () {
      return this.resellers || []
    },

    sourceTypesListFiltered () {
      let sourceTypesAll = this.appConfig?.SOURCE_TYPES?.DEFAULT?.ALL || {}
      let sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})
      let sourceTypesOther = this.appConfig?.SOURCE_TYPES?.OTHER || []

      sourceTypesAll = [{
        Id         : sourceTypesAll.source_type_id,
        Name       : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Title || sourceTypesAll.title,
        Description: this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Desc || sourceTypesAll.desc,
        Avatar     : {
          Icon : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Icon || sourceTypesAll.icon,
          Color: 'green'
        }
      }]

      sourceTypesDefault = sourceTypesDefault.filter(sourceType => !sourceType.deleted_at).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })

      sourceTypesOther = sourceTypesOther.filter(sourceType => !sourceType.deleted_at).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : o.title,
          Description: o.desc,
          Avatar     : {
            Img  : o.logo,
            Color: 'blue-grey'
          }
        }
      })

      return [...sourceTypesAll, ...sourceTypesDefault, ...sourceTypesOther]
    },

    orderTypesListFiltered () {
      const orderTypes = this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Items')
      const retVal = orderTypes // orderTypes.filter(orderType => this.editCatalog.SourceTypes.some(sourceType => catalog.OrderType === orderType.Id))

      return retVal
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    'domain.WithDummyData': function (newVal) {
      if (newVal) {
        this.domain.ImportFromThirdParty = false
        this.domain.ThirdPartyUrl = ''
        this.domain.ImportFromThirdPartyForce = false
      }
    },
    'domain.ImportFromThirdParty': function (newVal) {
      if (newVal) {
        this.domain.WithDummyData = false
      }
    },
    'domain.CNameActive': function (newVal) {
      if (newVal) {
        this.domain.Reserved = false
      } else if (this.domain.Active) {
        this.domain.Reserved = true
      }
    },
    'domain.DatabaseAutoGenerate': function (newVal) {
      if (!this.isAddNewMode) return

      if (newVal) {
        this.domain.validator.rules.DatabaseName.required = false
        this.domain.DatabaseName = ''
      } else if (this.domain.Active) {
        this.domain.validator.rules.DatabaseName.required = true
        this.onDomainChange()
      }
    },
    'domain.BaseDomain': function () {
      this.onReCheck()
    },
    isVisible (newVal) {
      if (newVal) {
        if (this.isAddNewMode) {
          window.callAS(window.SocketCommand.Staff.Group.All, {})
        } else {
          window.callAS(window.SocketCommand.Staff.Group.All, { AppId: this.domain.Application.id || null })
        }
        this.onReCheck()
      }
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Domains.CheckDomain, this.onCheckDomainResult)
    this.$bus.$on(window.SocketCommand.Settings.Domains.CheckCName, this.onCheckCNameResult)
    this.$bus.$on(window.SocketCommand.Staff.Group.All, this.onStaffGroupsResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Domains.CheckDomain, this.onCheckDomainResult)
    this.$bus.$off(window.SocketCommand.Settings.Domains.CheckCName, this.onCheckCNameResult)
    this.$bus.$off(window.SocketCommand.Staff.Group.All, this.onStaffGroupsResult)
  },
  methods: {
    removePlatformUpdateDayTime (dayArr, idx) {
      if (!Array.isArray(dayArr) || !dayArr.length || idx < 0) return
      dayArr.splice(idx, 1)
    },
    addPlatformUpdateDayTime (dayArr) {
      if (!Array.isArray(dayArr)) return

      dayArr.push('09:00')
    },
    addPlatformUpdateWeekTime (shop) {
      if (!shop?.update) return

      for (let i = 1; i <= 7; i++) {
        shop.update[i].push('09:00')
      }
    },
    editPlatformUpdateTime (dayArr, timeIdx) {
      this.selectedShopUpdate = {
        value  : dayArr[timeIdx],
        dayArr : dayArr,
        timeIdx: timeIdx
      }
      this.platformUpdateDialogVisible = true
    },
    savePlatformUpdateTime (value, dayArr, timeIdx) {
      dayArr[timeIdx] = value
    },

    shopsArrayMerged (platform) {
      const shopsArray = this.domain?.Platforms[platform]?.shops || []

      for (let i = 0; i < shopsArray.length; i++) {
        const payload = mergeDeep({
          active    : false,
          production: false,
          name      : '',
          shopId    : '',
          mainId    : '',
          password  : '',
          update    : {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: []
          }
        }, shopsArray[i])

        this.$set(shopsArray, 'i', payload)
        shopsArray[i] = payload
      }

      return shopsArray
    },

    onProviderChange (newVal) {
      const mandatoryCustomValues = [
        {
          key  : 'supportsNotify',
          value: [],
          list : [
            {
              Name: 'Email',
              Id  : NotifyTypeEnum.EMAIL
            },
            {
              Name: 'SMS',
              Id  : NotifyTypeEnum.SMS
            }
          ],
          type    : 'list',
          multiple: true,
          required: true
        },
        {
          key  : 'supportsDocument',
          value: [ReceiptTypeEnum.RECEIPT],
          list : [
            {
              Name: 'Receipt',
              Id  : ReceiptTypeEnum.RECEIPT
            },
            {
              Name: 'Invoice',
              Id  : ReceiptTypeEnum.INVOICE
            },
            {
              Name: 'Fnb',
              Id  : ReceiptTypeEnum.FNB
            }
          ],
          type    : 'list',
          multiple: true,
          required: true
        }
      ]

      if (newVal === 'impact') {
        const impact = {
          live: [
            {
              key  : 'vat',
              value: '',
              type : 'string'
            },
            {
              key  : 'apiKey',
              value: '',
              type : 'string'
            },
            {
              key  : 'endpoint',
              value: '',
              type : 'string'
            },
            ...mandatoryCustomValues
          ],
          staging: [
            {
              key  : 'vat',
              value: '',
              type : 'string'
            },
            {
              key  : 'apiKey',
              value: '',
              type : 'string'
            },
            {
              key  : 'endpoint',
              value: 'https://einvoiceapiuat.impact.gr/',
              type : 'string'
            },
            ...mandatoryCustomValues
          ]
        }
        this.domain.ReceiptsProviderConfig.live = impact.live
        this.domain.ReceiptsProviderConfig.staging = impact.staging

        return
      }

      if (newVal === 'mat') {
        const mat = {
          live: [
            {
              key  : 'username',
              value: '',
              type : 'string'
            },
            {
              key  : 'password',
              value: '',
              type : 'string'
            },
            {
              key  : 'endpoint',
              value: '',
              type : 'string'
            },
            {
              key  : 'activationCode',
              value: '',
              type : 'string'
            },
            {
              key  : 'vat',
              value: '',
              type : 'string'
            },
            ...mandatoryCustomValues
          ],
          staging: [
            {
              key  : 'username',
              value: '',
              type : 'string'
            },
            {
              key  : 'password',
              value: '',
              type : 'string'
            },
            {
              key  : 'endpoint',
              value: 'https://dev.rapidsign.com.gr/api/v1.0/'
            },
            {
              key  : 'activationCode',
              value: '',
              type : 'string'
            },
            {
              key  : 'vat',
              value: '',
              type : 'string'
            },
            ...mandatoryCustomValues
          ]
        }
        this.domain.ReceiptsProviderConfig.live = mat.live
        this.domain.ReceiptsProviderConfig.staging = mat.staging

        return
      }

      if (newVal === 'generic') {
        const generic = {
          live: [
            {
              key  : 'vat',
              value: '',
              type : 'string'
            },
            ...mandatoryCustomValues
          ],
          staging: [
            {
              key  : 'vat',
              value: '',
              type : 'string'
            },
            ...mandatoryCustomValues
          ]
        }
        this.domain.ReceiptsProviderConfig.live = generic.live
        this.domain.ReceiptsProviderConfig.staging = generic.staging

        return
      }

      const defaultProvider = {
        live: [
          {
            key  : 'vat',
            value: ''
          },
          {
            key  : 'apiKey',
            value: ''
          },
          {
            key  : 'endpoint',
            value: ''
          },
          ...mandatoryCustomValues
        ],
        staging: [
          {
            key  : 'vat',
            value: ''
          },
          {
            key  : 'apiKey',
            value: ''
          },
          {
            key  : 'endpoint',
            value: ''
          },
          ...mandatoryCustomValues
        ]
      }

      this.domain.ReceiptsProviderConfig.live = defaultProvider.live
      this.domain.ReceiptsProviderConfig.staging = defaultProvider.staging
    },

    addWebhook (type) {
      const webhooksArray = this.domainWebHooks[type]?.hooks || null

      if (webhooksArray) {
        webhooksArray.push({
          active    : false,
          production: false,
          status    : [OrderStatusEnum.Accepted, OrderStatusEnum.Completed, OrderStatusEnum.Canceled],
          sourceType: [0],
          orderType : 0,
          headers   : [],
          name      : '',
          url       : ''
        })
      }
    },
    removeWebhook (type, index) {
      const webhooksArray = this.domainWebHooks[type]?.hooks || []
      webhooksArray.splice(index, 1)
    },

    onWebhookSourceTypeChange (webhook) {
      const sourceTypeArray = webhook?.sourceType || []

      if (sourceTypeArray.length > 1) {
        if (sourceTypeArray.includes(0) && sourceTypeArray[0] !== 0) {
          webhook.sourceType = [0]
        } else if (sourceTypeArray.includes(0)) {
          webhook.sourceType = sourceTypeArray.filter(sourceType => sourceType !== 0)
        }
      }
    },

    addWebhookHeader (webhook) {
      if (!Array.isArray(webhook?.headers)) webhook.headers = []
      const headersArray = webhook?.headers || []

      headersArray.push({
        name : '',
        value: ''
      })
    },
    removeWebhookHeader (webhook, index) {
      const headersArray = webhook?.headers || []
      headersArray.splice(index, 1)
    },

    addShop (platform) {
      const shopsArray = this?.domain?.Platforms[platform]?.shops || null

      if (shopsArray) {
        shopsArray.push({
          active    : false,
          production: false,
          name      : '',
          shopId    : '',
          mainId    : '',
          update    : {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: []
          }
        })
      }
    },
    removeShop (platform, index) {
      const shopsArray = this?.domain?.Platforms[platform]?.shops || null
      if (shopsArray) shopsArray.splice(index, 1)
    },

    onAddReceiptProviderConfigLive () {
      this.domain.ReceiptsProviderConfig.live.push({
        key  : '',
        value: '',
        type : 'string'
      })
    },
    onRemoveReceiptProviderConfigLive (index) {
      this.domain.ReceiptsProviderConfig.live.splice(index, 1)
    },

    onAddReceiptProviderConfigStaging () {
      this.domain.ReceiptsProviderConfig.staging.push({
        key  : '',
        value: '',
        type : 'string'
      })
    },
    onRemoveReceiptProviderConfigStaging (index) {
      this.domain.ReceiptsProviderConfig.staging.splice(index, 1)
    },

    toggleIsPrimary (data) {
      const domains = JSON.parse(JSON.stringify(this.domain.PrimaryChildren))

      for (let i = 0; i < domains.length; i++) {
        if (domains[i].db_name === data.item.db_name) {
          domains[i].is_primary = !domains[i].is_primary
        } else {
          domains[i].is_primary = false
        }
      }

      this.domain.PrimaryChildren = domains
    },

    getPermissionColor (item, permission, isText = false) {
      const activePackage = this.editObj.Packages.find(pkg => parseInt(pkg.Id) === parseInt(this.editObj.ActivePackage))
      const found = activePackage && activePackage.Permissions.includes(parseInt(item.Id))
      return found ? ('green' + (isText ? '--text' : '')) : ('orange' + (isText ? '--text' : ''))
    },
    onCheckboxChange (type, permission) {
      if (this.editObj.Id > 1) {
        if (type === 'manage' || type === 'add' || type === 'delete') {
          if (!permission.GroupPermissions.includes('access')) {
            permission.GroupPermissions.push('access')
            if (!permission.GroupPermissions.includes('api')) permission.GroupPermissions.push('api')
          }
        }

        if (!permission.GroupPermissions.includes('api') && permission.GroupPermissions.includes('access')) {
          permission.GroupPermissions.push('api')
        } else {
          if (!permission.GroupPermissions.includes('access')) {
            if (this.permissionHasActiveRequires(permission)) permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'api')
            permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'manage')
            permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'add')
            permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'delete')
          }
        }

        if (permission.Requires.Parent) {
          const foundPermission = this.editObj.Permissions.find(p => parseInt(p.Id) === parseInt(permission.Requires.Parent))
          if (foundPermission) {
            if (!foundPermission.GroupPermissions.includes('api') && permission.GroupPermissions.includes('api')) foundPermission.GroupPermissions.push('api')
            if (!foundPermission.GroupPermissions.includes('access') && permission.GroupPermissions.includes('access')) foundPermission.GroupPermissions.push('access')
          }
        }

        const permissionChildren = this.permissionGetChildren(permission)
        permissionChildren.forEach(p => {
          if (!p.GroupPermissions.includes('api') && permission.GroupPermissions.includes('api')) {
            p.GroupPermissions.push('api')
          } else if (type === 'access') {
            p.GroupPermissions = p.GroupPermissions.filter(el => el !== 'api')
          }
          if (!p.GroupPermissions.includes('access') && permission.GroupPermissions.includes('access')) {
            p.GroupPermissions.push('access')
          } else if (type === 'access') {
            p.GroupPermissions = p.GroupPermissions.filter(el => el !== 'access')
          }
        })

        if (Array.isArray(permission.Requires.Permissions) && permission.Requires.Permissions.length > 0) {
          this.editObj.Permissions.forEach(permission => {
            if (!Array.isArray(permission.Requires.Permissions)) permission.Requires.Permissions = []
            permission.Requires.Permissions.forEach(r => {
              const foundPermission = this.editObj.Permissions.find(p => parseInt(p.Id) === parseInt(r))
              if (foundPermission) {
                if (!foundPermission.GroupPermissions.includes('api')) {
                  if (permission.GroupPermissions.includes('access') && (!foundPermission.GroupPermissions.includes('api'))) foundPermission.GroupPermissions.push('api')
                } else {
                  if (!foundPermission.GroupPermissions.includes('access') && this.editObj.Permissions.filter(p => (parseInt(p.Id) !== parseInt(permission.Id)) && (!p.GroupPermissions.includes('access') && !p.GroupPermissions.includes('api')) && (p.Requires.Permissions ? p.Requires.Permissions.includes(String(permission.Id)) : false)).length <= 0) {
                    if (!permission.GroupPermissions.includes('access')) foundPermission.GroupPermissions = foundPermission.GroupPermissions.filter(el => el !== 'api')
                  }
                }
              }
            })
          })
        }
      }
    },
    permissionHasActiveRequires (permission) {
      const retVal = this.editObj.Permissions.filter(p => {
        if (!Array.isArray(p.Requires.Permissions)) p.Requires.Permissions = []
        return parseInt(p.Id) !== parseInt(permission.Id) &&
          p.Requires.Permissions.includes(parseInt(permission.Id)) &&
          p.GroupPermissions.includes('access') && p.GroupPermissions.includes('api')
      })
      return retVal.length <= 0
    },
    permissionGetChildren (permission) {
      return this.editObj.Permissions.filter(p => parseInt(p.Requires.Parent) === parseInt(permission.Id))
    },
    onPackageSelection (activePackageId) {
      const activePackage = this.editObj.Packages.find(p => parseInt(p.Id) === parseInt(activePackageId))
      if (activePackage) {
        this.editObj.Permissions.forEach(p => {
          const found = activePackage ? parseInt(activePackage.Id) === 1 ? true : activePackage.Permissions.includes(parseInt(p.Id)) : false
          if (found) {
            p.GroupPermissions = ['access']
          } else {
            p.GroupPermissions = []
          }
        })
      }
    },
    onStaffGroupsResult (data) {
      if (data) {
        this.editObj = data.find(p => parseInt(p.Id) === 1)
        if (!this.editObj) return

        this.dataTable.data = this.editObj.Permissions

        if (this.isAddNewMode) {
          // this.editObj.ActivePackage = 10
        }
      }
    },

    onReCheck () {
      this.checkDomainStatus = null
      this.checkCNameStatus = null
      this.onCheckDomain()
      this.onCheckCName()
    },
    onCheckDomain () {
      if (this.isVisible && this.domain.Active && this.domain.Domain && this.domain.BaseDomain) {
        window.callAS(window.SocketCommand.Settings.Domains.CheckDomain, { Domain: `${ this.domain.Domain }.${ this.domain.BaseDomain }` }, window.SocketCommand.Settings.Domains.CheckDomain)
      }
    },
    onCheckCName () {
      if (this.isVisible && this.domain.CNameActive && this.domain.CName) {
        window.callAS(window.SocketCommand.Settings.Domains.CheckDomain, { Domain: this.domain.CName }, window.SocketCommand.Settings.Domains.CheckCName)
      }
    },
    onCheckDomainResult (data) {
      this.checkDomainStatus = data.status || null
    },
    onCheckCNameResult (data) {
      this.checkCNameStatus = data.status || null
    },
    onDomainChange () {
      if (!this.isAddNewMode) return

      const domain = this.domain.Domain.trim() ? this.domain.Domain + '' : ''
      this.domain.DatabaseName = domain ? 'gfp_' + domain.replace(/\./g, '_').replace(/-/g, '_') : ''
    },
    onSaveButtonClick () {
      this.$refs.domainForm.validate()

      if (this.domainFormValid) {
        let i = 0
        const permissions = {}
        const saveObj = JSON.parse(JSON.stringify(this.editObj))
        for (i = 0; i < this.editObj.Permissions.length; i++) {
          if (this.editObj.Permissions[i].GroupPermissions.length > 0) {
            permissions[this.editObj.Permissions[i].Id] = this.editObj.Permissions[i].GroupPermissions
          }
        }
        saveObj.Permissions = permissions

        if (this.$refs.domainForm) this.$refs.domainForm.resetValidation()
        const domain = new DomainModel(this.domain.clone())
        domain.Subdomain = `${ domain.Domain }.${ domain.BaseDomain }`

        this.$emit('domain-save', domain, saveObj)
        this.tab = 0
      }
    },
    onCancelButtonClick () {
      if (this.$refs.domainForm) this.$refs.domainForm.resetValidation()
      this.isVisible = false
      this.tab = 0
    }
  }
}
</script>
