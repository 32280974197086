<template>
  <v-alert
    v-if="alertData"
    border="left"
    dense
    dark
    :prominent="$vuetify.breakpoint.smAndUp"
    :icon="alertData.Icon"
    :class="alertData.Class"
    :color="alertData.Color"
    :type="alertData.Type"
    class="mb-0"
  >
    <v-row align="center">
      <v-col
        class="grow"
        :cols="$vuetify.breakpoint.smAndDown ? 'auto' : ''"
      >
        <div
          class="text-subtitle-1 font-weight-bold"
          v-html="alertData.Title"
        />

        <div
          class="text-caption"
          v-html="alertData.SubTitle"
        />
      </v-col>

      <v-col
        v-if="alertData.Button.Active && !hideActions"
        class="text-right"
        :class="{'shrink': $vuetify.breakpoint.smAndUp}"
      >
        <v-btn
          :to="alertData.Button.To"
          :class="alertData.Button.Class"
          :color="alertData.Button.Color"
          :block="$vuetify.breakpoint.xs"
          depressed
        >
          {{ alertData.Button.Title }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>

export default {
  name      : 'CurrentPlanWarningAlert',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    alertData: {
      type   : Object,
      default: undefined
    },
    hideActions: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
