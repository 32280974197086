<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12">
        <template v-for="(payment, index) in paymentData">
          <v-container
            :key="`payment-${payment.Timestamp}`"
            class="pa-0"
            fluid
          >
            <v-row dense>
              <!-- PAYMENT HEADER -->
              <v-col cols="12">
                <div
                  :class="[`${tableStaffColor(payment.StaffId)} lighten-4`]"
                  class="pa-1 grey--text text--darken-3 text-subtitle-1 font-weight-bold rounded"
                >
                  <v-container
                    class="pa-0"
                    fluid
                  >
                    <v-row
                      dense
                      justify="center"
                      align="center"
                    >
                      <v-col
                        cols="7"
                        class="py-1"
                      >
                        <div>
                          {{ $tc('Restaurant.Tables.Dialog.Payment.Title', 1) }} #{{ desc ? (paymentData.length - index) : (index + 1) }} -&nbsp;<span class="grey--text text--darken-1">{{ payment.Time }}</span>
                        </div>

                        <div
                          v-if="payment.StaffName"
                          class="text-caption"
                        >
                          <div class="grey--text text--darken-2">
                            <strong>{{ $tc('Waiter.Title', 1) }}:</strong> {{ payment.StaffName }}
                          </div>
                        </div>
                      </v-col>

                      <v-col
                        cols="5"
                        class="text-right"
                      >
                        <div class="d-inline-block">
                          <v-chip
                            v-if="getPaymentCouponsTotal(payment) > 0"
                            label
                            color="purple"
                            class="ml-1 mb-1 mb-sm-0"
                            text-color="white"
                          >
                            <v-icon left>
                              redeem
                            </v-icon>
                            {{ getPaymentCouponsTotal(payment) | currency }}
                          </v-chip>

                          <template v-for="(transaction, transIndex) in payment.Transactions">
                            <v-chip
                              :key="`payment-${payment.Timestamp}-transaction-${transIndex}`"
                              label
                              color="blue-grey lighten-1"
                              class="ml-1 mb-1 mb-sm-0"
                              text-color="white"
                            >
                              <v-icon left>
                                {{ getPaymentTypeByCode(transaction.PaymentType).icon }}
                              </v-icon>
                              {{ transaction.Amount | currency }}
                            </v-chip>
                          </template>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>

              <!-- PAYMENT ITEMS -->
              <v-col cols="12">
                <v-container
                  class="mb-4 pa-0"
                  fluid
                >
                  <v-row>
                    <v-col cols="12">
                      <template v-for="tableSeat in getPaymentItems(payment)">
                        <v-subheader
                          :key="`payment-${payment.Timestamp}-table-seat-${tableSeat.Seat}`"
                          :class="[`${tableStaffColor(payment.StaffId)}`, isDarkMode ? 'darken-3' : 'lighten-5']"
                          class="font-weight-bold pl-1 rounded"
                          style="height: 26px;"
                        >
                          {{ tableSeat.Name }}
                        </v-subheader>

                        <template v-for="category in tableSeat.Items">
                          <v-subheader
                            :key="`payment-${payment.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}`"
                            class="grey--text text--darken-2 text-subtitle-1 font-weight-bold pl-0"
                            style="height: 36px;"
                          >
                            {{ getTranslatedField(getCategory(category.CategoryId), 'Name') || category.CategoryName }}
                          </v-subheader>

                          <v-list
                            :key="`payment-${payment.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-list`"
                            class="pa-0"
                            dense
                            three-line
                          >
                            <template v-for="(menu) in category.Items">
                              <v-list-item
                                :key="`payment-${payment.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-item-${menu.RowId}-hash-${menu.HashId}`"
                                :class="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                                ripple
                              >
                                <v-list-item-content>
                                  <span
                                    :style="`background-color: #${getCategoryColor(category.CategoryId) || '333333'};`"
                                    style="position: absolute; width:5px; height: 100%; margin-left: -16px; padding: 0; margin-right: 16px; top: 1px;"
                                  />

                                  <v-list-item-title class="text-subtitle-1 font-weight-regular">
                                    <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                      <strong class="orange--text">{{ formatUnitQuantity($i18n.locale, menu.UnitQuantity, getMeasurementUnitById(menu.UnitId)) }}</strong>
                                    </template>
                                    <template v-else>
                                      <strong class="orange--text">
                                        {{ menu.PaymentQuantity }} x
                                      </strong>
                                    </template>
                                    {{ getTranslatedField(menu, 'Name') }}
                                  </v-list-item-title>

                                  <v-list-item-subtitle class="grey--text">
                                    <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                      <span class="old-price">
                                        {{ menu.MenuPrice | currency }}
                                      </span> {{ menu.SpecialPrice | currency }}
                                    </template>
                                    <template v-else>
                                      <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                        {{ menu.MenuPrice | currency }}
                                        <span class="grey--text text-caption">/ {{ getMeasurementUnitById(menu.UnitId).BaseUnit }}</span>
                                      </template>
                                      <template v-else>
                                        <span
                                          v-if="menuItemHasCoupon(menu)"
                                          class="old-price"
                                        >
                                          {{ parseFloat(menu.MenuPrice) | currency }}
                                        </span>
                                        {{ menuItemHasCoupon(menu) ? parseFloat(menu.PriceFmt) - (parseFloat(menu.Coupon.Amount) / parseInt(menu.PaymentQuantity)) : menu.PriceFmt | currency }}
                                      </template>
                                    </template>
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle class="pb-1 text-caption grey--text">
                                    {{ getMenuSelectedOptionsString(menu) }}&nbsp;
                                  </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-list-item-action-text class="text-subtitle-1 grey--text">
                                    <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                      <span class="old-price">
                                        <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                          {{ parseFloat(menu.MenuPrice) | currency }}
                                        </template>
                                        <template v-else>
                                          {{ parseFloat(parseFloat(menu.MenuPrice) * parseInt(menu.PaymentQuantity)) | currency }}
                                        </template>
                                      </span>
                                      {{ parseFloat(parseFloat(menu.SpecialPrice) * parseInt(menu.PaymentQuantity)) | currency }}
                                    </template>

                                    <template v-else>
                                      <span
                                        v-if="menuItemHasCoupon(menu)"
                                        class="old-price"
                                      >
                                        <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                          {{ parseFloat(menu.Price) | currency }}
                                        </template>
                                        <template v-else>
                                          {{ parseFloat(parseFloat(menu.Price) * parseInt(menu.PaymentQuantity)) | currency }}
                                        </template>
                                      </span>
                                      {{ menuItemPriceTotal(menu, menuItemHasCoupon(menu)) | currency }}
                                    </template>
                                  </v-list-item-action-text>

                                  <v-list-item-action-text>
                                    <v-btn
                                      v-if="menu.Custom && parseInt(menu.Custom.Order)"
                                      class="d-inline-block ml-1 px-1"
                                      color="pink lighten-1"
                                      style="min-width: 0px;"
                                      text
                                      small
                                      ripple
                                    >
                                      <v-icon>
                                        low_priority
                                      </v-icon>

                                      <span style="position: relative; top: -4px;">{{ menu.Custom.Order }}</span>
                                    </v-btn>

                                    <v-btn
                                      v-if="menu.Custom && parseInt(menu.Custom.Seat)"
                                      class="d-inline-block ml-1 px-1"
                                      color="brown lighten-1"
                                      style="min-width: 0px;"
                                      text
                                      small
                                    >
                                      <v-icon>
                                        event_seat
                                      </v-icon>

                                      <span style="position: relative; top: -4px;">{{ menu.Custom.Seat }}</span>
                                    </v-btn>

                                    <v-btn
                                      v-if="menuItemHasCoupon(menu)"
                                      class="d-inline-block ml-1 px-1"
                                      color="purple"
                                      style="min-width: 0px;"
                                      text
                                      small
                                    >
                                      <v-icon>
                                        redeem
                                      </v-icon>
                                    </v-btn>
                                  </v-list-item-action-text>
                                </v-list-item-action>
                              </v-list-item>

                              <v-divider
                                :key="`payment-${payment.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-item-divider-${menu.RowId}-hash-${menu.HashId}`"
                                class="mb-1"
                              />
                            </template>
                          </v-list>
                        </template>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                   from '@/mixins/auth'
import AppData                from '@/mixins/appdata'
import TableStatus            from '@/mixins/tables/tableStatus'
import PaymentData            from '@/mixins/tables/paymentData'
import Translatable           from '@/mixins/translatable'
import PosCatalog             from '@/mixins/pos/posCatalog'
import PaymentType            from '@/mixins/orders/paymentType'
import TableCommon            from '@/mixins/tables/tableCommon'
import { formatUnitQuantity } from '@/lib/currency/currency'

export default {
  name      : 'TablePaymentsList',
  components: {},
  directives: {},
  mixins    : [Auth, AppData, Translatable, TableStatus, TableCommon, PaymentData, PosCatalog, PaymentType],
  props     : {
    viewData: {
      type   : Object,
      default: undefined
    },
    table: {
      type   : Object,
      default: undefined
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: { formatUnitQuantity }
}
</script>

<style scoped>

</style>
