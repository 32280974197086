import semver                                         from 'semver'
import isElectron                                     from '@/electron/isElectron'
import { getIconFromCurrency, getSymbolFromCurrency } from '@/lib/currency/currency'
import { getContrastColor }                           from '@/lib/color'
import ReceiptProviderTypeEnum                        from '@/api/Enums/ReceiptProviderTypeEnum'
import NotifyTypeEnum                                 from '@/api/Enums/NotifyTypeEnum'
import ThemeMode                                      from '@/mixins/themeMode'
import { clone }                                      from '@/lib/helper/helper'

const LOGO_TYPE = {
  GMAP  : 'gmap',
  LAZY  : 'lazy',
  QRCODE: 'qrcode',
  MAIN  : 'main'
}
const LOGO_COLOR = {
  LIGHT: 'light',
  DARK : 'dark'
}

export default {
  mixins: [ThemeMode],

  data () {
    return {
      LOGO_TYPE : LOGO_TYPE,
      LOGO_COLOR: LOGO_COLOR
    }
  },

  methods: {
    getMeasurementUnitById (id) {
      if (!id) return null

      return this.$t('Restaurant.MenuAddDialog.Field.Unit.Units')?.find(item => item.Id === id) || null // this.appConfig?.MEASUREMENT_UNITS?.find(item => item.Id === id) || null
    },
    getLanguageByCode (langCode) {
      return this.selectedLanguages.find(lang => lang.Code === langCode)
    },
    getContrastLogo (type, color) {
      const logoContrast = color === LOGO_COLOR.LIGHT || color === LOGO_COLOR.DARK ? color : !color ? LOGO_COLOR.LIGHT : getContrastColor(color) === 'white' ? LOGO_COLOR.LIGHT : LOGO_COLOR.DARK
      if (type === LOGO_TYPE.GMAP) return this.getGMapIcon(logoContrast)
      if (type === LOGO_TYPE.LAZY) return this.getLazyLogo(logoContrast)
      if (type === LOGO_TYPE.QRCODE) return this.getQRLogo(logoContrast)
      if (type === LOGO_TYPE.MAIN) return this.getLogo(logoContrast)
      return this.getLogo(logoContrast)
    },
    getGMapIcon (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.GMapIconDark : this.appConfig.LOCATION_DATA.GMapIcon
    },
    getLazyLogo (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.LazyLogoDark : this.appConfig.LOCATION_DATA.LazyLogo
    },
    getQRLogo (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.QrLogoDark : this.appConfig.LOCATION_DATA.QrLogo
    },
    getLogo (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.LogoDark : this.appConfig.LOCATION_DATA.Logo
    }
  },
  watch   : {},
  computed: {
    appConfig: {
      get () {
        return window.Vue?.AppConfig || window.appConfig
      },
      set (val) {
        window.appConfig = val
        window.Vue.AppConfig = val
      }
    },
    appVersion () {
      return 'v' + semver.valid(semver.coerce(process.env.VUE_APP_VERSION))
    },
    appVersionDesktop () {
      return isElectron() ? 'v' + semver.valid(semver.coerce(this.$DataStore?.app?.versionDesktop)) : ''
    },

    isDarkMode () {
      return this.$vuetify.theme.dark
    },

    orderTypeDefaultTimes: {
      get () {
        return window.Vue.OrderTypeDefaultTimes
      },
      set (val) {
        window.Vue.OrderTypeDefaultTimes = val
      }
    },

    onlineOrderingStatus: {
      get () {
        return window.Vue.OnlineOrderingStatus
      },
      set (val) {
        window.Vue.OnlineOrderingStatus = val
      }
    },

    appOnlineStatus: {
      get () {
        return window.Vue.AppOnlineStatus
      },
      set (val) {
        window.Vue.AppOnlineStatus = val
      }
    },

    appOnlineTotalUsers () {
      return {
        users: [...this.appOnlineNormalUsers.users, ...this.appOnlineGuestUsers.users],
        count: (this.appOnlineNormalUsers.count + this.appOnlineGuestUsers.count)
      }
    },

    appOnlineNormalUsers () {
      return this.$DataStore.channelConnections.normal
    },

    appOnlineGuestUsers () {
      return this.$DataStore.channelConnections.guest
    },

    appOnlineAdminUsers () {
      return this.$DataStore.channelConnections.admin
    },

    appCountryCode () {
      return this.appConfig.LOCATION_DATA?.CountryCode?.toUpperCase() || 'GR'
    },

    appCountryId () {
      return this.appConfig.LOCATION_DATA?.CountryId || 86
    },

    appCountryIsGreece () {
      return this.appCountryCode === 'GR'
    },
    appCountryIsCyprus () {
      return this.appCountryCode === 'CY'
    },

    appSupportsEmailNotifications () {
      return true
    },
    appSupportsSmsNotifications () {
      return false
    },
    appSupportsPushNotifications () {
      return false
    },

    appSupportIncomeClassifications () {
      return this.appCountryIsGreece
    },
    appSupportSecondTax () {
      return this.appCountryIsGreece && this.posUserCan('Tables', 'ACCESS')
    },
    appTaxes () {
      return this.appConfig?.TAX || {}
    },
    appHasOrphanedTax () {
      return this.appTaxes?.Orphaned?.Category?.Count > 0 || this.appTaxes?.Orphaned?.Menu?.Count > 0
    },
    appHasReceiptsEnabled () {
      return this.userCanViewReceipt
    },
    appHasReceiptDocEnabled () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsEnabled ?? false
    },
    appHasInvoiceDocEnabled () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsInvoiceEnabled ?? false
    },
    appHasFnbDocEnabled () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsFnbEnabled ?? false
    },
    ReceiptsNotify () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsNotify || []
    },
    ReceiptsPrintNotify () {
      return this.ReceiptsNotify.includes(NotifyTypeEnum.PRINT)
    },
    ReceiptsEmailNotify () {
      return this.ReceiptsNotify.includes(NotifyTypeEnum.EMAIL) && this.appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)
    },
    ReceiptsInvoiceNotify () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsInvoiceNotify || []
    },
    ReceiptsInvoicePrintNotify () {
      return this.ReceiptsInvoiceNotify.includes(NotifyTypeEnum.PRINT)
    },
    ReceiptsInvoiceEmailNotify () {
      return this.ReceiptsInvoiceNotify.includes(NotifyTypeEnum.EMAIL) && this.appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)
    },
    appHasReceiptsProvider () {
      return !!this.appReceiptsProvider
    },
    appReceiptsProvider () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsProvider || ''
    },
    appReceiptsProviderType () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsProviderType || ReceiptProviderTypeEnum.NONE
    },
    appReceiptsProviderNotify () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsProviderNotify || []
    },
    appReceiptsProviderDocument () {
      return this.appConfig?.LOCATION_DATA?.ReceiptsProviderDocument || []
    },

    appNotifyPush () {
      return this.appConfig?.LOCATION_DATA?.Notify?.Push || {}
    },
    appNotifyPushActive () {
      return this.appNotifyPush?.Active && this.appNotifyPush?.Push?.Active && (this.appNotifyPush?.Push?.Web.Active || this.appNotifyPush?.Push?.Mobile.Active)
    },

    appNotifySms () {
      return this.appConfig?.LOCATION_DATA?.Notify?.Sms || {}
    },
    appNotifySmsActive () {
      return this.appNotifySms?.active
    },
    appNotifySmsCredits () {
      return this.appConfig?.LOCATION_DATA?.Notify?.SmsCredits || 0
    },

    appCurrency () {
      const retVal = {
        Name  : this.appConfig.LOCATION_DATA?.Currency?.name || '',
        Symbol: getSymbolFromCurrency(this.appConfig.LOCATION_DATA?.Currency?.name) || '',
        Icon  : getIconFromCurrency(this.appConfig.LOCATION_DATA?.Currency?.name) || '',
        Locale: this.appConfig.LOCATION_DATA?.Currency?.locale || ''
      }
      return retVal
    },
    appCurrencyOptions () {
      const Locale = window.appConfig.LOCATION_DATA?.Currency?.locale || 'el-GR'
      const Currency = window.appConfig.LOCATION_DATA?.Currency?.name || 'EUR'
      return new Intl.NumberFormat(Locale, {
        style   : 'currency',
        currency: Currency
      }).resolvedOptions()
    },

    appMachinePrinters () {
      return window.Vue.AppMachinePrinters ? window.Vue.AppMachinePrinters : []
    },
    appMachinePrintersActive () {
      return isElectron() ? this.appMachinePrinters.filter(printer => printer.status === '1') : [{
        name  : '',
        copies: 1
      }]
    },
    appMachineWaiterPrintersActive () {
      return this.appMachinePrintersActive?.filter(printer => printer?.waiter === '1') || [{
        name  : '',
        copies: 1
      }]
    },
    appHasPrinters () {
      return isElectron() ? this.appMachinePrinters.filter(printer => printer.status === '1').length > 0 : true
    },

    appMachineReceiptPrinters () {
      return window.Vue.AppMachineReceiptPrinters ? window.Vue.AppMachineReceiptPrinters : []
    },
    appMachineReceiptPrintersActive () {
      return isElectron() ? this.appMachineReceiptPrinters.filter(printer => printer.status === '1') : [{
        name  : '',
        copies: 1
      }]
    },
    appMachineHasReceiptPrinters () {
      return this.appMachineReceiptPrintersActive.length && this.appHasReceiptPrinters
    },
    appHasReceiptPrinters () {
      return isElectron() ? this.appMachineReceiptPrinters.filter(printer => printer.status === '1').length > 0 : true
    },

    appMachineReceiptTotalsZPrinters () {
      return window.Vue.AppMachineReceiptTotalsZPrinters ? window.Vue.AppMachineReceiptTotalsZPrinters : []
    },
    appMachineReceiptTotalsZPrintersActive () {
      return isElectron() ? this.appMachineReceiptTotalsZPrinters.filter(printer => printer.status === '1') : [{
        name  : '',
        copies: 1
      }]
    },
    appMachineHasReceiptTotalsZPrinters () {
      return this.appMachineReceiptTotalsZPrintersActive.length && this.appHasReceiptPrinters
    },
    appHasReceiptTotalsZPrinters () {
      return isElectron() ? this.appMachineReceiptTotalsZPrinters.filter(printer => printer.status === '1').length > 0 : true
    },

    appPrinterGroups () {
      return window.Vue.AppPrinterGroups ? window.Vue.AppPrinterGroups : []
    },
    appPrinterGroupsActive () {
      return this.appPrinterGroups?.filter(group => group.Status) || []
    },

    appMachinePreparationPrinters () {
      return window.Vue.AppMachinePreparationPrinters ? window.Vue.AppMachinePreparationPrinters : []
    },
    appMachinePreparationPrintersActive () {
      return isElectron() ? this.appMachinePreparationPrinters.filter(printer => printer.status === '1') : [{
        name  : '',
        copies: 1
      }]
    },
    appHasPreparationPrinters () {
      return isElectron() ? this.appMachinePreparationPrinters.filter(printer => printer.status === '1').length > 0 : true
    },

    domainReseller () {
      return this.appConfig?.LOCATION_DATA?.Reseller || null
    },

    defaultLanguage () {
      return this.selectedLanguages?.find(lang => lang.Selected) || this.selectedLanguages[0]
    },
    selectedLanguages () {
      const languages = clone(this.appConfig?.LOCATION_DATA?.LanguageList || [])
      return languages.sort((a, b) => a.Selected === b.Selected ? 0 : a.Selected ? -1 : 1)
    },
    faqLanguages () {
      return this.$i18n?.languages?.filter(lang => ['el', 'en'].includes(lang.locale)) || []
    },

    multipleLocations () {
      return this.appConfig?.LOCATION_DATA?.MultipleLocations || []
    }
  },
  mounted () {},
  beforeDestroy () {}
}
