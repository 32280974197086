<template>
  <div class="callsQueue orange rounded">
    <v-menu
      v-model="showMenuCallQueue"
      :close-on-click="true"
      :close-on-content-click="true"
      :nudge-bottom="0"
      :nudge-width="400"
      bottom
      class=""
      left
      offset-y
    >
      <template #activator="{on}">
        <v-btn
          class="orange grey--text text--darken-3 elevation-0 pa-0"
          height="48"
          v-on="on"
        >
          <div
            class=""
            style="position: relative; display: table-cell; text-align: center; vertical-align: middle;"
          >
            <v-icon large>
              phone_in_talk
            </v-icon>
          </div>

          <v-badge
            v-if="callsQueueActiveCount"
            :class="[callsQueueActiveCount > 0 ? 'pulse-badge' : '']"
            class="badgeCallActive"
            color="green"
            overlap
            right
          >
            <template #badge>
              <span>{{ callsQueueActiveCount }}</span>
            </template>
          </v-badge>

          <v-badge
            v-if="callsQueueWaitingCount"
            :class="[callsQueueWaitingCount > 0 ? 'pulse-badge' : '']"
            class="badgeCallWaiting"
            color="blue"
            overlap
            right
          >
            <template #badge>
              <span>{{ callsQueueWaitingCount }}</span>
            </template>
          </v-badge>

          <v-badge
            v-if="callsQueueMissedCount"
            :class="[callsQueueMissedCount > 0 ? 'pulse-badge' : '']"
            class="badgeCallMissed"
            color="red"
            overlap
            right
          >
            <template #badge>
              <span>{{ callsQueueMissedCount }}</span>
            </template>
          </v-badge>
        </v-btn>
      </template>

      <v-card dark>
        <v-toolbar
          id="callQueueCardToolbar"
          class="mb-1"
          color="grey darken-3"
        >
          <v-toolbar-title>{{ $t('Common.CallsQueue.Toolbar.Title') }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            v-if="posUserHasComponentPermission('VoipCallHistory', 'ACCESS') && $route.name!=='VoipCallHistory'"
            :exact="true"
            :to="{name: 'VoipCallHistory'}"
            class="blue white--text"
            text
          >
            {{ $t('Common.CallsQueue.Toolbar.Button.Title') }}
          </v-btn>
        </v-toolbar>

        <v-list
          id="callQueueActiveCallsList"
          dark
          subheader
          two-line
        >
          <v-subheader
            v-if="callsQueueActiveCount > 0"
            class="light-green--text"
          >
            {{ callsQueueActiveCount }} {{ $tc('Common.CallsQueue.ListView.Title', callsQueueActiveCount) }} {{ $t('Common.CallsQueue.ListView.InProgress.Title') }}
          </v-subheader>

          <v-subheader
            v-else
            class="light-green--text"
          >
            <v-icon>{{ $t('Common.CallsQueue.ListView.InProgress.Icon') }}</v-icon>
            <span class="ml-2">{{ $t('Common.CallsQueue.ListView.NoCall') }} {{ $t('Common.CallsQueue.ListView.InProgress.Title') }}</span>
          </v-subheader>

          <div
            v-if="callsQueueActiveCount > 0"
            :style="'min-height:74px; max-height: ' + (maxContainerHeight) + 'px; ' + 'overflow-y: auto;'"
          >
            <template v-for="(call, index) in callsQueueActive">
              <v-list-item :key="index">
                <v-list-item-avatar>
                  <v-icon
                    class="green"
                    dark
                  >
                    phone_in_talk
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ call.Customer ? Array.isArray(call.Customer) && call.Customer.length > 1 ? call.Customer.length + ' ' + $t('Common.CallsQueue.ListView.Common.PotentialCustomer') : call.Customer[0].Firstname + ' ' + call.Customer[0].Lastname : $t('Common.CallsQueue.ListView.Common.UnknownCustomer') }}
                  </v-list-item-title>

                  <v-list-item-subtitle v-if="isNaN(call.CallerID)">
                    {{ $t('Common.CallsQueue.ListView.Common.HiddenCallerID') }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-else>
                    {{ call.CallerID | phone }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-if="!!call.Agent"
                    class="text-caption green--text--"
                  >
                    <v-icon
                      x-small
                      color="green"
                      class="pr-1"
                    >
                      mdi-headphones
                    </v-icon>

                    <span>{{ call.Agent }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="posUserHasComponentPermission('Pos', 'ACCESS') && posUserHasComponentPermission('Orders', 'MANAGE') && $route.name!=='Pos'">
                  <v-tooltip
                    color="green darken-1"
                    left
                  >
                    <template #activator="{on}">
                      <v-btn
                        color="green"
                        icon
                        ripple
                        small
                        v-on="on"
                        @click="openNewOrder(call)"
                      >
                        <v-icon>add_shopping_cart</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Common.CallsQueue.ListView.Common.NewOrder') }}</span>
                  </v-tooltip>
                </v-list-item-action>

                <v-list-item-action v-if="posUserHasComponentPermission('TableReservation', 'MANAGE')">
                  <v-tooltip
                    color="indigo darken-1"
                    left
                  >
                    <template #activator="{on}">
                      <v-btn
                        color="indigo"
                        icon
                        ripple
                        small
                        v-on="on"
                        @click="openNewReservation(call)"
                      >
                        <v-icon>mdi mdi-book-open-variant</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Common.CallsQueue.ListView.Common.NewReservation') }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-divider
                v-if="index < callsQueueActive.length-1"
                :key="index + 'active_calls_divider'"
              />
            </template>
          </div>
        </v-list>

        <v-divider dark />

        <v-list
          id="callQueueWaitingCallsList"
          dark
          subheader
          two-line
        >
          <v-subheader
            v-if="callsQueueWaitingCount > 0"
            class="light-blue--text"
          >
            {{ callsQueueWaitingCount }} {{ $tc('Common.CallsQueue.ListView.Title', callsQueueWaitingCount) }} {{ $t('Common.CallsQueue.ListView.OnHold.Title') }}
          </v-subheader>

          <v-subheader
            v-else
            class="light-blue--text"
          >
            <v-icon>{{ $t('Common.CallsQueue.ListView.OnHold.Icon') }}</v-icon>
            <span class="ml-2">{{ $t('Common.CallsQueue.ListView.NoCall') }} {{ $t('Common.CallsQueue.ListView.OnHold.Title') }}</span>
          </v-subheader>
          <div
            v-if="callsQueueWaitingCount > 0"
            :style="'min-height:74px; max-height: ' + (maxContainerHeight) + 'px; ' + 'overflow-y: auto;'"
          >
            <template v-for="(call, index) in callsQueueWaiting">
              <v-list-item :key="index">
                <v-list-item-avatar>
                  <v-icon
                    class="blue"
                    dark
                  >
                    phone_paused
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ call.Customer ? Array.isArray(call.Customer) && call.Customer.length > 1 ? call.Customer.length + ' ' + $t('Common.CallsQueue.ListView.Common.PotentialCustomer') : call.Customer[0].Firstname + ' ' + call.Customer[0].Lastname : $t('Common.CallsQueue.ListView.Common.UnknownCustomer') }}</v-list-item-title>

                  <v-list-item-subtitle v-if="isNaN(call.CallerID)">
                    {{ $t('Common.CallsQueue.ListView.Common.HiddenCallerID') }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-else>
                    {{ call.CallerID | phone }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="index < callsQueueWaiting.length-1"
                :key="index + '_waiting_calls_divider'"
              />
            </template>
          </div>
        </v-list>

        <v-divider dark />

        <v-list
          id="callQueueMissedCallsList"
          dark
          subheader
          two-line
        >
          <v-subheader
            v-if="callsQueueMissedCount > 0"
            class="red--text text--lighten-1"
          >
            {{ callsQueueMissedCount }} {{ $tc('Common.CallsQueue.ListView.Missed.Title', callsQueueMissedCount) }}
            <v-spacer />

            <v-tooltip
              color="red"
              left
              open-delay="1000"
            >
              <template #activator="{on}">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="dismissMissedCalls()"
                >
                  <v-icon
                    :color="$t('Common.CallsQueue.ListView.Button.Dismiss.Color')"
                  >
                    {{ $t('Common.CallsQueue.ListView.Button.Dismiss.Icon') }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Common.CallsQueue.ListView.Button.Dismiss.Title') }}</span>
            </v-tooltip>
          </v-subheader>

          <v-subheader
            v-else
            class="red--text text--lighten-1"
          >
            <v-icon>{{ $t('Common.CallsQueue.ListView.Missed.Icon') }}</v-icon>
            <span class="ml-2">{{ $t('Common.CallsQueue.ListView.NoCall') }} {{ $tc('Common.CallsQueue.ListView.Missed.Title', 0) }}</span>
          </v-subheader>

          <div
            v-if="callsQueueMissedCount > 0"
            :style="'min-height:74px; max-height: ' + (maxContainerHeight) + 'px; ' + 'overflow-y: auto;'"
          >
            <template v-for="(call, index) in callsQueueMissed">
              <v-list-item
                v-if="!call.Dismiss"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-icon
                    class="red"
                    dark
                  >
                    phone_missed
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ -1 > call.CustomerId ? Math.abs(call.CustomerId) + ' ' + $t('Common.CallsQueue.ListView.Common.PotentialCustomer') : call.CustomerId === 0 ? $t('Common.CallsQueue.ListView.Common.UnknownCustomer') : call.CustomerName }}</v-list-item-title>

                  <v-list-item-subtitle v-if="call.CallerId === 'unknown' || call.CallerId === 'anonymous'">
                    {{ $t('Common.CallsQueue.ListView.Common.HiddenCallerID') }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-else>
                    {{ call.CallerId | phone }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <div>
                    <div class="text-no-wrap">
                      {{ call.CreatedAt | dateTime('HH:mm:ss') }}
                    </div>

                    <div class="text-caption grey--text">
                      {{ call.CreatedAt | dateTime('DD-MM-YYYY') }}
                    </div>
                  </div>
                </v-list-item-action>

                <v-list-item-action style="min-width: 28px;">
                  <v-tooltip
                    color="orange"
                    left
                    open-delay="1000"
                  >
                    <template #activator="{on}">
                      <v-btn
                        color="orange"
                        icon
                        ripple
                        small
                        v-on="on"
                        @click="dismissMissedCall(call)"
                      >
                        <v-icon small>
                          {{ $t('Common.CallsQueue.ListView.Button.Dismiss.Icon') }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Common.Button.Dismiss') }}</span>
                  </v-tooltip>

                  <template v-if="posUserHasComponentPermission('Pos', 'ACCESS') && posUserHasComponentPermission('Orders', 'MANAGE') && $route.name!=='Pos'">
                    <v-tooltip
                      color="green darken-1"
                      left
                      open-delay="1000"
                    >
                      <template #activator="{on}">
                        <v-btn
                          color="green"
                          icon
                          ripple
                          small
                          v-on="on"
                          @click="openNewOrderFromMissedCall(call)"
                        >
                          <v-icon small>
                            {{ $t('Common.CallsQueue.ListView.Button.DismissAndNewOrder.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('Common.CallsQueue.ListView.Button.DismissAndNewOrder.Title') }}</span>
                    </v-tooltip>
                  </template>

                  <template v-if="posUserHasComponentPermission('TableReservation', 'MANAGE')">
                    <v-tooltip
                      color="indigo darken-1"
                      left
                      open-delay="1000"
                    >
                      <template #activator="{on}">
                        <v-btn
                          color="indigo"
                          icon
                          ripple
                          small
                          v-on="on"
                          @click="openNewReservationFromMissedCall(call)"
                        >
                          <v-icon small>
                            {{ $t('Common.CallsQueue.ListView.Button.DismissAndNewReservation.Icon') }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('Common.CallsQueue.ListView.Button.DismissAndNewReservation.Title') }}</span>
                    </v-tooltip>
                  </template>
                </v-list-item-action>
              </v-list-item>

              <v-divider
                v-if="index < callsQueueMissed.length-1 && !call.Dismiss"
                :key="index + '_missed_calls_divider'"
              />
            </template>
          </div>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment        from 'moment'
import Resizable     from '@/mixins/resizable'
import AppData       from '@/mixins/appdata'
import Auth          from '@/mixins/auth'
import { clone }     from '@/lib/helper/helper'
import OrderTypeEnum from '@/api/Enums/OrderTypeEnum'

export default {
  mixins: [AppData, Auth, Resizable],
  props : [],
  data () {
    return {
      showMenuCallQueue : false,
      maxContainerHeight: 74
    }
  },
  computed: {
    callsQueueMissed: {
      get () {
        return window.Vue.callsQueueMissed || []
      },
      set (val) {
        this.$set(window.Vue, 'callsQueueMissed', val)
      }
    },
    callsQueueWaiting: {
      get () {
        return window.Vue.callsQueueWaiting || []
      },
      set (val) {
        this.$set(window.Vue, 'callsQueueWaiting', val)
      }
    },
    callsQueueActive: {
      get () {
        return window.Vue.callsQueueActive || []
      },
      set (val) {
        this.$set(window.Vue, 'callsQueueActive', val)
      }
    },
    callsQueueMissedCount () {
      let i = 0
      this.callsQueueMissed.forEach(c => {
        if (!c.Dismiss) i++
      })
      return i
    },
    callsQueueWaitingCount () {
      return this.callsQueueWaiting.length
    },
    callsQueueActiveCount () {
      return this.callsQueueActive.length
    }
  },
  watch: {
    callsQueueWaitingCount: function (newVal) {
      if (newVal <= 0) this.showMenuCallQueue = false
    },
    callsQueueActiveCount: function (newVal) {
      if (newVal <= 0) this.showMenuCallQueue = false
    }
  },
  created () {
    this.$bus.$on(window.SocketCommand.Voip.Incoming, this.onIncomingCall)
    this.$bus.$on(window.SocketCommand.Voip.Answer, this.onAnswerCall)
    this.$bus.$on(window.SocketCommand.Voip.Hangup, this.onHangupCall)
    this.$bus.$on(window.SocketCommand.Voip.History + '-queue', this.setMissedCallsData)
    this.$bus.$on(window.SocketCommand.Customer.Voip, this.setCustomerData)
    this.getMissedCallsData()
  },
  updated () {

  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Voip.Incoming, this.onIncomingCall)
    this.$bus.$off(window.SocketCommand.Voip.Answer, this.onAnswerCall)
    this.$bus.$off(window.SocketCommand.Voip.Hangup, this.onHangupCall)
    this.$bus.$off(window.SocketCommand.Voip.History + '-queue', this.setMissedCallsData)
    this.$bus.$off(window.SocketCommand.Customer.Voip, this.setCustomerData)
  },
  methods: {
    openNewOrder (call) {
      if (!isNaN(call.CallerID)) {
        if (call.Customer) {
          if (Array.isArray(call.Customer) && call.Customer.length === 1) {
            this.$router.push({
              name  : 'Pos',
              params: {
                orderId   : '0',
                orderType : OrderTypeEnum.DELIVERY,
                callerID  : call.CallerID,
                customerId: call.Customer[0].Id
              }
            })
            return
          }
        }
        this.$router.push({
          name  : 'Pos',
          params: {
            orderId  : '0',
            orderType: OrderTypeEnum.DELIVERY,
            callerID : call.CallerID
          }
        })
      } else {
        this.$router.push({
          name  : 'Pos',
          params: {
            orderId  : '0',
            orderType: OrderTypeEnum.DELIVERY,
            callerID : call.CallerID
          }
        })
      }
    },
    openNewReservation (call) {
      if (!isNaN(call.CallerID)) {
        if (call.Customer && Array.isArray(call.Customer)) {
          this.$bus.$emit('show-table-reservation-dialog', {
            Customer: call.Customer.length === 1 ? call.Customer[0] : null,
            CallerID: call.CallerID
          })
          return
        }
        this.$bus.$emit('show-table-reservation-dialog', {
          Customer: { Telephone: call.CallerID },
          CallerID: call.CallerID
        })
      } else {
        this.$bus.$emit('show-table-reservation-dialog', {})
      }
    },
    openNewReservationFromMissedCall (call) {
      if (!isNaN(call.CallerId)) {
        if (call.CustomerId > -1) {
          this.$bus.$emit('show-table-reservation-dialog', {
            Customer: {
              Id       : parseInt(call.CustomerId) > 0 ? call.CustomerId : null,
              Telephone: call.CallerId || '',
              Firstname: call.CustomerFirstName || '',
              Lastname : call.CustomerLastName || '',
              Email    : call.CustomerEmail || ''
            },
            CallerID: call.CallerId
          })

          this.dismissMissedCall(call)
          return
        }

        this.$bus.$emit('show-table-reservation-dialog', { CallerID: call.CallerId })
        this.dismissMissedCall(call)
      } else {
        this.$bus.$emit('show-table-reservation-dialog', {})
        this.dismissMissedCall(call)
      }
    },
    openNewOrderFromMissedCall (call) {
      if (!isNaN(call.CallerId)) {
        if (call.CustomerId > 0) {
          this.$router.push({
            name  : 'Pos',
            params: {
              orderId   : '0',
              orderType : OrderTypeEnum.DELIVERY,
              callerID  : call.CallerId,
              customerId: call.CustomerId
            }
          })
          this.dismissMissedCall(call)
          return
        }
        this.$router.push({
          name  : 'Pos',
          params: {
            orderId  : '0',
            orderType: OrderTypeEnum.DELIVERY,
            callerID : call.CallerId
          }
        })
        this.dismissMissedCall(call)
      } else {
        this.$router.push({
          name  : 'Pos',
          params: {
            orderId  : '0',
            orderType: OrderTypeEnum.DELIVERY,
            callerID : call.CallerId
          }
        })
        this.dismissMissedCall(call)
      }
    },
    setCustomerData (data) {
      const customers = data?.items || data?.table?.items?.items || []

      if (customers.length > 0) {
        for (let i = 0; i < this.callsQueueWaiting.length; i++) {
          const call = this.callsQueueWaiting[i]
          if (call.CallerID === customers[0].Telephone || call.CallerID === customers[0].Telephone1 || call.CallerID === customers[0].Telephone2) {
            call.Customer = customers
            break
          }
        }
      }
    },
    onIncomingCall (call) {
      this.addCallToQueueWaiting(call.CallerID)
      this.showMenuCallQueue = true
      window.callAS(window.SocketCommand.Customer.Voip, {
        config       : 0,
        filter_search: call.CallerID
      })
      this.getMissedCallsData()
    },
    onAnswerCall (call) {
      const callerID = call.CallerID
      const isCurrentUser = call.Users?.find(u => u.uid === this.posUser.staff_email && u.active)
      const callAgent = this.getCallVoipAgentName(call)
      const voipAddOrderDialog = this.appConfig?.LOCATION_DATA?.VoipAddOrderDialog || 'agent' // agent, everyone, none
      const showAddOrderDialog = voipAddOrderDialog === 'everyone' || (voipAddOrderDialog === 'agent' && !!isCurrentUser)

      for (let i = 0; i < this.callsQueueWaiting.length; i++) {
        const call = this.callsQueueWaiting[i]
        if (call.CallerID === callerID) {
          this.removeCallFromQueue(callerID)
          call.Agent = callAgent
          this.callsQueueActive.push(call)

          if (showAddOrderDialog) {
            this.$bus.$emit('app-show-notification', {
              title       : (isNaN(call.CallerID) ? this.$t('Common.CallsQueue.ListView.Common.HiddenCallerID') : this.$options.filters.phone(call.CallerID)),
              body        : call.Customer ? Array.isArray(call.Customer) && call.Customer.length > 1 ? call.Customer.length + ' ' + this.$t('Common.CallsQueue.ListView.Common.PotentialCustomer') : call.Customer[0].Firstname + ' ' + call.Customer[0].Lastname : this.$t('Common.CallsQueue.ListView.Common.UnknownCustomer'),
              type        : 'info',
              timeout     : -1,
              closeOnClick: false,
              icon        : 'phone_in_talk',
              buttons     : [
                {
                  text  : this.$t('Common.Button.Close'),
                  bold  : false,
                  class : 'orange',
                  action: (toastId) => {
                    this.$snotify.remove(toastId)
                  }
                },
                {
                  text   : this.$t('Common.CallsQueue.ListView.Common.CustomerTab'),
                  bold   : true,
                  class  : 'blue',
                  visible: call.Customer ? !(Array.isArray(call.Customer) && call.Customer.length > 1) : false,
                  action : (toastId) => {
                    this.$bus.$emit('show-customer-details-dialog', call.Customer[0]?.Id)
                  }
                },
                {
                  text  : this.$t('Common.CallsQueue.ListView.Common.NewOrder'),
                  bold  : true,
                  class : 'green',
                  action: (toastId) => {
                    this.openNewOrder(call)
                    this.$snotify.remove(toastId)
                  }
                },
                ...(this.posUserHasComponentPermission('TableReservation', 'MANAGE') ? [{
                  text  : this.$t('Common.CallsQueue.ListView.Common.NewReservation'),
                  bold  : true,
                  class : 'indigo',
                  action: (toastId) => {
                    this.openNewReservation(call)
                    this.$snotify.remove(toastId)
                  }
                }] : [])
              ]
            })
          }
          break
        }
      }
      this.getMissedCallsData()
    },
    onHangupCall (call) {
      this.removeCallFromQueue(call.CallerID)
      this.getMissedCallsData()
    },
    addCallToQueueWaiting (callerID) {
      const call = {
        CallerID: callerID,
        Customer: null
      }
      this.removeCallFromQueue(callerID)
      this.callsQueueWaiting.push(call)
    },
    removeCallFromQueue (callerID) {
      this.callsQueueWaiting = this.callsQueueWaiting.filter(obj => obj.CallerID !== callerID)
      this.callsQueueActive = this.callsQueueActive.filter(obj => obj.CallerID !== callerID)
    },
    dismissMissedCall (call) {
      const callId = call.Id
      const missedCall = this.callsQueueMissed.find(mc => mc.Id === callId)

      if (missedCall) {
        missedCall.Dismiss = true
        this.callsQueueMissed = clone(this.callsQueueMissed)
      }
    },
    dismissMissedCalls () {
      this.callsQueueMissed.forEach(call => { call.Dismiss = true })
      this.callsQueueMissed = clone(this.callsQueueMissed)
    },
    getMissedCallsData () {
      window.callAS(window.SocketCommand.Voip.History, {
        config       : 0,
        page         : 1,
        page_limit   : 10,
        sort_by      : 'incoming_at',
        order_by     : 'DESC',
        filter_status: 'MISSED',
        filter_date  : moment().format('DD-MM-YYYY'),
        filter_search: ''
      }, window.SocketCommand.Voip.History + '-queue')
    },
    setMissedCallsData (data) {
      if (data && data.items) {
        data.items.forEach(call => {
          if (this.callsQueueMissed?.findIndex(mc => mc.Id === call.Id) === -1) this.callsQueueMissed.push(call)
        })

        this.callsQueueMissed.sort((a, b) => moment(b.CreatedAt, 'DD-MM-YYYY HH:mm:ss').unix() - moment(a.CreatedAt, 'DD-MM-YYYY HH:mm:ss').unix())
      }
    },
    getCallVoipAgentName (call) {
      const voipAgent = call.Users?.find(u => u.active)
      return voipAgent?.name || ''
    },
    onResize () {
      const cardToolbarHeight = document.getElementById('callQueueCardToolbar')?.offsetHeight ? document.getElementById('callQueueCardToolbar').offsetHeight : 0
      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0

      this.maxContainerHeight = (innerHeight - headerHeight - cardToolbarHeight - footerHeight - (60 * 3)) / 3
    }
  }
}
</script>

<style>
.callsQueue {
  padding : 0 !important;
}

.callsQueue, .callsQueue > div, .callsQueue > div > div, .callsQueue > div > div > button {
  height : 100% !important;
}

.callsQueue > div > div > button {
  width     : 60px;
  margin    : 0px;
  min-width : 0px;
}

.callsQueue button.btn:after {
  top : 0px;
}

.callsQueue .badge__badge {
  top  : -7px;
  left : 40px;
}

.badgeCallActive {
  position : absolute;
  top      : 4px;
  left     : 4px;
}

.badgeCallWaiting {
  position : absolute;
  top      : 28px;
  left     : 4px;
}

.badgeCallMissed {
  position : absolute;
  top      : 4px;
  right    : -2px;
}
</style>
