<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Waiter.StaffAddDialog.Add.Color')"
          >
            <v-icon dark>
              {{ $t('Waiter.StaffAddDialog.Add.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!staffToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Waiter.StaffAddDialog.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Waiter.StaffAddDialog.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Waiter.StaffAddDialog.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Waiter.StaffAddDialog.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="staffForm"
            v-model="staffFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editStaff.Firstname"
                    :counter="32"
                    :hint="$t('Waiter.StaffAddDialog.Field.Firstname.Hint')"
                    :label="$t('Waiter.StaffAddDialog.Field.Firstname.Label')"
                    :maxlength="32"
                    :prepend-icon="$t('Waiter.StaffAddDialog.Field.Firstname.Icon')"
                    :rules="validationRules.staffForm.Firstname"
                    class="pr-2"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editStaff.Lastname"
                    :counter="32"
                    :hint="$t('Waiter.StaffAddDialog.Field.Lastname.Hint')"
                    :label="$t('Waiter.StaffAddDialog.Field.Lastname.Label')"
                    :maxlength="32"
                    :prepend-icon="$t('Waiter.StaffAddDialog.Field.Lastname.Icon')"
                    :rules="validationRules.staffForm.Lastname"
                    class="pr-2"
                    required
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editStaff.Phone"
                    v-mask="'################'"
                    :hint="$t('Waiter.StaffAddDialog.Field.Telephone.Hint')"
                    :label="$t('Waiter.StaffAddDialog.Field.Telephone.Label')"
                    :prepend-icon="$t('Waiter.StaffAddDialog.Field.Telephone.Icon')"
                    :rules="validationRules.staffForm.Phone"
                    class="pr-2"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="editStaff.Locale"
                    :hint="$t('Waiter.StaffAddDialog.Field.Locale.Hint')"
                    :items="$i18n.languages"
                    :label="$t('Waiter.StaffAddDialog.Field.Locale.Label')"
                    :prepend-icon="$t('Waiter.StaffAddDialog.Field.Locale.Icon')"
                    class="pr-2"
                    item-text="name"
                    item-value="locale"
                    required
                    single-line
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editStaff.Email"
                    :hint="$t('Waiter.StaffAddDialog.Field.Email.Hint')"
                    :label="$t('Waiter.StaffAddDialog.Field.Email.Label')"
                    :persistent-hint="!staffToEdit"
                    :prepend-icon="$t('Waiter.StaffAddDialog.Field.Email.Icon')"
                    class="pr-2 d-inline-flex"
                    readonly
                    style="width: 92%;"
                  />

                  <v-btn
                    :disabled="!editStaff.Email"
                    class="ma-0"
                    icon
                    small
                    @click="copyToClipboard(editStaff.Email)"
                  >
                    <v-icon small>
                      far fa-copy
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editStaff.Pin"
                    v-mask="'####'"
                    :append-icon="!pinVisible ? 'visibility' : 'visibility_off'"
                    :counter="4"
                    :disabled="!userCanManage"
                    :hint="$t('Waiter.StaffAddDialog.Field.Pin.Hint')"
                    :label="$t('Waiter.StaffAddDialog.Field.Pin.Label')"
                    :prepend-icon="$t('Waiter.StaffAddDialog.Field.Pin.Icon')"
                    :rules="validationRules.staffForm.Pin"
                    :type="!pinVisible ? 'password' : 'text'"
                    autocomplete="one-time-code"
                    class="pr-2"
                    maxlength="4"
                    required
                    @click:append="(pinVisible = !pinVisible)"
                  >
                    <template #append-outer>
                      <v-btn
                        :disabled="!editStaff.Pin"
                        class="ma-0"
                        icon
                        small
                        @click="copyToClipboard(editStaff.Pin)"
                      >
                        <v-icon small>
                          far fa-copy
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editStaff.TableAreas"
                    :hint="$t('Waiter.StaffAddDialog.Field.TableAreas.Hint')"
                    :items="tableAreaCombo"
                    :label="$t('Waiter.StaffAddDialog.Field.TableAreas.Label')"
                    :prepend-icon="$t('Waiter.StaffAddDialog.Field.TableAreas.Icon')"
                    :rules="validationRules.staffForm.TableAreas"
                    item-text="Name"
                    item-value="Id"
                    multiple
                    persistent-hint
                  >
                    <template #selection="{item}">
                      <v-chip
                        :color="`#${item.Color}`"
                        dark
                        label
                        small
                      >
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :color="`#${data.item.Color}`"
                        :size="28"
                      />

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <div class="grey--text my-2">
                    {{ $t('Settings.Website.Field.QRCode.Color') }}
                  </div>

                  <color-picker
                    v-model="editStaff.TmpColor"
                    style="width: 100%"
                    :style="isDarkMode ? 'background-color: #191919;' : 'background-color: white;'"
                  />
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editStaff.Status"
                    :disabled="!userCanDelete || (!userIsSuperAdmin && (parseInt(editStaff.GroupId) === 17) ? parseInt(posUser.staff_id) === parseInt(editStaff.Id) : false)"
                    :label="editStaff.Status ? $t('Common.Button.Toggle.ActiveM') : $t('Common.Button.Toggle.InactiveM')"
                    class="mt-4"
                    color="success"
                    inset
                  >
                    <span
                      slot="prepend"
                      class="mt-1 grey--text"
                    >
                      {{ $t('Waiter.StaffAddDialog.Field.Status.Title') }}
                    </span>
                  </v-switch>
                </v-col>

                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Tables.Tables.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Tables.Tables.Title') }}
                      </h5>
                    </v-card-title>

                    <v-divider />

                    <v-card-text>
                      <v-container
                        class="pa-0"
                        fluid
                      >
                        <v-container
                          class="pa-0"
                          fluid
                        >
                          <v-row align="center">
                            <v-col>
                              <h5>{{ $t('Settings.Tables.Waiters.WaiterCanManageAllTables.Title') }}</h5>
                              <span class="text-caption">{{
                                $t('Settings.Tables.Waiters.WaiterCanManageAllTables.Subtitle')
                              }}</span>
                            </v-col>
                            <v-col class="shrink">
                              <v-switch
                                v-model="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanManageAllTables"
                                :disabled="!userCanManage"
                                :label="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanManageAllTables ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="mt-0 pt-0"
                                color="success"
                                hide-details
                                inset
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-divider class="" />
                            </v-col>

                            <v-col>
                              <h5>{{ $t('Settings.Tables.Waiters.WaiterCanChangeTableWaiter.Title') }}</h5>
                              <span class="text-caption">{{
                                $t('Settings.Tables.Waiters.WaiterCanChangeTableWaiter.Subtitle')
                              }}</span>
                            </v-col>
                            <v-col class="shrink">
                              <v-switch
                                v-model="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanChangeTableWaiter"
                                :disabled="!userCanManage"
                                :label="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanChangeTableWaiter ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="mt-0 pt-0"
                                color="success"
                                hide-details
                                inset
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-divider class="" />
                            </v-col>

                            <v-col>
                              <h5>{{ $t('Settings.Tables.Waiters.WaiterCanSetCustomPrice.Title') }}</h5>
                              <span class="text-caption">{{
                                $t('Settings.Tables.Waiters.WaiterCanSetCustomPrice.Subtitle')
                              }}</span>
                            </v-col>
                            <v-col class="shrink">
                              <v-switch
                                v-model="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanSetCustomPrice"
                                :disabled="!userCanManage"
                                :label="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanSetCustomPrice ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="mt-0 pt-0"
                                color="success"
                                hide-details
                                inset
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-divider class="" />
                            </v-col>

                            <v-col>
                              <h5>{{ $t('Settings.Tables.Waiters.WaiterCanChangeExternalReceipt.Title') }}</h5>
                              <span class="text-caption">{{
                                $t('Settings.Tables.Waiters.WaiterCanChangeExternalReceipt.Subtitle')
                              }}</span>
                            </v-col>
                            <v-col class="shrink">
                              <v-switch
                                v-model="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanChangeExternalReceipt"
                                :disabled="!userCanManage"
                                :label="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanChangeExternalReceipt ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="mt-0 pt-0"
                                color="success"
                                hide-details
                                inset
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Tables.Orders.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Tables.Orders.Title') }}
                      </h5>
                    </v-card-title>

                    <v-divider />

                    <v-card-text>
                      <v-container
                        class="pa-0"
                        fluid
                      >
                        <v-row align="center">
                          <v-col>
                            <h5>{{ $t('Settings.Tables.Waiters.WaiterCanManagePos.Title') }}</h5>
                            <span class="text-caption">{{
                              $t('Settings.Tables.Waiters.WaiterCanManagePos.Subtitle')
                            }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanManagePos"
                              :disabled="!userCanManage"
                              :label="editStaff.Settings.Tables.Waiter.Permissions.WaiterCanManagePos ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="mt-0 pt-0"
                              color="success"
                              hide-details
                              inset
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5>{{ $t('Settings.Tables.Orders.Permission.Daily.Title') }}</h5>
                            <span class="text-caption">{{
                              $t('Settings.Tables.Orders.Permission.Daily.Subtitle')
                            }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="editStaff.Settings.Orders.Permissions.Daily"
                              :disabled="!userCanManage"
                              :label="editStaff.Settings.Orders.Permissions.Daily ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="mt-0 pt-0"
                              color="success"
                              hide-details
                              inset
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-select
                              v-model="editStaff.Settings.Orders.VisibleStatuses"
                              :hint="$t('Settings.Tables.Orders.VisibleStatuses.Subtitle')"
                              :items="$t('Order.OrdersAll.Filter.filterOrderStatus.Items').filter(o => o.Id > 0)"
                              :label="$t('Settings.Tables.Orders.VisibleStatuses.Title')"
                              :rules="validationRules.staffForm.TableAreas"
                              item-text="Name"
                              item-value="Id"
                              multiple
                              persistent-hint
                            >
                              <template #selection="{item}">
                                <v-chip
                                  :color="`${item.Avatar.Color}`"
                                  class="px-1"
                                  dark
                                  label
                                  small
                                >
                                  <v-icon
                                    class="mr-1"
                                    small
                                  >
                                    {{ item.Avatar.Icon }}
                                  </v-icon>
                                  <span>{{ item.Name }}</span>
                                </v-chip>
                              </template>

                              <template #item="data">
                                <v-list-item-avatar
                                  :color="`${data.item.Avatar.Color}`"
                                  :size="28"
                                  dark
                                >
                                  <v-icon
                                    color="white"
                                    small
                                  >
                                    {{ data.item.Avatar.Icon }}
                                  </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5>{{ $t('Settings.Tables.Orders.Permission.Saved.Title') }}</h5>
                            <span class="text-caption">{{
                              $t('Settings.Tables.Orders.Permission.Saved.Subtitle')
                            }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="editStaff.Settings.Orders.Permissions.Saved"
                              :disabled="!userCanManage"
                              :label="editStaff.Settings.Orders.Permissions.Saved ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="mt-0 pt-0"
                              color="success"
                              hide-details
                              inset
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5>{{ $t('Settings.Tables.Orders.Permission.All.Title') }}</h5>
                            <span class="text-caption">{{
                              $t('Settings.Tables.Orders.Permission.All.Subtitle')
                            }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="editStaff.Settings.Orders.Permissions.All"
                              :disabled="!userCanManage"
                              :label="editStaff.Settings.Orders.Permissions.All ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="mt-0 pt-0"
                              color="success"
                              hide-details
                              inset
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        lock
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.PinManager.Enabled.Title') }}
                      </h5>
                    </v-card-title>

                    <v-divider />

                    <v-card-text>
                      <v-container
                        class="pa-0"
                        fluid
                      >
                        <v-row align="center">
                          <v-col>
                            <h5>{{ $t('Settings.Tables.Orders.Permission.ExcludeFromPin.Title') }}</h5>
                            <span class="text-caption">{{
                              $t('Settings.Tables.Orders.Permission.ExcludeFromPin.Subtitle')
                            }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="editStaff.Settings.ExcludeFromPin"
                              :disabled="!userCanManage"
                              :label="editStaff.Settings.ExcludeFromPin ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="mt-0 pt-0"
                              color="success"
                              hide-details
                              inset
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData         from '@/mixins/appdata'
import Auth            from '@/mixins/auth'
import SocketData      from '@/api/SocketData'
import { Twitter }     from 'vue-color'
import OrderStatusEnum from '@/api/Enums/OrderStatusEnum'

export default {
  name      : 'WaiterStaffAddDialog',
  components: {
    'color-picker': Twitter
  },
  mixins: [AppData, Auth],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    staffToEdit: {
      type   : Object,
      default: undefined
    },
    tableAreaCombo: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      loading       : false,
      pinVisible    : false,
      staffFormValid: false,
      newStaff      : null,
      tmpStaff      : {
        Id       : null,
        Firstname: '',
        Lastname : '',
        Email    : '',
        Pin      : '',
        Status   : true,
        Phone    : '',
        Locale   : 'el',
        Color    : '',
        TmpColor : {
          hex   : '',
          source: ''
        },
        TableAreas: [0],
        Settings  : {
          ExcludeFromPin: false,
          Tables        : {
            Waiter: {
              Permissions: {
                WaiterCanManagePos            : false,
                WaiterCanChangeTableWaiter    : false,
                WaiterCanManageAllTables      : false,
                WaiterCanSetCustomPrice       : false,
                WaiterCanChangeExternalReceipt: false
              }
            }
          },
          Orders: {
            Permissions: {
              Daily: true,
              Saved: true,
              All  : true
            },
            VisibleStatuses: [OrderStatusEnum.Received, OrderStatusEnum.Accepted, OrderStatusEnum.Preparation, OrderStatusEnum.Delivery, OrderStatusEnum.Completed, OrderStatusEnum.Canceled]
          }
        }
      },
      validationRules: {
        staffForm: {
          Firstname: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Waiter.StaffAddDialog.Field.Firstname.Error.Between')
          ],
          Lastname: [
            (v) => !v || (v.length >= 2 && v.length <= 32) || this.$t('Waiter.StaffAddDialog.Field.Lastname.Error.Between')
          ],
          Phone: [
            (v) => !v || (v.length >= 8 && v.length <= 16 && !isNaN(v)) || this.$t('Waiter.StaffAddDialog.Field.Telephone.Error.Between')
          ],
          Pin: [
            (v) => v && v.length === 4 && !isNaN(v) || this.$t('Waiter.StaffAddDialog.Field.Pin.Error.FixedNumber')
          ],
          TableAreas: [
            (v) => v && v.length >= 1 || this.$t('Waiter.StaffAddDialog.Field.TableAreas.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editStaff: {
      get () {
        if (this.staffToEdit) {
          const staff = JSON.parse(JSON.stringify(this.staffToEdit))
          const c = JSON.parse(JSON.stringify(this.staffToEdit)).Color
          staff.TmpColor = {
            hex   : c ? '#' + c : '',
            source: 'hex'
          }
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.newStaff = staff
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.pinVisible = true
          const staff = JSON.parse(JSON.stringify(this.tmpStaff))
          const c = Math.floor(Math.random() * 16777215).toString(16)
          staff.TmpColor = {
            hex   : c ? '#' + c : '',
            source: 'hex'
          }
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.newStaff = staff
        }
        return this.newStaff
      }
    }
  },
  watch: {
    'editStaff.TableAreas': function (newVal, oldVal) {
      if (newVal.length > 1) {
        if (newVal.includes(0) && !oldVal.includes(0)) {
          this.editStaff.TableAreas = [0]
        } else if (newVal.includes(0)) {
          this.editStaff.TableAreas = newVal.filter(tableArea => tableArea !== 0)
        }
      }
    },

    isVisible (newVal) {
      if (newVal) {
        this.pinVisible = false
        this.$bus.$on(window.SocketCommand.Staff.Waiter.Save, this.onStaffSaveResult)
        this.$nextTick(() => {
          this.$refs.staffForm && this.$refs.staffForm.resetValidation()
        })
      } else {
        this.$bus.$off(window.SocketCommand.Staff.Waiter.Save, this.onStaffSaveResult)
      }
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.staffForm && this.$refs.staffForm.validate()

      if (this.staffFormValid) {
        this.loading = true
        this.editStaff.Color = JSON.parse(JSON.stringify(this.editStaff)).TmpColor.hex.replace('#', '')
        this.editStaff.Name = String(this.editStaff.Firstname + ' ' + this.editStaff.Lastname).trim()
        window.callAS(window.SocketCommand.Staff.Waiter.Save, this.editStaff)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onCancelButtonClick () {
      this.$refs.staffForm && this.$refs.staffForm.resetValidation()
      this.isVisible = false
      this.newStaff = null
    },

    onStaffSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
        if (Array.isArray(data.result) && data.result.length) {
          SocketData.set(window.SocketCommand.Staff.Waiter.All, data)
        }
      } else {
        if (data && data.message) {
          this.$bus.$emit('app-show-notification', {
            body: data.message,
            type: 'error',
            icon: 'warning'
          })
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.GenericError'),
            type: 'error',
            icon: 'warning'
          })
        }
      }
      this.loading = false
    }

  }
}
</script>
