<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="960"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.CategoryAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.CategoryAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!categoryToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.CategoryAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.CategoryAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.CategoryAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.CategoryAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="categoryForm"
            v-model="categoryFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="selectedLanguages.length > 1">
                    <v-tabs
                      v-model="languageTab"
                      :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                      grow
                      height="40"
                      slider-color="blue"
                    >
                      <template v-for="lang in selectedLanguages">
                        <v-tab
                          :key="lang.Code"
                          :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                        >
                          <v-avatar
                            v-if="lang.ImageUrl"
                            class="mr-2"
                            size="20"
                          >
                            <v-img :src="lang.ImageUrl" />
                          </v-avatar>
                          {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                        </v-tab>
                      </template>
                    </v-tabs>
                  </template>

                  <v-tabs-items v-model="languageTab">
                    <template v-for="lang in selectedLanguages">
                      <v-tab-item
                        :key="lang.Code"
                        eager
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editCategory.Lang[lang.Code].Name"
                              :counter="255"
                              :maxlength="255"
                              :hint="$t('Restaurant.CategoryAddDialog.Field.Name.Hint')"
                              :label="$t('Restaurant.CategoryAddDialog.Field.Name.Label')"
                              :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Name.Icon')"
                              :rules="validationRules.categoryForm.Name"
                              class="pr-2"
                              required
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editCategory"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Name"
                                />
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editCategory.Lang[lang.Code].Description"
                              :counter="255"
                              :maxlength="255"
                              :hint="$t('Restaurant.CategoryAddDialog.Field.Description.Hint')"
                              :label="$t('Restaurant.CategoryAddDialog.Field.Description.Label')"
                              :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Description.Icon')"
                              :rules="validationRules.categoryForm.Description"
                              class="pr-2"
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editCategory"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Description"
                                />
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </template>
                  </v-tabs-items>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editCategory.Priority"
                    :hint="$t('Restaurant.CategoryAddDialog.Field.Priority.Hint')"
                    :label="$t('Restaurant.CategoryAddDialog.Field.Priority.Label')"
                    :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Priority.Icon')"
                    :rules="validationRules.categoryForm.Priority"
                    type="number"
                    step="1"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="editCategory.Catalogs"
                    :hint="$t('Restaurant.CategoryAddDialog.Field.Catalogs.Hint')"
                    :items="catalogs"
                    :label="$t('Restaurant.CategoryAddDialog.Field.Catalogs.Label')"
                    :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Catalogs.Icon')"
                    :rules="validationRules.categoryForm.Catalogs"
                    persistent-hint
                    item-text="Name"
                    item-value="Id"
                    multiple
                  >
                    <template #selection="{item}">
                      <v-chip
                        small
                        label
                        :color="`#${item.Color}`"
                        dark
                      >
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :color="`#${data.item.Color}`"
                        :size="28"
                      />

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  offset-md="1"
                >
                  <v-subheader
                    class="pl-0 mt-2"
                    :class="{'red--text': editCategory.MenusCatalogsUpdate}"
                  >
                    {{ $t('Restaurant.CategoryAddDialog.Field.MenusCatalogsUpdate.Label') }}
                  </v-subheader>
                  <v-switch
                    v-model="editCategory.MenusCatalogsUpdate"
                    inset
                    :label="editCategory.MenusCatalogsUpdate ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    class="pa-0 ma-0"
                    color="red"
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <template v-if="preparationDocumentEnabled">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="editCategory.PrintGroup"
                      :hint="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Hint')"
                      :items="appPrinterGroups"
                      :label="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Label')"
                      :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Icon')"
                      :rules="validationRules.categoryForm.PrintGroup"
                      persistent-hint
                      item-text="Name"
                      item-value="Id"
                      multiple
                    >
                      <template #selection="{item, index}">
                        <v-chip
                          v-if="index < 2"
                          small
                          label
                        >
                          <span>{{ item.Name }}</span>
                        </v-chip>
                        <span
                          v-if="index > 1"
                          class="grey--text text-caption"
                        >(+{{ editCategory.PrintGroup.length - 2 }})</span>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="5"
                    offset-md="1"
                  >
                    <v-subheader
                      class="pl-0 mt-2"
                      :class="{'red--text': editCategory.PrintGroupUpdate}"
                    >
                      {{ $t('Restaurant.CategoryAddDialog.Field.PrintGroupUpdate.Label') }}
                    </v-subheader>
                    <v-switch
                      v-model="editCategory.PrintGroupUpdate"
                      inset
                      :label="editCategory.PrintGroupUpdate ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                      class="pa-0 ma-0"
                      color="red"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </template>

                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold mt-2">
                    {{ $t('Restaurant.CategoryAddDialog.Field.Tax.Label') }}
                  </h5>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="editCategory.TaxId"
                    :hint="$t('Restaurant.CategoryAddDialog.Field.Tax.Hint')"
                    :items="taxesList"
                    :label="$t('Restaurant.CategoryAddDialog.Field.Tax.Label')"
                    :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Tax.Icon')"
                    :rules="validationRules.categoryForm.TaxId"
                    class="mt-2"
                    item-text="Name"
                    item-value="Id"
                    persistent-hint
                    clearable
                    required
                  >
                    <template #selection="{item}">
                      {{ getTranslatedField(item, 'Name') }}
                    </template>
                  </v-select>

                  <v-select
                    v-if="appSupportSecondTax"
                    v-model="editCategory.Tax2Id"
                    :hint="$t('Restaurant.CategoryAddDialog.Field.Tax2.Hint')"
                    :items="taxesList"
                    :label="$t('Restaurant.CategoryAddDialog.Field.Tax2.Label')"
                    :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Tax2.Icon')"
                    :rules="validationRules.categoryForm.Tax2Id"
                    class="mt-4"
                    item-text="Name"
                    item-value="Id"
                    persistent-hint
                    clearable
                    required
                  >
                    <template #selection="{item}">
                      {{ getTranslatedField(item, 'Name') }}
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  offset-md="1"
                >
                  <v-subheader
                    class="pl-0 mt-2"
                    :class="{'red--text': editCategory.TaxUpdate}"
                  >
                    {{ $t('Restaurant.CategoryAddDialog.Field.TaxUpdate.Label') }}
                  </v-subheader>
                  <v-switch
                    v-model="editCategory.TaxUpdate"
                    inset
                    :label="editCategory.TaxUpdate ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    class="pa-0 ma-0"
                    color="red"
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>

                <template v-if="appSupportIncomeClassifications">
                  <v-col cols="12">
                    <h5 class="text-body-2 font-weight-bold mt-2">
                      {{ $t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Title') }}
                    </h5>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <h5 class="text-body-2 font-weight-bold mt-1">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Receipt') }}
                    </h5>

                    <v-select
                      v-model="editCategory.ClassificationCategoryCode"
                      :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                      :items="incomeClassificationCategoryCodeList"
                      :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                      :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Icon')"
                      :rules="validationRules.categoryForm.ClassificationCategoryCode"
                      class="mt-2"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Code }}</v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>

                    <v-select
                      v-model="editCategory.ClassificationTypeCode"
                      :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                      :items="getReceiptIncomeClassificationTypesByCategoryCode(editCategory.ClassificationCategoryCode)"
                      :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                      :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Icon')"
                      :rules="validationRules.categoryForm.ClassificationTypeCode"
                      class="mt-4"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.Code }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="5"
                    offset-md="1"
                    order-md="0"
                    order="2"
                  >
                    <v-subheader
                      class="pl-0 mt-2"
                      :class="{'red--text': editCategory.ClassificationUpdate}"
                    >
                      {{ $t('Restaurant.CategoryAddDialog.Field.ClassificationUpdate.Label') }}
                    </v-subheader>
                    <v-switch
                      v-model="editCategory.ClassificationUpdate"
                      inset
                      :label="editCategory.ClassificationUpdate ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                      class="pa-0 ma-0"
                      color="red"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <h5 class="text-body-2 font-weight-bold mt-4">
                      {{ $t('Settings.Documents.OrderReceipt.Document.Invoice') }}
                    </h5>

                    <v-select
                      v-model="editCategory.ClassificationCategoryCodeInvoice"
                      :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                      :items="incomeClassificationCategoryCodeList"
                      :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                      :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Icon')"
                      :rules="validationRules.categoryForm.ClassificationCategoryCode"
                      class="mt-2"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Code }}</v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>

                    <v-select
                      v-model="editCategory.ClassificationTypeCodeInvoice"
                      :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                      :items="getInvoiceIncomeClassificationTypesByCategoryCode(editCategory.ClassificationCategoryCodeInvoice)"
                      :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                      :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Icon')"
                      :rules="validationRules.categoryForm.ClassificationTypeCode"
                      class="mt-4"
                      item-text="Name"
                      item-value="Code"
                      persistent-hint
                      clearable
                      required
                    >
                      <template #selection="{item}">
                        <v-chip
                          small
                          label
                          color="grey darken-1"
                          dark
                        >
                          <span>{{ item.Code }}</span>
                        </v-chip>

                        <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.Code }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </template>
              </v-row>

              <v-row dense>
                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>
                  <v-switch
                    :id="editCategory.Id + '_status'"
                    v-model="editCategory.Status"
                    inset
                    :label="editCategory.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>
                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.CategoryAddDialog.Field.Visibility.Label') }}
                  </v-subheader>
                  <v-switch
                    :id="editCategory.Id + '_visible'"
                    v-model="editCategory.Visible"
                    inset
                    :label="editCategory.Visible ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>
                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.CategoryAddDialog.Field.Featured.Label') }}
                  </v-subheader>
                  <v-switch
                    :id="editCategory.Id + '_featured'"
                    v-model="editCategory.Featured"
                    inset
                    :label="editCategory.Featured ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                >
                  <div class="text-center">
                    <div class="grey--text pl-1 pb-2 text-left">
                      {{ $t('Restaurant.CategoryAddDialog.Field.Image.Label') }}
                    </div>

                    <image-crop-selector
                      v-model="croppa"
                      :height="400"
                      :initial-image="editCategory.Image ? appConfig.LOCATION_DATA.CdnImagesUrl + editCategory.Image : ''"
                      :placeholder="`${$t('Settings.Website.Field.Gallery.Image.Hint.Drag')} ${$t('Settings.Website.Field.Gallery.Image.Hint.Click')}`"
                      :placeholder-font-size="32"
                      :prevent-white-space="true"
                      :replace-drop="true"
                      :show-loading="true"
                      :width="1460"
                      :excluded-gallery-tabs="[]"
                      default-gallery-tab="category"
                      gallery-enabled
                      @new-image-drawn="onPhotoChange"
                      @image-remove="onPhotoRemoved"
                    />
                  </div>
                </v-col>

                <v-col cols="12">
                  <div class="grey--text pt-2">
                    {{ $t('Restaurant.CategoryAddDialog.Field.Color.Label') }}
                  </div>
                  <color-picker
                    v-model="editCategory.TmpColor"
                    style="width: 100%"
                    :style="isDarkMode ? 'background-color: #191919;' : 'background-color: white;'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <translate-all-fields-button
            v-if="selectedLanguages.length > 1"
            :disabled="loading"
            :item="editCategory"
            :locale-from="defaultLanguage.Code"
            :field="['Name', 'Description']"
          />

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Swatches }             from 'vue-color'
import ImageCropSelector        from '@/components/common/ImageCropSelector'
import AppData                  from '@/mixins/appdata'
import Auth                     from '@/mixins/auth'
import Translatable             from '@/mixins/translatable'
import TaxesCommon              from '@/mixins/taxes/taxesCommon'
import { clone }                from '@/lib/helper/helper'
import TranslateFieldButton     from '@/components/common/translation/translateFieldButton.vue'
import TranslateAllFieldsButton from '@/components/common/translation/translateAllFieldsButton.vue'

export default {
  components: {
    TranslateAllFieldsButton,
    TranslateFieldButton,
    'color-picker'       : Swatches,
    'image-crop-selector': ImageCropSelector
  },
  mixins: [AppData, Auth, TaxesCommon, Translatable],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    categoryToEdit: {
      type   : Object,
      default: undefined
    },
    taxes: {
      type   : Array,
      default: () => []
    },
    catalogs: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      languageTab      : 0,
      loading          : false,
      categoryFormValid: false,
      newCategory      : null,
      croppa           : null,
      tmpImage         : null,
      tmpImageBase64   : null,
      tmpCategory      : {
        Id      : null,
        Color   : '',
        TmpColor: {
          hex   : '',
          source: ''
        },
        Lang                                : {},
        Name                                : '',
        Description                         : '',
        ParentId                            : '-1',
        Image                               : '',
        Priority                            : '1',
        Visible                             : true,
        Status                              : true,
        Featured                            : false,
        Catalogs                            : [],
        MenusCatalogsUpdate                 : false,
        PrintGroup                          : [],
        PrintGroupOld                       : null,
        PrintGroupUpdate                    : false,
        TaxId                               : null,
        TaxIdOld                            : null,
        TaxLang                             : null,
        TaxRate                             : null,
        Tax2Id                              : null,
        Tax2IdOld                           : null,
        Tax2Lang                            : null,
        Tax2Rate                            : null,
        TaxUpdate                           : false,
        ClassificationTypeCode              : null,
        ClassificationTypeCodeOld           : null,
        ClassificationTypeCodeInvoice       : null,
        ClassificationTypeCodeInvoiceOld    : null,
        ClassificationCategoryCode          : null,
        ClassificationCategoryCodeOld       : null,
        ClassificationCategoryCodeInvoice   : null,
        ClassificationCategoryCodeInvoiceOld: null,
        ClassificationUpdate                : false
      },
      validationRules: {
        categoryForm: {
          Name: [
            (v) => (this.selectedLanguages.length > 1 ? this.selectedLanguages.some(lang => this.editCategory.Lang[lang.Code].Name) : true) || this.$t('Restaurant.CategoryAddDialog.Field.Name.Error.AtLeastOneLang'),
            (v) => (this.selectedLanguages.length > 1 ? !v || (v.length >= 2 && v.length <= 255) : v && (v.length >= 2 && v.length <= 255)) || this.$t('Restaurant.CategoryAddDialog.Field.Name.Error.Between')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 255) || this.$t('Restaurant.CategoryAddDialog.Field.Description.Error.Between')
          ],
          Priority: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Restaurant.CategoryAddDialog.Field.Priority.Error.Valid')
          ],
          Catalogs: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.CategoryAddDialog.Field.Catalogs.Error.Required')
          ],
          PrintGroup: [
            // (v) => !this.preparationDocumentEnabled || (this.preparationDocumentEnabled && v && v.length >= 1) || this.$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Error.Required')
          ],

          TaxId: [
            (v) => !!v || this.$t('Restaurant.CategoryAddDialog.Field.Tax.Error.Required')
          ],
          Tax2Id: [
            (v) => !this.appSupportSecondTax || (this.posUserCan('Tables', 'ACCESS') && !!v) || this.$t('Restaurant.CategoryAddDialog.Field.Tax2.Error.Required')
          ],

          ClassificationTypeCode: [
            (v) => !this.appSupportIncomeClassifications || (!!v) || this.$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Error.Required')
          ],
          ClassificationCategoryCode: [
            (v) => !this.appSupportIncomeClassifications || (!!v) || this.$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    catalogsActive () {
      return this.catalogs.filter(catalog => catalog.Status)
    },
    preparationDocumentEnabled () {
      return this.appConfig?.LOCATION_DATA?.PreparationDocumentEnabled ?? false
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editCategory: {
      get () {
        if (this.categoryToEdit) {
          const c = JSON.parse(JSON.stringify(this.categoryToEdit)).Color
          this.categoryToEdit.TmpColor = {
            hex   : c ? '#' + c : '',
            source: 'hex'
          }
          this.newCategory = clone(this.categoryToEdit)
        } else {
          this.newCategory = clone(this.tmpCategory)
        }

        this.newCategory.TaxId = this.newCategory.TaxId ? this.newCategory.TaxId : this.defaultTax.TaxId
        this.newCategory.Tax2Id = this.newCategory.Tax2Id ? this.newCategory.Tax2Id : this.defaultTax.Tax2Id

        this.newCategory.ClassificationTypeCode = this.newCategory.ClassificationTypeCode ? this.newCategory.ClassificationTypeCode : this.defaultClassification.ClassificationTypeCode
        this.newCategory.ClassificationCategoryCode = this.newCategory.ClassificationCategoryCode ? this.newCategory.ClassificationCategoryCode : this.defaultClassification.ClassificationCategoryCode

        this.newCategory.ClassificationTypeCodeInvoice = this.newCategory.ClassificationTypeCodeInvoice ? this.newCategory.ClassificationTypeCodeInvoice : this.defaultClassification.ClassificationTypeCodeInvoice
        this.newCategory.ClassificationCategoryCodeInvoice = this.newCategory.ClassificationCategoryCodeInvoice ? this.newCategory.ClassificationCategoryCodeInvoice : this.defaultClassification.ClassificationCategoryCodeInvoice

        const langObj = {}
        for (const lang of this.selectedLanguages) {
          langObj[lang.Code] = {
            Name       : '',
            Description: '',
            Locale     : lang.Code
          }
        }
        this.newCategory.Lang = Object.assign({}, langObj, this.newCategory.Lang)

        if (this.catalogsActive.length === 1) {
          this.newCategory.Catalogs = [this.catalogsActive[0].Id]
        }

        this.newCategory = clone(this.newCategory)

        return this.newCategory
      }
    }
  },
  watch: {
    'editCategory.TaxId': function (newVal) {
      const tax = this.taxesList.find(t => t.Id === newVal)
      this.editCategory.TaxLang = tax?.Lang || null
      this.editCategory.TaxRate = tax?.Rate || null
    },
    'editCategory.Tax2Id': function (newVal) {
      const tax = this.taxesList.find(t => t.Id === newVal)
      this.editCategory.Tax2Lang = tax?.Lang || null
      this.editCategory.Tax2Rate = tax?.Rate || null
    },

    visible (newVal) {
      this.languageTab = 0
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
        })
        setTimeout(function () {
          const evt = document.createEvent('UIEvents')
          evt.initUIEvent('resize', true, false, window, 0)
          window.dispatchEvent(evt)
        }, 250)
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Category.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Category.Save, this.onSaveResult)
  },
  methods: {
    onPhotoChange () {
      if (this.croppa && this.croppa.hasImage() && (this.croppa.getChosenFile() || this.croppa.initialImage) && !this.editCategory.Image) {
        this.tmpImage = this.croppa.getChosenFile() || this.croppa.initialImage
        this.tmpImageBase64 = {
          img : this.croppa.generateDataUrl('image/jpeg', 1),
          mime: 'image/jpeg', // this.croppa.getChosenFile().type,
          ext : 'jpg' // this.croppa.getChosenFile().name.split('.')[1]
        }
      }
    },
    onPhotoRemoved () {
      this.editCategory.Image = ''
      this.tmpImage = null
      this.tmpImageBase64 = null
    },
    onSaveButtonClick () {
      this.$refs.categoryForm.validate()

      this.$nextTick(() => {
        if (this.categoryFormValid) {
          const payload = clone(this.editCategory)

          payload.Color = payload.TmpColor.hex.replace('#', '')
          this.loading = true

          this.onPhotoChange()
          payload.ImageBase64 = payload.Image ? null : this.tmpImageBase64 ? this.tmpImageBase64.img : ''
          payload.ImageMime = this.tmpImageBase64 ? this.tmpImageBase64.mime : ''
          payload.ImageExt = this.tmpImageBase64 ? this.tmpImageBase64.ext : ''

          payload.Name = payload.Lang[this.defaultLanguage.Code].Name
          payload.Description = payload.Lang[this.defaultLanguage.Code].Description
          // payload.ParentId = -1

          window.callAS(window.SocketCommand.Category.Save, payload, '', 300000)
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.FieldError'),
            type: 'error',
            icon: 'warning'
          })
        }
      })
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.CategoryAddDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.croppa) this.croppa.remove()
      this.onPhotoRemoved()
      if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
      this.newCategory = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
.vc-swatches {
  min-width  : 472px;
  height     : auto;
  overflow-y : auto;
  zoom       : 75%;
}

/deep/ .vc-swatches .vc-swatches-box div.vc-swatches-color-group:last-child {
  display : none;
}

/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

</style>
