export default {
  data () {
    return {
      queueBusy: false
    }
  },

  created () {
    this.$bus.$on('promise-queue:idle', this.onQueueIdle)
    this.$bus.$on('promise-queue:push', this.onQueueBusy)
  },

  beforeDestroy () {
    this.$bus.$off('promise-queue:idle', this.onQueueIdle)
    this.$bus.$off('promise-queue:push', this.onQueueBusy)
  },

  methods: {
    onQueueIdle () {
      this.queueBusy = false
    },
    onQueueBusy () {
      this.queueBusy = true
    }
  }
}
