export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [],
  directives: {},
  watch     : {},
  methods   : {
    reservationTablesMinCapacity (table) {
      if (Array.isArray(table)) return table?.reduce((a, b) => a + parseInt(b.MinCapacity), 0) || 0

      return table.MinCapacity || 0
    },

    reservationTablesMaxCapacity (table) {
      if (Array.isArray(table)) return table?.reduce((a, b) => a + parseInt(b.MaxCapacity), 0) || 0

      return table.MaxCapacity || 0
    },

    reservationHasInvalidTablePersons (persons, table) {
      const minCapacity = this.reservationTablesMinCapacity(table)
      const maxCapacity = this.reservationTablesMaxCapacity(table)

      if (Array.isArray(table)) return table.some(tbl => parseInt(persons) < minCapacity || parseInt(persons) > maxCapacity)

      return parseInt(persons) < minCapacity || parseInt(persons) > maxCapacity
    },

    getDurationByPersons (persons = 1) {
      const defaultDuration = this.tableReservationSetting?.durationDefault || 120
      const useDurationByPeople = this.tableReservationSetting?.useDurationByPeople || false
      const durationByPeopleRules = this.tableReservationSetting?.durationByPeople || []
      if (!useDurationByPeople) return defaultDuration

      let duration = defaultDuration
      persons = parseInt(persons)

      durationByPeopleRules.forEach(rule => {
        if (persons >= parseInt(rule.min) && persons <= parseInt(rule.max)) {
          duration = parseInt(rule.duration)
        }
      })

      return duration
    }
  },
  computed: {
    tableReservationSetting () {
      return this.appConfig?.LOCATION_DATA?.TableReservation || {}
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {}
}
