<template>
  <v-card flat>
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Staff.ListView.Button.StaffAdd.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-0"
          text
          @click="showStaffAddDialog"
        >
          <v-icon left>
            {{ $t('Staff.ListView.Button.StaffAdd.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Staff.ListView.Button.StaffAdd.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('WaiterStaff', 'MANAGE')"
          :color="$t('Waiter.Button.WaiterStaff.Add.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="ma-0 px-2"
          text
          @click="showWaiterStaffAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Waiter.Button.WaiterStaff.Add.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Waiter.Button.WaiterStaff.Add.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('StaffGroups', 'ACCESS')"
          :color="$t('Staff.ListView.Button.StaffGroups.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'StaffGroups'}"
          class="elevation-0 ma-0"
          text
        >
          <v-icon left>
            {{ $t('Staff.ListView.Button.StaffGroups.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Staff.ListView.Button.StaffGroups.Title') }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.staff_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.staff_firstname`]="{item}">
              {{ item.Firstname }}
            </template>

            <template #[`item.staff_lastname`]="{item}">
              {{ item.Lastname }}
            </template>

            <template #[`item.staff_email`]="{item}">
              {{ item.Email }}
            </template>

            <template #[`item.domain`]="{item}">
              <a
                :href="`https://${item.Domain}`"
                target="_blank"
              >
                {{ item.Domain }}
              </a>
            </template>

            <template #[`item.staff_group_name`]="{item}">
              {{ item.GroupName }}
            </template>

            <template #[`item.staff_permissions`]="{item}">
              <v-icon
                v-if="item.HasPermissions"
                color="light-green"
              >
                verified_user
              </v-icon>

              <v-icon
                v-else
                color="grey lighten-3"
              >
                shield
              </v-icon>
            </template>

            <template #[`item.staff_locale`]="{item}">
              {{ $i18n.languages.find(lang => lang.locale === item.Locale).name }}
            </template>

            <template #[`item.single_login`]="{item}">
              <v-switch
                v-model="item.SingleLogin"
                inset
                :disabled="!userCanManage"
                :label="item.SingleLogin ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                color="purple"
                class="ma-0"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.is_guest`]="{item}">
              <v-switch
                v-model="item.Guest"
                inset
                :disabled="!userCanManage"
                :label="item.Guest ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                color="orange"
                class="ma-0"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.staff_status`]="{item}">
              <v-switch
                v-model="item.Status"
                inset
                :disabled="!userCanDelete || (!userIsSuperAdmin && (parseInt(item.GroupId) === 17) ? parseInt(posUser.staff_id) === parseInt(item.Id) : false)"
                :label="item.Status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                color="success"
                class="ma-0"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.created_at`]="{item}">
              {{ item.DateAdded }}
            </template>

            <template #[`item.actions`]="{item}">
              <v-tooltip
                v-if="(userCanManage && userIsSuperAdmin)"
                color="blue-grey"
                open-delay="1000"
                left
              >
                <template #activator="{on}">
                  <v-btn
                    v-if="(userCanManage && userIsSuperAdmin)"
                    :small="$vuetify.breakpoint.xsOnly"
                    class="blue-grey elevation-2 ma-0 mr-2"
                    dark
                    icon
                    v-on="on"
                    @click.stop="loginOnUserBehalf(item)"
                  >
                    <v-icon :small="$vuetify.breakpoint.xsOnly">
                      mdi-web-refresh
                    </v-icon>
                  </v-btn>
                </template>

                <span>Login on User Behalf</span>
              </v-tooltip>

              <v-tooltip
                v-if="(userCanManage || userIsSuperAdmin)"
                :color="$t('Staff.ListView.Table.Button.Edit.Color')"
                open-delay="1000"
                left
              >
                <template #activator="{on}">
                  <v-btn
                    v-if="(userCanManage || userIsSuperAdmin)"
                    :small="$vuetify.breakpoint.xsOnly"
                    class="cyan elevation-2 ma-0"
                    dark
                    icon
                    v-on="on"
                    @click="showEditDialog(item)"
                  >
                    <v-icon :small="$vuetify.breakpoint.xsOnly">
                      {{ $t('Staff.ListView.Table.Button.Edit.Icon') }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Staff.ListView.Table.Button.Edit.Title') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <staff-add-dialog
      :domains="viewData.domains"
      :groups="viewData.groups"
      :staff-to-edit="tmpStaff"
      :visible.sync="addEditStaffDialog"
    />

    <waiter-staff-add-dialog
      :staff-to-edit="tmpStaff"
      :table-area-combo="tableAreaCombo"
      :visible.sync="waiterStaffAddDialogVisible"
    />
  </v-card>
</template>

<script>
import AppData              from '@/mixins/appdata'
import Auth                 from '@/mixins/auth'
import FilterSearch         from '@/components/common/filter/FilterSearch.vue'
import StaffAddDialog       from './StaffAddDialog.vue'
import DataTable            from '@/mixins/data-table/dataTable'
import WaiterStaffAddDialog from '@/components/waiters/WaiterStaffAddDialog.vue'

export default {
  components: {
    WaiterStaffAddDialog,
    FilterSearch,
    'staff-add-dialog': StaffAddDialog
  },
  directives: {},
  mixins    : [AppData, Auth, DataTable],
  data () {
    return {
      tableAreaCombo             : [],
      selectedStaff              : null,
      tmpStaff                   : null,
      addEditStaffDialog         : false,
      waiterStaffAddDialogVisible: false,
      dataTable                  : {
        socketCommand: {
          list: window.SocketCommand.Staff.All,
          save: window.SocketCommand.Staff.Save
        },
        options: {
          defaultSortBy: 'staff_id',
          sortBy       : ['staff_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : ['staff_id', 'staff_permissions', 'staff_locale', 'single_login', 'is_guest', 'staff_status', 'created_at'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Staff.ListView.Table.Headers.Id'),
            value    : 'staff_id',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Firstname'),
            value    : 'staff_firstname',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Lastname'),
            value    : 'staff_lastname',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Email'),
            value    : 'staff_email',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Domain'),
            value    : 'domain',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.GroupName'),
            value    : 'staff_group_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.HasPermissions'),
            value    : 'staff_permissions',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Locale'),
            value    : 'staff_locale',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.SingleLogin'),
            value    : 'single_login',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Guest'),
            value    : 'is_guest',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Status'),
            value    : 'staff_status',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.DateAdded'),
            value    : 'created_at',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Staff.ListView.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 100
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');

    this.$bus.$on(window.SocketCommand.Staff.Waiter.All, this.getData)
    this.$bus.$on(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
    this.getTableAreaCombo()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Staff.Waiter.All, this.getData)
    this.$bus.$off(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
  },
  methods: {
    loginOnUserBehalf (user) {
      if (!this.userCanManage || !user) return
      const uid = user.Email
      const pin = user.Pin
      const token = window.authorizationToken

      const host = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
      window.opener = null
      window.open(`${ host }/login?uid=${ encodeURIComponent(uid) }&pin=${ pin }&token=${ token }`, '_blank', '')
    },

    getTableAreaCombo () {
      window.callAS(window.SocketCommand.Table.Area.List)
    },
    setTableAreaCombo (data) {
      const areas = (data || []).filter(area => area.Active).map(area => {
        area.Name = area.Name.toLocaleUpperCase(this.$i18n.locale)
        return area
      })
      areas.unshift({
        Id    : 0,
        Name  : this.$t('Waiter.StaffAddDialog.Field.TableAreas.All').toLocaleUpperCase(this.$i18n.locale),
        Color : '59ae4a',
        Active: true
      })
      this.tableAreaCombo = areas
    },

    setData (data) {
      this.setDataInternal(data)
      this.$set(this.viewData, 'domains', data?.domains || [])
      this.$set(this.viewData, 'groups', data?.groups || [])
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.Staff.Save, { staff: item })
    },

    showEditDialog (staff) {
      if (staff.GroupId === '102') {
        this.showWaiterStaffEditDialog(staff)
      } else {
        this.showStaffEditDialog(staff)
      }
    },

    showStaffAddDialog () {
      this.tmpStaff = null
      this.addEditStaffDialog = true
    },

    showStaffEditDialog (staff) {
      this.tmpStaff = staff
      this.addEditStaffDialog = true
    },

    showWaiterStaffAddDialog () {
      this.tmpStaff = null
      this.waiterStaffAddDialogVisible = true
    },

    showWaiterStaffEditDialog (staff) {
      this.tmpStaff = staff
      this.waiterStaffAddDialogVisible = true
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 75px !important;*/
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
