import Common         from './common'
import Dashboard      from './dashboard'
import Order          from './order'
import Customer       from './customer'
import Marketing      from './marketing'
import Voip           from './voip'
import Staff          from './staff'
import Settings       from './settings'
import Delivery       from './delivery'
import Waiter         from './waiter'
import Authentication from './authentication'
import Faq            from './faq'
import Pos            from './pos'
import Restaurant     from './restaurant'
import Message        from './message'
import Account        from './account'
import Support        from './support'
import Statistics     from './statistics'
import Reservation    from './reservation'
import Qr             from './qr'
import Charge         from './charge'
import Receipt        from './receipt'

export default {
  Common        : Common,
  Dashboard     : Dashboard,
  Order         : Order,
  Customer      : Customer,
  Marketing     : Marketing,
  Voip          : Voip,
  Staff         : Staff,
  Settings      : Settings,
  Delivery      : Delivery,
  Waiter        : Waiter,
  Authentication: Authentication,
  Faq           : Faq,
  Pos           : Pos,
  Restaurant    : Restaurant,
  Message       : Message,
  Account       : Account,
  Support       : Support,
  Statistics    : Statistics,
  Reservation   : Reservation,
  Qr            : Qr,
  Charge        : Charge,
  Receipt       : Receipt
}
