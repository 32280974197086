import AppData                 from '@/mixins/appdata'
import Auth                    from '@/mixins/auth'
import OrderStatusColor        from '@/mixins/orders/orderStatus'
import OrderSourceType         from '@/mixins/orders/sourceType'
import OrderPaymentType        from '@/mixins/orders/paymentType'
import OrderType               from '@/mixins/orders/orderType'
import OrderTypeTime           from '@/mixins/orders/orderTypeTime'
import OrderChangeStatusDialog from '@/components/orders/OrderChangeStatusDialog.vue'
import OrderDeleteDialog       from '@/components/orders/OrderDeleteDialog.vue'
import ConfirmDialog           from '@/components/common/ConfirmDialog.vue'
import Printer                 from '@/lib/helper/printer'
import OrderStatusEnum         from '@/api/Enums/OrderStatusEnum'
import SourceTypeEnum          from '@/api/Enums/SourceTypeEnum'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import PaymentTypeEnum         from '@/api/Enums/PaymentTypeEnum'
import PinManagerEnum          from '@/api/Enums/PinManagerEnum'

export default {
  data () {
    return {
      search                               : '',
      loader                               : null,
      debounceSearchTimeout                : null,
      selectedOrder                        : null,
      cancelOrderConfirmDialogVisible      : false,
      deleteOrderConfirmDialogVisible      : false,
      deleteAllOrdersConfirmDialogVisible  : false,
      orderPaymentDialogVisible            : false,
      confirmDialogEditOrderVisible        : false,
      confirmDialogEditOrderCanceledVisible: false,
      isDeleteAndCopyOrder                 : false,
      deleteOrderLoading                   : false,
      selectedOrders                       : []
    }
  },
  components: {
    'order-change-status-dialog': OrderChangeStatusDialog,
    'order-delete-dialog'       : OrderDeleteDialog,
    'confirm-dialog'            : ConfirmDialog
  },
  mixins    : [Auth, AppData, OrderStatusColor, OrderSourceType, OrderPaymentType, OrderType, OrderTypeTime, OrderStatusEnum],
  props     : {},
  directives: {},
  watch     : {},
  methods   : {
    /**
     * showOrderEditDialog
     *
     * @param order {Object}
     */
    showOrderEditDialog (order) {
      this.selectedOrder = order

      this.executeWithPinPermissionManager(PinManagerEnum.PosEdit, () => {
        if (!order.DeletedAt) {
          this.confirmDialogEditOrderVisible = true
        } else {
          this.confirmDialogEditOrderCanceledVisible = true
        }
      })
    },

    canEditOrder (order) {
      const sourceTypeId = parseInt(order.SourceTypeId)
      const allCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs || []
      const sourceTypeExists = allCatalogs.some(catalog => catalog.SourceTypes.includes(sourceTypeId))
      const sourceTypeCanCreateOrder = allCatalogs.some(catalog => catalog.CanCreateOrder && catalog.SourceTypes.includes(sourceTypeId))
      const canCreateOrder = !sourceTypeExists || (sourceTypeExists && sourceTypeCanCreateOrder)

      return canCreateOrder && parseInt(order.StatusId) !== OrderStatusEnum.NotComplete && this.posUserCan('Orders', 'MANAGE') && this.posUserCan('Pos', 'ACCESS') && this.$route.name !== 'Pos'
    },

    /**
     * onCancelOrderClick
     *
     * @param order {Object}
     */
    onCancelOrderClick (order) {
      this.selectedOrder = order

      this.executeWithPinPermissionManager(PinManagerEnum.PosCancel, () => {
        this.cancelOrderConfirmDialogVisible = true
      })
    },

    /**
     * onDeleteOrderClick
     *
     * @param order {Object}
     */
    onDeleteOrderClick (order) {
      if (parseInt(order.StatusId) !== OrderStatusEnum.Completed && parseInt(order.StatusId) !== OrderStatusEnum.Canceled && !this.appConfig.LOCATION_DATA.HasForceDeleteOrders) return
      this.selectedOrder = order

      this.executeWithPinPermissionManager(PinManagerEnum.PosDelete, () => {
        this.isDeleteAndCopyOrder = false
        this.deleteOrderConfirmDialogVisible = true
      })
    },

    /**
     * onDeleteOrdersClick
     *
     * @param orders {Array}
     */
    onDeleteOrdersClick (orders) {
      this.selectedOrder = orders?.map(o => o?.Id ?? o)

      this.executeWithPinPermissionManager(PinManagerEnum.PosDelete, () => {
        this.isDeleteAndCopyOrder = false
        this.deleteOrderConfirmDialogVisible = true
      })
    },

    onDeleteAllOrdersClick () {
      this.selectedOrder = null
      this.executeWithPinPermissionManager(PinManagerEnum.PosDelete, () => {
        this.deleteAllOrdersConfirmDialogVisible = true
      })
    },

    onEditDelete () {
      this.executeWithPinPermissionManager(PinManagerEnum.PosDelete, () => {
        this.isDeleteAndCopyOrder = true
        this.deleteOrderConfirmDialogVisible = true
      })
    },

    getExternalOrderRawUrl (order) {
      if (!order || !process.env.VUE_APP_API_URL || !this.authorizationToken || !this.userIsSuperAdmin) return ''

      const API_URL = process.env.VUE_APP_API_URL
      const ORDER_ID = order.Id
      const TOKEN = this.authorizationToken

      return `${ API_URL }raw/order/external/${ ORDER_ID }/${ TOKEN }`
    },

    onEditCopy () {
      this.isDeleteAndCopyOrder = false
      window.callAS(window.SocketCommand.Order.Edit, {
        order_id  : this.selectedOrder.Id,
        catalog_id: this.selectedOrder?.ExternalCatalogId || null,
        cancel    : false,
        Reserved  : parseInt(this.posUser.staff_id)
      })
    },

    onOrderEdit (data, payload) {
      if (data && data.CartId && parseInt(data.CartId) > 0) {
        this.$router.push({
          name  : 'Pos',
          params: {
            orderId             : data.CartId,
            catalogId           : payload?.catalog_id || null,
            isFromCopy          : true,
            isDeleteAndCopyOrder: this.isDeleteAndCopyOrder
          }
        })
        this.$bus.$emit('hide-order-details-dialog')
        this.$bus.$emit('hide-customer-details-dialog')
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Pos.Pos.Notification.Error'),
          timeout: 2000,
          type   : 'error',
          icon   : 'warning'
        })
      }
    },

    getOrderDetails (orderItem) {
      this.selectedOrder = orderItem

      window.callAS(window.SocketCommand.Order.Get, {
        order_id : orderItem.Id,
        status_id: orderItem.StatusId
      })
    },

    updateOrderStatus (order) {
      this.$bus.$emit(window.SocketCommand.Order.UpdateStatus, order)
    },

    onOrdersDelete () {
      this.isDeleteAndCopyOrder = false
      this.selectedOrders = []
    },

    onOrderStatusUpdated (data) {
      if (data && typeof this.getData === 'function') {
        this.getData()
      }
    },

    setOrderDetails (data) {
      if (!this.selectedOrder) return
      this.selectedOrder.Details = data
      this.$bus.$emit('show-order-details-dialog', this.selectedOrder)
    },

    printOrder (item, printer) {
      if (!item || !printer) return
      Printer.printOrder(item.Id, printer)
    },

    printOrderPreparation (item, printerGroup) {
      if (!item || !printerGroup) return
      Printer.printOrderPreparation(item.Id, printerGroup.Id)
    },

    printOrderTotalsZ (range, printer) {
      if (!printer) return
      Printer.printOrderTotalsZ(range, printer)
    },

    printReceiptTotalsZ (range, printer) {
      if (!printer) return
      Printer.printReceiptTotalsZ(range, printer)
    },

    issueReceiptDocumentVisible (order) {
      if (parseInt(order.SourceTypeId) === SourceTypeEnum.TABLE || parseInt(order.StatusId) === OrderStatusEnum.Canceled) return false

      return this.userCanViewReceipt
    },
    printOrderDocumentVisible (order) {
      if (parseInt(order.SourceTypeId) === SourceTypeEnum.TABLE && parseInt(order.StatusId) === OrderStatusEnum.Canceled) return false

      return this.posUserHasComponentPermission('Orders', 'MANAGE')
    },
    printOrderPreparationVisible (order) {
      if (parseInt(order.SourceTypeId) === SourceTypeEnum.TABLE && parseInt(order.StatusId) === OrderStatusEnum.Canceled) return false

      return this.posUserHasComponentPermission('Orders', 'MANAGE') && this.preparationDocumentPrintEnabled
    },

    getAddressFormatted (address) {
      const comma = (address.Address || '') || (address.Number || '') ? ',' : ''
      const retVal = `${ address.Address || '' } ${ address.Number || '' }${ comma } ${ address.City || '' }`

      return retVal?.trim() || address.Formatted
    },

    orderHasRefundableTransactions (order) {
      if (!order) return false

      return order?.PaymentMethods?.some(payment => payment.Refundable)
    },

    orderRefundableTransactionsTotal (order) {
      if (!order) return 0

      return order?.PaymentMethods?.filter(payment => payment.Refundable)?.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0
    },

    orderHasUnpaidBalance (order) {
      return this.orderBalance(order) > 0
    },

    orderBalance (order) {
      if (!order) return false

      const orderTotal = parseFloat(parseFloat(order.OrderTotal).toFixed(2))
      const transactionsTotal = this.orderTransactionsTotal(order)

      return orderTotal - transactionsTotal
    },

    orderTransactionsTotal (order) {
      let val = order.PaymentTransactions.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0

      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        val = order.PaymentTransactions.filter(transaction => transaction.Paid === 'success' || transaction.PaymentType !== PaymentTypeEnum.CARD).reduce((a, b) => a + parseFloat(b.Amount), 0) || 0
      }

      return parseFloat(val.toFixed(2))
    },

    orderHasOnlineCardPayments (order) {
      return order?.Payment === PaymentTypeEnum.VIVA_3DS || order?.Payment === PaymentTypeEnum.VIVA_SMART || order?.Payment === PaymentTypeEnum.EVERYPAY || order?.Payment === PaymentTypeEnum.STRIPE
    },

    orderRefundableTransactions (order) {
      return order?.PaymentTransactions?.filter(payment => payment.Status === 'APPROVED' && payment.PaymentType === PaymentTypeEnum.CARD) || []
    },

    orderHasPaymentMethodRefundable (order) {
      return this.orderRefundableTransactions(order).length > 0
    },

    orderIsRefundable (order) {
      return this.orderHasPaymentMethodRefundable(order) || this.orderHasOnlineCardPayments(order)
    },

    orderRefundableAmount (order) {
      if (this.orderHasOnlineCardPayments(order)) return order?.Price ?? 0

      if (this.orderHasPaymentMethodRefundable(order)) return this.orderRefundableTransactions(order).reduce((a, b) => a + parseFloat(b?.Amount ?? 0), 0) || 0

      return 0
    },

    orderHasReceipt (order) {
      return !!order?.ReceiptId
    }
  },
  computed: {
    selectedOrderHasRefundableTransactions () {
      return this.orderHasRefundableTransactions(this.selectedOrder)
    },
    selectedOrderRefundableTransactionsTotal () {
      return this.orderRefundableTransactionsTotal(this.selectedOrder)
    },

    userCanDeleteOrders () {
      return this.posUserHasComponentPermission('Orders', 'DELETE') && this.appConfig?.LOCATION_DATA?.HasForceDeleteOrders
    },
    confirmDialogEditOrderTitle () {
      return `${ this.$t('Order.OrdersAll.ListView.Button.Edit.Title') } # ${ this.selectedOrder ? this.selectedOrder.Id : '' };`
    },
    confirmDialogEditOrderBody () {
      return `${ this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Choose') } <span class="red--text text--darken-1">${ this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Delete') }</span> ${ this.selectedOrder && parseInt(this.selectedOrder.SourceTypeId) !== 1 ? this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Or') + ' <span class=\'green--text text--darken-1\'>' + this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Copy') + '</span>' : '' } ${ this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Order') }<br>`
    },
    confirmDialogEditOrderCanceledTitle () {
      return `${ this.$t('Order.OrdersAll.ListView.Button.Edit.Title') } #   ${ this.selectedOrder ? this.selectedOrder.Id : '' };`
    },
    confirmDialogEditOrderCanceledBody () {
      return `<br>${ this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Choose') } <span class="green--text text--darken-1">${ this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Copy') }</span> ${ this.$t('Order.OrdersAll.ListView.Button.Edit.PopUp.Content.Order') }<br>`
    },

    preparationDocumentPrintEnabled () {
      return this.appConfig?.LOCATION_DATA?.PreparationDocumentEnabled || false
    },

    sourceTypesList () {
      let sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})
      let sourceTypesOther = this.appConfig?.SOURCE_TYPES?.OTHER || []

      sourceTypesDefault = sourceTypesDefault.filter(sourceType => sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })
      sourceTypesOther = sourceTypesOther.filter(sourceType => sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : o.title,
          Description: o.desc,
          Avatar     : {
            Img  : o.logo,
            Color: 'blue-grey'
          }
        }
      })

      return [...sourceTypesDefault, ...sourceTypesOther]
    }
  },
  created () {},
  mounted () {
    // if (this.viewData && this.viewData.table && this.viewData.table.config) this.viewData.table.config.pagination.page = 1
    // if (this.getData) this.getData()
    this.$bus.$on(window.SocketCommand.Order.Get, this.setOrderDetails)
    this.$bus.$on(window.SocketCommand.Order.StatusUpdated, this.onOrderStatusUpdated)
    this.$bus.$on(window.SocketCommand.Order.Edit, this.onOrderEdit)
  },
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Order.Get, this.setOrderDetails)
    this.$bus.$off(window.SocketCommand.Order.StatusUpdated, this.onOrderStatusUpdated)
    this.$bus.$off(window.SocketCommand.Order.Edit, this.onOrderEdit)
  },
  destroyed () {}

}
