<template>
  <div
    v-if="order"
    class="d-inline-block"
  >
    <v-chip
      v-if="order"
      :class="getOrderStatusColorClass(order)"
      :style="`min-width: ${chipMinWidth}px;`"
      class="mx-1 pl-0"
      :small="dense"
      label
      dark
    >
      <v-avatar
        :class="getOrderStatusColorClass(order)"
        class="darken-2 mr-2"
        :style="avatarSize"
        tile
      >
        <v-icon
          dark
          :size="iconSize"
        >
          {{ getOrderStatusIcon(order) }}
        </v-icon>
      </v-avatar>

      <div
        style="width: 100%;"
        class="text-center"
      >
        {{ getOrderStatusLang(order).toLocaleUpperCase($i18n.locale) }}
      </div>
    </v-chip>
  </div>
</template>

<script>

import OrderStatus from '@/mixins/orders/orderStatus'

export default {
  name      : 'OrderStatusChip',
  components: {},
  directives: {},
  mixins    : [OrderStatus],
  props     : {
    order: {
      type   : Object,
      default: undefined
    },
    minWidth: {
      type   : [Number, String],
      default: 170
    },
    dense: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    chipMinWidth () {
      return this.minWidth.toString().replaceAll('px', '')
    },
    iconSize () {
      return this.dense ? 18 : 22
    },
    avatarSize () {
      return `width: ${ this.dense ? 32 : 46 }px !important; height: 32px !important;`
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-chip > .v-chip__content {
  width : 100%;
}
</style>
