<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Display.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Display.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $tc('Settings.Display.Title', 0) }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Display.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="userCanManage"
        color="light-green"
        class="white--text elevation-0"
        :disabled="!isDesktop"
        :icon="$vuetify.breakpoint.smAndDown"
        @click="onUpdateSystemDisplaysClick"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">
          refresh
        </v-icon>

        <span v-if="$vuetify.breakpoint.mdAndUp">
          {{ $t('Settings.Display.Toolbar.Button.Refresh') }}
        </span>
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
      :class="[{'pt-0': tab === 0}]"
    >
      <v-form
        ref="settingsForm"
        v-model="settingsFormValid"
        @submit.prevent
      >
        <v-container
          class="pa-0"
          fluid
        >
          <v-row dense>
            <v-col cols="12">
              <template v-if="displays.length">
                <v-expansion-panels accordion>
                  <template v-for="(machine, i) in displays">
                    <v-expansion-panel :key="i">
                      <v-expansion-panel-header>
                        <div
                          style="max-width: 45px;"
                          class="mr-3"
                        >
                          <v-badge
                            overlap
                            bordered
                            color="green"
                            :value="machine.guid === $DataStore.app.guid"
                          >
                            <template #badge>
                              <v-icon>mdi-account</v-icon>
                            </template>

                            <v-avatar
                              size="45"
                              color="grey"
                            >
                              <v-icon dark>
                                mdi-desktop-classic
                              </v-icon>
                            </v-avatar>
                          </v-badge>
                        </div>

                        <div>
                          <h5 class="text-body-2 font-weight-bold">
                            {{ machine.hostname }}
                          </h5>
                          <span class="text-caption grey--text">{{ machine.guid }}</span>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-card flat>
                          <v-card-text
                            class="pa-0 ma-0 overflow-auto"
                            :class="isDarkMode ? '' : 'grey lighten-4'"
                          >
                            <div class="divTable">
                              <div class="divTableHeading">
                                <div class="divTableRow">
                                  <div
                                    class="divTableHead text-left"
                                    v-html="$t('Settings.Display.Header.Id')"
                                  />
                                  <div
                                    class="divTableHead text-left"
                                    v-html="$t('Settings.Display.Header.Name')"
                                  />
                                  <div
                                    class="divTableHead text-center"
                                    v-html="$t('Settings.Display.Header.Bounds')"
                                  />
                                  <div
                                    class="divTableHead text-center"
                                    v-html="$t('Settings.Display.Header.Primary')"
                                  />
                                  <div
                                    class="divTableHead text-center"
                                    v-html="$t('Settings.Display.Header.AutoOpen')"
                                  />
                                  <div
                                    class="divTableHead text-center"
                                    v-html="$t('Settings.Display.Header.CdsStatus')"
                                  />
                                  <div
                                    class="divTableHead text-right"
                                    v-html="$t('Settings.Display.Header.Actions')"
                                  />
                                </div>
                              </div>
                              <div
                                v-for="(display, index) in machine.displays_save"
                                :key="index"
                                class="divTableRow"
                              >
                                <div class="divTableCell text-left">
                                  {{ display.id }}
                                </div>

                                <div class="divTableCell text-left">
                                  {{ display.label }}
                                </div>

                                <div class="divTableCell text-center">
                                  {{ display.bounds.width }} x {{ display.bounds.height }}
                                </div>

                                <div class="divTableCell text-center">
                                  <v-icon
                                    v-if="parseInt(display.primary)"
                                    color="primary"
                                  >
                                    check_circle
                                  </v-icon>
                                </div>

                                <div class="divTableCell text-center">
                                  <v-switch
                                    v-model="display.auto_open"
                                    inset
                                    :disabled="!userCanManage || !!parseInt(display.primary)"
                                    :false-value="'0'"
                                    :label="parseInt(display.auto_open) === 0 ? $t('Common.Button.No') : $t('Common.Button.Yes')"
                                    :true-value="'1'"
                                    color="success"
                                    hide-details
                                  />
                                </div>

                                <div class="divTableCell text-center">
                                  <v-switch
                                    v-model="display.cds_status"
                                    inset
                                    :disabled="!userCanManage || !!parseInt(display.primary)"
                                    :false-value="'0'"
                                    :label="parseInt(display.cds_status) === 0 ? $t('Common.Button.Toggle.InactiveF') : $t('Common.Button.Toggle.ActiveF')"
                                    :true-value="'1'"
                                    color="success"
                                    hide-details
                                    @change="onCdsStatusChange(display, machine)"
                                  />
                                </div>

                                <div class="divTableCell text-right">
                                  <template v-if="!!parseInt(display.cds_status)">
                                    <v-btn
                                      v-if="isDesktop"
                                      class="blue--text pr-4"
                                      icon
                                      width="22"
                                      height="22"
                                      @click="toggleCDS(display)"
                                    >
                                      <v-icon>mdi-monitor-multiple</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      :to="{name: 'Cds', query: {lang: $i18n.locale}}"
                                      target="_blank"
                                      class="blue--text mr-4"
                                      icon
                                      width="22"
                                      height="22"
                                    >
                                      <v-icon>mdi-monitor-multiple</v-icon>
                                    </v-btn>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </template>

              <template v-else>
                <v-container
                  fluid
                  class="pa-0"
                >
                  <v-row class="">
                    <v-col cols="12">
                      <div class="text-center grey--text">
                        <v-icon
                          class="mb-4"
                          color="grey lighten-2"
                          size="86"
                        >
                          mdi-monitor-screenshot
                        </v-icon>

                        <div
                          class="text-h6 mb-4"
                          style="height: auto;"
                        >
                          {{ $t('Settings.Display.Notification.NoDisplays') }}
                        </div>

                        <v-btn
                          v-if="userCanManage"
                          color="light-green"
                          class="white--text elevation-0"
                          :disabled="!isDesktop"
                          :icon="$vuetify.breakpoint.smAndDown"
                          @click="onUpdateSystemDisplaysClick"
                        >
                          <v-icon :left="$vuetify.breakpoint.mdAndUp">
                            refresh
                          </v-icon>

                          <span v-if="$vuetify.breakpoint.mdAndUp">
                            {{ $t('Settings.Display.Toolbar.Button.Refresh') }}
                          </span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        @click="onCancelButtonClick"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>

      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveButtonClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData             from '@/mixins/appdata'
import Auth                from '@/mixins/auth'
import isElectron          from '@/electron/isElectron'
import ipcCommandsEnum     from '@/electron/ipcCommandsEnum'
import ipcRendererElectron from '@/electron/ipcRendererElectron'
import { clone }           from '@/lib/helper/helper'

const IS_ELECTRON = isElectron()
const ipcRenderer = ipcRendererElectron()

export default {
  name      : 'SystemSettingsDisplays',
  components: {},
  mixins    : [AppData, Auth],
  data () {
    return {
      tab              : null,
      isSaving         : false,
      settingsFormValid: false,
      viewData         : null,
      tmpObjDefault    : {
        id        : '',
        label     : '',
        primary   : '0',
        auto_open : '0',
        cds_status: '0',
        bounds    : {
          x     : 0,
          y     : 0,
          width : 1024,
          height: 768
        }
      }
    }
  },
  computed: {
    isDesktop () {
      return IS_ELECTRON
    },

    systemDisplays () {
      if (!this.isDesktop) return []

      return ipcRenderer.sendSync(ipcCommandsEnum.GetSystemDisplays) || []
    },

    displays () {
      return this.viewData || []
    }
  },
  watch: {},
  created () {
    this.getData()
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.System.Displays.Get, this.setData)
    this.$bus.$on(window.SocketCommand.System.Displays.Save, this.onSaveResult)
  },
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.System.Displays.Get, this.setData)
    this.$bus.$off(window.SocketCommand.System.Displays.Save, this.onSaveResult)
  },
  methods: {
    setData (data) {
      if (!Array.isArray(data)) return

      this.viewData = data

      this.$nextTick(() => {
        this.initDisplays(this.viewData)
      })
    },

    initDisplays (data) {
      if (!Array.isArray(data)) data = []

      for (let i = 0; i < data.length; i++) {
        const tmpDisplays = []
        const machine = data[i]

        for (let p = 0; p < machine.displays.length; p++) {
          const display = machine.displays[p]
          const tmp = clone(this.tmpObjDefault)

          const defaultDisplay = machine.displays_default.find(d => d.id === display.id)
          tmp.id = defaultDisplay?.id || display.id
          tmp.label = defaultDisplay?.label || display.label
          tmp.primary = defaultDisplay?.primary || display.primary
          tmp.bounds = defaultDisplay?.bounds || display.bounds
          tmp.auto_open = defaultDisplay?.auto_open || tmp.auto_open
          tmp.cds_status = defaultDisplay?.cds_status || tmp.cds_status
          tmpDisplays.push(tmp)
        }

        this.$set(machine, 'displays_save', tmpDisplays)
      }
    },

    toggleCDS (display) {
      if (!display) return

      const cdsUrl = `${ process.env.VUE_APP_POS_URL }cds?lang=${ this.$i18n?.locale || 'el' }`
      const ipcRenderer = ipcRendererElectron()
      ipcRenderer.send(ipcCommandsEnum.ToggleCds, {
        ...display,
        cds_url: cdsUrl
      })
    },

    onUpdateSystemDisplaysClick () {
      window.callAS(window.SocketCommand.System.Displays.Set, {
        guid    : this.$DataStore.app.guid,
        hostname: this.$DataStore.app.computer.hostname,
        displays: this.systemDisplays
      })
    },

    getData () {
      window.callAS(window.SocketCommand.System.Displays.Get, {})
    },

    onCdsStatusChange (display, machine) {
      for (let i = 0; i < this.viewData.length; i++) {
        const m = this.viewData[i]
        if (m.guid === machine.guid) {
          for (let p = 0; p < machine.displays_save.length; p++) {
            const displaySave = machine.displays_save[p]
            if (display.id !== displaySave.id) {
              displaySave.cds_status = '0'
            }
          }
        }
      }
    },

    onCancelButtonClick () {
      this.$router.replace({ name: 'SystemSettings' })
    },

    onSaveButtonClick () {
      this.isSaving = true
      window.callAS(window.SocketCommand.System.Displays.Save, { machines: this.viewData })
    },

    onSaveResult (data) {
      this.isSaving = false

      if (data.status) {
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Display.Notification.Success'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Display.Notification.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-expansion-panel-content__wrap {
  padding : 0;
}

/deep/ .displays-group .v-toolbar__content {
  padding : 0;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  width    : 75px !important;
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
