<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.TableAreaAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.TableAreaAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!itemToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.TableAreaAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.TableAreaAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.TableAreaAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.TableAreaAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="tableAreaForm"
            v-model="tableAreaFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editTableArea.Name"
                    :counter="255"
                    :hint="$t('Restaurant.TableAreaAddDialog.Field.Name.Hint')"
                    :label="$t('Restaurant.TableAreaAddDialog.Field.Name.Label')"
                    :prepend-icon="$t('Restaurant.TableAreaAddDialog.Field.Name.Icon')"
                    :rules="validationRules.tableAreaForm.Name"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editTableArea.Priority"
                    :hint="$t('Restaurant.TableAreaAddDialog.Field.Priority.Hint')"
                    :label="$t('Restaurant.TableAreaAddDialog.Field.Priority.Label')"
                    :prepend-icon="$t('Restaurant.TableAreaAddDialog.Field.Priority.Icon')"
                    :rules="validationRules.tableAreaForm.Priority"
                    clearble
                    type="number"
                    step="1"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-combobox
                    v-model="editTableArea.Tags"
                    :items="editTableArea.Tags"
                    :search-input.sync="addNewTagSearch"
                    :label="$t('Restaurant.TableAreaAddDialog.Field.Tags.Label')"
                    :hint="$t('Restaurant.TableAreaAddDialog.Field.Tags.Hint')"
                    :prepend-icon="$t('Restaurant.TableAreaAddDialog.Field.Tags.Icon')"
                    :rules="validationRules.tableAreaForm.Tags"
                    deletable-chips
                    persistent-hint
                    hide-selected
                    multiple
                    small-chips
                    type="text"
                  >
                    <template #no-data>
                      <v-list-item>
                        <span class="text-subtitle-1 mr-2">{{ $t('Common.Button.Add') }} Tag: </span>

                        <v-chip
                          v-show="addNewTagSearch"
                          label
                          small
                        >
                          {{ addNewTagSearch }}
                        </v-chip>
                      </v-list-item>
                    </template>

                    <template #selection="{item, parent, selected}">
                      <v-chip
                        :value="selected"
                        label
                        small
                      >
                        <v-icon
                          small
                          left
                        >
                          mdi mdi-tag
                        </v-icon>

                        <span class="pr-2">
                          {{ item }}
                        </span>

                        <v-icon
                          small
                          @click="parent.selectItem(item)"
                        >
                          close
                        </v-icon>
                      </v-chip>
                    </template>

                    <template #item="{item}">
                      <v-list-item-content>
                        <v-chip
                          dark
                          label
                          small
                        >
                          {{ item }}
                        </v-chip>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editTableArea.CatalogId"
                    :items="catalogs"
                    :label="$tc('Restaurant.Catalogs.Title', 1)"
                    prepend-icon="menu_book"
                    item-text="Name"
                    item-value="Id"
                    class="mt-4"
                    clearable
                  >
                    <template #selection="{item}">
                      <span class="">{{ item.Name }}</span>
                    </template>

                    <template #item="{item}">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.Description }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>

                  <v-switch
                    v-model="editTableArea.Status"
                    inset
                    :label="editTableArea.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0 d-inline-block"
                    color="success"
                  />
                </v-col>

                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.TableAreaAddDialog.Field.Bookable.Label') }}
                  </v-subheader>

                  <v-switch
                    v-model="editTableArea.Bookable"
                    inset
                    :label="editTableArea.Bookable ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0 d-inline-block"
                    color="indigo"
                  />
                </v-col>

                <v-col cols="4">
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.TableAreaAddDialog.Field.BookableOnline.Label') }}
                  </v-subheader>

                  <v-switch
                    v-model="editTableArea.BookableOnline"
                    inset
                    :label="editTableArea.BookableOnline ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0 d-inline-block"
                    color="blue"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <div class="grey--text pt-2">
                    {{ $t('Restaurant.TableAreaAddDialog.Field.Color.Label') }}
                  </div>
                  <color-picker
                    v-model="editTableArea.TmpColor"
                    style="width: 100%"
                    :style="isDarkMode ? 'background-color: #191919;' : 'background-color: white;'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData        from '../../../../mixins/appdata'
import { Swatches }   from 'vue-color'
import Auth           from '@/mixins/auth'
import SourceTypeEnum from '@/api/Enums/SourceTypeEnum'

export default {
  name      : 'TableAreaAddDialog',
  components: {
    'color-picker': Swatches
  },
  mixins: [AppData, Auth],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading           : false,
      tableAreaFormValid: false,
      categories        : [],
      addNewTagSearch   : '',
      newTableArea      : null,
      tmpTableArea      : {
        Id            : null,
        CatalogId     : null,
        Color         : 'B71C1C',
        Priority      : '',
        Bookable      : true,
        BookableOnline: false,
        Tags          : [],
        TmpColor      : {
          hex   : '',
          source: ''
        },
        Name  : '',
        Status: true
      },
      validationRules: {
        tableAreaForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 255 || this.$t('Restaurant.TableAreaAddDialog.Field.Name.Error.Between'),
            (v) => v && /^[A-Za-zΑ-Ωα-ωίϊΐόάέύϋΰήώΊΪΌΆΈΎΫΉΏ0-9 /-:]*$/.test(v) || this.$t('Restaurant.TableAreaAddDialog.Field.Name.Error.Regex')
          ],
          Priority: [
            (v) => !v || (v && !isNaN(v)) || this.$t('Restaurant.TableAreaAddDialog.Field.Priority.Error.Number'),
            (v) => !v || (v && parseInt(v) > 0) || this.$t('Restaurant.TableAreaAddDialog.Field.Priority.Error.GreaterThanZero')
          ],
          Tags: [
            (v) => !v || (v && v.length <= 5) || this.$t('Restaurant.TableAreaAddDialog.Field.Tags.Error.Length'),
            (v) => !v || (v && !v.some(item => !/^[A-Za-zΑ-Ωα-ωίϊΐόάέύϋΰήώΊΪΌΆΈΎΫΉΏ0-9 /-:]*$/.test(item))) || this.$t('Restaurant.TableAreaAddDialog.Field.Tags.Error.Regex')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    catalogs () {
      const activeCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => catalog.Status) || []

      return activeCatalogs.filter(catalog => catalog.SourceTypes.includes(SourceTypeEnum.TABLE))
    },

    editTableArea: {
      get () {
        if (this.itemToEdit) {
          const c = JSON.parse(JSON.stringify(this.itemToEdit)).Color
          this.newTableArea = JSON.parse(JSON.stringify(this.itemToEdit))
          this.newTableArea.TmpColor = {
            hex   : c ? '#' + c : '',
            source: 'hex'
          }
        } else {
          const c = JSON.parse(JSON.stringify(this.tmpTableArea)).Color
          this.newTableArea = JSON.parse(JSON.stringify(this.tmpTableArea))
          this.newTableArea.TmpColor = {
            hex   : c ? '#' + c : '',
            source: 'hex'
          }
        }

        return this.newTableArea
      }
    }
  },
  watch: {
    'editTableArea.BookableOnline': function (newVal) {
      if (newVal) this.editTableArea.Bookable = true
    },

    visible (newVal) {
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.tableAreaForm) this.$refs.tableAreaForm.resetValidation()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Table.Area.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Table.Area.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.tableAreaForm.validate()

      if (this.tableAreaFormValid) {
        this.editTableArea.Color = JSON.parse(JSON.stringify(this.editTableArea)).TmpColor.hex.replace('#', '')
        this.loading = true

        window.callAS(window.SocketCommand.Table.Area.Save, this.editTableArea)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.TableAreaAddDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.tableAreaForm) this.$refs.tableAreaForm.resetValidation()
      this.newTableArea = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
.vc-swatches {
  min-width  : 472px;
  height     : auto;
  overflow-y : auto;
  zoom       : 75%;
}

/deep/ .vc-swatches .vc-swatches-box div.vc-swatches-color-group:last-child {
  display : none;
}

/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

</style>
