<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          extension-height="40"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              local_offer
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!couponToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Marketing.Coupon.Dialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Marketing.Coupon.Dialog.Toolbar.Add.SubTitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Marketing.Coupon.Dialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Marketing.Coupon.Dialog.Toolbar.Edit.SubTitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs
              v-model="tab"
              grow
              height="40"
              color="indigo"
              slider-color="indigo"
            >
              <v-tab
                key="general"
                href="#general"
              >
                {{ $t('Marketing.Coupon.Dialog.Toolbar.Tab.General').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab
                key="restrictions"
                href="#restrictions"
              >
                {{ $t('Marketing.Coupon.Dialog.Toolbar.Tab.Restrictions').toLocaleUpperCase($i18n.locale) }}
              </v-tab>

              <v-tab
                v-if="editCoupon.Id"
                key="history"
                href="#history"
              >
                {{ $t('Marketing.Coupon.Dialog.Toolbar.Tab.History').toLocaleUpperCase($i18n.locale) }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="couponForm"
            v-model="couponFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="tab">
              <v-tab-item
                key="general"
                value="general"
              >
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editCoupon.Name"
                        :counter="128"
                        :error-messages="errorMessages.couponForm.Name"
                        :hint="$t('Marketing.Coupon.Dialog.Field.Name.Hint')"
                        :label="$t('Marketing.Coupon.Dialog.Field.Name.Label')"
                        :rules="validationRules.couponForm.Name"
                        :maxlength="128"
                        prepend-icon="local_offer"
                        required
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editCoupon.Code"
                        :counter="15"
                        :maxlength="15"
                        :error-messages="errorMessages.couponForm.Code"
                        :hint="$t('Marketing.Coupon.Dialog.Field.Code.Hint')"
                        :label="$t('Marketing.Coupon.Dialog.Field.Code.Label')"
                        :rules="validationRules.couponForm.Code"
                        prepend-icon="mdi mdi-tag-text"
                        required
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <div class="grey--text text--darken-1 ma-0 pb-1">
                        {{ $t('Marketing.Coupon.Dialog.Field.Type.Title') }}
                      </div>
                      <v-radio-group
                        v-model="editCoupon.Type"
                        class="pa-0"
                        row
                      >
                        <v-radio
                          :label="$t('Marketing.Coupon.Dialog.Field.Type.RadioOption.DiscountPercent')"
                          value="P"
                        />
                        <v-radio
                          :label="$t('Marketing.Coupon.Dialog.Field.Type.RadioOption.FixedPrice')"
                          value="F"
                        />
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <div class="grey--text text--darken-1 ma-0 pb-1">
                        {{ editCoupon.Type === 'P' ? $t('Marketing.Coupon.Dialog.Field.Discount.Label.DiscountPercent') : $t('Marketing.Coupon.Dialog.Field.Discount.Label.FixedPrice') }}
                      </div>

                      <v-text-field
                        v-model.lazy="editCoupon.Discount"
                        :error-messages="errorMessages.couponForm.Discount"
                        :placeholder="editCoupon.Type === 'P' ? $t('Marketing.Coupon.Dialog.Field.Discount.Label.DiscountPercent') : $t('Marketing.Coupon.Dialog.Field.Discount.Label.FixedPrice')"
                        :hint="$t('Marketing.Coupon.Dialog.Field.Discount.Hint')"
                        :prepend-icon="editCoupon.Type === 'P' ? 'fas fa-percentage' : appCurrency.Icon"
                        :rules="validationRules.couponForm.Discount"
                        class="pt-0"
                        required
                        type="number"
                        min="0"
                        step="any"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <div class="grey--text text--darken-1 mt-2 mb-1">
                        {{ $t('Marketing.Coupon.Dialog.Field.Status.Title') }}
                      </div>
                      <v-switch
                        v-model="editCoupon.Status"
                        :label="editCoupon.Status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class=""
                        color="success"
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="6"
                    >
                      <div class="grey--text text--darken-1 mt-2 mb-1">
                        {{ $t('Marketing.Coupon.Dialog.Field.AutoApply.Title') }}
                      </div>
                      <v-switch
                        v-model="editCoupon.AutoApply"
                        :label="editCoupon.AutoApply ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        :disabled="!!editCoupon.Customers.length || !!editCoupon.CustomerGroups.length"
                        class=""
                        color="success"
                        inset
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <div class="grey--text text--darken-1 pb-1">
                        {{ $t('Marketing.Coupon.Dialog.Field.Validity.Title') }}
                      </div>
                      <v-btn-toggle
                        v-model="editCoupon.Validity"
                        :class="[{'d-inline-block': $vuetify.breakpoint.xsOnly}]"
                        :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                        dense
                        mandatory
                      >
                        <v-btn
                          :block="$vuetify.breakpoint.xsOnly"
                          :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                          text
                          value="forever"
                        >
                          {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.Always.Title') }}
                        </v-btn>
                        <v-btn
                          :block="$vuetify.breakpoint.xsOnly"
                          :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                          text
                          value="fixed"
                        >
                          {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.DateTime.Title') }}
                        </v-btn>
                        <v-btn
                          :block="$vuetify.breakpoint.xsOnly"
                          :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                          text
                          value="period"
                        >
                          {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.Period.Title') }}
                        </v-btn>
                        <v-btn
                          :block="$vuetify.breakpoint.xsOnly"
                          :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                          text
                          value="recurring"
                        >
                          {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.Repeat.Title') }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-container
                        class="pa-0"
                        fluid
                      >
                        <template v-if="editCoupon.Validity === 'fixed'">
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-btn-toggle
                                v-model="editCoupon.FixedTime"
                                :class="[{'d-inline-block': $vuetify.breakpoint.xsOnly}]"
                                :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                                class="mb-2"
                                dense
                                mandatory
                              >
                                <v-btn
                                  :block="$vuetify.breakpoint.xsOnly"
                                  text
                                  value="24hours"
                                >
                                  {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.DateTime.Options.TwentyFourHours') }}
                                </v-btn>
                                <v-btn
                                  :block="$vuetify.breakpoint.xsOnly"
                                  text
                                  value="custom"
                                >
                                  {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.DateTime.Options.Custom') }}
                                </v-btn>
                              </v-btn-toggle>
                            </v-col>

                            <v-col cols="12">
                              <v-menu
                                ref="menuFixedDateVisible"
                                v-model="menuFixedDateVisible"
                                :close-on-content-click="false"
                                max-width="290px"
                                min-width="290px"
                                offset-y
                                transition="scale-transition"
                              >
                                <template #activator="{on}">
                                  <v-text-field
                                    v-model="editCoupon.FixedDateFrm"
                                    :error-messages="errorMessages.couponForm.FixedDate"
                                    :label="$t('Common.Date.Title')"
                                    :required="editCoupon.Validity === 'fixed'"
                                    prepend-icon="event"
                                    readonly
                                    style="width: 100%;"
                                    @blur="editCoupon.FixedDate = parseDate(editCoupon.FixedDateFrm)"
                                    v-on="on"
                                  />
                                </template>

                                <v-date-picker
                                  v-model="editCoupon.FixedDate"
                                  :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                                  first-day-of-week="1"
                                  scrollable
                                  @input="editCoupon.FixedDateFrm = formatDate($event); menuFixedDateVisible = false"
                                />
                              </v-menu>
                            </v-col>

                            <template v-if="editCoupon.FixedTime === 'custom'">
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-menu
                                  v-model="menuFixedFromTimeVisible"
                                  :close-on-content-click="false"
                                  offset-y
                                  transition="scale-transition"
                                >
                                  <template #activator="{on}">
                                    <v-text-field
                                      v-model="editCoupon.FixedFromTime"
                                      :error-messages="errorMessages.couponForm.FixedFromTime"
                                      :label="`${$t('Common.Date.Time')} ${$t('Common.Date.PeriodFrom')}`"
                                      :required="editCoupon.Validity === 'fixed' && editCoupon.FixedTime === 'custom'"
                                      prepend-icon="access_time"
                                      readonly
                                      v-on="on"
                                    />
                                  </template>

                                  <v-time-picker
                                    v-model="editCoupon.FixedFromTime"
                                    :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                                    first-day-of-week="1"
                                    format="24hr"
                                    scrollable
                                    @input="menuFixedFromTimeVisible = false"
                                  />
                                </v-menu>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-menu
                                  v-model="menuFixedToTimeVisible"
                                  :close-on-content-click="false"
                                  offset-y
                                  transition="scale-transition"
                                >
                                  <template #activator="{on}">
                                    <v-text-field
                                      v-model="editCoupon.FixedToTime"
                                      :error-messages="errorMessages.couponForm.FixedToTime"
                                      :label="`${$t('Common.Date.Time')} ${$t('Common.Date.PeriodTo')}`"
                                      :required="editCoupon.Validity === 'fixed' && editCoupon.FixedTime === 'custom'"
                                      prepend-icon="access_time"
                                      readonly
                                      v-on="on"
                                    />
                                  </template>

                                  <v-time-picker
                                    v-model="editCoupon.FixedToTime"
                                    :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                                    first-day-of-week="1"
                                    format="24hr"
                                    scrollable
                                    @input="menuFixedToTimeVisible = false"
                                  />
                                </v-menu>
                              </v-col>
                            </template>
                          </v-row>
                        </template>

                        <template v-else-if="editCoupon.Validity === 'period'">
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="6"
                            >
                              <v-menu
                                ref="menuPeriodStartDateVisible"
                                v-model="menuPeriodStartDateVisible"
                                :close-on-content-click="false"
                                max-width="290px"
                                min-width="290px"
                                offset-y
                                transition="scale-transition"
                              >
                                <template #activator="{on}">
                                  <v-text-field
                                    v-model="editCoupon.PeriodStartDateFrm"
                                    :error-messages="errorMessages.couponForm.PeriodStartDate"
                                    :label="$t('Common.Date.Title')+' '+$t('Common.Date.PeriodFrom')"
                                    :required="editCoupon.Validity === 'period'"
                                    prepend-icon="event"
                                    readonly
                                    style="width: 100%;"
                                    @blur="editCoupon.PeriodStartDate = parseDate(editCoupon.PeriodStartDateFrm)"
                                    v-on="on"
                                  />
                                </template>

                                <v-date-picker
                                  v-model="editCoupon.PeriodStartDate"
                                  :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                                  first-day-of-week="1"
                                  scrollable
                                  @input="editCoupon.PeriodStartDateFrm = formatDate($event); menuPeriodStartDateVisible = false"
                                />
                              </v-menu>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                            >
                              <v-menu
                                ref="menuPeriodEndDateVisible"
                                v-model="menuPeriodEndDateVisible"
                                :close-on-content-click="false"
                                max-width="290px"
                                min-width="290px"
                                offset-y
                                transition="scale-transition"
                              >
                                <template #activator="{on}">
                                  <v-text-field
                                    v-model="editCoupon.PeriodEndDateFrm"
                                    :error-messages="errorMessages.couponForm.PeriodEndDate"
                                    :label="$t('Common.Date.Title')+' '+$t('Common.Date.PeriodTo')"
                                    :required="editCoupon.Validity === 'period'"
                                    prepend-icon="event"
                                    readonly
                                    style="width: 100%;"
                                    @blur="editCoupon.PeriodEndDate = parseDate(editCoupon.PeriodEndDateFrm)"
                                    v-on="on"
                                  />
                                </template>

                                <v-date-picker
                                  v-model="editCoupon.PeriodEndDate"
                                  :formatted-value.sync="editCoupon.PeriodEndDateFrm"
                                  :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                                  first-day-of-week="1"
                                  scrollable
                                  style="width: 320px;"
                                  @input="editCoupon.PeriodEndDateFrm = formatDate($event); menuPeriodEndDateVisible = false"
                                />
                              </v-menu>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-else-if="editCoupon.Validity === 'recurring'">
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-btn-toggle
                                v-model="editCoupon.RecurringEvery"
                                :class="[{'d-inline-block': $vuetify.breakpoint.xsOnly}]"
                                :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                                dense
                                mandatory
                                multiple
                              >
                                <v-btn
                                  v-for="(weekDay, index) in Weekdays"
                                  :key="index"
                                  :block="$vuetify.breakpoint.xsOnly"
                                  :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                                  text
                                >
                                  {{ weekDay }}
                                </v-btn>
                              </v-btn-toggle>
                            </v-col>
                            <v-col cols="12">
                              <v-btn-toggle
                                v-model="editCoupon.RecurringTime"
                                :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                                class="mt-2 mb-2"
                                dense
                                mandatory
                              >
                                <v-btn
                                  :block="$vuetify.breakpoint.xsOnly"
                                  :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                                  text
                                  value="24hours"
                                >
                                  {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.Repeat.Options.TwentyFourHours') }}
                                </v-btn>
                                <v-btn
                                  :block="$vuetify.breakpoint.xsOnly"
                                  :style="$vuetify.breakpoint.xsOnly ? 'width: 100%;' : ''"
                                  text
                                  value="custom"
                                >
                                  {{ $t('Marketing.Coupon.Dialog.Field.Validity.Toggle.Repeat.Options.Custom') }}
                                </v-btn>
                              </v-btn-toggle>
                            </v-col>

                            <template v-if="editCoupon.RecurringTime === 'custom'">
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-menu
                                  v-model="menuRecurringFromTimeVisible"
                                  :close-on-content-click="false"
                                  offset-y
                                  transition="scale-transition"
                                >
                                  <template #activator="{on}">
                                    <v-text-field
                                      v-model="editCoupon.RecurringFromTime"
                                      :error-messages="errorMessages.couponForm.RecurringFromTime"
                                      :label="$t('Common.Date.Time')+' '+$t('Common.Date.PeriodFrom')"
                                      :required="editCoupon.Validity === 'recurring' && editCoupon.RecurringTime === 'custom'"
                                      prepend-icon="access_time"
                                      readonly
                                      v-on="on"
                                    />
                                  </template>

                                  <v-time-picker
                                    v-model="editCoupon.RecurringFromTime"
                                    :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                                    first-day-of-week="1"
                                    format="24hr"
                                    scrollable
                                    @input="menuRecurringFromTimeVisible = false"
                                  />
                                </v-menu>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-menu
                                  v-model="menuRecurringToTimeVisible"
                                  :close-on-content-click="false"
                                  offset-y
                                  transition="scale-transition"
                                >
                                  <template #activator="{on}">
                                    <v-text-field
                                      v-model="editCoupon.RecurringToTime"
                                      :error-messages="errorMessages.couponForm.RecurringToTime"
                                      :label="$t('Common.Date.Time')+' '+$t('Common.Date.PeriodTo')"
                                      :required="editCoupon.Validity === 'recurring' && editCoupon.RecurringTime === 'custom'"
                                      prepend-icon="access_time"
                                      readonly
                                      v-on="on"
                                    />
                                  </template>

                                  <v-time-picker
                                    v-model="editCoupon.RecurringToTime"
                                    :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                                    first-day-of-week="1"
                                    format="24hr"
                                    scrollable
                                    @input="menuRecurringToTimeVisible = false"
                                  />
                                </v-menu>
                              </v-col>
                            </template>
                          </v-row>
                        </template>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                key="restrictions"
                value="restrictions"
              >
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model.lazy="editCoupon.MinTotal"
                        :error-messages="errorMessages.couponForm.MinTotal"
                        :hint="$t('Marketing.Coupon.Dialog.Field.MinTotal.Hint')"
                        :label="$t('Marketing.Coupon.Dialog.Field.MinTotal.Label')"
                        :prepend-icon="appCurrency.Icon"
                        :rules="validationRules.couponForm.MinTotal"
                        required
                        type="number"
                        min="0"
                        step="any"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <div class="grey--text text--darken-1 ma-0 pb-1">
                        {{ $t('Marketing.Coupon.Dialog.Field.OrderRestriction.Title') }}
                      </div>

                      <v-radio-group
                        v-model="editCoupon.OrderRestriction"
                        class="pa-0"
                        row
                      >
                        <v-radio
                          :label="$t('Marketing.Coupon.Dialog.Field.OrderRestriction.RadioOption.All')"
                          value="0"
                        />
                        <v-radio
                          :label="$t('Marketing.Coupon.Dialog.Field.OrderRestriction.RadioOption.Delivery')"
                          value="1"
                        />
                        <v-radio
                          :label="$t('Marketing.Coupon.Dialog.Field.OrderRestriction.RadioOption.TakeAway')"
                          value="2"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editCoupon.Redemptions"
                        v-mask="'##########'"
                        :disabled="editCoupon.AutoApply"
                        :error-messages="errorMessages.couponForm.Redemptions"
                        :hint="$t('Marketing.Coupon.Dialog.Field.Redemptions.Hint')"
                        :label="$t('Marketing.Coupon.Dialog.Field.Redemptions.Label')"
                        :rules="validationRules.couponForm.Redemptions"
                        prepend-icon="redeem"
                        required
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editCoupon.CustomerRedemptions"
                        v-mask="'##########'"
                        :disabled="editCoupon.AutoApply"
                        :error-messages="errorMessages.couponForm.CustomerRedemptions"
                        :hint="$t('Marketing.Coupon.Dialog.Field.CustomerRedemptions.Hint')"
                        :label="$t('Marketing.Coupon.Dialog.Field.CustomerRedemptions.Label')"
                        :rules="validationRules.couponForm.Redemptions"
                        prepend-icon="redeem"
                        required
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        v-model="editCoupon.SourceType"
                        :hint="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Hint')"
                        :items="sourceTypes"
                        :label="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Label')"
                        :prepend-icon="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Icon')"
                        :rules="validationRules.couponForm.SourceType"
                        item-text="Name"
                        item-value="Id"
                        multiple
                      >
                        <template #selection="{item}">
                          <v-chip
                            :color="item.Status ? 'green' : ''"
                            :dark="item.Status"
                            class="pl-0"
                            label
                            small
                          >
                            <v-avatar
                              :color="item.Avatar.Color"
                              class="mr-2"
                              tile
                            >
                              <v-icon
                                v-if="item.Avatar.Icon"
                                class="white--text"
                              >
                                {{ item.Avatar.Icon }}
                              </v-icon>

                              <img
                                v-else
                                :src="item.Avatar.Img"
                              >
                            </v-avatar>
                            <span>{{ item.Name }}</span>
                          </v-chip>
                        </template>

                        <template #item="data">
                          <v-list-item-avatar
                            :color="data.item.Avatar.Color"
                            :size="28"
                          >
                            <v-icon
                              v-if="data.item.Avatar.Icon"
                              class="white--text"
                            >
                              {{ data.item.Avatar.Icon }}
                            </v-icon>

                            <img
                              v-else
                              :src="data.item.Avatar.Img"
                            >
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="editCoupon.Categories"
                        :items="categories"
                        :label="$t('Restaurant.Menu.Filter.filterCategory.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Categories.Icon')"
                        item-value="Id"
                        item-text="Name"
                        class="pr-2"
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        required
                      >
                        <template #selection="data">
                          <v-chip
                            :input-value="data.selected"
                            close
                            label
                            small
                            v-bind="data.attrs"
                            @click="data.select"
                            @click:close="removeCategory(data.item.Id)"
                          >
                            {{ getTranslatedField(data.item, 'Name', 'Lang') }}
                          </v-chip>
                        </template>

                        <template #item="data">
                          <v-list-item-avatar
                            size="34"
                            :color="`#${data.item.Color}`"
                          >
                            <template v-if="data.item.Avatar.Img">
                              <v-img :src="data.item.Avatar.Img" />
                            </template>

                            <template v-else>
                              <v-icon :color="data.item.Avatar.Color">
                                {{ data.item.Avatar.Icon }}
                              </v-icon>
                            </template>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title v-html="getTranslatedField(data.item, 'Name', 'Lang')" />
                            <v-list-item-subtitle v-html="data.item.Description" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <div class="grey--text text--darken-1 ma-0 pb-1">
                        <v-icon>{{ $t('Customer.CustomerAddEditDialog.Field.Info.Group.Icon') }}</v-icon>
                        {{ $tc('Customer.Title', 2) }}
                      </div>

                      <div>
                        <v-btn
                          :v-if="posUserHasComponentPermission('Customers', 'ACCESS')"
                          class="d-inline-block"
                          color="blue-grey"
                          dark
                          depressed
                          height="24"
                          small
                          @click="customerSearchDialogVisible = true"
                        >
                          <v-icon left>
                            add
                          </v-icon>
                          {{ $t('Customer.CustomerAddEditDialog.Toolbar.Add.Title').toLocaleUpperCase($i18n.locale) }}
                        </v-btn>

                        <template v-for="(customer, index) in editCoupon.Customers">
                          <v-chip
                            :key="`customer-${customer.Id}`"
                            class="pl-0 ma-1"
                            close
                            color="grey lighten-2"
                            label
                            small
                            @click:close="removeCustomer(index)"
                          >
                            <v-avatar
                              class="mr-2 px-4"
                              color="blue-grey"
                              tile
                            >
                              <v-icon class="white--text">
                                people
                              </v-icon>
                            </v-avatar>
                            <span>{{ customer.Name }}</span>
                          </v-chip>
                        </template>
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                        v-model="editCoupon.CustomerGroups"
                        :hint="$t('Customer.CustomersExportDialog.Field.Group.Hint')"
                        :items="customerGroups"
                        :label="$t('Customer.CustomersExportDialog.Field.Group.Label')"
                        item-text="Name"
                        item-value="Id"
                        multiple
                      >
                        <template #selection="data">
                          <v-chip
                            :input-value="data.selected"
                            close
                            label
                            small
                            v-bind="data.attrs"
                            @click="data.select"
                            @click:close="removeCustomerGroup(data.item.Id)"
                          >
                            {{ data.item.Name }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="tab === 'history' && editCoupon.Id"
                key="history"
                value="history"
              >
                <v-data-table
                  :footer-props="dataTable.footerProps"
                  :headers="dataTableHeaders"
                  :height="dataTable.resize.bodyHeight"
                  :items="dataTable.data"
                  :loading="dataTable.loading"
                  :loading-text="dataTable.text.loadingText"
                  :no-data-text="dataTable.text.noDataText"
                  :no-results-text="dataTable.text.noResultsText"
                  :options.sync="dataTable.options"
                  :search="dataTable.search.terms"
                  :server-items-length="dataTable.total"
                  fixed-header
                  item-key="Id"
                >
                  <template #[`item.coupon_history_id`]="{item}">
                    {{ item.Id }}
                  </template>

                  <template #[`item.customer_id`]="{item}">
                    <v-btn
                      :disabled="item.CustomerId <= 0"
                      text
                      small
                      class="pa-1"
                      @click="showCustomerDetailsDialog(item.CustomerId)"
                    >
                      {{ item.CustomerName }}
                    </v-btn>
                  </template>

                  <template #[`item.order_id`]="{item}">
                    {{ item.OrderId }}
                  </template>

                  <template #[`item.order_total`]="{item}">
                    {{ item.OrderTotal }}
                  </template>

                  <template #[`item.amount`]="{item}">
                    {{ item.Amount }}
                  </template>

                  <template #[`item.date_used`]="{item}">
                    {{ item.UsedAt }}
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <customer-search-dialog
      v-if="posUserHasComponentPermission('Customers', 'ACCESS')"
      :customer-search-dialog-visible.sync="customerSearchDialogVisible"
      :offset="10"
      :search-filter.sync="customerSearch"
    />
  </div>
</template>

<script>
import AppData              from '@/mixins/appdata'
import SourceType           from '@/mixins/orders/sourceType'
import Auth                 from '@/mixins/auth'
import CustomerSearchDialog from '@/components/customers/CustomerSearchDialog'
import DataTable            from '@/mixins/data-table/dataTable'
import Translatable         from '@/mixins/translatable'

export default {
  components: { CustomerSearchDialog },
  mixins    : [Auth, AppData, DataTable, Translatable, SourceType],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    couponToEdit: {
      type   : Object,
      default: undefined
    },
    categories: {
      type   : Array,
      default: () => []
    },
    customerGroups: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      tab                         : 'general',
      filter                      : null,
      customerSearchDialogVisible : false,
      customerSearch              : '',
      menuFixedDateVisible        : false,
      menuFixedFromTimeVisible    : false,
      menuFixedToTimeVisible      : false,
      menuPeriodStartDateVisible  : false,
      menuPeriodEndDateVisible    : false,
      menuRecurringFromTimeVisible: false,
      menuRecurringToTimeVisible  : false,
      couponFormValid             : false,
      loading                     : false,
      newCoupon                   : null,
      tmpCoupon                   : {
        Id                 : null,
        Name               : '',
        Code               : '',
        Type               : 'P',
        TypeName           : '',
        Discount           : null,
        MinTotal           : '0.00',
        Redemptions        : '0',
        CustomerRedemptions: '0',
        Description        : '',
        Status             : true,
        DateAdded          : '',
        Validity           : 'forever',
        ValidityName       : '',
        FixedDate          : null,
        FixedFromTime      : null,
        FixedToTime        : null,
        PeriodStartDate    : null,
        PeriodEndDate      : null,
        RecurringEvery     : [],
        RecurringFromTime  : null,
        RecurringToTime    : null,
        FixedTime          : '24hours',
        RecurringTime      : '24hours',
        AutoApply          : false,
        OrderRestriction   : '0',
        SourceType         : [0],
        Customers          : [],
        CustomerGroups     : [],
        Categories         : [],
        Menus              : []
      },
      Weekdays       : this.$t('Common.Date.WeekDays.Short'),
      validationRules: {
        couponForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 128 || this.$t('Marketing.Coupon.Dialog.Field.Name.Error.Between')
          ],
          Code: [
            (v) => v && v.length >= 2 && v.length <= 15 || this.$t('Marketing.Coupon.Dialog.Field.Code.Error.Between')
          ],
          Discount: [
            (v) => v && v.length > 0 || this.$t('Marketing.Coupon.Dialog.Field.Discount.Error.Number')
          ],
          Redemptions: [
            (v) => v && (v.length > 0 && !isNaN(v)) || this.$t('Marketing.Coupon.Dialog.Field.Redemptions.Error.Number')
          ],
          MinTotal: [
            (v) => v && v.length > 0 || this.$t('Marketing.Coupon.Dialog.Field.MinTotal.Error.Number')
          ],
          SourceType: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Error.Required')
          ]
        }
      },
      errorMessages: {
        couponForm: {
          Name               : [],
          Code               : [],
          Discount           : [],
          Redemptions        : [],
          CustomerRedemptions: [],
          MinTotal           : [],
          FixedDate          : [],
          FixedFromTime      : [],
          FixedToTime        : [],
          PeriodStartDate    : [],
          PeriodEndDate      : [],
          RecurringFromTime  : [],
          RecurringToTime    : [],
          SourceType         : [],
          Customers          : [],
          CustomerGroups     : [],
          Categories         : [],
          Menus              : []
        }
      },
      dataTable: {
        autoload     : false,
        socketCommand: {
          list: window.SocketCommand.Coupon.History
        },
        payload: {
          id: null
        },
        options: {
          page         : 1,
          itemsPerPage : 10,
          defaultSortBy: 'coupon_history_id',
          sortBy       : ['coupon_history_id'],
          sortDesc     : [true]
        },
        resize: {
          enabled   : true,
          offset    : 200,
          bodyHeight: 0
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Marketing.Coupon.Dialog.History.Table.Headers.Id'),
            value    : 'coupon_history_id',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.Dialog.History.Table.Headers.Customer'),
            value    : 'customer_id',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.Dialog.History.Table.Headers.Order'),
            value    : 'order_id',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.Dialog.History.Table.Headers.OrderTotal'),
            value    : 'order_total',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.Dialog.History.Table.Headers.Amount'),
            value    : 'amount',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.Dialog.History.Table.Headers.Date'),
            value    : 'date_used',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          }
        ]
      }
    }
  },
  computed: {
    sourceTypes () {
      const sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT) || []

      return sourceTypesDefault.filter(sourceType => sourceType.active || sourceType.source_type_id === 0).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editCoupon: {
      get () {
        if (this.couponToEdit) {
          this.newCoupon = JSON.parse(JSON.stringify(this.couponToEdit))
          const nameArr = this.couponToEdit.Name.split(' ')
          this.newCoupon.Firstname = nameArr.length > 0 ? nameArr[0] : ''
          this.newCoupon.Lastname = nameArr.length > 1 ? nameArr[1] : ''
        } else {
          this.newCoupon = JSON.parse(JSON.stringify(this.tmpCoupon))
        }

        return this.newCoupon
      }
    }
  },
  watch: {
    'editCoupon.Customers': function (newVal, oldVal) {
      if (newVal.length > 0) this.editCoupon.AutoApply = false
    },
    'editCoupon.CustomerGroups': function (newVal, oldVal) {
      if (newVal.length > 0) this.editCoupon.AutoApply = false
    },

    'editCoupon.SourceType': function (newVal, oldVal) {
      if (newVal.length > 1) {
        if (newVal.includes(0) && !oldVal.includes(0)) {
          this.editCoupon.SourceType = [0]
        } else if (newVal.includes(0)) {
          this.editCoupon.SourceType = newVal.filter(sourceType => sourceType !== 0)
        }
      }
    },

    'editCoupon.AutoApply': function (newVal) {
      if (newVal) {
        this.editCoupon.Redemptions = '0'
        this.editCoupon.CustomerRedemptions = '0'
      }
    },

    tab (newVal) {
      if (newVal === 'history') {
        if (this.dataTable.payload.id === null) {
          this.dataTable.payload.id = this.editCoupon.Id
          this.getData()
        }
      }
    },

    isVisible (newVal) {
      if (newVal) {
        this.tab = 'general'
        this.$bus.$on(window.SocketCommand.Coupon.Save, this.onCouponSaveResult)
        this.$nextTick(() => {
          if (this.$refs.couponForm) this.$refs.couponForm.resetValidation()
        })
      } else {
        this.dataTable.payload.id = null
        this.dataTable.options.page = 1
        this.dataTable.total = 0
        this.dataTable.data = []
        this.dataTable.loaded = false
        this.$bus.$off(window.SocketCommand.Coupon.Save, this.onCouponSaveResult)
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Customer.Get + '-voip', this.setCustomer)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Customer.Get + '-voip', this.setCustomer)
  },
  methods: {
    removeCustomer (index) {
      this.editCoupon.Customers.splice(index, 1)
    },

    removeCustomerGroup (itemId) {
      const index = this.editCoupon.CustomerGroups.findIndex(item => item === itemId)
      if (index > -1) this.editCoupon.CustomerGroups.splice(index, 1)
    },

    removeCategory (itemId) {
      const index = this.editCoupon.Categories.findIndex(item => item === itemId)
      if (index > -1) this.editCoupon.Categories.splice(index, 1)
    },

    setCustomer (data) {
      if (!data?.Info) return

      this.editCoupon.Customers.push({
        Id  : data.Info.Id,
        Name: `${ data.Info.Firstname } ${ data.Info.Lastname }`
      })
    },

    showCustomerDetailsDialog (customerId) {
      this.$bus.$emit('show-customer-details-dialog', customerId)
    },

    onSaveButtonClick () {
      this.clearApiErrorMessages()
      this.$refs.couponForm.validate()

      if (this.couponFormValid) {
        this.loading = true
        window.callAS(window.SocketCommand.Coupon.Save, this.editCoupon)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      if (this.$refs.couponForm) this.$refs.couponForm.resetValidation()
      this.$nextTick(() => {
        this.newCoupon = null
      })
      this.clearApiErrorMessages()
    },
    onCouponSaveResult (result) {
      if (result.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.couponToEdit ? this.$t('Marketing.Coupon.Dialog.Message.Update.Success') : this.$t('Marketing.Coupon.Dialog.Message.Save.Success'),
          type: 'info',
          icon: 'info'
        })
        this.onCancelButtonClick()
      } else {
        // Set Error Messages from API to Fields
        let key
        for (key in result.Messages) {
          if (result.Messages.hasOwnProperty(key) && this.errorMessages.couponForm.hasOwnProperty(key)) {
            this.errorMessages.couponForm[key].push(result.Messages[key])
          }
        }

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },
    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      let key
      for (key in this.errorMessages.couponForm) {
        if (this.errorMessages.couponForm.hasOwnProperty(key)) {
          this.errorMessages.couponForm[key] = []
        }
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },
    parseDate (date) {
      if (!date) {
        return null
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    }

  }
}
</script>
