import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class MessageTypeValidator extends BaseValidator {
  rules = {
    Id: {
      required: true,
      type    : 'number'
    },
    GroupId: {
      required: true,
      type    : 'number'
    },
    GroupName: {
      required: true,
      type    : 'string'
    },
    Name: {
      required  : true,
      betweenLen: [2, 255],
      type      : 'string'
    },
    Color: {
      required: true,
      type    : 'string'
    },
    Icon: {
      required: true,
      type    : 'string'
    },
    Subject: {
      required  : true,
      betweenLen: [2, 255],
      type      : 'string'
    },
    Template: {
      required: false,
      type    : 'string'
    },
    Important: {
      required: true,
      type    : 'boolean'
    }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
