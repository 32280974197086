<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Tables.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Tables.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Tables.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Tables.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-form
          ref="settingsForm"
          v-model="settingsFormValid"
          autocomplete="off"
          @submit.prevent
        >
          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Tables.Tables.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Tables.Tables.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Tables.Tables.OpenTableWithDefaultPersons.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Tables.Tables.OpenTableWithDefaultPersons.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OpenTableWithDefaultPersons"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OpenTableWithDefaultPersons ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Tables.Tables.TableListExpandable.Enabled.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Tables.Tables.TableListExpandable.Enabled.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.TableListExpandableEnabled"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.TableListExpandableEnabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <template v-if="viewData.TableListExpandableEnabled">
                    <v-col cols="12">
                      <v-divider class="" />
                    </v-col>

                    <v-col cols="12">
                      <v-card flat>
                        <v-card-text class="pa-0 pl-4">
                          <v-container
                            fluid
                            class="pa-0"
                          >
                            <v-row
                              dense
                              align="center"
                            >
                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Tables.Tables.TableListExpandable.Mobile.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Tables.Tables.TableListExpandable.Mobile.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.TableListExpandableMobile"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.TableListExpandableMobile ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  color="success"
                                  class="ma-0"
                                  hide-details
                                />
                              </v-col>

                              <v-col
                                cols="12"
                                class="py-0"
                              />

                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Tables.Tables.TableListExpandable.Mobile.Multiple.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Tables.Tables.TableListExpandable.Mobile.Multiple.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.TableListExpandableMultipleMobile"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.TableListExpandableMultipleMobile ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  color="success"
                                  class="ma-0"
                                  hide-details
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="" />
                              </v-col>

                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Tables.Tables.TableListExpandable.Desktop.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Tables.Tables.TableListExpandable.Desktop.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.TableListExpandableDesktop"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.TableListExpandableDesktop ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  color="success"
                                  class="ma-0"
                                  hide-details
                                />
                              </v-col>

                              <v-col
                                cols="12"
                                class="py-0"
                              />

                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Tables.Tables.TableListExpandable.Desktop.Multiple.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Tables.Tables.TableListExpandable.Desktop.Multiple.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.TableListExpandableMultipleDesktop"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.TableListExpandableMultipleDesktop ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  color="success"
                                  class="ma-0"
                                  hide-details
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Tables.Orders.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Tables.Orders.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Tables.Orders.MenuOptionsDialogTableSeat.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Tables.Orders.MenuOptionsDialogTableSeat.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.MenuOptionsDialogTableSeat"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.MenuOptionsDialogTableSeat ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Tables.Orders.MenuOptionsDialogTableOrder.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Tables.Orders.MenuOptionsDialogTableOrder.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.MenuOptionsDialogTableOrder"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.MenuOptionsDialogTableOrder ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Tables.Waiters.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Tables.Waiters.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Tables.Waiters.WaiterCanManageAllTables.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Tables.Waiters.WaiterCanManageAllTables.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.WaiterCanManageAllTables"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.WaiterCanManageAllTables ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Tables.Waiters.WaiterCanChangeTableWaiter.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Tables.Waiters.WaiterCanChangeTableWaiter.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.WaiterCanChangeTableWaiter"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.WaiterCanChangeTableWaiter ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData      from '../../mixins/appdata'
import Auth         from '../../mixins/auth'
import Translatable from '@/mixins/translatable'

export default {
  name      : 'SystemSettingsTables',
  components: {},
  mixins    : [AppData, Auth, Translatable],
  data () {
    return {
      settingsFormValid          : false,
      menuOrdersDailyOpenVisible : false,
      menuOrdersDailyCloseVisible: false,
      isSaving                   : false,
      viewData                   : null,
      validationRules            : {}
    }
  },
  computed: {},
  watch   : {},
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Tables.Get, this.onGetDataResponse)
    this.$bus.$on(window.SocketCommand.Settings.Tables.Save, this.onSaveDataResponse)
    this.getData()
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Tables.Get, this.onGetDataResponse)
    this.$bus.$off(window.SocketCommand.Settings.Tables.Save, this.onSaveDataResponse)
  },
  methods: {
    getData () {
      window.callAS(window.SocketCommand.Settings.Tables.Get, {})
    },

    onGetDataResponse (result) {
      this.viewData = result
    },

    onSaveClick () {
      this.$refs?.settingsForm && this.$refs.settingsForm.validate()

      if (this.settingsFormValid) {
        this.isSaving = true
        const payload = JSON.parse(JSON.stringify(this.viewData))

        window.callAS(window.SocketCommand.Settings.Tables.Save, payload)
      }
    },

    onSaveDataResponse (result) {
      this.isSaving = false

      if (result.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Documents.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
