<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card v-if="domain">
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Domain.DatabaseMove.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Domain.DatabaseMove.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Domain.DatabaseMove.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Domain.DatabaseMove.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :value="getDomainName(domain)"
                    :label="$t('Settings.Domain.DatabaseMove.Field.Domain.Label')"
                    :prepend-icon="$t('Settings.Domain.DatabaseMove.Field.Domain.Icon')"
                    hide-details
                    readonly
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    v-model="domain.DatabaseConnection"
                    :hint="$t('Settings.Domain.DatabaseMove.Field.SourceDatabase.Hint')"
                    :items="databasesList"
                    :label="$t('Settings.Domain.DatabaseMove.Field.SourceDatabase.Label')"
                    :prepend-icon="$t('Settings.Domain.DatabaseMove.Field.SourceDatabase.Icon')"
                    :rules="[(v) => !!v || $t('Common.Error.Mandatory')]"
                    item-text="Name"
                    item-value="Id"
                    persistent-hint
                    required
                    readonly
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    v-model="targetDatabase"
                    :hint="$t('Settings.Domain.DatabaseMove.Field.TargetDatabase.Hint')"
                    :items="databasesListFiltered"
                    :label="$t('Settings.Domain.DatabaseMove.Field.TargetDatabase.Label')"
                    :prepend-icon="$t('Settings.Domain.DatabaseMove.Field.TargetDatabase.Icon')"
                    :rules="[(v) => !!v || $t('Common.Error.Mandatory')]"
                    item-text="Name"
                    item-value="Id"
                    persistent-hint
                    required
                  />
                </v-col>

                <v-col
                  class="py-2"
                  cols="12"
                />

                <v-col>
                  <v-icon left>
                    {{ $t('Settings.Domain.DatabaseMove.Field.WithDelete.Icon') }}
                  </v-icon>
                  <div class="d-inline-block">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Domain.DatabaseMove.Field.WithDelete.Title') }}
                    </h5>
                    <div class="text-caption">
                      {{ $t('Settings.Domain.DatabaseMove.Field.WithDelete.Subtitle') }}
                    </div>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="withDelete"
                    inset
                    :label="withDelete ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Move').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '../../../mixins/appdata'
import Auth    from '../../../mixins/auth'

export default {
  name      : 'DomainDatabaseMove',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    domain: {
      type   : Object,
      default: undefined
    },
    databases: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      dialogFormValid: false,
      targetDatabase : null,
      withDelete     : true
    }
  },
  computed: {
    databasesList () {
      return this.databases || []
    },

    databasesListFiltered () {
      return this.databasesList.filter(d => d.Id !== this.domain?.DatabaseConnection)
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Domains.MoveDB, this.onResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Domains.MoveDB, this.onResult)
  },
  methods: {
    getDomainName (d) {
      const Domain = d.Domain || ''
      const SubDomain = Domain.split('.').shift()
      const BaseDomain = Domain.split('.').slice(1).join('.').slice(0)
      return d ? `${ SubDomain }.${ d.BaseDomain || BaseDomain || '' }` : ''
    },

    onSaveButtonClick () {
      this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()

        const payload = {
          DomainId    : this.domain?.Id,
          AppId       : this.domain?.Application?.id,
          DatabaseName: this.domain?.DatabaseName,
          Source      : this.domain?.DatabaseConnection,
          Target      : this.targetDatabase,
          WithDelete  : this.withDelete
        }

        window.callAS(window.SocketCommand.Settings.Domains.MoveDB, payload)
      }
    },

    onResult (response) {
      if (response.status) {
        this.$bus.$emit('app-show-notification', {
          body: response?.type || '',
          type: 'success',
          icon: 'info'
        })
        this.onCancelButtonClick()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: response?.type || response?.result || '',
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onCancelButtonClick () {
      this.targetDatabase = null
      this.withDelete = true

      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
    }
  }
}
</script>
