<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-0">
        <toolbar-period-selector
          v-model="dataTable.filter.filter_date"
          default-period="today"
        >
          <v-btn
            v-if="userCanManage && (userIsOwner || userIsSuperAdmin)"
            :disabled="!dataTable.filter.filter_date"
            :color="$t('Customer.Table.Button.Export.Color')"
            :icon="$vuetify.breakpoint.xsOnly"
            class="elevation-0 ma-1"
            text
            @click="onExportButtonClick"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $t('Customer.Table.Button.Export.Icon') }}
            </v-icon>&nbsp;
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Customer.Table.Button.Export.Title').toLocaleUpperCase($i18n.locale) }}</span>
          </v-btn>

          <v-spacer />

          <filter-search v-model="dataTable.search.terms" />

          <filter-options
            v-model="filtersModel"
            :filters="filterData"
            max-width="450"
          />
        </toolbar-period-selector>
      </v-card-title>

      <v-divider />

      <v-container
        class="pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :footer-props="dataTable.footerProps"
              :headers="dataTableHeaders"
              :height="dataTable.resize.bodyHeight"
              :items="dataTable.data"
              :loading="dataTable.loading"
              :loading-text="dataTable.text.loadingText"
              :no-data-text="dataTable.text.noDataText"
              :no-results-text="dataTable.text.noResultsText"
              :options.sync="dataTable.options"
              :search="dataTable.search.terms"
              :server-items-length="dataTable.total"
              fixed-header
              item-key="Id"
            >
              <template #[`item.order_id`]="{item}">
                {{ item.OrderId }}
              </template>

              <template #[`item.receipt_id`]="{item}">
                <template v-for="(docNum, idx) in item.DocumentNumber">
                  <div
                    v-if="docNum"
                    :key="`doc-name-${docNum}-${idx}`"
                  >
                    <v-chip
                      class="mb-1 px-1 white--text"
                      :color="getFiscalDocumentNumber(item, idx).DocColor"
                      x-small
                      label
                    >
                      {{ getFiscalDocumentNumber(item, idx).DocName }}
                    </v-chip>
                  </div>
                </template>
              </template>

              <template #[`item.receipt_type`]="{item}">
                <template v-for="(docNum, idx) in item.DocumentNumber">
                  <div
                    v-if="getFiscalDocumentNumber(item, idx).DocType"
                    :key="`doc-type-${docNum}-${idx}`"
                  >
                    <v-chip
                      class="mb-1 px-1 white--text"
                      :color="getFiscalDocumentNumber(item, idx).DocColor"
                      x-small
                      label
                    >
                      {{ getFiscalDocumentNumber(item, idx).DocType }}
                    </v-chip>
                  </div>
                </template>
              </template>

              <template #[`item.order_total`]="{item}">
                <template v-if="item.Total">
                  {{ item.Total | currency }}
                </template>
              </template>

              <template
                v-for="tax in taxes"
                #[`item.${tax.IdName}`]="{item}"
              >
                <template v-if="item[tax.IdName]">
                  {{ item[tax.IdName] | currency }}
                </template>

                <template v-if="item[tax.IdName+'_Vat']">
                  <span
                    :key="tax.IdName+'_Vat'"
                    class="grey--text"
                  >/ {{ item[tax.IdName + '_Vat'] | currency }}</span>
                </template>
              </template>

              <template #[`item.date_added`]="{item}">
                {{ item.Date }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import FilterSearch          from '@/components/common/filter/FilterSearch'
import FilterCommon          from '@/mixins/filter/filterCommon'
import DataTable             from '@/mixins/data-table/dataTable'
import AppData               from '@/mixins/appdata'
import Auth                  from '@/mixins/auth'
import ToolbarPeriodSelector from '@/components/common/ToolbarPeriodSelector.vue'
import Translatable          from '@/mixins/translatable'
import FilterOptions         from '@/components/common/filter/FilterOptions.vue'
import ReceiptTypeCodeEnum   from '@/api/Enums/ReceiptTypeCodeEnum'

export default {
  name      : 'SalesReportZ',
  components: {
    FilterOptions,
    ToolbarPeriodSelector,
    FilterSearch
  },
  directives: {},
  mixins    : [AppData, Auth, FilterCommon, DataTable, Translatable],
  data () {
    return {
      selectDateRangeDialogVisible: false,

      dataTable: {
        socketCommand: {
          list: window.SocketCommand.Statistics.SalesReportZ.All
        },
        filter: {
          filter_date: 'today'
        },
        options: {
          defaultSortBy: 'order_id',
          sortBy       : ['order_id'],
          sortDesc     : [true]
        },
        headers: []
      },

      filtersModel: {
        lang           : 'Statistics.SalesReportZ',
        filterOrderType: {
          key  : 'filter_type',
          value: 0,
          items: this.$t('Statistics.SalesReportZ.Filter.filterOrderType.Items')
        },
        filterInvoiceType: {
          key  : 'filter_invoice_type',
          value: null,
          items: this.$t('Statistics.SalesReportZ.Filter.filterInvoiceType.Items')
        }
      }
    }
  },
  computed: {
    taxes () {
      return this.appConfig?.TAX?.Taxes || []
    },

    tableHeaders () {
      const headers = []

      headers.push(
        {
          text     : this.$t('Statistics.SalesReportZ.Table.Headers.Order'),
          value    : 'order_id',
          align    : 'center',
          sortable : true,
          cellClass: 'text-caption'
        },
        {
          text     : this.$t('Statistics.SalesReportZ.Table.Headers.Receipt'),
          value    : 'receipt_id',
          align    : 'left',
          sortable : false,
          cellClass: 'text-caption'
        },
        {
          text     : this.$t('Statistics.SalesReportZ.Table.Headers.ReceiptType'),
          value    : 'receipt_type',
          align    : 'left',
          sortable : false,
          cellClass: 'text-caption'
        },
        {
          text     : this.$t('Statistics.SalesReportZ.Table.Headers.Total'),
          value    : 'order_total',
          align    : 'right',
          sortable : true,
          cellClass: 'text-caption'
        }
      )

      this.taxes.forEach(tax => {
        headers.push(
          {
            text     : this.getTranslatedField(tax, 'Name'),
            value    : `${ tax.IdName }`,
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption'
          }
        )
      })

      headers.push(
        {
          text     : this.$t('Statistics.SalesReportZ.Table.Headers.Date'),
          value    : 'date_added',
          align    : 'center',
          sortable : true,
          cellClass: 'text-caption'
        }
      )

      return headers
    }
  },
  watch: {},
  created () {
    this.dataTable.headers = this.tableHeaders
    this.$bus.$on(window.SocketCommand.Statistics.SalesReportZ.Export, this.onExportResult)
  },
  mounted () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Statistics.SalesReportZ.Export, this.onExportResult)
  },
  methods: {
    getFiscalDocumentNumber (item, index) {
      const docSeries = item.DocumentSeries[index] || ''
      const docNumber = item.DocumentNumber[index] || ''
      const docType = item.InvoiceType[index] || ''
      const docTypeCode = item.InvoiceTypeCode[index] || ''

      return {
        DocName    : `${ docSeries ? (docSeries + '-') : '' }${ docNumber }`,
        DocSeries  : docSeries,
        DocNumber  : docNumber,
        DocType    : docType,
        DocTypeCode: docTypeCode,
        DocColor   : this.getFiscalDocumentColor(docTypeCode)
      }
    },

    getFiscalDocumentColor (docTypeCode) {
      if (ReceiptTypeCodeEnum.GREEK_RECEIPT === docTypeCode) return 'green'
      if (ReceiptTypeCodeEnum.GREEK_RECEIPT_CREDIT === docTypeCode) return 'red'

      if (ReceiptTypeCodeEnum.GREEK_INVOICE === docTypeCode) return 'blue'
      if (ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_1 === docTypeCode) return 'red'
      if (ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_2 === docTypeCode) return 'red'

      if (ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER === docTypeCode) return 'blue-grey'

      return 'grey'
    },

    onExportButtonClick () {
      if (!this.dataTable?.filter?.filter_date) return

      const dataTablePayload = this.dataTableRequestData()
      const payload = {
        Period     : this.dataTable.filter.filter_date,
        Type       : this.filtersModel?.filterOrderType?.value || null,
        InvoiceType: this.filtersModel?.filterInvoiceType?.value || null,
        SortBy     : dataTablePayload.sort_by,
        OrderBy    : dataTablePayload.order_by
      }

      window.callAS(window.SocketCommand.Statistics.SalesReportZ.Export, payload)
    },

    onExportResult (response) {
      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Statistics.SalesReportZ.Export.SuccessMessage', { email: this.posUser?.staff_email || '' }),
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
