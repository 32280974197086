<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card v-if="viewData">
        <v-toolbar
          flat
          height="80"
          max-height="80"
          tabs
        >
          <v-avatar color="teal lighten-1">
            <v-icon dark>
              credit_score
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ viewData.TableAreaName }} #{{ viewData.TableName }}
            </div>
            <div class="text-caption grey--text">
              {{ viewData.StaffName }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <payment-items-list
            :view-data="viewData"
            desc
          />
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row>
              <!-- ORDER TOTALS -->
              <template v-if="viewData">
                <v-col
                  class="grey--text text--darken-1 white"
                  cols="12"
                >
                  <div class="pa-2">
                    <strong>
                      <span class="grey--text text--darken-1">
                        {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                      </span>
                      <span class="grey--text text--darken-1 float-right">
                        {{ total | currency }}
                      </span>
                    </strong>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Auth             from '@/mixins/auth'
import PaymentItemsList from '@/components/waiters/PaymentItemsList.vue'

export default {
  name      : 'WaiterStaffPaymentDetailsDialog',
  components: { PaymentItemsList },
  mixins    : [Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },

    viewData: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    total () {
      return this.viewData?.Transactions?.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0
    }
  },
  watch  : {},
  methods: {
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
