<template>
  <v-menu
    v-model="filtersMenu"
    :close-on-content-click="false"
    :nudge-top="-9"
    :min-width="minWidth"
    :max-width="maxWidth"
    offset-y
  >
    <template #activator="{on: menu}">
      <v-badge
        :value="activeFiltersCount"
        color="purple"
        overlap
      >
        <template #badge>
          <span>{{ activeFiltersCount }}</span>
        </template>

        <v-btn
          :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
          :disabled="allFiltersCount <= 0 || disabled"
          :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
          class="pa-0 ma-1 elevation-0"
          v-on="menu"
        >
          <v-icon
            color="primary"
            v-text="'filter_alt'"
          />
        </v-btn>
      </v-badge>
    </template>

    <v-card :max-width="maxWidth">
      <v-card-text
        :style="`max-height: ${maxHeight}px;`"
        :class="{'pa-2': $vuetify.breakpoint.xsOnly}"
        class="overflow-y-auto"
      >
        <v-container
          class="pa-0"
          fluid
        >
          <v-row dense>
            <template v-for="(obj, objKey, objIndex) in filters">
              <v-col
                :key="`filter-${objKey}-${objIndex}`"
                :cols="obj.Cols ? obj.Cols : 12"
              >
                <p
                  class="pb-0 mb-1"
                  v-text="obj.Label"
                />

                <template v-if="obj.Type === 'v-date-picker'">
                  <v-menu
                    v-model="model[objKey]['visible']"
                    :close-on-content-click="false"
                    left
                    max-width="290px"
                    min-width="140px"
                    offset-y
                    style="width: 100%;"
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-if="!!obj.Range"
                        :value="model[objKey]['value'].join(' - ')"
                        :placeholder="obj.PlaceHolder || ''"
                        :class="[obj.Class ? obj.Class : '']"
                        clearable
                        hide-details
                        :prepend-inner-icon="obj.Icon || ''"
                        readonly
                        single-line
                        filled
                        flat
                        v-on="on"
                        @blur="onDatePickerTextFieldBlur(objKey)"
                      />

                      <v-text-field
                        v-else
                        v-model="model[objKey]['value']"
                        :placeholder="obj.PlaceHolder || ''"
                        :class="[obj.Class ? obj.Class : '']"
                        clearable
                        hide-details
                        :prepend-inner-icon="obj.Icon || ''"
                        readonly
                        single-line
                        filled
                        flat
                        v-on="on"
                        @blur="onDatePickerTextFieldBlur(objKey)"
                      />
                    </template>

                    <v-date-picker
                      v-model="model[objKey]['date']"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      :range="!!obj.Range"
                      first-day-of-week="1"
                      scrollable
                      @input="onDatePickerInput(objKey, $event)"
                    />
                  </v-menu>
                </template>

                <template v-if="obj.Type === 'v-time-picker'">
                  <v-menu
                    :ref="model[objKey]['key']"
                    v-model="model[objKey]['visible']"
                    :close-on-content-click="false"
                    :return-value.sync="model[objKey]['time']"
                    max-width="290px"
                    min-width="290px"
                    transition="scale-transition"
                    offset-y
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="model[objKey]['time']"
                        :placeholder="obj.PlaceHolder || ''"
                        :class="[obj.Class ? obj.Class : '']"
                        clearable
                        hide-details
                        :prepend-inner-icon="obj.Icon || ''"
                        readonly
                        single-line
                        filled
                        flat
                        v-on="on"
                        @input="onTimePickerInput(objKey, $event)"
                      />
                    </template>

                    <v-time-picker
                      v-if="model[objKey]['visible']"
                      v-model="model[objKey]['time']"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      first-day-of-week="1"
                      format="24hr"
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="model[objKey]['visible'] = false"
                      >
                        {{ $t('Common.Button.Cancel') }}
                      </v-btn>

                      <v-btn
                        text
                        color="primary"
                        @click="onTimePickerSave(objKey)"
                      >
                        {{ $t('Common.Button.Ok') }}
                      </v-btn>
                    </v-time-picker>
                  </v-menu>
                </template>

                <template v-if="obj.Type === 'v-btn-toggle'">
                  <v-btn-toggle
                    v-model="model[objKey]['value']"
                    class="elevation-0 d-inline-flex"
                    borderless
                    mandatory
                    style="min-width: 268px; border-radius: 4px; max-width: 100%; width: 100%;"
                  >
                    <template v-for="(item, itemIndex) in model[objKey]['items']">
                      <v-btn
                        :key="`filter-${objKey}-${objIndex}-btn-toggle-${itemIndex}`"
                        :value="item[item.ItemValue || 'Value']"
                        :color="item.Color ? item.Color : ''"
                        :class="[item.Class ? item.Class : '']"
                        :style="model[objKey]['value'] === item[item.ItemValue || 'Value'] ? 'opacity: 1' : 'opacity: 0.35'"
                        height="40"
                        class="elevation-0 px-2 grow"
                      >
                        <v-icon
                          v-if="item.Icon"
                          class="mr-2 white--text"
                          text--white
                          size="16"
                          v-text="item.Icon"
                        />
                        {{ item[item.ItemText || 'Name'] }}
                      </v-btn>
                    </template>
                  </v-btn-toggle>
                </template>

                <template v-else-if="obj.Type === 'v-select'">
                  <v-select
                    v-model="model[objKey]['value']"
                    :item-text="obj.ItemText || 'Name'"
                    :item-value="obj.ItemValue || 'Value'"
                    :items="model[objKey]['items']"
                    :multiple="!!obj.Multiple"
                    :placeholder="obj.PlaceHolder"
                    :small-chips="!!obj.Chips"
                    :clearable="!!model[objKey]['value']"
                    :class="[obj.Class ? obj.Class : '']"
                    full-width
                    flat
                    dense
                    hide-details
                    outlined
                    single-line
                  >
                    <template
                      v-if="obj.Chips"
                      #selection="data"
                    >
                      <v-chip
                        :value="data.selected"
                        color="transparent"
                        class="pa-0 ma-1"
                        small
                      >
                        <template v-if="data.item.Avatar">
                          <v-avatar
                            class="mr-2"
                            :color="data.item.Avatar.Color || ''"
                            :tile="!!data.item.Avatar.Tile"
                            dark
                          >
                            <v-icon
                              v-if="data.item.Avatar.Icon"
                              size="16"
                              class="white--text"
                              dark
                            >
                              {{ data.item.Avatar.Icon }}
                            </v-icon>

                            <img
                              v-if="data.item.Avatar.Img"
                              :src="data.item.Avatar.Img"
                            >
                          </v-avatar>
                        </template>

                        {{ data.item[obj.ItemText || 'Name'] }}
                      </v-chip>
                    </template>

                    <template #item="data">
                      <template v-if="data.item.Avatar">
                        <v-list-item-avatar
                          dense
                          :width="data.item.Avatar.Size || 24"
                          :height="data.item.Avatar.Size || 24"
                          :size="data.item.Avatar.Size || 24"
                          :color="data.item.Avatar.Color || ''"
                          :tile="!!data.item.Avatar.Tile"
                        >
                          <v-icon
                            v-if="data.item.Avatar.Icon"
                            :size="data.item.Avatar.IconSize || 18"
                            class="white--text"
                            dark
                          >
                            {{ data.item.Avatar.Icon }}
                          </v-icon>

                          <img
                            v-if="data.item.Avatar.Img"
                            :src="data.item.Avatar.Img"
                          >
                        </v-list-item-avatar>
                      </template>

                      <v-list-item-content>
                        <v-list-item-title v-text="data.item[obj.ItemText || 'Name']" />
                        <v-list-item-subtitle>
                          {{ data.item[obj.ItemDesc || 'Description'] | truncate }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="resetFilters"
        >
          {{ $t('Common.Button.Reset') }}
        </v-btn>

        <v-btn
          color="primary"
          class="elevation-0"
          @click="closeMenu"
        >
          {{ $t('Common.Button.Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>

const isPlainObject = val => !!val && typeof val === 'object' && val.constructor === Object
const isArray = (val) => Array.isArray(val)
const arrayEquals = (a, b) => isArray(a) && isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])

let initialDataFilters = {}

export default {
  name      : 'FilterOptions',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type    : Object,
      required: true
    },
    filters: {
      type    : Object,
      required: true
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    minWidth: {
      type   : [Number, String],
      default: 320
    },
    maxWidth: {
      type   : [Number, String],
      default: 395
    },
    maxHeight: {
      type   : [Number, String],
      default: 500
    }
  },
  enums: {},
  data () {
    return {
      filtersMenu: false
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    allFiltersCount () {
      const filters = isPlainObject(this.filters) ? Object.keys(this.filters) : []
      return filters.length
    },

    activeFiltersCount () {
      const langFilters = isPlainObject(this.filters) ? Object.keys(this.filters) : []
      const dataFilters = isPlainObject(this.model) ? { ...this.model } : {}
      const filteredFilters = Object.keys(dataFilters).filter(key => langFilters.includes(key)).reduce((obj, key) => {
        obj[key] = dataFilters[key]
        return obj
      }, {})

      return Object.keys(filteredFilters).filter(key => filteredFilters[key].value !== undefined && filteredFilters[key].value !== '' && filteredFilters[key].value !== initialDataFilters[key].value && (isArray(initialDataFilters[key].value) ? !arrayEquals(initialDataFilters[key].value, filteredFilters[key].value) : true)).length
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    initialDataFilters = JSON.parse(JSON.stringify({ ...this.model ?? {} }))
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    closeMenu () {
      this.filtersMenu = false
    },

    onTimePickerSave (objKey) {
      if (!this.model[objKey]) return

      this.$refs[this.model[objKey].key][0].save(this.model[objKey].time)
      this.model[objKey].value = this.model[objKey].time
    },

    onTimePickerInput (objKey, $event) {
      if (!this.model[objKey]) return

      this.model[objKey].value = $event
    },

    onDatePickerTextFieldBlur (objKey) {
      if (!this.model[objKey]) return

      this.model[objKey].date = this.parseDate(this.model[objKey].value, !!this.filters[objKey]?.Range)
    },

    onDatePickerInput (objKey, $event) {
      if (!this.model[objKey]) return

      this.model[objKey].value = this.formatDate($event, !!this.filters[objKey]?.Range)
      this.model[objKey].visible = this.filters[objKey]?.Range ? $event.length === 1 : false
    },

    formatDate (date, range = false) {
      if (!date) return range ? [] : null

      if (range) {
        date = date?.map(d => {
          const [year, month, day] = d.split('-')
          return `${ day }-${ month }-${ year }`
        }) || []

        return date
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },

    parseDate (date, range = false) {
      if (!date) return range ? [] : null

      if (range) {
        date = date?.map(d => {
          const [day, month, year] = d.split('-')
          return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
        }) || []

        return date
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    },

    resetFilters () {
      Object.keys(this.model).forEach(key => {
        if (key !== 'lang') {
          if (this.model[key].hasOwnProperty('value')) this.model[key].value = initialDataFilters[key].value
          if (this.model[key].hasOwnProperty('date')) this.model[key].date = initialDataFilters[key].date
          if (this.model[key].hasOwnProperty('time')) this.model[key].time = initialDataFilters[key].time
        }
      })
      this.closeMenu()
    }
  }
}
</script>

<style scoped>
/deep/ .v-btn-toggle .v-btn {
  border : 1px solid;
}

/deep/ .v-btn-toggle .v-btn:not(:last-child) {
  border-right : 1px solid transparent;
}

/*/deep/ .v-btn-toggle > .v-btn.v-btn:first-child {
  border-top-left-radius    : inherit;
  border-bottom-left-radius : inherit;
}*/

/deep/ .v-btn-toggle .v-btn:first-child {
  border-radius : 2px 0 0 2px;
}

/deep/ .v-btn-toggle .v-btn:last-child {
  border-radius : 0 2px 2px 0;
}

/deep/ .v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color : rgba(0, 0, 0, .12) !important;
}

/deep/ .v-badge__badge {
  top            : 2px !important;
  right          : -5px !important;
  pointer-events : none;
}

/deep/ .v-input__control > .v-input__slot {
  min-height : 42px;
}

/deep/ .v-text-field.v-text-field--enclosed .v-input__prepend-outer,
/deep/ .v-text-field.v-text-field--enclosed .v-input__prepend-inner,
/deep/ .v-text-field.v-text-field--enclosed .v-input__append-inner,
/deep/ .v-text-field.v-text-field--enclosed .v-input__append-outer {
  margin-top : 5px;
}

/deep/ .v-list--dense .v-list__tile {
  height : 44px;
}

/deep/ .v-text-field.v-text-field--enclosed .v-text-field__details,
/deep/ .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding : 2px 6px;
}
</style>
