<template>
  <v-card>
    <v-toolbar
      class="datatable-object settings-window-toolbar"
      flat
      height="80"
    >
      <v-avatar
        :color="$t('Settings.SystemUsers.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.SystemUsers.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.SystemUsers.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.SystemUsers.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      class="pa-1"
    >
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar
            class="filter-toolbar"
            flat
          >
            <template #default>
              <v-spacer />

              <v-autocomplete
                v-model="dataTable.filter.filter_domain"
                :items="domains"
                :filter="filterDomain"
                :label="$t('Staff.StaffAddDialog.Field.Domain.Label')"
                :placeholder="$t('Staff.StaffAddDialog.Field.Domain.Label')"
                style="max-width: 400px"
                class="mr-1"
                clearable
                item-text="Domain"
                item-value="Domain"
                solo-inverted
                hide-details
                required
                flat
              >
                <template #selection="data">
                  <div class="text-caption">
                    <div>{{ data.item.Domain }}</div>
                    <div
                      class="text-caption"
                      :class="data.item.CNameActive ? 'green--text' : 'grey--text'"
                    >
                      {{ data.item.CName }}
                    </div>
                  </div>
                </template>

                <template #item="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.Domain }}
                    </v-list-item-title>

                    <v-list-item-subtitle
                      class="text-caption"
                      :class="data.item.CNameActive ? 'green--text' : 'grey--text'"
                    >
                      {{ data.item.CName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>

              <filter-search v-model="dataTable.search.terms" />

              <v-btn
                :height="$vuetify.breakpoint.xsOnly ? 36 : 48"
                :width="$vuetify.breakpoint.xsOnly ? 36 : 48"
                class="pa-0 mr-2 elevation-0"
                :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
                min-height="36"
                min-width="36"
                @click="refreshData"
              >
                <v-icon
                  size="24"
                  color="primary"
                  v-text="'mdi-table-refresh'"
                />
              </v-btn>

              <v-btn
                v-if="posUserCan('SystemSettingsDomains', 'MANAGE')"
                :height="$vuetify.breakpoint.xsOnly ? 36 : 48"
                :width="$vuetify.breakpoint.xsOnly ? 36 : 48"
                class="red pa-0 mr-0"
                color="white"
                min-height="36"
                min-width="36"
                text
                @click="logoutSystemUsers"
              >
                <v-icon size="24">
                  mdi-account-off-outline
                </v-icon>
              </v-btn>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <v-data-table
                  :footer-props="dataTable.footerProps"
                  :headers="dataTableHeaders"
                  :height="dataTable.resize.bodyHeight"
                  :items="dataTable.data"
                  :loading="dataTable.loading"
                  :loading-text="dataTable.text.loadingText"
                  :no-data-text="dataTable.text.noDataText"
                  :no-results-text="dataTable.text.noResultsText"
                  :options.sync="dataTable.options"
                  :search="dataTable.search.terms"
                  :server-items-length="dataTable.total"
                  :group-by.sync="dataTable.options.groupBy"
                  :group-desc.sync="dataTable.options.groupDesc"
                  fixed-header
                  item-key="Uid"
                  dense
                >
                  <template #group.header="{items, isOpen, toggle}">
                    <th
                      colspan="10"
                      style="cursor: pointer;"
                      @click="toggle"
                    >
                      <div class="d-flex">
                        <div class="d-inline-flex justify-center align-center">
                          <v-icon class="mr-2">
                            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                          </v-icon>

                          <span class="font-weight-bold blue--text d-inline-block">
                            {{ items[0].Domain }}
                          </span>
                        </div>

                        <v-spacer />

                        <v-badge
                          color="primary"
                          class="mt-1 mr-1"
                          inline
                          dark
                        >
                          <template #badge>
                            {{ domainUsersCount(items[0].Domain) }}
                          </template>
                        </v-badge>

                        <v-tooltip
                          color="primary"
                          open-delay="750"
                          top
                        >
                          <template #activator="{on}">
                            <v-btn
                              :height="26"
                              :width="26"
                              class="primary pa-0 mr-1 d-inline-flex"
                              color="white"
                              min-height="26"
                              min-width="26"
                              text
                              target="_blank"
                              :href="`https://${items[0].Domain}`"
                              v-on="on"
                            >
                              <v-icon size="18">
                                open_in_new
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>Open Website</span>
                        </v-tooltip>

                        <template v-if="posUserCan('SystemSettingsDomains', 'MANAGE')">
                          <template v-if="posUser.Domain !== items[0].Domain">
                            <v-tooltip
                              color="blue-grey"
                              open-delay="750"
                              top
                            >
                              <template #activator="{on}">
                                <v-btn
                                  :height="26"
                                  :width="26"
                                  class="blue-grey pa-0 mr-2 d-inline-flex"
                                  color="white"
                                  min-height="26"
                                  min-width="26"
                                  text
                                  v-on="on"
                                  @click.stop="changeStaffDomain(items[0].Domain)"
                                >
                                  <v-icon size="18">
                                    mdi-web-refresh
                                  </v-icon>
                                </v-btn>
                              </template>

                              <span>Login to Domain</span>
                            </v-tooltip>
                          </template>

                          <v-tooltip
                            color="red"
                            open-delay="750"
                            top
                          >
                            <template #activator="{on}">
                              <v-btn
                                :height="26"
                                :width="26"
                                class="red pa-0 mr-0 d-inline-flex"
                                color="white"
                                min-height="26"
                                min-width="26"
                                text
                                v-on="on"
                                @click.stop="logoutDomainUsers(items[0].Domain)"
                              >
                                <v-icon size="18">
                                  mdi-account-off-outline
                                </v-icon>
                              </v-btn>
                            </template>

                            <span>Logout Domain Users</span>
                          </v-tooltip>
                        </template>
                      </div>
                    </th>
                  </template>

                  <template #[`item.id`]="{item}">
                    #{{ item.Id }}
                  </template>

                  <template #[`item.uid`]="{item}">
                    {{ item.Uid }}
                  </template>

                  <template #[`item.active`]="{item}">
                    <v-icon
                      v-if="item.Active"
                      dense
                      color="green"
                    >
                      mdi-check-circle
                    </v-icon>
                  </template>

                  <template #[`item.single_login`]="{item}">
                    <v-icon
                      v-if="item.SingleLogin"
                      dense
                      color="purple"
                    >
                      mdi-check-circle
                    </v-icon>
                  </template>

                  <template #[`item.voip_agent`]="{item}">
                    {{ item.VoipAgent }}
                  </template>

                  <template #[`item.voip_domain`]="{item}">
                    {{ item.VoipDomain }}
                  </template>

                  <template #[`item.last_login`]="{item}">
                    {{ formatDate(item.LastLogin) }}<br>{{ formatTime(item.LastLogin) }}
                  </template>

                  <template #[`item.created_at`]="{item}">
                    {{ formatDate(item.CreatedAt) }}<br>{{ formatTime(item.CreatedAt) }}
                  </template>

                  <template #[`item.updated_at`]="{item}">
                    {{ formatDate(item.UpdatedAt) }}<br>{{ formatTime(item.UpdatedAt) }}
                  </template>

                  <template #[`item.actions`]="{item}">
                    <template v-if="posUserCan('SystemSettingsDomains', 'MANAGE')">
                      <template v-if="posUser.staff_email !== item.Uid">
                        <v-tooltip
                          color="blue-grey"
                          open-delay="750"
                          top
                        >
                          <template #activator="{on}">
                            <v-btn
                              :height="18"
                              :width="18"
                              class="blue-grey pa-0 mr-2 d-inline-flex"
                              color="white"
                              min-height="18"
                              min-width="18"
                              text
                              v-on="on"
                              @click.stop="loginOnUserBehalf(item)"
                            >
                              <v-icon size="14">
                                mdi-web-refresh
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>Login on User Behalf</span>
                        </v-tooltip>
                      </template>

                      <v-tooltip
                        color="red"
                        open-delay="750"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            :height="18"
                            :width="18"
                            class="red pa-0 mr-0"
                            color="white"
                            min-height="18"
                            min-width="18"
                            text
                            v-on="on"
                            @click="logoutDomainUser(item)"
                          >
                            <v-icon size="14">
                              mdi-account-off-outline
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>Logout User</span>
                      </v-tooltip>
                    </template>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 datatable-object settings-window-actions">
      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData       from '@/mixins/appdata'
import Auth          from '@/mixins/auth'
import Watchable     from '@/mixins/watchable'
import DataTable     from '@/mixins/data-table/dataTable'
import FilterSearch  from '@/components/common/filter/FilterSearch'
import SocketCommand from '@/api/SocketCommand'

export default {
  name      : 'SystemSettingsSystemUsers',
  components: {
    FilterSearch
  },
  mixins: [AppData, Auth, Watchable, DataTable],
  data () {
    return {
      tab      : 'tab-production',
      dataTable: {
        resize: {
          offset: 42
        },
        socketCommand: {
          list: SocketCommand.Settings.SystemUsers
        },
        filter: {
          filter_domain: null
        },
        options: {
          defaultSortBy    : 'domain',
          sortBy           : ['domain'],
          sortDesc         : [true],
          groupBy          : ['Domain'],
          groupDesc        : [true],
          itemsPerPage     : 20,
          serverSideEnabled: true
        },
        footerProps: {
          disableItemsPerPage: false,
          itemsPerPageOptions: [5, 10, 15, 20, 25, 30],
          itemsPerPageText   : ''
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : 'ID',
            value    : 'id',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'UID',
            value    : 'uid',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'ACTIVE',
            value    : 'active',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'SINGLE LOGIN',
            value    : 'single_login',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'VOIP AGENT',
            value    : 'voip_agent',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'VOIP DOMAIN',
            value    : 'voip_domain',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'LAST LOGIN',
            value    : 'last_login',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'CREATED AT',
            value    : 'created_at',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'UPDATED AT',
            value    : 'updated_at',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : '',
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption'
          }
        ]
      }
    }
  },
  computed: {
    domains () {
      return this.$DataStore?.appDomains || []
    }
  },
  watch: {},
  created () {},
  mounted () {
    this.$bus.$on(SocketCommand.Settings.SystemUsers, this.setData)
  },
  updated () {},
  beforeDestroy () {
    this.$bus.$off(SocketCommand.Settings.SystemUsers, this.setData)
  },
  methods: {
    logoutSystemUsers () {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE')) return

      window.callAS(SocketCommand.System.LogoutUsers, {
        Ids: [],
        All: true
      })
    },

    logoutDomainUsers (domain) {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE')) return

      const users = this.viewData?.items?.filter(item => item.Domain === domain)?.map(user => user.Id) || []
      if (!users || !users.length) return

      window.callAS(SocketCommand.System.LogoutUsers, {
        Ids: users,
        All: false
      })
    },

    logoutDomainUser (user) {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE') || !user) return

      window.callAS(SocketCommand.System.LogoutUsers, {
        Ids: [user.Id],
        All: false
      })
    },

    loginOnUserBehalf (user) {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE') || !user) return
      const uid = user.Uid
      const pin = '0000'
      const token = window.authorizationToken

      const host = this.tab === 'tab-production' ? 'https://pos.getfoodpro.gr' : process.env.VUE_APP_ENV === 'development' ? 'http://localhost:8080' : 'https://dpos.getfoodpro.gr'
      window.opener = null
      window.open(`${ host }/login?uid=${ encodeURIComponent(uid) }&pin=${ pin }&token=${ token }`, '_blank', '')
    },

    domainUsersCount (domain) {
      if (!domain) return 0

      return this.viewData?.items?.filter(item => item.Domain === domain)?.length || 0
    },

    refreshData () {
      if (this.dataTable.loading) return

      this.getData()
    },

    formatDate (date, separatorFrom = '-', separatorTo = '/') {
      if (!date) return null

      const [day, month, year] = date.substring(0, 10).split(separatorFrom)
      return `${ day }${ separatorTo }${ month }${ separatorTo }${ year }`
    },

    formatTime (date, separatorFrom = ':', separatorTo = ':') {
      if (!date) return null

      const [hour, min, sec] = date.substring(11, 19).split(separatorFrom)
      return `${ hour }${ separatorTo }${ min }${ separatorTo }${ sec }`
    },

    changeStaffDomain (domain) {
      if (!domain || domain === this.posUser.Domain || !this.userInAdminGroup || !this.posUserCan('SystemSettingsDomains', 'MANAGE')) return

      this.$bus.$emit('show-app-loading-modal', true)
      window.callAS(window.SocketCommand.Staff.ChangeDomain, { Domain: domain })
    },

    filterDomain (item, queryText, itemText) {
      const domain = item.Domain?.toLowerCase() || ''
      const cname = item.CName?.toLowerCase() || ''
      const searchText = queryText.toLowerCase()

      return domain.includes(searchText) || cname.includes(searchText)
    }
  }
}
</script>

<style scoped>
/deep/ .single-login-label label.v-label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
