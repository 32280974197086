export default {
  data () {
    return {}
  },
  mixins  : [],
  methods : {},
  computed: {
    orderId () {
      return parseInt(this.$route.params.orderId || 0)
    },

    tableId () {
      return parseInt(this.$route.params.tableId || 0)
    },

    isTableOrder () {
      return !!this.posCart?.TableShiftId || this.tableId || (this.tableId && this.orderId)
    },

    title () {
      return this.orderId <= 0 ? this.$t('Pos.Pos.Toolbar.Title.New').toLocaleUpperCase(this.$i18n.locale) : this.$t('Pos.Pos.Toolbar.Title.Edit').toLocaleUpperCase(this.$i18n.locale)
    },

    tableTitle () {
      return this.$route.params?.table && this.$route.params?.area ? `${ this.$route.params.area.Name } #${ this.$route.params.table.Name }` : ''
    }
  }
}
