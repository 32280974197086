<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar class="">
            <svg-logo
              class="ml-1"
              :fork-color="isDarkMode ? '#fff' : '#414042'"
              :pro-color="isDarkMode ? '#fff' : '#414042'"
              :signal-color1="isDarkMode ? '#fff' : '#58595b'"
              :signal-color2="isDarkMode ? '#fff' : '#414042'"
              size="56px"
            />
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Pos.SelectOrderSourceTypeDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Pos.SelectOrderSourceTypeDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <template v-for="(item) in sourceTypes">
                <v-col
                  v-if="isSourceTypeEnabled(item)"
                  :key="item.source_type_id"
                  sm="6"
                  cols="12"
                >
                  <v-list
                    class="pa-0"
                    two-line
                  >
                    <v-list-item
                      class="grey lighten-3 rounded"
                      :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-3']"
                      ripple
                      @click="onSelectOrderSourceType(item.source_type_id)"
                    >
                      <v-list-item-avatar
                        :color="currentPlatformType!=='getfoodpro' ? 'white' : 'red darken-1'"
                        size="46"
                      >
                        <template v-if="item.logo">
                          <img
                            v-if="currentPlatformType!=='getfoodpro'"
                            :src="item.logo"
                          >
                          <v-icon
                            v-else
                            color="white"
                            size="32"
                          >
                            {{ item.logo }}
                          </v-icon>
                        </template>
                        <v-icon
                          v-else
                          color="white"
                          size="32"
                        >
                          check_circle
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content :class="isDarkMode ? 'white--text' : 'grey--text text--darken-2'">
                        <v-list-item-title class="text-left">
                          {{ parseInt(item.source_type_id) >= 100 ? getOrderSourceTypeById(item.source_type_id).title : getOrderSourceTypeLangById(item.source_type_id).Title }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.desc">
                          <span class="grey--text">
                            {{ parseInt(item.source_type_id) >= 100 ? getOrderSourceTypeById(item.source_type_id).desc : getOrderSourceTypeLangById(item.source_type_id).Desc }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </v-list>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <select-platform-catalog-dialog
      :visible.sync="selectCatalogDialogVisible"
      :source-type="selectedSourceTypeId"
      :catalogs="sourceTypeCatalogs"
      @catalog:selected="onSelectPlatformCatalog"
    />
  </v-row>
</template>

<script>
import AppData                     from '@/mixins/appdata'
import Auth                        from '@/mixins/auth'
import SourceType                  from '@/mixins/orders/sourceType'
import SvgLogo                     from '../common/SvgLogo'
import SourceTypeEnum              from '@/api/Enums/SourceTypeEnum'
import SelectPlatformCatalogDialog from '@/components/pos/SelectPlatformCatalogDialog.vue'

export default {
  name      : 'SelectOrderSourceTypeDialog',
  components: {
    SelectPlatformCatalogDialog,
    'svg-logo': SvgLogo
  },
  mixins: [AppData, Auth, SourceType],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    platformType: {
      type   : String,
      default: ''
    },
    tmpPlatformType: {
      type   : String,
      default: ''
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      selectCatalogDialogVisible: false,
      selectedSourceTypeId      : 0,
      sourceTypeCatalogs        : []
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    currentPlatformType: {
      get () {
        return this.platformType
      },
      set (val) {
        this.$emit('update:platformType', val)
      }
    },
    sourceTypes () {
      if (this.currentPlatformType === 'getfoodpro') {
        return this.appConfig.SOURCE_TYPES.DEFAULT
      } else {
        return this.appConfig.SOURCE_TYPES.OTHER
      }
    }
  },
  created () {
    this.$bus.$on('cart:init', this.onCartInit)
    this.$bus.$on('platform-catalog-dialog:show', this.onSelectOrderSourceType)
  },
  beforeDestroy () {
    this.$bus.$off('cart:init', this.onCartInit)
    this.$bus.$off('platform-catalog-dialog:show', this.onSelectOrderSourceType)
  },
  methods: {
    onCartInit (data) {
      if (!data?.SourceTypeId || (this.$route.params?.isFromCopy && this.$route.params?.catalogId) || this.$route.params?.area?.CatalogId) return

      this.onSelectOrderSourceType(data?.SourceTypeId)
    },

    onSelectOrderSourceType (orderSourceTypeId) {
      this.selectedSourceTypeId = orderSourceTypeId
      this.sourceTypeCatalogs = []

      if (!SourceTypeEnum.EXCLUDE_MULTIPLE_CATALOGS.includes(parseInt(orderSourceTypeId))) {
        const activeCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => catalog.Status) || []
        const catalogs = activeCatalogs.filter(catalog => catalog.SourceTypes.includes(parseInt(orderSourceTypeId))) || []

        if (catalogs.length > 1) {
          this.sourceTypeCatalogs = catalogs
          this.selectCatalogDialogVisible = true
          return
        }
      }

      this.setOrderSourceType()
    },

    onSelectPlatformCatalog (catalog) {
      this.setOrderSourceType(catalog)
    },

    setOrderSourceType (catalog) {
      this.isVisible = false
      this.currentPlatformType = this.platformType
      this.$bus.$emit('cart:order-source-type-selected', this.selectedSourceTypeId, catalog)

      this.selectedSourceTypeId = 0
      this.sourceTypeCatalogs = []
    },

    onCancelButtonClick () {
      this.currentPlatformType = this.tmpPlatformType
      this.isVisible = false
    },

    isSourceTypeEnabled (item) {
      const allCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs || []
      const sourceTypeExists = allCatalogs.some(catalog => catalog.SourceTypes.includes(parseInt(item.source_type_id)))
      const sourceTypeCanCreateOrder = allCatalogs.some(catalog => catalog.CanCreateOrder && catalog.SourceTypes.includes(parseInt(item.source_type_id)))
      const canCreateOrder = !sourceTypeExists || (sourceTypeExists && sourceTypeCanCreateOrder)

      return canCreateOrder && (parseInt(item.source_type_id) !== SourceTypeEnum.ONLINE && parseInt(item.source_type_id) !== SourceTypeEnum.TABLE && parseInt(item.source_type_id) !== SourceTypeEnum.MOBILE && parseInt(item.active) === 1 && (parseInt(item.source_type_id) > 99 || (this.posUserHasComponentPermission(item.name, 'ACCESS'))))
    }
  }
}
</script>
