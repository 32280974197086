<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      max-width="350"
      persistent
      scrollable
    >
      <v-card v-if="table && area">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            v-if="isTableAvailable"
            :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'"
          >
            <v-icon dark>
              {{ getTableStatusIcon(table) }}
            </v-icon>
          </v-avatar>

          <v-badge
            v-else
            :color="`${getTableStatusColor(table, true)} lighten-2`"
            overlap
          >
            <template #badge>
              <span>{{ table.Persons }}</span>
            </template>

            <v-avatar :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'">
              <v-icon dark>
                group
              </v-icon>
            </v-avatar>
          </v-badge>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ area.Name }} #{{ table.Name }}
            </div>

            <div class="text-caption grey--text">
              <span class="font-weight-bold">{{ $tc('Restaurant.Tables.Seat.Title', 0) }}</span>:
              {{ mergedMinCapacity }}/{{ mergedMaxCapacity }}

              <template v-if="table.Persons">
                <span class="font-weight-bold ml-2">{{ $t('Restaurant.Tables.Dialog.Open.Form.Field.Persons.Label') }}</span>:
                {{ table.Persons }}
              </template>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <v-col
                v-for="item in personsListTotal"
                :key="item"
                cols="3"
              >
                <v-list class="pb-0 pt-0">
                  <v-list-item
                    :class="[{'pa-1': !$vuetify.breakpoint.xsOnly}, getItemColor(item)]"
                    ripple
                    @click="setPersons(item)"
                  >
                    <v-list-item-content>
                      <div
                        class="text-h6 text-center text-no-wrap font-weight-bold text-lowercase"
                        style="width: 100%;"
                      >
                        {{ item }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </v-list>
              </v-col>

              <v-col
                cols="12"
                class="my-1"
              />

              <v-col cols="12">
                <v-form
                  ref="dataForm"
                  v-model="dataFormValid"
                  autocomplete="off"
                  @submit.prevent
                >
                  <v-text-field
                    v-model="persons"
                    v-mask="'##'"
                    :label="$t('Restaurant.Tables.Dialog.Open.Form.Field.Persons.Label')"
                    :hint="$t('Restaurant.Tables.Dialog.Open.Form.Field.Persons.Hint')"
                    :rules="validationRules.Persons"
                    clearable
                    filled
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            class="green white--text elevation-0"
            @click="onOpenButtonClick"
          >
            {{ $t('Common.Button.Ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TableStatus  from '@/mixins/tables/tableStatus'
import TableCommon  from '@/mixins/tables/tableCommon'
import TableActions from '@/mixins/tables/tableActions'

export default {
  name      : 'SetTablePersonsDialog',
  components: {},
  mixins    : [TableStatus, TableCommon, TableActions],
  props     : {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    mergedMinCapacity: {
      type   : Number,
      default: 0
    },
    mergedMaxCapacity: {
      type   : Number,
      default: 0
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      persons         : 0,
      personsListTotal: 12,
      dataFormValid   : true,
      validationRules : {
        Persons: [
          (v) => v && parseInt(v) > 0 || this.$t('Restaurant.Tables.Dialog.Open.Form.Field.Persons.ValidationRules.MinNum'),
          (v) => v && parseInt(v) <= (this.mergedMaxCapacity || 10000) || (this.mergedMaxCapacity ? this.$t('Restaurant.Tables.Dialog.Open.Form.Field.Persons.ValidationRules.MaxNum', { persons: this.mergedMaxCapacity }) : this.$t('Restaurant.Tables.Dialog.Open.Form.Field.Persons.ValidationRules.NoMaxNum'))
        ]
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.persons = 0
        const minCapacity = this.table?.MinCapacity || 1
        const maxCapacity = this.table?.MaxCapacity || 1
        const mergedMinCapacity = this.mergedMinCapacity || minCapacity
        const mergedMaxCapacity = this.mergedMaxCapacity || maxCapacity
        const maxPersonsList = 20

        if (this.table?.Persons) {
          this.persons = this.table.Persons || 1
        } else {
          if (this.persons < mergedMinCapacity) this.persons = mergedMinCapacity
          if (this.persons > mergedMaxCapacity) this.persons = mergedMaxCapacity
        }

        const nextWholeNumDivisibleByFour = Math.ceil(mergedMaxCapacity / 4) * 4
        this.personsListTotal = nextWholeNumDivisibleByFour <= maxPersonsList ? mergedMaxCapacity < nextWholeNumDivisibleByFour ? nextWholeNumDivisibleByFour : nextWholeNumDivisibleByFour + 4 : maxPersonsList
      }
    }
  },
  methods: {
    getItemColor (item) {
      if (item >= this.mergedMinCapacity && item <= this.mergedMaxCapacity) return this.isDarkMode ? 'green' : 'green lighten-5'

      if (item > this.mergedMaxCapacity) return this.isDarkMode ? 'orange' : 'orange lighten-5'

      return this.isDarkMode ? 'grey darken-3' : 'grey lighten-3'
    },

    setPersons (persons) {
      this.persons = persons
    },

    onOpenButtonClick () {
      if (parseInt(this.persons) > 0) {
        this.$emit('on:persons-set', this.table, this.area, this.persons)
        this.onCancelButtonClick()
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-text-field__slot {
  min-height  : 120px;
  display     : flex !important;
  align-items : center !important;
}

/deep/ .v-text-field input {
  font-size  : 70px;
  min-height : 70px;
  text-align : center;
}

/deep/ .v-text-field .v-label {
  font-size   : 30px;
  min-height  : 30px;
  line-height : 30px;
}

.v-text-field.error--text >>> .v-input__slot::after {
  border-color : #ff9800 !important;
}

.v-text-field.error--text >>> .v-input__slot::before {
  border-color : #ff9800 !important;
}

.v-text-field.error--text >>> .error--text {
  color : #ff9800 !important;
}

.v-text-field.error--text >>> input {
  caret-color : #ff9800 !important;
}
</style>
