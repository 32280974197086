<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.TableAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.TableAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!itemToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.TableAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.TableAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.TableAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.TableAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="tableForm"
            v-model="tableFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editTable.Name"
                    :counter="255"
                    :maxlength="255"
                    :hint="$t('Restaurant.TableAddDialog.Field.Name.Hint')"
                    :label="$t('Restaurant.TableAddDialog.Field.Name.Label')"
                    :prepend-icon="$t('Restaurant.TableAddDialog.Field.Name.Icon')"
                    :rules="validationRules.tableForm.Name"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="editTable.AreaId"
                    :items="tableAreaCombo"
                    :hint="$t('Restaurant.TableAddDialog.Field.AreaId.Hint')"
                    :label="$t('Restaurant.TableAddDialog.Field.AreaId.Label')"
                    :prepend-icon="$t('Restaurant.TableAddDialog.Field.AreaId.Icon')"
                    :rules="validationRules.tableForm.AreaId"
                    item-value="Id"
                    item-text="Name"
                    class="pr-2"
                    required
                  >
                    <template #item="data">
                      <v-list-item-avatar
                        size="34"
                        :color="`#${data.item.Color}`"
                      >
                        <div />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.Name" />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editTable.MinCapacity"
                    :hint="$t('Restaurant.TableAddDialog.Field.MinCapacity.Hint')"
                    :label="$t('Restaurant.TableAddDialog.Field.MinCapacity.Label')"
                    :prepend-icon="$t('Restaurant.TableAddDialog.Field.MinCapacity.Icon')"
                    :rules="validationRules.tableForm.MinCapacity"
                    type="number"
                    min="1"
                    step="1"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editTable.MaxCapacity"
                    :hint="$t('Restaurant.TableAddDialog.Field.MaxCapacity.Hint')"
                    :label="$t('Restaurant.TableAddDialog.Field.MaxCapacity.Label')"
                    :prepend-icon="$t('Restaurant.TableAddDialog.Field.MaxCapacity.Icon')"
                    :rules="validationRules.tableForm.MaxCapacity"
                    type="number"
                    min="1"
                    step="1"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editTable.Priority"
                    :hint="$t('Restaurant.TableAddDialog.Field.Priority.Hint')"
                    :label="$t('Restaurant.TableAddDialog.Field.Priority.Label')"
                    :prepend-icon="$t('Restaurant.TableAddDialog.Field.Priority.Icon')"
                    :rules="validationRules.tableForm.Priority"
                    clearble
                    type="number"
                    step="1"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <div>
                    <v-text-field
                      v-model="editTable.QrUrl"
                      :hint="!itemToEdit ? $t('Restaurant.TableAddDialog.Field.QrUrl.Hint') : ''"
                      :label="$t('Restaurant.TableAddDialog.Field.QrUrl.Label')"
                      :prepend-icon="$t('Restaurant.TableAddDialog.Field.QrUrl.Icon')"
                      :persistent-hint="!itemToEdit"
                      readonly
                      class="pr-2"
                      required
                    >
                      <template #append-outer>
                        <v-btn
                          class="ma-0 d-inline-block"
                          icon
                          small
                          @click="copyToClipboard(editTable.QrUrl)"
                        >
                          <v-icon small>
                            far fa-copy
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>
                  <v-switch
                    :id="editTable.Id + '_status'"
                    v-model="editTable.Active"
                    inset
                    :label="editTable.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    class="pa-0 ma-0"
                    color="success"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.TableAreaAddDialog.Field.Bookable.Label') }}
                  </v-subheader>

                  <v-switch
                    v-model="editTable.Bookable"
                    inset
                    :label="editTable.Bookable ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0 d-inline-block"
                    color="indigo"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-subheader class="pl-0 mt-2">
                    {{ $t('Restaurant.TableAreaAddDialog.Field.BookableOnline.Label') }}
                  </v-subheader>

                  <v-switch
                    v-model="editTable.BookableOnline"
                    inset
                    :label="editTable.BookableOnline ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    class="pa-0 ma-0 d-inline-block"
                    color="blue"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '../../../../mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'TableAddDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    },
    tableAreaCombo: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      loading       : false,
      tableFormValid: false,
      categories    : [],
      newTable      : null,
      tmpTable      : {
        Id            : null,
        AreaId        : 1,
        AreaName      : '',
        AreaColor     : '',
        StatusId      : 22,
        StatusName    : '',
        StatusColor   : '',
        Name          : '',
        MinCapacity   : 1,
        MaxCapacity   : '',
        Active        : true,
        Bookable      : true,
        BookableOnline: false,
        Priority      : ''
      },
      validationRules: {
        tableForm: {
          Name: [
            (v) => v && v.length >= 1 && v.length <= 255 || this.$t('Restaurant.TableAddDialog.Field.Name.Error.Between'),
            (v) => v && /^[A-Za-zΑ-Ωα-ωίϊΐόάέύϋΰήώΊΪΌΆΈΎΫΉΏ0-9 /-:]*$/.test(v) || this.$t('Restaurant.TableAddDialog.Field.Name.Error.Regex')
          ],
          AreaId: [
            (v) => !!v || this.$t('Restaurant.TableAddDialog.Field.AreaId.Error.Mandatory')
          ],
          MinCapacity: [
            (v) => !!v || this.$t('Restaurant.TableAddDialog.Field.MinCapacity.Error.Mandatory'),
            (v) => v && !isNaN(v) || this.$t('Restaurant.TableAddDialog.Field.MinCapacity.Error.Number'),
            (v) => v && parseInt(v) > 0 || this.$t('Restaurant.TableAddDialog.Field.MinCapacity.Error.GreaterThanZero'),
            (v) => v && parseInt(v) <= parseInt(this.editTable.MaxCapacity) || this.$t('Restaurant.TableAddDialog.Field.MinCapacity.Error.LessOrEqualThanMaxCapacity')
          ],
          MaxCapacity: [
            (v) => !!v || this.$t('Restaurant.TableAddDialog.Field.MaxCapacity.Error.Mandatory'),
            (v) => v && !isNaN(v) || this.$t('Restaurant.TableAddDialog.Field.MaxCapacity.Error.Number'),
            (v) => v && parseInt(v) > 0 || this.$t('Restaurant.TableAddDialog.Field.MaxCapacity.Error.GreaterThanZero'),
            (v) => v && parseInt(v) >= parseInt(this.editTable.MinCapacity) || this.$t('Restaurant.TableAddDialog.Field.MaxCapacity.Error.GreaterOrEqualThanMinCapacity')
          ],
          Priority: [
            (v) => !v || (v && !isNaN(v)) || this.$t('Restaurant.TableAddDialog.Field.Priority.Error.Number'),
            (v) => !v || (v && parseInt(v) > 0) || this.$t('Restaurant.TableAddDialog.Field.Priority.Error.GreaterThanZero')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editTable: {
      get () {
        if (this.itemToEdit) {
          this.newTable = JSON.parse(JSON.stringify(this.itemToEdit))
        } else {
          this.newTable = JSON.parse(JSON.stringify(this.tmpTable))
        }

        return this.newTable
      }
    }
  },
  watch: {
    'editTable.BookableOnline': function (newVal) {
      if (newVal) this.editTable.Bookable = true
    },

    visible (newVal) {
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.tableForm) this.$refs.tableForm.resetValidation()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Table.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Table.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.tableForm.validate()

      this.$nextTick(() => {
        if (this.tableFormValid) {
          this.loading = true

          window.callAS(window.SocketCommand.Table.Save, this.editTable)
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.FieldError'),
            type: 'error',
            icon: 'warning'
          })
        }
      })
    },

    onSaveResult (data) {
      if (data && (data.status === 'success' || data.result)) {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.TableAddDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      this.$refs.tableForm && this.$refs.tableForm.resetValidation()
      this.newTable = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
