import BaseModel from '../../../lib/data/Models/BaseModel'

export default class MessageGroupModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = rawData.Id || -1
    this.Name = rawData.Name || ''
  }

  boot () {

  }

  /* PROPERTIES */

  /* METHODS */
}
