/**
 * Enum for order types.
 * @readonly
 * @enum {Number} OrderTypeEnum
 */

const OrderTypeEnum = {
  ALL       : 0,
  DELIVERY  : 1,
  COLLECTION: 2
}

export default OrderTypeEnum
