import BaseModel                from '@/lib/data/Models/BaseModel'
import AccountOverviewValidator from '@/api/Validations/account/AccountOverviewValidator'

export default class AccountOverviewModel extends BaseModel {
  constructor (rawData = {}, validator = AccountOverviewValidator) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = rawData.Id || null
    this.Firstname = rawData.Firstname || ''
    this.Lastname = rawData.Lastname || ''
    this.FullName = rawData.FullName || ''
    this.Email = rawData.Email || ''
    this.Telephone = rawData.Telephone || ''
    this.Locale = rawData.Locale || ''
    this.Pin = rawData.Pin || ''
    this.GroupName = rawData.GroupName || ''
    this.GroupId = rawData.GroupId || null
    this.CreatedAt = rawData.CreatedAt || ''
    this.ThemeMode = rawData.ThemeMode || 'light'
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */
}
