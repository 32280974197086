<template>
  <v-container
    class="fill-height white--text"
    fluid
  >
    <v-row
      class="fill-height"
      no-gutters
    >
      <v-col
        cols="12"
        class="align-content-start pt-6"
      >
        <div class="text-h4">
          {{ $t('Common.Misc.Welcome') }}
        </div>
      </v-col>

      <v-col
        cols="12"
        class="align-content-center"
      >
        <div
          class="grey--text font-weight-light"
          style="font-size: 2.6rem; line-height: 3rem;"
        >
          {{ date }}
        </div>

        <div
          class="mt-6 font-weight-light"
          style="font-size: 10rem; line-height: 10rem;"
        >
          {{ time }}<span class="text-h2 ml-2">{{ second }}</span>
        </div>
      </v-col>

      <v-col
        cols="12"
        class="align-content-end pb-2"
      >
        <svg-logo
          fork-color="#fff"
          pro-color="#fff"
          signal-color1="#7f8082"
          signal-color2="#6b6c6e"
          text-main-active
          text-get-color="#fff"
          text-slogan-color="#fff"
          :text-slogan-active="false"
          size="120px"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SvgLogo from '@/components/common/SvgLogo.vue'

export default {
  name      : 'CdsWelcome',
  components: { SvgLogo },
  directives: {},
  mixins    : [],
  props     : {},
  data () {
    return {
      interval: null,
      date    : null,
      time    : null,
      second  : null
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.startInterval()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.stopInterval()
  },
  destroyed () {},
  methods: {
    startInterval () {
      this.setDateTimeNow()
      this.interval = setInterval(() => {
        this.setDateTimeNow()
      }, 1000)
    },

    stopInterval () {
      clearInterval(this.interval)
    },

    setDateTimeNow () {
      const d = new Date()

      this.date = Intl.DateTimeFormat(this.$i18n.locale, {
        weekday: 'long',
        day    : 'numeric',
        month  : 'long'
      }).format(d)

      this.time = Intl.DateTimeFormat(this.$i18n.locale, {
        hour  : '2-digit',
        minute: '2-digit',
        hour12: false
      }).format(d)

      this.second = this.padZero(d.getSeconds())
    },

    padZero (number) {
      if (number < 10) return '0' + number

      return number
    }
  }
}
</script>

<style scoped>

</style>
