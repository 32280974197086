<template>
  <div>
    <v-dialog
      v-if="selectedWaiterStaff && selectedShiftsDay"
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1 mb-0"
          height="80"
          max-height="80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ $t('Waiter.WaiterStaffDayShiftsDialog.Header.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Waiter.WaiterStaffDayShiftsDialog.Header.Title') }}
            </div>

            <div class="text-caption grey--text">
              {{ selectedWaiterStaff.Firstname }} {{ selectedWaiterStaff.Lastname }}:
              <span class="grey--text text--darken-1">
                {{ selectedShiftsDay.ShiftsDate }}
              </span>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-tabs
          v-model="activeTab"
          :grow="selectedShiftsDay.Shifts.length < 7"
          background-color="indigo"
          slider-color="light-green"
          dark
        >
          <v-tab
            v-for="(shift, key) in selectedShiftsDay.Shifts"
            :key="'tab' + key"
            :href="'#tab' + key"
            active-class="v-tabs__item--active indigo darken-2"
            ripple
            @click="shiftToggle(shift)"
          >
            <template v-if="!shift.TimeEndedFmt">
              {{ $t('Waiter.WaiterStaffDayShiftsDialog.Header.Shift') }}<br>{{ shift.ShowDates ? shift.DateStartedFmt + ' ' + shift.TimeStartedFmt : shift.TimeStartedFmt }} - {{ $t('Common.Date.Now') }}
            </template>

            <template v-else>
              {{ $t('Waiter.WaiterStaffDayShiftsDialog.Header.Shift') }}<br>{{ shift.ShowDates ? shift.DateStartedFmt + ' ' + shift.TimeStartedFmt : shift.TimeStartedFmt }} - {{ shift.ShowDates ? shift.DateEndedFmt + ' ' + shift.TimeEndedFmt : shift.TimeEndedFmt }}
            </template>
          </v-tab>
        </v-tabs>

        <v-container
          fluid
          class="pa-4"
        >
          <v-row>
            <v-col
              class="text-left"
              sm="3"
              cols="6"
            >
              <div
                v-if="selectedShift"
                class="text-center"
              >
                <div class="text-caption pl-1 pt-0 blue--text">
                  <strong>{{ $t('Waiter.Common.ShiftStart') }}</strong>
                </div>
                <div class="text-h6 grey--text text--darken-1">
                  {{ selectedShift.ShiftStartMoney | currency }}
                </div>
                <div
                  class="text-caption pl-1 pt-1 grey--text"
                  style="white-space: nowrap;"
                >
                  {{ selectedShiftsDay.ShiftsDate }}
                </div>
                <div class="text-caption">
                  {{ selectedShift.TimeStartedFmt }}
                </div>
              </div>
            </v-col>

            <v-col
              class="text-left"
              sm="3"
              cols="6"
            >
              <div
                v-if="selectedShift"
                class="text-center"
              >
                <div class="text-caption pl-1 pt-0 orange--text">
                  <strong>{{ $t('Waiter.Common.ShiftTotal') }}</strong>
                </div>
                <div class="text-h6 grey--text text--darken-1">
                  {{ (selectedShift.ShiftStartMoney + selectedShift.Payments.CodTotal) | currency }}
                </div>
                <div
                  class="text-caption pl-1 pt-1"
                  style="white-space: nowrap;"
                >
                  <span class="grey--text">
                    {{ $t('Waiter.Common.Orders') }}
                  </span>
                  <div>{{ selectedShift.Payments.CodTotal | currency }}</div>
                </div>
              </div>
            </v-col>

            <v-col
              class="text-right"
              sm="6"
              cols="12"
            >
              <v-menu
                v-if="appMachinePrintersActive.length > 1 && appHasPrinters && userCanManage"
                left
                offset-y
                open-on-hover
              >
                <template #activator="{on}">
                  <v-btn
                    absolute
                    class="grey"
                    dark
                    icon
                    right
                    style="margin-top: 220px; top: -14px;"
                    top
                    v-on="on"
                  >
                    <v-icon>print</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(printer, index) in appMachinePrintersActive"
                    :key="index"
                    @click="onPrintDeliveryShift(printer)"
                  >
                    <v-list-item-action>
                      <v-icon>print</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ printer.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                v-if="(appMachinePrintersActive.length === 1 && appHasPrinters) && userCanManage"
                absolute
                class="grey"
                dark
                icon
                right
                style="margin-top: 220px; top: -14px;"
                top
                @click.native="onPrintDeliveryShift(appMachinePrintersActive[0])"
              >
                <v-icon>print</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-divider />

        <v-subheader class="pl-0 pt-4 grey--text text--darken-2">
          <v-spacer />

          <template v-if="selectedShift">
            {{ selectedShift.Payments.length > 0 ? $t('Waiter.Common.ShiftsOrders') + selectedShift.Payments.length : '' }}
          </template>
        </v-subheader>

        <v-card-text class="px-2">
          <v-tabs-items
            v-if="selectedShift"
            v-model="activeTab"
          >
            <v-tab-item
              :key="activeTab"
              :value="activeTab"
            >
              <v-container
                class="pa-0"
                fluid
              >
                <v-row>
                  <v-col
                    v-if="selectedShift.Payments.length <= 0"
                    cols="12"
                  >
                    <div
                      class="text-center grey--text pa-6"
                    >
                      <v-icon
                        :color="$t('Waiter.WaiterStaffDayShiftsDialog.Result.NoOrders.Color')"
                        class="mb-4"
                        size="128"
                      >
                        {{ $t('Waiter.WaiterStaffDayShiftsDialog.Result.NoOrders.Icon') }}
                      </v-icon>
                      <div
                        class="text-h6 mb-2"
                        style="height: auto;"
                      >
                        {{ $t('Waiter.WaiterStaffDayShiftsDialog.Result.NoOrders.Title') }}
                      </div>
                      <div
                        class="text-subtitle-1"
                        style="height: auto;"
                      >
                        {{ $t('Waiter.WaiterStaffDayShiftsDialog.Result.NoOrders.Subtitle') }}
                      </div>
                    </div>
                  </v-col>

                  <v-col
                    v-else
                    cols="12"
                  >
                    <payments-list
                      :data="selectedShift.Payments"
                      @list-tile-click="showPaymentDetails"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData      from '@/mixins/appdata'
import Auth         from '@/mixins/auth'
import PaymentsList from '@/components/waiters/PaymentsList.vue'
import Printer      from '@/lib/helper/printer'

export default {
  name      : 'WaiterStaffDayShiftsDialog',
  components: {
    PaymentsList
  },
  mixins: [AppData, Auth],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    selectedWaiterStaff: {
      type   : Object,
      default: undefined
    },
    selectedShiftsDay: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      activeTab    : 'tab0',
      selectedShift: null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        if (this.selectedShiftsDay.Shifts.length > 0) {
          this.activeTab = 'tab'
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.shiftToggle(this.selectedShiftsDay.Shifts[0])
              this.activeTab = 'tab0'
            })
          })
        }
      }
    }
  },
  methods: {
    shiftToggle (shift) {
      this.selectedShift = shift
    },

    showPaymentDetails (item) {
      this.$emit('waiter-staff:show-payment-details', item)
    },

    onCancelButtonClick () {
      this.selectedShift = null
      this.isVisible = false
    },

    getWaiterStaffShiftTotals (withShiftStartMoney = true) {
      if (!this.selectedShift) return 0.00

      let total = this.selectedShift?.Payments?.CodTotal || 0.00

      if (withShiftStartMoney) total += parseFloat(this.selectedShift?.ShiftStartMoney || 0)

      return total
    },

    onPrintDeliveryShift (printer) {
      if (!this.selectedShift || !this.selectedWaiterStaff) return

      let printContents = ''
      const cardTotal = this.selectedShift?.Payments?.CardTotal || 0
      const shiftStartMoney = this.selectedShift?.ShiftStartMoney || 0
      const shiftStartDateTime = `${ this.selectedShift?.DateStartedFmt } ${ this.selectedShift?.TimeStartedFmt }`

      printContents += '<div style="font-family: Arial; width: 100%; text-align: center; padding-top: 12px; padding-bottom: 12px;">'
      printContents += '<div style="font-size: 12px; text-align: center; padding-bottom: 4px;"><strong>' + this.$tc('Waiter.Title', 1) + '</strong></div>'
      printContents += '<div style="font-size: 12px; text-align: center;">' + (String(this.selectedWaiterStaff.Firstname + ' ' + this.selectedWaiterStaff.Lastname).toLocaleUpperCase(this.$i18n.locale)) + '</div>'
      printContents += '</div>'

      if (this.selectedShift) {
        printContents += '' +
          '<div style="margin-bottom: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.StartShift') + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(shiftStartMoney) + '</div>' +
          '<div>' + shiftStartDateTime + '</div>' +
          '</div>'

        printContents += '' +
          '<div style="width: 100%; margin-top: 20px; font-family: Arial; font-size: 12px; text-align: center;">' +
          '<div style="font-size: 14px;"><strong>' + this.$t('Waiter.Common.ShiftTotal') + '</strong></div>' +
          '<div style="font-size: 34px; font-weight: 400;">' + this.$options.filters.currency(this.getWaiterStaffShiftTotals()) + '</div>' +
          '<div><strong>' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.Cash') + '</strong> ' + this.$options.filters.currency(this.getWaiterStaffShiftTotals(false)) + '</div>'

        if (cardTotal > 0) {
          printContents += '<br><div style="font-size: 12px;"><strong>' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.NotIncluded') + '</strong></div>'
          printContents += '<div style="font-size: 12px;">' + this.$options.filters.currency(cardTotal) + ' ' + this.$t('Waiter.PrintDeliveryShift.PrintReceipt.ByCard') + '</div>'
        }

        printContents += '</div>'
      }

      const html = '<html>' +
        '<head>' +
        '<style>' +
        ' @page {' +
        '            width: 100%;' +
        '            margin-left   : 0px;' +
        '            margin-right  : 0px;' +
        '            margin-top    : 0px;' +
        '            margin-bottom : 0px;' +
        '        }' +
        'body { width: 100%; border: 0; margin: 0; overflow : hidden; }' +
        '@media print { body { width: 100%; overflow : hidden; border: 0; margin: 0; } }' +
        '</style>' +
        '</head>' +
        '<body>' + printContents + '</body>' +
        '</html>'

      this.$bus.$emit('app-show-notification', {
        body: this.$t('Waiter.PrintDeliveryShift.Notification'),
        type: 'info',
        icon: 'print'
      })

      Printer.printStaffShift(html, printer.name)
    }
  }
}
</script>
