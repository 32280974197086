<template>
  <v-container
    v-if="viewData"
    fluid
  >
    <!--Period/Date Selection Controls-->
    <v-row dense>
      <v-col
        class=""
        cols="12"
      >
        <v-toolbar
          class="top10Toolbar rounded"
          color=""
          dense
        >
          <v-avatar
            size="48"
            style="min-width: 48px;"
            tile
          >
            <svg-logo
              size="45px"
              :fork-color="isDarkMode ? '#fff' : '#414042'"
              :pro-color="isDarkMode ? '#fff' : '#414042'"
              :signal-color1="isDarkMode ? '#fff' : '#58595b'"
              :signal-color2="isDarkMode ? '#fff' : '#414042'"
            />
          </v-avatar>

          <v-divider
            class="mr-1"
            style="margin-left: 1px;"
            vertical
          />

          <v-btn-toggle
            v-model="dashboardTimeRange"
            class="transparent"
            mandatory
            borderless
            tile
            dense
            @change="onDashboardTimeRangeChange"
          >
            <v-btn
              text
              color="grey"
              :active-class="toolbarButtonActiveClass"
              value="today"
              class="rounded"
            >
              <v-icon
                small
                left
                :color="dashboardTimeRange === 'today' ? toolbarButtonActiveIconColor : 'grey'"
              >
                fas fa-calendar-day
              </v-icon>

              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('Common.Date.Today') }}
              </span>
            </v-btn>

            <v-btn
              text
              color="grey"
              :active-class="toolbarButtonActiveClass"
              value="week"
              class="rounded"
            >
              <v-icon
                small
                left
                :color="dashboardTimeRange === 'week' ? toolbarButtonActiveIconColor : 'grey'"
              >
                fas fa-calendar-week
              </v-icon>

              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('Common.Date.Week') }}
              </span>
            </v-btn>

            <v-btn
              text
              color="grey"
              :active-class="toolbarButtonActiveClass"
              value="month"
              class="rounded"
            >
              <v-icon
                small
                left
                :color="dashboardTimeRange === 'month' ? toolbarButtonActiveIconColor : 'grey'"
              >
                fas fa-calendar-alt
              </v-icon>

              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('Common.Date.Month') }}
              </span>
            </v-btn>

            <v-btn
              text
              color="grey"
              :active-class="toolbarButtonActiveClass"
              value="year"
              class="rounded"
            >
              <v-icon
                small
                left
                :color="dashboardTimeRange === 'year' ? toolbarButtonActiveIconColor : 'grey'"
              >
                fas fa-calendar
              </v-icon>

              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('Common.Date.Year') }}
              </span>
            </v-btn>

            <v-btn
              text
              color="grey"
              :active-class="toolbarButtonActiveClass"
              value="custom"
              class="rounded"
            >
              <v-icon
                small
                left
                :color="dashboardTimeRange === 'custom' ? toolbarButtonActiveIconColor : 'grey'"
              >
                fas fa-calendar-plus
              </v-icon>

              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('Common.Date.Period') }}
              </span>
            </v-btn>
          </v-btn-toggle>

          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mx-2"
            vertical
          />

          <template v-if="dashboardTimeRange === 'custom'">
            <template :slot="$vuetify.breakpoint.smAndDown && dashboardTimeRange === 'custom' ? 'extension' : 'default'">
              <v-menu
                v-show="statsDateFieldsVisible"
                ref="statsStartDateVisible"
                v-model="statsStartDateVisible"
                :close-on-content-click="false"
                left
                max-width="290px"
                min-width="140px"
                offset-y
                style="width: 100%"
                transition="scale-transition"
              >
                <template #activator="{on, attrs}">
                  <v-text-field
                    v-model="statsStartDateFmt"
                    :label="$t('Common.Date.PeriodFrom')"
                    class="dateTextField ml-2"
                    :background-color="isDarkMode ? '' : 'grey lighten-3'"
                    clearable
                    flat
                    height="36px"
                    prepend-inner-icon="event"
                    readonly
                    single-line
                    solo
                    v-bind="attrs"
                    @blur="statsStartDate = parseDate(statsStartDateFmt)"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="statsStartDate"
                  :allowed-dates="allowedStartDates"
                  :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                  first-day-of-week="1"
                  scrollable
                  @input="statsStartDateFmt = formatDate($event); statsStartDateVisible = false"
                />
              </v-menu>

              <v-menu
                v-show="statsDateFieldsVisible"
                ref="statsEndDateVisible"
                v-model="statsEndDateVisible"
                :close-on-content-click="false"
                left
                max-width="290px"
                min-width="140px"
                offset-y
                style="width: 100%"
                transition="scale-transition"
              >
                <template #activator="{on, attrs}">
                  <v-text-field
                    v-model="statsEndDateFmt"
                    :label="$t('Common.Date.PeriodTo')"
                    class="dateTextField ml-2 mr-2"
                    :background-color="isDarkMode ? '' : 'grey lighten-3'"
                    clearable
                    flat
                    height="36px"
                    hide-details
                    prepend-inner-icon="event"
                    readonly
                    single-line
                    solo
                    v-bind="attrs"
                    @blur="statsEndDate = parseDate(statsEndDateFmt)"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="statsEndDate"
                  :allowed-dates="allowedEndDates"
                  :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                  first-day-of-week="1"
                  scrollable
                  @input="statsEndDateFmt = formatDate($event); statsEndDateVisible = false"
                />
              </v-menu>
            </template>
          </template>
        </v-toolbar>
      </v-col>

      <v-col cols="12">
        <v-divider class="mt-2" />
      </v-col>
    </v-row>

    <!--Current Plan & Upgrade Plan-->
    <v-row dense>
      <current-plan-warning
        ref="currentPlan"
        class="mt-1"
        :show-before-expire="false"
      />
    </v-row>

    <!--Top Stats-->
    <v-row dense>
      <!--Total Stats-->
      <v-col
        sm="4"
        cols="12"
      >
        <v-row dense>
          <v-col cols="12">
            <mini-stats-icon-card
              v-if="viewData.items.Orders && viewData.items.Sales"
              :flat="false"
              :icon-title="$t('Common.Misc.Total').toLocaleUpperCase($i18n.locale)"
              :sub-title="`${viewData.items.Orders.value} ${$tc('Order.Title', viewData.items.Orders.value)}`"
              :title="$options.filters.currency(viewData.items.Sales.value)"
              :to="posUserHasComponentPermission('Orders', 'ACCESS') ? {name: 'OrdersToday'} : ''"
              color-class="green"
              icon="shopping_cart"
              :icon-background-color="isDarkMode ? 'grey darken-4' : 'white'"
            />
          </v-col>

          <v-col cols="12">
            <mini-stats-icon-card
              v-if="viewData.items.Customers"
              :flat="false"
              :icon-title="$t('Common.Misc.Total').toLocaleUpperCase($i18n.locale)"
              :sub-title="$tc('Customer.Title', viewData.items.Customers.value)"
              :title="viewData.items.Customers.value.toString()"
              :to="posUserHasComponentPermission('Customers', 'ACCESS') ? {name: 'Customers'} : ''"
              color-class="light-blue"
              icon="account_box"
              :icon-background-color="isDarkMode ? 'grey darken-4' : 'white'"
            />
          </v-col>
        </v-row>
      </v-col>

      <!--Stats By Order Type-->
      <v-col
        sm="4"
        cols="12"
      >
        <v-row dense>
          <template v-for="orderType in orderTypes">
            <v-col
              :key="'orderType_' + orderType.Id"
              cols="12"
            >
              <mini-stats-icon-card
                :color-class="orderType.Id === 1 ? 'purple' : 'blue darken-3'"
                :icon="orderType.Icon"
                :icon-title="orderType.Title.toLocaleUpperCase($i18n.locale)"
                :sub-title="`${ordersByOrderType(orderType.Id)} ${$tc('Order.Title', viewData.items.Orders.value)}`"
                :title="salesByOrderType(orderType.Id)"
                :icon-background-color="isDarkMode ? 'grey darken-4' : 'white'"
              />
            </v-col>
          </template>
        </v-row>
      </v-col>

      <!--Stats By Payment Type-->
      <v-col
        sm="4"
        cols="12"
      >
        <v-row dense>
          <template v-for="(paymentType, index) in paymentTypesLang">
            <v-col
              :key="'paymentType_' + index"
              cols="12"
            >
              <mini-stats-icon-card
                :color-class="paymentType.Color"
                :icon="paymentType.Code === 'cod' ? appCurrency.Icon : paymentType.Icon"
                :icon-title="paymentType.Title.toLocaleUpperCase($i18n.locale)"
                :sub-title="`${ordersByPaymentType(paymentType.Code)}  ${$tc('Order.Title', viewData.items.Orders.value)}`"
                :title="salesByPaymentType(paymentType.Code)"
                :icon-background-color="isDarkMode ? 'grey darken-4' : 'white'"
              />
            </v-col>
          </template>
        </v-row>
      </v-col>

      <!--Stats By Source Types-->
      <v-col
        class="text-right"
        cols="12"
      >
        <v-btn
          class="text-caption"
          color="grey darken-1"
          text
          small
          @click="showMoreStats = !showMoreStats"
        >
          <v-icon>{{ showMoreStats ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
          {{ $t('Common.Button.More') }}
        </v-btn>
      </v-col>

      <!--Stats By Source Types-->
      <transition name="slide-y-transition">
        <v-col
          v-show="showMoreStats"
          cols="12"
        >
          <v-row dense>
            <template v-for="sourceType in allSourceTypes">
              <v-col
                v-if="sourceType.active===1"
                :key="'sourceType_' + sourceType.source_type_id"
                lg="2"
                md="3"
                sm="4"
                cols="12"
              >
                <mini-stats-icon-card
                  :icon="sourceType.source_type_id < 100 ? sourceType.logo : ''"
                  :icon-title="sourceType.title"
                  :img="sourceType.source_type_id > 99 ? sourceType.logo : ''"
                  :sub-title="`${ordersBySourceType(sourceType.source_type_id)} ${$tc('Order.Title', viewData.items.Orders.value)}`"
                  :title="salesBySourceType(sourceType.source_type_id)"
                  :icon-background-color="isDarkMode ? 'grey darken-4' : 'white'"
                />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </transition>
    </v-row>

    <!--Charts-->
    <v-row dense>
      <!--Income Chart-->
      <v-col cols="12">
        <v-card>
          <v-toolbar
            flat
            class="top10Toolbar"
            color="teal lighten-1"
            dark
            height="60"
          >
            <v-avatar
              :color="isDarkMode ? 'grey darken-4' : 'white'"
              size="60"
              tile
            >
              <v-icon
                color="teal lighten-1"
                dark
                size="30"
              >
                far fa-chart-bar
              </v-icon>
            </v-avatar>
            <v-toolbar-title
              class="px-0 mx-4"
              style="width: 100%;"
            >
              <div class="text-subtitle-1 font-weight-bold">
                {{ $t('Dashboard.OrdersVsTurnover.Title') }}
              </div>
              <div class="text-caption">
                <template v-if="dashboardTimeRange==='today'">
                  {{ $t('Dashboard.OrdersVsTurnover.SubTitle.Today') }}
                </template>
                <template v-if="dashboardTimeRange==='week'">
                  {{ $t('Dashboard.OrdersVsTurnover.SubTitle.Week') }}
                </template>
                <template v-if="dashboardTimeRange==='month'">
                  {{ $t('Dashboard.OrdersVsTurnover.SubTitle.Month') }}
                </template>
                <template v-if="dashboardTimeRange==='year'">
                  {{ $t('Dashboard.OrdersVsTurnover.SubTitle.Year') }}
                </template>
                <template v-if="dashboardTimeRange==='custom'">
                  {{ $t('Dashboard.OrdersVsTurnover.SubTitle.Custom') }}
                </template>
              </div>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <chart-income
            v-if="incomeChartData"
            :chart-data="incomeChartData"
            :chart-options="chartOptions"
            :height="200"
            chart-id="chartIncome"
            css-classes=""
          />
        </v-card>
      </v-col>
    </v-row>

    <!--Top Products & Customers Tables-->
    <v-row dense>
      <!-- Top Products -->
      <v-col
        sm="4"
        cols="12"
      >
        <v-card>
          <v-toolbar
            flat
            class="top10Toolbar"
            color="green"
            dark
            height="60"
          >
            <v-avatar
              :color="isDarkMode ? 'grey darken-4' : 'white'"
              size="60"
              tile
            >
              <v-icon
                color="green"
                dark
                size="28"
              >
                shopping_cart
              </v-icon>
            </v-avatar>
            <v-toolbar-title
              class="px-0 mx-4"
              style="width: 100%;"
            >
              <div
                class="text-subtitle-1 font-weight-bold"
                style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
              >
                {{ viewData.items.TopProducts.value.length > 0 ? String(viewData.items.TopProducts.value.length).padStart(2, '0') : '' }} {{ $tc('Dashboard.Top10.Products.Title', viewData.items.TopProducts.value.length) }}
              </div>
              <div class="text-caption">
                {{ $t('Dashboard.Top10.Products.SubTitle') }}
              </div>
            </v-toolbar-title>

            <v-spacer />

            <template v-if="posUserCan('ProductStatistics', 'ACCESS')">
              <v-tooltip
                color="green darken-3"
                open-delay="1000"
                left
              >
                <template #activator="{on}">
                  <v-btn
                    icon
                    small
                    class="mr-4"
                    :to="{name: 'ProductStatistics'}"
                    v-on="on"
                  >
                    <v-icon>mdi-table-eye</v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('Statistics.Product.Title') }}</span>
              </v-tooltip>
            </template>
          </v-toolbar>
          <v-divider />

          <v-container
            v-if="viewData.items.TopProducts"
            class="pa-1"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <v-list
                  id="statsTopProductsContentOrders"
                  :style="getTopProductsAndCustomersListStyle()"
                  dense
                >
                  <template v-if="viewData.items.TopProducts.value.length > 0">
                    <template v-for="(item, index) in viewData.items.TopProducts.value">
                      <v-list-item
                        :key="index"
                        :disabled="!posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
                        class="list__tile__container"
                      >
                        <v-list-item-content>
                          <div class="text-caption">
                            <span
                              class="orange--text text-right mr-1"
                              style="min-width: 20px; display: inline-block;"
                            >
                              {{ item.total_orders ? item.total_orders : 0 }} x
                            </span>{{ item.menu_name }}
                          </div>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text class="text-caption">
                            {{ item.total_sales | currency }}
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </template>
                  <v-row
                    v-else
                    class="align-center fill-height ma-0 pa-0"
                  >
                    <v-col
                      class="text-center"
                      cols="12"
                    >
                      <div
                        class="grey--text pa-1"
                      >
                        <v-icon
                          class="mb-2"
                          color="grey lighten-2"
                          size="48"
                        >
                          shopping_cart
                        </v-icon>
                        <div class="text-body-2 ">
                          {{ $t('Dashboard.Top10.Products.NoData.Title') }}
                        </div>
                        <div class="text-body-1">
                          {{ $t('Dashboard.Top10.Products.NoData.SubTitle') }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <!-- Top Customers Orders-->
      <v-col
        sm="4"
        cols="12"
      >
        <v-card>
          <v-toolbar
            flat
            class="top10Toolbar"
            color="light-blue"
            dark
            height="60"
          >
            <v-avatar
              :color="isDarkMode ? 'grey darken-4' : 'white'"
              size="60"
              tile
            >
              <v-icon
                color="light-blue"
                dark
                size="28"
              >
                fas fa-users
              </v-icon>
            </v-avatar>
            <v-toolbar-title
              class="px-0 mx-4"
              style="width: 100%;"
            >
              <div
                class="text-subtitle-1 font-weight-bold"
                style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
              >
                {{
                  viewData.items.TopCustomers.value.TopOrders.length > 0 ? String(viewData.items.TopCustomers.value.TopOrders.length).padStart(2, '0') : ''
                }} {{
                  $tc('Dashboard.Top10.Customers.Orders.Title', viewData.items.TopCustomers.value.TopOrders.length)
                }}
              </div>
              <div class="text-caption">
                {{ $t('Dashboard.Top10.Customers.Orders.SubTitle') }}
              </div>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider />

          <v-container
            v-if="viewData.items.TopCustomers"
            class="pa-1"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <v-list
                  id="statsTopCustomersContentOrders"
                  :style="getTopProductsAndCustomersListStyle()"
                  dense
                >
                  <template v-if="viewData.items.TopCustomers.value.TopOrders.length > 0">
                    <template v-for="item in viewData.items.TopCustomers.value.TopOrders">
                      <v-list-item
                        :key="item.customer_id"
                        :disabled="!posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
                        class="list__tile__container"
                        @click="$bus.$emit('show-customer-details-dialog', item.customer_id)"
                      >
                        <v-list-item-content>
                          <div class="text-caption">
                            <span
                              class="orange--text text-right mr-1"
                              style="min-width: 20px; display: inline-block;"
                            >
                              {{ item.total_orders ? item.total_orders : 0 }} x
                            </span>{{ item.first_name + ' ' + item.last_name }}
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="text-caption">
                            {{ item.total_sales | currency }}
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </template>
                  <v-row
                    v-else
                    class="align-center fill-height ma-0 pa-0"
                  >
                    <v-col
                      class="text-center"
                      cols="12"
                    >
                      <div
                        class="grey--text pa-1"
                      >
                        <v-icon
                          class="mb-2"
                          color="grey lighten-2"
                          size="48"
                        >
                          fas fa-users
                        </v-icon>
                        <div class="text-body-2 ">
                          {{ $t('Dashboard.Top10.Customers.Orders.NoData.Title') }}
                        </div>
                        <div class="text-body-1">
                          {{ $t('Dashboard.Top10.Customers.Orders.NoData.SubTitle') }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <!-- Top Customers Sales-->
      <v-col
        sm="4"
        cols="12"
      >
        <v-card>
          <v-toolbar
            flat
            class="top10Toolbar"
            color="light-blue"
            dark
            height="60"
          >
            <v-avatar
              :color="isDarkMode ? 'grey darken-4' : 'white'"
              size="60"
              tile
            >
              <v-icon
                color="light-blue"
                dark
                size="28"
              >
                fas fa-users
              </v-icon>
            </v-avatar>
            <v-toolbar-title
              class="px-0 mx-4"
              style="width: 100%;"
            >
              <div
                class="text-subtitle-1 font-weight-bold"
                style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
              >
                {{
                  viewData.items.TopCustomers.value.TopSales.length > 0 ? String(viewData.items.TopCustomers.value.TopSales.length).padStart(2, '0') : ''
                }} {{
                  $tc('Dashboard.Top10.Customers.Turnover.Title', viewData.items.TopCustomers.value.TopSales.length)
                }}
              </div>
              <div class="text-caption">
                {{ $t('Dashboard.Top10.Customers.Turnover.SubTitle') }}
              </div>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider />

          <v-container
            v-if="viewData.items.TopCustomers"
            class="pa-1"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <v-list
                  id="statsTopCustomersContentSales"
                  :style="getTopProductsAndCustomersListStyle()"
                  style="overflow-y: auto;"
                  dense
                >
                  <template v-if="viewData.items.TopCustomers.value.TopSales.length > 0">
                    <template v-for="item in viewData.items.TopCustomers.value.TopSales">
                      <v-list-item
                        :key="item.customer_id"
                        :disabled="!posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
                        class="list__tile__container"
                        @click="$bus.$emit('show-customer-details-dialog', item.customer_id)"
                      >
                        <v-list-item-content>
                          <div class="text-caption">
                            <span
                              class="orange--text text-right mr-1"
                              style="min-width: 20px; display: inline-block;"
                            >
                              {{ item.total_orders ? item.total_orders : 0 }} x
                            </span>{{ item.first_name + ' ' + item.last_name }}
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="text-caption">
                            {{ item.total_sales | currency }}
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </template>
                  <v-row
                    v-else
                    class="align-center fill-height ma-0 pa-0"
                  >
                    <v-col
                      class="text-center"
                      cols="12"
                    >
                      <div
                        class="grey--text pa-1"
                      >
                        <v-icon
                          class="mb-2"
                          color="grey lighten-2"
                          size="48"
                        >
                          fas fa-users
                        </v-icon>
                        <div class="text-body-2 ">
                          {{ $t('Dashboard.Top10.Customers.Turnover.NoData.Title') }}
                        </div>
                        <div class="text-body-1">
                          {{ $t('Dashboard.Top10.Customers.Turnover.NoData.SubTitle') }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!--Settings Wizard Dialog -->
    <settings-wizard-dialog
      v-if="posUser && posUser.staff_group_id === StaffGroupEnum.OWNER || posUser.staff_group_id === StaffGroupEnum.ADMIN"
      :visible.sync="settingsWizardDialogVisible"
    />
  </v-container>
</template>

<script>
import AppData              from '../../mixins/appdata'
import Auth                 from '../../mixins/auth'
import Resizable            from '../../mixins/resizable'
import SvgLogo              from '../common/SvgLogo.vue'
import ChartIncome          from './charts/ChartIncome.vue'
import SourceType           from '../../mixins/orders/sourceType'
import OrderType            from '../../mixins/orders/orderType'
import PaymentType          from '../../mixins/orders/paymentType'
import MiniStatsIconCard    from '../common/MiniStatsIconCard'
import SettingsWizardDialog from '../settings/wizard/SettingsWizardDialog'
import CurrentPlanWarning   from '@/components/account/CurrentPlanWarning'
import StaffGroupEnum       from '@/api/Enums/StaffGroupEnum'

const defaultChartPeriod = 'week'

export default {
  components: {
    CurrentPlanWarning,
    SettingsWizardDialog,
    MiniStatsIconCard,
    SvgLogo,
    ChartIncome
  },
  mixins: [AppData, SourceType, OrderType, PaymentType, Auth, Resizable],
  beforeRouteEnter (to, from, next) {
    if (from.name !== 'Login') {
      window.callAS(window.SocketCommand.Dashboard.Get, { stats_range: defaultChartPeriod })
    }
    next()
  },
  data () {
    return {
      fab                        : false,
      showMoreStats              : false,
      settingsWizardDialogVisible: false,
      statsStartDate             : '',
      statsStartDateFmt          : '',
      statsEndDate               : '',
      statsEndDateFmt            : '',
      statsDatePeriod            : defaultChartPeriod,
      dashboardTimeRange         : defaultChartPeriod,
      statsStartDateVisible      : false,
      statsEndDateVisible        : false,
      viewDataFetched            : null,
      chartOptions               : {
        responsive         : true,
        maintainAspectRatio: false,
        tooltips           : {
          callbacks: {
            label: (tooltipItem, data) => {
              // console.log(tooltipItem, data);
              return tooltipItem.datasetIndex === 0 ? data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel : data.datasets[tooltipItem.datasetIndex].label + ': ' + this.$options.filters.currency(tooltipItem.yLabel)
            }
          }
        },
        legend: {
          position: 'top'
        },
        title: {
          display: false,
          text   : ''
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display    : true,
                labelString: this.$t('Dashboard.OrdersVsTurnover.NumOfOrders'),
                fontColor  : '#26a2f8'
              },
              stacked : false,
              display : true,
              position: 'left',
              id      : 'y-axis-1'
            }, {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display    : true,
                labelString: this.$t('Dashboard.OrdersVsTurnover.Turnover'),
                fontColor  : '#44f847'
              },
              stacked : false,
              display : true,
              position: 'right',
              id      : 'y-axis-2'
            }
          ],
          xAxes: [{
            type        : 'time',
            distribution: 'linear',
            time        : {
              unit          : 'day',
              stepSize      : 1,
              parser        : 'YYYY-MM-DD HH:mm:ss',
              tooltipFormat : 'DD MMM YYYY',
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second     : 'HH:mm:ss a',
                minute     : 'HH:mm a',
                hour       : 'HH:mm',
                day        : 'ddd D MMM',
                week       : 'll',
                month      : 'MMM YYYY',
                quarter    : '[Q]Q - YYYY',
                year       : 'YYYY'

              }
            },
            gridLines: {
              display: false
            },
            categoryPercentage: 0.9,
            barPercentage     : 0.9,
            stacked           : false
          }]
        }
      }
    }
  },
  computed: {
    StaffGroupEnum () {
      return StaffGroupEnum
    },

    toolbarButtonActiveClass () {
      return this.isDarkMode ? 'grey--text text--lighten-4' : 'grey--text text--darken-4'
    },
    toolbarButtonActiveIconColor () {
      return this.isDarkMode ? 'grey lighten-4' : 'grey darken-4'
    },

    incomeChartData () {
      this.$set(this.chartOptions.scales.xAxes[0].time, 'unit', this.viewData.items.Chart.custom.unit)
      this.$set(this.chartOptions.scales.xAxes[0].time, 'tooltipFormat', this.viewData.items.Chart.custom.tooltipFormat)

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.viewData.items.Chart.custom.datasets[0].yAxisID = 'y-axis-1'
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.viewData.items.Chart.custom.datasets[1].yAxisID = 'y-axis-2'

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.viewData.items.Chart.custom.datasets[0].label = this.$t('Dashboard.OrdersVsTurnover.Orders')
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.viewData.items.Chart.custom.datasets[1].label = this.$t('Dashboard.OrdersVsTurnover.Turnover')

      return this.viewData.items.Chart.custom
    },

    statsDateFieldsVisible () {
      return this.dashboardTimeRange !== 'today' && this.dashboardTimeRange !== 'week' && this.dashboardTimeRange !== 'month' && this.dashboardTimeRange !== 'year'
    },

    viewData () {
      return window.Vue.dashboardData
    }
  },
  watch: {
    statsStartDate (newVal) {
      if (this.statsEndDate && newVal) {
        this.statsDatePeriod = newVal + ' 00:00_' + this.statsEndDate + ' 00:00'
        this.onDashboardTimeRangeChange()
      } else {
        this.statsDatePeriod = defaultChartPeriod
      }
    },
    statsEndDate (newVal) {
      if (this.statsStartDate && newVal) {
        this.statsDatePeriod = this.statsStartDate + ' 00:00_' + newVal + ' 00:00'
        this.onDashboardTimeRangeChange()
      } else {
        this.statsDatePeriod = defaultChartPeriod
      }
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on('show-settings-wizard', this.onShowSettingsWizard)

    if (this.posUser.staff_group_id === StaffGroupEnum.OWNER || this.posUser.staff_group_id === StaffGroupEnum.ADMIN) {
      this.onShowSettingsWizard(this.posUser.ShowSettingsWizard)
      if (this.posUser.ShowSettingsWizard) {
        const tmpUser = this.posUser
        tmpUser.ShowSettingsWizard = false
        this.posUser = tmpUser
      }
    }
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('show-settings-wizard', this.onShowSettingsWizard)
    this.resetDashboardData()
  },
  methods: {
    isCurrentPlanWarningActive () {
      return this.$refs?.currentPlan?.isActive ?? false
    },

    getTopProductsAndCustomersListStyle () {
      const height = this.isCurrentPlanWarningActive() ? 165 : 240
      return {
        height   : this.$vuetify.breakpoint.xsOnly ? 'auto' : `${ height }px`,
        minHeight: `${ height }px`,
        overflowY: 'auto'
      }
    },

    getData (statsRange) {
      window.callAS(window.SocketCommand.Dashboard.Get, { stats_range: statsRange })
    },

    onShowSettingsWizard (visible = true) {
      this.settingsWizardDialogVisible = visible
    },

    resetDashboardData () {
      if (this.viewData && this.viewData.items) {
        const data = this.viewData.items
        data.Sales.bySourceType = []
        data.Sales.byOrderType = []
        data.Sales.byPaymentType = []
        data.Sales.value = this.$options.filters.currency(0)
        data.Customers.value = this.$options.filters.currency(0)
        data.Orders.bySourceType = []
        data.Orders.byOrderType = []
        data.Orders.byPaymentType = []
        data.Orders.value = 0
        data.TablesReserved.value = 0
        data.TopCustomers.value.TopOrders = []
        data.TopCustomers.value.TopSales = []
        data.TopProducts.value = []
        data.Chart.custom.range = 'week'
        data.Chart.custom.unit = 'day'
        data.Chart.custom.tooltipFormat = 'ddd DD MMM YYYY'
        data.Chart.custom.labels = []
        data.Chart.custom.datasets[0].data = []
        data.Chart.custom.datasets[1].data = []
      }
    },
    salesBySourceType (sourceTypeId) {
      const result = this.viewData.items.Sales.bySourceType.find((item) => sourceTypeId.toString() === item.source_type_id.toString())
      return result ? this.$options.filters.currency(result.total) : this.$options.filters.currency('0')
    },
    salesByOrderType (orderTypeId) {
      const result = this.viewData.items.Sales.byOrderType.find((item) => orderTypeId.toString() === item.order_type.toString())
      return result ? this.$options.filters.currency(result.total) : this.$options.filters.currency('0')
    },
    salesByPaymentType (paymentTypeId) {
      const result = this.viewData.items.Sales.byPaymentType.find((item) => paymentTypeId.toString() === item.payment.toString())
      return result ? this.$options.filters.currency(result.total) : this.$options.filters.currency('0')
    },
    ordersBySourceType (sourceTypeId) {
      const result = this.viewData.items.Orders.bySourceType.find((item) => sourceTypeId.toString() === item.source_type_id.toString())
      return result ? parseInt(result.total) : 0
    },
    ordersByOrderType (orderTypeId) {
      const result = this.viewData.items.Orders.byOrderType.find((item) => orderTypeId.toString() === item.order_type.toString())
      return result ? parseInt(result.total) : 0
    },
    ordersByPaymentType (paymentTypeId) {
      const result = this.viewData.items.Orders.byPaymentType.find((item) => paymentTypeId.toString() === item.payment.toString())
      return result ? parseInt(result.total) : 0
    },
    onDashboardTimeRangeChange () {
      if (this.dashboardTimeRange !== 'custom') {
        this.getData(this.dashboardTimeRange)
      } else {
        if (this.statsDatePeriod !== '' && this.statsDatePeriod !== defaultChartPeriod) {
          this.getData(this.statsDatePeriod)
        }
      }
    },
    allowedStartDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.statsEndDate ? new Date(this.statsEndDate).getTime() : null
      return maxDate ? (currDate <= maxDate) : true
    },
    allowedEndDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.statsStartDate ? new Date(this.statsStartDate).getTime() : null
      return maxDate ? (currDate >= maxDate) : true
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },
    parseDate (date) {
      if (!date) {
        return null
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    },
    onResize () {
    }
  }
}
</script>

<style scoped>
/deep/ .list__tile__container {
  padding    : 0 4px !important;
  height     : 26px !important;
  min-height : 26px !important;
}

/deep/ .dateTextField {
  /*max-width : 160px;*/
  max-width : 100%;
}

/deep/ .dateTextField .v-input__prepend-outer {
  margin-top : 8px;
}

/deep/ .dateTextField .v-input__control {
  line-height : 36px !important;
  height      : 36px !important;
  min-height  : 36px !important;
}

/deep/ .top10Toolbar .v-toolbar__content, /deep/ .top10Toolbar .v-toolbar__extension {
  padding : 0 !important;
}
</style>
