export default {
  data () {
    return {}
  },

  props: {
    asc: {
      type   : Boolean,
      default: false
    },
    desc: {
      type   : Boolean,
      default: false
    }
  },

  computed: {
    carts () {
      const data = JSON.parse(JSON.stringify(this.viewData?.ItemsGrouped || this.posCart?.ItemsGrouped || []))

      if (this.desc) return data.sort((a, b) => parseInt(b.Timestamp) - parseInt(a.Timestamp))

      return data
    },

    cartHasCoupon () {
      const data = this.viewData || this.posCart
      return data ? !!data.Totals.find(total => total.Name === 'coupon') : false
    },

    cartHasTip () {
      const data = this.viewData || this.posCart
      return data ? !!data.Totals.find(total => total.Name === 'tip_amount') : false
    }
  },

  methods: {
    getCartItemsByRowIds (rowIdsArray, ungrouped = false, singleQuantity = false) {
      const data = this.viewData || this.posCart

      const items = data?.Items?.filter(item => rowIdsArray.includes(item.RowId)) || []

      if (ungrouped) return items

      const itemsByTableSeat = this.groupCartItemsByTableSeat(items)
      itemsByTableSeat.forEach(tableSeat => {
        tableSeat.Items = this.groupCartItemsByCategory(tableSeat.Items, singleQuantity)
      })

      return itemsByTableSeat
    },

    getMenuSelectedOptionsString (menu) {
      let menuArr = menu.Options?.filter(element => element.Selected && element.DisplayType !== 'unit' && element.DisplayType !== 'extra_charges')?.map(element => this.getTranslatedField(element, 'Name')) || []
      if (menu.Comment) menuArr.push(menu.Comment)
      const menuString = menuArr.join(', ')
      menuArr = null
      return menuString
    },

    groupCartItemsByTableSeat (cartItems) {
      const items = cartItems?.reduce(function (groups, item) {
        const val = item?.Custom?.Seat || 0
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {}) || []

      let prop
      const arr = []
      for (prop in items) {
        if (items.hasOwnProperty(prop)) {
          arr.push({
            Seat : parseInt(prop),
            Name : parseInt(prop) ? `${ this.$tc('Restaurant.Tables.Seat.Title', 1) } ${ prop }` : this.$tc('Restaurant.Tables.Title', 1),
            Items: items[prop]
          })
        }
      }
      arr.sort((a, b) => { return a.Seat - b.Seat })

      return arr
    },

    groupCartItemsByCategory (cartItems, singleQuantity = false) {
      const items = cartItems?.reduce(function (groups, item) {
        const val = item.CategoryId
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {}) || []

      let prop
      const arr = []
      for (prop in items) {
        if (items.hasOwnProperty(prop)) {
          arr.push({
            CategoryId      : parseInt(prop),
            CategoryPriority: parseInt(items[prop][0].CategoryPriority),
            CategoryName    : items[prop][0].CategoryName,
            Items           : items[prop]
          })
        }
      }
      arr.sort((a, b) => { return a.CategoryPriority - b.CategoryPriority })

      if (singleQuantity) {
        arr.forEach(category => {
          const categoryItems = []
          category.Items.forEach(item => {
            if (this.menuItemHasCoupon(item) || item.Quantity === 1) {
              const itemObj = JSON.parse(JSON.stringify(item))
              itemObj.HashId = itemObj.RowId
              categoryItems.push(itemObj)
            } else {
              for (let i = 0; i < item.Quantity; i++) {
                const itemObj = JSON.parse(JSON.stringify(item))
                itemObj.Quantity = 1
                itemObj.HashId = itemObj.RowId + '-' + i
                categoryItems.push(itemObj)
              }
            }
          })
          category.Items = categoryItems
        })
      }

      return arr
    },

    menuItemHasCoupon (item) {
      return !!this.getMenuItemCoupon(item)
    },

    getMenuItemCoupon (item) {
      const data = this.viewData || this.posCart
      return data?.Totals?.find(total => total.Name === 'coupon' && total.RowId === item.RowId) || null
    }

  }
}
