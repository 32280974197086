<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Support.Toolbar.Color')"
          >
            <v-icon color="indigo lighten-5">
              {{ $t('Support.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Support.TicketAddDialog.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Support.TicketAddDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            class="ml-0"
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="ticketForm"
            v-model="ticketFormValid"
            @submit.prevent
          >
            <v-container
              class="fill-height pa-1"
              fluid
            >
              <v-row dense>
                <v-col
                  sm="4"
                  cols="12"
                >
                  <v-select
                    v-model="editTicket.department"
                    :items="departments.all()"
                    :label="$t('Support.TicketAddDialog.Field.Department.Label')"
                    :rules="editTicket.vuetifyFormFieldRules('department')"
                    filled
                    item-text="name"
                    item-value="departmentid"
                  />
                </v-col>

                <v-col
                  sm="4"
                  cols="12"
                >
                  <v-select
                    v-model="editTicket.service"
                    :items="services.all()"
                    :label="$t('Support.TicketAddDialog.Field.Service.Label')"
                    :rules="editTicket.vuetifyFormFieldRules('service')"
                    filled
                    item-text="name"
                    item-value="serviceid"
                  />
                </v-col>

                <v-col
                  sm="4"
                  cols="12"
                >
                  <v-select
                    v-model="editTicket.priority"
                    :items="priorities.all()"
                    :label="$t('Support.TicketAddDialog.Field.Priority.Label')"
                    :rules="editTicket.vuetifyFormFieldRules('priority')"
                    type="number"
                    step="1"
                    filled
                    item-text="name"
                    item-value="priorityid"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editTicket.subject"
                    :counter="255"
                    :label="$t('Support.TicketAddDialog.Field.Subject.Label')"
                    :placeholder="$t('Support.TicketAddDialog.Field.Subject.PlaceHolder')"
                    :rules="editTicket.vuetifyFormFieldRules('subject')"
                    filled
                  />
                </v-col>

                <v-col cols="12">
                  <vue-editor
                    v-model="editTicket.message"
                    :editor-options="editorSettings"
                    :editor-toolbar="editorToolbar"
                    :rules="editTicket.vuetifyFormFieldRules('message')"
                  />
                  <v-messages
                    v-model="messageError"
                    class="pl-2 pt-2 ml-1"
                    color="error"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="elevation-0"
            color="light-green"
            dark
            @click="onSendButtonClick"
          >
            {{ $t('Common.Button.Send').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Quill, VueEditor }  from 'vue2-editor'
import MagicUrl              from 'quill-magic-url'
import SupportTicketAddModel from '../../api/Models/support-center/SupportTicketAddModel'

const AlignStyle = Quill.import('attributors/style/align')
const BackgroundStyle = Quill.import('attributors/style/background')
const DirectionStyle = Quill.import('attributors/style/direction')
const ColorStyle = Quill.import('attributors/style/color')
const SizeStyle = Quill.import('attributors/style/size')

Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(ColorStyle, true)
Quill.register(SizeStyle, true)
Quill.register('modules/magicUrl', MagicUrl)

export default {
  name      : 'TicketAddDialog',
  components: { VueEditor },
  mixins    : [],
  props     : {
    visible: {
      type    : Boolean,
      default : false,
      required: true
    }
  },
  DataStore: {
    departments: 'support.departments',
    services   : 'support.services',
    priorities : 'support.priorities'
  },
  data () {
    return {
      loading            : false,
      scheduledDateDialog: false,
      scheduledTimeDialog: false,
      scheduledDate      : '',
      scheduledTime      : '',
      ticketFormValid    : false,
      editTicket         : new SupportTicketAddModel(),
      editorToolbar      : [
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link']
      ],
      editorSettings: {
        debug  : 'error',
        modules: {
          magicUrl: true
        }
      }
    }
  },
  computed: {
    messageError () {
      return (!this.editTicket.validateField('message') && this.editTicket.validationMessages.message && this.editTicket.validationMessages.message.length && [this.editTicket.validationMessages.message[0]]) || []
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    onSendButtonClick () {
      // this.editTicket.validate()
      // console.log(this.editTicket.validationMessages)
      this.$refs.ticketForm.validate()

      if (this.ticketFormValid && this.editTicket.validateField('message')) {
        this.loading = true
        this.editTicket.save({ ...this.editTicket.clone() })

        this.onCancelButtonClick()
      }
    },
    onCancelButtonClick () {
      if (this.$refs.ticketForm) this.$refs.ticketForm.resetValidation()
      this.editTicket = new SupportTicketAddModel()
      this.isVisible = false
      this.loading = false
    }

  }
}
</script>
