export default {
  Title : 'Waiter Staff | Waiter Staff | Waiter Staff',
  Button: {
    WaiterStaff: {
      Add: {
        Title: 'Add Waiter Staff',
        Color: 'primary',
        Icon : 'add_box'
      },
      Edit: {
        Color: 'grey lighten-0',
        Icon : 'edit'
      },
      Delete: {
        PopUp: {
          Title  : 'Delete waiter staff?',
          Content: 'Are you sure you want to permanently delete this waiter staff?'
        }
      }
    },
    RemoveOrder: {
      PopUp: {
        Title  : 'Remove order?',
        Content: 'Are you sure you want to remove this order from the waiter staff\'s shift?'
      }
    },
    Shift: {
      Open: {
        Title: 'Start shift',
        Color: 'green',
        Icon : 'fas fa-plus-circle',
        PopUp: {
          Title : 'Shift Start Amount',
          Hint  : 'The amount with which the shift starts.',
          Button: 'Start'
        }
      },
      Close: {
        Title: 'End shift',
        Color: 'red',
        Icon : 'fas fa-times-circle',
        PopUp: {
          Title              : 'End shift?',
          Content            : 'Are you sure you want to end this shift?',
          ContentChangeWaiter: 'The waiter has open tables assigned! You should close all tables or transfer them to another waiter in order to end his shift.'
        }
      }
    }
  },

  ListView: {
    NoData: {
      Title   : 'Create Waiter Staff',
      Subtitle: 'Add New Waiter Staff',
      Icon    : 'account_circle'
    },
    SelectWaiterStaff: {
      Title   : 'Select waiter staff',
      Subtitle: 'Select waiter staff from the list',
      Icon    : 'room_service'
    },
    Table: {
      Headers: {
        DateStarted: 'Date',
        Swifts     : 'Swifts',
        Payments   : 'Payments',
        Total      : 'Total',
        Actions    : ''
      }
    }
  },

  Common: {
    Orders      : 'Orders:',
    ShiftsOrders: 'Shift\'s Total: ',
    ShiftStart  : 'Start of shift',
    ShiftTotal  : 'Total amount of shift',
    InfoLabels  : {
      Icon     : 'account_circle',
      Telephone: 'Tel.:',
      Email    : 'Username:'
    }
  },

  WaiterStaffInfo: {
    Tab: {
      Details: '@:Common.Misc.Details',
      History: 'History'
    },
    Button: {
      AddOrder: {
        Title: 'Add order',
        Color: 'light-green'
      },
      ViewDailyShift: {
        Title: 'View daily shifts',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      }
    },
    Result: {
      NoOrders     : 'There are no shift payments yet',
      InactiveShift: {
        Icon        : 'account_circle',
        Title       : 'Inactive shift',
        SubtitleLink: 'Start',
        SubtitleText: 'waiter staff\'s shift'
      }
    }
  },

  PrintWaiterShift: {
    PrintReceipt: {
      StartShift  : 'Start of Shift',
      Waiter      : 'Waiter',
      Collection  : 'Collection',
      Canceled    : 'Canceled',
      Cash        : 'Cash',
      Card        : 'Card',
      WithoutStart: 'Without Start:',
      NotIncluded : 'Not Included',
      ByCard      : 'by card'
    },
    Notification: 'The shift was sent for printing!'
  },

  WaiterStaffAddDialog: {
    Toolbar: {
      Color: 'indigo',
      Icon : 'room_service',
      Add  : {
        Title   : 'Add Waiter Staff',
        Subtitle: 'Create New Waiter Staff'
      },
      Edit: {
        Title   : 'Edit Waiter Staff',
        Subtitle: 'Edit Waiter Staff Details'
      }
    },
    Field: {
      Firstname: {
        Label: 'First Name',
        Hint : 'Waiter Staff\'s First Name. eg. George, Jane, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'First Name must be between 2 and 32 characters'
        }
      },
      Lastname: {
        Label: 'Last Name',
        Hint : 'Waiter Staff\'s Last Name. eg. Smith, Doe, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'Last Name must be between 2 and 32 characters'
        }
      },
      Telephone: {
        Label: 'Telephone',
        Hint : 'Waiter Staff\'s telephone number eg. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Telephone number must be between 8 and 16 digits'
        }
      },
      Email: {
        Label: 'Username',
        Hint : 'Waiter Staff\'s username',
        Icon : 'email',
        Error: {
          Between: 'Username must be up to 96 characters long',
          Valid  : 'Username must be valid'
        }
      },
      Notes: {
        Label: 'Notes',
        Hint : 'Waiter Staff\'s additional info',
        Icon : 'speaker_notes'
      }
    }
  },

  WaiterStaffDayShiftsDialog: {
    Header: {
      Icon : 'room_service',
      Title: 'Waiter Staff\'s Daily Shifts',
      Shift: 'Shift'
    },
    Result: {
      NoOrders: {
        Icon    : 'shopping_cart',
        Color   : 'grey lighten-2',
        Title   : 'There are no payments',
        Subtitle: 'There are no payments in the waiter staff\'s shift'
      }
    }
  },

  SelectWaiterStaffDialog: {
    Toolbar: {
      Icon    : 'room_service',
      Subtitle: 'Waiter Staff Selection'
    },
    Result: {
      NoWaiterStaff: {
        Title   : 'There are no Waiter Staff',
        Subtitle: {
          Please     : 'Please',
          Open       : 'open',
          ShiftOr    : 'shift or',
          Create     : 'create',
          WaiterStaff: 'waiter staff'
        }
      }
    }
  },

  PaymentChangeWaiterDialog: {
    Toolbar: {
      Icon    : 'transfer_within_a_station',
      Title   : 'Transfer Payment',
      Subtitle: 'Transfer payment to another waiter shift.'
    }
  },

  StaffAddDialog: {
    Add: {
      Title   : 'Add Waiter Staff',
      Subtitle: 'Create New Waiter Staff',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },
    Edit: {
      Title   : 'Edit Waiter Staff',
      Subtitle: 'Edit Waiter Staff Details',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },

    Field: {
      Firstname: {
        Label: 'First Name',
        Hint : 'Staff\'s First Name. eg. George, Jane, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'First Name must be between 2 and 32 characters.'
        }
      },
      Lastname: {
        Label: 'Last Name',
        Hint : 'Staff\'s Last Name. eg. Smith, Doe, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'Last Name must be between 2 and 32 characters.'
        }
      },
      Telephone: {
        Label: 'Telephone',
        Hint : 'Staff\'s Mobile or Landline. eg. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Telephone number must be between 8 and 16 digits'
        }
      },
      Email: {
        Label: 'Username',
        Hint : 'Username will be automatically completed',
        Icon : 'email',
        Error: {
          Between: 'Username must be up to 96 characters long',
          Valid  : 'Username must be valid'
        }
      },
      Pin: {
        Label: 'PIN',
        Hint : 'Staff\'s PIN number eg. 1234',
        Icon : 'fiber_pin',
        Error: {
          FixedNumber: 'PIN must be 4 digits long'
        }
      },
      TableAreas: {
        Label: 'Table Areas',
        Hint : 'Select one or more table areas',
        Icon : 'crop_square',
        All  : 'All',
        Error: {
          Required: 'You must select at least one table area.'
        }
      },
      Locale: {
        Label: 'System Language',
        Hint : 'Select the language you want the system to be displayed',
        Icon : 'language'
      },
      Status: {
        Title: 'Status'
      }
    }
  }
}
