import BaseCollection         from '../../../lib/data/Collections/BaseCollection'
import SupportDepartmentModel from '../../Models/support-center/SupportDepartmentModel'
import SocketCommand          from '@/api/SocketCommand'

export default class SupportDepartmentsCollection extends BaseCollection {
  constructor (items = []) {
    super(items, SupportDepartmentModel)

    this.fetchCommand = SocketCommand.SupportCenter.Ticket.Lists
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */

  onFetch (data) {
    if (data.status) {
      super.onFetch(data.data.departments)
      if (data.data.hasOwnProperty('services')) window.Vue.$DataStore.support.services.clear().add(data.data.services)
      if (data.data.hasOwnProperty('priorities')) window.Vue.$DataStore.support.priorities.clear().add(data.data.priorities)
      if (data.data.hasOwnProperty('priorities')) window.Vue.$DataStore.support.statuses.clear().add(data.data.statuses)
    }
  }
}
