import moment from 'moment'

export default {
  name : 'TimeAgoMoment',
  props: {
    since: {
      required: true
    },
    mini: {
      type   : Boolean,
      default: false
    },
    capitalize: {
      type   : Boolean,
      default: true
    },
    autoUpdate: {
      type   : Number,
      default: 0
    },
    locale: {
      type   : String,
      default: function () { return this.$i18n.locale }
    }
  },
  data () {
    return {
      timeNow: new Date().getTime()
    }
  },
  computed: {
    timeAgo () {
      moment.relativeTimeThreshold('ss', 60)
      moment.updateLocale('en', { relativeTime: { s: '%d sec' } })
      moment.updateLocale('el', { relativeTime: { s: '%d δευτ.' } })
      moment.locale(this.$i18n.locale)

      let h = moment.duration(moment(this.since).diff(moment(this.timeNow))).humanize(false)

      if (this.mini) {
        const s = h.split(' ')
        const retVal = s.length >= 1 ? s[0] + s[1].charAt(0) : ''

        h = retVal
      }

      return this.capitalize ? this.capitalizeString(h) : h
    }
  },
  mounted () {
    if (this.autoUpdate) this.update()
  },
  render (h) {
    return h('time', { attrs: { datetime: this.now } }, this.timeAgo)
  },
  watch  : {},
  methods: {
    capitalizeString (str, locale = this.locale) {
      return str ? str.split(' ').map(([firstChar, ...restChars]) => firstChar ? firstChar.toLocaleUpperCase(locale) + restChars.join('') : '').join(' ') : ''
    },
    update () {
      const period = this.autoUpdate * 1000
      this.interval = setInterval(() => {
        this.timeNow = new Date().getTime()
      }, period)
    },
    stopUpdate () {
      clearInterval(this.interval)
      this.interval = null
    }
  },
  beforeDestroy () {
    this.stopUpdate()
  }
}
