<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      max-width="314"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="px-1 pin-toolbar"
          height="80"
          max-height="80"
        >
          <v-avatar color="orange">
            <v-icon dark>
              lock
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.PinManager.Enabled.Title') }}
            </div>

            <div class="text-caption grey--text" />
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <pin-pad
                  v-if="isVisible"
                  v-model="pin"
                  :error="!!error"
                  @numpad:enter="onOkButtonClick"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <div class="text-center red--text text-subtitle-1">
            <span v-show="error">{{ error }}</span>
          </div>

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Auth           from '@/mixins/auth'
import { isFunction } from '@/lib/data/Validations/utils/Utils'
import PinPad         from '@/components/common/PinPad.vue'

export default {
  name      : 'ManagerPinDialog',
  components: { PinPad },
  mixins    : [Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    data: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      pin  : '',
      error: ''
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible () {
      this.initData()
    },

    pin () {
      this.error = ''
    }
  },
  methods: {
    onOkButtonClick () {
      const {
        action,
        payload,
        callback
      } = this.data

      if (this.pin && this.pin.match(/^\d{4}$/i) !== null) {
        if (this.pinActionsManagerPin === this.pin) {
          if (action) {
            this.$bus.$emit('manager-pin:success', payload)
            if (isFunction(callback)) callback(payload)
          } else {
            this.$bus.$emit(`manager-pin:${ action }:success`, payload)
            if (isFunction(callback)) callback(payload, action)
          }
          this.onCancelButtonClick()
        } else {
          this.error = this.$t('Authentication.Field.Result.WrongPin')
        }
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.initData()
    },

    initData () {
      this.pin = ''
      this.error = ''
    }
  }
}
</script>

<style scoped>
/deep/ .pin-toolbar .v-toolbar__content {
  padding : 0 6px;
}
</style>
