<template>
  <v-container
    fluid
    style="max-width: 580px;"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-icon
          color="error"
          size="162"
        >
          {{ $t('Account.DomainLocked.Icon') }}
        </v-icon>

        <h2 class="error--text text--darken- mt-3">
          {{ $t('Account.DomainLocked.Title') }}
        </h2>
      </v-col>
    </v-row>

    <v-row
      v-if="dateExpired && daysExpired"
      class="justify-center text-center"
    >
      <v-col cols="12">
        <v-alert
          type="error"
          colored-border
          border="bottom"
          class="font-weight-medium"
        >
          {{
            $t('Account.DomainLocked.Subscription', {
              days: daysExpired,
              date: dateExpired
            })
          }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="justify-center text-center">
      <v-col cols="12">
        <h2 class="grey--text text--lighten-1 mb-3">
          {{ domainReseller && domainReseller.Name ? domainReseller.Name : 'GETFOOD PRO' }}
        </h2>

        <h4 class="grey--text text--lighten-1 mb-3">
          {{ $t('Account.DomainLocked.SubTitle') }}
        </h4>

        <v-btn
          class="pa-2 mx-1 text-lowercase"
          text
        >
          <v-icon
            color="light-green"
            class="mr-1"
          >
            access_time
          </v-icon>
          <span>10:00 - 18:00</span>
        </v-btn>

        <v-btn
          class="pa-2 mx-1 text-lowercase"
          text
          :href="domainReseller && domainReseller.Phone ? `tel:${domainReseller.Phone}` : 'tel:+302152152324'"
        >
          <v-icon
            color="light-green"
            class="mr-1"
          >
            phone
          </v-icon>

          <span v-if="domainReseller && domainReseller.Phone">{{ domainReseller.Phone }}</span>
          <span v-else>+30 215 215 23 24</span>
        </v-btn>

        <v-btn
          class="pa-2 mx-1 text-lowercase"
          text
          :href="`mailto: ${domainReseller && domainReseller.Email ? domainReseller.Email : 'support@getfoodpro.gr'}?cc=${domainReseller && domainReseller.Email ? 'support@getfoodpro.gr' : ''}&subject=GFP SUPPORT REQUEST&body=
${$t('Dashboard.Help.SupportEmail.Name')}: ${posUser && posUser.staff_name} %0D%0A
${$t('Dashboard.Help.SupportEmail.Store')}: ${appConfig && appConfig.LOCATION_DATA.Name} %0D%0A
${$t('Dashboard.Help.SupportEmail.Url')}: ${appConfig && appConfig.LOCATION_DATA.SiteUrl} %0D%0A
${$t('Dashboard.Help.SupportEmail.Email')}: ${posUser && posUser.staff_email} %0D%0A %0D%0A
${$t('Dashboard.Help.SupportEmail.Message')} %0D%0A %0D%0A
          `"
        >
          <v-icon
            color="blue"
            class="mr-1"
          >
            email
          </v-icon>
          <span v-if="domainReseller && domainReseller.Email">{{ domainReseller.Email }}</span>
          <span v-else>support@getfoodpro.gr</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-if="(userIsOwner || userInAdminGroup)"
      class="justify-center text-center"
    >
      <v-col cols="12">
        <v-btn
          :disabled="isLoading || extendedPeriodUsed"
          class="mt-6"
          color="primary"
          outlined
          large
          @click="onRequestExtendClick"
        >
          {{ $t('Account.DomainLocked.Extend', {hours: extendedPeriodHours}) }}
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        class="warning--text text-caption"
      >
        {{ $t('Account.DomainLocked.ExtendUsed', {hours: extendedPeriodHours}) }}
      </v-col>
    </v-row>

    <loading-modal :visible="isLoading" />
  </v-container>
</template>

<script>
import AppData      from '@/mixins/appdata'
import Auth         from '@/mixins/auth'
import LoadingModal from '@/components/common/LoadingModal.vue'

export default {
  name: 'DomainLocked',

  components: {
    LoadingModal
  },

  mixins: [Auth, AppData],

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    daysExpired () {
      return Math.abs(this.appConfig?.LOCATION_DATA?.SubscriptionExpiredDaysIn || 0)
    },

    dateExpired () {
      return this.appConfig?.LOCATION_DATA?.SubscriptionExpiredAt || ''
    },

    extendedPeriodHours () {
      return this.appConfig?.LOCATION_DATA?.ExtendedPeriodHours || 72
    },

    extendedPeriodUsed () {
      return !!this.appConfig?.LOCATION_DATA?.ExtendedPeriodUsed ?? false
    }
  },

  watch: {},

  created () {
    this.$bus.$on(window.SocketCommand.Account.DomainExtendPeriod, this.onRequestExtendResult)
  },

  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Account.DomainExtendPeriod, this.onRequestExtendResult)
  },

  methods: {
    onRequestExtendClick () {
      if (this.extendedPeriodUsed) return

      this.isLoading = true
      window.callAS(window.SocketCommand.Account.DomainExtendPeriod, {})
    },

    onRequestExtendResult (data) {
      if (data?.status === 'success') {
        location && location.reload()
      } else {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
