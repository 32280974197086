/**
 * Enum for pin manager.
 * @readonly
 * @enum {String} PinManagerEnum
 */

const PinManagerEnum = {
  PosDiscount                   : 'PosDiscount',
  PosCancel                     : 'PosCancel',
  PosDelete                     : 'PosDelete',
  PosEdit                       : 'PosEdit',
  PosPayments                   : 'PosPayments',
  PosSetCustomPrice             : 'PosSetCustomPrice',
  PosChangeOrderTimeDuringAccept: 'PosChangeOrderTimeDuringAccept',
  PosChangeOrderTimeAfterAccept : 'PosChangeOrderTimeAfterAccept',

  TableDiscount      : 'TableDiscount',
  TableCancel        : 'TableCancel',
  TableSetCustomPrice: 'TableSetCustomPrice',

  OpenWaiterShift  : 'OpenWaiterShift',
  CloseWaiterShift : 'CloseWaiterShift',
  MovePayment      : 'MovePayment',
  ManageAllTables  : 'ManageAllTables',
  ChangeTableWaiter: 'ChangeTableWaiter'
}

export default PinManagerEnum
