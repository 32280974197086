<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Common.SelectDateRangeDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Common.SelectDateRangeDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>
          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Common.SelectDateRangeDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Common.SelectDateRangeDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="dataForm"
            v-model="dataFormValid"
            @submit.prevent
          >
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="startDateVisible"
                    :close-on-content-click="false"
                    left
                    max-width="290px"
                    min-width="140px"
                    offset-y
                    style="width: 100%"
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="startDateFmt"
                        :rules="validationRules.dataForm.startDate"
                        :label="$t('Common.Date.PeriodFrom')"
                        clearable
                        height="36px"
                        hide-details
                        prepend-inner-icon="event"
                        readonly
                        filled
                        v-on="on"
                        @blur="onStartDateBlur"
                      />
                    </template>

                    <v-date-picker
                      v-model="startDate"
                      :allowed-dates="allowedStartDates"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      first-day-of-week="1"
                      scrollable
                      @input="onStartDateInput"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="endDateVisible"
                    :close-on-content-click="false"
                    left
                    max-width="290px"
                    min-width="140px"
                    offset-y
                    style="width: 100%"
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="endDateFmt"
                        :rules="validationRules.dataForm.endDate"
                        :label="$t('Common.Date.PeriodTo')"
                        clearable
                        height="36px"
                        hide-details
                        prepend-inner-icon="event"
                        readonly
                        filled
                        v-on="on"
                        @blur="onEndDateBlur"
                      />
                    </template>

                    <v-date-picker
                      v-model="endDate"
                      :allowed-dates="allowedEndDates"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      first-day-of-week="1"
                      scrollable
                      @input="onEndDateInput"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            :disabled="isLoading || !dataFormValid"
            :loading="isLoading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name      : 'SelectDateRangeDialog',
  components: {},
  mixins    : [],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading       : false,
      dataFormValid   : false,
      startDateVisible: false,
      endDateVisible  : false,
      datePeriod      : '',
      startDate       : '',
      startDateFmt    : '',
      endDate         : '',
      endDateFmt      : '',
      validationRules : {
        dataForm: {
          startDate: [
            (v) => !!v || ''
          ],
          endDate: [
            (v) => !!v || ''
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  watch: {
    startDate (newVal) {
      if (this.endDate && newVal) {
        this.datePeriod = newVal + ' 00:00_' + this.endDate + ' 00:00'
      } else {
        this.datePeriod = ''
      }
    },

    endDate (newVal) {
      if (this.startDate && newVal) {
        this.datePeriod = this.startDate + ' 00:00_' + newVal + ' 00:00'
      } else {
        this.datePeriod = ''
      }
    },

    isVisible (newVal) {
      if (newVal) {
        this.initDates()

        this.$nextTick(() => {
          if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
        })
      }
    }
  },

  created () {
    // console.log('1. created');
  },

  mounted () {
    // console.log('2. mounted');
  },

  updated () {
    // console.log('3. updated');
  },

  beforeDestroy () {
    // console.log('4. destroyed');
  },

  methods: {
    initDates (dateStartStr, dateEndStr) {
      this.datePeriod = ''
      this.startDateFmt = dateStartStr ? this.formatDate(dateStartStr) ?? '' : ''
      this.endDateFmt = dateEndStr ? this.formatDate(dateEndStr) ?? '' : ''
      this.startDate = dateStartStr ? this.parseDate(this.startDateFmt) : ''
      this.endDate = dateEndStr ? this.parseDate(this.endDateFmt) : ''
    },

    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },

    parseDate (date) {
      if (!date) {
        return null
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    },

    allowedStartDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.endDate ? new Date(this.endDate).getTime() : null
      return maxDate ? (currDate <= maxDate) : true
    },

    allowedEndDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.startDate ? new Date(this.startDate).getTime() : null
      return maxDate ? (currDate >= maxDate) : true
    },

    todayDateStr () {
      return this.getLocalISOString(new Date()).slice(0, 10)
    },

    getLocalISOString (date) {
      const offset = date.getTimezoneOffset()
      const offsetAbs = Math.abs(offset)
      const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString()
      return `${ isoString.slice(0, -1) }${ offset > 0 ? '-' : '+' }${ String(Math.floor(offsetAbs / 60)).padStart(2, '0') }:${ String(offsetAbs % 60).padStart(2, '0') }`
    },

    onStartDateBlur () {
      this.startDate = this.parseDate(this.startDateFmt)
    },

    onEndDateBlur () {
      this.endDate = this.parseDate(this.endDateFmt)
    },

    onStartDateInput (event) {
      this.startDateFmt = this.formatDate(event)
      this.startDateVisible = false
    },

    onEndDateInput (event) {
      this.endDateFmt = this.formatDate(event)
      this.endDateVisible = false
    },

    onSaveButtonClick () {
      this.$refs.dataForm.validate()

      if (this.dataFormValid) {
        this.isLoading = true
        this.$emit('date-range', this.datePeriod)
        this.onCancelButtonClick()
      }
    },

    onCancelButtonClick () {
      if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      this.initDates()
      this.isVisible = false
      this.isLoading = false
    }

  }
}
</script>
