export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [],
  directives: {},
  watch     : {},
  methods   : {
    convertMinutesToDisplayString (minutes, padZero = false, useSuffix = true) {
      let retVal = ''
      minutes = parseInt(minutes) || 0
      let hour = Math.floor(minutes / 60)
      const minute = minutes % 60
      const day = Math.floor(hour / 24)
      hour = hour % 24

      retVal += day > 0 ? `${ padZero ? this.padZero(String(day), 2) : day }${ useSuffix ? this.$tc('Order.Type.Time.Day.Suffix', day) : ' ' + this.$tc('Order.Type.Time.Day.Title', day) }` : ''
      retVal += day > 0 && hour > 0 ? useSuffix ? ' ' : ` ${ this.$t('Common.Misc.And') } ` : ''
      retVal += hour > 0 ? `${ padZero ? this.padZero(String(hour), 2) : hour }${ useSuffix ? this.$tc('Order.Type.Time.Hour.Suffix', hour) : ' ' + this.$tc('Order.Type.Time.Hour.Title', hour) }` : ''
      retVal += hour > 0 && minute > 0 ? useSuffix ? ' ' : ` ${ this.$t('Common.Misc.And') } ` : ''
      retVal += minute > 0 ? `${ padZero ? this.padZero(String(minute), 2) : minute }${ useSuffix ? this.$tc('Order.Type.Time.Minute.Suffix', minute) : ' ' + this.$tc('Order.Type.Time.Minute.Title', minute) }` : ''

      return retVal
    },

    convertMinutesToTimeString (minutes) {
      minutes = parseInt(minutes) || 0
      const hour = Math.floor(minutes / 60) % 24
      const minute = minutes % 60

      return `${ this.padZero(String(hour), 2) }:${ this.padZero(String(minute), 2) }`
    },

    padZero (str, len) {
      len = len || 2
      const zeros = new Array(len).join('0')
      return (zeros + str).slice(-len)
    }
  },
  computed: {
    deliveryTimeRange () {
      return this.orderTypeDefaultTimes.delivery_time_range.map(t => parseInt(t)) || this.defaultTimeRange
    },
    collectionTimeRange () {
      return this.orderTypeDefaultTimes.collection_time_range.map(t => parseInt(t)) || this.defaultTimeRange
    },
    availableTimeRanges () {
      return this.orderTypeDefaultTimes.order_time_ranges.map(t => parseInt(t)) || this.defaultTimeRange
    },
    deliveryTimeRangeLabels () {
      return this.deliveryTimeRange.map(t => {
        return {
          id   : t,
          title: this.convertMinutesToDisplayString(t, false, false)
        }
      })
    },
    collectionTimeRangeLabels () {
      return this.collectionTimeRange.map(t => {
        return {
          id   : t,
          title: this.convertMinutesToDisplayString(t, false, false)
        }
      })
    },
    availableTimeRangeLabels () {
      return this.availableTimeRanges.map(t => {
        return {
          id   : t,
          title: this.convertMinutesToDisplayString(t, false, false)
        }
      })
    },
    defaultTimeRange () {
      return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  }

}
