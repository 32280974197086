<template>
  <v-container
    ref="settingsContainer"
    class="pa-2 pa-md-4"
    fluid
  >
    <v-row :class="{'flex-column': $vuetify.breakpoint.smAndDown}">
      <v-col class="shrink">
        <v-card v-if="$vuetify.breakpoint.mdAndUp">
          <v-toolbar
            flat
            height="80"
            :color="isDarkMode ? 'grey darken-4' : 'grey lighten-5'"
          >
            <v-avatar
              :color="$t('Settings.Settings.Toolbar.Color')"
            >
              <v-icon dark>
                {{ $t('Settings.Settings.Toolbar.Icon') }}
              </v-icon>
            </v-avatar>

            <v-toolbar-title class="pl-3">
              <div class="body-3">
                {{ $t('Settings.Settings.Toolbar.Title') }}
              </div>
              <div class="text-caption">
                {{ $t('Settings.Settings.Toolbar.Subtitle') }}
              </div>
            </v-toolbar-title>

            <v-spacer />
          </v-toolbar>

          <v-divider />

          <v-card-text
            ref="settingsMenu"
            class="pa-0"
          >
            <div class="pa-2 pb-">
              <v-text-field
                v-model="searchSettings"
                dense
                solo
                flat
                hide-details
                clearable
                :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                :prepend-inner-icon="$t('Common.Search.Icon')"
                :label="$t('Common.Search.Label')"
              />
            </div>

            <v-divider class="mx-2" />

            <v-list
              ref="settingsList"
              style="overflow-y: auto;"
              nav
            >
              <template v-for="(item, i) in viewDataFiltered">
                <v-list-item
                  v-if="checkPermissions(item)"
                  :key="i"
                  :to="gotoSettingPage(item)"
                  active-class="primary--text grey lighten-4"
                  style="min-height: 40px; height: 40px; margin-bottom: 4px;"
                >
                  <v-list-item-action>
                    <v-icon>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>

          <div
            v-if="posUserHasComponentPermission('SystemSettingsCatalog', 'MANAGE') && multipleLocations.length"
            style="position: absolute; bottom: 0; width: calc(100% - 16px);"
            class="ma-2"
          >
            <v-btn
              block
              class="elevation-0"
              color="primary"
              large
              @click="catalogCopyDialogVisible = !catalogCopyDialogVisible"
            >
              {{ $t('Settings.Catalog.Copy.Button.Title') }}
            </v-btn>
          </div>
        </v-card>

        <v-menu
          v-else
          bottom
          max-height="400"
          transition="slide-y-transition"
        >
          <template #activator="{on}">
            <v-btn
              block
              color="primary ma-0"
              dark
              v-on="on"
            >
              {{ $t('Settings.Settings.ListView.Title').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </template>

          <v-list>
            <template v-for="(item, i) in viewDataFiltered">
              <v-list-item
                v-if="checkPermissions(item)"
                :key="i"
                :to="{name: item.route.name}"
                active-class="primary--text grey lighten-4"
                style="min-height: 40px; height: 40px; margin-bottom: 4px;"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>

      <v-col v-if="$route.name !== 'SystemSettings'">
        <router-view />
      </v-col>

      <v-col
        v-else
        class="pa-0"
      >
        <v-container
          fluid
          class="pa-0 fill-height align-center justify-center"
        >
          <v-row class="fill-height align-center justify-center">
            <v-col cols="12">
              <div class="text-center grey--text">
                <v-icon
                  class="mb-4"
                  color="grey lighten-2"
                  size="128"
                >
                  settings
                </v-icon>

                <div
                  class="text-h6 mb-2"
                  style="height: auto;"
                >
                  {{ $t('Settings.Settings.ListView.Title') }}
                </div>

                <div
                  class="text-subtitle-1"
                  style="height: auto;"
                >
                  {{ $t('Settings.Settings.ListView.Subtitle') }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <catalog-copy-dialog
      v-if="posUserHasComponentPermission('SystemSettingsCatalog', 'MANAGE') && multipleLocations.length"
      :visible.sync="catalogCopyDialogVisible"
    />
  </v-container>
</template>

<script>
import isElectron        from '@/electron/isElectron'
import AppData           from '@/mixins/appdata'
import Auth              from '@/mixins/auth'
import Resizable         from '@/mixins/resizable'
import CatalogCopyDialog from '@/components/settings/domains/CatalogCopyDialog'
import greekUtils        from '@/lib/greek-utils'

export default {
  components: {
    CatalogCopyDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable],
  data () {
    return {
      searchSettings          : '',
      catalogCopyDialogVisible: false,
      viewData                : [
        {
          title   : this.$t('Settings.Settings.Header.Store.Title'),
          icon    : this.$t('Settings.Settings.Header.Store.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Store.Keywords'),
          route   : {
            name: 'SystemSettingsRestaurant',
            path: '/system-settings/restaurant'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.WorkHour.Title'),
          icon    : this.$t('Settings.Settings.Header.WorkHour.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.WorkHour.Keywords'),
          route   : {
            name: 'SystemSettingsWorkingHours',
            path: '/system-settings/working-hours'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Delivery.Title'),
          icon    : this.$t('Settings.Settings.Header.Delivery.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Delivery.Keywords'),
          route   : {
            name: 'SystemSettingsDelivery',
            path: '/system-settings/delivery'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Order.Title'),
          icon    : this.$t('Settings.Settings.Header.Order.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Order.Keywords'),
          route   : {
            name: 'SystemSettingsOrders',
            path: '/system-settings/orders'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Tables.Title'),
          icon    : this.$t('Settings.Settings.Header.Tables.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Tables.Keywords'),
          route   : {
            name: 'SystemSettingsTables',
            path: '/system-settings/tables'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.PinManager.Title'),
          icon    : this.$t('Settings.Settings.Header.PinManager.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.PinManager.Keywords'),
          route   : {
            name: 'SystemSettingsPinManager',
            path: '/system-settings/pin-manager'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Reservation.Title'),
          icon    : this.$t('Settings.Settings.Header.Reservation.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Reservation.Keywords'),
          route   : {
            name: 'SystemSettingsReservations',
            path: '/system-settings/reservations'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Payment.Title'),
          icon    : this.$t('Settings.Settings.Header.Payment.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Payment.Keywords'),
          route   : {
            name: 'SystemSettingsPayments',
            path: '/system-settings/payments'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Website.Title'),
          icon    : this.$t('Settings.Settings.Header.Website.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Website.Keywords'),
          route   : {
            name: 'SystemSettingsWebSite',
            path: '/system-settings/website'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Regional.Title'),
          icon    : this.$t('Settings.Settings.Header.Regional.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Regional.Keywords'),
          route   : {
            name: 'SystemSettingsRegional',
            path: '/system-settings/regional'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Documents.Title'),
          icon    : this.$t('Settings.Settings.Header.Documents.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Documents.Keywords'),
          route   : {
            name: 'SystemSettingsDocuments',
            path: '/system-settings/documents'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Notification.Title'),
          icon    : this.$t('Settings.Settings.Header.Notification.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Notification.Keywords'),
          route   : {
            name: 'SystemSettingsNotifications',
            path: '/system-settings/notifications'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Printer.Title'),
          icon    : this.$t('Settings.Settings.Header.Printer.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Printer.Keywords'),
          route   : {
            name: 'SystemSettingsPrinters',
            path: '/system-settings/printers'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Display.Title'),
          icon    : this.$t('Settings.Settings.Header.Display.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Display.Keywords'),
          route   : {
            name: 'SystemSettingsDisplays',
            path: '/system-settings/displays'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Email.Title'),
          icon    : this.$t('Settings.Settings.Header.Email.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Email.Keywords'),
          route   : {
            name: 'SystemSettingsEmail',
            path: '/system-settings/email'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Permission.Title'),
          icon    : this.$t('Settings.Settings.Header.Permission.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Permission.Keywords'),
          route   : {
            name: 'SystemSettingsPermissions',
            path: '/system-settings/permissions'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.Domain.Title'),
          icon    : this.$t('Settings.Settings.Header.Domain.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.Domain.Keywords'),
          route   : {
            name: 'SystemSettingsDomains',
            path: '/system-settings/domains'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.OnlineUsers.Title'),
          icon    : this.$t('Settings.Settings.Header.OnlineUsers.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.OnlineUsers.Keywords'),
          route   : {
            name: 'SystemSettingsOnlineUsers',
            path: '/system-settings/online-users'
          }
        },
        {
          title   : this.$t('Settings.Settings.Header.SystemUsers.Title'),
          icon    : this.$t('Settings.Settings.Header.SystemUsers.Icon'),
          keywords: this.getAllLocaleTranslations('Settings.Settings.Header.SystemUsers.Keywords'),
          route   : {
            name: 'SystemSettingsSystemUsers',
            path: '/system-settings/system-users'
          }
        }
      ]
    }
  },
  computed: {
    viewDataFiltered () {
      const searchTerms = greekUtils.toGreeklish(this.searchSettings?.toLocaleLowerCase(this.$i18n.locale)?.trim() || '')
      return searchTerms ? this.viewData.filter(item => item.keywords.some(keyword => greekUtils.toGreeklish(keyword.toLocaleLowerCase(this.$i18n.locale)).includes(searchTerms))) : this.viewData
    },

    isDesktop () {
      return isElectron()
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    getAllLocaleTranslations (key) {
      let merged = []
      this.$i18n.languages.forEach(lang => {
        merged = merged.concat(this.$t(key, lang.locale))
      })

      return merged
    },
    gotoSettingPage (item) {
      if (item?.route?.name && item?.route?.name !== this.$route.name) {
        return { name: item.route.name }
      }

      return { name: 'SystemSettings' }
    },

    checkPermissions (item) {
      const hasRoutePermission = this.posUserHasComponentPermission(item.route.name, 'ACCESS')
      if (item.route.name === 'SystemSettingsWebSite') return hasRoutePermission && (this.posUserHasComponentPermission('Online', 'ACCESS') || this.posUserHasComponentPermission('OnlineCatalog', 'ACCESS'))
      if (item.route.name === 'SystemSettingsEmail') return hasRoutePermission && this.userIsSuperExtraAdmin
      if (item.route.name === 'SystemSettingsPermissions') return hasRoutePermission && this.userIsSuperExtraAdmin
      if (item.route.name === 'SystemSettingsDomains') return hasRoutePermission && this.userInAdminGroup
      if (item.route.name === 'SystemSettingsOnlineUsers') return this.posUserHasComponentPermission('SystemSettingsDomains', 'ACCESS') && this.userInAdminGroup
      if (item.route.name === 'SystemSettingsSystemUsers') return this.posUserHasComponentPermission('SystemSettingsDomains', 'ACCESS') && this.userInAdminGroup

      return hasRoutePermission
    },

    onResize () {
      const settingsContainer = this.$refs.settingsContainer || null
      const settingsMenu = this.$refs.settingsMenu || null
      const settingsList = this.$refs.settingsList?.$el || null
      const settingsWindow = document.getElementById('settingsWindow')

      const settingsContainerPadding = settingsContainer ? parseInt(window.getComputedStyle(settingsContainer, null).getPropertyValue('padding-top')) * 2 : 0
      const appHeaderHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const appFooterHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0

      const toolbarHeight = settingsContainer ? settingsContainer.getElementsByClassName('settings-window-toolbar')[0] ? settingsContainer.getElementsByClassName('settings-window-toolbar')[0].offsetHeight : 96 : 96
      const actionsHeight = settingsContainer ? settingsContainer.getElementsByClassName('settings-window-actions')[0] ? settingsContainer.getElementsByClassName('settings-window-actions')[0].offsetHeight : 118 : 118

      if (settingsMenu) settingsMenu.style.height = (innerHeight - appHeaderHeight - appFooterHeight - settingsContainerPadding - 80) + 'px'

      if (settingsList) {
        if (this.posUserHasComponentPermission('SystemSettingsCatalog', 'MANAGE') && this.multipleLocations.length) {
          settingsList.style.height = (innerHeight - appHeaderHeight - appFooterHeight - settingsContainerPadding - 140 - 54) + 'px'
        } else {
          settingsList.style.height = (innerHeight - appHeaderHeight - appFooterHeight - settingsContainerPadding - 85 - 54) + 'px'
        }
      }
      if (settingsWindow) settingsWindow.style.height = (innerHeight - appHeaderHeight - appFooterHeight - settingsContainerPadding - toolbarHeight - actionsHeight) + 'px'
    }
  }
}
</script>

<style scoped>

</style>
