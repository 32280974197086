/* eslint-disable no-console */

import Pusher        from 'pusher-js'
import SocketData    from './SocketData'
import SocketCommand from './SocketCommand'

let pusher = null

export const Socket = {
  pusher: () => {
    return pusher
  },

  appId: () => {
    return window?.appConfig?.WS?.id
  },

  appKey: () => {
    return window?.appConfig?.WS?.key
  },

  clientChannel: () => {
    return window?.appConfig?.WS?.channel
  },

  clientEvent: () => {
    return window?.appConfig?.WS?.event
  },

  serverHost: () => {
    return window?.appConfig?.WS?.host
  },

  serverPort: () => {
    return window?.appConfig?.WS?.port
  },

  serverAuthEndpoint: () => {
    return window?.appConfig?.WS?.authEndpoint
  },

  disconnect: () => {
    if (pusher) {
      pusher.disconnect()
      pusher = null
    }
  },

  connect: () => {
    Socket.disconnect()
    pusher = new Pusher(Socket.appKey(), {
      wsHost      : Socket.serverHost(),
      wssHost     : Socket.serverHost(),
      wsPort      : Socket.serverPort(),
      wssPort     : Socket.serverPort(),
      // activityTimeout: 30000,
      // pongTimeout    : 10000,
      disableStats: true,
      authEndpoint: Socket.serverAuthEndpoint(),
      auth        : {
        headers: {
          Authorization     : window.authorizationTokenBearer,
          'X-App-ID'        : Socket.appId(),
          'X-Requested-With': 'XMLHttpRequest'
        }
      },
      enabledTransports: ['ws', 'flash'],
      forceTLS         : true
    })
    Socket.subscribeToAllChannels()
    /**
     * There’s an extra state_change utility event that fires for all state changes
     */
    pusher.connection.bind('state_change', states => {
      console.log(`%cPUSHER.STATE_CHANGE:: %c${ JSON.parse(JSON.stringify(states.current)) }`, 'font-weight: bold; color: #8E24AA', 'color: #8E24AA')
      window.Vue.$bus.$emit(SocketCommand.Pusher.IsOnline, { status: false })
      if (states.current === 'failed' || states.current === 'unavailable') {
        Socket.disconnect()
        window.Vue.$bus.$emit('app-logout')
      }
    })
    /**
     * This will give you the time until the next connection attempt
     */
    pusher.connection.bind('connecting_in', (delay) => {
      console.log(`%cPUSHER.CONNECTING_IN:: %c${ JSON.parse(JSON.stringify(delay)) }`, 'font-weight: bold; color: #8E24AA', 'color: #8E24AA')
      window.Vue.$bus.$emit(SocketCommand.Pusher.IsOnline, { status: false })
    })
    /**
     * On connected
     */
    pusher.connection.bind('connected', () => {
      console.log('%cPUSHER:: %cCONNECTED', 'font-weight: bold; color: #8E24AA', 'color: #8E24AA')
    })
    /**
     * On disconnected
     */
    pusher.connection.bind('disconnected', () => {
      console.log('%cPUSHER:: %cDISCONNECTED', 'font-weight: bold; color: #8E24AA', 'color: #8E24AA')
      window.Vue.$bus.$emit(SocketCommand.Pusher.IsOnline, { status: false })
    })

    pusher.connection.bind_global((event, data) => {
      if (event === 'message' && data.event === 'pusher:pong') {
        SocketData.set(data.event, data)
      }
    })
  },

  subscribeToAllChannels: () => {
    ['disconnection', 'connection', 'subscribed', 'ping', 'pong', Socket.clientChannel()].forEach(channelName => pusher.subscribe(channelName))

    /**
     * Bind channel to event
     */
    pusher.channel(Socket.clientChannel()).bind('pusher:subscription_succeeded', () => {
      console.log('%cPUSHER:: %cSUBSCRIPTION_SUCCEEDED', 'font-weight: bold; color: #8E24AA', 'color: #8E24AA')
      pusher.channel(Socket.clientChannel()).trigger('client-' + Socket.clientEvent(), {
        command: 'CLIENT_ID',
        uid    : window.Vue.posUser.staff_email,
        auth   : window.authorizationTokenBearer,
        token  : window.authorizationToken,
        name   : window.staffName
      })
    })

    pusher.channel(Socket.clientChannel()).bind('pusher:subscription_error', (status) => {
      console.log('%cPUSHER:: %cSUBSCRIPTION_ERROR', 'font-weight: bold; color: #8E24AA', 'color: #8E24AA')
      Socket.disconnect()
      window.Vue.$bus.$emit(SocketCommand.Pusher.IsOnline, { status: false })
      window.Vue.$bus.$emit('app-logout')
    })

    pusher.channel(Socket.clientChannel()).bind(Socket.clientEvent(), (response) => {
      if (!response || !response.command || !response.data) return

      const command = response.command
      const data = response.data

      SocketData.set(command, data)
    })
  }

}

export default Socket
