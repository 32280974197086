<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Faq.FaqCategoryAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Faq.FaqCategoryAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!categoryToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Faq.FaqCategoryAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Faq.FaqCategoryAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Faq.FaqCategoryAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Faq.FaqCategoryAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="categoryForm"
            v-model="categoryFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="faqLanguages.length > 1">
                    <v-tabs
                      v-model="languageTab"
                      :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                      slider-color="blue"
                      height="40"
                      grow
                    >
                      <template v-for="lang in faqLanguages">
                        <v-tab
                          :key="lang.locale"
                          :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                        >
                          <v-avatar
                            class="mr-2"
                            size="20"
                          >
                            <v-img :src="`/assets/flags/1x1/${lang.locale}.svg`" />
                          </v-avatar>
                          {{ $te(`Common.Language.${ lang.locale }`) ? $t(`Common.Language.${ lang.locale }`).name.toLocaleUpperCase($i18n.locale) : lang.name.toLocaleUpperCase($i18n.locale) }}
                        </v-tab>
                      </template>
                    </v-tabs>
                  </template>

                  <v-tabs-items v-model="languageTab">
                    <template v-for="lang in faqLanguages">
                      <v-tab-item
                        :key="lang.locale"
                        eager
                      >
                        <v-container
                          fluid
                          class="pa-0"
                        >
                          <v-row dense>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editCategory.Lang[lang.locale].Title"
                                :counter="255"
                                :hint="$t('Faq.FaqCategoryAddDialog.Field.Title.Hint')"
                                :label="$t('Faq.FaqCategoryAddDialog.Field.Title.Label')"
                                :prepend-icon="$t('Faq.FaqCategoryAddDialog.Field.Title.Icon')"
                                :rules="validationRules.categoryForm.Title"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editCategory.Lang[lang.locale].Slug"
                                :counter="255"
                                :hint="$t('Faq.FaqCategoryAddDialog.Field.Slug.Hint')"
                                :label="$t('Faq.FaqCategoryAddDialog.Field.Slug.Label')"
                                :prepend-icon="$t('Faq.FaqCategoryAddDialog.Field.Slug.Icon')"
                                :rules="validationRules.categoryForm.Slug"
                                class="pr-2"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editCategory.Lang[lang.locale].Description"
                                :counter="512"
                                :hint="$t('Faq.FaqCategoryAddDialog.Field.Description.Hint')"
                                :label="$t('Faq.FaqCategoryAddDialog.Field.Description.Label')"
                                :prepend-icon="$t('Faq.FaqCategoryAddDialog.Field.Description.Icon')"
                                :rules="validationRules.categoryForm.Description"
                                class="pr-2"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                    </template>
                  </v-tabs-items>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    v-model="editCategory.Priority"
                    :hint="$t('Faq.FaqCategoryAddDialog.Field.Priority.Hint')"
                    :label="$t('Faq.FaqCategoryAddDialog.Field.Priority.Label')"
                    :prepend-icon="$t('Faq.FaqCategoryAddDialog.Field.Priority.Icon')"
                    :rules="validationRules.categoryForm.Priority"
                    type="number"
                    step="1"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-switch
                    v-model="editCategory.Active"
                    inset
                    :label="editCategory.Active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                    color="success"
                  >
                    <template slot="prepend">
                      <label
                        class="v-label ml-2 text-no-wrap"
                        style="left: auto; right: 0px; position: relative;"
                      >{{ $t('Faq.FaqCategoryAddDialog.Field.Status.Label') }}</label>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <div class="grey--text pb-2">
                    {{ $t('Faq.FaqCategoryAddDialog.Field.Color.Label') }}
                  </div>
                  <color-picker
                    v-model="editCategory.TmpColor"
                    style="width: 100%;"
                    :style="isDarkMode ? 'background-color: #191919;' : 'background-color: white;'"
                  />
                </v-col>
                <v-col cols="12" />
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            v-if="faqLanguages.length > 1"
            :disabled="loading"
            class="purple white--text elevation-0"
            @click="translateItem()"
          >
            {{ $t('Common.Button.Translate').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Swatches }    from 'vue-color'
import AppData         from '@/mixins/appdata'
import googleTranslate from '@/lib/google-translate/googleTranslate'

export default {
  name      : 'FaqCategoryAddDialog',
  components: {
    'color-picker': Swatches
  },
  mixins: [AppData],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    categoryToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading          : false,
      languageTab      : 0,
      categoryFormValid: false,
      newCategory      : null,
      tmpCategory      : {
        Id         : null,
        Title      : '',
        Slug       : '',
        Description: '',
        Active     : true,
        Priority   : '1',
        Color      : '',
        TmpColor   : {
          hex   : '',
          source: ''
        }
      },
      validationRules: {
        categoryForm: {
          Title: [
            (v) => v && v.length >= 2 && v.length <= 255 || this.$t('Faq.FaqCategoryAddDialog.Field.Title.Error.Between')
          ],
          Slug: [
            (v) => v && v.length >= 2 && v.length <= 255 || this.$t('Faq.FaqCategoryAddDialog.Field.Slug.Error.Between')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 512) || this.$t('Faq.FaqCategoryAddDialog.Field.Description.Error.Between')
          ],
          Priority: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Faq.FaqCategoryAddDialog.Field.Priority.Error.Min')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editCategory: {
      get () {
        if (this.categoryToEdit) {
          const cat = JSON.parse(JSON.stringify(this.categoryToEdit))
          const color = JSON.parse(JSON.stringify(this.categoryToEdit)).Color
          cat.TmpColor = {
            hex   : color,
            source: 'hex'
          }
          cat.Priority = cat.Priority.toString()
          this.newCategory = JSON.parse(JSON.stringify(cat))
        } else {
          this.newCategory = JSON.parse(JSON.stringify(this.tmpCategory))
        }

        const langObj = {}
        for (const lang of this.faqLanguages) {
          langObj[lang.locale] = {
            Title      : '',
            Slug       : '',
            Description: '',
            Locale     : lang.locale
          }
        }
        this.newCategory.Lang = Object.assign({}, langObj, this.newCategory.Lang)

        return this.newCategory
      }
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Faq.Category.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$on(window.SocketCommand.Faq.Category.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.categoryForm.validate()

      if (this.categoryFormValid) {
        this.loading = true
        this.editCategory.Color = JSON.parse(JSON.stringify(this.editCategory)).TmpColor.hex// .replace('#', '')
        this.editCategory.Title = this.editCategory.Lang && this.editCategory.Lang[this.defaultLanguage.Code] && this.editCategory.Lang[this.defaultLanguage.Code].Title ? this.editCategory.Lang[this.defaultLanguage.Code].Title : ''
        this.editCategory.Slug = this.editCategory.Lang && this.editCategory.Lang[this.defaultLanguage.Code] && this.editCategory.Lang[this.defaultLanguage.Code].Slug ? this.editCategory.Lang[this.defaultLanguage.Code].Slug : ''

        window.callAS(window.SocketCommand.Faq.Category.Save, this.editCategory)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data.status === 'success') {
        this.onCancelButtonClick()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.GenericError'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      this.$refs.categoryForm && this.$refs.categoryForm.resetValidation()
      this.isVisible = false
      this.newCategory = null
      this.languageTab = 0
    },

    async translateItem (item = this.editCategory, localeFrom = this.defaultLanguage.Code, localeTo) {
      if (this.faqLanguages.length <= 0) return
      const langs = localeTo ? [{ locale: localeTo }] : this.faqLanguages.filter(lang => lang.locale !== localeFrom) || []

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].locale

        const result = await googleTranslate([
          item.Lang[localeFrom].Title || '',
          item.Lang[localeFrom].Description || ''
        ], localeTo, localeFrom)

        if (result) {
          const [Title, Description] = result
          item.Lang[localeTo].Title = Title.translatedText
          item.Lang[localeTo].Description = Description.translatedText
          item.Lang[localeTo].Slug = `${ item.Lang[localeFrom].Slug }_${ localeTo }`
        }
      }

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style scoped>
.vc-swatches {
  min-width  : 472px;
  height     : auto;
  overflow-y : auto;
  zoom       : 75%;
}

/deep/ .vc-swatches .vc-swatches-box div.vc-swatches-color-group:last-child {
  display : none;
}
</style>
