<template>
  <div v-if="editMenu">
    <v-dialog
      v-if="editMenu.Details"
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1280"
      persistent
      scrollable
    >
      <v-card ref="dialogCard">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.MenuAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.MenuAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!isMenuItemEdit"
            class="pl-3"
          >
            <div
              v-if="editMenu.Details.Name"
              class="body-3"
            >
              {{ getProductLangField('Name') }}
            </div>
            <div
              v-else
              class="body-3"
            >
              {{ $t('Restaurant.MenuAddDialog.Toolbar.Title') }}
            </div>
            <div
              v-if="editMenu.Details.Description"
              class="text-caption"
            >
              {{ getProductLangField('Description') }}
            </div>
            <div
              v-else
              class="text-caption"
            >
              {{ $t('Restaurant.MenuAddDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ getProductLangField('Name') }}
            </div>
            <div class="text-caption">
              {{ getProductLangField('Description') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-tabs
          v-model="activeTab"
          :scrollable="false"
          background-color="indigo"
          dark
          slider-color="light-green"
        >
          <v-tab
            key="tab1"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab1"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.Details').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="tab2"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab2"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.Settings').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="tab3"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab3"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.Automation').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="tab4"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab4"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.Options').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="tab5"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab5"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.ExtraCharges').toLocaleUpperCase($i18n.locale) }}
          </v-tab>
        </v-tabs>

        <v-tabs
          v-if="activeTab === 'tab3'"
          v-model="activeAutomationTab"
          :scrollable="false"
          background-color="blue-grey darken-3"
          dark
          slider-color="light-green"
        >
          <v-tab
            key="automationTab1"
            active-class="v-tabs__item--active blue-grey darken-4"
            href="#automationTab1"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.Status').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            v-if="editMenu.SubtractStock"
            key="automationTab2"
            active-class="v-tabs__item--active blue-grey darken-4"
            href="#automationTab2"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.Stock').toLocaleUpperCase($i18n.locale) }}
          </v-tab>
        </v-tabs>

        <v-card-text
          ref="dialogCardText"
          class="fill-height pa-3"
        >
          <v-form
            ref="menuForm"
            v-model="menuFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="activeTab">
              <v-tab-item
                key="tab1"
                value="tab1"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <template v-if="selectedLanguages.length > 1">
                        <v-tabs
                          v-model="languageTab"
                          :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                          grow
                          height="40"
                          slider-color="blue"
                        >
                          <template v-for="lang in selectedLanguages">
                            <v-tab
                              :key="lang.Code"
                              :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                            >
                              <v-avatar
                                v-if="lang.ImageUrl"
                                class="mr-2"
                                size="20"
                              >
                                <v-img :src="lang.ImageUrl" />
                              </v-avatar>
                              {{
                                $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale)
                              }}
                            </v-tab>
                          </template>
                        </v-tabs>
                      </template>

                      <v-tabs-items v-model="languageTab">
                        <template v-for="lang in selectedLanguages">
                          <v-tab-item
                            :key="lang.Code"
                            eager
                          >
                            <v-container
                              class="pa-1"
                              fluid
                            >
                              <v-row dense>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editMenu.Lang[lang.Code].Name"
                                    :counter="255"
                                    :hint="$t('Restaurant.MenuAddDialog.Field.Name.Hint')"
                                    :label="$t('Restaurant.MenuAddDialog.Field.Name.Label')"
                                    :maxlength="255"
                                    :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Name.Icon')"
                                    :rules="validationRules.menuForm.Name"
                                    class="pr-2"
                                    required
                                  >
                                    <template
                                      v-if="lang.Code !== defaultLanguage.Code"
                                      #append-outer
                                    >
                                      <translate-field-button
                                        :item="editMenu"
                                        :locale-from="defaultLanguage.Code"
                                        :locale-to="lang.Code"
                                        field="Name"
                                      />
                                    </template>
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editMenu.Lang[lang.Code].Description"
                                    :counter="500"
                                    :hint="$t('Restaurant.MenuAddDialog.Field.Description.Hint')"
                                    :label="$t('Restaurant.MenuAddDialog.Field.Description.Label')"
                                    :maxlength="500"
                                    :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Description.Icon')"
                                    :rules="validationRules.menuForm.Description"
                                    class="pr-2"
                                  >
                                    <template
                                      v-if="lang.Code !== defaultLanguage.Code"
                                      #append-outer
                                    >
                                      <translate-field-button
                                        :item="editMenu"
                                        :locale-from="defaultLanguage.Code"
                                        :locale-to="lang.Code"
                                        field="Description"
                                      />
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-tab-item>
                        </template>
                      </v-tabs-items>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-select
                        v-model="editMenu.Details.UnitId"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Unit.Hint')"
                        :items="measurementUnitsArray"
                        :label="$t('Restaurant.MenuAddDialog.Field.Unit.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Unit.Icon')"
                        :rules="validationRules.menuForm.UnitId"
                        class="pr-2"
                        item-text="Name"
                        item-value="Id"
                        required
                        @change="onUnitChange"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        v-model="editMenu.Details.UnitQuantity"
                        :disabled="editMenu.Details.UnitId === 1"
                        :hint="$t('Restaurant.MenuAddDialog.Field.UnitQuantity.Hint')"
                        :label="`${$t('Restaurant.MenuAddDialog.Field.UnitQuantity.Label')} ${$t('Common.Misc.In')} ${getMeasurementUnitById(editMenu.Details.UnitId).BaseName}`"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.UnitQuantity.Icon')"
                        :suffix="`/ ${getMeasurementUnitById(editMenu.Details.UnitId).RateUnit}`"
                        class="pr-2"
                        min="0"
                        step="1"
                        type="number"
                      />
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        v-model="editMenu.Details.Number"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Number.Hint')"
                        :label="$t('Restaurant.MenuAddDialog.Field.Number.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Number.Icon')"
                        class="pr-2"
                      />
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        v-model="editMenu.Details.Barcode"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Barcode.Hint')"
                        :label="$t('Restaurant.MenuAddDialog.Field.Barcode.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Barcode.Icon')"
                        class="pr-2"
                        maxlength="128"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        v-model="editMenu.Details.Price"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Price.Hint')"
                        :label="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                        :prepend-icon="appCurrency.Icon"
                        :rules="validationRules.menuForm.Price"
                        :suffix="`/ ${getMeasurementUnitById(editMenu.Details.UnitId).BaseUnit}`"
                        class="pr-2"
                        min="0"
                        required
                        step="any"
                        type="number"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        v-model="editMenu.OptionsPrice"
                        :hint="$t('Restaurant.MenuAddDialog.Field.OptionsPrice.Hint')"
                        :label="$t('Restaurant.MenuAddDialog.Field.OptionsPrice.Label')"
                        :prepend-icon="appCurrency.Icon"
                        class="pr-2"
                        readonly
                        required
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <div class="text-caption mt-1 grey--text">
                        {{ $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.Title') }}
                      </div>
                      <v-switch
                        :id="editMenu.Id + '_show_calculated_price'"
                        v-model="editMenu.ShowCalculatedPrice"
                        :disabled="editMenu.Details.SpecialStatus"
                        class="mt-0 d-inline-flex"
                        color="blue"
                        hide-details
                        inset
                        style="width: 100% !important;"
                      >
                        <span
                          slot="append"
                          :class="{'blue--text': editMenu.ShowCalculatedPrice, 'grey--text': !editMenu.ShowCalculatedPrice}"
                          class="pt-1"
                        >
                          <template
                            v-if="editMenu.ShowCalculatedPrice && editMenu.OptionsPrice && editMenu.Details.Price"
                          >
                            {{ (parseFloat(editMenu.OptionsPrice) + parseFloat(editMenu.Details.Price)) | currency }}
                          </template>
                          <template v-else-if="editMenu.Details.Price">
                            {{ parseFloat(editMenu.Details.Price) | currency }}
                          </template>
                        </span>
                        <span
                          slot="prepend"
                          :class="{'blue--text': editMenu.ShowCalculatedPrice, 'grey--text': !editMenu.ShowCalculatedPrice}"
                          class="pt-1 text-no-wrap"
                        >
                          {{
                            editMenu.ShowCalculatedPrice ? $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.Toggle.Active') : $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.Toggle.Inactive')
                          }}
                        </span>
                      </v-switch>
                      <div
                        v-if="editMenu.Details.SpecialStatus"
                        class="text-caption mt-1 error--text"
                      >
                        {{ $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.SpecialStatus') }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        v-model="editMenu.Tags"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Tags.Hint')"
                        :items="tags"
                        :label="$t('Restaurant.MenuAddDialog.Field.Tags.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Tags.Icon')"
                        clearable
                        item-text="Name"
                        item-value="Id"
                        multiple
                        persistent-hint
                      >
                        <template #selection="{item}">
                          <v-chip
                            :color="item.Color"
                            class="px-1"
                            dark
                            label
                            small
                          >
                            <v-icon
                              v-if="item.Icon"
                              :color="getContrastColor(item.Color, true, 168)"
                              class="mr-1"
                              small
                            >
                              {{ item.Icon }}
                            </v-icon>

                            <span>{{ getTranslatedField(item, 'Label') }}</span>
                          </v-chip>
                        </template>

                        <template #item="{item}">
                          <v-list-item-avatar
                            :color="`${item.Color}`"
                            :size="28"
                          >
                            <v-icon
                              v-if="item.Icon"
                              :color="getContrastColor(item.Color, true, 168)"
                              :size="20"
                            >
                              {{ item.Icon }}
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{ getTranslatedField(item, 'Label') }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                        v-model="editMenu.Catalogs"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Catalogs.Hint')"
                        :items="catalogsInternal"
                        :label="$t('Restaurant.MenuAddDialog.Field.Catalogs.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Catalogs.Icon')"
                        :rules="validationRules.menuForm.Catalogs"
                        item-text="Name"
                        item-value="Id"
                        multiple
                        persistent-hint
                      >
                        <template #selection="{item}">
                          <v-chip
                            :color="`#${item.Color}`"
                            dark
                            label
                            small
                          >
                            <span>{{ item.Name }}</span>
                          </v-chip>
                        </template>

                        <template #item="data">
                          <v-list-item-avatar
                            :color="`#${data.item.Color}`"
                            :size="28"
                          />

                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-container
                        class="pa-1"
                        fluid
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editMenu.Details.MenuComment"
                              :counter="500"
                              :hint="$t('Restaurant.MenuAddDialog.Field.MenuComment.Hint')"
                              :label="$t('Restaurant.MenuAddDialog.Field.MenuComment.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.MenuComment.Icon')"
                              :rows="editMenu.Details.MenuComment ? '3' : '1'"
                              :rules="validationRules.menuForm.Description"
                              auto-grow
                              class="pr-2"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-select
                              v-model="editMenu.Categories"
                              :hint="$t('Restaurant.MenuAddDialog.Field.Categories.Hint')"
                              :item-text="(data) => getTranslatedField(data, 'Name')"
                              :items="catalogCategories"
                              :label="$t('Restaurant.MenuAddDialog.Field.Categories.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Categories.Icon')"
                              :rules="validationRules.menuForm.Category"
                              chips
                              class="pr-2"
                              item-value="Id"
                              multiple
                              persistent-hint
                              required
                            >
                              <template
                                slot="selection"
                                slot-scope="data"
                              >
                                <v-chip
                                  :key="data.item.Id"
                                  :style="'background-color:#' + (data.item.Color ? data.item.Color : 'e0e0e0') + '; color: ' + (invertColor(data.item.Color ? data.item.Color : 'e0e0e0', true)) + ';'"
                                  class="chip--select-multi"
                                  label
                                  @input="data.parent.selectItem(data.item)"
                                >
                                  {{ getTranslatedField(data.item, 'Name') }}
                                  <span
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption ml-2"
                                  >- {{ getCategoryCatalog(data.item) }}</span>
                                </v-chip>
                              </template>
                              <template #item="data">
                                <v-list-item-content>
                                  <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption grey--text"
                                  >
                                    {{ getCategoryCatalog(data.item) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col cols="12">
                            <v-select
                              v-if="selectedCategories.length > 0"
                              v-model="editMenu.CategoryId"
                              :hint="$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Hint')"
                              :item-text="(data) => getTranslatedField(data, 'Name')"
                              :items="selectedCategories"
                              :label="$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Icon')"
                              :rules="validationRules.menuForm.DefaultCategory"
                              chips
                              class="pr-2 mt-2"
                              item-value="Id"
                              persistent-hint
                              required
                            >
                              <template
                                slot="selection"
                                slot-scope="data"
                              >
                                <v-chip
                                  :key="data.item.Id"
                                  :style="'background-color:#' + (data.item.Color ? data.item.Color : 'e0e0e0') + '; color: ' + (invertColor(data.item.Color ? data.item.Color : 'e0e0e0', true)) + ';'"
                                  class="chip--select-multi"
                                  label
                                  @input="data.parent.selectItem(data.item)"
                                >
                                  {{ getTranslatedField(data.item, 'Name') }}
                                  <span
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption ml-2"
                                  >- {{ getCategoryCatalog(data.item) }}</span>
                                </v-chip>
                              </template>
                              <template #item="data">
                                <v-list-item-content>
                                  <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption grey--text"
                                  >
                                    {{ getCategoryCatalog(data.item) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col cols="12">
                            <v-select
                              v-model="editMenu.Details.MealtimeId"
                              :hint="$t('Restaurant.MenuAddDialog.Field.Availability.Hint')"
                              :items="mealtimes"
                              :label="$t('Restaurant.MenuAddDialog.Field.Availability.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Availability.Icon')"
                              class="pr-2 mt-2"
                              item-text="Desc"
                              item-value="Id"
                              persistent-hint
                              required
                            >
                              <template
                                slot="selection"
                                slot-scope="data"
                              >
                                {{ getTranslatedField(data.item, 'Name') }}
                              </template>
                            </v-select>
                          </v-col>

                          <template v-if="preparationDocumentEnabled">
                            <v-col cols="12">
                              <v-select
                                v-model="editMenu.PrintGroup"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Hint')"
                                :items="appPrinterGroups"
                                :label="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Icon')"
                                :rules="validationRules.menuForm.PrintGroup"
                                item-text="Name"
                                item-value="Id"
                                multiple
                                persistent-hint
                              >
                                <template #selection="{item, index}">
                                  <v-chip
                                    v-if="index < 2"
                                    label
                                    small
                                  >
                                    <span>{{ item.Name }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index > 1"
                                    class="grey--text text-caption"
                                  >(+{{ editMenu.PrintGroup.length - 2 }})</span>
                                </template>
                              </v-select>
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <div class="pl-2 pr-2 text-center mt-6">
                        <div class="grey--text pl-4 pb-2 text-center">
                          {{ $t('Restaurant.MenuAddDialog.Field.Image.Label') }}
                        </div>

                        <image-crop-selector
                          v-model="croppa"
                          :excluded-gallery-tabs="[]"
                          :height="350"
                          :initial-image="editMenu.Details.Image ? appConfig.LOCATION_DATA.CdnImagesUrl + editMenu.Details.Image : ''"
                          :placeholder="`${$t('Settings.Website.Field.Gallery.Image.Hint.Drag')} ${$t('Settings.Website.Field.Gallery.Image.Hint.Click')}`"
                          :placeholder-font-size="15"
                          :prevent-white-space="true"
                          :replace-drop="true"
                          :show-loading="true"
                          :width="800"
                          default-gallery-tab="product"
                          gallery-enabled
                          @new-image-drawn="onPhotoChange"
                          @image-remove="onPhotoRemoved"
                        />
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="pa-1"
                        fluid
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Restaurant.CategoryAddDialog.Field.Tax.Label') }}
                            </h5>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-model="editMenu.TaxId"
                              :hint="$t('Restaurant.CategoryAddDialog.Field.Tax.Hint')"
                              :items="taxesList"
                              :label="$t('Restaurant.CategoryAddDialog.Field.Tax.Label')"
                              :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Tax.Icon')"
                              :rules="validationRules.menuForm.TaxId"
                              class=""
                              clearable
                              item-text="Name"
                              item-value="Id"
                              persistent-hint
                              required
                            >
                              <template #selection="{item}">
                                {{ getTranslatedField(item, 'Name') }}
                              </template>
                            </v-select>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-if="appSupportSecondTax"
                              v-model="editMenu.Tax2Id"
                              :hint="$t('Restaurant.CategoryAddDialog.Field.Tax2.Hint')"
                              :items="taxesList"
                              :label="$t('Restaurant.CategoryAddDialog.Field.Tax2.Label')"
                              :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Tax2.Icon')"
                              :rules="validationRules.menuForm.Tax2Id"
                              class="mt-4"
                              clearable
                              item-text="Name"
                              item-value="Id"
                              persistent-hint
                              required
                            >
                              <template #selection="{item}">
                                {{ getTranslatedField(item, 'Name') }}
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <template v-if="appSupportIncomeClassifications">
                            <v-col cols="12">
                              <h5 class="text-body-2 font-weight-bold mt-2">
                                {{ $t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Title') }}
                              </h5>
                            </v-col>

                            <v-col
                              cols="12"
                              md="6"
                            >
                              <h5 class="text-body-2 font-weight-bold mt-1 mb-2">
                                {{ $t('Settings.Documents.OrderReceipt.Document.Receipt') }}
                              </h5>

                              <v-select
                                v-model="editMenu.ClassificationCategoryCode"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                                :items="incomeClassificationCategoryCodeList"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationCategoryCode"
                                class="mt-2"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>

                              <v-select
                                v-model="editMenu.ClassificationTypeCode"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                                :items="getReceiptIncomeClassificationTypesByCategoryCode(editMenu.ClassificationCategoryCode)"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationTypeCode"
                                class="mt-4"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.Code }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </v-col>

                            <v-col
                              cols="12"
                              md="6"
                            >
                              <h5 class="text-body-2 font-weight-bold mt-1 mb-2">
                                {{ $t('Settings.Documents.OrderReceipt.Document.Invoice') }}
                              </h5>

                              <v-select
                                v-model="editMenu.ClassificationCategoryCodeInvoice"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                                :items="incomeClassificationCategoryCodeList"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationCategoryCode"
                                class="mt-2"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>

                              <v-select
                                v-model="editMenu.ClassificationTypeCodeInvoice"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                                :items="getInvoiceIncomeClassificationTypesByCategoryCode(editMenu.ClassificationCategoryCodeInvoice)"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationTypeCode"
                                class="mt-4"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.Code }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                key="tab2"
                value="tab2"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-text-field
                        v-model="editMenu.Details.StockQuantity"
                        v-mask="'######'"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Stock.Hint')"
                        :label="`${$t('Restaurant.MenuAddDialog.Field.Stock.Label')} ${$t('Common.Misc.In')} ${getMeasurementUnitById(editMenu.Details.UnitId).BaseName}`"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Stock.Icon')"
                        :rules="validationRules.menuForm.StockQuantity"
                        :suffix="`/ ${getMeasurementUnitById(editMenu.Details.UnitId).RateUnit}`"
                        class="pr-2"
                        min="0"
                        required
                        step="1"
                        type="number"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-text-field
                        v-model="editMenu.Details.MinimumQuantity"
                        v-mask="'######'"
                        :hint="$t('Restaurant.MenuAddDialog.Field.MinimumQuantity.Hint')"
                        :label="`${$t('Restaurant.MenuAddDialog.Field.MinimumQuantity.Label')} ${$t('Common.Misc.In')} ${getMeasurementUnitById(editMenu.Details.UnitId).BaseName}`"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.MinimumQuantity.Icon')"
                        :rules="validationRules.menuForm.MinimumQuantity"
                        :suffix="`/ ${getMeasurementUnitById(editMenu.Details.UnitId).RateUnit}`"
                        class="pr-2"
                        min="1"
                        required
                        step="1"
                        type="number"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-text-field
                        v-model="editMenu.Details.Priority"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Priority.Hint')"
                        :label="$t('Restaurant.MenuAddDialog.Field.Priority.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Priority.Icon')"
                        :rules="validationRules.menuForm.Priority"
                        class="pr-2"
                        required
                        step="1"
                        type="number"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    class="space-between-"
                    dense
                  >
                    <v-col
                      cols="6"
                      sm="2"
                    >
                      <div class="text-caption mt-2">
                        {{ $t('Restaurant.MenuAddDialog.Field.SubtractStock') }}
                      </div>
                      <v-switch
                        v-model="editMenu.SubtractStock"
                        :label="editMenu.SubtractStock ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                        class="mt-2"
                        color="success"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      sm="2"
                    >
                      <div class="text-caption mt-2">
                        {{ $t('Restaurant.MenuAddDialog.Field.Status') }}
                      </div>
                      <v-switch
                        v-model="editMenu.Status"
                        :label="editMenu.Status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        class="mt-2"
                        color="success"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      sm="2"
                    >
                      <div class="text-caption mt-2">
                        {{ $t('Restaurant.MenuAddDialog.Field.VisibilityPos') }}
                      </div>
                      <v-switch
                        v-model="editMenu.ActivePos"
                        :label="editMenu.ActivePos ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                        class="mt-2"
                        color="purple"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      sm="2"
                    >
                      <div class="text-caption mt-2">
                        {{ $t('Restaurant.MenuAddDialog.Field.Visibility') }}
                      </div>
                      <v-switch
                        v-model="editMenu.Active"
                        :label="editMenu.Active ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                        class="mt-2"
                        color="blue"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      sm="2"
                    >
                      <div class="text-caption mt-2">
                        {{ $t('Restaurant.MenuAddDialog.Field.MenuCustomPrice') }}
                      </div>
                      <v-switch
                        v-model="editMenu.Details.MenuCustomPrice"
                        :disabled="editMenu.Details.UnitId > 1"
                        :label="editMenu.Details.MenuCustomPrice ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                        class="mt-2"
                        color="success"
                        hide-details
                        inset
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      sm="2"
                    >
                      <div class="text-caption mt-2">
                        {{ $t('Restaurant.MenuAddDialog.Field.Featured') }}
                      </div>
                      <v-switch
                        v-model="editMenu.Details.Featured"
                        :label="editMenu.Details.Featured ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                        class="mt-2"
                        color="cyan"
                        hide-details
                        inset
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-divider class="mt-4" />
                      <v-subheader class="pl-0 text-subtitle-1">
                        {{ $t('Restaurant.MenuAddDialog.Field.Discount.Title') }}
                      </v-subheader>
                    </v-col>

                    <v-col cols="12">
                      <div class="text-caption mt-2">
                        {{ $t('Restaurant.MenuAddDialog.Field.DiscountExcluded') }}
                      </div>
                      <v-switch
                        v-model="editMenu.Details.Excluded"
                        :label="editMenu.Details.Excluded ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                        class="mt-2"
                        color="success"
                        hide-details
                        inset
                      />
                    </v-col>

                    <template v-if="editMenu.Details.UnitId === 1">
                      <v-col
                        cols="12"
                        sm="3"
                      >
                        <v-switch
                          :id="editMenu.Id + '_special_status'"
                          v-model="editMenu.Details.SpecialStatus"
                          :disabled="editMenu.Details.UnitId > 1"
                          :label="editMenu.Details.SpecialStatus ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                          color="success"
                          inset
                          @click.native="() => {if(editMenu.Details.SpecialStatus) editMenu.ShowCalculatedPrice = false}"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="3"
                      >
                        <v-menu
                          v-model="menuSpecialStartDateVisible"
                          :close-on-content-click="false"
                          max-width="290px"
                          min-width="290px"
                          offset-y
                          transition="scale-transition"
                        >
                          <template #activator="{on}">
                            <v-text-field
                              v-model="editMenu.Details.SpecialStartDateFmt"
                              :label="$t('Restaurant.MenuAddDialog.Field.Discount.Field.StartDate.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Discount.Field.StartDate.Icon')"
                              :required="editMenu.Details.SpecialStatus"
                              :rules="validationRules.menuForm.SpecialStartDate"
                              readonly
                              @blur="editMenu.Details.SpecialStartDate = parseDate(editMenu.Details.SpecialStartDateFmt)"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="editMenu.Details.SpecialStartDate"
                            :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                            first-day-of-week="1"
                            scrollable
                            @input="editMenu.Details.SpecialStartDateFmt = formatDate($event); menuSpecialStartDateVisible = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="3"
                      >
                        <v-menu
                          v-model="menuSpecialEndDateVisible"
                          :close-on-content-click="false"
                          max-width="290px"
                          min-width="290px"
                          offset-y
                          transition="scale-transition"
                        >
                          <template #activator="{on}">
                            <v-text-field
                              v-model="editMenu.Details.SpecialEndDateFmt"
                              :label="$t('Restaurant.MenuAddDialog.Field.Discount.Field.EndDate.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Discount.Field.EndDate.Icon')"
                              :required="editMenu.Details.SpecialStatus"
                              :rules="validationRules.menuForm.SpecialEndDate"
                              readonly
                              @blur="editMenu.Details.SpecialEndDate = parseDate(editMenu.Details.SpecialEndDateFmt)"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="editMenu.Details.SpecialEndDate"
                            :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                            first-day-of-week="1"
                            scrollable
                            @input="editMenu.Details.SpecialEndDateFmt = formatDate($event); menuSpecialEndDateVisible = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="3"
                      >
                        <v-text-field
                          v-model="editMenu.Details.SpecialPrice"
                          :hint="$t('Restaurant.MenuAddDialog.Field.Discount.Field.DiscountPrice.Hint')"
                          :label="$t('Restaurant.MenuAddDialog.Field.Discount.Field.DiscountPrice.Label')"
                          :prepend-icon="appCurrency.Icon"
                          :required="editMenu.Details.SpecialStatus"
                          :rules="validationRules.menuForm.SpecialPrice"
                          class="pr-2"
                          min="0"
                          step="any"
                          type="number"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="activeTab === 'tab3'"
                key="tab3"
                value="tab3"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <v-tabs-items v-model="activeAutomationTab">
                        <v-tab-item
                          key="automationTab1"
                          value="automationTab1"
                        >
                          <v-container
                            class=""
                            fluid
                          >
                            <v-row dense>
                              <v-col cols="12">
                                <v-simple-table v-if="editMenu && editMenu.Details">
                                  <template #default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          {{ $t('Common.Date.Day') }}
                                        </th>

                                        <th class="text-left">
                                          {{ $t('Common.Date.Time') }}
                                        </th>

                                        <th class="text-left">
                                          <v-btn
                                            icon
                                            depressed
                                            x-small
                                            class="purple white--text"
                                            @click="addStatusUpdateWeekTime"
                                          >
                                            <v-icon>mdi-plus</v-icon>
                                          </v-btn>
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <template v-for="(day, dayIdx) in Weekdays">
                                        <tr :key="`day-${dayIdx}`">
                                          <td class="font-weight-bold">
                                            {{ day }}
                                          </td>

                                          <td style="width: 100%;">
                                            <template v-for="(item, timeIdx) in getResetStatusValueDay(dayIdx)">
                                              <v-chip
                                                :key="`day-${dayIdx}-item-${timeIdx}`"
                                                class="mr-1 my-1 white--text"
                                                :color="item.status ? 'green' : 'orange'"
                                                small
                                                close
                                                label
                                                @click.stop="editStatusUpdateTime(dayIdx, timeIdx)"
                                                @click:close="removeStatusUpdateDayTime(dayIdx, timeIdx)"
                                              >
                                                <div
                                                  style="min-width: 155px;"
                                                  v-html="`${item.time} - <strong>${item.status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')}</strong>${item.active ? ', ' + $t('Restaurant.MenuAddDialog.StatusAutomation.Online') : ''}${item.activePos ? ', ' + $t('Restaurant.MenuAddDialog.StatusAutomation.Pos') : ''}`"
                                                />
                                              </v-chip>
                                            </template>
                                          </td>

                                          <td
                                            class="text-right"
                                            style="width: 40px;"
                                          >
                                            <v-btn
                                              icon
                                              depressed
                                              x-small
                                              class="primary white--text"
                                              @click="addStatusUpdateDayTime(dayIdx)"
                                            >
                                              <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="my-2" />
                              </v-col>

                              <v-col cols="12">
                                <div class="text-caption mt-2">
                                  {{ $t('Restaurant.MenuAddDialog.Tab.Automation') }}
                                </div>

                                <v-switch
                                  v-model="editMenu.Details.ResetStatus"
                                  :label="editMenu.Details.ResetStatus ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                                  class="mt-2"
                                  color="success"
                                  hide-details
                                  inset
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tab-item>

                        <v-tab-item
                          key="automationTab2"
                          value="automationTab2"
                        >
                          <v-container
                            class=""
                            fluid
                          >
                            <v-row dense>
                              <v-col cols="12">
                                <v-simple-table v-if="editMenu && editMenu.Details">
                                  <template #default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          {{ $t('Common.Date.Day') }}
                                        </th>

                                        <th class="text-left">
                                          {{ $t('Common.Date.Time') }}
                                        </th>

                                        <th class="text-left">
                                          <v-btn
                                            icon
                                            depressed
                                            x-small
                                            class="purple white--text"
                                            @click="addStockUpdateWeekTime"
                                          >
                                            <v-icon>mdi-plus</v-icon>
                                          </v-btn>
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <template v-for="(day, dayIdx) in Weekdays">
                                        <tr :key="`day-${dayIdx}`">
                                          <td class="font-weight-bold">
                                            {{ day }}
                                          </td>

                                          <td style="width: 100%;">
                                            <template v-for="(item, timeIdx) in getResetStockValueDay(dayIdx)">
                                              <v-chip
                                                :key="`day-${dayIdx}-item-${timeIdx}`"
                                                class="mr-1 my-1 white--text"

                                                :color="item.action === 'reset' ? 'green' : 'primary'"
                                                small
                                                close
                                                label
                                                @click.stop="editStockUpdateTime(dayIdx, timeIdx)"
                                                @click:close="removeStockUpdateDayTime(dayIdx, timeIdx)"
                                              >
                                                <template v-if="item.action === 'reset'">
                                                  <div
                                                    style="min-width: 245px;"
                                                    v-html="$t('Restaurant.MenuAddDialog.StockAutomation.Reset', {
                                                      time: item.time,
                                                      stock: `${ item.value } ${ getMeasurementUnitById(editMenu.Details.UnitId).BaseName }`
                                                    })"
                                                  />
                                                </template>
                                                <template v-else-if="item.action === 'add'">
                                                  <div
                                                    style="min-width: 245px;"
                                                    v-html="$t('Restaurant.MenuAddDialog.StockAutomation.Add', {
                                                      time: item.time,
                                                      stock: `${ item.value } ${ getMeasurementUnitById(editMenu.Details.UnitId).BaseName }`
                                                    })"
                                                  />
                                                </template>
                                              </v-chip>
                                            </template>
                                          </td>

                                          <td
                                            class="text-right"
                                            style="width: 40px;"
                                          >
                                            <v-btn
                                              icon
                                              depressed
                                              x-small
                                              class="primary white--text"
                                              @click="addStockUpdateDayTime(dayIdx)"
                                            >
                                              <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="my-2" />
                              </v-col>

                              <v-col cols="12">
                                <div class="text-caption mt-2">
                                  {{ $t('Restaurant.MenuAddDialog.Tab.Automation') }}
                                </div>

                                <v-switch
                                  v-model="editMenu.Details.ResetStock"
                                  :label="editMenu.Details.ResetStock ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                                  class="mt-2"
                                  color="success"
                                  hide-details
                                  inset
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="activeTab === 'tab4'"
                key="tab4"
                value="tab4"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col cols="auto">
                      <v-select
                        v-model="selectedOptionListItem"
                        :hint="$t('Restaurant.MenuAddDialog.Field.SelectOption.Hint')"
                        :items="optionsList"
                        :label="$t('Restaurant.MenuAddDialog.Field.SelectOption.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.SelectOption.Icon')"
                        class="pr-2"
                        item-text="Name"
                        item-value="Id"
                        persistent-hint
                        required
                        return-object
                        single-line
                        @input="onSelectOptionListItem"
                      >
                        <template #selection="data">
                          {{ getTranslatedField(data.item, 'Name') }}
                        </template>
                        <template #item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <div
                        v-if="editMenu.Details.Options.length<=0"
                        class="fill-height"
                        style="position: relative; height: 350px;"
                      >
                        <div
                          class="text-center grey--text pa-6"
                          style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                        >
                          <v-icon
                            class="mb-4"
                            color="grey lighten-2"
                            size="128"
                          >
                            {{ $t('Restaurant.MenuAddDialog.NoData.Icon') }}
                          </v-icon>
                          <div
                            class="text-h6 mb-2"
                            style="height: auto;"
                          >
                            {{ $t('Restaurant.MenuAddDialog.NoData.Title') }}
                          </div>
                          <div
                            class="text-subtitle-1"
                            style="height: auto;"
                          >
                            {{ $t('Restaurant.MenuAddDialog.NoData.Subtitle') }}
                          </div>
                        </div>
                      </div>

                      <v-tabs
                        v-else
                        v-model="dynamicTab"
                        background-color="grey darken-2"
                        class="mt-2 mb-2"
                        dark
                        show-arrows
                        slider-color="light-green"
                      >
                        <draggable
                          v-model="editMenu.Details.Options"
                          class="d-flex pa-0 ma-0"
                          handle=".my-handle"
                          @sort="onOptionSort"
                        >
                          <template v-for="(option, index) in editMenu.Details.Options">
                            <v-tab
                              :key="'dynamicTab' + index"
                              :href="'#dynamicTab' + index"
                              active-class="v-tabs__item--active grey darken-3"
                              class="px-0"
                            >
                              <v-icon
                                class="my-handle ml-1"
                                style="cursor: move; transform: rotate(90deg);"
                              >
                                drag_handle
                              </v-icon>

                              <v-spacer />

                              <span class="text-caption mx-2">
                                {{
                                  getTranslatedField(option, 'Name', 'Lang', true, 'OptionName') | sanitizeDiacritics | uppercase
                                }}<br>({{ option.DisplayType }})
                              </span>

                              <v-spacer />

                              <v-btn
                                class="mr-1"
                                color="grey"
                                icon
                                small
                                @click.prevent="removeTab(option, index)"
                              >
                                <v-icon
                                  color="white"
                                  small
                                >
                                  close
                                </v-icon>
                              </v-btn>
                            </v-tab>
                          </template>
                        </draggable>

                        <template v-for="(option, index) in editMenu.Details.Options">
                          <v-tab-item
                            v-if="dynamicTab === 'dynamicTab' + index"
                            :key="'dynamicTabItem' + index"
                            :value="'dynamicTab' + index"
                          >
                            <v-container
                              class="pa-1"
                              fluid
                            >
                              <v-row
                                dense
                                justify="space-between"
                              >
                                <v-col class="shrink">
                                  <div
                                    v-if="option.DisplayType === 'checkbox'"
                                    class="d-inline-block text-no-wrap mr-6"
                                  >
                                    <div class="text-caption grey--text text--darken-1 mt-2">
                                      {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Required') }}
                                    </div>

                                    <v-switch
                                      :id="option.Id + '_required'"
                                      v-model="option.Required"
                                      :label="option.Required ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                      class="ma-0 mt-1"
                                      color="success"
                                      hide-details
                                      inset
                                      @change="onRequiredChange(option)"
                                    />
                                  </div>
                                </v-col>

                                <v-col
                                  v-if="option.DisplayType !== 'unit' && option.DisplayType !== 'unit_value' && option.DisplayType !== 'extra_charges'"
                                  class="shrink"
                                >
                                  <div class="d-inline-block text-no-wrap mr-6">
                                    <div class="text-caption grey--text text--darken-1 mt-2">
                                      {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.DisplayName.Label') }}
                                    </div>

                                    <v-switch
                                      :id="option.Id + '_display_name'"
                                      v-model="option.DisplayName"
                                      :hint="$t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.DisplayName.Hint')"
                                      :label="option.DisplayName ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                      class="ma-0 mt-1"
                                      color="success"
                                      inset
                                      persistent-hint
                                    />
                                  </div>
                                </v-col>

                                <v-col v-if="option.DisplayType === 'checkbox'">
                                  <div class="mt-2 float-left">
                                    <div class="text-caption grey--text text--darken-1 ml-2">
                                      {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MinChoices.Label') }}
                                    </div>

                                    <v-btn
                                      class="grey--text elevation-0 ml-2"
                                      outlined
                                      style="min-width: 40px; max-width: 40px;"
                                      @click="onMinChoicesChange(option, -1)"
                                    >
                                      -
                                    </v-btn>

                                    <v-btn
                                      :class="isDarkMode ? 'grey darken-3' : 'white'"
                                      class="grey--text text elevation-0 mx-1"
                                      disabled
                                      outlined
                                      style="min-width: 50px; max-width: 100px;"
                                    >
                                      {{ option.MinChoices }}
                                    </v-btn>

                                    <v-btn
                                      class="grey--text elevation-0"
                                      outlined
                                      style="min-width: 40px; max-width: 40px;"
                                      @click="onMinChoicesChange(option, +1)"
                                    >
                                      +
                                    </v-btn>
                                    <br>
                                    <span
                                      class="text-caption grey--text ml-2"
                                      style="border-top: thin solid rgba(0,0,0,0.42);"
                                    >
                                      {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MinChoices.Hint') }}
                                    </span>
                                  </div>

                                  <div
                                    class="mt-2 ml-2 float-left"
                                    style="display: inline-block;"
                                  >
                                    <div class="text-caption grey--text text--darken-1 ml-2">
                                      {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MaxChoices.Label') }}
                                    </div>

                                    <v-btn
                                      class="grey--text elevation-0 ml-2"
                                      outlined
                                      style="min-width: 40px; max-width: 40px;"
                                      @click="onMaxChoicesChange(option, -1)"
                                    >
                                      -
                                    </v-btn>

                                    <v-btn
                                      :class="isDarkMode ? 'grey darken-3' : 'white'"
                                      class="grey--text elevation-0 mx-1"
                                      disabled
                                      outlined
                                      style="min-width: 50px; max-width: 100px;"
                                    >
                                      {{ option.MaxChoices }}
                                    </v-btn>

                                    <v-btn
                                      class="grey--text elevation-0"
                                      outlined
                                      style="min-width: 40px; max-width: 40px;"
                                      @click="onMaxChoicesChange(option, +1)"
                                    >
                                      +
                                    </v-btn>

                                    <br><span
                                      class="text-caption grey--text ml-2"
                                      style="border-top: thin solid rgba(0,0,0,0.42);"
                                    >
                                      {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MaxChoices.Hint') }}
                                    </span>
                                  </div>
                                </v-col>

                                <v-col
                                  v-show="option.DisplayType === 'radio' || option.DisplayType === 'unit' || option.DisplayType === 'unit_value'"
                                />

                                <v-col class="text-right shrink">
                                  <v-menu
                                    v-if="editMenu.tmpDetails.Options.findIndex(i => i.Name==editMenu.Details.Options[index].OptionName)>-1 && editMenu.tmpDetails.Options[editMenu.tmpDetails.Options.findIndex(i => i.Name === editMenu.Details.Options[index].OptionName)].OptionValues.length !== editMenu.Details.Options[index].OptionValues.length"
                                    v-model="option.showAddMenu"
                                    auto
                                    class="float-right"
                                    offset-y
                                  >
                                    <template #activator="{on}">
                                      <v-btn
                                        class="mt-4"
                                        color="green"
                                        dark
                                        v-on="on"
                                      >
                                        <span>{{
                                          $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.AddOptions')
                                        }}</span>
                                      </v-btn>
                                    </template>

                                    <v-list>
                                      <v-list-item
                                        v-for="item in editMenu.tmpDetails.Options[editMenu.tmpDetails.Options.findIndex(i => i.Name==editMenu.Details.Options[index].OptionName)].OptionValues.filter(e => !editMenu.Details.Options[index].OptionValues.find(a => e.Name === a.Name))"
                                        :key="Math.random() + '_' + item.Id + '_' + index"
                                        @click="addOptionRow(item, index)"
                                      >
                                        <v-list-item-title
                                          v-html="item.Name"
                                        />
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-col>

                                <v-col cols="12">
                                  <div
                                    v-if="option.OptionValues.length <= 0"
                                    class="fill-height"
                                    style="position: relative; height: 350px;"
                                  >
                                    <div
                                      class="text-center grey--text pa-6"
                                      style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                                    >
                                      <v-icon
                                        class="mb-4"
                                        color="grey lighten-2"
                                        size="128"
                                      >
                                        {{ $t('Restaurant.MenuAddDialog.NoData.Icon') }}
                                      </v-icon>

                                      <div
                                        class="text-h6 mb-2"
                                        style="height: auto;"
                                      >
                                        {{ $t('Restaurant.MenuAddDialog.NoData.Title') }}
                                      </div>

                                      <div
                                        class="text-subtitle-1"
                                        style="height: auto;"
                                      >
                                        {{ $t('Restaurant.MenuAddDialog.NoData.Subtitle') }}
                                      </div>
                                    </div>
                                  </div>

                                  <template v-else>
                                    <div v-if="option.DisplayType === 'radio'">
                                      <v-radio-group
                                        v-model="option.DefaultValueId"
                                        :mandatory="true"
                                        class="ma-0 pa-0"
                                        row
                                      >
                                        <div class="divTable">
                                          <div class="divTableHeading">
                                            <div class="divTableRow">
                                              <div class="divTableHead">
                                                &nbsp;
                                              </div>

                                              <div class="divTableHead">
                                                #
                                              </div>

                                              <div class="divTableHead">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Name')
                                                }}
                                              </div>

                                              <div class="divTableHead">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Price')
                                                }}
                                              </div>

                                              <div class="divTableHead text-center">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.ShowPrice')
                                                }}
                                              </div>

                                              <div class="divTableHead text-center">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Default')
                                                }}
                                              </div>

                                              <div class="divTableHead text-center">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Group')
                                                }}
                                              </div>

                                              <div class="divTableHead">
                                                &nbsp;
                                              </div>
                                            </div>
                                          </div>

                                          <draggable
                                            v-model="option.OptionValues"
                                            class="divTableBody"
                                            handle=".my-handle"
                                            @sort="onSort"
                                          >
                                            <div
                                              v-for="(optionValue, idx) in option.OptionValues"
                                              :key="idx"
                                              class="divTableRow"
                                            >
                                              <div class="divTableCell">
                                                <v-icon
                                                  class="my-handle"
                                                  style="cursor: move;"
                                                >
                                                  drag_handle
                                                </v-icon>
                                              </div>

                                              <div class="divTableCell">
                                                {{ optionValue.OptionValueId }}
                                              </div>

                                              <div
                                                class="divTableCell"
                                                style="min-width: 200px"
                                                v-html="getTranslatedField(optionValue, 'Name', 'Lang', true, 'Name')"
                                              />

                                              <div
                                                class="divTableCell"
                                                style="width: 100px;"
                                              >
                                                <v-text-field
                                                  v-model="optionValue.Price"
                                                  :append-icon="appCurrency.Icon"
                                                  :placeholder="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                                                  min="0"
                                                  required
                                                  single-line
                                                  step="any"
                                                  style="min-width: 70px;"
                                                  type="number"
                                                />
                                              </div>

                                              <div class="divTableCell text-center">
                                                <v-switch
                                                  :id="optionValue.Id + '_show_price'"
                                                  v-model="optionValue.ShowPrice"
                                                  :label="optionValue.ShowPrice ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                                  color="success"
                                                  hide-details
                                                  inset
                                                />
                                              </div>

                                              <div class="divTableCell text-center">
                                                <v-radio
                                                  :value="optionValue.Id"
                                                  hide-details
                                                  @change="onRadioValueChange(option, optionValue)"
                                                />
                                              </div>

                                              <div class="divTableCell text-center">
                                                <v-select
                                                  v-model="optionValue.GroupId"
                                                  :items="optionGroupsList"
                                                  :label="$t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.SelectGroup.Label')"
                                                  class="d-inline-block mr-2 pt-6"
                                                  clearable
                                                  hide-details
                                                  item-disabled="Disabled"
                                                  item-text="Name"
                                                  item-value="Id"
                                                  style="min-width: 100px; max-width: 200px;"
                                                  @change="onRadioValueChange(option, optionValue)"
                                                />

                                                <v-select
                                                  v-model="optionValue.LocalGroupId"
                                                  :items="optionLocalGroupsList"
                                                  :label="$t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.SelectLocalGroup.Label')"
                                                  class="d-inline-block mr-2 pt-6"
                                                  clearable
                                                  hide-details
                                                  item-disabled="Disabled"
                                                  item-text="Name"
                                                  item-value="Id"
                                                  style="min-width: 100px; max-width: 200px;"
                                                  @change="onRadioValueChange(option, optionValue)"
                                                />
                                              </div>

                                              <div
                                                class="divTableCell text-right"
                                                style="white-space: nowrap;"
                                              >
                                                <v-btn
                                                  v-if="userIsSuperAdmin"
                                                  v-show="optionValue.hasOwnProperty('MultiValues') && optionValue.MultiValues && optionValue.MultiValues!==''"
                                                  class="mr-1 orange"
                                                  dark
                                                  icon
                                                  small
                                                  @click="onClearOptionValueModifiers(optionValue)"
                                                >
                                                  <v-icon>remove</v-icon>
                                                </v-btn>

                                                <v-btn
                                                  v-if="userIsSuperAdmin"
                                                  :class="hasNewOptionsAdded ? 'grey' : 'blue'"
                                                  :disabled="hasNewOptionsAdded || !hasCheckboxOptions"
                                                  class="ml-0"
                                                  dark
                                                  icon
                                                  small
                                                  @click="onEditOptionValueClick(option, optionValue, index)"
                                                >
                                                  <v-icon small>
                                                    edit
                                                  </v-icon>
                                                </v-btn>

                                                <v-btn
                                                  class="ml-1 red"
                                                  dark
                                                  icon
                                                  small
                                                  @click="removeOptionRow(index, idx)"
                                                >
                                                  <v-icon small>
                                                    close
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </div>
                                          </draggable>
                                        </div>
                                      </v-radio-group>
                                    </div>

                                    <div v-if="option.DisplayType === 'unit'">
                                      <v-radio-group
                                        v-model="option.DefaultValueId"
                                        :mandatory="true"
                                        class="ma-0 pa-0"
                                        row
                                      >
                                        <div class="divTable">
                                          <div class="divTableHeading">
                                            <div class="divTableRow">
                                              <div class="divTableHead">
                                                &nbsp;
                                              </div>

                                              <div class="divTableHead">
                                                #
                                              </div>

                                              <div class="divTableHead">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Name')
                                                }}
                                              </div>

                                              <div class="divTableHead">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Quantity')
                                                }}
                                              </div>

                                              <div class="divTableHead text-center">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Default')
                                                }}
                                              </div>

                                              <div class="divTableHead">
                                                &nbsp;
                                              </div>
                                            </div>
                                          </div>

                                          <draggable
                                            v-model="option.OptionValues"
                                            class="divTableBody"
                                            handle=".my-handle"
                                            @sort="onSort"
                                          >
                                            <div
                                              v-for="(optionValue, idx) in option.OptionValues"
                                              :key="idx"
                                              class="divTableRow"
                                            >
                                              <div class="divTableCell">
                                                <v-icon
                                                  class="my-handle"
                                                  style="cursor: move;"
                                                >
                                                  drag_handle
                                                </v-icon>
                                              </div>

                                              <div class="divTableCell">
                                                {{ optionValue.OptionValueId }}
                                              </div>

                                              <div
                                                class="divTableCell"
                                                style="min-width: 200px"
                                                v-html="getTranslatedField(optionValue, 'Name', 'Lang', true, 'Name')"
                                              />

                                              <div
                                                class="divTableCell"
                                                style="width: 100px;"
                                              >
                                                <v-text-field
                                                  v-model="optionValue.Price"
                                                  :append-icon="'mdi-counter'"
                                                  :placeholder="$t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Quantity')"
                                                  disabled
                                                  min="0"
                                                  required
                                                  single-line
                                                  step="any"
                                                  style="min-width: 120px;"
                                                  type="number"
                                                />
                                              </div>

                                              <div class="divTableCell text-center">
                                                <v-radio
                                                  :value="optionValue.Id"
                                                  hide-details
                                                  @change="onRadioValueChange(option, optionValue)"
                                                />
                                              </div>

                                              <div
                                                class="divTableCell text-right"
                                                style="white-space: nowrap;"
                                              >
                                                <v-btn
                                                  class="ml-1 red"
                                                  dark
                                                  icon
                                                  small
                                                  @click="removeOptionRow(index, idx)"
                                                >
                                                  <v-icon small>
                                                    close
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </div>
                                          </draggable>
                                        </div>
                                      </v-radio-group>
                                    </div>

                                    <div v-if="option.DisplayType === 'unit_value'">
                                      <v-radio-group
                                        v-model="option.DefaultValueId"
                                        :mandatory="true"
                                        class="ma-0 pa-0"
                                        row
                                      >
                                        <div class="divTable">
                                          <div class="divTableHeading">
                                            <div class="divTableRow">
                                              <div class="divTableHead">
                                                &nbsp;
                                              </div>

                                              <div class="divTableHead">
                                                #
                                              </div>

                                              <div class="divTableHead">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Name')
                                                }}
                                              </div>

                                              <div class="divTableHead">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Quantity')
                                                }}
                                              </div>

                                              <div class="divTableHead text-center">
                                                {{
                                                  $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Default')
                                                }}
                                              </div>

                                              <div class="divTableHead">
                                                &nbsp;
                                              </div>
                                            </div>
                                          </div>

                                          <draggable
                                            v-model="option.OptionValues"
                                            class="divTableBody"
                                            handle=".my-handle"
                                            @sort="onSort"
                                          >
                                            <div
                                              v-for="(optionValue, idx) in option.OptionValues"
                                              :key="idx"
                                              class="divTableRow"
                                            >
                                              <div class="divTableCell">
                                                <v-icon
                                                  class="my-handle"
                                                  style="cursor: move;"
                                                >
                                                  drag_handle
                                                </v-icon>
                                              </div>

                                              <div class="divTableCell">
                                                {{ optionValue.OptionValueId }}
                                              </div>

                                              <div
                                                class="divTableCell"
                                                style="min-width: 200px"
                                                v-html="getTranslatedField(optionValue, 'Name', 'Lang', true, 'Name')"
                                              />

                                              <div
                                                class="divTableCell"
                                                style="width: 100px;"
                                              >
                                                <v-text-field
                                                  v-model="optionValue.Price"
                                                  :append-icon="'mdi-counter'"
                                                  :placeholder="$t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Quantity')"
                                                  min="0"
                                                  required
                                                  single-line
                                                  step="any"
                                                  style="min-width: 120px;"
                                                  type="number"
                                                />
                                              </div>

                                              <div class="divTableCell text-center">
                                                <v-radio
                                                  :value="optionValue.Id"
                                                  hide-details
                                                  @change="onRadioValueChange(option, optionValue)"
                                                />
                                              </div>

                                              <div
                                                class="divTableCell text-right"
                                                style="white-space: nowrap;"
                                              >
                                                <v-btn
                                                  class="ml-1 red"
                                                  dark
                                                  icon
                                                  small
                                                  @click="removeOptionRow(index, idx)"
                                                >
                                                  <v-icon small>
                                                    close
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </div>
                                          </draggable>
                                        </div>
                                      </v-radio-group>
                                    </div>

                                    <div v-else-if="option.DisplayType === 'checkbox'">
                                      <div class="divTable">
                                        <div class="divTableHeading">
                                          <div class="divTableRow">
                                            <div class="divTableHead">
                                              &nbsp;
                                            </div>
                                            <div class="divTableHead">
                                              #
                                            </div>
                                            <div class="divTableHead">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Name')
                                              }}
                                            </div>
                                            <div class="divTableHead">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Price')
                                              }}
                                            </div>
                                            <div class="divTableHead">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.ShowPrice')
                                              }}
                                            </div>
                                            <div class="divTableHead text-center">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Default')
                                              }}
                                            </div>
                                            <div class="divTableHead text-center">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Required')
                                              }}
                                            </div>
                                            <div class="divTableHead text-center">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Group')
                                              }}
                                            </div>
                                            <div class="divTableHead">
                                              &nbsp;
                                            </div>
                                          </div>
                                        </div>

                                        <draggable
                                          v-model="option.OptionValues"
                                          class="divTableBody"
                                          handle=".my-handle"
                                          @sort="onSort"
                                        >
                                          <div
                                            v-for="(optionValue, idx) in option.OptionValues"
                                            :key="idx"
                                            class="divTableRow"
                                          >
                                            <div class="divTableCell">
                                              <v-icon
                                                class="my-handle"
                                                style="cursor: move;"
                                              >
                                                drag_handle
                                              </v-icon>
                                            </div>

                                            <div class="divTableCell">
                                              {{ optionValue.OptionValueId }}
                                            </div>

                                            <div
                                              class="divTableCell"
                                              style="min-width: 200px"
                                              v-html="getTranslatedField(optionValue, 'Name', 'Lang', true, 'Name')"
                                            />

                                            <div
                                              class="divTableCell"
                                              style="width: 100px;"
                                            >
                                              <v-text-field
                                                v-model="optionValue.Price"
                                                :append-icon="appCurrency.Icon"
                                                :placeholder="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                                                min="0"
                                                required
                                                single-line
                                                step="any"
                                                style="min-width: 70px;"
                                                type="number"
                                              />
                                            </div>

                                            <div class="divTableCell text-center">
                                              <v-switch
                                                :id="optionValue.Id + '_show_price'"
                                                v-model="optionValue.ShowPrice"
                                                :label="optionValue.ShowPrice ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                                color="success"
                                                hide-details
                                                inset
                                              />
                                            </div>

                                            <div class="divTableCell text-center">
                                              <v-switch
                                                :id="index + '_' + idx + '_' + optionValue.Id + '_optionValue_selected'"
                                                v-model="optionValue.Selected"
                                                :disabled="optionValue.Mandatory"
                                                :false-value="false"
                                                :label="optionValue.Selected ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                                :true-value="true"
                                                color="success"
                                                hide-details
                                                inset
                                              />
                                            </div>

                                            <div class="divTableCell text-center">
                                              <v-switch
                                                :id="index + '_' + idx + '_' + optionValue.Id + '_optionValue_mandatory'"
                                                v-model="optionValue.Mandatory"
                                                :false-value="false"
                                                :label="optionValue.Mandatory ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                                :true-value="true"
                                                color="success"
                                                hide-details
                                                inset
                                                @change="optionValue.Mandatory ? optionValue.Selected = true : ''"
                                              />
                                            </div>

                                            <div class="divTableCell text-center">
                                              <v-select
                                                v-model="optionValue.GroupId"
                                                :items="optionGroupsList"
                                                :label="$t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.SelectGroup.Label')"
                                                class="d-inline-block mr-2 pt-6"
                                                clearable
                                                hide-details
                                                item-disabled="Disabled"
                                                item-text="Name"
                                                item-value="Id"
                                                style="min-width: 100px; max-width: 200px;"
                                              />

                                              <v-select
                                                v-model="optionValue.LocalGroupId"
                                                :items="optionLocalGroupsList"
                                                :label="$t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.SelectLocalGroup.Label')"
                                                class="d-inline-block mr-2 pt-6"
                                                clearable
                                                hide-details
                                                item-disabled="Disabled"
                                                item-text="Name"
                                                item-value="Id"
                                                style="min-width: 100px; max-width: 200px;"
                                              />
                                            </div>

                                            <div
                                              class="divTableCell text-center"
                                              style="white-space: nowrap;"
                                            >
                                              <v-btn
                                                class="red ml-1"
                                                dark
                                                icon
                                                left
                                                small
                                                @click="removeOptionRow(index, idx)"
                                              >
                                                <v-icon small>
                                                  close
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </div>
                                        </draggable>
                                      </div>
                                    </div>

                                    <div v-else-if="option.DisplayType === 'extra_charges'">
                                      <div class="divTable">
                                        <div class="divTableHeading">
                                          <div class="divTableRow">
                                            <div class="divTableHead">
                                              &nbsp;
                                            </div>
                                            <div class="divTableHead">
                                              #
                                            </div>
                                            <div class="divTableHead">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Name')
                                              }}
                                            </div>
                                            <div class="divTableHead">
                                              {{
                                                $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Price')
                                              }}
                                            </div>
                                            <div class="divTableHead">
                                              &nbsp;
                                            </div>
                                          </div>
                                        </div>

                                        <draggable
                                          v-model="option.OptionValues"
                                          class="divTableBody"
                                          handle=".my-handle"
                                          @sort="onSort"
                                        >
                                          <div
                                            v-for="(optionValue, idx) in option.OptionValues"
                                            :key="idx"
                                            class="divTableRow"
                                          >
                                            <div class="divTableCell">
                                              <v-icon
                                                class="my-handle"
                                                style="cursor: move;"
                                              >
                                                drag_handle
                                              </v-icon>
                                            </div>

                                            <div class="divTableCell">
                                              {{ optionValue.OptionValueId }}
                                            </div>

                                            <div
                                              class="divTableCell"
                                              style="min-width: 200px"
                                              v-html="getTranslatedField(optionValue, 'Name', 'Lang', true, 'Name')"
                                            />

                                            <div
                                              class="divTableCell"
                                              style="width: 100px;"
                                            >
                                              <v-text-field
                                                v-model="optionValue.Price"
                                                :append-icon="appCurrency.Icon"
                                                :placeholder="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                                                min="0"
                                                required
                                                single-line
                                                step="any"
                                                style="min-width: 70px;"
                                                type="number"
                                              />
                                            </div>

                                            <div
                                              class="divTableCell text-center"
                                              style="white-space: nowrap;"
                                            >
                                              <v-btn
                                                class="red ml-1"
                                                dark
                                                icon
                                                left
                                                small
                                                @click="removeOptionRow(index, idx)"
                                              >
                                                <v-icon small>
                                                  close
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </div>
                                        </draggable>
                                      </div>
                                    </div>
                                  </template>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-tab-item>
                        </template>
                      </v-tabs>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="activeTab === 'tab5'"
                key="tab5"
                value="tab5"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        v-model="selectedExtraChargeListItem"
                        :hint="$t('Restaurant.MenuAddDialog.Field.SelectExtraCharge.Hint')"
                        :items="extraCharges"
                        :label="$t('Restaurant.MenuAddDialog.Field.SelectExtraCharge.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.SelectExtraCharge.Icon')"
                        class="pr-2"
                        item-text="Name"
                        item-value="Id"
                        persistent-hint
                        required
                        return-object
                        single-line
                        @change="addExtraCharge"
                      >
                        <template #selection="data">
                          {{ getTranslatedField(data.item, 'Name') }}
                        </template>
                        <template #item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <div class="divTable">
                      <div class="divTableHeading">
                        <div class="divTableRow">
                          <div class="divTableHead">
                            &nbsp;
                          </div>
                          <div class="divTableHead">
                            #
                          </div>
                          <div class="divTableHead">
                            {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Name') }}
                          </div>
                          <div class="divTableHead">
                            {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Price') }}
                          </div>
                          <div class="divTableHead">
                            {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Quantity') }}
                          </div>
                          <div class="divTableHead">
                            &nbsp;
                          </div>
                        </div>
                      </div>

                      <draggable
                        v-model="editMenu.Details.Charges"
                        class="divTableBody"
                        handle=".my-handle"
                        @sort="onChargesSort"
                      >
                        <div
                          v-for="(charge, idx) in editMenu.Details.Charges"
                          :key="idx"
                          class="divTableRow"
                        >
                          <div class="divTableCell">
                            <v-icon
                              class="my-handle"
                              style="cursor: move;"
                            >
                              drag_handle
                            </v-icon>
                          </div>

                          <div class="divTableCell">
                            {{ charge.Id }}
                          </div>

                          <div
                            class="divTableCell"
                            style="min-width: 200px"
                            v-html="getTranslatedField(charge, 'Name', 'Lang')"
                          />

                          <div class="divTableCell">
                            {{ charge.PriceFmt }}
                          </div>

                          <div
                            class="divTableCell"
                            style="width: 100px;"
                          >
                            <v-text-field
                              v-model="charge.Quantity"
                              :placeholder="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                              append-icon="mdi-counter"
                              min="0"
                              required
                              single-line
                              step="1"
                              style="min-width: 70px;"
                              type="number"
                            />
                          </div>

                          <div
                            class="divTableCell text-center"
                            style="white-space: nowrap;"
                          >
                            <v-btn
                              class="red ml-1"
                              dark
                              icon
                              left
                              small
                              @click="removeExtraCharge(idx)"
                            >
                              <v-icon small>
                                close
                              </v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions
          :class="[{'text-center': $vuetify.breakpoint.xsOnly}]"
          :style="$vuetify.breakpoint.xsOnly ? 'display: block;' : ''"
          class="pa-4"
        >
          <translate-all-fields-button
            v-if="selectedLanguages.length > 1"
            :disabled="loading"
            :item="editMenu"
            :locale-from="defaultLanguage.Code"
            :field="['Name', 'Description', 'LongDescription']"
          />

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1 mb-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0 mb-1"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            v-if="activeTab !== 'tab5'"
            class="light-green--text text--darken-1 mb-1"
            outlined
            @click="onNextButtonClick"
          >
            {{ $t('Common.Button.Next').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>

    <menu-stock-update-dialog
      v-if="editMenu && editMenu.SubtractStock && editMenu.Details"
      :visible.sync="stockUpdateDialogVisible"
      :data="selectedStockDayTimeUpdate"
      :unit-id="editMenu.Details.UnitId"
      @save="saveStockUpdateTime"
    />

    <menu-status-update-dialog
      v-if="editMenu && editMenu.Details"
      :visible.sync="statusUpdateDialogVisible"
      :data="selectedStatusDayTimeUpdate"
      @save="saveStatusUpdateTime"
    />

    <menu-option-value-modifiers-dialog
      v-if="userIsSuperAdmin"
      :menu-to-edit="JSON.parse(JSON.stringify(newMenu))"
      :option-tab-to-edit="selectedOptionTabToEdit"
      :option-to-edit="selectedOptionToEdit"
      :option-value-to-edit.sync="selectedOptionValueToEdit"
      :visible.sync="editMenuOptionValueModifiersDialogVisible"
    />
  </div>
</template>

<script>
import AppData                        from '@/mixins/appdata'
import Auth                           from '@/mixins/auth'
import Resizable                      from '@/mixins/resizable'
import Watchable                      from '@/mixins/watchable'
import Translatable                   from '@/mixins/translatable'
import MenuOptionValueModifiersDialog from './MenuOptionValueModifiersDialog'
import draggable                      from 'vuedraggable'
import ImageCropSelector              from '@/components/common/ImageCropSelector'
import TaxesCommon                    from '@/mixins/taxes/taxesCommon'
import { clone, mergeDeep }           from '@/lib/helper/helper'
import { getContrastColor }           from '@/lib/color'
import MenuStockUpdateDialog          from '@/components/restaurant/kitchen/MenuStockUpdateDialog.vue'
import MenuStatusUpdateDialog         from '@/components/restaurant/kitchen/MenuStatusUpdateDialog.vue'
import TranslateFieldButton           from '@/components/common/translation/translateFieldButton.vue'
import TranslateAllFieldsButton       from '@/components/common/translation/translateAllFieldsButton.vue'

export default {
  name      : 'MenuAddDialog',
  components: {
    TranslateAllFieldsButton,
    TranslateFieldButton,
    MenuStatusUpdateDialog,
    'menu-option-value-modifiers-dialog': MenuOptionValueModifiersDialog,
    'image-crop-selector'               : ImageCropSelector,
    draggable                           : draggable,
    MenuStockUpdateDialog
  },
  mixins: [AppData, Auth, TaxesCommon, Resizable, Translatable, Watchable],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    menuToEdit: {
      type   : Object,
      default: undefined
    },
    isMenuItemEdit: {
      type   : Boolean,
      default: false
    },
    taxes: {
      type   : Array,
      default: () => []
    },
    tags: {
      type   : Array,
      default: () => []
    },
    catalogs: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      croppa                                   : null,
      loading                                  : false,
      isDirty                                  : false,
      hasNewOptionsAdded                       : false,
      editMenuOptionValueModifiersDialogVisible: false,
      selectedOptionToEdit                     : null,
      selectedOptionValueToEdit                : null,
      selectedOptionTabToEdit                  : null,
      tmpImage                                 : null,
      tmpImageBase64                           : null,
      showAddMenu                              : false,
      activeTab                                : 'tab1',
      activeAutomationTab                      : 'automationTab1',
      dynamicTab                               : 'dynamicTab1',
      languageTab                              : 0,
      menuFormValid                            : false,
      menuSpecialStartDateVisible              : false,
      menuSpecialEndDateVisible                : false,
      selectedOptionListItem                   : null,
      selectedExtraChargeListItem              : null,
      categories                               : [],
      selectedCategories                       : [],
      optionGroups                             : [],
      mealtimes                                : [],
      optionsList                              : [],
      newMenu                                  : null,
      Weekdays                                 : this.$t('Common.Date.WeekDays.Long'),
      stockUpdateDialogVisible                 : false,
      statusUpdateDialogVisible                : false,
      selectedStockDayTimeUpdate               : null,
      selectedStatusDayTimeUpdate              : null,
      validationRules                          : {
        menuForm: {
          Name: [
            (v) => (this.selectedLanguages.length > 1 ? this.selectedLanguages.some(lang => this.editMenu.Lang[lang.Code].Name) : true) || this.$t('Restaurant.MenuAddDialog.Field.Name.Error.AtLeastOneLang'),
            (v) => (this.selectedLanguages.length > 1 ? !v || (v.length >= 2 && v.length <= 255) : v && (v.length >= 2 && v.length <= 255)) || this.$t('Restaurant.MenuAddDialog.Field.Name.Error.Between')
          ],
          Price: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Restaurant.MenuAddDialog.Field.Price.Error.Valid')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 500) || this.$t('Restaurant.MenuAddDialog.Field.Description.Error.Between')
          ],
          Category: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.MenuAddDialog.Field.Categories.Error.Required'),
            (v) => v && v.length >= 1 && v.length <= 20 || this.$t('Restaurant.MenuAddDialog.Field.Categories.Error.Max')
          ],
          DefaultCategory: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Error.Required')
          ],
          StockQuantity: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Restaurant.MenuAddDialog.Field.Stock.Error.Valid')
          ],
          MinimumQuantity: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Restaurant.MenuAddDialog.Field.MinimumQuantity.Error.Valid')
          ],
          Priority: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Restaurant.MenuAddDialog.Field.Priority.Error.Valid')
          ],
          Catalogs: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.MenuAddDialog.Field.Catalogs.Error.Required')
          ],
          PrintGroup: [
            // (v) => !this.preparationDocumentEnabled || (this.preparationDocumentEnabled && !!v && v.length >= 1) || this.$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Error.Required')
          ],
          SpecialPrice: [
            (v) => !this.editMenu.Details.SpecialStatus || !!v || this.$t('Restaurant.MenuAddDialog.Field.Discount.Field.DiscountPrice.Error.Required'),
            (v) => !this.editMenu.Details.SpecialStatus || (!!v && v.length >= 1 && !isNaN(v)) || this.$t('Restaurant.MenuAddDialog.Field.Discount.Field.DiscountPrice.Error.Valid')
          ],
          SpecialStartDate: [
            (v) => !this.editMenu.Details.SpecialStatus || !!v || this.$t('Restaurant.MenuAddDialog.Field.Discount.Field.StartDate.Error.Required')
          ],
          SpecialEndDate: [
            (v) => !this.editMenu.Details.SpecialStatus || !!v || this.$t('Restaurant.MenuAddDialog.Field.Discount.Field.EndDate.Error.Required')
          ],

          TaxId: [
            (v) => !!v || this.$t('Restaurant.CategoryAddDialog.Field.Tax.Error.Required')
          ],
          Tax2Id: [
            (v) => !this.appSupportSecondTax || (this.posUserCan('Tables', 'ACCESS') && !!v) || this.$t('Restaurant.CategoryAddDialog.Field.Tax2.Error.Required')
          ],

          ClassificationTypeCode: [
            (v) => !this.appSupportIncomeClassifications || (!!v) || this.$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Error.Required')
          ],
          ClassificationCategoryCode: [
            (v) => !this.appSupportIncomeClassifications || (!!v) || this.$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    measurementUnitsArray () {
      return this.$t('Restaurant.MenuAddDialog.Field.Unit.Units') // this.appConfig?.MEASUREMENT_UNITS || []
    },

    extraCharges () {
      const charges = this.appConfig?.EXTRA_CHARGES || []
      return charges.filter(charge => !this.editMenu?.Details?.Charges?.find(c => c.Id === charge.Id))
    },

    optionGroupsList () {
      let groupId = null
      const options = this.editMenu.Details.Options
      options.forEach(option => {
        option.OptionValues.forEach(optionValue => {
          if (optionValue.GroupId) groupId = optionValue.GroupId
        })
      })

      return this.optionGroups.map(optionGroup => {
        return {
          Id      : optionGroup.Id,
          Name    : optionGroup.Name,
          Disabled: groupId ? !(optionGroup.Id === groupId) : false
        }
      })
    },

    optionLocalGroupsList () {
      let dynamicTab = null
      let groupId = null
      const options = this.editMenu.Details.Options
      options.forEach((option, index) => {
        dynamicTab = 'dynamicTab' + index
        if (dynamicTab === this.dynamicTab) {
          option.OptionValues.forEach(optionValue => {
            if (optionValue.LocalGroupId) groupId = optionValue.LocalGroupId
          })
        }
      })

      return this.optionGroups.map(optionGroup => {
        return {
          Id      : optionGroup.Id,
          Name    : optionGroup.Name,
          Disabled: groupId ? !(optionGroup.Id === groupId) : false
        }
      })
    },

    catalogCategories () {
      return this.categories.filter(category => category.Catalogs.some(catalogId => this.editMenu.Catalogs.includes(catalogId)))
    },

    catalogsInternal () {
      return this.catalogs.filter(catalog => !catalog.Api)
    },

    catalogsExternal () {
      return this.catalogs.filter(catalog => !!catalog.Api)
    },

    preparationDocumentEnabled () {
      return this.appConfig?.LOCATION_DATA?.PreparationDocumentEnabled ?? false
    },

    hasCheckboxOptions () {
      return this.editMenu.Details.Options.filter(o => o.DisplayType === 'checkbox' || o.DisplayType === 'extra_charges').length > 0
    },

    isVisible: {
      get () {
        return this.visible
      },

      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editMenu: {
      get () {
        if (this.menuToEdit) {
          const payload = clone(this.menuToEdit)

          const langObj = {}
          for (const lang of this.selectedLanguages) {
            langObj[lang.Code] = {
              Name           : '',
              Description    : '',
              LongDescription: '',
              Locale         : lang.Code
            }
          }
          if (!payload.Lang) payload.Lang = {}
          payload.Lang = Object.assign({}, langObj, payload.Lang)

          if (this.catalogs.length === 1) {
            payload.Catalogs = [this.catalogs[0].Id]
          }

          const stockDays = mergeDeep({
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: []
          }, payload.Details?.ResetStockValues || {})

          const statusDays = mergeDeep({
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: []
          }, payload.Details?.ResetStatusValues || {})

          for (let i = 1; i <= 7; i++) {
            stockDays[i] = stockDays[i].sort((a, b) => a.time.localeCompare(b.time))
            statusDays[i] = statusDays[i].sort((a, b) => a.time.localeCompare(b.time))
          }
          if (payload.Details) payload.Details.ResetStockValues = stockDays
          if (payload.Details) payload.Details.ResetStatusValues = statusDays

          this.$set(this, 'newMenu', payload)
        }

        return this.newMenu
      }
    }
  },

  watch: {
    activeTab () {
      this.activeAutomationTab = 'automationTab1'
    },
    'editMenu.SubtractStock': function (newVal) {
      if (!newVal && this.editMenu.Details) this.editMenu.Details.ResetStock = false
    },
    'editMenu.Catalogs': function () {
      this.editMenu.Categories = this.editMenu.Categories.filter(categoryId => this.catalogCategories.find(category => category.Id === categoryId))
    },
    'editMenu.Categories': function (newVal) {
      this.updateSelectedCategories()
      this.editMenu.CategoryId = this.selectedCategories.find(category => parseInt(category.Id) === parseInt(this.editMenu.CategoryId))?.Id || null
    },
    'editMenu.TaxId': function (newVal) {
      const tax = this.taxesList.find(t => t.Id === newVal)
      this.editMenu.TaxLang = tax?.Lang || null
      this.editMenu.TaxRate = tax?.Rate || null

      if (this.editMenu.Details) {
        this.editMenu.Details.TaxId = newVal
        this.editMenu.Details.TaxLang = tax?.Lang || null
        this.editMenu.Details.TaxRate = tax?.Rate || null
      }
    },
    'editMenu.Tax2Id': function (newVal) {
      const tax = this.taxesList.find(t => t.Id === newVal)
      this.editMenu.Tax2Lang = tax?.Lang || null
      this.editMenu.Tax2Rate = tax?.Rate || null

      if (this.editMenu.Details) {
        this.editMenu.Details.Tax2Id = newVal
        this.editMenu.Details.Tax2Lang = tax?.Lang || null
        this.editMenu.Details.Tax2Rate = tax?.Rate || null
      }
    },

    isVisible: function (newVal) {
      this.editMenu.tmpDetails = clone(this.editMenu.Details)
      this.editMenu.tmpDetails.Options = clone(this.optionsList)

      this.isDirty = false
      this.hasNewOptionsAdded = false
      this.loading = false
      this.languageTab = 0
      if (newVal) {
        this.updateSelectedCategories()
        this.$nextTick(() => {
          if (this.$refs.menuForm) this.$refs.menuForm.resetValidation()
          this.watch('editMenu', this.onEditMenuChange, true, false)
        })
      } else {
        this.selectedCategories = []
        this.unwatch('editMenu')
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Menu.Save, this.onSaveResult)
    this.$bus.$on(window.SocketCommand.MealTimes.All, this.setMealTimesList)
    this.$bus.$on(window.SocketCommand.Category.All, this.setCategoriesList)
    this.$bus.$on(window.SocketCommand.Menu.Options.All, this.setOptionsList)
    this.$bus.$on('menu-option-value-groups-update-data-items', this.setMenuOptionGroups)
    window.callAS(window.SocketCommand.Category.All, {
      config    : 0,
      page_limit: -1
    })
    window.callAS(window.SocketCommand.Menu.Options.All, {
      config    : 0,
      page_limit: -1
    })
    window.callAS(window.SocketCommand.Menu.OptionValueGroup.All, {
      config    : 0,
      page_limit: -1
    }, 'menu-option-value-groups-update-data-items')
    window.callAS(window.SocketCommand.MealTimes.All, {
      config    : 0,
      page_limit: -1
    })
    this.activeTab = 'tab1'
    this.dynamicTab = 'dynamicTab0'
    this.activeAutomationTab = 'automationTab1'
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Menu.Save, this.onSaveResult)
    this.$bus.$off(window.SocketCommand.MealTimes.All, this.setMealTimesList)
    this.$bus.$off(window.SocketCommand.Category.All, this.setCategoriesList)
    this.$bus.$off(window.SocketCommand.Menu.Options.All, this.setOptionsList)
    this.$bus.$off('menu-option-value-groups-update-data-items', this.setMenuOptionGroups)
  },
  methods: {
    getContrastColor,

    getProductLangField (fieldName) {
      const lang = this.editMenu?.Details?.Lang[this.$i18n.locale]
      return (lang && lang[fieldName]) || this.editMenu?.Details[fieldName] || ''
    },

    getResetStockValueDay (dayIdx) {
      return this.editMenu?.Details?.ResetStockValues[dayIdx + 1] || []
    },
    removeStockUpdateDayTime (dayIdx, idx) {
      const dayArr = this.getResetStockValueDay(dayIdx)

      if (!Array.isArray(dayArr) || !dayArr.length || idx < 0) return
      dayArr.splice(idx, 1)
    },
    addStockUpdateDayTime (dayIdx) {
      const dayArr = this.getResetStockValueDay(dayIdx)
      this.editStockUpdateTime(dayIdx, dayArr.length)
    },
    editStockUpdateTime (dayIdx, timeIdx) {
      const dayArr = this.getResetStockValueDay(dayIdx)

      this.selectedStockDayTimeUpdate = {
        data   : dayArr[timeIdx],
        dayIdx : dayIdx,
        timeIdx: timeIdx,
        week   : false
      }
      this.stockUpdateDialogVisible = true
    },
    addStockUpdateWeekTime () {
      this.selectedStockDayTimeUpdate = {
        data   : [],
        dayIdx : 0,
        timeIdx: 0,
        week   : true
      }
      this.stockUpdateDialogVisible = true
    },
    saveStockUpdateTime (value, dayIdx, timeIdx, week = false) {
      if (week) {
        for (let i = 0; i <= 6; i++) {
          const dayArr = this.getResetStockValueDay(i)
          dayArr.push(value)
        }
      } else {
        const dayArr = this.getResetStockValueDay(dayIdx)
        dayArr[timeIdx] = value
      }

      const days = clone(this.editMenu.Details.ResetStockValues)
      for (let i = 1; i <= 7; i++) {
        days[i] = days[i].sort((a, b) => a.time.localeCompare(b.time))
      }
      this.editMenu.Details.ResetStockValues = days
    },

    getResetStatusValueDay (dayIdx) {
      return this.editMenu?.Details?.ResetStatusValues[dayIdx + 1] || []
    },
    removeStatusUpdateDayTime (dayIdx, idx) {
      const dayArr = this.getResetStatusValueDay(dayIdx)

      if (!Array.isArray(dayArr) || !dayArr.length || idx < 0) return
      dayArr.splice(idx, 1)
    },
    addStatusUpdateDayTime (dayIdx) {
      const dayArr = this.getResetStatusValueDay(dayIdx)
      this.editStatusUpdateTime(dayIdx, dayArr.length)
    },
    editStatusUpdateTime (dayIdx, timeIdx) {
      const dayArr = this.getResetStatusValueDay(dayIdx)

      this.selectedStatusDayTimeUpdate = {
        data   : dayArr[timeIdx],
        dayIdx : dayIdx,
        timeIdx: timeIdx,
        week   : false
      }
      this.statusUpdateDialogVisible = true
    },
    addStatusUpdateWeekTime () {
      this.selectedStatusDayTimeUpdate = {
        data   : [],
        dayIdx : 0,
        timeIdx: 0,
        week   : true
      }
      this.statusUpdateDialogVisible = true
    },
    saveStatusUpdateTime (value, dayIdx, timeIdx, week = false) {
      if (week) {
        for (let i = 0; i <= 6; i++) {
          const dayArr = this.getResetStatusValueDay(i)
          dayArr.push(value)
        }
      } else {
        const dayArr = this.getResetStatusValueDay(dayIdx)
        dayArr[timeIdx] = value
      }

      const days = clone(this.editMenu.Details.ResetStatusValues)
      for (let i = 1; i <= 7; i++) {
        days[i] = days[i].sort((a, b) => a.time.localeCompare(b.time))
      }
      this.editMenu.Details.ResetStatusValues = days
    },

    onUnitChange () {
      this.editMenu.Details.UnitQuantity = this.getMeasurementUnitById(this.editMenu.Details.UnitId).Rate
      if (this.editMenu.Details.UnitId > 1) {
        this.editMenu.Details.MenuCustomPrice = true
        this.editMenu.Details.SpecialStatus = this.editMenu.SpecialStatus = false
      }
    },

    getCategoryCatalog (menuItem) {
      return this.editMenu?.Catalogs?.length > 1 ? this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => menuItem.Catalogs?.includes(catalog.Id))?.map(c => c.Name)?.join(', ') || '' : ''
    },

    onEditOptionValueClick (option, optionValue, optionTab) {
      this.$set(this.newMenu.Details, 'OptionsCopy', JSON.parse(JSON.stringify(this.newMenu.Details.Options)))
      this.selectedOptionToEdit = option
      this.selectedOptionValueToEdit = optionValue
      this.selectedOptionTabToEdit = optionTab
      this.editMenuOptionValueModifiersDialogVisible = true
    },
    onClearOptionValueModifiers (optionValue) {
      optionValue.MultiValues = ''
    },
    onRequiredChange (option) {
      if (option.Required) {
        if (option.MinChoices < 1) {
          option.MinChoices = 1
        }
      } else {
        option.MinChoices = 0
      }
    },
    onMinChoicesChange (option, num) {
      option.MinChoices += num
      if (option.MinChoices < 0) option.MinChoices = 0
      if (option.MaxChoices > 0 && option.MinChoices > option.MaxChoices) option.MaxChoices = option.MinChoices
      if (option.MinChoices > option.OptionValues.length) option.MinChoices = option.OptionValues.length
      if (option.MaxChoices > option.OptionValues.length) option.MaxChoices = option.OptionValues.length
      option.Required = option.MinChoices > 0
    },
    onMaxChoicesChange (option, num) {
      option.MaxChoices += num
      if (option.MaxChoices < 0) option.MaxChoices = 0
      if (option.MinChoices > 0 && option.MaxChoices < option.MinChoices) option.MinChoices = option.MaxChoices
      if (option.MinChoices > option.OptionValues.length) option.MinChoices = option.OptionValues.length
      if (option.MaxChoices > option.OptionValues.length) option.MaxChoices = option.OptionValues.length
      option.Required = option.MinChoices > 0
    },
    onRadioValueChange (option, optionValueItem) {
      this.$nextTick(() => {
        option.DefaultValueId = optionValueItem.Id
        option.OptionValues.forEach((optionValue) => {
          if (optionValue.Id !== option.DefaultValueId) {
            optionValue.Selected = false
            // optionValue.GroupId = ''
          } else {
            optionValue.Selected = true
          }
        })

        const optionIndex = option.OptionValues.findIndex(o => o.Selected)
        if (optionIndex === -1 && option.OptionValues.length > 0) option.OptionValues[0].Selected = true
      })
    },
    onSaveButtonClick () {
      this.$refs.menuForm.validate()

      this.calculateOptionsPrice()

      this.$nextTick(() => {
        if (this.menuFormValid) {
          this.loading = true
          this.onPhotoChange()
          this.editMenu.Details.ImageBase64 = this.editMenu.Details.Image ? null : this.tmpImageBase64 ? this.tmpImageBase64.img : ''
          this.editMenu.Details.ImageMime = this.tmpImageBase64 ? this.tmpImageBase64.mime : ''
          this.editMenu.Details.ImageExt = this.tmpImageBase64 ? this.tmpImageBase64.ext : ''

          // eslint-disable-next-line no-return-assign
          this.editMenu.Details.Options.forEach((option, index) => option.Priority = (index + 1))

          const saveMenu = clone(this.editMenu)

          if (saveMenu.Details.OptionsCopy) delete saveMenu.Details.OptionsCopy

          saveMenu.Details.Categories = clone(saveMenu.Categories)
          saveMenu.tmpDetails = null

          saveMenu.Name = saveMenu.Details.Name = saveMenu.Lang[this.defaultLanguage.Code].Name
          saveMenu.Description = saveMenu.Details.Description = saveMenu.Lang[this.defaultLanguage.Code].Description

          saveMenu.Details.TaxId = saveMenu.TaxId
          saveMenu.Details.TaxLang = saveMenu.TaxLang
          saveMenu.Details.TaxRate = saveMenu.TaxRate

          saveMenu.Details.Tax2Id = saveMenu.Tax2Id
          saveMenu.Details.Tax2Lang = saveMenu.Tax2Lang
          saveMenu.Details.Tax2Rate = saveMenu.Tax2Rate

          saveMenu.Details.ClassificationTypeCode = saveMenu.ClassificationTypeCode
          saveMenu.Details.ClassificationCategoryCode = saveMenu.ClassificationCategoryCode

          saveMenu.Details.ClassificationTypeCodeInvoice = saveMenu.ClassificationTypeCodeInvoice
          saveMenu.Details.ClassificationCategoryCodeInvoice = saveMenu.ClassificationCategoryCodeInvoice

          if (!saveMenu.TaxId || !saveMenu.TaxLang || !saveMenu.TaxRate) {
            const taxId = saveMenu.TaxId || this.defaultTax.TaxId
            const tax = this.taxesList.find(t => t.Id === taxId)
            saveMenu.TaxId = saveMenu.Details.TaxId = tax?.Id || null
            saveMenu.TaxLang = saveMenu.Details.TaxLang = tax?.Lang || null
            saveMenu.TaxRate = saveMenu.Details.TaxRate = tax?.Rate || null
          }

          if (!saveMenu.Tax2Id || !saveMenu.Tax2Lang || !saveMenu.Tax2Rate) {
            const tax2Id = saveMenu.Tax2Id || this.defaultTax.Tax2Id
            const tax2 = this.taxesList.find(t => t.Id === tax2Id)
            saveMenu.Tax2Id = saveMenu.Details.Tax2Id = tax2?.Id || null
            saveMenu.Tax2Lang = saveMenu.Details.Tax2Lang = tax2?.Lang || null
            saveMenu.Tax2Rate = saveMenu.Details.Tax2Rate = tax2?.Rate || null
          }

          saveMenu.Details.PrintGroup = saveMenu.PrintGroup || []

          saveMenu.Details.Catalogs = saveMenu.Catalogs || []

          saveMenu.Details.ActivePos = saveMenu.ActivePos || false

          window.callAS(window.SocketCommand.Menu.Save, { menu: saveMenu }, '', 300000)
        } else {
          this.$refs.menuForm.validate()
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.FieldError'),
            type: 'error',
            icon: 'warning'
          })
        }
      })
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.MenuAddDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.croppa) this.croppa.remove()
      this.onPhotoRemoved()
      this.activeTab = 'tab1'
      this.dynamicTab = 'dynamicTab0'
      this.activeAutomationTab = 'automationTab1'
      this.newMenu = null
      if (this.$refs.menuForm) this.$refs.menuForm.resetValidation()
      this.isVisible = false
      this.isDirty = false
      this.hasNewOptionsAdded = false
    },
    onPhotoChange () {
      if (this.croppa && this.croppa.hasImage() && (this.croppa.getChosenFile() || this.croppa.initialImage) && !this.editMenu.Details.Image) {
        this.tmpImage = this.croppa.getChosenFile() || this.croppa.initialImage
        this.tmpImageBase64 = {
          img : this.croppa.generateDataUrl('image/jpeg', 1),
          mime: 'image/jpeg', // this.croppa.getChosenFile().type,
          ext : 'jpg' // this.croppa.getChosenFile().name.split('.')[1]
        }
      }
    },
    onPhotoRemoved () {
      this.editMenu.Details.Image = ''
      this.tmpImage = null
      this.tmpImageBase64 = null
    },

    addExtraCharge (item) {
      if (!item) return
      const charge = JSON.parse(JSON.stringify(item))
      this.editMenu.Details.Charges.push(charge)
      this.$nextTick(() => {
        this.selectedExtraChargeListItem = null
      })
    },
    removeExtraCharge (index) {
      if (index < 0) return
      this.editMenu.Details.Charges.splice(index, 1)
    },

    onSelectOptionListItem (e) {
      if (!e) return
      const option = JSON.parse(JSON.stringify(e))

      option.MenuId = this.editMenu.Id
      option.OptionId = option.Id
      option.Id = 'TMP_ID_' + this.getRandomId()
      option.OptionName = option.Name
      option.DisplayName = false
      option.MaxChoices = 0
      option.MinChoices = 0
      option.Required = false

      for (let i = 0; i < option.OptionValues.length; i++) {
        option.OptionValues[i].Id = 'TMP_ID_' + this.getRandomId()
        option.OptionValues[i].ShowPrice = option.DisplayType !== 'unit'
        option.OptionValues[i].Selected = false
        option.OptionValues[i].Mandatory = false

        if (option.DisplayType === 'extra_charges') {
          option.OptionValues[i].ShowPrice = true
          option.OptionValues[i].Selected = true
        }
      }

      if (option.DisplayType === 'radio' || option.DisplayType === 'unit' || option.DisplayType === 'unit_value') {
        option.OptionValues[0].Selected = true
        option.DefaultValueId = option.OptionValues[0].Id
      } else if (option.DisplayType === 'checkbox') {
        option.DefaultValueId = 0
      } else if (option.DisplayType === 'extra_charges') {
        option.DefaultValueId = 0
        option.DisplayName = true
      }

      this.$nextTick(() => {
        this.selectedOptionListItem = null
      })

      this.editMenu.Details.Options.push(option)
      this.dynamicTab = 'dynamicTab' + (this.editMenu.Details.Options.length - 1)

      window.dispatchEvent(new Event('resize'))

      this.hasNewOptionsAdded = true
    },
    onNextButtonClick () {
      const tab = parseInt(this.activeTab.split('tab')[1])
      const nextTab = tab < 5 ? tab + 1 : 1
      this.activeTab = 'tab' + nextTab
    },
    setOptionsList (data) {
      if (data?.items) {
        for (let i = 0; i < data.items.length; i++) {
          for (let ii = 0; ii < data.items[i].OptionValues.length; ii++) {
            if (data.items[i].DisplayType === 'unit') {
              data.items[i].OptionValues[ii].Price = parseInt(data.items[i].OptionValues[ii].Price)
            } else {
              data.items[i].OptionValues[ii].Price = parseFloat(data.items[i].OptionValues[ii].Price).toFixed(2)
            }
          }
        }
      }

      this.optionsList = data?.items || []
    },
    setMenuOptionGroups (data) {
      this.optionGroups = data?.items || []
    },
    setMealTimesList (data) {
      const items = data?.items || []

      for (let i = 0; i < items.length; i++) {
        items[i].Desc = this.getTranslatedField(items[i], 'Name') + ' (' + items[i].StartTime + ' - ' + items[i].EndTime + ')'
      }
      items.unshift({
        Id  : 0,
        Name: this.$t('Restaurant.MenuAddDialog.Field.Availability.AllDay'),
        Desc: this.$t('Restaurant.MenuAddDialog.Field.Availability.AllDay')
      })
      this.mealtimes = items
    },
    setCategoriesList (data) {
      this.categories = data?.items || []
    },
    onOptionSort (e) {
      // eslint-disable-next-line no-return-assign
      this.editMenu.Details.Options.forEach((option, index) => option.Priority = (index + 1))
      this.dynamicTab = 'dynamicTab' + e.newIndex
    },
    onChargesSort (e) {
      this.editMenu.Details.Charges.forEach((charge, index) => {
        charge.Priority = (index + 1)
      })
    },
    onSort (e) {
      // eslint-disable-next-line no-return-assign
      this.editMenu.Details.Options.forEach(option => {
        option.OptionValues.forEach((optionValue, index) => {
          optionValue.Priority = (index + 1)
          if (optionValue.Id === option.DefaultValueId) {
            option.DefaultValueId = 0
            this.$nextTick(() => {
              option.DefaultValueId = optionValue.Id
            })
          }
        })
      })
    },
    getRandomId () {
      return (Math.floor(Math.random() * (99999999999 - 100000 + 1) + 100000))
    },
    addOptionRow (tmpItem, index) {
      const item = JSON.parse(JSON.stringify(tmpItem))
      item.Id = 'TMP_ID_' + this.getRandomId()
      item.Priority = this.editMenu.Details.Options[index].OptionValues.length + 1
      item.ShowPrice = true
      item.Selected = false
      const tmp = this.editMenu.Details.Options[index].DefaultValueId

      if (this.editMenu.Details.Options[index].DisplayType === 'radio' || this.editMenu.Details.Options[index].DisplayType === 'unit' || this.editMenu.Details.Options[index].DisplayType === 'unit_value') {
        const hasSelectedRadio = !!this.editMenu.Details.Options[index].OptionValues.find(o => o.Selected)

        if (!hasSelectedRadio) {
          item.Selected = true
          this.editMenu.Details.Options[index].DefaultValueId = item.Id
        } else {
          const defaultValueId = this.editMenu.Details.Options[index].DefaultValueId
          this.editMenu.Details.Options[index].DefaultValueId = 0
          this.$nextTick(() => {
            this.editMenu.Details.Options[index].DefaultValueId = defaultValueId
          })
        }
      } else if (this.editMenu.Details.Options[index].DisplayType === 'checkbox') {
        this.editMenu.Details.Options[index].DefaultValueId = tmp
      } else if (this.editMenu.Details.Options[index].DisplayType === 'extra_charges') {
        this.editMenu.Details.Options[index].DefaultValueId = tmp
        item.ShowPrice = true
        item.Selected = true
      }

      this.editMenu.Details.Options[index].OptionValues.unshift(item)
      this.hasNewOptionsAdded = true
    },
    removeOptionRow (index, idx) {
      this.editMenu.Details.Options[index].OptionValues.splice(idx, 1)

      this.$nextTick(() => {
        if (this.editMenu.Details.Options[index].DisplayType === 'radio' || this.editMenu.Details.Options[index].DisplayType === 'unit' || this.editMenu.Details.Options[index].DisplayType === 'unit_value') {
          const hasSelectedRadio = !!this.editMenu.Details.Options[index].OptionValues.find(o => o.Selected)

          if (!hasSelectedRadio) {
            if (this.editMenu.Details.Options[index].OptionValues.length > 0) {
              this.editMenu.Details.Options[index].DefaultValueId = 0
              this.$nextTick(() => {
                this.editMenu.Details.Options[index].OptionValues[0].Selected = true
                this.editMenu.Details.Options[index].DefaultValueId = this.editMenu.Details.Options[index].OptionValues[0].Id
              })
            }
          } else {
            const defaultValueId = this.editMenu.Details.Options[index].DefaultValueId
            this.editMenu.Details.Options[index].DefaultValueId = 0
            this.$nextTick(() => {
              this.editMenu.Details.Options[index].DefaultValueId = defaultValueId
            })
          }
        }

        if (this.editMenu.Details.Options[index].OptionValues.length <= 0) this.editMenu.Details.Options[index].DefaultValueId = 0
      })
    },
    removeTab (option, index) {
      this.editMenu.Details.Options.splice(index, 1)
      window.dispatchEvent(new Event('resize'))
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },
    parseDate (date) {
      if (!date) {
        return null
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    },
    onResize () {
      const card = this.$refs.dialogCard
      const cardText = this.$refs.dialogCardText
      if (card && card.$el && cardText) {
        cardText.style.height = 800 + 'px'
        cardText.style.height = (card.$el.offsetHeight - 200) + 'px'
      }
    },
    invertColor (hex, bw) {
      if (!hex) return '#FFFFFF'

      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      if (hex.length !== 6) {
        // eslint-disable-next-line no-console
        console.warn('Invalid HEX color.')
        return '#FFFFFF'
      }
      let r = parseInt(hex.slice(0, 2), 16)
      let g = parseInt(hex.slice(2, 4), 16)
      let b = parseInt(hex.slice(4, 6), 16)
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#323232'
          : '#FFFFFF'
      }
      // invert color components
      r = (255 - r).toString(16)
      g = (255 - g).toString(16)
      b = (255 - b).toString(16)
      // pad each with zeros and return
      return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
    },
    updateSelectedCategories () {
      this.selectedCategories = []
      this.editMenu.Categories.forEach(catID => {
        const category = this.categories.find(category => category.Id.toString() === catID.toString())
        if (category) this.selectedCategories.unshift(category)
      })
    },
    onEditMenuChange () {
      this.isDirty = true
      this.calculateOptionsPrice()
    },
    calculateOptionsPrice () {
      let price = 0
      if (this.editMenu && this.editMenu.Details) {
        for (let i = 0; i < this.editMenu.Details.Options.length; i++) {
          const item = this.editMenu.Details.Options[i]
          for (let ii = 0; ii < item.OptionValues.length; ii++) {
            const option = item.OptionValues[ii]
            if (item.DisplayType === 'checkbox' || item.DisplayType === 'extra_charges') {
              if (option.Selected) {
                price += parseFloat(option.Price)
              }
            } else if (item.DisplayType === 'radio') {
              if (parseInt(item.DefaultValueId) === parseInt(option.Id)) {
                price += parseFloat(option.Price)
              }
            }
          }
        }
      }
      this.editMenu.OptionsPrice = price.toFixed(2)
    }
  }
}
</script>

<style scoped>

/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

/deep/ .v-tabs__icon--prev {
  text-align  : left;
  line-height : 48px !important;
}

/deep/ .v-tabs__icon--next {
  text-align  : right;
  line-height : 48px !important;
}

/deep/ .v-input--radio-group .v-input__control {
  width : 100%;
}

/deep/ .v-input--radio-group .v-radio {
  display        : inline-block;
  max-width      : 24px;
  vertical-align : middle;
  margin         : 0;
  padding        : 0;
}

/deep/ .v-input--radio-group .v-radio label {
  display : none;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 75px !important;*/
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 14px !important;
  padding-right : 0;
  margin        : 0;
}

.divTable {
  display     : table;
  width       : 100%;
  padding-top : 12px;
  font-size   : 14px;
  color       : rgba(0, 0, 0, 0.85);
}
</style>
