<template>
  <div
    :id="mapContainerId"
    :style="mapContainerStyle"
  >
    <template v-if="$mapProvider.Name === MapsProviderEnum.Google">
      <template v-if="mapStatic">
        <img
          style="object-fit: cover; width: 100%; height: 100%;"
          :src="`https://maps.googleapis.com/maps/api/staticmap?center=${addressMarker.lat},${addressMarker.lng}&markers=${addressMarker.lat},${addressMarker.lng}&zoom=${mapZoom}&size=${width}x${height}&scale=2&language=${$i18n.locale}&key=${$mapProvider.Key}`"
        >
      </template>

      <template v-else>
        <gmap-map
          ref="gmap"
          :center="mapCenter"
          :options="{styles: GMapStyles, streetViewControl: mapStyles, mapTypeControl: mapStyles}"
          :zoom="mapZoom"
          map-type-id="roadmap"
          :style="`width: ${width}; height: ${height};`"
        >
          <slot />

          <template v-if="showStoreMarker">
            <gmap-marker
              :icon="getContrastLogo(LOGO_TYPE.GMAP, LOGO_COLOR.DARK)"
              :position="storeMarker"
            />

            <gmap-marker
              v-if="showAddressMarker"
              :draggable="draggable"
              :position="addressMarker"
              @dragend="onMarkerDragEnd"
            />
          </template>

          <template v-if="storeDeliveryAreas">
            <template v-if="storeDeliveryAreas.Circles && storeDeliveryAreas.Circles.length">
              <template v-for="(circ, index) in storeDeliveryAreas.Circles">
                <gmap-circle
                  :key="`gmap_circle_${index}`"
                  :center="circ.circle.center || storeMarker"
                  :editable="false"
                  :options="{strokeColor: circ.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: circ.color, fillOpacity: 0.15}"
                  :radius="circ.circle.radius || 1000"
                />
              </template>
            </template>

            <template v-if="storeDeliveryAreas.Polygons && storeDeliveryAreas.Polygons.length">
              <template v-for="(poly, index) in storeDeliveryAreas.Polygons">
                <gmap-polygon
                  :key="`gmap_poly_${index}`"
                  :editable="false"
                  :options="{strokeColor: poly.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: poly.color, fillOpacity: 0.15}"
                  :paths="poly.vertices"
                />
              </template>
            </template>
          </template>
        </gmap-map>
      </template>
    </template>

    <template v-if="$mapProvider.Name === MapsProviderEnum.MapBox">
      <template v-if="mapStatic">
        <img
          style="object-fit: cover; width: 100%; height: 100%;"
          :src="`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s+ff2600(${addressMarker.lng},${addressMarker.lat})/${addressMarker.lng},${addressMarker.lat},${mapZoom},0.00,0.00/${width}x${height}@2x?access_token=${$mapProvider.Key}`"
        >
      </template>

      <template v-else>
        <mgl-map
          :access-token="$mapProvider.Key"
          :map-style="MapBoxActiveStyle"
          :zoom="mapZoom"
          :center="mapCenter"
          @load="onMapBoxMapLoaded"
        >
          <v-btn-toggle
            v-if="mapStyles"
            v-model="MapBoxActiveStyle"
            dense
            class="mt-2 ml-2"
          >
            <v-btn :value="MapBoxStyles.Streets">
              {{ $t('Common.Map.Title') }}
            </v-btn>
            <v-btn :value="MapBoxStyles.Satellite">
              {{ $t('Common.Map.Satellite') }}
            </v-btn>
          </v-btn-toggle>

          <mgl-fullscreen-control position="top-right" />

          <mgl-navigation-control position="bottom-right" />

          <slot />

          <mgl-marker
            v-if="showStoreMarker"
            :coordinates="toMapBoxPoint(storeMarker)"
          >
            <template slot="marker">
              <img :src="getContrastLogo(LOGO_TYPE.GMAP, LOGO_COLOR.DARK)">
            </template>
          </mgl-marker>

          <mgl-marker
            v-if="showAddressMarker"
            :coordinates="toMapBoxPoint(addressMarker)"
            :draggable="draggable"
            color="#ff0000"
            @dragend="onMarkerDragEnd"
          />
        </mgl-map>
      </template>
    </template>
  </div>
</template>

<script>

import { MglFullscreenControl, MglMap, MglMarker, MglNavigationControl } from 'v-mapbox'
import MapsCommon, { MapsProviderEnum }                                  from '@/mixins/maps/mapsCommon'
import AppData                                                           from '@/mixins/appdata'
import { generateRandomId }                                              from '@/components/common/vue-snotify/util'
import { isFunction }                                                    from '@/lib/data/Validations/utils/Utils'

export default {
  name      : 'VMap',
  components: {
    MglMap,
    MglMarker,
    MglNavigationControl,
    MglFullscreenControl
  },
  directives: {},
  mixins    : [AppData, MapsCommon],
  props     : {
    width: {
      type   : String,
      default: '100%'
    },
    height: {
      type   : String,
      default: '250px'
    },
    map: {
      type   : Object,
      default: () => {
        return { Instance: null }
      }
    },
    mapStyles: {
      type   : Boolean,
      default: true
    },
    mapStatic: {
      type   : Boolean,
      default: false
    },
    mapContainerId: {
      type   : String,
      default: `vmap-${ generateRandomId() }`
    },
    mapProvider: {
      type   : Object,
      default: undefined,
      validator (value) {
        const validValues = ['mapbox', 'google']
        return validValues.includes(value?.Name)
      }
    },
    mapZoom: {
      type   : Number,
      default: 18
    },
    mapCenter: {
      type   : Object,
      default: () => {
        return {
          lat: window.appConfig?.LOCATION_DATA?.Lat || 0,
          lng: window.appConfig?.LOCATION_DATA?.Lng || 0
        }
      }
    },
    addressMarker: {
      type   : Object,
      default: () => {
        return {
          lat: window.appConfig?.LOCATION_DATA?.Lat || 0,
          lng: window.appConfig?.LOCATION_DATA?.Lng || 0
        }
      }
    },
    storeMarker: {
      type   : Object,
      default: () => {
        return {
          lat: window.appConfig?.LOCATION_DATA?.Lat || 0,
          lng: window.appConfig?.LOCATION_DATA?.Lng || 0
        }
      }
    },
    storeDeliveryAreas: {
      type   : Object,
      default: () => {
        return {
          Circles : [],
          Polygons: []
        }
      }
    },
    showAddressMarker: {
      type   : Boolean,
      default: false
    },
    showStoreMarker: {
      type   : Boolean,
      default: false
    },
    draggable: {
      type   : Boolean,
      default: false
    }
  },
  data: function () {
    return {
      MapBoxActiveStyle: ''
    }
  },
  computed: {
    MapBoxStyles () {
      return {
        Streets  : this.isDarkMode ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/streets-v12',
        Satellite: 'mapbox://styles/mapbox/satellite-streets-v12'
      }
    },
    GMapStyles () {
      if (!this.isDarkMode) {
        return [
          {
            featureType: 'poi',
            stylers    : [{ visibility: 'off' }]
          }
        ]
      }

      return [
        {
          featureType: 'poi',
          stylers    : [{ visibility: 'off' }]
        },
        {
          elementType: 'geometry',
          stylers    : [{ color: '#242f3e' }]
        },
        {
          elementType: 'labels.text.stroke',
          stylers    : [{ color: '#242f3e' }]
        },
        {
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#746855' }]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#d59563' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers    : [{ color: '#38414e' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers    : [{ color: '#212a37' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#9ca5b3' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers    : [{ color: '#746855' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers    : [{ color: '#1f2835' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#f3d19c' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers    : [{ color: '#2f3948' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#d59563' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers    : [{ color: '#17263c' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#515c6d' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers    : [{ color: '#17263c' }]
        }
      ]
    },

    MapsProviderEnum () {
      return MapsProviderEnum
    },

    mapContainerStyle () {
      if (this.mapStatic) return `width: 100%; height: ${ this.height }px;`

      return `width: ${ this.width }; height: ${ this.height };`
    },

    $mapProvider () {
      return this.mapProvider ? this.mapProvider : this.mapStatic ? this.staticMapsProvider : this.interactiveMapsProvider
    },

    mapObj: {
      get () {
        return this.map
      },
      set (val) {
        this.$emit('update:map', val)
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.MapBoxActiveStyle = this.MapBoxStyles.Streets
  },
  beforeMount () {},
  mounted () {
    if (this.$mapProvider.Name === MapsProviderEnum.Google) {
      this.mapObj = Object.freeze({
        Instance: this.$refs?.gmap
      })
    }
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getDarkMode () {
      return this.isDarkMode
    },

    toMapBoxPoint (point) {
      return [parseFloat(point?.lng || -1), parseFloat(point?.lat || -1)]
    },

    onMarkerDragEnd (e) {
      const latLng = {
        lat: isFunction(e?.marker?.getLngLat) ? e?.marker.getLngLat().lat : e.latLng.lat(),
        lng: isFunction(e?.marker?.getLngLat) ? e?.marker.getLngLat().lng : e.latLng.lng()
      }

      this.$emit('dragend', e, latLng, e?.marker, this.$mapProvider.Name)
    },

    onMapBoxMapLoaded (e) {
      this.mapObj = Object.freeze({
        Instance: e.map
      })
    }
  }
}
</script>

<style scoped>
/deep/ .mapboxgl-popup {
  max-width : 300px !important;
}

/deep/ .mapboxgl-popup-close-button {
  padding-left  : 6px;
  padding-right : 6px;
  font-size     : 18px;
}

/deep/ .mapboxgl-ctrl-bottom-left, /deep/ .mapboxgl-ctrl-bottom-right, /deep/ .mapboxgl-ctrl-top-left, /deep/ .mapboxgl-ctrl-top-right {
  z-index : auto;
}
</style>
