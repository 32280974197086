export default {
  Title: 'FAQs | FAQ | FAQs',

  Toolbar: {
    Subtitle     : 'Συχνές Ερωτήσεις',
    SearchResults: 'Αποτελέσματα Αναζήτησης',
    Icon         : 'support',
    Color        : 'indigo',
    Button       : {
      Title: 'Επιλέξτε κατηγορία FAQ',
      Color: 'primary ma-0'
    }
  },

  ListView: {
    ListItem: {
      Subtitle: 'Άρθρα | Άρθρο | Άρθρα',
      All     : 'Όλα',
      Question: 'E'
    },
    NoData: {
      Title   : 'Επιλέξτε κατηγορία',
      Subtitle: '@:Faq.ListView.NoData.Title από την λίστα',
      Icon    : 'support'
    }
  },

  FaqCategories: {
    Table: {
      Headers: {
        Id       : '#',
        Title    : 'Τίτλος',
        Slug     : 'Slug',
        Languages: 'Γλώσσες',
        Priority : 'Προτεραιότητα',
        Color    : 'Χρώμα',
        Status   : 'Κατάσταση',
        Date     : 'Ημερομηνία',
        Actions  : ''
      }
    },
    Button: {
      Title: 'Νέα κατηγορία',
      Icon : 'add_box'
    },
    Save: {
      Result: {
        Success: 'Η κατηγορία αποθηκεύτηκε επιτυχώς.',
        Error  : 'Υπήρξε κάποιο πρόβλημα κατα την αποθήκευση! @:Common.Misc.Retry'
      }
    },
    Delete: {
      Title  : 'Διαγραφή Κατηγορίας;',
      Content: 'Είστε σίγουροι ότι θέλετε να διαγράψετε οριστικά την κατηγορία;',
      Result : {
        Success: 'Η κατηγορία διαγράφηκε επιτυχώς.',
        Error  : 'Υπήρξε κάποιο πρόβλημα κατα την διαγραφή! @:Common.Misc.Retry'
      }
    }
  },

  FaqCategoryAddDialog: {
    Title  : 'Κατηγορίες | Κατηγορία | Κατηγορίες',
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Κατηγορίας',
        Subtitle: 'Δημιουργία Νέας Κατηγορίας'
      },
      Edit: {
        Title   : 'Επεξεργασία Κατηγορίας',
        Subtitle: 'Τροποποίηση Στοιχείων Κατηγορίας'
      },
      Icon : 'support',
      Color: 'indigo'
    },
    Field: {
      Title: {
        Label: 'Τίτλος',
        Hint : 'Τίτλος κατηγορίας',
        Icon : 'support',
        Error: {
          Between: 'Το τίτλος πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Slug: {
        Label: 'Slug',
        Hint : 'Slug της κατηγορίας',
        Icon : 'subtitles',
        Error: {
          Between: 'Το slug πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : 'Σύντομη περιγραφή της κατηγορίας',
        Icon : 'title',
        Error: {
          Between: 'Η περιγραφή πρέπει να είναι από 2 έως 512 χαρακτήρες'
        }
      },
      Priority: {
        Label: 'Προτεραιότητα',
        Hint : 'Αριθμός προτεραιότητας πχ. 10, 100, 150',
        Icon : 'sort_by_alpha',
        Error: {
          Min: 'Η προτεραιότητα πρέπει να είναι τουλάχιστον ένας αριθμός'
        }
      },
      Status: { Label: 'Κατάσταση' },
      Color : { Label: 'Χρώμα Κατηγορίας' }
    }
  },

  Faqs: {
    Toolbar: {
      Button: {
        Add: {
          Title: 'Νέο FAQ',
          Icon : 'add_box'
        }
      },
      Select: {
        Label: 'Επιλογή Κατηγορίας',
        Icon : 'support'
      }
    },
    Table: {
      Headers: {
        Id       : '#',
        Title    : 'Τίτλος',
        Slug     : 'Slug',
        Languages: 'Γλώσσες',
        Content  : 'Περιεχόμενο',
        Category : 'Κατηγορία',
        Status   : 'Κατάσταση',
        Date     : 'Ημερομηνία',
        Actions  : ''
      }
    },
    Dialog: {
      Title  : 'Διαγραφή FAQ;',
      Content: 'Είστε σίγουροι ότι θέλετε να διαγράψετε οριστικά το FAQ;'
    },
    Notification: {
      Success: {
        Save  : 'Το FAQ  αποθηκεύτηκε επιτυχώς.',
        Delete: 'Το FAQ διαγράφηκε επιτυχώς.'
      },
      Error: {
        Save  : 'Υπήρξε κάποιο πρόβλημα κατα την αποθήκευση! @:Common.Misc.Retry',
        Delete: 'Υπήρξε κάποιο πρόβλημα κατα την διαγραφή! @:Common.Misc.Retry'
      }
    },
    Filter: {
      filterCategory: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Κατηγορία FAQ',
        PlaceHolder: 'Όλες οι κατηγορίες FAQ',
        ItemText   : 'Title',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    }
  },

  FaqsAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη FAQ',
        Subtitle: 'Δημιουργία Νέου FAQ'
      },
      Edit: {
        Title   : 'Επεξεργασία FAQ',
        Subtitle: 'Τροποποίηση Στοιχείων FAQ'
      },
      Icon : 'support',
      Color: 'indigo'
    },
    Field: {
      Title: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Title.Label',
        Hint : 'Τίτλος FAQ',
        Icon : 'title',
        Error: {
          Between: '@:Faq.FaqCategoryAddDialog.Field.Title.Error.Between'
        }
      },
      Description: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Description.Label',
        Hint : 'Περιγραφή του FAQ',
        Icon : 'short_text',
        Error: {
          Between: '@:Faq.FaqCategoryAddDialog.Field.Description.Error.Between'
        }
      },
      Slug: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Slug.Label',
        Hint : 'Slugified τίτλος του FAQ',
        Icon : 'subtitles',
        Error: {
          Between: '@:Faq.FaqCategoryAddDialog.Field.Slug.Error.Between'
        }
      },
      Category: {
        Label: '@:Faq.Faqs.Toolbar.Select.Label',
        Icon : '@:Faq.Faqs.Toolbar.Select.Icon',
        Error: {
          Required: 'Πρέπει να επιλέξετε κατηγορία'
        }
      },
      Tip: {
        Label: 'Tip',
        Hint : 'FAQ Tip',
        Icon : 'help_outline',
        Error: {
          Between: 'Το Tip πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Note: {
        Label: 'Note',
        Hint : 'FAQ Note',
        Icon : 'note',
        Error: {
          Between: 'Το Note πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      VideoId: {
        Label: 'YouTube Βίντεο',
        Hint : 'YouTube Βίντεο ID. Πχ. reaRZQTPKCY',
        Icon : 'movie',
        Error: {
          Between: 'Το Βίντεο ID πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Priority: {
        Label: '@:Faq.FaqCategoryAddDialog.Field.Priority.Label',
        Hint : '@:Faq.FaqCategoryAddDialog.Field.Priority.Hint',
        Icon : '@:Faq.FaqCategoryAddDialog.Field.Priority.Icon',
        Error: {
          Min: '@:Faq.FaqCategoryAddDialog.Field.Priority.Error.Min'
        }
      },
      Content     : { Label: 'Περιεχόμενο' },
      ContentTitle: { Label: 'Κείμενα Περιεχομένου' },
      ContentMedia: { Label: 'Media Περιεχομένου' },
      Image       : { Label: 'Φωτογραφία' }
    }
  },

  ImagePreview: {
    Toolbar: {
      Title: '@:Common.Button.Preview Φωτογραφίας',
      Icon : 'photo',
      Color: 'indigo'
    }
  }
}
