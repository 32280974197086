<template>
  <v-container
    v-if="isVisible"
    class="fill-height"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-card
          class="ma-0 d-inline-flex"
          color="grey darken-2"
          light
          width="100%"
          max-width="540px"
          min-width="320px"
          flat
        >
          <v-card-text class="ma-0 pa-2">
            <v-card
              dark
              flat
            >
              <loading-modal :visible="showLoader" />

              <v-card-title class="justify-center mb-0 pb-0">
                <svg-logo
                  fork-color="#fff"
                  pro-color="#fff"
                  signal-color1="#7f8082"
                  signal-color2="#6b6c6e"
                  text-main-active
                  text-get-color="#fff"
                  text-slogan-color="#fff"
                  size="160px"
                  class="mb-3"
                />
              </v-card-title>

              <v-container
                class="px-0 pt-0 fill-height"
                fluid
              >
                <v-row
                  class="flex"
                  align="center"
                  justify="center"
                >
                  <v-col cols="12">
                    <v-row>
                      <v-col
                        v-if="currentTitle && showStepTitle"
                        class="text-center pa-2"
                        cols="12"
                      >
                        {{ currentTitle }}
                      </v-col>

                      <v-col
                        v-if="errorMessage"
                        class="text-center red--text text-subtitle-1"
                        cols="12"
                      >
                        {{ errorMessage }}
                      </v-col>

                      <v-window
                        v-model="step"
                        style="width: 100%;"
                      >
                        <v-window-item
                          :value="1"
                          class="text-center"
                        >
                          <v-form
                            ref="userForm"
                            v-model="userFormValid"
                            @submit.prevent
                          >
                            <v-col cols="12">
                              <v-text-field
                                v-model="UID"
                                :hint="$t('Authentication.Field.Username.Hint')"
                                :label="$t('Authentication.Field.Username.Label')"
                                :prepend-inner-icon="$t('Authentication.Field.Username.Icon')"
                                :rules="[(v) => !!v || $t('Authentication.Field.Username.Error.Empty'),(v) => v && v.length >= 8 || $t('Authentication.Field.Username.Error.Min')]"
                                autofocus
                                filled
                                class="px-6"
                                clearable
                                color="white"
                                required
                              />
                            </v-col>
                          </v-form>
                        </v-window-item>

                        <v-window-item
                          :value="2"
                          style="width: 100%;"
                        >
                          <v-row
                            dense
                            class="mt-1"
                          >
                            <v-col
                              class="text-center pa-2"
                              cols="12"
                            >
                              <span
                                :class="[pinCode.length >= 1 ? 'pinCodeCircleActive' : '']"
                                class="pinCodeCircle"
                              />
                              <span
                                :class="[pinCode.length >= 2 ? 'pinCodeCircleActive' : '']"
                                class="pinCodeCircle"
                              />
                              <span
                                :class="[pinCode.length >= 3 ? 'pinCodeCircleActive' : '']"
                                class="pinCodeCircle"
                              />
                              <span
                                :class="[pinCode.length >= 4 ? 'pinCodeCircleActive' : '']"
                                class="pinCodeCircle"
                              />
                            </v-col>
                          </v-row>

                          <v-row
                            dense
                            class="mb-3"
                          >
                            <v-col
                              class="text-center"
                              cols="12"
                            >
                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(1)"
                              >
                                1
                              </v-btn>

                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(2)"
                              >
                                2
                              </v-btn>

                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(3)"
                              >
                                3
                              </v-btn>
                            </v-col>

                            <v-col
                              class="text-center"
                              cols="12"
                            >
                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(4)"
                              >
                                4
                              </v-btn>

                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(5)"
                              >
                                5
                              </v-btn>

                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(6)"
                              >
                                6
                              </v-btn>
                            </v-col>

                            <v-col
                              class="text-center"
                              cols="12"
                            >
                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(7)"
                              >
                                7
                              </v-btn>

                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(8)"
                              >
                                8
                              </v-btn>

                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(9)"
                              >
                                9
                              </v-btn>
                            </v-col>

                            <v-col
                              class="text-center"
                              cols="12"
                            >
                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="blue"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onDelKeyClick"
                              >
                                <v-icon>backspace</v-icon>
                              </v-btn>

                              <v-btn
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="grey"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onNumKeyClick(0)"
                              >
                                0
                              </v-btn>

                              <v-btn
                                :disabled="pinCode.length < 4"
                                :style="{'min-width': $vuetify.breakpoint.xsOnly ? '75px' : '85px'}"
                                color="light-green"
                                dark
                                style="min-height: 55px;"
                                class="mx-1"
                                @click="onOkKeyClick"
                              >
                                <v-icon>check</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-window-item>

                        <v-window-item
                          :value="3"
                          style="width: 100%;"
                        >
                          <v-col
                            class="text-center pa-2"
                            cols="12"
                          >
                            <v-text-field
                              ref="superAdminPinCodeTextField"
                              v-model="superAdminPinCode"
                              :label="$t('Authentication.Field.AdminPass.Label')"
                              :prepend-inner-icon="$t('Authentication.Field.AdminPass.Icon')"
                              type="password"
                              class="px-6"
                              color="white"
                              autofocus
                              clearable
                              required
                              filled
                            />
                          </v-col>
                        </v-window-item>
                      </v-window>

                      <template v-if="!posUser || (posUser && !userIsSuperAdmin)" />

                      <template v-else-if="posUser && userIsSuperAdmin" />
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <v-divider class="mx-4" />

              <v-card-actions class="px-4">
                <v-btn
                  v-show="step === 2 || step === 3"
                  class="mr-2"
                  :disabled="step === 1"
                  text
                  @click="resetForm();step--"
                >
                  {{ $t('Common.Button.Back').toLocaleUpperCase($i18n.locale) }}
                </v-btn>

                <v-switch
                  v-if="step === 1"
                  v-model="rememberMe"
                  inset
                  :label="$t('Authentication.Field.RememberMe.Label')"
                  color="primary"
                />

                <v-switch
                  v-if="step === 3 && !isDesktop"
                  v-model="rememberMeSuperAdmin"
                  inset
                  :label="$t('Authentication.Field.RememberMe.Label')"
                  color="primary"
                />

                <v-spacer />

                <v-btn
                  v-show="step === 1"
                  :disabled="step === 2 || step === 3 || !userFormValid"
                  color="primary"
                  depressed
                  @click="step++"
                >
                  {{ $t('Common.Button.Next').toLocaleUpperCase($i18n.locale) }}
                </v-btn>

                <v-btn
                  v-show="step === 3"
                  :disabled="step === 1 || step === 2"
                  color="primary"
                  depressed
                  @click="loginSuperAdmin"
                >
                  {{ $t('Common.Button.Next').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                                            from '@/mixins/auth'
import Resizable                                       from '@/mixins/resizable'
import SvgLogo                                         from '@/components/common/SvgLogo.vue'
import LoadingModal                                    from '@/components/common/LoadingModal'
import { AES, HmacMD5 }                                from '@/lib/crypto/crypto'
import { resetRouter }                                 from '@/router/router'
import { setI18nLanguage, setI18nLanguageFromBrowser } from '@/lang/lang'
import isElectron                                      from '@/electron/isElectron'
import { stopCloudUpdatePollingCheck }                 from '@/api/ApiUpdate'

export default {
  components: {
    LoadingModal,
    'svg-logo': SvgLogo
  },
  mixins: [Auth, Resizable],
  data () {
    return {
      step              : 1,
      isVisible         : false,
      showStepTitle     : false,
      errorMessage      : '',
      pinCode           : [],
      superAdminPinCode : '',
      userFormValid     : false,
      showLoader        : false,
      UIDStorageKey     : HmacMD5.hash('__UID__'),
      SAPStorageKey     : HmacMD5.hash('__SAP__'),
      remember          : false,
      rememberSuperAdmin: false
    }
  },
  computed: {
    isDesktop () {
      return isElectron()
    },

    rememberMe: {
      get () {
        return this.remember || this.$localStorage.hasKey(this.UIDStorageKey)
      },
      set (val) {
        if (val) {
          this.$localStorage.set(this.UIDStorageKey, AES.encrypt(window.UID))
          this.remember = true
        } else {
          this.$localStorage.remove(this.UIDStorageKey)
          this.remember = false
        }
      }
    },
    rememberMeSuperAdmin: {
      get () {
        return this.rememberSuperAdmin || this.$localStorage.hasKey(this.SAPStorageKey)
      },
      set (val) {
        if (val) {
          this.$localStorage.set(this.SAPStorageKey, AES.encrypt(this.superAdminPinCode))
          this.rememberSuperAdmin = true
        } else {
          this.$localStorage.remove(this.SAPStorageKey)
          this.rememberSuperAdmin = false
        }
      }
    },
    currentTitle () {
      switch (this.step) {
        case 1:
          return this.$t('Authentication.Title.Username')
        case 2:
          return this.$t('Authentication.Title.PinPass')
        default:
          return this.$t('Authentication.Title.AdminPass')
      }
    },
    UID: {
      get () {
        return window.UID
      },
      set (val) {
        window.UID = val
      }
    }
  },
  watch: {
    step (newVal, oldVal) {
      if (oldVal === 1) window.removeEventListener('keyup', this.onKeyPrevent)
      if (oldVal === 2) window.removeEventListener('keyup', this.onKeyUp)
      if (oldVal === 3) window.removeEventListener('keyup', this.onAdminKeyPrevent)

      if (newVal === 1) {
        window.removeEventListener('keyup', this.onKeyPrevent)
        window.addEventListener('keyup', this.onKeyPrevent)
      }

      if (newVal === 2) {
        window.removeEventListener('keyup', this.onKeyPrevent)
        window.addEventListener('keyup', this.onKeyUp)
        if (this.rememberMe) {
          this.$localStorage.set(this.UIDStorageKey, AES.encrypt(window.UID))
        } else {
          this.$localStorage.remove(this.UIDStorageKey)
        }
      }

      if (newVal === 3) {
        window.removeEventListener('keyup', this.onKeyUp)
        window.addEventListener('keyup', this.onAdminKeyPrevent)

        // Skip super admin password for dev environments
        if (process.env.VUE_APP_ENV === 'development') {
          this.$localStorage.remove(this.SAPStorageKey)
          this.showLoader = true
          window.initSocket()
        }

        if (this.rememberMeSuperAdmin) {
          this.$localStorage.set(this.SAPStorageKey, AES.encrypt(this.superAdminPinCode))
        } else {
          this.$localStorage.remove(this.SAPStorageKey)
        }

        if (this.$refs?.superAdminPinCodeTextField) {
          setTimeout(() => {
            this.$refs.superAdminPinCodeTextField.focus()
          }, 250)
        }
      }
    }
  },

  created () {
    setI18nLanguageFromBrowser()

    if (this.$localStorage.get(this.UIDStorageKey)) {
      this.UID = AES.decrypt(this.$localStorage.get(this.UIDStorageKey))
    }

    if (this.isDesktop) {
      this.$localStorage.remove(this.SAPStorageKey)
    } else {
      if (this.$localStorage.get(this.SAPStorageKey)) {
        this.superAdminPinCode = AES.decrypt(this.$localStorage.get(this.SAPStorageKey))
      }
    }

    this.$bus.$emit('app-reset-data')
    resetRouter()
  },

  mounted () {
    this.$bus.$on('app-logout', this.onAppLogout)
    window.addEventListener('keyup', this.onKeyPrevent)
  },

  beforeMount () {
    this.step = 1
    this.errorMessage = ''

    if (this.$route.query.uid && this.$route.query.pin && this.$route.query.token) {
      this.login(this.$route.query.pin, this.$route.query.uid, this.$route.query.token)
    } else if (this.posUser && this.posUser?.staff_email === this.UID) {
      this.login(this.PIN, this.UID)
    } else {
      this.onAppLogout()
      this.isVisible = true
    }
  },
  updated () {},

  beforeDestroy () {
    this.$bus.$off('app-logout', this.onAppLogout)
    window.removeEventListener('keyup', this.onKeyPrevent)
    window.removeEventListener('keyup', this.onKeyUp)
    window.removeEventListener('keyup', this.onAdminKeyPrevent)
  },

  methods: {
    onAppLogout () {
      this.step = 1
      this.PIN = ''
      this.pinCode = []
      this.superAdminPinCode = ''
      this.posUser = null
      this.errorMessage = ''
      this.userFormValid = true
      this.showLoader = false
      this.authorizationToken = ''
      window.staffName = ''
      window.authorizationToken = ''
      window.appConfig = null
      window.socketId = null
      this.$sessionStorage && this.$sessionStorage.clear()
      window.Socket && window.Socket.disconnect()
      this.isVisible = true
      stopCloudUpdatePollingCheck()
      // eslint-disable-next-line no-console
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>> Login ----- onAppLogout')
    },

    loginSuperAdmin () {
      this.showLoader = true
      window.API.post(window.APICall.loginSuperAdmin, { password: this.superAdminPinCode }, { timeout: 30000 })
        .then(response => {
          if (response && response.status === 200) {
            if (this.rememberMeSuperAdmin) {
              this.$localStorage.set(this.SAPStorageKey, AES.encrypt(this.superAdminPinCode))
            } else {
              this.$localStorage.remove(this.SAPStorageKey)
            }

            this.superAdminPinCode = ''
            this.showLoader = true
            window.initSocket()
          } else {
            this.superAdminPinCode = ''
            this.showLoader = false
            this.errorMessage = this.$t('Authentication.Field.Result.WrongPassword')
            this.$localStorage.remove(this.SAPStorageKey)
          }
        })
        .catch(e => {
          this.showLoader = false
          // eslint-disable-next-line no-console
          console.error(e)
        })
    },

    login (pinCode, UID, token = null) {
      this.showLoader = true
      window.removeEventListener('keyup', this.onKeyUp)

      window.API.post(window.APICall.login, {
        uid: UID,
        pin: pinCode,
        ...(token && { token: token })
      }, { timeout: 30000 })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            if (response.data.staff) {
              this.errorMessage = ''
              this.PIN = pinCode
              this.pinCode = []
              this.posUser = response.data.staff
              setI18nLanguage(this.posUser.staff_locale)
              this.authorizationToken = response.data.token
              response.data.config.WS = response.data.ws
              window.Vue.AppConfig = window.appConfig = response.data.config

              this.initThemeMode(response?.data?.staff?.theme_mode || 'light')

              // --------------------------------------------------------------------------
              // TODO: Remove for loop when data from API respects floats
              window.appConfig.LOCATION_DATA.DeliveryAreas.forEach((area, a) => {
                window.appConfig.LOCATION_DATA.DeliveryAreas[a].areaId = parseFloat(area.areaId)
                window.appConfig.LOCATION_DATA.DeliveryAreas[a].circle.center.lat = parseFloat(area.circle.center.lat)
                window.appConfig.LOCATION_DATA.DeliveryAreas[a].circle.center.lng = parseFloat(area.circle.center.lng)
                window.appConfig.LOCATION_DATA.DeliveryAreas[a].circle.radius = parseFloat(area.circle.radius)

                area.vertices.forEach((vert, v) => {
                  window.appConfig.LOCATION_DATA.DeliveryAreas[a].vertices[v].lat = parseFloat(vert.lat)
                  window.appConfig.LOCATION_DATA.DeliveryAreas[a].vertices[v].lng = parseFloat(vert.lng)
                  if (!window.appConfig.LOCATION_DATA.DeliveryAreas[a].vertices[v].hasOwnProperty('latlng')) {
                    window.appConfig.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng = {
                      lat: parseFloat(vert.lat),
                      lng: parseFloat(vert.lng)
                    }
                  }
                  window.appConfig.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng.lat = parseFloat(vert.latlng.lat)
                  window.appConfig.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng.lng = parseFloat(vert.latlng.lng)
                })
              })

              window.Vue.AppConfig = window.appConfig
              // --------------------------------------------------------------------------

              if (response.data.staff.IsAdmin) { // IS USER SUPER ADMIN
                this.isVisible = true
                this.showLoader = false
                this.step = 3

                if (this.superAdminPinCode && this.rememberMeSuperAdmin) this.loginSuperAdmin()
              } else {
                window.initSocket()
              }
            } else {
              this.isVisible = true
              this.showLoader = false
              this.posUser = null
              this.pinCode = []
              this.errorMessage = this.$t('Authentication.Field.Result.WrongUsernamePin')
              window.addEventListener('keyup', this.onKeyUp)
            }
          } else {
            this.isVisible = true
            this.showLoader = false
            this.posUser = null
            this.pinCode = []
            this.errorMessage = this.$t('Authentication.Field.Result.WrongUsernamePin')
            window.addEventListener('keyup', this.onKeyUp)
          }
        })
        .catch(e => {
          this.resetForm()
          this.errorMessage = this.$t('Authentication.Field.Result.WrongUsernamePin')
          this.step = 1
          this.isVisible = true
          this.showLoader = false
          // eslint-disable-next-line no-console
          console.error(e)
        })
    },
    onNumKeyClick (val) {
      if (this.pinCode.length < 4) {
        this.pinCode.push(parseInt(val))
        if (this.pinCode.length === 4) this.onOkKeyClick()
      }
    },
    onDelKeyClick () {
      this.pinCode.pop()
    },
    onOkKeyClick () {
      if (this.pinCode.length < 4) return
      this.posUser = null
      this.login(this.pinCode.join(''), window.UID)
    },
    resetForm () {
      this.posUser = null
      this.pinCode = []
      this.superAdminPinCode = ''
      this.errorMessage = ''
    },
    onKeyPrevent (e) {
      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        e.returnValue = false
        e.cancelBubble = true
        this.onUserNameKeyUp(e)
        return false
      }
    },
    onAdminKeyPrevent (e) {
      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        e.returnValue = false
        e.cancelBubble = true
        this.loginSuperAdmin()
        return false
      }
    },

    onUserNameKeyUp (keyCode) {
      switch (keyCode.key) {
        case 'Enter':
          this.$refs.userForm.validate()
          if (this.userFormValid) {
            this.step++
          }
          break
      }
    },
    onKeyUp (keyCode) {
      switch (keyCode.key) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '0':
          this.onNumKeyClick(keyCode.key)
          break
        case 'Backspace':
        case 'Delete':
          this.onDelKeyClick()
          break
        case 'Enter':
          this.onOkKeyClick()
          break
      }
    },
    onResize () {

    }
  }
}
</script>

<style scoped>
.pinCodeCircle {
  margin           : 4px;
  display          : inline-block;
  height           : 20px;
  width            : 20px;
  line-height      : 60px;
  border-radius    : 50%;
  background-color : inherit;
  color            : white;
  text-align       : center;
  font-size        : 2em;
  border           : 2px solid #666;
}

.pinCodeCircleActive {
  border           : 2px solid #8bc34a;
  background-color : #8bc34a;
}

</style>
