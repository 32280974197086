<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1366px"
      persistent
      scrollable
    >
      <v-card
        v-if="editMenu && editMenu.Details && editMenu.Details.Options && editMenu.Details.OptionsCopy && optionToEdit && optionValueToEdit"
        ref="dialogCard"
      >
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.MenuOptionValueModifiersDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.MenuOptionValueModifiersDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Restaurant.MenuOptionValueModifiersDialog.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ optionToEdit.OptionName }}: <span class="grey--text text--darken-1">
                {{ optionValueToEdit.Name }}
              </span>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          ref="dialogCardText"
          class="fill-height pa-3"
        >
          <v-container
            class="pa-1"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <v-tabs
                  v-model="dynamicTab"
                  class="mt-2 mb-2"
                  background-color="grey darken-2"
                  dark
                  show-arrows
                  slider-color="light-green"
                >
                  <template v-for="(option, index) in editMenu.Details.Options">
                    <template v-if="'dynamicTab' + optionTabToEdit!=='dynamicTab' + index">
                      <v-tab
                        :key="'dynamicTab' + index"
                        :href="'#dynamicTab' + index"
                        active-class="v-tabs__item--active grey darken-3"
                      >
                        <span class="text-caption">
                          {{ option.OptionName | sanitizeDiacritics | uppercase }}<br>({{ option.DisplayType }})
                        </span>
                      </v-tab>

                      <v-tab-item
                        :key="'dynamicTab' + index"
                        :value="'dynamicTab' + index"
                      >
                        <v-container
                          class="pa-1"
                          fluid
                        >
                          <v-row
                            dense
                            justify="space-between"
                          >
                            <v-col cols="2">
                              <v-checkbox
                                v-model="option.VisibleCheckBox"
                                :indeterminate="!option.Visible"
                                :label="$t('Common.Button.Toggle.Active')"
                                :readonly="!option.Visible"
                                class="pt-0 mt-2"
                                color="red"
                                hide-details
                              />
                              <div class="text-caption grey--text text--darken-1 mt-2">
                                {{ $t('Restaurant.MenuOptionValueModifiersDialog.Field.Show') }}
                              </div>
                              <v-switch
                                :id="option.Id + '_visible'"
                                v-model="option.Visible"
                                inset
                                :label="option.Visible ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                class="mt-1"
                                color="success"
                                hide-details
                              />
                            </v-col>

                            <v-col
                              v-show="option.DisplayType === 'checkbox'"
                              cols="2"
                            >
                              <v-checkbox
                                v-model="option.RequiredCheckBox"
                                :indeterminate="editMenu.Details.OptionsCopy[index].Required !== option.Required"
                                :label="$t('Common.Button.Toggle.Active')"
                                :readonly="editMenu.Details.OptionsCopy[index].Required !== option.Required"
                                class="pt-0 mt-2"
                                color="red"
                                hide-details
                              />
                              <div class="text-caption grey--text text--darken-1 mt-2">
                                {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Required') }}
                              </div>
                              <v-switch
                                :id="option.Id + '_required'"
                                v-model="option.Required"
                                inset
                                :label="option.Required ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                class="mt-1"
                                color="success"
                                hide-details
                                @change="onRequiredChange(option)"
                              />
                            </v-col>

                            <v-col
                              v-show="option.DisplayType === 'checkbox'"
                              cols="8"
                            >
                              <div class="mt-2 float-left">
                                <v-checkbox
                                  v-model="option.MinChoicesCheckBox"
                                  :indeterminate="editMenu.Details.OptionsCopy[index].MinChoices !== option.MinChoices"
                                  :label="$t('Common.Button.Toggle.Active')"
                                  :readonly="editMenu.Details.OptionsCopy[index].MinChoices !== option.MinChoices"
                                  class="pt-0 mt-0 mb-2"
                                  color="red"
                                  hide-details
                                />
                                <div class="text-caption grey--text text--darken-1 ml-2">
                                  {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MinChoices.Label') }}
                                </div>
                                <v-btn
                                  class="grey--text elevation-0 ml-2"
                                  outlined
                                  style="min-width: 40px; max-width: 40px;"
                                  @click="onMinChoicesChange(option, -1)"
                                >
                                  -
                                </v-btn>
                                <v-btn
                                  :class="isDarkMode ? 'grey darken-3' : 'white'"
                                  class="grey--text text elevation-0 mx-1"
                                  disabled
                                  outlined
                                  style="min-width: 50px; max-width: 100px;"
                                >
                                  {{ option.MinChoices }}
                                </v-btn>
                                <v-btn
                                  class="grey--text elevation-0"
                                  outlined
                                  style="min-width: 40px; max-width: 40px;"
                                  @click="onMinChoicesChange(option, +1)"
                                >
                                  +
                                </v-btn>
                                <br><span
                                  class="text-caption grey--text ml-2"
                                  style="border-top: thin solid rgba(0,0,0,0.42);"
                                >
                                  {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MinChoices.Hint') }}
                                </span>
                              </div>
                              <div
                                class="mt-2 ml-2 float-left"
                                style="display: inline-block;"
                              >
                                <v-checkbox
                                  v-model="option.MaxChoicesCheckBox"
                                  :indeterminate="editMenu.Details.OptionsCopy[index].MaxChoices !== option.MaxChoices"
                                  :label="$t('Common.Button.Toggle.Active')"
                                  :readonly="editMenu.Details.OptionsCopy[index].MaxChoices !== option.MaxChoices"
                                  class="pt-0 mt-0 mb-2"
                                  color="red"
                                  hide-details
                                />
                                <div class="text-caption grey--text text--darken-1 ml-2">
                                  {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MaxChoices.Label') }}
                                </div>
                                <v-btn
                                  class="grey--text elevation-0 ml-2"
                                  outlined
                                  style="min-width: 40px; max-width: 40px;"
                                  @click="onMaxChoicesChange(option, -1)"
                                >
                                  -
                                </v-btn>
                                <v-btn
                                  :class="isDarkMode ? 'grey darken-3' : 'white'"
                                  class="grey--text text elevation-0 mx-1"
                                  disabled
                                  outlined
                                  style="min-width: 50px; max-width: 100px;"
                                >
                                  {{ option.MaxChoices }}
                                </v-btn>
                                <v-btn
                                  class="grey--text elevation-0"
                                  outlined
                                  style="min-width: 40px; max-width: 40px;"
                                  @click="onMaxChoicesChange(option, +1)"
                                >
                                  +
                                </v-btn>
                                <br><span
                                  class="text-caption grey--text ml-2"
                                  style="border-top: thin solid rgba(0,0,0,0.42);"
                                >
                                  {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.MaxChoices.Hint') }}
                                </span>
                              </div>
                            </v-col>

                            <v-col cols="12">
                              <div v-if="option.DisplayType=='radio'">
                                <v-radio-group
                                  v-model="option.DefaultValueId"
                                  :mandatory="true"
                                  class="ma-0 pa-0"
                                  row
                                >
                                  <div class="divTable">
                                    <div class="divTableHeading">
                                      <div class="divTableRow">
                                        <div class="divTableHead">
                                          &nbsp;
                                        </div>
                                        <div class="divTableHead">
                                          #
                                        </div>
                                        <div class="divTableHead">
                                          {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Name') }}
                                        </div>
                                        <div class="divTableHead">
                                          {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Price') }}
                                          <v-btn
                                            icon
                                            small
                                            @click="toggleColumnOptionValues(option.OptionValues, 'Price')"
                                          >
                                            <v-icon>check_box</v-icon>
                                          </v-btn>
                                          <v-btn
                                            icon
                                            small
                                            @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'Price')"
                                          >
                                            <v-icon>settings_backup_restore</v-icon>
                                          </v-btn>
                                        </div>
                                        <div class="divTableHead text-center">
                                          {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.ShowPrice') }}
                                          <v-btn
                                            icon
                                            small
                                            @click="toggleColumnOptionValues(option.OptionValues, 'ShowPrice')"
                                          >
                                            <v-icon>check_box</v-icon>
                                          </v-btn>
                                          <v-btn
                                            icon
                                            small
                                            @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'ShowPrice')"
                                          >
                                            <v-icon>settings_backup_restore</v-icon>
                                          </v-btn>
                                        </div>
                                        <div class="divTableHead text-center">
                                          {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Default') }}
                                        </div>
                                        <div class="divTableHead text-center">
                                          {{ $t('Restaurant.MenuOptionValueModifiersDialog.Field.Show') }}
                                          <v-btn
                                            icon
                                            small
                                            @click="toggleColumnOptionValues(option.OptionValues, 'Visible')"
                                          >
                                            <v-icon>check_box</v-icon>
                                          </v-btn>
                                          <v-btn
                                            icon
                                            small
                                            @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'Visible')"
                                          >
                                            <v-icon>settings_backup_restore</v-icon>
                                          </v-btn>
                                        </div>
                                      </div>
                                    </div>

                                    <draggable
                                      v-model="option.OptionValues"
                                      class="divTableBody"
                                      handle=".my-handle"
                                    >
                                      <div
                                        v-for="(optionValue, idx) in option.OptionValues"
                                        :key="idx"
                                        class="divTableRow"
                                      >
                                        <div class="divTableCell">
                                          <v-icon
                                            class="my-handle"
                                            style="cursor: move;"
                                          >
                                            drag_handle
                                          </v-icon>
                                        </div>
                                        <div class="divTableCell">
                                          {{ optionValue.OptionValueId }}
                                        </div>
                                        <div
                                          class="divTableCell"
                                          v-html="optionValue.Name"
                                        />
                                        <div
                                          class="divTableCell"
                                          style="width: 120px;"
                                        >
                                          <v-checkbox
                                            v-model="optionValue.PriceCheckBox"
                                            :indeterminate="editMenu.Details.OptionsCopy[index].OptionValues[idx].Price !== optionValue.Price"
                                            :label="$t('Common.Button.Toggle.Active')"
                                            :readonly="editMenu.Details.OptionsCopy[index].OptionValues[idx].Price !== optionValue.Price"
                                            class="d-inline-block"
                                            color="red"
                                            hide-details
                                          />
                                          <br>
                                          <v-text-field
                                            v-model="optionValue.Price"
                                            :append-icon="appCurrency.Icon"
                                            :clearable="editMenu.Details.OptionsCopy[index].OptionValues[idx].Price !== optionValue.Price"
                                            :placeholder="$t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Price')"
                                            clear-icon="settings_backup_restore"
                                            required
                                            single-line
                                            @click:clear="resetOptionValuePrice(optionValue, editMenu.Details.OptionsCopy[index].OptionValues[idx].Price)"
                                          />
                                        </div>
                                        <div class="divTableCell text-center">
                                          <v-checkbox
                                            v-model="optionValue.ShowPriceCheckBox"
                                            :indeterminate="editMenu.Details.OptionsCopy[index].OptionValues[idx].ShowPrice !== optionValue.ShowPrice"
                                            :label="$t('Common.Button.Toggle.Active')"
                                            :readonly="editMenu.Details.OptionsCopy[index].OptionValues[idx].ShowPrice !== optionValue.ShowPrice"
                                            class="d-inline-block"
                                            color="red"
                                            hide-details
                                          />
                                          <br>
                                          <v-switch
                                            :id="optionValue.Id + '_show_price'"
                                            v-model="optionValue.ShowPrice"
                                            inset
                                            :label="optionValue.ShowPrice ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                            color="success"
                                            hide-details
                                          />
                                        </div>
                                        <div class="divTableCell text-center">
                                          <v-radio
                                            :value="optionValue.Id"
                                            class="pt-4 mt-1"
                                            disabled
                                            hide-details
                                          />
                                        </div>
                                        <div class="divTableCell text-center">
                                          <v-checkbox
                                            v-model="optionValue.VisibleCheckBox"
                                            :indeterminate="!optionValue.Visible"
                                            :label="$t('Common.Button.Toggle.Active')"
                                            :readonly="!optionValue.Visible"
                                            class="d-inline-block"
                                            color="red"
                                            hide-details
                                          />
                                          <br>
                                          <v-switch
                                            :id="index + '_' + idx + '_' + optionValue.Id + '_optionValue_visible'"
                                            v-model="optionValue.Visible"
                                            inset
                                            :false-value="false"
                                            :label="optionValue.Visible ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                            :true-value="true"
                                            color="success"
                                            hide-details
                                          />
                                        </div>
                                      </div>
                                    </draggable>
                                  </div>
                                </v-radio-group>
                              </div>

                              <div v-else-if="option.DisplayType=='checkbox'">
                                <div class="divTable">
                                  <div class="divTableHeading">
                                    <div class="divTableRow">
                                      <div class="divTableHead">
                                        &nbsp;
                                      </div>
                                      <div class="divTableHead">
                                        #
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Name') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Price') }}
                                        <v-btn
                                          icon
                                          small
                                          @click="toggleColumnOptionValues(option.OptionValues, 'Price')"
                                        >
                                          <v-icon>check_box</v-icon>
                                        </v-btn>
                                        <v-btn
                                          icon
                                          small
                                          @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'Price')"
                                        >
                                          <v-icon>settings_backup_restore</v-icon>
                                        </v-btn>
                                      </div>
                                      <div class="divTableHead text-center">
                                        {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.ShowPrice') }}
                                        <v-btn
                                          icon
                                          small
                                          @click="toggleColumnOptionValues(option.OptionValues, 'ShowPrice')"
                                        >
                                          <v-icon>check_box</v-icon>
                                        </v-btn>
                                        <v-btn
                                          icon
                                          small
                                          @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'ShowPrice')"
                                        >
                                          <v-icon>settings_backup_restore</v-icon>
                                        </v-btn>
                                      </div>
                                      <div class="divTableHead text-center">
                                        {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Default') }}
                                        <v-btn
                                          icon
                                          small
                                          @click="toggleColumnOptionValues(option.OptionValues, 'Selected')"
                                        >
                                          <v-icon>check_box</v-icon>
                                        </v-btn>
                                        <v-btn
                                          icon
                                          small
                                          @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'Selected')"
                                        >
                                          <v-icon>settings_backup_restore</v-icon>
                                        </v-btn>
                                      </div>
                                      <div class="divTableHead text-center">
                                        {{ $t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Deactivated') }}
                                        <v-btn
                                          icon
                                          small
                                          @click="toggleColumnOptionValues(option.OptionValues, 'Mandatory')"
                                        >
                                          <v-icon>check_box</v-icon>
                                        </v-btn>
                                        <v-btn
                                          icon
                                          small
                                          @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'Mandatory')"
                                        >
                                          <v-icon>settings_backup_restore</v-icon>
                                        </v-btn>
                                      </div>
                                      <div class="divTableHead text-center">
                                        {{ $t('Restaurant.MenuOptionValueModifiersDialog.Field.Show') }}
                                        <v-btn
                                          icon
                                          small
                                          @click="toggleColumnOptionValues(option.OptionValues, 'Visible')"
                                        >
                                          <v-icon>check_box</v-icon>
                                        </v-btn>
                                        <v-btn
                                          icon
                                          small
                                          @click="resetColumnOptionValues(option.OptionValues, editMenu.Details.OptionsCopy[index].OptionValues, 'Visible')"
                                        >
                                          <v-icon>settings_backup_restore</v-icon>
                                        </v-btn>
                                      </div>
                                    </div>
                                  </div>

                                  <draggable
                                    v-model="option.OptionValues"
                                    class="divTableBody"
                                    handle=".my-handle"
                                  >
                                    <div
                                      v-for="(optionValue, idx) in option.OptionValues"
                                      :key="idx"
                                      class="divTableRow"
                                    >
                                      <div class="divTableCell">
                                        <v-icon
                                          class="my-handle"
                                          style="cursor: move;"
                                        >
                                          drag_handle
                                        </v-icon>
                                      </div>
                                      <div class="divTableCell">
                                        {{ optionValue.OptionValueId }}
                                      </div>
                                      <div
                                        class="divTableCell"
                                        v-html="optionValue.Name"
                                      />
                                      <div
                                        class="divTableCell"
                                        style="width: 120px;"
                                      >
                                        <v-checkbox
                                          v-model="optionValue.PriceCheckBox"
                                          :indeterminate="editMenu.Details.OptionsCopy[index].OptionValues[idx].Price !== optionValue.Price"
                                          :label="$t('Common.Button.Toggle.Active')"
                                          :readonly="editMenu.Details.OptionsCopy[index].OptionValues[idx].Price !== optionValue.Price"
                                          class="d-inline-block"
                                          color="red"
                                          hide-details
                                        />
                                        <br>
                                        <v-text-field
                                          v-model="optionValue.Price"
                                          :append-icon="appCurrency.Icon"
                                          :clearable="editMenu.Details.OptionsCopy[index].OptionValues[idx].Price !== optionValue.Price"
                                          :placeholder="$t('Restaurant.MenuOptionValueModifiersDialog.ListView.Header.Price')"
                                          clear-icon="settings_backup_restore"
                                          required
                                          single-line
                                          @click:clear="resetOptionValuePrice(optionValue, editMenu.Details.OptionsCopy[index].OptionValues[idx].Price)"
                                        />
                                      </div>
                                      <div class="divTableCell text-center">
                                        <v-checkbox
                                          v-model="optionValue.ShowPriceCheckBox"
                                          :indeterminate="editMenu.Details.OptionsCopy[index].OptionValues[idx].ShowPrice !== optionValue.ShowPrice"
                                          :label="$t('Common.Button.Toggle.Active')"
                                          :readonly="editMenu.Details.OptionsCopy[index].OptionValues[idx].ShowPrice !== optionValue.ShowPrice"
                                          class="d-inline-block"
                                          color="red"
                                          hide-details
                                        />
                                        <br>
                                        <v-switch
                                          :id="optionValue.Id + '_show_price'"
                                          v-model="optionValue.ShowPrice"
                                          inset
                                          :label="optionValue.ShowPrice ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                          color="success"
                                          hide-details
                                        />
                                      </div>
                                      <div class="divTableCell text-center">
                                        <v-checkbox
                                          v-model="optionValue.SelectedCheckBox"
                                          :indeterminate="editMenu.Details.OptionsCopy[index].OptionValues[idx].Selected !== optionValue.Selected"
                                          :label="$t('Common.Button.Toggle.Active')"
                                          :readonly="editMenu.Details.OptionsCopy[index].OptionValues[idx].Selected !== optionValue.Selected"
                                          class="d-inline-block"
                                          color="red"
                                          hide-details
                                        />
                                        <br>
                                        <v-switch
                                          :id="index + '_' + idx + '_' + optionValue.Id + '_optionValue_selected'"
                                          v-model="optionValue.Selected"
                                          inset
                                          :false-value="false"
                                          :label="optionValue.Selected ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                          :true-value="true"
                                          color="success"
                                          hide-details
                                          @change="optionValue.Selected ? '':optionValue.GroupId=''"
                                        />
                                      </div>
                                      <div class="divTableCell text-center">
                                        <v-checkbox
                                          v-model="optionValue.MandatoryCheckBox"
                                          :indeterminate="editMenu.Details.OptionsCopy[index].OptionValues[idx].Mandatory !== optionValue.Mandatory"
                                          :label="$t('Common.Button.Toggle.Active')"
                                          :readonly="editMenu.Details.OptionsCopy[index].OptionValues[idx].Mandatory !== optionValue.Mandatory"
                                          class="d-inline-block"
                                          color="red"
                                          hide-details
                                        />
                                        <br>
                                        <v-switch
                                          :id="index + '_' + idx + '_' + optionValue.Id + '_optionValue_mandatory'"
                                          v-model="optionValue.Mandatory"
                                          inset
                                          :false-value="false"
                                          :label="optionValue.Mandatory ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                          :true-value="true"
                                          color="success"
                                          hide-details
                                        />
                                      </div>
                                      <div class="divTableCell text-center">
                                        <v-checkbox
                                          v-model="optionValue.VisibleCheckBox"
                                          :indeterminate="!optionValue.Visible"
                                          :label="$t('Common.Button.Toggle.Active')"
                                          :readonly="!optionValue.Visible"
                                          class="d-inline-block"
                                          color="red"
                                          hide-details
                                        />
                                        <br>
                                        <v-switch
                                          :id="index + '_' + idx + '_' + optionValue.Id + '_optionValue_visible'"
                                          v-model="optionValue.Visible"
                                          inset
                                          :false-value="false"
                                          :label="optionValue.Visible ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                                          :true-value="true"
                                          color="success"
                                          hide-details
                                        />
                                      </div>
                                    </div>
                                  </draggable>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                    </template>
                  </template>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData   from '../../../mixins/appdata'
import Auth      from '../../../mixins/auth'
import Resizable from '../../../mixins/resizable'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable: draggable
  },
  mixins: [AppData, Auth, Resizable],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    menuToEdit: {
      type   : Object,
      default: undefined
    },
    optionToEdit: {
      type   : Object,
      default: undefined
    },
    optionTabToEdit: {
      type   : Object,
      default: undefined
    },
    optionValueToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      dynamicTab                 : 'dynamicTab0',
      menuSpecialStartDateVisible: false,
      menuSpecialEndDateVisible  : false,
      newMenu                    : null
    }
  },
  computed: {
    isVisible: {
      get () {
        if (this.visible) {
          this.setOptionsFromModifiers()
        }
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editMenu: {
      get () {
        if (this.menuToEdit) {
          this.newMenu = this.menuToEdit
        }
        return this.newMenu
      }
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.dynamicTab = 'dynamicTab0'
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    onRequiredChange (option) {
      if (option.Required) {
        if (option.MinChoices < 1) {
          option.MinChoices = 1
        }
      } else {
        option.MinChoices = 0
      }
    },

    onMinChoicesChange (option, num) {
      option.MinChoices += num
      if (option.MinChoices < 0) option.MinChoices = 0
      if (option.MaxChoices > 0 && option.MinChoices > option.MaxChoices) option.MaxChoices = option.MinChoices
      if (option.MinChoices > option.OptionValues.length) option.MinChoices = option.OptionValues.length
      if (option.MaxChoices > option.OptionValues.length) option.MaxChoices = option.OptionValues.length
      if (option.MinChoices > 0) {
        option.Required = true
      } else {
        option.Required = false
      }
    },

    onMaxChoicesChange (option, num) {
      option.MaxChoices += num
      if (option.MaxChoices < 0) option.MaxChoices = 0
      if (option.MinChoices > 0 && option.MaxChoices < option.MinChoices) option.MinChoices = option.MaxChoices
      if (option.MinChoices > option.OptionValues.length) option.MinChoices = option.OptionValues.length
      if (option.MaxChoices > option.OptionValues.length) option.MaxChoices = option.OptionValues.length
      if (option.MinChoices > 0) {
        option.Required = true
      } else {
        option.Required = false
      }
    },

    onRadioValueChange (option) {
      this.$nextTick(() => {
        option.OptionValues.forEach((optionValue) => {
          if (optionValue.Id !== option.DefaultValueId) {
            optionValue.GroupId = ''
          }
        })
      })
    },

    onSaveButtonClick () {
      this.setModifiersFromOptions()
      this.onCancelButtonClick()
    },

    toggleColumnOptionValues (optionValues, type) {
      optionValues.forEach((optionValue) => {
        this.$set(optionValue, type + 'CheckBox', optionValue[type + 'CheckBox'] !== true)
      })
    },

    resetColumnOptionValues (optionValues, optionValuesCopy, type) {
      optionValues.forEach((optionValue, index) => {
        this.$set(optionValue, type, optionValuesCopy[index].hasOwnProperty(type) ? optionValuesCopy[index][type] : true)
      })
    },

    resetOptionValuePrice (optionValue, newPrice) {
      this.$nextTick(() => {
        optionValue.Price = newPrice
      })
    },

    setModifiersFromOptions () {
      this.$set(this.optionValueToEdit, 'MultiValues', {})
      this.$set(this.optionValueToEdit.MultiValues, 'optionsModifiers', {})
      this.$set(this.optionValueToEdit.MultiValues, 'optionValuesModifiers', {})

      this.editMenu.Details.Options.forEach((option, o) => {
        if (parseInt(option.Id) !== parseInt(this.optionToEdit.Id)) {
          const obj = {}
          let hasChanges = false

          if (this.editMenu.Details.OptionsCopy[o].MaxChoices !== option.MaxChoices || option.MaxChoicesCheckBox) {
            obj.MaxChoices = parseInt(option.MaxChoices)
            if (option.MaxChoicesCheckBox) obj.MaxChoicesCheckBox = true
            hasChanges = true
          }

          if (this.editMenu.Details.OptionsCopy[o].MinChoices !== option.MinChoices || option.MinChoicesCheckBox) {
            obj.MinChoices = parseInt(option.MinChoices)
            if (option.MinChoicesCheckBox) obj.MinChoicesCheckBox = true
            hasChanges = true
          }

          if (this.editMenu.Details.OptionsCopy[o].Required !== option.Required || option.RequiredCheckBox) {
            obj.Required = option.Required
            if (option.RequiredCheckBox) obj.RequiredCheckBox = true
            hasChanges = true
          }

          if (this.editMenu.Details.OptionsCopy[o].Priority !== option.Priority) {
            obj.Priority = parseInt(option.Priority)
            hasChanges = true
          }

          if (!option.Visible || option.VisibleCheckBox) {
            obj.Visible = option.Visible
            if (option.VisibleCheckBox) obj.VisibleCheckBox = true
            hasChanges = true
          }

          if (hasChanges) this.$set(this.optionValueToEdit.MultiValues.optionsModifiers, option.Id, obj)

          option.OptionValues.forEach((optionValue, v) => {
            const obj = {}
            let hasChanges = false

            if (this.editMenu.Details.OptionsCopy[o].OptionValues[v].Price !== optionValue.Price || optionValue.PriceCheckBox) {
              obj.Price = parseFloat(optionValue.Price).toFixed(2)
              if (optionValue.PriceCheckBox) obj.PriceCheckBox = true
              hasChanges = true
            }

            if (this.editMenu.Details.OptionsCopy[o].OptionValues[v].Mandatory !== optionValue.Mandatory || optionValue.MandatoryCheckBox) {
              obj.Disabled = optionValue.Mandatory
              if (optionValue.MandatoryCheckBox) obj.MandatoryCheckBox = true
              hasChanges = true
            }

            if (this.editMenu.Details.OptionsCopy[o].OptionValues[v].Selected !== optionValue.Selected || optionValue.SelectedCheckBox) {
              obj.Selected = optionValue.Selected
              if (optionValue.SelectedCheckBox) obj.SelectedCheckBox = true
              hasChanges = true
            }

            if (this.editMenu.Details.OptionsCopy[o].OptionValues[v].Priority !== optionValue.Priority) {
              obj.Priority = optionValue.Priority
              hasChanges = true
            }

            if (!optionValue.Visible || optionValue.VisibleCheckBox) {
              obj.Visible = optionValue.Visible
              if (optionValue.VisibleCheckBox) obj.VisibleCheckBox = true
              hasChanges = true
            }

            if (this.editMenu.Details.OptionsCopy[o].OptionValues[v].ShowPrice !== optionValue.ShowPrice || optionValue.ShowPriceCheckBox) {
              obj.ShowPrice = optionValue.ShowPrice
              if (optionValue.ShowPriceCheckBox) obj.ShowPriceCheckBox = true
              hasChanges = true
            }

            if (hasChanges) this.$set(this.optionValueToEdit.MultiValues.optionValuesModifiers, optionValue.Id, obj)
          })
        }
      })
    },

    setOptionsFromModifiers () {
      this.editMenu.Details.Options.forEach((option) => {
        if (!option.hasOwnProperty('Visible')) this.$set(option, 'Visible', true)
        option.OptionValues.forEach((optionValue) => {
          if (!optionValue.hasOwnProperty('Visible')) this.$set(optionValue, 'Visible', true)
        })
      })

      if (this.optionValueToEdit.MultiValues) {
        if (this.optionValueToEdit.MultiValues.hasOwnProperty('optionsModifiers')) {
          for (const optionId in this.optionValueToEdit.MultiValues.optionsModifiers) {
            const option = this.newMenu.Details.Options.find(menuOption => parseInt(menuOption.Id) === parseInt(optionId))
            const optionModifier = this.optionValueToEdit.MultiValues.optionsModifiers[optionId]

            if (optionModifier.hasOwnProperty('MaxChoices')) option.MaxChoices = optionModifier.MaxChoices
            if (optionModifier.hasOwnProperty('MaxChoicesCheckBox')) option.MaxChoicesCheckBox = optionModifier.MaxChoicesCheckBox
            if (optionModifier.hasOwnProperty('MinChoices')) option.MinChoices = optionModifier.MinChoices
            if (optionModifier.hasOwnProperty('MinChoicesCheckBox')) option.MinChoicesCheckBox = optionModifier.MinChoicesCheckBox
            if (optionModifier.hasOwnProperty('Required')) option.Required = optionModifier.Required
            if (optionModifier.hasOwnProperty('RequiredCheckBox')) option.RequiredCheckBox = optionModifier.RequiredCheckBox
            if (optionModifier.hasOwnProperty('Priority')) option.Priority = optionModifier.Priority
            if (optionModifier.hasOwnProperty('Visible')) {
              option.Visible = optionModifier.Visible
              option.VisibleCheckBox = optionModifier.VisibleCheckBox
            } else {
              option.Visible = true
            }
            if (optionModifier.hasOwnProperty('ShowPrice')) option.ShowPrice = optionModifier.ShowPrice
          }
        }

        if (this.optionValueToEdit.MultiValues.hasOwnProperty('optionValuesModifiers')) {
          for (const optionValueId in this.optionValueToEdit.MultiValues.optionValuesModifiers) {
            const option = this.newMenu.Details.Options.find(menuOption => menuOption.OptionValues.some(menuOptionValue => parseInt(menuOptionValue.Id) === parseInt(optionValueId)))
            const optionValue = option.OptionValues.find(menuOptionValue => parseInt(menuOptionValue.Id) === parseInt(optionValueId))
            const optionModifier = this.optionValueToEdit.MultiValues.optionValuesModifiers[optionValueId]

            if (optionModifier.hasOwnProperty('Priority')) optionValue.Priority = optionModifier.Priority
            if (optionModifier.hasOwnProperty('PriorityCheckBox')) optionValue.PriorityCheckBox = optionModifier.PriorityCheckBox
            if (optionModifier.hasOwnProperty('Disabled')) optionValue.Mandatory = optionModifier.Disabled
            if (optionModifier.hasOwnProperty('MandatoryCheckBox')) optionValue.MandatoryCheckBox = optionModifier.MandatoryCheckBox
            if (optionModifier.hasOwnProperty('Selected')) optionValue.Selected = optionModifier.Selected
            if (optionModifier.hasOwnProperty('SelectedCheckBox')) optionValue.SelectedCheckBox = optionModifier.SelectedCheckBox
            if (optionModifier.hasOwnProperty('Visible')) {
              optionValue.Visible = optionModifier.Visible
              optionValue.VisibleCheckBox = optionModifier.VisibleCheckBox
            } else {
              optionValue.Visible = true
            }
            if (optionModifier.hasOwnProperty('ShowPrice')) optionValue.ShowPrice = optionModifier.ShowPrice
            if (optionModifier.hasOwnProperty('ShowPriceCheckBox')) optionValue.ShowPriceCheckBox = optionModifier.ShowPriceCheckBox
            if (optionModifier.hasOwnProperty('Price')) {
              optionValue.Price = parseFloat(optionModifier.Price).toFixed(2)
              optionValue.PriceCheckBox = optionModifier.PriceCheckBox
              optionValue.PriceFmt = this.$options.filters.currency(optionModifier.Price)
            }
          }
        }
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.dynamicTab = 'dynamicTab0'
      this.newMenu = null
    },

    onResize () {
      const card = this.$refs.dialogCard
      const cardText = this.$refs.dialogCardText
      if (card && card.$el && cardText) {
        cardText.style.height = 800 + 'px'
        cardText.style.height = (card.$el.offsetHeight - 200) + 'px'
      }
    }
  }
}
</script>

<style scoped>
/deep/ input[aria-checked="mixed"] ~ i {
  color       : #f48509 !important;
  caret-color : #f48509 !important;
}

/deep/ .v-input--radio-group .v-input__control {
  width : 100%;
}

/deep/ .v-input--radio-group .v-radio {
  display        : inline-block;
  max-width      : 24px;
  vertical-align : middle;
  margin         : 0;
  padding        : 0;
}

/deep/ .v-input--radio-group .v-radio label {
  display : none;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  width    : 75px !important;
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 14px !important;
  padding-right : 0;
  margin        : 0;
}

.divTable {
  display     : table;
  width       : 100%;
  padding-top : 12px;
  font-size   : 14px;
  color       : rgba(0, 0, 0, 0.85);
}

.divTableCell, .divTableHead {
  border-bottom : 1px solid lightgrey !important;
  padding       : 3px 10px;
}

.divTableCellInner {
  display        : inline-block;
  vertical-align : middle;
  padding        : 5px;
  height         : 100%;
}

.divTableHead button {
  margin : 0;
}
</style>
