<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Reservation.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Reservation.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Reservation.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Reservation.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-form
          ref="settingsForm"
          v-model="settingsFormValid"
          autocomplete="off"
          @submit.prevent
        >
          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Reservation.Section.Duration.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Reservation.Section.Duration.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Duration.Field.TimeIntervalOnline.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Duration.Field.TimeIntervalOnline.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.TimeIntervalOnline"
                      :label="$t('Settings.Reservation.Section.Duration.Field.TimeIntervalOnline.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.TimeIntervalOnlineValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Duration.Field.TimeSlotBetween.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Duration.Field.TimeSlotBetween.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.TimeSlotBetween"
                      :label="$t('Settings.Reservation.Section.Duration.Field.TimeSlotBetween.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.TimeSlotBetweenValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Duration.Field.AllowedMinutesStep.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Duration.Field.AllowedMinutesStep.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.AllowedMinutesStep"
                      :label="$t('Settings.Reservation.Section.Duration.Field.AllowedMinutesStep.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.AllowedMinutesStepValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Duration.Field.MaxDaysInAdvance.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Duration.Field.MaxDaysInAdvance.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.MaxDaysInAdvance"
                      :label="$t('Settings.Reservation.Section.Duration.Field.MaxDaysInAdvance.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.MaxDaysInAdvanceValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Duration.Field.MinTimeInAdvance.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Duration.Field.MinTimeInAdvance.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.MinTimeInAdvance"
                      :label="$t('Settings.Reservation.Section.Duration.Field.MinTimeInAdvance.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.MinTimeInAdvanceValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Duration.Field.DurationDefault.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Duration.Field.DurationDefault.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.DurationDefault"
                      :label="$t('Settings.Reservation.Section.Duration.Field.DurationDefault.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.DurationDefaultValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Duration.Field.UseDurationByPeople.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Duration.Field.UseDurationByPeople.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-switch
                      v-model="viewData.UseDurationByPeople"
                      :disabled="!userCanManage"
                      :label="viewData.UseDurationByPeople ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      class="ma-0"
                      hide-details
                      inset
                    />
                  </v-col>

                  <v-col
                    v-if="viewData.UseDurationByPeople"
                    cols="12"
                  >
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('Settings.Reservation.Section.Duration.Field.DurationByPeople.MinPeople') }}
                          </th>
                          <th class="text-left">
                            {{ $t('Settings.Reservation.Section.Duration.Field.DurationByPeople.MaxPeople') }}
                          </th>
                          <th class="text-left">
                            {{ $t('Settings.Reservation.Section.Duration.Field.DurationByPeople.Duration') }}
                          </th>
                          <th class="text-right" />
                        </tr>
                      </thead>

                      <tbody>
                        <template v-for="(item, idx) in viewData.DurationByPeople">
                          <tr :key="`duration-by-people-${idx}`">
                            <td>
                              <v-text-field
                                v-model="item.min"
                                v-mask="'####'"
                                type="number"
                                min="1"
                                step="1"
                                class="d-inline-block"
                                single-line
                                hide-details
                                outlined
                                dense
                                flat
                              />
                            </td>

                            <td>
                              <v-text-field
                                v-model="item.max"
                                v-mask="'####'"
                                type="number"
                                min="1"
                                step="1"
                                class="d-inline-block"
                                single-line
                                hide-details
                                outlined
                                dense
                                flat
                              />
                            </td>

                            <td>
                              <v-select
                                v-model="item.duration"
                                :disabled="!userCanManage"
                                :items="viewData.DurationDefaultValues"
                                item-text="Name"
                                item-value="Value"
                                class="d-inline-block"
                                single-line
                                hide-details
                                outlined
                                dense
                                flat
                              />
                            </td>

                            <td class="text-right">
                              <v-btn
                                depressed
                                small
                                icon
                                @click="removeDurationRule(idx)"
                              >
                                <v-icon left>
                                  mdi mdi-minus-circle-outline
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </v-simple-table>

                    <div class="mt-2 text-right">
                      <v-btn
                        depressed
                        color="primary"
                        @click="addDurationRule"
                      >
                        <v-icon left>
                          {{ $t('Settings.Reservation.Section.Duration.Field.DurationByPeople.Button.Add.Icon') }}
                        </v-icon>
                        {{ $t('Settings.Reservation.Section.Duration.Field.DurationByPeople.Button.Add.Title') }}
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Reservation.Section.People.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Reservation.Section.People.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.People.Field.MinPeopleOnline.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.People.Field.MinPeopleOnline.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.MinPeopleOnline"
                      :label="$t('Settings.Reservation.Section.People.Field.MinPeopleOnline.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.MinPeopleOnlineValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.People.Field.MaxPeopleOnline.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.People.Field.MaxPeopleOnline.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.MaxPeopleOnline"
                      :label="$t('Settings.Reservation.Section.People.Field.MaxPeopleOnline.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.MaxPeopleOnlineValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.People.Field.PersonsThreshold.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.People.Field.PersonsThreshold.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-select
                      v-model="viewData.PersonsThreshold"
                      :label="$t('Settings.Reservation.Section.People.Field.PersonsThreshold.Title')"
                      :disabled="!userCanManage"
                      :items="viewData.PersonsThresholdValues"
                      item-text="Name"
                      item-value="Value"
                      class="ma-0"
                      single-line
                      hide-details
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Reservation.Section.Manage.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Reservation.Section.Manage.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Manage.Field.AutoUpdateTables.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Manage.Field.AutoUpdateTables.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-switch
                      v-model="viewData.AutoUpdateTables"
                      :disabled="!userCanManage"
                      :label="viewData.AutoUpdateTables ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                      color="success"
                      class="ma-0"
                      hide-details
                      inset
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Manage.Field.TableOverwrite.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Manage.Field.TableOverwrite.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-switch
                      v-model="viewData.TableOverwrite"
                      :disabled="!userCanManage"
                      :label="viewData.TableOverwrite ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                      color="success"
                      class="ma-0"
                      hide-details
                      inset
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Reservation.Section.Manage.Field.OnlineBooking.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Reservation.Section.Manage.Field.OnlineBooking.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink shrink-col-min-width">
                    <v-switch
                      v-model="viewData.OnlineBooking"
                      :disabled="!userCanManage"
                      :label="viewData.OnlineBooking ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                      color="success"
                      class="ma-0"
                      hide-details
                      inset
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>

      <v-btn
        v-if="userCanManage"
        :disabled="!viewData || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  components: {},
  mixins    : [AppData, Auth],
  data () {
    return {
      settingsFormValid: false,
      isSaving         : false,
      viewData         : null,
      validationRules  : {}
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Reservations.Get, this.onGetDataResponse)
    this.$bus.$on(window.SocketCommand.Settings.Reservations.Save, this.onSaveDataResponse)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Reservations.Get, this.onGetDataResponse)
    this.$bus.$off(window.SocketCommand.Settings.Reservations.Save, this.onSaveDataResponse)
  },
  methods: {
    getData () {
      window.callAS(window.SocketCommand.Settings.Reservations.Get, {})
    },

    onGetDataResponse (result) {
      this.viewData = result
    },

    addDurationRule () {
      if (!this.viewData?.DurationByPeople) return

      this.viewData.DurationByPeople.push({
        min     : 1,
        max     : 2,
        duration: 120
      })
    },

    removeDurationRule (index) {
      if (parseInt(index) < 0) return

      this.viewData.DurationByPeople.splice(parseInt(index), 1)
    },

    onSaveClick () {
      this.$refs?.settingsForm && this.$refs.settingsForm.validate()

      if (this.settingsFormValid) {
        this.isSaving = true
        const payload = JSON.parse(JSON.stringify(this.viewData, (key, value) => key.endsWith('Values') ? undefined : value))

        window.callAS(window.SocketCommand.Settings.Reservations.Save, payload)
      }
    },

    onSaveDataResponse (result) {
      this.isSaving = false

      if (result.status) {
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Documents.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
.shrink-col-min-width {
  min-width : 260px;
}
</style>
