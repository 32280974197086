<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      extension-height="40"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.PinManager.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.PinManager.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.PinManager.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.PinManager.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <template #extension>
        <v-tabs
          v-if="viewData"
          v-model="tab"
          background-color="transparent"
          grow
          height="40"
          slider-color="blue"
        >
          <template v-for="group in viewData">
            <v-tab
              v-if="group.Users && group.Users.length"
              :key="`tab-${group.Id}`"
              :href="`#tab-${group.Id}`"
            >
              {{ group.Name }}
            </v-tab>
          </template>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-form
          ref="settingsForm"
          v-model="settingsFormValid"
          autocomplete="off"
          @submit.prevent
        >
          <v-tabs-items v-model="tab">
            <template v-for="(group, groupIdx) in viewData">
              <v-tab-item
                v-if="group.Users && group.Users.length"
                :key="`tab-item-${group.Id}-${groupIdx}`"
                :value="`tab-${group.Id}`"
              >
                <v-container fluid>
                  <v-row dense>
                    <v-col>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.PinManager.Enabled.Title') }}
                      </h5>
                      <span class="text-caption">{{ $t('Settings.PinManager.Enabled.Subtitle') }}</span>
                    </v-col>
                    <v-col class="shrink">
                      <v-switch
                        v-model="group.Enabled"
                        inset
                        :disabled="!userCanManage"
                        :label="group.Enabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                        color="success"
                        class="ma-0"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-card
                        flat
                        class="pb-4"
                      >
                        <v-card-title>{{ $tc('Staff.StaffGroups.Misc.User', group.Users.length) }}</v-card-title>
                        <v-card-text class="pa-0">
                          <v-list
                            dense
                            class="pa-0"
                          >
                            <v-container
                              class="pa-0"
                              fluid
                            >
                              <v-row dense>
                                <template v-for="(user, userIdx) in group.Users">
                                  <v-col
                                    :key="`user-${userIdx}`"
                                    cols="auto"
                                  >
                                    <v-list-item>
                                      <v-list-item-avatar :color="user.Color ? `#${user.Color}` : 'indigo'">
                                        <v-icon dark>
                                          account_circle
                                        </v-icon>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>{{ user.Fullname }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ user.Username }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-col>
                                </template>
                              </v-row>
                            </v-container>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <template v-if="group.Enabled">
                      <v-col cols="12">
                        <v-divider class="" />
                      </v-col>

                      <v-col cols="12">
                        <v-card flat>
                          <v-card-text class="pa-0">
                            <v-container
                              fluid
                              class="pa-0"
                            >
                              <v-row
                                dense
                                align="center"
                              >
                                <v-col>
                                  <h5 class="text-body-2 font-weight-bold">
                                    {{ $t('Settings.PinManager.Pin.Title') }}
                                  </h5>
                                  <span class="text-caption">{{ $t('Settings.PinManager.Pin.Subtitle') }}</span>
                                </v-col>
                                <v-col class="shrink">
                                  <v-text-field
                                    v-model="group.Pin"
                                    v-mask="'####'"
                                    :disabled="!userCanManage"
                                    :rules="getPinValidationRules(group)"
                                    class="elevation-0 d-inline-block ml-2"
                                    autocomplete="one-time-code"
                                    hide-details
                                    required
                                    outlined
                                    single-line
                                    style="min-width: 120px; max-width: 120px;"
                                    maxlength="4"
                                    :counter="4"
                                    :type="!managerPinVisible ? 'password' : 'text'"
                                    :append-icon="!managerPinVisible ? 'visibility' : 'visibility_off'"
                                    @click:append="(managerPinVisible = !managerPinVisible)"
                                  />
                                </v-col>

                                <v-col cols="12">
                                  <v-divider class="" />
                                </v-col>

                                <v-col cols="12">
                                  <h3 class="mb-2 grey--text mt-3">
                                    {{ $t('Settings.PinManager.Permissions.Title') }}
                                  </h3>
                                </v-col>

                                <template v-for="(permissionGroup, permissionGroupIdx) in $t('Settings.PinManager.PermissionGroups')">
                                  <v-col
                                    :key="`permission-group-${permissionGroupIdx}`"
                                    cols="12"
                                  >
                                    <v-card flat>
                                      <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                                        <v-icon class="pr-3">
                                          {{ permissionGroup.Icon }}
                                        </v-icon>

                                        <h5 class="text-body-2 font-weight-bold">
                                          {{ permissionGroup.Title }}
                                        </h5>
                                      </v-card-title>

                                      <v-divider />

                                      <v-card-text>
                                        <v-container
                                          fluid
                                          class="pa-0"
                                        >
                                          <v-row
                                            dense
                                            align="center"
                                          >
                                            <template v-for="(permission, permissionKey, permissionIdx) in permissionGroup.Permissions">
                                              <v-col :key="`group-${group.Id}-${permissionGroupIdx}-permission-${permissionKey}-${permissionIdx}-title`">
                                                <h5 class="text-body-2 font-weight-bold">
                                                  {{ permission.Title }}
                                                </h5>
                                                <span class="text-caption">{{ permission.Subtitle }}</span>
                                              </v-col>

                                              <v-col
                                                :key="`group-${group.Id}-${permissionGroupIdx}-permission-${permissionKey}-${permissionIdx}-value`"
                                                class="shrink"
                                              >
                                                <v-switch
                                                  v-model="group.Permissions[permissionKey]"
                                                  inset
                                                  :disabled="!userCanManage"
                                                  :label="group.Permissions[permissionKey] ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                                  color="success"
                                                  class="ma-0"
                                                  hide-details
                                                />
                                              </v-col>

                                              <v-col
                                                :key="`group-${group.Id}-${permissionGroupIdx}-permission-${permissionKey}-${permissionIdx}-divider`"
                                                cols="12"
                                              >
                                                <v-divider class="" />
                                              </v-col>
                                            </template>
                                          </v-row>
                                        </v-container>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </template>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>

                    <v-col cols="12">
                      <v-divider class="" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-form>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData              from '@/mixins/appdata'
import Auth                 from '@/mixins/auth'
import Translatable         from '@/mixins/translatable'
import { AES }              from '@/lib/crypto/crypto'
import { clone, mergeDeep } from '@/lib/helper/helper'
import PinManagerEnum       from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'SystemSettingsPinManager',
  components: {},
  mixins    : [AppData, Auth, Translatable],
  data () {
    return {
      tab              : null,
      settingsFormValid: false,
      managerPinVisible: false,
      isSaving         : false,
      viewData         : null,
      validationRules  : {
        Pin: [
          (v) => v && v.match(/^\d{4}$/i) !== null && this.viewData.PinManager.Enabled || this.$t('Settings.PinManager.Tables.ManagerPin.Pin.Error')
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.PinManager.Get, this.onGetDataResponse)
    this.$bus.$on(window.SocketCommand.Settings.PinManager.Save, this.onSaveDataResponse)
    this.getData()
  },
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.PinManager.Get, this.onGetDataResponse)
    this.$bus.$off(window.SocketCommand.Settings.PinManager.Save, this.onSaveDataResponse)
  },
  methods: {

    getPinValidationRules (group) {
      return [(v) => v && v.match(/^\d{4}$/i) !== null && group.Enabled || this.$t('Settings.PinManager.Pin.Error')]
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.PinManager.Get, {})
      this.onGetDataResponse()
    },

    onGetDataResponse (result) {
      const defaultPinManagerObj = {
        Enabled    : false,
        Pin        : '',
        Permissions: Object.keys(PinManagerEnum).reduce((acc, key) => ({
          ...acc,
          [key]: false
        }), {})
      }

      result = result?.map(group => {
        const retVal = mergeDeep(defaultPinManagerObj, group || {})
        if (retVal.Pin) retVal.Pin = AES.decrypt(retVal.Pin)
        return retVal
      }) || []

      this.viewData = result
    },

    onSaveClick () {
      this.$refs?.settingsForm && this.$refs.settingsForm.validate()

      if (this.settingsFormValid) {
        this.isSaving = true
        let payload = clone(this.viewData)

        payload = payload.map(group => {
          if (group.Pin) group.Pin = AES.encrypt(group.Pin)
          return group
        })

        window.callAS(window.SocketCommand.Settings.PinManager.Save, { PinManager: payload })
      }
    },

    onSaveDataResponse (result) {
      this.isSaving = false

      if (result.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Documents.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
