import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class DomainValidator extends BaseValidator {
  rules = {
    Id: {
      required: true,
      type    : 'number'
    },
    CustomerId: {
      required: false,
      type    : 'numeric'
    },
    LeadId: {
      required: false,
      type    : 'numeric'
    },
    CountryId: {
      required: false,
      type    : 'number'
    },
    Domain: {
      required: true,
      type    : 'string',
      minLen  : 3
    },
    BaseDomain: {
      required: true,
      type    : 'string'
    },
    Subdomain: {
      required: true,
      type    : 'string'
    },
    Active: {
      required: true,
      type    : 'boolean'
    },
    CName: {
      required: false,
      type    : 'string'
    },
    CNameActive: {
      required: true,
      type    : 'boolean'
    },
    VoipDomain: {
      required: false,
      type    : 'string'
    },
    VoipDomainActive: {
      required: true,
      type    : 'boolean'
    },
    VoipDomainDescription: {
      required: false,
      type    : 'string'
    },
    DatabaseName: {
      required: true,
      type    : 'string'
    },
    DatabaseAutoGenerate: {
      required: true,
      type    : 'boolean'
    },
    StoreGroup: {
      required: true,
      type    : 'number'
    },
    IsDemo: {
      required: true,
      type    : 'boolean'
    },
    ForceSSL: {
      required: true,
      type    : 'boolean'
    },
    ForceAssets: {
      required: true,
      type    : 'boolean'
    },
    PurgeCache: {
      required: true,
      type    : 'boolean'
    },
    Reserved: {
      required: true,
      type    : 'boolean'
    },
    AllowIFrame: {
      required: true,
      type    : 'boolean'
    },
    QrOnly: {
      required: true,
      type    : 'boolean'
    },
    HasLandingPage: {
      required: true,
      type    : 'boolean'
    },
    RedirectToLandingPage: {
      requiredIf: ['HasLandingPage', true],
      type      : 'boolean'
    },
    LandingPageUrl: {
      requiredIf: ['HasLandingPage', true],
      type      : 'url'
    },
    ShowOrderTypes: {
      required: true,
      type    : 'boolean'
    },
    DefaultOrderType: {
      requiredIf: ['ShowOrderTypes', true],
      type      : 'number'
    },
    DefaultOrderTypePos: {
      required: true,
      type    : 'number'
    },
    WithDummyData: {
      required: true,
      type    : 'boolean'
    },
    ImportFromThirdParty: {
      required: true,
      type    : 'boolean'
    },
    ImportFromThirdPartyForce: {
      requiredIf: ['ImportFromThirdParty', true],
      type      : 'boolean'
    },
    ImportFromThirdPartyWithOptions: {
      requiredIf: ['ImportFromThirdParty', true],
      type      : 'boolean'
    },
    ImportFromThirdPartyAllLocales: {
      requiredIf: ['ImportFromThirdParty', true],
      type      : 'boolean'
    },
    ImportFromThirdPartyDefaultLocale: {
      requiredIf: ['ImportFromThirdParty', true],
      type      : 'string'
    },

    CreateLead: {
      required: true,
      type    : 'boolean'
    },
    Firstname: {
      requiredIf: ['CreateLead', true],
      type      : 'string',
      betweenLen: [2, 255]
    },
    Lastname: {
      requiredIf: ['CreateLead', true],
      type      : 'string',
      betweenLen: [2, 255]
    },
    Company: {
      required  : false,
      type      : 'string',
      betweenLen: [2, 255]
    },
    Email: {
      requiredIf: ['CreateLead', true],
      type      : 'email'
    },
    Telephone: {
      required  : false,
      type      : 'string',
      betweenLen: [8, 16]
    },

    TrialExpiredAt: {
      required: false,
      type    : 'dateStringDash'
    },
    SubscriptionExpiredAt: {
      required: false,
      type    : 'dateStringDash'
    },
    SeasonStartedAt: {
      required: false,
      type    : 'dateNoYearStringDash'
    },
    SeasonEndedAt: {
      required: false,
      type    : 'dateNoYearStringDash'
    },
    ExtendedPeriodAt: {
      required: false,
      type    : 'dateStringDash'
    },

    ThirdPartyUrl: {
      requiredIf: ['ImportFromThirdParty', true],
      type      : 'string'
    },
    'Application.id': {
      required: true,
      type    : 'string'
    },
    'Application.key': {
      required: true,
      type    : 'string'
    },
    'Application.name': {
      required: true,
      type    : 'string'
    },
    'Application.secret': {
      required: true,
      type    : 'string'
    }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
