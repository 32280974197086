<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card v-if="pageData">
        <v-toolbar
          flat
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Website.PageEditDialog.Toolbar.Color')"
          >
            <v-icon color="indigo lighten-5">
              {{ $t('Settings.Website.PageEditDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Website.PageEditDialog.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ pageData.Page[defaultLanguage.Code] ? pageData.Page[defaultLanguage.Code].Heading : '' }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            class="ml-0"
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template
            v-if="Object.keys(pageData.Page).length > 0 && selectedLanguages.length > 0"
            #extension
          >
            <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
              height="40"
              slider-color="blue"
            >
              <template v-for="(page, key) in pageData.Page">
                <v-tab
                  v-if="getLanguageByCode(key)"
                  :key="key"
                >
                  <v-avatar
                    v-if="getLanguageByCode(key).ImageUrl"
                    class="mr-2"
                    size="20"
                  >
                    <v-img :src="getLanguageByCode(key).ImageUrl" />
                  </v-avatar>
                  {{ getLanguageByCode(key).Name }}
                </v-tab>
              </template>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="pageForm"
            v-model="pageFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="tab">
              <template v-for="(page, key) in pageData.Page">
                <v-tab-item
                  v-if="getLanguageByCode(key)"
                  :key="key"
                >
                  <v-container
                    class="fill-height pa-0"
                    fluid
                  >
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          v-model="page.Heading"
                          :counter="255"
                          :disabled="!userCanManage"
                          :label="$t('Settings.Website.PageEditDialog.Field.Title.Label')"
                          :placeholder="$t('Settings.Website.PageEditDialog.Field.Title.PlaceHolder')"
                          :rules="validationRules.Title"
                          filled
                        />
                      </v-col>
                      <v-col class="shrink">
                        <div style="min-width: 120px;">
                          <v-switch
                            v-model="page.Status"
                            inset
                            :disabled="!userCanManage"
                            :label="page.Status ? $t('Settings.Website.PageEditDialog.Field.Status.Active') : $t('Settings.Website.PageEditDialog.Field.Status.Inactive')"
                            color="success"
                            hide-details
                          />
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <vue-editor
                          v-model="page.Content"
                          :disabled="!userCanManage"
                          :editor-options="editorSettings"
                          :editor-toolbar="userCanManage ? editorToolbar : []"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
          <v-btn
            class="elevation-0"
            color="light-green"
            dark
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData              from '../../../mixins/appdata'
import Auth                 from '../../../mixins/auth'
import { Quill, VueEditor } from 'vue2-editor'
import MagicUrl             from 'quill-magic-url'

const AlignStyle = Quill.import('attributors/style/align')
const BackgroundStyle = Quill.import('attributors/style/background')
const DirectionStyle = Quill.import('attributors/style/direction')
const ColorStyle = Quill.import('attributors/style/color')
const SizeStyle = Quill.import('attributors/style/size')
const BaseImageFormat = Quill.import('formats/image')
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style']

// eslint-disable-next-line no-unused-vars
class ImageFormat extends BaseImageFormat {
  static formats (domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      return formats
    }, {})
  }

  format (name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

// Quill.register(ImageFormat, true)
Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(ColorStyle, true)
Quill.register(SizeStyle, true)
Quill.register('modules/magicUrl', MagicUrl)
// Quill.register('modules/imageDrop', ImageDrop)
// Quill.register('modules/imageResize', ImageResize)

export default {
  name      : 'PageEditDialog',
  components: { VueEditor },
  mixins    : [AppData, Auth],
  props     : {
    pageData: {
      type    : Object,
      default : undefined,
      required: false
    },
    visible: {
      type    : Boolean,
      default : false,
      required: true
    }
  },
  data () {
    return {
      tab            : null,
      pageFormValid  : false,
      validationRules: {
        Title: [
          (v) => !!v || this.$t('Settings.Website.PageEditDialog.Field.Title.Error.Mandatory'),
          (v) => v && v.length >= 2 && v.length <= 255 || this.$t('Settings.Website.PageEditDialog.Field.Title.Error.Between')
        ]
      },
      editorToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // ['bold', 'italic', 'underline', 'strike'],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ header: '1' }, { header: '2' }, 'blockquote'],
        // [{ 'color': [] }, { 'background': [] }],
        [{ color: [] }],
        // [{ 'script': 'super' }, { 'script': 'sub' }],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }, { indent: '-1' }],
        [{ indent: '-1' }, { indent: '+1' }],
        // ['link', 'image', 'video'],
        ['link'],
        ['clean']
      ],
      editorSettings: {
        debug  : 'error',
        modules: {
          magicUrl: true
          // imageDrop  : true,
          // imageResize: {}
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Website.SavePage, this.onSavePageResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Website.SavePage, this.onSavePageResult)
  },
  methods: {
    getLanguageByCode (langCode) {
      return this.selectedLanguages.find(lang => lang.Code === langCode)
    },
    onSaveButtonClick () {
      if (this.$refs.pageForm.validate()) {
        window.callAS(window.SocketCommand.Settings.Website.SavePage, this.pageData)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Website.PageEditDialog.Notification.CheckFields'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    onCancelButtonClick () {
      if (this.$refs.pageForm) this.$refs.pageForm.resetValidation()
      this.isVisible = false
    },
    onSavePageResult (result) {
      if (result && result.status) {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Website.PageEditDialog.Notification.Success'),
          type: 'success',
          icon: 'info'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Website.PageEditDialog.Notification.TryAgain'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>
