<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Customer.CustomerGroupAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Customer.CustomerGroupAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!categoryToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Customer.CustomerGroupAddDialog.Toolbar.Add.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerGroupAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Customer.CustomerGroupAddDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerGroupAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="categoryForm"
            v-model="categoryFormValid"
            @submit.prevent
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editCategory.Name"
                    :counter="32"
                    :hint="$t('Customer.CustomerGroupAddDialog.Field.Name.Hint')"
                    :label="$t('Customer.CustomerGroupAddDialog.Field.Name.Label')"
                    :prepend-icon="$t('Customer.CustomerGroupAddDialog.Field.Name.Icon')"
                    :rules="validationRules.categoryForm.Name"
                    class="pr-2"
                    required
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-select
                    v-model="editCategory.SourceTypeId"
                    :hint="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Hint')"
                    :items="sourceTypes"
                    :label="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Label')"
                    :prepend-icon="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Icon')"
                    item-text="Name"
                    item-value="Id"
                    persistent-hint
                    clearable
                  >
                    <template #selection="{item}">
                      <v-chip
                        small
                        label
                        :color="item.Status ? 'green' : ''"
                        :dark="item.Status"
                        class="pl-0"
                      >
                        <v-avatar
                          tile
                          :color="item.Avatar.Color"
                          class="mr-2"
                        >
                          <v-icon
                            v-if="item.Avatar.Icon"
                            class="white--text"
                          >
                            {{ item.Avatar.Icon }}
                          </v-icon>

                          <img
                            v-else
                            :src="item.Avatar.Img"
                          >
                        </v-avatar>
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :size="28"
                        :color="data.item.Avatar.Color"
                      >
                        <v-icon
                          v-if="data.item.Avatar.Icon"
                          class="white--text"
                        >
                          {{ data.item.Avatar.Icon }}
                        </v-icon>

                        <img
                          v-else
                          :src="data.item.Avatar.Img"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editCategory.Description"
                    :counter="200"
                    :hint="$t('Customer.CustomerGroupAddDialog.Field.Description.Hint')"
                    :label="$t('Customer.CustomerGroupAddDialog.Field.Description.Label')"
                    :prepend-icon="$t('Customer.CustomerGroupAddDialog.Field.Description.Icon')"
                    :rules="validationRules.categoryForm.Description"
                    class="pr-2"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import SourceType from '@/mixins/orders/sourceType'

export default {
  components: {},
  mixins    : [SourceType],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    categoryToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading          : false,
      categoryFormValid: false,
      newCategory      : null,
      tmpCategory      : {
        Id          : null,
        SourceTypeId: null,
        Name        : '',
        Description : '',
        Approval    : false,
        Default     : false
      },
      validationRules: {
        categoryForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Customer.CustomerGroupAddDialog.Field.Name.Error.Between')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 200) || this.$t('Customer.CustomerGroupAddDialog.Field.Description.Error.Between')
          ]
        }
      }
    }
  },
  computed: {
    sourceTypes () {
      let sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})
      let sourceTypesOther = this.appConfig?.SOURCE_TYPES?.OTHER || []

      sourceTypesDefault = sourceTypesDefault.filter(sourceType => sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })

      sourceTypesOther = sourceTypesOther.filter(sourceType => sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : o.title,
          Description: o.desc,
          Avatar     : {
            Img  : o.logo,
            Color: 'blue-grey'
          }
        }
      })

      return [...sourceTypesDefault, ...sourceTypesOther]
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editCategory: {
      get () {
        if (this.categoryToEdit) {
          this.newCategory = JSON.parse(JSON.stringify(this.categoryToEdit))
        } else {
          this.newCategory = JSON.parse(JSON.stringify(this.tmpCategory))
        }
        return this.newCategory
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
        })
      } else {
        if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
        this.$nextTick(() => {
          this.newCategory = null
          this.loading = false
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.CustomerGroup.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.CustomerGroup.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.categoryForm.validate()

      if (this.categoryFormValid) {
        this.saveCustomerGroup(this.editCategory)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    saveCustomerGroup (customerGroup) {
      this.loading = true
      window.callAS(window.SocketCommand.CustomerGroup.Save, { group: JSON.parse(JSON.stringify(customerGroup)) })
    },
    onSaveResult () {
      this.loading = false
      this.onCancelButtonClick()
    },
    onCancelButtonClick () {
      if (this.$refs.categoryForm) this.$refs.categoryForm.resetValidation()
      this.isVisible = false
      this.loading = false
      this.$nextTick(() => {
        this.newCategory = null
      })
    }
  }
}
</script>
