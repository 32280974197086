import PromiseQueue            from '@/lib/async/PromiseQueue'
import Watchable               from '@/mixins/watchable'
import CartData                from '@/mixins/pos/cartData'
import AddressCommon           from '@/mixins/maps/addressCommon'
import ReceiptTypeEnum         from '@/api/Enums/ReceiptTypeEnum'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import { emailIsGuest }        from '@/lib/helper/helper'

export default {
  data () {
    return {
      isCartInitialized: false,
      promiseQueue     : null,
      cdsChannel       : new BroadcastChannel('cds')
    }
  },

  computed: {},

  mixins: [Watchable, CartData, AddressCommon],

  watch: {},

  created () {
    this.promiseQueue = new PromiseQueue({
      concurrency: 1,
      cooldown   : 0
    })

    this.$bus.$on('cart:cart-update', this.cartUpdate)

    this.$bus.$on(window.SocketCommand.Cart.Init, this.onCartInit)
    this.$bus.$on(window.SocketCommand.Cart.Update, this.onCartUpdate)
    this.$bus.$on(window.SocketCommand.Cart.Destroy, this.onCartDestroy)

    this.$bus.$on('cart:cart-add-item', this.cartAddItem)
    this.$bus.$on('cart:cart-update-item', this.cartUpdateItem)
    this.$bus.$on(window.SocketCommand.Cart.Item.Add, this.onCartAddItem)
    this.$bus.$on(window.SocketCommand.Cart.Item.Remove, this.onCartRemoveItem)
    this.$bus.$on(window.SocketCommand.Cart.Item.Update, this.onCartUpdateItem)

    this.$bus.$on(window.SocketCommand.Cart.Coupon.Add, this.onCartAddCoupon)
    this.$bus.$on(window.SocketCommand.Cart.Coupon.Remove, this.onCartRemoveCoupon)

    this.$bus.$on(window.SocketCommand.Cart.Tip.Add, this.onCartAddTip)
    this.$bus.$on(window.SocketCommand.Cart.Tip.Remove, this.onCartRemoveTip)

    this.$bus.$on(window.SocketCommand.Cart.ExtraCharge.Add, this.onCartAddExtraCharge)
    this.$bus.$on(window.SocketCommand.Cart.ExtraCharge.Remove, this.onCartRemoveExtraCharge)

    this.$bus.$on(window.SocketCommand.Cart.Order.Complete, this.onCartOrderComplete)

    this.$bus.$on(window.SocketCommand.Table.Cart.Complete, this.onTableOrderComplete)

    this.$bus.$on('cds:send-message', this.cdsChannelMessageSend)

    this.cdsChannel.onmessage = this.cdsChannelMessageReceived

    this.cartInit()
  },

  beforeDestroy () {
    this.$bus.$off('cart:cart-update', this.cartUpdate)

    this.$bus.$off(window.SocketCommand.Cart.Init, this.onCartInit)
    this.$bus.$off(window.SocketCommand.Cart.Update, this.onCartUpdate)
    this.$bus.$off(window.SocketCommand.Cart.Destroy, this.onCartDestroy)

    this.$bus.$off('cart:cart-add-item', this.cartAddItem)
    this.$bus.$off('cart:cart-update-item', this.cartUpdateItem)
    this.$bus.$off(window.SocketCommand.Cart.Item.Add, this.onCartAddItem)
    this.$bus.$off(window.SocketCommand.Cart.Item.Remove, this.onCartRemoveItem)
    this.$bus.$off(window.SocketCommand.Cart.Item.Update, this.onCartUpdateItem)

    this.$bus.$off(window.SocketCommand.Cart.Coupon.Add, this.onCartAddCoupon)
    this.$bus.$off(window.SocketCommand.Cart.Coupon.Remove, this.onCartRemoveCoupon)

    this.$bus.$off(window.SocketCommand.Cart.Tip.Add, this.onCartAddTip)
    this.$bus.$off(window.SocketCommand.Cart.Tip.Remove, this.onCartRemoveTip)

    this.$bus.$off(window.SocketCommand.Cart.ExtraCharge.Add, this.onCartAddExtraCharge)
    this.$bus.$off(window.SocketCommand.Cart.ExtraCharge.Remove, this.onCartRemoveExtraCharge)

    this.$bus.$off(window.SocketCommand.Cart.Order.Complete, this.onCartOrderComplete)

    this.$bus.$off(window.SocketCommand.Table.Cart.Complete, this.onTableOrderComplete)

    this.$bus.$off('cds:send-message', this.cdsChannelMessageSend)

    this.clearData()
    this.promiseQueue = null

    this.cdsChannel.close()
  },

  methods: {
    cartInit () {
      if (this.$route.params.hasOwnProperty('callerID') && !isNaN(this.$route.params.callerID)) {
        this.posCart.SourceTypeId = this.appConfig.SOURCE_TYPES.DEFAULT.VOIP.source_type_id
        if (!this.$route.params.hasOwnProperty('customerId')) {
          this.showCustomerSearchDialog(this.$route.params.callerID)
        }
      }

      if (this.$route.params.hasOwnProperty('orderType') && this.$route.params.orderType) {
        this.posCart.OrderType = parseInt(this.$route.params.orderType || '1')
      }

      if (this.$route.params.hasOwnProperty('customerId') && this.$route.params.customerId) {
        this.posCart.CustomerId = this.$route.params.customerId
      }

      if (this.appConfig.LOCATION_DATA.hasOwnProperty('DefaultOrderTypePos') &&
        this.appConfig.LOCATION_DATA.DefaultOrderTypePos &&
        !this.$route.params.hasOwnProperty('isFromCopy')) {
        this.posCart.OrderType = this.appConfig.LOCATION_DATA.DefaultOrderTypePos
      }

      if (this.$route.params.hasOwnProperty('isFromCopy') && this.$route.params.hasOwnProperty('isDeleteAndCopyOrder')) {
        if (this.$route.params.isFromCopy === true && this.$route.params.isDeleteAndCopyOrder === false) {
          this.posCart.SourceTypeId = this.appConfig.SOURCE_TYPES.DEFAULT.POS.source_type_id
        }
      }

      if (this.orderId > 0) {
        this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Init, {
          CartId: this.orderId
        }))
      } else {
        const cartData = this.posCart.clone()
        this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Init, {
          Cart   : cartData,
          TableId: this.tableId || null,
          CartId : null
        }))
      }
    },
    onCartInit (data) {
      if (data && !data.hasOwnProperty('error')) {
        this.cartSetData(data)

        this.checkForCustomerAddress()

        this.$bus.$emit('cart:init', data)

        this.cdsChannelMessageSend({
          Action : 'cart:init',
          Payload: data
        })

        this.isCartInitialized = true
      } else {
        this.isOrderComplete = true
        this.$router.replace({ name: 'OrdersSaved' })
      }
    },

    async cartUpdate (event) {
      if (!this.isCartInitialized) return

      // eslint-disable-next-line no-console
      console.log('----- CART UPDATE: ', event)

      const cartData = this.posCart.clone()

      if (event === 'selectedAddress') await this.validateCustomerAddress()

      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Update, {
        Cart: {
          CustomerId                : cartData.CustomerId,
          SCustomer                 : cartData.SCustomer,
          AddressId                 : cartData.AddressId,
          SAddress                  : cartData.SAddress,
          Comment                   : cartData.Comment,
          SOrderType                : cartData.SOrderType,
          SourceTypeId              : cartData.SourceTypeId,
          OrderTypeTime             : cartData.OrderTypeTime,
          PaymentType               : cartData.PaymentType,
          RespectDeliveryAreaCharges: cartData.RespectDeliveryAreaCharges
        },
        CartId      : cartData.Id,
        Exit        : event === 'saveOrderEdit' ? 1 : 0,
        ExtraCharges: (event === 'selectedAddress' || event === 'orderType' || event === 'orderTypeTime' || event === 'removeDeliveryAreaCharges')
      }))
    },

    onCartUpdate (data) {
      this.cartSetData(data)
    },

    async validateCustomerAddress () {
      if (this.selectedAddress) {
        const result = await this.isPlaceInsideDeliveryAreas(this.addressToPlaceObject(this.selectedAddress))
        this.addressIsOutsideStoreLimits = !result
      }
      this.posCart.RespectDeliveryAreaCharges = true
    },

    cartDestroy () {
      if (!this.isCartInitialized) return
      this.promiseQueue.clear()
      window.callAS(window.SocketCommand.Cart.Destroy, { CartId: this.posCart.Id })
    },
    onCartDestroy (data) {
      this.cdsChannelMessageSend({
        Action : 'cart:destroy',
        Payload: null
      })

      if (data) {
        this.isOrderComplete = true
        if (this.routeNext) {
          this.routeNext()
        } else {
          if (this.tableId > 0) {
            this.$router.replace({ name: 'Tables' })
          } else {
            if (this.userIsWaiter) {
              if (this.posUserCan('Orders', 'ACCESS')) {
                this.$router.replace({ name: 'OrdersToday' })
              } else {
                this.$router.replace({ name: 'Tables' })
              }
            } else {
              this.$router.replace({ name: 'OrdersToday' })
            }
          }
        }
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Pos.Pos.Notification.Cancel'),
          timeout: 2000,
          type   : 'error',
          icon   : 'warning'
        })

        if (this.routeNext) this.routeNext(false)
      }
    },

    cartAddItem (menuItem) {
      if (!this.isCartInitialized) return
      const cartData = this.posCart.clone()
      const menuData = JSON.parse(JSON.stringify(menuItem))
      if (menuData?.Details?.OptionsCopy) delete menuData.Details.OptionsCopy

      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Item.Add, {
        CartId  : cartData.Id,
        MenuItem: {
          RowId       : menuData.Details.RowId,
          Custom      : menuData.Details.Custom,
          Id          : menuData.Details.Id,
          Comment     : menuData.Details.Comment,
          Options     : menuData.Details.Options,
          OptionValues: this.getMenuItemSelectedOptionValueIds(menuData),
          Quantity    : menuData.Quantity,
          UnitQuantity: menuData.UnitQuantity || 1,
          UnitId      : menuData.UnitId || 1,
          Price       : menuData.HasCustomPrice ? menuData.Price : null
        }
      }))
    },
    onCartAddItem (data) {
      this.cartSetData(data)
    },

    cartRemoveItem (menuItem) {
      if (!this.isCartInitialized || !menuItem?.RowId) return
      const cartData = this.posCart.clone()

      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Item.Remove, {
        CartId: cartData.Id,
        RowId : menuItem.RowId
      }))
    },
    onCartRemoveItem (data) {
      this.cartSetData(data)
    },

    cartUpdateItem (menuItem) {
      if (!this.isCartInitialized || !menuItem) return
      const cartData = this.posCart.clone()
      const menuData = JSON.parse(JSON.stringify(menuItem))
      if (menuData?.Details?.OptionsCopy) delete menuData.Details.OptionsCopy
      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Item.Update, {
        CartId  : cartData.Id,
        MenuItem: {
          RowId       : menuData.Details.RowId,
          Custom      : menuData.Details.Custom,
          Id          : menuData.Details.Id,
          Comment     : menuData.Details.Comment,
          Options     : menuData.Details.Options,
          OptionValues: this.getMenuItemSelectedOptionValueIds(menuData),
          Quantity    : menuData.Quantity,
          UnitQuantity: menuData.UnitQuantity || 1,
          UnitId      : menuData.UnitId || 1,
          Price       : menuData.HasCustomPrice ? menuData.Price : null
        }
      }))
    },
    onCartUpdateItem (data) {
      this.cartSetData(data)
      this.menuOptionsDialogVisible = false
    },

    cartAddCoupon (coupon) {
      if (!this.isCartInitialized || !coupon) return
      const cartData = this.posCart.clone()
      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Coupon.Add, {
        CartId: cartData.Id,
        Coupon: coupon
      }))
    },
    onCartAddCoupon (data, payload) {
      // console.log('>>>>>>> onCartAddCoupon', data, payload)
      const rowId = data?.Payload?.RowId || data?.Payload?.rowid || payload?.Coupon?.RowId || payload?.Coupon?.rowid
      const cartItem = this.getCartItemByRowId(rowId)
      if (cartItem) {
        this.$set(cartItem, 'Loading', false)
        this.$set(cartItem, 'LoadingCoupon', false)
      } else {
        this.$set(this, 'loadingAddCoupon', false)
      }

      this.cartSetData(data)
    },

    cartAddTip (tip) {
      if (!this.isCartInitialized || !tip) return
      const cartData = this.posCart.clone()
      this.loadingAddTip = true
      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Tip.Add, {
        CartId   : cartData.Id,
        TipAmount: tip
      }))
    },
    onCartAddTip (data) {
      this.cartSetData(data)
      this.selectTipDialogVisible = false
      this.loadingAddTip = false
    },

    cartAddExtraCharge (extraCharge) {
      if (!this.isCartInitialized || !extraCharge) return
      const cartData = this.posCart.clone()
      this.loadingAddExtraCharge = true
      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.ExtraCharge.Add, {
        CartId: cartData.Id,
        Charge: extraCharge
      }))
    },
    onCartAddExtraCharge (data) {
      this.cartSetData(data)
      this.selectExtraChargeDialogVisible = false
      this.loadingAddExtraCharge = false
    },

    removeDeliveryAreaCharges () {
      this.posCart.RespectDeliveryAreaCharges = false
      this.cartUpdate('removeDeliveryAreaCharges')
    },

    cartRemoveCoupon (coupon) {
      if (!this.isCartInitialized || !coupon) return
      const cartData = this.posCart.clone()
      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Coupon.Remove, {
        CartId: cartData.Id,
        Coupon: coupon
      }))
    },
    onCartRemoveCoupon (data, payload) {
      // console.log('>>>>>>> onCartRemoveCoupon', data, payload)
      const rowId = data?.Payload?.RowId || payload?.Coupon?.RowId
      const code = data?.Payload?.Code || payload?.Coupon?.Code
      const menuItem = this.getCartItemByRowId(rowId)
      const couponItem = this.getCartCouponByCode(code)

      if (menuItem) {
        this.$set(menuItem, 'Loading', false)
        this.$set(menuItem, 'LoadingCoupon', false)
      }

      if (couponItem) {
        this.$set(couponItem, 'Loading', false)
      }

      this.cartSetData(data)
    },

    cartRemoveExtraCharge (extraCharge) {
      if (!this.isCartInitialized || !extraCharge) return
      this.loadingRemoveExtraCharge = true
      const cartData = this.posCart.clone()
      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.ExtraCharge.Remove, {
        CartId: cartData.Id,
        Charge: extraCharge
      }))
    },
    onCartRemoveExtraCharge (data) {
      this.loadingRemoveExtraCharge = false
      this.cartSetData(data)
    },

    cartRemoveTip (tip) {
      if (!this.isCartInitialized || !tip) return
      this.loadingRemoveTip = true
      const cartData = this.posCart.clone()
      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Tip.Remove, {
        CartId   : cartData.Id,
        TipAmount: tip.Amount
      }))
    },
    onCartRemoveTip (data) {
      this.loadingRemoveTip = false
      this.cartSetData(data)
    },

    tableOrderComplete (data) {
      const cart = this.posCart.clone()
      const cartId = cart?.Id
      const tableId = cart?.TableShift?.TableId
      const autoPrint = data?.autoPrint ?? true
      if (!cart || !cartId || !tableId) return

      const requestData = {
        TableId  : tableId,
        CartId   : cart.Id,
        Cart     : cart,
        AutoPrint: autoPrint,
        Exit     : true
      }

      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Table.Cart.Complete, requestData))
    },
    onTableOrderComplete (data) {
      if (data.status === 'success') {
        this.isOrderComplete = true
        this.$router.replace({ name: 'Tables' })
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Pos.Pos.Notification.Complete'),
          timeout: 2000,
          type   : 'success',
          icon   : 'check_circle'
        })
      } else {
        this.completeOrderButtonEnabled = true
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    cartOrderComplete (data) {
      if (!this.isCartInitialized) return
      const cartData = this.posCart.clone()

      const autoPrint = data?.autoPrint ?? true
      const docType = data?.receiptType || ReceiptTypeEnum.RECEIPT
      const company = data?.receiptData || null
      const printNotify = data?.printNotify ?? null
      const emailNotify = data?.emailNotify ?? null
      const appSupportsReceipts = this.appHasReceiptsProvider && (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD || this.appReceiptsProviderType === ReceiptProviderTypeEnum.LOCAL)
      const customerEmail = emailNotify && this.posCart?.CustomerId && this.posCart?.SCustomer?.Email ? [this.posCart.SCustomer.Email] : []
      const dataEmails = data?.emailAddresses || []
      const emailAddresses = [...new Set([...customerEmail, ...dataEmails])].filter(email => !emailIsGuest(email))

      const receiptData = {
        DocType       : docType,
        Print         : printNotify,
        Email         : emailNotify,
        EmailAddresses: emailAddresses,
        Company       : company
      }

      const requestData = {
        CartId   : cartData.Id,
        AutoPrint: autoPrint,
        ...(appSupportsReceipts && receiptData)
      }

      this.promiseQueue.push(() => window.callAS(window.SocketCommand.Cart.Order.Complete, requestData))
    },
    onCartOrderComplete (data) {
      if (!data || data.hasOwnProperty('error')) {
        this.completeOrderButtonEnabled = true

        this.$bus.$emit('app-show-notification', {
          body   : data.message ? data.message : this.$t('Pos.Pos.Notification.Error'),
          timeout: 2000,
          type   : 'error',
          icon   : 'warning'
        })
        return
      }

      this.isOrderComplete = true
      this.$router.replace({ name: 'OrdersToday' })
      this.$bus.$emit('app-show-notification', {
        body   : this.$t('Pos.Pos.Notification.Complete'),
        timeout: 2000,
        type   : 'success',
        icon   : 'check_circle'
      })

      this.cdsChannelMessageSend({
        Action : 'cart:complete',
        Payload: null
      })
    },

    cartSetData (data) {
      if (!data || data.hasOwnProperty('error')) {
        this.$bus.$emit('app-show-notification', {
          body   : data.message ? data.message : this.$t('Pos.Pos.Notification.Error'),
          timeout: 2000,
          type   : 'error',
          icon   : 'warning'
        })

        if (data?.payload) {
          const cartItem = this.getCartItemByRowId(data?.payload?.RowId)
          if (cartItem) {
            this.$set(cartItem, 'Loading', false)
            this.$set(cartItem, 'LoadingRemove', false)
            this.$set(cartItem, 'LoadingCoupon', false)
          }
        }

        return
      }

      if (this.isTableOrder) {
        this.$bus.$emit('toolbar-update-title', `${ this.tableTitle } - ${ this.title }`)
      } else {
        this.$bus.$emit('toolbar-update-title', `${ this.title } #${ data.Id }`)
      }

      data = this.setLoaders(data)

      const menuItems = data?.Items || []
      const itemsByCategory = this.groupCartItemsByCategory(menuItems)
      const itemsByTableSeat = this.groupCartItemsByTableSeat(menuItems)
      itemsByTableSeat.forEach(tableSeat => {
        tableSeat.Items = this.groupCartItemsByCategory(tableSeat.Items)
      })
      data.ItemsFlat = menuItems
      data.ItemsByTableSeat = itemsByTableSeat
      data.Items = itemsByCategory

      this.removeWatchers()
      this.posCart.setData(data)
      this.addWatchers()

      this.cdsChannelMessageSend({
        Action : 'cart:data',
        Payload: this.posCart
      })
    },

    setLoaders (data) {
      const items = data?.Items || []
      items.forEach(item => {
        const cartItem = this.getCartItemByRowId(item.RowId)
        if (cartItem) {
          this.$set(item, 'Loading', cartItem?.Loading || false)
          this.$set(item, 'LoadingRemove', cartItem?.LoadingRemove || false)
          this.$set(item, 'LoadingCoupon', cartItem?.LoadingCoupon || false)
        }
      })

      const totals = data?.Totals || []
      totals.forEach(total => {
        const cartCoupon = this.getCartCouponByCode(total?.Code)
        if (cartCoupon) {
          this.$set(total, 'Loading', cartCoupon?.Loading || false)
        }

        const cartItem = this.getCartItemByRowId(cartCoupon?.RowId)
        if (cartItem) {
          this.$set(total, 'Loading', cartItem?.Loading || false)
          this.$set(total, 'LoadingCoupon', cartItem?.Loading || false)
        }
      })

      return data
    },

    getCartItemByRowId (rowId) {
      if (!rowId) return null
      const categories = this.posCart?.Items || []
      let retVal = null

      categories.forEach(category => {
        const menuItems = category?.Items || []
        menuItems.forEach(menuItem => {
          if (menuItem.RowId === rowId) retVal = menuItem
        })
      })

      return retVal
    },

    getCartCouponByCode (code) {
      if (!code) return null
      const totals = this.posCart?.Totals || []
      let retVal = null

      totals.forEach(total => {
        if (total?.Code === code) retVal = total
      })

      return retVal
    },

    getMenuItemSelectedOptionValueIds (menu) {
      const optionValuesIds = []

      if (!menu) return optionValuesIds

      const menuOptions = menu?.Details?.Options || []
      menuOptions.forEach(option => {
        const menuOptionValues = option?.OptionValues || []
        menuOptionValues.forEach(optionValue => {
          if (optionValue.Selected) optionValuesIds.push(parseInt(optionValue.Id))
        })
      })

      return optionValuesIds
    },

    checkForCustomerAddress () {
      if (this.posCart.Customer) {
        if (this.posCart.OrderType === 1) {
          if (this.posCart.Customer.Addresses.length <= 0) {
            this.$bus.$emit('show-customer-add-edit-address-dialog')
          } else if (this.posCart.Customer.Addresses.length === 1) {
            this.posCart.Address = this.posCart.Customer.Addresses[0]
          } else {
            if (this.posCart.Address) {
              this.posCart.Address = this.posCart.Customer.Addresses.find(addr => parseInt(addr.Id) === parseInt(this.posCart.Address.Id)) || null
              if (!this.posCart.Address) this.$bus.$emit('cart:show-customer-select-address-dialog')
            } else {
              this.$bus.$emit('cart:show-customer-select-address-dialog')
            }
          }
        } else {
          this.posCart.Address = null
        }
      }
    },

    clearData () {
      this.routeNext = null
      this.isOrderComplete = false
      this.isCartInitialized = false
      this.isFromCancelOrderButton = false

      this.removeWatchers()

      this.promiseQueue.clear()
      this.posCart.reset()
    },

    cdsChannelMessageSend ({
      Action,
      Payload
    }) {
      if (!this.cdsChannel || this.isTableOrder) return

      this.cdsChannel.postMessage({
        Action : Action,
        Payload: Payload
      })
    },

    cdsChannelMessageReceived (event) {
      const action = event?.data?.Action || null
      // const payload = event?.data?.Payload || null

      if (action === 'cart:get-data') {
        this.cdsChannelMessageSend({
          Action : 'cart:data',
          Payload: this.posCart
        })
      }
    },

    addWatchers () {
      this.watch('posCart.PaymentType', () => this.cartUpdate('orderPayment'))
      this.watch('posCart.SourceTypeId', () => this.cartUpdate('selectedOrderSourceType'))
      this.watch('posCart.CustomerId', () => {
        this.checkForCustomerAddress()
        this.cartUpdate('selectedCustomer')
      })
      this.watch('posCart.AddressId', () => this.cartUpdate('selectedAddress'))
      this.watch('posCart.OrderType', () => {
        this.checkForCustomerAddress()
        this.cartUpdate('orderTypeTime')
      })
    },
    removeWatchers () {
      this.unwatch('posCart.PaymentType')
      this.unwatch('posCart.SourceTypeId')
      this.unwatch('posCart.CustomerId')
      this.unwatch('posCart.AddressId')
      this.unwatch('posCart.OrderType')
    }
  }
}
