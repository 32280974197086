<template>
  <v-navigation-drawer
    v-model="navigationDrawerVisible"
    :width="$vuetify.breakpoint.xsOnly ? 295 : 400"
    dark
    style="overflow: hidden"
    temporary
    app
  >
    <v-card ref="card">
      <v-responsive
        class="white--text"
        height="95px"
        style="background-color: #E92731"
      >
        <v-container
          class="pa-4"
          fluid
        >
          <v-row>
            <v-col
              class="align-end flexbox"
              cols="12"
            >
              <v-avatar
                class="white mr-2"
                size="64px"
              >
                <svg-logo
                  class="float-right"
                  size="56px"
                />
              </v-avatar>
              <div
                class="d-inline-block pt-2"
                style="vertical-align: middle;"
              >
                <div class="text-h6">
                  GETFOOD Pro
                  <span class="text-caption">
                    {{ appVersion }}
                  </span>

                  <span
                    v-if="appVersionDesktop"
                    style="font-size: 10px;"
                  >
                    ({{ appVersionDesktop }})
                  </span>
                </div>

                <div
                  v-if="posUserHasComponentPermission('Online', 'ACCESS') || posUserHasComponentPermission('OnlineCatalog', 'ACCESS') || (appConfig && appConfig.LOCATION_DATA.CatalogOnly)"
                  class="text-caption pt-1"
                >
                  {{ (appConfig && appConfig.LOCATION_DATA.Name || '') | truncate($vuetify.breakpoint.xsOnly ? 23 : 40) }}
                  <v-btn
                    :href="appConfig && appConfig.LOCATION_DATA.SiteUrl"
                    class="pa-0 ma-0"
                    color="white"
                    text
                    icon
                    rel="noreferrer"
                    small
                    target="_blank"
                  >
                    <v-icon small>
                      open_in_new
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </v-card>

    <v-list
      dense
      style="overflow-y: auto; height: calc(100% - 96px);"
      two-line
      nav
    >
      <template v-for="(item, i) in items">
        <template v-if="item.heading && menuItemHasActiveChildren(item)">
          <v-divider
            :key="`divider-${i}`"
            class="mt-1"
            dark
          />

          <v-subheader
            :key="`subheader-${i}`"
            class="text-subtitle-2 mt-2 mb-1 pt-1 px-3 rounded grey darken-4"
          >
            {{ item.heading }}
          </v-subheader>

          <template v-if="item.children">
            <template v-for="(child, ii) in item.children">
              <v-divider
                v-if="child.divider"
                :key="`divider-${i}-${ii}`"
                class="mt-1"
                dark
              />

              <v-list-group
                v-else-if="child.children && menuItemHasActiveChildren(child)"
                :key="`list-group-${i}-${ii}`"
                v-model="child.model"
                :append-icon="child['control-icon-alt']"
                class="mt-1 listNoDivider"
                color="white"
                no-action
              >
                <template #activator>
                  <v-list-item-action v-if="child.icon">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-html="child.title" />
                    <v-list-item-subtitle v-html="child.subTitle" />
                  </v-list-item-content>
                </template>

                <template v-for="(child2, iii) in child.children">
                  <template v-if="child2.link">
                    <v-list-item
                      :key="`list-group-item-${i}-${ii}-${iii}`"
                      :href="child2.link.href"
                      :target="child2.link.target"
                      class="pb-1"
                    >
                      <v-list-item-action v-if="child2.icon">
                        <v-icon>{{ child2.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-text="child2.title" />
                        <v-list-item-subtitle v-html="child2.subTitle" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-else>
                    <v-list-item
                      v-if="posUserHasRoutePermission(!child2.items ? child2.route : '')"
                      :key="`list-group-item-${i}-${ii}-${iii}`"
                      :exact="!child2.items ? child2.route.exact: false"
                      :to="!child2.items ? child2.route : ''"
                      class="pb-1"
                    >
                      <v-list-item-action v-if="child2.icon">
                        <v-icon>{{ child2.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-text="child2.title" />
                        <v-list-item-subtitle v-html="child2.subTitle" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </template>
              </v-list-group>

              <template v-else-if="menuItemHasActiveChildren(child)">
                <template v-if="child.link">
                  <v-list-item
                    :key="`list-group-item-${i}-${ii}`"
                    :href="child.link.href"
                    :target="child.link.target"
                    class="mt-1"
                  >
                    <v-list-item-action v-if="child.icon">
                      <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="child.title" />
                      <v-list-item-subtitle v-html="child.subTitle" />
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <template v-else>
                  <v-list-item
                    v-if="posUserHasRoutePermission(!child.items ? child.route : '')"
                    :key="`list-group-item-${i}-${ii}`"
                    :exact="!child.items ? child.route.exact: false"
                    :to="!child.items ? child.route : ''"
                    class="mt-1"
                  >
                    <v-list-item-action v-if="child.icon">
                      <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="child.title" />
                      <v-list-item-subtitle v-html="child.subTitle" />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </template>
          </template>
        </template>

        <v-divider
          v-else-if="item.divider"
          :key="`divider-${i}`"
          class="mt-1"
          dark
        />

        <v-list-group
          v-else-if="!item.heading && item.children && menuItemHasActiveChildren(item)"
          :key="`list-group-${i}`"
          v-model="item.model"
          :append-icon="item['control-icon-alt']"
          class="mt-1 listNoDivider"
          color="white"
          no-action
        >
          <template #activator>
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="item.title" />
              <v-list-item-subtitle v-html="item.subTitle" />
            </v-list-item-content>
          </template>

          <template v-for="(child, ii) in item.children">
            <template v-if="child.link">
              <v-list-item
                :key="`list-group-item-${i}-${ii}`"
                :href="child.link.href"
                :target="child.link.target"
                class="pb-1"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title" />
                  <v-list-item-subtitle v-html="child.subTitle" />
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-else>
              <v-list-item
                v-if="posUserHasRoutePermission(!child.items ? child.route : '')"
                :key="`list-group-item-${i}-${ii}`"
                :exact="!child.items ? child.route.exact: false"
                :to="!child.items ? child.route : ''"
                class="pb-1"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title" />
                  <v-list-item-subtitle v-html="child.subTitle" />
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list-group>

        <template v-else-if="menuItemHasActiveChildren(item)">
          <template v-if="item.link">
            <v-list-item
              :key="`list-group-item-${i}`"
              :href="item.link.href"
              :target="item.link.target"
              class="mt-1"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
                <v-list-item-subtitle v-html="item.subTitle" />
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else>
            <v-list-item
              v-if="posUserHasRoutePermission(!item.items ? item.route : '')"
              :key="`list-group-item-${i}`"
              :exact="!item.items && item.route ? item.route.exact : false"
              :to="!item.items ? item.route : ''"
              class="mt-1"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
                <v-list-item-subtitle v-html="item.subTitle" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </template>

      <v-divider
        class="mt-1"
        dark
      />

      <v-list-item
        class="mt-1"
        @click="onAppExit"
      >
        <v-list-item-action>
          <v-icon>exit_to_app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Common.Misc.Exit').toLocaleUpperCase($i18n.locale) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('Common.Misc.ExitApp').toLocaleUpperCase($i18n.locale) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'
import SvgLogo from '@/components/common/SvgLogo.vue'

export default {
  name      : 'AppNavigationDrawer',
  components: { 'svg-logo': SvgLogo },
  directives: {},
  mixins    : [AppData, Auth],
  props     : {
    navigationDrawer: {
      type    : Boolean,
      required: true
    },
    items: {
      type    : [Array, Object],
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onAppExit () {
      this.$emit('app:exit')
    },

    menuItemHasActiveChildren (item) {
      let count = 1
      if (item.children) {
        count = 0

        item.children.forEach((child) => {
          if (this.posUserHasRoutePermission()) count++
        })
      }
      return count > 0
    }
  }
}
</script>

<style scoped>

</style>
