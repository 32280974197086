export default {
  data () {
    return {}
  },
  mixins : [],
  methods: {
    removeCustomer () {
      this.selectedCustomer = null
      this.selectedAddress = null
    }
  },
  computed: {
    selectedCustomer: {
      get () {
        return this.posCart.Customer
      },
      set (val) {
        this.posCart.Customer = val
      }
    },

    selectedAddress: {
      get () {
        return this.posCart.Address
      },
      set (val) {
        this.posCart.Address = val
      }
    }
  }
}
