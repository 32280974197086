<template>
  <!--TODO: Remove v-if and "posUser.billing" check on next version-->
  <v-tabs
    v-if="posUser.billing || posUser.crm"
    background-color="secondary"
    slider-color="light-green"
    dark
    grow
  >
    <v-tab
      v-if="posUserHasComponentPermission('AccountOverview', 'ACCESS') && posUser.billing"
      :to="{name: 'AccountOverview'}"
      active-class="secondary darken-1 v-tabs__item--active"
      ripple
    >
      {{ $t('Account.Toolbar.Tab.Overview') }}
    </v-tab>

    <v-tab
      v-if="posUserHasComponentPermission('AccountBilling', 'ACCESS') && posUser.crm"
      :to="{name: 'AccountBilling'}"
      active-class="secondary darken-1 v-tabs__item--active"
      ripple
    >
      {{ $t('Account.Toolbar.Tab.Billing') }}
    </v-tab>

    <v-tab
      v-if="posUserHasComponentPermission('AccountPlans', 'ACCESS') && posUser.billing"
      :to="{name: 'AccountPlans'}"
      active-class="secondary darken-1 v-tabs__item--active"
      ripple
    >
      {{ $t('Account.Toolbar.Tab.Plan') }}
    </v-tab>

    <v-tab
      v-if="posUserHasComponentPermission('AccountInvoices', 'ACCESS') && posUser.crm && !domainReseller"
      :to="{name: 'AccountInvoices'}"
      active-class="secondary darken-1 v-tabs__item--active"
      ripple
    >
      {{ $t('Account.Toolbar.Tab.Invoice') }}
    </v-tab>
  </v-tabs>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'AccountToolbar',
  components: {},
  directives: {},
  mixins    : [AppData, Auth],
  props     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
