<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="red"
          height="80"
          max-height="80"
        >
          <v-avatar
            color="red darken-3"
          >
            <v-icon
              color="white"
              size="28"
            >
              shopping_cart
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3 white--text">
            <div class="body-3">
              {{ $t('Order.OrderDeleteDialog.DeleteAllOrders.Title') }}
            </div>

            <div class="text-caption red--text text--lighten-4" />
          </v-toolbar-title>

          <v-spacer />
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-container
            class="pa-1"
            fluid
          >
            <v-row dense>
              <v-col
                class="mb-1 text-subtitle-1"
                cols="12"
                v-html="$t('Order.OrderDeleteDialog.DeleteAllOrders.Body')"
              />
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <v-col
                :class="isDarkMode ? 'red lighten-2' : 'red lighten-4'"
                cols="12"
              >
                <div
                  class="py-2 px-4 text-caption"
                  style="display: table;"
                >
                  <div style="display: table-row">
                    <div
                      class="font-weight-bold pr-2"
                      style="display: table-cell; vertical-align: middle;"
                    >
                      {{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:
                    </div>
                    <div style="display: table-cell; vertical-align: middle;">
                      {{ $t('Order.OrderDeleteDialog.DeleteAllOrders.Notification.Title') }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="green--text text--darken-1"
            text
            outlined
            @click.native="isVisible = false"
          >
            {{ $t('Common.Button.No').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            class="red darken-1 white--text"
            text
            @click="deleteOrders('all')"
          >
            {{ $t('Order.OrderDeleteDialog.DeleteAllOrders.Button.YesAll').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            class="amber darken-2 white--text"
            text
            @click="deleteOrders('mine')"
          >
            {{ $t('Order.OrderDeleteDialog.DeleteAllOrders.Button.YesMine').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'

export default {
  name      : 'OrderDeleteAllDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    deleteOrders (type = 'mine') {
      if (this.$route.name === 'OrdersSaved') {
        window.callAS(window.SocketCommand.Cart.Destroy, { Type: type || 'mine' })
      }

      this.$bus.$emit('order-deleted', null)
      this.$emit('order-deleted', null)
      this.isVisible = false
    }
  }
}
</script>
