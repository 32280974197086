/**
 * A collection of utilities for the Greek language such as replacement of accented and other diacritics characters,
 * conversion from Greek to phonetic, transliterated or greeklish Latin and more.
 *
 */
const greekUtils = {
  /**
   * Convert a Latin/greeklish characters text to its modern Greek equivalent.
   *
   * @method toGreek
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toGreek: function (text, ignore) {
    return replaceText(text, greeklishToGreekMap, true, ignore)
  },

  /**
   * Convert a modern Greek characters text to its greeklish equivalent.
   *
   * @method toGreeklish
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toGreeklish: function (text, ignore = '') {
    return replaceText(text, greekToGreeklishMap, true, ignore)
  },

  /**
   * Convert a modern Greek characters text to its phonetically equivalent Latin (sound mapping).
   *
   * @method toPhoneticLatin
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toPhoneticLatin: function (text, ignore) {
    return replaceText(text, greekToPhoneticLatinMap, true, ignore)
  },

  /**
   * Convert a modern Greek characters text to its transliterated equivalent Latin (letter mapping).
   *
   * @method toTransliteratedLatin
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toTransliteratedLatin: function (text, ignore) {
    return replaceText(text, greekToTransliteratedLatinMap, true, ignore)
  },

  /**
   * Convert a modern/ancient Greek characters text containing diacritics to its simple equivalent without diacritics.
   *
   * @method sanitizeDiacritics
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  sanitizeDiacritics: function (text, ignore) {
    return replaceText(text, diacriticsMap, false, ignore)
  }
}

// Private Functions
/**
 *
 * @param {String} text
 * @param {Array} characterMap
 * @param {Boolean} exactMatch
 * @param {String} ignore
 * @returns {String}
 */
function replaceText (text, characterMap, exactMatch, ignore) {
  let regexString,
    regex

  exactMatch = exactMatch ?? false

  if (typeof text === 'string' && text.length > 0) {
    characterMap.forEach(function (characters) {
      regexString = exactMatch ? characters.find : '[' + characters.find + ']'
      if (ignore) {
        regexString = '(?![' + ignore + '])' + regexString
      }
      regex = new RegExp(regexString, 'g')
      text = text.replace(regex, characters.replace)
    })
  }

  return text
}

export default greekUtils

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(greekUtils)
}

const diacriticsMap = [
  { find: 'άἀἁἂἃἄἅἆἇὰάᾀᾁᾂᾃᾄᾅᾆᾇᾰᾱᾲᾳᾴᾶᾷ', replace: 'α' },
  { find: 'ΆἈἉἊἋἌἍἎἏᾈᾉᾊᾋᾌᾍᾎᾏᾸᾹᾺΆᾼ', replace: 'Α' },
  { find: 'έἐἑἒἓἔἕὲέ', replace: 'ε' },
  { find: 'ΈἘἙἚἛἜἝ', replace: 'Ε' },
  { find: 'ήἠἡἢἣἤἥἦἧῆὴῇ', replace: 'η' },
  { find: 'ΉἨἩἪἫἬἭἮἯ', replace: 'Η' },
  { find: 'ίἰἱἲἳἴἵὶῖ', replace: 'ι' },
  { find: 'ΊἶἷἸἹἺἻἼἽἾἿ', replace: 'Ι' },
  { find: 'όὀὁὂὃὄὅὸ', replace: 'ο' },
  { find: 'ΌὈὉὊὋὌὍ', replace: 'Ο' },
  { find: 'ύὐὑὒὓὔὕὖὗ', replace: 'υ' },
  { find: 'ΎὙὛὝὟ', replace: 'Υ' },
  { find: 'ώὠὡὢὣὤὥὦὧῶ', replace: 'ω' },
  { find: 'ΏὨὩὪὫὬὭὮὯ', replace: 'Ω' }
]

const greekToGreeklishMap = [
  { find: 'ΓΧ', replace: 'GX' },
  { find: 'γχ', replace: 'gx' },
  { find: 'ΤΘ', replace: 'T8' },
  { find: 'τθ', replace: 't8' },
  { find: '(θη|Θη)', replace: '8h' },
  { find: 'ΘΗ', replace: '8H' },
  { find: 'αυ', replace: 'au' },
  { find: 'Αυ', replace: 'Au' },
  { find: 'ΑΥ', replace: 'AY' },
  { find: 'ευ', replace: 'eu' },
  { find: 'εύ', replace: 'eu' },
  { find: 'εϋ', replace: 'ey' },
  { find: 'εΰ', replace: 'ey' },
  { find: 'Ευ', replace: 'Eu' },
  { find: 'Εύ', replace: 'Eu' },
  { find: 'Εϋ', replace: 'Ey' },
  { find: 'Εΰ', replace: 'Ey' },
  { find: 'ΕΥ', replace: 'EY' },
  { find: 'ου', replace: 'ou' },
  { find: 'ού', replace: 'ou' },
  { find: 'οϋ', replace: 'oy' },
  { find: 'οΰ', replace: 'oy' },
  { find: 'Ου', replace: 'Ou' },
  { find: 'Ού', replace: 'Ou' },
  { find: 'Οϋ', replace: 'Oy' },
  { find: 'Οΰ', replace: 'Oy' },
  { find: 'ΟΥ', replace: 'OY' },
  { find: 'Α', replace: 'A' },
  { find: 'α', replace: 'a' },
  { find: 'ά', replace: 'a' },
  { find: 'Ά', replace: 'A' },
  { find: 'Β', replace: 'B' },
  { find: 'β', replace: 'b' },
  { find: 'Γ', replace: 'G' },
  { find: 'γ', replace: 'g' },
  { find: 'Δ', replace: 'D' },
  { find: 'δ', replace: 'd' },
  { find: 'Ε', replace: 'E' },
  { find: 'ε', replace: 'e' },
  { find: 'έ', replace: 'e' },
  { find: 'Έ', replace: 'E' },
  { find: 'Ζ', replace: 'Z' },
  { find: 'ζ', replace: 'z' },
  { find: 'Η', replace: 'H' },
  { find: 'η', replace: 'h' },
  { find: 'ή', replace: 'h' },
  { find: 'Ή', replace: 'H' },
  { find: 'Θ', replace: 'TH' },
  { find: 'θ', replace: 'th' },
  { find: 'Ι', replace: 'I' },
  { find: 'Ϊ', replace: 'I' },
  { find: 'ι', replace: 'i' },
  { find: 'ί', replace: 'i' },
  { find: 'ΐ', replace: 'i' },
  { find: 'ϊ', replace: 'i' },
  { find: 'Ί', replace: 'I' },
  { find: 'Κ', replace: 'K' },
  { find: 'κ', replace: 'k' },
  { find: 'Λ', replace: 'L' },
  { find: 'λ', replace: 'l' },
  { find: 'Μ', replace: 'M' },
  { find: 'μ', replace: 'm' },
  { find: 'Ν', replace: 'N' },
  { find: 'ν', replace: 'n' },
  { find: 'Ξ', replace: 'KS' },
  { find: 'ξ', replace: 'ks' },
  { find: 'Ο', replace: 'O' },
  { find: 'ο', replace: 'o' },
  { find: 'Ό', replace: 'O' },
  { find: 'ό', replace: 'o' },
  { find: 'Π', replace: 'p' },
  { find: 'π', replace: 'p' },
  { find: 'Ρ', replace: 'R' },
  { find: 'ρ', replace: 'r' },
  { find: 'Σ', replace: 'S' },
  { find: 'σ', replace: 's' },
  { find: 'Τ', replace: 'T' },
  { find: 'τ', replace: 't' },
  { find: 'Υ', replace: 'Y' },
  { find: 'Ύ', replace: 'Y' },
  { find: 'Ϋ', replace: 'Y' },
  { find: 'ΰ', replace: 'y' },
  { find: 'ύ', replace: 'y' },
  { find: 'ϋ', replace: 'y' },
  { find: 'υ', replace: 'y' },
  { find: 'Φ', replace: 'F' },
  { find: 'φ', replace: 'f' },
  { find: 'Χ', replace: 'X' },
  { find: 'χ', replace: 'x' },
  { find: 'Ψ', replace: 'Ps' },
  { find: 'ψ', replace: 'ps' },
  { find: 'Ω', replace: 'w' },
  { find: 'ω', replace: 'w' },
  { find: 'Ώ', replace: 'w' },
  { find: 'ώ', replace: 'w' },
  { find: 'ς', replace: 's' },
  { find: ';', replace: '?' }
]

const greeklishToGreekMap = [
  { find: 'tha', replace: 'θα' },
  { find: 'the', replace: 'θε' },
  { find: 'thi', replace: 'θι' },
  { find: 'thh', replace: 'θη' },
  { find: 'tho', replace: 'θο' },
  { find: '(thy|thu)', replace: 'θυ' },
  { find: '(thw|thv)', replace: 'θω' },
  { find: 'tH', replace: 'τΗ' },
  { find: 'TH', replace: 'ΤΗ' },
  { find: 'Th', replace: 'Τη' },
  { find: 'th', replace: 'τη' },
  { find: '(cH|ch)', replace: 'χ' },
  { find: '(CH|Ch)', replace: 'Χ' },
  { find: '(PS|Ps)', replace: 'Ψ' },
  { find: '(ps|pS)', replace: 'ψ' },
  { find: '(Ks|KS)', replace: 'Ξ' },
  { find: '(ks|kS)', replace: 'ξ' },
  { find: '(VR)', replace: 'ΒΡ' },
  { find: '(vr|vR)', replace: 'βρ' },
  { find: '(Vr)', replace: 'Βρ' },
  { find: '8a', replace: 'θα' },
  { find: '8e', replace: 'θε' },
  { find: '8h', replace: 'θη' },
  { find: '8i', replace: 'θι' },
  { find: '8o', replace: 'θο' },
  { find: '8y', replace: 'θυ' },
  { find: '8u', replace: 'θυ' },
  { find: '(8v|8w)', replace: 'θω' },
  { find: '8A', replace: 'ΘΑ' },
  { find: '8E', replace: 'ΘΕ' },
  { find: '8H', replace: 'ΘΗ' },
  { find: '8I', replace: 'ΘΙ' },
  { find: '8O', replace: 'ΘΟ' },
  { find: '(8Y|8U)', replace: 'ΘΥ' },
  { find: '8V', replace: 'ΘΩ' },
  { find: '8W', replace: 'ΘΩ' },
  { find: '9a', replace: 'θα' },
  { find: '9e', replace: 'θε' },
  { find: '9h', replace: 'θη' },
  { find: '9i', replace: 'θι' },
  { find: '9o', replace: 'θο' },
  { find: '9y', replace: 'θυ' },
  { find: '9u', replace: 'θυ' },
  { find: '(9v|9w)', replace: 'θω' },
  { find: '9A', replace: 'ΘΑ' },
  { find: '9E', replace: 'ΘΕ' },
  { find: '9H', replace: 'ΘΗ' },
  { find: '9I', replace: 'ΘΙ' },
  { find: '9O', replace: 'ΘΟ' },
  { find: '(9Y|9U)', replace: 'ΘΥ' },
  { find: '9V', replace: 'ΘΩ' },
  { find: '9W', replace: 'ΘΩ' },
  { find: 's[\\n]', replace: 'ς\n' },
  { find: 's[\\!]', replace: 'ς!' },
  { find: 's[\\.]', replace: 'ς.' },
  { find: 's[\\ ]', replace: 'ς ' },
  { find: 's[\\,]', replace: 'ς,' },
  { find: 's[\\+]', replace: 'ς+' },
  { find: 's[\\-]', replace: 'ς-' },
  { find: 's[\\(]', replace: 'ς(' },
  { find: 's[\\)]', replace: 'ς)' },
  { find: 's[\\[]', replace: 'ς[' },
  { find: 's[\\]]', replace: 'ς]' },
  { find: 's[\\{]', replace: 'ς{' },
  { find: 's[\\}]', replace: 'ς}' },
  { find: 's[\\<]', replace: 'ς<' },
  { find: 's[\\>]', replace: 'ς>' },
  { find: 's[\\?]', replace: 'ς;' },
  { find: 's[\\/]', replace: 'ς/' },
  { find: 's[\\:]', replace: 'ς:' },
  { find: 's[\\;]', replace: 'ς;' },
  { find: 's[\\"]', replace: 'ς"' },
  { find: 's[\\\']', replace: 'ς\'' },
  { find: 's[\\f]', replace: 'ς\f' },
  { find: 's[\\r]', replace: 'ς\r' },
  { find: 's[\\t]', replace: 'ς\t' },
  { find: 's[\\v]', replace: 'ς\v' },
  { find: 'rx', replace: 'ρχ' },
  { find: 'sx', replace: 'σχ' },
  { find: 'Sx', replace: 'Σχ' },
  { find: 'SX', replace: 'ΣΧ' },
  { find: 'ux', replace: 'υχ' },
  { find: 'Ux', replace: 'Υχ' },
  { find: 'UX', replace: 'ΥΧ' },
  { find: 'yx', replace: 'υχ' },
  { find: 'Yx', replace: 'Υχ' },
  { find: 'YX', replace: 'ΥΧ' },
  { find: '3a', replace: 'ξα' },
  { find: '3e', replace: 'ξε' },
  { find: '3h', replace: 'ξη' },
  { find: '3i', replace: 'ξι' },
  { find: '3ο', replace: 'ξο' },
  { find: '3u', replace: 'ξυ' },
  { find: '3y', replace: 'ξυ' },
  { find: '3v', replace: 'ξω' },
  { find: '3w', replace: 'ξω' },
  { find: 'a3', replace: 'αξ' },
  { find: 'e3', replace: 'εξ' },
  { find: 'h3', replace: 'ηξ' },
  { find: 'i3', replace: 'ιξ' },
  { find: 'ο3', replace: 'οξ' },
  { find: 'u3', replace: 'υξ' },
  { find: 'y3', replace: 'υξ' },
  { find: 'v3', replace: 'ωξ' },
  { find: 'w3', replace: 'ωξ' },
  { find: '3A', replace: 'ξΑ' },
  { find: '3E', replace: 'ξΕ' },
  { find: '3H', replace: 'ξΗ' },
  { find: '3I', replace: 'ξΙ' },
  { find: '3O', replace: 'ξΟ' },
  { find: '3U', replace: 'ξΥ' },
  { find: '3Y', replace: 'ξΥ' },
  { find: '3V', replace: 'ξΩ' },
  { find: '3W', replace: 'ξΩ' },
  { find: 'A3', replace: 'Αξ' },
  { find: 'E3', replace: 'Εξ' },
  { find: 'H3', replace: 'Ηξ' },
  { find: 'I3', replace: 'Ιξ' },
  { find: 'O3', replace: 'Οξ' },
  { find: 'U3', replace: 'Υξ' },
  { find: 'Y3', replace: 'Υξ' },
  { find: 'V3', replace: 'Ωξ' },
  { find: 'W3', replace: 'Ωξ' },
  { find: 'A', replace: 'Α' },
  { find: 'a', replace: 'α' },
  { find: 'B', replace: 'Β' },
  { find: 'b', replace: 'β' },
  { find: 'V', replace: 'Β' },
  { find: 'v', replace: 'β' },
  { find: 'c', replace: 'ψ' },
  { find: 'C', replace: 'Ψ' },
  { find: 'G', replace: 'Γ' },
  { find: 'g', replace: 'γ' },
  { find: 'D', replace: 'Δ' },
  { find: 'd', replace: 'δ' },
  { find: 'E', replace: 'Ε' },
  { find: 'e', replace: 'ε' },
  { find: 'Z', replace: 'Ζ' },
  { find: 'z', replace: 'ζ' },
  { find: 'H', replace: 'Η' },
  { find: 'h', replace: 'η' },
  { find: 'U', replace: 'Θ' },
  { find: 'u', replace: 'υ' },
  { find: 'I', replace: 'Ι' },
  { find: 'i', replace: 'ι' },
  { find: 'j', replace: 'ξ' },
  { find: 'J', replace: 'Ξ' },
  { find: 'K', replace: 'Κ' },
  { find: 'k', replace: 'κ' },
  { find: 'L', replace: 'Λ' },
  { find: 'l', replace: 'λ' },
  { find: 'M', replace: 'Μ' },
  { find: 'm', replace: 'μ' },
  { find: 'N', replace: 'Ν' },
  { find: 'n', replace: 'ν' },
  { find: 'X', replace: 'Χ' },
  { find: 'x', replace: 'χ' },
  { find: 'O', replace: 'Ο' },
  { find: 'o', replace: 'ο' },
  { find: 'P', replace: 'Π' },
  { find: 'p', replace: 'π' },
  { find: 'R', replace: 'Ρ' },
  { find: 'r', replace: 'ρ' },
  { find: 'S', replace: 'Σ' },
  { find: 's', replace: 'σ' },
  { find: 'T', replace: 'Τ' },
  { find: 't', replace: 'τ' },
  { find: 'Y', replace: 'Υ' },
  { find: 'y', replace: 'υ' },
  { find: 'F', replace: 'Φ' },
  { find: 'f', replace: 'φ' },
  { find: 'W', replace: 'Ω' },
  { find: 'w', replace: 'ω' },
  { find: '\\?', replace: ';' }
]

const greekVowels = 'αεηιουω'
const greekConsonants = 'βγδζθκλμνξπρςστφχψ'
const greekToPhoneticLatinMap = [
  { find: 'ηυ', replace: 'if' },
  { find: '(αυ)(?=[' + greekConsonants + '])', replace: 'af' },
  { find: '(αυ)(?=[' + greekVowels + '])', replace: 'av' },
  { find: '(ευ)(?=[' + greekConsonants + '])', replace: 'ef' },
  { find: '(ευ)(?=[' + greekVowels + '])', replace: 'ev' },
  { find: 'ου', replace: 'ou' },

  { find: '(Αυ)(?=[' + greekConsonants + '])', replace: 'Af' },
  { find: '(Αυ)(?=[' + greekVowels + '])', replace: 'Av' },
  { find: '(Ευ)(?=[' + greekConsonants + '])', replace: 'Ef' },
  { find: '(Ευ)(?=[' + greekVowels + '])', replace: 'Ev' },
  { find: 'Ηυ', replace: 'If' },
  { find: 'Ου', replace: 'Ou' },

  { find: 'ηύ', replace: 'íf' },
  { find: '(αύ)(?=[' + greekConsonants + '])', replace: 'áf' },
  { find: '(αύ)(?=[' + greekVowels + '])', replace: 'áv' },
  { find: '(εύ)(?=[' + greekConsonants + '])', replace: 'éf' },
  { find: '(εύ)(?=[' + greekVowels + '])', replace: 'éf' },
  { find: 'ού', replace: 'oú' },

  { find: '(Αύ)(?=[' + greekConsonants + '])', replace: 'Áf' },
  { find: '(Αύ)(?=[' + greekVowels + '])', replace: 'Áv' },
  { find: '(Εύ)(?=[' + greekConsonants + '])', replace: 'Éf' },
  { find: '(Εύ)(?=[' + greekVowels + '])', replace: 'Év' },
  { find: 'Ηύ', replace: 'Íf' },
  { find: 'Ού', replace: 'Oú' },

  { find: 'αι', replace: 'e' },
  { find: 'ει', replace: 'i' },
  { find: 'οι', replace: 'i' },

  { find: 'αί', replace: 'é' },
  { find: 'εί', replace: 'í' },
  { find: 'οί', replace: 'í' },

  { find: 'Αι|ΑΙ', replace: 'E' },
  { find: 'Ει|ΕΙ', replace: 'I' },
  { find: 'Οι|ΟΙ', replace: 'I' },

  { find: 'Αί', replace: 'É' },
  { find: 'Εί', replace: 'Í' },
  { find: 'Οί', replace: 'Í' },

  { find: 'γε', replace: 'ye' },
  { find: 'γι', replace: 'yi' },
  { find: 'γυ', replace: 'yi' },

  { find: 'Γε', replace: 'Ye' },
  { find: 'Γι', replace: 'Yi' },
  { find: 'Γυ', replace: 'Yi' },

  { find: 'μπ', replace: 'b' },
  { find: 'ντ', replace: 'd' },
  { find: 'γκ', replace: 'g' },

  { find: 'Μπ|ΜΠ', replace: 'B' },
  { find: 'Ντ|ΝΤ', replace: 'D' },
  { find: 'Γκ|ΓΚ', replace: 'G' },

  { find: 'α', replace: 'a' },
  { find: 'β', replace: 'v' },
  { find: 'γ', replace: 'g' },
  { find: 'δ', replace: 'd' },
  { find: 'ε', replace: 'e' },
  { find: 'ζ', replace: 'z' },
  { find: 'η', replace: 'i' },
  { find: 'θ', replace: 'th' },
  { find: 'ι', replace: 'i' },
  { find: 'κ', replace: 'k' },
  { find: 'λ', replace: 'l' },
  { find: 'μ', replace: 'm' },
  { find: 'ν', replace: 'n' },
  { find: 'ξ', replace: 'x' },
  { find: 'ο', replace: 'o' },
  { find: 'π', replace: 'p' },
  { find: 'ρ', replace: 'r' },
  { find: 'ς', replace: 's' },
  { find: 'σ', replace: 's' },
  { find: 'τ', replace: 't' },
  { find: 'υ', replace: 'i' },
  { find: 'φ', replace: 'ph' },
  { find: 'χ', replace: 'kh' },
  { find: 'ψ', replace: 'ps' },
  { find: 'ω', replace: 'o' },

  { find: 'ά', replace: 'á' },
  { find: 'έ', replace: 'é' },
  { find: 'ή', replace: 'í' },
  { find: 'ί', replace: 'í' },
  { find: 'ό', replace: 'ó' },
  { find: 'ύ', replace: 'í' },
  { find: 'ώ', replace: 'ó' },
  { find: 'ΰ', replace: 'ï' },
  { find: 'ΐ', replace: 'ï' },
  { find: 'ϊ', replace: 'ï' },
  { find: 'ϋ', replace: 'ï' },

  { find: 'Α', replace: 'A' },
  { find: 'Β', replace: 'V' },
  { find: 'Γ', replace: 'G' },
  { find: 'Δ', replace: 'D' },
  { find: 'Ε', replace: 'E' },
  { find: 'Ζ', replace: 'Z' },
  { find: 'Η', replace: 'I' },
  { find: 'Θ', replace: 'Th' },
  { find: 'Ι', replace: 'I' },
  { find: 'Κ', replace: 'K' },
  { find: 'Λ', replace: 'L' },
  { find: 'Μ', replace: 'M' },
  { find: 'Ν', replace: 'N' },
  { find: 'Ξ', replace: 'X' },
  { find: 'Ο', replace: 'O' },
  { find: 'Π', replace: 'P' },
  { find: 'Ρ', replace: 'R' },
  { find: 'Σ', replace: 'S' },
  { find: 'Τ', replace: 'T' },
  { find: 'Υ', replace: 'I' },
  { find: 'Φ', replace: 'Ph' },
  { find: 'Χ', replace: 'Kh' },
  { find: 'Ψ', replace: 'Ps' },
  { find: 'Ω', replace: 'O' },

  { find: 'Ά', replace: 'Á' },
  { find: 'Έ', replace: 'É' },
  { find: 'Ή', replace: 'Í' },
  { find: 'Ί', replace: 'Í' },
  { find: 'Ό', replace: 'Ó' },
  { find: 'Ύ', replace: 'Í' },
  { find: 'Ώ', replace: 'Ó' },
  { find: 'ΰ', replace: 'Ï' },
  { find: 'ΐ', replace: 'Ï' },
  { find: 'Ϊ', replace: 'Ï' },
  { find: 'Ϋ', replace: 'Ï' }
]

const greekToTransliteratedLatinMap = [
  { find: 'α', replace: 'a' },
  { find: 'β', replace: 'v' },
  { find: 'γ', replace: 'g' },
  { find: 'δ', replace: 'd' },
  { find: 'ε', replace: 'e' },
  { find: 'ζ', replace: 'z' },
  { find: 'η', replace: 'ē' },
  { find: 'θ', replace: 'th' },
  { find: 'ι', replace: 'i' },
  { find: 'κ', replace: 'k' },
  { find: 'λ', replace: 'l' },
  { find: 'μ', replace: 'm' },
  { find: 'ν', replace: 'n' },
  { find: 'ξ', replace: 'x' },
  { find: 'ο', replace: 'o' },
  { find: 'π', replace: 'p' },
  { find: 'ρ', replace: 'r' },
  { find: 'σ', replace: 's' },
  { find: 'ς', replace: 's' },
  { find: 'τ', replace: 't' },
  { find: 'υ', replace: 'u' },
  { find: 'φ', replace: 'ph' },
  { find: 'χ', replace: 'kh' },
  { find: 'ψ', replace: 'ps' },
  { find: 'ω', replace: 'ō' },

  { find: 'ά', replace: 'á' },
  { find: 'έ', replace: 'é' },
  { find: 'ί', replace: 'í' },
  { find: 'ό', replace: 'ó' },
  { find: 'ύ', replace: 'ú' },
  { find: 'ή', replace: 'ḗ' },
  { find: 'ώ', replace: 'ṓ' },
  { find: 'ϊ', replace: 'ï' },
  { find: 'ϋ', replace: 'ü' },
  { find: 'ΰ', replace: 'ǘ' },
  { find: 'ΐ', replace: 'ḯ' },

  { find: 'Α', replace: 'A' },
  { find: 'Β', replace: 'V' },
  { find: 'Γ', replace: 'G' },
  { find: 'Δ', replace: 'D' },
  { find: 'Ε', replace: 'E' },
  { find: 'Ζ', replace: 'Z' },
  { find: 'Η', replace: 'Ē' },
  { find: 'Θ', replace: 'Th' },
  { find: 'Ι', replace: 'I' },
  { find: 'Κ', replace: 'K' },
  { find: 'Λ', replace: 'L' },
  { find: 'Μ', replace: 'M' },
  { find: 'Ν', replace: 'N' },
  { find: 'Ξ', replace: 'X' },
  { find: 'Ο', replace: 'O' },
  { find: 'Π', replace: 'P' },
  { find: 'Ρ', replace: 'R' },
  { find: 'Σ', replace: 'S' },
  { find: 'Τ', replace: 'T' },
  { find: 'Υ', replace: 'U' },
  { find: 'Φ', replace: 'Ph' },
  { find: 'Χ', replace: 'Kh' },
  { find: 'Ψ', replace: 'Ps' },
  { find: 'Ω', replace: 'Ō' },

  { find: 'Ά', replace: 'Á' },
  { find: 'Έ', replace: 'É' },
  { find: 'Ί', replace: 'Í' },
  { find: 'Ό', replace: 'Ó' },
  { find: 'Ύ', replace: 'Ú' },
  { find: 'Ή', replace: 'Ḗ' },
  { find: 'Ώ', replace: 'Ṓ' },
  { find: 'Ϊ', replace: 'Ï' },
  { find: 'Ϋ', replace: 'Ü' },

  { find: ';', replace: '?' }
]
