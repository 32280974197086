<template>
  <v-container
    class="fill-height pa-2"
    fluid
  >
    <v-row
      v-if="inbox.length > 0"
    >
      <v-col
        lg="3"
        md="4"
        sm="12"
      >
        <v-card>
          <v-toolbar
            flat
            height="80"
          >
            <v-avatar :color="$t('Message.Toolbar.Color')">
              <v-icon color="indigo lighten-5">
                {{ $t('Message.Toolbar.Icon') }}
              </v-icon>
            </v-avatar>

            <v-toolbar-title
              class="pl-3"
            >
              <div class="body-3">
                {{ $tc('Message.Title', 0) }}
              </div>
              <div class="text-caption">
                {{ $t('Message.Toolbar.Subtitle') }}
              </div>
            </v-toolbar-title>
            <v-spacer />
            <v-menu offset-y>
              <template #activator="{on}">
                <v-btn
                  class="mx-0"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon dark>
                    {{ $t('Message.Toolbar.Button.Filter.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list
                dense
                subheader
              >
                <v-subheader>{{ $t('Message.Toolbar.Button.Filter.Title') }}</v-subheader>
                <v-divider />
                <v-list-item
                  :class="[{'primary--text': filterByProperty===''}]"
                  :inactive="filterByProperty===''"
                  @click="filterByProperty=''"
                >
                  <v-list-item-title>{{ $t('Common.Misc.All') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="group in groups"
                  :key="group.Id"
                  :class="[{'primary--text': filterByProperty.toString()===group.Id.toString()}]"
                  :inactive="filterByProperty.toString()===group.Id.toString()"
                  @click="filterByProperty=group.Id"
                >
                  <v-list-item-title>{{ group.Name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <template #activator="{on}">
                <v-btn
                  class="mx-0"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon dark>
                    {{ $t('Message.Toolbar.Button.Group.Icon') }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list
                dense
                subheader
              >
                <v-subheader>{{ $t('Message.Toolbar.Button.Group.Title') }}</v-subheader>
                <v-divider />
                <v-list-item
                  v-for="(group, index) in groupByGroups"
                  :key="index"
                  :class="[{'primary--text': groupByProperty.toString()===group.property.toString()}]"
                  :inactive="groupByProperty.toString()===group.property.toString()"
                  @click="groupByProperty=group.property"
                >
                  <v-list-item-title>{{ group.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="userIsSuperAdmin"
              class="mx-0"
              icon
              small
              @click="createNew"
            >
              <v-icon dark>
                {{ $t('Message.Toolbar.Button.New.Icon') }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text
            ref="messageList"
            class="pa-0"
            style="overflow-y: auto;"
          >
            <v-list
              style="overflow-y: auto;"
              two-line
            >
              <template v-for="(group, key) in messagesFilteredAndGrouped">
                <v-subheader :key="'header' + key">
                  <timeago
                    v-if="groupByProperty.toString() === groupByGroups.date.property.toString()"
                    :auto-update="1"
                    :locale="$i18n.locale"
                    :max-time="86400 * 365 * 100"
                    :since="getMessageTimeSince(key)"
                    min-val-calc="days"
                  />
                  <span v-else-if="groupByProperty.toString() === groupByGroups.read.property.toString()">
                    {{ (key === 'true') ? $t('Message.Toolbar.Label.Reading.Read') : $t('Message.Toolbar.Label.Reading.Unread') }}
                  </span>
                  <span v-else-if="groupByProperty.toString() === groupByGroups.important.property.toString()">
                    {{ (key === 'true') ? $t('Message.Toolbar.Label.Importance.Important') : $t('Message.Toolbar.Label.Importance.Regular') }}
                  </span>
                  <span v-else>
                    {{ key }}
                  </span>
                </v-subheader>
                <template v-for="(message, index) in group">
                  <v-list-item
                    :key="'list-tile-' + message.Id + '-' + key + '-' + index"
                    class="ma-1"
                    ripple
                    @click="showMessage(message)"
                  >
                    <div class="mr-4">
                      <v-badge
                        :value="message.Important"
                        color="red"
                        overlap
                        right
                      >
                        <v-icon
                          slot="badge"
                          dark
                          small
                        >
                          fas fa-exclamation
                        </v-icon>

                        <v-avatar
                          :color="message.Type.Color ? message.Type.Color : 'indigo'"
                          size="48"
                          dark
                        >
                          <v-icon
                            :color="message.Type.Color ? (message.Type.Color + ' lighten-5') : 'indigo lighten-5'"
                            dark
                          >
                            {{ message.Type.Icon }}
                          </v-icon>
                        </v-avatar>
                      </v-badge>
                    </div>

                    <v-list-item-content>
                      <v-list-item-title :class="[{'font-weight-bold': !message.Read}]">
                        {{ message.Type.Name }}
                      </v-list-item-title>
                      <v-list-item-subtitle :class="[{'font-weight-bold': !message.Read}]">
                        {{ message.Type.Subject }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="text-caption grey--text text--lighten-1"
                        v-html="getMessageBodyHtml(message)"
                      />
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        {{ message.DateAdded }}
                      </v-list-item-action-text>

                      <v-list-item-action-text>
                        {{ message.TimeAdded }}
                      </v-list-item-action-text>

                      <div>
                        <v-btn
                          v-if="userIsSuperAdmin"
                          icon
                          small
                          color="amber"
                          @click.stop="createNewFromMessage(message)"
                        >
                          <v-icon size="18">
                            mdi-message-plus-outline
                          </v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          small
                          @click.stop="showMessage(message, true)"
                        >
                          <v-icon>
                            mdi-fullscreen
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    :key="'divider-' + message.Id + '-' + key + '-' + index"
                    inset
                  />
                </template>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        lg="9"
        md="8"
      >
        <v-card
          v-if="selectedMessage"
        >
          <v-toolbar
            flat
            height="80"
          >
            <div class="mr-3">
              <v-badge
                :value="selectedMessage.Important"
                color="red"
                overlap
                right
              >
                <v-icon
                  slot="badge"
                  dark
                  small
                >
                  fas fa-exclamation
                </v-icon>
                <v-avatar :color="selectedMessage.Type.Color ? selectedMessage.Type.Color : 'indigo'">
                  <v-icon :color="selectedMessage.Type.Color ? (selectedMessage.Type.Color + ' lighten-5') : 'indigo lighten-5'">
                    {{ selectedMessage.Type.Icon }}
                  </v-icon>
                </v-avatar>
              </v-badge>
            </div>

            <v-toolbar-title
              class="pl-1"
            >
              <div class="body-3">
                {{ selectedMessage.Type.Name }}
              </div>
              <div class="text-caption">
                {{ selectedMessage.Type.Subject }}
              </div>
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-title
              class="px-4"
            >
              <div class="text-caption text-right">
                {{ selectedMessage.DateAdded }} {{ selectedMessage.TimeAdded }}
              </div>
            </v-toolbar-title>
            <v-btn
              class="mx-0"
              icon
              @click="toggleFullScreen"
            >
              <v-icon>fullscreen</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text
            ref="messageBody"
            v-dynamic-router-link
            style="overflow: auto;"
            :style="userIsSuperAdmin ? 'user-select: text;' : ''"
            v-html="selectedMessage.Body.replace(new RegExp('{username}', 'g'), posUser.staff_name).replace(new RegExp('{{\\$user_name}}', 'gm'), posUser.staff_name)"
          />
          <v-divider />
          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn
              class="light-blue--text text--darken-1"
              outlined
              @click="onCancelButtonClick"
            >
              {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-row
          v-else
          class="align-center fill-height"
        >
          <v-col
            class="text-center"
            cols="12"
          >
            <div
              class="grey--text pa-6"
            >
              <v-icon
                class="mb-4"
                color="grey lighten-2"
                size="128"
              >
                {{ $t('Message.ListView.Select.Icon') }}
              </v-icon>
              <div class="text-h6 mb-2">
                {{ $t('Message.ListView.Select.Title') }}
              </div>
              <div class="text-subtitle-1">
                {{ $t('Message.ListView.Select.Subtitle') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      v-else
      align="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <div
          class="grey--text pa-6"
        >
          <v-icon
            class="mb-4"
            color="grey lighten-2"
            size="128"
          >
            {{ $t('Message.ListView.NoData.Icon') }}
          </v-icon>
          <div class="text-h6 mb-2">
            {{ $tc('Message.Title', 0) }}
          </div>
          <div class="text-subtitle-1">
            {{ $t('Message.ListView.NoData.Subtitle') }}
          </div>
          <div class="pa-2">
            <v-btn
              v-if="userIsSuperAdmin"
              color="primary"
              dark
              @click="createNew"
            >
              {{ $t('Message.Toolbar.Button.New.Title').toLocaleUpperCase($i18n.locale) }}
              <v-icon right>
                {{ $t('Message.Toolbar.Button.New.Icon') }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <message-details-dialog
      :message="selectedMessage"
      :visible.sync="showMessageDialog"
    />

    <message-send-dialog
      v-if="userIsSuperAdmin"
      :message="selectedMessageEdit"
      :visible.sync="showSendMessageDialog"
    />
  </v-container>
</template>

<script>
import moment               from 'moment'
import MessageDetailsDialog from './MessageDetailsDialog'
import MessageSendDialog    from './MessageSendDialog'
import Resizable            from '../../mixins/resizable'
import AppData              from '../../mixins/appdata'
import Auth                 from '../../mixins/auth'

export default {
  name      : 'MessagesInbox',
  components: {
    MessageSendDialog,
    MessageDetailsDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable],
  props     : [],
  DataStore : {
    messages: 'messages',
    counts  : 'messages.count',
    inbox   : 'messages.inbox'
  },
  data () {
    return {
      showSendMessageDialog: false,
      showMessageDialog    : false,
      selectedMessage      : null,
      selectedMessageEdit  : null,
      filterByProperty     : '',
      groupByProperty      : 'DateAdded',
      groupByGroups        : {
        date: {
          title   : this.$t('Common.Date.Title'),
          property: 'DateAdded'
        },
        from: {
          title   : this.$t('Message.Toolbar.Label.Sender'),
          property: 'Type.Name'
        },
        read: {
          title   : this.$t('Message.Toolbar.Label.Reading.Read'),
          property: 'Read'
        },
        important: {
          title   : this.$t('Message.Toolbar.Label.Importance.Important'),
          property: 'Important'
        },
        type: {
          title   : this.$t('Message.Toolbar.Label.Type'),
          property: 'Type.GroupName'
        }
      }
    }
  },
  computed: {
    groups () {
      return this.messages.groups.all()
    },
    messagesFilteredAndGrouped () {
      return this.inbox.filteredAndGrouped(this.filterByProperty, this.groupByProperty)
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.messages.fetchCounts()
    this.inbox.fetch()
  },
  beforeUpdate () {
  },
  updated () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    showMessage (message, showInDialog = false) {
      if (this.selectedMessage !== message) {
        message.markAsRead()
      }
      this.selectedMessage = message
      if (this.$vuetify.breakpoint.smAndDown || showInDialog) {
        this.showMessageDialog = true
      } else {
        this.showMessageDialog = false
      }
    },

    createNew () {
      this.selectedMessageEdit = null
      this.showSendMessageDialog = true
    },

    createNewFromMessage (message) {
      this.selectedMessageEdit = message
      this.showSendMessageDialog = true
    },

    toggleFullScreen () {
      this.showMessageDialog = true
    },

    onCancelButtonClick () {
      this.selectedMessage = null
    },

    onResize () {
      const messageList = this.$refs.messageList
      const messageBody = this.$refs.messageBody

      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0

      if (messageList) messageList.style.height = (innerHeight - headerHeight - footerHeight - 81 - 24) + 'px'
      if (messageBody) messageBody.style.height = (innerHeight - headerHeight - footerHeight - 150 - 24) + 'px'
    },

    getMessageTimeSince (dateString) {
      return moment(dateString, 'DD-MM-YYYY').subtract(moment().days())
    },
    getMessageBodyHtml (message) {
      const messageBody = message?.Body || ''

      return messageBody
        .replace(/(<([^>]+)>)/ig, '')
        .replace(new RegExp(this.$t('Message.Toolbar.Label.Dear', 'el') + ' {username},', 'g'), '')
        .replace(new RegExp(this.$t('Message.Toolbar.Label.Dear', 'en') + ' {username},', 'g'), '')
        .replace(new RegExp(this.$t('Message.Toolbar.Label.Dear', 'el') + ' {{\\$user_name}},', 'g'), '')
        .replace(new RegExp(this.$t('Message.Toolbar.Label.Dear', 'en') + ' {{\\$user_name}},', 'g'), '')
    }
  }
}
</script>

<style scoped>

</style>
