import { render, staticRenderFns } from "./SelectTableCompletedOrders.vue?vue&type=template&id=90ee0420&scoped=true"
import script from "./SelectTableCompletedOrders.vue?vue&type=script&lang=js"
export * from "./SelectTableCompletedOrders.vue?vue&type=script&lang=js"
import style0 from "./SelectTableCompletedOrders.vue?vue&type=style&index=0&id=90ee0420&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ee0420",
  null
  
)

export default component.exports