export default {
  ListView: {
    Title: 'Προσωπικό | Προσωπικό | Προσωπικό',

    Button: {
      StaffAdd: {
        Title: 'Προσωπικό',
        Color: 'primary',
        Icon : 'add_box'
      },
      StaffGroups: {
        Title: 'Ομάδες Προσωπικού',
        Color: 'indigo',
        Icon : 'group'
      }
    },

    Table: {
      Button: {
        Edit: {
          Title: 'Επεξεργασία',
          Color: 'cyan',
          Icon : 'edit'
        }
      },
      Headers: {
        Id            : '#',
        Firstname     : 'Όνομα',
        Lastname      : 'Επώνυμο',
        Email         : 'E-mail',
        Domain        : 'Domain',
        GroupName     : 'Ομάδα',
        HasPermissions: 'Permissions',
        Locale        : 'Γλώσσα',
        SingleLogin   : 'Single Login',
        Guest         : 'Επισκέπτης',
        Status        : 'Κατάσταση',
        DateAdded     : 'Ημ/νία εγγραφής',
        Actions       : ''
      }
    }
  },
  StaffAddDialog: {
    Add: {
      Title   : 'Προσθήκη Προσωπικού',
      Subtitle: 'Δημιουργία Νέου Προσωπικού',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },
    Edit: {
      Title   : 'Επεξεργασία Προσωπικού',
      Subtitle: 'Τροποποίηση Στοιχείων Προσωπικού',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },
    Tab: {
      Info       : 'Στοιχεία',
      Permissions: 'Δικαιώματα'
    },
    Field: {
      Firstname: {
        Label: 'Όνομα',
        Hint : 'Όνομα προσωπικού. πχ. Βαγγέλης, Μαρία, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Lastname: {
        Label: 'Επίθετο',
        Hint : 'Επίθετο προσωπικού. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Telephone: {
        Label: 'Τηλέφωνο',
        Hint : 'Κινητό ή σταθερό τηλέφωνο προσωπικού. πχ. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Διεύθυνση Email προσωπικού. πχ. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
          Valid  : 'Το E-mail πρέπει να είναι έγκυρο'
        }
      },
      GroupId: {
        Label: 'Ομάδα Προσωπικού',
        Hint : 'Ομάδα προσωπικού. πχ. Διαχειριστής, Χρήστης',
        Icon : 'group'
      },
      Pin: {
        Label: 'PIN',
        Hint : 'Το PIN του προσωπικού πχ. 1234',
        Icon : 'fiber_pin',
        Error: {
          FixedNumber: 'Το PIN πρέπει να είναι 4 αριθμοί'
        }
      },
      Locale: {
        Label: 'Γλώσσα Συστήματος',
        Hint : 'Επιλέξτε τη γλώσσα που θέλετε να εμφανίζεται το σύστημα',
        Icon : 'language'
      },
      Domain: {
        Label: 'Domain',
        Icon : 'http',
        Error: ''
      },
      Group: {
        Label: 'Group',
        Icon : 'group_work',
        Error: ''
      },
      VoipDomain: {
        Label: 'VoipDomain',
        Icon : 'phonelink_setup',
        Error: ''
      },
      VoipAgent: {
        Label: 'VoipAgent',
        Icon : 'contact_phone',
        Error: ''
      },
      Status: {
        Title: 'Κατάσταση'
      },
      Guest: {
        Title: 'Guest Login'
      }
    }
  },

  StaffGroups: {
    Misc: {
      User       : 'Χρήστες | Χρήστης | Χρήστες',
      ChooseGroup: {
        Title   : 'Επιλέξτε ομάδα προσωπικού',
        Subtitle: 'Επιλέξτε ομάδα προσωπικού από τη λίστα',
        Icon    : 'group'
      },
      ChoosePackage: 'Επιλέξτε Πακέτο'
    },
    Groups: {
      Title         : 'Ομάδες Προσωπικού',
      Subtitle      : 'Δικαιώματα Πρόσβασης',
      Color         : 'indigo',
      Icon          : 'group',
      ModulesPackage: 'Modules / Package'
    },
    SingleGroup: {
      Subtitle: 'Δικαιώματα Πρόσβασης',
      Color   : 'blue',
      Icon    : 'group_work',
      Headers : {
        Right : 'Δικαίωμα',
        Access: 'Πρόσβαση',
        Api   : 'Api',
        Edit  : 'Διαχείριση',
        Add   : 'Προσθήκη',
        Delete: 'Διαγραφή'
      }
    }

  }
}
