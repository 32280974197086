import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class PaymentFeeDomainValidator extends BaseValidator {
  rules = {
    id    : { required: true, type: 'number' },
    active: { required: true, type: 'boolean' },
    factor: { required: true, type: 'number' },
    fee   : { required: true, type: 'number' },
    title : { required: false, type: 'string' }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
