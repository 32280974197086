/**
 * Enum for receipt provider type.
 * @readonly
 * @enum {String} ReceiptProviderTypeEnum
 */

const ReceiptProviderTypeEnum = {
  CLOUD        : 'cloud',
  LOCAL        : 'local',
  CASH_REGISTER: 'cash_register',
  TAX_REGISTER : 'tax_register',
  NONE         : ''
}

export default ReceiptProviderTypeEnum
