/**
 * Enum for payment types.
 * @readonly
 * @enum {String} PaymentTypeEnum
 */

const PaymentTypeEnum = {
  CASH    : 'cod',
  CARD    : 'pos_card',
  MULTIPLE: 'multiple',

  VIVA_SMART      : 'viva_card_smart_checkout',
  VIVA_3DS        : 'viva_card_3ds',
  VIVA_MARKETPLACE: 'viva_marketplace',
  STRIPE          : 'stripe',
  EVERYPAY        : 'everypay_card',

  DELIVERY: 'delivery_card',

  get ONLINE_CARDS () {
    return [this.VIVA_SMART, this.VIVA_3DS, this.VIVA_MARKETPLACE, this.STRIPE, this.EVERYPAY]
  },

  get DELIVERY_CARDS () {
    return [this.DELIVERY]
  },

  get POS_CARDS () {
    return [this.CARD]
  },

  get ALL_CARDS () {
    return [...this.ONLINE_CARDS, ...this.DELIVERY_CARDS, ...this.POS_CARDS]
  },

  get ALL_TYPES () {
    return [this.CASH, this.MULTIPLE, ...this.ONLINE_CARDS, ...this.DELIVERY_CARDS, ...this.POS_CARDS]
  }
}

export default PaymentTypeEnum
