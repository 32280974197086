import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class SupportTicketValidator extends BaseValidator {
  rules = {
    subject   : { required: true, type: 'string', betweenLen: [3, 300] },
    message   : { required: true, type: 'string', messageLength: (v) => v.replace(/(<([^>]+)>)/ig, '').length >= 5 },
    department: { required: true, type: 'number', departmentSelected: (v) => v > 0 },
    priority  : { required: true, type: 'number', prioritySelected: (v) => v > 0 },
    service   : { required: true, type: 'number', serviceSelected: (v) => v > 0 }
  }

  errorMessages = {
    el: {
      messageLength     : 'Πρέπει να είναι από 5 χαρακτήρες',
      departmentSelected: 'Παρακαλώ επιλέξτε τμήμα',
      prioritySelected  : 'Παρακαλώ επιλέξτε προτεραιότητα',
      serviceSelected   : 'Παρακαλώ επιλέξτε υπηρεσία'
    },
    en: {
      messageLength     : 'Minimum length is 5',
      departmentSelected: 'Please select department',
      prioritySelected  : 'Please select priority',
      serviceSelected   : 'Please select service'
    }
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
