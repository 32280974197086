<template>
  <v-toolbar
    :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
    class="filter-toolbar"
    flat
  >
    <v-btn-toggle
      v-model="timeRange"
      class="transparent"
      mandatory
      borderless
      tile
      color="primary"
      dense
      @change="onTimeRangeChange"
    >
      <v-btn
        v-if="!hideToday"
        text
        color="grey"
        active-class="primary--text text--darken-1"
        :value="valueToday"
        class="rounded"
      >
        <v-icon
          small
          left
          :color="timeRange === valueToday ? 'primary darken-1' : 'grey'"
        >
          fas fa-calendar-day
        </v-icon>

        <span v-if="$vuetify.breakpoint.smAndUp">
          {{ $t('Common.Date.Today') }}
        </span>
      </v-btn>

      <v-btn
        v-if="!hideWeek"
        text
        color="grey"
        active-class="primary--text text--darken-1"
        :value="valueWeek"
        class="rounded"
      >
        <v-icon
          small
          left
          :color="timeRange === valueWeek ? 'primary darken-1' : 'grey'"
        >
          fas fa-calendar-week
        </v-icon>

        <span v-if="$vuetify.breakpoint.smAndUp">
          {{ $t('Common.Date.Week') }}
        </span>
      </v-btn>

      <v-btn
        v-if="!hideMonth"
        text
        color="grey"
        active-class="primary--text text--darken-1"
        :value="valueMonth"
        class="rounded"
      >
        <v-icon
          small
          left
          :color="timeRange === valueMonth ? 'primary darken-1' : 'grey'"
        >
          fas fa-calendar-alt
        </v-icon>

        <span v-if="$vuetify.breakpoint.smAndUp">
          {{ $t('Common.Date.Month') }}
        </span>
      </v-btn>

      <v-btn
        v-if="!hideYear"
        text
        color="grey"
        active-class="primary--text text--darken-1"
        :value="valueYear"
        class="rounded"
      >
        <v-icon
          small
          left
          :color="timeRange === valueYear ? 'primary darken-1' : 'grey'"
        >
          fas fa-calendar
        </v-icon>

        <span v-if="$vuetify.breakpoint.smAndUp">
          {{ $t('Common.Date.Year') }}
        </span>
      </v-btn>

      <v-btn
        v-if="!hidePeriod"
        text
        color="grey"
        active-class="primary--text text--darken-1"
        :value="valuePeriod"
        class="rounded"
      >
        <v-icon
          small
          left
          :color="timeRange === valuePeriod ? 'primary darken-1' : 'grey'"
        >
          fas fa-calendar-plus
        </v-icon>

        <span v-if="$vuetify.breakpoint.smAndUp">
          {{ $t('Common.Date.Period') }}
        </span>
      </v-btn>
    </v-btn-toggle>

    <v-divider
      v-if="$vuetify.breakpoint.mdAndUp"
      class="mx-2"
      vertical
    />

    <template v-if="timeRange === valuePeriod">
      <template :slot="$vuetify.breakpoint.smAndDown && timeRange === valuePeriod ? 'extension' : 'default'">
        <v-menu
          v-show="statsDateFieldsVisible"
          ref="statsStartDateVisible"
          v-model="statsStartDateVisible"
          :close-on-content-click="false"
          left
          max-width="290px"
          min-width="140px"
          offset-y
          style="width: 100%"
          transition="scale-transition"
        >
          <template #activator="{on, attrs}">
            <v-text-field
              v-model="statsStartDateFmt"
              :label="$t('Common.Date.PeriodFrom')"
              class="dateTextField ml-2"
              :background-color="isDarkMode ? '' : 'grey lighten-3'"
              clearable
              flat
              height="36px"
              prepend-inner-icon="event"
              readonly
              single-line
              solo
              v-bind="attrs"
              @blur="statsStartDate = parseDate(statsStartDateFmt)"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="statsStartDate"
            :allowed-dates="allowedStartDates"
            :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
            first-day-of-week="1"
            scrollable
            @input="statsStartDateFmt = formatDate($event); statsStartDateVisible = false"
          />
        </v-menu>

        <v-menu
          v-show="statsDateFieldsVisible"
          ref="statsEndDateVisible"
          v-model="statsEndDateVisible"
          :close-on-content-click="false"
          left
          max-width="290px"
          min-width="140px"
          offset-y
          style="width: 100%"
          transition="scale-transition"
        >
          <template #activator="{on, attrs}">
            <v-text-field
              v-model="statsEndDateFmt"
              :label="$t('Common.Date.PeriodTo')"
              class="dateTextField ml-2 mr-2"
              :background-color="isDarkMode ? '' : 'grey lighten-3'"
              clearable
              flat
              height="36px"
              hide-details
              prepend-inner-icon="event"
              readonly
              single-line
              solo
              v-bind="attrs"
              @blur="statsEndDate = parseDate(statsEndDateFmt)"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="statsEndDate"
            :allowed-dates="allowedEndDates"
            :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
            first-day-of-week="1"
            scrollable
            @input="statsEndDateFmt = formatDate($event); statsEndDateVisible = false"
          />
        </v-menu>
      </template>
    </template>

    <slot />
  </v-toolbar>
</template>

<script>

import vModel  from '@/mixins/vModel'
import Appdata from '@/mixins/appdata'

export default {
  name      : 'ToolbarPeriodSelector',
  components: {},
  directives: {},
  mixins    : [Appdata, vModel],
  props     : {
    defaultPeriod: {
      type   : String,
      default: 'today'
    },

    valueToday: {
      type   : String,
      default: 'today'
    },
    valueWeek: {
      type   : String,
      default: 'week'
    },
    valueMonth: {
      type   : String,
      default: 'month'
    },
    valueYear: {
      type   : String,
      default: 'year'
    },
    valuePeriod: {
      type   : String,
      default: 'custom'
    },

    hideToday: {
      type   : Boolean,
      default: false
    },
    hideWeek: {
      type   : Boolean,
      default: false
    },
    hideMonth: {
      type   : Boolean,
      default: false
    },
    hideYear: {
      type   : Boolean,
      default: false
    },
    hidePeriod: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      statsStartDateVisible: false,
      statsEndDateVisible  : false,
      statsStartDate       : '',
      statsStartDateFmt    : '',
      statsEndDate         : '',
      statsEndDateFmt      : '',
      timeRange            : this.defaultPeriod
    }
  },
  computed: {
    statsDateFieldsVisible () {
      return this.timeRange !== this.valueToday && this.timeRange !== this.valueWeek && this.timeRange !== this.valueMonth && this.timeRange !== this.valueYear
    }
  },
  watch: {
    timeRange (newVal) {
      if (newVal !== this.valuePeriod) this.statsStartDateFmt = this.statsStartDate = this.statsEndDateFmt = this.statsEndDate = ''
    },
    statsStartDate (newVal) {
      if (this.statsEndDate && newVal) {
        this.statsDatePeriod = newVal + ' 00:00_' + this.statsEndDate + ' 00:00'
        this.onTimeRangeChange()
      } else {
        this.statsDatePeriod = this.defaultPeriod
      }
    },
    statsEndDate (newVal) {
      if (this.statsStartDate && newVal) {
        this.statsDatePeriod = this.statsStartDate + ' 00:00_' + newVal + ' 00:00'
        this.onTimeRangeChange()
      } else {
        this.statsDatePeriod = this.defaultPeriod
      }
    }
  },
  created () {
    this.vModel = this.timeRange
  },
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {
    onTimeRangeChange () {
      if (this.timeRange !== this.valuePeriod) {
        this.vModel = this.timeRange
      } else {
        if (this.statsDatePeriod && this.statsDatePeriod !== this.defaultPeriod) {
          this.vModel = this.statsDatePeriod
        }
      }
    },

    allowedStartDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.statsEndDate ? new Date(this.statsEndDate).getTime() : null
      return maxDate ? (currDate <= maxDate) : true
    },

    allowedEndDates: function (date) {
      const currDate = new Date(date).getTime()
      const maxDate = this.statsStartDate ? new Date(this.statsStartDate).getTime() : null
      return maxDate ? (currDate >= maxDate) : true
    },

    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },

    parseDate (date) {
      if (!date) {
        return null
      }

      const [day, month, year] = date.split('-')
      return `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }`
    }
  }
}
</script>

<style scoped>
/deep/ .dateTextField {
  max-width : 170px;
}

/deep/ .dateTextField .v-input__prepend-outer {
  margin-top : 8px;
}

/deep/ .dateTextField .v-input__control {
  line-height : 36px !important;
  height      : 36px !important;
  min-height  : 36px !important;
}
</style>
