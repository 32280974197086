<template>
  <v-row class="fill-height align-center justify-center">
    <div
      class="text-center grey--text"
    >
      <v-avatar
        class="mb-2"
        color="white"
        size="140"
        tile
      >
        <svg-logo size="150px" />
      </v-avatar>

      <div
        class="text-h5 ma-2 grey--text text--darken-1"
        style="height: auto;"
      >
        {{ $t('Settings.Wizard.Dialog.InstallationGuide') }}
      </div>

      <div
        v-if="!videoReady"
        class="mt-12"
      >
        <v-progress-circular
          :size="100"
          :width="3"
          color="red"
          indeterminate
        />
      </div>
      <div style="min-width: 200px; width: 100vw; max-width: 800px;">
        <div
          :class="[{'video-responsive': videoReady}]"
          :style="videoReady ? '' : 'height: 0px !important;'"
        >
          <youtube
            v-if="wizardVisible"
            :player-vars="youtubePlayerOptions"
            :style="videoReady ? 'opacity: 1' : 'opacity: 0'"
            :video-id="videoId"
            host="https://www.youtube-nocookie.com"
            @ready="onVideoReady"
          />
        </div>
      </div>

      <div
        v-if="videoReady"
        class="text-subtitle-1"
        style="height: auto;"
      >
        {{ $t('Settings.Wizard.Intro.Select') }}{{ $t('Common.Button.Next').toLocaleUpperCase($i18n.locale) }}{{ $t('Settings.Wizard.Intro.ToBegin') }}
      </div>
    </div>
  </v-row>
</template>

<script>
import AppData                      from '@/mixins/appdata'
import Auth                         from '@/mixins/auth'
import PlansEnum                    from '@/mixins/plans/plansEnum'
import SvgLogo                      from '@/components/common/SvgLogo'
import { YouTubePlayer as Youtube } from 'vue-youtube-embed'

export default {
  name      : 'SettingsWizardIntro',
  components: {
    SvgLogo,
    Youtube
  },
  mixins: [AppData, Auth, PlansEnum],
  props : {
    wizardVisible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      videoReady: false
    }
  },
  computed: {
    videoId () {
      const video = {
        el: {
          contactless: 'PxC2hi1b0qo',
          getsmart   : '0RpiGB_u60s'
        },
        en: {
          contactless: '8VXAgv3Pn3A',
          getsmart   : 'AaPLa4pzGHQ'
        }
      }

      return video[this.$i18n.locale === 'el' ? 'el' : 'en'][this.appConfig.LOCATION_DATA.CatalogOnly && this.appConfig.LOCATION_DATA.PackageId === this.PlansEnum.GetContactless ? 'contactless' : 'getsmart']
    },
    youtubePlayerOptions () {
      return {
        rel           : 0,
        autoplay      : 0,
        hl            : this.$i18n.locale,
        disablekb     : 1,
        modestbranding: 1,
        controls      : 1
      }
    }
  },
  watch: {
    wizardVisible (newVal) {
      if (!newVal) this.videoReady = false
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {
    onVideoReady () {
      this.videoReady = true
    },
    saveStep () {
      this.$emit('wizard-component-loading', true)
      this.$emit('wizard-component-loading', false)
      this.$emit('wizard-component-complete')
    }
  }
}
</script>

<style scoped>
/deep/ .video-responsive {
  padding : 10px;
}

/deep/ .video-responsive div {
  position       : relative;
  padding-bottom : 56.25%;
  height         : 0;
  overflow       : hidden;
  max-width      : 100%;
}

/deep/ .video-responsive iframe, /deep/ .video-responsive object, /deep/ .video-responsive embed {
  position : absolute;
  top      : 0;
  left     : 0;
  width    : 100%;
  height   : 100%;
}
</style>
