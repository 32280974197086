<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Email.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Email.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Email.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Email.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-form
          ref="emailForm"
          v-model="emailFormValid"
          @submit.prevent
        >
          <v-text-field
            v-model="viewData.host"
            :counter="96"
            :disabled="!userCanManage"
            :hint="$t('Settings.Email.Field.SMTPAddress.Hint')"
            :label="$t('Settings.Email.Field.SMTPAddress.Label')"
            :prepend-icon="$t('Settings.Email.Field.SMTPAddress.Icon')"
            :rules="validationRules.Smtp"
            required
          />

          <v-text-field
            v-model="viewData.port"
            :counter="6"
            :disabled="!userCanManage"
            :hint="$t('Settings.Email.Field.SMTPPort.Hint')"
            :label="$t('Settings.Email.Field.SMTPPort.Label')"
            :prepend-icon="$t('Settings.Email.Field.SMTPPort.Icon')"
            :rules="validationRules.Port"
            required
          />

          <v-select
            v-model="viewData.encryption"
            :disabled="!userCanManage"
            :hint="$t('Settings.Email.Field.Encryption.Hint')"
            :items="encryptionList"
            :label="$t('Settings.Email.Field.Encryption.Label')"
            :prepend-icon="$t('Settings.Email.Field.Encryption.Icon')"
            item-text="name"
            item-value="value"
            required
          />

          <v-text-field
            v-model="viewData.username"
            :counter="96"
            :disabled="!userCanManage"
            :hint="$t('Settings.Email.Field.Username.Hint')"
            :label="$t('Settings.Email.Field.Username.Label')"
            :prepend-icon="$t('Settings.Email.Field.Username.Icon')"
            :rules="validationRules.Username"
            autocomplete="off"
            required
          />

          <v-text-field
            v-model="viewData.password"
            :append-icon="!passwordVisible ? 'visibility' : 'visibility_off'"
            :counter="96"
            :disabled="!userCanManage"
            :hint="$t('Settings.Email.Field.Password.Hint')"
            :label="$t('Settings.Email.Field.Password.Label')"
            :prepend-icon="$t('Settings.Email.Field.Password.Icon')"
            :rules="validationRules.Password"
            :type="!passwordVisible ? 'password' : 'text'"
            autocomplete="off"
            required
            @click:append="(passwordVisible = !passwordVisible)"
          />

          <v-text-field
            v-model="viewData.from_address"
            :counter="96"
            :disabled="!userCanManage"
            :hint="$t('Settings.Email.Field.SenderAddress.Hint')"
            :label="$t('Settings.Email.Field.SenderAddress.Label')"
            :prepend-icon="$t('Settings.Email.Field.SenderAddress.Icon')"
            :rules="validationRules.Email"
            autocomplete="off"
            required
          />

          <v-text-field
            v-model="viewData.from_name"
            :disabled="!userCanManage"
            :hint="$t('Settings.Email.Field.SenderName.Hint')"
            :label="$t('Settings.Email.Field.SenderName.Label')"
            :prepend-icon="$t('Settings.Email.Field.SenderName.Icon')"
            autocomplete="off"
            required
          />
        </v-form>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-menu
        v-if="userIsSuperAdmin"
        v-model="testEmailPopOverVisible"
        :close-on-content-click="false"
        :disabled="!tmpFormValid"
        :nudge-width="85"
        class="ma-0 pa-0"
        top
      >
        <template #activator="{on}">
          <v-btn
            :disabled="!tmpFormValid"
            :icon="$vuetify.breakpoint.xsOnly"
            color="primary"
            depressed
            v-on="on"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">
              send
            </v-icon>
            <span v-else>{{ $t('Settings.Email.Field.TestEmail.Title') }}</span>
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <v-text-field
              v-model="testEmail"
              :append-icon="$t('Settings.Email.Field.TestEmail.Email.Icon')"
              :hint="$t('Settings.Email.Field.TestEmail.Email.Hint')"
              :label="$t('Settings.Email.Field.TestEmail.Email.Label')"
              autofocus
              name="input-order-comments"
              persistent-hint
            />
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn
              class="light-blue--text text--darken-1"
              outlined
              @click="cancelTestEmail"
            >
              {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
            <v-spacer />
            <v-btn
              class="primary--text"
              text
              @click="sendTestEmail"
            >
              {{ $t('Common.Button.Send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveEmailClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData from '../../mixins/appdata'
import Auth    from '../../mixins/auth'

export default {
  components: {},
  mixins    : [AppData, Auth],
  data () {
    return {
      isSaving               : false,
      emailFormValid         : false,
      tmpFormValid           : false,
      passwordVisible        : false,
      testEmailPopOverVisible: false,
      testEmail              : '',
      viewData               : null,
      tmpViewData            : null,
      encryptionList         : [
        {
          name : this.$t('Settings.Email.Field.Encryption.List.NoEncryption'),
          value: null
        },
        {
          name : this.$t('Settings.Email.Field.Encryption.List.SSLEncryption'),
          value: 'ssl'
        },
        {
          name : this.$t('Settings.Email.Field.Encryption.List.TSLEncryption'),
          value: 'tls'
        }
      ],
      validationRules: {
        Smtp: [
          (v) => v && v.length >= 2 && v.length <= 96 || this.$t('Settings.Email.Field.SMTPAddress.Error.Between')
        ],
        Port: [
          (v) => v && v.length >= 2 && v.length <= 6 && !isNaN(v) || this.$t('Settings.Email.Field.SMTPPort.Error.Between')
        ],
        Username: [
          (v) => v && v.length >= 2 && v.length <= 96 || this.$t('Settings.Email.Field.Username.Error.Between')
        ],
        Password: [
          (v) => v && v.length >= 5 && v.length <= 96 || this.$t('Settings.Email.Field.Password.Error.Between')
        ],
        Email: [
          (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Settings.Email.Field.TestEmail.Email.Error.Between'),
          (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Settings.Email.Field.TestEmail.Email.Error.Valid')
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Email.Get, this.onGetEmailResult)
    this.$bus.$on(window.SocketCommand.Settings.Email.Save, this.onSaveEmailResult)
    this.$bus.$on(window.SocketCommand.Settings.Email.Test, this.onTestEmailResult)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Email.Get, this.onGetEmailResult)
    this.$bus.$off(window.SocketCommand.Settings.Email.Save, this.onSaveEmailResult)
    this.$bus.$off(window.SocketCommand.Settings.Email.Test, this.onTestEmailResult)
  },
  methods: {
    getData () {
      window.callAS(window.SocketCommand.Settings.Email.Get, {})
    },
    onGetEmailResult (result) {
      this.viewData = result
      this.tmpViewData = result

      if (
        this.validationRules.Smtp[0](this.tmpViewData.host) === true &&
        this.validationRules.Port[0](this.tmpViewData.port) === true &&
        this.validationRules.Username[0](this.tmpViewData.username) === true &&
        this.validationRules.Password[0](this.tmpViewData.password) === true &&
        this.validationRules.Email[0](this.tmpViewData.from_email) === true &&
        this.validationRules.Email[1](this.tmpViewData.from_email) === true
      ) {
        this.tmpFormValid = true
      } else {
        this.tmpFormValid = false
      }
    },
    onSaveEmailClick () {
      this.$refs.emailForm.validate()
      if (this.emailFormValid) {
        this.isSaving = true
        if (this.viewData.from_address.trim() === '') {
          this.viewData.from_address = this.viewData.username
        }
        window.callAS(window.SocketCommand.Settings.Email.Save, { Email: this.viewData })
      }
    },
    onSaveEmailResult (result) {
      this.isSaving = false

      if (result.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Email.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    cancelTestEmail () {
      this.testEmailPopOverVisible = false
      this.testEmail = ''
    },
    sendTestEmail () {
      this.testEmailPopOverVisible = false
      const e = this.testEmail
      window.callAS(window.SocketCommand.Settings.Email.Test, { Email: e })
      this.testEmail = ''
    },
    onTestEmailResult (result) {
      if (result.status) {
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Email.Notification.Send'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
