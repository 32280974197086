<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Customer.CustomerAddEditDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Customer.CustomerAddEditDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Customer.CustomerAddEditDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerAddEditDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            v-if="editCustomer"
            ref="customerForm"
            v-model="customerFormValid"
            @submit.prevent
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-subheader class="white--text primary text-subtitle-1 ma-0">
                    <v-icon
                      class="pr-2"
                      dark
                    >
                      account_circle
                    </v-icon>
                    {{ $t('Customer.CustomerAddEditDialog.Field.Info.Title') }}
                  </v-subheader>
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editCustomer.Info.Firstname"
                    :counter="32"
                    :error-messages="errorMessages.customerForm.first_name"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Hint')"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Label')"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Icon')"
                    :rules="validationRules.customerForm.Firstname"
                    required
                    tabindex="1"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editCustomer.Info.Telephone"
                    v-mask="'################'"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Hint') + ' 6937XXXXXX'"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Label') + ' 1'"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Icon')"
                    :rules="validationRules.customerForm.Telephone"

                    required
                    tabindex="4"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editCustomer.Info.Lastname"
                    :counter="32"
                    :error-messages="errorMessages.customerForm.last_name"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Hint')"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Label')"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Icon')"
                    :rules="validationRules.customerForm.Lastname"
                    class=""
                    required
                    tabindex="2"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editCustomer.Info.Telephone2"
                    v-mask="'################'"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Hint') + ' 2106105200'"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Label') + ' 2'"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Icon')"
                    :rules="validationRules.customerForm.Telephone2"
                    tabindex="5"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editCustomer.Info.Email"
                    :counter="96"
                    :error-messages="errorMessages.customerForm.email"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Hint')"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Label')"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Email.Icon')"
                    :rules="validationRules.customerForm.Email"
                    class=""
                    tabindex="3"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editCustomer.Info.Telephone3"
                    v-mask="'################'"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Hint') + ' 2106105200'"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Label') + ' 3'"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Telephone.Icon')"
                    :rules="validationRules.customerForm.Telephone3"
                    tabindex="6"
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editCustomer.Info.Groups"
                    :hint="$t('Customer.CustomerAddEditDialog.Field.Info.Group.Hint')"
                    :items="editCustomer.CustomerGroups"
                    :label="$t('Customer.CustomerAddEditDialog.Field.Info.Group.Label')"
                    :prepend-icon="$t('Customer.CustomerAddEditDialog.Field.Info.Group.Icon')"
                    :rules="validationRules.customerForm.Groups"
                    chips
                    class=""
                    item-text="Name"
                    item-value="Id"
                    multiple
                    persistent-hint
                    required
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      <v-chip
                        :key="data.item.Id"
                        class="chip--select-multi"
                        color="indigo"
                        label
                        text-color="white"
                        @input="data.parent.selectItem(data.item)"
                      >
                        {{ data.item.Name }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editCustomer.Info.Notes"
                    :hint="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Hint')"
                    :label="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Label')"
                    :prepend-icon="$t('Customer.CustomerAddCompanyDialog.Field.Notes.Icon')"
                    hide-details="auto"
                    rows="3"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-subheader class="white--text primary text-subtitle-1 ma-0">
                    <v-icon
                      class="pr-2"
                      dark
                    >
                      notifications
                    </v-icon>
                    {{ $t('Customer.CustomerAddEditDialog.Field.Notifications.Title') }}
                  </v-subheader>
                </v-col>

                <v-col cols="12">
                  <v-subheader
                    class="pl-1"
                    style="height: 24px;"
                  >
                    {{ $t('Customer.CustomerAddEditDialog.Field.Notifications.Subtitle') }}
                  </v-subheader>

                  <div class="d-inline-flex">
                    <v-checkbox
                      v-model="editCustomer.Info.Newsletter"
                      :label="$t('Customer.CustomerAddEditDialog.Field.Notifications.Email.Label')"
                      class="mr-3"
                      color="success"
                      hide-details
                    />

                    <v-checkbox
                      v-model="editCustomer.Info.SMS"
                      :label="$t('Customer.CustomerAddEditDialog.Field.Notifications.Sms.Label')"
                      color="success"
                      class="mr-3"
                      hide-details
                    />

                    <v-checkbox
                      v-model="editCustomer.Info.Push"
                      :label="$t('Customer.CustomerAddEditDialog.Field.Notifications.Push.Label')"
                      color="success"
                      class="mr-3"
                      hide-details
                    />
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-subheader class="white--text primary text-subtitle-1">
                    <v-icon
                      class="pr-2"
                      dark
                    >
                      toggle_on
                    </v-icon>
                    {{ $t('Common.Misc.Status') }}
                  </v-subheader>
                </v-col>

                <v-col cols="12">
                  <span class="text-caption grey--text">
                    <v-switch
                      v-model="editCustomer.Info.Status"
                      inset
                      :label="editCustomer.Info.Status ? $t('Common.Button.Toggle.ActiveM') : $t('Common.Button.Toggle.InactiveM')"
                      hide-details
                      class="ma-0"
                    />
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { emailIsGuest } from '@/lib/helper/helper'

export default {
  components: {},
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    selectedCustomer: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading          : false,
      customerFormValid: false,
      editCustomer     : null,
      errorMessages    : {
        customerForm: {
          first_name: [],
          last_name : [],
          email     : []
        }
      },
      validationRules: {
        customerForm: {
          Firstname: [
            // (v) => !!v || 'Name is required',
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Firstname.Error.Between')
          ],
          Lastname: [
            // (v) => !!v || 'Name is required',
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Lastname.Error.Between')
          ],
          Telephone: [
            (v) => v && v.length >= 8 && v.length <= 16 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Telephone')
          ],
          Telephone2: [
            (v) => (v.length === 0 || (v.length >= 8 && v.length <= 16)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Telephone')
          ],
          Telephone3: [
            (v) => (v.length === 0 || (v.length >= 8 && v.length <= 16)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Telephone')
          ],
          Email: [
            (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Between'),
            (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Customer.CustomerAddEditDialog.Field.Info.Email.Error.Valid')
          ],
          Groups: [
            (v) => v.length > 0 || this.$t('Customer.CustomerAddEditDialog.Field.Info.Group.Error.Min')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$bus.$on(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        if (!this.selectedCustomer.Info.Telephone) this.selectedCustomer.Info.Telephone = ''
        if (!this.selectedCustomer.Info.Telephone2) this.selectedCustomer.Info.Telephone2 = ''
        if (!this.selectedCustomer.Info.Telephone3) this.selectedCustomer.Info.Telephone3 = ''

        this.editCustomer = JSON.parse(JSON.stringify(this.selectedCustomer))

        if (emailIsGuest(this.editCustomer.Info.Email)) {
          this.editCustomer.Info.EmailTmp = this.editCustomer.Info.Email
          this.editCustomer.Info.Email = ''
        }
      } else {
        this.$bus.$off(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        this.validationRules.customerForm.Telephone = this.validationRules.customerForm.Telephone2 = this.validationRules.customerForm.Telephone3 = []
        this.$refs.customerForm && this.$refs.customerForm.resetValidation()
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.customerForm.validate()

      if (this.customerFormValid) {
        this.clearApiErrorMessages()
        const customer = JSON.parse(JSON.stringify(this.editCustomer))
        if (customer.Info.hasOwnProperty('EmailTmp') && customer.Info.Email === '') customer.Info.Email = customer.Info.EmailTmp
        this.saveCustomer(customer)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    saveCustomer (customer) {
      this.loading = true

      const payload = {
        ...JSON.parse(JSON.stringify(customer.Info)),
        Addresses: JSON.parse(JSON.stringify(customer.Addresses || [])),
        Companies: JSON.parse(JSON.stringify(customer.Companies || []))
      }

      window.callAS(window.SocketCommand.Customer.Save, payload)
    },
    onCustomerSaveResult (result) {
      if (result.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'info',
          icon: 'info'
        })
        this.isVisible = false
      } else if (result.message) {
        this.$bus.$emit('app-show-notification', {
          body: result.message,
          type: 'error',
          icon: 'warning'
        })
      } else {
        // Set Error Messages from API to Fields
        let key
        for (key in result.result) {
          if (result.result.hasOwnProperty(key) && this.errorMessages.customerForm.hasOwnProperty(key)) {
            this.errorMessages.customerForm[key].push(result.result[key])
          }
        }

        if (result?.result?.email) {
          this.errorMessages.customerForm.email.push(result.result.email)
        }

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },
    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.clearApiErrorMessages()
    },
    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      let key
      for (key in this.errorMessages.customerForm) {
        if (this.errorMessages.customerForm.hasOwnProperty(key)) {
          this.errorMessages.customerForm[key] = []
        }
      }
    }

  }
}
</script>
