export default {
  Title: 'Dashboard',

  OrdersVsTurnover: {
    Title   : '@:Dashboard.OrdersVsTurnover.Orders vs. @:Dashboard.OrdersVsTurnover.Turnover',
    SubTitle: {
      Today : 'Στατιστικά ημέρας ανά ώρα',
      Week  : 'Στατιστικά εβδομάδας ανά ημέρα',
      Month : 'Στατιστικά μήνα ανά ημέρα',
      Year  : 'Στατιστικά έτους ανά μήνα',
      Custom: 'Στατιστικά περιόδου'
    },
    Orders     : 'Παραγγελίες',
    Turnover   : 'Τζίρος',
    NumOfOrders: 'Αρ. Παραγγελιών'
  },

  Top10: {
    Products: {
      Title   : 'Καλύτερα Προϊόντα | Καλύτερο Προϊόν | Καλύτερα Προϊόντα',
      SubTitle: 'Ανά Σύνολο Παραγγελιών',
      NoData  : {
        Title   : 'Δεν Υπάρχουν Προϊόντα',
        SubTitle: 'Επιλέξτε διαφορετική περίοδο'
      }
    },
    Customers: {
      Orders: {
        Title   : 'Καλύτεροι Πελάτες | Καλύτερος Πελάτης | Καλύτεροι Πελάτες',
        SubTitle: 'Ανά Σύνολο παραγγελιών',
        NoData  : {
          Title   : 'Δεν Υπάρχουν Πελάτες',
          SubTitle: 'Επιλέξτε διαφορετική περίοδο'
        }
      },
      Turnover: {
        Title   : 'Καλύτεροι Πελάτες | Καλύτερος Πελάτης | Καλύτεροι Πελάτες',
        SubTitle: 'Ανά Σύνολο Τζίρου',
        NoData  : {
          Title   : 'Δεν Υπάρχουν Πελάτες',
          SubTitle: 'Επιλέξτε διαφορετική περίοδο'
        }
      }
    }
  },

  Help: {
    SupportEmail: {
      Name   : 'Όνομα',
      Store  : 'Κατάστημα',
      Url    : 'E-Shop',
      Email  : 'Email',
      Message: 'Περιγραφή Μυνήματος'
    },
    SupportCenter: 'Κέντρο Υποστήριξης',
    Faq          : 'Κέντρο Βοηθείας',
    Wizard       : 'Οδηγός Γρήγορης Εγκατάστασης',
    WhatsNew     : 'Τι Nέο Υπάρχει;'
  }
}
