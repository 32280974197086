<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar color="indigo">
            <v-icon dark>
              {{ $t(`Restaurant.Tables.Dialog.MoveTable.Icon`) }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t(`Restaurant.Tables.Dialog.MoveTable.Title`) }}
            </div>

            <div class="text-caption grey--text">
              {{ $t(`Restaurant.Tables.Dialog.MoveTable.Subtitle`) }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-progress-linear
          v-if="loading"
          color="secondary"
          height="2"
          indeterminate
          class="pa-0 ma-0"
        />

        <v-divider />

        <v-card-text
          v-if="table && area"
          class="fill-height pa-3"
          style="min-height: 300px;"
        >
          <v-container
            v-if="viewData"
            class="pa-0"
            fluid
          >
            <v-row dense>
              <!-- Source Table -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid

                  :class="{'pr-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.MoveTable.SourceTable`) }}
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="ma-0 pa-0"
                        fluid
                      >
                        <v-row

                          style="min-height: 85px;"
                        >
                          <v-col cols="12">
                            <table-list-tile
                              :table="table"
                              :children-tables="table.ChildrenTables"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <!-- Target Table -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid

                  :class="{'pl-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.MoveTable.TargetTable`) }}
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="ma-0 pa-0"
                        fluid
                      >
                        <v-row

                          style="min-height: 85px;"
                        >
                          <v-col
                            v-if="targetTable"
                            cols="12"
                          >
                            <table-list-tile
                              :table="targetTable"
                              :children-tables="targetTable.ChildrenTables"
                              @click="onSelectTargetTable"
                            />
                          </v-col>

                          <v-col
                            v-else
                            cols="12"
                          >
                            <v-btn
                              outlined
                              color="grey"
                              block
                              class="ma-0"
                              style="height: 126px;"
                              @click="onSelectTargetTable"
                            >
                              {{ $t(`Restaurant.Tables.Dialog.MoveTable.SelectTable`).toLocaleUpperCase($i18n.locale) }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-divider class="my-2" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <!-- CART ORDERS -->
              <v-col cols="12">
                <table-completed-orders-list
                  v-model="selectedItems"
                  :view-data="viewData"
                  desc
                  unpaid
                  selectable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions
          v-if="!!tableCoupons.length"
          class="pa-0"
        >
          <v-alert
            type="warning"
            border="left"
            icon="warning"
            class="py-1 ma-0 text-caption"
            dense
            tile
            width="100%"
          >
            {{ $t('Restaurant.Tables.Notification.HasTableCouponMove') }}
          </v-alert>
        </v-card-actions>

        <v-divider />

        <v-card-actions class="px-4 py-2">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            :disabled="loadingMove || !targetTable || !selectedItems.length || !!tableCoupons.length"
            :loading="loadingMove"
            class="blue white--text elevation-0"
            @click="onMoveSelectedButtonClick"
          >
            {{ $t('Common.Button.MoveSelected') }}
          </v-btn>

          <v-btn
            :disabled="loadingMove || !targetTable"
            :loading="loadingMove"
            class="green white--text elevation-0"
            @click="onMoveAllButtonClick"
          >
            {{ $t('Common.Button.MoveAll') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      :html-content="$t('Restaurant.Tables.Dialog.ConfirmCloseSwift.Content')"
      :html-title="$t('Restaurant.Tables.Dialog.ConfirmCloseSwift.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Tables.Dialog.ConfirmCloseSwift.Notification')"
      :visible.sync="confirmCloseSwiftDialogVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-yes-close-swift"
      no-button-event="on-confirm-no-close-swift"
      cancel-button
      cancel-button-class="v-btn--outline light-blue--text"
      cancel-button-event="on-confirm-cancel-close-swift"
      @on-confirm-yes-close-swift="confirmYesCloseSwift"
      @on-confirm-no-close-swift="confirmNoCloseSwift"
      @on-confirm-cancel-close-swift="confirmCancelCloseSwift"
    />
  </v-row>
</template>

<script>
import TableListTile            from '@/components/restaurant/tables/tables/TableListTile'
import ConfirmDialog            from '@/components/common/ConfirmDialog'
import TableCompletedOrdersList from '@/components/restaurant/tables/orders/TableCompletedOrdersList'
import CartData                 from '@/mixins/pos/cartData'
import Auth                     from '@/mixins/auth'
import TableCommon              from '@/mixins/tables/tableCommon'
import TableStatus              from '@/mixins/tables/tableStatus'
import CompletedData            from '@/mixins/tables/completedData'
import PinManagerEnum           from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'TableOrderItemsMoveDialog',
  components: {
    TableCompletedOrdersList,
    ConfirmDialog,
    TableListTile
  },
  mixins: [Auth, CartData, TableCommon, TableStatus, CompletedData],
  props : {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      loading                       : false,
      loadingMove                   : false,
      confirmCloseSwiftDialogVisible: false,
      selectedItems                 : [],
      targetTable                   : null,
      viewData                      : null,
      requestData                   : null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$bus.$on('selected:table', this.onSelectedTargetTable)
        this.$bus.$on(window.SocketCommand.Table.Show, this.setData)
        this.$bus.$on(window.SocketCommand.Table.MoveOrderItems, this.onMoveResponse)
        this.getData()
      } else {
        this.$bus.$off('selected:table', this.onSelectedTargetTable)
        this.$bus.$off(window.SocketCommand.Table.Show, this.setData)
        this.$bus.$off(window.SocketCommand.Table.MoveOrderItems, this.onMoveResponse)
        this.viewData = null
        this.requestData = null
        this.targetTable = null
        this.selectedItems = []
      }
    }
  },
  methods: {
    totalUniqueItems () {
      let totalItems = 0
      const carts = this.viewData?.Completed || []

      carts.forEach(cart => {
        const items = cart?.Items || []
        items.forEach(item => {
          const paidItems = item.Quantity - item.PaymentQuantity
          const quantity = item.Quantity - paidItems

          if (quantity > 0) {
            totalItems = totalItems + quantity
          }
        })
      })

      return totalItems
    },

    getData () {
      this.loading = true
      window.callAS(window.SocketCommand.Table.Show, { TableId: this.table.Id })
    },
    setData (data) {
      this.viewData = data.data || null
      this.loading = false
    },

    onSelectTargetTable () {
      this.$bus.$emit('click:table-select-button', this.table)
    },

    onSelectedTargetTable (table) {
      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.ChangeTableWaiter) && !this.tableIsOwn(table) && !this.tableIsAvailable(table), () => {
        this.targetTable = table
      }, 'select-target-table-move-order-items')
    },

    onMoveSelectedButtonClick () {
      if (!this.table || !this.targetTable || !this.selectedItems.length) return
      this.loadingMove = true

      const items = this.selectedItems.map(menuItem => {
        return {
          RowId   : menuItem.RowId,
          Quantity: menuItem.Quantity
        }
      })

      const allItemsSelected = this.totalUniqueItems() === this.selectedItems.length
      this.requestData = {
        Whole        : allItemsSelected,
        Close        : false,
        SourceTableId: this.table.Id,
        TargetTableId: this.targetTable.Id,
        Items        : allItemsSelected ? [] : items
      }

      if (allItemsSelected) {
        this.confirmCloseSwiftDialogVisible = true
      } else {
        window.callAS(window.SocketCommand.Table.MoveOrderItems, this.requestData)
      }
    },

    onMoveAllButtonClick () {
      if (!this.table || !this.targetTable) return
      this.loadingMove = true

      this.requestData = {
        Whole        : true,
        Close        : false,
        SourceTableId: this.table.Id,
        TargetTableId: this.targetTable.Id,
        Items        : []
      }
      this.confirmCloseSwiftDialogVisible = true
    },

    confirmYesCloseSwift () {
      this.requestData.Close = true
      window.callAS(window.SocketCommand.Table.MoveOrderItems, this.requestData)
    },

    confirmNoCloseSwift () {
      this.requestData.Close = false
      window.callAS(window.SocketCommand.Table.MoveOrderItems, this.requestData)
    },

    confirmCancelCloseSwift () {
      this.loadingMove = false
    },

    onMoveResponse (data) {
      if (data.status === 'success') {
        this.$emit('save-success')
        this.onCancelButtonClick()
      } else {
        this.loading = false
        this.loadingMove = false
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.loadingMove = false
      this.viewData = null
      this.requestData = null
      this.targetTable = null
      this.selectedItems = []
    }
  }
}
</script>

<style scoped>

</style>
