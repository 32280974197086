<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="350"
      persistent
      scrollable
    >
      <v-card v-if="table && area">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            v-if="isTableAvailable"
            :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'"
          >
            <v-icon dark>
              {{ getTableStatusIcon(table) }}
            </v-icon>
          </v-avatar>

          <v-badge
            v-else
            :color="`${getTableStatusColor(table, true)} lighten-2`"
            overlap
          >
            <template #badge>
              <span>{{ table.Persons }}</span>
            </template>
            <v-avatar :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'">
              <v-icon dark>
                group
              </v-icon>
            </v-avatar>
          </v-badge>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ area.Name }} #{{ table.Name }}
            </div>

            <div
              v-if="table.StaffId && table.StaffName"
              class="text-caption grey--text"
            >
              {{ table.StaffName }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="fill-height pa-3"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <!-- Pay Table -->
              <v-col cols="12">
                <v-btn
                  :disabled="!table.CartsCount || table.CartsCount < 1"
                  depressed
                  large
                  block
                  class="white--text"
                  color="green"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onPayTableButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.PaymentMenu.PayTable.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.PaymentMenu.PayTable.Label').toLocaleUpperCase($i18n.locale) }} ({{ table.OrderTotal | currency }})
                </v-btn>
              </v-col>

              <!-- Pay Order(s) -->
              <v-col cols="12">
                <v-btn
                  :disabled="!table.CartsCount || table.CartsCount < 1"
                  depressed
                  large
                  block
                  class="white--text"
                  color="blue"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onPayOrderButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.PaymentMenu.PayOrder.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.PaymentMenu.PayOrder.Label').toLocaleUpperCase($i18n.locale) }} ({{ table.CartsCount }})
                </v-btn>
              </v-col>

              <!-- Pay Menu Item(s) -->
              <v-col cols="12">
                <v-btn
                  :disabled="!table.TotalItems || table.TotalItems < 1"
                  depressed
                  large
                  block
                  class="white--text"
                  color="indigo"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onPayMenuItemsButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.PaymentMenu.PayItems.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.PaymentMenu.PayItems.Label').toLocaleUpperCase($i18n.locale) }} ({{ table.TotalItems }})
                </v-btn>
              </v-col>

              <!-- Set Awaiting Payment Status -->
              <v-col cols="12">
                <v-btn
                  v-if="table.StatusId === $t('Restaurant.Tables.TableStatus.AwaitingPayment').Id"
                  :disabled="!table.TotalItems || table.TotalItems < 1 || !table.HasItemsUnpaid"
                  depressed
                  large
                  block
                  class="white--text"
                  color="brown"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onUnsetAwaitingPaymentStatusButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.PaymentMenu.AwaitingPayment.Unset.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.PaymentMenu.AwaitingPayment.Unset.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>

                <v-btn
                  v-else
                  :disabled="!table.TotalItems || table.TotalItems < 1 || !table.HasItemsUnpaid"
                  depressed
                  large
                  block
                  class="white--text"
                  color="brown"
                  style="height: 55px; font-size: 16px; line-height: 24px;"
                  @click="onSetAwaitingPaymentStatusButtonClick"
                >
                  <v-icon left>
                    {{ $t('Restaurant.Tables.Dialog.PaymentMenu.AwaitingPayment.Set.Icon') }}
                  </v-icon>
                  {{ $t('Restaurant.Tables.Dialog.PaymentMenu.AwaitingPayment.Set.Label').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TableStatus  from '@/mixins/tables/tableStatus'
import TableCommon  from '@/mixins/tables/tableCommon'
import TableActions from '@/mixins/tables/tableActions'

export default {
  name      : 'TablePaymentMenuDialog',
  components: {},
  mixins    : [TableStatus, TableCommon, TableActions],
  props     : {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch  : {},
  methods: {
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
