<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.Options.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showOptionAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.Options.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Options.Toolbar.Button.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ACCESS')"
          :color="$t('Restaurant.Categories.Toolbar.Button.Products.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          :to="{name: 'KitchenMenu'}"
        >
          <v-icon
            v-if="$t('Restaurant.Categories.Toolbar.Button.Products.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Categories.Toolbar.Button.Products.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Categories.Toolbar.Button.Products.Title').toLocaleUpperCase($i18n.locale) }}</span>
        </v-btn>

        <!--
        <v-btn
          v-if="selectedLanguages.length > 1 && userIsSuperExtraAdmin"
          :disabled="dataTable.loading"
          :loading="dataTable.loading"
          :loading-text="dataTable.text.loadingText"
          :no-data-text="dataTable.text.noDataText"
          :no-results-text="dataTable.text.noResultsText"
          class="purple white--text elevation-0 ma-1"
          @click="translateAllItems()"
        >
          {{ $t('Common.Button.Translate').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        -->

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.option_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.option_name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showOptionEditDialog(item)"
                >
                  {{ getTranslatedField(item, 'Name') }}
                </a>
              </template>
              <template v-else>
                {{ getTranslatedField(item, 'Name') }}
              </template>
            </template>

            <template #[`item.display_type`]="{item}">
              <span v-if="item.DisplayType ==='checkbox'">
                <v-icon>{{ $t('Restaurant.Options.ListView.ChoiceType.Multiple.Icon') }}</v-icon>
                {{ getTranslatedField(viewData.types.find(t => t.Id === item.DisplayType), 'Name') }}
              </span>
              <span v-else-if="item.DisplayType === 'radio'">
                <v-icon>{{ $t('Restaurant.Options.ListView.ChoiceType.Single.Icon') }}</v-icon>
                {{ getTranslatedField(viewData.types.find(t => t.Id === item.DisplayType), 'Name') }}
              </span>
              <span v-else-if="item.DisplayType === 'unit'">
                <v-icon>{{ $t('Restaurant.Options.ListView.ChoiceType.Unit.Icon') }}</v-icon>
                {{ getTranslatedField(viewData.types.find(t => t.Id === item.DisplayType), 'Name') }}
              </span>
              <span v-else-if="item.DisplayType === 'unit_value'">
                <v-icon>{{ $t('Restaurant.Options.ListView.ChoiceType.UnitValue.Icon') }}</v-icon>
                {{ getTranslatedField(viewData.types.find(t => t.Id === item.DisplayType), 'Name') }}
              </span>
              <span v-else-if="item.DisplayType === 'extra_charges'">
                <v-icon>{{ $t('Restaurant.Options.ListView.ChoiceType.ExtraCharges.Icon') }}</v-icon>
                {{ getTranslatedField(viewData.types.find(t => t.Id === item.DisplayType), 'Name') }}
              </span>
              <span v-else-if="item.DisplayType === 'label'">
                <v-icon>{{ $t('Restaurant.Options.ListView.ChoiceType.Label.Icon') }}</v-icon>
                {{ getTranslatedField(viewData.types.find(t => t.Id === item.DisplayType), 'Name') }}
              </span>
              <span v-else-if="item.DisplayType === 'separator'">
                <v-icon>{{ $t('Restaurant.Options.ListView.ChoiceType.Separator.Icon') }}</v-icon>
                {{ getTranslatedField(viewData.types.find(t => t.Id === item.DisplayType), 'Name') }}
              </span>
              <template v-else>
                {{ item.DisplayType }}
              </template>
            </template>

            <template #[`item.languages`]="{item}">
              <template v-for="lang in selectedLanguages">
                <div
                  :key="lang.Code"
                  class="mx-1 d-inline-block"
                >
                  <v-badge
                    :value="hasMissingLanguages(item, lang)"
                    color="orange"
                    overlap
                    right
                  >
                    <template #badge>
                      <v-icon
                        dark
                        small
                      >
                        priority_high
                      </v-icon>
                    </template>

                    <v-avatar
                      v-if="lang.ImageUrl"
                      size="20"
                    >
                      <img
                        :class="[{'grayscale opacity': hasMissingLanguages(item, lang)}]"
                        :src="lang.ImageUrl"
                      >
                    </v-avatar>

                    <div v-else>
                      {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                    </div>
                  </v-badge>
                </div>
              </template>
            </template>

            <template #[`item.priority`]="{item}">
              {{ item.Priority }}
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanManage"
                class="cyan elevation-2 ma-0"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showOptionEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanDelete"
                class="red elevation-2 ma-1"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="deleteMenuOption(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <option-add-dialog
      :option-to-edit="selectedItem"
      :option-types="viewData.types"
      :visible.sync="addEditOptionDialog"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Options.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.Options.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Options.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>
import { API, APICall } from '@/api/Api'
import AppData          from '@/mixins/appdata'
import Auth             from '@/mixins/auth'
import Translatable     from '@/mixins/translatable'
import DataTable        from '@/mixins/data-table/dataTable'
import ConfirmDialog    from '@/components/common/ConfirmDialog'
import FilterSearch     from '@/components/common/filter/FilterSearch.vue'
import OptionAddDialog  from './OptionAddDialog.vue'
import googleTranslate  from '@/lib/google-translate/googleTranslate'

export default {
  components: {
    FilterSearch,
    'option-add-dialog': OptionAddDialog,
    'confirm-dialog'   : ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, DataTable],
  data () {
    return {
      confirmDialogDeleteVisible: false,
      selectedItem              : null,
      addEditOptionDialog       : false,
      dataTable                 : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Menu.Options.All,
          save  : window.SocketCommand.Menu.Options.Save,
          delete: window.SocketCommand.Menu.Options.Delete
        },
        options: {
          defaultSortBy: 'option_id',
          sortBy       : ['option_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Restaurant.Options.Table.Headers.Id'),
            value    : 'option_id',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Options.Table.Headers.Name'),
            value    : 'option_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Options.Table.Headers.DisplayType'),
            value    : 'display_type',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Options.Table.Headers.Languages'),
            value    : 'languages',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Options.Table.Headers.Priority'),
            value    : 'priority',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Options.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 100
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    setData (data) {
      this.$set(this.viewData, 'types', data.types || [])

      if (data?.items) {
        for (let i = 0; i < data.items.length; i++) {
          for (let ii = 0; ii < data.items[i].OptionValues.length; ii++) {
            if (data.items[i].DisplayType === 'unit') {
              data.items[i].OptionValues[ii].Price = parseInt(data.items[i].OptionValues[ii].Price)
            } else {
              data.items[i].OptionValues[ii].Price = parseFloat(data.items[i].OptionValues[ii].Price).toFixed(2)
            }
          }
        }
      }

      this.setDataInternal(data)
    },

    hasMissingLanguages (item, lang) {
      const retVal = !item.Lang[lang.Code] || !item.Lang[lang.Code].Name

      if (Array.isArray(item.OptionValues)) {
        for (let i = 0; i < item.OptionValues.length; i++) {
          const optionValue = item.OptionValues[i]
          if (!optionValue.Lang[lang.Code] || !optionValue.Lang[lang.Code].Name) {
            return true
          }
        }
      }

      return retVal
    },

    showOptionAddDialog () {
      this.selectedItem = null
      this.addEditOptionDialog = true
    },

    showOptionEditDialog (menuOption) {
      this.selectedItem = menuOption
      this.addEditOptionDialog = true
    },

    deleteMenuOption (menuOption) {
      this.selectedItem = menuOption
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Menu.Options.Delete, { menu_option_id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.addEditCategoryDialog = false
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Options.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    async translateAllItems (localeFrom = this.defaultLanguage.Code, localeTo) {
      if (this.selectedLanguages.length <= 0 || !this.userIsSuperExtraAdmin) return
      this.dataTable.loading = true
      const langs = localeTo ? [{ Code: localeTo }] : this.selectedLanguages.filter(lang => lang.Code !== localeFrom) || []

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].Code

        const payload = APICall.CreatePayload(window.SocketCommand.Menu.Options.All, {
          config       : 0,
          page         : 1,
          page_limit   : -1,
          sort_by      : 'option_id',
          order_by     : 'DESC',
          filter_search: ''
        })
        // eslint-disable-next-line camelcase
        const items_result = await API.post(APICall.api, payload)

        const lngArrName = []
        for (let i = 0; i < items_result.data.length; i++) {
          const item = items_result.data[i]
          lngArrName.push(item.Lang[localeFrom].Name || '')
        }

        const resultName = await googleTranslate(lngArrName, localeTo, localeFrom)

        if (resultName) {
          for (let i = 0; i < items_result.data.length; i++) {
            const item = items_result.data[i]
            const langObj = {}
            for (const lang of this.selectedLanguages) {
              langObj[lang.Code] = {
                Name  : '',
                Locale: lang.Code
              }
            }
            item.Lang = Object.assign({}, langObj, item.Lang)
            // eslint-disable-next-line no-return-assign
            item.OptionValues.map(optionValue => optionValue.Lang = Object.assign({}, langObj, optionValue.Lang))
            item.OptionValues = JSON.parse(JSON.stringify(item.OptionValues))
            item.Lang[localeTo].Name = Array.isArray(resultName) ? resultName[i].translatedText : resultName.translatedText

            for (let lng = 0; lng < langs.length; lng++) {
              localeTo = langs[lng].Code
              const trs = []
              for (let opt = 0; opt < item.OptionValues.length; opt++) {
                trs.push(item.OptionValues[opt].Lang[localeFrom].Name || '')
              }

              const result = await googleTranslate(trs, localeTo, localeFrom)

              if (result) {
                for (let opt = 0; opt < item.OptionValues.length; opt++) {
                  item.OptionValues[opt].Lang[localeTo].Name = Array.isArray(result) ? result[opt].translatedText : result.translatedText
                }
              }
            }

            await API.post(APICall.api, APICall.CreatePayload(window.SocketCommand.Menu.Options.Save, { option: item }))
          }
        }
      }
      this.getData()
      this.dataTable.loading = false
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}
</style>
