<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="$vuetify.breakpoint.xsOnly">
          <v-navigation-drawer
            v-model="cartDrawer"
            fixed
            right
            temporary
            width="600"
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row no-gutters>
                <!-- Toolbar -->
                <v-col
                  id="cartToolbar"
                  cols="12"
                >
                  <v-toolbar flat>
                    <v-badge
                      v-model="showCartItemsBadge"
                      color="green"
                      top
                      right
                      overlap
                    >
                      <template #badge>
                        {{ posCart.TotalItems }}
                      </template>

                      <v-icon>{{ $t('Pos.Pos.Cart.Icon') }}</v-icon>
                    </v-badge>

                    <v-toolbar-title class="pl-4">
                      <div class="text-h6 grey--text text--darken-1">
                        {{ $t('Pos.Pos.Cart.Title') }}
                      </div>
                    </v-toolbar-title>

                    <v-spacer />

                    <v-btn
                      icon
                      @click="cartDrawer = !cartDrawer"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-divider />

                  <pos-toolbar-customer
                    class="pt-0"
                    :drawer.sync="cartDrawer"
                  />
                </v-col>

                <!-- Cart -->
                <v-col
                  id="cart"
                  class="px-1"
                  style="overflow-y: auto;"
                  cols="12"
                >
                  <div
                    v-if="posCart.Items.length <= 0"
                    class="fill-height"
                    style="position: relative;"
                  >
                    <div
                      class="text-center grey--text pa-6"
                      style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                    >
                      <v-icon
                        class="mb-4"
                        color="grey lighten-2"
                        size="128"
                      >
                        {{ $t('Pos.Pos.Cart.Icon') }}
                      </v-icon>
                      <div class="text-h6 mb-2">
                        {{ $t('Pos.Pos.Cart.NoData.Title') }}
                      </div>
                      <div class="text-subtitle-1">
                        {{ $t('Pos.Pos.Cart.NoData.Subtitle') }}
                      </div>
                    </div>
                  </div>

                  <template v-else>
                    <template v-for="tableSeat in posCart.ItemsByTableSeat">
                      <v-subheader
                        v-if="(posCart.ItemsByTableSeat.length && posCart.ItemsByTableSeat[0].Seat !== 0) || posCart.ItemsByTableSeat.length > 1"
                        :key="`table-seat-${tableSeat.Seat}`"
                        class="font-weight-bold pl-1 blue-grey lighten-4"
                        style="height: 26px;"
                      >
                        {{ tableSeat.Name }}
                      </v-subheader>

                      <template v-for="category in tableSeat.Items">
                        <v-subheader
                          :key="`table-seat-${tableSeat.Seat}-category-${category.CategoryId}`"
                          class=""
                          style="height: 26px;"
                        >
                          {{ getTranslatedField(getCategory(category.CategoryId), 'Name') }}
                        </v-subheader>

                        <template v-for="(menu) in category.Items">
                          <v-list
                            :key="menu.RowId"
                            class="mb-1 pb-0 pt-0"
                            dense
                          >
                            <v-list-item
                              :disabled="menu.Loading"
                              :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']"
                              dense
                              style="height: 76px;"
                              ripple
                              @click="showEditMenuOptionsDialog(menu)"
                            >
                              <v-list-item-content>
                                <span
                                  :style="'background-color: #' + (getCategoryColor(category.CategoryId) || '333333') + ';'"
                                  style="position: absolute; width:5px; height: 100%; margin-left: -16px; padding: 0; margin-right: 16px;"
                                />

                                <v-list-item-title class="text-subtitle-1 font-weight-regular mb-2">
                                  <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                    <strong class="orange--text">{{ formatUnitQuantity($i18n.locale, menu.UnitQuantity, getMeasurementUnitById(menu.UnitId)) }}</strong>
                                  </template>
                                  <template v-else>
                                    <strong class="orange--text">
                                      {{ menu.Quantity }} x
                                    </strong>
                                  </template>
                                  {{ getTranslatedField(menu, 'Name') }}
                                </v-list-item-title>

                                <v-list-item-subtitle class="font-weight-regular">
                                  <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                    <span class="old-price">
                                      {{ menu.MenuPrice | currency }}
                                    </span> {{ menu.SpecialPrice | currency }}
                                  </template>
                                  <template v-else>
                                    <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                      {{ getMenuItemUnitPriceWithOptions(menu) | currency }}
                                      <span class="grey--text text-caption">/ {{ getMeasurementUnitById(menu.UnitId).BaseUnit }}</span>
                                    </template>
                                    <template v-else>
                                      {{ menu.Price | currency }}
                                    </template>
                                  </template>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle class="pb-1 text-caption font-weight-regular">
                                  {{ getMenuSelectedOptionsString(menu) }}&nbsp;
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action
                                :style="menu.Custom && (parseInt(menu.Custom.Seat) || parseInt(menu.Custom.Order)) ? 'min-width: 100px;' : 'min-width: 65px;'"
                                class="ma-0"
                              >
                                <v-list-item-action-text class="text-subtitle-1">
                                  <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                    <span class="old-price">
                                      {{ parseFloat(parseFloat(menu.MenuPrice) * menu.Quantity) | currency }}
                                    </span>
                                    {{ parseFloat(parseFloat(menu.SpecialPrice) * menu.Quantity) | currency }}
                                  </template>
                                  <template v-else>
                                    {{ parseFloat(parseFloat(menu.Price) * menu.Quantity) | currency }}
                                  </template>
                                </v-list-item-action-text>

                                <div>
                                  <v-btn
                                    v-if="menu.Custom && parseInt(menu.Custom.Order)"
                                    class="d-inline-block px-1"
                                    color="pink lighten-1"
                                    text
                                    ripple
                                    small
                                    style="min-width: 0;"
                                  >
                                    <v-icon size="22">
                                      low_priority
                                    </v-icon>

                                    <span style="position: relative; top: -4px;">{{ menu.Custom.Order }}</span>
                                  </v-btn>

                                  <v-btn
                                    v-if="menu.Custom && parseInt(menu.Custom.Seat)"
                                    class="d-inline-block px-1"
                                    color="brown lighten-1"
                                    text
                                    ripple
                                    small
                                    style="min-width: 0;"
                                  >
                                    <v-icon size="22">
                                      event_seat
                                    </v-icon>

                                    <span style="position: relative; top: -4px;">{{ menu.Custom.Seat }}</span>
                                  </v-btn>

                                  <v-btn
                                    v-if="isCouponPerMenuItemVisible(menu)"
                                    :class="couponExistsForMenuItem(menu) ? 'purple--text' : 'green--text'"
                                    :disabled="menu.Loading || (cartTotalWithoutSpecialAndExcludedItems <= 0 && !couponExistsForMenuItem(menu))"
                                    :loading="menu.LoadingCoupon"
                                    class="d-inline-block"
                                    icon
                                    ripple
                                    @click.native.stop="toggleCouponForMenuItem(menu)"
                                  >
                                    <v-icon size="22">
                                      redeem
                                    </v-icon>
                                  </v-btn>

                                  <v-btn
                                    :disabled="menu.Loading"
                                    :loading="menu.LoadingRemove"
                                    class="red--text d-inline-block"
                                    icon
                                    ripple
                                    @click.native.stop="onRemoveCartMenuItem(menu)"
                                  >
                                    <v-icon size="22">
                                      close
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider />
                          </v-list>
                        </template>
                      </template>
                    </template>
                  </template>
                </v-col>

                <!-- Totals -->
                <v-col
                  id="cartTotals"
                  class=""
                  cols="12"
                >
                  <v-container
                    fluid
                    class="pa-1 pb-0 text-body-2"
                  >
                    <v-row no-gutters>
                      <!-- Sub Total -->
                      <v-col
                        class="grey--text text--darken-1 "
                        cols="12"
                      >
                        <v-divider />

                        <div class="pa-1">
                          <span class="grey--text text--darken-1">
                            {{ $t('Common.Misc.Subtotal').toLocaleUpperCase($i18n.locale) }}
                          </span>
                          <span class="grey--text text--darken-1 float-right pr-2">
                            {{ posCart.SCartTotal | currency }}
                          </span>
                        </div>

                        <v-divider />
                      </v-col>

                      <!-- Extra Charges -->
                      <v-col
                        v-if="!isTableOrder && extraCharges.length > 0"
                        class="grey--text text--darken-1"
                        cols="12"
                      >
                        <div
                          style="float: none; clear: both; line-height: 28px;"
                          class="pa-0 px-1"
                        >
                          <div class="">
                            <span class="grey--text text--darken-1">
                              {{ $t('Common.Misc.ExtraCharges').toLocaleUpperCase($i18n.locale) }}
                            </span>
                            <span
                              class="grey--text text--darken-1 float-right"
                            >
                              <v-btn
                                :disabled="(posCart && parseFloat(posCart.SCartTotal) <= 0) || loadingAddExtraCharge || !extraChargesAvailable"
                                :loading="loadingAddExtraCharge"
                                class="pa-0 ma-0 green--text"
                                icon
                                ripple
                                small
                                @click.native.stop="showSelectExtraChargeDialog()"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                            </span>
                          </div>
                          <v-divider />
                        </div>

                        <template v-if="cartHasExtraCharge">
                          <div class="divTable">
                            <template v-for="(total, index) in posCart.Totals">
                              <div
                                v-if="total.Name === 'extra_charge'"
                                :key="index"
                                class="divTableRow"
                              >
                                <div
                                  class="divTableCell text-left pl-1"
                                  style="width: 28px;"
                                >
                                  <v-btn
                                    :disabled="total.Loading || !canRemoveExtraCharges(total)"
                                    :loading="total.Loading"
                                    class="pa-0 ma-0 red--text"
                                    icon
                                    ripple
                                    small
                                    @click.native.stop="onRemoveExtraChargeItem(total)"
                                  >
                                    <v-icon size="20">
                                      close
                                    </v-icon>
                                  </v-btn>
                                </div>

                                <div
                                  class="divTableCell text-left pl-0"
                                  style="width: 100%;"
                                >
                                  <span class="grey--text text--darken-1">
                                    {{ total.Title }}
                                  </span>
                                </div>

                                <div
                                  class="divTableCell text-right text-no-wrap"
                                  style="min-width: 60px;"
                                >
                                  <span class="grey--text text--darken-1 px-1">
                                    +{{ total.AmountFormatted | currency }}
                                  </span>
                                </div>
                              </div>
                            </template>
                          </div>
                          <v-divider />
                        </template>
                      </v-col>

                      <!-- Tips -->
                      <v-col
                        v-if="appConfig.LOCATION_DATA.OrderTips && !isTableOrder"
                        class="grey--text text--darken-1"
                        cols="12"
                      >
                        <div
                          v-if="!cartHasTip"
                          class="pa-0 px-1"
                          style="float: none; clear: both; line-height: 28px;"
                        >
                          <span class="grey--text text--darken-1">
                            {{ $t('Common.Misc.Tip').toLocaleUpperCase($i18n.locale) }}
                          </span>

                          <span
                            v-if="!cartHasTip"
                            class="grey--text text--darken-1 float-right"
                          >
                            <v-btn
                              :disabled="(posCart && parseFloat(posCart.SCartTotal) <= 0) || loadingAddTip"
                              :loading="loadingAddTip"
                              class="pa-0 ma-0 green--text"
                              icon
                              ripple
                              small
                              @click.native.stop="showSelectTipDialog()"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </span>
                        </div>

                        <template v-if="cartHasTip">
                          <template v-for="(tip, index) in posCart.Totals">
                            <div
                              v-if="tip.Name === 'tip_amount'"
                              :key="index"
                              class="pa-0 px-1"
                              style="float: none; clear: both; line-height: 28px;"
                            >
                              <v-btn
                                :disabled="loadingRemoveTip"
                                :loading="loadingRemoveTip"
                                class="pa-0 ma-0 red--text"
                                icon
                                ripple
                                small
                                @click.native.stop="onRemoveTipItem(tip)"
                              >
                                <v-icon size="20">
                                  close
                                </v-icon>
                              </v-btn>
                              <span class="grey--text text--darken-1">
                                {{ tip.Title }}
                              </span>
                              <span class="grey--text text--darken-1 float-right pr-2">
                                +{{ tip.AmountFormatted | currency }}
                              </span>
                            </div>
                          </template>
                        </template>
                        <v-divider />
                      </v-col>

                      <!-- Coupons -->
                      <v-col
                        class="grey--text text--darken-1"
                        cols="12"
                      >
                        <div
                          v-if="cartCouponsActive"
                          style="float: none; clear: both; line-height: 28px;"
                          class="pa-0 px-1"
                        >
                          <div class="">
                            <span class="grey--text text--darken-1">
                              {{ $t('Common.Misc.Discount').toLocaleUpperCase($i18n.locale) }}
                            </span>
                            <span
                              class="grey--text text--darken-1 float-right"
                            >
                              <v-btn
                                :disabled="loadingAddCoupon || cartTotalWithoutSpecialAndExcludedItems <= 0"
                                :loading="loadingAddCoupon"
                                class="pa-0 ma-0 green--text"
                                icon
                                ripple
                                small
                                @click.native.stop="showSelectCouponDialog()"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                            </span>
                          </div>
                          <v-divider />
                        </div>

                        <template v-if="cartHasCoupon">
                          <div class="divTable">
                            <template v-for="(total, index) in posCart.Totals">
                              <div
                                v-if="total.Name === 'coupon'"
                                :key="index"
                                class="divTableRow"
                              >
                                <div
                                  class="divTableCell text-left pl-1"
                                  style="width: 28px;"
                                >
                                  <v-btn
                                    :disabled="total.Loading"
                                    :loading="total.Loading"
                                    class="pa-0 ma-0 red--text"
                                    icon
                                    ripple
                                    small
                                    @click.native.stop="onRemoveCouponItem(total)"
                                  >
                                    <v-icon size="20">
                                      close
                                    </v-icon>
                                  </v-btn>
                                </div>

                                <div
                                  class="divTableCell text-left pl-0"
                                  style="width: 100%;"
                                >
                                  <span class="grey--text text--darken-1">
                                    {{ total.Title }}
                                  </span>
                                </div>

                                <div
                                  class="divTableCell text-right text-no-wrap"
                                  style="min-width: 60px;"
                                >
                                  <span class="grey--text text--darken-1 px-1">
                                    -{{ total.AmountFormatted | currency }}
                                  </span>
                                </div>
                              </div>
                            </template>
                          </div>
                          <v-divider />
                        </template>
                      </v-col>

                      <!-- Delivery -->
                      <template v-for="(total, index) in posCart.Totals">
                        <v-col
                          v-if="total.Name === 'delivery'"
                          :key="`totals-delivery-${index}`"
                          class="grey--text text--darken-1"
                          cols="12"
                        >
                          <div class="pa-1">
                            <v-btn
                              class="pa-0 ma-0 red--text"
                              icon
                              ripple
                              small
                              @click.native.stop="removeDeliveryAreaCharges"
                            >
                              <v-icon size="20">
                                close
                              </v-icon>
                            </v-btn>
                            <span class="grey--text text--darken-1">
                              {{ $t('Order.Type.Delivery.Title').toLocaleUpperCase($i18n.locale) }}
                            </span>
                            <span class="grey--text text--darken-1 float-right pr-2">
                              {{ total.AmountFormatted | currency }}
                            </span>
                          </div>
                          <v-divider />
                        </v-col>
                      </template>

                      <!-- Order Totals -->
                      <template v-if="posCart.Totals.length === 0">
                        <v-col
                          class="grey--text text--darken-1"
                          cols="12"
                        >
                          <div class="pa-1">
                            <strong>
                              <span class="grey--text text--darken-1">
                                {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                              </span>
                              <span class="grey--text text--darken-1 float-right pr-2">
                                {{ 0 | currency }}
                              </span>
                            </strong>
                          </div>
                        </v-col>
                      </template>

                      <template v-else>
                        <template v-for="(total, index) in posCart.Totals">
                          <v-col
                            v-if="total.Name === 'order_total'"
                            :key="`totals-ordertotal-${index}`"
                            class="grey--text text--darken-1"
                            cols="12"
                          >
                            <div class="pa-1">
                              <strong>
                                <span class="grey--text text--darken-1">
                                  {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                                </span>
                                <span class="grey--text text--darken-1 float-right pr-2">
                                  {{ total.AmountFormatted | currency }}
                                </span>
                              </strong>
                            </div>
                          </v-col>
                        </template>
                      </template>

                      <!-- Order Type Time -->
                      <v-col
                        v-if="!isTableOrder"
                        class="grey--text text--darken-1"
                        cols="12"
                      >
                        <v-divider />

                        <div class="my-1">
                          <v-btn
                            :color="posCart.OrderType===1 ? 'purple' : 'blue darken-3'"
                            block
                            class="text-caption ma-0 pa-0"
                            dark
                            depressed
                            small
                            @click="showSelectOrderTypeTimeDialog()"
                          >
                            <div class="d-flex mx-2 pl-1">
                              <v-icon
                                dark
                                size="18"
                              >
                                timer
                              </v-icon>
                            </div>

                            <div
                              class="d-flex mr-2 pr-1"
                              style="width: 100%;"
                            >
                              <div class="d-inline-flex text-left">
                                {{ parseInt(posCart.OrderType) === 1 ? $t('Pos.Pos.Footer.Time.Delivery') : $t('Pos.Pos.Footer.Time.Collection') }}
                              </div>
                              <div
                                class="text-lowercase text-no-wrap d-inline-flex justify-end"
                                style="width: 100%;"
                              >
                                &nbsp; {{ convertMinutesToDisplayString(posCart.OrderTypeTime) }}
                              </div>
                            </div>
                          </v-btn>
                        </div>
                      </v-col>

                      <!-- Action Buttons -->
                      <v-col cols="12">
                        <v-container
                          fluid
                          class="pa-0"
                        >
                          <v-row no-gutters>
                            <v-col class="d-inline-flex">
                              <v-btn
                                :disabled="disableToolbar"
                                :large="$vuetify.breakpoint.smAndUp"
                                :small="$vuetify.breakpoint.xsOnly"
                                class="text-caption darken-1 red white--text elevation-0 ma-0 pa-0 mr-1 flex-grow-1"
                                style="min-width: 50px;"
                                @click="showCancelOrderDialog"
                              >
                                <span class="pa-1">
                                  {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
                                </span>
                              </v-btn>

                              <v-menu
                                v-model="commentsPopOverVisible"
                                :close-on-content-click="false"
                                :disabled="disableToolbar"
                                :nudge-left="150"
                                :nudge-width="300"
                                class="ma-0 pa-0"
                                max-width="450"
                                offset-y
                                style="width: 100%;"
                                top
                              >
                                <template #activator="{on}">
                                  <v-btn
                                    :disabled="disableToolbar"
                                    :large="$vuetify.breakpoint.smAndUp"
                                    :small="$vuetify.breakpoint.xsOnly"
                                    class="text-caption darken-1 blue white--text elevation-0 ma-0 pa-0 mr-1 flex-grow-1"
                                    style="min-width: 50px;"
                                    v-on="on"
                                  >
                                    <span class="pa-1">{{ $t('Pos.Pos.Footer.Button.Comment.Title').toLocaleUpperCase($i18n.locale) }}</span>

                                    <v-icon
                                      v-if="posCart.Comment"
                                      class="pl-1 text-subtitle-1"
                                      dark
                                    >
                                      comment
                                    </v-icon>
                                  </v-btn>
                                </template>

                                <v-card max-width="450">
                                  <v-card-text>
                                    <v-textarea
                                      v-model="posCart.Comment"
                                      :hint="$t('Pos.Pos.Footer.Button.Comment.Field.Hint')"
                                      :label="$t('Pos.Pos.Footer.Button.Comment.Field.Label')"
                                      clearable
                                      name="input-order-comments"
                                    />

                                    <div
                                      v-if="predefinedCommentsList.length"
                                      ref="predefinedCommentsList"
                                      style="max-height: 200px; overflow-y: auto;"
                                    >
                                      <template v-for="(comment, index) in predefinedCommentsList">
                                        <v-checkbox
                                          :key="`comment-${index}`"
                                          v-model="predefinedComments"
                                          :label="comment"
                                          :value="comment"
                                          class="ma-0"
                                          hide-details
                                          multiple
                                        />
                                      </template>
                                    </div>
                                  </v-card-text>

                                  <v-divider />

                                  <v-card-actions>
                                    <v-spacer />

                                    <v-btn
                                      text
                                      primary
                                      @click="commentsPopOverVisible = false"
                                    >
                                      {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-menu>

                              <v-btn
                                :disabled="disableToolbar || !completeOrderButtonEnabled || (!onlyWarningForAddresses && addressIsOutsideStoreLimits && selectedAddress && parseInt(posCart.OrderType) === 1)"
                                :large="$vuetify.breakpoint.smAndUp"
                                :loading="!completeOrderButtonEnabled"
                                :small="$vuetify.breakpoint.xsOnly"
                                class="text-caption white--text blue-grey ma-0 pa-0 mr-1 flex-grow-1"
                                depressed
                                style="min-width: 90px;"
                                @click="onSaveOrderButtonClick"
                              >
                                <span class="pa-1">{{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}</span>
                              </v-btn>

                              <v-btn
                                :disabled="disableToolbar || !completeOrderButtonEnabled || (!onlyWarningForAddresses && addressIsOutsideStoreLimits && selectedAddress && parseInt(posCart.OrderType) === 1)"
                                :large="$vuetify.breakpoint.smAndUp"
                                :loading="!completeOrderButtonEnabled"
                                :small="$vuetify.breakpoint.xsOnly"
                                class="text-caption white--text light-green darken-4 ma-0 pa-0 rounded-r-0"
                                depressed
                                style="min-width: 35px;"
                                @click="onCompleteOrderButtonClick(false)"
                              >
                                <v-icon>
                                  mdi-printer-pos-cancel-outline
                                </v-icon>
                              </v-btn>
                              <v-btn
                                :disabled="disableToolbar || !completeOrderButtonEnabled || (!onlyWarningForAddresses && addressIsOutsideStoreLimits && selectedAddress && parseInt(posCart.OrderType) === 1)"
                                :large="$vuetify.breakpoint.smAndUp"
                                :loading="!completeOrderButtonEnabled"
                                :small="$vuetify.breakpoint.xsOnly"
                                class="text-caption white--text light-green darken-2 ma-0 pa-0 flex-grow-1 rounded-l-0"
                                depressed
                                style="min-width: 90px;"
                                @click="onCompleteOrderButtonClick(true)"
                              >
                                <span class="pa-1">{{ isTableOrder ? $t('Pos.Pos.Footer.Button.AddOrder').toLocaleUpperCase($i18n.locale) : $t('Pos.Pos.Footer.Button.Payment').toLocaleUpperCase($i18n.locale) }}</span>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-navigation-drawer>
        </template>

        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row class="no-gutters">
              <!-- Toolbar -->
              <v-col
                id="cartToolbar"
                cols="12"
              >
                <pos-toolbar-customer :drawer.sync="cartDrawer" />
              </v-col>

              <!-- Cart -->
              <v-col
                id="cart"
                class="px-1"
                style="overflow-y: auto;"
                cols="12"
              >
                <div
                  v-if="posCart.Items.length <= 0"
                  class="fill-height"
                  style="position: relative;"
                >
                  <div
                    class="text-center grey--text pa-6"
                    style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                  >
                    <v-icon
                      class="mb-4"
                      color="grey lighten-2"
                      size="128"
                    >
                      {{ $t('Pos.Pos.Cart.Icon') }}
                    </v-icon>
                    <div class="text-h6 mb-2">
                      {{ $t('Pos.Pos.Cart.NoData.Title') }}
                    </div>
                    <div class="text-subtitle-1">
                      {{ $t('Pos.Pos.Cart.NoData.Subtitle') }}
                    </div>
                  </div>
                </div>

                <template v-else>
                  <template v-for="tableSeat in posCart.ItemsByTableSeat">
                    <v-subheader
                      v-if="(posCart.ItemsByTableSeat.length && posCart.ItemsByTableSeat[0].Seat !== 0) || posCart.ItemsByTableSeat.length > 1"
                      :key="`table-seat-${tableSeat.Seat}`"
                      class="font-weight-bold pl-1 blue-grey lighten-4"
                      style="height: 26px;"
                    >
                      {{ tableSeat.Name }}
                    </v-subheader>

                    <template v-for="category in tableSeat.Items">
                      <v-subheader
                        :key="`table-seat-${tableSeat.Seat}-category-${category.CategoryId}`"
                        class=""
                        style="height: 26px;"
                      >
                        {{ getTranslatedField(getCategory(category.CategoryId), 'Name') }}
                      </v-subheader>

                      <template v-for="(menu) in category.Items">
                        <v-list
                          :key="menu.RowId"
                          class="mb-1 pb-0 pt-0"
                          dense
                        >
                          <v-list-item
                            :disabled="menu.Loading"
                            :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']"
                            dense
                            style="height: 76px;"
                            ripple
                            @click="showEditMenuOptionsDialog(menu)"
                          >
                            <v-list-item-content>
                              <span
                                :style="'background-color: #' + (getCategoryColor(category.CategoryId) || '333333') + ';'"
                                style="position: absolute; width:5px; height: 100%; margin-left: -16px; padding: 0; margin-right: 16px;"
                              />

                              <v-list-item-title class="text-subtitle-1 font-weight-regular mb-2">
                                <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                  <strong class="orange--text">{{ formatUnitQuantity($i18n.locale, menu.UnitQuantity, getMeasurementUnitById(menu.UnitId)) }}</strong>
                                </template>
                                <template v-else>
                                  <strong class="orange--text">
                                    {{ menu.Quantity }} x
                                  </strong>
                                </template>
                                {{ getTranslatedField(menu, 'Name') }}
                              </v-list-item-title>

                              <v-list-item-subtitle class="font-weight-regular">
                                <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                  <span class="old-price">
                                    {{ menu.MenuPrice | currency }}
                                  </span> {{ menu.SpecialPrice | currency }}
                                </template>
                                <template v-else>
                                  <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                    {{ getMenuItemUnitPriceWithOptions(menu) | currency }}
                                    <span class="grey--text text-caption">/ {{ getMeasurementUnitById(menu.UnitId).BaseUnit }}</span>
                                  </template>
                                  <template v-else>
                                    {{ menu.Price | currency }}
                                  </template>
                                </template>
                              </v-list-item-subtitle>

                              <v-list-item-subtitle class="pb-1 text-caption font-weight-regular">
                                {{ getMenuSelectedOptionsString(menu) }}&nbsp;
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action
                              :style="menu.Custom && (parseInt(menu.Custom.Seat) || parseInt(menu.Custom.Order)) ? 'min-width: 100px;' : 'min-width: 65px;'"
                              class="ma-0"
                            >
                              <v-list-item-action-text class="text-subtitle-1">
                                <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                  <span class="old-price">
                                    {{ parseFloat(parseFloat(menu.MenuPrice) * menu.Quantity) | currency }}
                                  </span>
                                  {{ parseFloat(parseFloat(menu.SpecialPrice) * menu.Quantity) | currency }}
                                </template>
                                <template v-else>
                                  {{ parseFloat(parseFloat(menu.Price) * menu.Quantity) | currency }}
                                </template>
                              </v-list-item-action-text>

                              <div>
                                <v-btn
                                  v-if="menu.Custom && parseInt(menu.Custom.Order)"
                                  class="d-inline-block px-1"
                                  color="pink lighten-1"
                                  text
                                  ripple
                                  small
                                  style="min-width: 0;"
                                >
                                  <v-icon size="22">
                                    low_priority
                                  </v-icon>

                                  <span style="position: relative; top: -4px;">{{ menu.Custom.Order }}</span>
                                </v-btn>

                                <v-btn
                                  v-if="menu.Custom && parseInt(menu.Custom.Seat)"
                                  class="d-inline-block px-1"
                                  color="brown lighten-1"
                                  text
                                  ripple
                                  small
                                  style="min-width: 0;"
                                >
                                  <v-icon size="22">
                                    event_seat
                                  </v-icon>

                                  <span style="position: relative; top: -4px;">{{ menu.Custom.Seat }}</span>
                                </v-btn>

                                <v-btn
                                  v-if="isCouponPerMenuItemVisible(menu)"
                                  :class="couponExistsForMenuItem(menu) ? 'purple--text' : 'green--text'"
                                  :disabled="menu.Loading || (cartTotalWithoutSpecialAndExcludedItems <= 0 && !couponExistsForMenuItem(menu))"
                                  :loading="menu.LoadingCoupon"
                                  class="d-inline-block"
                                  icon
                                  ripple
                                  @click.native.stop="toggleCouponForMenuItem(menu)"
                                >
                                  <v-icon size="22">
                                    redeem
                                  </v-icon>
                                </v-btn>

                                <v-btn
                                  :disabled="menu.Loading"
                                  :loading="menu.LoadingRemove"
                                  class="red--text d-inline-block"
                                  icon
                                  ripple
                                  @click.native.stop="onRemoveCartMenuItem(menu)"
                                >
                                  <v-icon size="22">
                                    close
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider />
                        </v-list>
                      </template>
                    </template>
                  </template>
                </template>
              </v-col>

              <!-- Totals -->
              <v-col
                id="cartTotals"
                class=""
                cols="12"
              >
                <v-container
                  fluid
                  class="pa-1 pb-0 text-body-2"
                >
                  <v-row no-gutters>
                    <!-- Sub Total -->
                    <v-col
                      class="grey--text text--darken-1"
                      cols="12"
                    >
                      <v-divider />

                      <div class="pa-1">
                        <span class="grey--text text--darken-1">
                          {{ $t('Common.Misc.Subtotal').toLocaleUpperCase($i18n.locale) }}
                        </span>
                        <span class="grey--text text--darken-1 float-right pr-2">
                          {{ posCart.SCartTotal | currency }}
                        </span>
                      </div>

                      <v-divider />
                    </v-col>

                    <!-- Extra Charges -->
                    <v-col
                      v-if="!isTableOrder && extraCharges.length > 0"
                      class="grey--text text--darken-1"
                      cols="12"
                    >
                      <div
                        style="float: none; clear: both; line-height: 28px;"
                        class="pa-0 px-1"
                      >
                        <div class="">
                          <span class="grey--text text--darken-1">
                            {{ $t('Common.Misc.ExtraCharges').toLocaleUpperCase($i18n.locale) }}
                          </span>
                          <span
                            class="grey--text text--darken-1 float-right"
                          >
                            <v-btn
                              :disabled="(posCart && parseFloat(posCart.SCartTotal) <= 0) || loadingAddExtraCharge || !extraChargesAvailable"
                              :loading="loadingAddExtraCharge"
                              class="pa-0 ma-0 green--text"
                              icon
                              ripple
                              small
                              @click.native.stop="showSelectExtraChargeDialog()"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </span>
                        </div>
                        <v-divider />
                      </div>

                      <template v-if="cartHasExtraCharge">
                        <div class="divTable">
                          <template v-for="(total, index) in posCart.Totals">
                            <div
                              v-if="total.Name === 'extra_charge'"
                              :key="index"
                              class="divTableRow"
                            >
                              <div
                                class="divTableCell text-left pl-1"
                                style="width: 28px;"
                              >
                                <v-btn
                                  :disabled="total.Loading || !canRemoveExtraCharges(total)"
                                  :loading="total.Loading"
                                  class="pa-0 ma-0 red--text"
                                  icon
                                  ripple
                                  small
                                  @click.native.stop="onRemoveExtraChargeItem(total)"
                                >
                                  <v-icon size="20">
                                    close
                                  </v-icon>
                                </v-btn>
                              </div>

                              <div
                                class="divTableCell text-left pl-0"
                                style="width: 100%;"
                              >
                                <span class="grey--text text--darken-1">
                                  {{ total.Title }}
                                </span>
                              </div>

                              <div
                                class="divTableCell text-right text-no-wrap"
                                style="min-width: 60px;"
                              >
                                <span class="grey--text text--darken-1 px-1">
                                  +{{ total.AmountFormatted | currency }}
                                </span>
                              </div>
                            </div>
                          </template>
                        </div>
                        <v-divider />
                      </template>
                    </v-col>

                    <!-- Tips -->
                    <v-col
                      v-if="appConfig.LOCATION_DATA.OrderTips && !isTableOrder"
                      class="grey--text text--darken-1"
                      cols="12"
                    >
                      <div
                        v-if="!cartHasTip"
                        class="pa-0 px-1"
                        style="float: none; clear: both; line-height: 28px;"
                      >
                        <span class="grey--text text--darken-1">
                          {{ $t('Common.Misc.Tip').toLocaleUpperCase($i18n.locale) }}
                        </span>

                        <span
                          v-if="!cartHasTip"
                          class="grey--text text--darken-1 float-right"
                        >
                          <v-btn
                            :disabled="(posCart && parseFloat(posCart.SCartTotal) <= 0) || loadingAddTip"
                            :loading="loadingAddTip"
                            class="pa-0 ma-0 green--text"
                            icon
                            ripple
                            small
                            @click.native.stop="showSelectTipDialog()"
                          >
                            <v-icon>add</v-icon>
                          </v-btn>
                        </span>
                      </div>

                      <template v-if="cartHasTip">
                        <template v-for="(tip, index) in posCart.Totals">
                          <div
                            v-if="tip.Name === 'tip_amount'"
                            :key="index"
                            class="pa-0 px-1"
                            style="float: none; clear: both; line-height: 28px;"
                          >
                            <v-btn
                              :disabled="loadingRemoveTip"
                              :loading="loadingRemoveTip"
                              class="pa-0 ma-0 red--text"
                              icon
                              ripple
                              small
                              @click.native.stop="onRemoveTipItem(tip)"
                            >
                              <v-icon size="20">
                                close
                              </v-icon>
                            </v-btn>
                            <span class="grey--text text--darken-1">
                              {{ tip.Title }}
                            </span>
                            <span class="grey--text text--darken-1 float-right pr-2">
                              +{{ tip.AmountFormatted | currency }}
                            </span>
                          </div>
                        </template>
                      </template>
                      <v-divider />
                    </v-col>

                    <!-- Coupons -->
                    <v-col
                      class="grey--text text--darken-1"
                      cols="12"
                    >
                      <div
                        v-if="cartCouponsActive"
                        style="float: none; clear: both; line-height: 28px;"
                        class="pa-0 px-1"
                      >
                        <div class="">
                          <span class="grey--text text--darken-1">
                            {{ $t('Common.Misc.Discount').toLocaleUpperCase($i18n.locale) }}
                          </span>
                          <span
                            class="grey--text text--darken-1 float-right"
                          >
                            <v-btn
                              :disabled="loadingAddCoupon || cartTotalWithoutSpecialAndExcludedItems <= 0"
                              :loading="loadingAddCoupon"
                              class="pa-0 ma-0 green--text"
                              icon
                              ripple
                              small
                              @click.native.stop="showSelectCouponDialog()"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </span>
                        </div>
                        <v-divider />
                      </div>

                      <template v-if="cartHasCoupon">
                        <div class="divTable">
                          <template v-for="(total, index) in posCart.Totals">
                            <div
                              v-if="total.Name === 'coupon'"
                              :key="index"
                              class="divTableRow"
                            >
                              <div
                                class="divTableCell text-left pl-1"
                                style="width: 28px;"
                              >
                                <v-btn
                                  :disabled="total.Loading"
                                  :loading="total.Loading"
                                  class="pa-0 ma-0 red--text"
                                  icon
                                  ripple
                                  small
                                  @click.native.stop="onRemoveCouponItem(total)"
                                >
                                  <v-icon size="20">
                                    close
                                  </v-icon>
                                </v-btn>
                              </div>

                              <div
                                class="divTableCell text-left pl-0"
                                style="width: 100%;"
                              >
                                <span class="grey--text text--darken-1">
                                  {{ total.Title }}
                                </span>
                              </div>

                              <div
                                class="divTableCell text-right text-no-wrap"
                                style="min-width: 60px;"
                              >
                                <span class="grey--text text--darken-1 px-1">
                                  -{{ total.AmountFormatted | currency }}
                                </span>
                              </div>
                            </div>
                          </template>
                        </div>
                        <v-divider />
                      </template>
                    </v-col>

                    <!-- Delivery -->
                    <template v-for="(total, index) in posCart.Totals">
                      <v-col
                        v-if="total.Name === 'delivery'"
                        :key="`totals-delivery-${index}`"
                        class="grey--text text--darken-1"
                        cols="12"
                      >
                        <div class="pa-1">
                          <v-btn
                            class="pa-0 ma-0 red--text"
                            icon
                            ripple
                            small
                            @click.native.stop="removeDeliveryAreaCharges"
                          >
                            <v-icon size="20">
                              close
                            </v-icon>
                          </v-btn>
                          <span class="grey--text text--darken-1">
                            {{ $t('Order.Type.Delivery.Title').toLocaleUpperCase($i18n.locale) }}
                          </span>
                          <span class="grey--text text--darken-1 float-right pr-2">
                            {{ total.AmountFormatted | currency }}
                          </span>
                        </div>
                        <v-divider />
                      </v-col>
                    </template>

                    <!-- Order Totals -->
                    <template v-if="posCart.Totals.length === 0">
                      <v-col
                        class="grey--text text--darken-1"
                        cols="12"
                      >
                        <div class="pa-1">
                          <strong>
                            <span class="grey--text text--darken-1">
                              {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                            </span>
                            <span class="grey--text text--darken-1 float-right pr-2">
                              {{ 0 | currency }}
                            </span>
                          </strong>
                        </div>
                      </v-col>
                    </template>

                    <template v-else>
                      <template v-for="(total, index) in posCart.Totals">
                        <v-col
                          v-if="total.Name === 'order_total'"
                          :key="`totals-ordertotal-${index}`"
                          class="grey--text text--darken-1"
                          cols="12"
                        >
                          <div class="pa-1">
                            <strong>
                              <span class="grey--text text--darken-1">
                                {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                              </span>
                              <span class="grey--text text--darken-1 float-right pr-2">
                                {{ total.AmountFormatted | currency }}
                              </span>
                            </strong>
                          </div>
                        </v-col>
                      </template>
                    </template>

                    <!-- Order Type Time -->
                    <v-col
                      v-if="!isTableOrder"
                      class="grey--text text--darken-1"
                      cols="12"
                    >
                      <v-divider />

                      <div class="my-1">
                        <v-btn
                          :color="posCart.OrderType===1 ? 'purple' : 'blue darken-3'"
                          block
                          class="text-caption ma-0 pa-0"
                          dark
                          depressed
                          small
                          @click="showSelectOrderTypeTimeDialog()"
                        >
                          <div class="d-flex mx-2 pl-1">
                            <v-icon
                              dark
                              size="18"
                            >
                              timer
                            </v-icon>
                          </div>

                          <div
                            class="d-flex mr-2 pr-1"
                            style="width: 100%;"
                          >
                            <div class="d-inline-flex text-left">
                              {{ parseInt(posCart.OrderType) === 1 ? $t('Pos.Pos.Footer.Time.Delivery') : $t('Pos.Pos.Footer.Time.Collection') }}
                            </div>
                            <div
                              class="text-lowercase text-no-wrap d-inline-flex justify-end"
                              style="width: 100%;"
                            >
                              &nbsp; {{ convertMinutesToDisplayString(posCart.OrderTypeTime) }}
                            </div>
                          </div>
                        </v-btn>
                      </div>
                    </v-col>

                    <!-- Action Buttons -->
                    <v-col cols="12">
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row no-gutters>
                          <v-col class="d-inline-flex">
                            <v-btn
                              :disabled="disableToolbar"
                              :large="$vuetify.breakpoint.smAndUp"
                              :small="$vuetify.breakpoint.xsOnly"
                              class="text-caption darken-1 red white--text elevation-0 ma-0 pa-0 mr-1 flex-grow-1"
                              style="min-width: 50px;"
                              @click="showCancelOrderDialog"
                            >
                              <span class="pa-1">
                                {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
                              </span>
                            </v-btn>

                            <v-menu
                              v-model="commentsPopOverVisible"
                              :close-on-content-click="false"
                              :disabled="disableToolbar"
                              :nudge-left="150"
                              :nudge-width="300"
                              class="ma-0 pa-0"
                              max-width="450"
                              offset-y
                              style="width: 100%;"
                              top
                            >
                              <template #activator="{on}">
                                <v-btn
                                  :disabled="disableToolbar"
                                  :large="$vuetify.breakpoint.smAndUp"
                                  :small="$vuetify.breakpoint.xsOnly"
                                  class="text-caption darken-1 blue white--text elevation-0 ma-0 pa-0 mr-1 flex-grow-1"
                                  style="min-width: 50px;"
                                  v-on="on"
                                >
                                  <span class="pa-1">{{ $t('Pos.Pos.Footer.Button.Comment.Title').toLocaleUpperCase($i18n.locale) }}</span>

                                  <v-icon
                                    v-if="posCart.Comment"
                                    class="pl-1"
                                    dark
                                  >
                                    comment
                                  </v-icon>
                                </v-btn>
                              </template>

                              <v-card max-width="450">
                                <v-card-text>
                                  <v-textarea
                                    v-model="posCart.Comment"
                                    :hint="$t('Pos.Pos.Footer.Button.Comment.Field.Hint')"
                                    :label="$t('Pos.Pos.Footer.Button.Comment.Field.Label')"
                                    clearable
                                    name="input-order-comments"
                                  />

                                  <div
                                    v-if="predefinedCommentsList.length"
                                    ref="predefinedCommentsList"
                                    style="max-height: 200px; overflow-y: auto;"
                                  >
                                    <template v-for="(comment, index) in predefinedCommentsList">
                                      <v-checkbox
                                        :key="`comment-${index}`"
                                        v-model="predefinedComments"
                                        :label="comment"
                                        :value="comment"
                                        class="ma-0"
                                        hide-details
                                        multiple
                                      />
                                    </template>
                                  </div>
                                </v-card-text>

                                <v-divider />

                                <v-card-actions>
                                  <v-spacer />

                                  <v-btn
                                    text
                                    primary
                                    @click="commentsPopOverVisible = false"
                                  >
                                    {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>

                            <v-btn
                              :disabled="disableToolbar || !completeOrderButtonEnabled || (!onlyWarningForAddresses && addressIsOutsideStoreLimits && selectedAddress && parseInt(posCart.OrderType) === 1)"
                              :large="$vuetify.breakpoint.smAndUp"
                              :loading="!completeOrderButtonEnabled"
                              :small="$vuetify.breakpoint.xsOnly"
                              class="text-caption white--text blue-grey ma-0 pa-0 mr-1 flex-grow-1"
                              depressed
                              style="min-width: 90px;"
                              @click="onSaveOrderButtonClick"
                            >
                              <span class="pa-1">{{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}</span>
                            </v-btn>

                            <v-btn
                              :disabled="disableToolbar || !completeOrderButtonEnabled || (!onlyWarningForAddresses && addressIsOutsideStoreLimits && selectedAddress && parseInt(posCart.OrderType) === 1)"
                              :large="$vuetify.breakpoint.smAndUp"
                              :loading="!completeOrderButtonEnabled"
                              :small="$vuetify.breakpoint.xsOnly"
                              class="text-caption white--text light-green darken-4 ma-0 pa-0 rounded-r-0"
                              depressed
                              style="min-width: 35px;"
                              @click="onCompleteOrderButtonClick(false)"
                            >
                              <v-icon>
                                mdi-printer-pos-cancel-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              :disabled="disableToolbar || !completeOrderButtonEnabled || (!onlyWarningForAddresses && addressIsOutsideStoreLimits && selectedAddress && parseInt(posCart.OrderType) === 1)"
                              :large="$vuetify.breakpoint.smAndUp"
                              :loading="!completeOrderButtonEnabled"
                              :small="$vuetify.breakpoint.xsOnly"
                              class="text-caption white--text light-green darken-2 ma-0 pa-0 flex-grow-1 rounded-l-0"
                              depressed
                              style="min-width: 90px;"
                              @click="onCompleteOrderButtonClick(true)"
                            >
                              <span class="pa-1">{{ isTableOrder ? $t('Pos.Pos.Footer.Button.AddOrder').toLocaleUpperCase($i18n.locale) : $t('Pos.Pos.Footer.Button.Payment').toLocaleUpperCase($i18n.locale) }}</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <select-coupon-dialog
          :menu-item="selectedMenuItem"
          :source-type="posCart.SourceTypeId"
          :visible.sync="selectCouponDialogVisible"
          @cart:coupon-selected="onCouponSelected"
        />

        <select-tip-dialog
          :visible.sync="selectTipDialogVisible"
          @cart:tip-selected="onTipSelected"
        />

        <select-extra-charge-dialog
          :visible.sync="selectExtraChargeDialogVisible"
          @cart:extra-charge-selected="onExtraChargeSelected"
        />

        <select-order-type-time-dialog
          v-if="!isTableOrder"
          :order-type.sync="posCart.OrderType"
          :selected-time.sync="posCart.OrderTypeTime"
          :visible.sync="selectOrderTypeTimeDialogVisible"
          @cart:order-type-time-selected="onOrderTypeTimeSelected"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                      from '@/mixins/auth'
import OrderTypeTime             from '@/mixins/orders/orderTypeTime'
import Translatable              from '@/mixins/translatable'
import PosCatalog                from '@/mixins/pos/posCatalog'
import PosOrder                  from '@/mixins/pos/posOrder'
import PosCustomer               from '@/mixins/pos/posCustomer'
import PosCart                   from '@/mixins/pos/posCart'
import SelectCouponDialog        from '@/components/pos/SelectCouponDialog'
import SelectOrderTypeTimeDialog from '@/components/pos/SelectOrderTypeTimeDialog'
import Resizable                 from '@/mixins/resizable'
import SelectTipDialog           from '@/components/pos/SelectTipDialog'
import PosToolbarCustomer        from '@/components/pos/PosToolbarCustomer'
import PosCartQueue              from '@/mixins/pos/posCartQueue'
import { formatUnitQuantity }    from '@/lib/currency/currency'
import SelectExtraChargeDialog   from '@/components/pos/SelectExtraChargeDialog.vue'
import ReceiptProviderTypeEnum   from '@/api/Enums/ReceiptProviderTypeEnum'
import PaymentTypeEnum           from '@/api/Enums/PaymentTypeEnum'
import PinManagerEnum            from '@/api/Enums/PinManagerEnum'
import ReceiptTypeEnum           from '@/api/Enums/ReceiptTypeEnum'

export default {
  name: 'PosCart',

  components: {
    SelectExtraChargeDialog,
    PosToolbarCustomer,
    SelectTipDialog,
    SelectCouponDialog,
    SelectOrderTypeTimeDialog
  },

  directives: {},

  mixins: [Auth, Resizable, Translatable, OrderTypeTime, PosCartQueue, PosCart, PosCatalog, PosOrder, PosCustomer],

  props: {
    drawer: {
      type   : Boolean,
      default: false
    },
    disableToolbar: {
      type   : Boolean,
      default: false
    },
    orderComplete: {
      type   : Boolean,
      default: false
    },
    beforeRouteLeaveCallback: {
      type    : Function,
      default : undefined,
      required: false
    }
  },

  DataStore: { posCart: 'cart' },

  data () {
    return {
      routeNext                       : null,
      completeOrderButtonEnabled      : true,
      commentsPopOverVisible          : false,
      selectCouponDialogVisible       : false,
      selectExtraChargeDialogVisible  : false,
      selectTipDialogVisible          : false,
      selectOrderTypeTimeDialogVisible: false,
      selectedMenuItem                : null,
      couponPerMenuItemEnabled        : true,
      predefinedComments              : [],
      loadingCartItem                 : false,
      loadingAddCoupon                : false,
      loadingAddTip                   : false,
      loadingAddExtraCharge           : false,
      loadingRemoveExtraCharge        : false,
      loadingRemoveTip                : false
    }
  },

  computed: {
    extraCharges () {
      const orderType = this?.posCart?.SOrderType
      const sourceTypeId = this?.posCart?.SourceTypeId

      return this.appConfig?.EXTRA_CHARGES?.filter(charge =>
        (parseInt(charge.OrderRestriction) > 0 ? parseInt(charge.OrderRestriction) === orderType : true) &&
        (!charge.SourceType?.includes(0) ? charge.SourceType?.includes(sourceTypeId) : true)
      ) || []
    },

    predefinedCommentsList () {
      return this.appConfig?.LOCATION_DATA?.OrderComments?.filter(comment => comment.Active)?.map(comment => comment.Title) || []
    },

    showCartItemsBadge () {
      return this.posCart.TotalItems > 0
    },

    cartCouponsActive () {
      return !this.isTableOrder
    },

    cartCoupons () {
      const totals = this.posCart?.Totals || []
      return totals.filter(total => total.Name === 'coupon')
    },

    extraChargesAvailable () {
      const orderType = this?.posCart?.SOrderType
      const sourceTypeId = this?.posCart?.SourceTypeId
      const charges = this.appConfig?.EXTRA_CHARGES?.filter(charge =>
        (parseInt(charge.OrderRestriction) > 0 ? parseInt(charge.OrderRestriction) === orderType : true) &&
        (!charge.SourceType?.includes(0) ? charge.SourceType?.includes(sourceTypeId) : true)
      ) || []

      return charges.length > 0
    },

    cartHasExtraCharge () {
      return this.posCart ? !!this.posCart.Totals.find(total => total.Name === 'extra_charge') : false
    },

    cartHasCoupon () {
      return this.posCart ? !!this.posCart.Totals.find(total => total.Name === 'coupon') : false
    },

    cartHasTip () {
      return this.posCart ? !!this.posCart.Totals.find(total => total.Name === 'tip_amount') : false
    },

    cartHasPaidCardTransactions () {
      return this.posCart?.PaymentTransactions?.filter(transaction => transaction.Paid === 'success' && transaction.PaymentType === PaymentTypeEnum.CARD)?.length > 0
    },

    canCancelOrSaveOrder () {
      if (this.appHasReceiptsProvider && this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) return !this.cartHasPaidCardTransactions

      return true
    },

    cartTotalWithoutSpecialAndExcludedItems () {
      const cartOrderTotal = parseFloat(this.posCart.SCartTotal) || 0
      const cartCouponsTotal = parseFloat((this.cartCoupons?.reduce((a, b) => parseFloat(a) + parseFloat(b.Amount), 0) || 0))
      const cartSpecialAndExcludedTotal = parseFloat(this.posCart?.ItemsFlat?.filter(item => item.Excluded || (item.SpecialStatus && item.IsSpecial && !item.HasCustomPrice))?.reduce((a, b) => parseFloat(a) + (parseFloat(b.Price) * parseInt(b.Quantity)), 0) || 0)

      return parseFloat((cartOrderTotal - cartCouponsTotal - cartSpecialAndExcludedTotal).toFixed(2))
    },

    cartHasNoSpecialAndNoExcludedItems () {
      return !!this.posCart?.ItemsFlat?.filter(item => !item.SpecialStatus && !item.IsSpecial && !item.Excluded)?.length
    },

    isOrderComplete: {
      get () {
        return this.orderComplete
      },
      set (val) {
        this.$emit('update:order-complete', val)
      }
    },

    cartDrawer: {
      get () {
        return this.drawer
      },
      set (val) {
        this.$emit('update:drawer', val)
      }
    }
  },

  watch: {
    'posCart.Comment': function (newVal) {
      if (!newVal) this.initComments()
    },

    beforeRouteLeaveCallback (newVal) {
      this.routeNext = newVal
    },

    predefinedComments (newVal, oldVal) {
      if (this.posCart) {
        const commentsArray = this.posCart.Comment?.split('\n') || []
        const diffArray = commentsArray.filter(comment => !newVal.includes(comment.trim()) && !oldVal.includes(comment.trim()))
        const joinedArray = [...diffArray, ...newVal]
        if (joinedArray.length) this.posCart.Comment = joinedArray.join('\n')
      }
    },

    commentsPopOverVisible (newVal) {
      this.initComments()

      if (!newVal) {
        this.cartUpdate('commentsPopOverVisible')
      }
    }
  },

  beforeCreate () {
  },

  created () {
    this.$bus.$on('cart:save-order', this.onSaveOrder)
    this.$bus.$on('cart:cancel-order', this.onCancelOrder)
    this.$bus.$on('cart:complete-order', this.onCompleteOrderButtonClick)
    this.$bus.$on('cart:complete-order-after-payment', this.onCompleteOrderAfterPaymentButtonClick)
    this.$bus.$on(window.SocketCommand.Customer.Save, this.updateCustomerFromSaveCustomer)
    this.$bus.$on(window.SocketCommand.Customer.ReplyForNewOrder, this.setCustomer)
    this.$bus.$on(window.SocketCommand.Order.Payment.Transaction.CartResult, this.onPaymentTransactionResult)
  },

  beforeMount () {
  },

  mounted () {
  },

  beforeUpdate () {
  },

  updated () {
  },

  beforeDestroy () {
    this.$bus.$off('cart:save-order', this.onSaveOrder)
    this.$bus.$off('cart:cancel-order', this.onCancelOrder)
    this.$bus.$off('cart:complete-order', this.onCompleteOrderButtonClick)
    this.$bus.$off('cart:complete-order-after-payment', this.onCompleteOrderAfterPaymentButtonClick)
    this.$bus.$off(window.SocketCommand.Customer.Save, this.updateCustomerFromSaveCustomer)
    this.$bus.$off(window.SocketCommand.Customer.ReplyForNewOrder, this.setCustomer)
    this.$bus.$off(window.SocketCommand.Order.Payment.Transaction.CartResult, this.onPaymentTransactionResult)
  },

  destroyed () {
  },

  methods: {
    formatUnitQuantity,

    cartExtraChargesTotal (chargeId) {
      if (!this.posCart || !chargeId) return 0

      const totalCharges = parseFloat(this.posCart?.Totals?.filter(charge => charge.Id === chargeId)?.find(total => total.Name === 'extra_charge')?.Amount || 0)

      return parseFloat(totalCharges.toFixed(2))
    },

    cartItemsExtraChargesTotal (chargeId) {
      if (!this.posCart || !chargeId) return 0

      let totalCharges = 0
      const itemsWithCharges = this.posCart?.ItemsFlat?.filter(item => item?.MenuCharges?.length) || []
      itemsWithCharges.forEach(item => item.MenuCharges.filter(charge => charge.ChargeId === chargeId).forEach(charge => {
        totalCharges += (parseFloat(charge.Price) * parseInt(charge.Quantity)) * parseInt(item.Quantity)
      }))

      return parseFloat(totalCharges.toFixed(2))
    },

    canRemoveExtraCharges (total) {
      return this.cartExtraChargesTotal(total?.Id) - this.cartItemsExtraChargesTotal(total?.Id) > 0
    },

    getMenuItemUnitPriceWithOptions (menu) {
      const unitValueOptions = menu?.Options?.filter(option => option.DisplayType === 'unit_value' && option.Selected) || []

      let price = parseFloat(menu.MenuPrice || 0)
      unitValueOptions.forEach(option => {
        price = price + parseFloat(option.Price || 0)
      })
      return parseFloat(price.toFixed(2))
    },

    initComments () {
      if (this.$refs?.predefinedCommentsList) {
        this.$refs.predefinedCommentsList.scrollTop = 0
      }

      if (this.posCart?.Comment) {
        const commentsArray = this.posCart.Comment?.split('\n') || []
        const tmpArr = []

        commentsArray.forEach(comment => {
          if (commentsArray.includes(comment.trim())) {
            tmpArr.push(comment.trim())
          }
        })

        if (tmpArr.length) this.predefinedComments = tmpArr
      } else {
        if (this.predefinedComments.length) this.predefinedComments = []
      }
    },

    showEditMenuOptionsDialog (item) {
      if (!item || item.Loading || this.loadingCartItem) return

      this.$set(this, 'loadingCartItem', true)

      setTimeout(() => {
        this.$set(this, 'loadingCartItem', false)
      }, 1500)

      this.$bus.$emit('cart:show-edit-menu-dialog', item)
    },

    setCustomer (customer) {
      if (customer) {
        this.updateCustomerFromSaveCustomer({
          status: 'success',
          result: { customer: customer }
        })
      }
    },

    updateCustomerFromSaveCustomer (data) {
      if (data.status !== 'success') return
      const customer = data.result.customer.Info
      customer.Addresses = data.result.customer.Addresses
      this.posCart.Customer = customer
      this.checkForCustomerAddress()
    },

    onOrderTypeTimeSelected () {
      this.cartUpdate('orderTypeTime')
    },

    onCouponSelected (coupon, menuItem) {
      if (!coupon) return

      if (coupon.Type === 'F' && menuItem && ((parseFloat(menuItem.Price) * parseInt(menuItem.Quantity)) - coupon.Discount) < 0) {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Pos.Pos.Notification.CouponOverMenuItemTotal'),
          timeout: 3000,
          type   : 'error',
          icon   : 'warning'
        })

        return
      }

      if (coupon.Type === 'F') {
        const cartOrderTotalWithoutDiscounts = this.cartTotalWithoutSpecialAndExcludedItems - parseFloat(coupon.Discount)

        if (cartOrderTotalWithoutDiscounts < 0) {
          this.$bus.$emit('app-show-notification', {
            body   : this.$t('Pos.Pos.Notification.CouponOverOrderTotal'),
            timeout: 3000,
            type   : 'error',
            icon   : 'warning'
          })

          return
        }
      }

      if (!menuItem) this.$set(this, 'loadingAddCoupon', true)

      const cartMenuItem = this.getCartItemByRowId(menuItem?.RowId)
      if (cartMenuItem) {
        this.$set(cartMenuItem, 'Loading', true)
        this.$set(cartMenuItem, 'LoadingCoupon', true)
      }

      this.cartAddCoupon(coupon)
    },

    onRemoveExtraChargeItem (item) {
      if (!item || item?.Loading) return

      this.$set(item, 'Loading', true)
      this.cartRemoveExtraCharge(item)
    },

    onRemoveCouponItem (item) {
      if (!item || item?.Loading) return

      const menuItem = this.getCartItemByRowId(item?.RowId)
      if (menuItem) {
        this.$set(menuItem, 'Loading', true)
        this.$set(menuItem, 'LoadingCoupon', true)
      }

      this.$set(item, 'Loading', true)

      this.cartRemoveCoupon(item)
    },

    isCouponPerMenuItemVisible (item) {
      return this.couponPerMenuItemEnabled && !item.Excluded && !item.SpecialStatus && !item.IsSpecial
    },

    couponExistsForMenuItem (item) {
      return !!this.getMenuItemCoupon(item)
    },

    getMenuItemCoupon (item) {
      return this.posCart?.Totals?.find(total => total.Name === 'coupon' && total.RowId === item.RowId)
    },

    toggleCouponForMenuItem (item) {
      if (!item || item?.Loading || item?.LoadingCoupon || this.loadingCartItem) return

      if (this.couponExistsForMenuItem(item)) {
        const coupon = this.getMenuItemCoupon(item)

        this.$set(item, 'Loading', true)
        this.$set(item, 'LoadingCoupon', true)

        this.onRemoveCouponItem(coupon)
      } else {
        this.showSelectCouponForMenuItemDialog(item)
      }
    },

    onExtraChargeSelected (val) {
      if (!val) return

      this.cartAddExtraCharge(val)
    },

    onTipSelected (val) {
      if (!val || parseFloat(val.Amount) <= 0) return
      this.cartAddTip(val.Amount)
    },

    onRemoveTipItem (item) {
      if (!item) return
      this.cartRemoveTip(item)
    },

    onRemoveCartMenuItem (item) {
      if (!item || item.Loading || item.LoadingRemove || this.loadingCartItem) return

      this.$set(item, 'Loading', true)
      this.$set(item, 'LoadingRemove', true)

      this.cartRemoveItem(item)
    },

    onCancelOrder () {
      this.cartDestroy()
    },

    onPayOrderButtonClick (autoPrint = true) {
      this.$bus.$emit('cart:show-order-payment-dialog', autoPrint)
    },

    onSaveOrderButtonClick () {
      if (this.isTableOrder && this.posUserHasComponentPermission('Tables', 'ACCESS')) {
        this.$router.replace({
          name  : 'Tables',
          params: {
            noRouteGuardConfirmDialog: this.canCancelOrSaveOrder
          }
        })
      } else {
        this.$router.replace({
          name  : 'OrdersSaved',
          params: {
            noRouteGuardConfirmDialog: this.canCancelOrSaveOrder
          }
        })
      }

      this.cdsChannelMessageSend({
        Action : 'cart:data',
        Payload: null
      })
    },

    onCompleteOrderButtonClick (autoPrint = true) {
      if (this.posCart.OrderType === 1 && (
        this.posCart.Customer === null ||
        this.posCart.CustomerId <= 0 ||
        this.posCart.Address === null ||
        this.posCart.AddressId <= 0
      )) {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Pos.Pos.Notification.NoClient'),
          timeout: 2000,
          type   : 'error',
          icon   : 'warning'
        })

        return
      }

      autoPrint = autoPrint ?? true

      if (!this.isTableOrder && this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD || (this.appReceiptsProviderType !== ReceiptProviderTypeEnum.CLOUD && this.posCart.PaymentType === PaymentTypeEnum.MULTIPLE)) {
        this.onPayOrderButtonClick(autoPrint)
      } else {
        autoPrint = autoPrint ?? true
        const docType = !autoPrint && this.appReceiptsProviderType !== ReceiptProviderTypeEnum.CLOUD ? ReceiptTypeEnum.NONE : ReceiptTypeEnum.RECEIPT
        const payload = {
          autoPrint  : autoPrint,
          receiptType: docType,
          receiptData: null,
          printNotify: autoPrint ? null : false,
          emailNotify: autoPrint ? null : false
        }
        this.onCompleteOrderEdit(payload)
      }
    },

    onCompleteOrderAfterPaymentButtonClick (data) {
      const autoPrint = data?.autoPrint ?? true
      const docType = data?.receiptType || ReceiptTypeEnum.RECEIPT
      const payload = {
        autoPrint     : autoPrint,
        receiptType   : docType,
        receiptData   : data?.receiptData || null,
        printNotify   : data?.printNotify ?? null,
        emailNotify   : data?.emailNotify ?? null,
        emailAddresses: data?.emailAddresses ?? []
      }
      this.onCompleteOrderEdit(payload)
    },

    onSaveOrder () {
      if (!this.isFromCancelOrderButton) {
        this.isFromCancelOrderButton = false
        this.cartUpdate('saveOrderEdit')

        if (this.routeNext) {
          this.routeNext()
        }
      } else {
        this.isFromCancelOrderButton = false
      }

      window.callAS(window.SocketCommand.Order.Counts, {})
      this.cdsChannelMessageSend({
        Action : 'cart:save',
        Payload: null
      })
    },

    onCompleteOrderEdit (data) {
      const autoPrint = data?.autoPrint ?? true

      const payload = {
        autoPrint     : autoPrint,
        receiptType   : data?.receiptType || ReceiptTypeEnum.RECEIPT,
        receiptData   : data?.receiptData || null,
        printNotify   : data?.printNotify ?? null,
        emailNotify   : data?.emailNotify ?? null,
        emailAddresses: data?.emailAddresses ?? []
      }

      if (!this.posCart.Items.length) {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Pos.Pos.Notification.NoProduct'),
          timeout: 2000,
          type   : 'error',
          icon   : 'warning'
        })
        return
      }

      this.completeOrderButtonEnabled = false

      if (this.isTableOrder) {
        this.tableOrderComplete(payload)
      } else {
        this.cartOrderComplete(payload)
      }
    },

    showSelectOrderTypeTimeDialog () {
      this.selectOrderTypeTimeDialogVisible = true
    },

    showSelectCouponDialog (item) {
      this.selectedMenuItem = item || null

      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.PosDiscount) || this.userNeedsPinPermission(PinManagerEnum.TableDiscount), () => {
        this.selectCouponDialogVisible = true
      }, 'select-coupon')
    },

    showSelectCouponForMenuItemDialog (item) {
      if (!item || !this.couponPerMenuItemEnabled) return
      this.showSelectCouponDialog(item)
    },

    showSelectTipDialog () {
      this.selectTipDialogVisible = true
    },

    showSelectExtraChargeDialog () {
      this.selectExtraChargeDialogVisible = true
    },

    showCancelOrderDialog () {
      this.$bus.$emit('cart:show-cancel-order-dialog', { mustRefund: !this.canCancelOrSaveOrder })
    },

    showCustomerSearchDialog (searchTerms = '') {
      this.$bus.$emit('cart:show-customer-search-dialog', searchTerms)
    },

    showCustomerAddDialog () {
      this.$bus.$emit('cart:show-customer-add-dialog')
    },

    showCustomerSelectAddressDialog () {
      this.$bus.$emit('cart:show-customer-select-address-dialog')
    },

    onPaymentTransactionResult (data) {
      if (parseInt(data?.cart_id) !== parseInt(this.posCart.Id)) return

      this.$bus.$emit('cart:cart-update', window.SocketCommand.Order.Payment.Transaction.CartResult)
    },

    onResize () {
      const cart = document.getElementById('cart')
      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const totalsHeight = document.getElementById('cartTotals') ? document.getElementById('cartTotals').offsetHeight : 0
      const cartToolbarHeight = document.getElementById('cartToolbar') ? document.getElementById('cartToolbar').offsetHeight : 0

      if (cart) {
        if (this.$vuetify.breakpoint.xsOnly) {
          cart.style.height = (innerHeight - totalsHeight - cartToolbarHeight - 3) + 'px'
        } else {
          cart.style.height = (innerHeight - headerHeight - footerHeight - totalsHeight - cartToolbarHeight - 3) + 'px'
        }
      }
    }
  }
}
</script>

<style scoped>
.divTable {
  display : table;
  width   : 100%;
}

.divTableRow {
  display : table-row;
}

.divTableCell {
  border-collapse : collapse;
  display         : table-cell;
  padding-top     : 0;
  padding-bottom  : 0;
}
</style>
