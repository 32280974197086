<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ $t('Waiter.SelectWaiterStaffDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $tc('Waiter.Title', 0) }}
            </div>

            <div class="text-caption">
              {{ $t('Waiter.SelectWaiterStaffDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <div
            v-if="viewData.length <= 0"
            class="text-center grey--text"
          >
            <v-icon
              color="grey lighten-2"
              size="128"
            >
              {{ $t('Waiter.SelectWaiterStaffDialog.Toolbar.Icon') }}
            </v-icon>

            <div class="text-h6 mb-2">
              {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Title') }}
            </div>

            <div class="text-subtitle-1">
              {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Subtitle.Please') }}

              <template v-if="posUserHasComponentPermission('WaiterStaff', 'MANAGE') && !userIsWaiter && $route.name !== 'WaiterStaff'">
                <router-link :to="{name: 'WaiterStaff'}">
                  {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Subtitle.Open') }}
                </router-link>
              </template>
              <template v-else>
                {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Subtitle.Open') }}
              </template>

              {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Subtitle.ShiftOr') }}

              <template v-if="posUserHasComponentPermission('WaiterStaff', 'MANAGE') && !userIsWaiter && $route.name !== 'WaiterStaff'">
                <router-link :to="{name: 'WaiterStaff'}">
                  {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Subtitle.Create') }}
                </router-link>
              </template>
              <template v-else>
                {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Subtitle.Create') }}
              </template>

              {{ $t('Waiter.SelectWaiterStaffDialog.Result.NoWaiterStaff.Subtitle.WaiterStaff') }}
            </div>
          </div>

          <v-container
            v-else
            class="pa-0"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <v-list
                  two-line
                  class="pa-0"
                >
                  <template v-for="(item) in viewData">
                    <v-list-item
                      :key="`list-item-${item.Id}`"
                      :disabled="!item.HasActiveShift"
                      :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-3']"
                      ripple
                      @click="setWaiterStaff(item)"
                    >
                      <v-list-item-avatar size="54">
                        <v-icon
                          :color="item.HasActiveShift ? 'light-green' : 'grey lighten-1'"
                          size="54"
                        >
                          add_circle
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="grey--text text--darken-2">
                        <v-list-item-title class="text-left">
                          {{ item.Firstname }} {{ item.Lastname }}
                        </v-list-item-title>

                        <v-list-item-subtitle v-if="item.Phone || item.Email">
                          <span class="grey--text">
                            {{ item.Phone }} {{ item.Email }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      :key="`list-item-divider-${item.Id}`"
                      class="mb-1"
                    />
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'SelectWaiterStaffDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    selectedWaiterStaff: {
      type   : Object,
      default: undefined
    },
    excludeWaiterStaffIds: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {
    viewData () {
      const waiters = window.Vue?.waiterStaffItems
        ?.filter(waiterStaff => waiterStaff.HasActiveShift)
        ?.filter(waiterStaff => this.excludeWaiterStaffIds && this.excludeWaiterStaffIds?.length ? !!this.excludeWaiterStaffIds?.find(id => parseInt(id) !== parseInt(waiterStaff.Id)) : true)

      return waiters
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    currentWaiterStaff: {
      get () {
        return this.selectedWaiterStaff
      },
      set (val) {
        this.$emit('update:selectedWaiterStaff', val)
      }
    }
  },
  methods: {
    setWaiterStaff (waiterStaff) {
      if (!waiterStaff.HasActiveShift) return

      this.currentWaiterStaff = waiterStaff

      this.$emit('waiter-staff-selected', waiterStaff)

      this.isVisible = false
    },
    onCancelButtonClick () {
      this.currentWaiterStaff = null
      this.isVisible = false
    }
  }
}
</script>
