<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="px-2"
          height="80"
          max-height="80"
        >
          <v-avatar color="indigo">
            <v-icon dark>
              {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.Icon`) }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.Title`) }}
            </div>

            <div class="text-caption grey--text">
              {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.Subtitle`) }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-progress-linear
          v-if="loading"
          color="secondary"
          height="2"
          indeterminate
          class="pa-0 ma-0"
        />

        <v-divider />

        <v-card-text
          v-if="table && area"
          class="fill-height pa-3"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <!-- Source Table -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid

                  :class="{'pr-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.SourceTable`) }}
                      </h5>
                    </v-col>
                    <v-col cols="12">
                      <v-container
                        class="ma-0 pa-0"
                        fluid
                      >
                        <v-row

                          style="min-height: 85px;"
                        >
                          <v-col cols="12">
                            <table-list-tile
                              :table="table"
                              :children-tables="table.ChildrenTables"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <!-- Target Waiter -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid

                  :class="{'pl-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row dense>
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.TargetWaiter`) }}
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="ma-0 pa-0"
                        fluid
                      >
                        <v-row

                          style="min-height: 85px;"
                        >
                          <v-col
                            v-if="targetWaiter"
                            cols="12"
                          >
                            <v-list-item
                              class="grey lighten-3"
                              style="height: 126px;"
                              ripple
                              @click="onSelectTargetWaiterStaff"
                            >
                              <v-list-item-avatar size="54">
                                <v-icon
                                  color="light-green"
                                  size="54"
                                >
                                  login
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content class="grey--text text--darken-2">
                                <v-list-item-title class="text-left">
                                  {{ targetWaiter.Firstname }} {{ targetWaiter.Lastname }}
                                </v-list-item-title>

                                <v-list-item-subtitle v-if="targetWaiter.Phone">
                                  <span class="grey--text">
                                    {{ targetWaiter.Phone }}
                                  </span>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle v-if="targetWaiter.Email">
                                  <span class="grey--text">
                                    {{ targetWaiter.Email }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col
                            v-else
                            cols="12"
                          >
                            <v-btn
                              outlined
                              color="grey"
                              block
                              class="ma-0"
                              style="height: 126px;"
                              @click="onSelectTargetWaiterStaff"
                            >
                              {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.SelectWaiter`).toLocaleUpperCase($i18n.locale) }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col cols="12">
                <div
                  v-if="table.HasItemsPaid"
                  class="mt-4"
                >
                  <v-checkbox
                    v-model="withPayments"
                    :label="$t(`Restaurant.Tables.Dialog.ChangeWaiter.WithPayments`)"
                    color="success"
                    hide-details
                    @change="onCheckboxClick"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            :disabled="loadingMove || !targetWaiter"
            :loading="loadingMove"
            class="blue white--text elevation-0"
            @click="onMoveSelectedButtonClick"
          >
            {{ $t('Common.Button.Move') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <select-waiter-staff-dialog
      :visible.sync="selectWaiterStaffDialogVisible"
      :selected-waiter-staff.sync="targetWaiter"
      :exclude-waiter-staff-ids="excludeWaiterStaffIds"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Tables.Dialog.ConfirmChangeWaiter.Content')"
      :html-title="$t('Restaurant.Tables.Dialog.ConfirmChangeWaiter.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Tables.Dialog.ConfirmChangeWaiter.Notification')"
      :visible.sync="confirmChangeWaiterDialogVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      no-button-event="on-confirm-cancel"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-yes"
      cancel-button-event="on-confirm-cancel"
      @on-confirm-yes="confirmYes"
      @on-confirm-cancel="confirmCancel"
    />
  </v-row>
</template>

<script>
import TableListTile           from '@/components/restaurant/tables/tables/TableListTile'
import ConfirmDialog           from '@/components/common/ConfirmDialog'
import SelectWaiterStaffDialog from '@/components/waiters/SelectWaiterStaffDialog.vue'
import Auth                    from '@/mixins/auth'
import PinManagerEnum          from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'TableChangeWaiterDialog',
  components: {
    SelectWaiterStaffDialog,
    ConfirmDialog,
    TableListTile
  },
  mixins: [Auth],
  props : {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      loading                         : false,
      loadingMove                     : false,
      selectWaiterStaffDialogVisible  : false,
      confirmChangeWaiterDialogVisible: false,
      withPayments                    : false,
      targetWaiter                    : null,
      requestData                     : null
    }
  },
  computed: {
    excludeWaiterStaffIds () {
      return this.table ? [this.table.StaffId] : []
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$bus.$on(window.SocketCommand.Table.ChangeWaiter, this.onChangeWaiterResponse)
      } else {
        this.$bus.$off(window.SocketCommand.Table.ChangeWaiter, this.onChangeWaiterResponse)
        this.requestData = null
        this.targetWaiter = null
      }
    }
  },
  methods: {
    onCheckboxClick (withPayments) {
      this.$nextTick(() => {
        if (this.userNeedsPinPermission(PinManagerEnum.MovePayment) && withPayments) {
          this.withPayments = false

          this.$bus.$emit('show-manager-pin-dialog', {
            action  : 'table-change-waiter-move-payments',
            payload : null,
            callback: () => {
              this.withPayments = true
            }
          })
        }
      })
    },

    onSelectTargetWaiterStaff () {
      this.selectWaiterStaffDialogVisible = true
    },

    onMoveSelectedButtonClick () {
      if (!this.table || !this.targetWaiter) return
      this.loadingMove = true

      this.requestData = {
        TableId     : this.table.Id,
        StaffId     : this.targetWaiter.Id,
        WithPayments: this.withPayments
      }

      this.confirmChangeWaiterDialogVisible = true
    },

    confirmYes () {
      this.requestData.Close = true
      window.callAS(window.SocketCommand.Table.ChangeWaiter, this.requestData)
    },

    confirmCancel () {
      this.loadingMove = false
    },

    onChangeWaiterResponse (data) {
      if (data.status === 'success') {
        this.$emit('save-success')
        this.onCancelButtonClick()
      } else {
        this.loading = false
        this.loadingMove = false
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.loadingMove = false
      this.withPayments = false
      this.requestData = null
      this.targetWaiter = null
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile {
  height : 126px;
}
</style>
