import BaseModel                 from '../../../lib/data/Models/BaseModel'
import PaymentFeeDomainValidator from '@/api/Validations/settings/PaymentFeeDomainValidator'

export default class PaymentFeeDomainModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.id = rawData.id ?? ''
    this.active = rawData.active ?? false
    this.factor = rawData.factor ?? 0
    this.fee = rawData.fee ?? 0
    this.title = rawData.title ?? ''

    this.validator = new PaymentFeeDomainValidator(this)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */
}
