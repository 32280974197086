<template>
  <div
    v-if="item"
    class="d-inline-block mx-1"
  >
    <template v-for="(payment, index) in appConfig.PAYMENT_TYPES">
      <template v-if="parseInt(item.SourceTypeId) === SourceTypeEnum.TABLE && item.Details.Table && item.Details.Table.Transactions && (item.Details.Table.Transactions.cod || item.Details.Table.Transactions.card)">
        <v-chip
          v-if="item?.Details?.Table?.Transactions && item?.Details?.Table?.Transactions.cod && payment.code === PaymentTypeEnum.CASH"
          :key="payment.code"
          :color="payment.color1"
          :small="dense"
          class="white--text elevation-0 mr-1 mb-1 pl-0 text-caption"
          style="text-transform: uppercase;"
          :style="`min-width: ${chipMinWidth}px;`"
          label
        >
          <v-avatar
            :color="payment.color2"
            class="mr-2"
            :style="avatarSize"
            tile
          >
            <v-icon
              class="white--text"
              :size="iconSize"
            >
              {{ appCurrency.Icon }}
            </v-icon>
          </v-avatar>

          <div
            style="width: 100%;"
            class="text-center"
          >
            {{ item.Details.Table.Transactions.cod | currency }} {{ $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) }}
          </div>
        </v-chip>

        <v-chip
          v-if="item.Details.Table.Transactions && item.Details.Table.Transactions.card && payment.code === PaymentTypeEnum.CARD"
          :key="payment.code"
          :color="payment.color1"
          :small="dense"
          class="white--text elevation-0 mr-1 mb-1 pl-0 text-caption"
          style="text-transform: uppercase;"
          :style="`min-width: ${chipMinWidth}px;`"
          label
        >
          <v-avatar
            :color="payment.color2"
            class="mr-2"
            :style="avatarSize"
            tile
          >
            <v-icon
              class="white--text"
              :size="iconSize"
            >
              {{ payment.icon }}
            </v-icon>
          </v-avatar>

          <div
            style="width: 100%;"
            class="text-center"
          >
            {{ item.Details.Table.Transactions.card | currency }} {{ $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) }}
          </div>
        </v-chip>
      </template>

      <template v-else>
        <template v-if="hasPaymentTransactions">
          <template v-for="(transaction, idx) in paymentTransactions">
            <v-chip
              v-if="transaction.PaymentType === payment.code"
              :key="`payment-transaction-${index}-${idx}`"
              :color="payment.color1"
              :small="dense"
              class="white--text elevation-0 mr-1 mb-1 pl-0 text-caption"
              style="text-transform: uppercase;"
              :style="`min-width: ${chipMinWidth}px;`"
              label
            >
              <v-avatar
                :color="payment.color2"
                class="mr-2"
                :style="avatarSize"
                tile
              >
                <v-icon
                  class="white--text"
                  :size="iconSize"
                >
                  {{ payment.code === PaymentTypeEnum.CASH ? appCurrency.Icon : payment.icon }}
                </v-icon>
              </v-avatar>

              <div
                style="width: 100%;"
                class="text-center"
              >
                {{ transaction.Amount | currency }} {{ payment.code === PaymentTypeEnum.CASH ? $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) : payment.code === PaymentTypeEnum.CARD ? $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) : payment.name.toLocaleUpperCase($i18n.locale) }}
              </div>
            </v-chip>
          </template>
        </template>

        <template v-else>
          <v-chip
            v-if="item.Payment === payment.code"
            :key="payment.code"
            :color="payment.color1"
            :small="dense"
            class="white--text elevation-0 mr-1 mb-1 pl-0 text-caption"
            style="text-transform: uppercase;"
            :style="`min-width: ${chipMinWidth}px;`"
            label
          >
            <v-avatar
              :color="payment.color2"
              class="mr-2"
              :style="avatarSize"
              tile
            >
              <v-icon
                class="white--text"
                :size="iconSize"
              >
                {{ payment.code === PaymentTypeEnum.CASH ? appCurrency.Icon : payment.icon }}
              </v-icon>
            </v-avatar>

            <div
              style="width: 100%;"
              class="text-center"
            >
              {{ (item.Price || item.OrderTotal) | currency }} {{ payment.code === PaymentTypeEnum.CASH ? $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) : payment.code === PaymentTypeEnum.CARD ? $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) : payment.name.toLocaleUpperCase($i18n.locale) }}
            </div>
          </v-chip>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import AppData         from '@/mixins/appdata'
import SourceTypeEnum  from '@/api/Enums/SourceTypeEnum'
import PaymentTypeEnum from '@/api/Enums/PaymentTypeEnum'

export default {
  name      : 'OrderPaymentsChip',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : {
    item: {
      type   : Object,
      default: undefined
    },

    minWidth: {
      type   : [Number, String],
      default: 150
    },

    dense: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    PaymentTypeEnum () {
      return PaymentTypeEnum
    },
    SourceTypeEnum () {
      return SourceTypeEnum
    },

    chipMinWidth () {
      return this.minWidth.toString().replaceAll('px', '')
    },
    iconSize () {
      return this.dense ? 18 : 22
    },
    avatarSize () {
      return `width: ${ this.dense ? 32 : 46 }px !important; height: 32px !important;`
    },

    hasPaymentTransactions () {
      const paymentTransactions = this.item?.PaymentTransactions || []

      return paymentTransactions.length > 0
    },
    paymentTransactions () {
      const transactions = []
      const paymentTransactions = this.item?.PaymentTransactions || []
      const transactionsGroupedByPaymentType = Object.groupBy(paymentTransactions, trx => trx.PaymentType)

      Object.keys(transactionsGroupedByPaymentType).forEach(key => {
        const transactionsTotal = transactionsGroupedByPaymentType[key]?.reduce((a, b) => a + parseFloat(b.Amount) || 0, 0) || 0

        transactions.push({
          Amount     : transactionsTotal.toFixed(2),
          PaymentType: key
        })
      })

      return transactions
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-chip > .v-chip__content {
  width : 100%;
}
</style>
