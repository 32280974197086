<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1085"
      persistent
      scrollable
    >
      <v-card v-if="table && order">
        <v-toolbar
          flat
          height="80"
          max-height="80"
          tabs
        >
          <v-avatar color="orange">
            <v-icon dark>
              mdi-receipt-text-check-outline
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Common.Button.ManageDocuments') }}
            </div>

            <div class="text-caption grey--text">
              <div>{{ $tc('Order.Title', 1) }} #{{ order.OrderNumber }} -&nbsp;<span class="grey--text text--darken-1">{{ order.Time }}</span></div>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <receipts-table
            :cancel="userCanViewReceipt"
            :email="appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
            :print="appMachineHasReceiptPrinters"
            :receipts="receiptsList"
            :orders="[]"
            :table="table"
            alternate-row-colors
          />
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DialogVisible  from '@/mixins/dialogVisible'
import ReceiptsTable  from '@/components/receipts/ReceiptsTable.vue'
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'
import NotifyTypeEnum from '@/api/Enums/NotifyTypeEnum'

export default {
  name      : 'TableOrderFiscalDocumentsDialog',
  components: { ReceiptsTable },
  mixins    : [AppData, Auth, DialogVisible],
  props     : {
    order: {
      type   : Object,
      default: undefined
    },
    table: {
      type   : Object,
      default: undefined
    },
    receipts: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },

    receiptsList () {
      const receipts = this.receipts || []
      const retVal = []

      receipts.forEach(receipt => {
        const relatedReceipts = receipt?.Related?.Receipts || []

        if (receipt.Timestamp === this.order.Timestamp) {
          retVal.push(receipt)
          relatedReceipts.forEach(relatedReceipt => {
            const item = receipts.find(r => r.Id === relatedReceipt.Id)
            if (item) retVal.push(item)
          })
        }
      })

      return retVal
    }
  },
  watch  : {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-toolbar__extension {
  padding : 0 12px !important;
}
</style>
