export default {
  methods: {
    $emitAction (action, reservation) {
      if (!action || !reservation) return
      this.$bus.$emit(action, reservation)
      this.isVisible = false
    },

    // Table Reservations Menu Actions

    onTableReservationFinishedClick (reservation) {
      this.$emitAction('click:table-reservation-finished', reservation)
    },

    onTableReservationArrivedClick (reservation) {
      this.$emitAction('click:table-reservation-arrived', reservation)
    },

    onTableReservationPartiallyArrivedClick (reservation) {
      this.$emitAction('click:table-reservation-partially-arrived', reservation)
    },

    onTableReservationEditClick (reservation) {
      this.$emitAction('click:table-reservation-edit', reservation)
    },

    onTableReservationDeleteClick (reservation) {
      this.$emitAction('click:table-reservation-delete', reservation)
    },

    onTableReservationCancelClick (reservation) {
      this.$emitAction('click:table-reservation-cancel', reservation)
    }
  }
}
