<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Order.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Order.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Order.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Order.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-form
          ref="settingsForm"
          v-model="settingsFormValid"
          autocomplete="off"
          @submit.prevent
        >
          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Order.Field.GeneralOption.Icon') }}
              </v-icon>

              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Order.Field.GeneralOption.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderTips.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.GeneralOption.OrderTips.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrderTips"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.OrderTips ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col cols="12">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderStatus.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.GeneralOption.OrderStatus.Subtitle') }}</span>
                  </v-col>

                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-select
                      v-model="viewData.DefaultOrderStatus"
                      :items="$te('Settings.Payment.Edit.Field.OrderStatus.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatus.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatus.Items') : []"
                      :label="$t('Settings.Payment.Edit.Field.OrderStatus.Label')"
                      filled
                      class="pt-2"
                      clearable
                      hide-details
                      item-text="Name"
                      item-value="Id"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    cols="12"
                  >
                    <v-select
                      v-model="viewData.DefaultOrderStatusPos"
                      :items="$te('Settings.Payment.Edit.Field.OrderStatusPos.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatusPos.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatusPos.Items') : []"
                      :label="$t('Settings.Payment.Edit.Field.OrderStatusPos.Label')"
                      filled
                      class="pt-2"
                      clearable
                      hide-details
                      item-text="Name"
                      item-value="Id"
                    />
                  </v-col>

                  <v-col cols="12" />
                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderStatusPerPaymentMethod.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.GeneralOption.OrderStatusPerPaymentMethod.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.DefaultOrderStatusOverride"
                      inset
                      class="ma-0"
                      :disabled="!userCanManage"
                      :false-value="true"
                      :label="viewData.DefaultOrderStatusOverride ? $t('Common.Button.Toggle.Inactive') : $t('Common.Button.Toggle.Active')"
                      :true-value="false"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <template v-if="!viewData.DefaultOrderStatusOverride && activePaymentMethods.length">
                    <v-col cols="12">
                      <div>
                        <template v-for="(paymentMethod, index) in activePaymentMethods">
                          <div
                            :key="`online-payment-method-${paymentMethod.Code}-list-tile`"
                            class="py-4"
                          >
                            <div class="d-inline-flex">
                              <v-avatar :color="paymentMethod.Color1">
                                <v-icon dark>
                                  {{ paymentMethod.Icon }}
                                </v-icon>
                              </v-avatar>

                              <div class="d-inline-block pl-2">
                                <div style="font-size: 16px;">
                                  {{ paymentMethod.ModuleTitle }}
                                </div>
                                <div
                                  class="grey--text"
                                  style="font-size: 14px;"
                                >
                                  {{ getTranslatedField(paymentMethod, 'Name', 'Lang', true, 'Title') }}
                                </div>
                              </div>
                            </div>

                            <div
                              v-if="userCanManage"
                              class="mt-4"
                            >
                              <v-container
                                fluid
                                class="pa-0"
                              >
                                <v-row dense>
                                  <v-col
                                    sm="6"
                                    cols="12"
                                  >
                                    <v-select
                                      v-if="paymentMethod.Site"
                                      v-model="paymentMethod.OrderStatus"
                                      :items="$te('Settings.Payment.Edit.Field.OrderStatus.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatus.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatus.Items') : []"
                                      :label="$t('Settings.Payment.Edit.Field.OrderStatus.Label')"
                                      filled
                                      clearable
                                      hide-details
                                      item-text="Name"
                                      item-value="Id"
                                    />
                                  </v-col>
                                  <v-col
                                    sm="6"
                                    cols="12"
                                  >
                                    <v-select
                                      v-if="paymentMethod.Pos"
                                      v-model="paymentMethod.OrderStatusPos"
                                      :items="$te('Settings.Payment.Edit.Field.OrderStatusPos.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatusPos.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatusPos.Items') : []"
                                      :label="$t('Settings.Payment.Edit.Field.OrderStatusPos.Label')"
                                      filled
                                      clearable
                                      hide-details
                                      item-text="Name"
                                      item-value="Id"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </div>
                          </div>
                          <v-divider
                            v-if="index < activePaymentMethods.length-1"
                            :key="`online-payment-method-${paymentMethod.Code}-divider`"
                          />
                        </template>
                      </div>
                    </v-col>
                  </template>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col cols="12">
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Title') }}
                    </h5>
                  </v-col>
                  <v-col>
                    <div class="text-caption py-2">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.ShowOrderType') }}
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.ShowOrderTypes"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.ShowOrderTypes ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="blue"
                      hide-details
                    />
                  </v-col>
                  <v-col
                    v-if="viewData.ShowOrderTypes"
                    cols="12"
                  >
                    <h5 class="mt-2">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Label.DefaultTypeWebsite') }}
                    </h5>
                    <v-btn-toggle
                      v-model="viewData.DefaultOrderType"
                      class="lighten-2 elevation-0"
                      mandatory
                      style="width: 100%;"
                    >
                      <v-btn
                        :value="1"
                        :style="viewData.DefaultOrderType === 1 ? 'opacity: 1' : 'opacity: 0.35'"
                        class="purple white--text elevation-0 mr-0"
                        large
                        style="width: 50%; font-size: 13px !important;"
                      >
                        <span>{{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Delivery.Title') }}</span>
                        <v-icon
                          class="white--text"
                          right
                        >
                          {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Delivery.Icon') }}
                        </v-icon>
                      </v-btn>

                      <v-btn
                        :value="2"
                        :style="viewData.DefaultOrderType === 2 ? 'opacity: 1' : 'opacity: 0.35'"
                        class="blue darken-3 white--text elevation-0 ml-0"
                        large
                        style="width: 50%; font-size: 13px !important;"
                      >
                        <span>{{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Collection.Title') }}</span>
                        <v-icon
                          class="white--text"
                          right
                        >
                          {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Collection.Icon') }}
                        </v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>

                  <v-col cols="12">
                    <h5 class="mt-2">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Label.DefaultTypePos') }}
                    </h5>
                    <v-btn-toggle
                      v-model="viewData.DefaultOrderTypePos"
                      class="lighten-2 elevation-0"
                      mandatory
                      style="width: 100%;"
                    >
                      <v-btn
                        :value="1"
                        :style="viewData.DefaultOrderTypePos === 1 ? 'opacity: 1' : 'opacity: 0.35'"
                        class="purple white--text elevation-0 mr-0"
                        large
                        style="width: 50%; font-size: 13px !important;"
                      >
                        <span>{{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Delivery.Title') }}</span>
                        <v-icon
                          class="white--text"
                          right
                        >
                          {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Delivery.Icon') }}
                        </v-icon>
                      </v-btn>

                      <v-btn
                        :value="2"
                        :style="viewData.DefaultOrderTypePos === 2 ? 'opacity: 1' : 'opacity: 0.35'"
                        class="blue darken-3 white--text elevation-0 ml-0"
                        large
                        style="width: 50%; font-size: 13px !important;"
                      >
                        <span>{{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Collection.Title') }}</span>
                        <v-icon
                          class="white--text"
                          right
                        >
                          {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.Button.Collection.Icon') }}
                        </v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>

                  <v-col cols="12">
                    <h5 class="mt-2">
                      {{ $t('Settings.Order.Field.GeneralOption.OrderTypes.OrderTotalMin.Title') }}
                    </h5>

                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row dense>
                        <v-col
                          sm="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model="viewData.OrderTotalDeliveryMin"
                            :label="$t('Settings.Order.Field.GeneralOption.OrderTypes.OrderTotalMin.OrderTotalDeliveryMin')"
                            filled
                            clearable
                            hide-details
                            type="number"
                            min="0"
                            step="any"
                          />
                        </v-col>
                        <v-col
                          sm="6"
                          cols="12"
                        >
                          <v-text-field
                            v-model="viewData.OrderTotalCollectionMin"
                            :label="$t('Settings.Order.Field.GeneralOption.OrderTypes.OrderTotalMin.OrderTotalCollectionMin')"
                            filled
                            clearable
                            hide-details
                            type="number"
                            min="0"
                            step="any"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.GeneralOption.DailyOrders.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.GeneralOption.DailyOrders.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.OrdersDailyOverride"
                      inset
                      class="ma-0"
                      :disabled="!userCanManage"
                      :label="viewData.OrdersDailyOverride ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-menu
                      v-model="menuOrdersDailyOpenVisible"
                      :close-on-content-click="false"
                      :disabled="!viewData.OrdersDailyOverride"
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{on}">
                        <v-text-field
                          v-model="viewData.OrdersDailyOpen"
                          :disabled="!viewData.OrdersDailyOverride"
                          :label="$t('Settings.Order.Field.GeneralOption.DailyOrders.From')"
                          :prepend-icon="$t('Settings.Order.Field.GeneralOption.DailyOrders.Icon')"
                          readonly
                          required
                          v-on="on"
                        />
                      </template>

                      <v-time-picker
                        v-model="viewData.OrdersDailyOpen"
                        :disabled="!viewData.OrdersDailyOverride"
                        :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                        first-day-of-week="1"
                        format="24hr"
                        scrollable
                        @input="menuOrdersDailyOpenVisible = false"
                      />
                    </v-menu>

                    <v-menu
                      v-model="menuOrdersDailyCloseVisible"
                      :close-on-content-click="false"
                      :disabled="!viewData.OrdersDailyOverride"
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{on}">
                        <v-text-field
                          v-model="viewData.OrdersDailyClose"
                          :disabled="!viewData.OrdersDailyOverride"
                          :label="$t('Settings.Order.Field.GeneralOption.DailyOrders.To')"
                          :prepend-icon="$t('Settings.Order.Field.GeneralOption.DailyOrders.Icon')"
                          readonly
                          required
                          v-on="on"
                        />
                      </template>

                      <v-time-picker
                        v-model="viewData.OrdersDailyClose"
                        :disabled="!viewData.OrdersDailyOverride"
                        :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                        first-day-of-week="1"
                        format="24hr"
                        scrollable
                        @input="menuOrdersDailyCloseVisible = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-card flat>
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Order.Field.PosOption.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Order.Field.PosOption.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col cols="12">
                    <v-expansion-panels
                      accordion
                      flat
                    >
                      <v-expansion-panel class="elevation-0">
                        <v-expansion-panel-header class="py-0">
                          <div>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.PosOption.SourceTypes.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.SourceTypes.Subtitle') }}</span>
                          </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <div class="divTable ml-4 mt-4">
                            <template v-for="(sourceType, index) in sourceTypesList">
                              <div
                                v-if="sourceType.Editable"
                                :key="`receipt-${sourceType.Id}-${index}`"
                                :class="{'red lighten-5': !!sourceType.DeletedAt && !isDarkMode, 'red darken-2': !!sourceType.DeletedAt && isDarkMode}"
                                class="divTableRow"
                              >
                                <div
                                  class="divTableCell pa-2 text-left"
                                  style="width: 48px;"
                                >
                                  <v-avatar
                                    v-if="sourceType.Avatar.Icon || sourceType.Avatar.Img"
                                    :color="sourceType.Avatar.Color"
                                    size="48"
                                  >
                                    <v-icon
                                      v-if="sourceType.Avatar.Icon"
                                      dark
                                    >
                                      {{ sourceType.Avatar.Icon }}
                                    </v-icon>

                                    <img
                                      v-else-if="sourceType.Avatar.Img"
                                      :src="sourceType.Avatar.Img"
                                      height="48"
                                      width="48"
                                    >
                                  </v-avatar>
                                </div>

                                <div class="divTableCell pa-2 text-left">
                                  <div>
                                    <div>
                                      {{ sourceType.Title }}
                                    </div>
                                    <div class="text-caption grey--text">
                                      {{ sourceType.Description }} / {{ sourceType.SubTitle }}
                                    </div>
                                  </div>
                                </div>

                                <div class="divTableCell pa-2 text-right">
                                  <v-switch
                                    v-model="sourceType.Active"
                                    inset
                                    :label="sourceType.Active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                                    class="ma-0 d-inline-block"
                                    color="success"
                                    hide-details
                                  />
                                </div>
                              </div>
                            </template>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col cols="12">
                    <v-expansion-panels
                      accordion
                      flat
                    >
                      <v-expansion-panel class="elevation-0">
                        <v-expansion-panel-header class="py-0">
                          <div>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.PosOption.OrderComments.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.OrderComments.Subtitle') }}</span>
                          </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <div class="divTable mt-4">
                            <div class="divTableHeading">
                              <div class="divTableRow">
                                <div class="divTableHead">
                                  &nbsp;
                                </div>

                                <div class="divTableHead">
                                  {{ $t('Settings.Order.Field.PosOption.OrderComments.Table.Header.Comment') }}
                                </div>

                                <div class="divTableHead text-right">
                                  <v-btn
                                    class="pa-2"
                                    color="primary"
                                    text
                                    @click="addComment"
                                  >
                                    <v-icon left>
                                      {{ $t('Settings.Order.Field.PosOption.OrderComments.Button.Add.Icon') }}
                                    </v-icon>&nbsp;
                                    {{ $t('Settings.Order.Field.PosOption.OrderComments.Button.Add.Title') }}
                                  </v-btn>
                                </div>
                              </div>
                            </div>

                            <draggable
                              v-model="viewData.OrderComments"
                              class="divTableBody"
                              handle=".my-handle"
                            >
                              <template v-for="(comment, index) in orderComments">
                                <div
                                  :key="`order-comment-${index}`"
                                  class="divTableRow"
                                >
                                  <div class="divTableCell">
                                    <v-icon
                                      class="my-handle"
                                      style="cursor: move;"
                                    >
                                      drag_handle
                                    </v-icon>
                                  </div>

                                  <div class="divTableCell pl-0 text-left">
                                    <v-text-field
                                      v-model="comment.Title"
                                      :placeholder="$t('Settings.Order.Field.PosOption.OrderComments.Table.Header.Comment')"
                                      filled
                                      clearable
                                      flat
                                      full-width
                                      hide-details
                                      single-line
                                    />
                                  </div>

                                  <div class="divTableCell pa-2 text-right">
                                    <v-switch
                                      v-model="comment.Active"
                                      inset
                                      :label="comment.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      class="ma-0 d-inline-block mr-6"
                                      color="success"
                                      hide-details
                                    />

                                    <v-btn
                                      class="red ma-0 mb-2 mr-1"
                                      dark
                                      icon
                                      small
                                      @click="removeComment(index)"
                                    >
                                      <v-icon small>
                                        close
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                              </template>
                            </draggable>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.VoipAddOrderDialog.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.VoipAddOrderDialog.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn-toggle
                      v-model="viewData.VoipAddOrderDialog"
                      :disabled="!userCanManage"
                      mandatory
                      color="success"
                    >
                      <v-btn
                        text
                        value="agent"
                        :disabled="!userCanManage"
                      >
                        {{ $t('Settings.Order.Field.PosOption.VoipAddOrderDialog.Item.Agent').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>

                      <v-btn
                        text
                        value="everyone"
                        :disabled="!userCanManage"
                      >
                        {{ $t('Settings.Order.Field.PosOption.VoipAddOrderDialog.Item.Everyone').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>

                      <v-btn
                        text
                        value="none"
                        :disabled="!userCanManage"
                      >
                        {{ $t('Settings.Order.Field.PosOption.VoipAddOrderDialog.Item.None').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Enabled.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Enabled.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.MenuOptionsListExpandableEnabled"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.MenuOptionsListExpandableEnabled ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <template v-if="viewData.MenuOptionsListExpandableEnabled">
                    <v-col cols="12">
                      <v-divider class="" />
                    </v-col>

                    <v-col cols="12">
                      <v-card flat>
                        <v-card-text class="pa-0 pl-4">
                          <v-container
                            fluid
                            class="pa-0"
                          >
                            <v-row
                              dense
                              align="center"
                            >
                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Mobile.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Mobile.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.MenuOptionsListExpandableMobile"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.MenuOptionsListExpandableMobile ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="pa-0"
                                  color="success"
                                  hide-details
                                />
                              </v-col>

                              <v-col
                                class="py-0"
                                cols="12"
                              />

                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Mobile.Multiple.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Mobile.Multiple.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.MenuOptionsListExpandableMultipleMobile"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.MenuOptionsListExpandableMultipleMobile ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="ma-0"
                                  color="success"
                                  hide-details
                                />
                              </v-col>

                              <v-col cols="12">
                                <v-divider class="" />
                              </v-col>

                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Desktop.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Desktop.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.MenuOptionsListExpandableDesktop"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.MenuOptionsListExpandableDesktop ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="ma-0"
                                  color="success"
                                  hide-details
                                />
                              </v-col>

                              <v-col
                                class="py-0"
                                cols="12"
                              />

                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Desktop.Multiple.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuOptionsListExpandable.Desktop.Multiple.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <v-switch
                                  v-model="viewData.MenuOptionsListExpandableMultipleDesktop"
                                  inset
                                  :disabled="!userCanManage"
                                  :label="viewData.MenuOptionsListExpandableMultipleDesktop ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                  class="ma-0"
                                  color="success"
                                  hide-details
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.ShowSelectedMenuOptionsPos.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.ShowSelectedMenuOptionsPos.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.ShowSelectedMenuOptionsPos"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.ShowSelectedMenuOptionsPos ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.MenuItemQuickAdd.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuItemQuickAdd.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.MenuItemQuickAdd"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.MenuItemQuickAdd ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.MenuCategoriesTabsPosDesktop.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuCategoriesTabsPosDesktop.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink text-no-wrap">
                    <div>
                      <div class="d-inline-block">
                        <v-switch
                          v-model="MenuCategoriesTabsPosDesktop.Horizontal"
                          inset
                          :disabled="!userCanManage"
                          :label="$t('Common.Button.Toggle.Horizontal')"
                          class="ma-0 "
                          color="success"
                          hide-details
                          @change="onMenuCategoriesTabsPosDesktopHorizontalChange"
                        />
                      </div>

                      <div class="d-inline-block">
                        <v-switch
                          v-model="MenuCategoriesTabsPosDesktop.Vertical"
                          inset
                          :disabled="!userCanManage"
                          :label="$t('Common.Button.Toggle.Vertical')"
                          class="ma-0 ml-2"
                          color="success"
                          hide-details
                          @change="onMenuCategoriesTabsPosDesktopVerticalChange"
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.MenuCategoriesTabsPosMobile.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuCategoriesTabsPosMobile.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink text-no-wrap">
                    <div>
                      <div class="d-inline-block">
                        <v-switch
                          v-model="MenuCategoriesTabsPosMobile.Horizontal"
                          inset
                          :disabled="!userCanManage"
                          :label="$t('Common.Button.Toggle.Horizontal')"
                          class="ma-0 "
                          color="success"
                          hide-details
                          @change="onMenuCategoriesTabsPosMobileHorizontalChange"
                        />
                      </div>

                      <div class="d-inline-block">
                        <v-switch
                          v-model="MenuCategoriesTabsPosMobile.Vertical"
                          inset
                          :disabled="!userCanManage"
                          :label="$t('Common.Button.Toggle.Vertical')"
                          class="ma-0 ml-2"
                          color="success"
                          hide-details
                          @change="onMenuCategoriesTabsPosMobileVerticalChange"
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.MenuCategoriesColorWidthPos.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuCategoriesColorWidthPos.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink text-no-wrap">
                    <div class="d-inline-block">
                      <v-select
                        v-model="viewData.MenuCategoriesColorWidthPos"
                        :items=" $t('Settings.Order.Field.PosOption.MenuCategoriesColorWidthPos.Items')"
                        :label="$t('Settings.Order.Field.PosOption.MenuCategoriesColorWidthPos.Title')"
                        style="min-width: 300px;"
                        color="green lighten-1"
                        hide-details
                      />
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.MenuSearchEnabledPos.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuSearchEnabledPos.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.MenuSearchEnabledPos"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.MenuSearchEnabledPos ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.ShowMenuImages.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.ShowMenuImages.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.ShowMenuImagesPos"
                      inset
                      class="ma-0"
                      :disabled="!userCanManage"
                      :label="viewData.ShowMenuImagesPos ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <template v-if="viewData.ShowMenuImagesPos">
                    <v-col cols="12">
                      <v-divider class="" />
                    </v-col>

                    <v-col cols="12">
                      <v-card flat>
                        <v-card-text class="pa-0 pl-4">
                          <v-container
                            fluid
                            class="pa-0"
                          >
                            <v-row
                              dense
                              align="center"
                            >
                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ $t('Settings.Order.Field.PosOption.MenuListItemImageLeftPos.Title') }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuListItemImageLeftPos.Subtitle') }}</span>
                              </v-col>
                              <v-col class="shrink text-no-wrap">
                                <div>
                                  <div class="d-inline-block">
                                    <v-switch
                                      v-model="MenuListItemImagePos.Left"
                                      inset
                                      :disabled="!userCanManage"
                                      :label="$t('Common.Button.Left')"
                                      class="ma-0"
                                      color="success"
                                      hide-details
                                      @change="onMenuListItemImagePosLeftChange"
                                    />
                                  </div>

                                  <div class="d-inline-block">
                                    <v-switch
                                      v-model="MenuListItemImagePos.Right"
                                      inset
                                      :disabled="!userCanManage"
                                      :label="$t('Common.Button.Right')"
                                      class="ma-0 ml-2"
                                      color="success"
                                      hide-details
                                      @change="onMenuListItemImagePosRightChange"
                                    />
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.MenuItemCustomPrice.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.MenuItemCustomPrice.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-switch
                      v-model="viewData.MenuItemCustomPrice"
                      inset
                      :disabled="!userCanManage"
                      :label="viewData.MenuItemCustomPrice ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="success"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.BarcodeDelayBetweenScans.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.BarcodeDelayBetweenScans.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-text-field
                      v-model="viewData.BarcodeDelayBetweenScans"
                      single-line
                      hide-details
                      outlined
                      dense
                      :placeholder="$t('Settings.Order.Field.PosOption.BarcodeDelayBetweenScans.PlaceHolder')"
                      :suffix="$t('Settings.Order.Field.PosOption.BarcodeDelayBetweenScans.Suffix')"
                      type="number"
                      step="1"
                      min="1"
                      style="min-width: 300px;"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.BarcodePrefixProductUnitQuantity.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.BarcodePrefixProductUnitQuantity.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-combobox
                      v-model="viewData.BarcodePrefixProductUnitQuantity"
                      :label="$t('Settings.Order.Field.PosOption.BarcodePrefixProductUnitQuantity.PlaceHolder')"
                      :search-input.sync="AddNewBarcodePrefixQuantitySearch"
                      style="min-width: 300px;"
                      color="green lighten-1"
                      deletable-chips
                      hide-details
                      hide-selected
                      item-text="title"
                      item-value="id"
                      multiple
                      small-chips
                      type="number"
                      min="0"
                      step="1"
                    >
                      <template #no-data>
                        <v-list-item>
                          <span class="text-subtitle-1 mr-2">{{ $t('Common.Button.Add') }}: </span>

                          <v-chip
                            v-show="AddNewBarcodePrefixQuantitySearch"
                            label
                            small
                          >
                            {{ AddNewBarcodePrefixQuantitySearch }}
                          </v-chip>
                        </v-list-item>
                      </template>

                      <template #selection="{item, parent, selected}">
                        <v-chip
                          :value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item }}
                          </span>

                          <v-icon
                            small
                            @click="parent.selectItem(item)"
                          >
                            close
                          </v-icon>
                        </v-chip>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-chip
                            dark
                            label
                            small
                          >
                            {{ item }}
                          </v-chip>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.PosOption.BarcodePrefixProductUnitPrice.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.PosOption.BarcodePrefixProductUnitPrice.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <v-combobox
                      v-model="viewData.BarcodePrefixProductUnitPrice"
                      :label="$t('Settings.Order.Field.PosOption.BarcodePrefixProductUnitPrice.PlaceHolder')"
                      :search-input.sync="AddNewBarcodePrefixPriceSearch"
                      style="min-width: 300px;"
                      color="green lighten-1"
                      deletable-chips
                      hide-details
                      hide-selected
                      item-text="title"
                      item-value="id"
                      multiple
                      small-chips
                      type="number"
                    >
                      <template #no-data>
                        <v-list-item>
                          <span class="text-subtitle-1 mr-2">{{ $t('Common.Button.Add') }}: </span>

                          <v-chip
                            v-show="AddNewBarcodePrefixPriceSearch"
                            label
                            small
                          >
                            {{ AddNewBarcodePrefixPriceSearch }}
                          </v-chip>
                        </v-list-item>
                      </template>

                      <template #selection="{item, parent, selected}">
                        <v-chip
                          :value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item }}
                          </span>

                          <v-icon
                            small
                            @click="parent.selectItem(item)"
                          >
                            close
                          </v-icon>
                        </v-chip>
                      </template>

                      <template #item="{item}">
                        <v-list-item-content>
                          <v-chip
                            dark
                            label
                            small
                          >
                            {{ item }}
                          </v-chip>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12">
                    <v-divider class="my-2" />
                  </v-col>

                  <v-col>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Order.Field.SubtractStockThreshold.Title') }}
                    </h5>
                    <span class="text-caption">{{ $t('Settings.Order.Field.SubtractStockThreshold.Subtitle') }}</span>
                  </v-col>
                  <v-col class="shrink">
                    <div class="mb-1">
                      <v-text-field
                        :value="`> ${viewData.SubtractStockThreshold.Tier1.Value}`"
                        single-line
                        hide-details
                        outlined
                        dense
                        disabled
                        style="min-width: 300px;"
                      >
                        <template #prepend-inner>
                          <v-chip
                            label
                            dark
                            class="pa-1 mr-2"
                            style="height: 25px;"
                            :color="viewData.SubtractStockThreshold.Default.Color"
                          >
                            <v-icon>all_inclusive</v-icon>
                          </v-chip>
                        </template>
                      </v-text-field>
                    </div>

                    <div class="mb-1">
                      <v-text-field
                        v-model="viewData.SubtractStockThreshold.Tier1.Value"
                        single-line
                        hide-details
                        outlined
                        dense
                        type="number"
                        step="1"
                        min="1"
                        style="min-width: 300px;"
                      >
                        <template #prepend-inner>
                          <v-chip
                            label
                            dark
                            class="pa-1 mr-2"
                            style="height: 25px;"
                            :color="viewData.SubtractStockThreshold.Tier1.Color"
                          >
                            <v-icon>all_inclusive</v-icon>
                          </v-chip>
                        </template>
                      </v-text-field>
                    </div>

                    <div class="mb-1">
                      <v-text-field
                        v-model="viewData.SubtractStockThreshold.Tier2.Value"
                        single-line
                        hide-details
                        outlined
                        dense
                        type="number"
                        step="1"
                        min="1"
                        style="min-width: 300px;"
                      >
                        <template #prepend-inner>
                          <v-chip
                            label
                            dark
                            class="pa-1 mr-2"
                            style="height: 25px;"
                            :color="viewData.SubtractStockThreshold.Tier2.Color"
                          >
                            <v-icon>all_inclusive</v-icon>
                          </v-chip>
                        </template>
                      </v-text-field>
                    </div>

                    <div class="mb-1">
                      <v-text-field
                        v-model="viewData.SubtractStockThreshold.Tier3.Value"
                        single-line
                        hide-details
                        outlined
                        dense
                        type="number"
                        step="1"
                        min="1"
                        style="min-width: 300px;"
                      >
                        <template #prepend-inner>
                          <v-chip
                            label
                            dark
                            class="pa-1 mr-2"
                            style="height: 25px;"
                            :color="viewData.SubtractStockThreshold.Tier3.Color"
                          >
                            <v-icon>all_inclusive</v-icon>
                          </v-chip>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <!-- External Platforms -->
          <v-card
            flat
            class="mb-4"
          >
            <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
              <v-icon class="pr-3">
                {{ $t('Settings.Order.Field.ExternalPlatforms.Icon') }}
              </v-icon>
              <h5 class="text-body-2 font-weight-bold">
                {{ $t('Settings.Order.Field.ExternalPlatforms.Title') }}
              </h5>
            </v-card-title>

            <v-divider />

            <v-expansion-panels
              accordion
              flat
            >
              <!-- Delivery Hero -->
              <v-expansion-panel>
                <v-expansion-panel-header><h4>{{ $t('Settings.Order.Field.ExternalPlatforms.Platform.DeliveryHero') }}</h4></v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateCustomer.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateCustomer.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroAutoCreateCustomer"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroAutoCreateCustomer ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateAddress.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateAddress.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroAutoCreateAddress"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroAutoCreateAddress ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintOrder.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintOrder.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroAutoPrintOrder"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroAutoPrintOrder ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintPreparationOrder.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintPreparationOrder.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroAutoPrintPreparationOrder"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroAutoPrintPreparationOrder ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintReceipt.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintReceipt.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroAutoPrintReceipt"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroAutoPrintReceipt ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.CatalogImportUseItemPrintedName.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.CatalogImportUseItemPrintedName.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroCatalogImportUseItemPrintedName"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroCatalogImportUseItemPrintedName ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AddTip.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AddTip.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroAddTip"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroAddTip ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AddDelivery.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AddDelivery.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.DeliveryHeroAddDelivery"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.DeliveryHeroAddDelivery ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col cols="12">
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.InitOrderStatus.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.InitOrderStatus.Subtitle') }}</span>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="viewData.DeliveryHeroDefaultOrderStatus"
                              :items="$te('Settings.Payment.Edit.Field.OrderStatus.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatus.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatus.Items') : []"
                              :label="$t('Settings.Payment.Edit.Field.OrderStatus.Label')"
                              filled
                              clearable
                              hide-details
                              item-text="Name"
                              item-value="Id"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>

                <v-divider class="" />
              </v-expansion-panel>

              <!-- Fagi -->
              <v-expansion-panel>
                <v-expansion-panel-header><h4>{{ $t('Settings.Order.Field.ExternalPlatforms.Platform.Fagi') }}</h4></v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateCustomer.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateCustomer.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.FagiAutoCreateCustomer"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.FagiAutoCreateCustomer ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateAddress.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateAddress.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.FagiAutoCreateAddress"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.FagiAutoCreateAddress ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintOrder.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintOrder.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.FagiAutoPrintOrder"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.FagiAutoPrintOrder ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintPreparationOrder.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintPreparationOrder.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.FagiAutoPrintPreparationOrder"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.FagiAutoPrintPreparationOrder ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintReceipt.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintReceipt.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.FagiAutoPrintReceipt"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.FagiAutoPrintReceipt ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AddTip.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AddTip.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.FagiAddTip"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.FagiAddTip ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AddDelivery.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AddDelivery.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.FagiAddDelivery"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.FagiAddDelivery ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col cols="12">
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.InitOrderStatus.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.InitOrderStatus.Subtitle') }}</span>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="viewData.FagiDefaultOrderStatus"
                              :items="$te('Settings.Payment.Edit.Field.OrderStatus.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatus.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatus.Items') : []"
                              :label="$t('Settings.Payment.Edit.Field.OrderStatus.Label')"
                              filled
                              clearable
                              hide-details
                              item-text="Name"
                              item-value="Id"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>

                <v-divider class="" />
              </v-expansion-panel>

              <!-- GetFood -->
              <v-expansion-panel>
                <v-expansion-panel-header><h4>{{ $t('Settings.Order.Field.ExternalPlatforms.Platform.GetFood') }}</h4></v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateCustomer.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateCustomer.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetFoodAutoCreateCustomer"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetFoodAutoCreateCustomer ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateAddress.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoCreateAddress.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetFoodAutoCreateAddress"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetFoodAutoCreateAddress ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintOrder.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintOrder.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetFoodAutoPrintOrder"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetFoodAutoPrintOrder ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintPreparationOrder.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintPreparationOrder.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetFoodAutoPrintPreparationOrder"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetFoodAutoPrintPreparationOrder ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintReceipt.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AutoPrintReceipt.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetFoodAutoPrintReceipt"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetFoodAutoPrintReceipt ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AddTip.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AddTip.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetFoodAddTip"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetFoodAddTip ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.AddDelivery.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.AddDelivery.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetFoodAddDelivery"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetFoodAddDelivery ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.OrderUpdateStatusInSource.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.OrderUpdateStatusInSource.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetfoodOrderUpdateStatusInSource"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetfoodOrderUpdateStatusInSource ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.OrderDeleteInSource.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.OrderDeleteInSource.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.GetfoodOrderDeleteInSource"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.GetfoodOrderDeleteInSource ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              class="ma-0"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col cols="12">
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Order.Field.ExternalPlatforms.InitOrderStatus.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Order.Field.ExternalPlatforms.InitOrderStatus.Subtitle') }}</span>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="viewData.GetFoodDefaultOrderStatus"
                              :items="$te('Settings.Payment.Edit.Field.OrderStatus.Items') && Array.isArray($t('Settings.Payment.Edit.Field.OrderStatus.Items')) ? $t('Settings.Payment.Edit.Field.OrderStatus.Items') : []"
                              :label="$t('Settings.Payment.Edit.Field.OrderStatus.Label')"
                              filled
                              clearable
                              hide-details
                              item-text="Name"
                              item-value="Id"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>

                <v-divider class="" />
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-form>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveOrdersClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData      from '@/mixins/appdata'
import Auth         from '@/mixins/auth'
import Translatable from '@/mixins/translatable'
import SourceType   from '@/mixins/orders/sourceType'
import draggable    from 'vuedraggable'

export default {
  components: { draggable: draggable },
  mixins    : [AppData, Auth, Translatable, SourceType],
  data () {
    return {
      settingsFormValid           : false,
      validationRules             : {},
      menuOrdersDailyOpenVisible  : false,
      menuOrdersDailyCloseVisible : false,
      MenuCategoriesTabsPosDesktop: {
        Horizontal: false,
        Vertical  : false
      },
      MenuCategoriesTabsPosMobile: {
        Horizontal: false,
        Vertical  : false
      },
      MenuListItemImagePos: {
        Left : false,
        Right: false
      },
      isSaving                         : false,
      viewData                         : null,
      AddNewBarcodePrefixQuantitySearch: '',
      AddNewBarcodePrefixPriceSearch   : '',
      sourceTypesList                  : []
    }
  },
  computed: {
    activePaymentMethods () {
      return this.orderBy(this.viewData?.Payments || [], 'Site')
    },

    orderComments () {
      return this.viewData?.OrderComments || []
    }
  },
  watch: {
    'viewData.DeliveryHeroAutoCreateCustomer': function (newVal) {
      if (!newVal) this.viewData.DeliveryHeroAutoCreateAddress = false
    },
    'viewData.DeliveryHeroAutoCreateAddress': function (newVal) {
      if (newVal) this.viewData.DeliveryHeroAutoCreateCustomer = true
    },

    'viewData.GetFoodAutoCreateCustomer': function (newVal) {
      if (!newVal) this.viewData.GetFoodAutoCreateAddress = false
    },
    'viewData.GetFoodAutoCreateAddress': function (newVal) {
      if (newVal) this.viewData.GetFoodAutoCreateCustomer = true
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Orders.Get, this.onGetOrdersResult)
    this.$bus.$on(window.SocketCommand.Settings.Orders.Save, this.onSaveOrdersResult)
    this.sourceTypesList = [...this.sourceTypesDefault(), ...this.sourceTypesOther()]
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Orders.Get, this.onGetOrdersResult)
    this.$bus.$off(window.SocketCommand.Settings.Orders.Save, this.onSaveOrdersResult)
  },
  methods: {
    onMenuCategoriesTabsPosDesktopVerticalChange (value) {
      this.MenuCategoriesTabsPosDesktop.Horizontal = !value
      this.viewData.MenuCategoriesTabsPos = this.MenuCategoriesTabsPosDesktop.Horizontal
    },
    onMenuCategoriesTabsPosDesktopHorizontalChange (value) {
      this.MenuCategoriesTabsPosDesktop.Vertical = !value
      this.viewData.MenuCategoriesTabsPos = this.MenuCategoriesTabsPosDesktop.Horizontal
    },

    onMenuCategoriesTabsPosMobileVerticalChange (value) {
      this.MenuCategoriesTabsPosMobile.Horizontal = !value
      this.viewData.MenuCategoriesTabsPosMobile = this.MenuCategoriesTabsPosMobile.Horizontal
    },
    onMenuCategoriesTabsPosMobileHorizontalChange (value) {
      this.MenuCategoriesTabsPosMobile.Vertical = !value
      this.viewData.MenuCategoriesTabsPosMobile = this.MenuCategoriesTabsPosMobile.Horizontal
    },

    onMenuListItemImagePosLeftChange (value) {
      this.MenuListItemImagePos.Right = !value
      this.viewData.MenuListItemImageLeftPos = this.MenuListItemImagePos.Left
    },
    onMenuListItemImagePosRightChange (value) {
      this.MenuListItemImagePos.Left = !value
      this.viewData.MenuListItemImageLeftPos = this.MenuListItemImagePos.Left
    },

    sourceTypesDefault () {
      const excludeIds = [0]
      let sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})

      sourceTypesDefault = sourceTypesDefault.map(o => {
        return {
          Id         : parseInt(o.source_type_id),
          Title      : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          SubTitle   : o.name,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Active     : !!o.active,
          Deleted    : this.userIsSuperAdmin ? false : !!o.deleted_at,
          DeletedAt  : o.deleted_at,
          Editable   : this.userIsSuperAdmin,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      }).filter(o => !excludeIds.includes(o.Id))
        .filter(o => !o.Deleted)

      return sourceTypesDefault
    },

    sourceTypesOther () {
      const excludeIds = []
      let sourceTypesOther = this.appConfig?.SOURCE_TYPES?.OTHER || []

      sourceTypesOther = sourceTypesOther.map(o => {
        return {
          Id         : parseInt(o.source_type_id),
          Title      : o.title,
          SubTitle   : o.name,
          Description: o.desc,
          Active     : !!o.active,
          Deleted    : this.userIsSuperAdmin ? false : !!o.deleted_at,
          DeletedAt  : o.deleted_at,
          Editable   : true,
          Avatar     : {
            Img  : o.logo,
            Color: 'blue-grey'
          }
        }
      }).filter(o => !excludeIds.includes(o.Id))
        .filter(o => !o.Deleted)

      return sourceTypesOther
    },

    addComment () {
      if (!this.viewData?.OrderComments) {
        this.viewData.OrderComments = []
      }

      this.viewData.OrderComments.unshift({
        Title : '',
        Active: false
      })
    },

    removeComment (index) {
      if (!this.viewData?.OrderComments) return
      this.viewData.OrderComments.splice(index, 1)
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.Orders.Get, {})
    },

    onGetOrdersResult (result) {
      if (result) {
        if (result.OrdersDailyOpen && result.OrdersDailyClose) {
          result.OrdersDailyOpen = `${ result.OrdersDailyOpen.split(':')[0] }:${ result.OrdersDailyOpen.split(':')[1] }`
          result.OrdersDailyClose = `${ result.OrdersDailyClose.split(':')[0] }:${ result.OrdersDailyClose.split(':')[1] }`
        }

        result.SubtractStockThreshold = {
          ...(!this.viewData?.SubtractStockThreshold ? {} : result.SubtractStockThreshold),
          ...this.$t('Settings.Order.Field.SubtractStockThreshold.Values')
        }
      }

      this.viewData = result

      this.MenuCategoriesTabsPosDesktop.Horizontal = result.MenuCategoriesTabsPos
      this.MenuCategoriesTabsPosDesktop.Vertical = !result.MenuCategoriesTabsPos

      this.MenuCategoriesTabsPosMobile.Horizontal = result.MenuCategoriesTabsPosMobile
      this.MenuCategoriesTabsPosMobile.Vertical = !result.MenuCategoriesTabsPosMobile

      this.MenuListItemImagePos.Left = result.MenuListItemImageLeftPos
      this.MenuListItemImagePos.Right = !result.MenuListItemImageLeftPos
    },

    onSaveOrdersClick () {
      this.$refs?.settingsForm && this.$refs.settingsForm.validate()

      if (this.settingsFormValid) {
        this.isSaving = true
        const payload = JSON.parse(JSON.stringify(this.viewData))
        payload.SourceTypes = JSON.parse(JSON.stringify(this.sourceTypesList, ['Id', 'Active', 'Title']))

        payload.OrderComments = payload.OrderComments?.filter(comment => !!comment?.Title?.trim()) || []

        window.callAS(window.SocketCommand.Settings.Orders.Save, { Orders: payload })
      }
    },

    onSaveOrdersResult (result) {
      this.isSaving = false
      if (result.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Order.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>

/deep/ .v-expansion-panel__header {
  padding : 0;
}

.divTableCell, .divTableHead {
  padding : 3px 4px;
}

.divTableHead {
  padding : 4px 10px;
}
</style>
