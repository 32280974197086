export default {
  Title: 'QR Codes | QR Code | QR Codes',

  Toolbar: {
    Button: {
      New: {
        Title: 'Νέο QR Code',
        Icon : 'add_box',
        Color: 'primary'
      },
      Settings: {
        Title: 'QR Codes Design',
        Icon : 'qr_code_2',
        Color: 'primary'
      },
      Export: {
        Title         : 'Εξαγωγή',
        Icon          : 'file_download',
        Color         : 'primary',
        SuccessMessage: 'Σύντομα θα λάβετε αρχείο με την εξαγωγή των QR Codes στο email: {email}'
      }
    }
  },

  Table: {
    Headers: {
      Id       : '#',
      Code     : 'Κωδικός',
      Data     : 'Δεδομένα',
      Type     : 'Τύπος',
      CreatedAt: 'Ημερομηνία',
      Actions  : ''
    }
  },

  Type: {
    table  : { Id: '6', Enum: 'table', Name: 'Table', Type: 'TBL', Title: 'Τραπέζι' },
    room   : { Id: '8', Enum: 'room', Name: 'Room', Type: 'HRM', Title: 'Δωμάτιο' },
    sunbed : { Id: '9', Enum: 'sunbed', Name: 'Sunbed', Type: 'SNB', Title: 'Ξαπλώστρα' },
    booth  : { Id: '7', Enum: 'booth', Name: 'Booth', Type: 'BTH', Title: 'Περίπτερο' },
    catalog: { Id: '0', Enum: 'catalog', Name: 'Catalog', Type: 'CAT', Title: 'Κατάλογος' },
    home   : { Id: '0', Enum: 'home', Name: 'Home', Type: 'HOM', Title: 'Κεντρική' },
    mobile : { Id: '0', Enum: 'mobile', Name: 'Mobile', Type: 'MOB', Title: 'Κινητό' }
  },

  Dialog: {
    Delete: {
      Title       : 'Διαγραφή QR Code;',
      Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε το QR Code;',
      Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
    }
  },

  SettingsDialog: {
    Toolbar: {
      Title   : 'QR Codes Design',
      Subtitle: 'Σχεδιασμός QR Codes',
      Icon    : 'qr_code',
      Color   : 'indigo'
    }
  },

  AddEditDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη QR Code',
        Subtitle: 'Δημιουργία Νέου QR Code'
      },
      Edit: {
        Title   : 'Επεξεργασία QR Code',
        Subtitle: 'Τροποποίηση Στοιχείων QR Code'
      },
      Icon : 'qr_code',
      Color: 'indigo'
    },

    Field: {
      Data: {
        Label: 'Δεδομένα',
        Hint : 'Δεδομένα. πχ. ΤΡΑΠΕΖΙ ΜΕΣΑ #3, κλπ.',
        Icon : 'http',
        Error: {
          Between: 'Τα δεδομένα πρέπει να είναι από 2 έως 100 χαρακτήρες'
        }
      },
      Type: {
        Label: 'Τύπος',
        Hint : 'Τύπος QR Code. πχ. Τραπέζι',
        Icon : 'checklist',
        Error: {
          Required: 'Επιλέξτε τύπο QR Code'
        }
      }
    }
  }
}
