<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card v-if="editData">
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Domain.Customer.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Domain.Customer.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Domain.Customer.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Domain.Customer.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row
                dense
                class="align-center"
              >
                <v-col>
                  <v-text-field
                    :hint="$t('Settings.Domain.Email.Field.Subdomain.Hint')"
                    :label="$t('Settings.Domain.Email.Field.Subdomain.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.Subdomain.Icon')"
                    :value="subdomain"
                    disabled
                    readonly
                    required
                  />
                </v-col>

                <v-col class="shrink">
                  <v-switch
                    v-model="editData.Active"
                    inset
                    :disabled="!userCanManage"
                    :label="editData.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    :rules="editData.validator.vuetifyFormFieldRules('Active')"
                    class="ma-0 d-inline-flex ml-2"
                    color="blue"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="editData.CountryId"
                    :hint="$t('Settings.Domain.Edit.Field.Country.Hint')"
                    :items="countryList"
                    :label="$t('Settings.Domain.Edit.Field.Country.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.Country.Icon')"
                    :rules="editData.vuetifyFormFieldRules('CountryId')"
                    cache-items
                    class="pr-2"
                    disabled
                    item-text="name"
                    item-value="id"
                    required
                  >
                    <template #prepend>
                      <v-avatar
                        size="22"
                        tile
                      >
                        <template v-if="locale">
                          <v-img :src="`/assets/flags/1x1/${String(locale).toLowerCase()}.svg`" />
                        </template>
                        <template v-else>
                          <v-icon>map</v-icon>
                        </template>
                      </v-avatar>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar size="34">
                        <v-img :src="`/assets/flags/1x1/${String(data.item.code).toLowerCase()}.svg`" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name" />
                        <v-list-item-subtitle
                          class="text-caption"
                          v-html="data.item.desc"
                        />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.CustomerId"
                    :disabled="!userCanManage"
                    :hint="$t('Settings.Domain.Edit.Field.CustomerId.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.CustomerId.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.CustomerId.Icon')"
                    :rules="editData.vuetifyFormFieldRules('CustomerId')"
                    clearable
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        :href="`https://crm.getfoodpro.gr/admin/clients/client/${editData.CustomerId}`"
                        target="_blank"
                        small
                        icon
                        :disabled="!editData.CustomerId"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.LeadId"
                    :disabled="!userCanManage"
                    :hint="$t('Settings.Domain.Edit.Field.LeadId.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.LeadId.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.LeadId.Icon')"
                    :rules="editData.vuetifyFormFieldRules('LeadId')"
                    clearable
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        :href="`https://crm.getfoodpro.gr/admin/leads/index/${editData.LeadId}`"
                        target="_blank"
                        small
                        icon
                        :disabled="!editData.LeadId"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="editData.ResellerId"
                    :hint="$t('Settings.Domain.Edit.Field.ResellerId.Hint')"
                    :items="resellersList"
                    :label="$t('Settings.Domain.Edit.Field.ResellerId.Label')"
                    :rules="editData.vuetifyFormFieldRules('ResellerId')"
                    prepend-icon="mdi-face-agent"
                    cache-items
                    class=""
                    :item-text="(item) => `${item.FullName} ${item.CompanyName} ${item.Area} ${item.CrmId} `"
                    item-value="Id"
                    required
                  >
                    <template #selection="{item}">
                      <div>
                        <span class="mr-1">{{ item.FullName }}</span>
                        -
                        <span class="ml-1 grey--text">{{ item.CompanyName }} / {{ item.Area }}</span>
                      </div>
                    </template>

                    <template #item="{item}">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.FullName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.CompanyName }} / {{ item.Area }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template #append-outer>
                      <v-btn
                        :href="`https://crm.getfoodpro.gr/admin/leads/index/${editData.ResellerId}`"
                        target="_blank"
                        small
                        icon
                        :disabled="!editData.ResellerId"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.TrialExpiredAt"
                    v-mask="'##-##-####'"
                    :disabled="!userCanManage"
                    :hint="$t('Settings.Domain.Edit.Field.TrialExpiredAt.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.TrialExpiredAt.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.TrialExpiredAt.Icon')"
                    :rules="editData.vuetifyFormFieldRules('TrialExpiredAt')"
                    clearable
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.SubscriptionExpiredAt"
                    v-mask="'##-##-####'"
                    :disabled="!userCanManage"
                    :hint="$t('Settings.Domain.Edit.Field.SubscriptionExpiredAt.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.SubscriptionExpiredAt.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.SubscriptionExpiredAt.Icon')"
                    :rules="editData.vuetifyFormFieldRules('SubscriptionExpiredAt')"
                    clearable
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.SeasonStartedAt"
                    v-mask="'##-##'"
                    :disabled="!userCanManage"
                    :hint="$t('Settings.Domain.Edit.Field.SeasonStartedAt.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.SeasonStartedAt.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.SeasonStartedAt.Icon')"
                    :rules="editData.vuetifyFormFieldRules('SeasonStartedAt')"
                    clearable
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.SeasonEndedAt"
                    v-mask="'##-##'"
                    :disabled="!userCanManage"
                    :hint="$t('Settings.Domain.Edit.Field.SeasonEndedAt.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.SeasonEndedAt.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.SeasonEndedAt.Icon')"
                    :rules="editData.vuetifyFormFieldRules('SeasonEndedAt')"
                    clearable
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.ExtendedPeriodAt"
                    v-mask="'##-##-####'"
                    :disabled="!userCanManage"
                    :hint="$t('Settings.Domain.Edit.Field.ExtendedPeriodAt.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.ExtendedPeriodAt.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.ExtendedPeriodAt.Icon')"
                    :rules="editData.vuetifyFormFieldRules('ExtendedPeriodAt')"
                    :suffix="`+${appConfig?.LOCATION_DATA?.ExtendedPeriodHours} ${$tc('Order.Type.Time.Hour.Title', appConfig?.LOCATION_DATA?.ExtendedPeriodHours || 0)}`"
                    clearable
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-switch
                    v-model="editData.Locked"
                    inset
                    :disabled="!userCanManage"
                    :label="editData.Locked ? $t('Common.Button.Toggle.Locked') : $t('Common.Button.Toggle.Unlocked')"
                    :rules="editData.validator.vuetifyFormFieldRules('Locked')"
                    :color="editData.Locked ? 'red' : 'green'"
                    class="ma-0 d-inline-flex ml-2"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData             from '../../../mixins/appdata'
import Auth                from '../../../mixins/auth'
import CustomerDomainModel from '@/api/Models/settings/CustomerDomainModel'

export default {
  name      : 'DomainCustomerDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    domain: {
      type   : Object,
      default: undefined
    },
    countries: {
      type   : Array,
      default: () => []
    },
    resellers: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      dialogFormValid: false,
      editData       : null
    }
  },
  computed: {
    subdomain () {
      return `${ this.domain.Domain }.${ this.domain.BaseDomain }`
    },
    locale () {
      const country = this.countryList.find(country => country.id === this.domain.CountryId)
      return country ? (country.code === 'GR' ? 'EL' : country.code === 'GB' ? 'UK' : country.code) : ''
    },
    countryList () {
      return this.countries || []
    },
    resellersList () {
      return this.resellers || []
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.editData = new CustomerDomainModel(this.domain)
      } else {
        this.editData = null
      }
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Domains.Customer.Save, this.onResult)
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Domains.Customer.Save, this.onResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()

        this.editData.save({ Customer: this.editData.clone() })
      }
    },
    onResult (response) {
      this.onCancelButtonClick()

      if (response && response.status) {
        this.$bus.$emit('app-show-notification', {
          body   : response.type,
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Settings.Domain.Notification.Generic.Error'),
          type   : 'error',
          icon   : 'warning',
          timeout: 5000
        })
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
    }
  }
}
</script>
