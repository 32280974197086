import Vue     from 'vue'
import VueI18n from 'vue-i18n'
import moment  from 'moment'
import axios   from 'axios'
// Import Lang Files
import el      from './locale/el/index'
import en      from './locale/en/index'

Vue.use(VueI18n)

const messages = {
  el: el,
  en: en
}

const numberFormats = {
  USD: {
    currency: {
      style          : 'currency',
      currency       : 'USD',
      currencyDisplay: 'symbol'
    }
  },
  GBP: {
    currency: {
      style          : 'currency',
      currency       : 'GBP',
      currencyDisplay: 'symbol'
    }
  },
  EUR: {
    currency: {
      style          : 'currency',
      currency       : 'EUR',
      currencyDisplay: 'symbol'
    }
  }
}

export const i18n = new VueI18n({
  locale               : process.env.VUE_APP_I18N_LOCALE || 'el',
  fallbackLocale       : process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  numberFormats,
  messages
})

i18n.languages = Object.values(i18n.t('Common.Language')).filter(lang => lang.active)

export const setI18nLanguage = (lang) => {
  const lng = i18n.languages.find(l => l.locale === lang)
  lang = lng ? lng.locale : process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'el'
  i18n.locale = lang
  moment.locale(lang)
  axios.defaults.headers.common['Accept-Language'] = lang
  if (window.Vue) window.Vue.$vuetify.lang.current = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export const setI18nLanguageFromBrowser = () => {
  setI18nLanguage(getBrowserLocale())
}

export const getBrowserLocale = (countryCodeOnly = true) => {
  const navigatorLocale = navigator && navigator.languages && Array.isArray(navigator.languages) && navigator.languages.length ? navigator.languages[0] : navigator.language
  if (!navigatorLocale) return process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'el'
  return countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim()
}

// Hot Reloading Language Updates
if (module.hot) {
  module.hot.accept(['./locale/el/index', './locale/en/index'], function () {
    i18n.setLocaleMessage('el', require('./locale/el/index').default)
    i18n.setLocaleMessage('en', require('./locale/en/index').default)
  })
}

export default i18n
