<template>
  <v-container
    class="pa-0 fill-height"
    fluid
  >
    <v-row
      class="fill-height pr-0"
      :no-gutters="$vuetify.breakpoint.smAndUp"
    >
      <!-- Cart -->
      <v-col
        ref="cartFlex"
        cols="12"
        lg="4"
        sm="5"
        style="border-right: 1px solid rgba(0, 0, 0, 0.12);"
        xl="3"
        :class="{'pa-0 ma-0': $vuetify.breakpoint.xsOnly}"
      >
        <pos-cart
          :before-route-leave-callback="beforeRouteLeaveCallback"
          :disable-toolbar="disableToolbar"
          :drawer.sync="cartDrawerVisible"
          :order-complete.sync="isOrderComplete"
        />
      </v-col>

      <!-- Catalog -->
      <v-col
        ref="menuFlex"
        cols="12"
        lg="8"
        sm="7"
        xl="9"
        class="py-2 py-sm-0"
      >
        <pos-catalog
          :disable-toolbar="disableToolbar"
          :drawer.sync="cartDrawerVisible"
        />
      </v-col>
    </v-row>

    <order-payment-dialog
      :visible.sync="orderPaymentDialogVisible"
      :cart-id="posCart.Id"
      :customer-id="posCart.CustomerId"
      :order-total="posCart.SOrderTotal"
      :transactions="posCart.PaymentTransactions"
      :payment-type="posCart.PaymentType"
      :items="posCart.ItemsFlat"
      :payload="orderPaymentDialogPayload"
      receipt
    />

    <menu-options-dialog
      :menu-options-dialog-edit-mode="menuOptionsDialogEditMode"
      :menu-options-dialog-visible.sync="menuOptionsDialogVisible"
      :selected-menu-item="selectedMenuItem"
      @save:menuOptionsItem="onSaveMenuOptions"
    />

    <customer-search-dialog
      :customer-search-dialog-visible.sync="customerSearchDialogVisible"
      :offset="126"
      :search-filter.sync="customerSearch"
    />

    <customer-select-address-dialog
      :customer-select-address-dialog-visible.sync="customerSelectAddressDialogVisible"
    />

    <customer-add-edit-address-dialog
      :customer-add-edit-address-dialog-visible.sync="customerAddEditAddressDialogVisible"
      :edit-address-id="editAddressId"
      :order-type.sync="posCart.OrderType"
      :selected-address="posCart.Address"
      :selected-customer="posCart.Customer"
    />

    <confirm-dialog
      :cancel-button-text="$t('Common.Button.Close')"
      :html-content="$t(`Pos.Pos.Dialog.${saveDialogType}.Content`)"
      :html-title="$t(`Pos.Pos.Dialog.${saveDialogType}.Title`)"
      :info-bar-body="$t(`Pos.Pos.Dialog.${saveDialogType}.Notification`)"
      :visible.sync="saveOrderVisible"
      cancel-button
      cancel-button-event="cancel-pos-order-close"
      info-bar
      no-button-class="v-btn--outline red--text darken-1"
      no-button-event="cancel-pos-order-no"
      title-class="green white--text lighten-0"
      yes-button-class="green white--text"
      yes-button-event="cancel-pos-order-yes"
      @cancel-pos-order-close="onCloseSaveOrderDialog"
      @cancel-pos-order-no="onCancelOrder"
      @cancel-pos-order-yes="onSaveOrder"
    />

    <confirm-dialog
      :html-content="$t(`Pos.Pos.Dialog.${cancelDialogType}.Content`)"
      :html-title="$t(`Pos.Pos.Dialog.${cancelDialogType}.Title`)"
      :info-bar-body="$t(`Pos.Pos.Dialog.${cancelDialogType}.Notification`)"
      :visible.sync="cancelOrderVisible"
      cancel-button-event="cancel-pos-order-no"
      info-bar
      no-button-event="cancel-pos-order-no"
      title-class="red white--text lighten-0"
      yes-button-event="cancel-pos-order-yes"
      @cancel-pos-order-yes="onCancelOrder"
    />

    <div
      v-if="queueBusy && showLoaderModal"
      style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; z-index: 999997;"
    >
      <div
        style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; z-index: 999998; text-align: center; background-color: #000000; opacity: 0.2;"
      />

      <div style="position:relative; z-index: 999999;">
        <v-icon
          class="custom-loader"
          dark
          size="128"
        >
          cached
        </v-icon>
      </div>
    </div>
  </v-container>
</template>

<script>
import AppData                      from '@/mixins/appdata'
import Auth                         from '@/mixins/auth'
import PosCatalog                   from '@/components/pos/PosCatalog'
import ConfirmDialog                from '@/components/common/ConfirmDialog'
import PosCart                      from '@/components/pos/PosCart'
import PosOrder                     from '@/mixins/pos/posOrder'
import MenuOptionsDialog            from '@/components/pos/MenuOptionsDialog'
import CustomerSearchDialog         from '@/components/customers/CustomerSearchDialog'
import CustomerSelectAddressDialog  from '@/components/customers/CustomerSelectAddressDialog'
import CustomerAddEditAddressDialog from '@/components/customers/CustomerAddEditAddressDialog'
import PosMenuItemCommon            from '@/mixins/pos/posMenuItemCommon'
import PosCartQueue                 from '@/mixins/pos/posCartQueue'
import PosCatalogMixin              from '@/mixins/pos/posCatalog'
import BarcodeScanListener          from '@/lib/barcode/BarcodeScanListener'
import { formatUnitQuantity }       from '@/lib/currency/currency'
import OrderPaymentDialog           from '@/components/payments/orders/OrderPaymentDialog.vue'
import PaymentTypeEnum              from '@/api/Enums/PaymentTypeEnum'
import ReceiptProviderTypeEnum      from '@/api/Enums/ReceiptProviderTypeEnum'

export default {
  name: 'Pos',

  components: {
    OrderPaymentDialog,
    PosCart,
    PosCatalog,
    ConfirmDialog,
    MenuOptionsDialog,
    CustomerSearchDialog,
    CustomerSelectAddressDialog,
    CustomerAddEditAddressDialog
  },

  mixins: [Auth, AppData, PosCatalogMixin, PosCartQueue, PosOrder, PosMenuItemCommon],

  beforeRouteEnter (to, from, next) {
    next(vm => {})
  },

  beforeRouteLeave (to, from, next) {
    this.beforeRouteLeaveCallback = next

    if (to.name === 'Login') {
      next()
      return
    }

    if (!this.isOrderComplete) {
      this.$emit('update:navigationDrawer', false)
      if (to.params?.noRouteGuardConfirmDialog) {
        this.$nextTick(() => {
          this.onSaveOrder()
        })
      } else {
        this.showSaveOrderDialog(!this.canCancelOrSaveOrder)
      }
    } else {
      next()
    }
  },

  props: {
    navigationDrawer: {
      type   : Boolean,
      default: false
    }
  },

  DataStore: { posCart: 'cart' },

  data () {
    return {
      cartDrawerVisible                  : false,
      showLoaderModal                    : false,
      isOrderComplete                    : false,
      saveOrderVisible                   : false,
      saveDialogType                     : 'SaveRefund',
      cancelOrderVisible                 : false,
      cancelDialogType                   : 'Cancel',
      selectedMenuItem                   : null,
      menuOptionsDialogVisible           : false,
      menuOptionsDialogEditMode          : false,
      orderPaymentDialogVisible          : false,
      orderPaymentDialogPayload          : { autoPrint: false },
      beforeRouteLeaveCallback           : null,
      customerSearch                     : '',
      customerSearchDialogVisible        : false,
      customerSelectAddressDialogVisible : false,
      customerAddEditAddressDialogVisible: false,
      editAddressId                      : null,
      barcodeListener                    : null
    }
  },

  computed: {
    cartHasPaidCardTransactions () {
      return this.posCart?.PaymentTransactions?.filter(transaction => transaction.Paid === 'success' && transaction.PaymentType === PaymentTypeEnum.CARD)?.length > 0
    },

    canCancelOrSaveOrder () {
      if (this.appHasReceiptsProvider && this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) return !this.cartHasPaidCardTransactions

      return true
    },

    categoryTabs () {
      return this.$vuetify.breakpoint.smAndDown ? this.appConfig?.LOCATION_DATA?.MenuCategoriesTabsPosMobile ?? false : this.appConfig?.LOCATION_DATA?.MenuCategoriesTabsPos ?? false
    },

    disableToolbar () {
      return this.queueBusy && !this.showLoaderModal && !this.orderPaymentDialogVisible
    }
  },

  watch: {
    menuOptionsDialogVisible (newVal) {
      if (!newVal) this.onCartAddOrUpdateItem()
    },

    posCart: {
      deep: true,
      handler () {
        // console.log('watch posCart: ', newVal)
      }
    }
  },

  created () {
    this.$bus.$on('cart:show-save-order-dialog', this.showSaveOrderDialog)
    this.$bus.$on('cart:show-cancel-order-dialog', this.showCancelOrderDialog)
    this.$bus.$on('cart:cart-quick-add-item', this.quickAddMenuItemToCart)
    this.$bus.$on('cart:show-add-menu-dialog', this.showAddMenuOptionsDialog)
    this.$bus.$on('cart:show-edit-menu-dialog', this.showEditMenuOptionsDialog)
    this.$bus.$on('cart:show-customer-search-dialog', this.showCustomerSearchDialog)
    this.$bus.$on('cart:show-customer-add-dialog', this.showCustomerAddDialog)
    this.$bus.$on('cart:show-order-payment-dialog', this.showOrderPaymentDialog)
    this.$bus.$on('cart:show-customer-select-address-dialog', this.showCustomerSelectAddressDialog)
    this.$bus.$on('show-customer-add-edit-address-dialog', this.showCustomerAddEditAddressDialog)

    this.$bus.$on(window.SocketCommand.Menu.Get, this.onGetMenuItem)
    this.$bus.$on(window.SocketCommand.Cart.Item.Add, this.onCartAddOrUpdateItem)
    this.$bus.$on(window.SocketCommand.Cart.Item.Update, this.onCartAddOrUpdateItem)

    this.initBarcodeScanner()
  },

  beforeDestroy () {
    this.$bus.$off('cart:show-save-order-dialog', this.showSaveOrderDialog)
    this.$bus.$off('cart:show-cancel-order-dialog', this.showCancelOrderDialog)
    this.$bus.$off('cart:cart-quick-add-item', this.quickAddMenuItemToCart)
    this.$bus.$off('cart:show-add-menu-dialog', this.showAddMenuOptionsDialog)
    this.$bus.$off('cart:show-edit-menu-dialog', this.showEditMenuOptionsDialog)
    this.$bus.$off('cart:show-customer-search-dialog', this.showCustomerSearchDialog)
    this.$bus.$off('cart:show-customer-add-dialog', this.showCustomerAddDialog)
    this.$bus.$off('cart:show-order-payment-dialog', this.showOrderPaymentDialog)
    this.$bus.$off('cart:show-customer-select-address-dialog', this.showCustomerSelectAddressDialog)
    this.$bus.$off('show-customer-add-edit-address-dialog', this.showCustomerAddEditAddressDialog)

    this.$bus.$off(window.SocketCommand.Menu.Get, this.onGetMenuItem)
    this.$bus.$off(window.SocketCommand.Cart.Item.Add, this.onCartAddOrUpdateItem)
    this.$bus.$off(window.SocketCommand.Cart.Item.Update, this.onCartAddOrUpdateItem)

    this.destroyBarcodeScanner()
  },

  methods: {
    initBarcodeScanner () {
      this.barcodeListener = new BarcodeScanListener({
        debug           : true,
        eventSuffix     : 'Pos',
        maxKeyboardDelay: this.appConfig?.LOCATION_DATA?.BarcodeDelayBetweenScans || 50,
        allowedChars    : '[0-9]',
        barcodeRegex    : '(([0-9]{2})([0-9]{5})([0-9]{5})([0-9]{1}))|[0-9]{6,}',
        ignoreInputs    : true
      })

      window.removeEventListener('onBarcodeScan-Pos', this.onBarcodeScan)
      window.addEventListener('onBarcodeScan-Pos', this.onBarcodeScan)
    },

    destroyBarcodeScanner () {
      window.removeEventListener('onBarcodeScan-Pos', this.onBarcodeScan)
      this.barcodeListener.stop()
      this.barcodeListener = null
    },

    EAN13BarcodeCheckDigit (barcode) {
      const checkSum = barcode?.split('')?.reduce((p, v, i) => i % 2 === 0 ? p + 1 * v : p + 3 * v, 0) || -1

      return checkSum % 10 === 0
    },

    onBarcodeScan (e) {
      const validBarcodeResult = e.detail.match
      const data = {
        barcode: validBarcodeResult?.input || '',
        type   : validBarcodeResult[2],
        product: validBarcodeResult[3],
        value  : validBarcodeResult[4],
        check  : validBarcodeResult[5]
      }

      if (this.EAN13BarcodeCheckDigit(data.barcode)) {
        // Product UnitQuantity
        const barcodePrefixProductUnitQuantity = this.appConfig?.LOCATION_DATA?.BarcodePrefixProductUnitQuantity || []
        if (barcodePrefixProductUnitQuantity.includes(data.type)) {
          const menuItem = this.getCatalogMenuItemByBarcode(data.product)
          if (menuItem && menuItem.MenuCustomPrice && menuItem.UnitId > 1) {
            menuItem.UnitQuantity = parseInt(data.value)
            this.quickAddMenuItemToCart(menuItem)
          }
          return
        }

        // Product Price
        const barcodePrefixProductUnitPrice = this.appConfig?.LOCATION_DATA?.BarcodePrefixProductUnitPrice || []
        if (barcodePrefixProductUnitPrice.includes(data.type)) {
          const menuItem = this.getCatalogMenuItemByBarcode(data.product)
          if (menuItem && menuItem.MenuCustomPrice && menuItem.UnitId === 1) {
            menuItem.HasCustomPrice = true
            menuItem.Price = parseFloat(parseFloat(parseInt(data.value) / 100).toFixed(2))
            this.quickAddMenuItemToCart(menuItem)
          }
          return
        }
      }

      // All Products
      const menuItem = this.getCatalogMenuItemByBarcode(data.barcode)
      if (menuItem && menuItem.UnitId === 1) {
        this.quickAddMenuItemToCart(menuItem)
      }
    },

    getCatalogMenuItemByBarcode (barcode) {
      if (!barcode) return null

      const catalogItems = this.posMenuItems?.items || []
      return catalogItems.map(catalogItem => catalogItem.Items).flat().find(menuItem => menuItem.Barcode === barcode)
    },

    quickAddMenuItemToCart (menu) {
      this.showAddMenuOptionsDialog(menu, null, false)

      this.initMenuItem()

      if (!this.isRequiredOptionsValid()) {
        this.showAddMenuOptionsDialog(menu)
        return
      }

      this.$set(menu, 'Loading', true)

      this.cartAddMenuItem(this.selectedMenuItem)
    },

    showAddMenuOptionsDialog (menuItem, payload = null, showDialog = true) {
      if (!menuItem || (menuItem?.Loading && parseInt(this.selectedMenuItem?.Id) === parseInt(menuItem.Id))) return
      if ((menuItem.Status ? (menuItem.SubtractStock && menuItem.Quantity <= 0) : !menuItem.Status)) return
      const menuItemQuantity = parseInt(menuItem.UnitId > 1 ? menuItem.UnitQuantity : menuItem.Quantity)
      const menuItemStockQuantity = parseInt(menuItem.Quantity)

      let isStockValid = true
      if (menuItem.SubtractStock) {
        let totalStockUsed = 0
        for (let i = 0; i < this.posCart.Items.length; i++) {
          const cartItem = this.posCart.Items[i]
          const quantity = parseInt(menuItem.UnitId > 1 ? cartItem.UnitQuantity : cartItem.Quantity)
          if (parseInt(menuItem.Id) === parseInt(cartItem.Id)) totalStockUsed += quantity
        }
        isStockValid = totalStockUsed < menuItemQuantity
      }

      if (!isStockValid) {
        this.$bus.$emit('app-show-notification', {
          title  : '',
          body   : this.$t('Pos.Pos.Cart.Error.NoStock') + ' «' + menuItem.Name + '».',
          type   : 'warning',
          icon   : 'info_outline',
          timeout: 6000
        })
        return
      }

      if ((menuItemStockQuantity < menuItem.MinimumQuantity) && menuItem.SubtractStock) {
        this.$bus.$emit('app-show-notification', {
          title  : '',
          body   : this.$t('Pos.Pos.Cart.Error.MinStock.Title') + ' «' + menuItem.Name + '» ' + this.$t('Pos.Pos.Cart.Error.MinStock.Message') + formatUnitQuantity(this.$i18n.locale, menuItem.MinimumQuantity, this.getMeasurementUnitById(menuItem.UnitId)) + this.$t('Pos.Pos.Cart.Error.MinStock.MessageEnd'),
          type   : 'warning',
          icon   : 'info_outline',
          timeout: 6000
        })
        return
      }

      this.selectedMenuItem = JSON.parse(JSON.stringify(menuItem))
      this.selectedMenuItem.ModifierRuns = 0
      this.selectedMenuItem.Quantity = 1
      this.menuOptionsDialogEditMode = false

      if (this.selectedMenuItem?.DetailsData) {
        this.onGetMenuItem(this.selectedMenuItem.DetailsData, showDialog)
      } else {
        window.callAS(window.SocketCommand.Menu.Get, { menu_id: this.selectedMenuItem.Id })
      }
    },

    onGetMenuItem (menuItemDetails, showDialog = true) {
      if (!menuItemDetails) return

      if (this.menuOptionsDialogEditMode) {
        if (!menuItemDetails.Custom) menuItemDetails.Custom = {}
        if (!menuItemDetails.Custom.Seat) menuItemDetails.Custom.Seat = '0'
        if (!menuItemDetails.Custom.Order) menuItemDetails.Custom.Order = '0'
      } else {
        this.$set(menuItemDetails, 'Custom', {
          Seat : '0',
          Order: '0'
        })
      }
      this.$set(this.selectedMenuItem, 'Details', menuItemDetails)
      this.$set(this.selectedMenuItem.Details, 'OptionsCopy', JSON.parse(JSON.stringify(menuItemDetails?.Options || {})))
      if (showDialog) this.menuOptionsDialogVisible = true
    },

    showEditMenuOptionsDialog (menuItem) {
      if (!menuItem || (menuItem?.Loading && parseInt(this.selectedMenuItem?.Id) === parseInt(menuItem.Id))) return
      this.menuOptionsDialogEditMode = true
      this.selectedMenuItem = JSON.parse(JSON.stringify(menuItem))
      this.selectedMenuItem.ModifierRuns = 0

      window.callAS(window.SocketCommand.Menu.Get, {
        menu_id: this.selectedMenuItem.Id,
        cart_id: this.posCart.Id,
        row_id : this.selectedMenuItem.RowId
      })
    },

    onCartAddOrUpdateItem (data, payload) {
      // console.log('>>>>>>> onCartAddOrUpdateItem', data, payload, this.selectedMenuItem)
      const menuId = data?.Payload?.MenuId || payload?.MenuItem?.Id
      const rowId = data?.Payload?.RowId || payload?.MenuItem?.RowId
      if ((menuId && this.selectedMenuItem?.Id === menuId) || (rowId && this.selectedMenuItem?.RowId === rowId)) {
        this.selectedMenuItem = null
        this.menuOptionsDialogVisible = false
        this.menuOptionsDialogEditMode = false
      }
    },

    onSaveMenuOptions () {
      if (this.menuOptionsDialogEditMode) {
        this.cartUpdateMenuItem(this.selectedMenuItem)
      } else {
        this.cartAddMenuItem(this.selectedMenuItem)
      }

      this.$bus.$emit('onSaveMenuOptions', this.selectedMenuItem)

      if (!this.showLoaderModal) {
        this.selectedMenuItem = null
        this.menuOptionsDialogVisible = false
        this.menuOptionsDialogEditMode = false
      }
    },

    cartAddMenuItem (menu) {
      if (!menu) return

      this.$bus.$emit('cart:cart-add-item', menu)
    },

    cartUpdateMenuItem (menu) {
      if (!menu) return

      this.$bus.$emit('cart:cart-update-item', menu)
    },

    showSaveOrderDialog (mustRefund = true) {
      if (mustRefund) {
        this.saveDialogType = 'SaveRefund'
      } else {
        this.saveDialogType = 'Save'
      }

      this.saveOrderVisible = true
    },

    onSaveOrder () {
      this.$bus.$emit('cart:save-order')
    },

    showCancelOrderDialog (data) {
      if (data?.mustRefund) {
        this.cancelDialogType = 'CancelRefund'
      } else {
        this.cancelDialogType = 'Cancel'
      }

      this.cancelOrderVisible = true
    },

    onCancelOrder () {
      this.$bus.$emit('cart:cancel-order')
    },

    onCloseSaveOrderDialog () {
      this.beforeRouteLeaveCallback = null
      this.saveOrderVisible = false
    },

    showCustomerSearchDialog (searchTerms) {
      if (searchTerms) this.customerSearch = searchTerms || ''
      this.customerSearchDialogVisible = true
    },

    showCustomerAddDialog () {
      this.$bus.$emit('show-customer-add-dialog')
    },

    showOrderPaymentDialog (autoPrint) {
      this.orderPaymentDialogPayload = {
        autoPrint: autoPrint ?? true
      }
      this.orderPaymentDialogVisible = true
    },

    showCustomerSelectAddressDialog () {
      if (this.posCart.SourceTypeId === 1) return
      this.customerSelectAddressDialogVisible = true
    },

    showCustomerAddEditAddressDialog (address) {
      if (address) {
        this.editAddressId = address.Id
      } else {
        this.editAddressId = null
      }
      this.customerAddEditAddressDialogVisible = true
    }
  }
}
</script>

<style scoped>

/*
/deep/ .col {
  padding : 4px !important;
}
*/

.v-btn__content {
  padding : 0 4px;
}

.v-btn {
  min-width : 20px;
}
</style>
