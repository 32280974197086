<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Faq.ImagePreview.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Faq.ImagePreview.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Faq.ImagePreview.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ url }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="fill-height text-center">
          <img
            :src="url + '?cache=' + appVersion"
            style="max-width: 100%; height: auto;"
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'

export default {
  name      : 'ImagePreview',
  components: {},
  mixins    : [AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    url: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
