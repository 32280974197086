<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :max-width="width"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          :class="[titleClass]"
          flat
          dark
          height="80"
          max-height="80"
        >
          <v-avatar
            v-if="titleIcon"
            :class="[titleClass, 'darken-2']"
          >
            <v-icon
              color="white"
              size="28"
            >
              {{ titleIcon }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div
              class="text-h5"
              v-html="htmlTitle"
            />
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            dark
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider v-if="htmlContent" />

        <v-card-text
          v-if="htmlContent"
          class="text-subtitle-1 pa-4 fill-height"
          v-html="htmlContent"
        />

        <v-card-actions
          v-if="infoBar"
          class="pa-0"
        >
          <v-container
            fluid
            class="pa-0"
          >
            <v-row no-gutters>
              <v-col
                :class="infoBarClass"
                cols="12"
              >
                <div
                  class="py-2 px-4 text-caption grey--text text--darken-3"
                  style="display: table;"
                >
                  <div style="display: table-row">
                    <div
                      v-if="infoBarTitle"
                      class="font-weight-bold pr-2"
                      style="display: table-cell; vertical-align: middle;"
                      v-html="infoBarTitle"
                    />
                    <div
                      v-if="infoBarBody"
                      style="display: table-cell; vertical-align: middle;"
                      v-html="infoBarBody"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-divider />

        <v-card-actions :class="cardActionsClass">
          <v-btn
            v-if="cancelButton"
            :block="buttonsBlock"
            :class="[cancelButtonClass]"
            class="elevation-0"
            @click.native="onCancelButtonClick"
          >
            {{ cancelButtonText }}
          </v-btn>

          <v-spacer />

          <v-btn
            v-if="noButton"
            :block="buttonsBlock"
            :class="[noButtonClass]"
            class="elevation-0"
            @click.native="onNoButtonClick"
          >
            {{ noButtonText }}
          </v-btn>

          <v-btn
            v-if="yesButton"
            :block="buttonsBlock"
            :class="[yesButtonClass]"
            class="elevation-0"
            @click.native="onYesButtonClick"
          >
            {{ yesButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name : 'ConfirmDialog',
  props: {
    visible: {
      type   : Boolean,
      default: false
    },
    cardActionsClass: {
      type   : String,
      default: ''
    },
    htmlTitle: {
      type   : String,
      default: function () {
        return this.$t('Common.ConfirmDialog.Title')
      }
    },
    titleClass: {
      type   : String,
      default: ''
    },
    titleIcon: {
      type   : String,
      default: 'live_help'
    },
    htmlContent: {
      type   : String,
      default: ''
    },
    yesButton: {
      type   : Boolean,
      default: true
    },
    yesButtonEvent: {
      type   : String,
      default: 'confirm-dialog-yes'
    },
    yesButtonText: {
      type   : String,
      default: function () {
        return this.$t('Common.Button.Yes').toLocaleUpperCase(this.$i18n.locale)
      }
    },
    yesButtonClass: {
      type   : String,
      default: 'outline red darken-1 white--text'
    },
    noButton: {
      type   : Boolean,
      default: true
    },
    noButtonEvent: {
      type   : String,
      default: 'confirm-dialog-no'
    },
    noButtonText: {
      type   : String,
      default: function () {
        return this.$t('Common.Button.No').toLocaleUpperCase(this.$i18n.locale)
      }
    },
    noButtonClass: {
      type   : String,
      default: 'v-btn--outline green--text text--darken-1'
    },
    cancelButton: {
      type   : Boolean,
      default: false
    },
    cancelButtonEvent: {
      type   : String,
      default: 'confirm-dialog-cancel'
    },
    cancelButtonText: {
      type   : String,
      default: function () {
        return this.$t('Common.Button.Cancel').toLocaleUpperCase(this.$i18n.locale)
      }
    },
    cancelButtonClass: {
      type   : String,
      default: 'v-btn--outline grey--text text--darken-1'
    },
    buttonsBlock: {
      type   : Boolean,
      default: false
    },
    width: {
      type   : Number,
      default: 500
    },
    infoBar: {
      type   : Boolean,
      default: false
    },
    infoBarTitle: {
      type   : String,
      default: function () {
        return `${ this.$t('Common.Misc.Attention').toLocaleUpperCase(this.$i18n.locale) }:`
      }
    },
    infoBarBody: {
      type   : String,
      default: ''
    },
    infoBarClass: {
      type   : String,
      default: 'red lighten-4'
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onYesButtonClick () {
      this.isVisible = false
      this.$emit(this.yesButtonEvent)
    },
    onNoButtonClick () {
      this.isVisible = false
      this.$emit(this.noButtonEvent)
    },
    onCancelButtonClick () {
      this.isVisible = false
      this.$emit(this.cancelButtonEvent)
    }
  }
}
</script>
