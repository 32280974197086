<template>
  <div v-if="editMenu">
    <v-dialog
      v-if="editMenu.Details"
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1280"
      persistent
      scrollable
    >
      <v-card ref="dialogCard">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.MenuAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.MenuAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!isMenuItemEdit"
            class="pl-3"
          >
            <div
              v-if="editMenu.Details.Name"
              class="body-3"
            >
              {{ editMenu.Details.Name }}
            </div>
            <div
              v-else
              class="body-3"
            >
              {{ $t('Restaurant.MenuAddDialog.Toolbar.Title') }}
            </div>
            <div
              v-if="editMenu.Details.Description"
              class="text-caption"
            >
              {{ editMenu.Details.Description }}
            </div>
            <div
              v-else
              class="text-caption"
            >
              {{ $t('Restaurant.MenuAddDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ editMenu.Details.Name }}
            </div>
            <div class="text-caption">
              {{ editMenu.Details.Description }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-tabs
          v-model="activeTab"
          :scrollable="false"
          background-color="indigo"
          dark
          slider-color="light-green"
        >
          <v-tab
            key="tab1"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab1"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.Details').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="tab2"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab2"
          >
            {{ $t('Restaurant.MenuAddDialog.Tab.ExtraCharges').toLocaleUpperCase($i18n.locale) }}
          </v-tab>
        </v-tabs>

        <v-card-text
          ref="dialogCardText"
          class="fill-height pa-3"
        >
          <v-form
            ref="menuForm"
            v-model="menuFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="activeTab">
              <v-tab-item
                key="tab1"
                value="tab1"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <template v-if="selectedLanguages.length > 1">
                        <v-tabs
                          v-model="languageTab"
                          :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                          grow
                          height="40"
                          slider-color="blue"
                        >
                          <template v-for="lang in selectedLanguages">
                            <v-tab
                              :key="lang.Code"
                              :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                            >
                              <v-avatar
                                v-if="lang.ImageUrl"
                                class="mr-2"
                                size="20"
                              >
                                <v-img :src="lang.ImageUrl" />
                              </v-avatar>
                              {{
                                $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale)
                              }}
                            </v-tab>
                          </template>
                        </v-tabs>
                      </template>

                      <v-tabs-items v-model="languageTab">
                        <template v-for="lang in selectedLanguages">
                          <v-tab-item
                            :key="lang.Code"
                            eager
                          >
                            <v-container
                              class="pa-1"
                              fluid
                            >
                              <v-row dense>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editMenu.Lang[lang.Code].Name"
                                    :counter="255"
                                    :hint="$t('Restaurant.MenuAddDialog.Field.Name.Hint')"
                                    :label="$t('Restaurant.MenuAddDialog.Field.Name.Label')"
                                    :maxlength="255"
                                    :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Name.Icon')"
                                    :rules="validationRules.menuForm.Name"
                                    class="pr-2"
                                    required
                                  >
                                    <template
                                      v-if="lang.Code !== defaultLanguage.Code"
                                      #append-outer
                                    >
                                      <translate-field-button
                                        :item="editMenu"
                                        :locale-from="defaultLanguage.Code"
                                        :locale-to="lang.Code"
                                        field="Name"
                                      />
                                    </template>
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editMenu.Lang[lang.Code].Description"
                                    :counter="500"
                                    :hint="$t('Restaurant.MenuAddDialog.Field.Description.Hint')"
                                    :label="$t('Restaurant.MenuAddDialog.Field.Description.Label')"
                                    :maxlength="500"
                                    :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Description.Icon')"
                                    :rules="validationRules.menuForm.Description"
                                    class="pr-2"
                                  >
                                    <template
                                      v-if="lang.Code !== defaultLanguage.Code"
                                      #append-outer
                                    >
                                      <translate-field-button
                                        :item="editMenu"
                                        :locale-from="defaultLanguage.Code"
                                        :locale-to="lang.Code"
                                        field="Description"
                                      />
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-tab-item>
                        </template>
                      </v-tabs-items>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        v-model="editMenu.Details.Price"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Price.Hint')"
                        :label="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                        :prepend-icon="appCurrency.Icon"
                        :rules="validationRules.menuForm.Price"
                        :suffix="`/ ${getMeasurementUnitById(editMenu.Details.UnitId).BaseUnit}`"
                        disabled
                        class="pr-2"
                        min="0"
                        required
                        step="any"
                        type="number"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        v-model="editMenu.OptionsPrice"
                        :hint="$t('Restaurant.MenuAddDialog.Field.OptionsPrice.Hint')"
                        :label="$t('Restaurant.MenuAddDialog.Field.OptionsPrice.Label')"
                        :prepend-icon="appCurrency.Icon"
                        disabled
                        class="pr-2"
                        readonly
                        required
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <div class="text-caption mt-1 grey--text">
                        {{ $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.Title') }}
                      </div>
                      <v-switch
                        :id="editMenu.Id + '_show_calculated_price'"
                        v-model="editMenu.ShowCalculatedPrice"
                        disabled
                        class="mt-0 d-inline-flex"
                        color="blue"
                        hide-details
                        inset
                        style="width: 100% !important;"
                      >
                        <span
                          slot="append"
                          :class="{'blue--text': editMenu.ShowCalculatedPrice, 'grey--text': !editMenu.ShowCalculatedPrice}"
                          class="pt-1"
                        >
                          <template
                            v-if="editMenu.ShowCalculatedPrice && editMenu.OptionsPrice && editMenu.Details.Price"
                          >
                            {{ (parseFloat(editMenu.OptionsPrice) + parseFloat(editMenu.Details.Price)) | currency }}
                          </template>
                          <template v-else-if="editMenu.Details.Price">
                            {{ parseFloat(editMenu.Details.Price) | currency }}
                          </template>
                        </span>
                        <span
                          slot="prepend"
                          :class="{'blue--text': editMenu.ShowCalculatedPrice, 'grey--text': !editMenu.ShowCalculatedPrice}"
                          class="pt-1 text-no-wrap"
                        >
                          {{
                            editMenu.ShowCalculatedPrice ? $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.Toggle.Active') : $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.Toggle.Inactive')
                          }}
                        </span>
                      </v-switch>
                      <div
                        v-if="editMenu.Details.SpecialStatus"
                        class="text-caption mt-1 error--text"
                      >
                        {{ $t('Restaurant.MenuAddDialog.Field.ShowCalculatedPrice.SpecialStatus') }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        v-model="editMenu.Tags"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Tags.Hint')"
                        :items="tags"
                        :label="$t('Restaurant.MenuAddDialog.Field.Tags.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Tags.Icon')"
                        clearable
                        item-text="Name"
                        item-value="Id"
                        multiple
                        persistent-hint
                      >
                        <template #selection="{item}">
                          <v-chip
                            :color="item.Color"
                            class="px-1"
                            dark
                            label
                            small
                          >
                            <v-icon
                              v-if="item.Icon"
                              :color="getContrastColor(item.Color, true, 168)"
                              class="mr-1"
                              small
                            >
                              {{ item.Icon }}
                            </v-icon>

                            <span>{{ getTranslatedField(item, 'Label') }}</span>
                          </v-chip>
                        </template>

                        <template #item="{item}">
                          <v-list-item-avatar
                            :color="`${item.Color}`"
                            :size="28"
                          >
                            <v-icon
                              v-if="item.Icon"
                              :color="getContrastColor(item.Color, true, 168)"
                              :size="20"
                            >
                              {{ item.Icon }}
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{ getTranslatedField(item, 'Label') }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                        v-model="editMenu.Catalogs"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Catalogs.Hint')"
                        :items="catalogsExternal"
                        :label="$t('Restaurant.MenuAddDialog.Field.Catalogs.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Catalogs.Icon')"
                        :rules="validationRules.menuForm.Catalogs"
                        disabled
                        item-text="Name"
                        item-value="Id"
                        multiple
                        persistent-hint
                      >
                        <template #selection="{item}">
                          <v-chip
                            :color="`#${item.Color}`"
                            dark
                            label
                            small
                          >
                            <span>{{ item.Name }}</span>
                          </v-chip>
                        </template>

                        <template #item="data">
                          <v-list-item-avatar
                            :color="`#${data.item.Color}`"
                            :size="28"
                          />

                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-container
                        class="pa-1"
                        fluid
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editMenu.Details.MenuComment"
                              :counter="500"
                              :hint="$t('Restaurant.MenuAddDialog.Field.MenuComment.Hint')"
                              :label="$t('Restaurant.MenuAddDialog.Field.MenuComment.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.MenuComment.Icon')"
                              :rows="editMenu.Details.MenuComment ? '3' : '1'"
                              :rules="validationRules.menuForm.Description"
                              auto-grow
                              class="pr-2"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-select
                              v-model="editMenu.Categories"
                              :hint="$t('Restaurant.MenuAddDialog.Field.Categories.Hint')"
                              :item-text="(data) => getTranslatedField(data, 'Name')"
                              :items="catalogCategories"
                              :label="$t('Restaurant.MenuAddDialog.Field.Categories.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Categories.Icon')"
                              :rules="validationRules.menuForm.Category"
                              disabled
                              chips
                              class="pr-2"
                              item-value="Id"
                              multiple
                              persistent-hint
                              required
                            >
                              <template
                                slot="selection"
                                slot-scope="data"
                              >
                                <v-chip
                                  :key="data.item.Id"
                                  :style="'background-color:#' + (data.item.Color ? data.item.Color : 'e0e0e0') + '; color: ' + (invertColor(data.item.Color ? data.item.Color : 'e0e0e0', true)) + ';'"
                                  class="chip--select-multi"
                                  label
                                  @input="data.parent.selectItem(data.item)"
                                >
                                  {{ getTranslatedField(data.item, 'Name') }}
                                  <span
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption ml-2"
                                  >- {{ getCategoryCatalog(data.item) }}</span>
                                </v-chip>
                              </template>
                              <template #item="data">
                                <v-list-item-content>
                                  <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption grey--text"
                                  >
                                    {{ getCategoryCatalog(data.item) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col cols="12">
                            <v-select
                              v-if="selectedCategories.length > 0"
                              v-model="editMenu.CategoryId"
                              :hint="$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Hint')"
                              :item-text="(data) => getTranslatedField(data, 'Name')"
                              :items="selectedCategories"
                              :label="$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Label')"
                              :prepend-icon="$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Icon')"
                              :rules="validationRules.menuForm.DefaultCategory"
                              disabled
                              chips
                              class="pr-2 mt-2"
                              item-value="Id"
                              persistent-hint
                              required
                            >
                              <template
                                slot="selection"
                                slot-scope="data"
                              >
                                <v-chip
                                  :key="data.item.Id"
                                  :style="'background-color:#' + (data.item.Color ? data.item.Color : 'e0e0e0') + '; color: ' + (invertColor(data.item.Color ? data.item.Color : 'e0e0e0', true)) + ';'"
                                  class="chip--select-multi"
                                  label
                                  @input="data.parent.selectItem(data.item)"
                                >
                                  {{ getTranslatedField(data.item, 'Name') }}
                                  <span
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption ml-2"
                                  >- {{ getCategoryCatalog(data.item) }}</span>
                                </v-chip>
                              </template>
                              <template #item="data">
                                <v-list-item-content>
                                  <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="getCategoryCatalog(data.item)"
                                    class="text-caption grey--text"
                                  >
                                    {{ getCategoryCatalog(data.item) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </v-col>

                          <template v-if="preparationDocumentEnabled">
                            <v-col cols="12">
                              <v-select
                                v-model="editMenu.PrintGroup"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Hint')"
                                :items="appPrinterGroups"
                                :label="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.PrintGroup.Icon')"
                                :rules="validationRules.menuForm.PrintGroup"
                                item-text="Name"
                                item-value="Id"
                                multiple
                                persistent-hint
                              >
                                <template #selection="{item, index}">
                                  <v-chip
                                    v-if="index < 2"
                                    label
                                    small
                                  >
                                    <span>{{ item.Name }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index > 1"
                                    class="grey--text text-caption"
                                  >(+{{ editMenu.PrintGroup.length - 2 }})</span>
                                </template>
                              </v-select>
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <div class="pl-2 pr-2 text-center mt-6">
                        <div class="grey--text pl-4 pb-2 text-center">
                          {{ $t('Restaurant.MenuAddDialog.Field.Image.Label') }}
                        </div>

                        <image-crop-selector
                          v-model="croppa"
                          :excluded-gallery-tabs="[]"
                          :height="350"
                          :initial-image="editMenu.Details.Image ? appConfig.LOCATION_DATA.CdnImagesUrl + editMenu.Details.Image : ''"
                          :placeholder="`${$t('Settings.Website.Field.Gallery.Image.Hint.Drag')} ${$t('Settings.Website.Field.Gallery.Image.Hint.Click')}`"
                          :placeholder-font-size="15"
                          :prevent-white-space="true"
                          :replace-drop="true"
                          :show-loading="true"
                          :width="800"
                          default-gallery-tab="product"
                          gallery-enabled
                          @new-image-drawn="onPhotoChange"
                          @image-remove="onPhotoRemoved"
                        />
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="pa-1"
                        fluid
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Restaurant.CategoryAddDialog.Field.Tax.Label') }}
                            </h5>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-model="editMenu.TaxId"
                              :hint="$t('Restaurant.CategoryAddDialog.Field.Tax.Hint')"
                              :items="taxesList"
                              :label="$t('Restaurant.CategoryAddDialog.Field.Tax.Label')"
                              :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Tax.Icon')"
                              :rules="validationRules.menuForm.TaxId"
                              class=""
                              clearable
                              item-text="Name"
                              item-value="Id"
                              persistent-hint
                              required
                            >
                              <template #selection="{item}">
                                {{ getTranslatedField(item, 'Name') }}
                              </template>
                            </v-select>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-if="appSupportSecondTax"
                              v-model="editMenu.Tax2Id"
                              :hint="$t('Restaurant.CategoryAddDialog.Field.Tax2.Hint')"
                              :items="taxesList"
                              :label="$t('Restaurant.CategoryAddDialog.Field.Tax2.Label')"
                              :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Tax2.Icon')"
                              :rules="validationRules.menuForm.Tax2Id"
                              class="mt-4"
                              clearable
                              item-text="Name"
                              item-value="Id"
                              persistent-hint
                              required
                            >
                              <template #selection="{item}">
                                {{ getTranslatedField(item, 'Name') }}
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <template v-if="appSupportIncomeClassifications">
                            <v-col cols="12">
                              <h5 class="text-body-2 font-weight-bold mt-2">
                                {{ $t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Title') }}
                              </h5>
                            </v-col>

                            <v-col
                              cols="12"
                              md="6"
                            >
                              <h5 class="text-body-2 font-weight-bold mt-1 mb-2">
                                {{ $t('Settings.Documents.OrderReceipt.Document.Receipt') }}
                              </h5>

                              <v-select
                                v-model="editMenu.ClassificationCategoryCode"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                                :items="incomeClassificationCategoryCodeList"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationCategoryCode"
                                class="mt-2"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>

                              <v-select
                                v-model="editMenu.ClassificationTypeCode"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                                :items="getReceiptIncomeClassificationTypesByCategoryCode(editMenu.ClassificationCategoryCode)"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationTypeCode"
                                class="mt-4"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.Code }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </v-col>

                            <v-col
                              cols="12"
                              md="6"
                            >
                              <h5 class="text-body-2 font-weight-bold mt-1 mb-2">
                                {{ $t('Settings.Documents.OrderReceipt.Document.Invoice') }}
                              </h5>

                              <v-select
                                v-model="editMenu.ClassificationCategoryCodeInvoice"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Hint')"
                                :items="incomeClassificationCategoryCodeList"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationCategoryCode"
                                class="mt-2"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>

                              <v-select
                                v-model="editMenu.ClassificationTypeCodeInvoice"
                                :hint="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Hint')"
                                :items="getInvoiceIncomeClassificationTypesByCategoryCode(editMenu.ClassificationCategoryCodeInvoice)"
                                :label="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Label')"
                                :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Icon')"
                                :rules="validationRules.menuForm.ClassificationTypeCode"
                                class="mt-4"
                                clearable
                                item-text="Name"
                                item-value="Code"
                                persistent-hint
                                required
                              >
                                <template #selection="{item}">
                                  <v-chip
                                    color="grey darken-1"
                                    dark
                                    label
                                    small
                                  >
                                    <span>{{ item.Code }}</span>
                                  </v-chip>

                                  <span class="">{{ getTranslatedField(item, 'Name') }}</span>
                                </template>

                                <template #item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.Code }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ getTranslatedField(item, 'Name') }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                v-if="activeTab === 'tab2'"
                key="tab2"
                value="tab2"
              >
                <v-container
                  class=""
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        v-model="selectedExtraChargeListItem"
                        :hint="$t('Restaurant.MenuAddDialog.Field.SelectExtraCharge.Hint')"
                        :items="extraCharges"
                        :label="$t('Restaurant.MenuAddDialog.Field.SelectExtraCharge.Label')"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.SelectExtraCharge.Icon')"
                        class="pr-2"
                        item-text="Name"
                        item-value="Id"
                        persistent-hint
                        required
                        return-object
                        single-line
                        @change="addExtraCharge"
                      >
                        <template #selection="data">
                          {{ getTranslatedField(data.item, 'Name') }}
                        </template>
                        <template #item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <div class="divTable">
                      <div class="divTableHeading">
                        <div class="divTableRow">
                          <div class="divTableHead">
                            &nbsp;
                          </div>
                          <div class="divTableHead">
                            #
                          </div>
                          <div class="divTableHead">
                            {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Name') }}
                          </div>
                          <div class="divTableHead">
                            {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Price') }}
                          </div>
                          <div class="divTableHead">
                            {{ $t('Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Quantity') }}
                          </div>
                          <div class="divTableHead">
                            &nbsp;
                          </div>
                        </div>
                      </div>

                      <draggable
                        v-model="editMenu.Details.Charges"
                        class="divTableBody"
                        handle=".my-handle"
                        @sort="onChargesSort"
                      >
                        <div
                          v-for="(charge, idx) in editMenu.Details.Charges"
                          :key="idx"
                          class="divTableRow"
                        >
                          <div class="divTableCell">
                            <v-icon
                              class="my-handle"
                              style="cursor: move;"
                            >
                              drag_handle
                            </v-icon>
                          </div>

                          <div class="divTableCell">
                            {{ charge.Id }}
                          </div>

                          <div
                            class="divTableCell"
                            style="min-width: 200px"
                            v-html="getTranslatedField(charge, 'Name', 'Lang')"
                          />

                          <div class="divTableCell">
                            {{ charge.PriceFmt }}
                          </div>

                          <div
                            class="divTableCell"
                            style="width: 100px;"
                          >
                            <v-text-field
                              v-model="charge.Quantity"
                              :placeholder="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                              append-icon="mdi-counter"
                              min="0"
                              required
                              single-line
                              step="1"
                              style="min-width: 70px;"
                              type="number"
                            />
                          </div>

                          <div
                            class="divTableCell text-center"
                            style="white-space: nowrap;"
                          >
                            <v-btn
                              class="red ml-1"
                              dark
                              icon
                              left
                              small
                              @click="removeExtraCharge(idx)"
                            >
                              <v-icon small>
                                close
                              </v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions
          :class="[{'text-center': $vuetify.breakpoint.xsOnly}]"
          :style="$vuetify.breakpoint.xsOnly ? 'display: block;' : ''"
          class="pa-4"
        >
          <translate-all-fields-button
            v-if="selectedLanguages.length > 1"
            :disabled="loading"
            :item="editMenu"
            :locale-from="defaultLanguage.Code"
            :field="['Name', 'Description', 'LongDescription']"
          />

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1 mb-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0 mb-1"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData                  from '@/mixins/appdata'
import Auth                     from '@/mixins/auth'
import Resizable                from '@/mixins/resizable'
import Watchable                from '@/mixins/watchable'
import Translatable             from '@/mixins/translatable'
import draggable                from 'vuedraggable'
import ImageCropSelector        from '@/components/common/ImageCropSelector'
import TaxesCommon              from '@/mixins/taxes/taxesCommon'
import { clone }                from '@/lib/helper/helper'
import { getContrastColor }     from '@/lib/color'
import TranslateFieldButton     from '@/components/common/translation/translateFieldButton.vue'
import TranslateAllFieldsButton from '@/components/common/translation/translateAllFieldsButton.vue'

export default {
  name      : 'MenuExternalEditDialog',
  components: {
    TranslateAllFieldsButton,
    TranslateFieldButton,
    draggable            : draggable,
    'image-crop-selector': ImageCropSelector
  },
  mixins: [AppData, Auth, TaxesCommon, Resizable, Translatable, Watchable],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    menuToEdit: {
      type   : Object,
      default: undefined
    },
    isMenuItemEdit: {
      type   : Boolean,
      default: false
    },
    taxes: {
      type   : Array,
      default: () => []
    },
    tags: {
      type   : Array,
      default: () => []
    },
    catalogs: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      croppa                     : null,
      loading                    : false,
      isDirty                    : false,
      tmpImage                   : null,
      tmpImageBase64             : null,
      showAddMenu                : false,
      activeTab                  : 'tab1',
      languageTab                : 0,
      menuFormValid              : false,
      selectedExtraChargeListItem: null,
      categories                 : [],
      selectedCategories         : [],
      optionsList                : [],
      newMenu                    : null,
      validationRules            : {
        menuForm: {
          Name: [
            (v) => (this.selectedLanguages.length > 1 ? this.selectedLanguages.some(lang => this.editMenu.Lang[lang.Code].Name) : true) || this.$t('Restaurant.MenuAddDialog.Field.Name.Error.AtLeastOneLang'),
            (v) => (this.selectedLanguages.length > 1 ? !v || (v.length >= 2 && v.length <= 255) : v && (v.length >= 2 && v.length <= 255)) || this.$t('Restaurant.MenuAddDialog.Field.Name.Error.Between')
          ],
          Price: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Restaurant.MenuAddDialog.Field.Price.Error.Valid')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 500) || this.$t('Restaurant.MenuAddDialog.Field.Description.Error.Between')
          ],
          Category: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.MenuAddDialog.Field.Categories.Error.Required'),
            (v) => v && v.length >= 1 && v.length <= 20 || this.$t('Restaurant.MenuAddDialog.Field.Categories.Error.Max')
          ],
          DefaultCategory: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.MenuAddDialog.Field.DefaultCategory.Error.Required')
          ],
          Catalogs: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.MenuAddDialog.Field.Catalogs.Error.Required')
          ],

          TaxId: [
            (v) => !!v || this.$t('Restaurant.CategoryAddDialog.Field.Tax.Error.Required')
          ],
          Tax2Id: [
            (v) => !this.appSupportSecondTax || (this.posUserCan('Tables', 'ACCESS') && !!v) || this.$t('Restaurant.CategoryAddDialog.Field.Tax2.Error.Required')
          ],

          ClassificationTypeCode: [
            (v) => !this.appSupportIncomeClassifications || (!!v) || this.$t('Restaurant.CategoryAddDialog.Field.ClassificationTypeCode.Error.Required')
          ],
          ClassificationCategoryCode: [
            (v) => !this.appSupportIncomeClassifications || (!!v) || this.$t('Restaurant.CategoryAddDialog.Field.ClassificationCategoryCode.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    extraCharges () {
      const charges = this.appConfig?.EXTRA_CHARGES || []
      return charges.filter(charge => !this.editMenu?.Details?.Charges?.find(c => c.Id === charge.Id))
    },

    catalogCategories () {
      return this.categories.filter(category => category.Catalogs.some(catalogId => this.editMenu.Catalogs.includes(catalogId)))
    },

    catalogsExternal () {
      return this.catalogs.filter(catalog => !!catalog.Api)
    },

    preparationDocumentEnabled () {
      return this.appConfig?.LOCATION_DATA?.PreparationDocumentEnabled ?? false
    },

    isVisible: {
      get () {
        if (this.visible) {
          this.editMenu.tmpDetails = JSON.parse(JSON.stringify(this.editMenu.Details))
          this.editMenu.tmpDetails.Options = JSON.parse(JSON.stringify(this.optionsList))
        }
        return this.visible
      },

      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editMenu: {
      get () {
        if (this.menuToEdit) {
          this.newMenu = clone(this.menuToEdit)

          const langObj = {}
          for (const lang of this.selectedLanguages) {
            langObj[lang.Code] = {
              Name           : '',
              Description    : '',
              LongDescription: '',
              Locale         : lang.Code
            }
          }
          if (!this.newMenu.Lang) this.newMenu.Lang = {}
          this.newMenu.Lang = Object.assign({}, langObj, this.newMenu.Lang)

          if (this.catalogs.length === 1) {
            this.newMenu.Catalogs = [this.catalogs[0].Id]
          }
        }

        this.newMenu = clone(this.newMenu)

        return this.newMenu
      }
    }
  },

  watch: {
    'editMenu.Catalogs': function () {
      this.editMenu.Categories = this.editMenu.Categories.filter(categoryId => this.catalogCategories.find(category => category.Id === categoryId))
    },
    'editMenu.Categories': function (newVal) {
      this.updateSelectedCategories()
      this.editMenu.CategoryId = this.selectedCategories.find(category => parseInt(category.Id) === parseInt(this.editMenu.CategoryId))?.Id || null
    },
    'editMenu.TaxId': function (newVal) {
      const tax = this.taxesList.find(t => t.Id === newVal)
      this.editMenu.TaxLang = tax?.Lang || null
      this.editMenu.TaxRate = tax?.Rate || null

      if (this.editMenu.Details) {
        this.editMenu.Details.TaxId = newVal
        this.editMenu.Details.TaxLang = tax?.Lang || null
        this.editMenu.Details.TaxRate = tax?.Rate || null
      }
    },
    'editMenu.Tax2Id': function (newVal) {
      const tax = this.taxesList.find(t => t.Id === newVal)
      this.editMenu.Tax2Lang = tax?.Lang || null
      this.editMenu.Tax2Rate = tax?.Rate || null

      if (this.editMenu.Details) {
        this.editMenu.Details.Tax2Id = newVal
        this.editMenu.Details.Tax2Lang = tax?.Lang || null
        this.editMenu.Details.Tax2Rate = tax?.Rate || null
      }
    },

    visible (newVal) {
      this.isDirty = false
      this.loading = false
      this.languageTab = 0
      if (newVal) {
        this.updateSelectedCategories()
        this.$nextTick(() => {
          if (this.$refs.menuForm) this.$refs.menuForm.resetValidation()
          this.watch('editMenu', this.onEditMenuChange, true, false)
        })
      } else {
        this.selectedCategories = []
        this.unwatch('editMenu')
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Menu.Save, this.onSaveResult)
    this.$bus.$on(window.SocketCommand.Category.All, this.setCategoriesList)

    window.callAS(window.SocketCommand.Category.All, {
      config    : 0,
      page_limit: -1
    })

    this.activeTab = 'tab1'
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Menu.Save, this.onSaveResult)
    this.$bus.$off(window.SocketCommand.Category.All, this.setCategoriesList)
  },
  methods: {
    getContrastColor,

    getCategoryCatalog (menuItem) {
      return this.editMenu?.Catalogs?.length > 1 ? this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => menuItem.Catalogs?.includes(catalog.Id))?.map(c => c.Name)?.join(', ') || '' : ''
    },

    onSaveButtonClick () {
      this.$refs.menuForm.validate()

      this.$nextTick(() => {
        if (this.menuFormValid) {
          this.loading = true
          this.onPhotoChange()
          this.editMenu.Details.ImageBase64 = this.editMenu.Details.Image ? null : this.tmpImageBase64 ? this.tmpImageBase64.img : ''
          this.editMenu.Details.ImageMime = this.tmpImageBase64 ? this.tmpImageBase64.mime : ''
          this.editMenu.Details.ImageExt = this.tmpImageBase64 ? this.tmpImageBase64.ext : ''

          // eslint-disable-next-line no-return-assign
          this.editMenu.Details.Options.forEach((option, index) => option.Priority = (index + 1))

          const saveMenu = JSON.parse(JSON.stringify(this.editMenu))

          if (saveMenu.Details.OptionsCopy) delete saveMenu.Details.OptionsCopy

          saveMenu.Details.Categories = JSON.parse(JSON.stringify(saveMenu.Categories))
          saveMenu.tmpDetails = null

          saveMenu.Name = saveMenu.Details.Name = saveMenu.Lang[this.defaultLanguage.Code].Name
          saveMenu.Description = saveMenu.Details.Description = saveMenu.Lang[this.defaultLanguage.Code].Description

          saveMenu.Details.TaxId = saveMenu.TaxId
          saveMenu.Details.TaxLang = saveMenu.TaxLang
          saveMenu.Details.TaxRate = saveMenu.TaxRate

          saveMenu.Details.Tax2Id = saveMenu.Tax2Id
          saveMenu.Details.Tax2Lang = saveMenu.Tax2Lang
          saveMenu.Details.Tax2Rate = saveMenu.Tax2Rate

          saveMenu.Details.ClassificationTypeCode = saveMenu.ClassificationTypeCode
          saveMenu.Details.ClassificationCategoryCode = saveMenu.ClassificationCategoryCode

          saveMenu.Details.ClassificationTypeCodeInvoice = saveMenu.ClassificationTypeCodeInvoice
          saveMenu.Details.ClassificationCategoryCodeInvoice = saveMenu.ClassificationCategoryCodeInvoice

          if (!saveMenu.TaxId || !saveMenu.TaxLang || !saveMenu.TaxRate) {
            const taxId = saveMenu.TaxId || this.defaultTax.TaxId
            const tax = this.taxesList.find(t => t.Id === taxId)
            saveMenu.TaxId = saveMenu.Details.TaxId = tax?.Id || null
            saveMenu.TaxLang = saveMenu.Details.TaxLang = tax?.Lang || null
            saveMenu.TaxRate = saveMenu.Details.TaxRate = tax?.Rate || null
          }

          if (!saveMenu.Tax2Id || !saveMenu.Tax2Lang || !saveMenu.Tax2Rate) {
            const tax2Id = saveMenu.Tax2Id || this.defaultTax.Tax2Id
            const tax2 = this.taxesList.find(t => t.Id === tax2Id)
            saveMenu.Tax2Id = saveMenu.Details.Tax2Id = tax2?.Id || null
            saveMenu.Tax2Lang = saveMenu.Details.Tax2Lang = tax2?.Lang || null
            saveMenu.Tax2Rate = saveMenu.Details.Tax2Rate = tax2?.Rate || null
          }

          saveMenu.Details.PrintGroup = saveMenu.PrintGroup || []

          saveMenu.Details.Catalogs = saveMenu.Catalogs || []

          saveMenu.Details.ActivePos = saveMenu.ActivePos || false

          window.callAS(window.SocketCommand.Menu.Save, { menu: saveMenu }, '', 300000)
        } else {
          this.$refs.menuForm.validate()
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.FieldError'),
            type: 'error',
            icon: 'warning'
          })
        }
      })
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.croppa) this.croppa.remove()
      this.onPhotoRemoved()
      this.activeTab = 'tab1'
      this.newMenu = null
      if (this.$refs.menuForm) this.$refs.menuForm.resetValidation()
      this.isVisible = false
      this.isDirty = false
    },
    onPhotoChange () {
      if (this.croppa && this.croppa.hasImage() && (this.croppa.getChosenFile() || this.croppa.initialImage) && !this.editMenu.Details.Image) {
        this.tmpImage = this.croppa.getChosenFile() || this.croppa.initialImage
        this.tmpImageBase64 = {
          img : this.croppa.generateDataUrl('image/jpeg', 1),
          mime: 'image/jpeg', // this.croppa.getChosenFile().type,
          ext : 'jpg' // this.croppa.getChosenFile().name.split('.')[1]
        }
      }
    },
    onPhotoRemoved () {
      this.editMenu.Details.Image = ''
      this.tmpImage = null
      this.tmpImageBase64 = null
    },

    addExtraCharge (item) {
      if (!item) return
      const charge = JSON.parse(JSON.stringify(item))
      this.editMenu.Details.Charges.push(charge)
      this.$nextTick(() => {
        this.selectedExtraChargeListItem = null
      })
    },
    removeExtraCharge (index) {
      if (index < 0) return
      this.editMenu.Details.Charges.splice(index, 1)
    },

    setCategoriesList (data) {
      this.categories = data?.items || []
    },
    onChargesSort (e) {
      this.editMenu.Details.Charges.forEach((charge, index) => {
        charge.Priority = (index + 1)
      })
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },
    onResize () {
      const card = this.$refs.dialogCard
      const cardText = this.$refs.dialogCardText
      if (card && card.$el && cardText) {
        cardText.style.height = 800 + 'px'
        cardText.style.height = (card.$el.offsetHeight - 200) + 'px'
      }
    },
    invertColor (hex, bw) {
      if (!hex) return '#FFFFFF'

      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      if (hex.length !== 6) {
        // eslint-disable-next-line no-console
        console.warn('Invalid HEX color.')
        return '#FFFFFF'
      }
      let r = parseInt(hex.slice(0, 2), 16)
      let g = parseInt(hex.slice(2, 4), 16)
      let b = parseInt(hex.slice(4, 6), 16)
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#323232'
          : '#FFFFFF'
      }
      // invert color components
      r = (255 - r).toString(16)
      g = (255 - g).toString(16)
      b = (255 - b).toString(16)
      // pad each with zeros and return
      return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
    },
    updateSelectedCategories () {
      this.selectedCategories = []
      this.editMenu.Categories.forEach(catID => {
        const category = this.categories.find(category => category.Id.toString() === catID.toString())
        if (category) this.selectedCategories.unshift(category)
      })
    },
    onEditMenuChange () {
      this.isDirty = true
      this.calculateOptionsPrice()
    },
    calculateOptionsPrice () {
      let price = 0
      if (this.editMenu && this.editMenu.Details) {
        for (let i = 0; i < this.editMenu.Details.Options.length; i++) {
          const item = this.editMenu.Details.Options[i]
          for (let ii = 0; ii < item.OptionValues.length; ii++) {
            const option = item.OptionValues[ii]
            if (item.DisplayType === 'checkbox' || item.DisplayType === 'extra_charges') {
              if (option.Selected) {
                price += parseFloat(option.Price)
              }
            } else if (item.DisplayType === 'radio') {
              if (parseInt(item.DefaultValueId) === parseInt(option.Id)) {
                price += parseFloat(option.Price)
              }
            }
          }
        }
      }
      this.editMenu.OptionsPrice = price.toFixed(2)
    }
  }
}
</script>

<style scoped>

/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

/deep/ .v-tabs__icon--prev {
  text-align  : left;
  line-height : 48px !important;
}

/deep/ .v-tabs__icon--next {
  text-align  : right;
  line-height : 48px !important;
}

/deep/ .v-input--radio-group .v-input__control {
  width : 100%;
}

/deep/ .v-input--radio-group .v-radio {
  display        : inline-block;
  max-width      : 24px;
  vertical-align : middle;
  margin         : 0;
  padding        : 0;
}

/deep/ .v-input--radio-group .v-radio label {
  display : none;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 75px !important;*/
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 14px !important;
  padding-right : 0;
  margin        : 0;
}

.divTable {
  display     : table;
  width       : 100%;
  padding-top : 12px;
  font-size   : 14px;
  color       : rgba(0, 0, 0, 0.85);
}
</style>
