import BaseModel from '../../../lib/data/Models/BaseModel'

export default class SupportStatusModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.ticketstatusid = parseInt(rawData.ticketstatusid) || 0
    this.statusid = this.ticketstatusid
    this.name = rawData.name || ''
    this.isdefault = rawData.isdefault || 0
    this.color = rawData.color || rawData.statuscolor || ''
    this.statusorder = rawData.statusorder || 0
  }

  boot () {

  }

  /* PROPERTIES */

  /* METHODS */
}
