<template>
  <v-dialog
    v-model="isVisible"
    :overlay="false"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card
      v-if="displayOrder && displayOrder.Details"
      ref="cardOrderDetails"
      class="rounded-0"
    >
      <v-toolbar
        ref="cardOrderToolbar"
        class="secondary"
        flat
        height="80"
        max-height="80"
      >
        <v-avatar
          v-if="getOrderSourceTypeById(displayOrder.SourceTypeId)"
          :color="displayOrder.SourceTypeId < 100 ? 'blue-grey lighten-1' : 'white'"
        >
          <v-icon
            v-if="displayOrder.SourceTypeId < 100"
            dark
            medium
          >
            {{ getOrderSourceTypeById(displayOrder.SourceTypeId).logo }}
          </v-icon>

          <img
            v-else
            :src="getOrderSourceTypeById(displayOrder.SourceTypeId).logo"
          >
        </v-avatar>

        <v-toolbar-title class="pl-3">
          <div class="body-3 grey--text text--lighten-3">
            <span v-if="displayOrder.SourceTypeId >= 100">
              {{ $tc('Order.Title', 1) }}
            </span>

            {{
              displayOrder.SourceTypeId >= 100 ? getOrderSourceTypeById(displayOrder.SourceTypeId).desc : getOrderSourceTypeLangById(displayOrder.SourceTypeId).Desc
            }}

            <template
              v-if="parseInt(displayOrder.SourceTypeId) === 4 && displayOrder.Details.Table && displayOrder.Details.Table.Table"
            >
              {{ displayOrder.Details.Table.Table.TableAreaName }} #{{ displayOrder.Details.Table.Table.TableName }}
            </template>
          </div>
          <div class="text-caption grey--text  text--lighten-1">
            {{ $t('Order.OrderDetailsDialog.Toolbar.Subtitle') }}{{ displayOrder.Id }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          dark
          icon
          @click="isVisible = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-0 fill-height"
        :class="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
        style="overflow-y: auto; height: calc(100vh - 220px);"
      >
        <v-container
          class="pa-0"
          fluid
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="5"
            >
              <v-card
                class="transparent"
                flat
                height="100%"
              >
                <v-card-text
                  ref="cardOrderdetailsLeft"
                  class="pb-0"
                  style="overflow-y: auto;"
                >
                  <div class="text-center pt-1">
                    <barcode
                      :id="getBarcode(displayOrder).barcode"
                      :text="(String($tc('Order.Title',1) + ' #' + displayOrder.Id).toLocaleUpperCase($i18n.locale))"
                      :value="getBarcode(displayOrder).barcode"
                      background="white"
                      font-size="12"
                      format="CODE128"
                      height="45"
                      text-margin="2"
                      text-position="bottom"
                      width="2"
                    />

                    <div v-if="displayOrder.DailyCheck && appConfig.LOCATION_DATA.ShowDailyCheck">
                      <v-chip
                        label
                        x-small
                        class="px-1"
                      >
                        {{ $t('Order.Headers.DailyCheck') }}: {{ displayOrder.DailyCheck }}
                      </v-chip>
                    </div>

                    <div
                      v-if="displayOrder.ExternalPlatform"
                      class="text-caption text-uppercase pb-1"
                    >
                      {{ getOrderSourceTypeById(displayOrder.ExternalSourceTypeId).name }}: #{{ displayOrder.ExternalOrderId }}
                    </div>

                    <div
                      v-if="displayOrder.MarketplaceOrderCode"
                      class="text-caption text-uppercase pb-1"
                    >
                      {{ displayOrder.MarketplaceName }}: #{{ displayOrder.MarketplaceOrderCode }}
                    </div>

                    <div
                      v-if="displayOrder.QrData"
                      class="text-caption font-weight-bold pb-1"
                    >
                      {{ displayOrder.QrData }}
                    </div>

                    <template
                      v-if="displayOrder.StaffId && parseInt(displayOrder.SourceTypeId) === 4 && displayOrder.Details.Table && displayOrder.Details.Table.Table"
                    >
                      <div class="pb-2 px-2 d-inline-block">
                        <strong>{{
                          $tc('Restaurant.Tables.Title', 1)
                        }}</strong><br>{{ displayOrder.Details.Table.Table.TableAreaName }}
                        #{{ displayOrder.Details.Table.Table.TableName }}
                      </div>

                      <div class="pb-2 px-2 d-inline-block">
                        <strong>{{ $tc('Waiter.Title', 1) }}</strong><br>{{ displayOrder.StaffFirstname }}
                        {{ displayOrder.StaffLastname }}
                      </div>
                    </template>

                    <print-button
                      v-if="printOrderDocumentVisible(displayOrder)"
                      hide-label
                      icon
                      menu-offset-y
                      menu-position="left"
                      open-on-hover
                      style="position: absolute; top: 25px; right: 25px;"
                      @printer-selected="printer => printOrder(displayOrder, printer)"
                    />
                  </div>

                  <v-divider class="mb-2" />

                  <template v-if="displayOrder.Details.Table.Table">
                    <v-card flat>
                      <v-card-text>
                        <v-container
                          class="pa-0"
                          fluid
                        >
                          <v-row>
                            <v-col cols="12">
                              <v-tabs
                                v-model="tab"
                                :grow="true"
                                :slider-color="tabsSliderColor"
                                background-color="transparent"
                              >
                                <v-tab
                                  :disabled="!completedCount"
                                  href="#tab-orders"
                                >
                                  <v-badge
                                    :value="!!completedCount"
                                    color="blue"
                                    right
                                  >
                                    <span slot="badge">
                                      {{ completedCount }}
                                    </span>
                                    <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Orders.Title') }}</span>
                                  </v-badge>
                                </v-tab>

                                <v-tab
                                  :disabled="!unpaidCount"
                                  href="#tab-unpaid"
                                >
                                  <v-badge
                                    :value="!!unpaidCount"
                                    color="orange"
                                    right
                                  >
                                    <span slot="badge">
                                      {{ unpaidCount }}
                                    </span>
                                    <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Unpaid.Title') }}</span>
                                  </v-badge>
                                </v-tab>

                                <v-tab
                                  :disabled="!paidCount"
                                  href="#tab-paid"
                                >
                                  <v-badge
                                    :value="!!paidCount"
                                    color="green"
                                    right
                                  >
                                    <span slot="badge">
                                      {{ paidCount }}
                                    </span>
                                    <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Paid.Title') }}</span>
                                  </v-badge>
                                </v-tab>

                                <v-tab
                                  :disabled="!canceledCount"
                                  href="#tab-canceled"
                                >
                                  <v-badge
                                    :value="!!canceledCount"
                                    color="red"
                                    right
                                  >
                                    <span slot="badge">
                                      {{ canceledCount }}
                                    </span>
                                    <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Canceled.Title') }}</span>
                                  </v-badge>
                                </v-tab>
                              </v-tabs>
                            </v-col>

                            <v-col cols="12">
                              <v-tabs-items v-model="tab">
                                <v-tab-item value="tab-orders">
                                  <table-completed-orders-list
                                    :order-id="displayOrder.Id"
                                    :print="true"
                                    :print-order="false"
                                    :print-order-preparation="false"
                                    :print-receipt="parseInt(displayOrder.StatusId) !== 19"
                                    :table="displayOrder.Details.Table.Table"
                                    :view-data="displayOrder.Details.Table"
                                    desc
                                  />
                                </v-tab-item>

                                <v-tab-item value="tab-unpaid">
                                  <table-completed-orders-list
                                    :table="displayOrder.Details.Table.Table"
                                    :view-data="displayOrder.Details.Table"
                                    desc
                                    unpaid
                                  />
                                </v-tab-item>

                                <v-tab-item value="tab-paid">
                                  <table-payments-list
                                    :table="displayOrder.Details.Table.Table"
                                    :view-data="displayOrder.Details.Table"
                                    desc
                                  />
                                </v-tab-item>

                                <v-tab-item value="tab-canceled">
                                  <table-completed-orders-list
                                    :print="false"
                                    :table="displayOrder.Details.Table.Table"
                                    :view-data="displayOrder.Details.Table"
                                    canceled
                                    desc
                                  />
                                </v-tab-item>
                              </v-tabs-items>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </template>

                  <template v-else>
                    <template v-for="category in displayOrder.Details.CartItemsWithCategories">
                      <div
                        :key="category.Category.Id"
                        :style="'border-left: #' + (category.Category.Color ? category.Category.Color : 'e1e1e1') + ' 3px solid; ' + (isDarkMode ? 'background-color: #191919;' : 'background-color: #e1e1e1;')"
                        class="mt-2"
                      >
                        <v-subheader class="text-subtitle-1 pl-4 pt-2 pb-2">
                          {{ getTranslatedField(category.Category, 'Name') }}
                        </v-subheader>
                      </div>

                      <template v-for="(item, i) in category.Items">
                        <div :key="item.Id + '_' + i + '_' + category.Category.Id">
                          <div
                            class="text-body-2 font-weight-medium pa-1"
                            style="display: table;"
                          >
                            <div style="display: table-row-group; vertical-align: middle;">
                              <template v-if="item.UnitId > 1">
                                <span
                                  class="float-left orange--text mr-1"
                                  style="display: table-cell; vertical-align: middle; white-space: nowrap; min-width: 48px;"
                                >
                                  {{
                                    formatUnitQuantity($i18n.locale, item.UnitQuantity, getMeasurementUnitById(item.UnitId))
                                  }}
                                </span>
                              </template>
                              <template v-else>
                                <span
                                  class="float-left orange--text mr-1"
                                  style="display: table-cell; vertical-align: middle; white-space: nowrap; min-width: 28px;"
                                >
                                  {{ item.Quantity }} x
                                </span>
                              </template>
                              <span style="display: table-cell; vertical-align: middle; width: 100%;">
                                {{ getTranslatedField(item, 'Name') }}
                              </span>
                              <span
                                class="float-right ml-2"
                                style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                              >
                                {{ item.SubTotal | currency }}
                              </span>
                            </div>
                          </div>

                          <div
                            v-if="item.OptionsWithGroups && Array.isArray(item.OptionsWithGroups) && item.OptionsWithGroups.length"
                            class="grey--text text--darken-1 ml-1 text-caption"
                          >
                            <template v-for="(optionGroup, idx) in item.OptionsWithGroups">
                              <div
                                :key="idx"
                                style="display: table-row-group; vertical-align: middle;"
                              >
                                <div
                                  :style="`min-width:${item.UnitId > 1 ? 48 : 28}px`"
                                  style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                                />
                                <div
                                  style="display: table-cell; vertical-align: middle; white-space: nowrap; width: 100%;"
                                >
                                  <div
                                    v-if="optionGroup.display"
                                    class="text-caption font-weight-bold pt-2 pb-1 grey--text text--darken-2 ml-1"
                                  >
                                    {{
                                      optionGroup.group[$i18n.locale] || optionGroup.group[defaultLanguage.Code] || optionGroup.group[Object.keys(optionGroup.group) && Object.keys(optionGroup.group)[0]]
                                    }}
                                  </div>
                                  <template
                                    v-for="(option, odx) in (optionGroup.options[$i18n.locale] || optionGroup.options[defaultLanguage.Code] || optionGroup.options[Object.keys(optionGroup.options) && Object.keys(optionGroup.options)[0]])"
                                  >
                                    <div
                                      :key="odx"
                                      style="margin-left: 5px !important;"
                                    >
                                      • <span v-html="option" />
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </template>
                          </div>

                          <div
                            v-if="item.Comment"
                            class="ml-1 text-caption"
                            :class="isDarkMode ? 'white--text' : 'black--text'"
                          >
                            {{ $t('Order.OrderDetailsDialog.Customer.Comments') }}
                            <span class="grey--text text--darken-1">
                              {{ item.Comment }}
                            </span>
                          </div>
                        </div>
                        <v-divider
                          :key="item.Id + '_' + i + '_' + category.Category.Id + '_divider'"
                          class="my-2"
                        />
                      </template>
                    </template>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="7"
            >
              <v-card
                :class="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                class="py-4"
                flat
              >
                <v-card-text
                  ref="cardOrderDetailsRight"
                  :style="isDarkMode ? 'background-color: #1e1e1e' : 'white'"
                  style="overflow-y: auto;"
                >
                  <v-container
                    class="pa-0"
                    fluid
                  >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-img
                          v-if="isCustomAddress(displayOrder.Details.CustomerAddress) && !isQrOrder(displayOrder)"
                          :height="acceptCustomAddresses ? 320 : 350"
                          position="center center"
                          src="/assets/map/map-placeholder.png"
                          style="border: 1px solid #e2e2e2;"
                          width="100%"
                        />

                        <v-map
                          v-if="!isCustomAddress(displayOrder.Details.CustomerAddress) && !isQrOrder(displayOrder) && parseInt(displayOrder.OrderTypeId) === 1 && parseInt(displayOrder.AddressId) > -1 && displayOrder.Details.CustomerAddress && mapVisible"
                          :address-marker="markerAddress"
                          :map-center="mapCenter"
                          height="350px"
                          show-address-marker
                          show-store-marker
                        />
                      </v-col>

                      <template v-if="parseInt(displayOrder.OrderTypeId) === 1">
                        <v-col
                          v-if="parseInt(displayOrder.AddressId) > -1 && displayOrder.Details.CustomerAddress && !isQrOrder(displayOrder)"
                          class="mb-2 mt-2"
                          cols="12"
                        >
                          <v-divider />
                        </v-col>

                        <v-col
                          v-if="parseInt(displayOrder.AddressId) > -1 && displayOrder.Details.CustomerAddress && displayOrder.Details.CustomerAddress.Address"
                          cols="12"
                          sm="6"
                        >
                          <div class="grey--text text--darken-1">
                            <span :class="isDarkMode ? 'white--text' : 'black--text'">
                              {{ $t('Order.OrderDetailsDialog.Customer.Address.Street') }}
                            </span> {{
                              displayOrder.Details.CustomerAddress.Address + ' ' + (displayOrder.Details.CustomerAddress.Number || '')
                            }}
                          </div>
                        </v-col>
                        <v-col
                          v-if="parseInt(displayOrder.AddressId) > -1 && displayOrder.Details.CustomerAddress && (displayOrder.Details.CustomerAddress.City || displayOrder.Details.CustomerAddress.Postcode)"
                          cols="12"
                          sm="6"
                        >
                          <div class="grey--text text--darken-1">
                            <template v-if="displayOrder.Details.CustomerAddress.City">
                              <span :class="isDarkMode ? 'white--text' : 'black--text'">
                                {{ $t('Order.OrderDetailsDialog.Customer.Address.City') }}
                              </span> {{ displayOrder.Details.CustomerAddress.City }}
                            </template>

                            <template v-if="displayOrder.Details.CustomerAddress.Postcode">
                              <span
                                class="pl-2"
                                :class="isDarkMode ? 'white--text' : 'black--text'"
                              >
                                {{ $t('Order.OrderDetailsDialog.Customer.Address.PostCode') }}
                              </span> {{ displayOrder.Details.CustomerAddress.Postcode }}
                            </template>
                          </div>
                        </v-col>
                        <v-col
                          v-if="parseInt(displayOrder.AddressId)>-1 && displayOrder.Details.CustomerAddress && displayOrder.Details.CustomerAddress.DoorBell"
                          cols="12"
                          sm="6"
                        >
                          <div class="grey--text text--darken-1">
                            <span :class="isDarkMode ? 'white--text' : 'black--text'">
                              {{ $t('Order.OrderDetailsDialog.Customer.Address.Bell') }}
                            </span> {{ displayOrder.Details.CustomerAddress.DoorBell }}
                          </div>
                        </v-col>
                        <v-col
                          v-if="parseInt(displayOrder.AddressId)>-1 && displayOrder.Details.CustomerAddress && displayOrder.Details.CustomerAddress.Floor"
                          cols="12"
                          sm="6"
                        >
                          <div class="grey--text text--darken-1">
                            <span :class="isDarkMode ? 'white--text' : 'black--text'">
                              {{ $t('Order.OrderDetailsDialog.Customer.Address.Floor') }}
                            </span> {{ displayOrder.Details.CustomerAddress.Floor }}
                          </div>
                        </v-col>
                        <v-col
                          v-if="displayOrder.Details.CustomerAddress && displayOrder.Details.CustomerAddress.Comment"
                          cols="12"
                          sm="6"
                        >
                          <div class="grey--text text--darken-1">
                            <span :class="isDarkMode ? 'white--text' : 'black--text'">
                              {{ $t('Order.OrderDetailsDialog.Customer.Address.Comment') }}
                            </span> {{ displayOrder.Details.CustomerAddress.Comment }}
                          </div>
                        </v-col>
                      </template>

                      <v-col
                        v-if="parseInt(displayOrder.AddressId)>-1 && displayOrder.Details.CustomerAddress && !isQrOrder(displayOrder)"
                        class="mt-2"
                        cols="12"
                      >
                        <v-divider />
                      </v-col>

                      <v-col
                        class="mt-2"
                        cols="12"
                        sm="6"
                      >
                        <div class="grey--text text--darken-1">
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Order.OrderDetailsDialog.Customer.Name') }}
                          </span>

                          <v-icon
                            :class="[parseInt(displayOrder.CustomerId) === 0 ? 'grey--text' : 'amber--text']"
                            class="text--lighten-2"
                            small
                          >
                            verified_user
                          </v-icon>

                          <span
                            v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && parseInt(displayOrder.CustomerId) !== 0"
                            :class="isDarkMode ? 'grey--text text--lighten-1' : 'grey--text text--darken-3'"
                            style="cursor: pointer;"
                            @click="$bus.$emit('show-customer-details-dialog', displayOrder.CustomerId)"
                          >
                            {{ displayOrder.FullName }}
                          </span>

                          <template v-else>
                            <span :class="isDarkMode ? 'grey--text text--lighten-1' : 'grey--text text--darken-3'">
                              {{
                                parseInt(displayOrder.CustomerId) !== 0 || displayOrder.FullName.trim() ? displayOrder.FullName : $t('Customer.CustomerType.Visitor')
                              }}
                            </span>
                          </template>
                        </div>
                      </v-col>

                      <v-col
                        v-if="displayOrder.Email && !emailIsGuest(displayOrder.Email)"
                        class="mt-2"
                        cols="12"
                        sm="6"
                      >
                        <div class="grey--text text--darken-1">
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Order.OrderDetailsDialog.Customer.Email') }}:
                          </span> {{ displayOrder.Email }}
                        </div>
                      </v-col>

                      <v-col
                        v-if="displayOrder.Telephone && $options.filters.phone(displayOrder.Telephone)"
                        class="mt-2"
                        cols="12"
                        sm="6"
                      >
                        <div
                          class="grey--text text--darken-1"
                          style="white-space: nowrap;"
                        >
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Order.OrderDetailsDialog.Customer.Phone') }}
                          </span> {{ displayOrder.Telephone | phone }}
                        </div>
                      </v-col>

                      <v-col
                        v-if="displayOrder.Telephone2 && $options.filters.phone(displayOrder.Telephone2)"
                        class="mt-2"
                        cols="12"
                        sm="6"
                      >
                        <div
                          class="grey--text text--darken-1"
                          style="white-space: nowrap;"
                        >
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Order.OrderDetailsDialog.Customer.Phone') }}
                          </span> {{ displayOrder.Telephone2 | phone }}
                        </div>
                      </v-col>

                      <v-col
                        v-if="displayOrder.Telephone3 && $options.filters.phone(displayOrder.Telephone3)"
                        class="mt-2"
                        cols="12"
                        sm="6"
                      >
                        <div
                          class="grey--text text--darken-1"
                          style="white-space: nowrap;"
                        >
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Order.OrderDetailsDialog.Customer.Phone') }}
                          </span> {{ displayOrder.Telephone3 | phone }}
                        </div>
                      </v-col>

                      <v-col
                        class="mt-2"
                        cols="12"
                      >
                        <v-divider />
                      </v-col>

                      <v-col
                        class="mt-2"
                        cols="12"
                        sm="6"
                      >
                        <div class="grey--text text--darken-1">
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Common.Date.Time').toLocaleUpperCase($i18n.locale) }}:
                          </span> {{ displayOrder.TimeAdded }}
                        </div>
                      </v-col>

                      <v-col
                        class="mt-2"
                        cols="12"
                        sm="6"
                      >
                        <div class="grey--text text--darken-1">
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Common.Date.DateShort').toLocaleUpperCase($i18n.locale) }}:
                          </span> {{ displayOrder.DateAdded }}
                        </div>
                      </v-col>

                      <v-col
                        class="mt-2"
                        cols="12"
                      >
                        <v-divider />
                      </v-col>

                      <v-col
                        v-if="displayOrder.Comment"
                        class="mt-2"
                        cols="12"
                      >
                        <div class="grey--text text--darken-1">
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Order.OrderDetailsDialog.Customer.Comments') }}
                          </span> {{ displayOrder.Comment }}
                        </div>
                      </v-col>

                      <v-col
                        v-if="displayOrder.Comment"
                        class="mt-2"
                        cols="12"
                      >
                        <v-divider />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <div class="mr-2 mt-2">
                          <order-status-chip :order="displayOrder" />
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <div class="mr-2 mt-2">
                          <order-type-chip
                            :order="displayOrder"
                            label
                          />
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <div class="mr-2 mt-2">
                          <order-payments-chip :item="displayOrder" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <v-card
                v-if="userCanViewReceipt && (isSelectedOrderToday || selectedOrderHasReceipts)"
                class="mb-4"
                :style="isDarkMode ? 'background-color: #1e1e1e' : 'white'"
                flat
              >
                <v-card-title
                  class="font-weight-bold py-1"
                  style="min-height: 53px;"
                >
                  {{ $t('Order.OrderDetailsDialog.Receipt.Title') }}

                  <v-spacer />

                  <issue-receipt-document-button
                    v-if="issueReceiptDocumentVisible(displayOrder) && isSelectedOrderToday && displayOrder.Details && displayOrder.Details.CanGenerateReceipt"
                    :badge-offset-x="20"
                    :badge-offset-y="20"
                    :can-issue-document="displayOrder.Details.CanGenerateReceipt"
                    :can-issue-receipt="displayOrder.Details.CanGenerateReceipt"
                    :document-id="displayOrder.Details.ReceiptId"
                    :document-type="displayOrder.ReceiptType"
                    :document-url="displayOrder.DocumentUrl"
                    :icon-name="displayOrder.Details.ReceiptId && !displayOrder.Details.CanGenerateReceipt ? 'mdi-receipt-text-edit-outline' : 'mdi-receipt-text-plus-outline'"
                    :invoice="appReceiptsProviderDocument.includes(ReceiptTypeEnum.INVOICE) && appHasInvoiceDocEnabled"
                    :receipt="appReceiptsProviderDocument.includes(ReceiptTypeEnum.RECEIPT) && appHasReceiptDocEnabled"
                    :label="displayOrder.Details.ReceiptId && !displayOrder.Details.CanGenerateReceipt ? $t('Common.Button.ManageDocument').toLocaleUpperCase($i18n.locale) : $t('Common.Button.IssueDocument').toLocaleUpperCase($i18n.locale)"
                    :tooltip="displayOrder.Details.ReceiptId && !displayOrder.Details.CanGenerateReceipt ? item.Details.ReceiptType === ReceiptTypeEnum.RECEIPT ? $t('Common.Button.ManageReceipt') : item.Details.ReceiptType === ReceiptTypeEnum.INVOICE ? $t('Common.Button.ManageInvoice') : $t('Common.Button.ManageFnb') : $t('Common.Button.IssueDocument')"
                    :tooltip-open-delay="1500"
                    depressed
                    small
                    tooltip-position="top"
                    @view-document="data => $bus.$emit('fiscal-document:view', data.DocUrl)"
                    @issue-document="data => $bus.$emit('fiscal-document:create', displayOrder, data.DocType)"
                    @cancel-document="data => $bus.$emit('fiscal-document:cancel', displayOrder, data)"
                    @print-document="data => $bus.$emit('fiscal-document:print', displayOrder.Details.ReceiptId)"
                    @email-document="data => $bus.$emit('fiscal-document:email', displayOrder.Details.ReceiptId, displayOrder, data)"
                  />
                </v-card-title>

                <v-divider />

                <v-card-text
                  ref="cardOrderDetailsReceipts"
                  class=""
                  style="overflow-y: auto;"
                >
                  <receipts-table
                    :cancel="userCanViewReceipt"
                    :email="parseInt(displayOrder.StatusId) !== 19 && appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
                    :print="parseInt(displayOrder.StatusId) !== 19 && appMachineHasReceiptPrinters"
                    :receipts="displayOrder.Details.OrderReceipts"
                    :orders="ordersList"
                    alternate-group-colors
                  />
                </v-card-text>
              </v-card>

              <v-card
                v-if="isSelectedOrderToday || selectedOrderHasTransactions"
                class="mb-4"
                :style="isDarkMode ? 'background-color: #1e1e1e' : 'white'"
                flat
              >
                <v-card-title
                  class="font-weight-bold py-1"
                  style="min-height: 53px;"
                >
                  {{ $t('Order.OrderDetailsDialog.Transaction.Title') }}

                  <v-spacer />
                </v-card-title>

                <v-divider />

                <v-card-text
                  ref="cardOrderDetailsTransactions"
                  class=""
                  style="overflow-y: auto;"
                >
                  <order-payment-transaction-table :transactions="displayOrder.Details.Payments" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions
        ref="cardOrderFooter"
        class="secondary px-1 px-sm-4"
      >
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            no-gutters
            style="align-items: center;"
          >
            <v-col
              cols="12"
              md="5"
            >
              <template v-for="(item, i) in displayOrder.Details.CartTotals">
                <div
                  :key="i"
                  class="text-body-2 white--text px-1"
                >
                  {{
                    (item.Code === 'delivery' ? $t('Order.OrderDetailsDialog.Footer.DeliveryCost') : item.Code === 'coupon' ? displayOrder.SourceTypeId === 1 || displayOrder.SourceTypeId === 10 ? getTranslatedField(item, 'Name', 'Lang', true, 'Title') + ': ' + item.Title : item.Title : getTranslatedField(item, 'Name', 'Lang', true, 'Title'))
                  }}
                  <span class="float-right pr-0 pr-md-6">
                    {{ item.Value | currency }}
                  </span>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppData                      from '@/mixins/appdata'
import Auth                         from '@/mixins/auth'
import Translatable                 from '@/mixins/translatable'
import OrderStatus                  from '@/mixins/orders/orderStatus'
import OrderSourceType              from '@/mixins/orders/sourceType'
import OrderType                    from '@/mixins/orders/orderType'
import OrderTypeTime                from '@/mixins/orders/orderTypeTime'
import BarcodeGenerator             from '@/lib/barcode/BarcodeGenerator'
import BarcodePrefixEnum            from '@/api/Enums/BarcodePrefixEnum'
import OrdersCommon                 from '@/mixins/orders/common'
import PrintButton                  from '@/components/common/print/PrintButton.vue'
import TableCompletedOrdersList     from '@/components/restaurant/tables/orders/TableCompletedOrdersList.vue'
import TablePaymentsList            from '@/components/restaurant/tables/payments/TablePaymentsList.vue'
import ReceiptsTable                from '@/components/receipts/ReceiptsTable.vue'
import { formatUnitQuantity }       from '@/lib/currency/currency'
import { emailIsGuest }             from '@/lib/helper/helper'
import MapsCommon                   from '@/mixins/maps/mapsCommon'
import AddressCommon                from '@/mixins/maps/addressCommon'
import VMap                         from '@/components/common/maps/VMap.vue'
import IssueReceiptDocumentButton   from '@/components/common/receipt/IssueReceiptDocumentButton.vue'
import ReceiptTypeEnum              from '@/api/Enums/ReceiptTypeEnum'
import NotifyTypeEnum               from '@/api/Enums/NotifyTypeEnum'
import OrderPaymentsChip            from '@/components/orders/common/OrderPaymentsChip.vue'
import OrderPaymentTransactionTable from '@/components/orders/common/OrderPaymentTransactionTable.vue'
import SourceTypeEnum               from '@/api/Enums/SourceTypeEnum'
import OrderTypeChip                from '@/components/orders/common/OrderTypeChip.vue'
import OrderStatusChip              from '@/components/orders/common/OrderStatusChip.vue'

export default {
  name      : 'OrderDetailsDialog',
  components: {
    OrderStatusChip,
    OrderTypeChip,
    OrderPaymentTransactionTable,
    OrderPaymentsChip,
    IssueReceiptDocumentButton,
    VMap,
    ReceiptsTable,
    TablePaymentsList,
    TableCompletedOrdersList,
    PrintButton,
    barcode: BarcodeGenerator
  },
  mixins: [AppData, Auth, MapsCommon, AddressCommon, Translatable, OrdersCommon, OrderStatus, OrderSourceType, OrderType, OrderTypeTime],
  props : {
    orderDetailsDialogVisible: {
      type   : Boolean,
      default: false
    },
    displayOrder: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      mapVisible     : false,
      tab            : 'tab-orders',
      tabsSliderColor: 'blue'
    }
  },
  computed: {
    SourceTypeEnum () {
      return SourceTypeEnum
    },
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },
    defaultActiveTab () {
      let retVal = this.tab || 'tab-orders'

      if (!this.completedCount && this.tab === 'tab-orders') retVal = 'tab-canceled'
      if (!this.unpaidCount && this.tab === 'tab-unpaid') retVal = 'tab-orders'
      if (!this.paidCount && this.tab === 'tab-paid') retVal = 'tab-orders'
      if (!this.canceledCount && this.tab === 'tab-canceled') retVal = 'tab-orders'
      if (!this.receiptsCount && this.tab === 'tab-receipts') retVal = 'tab-orders'

      if (!this.completedCount && retVal === 'tab-orders') retVal = 'tab-canceled'

      return retVal
    },

    ordersList () {
      return this.displayOrder?.Details?.Table?.Completed || []
    },

    paidCount () {
      return this.displayOrder?.Details?.Table?.PaidCount || 0
    },
    canceledCount () {
      return this.displayOrder?.Details?.Table?.CanceledCount || 0
    },
    unpaidCount () {
      return this.displayOrder?.Details?.Table?.UnPaidCount || 0
    },
    completedCount () {
      return this.displayOrder?.Details?.Table?.OrdersCount || 0
    },
    receiptsCount () {
      return this.displayOrder?.Details?.Table?.ReceiptsCount || this.displayOrder?.Details?.OrderReceipts?.length || 0
    },

    ordersToday () {
      return this.$DataStore?.ordersDataToday?.items || []
    },
    selectedOrderReceipts () {
      return this.displayOrder?.Details?.OrderReceipts || []
    },
    selectedOrderHasReceipts () {
      return this.selectedOrderReceipts.length > 0
    },
    selectedOrderTransactions () {
      return this.displayOrder?.Details?.PaymentTransactions || []
    },
    selectedOrderHasTransactions () {
      return this.selectedOrderTransactions.length > 0
    },
    isSelectedOrderToday () {
      return !!this.ordersToday.find(order => parseInt(order.Id) === parseInt(this.displayOrder.Id))
    },
    isVisible: {
      get () {
        return this.orderDetailsDialogVisible
      },
      set (val) {
        this.$emit('update:orderDetailsDialogVisible', val)
      }
    }
  },
  watch: {
    displayOrder () {
      this.tab = this.defaultActiveTab
    },

    tab (newVal) {
      if (newVal === 'tab-orders') this.tabsSliderColor = 'blue'
      if (newVal === 'tab-unpaid') this.tabsSliderColor = 'orange'
      if (newVal === 'tab-paid') this.tabsSliderColor = 'green'
      if (newVal === 'tab-canceled') this.tabsSliderColor = 'red'
      if (newVal === 'tab-receipts') this.tabsSliderColor = 'indigo'
    },

    isVisible (newVal) {
      if (newVal) {
        this.tab = 'tab-orders'
        this.$nextTick(() => {
          this.tab = this.defaultActiveTab
        })
        this.$bus.$on(window.SocketCommand.Ajax.Get, this.refreshOrderDetails)
        this.$bus.$on(window.SocketCommand.Order.Payment.Transaction.OrderResult, this.onOrderPaymentTransactionResult)
        this.$bus.$on(window.SocketCommand.Receipt.Update, this.onFiscalDocumentUpdate)
      } else {
        this.$bus.$off(window.SocketCommand.Ajax.Get, this.refreshOrderDetails)
        this.$bus.$off(window.SocketCommand.Order.Payment.Transaction.OrderResult, this.onOrderPaymentTransactionResult)
        this.$bus.$off(window.SocketCommand.Receipt.Update, this.onFiscalDocumentUpdate)
      }
    },

    'displayOrder.Details': function () {
      if (parseInt(this.displayOrder.AddressId) > -1 && this.displayOrder.Details && this.displayOrder.Details.CustomerAddress) {
        const _self = this
        _self.mapZoom = 17
        _self.mapCenter = _self.markerAddress = JSON.parse(JSON.stringify(_self.markerStore))
        _self.markerVisible = false

        setTimeout(function () {
          _self.mapCenter = _self.markerAddress = {
            lat: parseFloat(_self.displayOrder.Details.CustomerAddress.Lat),
            lng: parseFloat(_self.displayOrder.Details.CustomerAddress.Lng)
          }
          _self.mapZoom = 18
          _self.markerVisible = true
          _self.mapVisible = true
        }, 250)
      } else {
        this.mapVisible = false
      }
    },
    orderDetailsDialogVisible (newVal) {
      if (newVal && parseInt(this.displayOrder.AddressId) > -1 && this.displayOrder.Details && this.displayOrder.Details.CustomerAddress) {
        // console.log('>>>>>  orderDetailsDialogVisible: ', newVal);
        const _self = this
        _self.mapZoom = 17
        _self.mapCenter = _self.markerAddress = JSON.parse(JSON.stringify(_self.markerStore))
        _self.markerVisible = false

        setTimeout(function () {
          _self.mapCenter = _self.markerAddress = {
            lat: parseFloat(_self.displayOrder.Details.CustomerAddress.Lat),
            lng: parseFloat(_self.displayOrder.Details.CustomerAddress.Lng)
          }
          _self.mapZoom = 18
          _self.markerVisible = true
          _self.mapVisible = true
        }, 250)
      }

      if (!newVal) this.mapVisible = false
    }
  },
  updated () {
    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },
  methods: {
    emailIsGuest,
    formatUnitQuantity,
    onFiscalDocumentUpdate () {
      if (!this.displayOrder || !this.isVisible) return

      setTimeout(() => {
        this.getOrderDetails(this.displayOrder)
      }, 1000)
    },

    onOrderPaymentTransactionResult (payload) {
      if (!this.displayOrder || !this.isVisible) return

      payload.command = window.SocketCommand.commandToSocketFormat(window.SocketCommand.Order.Get)
      this.refreshOrderDetails(payload)
    },

    refreshOrderDetails (payload) {
      if (!this.displayOrder || !this.isVisible) return

      const command = window.SocketCommand.Order.Get
      const payloadCommand = window.SocketCommand.commandToClientFormat(payload?.command || '')

      if (payload && payloadCommand === command && this.displayOrder?.Id === payload?.order_id) {
        setTimeout(() => {
          this.getOrderDetails(this.displayOrder)
        }, 1000)
      }
    },

    getBarcode (order) {
      const barcode = {
        barcode: BarcodePrefixEnum.Order + order.Id,
        value  : order.Id
      }

      return barcode
    },
    updateOrderStatus (order) {
      this.isVisible = false
      this.$bus.$emit('order-update-status', order)
    }
  }
}
</script>

<style scoped>

</style>
