import { render, staticRenderFns } from "./CdsWelcome.vue?vue&type=template&id=f5198e9e&scoped=true"
import script from "./CdsWelcome.vue?vue&type=script&lang=js"
export * from "./CdsWelcome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5198e9e",
  null
  
)

export default component.exports