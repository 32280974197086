<template>
  <div class="ma-0 pa-0">
    <template v-if="initialImageSrc">
      <div
        :style="{'max-width': (parseInt(width)*parseFloat(quality)) + 'px'}"
        class="text-center d-inline-block"
        style="width: 100%; min-width: 200px;"
      >
        <div
          :class="initialImageClass"
          :style="initialImageStyle"
          class="my-2"
          style="width: 100%;"
        >
          <v-img
            :contain="initialSize === 'contain'"
            :cover="initialSize === 'cover'"
            :max-width="parseInt(width) * parseFloat(quality)"
            :position="initialPosition"
            :src="initialImageSrc"
            :width="`100%`"
          />
        </div>

        <v-btn
          color="red"
          class="ma-1"
          outlined
          small
          @click="onImageRemove()"
        >
          {{ $t('Settings.Website.Field.Gallery.Image.Control.Remove') }}
        </v-btn>
      </div>
    </template>

    <template v-else>
      <div
        :style="{'max-width': (parseInt(width)*parseFloat(quality)) + 'px'}"
        class="text-center d-inline-block"
        style="width: 100%; min-width: 200px;"
      >
        <div
          :class="initialImageClass"
          :style="initialImageStyle"
          class="my-2"
          style="width: 100%;"
        >
          <croppa
            v-model="croppa"
            :accept="accept"
            :canvas-color="canvasColor"
            :class="containerClass"
            :disable-click-to-choose="disableClickToChoose"
            :disable-drag-and-drop="disableDragAndDrop"
            :disable-drag-to-move="disableDragToMove"
            :disable-pinch-to-zoom="disablePinchToZoom"
            :disable-rotation="disableRotation"
            :disable-scroll-to-zoom="disableScrollToZoom"
            :disabled="disabled"
            :file-size-limit="parseInt(fileSizeLimit)"
            :height="parseInt(height)"
            :image-border-radius="parseInt(imageBorderRadius)"
            :initial-image="initialGalleryImage"
            :initial-position="initialPosition"
            :initial-size="initialSize"
            :loading-color="loadingColor"
            :loading-size="parseInt(loadingSize)"
            :placeholder="defaultPlaceholder"
            :placeholder-color="placeholderColor"
            :placeholder-font-size="parseInt(placeholderFontSize)"
            :prevent-white-space="preventWhiteSpace"
            :quality="parseFloat(quality)"
            :remove-button-color="removeButtonColor"
            :remove-button-size="parseInt(removeButtonSize)"
            :replace-drop="replaceDrop"
            :reverse-scroll-to-zoom="reverseScrollToZoom"
            :show-loading="showLoading"
            :show-remove-button="showRemoveButton"
            :width="parseInt(width)"
            :zoom-speed="parseInt(zoomSpeed)"
            @draw="onDraw"
            @init="onInit"
            @move="onMove"
            @zoom="onZoom"
            @file-choose="onFileChoose"
            @file-size-exceed="onFileSizeExceed"
            @file-type-mismatch="onTypeMismatch"
            @new-image="onNewImage"
            @new-image-drawn="onNewImageDrawn"
            @image-remove="onImageRemove"
            @initial-image-loaded="onInitialImageLoaded"
            @loading-start="onLoadingStart"
            @loading-end="onLoadingEnd"
          />
        </div>
      </div>

      <v-alert
        :value="!!imageErrorMessage"
        class="pa-1"
        color="error"
        icon="warning"
        outlined
      >
        {{ imageErrorMessage }}
      </v-alert>

      <v-toolbar
        v-if="croppa && croppa.hasImage()"
        class="my-2"
        dense
      >
        <v-spacer />

        <v-btn
          class="ma-1"
          icon
          small
          @click="rotate(1)"
        >
          <v-icon>rotate_left</v-icon>
        </v-btn>

        <v-btn
          class="ma-1"
          icon
          @click="rotate(-1)"
        >
          <v-icon>rotate_right</v-icon>
        </v-btn>

        <v-btn
          class="ma-1"
          icon
          @click="flipX()"
        >
          <v-icon>flip</v-icon>
        </v-btn>

        <v-btn
          class="ma-1"
          icon
          @click="flipY()"
        >
          <v-icon style="transform: rotate(90deg);">
            flip
          </v-icon>
        </v-btn>

        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-btn
            class="ma-1"
            icon
            @click="zoomIn()"
          >
            <v-icon>zoom_in</v-icon>
          </v-btn>

          <v-btn
            class="ma-1"
            icon
            @click="zoomOut()"
          >
            <v-icon>zoom_out</v-icon>
          </v-btn>

          <v-btn
            class="ma-1"
            icon
            @click="moveUpwards(10)"
          >
            <v-icon>keyboard_arrow_up</v-icon>
          </v-btn>

          <v-btn
            class="ma-1"
            icon
            @click="moveDownwards(10)"
          >
            <v-icon>keyboard_arrow_down</v-icon>
          </v-btn>

          <v-btn
            class="ma-1"
            icon
            @click="moveLeftwards(10)"
          >
            <v-icon>keyboard_arrow_left</v-icon>
          </v-btn>

          <v-btn
            class="ma-1"
            icon
            @click="moveRightwards(10)"
          >
            <v-icon>keyboard_arrow_right</v-icon>
          </v-btn>
        </template>

        <v-spacer />

        <v-btn
          class="ma-1"
          icon
          small
          @click="reset()"
        >
          <v-icon>flip_camera_android</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="mt-2">
        <v-btn
          v-if="croppa && galleryEnabled"
          color="light-blue"
          class="ma-1"
          outlined
          small
          @click="chooseFromGallery"
        >
          {{ $t('Settings.Website.Field.Gallery.Image.Control.Gallery') }}
        </v-btn>

        <v-btn
          v-if="croppa"
          color="light-blue"
          class="ma-1"
          outlined
          small
          @click="chooseFile()"
        >
          {{ hasImage() ? $t('Settings.Website.Field.Gallery.Image.Control.Change') : $t('Settings.Website.Field.Gallery.Image.Control.Select') }}
        </v-btn>

        <v-btn
          v-if="croppa"
          :disabled="!hasImage()"
          class="ma-1"
          color="red"
          outlined
          small
          @click="remove()"
        >
          {{ $t('Settings.Website.Field.Gallery.Image.Control.Remove') }}
        </v-btn>
      </div>
    </template>

    <select-image-dialog
      v-if="galleryEnabled"
      :visible.sync="selectImageDialogVisible"
      :default-selected-tab="defaultGalleryTab"
      :excluded-tabs="excludedGalleryTabs"
      @image:save="onGalleryImageSelectedSave"
    />
  </div>
</template>

<script>
import SelectImageDialog from '@/components/common/SelectImageDialog.vue'

export default {
  name      : 'ImageCropSelector',
  components: { SelectImageDialog },
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : Object,
      default: undefined
    },
    width: {
      type     : [Number, String],
      default  : 200,
      validator: (val) => parseInt(val) > 0
    },
    height: {
      type     : [Number, String],
      default  : 200,
      validator: (val) => parseInt(val) > 0
    },
    placeholder: {
      type   : String,
      default: ''
    },
    placeholderColor: {
      type   : String,
      default: '#606060'
    },
    placeholderFontSize: {
      type     : [Number, String],
      default  : 0,
      validator: (val) => parseInt(val) >= 0
    },
    canvasColor: {
      type   : String,
      default: 'transparent'
    },
    quality: {
      type     : [Number, String],
      default  : 1,
      validator: (val) => parseInt(val) > 0
    },
    zoomSpeed: {
      type     : [Number, String],
      default  : 3,
      validator: (val) => parseInt(val) > 0
    },
    accept: {
      type   : String,
      default: 'image/jpeg,image/jpg,image/png'
    },
    fileSizeLimit: {
      type     : [Number, String],
      default  : 10485760,
      validator: (val) => parseInt(val) >= 0
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    disableDragAndDrop: {
      type   : Boolean,
      default: false
    },
    disableClickToChoose: {
      type   : Boolean,
      default: false
    },
    disableDragToMove: {
      type   : Boolean,
      default: false
    },
    disableScrollToZoom: {
      type   : Boolean,
      default: false
    },
    disablePinchToZoom: {
      type   : Boolean,
      default: false
    },
    disableRotation: {
      type   : Boolean,
      default: false
    },
    reverseScrollToZoom: {
      type   : Boolean,
      default: false
    },
    preventWhiteSpace: {
      type   : Boolean,
      default: false
    },
    showRemoveButton: {
      type   : Boolean,
      default: false
    },
    removeButtonColor: {
      type   : String,
      default: 'red'
    },
    removeButtonSize: {
      type     : [Number, String],
      default  : 0,
      validator: (val) => parseInt(val) >= 0
    },
    initialImage: {
      type   : String,
      default: ''
    },
    initialSize: {
      type     : String,
      default  : 'contain',
      validator: (val) => ['cover', 'contain', 'natural'].includes(String(val))
    },
    initialPosition: {
      type     : String,
      default  : 'center center',
      validator: function (val) {
        const arr = ['center', 'top', 'bottom', 'left', 'right']
        return (
          val.split(' ').every(word => {
            return arr.indexOf(word) >= 0
          }) || /^-?\d+% -?\d+%$/.test(val)
        )
      }
    },
    showLoading: {
      type   : Boolean,
      default: false
    },
    loadingSize: {
      type     : [Number, String],
      default  : 20,
      validator: (val) => parseInt(val) > 0
    },
    loadingColor: {
      type   : String,
      default: '#606060'
    },
    replaceDrop: {
      type   : Boolean,
      default: false
    },
    imageBorderRadius: {
      type     : [Number, String],
      default  : 0,
      validator: (val) => parseInt(val) >= 0
    },
    initialImageStyle: {
      type   : [String, Array],
      default: ''
    },
    initialImageClass: {
      type   : [String, Array],
      default: ''
    },
    containerClass: {
      type   : [String, Array],
      default: ''
    },
    galleryEnabled: {
      type   : Boolean,
      default: false
    },
    defaultGalleryTab: {
      type   : String,
      default: 'category'
    },
    excludedGalleryTabs: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      selectImageDialogVisible: false,
      croppa                  : this.value,
      metadata                : null,
      imageErrorMessage       : '',
      galleryImage            : null
    }
  },
  computed: {
    defaultPlaceholder () {
      return this.placeholder || `${ this.$t('Settings.Website.Field.Gallery.Image.Hint.Drag') } ${ this.$t('Settings.Website.Field.Gallery.Image.Hint.Click') }`
    },

    initialGalleryImage () {
      return this.galleryImage?.Image || undefined
    },

    initialImageSrc: {
      get () {
        return this.initialImage || ''
      },
      set (val) {
        this.$emit('update:initial-image', val)
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    /* EVENT METHODS */
    onInit () {
      this.imageErrorMessage = ''
      this.$emit('init', this.croppa)
      this.$emit('input', this.croppa)
    },
    onFileChoose () {
      this.$emit('file-choose', this.croppa)
    },
    onFileSizeExceed () {
      this.imageErrorMessage = this.$t('Settings.Website.Field.Gallery.Image.Error.Size')
      this.$emit('file-size-exceed', this.croppa)
    },
    onTypeMismatch () {
      this.imageErrorMessage = this.$t('Settings.Website.Field.Gallery.Image.Error.Type')
      this.$emit('file-type-mismatch', this.croppa)
    },
    onNewImage () {
      this.imageErrorMessage = ''
      this.$emit('new-image', this.croppa)
    },
    onNewImageDrawn () {
      this.imageErrorMessage = ''
      this.$emit('new-image-drawn', this.croppa)
      this.metadata = this.croppa.getMetadata()
    },
    onImageRemove () {
      this.imageErrorMessage = this.initialImageSrc
      this.galleryImage = null
      this.$emit('image-remove', this.croppa)
      this.metadata = null
    },
    onMove () {
      this.$emit('move', this.croppa)
    },
    onZoom () {
      this.$emit('zoom', this.croppa)
    },
    onDraw (ctx) {
      this.$emit('draw', ctx, this.croppa)
    },
    onInitialImageLoaded () {
      this.$emit('initial-image-loaded', this.croppa)
    },
    onLoadingStart () {
      this.$emit('loading-start', this.croppa)
    },
    onLoadingEnd () {
      this.$emit('loading-end', this.croppa)
    },
    onGalleryImageSelectedSave (data) {
      if (!data) return

      this.galleryImage = data
      this.refresh()
    },

    /* API METHODS */
    getCanvas () {
      return this.croppa && this.croppa.getCanvas()
    },
    getContext () {
      return this.croppa && this.croppa.getContext()
    },
    getChosenFile () {
      return this.croppa && this.croppa.getChosenFile()
    },
    moveUpwards (amountInPx) {
      return this.croppa && this.croppa.moveUpwards(amountInPx)
    },
    moveDownwards (amountInPx) {
      return this.croppa && this.croppa.moveDownwards(amountInPx)
    },
    moveLeftwards (amountInPx) {
      return this.croppa && this.croppa.moveLeftwards(amountInPx)
    },
    moveRightwards (amountInPx) {
      return this.croppa && this.croppa.moveRightwards(amountInPx)
    },
    move (xyObj) {
      return this.croppa && this.croppa.move(xyObj)
    },
    zoomIn () {
      return this.croppa && this.croppa.zoomIn()
    },
    zoomOut () {
      return this.croppa && this.croppa.zoomOut()
    },
    zoom (inOut, timesQuicken) {
      return this.croppa && this.croppa.zoom(inOut, timesQuicken)
    },
    rotate (step) {
      return this.croppa && this.croppa.rotate(step)
    },
    flipX () {
      return this.croppa && this.croppa.flipX()
    },
    flipY () {
      return this.croppa && this.croppa.flipY()
    },
    chooseFile () {
      return this.croppa && this.croppa.chooseFile()
    },
    chooseFromGallery () {
      this.selectImageDialogVisible = true
    },
    remove () {
      return this.croppa && this.croppa.remove()
    },
    refresh () {
      return this.croppa && this.croppa.refresh()
    },
    reset () {
      return this.croppa && this.metadata && this.croppa.applyMetadata(this.metadata)
    },
    hasImage () {
      return this.croppa && this.croppa.hasImage()
    },
    generateDataUrl (type, compressionRate) {
      return this.croppa && this.croppa.generateDataUrl(type, compressionRate)
    },
    generateBlob (callback, mimeType, compressionRate) {
      return this.croppa && this.croppa.generateBlob(callback, mimeType, compressionRate)
    },
    promisedBlob (mimeType, compressionRate) {
      return this.croppa && this.croppa.promisedBlob(mimeType, compressionRate)
    },
    getMetadata () {
      return this.croppa && this.croppa.getMetadata()
    },
    applyMetadata (metadata) {
      return this.croppa && this.croppa.applyMetadata(metadata)
    },
    addClipPlugin (func) {
      return this.croppa && this.croppa.addClipPlugin(func)
    },
    supportDetection () {
      return this.croppa && this.croppa.supportDetection()
    }
  }
}
</script>

<style scoped>
/deep/ .croppa-container canvas {
  width  : 100% !important;
  height : auto !important;
}

/deep/ .v-responsive__content {
  width : auto !important;
}
</style>
