<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.MenuOptionValueGroups.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showCategoryAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.MenuOptionValueGroups.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.MenuOptionValueGroups.Toolbar.Button.Title') }}</span>
        </v-btn>

        <!--
        <v-btn
          v-if="selectedLanguages.length > 1 && userIsSuperExtraAdmin"
          :disabled="dataTable.loading"
          :loading="dataTable.loading"
          :loading-text="dataTable.text.loadingText"
          :no-data-text="dataTable.text.noDataText"
          :no-results-text="dataTable.text.noResultsText"
          class="purple white--text elevation-0 ma-1"
          @click="translateAllItems()"
        >
          {{ $t('Common.Button.Translate').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        -->

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.menu_option_value_group_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.group_name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showCategoryEditDialog(item)"
                >
                  {{ getTranslatedField(item, 'Name') }}
                </a>
              </template>
              <template v-else>
                {{ getTranslatedField(item, 'Name') }}
              </template>
            </template>

            <template #[`item.language`]="{item}">
              <template v-for="lang in selectedLanguages">
                <div
                  :key="lang.Code"
                  class="mx-1 d-inline-block"
                >
                  <v-badge
                    :value="!item.Lang[lang.Code] || !item.Lang[lang.Code].Name"
                    color="orange"
                    overlap
                    right
                  >
                    <template #badge>
                      <v-icon
                        dark
                        small
                      >
                        priority_high
                      </v-icon>
                    </template>

                    <v-avatar
                      v-if="lang.ImageUrl"
                      size="20"
                    >
                      <img
                        :class="[{'grayscale opacity': !item.Lang[lang.Code] || !item.Lang[lang.Code].Name}]"
                        :src="lang.ImageUrl"
                      >
                    </v-avatar>
                    <div v-else>
                      {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                    </div>
                  </v-badge>
                </div>
              </template>
            </template>

            <template #[`item.description`]="{item}">
              {{ item.Description }}
            </template>

            <template #[`item.show_values`]="{item}">
              <v-switch
                v-model="item.Status"
                inset
                :disabled="!userCanManage"
                :label="item.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-0"
                color="success"
                hide-details
                @change="updateItem(item)"
              />
            </template>

            <template #[`item.active`]="{item}">
              <v-switch
                v-model="item.ShowOptionValues"
                inset
                :disabled="!userCanManage"
                :label="item.ShowOptionValues ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                class="ma-0"
                color="success"
                hide-details
                @change="updateItem(item)"
              />
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanManage"
                class="cyan elevation-2 ma-0"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                style="width: 32px; height: 32px;"
                @click="showCategoryEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanDelete"
                class="red elevation-2 ma-1"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                style="width: 32px; height: 32px;"
                @click="deleteMenuOptionValue(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <menu-option-value-group-add-dialog
      :category-to-edit="selectedItem"
      :visible.sync="addEditCategoryDialog"
    />
    <confirm-dialog
      :html-content="$t('Restaurant.MenuOptionValueGroups.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.MenuOptionValueGroups.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.MenuOptionValueGroups.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>
import { API, APICall }              from '@/api/Api'
import AppData                       from '@/mixins/appdata'
import Auth                          from '@/mixins/auth'
import Translatable                  from '@/mixins/translatable'
import DataTable                     from '@/mixins/data-table/dataTable'
import ConfirmDialog                 from '@/components/common/ConfirmDialog'
import FilterSearch                  from '@/components/common/filter/FilterSearch.vue'
import MenuOptionValueGroupAddDialog from './MenuOptionValueGroupAddDialog.vue'
import googleTranslate               from '@/lib/google-translate/googleTranslate'

export default {
  components: {
    FilterSearch,
    'menu-option-value-group-add-dialog': MenuOptionValueGroupAddDialog,
    'confirm-dialog'                    : ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, DataTable],
  data () {
    return {
      confirmDialogDeleteVisible: false,
      selectedItem              : null,
      addEditCategoryDialog     : false,
      dataTable                 : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Menu.OptionValueGroup.All,
          save  : window.SocketCommand.Menu.OptionValueGroup.Save,
          delete: window.SocketCommand.Menu.OptionValueGroup.Delete
        },
        options: {
          defaultSortBy: 'menu_option_value_group_id',
          sortBy       : ['menu_option_value_group_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Restaurant.MenuOptionValueGroups.Table.Headers.Id'),
            value    : 'menu_option_value_group_id',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.MenuOptionValueGroups.Table.Headers.Name'),
            value    : 'group_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.MenuOptionValueGroups.Table.Headers.Languages'),
            value    : 'language',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.MenuOptionValueGroups.Table.Headers.Description'),
            value    : 'description',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.MenuOptionValueGroups.Table.Headers.ShowOptionValues'),
            value    : 'show_values',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.MenuOptionValueGroups.Table.Headers.Status'),
            value    : 'active',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.MenuOptionValueGroups.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 100
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    showCategoryAddDialog () {
      this.selectedItem = null
      this.addEditCategoryDialog = true
    },
    showCategoryEditDialog (category) {
      this.selectedItem = category
      this.addEditCategoryDialog = true
    },

    updateItem (item) {
      if (!item) return

      const payload = JSON.parse(JSON.stringify(item))
      window.callAS(window.SocketCommand.Menu.OptionValueGroup.Save, { group: payload })
    },

    deleteMenuOptionValue (menuOptionValue) {
      this.selectedItem = menuOptionValue
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Menu.OptionValueGroup.Delete, { menu_option_value_group_id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.MenuOptionValueGroups.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    async translateAllItems (localeFrom = this.defaultLanguage.Code, localeTo) {
      if (this.selectedLanguages.length <= 0 || !this.userIsSuperExtraAdmin) return
      this.dataTable.loading = true
      const langs = localeTo ? [{ Code: localeTo }] : this.selectedLanguages.filter(lang => lang.Code !== localeFrom) || []

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].Code

        const payload = APICall.CreatePayload(window.SocketCommand.Menu.OptionValueGroup.All, {
          config       : 0,
          page         : 1,
          page_limit   : -1,
          sort_by      : 'menu_option_value_group_id',
          order_by     : 'DESC',
          filter_search: ''
        })
        // eslint-disable-next-line camelcase
        const items_result = await API.post(APICall.api, payload)

        const lngArrName = []
        const lngArrDesc = []
        for (let i = 0; i < items_result.data.length; i++) {
          const item = items_result.data[i]
          lngArrName.push(item.Lang[localeFrom].Name || '')
          lngArrDesc.push(item.Lang[localeFrom].Description || '')
        }

        const resultName = await googleTranslate(lngArrName, localeTo, localeFrom)
        const resultDesc = await googleTranslate(lngArrDesc, localeTo, localeFrom)

        if (resultName && resultDesc) {
          for (let i = 0; i < items_result.data.length; i++) {
            const item = items_result.data[i]
            const langObj = {}
            for (const lang of this.selectedLanguages) {
              langObj[lang.Code] = {
                Name       : '',
                Description: '',
                Locale     : lang.Code
              }
            }
            item.Lang = Object.assign({}, langObj, item.Lang)
            item.Lang[localeTo].Name = Array.isArray(resultName) ? resultName[i].translatedText : resultName.translatedText
            item.Lang[localeTo].Description = Array.isArray(resultDesc) ? resultDesc[i].translatedText : resultDesc.translatedText

            await API.post(APICall.api, APICall.CreatePayload(window.SocketCommand.Menu.OptionValueGroup.Save, { group: item }))
          }
        }
      }
      this.getData()
      this.dataTable.loading = false
    }

  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 65px !important;*/
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}

</style>
