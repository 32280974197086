<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card v-if="editData">
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Domain.Email.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Domain.Email.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Domain.Email.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Domain.Email.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editData.Subdomain"
                    :hint="$t('Settings.Domain.Email.Field.Subdomain.Hint')"
                    :label="$t('Settings.Domain.Email.Field.Subdomain.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.Subdomain.Icon')"
                    :rules="editData.validator.vuetifyFormFieldRules('Subdomain')"
                    disabled
                    readonly
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editData.To"
                    :hint="$t('Settings.Domain.Email.Field.To.Hint')"
                    :label="$t('Settings.Domain.Email.Field.To.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.To.Icon')"
                    :rules="editData.validator.vuetifyFormFieldRules('To')"
                    required
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editData.Firstname"
                    :hint="$t('Settings.Domain.Email.Field.Firstname.Hint')"
                    :label="$t('Settings.Domain.Email.Field.Firstname.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.Firstname.Icon')"
                    :rules="editData.validator.vuetifyFormFieldRules('Firstname')"
                    required
                  />
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editData.Lastname"
                    :hint="$t('Settings.Domain.Email.Field.Lastname.Hint')"
                    :label="$t('Settings.Domain.Email.Field.Lastname.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.Lastname.Icon')"
                    :rules="editData.validator.vuetifyFormFieldRules('Lastname')"
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.Email"
                    :hint="$t('Settings.Domain.Email.Field.Email.Hint')"
                    :label="$t('Settings.Domain.Email.Field.Email.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.Email.Icon')"
                    :rules="editData.validator.vuetifyFormFieldRules('Email')"
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="editData.Pin"
                    v-mask="'####'"
                    :hint="$t('Settings.Domain.Email.Field.Pin.Hint')"
                    :label="$t('Settings.Domain.Email.Field.Pin.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.Pin.Icon')"
                    :rules="editData.validator.vuetifyFormFieldRules('Pin')"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editData.Locale"
                    :hint="$t('Settings.Domain.Email.Field.Locale.Hint')"
                    :items="$i18n.languages"
                    :label="$t('Settings.Domain.Email.Field.Locale.Label')"
                    :prepend-icon="$t('Settings.Domain.Email.Field.Locale.Icon')"
                    :rules="editData.validator.vuetifyFormFieldRules('Locale')"
                    item-text="name"
                    item-value="locale"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Send').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData                  from '../../../mixins/appdata'
import Auth                     from '../../../mixins/auth'
import CustomerEmailDomainModel from '@/api/Models/settings/CustomerEmailDomainModel'

export default {
  name      : 'CustomerEmailDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    domain: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      dialogFormValid: false,
      editData       : null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.fetch()
      } else {
        this.editData = null
      }
    }
  },
  created () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Settings.Domains.Customer.Get, this.onFetchResult)
    this.$bus.$on(window.SocketCommand.Settings.Domains.Customer.SendEmail, this.onResult)
  },
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Settings.Domains.Customer.Get, this.onFetchResult)
    this.$bus.$off(window.SocketCommand.Settings.Domains.Customer.SendEmail, this.onResult)
  },
  methods: {
    fetch () {
      window.callAS(window.SocketCommand.Settings.Domains.Customer.Get, { Id: this.domain.Id })
    },
    onFetchResult (data) {
      this.editData = new CustomerEmailDomainModel(data)
    },

    onSaveButtonClick () {
      this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
        const data = new CustomerEmailDomainModel(this.editData.clone())
        data.save()
      }
    },
    onResult (result) {
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
    }
  }
}
</script>
