<template>
  <v-dialog
    v-model="isVisible"
    :overlay="false"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        class="blue-grey darken-3"
        extension-height="40"
        flat
        height="80"
        max-height="120"
      >
        <v-avatar
          :color="$t('Order.OrdersMapDialog.Toolbar.Color')"
        >
          <v-icon dark>
            {{ $t('Order.OrdersMapDialog.Toolbar.Icon') }}
          </v-icon>
        </v-avatar>

        <v-toolbar-title class="pl-3">
          <div class="body-3 grey--text text--lighten-3">
            {{ $t('Order.OrdersMapDialog.Toolbar.Title') }}
          </div>
          <div class="text-caption grey--text  text--lighten-1">
            {{ ordersCount }} {{ $tc('Order.Title', ordersCount) }} {{ $t('Order.OrdersMapDialog.Toolbar.Subtitle') }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <template :slot="$vuetify.breakpoint.xsOnly ? 'extension' : 'default'">
          <v-spacer />

          <div style="overflow-x: hidden; overflow-y: hidden;">
            <div
              class="px-2"
              style="white-space: nowrap;"
            >
              <v-tooltip
                bottom
                color="cyan"
                open-delay="1000"
              >
                <template #activator="{on}">
                  <v-btn
                    class="mr-1"
                    dark
                    icon
                    large
                    @click="centerZoomMap"
                    v-on="on"
                  >
                    <v-icon color="grey lighten-1">
                      {{ $t('Order.OrdersMapDialog.Toolbar.Button.ResetMap.Icon') }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('Order.OrdersMapDialog.Toolbar.Button.ResetMap.Title') }}</span>
              </v-tooltip>

              <v-tooltip
                bottom
                color="cyan"
                open-delay="1000"
              >
                <template #activator="{on}">
                  <v-btn
                    :disabled="!appConfig.LOCATION_DATA.HasDeliveryAreas"
                    class="mr-1"
                    dark
                    icon
                    large
                    @click="restaurantDeliveryAreaVisible=!restaurantDeliveryAreaVisible; centerZoomMap()"
                    v-on="on"
                  >
                    <v-icon :color="restaurantDeliveryAreaVisible ? 'red lighten-4' : 'blue-grey darken-2'">
                      {{ $t('Order.OrdersMapDialog.Toolbar.Button.MapLimit.Icon') }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('Order.OrdersMapDialog.Toolbar.Button.MapLimit.Title') }}</span>
              </v-tooltip>

              <template v-if="posUserHasComponentPermission('DeliveryStaff', 'ACCESS')">
                <template v-if="!selectedDeliveryStaff">
                  <v-tooltip
                    bottom
                    color="cyan"
                    open-delay="1000"
                  >
                    <template #activator="{on: tooltip}">
                      <v-menu
                        :disabled="viewDeliveryStaffData.length <= 0"
                        bottom
                        dark
                        offset-y
                      >
                        <template #activator="{on: menu}">
                          <v-btn
                            class="mr-1"
                            dark
                            icon
                            large
                            v-on="{...tooltip, ...menu}"
                          >
                            <v-icon
                              :color="viewDeliveryStaffData.length > 0 ? 'purple lighten-2' : 'blue-grey darken-2'"
                            >
                              {{ $t('Order.OrdersMapDialog.Toolbar.Button.Delivery.Icon') }}
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-list two-line>
                          <v-list-item
                            v-for="(deliveryStaff, i) in viewDeliveryStaffData"
                            :key="i"
                            @click="selectedDeliveryStaff = deliveryStaff"
                          >
                            <v-avatar
                              class="white--text mr-3"
                              color="purple"
                            >
                              {{ getDeliveryStaffOrdersCount(deliveryStaff) }}
                            </v-avatar>

                            <v-list-item-content>
                              <v-list-item-title>
                                {{ deliveryStaff.Firstname }} {{ deliveryStaff.Lastname }}
                              </v-list-item-title>

                              <v-list-item-subtitle>
                                <v-icon
                                  class="pr-2"
                                  small
                                >
                                  phone
                                </v-icon>
                                {{ deliveryStaff.Phone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>

                    <span>{{ $tc('Delivery.Title', 0) }}</span>
                  </v-tooltip>
                </template>

                <template v-else>
                  <v-chip
                    v-model="selectedDeliveryStaffChip"
                    class="pl-0"
                    close
                    color="purple lighten-2"
                    text-color="white"
                    @click:close="clearDeliveryStaff"
                  >
                    <v-avatar
                      class="mr-2"
                      color="purple"
                      style="height: 32px !important; width: 32px !important;"
                    >
                      <v-icon color="purple lighten-3">
                        directions_bike
                      </v-icon>
                    </v-avatar>
                    {{ selectedDeliveryStaff.Firstname }} {{ selectedDeliveryStaff.Lastname }}
                  </v-chip>
                </template>
              </template>

              <v-tooltip
                bottom
                color="cyan"
                open-delay="1000"
              >
                <template #activator="{on}">
                  <v-btn
                    class="mr-1"
                    dark
                    icon
                    large
                    @click="showReceivedOrders=!showReceivedOrders; centerZoomMap()"
                    v-on="on"
                  >
                    <v-badge
                      :color="showReceivedOrders ? 'orange lighten-2' : 'blue-grey darken-2'"
                      overlap
                      right
                    >
                      <span
                        slot="badge"
                        :class="[showReceivedOrders ? '' : '']"
                      >
                        {{ ordersCountReceived }}
                      </span>
                      <v-icon :color="showReceivedOrders ? 'grey lighten-4' : 'blue-grey darken-2'">
                        {{ $t('Order.OrdersMapDialog.Toolbar.Button.ReceivedOrder.Icon') }}
                      </v-icon>
                    </v-badge>
                  </v-btn>
                </template>

                <span>{{ $t('Order.OrdersMapDialog.Toolbar.Button.ReceivedOrder.Title') }}</span>
              </v-tooltip>

              <v-tooltip
                bottom
                color="cyan"
                open-delay="1000"
              >
                <template #activator="{on}">
                  <v-btn
                    class="mr-1"
                    dark
                    icon
                    large
                    @click="showAcceptedOrders=!showAcceptedOrders; centerZoomMap()"
                    v-on="on"
                  >
                    <v-badge
                      :color="showAcceptedOrders ? 'green' : 'blue-grey darken-2'"
                      overlap
                      right
                    >
                      <span slot="badge">
                        {{ ordersCountAccepted }}
                      </span>
                      <v-icon :color="showAcceptedOrders ? 'green' : 'blue-grey darken-2'">
                        {{ $t('Order.OrdersMapDialog.Toolbar.Button.AcceptedOrder.Icon') }}
                      </v-icon>
                    </v-badge>
                  </v-btn>
                </template>

                <span>{{ $t('Order.OrdersMapDialog.Toolbar.Button.AcceptedOrder.Title') }}</span>
              </v-tooltip>

              <v-tooltip
                bottom
                color="cyan"
                open-delay="1000"
              >
                <template #activator="{on}">
                  <v-btn
                    class="mr-1"
                    dark
                    icon
                    large
                    @click="showCompletedOrders=!showCompletedOrders; centerZoomMap()"
                    v-on="on"
                  >
                    <v-badge
                      :color="showCompletedOrders ? 'light-green' : 'blue-grey darken-2'"
                      overlap
                      right
                    >
                      <span slot="badge">
                        {{ ordersCountCompleted }}
                      </span>
                      <v-icon :color="showCompletedOrders ? 'light-green' : 'blue-grey darken-2'">
                        {{ $t('Order.OrdersMapDialog.Toolbar.Button.CompletedOrder.Icon') }}
                      </v-icon>
                    </v-badge>
                  </v-btn>
                </template>

                <span>{{ $t('Order.OrdersMapDialog.Toolbar.Button.CompletedOrder.Title') }}</span>
              </v-tooltip>
            </div>
          </div>

          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        </template>

        <v-btn
          dark
          icon
          @click="isVisible = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        v-if="viewData"
        class="pa-0"
      >
        <gmap-map
          v-if="mapVisible"
          ref="ordersMap"
          :center="mapCenter"
          :options="{styles: GMapStyles}"
          :style="'height: calc(100vh - ' + ($vuetify.breakpoint.xsOnly ? '120' : '80') + 'px)'"
          :zoom="mapZoom"
          map-type-id="roadmap"
        >
          <template v-if="restaurantDeliveryAreaVisible">
            <template v-for="(circ, index) in restaurantDeliveryAreaCircles">
              <gmap-circle
                :key="`circle_${index}`"
                :center="circ.circle.center || markerStore"
                :editable="false"
                :options="{strokeColor: circ.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: circ.color, fillOpacity: 0.15}"
                :radius="circ.circle.radius || 1000"
              />
            </template>

            <template v-for="(poly, index) in restaurantDeliveryAreaPolygons">
              <gmap-polygon
                :key="`poly_${index}`"
                :editable="false"
                :options="{strokeColor: poly.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: poly.color, fillOpacity: 0.15}"
                :paths="poly.vertices"
              />
            </template>
          </template>

          <gmap-marker
            :icon="getGMapIcon(LOGO_COLOR.DARK)"
            :position="markerStore"
            label=""
          />

          <template v-for="(order, index) in orders">
            <gmap-marker
              :key="index"
              ref="marker"
              :clickable="true"
              :icon="getMarkerIconByOrderStatusId(order)"
              :label="{
                orderId: order.Id.toString(),
                text: parseInt(order.StatusId) === 15 ? ' ' : order.Id.toString(),
                color: isOrderDelayed(order) ? '#f44336' : '#00933d',
                fontSize: '11px',
                fontWeight: 'bold',
              }"
              :opacity="0.85"
              :position="getOrderLatLng(order)"
              :z-index="parseInt(order.StatusId) === 15 ? 0 : index"
              @click="!fixOverlapingMarkersEnabled ? showOrderInfoWindow(order, true) : null"
            />
            <gmap-marker
              v-if="parseInt(order.StatusId) !== 15"
              :key="index + '_2'"
              :icon="{url: 'https://maps.google.com/mapfiles/kml/pal2/icon15.png', anchor: {x: 16, y: 56}}"
              :label="{
                orderId: order.Id.toString(),
                //text: (orders.length - index).toString() + '',
                text: getOrderTimeElapsedHumanized(order, true),
                color: '#000000',
                fontSize: '9px',
                fontWeight: 'bold',
              }"
              :opacity="0.85"
              :position="getOrderLatLng(order)"
              :z-index="parseInt(order.StatusId) === 15 ? 0 : (index+1)"
              @click="!fixOverlapingMarkersEnabled ? showOrderInfoWindow(order, true) : null"
            />
            <gmap-marker
              v-if="parseInt(order.StatusId) !== 15"
              :key="index + '_3'"
              :icon="{url: 'https://maps.google.com/mapfiles/kml/pal2/icon15.png', anchor: {x: 16, y: 36}}"
              :label="{
                orderId: order.Id.toString(),
                text: (orders.length - index).toString() + '',
                color: '#ffffff',
                fontSize: '11px',
                fontWeight: 'bold',
              }"
              :opacity="0.85"
              :position="getOrderLatLng(order)"
              :z-index="parseInt(order.StatusId) === 15 ? 0 : (index+1)"
              @click="!fixOverlapingMarkersEnabled ? showOrderInfoWindow(order, true) : null"
            />
          </template>

          <gmap-info-window
            :opened="infoWinOpen"
            :options="{pixelOffset: {width: 0, height: infoWindowOffset}}"
            :position="getOrderLatLng(selectedOrder)"
            @closeclick="infoWinOpen = false"
          >
            <div
              v-if="selectedOrder"
              class="grey--text text--darken-2 pa-2"
              style="overflow: auto;"
            >
              <h2
                :class="{'red--text': isOrderDelayed(selectedOrder)}"
                class="text-center pb-2"
              >
                {{ $tc('Order.Title', 1).toLocaleUpperCase($i18n.locale) }} #{{ selectedOrder.Id }}
              </h2>

              <h4
                class="text-caption pt-2 info-line"
                style="white-space: nowrap;"
              >
                <v-icon :color="getOrderPaymentType(selectedOrder).color1">
                  {{ getOrderPaymentType(selectedOrder).icon }}
                </v-icon>
                <span>{{ selectedOrder.Price | currency }} {{ getOrderPaymentType(selectedOrder).name }}</span>

                <span class="px-1" />

                <v-icon :color="getOrderStatusColorClass(selectedOrder)">
                  {{ getOrderStatusIcon(selectedOrder) }}
                </v-icon>
                <span>{{ selectedOrder.OrderStatusName }}</span>

                <span class="px-1" />

                <v-icon color="blue">
                  access_time
                </v-icon>
                <span>{{ selectedOrder.TimeAdded }}</span>

                <span class="px-1" />

                <v-icon :color="isOrderDelayed(selectedOrder) ? 'red' : 'green'">
                  access_time
                </v-icon>
                <span :class="[isOrderDelayed(selectedOrder) ? 'red--text' : 'green--text']">
                  {{ getOrderTimeElapsedHumanized(selectedOrder) }}
                </span>
              </h4>

              <h4
                v-if="selectedOrder.DeliveryStaffId > -1"
                class="text-caption pt-2 info-line"
                style="white-space: nowrap;"
              >
                <v-icon color="purple">
                  directions_bike
                </v-icon>
                <span class="pl-1 text-subtitle-1 purple--text">
                  {{ selectedOrder.DeliveryStaffFirstname }} {{ selectedOrder.DeliveryStaffLastname }}
                </span>
              </h4>

              <v-divider class="my-2" />

              <template v-if="posUserHasComponentPermission('DeliveryStaff', 'ACCESS')">
                <v-btn
                  v-if="selectedOrder.DeliveryStaffId === -1"
                  class="purple white--text elevation-0 mr-1"
                  small
                  style="min-width: 0px !important;"
                  @click="showAddOrderToDeliveryStaff(selectedOrder)"
                >
                  <v-icon
                    class="pr-2"
                    dark
                  >
                    directions_bike
                  </v-icon>
                  {{ $t('Common.Button.Add').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
                <template v-else>
                  <v-btn
                    v-if="deliveryStaffHasActiveShift(selectedOrder)"
                    class="red white--text elevation-0 mr-1"
                    small
                    style="min-width: 0px !important;"
                    @click="showRemoveOrderFromDeliveryStaff(selectedOrder)"
                  >
                    <v-icon
                      class="pr-2"
                      dark
                    >
                      directions_bike
                    </v-icon>
                    {{ $t('Common.Button.Remove').toLocaleUpperCase($i18n.locale) }}
                  </v-btn>
                </template>
              </template>

              <v-btn
                class="cyan white--text elevation-0 mr-1"
                small
                style="min-width: 0px !important;"
                @click="showOrderDetailsDialog(selectedOrder)"
              >
                <v-icon
                  class="pr-2"
                  dark
                >
                  remove_red_eye
                </v-icon>
                {{ $t('Common.Button.View').toLocaleUpperCase($i18n.locale) }}
              </v-btn>

              <v-btn
                v-if="parseInt(selectedOrder.StatusId) !== 19 && parseInt(selectedOrder.StatusId) !== 15 && posUserHasComponentPermission('Orders', 'MANAGE')"
                :class="getOrderStatusButtonColorClass(selectedOrder)"
                :disabled="false"
                :loading="selectedOrder.loading"
                class="white--text elevation-0 mr-1"
                small
                style="min-width: 100px; float: right;"
                @click="orderUpdateStatus(selectedOrder)"
              >
                <v-icon
                  class="pr-2"
                  dark
                >
                  {{ getOrderStatusIcon(selectedOrder) }}
                </v-icon>
                {{ getOrderStatusNextLang(selectedOrder) }}
              </v-btn>
              <v-btn
                v-if="(parseInt(selectedOrder.StatusId) === 0 || parseInt(selectedOrder.StatusId) === 11) && !selectedOrder.loading && posUserHasComponentPermission('Orders', 'MANAGE')"
                :class="getOrderStatusButtonColorClass(selectedOrder)"
                class="elevation-0 mr-1"
                color="orange"
                dark
                small
                style="min-width: 65px;"
                @click="showSelectOrderTypeTimeDialog(selectedOrder)"
              >
                <v-icon>timer</v-icon>
                <span class="ml-2 text-subtitle-1 text-lowercase">
                  {{ convertMinutesToDisplayString(selectedOrder.OrderTypeTime) }}
                </span>
              </v-btn>

              <v-divider class="my-2" />

              <h4
                class="text-caption pt-2 info-line"
                style="white-space: nowrap;"
              >
                <v-icon color="blue">
                  person
                </v-icon>
                <span>{{ selectedOrder.FullName }}</span>
              </h4>

              <h4
                class="text-caption pt-2 info-line"
                style="white-space: nowrap;"
              >
                <v-icon color="red">
                  location_on
                </v-icon>
                <span>{{ selectedOrder.Address.Formatted }}</span>
              </h4>

              <h4
                v-if="selectedOrder.Telephone || selectedOrder.Telephone2 || selectedOrder.Telephone3"
                class="text-caption pt-2 info-line"
                style="white-space: nowrap"
              >
                <span v-if="selectedOrder.Telephone && $options.filters.phone(selectedOrder.Telephone)">
                  <v-icon color="green">
                    phone
                  </v-icon>
                  {{ selectedOrder.Telephone }}
                </span>
                <span v-if="selectedOrder.Telephone2 && $options.filters.phone(selectedOrder.Telephone2)">
                  <v-icon color="green">
                    phone
                  </v-icon>
                  {{ selectedOrder.Telephone2 }}
                </span>
                <span v-if="selectedOrder.Telephone3 && $options.filters.phone(selectedOrder.Telephone3)">
                  <v-icon color="green">
                    phone
                  </v-icon>
                  {{ selectedOrder.Telephone3 }}
                </span>
              </h4>
            </div>
          </gmap-info-window>
        </gmap-map>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable camelcase */
import moment              from 'moment'
import AppData             from '../../mixins/appdata'
import Auth                from '../../mixins/auth'
import PaymentType         from '../../mixins/orders/paymentType'
import OrderStatus         from '../../mixins/orders/orderStatus'
import OrderSourceType     from '../../mixins/orders/sourceType'
import OrderTypeTime       from '../../mixins/orders/orderTypeTime'
import GMapAddressValidate from '../../mixins/gmapaddressvalidate'
import { gmapApi }         from 'vue2-google-maps'

export default {
  components: {},
  mixins    : [AppData, Auth, PaymentType, OrderSourceType, OrderStatus, OrderTypeTime, GMapAddressValidate],
  props     : {
    ordersMapDialogVisible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      oms                        : null,
      fixOverlapingMarkersEnabled: false,
      markerStore                : {
        lat: window.appConfig.LOCATION_DATA.Lat,
        lng: window.appConfig.LOCATION_DATA.Lng
      },
      mapCenter: {
        lat: window.appConfig.LOCATION_DATA.Lat,
        lng: window.appConfig.LOCATION_DATA.Lng
      },
      infoWinOpen                  : false,
      selectedOrder                : null,
      mapVisible                   : true,
      mapZoom                      : 16,
      restaurantDeliveryAreaVisible: true,
      showReceivedOrders           : true,
      showAcceptedOrders           : true,
      showCompletedOrders          : false,
      selectedDeliveryStaff        : null,
      selectedDeliveryStaffChip    : false,
      googleMapState               : false
    }
  },
  computed: {
    GMapStyles () {
      if (!this.isDarkMode) {
        return [
          {
            featureType: 'poi',
            stylers    : [{ visibility: 'off' }]
          }
        ]
      }

      return [
        {
          featureType: 'poi',
          stylers    : [{ visibility: 'off' }]
        },
        {
          elementType: 'geometry',
          stylers    : [{ color: '#242f3e' }]
        },
        {
          elementType: 'labels.text.stroke',
          stylers    : [{ color: '#242f3e' }]
        },
        {
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#746855' }]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#d59563' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers    : [{ color: '#38414e' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers    : [{ color: '#212a37' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#9ca5b3' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers    : [{ color: '#746855' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers    : [{ color: '#1f2835' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#f3d19c' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers    : [{ color: '#2f3948' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#d59563' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers    : [{ color: '#17263c' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#515c6d' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers    : [{ color: '#17263c' }]
        }
      ]
    },

    google () {
      return gmapApi()
    },
    ordersCount () {
      return this.orders.length
    },
    ordersCountReceived () {
      return this.viewData.items.filter(order => !this.isQrOrder(order) && !this.isCustomAddress(order.Address) && parseInt(order.AddressId) > -1 && parseInt(order.OrderTypeId) === 1 && parseInt(order.StatusId) !== 19 && parseInt(order.StatusId) !== 0 && parseInt(order.StatusId) === 11).length
    },
    ordersCountAccepted () {
      return this.viewData.items.filter(order => !this.isQrOrder(order) && !this.isCustomAddress(order.Address) && parseInt(order.AddressId) > -1 && parseInt(order.OrderTypeId) === 1 && parseInt(order.StatusId) !== 19 && parseInt(order.StatusId) !== 0 && parseInt(order.StatusId) === 21).length
    },
    ordersCountCompleted () {
      return this.viewData.items.filter(order => !this.isQrOrder(order) && !this.isCustomAddress(order.Address) && parseInt(order.AddressId) > -1 && parseInt(order.OrderTypeId) === 1 && parseInt(order.StatusId) !== 19 && parseInt(order.StatusId) !== 0 && parseInt(order.StatusId) === 15).length
    },
    orders () {
      let filteredOrders = this.viewData.items
      filteredOrders = filteredOrders.filter(order => !this.isQrOrder(order) && !this.isCustomAddress(order.Address) && parseInt(order.AddressId) > -1 && parseInt(order.OrderTypeId) === 1 && parseInt(order.StatusId) !== 19 && parseInt(order.StatusId) !== 0)

      if (!this.showReceivedOrders) filteredOrders = filteredOrders.filter(order => parseInt(order.StatusId) !== 11)
      if (!this.showAcceptedOrders) filteredOrders = filteredOrders.filter(order => parseInt(order.StatusId) !== 21)
      if (!this.showCompletedOrders) filteredOrders = filteredOrders.filter(order => parseInt(order.StatusId) !== 15)
      if (this.selectedDeliveryStaff && parseInt(this.selectedDeliveryStaff.Id) > 0) filteredOrders = filteredOrders.filter(order => parseInt(order.DeliveryStaffId) === parseInt(this.selectedDeliveryStaff.Id))

      return filteredOrders
    },
    viewDeliveryStaffData () {
      return window.Vue.deliveryStaffItems.filter(deliveryStaff => deliveryStaff.HasActiveShift === true)
    },
    viewData () {
      return this.$DataStore?.ordersDataToday
    },
    restaurantDeliveryAreaCircles () {
      const $location_polygons = this.appConfig.LOCATION_DATA.DeliveryAreas
      const circles = []

      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas) return circles

      for (const key in $location_polygons) {
        if ($location_polygons.hasOwnProperty(key)) {
          const area = $location_polygons[key]
          if (area.type === 'circle') {
            circles.push(area)
          }
        }
      }
      return circles
    },
    restaurantDeliveryAreaPolygons () {
      const $location_polygons = this.appConfig.LOCATION_DATA.DeliveryAreas
      const polys = []

      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas) return polys

      for (const key in $location_polygons) {
        if ($location_polygons.hasOwnProperty(key)) {
          const area = $location_polygons[key]
          if (area.type === 'shape') {
            polys.push(area)
          }
        }
      }
      return polys
    },
    infoWindowOffset () {
      return this.selectedOrder && parseInt(this.selectedOrder.StatusId) === 15 ? -45 : -85
    },
    isVisible: {
      get () {
        return this.ordersMapDialogVisible
      },
      set (val) {
        this.$emit('update:ordersMapDialogVisible', val)
      }
    }
  },
  watch: {
    ordersMapDialogVisible (newVal) {
      if (newVal) {
        this.init()
      }
    },
    orders (newVal) {
      if (newVal) {
        this.infoWinOpen = false
        this.fixOverlapingMarkers()
      }
    },
    selectedDeliveryStaffChip (newVal) {
      if (!newVal) {
        this.selectedDeliveryStaff = null
        this.centerZoomMap()
      }
    },
    selectedDeliveryStaff (newVal) {
      if (newVal) {
        this.selectedDeliveryStaffChip = true
        this.centerZoomMap()
      }
    }
  },
  created () {
    this.$bus.$on('on-monitor-orders', this.onMonitorOrders)
    this.$bus.$on(window.SocketCommand.Order.Get, this.setOrderDetails)
  },
  mounted () {
  },
  updated () {
    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },
  beforeDestroy () {
    if (this.loadInterval) clearInterval(this.loadInterval)
    this.$bus.$off('on-monitor-orders', this.onMonitorOrders)
    this.$bus.$off(window.SocketCommand.Order.Get, this.setOrderDetails)
  },
  methods: {
    init () {
      this.$gmapApiPromiseLazy().then(() => {
        this.googleMapState = true
        this.centerZoomMap()
        this.fixOverlapingMarkers()
      })
    },
    showSelectOrderTypeTimeDialog (order) {
      this.$bus.$emit('order-type-time-dialog', order)
    },
    showOrderInfoWindow (order, toggle) {
      if (toggle) {
        this.selectedOrder = order
        this.infoWinOpen = !this.infoWinOpen
        return
      }

      if (this.selectedOrder === order && this.infoWinOpen) return
      this.selectedOrder = order
      this.infoWinOpen = true
    },
    getOrderById (id) {
      return this.orders.find(order => parseInt(order.Id) === parseInt(id))
    },
    getOrderLatLng (order) {
      if (!this.googleMapState) return
      // eslint-disable-next-line
      return order ? new google.maps.LatLng(order.Address.Lat, order.Address.Lng) : new google.maps.LatLng(0, 0)
    },
    centerZoomMap () {
      if (!this.googleMapState) return
      // eslint-disable-next-line
      let latlngbounds = new google.maps.LatLngBounds()
      const maxZoom = 17

      if (this.orders.length > 0) {
        for (let i = 0; i < this.orders.length; i++) {
          // eslint-disable-next-line
          this.orders[i].latlng = new google.maps.LatLng(this.orders[i].Address.Lat, this.orders[i].Address.Lng)
          latlngbounds.extend(this.orders[i].latlng)
        }
      } else {
        if (this.appConfig.LOCATION_DATA.HasDeliveryAreas) {
          for (let area = 0; area < this.restaurantDeliveryAreaPolygons.length; area++) {
            for (let i = 0; i < this.restaurantDeliveryAreaPolygons[area].vertices.length; i++) {
              // eslint-disable-next-line
              let latlng = new google.maps.LatLng(this.restaurantDeliveryAreaPolygons[area].vertices[i].lat, this.restaurantDeliveryAreaPolygons[area].vertices[i].lng)
              this.restaurantDeliveryAreaPolygons[area].vertices[i].latlng = latlng
              latlngbounds.extend(latlng)
            }
          }

          for (let i = 0; i < this.restaurantDeliveryAreaCircles.length; i++) {
            // eslint-disable-next-line
            let latlng = new google.maps.LatLng(this.restaurantDeliveryAreaCircles[i].circle.center.lat, this.restaurantDeliveryAreaCircles[i].circle.center.lng)
            latlngbounds.extend(latlng)
          }
        } else {
          // eslint-disable-next-line
          latlngbounds.extend(new google.maps.LatLng(this.markerStore.lat, this.markerStore.lng))
        }
      }

      if (this.$refs.ordersMap) {
        if (this.$refs.ordersMap.$mapCreated) {
          this.$refs.ordersMap.$mapCreated.then((map) => {
            this.google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
              if (this.getZoom() > maxZoom) this.setZoom(maxZoom)
            })
            map.fitBounds(latlngbounds)
            map.panToBounds(latlngbounds)
          })
        } else if (this.$refs.ordersMap.$mapPromise) {
          this.$refs.ordersMap.$mapPromise.then((map) => {
            this.google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
              if (this.getZoom() > maxZoom) this.setZoom(maxZoom)
            })
            map.fitBounds(latlngbounds)
            map.panToBounds(latlngbounds)
          })
        }
      }
    },
    getMarkerIconByOrderStatusId (order) {
      if (!this.googleMapState) return

      let img = ''
      const cdnURL = this.appConfig.LOCATION_DATA.CdnUrl + 'assets/map/'
      const isOrderDelayed = this.isOrderDelayed(order)
      const isOrderAssinged = order.DeliveryStaffId > -1

      if (parseInt(order.StatusId) === 11) {
        img = cdnURL + 'received.png'
        if (isOrderDelayed) img = cdnURL + 'received-delayed.png'
        if (isOrderAssinged) img = cdnURL + 'received-delivery.png'
        if (isOrderAssinged && isOrderDelayed) img = cdnURL + 'received-delayed-delivery.png'
      }
      if (parseInt(order.StatusId) === 21) {
        img = cdnURL + 'accepted.png'
        if (isOrderDelayed) img = cdnURL + 'accepted-delayed.png'
        if (isOrderAssinged) img = cdnURL + 'accepted-delivery.png'
        if (isOrderAssinged && isOrderDelayed) img = cdnURL + 'accepted-delayed-delivery.png'
      }
      if (parseInt(order.StatusId) === 15) {
        if (isOrderAssinged) {
          img = cdnURL + 'completed-delivery.png'
        } else {
          img = cdnURL + 'completed.png'
        }
      }

      const markerIcon = {
        url        : img,
        // eslint-disable-next-line
        origin: new google.maps.Point(0, 0),
        // eslint-disable-next-line
        scaledSize: new google.maps.Size(127 / 2, 167 / 2),
        // eslint-disable-next-line
        anchor: new google.maps.Point(127 / 4, 167 / 2),
        // eslint-disable-next-line
        labelOrigin: new google.maps.Point(32, 32)
      }

      if (parseInt(order.StatusId) === 15) {
        // eslint-disable-next-line
        markerIcon.scaledSize = new google.maps.Size(127 / 4, 167 / 4)
        // eslint-disable-next-line
        markerIcon.anchor = new google.maps.Point(127 / 8, 167 / 4)
      }
      return markerIcon
    },
    isOrderDelayed (order) {
      let retVal = false
      const timeElapsed = this.getOrderTimeElapsed(order)
      const deliveryTime = parseInt(order.OrderTypeTime) > 0 ? parseInt(order.OrderTypeTime) : parseInt(this.orderTypeDefaultTimes.delivery_time)
      const takeawayTime = parseInt(order.OrderTypeTime) > 0 ? parseInt(order.OrderTypeTime) : parseInt(this.orderTypeDefaultTimes.collection_time)

      if (parseInt(order.StatusId) === 21) { // Accepted
        if (parseInt(order.OrderTypeId) === 1) { // DELIVERY
          retVal = timeElapsed >= deliveryTime
        } else if (parseInt(order.OrderTypeId) === 2) { // TAKEAWAY
          retVal = timeElapsed >= takeawayTime
        }
      } else if (parseInt(order.StatusId) === 11) { // Received
        retVal = timeElapsed >= 3
      } else if (parseInt(order.StatusId) === 0) {
        retVal = timeElapsed >= 3
      }

      return retVal
    },
    getOrderTimeElapsed (order) {
      return moment.duration(moment(order.DateAdded + ' ' + order.TimeAdded, 'DD-MM-YYYY HH:mm:ss').diff(moment())).asMinutes() * -1
    },
    getOrderTimeElapsedHumanized (order, mini = false) {
      let h = moment.duration(moment(order.DateAdded + ' ' + order.TimeAdded, 'DD-MM-YYYY HH:mm:ss').diff(moment())).humanize(false)
      if (mini) {
        const s = h.split(' ')
        h = (Number.isInteger(parseInt(s[0])) ? s[0] : '1') + (s[1].charAt(0) === this.$t('Order.Type.Time.Second.Suffix') ? this.$t('Order.Type.Time.Minute.Suffix') : s[1].charAt(0))
      }
      return h
    },
    showOrderDetailsDialog (orderItem) {
      this.selectedOrder = orderItem
      if (orderItem.Details) {
        this.$bus.$emit('show-order-details-dialog', this.selectedOrder)
      } else {
        this.getOrderDetails({
          order_id : orderItem.Id,
          status_id: orderItem.StatusId
        })
      }
    },
    getOrderDetails (data) {
      window.callAS(window.SocketCommand.Order.Get, { ...data })
    },
    showAddOrderToDeliveryStaff (order) {
      this.$bus.$emit('show-add-order-to-delivery-staff-dialog', this.selectedOrder)
    },
    showRemoveOrderFromDeliveryStaff (order) {
      this.$bus.$emit('show-remove-order-from-delivery-staff-dialog', this.selectedOrder)
    },
    deliveryStaffHasActiveShift (order) {
      return order && order.DeliveryStaffHasActiveShift
    },
    setOrderDetails (data) {
      if (this.selectedOrder) {
        this.selectedOrder.Details = data
        this.$bus.$emit('show-order-details-dialog', this.selectedOrder)
      }
    },
    orderUpdateStatus (order) {
      window.callAS(window.SocketCommand.Order.UpdateStatus, {
        order_id       : order.Id,
        status_id      : order.StatusId,
        order_type_time: order.OrderTypeTime
      }, window.SocketCommand.Order.StatusUpdated)
    },
    clearDeliveryStaff () {
      this.selectedDeliveryStaffChip = false
    },
    onMonitorOrders () {
      this.$forceUpdate()
    },
    getDeliveryStaffOrdersCount (deliveryStaff) {
      return this.viewData.items.filter(order => parseInt(order.AddressId) > -1 && parseInt(order.OrderTypeId) === 1 && parseInt(order.StatusId) !== 19 && parseInt(order.StatusId) !== 0 && parseInt(order.DeliveryStaffId) === parseInt(deliveryStaff.Id)).length
    },
    fixOverlapingMarkers () {
      if (!this.fixOverlapingMarkersEnabled) return

      const options = {
        legWeight           : 5,
        nearbyDistance      : 60,
        keepSpiderfied      : false,
        basicFormatEvents   : true,
        // circleSpiralSwitchover: 0, //Infinity
        circleFootSeparation: 100,
        spiralFootSeparation: 100,
        spiralLengthStart   : 100,
        spiralLengthFactor  : 4,
        markersWontMove     : false,
        markersWontHide     : false,
        event               : 'click'
      }

      if (this.$refs.ordersMap.$mapCreated) {
        this.$refs.ordersMap.$mapCreated.then((map) => {
          const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier')

          setTimeout(() => {
            if (!this.oms) {
              this.oms = new OverlappingMarkerSpiderfier(map, options)
              if (options.event === 'click') {
                this.oms.addListener('click', (marker) => {
                  this.showOrderInfoWindow(this.getOrderById(marker.label.orderId), false)
                })
              }
            }

            this.oms.clearMarkers()
            this.$refs.marker.forEach(marker => {
              this.oms.addMarker(marker.$markerObject)
            })
          }, 100)
        })
      } else if (this.$refs.ordersMap.$mapPromise) {
        this.$refs.ordersMap.$mapPromise.then((map) => {
          const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier')

          setTimeout(() => {
            if (!this.oms) {
              this.oms = new OverlappingMarkerSpiderfier(map, options)
              if (options.event === 'click') {
                this.oms.addListener('click', (marker) => {
                  this.showOrderInfoWindow(this.getOrderById(marker.label.orderId), false)
                })
              }
            }

            this.oms.clearMarkers()
            this.$refs.marker.forEach(marker => {
              this.oms.addMarker(marker.$markerObject)
            })
          }, 100)
        })
      }
    }

  }
}
</script>

<style scoped>
.info-line span {
  line-height : 1.5;
}

.info-line i, .info-line span {
  vertical-align : middle;
}

/deep/ .v-toolbar__extension {
  padding : 4px;
}
</style>
