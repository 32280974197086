<template>
  <div class="d-inline-flex">
    <v-menu
      v-model="visible"
      max-width="350"
      :close-on-content-click="false"
      offset-y
    >
      <template #activator="{on}">
        <div
          style="cursor: pointer;"
          class="text-no-wrap"
          v-on="on"
        >
          <v-icon
            small
            color="cyan"
            class="mr-1"
            :style="hover ? 'opacity: 1' : isDarkMode ? 'opacity: 0.15' : 'opacity: 0.35'"
          >
            mdi-playlist-edit
          </v-icon>

          <v-chip
            v-if="chip"
            :class="chipClass"
            :style="chipStyle"
            style="cursor: pointer;"
            class="px-2"
            label
          >
            {{ displayValue || value }}
          </v-chip>

          <span
            v-else
            style="cursor: pointer;"
          >
            {{ displayValue || value }}
          </span>
        </div>
      </template>

      <v-card>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="formValid"
            @submit.prevent
          >
            <v-text-field
              ref="input"
              v-model="editVal"
              v-mask="mask"
              :hint="hint"
              :label="label"
              :readonly="numpad"
              :prepend-icon="prependIcon"
              :rules="rules"
              :suffix="suffix"
              :type="type"
              hide-spin-buttons
              persistent-hint
              autofocus
              clearable
              @focus="$event.target.select()"
              @keyup.enter="onSaveButtonClick"
            />
          </v-form>

          <num-pad
            v-if="numpad"
            v-model="editVal"
            :initial-value="parseFloat(editVal)"
            :suffix="suffix"
            number
            class="mt-2"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions
          :class="[{'text-center': $vuetify.breakpoint.xsOnly}]"
          :style="$vuetify.breakpoint.xsOnly ? 'display: block;' : ''"
          class=""
        >
          <v-spacer class="hidden-xs-only" />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            small
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            small
            class="green white--text elevation-0"
            :disabled="isSaveDisabled"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import AppData from '@/mixins/appdata'
import NumPad  from '@/components/common/NumPad.vue'

export default {
  name      : 'DisplayEditableValue',
  components: { NumPad },
  directives: {},
  mixins    : [AppData],
  props     : {
    id: {
      type    : [String, Number],
      required: true
    },
    value: {
      type    : [String, Number],
      required: true
    },
    displayValue: {
      type   : [String, Number],
      default: ''
    },
    hover: {
      type   : Boolean,
      default: false
    },
    numpad: {
      type   : Boolean,
      default: false
    },
    chip: {
      type   : Boolean,
      default: false
    },
    chipClass: {
      type   : [String, Array, Object],
      default: ''
    },
    chipStyle: {
      type   : [String, Array, Object],
      default: ''
    },
    suffix: {
      type   : String,
      default: ''
    },
    hint: {
      type   : String,
      default: ''
    },
    label: {
      type   : String,
      default: ''
    },
    prependIcon: {
      type   : String,
      default: ''
    },
    mask: {
      type   : String,
      default: ''
    },
    type: {
      type   : String,
      default: 'text'
    },
    rules: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      visible  : false,
      formValid: false,
      editVal  : ''
    }
  },
  computed: {
    isSaveDisabled () {
      return this.editVal === this.value || this.editVal === ''
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.editVal = this.value
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onSaveButtonClick () {
      if (this.isSaveDisabled) return

      this.$refs.editForm.validate()

      if (this.formValid) {
        this.$emit('save', {
          Id   : this.id,
          Value: this.editVal
        })

        this.visible = false
      }
    },

    onCancelButtonClick () {
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
