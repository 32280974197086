import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class CustomerDomainValidator extends BaseValidator {
  rules = {
    Id: {
      required: true,
      type    : 'number'
    },
    Active: {
      required: true,
      type    : 'boolean'
    },
    CustomerId: {
      required: false,
      type    : 'numeric'
    },
    LeadId: {
      required: false,
      type    : 'numeric'
    },
    TrialExpiredAt: {
      required: false,
      type    : 'dateStringDash'
    },
    SubscriptionExpiredAt: {
      required: false,
      type    : 'dateStringDash'
    },
    SeasonStartedAt: {
      required: false,
      type    : 'dateNoYearStringDash'
    },
    SeasonEndedAt: {
      required: false,
      type    : 'dateNoYearStringDash'
    },
    ExtendedPeriodAt: {
      required: false,
      type    : 'dateStringDash'
    },
    CountryId: {
      required: false,
      type    : 'number'
    }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
