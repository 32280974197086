var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"auto","position":"absolute","width":"100%","height":"calc(100% - 80px)"},style:(`
      background-image : linear-gradient(rgba(0, 0, 0, .1) 1px, transparent 1px), linear-gradient(90deg, rgba(0, 0, 0, .1) 1px, transparent 1px);
      background-size  : ${50*_vm.zoom}px ${50*_vm.zoom}px;
      background-repeat : repeat;
  `)},[_c('div',{staticClass:"greenXX",style:(`
       width: calc(${200 * (1/_vm.zoom)}% - ${(100*_vm.zoom) * (1/_vm.zoom)}px);
       height: calc(${200 * (1/_vm.zoom)}% - ${(80 + (100*_vm.zoom)) * (1/_vm.zoom)}px);
       margin: ${50*_vm.zoom}px; transform: scale(${_vm.zoom});
       transform-origin: 0 0;
       position: absolute;`)},[_vm._l((_vm.tables),function(table,index){return [_c('floor-plan-table',{key:`floor-plan-table-${index}`,attrs:{"item":table,"zoom":_vm.zoom}})]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }