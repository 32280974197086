export default {
  Title: 'Customers | Customer | Customers',

  CustomerStatus: {
    Label: 'Status'
  },
  CustomerType: {
    Visitor   : 'Visitor',
    Registered: 'Registered Customer',
    Through   : 'with'
  },

  Table: {
    Button: {
      NewCustomer: {
        Title: 'New Customer',
        Icon : 'add_box',
        Color: 'primary'
      },
      Export: {
        Title         : 'Export',
        Icon          : 'file_download',
        Color         : 'primary',
        SuccessMessage: 'Soon you\'ll receive a file with the exported customers at your email: {email}'
      },
      Import: {
        Title         : 'Import',
        Subtitle      : 'Import customers from',
        Icon          : 'mdi-import',
        Color         : 'primary',
        SuccessMessage: 'Customers import was completed successfully.'
      },
      NewOrder: {
        Title: 'New Order',
        Icon : 'add_shopping_cart',
        Color: 'green'
      },
      NewReservation: {
        Title: 'New Reservation',
        Icon : 'mdi mdi-book-open-variant',
        Color: 'indigo'
      },
      ViewCustomer: {
        Title: 'View Customer',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      },
      DeleteCustomer: {
        Title: 'Delete Customer',
        Icon : 'delete',
        Color: 'red'
      }
    },

    Headers: {
      CustomerType: '',
      Id          : '#',
      Firstname   : 'Firstname',
      Lastname    : 'Lastname',
      Address     : 'Address',
      Groups      : 'Groups',
      Phone       : 'Phone',
      CreatedAt   : 'Created At',
      Actions     : ''
    },

    Filter: {
      filterCustomerStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Customer Status',
        PlaceHolder: 'All customers',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: 'true',
            Name : 'Active',
            Icon : 'check_circle',
            Color: 'light-green darken-1',
            Class: 'white--text'
          },
          {
            Value: 'false',
            Name : 'Inactive',
            Icon : 'check_circle',
            Color: 'orange darken-1',
            Class: 'white--text'
          }
        ]
      },
      filterCustomerSourceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Customer Source',
        PlaceHolder: 'All customer sources',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterCustomerGroup: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Customer Groups',
        PlaceHolder: 'All customer groups',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterCustomerSocial: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Registration Type',
        PlaceHolder: 'All registration types',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        ItemDesc   : 'Description',
        Items      : [
          {
            Value : 'Guest',
            Name  : 'Registered',
            Avatar: {
              Icon : 'verified_user',
              Color: 'amber'
            },
            Class: 'white--text'
          },
          {
            Value : 'Email',
            Name  : 'Registered with Email',
            Avatar: {
              Icon : 'email',
              Color: 'blue-grey'
            },
            Class: 'white--text'
          },
          {
            Value : 'Google',
            Name  : 'Registered with Google',
            Avatar: {
              Icon : 'fab fa-google-plus-square',
              Color: 'red'
            },
            Class: 'white--text'
          },
          {
            Value : 'Facebook',
            Name  : 'Registered with Facebook',
            Avatar: {
              Icon : 'fab fa-facebook-square',
              Color: 'blue darken-1'
            },
            Class: 'white--text'
          }
        ]
      },
      filterDate: {
        Type       : 'v-date-picker',
        Label      : 'Registration Date',
        PlaceHolder: 'Select Date',
        Icon       : 'event'
      }
    }
  },

  CustomersDeleteDialog: {
    Title       : 'Delete Customer {customer}?',
    Content     : 'Are you sure you want to delete customer "{customer}"?',
    Notification: 'The removal is permanent and the process is irreversible'
  },

  CustomerSelectEmailDialog: {
    Toolbar: {
      Title   : 'Customer Email',
      Subtitle: 'Enter customer email addresses',
      Icon    : 'mdi-email',
      Color   : 'primary'
    }
  },

  CustomerSelectCompanyDialog: {
    Toolbar: {
      Title        : 'Customer Company',
      Subtitle     : 'Select Company or Enter Company Details',
      SelectCompany: 'Select Company',
      Icon         : 'mdi-domain',
      Color        : 'indigo'
    }
  },

  CustomersExportDialog: {
    Toolbar: {
      Title   : 'Export Customers',
      Subtitle: 'Export Customers to excel',
      Icon    : 'file_download',
      Color   : 'primary'
    },
    Field: {
      Period: {
        Label: 'Select Customer Registration Period'
      },
      Group: {
        Label: 'Customer Groups',
        Hint : 'Select Customer Groups',
        Icon : 'group'
      },
      LastOrder: {
        Label            : 'Last Customer Order',
        Hint             : 'Select Period of Last Customer Order',
        HintWithOrders   : 'Period with customer orders',
        HintWithoutOrders: 'Period without customer orders',
        Icon             : 'add_shopping_cart',
        Items            : [
          {
            Name : 'Last week',
            Value: 'week'
          },
          {
            Name : 'Last month',
            Value: 'month'
          },
          {
            Name : 'Last three months',
            Value: 'months3'
          },
          {
            Name : 'Last six months',
            Value: 'months6'
          },
          {
            Name : 'Last year',
            Value: 'year'
          },
          {
            Name : 'More than last year',
            Value: 'yearMore'
          }
        ]
      },
      LastWithOrders: {
        Label: 'With Orders'
      },
      LastWithoutOrders: {
        Label: 'Without Orders'
      },
      Registered: {
        Label: 'Registered'
      },
      Guest: {
        Label: 'Guest'
      },
      WithAddress: {
        Label: 'Addresses'
      },
      ForSms: {
        Label: 'Excel for SMS'
      }
    },
    Button: {
      Export: 'Εξαγωγή'
    }
  },

  CustomerAddEditDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Customer',
        Subtitle: 'Create New Customer'
      },
      Edit: {
        Title   : 'Edit Customer',
        Subtitle: 'Modify Customer Details'
      },
      Icon : 'account_circle',
      Color: 'indigo'
    },
    Field: {
      Info: {
        Title    : 'Info',
        Firstname: {
          Label: 'First Name',
          Hint : 'Customer\'s First Name. eg. George, Jane, etc.',
          Icon : 'account_circle',
          Error: {
            Between: 'First Name must be between 2 and 32 characters'
          }
        },
        Lastname: {
          Label: 'Last Name',
          Hint : 'Customer\'s Last Name. eg. Smith, Doe, etc.',
          Icon : 'account_circle',
          Error: {
            Between: 'Last Name must be between 2 and 32 characters'
          }
        },
        Telephone: {
          Label: 'Telephone',
          Hint : 'Telephone. eg.',
          Icon : 'phone',
          Error: {
            Between: 'Telephone number must be between 8 and 16 digits'
          }
        },
        Email: {
          Label: 'Email',
          Hint : 'Customer\'s email address eg. support@getfoodpro.gr',
          Icon : 'email',
          Error: {
            Between: 'E-mail  must be up to 96 characters long',
            Valid  : 'E-mail must be valid'
          }
        },
        Group: {
          Label: 'Groups',
          Hint : 'Select Customer\'s Groups',
          Icon : 'group',
          Error: {
            Min: 'Please select at least one customer group'
          }
        }
      },
      Notifications: {
        Title   : 'Notifications',
        Subtitle: 'Promotional / Marketing Notification',
        Email   : {
          Label: 'Email'
        },
        Sms: {
          Label: 'SMS'
        },
        Push: {
          Label: 'Push'
        }
      }
    },
    Button: {
      SaveWithoutAddress: '@:Common.Button.Save without address',
      AddAddress        : 'Add address',
      CustomerDetails   : 'Customer details'
    }
  },

  CustomerAddCompanyDialog: {
    Toolbar: {
      Icon : 'mdi-domain',
      Color: 'indigo',
      Add  : {
        Title   : 'Add Company',
        Subtitle: 'Create New Company'
      },
      Edit: {
        Title   : 'Edit Company',
        Subtitle: 'Modify Company Details'
      }
    },
    Card: {
      NoData: {
        Icon    : 'mdi-domain',
        Title   : 'No Companies Found',
        Subtitle: 'Add a new company'
      }
    },
    Company: {
      Title: 'Company Info'
    },
    Address: {
      Title: 'Address Info'
    },
    Field: {
      BrandName: {
        Label : 'Name',
        Hint  : 'Company name',
        Icon  : 'account_balance',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      DistinctiveTitle: {
        Label : 'Distinctive Title',
        Hint  : 'Distinctive company title',
        Icon  : 'account_balance',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      Profession: {
        Label : 'Profession',
        Hint  : 'Company Profession',
        Icon  : 'account_balance',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      Phone: {
        Label : 'Phone',
        Hint  : 'Company phone number',
        Icon  : 'phone',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      VatNumber: {
        Label : 'VAT Number',
        Hint  : 'Company vat number',
        Icon  : 'toll',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters',
          ApiError : 'Non-existent / active VAT number'
        }
      },
      Irs: {
        Label : 'IRS',
        Hint  : 'Company Irs',
        Icon  : 'toll',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      Notes: {
        Label : 'Notes',
        Hint  : '',
        Icon  : 'mdi-text-box-edit',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 1024 characters'
        }
      },
      IsDefault: {
        Label : 'Default company',
        Hint  : '',
        Icon  : '',
        Errors: {
          Mandatory: 'Required field'
        }
      },
      County: {
        Label : 'State',
        Hint  : 'State of company headquarters',
        Icon  : 'map',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      City: {
        Label : 'City',
        Hint  : 'Company headquarters city',
        Icon  : 'place',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      Street: {
        Label : 'Address',
        Hint  : 'Company headquarters address',
        Icon  : 'my_location',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      StreetNumber: {
        Label : 'Number',
        Hint  : 'Company headquarters address number',
        Icon  : 'my_location',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      Postcode: {
        Label : 'Postcode',
        Hint  : 'Company headquarters postcode',
        Icon  : 'navigation',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      },
      Country: {
        Label : 'Country',
        Hint  : 'Company headquarters country',
        Icon  : 'place',
        Errors: {
          Mandatory: 'Required field',
          Valid    : 'Must be between 2 and 255 characters'
        }
      }
    }
  },

  CustomerAddEditAddressDialog: {
    Toolbar: {
      Icon : 'my_location',
      Color: 'indigo',
      Add  : {
        Title   : 'Add address',
        Subtitle: 'Create New Customer Address'
      },
      Edit: {
        Title   : 'Edit address',
        Subtitle: 'Modify Customer Address Details'
      }
    },
    UseCustom: 'Without map confirmation ?',
    Field    : {
      AutoComplete: {
        Label : 'Enter your address',
        Hint  : 'Enter your address.',
        Errors: {
          Mandatory: 'Please enter your address',
          Valid    : 'Address must be within delivery area of the store'
        }
      },
      Address: {
        PlaceHolder: 'Enter your address',
        Hint       : 'Enter your address',
        Icon       : 'location_on',
        Error      : {
          Range: {
            Subtitle: 'This address is not within store\'s delivery range.'
          },
          Custom: {
            Subtitle: 'Unconfirmed address. May be out of store delivery range.'
          }
        }
      },
      Instructions: {
        Label: 'Instructions',
        Hint : 'Additional address instructions',
        Icon : 'help'
      },
      DefaultAddress: {
        Label: 'Default Address'
      },
      FriendlyName: {
        Label: 'Friendly Name',
        Hint : 'eg. Home, Office, etc.',
        Icon : 'label'
      },
      Region: {
        PlaceHolder: 'City',
        Hint       : 'City',
        Icon       : 'location_on',
        Error      : {
          Between: 'City must be between 2 and 120 characters'
        }
      },
      Street: {
        PlaceHolder: 'Street',
        Hint       : 'Street',
        Icon       : 'location_on',
        Error      : {
          Between: 'Street must be between 2 and 120 characters'
        }
      },
      Number: {
        Label: 'Number',
        Error: {
          Between: 'Number must be between 1 and 10 characters'
        }
      },
      Postcode: {
        Label: 'Postcode',
        Hint : 'eg. SW1A 0AA',
        Error: {
          Valid: 'Postcode must be valid'
        }
      },
      Floor: {
        Label: 'Floor',
        Hint : 'eg. 1st, Ground Floor, House, etc.',
        Icon : 'domain'
      },
      DoorBell: {
        Label: 'Doorbell',
        Hint : 'Name written on the doorbell',
        Icon : 'notifications'
      }
    }
  },

  CustomerDetailsDialog: {
    Toolbar: {
      Title   : 'Customer Tab',
      Subtitle: 'Customer details and statistics'
    },
    Card: {
      Statistic: {
        Week: {
          FooterText: 'Previous @:Common.Date.Week:'
        },
        Month: {
          FooterText: 'Previous @:Common.Date.Month:'
        },
        Average: {
          Subtext: 'Average'
        },
        LoyaltyPoints: {
          Title     : 'Loyalty Points',
          Subtext   : 'Loyalty',
          FooterText: 'Total Points:'
        }
      },
      Details: {
        Toolbar: {
          Title: '@:Common.Misc.Details',
          Icon : 'account_circle',
          Color: 'blue darken-4',
          Tab  : {
            Customer : 'Customer',
            Companies: 'Companies'
          }
        },
        Content: {
          DateAdded    : 'Customer since',
          CustomerGroup: 'Customer Groups',
          Preferences  : 'Top 10 Customer Preferences | Customer Preference | Top {count} Customer Preferences'
        }
      },
      Companies: {
        Delete: {
          Title  : 'Delete Company?',
          Content: 'Do you want to permanently delete this company?'
        }
      },
      Address: {
        Toolbar: {
          Title: 'Addresses',
          Icon : 'location_on',
          Color: 'green darken-4'
        },
        Content: {
          ListView: {
            Floor       : '@:Customer.CustomerAddEditAddressDialog.Field.Floor.Label:',
            DoorBell    : '@:Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label:',
            Instructions: '@:Customer.CustomerAddEditAddressDialog.Field.Instructions.Label:'
          },
          NoData: {
            Title   : 'There Are No Addresses',
            Subtitle: 'Add a new address',
            Icon    : 'location_off',
            Color   : 'grey lighten-2'
          }
        },
        Delete: {
          Title  : 'Delete Address?',
          Content: 'Do you want to permanently delete this address?'
        }
      },
      Order: {
        Toolbar: {
          Title: 'Latest Orders | Latest Order | Latest {count} Orders',
          Icon : 'shopping_cart',
          Color: 'deep-purple darken-3'
        },
        Content: {
          NoData: {
            Title   : 'There Are No Orders',
            Subtitle: 'Add a new order',
            Icon    : 'shopping_cart',
            Color   : 'grey lighten-2'
          }
        }
      }
    },
    Button: {
      Color         : 'green',
      SelectCustomer: {
        Title: 'Select Customer',
        Icon : 'person_add'
      }
    }
  },

  CustomerGroupAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Customer Group',
        Subtitle: 'Create New Customer Group'
      },
      Edit: {
        Title   : 'Edit Customer Group',
        Subtitle: 'Modify Customer Group Details'
      },
      Icon : 'people',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: 'Name',
        Hint : 'Customer Group Name. eg. Company, Individual, E-Food, etc.',
        Icon : 'title',
        Error: {
          Between: 'Name must be between 2 and 32 characters'
        }
      },
      Description: {
        Label: 'Description',
        Hint : 'Customer Group description.',
        Icon : 'comment',
        Error: {
          Between: 'Description must be between 2 and 200 characters'
        }
      }
    }
  },

  CustomerGroups: {
    Table: {
      Button: {
        New: {
          Title: 'New Customer Group',
          Icon : 'add_box',
          Color: 'primary'
        },
        Edit: {
          Title: 'Edit Customer Group',
          Icon : 'edit',
          Color: 'cyan'
        }
      },

      Headers: {
        Id         : '#',
        Name       : 'Name',
        Description: 'Description',
        Actions    : ''
      }
    },

    Error: 'Category not updated. @:Common.Misc.Retry'
  },

  CustomerSearchDialog: {
    Toolbar: {
      Title   : 'Search customer',
      Subtitle: 'Search by name, surname, phone or E-mail',
      Icon    : 'account_circle',
      Color   : 'blue-grey lighten-1'
    }
  },

  CustomerSelectAddressDialog: {
    Toolbar: {
      Title   : 'Select Address',
      Subtitle: 'Select Customer Address',
      Icon    : 'my_location',
      Color   : 'indigo'
    },
    Header: {
      Name        : 'Name:',
      Telephone   : '@:Customer.CustomerAddEditDialog.Field.Info.Telephone.Label:',
      Email       : 'Email:',
      Floor       : '@:Customer.CustomerAddEditAddressDialog.Field.Floor.Label:',
      DoorBell    : '@:Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label:',
      Instructions: '@:Customer.CustomerAddEditAddressDialog.Field.Instructions.Label:'
    },
    Button: {
      NewAddress: {
        Title: 'New Address',
        Color: 'primary'
      }
    }
  }
}
