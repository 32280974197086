import BaseModel        from '../../../lib/data/Models/BaseModel'
import MessageTypeModel from './MessageTypeModel'
import SocketCommand    from '../../SocketCommand'
import { EventBus }     from '../../../events/eventBus'
import MessageValidator from '../../Validations/messages/MessageValidator'

export default class MessageModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  get TempType () {
    return this.tmpType
  }

  set TempType (val) {
    this.tmpType = this.Type = new MessageTypeModel(val)
    this.Important = this.Type.Important
    this.Body = this.cleanPaste(val.Template)
  }

  /* PROPERTIES */

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = rawData.Id || -1
    this.To = rawData.To || []
    this.Body = this.cleanPaste(rawData.Body) || ''
    this.DateAdded = rawData.DateAdded || ''
    this.TimeAdded = rawData.TimeAdded || ''
    this.Scheduled = rawData.Scheduled || ''
    this.Active = rawData.Active ?? true
    this.Important = rawData.Important ?? false
    this.Read = rawData.Read ?? false
    this.Type = new MessageTypeModel(rawData.Type)
    this.tmpType = new MessageTypeModel(rawData.Type, null)

    this.validator = new MessageValidator(this)
  }

  boot () {
    super.boot()

    this.guarded = ['tmpType']
    this.saveCommand = SocketCommand.Message.Send
  }

  /* METHODS */

  clone () {
    const msg = super.clone()

    if (Array.isArray(msg.To)) msg.To = msg.To.join(',')
    msg.Body = this.cleanPaste(msg.Body)
    return msg
  }

  cleanPaste (html) {
    // Remove MS Word / Outlook content
    html = String(html)
    html = html.replace(/<(\/)*(\\?xml:|body|html|!doctype|meta|title|link|font|del|ins|st1:|[ovwxp]:)((.|\s)*?)>/gi, '') // Unwanted tags
    html = html.replace(/(class|type|start)=("(.*?)"|(\w*))/gism, '') // Unwanted sttributes
    html = html.replace(/<head(.*?)head>/gism, '') // Style tags
    html = html.replace(/<style(.*?)style>/gism, '') // Style tags
    html = html.replace(/<script(.*?)script>/gism, '') // Script tags
    html = html.replace(/<!--(.*?)-->/gism, '') // HTML comments
    html = html.replace(new RegExp('style="cursor: nesw-resize;"', 'g'), '')
    // html = html.replace(new RegExp('<p><br></p>', 'g'), '')

    /* html = html.replace(/\s+/g, ' ').trim()
    html = html.replace(/<br([\s]*\/?>)/g, '<p> </p>')
    html = html.replace(/<p><\/p>/g, '<p> </p>')
    html = html.replace(/(<(?!\/)[\w=."'\s]*>) /g, '$1')
    html = html.replace(/ (<\/\w+>)/g, '$1') */

    return html
  }

  /* API METHODS */

  markAsRead () {
    EventBus.$once(SocketCommand.Message.Counts, resultData => this.onMarkAsRead(resultData))
    window.callAS(SocketCommand.Message.MarkRead, { Id: this.Id }, SocketCommand.Message.Counts)
  }

  onMarkAsRead (data) {
    this.Read = true
  }
}
