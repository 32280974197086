const ipcCommandsEnum = {
  App                  : 'app',
  AppSync              : 'app-sync',
  HardReload           : 'app-hard-reload',
  Reload               : 'app-reload',
  ClearHttpCache       : 'app-clear-http-cache',
  ClearStorageCache    : 'app-clear-storage-cache',
  ClearAllCache        : 'app-clear-all-cache',
  ClearAllAndRelaunch  : 'app-clear-all-and-relaunch',
  Relaunch             : 'app-relaunch',
  RetryLoadMainApp     : 'retry-loading-main-app',
  CheckForUpdates      : 'check-for-updates',
  AppExit              : 'app-exit',
  AppFocus             : 'app-focus',
  PrintUrlToPrinter    : 'print-url-to-printer',
  PrintRawHtmlToPrinter: 'print-raw-html-to-printer',
  GetSystemPrinters    : 'get-system-printers',
  GetSystemDisplays    : 'get-system-displays',
  OpenCds              : 'open-cds',
  CloseCds             : 'close-cds',
  ToggleCds            : 'toggle-cds',
  AutoUpdater          : {
    CheckingForUpdate : 'auto-updater:checking-for-update',
    UpdateAvailable   : 'auto-updater:update-available',
    UpdateNotAvailable: 'auto-updater:update-not-available',
    UpdateDownloaded  : 'auto-updater:update-downloaded',
    Progress          : 'auto-updater:progress',
    Error             : 'auto-updater:error'
  }
}

export default ipcCommandsEnum
