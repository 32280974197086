<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Marketing.Coupon.ListView.Button.Add.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showCouponAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Marketing.Coupon.ListView.Button.Add.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Marketing.Coupon.ListView.Button.Add.Title') }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.coupon_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showCouponEditDialog(item)"
                >
                  {{ item.Name }}
                </a>
              </template>
              <template v-else>
                {{ item.Name }}
              </template>
            </template>

            <template #[`item.code`]="{item}">
              <v-chip
                label
                small
              >
                {{ item.Code }}
              </v-chip>
            </template>

            <template #[`item.discount`]="{item}">
              <template v-if="item.Type === 'P'">
                {{ parseFloat(item.Discount).toFixed(2) }} %
              </template>

              <template v-else>
                {{ item.Discount | currency }}
              </template>
            </template>

            <template #[`item.validity`]="{item}">
              {{ item.ValidityName }}
            </template>

            <template #[`item.used_count`]="{item}">
              <v-chip
                label
                small
              >
                {{ formatDecimal($i18n.locale, item.UsedCount, 0) }}
              </v-chip>
            </template>

            <template #[`item.customers`]="{item}">
              <v-chip
                label
                small
              >
                {{ item.Customers.length }}
              </v-chip>
            </template>

            <template #[`item.customer_groups`]="{item}">
              <v-chip
                label
                small
              >
                {{ item.CustomerGroups.length }}
              </v-chip>
            </template>

            <template #[`item.order_restriction`]="{item}">
              <v-chip
                class="pl-0 ma-1"
                label
                small
              >
                <v-avatar
                  :color="getOrderTypeById(item.OrderRestriction).Color"
                  class="mr-2"
                  tile
                >
                  <v-icon class="white--text">
                    {{ getOrderTypeById(item.OrderRestriction).Icon }}
                  </v-icon>
                </v-avatar>
                <span>{{ getOrderTypeById(item.OrderRestriction).Name }}</span>
              </v-chip>
            </template>

            <template #[`item.source_type`]="{item}">
              <template v-for="sourceTypeId in item.SourceType">
                <v-chip
                  :key="`source-type-${sourceTypeId}`"
                  class="pl-0 ma-1"
                  label
                  small
                >
                  <v-avatar
                    :color="getSourceTypeById(sourceTypeId).Avatar.Color"
                    class="mr-2"
                    tile
                  >
                    <v-icon
                      v-if="getSourceTypeById(sourceTypeId).Avatar.Icon"
                      class="white--text"
                    >
                      {{ getSourceTypeById(sourceTypeId).Avatar.Icon }}
                    </v-icon>

                    <img
                      v-else
                      :src="getSourceTypeById(sourceTypeId).Avatar.Img"
                    >
                  </v-avatar>
                  <span>{{ getSourceTypeById(sourceTypeId).Name }}</span>
                </v-chip>
              </template>
            </template>

            <template #[`item.autoApply`]="{item}">
              <v-icon
                v-if="item.AutoApply"
                color="success"
              >
                check
              </v-icon>
              <v-icon
                v-else
                color="grey lighten-3"
              >
                close
              </v-icon>
            </template>

            <template #[`item.status`]="{item}">
              <v-switch
                v-model="item.Status"
                inset
                :disabled="!userCanManage"
                :label="item.Status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                color="success"
                class="ma-0"
                hide-details
                @change="updateItem(item)"
              />
            </template>

            <template #[`item.actions`]="{item}">
              <v-tooltip
                v-if="userCanManage"
                :color="$t('Marketing.Coupon.ListView.Button.Edit.Color')"
                :open-delay="1000"
                left
              >
                <template #activator="{on}">
                  <v-btn
                    v-if="userCanManage"
                    :class="$t('Marketing.Coupon.ListView.Button.Edit.Color')"
                    :small="$vuetify.breakpoint.xsOnly"
                    class="elevation-2 ma-0"
                    dark
                    icon
                    v-on="on"
                    @click="showCouponEditDialog(item)"
                  >
                    <v-icon>{{ $t('Marketing.Coupon.ListView.Button.Edit.Icon') }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Marketing.Coupon.ListView.Button.Edit.Title') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <coupon-add-dialog
      :coupon-to-edit="tmpCoupon"
      :visible.sync="addEditCouponDialog"
      :categories="categories"
      :customer-groups="customerGroups"
    />
  </v-card>
</template>

<script>
import AppData           from '@/mixins/appdata'
import Auth              from '@/mixins/auth'
import DataTable         from '@/mixins/data-table/dataTable'
import FilterSearch      from '@/components/common/filter/FilterSearch.vue'
import CouponAddDialog   from './CouponAddDialog.vue'
import SourceType        from '@/mixins/orders/sourceType'
import { formatDecimal } from '@/lib/currency/currency'

export default {
  components: {
    FilterSearch,
    'coupon-add-dialog': CouponAddDialog
  },
  directives: {},
  mixins    : [AppData, Auth, DataTable, SourceType],
  data () {
    return {
      categories         : [],
      customerGroups     : [],
      selectedCoupon     : null,
      tmpCoupon          : null,
      addEditCouponDialog: false,
      dataTable          : {
        socketCommand: {
          list: window.SocketCommand.Coupon.All,
          save: window.SocketCommand.Coupon.Save
        },
        options: {
          defaultSortBy: 'coupon_id',
          sortBy       : ['coupon_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Id'),
            value    : 'coupon_id',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Name'),
            value    : 'name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Code'),
            value    : 'code',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Discount'),
            value    : 'discount',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Validity'),
            value    : 'validity',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.UsedCount'),
            value    : 'used_count',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Customers'),
            value    : 'customers',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.CustomerGroups'),
            value    : 'customer_groups',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.SourceType'),
            value    : 'source_type',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.OrderRestriction'),
            value    : 'order_restriction',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.AutoApply'),
            value    : 'autoApply',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Status'),
            value    : 'status',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 40
          }
        ]
      }
    }
  },
  computed: {
    sourceTypes () {
      const sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT) || []

      return sourceTypesDefault.map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.CustomerGroup.All, this.setCustomerGroups)
    this.$bus.$on(window.SocketCommand.Category.All, this.setCategoriesData)
    this.getCategories()
    this.getCustomerGroups()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.CustomerGroup.All, this.setCustomerGroups)
    this.$bus.$off(window.SocketCommand.Category.All, this.setCategoriesData)
  },
  methods: {
    formatDecimal,
    getOrderTypeById (orderTypeId) {
      const orderTypes = this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Items')

      return orderTypes.find(orderType => parseInt(orderType.Id) === parseInt(orderTypeId))
    },

    getSourceTypeById (id) {
      return this.sourceTypes.find(sourceType => parseInt(sourceType.Id) === parseInt(id))
    },

    getCustomerGroupById (id) {
      return this.customerGroups.find(customerGroup => parseInt(customerGroup.Id) === parseInt(id))
    },

    getCategories () {
      window.callAS(window.SocketCommand.Category.All, {
        config    : 0,
        page_limit: -1
      })
    },

    getCustomerGroups () {
      if (this.posUserHasComponentPermission('CustomerGroups', 'ACCESS')) {
        window.callAS(window.SocketCommand.CustomerGroup.All, {
          page_limit: -1,
          config    : 0
        })
      }
    },

    updateItem (item) {
      window.callAS(window.SocketCommand.Coupon.Save, item)
    },

    showCouponAddDialog () {
      this.tmpCoupon = null
      this.addEditCouponDialog = true
    },

    showCouponEditDialog (coupon) {
      this.tmpCoupon = coupon
      this.addEditCouponDialog = true
    },

    setCustomerGroups (data) {
      this.customerGroups = data?.items || []
    },

    setCategoriesData (data) {
      data = (data?.items || []).map((o) => {
        if (o.Image) {
          o.Avatar = {
            Img: `${ this.appConfig.LOCATION_DATA.CdnImagesUrl }${ o.Image }`
          }
        } else {
          o.Avatar = {
            Icon : 'category',
            Color: 'grey'
          }
        }
        const activeCatalogName = this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => o.Catalogs.includes(catalog.Id))?.map(o => o.Name)?.join(', ') || ''
        o.Description = activeCatalogName
        o.Id = parseInt(o.Id)
        return o
      })

      this.categories = data || []
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 75px !important;*/
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
