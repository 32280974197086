import ReservationStatusEnum from '@/api/Enums/ReservationStatusEnum'

export default {
  Title: 'Κρατήσεις Τραπεζιών | Κράτηση Τραπεζιού | Κρατήσεις Τραπεζιών',

  Table: {
    Headers: {
      Id         : '#',
      SourceType : 'Πηγή',
      Code       : 'Κωδικός',
      Status     : 'Κατάσταση',
      Tags       : 'Ετικέτες',
      Reservation: 'Κράτηση',
      Date       : 'Ημερομηνία',
      Time       : 'Ώρα',
      Arrival    : 'Άφιξη',
      Departure  : 'Αποχώρηση',
      Persons    : 'Άτομα',
      Duration   : 'Διάρκεια',
      Comments   : 'Σχόλια',
      Notes      : 'Σημειώσεις',
      Customer   : 'Πελάτης',
      Table      : 'Τραπέζι',
      Actions    : ''
    },

    Button: {
      New: {
        Color: 'primary',
        Icon : 'add_box',
        Title: 'Νέα Κράτηση'
      },
      Edit: {
        Color  : 'cyan',
        Icon   : 'edit',
        Tooltip: 'Επεξεργασία Κράτησης'
      },
      Arrived: {
        Color  : 'light-green',
        Icon   : 'mdi mdi-account-multiple',
        Tooltip: 'Άφιξη'
      },
      PartiallyArrived: {
        Color  : 'amber darken-2',
        Icon   : 'mdi mdi-account-plus',
        Tooltip: 'Μερική Άφιξη'
      },
      Finished: {
        Color  : 'grey',
        Icon   : 'mdi mdi-check',
        Tooltip: 'Ολοκλήρωση'
      },
      Cancel: {
        Color  : 'pink',
        Icon   : 'close',
        Tooltip: 'Ακύρωση Κράτησης'
      },
      Delete: {
        Color  : 'red',
        Icon   : 'delete',
        Tooltip: 'Διαγραφή Κράτησης'
      }
    },

    Filter: {
      filterWithTable: {
        Type       : 'v-btn-toggle',
        Label      : 'Κρατήσεις',
        PlaceHolder: 'Όλες οι κρατήσεις',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλες'
          },
          {
            Value: 'true',
            Name : 'Με Τραπέζι',
            Icon : 'mdi mdi-check-circle-outline',
            Color: 'blue darken-1',
            Class: 'white--text'
          },
          {
            Value: 'false',
            Name : 'Χωρίς Τραπέζι',
            Icon : 'mdi mdi-close-circle-outline',
            Color: 'orange darken-1',
            Class: 'white--text'
          }
        ]
      },
      filterStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Κατάσταση',
        PlaceHolder: 'Όλες οι καταστάσεις',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterTable: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Τραπέζι',
        PlaceHolder: 'Όλα τα τραπέζια',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterDate: {
        Type       : 'v-date-picker',
        Range      : false,
        Label      : 'Ημερομηνία',
        PlaceHolder: 'Επιλέξτε Ημερομηνία',
        Icon       : 'event'
      },
      filterTimeStart: {
        Type       : 'v-time-picker',
        Label      : 'Από Ώρα',
        PlaceHolder: 'Επιλέξτε Ώρα',
        Cols       : 6,
        Icon       : 'mdi mdi-clock-time-eight-outline'
      },
      filterTimeEnd: {
        Type       : 'v-time-picker',
        Label      : 'Έως Ώρα',
        PlaceHolder: 'Επιλέξτε Ώρα',
        Cols       : 6,
        Icon       : 'mdi mdi-clock-time-eight-outline'
      }
    },

    ConfirmDialog: {
      Delete: {
        Title       : 'Διαγραφή Κράτησης;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε οριστικά την κράτηση με κωδικό <strong>{code}</strong> ;',
        Notification: 'Η διαγραφή είναι οριστική και η διαδικασία είναι μη αναστρέψιμη'
      }
    }
  },

  AddEditDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Κράτησης',
        Subtitle: 'Δημιουργία Νέας Κράτησης'
      },
      Edit: {
        Title   : 'Επεξεργασία Κράτησης',
        Subtitle: 'Τροποποίηση Στοιχείων Κράτησης'
      },
      Icon : 'menu_book',
      Color: 'indigo'
    },

    Section: {
      Customer: {
        TitleCustomer: 'Στοιχεία Πελάτη',
        TitleGuest   : 'Στοιχεία Επισκέπτη',
        Button       : {
          Select: 'Επιλογή Πελάτη',
          Change: 'Αλλαγή Πελάτη',
          Guest : 'Επισκέπτης'
        }
      },
      Reservation: {
        Title: 'Στοιχεία Κράτησης'
      }
    },

    Field: {
      FirstName: {
        Label: 'Όνομα',
        Hint : 'Όνομα πελάτη. πχ. Βαγγέλης, Μαρία, κλπ.',
        Icon : 'account_circle',
        Error: {
          Required: 'Πρέπει να συμπληρώσετε όνομα',
          Between : 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      LastName: {
        Label: 'Επίθετο',
        Hint : 'Επίθετο πελάτη. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
        Icon : 'account_circle',
        Error: {
          Required: 'Πρέπει να συμπληρώσετε επίθετο',
          Between : 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Telephone: {
        Label: 'Τηλέφωνο',
        Hint : 'Τηλέφωνο πελάτη. πχ. 2152152324',
        Icon : 'phone',
        Error: {
          Required: 'Πρέπει να συμπληρώσετε τηλέφωνο',
          Between : 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Διεύθυνση Email πελάτη. πχ. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Required: 'Πρέπει να συμπληρώσετε E-mail',
          Between : 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
          Valid   : 'Το E-mail πρέπει να είναι έγκυρο'
        }
      },
      ReserveDate: {
        Label: 'Ημερομηνία',
        Hint : '',
        Icon : 'event',
        Error: {
          Required: 'Πρέπει να επιλέξετε ημερομηνία κράτησης',
          Invalid : 'Επιλέξτε έγκυρη ημερομηνία'
        }
      },
      ReserveTime: {
        Label: 'Ώρα',
        Hint : '',
        Icon : 'access_time',
        Error: {
          Required: 'Πρέπει να επιλέξετε ώρα κράτησης',
          Invalid : 'Επιλέξτε έγκυρη ώρα'
        }
      },
      Duration: {
        Label: 'Διάρκεια',
        Hint : '',
        Icon : 'mdi mdi-timer-refresh-outline',
        Error: {
          Required: 'Πρέπει να συμπληρώσετε διάρκεια'
        }
      },
      Persons: {
        Label: 'Άτομα',
        Hint : '',
        Icon : 'mdi mdi-account-multiple-outline',
        Error: {
          Required: 'Πρέπει να συμπληρώσετε άτομα'
        }
      },
      SourceType: {
        Label: 'Πηγή Παραγγελίας',
        Hint : '',
        Icon : 'swipe_right_alt',
        Error: {
          Required: 'Πρέπει να επιλέξετε πηγή παραγγελίας'
        }
      },
      Status: {
        Label: 'Κατάσταση',
        Hint : 'Επιλέξτε Κατάσταση Κράτησης',
        Icon : 'mdi mdi-list-status',
        Error: {
          Required: 'Παρακαλώ επιλέξτε κατάσταση κράτησης'
        }
      },
      Tables: {
        Label: 'Τραπέζι',
        Hint : 'Επιλέξτε Τραπέζι ή Τραπέζια για την κράτηση',
        Icon : 'tab_unselected',
        Error: {}
      },
      Tags: {
        Label: 'Ετικέτες',
        Hint : 'Επιλέξτε Tags Κράτησης',
        Icon : 'mdi mdi-tag-plus',
        Error: {}
      },
      Comment: {
        Label: 'Σχόλια Πελάτη',
        Hint : 'Σχόλια που εμφανίζονται στον πελάτη',
        Icon : 'mdi mdi-comment-account-outline'
      },
      Notes: {
        Label: 'Σημειώσεις Διαχειριστή',
        Hint : 'Οι σημειώσεις διαχειριστή δεν εμφανίζονται στον πελάτη',
        Icon : 'mdi mdi-text-box-edit-outline'
      },
      Notify: {
        Label   : 'Αποστολή Ειδοποίησης',
        Customer: { Label: 'Αποστολή ειδοποίησης στον πελάτη' },
        Guest   : { Label: 'Αποστολή ειδοποίησης στον επισκέπτη' }
      }
    },

    Notification: {
      Error: 'Υπήρξε πρόβλημα, παρακαλώ δοκιμάστε ξανά.'
    }
  },

  Status: {
    Title: 'Κατάσταση',
    Items: [
      {
        Id    : ReservationStatusEnum.WaitingList,
        Name  : 'Λίστα Αναμονής',
        Avatar: {
          Color: 'purple',
          Icon : 'mdi mdi-clipboard-text-clock-outline'
        }
      },
      {
        Id    : ReservationStatusEnum.Late,
        Name  : 'Σε Καθυστέρηση',
        Avatar: {
          Color: 'orange',
          Icon : 'mdi mdi-clock-alert-outline'
        }
      },
      {
        Id    : ReservationStatusEnum.NotConfirmed,
        Name  : 'Μη Επιβεβαιωμένη',
        Avatar: {
          Color: 'grey darken-3',
          Icon : 'mdi mdi-cellphone-remove'
        }
      },
      {
        Id    : ReservationStatusEnum.Confirmed,
        Name  : 'Επιβεβαιωμένη',
        Avatar: {
          Color: 'blue',
          Icon : 'mdi mdi-cellphone-check'
        }
      },
      {
        Id    : ReservationStatusEnum.NoAnswer,
        Name  : 'Χωρίς Απάντηση',
        Avatar: {
          Color: 'red darken-2',
          Icon : 'mdi mdi-cellphone-remove'
        }
      },
      {
        Id    : ReservationStatusEnum.Arrived,
        Name  : 'Άφιξη',
        Avatar: {
          Color: 'light-green',
          Icon : 'mdi mdi-account-multiple'
        }
      },
      {
        Id    : ReservationStatusEnum.PartiallyArrived,
        Name  : 'Μερική Άφιξη',
        Avatar: {
          Color: 'amber darken-2',
          Icon : 'mdi mdi-account-plus'
        }
      },
      {
        Id    : ReservationStatusEnum.Finished,
        Name  : 'Ολοκληρωμένη',
        Avatar: {
          Color: 'grey',
          Icon : 'mdi mdi-check'
        }
      },
      {
        Id    : ReservationStatusEnum.Denied,
        Name  : 'Άρνηση',
        Avatar: {
          Color: 'pink',
          Icon : 'mdi mdi-minus'
        }
      },
      {
        Id    : ReservationStatusEnum.Canceled,
        Name  : 'Ακυρωμένη',
        Avatar: {
          Color: 'red',
          Icon : 'mdi mdi-close'
        }
      },
      {
        Id    : ReservationStatusEnum.NoShow,
        Name  : 'Μη Εμφάνιση',
        Avatar: {
          Color: 'blue-grey',
          Icon : 'mdi mdi-account-remove'
        }
      }
    ]
  },

  Tags: {
    Title: 'Ετικέτες',
    Items: [
      {
        Id    : 1,
        Name  : 'Επέτειος',
        Avatar: {
          Icon : 'mdi mdi-heart',
          Color: 'amber'
        }
      },
      {
        Id    : 2,
        Name  : 'Γενέθλια',
        Avatar: {
          Icon : 'mdi mdi-cake-variant',
          Color: 'amber'
        }
      },
      {
        Id    : 3,
        Name  : 'Τακτικός',
        Avatar: {
          Icon : 'mdi mdi-account-clock',
          Color: 'pink'
        }
      },
      {
        Id    : 4,
        Name  : 'VIP',
        Avatar: {
          Icon : 'mdi mdi-star',
          Color: 'pink'
        }
      },
      {
        Id    : 5,
        Name  : 'Καπνίζοντες',
        Avatar: {
          Icon : 'mdi mdi-smoking',
          Color: 'cyan'
        }
      },
      {
        Id    : 6,
        Name  : 'Παράθυρο',
        Avatar: {
          Icon : 'mdi mdi-window-closed-variant',
          Color: 'cyan'
        }
      }
    ]
  }
}
