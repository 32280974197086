/**
 * Enum for barcode prefix.
 * @readonly
 * @enum {String} BarcodePrefixEnum
 */

const BarcodePrefixEnum = {
  Order        : '0980',
  DeliveryStaff: '0990'
}

export default BarcodePrefixEnum
