<template>
  <div v-if="tags && tags.length">
    <template v-for="(tag, index) in tags">
      <v-chip
        :key="`tag-${index}`"
        :class="chipClass"
        :label="label"
        :small="small"
        :x-small="xSmall"
        :color="chipColor"
        :text-color="chipTextColor"
      >
        <v-icon
          v-if="icon"
          :class="iconClass"
          :color="iconColor"
          :small="small"
          :x-small="xSmall"
          left
        >
          {{ icon }}
        </v-icon>

        <span :class="textClass">{{ tag }}</span>
      </v-chip>
    </template>
  </div>
</template>

<script>

export default {
  name      : 'TableAreaTags',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    tags: {
      type   : Array,
      default: () => []
    },
    chipClass: {
      type   : String,
      default: 'mr-1'
    },
    chipColor: {
      type   : String,
      default: ''
    },
    chipTextColor: {
      type   : String,
      default: ''
    },
    textClass: {
      type   : String,
      default: 'font-weight-medium'
    },
    label: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    },
    xSmall: {
      type   : Boolean,
      default: false
    },
    icon: {
      type   : String,
      default: 'mdi mdi-tag'
    },
    iconColor: {
      type   : String,
      default: ''
    },
    iconClass: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
