<template>
  <v-card>
    <v-toolbar
      flat
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Notification.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Notification.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Notification.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Notification.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <vuetify-audio
        :disabled="!userCanManage"
        :show-progress-bar="false"
        :show-progress-timer="false"
        :sound.sync="selectedSound"
        :sounds="sounds"
      />

      <v-card flat>
        <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
          <v-icon class="pr-3">
            {{ $t('Settings.Notification.Toolbar.Icon') }}
          </v-icon>

          <h5 class="text-body-2 font-weight-bold">
            {{ $t('Settings.Notification.Toolbar.Title') }}
          </h5>
        </v-card-title>

        <v-divider />

        <template v-if="viewData">
          <v-card-text>
            <v-container
              fluid
              class="pa-0"
            >
              <!-- Store Email -->
              <v-row dense>
                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Notification.Data.StoreEmail.Title') }}
                  </h5>
                  <span class="text-caption mr-1">{{ viewData.Store.Email }}</span>

                  <v-btn
                    icon
                    x-small
                    color="cyan"
                    dark
                    :to="{name: 'SystemSettingsRestaurant'}"
                  >
                    <v-icon small>
                      mdi-email-edit-outline
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>
              </v-row>

              <!-- Registration Notifications -->
              <v-row
                v-if="posUserHasComponentPermission('Online', 'ACCESS')"
                dense
              >
                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Notification.Data.Registration.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Notification.Data.Registration.Subtitle') }}</span>
                </v-col>

                <v-col cols="12">
                  <v-btn-toggle
                    v-model="viewData.Registration"
                    :disabled="!userCanManage"
                    color="success"
                    multiple
                    dense
                  >
                    <v-btn
                      :disabled="!userCanManage"
                      text
                      value="customer"
                    >
                      {{ $t('Settings.Notification.Data.Button.To.Customer').toLocaleUpperCase($i18n.locale) }}
                    </v-btn>

                    <v-btn
                      :disabled="!userCanManage"
                      text
                      value="location"
                    >
                      {{ $t('Settings.Notification.Data.Button.To.Store').toLocaleUpperCase($i18n.locale) }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>
              </v-row>

              <!-- Orders Notifications -->
              <v-row dense>
                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Notification.Data.Order.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Notification.Data.Order.Subtitle') }}</span>
                </v-col>

                <v-col cols="12">
                  <v-card
                    flat
                    :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
                  >
                    <v-card-text class="pa-2">
                      <v-simple-table>
                        <template #default>
                          <thead>
                            <tr>
                              <th class="text-center pa-1">
                                {{ $t('Order.SourceType.Title') }}
                              </th>

                              <th class="text-center pa-1">
                                {{ $t('Order.FilterStatuses.Title') }}
                              </th>

                              <th class="text-center pa-1">
                                {{ $t('Order.Type.Title') }}
                              </th>

                              <th class="text-center pa-1">
                                {{ $t('Settings.Notification.Data.Button.Channel.Email') }}
                              </th>

                              <th class="text-center pa-1">
                                {{ $t('Settings.Notification.Data.Button.Channel.Push') }}
                              </th>

                              <th class="text-center pa-1">
                                {{ $t('Settings.Notification.Data.Button.Channel.Sms') }}
                              </th>

                              <th class="text-center pa-1">
                                {{ $t('Common.Button.Toggle.ActiveF') }}
                              </th>

                              <th class="text-right pa-1">
                                <v-btn
                                  icon
                                  depressed
                                  x-small
                                  class="primary white--text"
                                  @click="addNotifySource"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <template v-for="(sourceType, sourceTypeIdx) in viewData.Notify">
                              <tr :key="`source-type-${sourceTypeIdx}`">
                                <td class="pa-1 text-center">
                                  <v-select
                                    v-model="sourceType.source"
                                    :items="activeSourceTypesGroupsWithGroups"
                                    :label="$t('Restaurant.CatalogAddDialog.Field.SourceTypes.Label')"
                                    class="d-inline-flex"
                                    style="max-width: 255px;"
                                    item-text="Name"
                                    item-value="Id"
                                    outlined
                                    single-line
                                    dense
                                    hide-details
                                  >
                                    <template #selection="{item}">
                                      <v-chip
                                        small
                                        label
                                        :color="item.Status ? 'green' : ''"
                                        :dark="item.Status"
                                        class="pl-0"
                                        style="width: 100%;"
                                      >
                                        <v-avatar
                                          tile
                                          :color="item.Avatar.Color"
                                          class="mr-2"
                                        >
                                          <v-icon
                                            v-if="item.Avatar.Icon"
                                            class="white--text"
                                          >
                                            {{ item.Avatar.Icon }}
                                          </v-icon>

                                          <img
                                            v-else
                                            :src="item.Avatar.Img"
                                          >
                                        </v-avatar>
                                        <span>{{ item.Description }}</span>
                                      </v-chip>
                                    </template>

                                    <template #item="data">
                                      <v-list-item-avatar
                                        :size="28"
                                        :color="data.item.Avatar.Color"
                                      >
                                        <v-icon
                                          v-if="data.item.Avatar.Icon"
                                          class="white--text"
                                        >
                                          {{ data.item.Avatar.Icon }}
                                        </v-icon>

                                        <img
                                          v-else
                                          :src="data.item.Avatar.Img"
                                        >
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </td>

                                <td class="pa-1 text-center">
                                  <v-select
                                    v-model="sourceType.status"
                                    :items="orderStatusesFiltered"
                                    :label="$t('Restaurant.CatalogAddDialog.Field.OrderStatus.Label')"
                                    style="max-width: 290px;"
                                    class="d-inline-flex"
                                    item-text="Name"
                                    item-value="Id"
                                    single-line
                                    hide-details
                                    outlined
                                    dense
                                  >
                                    <template #selection="{item}">
                                      <v-chip
                                        small
                                        label
                                        :color="item.Status ? 'green' : ''"
                                        :dark="item.Status"
                                        class="pl-0"
                                        style="width: 100%;"
                                      >
                                        <v-avatar
                                          tile
                                          :color="item.Avatar.Color"
                                          class="mr-2"
                                        >
                                          <v-icon class="white--text">
                                            {{ item.Avatar.Icon }}
                                          </v-icon>
                                        </v-avatar>
                                        <span>{{ item.Name }}</span>
                                      </v-chip>
                                    </template>

                                    <template #item="data">
                                      <v-list-item-avatar :color="data.item.Avatar.Color">
                                        <v-icon dark>
                                          {{ data.item.Avatar.Icon }}
                                        </v-icon>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </td>

                                <td class="pa-1 text-center">
                                  <v-select
                                    v-model="sourceType.type"
                                    :items="orderTypesListFiltered"
                                    :label="$t('Restaurant.CatalogAddDialog.Field.OrderType.Label')"
                                    style="max-width: 250px;"
                                    class="d-inline-flex"
                                    item-text="Name"
                                    item-value="Id"
                                    single-line
                                    hide-details
                                    outlined
                                    dense
                                  >
                                    <template #selection="{item}">
                                      <v-chip
                                        small
                                        label
                                        :color="item.Status ? 'green' : ''"
                                        :dark="item.Status"
                                        class="pl-0"
                                        style="width: 100%;"
                                      >
                                        <v-avatar
                                          tile
                                          :color="item.Color"
                                          class="mr-2"
                                        >
                                          <v-icon class="white--text">
                                            {{ item.Icon }}
                                          </v-icon>
                                        </v-avatar>
                                        <span>{{ item.Name }}</span>
                                      </v-chip>
                                    </template>

                                    <template #item="data">
                                      <v-list-item-avatar :color="data.item.Color">
                                        <v-icon dark>
                                          {{ data.item.Icon }}
                                        </v-icon>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </td>

                                <td class="pa-1 text-center">
                                  <v-btn-toggle
                                    v-model="sourceType.via.email"
                                    :disabled="!userCanManage"
                                    color="success"
                                    class="mr-3"
                                    dense
                                    multiple
                                  >
                                    <v-btn
                                      :disabled="!userCanManage"
                                      text
                                      value="customer"
                                    >
                                      {{ $t('Settings.Notification.Data.Button.To.Customer').toLocaleUpperCase($i18n.locale) }}
                                    </v-btn>

                                    <v-btn
                                      :disabled="!userCanManage"
                                      text
                                      value="location"
                                    >
                                      {{ $t('Settings.Notification.Data.Button.To.Store').toLocaleUpperCase($i18n.locale) }}
                                    </v-btn>
                                  </v-btn-toggle>
                                </td>

                                <td class="pa-1 text-center">
                                  <v-btn
                                    v-if="!appNotifyPushActive"
                                    color="primary"
                                    @click="onRequestDialogShow('Push')"
                                  >
                                    {{ $t('Settings.Notification.SupportRequest.Contact') }}
                                  </v-btn>

                                  <v-btn-toggle
                                    v-else
                                    v-model="sourceType.via.push"
                                    :disabled="!userCanManage"
                                    color="success"
                                    class="mr-3"
                                    dense
                                    multiple
                                  >
                                    <v-btn
                                      :disabled="!userCanManage"
                                      text
                                      value="customer"
                                    >
                                      {{ $t('Settings.Notification.Data.Button.To.Customer').toLocaleUpperCase($i18n.locale) }}
                                    </v-btn>
                                  </v-btn-toggle>
                                </td>

                                <td class="pa-1 text-center">
                                  <v-btn
                                    v-if="!appNotifySmsActive"
                                    color="primary"
                                    @click="onRequestDialogShow('Sms')"
                                  >
                                    {{ $t('Settings.Notification.SupportRequest.Contact') }}
                                  </v-btn>

                                  <v-btn-toggle
                                    v-else
                                    v-model="sourceType.via.sms"
                                    :disabled="!userCanManage"
                                    color="success"
                                    class="mr-3"
                                    dense
                                    multiple
                                  >
                                    <v-btn
                                      :disabled="!userCanManage"
                                      text
                                      value="customer"
                                    >
                                      {{ $t('Settings.Notification.Data.Button.To.Customer').toLocaleUpperCase($i18n.locale) }}
                                    </v-btn>
                                  </v-btn-toggle>
                                </td>

                                <td class="pa-1 text-center">
                                  <v-switch
                                    v-model="sourceType.active"
                                    :disabled="!userCanManage"
                                    color="success"
                                    class="ma-0 pa-0"
                                    hide-details
                                    inset
                                  />
                                </td>

                                <td class="pa-1 text-right">
                                  <v-btn
                                    icon
                                    depressed
                                    x-small
                                    class="red white--text"
                                    @click="removeNotifySource(sourceTypeIdx)"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>
              </v-row>

              <!-- Order Status Actions Internal Notifications -->
              <v-row dense>
                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Notification.Data.InternalOrder.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Notification.Data.InternalOrder.Subtitle') }}</span>
                </v-col>

                <v-col cols="auto">
                  <div class="d-inline-block">
                    <v-switch
                      v-model="viewData.OrderNotify.active"
                      :disabled="!userCanManage"
                      :label="viewData.OrderNotify.active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                      color="success"
                      class="ma-0 pa-0"
                      hide-details
                      inset
                    />
                  </div>
                </v-col>

                <v-col
                  v-if="viewData.OrderNotify.active"
                  cols="12"
                >
                  <v-container
                    fluid
                    class="px-0 pb-0"
                  >
                    <v-row
                      class="align-center pb-8 pb-sm-4 pb-md-1"
                      dense
                    >
                      <v-col cols="12">
                        <span
                          class="text-caption"
                          v-html="$t('Settings.Notification.Data.InternalOrder.Snooze.Label', {minutes: getNotificationMinutes(viewData.OrderNotify.snoozeForMins)})"
                        />
                      </v-col>

                      <v-col cols="auto">
                        <v-chip
                          class="blue lighten-1 white--text elevation-0 pl-0 mr-3"
                          style="min-width: 160px;"
                          label
                        >
                          <v-avatar
                            class="blue darken-1 mr-2"
                            style="width: 32px !important; height: 32px !important;"
                            tile
                          >
                            <v-icon color="blue lighten-4">
                              mdi-alarm-snooze
                            </v-icon>
                          </v-avatar>

                          <span>{{ $t('Settings.Notification.Data.InternalOrder.Snooze.Title') }}</span>
                        </v-chip>
                      </v-col>

                      <v-col cols="auto">
                        <v-text-field
                          v-model.number="viewData.OrderNotify.snoozeForMins"
                          :suffix="$t('Settings.Notification.Data.InternalOrder.Snooze.Suffix')"
                          :prepend-inner-icon="$t('Settings.Notification.Data.InternalOrder.Snooze.Icon')"
                          type="number"
                          step="1"
                          min="1"
                          max="120"
                          dense
                          outlined
                          single-line
                          hide-details
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      class="align-center pb-8 pb-sm-4 pb-md-1"
                      dense
                    >
                      <v-col cols="12">
                        <span
                          class="text-caption"
                          v-html="$t('Settings.Notification.Data.InternalOrder.Accept.Label', {minutes: getNotificationMinutes(viewData.OrderNotify.notAcceptedMinsAfterReceived)})"
                        />
                      </v-col>

                      <v-col cols="auto">
                        <v-chip
                          class="pl-0 mr-3 white--text"
                          style="min-width: 160px;"
                          label
                          :color="getOrderStatusById(OrderStatusEnum.Accepted).Avatar.Color"
                        >
                          <v-avatar
                            :color="`${getOrderStatusById(OrderStatusEnum.Accepted).Avatar.Color} darken-3`"
                            style="height: 32px !important; width: 32px !important;"
                            tile
                          >
                            <v-icon>
                              {{ getOrderStatusById(OrderStatusEnum.Accepted).Avatar.Icon }}
                            </v-icon>
                          </v-avatar>
                          <span class="ml-2">{{ getOrderStatusById(OrderStatusEnum.Accepted).Name }}</span>
                        </v-chip>
                      </v-col>

                      <v-col cols="auto">
                        <v-text-field
                          v-model.number="viewData.OrderNotify.notAcceptedMinsAfterReceived"
                          :suffix="$t('Settings.Notification.Data.InternalOrder.Accept.Suffix')"
                          :prepend-inner-icon="$t('Settings.Notification.Data.InternalOrder.Accept.Icon')"
                          type="number"
                          step="1"
                          min="1"
                          max="120"
                          dense
                          outlined
                          single-line
                          hide-details
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      class="align-center pb-8 pb-sm-4 pb-md-1"
                      dense
                    >
                      <v-col cols="12">
                        <span
                          class="text-caption"
                          v-html="$t('Settings.Notification.Data.InternalOrder.CompletedDelivery.Label', {minutes: getNotificationMinutes(orderTypeDefaultTimes.delivery_time - viewData.OrderNotify.notCompletedMinsBeforeDeliveryTime), minutesBefore: getNotificationMinutes(viewData.OrderNotify.notCompletedMinsBeforeDeliveryTime)})"
                        />
                      </v-col>

                      <v-col cols="auto">
                        <v-chip
                          class="pl-0 mr-3 white--text"
                          style="min-width: 160px;"
                          label
                          :color="getOrderStatusById(OrderStatusEnum.Completed).Avatar.Color"
                        >
                          <v-avatar
                            :color="`${getOrderStatusById(OrderStatusEnum.Completed).Avatar.Color} darken-3`"
                            style="height: 32px !important; width: 32px !important;"
                            tile
                          >
                            <v-icon>
                              {{ getOrderStatusById(OrderStatusEnum.Completed).Avatar.Icon }}
                            </v-icon>
                          </v-avatar>
                          <span class="ml-2">{{ getOrderStatusById(OrderStatusEnum.Completed).Name }}</span>
                        </v-chip>
                      </v-col>

                      <v-col cols="auto">
                        <v-chip
                          class="purple lighten-1 white--text elevation-0 pl-0 mr-3"
                          style="min-width: 70px;"
                          label
                        >
                          <v-avatar
                            class="purple darken-1 mr-2"
                            style="width: 32px !important; height: 32px !important;"
                            tile
                          >
                            <v-icon color="purple lighten-4">
                              directions_bike
                            </v-icon>
                          </v-avatar>

                          <span>{{ convertMinutesToDisplayString(orderTypeDefaultTimes.delivery_time) }}</span>
                        </v-chip>
                      </v-col>

                      <v-col cols="auto">
                        <v-text-field
                          v-model.number="viewData.OrderNotify.notCompletedMinsBeforeDeliveryTime"
                          :suffix="$t('Settings.Notification.Data.InternalOrder.CompletedDelivery.Suffix')"
                          :prepend-inner-icon="$t('Settings.Notification.Data.InternalOrder.CompletedDelivery.Icon')"
                          type="number"
                          step="1"
                          min="1"
                          :max="orderTypeDefaultTimes.delivery_time"
                          dense
                          outlined
                          single-line
                          hide-details
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      class="align-center pb-8 pb-sm-4 pb-md-1"
                      dense
                    >
                      <v-col cols="12">
                        <span
                          class="text-caption"
                          v-html="$t('Settings.Notification.Data.InternalOrder.CompletedCollection.Label', {minutes: getNotificationMinutes(orderTypeDefaultTimes.collection_time - viewData.OrderNotify.notCompletedMinsBeforeCollectionTime), minutesBefore: getNotificationMinutes(viewData.OrderNotify.notCompletedMinsBeforeCollectionTime)})"
                        />
                      </v-col>

                      <v-col cols="auto">
                        <v-chip
                          class="pl-0 mr-3 white--text"
                          style="min-width: 160px;"
                          label
                          :color="getOrderStatusById(OrderStatusEnum.Completed).Avatar.Color"
                        >
                          <v-avatar
                            :color="`${getOrderStatusById(OrderStatusEnum.Completed).Avatar.Color} darken-3`"
                            style="height: 32px !important; width: 32px !important;"
                            tile
                          >
                            <v-icon>
                              {{ getOrderStatusById(OrderStatusEnum.Completed).Avatar.Icon }}
                            </v-icon>
                          </v-avatar>
                          <span class="ml-2">{{ getOrderStatusById(OrderStatusEnum.Completed).Name }}</span>
                        </v-chip>
                      </v-col>

                      <v-col cols="auto">
                        <v-chip
                          class="light-blue lighten-1 white--text elevation-0 pl-0 mr-3"
                          style="min-width: 70px;"
                          label
                        >
                          <v-avatar
                            class="light-blue darken-1 mr-2"
                            style="width: 32px !important; height: 32px !important;"
                            tile
                          >
                            <v-icon color="light-blue lighten-4">
                              directions_run
                            </v-icon>
                          </v-avatar>

                          <span>{{ convertMinutesToDisplayString(orderTypeDefaultTimes.collection_time) }}</span>
                        </v-chip>
                      </v-col>

                      <v-col cols="auto">
                        <v-text-field
                          v-model.number="viewData.OrderNotify.notCompletedMinsBeforeCollectionTime"
                          :suffix="$t('Settings.Notification.Data.InternalOrder.CompletedCollection.Suffix')"
                          :prepend-inner-icon="$t('Settings.Notification.Data.InternalOrder.CompletedCollection.Icon')"
                          type="number"
                          step="1"
                          min="1"
                          :max="orderTypeDefaultTimes.collection_time"
                          dense
                          outlined
                          single-line
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>
              </v-row>

              <!-- Reservations Notifications -->
              <v-row dense>
                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Notification.Data.Reservation.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Notification.Data.Reservation.Subtitle') }}</span>
                </v-col>

                <v-col cols="12">
                  <v-container
                    fluid
                    class="px-0 pb-0"
                  >
                    <template v-for="(reservationStatus, reservationStatusIdx) in viewData.Reservation">
                      <v-row
                        :key="`reservation-status-${reservationStatus.id}-${reservationStatusIdx}`"
                        class="align-center pb-8 pb-sm-4 pb-md-1"
                        dense
                      >
                        <v-col cols="auto">
                          <v-chip
                            class="pl-0 mr-3 white--text"
                            style="min-width: 160px;"
                            label
                            :color="getReservationStatusById(reservationStatus.id).Avatar.Color"
                          >
                            <v-avatar
                              :color="`${getReservationStatusById(reservationStatus.id).Avatar.Color} darken-3`"
                              style="height: 32px !important; width: 32px !important;"
                              tile
                            >
                              <v-icon>
                                {{ getReservationStatusById(reservationStatus.id).Avatar.Icon }}
                              </v-icon>
                            </v-avatar>
                            <span class="ml-2">{{ getReservationStatusById(reservationStatus.id).Name }}</span>
                          </v-chip>
                        </v-col>

                        <v-col cols="auto">
                          <v-btn-toggle
                            v-model="viewData.Reservation[reservationStatusIdx].to"
                            :disabled="!userCanManage"
                            color="success"
                            class="mr-3"
                            dense
                            multiple
                            @change="onReservationChange(viewData.Reservation[reservationStatusIdx])"
                          >
                            <v-btn
                              :disabled="!userCanManage"
                              text
                              value="customer"
                            >
                              {{ $t('Settings.Notification.Data.Button.To.Customer').toLocaleUpperCase($i18n.locale) }}
                            </v-btn>

                            <v-btn
                              :disabled="!userCanManage"
                              text
                              value="location"
                            >
                              {{ $t('Settings.Notification.Data.Button.To.Store').toLocaleUpperCase($i18n.locale) }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <v-btn-toggle
                            v-model="viewData.Reservation[reservationStatusIdx].channel"
                            :disabled="!userCanManage"
                            color="success"
                            class="mr-3"
                            dense
                            multiple
                            @change="onReservationChange(viewData.Reservation[reservationStatusIdx])"
                          >
                            <v-btn
                              :disabled="!userCanManage"
                              text
                              value="email"
                            >
                              {{ $t('Settings.Notification.Data.Button.Channel.Email').toLocaleUpperCase($i18n.locale) }}
                            </v-btn>

                            <v-btn
                              :disabled="!userCanManage || !appSupportsSmsNotifications"
                              text
                              value="sms"
                            >
                              {{ $t('Settings.Notification.Data.Button.Channel.Sms').toLocaleUpperCase($i18n.locale) }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="auto">
                          <div class="d-inline-block">
                            <v-switch
                              v-model="viewData.Reservation[reservationStatusIdx].active"
                              :disabled="!userCanManage || isReservationActiveDisabled(viewData.Reservation[reservationStatusIdx])"
                              :label="reservationStatus.active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                              color="success"
                              class="ma-0 pa-0"
                              hide-details
                              inset
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-container>
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />
      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveWebsiteClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>

    <ticket-request-dialog
      :message="ticketMessage"
      :ticket-model="ticketModel"
      :visible.sync="requestDialogVisible"
    />
  </v-card>
</template>

<script>
import VuetifyAudio          from '@/components/common/sound/VuetifyAudio'
import AppData               from '@/mixins/appdata'
import Auth                  from '@/mixins/auth'
import ReservationStatus     from '@/mixins/reservation/reservationStatus'
import { clone }             from '@/lib/helper/helper'
import OrderStatusEnum       from '@/api/Enums/OrderStatusEnum'
import OrderTypeTime         from '@/mixins/orders/orderTypeTime'
import SourceType            from '@/mixins/orders/sourceType'
import OrderTypeEnum         from '@/api/Enums/OrderTypeEnum'
import OrderType             from '@/mixins/orders/orderType'
import SupportTicketAddModel from '@/api/Models/support-center/SupportTicketAddModel'
import TicketRequestDialog   from '@/components/support-center/TicketRequestDialog.vue'
import SupportDepartmentEnum from '@/api/Enums/SupportDepartmentEnum'
import SupportPriorityEnum   from '@/api/Enums/SupportPriorityEnum'

export default {
  components: {
    TicketRequestDialog,
    VuetifyAudio
  },
  mixins: [AppData, Auth, SourceType, ReservationStatus, OrderType, OrderTypeTime],
  data () {
    return {
      isSaving     : false,
      selectedSound: {
        id  : -1,
        name: '',
        url : ''
      },
      ticketMessage       : '',
      requestDialogVisible: false,
      ticketModel         : new SupportTicketAddModel(),
      viewData            : null
    }
  },
  computed: {
    OrderTypeEnum () {
      return OrderTypeEnum
    },
    OrderStatusEnum () {
      return OrderStatusEnum
    },

    orderStatuses () {
      return this.$t('Order.OrdersAll.Filter.filterOrderStatus.Items')
    },

    orderStatusesFiltered () {
      const defaultStatuses = this.$t('Order.OrdersAll.Filter.filterOrderStatus.Items').filter(o => [OrderStatusEnum.Accepted, OrderStatusEnum.Completed, OrderStatusEnum.Canceled].includes(o.Id))
      const allStatus = [this.$t('Order.OrdersAll.Filter.filterOrderStatus.ItemAll')]

      return [...allStatus, ...defaultStatuses]
    },

    orderTypesListFiltered () {
      return this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Items')
    },

    sounds () {
      const arr = []
      let fileId = 1
      const totalSounds = 22

      for (fileId = 1; fileId <= totalSounds; fileId++) {
        arr.push(
          {
            id  : fileId,
            name: `${ this.$t('Settings.Notification.Data.Sound.Field.Sound') } ${ fileId }`,
            url : `${ this.appConfig.LOCATION_DATA.CdnUrl }assets/sound/sound_${ fileId }.mp3`
          }
        )
      }

      return arr
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Notifications.Get, this.onGetNotificationsResult)
    this.$bus.$on(window.SocketCommand.Settings.Notifications.Save, this.onSaveNotificationsResult)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Notifications.Get, this.onGetNotificationsResult)
    this.$bus.$off(window.SocketCommand.Settings.Notifications.Save, this.onSaveNotificationsResult)
  },
  methods: {
    isReservationActiveDisabled (reservation) {
      return !reservation.to?.length || !reservation.channel?.length
    },
    onReservationChange (reservation) {
      if (!reservation.to?.length || !reservation.channel?.length) reservation.active = false
    },

    getOrderStatusById (orderStatusId) {
      return this.orderStatuses.find(o => o.Id === orderStatusId)
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.Notifications.Get, {})
    },

    onGetNotificationsResult (result) {
      const activeSound = this.sounds.find(s => s.url.includes(result.Sound))
      if (activeSound) this.selectedSound = activeSound

      result.Reservation.forEach(reservation => this.onReservationChange(reservation))

      if (!result.Notify) result.Notify = []

      this.viewData = result
    },

    getNotificationMinutes (minutes) {
      return minutes > 0 ? minutes : 'X'
    },

    addNotifySource () {
      const payload = {
        active: true,
        source: 0,
        status: 0,
        type  : 0,
        via   : {
          email: [],
          sms  : [],
          push : []
        }
      }

      this.viewData.Notify.push(payload)
    },

    removeNotifySource (index) {
      this.viewData.Notify.splice(index, 1)
    },

    onRequestDialogShow (type) {
      const CurrentPlanId = this.appConfig?.LOCATION_DATA?.PackageId

      const Subject = this.$t(`Settings.Notification.SupportRequest.${ type }.Subject`)
      const Message = this.$t(`Settings.Notification.SupportRequest.${ type }.Message`)
      const Department = SupportDepartmentEnum.SALES
      const Priority = SupportPriorityEnum.MEDIUM
      const Service = this.$t(`Account.Plan.Package.${ CurrentPlanId }.ServiceId`)

      this.ticketMessage = Message
      this.ticketModel = new SupportTicketAddModel({
        subject   : Subject,
        department: Department,
        priority  : Priority,
        service   : Service
      })

      this.requestDialogVisible = true
    },

    onSaveWebsiteClick () {
      this.isSaving = true
      const payload = clone(this.viewData)
      const s = this.selectedSound.url.split('/')
      if (Array.isArray(s) && s.length > 0) payload.Sound = s[s.length - 1]

      payload.Reservation.forEach(reservation => this.onReservationChange(reservation))

      window.callAS(window.SocketCommand.Settings.Notifications.Save, { Notification: payload })
    },

    onSaveNotificationsResult (result) {
      this.isSaving = false

      if (result.status) {
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Notification.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
