<template>
  <v-card flat>
    <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
      <v-icon class="pr-3">
        {{ $t('Settings.Notification.Data.Sound.Icon') }}
      </v-icon>

      <h5 class="text-body-2 font-weight-bold">
        {{ $t('Settings.Notification.Data.Sound.Title') }}
      </h5>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-select
        v-model="selectedSound"
        :disabled="disabled"
        :items="sounds"
        :label="$t('Settings.Notification.Data.Sound.Field.Label')"
        hide-details
        item-text="name"
        item-value="url"
        outlined
        return-object
        @change="reload()"
      >
        <div
          slot="append-outer"
          style="white-space: nowrap;"
        >
          <div style="position: relative; top: -8px">
            <v-btn
              class="mx-1"
              :disabled="!loaded"
              color="teal"
              icon
              outlined
              @click.native="playing ? pause() : play()"
            >
              <v-icon v-if="!playing || paused">
                play_arrow
              </v-icon>
              <v-icon v-else>
                pause
              </v-icon>
            </v-btn>

            <v-btn
              class="mx-1"
              :disabled="!loaded || !playing"
              color="teal"
              icon
              outlined
              @click.native="stop()"
            >
              <v-icon>stop</v-icon>
            </v-btn>

            <v-btn
              class="mx-1"
              :disabled="!loaded"
              color="teal"
              icon
              outlined
              @click.native="mute()"
            >
              <v-icon v-if="!isMuted">
                volume_up
              </v-icon>
              <v-icon v-else>
                volume_off
              </v-icon>
            </v-btn>

            <v-slider
              v-if="showProgressBar"
              v-model="percentage"
              :disabled="!loaded"
              dark
              @click.native="setPosition()"
            />

            <p v-if="showProgressTimer">
              {{ currentTime }} / {{ duration }}
            </p>
          </div>
        </div>
      </v-select>
    </v-card-text>

    <audio
      id="player"
      ref="player"
      :src="sound.url"
      @canplay="canPlay"
      @ended="ended"
    />
  </v-card>
</template>
<script>
import Appdata from '@/mixins/appdata'

const formatTime = second => new Date(second * 1000).toISOString().substr(11, 8)

export default {
  name  : 'VuetifyAudio',
  mixins: [Appdata],
  props : {
    disabled: {
      type   : Boolean,
      default: false
    },
    sounds: {
      type   : Array,
      default: () => []
    },
    sound: {
      type   : Object,
      default: null
    },
    autoPlay: {
      type   : Boolean,
      default: false
    },
    ended: {
      type   : Function,
      default: () => {
      }
    },
    canPlay: {
      type   : Function,
      default: () => {
      }
    },
    showProgressBar: {
      type   : Boolean,
      default: true
    },
    showProgressTimer: {
      type   : Boolean,
      default: true
    }
  },
  data () {
    return {
      firstPlay    : true,
      isMuted      : false,
      loaded       : false,
      playing      : false,
      paused       : false,
      percentage   : 0,
      currentTime  : '00:00:00',
      audio        : undefined,
      totalDuration: 0
    }
  },
  computed: {
    selectedSound: {
      get () {
        return this.sound
      },
      set (data) {
        this.$emit('update:sound', data)
      }
    },
    duration () {
      return this.audio ? formatTime(this.totalDuration) : ''
    }
  },
  watch: {},
  mounted () {
    this.audio = this.$refs.player
    this.init()
  },
  beforeDestroy () {
    this.audio.removeEventListener('timeupdate', this._handlePlayingUI)
    this.audio.removeEventListener('loadeddata', this._handleLoaded)
    this.audio.removeEventListener('pause', this._handlePlayPause)
    this.audio.removeEventListener('play', this._handlePlayPause)
    this.audio.removeEventListener('ended', this._handleEnded)
  },

  methods: {
    setPosition () {
      this.audio.currentTime = parseInt(this.audio.duration / 100 * this.percentage)
    },
    stop () {
      this.paused = this.playing = false
      this.audio.pause()
      this.audio.currentTime = 0
    },
    play () {
      if (this.playing) return
      this.paused = false
      // eslint-disable-next-line no-return-assign
      this.audio.play().then(_ => this.playing = true)
    },
    pause () {
      this.paused = !this.paused;
      (this.paused) ? this.audio.pause() : this.audio.play()
    },
    mute () {
      this.isMuted = !this.isMuted
      this.audio.muted = this.isMuted
      this.volumeValue = this.isMuted ? 0 : 75
    },
    reload () {
      this.stop()
      this.audio.load()
    },
    _handleLoaded () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {
            }
            this.audio.currentTime = 0
            this.totalDuration = parseInt(this.audio.duration)
            this.loaded = true
          }
        } else {
          this.totalDuration = parseInt(this.audio.duration)
          this.loaded = true
        }

        if (this.autoPlay) this.audio.play()
      } else {
        throw new Error('Failed to load sound file')
      }
    },
    _handlePlayingUI (e) {
      this.percentage = this.audio.currentTime / this.audio.duration * 100
      this.currentTime = formatTime(this.audio.currentTime)
    },
    _handlePlayPause (e) {
      if (e.type === 'play' && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0
        if (this.firstPlay) {
          this.firstPlay = false
        }
      }
      if (e.type === 'pause' && this.paused === false && this.playing === false) {
        this.currentTime = '00:00:00'
      }
    },
    _handleEnded () {
      this.paused = this.playing = false
    },
    init () {
      this.audio.addEventListener('timeupdate', this._handlePlayingUI)
      this.audio.addEventListener('loadeddata', this._handleLoaded)
      this.audio.addEventListener('pause', this._handlePlayPause)
      this.audio.addEventListener('play', this._handlePlayPause)
      this.audio.addEventListener('ended', this._handleEnded)
    }
  }

}
</script>
