<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Reservation.AddEditDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Reservation.AddEditDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!reservationToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Reservation.AddEditDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Reservation.AddEditDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Reservation.AddEditDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Reservation.Table.Headers.Code') }}: <span class="grey--text">{{ editReservation.ReserveCode }}</span>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="reservationForm"
            v-model="reservationFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-toolbar
                    class="rounded"
                    color="grey darken-3"
                    dark
                    dense
                    flat
                  >
                    <v-toolbar-title>
                      {{ editReservation.CustomerId ? $t('Reservation.AddEditDialog.Section.Customer.TitleCustomer') : $t('Reservation.AddEditDialog.Section.Customer.TitleGuest') }}
                    </v-toolbar-title>

                    <v-spacer />

                    <template v-if="posUserHasComponentPermission('Customers', 'ACCESS')">
                      <v-btn
                        :small="$vuetify.breakpoint.xsOnly"
                        color="green"
                        class=""
                        dark
                        depressed
                        @click="showCustomerSelectDialog"
                      >
                        <v-icon :left="$vuetify.breakpoint.smAndUp">
                          {{ editReservation.CustomerId ? 'mdi mdi-account-search' : 'mdi mdi-account-multiple-plus' }}
                        </v-icon>

                        <template v-if="$vuetify.breakpoint.smAndUp">
                          {{ editReservation.CustomerId ? $t('Reservation.AddEditDialog.Section.Customer.Button.Change').toLocaleUpperCase($i18n.locale) : $t('Reservation.AddEditDialog.Section.Customer.Button.Select').toLocaleUpperCase($i18n.locale) }}
                        </template>
                      </v-btn>

                      <v-btn
                        v-if="editReservation.CustomerId"
                        :small="$vuetify.breakpoint.xsOnly"
                        class="ml-1"
                        color="light-blue"
                        dark
                        depressed
                        @click="clearCustomer"
                      >
                        <v-icon :left="$vuetify.breakpoint.smAndUp">
                          mdi mdi-account-remove
                        </v-icon>

                        <template v-if="$vuetify.breakpoint.smAndUp">
                          {{ $t('Reservation.AddEditDialog.Section.Customer.Button.Guest').toLocaleUpperCase($i18n.locale) }}
                        </template>
                      </v-btn>
                    </template>
                  </v-toolbar>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editReservation.FirstName"
                    :counter="32"
                    :maxlength="32"
                    :hint="$t('Reservation.AddEditDialog.Field.FirstName.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.FirstName.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.FirstName.Icon')"
                    :rules="validationRules.reservationForm.FirstName"
                    class=""
                    required
                    type="text"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editReservation.LastName"
                    :counter="32"
                    :maxlength="32"
                    :hint="$t('Reservation.AddEditDialog.Field.LastName.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.LastName.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.LastName.Icon')"
                    :rules="validationRules.reservationForm.LastName"
                    class=""
                    required
                    type="text"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editReservation.Telephone"
                    :hint="$t('Reservation.AddEditDialog.Field.Telephone.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.Telephone.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Telephone.Icon')"
                    :rules="validationRules.reservationForm.Telephone"
                    class=""
                    required
                    type="telephone"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editReservation.Email"
                    :hint="$t('Reservation.AddEditDialog.Field.Email.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.Email.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Email.Icon')"
                    :rules="validationRules.reservationForm.Email"
                    class=""
                    required
                    type="email"
                  />
                </v-col>

                <v-col cols="12">
                  <v-toolbar
                    class="rounded"
                    color="grey darken-3"
                    dark
                    dense
                    flat
                  >
                    <v-toolbar-title>
                      {{ $t('Reservation.AddEditDialog.Section.Reservation.Title') }}
                    </v-toolbar-title>
                  </v-toolbar>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    v-model="reserveDateVisible"
                    :close-on-content-click="false"
                    left
                    max-width="290px"
                    min-width="140px"
                    offset-y
                    style="width: 100%;"
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="reserveDateFmt"
                        :label="$t('Reservation.AddEditDialog.Field.ReserveDate.Label')"
                        :hint="$t('Reservation.AddEditDialog.Field.ReserveDate.Hint')"
                        :prepend-icon="$t('Reservation.AddEditDialog.Field.ReserveDate.Icon')"
                        :rules="validationRules.reservationForm.ReserveDate"
                        :error-messages="errors.reserveDate"
                        clearable
                        readonly
                        @blur="onDateBlur"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="reserveDate"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      :allowed-dates="allowedDates"
                      :min="allowedValues.dateMin"
                      :max="allowedValues.dateMax"
                      first-day-of-week="1"
                      scrollable
                      @input="onDateInput"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    ref="menuStartTime"
                    v-model="reserveTimeVisible"
                    :close-on-content-click="false"
                    :return-value.sync="editReservation.ReserveTime"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <v-text-field
                        v-model="editReservation.ReserveTime"
                        :label="$t('Reservation.AddEditDialog.Field.ReserveTime.Label')"
                        :hint="$t('Reservation.AddEditDialog.Field.ReserveTime.Hint')"
                        :prepend-icon="$t('Reservation.AddEditDialog.Field.ReserveTime.Icon')"
                        :rules="validationRules.reservationForm.ReserveTime"
                        :error-messages="errors.reserveTime"
                        clearable
                        readonly
                        required
                        v-on="on"
                      />
                    </template>

                    <v-time-picker
                      v-if="reserveTimeVisible"
                      v-model="editReservation.ReserveTime"
                      :locale="$i18n.languages.find(lang => lang.locale === $i18n.locale).code || 'el-GR'"
                      :allowed-hours="allowedHours"
                      :allowed-minutes="allowedMinutes"
                      :min="allowedValues.timeMin"
                      :max="allowedValues.timeMax"
                      first-day-of-week="1"
                      format="24hr"
                      scrollable
                      @click:minute="$refs.menuStartTime.save(editReservation.ReserveTime)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="reserveTimeVisible = false"
                      >
                        {{ $t('Common.Button.Cancel') }}
                      </v-btn>

                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuStartTime.save(editReservation.ReserveTime)"
                      >
                        {{ $t('Common.Button.Ok') }}
                      </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    :hint="$t('Reservation.AddEditDialog.Field.Duration.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.Duration.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Duration.Icon')"
                    :rules="validationRules.reservationForm.Duration"
                    :value="convertMinutesToDisplayString(editReservation.Duration, false, true)"
                    readonly
                    required
                  >
                    <template #append>
                      <v-btn
                        :class="durationMinusDisabled ? 'grey lighten-1' : 'blue lighten-2'"
                        :disabled="durationMinusDisabled"
                        class="mr-1"
                        dark
                        icon
                        x-small
                        @click="onDurationMinus"
                      >
                        <v-icon>mdi mdi-minus</v-icon>
                      </v-btn>

                      <v-btn
                        :class="durationPlusDisabled ? 'grey lighten-1' : 'blue lighten-2'"
                        :disabled="durationPlusDisabled"
                        class=""
                        dark
                        icon
                        x-small
                        @click="onDurationPlus"
                      >
                        <v-icon>mdi mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="editReservation.Persons"
                    v-mask="'###'"
                    :hint="$t('Reservation.AddEditDialog.Field.Persons.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.Persons.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Persons.Icon')"
                    :rules="validationRules.reservationForm.Persons"
                    required
                  >
                    <template #append>
                      <v-btn
                        :class="personsMinusDisabled ? 'grey lighten-1' : 'blue lighten-2'"
                        :disabled="personsMinusDisabled"
                        class="mr-1"
                        dark
                        icon
                        x-small
                        @click="onPersonsMinus"
                      >
                        <v-icon>mdi mdi-minus</v-icon>
                      </v-btn>

                      <v-btn
                        :class="personsPlusDisabled ? 'grey lighten-1' : 'blue lighten-2'"
                        :disabled="personsPlusDisabled"
                        class=""
                        dark
                        icon
                        x-small
                        @click="onPersonsPlus"
                      >
                        <v-icon>mdi mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    v-model="editReservation.SourceType"
                    :disabled="sourceTypeDisabled"
                    :hint="$t('Reservation.AddEditDialog.Field.SourceType.Hint')"
                    :items="sourceTypes"
                    :label="$t('Reservation.AddEditDialog.Field.SourceType.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.SourceType.Icon')"
                    :rules="validationRules.reservationForm.SourceType"
                    item-text="Name"
                    item-value="Id"
                  >
                    <template #selection="{item}">
                      <v-chip
                        :color="item.Status ? 'green' : ''"
                        :dark="item.Status"
                        class="pl-0"
                        label
                        small
                      >
                        <v-avatar
                          :color="item.Avatar.Color"
                          class="mr-2"
                          tile
                        >
                          <v-icon
                            v-if="item.Avatar.Icon"
                            class="white--text"
                          >
                            {{ item.Avatar.Icon }}
                          </v-icon>

                          <img
                            v-else
                            :src="item.Avatar.Img"
                          >
                        </v-avatar>
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :color="data.item.Avatar.Color"
                        :size="28"
                      >
                        <v-icon
                          v-if="data.item.Avatar.Icon"
                          class="white--text"
                        >
                          {{ data.item.Avatar.Icon }}
                        </v-icon>

                        <img
                          v-else
                          :src="data.item.Avatar.Img"
                        >
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    v-model="editReservation.StatusId"
                    :hint="$t('Reservation.AddEditDialog.Field.Status.Hint')"
                    :items="reservationStatuses"
                    :label="$t('Reservation.AddEditDialog.Field.Status.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Status.Icon')"
                    :rules="validationRules.reservationForm.Status"
                    item-text="Name"
                    item-value="Id"
                  >
                    <template #selection="{item}">
                      <v-chip
                        class="pl-0"
                        label
                        small
                      >
                        <v-avatar
                          :color="item.Avatar.Color"
                          class="mr-2"
                          tile
                        >
                          <v-icon
                            dark
                            small
                          >
                            {{ item.Avatar.Icon }}
                          </v-icon>
                        </v-avatar>
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :color="data.item.Avatar.Color"
                        :size="26"
                      >
                        <v-icon
                          class="white--text"
                          dark
                          small
                        >
                          {{ data.item.Avatar.Icon }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  class="text-right"
                >
                  <v-switch
                    v-model="editReservation.Notify"
                    prepend-icon="email"
                    color="blue"
                    class="d-inline-flex"
                    hide-details
                    inset
                  >
                    <template #label>
                      <span class="">{{ $t('Reservation.AddEditDialog.Field.Notify.Label') }}</span>
                    </template>
                  </v-switch>
                </v-col>

                <v-col
                  v-if="tablesVisible"
                  cols="12"
                >
                  <v-autocomplete
                    v-model="editReservation.Tables"
                    :items="tables"
                    :search-input.sync="tableSearch"
                    :label="$t('Reservation.AddEditDialog.Field.Tables.Label')"
                    :hint="$t('Reservation.AddEditDialog.Field.Tables.Hint')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Tables.Icon')"
                    :rules="validationRules.reservationForm.Tables"
                    persistent-hint
                    chips
                    :counter="editReservation.Tables && !!editReservation.Tables.length"
                    deletable-chips
                    hide-selected
                    item-text="Name"
                    item-value="Id"
                    return-object
                    multiple
                    required
                    small-chips
                  >
                    <template #counter>
                      <v-chip
                        :class="!reservationHasInvalidTablePersons(editReservation.Persons, editReservation.Tables && editReservation.Tables.length > 1 ? editReservation.Tables : editReservation.Tables[0]) ? 'grey grey--text text--darken-2' : 'warning white--text'"
                        :outlined="!reservationHasInvalidTablePersons(editReservation.Persons, editReservation.Tables && editReservation.Tables.length > 1 ? editReservation.Tables : editReservation.Tables[0])"
                        x-small
                        label
                      >
                        {{ reservationTablesMinCapacity(editReservation.Tables) }} - {{ reservationTablesMaxCapacity(editReservation.Tables) }}
                      </v-chip>
                    </template>

                    <template #selection="data">
                      <v-chip
                        :input-value="data.selected"
                        :color="`#${data.item.Area.Color}`"
                        close-icon="close"
                        class=""
                        close
                        dark
                        label
                        small
                        v-bind="data.attrs"
                        @click="data.select"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <span class="mr-1">{{ data.item.Area.Name }}</span>
                        <span class="font-weight-bold text-subtitle-2 mr-2">#{{ data.item.Name }}</span>
                        <v-chip
                          x-small
                          label
                          class="text-caption px-1 mb-1"
                          :class="!reservationHasInvalidTablePersons(editReservation.Persons, editReservation.Tables && editReservation.Tables.length > 1 ? editReservation.Tables : data.item) ? 'grey--text text--lighten-3' : 'warning white--text'"
                          :outlined="!reservationHasInvalidTablePersons(editReservation.Persons, editReservation.Tables && editReservation.Tables.length > 1 ? editReservation.Tables : data.item)"
                        >
                          {{ data.item.MinCapacity }}-{{ data.item.MaxCapacity }}
                        </v-chip>
                      </v-chip>
                    </template>

                    <template #item="{item}">
                      <v-list-item-content style="border-bottom: 1px solid #e3e3e3;">
                        <v-list-item-title class="font-weight-bold">
                          <span class="mr-2">#{{ item.Name }}</span>
                          <v-chip
                            x-small
                            label
                            class="text-caption px-2 mb-1"
                            :disabled="item.disabled"
                            :class="!reservationHasInvalidTablePersons(editReservation.Persons, item) ? 'grey--text text--darken-1' : 'warning white--text'"
                            :outlined="!reservationHasInvalidTablePersons(editReservation.Persons, item)"
                          >
                            {{ item.MinCapacity }}-{{ item.MaxCapacity }}
                          </v-chip>
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-caption">
                          <v-chip
                            :disabled="item.disabled"
                            :color="`#${item.Area.Color}`"
                            class="white--text"
                            label
                            x-small
                          >
                            {{ item.Area.Name }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editReservation.Tags"
                    :hint="$t('Reservation.AddEditDialog.Field.Tags.Hint')"
                    :items="reservationsTags"
                    :label="$t('Reservation.AddEditDialog.Field.Tags.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Tags.Icon')"
                    :rules="validationRules.reservationForm.Tags"
                    persistent-hint
                    chips
                    deletable-chips
                    hide-selected
                    item-text="Name"
                    item-value="Id"
                    multiple
                    required
                    small-chips
                  >
                    <template #selection="data">
                      <v-chip
                        :input-value="data.selected"
                        class="pl-0"
                        close
                        close-icon="close"
                        label
                        small
                        v-bind="data.attrs"
                        @click="data.select"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          :color="data.item.Avatar.Color"
                          class="mr-2"
                          tile
                        >
                          <v-icon
                            dark
                            small
                          >
                            {{ data.item.Avatar.Icon }}
                          </v-icon>
                        </v-avatar>

                        <span>{{ data.item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :color="data.item.Avatar.Color"
                        :size="26"
                      >
                        <v-icon
                          dark
                          small
                        >
                          {{ data.item.Avatar.Icon }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="6">
                  <v-textarea
                    v-model="editReservation.Comment"
                    :hint="$t('Reservation.AddEditDialog.Field.Comment.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.Comment.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Comment.Icon')"
                    :readonly="sourceTypeDisabled"
                    :rows="2"
                    :rules="validationRules.reservationForm.Comment"
                    class=""
                    required
                  />
                </v-col>

                <v-col cols="6">
                  <v-textarea
                    v-model="editReservation.Notes"
                    :hint="$t('Reservation.AddEditDialog.Field.Notes.Hint')"
                    :label="$t('Reservation.AddEditDialog.Field.Notes.Label')"
                    :prepend-icon="$t('Reservation.AddEditDialog.Field.Notes.Icon')"
                    :rules="validationRules.reservationForm.Notes"
                    :rows="2"
                    required
                    class=""
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <customer-search-dialog
      v-if="posUserHasComponentPermission('Customers', 'ACCESS')"
      :customer-search-dialog-visible.sync="customerSearchDialogVisible"
      :offset="10"
      :search-filter.sync="customerSearch"
    />
  </div>
</template>

<script>
import AppData                              from '@/mixins/appdata'
import Auth                                 from '@/mixins/auth'
import ReservationStatusEnum                from '@/api/Enums/ReservationStatusEnum'
import ReservationCommon                    from '@/mixins/reservation/reservationCommon'
import ReservationStatus                    from '@/mixins/reservation/reservationStatus'
import ReservationTags                      from '@/mixins/reservation/reservationTags'
import OrderTypeTime                        from '@/mixins/orders/orderTypeTime'
import SourceType                           from '@/mixins/orders/sourceType'
import SourceTypeEnum                       from '@/api/Enums/SourceTypeEnum'
import CustomerSearchDialog                 from '@/components/customers/CustomerSearchDialog.vue'
import { types }                            from '@/lib/data/Validations/utils/Validators'
import { emailIsGuest, roundDateToNearest } from '@/lib/helper/helper'
import Watchable                            from '@/mixins/watchable'

export default {
  name      : 'ReservationAddDialog',
  components: { CustomerSearchDialog },
  mixins    : [AppData, Auth, OrderTypeTime, SourceType, ReservationCommon, ReservationStatus, ReservationTags, Watchable],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    reservationToEdit: {
      type   : Object,
      default: undefined
    },
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    customer: {
      type   : Object,
      default: undefined
    },
    callerId: {
      type   : String,
      default: undefined
    }
  },
  data () {
    return {
      loading                    : false,
      customerSearchDialogVisible: false,
      customerSearch             : '',
      tableSearch                : '',
      reservationFormValid       : false,
      reserveDateFmt             : '',
      reserveDate                : '',
      reserveDateVisible         : false,
      reserveTimeVisible         : false,
      validatedTables            : [],
      allowedValues              : this.defaultAllowedValues(),
      newReservation             : null,
      tmpReservation             : {
        Id           : null,
        Tables       : [],
        CustomerId   : null,
        FirstName    : '',
        LastName     : '',
        Email        : '',
        Telephone    : '',
        Comment      : '',
        ReserveCode  : '',
        ReserveDate  : '',
        ReserveTime  : '',
        ArrivalTime  : '',
        DepartureTime: '',
        Duration     : '',
        Persons      : 1,
        Notify       : true,
        Notes        : '',
        StatusId     : ReservationStatusEnum.Confirmed,
        SourceType   : SourceTypeEnum.VOIP,
        Tags         : []
      },
      errors: {
        reserveDate: [],
        reserveTime: []
      },
      validationRules: {
        reservationForm: {
          FirstName: [
            // (v) => !!v || this.$t('Reservation.AddEditDialog.Field.FirstName.Error.Required'),
            (v) => !v || (v.length >= 2 && v.length <= 32) || this.$t('Reservation.AddEditDialog.Field.FirstName.Error.Between')
          ],
          LastName: [
            // (v) => !!v || this.$t('Reservation.AddEditDialog.Field.LastName.Error.Required'),
            (v) => !v || (v.length >= 2 && v.length <= 32) || this.$t('Reservation.AddEditDialog.Field.LastName.Error.Between')
          ],
          Telephone: [
            // (v) => !!v || this.$t('Reservation.AddEditDialog.Field.Telephone.Error.Required'),
            (v) => !v || (v.length >= 8 && v.length <= 16) || this.$t('Reservation.AddEditDialog.Field.Telephone.Error.Between')
          ],
          Email: [
            // (v) => !v || !!v && (v.length >= 0 && v.length <= 96) || this.$t('Reservation.AddEditDialog.Field.Email.Error.Between'),
            (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Reservation.AddEditDialog.Field.Email.Error.Valid')
          ],
          ReserveDate: [
            (v) => !!v && types.dateStringSlash(v) || this.$t('Reservation.AddEditDialog.Field.ReserveDate.Error.Required')
          ],
          ReserveTime: [
            (v) => !!v && types.timeString(v) || this.$t('Reservation.AddEditDialog.Field.ReserveTime.Error.Required')
          ],
          Duration: [
            (v) => !!v || this.$t('Reservation.AddEditDialog.Field.Duration.Error.Required')
          ],
          Persons: [
            (v) => !!v && parseInt(v) > 0 || this.$t('Reservation.AddEditDialog.Field.Persons.Error.Required')
          ],
          SourceType: [
            (v) => !!v || this.$t('Reservation.AddEditDialog.Field.SourceType.Error.Required')
          ],
          Status: [
            (v) => !!v || this.$t('Reservation.AddEditDialog.Field.Status.Error.Required')
          ],
          Tables: [],
          Tags  : []
        }
      }
    }
  },
  computed: {
    tables () {
      const areas = this.$DataStore.tables || []
      const tables = []

      areas.forEach(area => {
        area.Tables.forEach(table => {
          const tbl = {
            Id            : table.Id,
            Name          : table.Name,
            Bookable      : table.Bookable,
            BookableOnline: table.BookableOnline,
            MinCapacity   : table.MinCapacity,
            MaxCapacity   : table.MaxCapacity,
            disabled      : this.isTableInvalid(table.Id),
            Area          : {
              Id   : area.Id,
              Name : area.Name,
              Color: area.Color
            }
          }
          if (this.isTableValid(table.Id)) tables.push(tbl)
        })
      })

      areas.forEach(area => {
        area.Tables.forEach(table => {
          const tbl = {
            Id            : table.Id,
            Name          : table.Name,
            Bookable      : table.Bookable,
            BookableOnline: table.BookableOnline,
            MinCapacity   : table.MinCapacity,
            MaxCapacity   : table.MaxCapacity,
            disabled      : this.isTableInvalid(table.Id),
            Area          : {
              Id   : area.Id,
              Name : area.Name,
              Color: area.Color
            }
          }
          if (this.isTableInvalid(table.Id)) tables.push(tbl)
        })
      })

      return tables
    },

    durationPlusDisabled () {
      return this.editReservation.Duration >= 720
    },
    durationMinusDisabled () {
      return this.editReservation.Duration <= 15
    },

    personsPlusDisabled () {
      return this.editReservation.Persons >= 999
    },
    personsMinusDisabled () {
      return this.editReservation.Persons <= 1
    },

    tablesVisible () {
      return this.editReservation.StatusId !== ReservationStatusEnum.WaitingList
    },

    sourceTypes () {
      const sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT) || []
      const editableSourceTypes = [SourceTypeEnum.VOIP, SourceTypeEnum.POS]
      const onlineSourceTypes = [SourceTypeEnum.MOBILE, SourceTypeEnum.ONLINE, SourceTypeEnum.ONLINE_BOOTH, SourceTypeEnum.ONLINE_ROOM, SourceTypeEnum.ONLINE_SUNBED, SourceTypeEnum.ONLINE_TABLE]
      const validSourceTypes = editableSourceTypes.includes(this.editReservation.SourceType) ? editableSourceTypes : onlineSourceTypes

      return sourceTypesDefault.filter(sourceType => sourceType.active && validSourceTypes.includes(sourceType.source_type_id)).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })
    },

    sourceTypeDisabled () {
      const editableSourceTypes = [SourceTypeEnum.VOIP, SourceTypeEnum.POS]

      return !editableSourceTypes.includes(this.editReservation.SourceType)
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editReservation: {
      get () {
        let payload = null

        if (this.reservationToEdit) {
          payload = JSON.parse(JSON.stringify(this.reservationToEdit))
        } else {
          payload = JSON.parse(JSON.stringify(this.tmpReservation))

          const useDurationByPeople = this.tableReservationSetting?.useDurationByPeople || false
          const defaultDuration = this.tableReservationSetting?.durationDefault || 120
          payload.Duration = useDurationByPeople ? this.getDurationByPersons(payload.Persons) : defaultDuration
        }

        this.newReservation = payload

        return this.newReservation
      }
    }
  },
  watch: {
    'editReservation.StatusId': function (newVal) {
      if (newVal === ReservationStatusEnum.WaitingList) {
        this.editReservation.Tables = []
      }
    },

    visible (newVal) {
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.reservationForm) this.$refs.reservationForm.resetValidation()
          this.initCustomer()
          this.initTable()
          this.initDate()
          this.validateReservation()
        })
      } else {
        this.removeWatchers()
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Table.Reservation.Save, this.onSaveResult)
    this.$bus.$on(window.SocketCommand.Table.Reservation.Validate, this.onValidateReservationResult)
    this.$bus.$on(window.SocketCommand.Customer.Get + '-voip', this.setCustomer)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Table.Reservation.Save, this.onSaveResult)
    this.$bus.$off(window.SocketCommand.Table.Reservation.Validate, this.onValidateReservationResult)
    this.$bus.$off(window.SocketCommand.Customer.Get + '-voip', this.setCustomer)
  },
  methods: {
    initDate (setDateTimeNow = !this.reservationToEdit) {
      if (setDateTimeNow) {
        const minTimeInAdvance = parseInt(this.tableReservationSetting?.minTimeInAdvance) || 120
        const allowedMinutesStep = parseInt(this.tableReservationSetting?.allowedMinutesStep) || 15
        const now = roundDateToNearest(allowedMinutesStep, new Date())
        now.setTime(now.getTime() + ((minTimeInAdvance + allowedMinutesStep) * 60 * 1000))

        const year = now.getFullYear()
        const month = now.getMonth() + 1
        const day = now.getDate()

        const hour = now.getHours()
        const min = now.getMinutes()

        this.editReservation.ReserveDate = `${ String(day).padStart(2, '0') }/${ String(month).padStart(2, '0') }/${ year }`
        this.editReservation.ReserveTime = `${ String(hour).padStart(2, '0') }:${ String(min).padStart(2, '0') }`
      }

      const dateStr = this.editReservation.ReserveDate
      this.reserveDateFmt = dateStr ? this.formatServerDate(dateStr, '/') ?? '' : ''
      this.ReserveDate = dateStr ? this.parseDate(dateStr, '/') : ''
    },

    initCustomer () {
      if (!this.customer && this.callerId) this.showCustomerSelectDialog()

      if (this.customer) {
        const customer = {
          Info: {
            Id       : this.customer?.Id || this.customer?.Info?.Id || null,
            Firstname: this.customer?.Firstname || this.customer?.Info?.Firstname || '',
            Lastname : this.customer?.Lastname || this.customer?.Info?.Lastname || '',
            Telephone: this.callerId || this.customer?.Telephone || this.customer?.Info?.Telephone || '',
            Email    : this.customer?.Email || this.customer?.Info?.Email || ''
          }
        }
        this.setCustomer(customer)
      }
    },

    initTable () {
      if (!this.table || !this.area) return

      const tbl = {
        Id         : this.table.Id,
        Name       : this.table.Name,
        MinCapacity: this.table.MinCapacity,
        MaxCapacity: this.table.MaxCapacity,
        Area       : {
          Id   : this.area.Id,
          Name : this.area.Name,
          Color: this.area.Color
        }
      }

      this.editReservation.Tables = [tbl]
    },

    isTableInvalid (tableId) {
      const validTables = this.validatedTables || []
      return !validTables.includes(parseInt(tableId))
    },

    isTableValid (tableId) {
      const validTables = this.validatedTables || []
      return validTables.includes(parseInt(tableId))
    },

    onDateBlur () {
      this.ReserveDate = this.parseDate(this.reserveDateFmt)
    },

    onDateInput (event) {
      this.reserveDateFmt = this.formatDate(event)
      this.editReservation.ReserveDate = this.formatDate(event, '-', '/')
      this.reserveDateVisible = false
    },

    formatServerDate (date, separatorFrom = '/', separatorTo = '/') {
      if (!date) return null

      const [day, month, year] = date.split(separatorFrom)
      return `${ day }${ separatorTo }${ month }${ separatorTo }${ year }`
    },

    formatDate (date, separatorFrom = '-', separatorTo = '/') {
      if (!date) return null

      const [year, month, day] = date.split(separatorFrom)
      return `${ day }${ separatorTo }${ month }${ separatorTo }${ year }`
    },

    parseDate (date, separatorFrom = '/', separatorTo = '-') {
      if (!date) return null

      const [day, month, year] = date.split(separatorFrom)
      return `${ year }${ separatorTo }${ String(month).padStart(2, '0') }${ separatorTo }${ String(day).padStart(2, '0') }`
    },

    allowedHours (val) {
      const allowed = this.allowedValues.allowedHours.length ? this.allowedValues.allowedHours.includes(val) : true
      const excluded = this.allowedValues.excludedHours.length ? !this.allowedValues.excludedHours.includes(val) : true

      return allowed && excluded
    },

    allowedMinutes (val) {
      const allowed = this.allowedValues.allowedMinutes.length ? this.allowedValues.allowedMinutes.includes(val) : true
      const excluded = this.allowedValues.excludedMinutes.length ? !this.allowedValues.excludedMinutes.includes(val) : true

      return allowed && excluded
    },

    allowedDates (val) {
      const allowed = this.allowedValues.allowedDates.length ? this.allowedValues.allowedDates.includes(val) : true
      const excluded = this.allowedValues.excludedDates.length ? !this.allowedValues.excludedDates.includes(val) : true

      return allowed && excluded
    },

    onDurationPlus () {
      if (this.editReservation.Duration >= 720) return

      this.editReservation.Duration += 15
    },

    onDurationMinus () {
      if (this.editReservation.Duration <= 15) return

      this.editReservation.Duration -= 15
    },

    onPersonsPlus () {
      if (this.editReservation.Persons >= 999) return

      this.editReservation.Persons++
    },

    onPersonsMinus () {
      if (this.editReservation.Persons <= 1) return

      this.editReservation.Persons--
    },

    setCustomer (data) {
      if (!data?.Info) return

      this.editReservation.CustomerId = data.Info.Id
      this.editReservation.FirstName = data.Info.Firstname
      this.editReservation.LastName = data.Info.Lastname
      this.editReservation.Telephone = data.Info.Telephone
      this.editReservation.Email = emailIsGuest(data.Info.Email) ? '' : data.Info.Email
    },

    clearCustomer () {
      this.editReservation.CustomerId = null
      this.editReservation.FirstName = ''
      this.editReservation.LastName = ''
      this.editReservation.Telephone = ''
      this.editReservation.Email = ''
    },

    showCustomerSelectDialog () {
      if (!this.customer && this.callerId) this.customerSearch = this.callerId

      this.customerSearchDialogVisible = true
    },

    addWatchers () {
      this.removeWatchers()
      this.watch('editReservation.ReserveDate', () => this.validateReservation())
      this.watch('editReservation.ReserveTime', () => this.validateReservation())
      this.watch('editReservation.Duration', () => this.validateReservation())
      this.watch('editReservation.Persons', () => this.onPersonsChange())
    },
    removeWatchers () {
      this.unwatch('editReservation.ReserveDate')
      this.unwatch('editReservation.ReserveTime')
      this.unwatch('editReservation.Duration')
      this.unwatch('editReservation.Persons')
    },

    defaultAllowedValues () {
      return {
        allowedDates   : [],
        allowedHours   : [],
        allowedMinutes : [],
        excludedDates  : [],
        excludedHours  : [],
        excludedMinutes: [],
        dateMin        : '',
        dateMax        : '',
        timeMin        : '',
        timeMax        : ''
      }
    },

    onPersonsChange () {
      const useDurationByPeople = this.tableReservationSetting?.useDurationByPeople || false
      if (useDurationByPeople) {
        const duration = this.getDurationByPersons(this.editReservation.Persons)
        if (duration !== this.editReservation.Duration) this.editReservation.Duration = duration
      }

      this.validateReservation()
    },

    validateReservation () {
      this.removeWatchers()

      this.loading = true

      this.$nextTick(() => {
        const payload = {
          Id         : this.editReservation.Id,
          ReserveDate: this.editReservation.ReserveDate,
          ReserveTime: this.editReservation.ReserveTime,
          Duration   : this.editReservation.Duration,
          Persons    : this.editReservation.Persons,
          Tables     : this.editReservation.Tables.map(table => parseInt(table.Id))
        }

        if (this.editReservation.ReserveDate && this.editReservation.ReserveTime) {
          window.callAS(window.SocketCommand.Table.Reservation.Validate, payload)
        } else {
          this.loading = false
        }
      })

      this.addWatchers()
    },

    onValidateReservationResult (data) {
      this.removeWatchers()

      this.loading = false
      if (data && data.status === 'success') {
        const tables = data?.tables || []
        const validation = data?.validation || this.defaultAllowedValues()
        const reserveDate = data?.reserveDate || ''
        const reserveTime = data?.reserveTime || ''
        // const selectedTables = data?.selectedTables || []
        // const removedTables = data?.removedTables || []

        this.validatedTables = tables
        this.allowedValues = validation
        // this.editReservation.Tables = this.editReservation.Tables.filter(table => selectedTables.includes(table.Id))

        this.errors.reserveDate = []
        this.errors.reserveTime = []

        if (reserveDate) {
          this.editReservation.ReserveDate = reserveDate
          this.initDate(false)
        } else {
          this.errors.reserveDate = [this.$t('Reservation.AddEditDialog.Field.ReserveDate.Error.Invalid')]
        }

        if (reserveTime) {
          this.editReservation.ReserveTime = reserveTime
        } else {
          this.errors.reserveTime = [this.$t('Reservation.AddEditDialog.Field.ReserveTime.Error.Invalid')]
        }
      }

      this.addWatchers()
    },

    onSaveButtonClick () {
      this.$refs.reservationForm.validate()

      if (this.reservationFormValid) {
        this.loading = true
        const payload = JSON.parse(JSON.stringify(this.editReservation))
        payload.Tables = payload.Tables.map(table => parseInt(table.Id))

        window.callAS(window.SocketCommand.Table.Reservation.Save, payload)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()

        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Reservation.AddEditDialog.Notification.Error'),
          type: 'error',
          icon: 'warning'
        })
      }

      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.reservationForm) this.$refs.reservationForm.resetValidation()
      this.isVisible = false
      this.loading = false
      this.tableSearch = ''
      this.customerSearch = ''
      this.reserveDateFmt = ''
      this.newReservation = null
      this.errors = {
        reserveDate: [],
        reserveTime: []
      }
    }
  }
}
</script>

<style scoped>

</style>
