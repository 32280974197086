export default {
  Title : 'Σερβιτόροι | Σερβιτόρος | Σερβιτόροι',
  Button: {
    WaiterStaff: {
      Add: {
        Title: 'Προσθήκη Σερβιτόρου',
        Color: 'primary',
        Icon : 'add_box'
      },
      Edit: {
        Color: 'grey lighten-0',
        Icon : 'edit'
      },
      Delete: {
        PopUp: {
          Title  : 'Διαγραφή Σερβιτόρου;',
          Content: 'Είστε σίγουροι ότι θέλετε να διαγράψετε οριστικά το σερβιτόρο;'
        }
      }
    },
    RemoveOrder: {
      PopUp: {
        Title  : 'Αφαίρεση Παραγγελίας;',
        Content: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε την παραγγελία από τη βάρδια του σερβιτόρο;'
      }
    },
    Shift: {
      Open: {
        Title: 'Άνοιγμα βάρδιας',
        Color: 'green',
        Icon : 'fas fa-plus-circle',
        PopUp: {
          Title : 'Ποσό Έναρξης Βάρδιας',
          Hint  : 'Το ποσό με το οποίο ξεκινάει η βάρδια.',
          Button: 'Άνοιγμα'
        }
      },
      Close: {
        Title: 'Κλείσιμο βάρδιας',
        Color: 'red',
        Icon : 'fas fa-times-circle',
        PopUp: {
          Title              : 'Κλείσιμο Βάρδιας;',
          Content            : 'Είστε σίγουροι ότι θέλετε να κλείσετε τη βάρδια;',
          ContentChangeWaiter: 'Ο σερβιτόρος έχει ανατεθειμένα ανοιχτά τραπέζια! Θα πρέπει να κλείσετε τα τραπέζια του ή να τα μεταφέρετε σε άλλο σερβιτόρο για να να κλείσετε τη βάρδια του.'
        }
      }
    }
  },

  ListView: {
    NoData: {
      Title   : 'Δημιουργήστε Σερβιτόρο',
      Subtitle: 'Προσθέστε νέο σερβιτόρο',
      Icon    : 'account_circle'
    },
    SelectWaiterStaff: {
      Title   : 'Επιλέξτε Σερβιτόρο',
      Subtitle: 'Επιλέξτε σερβιτόρο από την λίστα',
      Icon    : 'room_service'
    },
    Table: {
      Headers: {
        DateStarted: 'Ημ/νία',
        Swifts     : 'Βάρδιες',
        Payments   : 'Πληρωμές',
        Total      : 'Σύνολο',
        Actions    : ''
      }
    }
  },

  Common: {
    Orders      : 'Παραγγελίες:',
    ShiftsOrders: 'Σύνολο βάρδιας: ',
    ShiftStart  : 'Έναρξη βάρδιας',
    ShiftTotal  : 'Σύνολο βάρδιας',
    InfoLabels  : {
      Icon     : 'account_circle',
      Telephone: 'Τηλ.:',
      Email    : 'Όνομα χρήστη:'
    }
  },

  WaiterStaffInfo: {
    Tab: {
      Details: '@:Common.Misc.Details',
      History: 'Ιστορικό'
    },
    Button: {
      AddOrder: {
        Title: 'Προσθηκη Παραγγελιας',
        Color: 'light-green'
      },
      ViewDailyShift: {
        Title: 'Προβολή βαρδιών ημέρας',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      }
    },
    Result: {
      NoOrders     : 'Δεν υπάρχουν ακόμη πληρωμές στη βάρδια',
      InactiveShift: {
        Icon        : 'account_circle',
        Title       : 'Μη Ενεργή Βάρδια',
        SubtitleLink: 'Ανοίξτε',
        SubtitleText: 'τη βάρδια σερβιτόρου'
      }
    }
  },

  PrintDeliveryShift: {
    PrintReceipt: {
      StartShift  : 'Έναρξη Βάρδιας',
      Delivery    : 'Παράδοση',
      Collection  : 'Παραλαβή',
      Canceled    : 'Ακυρωμένη',
      Cash        : 'Μετρητά',
      Card        : 'Κάρτα',
      WithoutStart: 'Χωρίς Έναρξη:',
      NotIncluded : 'Δεν Συμπεριλαμβάνονται',
      ByCard      : 'με κάρτα '
    },
    Notification: 'Η βάρδια στάλθηκε προς εκτύπωση!'
  },

  WaiterStaffAddDialog: {
    Toolbar: {
      Color: 'indigo',
      Icon : 'room_service',
      Add  : {
        Title   : 'Προσθήκη Σερβιτόρου',
        Subtitle: 'Δημιουργία Νέου Σερβιτόρου'
      },
      Edit: {
        Title   : 'Επεξεργασία Σερβιτόρου',
        Subtitle: 'Τροποποίηση Στοιχείων Σερβιτόρου'
      }
    },
    Field: {
      Firstname: {
        Label: 'Όνομα',
        Hint : 'Όνομα σερβιτόρου. πχ. Βαγγέλης, Μαρία, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Lastname: {
        Label: 'Επίθετο',
        Hint : 'Επίθετο σερβιτόρου. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Telephone: {
        Label: 'Τηλέφωνο',
        Hint : 'Κινητό ή σταθερό τηλέφωνο σερβιτόρου. πχ. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },
      Email: {
        Label: 'Όνομα χρήστη',
        Hint : 'Όνομα χρήστη',
        Icon : 'email',
        Error: {
          Between: 'Το Όνομα χρήστη πρέπει να είναι έως 96 χαρακτήρες',
          Valid  : 'Το Όνομα χρήστη πρέπει να είναι έγκυρο'
        }
      },
      Notes: {
        Label: 'Σημειώσεις',
        Hint : 'Επιπλέον στοιχεία για τον σερβιτόρο',
        Icon : 'speaker_notes'
      }
    }
  },

  WaiterStaffDayShiftsDialog: {
    Header: {
      Icon : 'room_service',
      Title: 'Βάρδιες Ημέρας Σερβιτόρου',
      Shift: 'Βάρδια'
    },
    Result: {
      NoOrders: {
        Icon    : 'shopping_cart',
        Color   : 'grey lighten-2',
        Title   : 'Δεν Υπάρχουν Πληρωμές',
        Subtitle: 'Δεν υπάρχουν πληρωμές στη βάρδια σερβιτόρου'
      }
    }
  },

  SelectWaiterStaffDialog: {
    Toolbar: {
      Icon    : 'room_service',
      Subtitle: 'Επιλογή Σερβιτόρου'
    },
    Result: {
      NoWaiterStaff: {
        Title   : 'Δεν Υπάρχουν Σερβιτόροι',
        Subtitle: {
          Please     : 'Παρακαλώ',
          Open       : 'ανοίξτε',
          ShiftOr    : 'βάρδια ή',
          Create     : 'δημιουργήστε',
          WaiterStaff: 'σερβιτόρο'
        }
      }
    }
  },

  PaymentChangeWaiterDialog: {
    Toolbar: {
      Icon    : 'transfer_within_a_station',
      Title   : 'Μεταφορά Πληρωμής',
      Subtitle: 'Μεταφορά πληρωμής σε βάρδια άλλου σερβιτόρου.'
    }
  },

  StaffAddDialog: {
    Add: {
      Title   : 'Προσθήκη Σερβιτόρου',
      Subtitle: 'Δημιουργία Νέου Σερβιτόρου',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },
    Edit: {
      Title   : 'Επεξεργασία Σερβιτόρου',
      Subtitle: 'Τροποποίηση Στοιχείων Σερβιτόρου',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },

    Field: {
      Firstname: {
        Label: 'Όνομα',
        Hint : 'Όνομα σερβιτόρου. πχ. Βαγγέλης, Μαρία, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Lastname: {
        Label: 'Επίθετο',
        Hint : 'Επίθετο σερβιτόρου. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Telephone: {
        Label: 'Τηλέφωνο',
        Hint : 'Κινητό ή σταθερό τηλέφωνο σερβιτόρου. πχ. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },
      Email: {
        Label: 'Όνομα Χρήστη',
        Hint : 'Το Όνομα Χρήστη θα συμπληρωθεί αυτόματα',
        Icon : 'email',
        Error: {
          Between: 'Το Όνομα Χρήστη πρέπει να είναι έως 96 χαρακτήρες',
          Valid  : 'Το Όνομα Χρήστη πρέπει να είναι έγκυρο'
        }
      },
      Pin: {
        Label: 'PIN',
        Hint : 'Το PIN του σερβιτόρου πχ. 1234',
        Icon : 'fiber_pin',
        Error: {
          FixedNumber: 'Το PIN πρέπει να είναι 4 αριθμοί'
        }
      },
      TableAreas: {
        Label: 'Περιοχές Τραπεζιών',
        Hint : 'Επιλέξτε μια ή περισσότερες περιοχές τραπεζιών',
        Icon : 'crop_square',
        All  : 'Όλες',
        Error: {
          Required: 'Πρέπει να επιλέξετε τουλάχιστον μια περιοχή τραπεζιών.'
        }
      },
      Locale: {
        Label: 'Γλώσσα Συστήματος',
        Hint : 'Επιλέξτε τη γλώσσα που θέλετε να εμφανίζεται το σύστημα',
        Icon : 'language'
      },
      Status: {
        Title: 'Κατάσταση'
      }
    }
  }
}
