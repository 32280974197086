<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="sourceType >= 100 ? 'white' : 'red darken-1'"
            size="46"
          >
            <template v-if="logo">
              <img
                v-if="sourceType >= 100"
                :src="logo"
              >
              <v-icon
                v-else
                color="white"
                size="32"
              >
                {{ logo }}
              </v-icon>
            </template>
            <v-icon
              v-else
              color="white"
              size="32"
            >
              check_circle
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Pos.SelectPlatformCatalogDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Pos.SelectPlatformCatalogDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <template v-for="(catalog, idx) in catalogs">
                <v-col
                  :key="idx"
                  cols="12"
                >
                  <v-list class="pa-0">
                    <v-list-item
                      class="grey lighten-3"
                      ripple
                      @click="onCatalogSelected(catalog)"
                    >
                      <v-list-item-avatar
                        :color="sourceType >= 100 ? 'white' : 'red darken-1'"
                        size="32"
                      >
                        <template v-if="logo">
                          <img
                            v-if="sourceType >= 100"
                            :src="logo"
                          >
                          <v-icon
                            v-else
                            color="white"
                            size="20"
                          >
                            {{ logo }}
                          </v-icon>
                        </template>
                        <v-icon
                          v-else
                          color="white"
                          size="32"
                        >
                          check_circle
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="grey--text text--darken-2">
                        <v-list-item-title class="text-left">
                          {{ catalog.Name }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="catalog.Description">
                          <span class="grey--text">{{ catalog.Description }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </v-list>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData    from '@/mixins/appdata'
import Auth       from '@/mixins/auth'
import SourceType from '@/mixins/orders/sourceType'

export default {
  name      : 'SelectPlatformCatalogDialog',
  components: {},
  mixins    : [AppData, Auth, SourceType],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    catalogs: {
      type   : Array,
      default: () => []
    },
    sourceType: {
      type    : Number,
      default : undefined,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    logo () {
      return this.getOrderSourceTypeById(this.sourceType)?.logo || null
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onCatalogSelected (catalog) {
      this.isVisible = false
      this.$emit('catalog:selected', catalog)
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>
