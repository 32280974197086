<template>
  <v-card>
    <v-toolbar
      class="datatable-object settings-window-toolbar"
      flat
      height="80"
    >
      <v-avatar
        :color="$t('Settings.OnlineUsers.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.OnlineUsers.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $tc('Settings.OnlineUsers.Title', 0) }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.OnlineUsers.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <template #extension>
        <v-tabs
          v-model="tab"
          background-color="primary"
          dark
        >
          <v-tab
            key="tab-production"
            href="#tab-production"
          >
            PRODUCTION
          </v-tab>

          <v-tab
            key="tab-dev"
            href="#tab-dev"
          >
            DEVELOPMENT
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      class="pa-1"
    >
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar
            class="filter-toolbar"
            flat
          >
            <template #default>
              <h4 class="grey--text text--darken-2 mr-6">
                Domains: {{ domainsCount }}
              </h4>

              <h4 class="grey--text text--darken-2">
                Users: {{ usersCount }}
              </h4>

              <v-spacer />

              <filter-search v-model="dataTable.search.terms" />

              <v-btn
                :height="$vuetify.breakpoint.xsOnly ? 36 : 48"
                :width="$vuetify.breakpoint.xsOnly ? 36 : 48"
                class="pa-0 mr-2 elevation-0"
                :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
                min-height="36"
                min-width="36"
                @click="refreshData"
              >
                <v-icon
                  size="24"
                  color="primary"
                  v-text="'mdi-table-refresh'"
                />
              </v-btn>

              <v-btn
                v-if="posUserCan('SystemSettingsDomains', 'MANAGE')"
                :height="$vuetify.breakpoint.xsOnly ? 36 : 48"
                :width="$vuetify.breakpoint.xsOnly ? 36 : 48"
                class="red pa-0 mr-0"
                color="white"
                min-height="36"
                min-width="36"
                text
                @click="logoutSystemUsers"
              >
                <v-icon size="24">
                  mdi-account-off-outline
                </v-icon>
              </v-btn>
            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <v-col cols="12">
                <v-data-table
                  :footer-props="dataTable.footerProps"
                  :headers="dataTableHeaders"
                  :height="dataTable.resize.bodyHeight"
                  :items="dataTable.data"
                  :loading="dataTable.loading"
                  :loading-text="dataTable.text.loadingText"
                  :no-data-text="dataTable.text.noDataText"
                  :no-results-text="dataTable.text.noResultsText"
                  :options.sync="dataTable.options"
                  :search="dataTable.search.terms"
                  :group-by.sync="dataTable.options.groupBy"
                  :group-desc.sync="dataTable.options.groupDesc"
                  fixed-header
                  item-key="guid"
                  dense
                >
                  <template #group.header="{items, isOpen, toggle}">
                    <th
                      colspan="10"
                      style="cursor: pointer;"
                      @click="toggle"
                    >
                      <div class="d-flex">
                        <div class="d-inline-flex justify-center align-center">
                          <v-icon class="mr-2">
                            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                          </v-icon>

                          <span class="font-weight-bold blue--text d-inline-block">
                            {{ items[0].domain }}
                          </span>
                        </div>

                        <v-spacer />

                        <v-badge
                          color="primary"
                          class="mt-1 mr-1"
                          inline
                          dark
                        >
                          <template #badge>
                            {{ domainUsersCount(items[0].domain) }}
                          </template>
                        </v-badge>

                        <v-tooltip
                          color="primary"
                          open-delay="750"
                          top
                        >
                          <template #activator="{on}">
                            <v-btn
                              :height="26"
                              :width="26"
                              class="primary pa-0 mr-1 d-inline-flex"
                              color="white"
                              min-height="26"
                              min-width="26"
                              text
                              target="_blank"
                              :href="`https://${items[0].domain}`"
                              v-on="on"
                            >
                              <v-icon size="18">
                                open_in_new
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>Open Website</span>
                        </v-tooltip>

                        <template v-if="posUserCan('SystemSettingsDomains', 'MANAGE')">
                          <template v-if="posUser.Domain !== items[0].domain">
                            <v-tooltip
                              color="blue-grey"
                              open-delay="750"
                              top
                            >
                              <template #activator="{on}">
                                <v-btn
                                  :height="26"
                                  :width="26"
                                  class="blue-grey pa-0 mr-2 d-inline-flex"
                                  color="white"
                                  min-height="26"
                                  min-width="26"
                                  text
                                  v-on="on"
                                  @click.stop="changeStaffDomain(items[0].domain)"
                                >
                                  <v-icon size="18">
                                    mdi-web-refresh
                                  </v-icon>
                                </v-btn>
                              </template>

                              <span>Login to Domain</span>
                            </v-tooltip>
                          </template>

                          <v-tooltip
                            color="red"
                            open-delay="750"
                            top
                          >
                            <template #activator="{on}">
                              <v-btn
                                :height="26"
                                :width="26"
                                class="red pa-0 mr-0 d-inline-flex"
                                color="white"
                                min-height="26"
                                min-width="26"
                                text
                                v-on="on"
                                @click.stop="logoutDomainUsers(items[0].domain)"
                              >
                                <v-icon size="18">
                                  mdi-account-off-outline
                                </v-icon>
                              </v-btn>
                            </template>

                            <span>Logout Domain Users</span>
                          </v-tooltip>
                        </template>
                      </div>
                    </th>
                  </template>

                  <template #[`item.id`]="{item}">
                    #{{ item.id }}
                  </template>

                  <template #[`item.group`]="{item}">
                    <v-chip
                      label
                      class="pl-0 pr-2"
                      style="min-width: 120px; height: 18px;"
                      x-small
                    >
                      <v-avatar
                        :color="staffGroup(item.group).Color"
                        size="18"
                        tile
                        class="rounded mr-2"
                      >
                        <v-icon
                          size="14"
                          color="white"
                        >
                          {{ staffGroup(item.group).Icon }}
                        </v-icon>
                      </v-avatar>

                      {{ staffGroup(item.group).Name }}
                    </v-chip>
                  </template>

                  <template #[`item.guest`]="{item}">
                    <v-avatar
                      v-if="item.guest"
                      color="orange"
                      tile
                      class="rounded"
                      size="18"
                    >
                      <v-icon
                        size="14"
                        color="white"
                      >
                        mdi-account-lock
                      </v-icon>
                    </v-avatar>
                  </template>

                  <template #[`item.single_login`]="{item}">
                    <v-icon
                      v-if="item.single"
                      dense
                      color="purple"
                    >
                      mdi-check-circle
                    </v-icon>
                  </template>

                  <template #[`item.duration`]="{item}">
                    {{ item.connection.duration }}
                  </template>

                  <template #[`item.connected_at`]="{item}">
                    {{ formatDate(item.connection.connectedAt) }}<br>{{ formatTime(item.connection.connectedAt) }}
                  </template>

                  <template #[`item.last_seen_at`]="{item}">
                    {{ formatDate(item.connection.lastSeenAt) }}<br>{{ formatTime(item.connection.lastSeenAt) }}
                  </template>

                  <template #[`item.actions`]="{item}">
                    <template v-if="posUserCan('SystemSettingsDomains', 'MANAGE')">
                      <template v-if="posUser.staff_email !== item.uid">
                        <v-tooltip
                          color="blue-grey"
                          open-delay="750"
                          top
                        >
                          <template #activator="{on}">
                            <v-btn
                              :height="18"
                              :width="18"
                              class="blue-grey pa-0 mr-2 d-inline-flex"
                              color="white"
                              min-height="18"
                              min-width="18"
                              text
                              v-on="on"
                              @click.stop="loginOnUserBehalf(item)"
                            >
                              <v-icon size="14">
                                mdi-web-refresh
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>Login on User Behalf</span>
                        </v-tooltip>
                      </template>

                      <v-tooltip
                        color="red"
                        open-delay="750"
                        top
                      >
                        <template #activator="{on}">
                          <v-btn
                            :height="18"
                            :width="18"
                            class="red pa-0 mr-0"
                            color="white"
                            min-height="18"
                            min-width="18"
                            text
                            v-on="on"
                            @click="logoutDomainUser(item)"
                          >
                            <v-icon size="14">
                              mdi-account-off-outline
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>Logout User</span>
                      </v-tooltip>
                    </template>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 datatable-object settings-window-actions">
      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'
import Watchable      from '@/mixins/watchable'
import DataTable      from '@/mixins/data-table/dataTable'
import FilterSearch   from '@/components/common/filter/FilterSearch'
import StaffGroupEnum from '@/api/Enums/StaffGroupEnum'
import SocketCommand  from '@/api/SocketCommand'

export default {
  name      : 'SystemSettingsOnlineUsers',
  components: {
    FilterSearch
  },
  mixins: [AppData, Auth, Watchable, DataTable],
  data () {
    return {
      tab         : 'tab-production',
      search      : '',
      domainsCount: 0,
      filtersMenu : false,
      filters     : {},
      dataTable   : {
        resize: {
          offset: 42
        },
        options: {
          defaultSortBy    : 'domain',
          sortBy           : ['domain'],
          sortDesc         : [true],
          groupBy          : ['domain'],
          groupDesc        : [true],
          itemsPerPage     : 30,
          serverSideEnabled: false
        },
        footerProps: {
          disableItemsPerPage: false,
          itemsPerPageOptions: [5, 10, 15, 20, 25, 30],
          itemsPerPageText   : ''
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : 'ID',
            value    : 'id',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'NAME',
            value    : 'name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'UID',
            value    : 'uid',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'GROUP',
            value    : 'group',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'GUEST',
            value    : 'guest',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'SINGLE LOGIN',
            value    : 'single_login',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : 'DURATION',
            value    : 'duration',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : 'CONNECTED AT',
            value    : 'connected_at',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : 'LAST SEEN AT',
            value    : 'last_seen_at',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : '',
            value    : 'actions',
            align    : 'right',
            sortable : true,
            cellClass: 'text-caption'
          }
        ]
      }
    }
  },
  computed: {
    usersCount () {
      return this.viewData?.items?.length || 0
    }
  },
  watch: {
    tab (newVal, oldVal) {
      if (newVal === oldVal) return

      this.domainsCount = 0
      this.dataTable.total = this.viewData.count = 0
      this.dataTable.data = this.viewData.items = []

      this.getData()
    }
  },
  created () {},
  mounted () {
    this.$bus.$on(window.SocketCommand.System.Socket.ChannelConnections.Get, this.setData)
  },
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.System.Socket.ChannelConnections.Get, this.setData)
  },
  methods: {
    logoutSystemUsers () {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE')) return

      window.callAS(SocketCommand.System.LogoutUsers, {
        Ids: [],
        All: true
      })
    },

    logoutDomainUsers (domain) {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE')) return

      const users = this.viewData?.items?.filter(item => item.domain === domain)?.map(user => user.id) || []
      if (!users || !users.length) return

      window.callAS(SocketCommand.System.LogoutUsers, {
        Ids: users,
        All: false
      })
    },

    logoutDomainUser (user) {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE') || !user) return

      window.callAS(SocketCommand.System.LogoutUsers, {
        Ids: [user.id],
        All: false
      })
    },

    loginOnUserBehalf (user) {
      if (!this.posUserCan('SystemSettingsDomains', 'MANAGE') || !user) return
      const uid = user.uid
      const pin = '0000'
      const token = window.authorizationToken

      const host = this.tab === 'tab-production' ? 'https://pos.getfoodpro.gr' : process.env.VUE_APP_ENV === 'development' ? 'http://localhost:8080' : 'https://dpos.getfoodpro.gr'
      window.opener = null
      window.open(`${ host }/login?uid=${ encodeURIComponent(uid) }&pin=${ pin }&token=${ token }`, '_blank', '')
    },

    domainUsersCount (domain) {
      if (!domain) return 0

      return this.viewData?.items?.filter(item => item.domain === domain)?.length || 0
    },

    refreshData () {
      if (this.dataTable.loading) return

      this.getData()
    },

    guid () {
      return Date.now().toString(36) + Math.random().toString(36).substr(2)
    },

    getData () {
      this.dataTable.loading = true
      const payload = { env: this.tab === 'tab-dev' ? 'local' : 'production' }
      window.callAS(SocketCommand.System.Socket.ChannelConnections.Get, this.dataTableRequestData(payload))
    },

    setData (data = {}) {
      if (!Array.isArray(data?.connections)) data.connections = []

      const items = []
      const result = data?.connections || []

      this.domainsCount = result.length

      result.forEach(app => {
        const adminUsers = app?.Connections?.admin?.users || []
        adminUsers.forEach(user => {
          user.guid = this.guid()
          user.appId = app.AppId
          user.admin = true
          items.push(user)
        })

        const normalUsers = app?.Connections?.normal?.users || []
        normalUsers.forEach(user => {
          user.guid = this.guid()
          user.appId = app.AppId
          user.admin = false
          items.push(user)
        })

        const guestUsers = app?.Connections?.guest?.users || []
        guestUsers.forEach(user => {
          user.guid = this.guid()
          user.appId = app.AppId
          user.admin = false
          items.push(user)
        })
      })

      const payload = {
        items: items,
        count: items.length
      }

      this.setDataInternal(payload)
    },

    formatDate (date, separatorFrom = '-', separatorTo = '/') {
      if (!date) return null

      const [year, month, day] = date.substring(0, 10).split(separatorFrom)
      return `${ day }${ separatorTo }${ month }${ separatorTo }${ year }`
    },

    formatTime (date, separatorFrom = ':', separatorTo = ':') {
      if (!date) return null

      const [hour, min, sec] = date.substring(11, 19).split(separatorFrom)
      return `${ hour }${ separatorTo }${ min }${ separatorTo }${ sec }`
    },

    changeStaffDomain (domain) {
      if (!domain || domain === this.posUser.Domain || !this.userInAdminGroup || !this.posUserCan('SystemSettingsDomains', 'MANAGE')) return

      this.$bus.$emit('show-app-loading-modal', true)
      window.callAS(window.SocketCommand.Staff.ChangeDomain, { Domain: domain })
    },

    staffGroup (id) {
      if (id === StaffGroupEnum.ADMIN) {
        return {
          Color: 'purple',
          Icon : 'mdi-shield-account',
          Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
          Id   : id
        }
      }
      if (id === StaffGroupEnum.OWNER) {
        return {
          Color: 'green',
          Icon : 'mdi-account-tie',
          Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
          Id   : id
        }
      }
      if (id === StaffGroupEnum.CASHIER) {
        return {
          Color: 'teal',
          Icon : 'mdi-account-cash',
          Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
          Id   : id
        }
      }
      if (id === StaffGroupEnum.WAITER) {
        return {
          Color: 'blue',
          Icon : 'room_service',
          Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
          Id   : id
        }
      }
      if (id === StaffGroupEnum.USER) {
        return {
          Color: 'blue-grey',
          Icon : 'mdi-account',
          Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
          Id   : id
        }
      }
      if (id === StaffGroupEnum.GFP_EDITOR) {
        return {
          Color: 'indigo',
          Icon : 'mdi-account-edit',
          Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
          Id   : id
        }
      }
      if (id === StaffGroupEnum.CRM_EDITOR) {
        return {
          Color: 'indigo',
          Icon : 'mdi-account-edit',
          Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
          Id   : id
        }
      }

      return {
        Color: 'grey darken-3',
        Icon : 'mdi-account',
        Name : this.$te(`Authentication.StaffGroup.${ id }`) ? this.$t(`Authentication.StaffGroup.${ id }`) : id,
        Id   : id
      }
    }
  }
}
</script>

<style scoped>
/deep/ .single-login-label label.v-label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
