const isPlainObject = val => !!val && typeof val === 'object' && val.constructor === Object
export default {
  name: 'DataTable',
  data () {
    return {
      tmpData: {
        count: 0,
        items: []
      },
      dataTable: {
        loading     : false,
        loaded      : false,
        autoload    : true,
        total       : 0,
        dataStore   : null,
        tableActions: false,
        text        : {
          loadingText  : this.$t('Common.Table.Text.LoadingText'),
          noDataText   : this.$t('Common.Table.Text.NoDataText'),
          noResultsText: this.$t('Common.Table.Text.NoResultsText')
        },
        requestTableConfig: false,
        socketCommand     : {
          list  : null,
          save  : null,
          delete: null,
          get   : null
        },
        search: {
          terms          : '',
          debounceTimeout: null,
          enabled        : true
        },
        resize: {
          enabled        : true,
          debounceTimeout: null,
          offset         : 0,
          bodyHeight     : undefined
        },
        options: {
          page             : 1,
          itemsPerPage     : 10,
          sortBy           : [],
          sortDesc         : [],
          groupBy          : [],
          groupDesc        : [],
          mustSort         : true,
          multiSort        : false,
          serverSideEnabled: true
        },
        footerProps: {
          disableItemsPerPage: false,
          itemsPerPageOptions: [5, 10, 15],
          itemsPerPageText   : ''
        },
        filter                     : {},
        payload                    : {},
        filterDebounceTimeout      : null,
        customFilter               : {},
        customFilterDebounceTimeout: null,
        rejectHeaders              : {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [],
        data   : []
      }
    }
  },

  computed: {
    viewData () {
      return this.dataTable?.dataStore ? this.$DataStore[this.dataTable.dataStore] : this.tmpData
    },

    viewHasData () {
      return this.viewData?.items?.length > 0
    },

    simpleFilterPayload () {
      const filtersKeys = isPlainObject(this.filters) ? Object.keys(this.filters) : []

      return filtersKeys.length ? { filters: this.filters } : null
    },

    dataTableHeaders () {
      const headers = this.dataTable?.headers || []

      if (this.$vuetify.breakpoint.xsOnly) return headers?.filter(header => !this.dataTable?.rejectHeaders?.xsOnly?.includes(header.value))
      if (this.$vuetify.breakpoint.smAndDown) return headers?.filter(header => !this.dataTable?.rejectHeaders?.smAndDown?.includes(header.value))
      if (this.$vuetify.breakpoint.mdAndDown) return headers?.filter(header => !this.dataTable?.rejectHeaders?.mdAndDown?.includes(header.value))
      if (this.$vuetify.breakpoint.lgAndDown) return headers?.filter(header => !this.dataTable?.rejectHeaders?.lgAndDown?.includes(header.value))
      if (this.$vuetify.breakpoint.xl) return headers?.filter(header => !this.dataTable?.rejectHeaders?.xlAndDown?.includes(header.value))

      return headers
    }
  },

  watch: {
    'dataTable.search.terms': {
      handler (newVal, oldVal) {
        if (this.dataTable.options.serverSideEnabled) {
          if (String(newVal).trim() !== String(oldVal).trim() && (String(newVal).trim().length >= 2 || String(newVal).trim().length === 0)) {
            this.dataTable.options.page = 1
            clearTimeout(this.dataTable.search.debounceTimeout)

            this.dataTable.search.debounceTimeout = setTimeout(() => {
              this.getData()
            }, 500)
          }
        }
      },
      deep: false
    },
    'dataTable.filter': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        this.dataTable.options.page = 1
        clearTimeout(this.dataTable.filterDebounceTimeout)
        this.dataTable.filterDebounceTimeout = setTimeout(() => {
          this.getData()
        }, 500)
      },
      deep: true
    },
    'dataTable.customFilter': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        this.dataTable.options.page = 1
        clearTimeout(this.dataTable.customFilterDebounceTimeout)
        this.dataTable.customFilterDebounceTimeout = setTimeout(() => {
          this.getData()
        }, 500)
      },
      deep: true
    },
    'dataTable.options': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        if (this.dataTable.loaded) this.getData()
      },
      deep: false
    }
  },

  methods: {
    dataTableRequestData (payload = {}) {
      const dt = JSON.parse(JSON.stringify(this.dataTable))

      Object.keys(dt?.filter || {}).forEach(key => {
        if (dt.filter[key] === 'null') dt.filter[key] = null
      })

      return {
        config: dt?.requestTableConfig ? 1 : 0,
        ...(dt?.options?.page && { page: dt?.options?.page }),
        ...(dt?.options?.itemsPerPage && { page_limit: dt?.options?.itemsPerPage }),
        ...(dt?.options?.sortBy?.length && { sort_by: this.first(dt?.options?.sortBy) }),
        ...(dt?.options?.sortDesc?.length && { order_by: this.first(dt?.options?.sortDesc) === true ? 'DESC' : 'ASC' }),
        ...(dt?.search?.terms?.trim() && { filter_search: dt?.search?.terms?.trim() }),
        ...(dt?.filter && dt.filter),
        ...(dt?.payload && dt.payload),
        ...(payload && payload),
        ...(this.simpleFilterPayload && this.simpleFilterPayload),
        ...(this.filterPayload && this.filterPayload)
      }
    },

    getDataInternal (payload = {}) {
      if (this.dataTable.socketCommand.list) {
        this.dataTable.loading = true
        window.callAS(this.dataTable.socketCommand.list, this.dataTableRequestData(payload))
      }
    },
    getData (payload = {}) {
      this.getDataInternal(payload)
      if (this.dataTable.socketCommand.list) return

      // eslint-disable-next-line no-console
      console.warn('dataTable MIXIN ::: You must implement "getData" function in your component!')
    },

    setDataInternal (data) {
      this.dataTable.loading = false

      if (data.error) return

      if (data?.items) {
        this.$set(this.viewData, 'count', data?.count || 0)
        this.$set(this.viewData, 'items', data?.items || [])
      } else if (data?.table) {
        this.$set(this.viewData, 'count', data?.table?.items?.count || 0)
        this.$set(this.viewData, 'items', data?.table?.items?.items || [])
      } else if (data?.result?.items) {
        this.$set(this.viewData, 'count', data?.result?.count || 0)
        this.$set(this.viewData, 'items', data?.result?.items || [])
      } else if (data?.result?.table) {
        this.$set(this.viewData, 'count', data?.result?.table?.items?.count || 0)
        this.$set(this.viewData, 'items', data?.result?.table?.items?.items || [])
      } else {
        this.$set(this.viewData, 'items', data || [])
      }

      this.$nextTick(() => {
        this.dataTable.total = this.viewData?.count || 0
        this.dataTable.data = this.viewData?.items || []
        this.dataTable.loaded = true
      })
    },
    setData (data) {
      this.setDataInternal(data)
      if (this.dataTable.socketCommand.list) return

      // eslint-disable-next-line no-console
      console.warn('dataTable MIXIN ::: You must implement "setData" function in your component!')
    },

    onSaveResultInternal (data) {
      if (this.requestIsSuccess(data)) this.getData()
    },
    onSaveResult (data) {
      this.onSaveResultInternal(data)
    },

    onDeleteResultInternal (data) {
      if (this.requestIsSuccess(data)) this.getData()
    },
    onDeleteResult (data) {
      this.onDeleteResultInternal(data)
    },

    requestIsSuccess (data) {
      return data.status === 'success' || data.status === true || data.result
    },

    calcTableBodyHeight () {
      if (!this.dataTable.resize.enabled) return
      const app = document.getElementsByClassName('v-application--wrap')[0]
      if (!app) return

      const table = app.getElementsByClassName('v-data-table')[0]
      const tableCard = table ? table.parentElement : null
      if (!tableCard) return

      const footer = app.getElementsByTagName('footer')[0]
      const footerHeight = footer ? footer.offsetHeight : 0

      const tableFooter = tableCard.getElementsByClassName('v-data-footer')[0]
      const tableFooterHeight = tableFooter ? tableFooter.offsetHeight : 0

      const cardTitle = tableCard.getElementsByClassName('v-card__title')[0]
      const cardTitleHeight = cardTitle ? cardTitle.offsetHeight : 0

      const appToolbar = app.getElementsByClassName('appToolbar')[0]
      const appToolbarHeight = appToolbar ? appToolbar.offsetHeight : 0

      const filterToolbar = app.getElementsByClassName('filter-toolbar')[0]
      const filterToolbarHeight = filterToolbar ? filterToolbar.offsetHeight : 0

      const customObjects = [...app.getElementsByClassName('datatable-object')]
      const customObjectsHeight = customObjects?.length ? customObjects.reduce((previous, current) => previous + (current?.offsetHeight || 0), 0) || 0 : 0

      const offset = parseInt(this.dataTable?.resize?.offset || 0)

      // console.log(appToolbarHeight, filterToolbarHeight, cardTitleHeight, tableFooterHeight, footerHeight, customObjectsHeight, offset)

      this.dataTable.resize.bodyHeight = `calc(100vh - ${ appToolbarHeight + filterToolbarHeight + cardTitleHeight + tableFooterHeight + footerHeight + customObjectsHeight + offset }px)`
    },

    resizeDebounced () {
      if (!this.dataTable.resize.enabled) return
      clearTimeout(this.dataTable.resize.debounceTimeout)
      this.dataTable.resize.debounceTimeout = setTimeout(this.calcTableBodyHeight, 350)
    },

    first (arr) {
      return (Array.isArray(arr) && arr.length && arr[0]) || null
    }
  },

  created () {
    if (this.viewData.items) {
      this.dataTable.total = this.viewData?.count || 0
      this.dataTable.data = this.viewData?.items || []
    }

    this.calcTableBodyHeight()
    window.addEventListener('resize', this.resizeDebounced)

    if (this.dataTable.autoload) this.getData()
  },

  mounted () {
    this.calcTableBodyHeight()

    if (this.dataTable.socketCommand.list) this.$bus.$on(this.dataTable.socketCommand.list, this.setData)
    if (this.dataTable.socketCommand.save) this.$bus.$on(this.dataTable.socketCommand.save, this.onSaveResult)
    if (this.dataTable.socketCommand.delete) this.$bus.$on(this.dataTable.socketCommand.delete, this.onDeleteResult)
  },

  updated () {
    this.calcTableBodyHeight()
  },

  beforeDestroy () {
    if (this.dataTable.socketCommand.list) this.$bus.$off(this.dataTable.socketCommand.list, this.setData)
    if (this.dataTable.socketCommand.save) this.$bus.$off(this.dataTable.socketCommand.save, this.onSaveResult)
    if (this.dataTable.socketCommand.delete) this.$bus.$off(this.dataTable.socketCommand.delete, this.onDeleteResult)

    window.removeEventListener('resize', this.resizeDebounced)
  }

}
