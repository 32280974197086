<template>
  <v-card>
    <v-toolbar
      ref="toolbar"
      flat
      extension-height="40"
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Website.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Website.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Website.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Website.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <template #extension>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          height="40"
          slider-color="blue"
        >
          <v-tab key="qrcode">
            {{ $t('Settings.Website.Tabs.QRCode') }}
          </v-tab>

          <v-tab key="pages">
            {{ $t('Settings.Website.Tabs.Pages') }}
          </v-tab>

          <v-tab key="gallery">
            {{ $t('Settings.Website.Tabs.Gallery') }}
          </v-tab>

          <v-tab key="social">
            {{ $t('Settings.Website.Tabs.Social') }}
          </v-tab>

          <v-tab key="analytics">
            {{ $t('Settings.Website.Tabs.Analytics') }}
          </v-tab>

          <v-tab key="theme">
            {{ $t('Settings.Website.Tabs.Theme') }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <v-tabs-items
        v-model="tab"
        class="overflow-visible"
      >
        <template v-if="viewData">
          <v-tab-item key="qrcode">
            <v-container
              fluid
              class="pa-1"
            >
              <v-row dense>
                <template v-for="(qr, key, index) in viewData.QrCodes">
                  <v-col
                    :key="index"
                    lg="6"
                    md="12"
                  >
                    <v-card>
                      <v-card-title>
                        <v-icon class="pr-3">
                          qr_code
                        </v-icon>
                        <h5 class="text-body-2 font-weight-bold">
                          {{ qr.Type === 'catalog' ? $t('Settings.Website.Field.QRCode.Title.Catalog') : $t('Settings.Website.Field.QRCode.Title.Home') }}
                        </h5>
                      </v-card-title>

                      <v-divider />

                      <v-card-text>
                        <v-container
                          fluid
                          class="pa-0"
                        >
                          <v-row
                            dense
                            align="center"
                          >
                            <v-col
                              class="text-center justify-center align-center"
                              lg="8"
                              md="12"
                            >
                              <div
                                :ref="key"
                                class="qr-image"
                              />

                              <div class="text-caption font-weight-bold">
                                {{ qr.Url }}
                                <v-btn
                                  class="ma-0"
                                  icon
                                  small
                                  @click="copyToClipboard(qr.Url)"
                                >
                                  <v-icon small>
                                    far fa-copy
                                  </v-icon>
                                </v-btn>

                                <v-btn
                                  :href="qr.Url"
                                  class="ma-0"
                                  icon
                                  small
                                  target="_blank"
                                >
                                  <v-icon small>
                                    launch
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-col>

                            <v-col
                              class="text-center"
                              lg="4"
                              md="12"
                            >
                              <v-container
                                fluid
                                class="pa-0"
                              >
                                <v-row
                                  no-gutters
                                  align="center"
                                >
                                  <v-col cols="12">
                                    <color-picker
                                      v-model="qr.Options.ColorDark"
                                      :color="qr.Options.ColorDark || '#000000'"
                                      :disabled="!userCanManage"
                                      :label="$t('Settings.Website.Field.QRCode.Color') + ' #1'"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <color-picker
                                      v-model="qr.Options.ColorLight"
                                      :color="qr.Options.ColorLight || '#FFFFFF'"
                                      :disabled="!userCanManage"
                                      :label="$t('Settings.Website.Field.QRCode.Color') + ' #2'"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <color-picker
                                      v-model="qr.Options.ColorControlOuter"
                                      :color="qr.Options.ColorControlOuter || qr.Options.ColorDark || '#000000'"
                                      :disabled="!userCanManage"
                                      :label="$t('Settings.Website.Field.QRCode.Color') + ' #3'"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <color-picker
                                      v-model="qr.Options.ColorControlInner"
                                      :color="qr.Options.ColorControlInner || qr.Options.ColorDark || '#000000'"
                                      :disabled="!userCanManage"
                                      :label="$t('Settings.Website.Field.QRCode.Color') + ' #4'"
                                    />
                                  </v-col>

                                  <v-col cols="12">
                                    <v-switch
                                      v-model="qr.Options.Logo"
                                      inset
                                      :disabled="!userCanManage"
                                      :label="qr.Options.Logo ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                      class="ma-0"
                                      color="success"
                                      hide-details
                                    >
                                      <h5
                                        slot="prepend"
                                        class="pt-1 grey--text text--darken-1 text-no-wrap"
                                      >
                                        {{ $t('Settings.Website.Field.QRCode.Logo') }}
                                      </h5>
                                    </v-switch>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-slider
                                      v-model="qr.Options.DotScale"
                                      :max="1.0"
                                      :min="0.5"
                                      always-dirty
                                      class="ma-0"
                                      color="grey darken-3"
                                      hide-details
                                      step="0.1"
                                      ticks="always"
                                    >
                                      <h5
                                        slot="prepend"
                                        class="pt-1 grey--text text--darken-1 text-no-wrap"
                                      >
                                        {{ $t('Settings.Website.Field.QRCode.DotScale') }}
                                      </h5>
                                    </v-slider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-col>

                            <v-col
                              class="text-center"
                              cols="12"
                            >
                              <v-btn
                                :download="qr.Type === 'catalog' ? 'gfp-qrcode-catalog' : 'gfp-qrcode-home'"
                                :href="qrCodeDownloadImageSrc[key]"
                                block
                                dark
                                tag="a"
                              >
                                {{ $t('Settings.Website.Field.QRCode.Button.DownloadQR') }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="pages">
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header class="py-2">
                  <v-card-title class="pa-0">
                    <v-icon class="pr-3">
                      {{ $t('Settings.Website.Field.Page.Home.Icon') }}
                    </v-icon>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Website.Field.Page.Home.Title') }}
                    </h5>
                  </v-card-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Home.StoreInfo.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Home.StoreInfo.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.ShowRestaurantInfoHome"
                              inset
                              :disabled="!userCanManage"
                              :label="viewData.ShowRestaurantInfoHome ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              class="ma-0"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Home.Offer.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Home.Offer.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.Offers"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.Offers ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Home.FeaturedProduct.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Home.FeaturedProduct.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.Menus"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.Menus ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Home.FeaturedCategory.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Home.FeaturedCategory.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.Categories"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.Categories ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Home.AllCategories.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Home.AllCategories.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.ShowAllCategoriesHome"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.ShowAllCategoriesHome ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col
                            v-if="!viewData.ShowAllCategoriesHome"
                            cols="12"
                          >
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Home.MaxCategoryNum.Title') }}
                            </h5>
                            <v-text-field
                              v-model="viewData.MaxVisibleCategoriesHome"
                              :disabled="!userCanManage || viewData.ShowAllCategoriesHome"
                              :label="$t('Settings.Website.Field.Page.Home.MaxCategoryNum.Label')"
                              clearable
                              hide-details
                              single-line
                              type="number"
                              min="0"
                              step="1"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Home.RandomCategory.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Home.RandomCategory.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.ShowRandomCategoriesHome"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.ShowRandomCategoriesHome ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="py-2">
                  <v-card-title class="pl-0">
                    <v-icon class="pr-3">
                      {{ $t('Settings.Website.Field.Page.Catalog.Icon') }}
                    </v-icon>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Website.Field.Page.Catalog.Title') }}
                    </h5>
                  </v-card-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Catalog.StoreInfo.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.StoreInfo.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.ShowRestaurantInfoCatalog"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.ShowRestaurantInfoCatalog ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Catalog.ProductImage.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.ProductImage.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.MenuImages"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.MenuImages ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Catalog.ProductImageSize.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.ProductImageSize.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.UseMenuLargeImage"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.UseMenuLargeImage ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Catalog.MenuListItemImageLeft.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.MenuListItemImageLeft.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <div class="text-no-wrap">
                              <v-switch
                                v-model="MenuListItemImagePos.Left"
                                inset
                                :disabled="!userCanManage"
                                :label="$t('Common.Button.Left')"
                                class="ma-0 d-inline-block"
                                color="success"
                                hide-details
                                @change="onMenuListItemImagePosLeftChange"
                              />

                              <v-switch
                                v-model="MenuListItemImagePos.Right"
                                inset
                                :disabled="!userCanManage"
                                :label="$t('Common.Button.Right')"
                                class="ma-0 ml-2 d-inline-block"
                                color="success"
                                hide-details
                                @change="onMenuListItemImagePosRightChange"
                              />
                            </div>
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Catalog.QuickAddMenuItem.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.QuickAddMenuItem.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.QuickAddMenuItem"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.QuickAddMenuItem ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Catalog.ShowSelectedMenuOptions.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.ShowSelectedMenuOptions.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.ShowSelectedMenuOptions"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.ShowSelectedMenuOptions ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Catalog.ProductPage.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.ProductPage.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.UseMenuLandingPage"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.UseMenuLandingPage ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <template v-if="userIsSuperAdmin">
                            <v-col cols="12">
                              <v-divider class="" />
                            </v-col>
                            <v-col>
                              <h5 class="text-body-2 font-weight-bold">
                                {{ $t('Settings.Website.Field.Page.Catalog.AllCategories.Title') }}
                              </h5>
                              <span class="text-caption">{{ $t('Settings.Website.Field.Page.Catalog.AllCategories.Subtitle.ThereAre') }}
                                <span :class="[parseInt(viewData.ProductsCount) >= parseInt(viewData.ShowMenuCategoriesDefaultLimit) ? 'red--text' : 'green--text', 'font-weight-bold']">{{ viewData.ProductsCount }}</span> {{ $t('Settings.Website.Field.Page.Catalog.AllCategories.Subtitle.ActiveProducts') }}
                                <br>{{ $t('Settings.Website.Field.Page.Catalog.AllCategories.ProductLimit.Title') }}</span>
                            </v-col>
                            <v-col
                              class="text-right shrink"
                            >
                              <v-switch
                                v-model="viewData.ShowMenuCategories"
                                inset
                                :disabled="!userCanManage"
                                :label="viewData.ShowMenuCategories ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                class="ma-0 d-inline-flex"
                                color="success"
                                hide-details
                              />
                              <v-text-field
                                v-model="viewData.ShowMenuCategoriesLimit"
                                :disabled="!userCanManage || !viewData.ShowMenuCategories"
                                :label="$t('Settings.Website.Field.Page.Catalog.AllCategories.ProductLimit.Label')"
                                clearable
                                hide-details
                                single-line
                                style="max-width: 170px;"
                                type="number"
                                min="0"
                                step="1"
                              />
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="py-2">
                  <v-card-title class="pl-0">
                    <v-icon class="pr-3">
                      {{ $t('Settings.Website.Field.Page.Contact.Icon') }}
                    </v-icon>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Website.Field.Page.Contact.Title') }}
                    </h5>
                  </v-card-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col cols="12">
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Contact.FormEmail.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Contact.FormEmail.Subtitle') }}</span>
                            <v-text-field
                              v-model="viewData.ContactFormEmail"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Website.Field.Page.Contact.FormEmail.Label')"
                              hide-details
                              required
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.Contact.ShowDeliveryAreas.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.Contact.ShowDeliveryAreas.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.ShowDeliveryAreas"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.ShowDeliveryAreas ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <template v-if="viewData.ShowDeliveryAreas">
                            <v-col cols="12">
                              <v-divider />
                            </v-col>

                            <v-col>
                              <h5 class="text-body-2 font-weight-bold">
                                {{ $t('Settings.Website.Field.Page.Contact.ShowDeliveryAreasCharges.Title') }}
                              </h5>
                              <span class="text-caption">{{ $t('Settings.Website.Field.Page.Contact.ShowDeliveryAreasCharges.Subtitle') }}</span>
                            </v-col>
                            <v-col class="shrink">
                              <v-switch
                                v-model="viewData.ShowDeliveryAreasCharges"
                                inset
                                class="ma-0"
                                :disabled="!userCanManage"
                                :label="viewData.ShowDeliveryAreasCharges ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                color="success"
                                hide-details
                              />
                            </v-col>
                          </template>

                          <v-col cols="12">
                            <v-divider />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!appConfig.LOCATION_DATA.CatalogOnly">
                <v-expansion-panel-header class="py-2">
                  <v-card-title class="pl-0">
                    <v-icon class="pr-3">
                      {{ $t('Settings.Website.Field.Page.User.Icon') }}
                    </v-icon>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Website.Field.Page.User.Title') }}
                    </h5>
                  </v-card-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.User.Guest.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.User.Guest.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.AllowGuestOrders"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.AllowGuestOrders ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.User.QrOnlineWithCustomer.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.User.QrOnlineWithCustomer.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.QrOnlineWithCustomer"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.QrOnlineWithCustomer ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="viewData.QrOnlineWithCustomer"
                          dense
                          align="center"
                          class="ml-2"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.User.QrOnlineWithCustomerRequired.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.User.QrOnlineWithCustomerRequired.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.QrOnlineWithCustomerRequired"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.QrOnlineWithCustomerRequired ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col cols="12">
                            <v-divider />
                          </v-col>

                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.User.Email.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.User.Email.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              inset
                              class="ma-0"
                              :label="$t('Common.Button.Toggle.Active')"
                              color="success"
                              disabled
                              hide-details
                              input-value="true"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-divider />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.User.GoogleLogin.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.User.GoogleLogin.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.SocialLoginGoogle"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.SocialLoginGoogle ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="viewData.GoogleClientId"
                              :disabled="!userCanManage || !viewData.SocialLoginGoogle"
                              :label="$t('Settings.Website.Field.Page.User.GoogleLogin.GoogleID')"
                              class=""
                              clearable
                              hide-details
                              prepend-icon=""
                              required
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="viewData.GoogleClientSecret"
                              :disabled="!userCanManage || !viewData.SocialLoginGoogle"
                              :label="$t('Settings.Website.Field.Page.User.GoogleLogin.GoogleSecret')"
                              class=""
                              clearable
                              hide-details
                              prepend-icon=""
                              required
                              type="password"
                            />
                          </v-col>
                          <v-col cols="12">
                            <h5 class="d-inline">
                              {{ $t('Settings.Website.Field.Page.User.GoogleLogin.GoogleRedirect.Title') }}
                            </h5>{{ viewData.GoogleRedirectUri }}
                            <v-btn
                              icon
                              small
                              @click="copyToClipboard(viewData.GoogleRedirectUri)"
                            >
                              <v-icon small>
                                {{ $t('Settings.Website.Field.Page.User.GoogleLogin.GoogleRedirect.Icon') }}
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-divider />
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.User.FacebookLogin.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.User.FacebookLogin.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.SocialLoginFacebook"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.SocialLoginFacebook ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="viewData.FacebookClientId"
                              :disabled="!userCanManage || !viewData.SocialLoginFacebook"
                              :label="$t('Settings.Website.Field.Page.User.FacebookLogin.FacebookID')"
                              class=""
                              clearable
                              hide-details
                              prepend-icon=""
                              required
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="viewData.FacebookClientSecret"
                              :disabled="!userCanManage || !viewData.SocialLoginFacebook"
                              :label="$t('Settings.Website.Field.Page.User.FacebookLogin.FacebookSecret')"
                              class=""
                              clearable
                              hide-details
                              prepend-icon=""
                              required
                              type="password"
                            />
                          </v-col>
                          <v-col cols="12">
                            <h5 class="d-inline">
                              {{ $t('Settings.Website.Field.Page.User.FacebookLogin.FacebookRedirect.Title') }}
                            </h5>{{ viewData.FacebookRedirectUri }}
                            <v-btn
                              icon
                              small
                              @click="copyToClipboard(viewData.FacebookRedirectUri)"
                            >
                              <v-icon small>
                                {{ $t('Settings.Website.Field.Page.User.FacebookLogin.FacebookRedirect.Icon') }}
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-divider />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="userIsSuperAdmin"
                          dense
                          align="center"
                        >
                          <v-col cols="12">
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.User.GoogleRecaptcha.Title') }}
                            </h5>
                            <v-text-field
                              v-model="viewData.RecaptchaKey"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Website.Field.Page.User.GoogleRecaptcha.Label.Public')"
                              clearable
                              hide-details
                              prepend-icon=""
                              required
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="viewData.RecaptchaSecret"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Website.Field.Page.User.GoogleRecaptcha.Label.Secret')"
                              clearable
                              hide-details
                              prepend-icon=""
                              required
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="py-2">
                  <v-card-title class="pl-0">
                    <v-icon class="pr-3">
                      {{ $t('Settings.Website.Field.Page.OtherPage.Icon') }}
                    </v-icon>
                    <h5 class="text-body-2 font-weight-bold">
                      {{ $t('Settings.Website.Field.Page.OtherPage.Title') }}
                    </h5>
                  </v-card-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Website.Field.Page.OtherPage.CustomPage.Title') }}
                            </h5>
                            <span class="text-caption">{{ $t('Settings.Website.Field.Page.OtherPage.CustomPage.Subtitle') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <v-switch
                              v-model="viewData.Pages.HasCustomPages"
                              inset
                              class="ma-0"
                              :disabled="!userCanManage"
                              :label="viewData.Pages.HasCustomPages ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                              color="success"
                              hide-details
                            />
                          </v-col>
                        </v-row>

                        <template v-if="viewData.Pages.HasCustomPages || userIsSuperAdmin">
                          <template v-for="(page, index) in viewData.Pages.Items">
                            <v-row
                              :key="index"
                              dense
                              align="center"
                            >
                              <v-col cols="12">
                                <v-divider />
                              </v-col>
                              <v-col>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ page.Heading }}
                                </h5>
                                <span class="text-caption">{{ $t('Settings.Website.Field.Page.OtherPage.ListView.Edit.Title') }}</span>
                              </v-col>
                              <v-col class="shrink">
                                <div class="text-no-wrap">
                                  <div class="d-inline-block">
                                    <v-tooltip
                                      :disabled="!viewData.Pages.HasCustomPages"
                                      open-delay="1000"
                                      color="grey"
                                      top
                                    >
                                      <template #activator="{on}">
                                        <v-btn
                                          :disabled="!viewData.Pages.HasCustomPages"
                                          class="grey elevation-2 ma-0"
                                          dark
                                          icon
                                          v-on="on"
                                          @click="onEditClick(page, false)"
                                        >
                                          <v-icon
                                            v-if="userCanManage"
                                            small
                                          >
                                            {{ $t('Settings.Website.Field.Page.OtherPage.ListView.Edit.Icon') }}
                                          </v-icon>
                                          <v-icon
                                            v-else
                                            small
                                          >
                                            {{ $t('Settings.Website.Field.Page.OtherPage.ListView.View.Icon') }}
                                          </v-icon>
                                        </v-btn>
                                      </template>

                                      <span v-if="userCanManage">{{ $t('Settings.Website.Field.Page.OtherPage.ListView.Edit.Title') }}</span>
                                      <span v-else>{{ $t('Settings.Website.Field.Page.OtherPage.ListView.View.Title') }}</span>
                                    </v-tooltip>
                                  </div>

                                  <div class="d-inline-block">
                                    <v-tooltip
                                      v-if="userIsSuperAdmin"
                                      open-delay="1000"
                                      color="orange"
                                      top
                                    >
                                      <template #activator="{on}">
                                        <v-btn
                                          v-if="userIsSuperAdmin"
                                          class="elevation-2 ma-1"
                                          color="orange"
                                          dark
                                          icon
                                          v-on="on"
                                          @click="onEditClick(page, true)"
                                        >
                                          <v-icon small>
                                            {{ $t('Settings.Website.Field.Page.OtherPage.ListView.EditManager.Icon') }}
                                          </v-icon>
                                        </v-btn>
                                      </template>

                                      <span>{{ $t('Settings.Website.Field.Page.OtherPage.ListView.EditManager.Title') }}</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>

          <v-tab-item key="gallery">
            <v-container
              fluid
              class="pa-1"
            >
              <v-row dense>
                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Website.Field.Gallery.Settings.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Website.Field.Gallery.Settings.Title') }}
                      </h5>
                    </v-card-title>

                    <v-divider />

                    <v-card-text class="px-0">
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col cols="12">
                            <v-slider
                              v-model="viewData.HeaderImagesSize.MinHeight"
                              :max="345"
                              :min="65"
                              :tick-labels="['65px', '100px', '135px', '170px', '205px', '240px', '275px', '310px', '345px']"
                              always-dirty
                              class="mx-4"
                              color="grey darken-3"
                              hide-details
                              step="35"
                              tick-size="2"
                              ticks="always"
                            >
                              <h5
                                slot="prepend"
                                class="pt-1 pr-2 grey--text text--darken-1 text-no-wrap"
                              >
                                {{ $t('Settings.Website.Field.Gallery.Settings.MinHeight.Label') }}
                              </h5>
                            </v-slider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Website.Field.Gallery.Upload.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Website.Field.Gallery.Upload.Title') }}
                      </h5>
                    </v-card-title>

                    <v-divider />

                    <v-card-text class="px-0">
                      <v-expansion-panels accordion>
                        <template v-for="(headerImage, i) in viewData.HeaderImages">
                          <v-expansion-panel :key="'header_image_' + i">
                            <v-expansion-panel-header>
                              <v-card-title class="pl-0">
                                <v-icon class="pr-3">
                                  far fa-images
                                </v-icon>
                                <h5 class="text-body-2 font-weight-bold">
                                  {{ headerImage.Name }} <span class="text-caption">({{ headerImage.Size.w }}x{{ headerImage.Size.h }})</span>
                                </h5>
                              </v-card-title>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <v-container
                                fluid
                                class="pa-0"
                              >
                                <v-row no-gutters>
                                  <v-col
                                    cols="12"
                                    class="text-center"
                                  >
                                    <image-crop-selector
                                      v-model="headerImage.croppa"
                                      :height="parseInt(headerImage.Size.h)"
                                      :initial-image="headerImage.Image ? appConfig.LOCATION_DATA.CdnImagesUrl + 'headers/' + headerImage.Image : ''"
                                      :placeholder="`${$t('Settings.Website.Field.Gallery.Image.Hint.Drag')} ${$t('Settings.Website.Field.Gallery.Image.Hint.Click')}`"
                                      :placeholder-font-size="32"
                                      :prevent-white-space="true"
                                      :replace-drop="true"
                                      :show-loading="true"
                                      :width="parseInt(headerImage.Size.w)"
                                      @new-image-drawn="onPhotoChange(headerImage, $event)"
                                      @image-remove="onPhotoRemoved(headerImage)"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </template>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="social">
            <v-card flat>
              <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                <v-icon class="pr-3">
                  {{ $t('Settings.Website.Field.Social.Icon') }}
                </v-icon>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Website.Field.Social.Title') }}
                </h5>
              </v-card-title>

              <v-divider />

              <v-card-text>
                <v-container
                  fluid
                  class="pa-0"
                >
                  <v-row
                    dense
                    align="center"
                  >
                    <template v-for="(Social, i) in viewData.Social">
                      <v-col
                        :key="i + '_' + 'social_value'"
                        cols="12"
                      >
                        <v-text-field
                          v-model="Social.url"
                          :disabled="!userCanManage"
                          :label="Social.name"
                          :prepend-icon="Social.icon"
                          hint="https://www.social-network-address.com/"
                          required
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="analytics">
            <v-card flat>
              <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                <v-icon class="pr-3">
                  {{ $t('Settings.Website.Field.Analytics.Google.Icon') }}
                </v-icon>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Website.Field.Analytics.Google.Title') }}
                </h5>
              </v-card-title>

              <v-divider />

              <v-card-text>
                <v-container
                  fluid
                  class="pa-0"
                >
                  <v-row
                    dense
                    align="center"
                  >
                    <v-col
                      v-if="userIsSuperAdmin"
                      cols="12"
                    >
                      <v-text-field
                        v-model="viewData.Analytics.google.gfpId"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Website.Field.Analytics.Google.Gfp.Hint')"
                        :label="$t('Settings.Website.Field.Analytics.Google.Gfp.Label')"
                        :prepend-icon="$t('Settings.Website.Field.Analytics.Google.Gfp.Icon')"
                        required
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="viewData.Analytics.google.clientId"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Website.Field.Analytics.Google.Client.Hint')"
                        :label="$t('Settings.Website.Field.Analytics.Google.Client.Label')"
                        :prepend-icon="$t('Settings.Website.Field.Analytics.Google.Client.Icon')"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card
              v-if="userIsSuperAdmin"
              flat
            >
              <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                <v-icon class="pr-3">
                  {{ $t('Settings.Website.Field.Analytics.Facebook.Icon') }}
                </v-icon>
                <h5 class="text-body-2 font-weight-bold">
                  {{ $t('Settings.Website.Field.Analytics.Facebook.Title') }}
                </h5>
              </v-card-title>

              <v-divider />

              <v-card-text>
                <v-container
                  fluid
                  class="pa-0"
                >
                  <v-row
                    dense
                    align="center"
                  >
                    <v-col cols="12">
                      <v-text-field
                        v-model="viewData.Analytics.facebook.appId"
                        :disabled="!userCanManage"
                        :hint="$t('Settings.Website.Field.Analytics.Facebook.AppId.Hint')"
                        :label="$t('Settings.Website.Field.Analytics.Facebook.AppId.Label')"
                        :prepend-icon="$t('Settings.Website.Field.Analytics.Facebook.AppId.Icon')"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="theme">
            <v-container
              fluid
              class="pa-1"
            >
              <v-row dense>
                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Website.Field.Theme.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Website.Field.Theme.Site') }}
                      </h5>

                      <v-spacer />

                      <v-btn
                        v-if="userCanManage"
                        :disabled="viewData===null || isSaving"
                        class="blue white--text elevation-0"
                        @click="onResetThemeClick"
                      >
                        {{ $t('Common.Button.Reset').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>
                    </v-card-title>

                    <v-divider />

                    <v-card-text class="px-0">
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <template v-if="!resetTheme">
                            <template v-for="(color, i) in viewData.Theme.active.site">
                              <v-col
                                :key="'site_color_value_' + color.name + '_' + i"
                                class="shrink"
                              >
                                <h5 class="pb-2 grey--text text--darken-1">
                                  {{ color.name }}
                                </h5>
                                <color-picker
                                  v-model="color.value"
                                  :color="color.value"
                                  :disabled="!userCanManage"
                                >
                                  <template
                                    v-if="themeItemIsDirty('site', color)"
                                    #append
                                  >
                                    <v-icon @click="resetThemeItem('site', color)">
                                      mdi-reload
                                    </v-icon>
                                  </template>
                                </color-picker>
                              </v-col>
                            </template>
                          </template>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Website.Field.Theme.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Website.Field.Theme.Control') }}
                      </h5>
                    </v-card-title>

                    <v-divider />

                    <v-card-text class="px-0">
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <template v-if="!resetTheme">
                            <template v-for="(color, i) in viewData.Theme.active.controls">
                              <v-col
                                :key="'controls_color_value_' + color.name + '_' + i"
                                class="shrink"
                              >
                                <h5 class="pb-2 grey--text text--darken-1">
                                  {{ color.name }}
                                </h5>
                                <color-picker
                                  v-model="color.value"
                                  :color="color.value"
                                  :disabled="!userCanManage"
                                >
                                  <template
                                    v-if="themeItemIsDirty('controls', color)"
                                    #append
                                  >
                                    <v-icon @click="resetThemeItem('controls', color)">
                                      mdi-reload
                                    </v-icon>
                                  </template>
                                </color-picker>
                              </v-col>
                            </template>
                          </template>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  v-if="userIsSuperAdmin"
                  cols="12"
                >
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark
                      flat
                    >
                      <v-icon>mdi-palette</v-icon>
                      <v-toolbar-title>Advanced Color Settings</v-toolbar-title>

                      <v-spacer />

                      <v-btn
                        v-if="userCanManage"
                        :disabled="viewData===null || isSaving"
                        class="blue white--text elevation-0"
                        @click="resetAdvancedTheme"
                      >
                        {{ $t('Common.Button.Reset').toLocaleUpperCase($i18n.locale) }}
                      </v-btn>
                    </v-toolbar>

                    <v-sheet class="pa-4 mb-3 primary lighten-2">
                      <v-container fluid>
                        <v-row dense>
                          <v-col>
                            <v-text-field
                              v-model="advancedThemeSearch"
                              label="Search Advanced Color Settings"
                              dark
                              hide-details
                              flat
                              solo-inverted
                              clearable
                            />
                          </v-col>

                          <v-col class="shrink text-no-wrap">
                            <v-switch
                              v-model="advancedThemeFilterDirty"
                              class="d-inline-flex- my-2 ml-2"
                              label="Show Only Changes"
                              color="orange"
                              dark
                              hide-details
                              dense
                              inset
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-sheet>

                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-card-text style="height: 460px; overflow-y: auto;">
                          <v-treeview
                            ref="tree"
                            :active.sync="advancedThemeActiveItem"
                            :items="advancedThemeTreeItems"
                            :open.sync="advancedThemeOpen"
                            :search="advancedThemeSearchFiltered"
                            :filter="advancedThemeFilter"
                            return-object
                            open-on-click
                            activatable
                            transition
                            class="mb-2 text-capitalize"
                          >
                            <template #prepend="{item}">
                              <v-badge
                                :value="advancedThemeItemIsDirty(item)"
                                :color="viewData.Theme.active.advanced[item.id]"
                                offset-x="-10"
                                offset-y="0"
                                bordered
                                inline
                                dot
                                dark
                              />
                            </template>
                          </v-treeview>
                        </v-card-text>
                      </v-col>

                      <v-divider vertical />

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-card-text>
                          <div
                            v-if="!advancedThemeSelectedItem"
                            class="text-h6 font-weight-light grey--text pa-4 text-center"
                          >
                            Select a color setting
                          </div>

                          <div
                            v-else
                            class="text-h6 font-weight-light grey--text pa-4 text-center"
                          >
                            <h5 class="pb-2 grey--text text--darken-1 text-left">
                              {{ advancedThemeSelectedItem.name | capitalize }}
                              <div class="text-capitalize text-caption text-left">
                                {{ advancedThemeSelectedItem.id.replaceAll('.', ' > ') }}
                              </div>
                            </h5>

                            <color-picker
                              v-if="!clearColorVal"
                              v-model="viewData.Theme.active.advanced[advancedThemeSelectedItem.id]"
                              :color="viewData.Theme.active.advanced[advancedThemeSelectedItem.id]"
                              :disabled="!userCanManage"
                            >
                              <template
                                v-if="advancedThemeItemIsDirty(advancedThemeSelectedItem)"
                                #append
                              >
                                <v-icon @click="resetAdvancedThemeItem(advancedThemeSelectedItem)">
                                  mdi-reload
                                </v-icon>
                              </template>
                            </color-picker>
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>

      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveWebsiteClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>

    <page-edit-dialog
      :page-data="selectedPage"
      :visible.sync="pageEditDialogVisible"
    />
  </v-card>
</template>

<script>
import * as QRCode                          from 'easyqrcodejs'
import PageEditDialog                       from './website/PageEditDialog'
import ColorPicker                          from '../common/color/ColorPicker'
import AppData                              from '../../mixins/appdata'
import Auth                                 from '../../mixins/auth'
import ImageCropSelector                    from '@/components/common/ImageCropSelector'
import { advancedThemeTreeItems }           from '@/components/settings/website/advancedThemeTreeItems'
import { advancedDotNotationItemsDefaults } from '@/components/settings/website/advancedDotNotationItemsDefaults'

export default {
  components: {
    ColorPicker,
    PageEditDialog,
    ImageCropSelector
  },
  mixins: [AppData, Auth],
  data () {
    return {
      qrCodeDownloadImageSrc: {
        catalog: '',
        home   : ''
      },
      qrCatalog               : null,
      qrHome                  : null,
      pageEditDialogVisible   : false,
      selectedPage            : null,
      resetTheme              : false,
      clearColorVal           : false,
      isSaving                : false,
      tab                     : null,
      viewData                : null,
      advancedThemeActiveItem : [],
      advancedThemeSearch     : null,
      advancedThemeFilterDirty: false,
      advancedThemeOpen       : [],
      MenuListItemImagePos    : {
        Left : false,
        Right: false
      },
      SocialDefaults: [
        {
          name: 'Trip Advisor',
          icon: 'fab fa-tripadvisor'
        },
        {
          name: 'Facebook',
          icon: 'fab fa-facebook-square'
        },
        {
          name: 'Instagram',
          icon: 'fab fa-instagram'
        },
        {
          name: 'Twitter',
          icon: 'fab fa-twitter-square'
        },
        {
          name: 'LinkedIn',
          icon: 'fab fa-linkedin'
        },
        {
          name: 'Youtube',
          icon: 'fab fa-youtube-square'
        },
        {
          icon: 'fab fa-foursquare',
          name: 'Foursquare'
        }
      ]
    }
  },
  computed: {
    advancedThemeSearchFiltered () {
      this.advancedThemeActiveItem = []

      const searchStr = this.advancedThemeSearch || ''
      if (this.advancedThemeFilterDirty) return searchStr + ' '

      return searchStr
    },

    advancedThemeTreeItems () {
      return advancedThemeTreeItems
    },

    advancedDotNotationItemsDefaults () {
      return advancedDotNotationItemsDefaults
    },

    advancedThemeSelectedItem () {
      if (!this.advancedThemeActiveItem.length) return null

      this.clearColorVal = true
      this.$nextTick(() => {
        this.clearColorVal = false
      })

      return this.advancedThemeActiveItem[0] ?? null
    },

    advancedThemeFilter () {
      return (item, search, textKey) => {
        const searchArr = (search?.split(' ') || []).map(item => item.toLowerCase())
        const itemKeywordsArr = item?.id?.toLowerCase()?.replaceAll('.', ' ') || ''

        if (this.advancedThemeFilterDirty) return searchArr.every(item => itemKeywordsArr.includes(item)) && this.advancedThemeItemIsDirty(item)

        return searchArr.every(item => itemKeywordsArr.includes(item))
      }
    }
  },
  watch: {
    'viewData.SelectedLanguages': function (newVal) {
      if (newVal.length === 1) {
        this.viewData.DefaultLanguage = newVal[0]
      } else if (newVal.length === 0) {
        this.viewData.DefaultLanguage = null
      }
    },
    'viewData.QrCodes.catalog.Options': {
      handler: function () {
        this.initQrCatalogCode()
      },
      deep: true
    },
    'viewData.QrCodes.home.Options': {
      handler: function () {
        this.initQrHomeCode()
      },
      deep: true
    },
    advancedThemeFilterDirty (newVal) {
      // if (this.$refs.tree) this.$refs.tree.updateAll(newVal)
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Website.Get, this.onGetWebsiteResult)
    this.$bus.$on(window.SocketCommand.Settings.Website.GetPage, this.onEditPageResult)
    this.$bus.$on(window.SocketCommand.Settings.Website.Save, this.onSaveSetting)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Website.Get, this.onGetWebsiteResult)
    this.$bus.$off(window.SocketCommand.Settings.Website.GetPage, this.onEditPageResult)
    this.$bus.$off(window.SocketCommand.Settings.Website.Save, this.onSaveSetting)
    if (this.qrCatalog) {
      this.qrHome.clear()
      this.qrCatalog = null
    }
    if (this.qrHome) {
      this.qrHome.clear()
      this.qrHome = null
    }
  },
  methods: {
    onMenuListItemImagePosLeftChange (value) {
      this.MenuListItemImagePos.Right = !value
      this.viewData.MenuListItemImageLeft = this.MenuListItemImagePos.Left
    },
    onMenuListItemImagePosRightChange (value) {
      this.MenuListItemImagePos.Left = !value
      this.viewData.MenuListItemImageLeft = this.MenuListItemImagePos.Left
    },

    initQrCatalogCode () {
      if (this.qrCatalog) this.qrCatalog.clear()
      if (this.$refs.catalog) {
        this.qrCatalog = new QRCode(this.$refs.catalog[0], {
          text               : this.viewData.QrCodes.catalog.Url,
          colorDark          : this.viewData.QrCodes.catalog.Options.ColorDark,
          colorLight         : this.viewData.QrCodes.catalog.Options.ColorLight,
          PO                 : this.viewData.QrCodes.catalog.Options.ColorControlOuter,
          PI                 : this.viewData.QrCodes.catalog.Options.ColorControlInner,
          AO                 : this.viewData.QrCodes.catalog.Options.ColorControlOuter,
          AI                 : this.viewData.QrCodes.catalog.Options.ColorControlInner,
          dotScale           : this.viewData.QrCodes.catalog.Options.DotScale,
          logo               : this.viewData.QrCodes.catalog.Options.Logo && this.getContrastLogo(this.LOGO_TYPE.QRCODE, this.viewData.QrCodes.catalog.Options.ColorLight) ? this.getContrastLogo(this.LOGO_TYPE.QRCODE, this.viewData.QrCodes.catalog.Options.ColorLight).encoded : undefined,
          logoBackgroundColor: this.viewData.QrCodes.catalog.Options.ColorLight,
          correctLevel       : QRCode.CorrectLevel.H, // L, M, Q, H
          quietZoneColor     : 'transparent',
          quietZone          : 20,
          width              : 1024,
          height             : 1024,
          onRenderingEnd     : this.onQrCodeCatalogInit
        })
      }
    },

    initQrHomeCode () {
      if (this.qrHome) this.qrHome.clear()
      if (this.$refs.home) {
        this.qrHome = new QRCode(this.$refs.home[0], {
          text               : this.viewData.QrCodes.home.Url,
          colorDark          : this.viewData.QrCodes.home.Options.ColorDark,
          colorLight         : this.viewData.QrCodes.home.Options.ColorLight,
          PO                 : this.viewData.QrCodes.home.Options.ColorControlOuter,
          PI                 : this.viewData.QrCodes.home.Options.ColorControlInner,
          AO                 : this.viewData.QrCodes.home.Options.ColorControlOuter,
          AI                 : this.viewData.QrCodes.home.Options.ColorControlInner,
          dotScale           : this.viewData.QrCodes.home.Options.DotScale,
          logo               : this.viewData.QrCodes.home.Options.Logo && this.getContrastLogo(this.LOGO_TYPE.QRCODE, this.viewData.QrCodes.home.Options.ColorLight) ? this.getContrastLogo(this.LOGO_TYPE.QRCODE, this.viewData.QrCodes.home.Options.ColorLight).encoded : undefined,
          logoBackgroundColor: this.viewData.QrCodes.home.Options.ColorLight,
          correctLevel       : QRCode.CorrectLevel.H, // L, M, Q, H
          quietZoneColor     : 'transparent',
          quietZone          : 20,
          width              : 1024,
          height             : 1024,
          onRenderingEnd     : this.onQrCodeHomeInit
        })
      }
    },

    onQrCodeCatalogInit (qrCodeOptions, base64DataURL) {
      this.qrCodeDownloadImageSrc.catalog = base64DataURL
    },
    onQrCodeHomeInit (qrCodeOptions, base64DataURL) {
      this.qrCodeDownloadImageSrc.home = base64DataURL
    },

    getData () {
      window.callAS(window.SocketCommand.Settings.Website.Get, {})
    },
    onGetWebsiteResult (result) {
      if (!result.Theme.active.site.find(item => item.id === 'gfp-delivery-button')) {
        result.Theme.active.site.push(
          {
            id   : 'gfp-delivery-button',
            name : 'Delivery Button',
            value: '#9c27b0'
          }
        )
        result.Theme.themes[0].site.push(
          {
            id   : 'gfp-delivery-button',
            name : 'Delivery Button',
            value: '#9c27b0'
          }
        )
      }
      if (!result.Theme.active.site.find(item => item.id === 'gfp-collection-button')) {
        result.Theme.active.site.push(
          {
            id   : 'gfp-collection-button',
            name : 'Collection Button',
            value: '#1565c0'
          }
        )
        result.Theme.themes[0].site.push(
          {
            id   : 'gfp-collection-button',
            name : 'Collection Button',
            value: '#1565c0'
          }
        )
      }

      const advancedDotNotationItemsDefaults = JSON.parse(JSON.stringify(this.advancedDotNotationItemsDefaults))
      const themeActiveAdvanced = JSON.parse(JSON.stringify(result?.Theme?.active?.advanced || {}))
      result.Theme.active.advanced = { ...advancedDotNotationItemsDefaults, ...themeActiveAdvanced }

      result.Social = [...this.SocialDefaults].map(s => result.Social.find(obj => obj.name === s.name) || s)
      if (!result?.HeaderImagesSize?.MinHeight) result.HeaderImagesSize = { MinHeight: 65 }

      this.viewData = result

      this.MenuListItemImagePos.Left = result.MenuListItemImageLeft
      this.MenuListItemImagePos.Right = !result.MenuListItemImageLeft

      this.$nextTick(() => {
        this.initQrHomeCode()
        this.initQrCatalogCode()
      })
    },

    onSaveWebsiteClick () {
      this.isSaving = true

      const saveData = JSON.parse(JSON.stringify(this.viewData, (key, value) => key === 'croppa' ? undefined : value))

      saveData.HeaderImages.forEach(headerImage => this.onPhotoChange(headerImage))

      for (let i = 0; i < saveData.HeaderImages.length; i++) {
        delete saveData.HeaderImages[i].croppa
      }

      // Reject Unchanged Advanced Theme Colors
      const propNames = Object.getOwnPropertyNames(saveData.Theme.active.advanced)
      for (let i = 0; i < propNames.length; i++) {
        const propName = propNames[i]
        if (saveData.Theme.active.advanced[propName] === advancedDotNotationItemsDefaults[propName]) {
          delete saveData.Theme.active.advanced[propName]
        }
      }

      if (isNaN(saveData.ShowMenuCategoriesLimit)) saveData.ShowMenuCategoriesLimit = parseInt(saveData.ShowMenuCategoriesDefaultLimit) || 350

      window.callAS(window.SocketCommand.Settings.Website.Save, { Website: saveData }, '', 300000)
    },

    onSaveSetting (response) {
      this.isSaving = false
      if (response && response.status) {
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: response.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Website.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    advancedThemeItemIsDirty (item) {
      if (!item?.id) return false

      return this.viewData?.Theme?.active?.advanced[item.id] !== advancedDotNotationItemsDefaults[item.id]
    },

    resetAdvancedThemeItem (item) {
      if (!item?.id) return

      this.viewData.Theme.active.advanced[item.id] = advancedDotNotationItemsDefaults[item.id]

      this.clearColorVal = true
      this.$nextTick(() => {
        this.clearColorVal = false
      })
    },

    resetAdvancedTheme () {
      this.clearColorVal = true
      const advancedDotNotationItemsDefaults = JSON.parse(JSON.stringify(this.advancedDotNotationItemsDefaults))
      const themeActiveAdvanced = JSON.parse(JSON.stringify(this.viewData.Theme?.active?.advanced || {}))
      this.viewData.Theme.active.advanced = { ...themeActiveAdvanced, ...advancedDotNotationItemsDefaults }
      this.$nextTick(() => {
        this.clearColorVal = false
      })
    },

    themeItemIsDirty (type, item) {
      if (!type || !item) return false

      const themeDefault = this.viewData.Theme.themes.find(t => t.id === this.viewData.Theme.active.id)
      const color = themeDefault[type]?.find(color => color.id === item.id)

      return color?.value !== item?.value
    },

    resetThemeItem (type, item) {
      if (!type || !item) return

      const themeDefault = this.viewData.Theme.themes.find(t => t.id === this.viewData.Theme.active.id)
      const themeActive = this.viewData.Theme.active
      const defaultColorObj = themeDefault[type]?.find(color => color.id === item.id)
      const activeColorObj = themeActive[type]?.find(color => color.id === item.id)

      this.resetTheme = true
      activeColorObj.value = defaultColorObj.value
      this.$nextTick(() => {
        this.resetTheme = false
      })
    },

    onResetThemeClick () {
      const data = JSON.parse(JSON.stringify(this.viewData.Theme))
      const theme = this.viewData.Theme.themes.find(t => t.id === data.active.id)
      if (theme) {
        this.resetTheme = true
        data.active = { ...data.active, ...theme }
        this.$set(this.viewData, 'Theme', data)
        this.$nextTick(() => {
          this.resetTheme = false
        })
      }
    },

    onPhotoChange (headerImage) {
      if (headerImage.croppa && headerImage.croppa.getChosenFile() && !headerImage.Image) {
        headerImage.tmpImage = headerImage.croppa.getChosenFile()
        headerImage.tmpImageBase64 = {
          img : headerImage.croppa.generateDataUrl(),
          mime: headerImage.croppa.getChosenFile().type,
          ext : headerImage.croppa.getChosenFile().name.split('.')[1]
        }
      }
    },
    onPhotoRemoved (headerImage) {
      headerImage.Image = ''
      headerImage.tmpImage = null
      headerImage.tmpImageBase64 = null
    },

    onEditClick (page, isManager = false) {
      window.callAS(window.SocketCommand.Settings.Website.GetPage, {
        Page  : { Name: page.Name },
        Manage: isManager
      })
    },
    onEditPageResult (data) {
      this.selectedPage = data
      this.pageEditDialogVisible = true
    }
  }
}
</script>

<style scoped>
/deep/ .picture-input .picture-inner-text {
  font-size : 12px !important;
}

/deep/ .qr-image img {
  max-width : 100%;
  width     : 300px;
  height    : 300px;
}

/deep/ .qr-image canvas {
  max-width : 100%;
  width     : 300px;
  height    : 300px;
}

/deep/ .croppa-container canvas {
  width  : 100% !important;
  height : auto !important;
}

</style>
