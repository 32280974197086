<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card v-if="selectedOrder">
        <v-toolbar
          flat
          class="pink accent-3"
          height="80"
          max-height="80"
        >
          <v-avatar color="pink darken-3">
            <v-icon
              v-if="selectedOrder.SourceTypeId<100"
              color="white"
              size="28"
            >
              {{ getOrderSourceTypeById(selectedOrder.SourceTypeId).logo }}
            </v-icon>

            <img
              v-else
              :src="getOrderSourceTypeById(selectedOrder.SourceTypeId).logo"
              style="width: 32px; height: auto;"
            >
          </v-avatar>

          <v-toolbar-title class="pl-3 white--text">
            <div class="body-3">
              {{ $t('Order.OrderChangeStatusDialog.CancelOrder.Title') }}
            </div>

            <div class="text-caption red--text text--lighten-4">
              {{ parseInt(selectedOrder.SourceTypeId) >= 100 ? getOrderSourceTypeById(selectedOrder.SourceTypeId).desc : getOrderSourceTypeLangById(selectedOrder.SourceTypeId).Desc }} #{{ selectedOrder.Id }}
            </div>
          </v-toolbar-title>

          <v-spacer />
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-container
            class="pa-1"
            fluid
          >
            <v-row dense>
              <v-col
                class="mb-1"
                cols="12"
              >
                <span class="text-subtitle-1">
                  {{ $t('Order.OrderChangeStatusDialog.CancelOrder.Subtitle.Order') }}
                  <strong>#{{ selectedOrder.Id }}</strong> {{ $t('Order.OrderChangeStatusDialog.CancelOrder.Subtitle.CancelPermanently') }}
                </span>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col
                sm="8"
                cols="12"
              >
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row no-gutters>
                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderChangeStatusDialog.Field.Name').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      {{ (parseInt(selectedOrder.CustomerId) !== 0 || selectedOrder.FullName !== 'null null') ? selectedOrder.FullName : $t('Customer.CustomerType.Visitor') }}
                    </v-col>

                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderChangeStatusDialog.Field.Telephone').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      {{ selectedOrder.Telephone }}
                    </v-col>

                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderChangeStatusDialog.Field.Time').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      {{ selectedOrder.TimeAdded }}
                    </v-col>

                    <v-col
                      sm="4"
                      cols="4"
                    >
                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                        {{ $t('Order.OrderChangeStatusDialog.Field.Total').toLocaleUpperCase($i18n.locale) }}
                      </span>
                    </v-col>
                    <v-col
                      class="grey--text text--darken-1"
                      sm="8"
                      cols="8"
                    >
                      <span
                        class="text-subtitle-1 font-weight-bold"
                        :class="isDarkMode ? 'white--text' : 'black--text'"
                      >{{ selectedOrder.Price | currency }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col
                sm="4"
                cols="12"
              >
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row dense>
                    <v-row dense>
                      <v-col cols="12">
                        <order-status-chip
                          :order="selectedOrder"
                          min-width="170"
                          dense
                        />
                      </v-col>

                      <v-col cols="12">
                        <order-type-chip
                          :order="selectedOrder"
                          min-width="170"
                          dense
                          label
                        />
                      </v-col>

                      <v-col cols="12">
                        <order-payments-chip
                          :item="selectedOrder"
                          min-width="170"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <v-col
                :class="isDarkMode ? 'red lighten-2' : 'red lighten-4'"
                cols="12"
              >
                <div
                  class="py-2 px-4 text-caption"
                  style="display: table;"
                >
                  <div style="display: table-row">
                    <div
                      class="font-weight-bold pr-2"
                      style="display: table-cell; vertical-align: top;"
                    >
                      {{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:
                    </div>

                    <div style="display: table-cell; vertical-align: middle;">
                      <div
                        v-if="selectedOrder.Reserved > 0 && selectedOrder.Reserved !== parseInt(posUser.staff_id)"
                        style="vertical-align: middle;"
                      >
                        <v-icon
                          color="orange"
                          class="pl-0 mr-1"
                          size="18"
                        >
                          lock
                        </v-icon>
                        &#8226; <span>{{ $t('Order.OrdersSaved.ListView.EditedBy') }} {{ selectedOrder.ReservedName }}</span> <br class="hidden-xs-only">
                      </div>

                      <div :class="{'pl-6': selectedOrder.Reserved > 0 && selectedOrder.Reserved !== parseInt(posUser.staff_id)}">
                        <div v-html="`&#8226; ${$t('Order.OrderChangeStatusDialog.Notification.Title')}`" />

                        <div
                          v-if="selectedOrderIsRefundable"
                          class="mt-1"
                          v-html="`&#8226; ${$t('Order.OrderChangeStatusDialog.Notification.Amount')} ${$options.filters.currency(selectedOrderRefundableAmount) } ${$t('Order.OrderChangeStatusDialog.Notification.Refund')}`"
                        />

                        <div
                          v-if="selectedOrderHasReceipt"
                          class="mt-1"
                          v-html="`&#8226; ${selectedOrder.ReceiptType === 'receipt' ? $t('Order.OrderChangeStatusDialog.Notification.Receipt') : $t('Order.OrderChangeStatusDialog.Notification.Invoice')}`"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="green--text text--darken-1"
            text
            outlined
            @click.native="isVisible = false"
          >
            {{ $t('Common.Button.No').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            class="pink darken-1 white--text"
            text
            @click.exact="cancelOrder()"
            @click.native="isVisible = false"
          >
            {{ $t('Common.Button.Yes').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderStatusColor  from '@/mixins/orders/orderStatus'
import OrderType         from '@/mixins/orders/orderType'
import OrderTypeTime     from '@/mixins/orders/orderTypeTime'
import SourceType        from '@/mixins/orders/sourceType'
import AppData           from '@/mixins/appdata'
import Auth              from '@/mixins/auth'
import PaymentTypeEnum   from '@/api/Enums/PaymentTypeEnum'
import OrderPaymentsChip from '@/components/orders/common/OrderPaymentsChip.vue'
import OrderTypeChip     from '@/components/orders/common/OrderTypeChip.vue'
import OrderStatusChip   from '@/components/orders/common/OrderStatusChip.vue'

export default {
  components: {
    OrderStatusChip,
    OrderTypeChip,
    OrderPaymentsChip
  },
  mixins: [AppData, Auth, OrderStatusColor, OrderType, SourceType, OrderTypeTime],
  props : {
    cancelOrderConfirmDialogVisible: {
      type   : Boolean,
      default: false
    },
    selectedOrder: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    orderHasOnlineCardPayments () {
      return this.selectedOrder?.Payment === PaymentTypeEnum.VIVA_3DS || this.selectedOrder?.Payment === PaymentTypeEnum.VIVA_SMART || this.selectedOrder?.Payment === PaymentTypeEnum.EVERYPAY || this.selectedOrder?.Payment === PaymentTypeEnum.STRIPE
    },

    orderRefundableTransactions () {
      return this.selectedOrder?.PaymentTransactions?.filter(payment => payment.Status === 'APPROVED' && payment.PaymentType === PaymentTypeEnum.CARD) || []
    },

    orderHasPaymentMethodRefundable () {
      return this.orderRefundableTransactions.length > 0
    },

    selectedOrderIsRefundable () {
      return this.orderHasPaymentMethodRefundable || this.orderHasOnlineCardPayments || (this.selectedOrder?.hasOwnProperty('CanDelete') && !this.selectedOrder.CanDelete)
    },

    selectedOrderRefundableAmount () {
      if (this.orderHasOnlineCardPayments) return this.selectedOrder?.Price ?? 0

      if (this.orderHasPaymentMethodRefundable) return this.orderRefundableTransactions.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0

      return 0
    },

    selectedOrderHasReceipt () {
      return !!this.selectedOrder?.ReceiptId
    },

    isVisible: {
      get () {
        return this.cancelOrderConfirmDialogVisible
      },
      set (val) {
        this.$emit('update:cancelOrderConfirmDialogVisible', val)
      }
    }
  },
  methods: {
    cancelOrder () {
      if (this.$route.name === 'OrdersSaved') {
        window.callAS(window.SocketCommand.Cart.Destroy, { CartId: this.selectedOrder.Id })
      } else {
        window.callAS(window.SocketCommand.Order.Cancel, {
          order_id       : this.selectedOrder.Id || null,
          status_id      : this.selectedOrder.StatusId || null,
          receipt_id     : this.selectedOrder.ReceiptId || null,
          order_type_time: this.selectedOrder.OrderTypeTime
        }, window.SocketCommand.Order.StatusUpdated)
        this.$bus.$emit('order-canceled', this.selectedOrder)
      }
    }
  }
}
</script>
