import Vue from 'vue'

let _el = null
let _height = 0
let _binding = 0

// Register a global custom directive called v-data-table-fixed
Vue.directive('datatablefixed', {
  bind (el, binding, vnode) {
    _el = el
    _binding = binding
    if (typeof binding.value === 'object') {
      if (binding.value.height) _height = binding.value.height
    }

    fixTable()

    el.style.backfaceVisibility = 'hidden'
    el.classList.add('fixed-header')
    el.classList.add('v-table__overflow')

    window.addEventListener('resize', onResize)
  },

  inserted (el, binding) {
    _el = el
    _el = el
    _binding = binding
    if (typeof binding.value === 'object') {
      if (binding.value.height) _height = binding.value.height
    }
    fixTable()
  },

  update (el, binding) {
    _el = el
    _el = el
    _binding = binding
    if (typeof binding.value === 'object') {
      if (binding.value.height) _height = binding.value.height
    }
    fixTable()
  },

  unbind (el) {
    window.removeEventListener('resize', onResize)
    _el = null
    _height = 0
    _binding = 0
  }
})

function fixTable () {
  const toolbar = document.getElementById('appToolbar')
  const appSubToolbar = document.getElementById('appSubToolbar')
  const footer = document.getElementById('appFooter')
  const cardTitleDocument = document.getElementsByClassName('v-card__title')
  const cardTitleElement = (_el.parentElement ? _el.parentElement.getElementsByClassName('v-card__title') : [])
  const cardTitle = cardTitleElement.length >= 1 ? cardTitleElement[0] || null : cardTitleDocument[0] || null

  const cardTitleHeight = cardTitle ? cardTitle.offsetHeight : 98
  const cardHeight = (_binding.arg === 'offset' ? parseInt(_binding.value) : cardTitleHeight)
  const toolbarHeight = toolbar ? toolbar.offsetHeight : 0
  const footerHeight = footer ? footer.offsetHeight : 0
  const appSubToolbarHeight = appSubToolbar ? appSubToolbar.offsetHeight : 0
  const tableBodyHeight = cardHeight + toolbarHeight + footerHeight + appSubToolbarHeight + _height

  _el.style.height = _el.style.maxHeight = `calc(100vh - ${ tableBodyHeight }px)`
}

function onResize (e) {
  fixTable()
}

export default 'DataTableFixed'
