import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class MessageValidator extends BaseValidator {
  rules = {
    Id: {
      required: true,
      type    : 'number'
    },
    To: {
      required: true,
      type    : 'array'
    },
    Body: {
      required: true,
      type    : 'string'
    },
    Scheduled: {
      required: false,
      type    : 'mysqlDateTime'
    },
    Active: {
      required: true,
      type    : 'boolean'
    },
    Important: {
      required: true,
      type    : 'boolean'
    },
    Read: {
      required: true,
      type    : 'boolean'
    },
    Type: {
      required    : true,
      type        : 'object',
      typeSelected: (v) => v.Id > -1
    },
    'Type.Name': {
      required  : true,
      betweenLen: [2, 255],
      type      : 'string'
    },
    'Type.Subject': {
      required  : true,
      betweenLen: [2, 255],
      type      : 'string'
    }
  }

  errorMessages = {
    el: {
      typeSelected: 'Παρακαλώ επιλέξτε τύπο μηνύματος'
    },
    en: {
      typeSelected: 'Please selected message type'
    }
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
