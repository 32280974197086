<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="$t('Restaurant.Menu.Toolbar.Button.Export.Color')">
            <v-icon dark>
              {{ $t('Restaurant.Menu.Toolbar.Button.Export.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Restaurant.Menu.Toolbar.Button.Export.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Restaurant.Menu.Toolbar.Button.Export.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="dataForm"
            v-model="dataFormValid"
            @submit.prevent
          >
            <v-container
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <h5 class="mb-1">
                    {{ $t('Restaurant.CategoryAddDialog.Field.Catalogs.Hint') }}
                  </h5>

                  <v-select
                    v-model="model.CatalogId"
                    :rules="validationRules.dataForm.CatalogId"
                    :items="productCatalogs"
                    :disabled="!!catalog"
                    :label="$t('Restaurant.CategoryAddDialog.Field.Catalogs.Label')"
                    :prepend-icon="$t('Restaurant.CategoryAddDialog.Field.Catalogs.Icon')"
                    hide-details="auto"
                    item-text="Name"
                    item-value="Id"
                    small-chips
                    clearable
                    required
                    filled
                    chips
                  >
                    <template #selection="{item}">
                      <v-chip
                        small
                        label
                        :color="`#${item.Color}`"
                        dark
                      >
                        <span>{{ item.Name }}</span>
                      </v-chip>
                    </template>

                    <template #item="data">
                      <v-list-item-avatar
                        :color="`#${data.item.Color}`"
                        :size="28"
                      />

                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.Name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.Description | truncate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Export.Title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'MenuExportDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    catalog: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading      : false,
      dataFormValid  : false,
      model          : null,
      validationRules: {
        dataForm: {
          CatalogId: [v => !!v || this.$t('Restaurant.CategoryAddDialog.Field.Catalogs.Hint')]
        }
      }
    }
  },
  computed: {
    productCatalogs () {
      return this.appConfig?.LOCATION_DATA?.Catalogs || []
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.initData()

        this.$nextTick(() => {
          if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
        })
      }
    }
  },

  created () {
    // console.log('1. created');
    this.initData()
  },

  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Menu.Export, this.onExportResult)
  },

  updated () {
    // console.log('3. updated');
  },

  beforeDestroy () {
    // console.log('4. destroyed');
  },

  destroyed () {
    this.$bus.$off(window.SocketCommand.Menu.Export, this.onExportResult)
  },

  methods: {
    initData () {
      this.model = {
        CatalogId: this.catalog?.Id || null
      }
    },

    onSaveButtonClick () {
      this.$refs.dataForm.validate()

      if (this.dataFormValid) {
        this.isLoading = true

        const payload = JSON.parse(JSON.stringify(this.model))
        window.callAS(window.SocketCommand.Menu.Export, payload)
      }
    },
    onExportResult (response) {
      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Restaurant.Menu.Toolbar.Button.Export.SuccessMessage', { email: this.posUser?.staff_email || '' }),
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
        this.onCancelButtonClick()
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
        this.isLoading = false
      }
    },

    onCancelButtonClick () {
      if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      this.initData()
      this.isVisible = false
      this.isLoading = false
    }

  }
}
</script>
