<template>
  <div v-if="item">
    <template v-for="(payment, index) in appConfig.PAYMENT_TYPES">
      <template v-if="parseInt(item.SourceTypeId) === SourceTypeEnum.TABLE && item.Payments">
        <v-tooltip
          v-if="(item.Payments.cod || (!item.Payments.cod && !item.Payments.card)) && payment.code === PaymentTypeEnum.CASH"
          :key="`payment-type-${index}`"
          :color="payment.color2"
          right
        >
          <template #activator="{on}">
            <v-badge
              :color="badgeColor"
              :dot="badgeDot"
              :value="canShowBadge(payment.code)"
              overlap
              right
            >
              <template #badge>
                <v-icon
                  dark
                  small
                >
                  {{ badgeIcon }}
                </v-icon>
              </template>

              <v-icon
                :color="payment.color1"
                :size="dense ? 22 : 28"
                :class="chip ? '' : 'mx-1'"
                v-on="on"
              >
                {{ appCurrency.Icon }}
              </v-icon>
            </v-badge>
          </template>

          <span>
            {{ item.Payments.cod | currency }} {{ $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) }}
          </span>
        </v-tooltip>

        <v-tooltip
          v-if="item.Payments.card && payment.code === PaymentTypeEnum.CARD"
          :key="`payment-type-${index}`"
          :color="payment.color2"
          right
        >
          <template #activator="{on}">
            <v-badge
              :color="badgeColor"
              :dot="badgeDot"
              :value="canShowBadge(payment.code)"
              overlap
              right
            >
              <template #badge>
                <v-icon
                  dark
                  small
                >
                  {{ badgeIcon }}
                </v-icon>
              </template>

              <v-icon
                :color="payment.color1"
                :size="dense ? 22 : 28"
                :class="chip ? '' : 'mx-1'"
                v-on="on"
              >
                {{ payment.icon }}
              </v-icon>
            </v-badge>
          </template>

          <span>
            {{ item.Payments.card | currency }} {{ $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) }}
          </span>
        </v-tooltip>
      </template>

      <template v-else>
        <template v-if="hasPaymentTransactions">
          <template v-for="(transaction, idx) in paymentTransactions">
            <v-tooltip
              v-if="transaction.PaymentType === payment.code"
              :key="`payment-transaction-${index}-${idx}`"
              :color="payment.color2"
              right
            >
              <template #activator="{on}">
                <v-badge
                  :color="badgeColor"
                  :dot="badgeDot"
                  overlap
                  right
                  :value="canShowBadge(payment.code)"
                >
                  <template #badge>
                    <v-icon
                      dark
                      small
                    >
                      {{ badgeIcon }}
                    </v-icon>
                  </template>

                  <v-icon
                    :color="payment.color1"
                    :class="chip ? '' : 'mx-1'"
                    :size="dense ? 22 : 28"
                    v-on="on"
                  >
                    {{ payment.code === PaymentTypeEnum.CASH ? appCurrency.Icon : payment.icon }}
                  </v-icon>
                </v-badge>
              </template>

              <span>
                {{ transaction.Amount | currency }} {{ payment.code === PaymentTypeEnum.CASH ? $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) : payment.code === PaymentTypeEnum.CARD ? $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) : payment.name.toLocaleUpperCase($i18n.locale) }}
              </span>
            </v-tooltip>
          </template>
        </template>

        <template v-else>
          <v-tooltip
            v-if="item.Payment === payment.code"
            :key="`payment-type-${index}`"
            :color="payment.color2"
            right
          >
            <template #activator="{on}">
              <v-badge
                :color="badgeColor"
                :dot="badgeDot"
                overlap
                right
                :value="canShowBadge(payment.code)"
              >
                <template #badge>
                  <v-icon
                    dark
                    small
                  >
                    {{ badgeIcon }}
                  </v-icon>
                </template>

                <v-icon
                  :color="payment.color1"
                  :class="chip ? '' : 'mx-1'"
                  :size="dense ? 22 : 28"
                  v-on="on"
                >
                  {{ payment.code === PaymentTypeEnum.CASH ? appCurrency.Icon : payment.icon }}
                </v-icon>
              </v-badge>
            </template>

            <span>
              {{ item.Price | currency }} {{ payment.code === PaymentTypeEnum.CASH ? $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) : payment.code === PaymentTypeEnum.CARD ? $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) : payment.name.toLocaleUpperCase($i18n.locale) }}
            </span>
          </v-tooltip>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import AppData         from '@/mixins/appdata'
import SourceTypeEnum  from '@/api/Enums/SourceTypeEnum'
import PaymentTypeEnum from '@/api/Enums/PaymentTypeEnum'

export default {
  name      : 'OrderPaymentsIcon',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : {
    item: {
      type   : Object,
      default: undefined
    },
    paymentType: {
      type   : String,
      default: ''
    },
    dense: {
      type   : Boolean,
      default: false
    },
    chip: {
      type   : Boolean,
      default: false
    },
    badge: {
      type   : Boolean,
      default: false
    },
    badgeDot: {
      type   : Boolean,
      default: false
    },
    badgePaymentType: {
      type   : Array,
      default: () => PaymentTypeEnum.ALL_TYPES
    },
    badgeColor: {
      type   : String,
      default: 'red'
    },
    badgeIcon: {
      type   : String,
      default: 'priority_high'
    }
  },
  data () {
    return {}
  },
  computed: {
    PaymentTypeEnum () {
      return PaymentTypeEnum
    },
    SourceTypeEnum () {
      return SourceTypeEnum
    },

    hasPaymentTransactions () {
      const paymentTransactions = this.item?.PaymentTransactions || []

      return paymentTransactions.length > 0
    },
    paymentTransactions () {
      const transactions = []
      const paymentTransactions = this.item?.PaymentTransactions || []
      const transactionsGroupedByPaymentType = Object.groupBy(paymentTransactions, trx => trx.PaymentType)

      Object.keys(transactionsGroupedByPaymentType).forEach(key => {
        const transactionsTotal = transactionsGroupedByPaymentType[key]?.reduce((a, b) => a + parseFloat(b.Amount) || 0, 0) || 0

        transactions.push({
          Amount     : transactionsTotal.toFixed(2),
          PaymentType: key
        })
      })

      return transactions
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    canShowBadge (paymentType) {
      return this.badge && this.badgePaymentType?.includes(paymentType)
    }
  }
}
</script>

<style scoped>

</style>
