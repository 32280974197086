import { mergeDeep }                     from '@/lib/helper/helper'
import incomeClassificationRelationsEnum from '@/api/Enums/IncomeClassificationRelationsEnum'
import ReceiptTypeCodeEnum               from '@/api/Enums/ReceiptTypeCodeEnum'

export default {
  data () {
    return {}
  },

  computed: {
    taxesList () {
      return this.appConfig?.TAX?.Taxes || []
    },
    feeCategoryCodeList () {
      return this.appConfig?.TAX?.Classifications?.FeeCategories || []
    },
    incomeClassificationCategoryCodeList () {
      return this.appConfig?.TAX?.Classifications?.Categories || []
    },
    incomeClassificationTypeCodeList () {
      return this.appConfig?.TAX?.Classifications?.Types || []
    },

    defaultTax () {
      return this.appConfig?.TAX?.Defaults?.Taxes || {
        TaxId : 2,
        Tax2Id: 1
      }
    },
    defaultClassification () {
      return mergeDeep({
        ClassificationTypeCode           : 'E3_561_003',
        ClassificationCategoryCode       : 'CATEGORY1_1',
        ClassificationTypeCodeInvoice    : 'E3_561_001',
        ClassificationCategoryCodeInvoice: 'CATEGORY1_1'
      }, this.appConfig?.TAX?.Defaults?.Classifications || {})
    }
  },

  methods: {
    getReceiptIncomeClassificationTypesByCategoryCode (categoryCode) {
      const receiptItems = incomeClassificationRelationsEnum[ReceiptTypeCodeEnum.GREEK_RECEIPT]
      const allowedCategoryItems = receiptItems[categoryCode] || []
      return this.incomeClassificationTypeCodeList.filter(typeItem => allowedCategoryItems.includes(typeItem.Code))
    },

    getInvoiceIncomeClassificationTypesByCategoryCode (categoryCode) {
      const invoiceItems = incomeClassificationRelationsEnum[ReceiptTypeCodeEnum.GREEK_INVOICE]
      const allowedCategoryItems = invoiceItems[categoryCode] || []
      return this.incomeClassificationTypeCodeList.filter(typeItem => allowedCategoryItems.includes(typeItem.Code))
    }
  }
}
