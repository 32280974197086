/**
 * Enum for receipt types codes.
 * @readonly
 * @enum {String} ReceiptTypeCodeEnum
 */

const ReceiptTypeCodeEnum = {
  GREEK_INVOICE              : '1.1', // Τιμολόγιο Πώλησης
  GREEK_INVOICE_CREDIT_1     : '5.1', // Πιστωτικό Τιμολόγιο / Συσχετιζόμενο
  GREEK_INVOICE_CREDIT_2     : '5.2', // Πιστωτικό Τιμολόγιο / Μη Συσχετιζόμενο
  GREEK_RECEIPT              : '11.1', // Απόδειξη Λιανικής Πώλησης
  GREEK_RECEIPT_CREDIT       : '11.4', // Πιστωτικό Στοιχ. Λιανικής
  GREEK_RECEIPT_CASH_POS     : '8.4', // Απόδειξη Είσπραξης POS
  GREEK_RECEIPT_RETURN_POS   : '8.5', // Απόδειξη Επιστροφής POS
  GREEK_DOCUMENT_ORDER       : '8.6', // Δελτίο Παραγγελίας Εστίασης
  GREEK_DOCUMENT_ORDER_CANCEL: '8.61', // Δελτίο Παραγγελίας Ακύρωσης - Internal use only
  GREEK_DOCUMENT_ORDER_RETURN: '8.62' // Δελτίο Παραγγελίας Επιστροφή - Internal use only
}

export default ReceiptTypeCodeEnum
