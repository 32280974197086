<template>
  <div
    v-if="userCanChangeExternalReceipt"
    class="d-inline-block"
  >
    <v-tooltip
      :bottom="tooltipPosition === 'bottom'"
      :top="tooltipPosition === 'top'"
      :left="tooltipPosition === 'left'"
      :right="tooltipPosition === 'right'"
      :disabled="isDisabled"
      :open-delay="tooltipOpenDelay"
      :close-delay="tooltipCloseDelay"
      :color="color"
    >
      <template #activator="{on}">
        <v-badge
          :value="tmpSelected"
          color="green"
          overlap
          right
        >
          <template #badge>
            <v-icon dark>
              check
            </v-icon>
          </template>

          <v-btn
            :class="[btnClass, icon ? color : '']"
            :color="icon ? '' : color"
            :style="btnStyle"
            :loading="loading"
            :dark="dark"
            :icon="icon"
            :small="small"
            :large="large"
            :outlined="outline"
            :text="flat"
            :depressed="depressed"
            :absolute="absolute"
            :disabled="isDisabled"
            :block="block"
            :fixed="fixed"
            :fab="fab"
            v-on="on"
            @click="toggleOrderExternalReceipt"
          >
            <v-icon
              v-if="!hideIcon"
              :small="small"
              :left="!!label && !hideLabel && !icon"
            >
              {{ iconName }}
            </v-icon>
            <span v-if="label && !hideLabel && !icon">{{ label }}</span>
          </v-btn>
        </v-badge>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Auth    from '@/mixins/auth'
import AppData from '@/mixins/appdata'

export default {
  name      : 'ExternalReceiptToggleButton',
  components: {},
  directives: {},
  mixins    : [Auth, AppData],
  props     : {
    tableId: {
      type   : [String, Number],
      default: ''
    },
    cartId: {
      type   : [String, Number],
      default: ''
    },
    timestamp: {
      type   : [String, Number],
      default: ''
    },
    selected: {
      type   : Boolean,
      default: false
    },
    tooltip: {
      type   : String,
      default: function () { return this.$t('Common.Button.ExternalReceipt') }
    },
    label: {
      type   : String,
      default: function () { return this.$t('Common.Button.ExternalReceipt').toLocaleUpperCase(this.$i18n.locale) }
    },
    hideLabel: {
      type   : Boolean,
      default: false
    },
    btnClass: {
      type   : String,
      default: 'white--text'
    },
    btnStyle: {
      type   : String,
      default: ''
    },
    color: {
      type   : String,
      default: 'amber'
    },
    icon: {
      type   : Boolean,
      default: false
    },
    iconName: {
      type   : String,
      default: 'mdi-receipt-text'
    },
    hideIcon: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    },
    large: {
      type   : Boolean,
      default: false
    },
    outline: {
      type   : Boolean,
      default: false
    },
    flat: {
      type   : Boolean,
      default: false
    },
    depressed: {
      type   : Boolean,
      default: false
    },
    absolute: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    block: {
      type   : Boolean,
      default: false
    },
    fixed: {
      type   : Boolean,
      default: false
    },
    fab: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    tooltipPosition: {
      type   : String,
      default: 'bottom'
    },
    tooltipOpenDelay: {
      type   : Number,
      default: 1200
    },
    tooltipCloseDelay: {
      type   : Number,
      default: 200
    },
    disableTooltip: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      loading    : false,
      tmpSelected: false
    }
  },
  computed: {
    isDisabled () {
      return this.disabled || !this.userCanChangeExternalReceipt
    },

    userCanChangeExternalReceipt () {
      return this.userIsWaiter ? !!this.posUser?.Settings?.Tables?.Waiter?.Permissions?.WaiterCanChangeExternalReceipt : !!this.posUserCan('Tables', 'MANAGE')
    }
  },
  watch: {
    selected (newVal) {
      this.tmpSelected = newVal
    }
  },
  beforeCreate () {},
  created () {
    this.tmpSelected = this.selected
    this.$bus.$on(window.SocketCommand.Table.ToggleOrderExternalReceipt, this.onToggleOrderExternalReceiptResult)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Table.ToggleOrderExternalReceipt, this.onToggleOrderExternalReceiptResult)
  },
  destroyed () {},
  methods: {
    toggleOrderExternalReceipt () {
      if (!this.cartId || !this.timestamp) return

      this.loading = true
      window.callAS(window.SocketCommand.Table.ToggleOrderExternalReceipt, {
        TableId  : this.tableId,
        CartId   : this.cartId,
        Timestamp: this.timestamp
      })
    },
    onToggleOrderExternalReceiptResult (data, payload) {
      if (this.cartId !== payload.CartId || this.timestamp !== payload.Timestamp) return

      this.loading = false

      if (data.status === 'success') {
        this.tmpSelected = !this.tmpSelected
      }
    }
  }
}
</script>

<style scoped>

</style>
