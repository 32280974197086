import BaseModel                    from '../../../lib/data/Models/BaseModel'
import CustomerEmailDomainValidator from '../../Validations/settings/CustomerEmailDomainValidator'
import SocketCommand                from '../../SocketCommand'

export default class CustomerEmailDomainModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = rawData.Id || null
    this.Email = rawData.Email || ''
    this.Pin = rawData.Pin || ''
    this.To = rawData.To || ''
    this.Locale = rawData.Locale || 'el'
    this.Firstname = rawData.Firstname || ''
    this.Lastname = rawData.Lastname || ''
    this.Subdomain = rawData.Subdomain || ''

    this.validator = new CustomerEmailDomainValidator(this)
  }

  boot () {
    super.boot()

    this.guarded = []
    this.fetchCommand = SocketCommand.Settings.Domains.Customer.Get
    this.saveCommand = SocketCommand.Settings.Domains.Customer.SendEmail
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */
}
