<template>
  <v-card flat>
    <v-card-title class="py-0 px-0">
      <v-toolbar
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        class="filter-toolbar"
        flat
      >
        <v-btn
          v-if="posUserHasComponentPermission('Orders', 'ADD') && posUserHasComponentPermission('Pos', 'ACCESS')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'Pos', exact: true}"
          class="primary--text elevation-0 ma-1"
          text
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Order.OrdersAll.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Order.OrdersAll.Toolbar.Button.Title') }}</span>
        </v-btn>

        <v-spacer />

        <table-actions
          v-if="userCanDeleteOrders"
          v-model="dataTable.tableActions"
          :items.sync="selectedOrders"
          :disabled="!viewHasData"
        >
          <v-btn
            v-if="userCanDeleteOrders"
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            :disabled="deleteOrderLoading || !selectedOrders.length"
            :loading="deleteOrderLoading"
            :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
            class="pa-0 ma-1 elevation-0"
            @click="onDeleteOrdersClick(selectedOrders)"
          >
            <v-icon
              color="red"
              v-text="'delete'"
            />
          </v-btn>
        </table-actions>

        <v-btn
          :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
          :disabled="!viewHasData"
          :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
          class="pa-0 ma-1 elevation-0"
          @click="onDeleteAllOrdersClick"
        >
          <v-icon
            color="primary"
            v-text="'mdi-delete-sweep'"
          />
        </v-btn>

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <!--SAVED ORDERS TABLE-->
    <v-data-table
      v-model="selectedOrders"
      :custom-filter="customSearch"
      :footer-props="dataTable.footerProps"
      :headers="dataTableHeaders"
      :height="dataTable.resize.bodyHeight"
      :items="filteredItems"
      :loading="dataTable.loading"
      :loading-text="dataTable.text.loadingText"
      :no-data-text="dataTable.text.noDataText"
      :no-results-text="dataTable.text.noResultsText"
      :options.sync="dataTable.options"
      :search="dataTable.search.terms"
      :show-select="dataTable.tableActions"
      :single-select="false"
      fixed-header
      item-key="Id"
    >
      <template #[`item.source_type`]="{item}">
        <div v-if="getOrderSourceTypeById(item.SourceTypeId)">
          <v-tooltip
            right
            color="indigo lighten-2"
          >
            <template #activator="{on}">
              <v-avatar
                size="28"
                v-on="on"
              >
                <v-icon
                  v-if="item.SourceTypeId<100"
                  color="indigo lighten-2"
                >
                  {{ getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo }}
                </v-icon>

                <img
                  v-else
                  :src="getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo"
                >
              </v-avatar>
            </template>

            <span v-if="item.SourceTypeId < 100">
              {{ getOrderSourceTypeLangById(item.SourceTypeId) && getOrderSourceTypeLangById(item.SourceTypeId).Desc }}
            </span>
            <span v-else>
              {{ getOrderSourceTypeById(item.SourceTypeId).desc }}
            </span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.order_id`]="{item}">
        <v-badge
          :value="item.Reserved > 0 && item.Reserved !== parseInt(posUser.staff_id)"
          color="transparent"
          right
        >
          <template #badge>
            <v-tooltip
              color="orange darken-3"
              content-class="text-xs-center"
              left
            >
              <template #activator="{on}">
                <v-icon
                  color="orange"
                  size="14"
                  v-on="on"
                >
                  {{ item.InEdit ? 'lock' : 'person' }}
                </v-icon>
              </template>
              <span>{{ item.InEdit ? $t('Order.OrdersSaved.ListView.EditedBy') : $t('Order.OrdersSaved.ListView.BelongsTo') }} {{ item.ReservedName }}</span>
            </v-tooltip>
          </template>

          <template v-if="(!item.InEdit || item.Reserved === parseInt(posUser.staff_id)) && posUserHasComponentPermission('Orders', 'MANAGE') && posUserHasComponentPermission('Pos', 'ACCESS')">
            <a
              href="#"
              @click.prevent="editOrder(item)"
            >
              {{ item.Id }}
            </a>
          </template>
          <template v-else>
            {{ item.Id }}
          </template>
        </v-badge>
      </template>

      <template #[`item.icons`]="{item}">
        <v-tooltip
          :color="parseInt(item.CustomerId) === -1 ? 'grey darken-3' : 'amber darken-3'"
          right
        >
          <template #activator="{on}">
            <v-icon
              :color="parseInt(item.CustomerId) === -1 ? 'grey' : 'amber'"
              class="text--lighten-2"
              small
              v-on="on"
            >
              verified_user
            </v-icon>
          </template>
          <span>{{ parseInt(item.CustomerId) === -1 ? $t('Customer.CustomerType.Visitor') : $t('Customer.CustomerType.Registered') }}</span>
        </v-tooltip>

        <div v-if="parseInt(item.AddressId) > -1 && item.Address && parseInt(item.OrderTypeId) === 1">
          <v-icon
            color="light-green lighten-1"
            small
          >
            location_on
          </v-icon>
        </div>

        <div v-if="item.Telephone && $options.filters.phone(item.Telephone)">
          <v-icon
            color="blue lighten-2"
            small
          >
            phone
          </v-icon>
        </div>

        <div v-if="item.Telephone2 && $options.filters.phone(item.Telephone2)">
          <v-icon
            color="blue lighten-2"
            small
          >
            phone
          </v-icon>
        </div>

        <div v-if="item.Telephone3 && $options.filters.phone(item.Telephone3)">
          <v-icon
            color="blue lighten-2"
            small
          >
            phone
          </v-icon>
        </div>
      </template>

      <template #[`item.customer`]="{item}">
        <div
          v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS') && parseInt(item.CustomerId) !== 0"
          :style="customerColumnMaxWidth"
          :class="isDarkMode ? 'white--text' : 'grey--text text--darken-3'"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; cursor: pointer;"
          @click="$bus.$emit('show-customer-details-dialog', item.CustomerId)"
        >
          {{ item.FullName }}
        </div>

        <template v-else>
          <div
            :style="customerColumnMaxWidth"
            :class="isDarkMode ? 'white--text' : 'grey--text text--darken-3'"
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          >
            {{ parseInt(item.CustomerId) !== 0 || item.FullName.trim() ? item.FullName : $t('Customer.CustomerType.Visitor') }}
          </div>
        </template>

        <div
          v-if="!isQrOrder(item) && parseInt(item.AddressId) > -1 && item.Address && parseInt(item.OrderTypeId) === 1"
          :style="customerColumnMaxWidth"
          class="grey--text"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        >
          {{ getAddressFormatted(item.Address) }}
        </div>

        <div
          v-else-if="isQrOrder(item) && parseInt(item.OrderTypeId) === 1"
          :style="customerColumnMaxWidth"
          class="grey--text"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        >
          `
          {{ item.Comment }}
        </div>

        <div
          v-if="item.Telephone || item.Telephone2 || item.Telephone3"
          :style="customerColumnMaxWidth"
          class="grey--text"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        >
          <template v-if="item.Telephone && $options.filters.phone(item.Telephone)">
            {{ item.Telephone | phone }}<br>
          </template>

          <template v-if="item.Telephone2 && $options.filters.phone(item.Telephone2)">
            {{ item.Telephone2 | phone }}<br>
          </template>

          <template v-if="item.Telephone3 && $options.filters.phone(item.Telephone3)">
            {{ item.Telephone3 | phone }}<br>
          </template>
        </div>
      </template>

      <template #[`item.status`]="{item}">
        <div class="my-1">
          <order-status-chip
            :order="item"
            dense
          />
        </div>
      </template>

      <template #[`item.order_type`]="{item}">
        <order-type-chip
          :order="item"
          dense
        />
      </template>

      <template #[`item.order_total`]="{item}">
        {{ item.Price | currency }}
      </template>

      <template #[`item.order_payment`]="{item}">
        <order-payments-icon
          :item="item"
          :badge="!canDeleteOrder(item)"
          :badge-payment-type="PaymentTypeEnum.ALL_CARDS"
          chip
          dense
        />
      </template>

      <template #[`item.date_added`]="{item}">
        <span>{{ item.DateAdded }}</span>

        <div class="grey--text">
          {{ item.TimeAdded }}
        </div>
      </template>

      <template #[`item.actions`]="{item}">
        <template v-if="!item.InEdit || item.Reserved === parseInt(posUser.staff_id)">
          <v-speed-dial
            v-if="posUserHasComponentPermission('Orders', 'MANAGE') || posUserHasComponentPermission('Orders', 'DELETE')"
            v-model="item.fab"
            direction="left"
            transition="slide-x-reverse-transition"
            class="mr-1"
          >
            <template #activator>
              <v-badge
                :value="item.Reserved > 0 && item.Reserved !== parseInt(posUser.staff_id)"
                color="transparent"
                overlap
                right
              >
                <template #badge>
                  <v-tooltip
                    color="orange darken-3"
                    content-class="text-xs-center"
                    left
                  >
                    <template #activator="{on}">
                      <v-icon
                        color="orange"
                        size="18"
                        v-on="on"
                      >
                        {{ item.InEdit ? 'lock_person' : 'person' }}
                      </v-icon>
                    </template>
                    <span>{{ item.InEdit ? $t('Order.OrdersSaved.ListView.EditedBy') : $t('Order.OrdersSaved.ListView.BelongsTo') }} {{ item.ReservedName }}</span>
                  </v-tooltip>
                </template>

                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  class="blue-grey lighten-2 elevation-2 ma-0"
                  dark
                  icon
                >
                  <v-icon
                    v-if="item.fab"
                    :small="$vuetify.breakpoint.xsOnly"
                  >
                    close
                  </v-icon>
                  <v-icon
                    v-else
                    :small="$vuetify.breakpoint.xsOnly"
                  >
                    menu
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-tooltip
              color="cyan"
              top
            >
              <template #activator="{on}">
                <v-btn
                  v-if="posUserHasComponentPermission('Orders', 'MANAGE') && posUserHasComponentPermission('Pos', 'ACCESS')"
                  :small="$vuetify.breakpoint.xsOnly"
                  class="cyan"
                  dark
                  icon
                  @click="editOrder(item)"
                  v-on="on"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    {{ $t('Order.OrdersAll.ListView.Button.Edit.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t('Order.OrdersAll.ListView.Button.Edit.Title') }}</span>
            </v-tooltip>

            <v-tooltip
              color="pink accent-3"
              top
            >
              <template #activator="{on}">
                <v-btn
                  v-if="parseInt(item.StatusId) !== 19 && posUserHasComponentPermission('Orders', 'DELETE')"
                  :small="$vuetify.breakpoint.xsOnly"
                  class="pink accent-3"
                  dark
                  icon
                  v-on="on"
                  @click="onCancelOrderClick(item)"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    {{ $t('Order.OrdersAll.ListView.Button.Cancel.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t('Order.OrdersAll.ListView.Button.Cancel.Title') }}</span>
            </v-tooltip>
          </v-speed-dial>
        </template>

        <template v-else>
          <v-tooltip
            color="orange darken-3"
            content-class="text-xs-center"
            left
          >
            <template #activator="{on}">
              <v-icon
                color="orange"
                class="mr-3"
                v-on="on"
              >
                lock_person
              </v-icon>
            </template>
            <span>{{ $t('Order.OrdersSaved.ListView.EditedBy') }} {{ item.ReservedName }}</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>

    <order-change-status-dialog
      :cancel-order-confirm-dialog-visible.sync="cancelOrderConfirmDialogVisible"
      :selected-order="!Array.isArray(selectedOrder) ? selectedOrder : undefined"
    />

    <order-delete-dialog
      v-if="posUserHasComponentPermission('Orders', 'DELETE')"
      :selected-order="selectedOrder"
      :visible.sync="deleteOrderConfirmDialogVisible"
      @order-deleted="onOrdersDelete"
    />

    <order-delete-all-dialog
      v-if="posUserHasComponentPermission('Orders', 'DELETE')"
      :visible.sync="deleteAllOrdersConfirmDialogVisible"
      @order-deleted="onOrdersDelete"
    />

    <confirm-edit-dialog
      v-if="selectedOrder"
      :html-content="confirmDialogEditSavedOrderBody"
      :html-title="`${$t('Order.OrdersAll.ListView.Button.Edit.Title')} #  ${selectedOrder ? selectedOrder.Id : ''};`"
      :title-class="'cyan white--text lighten-0'"
      :visible.sync="confirmDialogEditVisible"
      :width="700"
      no-button-class="grey--text"
      no-button-event="cancel-edit-order"
      title-icon="edit"
      yes-button-class="cyan white--text"
      yes-button-event="accept-edit-order"
      @accept-edit-order="onAcceptConfirmEdit"
      @cancel-edit-order="onCancelConfirmEdit"
    />
  </v-card>
</template>

<script>
import OrdersCommon         from '@/mixins/orders/common'
import Auth                 from '@/mixins/auth'
import DataTable            from '@/mixins/data-table/dataTable'
import greekUtils           from '@/lib/greek-utils'
import FilterSearch         from '@/components/common/filter/FilterSearch.vue'
import ConfirmDialog        from '@/components/common/ConfirmDialog.vue'
import OrderPaymentsIcon    from '@/components/orders/common/OrderPaymentsIcon.vue'
import PinManagerEnum       from '@/api/Enums/PinManagerEnum'
import TableActions         from '@/components/common/filter/TableActions.vue'
import OrderDeleteAllDialog from '@/components/orders/OrderDeleteAllDialog.vue'
import PaymentTypeEnum      from '@/api/Enums/PaymentTypeEnum'
import OrderStatusChip      from '@/components/orders/common/OrderStatusChip.vue'
import OrderTypeChip        from '@/components/orders/common/OrderTypeChip.vue'

export default {
  components: {
    OrderTypeChip,
    OrderStatusChip,
    OrderDeleteAllDialog,
    TableActions,
    OrderPaymentsIcon,
    FilterSearch,
    'confirm-edit-dialog': ConfirmDialog
  },
  directives: {},
  mixins    : [Auth, OrdersCommon, DataTable],
  data () {
    return {
      confirmDialogEditVisible: false,
      dataTable               : {
        tableActions : false,
        dataStore    : 'ordersDataSaved',
        socketCommand: {
          list: window.SocketCommand.Order.Saved
        },
        options: {
          serverSideEnabled: false,
          defaultSortBy    : 'Id',
          sortBy           : ['Id'],
          sortDesc         : [true]
        },
        rejectHeaders: {
          xsOnly   : ['icons'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Order.Headers.SourceType'),
            value    : 'source_type',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.OrderId'),
            value    : 'order_id',
            field    : 'order_id',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : '',
            value    : 'icons',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.Customer'),
            value    : 'customer',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.Status'),
            value    : 'status',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.OrderType'),
            value    : 'order_type',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Order.Headers.Total'),
            value    : 'order_total',
            align    : 'right',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : '',
            value    : 'order_payment',
            align    : 'left',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1',
            width    : 25
          },
          {
            text     : this.$t('Order.Headers.Date'),
            value    : 'date_added',
            align    : 'center',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1'
          },
          {
            text     : '',
            value    : 'actions',
            align    : 'right',
            sortable : false,
            class    : 'px-1',
            cellClass: 'text-caption px-1',
            width    : 40
          }
        ]
      }
    }
  },
  computed: {
    PaymentTypeEnum () {
      return PaymentTypeEnum
    },
    filteredItems () {
      const items = this.dataTable?.data || []

      return items
        .map(o => {
          o.isSelectable = this.userCanDeleteOrders && this.canDeleteOrder(o)
          return o
        })
    },

    confirmDialogEditSavedOrderBody () {
      return `
          <i aria-hidden="true" class="v-icon notranslate material-icons theme--light orange--text mr-2" style="font-size: 22px;">${ this.selectedOrder.InEdit ? 'lock_person' : 'person' }</i>
          <span>${ this.selectedOrder.InEdit ? this.$t('Order.OrdersSaved.ListView.EditedBy') : this.$t('Order.OrdersSaved.ListView.BelongsTo') } <strong>${ this.selectedOrder.ReservedName }</strong></span>
          <br><br>${ this.$t('Common.OrdersQueue.AcceptOrder.Content.Choose') }
          <span class="cyan--text text--darken-1">${ this.$t('Common.OrdersQueue.AcceptOrder.Content.Edit') }</span> ${ this.$t('Common.OrdersQueue.AcceptOrder.Content.Order') }<br>
      `
    },

    customerColumnMaxWidth () {
      return this.$vuetify.breakpoint.lgAndDown ? 'max-width: 200px; width: 200px;' : 'max-width: 310px;  width: 310px;'
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
    this.$bus.$on(window.SocketCommand.Cart.Destroy, this.getData)
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Cart.Destroy, this.getData)
  },
  methods: {
    canDeleteOrder (order) {
      return order.CanDelete ?? true
    },

    customSearch (value, search, item) {
      if (!search) return

      const searchString = greekUtils.toGreeklish(String(search).toLocaleLowerCase(this.$i18n.locale))

      const itemString = [
        item.Id && String(item.Id),
        item.Email && String(item.Email),
        item.Address && greekUtils.toGreeklish(JSON.stringify(item.Address).toLocaleLowerCase(this.$i18n.locale)),
        item.FirstName && greekUtils.toGreeklish(String(item.FirstName).toLocaleLowerCase(this.$i18n.locale)),
        item.LastName && greekUtils.toGreeklish(String(item.LastName).toLocaleLowerCase(this.$i18n.locale)),
        item.Telephone && String(item.Telephone),
        item.Telephone2 && String(item.Telephone2),
        item.Telephone3 && String(item.Telephone3)
      ].filter(Boolean).join(' ')

      return itemString.includes(searchString)
    },

    editOrder (orderItem) {
      this.selectedOrder = orderItem

      this.executeWithPinPermissionManager(PinManagerEnum.PosCancel, () => {
        if (orderItem.Reserved > 0 && orderItem.Reserved !== parseInt(this.posUser.staff_id)) {
          this.confirmDialogEditVisible = true
        } else {
          this.onAcceptConfirmEdit()
        }
      })
    },

    onCancelConfirmEdit () {
      this.selectedOrder = null
    },

    onAcceptConfirmEdit () {
      if (!this.selectedOrder) return

      this.$router.push({
        name  : 'Pos',
        params: { orderId: this.selectedOrder?.Id }
      })
    }
  }
}
</script>

<style scoped>

</style>
