export default {
  data () {
    return {}
  },
  mixins : [],
  methods: {
    initMenuItem () {
      if (!this.selectedMenuItem) return

      if (this.selectedMenuItem.UnitId > 1) {
        this.selectedMenuItem.UnitQuantity = this.unitQuantity
      } else {
        this.selectedMenuItem.Quantity = this.quantity
      }

      this.onRadioChange()
      this.onCheckBoxChange()
      this.setUnitQuantityCustomPrice()
    },

    incrementQuantity () {
      this.quantity++
    },

    decrementQuantity () {
      this.quantity = this.quantity <= parseInt(this.selectedMenuItem.Details.MinimumQuantity) ? parseInt(this.selectedMenuItem.Details.MinimumQuantity) : --this.quantity
    },

    calculateModifiers () {
      for (let i = 0; i < this.selectedMenuItemOptions.length; i++) {
        const item = this.selectedMenuItemOptions[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'radio') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              if (option.MultiValues) {
                this.setModifiers(option)
              }
            }
          }
        }
      }
      if (this.selectedMenuItem.hasOwnProperty('ModifierRuns')) this.selectedMenuItem.ModifierRuns++
    },

    setModifiers (menuOptionValue) {
      if (!menuOptionValue.MultiValues) return

      if (menuOptionValue.MultiValues.hasOwnProperty('optionsModifiers')) {
        for (const optionId in menuOptionValue.MultiValues.optionsModifiers) {
          const option = this.selectedMenuItemOptions.find(menuOption => parseInt(menuOption.Id) === parseInt(optionId))
          const optionModifier = menuOptionValue.MultiValues.optionsModifiers[optionId]

          if (optionModifier.hasOwnProperty('MaxChoices')) option.MaxChoices = optionModifier.MaxChoices
          if (optionModifier.hasOwnProperty('MinChoices')) option.MinChoices = optionModifier.MinChoices
          if (optionModifier.hasOwnProperty('Required')) option.Required = optionModifier.Required
          if (optionModifier.hasOwnProperty('Priority')) option.Priority = optionModifier.Priority
          if (optionModifier.hasOwnProperty('Visible')) {
            option.Visible = optionModifier.Visible
          } else {
            option.Visible = true
          }
        }
      }

      if (menuOptionValue.MultiValues.hasOwnProperty('optionValuesModifiers')) {
        for (const optionValueId in menuOptionValue.MultiValues.optionValuesModifiers) {
          const option = this.selectedMenuItemOptions.find(menuOption => menuOption.OptionValues.some(menuOptionValue => parseInt(menuOptionValue.Id) === parseInt(optionValueId)))
          const optionValue = option.OptionValues.find(menuOptionValue => parseInt(menuOptionValue.Id) === parseInt(optionValueId))
          const optionModifier = menuOptionValue.MultiValues.optionValuesModifiers[optionValueId]

          if (optionModifier.hasOwnProperty('Priority')) optionValue.Priority = optionModifier.Priority
          if (optionModifier.hasOwnProperty('Disabled')) optionValue.Disabled = optionModifier.Disabled
          if (optionModifier.hasOwnProperty('Visible')) {
            optionValue.Visible = optionModifier.Visible
          } else {
            optionValue.Visible = true
          }
          if (optionModifier.hasOwnProperty('ShowPrice')) optionValue.ShowPrice = optionModifier.ShowPrice
          if (optionModifier.hasOwnProperty('Price')) {
            optionValue.Price = parseFloat(optionModifier.Price).toFixed(2)
            optionValue.PriceFmt = optionModifier.Price
          }

          if (optionModifier.hasOwnProperty('Selected') && ((this.selectedMenuItem.ModifierRuns > 0 && this.selectedMenuItem.RowId) || !this.selectedMenuItem.RowId)) {
            optionValue.Selected = optionModifier.Selected
          }
        }
      }
    },

    isRequiredOptionsValid () {
      if (!this.selectedMenuItem) return false
      const menuItemOptions = this.selectedMenuItem?.Details?.Options || []
      let isValid = 0
      for (let i = 0; i < menuItemOptions.length; i++) {
        const item = menuItemOptions[i]
        let selectedOptionsCount = 0
        item.Error = false

        if (item.Required) {
          if (item.DisplayType === 'radio') {
            selectedOptionsCount = item.OptionValues.filter((option) => {
              return item.DefaultValueId.toString() === option.Id.toString()
            }).length

            if (selectedOptionsCount === 0) {
              item.Error = {
                Type      : 'MinChoices',
                Selected  : selectedOptionsCount,
                MinChoices: item.MinChoices,
                MaxChoices: item.MaxChoices,
                Message   : this.$t('Pos.MenuOptionsDialog.Field.Checkbox.Error.Message') + ' ' + item.MinChoices + ' ' + this.$tc('Pos.MenuOptionsDialog.Field.Checkbox.Error.Choice', item.MinChoices)
              }
            }
          }
        }

        if (item.DisplayType === 'checkbox') {
          selectedOptionsCount = item.OptionValues.filter((option) => {
            return option.Selected
          }).length

          if (selectedOptionsCount < item.MinChoices || (item.Required && selectedOptionsCount === 0)) {
            item.Error = {
              Type      : 'MinChoices',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : this.$t('Pos.MenuOptionsDialog.Field.Checkbox.Error.Min') + (item.MinChoices === 0 ? '1' : item.MinChoices) + ' ' + this.$tc('Pos.MenuOptionsDialog.Field.Checkbox.Error.Choice', item.MinChoices)
            }
          }
          if (selectedOptionsCount > item.MaxChoices && item.MaxChoices > 0) {
            item.Error = {
              Type      : 'MaxChoices',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : this.$t('Pos.MenuOptionsDialog.Field.Checkbox.Error.Max') + item.MaxChoices + ' ' + this.$tc('Pos.MenuOptionsDialog.Field.Checkbox.Error.Choice', item.MaxChoices)
            }
          }
          if ((selectedOptionsCount < item.MinChoices || selectedOptionsCount > item.MaxChoices) && (item.MaxChoices >= item.MinChoices && item.MaxChoices > 0)) {
            item.Error = {
              Type      : 'Between',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : this.$t('Pos.MenuOptionsDialog.Field.Checkbox.Error.From') + (item.MinChoices === 0 ? '1' : item.MinChoices) + this.$t('Pos.MenuOptionsDialog.Field.Checkbox.Error.To') + item.MaxChoices + ' ' + this.$tc('Pos.MenuOptionsDialog.Field.Checkbox.Error.Choice', item.MaxChoices)
            }
          }
        }

        if (item.Error) isValid++
      }
      return isValid === 0
    },

    setUnitQuantityCustomPrice (optionsPrice = 0) {
      if (!this.selectedMenuItem || this.selectedMenuItem.UnitId <= 1 || this.selectedMenuItem.CustomMenuPrice) return

      const measurementUnit = this.getMeasurementUnitById(this.selectedMenuItem.UnitId)
      const divisor = parseFloat(measurementUnit?.Divisor ?? 1)
      const menuBasePrice = parseFloat(this.selectedMenuItem.Details.Price)
      const price = parseFloat((menuBasePrice * divisor * parseInt(this.selectedMenuItem.UnitQuantity)).toFixed(2))

      optionsPrice = this.calculateOptionsPrices(false)

      this.onSetCustomPrice(price + (optionsPrice || 0))
    },

    onSetCustomPrice (val) {
      if (!this.selectedMenuItem) return

      this.selectedMenuItem.HasCustomPrice = true
      this.selectedMenuItem.Price = val
    },

    onRadioClick (item, option) {
      item.DefaultValueId = option.Id
      this.onRadioChange()
    },

    onRadioChange () {
      if (!this.selectedMenuItem) return
      this.calculateModifiers()
      this.isRequiredOptionsValid()
      this.calculateOptionsPrices()
    },

    onCheckBoxClick (option) {
      if ((option.Mandatory && option.Selected) || option.Disabled) return

      option.Selected = !option.Selected
      this.onCheckBoxChange()
    },

    onCheckBoxChange () {
      if (!this.selectedMenuItem) return
      this.isRequiredOptionsValid()
      this.calculateOptionsPrices()
    },

    calculateOptionsPrices (setCustomUnitPrice = true) {
      // console.log(this.selectedMenuItem)
      if (this.selectedMenuItem.HasCustomPrice && this.selectedMenuItem.UnitId === 1) return 0

      let price = parseFloat(this.selectedMenuItem.Details.Price)
      let unitPrice = parseFloat(this.selectedMenuItem.Details.PriceFmt)
      let optionsPrice = 0
      let priceSpecial = parseFloat(this.selectedMenuItem.Details.SpecialPrice)

      if (!Array.isArray(this.selectedMenuItemOptions)) {
        const arr = Object.keys(this.selectedMenuItemOptions).map((k) => this.selectedMenuItemOptions[k])
        this.selectedMenuItemOptions = arr
      }

      if (this.selectedMenuItem.HasCustomPrice && this.selectedMenuItem.UnitId > 1) {
        for (let i = 0; i < this.selectedMenuItemOptions.length; i++) {
          const item = this.selectedMenuItemOptions[i]
          for (let ii = 0; ii < item.OptionValues.length; ii++) {
            const option = item.OptionValues[ii]
            if (item.DisplayType === 'unit_value') {
              if (item.DefaultValueId.toString() === option.Id.toString()) {
                option.Selected = true
                unitPrice = parseFloat(unitPrice) + parseFloat(option.Price)
                priceSpecial = parseFloat(priceSpecial) + parseFloat(option.Price)
              } else {
                option.Selected = false
              }
            }
          }
        }

        this.selectedMenuItem.Details.Price = unitPrice
      }

      for (let i = 0; i < this.selectedMenuItemOptions.length; i++) {
        const item = this.selectedMenuItemOptions[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'checkbox' || item.DisplayType === 'extra_charges') {
            if (option.Selected) {
              price = parseFloat(price) + parseFloat(option.Price)
              optionsPrice = optionsPrice + parseFloat(option.Price)
              priceSpecial = parseFloat(priceSpecial) + parseFloat(option.Price)
            }
          } else if (item.DisplayType === 'radio') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              option.Selected = true
              price = parseFloat(price) + parseFloat(option.Price)
              optionsPrice = optionsPrice + parseFloat(option.Price)
              priceSpecial = parseFloat(priceSpecial) + parseFloat(option.Price)
            } else {
              option.Selected = false
            }
          }
        }
      }

      this.selectedMenuItem.Price = (this.selectedMenuItem.SpecialStatus && this.selectedMenuItem.IsSpecial) ? priceSpecial : price
      this.selectedMenuItem.PriceFmt = price
      this.selectedMenuItem.SpecialPrice = priceSpecial
      this.selectedMenuItem.SpecialPriceFmt = priceSpecial

      if (setCustomUnitPrice) this.setUnitQuantityCustomPrice(optionsPrice)

      return optionsPrice
    }

  },

  computed: {
    selectedMenuItemOptions () {
      return this.selectedMenuItem?.Details?.Options?.filter(option => option.DisplayType !== 'unit') || []
    },
    selectedMenuItemOptionsCopy () {
      return this.selectedMenuItem?.Details?.OptionsCopy?.filter(option => option.DisplayType !== 'unit') || []
    },
    quantity: {
      get () {
        let q = 0
        if (this.selectedMenuItem?.UnitId > 1) return parseInt(this.selectedMenuItem?.Quantity || 0)
        if (this.selectedMenuItem) q = parseInt(this.selectedMenuItem.Quantity) >= parseInt(this.selectedMenuItem.Details.MinimumQuantity) ? parseInt(this.selectedMenuItem.Quantity) : parseInt(this.selectedMenuItem.Details.MinimumQuantity)
        return q
      },
      set (val) {
        this.selectedMenuItem.Quantity = parseInt(val)
      }
    },
    unitQuantity: {
      get () {
        let q = 0
        if (this.selectedMenuItem) q = parseInt(this.selectedMenuItem.UnitQuantity) >= parseInt(this.selectedMenuItem.Details.MinimumQuantity) ? parseInt(this.selectedMenuItem.UnitQuantity) : parseInt(this.selectedMenuItem.Details.MinimumQuantity)
        return q
      },
      set (val) {
        this.selectedMenuItem.UnitQuantity = parseInt(val)
      }
    }
  }
}
