import AppData        from '@/mixins/appdata'
import SourceTypeEnum from '@/api/Enums/SourceTypeEnum'

export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [AppData],
  directives: {},
  watch     : {},
  methods   : {
    getOrderTypeById (id) {
      return this.orderTypes.find(orderType => parseInt(orderType.Id) === parseInt(id))
    },

    getOrderTypeName (order) {
      const orderType = this.orderTypes.find(orderType => parseInt(orderType.Id) === parseInt(order.OrderTypeId))
      const isTableOrder = parseInt(order.SourceTypeId) === SourceTypeEnum.TABLE
      return isTableOrder ? this.$tc('Restaurant.Tables.Title', 1) : orderType ? orderType.Title : ''
    },

    getOrderTypeIcon (order) {
      const orderType = this.orderTypes.find(orderType => parseInt(orderType.Id) === parseInt(order.OrderTypeId))
      return orderType ? orderType.Icon : ''
    }
  },
  computed: {
    orderTypes () {
      return [this.$t('Order.Type.Delivery'), this.$t('Order.Type.Collection')]
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  }

}
