<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      persistent
      scrollable
    >
      <v-card v-if="selectedDomain">
        <v-toolbar
          flat
          class="red"
          height="80"
          max-height="80"
        >
          <v-avatar
            color="red darken-3"
          >
            <v-icon
              color="white"
              size="28"
            >
              domain
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3 white--text">
            <div class="body-3">
              {{ $t('Settings.Domain.Dialog.Delete.Title') }}
            </div>
          </v-toolbar-title>

          <v-spacer />
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-container
            class="pa-1"
            fluid
          >
            <v-row dense>
              <v-col
                class="mb-1"
                cols="12"
              >
                <span
                  class="text-subtitle-1"
                  v-html="$t('Settings.Domain.Dialog.Delete.Content', {domain: getDomainName(selectedDomain)})"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="deleteDelay"
                  hide-details
                  type="number"
                  min="0"
                  step="1"
                  :label="$t('Settings.Domain.Dialog.Delete.Delay')"
                  :placeholder="$t('Settings.Domain.Dialog.Delete.Delay')"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  :suffix="$tc('Common.Date.Minute', deleteDelay)"
                  filled
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <v-col
                class="red lighten-4"
                cols="12"
              >
                <div
                  class="py-2 px-4 text-caption"
                  style="display: table;"
                >
                  <div style="display: table-row">
                    <div
                      class="font-weight-bold pr-2"
                      style="display: table-cell; vertical-align: middle;"
                    >
                      {{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:
                    </div>
                    <div style="display: table-cell; vertical-align: middle;">
                      {{ $t('Settings.Domain.Dialog.Delete.Warning') }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="green--text text--darken-1"
            text
            outlined
            @click="isVisible = false"
          >
            {{ $t('Common.Button.No').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            class="red darken-1 white--text"
            text
            @click="deleteOrder"
          >
            {{ $t('Common.Button.Yes').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'

export default {
  name      : 'DomainDeleteDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    selectedDomain: {
      type   : [Object, Array],
      default: undefined
    }
  },
  data () {
    return {
      deleteDelay: 30
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) this.deleteDelay = 30
    }
  },
  methods: {
    getDomainName (d) {
      if (!d) return ''
      const Domain = d.Domain || ''
      const SubDomain = Domain.split('.').shift()
      const BaseDomain = Domain.split('.').slice(1).join('.').slice(0)
      return d ? `${ SubDomain }.${ d.BaseDomain || BaseDomain || '' }` : ''
    },

    deleteOrder () {
      this.visible = false
      this.$emit('on-confirm-delete', this.deleteDelay)
    }
  }
}
</script>
