import SupportTicketModel from '../../Models/support-center/SupportTicketModel'
import BaseCollection     from '../../../lib/data/Collections/BaseCollection'
import SocketCommand      from '@/api/SocketCommand'

export default class SupportTicketCollection extends BaseCollection {
  constructor (items = []) {
    super(items, SupportTicketModel)
  }

  boot () {
    super.boot()

    this.fetchCommand = SocketCommand.SupportCenter.Ticket.All
  }

  /* PROPERTIES */

  /* METHODS */

  filteredAndGrouped (filterByProperty, groupByProperty) {
    const type = filterByProperty.split('-')[0] || ''
    const id = filterByProperty.split('-')[1] || ''
    filterByProperty = type && id ? filterByProperty : ''
    const filterResults = filterByProperty === '' ? this.all() : this.all().filter(ticket => String(ticket[type][`${ type }id`]) === String(id))

    const retVal = filterResults.reduce(
      (objectsByKeyValue, obj) => ({
        ...objectsByKeyValue,
        [groupByProperty.split('.').reduce((o, i) => o[i], obj)]: (objectsByKeyValue[groupByProperty.split('.').reduce((o, i) => o[i], obj)] || []).concat(obj)
      }),
      {}
    )
    return retVal
  }

  /* API METHODS */

  onFetch (data) {
    super.onFetch(data.data)
    // if (data.Messages) super.onFetch(data.Messages)
    // if (data.hasOwnProperty('Groups')) window.Vue.$DataStore.support.tickets.clear().add(data.Groups)
  }
}
