<template>
  <v-card flat>
    <app-sub-toolbar id="appSubToolbar" />

    <router-view />
  </v-card>
</template>

<script>
import AppData       from '../../mixins/appdata'
import Auth          from '../../mixins/auth'
import AppSubToolbar from '../../components/orders/OrdersToolbar.vue'

export default {
  components: {
    'app-sub-toolbar': AppSubToolbar
  },
  mixins: [Auth, AppData],
  data () {
    return {
      search                         : '',
      loader                         : null,
      selectedOrder                  : null,
      cancelOrderConfirmDialogVisible: false,
      deleteOrderConfirmDialogVisible: false
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {}
}
</script>
