<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar color="indigo">
            <v-icon dark>
              {{ $t(`Pos.OrderPaymentDialog.Toolbar.Icon`) }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t(`Pos.OrderPaymentDialog.Toolbar.Title`) }}
            </div>

            <div class="text-caption grey--text">
              {{ $t(`Pos.OrderPaymentDialog.Toolbar.Subtitle`) }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-progress-linear
          v-if="loading"
          class="pa-0 ma-0"
          color="secondary"
          height="2"
          indeterminate
        />

        <v-divider />

        <v-card-text
          class="fill-height pa-3"
          style="min-height: 310px;"
          :style="isDarkMode ? 'background-color: #323232;' : ''"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <v-col cols="12">
                <order-payment
                  v-if="isVisible"
                  v-model="paymentTransactions"
                  :receipt="receipt"
                  :receipt-type.sync="selectedReceiptType"
                  :receipt-data.sync="selectedReceiptData"
                  :receipt-notify.sync="selectedReceiptNotify"
                  :receipt-notify-emails.sync="selectedReceiptNotifyEmails"
                  :payment-type="paymentType"
                  :cart-id="cartId"
                  :order-id="orderId"
                  :customer-id="customerId"
                  :order-total="orderTotalForPayment"
                  :items="items"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-spacer />

          <template v-if="cartId">
            <v-btn
              :disabled="loading || orderBalance !== 0 || hasPendingPaymentTransactions || (appHasReceiptsEnabled && selectedReceiptType === ReceiptTypeEnum.INVOICE && !selectedReceiptData)"
              :loading="loading"
              class="green white--text elevation-0"
              style="min-width: 165px;"
              @click="onAddOrderClick"
            >
              <span class="mr-2">{{ $t('Pos.Pos.Footer.Button.AddOrder') }}</span>
              <v-spacer />
              {{ orderTotalForPayment | currency }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData                 from '@/mixins/appdata'
import ReceiptTypeEnum         from '@/api/Enums/ReceiptTypeEnum'
import PaymentTypeEnum         from '@/api/Enums/PaymentTypeEnum'
import OrderPayment            from '@/components/payments/orders/OrderPayment.vue'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import Auth                    from '@/mixins/auth'
import NotifyTypeEnum          from '@/api/Enums/NotifyTypeEnum'

export default {
  name      : 'OrderPaymentDialog',
  components: {
    OrderPayment
  },
  mixins: [Auth, AppData],
  props : {
    orderId: {
      type   : [Number, String],
      default: null
    },
    cartId: {
      type   : [Number, String],
      default: null
    },
    customerId: {
      type   : [Number, String],
      default: null
    },
    orderTotal: {
      type   : Number,
      default: 0
    },
    transactions: {
      type   : Array,
      default: () => []
    },
    paymentType: {
      type   : String,
      default: PaymentTypeEnum.CASH
    },
    payload: {
      type   : Object,
      default: undefined
    },
    items: {
      type   : Array,
      default: () => []
    },
    receipt: {
      type   : Boolean,
      default: false
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      loading                    : false,
      selectedReceiptType        : ReceiptTypeEnum.RECEIPT,
      selectedReceiptData        : null,
      selectedReceiptNotify      : [],
      selectedReceiptNotifyEmails: []
    }
  },
  computed: {
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },

    hasPendingPaymentTransactions () {
      return this.paymentTransactions?.some(transaction => transaction?.WaitingPos)
    },

    orderTotalForPayment () {
      return parseFloat(this.orderTotal.toFixed(2))
    },

    orderBalance () {
      let transactionsTotal = this.paymentTransactions?.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0

      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        transactionsTotal = parseFloat(this.paymentTransactions?.filter(transaction => transaction.PaymentType !== PaymentTypeEnum.CARD || (transaction.PaymentType === PaymentTypeEnum.CARD && transaction.Paid === 'success'))?.reduce((a, b) => a + parseFloat(b.Amount), 0).toFixed(2))
      }

      const orderTotal = parseFloat(this.orderTotal.toFixed(2))

      return parseFloat((orderTotal - transactionsTotal).toFixed(2))
    },

    paymentTransactions: {
      get () {
        return this.transactions || []
      },
      set (val) {
        this.transactions = []
      }
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.selectedReceiptType = !this.payload?.autoPrint ? ReceiptTypeEnum.NONE : ReceiptTypeEnum.RECEIPT
        this.selectedReceiptNotify = []
        this.selectedReceiptNotifyEmails = []
      }
    }
  },
  methods: {
    onAddOrderClick () {
      this.loading = true
      const payload = {
        ...this.payload,
        receiptType   : this.selectedReceiptType,
        receiptData   : this.selectedReceiptData,
        printNotify   : this.selectedReceiptNotify?.includes(NotifyTypeEnum.PRINT) ?? null,
        emailNotify   : this.selectedReceiptNotify?.includes(NotifyTypeEnum.EMAIL) ?? null,
        emailAddresses: this.selectedReceiptNotifyEmails
      }

      if (this.$route.name === 'Pos') {
        this.$bus.$emit('cart:complete-order-after-payment', payload)
        this.onCancelButtonClick()
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.selectedReceiptType = ReceiptTypeEnum.RECEIPT
      this.selectedReceiptData = null
      this.selectedReceiptNotify = []
      this.selectedReceiptNotifyEmails = []
    }
  }
}

</script>

<style scoped>

</style>
