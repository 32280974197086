<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ $t('Pos.SelectExtraCharge.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Pos.SelectExtraCharge.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Pos.SelectExtraCharge.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-list
                  class="pb-0 pt-0"
                  two-line
                >
                  <template v-for="(item, index) in extraCharges">
                    <v-list-item
                      :key="`list-item-${index}`"
                      class="px-2 rounded"
                      :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']"
                      ripple
                      @click="setExtraCharge(item)"
                    >
                      <v-list-item-avatar v-if="!$vuetify.breakpoint.xsOnly">
                        <v-icon
                          class="green--text"
                          size="36"
                        >
                          {{ $t('Pos.SelectExtraCharge.Toolbar.Icon') }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="grey--text text--darken-1">
                        <v-list-item-title>
                          {{ getTranslatedField(item, 'Name', 'Lang') }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action-text>
                        + {{ item.Price | currency }}
                      </v-list-item-action-text>
                    </v-list-item>

                    <v-divider
                      :key="`list-item-divider-${index}`"
                      class="mb-2"
                    />
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData      from '@/mixins/appdata'
import Translatable from '@/mixins/translatable'

export default {
  name      : 'SelectExtraChargeDialog',
  components: {},
  mixins    : [AppData, Translatable],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      isExtraChargeSelected: false
    }
  },
  computed: {
    extraCharges () {
      const orderType = this?.posCart?.SOrderType
      const sourceTypeId = this?.posCart?.SourceTypeId

      return this.appConfig?.EXTRA_CHARGES?.filter(charge =>
        (parseInt(charge.OrderRestriction) > 0 ? parseInt(charge.OrderRestriction) === orderType : true) &&
        (!charge.SourceType?.includes(0) ? charge.SourceType?.includes(sourceTypeId) : true)
      ) || []
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.isExtraChargeSelected = false
      }
    }
  },
  methods: {
    setExtraCharge (extraCharge) {
      if (this.isExtraChargeSelected) return
      this.isExtraChargeSelected = true
      this.$emit('cart:extra-charge-selected', extraCharge)
      this.isVisible = false
    },
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile--link {
  padding : 0 !important;
}
</style>
