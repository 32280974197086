import { render, staticRenderFns } from "./SystemSettingsEmail.vue?vue&type=template&id=2b8d14d3&scoped=true"
import script from "./SystemSettingsEmail.vue?vue&type=script&lang=js"
export * from "./SystemSettingsEmail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8d14d3",
  null
  
)

export default component.exports