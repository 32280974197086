<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        class="filter-toolbar"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.Menu.Toolbar.Button.New.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showMenuAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.Menu.Toolbar.Button.New.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Restaurant.Menu.Toolbar.Button.New.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ACCESS')"
          :color="$t('Restaurant.Menu.Toolbar.Button.Catalogs.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'KitchenCatalogs'}"
          class="elevation-0 ma-1"
          text
        >
          <v-icon
            v-if="$t('Restaurant.Menu.Toolbar.Button.Catalogs.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Menu.Toolbar.Button.Catalogs.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Restaurant.Menu.Toolbar.Button.Catalogs.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenuCategories', 'ACCESS')"
          :color="$t('Restaurant.Menu.Toolbar.Button.Categories.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'KitchenMenuCategories'}"
          class="elevation-0 ma-1"
          text
        >
          <v-icon
            v-if="$t('Restaurant.Menu.Toolbar.Button.Categories.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Menu.Toolbar.Button.Categories.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Restaurant.Menu.Toolbar.Button.Categories.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenuOptions', 'ACCESS')"
          :color="$t('Restaurant.Menu.Toolbar.Button.Options.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'KitchenMenuOptions'}"
          class="elevation-0 ma-1"
          text
        >
          <v-icon
            v-if="$t('Restaurant.Menu.Toolbar.Button.Options.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Menu.Toolbar.Button.Options.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Restaurant.Menu.Toolbar.Button.Options.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ACCESS')"
          :color="$t('Restaurant.Menu.Toolbar.Button.Tags.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :to="{name: 'Tags'}"
          class="elevation-0 ma-1"
          text
        >
          <v-icon
            v-if="$t('Restaurant.Menu.Toolbar.Button.Tags.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Menu.Toolbar.Button.Tags.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{
            $t('Restaurant.Menu.Toolbar.Button.Tags.Title').toLocaleUpperCase($i18n.locale)
          }}</span>
        </v-btn>

        <!--
        <v-btn
          v-if="selectedLanguages.length > 1 && userIsSuperExtraAdmin"
          :disabled="dataTable.loading"
          :loading="dataTable.loading"
          :loading-text="dataTable.text.loadingText"
          :no-data-text="dataTable.text.noDataText"
          :no-results-text="dataTable.text.noResultsText"
          class="purple white--text elevation-0 ma-1"
          @click="translateAllItems()"
        >
          {{ $t('Common.Button.Translate').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        -->

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />

        <filter-options
          v-model="filtersModel"
          :filters="filterData"
        />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.color`]="{item}">
              <div
                class=""
                style="height: 100%; min-height: 53px; text-align: left;"
              >
                <div
                  v-if="item.Image"
                  class="imagecontainer is-contain"
                  style="padding-top: 8px; position: relative;top: 50%;transform: perspective(1px) translateY(-50%);"
                >
                  <img
                    :src="appConfig.LOCATION_DATA.CdnImagesUrl + item.Image"
                    class="ml-1"
                    height="51"
                    style=""
                    width="auto"
                  >
                </div>
                <div
                  v-else
                  class="imagecontainer is-contain"
                  style="padding-top: 8px; position: relative;top: 50%;transform: perspective(1px) translateY(-50%);"
                >
                  <img
                    :src="getGMapIcon(LOGO_COLOR.DARK)"
                    class="ml-1 mr-1"
                    height="auto"
                    style="opacity: 0.12;"
                    width="auto"
                  >
                </div>
              </div>
            </template>

            <template #[`item.menu_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.menu_name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showMenuEditDialog(item)"
                >
                  {{ getTranslatedField(item, 'Name') }}
                </a>
              </template>
              <template v-else>
                {{ getTranslatedField(item, 'Name') }}
              </template>

              <div>
                <template v-for="(category, index) in item.Categories">
                  <v-chip
                    v-if="getCategoryData(category)"
                    :key="index"
                    :style="'background-color:#' + (getCategoryData(category).Color ? getCategoryData(category).Color : 'e0e0e0') + '; color: ' + (invertColor(getCategoryData(category).Color ? getCategoryData(category).Color : 'e0e0e0', true)) + ';'"
                    class="mr-1 my-1 px-2"
                    label
                    x-small
                    @click="onCategoryChipClick(category)"
                  >
                    {{ getTranslatedField(getCategoryData(category), 'Name') }}
                    <span
                      v-if="getCategoryCatalog(category, item.Catalogs)"
                      class="text-caption ml-1"
                    >- {{ getCategoryCatalog(category, item.Catalogs) }}</span>
                  </v-chip>
                </template>
              </div>
            </template>

            <template #[`item.catalog`]="{item}">
              <template v-for="(catalogId, index) in item.Catalogs">
                <v-chip
                  :key="`catalog-${catalogId}-${index}`"
                  :style="'background-color:#' + (getCatalogById(catalogId).Color ? getCatalogById(catalogId).Color : 'e0e0e0') + '; color: ' + (invertColor(getCatalogById(catalogId).Color ? getCatalogById(catalogId).Color : 'e0e0e0', true)) + ';'"
                  class="mr-1 my-1 px-2"
                  label
                  x-small
                  @click="onCatalogChipClick(catalogId)"
                >
                  {{ getCatalogById(catalogId).Name }}
                </v-chip>
              </template>
            </template>

            <template #[`item.tags`]="{item}">
              <div class="text-no-wrap">
                <template v-for="tag in getMenuTags(item)">
                  <v-tooltip
                    :key="`menu-tag-tooltip-${tag.Id}`"
                    :color="tag.Color || '#666666'"
                    bottom
                  >
                    <template #activator="{on}">
                      <v-chip
                        :key="`menu-tag-${tag.Id}`"
                        :color="tag.Color || '#666666'"
                        x-small
                        label
                        class="px-1 mr-1 d-inline-block"
                        :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`"
                        v-on="on"
                      >
                        <template v-if="!['over-18', 'over-16'].includes(tag.Group)">
                          <v-icon
                            v-if="tag.Icon"
                            x-small
                          >
                            {{ tag.Icon }}
                          </v-icon>
                        </template>

                        <div
                          v-if="!tag.Icon || ['over-18', 'over-16'].includes(tag.Group)"
                          :class="{'ml-1': !!tag.Icon && !['over-18', 'over-16'].includes(tag.Group)}"
                        >
                          {{ tag.Label }}
                        </div>
                      </v-chip>
                    </template>

                    <div :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`">
                      <div class="font-weight-bold text-center">
                        {{ getTranslatedField(tag, 'Label') }}
                      </div>
                      <div class="text-caption text-left">
                        {{ getTranslatedField(tag, 'Description') }}
                      </div>
                    </div>
                  </v-tooltip>
                </template>
              </div>
            </template>

            <template #[`item.tax_rate`]="{item}">
              <div>
                <v-chip
                  v-if="item.TaxId && item.TaxRate"
                  class="text-no-wrap px-2 my-1 chip-column"
                  color="blue lighten-1"
                  dark
                  label
                  style="min-width: 100px; max-width: 115px;"
                  x-small
                >
                  <div>{{ $t('Restaurant.CategoryAddDialog.Field.Tax.Type') }}</div>

                  <div>{{ parseInt(item.TaxRate) }}%</div>
                </v-chip>
              </div>

              <div>
                <v-chip
                  v-if="item.Tax2Id && item.Tax2Rate"
                  class="text-no-wrap px-2 my-1 chip-column"
                  color="purple lighten-2"
                  dark
                  label
                  style="min-width: 100px; max-width: 115px;"
                  x-small
                >
                  <div>{{ $t('Restaurant.CategoryAddDialog.Field.Tax2.Type') }}</div>

                  <div>{{ parseInt(item.Tax2Rate) }}%</div>
                </v-chip>
              </div>
            </template>

            <template #[`item.languages`]="{item}">
              <div class="text-no-wrap">
                <template v-for="lang in selectedLanguages">
                  <div
                    :key="lang.Code"
                    class="mr-1 d-inline-block"
                  >
                    <v-badge
                      :value="!item.Lang[lang.Code] || !item.Lang[lang.Code].Name"
                      color="orange"
                      overlap
                      right
                    >
                      <template #badge>
                        <v-icon
                          dark
                          small
                        >
                          priority_high
                        </v-icon>
                      </template>
                      <v-avatar
                        v-if="lang.ImageUrl"
                        size="20"
                      >
                        <img
                          :class="[{'grayscale opacity': !item.Lang[lang.Code] || !item.Lang[lang.Code].Name}]"
                          :src="lang.ImageUrl"
                        >
                      </v-avatar>
                      <div v-else>
                        {{
                          $te(`Common.Language.${ lang.Code }`) ?
                            $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) :
                            lang.Name.toLocaleUpperCase($i18n.locale)
                        }}
                      </div>
                    </v-badge>
                  </div>
                </template>
              </div>
            </template>

            <template #[`item.menu_price`]="{item}">
              <div class="d-inline-flex">
                <v-icon
                  v-if="item.SpecialStatus"
                  :color="item.IsSpecial ? 'purple' : 'grey'"
                  class="mr-2"
                >
                  redeem
                </v-icon>

                <span
                  :class="{'blue--text': item.ShowCalculatedPrice}"
                >
                  <template v-if="item.SpecialStatus && item.IsSpecial">
                    <span class="old-price">
                      {{
                        item.ShowCalculatedPrice ? (parseFloat(item.OptionsPrice) + parseFloat(item.Price)) : parseFloat(item.Price) | currency
                      }}
                    </span><br>{{
                      item.ShowCalculatedPrice ? (parseFloat(item.OptionsPrice) + parseFloat(item.SpecialPrice)) : parseFloat(item.SpecialPrice) | currency
                    }}
                  </template>
                  <template v-else-if="item.ShowCalculatedPrice">
                    {{ (parseFloat(item.OptionsPrice) + parseFloat(item.Price)) | currency }}
                  </template>
                  <template v-else>
                    <template v-if="userCanManage">
                      <v-hover v-slot="{hover}">
                        <display-editable-value
                          :id="item.Id"
                          :value="item.Price"
                          :display-value="$options.filters.currency(parseFloat(item.Price))"
                          :rules="[(v) => !!v || $t('Restaurant.MenuAddDialog.Field.Price.Error.Valid')]"
                          :suffix="`/ ${getMeasurementUnitById(item.UnitId).BaseUnit}`"
                          :hint="$t('Restaurant.MenuAddDialog.Field.Price.Hint')"
                          :label="$t('Restaurant.MenuAddDialog.Field.Price.Label')"
                          :prepend-icon="appCurrency.Icon"
                          :hover="hover"
                          type="number"
                          @save="saveMenuItemEditPrice"
                        />
                      </v-hover>
                    </template>
                    <template v-else>
                      {{ parseFloat(item.Price) | currency }}
                    </template>
                  </template>
                </span>
              </div>
            </template>

            <template #[`item.menu_priority`]="{item}">
              <template v-if="userCanManage">
                <v-hover v-slot="{hover}">
                  <display-editable-value
                    :id="item.Id"
                    :value="String(item.Priority)"
                    :display-value="item.Priority"
                    :rules="[(v) => !!v || $t('Restaurant.MenuAddDialog.Field.Priority.Error.Valid')]"
                    :hint="$t('Restaurant.MenuAddDialog.Field.Priority.Hint')"
                    :label="$t('Restaurant.MenuAddDialog.Field.Priority.Label')"
                    :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Priority.Icon')"
                    :hover="hover"
                    type="number"
                    @save="saveMenuItemEditPriority"
                  />
                </v-hover>
              </template>

              <template v-else>
                {{ item.Priority }}
              </template>
            </template>

            <template #[`item.stock_qty`]="{item}">
              <template v-if="item.SubtractStock">
                <v-badge
                  bordered
                  overlap
                  left
                  :offset-x="30"
                  :value="!!item.ResetStock"
                >
                  <template v-if="userCanManage">
                    <v-hover v-slot="{hover}">
                      <display-editable-value
                        :id="item.Id"
                        :value="String(item.Quantity)"
                        :display-value="formatUnitQuantity($i18n.locale, item.Quantity, getMeasurementUnitById(item.UnitId))"
                        :rules="[(v) => !!v || $t('Restaurant.MenuAddDialog.Field.Stock.Error.Valid')]"
                        :suffix="`${getMeasurementUnitById(item.UnitId).RateUnit}`"
                        :hint="$t('Restaurant.MenuAddDialog.Field.Stock.Hint')"
                        :label="`${$t('Restaurant.MenuAddDialog.Field.Stock.Label')} ${$t('Common.Misc.In')} ${getMeasurementUnitById(item.UnitId).BaseName}`"
                        :prepend-icon="$t('Restaurant.MenuAddDialog.Field.Stock.Icon')"
                        :chip-class="[subtractStockThresholdColor(item), 'text-caption white--text text-center']"
                        :hover="hover"
                        chip-style="min-width: 50px;"
                        type="number"
                        mask="######"
                        chip
                        @save="saveMenuItemEditQuantity"
                      />
                    </v-hover>
                  </template>
                  <template v-else>
                    <v-chip
                      :class="[subtractStockThresholdColor(item), 'text-caption white--text text-center']"
                      style="min-width: 50px;"
                      class="px-2"
                      label
                    >
                      {{ displayValue || value }}
                    </v-chip>
                  </template>

                  <template #badge>
                    <v-icon>mdi-shape-circle-plus</v-icon>
                  </template>
                </v-badge>
              </template>

              <v-chip
                v-else
                class="text-caption white--text text-center"
                color="grey lighten-1"
                label
                style="min-width: 50px; display: inline-block;"
              >
                <v-icon style="padding-top: 2px;">
                  all_inclusive
                </v-icon>
              </v-chip>
            </template>

            <template #[`item.menu_status`]="{item}">
              <v-badge
                bordered
                overlap
                left
                :value="!!item.ResetStatus"
              >
                <v-switch
                  v-if="!productBelongsToExternalCatalog(item)"
                  v-model="item.Status"
                  :disabled="!userCanManage || productBelongsToExternalCatalog(item)"
                  :label="item.Status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                  class="ma-0"
                  color="success"
                  hide-details
                  inset
                  @change="updateItemStatus(item)"
                />
                <v-icon
                  v-else-if="item.Status"
                  color="success"
                >
                  check_circle
                </v-icon>

                <template #badge>
                  <v-icon>mdi-shape-circle-plus</v-icon>
                </template>
              </v-badge>
            </template>

            <template #[`item.menu_active_pos`]="{item}">
              <div class="">
                <v-switch
                  v-if="!productBelongsToExternalCatalog(item)"
                  v-model="item.ActivePos"
                  :disabled="!userCanManage || productBelongsToExternalCatalog(item)"
                  :label="$t('Restaurant.Menu.Table.Headers.ActivePos')"
                  class="ma-0"
                  color="purple"
                  hide-details
                  inset
                  dense
                  @change="updateItemStatus(item)"
                />
                <v-icon
                  v-else-if="item.ActivePos"
                  color="purple"
                >
                  check_circle
                </v-icon>
              </div>

              <div class="">
                <v-switch
                  v-if="!productBelongsToExternalCatalog(item)"
                  v-model="item.Active"
                  :disabled="!userCanManage || productBelongsToExternalCatalog(item)"
                  :label="$t('Restaurant.Menu.Table.Headers.Active')"
                  class="ma-0"
                  color="blue"
                  hide-details
                  inset
                  dense
                  @change="updateItemStatus(item)"
                />
                <v-icon
                  v-else-if="item.Active"
                  color="blue"
                >
                  check_circle
                </v-icon>
              </div>

              <div class="">
                <v-switch
                  v-if="!productBelongsToExternalCatalog(item)"
                  v-model="item.Featured"
                  :disabled="!userCanManage || productBelongsToExternalCatalog(item)"
                  :label="$t('Restaurant.Menu.Table.Headers.Featured')"
                  class="ma-0"
                  color="cyan"
                  hide-details
                  inset
                  dense
                  @change="updateItemStatus(item)"
                />
                <v-icon
                  v-else-if="item.Featured"
                  color="cyan"
                >
                  check_circle
                </v-icon>
              </div>
            </template>

            <template #[`item.actions`]="{item}">
              <v-speed-dial
                v-model="item.fab"
                direction="left"
                transition="slide-x-reverse-transition"
              >
                <template #activator>
                  <v-btn
                    :small="$vuetify.breakpoint.xsOnly"
                    class="blue-grey lighten-2 elevation-2 ma-0"
                    dark
                    icon
                  >
                    <v-icon
                      v-if="item.fab"
                      :small="$vuetify.breakpoint.xsOnly"
                    >
                      close
                    </v-icon>
                    <v-icon
                      v-else
                      :small="$vuetify.breakpoint.xsOnly"
                    >
                      menu
                    </v-icon>
                  </v-btn>
                </template>

                <v-btn
                  v-if="userCanDelete && !productBelongsToExternalCatalog(item)"
                  :small="$vuetify.breakpoint.xsOnly"
                  class="red elevation-2 ma-0 mr-1"
                  dark
                  icon
                  @click="deleteMenuItem(item)"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    delete
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanAdd && !productBelongsToExternalCatalog(item)"
                  :loading="item.DuplicateIsLoading"
                  :small="$vuetify.breakpoint.xsOnly"
                  class="orange elevation-2 ma-0 mr-1"
                  dark
                  icon
                  @click="duplicateMenu(item)"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    difference
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :small="$vuetify.breakpoint.xsOnly"
                  class="cyan elevation-2 ma-0 mr-1"
                  dark
                  icon
                  @click="showMenuEditDialog(item)"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    edit
                  </v-icon>
                </v-btn>
              </v-speed-dial>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <menu-add-dialog
      :catalogs="catalogs"
      :is-menu-item-edit="isMenuItemEdit"
      :menu-to-edit="selectedItem"
      :tags="tags"
      :taxes="taxes"
      :visible.sync="addEditMenuDialog"
    />

    <menu-external-edit-dialog
      :catalogs="catalogs"
      :is-menu-item-edit="isMenuItemEdit"
      :menu-to-edit="selectedItem"
      :tags="tags"
      :taxes="taxes"
      :visible.sync="externalMenuEditDialog"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Menu.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.Menu.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Menu.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>

import MenuAddDialog          from './MenuAddDialog.vue'
import ConfirmDialog          from '@/components/common/ConfirmDialog'
import AppData                from '@/mixins/appdata'
import Auth                   from '@/mixins/auth'
import Watchable              from '@/mixins/watchable'
import Translatable           from '@/mixins/translatable'
import { API, APICall }       from '@/api/Api'
import FilterSearch           from '@/components/common/filter/FilterSearch.vue'
import FilterOptions          from '@/components/common/filter/FilterOptions.vue'
import FilterCommon           from '@/mixins/filter/filterCommon'
import DataTable              from '@/mixins/data-table/dataTable'
import { formatUnitQuantity } from '@/lib/currency/currency'
import TaxesCommon            from '@/mixins/taxes/taxesCommon'
import MenuExternalEditDialog from '@/components/restaurant/kitchen/MenuExternalEditDialog.vue'
import { getContrastColor }   from '@/lib/color'
import DisplayEditableValue   from '@/components/common/DisplayEditableValue.vue'
import googleTranslate        from '@/lib/google-translate/googleTranslate'

export default {
  components: {
    DisplayEditableValue,
    FilterOptions,
    FilterSearch,
    'menu-add-dialog'          : MenuAddDialog,
    'menu-external-edit-dialog': MenuExternalEditDialog,
    'confirm-dialog'           : ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, Watchable, FilterCommon, DataTable, TaxesCommon],
  data () {
    return {
      filtersModel: {
        lang         : 'Restaurant.Menu',
        filterCatalog: {
          key  : 'filter_catalog',
          value: [],
          items: []
        },
        filterCategory: {
          key  : 'filter_category',
          value: [],
          items: []
        },
        filterStatus: {
          key  : 'filter_status',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterStatus.Items')
        },
        filterActive: {
          key  : 'filter_active',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterActive.Items')
        },
        filterActivePos: {
          key  : 'filter_active_pos',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterActivePos.Items')
        },
        filterFeatured: {
          key  : 'filter_featured',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterFeatured.Items')
        },
        filterResetStock: {
          key  : 'filter_reset_stock',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterResetStock.Items')
        },
        filterResetStatus: {
          key  : 'filter_reset_status',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterResetStatus.Items')
        },
        filterCustomPrice: {
          key  : 'filter_custom_price',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterCustomPrice.Items')
        },
        filterCalculatedPrice: {
          key  : 'filter_calculated_price',
          value: null,
          items: this.$t('Restaurant.Menu.Filter.filterCalculatedPrice.Items')
        }
      },
      taxes                     : [],
      tags                      : [],
      catalogs                  : [],
      confirmDialogDeleteVisible: false,
      categories                : [],
      selectedItem              : null,
      isMenuItemEdit            : false,
      addEditMenuDialog         : false,
      externalMenuEditDialog    : false,
      dataTable                 : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Menu.All,
          save  : window.SocketCommand.Menu.Save,
          delete: window.SocketCommand.Menu.Delete
        },
        options: {
          defaultSortBy: 'menu_id',
          sortBy       : ['menu_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Image'),
            value    : 'color',
            align    : 'center',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Id'),
            value    : 'menu_id',
            align    : 'right',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Name'),
            value    : 'menu_name',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Catalog'),
            value    : 'catalog',
            align    : 'left',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Tags'),
            value    : 'tags',
            align    : 'left',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Tax'),
            value    : 'tax_rate',
            align    : 'center',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Languages'),
            value    : 'languages',
            align    : 'center',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Price'),
            value    : 'menu_price',
            align    : 'right',
            sortable : true,
            width    : 100,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Priority'),
            value    : 'menu_priority',
            align    : 'center',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Stock'),
            value    : 'stock_qty',
            align    : 'right',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Status'),
            value    : 'menu_status',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Visible'),
            value    : 'menu_active_pos',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Menu.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption px-2',
            width    : 50
          }
        ]
      },
      tmpMenu: {
        Active                              : true,
        ActivePos                           : true,
        CategoryName                        : '',
        ActiveLabel                         : this.$t('Common.Button.Yes').toLocaleUpperCase(this.$i18n.locale),
        Name                                : '',
        Quantity                            : '0',
        Price                               : '',
        Id                                  : null,
        Description                         : '',
        SpecialPrice                        : '',
        StatusLabel                         : this.$t('Common.Button.Toggle.Active'),
        Status                              : true,
        SpecialId                           : null,
        IsSpecial                           : false,
        CategoryId                          : null,
        Categories                          : [],
        Catalogs                            : [],
        ShowCalculatedPrice                 : false,
        SpecialStatus                       : false,
        SubtractStock                       : false,
        Lang                                : {},
        TaxId                               : null,
        TaxIdOld                            : null,
        TaxLang                             : null,
        TaxRate                             : null,
        Tax2Id                              : null,
        Tax2IdOld                           : null,
        Tax2Lang                            : null,
        Tax2Rate                            : null,
        ClassificationTypeCode              : null,
        ClassificationTypeCodeOld           : null,
        ClassificationCategoryCode          : null,
        ClassificationCategoryCodeOld       : null,
        ClassificationTypeCodeInvoice       : null,
        ClassificationTypeCodeInvoiceOld    : null,
        ClassificationCategoryCodeInvoice   : null,
        ClassificationCategoryCodeInvoiceOld: null,
        PrintGroup                          : [],
        PrintGroupOld                       : null,
        UnitId                              : 1,
        UnitQuantity                        : 1,
        Barcode                             : '',
        Charges                             : [],
        Tags                                : [],
        Details                             : {
          Categories                       : [],
          Catalogs                         : [],
          StockQuantity                    : '0',
          Name                             : '',
          Featured                         : false,
          LabelComments                    : '',
          MealtimeId                       : '0',
          MinimumQuantity                  : '1',
          Price                            : '',
          Id                               : null,
          PriceFmt                         : '',
          Description                      : '',
          SpecialStartDate                 : null,
          SpecialPrice                     : '',
          SpecialEndDateFmt                : null,
          SpecialEndDate                   : null,
          SpecialPriceFmt                  : '',
          SpecialId                        : null,
          IsSpecial                        : false,
          Image                            : '',
          SpecialStatus                    : false,
          Comment                          : '',
          Options                          : [],
          ButtonUpdate                     : this.$t('Common.Button.Update').toLocaleUpperCase(this.$i18n.locale),
          Priority                         : '0',
          RowId                            : '',
          SpecialStartDateFmt              : null,
          ButtonAdd                        : this.$t('Common.Button.Add').toLocaleUpperCase(this.$i18n.locale),
          LabelQuantity                    : this.$t('Common.Misc.Quantity'),
          Lang                             : {},
          TaxId                            : null,
          TaxLang                          : null,
          TaxRate                          : null,
          Tax2Id                           : null,
          Tax2Lang                         : null,
          Tax2Rate                         : null,
          ClassificationTypeCode           : null,
          ClassificationCategoryCode       : null,
          ClassificationTypeCodeInvoice    : null,
          ClassificationCategoryCodeInvoice: null,
          PrintGroup                       : [],
          UnitId                           : 1,
          UnitQuantity                     : 1,
          Barcode                          : '',
          Charges                          : [],
          Tags                             : [],
          ResetStockValues                 : null,
          ResetStock                       : false,
          ResetStatusValues                : null,
          ResetStatus                      : false
        },
        tmpDetails: {
          Categories                : [],
          Catalogs                  : [],
          StockQuantity             : '0',
          Name                      : '',
          Featured                  : false,
          LabelComments             : '',
          MealtimeId                : '0',
          MinimumQuantity           : '1',
          Price                     : '',
          Id                        : null,
          PriceFmt                  : '',
          Description               : '',
          SpecialStartDate          : null,
          SpecialPrice              : '',
          SpecialEndDateFmt         : null,
          SpecialEndDate            : null,
          SpecialPriceFmt           : '',
          SpecialId                 : null,
          IsSpecial                 : false,
          Image                     : '',
          SpecialStatus             : false,
          Comment                   : '',
          Options                   : [],
          ButtonUpdate              : this.$t('Common.Button.Update').toLocaleUpperCase(this.$i18n.locale),
          Priority                  : '0',
          RowId                     : '',
          SpecialStartDateFmt       : null,
          ButtonAdd                 : this.$t('Common.Button.Add').toLocaleUpperCase(this.$i18n.locale),
          LabelQuantity             : this.$t('Common.Misc.Quantity'),
          Lang                      : {},
          TaxId                     : null,
          TaxLang                   : null,
          TaxRate                   : null,
          Tax2Id                    : null,
          Tax2Lang                  : null,
          Tax2Rate                  : null,
          ClassificationTypeCode    : null,
          ClassificationCategoryCode: null,
          PrintGroup                : [],
          UnitId                    : 1,
          UnitQuantity              : 1,
          Barcode                   : '',
          Charges                   : [],
          Tags                      : [],
          ResetStockValues          : null,
          ResetStock                : false,
          ResetStatusValues         : null,
          ResetStatus               : false
        }
      }
    }
  },
  computed: {
    categoriesFilterData () {
      return this.categories
    },

    catalogsFilterData () {
      return this.catalogs.map(o => {
        o.Avatar = { Color: `#${ o.Color }` }
        return o
      })
    }
  },
  watch: {
    'filtersModel.filterCatalog.value': function (newVal) {
      this.filtersModel.filterCategory.items = this.categoriesFilterData.filter(category => newVal?.length ? !!category.Catalogs.find(catalog => newVal.includes(catalog)) : true)
    }
  },

  created () {
    // console.log('1. created');

    // Init Custom Filter Values
    this.filtersModel.filterCategory.items = this.categoriesFilterData
    this.filtersModel.filterCatalog.items = this.catalogsFilterData
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Category.All, this.setCategoriesData)
    this.$bus.$on(window.SocketCommand.Menu.GetAdmin, this.setDataDetails)
    this.$bus.$on(window.SocketCommand.Menu.Duplicate, this.onDuplicateMenuResult)
    this.$bus.$on(window.SocketCommand.Menu.UpdateValue, this.getData)

    window.callAS(window.SocketCommand.Category.All, {
      config    : 0,
      page_limit: -1,
      all       : true
    })
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Category.All, this.setCategoriesData)
    this.$bus.$off(window.SocketCommand.Menu.GetAdmin, this.setDataDetails)
    this.$bus.$off(window.SocketCommand.Menu.Duplicate, this.onDuplicateMenuResult)
    this.$bus.$off(window.SocketCommand.Menu.UpdateValue, this.getData)
  },
  methods: {
    getContrastColor,
    formatUnitQuantity,

    getMenuTagById (tagId) {
      return this.tags?.find(tag => tag.Id === tagId) || null
    },

    getMenuTags (menu) {
      const menuTags = menu?.Tags || []
      const tags = []

      menuTags.forEach(tagId => {
        const tag = this.getMenuTagById(tagId)
        if (tag && !tag.System) tags.push(tag)
      })

      return tags
    },

    subtractStockThresholdColor (menu) {
      const subtractStockThresholds = this.appConfig?.LOCATION_DATA?.SubtractStockThreshold || this.$t('Settings.Order.Field.SubtractStockThreshold.Values')
      const valueMultiplier = menu.UnitId > 1 ? this.getMeasurementUnitById(menu.UnitId).Rate : 1

      if (menu.SubtractStock) {
        if (menu.StockQuantity <= ((subtractStockThresholds.Tier3.Value || 1) * valueMultiplier)) return subtractStockThresholds.Tier3.Color
        if (menu.StockQuantity <= ((subtractStockThresholds.Tier2.Value || 1) * valueMultiplier)) return subtractStockThresholds.Tier2.Color
        if (menu.StockQuantity <= ((subtractStockThresholds.Tier1.Value || 1) * valueMultiplier)) return subtractStockThresholds.Tier1.Color

        return subtractStockThresholds.Default.Color
      }

      return 'grey'
    },

    showMenuAddDialog () {
      this.isMenuItemEdit = false

      const menu = JSON.parse(JSON.stringify(this.tmpMenu))
      menu.TaxId = menu.Details.TaxId = this.defaultTax.TaxId
      menu.Tax2Id = menu.Details.Tax2Id = this.defaultTax.Tax2Id
      menu.ClassificationTypeCode = menu.Details.ClassificationTypeCode = this.defaultClassification.ClassificationTypeCode
      menu.ClassificationCategoryCode = menu.Details.ClassificationCategoryCode = this.defaultClassification.ClassificationCategoryCode
      menu.ClassificationTypeCodeInvoice = menu.Details.ClassificationTypeCodeInvoice = this.defaultClassification.ClassificationTypeCodeInvoice
      menu.ClassificationCategoryCodeInvoice = menu.Details.ClassificationCategoryCodeInvoice = this.defaultClassification.ClassificationCategoryCodeInvoice

      this.selectedItem = menu

      this.addEditMenuDialog = true
    },

    showMenuEditDialog (item) {
      this.isMenuItemEdit = true

      const menu = JSON.parse(JSON.stringify(item))
      menu.TaxId = menu.TaxId ? menu.TaxId : this.defaultTax.TaxId
      if (menu.Details) menu.Details.TaxId = menu.Details.TaxId ? menu.Details.TaxId : this.defaultTax.TaxId
      menu.Tax2Id = menu.Tax2Id ? menu.Tax2Id : this.defaultTax.Tax2Id
      if (menu.Details) menu.Details.Tax2Id = menu.Details.Tax2Id ? menu.Details.Tax2Id : this.defaultTax.Tax2Id

      menu.ClassificationTypeCode = menu.ClassificationTypeCode ? menu.ClassificationTypeCode : this.defaultClassification.ClassificationTypeCode
      if (menu.Details) menu.Details.ClassificationTypeCode = menu.Details.ClassificationTypeCode ? menu.Details.ClassificationTypeCode : this.defaultClassification.ClassificationTypeCode
      menu.ClassificationCategoryCode = menu.ClassificationCategoryCode ? menu.ClassificationCategoryCode : this.defaultClassification.ClassificationCategoryCode
      if (menu.Details) menu.Details.ClassificationCategoryCode = menu.Details.ClassificationCategoryCode ? menu.Details.ClassificationCategoryCode : this.defaultClassification.ClassificationCategoryCode

      menu.ClassificationTypeCodeInvoice = menu.ClassificationTypeCodeInvoice ? menu.ClassificationTypeCodeInvoice : this.defaultClassification.ClassificationTypeCodeInvoice
      if (menu.Details) menu.Details.ClassificationTypeCodeInvoice = menu.Details.ClassificationTypeCodeInvoice ? menu.Details.ClassificationTypeCodeInvoice : this.defaultClassification.ClassificationTypeCodeInvoice
      menu.ClassificationCategoryCodeInvoice = menu.ClassificationCategoryCodeInvoice ? menu.ClassificationCategoryCodeInvoice : this.defaultClassification.ClassificationCategoryCodeInvoice
      if (menu.Details) menu.Details.ClassificationCategoryCodeInvoice = menu.Details.ClassificationCategoryCodeInvoice ? menu.Details.ClassificationCategoryCodeInvoice : this.defaultClassification.ClassificationCategoryCodeInvoice

      this.selectedItem = menu

      window.callAS(window.SocketCommand.Menu.GetAdmin, { menu_id: this.selectedItem.Id })
    },

    onCategoryChipClick (categoryId) {
      if (!this.filtersModel.filterCategory.value.includes(categoryId)) {
        this.filtersModel.filterCategory.value.push(categoryId)
      }
    },

    onCatalogChipClick (catalogId) {
      if (!this.filtersModel.filterCatalog.value.includes(catalogId)) {
        this.filtersModel.filterCatalog.value.push(catalogId)
      }
    },

    getCatalogById (catalogId) {
      return this.catalogs?.find(c => c.Id === catalogId) || ''
    },

    productBelongsToExternalCatalog (product) {
      return product?.Catalogs?.some(catalogId => this.isExternalCatalog(catalogId))
    },

    isExternalCatalog (catalogId) {
      return !!this.getCatalogById(catalogId)?.Api
    },

    setData (data) {
      this.taxes = data?.taxes || []
      this.catalogs = data?.catalogs || []
      this.tags = data?.tags || []
      this.filtersModel.filterCatalog.items = this.catalogsFilterData

      this.setDataInternal(data)

      const categoryId = this.$route.params?.categoryId
      if (categoryId && !this.filtersModel.filterCategory.value.includes(categoryId)) {
        this.$route.params.categoryId = null
        this.filtersModel.filterCategory.value.push(categoryId)
      }

      const catalogId = this.$route.params?.catalogId
      if (catalogId && !this.filtersModel.filterCatalog.value.includes(catalogId)) {
        this.$route.params.catalogId = null
        this.filtersModel.filterCatalog.value.push(catalogId)
      }
    },

    setCategoriesData (data) {
      data = (data?.items || []).map((o) => {
        if (o.Image) {
          o.Avatar = {
            Img: `${ this.appConfig.LOCATION_DATA.CdnImagesUrl }${ o.Image }`
          }
        } else {
          o.Avatar = {
            Icon : 'category',
            Color: 'grey'
          }
        }
        const activeCatalogName = this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => o.Catalogs.includes(catalog.Id))?.map(o => o.Name)?.join(', ') || ''
        o.Description = activeCatalogName
        return o
      })

      this.categories = data || []
      this.filtersModel.filterCategory.items = this.categoriesFilterData
    },

    setDataDetails (data) {
      if (data?.Options) {
        for (let i = 0; i < data.Options.length; i++) {
          for (let ii = 0; ii < data.Options[i].OptionValues.length; ii++) {
            if (data.Options[i].DisplayType === 'unit') {
              data.Options[i].OptionValues[ii].Price = parseInt(data.Options[i].OptionValues[ii].Price)
            } else {
              data.Options[i].OptionValues[ii].Price = parseFloat(data.Options[i].OptionValues[ii].Price).toFixed(2)
            }
          }
        }
      }

      this.$set(this.selectedItem, 'Details', data)
      this.$set(this.selectedItem, 'tmpDetails', data)

      if (this.productBelongsToExternalCatalog(this.selectedItem)) {
        this.externalMenuEditDialog = true
      } else {
        this.addEditMenuDialog = true
      }
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.Menu.UpdateStatus, {
        Id       : item.Id,
        Status   : item.Status ? 1 : 0,
        Active   : item.Active ? 1 : 0,
        ActivePos: item.ActivePos ? 1 : 0,
        Featured : item.Featured ? 1 : 0
      })
    },

    getCategoryCatalog (categoryId, itemCatalogs) {
      const categoryItem = this.getCategoryData(categoryId)
      return itemCatalogs.length > 1 ? this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => categoryItem?.Catalogs?.includes(catalog.Id))?.map(c => c.Name)?.join(', ') || '' : ''
    },

    getCategoryData (categoryId) {
      return this.categories.find(categoryObj => parseInt(categoryObj.Id) === parseInt(categoryId))
    },

    duplicateMenu (item) {
      this.$set(item, 'DuplicateIsLoading', true)
      window.callAS(window.SocketCommand.Menu.Duplicate, { Id: item.Id })
    },

    onDuplicateMenuResult (data) {
      const viewDataItems = this.viewData?.items || this.viewData?.table?.items?.items
      const item = viewDataItems?.find(menu => parseInt(menu?.Id) === parseInt(data?.result?.old))
      if (item) this.$set(item, 'DuplicateIsLoading', false)

      if (data.status === 'success') {
        this.getData()
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.MenuAddDialog.Notification.Duplicate', { id: data?.result?.new || '' }),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.GenericError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    deleteMenuItem (item) {
      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Menu.Delete, { menu_id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Menu.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    saveMenuItemEditPrice (data) {
      if (!data) return

      window.callAS(window.SocketCommand.Menu.UpdateValue, {
        Id   : data.Id,
        Price: data.Value
      })
    },

    saveMenuItemEditQuantity (data) {
      if (!data) return

      window.callAS(window.SocketCommand.Menu.UpdateValue, {
        Id      : data.Id,
        StockQty: data.Value
      })
    },

    saveMenuItemEditPriority (data) {
      if (!data) return

      window.callAS(window.SocketCommand.Menu.UpdateValue, {
        Id      : data.Id,
        Priority: data.Value
      })
    },

    invertColor (hex, bw) {
      if (!hex) return '#FFFFFF'

      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      if (hex.length !== 6) {
        // eslint-disable-next-line no-console
        console.warn('Invalid HEX color.')
        return '#FFFFFF'
      }
      let r = parseInt(hex.slice(0, 2), 16)
      let g = parseInt(hex.slice(2, 4), 16)
      let b = parseInt(hex.slice(4, 6), 16)
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#323232'
          : '#FFFFFF'
      }
      // invert color components
      r = (255 - r).toString(16)
      g = (255 - g).toString(16)
      b = (255 - b).toString(16)
      // pad each with zeros and return
      return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
    },

    async translateAllItems (localeFrom = this.defaultLanguage.Code, localeTo) {
      if (this.selectedLanguages.length <= 0 || !this.userIsSuperExtraAdmin) return
      this.dataTable.loading = true
      const langs = localeTo ? [{ Code: localeTo }] : this.selectedLanguages.filter(lang => lang.Code !== localeFrom) || []

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].Code

        for (let cat = 0; cat < this.categories.length; cat++) {
          const payload = APICall.CreatePayload(window.SocketCommand.Menu.All, {
            config         : 0,
            page           : 1,
            page_limit     : -1,
            sort_by        : 'menu_id',
            order_by       : 'DESC',
            filter_search  : '',
            filter_category: this.categories[cat].Id || ''
          })
          // eslint-disable-next-line camelcase
          const items_result = await API.post(APICall.api, payload)

          const lngArrName = []
          const lngArrDesc = []
          const lngArrLongDesc = []
          for (let i = 0; i < items_result.data.length; i++) {
            const payload = APICall.CreatePayload(window.SocketCommand.Menu.GetAdmin, { menu_id: items_result.data[i].Id })
            let item = await API.post(APICall.api, payload)
            item = item.data
            items_result.data[i].Details = item

            lngArrName.push(item.Lang[localeFrom].Name || '')
            lngArrDesc.push(item.Lang[localeFrom].Description || '')
            lngArrLongDesc.push(item.Lang[localeFrom].LongDescription || '')
          }

          const resultName = await googleTranslate(lngArrName, localeTo, localeFrom)
          const resultDesc = await googleTranslate(lngArrDesc, localeTo, localeFrom)
          const resultLongDesc = await googleTranslate(lngArrLongDesc, localeTo, localeFrom)

          if (resultName && resultDesc && resultLongDesc) {
            for (let i = 0; i < items_result.data.length; i++) {
              const item = items_result.data[i]
              const langObj = {}
              for (const lang of this.selectedLanguages) {
                langObj[lang.Code] = {
                  Name           : '',
                  Description    : '',
                  LongDescription: '',
                  Slug           : '',
                  Locale         : lang.Code
                }
              }
              item.Lang = Object.assign({}, langObj, item.Lang)
              item.Lang[localeTo].Name = Array.isArray(resultName) ? resultName[i].translatedText : resultName.translatedText
              item.Lang[localeTo].Description = Array.isArray(resultName) ? resultDesc[i].translatedText : resultDesc.translatedText
              item.Lang[localeTo].LongDescription = Array.isArray(resultLongDesc) ? resultLongDesc[i].translatedText : resultLongDesc.translatedText
              item.Lang[localeTo].Slug = `${ item.Lang[localeFrom].Slug }_${ localeTo }`

              item.Details.Categories = JSON.parse(JSON.stringify(item.Categories))

              await API.post(APICall.api, APICall.CreatePayload(window.SocketCommand.Menu.Save, { menu: item }))
            }
          }
        }
      }
      this.getData()
      this.dataTable.loading = false
    }

  }
}
</script>

<style scoped>
/deep/ .chip-column.v-chip .v-chip__content {
  width           : 100%;
  justify-content : space-between;
}

/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--switch .v-input--selection-controls__input {
  margin-right : 0;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 65px !important;*/
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}

/deep/ .chip:focus:not(.chip--disabled):after, .chip--active:not(.chip--disabled):after, .chip--selected:not(.chip--disabled):after {
  top  : 0;
  left : 0;
}
</style>
