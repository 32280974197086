<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card ref="dialogCard">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.OptionAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.OptionAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!optionToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.OptionAddDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.OptionAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.OptionAddDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.OptionAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-tabs
          v-model="activeTab"
          :scrollable="false"
          background-color="indigo"
          dark
          slider-color="light-green"
        >
          <v-tab
            key="tab1"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab1"
          >
            {{ $t('Restaurant.OptionAddDialog.Tab.Details').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="tab2"
            :disabled="isSimpleOptionSelected"
            active-class="v-tabs__item--active indigo darken-2"
            href="#tab2"
          >
            {{ $t('Restaurant.OptionAddDialog.Tab.Values').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-btn
            v-if="activeTab === 'tab2'"
            absolute
            class="green"
            dark
            icon
            right
            style="margin-top: 6px;"
            @click="addOptionRow"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-tabs>

        <v-card-text
          ref="dialogCardText"
          class="fill-height pa-3"
        >
          <v-form
            ref="optionForm"
            v-model="optionFormValid"
            @submit.prevent
          >
            <v-tabs-items v-model="activeTab">
              <v-tab-item
                key="tab1"
                value="tab1"
              >
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <template v-if="selectedLanguages.length > 1">
                        <v-tabs
                          v-model="languageTab"
                          :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                          grow
                          height="40"
                          slider-color="blue"
                        >
                          <template v-for="lang in selectedLanguages">
                            <v-tab
                              :key="lang.Code"
                              :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                            >
                              <v-avatar
                                v-if="lang.ImageUrl"
                                class="mr-2"
                                size="20"
                              >
                                <v-img :src="lang.ImageUrl" />
                              </v-avatar>
                              {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                            </v-tab>
                          </template>
                        </v-tabs>
                      </template>

                      <v-tabs-items v-model="languageTab">
                        <template v-for="lang in selectedLanguages">
                          <v-tab-item
                            :key="lang.Code"
                            eager
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editOption.Lang[lang.Code].Name"
                                  :counter="64"
                                  :maxlength="64"
                                  :hint="$t('Restaurant.OptionAddDialog.Field.Name.Hint')"
                                  :label="$t('Restaurant.OptionAddDialog.Field.Name.Label')"
                                  :prepend-icon="$t('Restaurant.OptionAddDialog.Field.Name.Icon')"
                                  :rules="validationRules.optionForm.Name"
                                  class="pr-2"
                                  clearable
                                  required
                                >
                                  <template
                                    v-if="lang.Code !== defaultLanguage.Code"
                                    #append-outer
                                  >
                                    <translate-field-button
                                      :item="editOption"
                                      :locale-from="defaultLanguage.Code"
                                      :locale-to="lang.Code"
                                      field="Name"
                                    />
                                  </template>
                                </v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-text-field
                                  v-model="editOption.Lang[lang.Code].PrintableName"
                                  :counter="64"
                                  :maxlength="64"
                                  :hint="$t('Restaurant.OptionAddDialog.Field.PrintableName.Hint')"
                                  :label="$t('Restaurant.OptionAddDialog.Field.PrintableName.Label')"
                                  :prepend-icon="$t('Restaurant.OptionAddDialog.Field.PrintableName.Icon')"
                                  :rules="validationRules.optionForm.PrintableName"
                                  class="pr-2"
                                  clearable
                                  required
                                >
                                  <template
                                    v-if="lang.Code !== defaultLanguage.Code"
                                    #append-outer
                                  >
                                    <translate-field-button
                                      :item="editOption"
                                      :locale-from="defaultLanguage.Code"
                                      :locale-to="lang.Code"
                                      field="PrintableName"
                                    />
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-tab-item>
                        </template>
                      </v-tabs-items>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col
                      sm="6"
                      cols="12"
                    >
                      <v-select
                        v-model="editOption.DisplayType"
                        :hint="$t('Restaurant.OptionAddDialog.Field.DisplayType.Hint')"
                        :items="optionTypesFiltered"
                        :label="$t('Restaurant.OptionAddDialog.Field.DisplayType.Label')"
                        :prepend-icon="editOption.DisplayType === 'radio' ? $t('Restaurant.OptionAddDialog.Field.DisplayType.Icon.Radio') : editOption.DisplayType ==='checkbox' ? $t('Restaurant.OptionAddDialog.Field.DisplayType.Icon.Checkbox') : $t('Restaurant.OptionAddDialog.Field.DisplayType.Icon.All')"
                        :rules="validationRules.optionForm.DisplayType"
                        class="pr-2"
                        item-text="Name"
                        item-value="Id"
                        persistent-hint
                        required
                        single-line
                      >
                        <template #selection="data">
                          {{ getTranslatedField(data.item, 'Name') }}
                        </template>

                        <template #item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{ getTranslatedField(data.item, 'Name') }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      sm="6"
                      cols="12"
                    >
                      <v-text-field
                        v-model="editOption.Priority"
                        v-mask="'#####'"
                        :hint="$t('Restaurant.OptionAddDialog.Field.Priority.Hint')"
                        :label="$t('Restaurant.OptionAddDialog.Field.Priority.Label')"
                        :prepend-icon="$t('Restaurant.OptionAddDialog.Field.Priority.Icon')"
                        :rules="validationRules.optionForm.Priority"
                        type="number"
                        step="1"
                        class="pr-2"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item
                key="tab2"
                value="tab2"
              >
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row dense>
                    <v-col cols="12">
                      <div
                        v-if="editOption.OptionValues.length<=0"
                        class="fill-height"
                        style="position: relative; height: 350px;"
                      >
                        <div
                          class="text-center grey--text pa-6"
                          style="position: absolute; width: 100%; top: 50%; transform: translateY(-50%);"
                        >
                          <v-icon
                            class="mb-4"
                            color="grey lighten-2"
                            size="128"
                          >
                            {{ $t('Restaurant.OptionAddDialog.NoData.Icon') }}
                          </v-icon>
                          <div
                            class="text-h6 mb-2"
                            style="height: auto;"
                          >
                            {{ $t('Restaurant.OptionAddDialog.NoData.Title') }}
                          </div>
                          <div
                            class="text-subtitle-1"
                            style="height: auto;"
                          >
                            {{ $t('Restaurant.OptionAddDialog.NoData.Subtitle') }}
                          </div>
                        </div>
                      </div>

                      <div v-else>
                        <v-alert
                          :value="true"
                          class="pa-1 mb-4"
                          color="error"
                          icon="warning"
                          outlined
                        >
                          <strong>{{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:</strong> {{ $t('Restaurant.OptionAddDialog.Notification.Remove') }}
                        </v-alert>
                        <div class="divTable">
                          <div class="divTableHeading">
                            <div class="divTableRow">
                              <div class="divTableHead">
                                &nbsp;
                              </div>
                              <div class="divTableHead">
                                {{ $t('Restaurant.OptionAddDialog.ListView.Header.Name') }}
                              </div>
                              <div class="divTableHead">
                                {{ editOption.DisplayType === 'unit' ? $t('Restaurant.OptionAddDialog.Unit.HeaderTitle') : $t('Restaurant.OptionAddDialog.ListView.Header.Price') }}
                              </div>
                              <div class="divTableHead">
                                &nbsp;
                              </div>
                            </div>
                          </div>

                          <draggable
                            v-model="editOption.OptionValues"
                            class="divTableBody"
                            handle=".my-handle"
                            @sort="onSort"
                          >
                            <div
                              v-for="(optionValue, index) in editOption.OptionValues"
                              :key="index"
                              class="divTableRow"
                            >
                              <div
                                class="divTableCell"
                                style="width: 50px;"
                              >
                                <v-icon
                                  class="my-handle"
                                  style="cursor: move;"
                                >
                                  drag_handle
                                </v-icon>
                              </div>
                              <div class="divTableCell pb-2">
                                <template v-for="lang in selectedLanguages">
                                  <v-text-field
                                    :key="lang.Code"
                                    v-model="optionValue.Lang[lang.Code].Name"
                                    :placeholder="$t('Restaurant.OptionAddDialog.ListView.Header.Name')"
                                    hide-details
                                    required
                                    single-line
                                  >
                                    <template #prepend-inner>
                                      <v-avatar
                                        v-if="lang.ImageUrl"
                                        class="ma-0 pa-0 mr-2"
                                        size="17"
                                      >
                                        <v-img :src="lang.ImageUrl" />
                                      </v-avatar>
                                    </template>

                                    <template
                                      v-if="lang.Code !== defaultLanguage.Code"
                                      #append-outer
                                    >
                                      <translate-field-button
                                        :item="optionValue"
                                        :locale-from="defaultLanguage.Code"
                                        :locale-to="lang.Code"
                                        field="Name"
                                      />
                                    </template>
                                  </v-text-field>
                                </template>
                              </div>
                              <div
                                class="divTableCell"
                                style="width: 150px;"
                              >
                                <v-text-field
                                  v-model="optionValue.Price"
                                  :append-icon="editOption.DisplayType !== 'unit' ? appCurrency.Icon : 'mdi-counter'"
                                  :placeholder="editOption.DisplayType === 'unit' ? $t('Restaurant.OptionAddDialog.Unit.HeaderTitle') : $t('Restaurant.OptionAddDialog.ListView.Header.Price')"
                                  :hint="editOption.DisplayType === 'unit' ? $t('Restaurant.OptionAddDialog.Unit.ValueHint') : ''"
                                  required
                                  single-line
                                  type="number"
                                  min="0"
                                  step="any"
                                />
                              </div>
                              <div class="divTableCell text-center px-0">
                                <v-btn
                                  class="pa-0 ml-0 red"
                                  dark
                                  icon
                                  left
                                  small
                                  @click="removeOptionRow(index)"
                                >
                                  <v-icon>close</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <template v-if="activeTab === 'tab1'">
            <translate-all-fields-button
              v-if="selectedLanguages.length > 1"
              :disabled="loading"
              :item="editOption"
              :locale-from="defaultLanguage.Code"
              :field="['Name', 'PrintableName']"
            />
          </template>
          <template v-else-if="activeTab === 'tab2'">
            <translate-all-fields-button
              v-if="selectedLanguages.length > 1"
              :disabled="loading"
              :item="editOption.OptionValues"
              :locale-from="defaultLanguage.Code"
              :field="['Name']"
            />
          </template>

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import draggable                from 'vuedraggable'
import Resizable                from '@/mixins/resizable'
import AppData                  from '@/mixins/appdata'
import Translatable             from '@/mixins/translatable'
import Auth                     from '@/mixins/auth'
import googleTranslate          from '@/lib/google-translate/googleTranslate'
import TranslateFieldButton     from '@/components/common/translation/translateFieldButton.vue'
import TranslateAllFieldsButton from '@/components/common/translation/translateAllFieldsButton.vue'

export default {
  directives: {},
  components: {
    TranslateAllFieldsButton,
    TranslateFieldButton,
    draggable
  },
  mixins: [AppData, Auth, Translatable, Resizable],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    optionToEdit: {
      type   : Object,
      default: undefined
    },
    optionTypes: {
      type   : Array,
      default: undefined
    }
  },
  data () {
    return {
      languageTab    : 0,
      activeTab      : null,
      optionFormValid: false,
      loading        : false,
      newOption      : null,
      tmpOption      : {
        Id          : null,
        Name        : '',
        DisplayType : null,
        OptionValues: [],
        Priority    : '1',
        Lang        : {}
      },
      validationRules: {
        optionForm: {
          Name: [
            (v) => (this.selectedLanguages.length > 1 ? this.selectedLanguages.some(lang => this.editOption.Lang[lang.Code].Name) : true) || this.$t('Restaurant.OptionAddDialog.Field.Name.Error.AtLeastOneLang'),
            (v) => (this.selectedLanguages.length > 1 ? !v || (v.length >= 2 && v.length <= 64) : v && (v.length >= 2 && v.length <= 64)) || this.$t('Restaurant.OptionAddDialog.Field.Name.Error.Between')
          ],
          PrintableName: [
            (v) => !v || (v.length >= 2 && v.length <= 64) || this.$t('Restaurant.OptionAddDialog.Field.Name.Error.Between')
          ],
          DisplayType: [
            (v) => v && v.length > 0 || this.$t('Restaurant.OptionAddDialog.Field.DisplayType.Error.Required')
          ],
          Priority: [
            (v) => v && v.length >= 1 && !isNaN(v) || this.$t('Restaurant.OptionAddDialog.Field.Priority.Error.Valid')
          ]
        }
      }
    }
  },
  computed: {
    optionTypesFiltered () {
      const items = this.optionTypes || []

      // if (!this.optionToEdit) return items
      if (!this.optionToEdit) return items.filter(item => item.Id !== 'extra_charges')

      if (this.isSimpleOptionSelected) return items.filter(item => item.Id === 'label' || item.Id === 'separator')

      // return items.filter(item => item.Id === 'radio' || item.Id === 'checkbox' || item.Id === 'unit' || item.Id === 'unit_value' || item.Id === 'extra_charges')
      return items.filter(item => item.Id === 'radio' || item.Id === 'checkbox' || item.Id === 'unit' || item.Id === 'unit_value')
    },

    isSimpleOptionSelected () {
      return this.editOption?.DisplayType === 'label' || this.editOption?.DisplayType === 'separator'
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editOption: {
      get () {
        let data = null
        if (this.optionToEdit) {
          data = JSON.parse(JSON.stringify(this.optionToEdit))
        } else {
          data = JSON.parse(JSON.stringify(this.tmpOption))
        }

        const langObj = {}
        for (const lang of this.selectedLanguages) {
          langObj[lang.Code] = {
            Name  : '',
            Locale: lang.Code
          }
        }
        data.Lang = Object.assign({}, langObj, data.Lang)
        // eslint-disable-next-line no-return-assign
        data.OptionValues.map(optionValue => optionValue.Lang = Object.assign({}, langObj, optionValue.Lang))
        data.OptionValues = JSON.parse(JSON.stringify(data.OptionValues))
        this.newOption = data

        return this.newOption
      }
    }
  },
  watch: {
    isVisible (newVal) {
      this.languageTab = 0
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Menu.Options.Save, this.onSaveMenuOptionResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Menu.Options.Save, this.onSaveMenuOptionResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.optionForm.validate()

      if (this.optionFormValid) {
        this.loading = true

        const saveData = JSON.parse(JSON.stringify(this.editOption))

        saveData.Name = saveData.Lang[this.defaultLanguage.Code].Name

        if (this.isSimpleOptionSelected) saveData.OptionValues = []

        saveData.OptionValues.forEach((optionValue, index) => {
          saveData.OptionValues[index].Value = optionValue.Lang[this.defaultLanguage.Code].Name
        })
        saveData.OptionValues = saveData.OptionValues.filter(option => option.Value.trim() !== '')

        window.callAS(window.SocketCommand.Menu.Options.Save, { option: saveData })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    onSaveMenuOptionResult (data) {
      if (data.status === 'success') {
        this.newOption = null
        this.isVisible = false
        this.activeTab = 'tab1'
        this.optionFormValid = true
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.OptionAddDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },
    onCancelButtonClick () {
      this.isVisible = false
      this.optionFormValid = true
      this.activeTab = 'tab1'
      this.newOption = null
    },
    onSort () {
      this.setSortOrder()
    },
    addOptionRow () {
      const tmpObj = {
        OptionId     : this.editOption.Id,
        OptionValueId: null,
        Price        : this.editOption.DisplayType === 'unit' ? '0' : '0.00',
        PriceFmt     : this.$options.filters.currency(0),
        Priority     : this.editOption.OptionValues.length + 1,
        Value        : '',
        Lang         : {}
      }

      const langObj = {}
      for (const lang of this.selectedLanguages) {
        langObj[lang.Code] = {
          Name  : '',
          Locale: lang.Code
        }
      }
      tmpObj.Lang = { ...langObj }

      this.editOption.OptionValues.unshift(tmpObj)
      this.setSortOrder()
    },
    removeOptionRow (index) {
      this.editOption.OptionValues.splice(index, 1)
      this.setSortOrder()
    },
    setSortOrder () {
      for (let i = 0; i < this.editOption.OptionValues.length; i++) {
        this.editOption.OptionValues[i].Priority = i + 1
      }
    },
    onResize () {
      const card = this.$refs.dialogCard
      const cardText = this.$refs.dialogCardText
      if (card && card.$el && cardText) {
        cardText.style.height = 800 + 'px'
        cardText.style.height = (card.$el.offsetHeight - 200) + 'px'
      }
    },

    async translateItem (item = this.editOption, localeFrom = this.defaultLanguage.Code, localeTo) {
      if (this.selectedLanguages.length <= 0 || !this.userIsSuperAdmin) return
      this.loading = true
      const langs = localeTo ? [{ Code: localeTo }] : this.selectedLanguages.filter(lang => lang.Code !== localeFrom) || []

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].Code

        const result = await googleTranslate([
          item.Lang[localeFrom].Name || '',
          item.Lang[localeFrom].PrintableName || ''
        ], localeTo, localeFrom)

        if (result) {
          const [Name, PrintableName] = Array.isArray(result) ? result : [result]
          item.Lang[localeTo].Name = Name.translatedText
          item.Lang[localeTo].PrintableName = PrintableName.translatedText
        }
      }

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].Code
        const trs = []
        for (let opt = 0; opt < item.OptionValues.length; opt++) {
          trs.push(item.OptionValues[opt].Lang[localeFrom].Name || '')
        }

        const result = await googleTranslate(trs, localeTo, localeFrom)

        if (result) {
          for (let opt = 0; opt < item.OptionValues.length; opt++) {
            item.OptionValues[opt].Lang[localeTo].Name = Array.isArray(result) ? result[opt].translatedText : result.translatedText
          }
        }
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
.divTableCell, .divTableHead {
  padding : 3px 4px;
}
</style>
