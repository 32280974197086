export default {
  Title: 'Account',

  Toolbar: {
    Tab: {
      Overview: 'Overview',
      Billing : 'Billing Information',
      Plan    : 'Plans & Addons',
      Invoice : 'Payments & Invoices'
    }
  },

  Overview: {
    Title   : 'My Profile',
    Subtitle: 'Member since {date}',

    Field: {
      Firstname: {
        Label: 'First Name',
        Hint : 'Your First Name. eg. George, Jane, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'First Name must be between 2 and 32 characters.'
        }
      },
      Lastname: {
        Label: 'Last Name',
        Hint : 'Your Last Name. eg. Smith, Doe, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'Last Name must be between 2 and 32 characters.'
        }
      },
      Telephone: {
        Label: 'Telephone',
        Hint : 'Your Mobile or Landline. eg. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Telephone number must be between 8 and 16 digits'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Your Email. eg. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'E-mail must be up to 96 characters long',
          Valid  : 'E-mail must be valid'
        }
      },
      GroupName: {
        Label: 'User Group',
        Hint : 'Your User Group. eg. Administrator, User',
        Icon : 'group'
      },
      Pin: {
        Label: 'PIN',
        Hint : 'Your PIN number eg. 1234',
        Icon : 'fiber_pin',
        Error: {
          FixedNumber: 'PIN must be 4 digits long'
        }
      },
      Locale: {
        Label: 'System Language',
        Hint : 'Select the language you want the system to be displayed',
        Icon : 'language'
      },

      Theme: {
        Title: 'Select Theme',
        Items: [
          {
            Value: 'auto',
            Label: 'Auto',
            Hint : 'Use system theme'
          },
          {
            Value: 'dark',
            Label: 'Dark',
            Hint : 'Use dark theme'
          },
          {
            Value: 'light',
            Label: 'Light',
            Hint : 'Use light theme'
          }
        ]
      }
    },

    DeleteAccount: {
      Toolbar: {
        Title   : 'Close Account',
        Subtitle: 'Close your GETFOOD Pro account and delete all data. This action is irreversible '

      },
      Delete: 'Request Account Closing'
    }
  },

  Billing: {
    Misc: {
      Reseller: {
        Title   : 'Partner Information',
        Subtitle: 'Partner Contact Information'
      },
      ContactInfo: 'Contact Information',
      BillingInfo: 'Billing Information',
      InvoiceInfo: 'Invoice Information'
    },
    Field: {
      Firstname: {
        Label: 'First Name',
        Hint : 'Contact\'s First Name. eg. George, Jane, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'First Name must be between 2 and 128 characters.'
        }
      },
      Lastname: {
        Label: 'Last Name',
        Hint : 'Contact\'s Last Name. eg. Smith, Doe, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'Last Name must be between 2 and 128 characters.'
        }
      },
      Telephone: {
        Label: 'Telephone',
        Hint : 'Contact\'s Mobile or Landline. eg. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Telephone number must be between 8 and 16 digits'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Contact\'s Email. eg. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'E-mail must be up to 96 characters long',
          Valid  : 'E-mail must be valid'
        }
      },
      IsReceiptToggle: {
        Invoice: 'Invoice',
        Receipt: 'Receipt',
        Fnb    : 'Fnb Order',
        None   : 'No Fiscal Doc'
      },
      Company: {
        Label: 'Company Name',
        Hint : 'Company Name. eg. OTO DEV S.A.',
        Icon : 'account_balance',
        Error: {
          Between: 'Company Name must be between 2 and 100 characters'
        }
      },
      Address: {
        Label: 'Address',
        Icon : 'mdi-map-search'
      },
      Profession: {
        Label: 'Profession',
        Hint : 'Profession or Company Type eg. Pizzeria, Cafe, Homemade products etc.',
        Icon : 'account_balance',
        Error: {
          Between: 'Profession must be between 2 and 128 characters'
        }
      },
      DistinctiveTitle: {
        Label: 'Distinctive Title',
        Hint : 'Company\'s Distinctive Title eg. John\'s Store',
        Icon : 'account_balance',
        Error: {
          Between: 'Distinctive Title must be between 2 and 128 characters'
        }
      },
      Irs: {
        Label: 'Tax Office',
        Hint : 'Company\'s Tax Office eg. Athens FAEE',
        Icon : 'toll',
        Error: {
          Between: 'Tax Office must be between 2 and 128 characters'
        }
      },
      Vat: {
        Label: 'Vat Number',
        Hint : 'Company\'s Vat Number. eg. EL888223344',
        Icon : 'toll',
        Error: {
          Between: 'Vat Number must be between 7 and 12 characters'
        }
      },
      Gemi: {
        Label: 'G.E.MI',
        Hint : 'G.E.MI Reg. Num.',
        Icon : 'toll',
        Error: {
          Between: 'G.E.MI Registration  Number must be 12 characters'
        }
      },
      CompanyPhone: {
        Label: 'Telephone',
        Hint : 'Company\'s phone number. eg. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Telephone number must be between 8 and 16 digits'
        }
      },

      Country: {
        Label: 'Country',
        Hint : 'Company\'s Country. eg. Greece',
        Icon : 'map'
      },
      City: {
        Label: 'City',
        Hint : 'Company\'s City. eg. Athens',
        Icon : 'place',
        Error: {
          Between: 'City must be between 2 and 100 characters'
        }
      },
      State: {
        Label: 'State',
        Hint : 'Company\'s State. eg. Attica',
        Icon : 'place',
        Error: {
          Between: 'Company\'s state must be between 2 and 100 characters'
        }
      },
      Street: {
        Label: 'Street & Number',
        Hint : 'Company\'s Street & Number. eg. Agioy Konstantinou str 40',
        Icon : 'my_location',
        Error: {
          Between: 'Street & Number must be between 2 and 100 characters'
        }
      },
      PostCode: {
        Label: 'PostCode',
        Hint : 'Company\'s PostCode. eg. 15124',
        Icon : 'navigation',
        Error: {
          Between: 'PostCode must be between 2 and 15 characters'
        }
      }

    },
    CreditCard: {
      Title   : 'Credit or debit cards',
      Subtitle: 'The card will be charged either at the end of the month or whenever your balance exceeds the usage limit. All major credit / debit cards are accepted.',
      Body    : 'No Cards Available',
      Button  : {
        Add: 'Add New Card'
      }

    }
  },

  Invoice: {
    InvoiceList: {
      Toolbar: {
        Title   : 'Invoices',
        SubTitle: 'Invoices list'
      }
    },
    InvoiceDetails: {
      Toolbar: {
        Title   : 'Details',
        SubTitle: 'Invoice Details'
      },
      NoDataWarning: {
        Title   : 'No invoice selected',
        SubTitle: 'Select an invoice from the list'
      },
      Table: {
        Service : 'Service',
        Quantity: 'Quantity',
        Price   : 'Price',
        Total   : 'Total'
      }
    },
    NoDataWarning: {
      Title   : 'No invoices available',
      SubTitle: 'Upgrade your subscription'
    },
    Status: {
      1: {
        Title: 'Unpaid',
        Color: 'red'
      },
      2: {
        Title: 'Paid',
        Color: 'light-green'
      },
      3: {
        Title: 'Partially Paid',
        Color: 'orange'
      },
      4: {
        Title: 'Overdue',
        Color: 'red'
      },
      5: {
        Title: 'Canceled',
        Color: 'grey darken-2'
      },
      6: {
        Title: 'Draft',
        Color: 'grey darken-2'
      }
    }
  },

  Plan: {
    CurrentPlan : 'Your Current Plan: {planName}',
    UpgradePlan : 'Upgrade your subscription today and increase your productivity!',
    BillingCycle: {
      Fixed  : 'one-off fee',
      Month  : 'month',
      Monthly: 'Billed Monthly',
      Yearly : {
        Title   : 'Billed Yearly',
        Discount: 'Save 20%'
      }
    },
    Package: {
      11: {
        Name     : 'getCONTACTLESS',
        Price    : 'FREE',
        ServiceId: '12'
      },
      10: {
        Name     : 'getONLINE',
        ServiceId: '9'
      },
      30: {
        Name     : 'getPOS',
        ServiceId: '10'
      },
      60: {
        Name     : 'getSMART',
        ServiceId: '11'
      },
      1: {
        Name     : 'getENTERPRISE',
        ServiceId: '13'
      }
    },
    ExpireNotice: {
      Days: 'Today | {days} Day | {days} Days',

      Extended: {
        Title     : 'Your Current Plan: {planName}',
        ExpiringIn: '<strong>Your {hours} hours subscription extension ends today!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Your {hours} hours subscription extension ends in {days} day!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Your {hours} hours subscription extension ends in {days} days!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip'
      },
      Current: {
        Title      : 'Your Current Plan: {planName}',
        ExpiringOn : 'Your subscription expires on {date}!',
        ExpiringIn : '<strong>Your subscription ends today!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Your subscription ends in {days} day!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip | <strong>Your subscription ends in {days} days!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip',
        ExpiredOn  : '<strong>Your subscription expired {days} days ago on {date}!</strong> @:Account.Plan.ExpireNotice.Current.ExpiringTip',
        ExpiringTip: 'Contact us in order to avoid possible service interruption.'
      },
      Trial: {
        Title      : 'Trial Period of Service',
        ExpiringOn : 'The free trial expires on {date}!',
        ExpiringIn : '<strong>The free trial period ends today!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip | <strong>The free trial period ends in {days} day!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip | <strong>The free trial period ends in {days} days!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip',
        ExpiredOn  : '<strong>The free trial expired {days} days ago on {date}!</strong> @:Account.Plan.ExpireNotice.Trial.ExpiringTip',
        ExpiringTip: 'Contact us in order to upgrade your subscription.'
      },
      Contactless: {
        Title   : 'Free contactless products catalog with QR-Code',
        SubTitle: 'Upgrade your subscription, combine it with Online Ordering and offer to your customers a new and secure way to order.',
        Free    : 'Free'
      }
    }
  },

  Ticket: {
    BillingPeriod: {
      Monthly: 'monthly',
      Yearly : 'yearly'
    },
    RenewSubscriptionRequest: {
      Subject: 'Renew Subscription Request',
      Message: 'I would like to renew my subscription which expires on {SubscriptionExpiredAt} for plan {RequestPlanName} and pay {BillingPeriod}.'
    },
    ChangeSubscriptionRequest: {
      Subject           : 'Change Subscription Request',
      Message           : 'I would like to change my subscription which expires on {SubscriptionExpiredAt} from plan {CurrentPlanName} to plan {RequestPlanName} and pay {BillingPeriod}.',
      MessageContactless: 'I would like to change my subscription which expires on {SubscriptionExpiredAt} from plan {CurrentPlanName} to free plan {RequestPlanName}.'
    },
    TrialUpgradeRequest: {
      Subject: 'Trial Upgrade Request',
      Message: 'I would like to upgrade the trial which expires on {TrialExpiredAt} to a paid subscription for plan {RequestPlanName} and pay {BillingPeriod}.'
    },
    AccountCloseRequest: {
      Subject: 'Account Close Request',
      Message: 'I would like to permanently close my GETFOOD Pro account and delete all related data. I understand that this action is irreversible and all my data will be lost permanently.'
    }
  },

  DomainLocked: {
    Title       : 'Account Locked',
    SubTitle    : 'Please contact us as soon as possible.',
    Subscription: 'Your subscription expired {days} days ago on {date}!',
    Extend      : 'Extend subscription for {hours} hours',
    ExtendUsed  : 'You have already used the the {hours} hours subscription extension!',
    Icon        : 'mdi-account-lock-outline'
  },

  Misc: {
    ProfileSaved: 'Profile is saved Successfully!'
  }
}
