<template>
  <div class="d-inline-flex">
    <v-expand-x-transition>
      <div v-show="vModel">
        <slot />
      </div>
    </v-expand-x-transition>

    <v-badge
      :value="selectedItems.length"
      color="primary"
      left
      overlap
      :offset-x="22"
      :offset-y="20"
    >
      <v-btn
        :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
        :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
        :disabled="disabled"
        class="pa-0 ma-1 elevation-0"
        @click="onToggleTableActions"
      >
        <v-icon
          color="primary"
          v-text="'mdi-format-list-checkbox'"
        />
      </v-btn>

      <template #badge>
        {{ selectedItems.length }}
      </template>
    </v-badge>
  </div>
</template>

<script>

import vModel from '@/mixins/vModel'

export default {
  name      : 'TableActions',
  components: {},
  directives: {},
  mixins    : [vModel],
  props     : {
    items: {
      type   : Array,
      default: () => []
    },

    deleteAction: {
      type   : Boolean,
      default: false
    },

    cancelAction: {
      type   : Boolean,
      default: false
    },

    printAction: {
      type   : Boolean,
      default: false
    },

    deliveryAction: {
      type   : Boolean,
      default: false
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  enums: {},
  data () {
    return {}
  },
  computed: {
    selectedItems: {
      get () {
        return this.items
      },
      set (val) {
        this.$emit('update:items', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onToggleTableActions () {
      this.vModel = !this.vModel
      this.selectedItems = []
    }
  }
}
</script>

<style scoped>

</style>
