<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="$t('Restaurant.Menu.Toolbar.Button.Import.Color')">
            <v-icon dark>
              {{ $t('Restaurant.Menu.Toolbar.Button.Import.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Restaurant.Menu.Toolbar.Button.Import.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Restaurant.Menu.Toolbar.Button.Import.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="dataForm"
            v-model="dataFormValid"
            @submit.prevent
          >
            <v-container
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="model.file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    label="Excel File"
                    placeholder="Select an Excel File"
                    prepend-icon="mdi-microsoft-excel"
                    show-size
                    filled
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Import.Title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import AppData from '@/mixins/appdata'

export default {
  name      : 'MenuImportDialog',
  components: {},
  mixins    : [AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading    : false,
      dataFormValid: false,
      model        : {
        file: null
      },
      validationRules: {
        dataForm: {
          file: []
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.initData()

        this.$nextTick(() => {
          if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
        })
      }
    }
  },

  created () {
    // console.log('1. created');
  },

  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Menu.Import, this.onImportResult)
  },

  updated () {
    // console.log('3. updated');
  },

  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Menu.Import, this.onImportResult)
  },

  methods: {
    initData () {
      this.model = {
        file: null
      }
    },

    onSaveButtonClick () {
      this.$refs.dataForm.validate()

      if (this.dataFormValid) {
        this.isLoading = true

        const formData = new FormData()
        formData.append('products', this.model.file)
        window.callAS(window.SocketCommand.Menu.Import, formData, window.SocketCommand.Menu.Import, 60000, {}, true)
      }
    },
    onImportResult (response) {
      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Restaurant.Menu.Toolbar.Button.Import.SuccessMessage'),
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
        this.onCancelButtonClick()
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
        this.isLoading = false
      }
    },

    onCancelButtonClick () {
      if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      this.initData()
      this.isVisible = false
      this.isLoading = false
    }

  }
}
</script>
