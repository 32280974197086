export default {
  ListView: {
    Title: 'Staff | Staff | Staff',

    Button: {
      StaffAdd: {
        Title: 'Staff',
        Color: 'primary',
        Icon : 'add_box'
      },
      StaffGroups: {
        Title: 'Staff Groups',
        Color: 'indigo',
        Icon : 'group'
      }
    },

    Table: {
      Button: {
        Edit: {
          Title: 'Edit',
          Color: 'cyan',
          Icon : 'edit'
        }
      },
      Headers: {
        Id            : '#',
        Firstname     : 'Firstname',
        Lastname      : 'Lastname',
        Email         : 'E-mail',
        Domain        : 'Domain',
        GroupName     : 'Group',
        HasPermissions: 'Permissions',
        Locale        : 'Language',
        SingleLogin   : 'Single Login',
        Guest         : 'Guest',
        Status        : 'Status',
        DateAdded     : 'Date Added',
        Actions       : ''
      }
    }
  },
  StaffAddDialog: {
    Add: {
      Title   : 'Add Staff',
      Subtitle: 'Create New Staff',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },
    Edit: {
      Title   : 'Edit Staff',
      Subtitle: 'Edit Staff Details',
      Color   : 'indigo',
      Icon    : 'supervisor_account'
    },
    Tab: {
      Info       : 'Info',
      Permissions: 'Permissions'
    },
    Field: {
      Firstname: {
        Label: 'First Name',
        Hint : 'Staff\'s First Name. eg. George, Jane, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'First Name must be between 2 and 32 characters.'
        }
      },
      Lastname: {
        Label: 'Last Name',
        Hint : 'Staff\'s Last Name. eg. Smith, Doe, etc.',
        Icon : 'account_circle',
        Error: {
          Between: 'Last Name must be between 2 and 32 characters.'
        }
      },
      Telephone: {
        Label: 'Telephone',
        Hint : 'Staff\'s Mobile or Landline. eg. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Telephone number must be between 8 and 16 digits'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Staff\'s Email. eg. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'E-mail must be up to 96 characters long',
          Valid  : 'E-mail must be valid'
        }
      },
      GroupId: {
        Label: 'Staff Group',
        Hint : 'Staff Group. eg. Administrator, User',
        Icon : 'group'
      },
      Pin: {
        Label: 'PIN',
        Hint : 'Staff\'s PIN number eg. 1234',
        Icon : 'fiber_pin',
        Error: {
          FixedNumber: 'PIN must be 4 digits long'
        }
      },
      Locale: {
        Label: 'System Language',
        Hint : 'Select the language you want the system to be displayed',
        Icon : 'language'
      },
      Domain: {
        Label: 'Domain',
        Icon : 'http',
        Error: ''
      },
      Group: {
        Label: 'Group',
        Icon : 'group_work',
        Error: ''
      },
      VoipDomain: {
        Label: 'VoipDomain',
        Icon : 'phonelink_setup',
        Error: ''
      },
      VoipAgent: {
        Label: 'VoipAgent',
        Icon : 'contact_phone',
        Error: ''
      },
      Status: {
        Title: 'Status'
      },
      Guest: {
        Title: 'Guest Login'
      }
    }
  },

  StaffGroups: {
    Misc: {
      User       : 'Users | User | Users',
      ChooseGroup: {
        Title   : 'Choose staff group',
        Subtitle: 'Choose staff group from the list',
        Icon    : 'group'
      },
      ChoosePackage: 'Choose Plan'
    },
    Groups: {
      Title         : 'Staff Groups',
      Subtitle      : 'Access rights',
      Color         : 'indigo',
      Icon          : 'group',
      ModulesPackage: 'Modules / Package'
    },
    SingleGroup: {
      Subtitle: 'Access rights',
      Color   : 'blue',
      Icon    : 'group_work',
      Headers : {
        Right : 'Right',
        Access: 'Access',
        Api   : 'Api',
        Edit  : 'Edit',
        Add   : 'Add',
        Delete: 'Delete'
      }
    }

  }
}
