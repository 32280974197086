<template>
  <v-container
    fluid
    class="py-1"
  >
    <v-row
      ref="categoriesTabs"
      v-resize="onResize"
      v-scroll="onScroll"
    >
      <v-col
        id="categoriesListAffixContainer"
        ref="categoriesListContainer"
        class="px-0"
        cols="12"
      >
        <v-tabs
          ref="tabs"
          v-model="selectedTab"
          color="white"
          :slider-color="selectedCategoryColor || 'red'"
          slider-size="4"
          class="rounded"
          show-arrows
          dark
        >
          <template v-for="(item, index) in activeCategories">
            <v-tab
              :key="`category-${item.Id}-${index}`"
              class="px-2"
              @click="$emit('on-select-category', item)"
            >
              {{ getTranslatedField(item, 'Name') | sanitizeDiacritics }}
            </v-tab>
          </template>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Translatable from '@/mixins/translatable'

export default {
  name      : 'PosCatalogCategoriesTabs',
  components: {},
  directives: {},
  mixins    : [Translatable],
  props     : {
    activeCategories: {
      type   : Array,
      default: undefined
    },
    activeCategory: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      selectedTab         : null,
      selectedCatalog     : null,
      selectedSourceTypeId: null
    }
  },
  computed: {
    selectedCategoryColor () {
      const color = this.activeCategories[this.selectedTab]?.Color
      return color ? `#${ color }` : ''
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.$bus.$on('cart:order-source-type-selected', this.onCatalogSelected)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('cart:order-source-type-selected', this.onCatalogSelected)
  },
  destroyed () {},
  methods: {
    onCatalogSelected (sourceTypeId, catalog) {
      if (this.selectedCatalog?.Id !== catalog?.id || this.selectedSourceTypeId !== sourceTypeId) this.selectedTab = null

      this.selectedSourceTypeId = sourceTypeId
      this.selectedCatalog = catalog
    },

    onResize (e) {
      this.onScroll()
    },

    onScroll (e) {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
      if (this.$refs.categoriesTabs) {
        this.$refs.categoriesTabs.style = 'position: relative;'
        this.$refs.categoriesTabs.style = this.offsetTop > 280 ? 'z-index: 1; position: fixed; top: 64px; width: ' + this.$refs.categoriesTabs.clientWidth + 'px;' : 'position: relative; width: auto;'
      }
    }
  }
}
</script>

<style scoped>

</style>
