export default {
  Title: {
    Username : 'Πληκτρολογήστε το όνομα χρήστη σας',
    PinPass  : 'Πληκτρολογήστε το προσωπικό σας PIN',
    AdminPass: 'Πληκτρολογήστε κωδικό διαχειριστή'
  },
  Field: {
    Username: {
      Label: 'Όνομα χρήστη',
      Hint : 'Email ή UID',
      Icon : 'person_pin',
      Error: {
        Empty: 'Συμπληρώστε όνομα χρήστη',
        Min  : 'Το όνομα χρήστη πρέπει να είναι τουλάχιστον 8 χαρακτήρες'
      }
    },
    AdminPass: {
      Label: 'Κωδικός διαχειριστή',
      Icon : 'password'
    },
    RememberMe: {
      Label: 'Να με θυμάσαι'
    },
    Result: {
      WrongUsernamePin: 'Λάθος όνομα χρήστη ή PIN',
      WrongPin        : 'Λάθος Κωδικός PIN',
      WrongPassword   : 'Λάθος Κωδικός'
    }
  },
  StaffGroup: {
    11 : 'Super Admin',
    12 : 'GFP Editor',
    13 : 'CRM Editor',
    100: 'Ιδιοκτήτης',
    101: 'Ταμίας',
    102: 'Σερβιτόρος',
    103: 'Χρήστης'
  }
}
