<template>
  <div
    style="height: 40px; max-height: 40px;"
    :class="[disabled ? 'grey lighten-3' : getTableStatusColor(table, true) + (isDarkMode && parseInt(table.StatusId) === 22 ? ' darken-4' : ' lighten-4')]"
  >
    <div
      :class="disabled ? 'grey' : getTableStatusColor(table, true)"
      class="float-left"
      style="width: 5px; height: 41px;"
    />

    <div
      class="float-left"
      style="padding: 3px;"
    >
      <print-button
        v-if="canViewTable"
        :disable-tooltip="$vuetify.breakpoint.smAndUp"
        :menu-position="'top'"
        btn-class="white--text ma-1"
        icon
        small
        menu-offset-y
        @printer-selected="printer => printTableOrders(printer)"
      />
    </div>

    <div
      class="text-right"
      style="padding: 3px;"
    >
      <!-- Close -->
      <v-btn
        v-if="canCloseTable"
        :disabled="disabled"
        :class="disabled ? 'grey' : 'red'"
        class="white--text ma-1"
        icon
        small
        @click="onCloseTableButtonClick"
      >
        <v-icon small>
          {{ $t('Restaurant.Tables.Dialog.OptionMenu.Close.Icon') }}
        </v-icon>
      </v-btn>

      <!-- Pay -->
      <v-btn
        v-if="canPayTable"
        :disabled="disabled"
        :class="disabled ? 'grey' : 'light-green'"
        class="white--text ma-1"
        icon
        small
        @click="onPayButtonClick"
      >
        <v-icon small>
          {{ $t('Restaurant.Tables.Dialog.OptionMenu.PayOrder.Icon') }}
        </v-icon>
      </v-btn>

      <!-- View -->
      <v-btn
        v-if="canViewTable"
        :disabled="disabled"
        :class="disabled ? 'grey' : 'light-blue'"
        class="white--text ma-1"
        icon
        small
        @click="onViewTableButtonClick"
      >
        <v-icon small>
          {{ $t('Restaurant.Tables.Dialog.OptionMenu.History.Icon') }}
        </v-icon>
      </v-btn>

      <!-- Edit -->
      <v-btn
        v-if="canEditTable"
        :disabled="disabled"
        :class="disabled ? 'grey' : 'grey darken-2'"
        class="white--text ma-1"
        icon
        small
        @click="onEditTableButtonClick"
      >
        <v-icon small>
          {{ $t('Restaurant.Tables.Dialog.OptionMenu.Edit.Icon') }}
        </v-icon>
      </v-btn>

      <!-- Order -->
      <v-btn
        v-if="canOrderTable && !canEditTable"
        :class="disabled || canEditTable ? 'grey' : 'primary'"
        class="white--text ma-1"
        icon
        small
        @click="onNewOrderButtonClick"
      >
        <v-icon small>
          {{ $t('Restaurant.Tables.Dialog.OptionMenu.NewOrder.Icon') }}
        </v-icon>
      </v-btn>

      <!-- Open -->
      <v-btn
        v-if="canOpenTable"
        :disabled="disabled"
        :class="disabled ? 'grey' : 'green'"
        class="white--text ma-1"
        icon
        small
        @click="onOpenShiftButtonClick"
      >
        <v-icon small>
          {{ $t('Restaurant.Tables.Dialog.OptionMenu.Open.Icon') }}
        </v-icon>
      </v-btn>

      <!-- Reservation -->
      <v-btn
        v-if="canBookTable"
        :disabled="disabled"
        :class="disabled ? 'grey' : 'indigo'"
        class="white--text ma-1"
        icon
        small
        @click="onReservationButtonClick"
      >
        <v-icon small>
          {{ $t('Restaurant.Tables.Dialog.OptionMenu.Reservation.Icon') }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Auth         from '@/mixins/auth'
import Appdata      from '@/mixins/appdata'
import TableStatus  from '@/mixins/tables/tableStatus'
import TableCommon  from '@/mixins/tables/tableCommon'
import TableActions from '@/mixins/tables/tableActions'
import PrintButton  from '@/components/common/print/PrintButton.vue'
import Printer      from '@/lib/helper/printer'

export default {
  name      : 'TableListTileActionsToolbar',
  components: { PrintButton },
  directives: {},
  mixins    : [Auth, Appdata, TableStatus, TableCommon, TableActions],
  props     : {
    table: {
      type    : Object,
      required: true
    },
    area: {
      type   : Object,
      default: undefined
    },
    disabled: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      fab: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    printTableOrders (printer) {
      if (!printer) return

      if (this.userIsWaiter) {
        window.callAS(window.SocketCommand.Table.SendOrderForPrint, { cart_id: this.table.CartId })
      } else {
        Printer.printTableOrder(this.table.CartId, null, printer)
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding : 0 8px;
}
</style>
