/**
 * Enum for table status
 * @readonly
 * @enum {Number} TableStatusEnum
 */

const TableStatusEnum = {
  Available      : 22,
  Occupied       : 23,
  Unavailable    : 24,
  Reserved       : 25,
  Paid           : 30,
  PartiallyPaid  : 31,
  AwaitingPayment: 32
}

export default TableStatusEnum
