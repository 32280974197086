<template>
  <div
    class="mt-1"
    :style="`display: inline-block; width: ${size}; height: auto;`"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="`0 0 ${viewBox.width} ${viewBox.height}`"
    >
      <!-- SYMBOL -->
      <g>
        <!-- POI -->
        <path
          :fill="oneColor ? oneColor : poiColor"
          d="m347.16,171.89c-27.15-26.58-59.51-39.88-97.06-39.88s-69.86,13.29-96.9,39.88c-27.04,26.59-40.56,58.66-40.56,96.21v135.41h137.45c37.78,0,70.19-13.18,97.23-39.54,27.04-26.35,40.56-58.31,40.56-95.87s-13.58-69.63-40.73-96.21Zm-40.22,153.74c-15.5,15.95-34.45,23.93-56.84,23.93h-80.09v-81.45c0-22.4,7.75-41.69,23.25-57.86,15.49-16.17,34.45-24.26,56.85-24.26s41.34,8.09,56.84,24.26c15.49,16.18,23.25,35.47,23.25,57.86s-7.75,41.57-23.25,57.53Z"
        />

        <!-- SIGNAL -->
        <g v-if="signalActive">
          <path
            :fill="oneColor ? oneColor : signalColor1"
            d="m430.36,286.44c-.38,0-.76-.01-1.14-.03-10.93-.67-19.29-10.73-18.68-22.48,2.28-43.28-11.14-82.99-37.8-111.81-26.95-29.13-64.3-43.95-105.17-41.71-10.94.59-20.25-8.45-20.81-20.2-.56-11.75,7.86-21.77,18.8-22.36,26.25-1.44,51.49,2.71,75.03,12.32,22.93,9.36,43.21,23.46,60.28,41.91,34.7,37.51,52.2,88.74,49.27,144.26-.6,11.34-9.34,20.1-19.78,20.1Z"
          />
          <path
            :fill="oneColor ? oneColor : signalColor2"
            d="m503.91,282.19c-.4,0-.8-.01-1.21-.03-11.61-.66-20.49-10.6-19.83-22.21,3.47-61.24-17-117.48-57.64-158.36-41.07-41.3-97.89-62.31-160-59.15-11.61.6-21.51-8.35-22.1-19.96S251.47.96,263.09.37c37.32-1.9,73.18,3.58,106.59,16.27,32.5,12.35,61.24,30.94,85.41,55.25,23.96,24.1,42.21,52.6,54.25,84.71,12.43,33.14,17.66,68.71,15.57,105.72-.63,11.21-9.92,19.87-21,19.87Z"
          />
        </g>

        <!-- FORK -->
        <g>
          <path
            :fill="oneColor ? oneColor : forkColor"
            d="m263.09,259.62c-4.49,4.48-9.07,8.87-13.45,13.46-5.7,5.97-12.55,8.32-20.46,6.21-3.87-1.03-6.14.34-8.6,2.87-6.4,6.56-12.94,12.97-19.46,19.41-1.99,1.97-4.08,4.62-6.86,1.09-2.23-2.82-5.43-5.39-1.05-9.41,6.74-6.2,12.97-12.95,19.56-19.3,2.59-2.49,3.74-4.83,2.72-8.63-2.07-7.7.45-14.34,6.02-19.89,9.48-9.46,18.94-18.93,28.47-28.35,1.58-1.56,3.5-4.44,5.77-2.26,2.76,2.65-.6,4.56-2.22,6.23-5.89,6.06-12.03,11.9-17.88,18-1.68,1.76-5.66,3.55-2.33,6.7,2.84,2.69,4.58-.8,6.24-2.4,6.27-6.02,12.33-12.26,18.55-18.33,1.57-1.53,3.45-4.34,5.79-2.01,2.37,2.36-.5,4.2-2.02,5.75-6.25,6.37-12.66,12.6-18.9,18.98-1.5,1.53-4.38,3.16-1.99,5.76,2.79,3.03,4.6-.17,6.28-1.79,6.25-6.04,12.31-12.28,18.55-18.33,1.7-1.65,3.66-4.93,6.27-2.16,2.15,2.29-.8,4.16-2.35,5.73-6.27,6.36-12.66,12.6-18.91,18.96-1.52,1.55-4.24,3.18-1.74,5.78,2.52,2.61,4.24-.04,5.8-1.55,6.57-6.39,13-12.92,19.56-19.32,1.44-1.41,3.31-3.72,5.34-1.88,2.56,2.34-.2,4.15-1.72,5.7-4.93,5.04-9.96,9.97-14.95,14.95Z"
          />
          <path
            :fill="oneColor ? oneColor : forkColor"
            d="m249.99,290.53c8.65-8.62,17.08-17.48,26.03-25.78,6.05-5.6,13.22-9.25,21.86-6.01,6.36,2.39,6.93,5.26,2.13,10.08-11.44,11.48-22.97,22.87-34.45,34.31-4.17,4.16-8.97,7.01-15.06,6.13-2.86-.41-4.4,1.09-6.06,2.71-3.19,3.12-6.56,6.1-9.43,9.49-4.15,4.9-8.69,2.75-12.71.64-3.77-1.98-.17-3.93,1.17-5.29,8.72-8.88,17.59-17.62,26.41-26.41.04.04.08.08.12.12Z"
          />
        </g>

        <!-- PRO -->
        <g
          v-if="proActive"
          style=""
        >
          <path
            :fill="oneColor ? oneColor : proColor"
            d="m419.29,349.65c-1.57,1.57-3.45,2.36-5.64,2.36v-28.51c0-5.27,1.9-9.77,5.69-13.49,3.79-3.73,8.32-5.59,13.59-5.59s9.81,1.86,13.61,5.59c3.81,3.73,5.71,8.23,5.71,13.49s-1.9,9.75-5.69,13.45c-3.79,3.7-8.34,5.55-13.64,5.55h-11.28v1.52c0,2.19-.79,4.07-2.36,5.64Zm13.64-14.73c3.14,0,5.8-1.12,7.97-3.36,2.17-2.24,3.26-4.93,3.26-8.07s-1.09-5.85-3.26-8.12c-2.17-2.27-4.83-3.4-7.97-3.4s-5.8,1.13-7.97,3.4c-2.17,2.27-3.26,4.97-3.26,8.12v11.42h11.23Z"
          />
          <path
            :fill="oneColor ? oneColor : proColor"
            d="m463.35,334.49c0,2.19-.79,4.07-2.36,5.64-1.57,1.57-3.45,2.36-5.64,2.36v-19.09c0-5.27,1.9-9.75,5.69-13.45,3.79-3.7,8.32-5.55,13.59-5.55h11.23c0,2.09-.74,3.88-2.21,5.35-1.48,1.48-3.26,2.21-5.36,2.21h-3.66c-3.14,0-5.81,1.12-8,3.36-2.19,2.24-3.28,4.93-3.28,8.07v11.09Z"
          />
          <path
            :fill="oneColor ? oneColor : proColor"
            d="m505.57,304.41c5.27,0,9.8,1.86,13.59,5.59,3.79,3.73,5.69,8.23,5.69,13.49s-1.9,9.75-5.69,13.45c-3.79,3.7-8.32,5.55-13.59,5.55s-9.79-1.85-13.57-5.55c-3.78-3.7-5.66-8.18-5.66-13.45s1.89-9.77,5.66-13.49c3.78-3.73,8.3-5.59,13.57-5.59Zm0,7.57c-3.14,0-5.8,1.13-7.97,3.4-2.17,2.27-3.26,4.97-3.26,8.12s1.09,5.83,3.26,8.07c2.17,2.24,4.83,3.36,7.97,3.36s5.81-1.12,8-3.36c2.19-2.24,3.28-4.93,3.28-8.07s-1.09-5.85-3.28-8.12c-2.19-2.27-4.86-3.4-8-3.4Z"
          />
        </g>
      </g>

      <!-- TEXT -->
      <g v-if="textMainActive">
        <!-- GETFOOD -->
        <g v-if="textMainActive">
          <!-- GET -->
          <g>
            <path
              :fill="oneColor ? oneColor : textGetColor"
              d="m88,483.63h-16.13s-3.39,0-3.39,0h-14.47c-6.14,0-9.52,7.39-5.28,11.83.29.3.59.6.91.89,3.3,3.04,7.73,4.56,12.22,4.56h6.5c-.99,2.96-2.5,5.69-4.56,8.19-.46.55-.94,1.09-1.45,1.62-5.01,5.12-11.12,7.68-18.31,7.68s-13.28-2.56-18.26-7.68c-4.34-4.47-6.78-9.72-7.34-15.76-.07-.83-.11-1.67-.11-2.53,0-7.02,2.41-13.08,7.19-18.19,4.95-5.08,10.95-7.62,17.92-7.69h17.33c2.21,0,12.09-6.6,12.85-11.64.49-3.28-3.06-5.74-5.13-5.81l-24.78-.02c-.19,0-.38.01-.58.01h-1.1v.02c-11.08.37-20.73,4.38-28.88,12.09-.06.05-.12.08-.18.14-.13.13-.26.27-.39.4-.07.07-.14.13-.21.2h0C4.14,470.37,0,480.46,0,492.24c0,7.57,1.7,14.43,5.11,20.58.3.54.61,1.07.93,1.6.03.05.06.1.09.16,1.87,3.03,4.18,5.89,6.92,8.56,8.66,8.44,19,12.66,31.03,12.66,5.95,0,11.48-1.06,16.59-3.17,3.94-1.59,7.64-3.78,11.09-6.58,1.15-.94,2.28-1.94,3.38-3.01,7.69-7.5,11.97-16.42,12.85-26.74.11-1.33.18-2.68.18-4.05s-.18-8.62-.18-8.62Z"
            />
            <path
              :fill="oneColor ? oneColor : textGetColor"
              d="m167.13,454.84c-.75,4.98-10.51,11.5-12.69,11.5h-17.11c-6.97.06-12.92,2.66-17.85,7.79-2.72,2.83-4.66,5.96-5.9,9.35-.05.15.06.3.21.3h39.32c6.45,0,9.61,7.85,4.97,12.32-.87.84-1.88,1.64-3.17,2.39-2.95,1.72-6.35,2.53-9.76,2.53h-31.47c-.15,0-.26.15-.21.3,1.23,3.49,3.21,6.69,6.01,9.57,4.91,5.06,10.85,7.61,17.79,7.68h0s17.01,0,17.01,0c2.18,0,11.95,6.52,12.69,11.5.49,3.24-3.03,5.67-5.06,5.74,0,0-26.2,0-26.23,0-.41-.02-1.95-.14-2.31-.17-.23-.02-1.74-.18-2.27-.25-.09-.01-.18-.03-.28-.04-9.22-1.3-17.32-5.38-24.3-12.27l.02-.02c-8.52-8.4-12.78-18.56-12.78-30.5s4.3-22.25,12.9-30.74c8.2-8.1,17.95-12.33,29.24-12.7l1.09-.02c.19,0,.38-.01.57-.01l24.48.02c2.04.07,5.55,2.5,5.06,5.74Z"
            />
            <path
              :fill="oneColor ? oneColor : textGetColor"
              d="m247.06,449.08c6.23,0,9.55,7.54,5.17,11.98-.03.03-.07.07-.1.1-3.33,3.33-7.35,4.99-12.08,4.99h-17.18v62.08c0,3.28-4.51,7.56-9.02,7.56s-9.02-4.28-9.02-7.56v-62.08h-17.18c-4.72,0-8.75-1.66-12.08-4.99-.03-.03-.07-.07-.1-.1-4.37-4.43-1.05-11.98,5.17-11.98h66.39Z"
            />
          </g>

          <!-- FOOD -->
          <g>
            <path
              :fill="oneColor ? oneColor : textFoodColor"
              d="m316.38,449.1h-8.53s-.09-.02-.14-.02h-15.62s-.2,0-.2,0c-.19,0-.38.01-.57.01l-1.09.02s-.08,0-.13,0c-.3.01-.59.03-.89.05-.5.03-1.01.06-1.5.1-.05,0-.09,0-.14.01-10.17.91-19.04,5.08-26.59,12.53l.02.02c-8.55,8.4-12.83,18.57-12.83,30.5l-.37,35.83c0,6.63,8.02,10.15,12.74,5.5.04-.04.07-.07.11-.11,3.58-3.58,5.74-10.97,5.74-15.96v-16.59h33.55c4.45,0,8.83-1.51,12.11-4.52.21-.19.42-.39.62-.59,4.49-4.48,1.38-12.14-4.96-12.14h-39.94c1.21-3.58,3.2-6.83,6.03-9.74.17-.17.34-.32.51-.48,3.64-3.57,7.84-5.83,12.6-6.76.04,0,.08-.01.11-.02.75-.14,1.51-.25,2.29-.32.47-.04.94-.06,1.42-.08.36-.02.72-.05,1.09-.05h16.92c2.18,0,11.95-6.52,12.69-11.5.49-3.24-3.03-5.67-5.06-5.74Z"
            />
            <path
              :fill="oneColor ? oneColor : textFoodColor"
              d="m364.65,449.08c-12,0-22.3,4.25-30.9,12.74-8.6,8.49-12.9,18.74-12.9,30.74s4.3,22.21,12.9,30.63c8.6,8.42,18.9,12.63,30.9,12.63s22.32-4.21,30.96-12.63c8.64-8.42,12.96-18.63,12.96-30.63s-4.32-22.25-12.96-30.74c-8.64-8.49-18.96-12.74-30.96-12.74Zm18.22,61.86c-4.99,5.1-11.06,7.64-18.22,7.64s-13.21-2.55-18.16-7.64c-4.95-5.1-7.43-11.22-7.43-18.38s2.48-13.32,7.43-18.49c4.95-5.17,11.01-7.75,18.16-7.75s13.23,2.59,18.22,7.75c4.99,5.17,7.48,11.33,7.48,18.49s-2.49,13.28-7.48,18.38Z"
            />
            <path
              :fill="oneColor ? oneColor : textFoodColor"
              d="m459.42,449.08c-12,0-22.3,4.25-30.9,12.74-8.6,8.49-12.9,18.74-12.9,30.74s4.3,22.21,12.9,30.63c8.6,8.42,18.9,12.63,30.9,12.63s22.32-4.21,30.96-12.63c8.64-8.42,12.96-18.63,12.96-30.63s-4.32-22.25-12.96-30.74c-8.64-8.49-18.96-12.74-30.96-12.74Zm18.22,61.86c-4.99,5.1-11.06,7.64-18.22,7.64s-13.21-2.55-18.16-7.64c-4.95-5.1-7.43-11.22-7.43-18.38s2.47-13.32,7.43-18.49c4.95-5.17,11.01-7.75,18.16-7.75s13.23,2.59,18.22,7.75c4.99,5.17,7.48,11.33,7.48,18.49s-2.49,13.28-7.48,18.38Z"
            />
            <path
              :fill="oneColor ? oneColor : textFoodColor"
              d="m587.12,462.13c-8.59-8.41-18.83-12.62-30.71-12.62s-22.11,4.21-30.66,12.62c-8.56,8.41-12.83,18.56-12.83,30.45v42.85h43.5c11.96,0,22.21-4.17,30.77-12.51,8.56-8.34,12.83-18.45,12.83-30.34s-4.3-22.03-12.89-30.45Zm-12.73,48.65c-4.91,5.05-10.9,7.57-17.99,7.57h-25.35v-25.77c0-7.09,2.45-13.19,7.36-18.31,4.9-5.12,10.9-7.68,17.99-7.68s13.08,2.56,17.99,7.68c4.9,5.12,7.36,11.22,7.36,18.31s-2.45,13.16-7.36,18.2Z"
            />
          </g>
        </g>

        <!-- SLOGAN -->
        <g v-if="textSloganActive">
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m2.14,555.9v10.45h10.86v1.78H2.14v13.52H0v-27.53h14.17v1.78H2.14Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m45.81,567.89c0,7.91-6.42,14.13-14.61,14.13s-14.53-6.22-14.53-14.13,6.38-14.13,14.53-14.13,14.61,6.22,14.61,14.13Zm-2.3,0c0-6.9-5.33-12.15-12.23-12.15s-12.31,5.25-12.31,12.15,5.41,12.15,12.31,12.15,12.23-5.25,12.23-12.15Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m79.11,567.89c0,7.91-6.42,14.13-14.61,14.13s-14.53-6.22-14.53-14.13,6.38-14.13,14.53-14.13,14.61,6.22,14.61,14.13Zm-2.3,0c0-6.9-5.33-12.15-12.23-12.15s-12.31,5.25-12.31,12.15,5.41,12.15,12.31,12.15,12.23-5.25,12.23-12.15Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m109.06,567.89c0,6.38-3.75,13.76-14.65,13.76h-10.01v-27.53h9.65c11.7,0,15.01,7.71,15.01,13.76Zm-2.3,0c0-7.06-5.13-11.99-12.59-11.99h-7.63v23.97h7.75c6.62,0,12.47-4.24,12.47-11.99Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m144.34,581.65l-4.16-4.32c-2.62,2.95-5.29,4.68-9.48,4.68s-7.18-2.62-7.18-6.54c0-3.55,2.42-6.5,6.82-8.39-2.26-2.42-3.43-4.2-3.43-6.42,0-3.39,2.87-6.01,6.54-6.01,3.47,0,6.01,2.42,6.01,5.69,0,3.03-1.78,5.25-5.77,7.31l6.26,6.5c1.29-1.94,2.18-3.67,3.03-6.22h2.26c-.93,2.91-2.22,5.49-3.79,7.79l5.61,5.93h-2.7Zm-12.59-13.08c-4.2,1.82-5.93,3.71-5.93,6.62,0,3.07,1.94,4.84,5.33,4.84,2.78,0,5.17-1.29,7.59-4.2l-6.98-7.27Zm5.73-8.03c0-2.26-1.74-3.92-4.16-3.92s-4.36,1.65-4.36,3.92c0,1.7,1.13,3.55,3.35,5.61,3.39-1.41,5.17-3.35,5.17-5.61Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m186.52,567.89c0,6.38-3.75,13.76-14.65,13.76h-10.01v-27.53h9.65c11.7,0,15.01,7.71,15.01,13.76Zm-2.3,0c0-7.06-5.13-11.99-12.59-11.99h-7.63v23.97h7.75c6.62,0,12.47-4.24,12.47-11.99Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m210.14,581.65l-4.8-7.55c-3.35-5.21-4.32-5.65-7.67-5.65h-3.71v13.2h-2.14v-27.53h7.06c6.74,0,9.12,3.39,9.12,7.1,0,3.19-1.74,5.41-5.25,6.7,1.29.69,2.38,2.26,3.67,4.2l6.22,9.53h-2.5Zm-4.44-20.38c0-3.11-2.22-5.37-7.26-5.37h-4.48v10.78h4.56c4.88,0,7.18-2.06,7.18-5.41Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m214.98,581.65v-27.53h2.14v27.53h-2.14Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m246.5,581.65l-20.91-24.06v24.06h-2.14v-27.53h1.82l20.87,24.06v-24.06h2.14v27.53h-1.78Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m271.13,581.65l-14.41-13.8v13.8h-2.14v-27.53h2.14v13.72l13.2-13.72h2.66l-13.12,13.72,14.37,13.8h-2.7Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m312.18,567.89c0,6.38-3.75,13.76-14.65,13.76h-10.01v-27.53h9.65c11.7,0,15.01,7.71,15.01,13.76Zm-2.3,0c0-7.06-5.13-11.99-12.59-11.99h-7.63v23.97h7.75c6.62,0,12.47-4.24,12.47-11.99Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m317.43,581.65v-27.53h2.14v27.53h-2.14Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m349.51,579.67c-3.27,1.57-6.54,2.34-9.77,2.34-8.56,0-14.97-6.01-14.97-14.21s6.26-14.05,14.77-14.05c3.11,0,5.57.65,9.44,2.34v2.42c-3.39-1.9-6.42-2.78-9.77-2.78-6.98,0-12.15,5.17-12.15,12.15,0,6.18,4.04,12.15,12.75,12.15,3.23,0,5.33-.61,7.55-1.7v-7.43h-5.81v-1.78h7.95v10.53Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m355.01,581.65v-27.53h2.14v27.53h-2.14Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m372.97,555.9v25.75h-2.14v-25.75h-10.33v-1.78h22.8v1.78h-10.33Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m408.08,581.65l-4.36-9.53h-13.44l-4.36,9.53h-2.22l12.71-27.73h1.17l12.71,27.73h-2.22Zm-11.1-24.26l-5.89,12.96h11.83l-5.93-12.96Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m413.74,581.65v-27.53h2.14v25.75h13.6v1.78h-15.74Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m458.13,574.47c0,4.24-3.51,7.55-7.99,7.55-2.3,0-4.64-.81-7.06-2.42v-2.5c3.11,2.26,4.76,2.95,6.94,2.95,3.27,0,5.81-2.38,5.81-5.49,0-7.83-13.12-4.4-13.12-13.48,0-4.08,3.55-7.31,7.91-7.31,2.14,0,4.24.57,6.38,1.74v2.34c-2.78-1.61-4.28-2.1-6.17-2.1-3.31,0-5.81,2.26-5.81,5.25,0,7.31,13.12,3.83,13.12,13.48Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m463.1,581.65v-27.53h15.01v1.78h-12.87v11.02h12.55v1.78h-12.55v11.18h13.2v1.78h-15.34Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m501.24,581.65l-4.8-7.55c-3.35-5.21-4.32-5.65-7.67-5.65h-3.71v13.2h-2.14v-27.53h7.06c6.74,0,9.12,3.39,9.12,7.1,0,3.19-1.74,5.41-5.25,6.7,1.29.69,2.38,2.26,3.67,4.2l6.22,9.53h-2.5Zm-4.44-20.38c0-3.11-2.22-5.37-7.26-5.37h-4.48v10.78h4.56c4.88,0,7.18-2.06,7.18-5.41Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m515.25,581.93h-.61l-11.58-27.81h2.14l9.77,23.29,9.69-23.29h2.14l-11.54,27.81Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m530.15,581.65v-27.53h2.14v27.53h-2.14Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m561.87,579.43c-3.23,1.74-6.54,2.58-9.77,2.58-8.4,0-14.65-6.01-14.65-14.09s6.86-14.17,14.45-14.17c3.11,0,6.34.77,9.69,2.3v2.34c-3.43-1.82-6.54-2.66-9.81-2.66-6.9,0-12.03,5.25-12.03,12.19s5.09,12.11,12.31,12.11c3.11,0,6.05-.85,9.81-2.83v2.22Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m566.11,581.65v-27.53h15.01v1.78h-12.87v11.02h12.55v1.78h-12.55v11.18h13.2v1.78h-15.34Z"
          />
          <path
            :fill="oneColor ? oneColor : textSloganColor"
            d="m600.02,574.47c0,4.24-3.51,7.55-7.99,7.55-2.3,0-4.64-.81-7.06-2.42v-2.5c3.11,2.26,4.76,2.95,6.94,2.95,3.27,0,5.81-2.38,5.81-5.49,0-7.83-13.12-4.4-13.12-13.48,0-4.08,3.55-7.31,7.91-7.31,2.14,0,4.24.57,6.38,1.74v2.34c-2.78-1.61-4.28-2.1-6.17-2.1-3.31,0-5.81,2.26-5.81,5.25,0,7.31,13.12,3.83,13.12,13.48Z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name : 'SvgLogo',
  props: {
    size: {
      type   : String,
      default: '26px'
    },
    oneColor: {
      type   : String,
      default: ''
    },
    poiColor: {
      type   : String,
      default: '#ec232c'
    },
    forkColor: {
      type   : String,
      default: '#414042'
    },
    signalActive: {
      type   : Boolean,
      default: true
    },
    signalColor1: {
      type   : String,
      default: '#58595b'
    },
    signalColor2: {
      type   : String,
      default: '#414042'
    },
    proColor: {
      type   : String,
      default: '#414042'
    },
    proActive: {
      type   : Boolean,
      default: true
    },
    textMainActive: {
      type   : Boolean,
      default: false
    },
    textSloganActive: {
      type   : Boolean,
      default: true
    },
    textGetColor: {
      type   : String,
      default: '#414042'
    },
    textFoodColor: {
      type   : String,
      default: '#ec232c'
    },
    textSloganColor: {
      type   : String,
      default: '#414042'
    }
  },
  data () {
    return {}
  },
  computed: {
    viewBox () {
      if (this.textMainActive) {
        return {
          width : 600,
          height: 582
        }
      }

      return {
        width : 600,
        height: 420
      }
    }
  }
}
</script>
