export default {

  Language: {
    el: {
      active: true,
      name  : 'Greek',
      locale: 'el',
      code  : 'el-GR',
      code2 : 'el_GR'
    },
    en: {
      active: true,
      name  : 'English',
      locale: 'en',
      code  : 'en-US',
      code2 : 'en_US'
    },
    bg: {
      active: false,
      name  : 'Bulgarian',
      locale: 'bg',
      code  : 'bg-BG',
      code2 : 'bg_BG'
    }
  },

  Date: {
    Title     : 'Date',
    DateShort : 'Date',
    Time      : 'Time',
    Minute    : 'Minutes | Minute | Minutes',
    Today     : 'Today',
    Day       : 'Day',
    Week      : 'Week',
    Month     : 'Month',
    Year      : 'Year',
    Period    : 'Period',
    PeriodFrom: 'From',
    PeriodTo  : 'To',
    WeekDays  : {
      Short: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      Long : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    },
    Now: 'Now'
  },

  Update: {
    Cloud: {
      Title          : 'New version available',
      BodyWithVersion: 'There is a system update available. Press "UPDATE NOW" to proceed and switch from version v{CurrentVersion} to the new version v{ServerVersion}.',
      BodyNoVersion  : 'There is a system update available. Press "UPDATE NOW" to proceed and switch to the new version.'
    }
  },

  Button: {
    All             : 'All',
    PaymentAll      : 'Pay All',
    Backup          : 'Backup',
    Upgrade         : 'Upgrade',
    Downgrade       : 'Downgrade',
    ChangePlan      : 'Change Plan',
    CurrentPlan     : 'Current Plan',
    RenewPlan       : 'Renew Subscription',
    More            : 'More',
    Save            : 'Save',
    Cancel          : 'Cancel',
    CancelWithRefund: 'Cancel & Refund',
    Close           : 'Close',
    Duplicate       : 'Duplicate',
    Delete          : 'Delete',
    Update          : 'Update',
    Send            : 'Send',
    Here            : 'Here',
    Translate       : 'Translate',
    TranslateAll    : 'Translate All',
    Yes             : 'Yes',
    No              : 'No',
    Ok              : 'Ok',
    Next            : 'Next',
    Previous        : 'Previous',
    Back            : 'Back',
    Dismiss         : 'Dismiss',
    View            : 'View',
    Add             : 'Add',
    Remove          : 'Remove',
    Reset           : 'Reset',
    End             : 'End',
    Finish          : 'Finish',
    Preview         : 'Preview',
    Renew           : 'Renew',
    Copy            : 'Copy',
    Edit            : 'Edit',
    Move            : 'Move',
    MoveSelected    : 'Move Selected',
    MoveAll         : 'Move All',
    Print           : 'Print',
    PrintOrder      : 'Print Order',
    PrintPreparation: 'Print Preparation',
    PrintReceipt    : 'Print Receipt',
    PrintInvoice    : 'Print Invoice',
    PrintFnb        : 'Print Order Document',
    ViewDocument    : 'View Fiscal Document',
    PrintDocument   : 'Print Fiscal Document',
    RetryDocument   : 'Transmit Fiscal Document',
    EmailDocument   : 'Email Fiscal Document',
    CancelReceipt   : 'Cancel Receipt',
    CancelInvoice   : 'Cancel Invoice',
    CancelFnb       : 'Cancel Order Document',
    ManageDocuments : 'Manage Documents',
    ManageDocument  : 'Manage Document',
    ManageReceipt   : 'Manage Receipt',
    ManageInvoice   : 'Manage Invoice',
    ManageFnb       : 'Manage Order Document',
    EmailReceipt    : 'Email Receipt',
    EmailInvoice    : 'Email Invoice',
    EmailFnb        : 'Email Order Document',
    ViewReceipt     : 'View Receipt',
    ViewInvoice     : 'View Invoice',
    ViewFnb         : 'View Order Document',
    IssueDocument   : 'Issue Fiscal Document',
    IssueReceipt    : 'Issue Receipt',
    IssueInvoice    : 'Issue Invoice',
    IssueFnb        : 'Issue Order Document',
    ExternalReceipt : 'External Receipt',
    Payment         : 'Payment',
    TransactionAdd  : 'Add Transaction',
    Left            : 'Left',
    Right           : 'Right',
    Toggle          : {
      Active    : 'Active',
      ActiveF   : '@:Common.Button.Toggle.Active',
      ActiveM   : '@:Common.Button.Toggle.Active',
      Inactive  : 'Inactive',
      InactiveF : '@:Common.Button.Toggle.Inactive',
      InactiveM : '@:Common.Button.Toggle.Inactive',
      Horizontal: 'Horizontal',
      Vertical  : 'Vertical',
      Locked    : 'Locked',
      Unlocked  : 'Unlocked'
    },
    Import: {
      Icon : 'file_upload',
      Title: 'Import'
    },
    Export: {
      Icon : 'file_download',
      Title: 'Export'
    }
  },

  Misc: {
    Desc         : 'Desc',
    Printer      : 'Printers | Printer | Printers',
    Total        : 'Total',
    Change       : 'Change',
    Payment      : 'Payment',
    Subtotal     : 'Subtotal',
    Name         : 'Name',
    Discount     : 'Discount',
    Discounts    : 'Discounts',
    ExtraCharges : 'Extra Charge',
    Balance      : 'Balance',
    Tip          : 'Tip',
    Details      : 'Details',
    Quantity     : 'Quantity',
    Status       : 'Status',
    Welcome      : 'Welcome',
    All          : 'All',
    And          : 'and',
    In           : 'in',
    RequiredField: '* Indicates a required field',
    UsersOnline  : 'Users Online',
    Notification : {
      ImportPlatformInProgress: 'Please wait while the<br>catalog is importing',
      ImportEFoodSuccess      : 'Import from E-Food has started successfully',
      ImportFoodySuccess      : 'Import from Foody has started successfully',
      ImportFagiSuccess       : 'Import from Fagi has started successfully',
      ImportGetFoodSuccess    : 'Import from Get Food has started successfully',
      FieldError              : 'Check that you have filled in all the information correctly!',
      GenericError            : 'An error occurred. Please try again.',
      IssueReceipt            : {
        Success: 'The fiscal document has been issued successfully',
        Error  : 'There was a problem issuing the fiscal document. Please try again!'
      },
      CancelReceipt: {
        Success: 'The fiscal document has been canceled successfully',
        Error  : 'There was a problem canceling the fiscal document. Please try again!'
      },
      RetryReceipt: {
        Success: 'The fiscal document has been sent for transmission retry',
        Error  : 'There was a problem while sending the fiscal document for transmission retry!'
      },
      PrintReceipt: {
        Success: 'The fiscal document has been send for print',
        Error  : 'There was a problem printing the fiscal document. Please try again!'
      },
      EmailReceipt: {
        Success: 'The fiscal document has been emailed successfully',
        Error  : 'There was a problem emailing the fiscal document. Please try again!'
      },
      TaxesOrphaned: {
        Both    : 'There are <strong>{categories} categories</strong> and {products} <strong>products</strong> for which no VAT category has been applied!',
        Category: 'There are <strong>{categories} categories</strong> that have no VAT category applied! | There is <strong>{categories} category</strong> that does not have a VAT category applied! | There are <strong>{categories} categories</strong> that have no VAT category applied!',
        Product : 'There are <strong>{products} products</strong> for which no VAT category has been applied! | There is <strong>{products} product</strong> that does not have a VAT category applied! | There are <strong>{products} products</strong> for which no VAT category has been applied!'
      }
    },
    WaitProgress: {
      Title: 'Please Wait'
    },
    Retry       : 'Please try again.',
    Attention   : 'Attention',
    Copy        : 'Copied Successfully',
    Exit        : 'Exit',
    ExitApp     : 'Exit Application',
    LogoutDialog: {
      Title  : 'Disconnect',
      Body   : 'Do you want to Disconnect?',
      Warning: 'The Online store will stop receiving orders if no other workstation is active'
    },
    ExitAppDialog: {
      Title  : '@:Common.Misc.Exit',
      Body   : 'Do you want to exit the application?',
      Warning: '@:Common.Misc.LogoutDialog.Warning'
    }
  },

  Table: {
    Text: {
      LoadingText  : '',
      NoDataText   : 'No data',
      NoResultsText: 'No results'
    },
    Pagination: {
      From: 'from',
      To  : 'to'
    },

    Result: {
      NoDataText      : 'No records available',
      NoResultsText   : 'No records found',
      RowsPerPageItems: [10, 15, 25],
      RowsPerPageText : 'Records per page'
    }
  },

  Search: {
    Label      : 'Search',
    Icon       : 'search',
    Description: 'Search Results'
  },

  CallsQueue: {
    Toolbar: {
      Title : 'Phone calls',
      Button: {
        Title: '@:Common.Button.View all'
      }
    },
    ListView: {
      Title     : 'Calls | Call | Calls',
      NoCall    : 'There are no',
      InProgress: {
        Title: 'Calls in Progress',
        Icon : 'phone_in_talk'
      },
      OnHold: {
        Title: 'Calls on Hold',
        Icon : 'phone_paused'
      },
      Missed: {
        Title: 'Missed Calls | Missed Call | Missed Calls',
        Icon : 'phone_missed'
      },
      Common: {
        PotentialCustomer: 'Potential Customers',
        UnknownCustomer  : 'Unknown Customer',
        HiddenCallerID   : 'Hidden caller ID',
        NewOrder         : 'New Order',
        NewReservation   : 'New Reservation',
        CustomerTab      : 'Customer Tab'
      },
      Button: {
        Dismiss: {
          Title: '@:Common.Button.Dismiss All',
          Icon : 'delete_forever',
          Color: 'red'
        },
        DismissAndNewOrder: {
          Title: '@:Common.Button.Dismiss & New Order',
          Icon : 'add_shopping_cart'
        },
        DismissAndNewReservation: {
          Title: '@:Common.Button.Dismiss & New Reservation',
          Icon : 'mdi mdi-book-open-variant'
        }
      }
    }
  },

  ConfirmDialog: {
    Title: 'Are you sure?'
  },

  NumPadDialog: {
    Title: 'Enter Value'
  },

  OnlineStatus: {
    Title: {
      Open  : 'Open',
      Closed: 'Closed'
    }
  },

  OrdersQueue: {
    Button: {
      Title: '@:Common.Button.View all'
    },
    ListView: {
      Title: {
        ToAccept  : 'to Accept',
        ToComplete: 'to Complete'
      },
      ListItem: {
        Visitor  : 'Visitor',
        Telephone: 'Tel:'
      }
    },
    Notification: {
      Title  : 'Pending Order',
      Order  : 'Order',
      Content: {
        Delay : 'You are',
        Doing : 'late to',
        Cancel: 'or CANCEL the pending order',
        Order : 'order'
      },
      Button: {
        Close : 'Close without reminder',
        Remind: 'Remind in 5"',
        View  : 'View order',
        Cancel: '@:Common.Button.Cancel @:Common.OrdersQueue.Notification.Order'
      }
    },
    AcceptOrder: {
      Title  : 'Accept Pending Order',
      Content: {
        Payment: 'Pending orders do not have a confirmed payment yet.',
        Choose : 'Choose if you want to',
        Accept : 'accept',
        Edit   : 'edit',
        Order  : 'this pending order or not.'
      }
    }
  },

  SelectDateRangeDialog: {
    Toolbar: {
      Title   : 'Select Date Range',
      Subtitle: 'Select date range from - to',
      Color   : 'primary',
      Icon    : 'date_range'
    }
  },

  Map: {
    Title    : 'Map',
    Satellite: 'Satellite'
  },

  ColorPicker: {
    Error: {
      InvalidColor: 'It\'s not a valid color code!'
    }
  },

  Error: {
    Mandatory: 'The field is mandatory',
    Generic  : 'An error occurred. Please try again later.'
  }
}
