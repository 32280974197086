<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar color="indigo">
            <v-icon dark>
              tab_unselected
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Restaurant.Tables.Dialog.SelectTable.Title') }}
            </div>

            <div class="text-caption grey--text">
              {{ $t('Restaurant.Tables.Dialog.SelectTable.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          v-if="tables"
          class="pa-3"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row dense>
              <template v-for="(area, index) in viewDataFiltered">
                <v-col
                  v-if="area.Tables && area.Tables.length > 0"
                  :key="`area-${area.Id}`"
                  cols="12"
                  :class="{'pt-4': index > 0}"
                >
                  <v-subheader class="pl-0">
                    <div
                      class="d-inline-block"
                      :style="`background-color: #${area.Color || '000000'};`"
                      style="width:5px; height: 22px; margin-right: 6px;"
                    />

                    <span class="grey--text text--darken-2 font-weight-bold text-subtitle-1 pr-1">{{ area.Name && area.Name.toLocaleUpperCase($i18n.locale) }}</span>&nbsp;
                    <span class="grey--text font-weight-regular">{{ area.Tables.length }} {{ $tc('Restaurant.Tables.Title', area.Tables.length) }}</span>
                  </v-subheader>
                </v-col>

                <template v-for="table in area.Tables">
                  <v-col
                    :key="`area-${area.Id}-table-${table.Id}`"
                    md="4"
                    sm="6"
                    cols="12"
                  >
                    <table-list-tile
                      :table="table"
                      :children-tables="getTableChildrenTables(table)"
                      @click="onSelectTableClick(table)"
                    />
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData       from '@/mixins/appdata'
import TableListTile from '@/components/restaurant/tables/tables/TableListTile'
import TableCommon   from '@/mixins/tables/tableCommon'
import TableStatus   from '@/mixins/tables/tableStatus'

export default {
  name      : 'SelectTableDialog',
  components: { TableListTile },
  mixins    : [AppData, TableStatus, TableCommon],
  props     : {
    tables: {
      type   : Array,
      default: undefined
    },
    exclude: {
      type   : Array,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    viewDataFiltered () {
      const data = JSON.parse(JSON.stringify(this.tables || []))

      data.forEach(area => {
        area.Tables = area.Tables
          .filter(table => !table.Parent)
          .filter(table => !this.tableCanManage(table) ? this.tableCanManage(table) || !table.StaffId : true)
        if (this?.exclude?.length) area.Tables = area.Tables.filter(table => !!this?.exclude?.find(tbl => tbl?.Id !== table.Id))
      })

      return data
    },

    viewData () {
      return JSON.parse(JSON.stringify(this.tables || []))
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch  : {},
  methods: {
    onSelectTableClick (item) {
      const table = JSON.parse(JSON.stringify(item))
      table.ChildrenTables = this.getTableChildrenTables(table)
      this.$emit('selected:table', table)
      this.$bus.$emit('selected:table', table)
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
