<template>
  <v-btn
    dark
    small
    depressed
    class="light-blue d-inline-flex px-2"
    style="min-width: 32px;"
    :disabled="isDisabled"
    :loading="isLoading"
    @click="translateField(field, item, localeTo, localeFrom)"
  >
    <v-icon small>
      mdi-translate
    </v-icon>
  </v-btn>
</template>

<script>

import AppData             from '@/mixins/appdata'
import TranslateFieldMixin from '@/components/common/translation/translateFieldMixin'

export default {
  name      : 'TranslateFieldButton',
  components: {},
  directives: {},
  mixins    : [AppData, TranslateFieldMixin],
  props     : {
    item: {
      type   : Object,
      default: undefined
    },

    field: {
      type   : String,
      default: ''
    },

    localeTo: {
      type   : String,
      default: ''
    },

    localeFrom: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    isDisabled () {
      return this.isLoading ||
        !this.field ||
        !this.item?.Lang?.hasOwnProperty(this.localeTo) ||
        !this.item?.Lang.hasOwnProperty(this.localeFrom) ||
        !this.item.Lang[this.localeFrom]?.hasOwnProperty(this.field) ||
        !this.item.Lang[this.localeFrom][this.field] ||
        this.localeFrom === this.localeTo ||
        (Array.isArray(this.field) && !this.field?.length)
    },
    isLoading () {
      return !!this.item?.loadingTranslation
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
