<template>
  <v-container
    id="permissionsContainer"
    class="pa-2 pa-md-4"
    fluid
  >
    <v-row :class="{'flex-column': $vuetify.breakpoint.smAndDown}">
      <v-col class="shrink">
        <v-card v-if="$vuetify.breakpoint.mdAndUp">
          <v-toolbar
            flat
            height="80"
            :color="isDarkMode ? 'grey darken-4' : 'grey lighten-5'"
          >
            <v-avatar
              :color="$t('Staff.StaffGroups.Groups.Color')"
            >
              <v-icon dark>
                {{ $t('Staff.StaffGroups.Groups.Icon') }}
              </v-icon>
            </v-avatar>

            <v-toolbar-title class="pl-3">
              <div class="body-3">
                {{ $t('Staff.StaffGroups.Groups.Title') }}
              </div>

              <div class="text-caption">
                {{ $t('Staff.StaffGroups.Groups.Subtitle') }}
              </div>
            </v-toolbar-title>

            <v-spacer />
          </v-toolbar>

          <v-divider />

          <v-card-text
            ref="groupsMenu"
            class="pa-0"
          >
            <v-list
              ref="groupsList"
              style="overflow-y: auto;"
              :style="$vuetify.breakpoint.smAndDown ? 'height: 150px' : 'height: calc(100vh - 192px)'"
              nav
            >
              <template v-for="group in viewCustomData">
                <v-list-item
                  :key="group.Id"
                  :value="parseInt(group.Id)===parseInt(selectedId)"
                  active-class="primary--text grey lighten-4"
                  @click="onSelect(group)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ group.Name }}</v-list-item-title>

                    <v-list-item-subtitle v-if="parseInt(group.Id) !== 1">
                      {{ group.UsersCount + ' ' + $tc('Staff.StaffGroups.Misc.User', group.UsersCount) }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-else>
                      {{ $t('Staff.StaffGroups.Groups.ModulesPackage') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon color="grey">
                      {{ userIsSuperAdmin ? 'edit' : !group.Editable ? 'remove_red_eye' : userCanManage ? 'edit' : 'remove_red_eye' }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>

        <v-menu
          v-else
          bottom
          max-height="400"
          transition="slide-y-transition"
        >
          <template #activator="{on}">
            <v-btn
              block
              color="primary ma-0"
              dark
              v-on="on"
            >
              {{ $t('Staff.StaffGroups.Misc.ChooseGroup.Title').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </template>

          <v-list>
            <template v-for="group in viewCustomData">
              <v-list-item
                :key="group.Id"
                :value="parseInt(group.Id)===parseInt(selectedId)"
                active-class="primary--text grey lighten-4"
                @click="onSelect(group)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ group.Name }}</v-list-item-title>

                  <v-list-item-subtitle>
                    {{ group.UsersCount + ' ' + $tc('Staff.StaffGroups.Misc.User', group.UsersCount) }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="grey">
                    {{ userIsSuperAdmin ? 'edit' : !group.Editable ? 'remove_red_eye' : userCanManage ? 'edit' : 'remove_red_eye' }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>

      <v-col v-if="editObj">
        <v-card>
          <v-toolbar
            flat
            height="80"
          >
            <v-avatar
              :color="$t('Staff.StaffGroups.SingleGroup.Color')"
            >
              <v-icon dark>
                {{ $t('Staff.StaffGroups.SingleGroup.Icon') }}
              </v-icon>
            </v-avatar>

            <v-toolbar-title class="pl-3">
              <div class="body-3">
                {{ editObj.Name }}
              </div>

              <div class="text-caption">
                {{ $t('Staff.StaffGroups.SingleGroup.Subtitle') }}
              </div>
            </v-toolbar-title>

            <v-spacer />

            <div>
              <v-switch
                v-if="userIsSuperExtraAdmin"
                v-model="ignorePermissionRelations"
                inset
                :label="$vuetify.breakpoint.smAndDown ? '' : ignorePermissionRelations ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                color="success"
                class="mt-0 pt-0 mr-4"
                hide-details
              >
                <template #prepend>
                  <span
                    class="mt-1 grey--text text--darken-1"
                    :class="{'text-no-wrap': $vuetify.breakpoint.smAndUp}"
                  >Ignore Relations</span>
                </template>
              </v-switch>
            </div>

            <filter-search v-model="dataTable.search.terms" />
          </v-toolbar>

          <v-divider />

          <v-card-text
            id="settingsWindow"
            class="pa-0"
            :style="$vuetify.breakpoint.smAndDown ? 'height: calc(100vh - 361px)' : 'height: calc(100vh - 261px)'"
          >
            <v-form
              ref="permissionsForm"
              v-model="permissionsFormValid"
              @submit.prevent
            >
              <v-select
                v-if="parseInt(selectedId) === 1"
                v-model="editObj.ActivePackage"
                :items="editObj.Packages"
                :label="$t('Staff.StaffGroups.Misc.ChoosePackage')"
                filled
                flat
                full-width
                hide-details
                item-text="Name"
                item-value="Id"
                prepend-inner-icon="extension"
                single-line
                @change="onPackageSelection"
              />

              <v-data-table
                :headers="headers"
                :items="editObj.Permissions"
                :search="dataTable.search.terms"
                :custom-filter="customSearch"
                :height="dataTable.resize.bodyHeight"
                :footer-props="dataTable.footerProps"
                :options.sync="dataTable.options"
                fixed-header
                hide-default-footer
                class="elevation-0"
              >
                <template #[`item.Id`]="{item}">
                  <v-chip label>
                    {{ item.Id }}
                  </v-chip>
                </template>

                <template #[`item.Permission`]="{item}">
                  <div>
                    <div>{{ item.Permission }}</div>
                    <div
                      v-if="parseInt(selectedId) === 1 && userIsSuperAdmin"
                      class="text-caption grey--text"
                    >
                      {{ item.Name }}
                    </div>
                  </div>
                </template>

                <template
                  v-if="parseInt(selectedId) !== 1 && userIsSuperAdmin"
                  #[`item.Api`]="{item}"
                >
                  <v-checkbox
                    v-if="item.Action.findIndex(p => (p === 'api')) > -1 && parseInt(editObj.Id)!==1"
                    v-model="item.GroupPermissions"
                    :color="getPermissionColor(item, 'api')"
                    :disabled="!ignorePermissionRelations"
                    hide-details
                    primary
                    style="display: inline-block"
                    value="api"
                    @change="onCheckboxChange('api', item)"
                  />
                </template>

                <template #[`item.Access`]="{item}">
                  <v-checkbox
                    v-if="item.Action.findIndex(p => (p === 'access')) > -1"
                    v-model="item.GroupPermissions"
                    :color="getPermissionColor(item, 'access')"
                    :disabled="(!userIsSuperAdmin && !editObj.Editable) || !userCanManage"
                    hide-details
                    primary
                    style="display: inline-block"
                    value="access"
                    @change="onCheckboxChange('access', item)"
                  />
                </template>

                <template
                  v-if="parseInt(selectedId) !== 1"
                  #[`item.Manage`]="{item}"
                >
                  <v-checkbox
                    v-if="item.Action.findIndex(p => (p === 'manage')) > -1 && parseInt(editObj.Id)!==1"
                    v-model="item.GroupPermissions"
                    :color="getPermissionColor(item, 'manage')"
                    :disabled="(!userIsSuperAdmin && !editObj.Editable) || !userCanManage"
                    hide-details
                    primary
                    style="display: inline-block"
                    value="manage"
                    @change="onCheckboxChange('manage', item)"
                  />
                </template>

                <template
                  v-if="parseInt(selectedId) !== 1"
                  #[`item.Add`]="{item}"
                >
                  <v-checkbox
                    v-if="item.Action.findIndex(p => (p === 'add')) > -1 && parseInt(editObj.Id)!==1"
                    v-model="item.GroupPermissions"
                    :color="getPermissionColor(item, 'add')"
                    :disabled="(!userIsSuperAdmin && !editObj.Editable) || !userCanManage"
                    hide-details
                    primary
                    style="display: inline-block"
                    value="add"
                    @change="onCheckboxChange('add', item)"
                  />
                </template>

                <template
                  v-if="parseInt(selectedId) !== 1"
                  #[`item.Delete`]="{item}"
                >
                  <td class="text-center">
                    <v-checkbox
                      v-if="item.Action.findIndex(p => (p === 'delete')) > -1 && parseInt(editObj.Id)!==1"
                      v-model="item.GroupPermissions"
                      :color="getPermissionColor(item, 'delete')"
                      :disabled="(!userIsSuperAdmin && !editObj.Editable) || !userCanManage"
                      hide-details
                      primary
                      style="display: inline-block"
                      value="delete"
                      @change="onCheckboxChange('delete', item)"
                    />
                  </td>
                </template>
              </v-data-table>
            </v-form>
          </v-card-text>

          <v-divider />

          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn
              class="light-blue--text text--darken-1"
              outlined
              @click="onCancel"
            >
              {{ $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="userCanManage && editObj.Editable || userIsSuperAdmin"
              :disabled="loading"
              :loading="loading"
              class="green white--text elevation-0"
              @click="onSave"
            >
              {{ $t('Common.Button.Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
        v-if="!editObj"
        class="ma-0 pa-0"
      >
        <v-row class="fill-height align-center justify-center">
          <div
            class="text-center grey--text"
          >
            <v-icon
              class="mb-4"
              color="grey lighten-2"
              size="128"
            >
              {{ $t('Staff.StaffGroups.Misc.ChooseGroup.Icon') }}
            </v-icon>
            <div
              class="text-h6 mb-2"
              style="height: auto;"
            >
              {{ $t('Staff.StaffGroups.Misc.ChooseGroup.Title') }}
            </div>
            <div
              class="text-subtitle-1"
              style="height: auto;"
            >
              {{ $t('Staff.StaffGroups.Misc.ChooseGroup.Subtitle') }}
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppData      from '../../mixins/appdata'
import Auth         from '../../mixins/auth'
import DataTable    from '@/mixins/data-table/dataTable'
import FilterSearch from '@/components/common/filter/FilterSearch.vue'
import greekUtils   from '@/lib/greek-utils'

export default {
  components: { FilterSearch },
  directives: {},
  mixins    : [AppData, Auth, DataTable],
  data () {
    return {
      ignorePermissionRelations: true,
      viewCustomData           : null,
      loading                  : false,
      permissionsFormValid     : false,
      editObj                  : null,
      selectedId               : -1,
      dataTable                : {
        options: {
          itemsPerPage     : -1,
          mustSort         : false,
          serverSideEnabled: false
        }
      }
    }
  },
  computed: {
    tableHeightOffset () {
      return this.$vuetify.breakpoint.smAndUp ? parseInt(this.selectedId) === 1 ? 224 : 180 : 80 + 68
    },

    headers () {
      if (parseInt(this.selectedId) === 1) {
        return [
          {
            text    : '#',
            align   : 'center',
            value   : 'Id',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Right'),
            align   : 'left',
            value   : 'Permission',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Access'),
            align   : 'center',
            value   : 'Access',
            sortable: false
          }
        ]
      }
      if (this.userIsSuperAdmin) {
        return [
          {
            text    : '#',
            align   : 'center',
            value   : 'Id',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Right'),
            align   : 'left',
            value   : 'Permission',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Api'),
            align   : 'center',
            value   : 'Api',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Access'),
            align   : 'center',
            value   : 'Access',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Edit'),
            align   : 'center',
            value   : 'Manage',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Add'),
            align   : 'center',
            value   : 'Add',
            sortable: false
          },
          {
            text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Delete'),
            align   : 'center',
            value   : 'Delete',
            sortable: false
          }
        ]
      }
      return [
        {
          text    : '#',
          align   : 'center',
          value   : 'Id',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Right'),
          align   : 'left',
          value   : 'Permission',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Access'),
          align   : 'center',
          value   : 'Access',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Edit'),
          align   : 'center',
          value   : 'Manage',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Add'),
          align   : 'center',
          value   : 'Add',
          sortable: false
        },
        {
          text    : this.$t('Staff.StaffGroups.SingleGroup.Headers.Delete'),
          align   : 'center',
          value   : 'Delete',
          sortable: false
        }
      ]
    },
    storeGroup () {
      return this.viewCustomData.find(p => parseInt(p.Id) === 1)
    }
  },
  watch: {
    selectedId: {
      immediate: true,
      handler () {
        this.dataTable.resize.offset = this.$vuetify.breakpoint.smAndUp ? parseInt(this.selectedId) === 1 ? 236 : 180 : parseInt(this.selectedId) === 1 ? 236 + 52 : 180 + 52
      }
    },

    '$vuetify.breakpoint': {
      immediate: false,
      handler () {
        this.dataTable.resize.offset = this.$vuetify.breakpoint.smAndUp ? parseInt(this.selectedId) === 1 ? 236 : 180 : parseInt(this.selectedId) === 1 ? 236 + 52 : 180 + 52
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Staff.Group.All, this.setData)
    this.$bus.$on(window.SocketCommand.Staff.Group.Save, this.onSaveResult)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Staff.Group.All, this.setData)
    this.$bus.$off(window.SocketCommand.Staff.Group.Save, this.onSaveResult)
  },
  methods: {
    getPermissionColor (item, permission, isText = false) {
      permission = permission.toUpperCase()
      let found = true

      if (this.editObj && this.editObj.Packages) {
        const activePackage = this.editObj.Packages.find(pkg => parseInt(pkg.Id) === parseInt(this.editObj.ActivePackage))
        found = activePackage && activePackage.Permissions.includes(parseInt(item.Id))
      }

      switch (permission) {
        case 'API':
          return 'purple' + (isText ? '--text' : '')
        case 'ACCESS':
          return found ? ('green' + (isText ? '--text' : '')) : ('orange' + (isText ? '--text' : ''))
        case 'MANAGE':
          return 'blue' + (isText ? '--text' : '')
        case 'ADD':
          return 'amber' + (isText ? '--text darken-3' : ' darken-3')
        case 'DELETE':
          return 'red' + (isText ? '--text' : '')
        default:
          return 'grey' + (isText ? '--text' : '')
      }
    },

    onCheckboxChange (type, permission) {
      if (this.editObj.Id > 1) {
        if (this.userIsSuperExtraAdmin && this.ignorePermissionRelations) return

        if (type === 'manage' || type === 'add' || type === 'delete') {
          if (!permission.GroupPermissions.includes('access')) {
            permission.GroupPermissions.push('access')
            if (!permission.GroupPermissions.includes('api')) permission.GroupPermissions.push('api')
          }
        }

        if (!permission.GroupPermissions.includes('api') && permission.GroupPermissions.includes('access')) {
          permission.GroupPermissions.push('api')
        } else {
          if (!permission.GroupPermissions.includes('access')) {
            if (this.permissionHasActiveRequires(permission)) permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'api')
            permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'manage')
            permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'add')
            permission.GroupPermissions = permission.GroupPermissions.filter(el => el !== 'delete')
          }
        }

        if (permission.Requires.Parent) {
          const foundPermission = this.editObj.Permissions.find(p => parseInt(p.Id) === parseInt(permission.Requires.Parent))
          if (foundPermission) {
            if (!foundPermission.GroupPermissions.includes('api') && permission.GroupPermissions.includes('api')) foundPermission.GroupPermissions.push('api')
            if (!foundPermission.GroupPermissions.includes('access') && permission.GroupPermissions.includes('access')) foundPermission.GroupPermissions.push('access')
          }
        }

        const permissionChildren = this.permissionGetChildren(permission)
        permissionChildren.forEach(p => {
          if (!p.GroupPermissions.includes('api') && permission.GroupPermissions.includes('api')) {
            p.GroupPermissions.push('api')
          } else if (type === 'access') {
            p.GroupPermissions = p.GroupPermissions.filter(el => el !== 'api')
          }
          if (!p.GroupPermissions.includes('access') && permission.GroupPermissions.includes('access')) {
            p.GroupPermissions.push('access')
          } else if (type === 'access') {
            p.GroupPermissions = p.GroupPermissions.filter(el => el !== 'access')
          }
        })

        if (Array.isArray(permission.Requires.Permissions) && permission.Requires.Permissions.length > 0) {
          this.editObj.Permissions.forEach(permission => {
            if (!Array.isArray(permission.Requires.Permissions)) permission.Requires.Permissions = []
            permission.Requires.Permissions.forEach(r => {
              const foundPermission = this.editObj.Permissions.find(p => parseInt(p.Id) === parseInt(r))
              if (foundPermission) {
                if (!foundPermission.GroupPermissions.includes('api')) {
                  if (permission.GroupPermissions.includes('access') && (!foundPermission.GroupPermissions.includes('api'))) foundPermission.GroupPermissions.push('api')
                } else {
                  if (!foundPermission.GroupPermissions.includes('access') && this.editObj.Permissions.filter(p => (parseInt(p.Id) !== parseInt(permission.Id)) && (!p.GroupPermissions.includes('access') && !p.GroupPermissions.includes('api')) && (p.Requires.Permissions ? p.Requires.Permissions.includes(String(permission.Id)) : false)).length <= 0) {
                    if (!permission.GroupPermissions.includes('access')) foundPermission.GroupPermissions = foundPermission.GroupPermissions.filter(el => el !== 'api')
                  }
                }
              }
            })
          })
        }
      }
    },

    permissionHasActiveRequires (permission) {
      const retVal = this.editObj.Permissions.filter(p => {
        if (!Array.isArray(p.Requires.Permissions)) p.Requires.Permissions = []
        return parseInt(p.Id) !== parseInt(permission.Id) &&
          p.Requires.Permissions.includes(parseInt(permission.Id)) &&
          p.GroupPermissions.includes('access') && p.GroupPermissions.includes('api')
      })
      return retVal.length <= 0
    },

    permissionGetChildren (permission) {
      return this.editObj.Permissions.filter(p => parseInt(p.Requires.Parent) === parseInt(permission.Id))
    },

    onPackageSelection (activePackageId) {
      const activePackage = this.editObj.Packages.find(p => parseInt(p.Id) === parseInt(activePackageId))
      if (activePackage) {
        this.editObj.Permissions.forEach(p => {
          const found = activePackage ? activePackage.Permissions.includes(parseInt(p.Id)) : false
          if (found) {
            p.GroupPermissions = ['access']
          } else {
            p.GroupPermissions = []
          }
        })
      }
    },

    getData () {
      window.callAS(window.SocketCommand.Staff.Group.All, {})
    },

    setData (data) {
      this.$set(this, 'viewCustomData', data)
    },

    onSelect (group) {
      const data = JSON.parse(JSON.stringify(group))

      if (parseInt(data.Id) === 1) {
        const packageAll = data.Packages.find(p => parseInt(p.Id) === 1)
        data.Permissions = data.Permissions.filter(p => packageAll.Permissions.includes(p.Id))
      }

      if (parseInt(data.Id) > 1 && !data.Admin && !this.adminGroups.includes(parseInt(data.Id))) {
        const ownerGroup = this.viewCustomData.find(p => !p.Admin && !p.Editable && !this.adminGroups.includes(parseInt(p.Id)))

        if (data.Id === ownerGroup.Id) {
          data.Permissions = data.Permissions.filter(p => p.GroupPermissions.length > 0)
        } else {
          data.Permissions = data.Permissions.filter(p => ownerGroup.Permissions.find(o => o.Id === p.Id).GroupPermissions.length > 0)
        }
      }

      this.editObj = data
      this.selectedId = data.Id
    },

    onSave () {
      let i = 0
      const permissions = {}
      const saveObj = JSON.parse(JSON.stringify(this.editObj))
      for (i = 0; i < this.editObj.Permissions.length; i++) {
        if (this.editObj.Permissions[i].GroupPermissions.length > 0) {
          permissions[this.editObj.Permissions[i].Id] = this.editObj.Permissions[i].GroupPermissions
        }
      }
      saveObj.Permissions = permissions
      this.loading = true
      window.callAS(window.SocketCommand.Staff.Group.Save, { StaffGroup: saveObj })
    },

    onSaveResult (result) {
      if (result.status) {
        this.editObj = null
        this.selectedId = -1
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'info',
          icon: 'info'
        })
      }
      this.loading = false
    },

    onCancel () {
      this.editObj = null
      this.selectedId = -1
    },

    customSearch (value, search, item) {
      if (!search) return

      const searchString = greekUtils.toGreeklish(String(search).toLocaleLowerCase(this.$i18n.locale))

      const itemString = [
        item.Id && String(item.Id),
        item.Permission && greekUtils.toGreeklish(String(item.Permission).toLocaleLowerCase(this.$i18n.locale))

      ].filter(Boolean).join(' ')

      return itemString.includes(searchString)
    }
  }
}
</script>

<style scoped>

</style>
