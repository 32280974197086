<template>
  <v-container
    class="pa-0 grey darken-4 fill-height"
    fluid
  >
    <v-row
      no-gutters
      class="fill-height"
    >
      <!-- Cart -->
      <v-col class="shrink">
        <cds-cart
          v-if="hasActiveSession"
          style="min-width: 450px;"
        />
      </v-col>

      <!-- Logo -->
      <v-col
        class="text-center"
        style="max-height: 100vh;"
      >
        <v-slide-x-reverse-transition hide-on-leave>
          <cds-welcome v-show="!hasActiveSession" />
        </v-slide-x-reverse-transition>

        <v-scale-transition
          origin="bottom center 0"
          hide-on-leave
        >
          <svg-logo
            v-show="hasActiveSession"
            style="align-content: center; block-size: 98vh;"
            fork-color="#fff"
            pro-color="#fff"
            signal-color1="#7f8082"
            signal-color2="#6b6c6e"
            text-main-active
            text-get-color="#fff"
            text-slogan-color="#fff"
            size="25vw"
          />
        </v-scale-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CdsCart    from '@/components/cds/CdsCart.vue'
import CdsWelcome from '@/components/cds/CdsWelcome.vue'
import SvgLogo    from '@/components/common/SvgLogo.vue'

export default {
  name      : 'Cds',
  components: {
    SvgLogo,
    CdsWelcome,
    CdsCart
  },
  mixins: [],
  data () {
    return {
      cdsChannel      : new BroadcastChannel('cds'),
      hasActiveSession: false
    }
  },
  computed: {},
  watch   : {},
  created () {
    this.cdsChannel.onmessage = this.cdsChannelMessageReceived
  },
  beforeDestroy () {
    this.cdsChannel.close()
  },
  methods: {
    cdsChannelMessageReceived (event) {
      const action = event?.data?.Action || null
      // console.log('cdsChannelMessageReceived: ', event?.data)

      if (action === 'cart:init') {
        this.hasActiveSession = true
      } else if (action === 'cart:destroy' || action === 'cart:complete' || action === 'cart:save') {
        this.hasActiveSession = false
      }
    }
  }
}
</script>

<style scoped>

</style>
