<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        class="filter-toolbar"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Charge.Toolbar.Button.New.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showChargeAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Charge.Toolbar.Button.New.Icon') }}
          </v-icon>&nbsp;

          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Charge.Toolbar.Button.New.Title') }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.charge_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.name`]="{item}">
              {{ getTranslatedField(item, 'Name') }}
            </template>

            <template #[`item.quantity`]="{item}">
              {{ item.Quantity }}
            </template>

            <template #[`item.price`]="{item}">
              {{ item.PriceFmt }}
            </template>

            <template #[`item.tax_id`]="{item}">
              {{ getTranslatedField(item, 'Name', 'TaxLang') }}
            </template>

            <template #[`item.order_restriction`]="{item}">
              <v-chip
                v-if="item.OrderRestriction"
                class="pl-0 ma-1"
                label
                small
              >
                <v-avatar
                  :color="getOrderTypeById(item.OrderRestriction).Color"
                  class="mr-2"
                  tile
                >
                  <v-icon class="white--text">
                    {{ getOrderTypeById(item.OrderRestriction).Icon }}
                  </v-icon>
                </v-avatar>
                <span>{{ getOrderTypeById(item.OrderRestriction).Name }}</span>
              </v-chip>
            </template>

            <template #[`item.source_type`]="{item}">
              <template v-for="sourceTypeId in item.SourceType">
                <v-chip
                  :key="`source-type-${sourceTypeId}`"
                  class="pl-0 ma-1"
                  label
                  small
                >
                  <v-avatar
                    :color="getSourceTypeById(sourceTypeId).Avatar.Color"
                    class="mr-2"
                    tile
                  >
                    <v-icon
                      v-if="getSourceTypeById(sourceTypeId).Avatar.Icon"
                      class="white--text"
                    >
                      {{ getSourceTypeById(sourceTypeId).Avatar.Icon }}
                    </v-icon>

                    <img
                      v-else
                      :src="getSourceTypeById(sourceTypeId).Avatar.Img"
                    >
                  </v-avatar>
                  <span>{{ getSourceTypeById(sourceTypeId).Name }}</span>
                </v-chip>
              </template>
            </template>

            <template #[`item.priority`]="{item}">
              {{ item.Priority }}
            </template>

            <template #[`item.active`]="{item}">
              <v-switch
                v-model="item.Active"
                inset
                :disabled="!userCanManage"
                :label="item.Active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                color="success"
                class="ma-0"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanManage"
                :small="$vuetify.breakpoint.xsOnly"
                class="cyan elevation-2 ma-0"
                dark
                icon
                @click="showChargeEditDialog(item)"
              >
                <v-icon>edit</v-icon>
              </v-btn>

              <v-btn
                v-if="userCanDelete"
                :small="$vuetify.breakpoint.xsOnly"
                :disabled="item.IsSystem"
                :class="{'grey': item.IsSystem}"
                class="red elevation-2 ma-1"
                dark
                icon
                @click="deleteItem(item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <charge-add-dialog
      :item-to-edit="selectedItem"
      :visible.sync="addEditChargeDialog"
    />

    <confirm-dialog
      :html-content="$t('Charge.Dialog.Delete.Content')"
      :html-title="$t('Charge.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Charge.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>
import AppData         from '@/mixins/appdata'
import Auth            from '@/mixins/auth'
import Translatable    from '@/mixins/translatable'
import DataTable       from '@/mixins/data-table/dataTable'
import ConfirmDialog   from '@/components/common/ConfirmDialog'
import FilterSearch    from '@/components/common/filter/FilterSearch.vue'
import ChargeAddDialog from '@/components/charges/ChargeAddDialog.vue'
import SourceType      from '@/mixins/orders/sourceType'

export default {
  name      : 'Charges',
  components: {
    ChargeAddDialog,
    FilterSearch,
    'confirm-dialog': ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, DataTable, SourceType],
  data () {
    return {
      confirmDialogDeleteVisible: false,
      selectedItem              : null,
      addEditChargeDialog       : false,
      dataTable                 : {
        socketCommand: {
          list  : window.SocketCommand.ExtraCharge.All,
          save  : window.SocketCommand.ExtraCharge.Save,
          delete: window.SocketCommand.ExtraCharge.Delete
        },
        options: {
          defaultSortBy: 'charge_id',
          sortBy       : ['charge_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Charge.Table.Headers.Id'),
            value    : 'charge_id',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Charge.Table.Headers.Name'),
            value    : 'name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Charge.Table.Headers.Quantity'),
            value    : 'quantity',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Charge.Table.Headers.Price'),
            value    : 'price',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Charge.Table.Headers.Tax'),
            value    : 'tax_id',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.SourceType'),
            value    : 'source_type',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Marketing.Coupon.ListView.Table.Headers.OrderRestriction'),
            value    : 'order_restriction',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Charge.Table.Headers.Priority'),
            value    : 'priority',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Charge.Table.Headers.Active'),
            value    : 'active',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Charge.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 100
          }
        ]
      }
    }
  },
  computed: {
    sourceTypes () {
      const sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT) || []

      return sourceTypesDefault.map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    getOrderTypeById (orderTypeId) {
      const orderTypes = this.$t('Restaurant.CatalogAddDialog.Field.OrderType.Items')

      return orderTypes.find(orderType => parseInt(orderType.Id) === parseInt(orderTypeId))
    },

    getSourceTypeById (id) {
      return this.sourceTypes.find(sourceType => parseInt(sourceType.Id) === parseInt(id))
    },

    showChargeAddDialog () {
      this.selectedItem = null
      this.addEditChargeDialog = true
    },

    showChargeEditDialog (item) {
      this.selectedItem = item
      this.addEditChargeDialog = true
    },

    setData (data) {
      this.setDataInternal(data)
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.ExtraCharge.Save, item)
    },

    deleteItem (item) {
      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.ExtraCharge.Delete, { Id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Charge.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  width    : 75px !important;
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
