export default {
  Title: 'Παραστατικά | Παραστατικό | Παραστατικά',

  Status: {
    Transmitted: {
      Id   : 1,
      Name : 'Διαβιβασμένο',
      Color: 'blue lighten-1'
    },
    Canceled: {
      Id   : 2,
      Name : 'Ακυρωμένο',
      Color: 'red'
    },
    Issued: {
      Id   : 3,
      Name : 'Εκδοθέν',
      Color: 'blue lighten-1'
    },
    Closed: {
      Id   : 4,
      Name : 'Κλειστό',
      Color: 'success'
    },
    Open: {
      Id   : 5,
      Name : 'Ανοιχτό',
      Color: 'warning'
    },
    Waiting: {
      Id   : 6,
      Name : 'Σε Αναμονή',
      Color: 'blue-grey'
    }
  },

  Table: {
    Button: {
      View: {
        Title: 'Προβολή Πελάτη',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      },
      Delete: {
        Title: 'Διαγραφή Πελάτη',
        Icon : 'delete',
        Color: 'red'
      }
    },

    Headers: {
      Id      : '#',
      Type    : 'Τύπος',
      Number  : 'Αρ.Προοδ.',
      Order   : 'Παραγγελία',
      Total   : 'Πληρ. Ποσό',
      Aade    : 'ΑΑΔΕ',
      Status  : 'Κατάσταση',
      ClosedBy: 'Έκλεισε Από',
      Related : 'Σχετικά Παραστατικά',
      Date    : 'Ημ/νία',
      Actions : ''
    },

    Filter: {
      filterFailure: {
        Type       : 'v-btn-toggle',
        Label      : 'Απώλεια Διασύνδεσης',
        PlaceHolder: 'Όλα τα παραστατικά',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα',
            Icon : 'mdi-wifi',
            Color: 'blue darken-1',
            Class: 'white--text'
          },
          {
            Value: 'true',
            Name : 'Απώλεια',
            Icon : 'mdi-wifi-remove',
            Color: 'red darken-1',
            Class: 'white--text'
          },
          {
            Value: 'false',
            Name : 'Χωρίς',
            Icon : 'mdi-wifi-check',
            Color: 'light-green darken-1',
            Class: 'white--text'
          }
        ]
      },
      filterStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Κατάσταση Παραστατικού',
        PlaceHolder: 'Όλα τα παραστατικά',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        Items      : []
      },
      filterInvoiceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Τύπος Παραστατικού',
        PlaceHolder: 'Όλοι οι τύποι',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        Items      : []
      },
      filterDate: {
        Type       : 'v-date-picker',
        Label      : 'Ημερομηνία Έκδοσης',
        PlaceHolder: 'Επιλέξτε Ημερομηνία',
        Icon       : 'event'
      }
    }
  }

}
