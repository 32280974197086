import BaseValidator from '../../../lib/data/Validations/BaseValidator'

export default class AccountBillingValidator extends BaseValidator {
  rules = {
    Firstname: { required: true, type: 'string', betweenLen: [2, 128] },
    Lastname : { required: true, type: 'string', betweenLen: [2, 128] },
    Telephone: { required: true, type: 'string', betweenLen: [8, 16] },
    Email    : { required: true, type: 'email', maxLen: 96 },

    IsReceipt       : { required: true, type: 'boolean' },
    Profession      : { required: true, type: 'string', betweenLen: [2, 128] },
    DistinctiveTitle: { required: false, type: 'string', betweenLen: [2, 128] },
    Company         : { requiredIf: ['IsInvoice', true], type: 'string', betweenLen: [2, 100] },
    Irs             : { requiredIf: ['CountryId', '86'], type: 'string', betweenLen: [2, 128] },
    Vat             : { required: true, type: 'string', betweenLen: [7, 12] },
    Gemi            : { required: false, type: 'string', length: 12 },
    CompanyPhone    : { required: false, type: 'string', betweenLen: [8, 16] },

    CountryId: { required: true },
    City     : { required: true, type: 'string', betweenLen: [2, 100] },
    Street   : { required: true, type: 'string', betweenLen: [2, 100] },
    State    : { required: true, type: 'string', betweenLen: [2, 100] },
    PostCode : { required: true, type: 'string', betweenLen: [2, 15] }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
