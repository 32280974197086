/**
 * Enum for support departments.
 * @readonly
 * @enum {String} SupportDepartmentEnum
 */

const SupportDepartmentEnum = {
  SALES    : '1',
  FINANCE  : '2',
  MARKETING: '3',
  SUPPORT  : '6'
}

export default SupportDepartmentEnum
