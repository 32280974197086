<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'EmptyLayout',
  components: {},
  mixins    : [AppData, Auth],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {}
}

</script>

<style scoped>

</style>
