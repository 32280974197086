<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              {{ $t('Delivery.SelectDeliveryStaffDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $tc('Delivery.Title', 0) }}
            </div>
            <div class="text-caption">
              {{ $t('Delivery.SelectDeliveryStaffDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <div
            v-if="viewData.length <= 0"
            class="text-center grey--text"
          >
            <v-icon
              color="grey lighten-2"
              size="128"
            >
              directions_bike
            </v-icon>
            <div class="text-h6 mb-2">
              {{ $t('Delivery.SelectDeliveryStaffDialog.Result.NoDeliveryStaff.Title') }}
            </div>
            <div class="text-subtitle-1">
              {{ $t('Delivery.SelectDeliveryStaffDialog.Result.NoDeliveryStaff.Subtitle.Please') }}
              <router-link :to="{name: 'DeliveryStaff'}">
                {{ $t('Delivery.SelectDeliveryStaffDialog.Result.NoDeliveryStaff.Subtitle.Open') }}
              </router-link>
              {{ $t('Delivery.SelectDeliveryStaffDialog.Result.NoDeliveryStaff.Subtitle.ShiftOr') }}
              <router-link :to="{name: 'DeliveryStaff'}">
                {{ $t('Delivery.SelectDeliveryStaffDialog.Result.NoDeliveryStaff.Subtitle.Create') }}
              </router-link>
              {{ $t('Delivery.SelectDeliveryStaffDialog.Result.NoDeliveryStaff.Subtitle.DeliveryStaff') }}
            </div>
          </div>

          <v-container
            v-else
            class="pa-0"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <v-list
                  v-for="(item) in viewData"
                  :key="item.Id"
                  class="mb-2 mt-2 pb-0 pt-0"
                  two-line
                >
                  <template v-if="item.HasActiveShift">
                    <v-list-item
                      :disabled="!item.HasActiveShift"
                      class="rounded"
                      :class="isDarkMode ? 'grey darken-4' : 'grey lighten-3'"
                      ripple
                      @click="setDeliveryStaff(item)"
                    >
                      <v-list-item-avatar size="54">
                        <v-icon
                          :color="item.HasActiveShift ? 'light-green' : 'grey lighten-1'"
                          size="54"
                        >
                          add_circle
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="grey--text">
                        <v-list-item-title
                          class="text-left"
                          :class="isDarkMode ? 'white--text' : 'grey--text text--darken-2'"
                        >
                          {{ item.Firstname }} {{ item.Lastname }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.Phone || item.Email">
                          <span class="grey--text">
                            {{ item.Phone }} {{ item.Email }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData from '../../mixins/appdata'

export default {
  components: {},
  mixins    : [AppData],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    selectedDeliveryStaff: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    viewData () {
      return window.Vue.deliveryStaffItems.filter(deliveryStaff => deliveryStaff.HasActiveShift)
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    currentDeliveryStaff: {
      get () {
        return this.selectedDeliveryStaff
      },
      set (val) {
        this.$emit('update:selectedDeliveryStaff', val)
      }
    }
  },
  methods: {
    setDeliveryStaff (deliveryStaff) {
      if (!deliveryStaff.HasActiveShift) return

      this.currentDeliveryStaff = deliveryStaff
      this.isVisible = false
    },
    onCancelButtonClick () {
      this.currentDeliveryStaff = null
      this.isVisible = false
    }
  }
}
</script>
