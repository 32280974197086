<template>
  <v-container
    class="pa-1"
    fluid
  >
    <v-row no-gutters>
      <!-- Toolbar -->
      <v-col
        id="posToolbar"
        ref="toolbarFlex"
        cols="12"
      >
        <v-container
          class="pa-0"
          fluid
        >
          <v-row no-gutters>
            <v-col cols="12">
              <pos-toolbar
                :disabled="disableToolbar"
                :drawer.sync="cartDrawer"
              />
            </v-col>

            <v-col
              v-if="categoryTabs"
              cols="12"
            >
              <pos-catalog-categories-tabs
                :active-categories="activeCategories"
                :active-category="activeCategory"
                @on-select-category="onCategoryClick"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <!-- Menu Categories -->
      <v-col
        v-if="!categoryTabs"
        lg="3"
        cols="3"
      >
        <pos-catalog-categories
          :active-categories="activeCategories"
          :active-category="activeCategory"
          @on-select-category="onCategoryClick"
        />
      </v-col>

      <!-- Category Items -->
      <v-col
        :lg="!categoryTabs ? 9 : 12"
        :cols="!categoryTabs ? 9 : 12"
      >
        <v-container
          class="pa-1"
          :class="{'pt-0': isTableOrder && !$vuetify.breakpoint.xsOnly, 'pl-5': $vuetify.breakpoint.mdAndDown && !categoryTabs}"
          fluid
        >
          <v-row dense>
            <v-col
              v-if="searchEnabled"
              cols="12"
            >
              <div>
                <v-text-field
                  v-model="searchProduct"
                  :label="$t('Pos.Pos.ListView.Field.Search.Label')"
                  :prepend-inner-icon="$t('Pos.Pos.ListView.Field.Search.Icon')"
                  :background-color="isDarkMode ? 'grey darken-4' : 'white'"
                  filled
                  dense
                  clearable
                  color="light-green darken-1"
                  hide-details
                  @input="onSearchProduct"
                />
                <v-divider />
              </div>
            </v-col>

            <v-col
              id="categoryDetailsList"
              style="overflow-y: auto;"
              class="pb-12 pb-sm-0"
              cols="12"
            >
              <template v-if="!activeCategoryHasItems">
                <div class="text-center grey--text pa-6">
                  <v-icon
                    class="mb-4"
                    color="grey lighten-2"
                    size="128"
                  >
                    {{ $t('Pos.Pos.ListView.Categories.NoData.Icon') }}
                  </v-icon>

                  <div class="text-h6 mb-2">
                    {{ $t('Pos.Pos.ListView.Categories.NoData.Title') }}
                  </div>

                  <div class="text-subtitle-1">
                    {{ $t('Pos.Pos.ListView.Categories.NoData.Subtitle') }}
                  </div>
                </div>
              </template>

              <template v-else>
                <v-container
                  class="pa-1"
                  fluid
                >
                  <v-row dense>
                    <v-col
                      v-if="!!searchProduct && !!activeMenuItemsTags.length"
                      cols="12"
                      class="px-0"
                    >
                      <v-chip-group
                        v-model="activeTags"
                        multiple
                      >
                        <v-chip
                          v-for="tag in activeMenuItemsTags"
                          :key="`tag-tags-${tag.Id}`"
                          :value="tag.Id"
                          class="ma-0 ml-1 px-1"
                          :color="(activeTags || []).includes(tag.Id) ? tag.Color || '#666666' : ''"
                          :style="(activeTags || []).includes(tag.Id) ? `color: ${getContrastColor(tag.Color || '#666666', true, 135)}` : ''"
                          small
                          label
                        >
                          <v-icon
                            v-if="tag.Icon && !tag.LabelOnly"
                            class="mr-2"
                            small
                          >
                            {{ tag.Icon }}
                          </v-icon>

                          {{ getTranslatedField(tag, 'Label') }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                    <template v-for="(item) in posMenuItemsActive">
                      <template v-if="item.Category && item.Category.Status">
                        <v-col
                          v-if="item.Items.length > 0"
                          :key="item.Id"
                          cols="12"
                        >
                          <div
                            class="v-subheader font-weight-medium grey--text text--darken-1 text-subtitle-1 theme--light pa-1 d-inline-flex"
                            style="max-height: 36px; width: 100%;"
                          >
                            <span>{{ getTranslatedField(item.Category, 'Name') }}</span>

                            <v-spacer />

                            <v-chip-group
                              v-if="$vuetify.breakpoint.lgAndUp && !searchProduct && !!activeMenuItemsTags.length"
                              v-model="activeTags"
                              multiple
                            >
                              <v-chip
                                v-for="tag in activeMenuItemsTags"
                                :key="`tag-tags-${tag.Id}`"
                                :value="tag.Id"
                                class="ma-0 ml-1 px-1"
                                :color="(activeTags || []).includes(tag.Id) ? tag.Color || '#666666' : ''"
                                :style="(activeTags || []).includes(tag.Id) ? `color: ${getContrastColor(tag.Color || '#666666', true, 135)}` : ''"
                                small
                                label
                              >
                                <v-icon
                                  v-if="tag.Icon && !tag.LabelOnly"
                                  class="mr-2"
                                  small
                                >
                                  {{ tag.Icon }}
                                </v-icon>

                                {{ getTranslatedField(tag, 'Label') }}
                              </v-chip>
                            </v-chip-group>
                          </div>

                          <v-chip-group
                            v-if="$vuetify.breakpoint.mdAndDown && !searchProduct && !!activeMenuItemsTags.length"
                            v-model="activeTags"
                            multiple
                          >
                            <v-chip
                              v-for="tag in activeMenuItemsTags"
                              :key="`tag-tags-${tag.Id}`"
                              :value="tag.Id"
                              class="ma-0 ml-1 px-1"
                              :color="(activeTags || []).includes(tag.Id) ? tag.Color || '#666666' : ''"
                              :style="(activeTags || []).includes(tag.Id) ? `color: ${getContrastColor(tag.Color || '#666666', true, 135)}` : ''"
                              small
                              label
                            >
                              <v-icon
                                v-if="tag.Icon && !tag.LabelOnly"
                                class="mr-2"
                                small
                              >
                                {{ tag.Icon }}
                              </v-icon>

                              {{ getTranslatedField(tag, 'Label') }}
                            </v-chip>
                          </v-chip-group>
                        </v-col>

                        <template v-for="menu in menuItemsFiltered(item)">
                          <v-col
                            :key="`${menu.Id}-${item.Category.Id}`"
                            :xl="(12/menuItemsPerRowXl)"
                            :lg="(12/menuItemsPerRowLg)"
                            cols="12"
                          >
                            <v-list
                              class="mb-0 pb-0 pt-0"
                              dense
                              three-line
                            >
                              <v-list-item
                                :disabled="isMenuItemDisabled(menu)"
                                :inactive="isMenuItemDisabled(menu)"
                                class="menu-item"
                                :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-4']"
                                ripple
                                @click="showAddMenuOptionsDialog(menu, item.Category)"
                              >
                                <div
                                  v-if="getCategoryColorByCategory(item.Category)"
                                  :style="'background-color: #' + getCategoryColorByCategory(item.Category) + ';'"
                                  style="position: absolute; width:5px; height: 100%; margin-left: -8px; padding: 0"
                                />

                                <div
                                  v-if="menu.Image && $vuetify.breakpoint.mdAndUp && leftImage"
                                  :style="$vuetify.breakpoint.smAndDown ? 'height: 90px; width: 125px;' : 'height: 90px; width: 125px;'"
                                  class="mr-2"
                                >
                                  <img
                                    v-lazy="appConfig.LOCATION_DATA.CdnImagesUrl + menu.Image"
                                    :style="{
                                      height: '90px',
                                      width: $vuetify.breakpoint.smAndDown ? '125px' : '125px',
                                      filter: (isMenuItemDisabled(menu) ? 'grayscale(1)' : 'grayscale(0)')
                                    }"
                                    style="border-radius: 6px; object-fit: cover;"
                                  >
                                </div>

                                <v-list-item-content class="pa-0 pr-1">
                                  <v-list-item-title
                                    :style="{'padding-right': leftImage && quickAddTop && $vuetify.breakpoint.mdAndUp ? '32px' : 0}"
                                    class="mb-auto"
                                  >
                                    <v-chip
                                      v-if="menu.Number"
                                      :disabled="isMenuItemDisabled(menu)"
                                      class="pa-1 mr-1 text-center"
                                      label
                                      x-small
                                    >
                                      {{ menu.Number }}
                                    </v-chip>

                                    <span
                                      :style="{'font-size': ($vuetify.breakpoint.xsOnly ? '13px' : '14px')}"
                                      class="font-weight-medium"
                                    >
                                      {{ getTranslatedField(menu, 'Name') }}
                                    </span>
                                  </v-list-item-title>

                                  <v-list-item-subtitle
                                    v-if="getTranslatedField(menu, 'Description')"
                                    :style="{'padding-right': leftImage && quickAddTop && $vuetify.breakpoint.mdAndUp ? '32px' : 0}"
                                    class="mb-auto"
                                    v-html="getTranslatedField(menu, 'Description')"
                                  />

                                  <div
                                    style="font-size: 14px;"
                                    class="mt-auto"
                                  >
                                    <div class="">
                                      <template v-if="menu.SpecialStatus && menu.IsSpecial">
                                        <div
                                          :class="[isMenuItemDisabled(menu) ? 'grey--text' : isDarkMode ? 'grey--text text--lighten-1' : 'grey--text text--darken-1']"
                                          class="pt-1 white--text d-inline-block"
                                        >
                                          <span class="old-price grey--text">
                                            {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.Price)) : getMenuPriceWithExtraCharges(menu) | currency }}
                                          </span> {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.SpecialPrice)) : getMenuPriceWithExtraCharges(menu, true) | currency }}
                                        </div>
                                      </template>
                                      <template v-else>
                                        <div
                                          :class="[isMenuItemDisabled(menu) ? 'grey--text lighten-1' : 'success--text text--darken-1']"
                                          class="pt-1 white--text d-inline-block"
                                        >
                                          <template v-if="menu.UnitId > 1">
                                            {{ getMenuUnitBasePriceWithOptions(menu) | currency }}
                                            <span
                                              class="grey--text text-caption"
                                            >/ {{ getMeasurementUnitById(menu.UnitId).BaseUnit }}</span>
                                          </template>
                                          <template v-else>
                                            {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.Price)) : getMenuPriceWithExtraCharges(menu) | currency }}
                                          </template>
                                        </div>
                                      </template>

                                      <div class="d-inline-block mx-1">
                                        <v-chip
                                          v-if="menu.SubtractStock"
                                          :disabled="isMenuItemDisabled(menu)"
                                          :class="[isMenuItemDisabled(menu) ? isDarkMode ? 'grey darken-3' : 'grey lighten-1' : subtractStockThresholdColor(menu)]"
                                          class="text-caption white--text text-center align-center justify-center ma-0 px-2"
                                          label
                                          small
                                          style="width: auto; min-width: 30px; max-height: 16px; margin-bottom: 2px !important;"
                                        >
                                          {{ formatUnitQuantity($i18n.locale, menu.Quantity, getMeasurementUnitById(menu.UnitId)) }}
                                        </v-chip>
                                      </div>
                                    </div>

                                    <!-- Tags -->
                                    <div :style="(leftImage ? quickAddMenuItemEnabled ? 'margin-right: 40px;' : 'margin-right: 5px;' : !menu.Image ? quickAddMenuItemEnabled ? 'margin-right: 40px;' : 'margin-right: 5px;' : tagsMarginStyle) + tagsPositionStyle ">
                                      <template v-for="tag in getMenuItemTags(menu.DetailsData)">
                                        <v-tooltip
                                          v-if="tag.Visible && !tag.System"
                                          :key="`menu-tag-tooltip-${tag.Id}`"
                                          :color="tag.Color || '#666666'"
                                          bottom
                                        >
                                          <template #activator="{on}">
                                            <v-chip
                                              v-if="tag.Visible && !tag.System"
                                              :key="`menu-tag-${tag.Id}`"
                                              :disabled="isMenuItemDisabled(menu)"
                                              :color="tag.Color || '#666666'"
                                              small
                                              label
                                              class="menu-tag"
                                              :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`"
                                              v-on="on"
                                            >
                                              <v-icon
                                                v-if="tag.Icon && !tag.LabelOnly"
                                                small
                                              >
                                                {{ tag.Icon }}
                                              </v-icon>

                                              <span
                                                v-if="!tag.Icon || tag.LabelOnly"
                                                class="text-caption"
                                                :class="{'ml-1': !!tag.Icon && !tag.LabelOnly}"
                                              >
                                                {{ tag.Label }}
                                              </span>
                                            </v-chip>
                                          </template>

                                          <div :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`">
                                            <div class="font-weight-bold text-center">
                                              {{ tag.Lang[$i18n.locale].Label }}
                                            </div>
                                            <div class="text-caption text-left">
                                              {{ tag.Lang[$i18n.locale].Description }}
                                            </div>
                                          </div>
                                        </v-tooltip>
                                      </template>
                                    </div>
                                  </div>
                                </v-list-item-content>

                                <div
                                  v-if="menu.Image && $vuetify.breakpoint.mdAndUp && !leftImage"
                                  :style="$vuetify.breakpoint.smAndDown ? 'height: 90px; width: 125px;' : 'height: 90px; width: 125px;'"
                                >
                                  <img
                                    v-lazy="appConfig.LOCATION_DATA.CdnImagesUrl + menu.Image"
                                    :style="{
                                      height: '90px',
                                      width: $vuetify.breakpoint.smAndDown ? '125px' : '125px',
                                      filter: (isMenuItemDisabled(menu) ? 'grayscale(1)' : 'grayscale(0)')
                                    }"
                                    style="border-radius: 6px; object-fit: cover;"
                                  >
                                </div>

                                <template v-if="quickAddMenuItemEnabled">
                                  <div :style="quickAddPositionStyle">
                                    <v-btn
                                      :class="[isMenuItemDisabled(menu) ? isDarkMode ? 'grey darken-3' : 'grey lighten-1' : 'success']"
                                      :disabled="isMenuItemDisabled(menu)"
                                      :style="quickAddButtonStyle"
                                      class="white--text pa-0"
                                      text
                                      ripple
                                      small
                                      @click.native.stop="quickAddMenuItemToCart(menu)"
                                    >
                                      <v-progress-circular
                                        v-if="menu.Loading"
                                        color="white"
                                        indeterminate
                                        size="16"
                                        style="margin: 3px;"
                                        width="2"
                                      />
                                      <v-icon v-else>
                                        add
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                </template>
                              </v-list-item>
                              <v-divider />
                            </v-list>
                          </v-col>
                        </template>
                      </template>
                    </template>
                  </v-row>
                </v-container>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Translatable             from '@/mixins/translatable'
import PosCatalog               from '@/mixins/pos/posCatalog'
import Resizable                from '@/mixins/resizable'
import PosOrder                 from '@/mixins/pos/posOrder'
import PosToolbar               from '@/components/pos/PosToolbar'
import PosCatalogCategories     from '@/components/pos/PosCatalogCategories.vue'
import PosCatalogCategoriesTabs from '@/components/pos/PosCatalogCategoriesTabs.vue'
import greekUtils               from '@/lib/greek-utils'
import { formatUnitQuantity }   from '@/lib/currency/currency'
import { getContrastColor }     from '@/lib/color'
import { clone }                from '@/lib/helper/helper'

export default {
  name      : 'PosCatalog',
  components: {
    PosCatalogCategoriesTabs,
    PosCatalogCategories,
    PosToolbar
  },
  directives: {},
  mixins    : [Translatable, Resizable, PosCatalog, PosOrder],
  props     : {
    drawer: {
      type   : Boolean,
      default: false
    },
    disableToolbar: {
      type   : Boolean,
      default: false
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      searchProduct    : '',
      menuItemsPerRowXl: 3,
      menuItemsPerRowLg: 2,
      selectedMenuItem : null,
      quickAddTop      : false
    }
  },
  computed: {
    tagsPositionStyle () {
      return 'position: absolute; bottom: 1px; right: -2px;'
    },
    tagsMarginStyle () {
      return this.$vuetify.breakpoint.smAndDown ? 'margin-right: 40px;' : 'margin-right: 135px;'
    },

    quickAddPositionStyle () {
      if (!this.quickAddTop) return 'position: absolute; bottom: 7px; right: 6px'

      return 'position: absolute; top: 7px; right: 6px'
    },

    quickAddButtonStyle () {
      if (!this.quickAddTop) return 'min-width: 30px; height: 30px; border-radius: 16px 0px 4px 0px;'

      return 'min-width: 30px; height: 30px; border-radius: 0px 4px 0px 16px;'
    },

    searchEnabled () {
      return this.appConfig?.LOCATION_DATA?.MenuSearchEnabledPos || false
    },

    categoryTabs () {
      return this.$vuetify.breakpoint.smAndDown ? this.appConfig?.LOCATION_DATA?.MenuCategoriesTabsPosMobile ?? false : this.appConfig?.LOCATION_DATA?.MenuCategoriesTabsPos ?? false
    },

    leftImage () {
      return this.appConfig?.LOCATION_DATA?.MenuListItemImageLeftPos || false
    },

    quickAddMenuItemEnabled () {
      return this.appConfig?.LOCATION_DATA?.MenuOptionsDialog?.QuickAdd || false
    },

    activeCategories () {
      return this.posMenuItems?.categories ? this.posMenuItems.categories.filter(cat => cat.Status) : []
    },

    activeCategoryHasItems () {
      if (!this.posMenuItemsActive?.length) return false

      return !!this.posMenuItemsActive?.reduce((a, b) => a + b?.Items?.length || 0, 0)
    },

    cartDrawer: {
      get () {
        return this.drawer
      },
      set (val) {
        this.$emit('update:drawer', val)
      }
    }
  },
  watch: {
    'posCart.SourceTypeId': {
      immediate: false,
      handler () {
        this.onPosMenuUpdate()
      }
    },
    'posCart.OrderType': {
      immediate: false,
      handler () {
        this.onPosMenuUpdate()
      }
    }
  },
  beforeCreate () {
  },
  created () {
    if (this.posMenuItems?.categories) {
      const arr = this.posMenuItems.categories.filter(item => item.Status)
      const cat = arr.length > 0 ? arr[0] : null
      this.getCategoryDetails(cat)
    } else {
      this.getCategoryDetails(null)
    }

    if (this.isTableOrder && this.$route.params?.area?.CatalogId) {
      this.setSelectedCatalogById(this.$route.params?.area?.CatalogId)
    }

    if (this.$route.params?.isFromCopy && this.$route.params?.catalogId) {
      this.setSelectedCatalogById(this.$route.params?.catalogId)
    }

    this.$bus.$on(window.SocketCommand.Menu.PosUpdate, this.onPosMenuUpdate)
    this.$bus.$on(window.SocketCommand.Cart.Item.Add, this.onCartMenuItemAddOrUpdate)
    this.$bus.$on(window.SocketCommand.Cart.Item.Update, this.onCartMenuItemAddOrUpdate)
    this.$bus.$on('onSaveMenuOptions', this.onSaveMenuOptions)
    this.$bus.$on('cart:order-source-type-selected', this.onSourceTypeSelected)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Menu.PosUpdate, this.onPosMenuUpdate)
    this.$bus.$off(window.SocketCommand.Cart.Item.Add, this.onCartMenuItemAddOrUpdate)
    this.$bus.$off(window.SocketCommand.Cart.Item.Update, this.onCartMenuItemAddOrUpdate)
    this.$bus.$off('onSaveMenuOptions', this.onSaveMenuOptions)
    this.$bus.$off('cart:order-source-type-selected', this.onSourceTypeSelected)
  },
  destroyed () {},
  methods: {
    getContrastColor,
    formatUnitQuantity,

    menuItemsFiltered (item) {
      const selectedTags = this.activeTags || []
      const items = item?.Items || []

      if (selectedTags.length) {
        return items.filter(menu => menu.DetailsData.Tags.some(tag => selectedTags.includes(tag)))
      }

      return items
    },

    setSelectedCatalogById (id) {
      if (!id) return

      const activeCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs?.filter(catalog => catalog.Status) || []
      const catalog = activeCatalogs.find(catalog => parseInt(catalog.Id) === parseInt(id))

      if (catalog) {
        this.selectedCatalog = catalog
        this.$nextTick(() => {
          this.onPosMenuUpdate()
        })
      }
    },

    subtractStockThresholdColor (menu) {
      const subtractStockThresholds = this.appConfig?.LOCATION_DATA?.SubtractStockThreshold || this.$t('Settings.Order.Field.SubtractStockThreshold.Values')
      const valueMultiplier = menu.UnitId > 1 ? this.getMeasurementUnitById(menu.UnitId).Rate : 1

      if (menu.SubtractStock) {
        if (menu.Quantity <= ((subtractStockThresholds.Tier3.Value || 1) * valueMultiplier)) return subtractStockThresholds.Tier3.Color
        if (menu.Quantity <= ((subtractStockThresholds.Tier2.Value || 1) * valueMultiplier)) return subtractStockThresholds.Tier2.Color
        if (menu.Quantity <= ((subtractStockThresholds.Tier1.Value || 1) * valueMultiplier)) return subtractStockThresholds.Tier1.Color

        return subtractStockThresholds.Default.Color
      }

      return 'grey'
    },

    onSourceTypeSelected (sourceTypeId, catalog) {
      this.selectedCatalog = catalog || null

      this.$nextTick(() => {
        this.posCart.SourceTypeId = sourceTypeId
        this.onPosMenuUpdate()
      })
    },

    onSaveMenuOptions (data) {
      if (!data?.Id) return

      const menuItems = this.getMenuItemsAllById(data.Id)

      menuItems.forEach(menuItem => {
        this.$set(menuItem, 'Loading', true)
      })
    },

    onCartMenuItemAddOrUpdate (data, payload) {
      const menuId = data?.Payload?.MenuId || payload?.MenuItem?.Id
      // console.log('>>>>>>> onCartMenuItemAddOrUpdate', data, payload, menuId)

      if (!menuId) return

      const menuItemsActive = this.getMenuItemsActiveById(menuId) || []
      menuItemsActive.forEach(menuItem => {
        this.$set(menuItem, 'Loading', false)
      })

      const menuItems = this.getMenuItemsAllById(menuId) || []
      menuItems.forEach(menuItem => {
        this.$set(menuItem, 'Loading', false)
      })
    },

    onPosMenuUpdate () {
      this.lastPosMenuUpdate = new Date().getTime()
      this.$nextTick(() => {
        this.getCategoryDetails(this.activeCategory, this.posMenuItems)
      })
    },

    isMenuItemDisabled (menu) {
      return (
        menu.Status
          ? (menu.SubtractStock && (menu.Quantity <= 0 || (this.menuItemQuantityInCart(menu) > 0 && this.menuItemQuantityInCart(menu) >= menu.Quantity)))
          : !menu.Status
      )
    },

    showAddMenuOptionsDialog (menu) {
      if (!menu || menu.Loading) return

      this.$bus.$emit('cart:show-add-menu-dialog', menu)
    },

    quickAddMenuItemToCart (menu) {
      if (!menu || menu.Loading) return

      this.$bus.$emit('cart:cart-quick-add-item', menu)
    },

    onSearchProduct (data) {
      clearTimeout(this.debounceSearchTimeout)
      this.debounceSearchTimeout = setTimeout(() => {
        this.onSearchProductDebounced(data)
      }, 350)
    },

    onSearchProductDebounced (data) {
      if (!data) {
        if (this.activeCategory && parseInt(this.activeCategory.Id) !== -1) {
          const categoryData = this.posMenuItems.items.find((obj) => parseInt(obj.Category.Id) === parseInt(this.activeCategory.Id))
          this.posMenuItemsActive = [categoryData ? clone(categoryData) : []]
        } else {
          this.posMenuItemsActive = clone(this.posMenuItems.items)
        }
        return
      } else {
        this.posMenuItemsActive = clone(this.posMenuItems.items)
      }

      const categoryDetailsList = document.getElementById('categoryDetailsList')
      if (categoryDetailsList) categoryDetailsList.scrollTo(0, 0)

      const searchString = greekUtils.toGreeklish(data?.trim()?.toLowerCase())
      if (searchString.length <= 0) return

      const menuItemsActive = clone(this.posMenuItemsActive)
      menuItemsActive.forEach((menuItem, index) => {
        menuItemsActive[index].Items = menuItem.Items.filter(obj => {
          const str = greekUtils.toGreeklish(`
              ${ obj.Name }
              ${ obj.CategoryName }
              ${ obj.Description }
              ${ obj.Number }
              ${ this.getMenuItemTags(obj?.DetailsData).map(v => `${ v.Label } ${ v.Description }`).join(' ') }
              ${ Object.values(obj.Lang).map(v => `${ v.Name } ${ v.Description } ${ v.LongDescription }`).join(' ') }
          `).toLowerCase()

          return obj.ActivePos && str.includes(searchString)
        })
      })

      this.posMenuItemsActive = JSON.parse(JSON.stringify(menuItemsActive))
    },

    onResize () {
      const categoriesList = document.getElementById('categoriesList')
      const categoryDetailsList = document.getElementById('categoryDetailsList')
      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const posToolbarHeight = document.getElementById('posToolbar') ? document.getElementById('posToolbar').offsetHeight : 0

      if (categoriesList) categoriesList.style.height = (innerHeight - headerHeight - footerHeight - posToolbarHeight - (this.isTableOrder ? 6 : 11)) + 'px'
      if (categoryDetailsList) categoryDetailsList.style.height = (innerHeight - headerHeight - footerHeight - posToolbarHeight - (this.searchEnabled ? 46 : 0) - (this.isTableOrder ? 18 : 28)) + 'px'
    }
  }
}
</script>

<style scoped>
/deep/ .price-tag {
  border-radius : 2px;
  min-width     : 55px;
  min-height    : 28px;
  text-align    : center;
}

/deep/ .v-list--three-line .v-list-item {
  height  : 104px !important;
  padding : 6px;
}

/deep/ .v-list--three-line .v-list-item__content .v-list-item__subtitle {
  white-space        : normal;
  -webkit-line-clamp : 2;
  -webkit-box-orient : vertical;
  display            : -webkit-box;
}

.menu-tag {
  margin        : 0;
  margin-right  : 4px;
  margin-bottom : 6px;
  height        : 18px !important;
  padding       : 2px;
}
</style>
