import BaseModel       from '../../../lib/data/Models/BaseModel'
import DomainValidator from '../../Validations/settings/DomainValidator'
import SocketCommand   from '../../SocketCommand'
import { mergeDeep }   from '@/lib/helper/helper'

export default class DomainModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)
    const Domain = rawData.Domain || ''
    const SubDomain = Domain.split('.').shift()
    const BaseDomain = Domain.split('.').slice(1).join('.').slice(0)

    this.Id = rawData.Id || null
    this.CustomerId = rawData.CustomerId || ''
    this.LeadId = rawData.LeadId || ''
    this.ResellerId = rawData.ResellerId || ''
    this.CountryId = rawData.CountryId || 86
    this.ShopId = rawData.ShopId || ''
    this.Domain = SubDomain || ''
    this.BaseDomain = rawData.BaseDomain || BaseDomain || 'on.getfood.gr'
    this.Subdomain = rawData.Subdomain || ''
    this.Active = rawData.Active ?? true
    this.CName = rawData.CName || ''
    this.CNameActive = rawData.CNameActive ?? false
    this.VoipDomain = rawData.VoipDomain || ''
    this.VoipDomainActive = rawData.VoipDomainActive ?? false
    this.VoipDomainDescription = rawData.VoipDomainDescription || ''
    this.PackageId = rawData.PackageId || null
    this.PackageName = rawData.PackageName || ''
    this.SubscriptionExpiredAt = rawData.SubscriptionExpiredAt || ''
    this.SubscriptionExpiredDaysIn = rawData.SubscriptionExpiredDaysIn || ''
    this.TrialExpiredAt = rawData.TrialExpiredAt || ''
    this.TrialExpiredDaysIn = rawData.TrialExpiredDaysIn || ''
    this.DatabaseName = rawData.DatabaseName || ''
    this.DatabaseAutoGenerate = rawData.DatabaseAutoGenerate ?? false
    this.StoreGroup = rawData.StoreGroup || null
    this.IsDemo = rawData.IsDemo ?? false
    this.IsPrimary = rawData.IsPrimary ?? false
    this.PrimaryChildren = rawData.PrimaryChildren ?? []
    this.HasReceipts = rawData.HasReceipts ?? false
    this.HasInvoices = rawData.HasInvoices ?? false
    this.HasFnb = rawData.HasFnb ?? false
    this.ReceiptsProvider = rawData.ReceiptsProvider || null
    this.ForceSSL = rawData.ForceSSL || !this.Id
    this.ForceAssets = rawData.ForceAssets ?? false
    this.PurgeCache = rawData.PurgeCache ?? false
    this.Reserved = rawData.Reserved ?? false
    this.AllowIFrame = rawData.AllowIFrame ?? false
    this.QrOnly = rawData.QrOnly ?? false
    this.HasForceDeleteOrders = rawData.HasForceDeleteOrders ?? false
    this.HasLandingPage = rawData.HasLandingPage ?? false
    this.RedirectToLandingPage = rawData.RedirectToLandingPage ?? false
    this.LandingPageUrl = rawData.LandingPageUrl || ''
    this.ShowOrderTypes = rawData.ShowOrderTypes ?? true
    this.DefaultOrderType = rawData.DefaultOrderType || 1
    this.DefaultOrderTypePos = rawData.DefaultOrderTypePos || 1
    this.WithDummyData = rawData.WithDummyData ?? false
    this.ImportFromThirdParty = rawData.ImportFromThirdParty ?? false
    this.ImportFromThirdPartyForce = rawData.ImportFromThirdPartyForce ?? false
    this.ImportFromThirdPartyWithOptions = rawData.ImportFromThirdPartyWithOptions ?? true
    this.ImportFromThirdPartyAllLocales = rawData.ImportFromThirdPartyAllLocales ?? false
    this.ImportFromThirdPartyDefaultLocale = rawData.ImportFromThirdPartyDefaultLocale || 'el'
    this.ThirdPartyUrl = rawData.ThirdPartyUrl || ''
    this.CreateLead = rawData.CreateLead ?? false
    this.Firstname = rawData.Firstname || ''
    this.Lastname = rawData.Lastname || ''
    this.Company = rawData.Company || ''
    this.Email = rawData.Email || ''
    this.Telephone = rawData.Telephone || ''
    this.PaymentFee = rawData.PaymentFee || null
    this.SeasonStartedAt = rawData.SeasonStartedAt || ''
    this.SeasonEndedAt = rawData.SeasonEndedAt || ''
    this.DatabaseConnection = this.Id ? rawData.DatabaseConnection || '' : 'gfp-db-mysql-cluster'
    this.ExtendedPeriodAt = rawData.ExtendedPeriodAt || ''
    this.Locked = rawData.Locked ?? false
    this.SmsCredits = rawData.SmsCredits ?? 0

    const defaultSmsProvider = {
      routee: {
        from       : 'GETFOODPRO',
        active     : false,
        endpoint   : 'https://connect.routee.net/',
        credentials: {
          username: '605b72417841570001b3f6af',
          password: 'JYL0Kxe8PQ'
        }
      }
    }
    this.SmsProvider = mergeDeep(defaultSmsProvider, rawData.SmsProvider || {})

    this.ReceiptsProviderConfig = rawData.ReceiptsProviderConfig || {
      env    : 'staging',
      live   : [],
      staging: []
    }

    const webHooksDefault = {
      order: {
        hooks : [],
        active: false
      }
    }
    this.Webhooks = mergeDeep(webHooksDefault, rawData.Webhooks || {})

    const platformsDefault = {
      wolt: {
        active    : false,
        venueId   : '',
        password  : '',
        username  : '',
        packageApi: '',
        production: false
      },
      efood: {
        active: false,
        shops : []
      },
      foody: {
        active: false,
        shops : []
      },
      fagi: {
        active: false,
        shops : []
      },
      getfood: {
        active: false,
        shops : []
      }
    }

    this.Platforms = mergeDeep(platformsDefault, rawData.Platforms || {})

    this.Logging = rawData.Logging || {
      ENABLED: false,
      BUGSNAG: {
        DESKTOP: false,
        INIT   : false,
        KEY    : '',
        WEB    : false
      },
      LOGROCKET: {
        DESKTOP: false,
        INIT   : false,
        KEY    : '',
        WEB    : false
      }
    }

    this.Marketing = rawData.Marketing || {
      OneSignal: {
        Active       : false,
        AppId        : '',
        RestApiKey   : '',
        ClientTimeout: '',
        Push         : {
          Active: false,
          Web   : { Active: false },
          Mobile: { Active: false }
        },
        Email: { Active: false },
        Sms  : { Active: false }
      }
    }

    this.Mobile = rawData.Mobile || {
      Active: false,
      Ios   : {
        Active  : false,
        LogoUrl : 'icons/app-store-icon.png',
        StoreUrl: ''
      },
      Android: {
        Active  : false,
        LogoUrl : 'icons/play-store-icon.png',
        StoreUrl: ''
      }
    }

    this.Application = {
      id                    : rawData.Application ? rawData.Application.id || '' : '',
      key                   : rawData.Application ? rawData.Application.key || '' : '',
      name                  : rawData.Application ? rawData.Application.name || '' : '',
      secret                : rawData.Application ? rawData.Application.secret || '' : '',
      enable_statistics     : rawData.Application ? rawData.Application.enable_statistics ?? false : false,
      enable_client_messages: rawData.Application ? rawData.Application.enable_client_messages ?? false : false
    }

    this.validator = new DomainValidator(this)
  }

  boot () {
    super.boot()

    this.guarded = []
    this.saveCommand = SocketCommand.Settings.Domains.Save
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */
}
