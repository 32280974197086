<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="450"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar color="primary">
            <v-icon dark>
              mdi-calendar-clock
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="pa-3"
        >
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="timeVal"
                    v-mask="'##:##'"
                    :hint="$t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Field.Time.Hint')"
                    :label="$t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Field.Time.Label')"
                    :prepend-icon="$t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Field.Time.Icon')"
                    :placeholder="$t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Field.Time.Placeholder')"
                    :rules="[
                      (v) => !!v || $t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Field.Time.Error.Required'),
                      (v) => /^(2[0-3]|[01][0-9]):([0-5][0-9])$/.test(v) || $t('Settings.Domain.Edit.Field.Platform.Update.Dialog.Field.Time.Error.TimeString')
                    ]"
                    clearable
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'PlatformUpdateDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    data: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      timeVal        : '',
      dialogFormValid: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.timeVal = this.data?.value || ''
        })
      }
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {
    onSaveButtonClick () {
      this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
        this.$emit('save:time', this.timeVal, this.data.dayArr, this.data.timeIdx)
        this.onCancelButtonClick()
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
      this.timeVal = ''
    }
  }
}
</script>
