<template>
  <v-container
    class="fill-height pa-2"
    fluid
  >
    <v-row
      v-if="tickets.length > 0"
      dense
    >
      <v-col
        lg="3"
        md="4"
        sm="12"
      >
        <v-card>
          <v-toolbar
            flat
            height="80"
          >
            <v-avatar :color="$t('Support.Toolbar.Color')">
              <v-icon color="indigo lighten-5">
                {{ $t('Support.Toolbar.Icon') }}
              </v-icon>
            </v-avatar>

            <v-toolbar-title
              class="pl-3"
            >
              <div class="body-3">
                {{ $t('Support.Title') }}
              </div>

              <div class="text-caption">
                {{ $t('Support.Toolbar.Subtitle') }}
              </div>
            </v-toolbar-title>

            <v-spacer />

            <v-menu offset-y>
              <template #activator="{on}">
                <v-btn
                  class="mx-0"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon dark>
                    {{ $t('Support.Toolbar.Button.Filter.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list
                dense
                style="max-height: 480px; overflow-y: auto;"
                subheader
              >
                <v-subheader>{{ $t('Support.Toolbar.Button.Filter.Title') }}</v-subheader>

                <v-divider />

                <v-list-item
                  :class="[{'primary--text': filterByProperty===''}]"
                  :inactive="filterByProperty===''"
                  @click="filterByProperty=''"
                >
                  <v-list-item-title>{{ $t('Common.Misc.All') }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-subheader>{{ $t('Support.GroupBy.Status') }}</v-subheader>

                <v-divider />

                <template v-for="group in statuses.all()">
                  <v-list-item
                    :key="`status-${group.ticketstatusid}`"
                    :class="[{'primary--text': filterByProperty === `status-${group.ticketstatusid}`}]"
                    :inactive="filterByProperty === `status-${group.ticketstatusid}`"
                    @click="filterByProperty = `status-${group.ticketstatusid}`"
                  >
                    <v-list-item-title>{{ group.name }}</v-list-item-title>
                  </v-list-item>
                </template>

                <v-divider />

                <v-subheader>{{ $t('Support.GroupBy.Department') }}</v-subheader>

                <v-divider />

                <template v-for="group in departments.all()">
                  <v-list-item
                    :key="`department-${group.departmentid}`"
                    :class="[{'primary--text': filterByProperty === `department-${group.departmentid}`}]"
                    :inactive="filterByProperty === `department-${group.departmentid}`"
                    @click="filterByProperty = `department-${group.departmentid}`"
                  >
                    <v-list-item-title>{{ group.name }}</v-list-item-title>
                  </v-list-item>
                </template>

                <v-divider />

                <v-subheader>{{ $t('Support.GroupBy.Priority') }}</v-subheader>

                <v-divider />

                <template v-for="group in priorities.all()">
                  <v-list-item
                    :key="`priority-${group.priorityid}`"
                    :class="[{'primary--text': filterByProperty === `priority-${group.priorityid}`}]"
                    :inactive="filterByProperty === `priority-${group.priorityid}`"
                    @click="filterByProperty = `priority-${group.priorityid}`"
                  >
                    <v-list-item-title>{{ group.name }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template #activator="{on}">
                <v-btn
                  class="mx-0"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon dark>
                    {{ $t('Support.Toolbar.Button.Group.Icon') }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list
                dense
                subheader
              >
                <v-subheader>{{ $t('Support.Toolbar.Button.Group.Title') }}</v-subheader>

                <v-divider />

                <template v-for="(group, index) in groupByGroups">
                  <v-list-item
                    :key="index"
                    :class="[{'primary--text': groupByProperty.toString()===group.property.toString()}]"
                    :inactive="groupByProperty.toString()===group.property.toString()"
                    @click="groupByProperty=group.property"
                  >
                    <v-list-item-title>{{ group.title }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>

            <v-btn
              class="mx-0"
              icon
              small
              @click="showTicketAddDialog = true"
            >
              <v-icon dark>
                {{ $t('Support.Toolbar.Button.New.Icon') }}
              </v-icon>
            </v-btn>

            <v-btn
              :href="`https://help.getfoodpro.com/${$i18n.locale}`"
              icon
              small
              target="_blank"
            >
              <v-icon>
                support
              </v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-text
            ref="messageList"
            class="pa-0"
            style="overflow-y: auto;"
          >
            <v-list
              style="overflow-y: auto;"
              two-line
            >
              <template v-for="(group, key) in supportTicketsFilteredAndGrouped">
                <v-subheader
                  :key="'header' + key"
                  style="height: 32px;"
                >
                  <timeago
                    v-if="groupByProperty.toString() === groupByGroups.date.property.toString()"
                    :auto-update="1"
                    :locale="$i18n.locale"
                    :max-time="86400 * 365"
                    :since="getTicketTimeSince(key)"
                    min-val-calc="days"
                  />
                  <span v-else-if="groupByProperty.toString() === groupByGroups.read.property.toString()">
                    {{ (key === 'true') ? $t('Support.GroupBy.Read') : $t('Support.GroupBy.Unread') }}
                  </span>

                  <span v-else>
                    {{ key }}
                  </span>
                </v-subheader>

                <template v-for="(ticket, index) in group">
                  <v-list-item
                    :key="'list-tile-' + ticket.ticketid + '-' + key + '-' + index"
                    style="height: 86px;"
                    ripple
                    dense
                    @click="showMessage(ticket)"
                  >
                    <v-list-item-content>
                      <v-list-item-title :class="[{'font-weight-bold': !ticket.clientread}]">
                        {{ ticket.subject }}
                      </v-list-item-title>

                      <v-list-item-subtitle :class="[{'font-weight-bold': !ticket.clientread}]">
                        {{ ticket.service.name }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle class="text-caption grey--text text--lighten-1">
                        <v-chip
                          :color="ticket.status.color"
                          dark
                          label
                          outlined
                          small
                          class="mr-1"
                        >
                          {{ ticket.status.name }}
                        </v-chip>

                        <v-chip
                          :color="getPriorityColor(ticket.priority.priorityid)"
                          dark
                          label
                          outlined
                          small
                          class="mr-1"
                        >
                          {{ ticket.priority.name }}
                        </v-chip>

                        <v-chip
                          color="grey"
                          dark
                          label
                          outlined
                          small
                          class="mr-1"
                        >
                          {{ ticket.department.name }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        {{ ticket.date }}
                      </v-list-item-action-text>

                      <v-list-item-action-text>
                        {{ ticket.time }}
                      </v-list-item-action-text>

                      <v-btn
                        icon
                        @click.stop="showMessage(ticket, true)"
                      >
                        <v-icon>
                          fullscreen
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider :key="'divider-' + ticket.ticketid + '-' + key + '-' + index" />
                </template>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        lg="9"
        md="8"
      >
        <v-card v-if="selectedSupportTicket">
          <v-toolbar
            flat
            height="80"
          >
            <v-avatar
              :color="selectedSupportTicket.status.color ? selectedSupportTicket.status.color : 'indigo'"
              dark
            >
              <h2 class="white--text">
                {{ getTicketAvatarStatus(selectedSupportTicket) }}
              </h2>
            </v-avatar>

            <v-toolbar-title
              class="pl-3"
            >
              <div class="body-3">
                {{ selectedSupportTicket.subject }}
              </div>

              <div class="text-caption">
                {{ selectedSupportTicket.service.name }}
              </div>
            </v-toolbar-title>

            <v-spacer />

            <v-toolbar-title
              class="px-4"
            >
              <div class="text-caption text-right">
                {{ selectedSupportTicket.date }} {{ selectedSupportTicket.time }}
              </div>

              <div>
                <v-chip
                  dark
                  label
                  outlined
                  small
                  class="ml-1"
                >
                  {{ selectedSupportTicket.department.name }}
                </v-chip>

                <v-chip
                  :color="selectedSupportTicket.status.color"
                  dark
                  label
                  outlined
                  small
                  class="ml-1"
                >
                  {{ selectedSupportTicket.status.name }}
                </v-chip>

                <v-chip
                  :color="getPriorityColor(selectedSupportTicket.priority.priorityid)"
                  dark
                  label
                  outlined
                  small
                  class="ml-1"
                >
                  {{ selectedSupportTicket.priority.name }}
                </v-chip>
              </div>
            </v-toolbar-title>

            <v-btn
              class="mx-0"
              icon
              @click="toggleFullScreen"
            >
              <v-icon>fullscreen</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-text
            ref="messageBody"
            v-dynamic-router-link
            class="ticket-body"
            style="overflow: auto;"
          >
            <v-timeline
              v-if="selectedSupportTicket.replies"
              align-top
              dense
            >
              <v-timeline-item
                class="white--text mb-12"
                color="grey"
                fill-dot
                large
              >
                <template #icon>
                  <span>YOU</span>
                </template>

                <v-text-field
                  v-model="replyMessage"
                  hide-details
                  label="Reply to this ticket..."
                  solo
                  @keydown.enter="onReplyMessage"
                >
                  <template #append>
                    <v-btn
                      class="mx-0"
                      depressed
                      @click="onReplyMessage"
                    >
                      REPLY
                    </v-btn>
                  </template>
                </v-text-field>
              </v-timeline-item>

              <v-slide-x-transition group>
                <v-timeline-item
                  v-for="reply in selectedSupportTicket.replies"
                  :key="reply.id"
                  :color="parseInt(reply.admin) > 0 ? 'info' : 'green'"
                  class="white--text"
                  large
                >
                  <template #icon>
                    <span>{{ parseInt(reply.admin) > 0 ? 'GFP' : 'YOU' }}</span>
                  </template>

                  <v-card class="elevation-2">
                    <v-card-title class="text-h6">
                      {{ parseInt(reply.admin) > 0 ? 'GETFOOD Pro' : reply.submitter }}
                      <v-spacer />
                      <span class="text-caption font-weight-bold">
                        {{ reply.date }} {{ reply.time }}
                      </span>
                    </v-card-title>
                    <v-card-text v-html="reply.message" />
                  </v-card>
                </v-timeline-item>
              </v-slide-x-transition>

              <v-timeline-item
                class="white--text"
                color="green"
                large
              >
                <template #icon>
                  <span>YOU</span>
                </template>

                <v-card class="elevation-2">
                  <v-card-title class="text-h6">
                    {{ selectedSupportTicket.name }}
                    <v-spacer />
                    <span class="text-caption font-weight-bold">
                      {{ selectedSupportTicket.date }} {{ selectedSupportTicket.time }}
                    </span>
                  </v-card-title>
                  <v-card-text v-html="selectedSupportTicket.message" />
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>

          <v-divider />

          <v-card-actions class="pa-4">
            <v-spacer />

            <v-btn
              class="light-blue--text text--darken-1"
              outlined
              @click="onCancelButtonClick"
            >
              {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-row
          v-else
          class="align-center fill-height"
        >
          <v-col
            class="text-center"
            cols="12"
          >
            <div
              class="grey--text pa-6"
            >
              <v-icon
                class="mb-4"
                color="grey lighten-2"
                size="128"
              >
                {{ $t('Support.ListView.Select.Icon') }}
              </v-icon>

              <div class="text-h6 mb-2">
                {{ $t('Support.ListView.Select.Title') }}
              </div>

              <div class="text-subtitle-1">
                {{ $t('Support.ListView.Select.Subtitle') }}
              </div>

              <div class="mt-4">
                <v-btn
                  color="primary"
                  dark
                  style="min-width: 280px;"
                  @click="showTicketAddDialog = true"
                >
                  <v-icon left>
                    {{ $t('Support.Toolbar.Button.New.Icon') }}
                  </v-icon>
                  {{ $t('Support.Toolbar.Button.New.Title').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </div>

              <div class="mt-2">
                <v-btn
                  :href="`https://help.getfoodpro.com/${$i18n.locale}`"
                  color="green"
                  dark
                  style="min-width: 280px;"
                  target="_blank"
                >
                  <v-icon left>
                    support
                  </v-icon>
                  {{ $t('Dashboard.Help.Faq').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      v-else
      align="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <div
          class="grey--text pa-6"
        >
          <v-icon
            class="mb-4"
            color="grey lighten-2"
            size="128"
          >
            {{ $t('Support.ListView.NoData.Icon') }}
          </v-icon>
          <div class="text-h6 mb-2">
            {{ $t('Support.Title') }}
          </div>
          <div class="text-subtitle-1">
            {{ $t('Support.ListView.NoData.Subtitle') }}
          </div>

          <div class="mt-4">
            <v-btn
              color="primary"
              dark
              style="min-width: 280px;"
              @click="showTicketAddDialog = true"
            >
              <v-icon left>
                {{ $t('Support.Toolbar.Button.New.Icon') }}
              </v-icon>
              {{ $t('Support.Toolbar.Button.New.Title').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </div>

          <div class="mt-2">
            <v-btn
              :href="`https://help.getfoodpro.com/${$i18n.locale}`"
              color="green"
              dark
              style="min-width: 280px;"
              target="_blank"
            >
              <v-icon left>
                support
              </v-icon>
              {{ $t('Dashboard.Help.Faq').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <ticket-add-dialog
      :visible.sync="showTicketAddDialog"
    />

    <ticket-details-dialog
      :ticket="selectedSupportTicket"
      :visible.sync="showTicketDetailsDialog"
    />
  </v-container>
</template>

<script>
import moment              from 'moment'
import Resizable           from '@/mixins/resizable'
import AppData             from '@/mixins/appdata'
import Auth                from '@/mixins/auth'
import TicketDetailsDialog from '@/components/support-center/TicketDetailsDialog'
import TicketAddDialog     from '@/components/support-center/TicketAddDialog'

export default {
  name      : 'SupportCenter',
  components: {
    TicketAddDialog,
    TicketDetailsDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable],
  props     : [],
  DataStore : {
    tickets    : 'support.tickets',
    departments: 'support.departments',
    services   : 'support.services',
    priorities : 'support.priorities',
    statuses   : 'support.statuses'
  },
  data () {
    return {
      replyMessage           : '',
      showTicketDetailsDialog: false,
      showTicketAddDialog    : false,
      selectedSupportTicket  : null,
      filterByProperty       : '',
      groupByProperty        : 'date',
      groupByGroups          : {
        date: {
          title   : this.$t('Support.GroupBy.Date'),
          property: 'date'
        },
        department: {
          title   : this.$t('Support.GroupBy.Department'),
          property: 'department.name'
        },
        read: {
          title   : this.$t('Support.GroupBy.Read'),
          property: 'clientread'
        },
        status: {
          title   : this.$t('Support.GroupBy.Status'),
          property: 'status.name'
        },
        service: {
          title   : this.$t('Support.GroupBy.Service'),
          property: 'service.name'
        }
      }
    }
  },
  computed: {
    supportTicketsFilteredAndGrouped () {
      return this.tickets.filteredAndGrouped(this.filterByProperty, this.groupByProperty)
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.getTickets()
    this.departments.fetch()
    this.$bus.$on(window.SocketCommand.SupportCenter.PushReply.AfterReplyAdded, this.onAfterReplyAdd)
    this.$bus.$on(window.SocketCommand.SupportCenter.PushReply.AfterUpdated, this.getTickets)
  },
  beforeUpdate () {
  },
  updated () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.SupportCenter.PushReply.AfterReplyAdded, this.onAfterReplyAdd)
    this.$bus.$off(window.SocketCommand.SupportCenter.PushReply.AfterUpdated, this.getTickets)
  },
  destroyed () {
  },
  methods: {
    getTickets () {
      this.tickets.fetch()
    },

    getPriorityColor (priorityId) {
      const colors = ['light-blue ', 'amber', 'red']
      return colors[parseInt(priorityId) - 1] || 'grey darken-2'
    },

    getTicketAvatarStatus (ticket) {
      const nameArr = ticket.status.name.split(' ')
      return nameArr.length === 2 ? nameArr[1].charAt(0).toUpperCase() : nameArr[0].charAt(0).toUpperCase()
    },

    showMessage (ticket, showInDialog = false) {
      if (this.selectedSupportTicket !== ticket) {
        ticket.markAsRead()
        if (parseInt(ticket.replies_count)) {
          ticket.getReplies()
        }
      }

      this.selectedSupportTicket = ticket
      if (this.$vuetify.breakpoint.smAndDown || showInDialog) {
        this.showTicketDetailsDialog = true
      } else {
        this.showTicketDetailsDialog = false
      }
    },

    toggleFullScreen () {
      this.showTicketDetailsDialog = true
    },

    onReplyMessage () {
      if (this.replyMessage.trim() && this.selectedSupportTicket) {
        window.callAS(window.SocketCommand.SupportCenter.Ticket.AddReply, {
          id     : this.selectedSupportTicket.ticketid,
          message: this.replyMessage
        })
      }
      this.replyMessage = ''
    },
    onAfterReplyAdd (data) {
      if (data && this.selectedSupportTicket) {
        this.selectedSupportTicket.getReplies()
      }
    },

    onCancelButtonClick () {
      this.selectedSupportTicket = null
    },

    onResize () {
      const messageList = this.$refs.messageList
      const messageBody = this.$refs.messageBody

      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0

      if (messageList) messageList.style.height = (innerHeight - headerHeight - footerHeight - 81 - 24) + 'px'
      if (messageBody) messageBody.style.height = (innerHeight - headerHeight - footerHeight - 150 - 24) + 'px'
    },

    getTicketTimeSince (dateString) {
      return moment(dateString, 'DD-MM-YYYY').subtract(moment().days())
    }
  }
}
</script>

<style scoped>
.ticket-body {
  -webkit-user-select : text !important; /* Chrome all / Safari all */
  -moz-user-select    : text !important; /* Firefox all */
  -ms-user-select     : text !important; /* IE 10+ */
  user-select         : text !important; /* Likely future */
}
</style>
