<template>
  <v-card>
    <v-toolbar
      ref="toolbar"
      flat
      extension-height="40"
      height="80"
      class="settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Delivery.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Delivery.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $t('Settings.Delivery.Toolbar.Title') }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Delivery.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <template #extension>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          height="40"
          slider-color="blue"
        >
          <v-tab key="settings">
            {{ $t('Settings.Delivery.Tab.Settings').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab key="maps">
            {{ $t('Settings.Delivery.Tab.Maps').toLocaleUpperCase($i18n.locale) }}
          </v-tab>

          <v-tab
            key="delivery-areas"
            :disabled="viewData && !viewData.HasDeliveryAreas"
          >
            {{ $t('Settings.Delivery.Tab.DeliveryArea').toLocaleUpperCase($i18n.locale) }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
    >
      <template v-if="viewData">
        <v-tabs-items v-model="tab">
          <v-tab-item key="settings">
            <v-container
              fluid
              class="pa-0"
            >
              <v-row
                dense
                class="align-center"
              >
                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Delivery.ListView.OutOfRange.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Delivery.ListView.OutOfRange.Subtitle') }}
                  </div>
                  <div class="text-caption orange--text text--darken-2">
                    {{ $t('Settings.Delivery.ListView.OutOfRange.Warning') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="viewData.HasOnlyWarningForAddresses"
                    inset
                    class="ma-0"
                    :disabled="!userCanManage"
                    :label="viewData.HasOnlyWarningForAddresses ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    color="success"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Delivery.ListView.AcceptCustomAddresses.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Delivery.ListView.AcceptCustomAddresses.Subtitle') }}
                  </div>
                  <div class="text-caption orange--text text--darken-2">
                    {{ $t('Settings.Delivery.ListView.AcceptCustomAddresses.Warning') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="viewData.AcceptCustomAddresses"
                    inset
                    class="ma-0"
                    :disabled="!userCanManage"
                    :label="viewData.AcceptCustomAddresses ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    color="success"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Delivery.ListView.AcceptLatLngAddresses.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Delivery.ListView.AcceptLatLngAddresses.Subtitle') }}
                  </div>
                  <div class="text-caption orange--text text--darken-2">
                    {{ $t('Settings.Delivery.ListView.AcceptLatLngAddresses.Warning') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="viewData.AcceptLatLngAddresses"
                    inset
                    class="ma-0"
                    :disabled="!userCanManage"
                    :label="viewData.AcceptLatLngAddresses ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    color="success"
                    hide-details
                    @change="onAcceptLatLngAddressesChange"
                  />
                </v-col>

                <v-col
                  class="pa-0"
                  cols="12"
                />

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Order.Field.PrintOption.ShowMap.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Order.Field.PrintOption.ShowMap.Subtitle') }}</span>
                  <div class="text-caption grey--text">
                    {{ $t('Settings.Order.Field.PrintOption.ShowMap.Hint') }}
                    <router-link :to="{name: 'SystemSettingsDocuments'}">
                      {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                    </router-link>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="viewData.PrintLatLngMap"
                    inset
                    class="ma-0"
                    :disabled="!userCanManage"
                    :label="viewData.PrintLatLngMap ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    color="success"
                    hide-details
                  />
                </v-col>

                <v-col
                  class="pa-0"
                  cols="12"
                />

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Order.Field.PrintOption.ShowQRCode.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Order.Field.PrintOption.ShowQRCode.Subtitle') }}</span>
                  <div class="text-caption grey--text">
                    {{ $t('Settings.Order.Field.PrintOption.ShowQRCode.Hint') }}
                    <router-link :to="{name: 'SystemSettingsDocuments'}">
                      {{ $t('Common.Button.Here').toLocaleLowerCase($i18n.locale) }}
                    </router-link>
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="viewData.PrintLatLngQR"
                    inset
                    class="ma-0"
                    :disabled="!userCanManage"
                    :label="viewData.PrintLatLngQR ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    color="success"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col>
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Delivery.ListView.DeliveryLimit.Title') }}
                  </h5>
                  <div class="text-caption">
                    {{ $t('Settings.Delivery.ListView.DeliveryLimit.Subtitle') }}
                  </div>
                </v-col>
                <v-col class="shrink">
                  <v-switch
                    v-model="viewData.HasDeliveryAreas"
                    inset
                    :disabled="!userCanManage"
                    :label="viewData.HasDeliveryAreas ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                    color="success"
                    class="ma-0"
                    hide-details
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-2" />
                </v-col>

                <v-col cols="12">
                  <h5 class="text-body-2 font-weight-bold">
                    {{ $t('Settings.Delivery.ListView.Country.Title') }}
                  </h5>
                  <span class="text-caption">{{ $t('Settings.Delivery.ListView.Country.Subtitle') }}</span>
                  <v-autocomplete
                    v-if="viewData"
                    v-model="viewData.DeliveryCountries"
                    :disabled="!userCanManage"
                    :items="viewData.Countries"
                    :placeholder="$t('Settings.Delivery.ListView.Country.PlaceHolder')"
                    :rules="[(v) => v.length <= 5 || $t('Settings.Delivery.ListView.Country.Error.Max')]"
                    clearable
                    item-text="name"
                    item-value="code"
                    multiple
                    persistent-hint
                    required
                    single-line
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="maps">
            <v-container
              fluid
              class="pa-0"
            >
              <v-row
                dense
                class="align-center"
              >
                <v-slide-y-transition>
                  <v-col
                    v-show="showDomainInfo"
                    cols="12"
                  >
                    <v-alert
                      type="info"
                      color="red"
                      class="text-caption mb-1"
                      dense
                      dark
                    >
                      {{ $t('Settings.Delivery.ListView.Maps.Info.Google') }}

                      <template v-for="(domain, index) in viewData.MapDomains.Google">
                        <v-btn
                          :key="`domain-google-${index}`"
                          class="ma-0 pl-1 pr-2 text-lowercase"
                          small
                          text
                          @click="copyToClipboard(domain)"
                        >
                          <v-icon
                            small
                            left
                          >
                            far fa-copy
                          </v-icon>

                          {{ domain }}
                        </v-btn>
                      </template>
                    </v-alert>

                    <v-alert
                      type="info"
                      color="info"
                      class="text-caption mb-1"
                      dense
                      dark
                    >
                      {{ $t('Settings.Delivery.ListView.Maps.Info.MapBox') }}

                      <template v-for="(domain, index) in viewData.MapDomains.Mapbox">
                        <v-btn
                          :key="`domain-google-${index}`"
                          class="ma-0 pl-1 pr-2 text-lowercase"
                          small
                          text
                          @click="copyToClipboard(domain)"
                        >
                          <v-icon
                            small
                            left
                          >
                            far fa-copy
                          </v-icon>

                          {{ domain }}
                        </v-btn>
                      </template>
                    </v-alert>
                  </v-col>
                </v-slide-y-transition>

                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Delivery.ListView.Maps.Pos.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Delivery.ListView.Maps.Pos.Title') }}
                      </h5>

                      <v-spacer />

                      <v-btn
                        icon
                        color="info"
                        @click="showDomainInfo = !showDomainInfo"
                      >
                        <v-icon large>
                          mdi-help-circle
                        </v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-divider />

                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <template v-if="userIsSuperAdmin">
                          <v-row
                            dense
                            align="center"
                          >
                            <v-col>
                              <h5 class="text-body-2 font-weight-bold">
                                {{ $t('Settings.Delivery.ListView.Maps.Provider.DefaultMap.Title') }}
                              </h5>
                              <span class="text-caption">
                                {{ $t('Settings.Delivery.ListView.Maps.Pos.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.Map.Subtitle') }}
                              </span>
                            </v-col>
                            <v-col class="shrink shrink-col-min-width">
                              <v-select
                                v-model="viewData.Maps.Pos.DefaultMapsProvider.Name"
                                :label="$t('Settings.Delivery.ListView.Maps.Provider.Map.Title')"
                                :disabled="!userCanManage"
                                :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                                item-text="Name"
                                item-value="Key"
                                class="ma-0 pt-0"
                                single-line
                                filled
                                clearable
                                hide-details
                                required
                              />

                              <v-text-field
                                v-model="viewData.Maps.Pos.DefaultMapsProvider.Key"
                                :disabled="!userCanManage"
                                :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                                class="elevation-0 mt-4"
                                hide-details
                                required
                                filled
                                clearable
                                style="min-width: 260px; width: 100%; max-width: 400px;"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-divider class="" />
                            </v-col>
                          </v-row>
                        </template>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Delivery.ListView.Maps.Provider.Map.Title') }}
                            </h5>
                            <span class="text-caption">
                              {{ $t('Settings.Delivery.ListView.Maps.Pos.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.Map.Subtitle') }}
                            </span>
                          </v-col>
                          <v-col class="shrink shrink-col-min-width">
                            <v-select
                              v-model="viewData.Maps.Pos.InteractiveMapsProvider.Name"
                              :label="$t('Settings.Delivery.ListView.Maps.Provider.Map.Title')"
                              :disabled="!userCanManage"
                              :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                              item-text="Name"
                              item-value="Key"
                              class="ma-0 pt-0"
                              single-line
                              filled
                              clearable
                              hide-details
                              required
                            />

                            <v-text-field
                              v-model="viewData.Maps.Pos.InteractiveMapsProvider.Key"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                              class="elevation-0 mt-4"
                              hide-details
                              required
                              filled
                              clearable
                              style="min-width: 260px; width: 100%; max-width: 400px;"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Delivery.ListView.Maps.Provider.MapStatic.Title') }}
                            </h5>
                            <span class="text-caption">
                              {{ $t('Settings.Delivery.ListView.Maps.Pos.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.MapStatic.Subtitle') }}
                            </span>
                          </v-col>
                          <v-col class="shrink shrink-col-min-width">
                            <v-select
                              v-model="viewData.Maps.Pos.StaticMapsProvider.Name"
                              :label="$t('Settings.Delivery.ListView.Maps.Provider.MapStatic.Title')"
                              :disabled="!userCanManage"
                              :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                              item-text="Name"
                              item-value="Key"
                              class="ma-0 pt-0"
                              single-line
                              filled
                              clearable
                              hide-details
                              required
                            />

                            <v-text-field
                              v-model="viewData.Maps.Pos.StaticMapsProvider.Key"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                              class="elevation-0 mt-4"
                              hide-details
                              required
                              filled
                              clearable
                              style="min-width: 260px; width: 100%; max-width: 400px;"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Delivery.ListView.Maps.Provider.MapPrint.Title') }}
                            </h5>
                            <span class="text-caption">
                              {{ $t('Settings.Delivery.ListView.Maps.Pos.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.MapPrint.Subtitle') }}
                            </span>
                          </v-col>
                          <v-col class="shrink shrink-col-min-width">
                            <v-select
                              v-model="viewData.Maps.Pos.PrintMapsProvider.Name"
                              :label="$t('Settings.Delivery.ListView.Maps.Provider.MapPrint.Title')"
                              :disabled="!userCanManage"
                              :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                              item-text="Name"
                              item-value="Key"
                              class="ma-0 pt-0"
                              single-line
                              filled
                              clearable
                              hide-details
                              required
                            />

                            <v-text-field
                              v-model="viewData.Maps.Pos.PrintMapsProvider.Key"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                              class="elevation-0 mt-4"
                              hide-details
                              required
                              filled
                              clearable
                              style="min-width: 260px; width: 100%; max-width: 400px;"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Delivery.ListView.Maps.Provider.Geocode.Title') }}
                            </h5>
                            <span class="text-caption">
                              {{ $t('Settings.Delivery.ListView.Maps.Pos.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.Geocode.Subtitle') }}
                            </span>
                          </v-col>
                          <v-col class="shrink shrink-col-min-width">
                            <v-select
                              v-model="viewData.Maps.Pos.GeocodeProvider.Name"
                              :label="$t('Settings.Delivery.ListView.Maps.Provider.Geocode.Title')"
                              :disabled="!userCanManage"
                              :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                              item-text="Name"
                              item-value="Key"
                              class="ma-0 pt-0"
                              single-line
                              filled
                              clearable
                              hide-details
                              required
                            />

                            <v-text-field
                              v-model="viewData.Maps.Pos.GeocodeProvider.Key"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                              class="elevation-0 mt-4"
                              hide-details
                              required
                              filled
                              clearable
                              style="min-width: 260px; width: 100%; max-width: 400px;"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card flat>
                    <v-card-title :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-4']">
                      <v-icon class="pr-3">
                        {{ $t('Settings.Delivery.ListView.Maps.Web.Icon') }}
                      </v-icon>
                      <h5 class="text-body-2 font-weight-bold">
                        {{ $t('Settings.Delivery.ListView.Maps.Web.Title') }}
                      </h5>
                    </v-card-title>

                    <v-divider />

                    <v-card-text>
                      <v-container
                        fluid
                        class="pa-0"
                      >
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Delivery.ListView.Maps.Provider.Map.Title') }}
                            </h5>
                            <span class="text-caption">
                              {{ $t('Settings.Delivery.ListView.Maps.Web.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.Map.Subtitle') }}
                            </span>
                          </v-col>
                          <v-col class="shrink shrink-col-min-width">
                            <v-select
                              v-model="viewData.Maps.Site.InteractiveMapsProvider.Name"
                              :label="$t('Settings.Delivery.ListView.Maps.Provider.Map.Title')"
                              :disabled="!userCanManage"
                              :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                              item-text="Name"
                              item-value="Key"
                              class="ma-0 pt-0"
                              single-line
                              filled
                              clearable
                              hide-details
                              required
                            />

                            <v-text-field
                              v-model="viewData.Maps.Site.InteractiveMapsProvider.Key"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                              class="elevation-0 mt-4"
                              hide-details
                              required
                              filled
                              clearable
                              style="min-width: 260px; width: 100%; max-width: 400px;"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Delivery.ListView.Maps.Provider.MapStatic.Title') }}
                            </h5>
                            <span class="text-caption">
                              {{ $t('Settings.Delivery.ListView.Maps.Web.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.MapStatic.Subtitle') }}
                            </span>
                          </v-col>
                          <v-col class="shrink shrink-col-min-width">
                            <v-select
                              v-model="viewData.Maps.Site.StaticMapsProvider.Name"
                              :label="$t('Settings.Delivery.ListView.Maps.Provider.MapStatic.Title')"
                              :disabled="!userCanManage"
                              :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                              item-text="Name"
                              item-value="Key"
                              class="ma-0 pt-0"
                              single-line
                              filled
                              clearable
                              hide-details
                              required
                            />

                            <v-text-field
                              v-model="viewData.Maps.Site.StaticMapsProvider.Key"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                              class="elevation-0 mt-4"
                              hide-details
                              required
                              filled
                              clearable
                              style="min-width: 260px; width: 100%; max-width: 400px;"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          align="center"
                        >
                          <v-col>
                            <h5 class="text-body-2 font-weight-bold">
                              {{ $t('Settings.Delivery.ListView.Maps.Provider.Geocode.Title') }}
                            </h5>
                            <span class="text-caption">
                              {{ $t('Settings.Delivery.ListView.Maps.Web.Title') }} {{ $t('Settings.Delivery.ListView.Maps.Provider.Geocode.Subtitle') }}
                            </span>
                          </v-col>
                          <v-col class="shrink shrink-col-min-width">
                            <v-select
                              v-model="viewData.Maps.Site.GeocodeProvider.Name"
                              :label="$t('Settings.Delivery.ListView.Maps.Provider.Geocode.Title')"
                              :disabled="!userCanManage"
                              :items="$t('Settings.Delivery.ListView.Maps.Providers')"
                              item-text="Name"
                              item-value="Key"
                              class="ma-0 pt-0"
                              single-line
                              filled
                              clearable
                              hide-details
                              required
                            />

                            <v-text-field
                              v-model="viewData.Maps.Site.GeocodeProvider.Key"
                              :disabled="!userCanManage"
                              :label="$t('Settings.Delivery.ListView.Maps.ApiKey.Map')"
                              class="elevation-0 mt-4"
                              hide-details
                              required
                              filled
                              clearable
                              style="min-width: 260px; width: 100%; max-width: 400px;"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item
            key="delivery-areas"
            :disabled="!viewData.HasDeliveryAreas"
          >
            <v-container
              fluid
              class="pa-0"
            >
              <v-row
                dense
                class="align-start"
              >
                <v-col
                  lg="7"
                  cols="12"
                >
                  <gmap-map
                    ref="restaurantMap"
                    :center="markerStore"
                    :options="{styles: GMapStyles}"
                    :zoom="mapZoom"
                    map-type-id="roadmap"
                    style="height: 620px"
                  >
                    <template v-if="viewData && viewData.HasDeliveryAreas && selectedDeliveryArea">
                      <gmap-circle
                        v-if="selectedDeliveryAreaType === 'circle'"
                        ref="circle"
                        :center="restaurantDeliveryAreaCircle.center || markerStore"
                        :editable="userCanManage"
                        :options="{strokeColor: selectedDeliveryAreaColor, strokeOpacity: 1.0, strokeWeight: 3, fillColor: selectedDeliveryAreaColor, fillOpacity: 0.15}"
                        :radius="restaurantDeliveryAreaCircle.radius || 1000"
                        @center_changed="updateEditedCircleCenter($event)"
                        @radius_changed="updateEditedCircleRadius($event)"
                      />

                      <gmap-polygon
                        v-if="selectedDeliveryAreaType === 'shape'"
                        ref="polyline"
                        :draggable="userCanManage && dragPolygon"
                        :editable="userCanManage && !dragPolygon"
                        :options="{strokeColor: selectedDeliveryAreaColor, strokeOpacity: 1.0, strokeWeight: 3, fillColor: selectedDeliveryAreaColor, fillOpacity: 0.15}"
                        :paths="restaurantDeliveryAreaPolygons"
                        @paths_changed="updateEdited($event)"
                        @rightclick="handleClickForDelete"
                      />
                    </template>

                    <template v-if="viewData && viewData.HasDeliveryAreas && !selectedDeliveryArea">
                      <template v-for="(deliveryArea, index) in viewData.DeliveryAreas">
                        <gmap-circle
                          v-if="deliveryArea.type === 'circle'"
                          :key="`map-circle-${index}`"
                          :center="deliveryArea.circle.center || markerStore"
                          :options="{strokeColor: deliveryArea.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: deliveryArea.color, fillOpacity: 0.15}"
                          :radius="deliveryArea.circle.radius || 1000"
                        />

                        <gmap-polygon
                          v-if="deliveryArea.type === 'shape'"
                          :key="`map-poly-${index}`"
                          :options="{strokeColor: deliveryArea.color, strokeOpacity: 1.0, strokeWeight: 3, fillColor: deliveryArea.color, fillOpacity: 0.15}"
                          :paths="deliveryArea.vertices || []"
                        />
                      </template>
                    </template>

                    <gmap-marker
                      :icon="getGMapIcon(LOGO_COLOR.DARK)"
                      :position="markerStore"
                      label=""
                    />
                  </gmap-map>
                </v-col>

                <v-col
                  lg="5"
                  cols="12"
                >
                  <v-btn
                    :disabled="!userCanManage"
                    block
                    class="blue white--text mb-2"
                    depressed
                    @click="onAddDeliveryArea()"
                  >
                    {{ $t('Settings.Delivery.ListView.Map.Button.Add') }}
                  </v-btn>

                  <v-expansion-panels
                    v-model="xpanel"
                    accordion
                  >
                    <template v-for="(deliveryArea, index) in viewData.DeliveryAreas">
                      <v-expansion-panel :key="`delivery_area_${index}`">
                        <v-expansion-panel-header class="px-4 py-0">
                          <v-card-title class="pl-0">
                            <v-icon
                              :color="deliveryArea.color || getAreaColor(index)"
                              class="pr-4"
                            >
                              {{ deliveryArea.type === 'shape' ? 'fas fa-vector-square' : 'far fa-circle' }}
                            </v-icon>

                            <h5>{{ $t('Settings.Delivery.ListView.Map.Region') }} #{{ (index + 1) }}</h5>

                            <h5
                              v-if="deliveryArea.title"
                              class="grey--text text--darken-1"
                            >
                              &nbsp; - {{ deliveryArea.title }}
                            </h5>

                            <v-spacer />

                            <v-btn
                              v-if="userCanManage && viewData.DeliveryAreas.length > 1"
                              color="red"
                              dark
                              text
                              icon
                              small
                              @click.stop="onRemoveDeliveryArea(index)"
                            >
                              <v-icon>delete_forever</v-icon>
                            </v-btn>
                          </v-card-title>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <v-card flat>
                            <v-card-text class="pa-3">
                              <v-row align="center">
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="deliveryArea.title"
                                    :disabled="!userCanManage"
                                    :label="$t('Settings.Delivery.ListView.Map.Field.Title.Label')"
                                    clearable
                                    hide-details
                                    required
                                  />
                                </v-col>

                                <v-col cols="12">
                                  <v-btn-toggle
                                    v-model="deliveryArea.type"
                                    :disabled="!userCanManage"
                                    class="elevation-0"
                                    dark
                                    dense
                                    mandatory
                                    style="width: 100%;"
                                  >
                                    <v-btn
                                      class="elevation-0 flex-grow-1"
                                      color="red accent-4"
                                      value="shape"
                                      :style="deliveryArea.type === 'shape' ? 'opacity: 1' : 'opacity: 0.35'"
                                    >
                                      <v-icon
                                        class="mr-2"
                                        color="white"
                                        size="22"
                                      >
                                        fas fa-vector-square
                                      </v-icon>
                                      <span>{{ $t('Settings.Delivery.ListView.Map.Button.Polygon').toLocaleUpperCase($i18n.locale) }}</span>
                                    </v-btn>

                                    <v-btn
                                      class="elevation-0 flex-grow-1"
                                      color="blue darken-3"
                                      value="circle"
                                      :style="deliveryArea.type === 'circle' ? 'opacity: 1' : 'opacity: 0.35'"
                                    >
                                      <v-icon
                                        class="mr-2"
                                        color="white"
                                        size="22"
                                      >
                                        far fa-circle
                                      </v-icon>
                                      <span>{{ $t('Settings.Delivery.ListView.Map.Button.Circle').toLocaleUpperCase($i18n.locale) }}</span>
                                    </v-btn>
                                  </v-btn-toggle>
                                </v-col>

                                <v-col cols="12">
                                  <v-switch
                                    v-if="deliveryArea.type==='shape'"
                                    v-model="dragPolygon"
                                    inset
                                    :disabled="!userCanManage"
                                    :label="$t('Settings.Delivery.ListView.Map.Field.Move.Label').toLocaleUpperCase($i18n.locale)"
                                    class="ma-0 ml-4 d-inline-flex"
                                    color="red"
                                    hide-details
                                  />
                                </v-col>

                                <v-col cols="12">
                                  <h5 class="text-body-2 font-weight-bold">
                                    {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Title') }}
                                  </h5>
                                  <span class="text-caption">{{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Subtitle') }}</span>
                                </v-col>
                                <v-col cols="12">
                                  <div class="divTable">
                                    <div class="divTableHeading">
                                      <div class="divTableRow text-center">
                                        <div class="divTableHead">
                                          {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Charge') }}
                                        </div>
                                        <div class="divTableHead">
                                          {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Condition') }}
                                        </div>
                                        <div class="divTableHead">
                                          {{ $t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Total') }}
                                        </div>
                                        <div class="divTableHead">
                                          <v-menu
                                            v-if="userCanManage"
                                            :disabled="!userCanManage"
                                            auto
                                            offset-y
                                          >
                                            <template #activator="{on}">
                                              <v-btn
                                                :disabled="!userCanManage"
                                                class="blue my-0"
                                                dark
                                                icon
                                                small
                                                v-on="on"
                                              >
                                                <v-icon>add</v-icon>
                                              </v-btn>
                                            </template>

                                            <v-list>
                                              <v-list-item
                                                v-for="item in chargeConditionsList"
                                                :key="item.Id"
                                                :disabled="deliveryArea.charge && !!deliveryArea.charge.find(charge => charge.condition === item.Id) && item.Id === 'all'"
                                                @click="onAddChargeCondition(deliveryArea, item)"
                                              >
                                                <v-list-item-title
                                                  v-html="item.Name"
                                                />
                                              </v-list-item>
                                            </v-list>
                                          </v-menu>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="divTableBody">
                                      <div
                                        v-for="(charge, idx) in deliveryArea.charge || []"
                                        :key="`delivery_area_${index}_charge_${idx}`"
                                        class="divTableRow"
                                      >
                                        <div
                                          class="divTableCell"
                                          style="min-width: 120px;"
                                        >
                                          <v-text-field
                                            v-model="charge.amount"
                                            :append-icon="appCurrency.Icon"
                                            :disabled="!userCanManage"
                                            :placeholder="$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Charge')"
                                            hide-details
                                            required
                                            single-line
                                            type="number"
                                            min="0"
                                            step="any"
                                          />
                                        </div>
                                        <div
                                          class="divTableCell text-center text-subtitle-1 text-no-wrap"
                                          :class="isDarkMode ? 'white--text' : 'black--text'"
                                        >
                                          {{ chargeConditionsList.find(condition => condition.Id === charge.condition).Name }}
                                        </div>
                                        <div
                                          class="divTableCell"
                                          style="min-width: 120px;"
                                        >
                                          <v-text-field
                                            v-model="charge.total"
                                            :append-icon="appCurrency.Icon"
                                            :disabled="charge.condition === 'all' || !userCanManage"
                                            :placeholder="$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.Total')"
                                            hide-details
                                            required
                                            single-line
                                            type="number"
                                            min="0"
                                            step="any"
                                          />
                                        </div>
                                        <div
                                          class="divTableCell text-right"
                                          style="white-space: nowrap;"
                                        >
                                          <v-btn
                                            v-if="userCanManage"
                                            :disabled="(deliveryArea.charge && deliveryArea.charge.length === 1) || !userCanManage"
                                            class="orange"
                                            dark
                                            icon
                                            small
                                            @click="onRemoveChargeCondition(deliveryArea, idx)"
                                          >
                                            <v-icon>remove</v-icon>
                                          </v-btn>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </template>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        to="/system-settings"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
      <v-btn
        v-if="userCanManage"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveButtonClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppData       from '@/mixins/appdata'
import Auth          from '@/mixins/auth'
import { mergeDeep } from '@/lib/helper/helper'

export default {
  components: {},
  mixins    : [AppData, Auth],
  data () {
    return {
      tab                 : null,
      xpanel              : null,
      viewData            : null,
      showDomainInfo      : false,
      countryList         : [],
      colorsList          : ['#F16745', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#404040', '#F16745', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#404040', '#F16745', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#404040', '#F16745', '#FFC65D'],
      chargeConditionsList: [
        {
          Id  : 'all',
          Name: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.NewCondition.All')
        },
        {
          Id  : 'above',
          Name: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.NewCondition.Above')
        },
        {
          Id  : 'below',
          Name: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Label.NewCondition.Bellow')
        }
      ],
      isSaving   : false,
      dragPolygon: false,
      newPath    : [],
      newCircle  : {
        radius: 0,
        center: {
          lat: 0,
          lng: 0
        }
      },
      mapZoom: 16
    }
  },
  computed: {
    GMapStyles () {
      if (!this.isDarkMode) {
        return [
          {
            featureType: 'poi',
            stylers    : [{ visibility: 'off' }]
          }
        ]
      }

      return [
        {
          featureType: 'poi',
          stylers    : [{ visibility: 'off' }]
        },
        {
          elementType: 'geometry',
          stylers    : [{ color: '#242f3e' }]
        },
        {
          elementType: 'labels.text.stroke',
          stylers    : [{ color: '#242f3e' }]
        },
        {
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#746855' }]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#d59563' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers    : [{ color: '#38414e' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers    : [{ color: '#212a37' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#9ca5b3' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers    : [{ color: '#746855' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers    : [{ color: '#1f2835' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#f3d19c' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers    : [{ color: '#2f3948' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#d59563' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers    : [{ color: '#17263c' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers    : [{ color: '#515c6d' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers    : [{ color: '#17263c' }]
        }
      ]
    },

    defaultArea () {
      return {
        type  : 'circle',
        areaId: this.viewData ? this.viewData.DeliveryAreas.length + 1 : 1,
        charge: [
          {
            total    : 0,
            amount   : 0,
            condition: 'all'
          }
        ],
        circle: {
          center: {
            lat: this.markerStore.lat,
            lng: this.markerStore.lng
          },
          radius: 1200
        },
        area_id : this.viewData ? this.viewData.DeliveryAreas.length + 1 : 1,
        color   : this.getAreaColor(this.viewData ? this.viewData.DeliveryAreas.length : 0),
        vertices: this.newPath || []
      }
    },

    selectedDeliveryArea () {
      return this.viewData && this.xpanel > -1 ? this.viewData.DeliveryAreas[this.xpanel] : {}
    },

    selectedDeliveryAreaColor () {
      return this.selectedDeliveryArea.color || this.getAreaColor(parseInt(this.selectedDeliveryArea.areaId) - 1 || 0)
    },

    selectedDeliveryAreaType: {
      get () {
        return this.selectedDeliveryArea && this.selectedDeliveryArea.type
      },
      set (val) {
        this.selectedDeliveryArea.type = val
      }
    },
    markerStore () {
      return {
        lat: this.viewData ? this.viewData.Lat : 0,
        lng: this.viewData ? this.viewData.Lng : 0
      }
    },

    restaurantDeliveryAreaCircle () {
      return this.selectedDeliveryArea ? this.selectedDeliveryArea.circle : {}
    },

    restaurantDeliveryAreaPolygons () {
      return this.selectedDeliveryArea ? [this.selectedDeliveryArea.vertices] : []
    }
  },
  watch: {
    tab (newVal) {
      if (newVal === 1) {
        setTimeout(() => {
          this.centerZoomMap()
        }, 150)
      }
    },
    selectedDeliveryArea () {
      this.centerZoomMap()
    },
    selectedDeliveryAreaType () {
      this.centerZoomMap()
    },
    'viewData.AcceptCustomAddresses': function (newVal) {
      if (newVal) {
        this.viewData.AcceptLatLngAddresses = false
        this.viewData.PrintLatLngMap = false
        this.viewData.PrintLatLngQR = false
      }
    },
    'viewData.AcceptLatLngAddresses': function (newVal) {
      if (newVal) this.viewData.AcceptCustomAddresses = false
    },

    'viewData.PrintLatLngMap': function () {
      if (!this.viewData.PrintLatLngQR && !this.viewData.PrintLatLngMap) this.viewData.AcceptLatLngAddresses = false
      if (this.viewData.PrintLatLngQR || this.viewData.PrintLatLngMap) this.viewData.AcceptLatLngAddresses = true
    },
    'viewData.PrintLatLngQR': function () {
      if (!this.viewData.PrintLatLngQR && !this.viewData.PrintLatLngMap) this.viewData.AcceptLatLngAddresses = false
      if (this.viewData.PrintLatLngQR || this.viewData.PrintLatLngMap) this.viewData.AcceptLatLngAddresses = true
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Settings.Delivery.Get, this.onGetDeliveryResult)
    this.$bus.$on(window.SocketCommand.Settings.Delivery.Save, this.onSaveDeliveryResult)
    this.getData()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Settings.Delivery.Get, this.onGetDeliveryResult)
    this.$bus.$off(window.SocketCommand.Settings.Delivery.Save, this.onSaveDeliveryResult)
  },
  methods: {
    init () {
      this.$gmapApiPromiseLazy().then(() => {
        this.googleMapState = true
        // eslint-disable-next-line no-undef
        const circleArea = new google.maps.Circle({
          center: this.markerStore,
          radius: 1000
        })
        const ne = circleArea.getBounds().getNorthEast()
        const sw = circleArea.getBounds().getSouthWest()
        const scale = 1
        const windowWidth = ne.lng() - sw.lng()
        const windowHeight = ne.lat() - sw.lat()
        const widthMargin = windowWidth * scale
        const heightMargin = windowHeight * scale
        const top = ne.lat() - heightMargin
        const bottom = sw.lat() + heightMargin
        const left = sw.lng() + widthMargin
        const right = ne.lng() - widthMargin
        this.newPath = [
          {
            lat   : top,
            lng   : right,
            latlng: {
              lat: top,
              lng: right
            }
          },
          {
            lat   : bottom,
            lng   : right,
            latlng: {
              lat: bottom,
              lng: right
            }
          },
          {
            lat   : bottom,
            lng   : left,
            latlng: {
              lat: bottom,
              lng: left
            }
          },
          {
            lat   : top,
            lng   : left,
            latlng: {
              lat: top,
              lng: left
            }
          }
        ]

        this.centerZoomMap()
      })
    },

    onAddDeliveryArea () {
      this.viewData.DeliveryAreas.push(this.defaultArea)
    },
    onRemoveDeliveryArea (index) {
      this.viewData.DeliveryAreas.splice(index, 1)
    },

    onAddChargeCondition (deliveryArea, condition) {
      if (!deliveryArea.charge) deliveryArea.charge = []
      deliveryArea.charge.push({
        amount   : 0,
        condition: condition.Id,
        total    : 0
      })
    },
    onRemoveChargeCondition (deliveryArea, index) {
      if (!deliveryArea.charge) deliveryArea.charge = []
      deliveryArea.charge.splice(index, 1)
    },

    updateEditedCircleCenter (center) {
      // console.log('Center::', center)
      if (this.selectedDeliveryArea) {
        this.selectedDeliveryArea.circle.center.lat = center.lat()
        this.selectedDeliveryArea.circle.center.lng = center.lng()
      }
    },
    updateEditedCircleRadius (radius) {
      // console.log('Radius::', radius)
      if (this.selectedDeliveryArea) this.selectedDeliveryArea.circle.radius = parseFloat(radius)
    },
    updateEdited (mvcArray) {
      const paths = []
      for (let i = 0; i < mvcArray.getLength(); i++) {
        const path = []
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          const point = mvcArray.getAt(i).getAt(j)
          path.push({
            lat   : point.lat(),
            lng   : point.lng(),
            latlng: {
              lat: point.lat(),
              lng: point.lng()
            }
          })
        }
        paths.push(path)
      }

      if (paths.length > 0 && this.selectedDeliveryArea && this.selectedDeliveryAreaType === 'shape') {
        this.selectedDeliveryArea.vertices = paths[0]
      }
    },
    handleClickForDelete ($event) {
      if ($event.vertex) {
        this.$refs.polyline.$polygonObject.getPaths()
          .getAt($event.path)
          .removeAt($event.vertex)
      }
    },
    getAreaColor (areaIndex) {
      return this.colorsList[areaIndex] ? this.colorsList[areaIndex] : this.colorsList[0]
    },
    centerZoomMap () {
      if (!this.googleMapState) return
      // eslint-disable-next-line no-undef
      const latlngbounds = new google.maps.LatLngBounds()

      if (this.selectedDeliveryArea) {
        if (this.selectedDeliveryAreaType === 'shape') {
          for (let i = 0; i < this.restaurantDeliveryAreaPolygons.length; i++) {
            for (let j = 0; j < this.restaurantDeliveryAreaPolygons[i].length; j++) {
              // eslint-disable-next-line no-undef
              this.restaurantDeliveryAreaPolygons[i][j].latlng = new google.maps.LatLng(this.restaurantDeliveryAreaPolygons[i][j].lat, this.restaurantDeliveryAreaPolygons[i][j].lng)
              latlngbounds.extend(this.restaurantDeliveryAreaPolygons[i][j].latlng)
            }
          }

          if (this.$refs.restaurantMap) {
            if (this.$refs.restaurantMap.$mapCreated) {
              this.$refs.restaurantMap.$mapCreated.then((map) => {
                map.fitBounds(latlngbounds)
                map.panToBounds(latlngbounds)
              })
            } else if (this.$refs.restaurantMap.$mapPromise) {
              this.$refs.restaurantMap.$mapPromise.then((map) => {
                map.fitBounds(latlngbounds)
                map.panToBounds(latlngbounds)
              })
            }
          }
        } else {
          if (this.$refs.restaurantMap) {
            if (this.$refs.restaurantMap.$mapCreated) {
              this.$refs.restaurantMap.$mapCreated.then((map) => {
                if (this.$refs.circle) {
                  this.$refs.circle.$circlePromise.then((circle) => {
                    map.fitBounds(circle.getBounds())
                    map.panToBounds(circle.getBounds())
                  })
                }
              })
            } else if (this.$refs.restaurantMap.$mapPromise) {
              this.$refs.restaurantMap.$mapPromise.then((map) => {
                if (this.$refs.circle) {
                  this.$refs.circle.$circlePromise.then((circle) => {
                    map.fitBounds(circle.getBounds())
                    map.panToBounds(circle.getBounds())
                  })
                }
              })
            }
          }
        }
      } else {
        if (Array.isArray(this.viewData.DeliveryAreas) && this.viewData.DeliveryAreas.length > 0) {
          for (let area = 0; area < this.viewData.DeliveryAreas.length; area++) {
            const deliveryArea = this.viewData.DeliveryAreas[area]
            if (deliveryArea.type === 'shape') {
              for (let i = 0; i < deliveryArea.vertices.length; i++) {
                // eslint-disable-next-line
                let latlng = new google.maps.LatLng(parseFloat(deliveryArea.vertices[i].lat), parseFloat(deliveryArea.vertices[i].lng))
                deliveryArea.vertices[i].latlng = latlng
                latlngbounds.extend(latlng)
              }
            } else {
              // eslint-disable-next-line
              let latlng = new google.maps.LatLng(parseFloat(deliveryArea.circle.center.lat), parseFloat(deliveryArea.circle.center.lng))
              latlngbounds.extend(latlng)
            }

            if (this.$refs.restaurantMap) {
              if (this.$refs.restaurantMap.$mapCreated) {
                this.$refs.restaurantMap.$mapCreated.then((map) => {
                  map.fitBounds(latlngbounds)
                  map.panToBounds(latlngbounds)
                })
              } else if (this.$refs.restaurantMap.$mapPromise) {
                this.$refs.restaurantMap.$mapPromise.then((map) => {
                  map.fitBounds(latlngbounds)
                  map.panToBounds(latlngbounds)
                })
              }
            }
          }
        } else {
          if (this.$refs.restaurantMap) {
            if (this.$refs.restaurantMap.$mapCreated) {
              this.$refs.restaurantMap.$mapCreated.then((map) => {
                // eslint-disable-next-line no-undef
                map.setCenter(new google.maps.LatLng(parseFloat(this.markerStore.lat), parseFloat(this.markerStore.lng)))
                map.setZoom(15)
              })
            } else if (this.$refs.restaurantMap.$mapPromise) {
              this.$refs.restaurantMap.$mapPromise.then((map) => {
                // eslint-disable-next-line no-undef
                map.setCenter(new google.maps.LatLng(parseFloat(this.markerStore.lat), parseFloat(this.markerStore.lng)))
                map.setZoom(15)
              })
            }
          }
        }
      }
    },

    onGetDeliveryResult (data) {
      data.DeliveryAreas.forEach((area, a) => {
        data.DeliveryAreas[a].areaId = parseFloat(area.areaId)
        data.DeliveryAreas[a].circle.center.lat = parseFloat(area.circle.center.lat)
        data.DeliveryAreas[a].circle.center.lng = parseFloat(area.circle.center.lng)
        data.DeliveryAreas[a].circle.radius = parseFloat(area.circle.radius)

        area.vertices.forEach((vert, v) => {
          data.DeliveryAreas[a].vertices[v].lat = parseFloat(vert.lat)
          data.DeliveryAreas[a].vertices[v].lng = parseFloat(vert.lng)
          if (!data.DeliveryAreas[a].vertices[v].hasOwnProperty('latlng')) {
            data.DeliveryAreas[a].vertices[v].latlng = {
              lat: parseFloat(vert.lat),
              lng: parseFloat(vert.lng)
            }
          }
          data.DeliveryAreas[a].vertices[v].latlng.lat = parseFloat(vert.latlng.lat)
          data.DeliveryAreas[a].vertices[v].latlng.lng = parseFloat(vert.latlng.lng)
        })
      })
      data.PrintDeliveryStaffShiftWithOrders = data.hasOwnProperty('PrintDeliveryStaffShiftWithOrders') ? data.PrintDeliveryStaffShiftWithOrders : true

      const mapsDefaultObject = {
        Pos: {
          DefaultMapsProvider: {
            Name: '',
            Key : ''
          },
          InteractiveMapsProvider: {
            Name: '',
            Key : ''
          },
          StaticMapsProvider: {
            Name: '',
            Key : ''
          },
          PrintMapsProvider: {
            Name: '',
            Key : ''
          },
          GeocodeProvider: {
            Name: '',
            Key : ''
          }
        },
        Site: {
          InteractiveMapsProvider: {
            Name: '',
            Key : ''
          },
          StaticMapsProvider: {
            Name: '',
            Key : ''
          },
          GeocodeProvider: {
            Name: '',
            Key : ''
          }
        }
      }

      data.Maps = mergeDeep(mapsDefaultObject, data.Maps || {})

      this.viewData = data
      this.init()
    },

    onSaveButtonClick () {
      this.isSaving = true

      const saveData = JSON.parse(JSON.stringify(this.viewData))
      if (saveData.Countries) delete saveData.Countries

      saveData.DeliveryAreas.forEach((item, index) => {
        saveData.DeliveryAreas[index].areaId = saveData.DeliveryAreas[index].area_id = (index + 1)
        if (!saveData.DeliveryAreas[index].color) saveData.DeliveryAreas[index].color = this.getAreaColor(index)
        saveData.DeliveryAreas[index].vertices.forEach((vert, v) => {
          if (!saveData.DeliveryAreas[index].vertices[v].hasOwnProperty('latlng')) {
            saveData.DeliveryAreas[index].vertices[v].latlng = {
              lat: parseFloat(vert.lat),
              lng: parseFloat(vert.lng)
            }
          }
        })
      })

      window.callAS(window.SocketCommand.Settings.Delivery.Save, { Delivery: saveData })
    },

    onSaveDeliveryResult (result) {
      this.isSaving = false
      if (result.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Delivery.ListView.Map.Field.ChargeCondition.Notification'),
          type: 'error',
          icon: 'warning'
        })
      }
    },
    getData () {
      window.callAS(window.SocketCommand.Settings.Delivery.Get, {})
    },

    onAcceptLatLngAddressesChange () {
      this.viewData.PrintLatLngMap = this.viewData.PrintLatLngQR = this.viewData.AcceptLatLngAddresses
    }
  }
}
</script>

<style scoped>
.v-card__actions .v-btn-toggle .v-btn + .v-btn {
  margin-left : 0;
}

.v-btn-toggle .v-btn__content {
  padding : 0 4px;
}

.divTable {
  display     : table;
  width       : 100%;
  padding-top : 12px;
  font-size   : 14px;
  color       : rgba(0, 0, 0, 0.85);
}

.divTableCell, .divTableHead {
  padding : 3px 10px;
}

/deep/ .v-expansion-panel-content__wrap {
  padding : 0;
}
</style>
