import MessagesModel                from '../Models/messages/MessagesModel'
import Vue                          from 'vue'
import SupportDepartmentsCollection from '@/api/Collections/support-tickets/SupportDepartmentsCollection'
import SupportServicesCollection    from '@/api/Collections/support-tickets/SupportServicesCollection'
import SupportPrioritiesCollection  from '@/api/Collections/support-tickets/SupportPrioritiesCollection'
import SupportTicketCollection      from '@/api/Collections/support-tickets/SupportTicketCollection'
import SupportStatusesCollection    from '@/api/Collections/support-tickets/SupportStatusesCollection'
import CartModel                    from '@/api/Models/pos/CartModel'

const DataStoreInitData = {
  app: {
    versionWeb    : null,
    versionDesktop: null,
    guid          : null,
    guid_old      : null,
    computer      : null
  },

  cloudUpdate: {
    HasUpdate     : false,
    CurrentVersion: null,
    ServerVersion : null
  },

  appDomains: [],

  channelConnections: {
    guest: {
      users: [],
      count: 0
    },
    normal: {
      users: [],
      count: 0
    },
    admin: {
      users: [],
      count: 0
    }
  },

  settings: {},

  messages: new MessagesModel(),

  cart: new CartModel(),

  support: {
    tickets    : new SupportTicketCollection(),
    departments: new SupportDepartmentsCollection(),
    services   : new SupportServicesCollection(),
    priorities : new SupportPrioritiesCollection(),
    statuses   : new SupportStatusesCollection()
  },

  tables: [],

  customers: {
    count: 0,
    items: []
  },
  customerGroups: {
    count: 0,
    items: []
  },

  ordersDataToday: {
    count: 0,
    items: []
  },
  ordersDataSaved: {
    count: 0,
    items: []
  },
  ordersDataAll: {
    count: 0,
    items: []
  }
}

const DataStore = Vue.observable(DataStoreInitData)

export default DataStore
