export default {

  Settings: {
    Toolbar: {
      Title   : 'Settings',
      Subtitle: 'System Settings',
      Icon    : 'settings',
      Color   : 'indigo'
    },
    ListView: {
      Title   : 'Select settings category',
      Subtitle: '@:Settings.Settings.ListView.Title from the list'
    },
    Header: {
      Store: {
        Title   : 'Store',
        Icon    : 'storefront',
        Keywords: ['store', 'details', 'address', 'business', 'brand', 'logo']
      },
      WorkHour: {
        Title   : 'Working Hours',
        Icon    : 'timer',
        Keywords: ['work hours', 'time', 'mode', 'times']
      },
      Delivery: {
        Title   : 'Delivery',
        Icon    : 'directions_bike',
        Keywords: ['delivery', 'distribution', 'map', 'map', 'maps', 'boundaries', 'addresses', 'lat', 'lng', 'coordinates', 'qr', 'qrcode', ' country', 'charge']
      },
      Order: {
        Title   : 'Orders',
        Icon    : 'shopping_cart',
        Keywords: ['orders', 'tips', 'status', 'auto', 'accept', 'completion', 'method', 'payment', 'type', 'delivery', 'pickup', 'default', ' amount', 'min', 'today', 'pos', 'time', 'sources', 'comments', 'list', 'display', 'categories', 'horizontal', 'vertical', 'computer', 'mobile', 'view', 'add', 'click', 'color', 'width', 'search', 'images', 'image', 'position', 'value', 'custom', ' barcode', 'prefix', 'prefix', 'weight', 'remove', 'stock', 'external', 'platforms', 'efood', 'fagi', 'foody', 'getfood', 'platform']
      },
      Tables: {
        Title   : 'Tables',
        Icon    : 'tab_unselected',
        Keywords: ['tables', 'people', 'entry', 'admin', 'list', 'expandable', 'mobile', 'computer', 'orders', 'position', 'product', ' order', 'serving', 'waiters', 'carriage', 'management']
      },
      PinManager: {
        Title   : 'Actions PIN',
        Icon    : 'mdi-form-textbox-password',
        Keywords: ['pin', 'actions', 'manager']
      },
      Reservation: {
        Title   : 'Reservations',
        Icon    : 'mdi mdi-book-open-variant',
        Keywords: ['reservations', 'bookings', 'duration', 'time', 'booking', 'arrival', 'time', 'people', 'limit', 'min', 'max', 'capacity']
      },
      Payment: {
        Title   : 'Payments',
        Icon    : 'payment',
        Keywords: ['payments', 'tips', 'methods', 'method', 'payment', 'viva', 'everypay', 'stripe', 'pos', 'card', 'cash', 'cash', 'card', 'gateways', 'terminals', 'nft', 'mellon']
      },
      Website: {
        Title   : 'Website',
        Icon    : 'language',
        Keywords: ['website', 'web', 'site', 'qr', 'qrcode', 'pages', 'gallery', 'gallery', 'social', 'analytics', 'theme', 'home', ' catalog', 'contact', 'login', 'users', 'other', 'offers', 'recommended', 'products', 'categories', 'store', 'images', 'size', 'position', 'email', 'form', 'areas', 'distribution', 'login', 'google', 'facebook', 'recatcha', 'guest', 'visitors', 'terms', 'use', ' privacy', 'protection policy', 'cookies', 'methods', 'payment', 'return', 'money', 'pixel', 'ga', 'ga4', 'options', 'colors']
      },
      Regional: {
        Title   : 'Regional',
        Icon    : 'translate',
        Keywords: ['regional', 'local', 'settings', 'country', 'zone', 'time', 'language', 'website', 'pos', 'vat', 'revenue', 'receipt', 'documents', ' category', 'invoice', 'coupon', 'charge', 'distribution', 'tips']
      },
      Documents: {
        Title   : 'Documents',
        Icon    : 'receipt_long',
        Keywords: ['documents', 'vat', 'capital', 'width', 'map', 'time', 'barcode', 'number', 'product', 'invoice', 'receipt', 'slip', ' order', 'report', 'shift', 'distributor', 'tax', 'row', 'number', 'print', 'credit', 'slip', 'map', 'categories', 'qr', 'qrcode', 'time', 'version', 'size', 'font', 'font', 'width', 'print']
      },
      Notification: {
        Title   : 'Notifications',
        Icon    : 'notifications_active',
        Keywords: ['notifications', 'sound', 'news', 'order', 'notifications', 'customer', 'store', 'email', 'confirmation']
      },
      Printer: {
        Title   : 'Printers',
        Icon    : 'print',
        Keywords: ['printers', 'groups', 'kitchen', 'pass', 'bar', 'bar', 'slip', 'orders', 'prep', 'documents', 'tax', 'receipt', ' invoice', 'credit', 'reports']
      },
      Display: {
        Title   : 'Displays',
        Icon    : 'mdi-monitor-screenshot',
        Keywords: ['display', 'monitor', 'customer', 'screen', 'cds']
      },
      Email: {
        Title   : 'Email',
        Icon    : 'email',
        Keywords: ['email', 'smtp', 'port', 'encryption', 'tls', 'ssl', 'sender']
      },
      Permission: {
        Title   : 'Permissions',
        Icon    : 'group',
        Keywords: ['permissions', 'rights', 'access', 'requirements', 'api']
      },
      Domain: {
        Title   : 'Domains',
        Icon    : 'domain',
        Keywords: ['domains', 'env', 'reseller', 'package', 'voip', 'subscription', 'mobile', 'app', 'platforms', 'bug', 'snag', 'busnag', ' log', 'logrocket', 'external', 'efood', 'fagi', 'foody', 'getfood', 'wolt', 'debug', 'logging', 'remote', 'permissions', 'marketing', 'customer', 'client', 'application', 'demo', 'landing', 'fb', 'iframe', 'order', 'delete', 'final', 'default type', 'delivery', 'distribution', 'receipt', 'qr', 'qronly', 'tax', 'documents', 'receipts', 'invoices', 'providers', 'electronic', 'invoicing', 'live', 'staging', 'impact ', 'rbs', 'mat', 'primer', 'generic', 'crm', 'app', 'mobile', 'ios', 'android', 'onesignal', 'push', 'notifications']
      },
      OnlineUsers: {
        Title   : 'Online Users',
        Icon    : 'mdi-account-voice',
        Keywords: ['domains', 'online', 'users', 'socket', 'production', 'development']
      },
      SystemUsers: {
        Title   : 'System Users',
        Icon    : 'mdi-account-group',
        Keywords: ['domains', 'online', 'all', 'system', 'users', 'socket', 'production', 'development']
      }
    }
  },

  Delivery: {
    Toolbar: {
      Title   : 'Orders Delivery',
      Subtitle: '@:Settings.Settings.Toolbar.Title @:Settings.Delivery.Toolbar.Title',
      Icon    : 'directions_bike',
      Color   : 'blue'
    },
    Tab: {
      Settings    : 'Delivery Settings',
      Maps        : 'Maps Settings',
      DeliveryArea: 'Delivery range'
    },
    ListView: {
      OutOfRange: {
        Title   : 'Addresses Out Of Store Range',
        Subtitle: 'Allow customers\' addresses that are out of store range only for POS listings.',
        Warning : 'A warning is displayed during data entry. It does not affect the operation of the online store.'
      },
      AcceptCustomAddresses: {
        Title   : 'Addresses Without Maps Validation',
        Subtitle: 'Allow customer addresses without validation with Maps.',
        Warning : 'Caution! Addresses may be outside store limits.'
      },
      AcceptLatLngAddresses: {
        Title   : 'Addresses Using Maps Coordinates Only (Lat / Lng)',
        Subtitle: 'Allow customer addresses without street, number, area or post code but only with coordinates',
        Warning : 'Attention! Addresses may have no other information than the coordinates on the map. '
      },
      DeliveryLimit: {
        Title   : 'Store\'s Orders Delivery Limits',
        Subtitle: 'Delivery limits creation for store orders using the map.'
      },
      Country: {
        Title      : 'Country / Countries of Store\'s Orders Delivery',
        Subtitle   : 'Optionally select country or countries to restrict / limit store\'s orders delivery and map addresses.',
        PlaceHolder: 'Delivery Country / Countries',
        Error      : {
          Max: 'Please select a maximum of 5 countries.'
        }
      },
      Maps: {
        Info: {
          Google: 'All Google Api Keys should allow use in the following domains:',
          MapBox: 'All MapBox Api Keys should allow use in the following domains:'
        },
        Pos: {
          Title: 'Point of Sales (POS)',
          Icon : 'mdi-network-pos'
        },
        Web: {
          Title: 'Website & Mobile App',
          Icon : 'mdi-web'
        },
        ApiKey: {
          Map    : 'Api Key',
          Geocode: 'Api Key'
        },
        Provider: {
          DefaultMap: {
            Title   : 'System Default Interactive Maps Provider',
            Subtitle: ''
          },
          Map: {
            Title   : 'Interactive Maps Provider',
            Subtitle: ''
          },
          MapStatic: {
            Title   : 'Static Maps Provider',
            Subtitle: ''
          },
          MapPrint: {
            Title   : 'Print Maps Provider',
            Subtitle: ''
          },
          Geocode: {
            Title   : 'Geocode Provider',
            Subtitle: ''
          }
        },
        Providers: [
          {
            Name: 'Google',
            Key : 'google'
          },
          {
            Name: 'MapBox',
            Key : 'mapbox'
          }
        ]
      },
      Map: {
        Button: {
          Add    : 'Add Region',
          Polygon: 'Polygon',
          Circle : 'Circle'
        },
        Region: 'Region',
        Field : {
          Title          : { Label: 'Region Title' },
          Move           : { Label: 'Move @:Settings.Delivery.ListView.Map.Button.Polygon' },
          ChargeCondition: {
            Title   : 'Order delivery charge conditions',
            Subtitle: 'Use the table below to set different delivery charge terms. E.g. xx delivery costs on the order in total over xx. Set 0 shipping costs for free delivery or apply to all orders.',
            Label   : {
              Charge      : 'Charge',
              Condition   : 'Condition',
              Total       : '@:Common.Misc.Total',
              NewCondition: {
                All   : 'All orders',
                Above : 'Order above',
                Bellow: 'Order bellow'
              }
            },
            Notification: 'Delivery settings were not updated. @:Common.Misc.Retry'
          }
        }
      }
    }
  },

  Domain: {
    Title  : 'Domains | Domain | Domains',
    Toolbar: {
      Subtitle: 'Domains @:Settings.Settings.Toolbar.Title',
      Icon    : 'domain',
      Color   : 'blue'
    },
    Button: {
      BackupAll: 'Backup Domains',
      Add      : {
        Title: 'New Domain',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Filters: {
      Package   : { Label: 'Package' },
      Reseller  : { Label: 'Reseller' },
      StoreGroup: { Label: 'Business Type' }
    },
    Dialog: {
      Backup: {
        Title  : 'Backup Domain?',
        Content: 'Are you sure you want to back up the domain <strong>{domain}</strong>?'
      },
      BackupAll: {
        Title  : 'Backup All Domains?',
        Content: 'Are you sure you want to back up all domains?'
      },
      Delete: {
        Title  : 'Delete Domain?',
        Content: 'Are you sure you want to permanently delete domain <strong>{domain}</strong>?',
        Delay  : 'Delete delay',
        Warning: 'Final domain deletion is irreversible!'
      }
    },
    Notification: {
      Success: 'Domain backup completed.',
      Error  : 'Domain was not updated. @:Common.Misc.Retry',
      Generic: {
        Success: 'Domain was updated successfully.',
        Error  : 'Domain update failed. @:Common.Misc.Retry'
      }
    },
    Edit: {
      Toolbar: {
        Add: {
          Title   : 'Create Domain',
          Subtitle: 'Create New Domain'
        },
        Edit: {
          Title   : 'Edit Domain',
          Subtitle: 'Modify Domain Details'
        },
        Icon : 'domain',
        Color: 'blue'
      },
      Tab: {
        Domain       : 'Domain',
        Settings     : 'Settings',
        Documents    : 'Documents',
        Customer     : 'Customer',
        Permissions  : 'Permissions',
        Mobile       : 'Mobile App',
        Sms          : 'SMS',
        Marketing    : 'Marketing',
        Platforms    : 'Platforms',
        WebHooks     : 'WebHooks',
        RemoteLogging: 'Remote Logging',
        Application  : 'Application'
      },
      Field: {
        WebHooks: {
          Header: {
            Title: 'Webhook Headers',
            Name : 'Name',
            Value: 'Value'
          },
          Button: {
            Add   : 'Add WebHook',
            Delete: 'Delete Webhook'
          },
          Order: {
            Title   : 'Orders',
            Subtitle: 'Orders Webhooks',
            Field   : {
              Name: {
                Label: 'Name',
                Hint : ''
              },
              Url: {
                Label: 'URL',
                Hint : ''
              },
              Status: {
                Label: 'Order Status',
                Hint : 'Activation of webhook only for these order statuses'
              },
              Production: {
                Label: 'Production Environment',
                Hint : 'Enable or disable production environment'
              }
            }
          }
        },
        Platform: {
          Update: {
            Title : 'Automatic Catalog Update',
            Dialog: {
              Title   : 'Automatic Catalog Update',
              Subtitle: 'Select Time',
              Field   : {
                Time: {
                  Label      : 'Time',
                  Placeholder: 'HH:MM',
                  Hint       : 'Automatic update time',
                  Icon       : 'mdi-calendar-clock',
                  Error      : {
                    Required  : 'The field is required',
                    TimeString: 'Must be a valid time in the format HH:MM'
                  }
                }
              }
            }
          },
          Efood: {
            Active: {
              Title   : 'E-Food Integration',
              SubTitle: 'E-Food Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          Foody: {
            Active: {
              Title   : 'Foody Integration',
              SubTitle: 'Foody Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          Fagi: {
            Active: {
              Title   : 'Fagi Integration',
              SubTitle: 'Fagi Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          Wolt: {
            Active: {
              Title   : 'Wolt Integration',
              SubTitle: 'Wolt Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          },
          GetFood: {
            Active: {
              Title   : 'GetFood Integration',
              SubTitle: 'GetFood Integration για αυτόματη εισαγωγή παραγγελιών'
            },
            Production: {
              Title   : 'Production Environment',
              SubTitle: 'Ενεργοποίηση ή απενεργοποίηση του Production περιβάλλοντος'
            }
          }
        },
        TrialExpiredAt: {
          Label: 'Trial Expiration',
          Hint : 'Trial Expiration Date',
          Icon : 'today'
        },
        SubscriptionExpiredAt: {
          Label: 'Subscription Expiration',
          Hint : 'Trial Expiration Date',
          Icon : 'today'
        },
        SeasonStartedAt: {
          Label: 'Season Start',
          Hint : 'Season Start Date',
          Icon : 'today'
        },
        SeasonEndedAt: {
          Label: 'Season End',
          Hint : 'Season End Date',
          Icon : 'today'
        },
        ExtendedPeriodAt: {
          Label: 'Grace Period Start',
          Hint : 'Grace Period Start Date',
          Icon : 'today'
        },
        CreateLead: {
          Label: 'Create Lead',
          Hint : 'Create Lead',
          Icon : 'engineering'
        },
        Company: {
          Label: 'Company',
          Hint : 'Company Name',
          Icon : 'store'
        },
        Firstname: {
          Label: 'Firstname',
          Hint : 'Lead Firstname',
          Icon : 'person'
        },
        Lastname: {
          Label: 'Lastname',
          Hint : 'Lead Lastname',
          Icon : 'person'
        },
        Email: {
          Label: 'Email',
          Hint : 'Lead Email',
          Icon : 'email'
        },
        Telephone: {
          Label: 'Telephone',
          Hint : 'Lead Telephone',
          Icon : 'phone'
        },
        CustomerId: {
          Label: 'Customer ID',
          Hint : 'Customer ID from CRM',
          Icon : 'person'
        },
        LeadId: {
          Label: 'Lead ID',
          Hint : 'Lead ID from CRM',
          Icon : 'engineering'
        },
        ResellerId: {
          Label: 'Reseller',
          Hint : 'Reseller from CRM',
          Icon : 'mdi-face-agent'
        },
        Country: {
          Label: 'Country',
          Hint : 'Company\'s Country. eg. Greece',
          Icon : 'map'
        },
        BaseDomain: {
          Label: 'Base Domain',
          Hint : '',
          Icon : 'public'
        },
        SubDomain: {
          Label: 'Sub Domain',
          Hint : 'Subdomain eg. demo, dev, etc.',
          Icon : 'domain'
        },
        CName: {
          Label: 'C Name',
          Hint : 'Cname eg. getfood.gr',
          Icon : 'http'
        },
        VoipDomain: {
          Label: 'Voip ID',
          Hint : 'Voip ID eg. 66',
          Icon : 'dialer_sip'
        },
        VoipDomainDesc: {
          Label: 'Voip Number',
          Hint : 'Voip Number eg. 2152152324',
          Icon : 'perm_phone_msg'
        },
        DatabaseName: {
          Label: 'Database Name',
          Hint : 'The name for the database format eg. demo_getfood',
          Icon : 'fas fa-database'
        },
        DatabaseAutoGenerate: {
          Active  : 'Automated',
          Inactive: 'Manual'
        },
        StoreGroup: {
          Label: 'Select Business Type',
          Hint : 'Select your business type',
          Group: {
            FoodIndustry: 'Food and Dining',
            Other       : 'Other'
          }
        },
        ImportDummyData: {
          Title   : 'Import Dummy Data',
          Subtitle: 'Import dummy/demo store catalog based on business type'
        },
        ImportFromThirdParty: {
          Title   : 'Import from another platform?',
          Subtitle: 'Import store catalog from another platform',
          Label   : 'URL address of store to import'
        },
        ImportFromThirdPartyForce: {
          Title   : 'Forced import?',
          Subtitle: 'Import Live or from Cache (inactive)'
        },
        ImportFromThirdPartyWithOptions: {
          Title   : 'Import with options?',
          Subtitle: 'Import from another platform with options'
        },
        ImportFromThirdPartyAllLocales: {
          Title   : 'Import all Locales?',
          Subtitle: 'Import all Locales from another platform'
        },
        ImportFromThirdPartyDefaultLocale: {
          Title   : 'Default Locale?',
          Subtitle: 'Select the default Locale'
        },
        HasReceipts: {
          Title   : 'Fiscal Documents',
          Subtitle: 'Fiscal documents enabled for this domain.'
        },
        DemoInstallation: {
          Title   : 'Demo Installation',
          Subtitle: 'Define domain as Demo store / site. Some features are limited.'
        },
        SSLCertificate: {
          Title   : 'SSL Certificate',
          Subtitle: 'Create or update an SSL Certificate for C Name'
        },
        UploadDefaultAsset: {
          Title   : 'Upload Default Assets',
          Subtitle: 'Upload default assets for the selected domain on the CDN'
        },
        PurgeCDNCache: {
          Title   : 'Purge CDN Cache',
          Subtitle: 'Purges CDN cache for domain assets'
        },
        AllowFbIFrame: {
          Title   : 'Allow FB IFrame',
          Subtitle: 'Allow the use of iframe for facebook application'
        },
        OrderWithoutAddress: {
          Title   : 'Delivery orders without address (QrOnly)',
          Subtitle: 'Allow delivery orders without address only.'
        },
        ForceDeleteOrders: {
          Title   : 'Permanently Delete Orders',
          Subtitle: 'The store has active the Permanently Delete Orders'
        },
        LandingPage: {
          Title   : 'Landing Page',
          Subtitle: 'The store has an active landing page',
          Label   : 'URL Landing Page'
        },
        RedirectToLandingPage: {
          Title   : 'Redirect to Landing Page',
          Subtitle: 'Redirect back to Landing Page when users directly access websites (not through landing page)'
        },
        OrderType: {
          Title   : 'Order Types',
          Subtitle: 'Show website order type option'
        },
        DefaultOrderType: {
          Title: {
            Site: 'Default website order type',
            Pos : 'Default POS order type'
          },
          Delivery: {
            Title: '@:Order.Type.Delivery.Title',
            Icon : '@:Order.Type.Delivery.Icon'
          },
          Collection: {
            Title: '@:Order.Type.Collection.Title',
            Icon : '@:Order.Type.Collection.Icon'
          }
        }, // Permissions
        SelectPackage: 'Select Package', // Application
        AppName      : {
          Label: 'Name ',
          Hint : 'The name of the application eg. demo.getfood.gr, etc.',
          Icon : 'label'
        },
        AppId: {
          Label: 'Id',
          Icon : 'fas fa-key'
        },
        AppKey: {
          Label: 'Key',
          Icon : 'vpn_key'
        },
        AppSecret: {
          Label: 'Secret',
          Icon : 'security'
        },
        Permission: 'Permission',
        Access    : 'Access'
      }
    },
    Import: {
      Toolbar: {
        Title   : 'Import from another platform',
        Subtitle: 'Import store catalog from another platform',
        Icon    : 'domain',
        Color   : 'blue'
      },
      Field: {
        StoreId: {
          Label: 'Store ID or URL',
          Hint : 'Store ID or URL from another platform',
          Icon : 'fas fa-store',
          Error: {
            Required: 'Required field',
            Between : 'Must be at least 3 characters long'
          }
        },
        CatalogOnly: {
          Label: 'Import catalog only',
          Hint : 'Import products catalog only'
        },
        Catalog: {
          Label: 'Select catalog',
          Hint : 'Select catalog for import'
        },
        CatalogId: {
          Label: 'Catalog (id)',
          Hint : 'Select product (id) catalog',
          Icon : 'menu_book'
        }
      }
    },
    Copy: {
      Notification: 'There was a problem with the copy! Check the selected stores and try again.',
      Toolbar     : {
        Title   : 'Copy Domain',
        Subtitle: 'Copy domain data to another domain',
        Icon    : 'domain',
        Color   : 'blue'
      },
      Field: {
        Source: {
          Label: 'Source Domain',
          Hint : 'Source domain to copy data from',
          Icon : 'copy_all'
        },
        Target: {
          Label: 'Target Domain',
          Hint : 'Target domain to copy data to',
          Icon : 'adjust'
        },
        WithActivity: {
          Title   : 'With Activity Log',
          Subtitle: 'Copy with Activity log',
          Icon    : 'view_list'
        },
        WithMenu: {
          Title   : 'With Catalog Data',
          Subtitle: 'Copy with Catalog Data',
          Icon    : 'view_list'
        },
        WithLocation: {
          Title   : 'With Location Data',
          Subtitle: 'Copy with Location Data',
          Icon    : 'map'
        },
        WithCustomers: {
          Title   : 'With Customers Data',
          Subtitle: 'Copy with Customers Data',
          Icon    : 'account_circle'
        },
        WithOrders: {
          Title   : 'With Orders Data',
          Subtitle: 'Copy with Orders Data',
          Icon    : 'shopping_cart'
        },
        WithCoupons: {
          Title   : 'With Coupons Data',
          Subtitle: 'Copy with Coupons Data',
          Icon    : 'local_offer'
        },
        WithStaff: {
          Title   : 'With Staff Data',
          Subtitle: 'Copy with Staff Data',
          Icon    : 'supervisor_account'
        },
        WithStaffGroups: {
          Title   : 'With Staff Groups Data',
          Subtitle: 'Copy with taff Groups Data',
          Icon    : 'supervisor_account'
        },
        WithDeliveryStaff: {
          Title   : 'With Delivery Staff Data',
          Subtitle: 'Copy with Delivery Staff Data',
          Icon    : 'directions_bike'
        },
        WithCdr: {
          Title   : 'With Voip Calls Data',
          Subtitle: 'Copy with Voip Calls Data',
          Icon    : 'phone_in_talk'
        },
        WithAssets: {
          Title   : 'With Image Assets Data',
          Subtitle: 'Copy with Image Assets Data',
          Icon    : 'photo'
        }
      }
    },
    PaymentFee: {
      Toolbar: {
        Title   : 'Payment Fee',
        Subtitle: 'Edit Payment Fee',
        Icon    : 'payment',
        Color   : 'teal'
      },
      Field: {
        Active: {
          Label: 'Active',
          Hint : 'Payment Fee Active',
          Icon : 'toggle_on'
        },
        Factor: {
          Label: 'Factor',
          Hint : 'Payment Factor',
          Icon : 'percent'
        },
        Fee: {
          Label: 'Fee',
          Hint : 'Payment Fee',
          Icon : 'euro'
        }
      }
    },
    Email: {
      Toolbar: {
        Title   : 'Send Welcome Email',
        Subtitle: 'Send Welcome Email to Customer',
        Icon    : 'email',
        Color   : 'blue'
      },
      Field: {
        Email: {
          Label: 'Username',
          Hint : 'Customer Username',
          Icon : 'email'
        },
        To: {
          Label: 'To',
          Hint : 'To Email',
          Icon : 'email'
        },
        Pin: {
          Label: 'PIN',
          Hint : 'Customer PIN',
          Icon : 'adjust'
        },
        Locale: {
          Label: 'Locale',
          Hint : 'Customer Locale',
          Icon : 'map'
        },
        Firstname: {
          Label: 'Firstname',
          Hint : 'Customer Firstname',
          Icon : 'person'
        },
        Lastname: {
          Label: 'Lastname',
          Hint : 'Customer Lastname',
          Icon : 'person'
        },
        Subdomain: {
          Label: 'Subdomain',
          Hint : 'Customer Subdomain',
          Icon : 'language'
        }
      }
    },
    Customer: {
      Toolbar: {
        Title   : 'Edit Domain',
        Subtitle: 'Modify Domain Customer',
        Icon    : 'person',
        Color   : 'light-blue'
      }
    },
    DatabaseMove: {
      Notification: 'There was a problem with the domain move! Check the selected target database and try again.',
      Toolbar     : {
        Title   : 'Move Domain',
        Subtitle: 'Move domain to another database',
        Icon    : 'storage',
        Color   : 'blue-grey'
      },
      Field: {
        Domain: {
          Label: 'Domain',
          Icon : 'language'
        },
        SourceDatabase: {
          Label: 'Source Database',
          Hint : 'Source database to move domain from',
          Icon : 'logout'
        },
        TargetDatabase: {
          Label: 'Target Database',
          Hint : 'Target database to move domain to',
          Icon : 'login'
        },
        WithDelete: {
          Title   : 'Delete source',
          Subtitle: 'Delete source database',
          Icon    : 'view_list'
        }
      }
    }
  },

  Catalog: {
    Copy: {
      Button: {
        Title: 'Copy Catalogue'
      },
      Toolbar: {
        Title   : 'Copy Catalog',
        Subtitle: 'Copy catalog data from another store to this one',
        Icon    : 'domain',
        Color   : 'blue'
      },
      Field: {
        Source: {
          Label: 'Source Store',
          Hint : 'Source store to copy catalog data from',
          Icon : 'adjust'
        },
        Target: {
          Label: 'Target Stores',
          Hint : 'Target stores to copy catalog data to',
          Icon : 'copy_all'
        }
      }
    }
  },

  Email: {
    Toolbar: {
      Title   : 'Email',
      Subtitle: '@:Settings.Settings.Toolbar.Title Email',
      Icon    : 'email',
      Color   : 'blue'
    },
    Field: {
      SMTPAddress: {
        Label: 'SMTP Address',
        Hint : 'SMTP Address. eg. mail.getfoodpro.gr',
        Icon : 'fas fa-server',
        Error: {
          Between: 'The smtp address must be between 2 and 32 characters long'
        }
      },
      SMTPPort: {
        Label: 'SMTP Port',
        Hint : 'SMTP Port. eg. 25',
        Icon : 'fas fa-door-open',
        Error: {
          Between: 'The smtp port must be from 2 to 6 numbers'
        }
      },
      Encryption: {
        Label: 'Encryption',
        Hint : 'SMTP Server Encryption',
        Icon : 'lock',
        List : {
          NoEncryption : 'No Encryption',
          SSLEncryption: 'SSL',
          TSLEncryption: 'TSL'
        }
      },
      Username: {
        Label: 'Username',
        Hint : 'SMTP Username. eg. noreply@getfoodpro.gr',
        Icon : 'account_circle',
        Error: {
          Between: 'The username must be between 2 and 96 characters long'
        }
      },
      Password: {
        Label: 'User Password',
        Hint : 'SMTP User Password',
        Icon : 'fas fa-shield-alt',
        Error: {
          Between: 'The user password must be between 5 and 32 characters long'
        }
      },
      SenderAddress: {
        Label: 'Sender\'s address',
        Hint : 'Sender\'s address eg. info@getfoodpro.gr',
        Icon : 'email'
      },
      SenderName: {
        Label: 'Sender\'s Name',
        Hint : 'Sender\'s Name eg. GETFOOD Pro',
        Icon : 'account_circle'
      },
      TestEmail: {
        Title: 'Send Test Email',
        Email: {
          Label: 'Recipient email',
          Hint : 'The email to which you want to receive the test message. eg info@getfoodpro.gr',
          Icon : 'email',
          Error: {
            Between: 'E-mail must be up to 96 characters long',
            Valid  : 'E-mail must be valid'
          }
        }
      }
    },
    Notification: {
      Update: 'Email settings were not updated. @:Common.Misc.Retry',
      Send  : 'Test email was not sent. @:Common.Misc.Retry'
    }
  },

  Notification: {
    Toolbar: {
      Title   : 'Notifications',
      Subtitle: '@:Settings.Notification.Toolbar.Title @:Settings.Settings.Toolbar.Title',
      Icon    : 'notifications_active',
      Color   : 'blue'
    },
    Data: {
      Sound: {
        Title: 'Select notification sound for new orders',
        Icon : 'music_video',
        Field: {
          Sound: 'Sound',
          Label: 'New orders notification sound'
        }
      },
      StoreEmail: {
        Title   : 'Store Notification Email',
        Subtitle: ''
      },
      Registration: {
        Title   : 'Registration Email',
        Subtitle: 'Automatically send confirmation email to client and/or store after successful account registration'
      },
      Reservation: {
        Title   : 'Reservation Notifications',
        Subtitle: 'Automatically send notifications to customer and/or store'
      },
      Order: {
        Title   : 'Order Notifications',
        Subtitle: 'Automatically send notifications to customer and/or store, via email and/or sms for pos and/or online (website, mobile app) orders.'
      },
      InternalOrder: {
        Title   : 'Order action delay notifications',
        Subtitle: 'Internal notifications of order action delay.',
        Accept  : {
          Icon  : 'mdi-clock',
          Suffix: 'Mins',
          Label : 'Delayed order Accept. Notify  <span class="text-body-1">{minutes}</span> minutes after order receive. '
        },
        CompletedDelivery: {
          Icon  : 'mdi-clock',
          Suffix: 'Mins',
          Label : 'Delayed order Complete. Notify after <span class="text-body-1">{minutes}</span> minutes. <span class="text-body-1">{minutesBefore}</span> minutes before order delivery time. '
        },
        CompletedCollection: {
          Icon  : 'mdi-clock',
          Suffix: 'Mins',
          Label : 'Delayed order Complete. Notify after <span class="text-body-1">{minutes}</span> minutes.<span class="text-body-1">{minutesBefore}</span> minutes before order collection time. '
        },
        Snooze: {
          Icon  : 'mdi-clock',
          Suffix: 'Mins',
          Title : 'Snooze',
          Label : 'Reminder in <span class="text-body-1">{minutes}</span> minutes'
        }
      },

      Button: {
        To: {
          Customer: 'Customer',
          Admin   : 'Admin',
          Store   : 'Store'
        },
        Channel: {
          Email: 'Email',
          Sms  : 'Sms',
          Push : 'Push'
        },
        Source: {
          Pos   : 'Pos',
          Online: 'Online'
        }
      }
    },
    Notification  : 'Notifications settings were not updated. @:Common.Misc.Retry',
    SupportRequest: {
      Contact: 'Activate',
      Sms    : {
        Subject: 'SMS Notifications Activation request',
        Message: 'I want to activate the SMS Notifications feature.'
      },
      Push: {
        Subject: 'Push Notifications Activation request',
        Message: 'I want to activate Push Notifications feature.'
      }
    }
  },

  Order: {
    Toolbar: {
      Title   : 'Orders',
      Subtitle: 'Orders @:Settings.Settings.Toolbar.Title',
      Icon    : 'shopping_cart',
      Color   : 'blue'
    },
    Field: {
      SubtractStockThreshold: {
        Title   : 'Subtract Stock Threshold',
        Subtitle: 'Set the subtract stock threshold per indicator',
        Values  : {
          Default: {
            Color: 'green',
            Value: ''
          },
          Tier1: {
            Color: 'blue',
            Value: 25
          },
          Tier2: {
            Color: 'orange',
            Value: 10
          },
          Tier3: {
            Color: 'red',
            Value: 5
          }
        }
      },
      GeneralOption: {
        Title    : 'General Options',
        Icon     : 'settings',
        OrderTips: {
          Title   : 'Show add "Tip" option',
          Subtitle: 'Enable add order "Tip" option for active payment methods.'
        },
        OrderStatus: {
          Title   : 'Default Order Status',
          Subtitle: 'Select the default Online & POS order status.'
        },
        OrderStatusPerPaymentMethod: {
          Title   : 'Default Order Status per Payment Method',
          Subtitle: 'Select the default Online & POS order status per payment method.'
        },
        OrderTypes: {
          Title        : 'Order Types',
          ShowOrderType: 'Show website order type option',
          Label        : {
            DefaultTypeWebsite: 'Default website order type',
            DefaultTypePos    : 'Default POS order type'
          },
          Button: {
            Delivery: {
              Title: 'Delivery',
              Icon : 'directions_bike'
            },
            Collection: {
              Title: 'Collection',
              Icon : 'directions_run'
            }
          },
          OrderTotalMin: {
            Title                  : 'Minimum order total',
            OrderTotalDeliveryMin  : 'Minimum Order Amount for Delivery Orders',
            OrderTotalCollectionMin: 'Minimum Order Amount for Collection Orders'
          }
        },
        DailyOrders: {
          Title   : 'Show daily orders',
          Subtitle: 'Display daily orders past store hours',
          From    : 'From Hour',
          To      : 'To Hour',
          Icon    : 'access_time'
        }
      },
      PrintOption: {
        Title         : 'Print Options',
        Icon          : 'print',
        ShowCategories: {
          Title   : 'Show categories',
          Subtitle: 'Show categories on order printing'
        },
        ShowMap: {
          Title   : 'Show Map',
          Subtitle: 'Display map with customer address on order printing',
          Hint    : 'To adjust map width click'
        },
        ShowQRCode: {
          Title   : 'Show map QR Code',
          Subtitle: 'Print QR Code to view the customer\'s address in Maps',
          Hint    : 'To adjust QR Code map width click'
        },
        ShowPrintNamesUppercase: {
          Title   : 'Print uppercase',
          Subtitle: 'Print uppercase on order printing'
        }
      },
      PosOption: {
        Title             : 'POS Options',
        Icon              : 'print',
        VoipAddOrderDialog: {
          Title   : 'Show Phone Call Add Order Dialog',
          Subtitle: 'Display add order dialog when answering a phone call to the agent who answered, to all agents, or to none.',
          Item    : {
            Agent   : 'Agent',
            Everyone: 'Everyone',
            None    : 'None'
          }
        },
        ShowMenuImages: {
          Title   : 'Show product images',
          Subtitle: 'Show product images in Pos.'
        },
        ShowSelectedMenuOptionsPos: {
          Title   : 'Show Selected Product Options',
          Subtitle: 'Show selected product options text'
        },
        MenuItemQuickAdd: {
          Title   : 'Quick Add Products with a Click',
          Subtitle: 'Quick add products to the Pos with a click.'
        },
        MenuListItemImageLeftPos: {
          Title   : 'Image Position',
          Subtitle: 'Show product image right or left.'
        },
        MenuCategoriesTabsPosDesktop: {
          Title   : 'Show Product Categories in Desktop',
          Subtitle: 'Display product categories in a horizontal or vertical list in Desktop.'
        },
        MenuCategoriesTabsPosMobile: {
          Title   : 'Show Product Categories in Mobile',
          Subtitle: 'Display product categories in a horizontal or vertical list in Mobile.'
        },
        MenuCategoriesColorWidthPos: {
          Title   : 'Product Categories Color Width',
          Subtitle: 'Change the product categories color width',
          Items   : [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
        },
        MenuSearchEnabledPos: {
          Title   : 'Use Product Search',
          Subtitle: 'Ability to search for products.'
        },
        MenuItemCustomPrice: {
          Title   : 'Use Product Custom Price',
          Subtitle: 'Ability to set custom price for product in pos.'
        },
        BarcodeDelayBetweenScans: {
          Title      : 'Time Between Barcode Scans',
          Subtitle   : 'Time in Milliseconds Between Barcode Scans.',
          PlaceHolder: 'Enter Time in Milliseconds',
          Suffix     : 'ms'
        },
        BarcodePrefixProductUnitQuantity: {
          Title      : 'Prefix for Weight Barcode',
          Subtitle   : 'Set weight barcode prefix for weighted products.',
          PlaceHolder: 'Enter Weight Barcode Prefix'
        },
        BarcodePrefixProductUnitPrice: {
          Title      : 'Prefix for Price Barcode',
          Subtitle   : 'Set price barcode prefix for weighted products.',
          PlaceHolder: 'Enter Price Barcode Prefix'
        },
        MenuOptionsListExpandable: {
          Enabled: {
            Title   : 'Expandable Product Options List View',
            Subtitle: 'Ability to view the list of product options with an expandable list.'
          },
          Mobile: {
            Title   : 'Expandable List on Mobile',
            Subtitle: 'Use the expandable list on mobile',
            Multiple: {
              Title   : 'Expand Multiple at The Same Time',
              Subtitle: ''
            }
          },
          Desktop: {
            Title   : 'Expandable List on Desktops',
            Subtitle: 'Use the expandable list on desktop',
            Multiple: {
              Title   : 'Expand Multiple at The Same Time',
              Subtitle: ''
            }
          }
        },
        SourceTypes: {
          Title   : 'Order Source Types',
          Subtitle: 'Manage order source types.'
        },
        OrderComments: {
          Title   : 'Order Comments',
          Subtitle: 'Manage order comments.',
          Table   : {
            Header: {
              Comment: 'Comment'
            }
          },
          Button: {
            Add: {
              Title: 'Add Comment',
              Icon : 'add_box'
            }
          }
        }
      },
      ExternalPlatforms: {
        Title   : 'External Platforms',
        Icon    : 'mdi-open-in-new',
        Platform: {
          DeliveryHero: 'Delivery Hero (E-Food / Foody)',
          Fagi        : 'Fagi',
          GetFood     : 'GetFood'
        },
        AutoCreateCustomer: {
          Title   : 'Automatic Customer Creation',
          Subtitle: 'Automatically create client if not found.'
        },
        AutoCreateAddress: {
          Title   : 'Automatic Address Creation',
          Subtitle: 'Automatically create customer address if not found.'
        },
        AutoPrintOrder: {
          Title   : 'Automatic Order Summary Sheet Printing',
          Subtitle: 'Automatic order summary sheet printing of orders when they are added into the system.'
        },
        AutoPrintPreparationOrder: {
          Title   : 'Automatic Order Preparation Sheet Printing',
          Subtitle: 'Automatic preparation sheet printing of orders when they are added into the system.'
        },
        AutoPrintReceipt: {
          Title   : 'Automatic Order Receipt Sheet Printing',
          Subtitle: 'Automatic receipt sheet printing of orders when they are added into the system.'
        },
        AddDelivery: {
          Title   : 'Add Delivery Charge to Order',
          Subtitle: 'Add delivery charge to orders when they are added into the system.'
        },
        CatalogImportUseItemPrintedName: {
          Title   : 'Product Print Name as Product Name',
          Subtitle: 'Use product print name as product name'
        },
        AddTip: {
          Title   : 'Add Tip to Order',
          Subtitle: 'Add Tip to orders when they are added into the system.'
        },
        InitOrderStatus: {
          Title   : 'Initial Order Status',
          Subtitle: 'Select the initial orders status when they are added into the system.'
        },
        OrderUpdateStatusInSource: {
          Title   : 'Change Order Status',
          Subtitle: 'Ability to change the status of orders.'
        },
        OrderDeleteInSource: {
          Title   : 'Delete Orders',
          Subtitle: 'Ability to delete orders.'
        }
      }
    },
    Notification: 'Orders settings were not updated. @:Common.Misc.Retry'
  },

  Payment: {
    Toolbar: {
      Title   : 'Payments',
      Subtitle: '@:Settings.Payment.Toolbar.Title @:Settings.Settings.Toolbar.Title',
      Icon    : 'payment',
      Color   : 'blue',
      Tab     : {
        Gateway : 'PAYMENT GATEWAYS',
        Terminal: 'CARD TERMINALS'
      }
    },
    ListView: {
      Title      : '@:Settings.Payment.Toolbar.Title methods',
      Subtitle   : 'Select the available payment methods for the website and pos.',
      UsedPos    : 'Used in Pos',
      UsedWebsite: 'Used in Website',
      Tips       : 'Accept Tips',
      Extra      : 'Extra'
    },
    Notification: {
      VivaWallet: 'Your credentials could not be verified by Viva Wallet.',
      Update    : 'Orders settings were not updated. @:Common.Misc.Retry'
    },
    Edit: {
      Toolbar: {
        Title   : 'Edit',
        Subtitle: 'Modify Payment Method\'s Details'
      },
      Field: {
        OrderStatus: {
          Label: 'Default Online Order Status',
          Hint : 'Select the default status of Online orders.',
          Items: [
            {
              Id  : 11,
              Name: 'Received (Manual Acceptance)'
            },
            {
              Id  : 21,
              Name: 'Accepted (Automatic Acceptance)'
            },
            {
              Id  : 15,
              Name: 'Completed (Automatic Completion)'
            }
          ]
        },
        OrderStatusPos: {
          Label: 'Default POS Order Status',
          Hint : 'Select the default status of POS orders.',
          Items: [
            {
              Id  : 11,
              Name: 'Received (Manual Acceptance)'
            },
            {
              Id  : 21,
              Name: 'Accepted (Automatic Acceptance)'
            },
            {
              Id  : 15,
              Name: 'Completed (Automatic Completion)'
            }
          ]
        },
        SiteTitle: {
          Label: 'Site Title',
          Hint : 'Title displayed in Site eg. Card, Cash, etc.'
        },
        PosTitle: {
          Label: 'POS Title',
          Hint : 'Title displayed in POS eg. Card, Cash, etc.'
        },
        MinTotal: {
          Label: 'Minimum @:Common.Misc.Total',
          Hint : 'The order total must be greater than the minimum price to trigger the payment.'
        },
        Priority: {
          Label: 'Priority',
          Hint : 'Priority displayed eg 1, 5, etc.'
        },
        Label: {
          Icon          : 'Icon',
          PrimaryColor  : 'Primary Color',
          SecondaryColor: 'Secondary Color',
          Method        : 'Method'
        }
      }
    }
  },

  Permission: {
    Toolbar: {
      Title   : 'Permissions',
      Subtitle: 'Permissions @:Settings.Settings.Toolbar.Title',
      Icon    : 'group',
      Color   : 'blue',
      Button  : {
        Add: {
          Title: 'Create new permission',
          Icon : 'add',
          Color: 'light-green'
        },
        Edit: {
          Title: 'Edit permission',
          Icon : 'edit',
          Color: 'grey'
        },
        Delete: {
          Title: 'Delete permission',
          Icon : 'close',
          Color: 'red'
        }
      }
    },
    Dialog: {
      Delete: {
        Title  : 'Delete permission?',
        Content: 'Are you sure you want to delete the permission:'
      }
    },
    Header: {
      Permission  : 'Permission',
      Access      : 'Access',
      Requirements: 'Requirements'
    },
    Edit: {
      Toolbar: {
        Permission: 'Permission',
        Icon      : 'group',
        Color     : 'blue',
        Add       : {
          Title   : 'Create @:Settings.Permission.Edit.Toolbar.Permission',
          Subtitle: 'Create New @:Settings.Permission.Edit.Toolbar.Permission'
        },
        Edit: {
          Title   : 'Edit @:Settings.Permission.Edit.Toolbar.Permission',
          Subtitle: 'Edit @:Settings.Permission.Edit.Toolbar.Permission Details'
        }
      },
      Field: {
        Id: {
          Error: {
            Required: 'The Id field is required.'
          }
        },
        Name: {
          Label: '@:Common.Misc.Name',
          Error: {
            Between: 'Name must be between 2 and 32 characters.'
          }
        },
        Description: {
          Label: 'Description',
          Error: {
            Required: 'The description field is required.'
          }
        },
        Parent: {
          Label: 'Parent Permission'
        },
        AccessType: {
          Label: 'Access Type',
          Error: {
            Required: 'The access type field is required.'
          }
        },
        RequiredPermissions: {
          Label: 'Required Permissions'
        }
      }
    },
    Notification: {
      Update: 'Permission was not updated. @:Common.Misc.Retry',
      Delete: {
        Success: 'Permission was successfully deleted!',
        Error  : 'Permission was not deleted. @:Common.Misc.Retry'
      }
    }
  },

  Display: {
    Title  : 'Displays | Display | Displays',
    Toolbar: {
      Subtitle: 'Displays @:Settings.Settings.Toolbar.Title',
      Icon    : 'mdi-monitor-screenshot',
      Color   : 'blue',
      Button  : {
        Refresh: 'Refresh Displays'
      }
    },
    Header: {
      Id       : '#',
      Name     : 'Display<br>Name',
      Bounds   : 'Display<br>Resolution',
      Primary  : 'Primary<br>Display',
      AutoOpen : 'Auto<br>Open',
      CdsStatus: 'Customer<br>Display',
      Actions  : ''
    },
    Notification: {
      NoDisplays: 'No Displays Available',
      Success   : 'Displays updated successfully.',
      Error     : 'Displays were not updated. @:Common.Misc.Retry'
    }
  },

  Printer: {
    Title  : 'Printers | Printer |Printers',
    Toolbar: {
      Subtitle: 'Printers @:Settings.Settings.Toolbar.Title',
      Icon    : 'print',
      Color   : 'blue',
      Button  : {
        Refresh: 'Refresh Printers'
      }
    },
    Tab: {
      Groups     : 'PRINTER GROUPS',
      Default    : 'ORDER SUMMARY DOC',
      Preparation: 'PREPARATION DOC',
      Receipt    : 'FISCAL DOC',
      Total      : 'REPORT DOC'
    },
    Header: {
      Copy       : 'Copies',
      AutoPrint  : 'Automatic Print',
      Auto       : 'Automatic',
      WaiterPrint: 'Waiter<br>Print',
      Group      : 'Group',
      Print      : 'Print',
      MaxWidth   : 'Max Width',
      FontSize   : 'Font Size',
      Status     : '@:Common.Misc.Status'
    },
    Field: {
      Name: {
        Error: {
          Between: 'Name must be between 2 and 128 characters'
        }
      },
      Email: {
        Error: {
          Between: 'E-mail  must be up to 96 characters long',
          Valid  : 'E-mail must be valid'
        }
      }
    },
    Notification: {
      NoPrinters: 'No Printers Available',
      Success   : 'Printers updated successfully.',
      Error     : 'Printers were not updated. @:Common.Misc.Retry'
    },

    PrinterGroupAddDialog: {
      Toolbar: {
        Add: {
          Title   : 'Add Printer Group',
          Subtitle: 'Create New Printer Group'
        },
        Edit: {
          Title   : 'Edit Printer Group',
          Subtitle: 'Edit Printer Group Details'
        },
        Icon : 'print',
        Color: 'primary'
      },
      Field: {
        Name: {
          Label: 'Name',
          Hint : 'Printer group name ex. Kitchen, Bar, etc.',
          Icon : 'title',
          Error: {
            Between: 'Name must be between 2 and 255 characters'
          }
        },
        Description: {
          Label: 'Description',
          Hint : 'Printer group description',
          Icon : 'description'
        }
      },
      Notification: {
        Update : 'Printer group was NOT saved. @:Common.Misc.Retry',
        Success: 'Printer group was saved successfully.'
      }
    }
  },

  Reservation: {
    Toolbar: {
      Title   : 'Reservations',
      Subtitle: 'Reservations @:Settings.Settings.Toolbar.Title',
      Icon    : 'mdi mdi-book-open-variant',
      Color   : 'blue'
    },
    Section: {
      Duration: {
        Title: 'Duration & Time',
        Icon : 'mdi mdi-calendar-clock',
        Field: {
          TimeIntervalOnline: {
            Title   : 'Time interval',
            Subtitle: 'The time between available times shown to guests for online reservations. eg. if interval is 0:30 guests can reserve at 10:00, 10:30, 11:00 etc.'
          },
          TimeSlotBetween: {
            Title   : 'Time between two reservations',
            Subtitle: 'Time slot between two reservations'
          },
          AllowedMinutesStep: {
            Title   : 'Increment of allowed minutes to select',
            Subtitle: 'eg. Step 15 gives us a choice of 0, 15, 30, 45 minutes.'
          },
          DurationDefault: {
            Title   : 'Default duration',
            Subtitle: 'The duration of a reservation made by guests online, and the default duration for reservations created by staff — including turnaround time.'
          },
          MaxDaysInAdvance: {
            Title   : 'The maximum days a reservation can be made before the arrival date',
            Subtitle: 'eg. if set to "1 week", only the next 7 days will be available when making a reservation.'
          },
          MinTimeInAdvance: {
            Title   : 'The minimum time a reservation can be made before the arrival time',
            Subtitle: 'eg. if set to 1 hour and the time is 13:00, the earliest time a guest can reserve will be 14:00.'
          },
          UseDurationByPeople: {
            Title   : 'Set duration based on number of people',
            Subtitle: 'If active, set up custom rules defining the durations for online reservations based on the number of party size. eg. reservations with 1-3 people could be 2 hours and reservations with 4 or more people could be 3 hours. If no rules match, the default duration defined in your reservation settings will be used.'
          },
          DurationByPeople: {
            MinPeople: 'Min People',
            MaxPeople: 'Max People',
            Duration : 'Duration',
            Button   : {
              Add: {
                Title: 'Add Duration Rule',
                Icon : 'mdi mdi-plus'
              }
            }
          }
        }
      },
      People: {
        Title: 'Persons',
        Icon : 'people',
        Field: {
          MinPeopleOnline: {
            Title   : 'Min persons for a single online reservation',
            Subtitle: 'The minimum number of persons on a single online reservation.'
          },
          MaxPeopleOnline: {
            Title   : 'Max persons for a single online reservation',
            Subtitle: 'The maximum number of persons on a single online reservation.'
          },
          PersonsThreshold: {
            Title   : 'Persons threshold for table capacity',
            Subtitle: 'eg. +2 & -2 persons when searching tables for reservation'
          }
        }
      },
      Manage: {
        Title: 'Management',
        Icon : 'settings',
        Field: {
          AutoUpdateTables: {
            Title   : 'Automatic table update',
            Subtitle: 'Automatically update selected tables when changing the number of people on a reservation.'
          },
          TableOverwrite: {
            Title   : 'Automatically changing tables',
            Subtitle: 'Automatically remove existing tables when you edit a reservation and select a new table that fit the number of people.'
          },
          OnlineBooking: {
            Title   : 'Online reservations service',
            Subtitle: 'Enable or disable the online reservations service.'
          }
        }
      }
    }
  },

  Restaurant: {
    Toolbar: {
      Title   : 'Store',
      Subtitle: 'Store @:Settings.Settings.Toolbar.Title',
      Icon    : 'storefront',
      Color   : 'blue'
    },
    Tab: {
      Details: '@:Common.Misc.Details',
      Address: 'Address'
    },
    Field: {
      CompanyDetails: {
        Title: 'Company Details',
        Icon : 'far fa-building',
        Name : {
          Label: 'Company Name',
          Hint : 'Company Name. eg. Suvlaki Vaggelis',
          Icon : 'storefront'
        },
        Description: {
          Label: 'Company Description',
          Hint : 'Company Description. eg. We grill, cook & create the best for you!',
          Icon : 'comment'
        },
        Email: {
          Label: 'Email',
          Hint : 'Company Email. eg. info@getfoodpro.gr',
          Icon : 'email'
        },
        Phone: {
          Label: 'Phone',
          Hint : 'Company Phone. eg. 2106105200',
          Icon : 'phone'
        },
        Logo: {
          Title     : 'Company Logo (340x150)',
          TitleLight: 'Light Logo (340x150)',
          TitleDark : 'Dark Logo (340x150)',
          Icon      : 'far fa-object-group',
          Button    : { Title: 'Remove image' },
          Hint      : {
            Drag   : 'Drag & drop an image or',
            Click  : 'click to select a file',
            Tap    : 'Click here to select an',
            Gallery: 'image from your gallery',
            Aspect : 'Landscape / Portrait'
          },
          Control: {
            Change: 'Change image',
            Remove: '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
            Select: 'Select image'
          },
          Success: 'Image selected successfully!',
          Error  : {
            Upload: 'Your device does not support file upload,',
            Size  : 'File size exceeds limit',
            Type  : 'This file type is not supported.'
          }
        }
      },
      CompanyAddress: {
        Title  : 'Company Address',
        Icon   : 'place',
        Address: {
          PlaceHolder: 'Enter your address',
          Hint       : '@:Settings.Restaurant.Field.CompanyAddress.Address.PlaceHolder',
          Icon       : 'location_on'
        },
        CustomAddress: {
          Label : 'Use Custom Address',
          Region: {
            PlaceHolder: 'Region',
            Hint       : '@:Settings.Restaurant.Field.CompanyAddress.CustomAddress.Region.PlaceHolder',
            Icon       : 'location_on'
          },
          Street: {
            PlaceHolder: 'Street',
            Hint       : '@:Settings.Restaurant.Field.CompanyAddress.CustomAddress.Street.PlaceHolder',
            Icon       : 'location_on'
          },
          Number  : { Label: 'Number' },
          Postcode: {
            Label: 'Postcode',
            Hint : 'eg. 153 54'
          }
        }
      }
    },
    Notification: 'Store was not updated. @:Common.Misc.Retry'
  },

  Website: {
    Toolbar: {
      Title   : 'Website',
      Subtitle: 'Website @:Settings.Settings.Toolbar.Title',
      Icon    : 'language',
      Color   : 'blue'
    },
    Tabs: {
      QRCode   : 'QR-Code',
      Pages    : 'Pages',
      Gallery  : 'Gallery',
      Social   : 'Social',
      Analytics: 'Analytics',
      Theme    : 'Theme'
    },
    Field: {
      Settings: {
        Icon     : 'photo_size_select_large',
        Title    : 'Image Settings',
        MinHeight: {
          Label: 'Minimum Height'
        }
      },
      Upload: {
        Icon : 'cloud_upload',
        Title: 'Image Management'
      },
      QRCode: {
        Title: {
          Home   : 'Home Page',
          Catalog: 'Product Catalog'
        },
        Color   : 'Color',
        Logo    : 'Logo',
        DotScale: 'Dots Scale',
        Button  : {
          DownloadQR: 'Download QR Code'
        }
      },
      Page: {
        Title: 'Page',
        Home : {
          Title    : 'Home @:Settings.Website.Field.Page.Title',
          Icon     : 'home',
          StoreInfo: {
            Title   : 'Show store details',
            Subtitle: 'Show or hide store details on the Home Page.'
          },
          Offer: {
            Title   : 'Show offers',
            Subtitle: 'Enable or disable offers on the Home Page.'
          },
          FeaturedProduct: {
            Title   : 'Show featured products',
            Subtitle: 'Enable or disable featured products on the Home Page.'
          },
          FeaturedCategory: {
            Title   : 'Show featured categories',
            Subtitle: 'Enable or disable featured categories on the Home Page.'
          },
          AllCategories: {
            Title   : 'Show all categories',
            Subtitle: 'Enable or disable the display of all categories on the Home Page.'
          },
          MaxCategoryNum: {
            Title: 'Maximum number of categories to display',
            Label: 'Categories Limit'
          },
          RandomCategory: {
            Title   : 'Show categories in random order and selection',
            Subtitle: 'Enable or disable showing categories in random order and selection on the Home Page.'
          }
        },
        Catalog: {
          Title    : 'Catalog @:Settings.Website.Field.Page.Title',
          Icon     : 'fas fa-th-list',
          StoreInfo: {
            Title   : '@:Settings.Website.Field.Page.Home.StoreInfo.Title',
            Subtitle: 'Show or hide store details on the Catalog Page.'
          },
          ProductImage: {
            Title   : 'Display product images',
            Subtitle: 'Display product images on the Catalog Page.'
          },
          ProductImageSize: {
            Title   : 'Product image size',
            Subtitle: 'Use large product images on the product page or popup.'
          },
          ShowSelectedMenuOptions: {
            Title   : 'View Selected Product Options',
            Subtitle: 'View selected product options text'
          },
          QuickAddMenuItem: {
            Title   : 'Quick Add Products with a Click',
            Subtitle: 'Quick Add products to the cart with a Click.'
          },
          MenuListItemImageLeft: {
            Title   : 'Image Position',
            Subtitle: 'Show product image right or left.'
          },
          ProductPage: {
            Title   : 'Product Page / Popup',
            Subtitle: 'Use product page instead of product popup.'
          },
          AllCategories: {
            Title   : 'Show all categories',
            Subtitle: {
              ThereAre      : 'There are',
              ActiveProducts: 'active products.'
            },
            ProductLimit: {
              Title: 'Set up to which product limit all product categories will be displayed',
              Label: 'Products Limit'
            }
          }
        },
        Contact: {
          Title    : '@:Settings.Website.Field.Page.Title',
          Icon     : 'email',
          FormEmail: {
            Title   : 'Contact form email',
            Subtitle: 'The email will be used to receive contact form\'s data.',
            Label   : 'Contact email'
          },
          ShowDeliveryAreas: {
            Title   : 'Show Delivery Areas',
            Subtitle: 'View store delivery areas.'
          },
          ShowDeliveryAreasCharges: {
            Title   : 'Show Delivery Area Charges',
            Subtitle: 'View delivery area charges by area.'
          }
        },
        User: {
          Title: 'Users Login',
          Icon : 'lock',
          Guest: {
            Title   : 'Guest Orders',
            Subtitle: 'Allow website visitors to order without creating an account'
          },
          QrOnlineWithCustomer: {
            Title   : 'QR Orders with Customer Info',
            Subtitle: 'Online QR Orders with Customer Info'
          },
          QrOnlineWithCustomerRequired: {
            Title   : 'Customer Info Required',
            Subtitle: 'Online QR Orders with Customer Info Required'
          },
          Email: {
            Title   : 'Sign in with Email',
            Subtitle: 'Allow users to log into the site by email.'
          },
          GoogleLogin: {
            Title         : 'Sign in with Google',
            Subtitle      : 'Allow users to log into the site with Google.',
            GoogleID      : 'Google Login Client ID',
            GoogleSecret  : 'Google Login Client Secret',
            GoogleRedirect: {
              Title: 'Google Redirect URI:',
              Icon : 'far fa-copy'
            }
          },
          FacebookLogin: {
            Title           : 'Sign in with Facebook',
            Subtitle        : 'Allow users to log into the site with Facebook.',
            FacebookID      : 'Facebook Login App ID',
            FacebookSecret  : 'Facebook Login App Secret',
            FacebookRedirect: {
              Title: 'Facebook Redirect URI:',
              Icon : 'far fa-copy'
            }
          },
          GoogleMaps: {
            Title: 'Google Maps',
            Label: 'Google Maps Api Key'
          },
          GoogleRecaptcha: {
            Title: 'Google Recaptcha',
            Label: {
              Public: 'Google Recaptcha Key',
              Secret: 'Google Recaptcha Secret'
            }
          }
        },
        OtherPage: {
          Title     : 'Other Pages',
          Icon      : 'description',
          CustomPage: {
            Title   : 'Personalize page content',
            Subtitle: 'Enable page content modification or use existing content.'
          },
          ListView: {
            Edit: {
              Title: 'Edit page',
              Icon : 'far fa-edit'
            },
            View: {
              Title: 'View page',
              Icon : 'far fa-eye'
            },
            EditManager: {
              Title: 'Edit page manager',
              Icon : 'fas fa-user-edit'
            }
          }
        }
      },
      Icons: {
        Title      : 'Icons',
        SubTitle   : 'Select Icon',
        Search     : 'Search',
        PlaceHolder: 'Search in {count} icons',
        NoIcons    : {
          Title   : 'No Icons Found',
          SubTitle: 'Try searching with different terms'
        }
      },
      Gallery: {
        Title   : 'Gallery',
        SubTitle: 'Select Image',
        Tabs    : {
          Category: 'Categories',
          Product : 'Products',
          Other   : 'Other Photos'
        },
        Settings: {
          Icon     : 'photo_size_select_large',
          Title    : 'Photos Settings',
          MinHeight: {
            Label: 'Minimum Height'
          }
        },
        Upload: {
          Icon : 'cloud_upload',
          Title: 'Manage Photos'
        },
        Image: {
          Button: { Title: 'Remove Image' },
          Hint  : {
            Drag   : 'Drag & drop an image or',
            Click  : 'click to select a file',
            Tap    : 'Click here to select an',
            Gallery: 'image from your gallery',
            Aspect : 'Landscape / Portrait'
          },
          Control: {
            Change : 'Select from device',
            Remove : '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
            Select : 'Select from device',
            Gallery: 'Select from Gallery'
          },
          Success: 'Image selected successfully!',
          Error  : {
            Upload: 'Your device does not support file upload,',
            Size  : 'File size exceeds the 10MB limit',
            Type  : 'This file type is not supported.'
          },
          DeleteConfirmDialog: {
            Title       : 'Delete {count} Photos? | Delete Photo? | Delete {count} Photos?',
            Content     : 'Are you sure you want to delete the {count} selected photos? | Are you sure you want to delete the selected photo? | Are you sure you want to delete the {count} selected photos?',
            Notification: 'The removal is permanent and the process is irreversible'
          }
        }
      },
      Social: {
        Title: 'Social Pages',
        Icon : 'share'
      },
      Analytics: {
        Google: {
          Title: 'Google Analytics',
          Icon : 'fab fa-google',
          Gfp  : {
            Label: 'GFP GA Property ID',
            Hint : 'Enter your GFP GA Property ID',
            Icon : 'analytics'
          },
          Client: {
            Label: 'GA Property ID',
            Hint : 'Enter your GA Property ID',
            Icon : 'analytics'
          }
        },
        Facebook: {
          Title: 'Facebook Pixel',
          Icon : 'fab fa-facebook',
          AppId: {
            Label: 'Application ID',
            Hint : 'Enter your Facebook Pixel ID',
            Icon : 'analytics'
          }
        }
      },
      Theme: {
        Title  : 'Color Options',
        Site   : 'Site @:Settings.Website.Field.Theme.Title',
        Control: 'Controls @:Settings.Website.Field.Theme.Title',
        Icon   : 'fas fa-palette'
      }
    },
    Notification  : 'Website settings were not updated. @:Common.Misc.Retry',
    PageEditDialog: {
      Toolbar: {
        Title: 'Edit Page',
        Color: 'indigo',
        Icon : 'description'
      },
      Field: {
        Title: {
          Label      : 'Title',
          PlaceHolder: 'Page Title',
          Error      : {
            Mandatory: 'This field is mandatory',
            Between  : 'Title must be between 2 and 255 characters long'
          }
        },
        Status: {
          Active  : '@:Common.Button.Toggle.ActiveF',
          Inactive: '@:Common.Button.Toggle.InactiveF'

        }
      },
      Notification: {
        CheckFields: 'Check that you fill in all the information correctly!',
        Success    : 'The page was successfully saved.',
        TryAgain   : 'Page not updated. Please try again.'
      }

    }
  },

  Regional: {
    Toolbar: {
      Title   : 'Regional Settings',
      Subtitle: '@:Settings.Settings.Toolbar.Title',
      Icon    : 'translate',
      Color   : 'blue'
    },
    Region: {
      Title   : 'Select Country and Time Zone',
      Label   : 'Available Countries and Time Zones',
      Subtitle: 'Select store country and time zone',
      Icon    : 'translate',
      Country : { Label: 'Countries' },
      Zone    : { Label: 'Time Zones' }
    },
    Website: {
      Title    : 'Website Language Settings',
      Icon     : 'translate',
      Available: {
        Title   : 'Available Website Languages',
        Subtitle: 'Select the languages that will be available on the website.',
        Label   : 'Available Languages'
      },
      Default: {
        Title   : 'Default Website Language',
        Subtitle: 'Select the default website language.',
        Label   : 'Default Language'
      }
    },
    Pos: {
      Title  : 'POS Language Settings',
      Icon   : 'translate',
      Default: {
        Title   : 'POS Language',
        Subtitle: 'Select the POS language.',
        Label   : 'Language'
      }
    },
    Tax: {
      Title  : 'VAT Settings',
      Icon   : 'percent',
      Default: {
        Vat: {
          Title   : 'Default VAT',
          Subtitle: 'Select default VAT Category'
        },
        Classification: {
          Title   : 'Default Income Classification Category and Type Code',
          Subtitle: 'Select default income classification category and type code'
        }
      },
      Extension: {
        Title   : 'VAT Options',
        Subtitle: 'Select VAT Category.',
        Coupon  : {
          Title: 'Coupon',
          Label: 'VAT Category'
        },
        Delivery: {
          Title: 'Delivery Charge',
          Label: 'VAT Category'
        },
        TipAmount: {
          Title: 'Tip',
          Label: 'VAT Category'
        }
      },
      Reset: {
        Title     : 'Reset VAT Category',
        Subtitle  : 'Change VAT Category for selected categories and their products.',
        Categories: {
          Label: 'Product Categories'
        },
        TaxId: {
          Label: 'VAT Category'
        },
        Tax2Id: {
          Label: 'Sitting VAT Category'
        }
      }
    },
    Classifications: {
      Reset: {
        Title     : 'Reset Income Classification Category and Type Code',
        Subtitle  : 'Change income classification category and type code for the selected categories and their products.',
        Categories: {
          Label: 'Product Categories'
        },
        ClassificationCategoryCode: {
          Label: 'Income Classification Category Code'
        },
        ClassificationTypeCode: {
          Label: 'Income Classification Type Code'
        }
      }
    },
    Notification: 'Regional settings were not updated. @:Common.Misc.Retry',
    Warning     : 'You must logout and login again to apply the language changes'
  },

  Documents: {
    Toolbar: {
      Title   : 'Documents',
      Subtitle: '@:Settings.Settings.Toolbar.Title',
      Icon    : 'receipt_long',
      Color   : 'blue'
    },
    PreparationDocument: {
      Title        : 'Preparation Sheet',
      Icon         : 'summarize',
      ShowItemPrice: {
        Title   : 'Show Menu Item Price',
        Subtitle: 'Show menu item price on order printing'
      },
      ShowCategories: {
        Title   : 'Show Categories',
        Subtitle: 'Show categories on order printing'
      },
      ShowPrintNamesUppercase: {
        Title   : 'Print Uppercase',
        Subtitle: 'Print uppercase on order printing'
      },
      ShowProductNumber: {
        Title   : 'Print Product Number',
        Subtitle: 'Print product number on order printing'
      },
      MaxWidth: {
        Title   : 'Max Width',
        Subtitle: 'Max width for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      },
      FontSize: {
        Title   : 'Font Size',
        Subtitle: 'Font size for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      }
    },
    OrderDocument: {
      Title         : 'Order Summary',
      Icon          : 'receipt',
      ShowCategories: {
        Title   : 'Show Categories',
        Subtitle: 'Show categories on order printing'
      },
      ShowMap: {
        Title   : 'Show Map',
        Subtitle: 'Display map with customer address on order printing',
        Hint    : 'To print a map using Maps coordinates (Lat / Lng) click'
      },
      MapWidth: {
        Title   : 'Map Width',
        Subtitle: 'Wide map with customer address on print order'
      },
      ShowQRCode: {
        Title   : 'Show Map QR Code',
        Subtitle: 'Print QR Code to view the customer\'s address in Maps',
        Hint    : 'For map QR Code press using Maps coordinates (Lat / Lng) click'
      },
      QrWidth: {
        Title   : 'Map QR Code Width',
        Subtitle: 'Width of Map QR Code to display the client\'s address on Maps'
      },
      ShowBarcode: {
        Title   : 'Show Barcode',
        Subtitle: 'Print Barcode for use with barcode scanner'
      },
      ShowPrintSourceType: {
        Title   : 'Print Order Source Type',
        Subtitle: 'Print order source type on order printing'
      },
      ShowPrintOrderDailyNumber: {
        Title   : 'Print Daily Order Number',
        Subtitle: 'Printing daily order number in order printing'
      },
      ShowPrintNamesUppercase: {
        Title   : 'Print Uppercase',
        Subtitle: 'Print uppercase on order printing'
      },
      ShowProductNumber: {
        Title   : 'Print Product Number',
        Subtitle: 'Print product number on order printing'
      },
      MaxWidth: {
        Title   : 'Max Width',
        Subtitle: 'Max width for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      },
      FontSize: {
        Title   : 'Font Size',
        Subtitle: 'Font size for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      },
      RespectWorkingHours: {
        Title   : 'Generate reports based on opening hours',
        Subtitle: 'Generate reports based on daily orders opening hours'
      },
      ShowTaxes: {
        Title   : 'Print VAT analysis',
        Subtitle: 'Print VAT analysis of the order'
      }
    },
    OrderReceipt: {
      Title   : 'Fiscal Documents',
      Icon    : 'receipt_long',
      Document: {
        Receipt      : 'Retail Receipt',
        ReceiptCredit: 'Retail Receipt Credit',
        Invoice      : 'Sales Invoice',
        InvoiceCredit: 'Sales Invoice Credit',
        Fnb          : 'Order Sheet',
        FnbCancel    : 'Order Sheet Cancel',
        FnbReturn    : 'Order Sheet Return',
        Misc         : 'Misc Settings'
      },
      AutoGenerate: {
        Title   : 'Automatic Receipt',
        Subtitle: 'Ability to automatically issue a receipt'
      },
      FnbAutoGenerate: {
        Title   : 'Automatic Order Sheet',
        Subtitle: 'Ability to automatically issue an order sheet'
      },
      Notify: {
        Title   : 'Print and/or Email by Default',
        Subtitle: 'Ability to print a receipt and/or send it by email by default',
        Print   : 'Print',
        Email   : 'Email'
      },
      InvoiceNotify: {
        Title   : 'Print and/or Email by Default',
        Subtitle: 'Ability to print an invoice and/or send it by email by default',
        Print   : 'Print',
        Email   : 'Email'
      },
      ShowCategories: {
        Title   : 'Show Categories',
        Subtitle: 'Show categories on order receipt'
      },
      ShowMap: {
        Title   : 'Show Map',
        Subtitle: 'Display map with customer address on order printing',
        Hint    : 'To print a map using Maps coordinates (Lat / Lng) click'
      },
      MapWidth: {
        Title   : 'Map Width',
        Subtitle: 'Map width with customer address on print order'
      },
      ShowQRCode: {
        Title   : 'Show Map QR Code',
        Subtitle: 'Print QR Code to view the customer\'s address in Maps',
        Hint    : 'For map QR Code press using Maps coordinates (Lat / Lng) click'
      },
      QrWidth: {
        Title   : 'Map QR Code Width',
        Subtitle: 'Width QR Code to display the customer\'s address on Maps'
      },
      ShowCustomer: {
        Title   : 'Show Customer',
        Subtitle: 'Show customer details on order receipt'
      },
      ShowOrderType: {
        Title   : 'Show order type',
        Subtitle: 'Show order type on order receipt'
      },
      ShowPayment: {
        Title   : 'Show payment type',
        Subtitle: 'Show payment type on order receipt'
      },
      RespectWorkingHours: {
        Title   : 'Generate receipts based on opening hours',
        Subtitle: 'Generate receipts based on daily orders opening hours'
      },
      Series: {
        Title   : 'Receipt Series Prefix',
        Subtitle: 'Receipt series prefix eg. ΑΛΠ, ΑΛΠ-Α, ΑΛΠ-Β etc. Cannot be modified once receipts have been issued.'
      },
      SeriesCredit: {
        Title   : 'Receipt Credit Series Prefix',
        Subtitle: 'Receipt credit series prefix eg. ΠΛΠ, ΠΛΠ-Α, ΠΛΠ-Β etc. Cannot be modified once receipts have been issued.'
      },
      TotalCheckInit: {
        Title   : 'Initial receipt number',
        Subtitle: 'Initial receipt progress number. Cannot be modified once receipts have been issued.',
        Error   : {
          InvalidNumber: 'Must be a valid integer'
        }
      },
      TotalCheckInitCredit: {
        Title   : 'Initial receipt credit number',
        Subtitle: 'Initial receipt credit progress number. Cannot be modified once receipt credits have been issued.',
        Error   : {
          InvalidNumber: 'Must be a valid integer'
        }
      },
      InvoiceSeries: {
        Title   : 'Invoice Series Prefix',
        Subtitle: 'Invoice series prefix eg. ΤΠ, ΤΠ-Α, ΤΠ-Β etc. Cannot be modified once invoices have been issued.'
      },
      InvoiceSeriesCredit: {
        Title   : 'Invoice Credit Series Prefix',
        Subtitle: 'Invoice credit series prefix eg. ΠΤΠ, ΠΤΠ-Α, ΠΤΠ-Β etc. Cannot be modified once invoice credits have been issued.'
      },
      InvoiceTotalCheckInit: {
        Title   : 'Initial invoice number',
        Subtitle: 'Initial invoice progress number. Cannot be modified once invoices have been issued.',
        Error   : {
          InvalidNumber: 'Must be a valid integer'
        }
      },
      InvoiceTotalCheckInitCredit: {
        Title   : 'Initial invoice credit number',
        Subtitle: 'Initial invoice credit progress number. Cannot be modified once invoice credits have been issued.',
        Error   : {
          InvalidNumber: 'Must be a valid integer'
        }
      },
      FnbSeries: {
        Title   : 'Order Sheet Series Prefix',
        Subtitle: 'Order sheet series prefix eg. ΔΠ, ΔΠ-Α, ΔΠ-Β etc. Cannot be modified once order sheets have been issued.'
      },
      FnbSeriesCancel: {
        Title   : 'Order Sheet Cancel Series Prefix',
        Subtitle: 'Order sheet cancel series prefix eg. ΔΠΑ, ΔΠΑ-Α, ΔΠΑ-Β etc. Cannot be modified once order sheets cancel have been issued.'
      },
      FnbSeriesReturn: {
        Title   : 'Order Sheet Return Series Prefix',
        Subtitle: 'Order sheet return series prefix eg. ΔΠΕ, ΔΠΕ-Α, ΔΠΕ-Β etc. Cannot be modified once order sheets return have been issued.'
      },
      FnbTotalCheckInit: {
        Title   : 'Initial order sheet number',
        Subtitle: 'Initial order sheet progress number. Cannot be modified once order sheets have been issued.',
        Error   : {
          InvalidNumber: 'Must be a valid integer'
        }
      },
      FnbTotalCheckInitCancel: {
        Title   : 'Initial order sheet cancel number',
        Subtitle: 'Initial order sheet cancel progress number. Cannot be modified once order sheets cancel have been issued.',
        Error   : {
          InvalidNumber: 'Must be a valid integer'
        }
      },
      FnbTotalCheckInitReturn: {
        Title   : 'Initial order sheet return number',
        Subtitle: 'Initial order sheet return progress number. Cannot be modified once order sheets return have been issued.',
        Error   : {
          InvalidNumber: 'Must be a valid integer'
        }
      },
      MaxWidth: {
        Title   : 'Max Width',
        Subtitle: 'Max width for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      },
      FontSize: {
        Title   : 'Font Size',
        Subtitle: 'Font size for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      }
    },
    TotalsDocument: {
      Title   : 'Z Report',
      Icon    : 'inventory',
      MaxWidth: {
        Title   : 'Max Width',
        Subtitle: 'Max width for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      },
      FontSize: {
        Title   : 'Font Size',
        Subtitle: 'Font size for order printing (only for printing from browser)',
        Hint    : 'To set Desktop application printing click'
      }
    },
    DeliveryStaffDocument: {
      Title                            : 'Delivery Staff Shift Report',
      Icon                             : 'directions_bike',
      PrintDeliveryStaffShiftWithOrders: {
        Title   : 'Print Delivery Staff Shift',
        Subtitle: 'Show orders on the delivery staff shift printing'
      }
    },
    Notification: 'Document settings were not updated. @:Common.Misc.Retry'
  },

  WorkingHour: {
    Toolbar: {
      Title   : 'Working Hours & Time Slots',
      Subtitle: 'Working Hours & Order Time Slots',
      Icon    : 'timer',
      Color   : 'blue'
    },
    Tab: {
      WorkingHours : 'Working Hours',
      OrderingTimes: 'Available Ordering Time Slots'
    },
    Field: {
      WorkingHours: {
        Title               : 'Store Hours Settings',
        OnlineOrderingStatus: {
          Title   : 'Online Store',
          Subtitle: 'Online store\'s operation based on opening hours (Open / Close)',
          Open    : 'Open',
          Closed  : 'Closed'
        },
        AlwaysOnlineOrdering: {
          Title   : 'Orders Outside of Store Hours',
          Subtitle: 'The online store can accept orders outside of store hours.'
        },
        OpeningType: {
          Title        : 'Store Hours',
          AlwaysOpen   : 'Open 24/7',
          FlexibleHours: 'Flexible Hours'
        },
        OpeningTime: {
          Label: 'Opening Time',
          Icon : 'access_time',
          Error: {
            Required     : 'The opening time field is required',
            RequiredClose: 'Fill in the closing time field as well.'
          }
        },
        ClosingTime: {
          Label: 'Closing Time',
          Icon : 'access_time',
          Error: {
            Required    : 'The closing time field is required',
            RequiredOpen: 'Fill in the opening time field as well'
          }
        },
        ExtendedHour: 'Extended Hours',
        Button      : {
          Store: {
            Title: 'Store',
            Icon : 'storefront'
          },
          FlexibleHours: {
            Title: '@:Settings.WorkingHour.Field.WorkingHours.OpeningType.FlexibleHours',
            Icon : 'access_time'
          }
        },
        DeliveryHour: {
          Title              : 'Delivery Hours',
          Icon               : 'directions_bike',
          DeliveryService    : 'Delivery Service',
          DefaultDeliveryHour: 'Default Order Delivery Time Slot'
        },
        CollectionHour: {
          Title                : 'Collection Hours',
          Icon                 : 'directions_run',
          CollectionService    : 'Collection Service',
          DefaultCollectionHour: 'Default Order Collection Time Slot'
        },
        ReservationHour: {
          Title             : 'Reservation Hours',
          Icon              : 'mdi mdi-book-open-variant',
          ReservationService: 'Reservation Service'
        }
      },
      OrderingTimes: {
        Title         : 'Available Order Time Slots Settings',
        AddTime       : 'Add Time Slot',
        AvailableTimes: 'Available Time Slots',
        DeliveryTime  : {
          Title              : 'Available Order Delivery Time Slots',
          Icon               : 'directions_bike',
          CopyFromCollection : 'Copy from collection',
          DefaultDeliveryTime: 'Default Order Delivery Time Slot'
        },
        CollectionTime: {
          Title                : 'Available Order Collection Time Slots',
          Icon                 : 'directions_run',
          CopyFromCollection   : 'Copy from delivery',
          DefaultCollectionTime: 'Default Order Collection Time Slot'
        }
      },
      Error: {
        Required    : 'Required field',
        RequiredBoth: 'Please fill in both fields'
      }
    },
    Notification: 'The store\'s working hours were not updated. @:Common.Misc.Retry'
  },

  PinManager: {
    Toolbar: {
      Title   : '@:Settings.Settings.Header.PinManager.Title',
      Subtitle: 'Actions PIN @:Settings.Settings.Toolbar.Title per user group',
      Icon    : '@:Settings.Settings.Header.PinManager.Icon',
      Color   : 'blue'
    },
    Enabled: {
      Title   : 'Administrator PIN',
      Subtitle: 'Use administrator PIN for unlocking actions'
    },
    Pin: {
      Title   : 'PIN Code',
      Subtitle: 'PIN Code for unlocking actions',
      Error   : 'PIN code must be 4 numbers'
    },
    Permissions: {
      Title: 'PIN Required for'
    },
    PermissionGroups: [
      {
        Title      : 'POS',
        Icon       : 'add_shopping_cart',
        Description: '',
        Permissions: {
          PosDiscount: {
            Title   : 'Discount at order',
            Subtitle: ''
          },
          PosCancel: {
            Title   : 'Cancel order',
            Subtitle: ''
          },
          PosDelete: {
            Title   : 'Delete order',
            Subtitle: ''
          },
          PosEdit: {
            Title   : 'Edit order',
            Subtitle: ''
          },
          PosPayments: {
            Title   : 'Manage order payment transactions',
            Subtitle: ''
          },
          PosSetCustomPrice: {
            Title   : 'Set custom product price at order',
            Subtitle: ''
          },
          PosChangeOrderTimeDuringAccept: {
            Title   : 'Change delivery/takeaway time of order during accept',
            Subtitle: ''
          },
          PosChangeOrderTimeAfterAccept: {
            Title   : 'Change delivery/takeaway time of order after accept',
            Subtitle: ''
          }
        }
      },
      {
        Title      : 'Tables',
        Icon       : 'tab_unselected',
        Description: '',
        Permissions: {
          TableDiscount: {
            Title   : 'Discount in table order',
            Subtitle: ''
          },
          TableCancel: {
            Title   : 'Cancel in table order',
            Subtitle: ''
          },
          TableSetCustomPrice: {
            Title   : 'Set custom product price in table order',
            Subtitle: ''
          }
        }
      },
      {
        Title      : 'Waiters',
        Icon       : 'room_service',
        Description: '',
        Permissions: {
          OpenWaiterShift: {
            Title   : 'Open waiter shift',
            Subtitle: ''
          },
          CloseWaiterShift: {
            Title   : 'Close waiter shift',
            Subtitle: ''
          },
          MovePayment: {
            Title   : 'Move payment',
            Subtitle: ''
          },
          ManageAllTables: {
            Title   : 'Manage other waiter\'s tables',
            Subtitle: ''
          },
          ChangeTableWaiter: {
            Title   : 'Move table to another waiter',
            Subtitle: ''
          }
        }
      }
    ]
  },

  Tables: {
    Toolbar: {
      Title   : '@:Settings.Settings.Header.Tables.Title',
      Subtitle: 'Tables @:Settings.Settings.Toolbar.Title',
      Icon    : '@:Settings.Settings.Header.Tables.Icon',
      Color   : 'blue'
    },
    Tables: {
      Title                      : 'Tables',
      Icon                       : 'tab_unselected',
      OpenTableWithDefaultPersons: {
        Title   : 'Set Table Persons',
        Subtitle: 'Automatically set people sitting at the table when opening a table'
      },
      TableListExpandable: {
        Enabled: {
          Title   : 'Expandable Table List View',
          Subtitle: 'Ability to view the list of areas and tables with an expandable list.'
        },
        Mobile: {
          Title   : 'Expandable List on Mobile',
          Subtitle: 'Use the expandable list on mobile',
          Multiple: {
            Title   : 'Expand Multiple at The Same Time',
            Subtitle: ''
          }
        },
        Desktop: {
          Title   : 'Expandable List on Desktop',
          Subtitle: 'Use the expandable list on desktop',
          Multiple: {
            Title   : 'Expand Multiple at The Same Time',
            Subtitle: ''
          }
        }
      }
    },
    Orders: {
      Title                     : 'Orders',
      Icon                      : 'shopping_cart',
      MenuOptionsDialogTableSeat: {
        Title   : 'Select Product Position',
        Subtitle: 'Ability of choosing the position corresponding to the product and the people sitting at the table. eg Middle (Table), 1, 2, 3, etc. '
      },
      MenuOptionsDialogTableOrder: {
        Title   : 'Select Product Serving Order',
        Subtitle: 'Ability of choosing the order in which the product will be served at the table. eg 1st, 2nd, 3rd, etc.'
      },
      Permission: {
        Daily: {
          Title   : 'Today Orders',
          Subtitle: 'Access today\'s orders'
        },
        Saved: {
          Title   : 'Saved Orders',
          Subtitle: 'Access to saved orders'
        },
        All: {
          Title   : 'All Orders',
          Subtitle: 'Access to all orders'
        },
        ExcludeFromPin: {
          Title   : 'Exemption from Admin PIN',
          Subtitle: 'Exemption from Admin PIN to unlock actions'
        }
      },
      VisibleStatuses: {
        Title   : 'Display Orders with Status',
        Subtitle: 'Show orders with these statuses only'
      }
    },
    Waiters: {
      Title             : 'Waiters',
      Icon              : 'room_service',
      WaiterCanManagePos: {
        Title   : 'POS Orders',
        Subtitle: 'Access POS and Add POS Orders'
      },
      WaiterCanManageAllTables: {
        Title   : 'Manage other waiter\'s tables',
        Subtitle: 'Access and manage tables of all waiters'
      },
      WaiterCanChangeTableWaiter: {
        Title   : 'Move table to another waiter',
        Subtitle: 'Ability to change table waiter'
      },
      WaiterCanSetCustomPrice: {
        Title   : 'Set custom product price',
        Subtitle: 'Ability to add product with custom price to orders'
      },
      WaiterCanChangeExternalReceipt: {
        Title   : 'Change external receipt',
        Subtitle: 'Ability to change external receipt'
      }
    }
  },

  OnlineUsers: {
    Title  : 'Online Users | Online User | Online Users',
    Toolbar: {
      Subtitle: 'Online Users @:Settings.Settings.Toolbar.Title',
      Icon    : 'mdi-account-voice',
      Color   : 'blue'
    }
  },

  SystemUsers: {
    Title  : 'System Users',
    Toolbar: {
      Subtitle: 'System Users @:Settings.Settings.Toolbar.Title',
      Icon    : 'mdi-account-group',
      Color   : 'blue'
    }
  },

  Wizard: {
    Dialog: {
      InstallationGuide     : 'Installation Guide',
      QuickInstallationGuide: 'Quick Installation Guide',
      WorkingHours          : 'Working Hours',
      WorkingHoursSettings  : 'Working Hours Settings',
      Success               : 'Completed successfully',
      DeliveryArea          : 'Orders Delivery Area',
      DeliverySettings      : 'Orders Delivery Settings'
    },
    Intro: {
      Select : 'Select "',
      ToBegin: '" to begin'
    },
    Outro: {
      FaqInstructions     : 'Help Center',
      MoreSettings        : 'More Settings',
      CategoriesManagement: 'Categories Management',
      ProductsManagement  : 'Products Management'
    }
  }

}
