<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card v-if="selectedCustomer">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Customer.CustomerAddEditAddressDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Customer.CustomerAddEditAddressDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!editAddressId"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Customer.CustomerAddEditAddressDialog.Toolbar.Add.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerAddEditAddressDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>
          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Customer.CustomerAddEditAddressDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerAddEditAddressDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="addressForm"
            v-model="addressFormValid"
            @submit.prevent
          >
            <v-container
              class="mt-0 pt-0"
              fluid
            >
              <v-alert
                :value="addressIsOutsideStoreLimits"
                class="pa-1"
                color="error"
                icon="warning"
                outlined
              >
                <template v-if="isCustomAddress(selectedAddress)">
                  <strong>{{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:</strong> {{ $t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Custom.Subtitle') }}
                </template>
                <template v-else>
                  <strong>{{ $t('Common.Misc.Attention').toLocaleUpperCase($i18n.locale) }}:</strong> {{ $t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Range.Subtitle') }}
                </template>
              </v-alert>

              <v-row dense>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-container
                    class=""
                    fluid
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-map-autocomplete-service
                          v-model="selectedLocation"
                          :rules="useCustomAddress ? [] : validationRules.addressForm.AutoComplete"
                          :maps-provider="interactiveMapsProvider.Name"
                          :maps-access-token="interactiveMapsProvider.Key"
                          :geocode-provider="geocodeProvider.Name"
                          :geocode-access-token="geocodeProvider.Key"
                          :map-center="mapCenter"
                          :store-marker="markerStore"
                          :address-marker="markerAddress"
                          :country="locationDeliveryCountriesStr"
                          :disabled="useCustomAddress || !isVisible"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Address.Hint')"
                          :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Address.PlaceHolder')"
                          :types="['geocode']"
                          color="gfp-adv-pages-account-address-dialog-body-controls"
                          display-field="Name"
                          :map-height="acceptCustomAddresses ? '320px' : '380px'"
                          :map="isVisible && (!isCustomAddress(address) && !useCustomAddress)"
                          @place-changed="(placeObj) => onPlaceChanged(placeObj)"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-img
                          v-if="isCustomAddress(address) || useCustomAddress"
                          :height="acceptCustomAddresses ? 320 : 350"
                          position="center center"
                          src="/assets/map/map-placeholder.png"
                          style="border: 1px solid #e2e2e2;"
                          width="100%"
                        />
                      </v-col>

                      <v-col
                        v-if="acceptCustomAddresses"
                        cols="12"
                      >
                        <v-switch
                          v-model="useCustomAddress"
                          inset
                          :label="$t('Customer.CustomerAddEditAddressDialog.UseCustom')"
                          class="pt-0 mt-0"
                          color="success"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-container
                    class=""
                    fluid
                  >
                    <v-row
                      v-if="!useCustomAddress && !acceptLatLngAddresses"
                      dense
                    >
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.Nickname"
                          :counter="35"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Icon')"
                        />
                      </v-col>

                      <v-col
                        sm="9"
                        cols="12"
                      >
                        <v-map-autocomplete-service
                          v-model="selectedCity"
                          :geocode-provider="geocodeProvider.Name"
                          :geocode-access-token="geocodeProvider.Key"
                          :country="locationDeliveryCountriesStr"
                          :disabled="!isVisible"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Region.Hint')"
                          :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Region.PlaceHolder')"
                          :rules="validationRules.addressForm.City"
                          :types="['city']"
                          display-field="Properties.City"
                          color="gfp-adv-pages-account-address-dialog-body-controls"
                          @place-changed="(placeObj) => onPlaceChanged(placeObj)"
                        />
                      </v-col>
                      <v-col
                        sm="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.Postcode"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Label')"
                          :rules="validationRules.addressForm.Postcode"
                          required
                        />
                      </v-col>

                      <v-col
                        sm="9"
                        cols="12"
                      >
                        <v-map-autocomplete-service
                          v-model="selectedStreet"
                          :geocode-provider="geocodeProvider.Name"
                          :geocode-access-token="geocodeProvider.Key"
                          :country="locationDeliveryCountriesStr"
                          :disabled="!isVisible"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Street.Hint')"
                          :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Street.PlaceHolder')"
                          :rules="validationRules.addressForm.Street"
                          :types="['address']"
                          display-field="Properties.Street"
                          color="gfp-adv-pages-account-address-dialog-body-controls"
                          @place-changed="(placeObj) => onPlaceChanged(placeObj)"
                        />
                      </v-col>
                      <v-col
                        sm="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.Number"
                          :counter="8"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Number.Label')"
                          :rules="validationRules.addressForm.Number"
                          hint=""
                          required
                          @blur="getAddress"
                        />
                      </v-col>

                      <v-col
                        sm="5"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.Floor"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Icon')"
                          class="pr-2"
                        />
                      </v-col>
                      <v-col
                        sm="7"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.DoorBell"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Icon')"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="address.Comment"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Icon')"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-checkbox
                          v-model="address.IsDefault"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.DefaultAddress.Label')"
                          hide-details
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-else
                      dense
                    >
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.Nickname"
                          :counter="35"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.FriendlyName.Icon')"
                        />
                      </v-col>
                      <v-col cols="12">
                        <div class="input-group input-group--prepend-icon input-group--text-field">
                          <div class="input-group__input">
                            <v-text-field
                              v-model="address.City"
                              :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Region.Hint')"
                              :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Region.PlaceHolder')"
                              :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Region.Icon')"
                              :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.City"
                            />
                          </div>
                          <div
                            class="input-group__details"
                            style="min-height:0;"
                          />
                        </div>
                      </v-col>
                      <v-col
                        sm="6"
                        cols="12"
                      >
                        <div class="input-group input-group--prepend-icon input-group--text-field">
                          <div class="input-group__input">
                            <v-text-field
                              v-model="address.Street"
                              :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Street.Hint')"
                              :placeholder="$t('Customer.CustomerAddEditAddressDialog.Field.Street.PlaceHolder')"
                              :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Street.Icon')"
                              :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Street"
                              required
                            />
                          </div>
                          <div
                            class="input-group__details"
                            style="min-height:0;"
                          />
                        </div>
                      </v-col>
                      <v-col
                        sm="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.Number"
                          :counter="8"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Number.Label')"
                          :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Number"
                          hint=""
                          required
                        />
                      </v-col>
                      <v-col
                        sm="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.Postcode"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Label')"
                          :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Postcode"
                          required
                        />
                      </v-col>

                      <v-col
                        sm="5"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.Floor"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Floor.Icon')"
                          class="pr-6"
                        />
                      </v-col>
                      <v-col
                        sm="7"
                        cols="12"
                      >
                        <v-text-field
                          v-model="address.DoorBell"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.DoorBell.Icon')"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="address.Comment"
                          :hint="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Hint')"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Label')"
                          :prepend-icon="$t('Customer.CustomerAddEditAddressDialog.Field.Instructions.Icon')"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-checkbox
                          v-model="address.IsDefault"
                          :label="$t('Customer.CustomerAddEditAddressDialog.Field.DefaultAddress.Label')"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import AppData                 from '@/mixins/appdata'
import MapsCommon              from '@/mixins/maps/mapsCommon'
import AddressCommon           from '@/mixins/maps/addressCommon'
import VMapAutocompleteService from '@/components/common/maps/VMapAutocompleteService.vue'

export default {
  components: {
    VMapAutocompleteService
  },
  mixins: [AppData, MapsCommon, AddressCommon],
  props : {
    editAddressId: {
      type   : [Number, String],
      default: undefined
    },
    selectedCustomer: {
      type   : Object,
      default: undefined
    },
    selectedAddress: {
      type   : Object,
      default: undefined
    },
    customerAddEditAddressDialogVisible: {
      type   : Boolean,
      default: false
    },
    orderType: {
      type   : [Number, String],
      default: undefined
    }
  },
  data () {
    return {
      loading          : false,
      addressFormValid : false,
      useCustomAddress : false,
      selectedAddressId: null,
      address          : this.defaultEmptyAddress(),
      validationRules  : {
        addressForm: {
          AutoComplete: this.useCustomAddress ? [] : [
            (v) => !!v || this.$t('Customer.CustomerAddEditAddressDialog.Field.AutoComplete.Errors.Mandatory'),
            (v) => !!v && !!this.address.Lat && !!this.address.Lng || this.$t('Customer.CustomerAddEditAddressDialog.Field.AutoComplete.Errors.Mandatory'),
            (v) => v && !this.addressIsOutsideStoreLimits || this.$t('Customer.CustomerAddEditAddressDialog.Field.AutoComplete.Errors.Valid')
          ],
          City: [
            (v) => v && v.length >= 2 && v.length <= 120 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Region.Error.Between')
          ],
          Postcode: [
            (v) => v && v.length >= 2 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Postcode.Error.Valid')
          ],
          Street: [
            (v) => v && v.length >= 2 && v.length <= 120 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Street.Error.Between')
          ],
          Number: [
            (v) => v && v.length >= 1 && v.length <= 10 || this.$t('Customer.CustomerAddEditAddressDialog.Field.Number.Error.Between')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.customerAddEditAddressDialogVisible
      },
      set (val) {
        this.$emit('update:customerAddEditAddressDialogVisible', val)
      }
    },
    currentOrderType: {
      get () {
        return this.orderType
      },
      set (val) {
        this.$emit('update:orderType', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.addressIsOutsideStoreLimits = false
        this.$bus.$on(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        this.$nextTick(() => {
          this.setAddress()
        })
      } else {
        this.$bus.$off(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
      }
    },
    useCustomAddress (newVal, oldVal) {
      if (newVal) {
        this.address.Lng = this.address.Lat = this.address.PlaceId = this.address.Formatted = ''
      }
    }
  },
  mounted () {},
  beforeDestroy () {},
  methods: {
    async setAddress () {
      if (this.editAddressId && this.selectedAddress) {
        this.clearSelectedAddress()
        this.$set(this, 'address', JSON.parse(JSON.stringify(this.selectedAddress)))
        this.selectedLocation = this.selectedStreet = this.selectedCity = this.addressToPlaceObject(this.selectedAddress)
        this.markerAddress = this.mapCenter = this.getAddressLatLng(this.selectedAddress)
        await this.checkAddress(this.selectedAddress)
      } else {
        this.clearSelectedAddress()
        this.initMapCenter()
      }
      this.useCustomAddress = this.isCustomAddress(this.selectedAddress)
    },

    async checkAddress (address) {
      if (!address) return false

      const result = await this.isPlaceInsideDeliveryAreas(this.addressToPlaceObject(address))
      this.addressIsOutsideStoreLimits = !result

      return result
    },

    async onPlaceChanged (place) {
      this.setAddressFromPlace(place, true)
      await this.checkAddress(this.placeToAddressObject(place))
    },

    onCustomerSaveResult (result) {
      if (result.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: result.type,
          type: 'info',
          icon: 'info'
        })

        const customer = result?.customer || result?.result?.customer

        this.$bus.$emit(window.SocketCommand.Customer.Get, customer)

        this.clearSelectedAddress()
        this.isVisible = false
      } else {
        this.$bus.$emit('app-show-notification', {
          body: result.type || result.message,
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onSaveButtonClick () {
      const address = JSON.parse(JSON.stringify(this.address))
      const customer = JSON.parse(JSON.stringify(this.selectedCustomer))
      let i = 0

      address.Formatted = this.getAddressFormatted(address)
      if (this.useCustomAddress) address.Lat = address.Lng = 0

      this.addressFormValid = this.$refs.addressForm.validate()

      if (this.addressFormValid) {
        if (this.editAddressId) {
          for (i = 0; i < customer.Addresses.length; i++) {
            if (customer.Addresses[i].Id.toString() === this.editAddressId.toString()) {
              customer.Addresses[i] = address
            } else {
              if (address.IsDefault) {
                customer.Addresses[i].IsDefault = false
              }
            }
          }
        } else {
          if (address.IsDefault) {
            for (i = 0; i < customer.Addresses.length; i++) {
              customer.Addresses[i].IsDefault = false
            }
          }
          customer.Addresses.push(address)
        }
        this.saveCustomer(customer)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    saveCustomer (customer) {
      this.loading = true
      const tmpCustomer = JSON.parse(JSON.stringify(customer))
      const groups = tmpCustomer?.Info?.Groups?.map(group => group?.Id || group || null)?.filter(group => group !== null)
      tmpCustomer.Info.Groups = groups?.length ? groups : [11]

      const payload = {
        ...JSON.parse(JSON.stringify(tmpCustomer.Info)),
        Addresses: JSON.parse(JSON.stringify(tmpCustomer.Addresses || [])),
        Companies: JSON.parse(JSON.stringify(tmpCustomer.Companies || []))
      }

      window.callAS(window.SocketCommand.Customer.Save, payload)
    },

    onCancelButtonClick () {
      if (this.$refs.addressForm) this.$refs.addressForm.resetValidation()
      if (!this.selectedAddress) this.currentOrderType = 2
      this.clearSelectedAddress()
      this.isVisible = false
    }
  }
}
</script>
