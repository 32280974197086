<template>
  <v-dialog
    v-model="isVisible"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    max-width="1280"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        class="pl-1"
        height="80"
        max-height="80"
      >
        <v-avatar color="blue">
          <v-icon dark>
            {{ currentStepComponent.icon }}
          </v-icon>
        </v-avatar>

        <v-toolbar-title class="pl-3">
          <div class="body-3">
            {{ currentStepComponent.title }}
          </div>
          <div class="text-caption">
            {{ currentStepComponent.subTitle }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          class="ml-0"
          icon
          @click="onCancelButtonClick"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider />

      <v-card-text style="height: 1000px;">
        <component
          :is="currentStepComponent.route.name"
          ref="currentComponentRef"
          :wizard-visible="isVisible"
          @wizard-component-loading="onLoading"
          @wizard-component-complete="onComplete"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-btn
          class="hidden-xs-only"
          color="light-blue darken-1"
          outlined
          @click="onCancelButtonClick"
        >
          {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
        </v-btn>

        <v-spacer />

        <v-btn
          :disabled="currentStep === 1 || isLoading"
          class="white--text"
          color="green"
          depressed
          @click="onPrevClick"
        >
          {{ $t('Common.Button.Previous').toLocaleUpperCase($i18n.locale) }}
        </v-btn>

        <v-btn
          :disabled="currentStep > totalSteps || isLoading"
          class="white--text"
          color="green"
          depressed
          @click="onNextClick"
        >
          {{ currentStep === totalSteps ? $t('Common.Button.Finish').toLocaleUpperCase($i18n.locale) : $t('Common.Button.Next').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Auth                       from '../../../mixins/auth'
import SettingsWizardIntro        from './SettingsWizardIntro'
import SettingsWizardRestaurant   from './SettingsWizardRestaurant'
import SettingsWizardWorkingHours from './SettingsWizardWorkingHours'
import SettingsWizardDelivery     from './SettingsWizardDelivery'
import SettingsWizardOutro        from './SettingsWizardOutro'

export default {
  name      : 'SettingsWizardDialog',
  components: {
    SettingsWizardIntro,
    SettingsWizardRestaurant,
    SettingsWizardWorkingHours,
    SettingsWizardDelivery,
    SettingsWizardOutro
  },
  directives: {},
  mixins    : [Auth],
  props     : {
    visible: {
      type    : Boolean,
      default : false,
      required: true
    }
  },
  data () {
    return {
      isLoading  : false,
      currentStep: 1
    }
  },
  computed: {
    stepComponents () {
      if (this.appConfig.LOCATION_DATA.CatalogOnly) {
        return [
          {
            title   : this.$t('Settings.Wizard.Dialog.InstallationGuide'),
            subTitle: this.$t('Settings.Wizard.Dialog.QuickInstallationGuide'),
            icon    : 'settings',
            route   : { name: 'SettingsWizardIntro' }
          },
          {
            title   : this.$t('Settings.Restaurant.Toolbar.Title'),
            subTitle: this.$t('Settings.Restaurant.Toolbar.Subtitle'),
            icon    : 'storefront',
            route   : { name: 'SettingsWizardRestaurant' }
          },
          /* {
            title   : this.$t('Settings.Wizard.Dialog.WorkingHours'),
            subTitle: this.$t('Settings.Wizard.Dialog.WorkingHoursSettings'),
            icon    : 'timer',
            route   : { name: 'SettingsWizardWorkingHours' }
          }, */
          {
            title   : this.$t('Settings.Wizard.Dialog.InstallationGuide'),
            subTitle: this.$t('Settings.Wizard.Dialog.Success'),
            icon    : 'storefront',
            route   : { name: 'SettingsWizardOutro' }
          }
        ]
      }

      return [
        {
          title   : this.$t('Settings.Wizard.Dialog.InstallationGuide'),
          subTitle: this.$t('Settings.Wizard.Dialog.QuickInstallationGuide'),
          icon    : 'settings',
          route   : { name: 'SettingsWizardIntro' }
        },
        {
          title   : this.$t('Settings.Restaurant.Toolbar.Title'),
          subTitle: this.$t('Settings.Restaurant.Toolbar.Subtitle'),
          icon    : 'storefront',
          route   : { name: 'SettingsWizardRestaurant' }
        },
        /*
      {
        title   : this.$t('Settings.Wizard.Dialog.WorkingHours'),
        subTitle: this.$t('Settings.Wizard.Dialog.WorkingHoursSettings'),
        icon    : 'timer',
        route   : { name: 'SettingsWizardWorkingHours' }
      },
        {
          title   : this.$t('Settings.Wizard.Dialog.DeliveryArea'),
          subTitle: this.$t('Settings.Wizard.Dialog.DeliverySettings'),
          icon    : 'directions_bike',
          route   : { name: 'SettingsWizardDelivery' }
        },
         */
        {
          title   : this.$t('Settings.Wizard.Dialog.InstallationGuide'),
          subTitle: this.$t('Settings.Wizard.Dialog.Success'),
          icon    : 'storefront',
          route   : { name: 'SettingsWizardOutro' }
        }
      ]
    },

    totalSteps () {
      return this.stepComponents.length || 0
    },

    currentStepComponent () {
      return this.stepComponents[this.currentStep - 1] || null
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.initSteps()
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    initSteps () {
      this.currentStep = 1
      const tabs = this.$refs.currentComponentRef && this.$refs.currentComponentRef.tabs
      const hasTabs = tabs && Array.isArray(tabs)
      if (hasTabs) this.$refs.currentComponentRef.tab = 0
    },

    onLoading (status) {
      this.isLoading = status
    },

    onComplete () {
      this.currentStep++
    },

    onNextClick () {
      if (this.currentStep < this.totalSteps) {
        const tabs = this.$refs.currentComponentRef.tabs
        const hasTabs = tabs && Array.isArray(tabs)

        if (hasTabs) {
          const currentTab = this.$refs.currentComponentRef.tab

          if (currentTab < tabs.length - 1) {
            this.$refs.currentComponentRef.tab = currentTab + 1
          } else {
            this.$refs.currentComponentRef.saveStep()
          }
        } else {
          this.$refs.currentComponentRef.saveStep()
        }
      } else if (this.currentStep === this.totalSteps) {
        this.onCancelButtonClick()
      }
    },

    onPrevClick () {
      if (this.currentStep <= this.totalSteps) {
        const tabs = this.$refs.currentComponentRef.tabs
        const hasTabs = tabs && Array.isArray(tabs)

        if (hasTabs) {
          const currentTab = this.$refs.currentComponentRef.tab

          if (currentTab > 0) {
            this.$refs.currentComponentRef.tab = currentTab - 1
          } else {
            this.currentStep--
          }
        } else {
          this.currentStep--
        }
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
