<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-0">
        <toolbar-period-selector
          v-model="filterDate"
          default-period="month_calendar"
          hide-today
          hide-period
          value-week="week_calendar"
          value-month="month_calendar"
          value-year="year_calendar"
        >
          <v-spacer />

          <span class="mx-2 grey--text text--darken-2 text-body-2">
            {{ $t('Statistics.OrdersHeatmap.Orders', {orders: viewData.length}) }}
          </span>
        </toolbar-period-selector>
      </v-card-title>

      <v-divider />

      <v-container
        class="pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-map
              :map.sync="map"
              :map-center="mapCenter"
              :map-provider="mapProvider"
              :map-styles="false"
              :map-zoom="mapZoom"
              :store-delivery-areas="{
                Circles: restaurantDeliveryAreaCircles,
                Polygons: restaurantDeliveryAreaPolygons
              }"
              show-store-marker
              height="calc(100vh - 142px)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppData                          from '@/mixins/appdata'
import Auth                             from '@/mixins/auth'
import ToolbarPeriodSelector            from '@/components/common/ToolbarPeriodSelector.vue'
import VMap                             from '@/components/common/maps/VMap.vue'
import MapsCommon, { MapsProviderEnum } from '@/mixins/maps/mapsCommon'

export default {
  name      : 'OrdersHeatmap',
  components: {
    VMap,
    ToolbarPeriodSelector
  },
  directives: {},
  mixins    : [AppData, Auth, MapsCommon],
  data () {
    return {
      map                         : null,
      filterDate                  : 'month_calendar',
      viewData                    : [],
      selectDateRangeDialogVisible: false,
      heatmap                     : null,
      mapZoom                     : 15,
      mapCenter                   : {
        lat: window.appConfig.LOCATION_DATA.Lat,
        lng: window.appConfig.LOCATION_DATA.Lng
      }
    }
  },
  computed: {
    MapsProviderEnum () {
      return MapsProviderEnum
    },

    mapProvider () {
      return this.defaultMapsProvider // this.interactiveMapsProvider
    },

    restaurantDeliveryAreaCircles () {
      const locationPolygons = this.appConfig.LOCATION_DATA.DeliveryAreas
      const circles = []

      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas) return circles

      for (const key in locationPolygons) {
        if (locationPolygons.hasOwnProperty(key)) {
          const area = locationPolygons[key]
          if (area.type === 'circle') {
            circles.push(area)
          }
        }
      }
      return circles
    },
    restaurantDeliveryAreaPolygons () {
      const locationPolygons = this.appConfig.LOCATION_DATA.DeliveryAreas
      const polys = []

      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas) return polys

      for (const key in locationPolygons) {
        if (locationPolygons.hasOwnProperty(key)) {
          const area = locationPolygons[key]
          if (area.type === 'shape') {
            polys.push(area)
          }
        }
      }
      return polys
    }
  },
  watch: {
    filterDate () {
      this.getData()
    }
  },
  created () {
    this.$bus.$on(window.SocketCommand.Statistics.OrdersHeatmap.All, this.onGetDataResult)
    this.getData()
  },
  mounted () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Statistics.OrdersHeatmap.All, this.onGetDataResult)
  },
  methods: {
    getData () {
      window.callAS(window.SocketCommand.Statistics.OrdersHeatmap.All, { filter_date: this.filterDate })
    },

    onGetDataResult (response) {
      this.viewData = response || []

      setTimeout(() => {
        this.mapProvider.Name === MapsProviderEnum.Google ? this.initGoogleMap() : this.initMapBoxMap()
      }, 500)
    },

    initMapBoxMap () {
      // const data = this.convertDataToMapBoxPoints()
      // const map = this.map.Instance

      /* map.addSource('trees', {
        type: 'geojson',
        data: './trees.geojson'
      })

      map.addLayer(
        {
          id     : 'trees-heat',
          type   : 'heatmap',
          source : 'trees',
          maxzoom: 15,
          paint  : {
            // increase weight as diameter breast height increases
            'heatmap-weight': {
              property: 'dbh',
              type    : 'exponential',
              stops   : [
                [1, 0],
                [62, 1]
              ]
            },
            // increase intensity as zoom level increases
            'heatmap-intensity': {
              stops: [
                [11, 1],
                [15, 3]
              ]
            },
            // assign color values be applied to points depending on their density
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(236,222,239,0)',
              0.2,
              'rgb(208,209,230)',
              0.4,
              'rgb(166,189,219)',
              0.6,
              'rgb(103,169,207)',
              0.8,
              'rgb(28,144,153)'
            ],
            // increase radius as zoom increases
            'heatmap-radius': {
              stops: [
                [11, 15],
                [15, 20]
              ]
            },
            // decrease opacity to transition into the circle layer
            'heatmap-opacity': {
              default: 1,
              stops  : [
                [14, 1],
                [15, 0]
              ]
            }
          }
        },
        'waterway-label'
      ) */
    },

    initGoogleMap () {
      const data = this.convertDataToGoogleMapPoints()
      const map = this.map.Instance.$mapObject
      const radius = 50
      const orders = this.viewData?.length || 1
      const maxIntensity = orders / radius
      const opacity = 0.75

      if (this.heatmap) {
        this.heatmap.setMap(null)
        this.heatmap = null
      }

      this.heatmap = new window.google.maps.visualization.HeatmapLayer({
        data        : data,
        map         : map,
        radius      : radius,
        maxIntensity: maxIntensity,
        opacity     : opacity
      })

      this.centerZoomGoogleMap()
    },

    centerZoomGoogleMap () {
      const latlngbounds = new window.google.maps.LatLngBounds()

      if (this.appConfig.LOCATION_DATA.HasDeliveryAreas) {
        for (let area = 0; area < this.restaurantDeliveryAreaPolygons.length; area++) {
          for (let i = 0; i < this.restaurantDeliveryAreaPolygons[area].vertices.length; i++) {
            const latlng = new window.google.maps.LatLng(this.restaurantDeliveryAreaPolygons[area].vertices[i].lat, this.restaurantDeliveryAreaPolygons[area].vertices[i].lng)
            this.restaurantDeliveryAreaPolygons[area].vertices[i].latlng = latlng
            latlngbounds.extend(latlng)
          }
        }

        for (let i = 0; i < this.restaurantDeliveryAreaCircles.length; i++) {
          const latlng = new window.google.maps.LatLng(this.restaurantDeliveryAreaCircles[i].circle.center.lat, this.restaurantDeliveryAreaCircles[i].circle.center.lng)
          latlngbounds.extend(latlng)
        }

        this.map.Instance.$mapObject.fitBounds(latlngbounds)
        this.map.Instance.$mapObject.panToBounds(latlngbounds)
      } else {
        latlngbounds.extend(new window.google.maps.LatLng(this.mapCenter.lat, this.mapCenter.lng))

        this.mapZoom = 15
        this.map.Instance.$mapObject.panToBounds(latlngbounds)
      }
    },

    convertDataToMapBoxPoints () {
      return this.viewData.map(p => {
        return {
          location: new window.google.maps.LatLng(p.lat, p.lng),
          weight  : 0
        }
      })
    },

    convertDataToGoogleMapPoints () {
      return this.viewData.map(p => {
        return {
          location: new window.google.maps.LatLng(p.lat, p.lng),
          weight  : p.weight || 1
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
