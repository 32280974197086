import BaseModel            from '../../../lib/data/Models/BaseModel'
import MessageTypeValidator from '../../Validations/messages/MessageTypeValidator'

export default class MessageTypeModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.Id = rawData.Id || -1
    this.GroupId = rawData.GroupId || -1
    this.GroupName = rawData.GroupName || ''
    this.Name = rawData.Name || ''
    this.Color = rawData.Color || 'indigo'
    this.Icon = rawData.Icon || 'email'
    this.Subject = rawData.Subject || ''
    this.Template = rawData.Template || ''
    this.Important = rawData.Important ?? false
    this.Editable = rawData.Editable ?? false

    this.validator = new MessageTypeValidator(this)
  }

  boot () {
    super.boot()

    this.guarded = ['Template']
  }

  /* PROPERTIES */

  /* METHODS */
}
