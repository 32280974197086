<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :overlay="false"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar
          id="cardOrderToolbar"
          class="pl-1 blue-grey darken-3 pa-1"
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Customer.CustomerSearchDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Customer.CustomerSearchDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3 grey--text text--lighten-3">
              {{ $t('Customer.CustomerSearchDialog.Toolbar.Title') }}
            </div>

            <div class="text-caption grey--text  text--lighten-1">
              {{ $t('Customer.CustomerSearchDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            dark
            icon
            @click="isVisible = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title class="py-0 px-1">
          <v-toolbar
            :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
            class="filter-toolbar"
            flat
          >
            <v-btn
              :color="$t('Customer.Table.Button.NewCustomer.Color')"
              class="elevation-0 ma-0"
              text
              @click="showCustomerAddDialog"
            >
              {{ $t('Customer.Table.Button.NewCustomer.Title').toLocaleUpperCase($i18n.locale) }}
            </v-btn>

            <v-spacer />

            <filter-search v-model="dataTable.search.terms" />
          </v-toolbar>
        </v-card-title>

        <v-container
          v-if="viewData"
          class="pa-1"
          fluid
        >
          <v-row dense>
            <v-col cols="12">
              <v-data-table
                :footer-props="dataTable.footerProps"
                :headers="dataTableHeaders"
                :height="dataTable.resize.bodyHeight"
                :items="dataTable.data"
                :loading="dataTable.loading"
                :loading-text="dataTable.text.loadingText"
                :no-data-text="dataTable.text.noDataText"
                :no-results-text="dataTable.text.noResultsText"
                :options.sync="dataTable.options"
                :search="dataTable.search.terms"
                :server-items-length="dataTable.total"
                fixed-header
                item-key="Key"
              >
                <template #[`item.customer_type`]="{item}">
                  <div class="text-no-wrap">
                    <v-tooltip
                      :color="item.Status ? 'light-green darken-1' : 'grey darken-1'"
                      right
                    >
                      <template #activator="{on}">
                        <v-icon
                          :color="item.Status ? 'light-green' : 'grey'"
                          v-on="on"
                        >
                          check_circle
                        </v-icon>
                      </template>
                      <span>{{ item.Status ? $t('Common.Button.Toggle.ActiveM') : $t('Common.Button.Toggle.InactiveM') }}</span>
                    </v-tooltip>

                    <template v-if="getOrderSourceTypeById(item.SourceTypeId)">
                      <v-tooltip right>
                        <template #activator="{on}">
                          <v-avatar
                            class="ml-1"
                            color="white"
                            size="24"
                            v-on="on"
                          >
                            <v-icon
                              v-if="item.SourceTypeId < 100"
                              color="indigo lighten-2"
                            >
                              {{ getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo }}
                            </v-icon>

                            <img
                              v-else
                              :src="getOrderSourceTypeById(item.SourceTypeId) && getOrderSourceTypeById(item.SourceTypeId).logo"
                            >
                          </v-avatar>
                        </template>

                        <span v-if="item.SourceTypeId < 100">
                          {{ getOrderSourceTypeLangById(item.SourceTypeId) && getOrderSourceTypeLangById(item.SourceTypeId).Title }}
                        </span>
                        <span v-else>
                          {{ getOrderSourceTypeById(item.SourceTypeId).title }}
                        </span>
                      </v-tooltip>
                    </template>

                    <v-tooltip
                      :color="getCustomerRegistrationType(item.SocialProvider).Color + ' darken-1'"
                      right
                    >
                      <template #activator="{on}">
                        <v-icon
                          :color="getCustomerRegistrationType(item.SocialProvider).Color"
                          class="ml-1"
                          v-on="on"
                        >
                          {{ getCustomerRegistrationType(item.SocialProvider).Icon }}
                        </v-icon>
                      </template>
                      <span>{{ getCustomerRegistrationType(item.SocialProvider).Name }}</span>
                    </v-tooltip>
                  </div>
                </template>

                <template #[`item.customer_id`]="{item}">
                  {{ item.Id }}
                </template>

                <template #[`item.first_name`]="{item}">
                  {{ item.Firstname }}
                </template>

                <template #[`item.last_name`]="{item}">
                  {{ item.Lastname }}
                </template>

                <template #[`item.address`]="{item}">
                  <template v-for="address in item.Addresses">
                    <div
                      :key="address.Id"
                      class="text-no-wrap"
                    >
                      <v-icon
                        :color="address.IsDefault ? 'light-green lighten-1' : 'grey'"
                        size="14"
                      >
                        location_on
                      </v-icon>
                      {{ getAddressFormatted(address) }}
                    </div>
                  </template>
                </template>

                <template #[`item.groups`]="{item}">
                  <div class="mt-2 mb-1">
                    <template v-for="group in item.Groups">
                      <div :key="group.Id">
                        <v-chip
                          class="text-caption ma-0 mb-1"
                          color="indigo lighten-3"
                          dark
                          label
                          small
                        >
                          {{ group.Name }}
                        </v-chip>
                      </div>
                    </template>
                  </div>
                </template>

                <template #[`item.telephone`]="{item}">
                  <div
                    v-if="item.Telephone && $options.filters.phone(item.Telephone)"
                    class="text-no-wrap"
                  >
                    <v-icon
                      color="blue lighten-2"
                      small
                    >
                      phone
                    </v-icon>
                    {{ item.Telephone | phone }}
                  </div>
                  <div
                    v-if="item.Telephone2 && $options.filters.phone(item.Telephone2)"
                    class="text-no-wrap"
                  >
                    <v-icon
                      color="blue lighten-2"
                      small
                    >
                      phone
                    </v-icon>
                    {{ item.Telephone2 | phone }}<br>
                  </div>
                  <div
                    v-if="item.Telephone3 && $options.filters.phone(item.Telephone3)"
                    class="text-no-wrap"
                  >
                    <v-icon
                      color="blue lighten-2"
                      small
                    >
                      phone
                    </v-icon>
                    {{ item.Telephone3 | phone }}<br>
                  </div>
                </template>

                <template #[`item.date_added`]="{item}">
                  <div class="text-no-wrap">
                    {{ item.DateAdded }}
                  </div>
                </template>

                <template #[`item.actions`]="{item}">
                  <v-btn
                    v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
                    :class="$t('Customer.Table.Button.ViewCustomer.Color')"
                    :small="$vuetify.breakpoint.xsOnly"
                    class="elevation-2 ml-1"
                    dark
                    icon
                    @click="$bus.$emit('show-customer-details-dialog', item.Id)"
                  >
                    <v-icon :small="$vuetify.breakpoint.xsOnly">
                      remove_red_eye
                    </v-icon>
                  </v-btn>

                  <v-btn
                    v-if="item.Status"
                    :class="$t('Customer.Table.Button.NewOrder.Color')"
                    :small="$vuetify.breakpoint.xsOnly"
                    class="elevation-2 ml-1"
                    dark
                    icon
                    @click.native.stop="setCustomer(item)"
                  >
                    <v-icon :small="$vuetify.breakpoint.xsOnly">
                      person_add
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Auth           from '@/mixins/auth'
import AppData        from '@/mixins/appdata'
import Watchable      from '@/mixins/watchable'
import DataTable      from '@/mixins/data-table/dataTable'
import FilterSearch   from '@/components/common/filter/FilterSearch.vue'
import CustomerCommon from '@/mixins/customers/customerCommon'
import SourceType     from '@/mixins/orders/sourceType'

export default {
  components: { FilterSearch },
  directives: {},
  mixins    : [AppData, Auth, Watchable, DataTable, SourceType, CustomerCommon],
  props     : {
    customerSearchDialogVisible: {
      type   : Boolean,
      default: false
    },
    searchFilter: {
      type   : String,
      default: ''
    },
    offset: {
      type   : [String, Number],
      default: 0
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      customerSelectAddressDialogVisible: false,
      debounceSearchTimeout             : null,
      searchString                      : '',
      dataTable                         : {
        dataStore    : 'customers',
        socketCommand: {
          list: window.SocketCommand.Customer.All,
          save: window.SocketCommand.Customer.Save
        },
        resize: {
          offset: this.offset || 0
        },
        options: {
          defaultSortBy: 'customer_id',
          sortBy       : ['customer_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : ['groups', 'date_added'],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Customer.Table.Headers.CustomerType'),
            value    : 'customer_type',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.Id'),
            value    : 'customer_id',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.Firstname'),
            value    : 'first_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.Lastname'),
            value    : 'last_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.Address'),
            value    : 'address',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.Groups'),
            value    : 'groups',
            align    : 'left',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.Phone'),
            value    : 'telephone',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.CreatedAt'),
            value    : 'date_added',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Customer.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption text-no-wrap px-2'
          }
        ]
      }
    }
  },

  computed: {
    isVisible: {
      get () {
        return this.customerSearchDialogVisible
      },

      set (val) {
        if (!val) {
          this.dataTable.search.terms = ''
        } else {
          this.getData()
        }
        this.$emit('update:customerSearchDialogVisible', val)
      }
    }
  },

  watch: {
    isVisible (newVal, oldVal) {
      if (newVal) {
        if (this.searchFilter) {
          this.dataTable.search.terms = String(this.searchFilter)
          this.$emit('update:searchFilter', '')
          window.dispatchEvent(new Event('resize'))
        } else {
          this.getData()
        }
      }
    }
  },

  created () {
    // console.log('1. created');
    this.$bus.$on(window.SocketCommand.Customer.Get + '-voip', this.setCustomerDetails)
  },

  mounted () {
    // console.log('2. mounted');
  },

  updated () {
    // console.log('3. updated');
    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },

  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Customer.Get + '-voip', this.setCustomerDetails)
  },

  methods: {
    showSearchCustomerDialog () {
      this.isVisible = true
    },

    showCustomerAddDialog () {
      this.isVisible = false
      this.$bus.$emit('show-customer-add-dialog')
    },

    setCustomerDetails (details) {
      const customer = details.Info
      customer.Addresses = details.Addresses
      this.posCart.Customer = customer
      this.isVisible = false
    },

    setCustomer (customer) {
      window.callAS(window.SocketCommand.Customer.Get, { id: customer.Id }, window.SocketCommand.Customer.Get + '-voip')
    },

    getAddressFormatted (address) {
      const comma = address.Address || address.Number ? ',' : ''
      const retVal = `${ address.Address } ${ address.Number }${ comma } ${ address.City }`

      return retVal?.trim() || address.Formatted
    }
  }
}
</script>
