<template>
  <v-row class="fill-height align-center justify-center">
    <div
      class="text-center grey--text"
    >
      <v-icon
        class="mb-1"
        color="light-green"
        size="200"
      >
        check_circle
      </v-icon>

      <div
        class="text-h5 ma-2 grey--text text--darken-1"
        style="height: auto;"
      >
        {{ $t('Settings.Wizard.Dialog.InstallationGuide') }}
      </div>
      <div
        class="text-h6"
        style="height: auto;"
      >
        {{ $t('Settings.Wizard.Dialog.Success') }}
      </div>

      <div class="mt-6">
        <v-btn
          :href="`https://help.getfoodpro.com/${$i18n.locale}`"
          block
          class="mb-4"
          color="primary"
          large
          target="_blank"
        >
          {{ $t('Settings.Wizard.Outro.FaqInstructions').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        <v-btn
          :to="{name: 'SystemSettings'}"
          block
          class="mb-4"
          color="primary"
          large
        >
          {{ $t('Settings.Wizard.Outro.MoreSettings').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        <v-btn
          :to="{name: 'KitchenMenu'}"
          block
          class="mb-4"
          color="primary"
          large
        >
          {{ $t('Settings.Wizard.Outro.CategoriesManagement').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        <v-btn
          :to="{name: 'KitchenMenuCategories'}"
          block
          class="mb-4"
          color="primary"
          large
        >
          {{ $t('Settings.Wizard.Outro.ProductsManagement').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
      </div>
    </div>
  </v-row>
</template>

<script>
import AppData from '../../../mixins/appdata'
import Auth    from '../../../mixins/auth'

export default {
  name      : 'SettingsWizardOutro',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    wizardVisible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {
    saveStep () {
      this.$emit('wizard-component-loading', true)
      this.$emit('wizard-component-loading', false)
      this.$emit('wizard-component-complete')
    }
  }
}
</script>

<style scoped>

</style>
