<template>
  <v-row
    v-if="paymentTerminal"
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1"
          height="80"
          max-height="80"
        >
          <v-avatar color="primary">
            <v-icon dark>
              mdi-credit-card-wireless
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Payment.Edit.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Payment.Edit.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="paymentTerminalForm"
            v-model="paymentTerminalFormValid"
            @submit.prevent
          >
            <v-container
              class=""
              fluid
            >
              <v-row>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="paymentTerminal.Nickname"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('Nickname')"
                    label="Nickname"
                    type="text"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <!--
                  <v-radio-group
                    v-model="paymentTerminal.Type"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('Type')"
                    label="Type"
                    class="d-inline-block"
                    mandatory
                    row
                  >
                    <v-radio
                      label="Cloud"
                      value="cloud"
                    />

                    <v-radio
                      label="Local"
                      value="local"
                    />
                  </v-radio-group>
                  -->

                  <div class="d-inline-block ml-4">
                    <p class="grey--text text-caption ma-0">
                      {{ $t('Settings.Payment.ListView.Tips') }}
                    </p>
                    <v-switch
                      v-model="paymentTerminal.HasTip"
                      inset
                      :label="paymentTerminal.HasTip ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                      class="ma-0"
                      color="purple"
                    />
                  </div>
                </v-col>

                <template v-if="paymentTerminal.Type === 'local'">
                  <v-col
                    sm="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="paymentTerminal.Protocol"
                      :rules="paymentTerminal.validator.vuetifyFormFieldRules('Protocol')"
                      label="Protocol"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    sm="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="paymentTerminal.IpAddress"
                      :rules="paymentTerminal.validator.vuetifyFormFieldRules('IpAddress')"
                      label="Local IP"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    sm="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="paymentTerminal.Port"
                      :rules="paymentTerminal.validator.vuetifyFormFieldRules('Port')"
                      label="Port"
                      type="text"
                    />
                  </v-col>
                </template>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="paymentTerminal.TerminalId"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('TerminalId')"
                    label="Terminal Id"
                    type="text"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-if="paymentTerminal.PosVendorId === 1"
                    v-model="paymentTerminal.VirtualTerminalId"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('VirtualTerminalId')"
                    label="Virtual Terminal Id"
                    type="text"
                  />

                  <v-text-field
                    v-else-if="paymentTerminal.PosVendorId === 2"
                    v-model="paymentTerminal.TId"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('TId')"
                    label="TId"
                    type="text"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="paymentTerminal.Model"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('Model')"
                    label="Model"
                    type="text"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="paymentTerminal.Manufactor"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('Manufactor')"
                    label="Manufacturer"
                    type="text"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="paymentTerminal.Connection"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('Connection')"
                    label="Connection"
                    type="text"
                  />
                </v-col>

                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="paymentTerminal.OperationSystem"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('OperationSystem')"
                    label="Operation System"
                    type="text"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="paymentTerminal.SerialNumber"
                    :rules="paymentTerminal.validator.vuetifyFormFieldRules('SerialNumber')"
                    label="Serial Number"
                    type="text"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'TerminalEditDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    paymentTerminal: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      paymentTerminalFormValid: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (newVal) {}
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {
    onSaveButtonClick () {
      this.$refs.paymentTerminalForm.validate()

      if (this.paymentTerminalFormValid) {
        this.$emit('payment-terminal-save', this.paymentTerminal)
      }
    },

    onCancelButtonClick () {
      if (this.$refs.paymentTerminalForm) this.$refs.paymentTerminalForm.resetValidation()
      this.isVisible = false
    }
  }
}
</script>
