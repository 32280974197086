<template>
  <div class="fill-height">
    <v-card flat>
      <v-card-title class="py-0 px-1">
        <v-toolbar
          class="filter-toolbar"
          :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
          flat
        >
          <v-btn
            v-if="userCanAdd"
            :color="$t('Reservation.Table.Button.New.Color')"
            :icon="$vuetify.breakpoint.xsOnly"
            class="elevation-0 ma-1"
            text
            @click="onEditClick(undefined)"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $t('Reservation.Table.Button.New.Icon') }}
            </v-icon>&nbsp;
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Reservation.Table.Button.New.Title').toLocaleUpperCase($i18n.locale) }}</span>
          </v-btn>

          <v-spacer />

          <v-btn
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
            class="pa-0 ma-1 elevation-0"
            @click="setToday"
          >
            <v-icon
              color="primary"
              v-text="'mdi-calendar-today'"
            />
          </v-btn>
          <v-btn
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
            class="pa-0 ma-1 elevation-0"
            @click="prev"
          >
            <v-icon
              color="primary"
              v-text="'mdi-chevron-left'"
            />
          </v-btn>
          <v-btn
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
            class="pa-0 ma-1 elevation-0"
            @click="next"
          >
            <v-icon
              color="primary"
              v-text="'mdi-chevron-right'"
            />
          </v-btn>

          <filter-options
            v-model="filtersModel"
            :filters="filterData"
          />

          <v-btn
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
            :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
            class="pa-0 ma-1 elevation-0"
            @click="onToggleView"
          >
            <v-icon
              color="purple"
              v-text="'mdi-table-clock'"
            />
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-container
        class="ma-0 pa-0"
        fluid
      >
        <v-row>
          <v-col cols="12">
            <v-sheet
              ref="calendarContainer"
              :height="$vuetify.breakpoint.xsOnly ? 'calc(100vh - 174px)' : 'calc(100vh - 142px)'"
            >
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                type="day"
                :event-overlap-threshold="60"
                :locale="$i18n.locale"
                :events="viewData.items"
                :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                :categories="[]"
                category-show-all
                :event-color="getEventColor"
                :interval-width="45"
                :interval-height="70"
                :interval-format="formatCalendarInterval"
                :first-interval="calendarTimeNow()"
                @click:event="(data) => onReservationClick(data.event)"
                @change="getData"
              >
                <template #day-header>
                  <div
                    v-if="$refs.calendar"
                    class="text-center"
                  >
                    {{ $refs.calendar.title }}
                  </div>
                </template>

                <template #day-body>
                  <div
                    class="v-current-time"
                    :style="{top: nowY()}"
                  />
                </template>

                <template #event="{event}">
                  <div class="v-event-draggable px-1">
                    <span>
                      <v-icon
                        dark
                        small
                      >
                        people
                      </v-icon>
                      {{ event.reservation.Persons }}
                    </span>

                    <strong>{{ event.name }}</strong>

                    <br>

                    {{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }}
                    <span class="text-caption ml-1">({{ convertMinutesToDisplayString(event.reservation.Duration, false, true) }})</span>
                  </div>
                </template>
              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <reservation-menu-dialog
      :visible.sync="reservationMenuDialogVisible"
      :calendar-event="selectedCalendarEvent"
    />
  </div>
</template>

<script>
import AppData               from '@/mixins/appdata'
import Auth                  from '@/mixins/auth'
import OrderTypeTime         from '@/mixins/orders/orderTypeTime'
import ReservationTags       from '@/mixins/reservation/reservationTags'
import ReservationStatus     from '@/mixins/reservation/reservationStatus'
import SourceType            from '@/mixins/orders/sourceType'
import ReservationCommon     from '@/mixins/reservation/reservationCommon'
import FilterOptions         from '@/components/common/filter/FilterOptions.vue'
import FilterCommon          from '@/mixins/filter/filterCommon'
import ReservationMenuDialog from '@/components/restaurant/reservations/ReservationMenuDialog.vue'

export default {
  name      : 'TableReservationCalendarView',
  components: {
    ReservationMenuDialog,
    FilterOptions
  },
  directives: {},
  mixins    : [AppData, Auth, FilterCommon, SourceType, OrderTypeTime, ReservationCommon, ReservationStatus, ReservationTags],
  props     : {
    view: {
      type   : String,
      default: 'Table'
    }
  },
  data () {
    return {
      focus   : '',
      viewData: {
        count: 0,
        items: []
      },
      reservationMenuDialogVisible: false,
      selectedCalendarEvent       : null,
      filtersModel                : {
        lang           : 'Reservation.Table',
        filterWithTable: {
          key  : 'filter_with_table',
          value: null,
          items: this.$t('Reservation.Table.Filter.filterWithTable.Items')
        },
        filterStatus: {
          key  : 'filter_status',
          value: null,
          items: this.$t('Reservation.Status.Items')
        },
        filterTable: {
          key  : 'filter_table',
          value: [],
          items: []
        }
      }
    }
  },
  computed: {
    toggleView: {
      get () {
        return this.view || ''
      },
      set (val) {
        this.$emit('update:view', val)
      }
    },

    tables () {
      const areas = this.$DataStore.tables || []
      const tables = []

      areas.forEach(area => {
        area.Tables.forEach(table => {
          const tbl = {
            Id            : table.Id,
            Name          : `${ area.Name } #${ table.Name }`,
            Description   : '',
            Bookable      : table.Bookable,
            BookableOnline: table.BookableOnline,
            MinCapacity   : table.MinCapacity,
            MaxCapacity   : table.MaxCapacity,
            Area          : {
              Id   : area.Id,
              Name : area.Name,
              Color: area.Color
            },
            Avatar: {
              Color: area.Color ? `#${ area.Color }` : ''
            }
          }
          tables.push(tbl)
        })
      })

      return tables
    }
  },
  watch: {
    '$DataStore.tables': function () {
      this.filtersModel.filterTable.items = this.tables
    },

    filtersModel: {
      handler () {
        this.getData()
      },
      deep: true
    }
  },
  beforeCreate () {},
  created () {
    // Init Custom Filter Values
    this.filtersModel.filterTable.items = this.tables
  },
  beforeMount () {},
  mounted () {
    this.$bus.$on(window.SocketCommand.Table.Reservation.All, this.setData)
    this.$bus.$on(window.SocketCommand.Table.Reservation.Cancel, this.getData)
    this.$bus.$on(window.SocketCommand.Table.Reservation.Delete, this.getData)
    this.$bus.$on(window.SocketCommand.Table.Reservation.Status, this.getData)
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Table.Reservation.All, this.setData)
    this.$bus.$off(window.SocketCommand.Table.Reservation.Cancel, this.getData)
    this.$bus.$off(window.SocketCommand.Table.Reservation.Delete, this.getData)
    this.$bus.$off(window.SocketCommand.Table.Reservation.Status, this.getData)
  },
  destroyed () {},
  methods: {
    onToggleView () {
      this.toggleView = this.toggleView === 'Table' ? 'Calendar' : 'Table'
    },

    onReservationClick (calendarEvent) {
      this.selectedCalendarEvent = calendarEvent
      this.reservationMenuDialogVisible = true
    },

    onEditClick (item) {
      this.$bus.$emit('show-table-reservation-dialog', { Reservation: item })
    },

    getEventColor (event) {
      return event.color || 'primary'
    },

    setToday () {
      this.focus = ''
      this.scrollContainerTop()
    },

    prev () {
      if (!this.$refs.calendar) return

      this.$refs.calendar.prev()
      this.scrollContainerTop()
    },

    next () {
      if (!this.$refs.calendar) return

      this.$refs.calendar.next()
      this.scrollContainerTop()
    },

    scrollContainerTop () {
      const container = this.$refs.calendar.$el.getElementsByClassName('v-calendar-daily__scroll-area')[0]
      if (container) container.scrollTop = 0
    },

    getReservationName (reservation) {
      if (!reservation) return ''

      const tables = reservation.Tables?.map(table => table.Area.Name + ' #' + table.Name)?.join(', ') || ''

      return `${ tables ? ' - ' + tables : '' }`
    },

    getReservationDuration (reservation) {
      if (!reservation) return null

      const [day, month, year] = reservation.ReserveDate.split('/')
      const start = `${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') } ${ reservation.ReserveTime }:00`

      const date = new Date(`${ year }-${ String(month).padStart(2, '0') }-${ String(day).padStart(2, '0') }T${ reservation.ReserveTime }:00.000Z`)
      const durationToAdd = (reservation.Duration) * 60 * 1000
      date.setTime(date.getTime() + durationToAdd)

      return {
        start: start,
        end  : date.toISOString().slice(0, 19).replace('T', ' ')
      }
    },

    formatCalendarInterval (data) {
      const date = new Date()
      date.setHours(data.hour, data.minute)
      return date.toLocaleTimeString(this.$i18n.locale, {
        hour  : '2-digit',
        minute: '2-digit',
        hour12: false
      })
    },

    formatEventTime (date) {
      return new Date(date).toLocaleTimeString(this.$i18n.locale, {
        hour  : '2-digit',
        minute: '2-digit',
        hour12: false
      })
    },

    calendarTimeNow () {
      const hourNow = this.$refs?.calendar?.times?.now?.hour || 0
      return hourNow > 3 ? hourNow - 3 : hourNow
    },

    nowY () {
      return this.$refs?.calendar?.timeToY(this.$refs?.calendar?.times?.now) + 'px'
    },

    setData (data) {
      if (!data) return

      const payload = {
        count: data?.count ?? 0,
        items: data?.items ?? []
      }

      payload.items = payload.items.map(reservation => {
        return {
          name       : this.getReservationName(reservation),
          start      : this.getReservationDuration(reservation).start,
          end        : this.getReservationDuration(reservation).end,
          color      : this.getReservationStatusById(reservation.StatusId)?.Avatar?.Color || 'primary',
          reservation: reservation,
          timed      : true
        }
      })

      this.viewData = payload
    },

    parseFilterDate (date) {
      const [year, month, day] = date.split('-')
      return `${ day }-${ month }-${ year }`
    },

    getData (data) {
      const filterDateRaw = data?.start?.date || this.$refs?.calendar?.value || this.$refs?.calendar?.times?.today?.date || null
      const filterDate = filterDateRaw ? this.parseFilterDate(filterDateRaw) : null
      window.callAS(window.SocketCommand.Table.Reservation.All, {
        page             : 1,
        page_limit       : 100,
        sort_by          : 'reservation_id',
        order_by         : 'DESC',
        filter_date      : filterDate,
        filter_with_table: this.filtersModel.filterWithTable.value,
        filter_status    : this.filtersModel.filterStatus.value,
        filter_table     : this.filtersModel.filterTable.value
      })
    }
  }
}
</script>

<style scoped>
/deep/ .v-current-time {
  height           : 2px;
  background-color : #ea4335;
  position         : absolute;
  left             : -1px;
  right            : 0;
  pointer-events   : none;
}

/deep/ .v-current-time::before {
  content          : '';
  position         : absolute;
  background-color : #ea4335;
  width            : 12px;
  height           : 12px;
  border-radius    : 50%;
  margin-top       : -5px;
  margin-left      : -6.5px;
}

/deep/ .v-calendar-daily_head-weekday {
  font-size : 20px;
}

/deep/ .v-calendar-daily_head-day-label .v-btn__content {
  font-size : 26px;
}
</style>
