import BaseModel              from '../../../lib/data/Models/BaseModel'
import SupportDepartmentModel from '@/api/Models/support-center/SupportDepartmentModel'
import SupportPriorityModel   from '@/api/Models/support-center/SupportPriorityModel'
import SupportServiceModel    from '@/api/Models/support-center/SupportServiceModel'
import SupportStatusModel     from '@/api/Models/support-center/SupportStatusModel'
import { EventBus }           from '@/events/eventBus'
import SocketCommand          from '@/api/SocketCommand'

export default class SupportTicketModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.ticketid = parseInt(rawData.ticketid) || 0
    this.ticketkey = rawData.ticketkey || ''
    this.subject = rawData.subject || ''
    this.message = rawData.message || ''
    this.userid = parseInt(rawData.userid) || 0
    this.contactid = parseInt(rawData.contactid) || 0
    this.email = rawData.email || ''
    this.name = rawData.name || ''

    this.department = new SupportDepartmentModel({
      departmentid: parseInt(rawData.departmentid) || parseInt(rawData.department) || 0,
      name        : rawData.department_name || ''
    })

    this.priority = new SupportPriorityModel({
      priorityid: parseInt(rawData.priorityid) || parseInt(rawData.priority) || 0,
      name      : rawData.priority_name || ''
    })

    this.status = new SupportStatusModel({
      ticketstatusid: parseInt(rawData.ticketstatusid) || parseInt(rawData.status) || 0,
      name          : rawData.status_name || '',
      color         : rawData.statuscolor || ''
    })

    this.service = new SupportServiceModel({
      serviceid: parseInt(rawData.serviceid) || parseInt(rawData.service) || 0,
      name     : rawData.service_name || ''
    })

    this.replies_count = rawData.replies_count || 0
    this.replies = rawData.replies || !!this.replies_count ? null : []

    this.date = rawData.date || ''
    this.time = rawData.time || ''
    this.lastreply = rawData.lastreply || ''
    this.clientread = Boolean(parseInt(rawData.clientread)) || 0
  }

  boot () {

  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */

  getReplies () {
    EventBus.$once(SocketCommand.SupportCenter.Ticket.GetReplies, resultData => this.onGetReplies(resultData))
    window.callAS(SocketCommand.SupportCenter.Ticket.GetReplies, { id: this.ticketid })
  }

  onGetReplies (result) {
    if (result && result.data) {
      this.replies = result.data || []
      this.replies_count = result.data.length || 0
    }
  }

  markAsRead () {
    EventBus.$once(SocketCommand.SupportCenter.Ticket.ReadUpdate, resultData => this.onMarkAsRead(resultData))
    window.callAS(SocketCommand.SupportCenter.Ticket.ReadUpdate, { id: this.ticketid })
  }

  onMarkAsRead (result) {
    this.clientread = 1
  }
}
