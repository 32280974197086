import { clone } from '@/lib/helper/helper'

export default {
  data () {
    return {}
  },

  props: {
    asc: {
      type   : Boolean,
      default: false
    },
    desc: {
      type   : Boolean,
      default: false
    }
  },

  computed: {
    tableTransactions () {
      return this.viewData?.PaymentTransactions || []
    },

    paymentData () {
      const data = clone(this.viewData?.Payments || [])

      if (this.asc) return data

      return data.sort((a, b) => parseInt(b.Timestamp) - parseInt(a.Timestamp))
    }
  },

  methods: {
    getPaymentTransactions (payment) {
      return payment?.Transactions || []
    },

    sumPaymentTransactions (payment) {
      return this.getPaymentTransactions(payment)?.reduce((a, b) => a + parseFloat(b.Amount), 0) || 0
    },

    sumPaymentsTransactions () {
      let total = 0
      this.paymentData.forEach(payment => {
        total = total + this.sumPaymentTransactions(payment)
      })

      return parseFloat(total.toFixed(2))
    },

    getPaymentItems (payment, ungrouped = false, singleQuantity = false) {
      const items = payment?.Items || []

      if (ungrouped) return items

      const itemsByTableSeat = this.groupPaymentItemsByTableSeat(items)
      itemsByTableSeat.forEach(tableSeat => {
        tableSeat.Items = this.groupPaymentItemsByCategory(tableSeat.Items, singleQuantity)
      })

      return itemsByTableSeat
    },

    getMenuSelectedOptionsString (menu) {
      let menuArr = menu.Options?.filter(element => element.Selected && element.DisplayType !== 'unit' && element.DisplayType !== 'extra_charges')?.map(element => this.getTranslatedField(element, 'Name'))
      if (menu.Comment) menuArr.push(menu.Comment)
      const menuString = menuArr.join(', ')
      menuArr = null
      return menuString
    },

    groupPaymentItemsByTableSeat (cartItems) {
      const items = cartItems?.reduce(function (groups, item) {
        const val = item?.Custom?.Seat || 0
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {}) || []

      let prop
      const arr = []
      for (prop in items) {
        if (items.hasOwnProperty(prop)) {
          arr.push({
            Seat : parseInt(prop),
            Name : parseInt(prop) ? `${ this.$tc('Restaurant.Tables.Seat.Title', 1) } ${ prop }` : this.$tc('Restaurant.Tables.Title', 1),
            Items: items[prop]
          })
        }
      }
      arr.sort((a, b) => { return a.Seat - b.Seat })

      return arr
    },

    groupPaymentItemsByCategory (cartItems, singleQuantity = false) {
      const items = cartItems?.reduce(function (groups, item) {
        const val = item.CategoryId
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {}) || []

      let prop
      const arr = []
      for (prop in items) {
        if (items.hasOwnProperty(prop)) {
          arr.push({
            CategoryId      : parseInt(prop),
            CategoryPriority: parseInt(items[prop][0].CategoryPriority),
            CategoryName    : items[prop][0].CategoryName,
            Items           : items[prop]
          })
        }
      }
      arr.sort((a, b) => { return a.CategoryPriority - b.CategoryPriority })

      if (singleQuantity) {
        arr.forEach(category => {
          const categoryItems = []
          category.Items.forEach(item => {
            if (this.menuItemHasCoupon(item) || item.PaymentQuantity === 1) {
              const itemObj = JSON.parse(JSON.stringify(item))
              itemObj.Quantity = item.PaymentQuantity
              itemObj.HashId = itemObj.RowId
              categoryItems.push(itemObj)
            } else {
              for (let i = 0; i < item.PaymentQuantity; i++) {
                const itemObj = JSON.parse(JSON.stringify(item))
                itemObj.Quantity = itemObj.PaymentQuantity = 1
                itemObj.HashId = itemObj.RowId + '-' + i
                categoryItems.push(itemObj)
              }
            }
          })
          category.Items = categoryItems
        })
      }

      return arr
    },

    menuItemPriceTotal (menuItem, calculateCoupons = false) {
      let quantity = parseInt(menuItem.PaymentQuantity)
      if (menuItem.UnitId > 1) quantity = 1
      const couponAmount = calculateCoupons ? (this.getMenuItemCoupon(menuItem)?.Amount || 0) : 0
      const menuItemPrice = (parseFloat(menuItem.Price) * quantity) - couponAmount
      return menuItemPrice
    },

    cartOrderPriceTotal (cart, calculateCoupons = false) {
      const items = this.getOrderMenuItems(cart)
      return items?.reduce((value, menuItem) => value + this.menuItemPriceTotal(menuItem, calculateCoupons), 0) || 0
    },

    menuItemHasCoupon (item) {
      return !!this.getMenuItemCoupon(item)
    },

    getMenuItemCoupon (item) {
      return item?.Coupon || null
    },

    getPaymentCouponsTotal (payment) {
      const itemsTotalWithCoupons = payment?.Items?.reduce((value, menuItem) => value + this.menuItemPriceTotal(menuItem, true), 0) || 0
      const itemsTotalWithoutCoupons = payment?.Items?.reduce((value, menuItem) => value + this.menuItemPriceTotal(menuItem, false), 0) || 0
      const menuItemsCouponsTotal = parseFloat((itemsTotalWithoutCoupons - itemsTotalWithCoupons).toFixed(2))
      const tableCouponsTotal = this.viewData?.TableTotalsUsed?.find(total => total.Used === payment.Timestamp)?.Amount || 0
      return parseFloat((tableCouponsTotal + menuItemsCouponsTotal).toFixed(2))
    }

  }
}
