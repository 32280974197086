import googleTranslate from '@/lib/google-translate/googleTranslate'

export default {
  data () {
    return {}
  },

  methods: {
    /**
     * translateField
     *
     * @param field {String || Array<String>}
     * @param item {Object}
     * @param localeTo {String}
     * @param localeFrom {String}
     * @returns {Promise<Boolean>}
     */
    async translateField (field, item, localeTo, localeFrom) {
      const localeToExists = !!this.selectedLanguages.find(lang => lang.Code === localeTo)
      if (!field || (Array.isArray(field) && !field?.length) || !localeToExists || localeFrom === localeTo || !item?.Lang?.hasOwnProperty(localeTo) || !item?.Lang?.hasOwnProperty(localeFrom)) return false

      const fields = Array.isArray(field) ? field : [field]
      const fieldsTexts = []

      fields.forEach(field => {
        const text = item.Lang[localeFrom][field]
        if (text?.trim()) fieldsTexts.push(text.trim())
      })
      if (!fieldsTexts?.length) return false

      item.loadingTranslation = true
      const result = await googleTranslate(fieldsTexts, localeTo, localeFrom)
      item.loadingTranslation = false
      if (!result?.length) return false

      fields.forEach((field, index) => {
        if (result[index]?.translatedText) item.Lang[localeTo][field] = result[index].translatedText
      })

      item.Lang[localeTo].Slug = `${ item.Lang[localeFrom].Slug }_${ localeTo }`

      return true
    }
  }
}
