export default {
  Title: {
    Username : 'Type your username',
    PinPass  : 'Type your personal PIN',
    AdminPass: 'Type administrator password'
  },
  Field: {
    Username: {
      Label: 'Username',
      Hint : 'Email or UID',
      Icon : 'person_pin',
      Error: {
        Empty: 'Insert username',
        Min  : 'Username must be at least 8 characters long'
      }
    },
    AdminPass: {
      Label: 'Administrator password',
      Icon : 'password'
    },
    RememberMe: {
      Label: 'Remember me'
    },
    Result: {
      WrongUsernamePin: 'Wrong username or PIN',
      WrongPin        : 'Wrong PIN Code',
      WrongPassword   : 'Wrong password'
    }
  },
  StaffGroup: {
    11 : 'Super Admin',
    12 : 'GFP Editor',
    13 : 'CRM Editor',
    100: 'Owner',
    101: 'Cashier',
    102: 'Waiter',
    103: 'User'
  }
}
