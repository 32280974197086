<template>
  <div v-if="isVisible">
    <v-dialog
      v-model="isVisible"
      fullscreen
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="60"
          extension-height="58"
          max-height="118"
        >
          <v-avatar color="primary">
            <v-icon dark>
              tag
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Website.Field.Gallery.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Website.Field.Gallery.SubTitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs
              v-model="tab"
              style="width: calc(100% - 128px)"
            >
              <v-tab
                v-if="!excludedTabs.includes('category')"
                :tab-value="'category'"
              >
                <v-badge
                  color="primary"
                  inline
                >
                  <span slot="badge">
                    {{ viewData.category.length }}
                  </span>

                  {{ $t('Settings.Website.Field.Gallery.Tabs.Category').toLocaleUpperCase($i18n.locale) }}
                </v-badge>
              </v-tab>

              <v-tab
                v-if="!excludedTabs.includes('product')"
                :tab-value="'product'"
              >
                <v-badge
                  color="primary"
                  inline
                >
                  <span slot="badge">
                    {{ viewData.product.length }}
                  </span>

                  {{ $t('Settings.Website.Field.Gallery.Tabs.Product').toLocaleUpperCase($i18n.locale) }}
                </v-badge>
              </v-tab>

              <v-tab
                v-if="!excludedTabs.includes('other')"
                :tab-value="'other'"
              >
                <v-badge
                  color="primary"
                  inline
                >
                  <span slot="badge">
                    {{ viewData.other.length }}
                  </span>

                  {{ $t('Settings.Website.Field.Gallery.Tabs.Other').toLocaleUpperCase($i18n.locale) }}
                </v-badge>
              </v-tab>
            </v-tabs>

            <v-spacer />

            <filter-search v-model="search" />

            <filter-options
              v-model="filtersModel"
              :disabled="tab === 'other'"
              :filters="filterData"
            />

            <v-btn
              :color="$vuetify.theme.dark ? '#1E1E1E' : 'rgba(0, 0, 0, 0.06)'"
              :style="$vuetify.breakpoint.xsOnly ? 'width: 36px; min-width: 36px; height: 36px;' : 'width: 48px; min-width: 48px; height: 48px;'"
              class="pa-0 ma-1 elevation-0"
              @click="toggleSort"
            >
              <v-icon
                v-if="sort === 'DESC'"
                color="primary"
              >
                mdi-sort-calendar-ascending
              </v-icon>
              <v-icon
                v-else
                color="primary"
              >
                mdi-sort-calendar-descending
              </v-icon>
            </v-btn>
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text
          ref="cardText"
          class="pa-2"
        >
          <v-container
            class=""
            fluid
          >
            <v-item-group
              v-model="selected"
              :multiple="multiple"
              :max="max"
            >
              <v-row>
                <template v-for="(item, index) in paginatedViewData">
                  <v-col
                    :key="index"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="2"
                  >
                    <v-lazy
                      :options="{threshold: .5}"
                      transition="fade-transition"
                    >
                      <v-item
                        v-slot="{active, toggle}"
                        :value="item"
                      >
                        <v-hover
                          v-slot="{hover}"
                          open-delay="100"
                        >
                          <v-card
                            outlined
                            @click="tab === 'other' && mode === 'delete' ? toggleDelete(item) : toggle(item)"
                          >
                            <v-img
                              :key="item.Filename"
                              :src="item.Image"
                              :height="active || toDeleteHasItem(item) ? 125-16 : 125"
                              class="text-right"
                              :class="{'ma-2': active || toDeleteHasItem(item)}"
                            >
                              <template #placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    :width="2"
                                    color="grey lighten-2"
                                    indeterminate
                                  />
                                </v-row>
                              </template>

                              <v-fade-transition>
                                <v-btn
                                  v-if="tab === 'other' && mode === 'delete'"
                                  v-show="hover || toDeleteHasItem(item)"
                                  icon
                                  small
                                  class="ma-1 white elevation-2"
                                  color="red"
                                  dark
                                >
                                  <v-icon>
                                    {{ toDeleteHasItem(item) ? 'mdi-close-circle' : 'mdi-checkbox-blank-circle-outline' }}
                                  </v-icon>
                                </v-btn>
                              </v-fade-transition>

                              <v-fade-transition>
                                <v-btn
                                  v-if="tab !== 'other' || mode === 'select'"
                                  v-show="hover || active"
                                  icon
                                  small
                                  class="ma-1 white elevation-1"
                                  color="green"
                                  dark
                                >
                                  <v-icon>
                                    {{ active ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                                  </v-icon>
                                </v-btn>
                              </v-fade-transition>
                            </v-img>

                            <template v-if="item.Name">
                              <v-divider />

                              <v-card-title class="text-subtitle-2 pa-2 white">
                                {{ getTranslatedField(item, 'Name') }}
                              </v-card-title>
                            </template>
                          </v-card>
                        </v-hover>
                      </v-item>
                    </v-lazy>
                  </v-col>
                </template>
              </v-row>
            </v-item-group>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-2 px-0 py-sm-2 px-sm-4">
          <v-pagination
            v-model="page"
            :length="totalPages"
            :total-visible="$vuetify.breakpoint.xsOnly ? 0 : 7"
          />

          <v-spacer />

          <v-btn-toggle
            v-if="tab === 'other'"
            v-model="mode"
            :color="mode === 'select' ? 'blue' : 'red'"
            class="mr-1"
            mandatory
            dense
          >
            <v-btn value="delete">
              <v-icon color="red">
                mdi-image-remove
              </v-icon>
            </v-btn>

            <v-btn value="select">
              <v-icon color="blue">
                mdi-image-check
              </v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle
            v-if="tab ==='other' && mode === 'delete' && userCanDelete"
            :disabled="!toDelete.length || loading"
            class="ma-0 pa-0 mr-1"
            active-class=""
            dense
          >
            <v-btn
              :disabled="!toDelete.length || loading"
              :loading="loading"
              class="white--text"
              color="red"
              depressed
              style="min-width: 90px;"
              @click="onDeleteButtonClick"
            >
              {{ $t('Common.Button.Delete').toLocaleUpperCase($i18n.locale) }}

              <template v-if="totalSelectedForDelete > 1">
                ({{ totalSelectedForDelete }})
              </template>
            </v-btn>

            <v-btn
              :disabled="!toDelete.length || loading"
              class="white--text"
              color="grey darken-1"
              depressed
              style="max-width: 48px;"
              @click="toDelete = []"
            >
              <v-icon color="white">
                mdi-arrow-u-left-top
              </v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle
            v-if="tab !== 'other' || mode === 'select'"
            :disabled="!hasSelectedImage"
            class="ma-0 pa-0 mr-1"
            active-class=""
            dense
          >
            <v-btn
              :disabled="!hasSelectedImage"
              class="white--text"
              color="green"
              depressed
              style="min-width: 90px;"
              @click="onSaveButtonClick"
            >
              {{ $t('Common.Button.Ok').toLocaleUpperCase($i18n.locale) }}

              <template v-if="totalSelected > 1">
                ({{ totalSelected }})
              </template>
            </v-btn>

            <v-btn
              :disabled="!hasSelectedImage"
              class="white--text"
              color="grey darken-1"
              depressed
              style="max-width: 48px;"
              @click="selected = null"
            >
              <v-icon color="white">
                mdi-arrow-u-left-top
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      :html-content="$tc('Settings.Website.Field.Gallery.Image.DeleteConfirmDialog.Content', totalSelectedForDelete, {count: totalSelectedForDelete})"
      :html-title="$tc('Settings.Website.Field.Gallery.Image.DeleteConfirmDialog.Title', totalSelectedForDelete, {count: totalSelectedForDelete})"
      :info-bar-body="$t('Settings.Website.Field.Gallery.Image.DeleteConfirmDialog.Notification')"
      :info-bar="true"
      :visible.sync="confirmDialogDeleteVisible"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </div>
</template>

<script>
import FilterSearch  from '@/components/common/filter/FilterSearch.vue'
import FilterOptions from '@/components/common/filter/FilterOptions.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import FilterCommon  from '@/mixins/filter/filterCommon'
import Translatable  from '@/mixins/translatable'
import greekUtils    from '@/lib/greek-utils'
import Auth          from '@/mixins/auth'

export default {
  name      : 'SelectImageDialog',
  components: {
    ConfirmDialog,
    FilterOptions,
    FilterSearch
  },
  mixins: [Auth, Translatable, FilterCommon],
  props : {
    multiple: {
      type   : Boolean,
      default: false
    },

    max: {
      type   : [String, Number],
      default: undefined
    },

    excludedTabs: {
      type   : Array,
      default: () => []
    },

    defaultSelectedTab: {
      type   : String,
      default: 'category'
    },

    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      tmpData                   : null,
      tmpCat                    : null,
      page                      : 1,
      perPage                   : 30,
      loading                   : false,
      confirmDialogDeleteVisible: false,
      selected                  : null,
      mode                      : 'select',
      sort                      : 'DESC',
      search                    : '',
      tab                       : 'category',
      categories                : [],
      toDelete                  : [],
      viewData                  : {
        category: [],
        product : [],
        other   : []
      },
      filtersModel: {
        lang          : 'Restaurant.Menu',
        filterCategory: {
          key     : 'filter_category',
          disabled: true,
          value   : null,
          items   : []
        },
        filterCatalog: {
          key     : 'filter_catalog',
          disabled: false,
          value   : [],
          items   : []
        }
      }
    }
  },
  computed: {
    catalogs () {
      return this.appConfig?.LOCATION_DATA?.Catalogs || []
    },

    hasSelectedImage () {
      return this.multiple ? Array.isArray(this.selected) && this.selected.length : !!this.selected
    },

    totalPages () {
      return Math.ceil(this.tabViewData.length / this.perPage)
    },

    tabViewData () {
      const data = this.viewData[this.tab] || []
      const searchString = greekUtils.toGreeklish(String(this.search).toLocaleLowerCase(this.$i18n.locale))
      const getItemString = (item) => {
        const fileName = item.Filename ? greekUtils.toGreeklish(item.Filename.toLocaleLowerCase(this.$i18n.locale)) : ''
        const itemName = item.Name ? greekUtils.toGreeklish(item.Name.toLocaleLowerCase(this.$i18n.locale)) : ''
        const localeName = this.getTranslatedField(item, 'Name') ? greekUtils.toGreeklish(this.getTranslatedField(item, 'Name').toLocaleLowerCase(this.$i18n.locale)) : ''

        return `${ fileName } ${ itemName } ${ localeName }`
      }

      return data
        .filter(item => searchString ? getItemString(item).includes(searchString) : true)
        .filter(item => this.filterPayload?.filter_catalog?.length && item.CatalogId ? this.filterPayload.filter_catalog.some(val => item.CatalogId.includes(val)) : true)
        .filter(item => this.filterPayload?.filter_category && item.CategoryId ? parseInt(item.CategoryId) === parseInt(this.filterPayload.filter_category) : true)
        .sort((a, b) => this.sort === 'ASC' ? a.Timestamp - b.Timestamp : b.Timestamp - a.Timestamp)
    },

    paginatedViewData () {
      return this.tabViewData.slice((this.page - 1) * this.perPage, this.page * this.perPage)
    },

    categoriesFilterData () {
      return this.categories
    },

    catalogsFilterData () {
      return this.catalogs.map(o => {
        o.Avatar = { Color: `#${ o.Color }` }
        return o
      })
    },

    totalSelected () {
      return Array.isArray(this.selected) ? this.selected?.length || 0 : this.selected ? 1 : 0
    },

    totalSelectedForDelete () {
      return this.toDelete?.length || 0
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    'filtersModel.filterCatalog.value': function (newVal) {
      this.filtersModel.filterCategory.items = this.categoriesFilterData.filter(category => newVal?.length ? !!category.Catalogs.find(catalog => newVal.includes(catalog)) : true)
    },

    mode () {
      this.selected = null
      this.toDelete = []
    },

    tab (newVal) {
      this.mode = 'select'
      this.selected = null
      this.toDelete = []
      this.page = 1

      if (this.$refs.cardText) this.$refs.cardText.scrollTop = 0

      if (newVal === 'category') {
        this.perPage = 24
        this.filtersModel.filterCatalog.disabled = false
        this.filtersModel.filterCategory.disabled = true
      } else if (newVal === 'product') {
        this.perPage = 24
        this.filtersModel.filterCatalog.disabled = false
        this.filtersModel.filterCategory.disabled = false
      } else {
        this.perPage = 30
        this.filtersModel.filterCatalog.disabled = true
        this.filtersModel.filterCategory.disabled = true
      }
    },

    page () {
      if (this.$refs.cardText) this.$refs.cardText.scrollTop = 0
    },

    isVisible (newVal) {
      if (newVal) {
        this.initData()
        this.getData()
      }
    }
  },
  created () {
    this.initData()

    this.$bus.$on(window.SocketCommand.Gallery.All, this.setGalleryData)
    this.$bus.$on(window.SocketCommand.Category.AllFilter, this.setCategoriesData)
    this.$bus.$on(window.SocketCommand.Gallery.Delete, this.onDeleteResult)

    if (!this.tmpData) this.getData()
    if (!this.tmpCat) this.getCategoryData()

    // Init Custom Filter Values
    this.filtersModel.filterCategory.items = this.categoriesFilterData
    this.filtersModel.filterCatalog.items = this.catalogsFilterData
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Gallery.All, this.setGalleryData)
    this.$bus.$off(window.SocketCommand.Category.AllFilter, this.setCategoriesData)
    this.$bus.$off(window.SocketCommand.Gallery.Delete, this.onDeleteResult)
  },
  methods: {
    toggleSort () {
      if (this.sort === 'DESC') {
        this.sort = 'ASC'
      } else {
        this.sort = 'DESC'
      }
    },

    toggleDelete (item) {
      const idx = this.toDelete.findIndex(obj => obj.Filename === item.Filename)
      if (idx > -1) {
        this.toDelete.splice(idx, 1)
      } else {
        this.toDelete.push(item)
      }
    },

    toDeleteHasItem (item) {
      return !!this.toDelete.find(obj => obj.Filename === item.Filename)
    },

    getData () {
      window.callAS(window.SocketCommand.Gallery.All)
    },

    setGalleryData (response) {
      if (response) {
        this.tmpData = response
        this.viewData.category = response.category || []
        this.viewData.product = response.product || []
        this.viewData.other = response.other || []
      }
    },

    getCategoryData () {
      window.callAS(window.SocketCommand.Category.All, {
        config    : 0,
        page_limit: -1
      }, window.SocketCommand.Category.AllFilter)
    },

    setCategoriesData (data) {
      this.tmpCat = data
      const retVal = (data?.items || []).map((o) => {
        if (o.Image) {
          o.Avatar = {
            Img: `${ this.appConfig.LOCATION_DATA.CdnImagesUrl }${ o.Image }`
          }
        } else {
          o.Avatar = {
            Icon : 'category',
            Color: 'grey'
          }
        }
        const activeCatalogName = this.catalogs.filter(catalog => o.Catalogs.includes(catalog.Id))?.map(o => o.Name)?.join(', ') || ''
        o.Description = activeCatalogName
        return o
      })

      this.categories = retVal || []
      this.filtersModel.filterCategory.items = this.categoriesFilterData
    },

    onDeleteButtonClick () {
      if (!this.toDelete?.length) return

      this.confirmDialogDeleteVisible = true
    },
    onConfirmDelete () {
      this.loading = true
      const payload = JSON.parse(JSON.stringify(this.toDelete))
      window.callAS(window.SocketCommand.Gallery.Delete, { Images: payload.map(item => item.Filename) })
      this.$emit('image:delete', payload)
    },
    onDeleteResult (response, payload) {
      this.loading = false

      if (response.status === 'success') {
        this.mode = 'select'
        this.$emit('image:deleted', payload)
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }

      this.toDelete = []
      this.getData()
    },

    onSaveButtonClick () {
      if (!this.selected) return

      const payload = JSON.parse(JSON.stringify(this.selected))

      this.$emit('image:save', payload)
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.initData()
    },

    initData () {
      this.loading = false
      this.mode = 'select'
      this.sort = 'DESC'
      this.tab = this.defaultSelectedTab || 'category'
      this.selected = null
      this.toDelete = []
      this.page = 1

      this.viewData = {
        category: [],
        product : [],
        other   : []
      }

      this.$nextTick(() => {
        if (this.tmpData) this.setGalleryData(this.tmpData)
        if (this.tmpCat) this.setCategoriesData(this.tmpCat)
      })
    }
  }
}
</script>

<style scoped>
/deep/ .v-pagination > li > button {
  margin : 4px;
}
</style>
