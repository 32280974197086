import BaseCollection      from '../../../lib/data/Collections/BaseCollection'
import SupportServiceModel from '../../Models/support-center/SupportServiceModel'
import SocketCommand       from '@/api/SocketCommand'

export default class SupportServicesCollection extends BaseCollection {
  constructor (items = []) {
    super(items, SupportServiceModel)

    this.fetchCommand = SocketCommand.SupportCenter.Ticket.Lists
  }

  /* PROPERTIES */

  /* METHODS */

  /* API METHODS */

  onFetch (data) {
    if (data.status) {
      super.onFetch(data.data.services)
      if (data.data.hasOwnProperty('services')) window.Vue.$DataStore.support.departments.clear().add(data.data.departments)
      if (data.data.hasOwnProperty('priorities')) window.Vue.$DataStore.support.priorities.clear().add(data.data.priorities)
      if (data.data.hasOwnProperty('priorities')) window.Vue.$DataStore.support.statuses.clear().add(data.data.statuses)
    }
  }
}
