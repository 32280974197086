export default {
  Title : 'Διανομείς | Διανομέας | Διανομείς',
  Button: {
    DeliveryStaff: {
      Add: {
        Title: 'Προσθήκη Διανομέα',
        Color: 'primary',
        Icon : 'add_box'
      },
      Edit: {
        Color: 'grey lighten-0',
        Icon : 'edit'
      },
      Delete: {
        PopUp: {
          Title  : 'Διαγραφή Διανομέα;',
          Content: 'Είστε σίγουροι ότι θέλετε να διαγράψετε οριστικά το διανομέα;'
        }
      }
    },
    RemoveOrder: {
      PopUp: {
        Title  : 'Αφαίρεση Παραγγελίας;',
        Content: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε την παραγγελία από τη βάρδια του διανομέα;'
      }
    },
    Shift: {
      Open: {
        Title: 'Άνοιγμα βάρδιας',
        Color: 'green',
        Icon : 'fas fa-plus-circle',
        PopUp: {
          Title : 'Ποσό Έναρξης Βάρδιας',
          Hint  : 'Το ποσό με το οποίο ξεκινάει η βάρδια.',
          Button: 'Άνοιγμα'
        }
      },
      Close: {
        Title: 'Κλείσιμο βάρδιας',
        Color: 'red',
        Icon : 'fas fa-times-circle',
        PopUp: {
          Title  : 'Κλείσιμο Βάρδιας;',
          Content: 'Είστε σίγουροι ότι θέλετε να κλείσετε τη βάρδια;'
        }
      }
    }
  },

  ListView: {
    NoData: {
      Title   : 'Δημιουργήστε Διανομέα',
      Subtitle: 'Προσθέστε νέο διανομέα',
      Icon    : 'account_circle'
    },
    SelectDeliveryStaff: {
      Title   : 'Επιλέξτε Διανομέα',
      Subtitle: 'Επιλέξτε διανομέα από την λίστα',
      Icon    : 'directions_bike'
    },
    Table: {
      Headers: {
        DateStarted: 'Ημ/νία',
        Swifts     : 'Βάρδιες',
        Orders     : 'Παραγγελίες',
        Total      : 'Σύνολο',
        Actions    : ''
      }
    }
  },

  Common: {
    Orders      : 'Παραγγελίες:',
    ShiftsOrders: 'Παραγγελίες βάρδιας: ',
    ShiftStart  : 'Έναρξη βάρδιας',
    ShiftTotal  : 'Σύνολο βάρδιας',
    InfoLabels  : {
      Icon     : 'account_circle',
      Telephone: 'Τηλ.:',
      Email    : 'Email:'
    }
  },

  DeliveryStaffInfo: {
    Tab: {
      Details: '@:Common.Misc.Details',
      History: 'Ιστορικό'
    },
    Button: {
      AddOrder: {
        Title: 'Προσθηκη Παραγγελιας',
        Color: 'light-green'
      },
      ViewDailyShift: {
        Title: 'Προβολή βαρδιών ημέρας',
        Icon : 'remove_red_eye',
        Color: 'cyan'
      }
    },
    Result: {
      NoOrders     : 'Δεν υπάρχουν ακόμη παραγγελίες βάρδιας',
      InactiveShift: {
        Icon        : 'account_circle',
        Title       : 'Μη Ενεργή Βάρδια',
        SubtitleLink: 'Ανοίξτε',
        SubtitleText: 'τη βάρδια διανομέα'
      }
    }
  },

  PrintDeliveryShift: {
    PrintReceipt: {
      StartShift  : 'Έναρξη Βάρδιας',
      Delivery    : 'Παράδοση',
      Collection  : 'Παραλαβή',
      Canceled    : 'Ακυρωμένη',
      Cash        : 'Μετρητά',
      Card        : 'Κάρτα',
      WithoutStart: 'Χωρίς Έναρξη:',
      NotIncluded : 'Δεν Συμπεριλαμβάνονται',
      ByCard      : ' με Κάρτα ('
    },
    Notification: 'Η βάρδια στάλθηκε προς εκτύπωση!'
  },

  DeliveryStaffAddDialog: {
    Toolbar: {
      Color: 'indigo',
      Icon : 'directions_bike',
      Add  : {
        Title   : 'Προσθήκη Διανομέα',
        Subtitle: 'Δημιουργία Νέου Διανομέα'
      },
      Edit: {
        Title   : 'Επεξεργασία Διανομέα',
        Subtitle: 'Τροποποίηση Στοιχείων Διανομέα'
      }
    },
    Field: {
      Firstname: {
        Label: 'Όνομα',
        Hint : 'Όνομα διανομέα. πχ. Βαγγέλης, Μαρία, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Lastname: {
        Label: 'Επίθετο',
        Hint : 'Επίθετο διανομέα. πχ. Παπαδόπουλος, Μαρκορά, κλπ.',
        Icon : 'account_circle',
        Error: {
          Between: 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      Telephone: {
        Label: 'Τηλέφωνο',
        Hint : 'Κινητό ή σταθερό τηλέφωνο διανομέα. πχ. 2106105200',
        Icon : 'phone',
        Error: {
          Between: 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
        }
      },
      Email: {
        Label: 'Email',
        Hint : 'Διεύθυνση Email διανομέα. πχ. support@getfoodpro.gr',
        Icon : 'email',
        Error: {
          Between: 'Το E-mail πρέπει να είναι έως 96 χαρακτήρες',
          Valid  : 'Το E-mail πρέπει να είναι έγκυρο'
        }
      },
      Notes: {
        Label: 'Σημειώσεις',
        Hint : 'Επιπλέον στοιχεία για τον διανομέα',
        Icon : 'speaker_notes'
      }
    }
  },

  DeliveryStaffDayShiftsDialog: {
    Header: {
      Icon : 'directions_bike',
      Title: 'Βάρδιες Ημέρας Διανομέα',
      Shift: 'Βάρδια'
    },
    Result: {
      NoOrders: {
        Icon    : 'shopping_cart',
        Color   : 'grey lighten-2',
        Title   : 'Δεν Υπάρχουν Παραγγελίες',
        Subtitle: 'Δεν υπάρχουν παραγγελίες στη βάρδια διανομέα'
      }
    }
  },

  SelectDeliveryStaffDialog: {
    Toolbar: {
      Icon    : 'directions_bike',
      Subtitle: 'Επιλογή Διανομέα'
    },
    Result: {
      NoDeliveryStaff: {
        Title   : 'Δεν Υπάρχουν Διανομείς',
        Subtitle: {
          Please       : 'Παρακαλώ',
          Open         : 'ανοίξτε',
          ShiftOr      : 'βάρδια ή',
          Create       : 'δημιουργήστε',
          DeliveryStaff: 'διανομέα'
        }
      }
    }
  },

  DeliveryStaffBarcode: {
    OnBarcodeScan: {
      Error: {
        Title: 'Barcode Παραγγελίας #',
        Body : 'Η παραγγελία δεν προστέθηκε! Παρακαλώ σαρώστε πρώτα το barcode διανομέα!'
      },
      SetActiveDeliveryStaff: {
        Add: {
          Body: 'Έναρξη παραλαβής παραγγελιών από διανομέα. Παρακαλώ σαρώστε barcode παραγγελίας.'
        },
        ClosedShift: {
          Title: 'Barcode Διανομέα',
          Body : 'Ο διανομέας {firstname} {lastname} δεν έχει ανοιχτή βάρδια. Παρακαλώ πρώτα ανοίξτε βάρδια.'
        },
        NotFound: {
          Title: 'Barcode Διανομέα',
          Body : 'Το barcode δεν αντιστοιχεί σε διανομέα!'
        }
      },
      ClearActiveDeliveryStaff: {
        Body: 'Λήξη παραλαβής παραγγελιών από διανομέα.'
      }
    }
  }
}
