<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.Categories.Toolbar.Button.New.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showCategoryAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.Categories.Toolbar.Button.New.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Categories.Toolbar.Button.New.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ACCESS')"
          :color="$t('Restaurant.Categories.Toolbar.Button.Products.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          :to="{name: 'KitchenMenu'}"
        >
          <v-icon
            v-if="$t('Restaurant.Categories.Toolbar.Button.Products.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Categories.Toolbar.Button.Products.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Categories.Toolbar.Button.Products.Title').toLocaleUpperCase($i18n.locale) }}</span>
        </v-btn>

        <!--
        <v-btn
          v-if="selectedLanguages.length > 1 && userIsSuperExtraAdmin"
          :disabled="dataTable.loading"
          :loading="dataTable.loading"
          :loading-text="dataTable.text.loadingText"
          :no-data-text="dataTable.text.noDataText"
          :no-results-text="dataTable.text.noResultsText"
          class="purple white--text elevation-0 ma-1"
          @click="translateAllItems()"
        >
          {{ $t('Common.Button.Translate').toLocaleUpperCase($i18n.locale) }}
        </v-btn>
        -->

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />

        <filter-options
          v-model="filtersModel"
          :filters="filterData"
        />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.color`]="{item}">
              <div
                class="d-inline-flex align-center my-1"
                style="height: 100%; min-height: 53px; text-align: left;"
              >
                <div
                  v-if="item.Color"
                  :style="'background-color: #' + item.Color + ';width:5px; max-width:5px; height: 100%'"
                  class="pa-0 ma-0"
                />

                <div
                  v-if="item.Image"
                  class="imagecontainer is-contain"
                >
                  <img
                    :src="appConfig.LOCATION_DATA.CdnImagesUrl + item.Image"
                    class="ml-1 py-1"
                    height="51"
                    style=""
                    width="auto"
                  >
                </div>

                <div
                  v-else
                  class="imagecontainer is-contain"
                  style="padding-top: 10px;"
                >
                  <img
                    :src="getGMapIcon(LOGO_COLOR.DARK)"
                    class="ml-1 mr-1"
                    height="auto"
                    style="opacity: 0.12;"
                    width="auto"
                  >
                </div>
              </div>
            </template>

            <template #[`item.category_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showCategoryEditDialog(item)"
                >
                  {{ getTranslatedField(item, 'Name') }}
                </a>
              </template>
              <template v-else>
                {{ getTranslatedField(item, 'Name') }}
              </template>

              <div class="text-caption grey--text">
                {{ getTranslatedField(item, 'Description') | truncate }}
              </div>
            </template>

            <template #[`item.menus_count`]="{item}">
              <v-chip
                label
                small
                @click="onProductsChipClick(item.Id)"
              >
                {{ item.MenusCount }}
              </v-chip>
            </template>

            <template #[`item.catalog`]="{item}">
              <template v-for="(catalogId, index) in item.Catalogs">
                <v-chip
                  :key="`catalog-${catalogId}-${index}`"
                  label
                  small
                  class="mr-1 my-1"
                  @click="onCatalogChipClick(catalogId)"
                >
                  {{ getCatalogById(catalogId).Name }}
                </v-chip>
              </template>
            </template>

            <template #[`item.tax_rate`]="{item}">
              <div>
                <v-chip
                  v-if="item.TaxId && item.TaxRate"
                  color="blue lighten-1"
                  class="text-no-wrap px-2 my-1 chip-column"
                  style="min-width: 100px; max-width: 115px;"
                  label
                  dark
                  x-small
                >
                  <div>{{ $t('Restaurant.CategoryAddDialog.Field.Tax.Type') }}</div>

                  <div>{{ parseInt(item.TaxRate) }}%</div>
                </v-chip>
              </div>

              <div>
                <v-chip
                  v-if="item.Tax2Id && item.Tax2Rate"
                  color="purple lighten-2"
                  class="text-no-wrap px-2 my-1 chip-column"
                  style="min-width: 100px; max-width: 115px;"
                  label
                  dark
                  x-small
                >
                  <div>{{ $t('Restaurant.CategoryAddDialog.Field.Tax2.Type') }}</div>

                  <div>{{ parseInt(item.Tax2Rate) }}%</div>
                </v-chip>
              </div>
            </template>

            <template #[`item.languages`]="{item}">
              <template v-for="lang in selectedLanguages">
                <div
                  :key="lang.Code"
                  class="mx-1 d-inline-block"
                >
                  <v-badge
                    :value="!item.Lang[lang.Code] || !item.Lang[lang.Code].Name"
                    color="orange"
                    overlap
                    right
                  >
                    <template #badge>
                      <v-icon
                        dark
                        small
                      >
                        priority_high
                      </v-icon>
                    </template>
                    <v-avatar
                      v-if="lang.ImageUrl"
                      size="20"
                    >
                      <img
                        :class="[{'grayscale opacity': !item.Lang[lang.Code] || !item.Lang[lang.Code].Name}]"
                        :src="lang.ImageUrl"
                      >
                    </v-avatar>
                    <div v-else>
                      {{ $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale) }}
                    </div>
                  </v-badge>
                </div>
              </template>
            </template>

            <template #[`item.priority`]="{item}">
              {{ item.Priority }}
            </template>

            <template #[`item.status`]="{item}">
              <v-switch
                v-model="item.Status"
                inset
                :disabled="!userCanManage"
                :label="item.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-0"
                color="success"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.visible`]="{item}">
              <v-switch
                v-model="item.Visible"
                inset
                :disabled="!userCanManage"
                :label="item.Visible ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                class="ma-0"
                color="blue"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.featured`]="{item}">
              <v-switch
                v-model="item.Featured"
                inset
                :disabled="!userCanManage"
                :label="item.Featured ? $t('Common.Button.Yes') : $t('Common.Button.No')"
                class="ma-0"
                color="cyan"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanAdd"
                :loading="item.DuplicateIsLoading"
                class="orange elevation-2 ma-0 mr-1"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="duplicateCategory(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  difference
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanManage"
                class="cyan elevation-2 ma-0 mr-1"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showCategoryEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanDelete"
                class="red elevation-2 ma-0"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="deleteCategory(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <category-add-dialog
      :taxes="taxes"
      :catalogs="catalogs"
      :category-to-edit="selectedItem"
      :visible.sync="addEditCategoryDialog"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Categories.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.Categories.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Categories.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="565"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDeleteWithProducts"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Categories.Dialog.Duplicate.Content')"
      :html-title="$t('Restaurant.Categories.Dialog.Duplicate.Title')"
      :visible.sync="confirmDialogDuplicateVisible"
      :width="565"
      :cancel-button="$vuetify.breakpoint.smAndUp"
      no-button-class="v-btn--outline green--text"
      no-button-event="on-confirm-duplicate"
      no-button-text="ΧΩΡΙΣ ΠΡΟΙΟΝΤΑ"
      title-class="orange white--text lighten-0"
      yes-button-class="orange white--text"
      yes-button-event="on-confirm-duplicate-with-products"
      yes-button-text="ΜΕ ΠΡΟΙΟΝΤΑ"
      @on-confirm-duplicate="onConfirmDuplicate"
      @on-confirm-duplicate-with-products="onConfirmDuplicateWithProducts"
    />
  </v-card>
</template>

<script>
import { API, APICall }  from '@/api/Api'
import AppData           from '@/mixins/appdata'
import Auth              from '@/mixins/auth'
import Translatable      from '@/mixins/translatable'
import FilterCommon      from '@/mixins/filter/filterCommon'
import ConfirmDialog     from '@/components/common/ConfirmDialog'
import FilterSearch      from '@/components/common/filter/FilterSearch.vue'
import FilterOptions     from '@/components/common/filter/FilterOptions.vue'
import CategoryAddDialog from './CategoryAddDialog.vue'
import DataTable         from '@/mixins/data-table/dataTable'
import googleTranslate   from '@/lib/google-translate/googleTranslate'
import SocketCommand     from '@/api/SocketCommand'

export default {
  components: {
    FilterOptions,
    FilterSearch,
    'category-add-dialog': CategoryAddDialog,
    'confirm-dialog'     : ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, FilterCommon, DataTable],
  data () {
    return {
      filtersModel: {
        lang         : 'Restaurant.Categories',
        filterCatalog: {
          key  : 'filter_catalog',
          value: [],
          items: []
        },
        filterStatus: {
          key  : 'filter_status',
          value: null,
          items: this.$t('Restaurant.Categories.Filter.filterStatus.Items')
        },
        filterVisible: {
          key  : 'filter_visible',
          value: null,
          items: this.$t('Restaurant.Categories.Filter.filterVisible.Items')
        },
        filterFeatured: {
          key  : 'filter_featured',
          value: null,
          items: this.$t('Restaurant.Categories.Filter.filterFeatured.Items')
        }
      },
      taxes                        : [],
      catalogs                     : [],
      confirmDialogDeleteVisible   : false,
      confirmDialogDuplicateVisible: false,
      selectedItem                 : null,
      addEditCategoryDialog        : false,
      dataTable                    : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Category.All,
          save  : window.SocketCommand.Category.Save,
          delete: window.SocketCommand.Category.Delete
        },
        options: {
          defaultSortBy: 'category_id',
          sortBy       : ['category_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Image'),
            value    : 'color',
            align    : 'left',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Id'),
            value    : 'category_id',
            align    : 'right',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Name'),
            value    : 'name',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.MenusCount'),
            value    : 'menus_count',
            align    : 'center',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Catalog'),
            value    : 'catalog',
            align    : 'left',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Tax'),
            value    : 'tax_rate',
            align    : 'center',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Languages'),
            value    : 'languages',
            align    : 'center',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Priority'),
            value    : 'priority',
            align    : 'center',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Status'),
            value    : 'status',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Visible'),
            value    : 'visible',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Featured'),
            value    : 'featured',
            align    : 'left',
            sortable : true,
            class    : 'text-no-wrap',
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Categories.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            class    : 'text-no-wrap',
            cellClass: 'text-caption px-2',
            width    : 140
          }
        ]
      }
    }
  },
  computed: {
    SocketCommand () {
      return SocketCommand
    },
    catalogsFilterData () {
      return this.catalogs.map(o => {
        o.Avatar = { Color: `#${ o.Color }` }
        return o
      })
    }
  },
  watch: {},
  created () {
    // console.log('1. created');

    // Init Custom Filter Values
    this.filtersModel.filterCatalog.items = this.catalogsFilterData
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Category.Duplicate, this.onDuplicateCategoryResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Category.Duplicate, this.onDuplicateCategoryResult)
  },
  methods: {
    onProductsChipClick (categoryId) {
      this.$router.push({
        name  : 'KitchenMenu',
        params: { categoryId: categoryId }
      })
    },

    onCatalogChipClick (catalogId) {
      if (!this.filtersModel.filterCatalog.value.includes(catalogId)) {
        this.filtersModel.filterCatalog.value.push(catalogId)
      }
    },

    getCatalogById (catalogId) {
      return this.catalogs?.find(c => c.Id === catalogId) || ''
    },

    showCategoryAddDialog () {
      this.selectedItem = null
      this.addEditCategoryDialog = true
    },

    duplicateCategory (category) {
      this.selectedItem = category
      this.confirmDialogDuplicateVisible = true
    },

    showCategoryEditDialog (category) {
      this.selectedItem = category
      this.addEditCategoryDialog = true
    },

    setData (data) {
      this.taxes = data?.taxes || []
      this.catalogs = data?.catalogs || []
      this.filtersModel.filterCatalog.items = this.catalogsFilterData

      this.setDataInternal(data)
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.Category.UpdateStatus, {
        category_id      : item.Id,
        category_status  : item.Status ? 1 : 0,
        category_visible : item.Visible ? 1 : 0,
        category_featured: item.Featured ? 1 : 0
      })
    },

    onConfirmDuplicate () {
      this.$set(this.selectedItem, 'DuplicateIsLoading', true)
      window.callAS(window.SocketCommand.Category.Duplicate, {
        Id       : this.selectedItem.Id,
        WithMenus: false
      })
    },

    onConfirmDuplicateWithProducts () {
      this.$set(this.selectedItem, 'DuplicateIsLoading', true)
      window.callAS(window.SocketCommand.Category.Duplicate, {
        Id       : this.selectedItem.Id,
        WithMenus: true
      })
    },

    onDuplicateCategoryResult (data) {
      const viewDataItems = this.viewData?.items || this.viewData?.table?.items?.items
      const item = viewDataItems?.find(menu => parseInt(menu?.Id) === parseInt(data?.result?.old))
      if (item) this.$set(item, 'DuplicateIsLoading', false)

      if (data.status === 'success') {
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.CategoryAddDialog.Notification.Duplicate', { id: data?.result?.new || '' }),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.GenericError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    deleteCategory (item) {
      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Category.Delete, {
        category_id  : this.selectedItem.Id,
        with_products: false
      })
    },

    onConfirmDeleteWithProducts () {
      window.callAS(window.SocketCommand.Category.Delete, {
        category_id  : this.selectedItem.Id,
        with_products: true
      })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Categories.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    async translateAllItems (localeFrom = this.defaultLanguage.Code, localeTo) {
      if (this.selectedLanguages.length <= 0 || !this.userIsSuperExtraAdmin) return
      this.dataTable.loading = true
      const langs = localeTo ? [{ Code: localeTo }] : this.selectedLanguages.filter(lang => lang.Code !== localeFrom) || []

      for (let lng = 0; lng < langs.length; lng++) {
        localeTo = langs[lng].Code

        const payload = APICall.CreatePayload(window.SocketCommand.Category.All, {
          config       : 0,
          page         : 1,
          page_limit   : -1,
          sort_by      : 'category_id',
          order_by     : 'DESC',
          filter_search: ''
        })
        // eslint-disable-next-line camelcase
        const categories_result = await API.post(APICall.api, payload)

        const lngArrName = []
        const lngArrDesc = []
        for (let i = 0; i < categories_result.data.items.length; i++) {
          const item = categories_result.data.items[i]
          lngArrName.push(item.Lang[localeFrom].Name || '')
          lngArrDesc.push(item.Lang[localeFrom].Description || '')
        }

        const resultName = await googleTranslate(lngArrName, localeTo, localeFrom)
        const resultDesc = await googleTranslate(lngArrDesc, localeTo, localeFrom)

        if (resultName && resultDesc) {
          for (let i = 0; i < categories_result.data.items.length; i++) {
            const item = categories_result.data.items[i]
            const langObj = {}
            for (const lang of this.selectedLanguages) {
              langObj[lang.Code] = {
                Name       : '',
                Description: '',
                Slug       : '',
                Locale     : lang.Code
              }
            }
            item.Lang = Object.assign({}, langObj, item.Lang)
            item.Lang[localeTo].Name = resultName[i].translatedText
            item.Lang[localeTo].Description = resultDesc[i].translatedText
            item.Lang[localeTo].Slug = `${ item.Lang[localeFrom].Slug }_${ localeTo }`

            await API.post(APICall.api, APICall.CreatePayload(window.SocketCommand.Category.Save, item))
          }
        }
      }
      this.getData()
      this.dataTable.loading = false
    }
  }
}
</script>

<style scoped>
/deep/ .chip-column.v-chip .v-chip__content {
  width           : 100%;
  justify-content : space-between;
}

/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--switch .v-input--selection-controls__input {
  margin-right : 0;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 65px !important;*/
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}

/deep/ .chip:focus:not(.chip--disabled):after, .chip--active:not(.chip--disabled):after, .chip--selected:not(.chip--disabled):after {
  top  : 0;
  left : 0;
}
</style>
