<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1200"
      persistent
      scrollable
    >
      <v-card
        v-if="table && area"
        style="height:90vh;"
      >
        <v-toolbar
          flat
          height="80"
          max-height="128"
          tabs
        >
          <v-badge
            :color="`${getTableStatusColor(table, true)} lighten-2`"
            overlap
          >
            <template #badge>
              <span>{{ viewData && viewData.Persons ? viewData.Persons : table.Persons }}</span>
            </template>

            <v-avatar :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'">
              <v-icon dark>
                group
              </v-icon>
            </v-avatar>
          </v-badge>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ area.Name }} #{{ table.Name }}
            </div>

            <div
              v-if="table.StaffId && table.StaffName"
              class="text-caption grey--text"
            >
              {{ table.StaffName }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs
              v-model="tab"
              :color="isDarkMode ? 'white' : 'grey-darken-3'"
              :grow="true"
              :slider-color="tabsSliderColor"
              background-color="transparent"
              show-arrows
            >
              <v-tab
                :disabled="!completedCount"
                href="#tab-orders"
              >
                <v-badge
                  :value="!!completedCount"
                  color="blue"
                  right
                >
                  <template #badge>
                    {{ completedCount }}
                  </template>

                  <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Orders.Title') }}</span>
                </v-badge>
              </v-tab>

              <v-tab
                :disabled="!unpaidCount"
                href="#tab-unpaid"
              >
                <v-badge
                  :value="!!unpaidCount"
                  color="orange"
                  right
                >
                  <template #badge>
                    {{ unpaidCount }}
                  </template>

                  <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Unpaid.Title') }}</span>
                </v-badge>
              </v-tab>

              <v-tab
                :disabled="!paidCount"
                href="#tab-paid"
              >
                <v-badge
                  :value="!!paidCount"
                  color="green"
                  right
                >
                  <template #badge>
                    {{ paidCount }}
                  </template>

                  <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Paid.Title') }}</span>
                </v-badge>
              </v-tab>

              <v-tab
                :disabled="!canceledCount"
                href="#tab-canceled"
              >
                <v-badge
                  :value="!!canceledCount"
                  color="red"
                  right
                >
                  <template #badge>
                    {{ canceledCount }}
                  </template>

                  <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Canceled.Title') }}</span>
                </v-badge>
              </v-tab>

              <v-tab
                v-if="userCanViewReceipt"
                :disabled="!receiptsCount"
                href="#tab-receipts"
              >
                <v-badge
                  :value="!!receiptsCount"
                  color="indigo"
                  right
                >
                  <template #badge>
                    {{ receiptsCount }}
                  </template>

                  <span>{{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Receipts.Title') }}</span>
                </v-badge>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-progress-linear
          v-if="loading"
          class="pa-0 ma-0"
          color="secondary"
          height="2"
          indeterminate
        />

        <v-divider />

        <div v-if="tab === 'tab-unpaid'">
          <v-stepper
            v-model="paymentStep"
            :alt-labels="true"
            class="elevation-0"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="paymentStep > 1"
                step="1"
              >
                {{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Unpaid.Step.Orders') }}
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                :complete="paymentStep > 2"
                step="2"
              >
                {{ $t('Restaurant.Tables.Dialog.ViewTable.Tab.Unpaid.Step.Payment') }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>

        <v-divider />

        <v-card-text
          ref="tabsContainer"
          class="fill-height pa-4"
        >
          <!--<div style="min-height: calc(100vh - 410px); height: calc(100vh - 410px);">-->
          <div>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-orders">
                <table-completed-orders-list
                  :issue-receipt="appHasReceiptsEnabled && appHasReceiptsProvider"
                  :print="canManageTable"
                  :table="table"
                  :view-data="viewData"
                  desc
                  editable-item-units
                />
              </v-tab-item>

              <v-tab-item value="tab-unpaid">
                <v-stepper
                  v-model="paymentStep"
                  class="elevation-0 transparent"
                >
                  <v-stepper-items>
                    <v-stepper-content
                      class="pa-0"
                      step="1"
                    >
                      <table-completed-orders-list
                        v-model="selectedUnpaidItems"
                        :selectable="canManageTable"
                        :selected-total.sync="selectedUnpaidItemsTotal"
                        :table="table"
                        :view-data="viewData"
                        desc
                        unpaid
                      />
                    </v-stepper-content>

                    <v-stepper-content
                      class="pa-0"
                      step="2"
                    >
                      <table-payment
                        v-model="transactions"
                        :items="selectedUnpaidItems"
                        :payment-totals.sync="totals"
                        :receipt-data.sync="selectedReceiptData"
                        :receipt-notify.sync="selectedReceiptNotify"
                        :receipt-type.sync="selectedReceiptType"
                        :table="table"
                        :table-total="tableUnpaidTotalForPayment"
                        receipt
                        @transaction:add:loading="(data) => { loading = true }"
                        @transaction:remove:loading="(data) => { loading = true }"
                      />
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-tab-item>

              <v-tab-item value="tab-paid">
                <table-payments-list
                  :table="table"
                  :view-data="viewData"
                  desc
                />
              </v-tab-item>

              <v-tab-item value="tab-canceled">
                <table-completed-orders-list
                  :print="canManageTable"
                  :table="table"
                  :view-data="viewData"
                  canceled
                  desc
                />
              </v-tab-item>

              <v-tab-item
                v-if="userCanViewReceipt"
                value="tab-receipts"
              >
                <receipts-table
                  :cancel="userCanViewReceipt"
                  :email="appReceiptsProviderNotify.includes(NotifyTypeEnum.EMAIL)"
                  :print="appMachineHasReceiptPrinters"
                  :receipts="receiptsList"
                  :orders="ordersList"
                  :table="table"
                  alternate-row-colors
                />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions
          class="pa-2 pb-0"
        >
          <v-container
            class="pa-1"
            fluid
          >
            <v-row no-gutters>
              <!-- ORDER TOTALS -->
              <template v-if="viewData && paymentStep === 1">
                <v-col cols="12">
                  <table-carts-totals
                    :can-add-coupon="canManageTable && paymentStep === 1"
                    :can-remove-coupon="canManageTable"
                    :loading.sync="loading"
                    :view-data="viewData"
                    class=""
                  />
                </v-col>

                <v-col
                  v-if="canManageTable"
                  cols="12"
                >
                  <v-divider class="mt-1 mb-0" />
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-card-actions
          v-if="!!tableCoupons.length && tab === 'tab-unpaid'"
          class="pa-0 py-1"
        >
          <v-alert
            border="left"
            class="py-1 ma-0 text-caption"
            dense
            icon="warning"
            tile
            type="warning"
            width="100%"
          >
            {{ $t('Restaurant.Tables.Notification.HasTableCouponPay') }}
          </v-alert>
        </v-card-actions>

        <v-card-actions
          v-if="canManageTable"
          class="pa-2 px-1 px-md-3 pt-1"
        >
          <print-button
            :disable-tooltip="$vuetify.breakpoint.smAndUp"
            :disabled="viewData && !viewData.Completed.length"
            :hide-label="$vuetify.breakpoint.xsOnly"
            :menu-position="'top'"
            btn-class="px-2 white--text"
            btn-style="min-width: 40px;"
            depressed
            menu-offset-y
            @printer-selected="printer => printTableOrders(printer)"
          />

          <v-spacer />

          <template v-if="tab === 'tab-unpaid'">
            <template v-if="paymentStep === 1">
              <v-btn
                :disabled="loading || !!tableCoupons.length || !selectedUnpaidItems.length"
                :loading="loading"
                class="red white--text elevation-0 darken-4 ma-0 pa-0 rounded-r-0"
                min-width="35px"
                @click="onCancelItemsButtonClick(false)"
              >
                <v-icon>
                  mdi-printer-pos-cancel-outline
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="loading || !!tableCoupons.length || !selectedUnpaidItems.length"
                :loading="loading"
                class="red white--text elevation-0 ma-0 pa-0 px-2 rounded-l-0"
                min-width="35px"
                @click="onCancelItemsButtonClick(true)"
              >
                <v-icon v-if="$vuetify.breakpoint.xsOnly">
                  cancel
                </v-icon>
                <template v-else>
                  {{ $t('Common.Button.Cancel') }}
                </template>
              </v-btn>

              <v-btn
                :disabled="loading || !!tableCoupons.length || !selectedUnpaidItems.length"
                :loading="loading"
                class="blue white--text elevation-0 ml-1 px-2"
                @click="onPaymentButtonClick('selected')"
              >
                {{ $t('Common.Button.Payment') }}
              </v-btn>

              <v-btn
                :disabled="loading"
                :loading="loading"
                class="green white--text elevation-0 ml-1 px-2"
                @click="onPaymentButtonClick('all')"
              >
                {{ $t('Common.Button.PaymentAll') }}
              </v-btn>
            </template>

            <template v-if="paymentStep === 2">
              <v-btn
                class="light-blue--text text--darken-1 ml-1"
                outlined
                text
                @click="paymentStep = 1"
              >
                {{ $t('Common.Button.Back') }}
              </v-btn>

              <v-btn
                :disabled="loading || tableUnpaidBalance > 0 || tableUnpaidBalance < 0 || tableHasCardPaymentsThatRequireReceipt || (appHasReceiptsEnabled && selectedReceiptType === ReceiptTypeEnum.INVOICE && !selectedReceiptData)"
                :loading="loading"
                class="green white--text elevation-0 darken-2 ma-0 ml-1 pa-0 rounded-r-0"
                min-width="35px"
                @click="onPayButtonClick(false)"
              >
                <v-icon>
                  mdi-receipt-text-remove-outline
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="loading || tableUnpaidBalance > 0 || tableUnpaidBalance < 0 || (appHasReceiptsEnabled && selectedReceiptType === ReceiptTypeEnum.INVOICE && !selectedReceiptData)"
                :loading="loading"
                class="green white--text elevation-0 ml-0 rounded-l-0"
                style="min-width: 150px;"
                @click="onPayButtonClick(true)"
              >
                <span class="mr-2">{{ $t('Common.Button.Payment') }}</span>
                <v-spacer />
                {{ tableUnpaidTotalForPayment | currency }}
              </v-btn>
            </template>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      :html-content="$tc('Restaurant.Tables.Dialog.ConfirmCancelOrderItems.Content', selectedUnpaidItems.length || 0, {count: selectedUnpaidItems.length || 0})"
      :html-title="$tc('Restaurant.Tables.Dialog.ConfirmCancelOrderItems.Title', selectedUnpaidItems.length || 0)"
      :info-bar="true"
      :info-bar-body="$tc('Restaurant.Tables.Dialog.ConfirmCancelOrderItems.Notification', selectedUnpaidItems.length || 0, {count: selectedUnpaidItems.length || 0})"
      :visible.sync="confirmCancelOrderItemsDialogVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-yes-cancel-order-items"
      @on-confirm-yes-cancel-order-items="onConfirmYesCancelOrderItems"
    />
  </v-row>
</template>

<script>
import PrintButton              from '@/components/common/print/PrintButton.vue'
import TablePaymentsList        from '@/components/restaurant/tables/payments/TablePaymentsList'
import TableCompletedOrdersList from '@/components/restaurant/tables/orders/TableCompletedOrdersList'
import TablePayment             from '@/components/restaurant/tables/payments/TablePayment'
import Auth                     from '@/mixins/auth'
import TableStatus              from '@/mixins/tables/tableStatus'
import TableCommon              from '@/mixins/tables/tableCommon'
import TablePaymentCommon       from '@/mixins/tables/tablePaymentCommon'
import Printer                  from '@/lib/helper/printer'
import ConfirmDialog            from '@/components/common/ConfirmDialog.vue'
import TableCartsTotals         from '@/components/restaurant/tables/orders/TableCartsTotals.vue'
import ReceiptsTable            from '@/components/receipts/ReceiptsTable.vue'
import ReceiptTypeEnum          from '@/api/Enums/ReceiptTypeEnum'
import NotifyTypeEnum           from '@/api/Enums/NotifyTypeEnum'
import PinManagerEnum           from '@/api/Enums/PinManagerEnum'
import { clone, emailIsGuest }  from '@/lib/helper/helper'

export default {
  name      : 'TableViewDialog',
  components: {
    ReceiptsTable,
    TableCartsTotals,
    ConfirmDialog,
    TablePayment,
    TableCompletedOrdersList,
    TablePaymentsList,
    PrintButton
  },
  mixins: [Auth, TableStatus, TableCommon, TablePaymentCommon],
  props : {},
  data () {
    return {
      tab                                 : 'tab-orders',
      tabsSliderColor                     : 'blue',
      confirmCancelOrderItemsDialogVisible: false,
      cancelItemsAutoPrint                : true
    }
  },
  computed: {
    ReceiptTypeEnum () {
      return ReceiptTypeEnum
    },
    NotifyTypeEnum () {
      return NotifyTypeEnum
    },
    receiptsList () {
      return clone(this.viewData?.OrderReceipts || [])
    },
    ordersList () {
      return clone(this.viewData?.Completed || [])
    },

    defaultActiveTab () {
      let retVal = this.tab || 'tab-orders'

      if (!this.completedCount && this.tab === 'tab-orders') retVal = 'tab-canceled'
      if (!this.unpaidCount && this.tab === 'tab-unpaid') retVal = 'tab-orders'
      if (!this.paidCount && this.tab === 'tab-paid') retVal = 'tab-orders'
      if (!this.canceledCount && this.tab === 'tab-canceled') retVal = 'tab-orders'
      if (!this.receiptsCount && this.tab === 'tab-receipts') retVal = 'tab-orders'

      if (!this.completedCount && retVal === 'tab-orders') retVal = 'tab-canceled'

      return retVal
    },

    paidCount () {
      return this.viewData?.PaidCount || 0
    },

    canceledCount () {
      return this.viewData?.CanceledCount || 0
    },

    unpaidCount () {
      return this.viewData?.UnPaidCount || 0
    },

    completedCount () {
      return this.viewData?.OrdersCount || 0
    },

    receiptsCount () {
      return this.viewData?.ReceiptsCount || 0
    }
  },
  watch: {
    tab (newVal) {
      if (this.$refs?.tabsContainer) this.$refs.tabsContainer.scrollTop = 0

      if (newVal === 'tab-orders') {
        this.tabsSliderColor = 'blue'
        this.paymentStep = 1
      }
      if (newVal === 'tab-unpaid') this.tabsSliderColor = 'orange'
      if (newVal === 'tab-paid') this.tabsSliderColor = 'green'
      if (newVal === 'tab-canceled') this.tabsSliderColor = 'red'
      if (newVal === 'tab-receipts') this.tabsSliderColor = 'indigo'
    },

    paymentStep () {
      if (this.$refs?.tabsContainer) this.$refs.tabsContainer.scrollTop = 0
    }
  },
  methods: {
    initData () {
      this.$nextTick(() => {
        this.tab = 'tab-orders'
        this.tabsSliderColor = 'blue'
      })
      this.$bus.$on(window.SocketCommand.Ajax.Get, this.getData)
      this.$bus.$on(window.SocketCommand.Table.Show, this.setData)
      this.$bus.$on(window.SocketCommand.Table.Pay, this.onPayResponse)
      this.$bus.$on(window.SocketCommand.Table.Cancel.Items, this.onCancelItemsResponse)
      this.$bus.$on(window.SocketCommand.Table.Cart.UpdateUnitItem, this.onUpdateUnitItemResponse)
      this.clearData(false)
      this.getData()
    },
    clearData (clearEvents = true) {
      if (clearEvents) {
        this.$bus.$off(window.SocketCommand.Ajax.Get, this.getData)
        this.$bus.$off(window.SocketCommand.Table.Show, this.setData)
        this.$bus.$off(window.SocketCommand.Table.Pay, this.onPayResponse)
        this.$bus.$off(window.SocketCommand.Table.Cancel.Items, this.onCancelItemsResponse)
        this.$bus.$off(window.SocketCommand.Table.Cart.UpdateUnitItem, this.onUpdateUnitItemResponse)
        this.viewData = null
      }

      this.loading = false
      this.selectedItems = []
      this.selectedUnpaidItems = []
      this.transactions = []
      this.paymentStep = 1
      this.selectedItemsTotal = 0
      this.selectedUnpaidItemsTotal = 0
    },

    getData (payload) {
      if (!this.isVisible) return

      const command = window.SocketCommand.Table.GetById
      const payloadCommand = window.SocketCommand.commandToClientFormat(payload?.command || '')
      const payloadTableIdsArray = payload?.TableId
      const payloadTableClose = payload?.TableClose

      if (!payload || (payloadCommand === command && payloadTableIdsArray.includes(this.table.Id))) {
        if (payloadTableClose) {
          this.onCancelButtonClick()
        } else {
          this.loading = true
          window.callAS(window.SocketCommand.Table.Show, { TableId: this.table.Id })
        }
      }
    },
    setData (data) {
      this.viewData = data.data || null
      this.loading = false

      this.tab = this.defaultActiveTab
    },

    onEditButtonClick () {
      this.$router.push({
        name  : 'Pos',
        params: {
          orderId: this.viewData.Id,
          tableId: this.table.Id,
          table  : this.table,
          area   : this.area
        }
      })
      this.onCancelButtonClick()
    },

    printTableOrders (printer) {
      if (!printer) return

      if (this.userIsWaiter) {
        window.callAS(window.SocketCommand.Table.SendOrderForPrint, { cart_id: this.viewData.Id })
      } else {
        Printer.printTableOrder(this.viewData.Id, null, printer)
      }
    },
    /**
     *
     * @param printer {Object}
     * @param receiptType {ReceiptTypeEnum}
     */
    printTableReceipt (printer, receiptType = ReceiptTypeEnum.FNB) {
      if (!printer) return
      Printer.printTableReceipt(receiptType, this.viewData.Id, null, printer)
    },

    onCancelItemsButtonClick (autoPrint = true) {
      if (!this.table || !this.selectedUnpaidItems.length) return

      this.cancelItemsAutoPrint = autoPrint

      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.TableCancel) || (this.userNeedsPinPermission(PinManagerEnum.ManageAllTables) && this.canManageTable && !this.isOwnTable), () => {
        this.confirmCancelOrderItemsDialogVisible = true
      }, 'table-cancel-items')
    },
    onConfirmYesCancelOrderItems () {
      if (!this.table || !this.selectedUnpaidItems.length) return
      this.loading = true

      const items = this.selectedUnpaidItems.map(item => {
        return {
          RowId   : item.RowId,
          Quantity: item.UnitId > 1 ? item.UnitQuantity : item.Quantity
        }
      })
      const requestData = {
        TableId  : this.table.Id,
        CartId   : this.table.CartId,
        AutoPrint: this.cancelItemsAutoPrint,
        Items    : items
      }

      window.callAS(window.SocketCommand.Table.Cancel.Items, requestData)
    },
    onCancelItemsResponse (response) {
      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$tc('Restaurant.Tables.Dialog.ConfirmCancelOrderItems.Success', this.selectedUnpaidItems.length || 0, { count: this.selectedUnpaidItems.length || 0 }),
          type: 'success',
          icon: 'info'
        })
        if (this.viewData) this.viewData.TableTotals = []
        this.clearData(false)
        this.getData()
        this.$emit('cancel-success')
      } else {
        this.loading = false
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onUpdateUnitItemResponse (response) {
      if (response?.data) {
        this.viewData = response.data || null
      }
    },

    onPaymentButtonClick (type = 'selected') {
      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.ManageAllTables) && this.canManageTable && !this.isOwnTable, () => {
        this.gotoPaymentStep2(type)
      }, 'table-cancel-items')
    },

    gotoPaymentStep2 (type) {
      if (type === 'all') {
        this.$bus.$emit('table-order-select-all-carts-items')
      }

      this.$nextTick(async () => {
        if (this.tableUnpaidBalance <= 0 && (this.selectedUnpaidItemsHaveFullDiscount || !!this.tableTotals.length) && !this.transactions.length) {
          this.transactions = [this.getZeroTransaction()]
          this.onPayButtonClick(false, true)
        } else {
          this.paymentStep = 2
        }
      })
    },

    onPayButtonClick (autoPrint = true, isDiscountFull = false) {
      if (!this.table || !this.selectedUnpaidItems.length) return
      this.loading = true

      const items = this.selectedUnpaidItems.map(item => {
        return {
          RowId   : item.RowId,
          Quantity: item.UnitId > 1 ? item.UnitQuantity : item.Quantity
        }
      })

      const autoPrintReceipt = this.tableHasCardPaymentsThatRequireReceipt || autoPrint || isDiscountFull
      const docType = this.selectedReceiptType || ReceiptTypeEnum.RECEIPT
      const company = this.selectedReceiptData || null
      const printNotify = this.selectedReceiptNotify?.includes('print') || null
      const emailNotify = this.selectedReceiptNotify?.includes('email') || null
      const customerEmail = emailNotify && this.viewData?.SCustomer?.Email ? [this.viewData.SCustomer.Email] : []
      const dataEmails = this.selectedReceiptNotifyEmails || []
      const emailAddresses = [...new Set([...customerEmail, ...dataEmails])].filter(email => !emailIsGuest(email))
      const transactions = JSON.parse(JSON.stringify(this.transactions))
      const totals = [...this.tableTotals, ...clone(this.totals)]

      const requestData = {
        TableId       : this.table.Id,
        CartId        : this.table.CartId,
        Items         : items,
        Transactions  : transactions,
        Totals        : totals,
        Print         : printNotify,
        Email         : emailNotify,
        EmailAddresses: emailAddresses,
        Company       : company,
        ReceiptType   : docType,
        Receipt       : autoPrintReceipt,
        DiscountFull  : isDiscountFull
      }

      window.callAS(window.SocketCommand.Table.Pay, requestData)
    },

    onPayResponse (response) {
      if (response.status === 'success') {
        this.clearData(false)
        this.getData()
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Tables.Dialog.Payment.PaymentSuccess'),
          type: 'success',
          icon: 'info'
        })
        this.$emit('payment-success')
      } else {
        this.loading = false
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__extension {
  padding : 0 12px !important;
}

/deep/ .v-stepper__step {
  padding : 6px 24px;
}

/deep/ .v-stepper__header > hr {
  margin-top : 19px !important
}

@media only screen and (max-width : 959px) {
  /deep/ .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display : flex !important;
  }

  /deep/ .v-stepper__label {
    font-size : 12px;
  }
}
</style>
