<template>
  <div v-if="isVisible">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="60"
          extension-height="58"
          max-height="118"
        >
          <v-avatar color="primary">
            <v-icon dark>
              mdi-image-search
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Settings.Website.Field.Icons.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Settings.Website.Field.Icons.SubTitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <template #extension>
            <v-text-field
              v-model.trim="searchStr"
              :label="$t('Settings.Website.Field.Icons.PlaceHolder', {count: viewData.length})"
              :placeholder="$t('Settings.Website.Field.Icons.PlaceHolder', {count: viewData.length})"
              :background-color="isDarkMode ? 'grey darken-3' : 'grey lighten-3'"
              single-line
              hide-details
              clearable
              dense
              solo
              flat
            />
          </template>
        </v-toolbar>

        <v-divider />

        <v-card-text
          ref="cardText"
          class="pa-2"
          style="min-height: 260px;"
        >
          <v-row
            dense
            class="justify-center align-center"
            :class="{'fill-height': !viewDataFiltered.length || loading}"
          >
            <template v-if="loading">
              <v-col class="text-center">
                <div class="my-auto">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="48"
                    width="2"
                  />
                </div>
              </v-col>
            </template>

            <template v-else>
              <template v-if="viewDataFiltered.length">
                <template v-for="(icon, idx) in viewDataFiltered">
                  <v-lazy
                    :key="`icon-${idx}`"
                    :options="{threshold: .5}"
                    transition="fade-transition"
                  >
                    <v-col class="text-center">
                      <v-btn
                        icon
                        width="38"
                        height="38"
                        @click="onSelectIcon(`mdi-${icon.name}`)"
                      >
                        <v-icon size="30">
                          mdi-{{ icon.name }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-lazy>
                </template>
              </template>

              <template v-else>
                <v-col class="text-center">
                  <div class="my-auto">
                    <h2>{{ $t('Settings.Website.Field.Icons.NoIcons.Title') }}</h2>

                    <div class="mt-2">
                      {{ $t('Settings.Website.Field.Icons.NoIcons.SubTitle') }}
                    </div>
                  </div>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import axios   from 'axios'
import Appdata from '@/mixins/appdata'

const API = axios.create({
  baseURL     : process.env.VUE_APP_API_URL || '',
  timeout     : parseInt(process.env.VUE_APP_API_TIMEOUT) || 60000,
  responseType: 'json'
})

export default {
  name      : 'SelectIconDialog',
  components: {},
  mixins    : [Appdata],
  props     : {
    version: {
      type   : String,
      default: '7.4.47'
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      searchStr        : '',
      debounceSearchStr: '',
      loading          : false,
      debounceTimeout  : null,
      viewData         : []
    }
  },
  computed: {
    viewDataFiltered () {
      const str = this.debounceSearchStr?.toLowerCase()?.trim() || ''
      if (!str) return this.viewData?.slice(0, 80) || []

      return this.viewData?.filter(icon => {
        return icon.name?.split('-')?.some(item => item.includes(str)) ||
          icon.aliases?.some(item => item.includes(str)) ||
          icon.styles?.some(item => item.includes(str)) ||
          icon.tags?.some(item => item.includes(str))
      }) || []
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    searchStr: {
      handler (newVal, oldVal) {
        if (String(newVal).trim() !== String(oldVal).trim() && (String(newVal).trim().length >= 3 || String(newVal).trim().length === 0)) {
          clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debounceSearchStr = newVal?.trim() || ''
          }, 500)
        }
      },
      deep: false
    },

    isVisible (newVal) {
      if (newVal) {
        this.initData()
        this.getData()
      }
    }
  },
  created () {},
  beforeDestroy () {},
  methods: {
    async getData () {
      this.loading = true

      try {
        const response = await API.get(`https://cdn.jsdelivr.net/npm/@mdi/svg@${ this.version }/meta.json`)
        this.setData(response)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    setData (response) {
      if (response) {
        this.viewData = response?.data || []
      }
      this.loading = false
    },

    onSelectIcon (icon) {
      if (!icon) return
      this.$emit('icon:selected', icon)
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.initData()
    },

    initData () {
      this.searchStr = ''
      this.debounceSearchStr = ''
      this.debounceTimeout = null
      this.loading = false
      this.viewData = []
    }
  }
}
</script>

<style scoped>

</style>
