export const advancedThemeTreeItems = [
  {
    id      : 'global',
    name    : 'Global',
    children: [
      {
        id  : 'global.background.color',
        name: 'Background'
      },
      {
        id  : 'global.text.color',
        name: 'Text'
      },
      {
        id      : 'global.button',
        name    : 'Button',
        children: [
          {
            id  : 'global.button.background.color',
            name: 'Background'
          },
          {
            id  : 'global.button.text.color',
            name: 'Text'
          }
        ]
      }
    ]
  },
  {
    id      : 'common',
    name    : 'Common',
    children: [
      {
        id      : 'common.toolbar',
        name    : 'Toolbar',
        children: [
          {
            id  : 'common.toolbar.background.color',
            name: 'background'
          },
          {
            id  : 'common.toolbar.text.color',
            name: 'text'
          },
          {
            id      : 'common.toolbar.item',
            name    : 'item',
            children: [
              {
                id  : 'common.toolbar.item.text.color',
                name: 'text'
              },
              {
                id  : 'common.toolbar.item.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.toolbar.item.active.color',
                name: 'active'
              }
            ]
          }
        ]
      },
      {
        id      : 'common.footer',
        name    : 'Footer',
        children: [
          {
            id      : 'common.footer.mobile',
            name    : 'mobile',
            children: [
              {
                id  : 'common.footer.mobile.background.color',
                name: 'background'
              },
              {
                id      : 'common.footer.mobile.text',
                name    : 'text',
                children: [
                  {
                    id  : 'common.footer.mobile.text.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'common.footer.mobile.text.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'common.footer.mobile.text.description.color',
                    name: 'description'
                  }
                ]
              }
            ]
          },
          {
            id      : 'common.footer.social',
            name    : 'social',
            children: [
              {
                id  : 'common.footer.social.background.color',
                name: 'background'
              },
              {
                id  : 'common.footer.social.text.color',
                name: 'text'
              },
              {
                id  : 'common.footer.social.button.color',
                name: 'button'
              }
            ]
          },
          {
            id      : 'common.footer.links',
            name    : 'links',
            children: [
              {
                id  : 'common.footer.links.background.color',
                name: 'background'
              },
              {
                id  : 'common.footer.links.text.color',
                name: 'text'
              }
            ]
          },
          {
            id      : 'common.footer.copyright',
            name    : 'copyright',
            children: [
              {
                id  : 'common.footer.copyright.background.color',
                name: 'background'
              },
              {
                id  : 'common.footer.copyright.text.color',
                name: 'text'
              }
            ]
          }
        ]
      },
      {
        id      : 'common.cart',
        name    : 'Cart',
        children: [
          {
            id  : 'common.cart.background.color',
            name: 'background'
          },
          {
            id  : 'common.cart.text.color',
            name: 'text'
          },
          {
            id      : 'common.cart.toolbar',
            name    : 'toolbar',
            children: [
              {
                id  : 'common.cart.toolbar.background.color',
                name: 'background'
              },
              {
                id  : 'common.cart.toolbar.title.color',
                name: 'title'
              },
              {
                id      : 'common.cart.toolbar.icon',
                name    : 'icon',
                children: [
                  {
                    id  : 'common.cart.toolbar.icon.color',
                    name: 'color'
                  },
                  {
                    id      : 'common.cart.toolbar.icon.badge',
                    name    : 'badge',
                    children: [
                      {
                        id  : 'common.cart.toolbar.icon.badge.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.cart.toolbar.icon.badge.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id      : 'common.cart.button',
            name    : 'button',
            children: [
              {
                id  : 'common.cart.button.remove.color',
                name: 'remove'
              },
              {
                id      : 'common.cart.button.buyNow',
                name    : 'buyNow',
                children: [
                  {
                    id  : 'common.cart.button.buyNow.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'common.cart.button.buyNow.background.color',
                    name: 'background'
                  }
                ]
              },
              {
                id      : 'common.cart.button.orderType',
                name    : 'orderType',
                children: [
                  {
                    id      : 'common.cart.button.orderType.delivery',
                    name    : 'delivery',
                    children: [
                      {
                        id  : 'common.cart.button.orderType.delivery.text.color',
                        name: 'text'
                      },
                      {
                        id  : 'common.cart.button.orderType.delivery.background.color',
                        name: 'background'
                      }
                    ]
                  },
                  {
                    id      : 'common.cart.button.orderType.collection',
                    name    : 'collection',
                    children: [
                      {
                        id  : 'common.cart.button.orderType.collection.text.color',
                        name: 'text'
                      },
                      {
                        id  : 'common.cart.button.orderType.collection.background.color',
                        name: 'background'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id      : 'common.cart.coupon',
            name    : 'coupon',
            children: [
              {
                id  : 'common.cart.coupon.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.cart.coupon.text.color',
                name: 'text'
              },
              {
                id      : 'common.cart.coupon.button',
                name    : 'button',
                children: [
                  {
                    id  : 'common.cart.coupon.button.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'common.cart.coupon.button.background.color',
                    name: 'background'
                  }
                ]
              }
            ]
          },
          {
            id      : 'common.cart.tip',
            name    : 'tip',
            children: [
              {
                id  : 'common.cart.tip.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.cart.tip.text.color',
                name: 'text'
              },
              {
                id      : 'common.cart.tip.button',
                name    : 'button',
                children: [
                  {
                    id  : 'common.cart.tip.button.icon.color',
                    name: 'icon'
                  },
                  {
                    id  : 'common.cart.tip.button.icon.active.color',
                    name: 'icon active'
                  },
                  {
                    id  : 'common.cart.tip.button.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'common.cart.tip.button.text.active.color',
                    name: 'text active'
                  },
                  {
                    id  : 'common.cart.tip.button.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'common.cart.tip.button.background.active.color',
                    name: 'text active'
                  }
                ]
              }
            ]
          },
          {
            id      : 'common.cart.items',
            name    : 'items',
            children: [
              {
                id      : 'common.cart.items.category',
                name    : 'category',
                children: [
                  {
                    id  : 'common.cart.items.category.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'common.cart.items.category.background.color',
                    name: 'background'
                  }
                ]
              },
              {
                id      : 'common.cart.items.total',
                name    : 'total',
                children: [
                  {
                    id  : 'common.cart.items.total.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'common.cart.items.total.background.color',
                    name: 'background'
                  }
                ]
              },
              {
                id      : 'common.cart.items.product',
                name    : 'product',
                children: [
                  {
                    id      : 'common.cart.items.product.text',
                    name    : 'text',
                    children: [
                      {
                        id  : 'common.cart.items.product.text.price.color',
                        name: 'price'
                      },
                      {
                        id  : 'common.cart.items.product.text.title.quantity.color',
                        name: 'quantity'
                      },
                      {
                        id  : 'common.cart.items.product.text.title.color',
                        name: 'title'
                      },
                      {
                        id  : 'common.cart.items.product.text.description.color',
                        name: 'description'
                      }
                    ]
                  },
                  {
                    id  : 'common.cart.items.product.background.color',
                    name: 'background'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.productDialog',
        name    : 'Product Dialog',
        children: [
          {
            id      : 'common.productDialog.toolbar',
            name    : 'toolbar',
            children: [
              {
                id  : 'common.productDialog.toolbar.background.color',
                name: 'background'
              },
              {
                id  : 'common.productDialog.toolbar.title.color',
                name: 'title'
              },
              {
                id  : 'common.productDialog.toolbar.price.color',
                name: 'price'
              },
              {
                id  : 'common.productDialog.toolbar.quantity.color',
                name: 'quantity'
              }
            ]
          },
          {
            id      : 'common.productDialog.body',
            name    : 'body',
            children: [
              {
                id  : 'common.productDialog.body.background.color',
                name: 'background'
              },
              {
                id  : 'common.productDialog.body.description.color',
                name: 'description'
              },
              {
                id      : 'common.productDialog.body.options',
                name    : 'options',
                children: [
                  {
                    id  : 'common.productDialog.body.options.title.color',
                    name: 'title'
                  },
                  {
                    id      : 'common.productDialog.body.options.option',
                    name    : 'option',
                    children: [
                      {
                        id  : 'common.productDialog.body.options.option.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.productDialog.body.options.option.text.color',
                        name: 'text'
                      },
                      {
                        id  : 'common.productDialog.body.options.option.control.color',
                        name: 'control'
                      }
                    ]
                  }
                ]
              },
              {
                id  : 'common.productDialog.body.comments.color',
                name: 'comments'
              }
            ]
          },
          {
            id      : 'common.productDialog.actions',
            name    : 'actions',
            children: [
              {
                id  : 'common.productDialog.actions.background.color',
                name: 'background'
              },
              {
                id  : 'common.productDialog.actions.text.color',
                name: 'text'
              },
              {
                id      : 'common.productDialog.actions.quantity',
                name    : 'quantity',
                children: [
                  {
                    id  : 'common.productDialog.actions.quantity.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'common.productDialog.actions.quantity.button.color',
                    name: 'button'
                  }
                ]
              },
              {
                id      : 'common.productDialog.actions.button',
                name    : 'button',
                children: [
                  {
                    id  : 'common.productDialog.actions.button.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'common.productDialog.actions.button.background.color',
                    name: 'background'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.login',
        name    : 'login',
        children: [
          {
            id      : 'common.login.toolbar',
            name    : 'toolbar',
            children: [
              {
                id  : 'common.login.toolbar.background.color',
                name: 'background'
              },
              {
                id  : 'common.login.toolbar.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.login.toolbar.title.color',
                name: 'title'
              },
              {
                id  : 'common.login.toolbar.description.color',
                name: 'description'
              }
            ]
          },
          {
            id      : 'common.login.body',
            name    : 'body',
            children: [
              {
                id  : 'common.login.body.background.color',
                name: 'background'
              },
              {
                id  : 'common.login.body.title.color',
                name: 'title'
              },
              {
                id  : 'common.login.body.text.color',
                name: 'text'
              },
              {
                id  : 'common.login.body.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.login.body.link.color',
                name: 'link'
              },
              {
                id  : 'common.login.body.control.color',
                name: 'control'
              },
              {
                id      : 'common.login.body.button',
                name    : 'button',
                children: [
                  {
                    id      : 'common.login.body.button.login',
                    name    : 'login',
                    children: [
                      {
                        id  : 'common.login.body.button.login.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.login.body.button.login.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'common.login.body.button.register',
                    name    : 'register',
                    children: [
                      {
                        id  : 'common.login.body.button.register.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.login.body.button.register.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.register',
        name    : 'Register',
        children: [
          {
            id      : 'common.register.toolbar',
            name    : 'toolbar',
            children: [
              {
                id  : 'common.register.toolbar.background.color',
                name: 'background'
              },
              {
                id  : 'common.register.toolbar.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.register.toolbar.title.color',
                name: 'title'
              },
              {
                id  : 'common.register.toolbar.description.color',
                name: 'description'
              }
            ]
          },
          {
            id      : 'common.register.body',
            name    : 'body',
            children: [
              {
                id  : 'common.register.body.background.color',
                name: 'background'
              },
              {
                id  : 'common.register.body.title.color',
                name: 'title'
              },
              {
                id  : 'common.register.body.text.color',
                name: 'text'
              },
              {
                id  : 'common.register.body.link.color',
                name: 'link'
              },
              {
                id  : 'common.register.body.control.color',
                name: 'control'
              },
              {
                id      : 'common.register.body.button',
                name    : 'button',
                children: [
                  {
                    id      : 'common.register.body.button.register',
                    name    : 'register',
                    children: [
                      {
                        id  : 'common.register.body.button.register.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.register.body.button.register.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.forgotPassword',
        name    : 'Forgot Password',
        children: [
          {
            id      : 'common.forgotPassword.toolbar',
            name    : 'toolbar',
            children: [
              {
                id  : 'common.forgotPassword.toolbar.background.color',
                name: 'background'
              },
              {
                id  : 'common.forgotPassword.toolbar.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.forgotPassword.toolbar.title.color',
                name: 'title'
              },
              {
                id  : 'common.forgotPassword.toolbar.description.color',
                name: 'description'
              }
            ]
          },
          {
            id      : 'common.forgotPassword.body',
            name    : 'body',
            children: [
              {
                id  : 'common.forgotPassword.body.background.color',
                name: 'background'
              },
              {
                id  : 'common.forgotPassword.body.text.color',
                name: 'text'
              },
              {
                id  : 'common.forgotPassword.body.link.color',
                name: 'link'
              },
              {
                id  : 'common.forgotPassword.body.control.color',
                name: 'control'
              },
              {
                id      : 'common.forgotPassword.body.button',
                name    : 'button',
                children: [
                  {
                    id      : 'common.forgotPassword.body.button.reset',
                    name    : 'reset',
                    children: [
                      {
                        id  : 'common.forgotPassword.body.button.reset.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.forgotPassword.body.button.reset.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.gdpr',
        name    : 'GDPR',
        children: [
          {
            id      : 'common.gdpr.toolbar',
            name    : 'toolbar',
            children: [
              {
                id  : 'common.gdpr.toolbar.background.color',
                name: 'background'
              },
              {
                id  : 'common.gdpr.toolbar.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.gdpr.toolbar.title.color',
                name: 'title'
              },
              {
                id  : 'common.gdpr.toolbar.description.color',
                name: 'description'
              }
            ]
          },
          {
            id      : 'common.gdpr.body',
            name    : 'body',
            children: [
              {
                id  : 'common.gdpr.body.background.color',
                name: 'background'
              },
              {
                id  : 'common.gdpr.body.text.color',
                name: 'text'
              },
              {
                id  : 'common.gdpr.body.control.color',
                name: 'control'
              },
              {
                id      : 'common.gdpr.body.button',
                name    : 'button',
                children: [
                  {
                    id      : 'common.gdpr.body.button.check',
                    name    : 'check',
                    children: [
                      {
                        id  : 'common.gdpr.body.button.check.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.gdpr.body.button.check.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.confirm',
        name    : 'Confirm Dialog',
        children: [
          {
            id      : 'common.confirm.toolbar',
            name    : 'toolbar',
            children: [
              {
                id  : 'common.confirm.toolbar.background.color',
                name: 'background'
              },
              {
                id  : 'common.confirm.toolbar.icon.color',
                name: 'icon'
              },
              {
                id  : 'common.confirm.toolbar.title.color',
                name: 'title'
              },
              {
                id  : 'common.confirm.toolbar.description.color',
                name: 'description'
              }
            ]
          },
          {
            id      : 'common.confirm.body',
            name    : 'body',
            children: [
              {
                id  : 'common.confirm.body.background.color',
                name: 'background'
              },
              {
                id  : 'common.confirm.body.text.color',
                name: 'text'
              },
              {
                id  : 'common.confirm.body.link.color',
                name: 'link'
              }
            ]
          },
          {
            id      : 'common.confirm.actions',
            name    : 'actions',
            children: [
              {
                id  : 'common.confirm.actions.background.color',
                name: 'background'
              },
              {
                id      : 'common.confirm.actions.button',
                name    : 'button',
                children: [
                  {
                    id      : 'common.confirm.actions.button.cancel',
                    name    : 'cancel',
                    children: [
                      {
                        id  : 'common.confirm.actions.button.cancel.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.confirm.actions.button.cancel.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'common.confirm.actions.button.no',
                    name    : 'no',
                    children: [
                      {
                        id  : 'common.confirm.actions.button.no.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.confirm.actions.button.no.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'common.confirm.actions.yes.cancel',
                    name    : 'yes',
                    children: [
                      {
                        id  : 'common.confirm.actions.button.yes.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.confirm.actions.button.yes.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.sidebar',
        name    : 'Sidebar',
        children: [
          {
            id      : 'common.sidebar.needHelp',
            name    : 'Need Help',
            children: [
              {
                id      : 'common.sidebar.needHelp.toolbar',
                name    : 'toolbar',
                children: [
                  {
                    id  : 'common.sidebar.needHelp.toolbar.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'common.sidebar.needHelp.toolbar.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'common.sidebar.needHelp.toolbar.icon.color',
                    name: 'icon'
                  }
                ]
              },
              {
                id      : 'common.sidebar.needHelp.body',
                name    : 'body',
                children: [
                  {
                    id  : 'common.sidebar.needHelp.body.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'common.sidebar.needHelp.body.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'common.sidebar.needHelp.body.icon.color',
                    name: 'icon'
                  },
                  {
                    id  : 'common.sidebar.needHelp.body.label.color',
                    name: 'label'
                  },
                  {
                    id  : 'common.sidebar.needHelp.body.text.color',
                    name: 'text'
                  }
                ]
              }
            ]
          },
          {
            id      : 'common.sidebar.deliveryAreas',
            name    : 'Delivery Areas',
            children: [
              {
                id      : 'common.sidebar.deliveryAreas.toolbar',
                name    : 'toolbar',
                children: [
                  {
                    id  : 'common.sidebar.deliveryAreas.toolbar.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'common.sidebar.deliveryAreas.toolbar.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'common.sidebar.deliveryAreas.toolbar.icon.color',
                    name: 'icon'
                  }
                ]
              },
              {
                id      : 'common.sidebar.deliveryAreas.body',
                name    : 'body',
                children: [
                  {
                    id  : 'common.sidebar.deliveryAreas.body.background.color',
                    name: 'background'
                  },
                  {
                    id      : 'common.sidebar.deliveryAreas.body.list.area',
                    name    : 'list area',
                    children: [
                      {
                        id  : 'common.sidebar.deliveryAreas.body.list.area.title.color',
                        name: 'title'
                      },
                      {
                        id  : 'common.sidebar.deliveryAreas.body.list.area.text.color',
                        name: 'text'
                      },
                      {
                        id  : 'common.sidebar.deliveryAreas.body.list.area.selected.color',
                        name: 'selected'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id      : 'common.sidebar.workingHours',
            name    : 'Working Hours',
            children: [
              {
                id      : 'common.sidebar.workingHours.toolbar',
                name    : 'toolbar',
                children: [
                  {
                    id  : 'common.sidebar.workingHours.toolbar.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'common.sidebar.workingHours.toolbar.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'common.sidebar.workingHours.toolbar.icon.color',
                    name: 'icon'
                  }
                ]
              },
              {
                id      : 'common.sidebar.workingHours.body',
                name    : 'body',
                children: [
                  {
                    id  : 'common.sidebar.workingHours.body.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'common.sidebar.workingHours.body.text.color',
                    name: 'text'
                  },
                  {
                    id      : 'common.sidebar.workingHours.body.todayOpen',
                    name    : 'todayOpen',
                    children: [
                      {
                        id  : 'common.sidebar.workingHours.body.todayOpen.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.sidebar.workingHours.body.todayOpen.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'common.sidebar.workingHours.body.todayClosed',
                    name    : 'todayClosed',
                    children: [
                      {
                        id  : 'common.sidebar.workingHours.body.todayClosed.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'common.sidebar.workingHours.body.todayClosed.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'common.cookies',
        name    : 'cookies',
        children: [
          {
            id  : 'common.cookies.background.color',
            name: 'background'
          },
          {
            id  : 'common.cookies.text.color',
            name: 'text'
          },
          {
            id  : 'common.cookies.link.color',
            name: 'link'
          },
          {
            id      : 'common.cookies.button',
            name    : 'button',
            children: [
              {
                id  : 'common.cookies.button.background.color',
                name: 'background'
              },
              {
                id  : 'common.cookies.button.hover.color',
                name: 'hover'
              },
              {
                id  : 'common.cookies.button.text.color',
                name: 'text'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id      : 'pages',
    name    : 'Pages',
    children: [
      {
        id      : 'pages.home',
        name    : 'Home',
        children: [
          {
            id  : 'pages.home.background.color',
            name: 'background'
          },
          {
            id  : 'pages.home.text.color',
            name: 'text'
          },
          {
            id      : 'pages.home.sections',
            name    : 'Sections',
            children: [
              {
                id      : 'pages.home.sections.categories.color',
                name    : 'Categories',
                children: [
                  {
                    id  : 'pages.home.sections.categories.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.home.sections.categories.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'pages.home.sections.categories.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'pages.home.sections.categories.button.color',
                    name: 'button'
                  }
                ]
              },
              {
                id      : 'pages.home.sections.featuredProducts',
                name    : 'Featured Products',
                children: [
                  {
                    id  : 'pages.home.sections.featuredProducts.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.home.sections.featuredProducts.title.color',
                    name: 'title'
                  },
                  {
                    id      : 'pages.home.sections.featuredProducts.product',
                    name    : 'product',
                    children: [
                      {
                        id      : 'pages.home.sections.featuredProducts.product.image',
                        name    : 'image',
                        children: [
                          {
                            id  : 'pages.home.sections.featuredProducts.product.image.background.color',
                            name: 'background'
                          }
                        ]
                      },
                      {
                        id      : 'pages.home.sections.featuredProducts.product.text',
                        name    : 'text',
                        children: [
                          {
                            id  : 'pages.home.sections.featuredProducts.product.text.title.color',
                            name: 'title'
                          },
                          {
                            id  : 'pages.home.sections.featuredProducts.product.text.description.color',
                            name: 'description'
                          },
                          {
                            id  : 'pages.home.sections.featuredProducts.product.text.button.color',
                            name: 'button'
                          },
                          {
                            id  : 'pages.home.sections.featuredProducts.product.text.background.color',
                            name: 'background'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                id      : 'pages.home.sections.featuredCategories',
                name    : 'Featured Categories',
                children: [
                  {
                    id  : 'pages.home.sections.featuredCategories.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.home.sections.featuredCategories.title.color',
                    name: 'title'
                  },
                  {
                    id      : 'pages.home.sections.featuredCategories.category',
                    name    : 'category',
                    children: [
                      {
                        id      : 'pages.home.sections.featuredCategories.category.image',
                        name    : 'image',
                        children: [
                          {
                            id  : 'pages.home.sections.featuredCategories.category.image.background.color',
                            name: 'background'
                          }
                        ]
                      },
                      {
                        id      : 'pages.home.sections.featuredCategories.category.text',
                        name    : 'text',
                        children: [
                          {
                            id  : 'pages.home.sections.featuredCategories.category.text.title.color',
                            name: 'title'
                          },
                          {
                            id  : 'pages.home.sections.featuredCategories.category.text.button.color',
                            name: 'button'
                          },
                          {
                            id  : 'pages.home.sections.featuredCategories.category.text.background.color',
                            name: 'background'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                id      : 'pages.home.sections.offers',
                name    : 'Offers',
                children: [
                  {
                    id  : 'pages.home.sections.offers.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.home.sections.offers.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'pages.home.sections.offers.description.color',
                    name: 'description'
                  },
                  {
                    id      : 'pages.home.sections.offers.offer',
                    name    : 'offer',
                    children: [
                      {
                        id      : 'pages.home.sections.offers.offer.image',
                        name    : 'image',
                        children: [
                          {
                            id  : 'pages.home.sections.offers.offer.image.background.color',
                            name: 'background'
                          }
                        ]
                      },
                      {
                        id      : 'pages.home.sections.offers.offer.ribbon',
                        name    : 'ribbon',
                        children: [
                          {
                            id  : 'pages.home.sections.offers.offer.ribbon.background.color',
                            name: 'background'
                          },
                          {
                            id  : 'pages.home.sections.offers.offer.ribbon.text.color',
                            name: 'text'
                          }
                        ]
                      },
                      {
                        id      : 'pages.home.sections.offers.offer.text',
                        name    : 'text',
                        children: [
                          {
                            id  : 'pages.home.sections.offers.offer.text.title.color',
                            name: 'title'
                          },
                          {
                            id  : 'pages.home.sections.offers.offer.text.background.color',
                            name: 'background'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                id      : 'pages.home.sections.partners',
                name    : 'Partners',
                children: [
                  {
                    id  : 'pages.home.sections.partners.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.home.sections.partners.title.color',
                    name: 'title'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'pages.catalog',
        name    : 'Catalog',
        children: [
          {
            id  : 'pages.catalog.background.color',
            name: 'background'
          },
          {
            id  : 'pages.catalog.text.color',
            name: 'text'
          },
          {
            id      : 'pages.catalog.sections',
            name    : 'Sections',
            children: [
              {
                id      : 'pages.catalog.sections.categories',
                name    : 'Categories',
                children: [
                  {
                    id  : 'pages.catalog.sections.categories.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.catalog.sections.categories.active.color',
                    name: 'active'
                  },
                  {
                    id  : 'pages.catalog.sections.categories.text.color',
                    name: 'text'
                  },
                  {
                    id      : 'pages.catalog.sections.categories.tabs',
                    name    : 'tabs',
                    children: [
                      {
                        id  : 'pages.catalog.sections.categories.tabs.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.catalog.sections.categories.tabs.active.color',
                        name: 'active'
                      },
                      {
                        id  : 'pages.catalog.sections.categories.tabs.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              },
              {
                id      : 'pages.catalog.sections.catalog',
                name    : 'Catalog',
                children: [
                  {
                    id      : 'pages.catalog.sections.catalog.category',
                    name    : 'category',
                    children: [
                      {
                        id  : 'pages.catalog.sections.catalog.category.title.color',
                        name: 'title'
                      },
                      {
                        id  : 'pages.catalog.sections.catalog.category.background.color',
                        name: 'background'
                      }
                    ]
                  },
                  {
                    id      : 'pages.catalog.sections.catalog.product',
                    name    : 'product',
                    children: [
                      {
                        id      : 'pages.catalog.sections.catalog.product.number',
                        name    : 'number',
                        children: [
                          {
                            id  : 'pages.catalog.sections.catalog.product.number.background.color',
                            name: 'background'
                          },
                          {
                            id  : 'pages.catalog.sections.catalog.product.number.text.color',
                            name: 'text'
                          }
                        ]
                      },
                      {
                        id  : 'pages.catalog.sections.catalog.product.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.catalog.sections.catalog.product.quantity.color',
                        name: 'quantity'
                      },
                      {
                        id  : 'pages.catalog.sections.catalog.product.title.color',
                        name: 'title'
                      },
                      {
                        id  : 'pages.catalog.sections.catalog.product.description.color',
                        name: 'description'
                      },
                      {
                        id  : 'pages.catalog.sections.catalog.product.price.color',
                        name: 'price'
                      },
                      {
                        id      : 'pages.catalog.sections.catalog.product.quickAddButton',
                        name    : 'quickAddButton',
                        children: [
                          {
                            id  : 'pages.catalog.sections.catalog.product.quickAddButton.background.color',
                            name: 'background'
                          },
                          {
                            id  : 'pages.catalog.sections.catalog.product.quickAddButton.text.color',
                            name: 'text'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                id      : 'pages.catalog.sections.search',
                name    : 'Search',
                children: [
                  {
                    id  : 'pages.catalog.sections.search.color.color',
                    name: 'color'
                  },
                  {
                    id  : 'pages.catalog.sections.search.background.color',
                    name: 'background'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'pages.contact',
        name    : 'Contact',
        children: [
          {
            id  : 'pages.contact.background.color',
            name: 'background'
          },
          {
            id  : 'pages.contact.text.color',
            name: 'text'
          },
          {
            id      : 'pages.contact.form',
            name    : 'form',
            children: [
              {
                id      : 'pages.contact.form.section',
                name    : 'section',
                children: [
                  {
                    id  : 'pages.contact.form.section.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.contact.form.section.icon.color',
                    name: 'icon'
                  },
                  {
                    id  : 'pages.contact.form.section.title.color',
                    name: 'title'
                  }
                ]
              },
              {
                id      : 'pages.contact.form.button',
                name    : 'button',
                children: [
                  {
                    id  : 'pages.contact.form.section.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.contact.form.section.text.color',
                    name: 'text'
                  }
                ]
              },
              {
                id  : 'pages.contact.form.fields.color',
                name: 'fields'
              },
              {
                id  : 'pages.contact.form.text.color',
                name: 'text'
              }
            ]
          }
        ]
      },
      {
        id      : 'pages.account',
        name    : 'Account',
        children: [
          {
            id  : 'pages.account.background.color',
            name: 'background'
          },
          {
            id  : 'pages.account.text.color',
            name: 'text'
          },
          {
            id      : 'pages.account.tabs',
            name    : 'tabs',
            children: [
              {
                id  : 'pages.account.tabs.background.color',
                name: 'background'
              },
              {
                id  : 'pages.account.tabs.text.color',
                name: 'text'
              },
              {
                id  : 'pages.account.tabs.active.color',
                name: 'active'
              }
            ]
          },
          {
            id      : 'pages.account.profile',
            name    : 'profile',
            children: [
              {
                id  : 'pages.account.profile.controls.color',
                name: 'controls'
              },
              {
                id      : 'pages.account.profile.button',
                name    : 'button',
                children: [
                  {
                    id      : 'pages.account.profile.button.edit',
                    name    : 'edit',
                    children: [
                      {
                        id  : 'pages.account.profile.button.edit.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.edit.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.profile.button.cancel',
                    name    : 'cancel',
                    children: [
                      {
                        id  : 'pages.account.profile.button.cancel.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.cancel.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.profile.button.save',
                    name    : 'save',
                    children: [
                      {
                        id  : 'pages.account.profile.button.save.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.save.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.profile.button.manage',
                    name    : 'manage',
                    children: [
                      {
                        id  : 'pages.account.profile.button.manage.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.manage.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.profile.button.delete',
                    name    : 'delete',
                    children: [
                      {
                        id  : 'pages.account.profile.button.delete.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.delete.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.profile.button.disable',
                    name    : 'disable',
                    children: [
                      {
                        id  : 'pages.account.profile.button.disable.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.disable.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.profile.button.enable',
                    name    : 'enable',
                    children: [
                      {
                        id  : 'pages.account.profile.button.enable.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.enable.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.profile.button.export',
                    name    : 'export',
                    children: [
                      {
                        id  : 'pages.account.profile.button.export.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.profile.button.export.text.color',
                        name: 'text'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id      : 'pages.account.addresses',
            name    : 'addresses',
            children: [
              {
                id      : 'pages.account.addresses.card',
                name    : 'card',
                children: [
                  {
                    id  : 'pages.account.addresses.card.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.account.addresses.card.text.color',
                    name: 'text'
                  }
                ]
              },
              {
                id      : 'pages.account.addresses.button',
                name    : 'button',
                children: [
                  {
                    id      : 'pages.account.addresses.button.add',
                    name    : 'add',
                    children: [
                      {
                        id  : 'pages.account.addresses.button.add.color',
                        name: 'color'
                      },
                      {
                        id  : 'pages.account.addresses.button.add.text.color',
                        name: 'text'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.addresses.button.edit',
                    name    : 'edit',
                    children: [
                      {
                        id  : 'pages.account.addresses.button.edit.color',
                        name: 'color'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.addresses.button.delete',
                    name    : 'delete',
                    children: [
                      {
                        id  : 'pages.account.addresses.button.delete.color',
                        name: 'color'
                      }
                    ]
                  }
                ]
              },
              {
                id      : 'pages.account.addresses.addressDialog',
                name    : 'Address Dialog',
                children: [
                  {
                    id      : 'pages.account.addresses.addressDialog.toolbar',
                    name    : 'toolbar',
                    children: [
                      {
                        id  : 'pages.account.addresses.addressDialog.toolbar.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.account.addresses.addressDialog.toolbar.title.color',
                        name: 'title'
                      },
                      {
                        id  : 'pages.account.addresses.addressDialog.toolbar.description.color',
                        name: 'description'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.addresses.addressDialog.body',
                    name    : 'body',
                    children: [
                      {
                        id  : 'pages.account.addresses.addressDialog.body.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.account.addresses.addressDialog.body.text.color',
                        name: 'text'
                      },
                      {
                        id  : 'pages.account.addresses.addressDialog.body.controls.color',
                        name: 'controls'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.addresses.addressDialog.actions',
                    name    : 'actions',
                    children: [
                      {
                        id  : 'pages.account.addresses.addressDialog.actions.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.account.addresses.addressDialog.actions.text.color',
                        name: 'text'
                      },
                      {
                        id      : 'pages.account.addresses.addressDialog.actions.button',
                        name    : 'button',
                        children: [
                          {
                            id      : 'pages.account.addresses.addressDialog.actions.button.cancel',
                            name    : 'cancel',
                            children: [
                              {
                                id  : 'pages.account.addresses.addressDialog.actions.button.cancel.background.color',
                                name: 'background'
                              },
                              {
                                id  : 'pages.account.addresses.addressDialog.actions.button.cancel.text.color',
                                name: 'text'
                              }
                            ]
                          },
                          {
                            id      : 'pages.account.addresses.addressDialog.actions.button.save',
                            name    : 'save',
                            children: [
                              {
                                id  : 'pages.account.addresses.addressDialog.actions.button.save.background.color',
                                name: 'background'
                              },
                              {
                                id  : 'pages.account.addresses.addressDialog.actions.button.save.text.color',
                                name: 'text'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                id      : 'pages.account.addresses.selectAddressDialog',
                name    : 'Select Address Dialog',
                children: [
                  {
                    id      : 'pages.account.addresses.selectAddressDialog.toolbar',
                    name    : 'toolbar',
                    children: [
                      {
                        id  : 'pages.account.addresses.selectAddressDialog.toolbar.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.account.addresses.selectAddressDialog.toolbar.title.color',
                        name: 'title'
                      },
                      {
                        id  : 'pages.account.addresses.selectAddressDialog.toolbar.description.color',
                        name: 'description'
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.addresses.selectAddressDialog.body',
                    name    : 'body',
                    children: [
                      {
                        id  : 'pages.account.addresses.selectAddressDialog.body.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.account.addresses.selectAddressDialog.body.text.color',
                        name: 'text'
                      },
                      {
                        id      : 'pages.account.addresses.selectAddressDialog.body.list.item',
                        name    : 'List Item',
                        children: [
                          {
                            id  : 'pages.account.addresses.selectAddressDialog.body.list.item.background',
                            name: 'background'
                          },
                          {
                            id  : 'pages.account.addresses.selectAddressDialog.body.list.item.title.color',
                            name: 'title'
                          },
                          {
                            id      : 'pages.account.addresses.selectAddressDialog.body.list.item.address',
                            name    : 'address',
                            children: [
                              {
                                id  : 'pages.account.addresses.selectAddressDialog.body.list.item.address.title.color',
                                name: 'title'
                              },
                              {
                                id  : 'pages.account.addresses.selectAddressDialog.body.list.item.address.text.color',
                                name: 'text'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    id      : 'pages.account.addresses.selectAddressDialog.actions',
                    name    : 'actions',
                    children: [
                      {
                        id  : 'pages.account.addresses.selectAddressDialog.actions.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.account.addresses.selectAddressDialog.actions.text.color',
                        name: 'text'
                      },
                      {
                        id      : 'pages.account.addresses.selectAddressDialog.actions.button',
                        name    : 'button',
                        children: [
                          {
                            id      : 'pages.account.addresses.selectAddressDialog.actions.button.cancel',
                            name    : 'cancel',
                            children: [
                              {
                                id  : 'pages.account.addresses.selectAddressDialog.actions.button.cancel.background.color',
                                name: 'background'
                              },
                              {
                                id  : 'pages.account.addresses.selectAddressDialog.actions.button.cancel.text.color',
                                name: 'text'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id      : 'pages.account.orders',
            name    : 'orders',
            children: [
              {
                id      : 'pages.account.orders.list.item',
                name    : 'List Item',
                children: [
                  {
                    id  : 'pages.account.orders.list.item.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.account.orders.list.item.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'pages.account.orders.list.item.subtitle.color',
                    name: 'subtitle'
                  },
                  {
                    id  : 'pages.account.orders.list.item.price.color',
                    name: 'price'
                  },
                  {
                    id      : 'pages.account.orders.list.item.button',
                    name    : 'button',
                    children: [
                      {
                        id  : 'pages.account.orders.list.item.button.view.color',
                        name: 'view'
                      },
                      {
                        id  : 'pages.account.orders.list.item.button.reorder.color',
                        name: 'reorder'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'pages.checkout',
        name    : 'Checkout',
        children: [
          {
            id  : 'pages.checkout.background.color',
            name: 'background'
          },
          {
            id  : 'pages.checkout.text.color',
            name: 'text'
          },
          {
            id      : 'pages.checkout.steps',
            name    : 'steps',
            children: [
              {
                id  : 'pages.checkout.steps.background.color',
                name: 'background'
              }
            ]
          },
          {
            id      : 'pages.checkout.details',
            name    : 'details',
            children: [
              {
                id      : 'pages.checkout.details.user',
                name    : 'user',
                children: [
                  {
                    id  : 'pages.checkout.details.user.color',
                    name: 'color'
                  },
                  {
                    id  : 'pages.checkout.details.user.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'pages.checkout.details.user.link.color',
                    name: 'link'
                  }
                ]
              },
              {
                id      : 'pages.checkout.details.header',
                name    : 'header',
                children: [
                  {
                    id  : 'pages.checkout.details.header.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.checkout.details.header.title.color',
                    name: 'title'
                  },
                  {
                    id  : 'pages.checkout.details.header.icon.color',
                    name: 'icon'
                  }
                ]
              },
              {
                id      : 'pages.checkout.details.address',
                name    : 'address',
                children: [
                  {
                    id      : 'pages.checkout.details.address.card',
                    name    : 'card',
                    children: [
                      {
                        id  : 'pages.checkout.details.address.card.background.color',
                        name: 'background'
                      },
                      {
                        id  : 'pages.checkout.details.address.card.text.color',
                        name: 'text'
                      },
                      {
                        id      : 'pages.checkout.details.address.card.button',
                        name    : 'button',
                        children: [
                          {
                            id      : 'pages.checkout.details.address.card.button.add',
                            name    : 'add',
                            children: [
                              {
                                id  : 'pages.checkout.details.address.card.button.add.color',
                                name: 'color'
                              },
                              {
                                id  : 'pages.checkout.details.address.card.button.add.text.color',
                                name: 'text'
                              }
                            ]
                          },
                          {
                            id      : 'pages.checkout.details.address.card.button.change',
                            name    : 'change',
                            children: [
                              {
                                id  : 'pages.checkout.details.address.card.button.change.color',
                                name: 'color'
                              },
                              {
                                id  : 'pages.checkout.details.address.card.button.change.text.color',
                                name: 'text'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id      : 'pages.informational',
        name    : 'Informational',
        children: [
          {
            id  : 'pages.informational.background.color',
            name: 'background'
          },
          {
            id  : 'pages.informational.text.color',
            name: 'text'
          },
          {
            id      : 'pages.informational.content',
            name    : 'content',
            children: [
              {
                id  : 'pages.informational.content.background.color',
                name: 'background'
              },
              {
                id      : 'pages.informational.content.list',
                name    : 'list',
                children: [
                  {
                    id  : 'pages.informational.content.list.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.informational.content.list.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'pages.informational.content.list.active.color',
                    name: 'active'
                  }
                ]
              },
              {
                id      : 'pages.informational.content.tabs',
                name    : 'tabs',
                children: [
                  {
                    id  : 'pages.informational.content.tabs.background.color',
                    name: 'background'
                  },
                  {
                    id  : 'pages.informational.content.tabs.text.color',
                    name: 'text'
                  },
                  {
                    id  : 'pages.informational.content.tabs.active.color',
                    name: 'active'
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]
