<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="650"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :class="[orderType.toString()==='1' ? 'purple' : 'blue darken-3']">
            <v-icon dark>
              timer
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ orderType.toString() === '1' ? $t('Pos.SelectOrderTypeTimeDialog.Toolbar.Title.Delivery') : $t('Pos.SelectOrderTypeTimeDialog.Toolbar.Title.Collection') }}
            </div>
            <div class="text-caption">
              {{ orderType.toString() === '1' ? $t('Pos.SelectOrderTypeTimeDialog.Toolbar.Subtitle.Delivery') : $t('Pos.SelectOrderTypeTimeDialog.Toolbar.Subtitle.Collection') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-container fluid>
            <v-row dense>
              <v-col
                v-for="(item, index) in times"
                :key="index"
                cols="4"
              >
                <v-list
                  class="mb- mt- pb-0 pt-0"
                  two-line
                >
                  <v-list-item
                    :class="[getItemColor(item).bgColor, {'pa-1': !$vuetify.breakpoint.xsOnly}]"
                    ripple
                    @click="setTime(item)"
                  >
                    <v-list-item-content>
                      <div
                        class="text-center text-no-wrap font-weight-bold text-lowercase"
                        style="width: 100%;"
                      >
                        <v-icon
                          :color="getItemColor(item).iconColor"
                          size="24"
                        >
                          timer
                        </v-icon>
                      </div>
                      <div
                        :class="[getItemColor(item).textColor, {'caption': $vuetify.breakpoint.xsOnly, 'title': $vuetify.breakpoint.smAndUp}]"
                        class="text-center text-no-wrap font-weight-bold text-lowercase"
                        style="width: 100%;"
                      >
                        {{ convertMinutesToDisplayString(item) }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </v-list>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-checkbox
                  v-model="setAsDefault"
                  hide-details
                  :label="orderType.toString() === '1' ? $t('Pos.SelectOrderTypeTimeDialog.SetAsDefault.Delivery') : $t('Pos.SelectOrderTypeTimeDialog.SetAsDefault.Collection')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppData       from '../../mixins/appdata'
import OrderTypeTime from '../../mixins/orders/orderTypeTime'

export default {
  components: {},
  mixins    : [AppData, OrderTypeTime],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    selectedTime: {
      type    : [String, Number],
      required: true
    },
    orderType: {
      type    : [String, Number],
      required: true
    },
    updateStatus: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      setAsDefault  : false,
      isTimeSelected: false
    }
  },
  computed: {
    times () {
      return this.orderType.toString() === '1' ? this.deliveryTimeRange : this.collectionTimeRange
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    currentTime: {
      get () {
        return this.selectedTime
      },
      set (val) {
        this.$emit('update:selectedTime', val)
      }
    },
    currentStatus: {
      get () {
        return this.updateStatus
      },
      set (val) {
        this.$emit('update:updateStatus', val)
      }
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.isTimeSelected = false
        this.setAsDefault = false
      }
    }
  },
  methods: {
    setTime (time) {
      if (this.isTimeSelected) return
      this.isTimeSelected = true
      this.currentTime = parseInt(time)
      this.currentStatus = true
      this.isVisible = false

      this.$emit('update-order-time', {
        time        : parseInt(time),
        setAsDefault: this.setAsDefault ?? false
      })

      this.$emit('cart:order-type-time-selected', parseInt(time))
    },
    onCancelButtonClick () {
      this.setAsDefault = false
      this.currentStatus = false
      this.isVisible = false
    },
    getItemColor (item) {
      const retVal = {
        icon     : 'timer',
        iconColor: this.orderType.toString() === '1' ? 'purple' : 'blue darken-3',
        bgColor  : this.isDarkMode ? 'grey darken-3' : 'grey lighten-4',
        textColor: this.isDarkMode ? 'grey--text text--lighten-1' : 'grey--text text--darken-1'
      }
      const orderDefaultTime = this.orderType.toString() === '1' ? this.orderTypeDefaultTimes.delivery_time : this.orderTypeDefaultTimes.collection_time

      if (this.currentTime.toString() === item.toString()) {
        retVal.iconColor = this.orderType.toString() === '1' ? 'purple' : 'blue darken-3'
        retVal.bgColor = this.orderType.toString() === '1' ? (this.isDarkMode ? 'purple darken-4' : 'purple lighten-5') : (this.isDarkMode ? 'blue darken-4' : 'blue lighten-5')
        retVal.textColor = this.orderType.toString() === '1' ? 'purple--text' : 'blue--text text--darken-3'
      }

      if (orderDefaultTime.toString() === item.toString()) {
        retVal.iconColor = 'green'
      }

      return retVal
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile--link {
  padding : 0 !important;
}
</style>
