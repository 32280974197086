export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [],
  directives: {},
  watch     : {},
  methods   : {
    getReservationTagById (tagId) {
      return this.reservationsTags.find(tag => parseInt(tag.Id) === parseInt(tagId))
    }
  },
  computed: {
    reservationsTags () {
      return this.$t('Reservation.Tags.Items')
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {}
}
