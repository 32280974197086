/* eslint-disable no-console */

import axios               from 'axios'
import semver              from 'semver'
import isElectron          from '@/electron/isElectron'
import ipcRendererElectron from '@/electron/ipcRendererElectron'
import ipcCommandsEnum     from '@/electron/ipcCommandsEnum'
import SnotifyService      from '@/components/common/vue-snotify/SnotifyService'
import i18n                from '@/lang/lang'
import { EventBus }        from '@/events/eventBus'

window.AppUpdateCloudPollingIntervalId = null
window.AppUpdateCloudReminderTimeoutID = null
window.AppUpdateCloudSnotifyId = null

export const API_UPDATE = axios.create({
  baseURL        : process.env.VUE_APP_POS_URL || '',
  timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 60000,
  responseType   : 'json',
  crossDomain    : true,
  withCredentials: false,
  headers        : { 'X-Requested-With': 'XMLHttpRequest' }
})

// Request interceptor
API_UPDATE.interceptors.request.use((request) => {
  return request
}, function (error) {
  return Promise.reject(error)
})

// Response interceptor
API_UPDATE.interceptors.response.use((response) => {
  return response
}, function (error) {
  return Promise.reject(error)
})

const performCloudUpdate = () => {
  EventBus.$emit('show-app-loading-modal')

  setTimeout(() => {
    if (isElectron()) {
      const ipcRenderer = ipcRendererElectron()
      ipcRenderer.send(ipcCommandsEnum.Reload)
    } else {
      location && location.reload()
    }
  }, 1000)
}

const checkForUpdateInternal = async () => {
  let updateExists = false
  let serverVersion = null
  const currentVersion = semver.valid(semver.coerce(process.env.VUE_APP_VERSION))

  try {
    const response = await API_UPDATE.get('version.json', {})

    if (response && response.status === 200 && currentVersion) {
      serverVersion = semver.valid(semver.coerce(response.data.version))
      if (serverVersion) updateExists = semver.lt(currentVersion, serverVersion)
    }
  } catch (e) {
    console.error(e)
  }

  console.log('--- Check For Cloud Update ---')
  console.log('  - Current Version:', currentVersion)
  console.log('  - Server Version:', serverVersion)
  console.log('  - Update Exists:', updateExists)
  console.log('-----------------------------')

  const data = {
    HasUpdate     : updateExists,
    CurrentVersion: currentVersion,
    ServerVersion : serverVersion
  }

  if (updateExists) {
    stopCloudUpdatePollingCheck()
    stopCloudUpdateDelayedNotifications()

    EventBus.$emit('app-cloud-update-exists', data)

    notifyUser(data)
  }

  window.Vue.$DataStore.cloudUpdate = data

  return updateExists
}

const notifyUser = (updateData) => {
  stopCloudUpdatePollingCheck()
  stopCloudUpdateDelayedNotifications()

  const data = {
    title: i18n.t('Common.Update.Cloud.Title'),
    body : updateData ? i18n.t('Common.Update.Cloud.BodyWithVersion', {
      CurrentVersion: updateData.CurrentVersion,
      ServerVersion : updateData.ServerVersion
    }) : i18n.t('Common.Update.Cloud.BodyNoVersion'),
    timeout     : -1,
    closeOnClick: false,
    type        : 'warning',
    icon        : 'info',
    buttons     : [
      {
        text  : i18n.t('Common.Button.Close').toLocaleUpperCase(i18n.locale),
        bold  : false,
        class : 'grey caption',
        action: () => {
          window.AppUpdateCloudSnotifyId && SnotifyService.remove(window.AppUpdateCloudSnotifyId)
        }
      },
      {
        text  : i18n.t('Common.OrdersQueue.Notification.Button.Remind').toLocaleUpperCase(i18n.locale),
        bold  : false,
        class : 'blue caption',
        action: () => {
          window.AppUpdateCloudSnotifyId && SnotifyService.remove(window.AppUpdateCloudSnotifyId)
          notificationSnooze(updateData)
        }
      },
      {
        text  : `${ i18n.t('Common.Button.Update').toLocaleUpperCase(i18n.locale) } ${ i18n.t('Common.Date.Now').toLocaleUpperCase(i18n.locale) }`,
        bold  : false,
        class : 'green caption font-weight-bold',
        action: () => {
          window.AppUpdateCloudSnotifyId && SnotifyService.remove(window.AppUpdateCloudSnotifyId)
          performCloudUpdate()
        }
      }
    ]
  }

  window.AppUpdateCloudSnotifyId && SnotifyService.remove(window.AppUpdateCloudSnotifyId)

  if (window?.Vue?.$route?.name === 'Pos') {
    notificationSnooze(updateData, 0.25)
  } else {
    window.AppUpdateCloudSnotifyId = SnotifyService[data.type](data.body, data.title, {
      timeout        : data.hasOwnProperty('timeout') ? data.timeout : 2000,
      showProgressBar: data.hasOwnProperty('showProgressBar') ? data.showProgressBar : true,
      closeOnClick   : data.hasOwnProperty('closeOnClick') ? data.closeOnClick : true,
      pauseOnHover   : data.hasOwnProperty('pauseOnHover') ? data.pauseOnHover : true,
      position       : data.position ? data.position : 'centerTop',
      buttons        : data.buttons ? data.buttons : [],
      icon           : data.icon ? data.icon : null
    })
  }
}

const notificationSnooze = (updateData, delayInMinutes = 5) => {
  window.AppUpdateCloudReminderTimeoutID = setTimeout(() => {
    notifyUser(updateData)
  }, 1000 * 60 * delayInMinutes)
}
export const stopCloudUpdateDelayedNotifications = () => {
  if (window.AppUpdateCloudReminderTimeoutID) {
    clearTimeout(window.AppUpdateCloudReminderTimeoutID)
    window.AppUpdateCloudReminderTimeoutID = null
  }
}

const startCloudUpdatePollingCheck = () => {
  const AppUpdateCloudEnabled = window?.appConfig?.APP_UPDATE?.Cloud?.Enabled ?? false
  const AppUpdateCloudPollingEnabled = window?.appConfig?.APP_UPDATE?.Cloud?.PollingEnabled ?? false
  const AppUpdateCloudPollingTimeout = window?.appConfig?.APP_UPDATE?.Cloud?.PollingTimeout ?? 60000

  stopCloudUpdatePollingCheck()

  if (AppUpdateCloudEnabled && AppUpdateCloudPollingEnabled) {
    window.AppUpdateCloudPollingIntervalId = setInterval(checkForUpdateInternal, AppUpdateCloudPollingTimeout)
  }
}
export const stopCloudUpdatePollingCheck = () => {
  if (window.AppUpdateCloudPollingIntervalId) {
    clearInterval(window.AppUpdateCloudPollingIntervalId)
    window.AppUpdateCloudPollingIntervalId = null
  }
}

export const checkForCloudUpdate = async () => {
  let updateExists = false
  const AppUpdateCloudEnabled = window?.appConfig?.APP_UPDATE?.Cloud?.Enabled ?? false

  if (AppUpdateCloudEnabled) {
    updateExists = await checkForUpdateInternal()

    if (updateExists) performCloudUpdate()

    startCloudUpdatePollingCheck()
  }

  return updateExists
}

EventBus.$on('app-cloud-update-notify-user', notifyUser)
