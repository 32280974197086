const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const WEEK = DAY * 7
const MONTH = DAY * 30
const YEAR = DAY * 365

function pluralOrSingular (data, locale) {
  if (data === 'just now') {
    return locale
  }

  const count = data < 1 ? Math.ceil(data) : Math.round(data)
  if (Array.isArray(locale)) {
    return count > 1
      ? locale[1].replace(/%s/, count)
      : locale[0].replace(/%s/, count)
  }
  return locale.replace(/%s/, count)
}

function formatTime (time) {
  const d = new Date(time)
  return d.toLocaleString()
}

export default function install (Vue, {
  name = 'timeago',
  locale = 'en'
} = {}) {
  const VueTimeago = {
    props: {
      since: {
        required: true
      },
      locale    : String,
      maxTime   : Number,
      autoUpdate: Number,
      format    : Function,
      minValCalc: {
        default: 'seconds'
      }
    },
    data () {
      // Todo: Transfer to proper lang file
      return {
        now    : new Date().getTime(),
        locales: {
          en: [
            'Just Now',
            ['%s Second', '%s Seconds'],
            ['%s Minute', '%s Minutes'],
            ['%s Hour', '%s Hours'],
            ['%s Day', '%s Days'],
            ['%s Week', '%s Weeks'],
            ['%s Month', '%s Months'],
            ['%s Year', '%s Years'],
            ['Today', 'Today']
          ],
          el: [
            'Τώρα',
            ['%s Δευτερόλεπτο', '%s Δευτερόλεπτα'],
            ['%s Λεπτό', '%s Λεπτά'],
            ['%s Ώρα', '%s Ώρες'],
            ['%s Ημέρα', '%s Ημέρες'],
            ['%s Εβδομάδα', '%s Εβδομάδες'],
            ['%s Μήνα', '%s Μήνες'],
            ['%s Έτος', '%s Έτη'],
            ['Σήμερα', 'Σήμερα']
          ]
        }
      }
    },
    computed: {
      currentLocale () {
        const current = this.locales[this.locale || locale]
        if (!current) {
          return this.locales[locale]
        }
        return current
      },
      sinceTime () {
        return new Date(this.since).getTime()
      },
      timeago () {
        const seconds = this.now / 1000 - this.sinceTime / 1000

        if (this.maxTime && seconds > this.maxTime) {
          clearInterval(this.interval)
          return this.format
            ? this.format(this.sinceTime)
            : formatTime(this.sinceTime)
        }

        const ret =
                seconds <= 5
                  ? this.minValCalc === 'seconds' ? pluralOrSingular('just now', this.currentLocale[0]) : pluralOrSingular('', this.currentLocale[8])
                  : seconds < MINUTE
                    ? this.minValCalc === 'seconds' ? pluralOrSingular(seconds, this.currentLocale[1]) : pluralOrSingular('', this.currentLocale[8])
                    : seconds < HOUR
                      ? this.minValCalc === 'seconds' ? pluralOrSingular(seconds / MINUTE, this.currentLocale[2]) : pluralOrSingular('', this.currentLocale[8])
                      : seconds < DAY
                        ? this.minValCalc === 'seconds' ? pluralOrSingular(seconds / HOUR, this.currentLocale[3]) : pluralOrSingular('', this.currentLocale[8])
                        : seconds < WEEK
                          ? pluralOrSingular((seconds / DAY) - 1, this.currentLocale[4])
                          : seconds < MONTH
                            ? pluralOrSingular(seconds / WEEK, this.currentLocale[5])
                            : seconds < YEAR
                              ? pluralOrSingular(
                                seconds / MONTH,
                                this.currentLocale[6]
                              )
                              : pluralOrSingular(
                                seconds / YEAR,
                                this.currentLocale[7]
                              )

        return ret
      }
    },
    mounted () {
      if (this.autoUpdate) {
        this.update()
      }
    },
    render (h) {
      return h('time', { attrs: { datetime: new Date(this.since) } }, this.timeago)
    },
    watch: {
      autoUpdate (newAutoUpdate) {
        this.stopUpdate()
        // only update when it's not falsy value
        // which means you cans set it to 0 to disable auto-update
        if (newAutoUpdate) {
          this.update()
        }
      }
    },
    methods: {
      update () {
        const period = this.autoUpdate * 1000
        this.interval = setInterval(() => {
          this.now = new Date().getTime()
        }, period)
      },
      stopUpdate () {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    beforeDestroy () {
      this.stopUpdate()
    }
  }

  Vue.component(name, VueTimeago)
}
