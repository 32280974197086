/**
 * Enum for tag groups.
 * @readonly
 * @enum {String} TagGroupsEnum
 */

const TagGroupsEnum = {
  SUGGEST_CART   : 'suggest-cart',
  FORGET_ADD_CART: 'forget-add-cart',
  SMOKE          : 'smoke',
  ALCOHOL        : 'alcohol',
  OVER_18        : 'over-18',
  OVER_16        : 'over-16'
}

export default TagGroupsEnum
