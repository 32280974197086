export default {
  Menu: {
    Toolbar: {
      Button: {
        New: {
          Title: 'Νέο προϊόν',
          Icon : 'add_box',
          Color: 'primary'
        },
        Catalogs: {
          Title: 'Κατάλογοι',
          Icon : '',
          Color: 'primary'
        },
        Categories: {
          Title: 'Κατηγορίες',
          Icon : '',
          Color: 'primary'
        },
        Options: {
          Title: 'Επιλογές Προϊόντων',
          Icon : '',
          Color: 'primary'
        },
        Tags: {
          Title: 'Ετικέτες',
          Icon : '',
          Color: 'primary'
        },
        Export: {
          Title         : 'Εξαγωγή',
          Subtitle      : 'Εξαγωγή προϊόντων σε Excel',
          Icon          : 'mdi-export',
          Color         : 'primary',
          SuccessMessage: 'Σύντομα θα λάβετε αρχείο με την εξαγωγή των προϊόντων στο email: {email}'
        },
        Import: {
          Title         : 'Εισαγωγή',
          Subtitle      : 'Εισαγωγή προϊόντων από',
          Icon          : 'mdi-import',
          Color         : 'primary',
          SuccessMessage: 'Η εισαγωγή προϊόντων ολοκληρώθηκε με επιτυχία.'
        }
      },
      Field: {
        Select: {
          Label: 'Επιλογή Κατηγορίας',
          Icon : 'category'
        }
      }
    },
    Table: {
      Headers: {
        Image        : '',
        Id           : '#',
        Name         : 'Όνομα',
        Category     : 'Κατηγορία',
        Catalog      : 'Κατάλογος',
        Tags         : 'Ετικέτες',
        Tax          : 'ΦΠΑ',
        Languages    : 'Γλώσσες',
        Price        : 'Τιμή',
        SpecialStatus: 'Προσφορά',
        OfferActive  : 'Πρ. Ενεργή',
        Priority     : 'Προτεραιότητα',
        Stock        : 'Απόθεμα',
        Status       : 'Κατάσταση',
        Visible      : 'Εμφάνιση',
        Active       : 'Online',
        ActivePos    : 'Pos',
        Featured     : 'Προτεινόμενο',
        Actions      : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Διαγραφή Προϊόντος;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε το προϊόν;',
        Notification: 'Η διαγραφή είναι οριστική και η διαδικασία είναι μη αναστρέψιμη'
      }
    },
    Notification: {
      Delete: 'Το προϊόν δεν διαγράφηκε. @:Common.Misc.Retry'
    },
    Filter: {
      filterCatalog: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Κατάλογος Προϊόντων',
        PlaceHolder: 'Όλοι οι κατάλογοι προϊόντων',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterCategory: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Κατηγορία Προϊόντων',
        PlaceHolder: 'Όλες οι κατηγορίες προϊόντων',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterActive: {
        Type       : 'v-btn-toggle',
        Label      : 'Εμφάνιση Online',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterActivePos: {
        Type       : 'v-btn-toggle',
        Label      : 'Εμφάνιση Pos',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterFeatured: {
        Type       : 'v-btn-toggle',
        Label      : 'Προτεινόμενο',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργό',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργό',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Κατάσταση',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterResetStock: {
        Type       : 'v-btn-toggle',
        Label      : 'Αυτοματοποίηση Αποθέματος',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterResetStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Αυτοματοποίηση Κατάστασης',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterCustomPrice: {
        Type       : 'v-btn-toggle',
        Label      : 'Προσαρμοσμένη Τιμή',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterCalculatedPrice: {
        Type       : 'v-btn-toggle',
        Label      : 'Τιμή με Κόστος Επιλογών',
        PlaceHolder: 'Όλα τα προϊόντα',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλα'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      }
    }
  },

  MenuStatusUpdateDialog: {
    Toolbar: {
      Title   : 'Αυτοματοποίηση Κατάστασης Προϊόντος',
      Subtitle: 'Αυτοματοποίηση αλλαγής κατάστασης προϊόντος',
      Icon    : 'mdi-shape-circle-plus',
      Color   : 'indigo'
    },
    Field: {
      Time: {
        Label      : 'Ώρα',
        Hint       : 'Ώρα αυτόματης αλλαγής κατάστασης προϊόντος',
        Placeholder: 'ΩΩ:ΛΛ',
        Icon       : 'mdi-calendar-clock',
        Error      : {
          Required  : 'Το πεδίο είναι υποχρεωτικό',
          TimeString: 'Πρέπει να είναι έγκυρη ώρα της μορφής ΩΩ:ΛΛ'
        }
      }
    }
  },

  MenuStockUpdateDialog: {
    Toolbar: {
      Title   : 'Αυτοματοποίηση Αποθέματος',
      Subtitle: 'Αυτοματοποίηση αναπλήρωσης αποθέματος',
      Icon    : 'mdi-shape-circle-plus',
      Color   : 'indigo'
    },
    Field: {
      Time: {
        Label      : 'Ώρα',
        Hint       : 'Ώρα αυτόματης αναπλήρωσης αποθέματος',
        Placeholder: 'ΩΩ:ΛΛ',
        Icon       : 'mdi-calendar-clock',
        Error      : {
          Required  : 'Το πεδίο είναι υποχρεωτικό',
          TimeString: 'Πρέπει να είναι έγκυρη ώρα της μορφής ΩΩ:ΛΛ'
        }
      },
      Stock: {
        Label      : 'Απόθεμα',
        Hint       : 'Ρυθμίστε την ποσότητα αποθέματος για αυτόματη αναπλήρωση.',
        Placeholder: '',
        Icon       : 'shopping_basket',
        Error      : {
          Valid: 'Το απόθεμα πρέπει να είναι αριθμός'
        }
      },
      Action: {
        Reset: 'Επαναφορά Αποθέματος',
        Add  : 'Προσθήκη Αποθέματος'
      }
    }
  },

  MenuAddDialog: {
    Toolbar: {
      Title   : 'Προσθήκη Προϊόντος Καταλόγου',
      Subtitle: 'Δημιουργία Νέου Προϊόντος Καταλόγου',
      Icon    : 'shopping_cart',
      Color   : 'indigo'
    },
    Button: {
      CustomPrice: {
        Set   : 'Εισάγετε Τιμή',
        Update: 'Αλλαγή Τιμής',
        Reset : 'Επαναφορά Τιμής'
      }
    },
    Tab: {
      Details     : 'Στοιχεία',
      Settings    : 'Ρυθμίσεις',
      Automation  : 'Αυτοματοποίηση',
      Stock       : 'Απόθεμα',
      Status      : 'Κατάσταση',
      Options     : 'Επιλογές προϊόντος',
      ExtraCharges: 'Επιπλέον Χρεώσεις'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Όνομα προϊόντος',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'Πρέπει να συμπληρώσετε όνομα σε τουλάχιστον μια γλώσσα',
          Between       : 'Το όνομα πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : 'Σύντομη περιγραφή του προϊόντος',
        Icon : 'subtitles',
        Error: {
          Between: 'Η περιγραφή πρέπει να είναι από 2 έως 500 χαρακτήρες'
        }
      },
      Number: {
        Label: 'Νούμερο Αρίθμησης',
        Hint : 'Νούμερο Αρίθμησης Προϊόντος Καταλόγου. πχ. 158',
        Icon : 'filter_2'
      },
      Barcode: {
        Label: 'Barcode',
        Hint : 'Barcode Προϊόντος Καταλόγου.',
        Icon : 'mdi-barcode'
      },
      UnitQuantity: {
        Label: 'Ποσότητα Μονάδας',
        Hint : 'Ποσότητα Μονάδας Προϊόντος Καταλόγου.',
        Icon : 'mdi-counter'
      },
      Unit: {
        Label: 'Μονάδα Μέτρησης',
        Hint : 'Επιλέξτε μονάδα μέτρησης προϊόντος',
        Icon : 'mdi-counter',
        Error: {
          Required: 'Πρέπει να επιλέξετε μονάδα μέτρησης.'
        },
        Units: [
          {
            Id      : 1,
            Name    : 'Τεμάχιο (τμχ)',
            BaseName: 'Τεμάχια (τμχ)',
            BaseUnit: 'τμχ',
            RateUnit: 'τμχ',
            Rate    : 1,
            Divisor : 1.000000
          },
          {
            Id      : 2,
            Name    : 'Κιλό (kg)',
            BaseName: 'Γραμμάρια (g)',
            BaseUnit: 'kg',
            RateUnit: 'g',
            Rate    : 1000,
            Divisor : 0.001000
          },
          {
            Id      : 3,
            Name    : 'Λίτρο (l)',
            BaseName: 'Μιλιλίτρα (ml)',
            BaseUnit: 'l',
            RateUnit: 'ml',
            Rate    : 1000,
            Divisor : 0.001000
          }
        ]
      },
      Price: {
        Label: 'Τιμή',
        Hint : 'Τιμή πώλησης του προϊόντος',
        Error: {
          Valid: 'Η Τιμή πρέπει να είναι αριθμός'
        }
      },
      OptionsPrice: {
        Label: 'Κόστος Επιλογών Προϊόντος',
        Hint : 'Συνολικό κόστος επιλογών του προϊόντος'
      },
      ShowCalculatedPrice: {
        Title : 'Εμφάνιση Τιμής με Κόστος Επιλογών Προϊόντος',
        Toggle: {
          Active  : 'Με Κόστος Επιλογών',
          Inactive: 'Χωρίς Κόστος Επιλογών'
        },
        SpecialStatus: 'Το προϊόν έχει ενεργή προσφορά'
      },
      MenuComment: {
        Label: 'Σχόλια Εκτύπωσης',
        Hint : 'Σχόλια προϊόντος για εκτύπωση',
        Icon : 'comment'
      },
      Tags: {
        Label: 'Ετικέτες',
        Hint : 'Επιλέξτε ετικέτες',
        Icon : 'mdi-tag-multiple'
      },
      Catalogs: {
        Label: 'Κατάλογος',
        Hint : 'Επιλέξτε κατάλογο προϊόντων',
        Icon : 'menu_book',
        Error: {
          Required: 'Πρέπει να επιλέξετε τουλάχιστον ένα κατάλογο προϊόντων.'
        }
      },
      PrintGroup: {
        Label: 'Ομάδα Εκτυπωτών',
        Hint : 'Επιλέξτε ομάδα εκτυπωτών',
        Icon : 'print',
        Error: {
          Required: 'Πρέπει να επιλέξετε τουλάχιστον μία ομάδα εκτυπωτών.'
        }
      },
      Categories: {
        Label: 'Επιλέξτε Κατηγορία',
        Hint : 'Κατηγορία στην οποία ανήκει το προϊόν',
        Icon : 'category',
        Error: {
          Required: 'Πρέπει να επιλέξετε τουλάχιστον μία κατηγορία.',
          Max     : 'Μπορείτε να επιλέξετε έως είκοσι (20) κατηγορίες.'
        }
      },
      DefaultCategory: {
        Label: 'Επιλέξτε Προεπιλεγμένη Κατηγορία',
        Hint : 'Προεπιλεγμένη κατηγορία στην οποία ανήκει το προϊόν',
        Icon : 'category',
        Error: {
          Required: 'Πρέπει να επιλέξετε προεπιλεγμένη κατηγορία.'
        }
      },
      Availability: {
        Label : 'Επιλέξτε Διαθεσιμότητα',
        Hint  : 'Ορίστε αν το προϊόν διατίθεται όλη την ημέρα ή σε συγκεκριμένες ώρες',
        Icon  : 'access_time',
        AllDay: 'Διαθέσιμο όλη την ημέρα'
      },
      Image: {
        Label : 'Φωτογραφία Προϊόντος (800x350)',
        Button: { Title: 'Καταργηση φωτογραφιας' },
        Hint  : {
          Drag   : 'Κάντε drag & drop μια εικόνα ή',
          Click  : 'κάντε κλικ για να επιλέξετε ένα αρχείο',
          Tap    : 'Πατήστε εδώ για να επιλέξετε μια',
          Gallery: 'φωτογραφία από την γκαλερί σας',
          Aspect : 'Τοπιο/Πορτρετο'
        },
        Control: {
          Change: 'Αλλαγη φωτογραφιας',
          Remove: '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
          Select: 'Επιλεξτε φωτογραφια'
        },
        Success: 'Η φωτογραφία επιλέχθηκε με επιτυχία!',
        Error  : {
          Upload: 'Η συσκευή σας δεν υποστηρίζει τη μεταφόρτωση αρχείων.',
          Size  : 'Το μέγεθος του αρχείου υπερβαίνει το όριο',
          Type  : 'Αυτός ο τύπος αρχείου δεν υποστηρίζεται.'
        }
      },
      Stock: {
        Label: 'Απόθεμα',
        Hint : 'Ορίστε 0 για απεριόριστη ποσότητα αποθέματος.',
        Icon : 'shopping_basket',
        Error: {
          Valid: 'Το απόθεμα πρέπει να είναι τουλάχιστον ένας αριθμός'
        }
      },
      MinimumQuantity: {
        Label: 'Ελάχιστη Ποσότητα',
        Hint : 'Η ελάχιστη ποσότητα που μπορεί να παραγγελθεί.',
        Icon : 'opacity',
        Error: {
          Valid: 'Η ελάχιστη ποσότητα πρέπει να είναι τουλάχιστον ένας αριθμός'
        }
      },
      Priority: {
        Label: 'Προτεραιότητα',
        Hint : 'Αριθμός προτεραιότητας πχ. 10, 100, 150',
        Icon : 'sort_by_alpha',
        Error: {
          Valid: 'Η προτεραιότητα πρέπει να είναι τουλάχιστον ένας αριθμός'
        }
      },
      SubtractStock   : 'Αφαίρεση Αποθέματος',
      Status          : '@:Common.Misc.Status Προϊόντος',
      Visibility      : 'Εμφάνιση Προϊόντος Online',
      VisibilityPos   : 'Εμφάνιση Προϊόντος στο POS',
      MenuCustomPrice : 'Χρήση Προσαρμοσμένης Τιμής',
      DiscountExcluded: 'Εξαίρεση Από Εκπτώσεις',
      Featured        : 'Προτεινόμενο Προϊόν',
      Discount        : {
        Title: 'Προσφορά',
        Field: {
          StartDate: {
            Label: 'Ημερομηνία Από',
            Icon : 'event',
            Error: {
              Required: 'Πρέπει να επιλέξετε ημερομηνία από'
            }
          },
          EndDate: {
            Label: 'Ημερομηνία Έως',
            Icon : 'event',
            Error: {
              Required: 'Πρέπει να επιλέξετε ημερομηνία έως'
            }
          },
          DiscountPrice: {
            Label: '@:Restaurant.MenuAddDialog.Field.Price.Label',
            Hint : 'Τιμή προσφοράς',
            Error: {
              Required: 'Πρέπει να εισάγετε τιμή',
              Valid   : 'Η Τιμή πρέπει να είναι αριθμός'
            }
          }
        }
      },
      SelectExtraCharge: {
        Label: 'Επιλέξτε Επιπλέον Χρέωση',
        Hint : 'Επιλέξτε επιπλέον χρέωση για το προϊόν',
        Icon : 'list_alt'
      },
      SelectOption: {
        Label   : 'Επιλέξτε Είδη Προϊόντος',
        Hint    : 'Επιλογές προϊόντος καταλόγου',
        Icon    : 'list_alt',
        ListView: {
          Required   : 'Υποχρεωτική Επιλογή',
          DisplayName: {
            Label: 'Εμφάνιση Ονόματος',
            Hint : 'Στην εκτύπωση'
          },
          ParentId: {
            Label: 'Επιλογή Parent',
            Hint : ''
          },
          MinChoices: {
            Label: 'Min Επιλογές',
            Hint : '0 για απεριόριστες επιλογές'
          },
          MaxChoices: {
            Label: 'Max Επιλογές',
            Hint : '0 για απεριόριστες επιλογές'
          },
          AddOptions: 'Προσθήκη Επιλογών',
          Header    : {
            Name     : '@:Common.Misc.Name',
            Price    : '@:Restaurant.MenuAddDialog.Field.Price.Label',
            Quantity : 'Ποσότητα',
            ShowPrice: 'Εμφ. Τιμής',
            Default  : 'Προεπιλογή',
            Required : 'Υποχρεωτικό',
            Group    : 'Ομάδα'
          },
          SelectGroup: {
            Label: 'Ομάδα Συνταγής',
            Hint : 'Επιλέξτε Ομάδα Συνταγής'
          },
          SelectLocalGroup: {
            Label: 'Ομάδα Επιλογών',
            Hint : 'Επιλέξτε Ομάδα Επιλογών'
          }
        }
      }
    },
    NoData: {
      Title   : 'Δεν Υπάρχουν Διαθέσιμες Επιλογές',
      Subtitle: 'Προσθέστε Επιλογές',
      Icon    : 'list_alt'
    },
    Notification: {
      Update   : 'Το προϊόν δεν ενημερώθηκε. @:Common.Misc.Retry',
      Duplicate: 'Το προϊόν αντιγράφηκε επιτυχώς με νέο ID #{id}.'
    },
    StockAutomation: {
      Reset: '<strong>{time}</strong> - Επαναφορά σε <strong>{stock}</strong>',
      Add  : '<strong>{time}</strong> - Προσθήκη <strong>{stock}</strong>'
    },
    StatusAutomation: {
      Online: '<strong>Online</strong>',
      Pos   : '<strong>Pos</strong>'
    }
  },

  Categories: {
    Title  : 'Κατηγορίες | Κατηγορία | Κατηγορίες',
    Toolbar: {
      Button: {
        New: {
          Title: 'Νέα Κατηγορία',
          Icon : 'add_box',
          Color: 'primary'
        },
        Products: {
          Title: 'Προϊόντα',
          Icon : '',
          Color: 'primary'
        }
      }
    },
    Table: {
      Headers: {
        Image      : '',
        Id         : '#',
        Name       : 'Όνομα',
        Description: 'Περιγραφή',
        MenusCount : 'Προϊόντα',
        Catalog    : 'Κατάλογος',
        Tax        : 'ΦΠΑ',
        Languages  : 'Γλώσσες',
        Priority   : 'Προτεραιότητα',
        Status     : 'Κατάσταση',
        Visible    : 'Εμφάνιση Online',
        Featured   : 'Προτεινόμενη',
        Actions    : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Διαγραφή Κατηγορίας & Προϊόντων;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε την κατηγορία και όλα τα προϊόντα που ανήκουν σε αυτή;',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      },
      Duplicate: {
        Title  : 'Αντιγραφή Κατηγορίας & Προϊόντων;',
        Content: 'Θέλετε να αντιγράψετε την κατηγορία με όλα τα προϊόντα που ανήκουν σε αυτή;'
      }
    },
    Notification: {
      Delete: 'Η κατηγορία δεν διαγράφηκε. @:Common.Misc.Retry'
    },
    Filter: {
      filterCatalog: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Κατάλογος Προϊόντων',
        PlaceHolder: 'Όλοι οι κατάλογοι προϊόντων',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Κατάσταση',
        PlaceHolder: 'Όλες οι κατηγορίες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλες'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterVisible: {
        Type       : 'v-btn-toggle',
        Label      : 'Εμφάνιση Online',
        PlaceHolder: 'Όλες οι κατηγορίες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλες'
          },
          {
            Value: '1',
            Name : 'Ενεργή',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργή',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterFeatured: {
        Type       : 'v-btn-toggle',
        Label      : 'Προτεινόμενη',
        PlaceHolder: 'Όλες οι κατηγορίες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'Όλες'
          },
          {
            Value: '1',
            Name : 'Ενεργό',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Ανενεργό',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      }
    }
  },

  CategoryAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Κατηγορίας',
        Subtitle: 'Δημιουργία Νέας Κατηγορίας'
      },
      Edit: {
        Title   : 'Επεξεργασία Κατηγορίας',
        Subtitle: 'Τροποποίηση Στοιχείων Κατηγορίας'
      },
      Icon : 'category',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Όνομα κατηγορίας. πχ. Σαλάτες, Ζυμαρικά, Ποτά, κλπ.',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'Πρέπει να συμπληρώσετε όνομα σε τουλάχιστον μια γλώσσα',
          Between       : 'Το όνομα πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Description: {
        Label: '@:Restaurant.MenuAddDialog.Field.Description.Label',
        Hint : 'Σύντομη περιγραφή της κατηγορίας',
        Icon : 'subtitles',
        Error: {
          Between: 'Η περιγραφή πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Priority: {
        Label: '@:Restaurant.MenuAddDialog.Field.Priority.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.Priority.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.Priority.Icon',
        None : 'Καμία',
        Error: {
          Valid: '@:Restaurant.MenuAddDialog.Field.Priority.Error.Valid'
        }
      },
      Catalogs: {
        Label: '@:Restaurant.MenuAddDialog.Field.Catalogs.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.Catalogs.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.Catalogs.Icon',
        Error: {
          Required: '@:Restaurant.MenuAddDialog.Field.Catalogs.Error.Required'
        }
      },
      PrintGroup: {
        Label: '@:Restaurant.MenuAddDialog.Field.PrintGroup.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.PrintGroup.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.PrintGroup.Icon',
        None : 'Καμία',
        Error: {
          Required: '@:Restaurant.MenuAddDialog.Field.PrintGroup.Error.Required'
        }
      },
      PrintGroupUpdate: {
        Label: 'Αλλαγή ομάδων εκτυπωτών όλων των προϊόντων της κατηγορίας με τις επιλεγμένες'
      },
      MenusCatalogsUpdate: {
        Label: 'Αλλαγή των καταλόγων όλων των προϊόντων της κατηγορίας με τους επιλεγμένους'
      },
      Tax: {
        Label: 'Κατηγορία ΦΠΑ',
        Type : 'TAKEAWAY',
        Hint : 'Επιλέξτε κατηγορία ΦΠΑ',
        Icon : 'percent',
        Error: {
          Required: 'Πρέπει να επιλέξετε κατηγορία ΦΠΑ.'
        }
      },
      Tax2: {
        Label: 'Κατηγορία ΦΠΑ Καθήμενων',
        Type : 'ΤΡΑΠΕΖΙΑ',
        Hint : 'Επιλέξτε κατηγορία ΦΠΑ καθήμενων',
        Icon : 'percent',
        Error: {
          Required: 'Πρέπει να επιλέξετε κατηγορία ΦΠΑ καθήμενων.'
        }
      },
      TaxUpdate                 : { Label: 'Αλλαγή κατηγορίας ΦΠΑ όλων των προϊόντων της κατηγορίας με την επιλεγμένη' },
      ClassificationCategoryCode: {
        Title: 'Χαρακτηρισμός Εσόδων',
        Label: 'Κωδικός Κατηγορίας Χαρακτηρισμού Εσόδων',
        Hint : 'Επιλέξτε κωδικό κατηγορίας χαρακτηρισμού εσόδων',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'Πρέπει να επιλέξετε κωδικό κατηγορίας χαρακτηρισμού εσόδων.'
        }
      },
      ClassificationTypeCode: {
        Title: 'Χαρακτηρισμός Εσόδων',
        Label: 'Κωδικός Τύπου Χαρακτηρισμού Εσόδων ',
        Hint : 'Επιλέξτε κωδικό τύπου χαρακτηρισμού εσόδων',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'Πρέπει να επιλέξετε κωδικό τύπου χαρακτηρισμού εσόδων.'
        }
      },
      ClassificationUpdate: { Label: 'Αλλαγή κατηγορίας και τύπου χαρακτηρισμού εσόδων' },
      Visibility          : { Label: 'Εμφάνιση Online' },
      Featured            : { Label: 'Προτεινόμενη Κατηγορία' },
      Color               : { Label: 'Χρώμα Κατηγορίας' },
      Image               : {
        Label : 'Φωτογραφία Κατηγορίας (1460x400)',
        Button: { Title: 'Κατάργηση φωτογραφίας' },
        Hint  : {
          Drag   : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Drag',
          Click  : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Click',
          Tap    : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Tap',
          Gallery: '@:Restaurant.MenuAddDialog.Field.Image.Hint.Gallery',
          Aspect : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Aspect'
        },
        Control: {
          Change: '@:Restaurant.MenuAddDialog.Field.Image.Control.Change',
          Remove: '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
          Select: '@:Restaurant.MenuAddDialog.Field.Image.Control.Select'
        },
        Success: '@:Restaurant.MenuAddDialog.Field.Image.Success',
        Error  : {
          Upload: '@:Restaurant.MenuAddDialog.Field.Image.Error.Upload',
          Size  : '@:Restaurant.MenuAddDialog.Field.Image.Error.Size',
          Type  : '@:Restaurant.MenuAddDialog.Field.Image.Error.Type'
        }
      }
    },
    Notification: {
      Update   : 'Η κατηγορία δεν ενημερώθηκε. @:Common.Misc.Retry',
      Duplicate: 'Η κατηγορία αντιγράφηκε επιτυχώς.'
    }
  },

  Tags: {
    Title  : 'Ετικέτες | Ετικέτα | Ετικέτες',
    Toolbar: {
      Button: {
        Title: 'Νέα Ετικέτα',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Id         : '#',
        Label      : 'Ετικέτα',
        Description: 'Περιγραφή',
        Language   : 'Γλώσσες',
        Color      : 'Χρώμα',
        Icon       : 'Εικονίδιο',
        Active     : 'Κατάσταση',
        Visible    : 'Εμφάνιση',
        LabelOnly  : 'Μόνο Κείμενο',
        System     : 'Σύστημα',
        Group      : 'Ομάδα',
        Actions    : ''
      }
    },
    Dialog: {
      Toolbar: {
        Add: {
          Title   : 'Προσθήκη Ετικέτας',
          Subtitle: 'Δημιουργία Νέας Ετικέτας'
        },
        Edit: {
          Title   : 'Επεξεργασία Ετικέτας',
          Subtitle: 'Τροποποίηση Στοιχείων Ετικέτας'
        },
        Color: 'indigo',
        Icon : ' mdi-tag'
      },
      Field: {
        Icon: {
          Error: {
            Required: 'Το εικονίδιο είναι υποχρεωτικό'
          }
        },
        Label: {
          Label: 'Ετικέτα',
          Hint : 'Ετικέτα. πχ. Healthy, Vegan, Gluten Free, κλπ.',
          Icon : 'title',
          Error: {
            Between: 'Η ετικέτα πρέπει να είναι από 2 έως 32 χαρακτήρες'
          }
        },
        Description: {
          Label: 'Περιγραφή',
          Hint : 'Περιγραφή για την ετικέτα',
          Icon : 'mdi-text',
          Error: {
            Between: 'Η περιγραφή πρέπει να είναι από 2 έως 128 χαρακτήρες'
          }
        },
        Group: {
          Label: 'Ομάδα',
          Hint : 'Ομάδα. πχ. admin, over-18, smoke, alcohol, κλπ.',
          Icon : 'title',
          Error: {
            Between: 'Η ομάδα πρέπει να είναι από 2 έως 10 χαρακτήρες'
          }
        },
        Color: {
          Label: 'Χρώμα Ετικέτας'
        },
        Active: {
          Label: 'Κατάσταση'
        },
        Visible: {
          Label: 'Εμφάνιση'
        },
        LabelOnly: {
          Label: 'Μόνο Κείμενο'
        },
        System: {
          Label: 'Σύστημα'
        }
      },
      Delete: {
        Title       : 'Διαγραφή Ετικέτας;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε την ετικέτα;',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Delete: 'Η ετικέτα δεν διαγράφηκε. @:Common.Misc.Retry'
    }
  },

  Catalogs: {
    Title  : 'Κατάλογοι | Κατάλογος | Κατάλογοι',
    Toolbar: {
      Button: {
        Title: 'Νέος Κατάλογος',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Image          : '',
        Id             : '#',
        Name           : 'Όνομα',
        ProductsCount  : 'Προϊόντα',
        TableAreasCount: 'Περιοχές Τραπεζιών',
        OrderSource    : 'Πηγή Παραγγελίας',
        OrderType      : 'Τύπος Παραγγελίας',
        IsDefault      : 'Κύριος Κατάλογος',
        Status         : 'Κατάσταση',
        Actions        : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Διαγραφή Καταλόγου & Προϊόντων;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον κατάλογο και όλα τα προϊόντα που ανήκουν σε αυτόν;',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Delete: 'Ο κατάλογος δεν διαγράφηκε. @:Common.Misc.Retry'
    }
  },

  CatalogAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Καταλόγου',
        Subtitle: 'Δημιουργία Νέου Καταλόγου'
      },
      Edit: {
        Title   : 'Επεξεργασία Καταλόγου',
        Subtitle: 'Τροποποίηση Στοιχείων Καταλόγου'
      },
      Duplicate: {
        Title   : 'Αντιγραφή Καταλόγου',
        Subtitle: 'Αντιγραφή Καταλόγου με Προϊόντα και Κατηγορίες'
      },
      Icon : 'menu_book',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: 'Όνομα',
        Hint : 'Όνομα καταλόγου. πχ. Website, Mobile, κλπ.',
        Icon : 'title',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : 'Σύντομη περιγραφή του καταλόγου',
        Icon : 'subtitles',
        Error: {
          Between: 'Η περιγραφή πρέπει να είναι από 2 έως 255 χαρακτήρες'
        }
      },
      SourceTypes: {
        Label: 'Πηγή Παραγγελίας',
        Hint : '',
        Icon : 'swipe_right_alt',
        Error: {
          Required: 'Πρέπει να επιλέξετε τουλάχιστον 1 πηγή παραγγελίας'
        }
      },
      OrderStatus: {
        Label: 'Κατάσταση Παραγγελίας',
        Hint : ''
      },
      OrderType: {
        Label: 'Τύπος Παραγγελίας',
        Hint : '',
        Icon : 'shopping_cart',
        Items: [
          {
            Id   : 0,
            Name : 'Όλοι οι τύποι παραγγελίας',
            Icon : 'shopping_cart',
            Color: 'green'
          },
          {
            Id   : 1,
            Name : 'Παράδοση',
            Icon : 'directions_bike',
            Color: 'purple'
          },
          {
            Id   : 2,
            Name : 'Παραλαβή',
            Icon : 'directions_run',
            Color: 'blue darken-3'
          }
        ],
        Error: {
          Required: 'Πρέπει να επιλέξετε τύπο παραγγελίας'
        }
      },
      Default: {
        Label: 'Κύριος Κατάλογος'
      },
      CanCreateOrder: {
        Label: 'Δημιουργία / Επεξεργασία Παραγγελιών'
      },
      Color: { Label: 'Χρώμα Καταλόγου' }
    },
    Notification: {
      Update        : 'Ο κατάλογος δεν ενημερώθηκε. @:Common.Misc.Retry',
      Duplicate     : 'Ο κατάλογος αντιγράφηκε επιτυχώς με ID #{id}',
      DuplicateError: 'Ο κατάλογος δεν αντιγράφηκε. @:Common.Misc.Retry'
    }
  },

  // Product Availability
  MealTimes: {
    Title  : 'Διαθεσιμότητας Προϊόντων',
    Toolbar: {
      Button: {
        Title: 'Νέα Κατηγορία @:Restaurant.MealTimes.Title',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Id       : '#',
        Name     : 'Όνομα',
        Languages: 'Γλώσσες',
        StartTime: 'Ώρα Έναρξης',
        EndTime  : 'Ώρα Λήξης',
        Status   : 'Κατάσταση',
        Actions  : ''
      }
    },
    Notification: {
      Update: '@:Restaurant.CategoryAddDialog.Notification.Update'
    }
  },

  MealTimeAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Κατηγορίας @:Restaurant.MealTimes.Title',
        Subtitle: 'Δημιουργία Νέας Κατηγορίας @:Restaurant.MealTimes.Title'
      },
      Edit: {
        Title   : 'Επεξεργασία Κατηγορίας @:Restaurant.MealTimes.Title',
        Subtitle: 'Τροποποίηση Στοιχείων Κατηγορίας @:Restaurant.MealTimes.Title'
      },
      Icon : 'access_time',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Όνομα κατηγορίας. πχ. Πρωινό, Μεσημεριανό, Happy Hour, κλπ.',
        Icon : 'title',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      StartTime: {
        Label: 'Ώρα Έναρξης',
        Icon : 'access_time',
        Error: {
          Required: 'Το πεδίο ώρα έναρξης απαιτείται.'
        }
      },
      EndTime: {
        Label: 'Ώρα Λήξης',
        Icon : 'access_time',
        Error: {
          Required: 'Το πεδίο ώρα λήξης απαιτείται.'
        }
      }
    }
  },

  // Options Group
  MenuOptionValueGroups: {
    Toolbar: {
      Button: {
        Title: 'Νέα Ομάδα Επιλογών',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Id              : '#',
        Name            : 'Όνομα',
        Languages       : 'Γλώσσες',
        Description     : 'Περιγραφή',
        Status          : 'Κατάσταση',
        ShowOptionValues: 'Εμφάνιση Επιλογών',
        Actions         : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Διαγραφή Ομάδας Επιλογών;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε την ομάδα επιλογών;',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Update: 'Η ομάδα επιλογών δεν ενημερώθηκε. @:Common.Misc.Retry',
      Delete: 'Η ομάδα επιλογών δεν διαγράφηκε. @:Common.Misc.Retry'
    }
  },

  MenuOptionValueGroupAddDialog: {
    Title  : 'Ομάδας Επιλογών',
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη @:Restaurant.MenuOptionValueGroupAddDialog.Title',
        Subtitle: 'Δημιουργία Νέας @:Restaurant.MenuOptionValueGroupAddDialog.Title'
      },
      Edit: {
        Title   : 'Επεξεργασία @:Restaurant.MenuOptionValueGroupAddDialog.Title',
        Subtitle: 'Τροποποίηση Στοιχείων @:Restaurant.MenuOptionValueGroupAddDialog.Title'
      },
      Icon : 'tune',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Όνομα @:Restaurant.MenuOptionValueGroupAddDialog.Title πχ. Απ\' Όλα, κλπ.',
        Icon : 'title',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
        }
      },
      ShowOptionValues: {
        Label: 'Εμφάνιση Επιλογών'
      },
      Description: {
        Label: '@:Restaurant.MenuAddDialog.Field.Description.Label',
        Hint : 'Περιγραφή Ομάδας Επιλογών',
        Icon : 'subtitles',
        Error: {
          Between: 'Η περιγραφή πρέπει να είναι από 2 έως 200 χαρακτήρες'
        }
      }
    }
  },

  MenuOptionValueModifiersDialog: {
    Toolbar: {
      Title: 'Επεξεργασία Επιλογής Προϊόντος',
      Icon : 'dynamic_form',
      Color: 'blue'
    },
    Field: {
      Show: 'Εμφάνιση'
    },
    ListView: {
      Header: {
        Name       : '@:Common.Misc.Name',
        Price      : '@:Restaurant.MenuAddDialog.Field.Price.Label',
        ShowPrice  : '@:Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.ShowPrice',
        Default    : 'Προεπιλεγμένο',
        Deactivated: 'Απενεργοποιημένο'
      }
    }
  },

  /* Product Options */
  Options: {
    Toolbar: {
      Button: {
        Title: 'Νέα επιλογή προϊόντων',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    ListView: {
      ChoiceType: {
        Single: {
          Title: 'Μία Επιλογή',
          Icon : 'radio_button_checked'
        },
        Unit: {
          Title: 'Ποσότητα Μονάδας',
          Icon : 'mdi-counter'
        },
        UnitValue: {
          Title: 'Τιμή Μονάδας',
          Icon : 'mdi-currency-eur'
        },
        ExtraCharges: {
          Title: 'Επιπλέον Χρεώσεις',
          Icon : 'mdi-currency-eur'
        },
        Multiple: {
          Title: 'Πολλαπλές Επιλογές',
          Icon : 'check_box'
        },
        Label: {
          Title: 'Τίτλος (Label)',
          Icon : 'text_fields'
        },
        Separator: {
          Title: 'Διαχωριστική Γραμμή',
          Icon : 'horizontal_rule'
        }
      }
    },
    Table: {
      Headers: {
        Id         : '#',
        Name       : 'Όνομα',
        DisplayType: 'Τύπος προβολής',
        Languages  : 'Γλώσσες',
        Priority   : 'Προτεραιότητα',
        Actions    : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Διαγραφή Επιλογής Προϊόντος;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε την επιλογή προϊόντος;',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Update: 'Η επιλογή προϊόντος δεν ενημερώθηκε. @:Common.Misc.Retry'
    }
  },

  OptionAddDialog: {
    Title  : 'Επιλογής Προϊόντων',
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη @:Restaurant.OptionAddDialog.Title',
        Subtitle: 'Δημιουργία Νέας @:Restaurant.OptionAddDialog.Title'
      },
      Edit: {
        Title   : 'Επεξεργασία @:Restaurant.OptionAddDialog.Title',
        Subtitle: 'Τροποποίηση Στοιχείων@:Restaurant.OptionAddDialog.Title'
      },
      Icon : 'list_alt',
      Color: 'indigo'
    },
    Tab: {
      Details: 'Γενικά στοιχεία',
      Values : 'Τιμές επιλογών'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Όνομα επιλογής προϊόντος. πχ. Επιλέξτε Μέγεθος, κλπ.',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'Πρέπει να συμπληρώσετε όνομα σε τουλάχιστον μια γλώσσα',
          Between       : 'Το όνομα πρέπει να είναι από 2 έως 64 χαρακτήρες'
        }
      },
      PrintableName: {
        Label: 'Όνομα για εκτύπωση',
        Hint : 'Όνομα επιλογής προϊόντος για την εκτύπωση',
        Icon : 'receipt_long',
        Error: {
          Between: 'Το όνομα για εκτύπωση πρέπει να είναι από 2 έως 64 χαρακτήρες'
        }
      },
      DisplayType: {
        Label: 'Τύπος Προβολής',
        Hint : 'Επιλέξτε τύπο προβολής πχ. Radio, Checkbox',
        Icon : {
          All     : 'select_all',
          Radio   : 'radio_button_checked',
          Checkbox: 'check_box'
        },
        Error: {
          Required: 'Το τύπος προβολής είναι υποχρεωτικό'
        }
      },
      Priority: {
        Label: '@:Restaurant.MenuAddDialog.Field.Priority.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.Priority.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.Priority.Icon',
        Error: {
          Valid: '@:Restaurant.MenuAddDialog.Field.Priority.Error.Valid'
        }
      }
    },
    ListView: {
      Header: {
        Name : '@:Common.Misc.Name',
        Price: '@:Restaurant.MenuAddDialog.Field.Price.Label'
      }
    },
    Notification: {
      Update: 'Η επιλογή δεν ενημερώθηκε. @:Common.Misc.Retry',
      Remove: 'Εάν αφαιρέσετε μία επιλογή θα αφαιρεθεί και από όλα τα προϊόντα που χρησιμοποιείται!'
    },
    NoData: {
      Title   : '@:Restaurant.MenuAddDialog.NoData.Title',
      Subtitle: '@:Restaurant.MenuAddDialog.NoData.Subtitle',
      Icon    : '@:Restaurant.MenuAddDialog.NoData.Icon'
    },
    Unit: {
      HeaderTitle: 'Ποσότητα',
      ValueHint  : 'Ποσότητα σε (g) ή (ml)'
    }
  },

  Tables: {
    Title   : 'Τραπέζια | Τραπέζι | Τραπέζια',
    Time    : 'Χρόνος',
    Products: 'Προϊόντα',
    Seat    : {
      Title : 'Θέσεις | Θέση | Θέσεις',
      Select: 'Θέση'
    },
    ItemOrder: {
      Title : 'Σειρά',
      Select: 'Σειρά'
    },
    Toolbar: {
      Button: {
        Title: 'Νέο Τραπέζι',
        Icon : 'add_box',
        Color: 'primary'
      },
      Filter: {
        TableStatus: {
          Label: 'Κατάσταση Τραπεζιού',
          Icon : 'crop_square'
        },
        TableArea: {
          Label: 'Περιοχή Τραπεζιού',
          Icon : 'crop_square'
        },
        WaiterStaff: {
          Label: 'Σερβιτόρος',
          Icon : 'room_service'
        }
      },
      Tab: {
        All : 'Όλα',
        My  : 'Δικά Μου',
        Open: 'Ανοιχτά'
      }
    },
    Dialog: {
      OptionMenu: {
        NewOrder: {
          Label: 'Νέα Παραγγελία',
          Icon : 'add'
        },
        PayOrder: {
          Label: 'Πληρωμή',
          Icon : 'euro_symbol'
        },
        History: {
          Label: 'Προβολή',
          Icon : 'visibility'
        },
        Move: {
          Label: 'Μεταφορά',
          Icon : 'sync_alt'
        },
        Waiter: {
          Label: 'Αλλαγή Σερβιτόρου',
          Icon : 'transfer_within_a_station'
        },
        Merge: {
          Label: 'Συγχώνευση',
          Icon : 'merge_type'
        },
        Split: {
          Label: 'Διαχωρισμός',
          Icon : 'call_split'
        },
        Edit: {
          Label: 'Επεξεργασία',
          Icon : 'edit_note'
        },
        Close: {
          Label: 'Κλείσιμο Τραπεζιού',
          Icon : 'close'
        },
        Open: {
          Label: 'Άνοιγμα',
          Icon : 'group_add'
        },
        Reservation: {
          Label: 'Κράτηση',
          Icon : 'mdi mdi-book-open-variant'
        }
      },
      PaymentMenu: {
        PayTable: {
          Label: 'Πληρωμή Τραπεζιού',
          Icon : 'tab_unselected'
        },
        PayOrder: {
          Label: 'Πληρωμή ανά Παραγγελία',
          Icon : 'shopping_basket'
        },
        PayItems: {
          Label: 'Πληρωμή ανά Προϊόν',
          Icon : 'call_split'
        },
        AwaitingPayment: {
          Set: {
            Label: 'Αναμονή Λογαριασμού',
            Icon : 'hourglass_empty'
          },
          Unset: {
            Label: 'Ακύρωση Αναμ. Λογαριασμού',
            Icon : 'hourglass_empty'
          }
        }
      },
      Payment: {
        Title: 'Πληρωμές | Πληρωμή | Πληρωμές',
        Table: {
          Title   : 'Πληρωμή Τραπεζιού',
          Subtitle: 'Πληρωμή Τραπεζιού',
          Icon    : 'tab_unselected'
        },
        Order: {
          Title   : 'Πληρωμή Τραπεζιού',
          Subtitle: 'Πληρωμή ανά Παραγγελία',
          Icon    : 'shopping_basket'
        },
        Items: {
          Title   : 'Πληρωμή Τραπεζιού',
          Subtitle: 'Πληρωμή ανά Προϊόν',
          Icon    : 'call_split'
        },
        PaymentSuccess: 'Η πληρωμή καταχωρήθηκε επιτυχώς!',
        Transactions  : {
          Title : 'Συναλλαγές',
          Button: {
            Terminal: {
              Add   : 'Αποστολή στο EFT/POS Τερματικό',
              Retry : 'Επαναποστολή στο EFT/POS Τερματικό',
              Refund: 'Επιστροφή χρημάτων συναλλαγής'
            },
            Remove: 'Διαγραφή συναλλαγής'
          },
          Info: {
            Refund       : 'Κάντε επιστροφή χρημάτων συναλλαγής.',
            RefundSuccess: '@:Pos.OrderPaymentDialog.Notification.Refund.Success',
            RefundError  : '@:Pos.OrderPaymentDialog.Notification.Refund.Error',

            Sale     : 'Κάντε αποστολή στο EFT/POS Τερματικό.',
            SaleRetry: 'Κάντε επαναποστολή στο EFT/POS Τερματικό.',

            SaleWaitingResult: 'Σε αναμονή απάντησης από EFT/POS Τερματικό.',
            SaleSuccess      : 'Η συναλλαγή ολοκληρώθηκε επιτυχώς.',
            SaleError        : 'Η συναλλαγή απέτυχε. Δοκιμάστε ξανά.'
          },
          Notification: {
            RefundSuccess: 'Η επιστροφή χρημάτων της συναλλαγής έγινε με επιτυχία'
          },
          Dialog: {
            ConfirmRefund: {
              Title       : 'Επιστροφή Χρημάτων Συναλλαγής;',
              Content     : 'Είστε σίγουροι ότι θέλετε να κάνετε επιστροφή των χρημάτων της συναλλαγής;',
              Notification: 'Η επιστροφή των χρημάτων της συναλλαγής είναι μη αναστρέψιμη!'
            }
          }
        },
        Total  : 'Σύνολο',
        Change : 'Ρέστα',
        Balance: 'Υπόλοιπο'
      },
      MergeTable: {
        Title          : 'Συγχώνευση Τραπεζιών',
        Subtitle       : 'Επιλέξτε τραπέζι(α) προς συγχώνευση',
        Icon           : 'merge_type',
        MainTable      : 'Κύριο Τραπέζι',
        SelectedTables : 'Επιλεγμένα Τραπέζια για Συγχώνευση',
        AvailableTables: 'Διαθέσιμα Τραπέζια προς Συγχώνευση'
      },
      SplitTable: {
        Title          : 'Διαχωρισμός Τραπεζιών',
        Subtitle       : 'Αφαιρέστε επιλεγμένα τραπέζι(α) για διαχωρισμό',
        Icon           : 'call_split',
        MainTable      : '@:Restaurant.Tables.Dialog.MergeTable.MainTable',
        SelectedTables : 'Συγχωνευμένα Τραπέζια',
        AvailableTables: 'Επιλεγμένα Τραπέζια προς Διαχωρισμό'
      },
      ViewTable: {
        Title   : 'Προβολή Τραπεζιού',
        Subtitle: 'Προβολή λεπτομερειών τραπεζιού',
        Icon    : 'tab_unselected',
        Tab     : {
          Orders: {
            Title: 'Παραγγελίες'
          },
          Unpaid: {
            Title: 'Ανεξόφλητα',
            Step : {
              Orders : 'Παραγγελίες',
              Payment: 'Πληρωμή'
            }
          },
          Paid: {
            Title: 'Εξοφλημένα'
          },
          Canceled: {
            Title: 'Ακυρωμένα'
          },
          Receipts: {
            Title: 'Παραστατικά'
          }
        }
      },
      MoveTable: {
        Title      : 'Μεταφορά Τραπεζιού',
        Subtitle   : 'Μεταφορά Παραγγελιών Τραπεζιού',
        Icon       : 'sync_alt',
        SourceTable: 'Απο Τραπέζι',
        TargetTable: 'Σε Τραπέζι',
        SelectTable: 'Επιλέξτε Τραπέζι'
      },
      SelectTable: {
        Title   : 'Τραπέζια',
        Subtitle: 'Επιλέξτε Τραπέζι'
      },
      SelectTerminal: {
        Title       : 'EFT/POS Τερματικά Πληρωμών',
        Subtitle    : 'Επιλέξτε EFT/POS Τερματικό Πληρωμής',
        Notification: {
          Success: 'Το ποσό της συναλλαγής στάλθηκε στο επιλεγμένο τερματικό πληρωμών EFT/POS.',
          Error  : 'Μια άλλη συναλλαγή χρησιμοποιεί το επιλεγμένο τερματικό πληρωμών EFT/POS. Δοκιμάστε ξανά αργότερα.'
        }
      },
      ChangeWaiter: {
        Title       : 'Αλλαγή Σερβιτόρου',
        Subtitle    : 'Μεταφορά Τραπεζιού σε Σερβιτόρο',
        Icon        : 'transfer_within_a_station',
        SourceTable : 'Τραπέζι',
        SourceWaiter: 'Από Σερβιτόρο',
        TargetWaiter: 'Σε Σερβιτόρο',
        WithPayments: 'Μεταφορά πληρωμών στην βάρδια του νέου σερβιτόρου',
        SelectWaiter: 'Επιλέξτε Σερβιτόρο'
      },
      ConfirmCloseSwift: {
        Title       : 'Αποδέσμευση Τραπεζιού',
        Content     : 'Θέλετε να αποδεσμεύσετε το τραπέζι;',
        Notification: 'Μετά την ενέργεια αυτό το τραπέζι θα είναι διαθέσιμο.'
      },
      ConfirmChangeWaiter: {
        Title       : 'Αλλαγή Σερβιτόρου',
        Content     : 'Θέλετε να αλλάξετε σερβιτόρο στο τραπέζι;',
        Notification: 'Μετά την ενέργεια αυτό το τραπέζι θα ανατεθεί στο νέο σερβιτόρο.'
      },
      ConfirmCloseSwiftAndDiscardOrder: {
        Title       : 'Αποδέσμευση Τραπεζιού',
        Content     : 'Το τραπέζι <strong>δεν έχει εξοφληθεί</strong> και έχει απλήρωτες παραγγελίες και προϊόντα. <br><br>Είστε σίγουροι ότι Θέλετε να αποδεσμεύσετε το τραπέζι;',
        Notification: 'Μετά την ενέργεια αυτό το τραπέζι θα είναι διαθέσιμο και οποιαδήποτε παραγγελία και προϊόντα είναι απλήρωτα δεν θα προσμετρούνται.',
        Button      : {
          Yes   : 'Αποδέσμευση',
          No    : 'Πληρωμή',
          Cancel: 'Ακύρωση'
        }
      },
      ConfirmCloseSwiftAndDiscardOrderPartialPaid: {
        Title       : 'Αποδέσμευση Τραπεζιού',
        Content     : 'Το τραπέζι <strong>είναι μερικώς πληρωμένο</strong> και έχει απλήρωτες παραγγελίες και προϊόντα. <br><br>Είστε σίγουροι ότι Θέλετε να αποδεσμεύσετε το τραπέζι;',
        Notification: 'Μετά την ενέργεια αυτό το τραπέζι θα είναι διαθέσιμο και οποιαδήποτε παραγγελία και προϊόντα είναι απλήρωτα δεν θα προσμετρούνται.',
        Button      : {
          Yes   : 'Αποδέσμευση',
          No    : 'Πληρωμή',
          Cancel: 'Ακύρωση'
        }
      },
      ConfirmCancelOrderItems: {
        Title       : 'Ακύρωση Προϊόντος | Ακύρωση Προϊόντων',
        Content     : 'Θέλετε να ακυρώσετε το επιλεγμένο προϊόν; | Θέλετε να ακυρώσετε τα {count} επιλεγμένα προϊόντα;',
        Notification: 'Μετά την ενέργεια αυτή, το επιλεγμένο προϊόν θα ακυρωθεί οριστικά. | Μετά την ενέργεια αυτή, τα {count} επιλεγμένα προϊόντα θα ακυρωθούν οριστικά.',
        Success     : 'Το επιλεγμένο προϊόν ακυρώθηκε οριστικά. | Τα {count} επιλεγμένα προϊόντα ακυρώθηκαν οριστικά.'
      },
      Delete: {
        Title       : 'Διαγραφή Τραπεζιού;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε το τραπέζι;',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      },
      Open: {
        Title: 'Άνοιγμα Τραπεζιού',
        Form : {
          Field: {
            Persons: {
              Label          : 'Άτομα',
              Hint           : 'Δώστε τον αριθμό των ατόμων που κάθονται στο τραπέζι. πχ. 5',
              ValidationRules: {
                MinNum  : 'Ο αριθμός των ατόμων που κάθονται πρέπει να είναι τουλάχιστον 1',
                MaxNum  : 'O αριθμός των ατόμων ξεπερνά τον μέγιστο αριθμό ατόμων ({persons}) που επιτρέπονται στο τραπέζι.',
                NoMaxNum: 'O αριθμός των ατόμων ξεπερνά τον μέγιστο αριθμό ατόμων επιτρέπονται στο τραπέζι.'
              }
            }
          }
        }
      }
    },
    TableStatus: {
      Available: {
        Active: true,
        Id    : 22,
        Name  : 'Διαθέσιμο',
        Color : 'grey'
      },
      Occupied: {
        Active: true,
        Id    : 23,
        Name  : 'Κατειλημμένο',
        Color : 'purple'
      },
      Unavailable: {
        Active: false,
        Id    : 24,
        Name  : 'Μη Διαθέσιμο',
        Color : 'red'
      },
      Reserved: {
        Active: false,
        Id    : 25,
        Name  : 'Ρεζερβέ',
        Color : 'blue'
      },
      Paid: {
        Active: true,
        Id    : 30,
        Name  : 'Πληρωμένο',
        Color : 'green'
      },
      PartiallyPaid: {
        Active: true,
        Id    : 31,
        Name  : 'Μερικώς Πληρωμένο',
        Color : 'orange'
      },
      AwaitingPayment: {
        Active: true,
        Id    : 32,
        Name  : 'Αναμονή Λογαριασμού',
        Color : 'brown'
      },
      Merged: {
        Active: true,
        Id    : 1000,
        Name  : 'Συγχωνευμένο',
        Color : 'indigo lighten-2'
      }
    },
    Notification: {
      Delete            : 'Το τραπέζι δεν διαγράφηκε. @:Common.Misc.Retry',
      HasTableCouponMove: 'Περιλαμβάνεται έκπτωση τραπεζιού. Επιτρέπεται μόνο η μεταφορά όλων.',
      HasTableCouponPay : 'Περιλαμβάνεται έκπτωση τραπεζιού. Επιτρέπεται μόνο η πληρωμή όλων.'
    },
    Filter: {
      filterTableArea: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Περιοχή Τραπεζιού',
        PlaceHolder: 'Όλες οι περιοχές τραπεζιών',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterTableStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Κατάσταση Τραπεζιού',
        PlaceHolder: 'Όλες οι καταστάσεις τραπεζιών',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterWaiterStaff: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Σερβιτόρος',
        PlaceHolder: 'Όλες οι σερβιτόροι',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    },
    NoData: {
      Title : 'Δεν υπάρχει ανοιχτή βάρδια σερβιτόρου',
      Button: {
        ManageWaiters: 'Διαχείριση Σερβιτόρων'
      }
    },
    NoTables: {
      Title : 'Δεν υπάρχουν τραπέζια',
      Button: {
        ManageWaiters: 'Διαχείριση Τραπεζιών'
      }
    }
  },

  TableManagement: {
    Filter: {
      filterArea: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Περιοχή Τραπεζιών',
        PlaceHolder: 'Όλες οι περιοχές τραπεζιών',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    },

    Table: {
      Headers: {
        Name          : 'Όνομα',
        Code          : 'Κωδικός QR',
        Status        : 'Κατάσταση',
        Priority      : 'Ταξινόμιση',
        Area          : 'Περιοχή',
        MinCapacity   : 'Ελάχιστες Θέσεις',
        MaxCapacity   : 'Μέγιστες Θέσεις',
        Bookable      : 'Δυνατότητα Κράτησης',
        BookableOnline: 'Δυνατότητα Κράτησης Online',
        Active        : 'Κατάσταση',
        Actions       : ''
      }
    }
  },

  TableAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Τραπεζιού',
        Subtitle: 'Δημιουργία Νέου Τραπεζιού'
      },
      Edit: {
        Title   : 'Επεξεργασία Τραπεζιού',
        Subtitle: 'Τροποποίηση Στοιχείων Τραπεζιού'
      },
      Icon : 'fas fa-border-none',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Όνομα τραπεζιού. πχ. Α1, Β2, 24, κλπ.',
        Icon : 'title',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 1 έως 255 χαρακτήρες',
          Regex  : 'Το όνομα περιέχει μη έγκυρους χαρακτήρες.'
        }
      },
      AreaId: {
        Label: 'Περιοχή Τραπεζιού',
        Hint : 'Επιλέξτε Περιοχή που ανήκει το τραπέζι.',
        Icon : 'crop_square',
        Error: {
          Mandatory: 'Η περιοχή τραπεζιού είναι υποχρεωτική.'
        }
      },
      MinCapacity: {
        Label: 'Ελάχιστες Θέσεις',
        Hint : 'Ελάχιστη χωρητικότητα τραπεζιού. πχ. 2, 4, 6, κλπ.',
        Icon : 'person',
        Error: {
          Mandatory                 : 'Οι ελάχιστες Θέσεις είναι υποχρεωτικές.',
          Number                    : 'Οι ελάχιστες Θέσεις πρέπει να είναι αριθμός.',
          GreaterThanZero           : 'Οι ελάχιστες Θέσεις τραπεζιού πρέπει να είναι μεγαλύτερες από το μηδέν.',
          LessOrEqualThanMaxCapacity: 'Οι ελάχιστες Θέσεις πρέπει να είναι μεγαλύτερες ή ίσες με τις μέγιστες θέσεις.'
        }
      },
      MaxCapacity: {
        Label: 'Μέγιστες Θέσεις',
        Hint : 'Μέγιστη χωρητικότητα τραπεζιού. πχ. 2, 4, 6, κλπ.',
        Icon : 'groups',
        Error: {
          Mandatory                    : 'Οι μέγιστες Θέσεις είναι υποχρεωτικές.',
          Number                       : 'Οι μέγιστες Θέσεις πρέπει να είναι αριθμός.',
          GreaterThanZero              : 'Οι μέγιστες Θέσεις τραπεζιού πρέπει να είναι μεγαλύτερες από το μηδέν.',
          GreaterOrEqualThanMinCapacity: 'Οι μέγιστες Θέσεις πρέπει να είναι μεγαλύτερες ή ίσες με τις ελάχιστες θέσεις.'
        }
      },
      Priority: {
        Label: 'Ταξινόμηση',
        Hint : 'Ταξινόμηση. Σειρά εμφάνισης τραπεζιού. πχ. 2, 4, 6, κλπ. Αφήστε κενό για αυτόματη σειρά.',
        Icon : 'low_priority',
        Error: {
          Number         : 'Η ταξινόμηση πρέπει να είναι αριθμός.',
          GreaterThanZero: 'Η ταξινόμηση πρέπει να είναι μεγαλύτερη από το μηδέν.'
        }
      },
      QrUrl: {
        Label: 'Qr Code',
        Hint : 'Το Qr Code θα δημιουργηθεί αυτόματα κατά την αποθήκευση',
        Icon : 'qr_code'
      }
    },
    Notification: {
      Update: 'Το τραπέζι δεν ενημερώθηκε. @:Common.Misc.Retry'
    }
  },

  TableAreas: {
    Title  : 'Περιοχές Τραπεζιών | Περιοχή Τραπεζιών | Περιοχές Τραπεζιών',
    Toolbar: {
      Button: {
        Title: 'Νέα Περιοχή Τραπεζιών',
        Icon : 'add_box',
        Color: 'primary'
      }
    },

    Table: {
      Headers: {
        Color         : '',
        Id            : '#',
        Name          : 'Όνομα',
        TablesCount   : 'Τραπέζια',
        Priority      : 'Προτεραιότητα',
        Bookable      : 'Δυνατότητα Κράτησης',
        BookableOnline: 'Δυνατότητα Κράτησης Online',
        Status        : 'Κατάσταση',
        Actions       : ''
      }
    },

    Dialog: {
      Delete: {
        Title       : 'Διαγραφή Περιοχής;',
        Content     : 'Είστε σίγουροι ότι θέλετε να διαγράψετε την περιοχή τραπεζιών; Όλα τα τραπέζια που ανήκουν σε αυτή θα μεταφερθούν στην Default περιοχή.',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },

    Notification: {
      Delete: 'Η περιοχή τραπεζιών δεν διαγράφηκε. @:Common.Misc.Retry'
    }
  },

  TableAreaAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Προσθήκη Περιοχής Τραπεζιών',
        Subtitle: 'Δημιουργία Νέας Περιοχής Τραπεζιών'
      },
      Edit: {
        Title   : 'Επεξεργασία Περιοχής Τραπεζιών',
        Subtitle: 'Τροποποίηση Στοιχείων Περιοχής Τραπεζιών'
      },
      Icon : 'crop_square',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Όνομα περιοχής τραπεζιών. πχ. Σάλα, Βεράντα, Κήπος, κλπ.',
        Icon : 'title',
        Error: {
          Between: 'Το όνομα πρέπει να είναι από 2 έως 255 χαρακτήρες',
          Regex  : 'Το όνομα δεν μπορεί να περιέχει μη έγκυρους χαρακτήρες.'
        }
      },
      Priority: {
        Label: 'Ταξινόμηση',
        Hint : 'Ταξινόμηση. Σειρά εμφάνισης περιοχής τραπεζιού. πχ. 2, 4, 6, κλπ. Αφήστε κενό για αυτόματη σειρά.',
        Icon : 'low_priority',
        Error: {
          Number         : 'Η ταξινόμηση πρέπει να είναι αριθμός.',
          GreaterThanZero: 'Η ταξινόμηση πρέπει να είναι μεγαλύτερη από το μηδέν.'
        }
      },
      Tags: {
        Label: 'Tags',
        Hint : 'Tags περιοχής τραπεζιών πχ. Εξωτερικός Χώρος, Εσωτερικός Χώρος, Καπνιστές, Μη Καπνιστές, κλπ.',
        Icon : 'mdi mdi-tag-plus',
        Error: {
          Length: 'Δεν μπορείτε να προσθέσετε περισσότερα απο 5 tags.',
          Regex : 'Τα Tags δεν μπορούν να περιέχουν μη έγκυρους χαρακτήρες.'
        }
      },
      Bookable: {
        Label: 'Δυνατότητα Κράτησης'
      },
      BookableOnline: {
        Label: 'Δυνατότητα Κράτησης Online'
      },
      Color: {
        Label: 'Χρώμα Περιοχής Τραπεζιών'
      }
    },
    Notification: {
      Update: 'Η περιοχή τραπεζιών δεν ενημερώθηκε. @:Common.Misc.Retry'
    }
  }
}
