<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        class="filter-toolbar"
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        flat
      >
        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.Tables.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showTableAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.Tables.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Tables.Toolbar.Button.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="userCanAdd"
          :color="$t('Restaurant.TableAreas.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          :to="{name: 'TableAreasManagement'}"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.TableAreaAddDialog.Toolbar.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $tc('Restaurant.TableAreas.Title', 0) }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('QrManagement', 'MANAGE')"
          :color="$t('Qr.Toolbar.Button.Export.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          :loading="loadingExport"
          class="elevation-0 ma-1"
          text
          @click="onExportButtonClick"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Qr.Toolbar.Button.Export.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $tc('Qr.Title', 0) }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />

        <filter-options
          v-model="filtersModel"
          :filters="filterData"
        />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.table_name`]="{item}">
              <template v-if="userCanManage">
                <a
                  href="#"
                  @click.prevent="showTableEditDialog(item)"
                >
                  {{ item.Name }}
                </a>
              </template>
              <template v-else>
                {{ item.Name }}
              </template>
            </template>

            <template #[`item.qr`]="{item}">
              <template v-if="item.QrUrl">
                <a
                  :href="item.QrUrl"
                  target="_blank"
                >
                  <v-icon>qr_code</v-icon>
                </a>
              </template>
            </template>

            <template #[`item.table_status_id`]="{item}">
              <v-badge
                v-model="item.HasParent"
                color="indigo"
                overlap
                right
              >
                <span slot="badge">
                  <v-icon>merge_type</v-icon>
                  {{ item.ParentName }}
                </span>
                <v-chip
                  :color="item.HasParent ? 'indigo lighten-2' : item.StatusColor"
                  class="white--text ma-0"
                  label
                  small
                  style="z-index: 0; min-width: 100px"
                >
                  {{ item.HasParent ? getStatusNameById(1000) : getStatusNameById(item.StatusId) || item.StatusName }}
                </v-chip>
              </v-badge>
            </template>

            <template #[`item.priority`]="{item}">
              {{ item.Priority }}
            </template>

            <template #[`item.table_area_id`]="{item}">
              <v-chip
                :color="`#${item.AreaColor}`"
                class="white--text ma-0"
                label
                small
                style="z-index: 0; min-width: 100px;"
                @click="onTableAreaChipClick(item.AreaId)"
              >
                {{ item.AreaName | capitalize }}
              </v-chip>
            </template>

            <template #[`item.min_capacity`]="{item}">
              {{ item.MinCapacity }}
            </template>

            <template #[`item.max_capacity`]="{item}">
              {{ item.MaxCapacity }}
            </template>

            <template #[`item.bookable`]="{item}">
              <v-switch
                v-model="item.Bookable"
                inset
                :disabled="!userCanManage"
                :label="item.Bookable ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-0"
                color="indigo"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.bookable_online`]="{item}">
              <v-switch
                v-model="item.BookableOnline"
                inset
                :disabled="!userCanManage"
                :label="item.BookableOnline ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-0"
                color="blue"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.table_status`]="{item}">
              <v-switch
                v-model="item.Active"
                inset
                :disabled="!userCanManage"
                :label="item.Active ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                class="ma-0"
                color="success"
                hide-details
                @change="updateItemStatus(item)"
              />
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="userCanManage"
                class="cyan elevation-2 ma-0"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="showTableEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>

              <v-btn
                v-if="userCanDelete"
                class="red elevation-2 ma-1"
                :small="$vuetify.breakpoint.xsOnly"
                dark
                icon
                @click="deleteTable(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <table-add-dialog
      :item-to-edit="selectedItem"
      :table-area-combo="tableAreaCombo"
      :visible.sync="addEditTableDialog"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Tables.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.Tables.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Tables.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>
import AppData        from '@/mixins/appdata'
import Auth           from '@/mixins/auth'
import Translatable   from '@/mixins/translatable'
import FilterCommon   from '@/mixins/filter/filterCommon'
import DataTable      from '@/mixins/data-table/dataTable'
import ConfirmDialog  from '@/components/common/ConfirmDialog'
import FilterSearch   from '@/components/common/filter/FilterSearch.vue'
import FilterOptions  from '@/components/common/filter/FilterOptions.vue'
import TableAddDialog from './TableAddDialog'

export default {
  name      : 'TableManagement',
  components: {
    FilterOptions,
    FilterSearch,
    'table-add-dialog': TableAddDialog,
    'confirm-dialog'  : ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, FilterCommon, DataTable],
  data () {
    return {
      filtersModel: {
        lang      : 'Restaurant.TableManagement',
        filterArea: {
          key  : 'filter_area',
          value: [],
          items: []
        }
      },
      confirmDialogDeleteVisible: false,
      tableAreaCombo            : [],
      selectedItem              : null,
      addEditTableDialog        : false,
      loadingExport             : false,
      dataTable                 : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Table.All,
          save  : window.SocketCommand.Table.Save,
          delete: window.SocketCommand.Table.Delete
        },
        options: {
          defaultSortBy: 'table_id',
          sortBy       : ['table_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Name'),
            value    : 'table_name',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Code'),
            value    : 'qr',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Status'),
            value    : 'table_status_id',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Priority'),
            value    : 'priority',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Area'),
            value    : 'table_area_id',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.MinCapacity'),
            value    : 'min_capacity',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.MaxCapacity'),
            value    : 'max_capacity',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Bookable'),
            value    : 'bookable',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.BookableOnline'),
            value    : 'bookable_online',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Active'),
            value    : 'table_status',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.TableManagement.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption px-2',
            width    : 100
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  created () {
    // console.log('1. created');

    // Init Custom Filter Values
    this.filtersModel.filterArea.items = this.tableAreaCombo
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
    this.$bus.$on(window.SocketCommand.QRCode.Export, this.onExportResult)
    this.getTableAreaCombo()
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Table.Area.List, this.setTableAreaCombo)
    this.$bus.$off(window.SocketCommand.QRCode.Export, this.onExportResult)
  },
  methods: {
    onExportButtonClick () {
      this.loadingExport = true
      window.callAS(window.SocketCommand.QRCode.Export, {})
    },

    onExportResult (response) {
      this.loadingExport = false

      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Qr.Toolbar.Button.Export.SuccessMessage', { email: this.posUser?.staff_email || '' }),
          type   : 'success',
          icon   : 'check',
          timeout: 5000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    showTableAddDialog () {
      this.selectedItem = null
      this.addEditTableDialog = true
    },

    showTableEditDialog (item) {
      this.selectedItem = item
      this.addEditTableDialog = true
    },

    getStatusNameById (statusId) {
      return Object.values(this.$t('Restaurant.Tables.TableStatus')).find(status => status.Id === statusId)?.Name
    },

    getTableAreaCombo () {
      window.callAS(window.SocketCommand.Table.Area.List)
    },
    setTableAreaCombo (data) {
      data = (data || []).map(o => {
        o.Avatar = {
          Color: `#${ o.Color }`
        }
        return o
      })
      this.tableAreaCombo = data
      this.filtersModel.filterArea.items = this.tableAreaCombo
    },

    updateItemStatus (item) {
      window.callAS(window.SocketCommand.Table.Save, item)
    },

    onTableAreaChipClick (tableAreaId) {
      this.filtersModel.filterArea.value = tableAreaId
    },

    deleteTable (item) {
      this.selectedItem = item
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Table.Delete, { Id: this.selectedItem.Id })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Tables.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  width    : 75px !important;
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}

/deep/ .v-chip__content {
  width           : 100%;
  justify-content : center;
}
</style>
