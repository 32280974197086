import BaseModel              from '../../../lib/data/Models/BaseModel'
import BaseCollection         from '../../../lib/data/Collections/BaseCollection'
import MessageGroupCollection from '../../Collections/messages/MessageGroupCollection'
import MessageTypeCollection  from '../../Collections/messages/MessageTypeCollection'
import SocketCommand          from '../../SocketCommand'

export default class MessageConfigModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    if (rawData.hasOwnProperty('Types')) {
      rawData.Types.forEach((type, index) => {
        rawData.Types[index].GroupName = rawData.Groups.find(group => group.Id.toString() === type.GroupId.toString()).Name
      })
    }
    this.Apps = new BaseCollection(rawData.Apps || [])
    this.Groups = new MessageGroupCollection(rawData.Groups || [])
    this.Types = new MessageTypeCollection(rawData.Types || [])
  }

  boot () {
    this.fetchCommand = SocketCommand.Message.Config
  }

  /* PROPERTIES */

  /* METHODS */

  onFetch (data) {
    super.onFetch(data)

    if (data.hasOwnProperty('Types')) {
      data.Types.forEach((type, index) => {
        data.Types[index].GroupName = data.Groups.find(group => group.Id.toString() === type.GroupId.toString()).Name
      })
    }
    this.Apps.clear().add(data.Apps || [])
    this.Groups.clear().add(data.Groups || [])
    this.Types.clear().add(data.Types || [])
  }
}
