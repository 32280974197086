<template>
  <v-card>
    <v-toolbar
      flat
      extension-height="40"
      height="80"
      class="datatable-object settings-window-toolbar"
    >
      <v-avatar
        :color="$t('Settings.Printer.Toolbar.Color')"
      >
        <v-icon dark>
          {{ $t('Settings.Printer.Toolbar.Icon') }}
        </v-icon>
      </v-avatar>

      <v-toolbar-title class="pl-3">
        <div class="body-3">
          {{ $tc('Settings.Printer.Title', 0) }}
        </div>

        <div class="text-caption">
          {{ $t('Settings.Printer.Toolbar.Subtitle') }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="userCanManage"
        color="light-green"
        class="white--text elevation-0"
        :disabled="!isDesktop"
        :icon="$vuetify.breakpoint.smAndDown"
        @click="onUpdateSystemPrintersClick"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">
          refresh
        </v-icon>

        <span v-if="$vuetify.breakpoint.mdAndUp">
          {{ $t('Settings.Printer.Toolbar.Button.Refresh') }}
        </span>
      </v-btn>

      <template #extension>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          height="40"
          slider-color="blue"
        >
          <v-tab key="groups">
            {{ $t('Settings.Printer.Tab.Groups') }}
          </v-tab>

          <v-tab key="default">
            {{ $t('Settings.Printer.Tab.Default') }}
          </v-tab>

          <v-tab key="preparation">
            {{ $t('Settings.Printer.Tab.Preparation') }}
          </v-tab>

          <v-tab
            key="reciept"
            :disabled="!userCanViewReceipt"
          >
            {{ $t('Settings.Printer.Tab.Receipt') }}
          </v-tab>

          <v-tab
            key="total"
            :disabled="!userCanViewReceipt"
          >
            {{ $t('Settings.Printer.Tab.Total') }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-divider />

    <v-card-text
      id="settingsWindow"
      style="overflow-y: auto;"
      :class="[{'pt-0': tab === 0}]"
    >
      <v-form
        ref="settingsForm"
        v-model="settingsFormValid"
        @submit.prevent
      >
        <v-tabs-items v-model="tab">
          <v-tab-item key="groups">
            <v-toolbar
              flat
              color="transparent"
              class="printers-group"
            >
              <v-btn
                class="elevation-0"
                color="primary"
                @click="onAddPrintersGroupClick"
              >
                <v-icon left>
                  add
                </v-icon>
                {{ $t('Common.Button.Add') }}
              </v-btn>
            </v-toolbar>

            <v-divider />

            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-data-table
                    :headers="printerGroupsHeaders"
                    :items="dataTable.data"
                    :footer-props="dataTable.footerProps"
                    :height="dataTable.resize.bodyHeight"
                    :loading="dataTable.loading"
                    :loading-text="dataTable.text.loadingText"
                    :no-data-text="dataTable.text.noDataText"
                    :no-results-text="dataTable.text.noResultsText"
                    :options.sync="dataTable.options"
                    class="elevation-0"
                    fixed-header
                    hide-default-footer
                  >
                    <template #[`item.Id`]="{item}">
                      {{ item.Id }}
                    </template>

                    <template #[`item.Name`]="{item}">
                      <div :class="{'caption': $vuetify.breakpoint.xsOnly}">
                        {{ item.Name }}
                      </div>
                    </template>

                    <template #[`item.Description`]="{item}">
                      <div :class="{'caption': $vuetify.breakpoint.xsOnly}">
                        {{ item.Desc }}
                      </div>
                    </template>

                    <template #[`item.PrintersCount`]="{item}">
                      <v-chip
                        class="justify-center"
                        color="grey"
                        label
                        small
                        text-color="white"
                      >
                        {{ item.PrintersCount }}
                      </v-chip>
                    </template>

                    <template #[`item.Status`]="{item}">
                      <v-switch
                        v-model="item.Status"
                        inset
                        :disabled="!userCanManage"
                        :label="item.Status ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                        color="success"
                        class="ma-0"
                        hide-details
                        @change="onTogglePrinterGroupStatus(item)"
                      />
                    </template>

                    <template #[`item.Actions`]="{item}">
                      <v-btn
                        v-if="userCanDelete && userCanManage && parseInt(item.PrintersCount) === 0"
                        :disabled="parseInt(item.PrintersCount) > 0 || item.Loading"
                        :class="$t('Settings.Permission.Toolbar.Button.Delete.Color')"
                        :loading="item.loading"
                        class="white--text elevation-2 ma-1"
                        small
                        icon
                        @click="onDeletePrintersGroupClick(item)"
                      >
                        <v-icon small>
                          delete
                        </v-icon>
                      </v-btn>

                      <v-btn
                        v-if="userCanAdd && userCanManage"
                        class="cyan white--text elevation-2 ma-1"
                        small
                        icon
                        @click="onEditPrintersGroupClick(item)"
                      >
                        <v-icon small>
                          edit
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="default">
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="printers.length">
                    <v-expansion-panels accordion>
                      <template v-for="(machine, i) in printers">
                        <v-expansion-panel :key="i">
                          <v-expansion-panel-header class="px-0">
                            <div
                              style="max-width: 45px;"
                              class="mr-3"
                            >
                              <v-badge
                                overlap
                                bordered
                                color="green"
                                :value="machine.guid === $DataStore.app.guid"
                              >
                                <template #badge>
                                  <v-icon>mdi-account</v-icon>
                                </template>

                                <v-avatar
                                  size="45"
                                  color="grey"
                                >
                                  <v-icon dark>
                                    mdi-desktop-classic
                                  </v-icon>
                                </v-avatar>
                              </v-badge>
                            </div>

                            <div>
                              <h5 class="text-body-2 font-weight-bold">
                                {{ machine.hostname }}
                              </h5>
                              <span class="text-caption grey--text">{{ machine.guid }}</span>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-card flat>
                              <v-card-text
                                class="pa-0 ma-0 overflow-auto"
                                :class="isDarkMode ? '' : 'grey lighten-4'"
                              >
                                <div class="divTable">
                                  <div class="divTableHeading">
                                    <div class="divTableRow">
                                      <div class="divTableHead">
                                        {{ $tc('Settings.Printer.Title', 1) }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Auto') }} <br> {{ $t('Settings.Printer.Header.Print') }}
                                      </div>
                                      <div
                                        class="divTableHead"
                                        v-html="$t('Settings.Printer.Header.WaiterPrint')"
                                      />
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Copy') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.MaxWidth') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.FontSize') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Status') }}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-for="(printer, index) in machine.printers_save"
                                    :key="index"
                                    class="divTableRow"
                                  >
                                    <div class="divTableCell">
                                      {{ printer.name }}
                                    </div>
                                    <div class="divTableCell">
                                      <v-switch
                                        v-model="printer.copies"
                                        inset
                                        :disabled="!userCanManage"
                                        :false-value="'0'"
                                        :label="parseInt(printer.copies) === 0 ? $t('Common.Button.No') : $t('Common.Button.Yes')"
                                        :true-value="parseInt(printer.copies) > 1 ? String(printer.copies) : '1'"
                                        color="success"
                                        hide-details
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-switch
                                        v-model="printer.waiter"
                                        inset
                                        :disabled="!userCanManage"
                                        :false-value="'0'"
                                        :label="parseInt(printer.waiter) === 0 ? $t('Common.Button.No') : $t('Common.Button.Yes')"
                                        :true-value="'1'"
                                        color="success"
                                        hide-details
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.copies"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.Copy')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        min="0"
                                        max="100"
                                        step="1"
                                        type="number"
                                        @change="onDefaultPrinterCopiesChange(printer)"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.max_width"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.MaxWidth')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="mm"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.font_size"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.FontSize')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="pt"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-switch
                                        v-model="printer.status"
                                        inset
                                        :disabled="!userCanManage"
                                        :false-value="'0'"
                                        :label="parseInt(printer.status) === 1 ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                        :true-value="'1'"
                                        color="success"
                                        hide-details
                                      />
                                    </div>
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </template>
                    </v-expansion-panels>
                  </template>

                  <template v-else>
                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row class="">
                        <v-col cols="12">
                          <div class="text-center grey--text">
                            <v-icon
                              class="mb-4"
                              color="grey lighten-2"
                              size="86"
                            >
                              mdi-printer-alert
                            </v-icon>

                            <div
                              class="text-h6 mb-4"
                              style="height: auto;"
                            >
                              {{ $t('Settings.Printer.Notification.NoPrinters') }}
                            </div>

                            <v-btn
                              v-if="userCanManage"
                              color="light-green"
                              class="white--text elevation-0"
                              :disabled="!isDesktop"
                              :icon="$vuetify.breakpoint.smAndDown"
                              @click="onUpdateSystemPrintersClick"
                            >
                              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                                refresh
                              </v-icon>

                              <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{ $t('Settings.Printer.Toolbar.Button.Refresh') }}
                              </span>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="preparation">
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="printers.length">
                    <v-expansion-panels accordion>
                      <template v-for="(machine, i) in printers">
                        <v-expansion-panel :key="i">
                          <v-expansion-panel-header class="px-0">
                            <div
                              style="max-width: 45px;"
                              class="mr-3"
                            >
                              <v-badge
                                overlap
                                bordered
                                color="green"
                                :value="machine.guid === $DataStore.app.guid"
                              >
                                <template #badge>
                                  <v-icon>mdi-account</v-icon>
                                </template>

                                <v-avatar
                                  size="45"
                                  color="grey"
                                >
                                  <v-icon dark>
                                    mdi-desktop-classic
                                  </v-icon>
                                </v-avatar>
                              </v-badge>
                            </div>

                            <div>
                              <h5 class="text-body-2 font-weight-bold">
                                {{ machine.hostname }}
                              </h5>
                              <span class="text-caption grey--text">{{ machine.guid }}</span>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-card flat>
                              <v-card-text
                                class="pa-0 ma-0 overflow-auto"
                                :class="isDarkMode ? '' : 'grey lighten-4'"
                              >
                                <div class="divTable">
                                  <div class="divTableHeading">
                                    <div class="divTableRow">
                                      <div class="divTableHead">
                                        {{ $tc('Settings.Printer.Title', 1) }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Auto') }} <br> {{ $t('Settings.Printer.Header.Print') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Copy') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.MaxWidth') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.FontSize') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Group') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Status') }}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-for="(printer, printerIndex) in machine.printers_preparation_save"
                                    :key="printerIndex"
                                    class="divTableRow"
                                  >
                                    <div class="divTableCell">
                                      {{ printer.name }}
                                    </div>
                                    <div class="divTableCell">
                                      <v-switch
                                        v-model="printer.copies"
                                        inset
                                        :disabled="!userCanManage"
                                        :false-value="'0'"
                                        :label="parseInt(printer.copies) === 0 ? $t('Common.Button.No') : $t('Common.Button.Yes')"
                                        :true-value="parseInt(printer.copies) > 1 ? String(printer.copies) : '1'"
                                        color="success"
                                        hide-details
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.copies"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.Copy')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        min="0"
                                        max="100"
                                        step="1"
                                        type="number"
                                        @change="onDefaultPrinterCopiesChange(printer)"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.max_width"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.MaxWidth')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="mm"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.font_size"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.FontSize')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="pt"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-select
                                        v-model="printer.groups"
                                        :items="appPrinterGroups"
                                        :label="$t('Settings.Printer.Header.Group')"
                                        item-text="Name"
                                        item-value="Id"
                                        style="min-width: 85px; max-width: 350px;"
                                        multiple
                                        hide-details
                                        required
                                        solo
                                      >
                                        <template #selection="{item, index}">
                                          <v-chip
                                            v-if="index < 2"
                                            small
                                            label
                                          >
                                            <span>{{ item.Name }}</span>
                                          </v-chip>
                                          <span
                                            v-if="index > 1"
                                            class="grey--text text-caption"
                                          >(+{{ printer.groups.length - 2 }})</span>
                                        </template>
                                      </v-select>
                                    </div>
                                    <div class="divTableCell">
                                      <v-switch
                                        v-model="printer.status"
                                        inset
                                        :disabled="!userCanManage"
                                        :false-value="'0'"
                                        :label="parseInt(printer.status) === 1 ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                        :true-value="'1'"
                                        color="success"
                                        hide-details
                                      />
                                    </div>
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </template>
                    </v-expansion-panels>
                  </template>

                  <template v-else>
                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row class="">
                        <v-col cols="12">
                          <div class="text-center grey--text">
                            <v-icon
                              class="mb-4"
                              color="grey lighten-2"
                              size="86"
                            >
                              mdi-printer-alert
                            </v-icon>

                            <div
                              class="text-h6 mb-4"
                              style="height: auto;"
                            >
                              {{ $t('Settings.Printer.Notification.NoPrinters') }}
                            </div>

                            <v-btn
                              v-if="userCanManage"
                              color="light-green"
                              class="white--text elevation-0"
                              :disabled="!isDesktop"
                              :icon="$vuetify.breakpoint.smAndDown"
                              @click="onUpdateSystemPrintersClick"
                            >
                              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                                refresh
                              </v-icon>

                              <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{ $t('Settings.Printer.Toolbar.Button.Refresh') }}
                              </span>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="reciept">
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="printers.length">
                    <v-expansion-panels accordion>
                      <template v-for="(machine, i) in printers">
                        <v-expansion-panel :key="i">
                          <v-expansion-panel-header class="px-0">
                            <div
                              style="max-width: 45px;"
                              class="mr-3"
                            >
                              <v-badge
                                overlap
                                bordered
                                color="green"
                                :value="machine.guid === $DataStore.app.guid"
                              >
                                <template #badge>
                                  <v-icon>mdi-account</v-icon>
                                </template>

                                <v-avatar
                                  size="45"
                                  color="grey"
                                >
                                  <v-icon dark>
                                    mdi-desktop-classic
                                  </v-icon>
                                </v-avatar>
                              </v-badge>
                            </div>

                            <div>
                              <h5 class="text-body-2 font-weight-bold">
                                {{ machine.hostname }}
                              </h5>
                              <span class="text-caption grey--text">{{ machine.guid }}</span>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-card flat>
                              <v-card-text
                                class="pa-0 ma-0 overflow-auto"
                                :class="isDarkMode ? '' : 'grey lighten-4'"
                              >
                                <div class="divTable">
                                  <div class="divTableHeading">
                                    <div class="divTableRow">
                                      <div class="divTableHead">
                                        {{ $tc('Settings.Printer.Title', 1) }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.MaxWidth') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.FontSize') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Status') }}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-for="(printer, index) in machine.printers_receipts_save"
                                    :key="index"
                                    class="divTableRow"
                                  >
                                    <div class="divTableCell">
                                      {{ printer.name }}
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.max_width"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.MaxWidth')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="mm"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.font_size"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.FontSize')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="pt"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-switch
                                        :id="printer.index"
                                        v-model="printer.status"
                                        inset
                                        :disabled="!userCanManage"
                                        :false-value="'0'"
                                        :label="parseInt(printer.status) === 1 ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                        :true-value="'1'"
                                        color="success"
                                        hide-details
                                        @change="onReceiptPrinterStatusChange(printer, machine)"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </template>
                    </v-expansion-panels>
                  </template>

                  <template v-else>
                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row class="">
                        <v-col cols="12">
                          <div class="text-center grey--text">
                            <v-icon
                              class="mb-4"
                              color="grey lighten-2"
                              size="86"
                            >
                              mdi-printer-alert
                            </v-icon>

                            <div
                              class="text-h6 mb-4"
                              style="height: auto;"
                            >
                              {{ $t('Settings.Printer.Notification.NoPrinters') }}
                            </div>

                            <v-btn
                              v-if="userCanManage"
                              color="light-green"
                              class="white--text elevation-0"
                              :disabled="!isDesktop"
                              :icon="$vuetify.breakpoint.smAndDown"
                              @click="onUpdateSystemPrintersClick"
                            >
                              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                                refresh
                              </v-icon>

                              <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{ $t('Settings.Printer.Toolbar.Button.Refresh') }}
                              </span>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item key="total">
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="printers.length">
                    <v-expansion-panels accordion>
                      <template v-for="(machine, i) in printers">
                        <v-expansion-panel :key="i">
                          <v-expansion-panel-header class="px-0">
                            <div
                              style="max-width: 45px;"
                              class="mr-3"
                            >
                              <v-badge
                                overlap
                                bordered
                                color="green"
                                :value="machine.guid === $DataStore.app.guid"
                              >
                                <template #badge>
                                  <v-icon>mdi-account</v-icon>
                                </template>

                                <v-avatar
                                  size="45"
                                  color="grey"
                                >
                                  <v-icon dark>
                                    mdi-desktop-classic
                                  </v-icon>
                                </v-avatar>
                              </v-badge>
                            </div>

                            <div>
                              <h5 class="text-body-2 font-weight-bold">
                                {{ machine.hostname }}
                              </h5>
                              <span class="text-caption grey--text">{{ machine.guid }}</span>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-card flat>
                              <v-card-text
                                class="pa-0 ma-0 overflow-auto"
                                :class="isDarkMode ? '' : 'grey lighten-4'"
                              >
                                <div class="divTable">
                                  <div class="divTableHeading">
                                    <div class="divTableRow">
                                      <div class="divTableHead">
                                        {{ $tc('Settings.Printer.Title', 1) }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.MaxWidth') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.FontSize') }}
                                      </div>
                                      <div class="divTableHead">
                                        {{ $t('Settings.Printer.Header.Status') }}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-for="(printer, index) in machine.printers_totals_save"
                                    :key="index"
                                    class="divTableRow"
                                  >
                                    <div class="divTableCell">
                                      {{ printer.name }}
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.max_width"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.MaxWidth')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="mm"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-text-field
                                        v-model="printer.font_size"
                                        :disabled="!userCanManage"
                                        :label="$t('Settings.Printer.Header.FontSize')"
                                        class="elevation-0"
                                        hide-details
                                        required
                                        solo
                                        style="min-width: 85px; max-width: 100px;"
                                        suffix="pt"
                                        type="number"
                                        min="0"
                                        step="1"
                                      />
                                    </div>
                                    <div class="divTableCell">
                                      <v-switch
                                        :id="printer.index"
                                        v-model="printer.status"
                                        inset
                                        :disabled="!userCanManage"
                                        :false-value="'0'"
                                        :label="parseInt(printer.status) === 1 ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                                        :true-value="'1'"
                                        color="success"
                                        hide-details
                                      />
                                    </div>
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </template>
                    </v-expansion-panels>
                  </template>

                  <template v-else>
                    <v-container
                      fluid
                      class="pa-0"
                    >
                      <v-row class="">
                        <v-col cols="12">
                          <div class="text-center grey--text">
                            <v-icon
                              class="mb-4"
                              color="grey lighten-2"
                              size="86"
                            >
                              mdi-printer-alert
                            </v-icon>

                            <div
                              class="text-h6 mb-4"
                              style="height: auto;"
                            >
                              {{ $t('Settings.Printer.Notification.NoPrinters') }}
                            </div>

                            <v-btn
                              v-if="userCanManage"
                              color="light-green"
                              class="white--text elevation-0"
                              :disabled="!isDesktop"
                              :icon="$vuetify.breakpoint.smAndDown"
                              @click="onUpdateSystemPrintersClick"
                            >
                              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                                refresh
                              </v-icon>

                              <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{ $t('Settings.Printer.Toolbar.Button.Refresh') }}
                              </span>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 settings-window-actions">
      <v-spacer />

      <v-btn
        class="light-blue--text text--darken-1"
        outlined
        @click="onCancelButtonClick"
      >
        {{ $t('Common.Button.Close').toLocaleUpperCase($i18n.locale) }}
      </v-btn>

      <v-btn
        v-if="userCanManage && tab > 0"
        :disabled="viewData===null || isSaving"
        :loading="isSaving"
        class="green white--text elevation-0"
        @click="onSaveButtonClick"
      >
        {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
      </v-btn>
    </v-card-actions>

    <printer-group-add-dialog
      :visible.sync="printerGroupAddDialogVisible"
      :item-to-edit="selectedPrinterGroup"
    />
  </v-card>
</template>

<script>
import AppData               from '@/mixins/appdata'
import Auth                  from '@/mixins/auth'
import Printer               from '@/lib/helper/printer'
import capitalize            from '@/filters/string/capitalize'
import PrinterGroupAddDialog from '@/components/settings/printers/PrinterGroupAddDialog.vue'
import isElectron            from '@/electron/isElectron'
import DataTable             from '@/mixins/data-table/dataTable'

export default {
  components: { PrinterGroupAddDialog },
  mixins    : [AppData, Auth, DataTable],
  data () {
    return {
      tab                         : null,
      isSaving                    : false,
      settingsFormValid           : false,
      printerGroupAddDialogVisible: false,
      selectedPrinterGroup        : null,
      categoryItems               : [],
      dataTable                   : {
        socketCommand: {
          list: window.SocketCommand.System.Printers.Get
        },
        resize: {
          offset: 110
        },
        options: {
          serverSideEnabled: false,
          itemsPerPage     : -1
        }
      },
      tmpObjDefault: {
        copies   : '0',
        index    : '0',
        name     : '',
        status   : '0',
        groups   : [],
        font_size: '10',
        max_width: '72'
      },
      tmpObjPreparation: {
        copies   : '0',
        index    : '0',
        name     : '',
        status   : '0',
        groups   : [],
        font_size: '10',
        max_width: '72'
      },
      tmpObjReceipts: {
        copies   : '0',
        index    : '0',
        name     : '',
        status   : '0',
        groups   : [],
        font_size: '9',
        max_width: '72'
      },
      tmpObjTotals: {
        copies   : '0',
        index    : '0',
        name     : '',
        status   : '0',
        groups   : [],
        font_size: '10',
        max_width: '72'
      },
      validationRules: {
        settingsForm: {
          Name: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Settings.Printer.Field.Name.Error.Between')
          ],
          Email: [
            (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Settings.Printer.Field.Email.Error.Between'),
            (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Settings.Printer.Field.Email.Error.Valid')
          ]
        }
      }
    }
  },
  computed: {
    isDesktop () {
      return isElectron()
    },

    systemPrinters () {
      return Printer.getSystemPrinters()
    },

    printers () {
      return this.viewData?.printers || []
    },

    printerGroups () {
      return this.viewData?.groups || []
    },

    printerGroupsHeaders () {
      return [
        {
          text    : '#',
          align   : 'left',
          value   : 'Id',
          sortable: false
        },
        {
          text    : this.$t('Common.Misc.Name'),
          align   : 'left',
          value   : 'Name',
          sortable: false
        },
        {
          text    : this.$t('Common.Misc.Desc'),
          align   : 'left',
          value   : 'Description',
          sortable: false
        },
        {
          text    : this.$tc('Common.Misc.Printer', 0),
          align   : 'center',
          value   : 'PrintersCount',
          sortable: false
        },
        {
          text    : this.$t('Common.Misc.Status'),
          align   : 'left',
          value   : 'Status',
          sortable: false
        },
        {
          text    : '',
          align   : 'right',
          value   : 'Actions',
          sortable: false
        }
      ]
    }
  },
  watch: {},
  created () {
    this.setData({
      groups  : this.appPrinterGroups || [],
      printers: []
    })
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.System.Printers.Save, this.onSaveResult)
    this.$bus.$on(window.SocketCommand.Settings.Printer.Group.Save, this.onSavePrinterGroupResult)
    this.$bus.$on(window.SocketCommand.Settings.Printer.Group.Delete, this.onDeletePrinterGroupResult)
  },
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.System.Printers.Save, this.onSaveResult)
    this.$bus.$off(window.SocketCommand.Settings.Printer.Group.Save, this.onSavePrinterGroupResult)
    this.$bus.$off(window.SocketCommand.Settings.Printer.Group.Delete, this.onDeletePrinterGroupResult)
  },
  methods: {
    onDefaultPrinterCopiesChange (printer) {
      if (parseInt(printer.copies) > 100) printer.copies = '100'
      if (parseInt(printer.copies) < 0) printer.copies = '0'
    },

    onReceiptPrinterStatusChange (printer, item) {
      const printersArray = item.printers_receipts_save.filter(p => p.name !== printer.name)
      for (let i = 0; i < printersArray.length; i++) {
        printersArray[i].status = '0'
        printersArray[i].copies = '0'
      }
      printer.copies = '1'
    },

    onAddPrintersGroupClick () {
      this.selectedPrinterGroup = null
      this.printerGroupAddDialogVisible = true
    },
    onSavePrinterGroupResult () {
      this.selectedPrinterGroup = null
      this.getData()
    },

    onEditPrintersGroupClick (item) {
      if (!item) return
      const payload = JSON.parse(JSON.stringify(item))
      this.selectedPrinterGroup = payload
      this.printerGroupAddDialogVisible = true
    },

    onTogglePrinterGroupStatus (item) {
      if (!item) return
      const payload = JSON.parse(JSON.stringify(item))
      window.callAS(window.SocketCommand.Settings.Printer.Group.Save, payload)
    },

    onDeletePrintersGroupClick (item) {
      if (!item) return
      this.$set(item, 'loading', true)
      const payload = { Id: item.Id }
      window.callAS(window.SocketCommand.Settings.Printer.Group.Delete, payload)
    },
    onDeletePrinterGroupResult () {
      this.getData()
    },

    setData (data) {
      this.$set(this.viewData, 'items', data?.groups || [])
      this.$set(this.viewData, 'groups', data?.groups || [])
      this.$set(this.viewData, 'printers', data?.printers || [])

      this.$nextTick(() => {
        this.setDataInternal(this.viewData)
        this.initPrinters(this.viewData)
      })
    },

    initPrinters (data) {
      if (!data) return

      const fields = ['default', 'preparation', 'receipts', 'totals']
      fields.forEach(field => {
        const fieldName = `printers_${ field }`
        const saveFieldName = field === 'default' ? 'printers_save' : `printers_${ field }_save`

        if (!Array.isArray(data?.printers)) data.printers = []

        for (let i = 0; i < data.printers.length; i++) {
          const tmpPrinters = []
          const machine = data.printers[i]

          for (let p = 0; p < machine.printers.length; p++) {
            const printer = machine.printers[p]
            const tmp = JSON.parse(JSON.stringify(this[`tmpObj${ capitalize(field) }`]))
            tmp.index = p.toString()
            tmp.name = printer

            for (let d = 0; d < machine[fieldName].length; d++) {
              if (machine[fieldName][d].name === tmp.name) {
                if (field === 'default') tmp.waiter = machine[fieldName][d]?.waiter || tmp.waiter
                tmp.status = machine[fieldName][d]?.status || tmp.status
                tmp.copies = machine[fieldName][d]?.copies || tmp.copies
                tmp.groups = machine[fieldName][d]?.groups || tmp.groups
                tmp.max_width = machine[fieldName][d]?.max_width || tmp.max_width
                tmp.font_size = machine[fieldName][d]?.font_size || tmp.font_size
              }
            }
            tmpPrinters.push(tmp)
          }

          this.$set(machine, saveFieldName, tmpPrinters)
        }
      })
    },

    onUpdateSystemPrintersClick () {
      window.callAS(window.SocketCommand.System.Printers.Set, {
        guid    : this.$DataStore.app.guid,
        hostname: this.$DataStore.app.computer.hostname,
        printers: this.systemPrinters
      })
    },

    onCancelButtonClick () {
      this.$router.replace({ name: 'SystemSettings' })
    },

    onSaveButtonClick () {
      this.isSaving = true
      window.callAS(window.SocketCommand.System.Printers.Save, { machines: this.viewData.printers })
    },

    onSaveResult (data) {
      this.isSaving = false

      if (data.status) {
        // this.$router.replace({ name: 'SystemSettings' })
        this.getData()

        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Printer.Notification.Success'),
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Settings.Printer.Notification.Error'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-expansion-panel-content__wrap {
  padding : 0;
}

/deep/ .printers-group .v-toolbar__content {
  padding : 0;
}

/deep/ .v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  width    : 75px !important;
}

/deep/ .v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}
</style>
