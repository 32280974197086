<template>
  <div v-if="permission">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1"
          extension-height="40"
          height="80"
          max-height="120"
        >
          <v-avatar
            :color="$t('Settings.Permission.Edit.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Settings.Permission.Edit.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ isEditMode ? $t('Settings.Permission.Edit.Toolbar.Edit.Title') : $t('Settings.Permission.Edit.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ isEditMode ? $t('Settings.Permission.Edit.Toolbar.Edit.Subtitle') : $t('Settings.Permission.Edit.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="permissionForm"
            v-model="permissionFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col
                  cols="3"
                >
                  <v-text-field
                    v-model="permission.Id"
                    :disabled="isEditMode"
                    :rules="validationRules.permissionForm.Id"
                    class="pr-2"
                    label="Id"
                    required
                    type="number"
                    min="0"
                    step="1"
                  />
                </v-col>

                <v-col
                  cols="9"
                >
                  <v-text-field
                    v-model="permission.Name"
                    :counter="32"
                    :label="$t('Settings.Permission.Edit.Field.Name.Label')"
                    :rules="validationRules.permissionForm.Name"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="permission.Description"
                    :counter="100"
                    :label="$t('Settings.Permission.Edit.Field.Description.Label')"
                    :rules="validationRules.permissionForm.Description"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="permission.Requires.Parent"
                    :items="permissions"
                    :label="$t('Settings.Permission.Edit.Field.Parent.Label')"
                    :menu-props="{maxHeight: '250'}"
                    clearable
                    item-text="Name"
                    item-value="Id"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="permission.Action"
                    :items="permissionActions"
                    :label="$t('Settings.Permission.Edit.Field.AccessType.Label')"
                    :rules="validationRules.permissionForm.Permissions"
                    deletable-chips
                    small-chips
                    multiple
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="permission.Requires.Permissions"
                    :items="permissions"
                    :label="$t('Settings.Permission.Edit.Field.RequiredPermissions.Label')"
                    :menu-props="{maxHeight: '250'}"
                    small-chips
                    deletable-chips
                    clearable
                    item-text="Name"
                    item-value="Id"
                    multiple
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <div class="d-inline-block">
                    <p class="grey--text text-caption ma-0">
                      {{ $t('Settings.Permission.Header.Permission') }}
                    </p>
                    <v-switch
                      v-model="permission.Status"
                      inset
                      :label="permission.Status ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="ma-0"
                      color="success"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '../../../mixins/appdata'
import Auth    from '../../../mixins/auth'

export default {
  name      : 'PermissionEditDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    permission: {
      type   : Object,
      default: undefined
    },
    permissions: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      editMode           : null,
      permissionFormValid: false,
      permissionActions  : ['api', 'access', 'add', 'manage', 'delete'],
      validationRules    : {
        permissionForm: {
          Id: [
            (v) => !!v || this.$t('Settings.Permission.Edit.Field.Id.Error.Required')
          ],
          Name: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Settings.Permission.Edit.Field.Name.Error.Between')
          ],
          Description: [
            (v) => v && v.length >= 2 && v.length <= 100 || this.$t('Settings.Permission.Edit.Field.Description.Error.Required')
          ],
          Permissions: [
            (v) => v && v.length > 0 || this.$t('Settings.Permission.Edit.Field.AccessType.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    isEditMode () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (this.editMode === null) this.editMode = !!this.permission.Id

      return this.editMode
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.editMode = null
        if (this.$refs.permissionForm) this.$refs.permissionForm.resetValidation()
      }
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.permissionForm.validate()
      const payload = JSON.parse(JSON.stringify(this.permission))

      if (this.permissionFormValid) {
        if (!payload.Requires.Parent) payload.Requires.Parent = ''
        if (!payload.Requires.Permissions || (Array.isArray(payload.Requires.Permissions) && payload.Requires.Permissions.length <= 0)) payload.Requires.Permissions = ''
        this.$emit('permission-save', payload)
      }
    },
    onCancelButtonClick () {
      if (this.$refs.permissionForm) this.$refs.permissionForm.resetValidation()
      this.isVisible = false
    }
  }
}
</script>
