<template>
  <v-container
    class="pa-1"
    fluid
  >
    <v-row dense>
      <v-col cols="12">
        <v-container
          class="pa-0"
          fluid
        >
          <v-row dense>
            <!-- PAYMENT HEADER -->
            <v-col cols="12">
              <div class="pl-2 pr-1 mb-1 grey--text text--darken-3 text-subtitle-1 font-weight-bold green lighten-4">
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row
                    dense
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="8"
                      class="py-1"
                    >
                      <div>
                        {{ $tc('Restaurant.Tables.Dialog.Payment.Title', 1) }} -&nbsp;<span class="grey--text text--darken-1">{{ viewData.Time }}</span>
                      </div>
                    </v-col>

                    <v-col
                      cols="4"
                      class="text-right"
                    >
                      <div class="d-inline-block">
                        <template v-for="(transaction, transIndex) in viewData.Transactions">
                          <v-chip
                            :key="`payment-${viewData.Timestamp}-transaction-${transIndex}`"
                            label
                            color="blue-grey lighten-1"
                            text-color="white"
                            class="ml-1"
                          >
                            <v-icon left>
                              {{ getPaymentTypeByCode(transaction.PaymentType).icon }}
                            </v-icon>
                            {{ transaction.Amount | currency }}
                          </v-chip>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>

            <!-- PAYMENT ITEMS -->
            <v-col cols="12">
              <v-container
                class="pa-0"
                fluid
              >
                <v-row dense>
                  <v-col cols="12">
                    <template v-for="tableSeat in getPaymentItems(viewData)">
                      <v-subheader
                        :key="`payment-${viewData.Timestamp}-table-seat-${tableSeat.Seat}`"
                        class="font-weight-bold pl-1 green lighten-5"
                        style="height: 26px;"
                      >
                        {{ tableSeat.Name }}
                      </v-subheader>
                      <template v-for="category in tableSeat.Items">
                        <v-subheader
                          :key="`payment-${viewData.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}`"
                          class="grey--text text--darken-2 text-subtitle-1 pl-0"
                          style="height: 36px;"
                        >
                          {{ getTranslatedField(getCategory(category.CategoryId), 'Name') }}
                        </v-subheader>

                        <template v-for="(menu) in category.Items">
                          <v-list
                            :key="`payment-${viewData.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-item-${menu.RowId}-hash-${menu.HashId}`"
                            class="mb-1 pb-0 pt-0"
                            dense
                            light
                            three-line
                          >
                            <v-list-item
                              class="grey lighten-4"
                              ripple
                            >
                              <v-list-item-content>
                                <span
                                  v-if="getCategoryColor(category.CategoryId)"
                                  :style="'background-color: #' + getCategoryColor(category.CategoryId) + ';'"
                                  style="position: absolute; width:5px; height: 100%; margin-left: -16px; padding: 0; margin-right: 16px;"
                                />
                                <v-list-item-title class="text-subtitle-1">
                                  <strong class="orange--text">
                                    {{ menu.PaymentQuantity }} x
                                  </strong>
                                  {{ getTranslatedField(menu, 'Name') }}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                  <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                    <span class="old-price">
                                      {{ menu.MenuPrice | currency }}
                                    </span> {{ menu.SpecialPriceFmt | currency }}
                                  </template>
                                  <template v-else>
                                    <span
                                      v-if="menuItemHasCoupon(menu)"
                                      class="old-price"
                                    >
                                      {{ parseFloat(menu.MenuPrice) | currency }}
                                    </span>
                                    {{ menuItemHasCoupon(menu) ? parseFloat(menu.PriceFmt) - (parseFloat(menu.Coupon.Amount) / parseInt(menu.PaymentQuantity)) : menu.PriceFmt | currency }}
                                  </template>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle class="pb-1 text-caption">
                                  {{ getMenuSelectedOptionsString(menu) }}&nbsp;
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-list-item-action-text class="text-subtitle-1">
                                  <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                    <span class="old-price">
                                      {{ parseFloat(parseFloat(menu.MenuPrice) * parseInt(menu.PaymentQuantity)) | currency }}
                                    </span>
                                    {{ parseFloat(parseFloat(menu.SpecialPrice) * parseInt(menu.PaymentQuantity)) | currency }}
                                  </template>
                                  <template v-else>
                                    <span
                                      v-if="menuItemHasCoupon(menu)"
                                      class="old-price"
                                    >
                                      {{ parseFloat(parseFloat(menu.MenuPrice) * parseInt(menu.PaymentQuantity)) | currency }}
                                    </span>
                                    {{ menuItemPriceTotal(menu, menuItemHasCoupon(menu)) | currency }}
                                  </template>
                                </v-list-item-action-text>

                                <v-list-item-action-text>
                                  <v-btn
                                    v-if="menu.Custom && parseInt(menu.Custom.Order)"
                                    class="d-inline-block ml-1 px-1"
                                    color="pink lighten-1"
                                    style="min-width: 0px;"
                                    text
                                    small
                                    ripple
                                  >
                                    <v-icon>
                                      low_priority
                                    </v-icon>

                                    <span style="position: relative; top: -4px;">{{ menu.Custom.Order }}</span>
                                  </v-btn>

                                  <v-btn
                                    v-if="menu.Custom && parseInt(menu.Custom.Seat)"
                                    class="d-inline-block ml-1 px-1"
                                    color="brown lighten-1"
                                    style="min-width: 0px;"
                                    text
                                    small
                                  >
                                    <v-icon>
                                      event_seat
                                    </v-icon>

                                    <span style="position: relative; top: -4px;">{{ menu.Custom.Seat }}</span>
                                  </v-btn>

                                  <v-btn
                                    v-if="menuItemHasCoupon(menu)"
                                    class="d-inline-block ml-1 px-1"
                                    color="purple"
                                    style="min-width: 0px;"
                                    text
                                    small
                                  >
                                    <v-icon>
                                      redeem
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action-text>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider />
                          </v-list>
                        </template>
                      </template>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth         from '@/mixins/auth'
import AppData      from '@/mixins/appdata'
import PaymentData  from '@/mixins/tables/paymentData'
import Translatable from '@/mixins/translatable'
import PosCatalog   from '@/mixins/pos/posCatalog'
import PaymentType  from '@/mixins/orders/paymentType'
import TableCommon  from '@/mixins/tables/tableCommon'

export default {
  name      : 'PaymentItemsList',
  components: {},
  directives: {},
  mixins    : [Auth, AppData, Translatable, TableCommon, PaymentData, PosCatalog, PaymentType],
  props     : {
    viewData: {
      type   : Object,
      default: undefined
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
