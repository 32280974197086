<template>
  <v-container
    class="pa-1"
    fluid
  >
    <v-row
      no-gutters
      class="pr-1"
    >
      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <v-btn
          :style="btnStyle"
          color="grey"
          depressed
          dark
          @click="onClearClick('c')"
        >
          C
        </v-btn>

        <v-btn
          :style="divStyle"
          :ripple="false"
          color="grey"
          style="text-transform: unset;"
          outlined
          depressed
          dark
        >
          <h2 :style="labelStyle">
            {{ formatCurrency(vModel) }}
          </h2>
        </v-btn>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <template v-for="num in 3">
          <v-btn
            :key="`key-${num + 6}`"
            :style="btnStyle"
            color="grey"
            depressed
            dark
            @click="onNumKeyClick(num + 6)"
          >
            {{ num + 6 }}
          </v-btn>
        </template>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <template v-for="num in 3">
          <v-btn
            :key="`key-${num + 3}`"
            :style="btnStyle"
            color="grey"
            depressed
            dark
            @click="onNumKeyClick(num + 3)"
          >
            {{ num + 3 }}
          </v-btn>
        </template>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <template v-for="num in 3">
          <v-btn
            :key="`key-${num}`"
            :style="btnStyle"
            color="grey"
            depressed
            dark
            @click="onNumKeyClick(num)"
          >
            {{ num }}
          </v-btn>
        </template>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <v-btn
          :style="btnStyle"
          color="grey"
          depressed
          dark
          @click="onNumKeyClick('00')"
        >
          00
        </v-btn>

        <v-btn
          :style="btnStyle"
          color="grey"
          depressed
          dark
          @click="onNumKeyClick(0)"
        >
          0
        </v-btn>

        <v-btn
          :style="btnStyle"
          color="blue"
          depressed
          dark
          @click="onDelClick"
        >
          <v-icon>backspace</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vModel                                        from '@/mixins/vModel'
import AppData                                       from '@/mixins/appdata'
import formatCurrency, { formatDecimal, formatUnit } from '@/lib/currency/currency'

export default {
  name      : 'NumPad',
  components: {},
  directives: {},
  mixins    : [AppData, vModel],
  props     : {
    disabled: {
      type   : Boolean,
      default: false
    },
    initialValue: {
      type   : [Number, String],
      default: 0
    },
    useDecimals: {
      type   : Boolean,
      default: true
    },
    maxNumValue: {
      type   : [Number, String],
      default: undefined
    },
    maxNumLength: {
      type   : [Number, String],
      default: 9
    },
    align: {
      type   : String,
      default: 'center'
    },
    small: {
      type   : Boolean,
      default: false
    },
    currency: {
      type   : Boolean,
      default: false
    },
    number: {
      type   : Boolean,
      default: false
    },
    unit: {
      type   : String,
      default: undefined
    },
    suffix: {
      type   : String,
      default: ''
    },
    fullWidth: {
      type   : Boolean,
      default: true
    },
    buttonHeight: {
      type   : Number,
      default: 55
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {
      data    : [],
      decimals: 2
    }
  },
  computed: {
    btnStyle () {
      const fullWidth = {
        'min-width' : '32%',
        'min-height': this.small ? '40px' : '55px',
        'max-height': this.small ? '40px' : '80px',
        height      : `${ this.buttonHeight }px`,
        margin      : '3px 3px'
      }
      const smallStyle = {
        'min-width' : this.$vuetify.breakpoint.xsOnly ? '55px' : '55px',
        'min-height': '40px',
        margin      : '3px 3px'
      }
      const normalStyle = {
        'min-width' : this.$vuetify.breakpoint.xsOnly ? '75px' : '88px',
        'min-height': '55px',
        margin      : '3px 3px'
      }

      if (this.fullWidth) return fullWidth
      if (this.small) return smallStyle

      return normalStyle
    },

    divStyle () {
      const fullWidth = {
        'min-width' : '66%',
        'min-height': this.small ? '40px' : '55px',
        'max-height': this.small ? '40px' : '80px',
        height      : `${ this.buttonHeight }px`,
        margin      : '3px 3px',
        padding     : this.small ? '0 8px' : '0 16px'
      }
      const smallStyle = {
        'min-width' : this.$vuetify.breakpoint.xsOnly ? '114px' : '116px',
        'min-height': '40px',
        margin      : '3px 3px',
        padding     : '0 8px'
      }
      const normalStyle = {
        'min-width' : this.$vuetify.breakpoint.xsOnly ? '156px' : '182px',
        'min-height': '55px',
        margin      : '3px 3px',
        padding     : '0 16px'
      }

      if (this.fullWidth) return fullWidth
      if (this.small) return smallStyle

      return normalStyle
    },

    labelStyle () {
      const smallStyle = { 'font-size': '14px' }
      const normalStyle = { 'font-size': '20px' }

      if (this.small) return smallStyle

      return normalStyle
    }
  },
  watch: {
    initialValue: {
      immediate: true,
      handler (newVal) {
        if (newVal > this.maxNumValue) newVal = this.maxNumValue
        const items = this.convertNumToArray(newVal)
        this.$nextTick(() => {
          this.data = items
        })
      }
    },
    value: {
      immediate: false,
      handler (newVal) {
        if (newVal > this.maxNumValue) newVal = this.maxNumValue
        const items = this.convertNumToArray(newVal)
        this.$nextTick(() => {
          this.data = items
        })
      }
    },
    maxNumValue () {
      if (this.vModel > this.maxNumValue) {
        this.vModel = this.maxNumValue
        const items = this.convertNumToArray(this.vModel)
        this.$nextTick(() => {
          this.data = items
        })
      }
    },
    data (newVal) {
      const val = this.convertArrayToNum(newVal)
      this.vModel = parseFloat(parseFloat(val).toFixed(2))
    }
  },
  beforeCreate () {
  },
  created () {
    window && window.addEventListener('keyup', this.onKeyUp)
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    window && window.removeEventListener('keyup', this.onKeyUp)
  },
  destroyed () {
  },
  methods: {
    onNumKeyClick (val) {
      if (this.disabled) return

      if (/[0-9]/.test(val)) {
        const data = String(val).split('')
        if ((!this.data.length && String(val).startsWith('0')) || (this.data.length + data.length) > parseInt(this.maxNumLength)) return
        data.forEach(num => this.data.push(parseInt(num)))

        if (this.maxNumValue !== undefined && this.maxNumValue !== null && this.maxNumValue !== -1) {
          const newVal = this.convertArrayToNum(this.data)
          const maxVal = parseFloat(parseFloat(this.maxNumValue).toFixed(2))
          if (maxVal >= 0 && newVal > maxVal) {
            this.data = this.convertNumToArray(maxVal)
          }
        }
      }
    },

    onDelClick () {
      if (this.disabled) return

      this.data.pop()
    },

    onClearClick () {
      if (this.disabled) return

      this.data = []
    },

    onOkKeyClick () {
      if (this.disabled) return

      this.$emit('numpad:enter')
    },

    convertArrayToNum (val) {
      return parseFloat(((parseInt(val.join('')) || 0) / (this.useDecimals ? 100 : 1)).toFixed(2))
    },

    convertNumToArray (val) {
      let items = []

      if (val && parseFloat(val) > 0) {
        const newVal = this.useDecimals ? parseFloat(parseFloat(val).toFixed(2)).toFixed(2) : parseInt(val).toFixed(0)
        items = newVal.split('').filter(item => item !== '.').map(item => parseInt(item))
      }

      return items
    },

    formatCurrency (val) {
      const locale = this.appConfig.LOCATION_DATA.Currency.locale || 'el-GR'
      const currency = this.appConfig.LOCATION_DATA.Currency.name || 'EUR'
      const decimals = this.useDecimals ? 2 : 0
      const unit = this.unit || ''
      const suffix = this.suffix || ''
      const value = val || 0

      if (this.currency) {
        return formatCurrency(locale, currency, value, decimals)
      } else if (this.unit) {
        return formatUnit(locale, value, unit)
      } else if (this.number) {
        return formatDecimal(locale, value, decimals, suffix)
      }

      return value
    },

    onKeyUp (keyCode) {
      if (this.disabled) return

      switch (keyCode.key) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '0':
          this.onNumKeyClick(keyCode.key)
          break
        case 'Backspace':
        case 'Delete':
          this.onDelClick()
          break
        case 'Enter':
          this.onOkKeyClick()
          break
        case 'C':
        case 'c':
          this.onClearClick()
          break
      }
    }
  }
}
</script>

<style scoped>

</style>
