export default {
  MenuOptionsDialog: {
    Field: {
      Comment: {
        Label: 'Σχόλια',
        Hint : 'πχ. Χωρίς Κρεμμύδι, Καλοψημένο, κλπ.'
      },
      Checkbox: {
        Error: {
          Message: 'Παρακαλώ κάντε',
          Choice : 'επιλογές | επιλογή | επιλογές',
          Min    : '@:Pos.MenuOptionsDialog.Field.Checkbox.Error.Message τουλάχιστον ',
          Max    : '@:Pos.MenuOptionsDialog.Field.Checkbox.Error.Message μέχρι ',
          From   : '@:Pos.MenuOptionsDialog.Field.Checkbox.Error.Message από ',
          To     : ' έως '
        }
      }
    },
    SelectedOptions: 'Έχεις επιλέξει'
  },

  Pos: {
    Toolbar: {
      Title: {
        New : '@:Order.OrdersAll.Toolbar.Button.Title',
        Edit: '@:Order.OrdersAll.ListView.Button.Edit.Title'
      },
      Customer: {
        Select: {
          Title: 'Επιλογή Πελάτη',
          Icon : 'account_circle'
        },
        Search: {
          Title: 'Αναζήτηση Πελάτη',
          Icon : 'search'
        },
        Add: {
          Title: 'Νέος Πελάτης',
          Icon : 'person_add'
        },
        Selected: {
          Floor       : '@:Customer.CustomerAddEditAddressDialog.Field.Floor.Label:',
          DoorBell    : '@:Customer.CustomerAddEditAddressDialog.Field.DoorBell.Label:',
          Instructions: '@:Customer.CustomerAddEditAddressDialog.Field.Instructions.Label:'
        }
      },
      Source: 'Άλλη πηγή'
    },
    Cart: {
      Title : 'Καλάθι Αγορών',
      Icon  : 'shopping_cart',
      NoData: {
        Title   : 'Άδειο Καλάθι',
        Subtitle: 'Προσθέστε προϊόντα'
      },
      Error: {
        NoStock : 'Δεν υπάρχει διαθέσιμο απόθεμα για το προϊόν',
        MinStock: {
          Title     : 'Το απόθεμα για το προϊόν',
          Message   : 'είναι μικρότερο από την ελάχιστη ποσότητα (',
          MessageEnd: ') παραγγελίας.'
        }
      }
    },
    ListView: {
      Categories: {
        All   : 'Όλα',
        NoData: {
          Title   : 'Άδεια Κατηγορία',
          Subtitle: 'Δεν υπάρχουν διαθέσιμα προϊόντα',
          Icon    : 'shopping_cart'
        }
      },
      Field: {
        Search: {
          Label: 'Αναζήτηση Προϊόντος',
          Icon : 'search'
        }
      }
    },
    Footer: {
      Time: {
        Delivery  : 'Χρόνος Παράδοσης',
        Collection: 'Χρόνος Παραλαβής'
      },
      Button: {
        Comment: {
          Title: '@:Pos.MenuOptionsDialog.Field.Comment.Label',
          Field: {
            Label: 'Σχόλια Πραγγελίας',
            Hint : 'πχ. Κάντε Αναπάντη, κλπ.'
          }
        },
        Payment : 'Πληρωμή',
        AddOrder: 'Καταχώρηση'
      }
    },
    Dialog: {
      Save: {
        Title       : 'Αποθήκευση Παραγγελίας;',
        Content     : 'Θέλετε να αποθηκεύσετε την παραγγελία;',
        Notification: 'Διαφορετικά η παραγγελία θα ακυρωθεί οριστικά!'
      },
      SaveRefund: {
        Title       : 'Αποθήκευση Παραγγελίας;',
        Content     : '<strong>Η παραγγελία έχει συναλλαγές με κάρτα!</strong><br>Θέλετε να αποθηκεύσετε την παραγγελία;',
        Notification: 'Διαφορετικά η παραγγελία και οι συναλλαγές κάρτας θα ακυρωθούν οριστικά και θα γίνει επιστροφή χρημάτων!'
      },
      Cancel: {
        Title       : 'Ακύρωση Παραγγελίας;',
        Content     : 'Θέλετε να ακυρώσετε οριστικά την παραγγελία;',
        Notification: 'Η παραγγελία θα ακυρωθεί οριστικά!'
      },
      CancelRefund: {
        Title       : 'Ακύρωση Παραγγελίας;',
        Content     : '<strong>Η παραγγελία έχει συναλλαγές με κάρτα οι οποίες θα ακυρωθούν και θα γίνει επιστροφή χρημάτων.</strong><br><br>Θέλετε να ακυρώσετε οριστικά την παραγγελία και τις συναλλαγές κάρτας;',
        Notification: 'Η παραγγελία και οι συναλλαγές κάρτας θα ακυρωθούν οριστικά και θα γίνει επιστροφή χρημάτων!'
      }
    },
    Notification: {
      Error                              : 'Παρουσιάστηκε σφάλμα. Παρακαλώ δοκιμάστε ξανά!',
      Complete                           : 'Η Παραγγελία Ολοκληρώθηκε!',
      Cancel                             : 'Η Παραγγελία Δεν Ακυρώθηκε!',
      NoClient                           : 'Παρακαλώ Επιλέξτε Πελάτη και Διεύθυνση!',
      NoProduct                          : 'Παρακαλώ Προσθέστε Προϊόντα στο Καλάθι!',
      CouponOverOrderTotal               : 'Η έκπτωση δεν προστέθηκε. Το σύνολο των εκπτώσεων υπερβαίνει το σύνολο της παραγγελίας.',
      CouponOverMenuItemTotal            : 'Η έκπτωση δεν προστέθηκε. Η έκπτωση υπερβαίνει την αξία του προϊόντος.',
      FullDiscountWithPaymentTransactions: 'Η 100% έκπτωση δεν προστέθηκε. Πρέπει πρώτα να αφαιρέσετε όλες τις συναλλαγές πληρωμών.'
    }
  },

  SelectCouponDialog: {
    Toolbar: {
      Subtitle: 'Επιλογή Έκπτωσης'
    },
    Field: {
      DiscountPercent: {
        Label: 'Ποσοστό Έκπτωσης',
        Hint : 'Ποσοστιαία έκπτωση. πχ. 10, 5.3'
      },
      DiscountAmount: {
        Label: 'Ποσό Έκπτωσης',
        Hint : 'Ποσό Έκπτωσης. πχ. 10, 5.3'
      }
    }
  },

  SelectTipAmount: {
    Toolbar: {
      Title   : 'Ποσό Tip',
      Subtitle: 'Επιλογή Ποσού Tip',
      Icon    : 'redeem'
    },
    Field: {
      TipAmount: {
        Label: 'Ποσό Tip',
        Hint : 'Ποσό Tip. πχ. 0.5, 1, 2, 5, 10'
      }
    },
    Amounts: [{ Amount: 0.25 }, { Amount: 0.5 }, { Amount: 1.0 }, { Amount: 1.5 }, { Amount: 2.0 }, { Amount: 2.5 }, { Amount: 3.0 }, { Amount: 3.5 }, { Amount: 4.0 }, { Amount: 4.5 }, { Amount: 5.0 }, { Amount: 10.0 }]
  },

  SelectExtraCharge: {
    Toolbar: {
      Title   : 'Επιπλέον Χρέωση',
      Subtitle: 'Επιλογή Επιπλέον Χρέωσης',
      Icon    : 'mdi-credit-card-plus'
    }
  },

  OrderPaymentDialog: {
    Toolbar: {
      Title   : 'Πληρωμή Παραγγελίας',
      Subtitle: 'Προσθήκη Συναλλαγών',
      Icon    : 'mdi-account-credit-card'
    },
    Notification: {
      Refund: {
        Success: 'Η επιστροφή χρημάτων της συναλλαγής έγινε με επιτυχία.',
        Error  : 'Η επιστροφή χρημάτων της συναλλαγής απέτυχε! Δοκιμάστε ξανά.'
      },
      Sale: {
        Success: 'Η συναλλαγή στο επιλεγμένο τερματικό πληρωμών EFT/POS ολοκληρώθηκε επιτυχώς.',
        Error  : 'Η συναλλαγή στο επιλεγμένο τερματικό πληρωμών EFT/POS απέτυχε! Δοκιμάστε ξανά.'
      }
    }
  },

  SelectOrderSourceTypeDialog: {
    Toolbar: {
      Title   : 'Πηγή Παραγγελίας',
      Subtitle: 'Επιλογή Πηγής Παραγγελίας'
    }
  },

  SelectPlatformCatalogDialog: {
    Button: {
      ChangeCatalog: 'Αλλαγή Καταλόγου'
    },
    Toolbar: {
      Title   : 'Κατάλογος Προϊόντων',
      Subtitle: 'Επιλογή Καταλόγου Προϊόντων'
    }
  },

  SelectOrderTypeTimeDialog: {
    Toolbar: {
      Title: {
        Delivery  : '@:Pos.Pos.Footer.Time.Delivery Παραγγελίας',
        Collection: '@:Pos.Pos.Footer.Time.Collection Παραγγελίας'
      },
      Subtitle: {
        Delivery  : 'Επιλογή Χρόνου Παράδοσης Παραγγελίας',
        Collection: 'Επιλογή Χρόνου Παραλαβής Παραγγελίας'
      }
    },
    SetAsDefault: {
      Delivery  : 'Ορισμός ως προεπιλογή χρόνου διανομής παραγγελίας',
      Collection: 'Ορισμός ως προεπιλογή χρόνου παραλαβής παραγγελίας'
    }
  }
}
