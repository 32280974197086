<template>
  <v-toolbar
    color="grey darken-3"
    dark
    dense
    flat
    max-height="48"
  >
    <div class="d-inline-flex align-center mr-2">
      <v-toolbar-title class="white--text text-body-1 mr-4">
        {{ $tc('Restaurant.Tables.Seat.Select', 1) }}
      </v-toolbar-title>

      <v-btn
        class="pa-2 ma-1"
        color="grey darken-4"
        style="min-width: 0px;"
        @click="onRemoveTableSeat"
      >
        <v-icon>remove</v-icon>
      </v-btn>
      <v-btn
        class="pa-2 ma-1"
        color="grey darken-4"
        style="min-width: 0px;"
        @click="onAddTableSeat"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>

    <v-tabs
      :show-arrows="true"
      :style="$vuetify.breakpoint.xsOnly ? 'max-width: calc(100% - 96px)' : 'max-width: calc(100% - 228px)'"
      class="d-inline"
      background-color="transparent"
      dark
      height="48"
      hide-slider
      style="height: 48px; width: auto; min-width: 150px;"
    >
      <v-btn-toggle
        v-model="vModel"
        dark
        mandatory
      >
        <v-btn
          active-class="primary"
          class="px-2 white--text"
          dark
          text
          style="opacity: 1; height: 48px;"
          value="0"
        >
          {{ $tc('Restaurant.Tables.Title', 1) }}
        </v-btn>

        <template v-for="seat in tablePersons">
          <v-btn
            :key="`seat-${seat}`"
            :value="String(seat)"
            active-class="primary"
            class="px-4 white--text"
            dark
            text
            style="opacity: 1; height: 48px;"
          >
            {{ seat }}
          </v-btn>
        </template>
      </v-btn-toggle>
    </v-tabs>
  </v-toolbar>
</template>

<script>
import vModel from '@/mixins/vModel'

export default {
  name      : 'SelectTableSeat',
  components: {},
  directives: {},
  mixins    : [vModel],
  props     : {
    persons: {
      type   : Number,
      default: 1
    },
    minCapacity: {
      type   : Number,
      default: 1
    },
    maxCapacity: {
      type   : Number,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    tablePersons: {
      get () {
        return this.persons || ''
      },
      set (val) {
        this.$emit('update:persons', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onAddTableSeat () {
      if (this.maxCapacity && this.tablePersons >= this.maxCapacity) return
      this.tablePersons++
      this.dispatchResizeEvent()
    },

    onRemoveTableSeat () {
      if (this.minCapacity && this.tablePersons <= this.minCapacity) return
      this.tablePersons--
      this.dispatchResizeEvent()
    },

    dispatchResizeEvent () {
      if (typeof window === 'undefined') return
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    }
  }
}
</script>

<style scoped>

</style>
