import BaseCollection   from '../../../lib/data/Collections/BaseCollection'
import MessageTypeModel from '../../Models/messages/MessageTypeModel'

export default class MessageTypeCollection extends BaseCollection {
  constructor (items = []) {
    super(items, MessageTypeModel)
  }

  /* PROPERTIES */

  /* METHODS */
  groupedTypesForSelect () {
    let tmpGroup = ''
    const gt = []
    const sorted = this.sort((a, b) => a.GroupId - b.GroupId).all()

    sorted.forEach((type, index) => {
      if (type.GroupId !== tmpGroup) {
        tmpGroup = type.GroupId
        if (index > 0) gt.push({ divider: true })
        gt.push({ header: type.GroupName })
      }
      gt.push(type)
    })

    return gt
  }
}
