import ReceiptTypeCodeEnum from '@/api/Enums/ReceiptTypeCodeEnum'

export default {
  Title: 'Στατιστικά',

  Product: {
    Title: 'Στατιστικά Προϊόντων',
    Table: {
      Headers: {
        Id          : '#',
        CategoryName: 'Κατηγορία',
        Name        : 'Προϊόν',
        Quantity    : 'Παραγγελίες',
        UnitQuantity: 'Ποσότητα',
        Sales       : 'Πωλήσεις'
      }
    },

    Export: {
      Title         : 'Εξαγωγή',
      Icon          : 'file_download',
      Color         : 'primary',
      SuccessMessage: 'Σύντομα θα λάβετε αρχείο με την εξαγωγή των στατιστικών προϊόντων στο email: {email}'
    }
  },

  SalesReportZ: {
    Title: 'Αναφορά Πωλήσεων Ζ',
    Table: {
      Headers: {
        Order      : 'Παραγγελία',
        Receipt    : 'Παραστατικό',
        ReceiptType: 'Τύπος Παραστατικού',
        Total      : 'Σύνολο',
        Date       : 'Ημερομηνία'
      }
    },

    Export: {
      Title         : 'Εξαγωγή',
      Icon          : 'file_download',
      Color         : 'primary',
      SuccessMessage: 'Σύντομα θα λάβετε την Αναφορά Πωλήσεων Ζ στο email: {email}'
    },

    Filter: {
      filterOrderType: {
        Type       : 'v-btn-toggle',
        Label      : 'Παραγγελίες',
        PlaceHolder: 'Όλες οι παραγγελίες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Με Παραστατικό',
            Icon : 'mdi-receipt-text',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: 2,
            Name : 'Χωρίς Παραστατικό',
            Icon : 'mdi-receipt-text-remove',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },

      filterInvoiceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Τύπος Παραστατικού',
        PlaceHolder: 'Όλοι οι τύποι παραστατικού',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : [
          {
            Id    : ReceiptTypeCodeEnum.GREEK_INVOICE,
            Name  : 'Τιμολόγιο Πώλησης',
            Avatar: {
              Color: 'blue',
              Icon : 'mdi-receipt-text'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_1,
            Name  : 'Πιστωτικό Τιμολόγιο / Συσχετιζόμενο',
            Avatar: {
              Color: 'red',
              Icon : 'mdi-receipt-text-remove'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_2,
            Name  : 'Πιστωτικό Τιμολόγιο / Μη Συσχετιζόμενο',
            Avatar: {
              Color: 'red',
              Icon : 'mdi-receipt-text-remove'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_RECEIPT,
            Name  : 'Απόδειξη Λιανικής Πώλησης',
            Avatar: {
              Color: 'green',
              Icon : 'mdi-receipt-text'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_RECEIPT_CREDIT,
            Name  : 'Πιστωτικό Στοιχ. Λιανικής',
            Avatar: {
              Color: 'red',
              Icon : 'mdi-receipt-text-remove'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER,
            Name  : 'Δελτίο Παραγγελίας Εστίασης',
            Avatar: {
              Color: 'blue-grey',
              Icon : 'mdi-receipt-text'
            }
          }
        ]
      }
    }
  },

  OrdersHeatmap: {
    Title : 'Heatmap Παραγγελιών',
    Orders: 'Δεδομένα από {orders} παραγγελίες'
  }
}
