<template>
  <div
    :class="[!!receipt.CanceledAt ? 'red--text' : '']"
    class="divTableRow text-caption"
  >
    <div
      class="divTableCell text-left text-no-wrap"
      :class="[receiptTypeColor(receipt, true)]"
    >
      {{ receipt.InvoiceTypeCode }} {{ receipt.InvoiceTypeName }}
    </div>

    <div
      v-if="!!orders.length"
      class="divTableCell text-center text-no-wrap"
    >
      <div>
        {{ tableReceiptOrderNumber(receipt, orders) }}
      </div>
    </div>

    <div class="divTableCell text-right text-no-wrap">
      <div class="text-no-wrap">
        <v-chip
          label
          dark
          x-small
          style="min-width: 90px;"
          class="align-center justify-center font-weight-bold px-1"
          :color="receiptTypeColor(receipt)"
        >
          {{ receipt.InternalId }}
        </v-chip>

        <v-btn
          v-if="receipt.DocumentUrl"
          icon
          x-small
          :color="receiptTypeColor(receipt)"
          target="_blank"
          :href="receipt.DocumentUrl"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="divTableCell text-right text-no-wrap">
      <v-tooltip
        :disabled="!receipt.PaymentMethods || (receipt.PaymentMethods && !receipt.PaymentMethods.length)"
        bottom
        :color="isDarkMode ? '#191919' : 'grey lighten-5'"
      >
        <template #activator="{on}">
          <div v-on="on">
            <v-badge
              color="transparent"
            >
              {{ receipt.Amount | currency }}

              <template
                v-if="receipt.PaymentMethods && receipt.PaymentMethods.length"
                #badge
              >
                <v-icon
                  color="info"
                  x-small
                >
                  mdi-information-variant-circle-outline
                </v-icon>
              </template>
            </v-badge>
          </div>
        </template>

        <v-card
          flat
          color="transparent"
        >
          <v-card-title class="justify-center font-weight-bold text-body-1">
            {{ $tc('Restaurant.Tables.Dialog.Payment.Title', 0) }}
          </v-card-title>

          <v-list
            flat
            dense
            color="transparent"
          >
            <v-list-item
              v-for="(payment, paymentIdx) in receipt.PaymentMethods"
              :key="paymentIdx"
              class="text-left"
            >
              <v-list-item-avatar :color="getPaymentTypeLangByCode(payment.Code).Color">
                <v-icon
                  dark
                  v-text="getPaymentTypeLangByCode(payment.Code).Icon"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ getPaymentTypeLangByCode(payment.Code).Title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ payment.Amount | currency }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tooltip>
    </div>

    <div
      v-if="appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD"
      class="divTableCell text-center text-no-wrap"
    >
      <template v-if="receipt.Mark">
        <v-tooltip
          bottom
          :color="isDarkMode ? '#191919' : 'grey lighten-5'"
        >
          <template #activator="{on}">
            <div v-on="on">
              <v-badge
                color="transparent"
                offset-x="6"
                offset-y="16"
                overlap
              >
                <my-data-logo
                  width="45"
                  img-class="mt-3"
                />

                <template #badge>
                  <v-icon
                    color="info"
                    x-small
                  >
                    mdi-information-variant-circle-outline
                  </v-icon>
                </template>
              </v-badge>
            </div>
          </template>

          <div
            class="text-caption pa-2 text-center"
            :class="isDarkMode ? 'white--text' : 'black--text'"
          >
            <my-data-logo
              width="125"
              img-class="mb-2"
            />

            <div class="font-weight-medium">
              {{ $t('Order.OrderDetailsDialog.Receipt.Submitted') }}
            </div>

            <div class="my-4" />

            <div class="text-left">
              <div>{{ $t('Order.OrderDetailsDialog.Receipt.Mark') }}</div>
              <div class="grey--text">
                {{ receipt.Mark }}
              </div>

              <div class="my-2" />

              <div>{{ $t('Order.OrderDetailsDialog.Receipt.Uid') }}</div>
              <div class="grey--text">
                {{ receipt.Uid }}
              </div>

              <div class="my-2" />

              <div>{{ $t('Order.OrderDetailsDialog.Receipt.AuthCode') }}</div>
              <div class="grey--text">
                {{ receipt.AuthenticationCode }}
              </div>

              <div class="my-2" />

              <div>{{ $t('Order.OrderDetailsDialog.Receipt.Provider') }}</div>
              <div class="grey--text">
                {{ receipt.Provider }}
              </div>
            </div>
          </div>
        </v-tooltip>
      </template>
    </div>

    <div class="divTableCell text-center text-no-wrap">
      <v-chip
        label
        dark
        x-small
        style="min-width: 100px;"
        class="align-center justify-center font-weight-bold"
        :color="receiptStatus(receipt).Color"
      >
        {{ receiptStatus(receipt).Name.toLocaleUpperCase($i18n.locale) }}
      </v-chip>
    </div>

    <div class="divTableCell text-right text-no-wrap">
      <template v-for="(item, itemIdx) in receipt.relatedItems">
        <div
          :key="`receipt-related-item-${itemIdx}`"
          class="text-no-wrap"
        >
          <v-chip
            label
            dark
            x-small
            style="min-width: 90px;"
            class="align-center justify-center font-weight-bold px-1"
            :color="receiptTypeColor(item)"
          >
            {{ item.InternalId }}
          </v-chip>

          <v-btn
            v-if="item.DocumentUrl"
            icon
            x-small
            :color="receiptTypeColor(item)"
            target="_blank"
            :href="item.DocumentUrl"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </template>
    </div>

    <div class="divTableCell text-right text-no-wrap">
      {{ receipt.CreatedAt }}
    </div>

    <div class="divTableCell text-right">
      <v-speed-dial
        v-model="receipt.fab"
        direction="left"
        transition="slide-x-reverse-transition"
      >
        <template #activator>
          <v-btn
            icon
            small
          >
            <v-icon>mdi mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <print-receipt-button
          v-if="userCanPrintReceipt && print"
          class="ml-2"
          :small-size="28"
          hide-label
          icon
          small
          small-icon
          @fiscal-document:print="$bus.$emit('fiscal-document:print', receipt.Id)"
        />

        <email-receipt-button
          v-if="userCanViewReceipt && email"
          class="ml-2"
          :small-size="28"
          hide-label
          icon
          small
          small-icon
          @fiscal-document:email="$bus.$emit('fiscal-document:email', receipt.Id)"
        />

        <template v-if="userCanViewReceipt && appHasReceiptsProvider && receipt.DocumentUrl">
          <v-tooltip
            color="light-green"
            top
          >
            <template #activator="{on}">
              <v-btn
                class="light-green ma-0 ml-2"
                dark
                icon
                small
                v-on="on"
                @click="$bus.$emit('fiscal-document:view', receipt.DocumentUrl)"
              >
                <v-icon small>
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Common.Button.ViewDocument') }}</span>
          </v-tooltip>
        </template>

        <template v-if="canRetryReceipt(receipt)">
          <v-tooltip
            color="orange"
            top
          >
            <template #activator="{on}">
              <v-btn
                class="orange ma-0 ml-2"
                dark
                icon
                small
                v-on="on"
                @click="$bus.$emit('fiscal-document:retry', receipt.Id)"
              >
                <v-icon small>
                  mdi-cloud-upload-outline
                </v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Common.Button.RetryDocument') }}</span>
          </v-tooltip>
        </template>

        <template v-if="canCancelReceipt(receipt)">
          <v-tooltip
            :color="$t('Order.OrdersAll.ListView.Button.Delete.Color')"
            top
          >
            <template #activator="{on}">
              <v-btn
                :class="$t('Order.OrdersAll.ListView.Button.Delete.Color')"
                class="ma-0 ml-2"
                dark
                icon
                small
                v-on="on"
                @click="$bus.$emit('fiscal-document:cancel', {...receipt, ReceiptId: receipt.Id, TableId: table?.Id || null}, {refund: false})"
              >
                <v-icon small>
                  {{ $t('Order.OrdersAll.ListView.Button.Cancel.Icon') }}
                </v-icon>
              </v-btn>
            </template>

            <span>{{ $t('Order.OrderDetailsDialog.Receipt.Cancel') }}</span>
          </v-tooltip>
        </template>
      </v-speed-dial>
    </div>
  </div>
</template>

<script>

import MyDataLogo              from '@/components/receipts/MyDataLogo.vue'
import EmailReceiptButton      from '@/components/common/receipt/EmailReceiptButton.vue'
import PrintReceiptButton      from '@/components/common/receipt/PrintReceiptButton.vue'
import Auth                    from '@/mixins/auth'
import AppData                 from '@/mixins/appdata'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import ReceiptsCommon          from '@/mixins/receipts/receiptsCommon'
import PaymentType             from '@/mixins/orders/paymentType'

export default {
  name      : 'ReceiptsTableRow',
  components: {
    PrintReceiptButton,
    EmailReceiptButton,
    MyDataLogo
  },
  directives: {},
  mixins    : [Auth, AppData, PaymentType, ReceiptsCommon],
  props     : {
    receipt: {
      type   : Object,
      default: undefined
    },

    receiptIndex: {
      type   : Number,
      default: 0
    },

    print: {
      type   : Boolean,
      default: false
    },

    email: {
      type   : Boolean,
      default: false
    },

    cancel: {
      type   : Boolean,
      default: false
    },

    table: {
      type   : Object,
      default: undefined
    },

    orders: {
      type   : Array,
      default: () => []
    },

    noBorder: {
      type   : Boolean,
      default: false
    },

    noPadding: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ReceiptProviderTypeEnum () {
      return ReceiptProviderTypeEnum
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-speed-dial__list {
  padding : 0;
}

.divTableCell.no-border {
  border-bottom : 0 !important;
}

.divTableCell.no-padding {
  padding-top    : 2px !important;
  padding-bottom : 2px !important;
}
</style>
