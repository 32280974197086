/**
 * Enum for receipt types.
 * @readonly
 * @enum {String} ReceiptTypeEnum
 */

const ReceiptTypeEnum = {
  RECEIPT          : 'receipt',
  RECEIPT_CREDIT   : 'receipt_credit',
  INVOICE          : 'invoice',
  INVOICE_CREDIT   : 'invoice_credit',
  INVOICE_CREDIT_NO: 'invoice_credit_no',
  FNB              : 'fnb',
  FNB_CANCEL       : 'fnb_cancel',
  FNB_RETURN       : 'fnb_return',
  NONE             : 'none'
}

export default ReceiptTypeEnum
