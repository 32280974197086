/**
 * Enum for support priority.
 * @readonly
 * @enum {String} SupportPriorityEnum
 */

const SupportPriorityEnum = {
  LOW   : '1',
  MEDIUM: '2',
  HIGH  : '3'
}

export default SupportPriorityEnum
