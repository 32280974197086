export default {
  Menu: {
    Toolbar: {
      Button: {
        New: {
          Title: 'New Product',
          Icon : 'add_box',
          Color: 'primary'
        },
        Catalogs: {
          Title: 'Catalogs',
          Icon : '',
          Color: 'primary'
        },
        Categories: {
          Title: 'Categories',
          Icon : '',
          Color: 'primary'
        },
        Options: {
          Title: 'Product Options',
          Icon : '',
          Color: 'primary'
        },
        Tags: {
          Title: 'Tags',
          Icon : '',
          Color: 'primary'
        },
        Export: {
          Title         : 'Export',
          Subtitle      : 'Export products to Excel',
          Icon          : 'mdi-export',
          Color         : 'primary',
          SuccessMessage: 'Soon you\'ll receive a file with the exported products at your email: {email}'
        },
        Import: {
          Title         : 'Import',
          Subtitle      : 'Import products from',
          Icon          : 'mdi-import',
          Color         : 'primary',
          SuccessMessage: 'Products import completed successfully.'
        }
      },
      Field: {
        Select: {
          Label: 'Select Category',
          Icon : 'category'
        }
      }
    },
    Table: {
      Headers: {
        Image        : 'Image',
        Id           : '#',
        Name         : 'Name',
        Category     : 'Category',
        Catalog      : 'Catalog',
        Tags         : 'Tags',
        Tax          : 'VAT',
        Languages    : 'Languages',
        Price        : 'Price',
        SpecialStatus: 'Special',
        OfferActive  : 'Offer',
        Priority     : 'Priority',
        Stock        : 'Stock',
        Status       : 'Status',
        Visible      : 'Visible',
        Active       : 'Online',
        ActivePos    : 'Pos',
        Featured     : 'Featured',
        Actions      : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Delete Product?',
        Content     : 'Are you sure you want to delete the product?',
        Notification: 'The removal is permanent and the process is irreversible'
      }
    },
    Notification: {
      Delete: 'The product was not deleted. @:Common.Misc.Retry'
    },
    Filter: {
      filterCatalog: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Products Catalog',
        PlaceHolder: 'All product catalogs',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterCategory: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Products Category',
        PlaceHolder: 'All product categories',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterActive: {
        Type       : 'v-btn-toggle',
        Label      : 'Visible Online',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterActivePos: {
        Type       : 'v-btn-toggle',
        Label      : 'Visible in Pos',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterFeatured: {
        Type       : 'v-btn-toggle',
        Label      : 'Featured',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Status',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterResetStock: {
        Type       : 'v-btn-toggle',
        Label      : 'Stock Automation',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterResetStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Status Automation',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterCustomPrice: {
        Type       : 'v-btn-toggle',
        Label      : 'Custom Price',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterCalculatedPrice: {
        Type       : 'v-btn-toggle',
        Label      : 'Price with Product Options',
        PlaceHolder: 'All Products',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      }
    }
  },

  MenuStatusUpdateDialog: {
    Toolbar: {
      Title   : 'Product Status Automation',
      Subtitle: 'Automate Product Status Change',
      Icon    : 'mdi-shape-circle-plus',
      Color   : 'indigo'
    },
    Field: {
      Time: {
        Label      : 'Time',
        Hint       : 'Automatic product status change time',
        Placeholder: 'HH:MM',
        Icon       : 'mdi-calendar-clock',
        Error      : {
          Required  : 'The field is required',
          TimeString: 'Must be a valid time in the format HH:MM'
        }
      }
    }
  },

  MenuStockUpdateDialog: {
    Toolbar: {
      Title   : 'Stock Automation',
      Subtitle: 'Automate Stock Refill',
      Icon    : 'mdi-shape-circle-plus',
      Color   : 'indigo'
    },
    Field: {
      Time: {
        Label      : 'Time',
        Hint       : 'Automatic stock refill time',
        Placeholder: 'HH:MM',
        Icon       : 'mdi-calendar-clock',
        Error      : {
          Required  : 'The field is required',
          TimeString: 'Must be a valid time in the format HH:MM'
        }
      },
      Stock: {
        Label      : 'Stock',
        Hint       : 'Set stock quantity for automatic stock refill.',
        Placeholder: '',
        Icon       : 'shopping_basket',
        Error      : {
          Valid: 'Stock must be at least one number'
        }
      },
      Action: {
        Reset: 'Reset Stock',
        Add  : 'Add Stock'
      }
    }
  },

  MenuAddDialog: {
    Toolbar: {
      Title   : 'Add Catalog Product',
      Subtitle: 'Create New Catalog Product',
      Icon    : 'shopping_cart',
      Color   : 'indigo'
    },
    Button: {
      CustomPrice: {
        Set   : 'Set Price',
        Update: 'Change Price',
        Reset : 'Reset Price'
      }
    },
    Tab: {
      Details     : 'Details',
      Settings    : 'Settings',
      Automation  : 'Automation',
      Stock       : 'Stock',
      Status      : 'Status',
      Options     : 'Product Options',
      ExtraCharges: 'Extra Charges'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Product Name',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'You must enter product name for at least one language',
          Between       : 'Name must be between 2 and 255 characters'
        }
      },
      Description: {
        Label: 'Description',
        Hint : 'Short product description',
        Icon : 'subtitles',
        Error: {
          Between: 'Description must be between 2 and 500 characters'
        }
      },
      Number: {
        Label: 'Number',
        Hint : 'Catalog Product Number. eg 158',
        Icon : 'filter_2'
      },
      Barcode: {
        Label: 'Barcode',
        Hint : 'Catalog Product Barcode.',
        Icon : 'mdi-barcode'
      },
      UnitQuantity: {
        Label: 'Unit Quantity',
        Hint : 'Catalog Product Unit Quantity.',
        Icon : 'mdi-counter'
      },
      Unit: {
        Label: 'Measurement Unit',
        Hint : 'Select product measurement unit',
        Icon : 'mdi-counter',
        Error: {
          Required: 'You must select measurement unit.'
        },
        Units: [
          {
            Id      : 1,
            Name    : 'Item (itm)',
            BaseName: 'Items (itm)',
            BaseUnit: 'itm',
            RateUnit: 'itm',
            Rate    : 1,
            Divisor : 1.000000
          },
          {
            Id      : 2,
            Name    : 'Kilogram (kg)',
            BaseName: 'Grams (g)',
            BaseUnit: 'kg',
            RateUnit: 'g',
            Rate    : 1000,
            Divisor : 0.001000
          },
          {
            Id      : 3,
            Name    : 'Litre (l)',
            BaseName: 'Millilitre (g)',
            BaseUnit: 'l',
            RateUnit: 'ml',
            Rate    : 1000,
            Divisor : 0.001000
          }
        ]
      },
      Price: {
        Label: 'Price',
        Hint : 'Product selling price',
        Error: {
          Valid: 'Price must be a number'
        }
      },
      OptionsPrice: {
        Label: 'Options Price',
        Hint : 'Total product options cost'
      },
      ShowCalculatedPrice: {
        Title : 'Show Price with Product Options Cost',
        Toggle: {
          Active  : 'With Options Cost',
          Inactive: 'No Options Cost'
        },
        SpecialStatus: 'The product has an active offer'
      },
      MenuComment: {
        Label: 'Print Comments',
        Hint : 'Product comments for printing',
        Icon : 'comment'
      },
      Tags: {
        Label: 'Tags',
        Hint : 'Select product tags',
        Icon : 'mdi-tag-multiple'
      },
      Catalogs: {
        Label: 'Catalog',
        Hint : 'Select products catalog',
        Icon : 'menu_book',
        Error: {
          Required: 'You must select at least one products catalog.'
        }
      },
      PrintGroup: {
        Label: 'Printer Group',
        Hint : 'Select printer Group',
        Icon : 'print',
        Error: {
          Required: 'You must select at least one printer group.'
        }
      },
      Categories: {
        Label: 'Select Category',
        Hint : 'Category to which the product belongs',
        Icon : 'category',
        Error: {
          Required: 'You must select at least one category',
          Max     : 'You can select up to twenty (20) categories.'
        }
      },
      DefaultCategory: {
        Label: 'Select Default Category',
        Hint : 'Default category to which the product belongs',
        Icon : 'category',
        Error: {
          Required: 'You must select default category'
        }
      },
      Availability: {
        Label : 'Select Availability',
        Hint  : 'Specify whether the product is available all day or at certain times',
        Icon  : 'access_time',
        AllDay: 'Available all day'
      },
      Image: {
        Label : 'Product Image (800x350)',
        Button: { Title: 'Remove image' },
        Hint  : {
          Drag   : 'Drag & drop an image or',
          Click  : 'click to select a file',
          Tap    : 'Click here to select an',
          Gallery: 'image from your gallery',
          Aspect : 'Landscape / Portrait'
        },
        Control: {
          Change: 'Change image',
          Remove: '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
          Select: 'Select image'
        },
        Success: 'Image selected successfully!',
        Error  : {
          Upload: 'Your device does not support file upload,',
          Size  : 'File size exceeds limit',
          Type  : 'This file type is not supported.'
        }
      },
      Stock: {
        Label: 'Stock',
        Hint : 'Set 0 for unlimited stock.',
        Icon : 'shopping_basket',
        Error: {
          Valid: 'Stock must be at least one number'
        }
      },
      MinimumQuantity: {
        Label: 'Minimum Quantity',
        Hint : 'The minimum quantity that can be ordered',
        Icon : 'opacity',
        Error: {
          Valid: 'Minimum quantity must be at least one number'
        }
      },
      Priority: {
        Label: 'Priority',
        Hint : 'Priority number eg. 10, 100, 150',
        Icon : 'sort_by_alpha',
        Error: {
          Valid: 'Priority must be at least one number'
        }
      },
      SubtractStock   : 'Subtract Stock',
      Status          : 'Product @:Common.Misc.Status',
      Visibility      : 'Product Visible Online',
      VisibilityPos   : 'Product Visible in POS',
      MenuCustomPrice : 'Use Custom Price in POS',
      DiscountExcluded: 'Exclude From Discounts',
      Featured        : 'Featured Product',
      Discount        : {
        Title: 'Discount',
        Field: {
          StartDate: {
            Label: 'Start Date',
            Icon : 'event',
            Error: {
              Required: 'Please select start date'
            }
          },
          EndDate: {
            Label: 'End Date',
            Icon : 'event',
            Error: {
              Required: 'Please select end date'
            }
          },
          DiscountPrice: {
            Label: '@:Restaurant.MenuAddDialog.Field.Price.Label',
            Hint : 'Offer price',
            Error: {
              Required: 'Please enter a price',
              Valid   : 'Price must be a number'
            }
          }
        }
      },
      SelectExtraCharge: {
        Label: 'Select Extra Charge',
        Hint : 'Select product extra charge',
        Icon : 'list_alt'
      },
      SelectOption: {
        Label   : 'Select product options',
        Hint    : 'Catalog product options',
        Icon    : 'list_alt',
        ListView: {
          Required   : 'Required Option',
          DisplayName: {
            Label: 'Display Name',
            Hint : 'Print Only'
          },
          ParentId: {
            Label: 'Select Parent',
            Hint : ''
          },
          MinChoices: {
            Label: 'Min Options',
            Hint : '0 for unlimited options'
          },
          MaxChoices: {
            Label: 'Max Options',
            Hint : '0 for unlimited options'
          },
          AddOptions: 'Add Options',
          Header    : {
            Name     : '@:Common.Misc.Name',
            Price    : '@:Restaurant.MenuAddDialog.Field.Price.Label',
            Quantity : 'Quantity',
            ShowPrice: 'Show @:Restaurant.MenuAddDialog.Field.Price.Label',
            Default  : 'Default',
            Required : 'Required',
            Group    : 'Group'
          },
          SelectGroup: {
            Label: 'Recipe Group',
            Hint : 'Select Recipe Group'
          },
          SelectLocalGroup: {
            Label: 'Options Group',
            Hint : 'Select Options Group'
          }
        }
      }
    },
    NoData: {
      Title   : 'There Are No Available Options',
      Subtitle: 'Add options',
      Icon    : 'list_alt'
    },
    Notification: {
      Update   : 'The product was not updated. @:Common.Misc.Retry',
      Duplicate: 'The product was duplicated successfully with new ID #{id}.'
    },
    StockAutomation: {
      Reset: '<strong>{time}</strong> - Reset to <strong>{stock}</strong>',
      Add  : '<strong>{time}</strong> - Add <strong>{stock}</strong>'
    },
    StatusAutomation: {
      Online: '<strong>Online</strong>',
      Pos   : '<strong>Pos</strong>'
    }
  },

  Categories: {
    Title  : 'Categories | Category | Categories',
    Toolbar: {
      Button: {
        New: {
          Title: 'New Category',
          Icon : 'add_box',
          Color: 'primary'
        },
        Products: {
          Title: 'Products',
          Icon : '',
          Color: 'primary'
        }
      }
    },
    Table: {
      Headers: {
        Image      : '',
        Id         : '#',
        Name       : 'Name',
        Description: 'Description',
        MenusCount : 'Products',
        Catalog    : 'Catalog',
        Tax        : 'VAT',
        Languages  : 'Languages',
        Priority   : 'Priority',
        Status     : 'Status',
        Visible    : 'Visible Online',
        Featured   : 'Featured',
        Actions    : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Delete Category & Products?',
        Content     : 'Are you sure you want to delete the category and all it\'s products?',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      },
      Duplicate: {
        Title  : 'Duplicate Category & Products?',
        Content: 'Do you want to duplicate the category with all it\'s products?'
      }
    },
    Notification: {
      Delete: 'The category was not deleted. @:Common.Misc.Retry'
    },
    Filter: {
      filterCatalog: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Products Catalog',
        PlaceHolder: 'All product catalogs',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterStatus: {
        Type       : 'v-btn-toggle',
        Label      : 'Status',
        PlaceHolder: 'All Categories',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterVisible: {
        Type       : 'v-btn-toggle',
        Label      : 'Visible Online',
        PlaceHolder: 'All Categories',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },
      filterFeatured: {
        Type       : 'v-btn-toggle',
        Label      : 'Featured',
        PlaceHolder: 'All Categories',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: null,
            Name : 'All'
          },
          {
            Value: '1',
            Name : 'Active',
            Icon : '',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: '0',
            Name : 'Inactive',
            Icon : '',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      }
    }
  },

  CategoryAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Category',
        Subtitle: 'Create New Category'
      },
      Edit: {
        Title   : 'Edit Category',
        Subtitle: 'Modify Category Details'
      },
      Icon : 'category',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Category Name. eg. Salads, Pasta, Drinks, etc.',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'You must enter product name for at least one language',
          Between       : 'Name must be between 2 and 255 characters'
        }
      },
      Description: {
        Label: '@:Restaurant.MenuAddDialog.Field.Description.Label',
        Hint : 'Short category description',
        Icon : 'subtitles',
        Error: {
          Between: 'Description must be between 2 and 255 characters'
        }
      },
      Priority: {
        Label: '@:Restaurant.MenuAddDialog.Field.Priority.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.Priority.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.Priority.Icon',
        None : 'None',
        Error: {
          Valid: '@:Restaurant.MenuAddDialog.Field.Priority.Error.Valid'
        }
      },
      Catalogs: {
        Label: '@:Restaurant.MenuAddDialog.Field.Catalogs.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.Catalogs.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.Catalogs.Icon',
        Error: {
          Valid: '@:Restaurant.MenuAddDialog.Field.Catalogs.Error.Valid'
        }
      },
      PrintGroup: {
        Label: '@:Restaurant.MenuAddDialog.Field.PrintGroup.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.PrintGroup.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.PrintGroup.Icon',
        None : 'None',
        Error: {
          Valid: '@:Restaurant.MenuAddDialog.Field.PrintGroup.Error.Valid'
        }
      },
      PrintGroupUpdate: {
        Label: 'Change printer groups of all products in the category with the selected'
      },
      MenusCatalogsUpdate: {
        Label: 'Change catalogs of all products in the category with the selected'
      },
      Tax: {
        Label: 'VAT Category',
        Type : 'TAKEAWAY',
        Hint : 'Select VAT Category',
        Icon : 'percent',
        Error: {
          Required: 'You must select VAT category.'
        }
      },
      Tax2: {
        Label: 'Sitting VAT Category',
        Type : 'TABLES',
        Hint : 'Select Sitting VAT Category',
        Icon : 'percent',
        Error: {
          Required: 'You must select sitting VAT category.'
        }
      },
      TaxUpdate                 : { Label: 'Change VAT category of all products in the category with the selected' },
      ClassificationCategoryCode: {
        Title: 'Income Classification',
        Label: 'Income Classification Category Code',
        Hint : 'Select income classification category code',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'You must select a income classification category code.'
        }
      },
      ClassificationTypeCode: {
        Title: 'Income Classification',
        Label: 'Income Classification Type Code ',
        Hint : 'Select a income classification type code',
        Icon : 'mdi-format-list-bulleted-type',
        Error: {
          Required: 'You must select a income classification type code.'
        }
      },
      ClassificationUpdate: { Label: 'Change income classification category and type' },
      Visibility          : { Label: 'Visible Online' },
      Featured            : { Label: 'Featured Category' },
      Color               : { Label: 'Category Color' },
      Image               : {
        Label : 'Category Image (1460x400)',
        Button: { Title: 'Remove image' },
        Hint  : {
          Drag   : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Drag',
          Click  : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Click',
          Tap    : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Tap',
          Gallery: '@:Restaurant.MenuAddDialog.Field.Image.Hint.Gallery',
          Aspect : '@:Restaurant.MenuAddDialog.Field.Image.Hint.Aspect'
        },
        Control: {
          Change: '@:Restaurant.MenuAddDialog.Field.Image.Control.Change',
          Remove: '@:Restaurant.CategoryAddDialog.Field.Image.Button.Title',
          Select: '@:Restaurant.MenuAddDialog.Field.Image.Control.Select'
        },
        Success: '@:Restaurant.MenuAddDialog.Field.Image.Success',
        Error  : {
          Upload: '@:Restaurant.MenuAddDialog.Field.Image.Error.Upload',
          Size  : '@:Restaurant.MenuAddDialog.Field.Image.Error.Size',
          Type  : '@:Restaurant.MenuAddDialog.Field.Image.Error.Type'
        }
      }
    },
    Notification: {
      Update   : 'The category was not updated. @:Common.Misc.Retry',
      Duplicate: 'The category was duplicated successfully.'
    }
  },

  Tags: {
    Title  : 'Tags | Tag | Tags',
    Toolbar: {
      Button: {
        Title: 'New Tag',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Id         : '#',
        Label      : 'Label',
        Description: 'Description',
        Language   : 'Language',
        Color      : 'Color',
        Icon       : 'Icon',
        Active     : 'Active',
        Visible    : 'Visible',
        LabelOnly  : 'Only Label',
        System     : 'System',
        Group      : 'Group',
        Actions    : ''
      }
    },
    Dialog: {
      Toolbar: {
        Add: {
          Title   : 'Create Tag',
          Subtitle: 'Create Tag'
        },
        Edit: {
          Title   : 'Edit Tag',
          Subtitle: 'Edit Tag'
        },
        Color: 'indigo',
        Icon : ' mdi-tag'
      },
      Field: {
        Icon: {
          Error: {
            Required: 'The icon is required'
          }
        },
        Label: {
          Label: 'Label',
          Hint : 'Label. eg. Healthy, Vegan, Gluten Free, etc.',
          Icon : 'title',
          Error: {
            Between: 'The tag must be between 2 and 32 characters'
          }
        },
        Description: {
          Label: 'Description',
          Hint : 'Description for the tag',
          Icon : 'mdi-text',
          Error: {
            Between: 'The description must be between 2 and 128 characters'
          }
        },
        Group: {
          Label: 'Group',
          Hint : 'Group. eg. admin, over-18, smoke, alcohol, etc.',
          Icon : 'title',
          Error: {
            Between: 'The group must be between 2 and 10 characters'
          }
        },
        Color: {
          Label: 'Tag Color'
        },
        Active: {
          Label: 'Active'
        },
        Visible: {
          Label: 'Visible'
        },
        LabelOnly: {
          Label: 'Only Label'
        },
        System: {
          Label: 'Σύστημα'
        }
      },
      Delete: {
        Title       : 'Delete Tag?',
        Content     : 'Are you sure you want to delete the tag?',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Delete: 'The tag was not deleted. @:Common.Misc.Retry'
    }
  },

  Catalogs: {
    Title  : 'Catalogs | Catalog | Catalogs',
    Toolbar: {
      Button: {
        Title: 'New Catalog',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Image          : '',
        Id             : '#',
        Name           : 'Name',
        ProductsCount  : 'Products',
        TableAreasCount: 'Table Areas',
        OrderSource    : 'Order Source',
        OrderType      : 'Order Type',
        IsDefault      : 'Default Catalog',
        Status         : 'Status',
        Actions        : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Delete Catalog & Products?',
        Content     : 'Are you sure you want to delete the catalog and all the products that belong to this catalog?',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Delete: 'The catalog was not deleted. @:Common.Misc.Retry'
    }
  },

  CatalogAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Catalog',
        Subtitle: 'Create New Catalog'
      },
      Edit: {
        Title   : 'Edit Catalog',
        Subtitle: 'Modify Catalog Details'
      },
      Duplicate: {
        Title   : 'Duplicate Catalog',
        Subtitle: 'Duplicate Catalog With Products & Categories'
      },
      Icon : 'menu_book',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: 'Name',
        Hint : 'Catalog Name ex. Website, Mobile, etc.',
        Icon : 'title',
        Error: {
          Between: 'Name must be between 2 and 255 characters'
        }
      },
      Description: {
        Label: 'Description',
        Hint : 'Quick catalog description',
        Icon : 'subtitles',
        Error: {
          Between: 'Description must be between 2 and 255 characters'
        }
      },
      SourceTypes: {
        Label: 'Order Source Type',
        Hint : '',
        Icon : 'swipe_right_alt',
        Error: {
          Required: 'Select at least 1 source type'
        }
      },
      OrderStatus: {
        Label: 'Order Status',
        Hint : ''
      },
      OrderType: {
        Label: 'Order Type',
        Hint : '',
        Icon : 'shopping_cart',
        Items: [
          {
            Id   : 0,
            Name : 'All Order Types',
            Icon : 'shopping_cart',
            Color: 'green'
          },
          {
            Id   : 1,
            Name : 'Delivery',
            Icon : 'directions_bike',
            Color: 'purple'
          },
          {
            Id   : 2,
            Name : 'Collection',
            Icon : 'directions_run',
            Color: 'blue darken-3'
          }
        ],
        Error: {
          Required: 'Select order type'
        }
      },
      Default: {
        Label: 'Main Catalog'
      },
      CanCreateOrder: {
        Label: 'Create/Edit Orders'
      },
      Color: { Label: 'Catalog Color' }
    },
    Notification: {
      Update        : 'Catalog was not updated!. @:Common.Misc.Retry',
      Duplicate     : 'Catalog was duplicated successfully with ID #{id}',
      DuplicateError: 'Catalog was not duplicated!. @:Common.Misc.Retry'
    }
  },

  // Product Availability
  MealTimes: {
    Title  : 'Product Availability',
    Toolbar: {
      Button: {
        Title: 'New @:Restaurant.MealTimes.Title Category',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Id       : '#',
        Name     : 'Name',
        Languages: 'Languages',
        StartTime: 'Start Time',
        EndTime  : 'End Time',
        Status   : 'Status',
        Actions  : ''
      }
    },
    Notification: {
      Update: '@:Restaurant.CategoryAddDialog.Notification.Update'
    }
  },

  MealTimeAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add @:Restaurant.MealTimes.Title Category',
        Subtitle: 'Create New @:Restaurant.MealTimes.Title Category'
      },
      Edit: {
        Title   : 'Edit @:Restaurant.MealTimes.Title Category',
        Subtitle: 'Modify @:Restaurant.MealTimes.Title Category Details'
      },
      Icon : 'access_time',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Category Name. eg. Breakfast, Lunch, Happy Hour, etc.',
        Icon : 'title',
        Error: {
          Between: 'Name must be between 2 and 32 characters'
        }
      },
      StartTime: {
        Label: 'Start Time',
        Icon : 'access_time',
        Error: {
          Required: 'The start time field is required.'
        }
      },
      EndTime: {
        Label: 'End Time',
        Icon : 'access_time',
        Error: {
          Required: 'The end time field is required.'
        }
      }
    }
  },

  // Options Group
  MenuOptionValueGroups: {
    Toolbar: {
      Button: {
        Title: 'New Option Value Group',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    Table: {
      Headers: {
        Id              : '#',
        Name            : 'Name',
        Languages       : 'Languages',
        Description     : 'Description',
        Status          : 'Status',
        ShowOptionValues: 'Show Options',
        Actions         : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Delete Option Value Group?',
        Content     : 'Are you sure you want to delete the option value group?',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Update: 'The option value group was not updated. @:Common.Misc.Retry',
      Delete: 'The option value group was not deleted. @:Common.Misc.Retry'
    }
  },

  MenuOptionValueGroupAddDialog: {
    Title  : 'Option Value Group',
    Toolbar: {
      Add: {
        Title   : 'Add @:Restaurant.MenuOptionValueGroupAddDialog.Title',
        Subtitle: 'Create New @:Restaurant.MenuOptionValueGroupAddDialog.Title'
      },
      Edit: {
        Title   : 'Edit @:Restaurant.MenuOptionValueGroupAddDialog.Title',
        Subtitle: 'Modify @:Restaurant.MenuOptionValueGroupAddDialog.Title Details'
      },
      Icon : 'tune',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : '@:Restaurant.MenuOptionValueGroupAddDialog.Title Name eg. With everything, etc.',
        Icon : 'title',
        Error: {
          Between: 'Name must be between 2 and 32 characters'
        }
      },
      ShowOptionValues: {
        Label: 'Show Options'
      },
      Description: {
        Label: '@:Restaurant.MenuAddDialog.Field.Description.Label',
        Hint : '@:Restaurant.MenuOptionValueGroupAddDialog.Title Description',
        Icon : 'subtitles',
        Error: {
          Between: 'Description must be between 2 and 200 characters'
        }
      }
    }
  },

  MenuOptionValueModifiersDialog: {
    Toolbar: {
      Title: 'Edit Product Option',
      Icon : 'dynamic_form',
      Color: 'blue'
    },
    Field: {
      Show: 'Show'
    },
    ListView: {
      Header: {
        Name       : '@:Common.Misc.Name',
        Price      : '@:Restaurant.MenuAddDialog.Field.Price.Label',
        ShowPrice  : '@:Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.ShowPrice',
        Default    : '@:Restaurant.MenuAddDialog.Field.SelectOption.ListView.Header.Default',
        Deactivated: 'Deactivated'
      }
    }
  },

  /* Product Options */
  Options: {
    Toolbar: {
      Button: {
        Title: 'New Products Option',
        Icon : 'add_box',
        Color: 'primary'
      }
    },
    ListView: {
      ChoiceType: {
        Single: {
          Title: 'Single Choice',
          Icon : 'radio_button_checked'
        },
        Unit: {
          Title: 'Unit Quantity',
          Icon : 'mdi-counter'
        },
        UnitValue: {
          Title: 'Unit Value',
          Icon : 'mdi-currency-eur'
        },
        ExtraCharges: {
          Title: 'Extra Charges',
          Icon : 'mdi-currency-eur'
        },
        Multiple: {
          Title: 'Multiple Choice',
          Icon : 'check_box'
        },
        Label: {
          Title: 'Title (Label)',
          Icon : 'text_fields'
        },
        Separator: {
          Title: 'Separator Line',
          Icon : 'horizontal_rule'
        }
      }
    },
    Table: {
      Headers: {
        Id         : '#',
        Name       : 'Name',
        DisplayType: 'Display Type',
        Languages  : 'Languages',
        Priority   : 'Priority',
        Actions    : ''
      }
    },
    Dialog: {
      Delete: {
        Title       : 'Delete Product Option?',
        Content     : 'Are you sure you want to delete the product option?',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },
    Notification: {
      Update: 'The product option was not updated. @:Common.Misc.Retry'
    }
  },

  OptionAddDialog: {
    Title  : 'Products Option',
    Toolbar: {
      Add: {
        Title   : 'Add @:Restaurant.OptionAddDialog.Title',
        Subtitle: 'Create New @:Restaurant.OptionAddDialog.Title'
      },
      Edit: {
        Title   : 'Edit @:Restaurant.OptionAddDialog.Title',
        Subtitle: 'Modify @:Restaurant.OptionAddDialog.Title Details'
      },
      Icon : 'list_alt',
      Color: 'indigo'
    },
    Tab: {
      Details: 'General Details',
      Values : 'Option Values'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Product Option Name eg. Select size, etc.',
        Icon : 'title',
        Error: {
          AtLeastOneLang: 'You must enter product name for at least one language',
          Between       : 'Name must be between 2 and 64 characters'
        }
      },
      PrintableName: {
        Label: 'Name for print',
        Hint : 'Product Option Name for print.',
        Icon : 'receipt_long',
        Error: {
          Between: 'Name for print must be between 2 and 64 characters'
        }
      },
      DisplayType: {
        Label: 'Display Type',
        Hint : 'Select display type eg. Radio, Checkbox',
        Icon : {
          All     : 'select_all',
          Radio   : 'radio_button_checked',
          Checkbox: 'check_box'
        },
        Error: {
          Required: 'The display type field is required'
        }
      },
      Priority: {
        Label: '@:Restaurant.MenuAddDialog.Field.Priority.Label',
        Hint : '@:Restaurant.MenuAddDialog.Field.Priority.Hint',
        Icon : '@:Restaurant.MenuAddDialog.Field.Priority.Icon',
        Error: {
          Valid: '@:Restaurant.MenuAddDialog.Field.Priority.Error.Valid'
        }
      }
    },
    ListView: {
      Header: {
        Name : '@:Common.Misc.Name',
        Price: '@:Restaurant.MenuAddDialog.Field.Price.Label'
      }
    },
    Notification: {
      Update: 'The option was not updated. @:Common.Misc.Retry',
      Remove: 'If you remove an option it will also be removed from all the products used!'
    },
    NoData: {
      Title   : '@:Restaurant.MenuAddDialog.NoData.Title',
      Subtitle: '@:Restaurant.MenuAddDialog.NoData.Subtitle',
      Icon    : '@:Restaurant.MenuAddDialog.NoData.Icon'
    },
    Unit: {
      HeaderTitle: 'Quantity',
      ValueHint  : 'Quantity in (g) or (ml)'
    }
  },

  Tables: {
    Title   : 'Tables | Table | Tables',
    Time    : 'Time',
    Products: 'Products',
    Seat    : {
      Title : 'Seats | Seat | Seats',
      Select: 'Seat'
    },
    ItemOrder: {
      Title : 'Order',
      Select: 'Order'
    },
    Toolbar: {
      Button: {
        Title: 'New Table',
        Icon : 'add_box',
        Color: 'primary'
      },
      Filter: {
        TableStatus: {
          Label: 'Table Status',
          Icon : 'crop_square'
        },
        TableArea: {
          Label: 'Table Area',
          Icon : 'crop_square'
        },
        WaiterStaff: {
          Label: 'Waiter',
          Icon : 'room_service'
        }
      },
      Tab: {
        All : 'All',
        My  : 'Mine',
        Open: 'Open'
      }
    },
    Dialog: {
      OptionMenu: {
        NewOrder: {
          Label: 'New Order',
          Icon : 'add'
        },
        PayOrder: {
          Label: 'Payment',
          Icon : 'euro_symbol'
        },
        History: {
          Label: 'View',
          Icon : 'visibility'
        },
        Move: {
          Label: 'Move',
          Icon : 'sync_alt'
        },
        Waiter: {
          Label: 'Change Waiter',
          Icon : 'transfer_within_a_station'
        },
        Merge: {
          Label: 'Merge',
          Icon : 'merge_type'
        },
        Split: {
          Label: 'Split',
          Icon : 'call_split'
        },
        Edit: {
          Label: 'Edit',
          Icon : 'edit_note'
        },
        Close: {
          Label: 'Close Table',
          Icon : 'close'
        },
        Open: {
          Label: 'Open',
          Icon : 'group_add'
        },
        Reservation: {
          Label: 'Reservation',
          Icon : 'mdi mdi-book-open-variant'
        }
      },
      PaymentMenu: {
        PayTable: {
          Label: 'Pay Table',
          Icon : 'tab_unselected'
        },
        PayOrder: {
          Label: 'Pay Per Order',
          Icon : 'shopping_basket'
        },
        PayItems: {
          Label: 'Pay Per Product',
          Icon : 'call_split'
        },
        AwaitingPayment: {
          Set: {
            Label: 'Awaiting Bill',
            Icon : 'hourglass_empty'
          },
          Unset: {
            Label: 'Cancel Awaiting Bill',
            Icon : 'hourglass_empty'
          }
        }
      },
      Payment: {
        Title: 'Payments | Payment | Payments',
        Table: {
          Title   : 'Table Payment',
          Subtitle: 'Pay Table',
          Icon    : 'tab_unselected'
        },
        Order: {
          Title   : 'Table Payment',
          Subtitle: 'Pay Per Order',
          Icon    : 'shopping_basket'
        },
        Items: {
          Title   : 'Table Payment',
          Subtitle: 'Pay Per Product',
          Icon    : 'call_split'
        },
        PaymentSuccess: 'The payment was successfully submitted!',
        Transactions  : {
          Title : 'Transactions',
          Button: {
            Terminal: {
              Add   : 'Send to EFT/POS Terminal',
              Retry : 'Resend to EFT/POS Terminal',
              Refund: 'Refund transaction'
            },
            Remove: 'Delete transaction'
          },
          Info: {
            Refund       : 'Refund the transaction.',
            RefundSuccess: '@:Pos.OrderPaymentDialog.Notification.Refund.Success',
            RefundError  : '@:Pos.OrderPaymentDialog.Notification.Refund.Error',

            Sale     : 'Send to EFT/POS Terminal.',
            SaleRetry: 'Resend to EFT/POS Terminal.',

            SaleWaitingResult: 'Awaiting response from EFT/POS Terminal.',
            SaleSuccess      : 'The transaction was completed successfully.',
            SaleError        : 'The transaction failed. Please try again.'
          },
          Notification: {
            RefundSuccess: 'Transaction refunded successfully'
          },
          Dialog: {
            ConfirmRefund: {
              Title       : 'Refund Transaction;',
              Content     : 'Are you sure you want to refund the transaction?',
              Notification: 'The transaction refund is final and irreversible!'
            }
          }
        },
        Total  : 'Total',
        Change : 'Change',
        Balance: 'Balance'
      },
      MergeTable: {
        Title          : 'Table Merge',
        Subtitle       : 'Select table(s) to merge',
        Icon           : 'merge_type',
        MainTable      : 'Main Table',
        SelectedTables : 'Selected Tables to Merge',
        AvailableTables: 'Available Tables for Merge'
      },
      SplitTable: {
        Title          : 'Split Tables',
        Subtitle       : 'Remove selected table(s) to split',
        Icon           : 'call_split',
        MainTable      : '@:Restaurant.Tables.Dialog.MergeTable.MainTable',
        SelectedTables : 'Merged Tables',
        AvailableTables: 'Selected Tables for Split'
      },
      ViewTable: {
        Title   : 'Table View',
        Subtitle: 'View table details',
        Icon    : 'tab_unselected',
        Tab     : {
          Orders: {
            Title: 'Orders'
          },
          Unpaid: {
            Title: 'Unpaid',
            Step : {
              Orders : 'Orders',
              Payment: 'Payment'
            }
          },
          Paid: {
            Title: 'Paid'
          },
          Canceled: {
            Title: 'Canceled'
          },
          Receipts: {
            Title: 'Fiscal Docs'
          }
        }
      },
      MoveTable: {
        Title      : 'Table Transfer',
        Subtitle   : 'Transfer Table Orders',
        Icon       : 'sync_alt',
        SourceTable: 'Source Table',
        TargetTable: 'Target Table',
        SelectTable: 'Select Table'
      },
      SelectTable: {
        Title   : 'Tables',
        Subtitle: 'Select Table'
      },
      SelectTerminal: {
        Title       : 'EFT/POS Payment Terminals',
        Subtitle    : 'Select EFT/POS Payment Terminal',
        Notification: {
          Success: 'The transaction amount was sent to the selected EFT/POS Payment Terminal.',
          Error  : 'Another transaction is using the selected EFT/POS Payment Terminal. Please try again later.'
        }
      },
      ChangeWaiter: {
        Title       : 'Change Waiter',
        Subtitle    : 'Transfer Table to Waiter',
        Icon        : 'transfer_within_a_station',
        SourceTable : 'Table',
        SourceWaiter: 'Source Waiter',
        TargetWaiter: 'Target Waiter',
        WithPayments: 'Transfer payments to new waiter\'s shift',
        SelectWaiter: 'Select Waiter'
      },
      ConfirmCloseSwift: {
        Title       : 'Table Close',
        Content     : 'Do you want to close the table?',
        Notification: 'After this action the table will be available.'
      },
      ConfirmChangeWaiter: {
        Title       : 'Change Waiter',
        Content     : 'Do you want to change table waiter?',
        Notification: 'After this action the table will be assigned to the new waiter.'
      },
      ConfirmCloseSwiftAndDiscardOrder: {
        Title       : 'Table Close',
        Content     : 'The table <strong>has not been paid for</strong> and has unpaid orders and products. <br><br>Are you sure you want to release the table?',
        Notification: 'After this action the table will be available and any order and products that are unpaid will not be counted.',
        Button      : {
          Yes   : 'Release',
          No    : 'Payment',
          Cancel: 'Cancel'
        }
      },
      ConfirmCloseSwiftAndDiscardOrderPartialPaid: {
        Title       : 'Table Close',
        Content     : 'The table <strong>is partially paid</strong> and has unpaid orders and products. <br><br>Are you sure you want to release the table?',
        Notification: 'After this action the table will be available and any order and products that are unpaid will not be counted.',
        Button      : {
          Yes   : 'Release',
          No    : 'Payment',
          Cancel: 'Cancel'
        }
      },
      ConfirmCancelOrderItems: {
        Title       : 'Cancel Product | Cancel Products',
        Content     : 'Do you want to cancel the selected product? | Do you want to cancel the {count} selected products?',
        Notification: 'After this action the selected product will be permanently cancelled. | After this action the {count} selected products will be permanently cancelled.',
        Success     : 'The selected product has been permanently cancelled. | The {count} selected products have been permanently cancelled.'
      },
      Delete: {
        Title       : 'Delete Table?',
        Content     : 'Are you sure you want to delete this table?',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      },
      Open: {
        Title: 'Open Table',
        Form : {
          Field: {
            Persons: {
              Label          : 'Persons',
              Hint           : 'Enter the number of people sitting at the table. eg. 5',
              ValidationRules: {
                MinNum  : 'The number of people seated must be at least 1',
                MaxNum  : 'The number of people exceeds the maximum number of people ({persons}) allowed at the table.',
                NoMaxNum: 'The number of people exceeds the maximum number of people allowed at the table.'
              }
            }
          }
        }
      }
    },
    TableStatus: {
      Available: {
        Active: true,
        Id    : 22,
        Name  : 'Available',
        Color : 'grey'
      },
      Occupied: {
        Active: true,
        Id    : 23,
        Name  : 'Occupied',
        Color : 'purple'
      },
      Unavailable: {
        Active: false,
        Id    : 24,
        Name  : 'Unavailable',
        Color : 'red'
      },
      Reserved: {
        Active: false,
        Id    : 25,
        Name  : 'Reserved',
        Color : 'blue'
      },
      Paid: {
        Active: true,
        Id    : 30,
        Name  : 'Paid',
        Color : 'green'
      },
      PartiallyPaid: {
        Active: true,
        Id    : 31,
        Name  : 'Partially Paid',
        Color : 'orange'
      },
      AwaitingPayment: {
        Active: true,
        Id    : 32,
        Name  : 'Awaiting Bill',
        Color : 'brown'
      },
      Merged: {
        Active: true,
        Id    : 1000,
        Name  : 'Merged',
        Color : 'indigo lighten-2'
      }
    },
    Notification: {
      Delete            : 'Deleting table failed. @:Common.Misc.Retry',
      HasTableCouponMove: 'Table discount is included. Only move all is allowed.',
      HasTableCouponPay : 'Table discount is included. Only pay all is allowed.'
    },
    Filter: {
      filterTableArea: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Table Area',
        PlaceHolder: 'All table areas',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterTableStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Table Status',
        PlaceHolder: 'All table statuses',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterWaiterStaff: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Waiter',
        PlaceHolder: 'All waiters',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    },
    NoData: {
      Title : 'There is no open waiter shift',
      Button: {
        ManageWaiters: 'Waiter Management'
      }
    },
    NoTables: {
      Title : 'There are No tables',
      Button: {
        ManageWaiters: 'Manage Tables'
      }
    }
  },

  TableManagement: {
    Filter: {
      filterArea: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Tables Area',
        PlaceHolder: 'All table areas',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    },

    Table: {
      Headers: {
        Name          : 'Name',
        Code          : 'QR Code',
        Status        : 'Status',
        Priority      : 'Priority',
        Area          : 'Area',
        MinCapacity   : 'Min Capacity',
        MaxCapacity   : 'Max Capacity',
        Bookable      : 'Bookable',
        BookableOnline: 'Bookable Online',
        Active        : 'Active',
        Actions       : ''
      }
    }
  },

  TableAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Table',
        Subtitle: 'Create New Table'
      },
      Edit: {
        Title   : 'Edit Table',
        Subtitle: 'Modify Table Data'
      },
      Icon : 'fas fa-border-none',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Table Name. eg. A1, B2, 24, etc.',
        Icon : 'title',
        Error: {
          Between: 'Table name must be between 1 and 255 characters',
          Regex  : 'Table name contains invalid characters'
        }
      },
      AreaId: {
        Label: 'Table Area',
        Hint : 'Select Table Area. eg. Garden, Roofgarden, etc.',
        Icon : 'crop_square',
        Error: {
          Mandatory: 'Table Area is required.'
        }
      },
      MinCapacity: {
        Label: 'Minimum Capacity',
        Hint : 'Minimum table capacity. eg. 2, 4, 6, etc.',
        Icon : 'person',
        Error: {
          Mandatory                 : 'Minimum Capacity are mandatory.',
          Number                    : 'Minimum Capacity must be a number.',
          GreaterThanZero           : 'Minimum Capacity must be greater than zero.',
          LessOrEqualThanMaxCapacity: 'Minimum Capacity must be greater than or equal to maximum seats.'
        }
      },
      MaxCapacity: {
        Label: 'Maximum Capacity',
        Hint : 'Maximum table capacity. eg. 2, 4, 6, etc.',
        Icon : 'groups',
        Error: {
          Mandatory                    : 'Maximum Capacity are mandatory.',
          Number                       : 'Maximum Capacity must be a number.',
          GreaterThanZero              : 'Maximum Capacity must be greater than zero.',
          GreaterOrEqualThanMinCapacity: 'Maximum Capacity must be greater than or equal to minimum seats.'
        }
      },
      Priority: {
        Label: 'Priority',
        Hint : 'Priority. Table display order. eg. 2, 4, 6, etc. Leave blank for auto-order.',
        Icon : 'low_priority',
        Error: {
          Number         : 'Priority must be a number.',
          GreaterThanZero: 'Priority must be greater than zero.'
        }
      },
      QrUrl: {
        Label: 'Qr Code',
        Hint : 'The Qr Code will be generated automatically upon saving',
        Icon : 'qr_code'
      }
    },
    Notification: {
      Update: 'Updating table failed. @:Common.Misc.Retry'
    }
  },

  TableAreas: {
    Title  : 'Table Areas | Table Area | Table Areas',
    Toolbar: {
      Button: {
        Title: 'New Table Area',
        Icon : 'add_box',
        Color: 'primary'
      }
    },

    Table: {
      Headers: {
        Color         : '',
        Id            : 'Id',
        Name          : 'Name',
        TablesCount   : 'Tables',
        Priority      : 'Priority',
        Bookable      : 'Bookable',
        BookableOnline: 'Bookable Online',
        Status        : 'Status',
        Actions       : ''
      }
    },

    Dialog: {
      Delete: {
        Title       : 'Delete Table Area?',
        Content     : 'Are you sure you want to delete this table area? All related tables that belong to this table area will be moved to the Default table area.',
        Notification: '@:Restaurant.Menu.Dialog.Delete.Notification'
      }
    },

    Notification: {
      Delete: 'Deleting table area failed. @:Common.Misc.Retry'
    }
  },

  TableAreaAddDialog: {
    Toolbar: {
      Add: {
        Title   : 'Add Table Area',
        Subtitle: 'Create New Table Area'
      },
      Edit: {
        Title   : 'Edit Table Area',
        Subtitle: 'Edit table area data'
      },
      Icon : 'crop_square',
      Color: 'indigo'
    },
    Field: {
      Name: {
        Label: '@:Common.Misc.Name',
        Hint : 'Table area name. eg. Hall, Veranda, Garden, etc.',
        Icon : 'title',
        Error: {
          Between: 'Name must be between 2 and 255 characters',
          Regex  : 'Name cannot contain invalid characters.'
        }
      },
      Priority: {
        Label: 'Priority',
        Hint : 'Priority. Table area display order. eg. 2, 4, 6, etc. Leave blank for auto-order.',
        Icon : 'low_priority',
        Error: {
          Number         : 'Priority must be a number.',
          GreaterThanZero: 'Priority must be greater than zero.'
        }
      },
      Tags: {
        Label: 'Tags',
        Hint : 'Table area tags. eg Outdoor, Indoor, Smoking, Non-Smoking, etc.',
        Icon : 'mdi mdi-tag-plus',
        Error: {
          Length: 'You cannot add more than 5 tags.',
          Regex : 'Tags cannot contain invalid characters.'
        }
      },
      Bookable: {
        Label: 'Bookable'
      },
      BookableOnline: {
        Label: 'Δυνατότητα Κράτησης Online'
      },
      Color: {
        Label: 'Table Area Color'
      }
    },
    Notification: {
      Update: 'Updating table area failed. @:Common.Misc.Retry'
    }
  }
}
