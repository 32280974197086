<template>
  <v-list
    class="mb-2 mt-2 pb-0 pt-0"
    three-line
  >
    <v-list-item
      v-for="order in orders"
      :key="order[fieldOrderId]"
      class="my-2 px-2 px-sm-4"
      :class="isDarkMode ? 'grey darken-4' : 'grey lighten-3'"
      ripple
      @click="onListTileClick(order)"
    >
      <div
        class="text-caption pr-2"
        style="display: grid !important;"
      >
        <v-chip
          class="white--text px-2 mr-1 mb-1"
          :color="parseInt(order[fieldStatusId]) !== 19 ? 'green' : 'red'"
          label
          style="display: inline-block; text-align: center; min-width: 110px;"
        >
          <strong>{{ '#' + order[fieldOrderId] }}</strong>
        </v-chip>

        <div>
          <v-chip
            class="blue-grey--text text--lighten-3 mr-1 mb-1"
            :color="parseInt(order[fieldSourceTypeId]) < 100 ? 'blue-grey' : 'transparent'"
            :class="parseInt(order[fieldSourceTypeId]) < 100 ? 'px-2' : 'px-0'"
            label
            style="display: inline-block; text-align: center;"
            :style="parseInt(order[fieldSourceTypeId]) !== 4 ? parseInt(order[fieldSourceTypeId]) >= 100 ? 'min-width:32px; width: 32px;' : 'min-width:45px;' : 'min-width: 128px;'"
          >
            <v-icon
              v-if="parseInt(order[fieldSourceTypeId]) < 100"
              size="20"
            >
              {{ getOrderSourceTypeById(order[fieldSourceTypeId]).logo }}
            </v-icon>
            <img
              v-else
              height="32"
              :src="getOrderSourceTypeById(order[fieldSourceTypeId]).logo"
            >
          </v-chip>

          <template v-if="parseInt(order[fieldSourceTypeId]) !== 4">
            <v-chip
              v-if="parseInt(order[fieldOrderTypeId]) === 2"
              class="light-blue--text text--lighten-4 px-2 mr-1 mb-1"
              color="light-blue"
              label
              style="display: inline-block; text-align: center;"
              :style="parseInt(order[fieldSourceTypeId]) !== 4 ? parseInt(order[fieldSourceTypeId]) < 100 ? 'min-width: 45px;' : 'min-width: 45px; width: 88px' : 'min-width: 128px;'"
            >
              <v-icon size="20">
                directions_run
              </v-icon>
              <span class="pl-2">
                <strong>{{ convertMinutesToDisplayString(order[fieldOrderTypeTime]) }}</strong>
              </span>
            </v-chip>

            <v-chip
              v-if="parseInt(order[fieldOrderTypeId]) === 1"
              class="purple--text text--lighten-3 px-2 mr-1 mb-1"
              color="purple"
              label
              style="display: inline-block; text-align: center;"
              :style="parseInt(order[fieldSourceTypeId]) !== 4 ? parseInt(order[fieldSourceTypeId]) < 100 ? 'min-width: 45px;' : 'min-width: 45px; width: 88px' : 'min-width: 128px;'"
            >
              <v-icon size="20">
                directions_bike
              </v-icon>
              <span class="pl-2">
                <strong>{{ convertMinutesToDisplayString(order[fieldOrderTypeTime]) }}</strong>
              </span>
            </v-chip>
          </template>
        </div>
      </div>

      <v-list-item-content class="grey--text text--darken-1">
        <slot
          :order="order"
          name="content"
        >
          <v-list-item-title
            v-if="order[fieldFullName].trim()"
            class=""
          >
            <v-icon
              :class="[parseInt(order[fieldCustomerId]) === 0 ? 'grey--text' : 'amber--text']"
              class="text--lighten-2 pb-1"
              size="16"
              style="width: 22px;"
            >
              verified_user
            </v-icon>
            {{ order[fieldFullName] }}
          </v-list-item-title>
          <v-list-item-title
            v-else
            class="text-caption"
          >
            <v-icon
              :class="[parseInt(order[fieldCustomerId]) === 0 ? 'grey--text' : 'amber--text']"
              class="text--lighten-2 pb-1"
              size="16"
              style="width: 22px;"
            >
              verified_user
            </v-icon>
            {{ parseInt(order[fieldCustomerId]) === 0 ? $t('Customer.CustomerType.Visitor') : $t('Customer.CustomerType.Registered') }}
          </v-list-item-title>

          <v-list-item-subtitle
            v-if="order[fieldAddressFormatted]"
            class="text-caption"
          >
            <v-icon
              color="light-green"
              size="16"
              style="width: 22px;"
            >
              location_on
            </v-icon>
            {{ order[fieldAddressFormatted] }}
          </v-list-item-subtitle>

          <v-list-item-subtitle>
            <template v-for="(payment, index) in paymentTypes">
              <template v-if="order[fieldPayment] === payment.code">
                <span :key="index">
                  <v-icon
                    :color="payment.color1"
                    size="16"
                    style="width: 22px;"
                  >
                    {{ payment.code === 'cod' ? appCurrency.Icon : payment.icon }}
                  </v-icon>
                  <span class="blue-grey--text text--darken-2 text-caption">
                    {{ payment.code === 'cod' ? $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) : payment.code === 'pos_card' ? $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) : payment.name.toLocaleUpperCase($i18n.locale) }}
                  </span>
                </span>
              </template>
            </template>
          </v-list-item-subtitle>

          <v-list-item-subtitle style="white-space: nowrap;">
            <v-icon
              color="blue"
              size="16"
              style="width: 22px;"
            >
              today
            </v-icon>
            <span class="text-caption">{{ order[fieldDateAdded] + ' - ' + order[fieldTimeAdded] }}</span>
          </v-list-item-subtitle>
        </slot>
      </v-list-item-content>

      <v-list-item-action>
        <slot
          :order="order"
          name="actions"
        >
          <v-list-item-action-text class="text-subtitle-1">
            {{ order[fieldOrderTotal] | currency }}
          </v-list-item-action-text>
          <v-btn
            v-if="removeAction && posUserHasComponentPermission('Orders', 'MANAGE')"
            color="red"
            icon
            ripple
            small
            @click.native.stop="onRemoveActionClick(order)"
          >
            <v-icon>close</v-icon>
          </v-btn>

          <v-tooltip
            v-if="reorderAction && canEditOrder(order)"
            color="green darken-1"
            left
          >
            <template #activator="{on}">
              <v-btn
                v-if="reorderAction && canEditOrder(order)"
                color="green"
                icon
                ripple
                small
                v-on="on"
                @click.native.stop="onReOrderClick(order)"
              >
                <v-icon>{{ $t('Order.OrdersVList.ReOrder.Icon') }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Order.OrdersVList.ReOrder.Title') }}</span>
          </v-tooltip>
        </slot>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import AppData         from '@/mixins/appdata'
import Auth            from '@/mixins/auth'
import paymentType     from '@/mixins/orders/paymentType'
import OrderCommon     from '@/mixins/orders/common'
import OrderSourceType from '@/mixins/orders/sourceType'

export default {
  name      : 'OrdersVList',
  components: {},
  directives: {},
  mixins    : [AppData, Auth, paymentType, OrderCommon, OrderSourceType],
  props     : {
    orders: {
      type    : Array,
      default : () => [],
      required: true
    },
    fieldOrderId: {
      type   : String,
      default: 'Id'
    },
    fieldFullName: {
      type   : String,
      default: 'FullName'
    },
    fieldCustomerId: {
      type   : String,
      default: 'CustomerId'
    },
    fieldAddressFormatted: {
      type   : String,
      default: 'AddressFormatted'
    },
    fieldPayment: {
      type   : String,
      default: 'Payment'
    },
    fieldDateAdded: {
      type   : String,
      default: 'DateAdded'
    },
    fieldTimeAdded: {
      type   : String,
      default: 'TimeAdded'
    },
    fieldOrderTotal: {
      type   : String,
      default: 'OrderTotal'
    },
    fieldStatusId: {
      type   : String,
      default: 'StatusId'
    },
    fieldOrderTypeId: {
      type   : String,
      default: 'OrderTypeId'
    },
    fieldOrderTypeTime: {
      type   : String,
      default: 'OrderTypeTime'
    },
    fieldSourceTypeId: {
      type   : String,
      default: 'SourceTypeId'
    },

    listTileClickEvent: {
      type   : String,
      default: 'list-tile-click'
    },
    removeActionClickEvent: {
      type   : String,
      default: 'remove-action-click'
    },
    removeAction: {
      type   : Boolean,
      default: false
    },
    reorderAction: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onListTileClick (order) {
      this.$emit(this.listTileClickEvent, order)
    },
    onRemoveActionClick (order) {
      this.$emit(this.removeActionClickEvent, order)
    },
    onReOrderClick (order) {
      this.selectedOrder = order
      this.onEditCopy()
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile {
  padding : 0 8px;
}

/deep/ .v-chip__content {
  padding : 0;
}
</style>
