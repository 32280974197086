<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card v-if="table && area">
        <v-toolbar
          flat
          height="80"
          max-height="80"
          tabs
        >
          <v-badge
            :color="`${getTableStatusColor(table, true)} lighten-2`"
            overlap
          >
            <template #badge>
              <span>{{ viewData && viewData.Persons ? viewData.Persons : table.Persons }}</span>
            </template>

            <v-avatar :color="table.StatusColor ? `${table.StatusColor}` : 'blue darken-3'">
              <v-icon dark>
                group
              </v-icon>
            </v-avatar>
          </v-badge>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ area.Name }} #{{ table.Name }}
            </div>

            <div
              v-if="table.StaffId && table.StaffName"
              class="text-caption grey--text"
            >
              {{ table.StaffName }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-progress-linear
          v-if="loading"
          class="pa-0 ma-0"
          color="secondary"
          height="2"
          indeterminate
        />

        <v-divider />

        <v-card-text class="pa-3">
          <table-cart-orders-list
            :table="table"
            :view-data="viewData"
            desc
            @save-success="onSaveSuccess"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row no-gutters>
              <!-- ORDER TOTALS -->
              <template v-if="viewData">
                <v-col cols="12">
                  <table-orders-totals :view-data="viewData" />
                </v-col>

                <v-col cols="12">
                  <v-divider class="mb-4" />
                </v-col>
              </template>

              <!-- Action Buttons -->
              <v-col
                class="text-right"
                cols="12"
              >
                <v-btn
                  class="light-blue--text text--darken-1 mr-2"
                  text
                  outlined
                  @click="onCancelButtonClick"
                >
                  {{ $t('Common.Button.Close') }}
                </v-btn>

                <v-btn
                  v-if="isTableInOccupiedStatuses && canEditTable && canManageTable"
                  :disabled="loading"
                  :loading="loading"
                  class="primary white--text elevation-0"
                  @click="onEditButtonClick"
                >
                  {{ $t('Common.Button.Edit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TableStatus         from '@/mixins/tables/tableStatus'
import TableOrdersTotals   from '@/components/restaurant/tables/orders/TableOrdersTotals'
import Auth                from '@/mixins/auth'
import TableCommon         from '@/mixins/tables/tableCommon'
import TableCartOrdersList from '@/components/restaurant/tables/orders/TableCartOrdersList'

export default {
  name      : 'TableEditDialog',
  components: {
    TableCartOrdersList,
    TableOrdersTotals
  },
  mixins: [Auth, TableStatus, TableCommon],
  props : {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      loading : false,
      viewData: null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$bus.$on(window.SocketCommand.Table.Show, this.setData)
        this.$bus.$on(window.SocketCommand.Ajax.Get, this.getData)
        this.getData()
      } else {
        this.$bus.$off(window.SocketCommand.Table.Show, this.setData)
        this.$bus.$off(window.SocketCommand.Ajax.Get, this.getData)
        this.viewData = null
      }
    }
  },
  methods: {
    onSaveSuccess () {
      this.$emit('save-success')
      this.getData()
    },

    getData (payload) {
      const command = window.SocketCommand.Table.GetById
      const payloadCommand = window.SocketCommand.commandToClientFormat(payload?.command || '')
      const payloadTableIdsArray = payload?.TableId
      const payloadTableClose = payload?.TableClose

      if (!payload || (payloadCommand === command && payloadTableIdsArray.includes(this.table.Id))) {
        if (payloadTableClose) {
          this.onCancelButtonClick()
        } else {
          this.loading = true
          window.callAS(window.SocketCommand.Table.Show, { TableId: this.table.Id })
        }
      }
    },
    setData (data) {
      this.viewData = data.data || null
      this.loading = false

      if (!this.viewData?.ItemsGrouped?.length) this.onCancelButtonClick()
    },

    onEditButtonClick () {
      this.$router.push({
        name  : 'Pos',
        params: {
          orderId: this.viewData.Id,
          tableId: this.table.Id,
          table  : this.table,
          area   : this.area
        }
      })
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.viewData = null
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__extension {
  padding : 0 12px !important;
}
</style>
