import ReceiptTypeEnum         from '@/api/Enums/ReceiptTypeEnum'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'
import PaymentTypeEnum         from '@/api/Enums/PaymentTypeEnum'

export default {
  props: {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedItemsTotal         : 0,
      selectedUnpaidItemsTotal   : 0,
      paymentStep                : 1,
      selectedItems              : [],
      selectedReceiptType        : ReceiptTypeEnum.RECEIPT,
      selectedReceiptData        : null,
      selectedReceiptNotify      : [],
      selectedReceiptNotifyEmails: [],
      selectedUnpaidItems        : [],
      totals                     : [],
      loading                    : false,
      viewData                   : null
    }
  },

  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.initData()
      } else {
        this.clearData()
      }
    }
  },

  computed: {
    transactions: {
      get () {
        return this.viewData?.PaymentTransactions || []
      },
      set (val) {
        if (!this.viewData) return
        this.viewData.PaymentTransactions = val
      }
    },

    tableTotals () {
      return this.viewData?.TableTotals || []
    },

    tableTotalsUsed () {
      return this.viewData?.TableTotalsUsed || []
    },

    tableCouponsDiscountTotal () {
      return parseFloat((this.tableCoupons.reduce((value, coupon) => value + coupon.Amount, 0) || 0).toFixed(2))
    },

    tableCouponsUsedDiscountTotal () {
      return parseFloat((this.tableCouponsUsed.reduce((value, coupon) => value + coupon.Amount, 0) || 0).toFixed(2))
    },

    tableCoupons () {
      return this.tableTotals.filter(total => total.Name === 'coupon') || []
    },

    tableCouponsUsed () {
      return this.tableTotalsUsed.filter(total => total.Name === 'coupon') || []
    },

    selectedUnpaidItemsHaveFullDiscount () {
      return !!this.selectedUnpaidItems?.filter(item => item?.Coupon?.Custom === '100.00%' || item?.Coupon?.Custom === '100%').length
    },

    selectedItemsHaveFullDiscount () {
      return !!this.selectedItems?.filter(item => item?.Coupon?.Custom === '100.00%' || item?.Coupon?.Custom === '100%').length
    },

    selectedOrdersHaveItemsWithFullDiscount () {
      let retVal = false
      this.selectedItems.forEach(order => {
        if (order.Items?.filter(item => item?.Coupon?.Custom === '100.00%' || item?.Coupon?.Custom === '100%').length) retVal = true
      })
      return retVal
    },

    tableBalance () {
      const transactionsTotal = parseFloat(this.transactions.reduce((a, b) => a + parseFloat(b.Amount), 0).toFixed(2))
      const selectedItemsTotal = parseFloat(this.selectedItemsTotal.toFixed(2))

      return parseFloat((selectedItemsTotal - transactionsTotal - this.tableCouponsDiscountTotal).toFixed(2))
    },

    tableUnpaidBalance () {
      let transactionsTotal = parseFloat(this.transactions.reduce((a, b) => a + parseFloat(b.Amount), 0).toFixed(2))

      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        transactionsTotal = parseFloat(this.transactions.filter(transaction => transaction.Paid === 'success' || transaction.PaymentType === PaymentTypeEnum.CASH).reduce((a, b) => a + parseFloat(b.Amount), 0).toFixed(2))
      }
      const selectedItemsTotal = parseFloat(this.selectedUnpaidItemsTotal.toFixed(2))

      return parseFloat((selectedItemsTotal - transactionsTotal - this.tableCouponsDiscountTotal).toFixed(2))
    },

    tableTotalForPayment () {
      const selectedItemsTotal = parseFloat(this.selectedItemsTotal.toFixed(2))

      return selectedItemsTotal - this.tableCouponsDiscountTotal
    },

    tableUnpaidTotalForPayment () {
      const selectedItemsTotal = parseFloat(this.selectedUnpaidItemsTotal.toFixed(2))

      return selectedItemsTotal - this.tableCouponsDiscountTotal
    },

    tableHasCardPayments () {
      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        return !!this.transactions?.filter(transaction => transaction.PaymentType === PaymentTypeEnum.CARD && transaction.Paid === 'success')?.length
      }

      return !!this.transactions?.filter(transaction => transaction.PaymentType === PaymentTypeEnum.CARD)?.length
    },

    tableHasCardPaymentsThatRequireReceipt () {
      if (this.appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD) {
        return !!this.transactions?.filter(transaction => transaction.PaymentType === PaymentTypeEnum.CARD && transaction.Paid === 'success')?.length
      }

      return false
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  methods: {
    initData () {
      this.$bus.$on(window.SocketCommand.Table.Show, this.setData)
      this.$bus.$on(window.SocketCommand.Table.Pay, this.onPayResponse)
      this.clearData(false)
      this.getData()
    },
    clearData (clearEvents = true) {
      if (clearEvents) {
        this.$bus.$off(window.SocketCommand.Table.Show, this.setData)
        this.$bus.$off(window.SocketCommand.Table.Pay, this.onPayResponse)
      }

      this.loading = false
      this.viewData = null
      this.selectedItems = []
      this.selectedUnpaidItems = []
      this.transactions = []
      this.totals = []
      this.paymentStep = 1
      this.selectedItemsTotal = 0
      this.selectedUnpaidItemsTotal = 0

      this.selectedReceiptType = ReceiptTypeEnum.RECEIPT
      this.selectedReceiptData = null
      this.selectedReceiptNotify = []
    },

    getData () {
      this.loading = true
      window.callAS(window.SocketCommand.Table.Show, { TableId: this.table.Id })
    },
    setData (data) {
      this.viewData = data.data || null
      this.loading = false
    },

    getZeroTransaction () {
      return {
        TableId      : this.table.Id,
        CartId       : this.table.CartId,
        TransactionId: Date.now().toString() + (this.transactions.length + 1),
        PaymentType  : PaymentTypeEnum.CASH,
        Received     : 0.00,
        Amount       : 0.00
      }
    },

    onPayResponse (response) {
      if (response.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Tables.Dialog.Payment.PaymentSuccess'),
          type: 'success',
          icon: 'info'
        })
        this.$emit('payment-success')
        this.onCancelButtonClick()
      } else {
        this.loading = false
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.clearData()
    }
  }
}
