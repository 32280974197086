<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :overlay="false"
      fullscreen
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar
          id="cardOrderToolbar"
          color="blue-grey darken-3"
          flat
          height="80"
          max-height="80"
        >
          <v-avatar :color="selectedCustomer ? getCustomerRegistrationType(selectedCustomer.Info.SocialProvider).Color : 'blue-grey lighten-1'">
            <v-icon dark>
              {{ selectedCustomer ? getCustomerRegistrationType(selectedCustomer.Info.SocialProvider).Icon : 'account_circle' }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div
              v-if="selectedCustomer"
              class="body-3 grey--text text--lighten-3"
            >
              {{ selectedCustomer.Info.Firstname }} {{ selectedCustomer.Info.Lastname }}
            </div>
            <div
              v-else
              class="body-3 grey--text text--lighten-3"
            >
              {{ $t('Customer.CustomerDetailsDialog.Toolbar.Title') }}
            </div>

            <div class="text-caption grey--text  text--lighten-1">
              {{ selectedCustomer ? getCustomerRegistrationType(selectedCustomer.Info.SocialProvider).Name : $t('Customer.CustomerDetailsDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            dark
            icon
            @click="isVisible = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="ma-0 pa-0 fill-height">
          <v-container
            v-if="selectedCustomer"
            id="customerDetailsContainer"
            fluid
          >
            <v-row>
              <!-- STATS -->
              <v-col
                v-if="posUserHasComponentPermission('CustomerStats', 'ACCESS')"
                cols="12"
              >
                <v-row
                  id="statsCard"
                >
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                  >
                    <elevated-card
                      :avatar-color="statsWeek > statsPrevWeek ? 'light-green' : statsWeek - statsPrevWeek === 0 ? 'light-blue' : 'orange'"
                      :avatar-sub-text="$tc('Order.Title',statsWeek).toLocaleUpperCase($i18n.locale)"
                      :avatar-text="statsWeek.toString()"
                      :footer-text="'<b>' + $t('Customer.CustomerDetailsDialog.Card.Statistic.Week.FooterText')+ '</b> ' + statsPrevWeek.toString() + ' (' + $options.filters.currency(salesPrevWeek) + ')'"
                      :title="$t('Common.Date.Week')"
                    >
                      <div
                        slot="subTitle"
                        class="subtext-h6 text-h6 grey--text text--darken-2"
                        style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
                      >
                        <v-icon :class="salesWeek > salesPrevWeek ? 'light-green--text' : salesWeek - salesPrevWeek === 0 ? 'light-blue--text' : 'orange--text'">
                          {{ salesWeek > salesPrevWeek ? 'trending_up' : salesWeek - salesPrevWeek === 0 ? 'trending_flat' : 'trending_down' }}
                        </v-icon>
                        {{ salesWeek | currency }}
                      </div>
                    </elevated-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                  >
                    <elevated-card
                      :avatar-color="statsMonth > statsPrevMonth ? 'light-green' : statsMonth-statsPrevMonth === 0 ? 'light-blue' : 'orange'"
                      :avatar-sub-text="$tc('Order.Title',statsMonth).toLocaleUpperCase($i18n.locale)"
                      :avatar-text="statsMonth.toString()"
                      :footer-text="'<b>' + $t('Customer.CustomerDetailsDialog.Card.Statistic.Month.FooterText')+ '</b> ' + statsPrevMonth.toString() + ' (' + $options.filters.currency(salesPrevMonth) + ')'"
                      :title="$t('Common.Date.Month')"
                    >
                      <div
                        slot="subTitle"
                        class="subtext-h6 text-h6 grey--text text--darken-2"
                        style="text-overflow:ellipsis; overflow: hidden; white-space: nowrap;"
                      >
                        <v-icon :class="salesMonth > salesPrevMonth ? 'light-green--text' : salesMonth-salesPrevMonth === 0 ? 'light-blue--text' : 'orange--text'">
                          {{ salesMonth > salesPrevMonth ? 'trending_up' : salesMonth - salesPrevMonth === 0 ? 'trending_flat' : 'trending_down' }}
                        </v-icon>
                        {{ salesMonth | currency }}
                      </div>
                    </elevated-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                  >
                    <elevated-card
                      :avatar-sub-text="$tc('Order.Title',statsYear).toLocaleUpperCase($i18n.locale)"
                      :avatar-text="statsYear.toString()"
                      :footer-text="'<b>'+$t('Common.Misc.Total')+':</b> ' + statsAll.toString() + ' (' + $options.filters.currency(salesAll) + ')'"
                      :sub-title="salesYear | currency"
                      :title="$t('Common.Date.Year')"
                      avatar-color="purple lighten-1"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                  >
                    <elevated-card
                      :avatar-sub-text="$t('Customer.CustomerDetailsDialog.Card.Statistic.Average.Subtext').toLocaleUpperCase($i18n.locale)"
                      :footer-text="'<b>'+$t('Common.Misc.Total')+':</b> ' + $options.filters.currency(salesAll/statsAll || 0)"
                      :sub-title="(salesYear/statsYear || 0) | currency"
                      :title="$t('Common.Date.Year')"
                      avatar-color="teal"
                      avatar-icon="equalizer"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <!-- DETAILS -->
                    <v-row
                      id="customerDetailsCard"
                    >
                      <v-col cols="12">
                        <v-card>
                          <v-toolbar
                            id="customerDetailsCardTitle"
                            color="blue"
                            dense
                            flat
                            height="52"
                          >
                            <v-icon
                              :color="$t('Customer.CustomerDetailsDialog.Card.Details.Toolbar.Color')"
                              class="pr-2"
                              large
                            >
                              {{ $t('Customer.CustomerDetailsDialog.Card.Details.Toolbar.Icon') }}
                            </v-icon>

                            <v-tabs
                              v-model="detailsTab"
                              align-with-title
                              background-color="blue"
                              dark
                              show-arrows="never"
                            >
                              <v-tabs-slider color="white" />

                              <v-tab
                                key="tab-customer"
                                class="text-capitalize white--text"
                                tab-value="tab-customer"
                              >
                                {{ $t('Customer.CustomerDetailsDialog.Card.Details.Toolbar.Tab.Customer') }}
                              </v-tab>

                              <v-tab
                                key="tab-companies"
                                class="text-capitalize white--text"
                                tab-value="tab-companies"
                              >
                                {{ $t('Customer.CustomerDetailsDialog.Card.Details.Toolbar.Tab.Companies') }}
                              </v-tab>
                            </v-tabs>

                            <div>
                              <v-btn
                                v-if="posUserHasComponentPermission('Customers', 'MANAGE') && detailsTab === 'tab-customer'"
                                class="blue darken-2"
                                dark
                                height="36"
                                icon
                                ripple
                                width="36"
                                @click.native.stop="showCustomerEditDialog"
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>

                              <v-btn
                                v-if="posUserHasComponentPermission('Customers', 'MANAGE') && detailsTab === 'tab-companies'"
                                class="blue darken-2"
                                dark
                                height="36"
                                icon
                                ripple
                                width="36"
                                @click.native.stop="showCustomerAddCompanyDialog(null)"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                            </div>
                          </v-toolbar>

                          <v-divider />

                          <v-card-text>
                            <v-tabs-items
                              v-model="detailsTab"
                              class="fill-height"
                              style="overflow-y: auto; min-height:225px;"
                            >
                              <v-tab-item
                                key="tab-customer"
                                value="tab-customer"
                              >
                                <v-container
                                  class="pa-3"
                                  fluid
                                >
                                  <v-row
                                    class="pt-2 pb-0"
                                    :class="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                                  >
                                    <v-col
                                      class="text-center text-h6 grey--text text--darken-1"
                                      cols="12"
                                    >
                                      <v-badge
                                        :color="selectedCustomer.Info.Status ? 'light-green' : 'grey'"
                                        right
                                      >
                                        <template #badge>
                                          <v-icon
                                            dark
                                            small
                                          >
                                            {{ selectedCustomer.Info.Status ? 'done' : 'close' }}
                                          </v-icon>
                                        </template>

                                        <span>{{ selectedCustomer.Info.Firstname + ' ' + selectedCustomer.Info.Lastname }}</span>
                                      </v-badge>
                                    </v-col>
                                    <v-col
                                      class="text-center grey--text text--darken-1 mt-0 pt-0"
                                      cols="12"
                                    >
                                      <div
                                        v-if="selectedCustomer.Info.Telephone && $options.filters.phone(selectedCustomer.Info.Telephone)"
                                        class="pr-2"
                                        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: inline-block;"
                                      >
                                        <v-icon
                                          color="indigo lighten-2"
                                          small
                                        >
                                          phone
                                        </v-icon>
                                        {{ selectedCustomer.Info.Telephone | phone }}
                                      </div>
                                      <div
                                        v-if="selectedCustomer.Info.Telephone2 && $options.filters.phone(selectedCustomer.Info.Telephone2)"
                                        class="pr-2"
                                        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: inline-block;"
                                      >
                                        <v-icon
                                          color="indigo lighten-2"
                                          small
                                        >
                                          phone
                                        </v-icon>
                                        {{ selectedCustomer.Info.Telephone2 | phone }}
                                      </div>
                                      <div
                                        v-if="selectedCustomer.Info.Telephone3 && $options.filters.phone(selectedCustomer.Info.Telephone3)"
                                        class="pr-2"
                                        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: inline-block;"
                                      >
                                        <v-icon
                                          color="indigo lighten-2"
                                          small
                                        >
                                          phone
                                        </v-icon>
                                        {{ selectedCustomer.Info.Telephone3 | phone }}
                                      </div>
                                      <div
                                        v-if="!emailIsGuest(selectedCustomer.Info.Email)"
                                        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: inline-block;"
                                      >
                                        <v-icon
                                          color="indigo lighten-2"
                                          small
                                        >
                                          email
                                        </v-icon>
                                        {{ selectedCustomer.Info.Email }}
                                      </div>

                                      <div v-if="selectedCustomer.Info.Notes">
                                        <v-alert
                                          class="text-center text-caption grey--text"
                                          :color="isDarkMode ? '#191919' : 'grey lighten-3'"
                                          dense
                                        >
                                          {{ selectedCustomer.Info.Notes }}
                                        </v-alert>
                                      </div>
                                    </v-col>

                                    <v-col
                                      class="pa-0 ma-0"
                                      cols="12"
                                    >
                                      <div
                                        class="text-caption grey--text text-right pr-2 pb-2 ma-0"
                                      >
                                        {{ $t('Customer.CustomerDetailsDialog.Card.Details.Content.DateAdded') }} {{ selectedCustomer.Info.DateAdded }}
                                      </div>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col
                                      class="grey--text text--darken-1  ma-0 pa-0"
                                      cols="12"
                                    >
                                      <v-subheader
                                        class="pa-0 ma-0"
                                        v-text="$t('Customer.CustomerDetailsDialog.Card.Details.Content.CustomerGroup')"
                                      />

                                      <div
                                        class="pb-2"
                                        style="width: auto;white-space: nowrap;overflow-x: auto;"
                                      >
                                        <template v-for="(group, index) in selectedCustomer.Info.Groups">
                                          <v-chip
                                            v-if="selectedCustomer.CustomerGroups.findIndex(groupObj => parseInt(groupObj.Id) === parseInt(group)) > -1"
                                            :key="index"
                                            class="mr-1 px-1"
                                            color="indigo"
                                            label
                                            small
                                            text-color="white"
                                          >
                                            <v-avatar class="mr-1">
                                              <v-icon>group</v-icon>
                                            </v-avatar>
                                            {{ selectedCustomer.CustomerGroups.find(groupObj => parseInt(groupObj.Id) === parseInt(group)).Name }}
                                          </v-chip>
                                        </template>
                                      </div>
                                    </v-col>
                                  </v-row>

                                  <v-divider class="mt-4 mb-2" />

                                  <template v-if="selectedCustomer.Stats.top_products">
                                    <v-row
                                      v-if="selectedCustomer.Stats.top_products.length > 0"
                                    >
                                      <v-col
                                        class="ma-0 pa-0"
                                        cols="12"
                                      >
                                        <v-subheader
                                          v-if="topProductsChartData"
                                          class="pa-0 ma-0"
                                          v-text="$tc('Customer.CustomerDetailsDialog.Card.Details.Content.Preferences', topProductsChartData.labels.length, {count: topProductsChartData.labels.length})"
                                        />
                                        <top-products-chart
                                          v-if="topProductsChartData"
                                          :chart-data="topProductsChartData"
                                          :chart-options="topProductsChartOptions"
                                          :height="315"
                                          :widht="315"
                                          chart-id="chartTopProducts"
                                          class="ma-0 pa-0"
                                          css-classes=""
                                        />
                                      </v-col>
                                    </v-row>
                                  </template>
                                </v-container>
                              </v-tab-item>

                              <v-tab-item
                                key="tab-companies"
                                class="fill-height"
                                value="tab-companies"
                              >
                                <div
                                  v-if="!selectedCustomer.Companies || selectedCustomer.Companies.length <= 0"
                                  class="align-center justify-center d-flex fill-height"
                                >
                                  <div class="align-center justify-center d-flex pa-0 my-auto">
                                    <div
                                      class="text-center grey--text pa-0"
                                      style="zoom: 0.80"
                                    >
                                      <v-icon
                                        class="mb-4"
                                        size="128"
                                      >
                                        {{ $t('Customer.CustomerAddCompanyDialog.Card.NoData.Icon') }}
                                      </v-icon>
                                      <div
                                        class="text-h6 mb-2"
                                        style="height: auto;"
                                      >
                                        {{ $t('Customer.CustomerAddCompanyDialog.Card.NoData.Title') }}
                                      </div>
                                      <div
                                        class="text-subtitle-1"
                                        style="height: auto;"
                                      >
                                        {{ $t('Customer.CustomerAddCompanyDialog.Card.NoData.Subtitle') }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <v-list
                                  v-else
                                  class="pa-0"
                                  three-line
                                >
                                  <template v-for="(item, index) in selectedCustomer.Companies">
                                    <v-list-item
                                      :key="`company-list-item-${index}`"
                                      :class="isDarkMode ? 'grey darken-4' : 'grey lighten-3'"
                                    >
                                      <v-list-item-avatar>
                                        <v-icon
                                          :class="[item.IsDefault ? 'light-green' : 'grey lighten-2']"
                                          class="white--text"
                                          large
                                        >
                                          check
                                        </v-icon>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title :class="[isDarkMode ? 'white--text' : 'black--text']">
                                          {{ item.BrandName }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                          v-if="item.Profession"
                                          class="text-caption grey--text text--darken-4"
                                        >
                                          {{ item.Profession }}
                                        </v-list-item-subtitle>

                                        <v-list-item-subtitle class="grey--text text-caption">
                                          <span class="grey--text text--darken-2">{{ $t('Customer.CustomerAddCompanyDialog.Field.VatNumber.Label') }}: {{ item.VatNumber }}</span>
                                          <span
                                            v-if="item.DistinctiveTitle"
                                            class="grey--text text--darken-2 ml-3"
                                          >{{ item.DistinctiveTitle }}</span>
                                        </v-list-item-subtitle>

                                        <v-list-item-subtitle class="text-caption grey--text">
                                          {{ item.Street }} {{ item.StreetNumber }}, {{ item.City }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>

                                      <v-list-item-action>
                                        <v-btn
                                          v-if="posUserHasComponentPermission('Customers', 'MANAGE') && (!isCustomAddress(item) || (isCustomAddress(item) && acceptCustomAddresses))"
                                          icon
                                          ripple
                                          @click.native.stop="showCustomerAddCompanyDialog(item)"
                                        >
                                          <v-icon color="green lighten-1">
                                            edit
                                          </v-icon>
                                        </v-btn>
                                        <v-btn
                                          v-if="selectedCustomer.Companies.length && posUserHasComponentPermission('Customers', 'DELETE')"
                                          icon
                                          ripple
                                          @click.native.stop="showConfirmDeleteCompanyDialog(item)"
                                        >
                                          <v-icon color="red lighten-1">
                                            close
                                          </v-icon>
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>

                                    <v-divider
                                      :key="`company-list-item-divider-${index}`"
                                      class="mb-2"
                                    />
                                  </template>
                                </v-list>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-row
                      id="customerMetaCard"
                    >
                      <!-- ADDRESSES -->
                      <v-col
                        v-if="posUserHasComponentPermission('CustomerAddresses', 'ACCESS')"
                        id="customerAddressCard"
                        cols="12"
                      >
                        <v-card>
                          <v-card-title
                            class="pt-0 pb-0 green lighten-0"
                            style="height: 52px;"
                          >
                            <v-icon
                              :color="$t('Customer.CustomerDetailsDialog.Card.Address.Toolbar.Color')"
                              class="pr-2"
                              large
                            >
                              {{ $t('Customer.CustomerDetailsDialog.Card.Address.Toolbar.Icon') }}
                            </v-icon>
                            <h3 class="text-h6 mb-0 white--text">
                              {{ $t('Customer.CustomerDetailsDialog.Card.Address.Toolbar.Title') }}
                            </h3>
                            <v-spacer />
                            <v-btn
                              v-if="posUserHasComponentPermission('CustomerAddresses', 'ADD')"
                              class="green darken-2"
                              dark
                              icon
                              ripple
                              @click="showCustomerAddEditAddressDialog(null)"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </v-card-title>

                          <v-divider />

                          <v-card-text style="overflow-y: auto; min-height:113px; max-height: 195px;">
                            <v-row
                              v-if="!selectedCustomer.Addresses || selectedCustomer.Addresses.length <= 0"
                              class="align-center justify-center fill-height"
                            >
                              <div
                                class="align-center justify-center flex pa-0"
                                style="zoom: 0.80"
                              >
                                <div
                                  class="text-center grey--text align-center justify-center flex pa-0"
                                >
                                  <v-icon
                                    :color="$t('Customer.CustomerDetailsDialog.Card.Address.Content.NoData.Color')"
                                    class="mb-4"
                                    size="128"
                                  >
                                    {{ $t('Customer.CustomerDetailsDialog.Card.Address.Content.NoData.Icon') }}
                                  </v-icon>
                                  <div
                                    class="text-h6 mb-2"
                                    style="height: auto;"
                                  >
                                    {{ $t('Customer.CustomerDetailsDialog.Card.Address.Content.NoData.Title') }}
                                  </div>
                                  <div
                                    class="text-subtitle-1"
                                    style="height: auto;"
                                  >
                                    {{ $t('Customer.CustomerDetailsDialog.Card.Address.Content.NoData.Subtitle') }}
                                  </div>
                                </div>
                              </div>
                            </v-row>

                            <v-list
                              v-for="(item, index) in selectedCustomer.Addresses"
                              v-else
                              :key="item.Id"
                              class="mb-2 mt-0 pb-0 pt-0"
                              two-line
                            >
                              <v-list-item
                                :key="index"
                                :class="[isDarkMode ? 'grey darken-4' : 'grey lighten-3']"
                              >
                                <v-list-item-avatar>
                                  <v-icon
                                    :class="[item.IsDefault ? 'light-green' : 'grey lighten-2']"
                                    class="white--text"
                                    large
                                  >
                                    check
                                  </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content class="grey--text text--darken-1">
                                  <v-list-item-title class="text-left">
                                    {{ item.Formatted }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="item.Floor || item.DoorBell">
                                    <span
                                      v-if="item.Floor"
                                      class="mr-4"
                                    >
                                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                                        {{ $t('Customer.CustomerDetailsDialog.Card.Address.Content.ListView.Floor') }}
                                      </span> {{ item.Floor }}
                                    </span>
                                    <span v-if="item.DoorBell">
                                      <span :class="isDarkMode ? 'white--text' : 'black--text'">
                                        {{ $t('Customer.CustomerDetailsDialog.Card.Address.Content.ListView.DoorBell') }}
                                      </span> {{ item.DoorBell }}
                                    </span>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle v-if="item.Comment">
                                    <span :class="isDarkMode ? 'white--text' : 'black--text'">
                                      {{ $t('Customer.CustomerDetailsDialog.Card.Address.Content.ListView.Instructions') }}
                                    </span> {{ item.Comment }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    v-if="posUserHasComponentPermission('CustomerAddresses', 'MANAGE') && (!isCustomAddress(item) || (isCustomAddress(item) && acceptCustomAddresses))"
                                    icon
                                    ripple
                                    @click.native.stop="showCustomerAddEditAddressDialog(item)"
                                  >
                                    <v-icon color="green lighten-1">
                                      edit
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="selectedCustomer.Addresses.length && posUserHasComponentPermission('CustomerAddresses', 'DELETE')"
                                    icon
                                    ripple
                                    @click.native.stop="showConfirmDeleteAddressDialog(item)"
                                  >
                                    <v-icon color="red lighten-1">
                                      close
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider />
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <!-- ORDERS -->
                      <v-col
                        v-if="posUserHasComponentPermission('CustomerOrders', 'ACCESS')"
                        id="customerOrdersCard"
                        cols="12"
                      >
                        <v-card>
                          <v-card-title
                            class="pt-2 pb-2 deep-purple lighten-1"
                            style="min-height: 48px;"
                          >
                            <v-icon
                              :color="$t('Customer.CustomerDetailsDialog.Card.Order.Toolbar.Color')"
                              class="pr-2"
                              large
                            >
                              {{ $t('Customer.CustomerDetailsDialog.Card.Order.Toolbar.Icon') }}
                            </v-icon>
                            <h3 class="text-h6 mb-0 white--text">
                              {{ $tc('Customer.CustomerDetailsDialog.Card.Order.Toolbar.Title', selectedCustomer.Orders.length, {count: selectedCustomer.Orders.length}) }}
                            </h3>
                            <v-spacer />
                          </v-card-title>
                          <v-divider />
                          <v-card-text
                            class="fill-height"
                            style="overflow-y: auto;"
                          >
                            <v-row
                              v-if="!selectedCustomer.Orders || selectedCustomer.Orders.length <= 0"
                              class="align-center justify-center fill-height"
                            >
                              <div
                                class="align-center justify-center flex pa-0"
                                style="zoom: 0.80"
                              >
                                <div
                                  class="text-center grey--text align-center justify-center flex pa-0"
                                >
                                  <v-icon
                                    :color="$t('Customer.CustomerDetailsDialog.Card.Order.Content.NoData.Color')"
                                    class="mb-4"
                                    size="128"
                                  >
                                    {{ $t('Customer.CustomerDetailsDialog.Card.Order.Content.NoData.Icon') }}
                                  </v-icon>
                                  <div
                                    class="text-h6 mb-2"
                                    style="height: auto;"
                                  >
                                    {{ $t('Customer.CustomerDetailsDialog.Card.Order.Content.NoData.Title') }}
                                  </div>
                                  <div
                                    class="text-subtitle-1"
                                    style="height: auto;"
                                  >
                                    {{ $t('Customer.CustomerDetailsDialog.Card.Order.Content.NoData.Subtitle') }}
                                  </div>
                                </div>
                              </div>
                            </v-row>

                            <orders-v-list
                              :orders="selectedCustomer.Orders"
                              :reorder-action="true"
                              @list-tile-click="getOrderDetails"
                            />
                          </v-card-text>

                          <template v-if="posUserHasComponentPermission('Pos', 'ACCESS') && posUserHasComponentPermission('Orders', 'MANAGE')">
                            <v-tooltip
                              color="green darken-1"
                              left
                              open-delay="1000"
                            >
                              <template #activator="{on}">
                                <v-btn
                                  :class="$t('Customer.CustomerDetailsDialog.Button.Color')"
                                  bottom
                                  dark
                                  fab
                                  fixed
                                  right
                                  small
                                  style="right: 6px; bottom: 6px;"
                                  @click="createNewCustomerOrder(selectedCustomer)"
                                  v-on="on"
                                >
                                  <v-icon>{{ $route.name !== 'Pos' ? $t('Customer.Table.Button.NewOrder.Icon') : $t('Customer.CustomerDetailsDialog.Button.SelectCustomer.Icon') }}</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $route.name !== 'Pos' ? $t('Customer.Table.Button.NewOrder.Title') : $t('Customer.CustomerDetailsDialog.Button.SelectCustomer.Title') }}</span>
                            </v-tooltip>
                          </template>

                          <template v-if="posUserHasComponentPermission('TableReservation', 'MANAGE')">
                            <v-tooltip
                              color="green darken-1"
                              left
                              open-delay="1000"
                            >
                              <template #activator="{on}">
                                <v-btn
                                  :class="$t('Customer.Table.Button.NewReservation.Color')"
                                  bottom
                                  dark
                                  fab
                                  fixed
                                  right
                                  small
                                  style="right: 52px; bottom: 6px;"
                                  @click="createNewCustomerReservation(selectedCustomer)"
                                  v-on="on"
                                >
                                  <v-icon>{{ $t('Customer.Table.Button.NewReservation.Icon') }}</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('Customer.Table.Button.NewReservation.Title') }}</span>
                            </v-tooltip>
                          </template>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <customer-edit-dialog
              :selected-customer="selectedCustomer"
              :visible.sync="customerEditDialogVisible"
            />

            <customer-company-add-dialog
              :edit-company-id.sync="editCompanyId"
              :selected-company.sync="selectedCompany"
              :selected-customer.sync="selectedCustomer"
              :visible.sync="customerAddCompanyDialogVisible"
            />

            <customer-add-edit-address-dialog
              :customer-add-edit-address-dialog-visible.sync="customerAddEditAddressDialogVisible"
              :edit-address-id.sync="editAddressId"
              :selected-address.sync="selectedAddress"
              :selected-customer.sync="selectedCustomer"
            />

            <confirm-dialog
              :html-content="$t('Customer.CustomerDetailsDialog.Card.Address.Delete.Content')"
              :html-title="$t('Customer.CustomerDetailsDialog.Card.Address.Delete.Title')"
              :title-class="'red white--text lighten-0'"
              :visible.sync="confirmDeleteAddressDialogVisible"
              cancel-button-event="cancel-delete-address"
              no-button-event="cancel-delete-address"
              yes-button-event="confirm-delete-address"
              @confirm-delete-address="onConfirmDeleteAddress"
              @cancel-delete-address="onCancelDeleteAddress"
            />

            <confirm-dialog
              :html-content="$t('Customer.CustomerDetailsDialog.Card.Companies.Delete.Content')"
              :html-title="$t('Customer.CustomerDetailsDialog.Card.Companies.Delete.Title')"
              :title-class="'red white--text lighten-0'"
              :visible.sync="confirmDeleteCompanyDialogVisible"
              cancel-button-event="cancel-delete-address"
              no-button-event="cancel-delete-address"
              yes-button-event="confirm-delete-address"
              @confirm-delete-address="onConfirmDeleteCompany"
              @cancel-delete-address="onCancelDeleteCompany"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import AppData                      from '../../mixins/appdata'
import Auth                         from '../../mixins/auth'
import Resizable                    from '../../mixins/resizable'
import GMapAddressValidate          from '../../mixins/gmapaddressvalidate'
import CustomerAddEditAddressDialog from './CustomerAddEditAddressDialog.vue'
import CustomerEditDialog           from './CustomerEditDialog.vue'
import TopProductsChart             from './charts/TopProductsChart.vue'
import ConfirmDialog                from '../../components/common/ConfirmDialog.vue'
import ElevatedCard                 from '../../components/common/ElevatedCard.vue'
import OrdersVList                  from '../orders/common/OrdersVList'
import SocketCommand                from '@/api/SocketCommand'
import { hexToRGB }                 from '@/lib/color'
import CustomerCommon               from '@/mixins/customers/customerCommon'
import { emailIsGuest }             from '@/lib/helper/helper'
import CustomerCompanyAddDialog     from '@/components/customers/CustomerCompanyAddDialog.vue'

export default {
  components: {
    CustomerCompanyAddDialog,
    OrdersVList,
    'confirm-dialog'                  : ConfirmDialog,
    'customer-edit-dialog'            : CustomerEditDialog,
    'customer-add-edit-address-dialog': CustomerAddEditAddressDialog,
    'elevated-card'                   : ElevatedCard,
    'top-products-chart'              : TopProductsChart
  },
  mixins: [AppData, Auth, Resizable, GMapAddressValidate, CustomerCommon],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    customerId: {
      type   : [Number, String],
      default: undefined
    }
  },
  data () {
    return {
      detailsTab                         : 'tab-customer',
      customerEditDialogVisible          : false,
      customerAddCompanyDialogVisible    : false,
      customerAddEditAddressDialogVisible: false,
      confirmDeleteAddressDialogVisible  : false,
      confirmDeleteCompanyDialogVisible  : false,
      selectedOrder                      : null,
      selectedCustomer                   : null,
      selectedAddress                    : null,
      selectedCompany                    : null,
      editAddressId                      : null,
      editCompanyId                      : null,
      topProductsChartData               : null,
      topProductsChartOptions            : {
        responsive         : true,
        maintainAspectRatio: false,
        legend             : {
          display : true,
          position: 'top',
          labels  : {
            boxWidth: 12
          }
        },
        pieceLabel: {
          // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          render: function (args) {
            // args will be something like:
            // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
            return args.value
            // return object if it is image
            // return { src: 'image.png', width: 16, height: 16 };
          }, // show the real calculated percentages from the values and don't apply the additional logic to fit the percentages to 100 in total, default is false
          showActualPercentages: false, // draw label even it's overlap, default is false
          overlap              : false, // draw label in arc, default is false
          arc                  : false, // precision for percentage, default is 0
          precision            : 2, // identifies whether or not labels of value 0 are displayed, default is false
          showZero             : false, // position to draw label, available value is 'default', 'border' and 'outside'. default is 'default'
          position             : 'border', // font size, default is defaultFontSize
          fontSize             : 12, // font style, default is defaultFontStyle
          fontStyle            : 'bold', // font family, default is defaultFontFamily
          fontFamily           : '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif', // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
          fontColor            : function (data) {
            let rgb = data.dataset.backgroundColor[data.index]
            rgb = {
              r: rgb.replace(/[^\d,]/g, '').split(',')[0],
              g: rgb.replace(/[^\d,]/g, '').split(',')[1],
              b: rgb.replace(/[^\d,]/g, '').split(',')[2]
            }
            const threshold = 170
            const luminance = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b
            return luminance > threshold ? 'black' : 'white'
          }
        },
        tooltips: {
          callbacks: {
            // eslint-disable-next-line
            title: (tooltipItem, data) => {
              return this.$t('Order.SelectOrderDialog.Toolbar.Title')
            }
          }
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    statsWeek () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.week.current.total_orders)
      return val || 0.0
    },
    salesWeek () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.week.current.total_sales)
      return val || 0.0
    },
    statsPrevWeek () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.week.previous.total_orders)
      return val || 0.0
    },
    salesPrevWeek () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.week.previous.total_sales)
      return val || 0.0
    },
    statsMonth () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.month.current.total_orders)
      return val || 0.0
    },
    salesMonth () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.month.current.total_sales)
      return val || 0.0
    },
    statsPrevMonth () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.month.previous.total_orders)
      return val || 0.0
    },
    salesPrevMonth () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.month.previous.total_sales)
      return val || 0.0
    },
    statsAll () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.year.all.total_orders)
      return val || 0.0
    },
    salesAll () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.year.all.total_sales)
      return val || 0.0
    },
    statsYear () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.year.current.total_orders)
      return val || 0.0
    },
    salesYear () {
      if (!this.selectedCustomer) return 0.0
      const val = parseFloat(this.selectedCustomer.Stats.year.current.total_sales)
      return val || 0.0
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.$bus.$on(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        this.$bus.$on(window.SocketCommand.Order.Get, this.setOrderDetails)
        this.$bus.$on(window.SocketCommand.Customer.Get, this.setCustomerDetails)
        this.getCustomerDetails()
      } else {
        this.$bus.$off(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
        this.$bus.$off(window.SocketCommand.Order.Get, this.setOrderDetails)
        this.$bus.$off(window.SocketCommand.Customer.Get, this.setCustomerDetails)
        this.selectedOrder = null
        this.selectedCustomer = null
        this.selectedAddress = null
        this.editAddressId = null
        this.topProductsChartData = null
        this.detailsTab = 'tab-customer'
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Customer.Save, this.onCustomerSaveResult)
    this.$bus.$off(window.SocketCommand.Order.Get, this.setOrderDetails)
    this.$bus.$off(SocketCommand.Customer.Get, this.setCustomerDetails)
  },
  methods: {
    emailIsGuest,
    getCustomerDetails () {
      if (!this.customerId) return
      this.loading = true
      window.callAS(window.SocketCommand.Customer.Get, { id: this.customerId })
    },
    setCustomerDetails (details) {
      if (!details) return

      const chartColors = [
        '#2ECC40',
        '#B10DC9',
        '#0074D9',
        '#FF4136',
        '#3D9970',
        '#FF851B',
        '#F012BE',
        '#FFDC00',
        '#85144b',
        '#39CCCC',
        '#01FF70'
      ].map(c => hexToRGB(c))

      const chartLabels = []
      const chartDataSet = []

      if (details.Stats.top_products) {
        for (let i = 0; i < details.Stats.top_products.length; i++) {
          chartDataSet.push(details.Stats.top_products[i].total_orders)
          chartLabels.push(details.Stats.top_products[i].menu_name)
        }
      }
      this.topProductsChartData = {
        datasets: [{
          data           : chartDataSet,
          backgroundColor: chartColors,
          borderColor    : this.isDarkMode ? '#212121' : '#FFFFFF'
        }],
        labels: chartLabels
      }

      if (!details?.Companies) details.Companies = []

      this.$set(this, 'selectedCustomer', details)
      this.loading = false
    },

    getOrderDetails (orderItem) {
      this.selectedOrder = orderItem
      window.callAS(window.SocketCommand.Order.Get, {
        order_id : orderItem.Id,
        status_id: orderItem.StatusId
      })
    },
    setOrderDetails (data) {
      if (this.selectedOrder) {
        this.selectedOrder.Details = data
        this.$bus.$emit('show-order-details-dialog', this.selectedOrder)
      }
    },

    showCustomerEditDialog () {
      if (this.selectedCustomer) {
        this.customerEditDialogVisible = true
      }
    },

    showCustomerAddCompanyDialog (company) {
      if (company) {
        this.editCompanyId = company.Id
        this.selectedCompany = company
      } else {
        this.editCompanyId = null
        this.selectedCompany = null
      }
      this.customerAddCompanyDialogVisible = true
    },

    showCustomerAddEditAddressDialog (address) {
      if (address) {
        this.editAddressId = address.Id
        this.selectedAddress = address
      } else {
        this.editAddressId = null
        this.selectedAddress = null
      }
      this.customerAddEditAddressDialogVisible = true
    },

    showConfirmDeleteCompanyDialog (company) {
      if (company) {
        this.editCompanyId = company.Id
        this.selectedCompany = company
        this.confirmDeleteCompanyDialogVisible = true
      }
    },

    showConfirmDeleteAddressDialog (address) {
      if (address) {
        this.editAddressId = address.Id
        this.selectedAddress = address
        this.confirmDeleteAddressDialogVisible = true
      }
    },

    createNewCustomerReservation (customer) {
      if (!customer) return

      this.$bus.$emit('show-table-reservation-dialog', {
        Customer: customer
      })
    },

    createNewCustomerOrder (customer) {
      if (!customer) return

      this.isVisible = false
      if (this.$route.name !== 'Pos') {
        this.$router.push({
          name  : 'Pos',
          params: {
            orderId   : '0',
            orderType : '1',
            customerId: customer.Info.Id
          }
        })
      } else {
        window.callAS(window.SocketCommand.Customer.Get, { id: customer.Info.Id }, window.SocketCommand.Customer.Get + '-voip')
      }
    },
    onConfirmDeleteAddress () {
      const addressIndex = this.selectedCustomer.Addresses.indexOf(this.selectedAddress)
      if (addressIndex > -1) this.selectedCustomer.Addresses.splice(addressIndex, 1)

      this.saveCustomer(this.selectedCustomer)
    },

    onConfirmDeleteCompany () {
      const index = this.selectedCustomer.Companies.indexOf(this.selectedCompany)
      if (index > -1) this.selectedCustomer.Companies.splice(index, 1)

      this.saveCustomer(this.selectedCustomer)
    },

    saveCustomer (customer) {
      this.loading = true

      const payload = {
        ...JSON.parse(JSON.stringify(customer.Info)),
        Addresses: JSON.parse(JSON.stringify(customer.Addresses || [])),
        Companies: JSON.parse(JSON.stringify(customer.Companies || []))
      }

      window.callAS(window.SocketCommand.Customer.Save, payload)
    },
    onCancelDeleteAddress () {
      this.editAddressId = null
      this.selectedAddress = null
    },
    onCancelDeleteCompany () {
      this.editCompanyId = null
      this.selectedCompany = null
    },
    onCustomerSaveResult (result) {
      if (result.status === 'success') {
        this.getCustomerDetails()
      }
      this.loading = false
    },
    onResize () {
      const customerDetailsContainer = document.getElementById('customerDetailsContainer')
      if (!customerDetailsContainer) return

      const containerPadding = parseInt(window.getComputedStyle(customerDetailsContainer, null).getPropertyValue('padding-top')) * 3

      const statsCardHeight = document.getElementById('statsCard') ? document.getElementById('statsCard').offsetHeight : 0

      const customerDetailsCard = document.getElementById('customerDetailsCard')
      const customerDetailsCardTitle = document.getElementById('customerDetailsCardTitle')
      const customerDetailsCardText = customerDetailsCard?.getElementsByClassName('v-card__text')[0]
      const customerDetailsCardTitleHeight = customerDetailsCardTitle?.offsetHeight ? customerDetailsCardTitle?.offsetHeight : 0

      const customerAddressCard = document.getElementById('customerAddressCard')
      const customerAddressCardHeight = customerAddressCard?.offsetHeight ? customerAddressCard.offsetHeight : 0

      const customerOrdersCard = document.getElementById('customerOrdersCard')
      const customerOrdersCardText = customerOrdersCard?.getElementsByClassName('v-card__text')[0]

      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0

      if (customerDetailsCardText) {
        customerDetailsCardText.style.height = this.$vuetify.breakpoint.smAndDown ? 'auto' : (innerHeight - headerHeight - footerHeight - customerDetailsCardTitleHeight - statsCardHeight - containerPadding) + 'px'
      }
      if (customerOrdersCardText) {
        customerOrdersCardText.style.height = this.$vuetify.breakpoint.smAndDown ? customerOrdersCardText.style.height = 'auto' : (innerHeight - headerHeight - footerHeight - customerAddressCardHeight - customerDetailsCardTitleHeight - statsCardHeight - containerPadding) + 'px'
      }
    }
  }
}
</script>
