<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Qr.AddEditDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Qr.AddEditDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!itemToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Qr.AddEditDialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Qr.AddEditDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Qr.AddEditDialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Qr.AddEditDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="qrForm"
            v-model="qrFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editQr.Data"
                    :counter="100"
                    :hint="$t('Qr.AddEditDialog.Field.Data.Hint')"
                    :label="$t('Qr.AddEditDialog.Field.Data.Label')"
                    :prepend-icon="$t('Qr.AddEditDialog.Field.Data.Icon')"
                    :rules="validationRules.qrForm.Data"
                    class="pr-2"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="editQr.Type"
                    :hint="$t('Qr.AddEditDialog.Field.Type.Hint')"
                    :items="qrTypes"
                    :label="$t('Qr.AddEditDialog.Field.Type.Label')"
                    :prepend-icon="$t('Qr.AddEditDialog.Field.Type.Icon')"
                    :rules="validationRules.qrForm.Type"
                    class="pr-2"
                    item-text="Title"
                    item-value="Enum"
                    required
                    single-line
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>

        <div
          v-if="loading"
          style="position: absolute; display: flex; align-items: center; justify-content: center; left: 0px; top: 0px; width: 100%; height: 100%; background-color: #000000; opacity: 0.65; z-index: 99999; text-align: center;"
        >
          <v-icon
            class="custom-loader"
            dark
            size="128"
          >
            cached
          </v-icon>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'QrAddDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading    : false,
      qrFormValid: false,
      newQr      : null,
      tmpQr      : {
        Id         : null,
        Data       : '',
        Type       : '',
        RedirectUrl: null,
        Options    : null
      },
      validationRules: {
        qrForm: {
          Data: [
            (v) => v && /^[A-Za-zΑ-Ωα-ωίϊΐόάέύϋΰήώΊΪΌΆΈΎΫΉΏ0-9 /-:]*$/.test(v) || this.$t('Restaurant.TableAddDialog.Field.Name.Error.Regex'),
            (v) => v && v.length >= 2 && v.length <= 100 || this.$t('Qr.AddEditDialog.Field.Data.Error.Between')
          ],
          Type: [
            (v) => v && v.length > 0 || this.$t('Qr.AddEditDialog.Field.Type.Error.Required')
          ]
        }
      }
    }
  },
  computed: {
    qrTypes () {
      return Object.values(this.$t('Qr.Type')).filter(type => parseInt(type.Id) > 0)
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editQr: {
      get () {
        if (this.itemToEdit) {
          this.newQr = JSON.parse(JSON.stringify(this.itemToEdit))
        } else {
          this.newQr = JSON.parse(JSON.stringify(this.tmpQr))
        }

        return this.newQr
      }
    }
  },
  watch: {
    visible (newVal) {
      this.loading = false
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.qrForm) this.$refs.qrForm.resetValidation()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.QRCode.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.QRCode.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.qrForm.validate()

      if (this.qrFormValid) {
        this.loading = true
        const payload = JSON.parse(JSON.stringify(this.editQr))
        window.callAS(window.SocketCommand.QRCode.Save, payload)
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data && data.status) {
        this.onCancelButtonClick()
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Qr.AddEditDialog.Notification.Update'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      if (this.$refs.qrForm) this.$refs.qrForm.resetValidation()
      this.newQr = null
      this.isVisible = false
    }
  }
}
</script>

<style scoped>

</style>
