<template>
  <v-container
    class="pl-0 py-0 fill-height"
    fluid
  >
    <v-row
      class="fill-height"
      dense
    >
      <v-col
        cols="12"
        class="fill-height pb-0"
        :class="{'pt-0': isTableOrder && !$vuetify.breakpoint.xsOnly}"
      >
        <div
          id="categoriesList"
          class="fill-height"
          :class="[isDarkMode ? 'grey darken-4' : 'grey darken-3']"
          style="overflow-x: hidden; overflow-y: auto;"
        >
          <div
            class="fill-height"
            :class="[isDarkMode ? 'grey darken-4' : 'grey darken-3']"
          >
            <v-list
              :one-line="$vuetify.breakpoint.xsOnly"
              :two-line="$vuetify.breakpoint.smAndUp"
              class="ma-0 pa-0"
              dark
              dense
            >
              <template v-for="(item, index) in activeCategories">
                <v-list-item
                  :key="`category-${item.Id}-${index}`"
                  :class="[getCategoryColorClass(item)]"
                  :style="getCategoryColorStyle(item)"
                  ripple
                  @click="$emit('on-select-category', item)"
                >
                  <span
                    v-if="item.Color"
                    :style="`background-color: #${item.Color}; width: ${menuCategoriesColorWidthPos}px;`"
                    style="position: absolute; height: 100%; margin-left: -16px; padding: 0"
                  />
                  <v-list-item-content :class="[activeCategory && parseInt(activeCategory.Id) === parseInt(item.Id) ? 'grey--text text--darken-3' : 'grey--text text--darken-0']">
                    <v-list-item-title
                      :style="activeCategory && parseInt(activeCategory.Id) === parseInt(item.Id) ? `color: ${invertColor(`#${item.Color}`, true)} !important;` : ''"
                      class="text-center"
                    >
                      {{ getTranslatedField(item, 'Name') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="'divider_' + item.Id" />
              </template>
            </v-list>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Translatable from '@/mixins/translatable'
import PosOrder     from '@/mixins/pos/posOrder'

export default {
  name      : 'PosCatalogCategories',
  components: {},
  directives: {},
  mixins    : [Translatable, PosOrder],
  props     : {
    activeCategories: {
      type   : Array,
      default: undefined
    },
    activeCategory: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    menuCategoriesColorWidthPos () {
      return this.appConfig?.LOCATION_DATA?.MenuCategoriesColorWidthPos ?? 5
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getCategoryColorClass (item) {
      if (parseInt(this.activeCategory?.Id) === parseInt(item.Id)) return ''

      return this.isDarkMode ? 'grey darken-4' : 'grey darken-3'
    },
    getCategoryColorStyle (item) {
      // `background-color: #${item.Color};` : ''
      if (parseInt(this.activeCategory?.Id) === parseInt(item.Id)) return `background-color: #${ item.Color }`

      return ''
    },

    invertColor (hex, bw) {
      if (!hex) return '#FFFFFF'

      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      if (hex.length !== 6) {
        // eslint-disable-next-line no-console
        console.warn('Invalid HEX color.')
        return '#FFFFFF'
      }
      let r = parseInt(hex.slice(0, 2), 16)
      let g = parseInt(hex.slice(2, 4), 16)
      let b = parseInt(hex.slice(4, 6), 16)
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#323232'
          : '#FFFFFF'
      }
      // invert color components
      r = (255 - r).toString(16)
      g = (255 - g).toString(16)
      b = (255 - b).toString(16)
      // pad each with zeros and return
      return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
    },

    padZero (str, len) {
      len = len || 2
      const zeros = new Array(len).join('0')
      return (zeros + str).slice(-len)
    }
  }
}
</script>

<style scoped>

</style>
