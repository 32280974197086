export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [],
  directives: {},
  watch     : {},
  methods   : {
    getReservationStatusById (statusId) {
      return this.reservationStatuses.find(status => parseInt(status.Id) === parseInt(statusId))
    }
  },
  computed: {
    reservationStatuses () {
      return this.$t('Reservation.Status.Items')
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {}
}
