<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12">
        <table class="divTable text-caption">
          <tr class="divTableHeading">
            <th class="divTableHead text-left">
              #
            </th>

            <th class="divTableHead text-right">
              {{ $t('Order.OrderDetailsDialog.Transaction.Amount') }}
            </th>

            <th class="divTableHead text-center">
              {{ $t('Order.OrderDetailsDialog.Transaction.PaymentType') }}
            </th>

            <template v-if="appHasReceiptsEnabled && appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD">
              <th class="divTableHead text-left">
                {{ $t('Order.OrderDetailsDialog.Transaction.Terminal') }}
              </th>

              <th
                class="divTableHead text-left"
                style="width: 85px;"
              >
                #
              </th>

              <th
                class="divTableHead text-center"
                style="width: 100px;"
              >
                {{ $t('Order.OrderDetailsDialog.Transaction.Action') }}
              </th>

              <th
                class="divTableHead text-center"
                style="width: 100px;"
              >
                {{ $t('Order.OrderDetailsDialog.Transaction.Status') }}
              </th>

              <th
                class="divTableHead text-right"
                style="width: 130px;"
              >
                {{ $t('Order.OrderDetailsDialog.Transaction.Date') }}
              </th>

              <th class="divTableHead text-right" />
            </template>

            <template v-else>
              <th class="divTableHead text-right" />
            </template>
          </tr>

          <template v-for="(transaction, index) in transactionsList">
            <tr
              :key="`transaction-${transaction.Id}-${index}`"
              class="divTableRow"
            >
              <td
                class="divTableCell text-left text-no-wrap"
                :class="{'no-border': transaction.historyVisible}"
              >
                {{ transaction.Id }}
                <div
                  v-if="transaction.OriginalTransactionId"
                  class="grey--text"
                >
                  #{{ transaction.OriginalTransactionId }}
                </div>
              </td>

              <td
                class="divTableCell text-right text-no-wrap"
                :class="{'no-border': transaction.historyVisible}"
              >
                <span v-if="isNegativeTransaction(transaction)">-</span>{{ transaction.Amount | currency }}
              </td>

              <td
                class="divTableCell text-center text-no-wrap"
                :class="{'no-border': transaction.historyVisible}"
              >
                <template v-for="(payment, idx) in appConfig.PAYMENT_TYPES">
                  <v-tooltip
                    v-if="transaction.PaymentType === payment.code"
                    :key="`payment-transaction-${index}-${idx}`"
                    :color="payment.color2"
                    right
                  >
                    <template #activator="{on}">
                      <v-icon
                        :color="payment.color1"
                        class="mx-1"
                        size="20"
                        v-on="on"
                      >
                        {{ payment.code === PaymentTypeEnum.CASH ? appCurrency.Icon : payment.icon }}
                      </v-icon>
                    </template>

                    <span>
                      {{ transaction.Amount | currency }} {{ payment.code === PaymentTypeEnum.CASH ? $t('Order.PaymentMethod.Cash').Title.toLocaleUpperCase($i18n.locale) : payment.code === PaymentTypeEnum.CARD ? $t('Order.PaymentMethod.Card').Title.toLocaleUpperCase($i18n.locale) : payment.name.toLocaleUpperCase($i18n.locale) }}
                    </span>
                  </v-tooltip>
                </template>
              </td>

              <template v-if="appHasReceiptsEnabled && appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD">
                <td
                  class="divTableCell text-left text-no-wrap"
                  :class="{'no-border': transaction.historyVisible}"
                >
                  <span class="font-weight-bold">{{ transaction.Nickname }}</span>
                  <div
                    v-if="transaction.TerminalId"
                    class="text-caption grey--text"
                  >
                    #{{ transaction.TerminalId }}
                  </div>
                </td>

                <td
                  class="divTableCell text-left text-no-wrap"
                >
                  {{ getTransactionStateField(transaction, 'Id') ? `#${ getTransactionStateField(transaction, 'Id') }` : '' }}
                </td>

                <td
                  class="divTableCell text-center text-no-wrap"
                >
                  <template v-if="getTransactionStateField(transaction, 'Type')">
                    <v-chip
                      label
                      dark
                      x-small
                      style="min-width: 85px;"
                      class="px-2 justify-center font-weight-bold"
                      :color="getTransactionStateField(transaction, 'Status') === 'APPROVED' ? getTransactionStateField(transaction, 'Type') === 'SALE' ? 'blue' : 'purple' : 'red'"
                    >
                      {{ $t(`Order.OrderDetailsDialog.Transaction.Type.${ capitalize(getTransactionStateField(transaction, 'Type')) }`).toLocaleUpperCase($i18n.locale) }}
                    </v-chip>
                  </template>
                </td>

                <td
                  class="divTableCell text-center text-no-wrap"
                >
                  <template v-if="getTransactionStateField(transaction, 'Status')">
                    <v-icon
                      v-if="getTransactionStateField(transaction, 'Status') === 'APPROVED'"
                      color="success"
                      small
                    >
                      mdi-check-circle-outline
                    </v-icon>

                    <v-icon
                      v-else
                      color="error"
                      small
                    >
                      mdi-close-circle-outline
                    </v-icon>
                  </template>
                </td>

                <td
                  class="divTableCell text-right text-no-wrap"
                >
                  {{ getTransactionStateField(transaction, 'UpdatedAt') }}
                </td>

                <td
                  class="divTableCell text-right text-no-wrap"
                  :class="{'no-border': transaction.historyVisible}"
                >
                  <v-btn
                    v-if="appHasReceiptsEnabled && appHasReceiptsProvider && getTransactionHistory(transaction).length"
                    icon
                    x-small
                    @click="toggleTransactionHistory(transaction)"
                  >
                    <v-icon>mdi-history</v-icon>
                  </v-btn>
                </td>
              </template>

              <template v-else>
                <th
                  class="divTableHead text-right"
                  style="width: 55%;"
                />
              </template>
            </tr>

            <template v-if="appHasReceiptsEnabled && appHasReceiptsProvider && appReceiptsProviderType === ReceiptProviderTypeEnum.CLOUD">
              <v-slide-y-transition :key="`transaction-transition-history-${transaction.Id}-${index}`">
                <tr
                  v-show="transaction.historyVisible"
                  :key="`transaction-history-${transaction.Id}-${index}`"
                  class="divTableRow"
                >
                  <td class="divTableCell" />
                  <td class="divTableCell" />
                  <td class="divTableCell" />
                  <td class="divTableCell" />
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    <table class="divTable">
                      <template v-for="(history, historyIndex) in getTransactionHistory(transaction)">
                        <tr
                          :key="`transaction-${transaction.Id}-${index}-history-${historyIndex}`"
                          class="divTableRow"
                        >
                          <td
                            class="divTableCell dense text-left text-no-wrap"
                            style="width: 85px;"
                          >
                            #{{ history.Id }}
                          </td>

                          <td
                            class="divTableCell dense text-center text-no-wrap"
                            style="width: 100px;"
                          >
                            <v-chip
                              label
                              dark
                              x-small
                              style="min-width: 85px;"
                              class="px-2 justify-center font-weight-bold"
                              :color="history.Status === 'APPROVED' ? history.Type === 'SALE' ? 'blue' : 'purple' : 'red'"
                            >
                              {{ $t(`Order.OrderDetailsDialog.Transaction.Type.${ capitalize(history.Type) }`).toLocaleUpperCase($i18n.locale) }}
                            </v-chip>
                          </td>

                          <td
                            class="divTableCell dense text-center text-no-wrap"
                            style="width: 100px;"
                          >
                            <template v-if="history.Status">
                              <v-icon
                                v-if="history.Status === 'APPROVED'"
                                color="success"
                                small
                              >
                                mdi-check-circle-outline
                              </v-icon>

                              <v-icon
                                v-else
                                color="error"
                                small
                              >
                                mdi-close-circle-outline
                              </v-icon>
                            </template>
                          </td>

                          <td
                            class="divTableCell dense text-right text-no-wrap"
                            style="width: 130px;"
                          >
                            {{ history.UpdatedAt }}
                          </td>
                        </tr>
                      </template>
                    </table>
                  </td>
                  <td class="divTableCell" />
                </tr>
              </v-slide-y-transition>
            </template>
          </template>

          <tr
            class="divTableRow text-caption font-weight-bold"
            :class="isDarkMode ? 'grey darken-3' : 'grey lighten-4'"
          >
            <td class="divTableCell text-left text-no-wrap text-subtitle-2">
              {{ $t('Order.OrderDetailsDialog.Transaction.Total') }}
            </td>

            <td class="divTableCell text-right text-no-wrap text-subtitle-2">
              {{ transactionsTotal | currency }}
            </td>

            <td
              class="divTableCell"
              colspan="8"
            />
          </tr>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                    from '@/mixins/auth'
import AppData                 from '@/mixins/appdata'
import PaymentType             from '@/mixins/orders/paymentType'
import PaymentTypeEnum         from '@/api/Enums/PaymentTypeEnum'
import { clone }               from '@/lib/helper/helper'
import capitalize              from '../../../filters/string/capitalize'
import ReceiptProviderTypeEnum from '@/api/Enums/ReceiptProviderTypeEnum'

export default {
  name      : 'OrderPaymentTransactionTable',
  components: {},
  directives: {},
  mixins    : [Auth, AppData, PaymentType],
  props     : {
    transactions: {
      type   : Array,
      default: () => []
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    ReceiptProviderTypeEnum () {
      return ReceiptProviderTypeEnum
    },
    PaymentTypeEnum () {
      return PaymentTypeEnum
    },

    transactionsList () {
      return this.transactions || []
    },

    transactionsCount () {
      return this.transactionsList?.length || 0
    },

    transactionsTotal () {
      return this.transactionsList
        .filter(transaction => !this.isNegativeTransaction(transaction))
        .reduce((a, b) => a + parseFloat(b.Amount) || 0, 0) || 0
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    capitalize,
    getTransactionHistory (transaction) {
      const arr = clone(transaction?.History || [])
      arr.pop()
      return arr.reverse()
    },

    getTransactionStateFromHistory (transaction) {
      const history = clone(transaction?.History || [])
      return history?.at(-1) || null
    },

    getTransactionStateField (transaction, fieldName = 'Id') {
      const state = this.getTransactionStateFromHistory(transaction)
      return state && state[fieldName] || ''
    },

    toggleTransactionHistory (transaction) {
      this.$set(transaction, 'historyVisible', !transaction?.historyVisible)
    },

    isNegativeTransaction (transaction) {
      return (!!transaction?.History?.length && (this.getTransactionStateField(transaction, 'Status') !== 'APPROVED' || (this.getTransactionStateField(transaction, 'Status') === 'APPROVED' && this.getTransactionStateField(transaction, 'Type') === 'REFUND'))) || transaction.DeletedAt
    }
  }
}
</script>

<style scoped>

/deep/ .v-speed-dial__list {
  padding : 0;
}

.divTableCell.no-border {
  border-bottom : 0 !important;
}

.divTableCell.dense, .divTableHead.dense {
  /*padding : 4px;*/
}
</style>
