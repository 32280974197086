import AppData        from '../../mixins/appdata'
import SourceTypeEnum from '@/api/Enums/SourceTypeEnum'

export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [AppData],
  directives: {},
  watch     : {},
  methods   : {
    isQrOrder (Order) {
      return Order ? this.qrSourceTypeIds.includes(parseInt(Order.SourceTypeId)) : false
    },
    getOrderSourceTypeById (id) {
      return this.allSourceTypes.find(sourceType => parseInt(sourceType.source_type_id) === parseInt(id))
    },
    getActiveOrderSourceTypeById (id) {
      return this.activeSourceTypes.find(sourceType => parseInt(sourceType.Id) === parseInt(id))
    },
    getOrderSourceTypeLangById (id) {
      return Object.values(this.defaultSourceTypesLang).find(sourceType => parseInt(sourceType.Id) === parseInt(id))
    }
  },
  computed: {
    defaultSourceTypesLang () {
      return this.$t('Order.SourceType.Default')
    },
    defaultSourceTypes () {
      const sourceTypes = this.appConfig.SOURCE_TYPES.DEFAULT
      const sourceTypesSorted = Object.fromEntries(Object.entries(sourceTypes).map(obj => {
        obj[1].order = this.getOrderSourceTypeLangById(obj[1].source_type_id)?.Order || 0
        return obj
      }).sort(([, a], [, b]) => a.order - b.order))
      return sourceTypesSorted
    },
    otherSourceTypes () {
      return this.appConfig.SOURCE_TYPES.OTHER
    },
    allSourceTypes () {
      const arr = []
      Object.keys(this.defaultSourceTypes).forEach((key) => {
        arr.push(this.defaultSourceTypes[key])
      })
      Object.keys(this.otherSourceTypes).forEach((key) => {
        arr.push(this.otherSourceTypes[key])
      })
      return arr
    },
    activeSourceTypesAll () {
      let sourceTypesAll = this.appConfig?.SOURCE_TYPES?.DEFAULT?.ALL || {}

      sourceTypesAll = [{
        Id         : sourceTypesAll.source_type_id,
        Name       : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Title || sourceTypesAll.title,
        Description: this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Desc || sourceTypesAll.desc,
        Avatar     : {
          Icon : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Icon || sourceTypesAll.icon,
          Color: 'green'
        }
      }]

      return [...sourceTypesAll, ...this.activeSourceTypes]
    },
    activeSourceTypesGroupsWithGroups () {
      let sourceTypesAll = this.appConfig?.SOURCE_TYPES?.DEFAULT?.ALL || {}

      sourceTypesAll = [
        {
          Id         : sourceTypesAll.source_type_id,
          Name       : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Title || sourceTypesAll.title,
          Description: this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Desc || sourceTypesAll.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(sourceTypesAll.source_type_id)?.Icon || sourceTypesAll.icon,
            Color: 'green'
          }
        },
        {
          Id         : SourceTypeEnum.POS_GROUP,
          Name       : 'POS',
          Description: 'All Pos Order Sources',
          Avatar     : {
            Icon : 'shopping_cart',
            Color: 'green'
          }
        },
        {
          Id         : SourceTypeEnum.ONLINE_GROUP,
          Name       : 'ONLINE',
          Description: 'All Online Order Sources',
          Avatar     : {
            Icon : 'language',
            Color: 'green'
          }
        },
        {
          Id         : SourceTypeEnum.EXTERNAL_GROUP,
          Name       : 'EXTERNAL',
          Description: 'All External Order Sources',
          Avatar     : {
            Icon : 'mdi-store-marker',
            Color: 'green'
          }
        },
        { divider: true }
      ]

      return [...sourceTypesAll, ...this.activeSourceTypes]
    },
    activeSourceTypes () {
      let sourceTypesDefault = Object.values(this.appConfig?.SOURCE_TYPES?.DEFAULT || {})
      let sourceTypesOther = this.appConfig?.SOURCE_TYPES?.OTHER || []

      sourceTypesDefault = sourceTypesDefault.filter(sourceType => !sourceType.deleted_at && sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : this.getOrderSourceTypeLangById(o.source_type_id)?.Title || o.title,
          Description: this.getOrderSourceTypeLangById(o.source_type_id)?.Desc || o.desc,
          Avatar     : {
            Icon : this.getOrderSourceTypeLangById(o.source_type_id)?.Icon || o.logo,
            Color: 'blue-grey'
          }
        }
      })

      sourceTypesOther = sourceTypesOther.filter(sourceType => !sourceType.deleted_at && sourceType.active).map(o => {
        return {
          Id         : o.source_type_id,
          Name       : o.title,
          Description: o.desc,
          Avatar     : {
            Img  : o.logo,
            Color: 'blue-grey'
          }
        }
      })

      return [...sourceTypesDefault, ...sourceTypesOther]
    },

    qrSourceTypeIds () {
      return [6, 7, 8, 9]
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {}

}
