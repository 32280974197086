export default {
  data () {
    return {}
  },

  methods: {
    initThemeMode (mode = 'light') {
      this.setThemeMode(mode)

      if (window.matchMedia) {
        const query = window.matchMedia('(prefers-color-scheme: dark)')
        query.addEventListener('change', (e) => this.setThemeMode(e.matches ? 'dark' : 'light'))
      }
    },

    isSystemThemeDark () {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    },

    setThemeGlobalCssVars () {
      // const root = document.querySelector(':root')
      // root.style.setProperty('--divTableHeadingBgColor', this.$vuetify.theme.dark ? '#191919' : '#EEE')
    },

    setThemeMode (mode = 'light') {
      this.$vuetify.theme.dark = mode === 'auto' ? this.isSystemThemeDark() : mode === 'dark'
      this.setThemeGlobalCssVars()
    },

    toggleThemeDark () {
      this.setThemeMode(this.$vuetify.theme.dark ? 'light' : 'dark')
    }
  },
  watch   : {},
  computed: {},
  mounted () {},
  beforeDestroy () {}
}
