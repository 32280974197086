export default {
  Title: 'Orders | Order | Orders',

  Headers: {
    SourceType : '',
    OrderId    : '#',
    Customer   : 'CUSTOMER',
    User       : 'USER',
    Status     : 'STATUS',
    DailyCheck : 'DAILY NUM.',
    OrderNumber: 'ORDER NUM.',
    OrderType  : 'TYPE',
    Payment    : 'PAYMENT',
    Total      : 'TOTAL',
    Date       : 'DATE'
  },

  Total: {
    PartiallyPaid: 'Partially Paid',
    NoFiscalDoc  : 'Νο Fiscal Document',
    Receipt      : 'Receipt',
    Invoice      : 'Invoice'
  },

  Type: {
    Title   : 'Order Type',
    Delivery: {
      Id   : 1,
      Title: 'Delivery',
      Icon : 'directions_bike',
      Color: 'purple'
    },
    Collection: {
      Id   : 2,
      Title: 'Collection',
      Icon : 'directions_run',
      Color: 'blue darken-3'
    },
    Time: {
      Day: {
        Title : 'Day | Days',
        Suffix: 'd'
      },
      Hour: {
        Title : 'Hour | Hours',
        Suffix: 'h'
      },
      Minute: {
        Title : 'Minute | Minutes',
        Suffix: 'm'
      },
      Second: {
        Title : 'Second | Seconds',
        Suffix: 's'
      }
    }
  },

  PaymentMethod: {
    Title: 'Payment method | Payment methods',
    Card : {
      Code : 'pos_card',
      Title: 'Card',
      Icon : 'fa-credit-card',
      Color: 'blue-grey darken-2'
    },
    Cash: {
      Code : 'cod',
      Title: 'Cash',
      Icon : 'euro_symbol',
      Color: 'amber darken-4'
    }
  },

  FilterStatuses: {
    Title: 'Order Status',
    Items: {
      Received: {
        Id      : 11,
        StatusId: 11,
        Title   : 'Received'
      },
      Accepted: {
        Id      : 21,
        StatusId: 21,
        Title   : 'Accepted'
      },
      Completed: {
        Id      : 15,
        StatusId: 15,
        Title   : 'Completed'
      },
      Canceled: {
        Id       : 19,
        StatusId : 19,
        DeletedAt: false,
        Title    : 'Canceled'
      },
      Deleted: {
        Id       : -19,
        StatusId : 19,
        DeletedAt: true,
        Title    : 'Deleted'
      }
    }
  },

  Statuses: {
    NotComplete: 'Pending',
    Pending    : 'Pending',
    Received   : 'Received',
    Preparation: 'Preparation',
    Delivery   : 'Delivery',
    Completed  : 'Completed',
    Canceled   : 'Canceled',
    Deleted    : 'Deleted',
    Printed    : 'Printed',
    Accepted   : 'Accepted',

    Next: {
      Receive : 'Receive',
      Prepare : 'Prepare',
      Deliver : 'Deliver',
      Complete: 'Complete',
      Accept  : 'Accept'
    }
  },

  Status: {
    Canceled: 'Canceled | Canceled | Canceled',
    Delete  : 'Deleted'
  },

  SourceType: {
    Title  : 'Order Source',
    Default: {
      ALL: {
        Id   : 0,
        Order: 0,
        Name : 'ALL',
        Title: 'ALL',
        Desc : 'All Order Source Types',
        Icon : 'swipe_right_alt'
      },
      ONLINE: {
        Id   : 1,
        Order: 1,
        Name : 'ONLINE',
        Title: 'WEBSITE',
        Desc : 'Online Order - Website',
        Icon : 'language'
      },
      MOBILE: {
        Id   : 10,
        Order: 2,
        Name : 'MOBILE',
        Title: 'MOBILE APP',
        Desc : 'Online Order - Mobile App',
        Icon : 'smartphone'
      },
      VOIP: {
        Id   : 2,
        Order: 3,
        Name : 'VOIP',
        Title: 'PHONE',
        Desc : 'Phone Order',
        Icon : 'phone_in_talk'
      },
      TELEPHONY: {
        Id   : 3,
        Order: 4,
        Name : 'TELEPHONY',
        Title: 'PHONE',
        Desc : 'Phone Order',
        Icon : 'local_phone'
      },
      TABLE: {
        Id   : 4,
        Order: 5,
        Name : 'TABLE',
        Title: 'TABLE',
        Desc : 'Table Order',
        Icon : 'tab_unselected'
      },
      POS: {
        Id   : 5,
        Order: 6,
        Name : 'POS',
        Title: 'POS',
        Desc : 'Internal Order',
        Icon : 'shopping_cart'
      },
      MARKETPLACE: {
        Id   : 11,
        Order: 7,
        Name : 'MARKETPLACE',
        Title: 'MARKETPLACE',
        Desc : 'Marketplace Order',
        Icon : 'mdi-store-marker'
      },
      ONLINE_INTERNAL_TABLE: {
        Id   : 6,
        Order: 8,
        Name : 'ONLINE_INTERNAL_TABLE',
        Title: 'ONLINE TABLE',
        Desc : 'Online Order - Table',
        Icon : 'fas fa-border-none'
      },
      ONLINE_INTERNAL_BOOTH: {
        Id   : 7,
        Order: 9,
        Name : 'ONLINE_INTERNAL_BOOTH',
        Title: 'ONLINE BOOTH',
        Desc : 'Online Order - Booth',
        Icon : 'fas fa-store'
      },
      ONLINE_INTERNAL_ROOM: {
        Id   : 8,
        Order: 10,
        Name : 'ONLINE_INTERNAL_ROOM',
        Title: 'ONLINE ROOM',
        Desc : 'Online Order - Room',
        Icon : 'fas fa-h-square'
      },
      ONLINE_INTERNAL_SUNBED: {
        Id   : 9,
        Order: 11,
        Name : 'ONLINE_INTERNAL_SUNBED',
        Title: 'ONLINE SUNBED',
        Desc : 'Online Order - Sunbed',
        Icon : 'fas fa-umbrella-beach'
      }
    }
  },

  Print: {
    Button: {
      Title: 'Print order',
      Icon : 'print'
    },
    ButtonReportZ: {
      Title: 'REPORT Z',
      Icon : 'fa-solid fa-print'
    },
    Content: {
      DayTotal   : 'Day Total',
      Included   : 'Included',
      NotIncluded: 'Not Included',
      ByCard     : 'by @:Order.PaymentMethod.Card.Title ('
    },
    Notification       : 'The order was sent for printing!',
    NotificationReceipt: 'The receipt was sent for printing!',
    NotificationTotals : 'The report was sent for printing!'
  },

  OrdersVList: {
    ReOrder: {
      Title: 'Re-order',
      Icon : 'add_shopping_cart'
    }
  },

  OrderDetailsDialog: {
    Toolbar: {
      Subtitle: 'Details for Order #'
    },
    Footer: {
      DeliveryCost: 'Delivery cost'
    },
    Customer: {
      Name    : 'NAME:',
      Phone   : 'PHONE:',
      Comments: 'COMMENTS:',
      Email   : 'EMAIL',
      Address : {
        Title   : 'ADDRESS:',
        Street  : 'STREET:',
        City    : 'CITY:',
        PostCode: 'ZIP:',
        Floor   : 'FLOOR:',
        Bell    : 'BELL:',
        Comment : 'INSTRUCTIONS:'
      }
    },
    Transaction: {
      Title      : 'Transactions',
      Amount     : 'Amount',
      PaymentType: 'Type',
      Terminal   : 'EFT/POS',
      Action     : 'Action',
      Status     : 'Status',
      Date       : 'Date',
      Vendor     : 'Vendor',
      Total      : 'Total',
      Type       : {
        Sale  : 'Charge',
        Refund: 'Refund'
      }
    },
    Receipt: {
      Title        : 'Fiscal Documents',
      Cancel       : 'Cancel Document',
      CancelSuccess: 'The fiscal document was canceled successfully',
      ConfirmDialog: {
        Cancel: {
          Title       : 'Cancel Fiscal Document?',
          Content     : 'Are you sure you want to cancel the fiscal document?',
          Notification: 'The fiscal document cancellation is final and irreversible!'
        },
        CancelWithRefund: {
          Title       : 'Cancel Fiscal Document?',
          Content     : 'Are you sure you want to cancel the fiscal document and refund {amount} to the customer\'s card?',
          Notification: 'The fiscal document cancellation and refund is final and irreversible!'
        }
      },
      Order      : 'Order',
      Type       : 'Type',
      DailyCheck : 'Daily Num.',
      OrderNumber: 'Order Num.',
      TotalCheck : 'Total Num.',
      Amount     : 'Amount',
      RelatedDocs: 'Related Docs',
      PrintCount : 'Print Count',
      CreatedAt  : 'Date',
      Aade       : 'AADE',
      Status     : 'Status',
      Mark       : 'Mark',
      Uid        : 'UID',
      AuthCode   : 'Signature',
      Provider   : 'Provider',
      Submitted  : 'The document has been submitted to the AADE'
    }
  },

  OrdersAll: {
    Toolbar: {
      Button: {
        Title: 'New order',
        Icon : 'add_box'
      }
    },
    Filter: {
      filterOrderType: {
        Type       : 'v-btn-toggle',
        Label      : 'Order Type',
        PlaceHolder: 'All order types',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'All'
          },
          {
            Value: 1,
            Name : 'Delivery',
            Icon : 'directions_bike',
            Color: 'purple',
            Class: 'white--text'
          },
          {
            Value: 2,
            Name : 'Collection',
            Icon : 'directions_run',
            Color: 'blue darken-3',
            Class: 'white--text'
          }
        ]
      },
      filterOrderReceipt: {
        Type       : 'v-btn-toggle',
        Label      : 'Fiscal Document',
        PlaceHolder: 'All',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'All'
          },
          {
            Value: 1,
            Name : 'Yes',
            Icon : 'mdi-receipt-text-check-outline',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: 2,
            Name : 'No',
            Icon : 'mdi-receipt-text-remove',
            Color: 'purple',
            Class: 'white--text'
          }
        ]
      },
      filterHasDeliveryStaff: {
        Type       : 'v-btn-toggle',
        Label      : 'Assigned to Delivery Staff',
        PlaceHolder: 'All orders',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'All'
          },
          {
            Value: 1,
            Name : 'Yes',
            Icon : 'directions_bike',
            Color: 'purple',
            Class: 'white--text'
          },
          {
            Value: -1,
            Name : 'No',
            Icon : 'directions_bike',
            Color: 'blue darken-3',
            Class: 'white--text'
          }
        ]
      },
      filterOrderStatus: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : true,
        Label      : 'Order Status',
        PlaceHolder: 'All order statuses',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        ItemAll    : {
          Id      : 0,
          StatusId: 0,
          Name    : 'All',
          Avatar  : {
            Color: 'green',
            Icon : 'mdi-check'
          }
        },
        Items: [
          {
            Id      : 11,
            StatusId: 11,
            Name    : 'Received',
            Avatar  : {
              Color: 'grey',
              Icon : 'call_received'
            }
          },
          {
            Id      : 21,
            StatusId: 21,
            Name    : 'Accepted',
            Avatar  : {
              Color: 'green',
              Icon : 'done'
            }
          },
          {
            Id      : 13,
            StatusId: 21,
            Name    : 'Preparation',
            Avatar  : {
              Color: 'purple',
              Icon : 'room_service'
            }
          },
          {
            Id      : 14,
            StatusId: 21,
            Name    : 'Delivery',
            Avatar  : {
              Color: 'brown',
              Icon : 'directions_run'
            }
          },
          {
            Id      : 15,
            StatusId: 15,
            Name    : 'Completed',
            Avatar  : {
              Color: 'light-green',
              Icon : 'done_all'
            }
          },
          {
            Id       : 19,
            StatusId : 19,
            DeletedAt: false,
            Name     : 'Canceled',
            Avatar   : {
              Color: 'pink',
              Icon : 'close'
            }
          },
          {
            Id       : -19,
            StatusId : 19,
            DeletedAt: true,
            Name     : 'Deleted',
            Avatar   : {
              Color: 'red',
              Icon : 'delete_forever'
            }
          }
        ]
      },
      filterSourceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Order Source',
        PlaceHolder: 'All order sources',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterPaymentType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Payment Type',
        PlaceHolder: 'All payment types',
        ItemText   : 'Title',
        ItemValue  : 'Code',
        ItemDesc   : 'Description',
        Items      : []
      },
      filterStaff: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Staff',
        PlaceHolder: 'All Staff',
        ItemText   : 'StaffFullname',
        ItemValue  : 'StaffId',
        Items      : []
      },
      filterDate: {
        Type       : 'v-date-picker',
        Label      : 'Order Date',
        PlaceHolder: 'Select Date',
        Icon       : 'event'
      }
    },
    ListView: {
      Type: {
        Collection: '@:Order.Type.Collection.Title in',
        Delivery  : '@:Order.Type.Delivery.Title in'
      },
      Status: {
        DeletedAt: '@:Order.Status.Delete at'
      },
      Button: {
        Edit: {
          Title: 'Edit order',
          Icon : 'edit',
          Color: 'cyan lighten-2',
          PopUp: {
            Content: {
              Choose: 'Choose if you want to',
              Delete: 'delete',
              Or    : 'or',
              Copy  : 'copy',
              Order : 'the order and create a new one with its details to be edited.'
            },
            Button: {
              Copy  : 'Copy & Create New',
              Delete: 'Delete & Create New'
            },
            Notification: 'Discounts and tips will not be included in the creation of the order.'
          }
        },
        View: {
          Title: 'View order details',
          Icon : 'remove_red_eye',
          Color: 'cyan'
        },
        Cancel: {
          Title: 'Cancel order',
          Icon : 'close',
          Color: 'pink accent-3'
        },
        Delete: {
          Title: 'Permanently Delete Order',
          Icon : 'delete',
          Color: 'red'
        }
      }
    }
  },

  OrderChangeStatusDialog: {
    CancelOrder: {
      Title   : '@:Order.OrdersAll.ListView.Button.Cancel.Title?',
      Subtitle: {
        Order            : 'Order',
        CancelPermanently: 'will be permanently canceled.'
      }
    },
    Field: {
      Name     : '@:Common.Misc.Name:',
      Telephone: 'Telephone:',
      Time     : 'Time:',
      Total    : '@:Common.Misc.Total:'
    },
    Notification: {
      Title  : 'Order cancellation is irreversible!',
      Amount : 'The amount of',
      Refund : 'has been paid by card and it will be automatically refunded.',
      Receipt: 'A receipt has been issued for the order and will be automatically cancelled.',
      Invoice: 'A invoice has been issued for the order and will be automatically cancelled.'
    }
  },

  OrderDeleteDialog: {
    DeleteOrder: {
      Title   : 'Permanently Delete Order?',
      Subtitle: {
        Order            : 'Order',
        CancelPermanently: 'will be permanently deleted.'
      }
    },
    DeleteOrders: {
      Title   : 'Permanently Delete Orders?',
      Subtitle: 'You have selected {orders} orders | You have selected {orders} order | You have selected {orders} orders',
      Body    : 'You have selected {orders} orders for permanent deletion. | You have selected {orders} order for permanent deletion. | You have selected {orders} orders for permanent deletion.'
    },
    DeleteAllOrders: {
      Title   : 'Permanently Delete Orders?',
      Subtitle: '',
      Body    : 'Select if you want to permanently delete all or only your orders.',
      Button  : {
        YesAll : 'Delete All',
        YesMine: 'Delete Mine'
      },
      Notification: {
        Title: 'Permanent orders deletion is irreversible!'
      }
    },
    Field: {
      Name     : '@:Common.Misc.Name:',
      Telephone: 'Telephone:',
      Time     : 'Time:',
      Total    : '@:Common.Misc.Total:'
    },
    Notification: {
      Title                   : 'Permanent order deletion is irreversible!',
      Amount                  : 'The amount of',
      Refund                  : 'has been paid by card and it will <strong>ΝΟΤ be refunded</strong> automatically.',
      Receipt                 : 'A receipt has been issued for the order and will <strong>ΝΟΤ be cancelled</strong> automatically.',
      Invoice                 : 'A invoice has been issued for the order and will <strong>ΝΟΤ be cancelled</strong> automatically.',
      Tip                     : 'Otherwise, first "CANCEL" and then "DELETE" the order.',
      RefundMultiple          : 'If orders have card payments they will <strong>ΝΟΤ be refunded</strong> automatically.',
      ReceiptOrInvoiceMultiple: 'If orders have receipts or invoices issued they will <strong>ΝΟΤ be cancelled</strong> automatically.'
    }
  },

  SelectOrderDialog: {
    Toolbar: {
      Title   : 'Orders',
      Subtitle: 'Select order',
      Icon    : 'shopping_cart',
      Color   : 'indigo'
    },
    Result: {
      Button: {
        All: {
          Title: 'All',
          Icon : 'shopping_cart'
        }
      },
      NoOrder: {
        Title   : 'There are no orders',
        Subtitle: 'or they have all been assigned to delivery staff',
        Icon    : 'shopping_cart',
        Color   : 'grey lighten-2'
      }
    }
  },

  OrdersMapDialog: {
    Toolbar: {
      Title   : 'Orders Map',
      Subtitle: 'to deliver',
      Icon    : 'map',
      Color   : 'light-green',
      Button  : {
        ResetMap: {
          Title: 'Reset Map',
          Icon : 'my_location'
        },
        MapLimit: {
          Title: 'Store Delivery Areas',
          Icon : 'map'
        },
        Delivery: {
          Icon: 'directions_bike'
        },
        ReceivedOrder: {
          Title: 'Received Orders',
          Icon : 'shopping_cart'
        },
        AcceptedOrder: {
          Title: 'Accepted Orders',
          Icon : 'shopping_cart'
        },
        CompletedOrder: {
          Title: 'Completed Orders',
          Icon : 'shopping_cart'
        }
      }
    }
  },

  OrdersSaved: {
    ListView: {
      EditedBy : 'Being edited by the user',
      BelongsTo: 'Belongs to user'
    }
  },

  OrdersToday: {
    Toolbar: {
      Button: {
        Print: {
          Title       : 'Print',
          Icon        : 'print',
          Notification: 'The orders were sent for printing!'
        },
        Map: {
          Title: 'Map',
          Icon : 'map'
        },
        All: {
          Title: 'All',
          Icon : 'shopping_cart'
        }
      }
    },
    ListView: {
      Button: {
        OrderPayment: {
          Title: 'Payment Transactions',
          Icon : 'mdi-account-credit-card',
          Color: 'indigo'
        },
        AddTo: {
          Title: 'Add order to delivery staff shift',
          Icon : 'directions_bike',
          Color: 'light-green darken-1'
        },
        RemoveFrom: {
          Title: 'Remove order from delivery staff shift',
          Icon : 'directions_bike',
          Color: 'red accent-4',
          PopUp: {
            Title  : 'Remove Order?',
            Content: 'Are you sure you want to remove the order from the delivery staff shift:'
          }
        }
      }
    }
  },

  OrdersToolbar: {
    All  : 'All',
    Today: 'Today',
    Saved: 'Saved'
  }
}
