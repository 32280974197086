import axios         from 'axios'
import i18n          from '@/lang/lang'
import SocketCommand from '@/api/SocketCommand'

export const APIEndPoints = {
  API: process.env.VUE_APP_API_URL
}

export const API = axios.create({
  baseURL        : process.env.VUE_APP_API_URL || '',
  timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 60000,
  responseType   : 'json',
  crossDomain    : true,
  withCredentials: true,
  headers        : { 'X-Requested-With': 'XMLHttpRequest' }
})

// Request interceptor
API.interceptors.request.use((request) => {
  if (typeof window !== 'undefined') {
    if (request.url !== window.APICall.login) request.headers.Authorization = getAuthorizationTokenBearer()
    request.headers['X-Socket-Id'] = getSocketId()
  }
  request.headers['Accept-Language'] = i18n.locale
  request.headers['X-Referer'] = process.env.VUE_APP_API_X_REFERER || ''
  return request
}, function (error) {
  return Promise.reject(error)
})

// Response interceptor
API.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error.response && error.response.status === 401 && typeof window !== 'undefined') {
    window.Vue && window.Vue.$route.name !== 'Login' && !error.response.request.responseURL.includes('logout') && window.Vue.$bus.$emit('app-logout')
  } else if (error.message === 'Network Error' && typeof window !== 'undefined') {
    window.Vue && window.Vue.$bus.$emit('app-logout')
  } else {
    return Promise.reject(error)
  }
})

export const APICall = {
  loginSuperAdmin: 'login-sa',
  login          : 'login',
  logout         : 'logout',
  update         : 'update',
  api            : 'api',
  vatCheck       : 'vat/check',

  CreatePayload (command, data = {}, resultCommand = '', multipart = false) {
    resultCommand = resultCommand === '' ? command : resultCommand
    let payload = { multipartData: null }

    if (multipart) {
      payload.multipartData = data
      payload.multipartData.append('command', SocketCommand.commandToSocketFormat(command))
      payload.multipartData.append('result_command', SocketCommand.commandToSocketFormat(resultCommand))
      payload.multipartData.append('location_id', window && window.appConfig ? window.appConfig.LOCATION_DATA.Id : 1)
      payload.command = SocketCommand.commandToSocketFormat(command)
      payload.result_command = SocketCommand.commandToSocketFormat(resultCommand)
      payload.location_id = window && window.appConfig ? window.appConfig.LOCATION_DATA.Id : 1
    } else {
      payload = Object.assign({}, { ...data }, {
        command       : command,
        result_command: resultCommand,
        location_id   : window && window.appConfig ? window.appConfig.LOCATION_DATA.Id : 1
      })
      payload.command = SocketCommand.commandToSocketFormat(command)
      payload.result_command = SocketCommand.commandToSocketFormat(resultCommand)
    }

    return payload
  }
}

function getAuthorizationTokenBearer () {
  return window && window.authorizationTokenBearer || null
}

function getSocketId () {
  return window && window.socketId || null
}
