<template>
  <v-container
    v-if="viewData"
    class="pa-0"
    fluid
  >
    <v-row dense>
      <v-col
        class="grey--text text--darken-1 white"
        cols="12"
      >
        <div>
          <span class="grey--text text--darken-1">
            {{ $t('Common.Misc.Subtotal').toLocaleUpperCase($i18n.locale) }}
          </span>
          <span class="grey--text text--darken-1 float-right pr-2">
            {{ viewData.SCartTotal | currency }}
          </span>
        </div>
        <v-divider />
      </v-col>

      <!-- Tips -->
      <v-col
        v-if="appConfig.LOCATION_DATA.OrderTips && cartHasTip"
        class="grey--text text--darken-1 white"
        cols="12"
      >
        <template v-for="(tip, tipIndex) in viewData.Totals">
          <div
            v-if="tip.Name === 'tip_amount'"
            :key="tipIndex"
            style="float: none; clear: both; line-height: 28px;"
          >
            <span class="grey--text text--darken-1">
              {{ tip.Title }}
            </span>

            <span class="grey--text text--darken-1 float-right pr-2">
              +{{ tip.AmountFormatted | currency }}
            </span>
          </div>
        </template>
        <v-divider />
      </v-col>

      <!-- Coupons -->
      <v-col
        class="grey--text text--darken-1 white"
        cols="12"
      >
        <div
          v-if="!cartHasCoupon"
          style="float: none; clear: both; line-height: 28px;"
        >
          <span class="grey--text text--darken-1">
            {{ $t('Common.Misc.Discount').toLocaleUpperCase($i18n.locale) }}
          </span>

          <span
            v-if="!cartHasCoupon"
            class="grey--text text--darken-1 float-right pr-2"
          >
            {{ 0 | currency }}
          </span>
        </div>

        <template v-else>
          <template v-for="(total, totalIndex) in viewData.Totals">
            <div
              v-if="total.Name === 'coupon'"
              :key="totalIndex"
              style="float: none; clear: both; line-height: 28px;"
            >
              <span class="grey--text text--darken-1">
                {{ total.Title }}
              </span>

              <span class="grey--text text--darken-1 float-right pr-2">
                -{{ total.AmountFormatted | currency }}
              </span>
            </div>
          </template>
        </template>
      </v-col>

      <template v-if="viewData.Totals.length === 0">
        <v-col
          class="grey--text text--darken-1 white"
          cols="12"
        >
          <v-divider />

          <div class="py-1">
            <strong>
              <span class="grey--text text--darken-1">
                {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
              </span>

              <span class="grey--text text--darken-1 float-right pr-2">
                {{ 0 | currency }}
              </span>
            </strong>
          </div>
        </v-col>
      </template>

      <template v-else>
        <template v-for="(total, index) in viewData.Totals">
          <v-col
            v-if="total.Name === 'order_total'"
            :key="`totals-ordertotal-${index}`"
            class="grey--text text--darken-1 white"
            cols="12"
          >
            <v-divider />

            <div class="py-1">
              <strong>
                <span class="grey--text text--darken-1">
                  {{ $t('Common.Misc.Total').toLocaleUpperCase($i18n.locale) }}
                </span>

                <span class="grey--text text--darken-1 float-right pr-2">
                  {{ total.Amount | currency }}
                </span>
              </strong>
            </div>
          </v-col>
        </template>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import Auth    from '@/mixins/auth'
import AppData from '@/mixins/appdata'

export default {
  name      : 'TableOrdersTotals',
  components: {},
  directives: {},
  mixins    : [Auth, AppData],
  props     : {
    viewData: {
      type   : Object,
      default: undefined
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    cartHasCoupon () {
      return this.viewData ? !!this.viewData.Totals.find(total => total.Name === 'coupon') : false
    },

    cartHasTip () {
      return this.viewData ? !!this.viewData.Totals.find(total => total.Name === 'tip_amount') : false
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
