<template>
  <v-container
    fluid
  >
    <v-row
      v-if="isInit"
      dense
    >
      <current-plan-warning ref="currentPlan" />

      <v-col cols="12">
        <v-card>
          <v-card-text
            ref="cardBody"
            style="overflow: auto;"
          >
            <v-form
              ref="billingForm"
              v-model="billingFormValid"
              autocomplete="off"
              @submit.prevent
            >
              <v-container
                class="pa-1"
                fluid
              >
                <v-row dense>
                  <v-col
                    lg="6"
                    cols="12"
                  >
                    <v-card flat>
                      <v-toolbar
                        flat
                        class="pl-0"
                        height="70"
                      >
                        <v-avatar class="success">
                          <v-icon dark>
                            people
                          </v-icon>
                        </v-avatar>

                        <v-toolbar-title class="pl-3">
                          <div class="body-3">
                            {{ $t('Account.Billing.Misc.ContactInfo') }}
                          </div>

                          <div class="text-caption">
                            {{ $t('Account.Billing.Misc.BillingInfo') }}
                          </div>
                        </v-toolbar-title>

                        <v-spacer />
                      </v-toolbar>

                      <v-divider />

                      <v-card-text>
                        <v-container
                          class="pa-1"
                          fluid
                        >
                          <v-row dense>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Firstname"
                                :counter="128"
                                :hint="$t('Account.Billing.Field.Firstname.Hint')"
                                :label="$t('Account.Billing.Field.Firstname.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Firstname.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Firstname')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Lastname"
                                :counter="128"
                                :hint="$t('Account.Billing.Field.Lastname.Hint')"
                                :label="$t('Account.Billing.Field.Lastname.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Lastname.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Lastname')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Telephone"
                                v-mask="'################'"
                                :counter="16"
                                :hint="$t('Account.Billing.Field.Telephone.Hint')"
                                :label="$t('Account.Billing.Field.Telephone.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Telephone.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Telephone')"
                                class="pr-2"
                              />
                            </v-col>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Email"
                                :counter="96"
                                :disabled="!viewData.CustomerId"
                                :hint="$t('Account.Billing.Field.Email.Hint')"
                                :label="$t('Account.Billing.Field.Email.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Email.Icon')"
                                :readonly="!viewData.CustomerId"
                                :rules="viewData.validator.vuetifyFormFieldRules('Email')"
                                class="pr-2"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-radio-group
                                v-model="viewData.IsReceipt"
                                class="ma-0"
                                row
                              >
                                <v-radio
                                  :label="$t('Account.Billing.Field.IsReceiptToggle.Invoice')"
                                  :value="false"
                                />
                                <v-radio
                                  :label="$t('Account.Billing.Field.IsReceiptToggle.Receipt')"
                                  :value="true"
                                />
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>

                    <reseller-contact-info-card />
                  </v-col>

                  <v-col
                    lg="6"
                    cols="12"
                  >
                    <v-card flat>
                      <v-toolbar
                        flat
                        class="pl-0"
                        height="70"
                      >
                        <v-avatar class="info">
                          <v-icon dark>
                            account_balance
                          </v-icon>
                        </v-avatar>

                        <v-toolbar-title class="pl-3">
                          <div class="body-3">
                            {{ $t('Account.Billing.Misc.InvoiceInfo') }}
                          </div>

                          <div class="text-caption">
                            {{ $t('Account.Billing.Misc.BillingInfo') }}
                          </div>
                        </v-toolbar-title>

                        <v-spacer />
                      </v-toolbar>

                      <v-divider />

                      <v-card-text>
                        <v-container
                          class="pa-1"
                          fluid
                        >
                          <v-row dense>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Company"
                                :counter="100"
                                :disabled="viewData.IsReceipt"
                                :hint="$t('Account.Billing.Field.Company.Hint')"
                                :label="$t('Account.Billing.Field.Company.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Company.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Company')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Profession"
                                :counter="128"
                                :hint="$t('Account.Billing.Field.Profession.Hint')"
                                :label="$t('Account.Billing.Field.Profession.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Profession.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Profession')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="viewData.DistinctiveTitle"
                                :counter="128"
                                :hint="$t('Account.Billing.Field.DistinctiveTitle.Hint')"
                                :label="$t('Account.Billing.Field.DistinctiveTitle.Label')"
                                :prepend-icon="$t('Account.Billing.Field.DistinctiveTitle.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('DistinctiveTitle')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Irs"
                                :counter="128"
                                :hint="$t('Account.Billing.Field.Irs.Hint')"
                                :label="$t('Account.Billing.Field.Irs.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Irs.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Irs')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Vat"
                                :counter="12"
                                :hint="$t('Account.Billing.Field.Vat.Hint')"
                                :label="$t('Account.Billing.Field.Vat.Label')"
                                :prefix="locale"
                                :prepend-icon="$t('Account.Billing.Field.Vat.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Vat')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Gemi"
                                :counter="12"
                                :hint="$t('Account.Billing.Field.Gemi.Hint')"
                                :label="$t('Account.Billing.Field.Gemi.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Gemi.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Gemi')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-autocomplete
                                v-model="viewData.CountryId"
                                :hint="$t('Account.Billing.Field.Country.Hint')"
                                :items="countryList"
                                :label="$t('Account.Billing.Field.Country.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Country.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('CountryId')"
                                cache-items
                                class="pr-2"
                                item-text="short_name"
                                item-value="country_id"
                                required
                              >
                                <template #prepend>
                                  <v-avatar
                                    size="22"
                                    tile
                                  >
                                    <template v-if="locale">
                                      <v-img :src="`/assets/flags/1x1/${String(locale).toLowerCase()}.svg`" />
                                    </template>
                                    <template v-else>
                                      <v-icon>map</v-icon>
                                    </template>
                                  </v-avatar>
                                </template>
                                <template #item="data">
                                  <v-list-item-avatar size="34">
                                    <v-img :src="`/assets/flags/1x1/${String(data.item.iso2).toLowerCase()}.svg`" />
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title v-html="data.item.short_name" />
                                    <v-list-item-subtitle
                                      class="text-caption"
                                      v-html="data.item.long_name"
                                    />
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.City"
                                :counter="100"
                                :hint="$t('Account.Billing.Field.City.Hint')"
                                :label="$t('Account.Billing.Field.City.Label')"
                                :prepend-icon="$t('Account.Billing.Field.City.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('City')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.State"
                                :counter="100"
                                :hint="$t('Account.Billing.Field.State.Hint')"
                                :label="$t('Account.Billing.Field.State.Label')"
                                :prepend-icon="$t('Account.Billing.Field.State.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('State')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col
                              sm="9"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.Street"
                                :counter="100"
                                :hint="$t('Account.Billing.Field.Street.Hint')"
                                :label="$t('Account.Billing.Field.Street.Label')"
                                :prepend-icon="$t('Account.Billing.Field.Street.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('Street')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col
                              sm="3"
                              cols="12"
                            >
                              <v-text-field
                                v-model="viewData.PostCode"
                                :counter="15"
                                :hint="$t('Account.Billing.Field.PostCode.Hint')"
                                :label="$t('Account.Billing.Field.PostCode.Label')"
                                :prepend-icon="$t('Account.Billing.Field.PostCode.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('PostCode')"
                                class="pr-2"
                                required
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="viewData.CompanyPhone"
                                v-mask="'################'"
                                :counter="16"
                                :hint="$t('Account.Billing.Field.CompanyPhone.Hint')"
                                :label="$t('Account.Billing.Field.CompanyPhone.Label')"
                                :prepend-icon="$t('Account.Billing.Field.CompanyPhone.Icon')"
                                :rules="viewData.validator.vuetifyFormFieldRules('CompanyPhone')"
                                class="pr-2"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider />

          <v-card-actions
            ref="cardActions"
            class="pa-4"
          >
            <v-spacer class="hidden-xs-only" />
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              :disabled="loading"
              :loading="loading"
              class="green white--text elevation-0"
              @click="onSaveButtonClick"
            >
              {{ $t('Common.Button.Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Resizable               from '@/mixins/resizable'
import AppData                 from '@/mixins/appdata'
import Auth                    from '@/mixins/auth'
import AccountBillingModel     from '@/api/Models/account/AccountBillingModel'
import CurrentPlanWarning      from '@/components/account/CurrentPlanWarning'
import ResellerContactInfoCard from '@/components/account/ResellerContactInfoCard.vue'

export default {
  name      : 'AccountBilling',
  components: {
    ResellerContactInfoCard,
    CurrentPlanWarning
  },
  directives: {},
  mixins    : [AppData, Auth, Resizable],
  props     : {},
  data () {
    return {
      viewData        : new AccountBillingModel(),
      isInit          : false,
      countriesArray  : [],
      billingFormValid: true,
      loading         : true,
      tmpCompany      : ''
    }
  },
  computed: {
    locale () {
      const country = this.countriesArray.find(country => country.country_id === this.viewData.CountryId)
      return country ? (country.iso2 === 'GR' ? 'EL' : country.iso2 === 'GB' ? 'UK' : country.iso2) : ''
    },
    countryList () {
      // const countries = ['GR', 'CY']
      // return this.countriesArray.filter(country => countries.includes(country.iso2))
      return this.countriesArray || []
    }
  },
  watch: {
    'viewData.Firstname': function () {
      if (this.viewData.IsReceipt) this.viewData.Company = `${ this.viewData.Firstname } ${ this.viewData.Lastname }`
    },
    'viewData.Lastname': function () {
      if (this.viewData.IsReceipt) this.viewData.Company = `${ this.viewData.Firstname } ${ this.viewData.Lastname }`
    },
    'viewData.IsReceipt': function (newVal) {
      if (newVal) {
        this.tmpCompany = this.viewData.Company
        this.viewData.Company = `${ this.viewData.Firstname } ${ this.viewData.Lastname }`
      } else {
        this.viewData.Company = this.tmpCompany
        this.tmpCompany = ''
      }
    }
  },
  beforeCreate () {
  },
  created () {
    this.getBillingData()
  },
  beforeMount () {
  },
  mounted () {
    this.$bus.$on(window.SocketCommand.Account.Billing.Get, this.setBillingData)
    this.$bus.$on(window.SocketCommand.Account.Billing.Save, this.onSaveBillingResult)
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Account.Billing.Get, this.setBillingData)
    this.$bus.$off(window.SocketCommand.Account.Billing.Save, this.onSaveBillingResult)
  },
  destroyed () {
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.billingForm.validate()
      if (this.billingFormValid) {
        this.loading = true
        window.callAS(window.SocketCommand.Account.Billing.Save, { Billing: this.viewData.clone() })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveBillingResult (data) {
      if (data && data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Account.Misc.ProfileSaved'),
          type: 'success',
          icon: 'info'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: data.error || this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    getBillingData () {
      this.loading = true
      window.callAS(window.SocketCommand.Account.Billing.Get, { Id: this.posUser.staff_id })
    },

    setBillingData (data) {
      if (data && data.status === 'success') {
        this.countriesArray = data.countries
        this.viewData = new AccountBillingModel(data.data)
        this.isInit = true
      } else {
        this.isInit = true
      }
      this.loading = false
    },

    onResize () {
      const cardBody = this.$refs.cardBody
      const cardActions = this.$refs.cardActions
      const currentPlan = this.$refs.currentPlan
      const headerHeight = document.getElementById('appToolbar')?.offsetHeight ? document.getElementById('appToolbar').offsetHeight : 0
      const footerHeight = document.getElementById('appFooter')?.offsetHeight ? document.getElementById('appFooter').offsetHeight : 0
      const tabsHeight = document.getElementsByClassName('v-tabs')[0] ? document.getElementsByClassName('v-tabs')[0].offsetHeight + 16 : 48
      const cardActionsHeight = cardActions ? cardActions.offsetHeight + 10 : 98
      const currentPlanHeight = currentPlan ? currentPlan.$el.offsetHeight : 96

      if (cardBody) cardBody.style.height = (innerHeight - headerHeight - footerHeight - tabsHeight - cardActionsHeight - currentPlanHeight) + 'px'
    }
  }
}
</script>

<style scoped>
/deep/ .top10Toolbar .v-toolbar__content, /deep/ .top10Toolbar .v-toolbar__extension {
  padding : 0 !important;
}
</style>
