<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card v-if="table && area">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar color="indigo">
            <v-icon dark>
              {{ $t(`Restaurant.Tables.Dialog.${ mode }Table.Icon`) }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t(`Restaurant.Tables.Dialog.${ mode }Table.Title`) }}
            </div>

            <div class="text-caption grey--text">
              {{ $t(`Restaurant.Tables.Dialog.${ mode }Table.Subtitle`) }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-container
            class="pa-0"
            fluid
          >
            <!-- Main Table & Target Waiter -->
            <v-row dense>
              <!-- Main Table -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid

                  :class="{'pr-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.${ mode }Table.MainTable`) }}
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="pa-0"
                        fluid
                      >
                        <v-row

                          style="min-height: 85px;"
                        >
                          <v-col cols="12">
                            <table-list-tile
                              :table="table"
                              :children-tables="selectedTables"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <!-- Target Waiter -->
              <v-col
                v-if="targetWaiterIsRequired"
                cols="12"
                sm="6"
              >
                <v-container
                  class="pa-0"
                  fluid

                  :class="{'pl-2': $vuetify.breakpoint.smAndUp}"
                >
                  <v-row
                    no-gutters
                    style="min-height: 85px;"
                  >
                    <v-col cols="12">
                      <h5 class="mt-0">
                        {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.TargetWaiter`) }}
                      </h5>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="pa-0"
                        fluid
                      >
                        <v-row
                          style="min-height: 85px;"
                          dense
                        >
                          <v-col
                            v-if="targetWaiter"
                            cols="12"
                          >
                            <v-list-item
                              class="grey lighten-3"
                              ripple
                              :disabled="!canChangeWaiter"
                              style="height: 126px;"
                              @click="onSelectTargetWaiterStaff"
                            >
                              <v-list-item-avatar size="54">
                                <v-icon
                                  color="light-green"
                                  size="54"
                                >
                                  login
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content class="grey--text text--darken-2">
                                <v-list-item-title class="text-left">
                                  {{ targetWaiter.Firstname }} {{ targetWaiter.Lastname }}
                                </v-list-item-title>

                                <v-list-item-subtitle v-if="targetWaiter.Phone">
                                  <span class="grey--text">
                                    {{ targetWaiter.Phone }}
                                  </span>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle v-if="targetWaiter.Email">
                                  <span class="grey--text">
                                    {{ targetWaiter.Email }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col
                            v-else
                            cols="12"
                          >
                            <v-btn
                              outlined
                              color="grey"
                              block
                              class="ma-0"
                              style="height: 126px;"
                              @click="onSelectTargetWaiterStaff"
                            >
                              {{ $t(`Restaurant.Tables.Dialog.ChangeWaiter.SelectWaiter`).toLocaleUpperCase($i18n.locale) }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col cols="12">
                <v-divider class="my-1" />
              </v-col>
            </v-row>

            <!-- Selected Tables -->
            <v-row dense>
              <v-col cols="12">
                <h5 class="mt-4">
                  {{ $t(`Restaurant.Tables.Dialog.${ mode }Table.SelectedTables`) }}&nbsp;
                  <span class="grey--text font-weight-regular float-right">{{ selectedTables.length }} {{ $tc('Restaurant.Tables.Title', selectedTables.length) }}</span>
                </h5>
              </v-col>

              <v-col cols="12">
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row
                    style="min-height: 136px;"
                    dense
                  >
                    <template v-for="tbl in selectedTables">
                      <v-col
                        :key="`table-${tbl.Id}`"
                        md="4"
                        sm="6"
                        cols="12"
                      >
                        <table-list-tile
                          :table="tbl"
                          :disabled="isSelectedTableDisabled(tbl)"
                          @click="onSelectedTableClick"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
                <v-divider class="my-2" />
              </v-col>

              <v-col cols="12">
                <h5 class="mt-4">
                  {{ $t(`Restaurant.Tables.Dialog.${ mode }Table.AvailableTables`) }}&nbsp;
                  <span class="grey--text font-weight-regular float-right">{{ selectableTables.length }} {{ $tc('Restaurant.Tables.Title', selectableTables.length) }}</span>
                </h5>
              </v-col>
              <v-col cols="12">
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row
                    style="min-height: 120px;"
                    dense
                  >
                    <template v-for="tbl in selectableTables">
                      <v-col
                        :key="`table-${tbl.Id}`"
                        md="4"
                        sm="6"
                        cols="12"
                      >
                        <table-list-tile
                          :table="tbl"
                          :disabled="isTableSelected(tbl)"
                          @click="onTableClick"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            text
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            :disabled="loading || (isDialogModeMerge && (!selectedTables.length || (targetWaiterIsRequired && !targetWaiter))) || (isDialogModeSplit && selectedTables.length === getInitialSelectedTables().length)"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <select-waiter-staff-dialog
      :visible.sync="selectWaiterStaffDialogVisible"
      :selected-waiter-staff.sync="targetWaiter"
      :exclude-waiter-staff-ids="excludeWaiterStaffIds"
    />
  </v-row>
</template>

<script>
import TableListTile           from '@/components/restaurant/tables/tables/TableListTile'
import TableMergeSplitModeEnum from '@/api/Enums/TableMergeSplitModeEnum'
import SelectWaiterStaffDialog from '@/components/waiters/SelectWaiterStaffDialog.vue'
import TableStatusEnum         from '@/api/Enums/TableStatusEnum'
import TableCommon             from '@/mixins/tables/tableCommon'
import TableStatus             from '@/mixins/tables/tableStatus'
import PinManagerEnum          from '@/api/Enums/PinManagerEnum'

export default {
  name      : 'TableMergeSplitDialog',
  components: {
    SelectWaiterStaffDialog,
    TableListTile
  },
  mixins: [TableStatus, TableCommon],
  props : {
    table: {
      type   : Object,
      default: undefined
    },
    area: {
      type   : Object,
      default: undefined
    },
    mode: {
      type   : String,
      default: TableMergeSplitModeEnum.Merge
    },
    returnObject: {
      type   : Boolean,
      default: false
    },
    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      loading                       : false,
      targetWaiter                  : null,
      selectWaiterStaffDialogVisible: false,
      selectedTables                : []
    }
  },
  computed: {
    targetWaiterIsRequired () {
      return this.isDialogModeMerge && (this.table?.StaffId || this.selectedTables.some(table => table?.StaffId) || (this.table.StatusId !== TableStatusEnum.Available && this.selectedTables.some(table => table.StatusId !== TableStatusEnum.Available)))
    },

    excludeWaiterStaffIds () {
      return [] // this.table && this.table.StaffId ? [this.table.StaffId] : []
    },

    isDialogModeMerge () {
      return this.mode === this.TableMergeSplitModeEnum.Merge
    },
    isDialogModeSplit () {
      return this.mode === this.TableMergeSplitModeEnum.Split
    },

    selectableTables () {
      return this.tables
        .filter(table => !table.Children && !table.Parent)
        .filter(table => !this.tableCanManage(table) ? this.tableCanManage(table) || !table.StaffId : true)
    },

    tables () {
      const tables = this.area?.Tables || []

      if (this.isDialogModeMerge) {
        return tables
          .filter(table => table.Id !== this.table.Id)
          .filter(table => !this.isTableSelected(table))
      }

      return this.getInitialSelectedTables()
        .filter(table => table.Id !== this.table.Id)
    },

    TableMergeSplitModeEnum () {
      return TableMergeSplitModeEnum
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$bus.$on(window.SocketCommand.Table.Merge, this.onSaveResult)
        this.selectedTables = this.getInitialSelectedTables()

        const waiters = window.Vue?.waiterStaffItems || []
        this.targetWaiter = this.table?.StaffId ? waiters.find(waiter => parseInt(waiter.Id) === parseInt(this.table.StaffId)) || null : null
      } else {
        this.$bus.$off(window.SocketCommand.Table.Merge, this.onSaveResult)
      }
    }
  },
  methods: {
    tableCanChangeWaiter (table) {
      if (this.canManageTable) {
        if (
          this.userIsWaiter &&
          this.appConfig?.LOCATION_DATA?.WaiterCanChangeTableWaiter &&
          this.posUser?.Settings?.Tables?.Waiter?.Permissions?.WaiterCanChangeTableWaiter
        ) {
          return true
        }

        return (!this.userIsWaiter && this.canManageTable)
      }

      return false
    },

    onSelectTargetWaiterStaff () {
      this.executeWithPinPermissionManager(PinManagerEnum.ChangeTableWaiter, () => {
        this.selectWaiterStaffDialogVisible = true
      }, 'change-table-waiter')
    },

    getInitialSelectedTables () {
      if (!this.table?.Children?.length) return []

      const initialTables = []
      const tables = this.area?.Tables || []

      this.table.Children.forEach(tableId => {
        const table = tables.find(tbl => tbl.Id === tableId)
        if (table) initialTables.push(table)
      })

      return initialTables
    },

    isSelectedTableDisabled (table) {
      return this.isDialogModeMerge && this.isTableAnInitialSelectedTable(table)
    },

    isTableAnInitialSelectedTable (table) {
      return !!this.getInitialSelectedTables().find(tbl => tbl.Id === table.Id)
    },

    isTableSelected (table) {
      return !!this.selectedTables.find(tbl => tbl.Id === table.Id)
    },

    onTableClick (table) {
      this.executeWithPinPermissionManager(this.userNeedsPinPermission(PinManagerEnum.ChangeTableWaiter) && !this.tableIsOwn(table) && !this.tableIsAvailable(table), () => {
        this.setSelectedTable(table)
      }, 'select-table-merge')
    },

    setSelectedTable (table) {
      if (!this.selectedTables.find(tbl => tbl.Id === table.Id)) {
        table.Parent = this.table.Id
        this.selectedTables.push(table)
      }
    },

    onSelectedTableClick (table) {
      const index = this.selectedTables.findIndex(tbl => tbl.Id === table.Id)
      if (index > -1) {
        table.Parent = null
        this.selectedTables.splice(index, 1)
      }
    },

    onSaveButtonClick () {
      this.loading = true
      const table = JSON.parse(JSON.stringify(this.table))
      const selectedTables = JSON.parse(JSON.stringify(this.selectedTables))
      const selectedTableIds = selectedTables.map(table => table.Id)

      if (this.returnObject) {
        this.$emit('save', table, selectedTables)
      } else {
        this.$emit('save', table, selectedTableIds)
      }

      const staffId = this.targetWaiterIsRequired ? this.targetWaiter?.Id || this.table?.StaffId || null : null

      window.callAS(window.SocketCommand.Table.Merge, {
        StaffId         : staffId,
        TableId         : table.Id,
        TableChildrenIds: selectedTableIds
      })
    },

    onSaveResult (result) {
      if (result && result.status === 'success') {
        const table = JSON.parse(JSON.stringify(this.table))
        const selectedTables = JSON.parse(JSON.stringify(this.selectedTables))
        const selectedTableIds = selectedTables.map(table => table.Id)

        if (this.returnObject) {
          this.$emit('save-success', table, selectedTables)
        } else {
          this.$emit('save-success', table, selectedTableIds)
        }
        this.onCancelButtonClick()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: result.message,
          type: 'error',
          icon: 'warning'
        })
      }
      this.loading = false
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.loading = false
      this.targetWaiter = null
      this.selectedTables = []
    }
  }
}
</script>

<style scoped>
/deep/ .v-list__tile {
  height : 126px;
}
</style>
