export const SocketCommand = {

  Pusher: {
    Pong    : 'pusher:pong',
    IsOnline: 'pusher:is-online'
  },

  getClientId: 'client-id',

  // AJAX
  Ajax: {
    Get: 'get-ajax'
  },

  // Electron
  ElectronApp: {
    HardReload         : 'electron-app-hard-reload',
    Reload             : 'electron-app-reload',
    ClearHttpCache     : 'electron-app-clear-http-cache',
    ClearStorageCache  : 'electron-app-clear-storage-cache',
    ClearAllCache      : 'electron-app-clear-all-cache',
    RetryLoadMainApp   : 'electron-app-retry-loading-main-app',
    ClearAllAndRelaunch: 'electron-app-clear-all-and-relaunch',
    Relaunch           : 'electron-app-relaunch'
  },

  // DASHBOARD
  Dashboard: {
    Get: 'get-dashboard'
  },

  // GALLERY
  Gallery: {
    All   : 'get-gallery',
    Delete: 'delete-gallery-image'
  },

  // VOIP
  Voip: {
    Incoming: 'get-voip-incoming',
    Answer  : 'get-voip-answer',
    Hangup  : 'get-voip-hangup',
    History : 'get-voip-history'
  },

  // FAQS
  Faq: {
    All   : 'get-faq-list',
    Manage: {
      All   : 'get-faqs-list',
      Save  : 'save-faq',
      Delete: 'delete-faq'
    },
    Category: {
      All   : 'get-faq-categories',
      Combo : 'get-faq-categories-combo',
      Save  : 'save-faq-category',
      Delete: 'delete-faq-category'
    }
  },

  // GOOGLE TRANSLATION
  Translate: {
    Get: 'get-translate'
  },

  // ACCOUNT
  Account: {
    Get    : 'get-account',
    Save   : 'save-account',
    Delete : 'delete-account',
    Billing: {
      Get : 'get-account-billing',
      Save: 'save-account-billing'
    },
    Plans: {
      List: 'get-account-plans'
    },
    Invoices: {
      List: 'get-account-invoices'
    },
    DomainExtendPeriod: 'domain-extend-period'
  },

  // SETTINGS
  Settings: {
    Permissions: {
      All   : 'get-permissions',
      Save  : 'save-permission',
      Delete: 'delete-permission'
    },
    Printer: {
      Group: {
        All   : 'get-printer-groups',
        Save  : 'save-printer-group',
        Delete: 'delete-printer-group'
      }
    },
    Restaurant: {
      Get : 'get-restaurant-settings',
      Save: 'save-restaurant-settings'
    },
    Delivery: {
      Get : 'get-delivery-settings',
      Save: 'save-delivery-settings'
    },
    Orders: {
      Get : 'get-orders-settings',
      Save: 'save-orders-settings'
    },
    Documents: {
      Get : 'get-documents-settings',
      Save: 'save-documents-settings'
    },
    Tables: {
      Get : 'get-tables-settings',
      Save: 'save-tables-settings'
    },
    PinManager: {
      Get : 'get-pin-manager-settings',
      Save: 'save-pin-manager-settings'
    },
    Reservations: {
      Get : 'get-table-reservation-settings',
      Save: 'save-table-reservation-settings'
    },
    Website: {
      Get     : 'get-website-settings',
      Save    : 'save-website-settings',
      GetPage : 'get-website-page-settings',
      SavePage: 'save-website-page-settings'
    },
    Regional: {
      Get : 'get-regional-settings',
      Save: 'save-regional-settings'
    },
    Notifications: {
      Get : 'get-notifications-settings',
      Save: 'save-notifications-settings'
    },
    Email: {
      Get : 'get-email-settings',
      Save: 'save-email-settings',
      Test: 'test-email-settings'
    },
    Payments: {
      Get     : 'get-payments-settings',
      Save    : 'save-payment-settings',
      Terminal: {
        Get   : 'get-payment-terminal-settings',
        Save  : 'save-payment-terminal-settings',
        Delete: 'delete-payment-terminal-settings'
      }
    },
    Catalog: {
      Copy: 'catalog-copy-data'
    },
    Domains: {
      All        : 'get-domains',
      Save       : 'save-domain',
      CheckDomain: 'check-domain',
      CheckCName : 'check-cname',
      Backup     : 'backup-domain',
      Import     : 'parse-menu',
      Copy       : 'domain-copy-data',
      MoveDB     : 'domain-db-move',
      Delete     : 'domain-delete',
      Settings   : 'domain-settings',
      ComboList  : 'get-domains-combo',
      Customer   : {
        Save     : 'save-domain-customer',
        Get      : 'get-domain-customer-data',
        SendEmail: 'send-domain-customer-mail'
      },
      PaymentFee  : 'domain-payment-fee',
      CacheClear  : 'cache-clear-domain',
      RunSchedules: 'run-schedules'
    },
    SystemUsers: 'get-all-users',
    Misc       : {
      Countries: 'get-countries-combo'
    }
  },

  // SYSTEM
  System: {
    Init  : 'app-init',
    Ping  : 'app-ping',
    Config: 'app-config',
    Socket: {
      Clients           : 'get-socket-clients',
      ChannelConnections: {
        Ws : 'ws-channel-connections',
        Get: 'get-socket-connections'
      }
    },
    LogoutUsers: 'logout-users',
    Printers   : {
      Get : 'get-printers',
      Save: 'save-printers',
      Set : 'store-printers'
    },
    Displays: {
      Get : 'get-displays',
      Save: 'save-displays',
      Set : 'store-displays'
    }
  },

  // Support Center
  SupportCenter: {
    Ticket: {
      All       : 'get-ticket-list',
      Lists     : 'get-ticket-lists',
      Get       : 'get-ticket-get',
      ReadUpdate: 'get-ticket-read',
      Add       : 'get-ticket-add',
      AddReply  : 'get-ticket-reply',
      GetReplies: 'get-ticket-replies'
    },
    PushReply: {
      AfterReplyAdded: 'after-ticket-reply-added-s2s',
      AfterUpdated   : 'after-ticket-updated-s2s'
    }
  },

  // MESSAGES
  Message: {
    All     : 'get-messages-list',
    Counts  : 'get-messages-counts',
    MarkRead: 'set-message-read',
    Config  : 'get-messages-config',
    Send    : 'message-send'
  },

  // CUSTOMER
  Customer: {
    All             : 'get-customers',
    Get             : 'get-customer',
    GetDetails      : 'get-customer-details',
    Companies       : 'get-customer-companies',
    ReplyForNewOrder: 'get-customer-for-new-order',
    Save            : 'save-customer',
    Delete          : 'delete-customer',
    Voip            : 'get-voip-customers',
    Export          : 'export-customers',
    Import          : 'import-customers',
    AddressValidate : 'customer-address-validate'
  },

  // CUSTOMER GROUPS
  CustomerGroup: {
    All : 'get-customer-groups',
    Save: 'save-customer-group'
  },

  // CATEGORY
  Category: {
    All         : 'get-categories',
    AllFilter   : 'get-categories-filter',
    Get         : 'get-category',
    Save        : 'save-category',
    Duplicate   : 'duplicate-category',
    Delete      : 'delete-category',
    UpdateStatus: 'update-category-status'
  },

  // CATALOG
  Catalog: {
    All          : 'get-catalogs',
    Save         : 'save-catalog',
    Delete       : 'delete-catalog',
    Duplicate    : 'duplicate-catalog',
    ImportEFood  : 'efood-catalog',
    ImportFoody  : 'foody-catalog',
    ImportFagi   : 'fagi-catalog',
    ImportGetFood: 'getfood-catalog',
    TaxesOrphaned: 'taxes-orphaned'
  },

  // TAGS
  Tag: {
    All   : 'get-tags',
    Save  : 'save-tag',
    Delete: 'delete-tag'
  },

  // COUPONS
  Coupon: {
    All    : 'get-coupons',
    Save   : 'save-coupon',
    History: 'get-coupon-history'
  },

  // ORDERS
  Order: {
    Counts          : 'get-orders-counts',
    Daily           : 'get-daily-orders',
    Lost            : 'get-daily-lost',
    Saved           : 'get-saved-orders',
    All             : 'get-all-orders',
    Get             : 'get-order-details',
    Delivery        : 'get-daily-orders-delivery',
    UpdateStatus    : 'update-order-status',
    StatusUpdated   : 'order-status-updated',
    UpdateTime      : 'update-order-time',
    Cancel          : 'order-cancel',
    Delete          : 'order-delete',
    Print           : 'send-order-for-print',
    PrintPreparation: 'send-order-preparation-for-print',
    Edit            : 'order-edit',
    Payment         : {
      Pay        : 'pay-order',
      Transaction: {
        Add        : 'order-payment-transaction-add',
        Remove     : 'order-payment-transaction-remove',
        Update     : 'order-payment-transaction-update',
        OrderResult: 'order-payment-transaction-result',
        CartResult : 'cart-payment-transaction-result',
        Sale       : 'order-payment-transaction-sale',
        Refund     : 'order-payment-transaction-refund'
      }
    }
  },

  // RECEIPTS
  Receipt: {
    All        : 'get-receipt-list',
    Get        : 'get-receipt',
    Retry      : 'receipt-retry',
    Filters    : 'get-receipt-filters',
    Print      : 'send-receipt-for-print',
    PrintManual: 'send-receipt-for-print-manual',
    Cancel     : 'receipt-cancel',
    Update     : 'fiscal-document-update',
    Generate   : 'receipt-generate',
    Notify     : 'receipt-notify',
    NotifyPrint: 'receipt-notify-print',
    NotifyEmail: 'receipt-notify-email'
  },

  // MENU
  Menu: {
    All         : 'get-menu',
    Pos         : 'get-menus-pos',
    PosUpdate   : 'get-menus-pos-update',
    PosItem     : 'get-menus-pos-item',
    Get         : 'get-menu-item',
    Save        : 'save-menu-item',
    Duplicate   : 'duplicate-menu-item',
    Delete      : 'delete-menu-item',
    GetAdmin    : 'get-admin-menu-item',
    UpdateStatus: 'update-menu-item-status',
    UpdateValue : 'update-menu-item-value',
    Import      : 'import-menu',
    Export      : 'export-menu',

    // MENU OPTIONS
    Options: {
      All   : 'get-menus-options',
      Save  : 'save-menus-option',
      Delete: 'delete-menus-option'
    },

    // MENU OPTION VALUE GROUPS
    OptionValueGroup: {
      All   : 'get-menu-option-value-groups',
      Save  : 'save-menu-option-value-group',
      Delete: 'delete-menu-option-value-group'
    }
  },

  // STAFF
  Staff: {
    All             : 'get-staffs',
    Save            : 'save-staff',
    ChangeUserSingle: 'change-user-single',
    ChangeDomain    : 'change-staff-domain',

    // STAFF GROUPS
    Group: {
      All : 'get-staff-groups',
      Save: 'save-staff-group'
    },

    // DELIVERY STAFF
    Delivery: {
      All          : 'get-delivery-staff-list',
      ShiftsHistory: 'get-delivery-staff-list-by-id',
      Save         : 'save-delivery-staff',
      Delete       : 'delete-delivery-staff',
      Shift        : {
        Open       : 'open-delivery-staff-shift',
        Close      : 'close-delivery-staff-shift',
        AddOrder   : 'add-delivery-staff-shift-order',
        RemoveOrder: 'remove-delivery-staff-shift-order'
      }
    },

    // WAITER STAFF
    Waiter: {
      All          : 'get-waiter-staff-list',
      ShiftsHistory: 'get-waiter-staff-list-by-id',
      Save         : 'save-waiter-staff',
      Delete       : 'delete-waiter-staff',
      Payment      : {
        ChangeWaiter: 'move-waiter-staff-shift-payments'
      },
      Shift: {
        Open   : 'open-waiter-staff-shift',
        Close  : 'close-waiter-staff-shift',
        Changed: 'get-waiter-staff-shift-changed'
      }
    }
  },

  // Cart
  Cart: {
    Init   : 'cart-init',
    Update : 'cart-update',
    Destroy: 'cart-destroy',
    Item   : {
      Add   : 'cart-add-item',
      Update: 'cart-update-item',
      Remove: 'cart-remove-item'
    },
    Coupon: {
      Add   : 'cart-add-coupon',
      Remove: 'cart-remove-coupon'
    },
    Tip: {
      Add   : 'cart-add-tip',
      Remove: 'cart-remove-tip'
    },
    ExtraCharge: {
      Add   : 'cart-add-extra-charge',
      Remove: 'cart-remove-extra-charge'
    },
    Order: {
      Complete: 'cart-order-complete'
    }
  },

  // LOCATION
  Location: {
    OnlineOrderingStatus: 'get-online-ordering-status'
  },

  // WORKING HOURS
  WorkingHours: {
    All : 'get-working-hours',
    Save: 'save-working-hours'
  },

  // MEAL TIMES
  MealTimes: {
    All : 'get-mealtimes',
    Save: 'save-mealtime'
  },

  // QR CODES
  QRCode: {
    All     : 'get-qr-list',
    Save    : 'save-qr',
    Delete  : 'delete-qr',
    Export  : 'export-qr',
    Settings: {
      Save: 'save-qr-options'
    }
  },

  // Extra Charges
  ExtraCharge: {
    All   : 'get-charges',
    Save  : 'save-charge',
    Delete: 'delete-charge'
  },

  // TABLES
  Table: {
    All                       : 'get-table-list',
    AllByArea                 : 'get-tables',
    Get                       : 'get-table',
    GetById                   : 'get-table-by-id',
    Save                      : 'save-table',
    Delete                    : 'delete-table',
    Merge                     : 'merge-table',
    Show                      : 'show-table',
    MoveOrderItems            : 'move-table',
    Open                      : 'open-table',
    Close                     : 'close-table',
    Pay                       : 'pay-table',
    ChangeWaiter              : 'change-table-waiter-staff',
    ChangePersons             : 'change-table-persons',
    SetAwaitingPaymentStatus  : 'set-awaiting-payment-table-status',
    UnsetAwaitingPaymentStatus: 'unset-awaiting-payment-table-status',
    Cancel                    : {
      Items: 'cancel-table-items'
    },
    PrintOrderTable             : 'print-order-table',
    SendOrderForPrint           : 'send-order-table-for-print',
    SendOrderPreparationForPrint: 'send-order-table-preparation-for-print',
    SendCanceledForPrint        : 'send-order-table-items-canceled-for-print',
    SendReceiptForPrint         : 'send-receipt-table-for-print',
    ToggleOrderExternalReceipt  : 'table-cart-external-receipt',
    IssueOrderReceiptDocument   : 'table-cart-provider-receipt',
    Cart                        : {
      Complete      : 'cart-table-complete',
      UpdateUnitItem: 'cart-table-update-unit-item'
    },
    Area: {
      All   : 'get-table-area-list',
      List  : 'get-table-area-combo',
      Save  : 'save-table-area',
      Delete: 'delete-table-area'
    },
    Coupon: {
      Add   : 'table-add-coupon',
      Remove: 'table-remove-coupon'
    },
    Reservation: {
      All     : 'get-table-reservation-list',
      Save    : 'save-table-reservation',
      Cancel  : 'cancel-table-reservation',
      Status  : 'status-table-reservation',
      Delete  : 'delete-table-reservation',
      Validate: 'validate-table-reservation'
    },
    Transaction: {
      Add   : 'cart-table-payment-transaction-add',
      Remove: 'cart-table-payment-transaction-remove',
      Update: 'cart-table-payment-transaction-update',
      Result: 'cart-table-payment-transaction-result',
      Sale  : 'cart-table-payment-transaction-sale',
      Refund: 'cart-table-payment-transaction-refund'
    }
  },

  // STATISTICS
  Statistics: {
    Products: {
      All   : 'get-statistics-products',
      Export: 'export-statistics-products'
    },
    SalesReportZ: {
      All   : 'get-statistics-sales-report-z',
      Export: 'export-statistics-sales-report-z'
    },
    OrdersHeatmap: {
      All: 'get-statistics-orders-heatmap'
    }
  },

  /**
   *
   * @param command {String}
   * @returns {String}
   */
  commandToSocketFormat: (command) => {
    return command.toUpperCase().replace(new RegExp('-', 'g'), '_')
  },

  /**
   *
   * @param command {String}
   * @returns {String}
   */
  commandToClientFormat: (command) => {
    return command.toLowerCase().replace(new RegExp('_', 'g'), '-')
  }
}

export default SocketCommand
