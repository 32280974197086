<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Restaurant.Tags.Dialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Restaurant.Tags.Dialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!itemToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.Tags.Dialog.Toolbar.Add.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.Tags.Dialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Restaurant.Tags.Dialog.Toolbar.Edit.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.Tags.Dialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-3">
          <v-form
            ref="dataForm"
            v-model="formValid"
            @submit.prevent
          >
            <v-container
              class="pa-1"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <template v-if="selectedLanguages.length > 1">
                    <v-tabs
                      v-model="languageTab"
                      :background-color="isDarkMode ? 'grey darken-4' : 'grey lighten-4'"
                      grow
                      height="40"
                      slider-color="blue"
                    >
                      <template v-for="lang in selectedLanguages">
                        <v-tab
                          :key="lang.Code"
                          :active-class="isDarkMode ? 'grey darken-3 v-tabs__item--active' : 'grey lighten-3 v-tabs__item--active'"
                        >
                          <v-avatar
                            v-if="lang.ImageUrl"
                            class="mr-2"
                            size="20"
                          >
                            <v-img :src="lang.ImageUrl" />
                          </v-avatar>
                          {{
                            $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale)
                          }}
                        </v-tab>
                      </template>
                    </v-tabs>
                  </template>
                  <v-tabs-items v-model="languageTab">
                    <template v-for="lang in selectedLanguages">
                      <v-tab-item
                        :key="lang.Code"
                        eager
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editItem.Lang[lang.Code].Label"
                              :counter="32"
                              :hint="$t('Restaurant.Tags.Dialog.Field.Label.Hint')"
                              :label="$t('Restaurant.Tags.Dialog.Field.Label.Label')"
                              :maxlength="32"
                              :prepend-icon="$t('Restaurant.Tags.Dialog.Field.Label.Icon')"
                              :rules="validationRules.dataForm.Label"
                              class="pr-2"
                              required
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editItem"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Label"
                                />
                              </template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="editItem.Lang[lang.Code].Description"
                              :counter="128"
                              :hint="$t('Restaurant.Tags.Dialog.Field.Description.Hint')"
                              :label="$t('Restaurant.Tags.Dialog.Field.Description.Label')"
                              :maxlength="128"
                              :prepend-icon="$t('Restaurant.Tags.Dialog.Field.Description.Icon')"
                              :rules="validationRules.dataForm.Description"
                              class="pr-2"
                              required
                            >
                              <template
                                v-if="lang.Code !== defaultLanguage.Code"
                                #append-outer
                              >
                                <translate-field-button
                                  :item="editItem"
                                  :locale-from="defaultLanguage.Code"
                                  :locale-to="lang.Code"
                                  field="Description"
                                />
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </template>
                  </v-tabs-items>
                </v-col>

                <template v-if="(!editItem.System && !editItem.Group) || userIsSuperAdmin">
                  <v-col cols="12">
                    <select-icon-input
                      v-model="editItem.Icon"
                      :rules="validationRules.dataForm.Icon"
                      readonly
                      clearable
                      editable
                    />
                  </v-col>

                  <v-col cols="12">
                    <div class="grey--text py-2">
                      {{ $t('Restaurant.Tags.Dialog.Field.Color.Label') }}
                    </div>

                    <color-picker
                      v-model="editItem.TmpColor"
                      style="width: 100%;"
                      :style="isDarkMode ? 'background-color: #191919;' : 'background-color: white;'"
                    />
                  </v-col>

                  <v-col
                    v-if="userIsSuperAdmin"
                    cols="12"
                  >
                    <v-text-field
                      v-model="editItem.Group"
                      :counter="50"
                      :hint="$t('Restaurant.Tags.Dialog.Field.Group.Hint')"
                      :label="$t('Restaurant.Tags.Dialog.Field.Group.Label')"
                      :maxlength="50"
                      :prepend-icon="$t('Restaurant.Tags.Dialog.Field.Group.Icon')"
                      :rules="validationRules.dataForm.Group"
                      class="pr-2"
                      required
                    />
                  </v-col>
                </template>
              </v-row>

              <v-row>
                <template v-if="!editItem.System || userIsSuperAdmin">
                  <v-col cols="auto">
                    <v-subheader class="pl-0 mt-2">
                      {{ $t('Restaurant.Tags.Dialog.Field.Active.Label') }}
                    </v-subheader>
                    <v-switch
                      v-model="editItem.Active"
                      :label="editItem.Active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                      class="pa-0 ma-0"
                      color="success"
                      inset
                    />
                  </v-col>
                </template>

                <template v-if="(!editItem.System && !editItem.Group) || userIsSuperAdmin">
                  <v-col cols="auto">
                    <v-subheader class="pl-0 mt-2">
                      {{ $t('Restaurant.Tags.Dialog.Field.Visible.Label') }}
                    </v-subheader>
                    <v-switch
                      v-model="editItem.Visible"
                      :label="editItem.Visible ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                      class="pa-0 ma-0"
                      color="success"
                      inset
                    />
                  </v-col>

                  <v-col
                    v-if="userIsSuperAdmin"
                    cols="auto"
                  >
                    <v-subheader class="pl-0 mt-2">
                      {{ $t('Restaurant.Tags.Dialog.Field.LabelOnly.Label') }}
                    </v-subheader>
                    <v-switch
                      v-model="editItem.LabelOnly"
                      :label="editItem.LabelOnly ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="pa-0 ma-0"
                      color="primary"
                      inset
                    />
                  </v-col>

                  <v-col
                    v-if="userIsSuperAdmin"
                    cols="auto"
                  >
                    <v-subheader class="pl-0 mt-2">
                      {{ $t('Restaurant.Tags.Dialog.Field.System.Label') }}
                    </v-subheader>
                    <v-switch
                      v-model="editItem.System"
                      :label="editItem.System ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                      class="pa-0 ma-0"
                      color="red"
                      inset
                    />
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <translate-all-fields-button
            v-if="selectedLanguages.length > 1"
            :disabled="loading"
            :item="editItem"
            :locale-from="defaultLanguage.Code"
            :field="['Label', 'Description']"
          />

          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { Swatches }             from 'vue-color'
import AppData                  from '@/mixins/appdata'
import Auth                     from '@/mixins/auth'
import SelectIconInput          from '@/components/common/icon/SelectIconInput.vue'
import { clone }                from '@/lib/helper/helper'
import TranslateAllFieldsButton from '@/components/common/translation/translateAllFieldsButton.vue'
import TranslateFieldButton     from '@/components/common/translation/translateFieldButton.vue'

export default {
  name      : 'TagAddDialog',
  components: {
    TranslateFieldButton,
    TranslateAllFieldsButton,
    'color-picker': Swatches,
    SelectIconInput
  },
  mixins: [AppData, Auth],
  props : {
    visible: {
      type   : Boolean,
      default: false
    },
    itemToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      languageTab: 0,
      formValid  : false,
      loading    : false,
      newItem    : null,
      tmpItem    : {
        Id         : null,
        Label      : '',
        Description: '',
        Group      : '',
        Icon       : '',
        Color      : '',
        TmpColor   : {
          hex   : '',
          source: ''
        },
        Active   : true,
        Visible  : true,
        System   : false,
        LabelOnly: false,
        Lang     : {}
      },
      validationRules: {
        dataForm: {
          Label: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Restaurant.Tags.Dialog.Field.Label.Error.Between')
          ],
          Description: [
            (v) => !v || (v.length >= 2 && v.length <= 128) || this.$t('Restaurant.Tags.Dialog.Field.Description.Error.Between')
          ],
          Icon: [
            (v) => v && v.length >= 1 || this.$t('Restaurant.Tags.Dialog.Field.Icon.Error.Required')
          ],
          Group: [
            (v) => !v || (v.length >= 2 && v.length <= 50) || this.$t('Restaurant.Tags.Dialog.Field.Group.Error.Between')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    editItem: {
      get () {
        if (this.itemToEdit) {
          const c = clone(this.itemToEdit).Color
          this.itemToEdit.TmpColor = {
            hex   : c || '',
            source: 'hex'
          }
          this.newItem = clone(this.itemToEdit)
        } else {
          this.newItem = clone(this.tmpItem)
        }

        const langObj = {}
        for (const lang of this.selectedLanguages) {
          langObj[lang.Code] = {
            Label      : '',
            Description: '',
            Locale     : lang.Code
          }
        }
        if (!this.newItem.Lang) this.newItem.Lang = {}
        this.newItem.Lang = Object.assign({}, langObj, this.newItem.Lang)

        return this.newItem
      }
    }
  },
  watch: {
    isVisible (newVal) {
      this.languageTab = 0
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
        })
      }
    }
  },
  created () {
    // console.log('1. created');
  },
  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Tag.Save, this.onSaveResult)
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Tag.Save, this.onSaveResult)
  },
  methods: {
    onSaveButtonClick () {
      this.$refs.dataForm.validate()

      if (this.formValid) {
        this.loading = true
        const saveObj = clone(this.editItem)

        saveObj.Color = saveObj.TmpColor.hex
        saveObj.Label = saveObj.Lang[this.defaultLanguage.Code].Label
        saveObj.Description = saveObj.Lang[this.defaultLanguage.Code].Description
        delete saveObj.TmpColor
        delete saveObj.CreatedAt

        window.callAS(window.SocketCommand.Tag.Save, saveObj)
        if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult () {
      this.loading = false
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      this.isVisible = false
      this.newItem = null
    }
  }
}
</script>

<style scoped>
.vc-swatches {
  min-width  : 472px;
  height     : auto;
  overflow-y : auto;
  zoom       : 75%;
}

/deep/ .vc-swatches .vc-swatches-box div.vc-swatches-color-group:last-child {
  display : none;
}
</style>
