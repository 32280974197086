<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="850"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Delivery.DeliveryStaffAddDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Delivery.DeliveryStaffAddDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!deliveryStaffToEdit"
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Delivery.DeliveryStaffAddDialog.Toolbar.Add.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Delivery.DeliveryStaffAddDialog.Toolbar.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-3"
          >
            <div class="body-3">
              {{ $t('Delivery.DeliveryStaffAddDialog.Toolbar.Edit.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Delivery.DeliveryStaffAddDialog.Toolbar.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-4">
          <v-form
            ref="deliveryStaffForm"
            v-model="deliveryStaffFormValid"
            @submit.prevent
          >
            <v-container
              fluid
              class="pa-0"
            >
              <v-row>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editDeliveryStaff.Firstname"
                    :counter="32"
                    :hint="$t('Delivery.DeliveryStaffAddDialog.Field.Firstname.Hint')"
                    :label="$t('Delivery.DeliveryStaffAddDialog.Field.Firstname.Label')"
                    :prepend-icon="$t('Delivery.DeliveryStaffAddDialog.Field.Firstname.Icon')"
                    :rules="validationRules.deliveryStaffForm.Firstname"
                    class=""
                    required
                  />
                </v-col>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editDeliveryStaff.Lastname"
                    :counter="32"
                    :hint="$t('Delivery.DeliveryStaffAddDialog.Field.Lastname.Hint')"
                    :label="$t('Delivery.DeliveryStaffAddDialog.Field.Lastname.Label')"
                    :prepend-icon="$t('Delivery.DeliveryStaffAddDialog.Field.Lastname.Icon')"
                    :rules="validationRules.deliveryStaffForm.Lastname"
                    class=""
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editDeliveryStaff.Phone"
                    v-mask="'################'"
                    :hint="$t('Delivery.DeliveryStaffAddDialog.Field.Telephone.Hint')"
                    :label="$t('Delivery.DeliveryStaffAddDialog.Field.Telephone.Label')"
                    :prepend-icon="$t('Delivery.DeliveryStaffAddDialog.Field.Telephone.Icon')"
                    :rules="validationRules.deliveryStaffForm.Phone"
                    class=""
                    required
                  />
                </v-col>
                <v-col
                  sm="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="editDeliveryStaff.Email"
                    :counter="96"
                    :hint="$t('Delivery.DeliveryStaffAddDialog.Field.Email.Hint')"
                    :label="$t('Delivery.DeliveryStaffAddDialog.Field.Email.Label')"
                    :prepend-icon="$t('Delivery.DeliveryStaffAddDialog.Field.Email.Icon')"
                    :rules="validationRules.deliveryStaffForm.Email"
                    class=""
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="editDeliveryStaff.Notes"
                    :hint="$t('Delivery.DeliveryStaffAddDialog.Field.Notes.Hint')"
                    :label="$t('Delivery.DeliveryStaffAddDialog.Field.Notes.Label')"
                    :prepend-icon="$t('Delivery.DeliveryStaffAddDialog.Field.Notes.Icon')"
                    class=""
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  components: {},
  mixins    : [],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    deliveryStaffToEdit: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      isLoading             : false,
      deliveryStaffFormValid: false,
      newDeliveryStaff      : null,
      tmpDeliveryStaff      : {
        Id       : null,
        Firstname: '',
        Lastname : '',
        Phone    : '',
        Email    : '',
        Notes    : ''
      },
      validationRules: {
        deliveryStaffForm: {
          Firstname: [
            (v) => v && v.length >= 2 && v.length <= 32 || this.$t('Delivery.DeliveryStaffAddDialog.Field.Firstname.Error.Between')
          ],
          Lastname: [
            (v) => !v || (v.length >= 2 && v.length <= 32) || this.$t('Delivery.DeliveryStaffAddDialog.Field.Lastname.Error.Between')
          ],
          Phone: [
            (v) => v && v.length >= 8 && v.length <= 16 && !isNaN(v) || this.$t('Delivery.DeliveryStaffAddDialog.Field.Telephone.Error.Between')
          ],
          Email: [
            (v) => !v || (v.length >= 0 && v.length <= 96) || this.$t('Delivery.DeliveryStaffAddDialog.Field.Email.Error.Between'),
            (v) => !v || (/^\w+([.\-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Delivery.DeliveryStaffAddDialog.Field.Email.Error.Valid')
          ]
        }
      }
    }
  },
  computed: {

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },

    editDeliveryStaff: {
      get () {
        if (this.deliveryStaffToEdit) {
          this.newDeliveryStaff = JSON.parse(JSON.stringify(this.deliveryStaffToEdit))
        } else {
          this.newDeliveryStaff = JSON.parse(JSON.stringify(this.tmpDeliveryStaff))
        }
        return this.newDeliveryStaff
      }
    }
  },

  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.deliveryStaffForm) this.$refs.deliveryStaffForm.resetValidation()
        })
      }
    }
  },

  created () {
    // console.log('1. created');
  },

  mounted () {
    // console.log('2. mounted');
    this.$bus.$on(window.SocketCommand.Staff.Delivery.Save, this.onSaveResult)
  },

  updated () {
    // console.log('3. updated');
  },

  beforeDestroy () {
    // console.log('4. destroyed');
    this.$bus.$off(window.SocketCommand.Staff.Delivery.Save, this.onSaveResult)
  },

  methods: {
    onSaveButtonClick () {
      this.$refs.deliveryStaffForm.validate()

      if (this.deliveryStaffFormValid) {
        this.isLoading = true
        window.callAS(window.SocketCommand.Staff.Delivery.Save, { staff: this.editDeliveryStaff })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Common.Misc.Notification.FieldError'),
          type: 'error',
          icon: 'warning'
        })
      }
    },

    onSaveResult (data) {
      if (data && data.status === 'success') {
        this.onCancelButtonClick()
        if (Array.isArray(data.result) && data.result.length) {
          this.$bus.$emit(window.SocketCommand.Staff.Delivery.All, data)
          this.$set(window.Vue, 'deliveryStaffItems', data.result || [])
        }
      } else {
        if (data && data.message) {
          this.$bus.$emit('app-show-notification', {
            body: data.message,
            type: 'error',
            icon: 'warning'
          })
        } else {
          this.$bus.$emit('app-show-notification', {
            body: this.$t('Common.Misc.Notification.GenericError'),
            type: 'error',
            icon: 'warning'
          })
        }
      }
      this.isLoading = false
    },

    onCancelButtonClick () {
      this.newDeliveryStaff = null
      if (this.$refs.deliveryStaffForm) this.$refs.deliveryStaffForm.resetValidation()
      this.isVisible = false
      this.isLoading = false
    }

  }
}
</script>
