<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card v-if="posCart.Customer">
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar
            :color="$t('Customer.CustomerSelectAddressDialog.Toolbar.Color')"
          >
            <v-icon dark>
              {{ $t('Customer.CustomerSelectAddressDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Customer.CustomerSelectAddressDialog.Toolbar.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Customer.CustomerSelectAddressDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-title class="pa-4">
          <v-container
            class="pa-0 text-body-2"
            fluid
          >
            <v-row dense>
              <v-col
                class="grey--text text--darken-1"
                cols="12"
                sm="4"
              >
                <span :class="isDarkMode ? 'white--text' : 'black--text'">
                  {{ $t('Customer.CustomerSelectAddressDialog.Header.Name').toLocaleUpperCase($i18n.locale) }}
                </span> {{ posCart.Customer.Firstname + ' ' + posCart.Customer.Lastname }}
              </v-col>

              <v-col
                v-if="posCart.Customer.Telephone || posCart.Customer.Telephone2 || posCart.Customer.Telephone3"
                class="grey--text text--darken-1"
                cols="12"
                sm="4"
              >
                <div v-if="posCart.Customer.Telephone && $options.filters.phone(posCart.Customer.Telephone)">
                  <span :class="isDarkMode ? 'white--text' : 'black--text'">
                    {{ $t('Customer.CustomerSelectAddressDialog.Header.Telephone').toLocaleUpperCase($i18n.locale) }}
                  </span> {{ posCart.Customer.Telephone }}
                </div>
                <div v-if="posCart.Customer.Telephone2 && $options.filters.phone(posCart.Customer.Telephone2)">
                  <span :class="isDarkMode ? 'white--text' : 'black--text'">
                    {{ $t('Customer.CustomerSelectAddressDialog.Header.Telephone').toLocaleUpperCase($i18n.locale) }}
                  </span> {{ posCart.Customer.Telephone2 }}
                </div>
                <div v-if="posCart.Customer.Telephone3 && $options.filters.phone(posCart.Customer.Telephone3)">
                  <span :class="isDarkMode ? 'white--text' : 'black--text'">
                    {{ $t('Customer.CustomerSelectAddressDialog.Header.Telephone').toLocaleUpperCase($i18n.locale) }}
                  </span> {{ posCart.Customer.Telephone3 }}
                </div>
              </v-col>

              <v-col
                v-if="!emailIsGuest(posCart.Customer.Email)"
                class="grey--text text--darken-1"
                cols="12"
                sm="4"
              >
                <span :class="isDarkMode ? 'white--text' : 'black--text'">
                  {{ $t('Customer.CustomerSelectAddressDialog.Header.Email').toLocaleUpperCase($i18n.locale) }}
                </span> {{ posCart.Customer.Email }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-divider />

        <v-card-text class="pa-3 py-1">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <v-list
                  v-for="(item, index) in posCart.Customer.Addresses"
                  :key="`address-${item.Id}-${index}`"
                  class="mb-2 mt-2 pb-0 pt-0"
                  two-line
                >
                  <v-list-item
                    :class="posCart.Address && parseInt(posCart.Address.Id) === parseInt(item.Id) ? (isDarkMode ? 'green darken-4' : 'green lighten-5') : (isDarkMode ? 'grey darken-4' : 'grey lighten-3')"
                    class="rounded"
                    ripple
                    @click="setAddress(item)"
                  >
                    <v-list-item-content class="grey--text text--darken-1">
                      <v-list-item-title
                        :class="{'font-weight-bold green--text': posCart.Address && parseInt(posCart.Address.Id) === parseInt(item.Id)}"
                        class="text-left"
                      >
                        <v-icon
                          :color="item.IsDefault ? 'light-green lighten-1' : 'grey'"
                          size="18"
                        >
                          location_on
                        </v-icon>
                        {{ item.Formatted }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        v-if="item.Floor || item.DoorBell"
                        class="ml-6"
                      >
                        <span
                          v-if="item.Floor"
                          class="mr-4"
                        >
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Customer.CustomerSelectAddressDialog.Header.Floor') }}
                          </span> {{ item.Floor }}
                        </span>
                        <span v-if="item.DoorBell">
                          <span :class="isDarkMode ? 'white--text' : 'black--text'">
                            {{ $t('Customer.CustomerSelectAddressDialog.Header.DoorBell') }}
                          </span> {{ item.DoorBell }}
                        </span>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-if="item.Comment"
                        class="ml-6"
                      >
                        <span :class="isDarkMode ? 'white--text' : 'black--text'">
                          {{ $t('Customer.CustomerSelectAddressDialog.Header.Instructions') }}
                        </span> {{ item.Comment }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        icon
                        @click.native.stop="onEditAddressButtonClick(item)"
                      >
                        <v-icon color="green lighten-1">
                          edit
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            text
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onNewAddressButtonClick"
          >
            {{ $t('Customer.CustomerSelectAddressDialog.Button.NewAddress.Title').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { emailIsGuest } from '@/lib/helper/helper'
import Appdata          from '@/mixins/appdata'

export default {
  components: {},
  mixins    : [Appdata],
  props     : {
    customerSelectAddressDialogVisible: {
      type   : Boolean,
      default: false
    }
  },
  DataStore: { posCart: 'cart' },
  data () {
    return {
      selectedAddressId: null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.customerSelectAddressDialogVisible
      },
      set (val) {
        this.$emit('update:customerSelectAddressDialogVisible', val)
      }
    }
  },
  methods: {
    emailIsGuest,
    setAddress (address) {
      this.isVisible = false
      this.posCart.Address = address
      this.posCart.OrderType = 1
    },
    onEditAddressButtonClick (address) {
      this.isVisible = false
      this.posCart.Address = address
      this.$bus.$emit('show-customer-add-edit-address-dialog', address)
    },
    onNewAddressButtonClick () {
      this.isVisible = false
      this.$bus.$emit('show-customer-add-edit-address-dialog')
    },
    onCancelButtonClick () {
      this.isVisible = false
      if (!this.posCart.Address) this.posCart.OrderType = 2
    }
  }
}
</script>
