<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row no-gutters>
      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <template v-for="(bullet) in pinLength">
          <span
            :key="bullet"
            :class="[{'pinCodeActive': vModel.length >= bullet && !error, 'pinCodeError': error}]"
            class="pinCodeCircle"
            style="margin: 6px; height: 25px; width: 25px;"
          />
        </template>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <template v-for="num in 3">
          <v-btn
            :key="`key-${num + 6}`"
            :style="btnStyle"
            color="grey"
            depressed
            dark
            @click="onNumKeyClick(num + 6)"
          >
            {{ num + 6 }}
          </v-btn>
        </template>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <template v-for="num in 3">
          <v-btn
            :key="`key-${num + 3}`"
            :style="btnStyle"
            color="grey"
            depressed
            dark
            @click="onNumKeyClick(num + 3)"
          >
            {{ num + 3 }}
          </v-btn>
        </template>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <template v-for="num in 3">
          <v-btn
            :key="`key-${num}`"
            :style="btnStyle"
            color="grey"
            depressed
            dark
            @click="onNumKeyClick(num)"
          >
            {{ num }}
          </v-btn>
        </template>
      </v-col>

      <v-col
        :class="`text-${align}`"
        style="white-space: nowrap;"
        cols="12"
      >
        <v-btn
          :style="btnStyle"
          color="grey"
          depressed
          dark
          @click="onClearClick('c')"
        >
          C
        </v-btn>

        <v-btn
          :style="btnStyle"
          color="grey"
          depressed
          dark
          @click="onNumKeyClick(0)"
        >
          0
        </v-btn>

        <v-btn
          :style="btnStyle"
          color="blue"
          depressed
          dark
          @click="onDelClick"
        >
          <v-icon>backspace</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vModel from '@/mixins/vModel'

export default {
  name      : 'PinPad',
  components: {},
  directives: {},
  mixins    : [vModel],
  props     : {
    autoSubmit: {
      type   : Boolean,
      default: true
    },
    pinLength: {
      type   : Number,
      default: 4
    },
    align: {
      type   : String,
      default: 'center'
    },
    small: {
      type   : Boolean,
      default: false
    },
    fullWidth: {
      type   : Boolean,
      default: false
    },
    error: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {
      data: []
    }
  },
  computed: {
    btnStyle () {
      const fullWidth = { 'min-width': '32%', 'min-height': this.small ? '40px' : '55px', margin: '3px 3px' }
      const smallStyle = { 'min-width': this.$vuetify.breakpoint.xsOnly ? '55px' : '55px', 'min-height': '40px', margin: '3px 3px', 'font-size': '18px' }
      const normalStyle = { 'min-width': this.$vuetify.breakpoint.xsOnly ? '75px' : '88px', 'min-height': '55px', margin: '3px 3px', 'font-size': '24px' }

      if (this.fullWidth) return fullWidth
      if (this.small) return smallStyle

      return normalStyle
    }
  },
  watch: {
    value (newVal) {
      const items = this.convertStringToArray(newVal)
      this.$nextTick(() => {
        this.data = items
      })
    },
    data (newVal) {
      this.vModel = this.convertArrayToString(newVal)
    }
  },
  beforeCreate () {
  },
  created () {
    window && window.addEventListener('keyup', this.onKeyUp)
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    window && window.removeEventListener('keyup', this.onKeyUp)
  },
  destroyed () {
  },
  methods: {
    onNumKeyClick (val) {
      if (/[0-9]/.test(val)) {
        const data = String(val).split('')
        if (this.data.length === this.pinLength) return
        data.forEach(num => this.data.push(parseInt(num)))

        this.$nextTick(() => {
          if (this.autoSubmit && this.data.length === this.pinLength) this.onOkKeyClick()
        })
      }
    },

    onDelClick () {
      this.data.pop()
    },

    onClearClick () {
      this.data = []
    },

    onOkKeyClick () {
      this.$emit('numpad:enter')
    },

    convertArrayToString (val) {
      return val.join('')
    },

    convertStringToArray (val) {
      let items = []

      if (val && parseInt(val) >= 0) {
        items = String(val).split('').map(item => parseInt(item))
      }

      return items
    },

    onKeyUp (keyCode) {
      switch (keyCode.key) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '0':
          this.onNumKeyClick(keyCode.key)
          break
        case 'Backspace':
        case 'Delete':
          this.onDelClick()
          break
        case 'Enter':
          this.onOkKeyClick()
          break
        case 'C':
        case 'c':
          this.onClearClick()
          break
      }
    }
  }
}
</script>

<style scoped>
.pinCodeCircle {
  display          : inline-block;
  border-radius    : 50%;
  background-color : inherit;
  text-align       : center;
  border           : 2px solid #666;
}

.pinCodeActive {
  background-color : #6cc760;
  border           : 2px solid #419430;
}

.pinCodeError {
  background-color : #f66353;
  border           : 2px solid #d5382e;
}
</style>
