/**
 * Enum for receipt status.
 * @readonly
 * @enum {String} ReceiptStatusEnum
 */

const ReceiptStatusEnum = {
  CLOSED              : 'closed',
  OPEN                : 'open',
  AWAITING            : 'awaiting',
  TRANSMITTED         : 'transmitted',
  TRANSMISSION_FAILURE: 'transmission_failure',
  CANCELED            : 'canceled'
}

export default ReceiptStatusEnum
