export default {
  Title: 'Support Center',

  Toolbar: {
    Subtitle: 'Support Tickets',
    Icon    : 'help_outline',
    Color   : 'indigo',
    Button  : {
      New   : { Title: 'New Support Ticket', Icon: 'add_box' },
      Filter: { Title: 'Filter by', Icon: 'filter_list' },
      Group : { Title: 'Group by', Icon: 'more_vert' }
    }
  },

  ListView: {
    Select: {
      Title   : 'Select Support Ticket',
      Subtitle: 'Select a support ticket to view from the list',
      Icon    : '@:Support.Toolbar.Icon'
    },
    NoData: {
      Subtitle: 'There are no support tickets yet.',
      Icon    : '@:Support.Toolbar.Icon'
    }
  },

  TicketAddDialog: {
    Toolbar: {
      Title   : 'New Support Ticket',
      Subtitle: 'Create a new support ticket'
    },
    Field: {
      Department: { Label: 'Select Department' },
      Service   : { Label: 'Select Service' },
      Priority  : { Label: 'Select Priority' },
      Subject   : { Label: 'Subject', PlaceHolder: 'Type Support Ticket Subject' }
    }
  },

  GroupBy: {
    Date      : 'Date',
    Department: 'Department',
    Read      : 'Read',
    Unread    : 'Unread',
    Status    : 'Status',
    Service   : 'Service',
    Priority  : 'Priority'
  }
}
