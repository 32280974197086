import Watchable  from '@/mixins/watchable'
import * as Utils from '@/lib/data/Validations/utils/Utils'

const isPlainObject = val => !!val && typeof val === 'object' && val.constructor === Object
export default {
  mixins: [Watchable],
  data () {
    return {
      filtersModel: {
        lang: ''
      }
    }
  },
  methods: {
    onFilterChange () {
      if (this.dataTable?.options?.page) this.dataTable.options.page = 1
      if (Utils.isFunction(this.getData) && this.dataTable?.options?.itemsPerPage > -1) this.getData()
    }
  },
  watch   : {},
  computed: {
    filterData () {
      const filtersLangObj = this.$te(`${ this.filtersModel.lang }.Filter`) ? this.$t(`${ this.filtersModel.lang }.Filter`) : {}
      const filtersLangKeys = isPlainObject(filtersLangObj) ? Object.keys(filtersLangObj) : []
      const filtersObj = isPlainObject(this.filtersModel) ? { ...this.filtersModel } : {}
      const filtersKeys = isPlainObject(filtersObj) ? Object.keys(filtersObj) : []

      const retVal = filtersLangKeys.filter(key => filtersKeys.includes(key) && !this.filtersModel[key]?.disabled).reduce((obj, key) => {
        obj[key] = filtersLangObj[key]
        return obj
      }, {})

      return retVal
    },

    filterPayload () {
      const retVal = {}
      const filtersObj = isPlainObject(this.filtersModel) ? { ...this.filtersModel } : {}
      const filtersKeys = isPlainObject(filtersObj) ? Object.keys(filtersObj) : []

      filtersKeys.filter(key => key !== 'lang').forEach(key => {
        if (this.filtersModel[key]?.key) retVal[this.filtersModel[key].key] = this.filtersModel[key]?.value || null
      })

      return filtersKeys.length ? retVal : null
    }

  },
  beforeMount () {
    const filtersObj = isPlainObject(this.filtersModel) ? { ...this.filtersModel } : {}
    const filtersKeys = isPlainObject(filtersObj) ? Object.keys(filtersObj) : []

    filtersKeys.filter(key => key !== 'lang').forEach(key => {
      this.watch(`filtersModel.${ key }.value`, this.onFilterChange)
    })
  }
}
