import BaseValidator from '@/lib/data/Validations/BaseValidator'

export default class PaymentTerminalValidator extends BaseValidator {
  rules = {
    Id: {
      required: true,
      type    : 'number'
    },
    PosVendorId: {
      required: true,
      type    : 'number'
    },
    Nickname: {
      required: true,
      type    : 'string',
      minLen  : 2
    },
    Type: {
      required: true,
      type    : 'string'
    },
    Port: {
      required: false,
      type    : 'string'
    },
    Protocol: {
      required: false,
      type    : 'string'
    },
    Model: {
      required: false,
      type    : 'string'
    },
    Manufactor: {
      required: false,
      type    : 'string'
    },
    Connection: {
      required: false,
      type    : 'string'
    },
    OperationSystem: {
      required: false,
      type    : 'string'
    },
    IpAddress: {
      required: false,
      type    : 'string'
    },
    TerminalId: {
      required: true,
      type    : 'string'
    },
    VirtualTerminalId: {
      requiredIf: ['PosVendorId', 1], // VIVA
      type      : 'string'
    },
    TId: {
      requiredIf: ['PosVendorId', 2], // MELLON
      type      : 'string'
    },
    SerialNumber: {
      required: false,
      type    : 'string'
    }
  }

  errorMessages = {
    el: {},
    en: {}
  }

  constructor (rawData = {}) {
    super(rawData)
  }
}
