<template>
  <v-container
    class="pa-1"
    fluid
  >
    <v-row no-gutters>
      <!-- Select Customer & Customer -->
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        cols="12"
      >
        <v-menu
          v-if="!selectedCustomer"
          offset-y
        >
          <template #activator="{on}">
            <v-btn
              block
              class="elevation-0"
              :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-2']"
              v-on="on"
            >
              <v-icon
                :class="[selectedCustomer ? 'green--text' : 'grey--text']"
                left
              >
                {{ $t('Pos.Pos.Toolbar.Customer.Select.Icon') }}
              </v-icon>

              {{ $t('Pos.Pos.Toolbar.Customer.Select.Title').toLocaleUpperCase($i18n.locale) }}
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="showCustomerSearchDialog">
              <v-icon
                class="mr-4"
                color="green"
              >
                {{ $t('Pos.Pos.Toolbar.Customer.Search.Icon') }}
              </v-icon>
              <v-list-item-title>{{ $t('Pos.Pos.Toolbar.Customer.Search.Title').toLocaleUpperCase($i18n.locale) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showCustomerAddDialog">
              <v-icon
                class="mr-4"
                color="green"
              >
                {{ $t('Pos.Pos.Toolbar.Customer.Add.Icon') }}
              </v-icon>
              <v-list-item-title>{{ $t('Pos.Pos.Toolbar.Customer.Add.Title').toLocaleUpperCase($i18n.locale) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <template v-else>
          <div
            class="chip light-green white--text darken-2 text-body-2 px-2 selected-customer chip--label"
            style="width: 100%; height: 36px; line-height: 36px; vertical-align: middle;"
          >
            <v-btn
              v-if="parseInt(posCart && posCart.SourceTypeId || 0) !== 1"
              class="light-green darken-4 ml-1 mt-1 float-right"
              color=""
              dark
              icon
              ripple
              small
              style="min-width: 28px; display: inline;"
              @click="removeCustomer()"
            >
              <v-icon size="18">
                close
              </v-icon>
            </v-btn>

            <v-btn
              v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
              class="light-green darken-4 ml-1 mt-1 float-right"
              color=""
              dark
              icon
              ripple
              small
              style="min-width: 28px; display: inline;"
              @click="$bus.$emit('show-customer-details-dialog', selectedCustomer.Id)"
            >
              <v-icon size="18">
                remove_red_eye
              </v-icon>
            </v-btn>

            <div
              :style="posUserHasComponentPermission('CustomerDetails', 'ACCESS') ? 'width: calc(100% - 68px)' : 'width: calc(100% - 34px)'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <span
                class="text-left text-body-2 font-weight-medium"
                style="min-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
              >
                <v-icon
                  color="light-green lighten-2"
                  size="18"
                >
                  account_circle
                </v-icon>
                {{ selectedCustomer.Firstname + ' ' + selectedCustomer.Lastname }}
              </span>

              <span
                class="text-left pl-2 pr-2 text-body-2 font-weight-medium"
                style="min-width: 130px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
              >
                <template v-if="selectedCustomer.Telephone && $options.filters.phone(selectedCustomer.Telephone)">
                  <v-icon
                    color="light-green lighten-2"
                    size="18"
                  >
                    phone
                  </v-icon>
                  {{ selectedCustomer.Telephone | phone }}
                </template>

                <template v-if="selectedCustomer.Telephone2 && $options.filters.phone(selectedCustomer.Telephone2)">
                  <v-icon
                    color="light-green lighten-2"
                    size="18"
                  >
                    phone
                  </v-icon>
                  {{ selectedCustomer.Telephone2 | phone }}
                </template>

                <template v-if="selectedCustomer.Telephone3 && $options.filters.phone(selectedCustomer.Telephone3)">
                  <v-icon
                    color="light-green lighten-2"
                    size="18"
                  >
                    phone
                  </v-icon>
                  {{ selectedCustomer.Telephone3 | phone }}
                </template>
              </span>
            </div>
          </div>
        </template>
      </v-col>

      <!-- Customer Address -->
      <v-col
        v-if="(selectedCustomer && selectedAddress && parseInt(posCart.OrderType) === 1) || $vuetify.breakpoint.xsOnly"
        cols="12"
      >
        <div
          v-if="$vuetify.breakpoint.xsOnly"
          class="pa-0 mt-1"
        >
          <div class="pa-0 ma-0">
            <v-menu
              v-if="!selectedCustomer"
              offset-y
            >
              <template #activator="{on}">
                <v-btn
                  block
                  class="ma-0 elevation-0"
                  :class="[isDarkMode ? 'grey darken-3' : 'grey lighten-2']"
                  v-on="on"
                >
                  <v-icon
                    :class="[selectedCustomer ? 'green--text' : 'grey--text']"
                    left
                  >
                    {{ $t('Pos.Pos.Toolbar.Customer.Select.Icon') }}
                  </v-icon>

                  {{ $t('Pos.Pos.Toolbar.Customer.Select.Title').toLocaleUpperCase($i18n.locale) }}
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="showCustomerSearchDialog()">
                  <v-icon
                    class="mr-4"
                    color="green"
                  >
                    {{ $t('Pos.Pos.Toolbar.Customer.Search.Icon') }}
                  </v-icon>
                  <v-list-item-title>{{ $t('Pos.Pos.Toolbar.Customer.Search.Title').toLocaleUpperCase($i18n.locale) }}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="showCustomerAddDialog()">
                  <v-icon
                    class="mr-4"
                    color="green"
                  >
                    {{ $t('Pos.Pos.Toolbar.Customer.Add.Icon') }}
                  </v-icon>
                  <v-list-item-title>{{ $t('Pos.Pos.Toolbar.Customer.Add.Title').toLocaleUpperCase($i18n.locale) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <div
              v-if="selectedCustomer"
              class="chip light-green white--text darken-2 pr-2 pl-2 selected-customer chip--label"
              style="width: 100%; height: 36px; line-height: 36px; vertical-align: middle;"
            >
              <v-btn
                class="light-green darken-4 ml-1 mt-1 float-right"
                color=""
                dark
                icon
                ripple
                small
                style="min-width: 28px; display: inline;"
                @click="removeCustomer()"
              >
                <v-icon size="18">
                  close
                </v-icon>
              </v-btn>

              <v-btn
                v-if="posUserHasComponentPermission('CustomerDetails', 'ACCESS')"
                class="light-green darken-4 ml-1 mt-1 float-right"
                color=""
                dark
                icon
                ripple
                small
                style="min-width: 28px; display: inline;"
                @click="$bus.$emit('show-customer-details-dialog', selectedCustomer.Id)"
              >
                <v-icon size="18">
                  remove_red_eye
                </v-icon>
              </v-btn>

              <div style="width: calc(100% - 68px); white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <span
                  class="text-left text-body-2 font-weight-medium"
                  style="min-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  <v-icon
                    color="light-green lighten-2"
                    size="18"
                  >
                    account_circle
                  </v-icon>
                  {{ selectedCustomer.Firstname + ' ' + selectedCustomer.Lastname }}
                </span>

                <span
                  class="text-left pl-2 pr-2 text-body-2 font-weight-medium"
                  style="min-width: 130px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  <template v-if="selectedCustomer.Telephone && $options.filters.phone(selectedCustomer.Telephone)">
                    <v-icon
                      color="light-green lighten-2"
                      size="18"
                    >
                      phone
                    </v-icon>
                    {{ selectedCustomer.Telephone | phone }}
                  </template>

                  <template v-if="selectedCustomer.Telephone2 && $options.filters.phone(selectedCustomer.Telephone2)">
                    <v-icon
                      color="light-green lighten-2"
                      size="18"
                    >
                      phone
                    </v-icon>
                    {{ selectedCustomer.Telephone2 | phone }}
                  </template>

                  <template v-if="selectedCustomer.Telephone3 && $options.filters.phone(selectedCustomer.Telephone3)">
                    <v-icon
                      color="light-green lighten-2"
                      size="18"
                    >
                      phone
                    </v-icon>
                    {{ selectedCustomer.Telephone3 | phone }}
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="selectedCustomer && selectedAddress && parseInt(posCart.OrderType) === 1"
          class="pa-0 mt-1 grey--text text--darken-1"
        >
          <v-list
            class="pa-0 ma-0"
            light
            dense
          >
            <v-list-item
              :class="[{'mt-1': $vuetify.breakpoint.xsOnly}]"
              class="blue-grey lighten-4"
              ripple
              style="max-height: 60px; height: 60px;"
              two-line
              @click="showCustomerSelectAddressDialog()"
            >
              <v-list-item-content class="grey--text text--darken-1">
                <v-list-item-title class="text-left">
                  {{ selectedAddress.Formatted }}
                </v-list-item-title>

                <v-list-item-subtitle
                  v-if="selectedAddress.Floor || selectedAddress.DoorBell"
                  class=""
                >
                  <span
                    v-if="selectedAddress.Floor"
                    class="black--text"
                  >
                    {{ $t('Pos.Pos.Toolbar.Customer.Selected.Floor') }}
                  </span> {{ selectedAddress.Floor }}
                  <span
                    v-if="selectedAddress.DoorBell"
                    :class="{'ml-3': selectedAddress.Floor}"
                    class="black--text"
                  >
                    {{ $t('Pos.Pos.Toolbar.Customer.Selected.DoorBell') }}
                  </span> {{ selectedAddress.DoorBell }}
                </v-list-item-subtitle>

                <v-list-item-subtitle
                  v-if="selectedAddress.Comment"
                  class=""
                >
                  <span class="black--text">
                    {{ $t('Pos.Pos.Toolbar.Customer.Selected.Instructions') }}
                  </span> {{ selectedAddress.Comment }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar>
                <v-icon class="light-green darken-2 white--text">
                  location_on
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>

          <div v-if="addressIsOutsideStoreLimits">
            <v-alert
              class="pa-0 ma-0 mt-1 text-caption"
              color="error"
              icon="warning"
              outlined
            >
              <template v-if="isCustomAddress(selectedAddress)">
                {{ $t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Custom.Subtitle') }}
              </template>
              <template v-else>
                {{ $t('Customer.CustomerAddEditAddressDialog.Field.Address.Error.Range.Subtitle') }}
              </template>
            </v-alert>
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <v-divider class="mt-1" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PosCustomer   from '@/mixins/pos/posCustomer'
import Auth          from '@/mixins/auth'
import AddressCommon from '@/mixins/maps/addressCommon'

export default {
  name      : 'PosToolbarCustomer',
  components: {},
  directives: {},
  mixins    : [Auth, PosCustomer, AddressCommon],
  props     : {},
  DataStore : { posCart: 'cart' },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    showCustomerSearchDialog () {
      this.$bus.$emit('cart:show-customer-search-dialog')
    },

    showCustomerAddDialog () {
      this.$bus.$emit('cart:show-customer-add-dialog')
    },

    showCustomerSelectAddressDialog () {
      this.$bus.$emit('cart:show-customer-select-address-dialog')
    }
  }
}
</script>

<style scoped>

</style>
