<template>
  <v-card
    v-if="viewData"
    flat
  >
    <v-card-title class="py-0 px-0">
      <v-toolbar
        :height="$vuetify.breakpoint.xsOnly ? 44 : 60"
        class="filter-toolbar"
        flat
      >
        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ADD')"
          :color="$t('Restaurant.Tags.Toolbar.Button.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          @click="showAddDialog"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">
            {{ $t('Restaurant.Tags.Toolbar.Button.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Tags.Toolbar.Button.Title') }}</span>
        </v-btn>

        <v-btn
          v-if="posUserHasComponentPermission('KitchenMenu', 'ACCESS')"
          :color="$t('Restaurant.Categories.Toolbar.Button.Products.Color')"
          :icon="$vuetify.breakpoint.xsOnly"
          class="elevation-0 ma-1"
          text
          :to="{name: 'KitchenMenu'}"
        >
          <v-icon
            v-if="$t('Restaurant.Categories.Toolbar.Button.Products.Icon')"
            :left="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('Restaurant.Categories.Toolbar.Button.Products.Icon') }}
          </v-icon>&nbsp;
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Restaurant.Categories.Toolbar.Button.Products.Title').toLocaleUpperCase($i18n.locale) }}</span>
        </v-btn>

        <v-spacer />

        <filter-search v-model="dataTable.search.terms" />
      </v-toolbar>
    </v-card-title>

    <v-container
      class="ma-0 pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :footer-props="dataTable.footerProps"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :loading-text="dataTable.text.loadingText"
            :no-data-text="dataTable.text.noDataText"
            :no-results-text="dataTable.text.noResultsText"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.tag_id`]="{item}">
              {{ item.Id }}
            </template>

            <template #[`item.label`]="{item}">
              <v-chip
                :color="item.Color"
                :style="`color: ${getContrastColor(item.Color, true, 168)}`"
                class="px-1"
                label
                small
                @click="showEditDialog(item)"
              >
                <v-icon
                  v-if="item.Icon"
                  small
                  :color="getContrastColor(item.Color, true, 168)"
                  class="mr-1"
                >
                  {{ item.Icon }}
                </v-icon>

                {{ getTranslatedField(item, 'Label') }}
              </v-chip>
            </template>

            <template #[`item.description`]="{item}">
              {{ getTranslatedField(item, 'Description') }}
            </template>

            <template #[`item.language`]="{item}">
              <template v-for="lang in selectedLanguages">
                <div
                  :key="lang.Code"
                  class="mx-1 d-inline-block"
                >
                  <v-badge
                    :value="!item.Lang[lang.Code] || !item.Lang[lang.Code].Label"
                    color="orange"
                    overlap
                    right
                  >
                    <template #badge>
                      <v-icon
                        dark
                        small
                      >
                        priority_high
                      </v-icon>
                    </template>

                    <v-avatar
                      v-if="lang.ImageUrl"
                      size="20"
                    >
                      <img
                        :class="[{'grayscale opacity': !item.Lang[lang.Code] || !item.Lang[lang.Code].Label}]"
                        :src="lang.ImageUrl"
                      >
                    </v-avatar>
                    <div v-else>
                      {{
                        $te(`Common.Language.${ lang.Code }`) ? $t(`Common.Language.${ lang.Code }`).name.toLocaleUpperCase($i18n.locale) : lang.Name.toLocaleUpperCase($i18n.locale)
                      }}
                    </div>
                  </v-badge>
                </div>
              </template>
            </template>

            <template #[`item.icon`]="{item}">
              <v-icon
                v-if="item.Icon"
                size="28"
              >
                {{ item.Icon }}
              </v-icon>
            </template>

            <template #[`item.visible`]="{item}">
              <v-switch
                v-if="(!item.System && !item.Group) || userIsSuperAdmin"
                v-model="item.Visible"
                :disabled="(!posUserCan('KitchenMenu', 'MANAGE') || !!item.System || !!item.Group) && !userIsSuperAdmin"
                :label="item.Visible ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-2"
                color="success"
                hide-details
                inset
                @change="updateItem(item)"
              />
            </template>

            <template #[`item.active`]="{item}">
              <v-switch
                v-if="!item.System || userIsSuperAdmin"
                v-model="item.Active"
                :disabled="(!posUserCan('KitchenMenu', 'MANAGE') || !!item.System) && !userIsSuperAdmin"
                :label="item.Active ? $t('Common.Button.Toggle.ActiveF') : $t('Common.Button.Toggle.InactiveF')"
                class="ma-2"
                color="success"
                hide-details
                inset
                @change="updateItem(item)"
              />
            </template>

            <template
              v-if="userIsSuperAdmin"
              #[`item.label_only`]="{item}"
            >
              <v-switch
                v-model="item.LabelOnly"
                :disabled="!posUserCan('KitchenMenu', 'MANAGE')"
                :label="item.LabelOnly ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                class="ma-2"
                color="primary"
                hide-details
                inset
                @change="updateItem(item)"
              />
            </template>

            <template
              v-if="userIsSuperAdmin"
              #[`item.system`]="{item}"
            >
              <v-switch
                v-model="item.System"
                :disabled="!posUserCan('KitchenMenu', 'MANAGE')"
                :label="item.System ? $t('Common.Button.Toggle.Active') : $t('Common.Button.Toggle.Inactive')"
                class="ma-2"
                color="red"
                hide-details
                inset
                @change="updateItem(item)"
              />
            </template>

            <template
              v-if="userIsSuperAdmin"
              #[`item.group`]="{item}"
            >
              <v-chip
                v-if="item.Group"
                style="min-width: 120px"
                class="text-center align-center justify-center px-1"
                small
                label
              >
                {{ item.Group }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{item}">
              <v-btn
                v-if="posUserCan('KitchenMenu', 'ADD') && (![TagGroupsEnum.OVER_16, TagGroupsEnum.OVER_18].includes(item.Group) || userIsSuperAdmin)"
                :small="$vuetify.breakpoint.xsOnly"
                class="elevation-2 ma-0 mr-1 cyan"
                dark
                icon
                @click="showEditDialog(item)"
              >
                <v-icon :small="$vuetify.breakpoint.xsOnly">
                  edit
                </v-icon>
              </v-btn>

              <template v-if="(!item.System && !item.Group) || userIsSuperAdmin">
                <v-btn
                  v-if="posUserCan('KitchenMenu', 'DELETE')"
                  :small="$vuetify.breakpoint.xsOnly"
                  class="elevation-2 ma-0 red"
                  dark
                  icon
                  @click="deleteItem(item)"
                >
                  <v-icon :small="$vuetify.breakpoint.xsOnly">
                    delete
                  </v-icon>
                </v-btn>
              </template>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <tag-add-dialog
      :item-to-edit="selectedItem"
      :visible.sync="addEditDialogVisible"
    />

    <confirm-dialog
      :html-content="$t('Restaurant.Tags.Dialog.Delete.Content')"
      :html-title="$t('Restaurant.Tags.Dialog.Delete.Title')"
      :info-bar="true"
      :info-bar-body="$t('Restaurant.Tags.Dialog.Delete.Notification')"
      :visible.sync="confirmDialogDeleteVisible"
      :width="560"
      no-button-class="v-btn--outline green--text"
      title-class="red white--text lighten-0"
      yes-button-class="red white--text"
      yes-button-event="on-confirm-delete"
      @on-confirm-delete="onConfirmDelete"
    />
  </v-card>
</template>

<script>
import AppData              from '@/mixins/appdata'
import Auth                 from '@/mixins/auth'
import SourceType           from '@/mixins/orders/sourceType'
import ConfirmDialog        from '@/components/common/ConfirmDialog'
import FilterSearch         from '@/components/common/filter/FilterSearch'
import DataTable            from '@/mixins/data-table/dataTable'
import { clone }            from '@/lib/helper/helper'
import { getContrastColor } from '@/lib/color'
import TagAddDialog         from '@/components/restaurant/kitchen/TagAddDialog.vue'
import Translatable         from '@/mixins/translatable'
import TagGroupsEnum        from '@/api/Enums/TagGroupsEnum'

export default {
  name      : 'Tags',
  components: {
    TagAddDialog,
    FilterSearch,
    'confirm-dialog': ConfirmDialog
  },
  directives: {},
  mixins    : [AppData, Auth, Translatable, SourceType, DataTable],
  data () {
    return {
      confirmDialogDeleteVisible: false,
      selectedItem              : null,
      addEditDialogVisible      : false,
      dataTable                 : {
        requestTableConfig: false,
        socketCommand     : {
          list  : window.SocketCommand.Tag.All,
          save  : window.SocketCommand.Tag.Save,
          delete: window.SocketCommand.Tag.Delete
        },
        options: {
          defaultSortBy: 'tag_id',
          sortBy       : ['tag_id'],
          sortDesc     : [true]
        },
        rejectHeaders: {
          xsOnly   : [],
          smAndDown: [],
          mdAndDown: [],
          lgAndDown: [],
          xlAndDown: []
        },
        headers: [
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Id'),
            value    : 'tag_id',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Label'),
            value    : 'label',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Description'),
            value    : 'description',
            align    : 'left',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Language'),
            value    : 'language',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Icon'),
            value    : 'icon',
            align    : 'center',
            sortable : false,
            cellClass: 'text-caption px-1'
          },
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Active'),
            value    : 'active',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Visible'),
            value    : 'visible',
            align    : 'center',
            sortable : true,
            cellClass: 'text-caption'
          },
          {
            text     : this.$t('Restaurant.Tags.Table.Headers.Actions'),
            value    : 'actions',
            align    : 'right',
            sortable : false,
            cellClass: 'text-caption text-no-wrap px-2',
            width    : 140
          }
        ]
      }
    }
  },
  computed: {
    TagGroupsEnum () {
      return TagGroupsEnum
    }
  },
  watch: {},
  created () {
    // console.log('1. created');
    if (this.userIsSuperAdmin) {
      this.dataTable.headers.splice(this.dataTable.headers.length - 1, 0, {
        text     : this.$t('Restaurant.Tags.Table.Headers.LabelOnly'),
        value    : 'label_only',
        align    : 'center',
        sortable : true,
        cellClass: 'text-caption'
      })
      this.dataTable.headers.splice(this.dataTable.headers.length - 1, 0, {
        text     : this.$t('Restaurant.Tags.Table.Headers.System'),
        value    : 'system',
        align    : 'center',
        sortable : true,
        cellClass: 'text-caption'
      })
      this.dataTable.headers.splice(this.dataTable.headers.length - 1, 0, {
        text     : this.$t('Restaurant.Tags.Table.Headers.Group'),
        value    : 'group',
        align    : 'center',
        sortable : true,
        cellClass: 'text-caption'
      })
    }
  },
  mounted () {
    // console.log('2. mounted');
  },
  updated () {
    // console.log('3. updated');
  },
  beforeDestroy () {
    // console.log('4. destroyed');
  },
  methods: {
    getContrastColor,
    showAddDialog () {
      this.selectedItem = null
      this.addEditDialogVisible = true
    },

    showEditDialog (item) {
      if (!item || !this.posUserCan('KitchenMenu', 'ADD') || ([TagGroupsEnum.OVER_16, TagGroupsEnum.OVER_18].includes(item.Group) && !this.userIsSuperAdmin)) return

      this.selectedItem = clone(item)
      this.addEditDialogVisible = true
    },

    updateItem (item) {
      if (!item || !this.posUserCan('KitchenMenu', 'MANAGE')) return

      window.callAS(window.SocketCommand.Tag.Save, clone(item))
    },

    deleteItem (item) {
      if (!item || !this.posUserCan('KitchenMenu', 'DELETE')) return

      this.selectedItem = clone(item)
      this.confirmDialogDeleteVisible = true
    },

    onConfirmDelete () {
      window.callAS(window.SocketCommand.Tag.Delete, {
        Id: this.selectedItem.Id
      })
    },

    onDeleteResult (data) {
      this.onDeleteResultInternal(data)

      if (data.status === 'success') {
        this.$bus.$emit('app-show-notification', {
          body: data.type,
          type: 'success',
          icon: 'check'
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body: this.$t('Restaurant.Tags.Notification.Delete'),
          type: 'error',
          icon: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .filter-toolbar .v-toolbar__content {
  padding : 0 6px;
}

/deep/ .v-input--switch .v-input--selection-controls__input {
  margin-right : 0;
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch {
  display  : inline-block;
  position : relative !important;
  /*width    : 65px !important;*/
}

/deep/ .v-input--switch.v-input--selection-controls.v-input--switch label {
  font-size     : 12px !important;
  padding-right : 0;
  margin        : 0;
}

/deep/ .chip:focus:not(.chip--disabled):after, .chip--active:not(.chip--disabled):after, .chip--selected:not(.chip--disabled):after {
  top  : 0;
  left : 0;
}
</style>
