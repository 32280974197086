import BaseCollection    from '../../../lib/data/Collections/BaseCollection'
import MessageGroupModel from '../../Models/messages/MessageGroupModel'

export default class MessageGroupCollection extends BaseCollection {
  constructor (items = []) {
    super(items, MessageGroupModel)
  }

  /* PROPERTIES */

  /* METHODS */
}
