<template>
  <div class="d-inline-block print-receipt-button">
    <v-tooltip
      :bottom="tooltipPosition === 'bottom'"
      :top="tooltipPosition === 'top'"
      :left="tooltipPosition === 'left'"
      :right="tooltipPosition === 'right'"
      :disabled="disabled || disableTooltip"
      :open-delay="tooltipOpenDelay"
      :close-delay="tooltipCloseDelay"
      :color="color"
    >
      <template #activator="{on}">
        <v-btn
          :class="[btnClass, icon ? color : '', {'grey lighten-2': disabled && icon}]"
          :color="icon ? '' : color"
          :style="btnStyle ? btnStyle : small ? icon ? `width: ${smallSize}px; height: ${smallSize}px;` : `height: ${smallSize}px;` : ''"
          :dark="dark"
          :icon="icon"
          :small="small"
          :large="large"
          :outlined="outline"
          :text="flat"
          :depressed="depressed"
          :absolute="absolute"
          :disabled="disabled"
          :block="block"
          :fixed="fixed"
          :fab="fab"
          v-on="on"
          @click="onButtonClick()"
        >
          <v-icon
            v-if="!hideIcon"
            :small="small && smallIcon"
            :left="!!label && !hideLabel && !icon"
          >
            {{ iconName }}
          </v-icon>
          <span v-if="label && !hideLabel && !icon">{{ label }}</span>
        </v-btn>
      </template>

      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Auth    from '@/mixins/auth'
import AppData from '@/mixins/appdata'

export default {
  name      : 'EmailReceiptButton',
  components: {},
  directives: {},
  mixins    : [Auth, AppData],
  props     : {
    tooltip: {
      type   : String,
      default: function () {
        return this.$t('Common.Button.EmailDocument')
      }
    },
    label: {
      type   : String,
      default: function () {
        return this.$t('Common.Button.EmailDocument').toLocaleUpperCase(this.$i18n.locale)
      }
    },
    hideLabel: {
      type   : Boolean,
      default: false
    },
    btnClass: {
      type   : String,
      default: 'white--text ma-0'
    },
    btnStyle: {
      type   : String,
      default: ''
    },
    color: {
      type   : String,
      default: 'blue'
    },
    icon: {
      type   : Boolean,
      default: false
    },
    iconName: {
      type   : String,
      default: 'mdi-email-arrow-right'
    },
    hideIcon: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    },
    smallIcon: {
      type   : Boolean,
      default: false
    },
    smallSize: {
      type   : Number,
      default: 32
    },
    large: {
      type   : Boolean,
      default: false
    },
    outline: {
      type   : Boolean,
      default: false
    },
    flat: {
      type   : Boolean,
      default: false
    },
    depressed: {
      type   : Boolean,
      default: false
    },
    absolute: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    block: {
      type   : Boolean,
      default: false
    },
    fixed: {
      type   : Boolean,
      default: false
    },
    fab: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    tooltipPosition: {
      type   : String,
      default: 'top'
    },
    tooltipOpenDelay: {
      type   : Number,
      default: 150
    },
    tooltipCloseDelay: {
      type   : Number,
      default: 150
    },
    disableTooltip: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    onButtonClick () {
      this.$emit('fiscal-document:email')
    }
  }
}
</script>

<style scoped>

</style>
