import formatCurrency from '@/lib/currency/currency'

/**
 *
 * 12345 => 12.345,00€
 *
 * @param {String || Number} value
 */

function currency (value = 0) {
  const appCurrency = window.appConfig?.LOCATION_DATA?.Currency || null
  const locale = appCurrency?.locale || 'el-GR'
  const currency = appCurrency?.name || 'EUR'

  return formatCurrency(locale, currency, value)
}

export default currency
