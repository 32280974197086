export default {
  Call: {
    Title: 'Κλήσεις | Κλήση | Κλήσεις',

    ListView: {
      CallerId: {
        Unknown: {
          Title: 'Απόκρυψη Αρ.',
          Color: 'red lighten-2',
          Icon : 'phone'
        },
        Phone: {
          Color: 'blue lighten-2',
          Icon : 'phone'
        }
      },
      CustomerAdd: {
        Tooltip: {
          Title: 'Προσθήκη Νέου Πελάτη',
          Color: 'green darken-1'
        },
        Button: {
          Color: 'green darken-1',
          Icon : 'person_add_alt_1'
        }
      },
      SearchCustomer: {
        Tooltip: {
          Title: 'Αναζήτηση Πελάτων',
          Color: 'blue darken-3'
        },
        Button: {
          Color: 'blue darken-3',
          Icon : 'person_search'
        }
      },
      CustomerDetails: {
        Tooltip: {
          Title: 'Προβολή Καρτέλας Πελάτη',
          Color: 'cyan darken-1'
        },
        Button: {
          Color: 'cyan',
          Icon : 'remove_red_eye'
        }
      }
    },

    Table: {
      Headers: {
        Status    : 'Κατάσταση',
        Customer  : 'Πελάτης',
        User      : 'Χρήστης',
        CallerId  : 'Τηλέφωνο',
        Address   : 'Διεύθυνση',
        IncomingAt: 'Ημερομηνια',
        Actions   : ''
      },
      Filter: {
        filterStatus: {
          Type       : 'v-btn-toggle',
          Label      : 'Τύπος Παραγγελίας',
          PlaceHolder: 'Όλοι οι τύποι παραγγελίας',
          ItemText   : 'Name',
          ItemValue  : 'Value',
          Items      : [
            {
              Value: 'ALL',
              Name : 'Όλες',
              Color: 'light-blue',
              Icon : 'phone_in_talk',
              Class: 'white--text'
            },
            {
              Value: 'ANSWERED',
              Name : 'Απαντημένες',
              Color: 'green',
              Icon : 'phone_in_talk',
              Class: 'white--text'
            },
            {
              Value: 'MISSED',
              Name : 'Αναπάντητες',
              Color: 'red',
              Icon : 'phone_missed',
              Class: 'white--text'
            }
          ]
        },
        filterDate: {
          Type       : 'v-date-picker',
          Label      : 'Ημερομηνία',
          PlaceHolder: 'Επιλέξτε Ημερομηνία',
          Icon       : 'event'
        }
      }
    }
  }
}
