<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <template v-for="(cart, index) in carts">
          <v-container
            :key="`cart-${cart.Timestamp}`"
            class="pa-0"
            fluid
          >
            <v-row>
              <!-- CART HEADER -->
              <v-col cols="12">
                <v-subheader class="pl-2 pr-1 mb-1 blue-grey lighten-3 grey--text text--darken-3 text-subtitle-1 font-weight-bold">
                  {{ $tc('Order.Title', 1) }} #{{ desc ? (carts.length - index) : (index + 1) }} -&nbsp;<span class="grey--text text--darken-1">{{ cart.Time }}</span>

                  <v-spacer />
                  <v-btn
                    :loading="cart.loading"
                    :disabled="cart.loading"
                    class="text-caption white--text light-green darken-4 ma-0 pa-0 rounded-r-0"
                    depressed
                    style="min-width: 35px;"
                    @click="onTableCartOrderComplete(cart, false)"
                  >
                    <v-icon>
                      mdi-printer-pos-cancel-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :loading="cart.loading"
                    :disabled="cart.loading"
                    class="text-caption white--text light-green darken-2 ma-0 pa-0 px-4 rounded-l-0 font-weight-medium"
                    depressed
                    style="min-width: 90px;"
                    @click="onTableCartOrderComplete(cart, true)"
                  >
                    {{ $t('Pos.Pos.Footer.Button.AddOrder') }}
                  </v-btn>
                </v-subheader>
              </v-col>

              <!-- CART ITEMS -->
              <v-col cols="12">
                <v-container
                  class="pa-0 mb-4"
                  fluid
                >
                  <v-row>
                    <v-col cols="12">
                      <template v-for="tableSeat in getCartItemsByRowIds(cart.Items.map(c => c.Id))">
                        <v-subheader
                          :key="`table-seat-${tableSeat.Seat}`"
                          class="font-weight-bold pl-1 blue-grey lighten-4"
                          style="height: 26px;"
                        >
                          {{ tableSeat.Name }}
                        </v-subheader>
                        <template v-for="category in tableSeat.Items">
                          <v-subheader
                            :key="`cart-${cart.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}`"
                            class="grey--text text--darken-2 text-subtitle-1 pl-0"
                            style="height: 36px;"
                          >
                            {{ getTranslatedField(getCategory(category.CategoryId), 'Name') }}
                          </v-subheader>

                          <template v-for="(menu) in category.Items">
                            <v-list
                              :key="`cart-${cart.Timestamp}-table-seat-${tableSeat.Seat}-category-${category.CategoryId}-item-${menu.RowId}-hash-${menu.HashId}`"
                              class="mb-1 pb-0 pt-0"
                              dense
                              light
                              three-line
                            >
                              <v-list-item
                                :disabled="menu.Loading"
                                class="grey lighten-4"
                                ripple
                              >
                                <v-list-item-content>
                                  <span
                                    v-if="getCategoryColor(category.CategoryId)"
                                    :style="'background-color: #' + getCategoryColor(category.CategoryId) + ';'"
                                    style="position: absolute; width:5px; height: 100%; margin-left: -16px; padding: 0; margin-right: 16px;"
                                  />
                                  <v-list-item-title class="text-subtitle-1">
                                    <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                      <strong class="orange--text">{{ formatUnitQuantity($i18n.locale, menu.UnitQuantity, getMeasurementUnitById(menu.UnitId)) }}</strong>
                                    </template>
                                    <template v-else>
                                      <strong class="orange--text">
                                        {{ menu.Quantity }} x
                                      </strong>
                                    </template>
                                    {{ getTranslatedField(menu, 'Name') }}
                                  </v-list-item-title>

                                  <v-list-item-subtitle>
                                    <template v-if="menu.SpecialStatus && menu.IsSpecial && !menu.HasCustomPrice">
                                      <span class="old-price">
                                        {{ menu.MenuPrice | currency }}
                                      </span>
                                      {{ menu.SpecialPrice | currency }}
                                    </template>

                                    <template v-else>
                                      <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                                        {{ menu.MenuPrice | currency }}
                                        <span class="grey--text text-caption">/ {{ getMeasurementUnitById(menu.UnitId).BaseUnit }}</span>
                                      </template>
                                      <template v-else>
                                        <span
                                          v-if="menuItemHasCoupon(menu)"
                                          class="old-price"
                                        >
                                          {{ parseFloat(menu.Price) | currency }}
                                        </span>
                                        {{ menuItemHasCoupon(menu) ? parseFloat(menu.PriceFmt) - (parseFloat(menu.Coupon.Amount) / getMenuQuantity(menu)) : menu.PriceFmt | currency }}
                                      </template>
                                    </template>
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle class="pb-1 text-caption">
                                    {{ getMenuSelectedOptionsString(menu) }}&nbsp;
                                  </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-list-item-action-text class="text-subtitle-1">
                                    <template v-if="menu.SpecialStatus && menu.IsSpecial">
                                      <span class="old-price">
                                        {{ parseFloat(parseFloat(menu.MenuPrice) * parseInt(menu.Quantity)) | currency }}
                                      </span>
                                      {{ parseFloat(parseFloat(menu.SpecialPrice) * parseInt(menu.Quantity)) | currency }}
                                    </template>
                                    <template v-else>
                                      {{ parseFloat(parseFloat(menu.Price) * parseInt(menu.Quantity)) | currency }}
                                    </template>
                                  </v-list-item-action-text>

                                  <v-list-item-action-text>
                                    <v-btn
                                      v-if="menu.Custom && parseInt(menu.Custom.Order)"
                                      class="d-inline-block ml-1 px-1"
                                      color="pink lighten-1"
                                      style="min-width: 0px;"
                                      text
                                      small
                                      ripple
                                    >
                                      <v-icon>
                                        low_priority
                                      </v-icon>

                                      <span style="position: relative; top: -4px;">{{ menu.Custom.Order }}</span>
                                    </v-btn>

                                    <v-btn
                                      v-if="menu.Custom && parseInt(menu.Custom.Seat)"
                                      class="d-inline-block ml-1 px-1"
                                      color="brown lighten-1"
                                      style="min-width: 0px;"
                                      text
                                      small
                                    >
                                      <v-icon>
                                        event_seat
                                      </v-icon>

                                      <span style="position: relative; top: -4px;">{{ menu.Custom.Seat }}</span>
                                    </v-btn>

                                    <v-btn
                                      v-if="menuItemHasCoupon(menu)"
                                      class="d-inline-block ml-1 px-1"
                                      color="purple"
                                      style="min-width: 0px;"
                                      text
                                      small
                                    >
                                      <v-icon>
                                        redeem
                                      </v-icon>
                                    </v-btn>
                                  </v-list-item-action-text>
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider />
                            </v-list>
                          </template>
                        </template>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>

      <v-col
        v-if="totals"
        cols="12"
      >
        <table-orders-totals :view-data="viewData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                   from '@/mixins/auth'
import AppData                from '@/mixins/appdata'
import Translatable           from '@/mixins/translatable'
import TableStatus            from '@/mixins/tables/tableStatus'
import PosCatalog             from '@/mixins/pos/posCatalog'
import vModel                 from '@/mixins/vModel'
import TableOrdersTotals      from '@/components/restaurant/tables/orders/TableOrdersTotals'
import CartData               from '@/mixins/pos/cartData'
import { formatUnitQuantity } from '../../../../lib/currency/currency'

export default {
  name      : 'TableCartOrdersList',
  components: { TableOrdersTotals },
  directives: {},
  mixins    : [Auth, AppData, vModel, Translatable, TableStatus, CartData, PosCatalog],
  props     : {
    viewData: {
      type   : Object,
      default: undefined
    },
    table: {
      type   : Object,
      default: undefined
    },
    totals: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.$bus.$on(window.SocketCommand.Table.Cart.Complete, this.onTableCartOrderCompleteResponse)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off(window.SocketCommand.Table.Cart.Complete, this.onTableCartOrderCompleteResponse)
  },
  destroyed () {},
  methods: {
    formatUnitQuantity,
    onTableCartOrderComplete (cart, autoPrint = true) {
      if (!cart || !this.table || !this.viewData) return

      this.$set(cart, 'loading', true)
      const requestData = {
        TableId  : this.table.Id,
        CartId   : this.viewData.Id,
        Orders   : [cart.Timestamp],
        AutoPrint: autoPrint
      }

      window.callAS(window.SocketCommand.Table.Cart.Complete, requestData)
    },

    onTableCartOrderCompleteResponse (response) {
      const cart = this.carts.find(cart => cart.Timestamp === response?.Timestamp)
      if (response.status === 'success') {
        this.$emit('save-success')
      } else {
        if (cart) this.$set(cart, 'loading', false)
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'warning',
          timeout: 3000
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
