<template>
  <div>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="450"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          class="pl-1"
          height="80"
        >
          <v-avatar :color="$t('Restaurant.MenuStockUpdateDialog.Toolbar.Color')">
            <v-icon dark>
              {{ $t('Restaurant.MenuStockUpdateDialog.Toolbar.Icon') }}
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Restaurant.MenuStockUpdateDialog.Toolbar.Title') }}
            </div>

            <div class="text-caption">
              {{ $t('Restaurant.MenuStockUpdateDialog.Toolbar.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="pa-3"
        >
          <v-form
            ref="dialogForm"
            v-model="dialogFormValid"
            @submit.prevent
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="timeVal"
                    v-mask="'##:##'"
                    :hint="$t('Restaurant.MenuStockUpdateDialog.Field.Time.Hint')"
                    :label="$t('Restaurant.MenuStockUpdateDialog.Field.Time.Label')"
                    :prepend-icon="$t('Restaurant.MenuStockUpdateDialog.Field.Time.Icon')"
                    :placeholder="$t('Restaurant.MenuStockUpdateDialog.Field.Time.Placeholder')"
                    :rules="[
                      (v) => !!v || $t('Restaurant.MenuStockUpdateDialog.Field.Time.Error.Required'),
                      (v) => /^(2[0-3]|[01][0-9]):([0-5][0-9])$/.test(v) || $t('Restaurant.MenuStockUpdateDialog.Field.Time.Error.TimeString')
                    ]"
                    clearable
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="stockVal"
                    v-mask="'######'"
                    :hint="$t('Restaurant.MenuStockUpdateDialog.Field.Stock.Hint')"
                    :label="`${$t('Restaurant.MenuStockUpdateDialog.Field.Stock.Label')} ${$t('Common.Misc.In')} ${getMeasurementUnitById(unitId).BaseName}`"
                    :prepend-icon="$t('Restaurant.MenuStockUpdateDialog.Field.Stock.Icon')"
                    :rules="[
                      (v) => !!v || $t('Restaurant.MenuStockUpdateDialog.Field.Stock.Error.Valid')
                    ]"
                    :suffix="`/ ${getMeasurementUnitById(unitId).RateUnit}`"
                    class="pr-2"
                    min="0"
                    required
                    step="1"
                    type="number"
                    clearable
                  />
                </v-col>

                <v-col cols="12">
                  <v-icon class="mr-2">
                    mdi-shape-circle-plus
                  </v-icon>

                  <v-btn-toggle
                    v-model="actionVal"
                    color="success"
                    dense
                  >
                    <v-btn
                      text
                      value="reset"
                      :color="actionVal === 'reset' ? 'green' : ''"
                    >
                      {{ $t('Restaurant.MenuStockUpdateDialog.Field.Action.Reset').toLocaleUpperCase($i18n.locale) }}
                    </v-btn>

                    <v-btn
                      text
                      value="add"
                      :color="actionVal === 'add' ? 'primary' : ''"
                    >
                      {{ $t('Restaurant.MenuStockUpdateDialog.Field.Action.Add').toLocaleUpperCase($i18n.locale) }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="light-blue--text text--darken-1"
            outlined
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
          <v-btn
            class="green white--text elevation-0"
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Save').toLocaleUpperCase($i18n.locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppData from '@/mixins/appdata'
import Auth    from '@/mixins/auth'

export default {
  name      : 'MenuStockUpdateDialog',
  components: {},
  mixins    : [AppData, Auth],
  props     : {
    visible: {
      type   : Boolean,
      default: false
    },
    data: {
      type   : Object,
      default: undefined
    },
    unitId: {
      type   : [String, Number],
      default: undefined
    }
  },
  data () {
    return {
      timeVal        : '',
      stockVal       : '',
      actionVal      : 'reset',
      dialogFormValid: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.timeVal = this.data?.data?.time || this.appConfig?.LOCATION_DATA?.Automation?.DailyOpen || ''
          this.stockVal = this.data?.data?.value || ''
          this.actionVal = this.data?.data?.action || 'reset'
        })
      }
    }
  },
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {
    onSaveButtonClick () {
      this.$refs.dialogForm.validate()

      if (this.dialogFormValid) {
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()

        this.$emit('save', {
          time  : this.timeVal,
          value : parseInt(this.stockVal),
          action: this.actionVal
        }, this.data.dayIdx, this.data.timeIdx, this.data.week)

        this.onCancelButtonClick()
      }
    },

    onCancelButtonClick () {
      this.isVisible = false
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation()
      this.timeVal = this.stockVal = ''
      this.actionVal = 'reset'
    }
  }
}
</script>
