<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-list
          class=""
          dense
          two-line
        >
          <template v-for="(cart, index) in completedDataFiltered">
            <v-divider :key="`divider-top-${index}`" />

            <v-list-item
              :key="`list-tile-${index}`"
              :class="isDarkMode ? 'grey darken-3' : 'grey lighten-4'"
              ripple
              v-on="selectableOrders ? {click: () => selectableMenuItems ? toggleOrderMenuItems(cart) : toggleCartItem(cart)} : {}"
            >
              <v-list-item-action
                v-if="selectableOrders"
                class="mr-2"
              >
                <v-checkbox
                  :indeterminate="selectableMenuItems ? someOrderMenuItemsSelected(cart) && !allOrderMenuItemsSelected(cart) : false"
                  :value="selectableMenuItems ? someOrderMenuItemsSelected(cart) : isCartItemSelected(cart)"
                  color="grey darken-2"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1 font-weight-bold">
                  {{ $tc('Order.Title', 1) }} #{{ desc ? (completedData.length - index) : (index + 1) }}
                </v-list-item-title>

                <v-list-item-subtitle class="mt-1">
                  {{ cart.Time }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text class="text-subtitle-1">
                  <span
                    v-if="cartHasItemsWithCoupon(cart)"
                    class="old-price"
                  >
                    {{ cartOrderPriceTotal(cart, false) | currency }}
                  </span>
                  {{ cartOrderPriceTotal(cart, cartHasItemsWithCoupon(cart)) | currency }}
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <v-divider :key="`list-tile-divider-${index}`" />

            <div
              :key="`v-list-menu-items-${index}`"
              class="mb-6"
            >
              <v-list
                class="pa-0 ma-0"
                dense
              >
                <template v-for="(menuItem, menuItemIndex) in getOrderMenuItems(cart)">
                  <v-list-item
                    :key="`list-tile-menu-item-${menuItemIndex}`"
                    class="list-tile-small"
                    :class="isDarkMode ? 'grey darken-4' : 'grey lighten-5'"
                    style="min-height: 32px;"
                    v-on="selectableMenuItems ? {click: () => toggleMenuItem(menuItem, cart)} : {}"
                  >
                    <v-list-item-action
                      v-if="selectableMenuItems"
                      class="mr-2"
                    >
                      <v-checkbox
                        :value="isMenuItemSelected(menuItem)"
                        color="grey darken-2"
                      />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title
                        :style="!selectableMenuItems && !selectableOrders ? '' : !selectableMenuItems ? 'padding-left: 32px;' : ''"
                        class="text-subtitle-1 font-weight-regular"
                      >
                        <template v-if="menuItem.MenuCustomPrice && menuItem.UnitId > 1">
                          <strong class="orange--text">{{ formatUnitQuantity($i18n.locale, menuItem.UnitQuantity, getMeasurementUnitById(menuItem.UnitId)) }}</strong>
                        </template>
                        <template v-else>
                          <strong class="orange--text"> {{ menuItem.PaymentQuantity }} x </strong>
                        </template>
                        {{ menuItem.Name }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text class="text-subtitle-1">
                        <v-btn
                          v-if="menuItem.Custom && parseInt(menuItem.Custom.Order)"
                          class="d-inline-block ml-1 px-1"
                          color="pink lighten-1"
                          text
                          ripple
                          small
                          style="min-width: 0px;"
                        >
                          <v-icon small>
                            low_priority
                          </v-icon>

                          <span style="position: relative; top: -4px;">{{ menuItem.Custom.Order }}</span>
                        </v-btn>

                        <v-btn
                          v-if="menuItem.Custom && parseInt(menuItem.Custom.Seat)"
                          class="d-inline-block ml-1 px-1"
                          color="brown lighten-1"
                          text
                          small
                          style="min-width: 0px;"
                        >
                          <v-icon small>
                            event_seat
                          </v-icon>

                          <span style="position: relative; top: -4px;">{{ menuItem.Custom.Seat }}</span>
                        </v-btn>

                        <v-btn
                          v-if="menuItemHasCoupon(menuItem)"
                          class="d-inline-block ml-1 px-1"
                          color="purple"
                          text
                          ripple
                          small
                          style="min-width: 0px;"
                        >
                          <v-icon small>
                            redeem
                          </v-icon>
                        </v-btn>

                        <template v-if="menuItem.SpecialStatus && menuItem.IsSpecial && !menuItem.HasCustomPrice">
                          <span class="old-price">
                            <template v-if="menu.MenuCustomPrice && menu.UnitId > 1">
                              {{ parseFloat(menu.MenuPrice) | currency }}
                            </template>
                            <template v-else>
                              {{ parseFloat(menuItem.MenuPrice) * parseInt(menuItem.PaymentQuantity) | currency }}
                            </template>
                          </span>
                          {{ parseFloat(menuItem.SpecialPrice) * parseInt(menuItem.PaymentQuantity) | currency }}
                        </template>
                        <template v-else>
                          <span
                            v-if="menuItemHasCoupon(menuItem)"
                            class="old-price"
                          >
                            <template v-if="menuItem.MenuCustomPrice && menuItem.UnitId > 1">
                              {{ parseFloat(menuItem.Price) | currency }}
                            </template>
                            <template v-else>
                              {{ parseFloat(menuItem.Price) * parseInt(menuItem.PaymentQuantity) | currency }}
                            </template>
                          </span>
                          {{ menuItemPriceTotal(menuItem, menuItemHasCoupon(menuItem)) | currency }}
                        </template>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="`list-tile-menu-item-divider-${menuItemIndex}`" />
                </template>
              </v-list>
            </div>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                   from '@/mixins/auth'
import AppData                from '@/mixins/appdata'
import Translatable           from '@/mixins/translatable'
import TableStatus            from '@/mixins/tables/tableStatus'
import PosCatalog             from '@/mixins/pos/posCatalog'
import vModel                 from '@/mixins/vModel'
import CompletedData          from '@/mixins/tables/completedData'
import { formatUnitQuantity } from '@/lib/currency/currency'

export default {
  name      : 'SelectTableCompletedOrders',
  components: {},
  directives: {},
  mixins    : [Auth, AppData, vModel, Translatable, TableStatus, CompletedData, PosCatalog],
  props     : {
    viewData: {
      type   : Object,
      default: undefined
    },
    unpaid: {
      type   : Boolean,
      default: false
    },
    paid: {
      type   : Boolean,
      default: false
    },
    selectableOrders: {
      type   : Boolean,
      default: false
    },
    selectableMenuItems: {
      type   : Boolean,
      default: false
    },
    selectedTotal: {
      type   : Number,
      default: 0
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    selectedItemsTotal: {
      get () {
        return this.selectedTotal || 0
      },
      set (val) {
        this.$emit('update:selected-total', val)
      }
    },
    completedDataFiltered () {
      return this.completedData.filter(cart => this.paid ? cart.Paid : this.unpaid ? !cart.Paid : true)
    }
  },
  watch: {
    vModel (items) {
      this.selectedItemsTotal = items?.reduce((a, b) => a + parseFloat(b.Total), 0) || 0
    }
  },
  beforeCreate () {
  },
  created () {
    this.$bus.$on('table-order-select-all-carts-items', this.selectAllItems)
  },
  beforeMount () {
  },
  mounted () {
    if (!this.selectableOrders && !this.selectableMenuItems) {
      this.selectAllItems()
    }
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('table-order-select-all-carts-items', this.selectAllItems)
  },
  destroyed () {
  },
  methods: {
    formatUnitQuantity,
    isCartItemSelected (item) {
      return !!this.vModel?.find(cart => cart.Timestamp === item.Timestamp)
    },

    isMenuItemSelected (item) {
      return !!this.vModel?.find(menuItem => menuItem.HashId === item.HashId)
    },

    toggleCartItem (item) {
      if (!this.vModel) return

      const index = this.vModel?.findIndex(cart => cart.Timestamp === item.Timestamp)

      if (index > -1) {
        this.vModel.splice(index, 1)
      } else {
        this.vModel.push(this.setCartExtraData(item))
      }
    },

    selectAllItems () {
      if (this.selectableOrders && !this.selectableMenuItems) {
        this.selectAllCarts()
      } else {
        this.selectAllMenuItems()
      }
    },

    selectAllCarts () {
      const tmpModel = []

      this.completedDataFiltered.forEach(cart => {
        tmpModel.push(this.setCartExtraData(cart))
      })

      this.vModel = tmpModel
    },

    selectAllMenuItems () {
      const tmpModel = []

      this.completedDataFiltered.forEach(cart => {
        const items = this.getOrderMenuItems(cart)
        items.forEach(menuItem => {
          tmpModel.push(this.setMenuItemExtraData(menuItem))
        })
      })

      this.vModel = tmpModel
    },

    toggleOrderMenuItems (cart) {
      const items = this.getOrderMenuItems(cart)
      const tmpModel = JSON.parse(JSON.stringify(this.vModel))

      if (this.someOrderMenuItemsSelected(cart) && !this.allOrderMenuItemsSelected(cart)) {
        items.forEach(menuItem => {
          const index = tmpModel.findIndex(menu => menu.HashId === menuItem.HashId)
          tmpModel.splice(index, 1)
        })

        items.forEach(menuItem => {
          tmpModel.push(this.setMenuItemExtraData(menuItem))
        })
      } else {
        items.forEach(menuItem => {
          const index = tmpModel.findIndex(menu => menu.HashId === menuItem.HashId)
          if (index > -1) {
            tmpModel.splice(index, 1)
          } else {
            tmpModel.push(this.setMenuItemExtraData(menuItem))
          }
        })
      }

      this.vModel = tmpModel
    },

    setMenuItemExtraData (item) {
      const itemObj = JSON.parse(JSON.stringify(item))
      itemObj.Total = this.menuItemPriceTotal(itemObj, true)

      return itemObj
    },

    setCartExtraData (item) {
      const itemObj = JSON.parse(JSON.stringify(item))
      itemObj.Total = this.cartOrderPriceTotal(item, true)

      return itemObj
    },

    allOrderMenuItemsSelected (cart) {
      const items = this.getOrderMenuItems(cart)

      return items.every(menuItem => !!this.vModel && this.vModel.find(menu => menu.HashId === menuItem.HashId))
    },

    someOrderMenuItemsSelected (cart) {
      const items = this.getOrderMenuItems(cart)

      return items.some(menuItem => !!this.vModel && this.vModel.find(menu => menu.HashId === menuItem.HashId))
    },

    toggleMenuItem (menuItem, cart) {
      if (!this.vModel) return

      const index = this.vModel?.findIndex(menu => menu.HashId === menuItem.HashId)

      if (index > -1) {
        this.vModel.splice(index, 1)
      } else {
        this.vModel.push(this.setMenuItemExtraData(menuItem))
      }
    },

    getOrderMenuItems (cart) {
      if (this.selectableMenuItems) {
        const items = []
        const seats = this.getCartItems(cart, false, this.selectableMenuItems, 'PaymentQuantity')
        seats.forEach(seat => {
          seat.Items.forEach(category => {
            category.Items.forEach(menuItem => {
              items.push(menuItem)
            })
          })
        })
        return items
      } else {
        return this.getCartItems(cart, true, this.selectableMenuItems, 'PaymentQuantity')
      }
    }
  }
}
</script>

<style scoped>
/deep/ .list-tile-small .v-list__tile {
  height : 36px !important;
}

/deep/ .v-list__tile__action {
  min-width : auto !important;
}
</style>
