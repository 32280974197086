import BaseModel from '../../../lib/data/Models/BaseModel'

export default class SupportDepartmentModel extends BaseModel {
  constructor (rawData = {}, validator = null) {
    super(rawData, validator)
  }

  setData (rawData = {}) {
    super.setData(rawData)

    this.departmentid = parseInt(rawData.departmentid) || 0
    this.name = rawData.name || ''
  }

  boot () {

  }

  /* PROPERTIES */

  /* METHODS */
}
