import ReceiptTypeCodeEnum from '@/api/Enums/ReceiptTypeCodeEnum'

export default {
  Title: 'Statistics',

  Product: {
    Title: 'Product Statistics',
    Table: {
      Headers: {
        Id          : '#',
        CategoryName: 'Category',
        Name        : 'Product',
        Quantity    : 'Orders',
        UnitQuantity: 'Quantity',
        Sales       : 'Sales'
      }
    },

    Export: {
      Title         : 'Export',
      Icon          : 'file_download',
      Color         : 'primary',
      SuccessMessage: 'Soon you\'ll receive a file with the exported product statistics at your email: {email}'
    }
  },

  SalesReportZ: {
    Title: 'Sales Report Z',
    Table: {
      Headers: {
        Order      : 'Order',
        Receipt    : 'Fiscal Doc',
        ReceiptType: 'Fiscal Doc Type',
        Total      : 'Total',
        Date       : 'Date'
      }
    },

    Export: {
      Title         : 'Export',
      Icon          : 'file_download',
      Color         : 'primary',
      SuccessMessage: 'Soon you\'ll receive the Sales Report Z at your email: {email}'
    },

    Filter: {
      filterOrderType: {
        Type       : 'v-btn-toggle',
        Label      : 'Orders',
        PlaceHolder: 'All Orders',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 0,
            Name : 'All'
          },
          {
            Value: 1,
            Name : 'With Receipt',
            Icon : 'mdi-receipt-text',
            Color: 'green',
            Class: 'white--text'
          },
          {
            Value: 2,
            Name : 'Without Receipt',
            Icon : 'mdi-receipt-text-remove',
            Color: 'orange',
            Class: 'white--text'
          }
        ]
      },

      filterInvoiceType: {
        Type       : 'v-select',
        Chips      : true,
        Multiple   : false,
        Label      : 'Fiscal Doc Type',
        PlaceHolder: 'All fiscal doc types',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : [
          {
            Id    : ReceiptTypeCodeEnum.GREEK_INVOICE,
            Name  : 'Sales Invoice',
            Avatar: {
              Color: 'blue',
              Icon : 'mdi-receipt-text'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_1,
            Name  : 'Credit Invoice / Associated',
            Avatar: {
              Color: 'red',
              Icon : 'mdi-receipt-text-remove'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_INVOICE_CREDIT_2,
            Name  : 'Credit Invoice / Not Associated',
            Avatar: {
              Color: 'red',
              Icon : 'mdi-receipt-text-remove'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_RECEIPT,
            Name  : 'Retail Receipt',
            Avatar: {
              Color: 'green',
              Icon : 'mdi-receipt-text'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_RECEIPT_CREDIT,
            Name  : 'Credit Retail Receipt',
            Avatar: {
              Color: 'red',
              Icon : 'mdi-receipt-text-remove'
            }
          },
          {
            Id    : ReceiptTypeCodeEnum.GREEK_DOCUMENT_ORDER,
            Name  : 'Order Report',
            Avatar: {
              Color: 'blue-grey',
              Icon : 'mdi-receipt-text'
            }
          }
        ]
      }
    }
  },

  OrdersHeatmap: {
    Title : 'Orders Heatmap',
    Orders: 'Data from {orders} orders'
  }
}
