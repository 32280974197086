import { isObject } from '@/lib/data/Validations/utils/Utils'

/**
 *
 * @param item
 * @param fieldName
 * @returns {number}
 */
export const quantityFromItem = (item, fieldName = 'Quantity') => {
  return toInt(item[fieldName])
}

/**
 *
 * @param item
 * @param fieldName
 * @returns {number}
 */
export const priceFromItem = (item, fieldName = 'Price') => {
  return toFloat(item[fieldName])
}

/**
 *
 * @param val
 * @returns {number}
 */
export const toFloat = (val) => {
  return parseFloat(parseFloat(val || 0).toFixed(2))
}

/**
 *
 * @param val
 * @returns {number}
 */
export const toInt = (val) => {
  return parseInt(val || 0)
}

/**
 *
 * @param valueInt
 * @param nearestInt
 * @returns {number}
 */
export const roundIntToNearest = (valueInt, nearestInt) => Math.round(toInt(valueInt) / toInt(nearestInt)) * toInt(nearestInt)

/**
 *
 * @param valueInt
 * @param nearestInt
 * @returns {number}
 */
export const floorIntToNearest = (valueInt, nearestInt) => Math.floor(toInt(valueInt) / toInt(nearestInt)) * toInt(nearestInt)

export const roundDateToNearest = (minutes, d = new Date()) => {
  const ms = 1000 * 60 * minutes // convert minutes to ms
  return new Date(Math.round(d.getTime() / ms) * ms)
}

/**
 *
 * @param obj
 * @returns {{}}
 */
export const dotNotationToObj = (obj) => {
  const result = {}

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.')

    // Create sub-objects along path as needed
    let target = result
    while (parts.length > 1) {
      const part = parts.shift()
      target = target[part] = target[part] || {}
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath]
  }

  return result
}

/**
 *
 * @param obj
 * @param res
 * @param current
 * @returns {{}}
 */
export const objToDotNotation = (obj, res = {}, current = '') => {
  for (const key in obj) {
    const value = obj[key]
    const newKey = (current ? current + '.' + key : key) // joined key with dot
    if (value && typeof value === 'object') {
      objToDotNotation(value, res, newKey) // it's a nested object, so do it again
    } else {
      res[newKey] = value // it's not an object, so set the property
    }
  }
  return res
}

/**
 *
 * @param obj
 * @param keyName
 * @returns {{[p: string]: unknown}}
 */
export const filterObjectByKeyIncludes = (obj = {}, keyName = '') => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => key.includes(keyName)))
}
/**
 *
 * @param obj
 * @param keyName
 * @returns {{[p: string]: unknown}}
 */
export const filterObjectByKeyStartsWith = (obj = {}, keyName = '') => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => key.startsWith(keyName)))
}

/**
 *
 * @param email
 * @returns {*}
 */
export const emailIsGuest = (email) => {
  return email?.includes('guest.getfoodpro.gr')
}

/**
 * Deep merge objects.
 *
 * @param sources
 * @returns {Object}
 */
export function mergeDeep (...sources) {
  const target = {}
  if (!sources.length) {
    return target
  }

  while (sources.length > 0) {
    const source = sources.shift()
    if (isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          target[key] = mergeDeep(target[key], source[key])
        } else {
          Object.assign(target, { [key]: source[key] })
        }
      }
    }
  }
  return target
}

export const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

/**
 *
 * @param hex
 * @param bw
 * @returns {string|string}
 */
export const invertColor = (hex, bw) => {
  if (!hex) return '#FFFFFF'

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    // eslint-disable-next-line no-console
    console.warn('Invalid HEX color.')
    return '#FFFFFF'
  }
  let r = parseInt(hex.slice(0, 2), 16)
  let g = parseInt(hex.slice(2, 4), 16)
  let b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#323232'
      : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b)
}
